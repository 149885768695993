import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { USEFHorse } from "../../interfaces/USEFData";
import { Barn, Horse } from "../../models";
import { calculateHeightInInches } from "../../utilities/horse/Height";
import { createHorse, deleteHorse, updateHorse } from "../../utilities/horse/Horse";
import { createMembership } from "../../utilities/membership/Membership";
import { capitalizeName } from "../../utilities/person/PersonNameFormat";
import ErrorAlert from "../Errors/ErrorAlert";
import USEFHorseForm from "../Memberships/USEFHorseForm";
import ECHorseForm from "../Memberships/ECHorseForm";
import HorseHeightInput from "./HorseHeightInput";
import { ECHorse } from "../../interfaces/ECData";
import { CreateHorseInput, CreateMembershipInput, UpdateHorseInput } from "../../API";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    horse?: (Horse | null)
    selectedBarn?: (Barn | null)
    onChange: Function
}

const HorseForm: React.FC<_Props> = ({horse, selectedBarn, onChange}) => {
    const user = useContext(PersonContext);
    
    const [barn, setBarn] = useState<Barn | null | undefined>();
    const [showUSEFForm, setShowUSEFForm] = useState(false);
    const [showECForm, setShowECForm] = useState(false);
    const [usefNumber, setUSEFNumber] = useState("");
    const [ecNumber, setECNumber] = useState("");
    const [usefData, setUSEFData] = useState<USEFHorse | null | undefined>();
    const [ecData, setECData] = useState<ECHorse | null | undefined>();
    const [name, setName] = useState("");
    const [nickname, setNickname] = useState("");
    const [height, setHeight] = useState(0);
    const [yearBorn, setYearBorn] = useState("");
    const [cogginsNumber, setCogginsNumber] = useState("");
    const [microchipNumber, setMicrochipNumber] = useState("");
    const [ownerName, setOwnerName] = useState("");
    const [error, setError] = useState("");

    const clearForm = () => {
        setName("");
        setNickname("");
        setHeight(0);
        setYearBorn("");
        setCogginsNumber("");
        setMicrochipNumber("");
        setOwnerName("");
        setShowUSEFForm(false);
        setShowECForm(false);
    }

    useEffect(() => {
        setBarn(selectedBarn);
    }, [selectedBarn]);

    useEffect(() => {
        if (horse) {
            setName(horse.name);
            setNickname(horse.nickname || "");
            setHeight(parseInt(horse.height || "0") || 0);
            setYearBorn(moment(horse.dateOfBirth).format("YYYY"));
            setCogginsNumber(horse.cogginsNumber || "");
            setMicrochipNumber(horse.microchipNumber || "");
            setOwnerName(horse.ownerName);
            if (horse.height) setHeight(parseFloat(horse.height));
        } else {
            clearForm();
        }
    }, [horse]);

    const verifyForm = () => {
        if (!name) {
            setError("Please include a show name for the horse");
            return false;
        }
        if (!height) {
            setError("Please include a height for the horse");
            return false;
        }
        return true;
    }

    const onUSEFNumberSubmit = (data?: USEFHorse) => {
        if (data) {
            setUSEFData(data);
            setName(capitalizeName(data.Name));
            setHeight(calculateHeightInInches(parseFloat(data.Height)));
            setYearBorn(moment(data.FoalDate).format("YYYY"));
            setMicrochipNumber(data.MicrochipNumber);

            const ownerName = (data.Owners && data.Owners.length) ? data.Owners[0].Name : "";
            if (ownerName) {
                let name = "";
                if (ownerName.includes(",")) {
                    const nameArray = ownerName.split(",");
                    name = nameArray[1].trim() + " " + nameArray[0].trim();
                } else {
                    name = ownerName.trim();
                }
                setOwnerName(capitalizeName(name));
            }
        } else {
            setError("Could not find data for this horse.");
        }
    }

    const onECNumberSubmit = (data?: ECHorse) => {
        if (data) {
            setECData(data);
            setName(capitalizeName(data.HorseName));
            setHeight(calculateHeightInInches(data.Horse_Size_Hands));
            setYearBorn(moment(data.Birthdate).format("YYYY"));

            const ownerName = data.Owner_Lastname_FirstName;
            if (ownerName) {
                let name = "";
                if (ownerName.includes(",")) {
                    const nameArray = ownerName.split(",");
                    name = nameArray[1].trim() + " " + nameArray[0].trim();
                } else {
                    name = ownerName.trim();
                }
                setOwnerName(capitalizeName(name));
            }
        } else {
            setError("Could not find data for this horse.");
        }
    }

    const handleHeightChange = (heightInInches: number) => {
        setHeight(heightInInches);
    }

    const handleCreateHorse = async () => {
        const input: CreateHorseInput = {
            personId: user.id,
            name,
            nickname,
            ownerName,
            height: height.toString(),
            dateOfBirth: yearBorn ? moment(yearBorn).format("YYYY-MM-DD") : undefined,
            microchipNumber,
            cogginsNumber,
            barnId: barn?.id,
            // horseBarnId: barn?.id,
            barnName: barn?.name
        };
        const createResult = await createHorse(input);
        if (createResult.isSuccess) {
            if (usefNumber) {
                await handleCreateUSEFMembership(createResult.result);
            } 
            if (ecNumber) {
                await handleCreateECMembership(createResult.result);
            }
            onChange(createResult.result, "update");
            clearForm();
        } else {
            setError(createResult.message);
        }
    }

    const handleCreateUSEFMembership = async (horse: Horse) => {
        const input: CreateMembershipInput = {
            personId: user.id,
            name: "USEF Horse",
            membershipId: usefNumber,
            membershipStatus: usefData?.USEFStatus,
            type: usefData?.USEFStatus,
            dateMembershipEnds: (usefData?.USEFEndDate ? usefData?.USEFEndDate : undefined),
            horseId: horse.id,
            // membershipHorseId: horse.id
        };
        await createMembership(input);
    }

    const handleCreateECMembership = async (horse: Horse) => {
        const input: CreateMembershipInput = {
            personId: user.id,
            name: "EC Horse",
            membershipId: ecNumber,
            membershipStatus: capitalizeName(ecData?.Status || ""),
            horseId: horse.id,
            // membershipHorseId: horse.id
        };
        await createMembership(input);
    }

    const handleUpdateHorse = async () => {
        if (horse) {
            const input: UpdateHorseInput = {
                id: horse.id,
                personId: user.id,
                name,
                nickname,
                height: height.toString(),
                dateOfBirth: yearBorn ? moment(yearBorn).format("YYYY-MM-DD") : undefined,
                microchipNumber,
                cogginsNumber,
                barnId: barn?.id,
                // horseBarnId: barn?.id,
                barnName: barn?.name
            };
            const createResult = await updateHorse(input);
            if (createResult.isSuccess) {
                if (usefNumber) {
                    await handleCreateUSEFMembership(createResult.result);
                } 
                if (ecNumber) {
                    await handleCreateECMembership(createResult.result);
                }
                onChange(createResult.result, "update");
                clearForm();
            } else {
                setError(createResult.message);
            }
        } else {
            setError("No horse to edit.");
        }
    }

    const handleAddHorse = async () => {
        const isValid = verifyForm();
        if (isValid) {
            await handleCreateHorse();
        }
    }

    const handleEditHorse = async () => {
        const isValid = verifyForm();
        if (isValid) {
            await handleUpdateHorse();
        }
    }

    const handleDeleteHorse = async () => {
        if (horse) {
            const deleteResult = await deleteHorse(horse);
            if (deleteResult.isSuccess) {
                onChange();
                clearForm();
            } else {
                setError(deleteResult.message);
            }
        } else {
            setError("No horse to delete.");
        }
    }

    return (
        <>
            {/* Hide until can reliably access USEF and EC search */}
            <IonRow className="ion-justify-content-center">
                {/* TO DO - add back ability to search for horse */}
                {/* <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton onClick={() => {setShowECForm(false); setShowUSEFForm(true);}}>
                        Add From USEF
                    </IonButton>
                </IonCol> */}
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton onClick={() => {setShowUSEFForm(false); setShowECForm(true);}}>
                        Add From EC
                    </IonButton>
                </IonCol>
            </IonRow>
            {showUSEFForm && (
                <>
                    <USEFHorseForm onSubmit={onUSEFNumberSubmit}/>
                    <hr/>
                </>
            )}
            {showECForm && (
                <>
                    <ECHorseForm onSubmit={onECNumberSubmit}/>
                    <hr/>
                </>
            )}
            {error && <ErrorAlert error={error} />}
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Show Name <RequiredInputIndicator showWordRequired /></IonLabel>
                        <IonInput 
                            id="horseShowName"
                            type="text"
                            value={name}
                            aria-required={true}
                            onIonChange={e => {
                                setName(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Nickname / Barn Name</IonLabel>
                        <IonInput 
                            id="horseBarnName"
                            type="text"
                            value={nickname}
                            aria-required={true}
                            onIonChange={e => {
                                setNickname(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <HorseHeightInput height={height} isRequired={true} onChange={handleHeightChange} />
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Year Born</IonLabel>
                        <IonInput 
                            id="horseYearBorn"
                            type="text"
                            value={yearBorn}
                            aria-required={true}
                            onIonChange={e => {
                                setYearBorn(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Microchip Number</IonLabel>
                        <IonInput 
                            id="horseMicrochipNumber"
                            type="text"
                            value={microchipNumber}
                            aria-required={true}
                            onIonChange={e => {
                                setMicrochipNumber(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Coggins Number</IonLabel>
                        <IonInput 
                            id="horseCogginsNumber"
                            type="text"
                            value={cogginsNumber}
                            aria-required={true}
                            onIonChange={e => {
                                setCogginsNumber(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
             {horse && (
                    <IonCol sizeMd="4" className="ion-text-center">
                        <IonButton
                            className="ion-margin-top"
                            color="danger"
                            expand="block"
                            onClick={handleDeleteHorse}
                        >
                            Delete Horse
                        </IonButton>
                    </IonCol>
                )}
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton
                        id="horseSubmitBtn"
                        className="ion-margin-top"
                        color="tertiary"
                        expand="block"
                        onClick={horse ? handleEditHorse : handleAddHorse}
                    >
                        {horse ? "Edit Horse" : "Add Horse"}
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default HorseForm;