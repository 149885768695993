import {
    IonCard,
    IonCardContent,
    IonCol,
    IonIcon,
    IonLabel,
    IonRow,
} from "@ionic/react";
import { checkboxOutline } from "ionicons/icons";
import React from "react";

interface _Props {
    success: string
    width?: string
}

const SuccessBanner: React.FC<_Props> = ({success, width}) => {
    return (
        <>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeSm="12" sizeMd={width ? width : "8"} className="ion-no-padding">
                    <IonCard color="success" mode="md">
                        <IonCardContent>
                            <IonIcon icon={checkboxOutline} />
                            <IonLabel id="successMsg" className="ion-text-wrap description font-weight-bold">
                                {" " + success}
                            </IonLabel>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </>
    );
};

export default SuccessBanner;
