import {
    IonButton,
    IonCard,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import Spinner from "../../../../../components/Spinners/Spinner";
import FileBrowser from "../../../../../components/FileBrowser/FileBrowser";
import { isCSV } from "../../../../../utilities/files/File";
import Papa from "papaparse";
import { Table } from "reactstrap";
import { bulkAddRHSCMembershipfromCSV } from "../../../../../utilities/membership/bulkAdd";
import { PersonContext } from "../../../../../context/PersonContext";
import SuccessBanner from "../../../../../components/Banners/SuccessBanner";

export interface InputRow {
    'Submission Date': string, 
    'First Name': string, 
    'Last Name': string, 
    'Rider Date of Birth': string, 
    'Email': string, 
    'Phone Number': string, 
    'Street Address': string, 
    'Street Address Line 2': string, 
    'City': string, 
    'State': string, 
    'Zip Code': string, 
    'Barn / Farm': string, 
    'Membership Type': string, 
    '# Family Members': string, 
    'First Name 1': string, 
    'Last Name 1': string, 
    'Date of Birth 1 (If under 18)': string, 
    'First Name 2': string, 
    'Last Name 2': string, 
    'Date of Birth 2 (If under 18)': string, 
    'First Name 3': string, 
    'Last Name 3': string, 
    'Date of Birth 3 (If under 18)': string, 
    'First Name 4': string, 
    'Last Name 4': string, 
    'Date of Birth 4 (If under 18)': string, 
    'Payment': string
}

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventOrganizationMembersBulkAddPage: React.FC<OrganizationPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");

    const [uploadedInputRows, setUploadedInputRows] = useState<InputRow[] | null | undefined>();

    const handleSelectedFile = async (file: File) => {
        try {
            setIsLoading(true);
            if (isCSV(file)) {
                await handleReadingFile(file);
                setError("");
                setIsLoading(false);
            }
            else {
                setError("Please check that your upload is one of: .csv, .xlsx, .xls, .xlt");
                setIsLoading(false);
            }
        } catch (error: any) {
            console.error("Caught an error: ", error);
            setIsLoading(false);
        }
    }

    async function handleReadingFile(file: File) {
        try {
            const inputRows: InputRow[] = await handleGettingDataFromFile(file) as InputRow[];
            setUploadedInputRows(inputRows);
        } catch (error: any) {
            console.error("Error while handling selected file: ", error);
            setError("Sorry, we could not upload the file. Check that the columns are all valid. If the issue persists, contact customer service.");
        }
    }

    async function handleGettingDataFromFile(file: File) {
        try {
            return new Promise((resolve, reject) => {
                Papa.parse(file, {
                    header: true,
                    dynamicTyping: true,
                    complete (results, file) {
                        let inputRows: InputRow[] = results.data as InputRow[];
                        resolve(inputRows);
                    },
                    error (err, file) {
                        reject(err)
                    }
                })
            });
        } catch (error: any) {
            console.error("Error while handling selected file: ", error);
            return [];
        }
    }

    async function startBulkUpload(inputRows: InputRow[]) {
        setIsLoading(true);
        await bulkAddRHSCMembershipfromCSV(inputRows, user, setLoadingProgress);
        setSuccess("Finished adding members.")
        setIsLoading(false);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="New Members" />
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Bulk Add New Members</IonCardTitle>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="12" className="text-center">
                                    <p>First, select a CSV file (.csv) with these columns:</p>
                                    <p className="description">Submission Date, First Name, Last Name, Rider Date of Birth,</p>
                                    <p className="description">Email, Phone Number, Street Address, Street Address Line 2, City, State, Zip Code,</p>
                                    <p className="description">Barn / Farm, Membership Type, # Family Members, </p>
                                    <p className="description">First Name 1, Last Name 1, Date of Birth 1 (If under 18),</p>
                                    <p className="description">First Name 2, Last Name 2, Date of Birth 2 (If under 18),</p>
                                    <p className="description">First Name 3, Last Name 3, Date of Birth 3 (If under 18),</p>
                                    <p className="description">First Name 4, Last Name 4, Date of Birth 4 (If under 18), Payment</p>
                                </IonCol>
                            </IonRow>
                            {error && <ErrorAlert width="12" error={error} />}
                            {success && <SuccessBanner width="12" success={success} />}
                            {isLoading ?
                                <>
                                    <Spinner />
                                    <p>{loadingProgress.toFixed(0)}% Loaded</p>
                                </>
                                :
                                <>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol size="12" className="text-center">
                                            <FileBrowser handleSelectedFile={handleSelectedFile}/>
                                        </IonCol>
                                    </IonRow>
                                    {(uploadedInputRows && uploadedInputRows.length > 0) && 
                                        <>
                                            <hr/>
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol size="12" className="text-center">
                                                    <p>Review the data below before starting the bulk upload of these members.</p>
                                                    <IonButton color="success" onClick={() => startBulkUpload(uploadedInputRows)}>
                                                        Start Bulk Upload
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <p>{uploadedInputRows.length} total rows</p>
                                                </IonCol>
                                            </IonRow>
                                            <Table responsive striped hover>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Date Submitted</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Rider Date of Birth</th>
                                                        <th>Email</th>
                                                        <th>Phone Number</th>
                                                        <th>Street Address</th>
                                                        <th>Street Address Line 2</th>
                                                        <th>City</th>
                                                        <th>State</th>
                                                        <th>Zip Code</th>
                                                        <th>Barn / Farm</th>
                                                        <th>Membership Type</th>
                                                        <th># Family Members</th>
                                                        <th>First Name 1</th>
                                                        <th>Last Name 1</th>
                                                        <th>Date of Birth 1 (If under 18)</th>
                                                        <th>First Name 2</th>
                                                        <th>Last Name 2</th>
                                                        <th>Date of Birth 2 (If under 18)</th>
                                                        <th>First Name 3</th>
                                                        <th>Last Name 3</th>
                                                        <th>Date of Birth 3 (If under 18)</th>
                                                        <th>First Name 4</th>
                                                        <th>Last Name 4</th>
                                                        <th>Date of Birth 4 (If under 18)</th>
                                                        <th>Payment</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {uploadedInputRows.map((row, index) => (
                                                        <tr key={index} >
                                                            <td>{index+1}</td>
                                                            <td>{row["Submission Date"]}</td>
                                                            <td>{row["First Name"]}</td>
                                                            <td>{row["Last Name"]}</td>
                                                            <td>{row["Rider Date of Birth"]}</td>
                                                            <td>{row["Email"]}</td>
                                                            <td>{row["Phone Number"]}</td>
                                                            <td>{row["Street Address"]}</td>
                                                            <td>{row["Street Address Line 2"]}</td>
                                                            <td>{row["City"]}</td>
                                                            <td>{row["State"]}</td>
                                                            <td>{row["Zip Code"]}</td>
                                                            <td>{row["Barn / Farm"]}</td>
                                                            <td>{row["Membership Type"]}</td>
                                                            <td>{row["# Family Members"]}</td>
                                                            <td>{row["First Name 1"]}</td>
                                                            <td>{row["Last Name 1"]}</td>
                                                            <td>{row["Date of Birth 1 (If under 18)"]}</td>
                                                            <td>{row["First Name 2"]}</td>
                                                            <td>{row["Last Name 2"]}</td>
                                                            <td>{row["Date of Birth 2 (If under 18)"]}</td>
                                                            <td>{row["First Name 3"]}</td>
                                                            <td>{row["Last Name 3"]}</td>
                                                            <td>{row["Date of Birth 3 (If under 18)"]}</td>
                                                            <td>{row["First Name 4"]}</td>
                                                            <td>{row["Last Name 4"]}</td>
                                                            <td>{row["Date of Birth 4 (If under 18)"]}</td>
                                                            <td>{row["Payment"]}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </>
                                    }
                                </>
                            }
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationMembersBulkAddPage;