import { EventClass } from "../../models";

var moment = require("moment");

/**
 * Sorts the eventClasses so that the first element is the smallest number and the last element is the largest number
 * 
 * @param data 
 * @returns eventClasses sorted smallest number to largest number
 */
export function sortEventClassesByNumber(data: EventClass[]) {
    try {
        let result: EventClass[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => new moment(a.number) - new moment(b.number));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: eventClass");
        console.error(error);
        return null;
    }
};