import {
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event} from "../../../models";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { RouteComponentProps, useHistory } from "react-router";
import {getEventById} from "../../../utilities/events/Event";
import ErrorAlert from "../../../components/Errors/ErrorAlert";
import CreateEntriesForm from "../../../components/Entry/CreateEntriesForm";

interface EntryPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventCreateEntryPage: React.FC<EntryPageProps> = ({match}) => {
    const history = useHistory();

    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setCurrentEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        getEvent();
    }, [match, match.params.id]);

    const navigateToPage = () => {
        if (currentEvent && currentEvent.id) {
            const path = "index/event/entries/" + currentEvent.id;
            history.push(path);
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="New Entry" />
                {error && <ErrorAlert width="12" error={error} />}
                <IonRow id="entryForm" className="ion-align-items-stretch">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-no-padding bg-white stretch">
                            <IonCardContent>
                                <CreateEntriesForm event={currentEvent} onSubmit={navigateToPage}/>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EventCreateEntryPage;