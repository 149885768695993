import { EventEntry } from "../../models";

/**
 * Sorts the eventEntries so that the first element is the smallest number and the last element is the largest number
 * 
 * @param data 
 * @returns eventes sorted smallest number to largest number
 */
 export function sortEventEntries(data: EventEntry[]) {
    try {
        let result: EventEntry[] = [];
        if (data && data.length) {
            result = data.sort(function(a: EventEntry, b: EventEntry): number {
                const numberResult = (b.number || 0) - (a.number || 0);
                const nameResult = (a.horseName || "").localeCompare(b.horseName || "");
                return numberResult || nameResult;
            });
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: EventEntry");
        console.error(error);
        return null;
    }
};

/**
 * Sorts the eventEntries so that the first element is the smallest number and the last element is the largest number
 * 
 * @param data 
 * @returns eventes sorted smallest number to largest number
 */
export function sortEventEntriesByNumber(data: EventEntry[]) {
    try {
        let result: EventEntry[] = [];
        if (data && data.length) {
            result = data.sort((a: EventEntry, b: EventEntry) => (a.number || 0) - (b.number || 0));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: EventEntry");
        console.error(error);
        return null;
    }
};

/**
 * Sorts the eventEntries so that the first element is the smallest number and the last element is the largest number
 * 
 * @param data 
 * @returns eventes sorted smallest number to largest number
 */
 export function sortEventEntriesByBarn(data: EventEntry[]) {
    try {
        let result: EventEntry[] = [];
        if (data && data.length) {
            result = data.sort(function(a: EventEntry, b: EventEntry): number {
                const numberResult = (b.number || 0) - (a.number || 0);
                const nameResult = (a.barnName || a?.barn?.name || "").localeCompare(b.barnName || b?.barn?.name || "");
                return nameResult || numberResult;
            });
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: EventEntry");
        console.error(error);
        return null;
    }
};

/**
 * Sorts the eventEntries so that the first element is the newest and the last element is the oldest
 * 
 * @param data 
 * @returns eventes sorted newest to oldest
 */
 export function sortEventEntriesByUpdatedDate(data: EventEntry[]) {
    try {
        let result: EventEntry[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => (b.updatedOn || b.createdOn).localeCompare((a.updatedOn || a.createdOn)));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: EventEntry");
        console.error(error);
        return null;
    }
};