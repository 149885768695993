import {Auth} from "aws-amplify";
import {CognitoUser} from "../../interfaces/CognitoUser";

export interface UserAttributes {
    "email"?: (string | null) 
    "given_name"?: (string | null)
    "middle_name"?: (string | null)
    "family_name"?: (string | null)
    "custom:roles"?: (string | null)
    "custom:personid"?: (string | null)
    "custom:organizationids"?: (string | null)
}

export async function getUser(): Promise<CognitoUser | null> {
    try {
        const user = await Auth.currentAuthenticatedUser();
        return user;
    } catch (error: any) {
        return null;
    }
}

export async function getUserAttributes(): Promise<CognitoUser | null> {
    try {
        const userObject = await Auth.currentUserInfo();
        return userObject;
    } catch (error: any) {
        return null;
    }
}

export async function updateUserGivenName(user: any, firstName: string) {
    try {
        const attributes: UserAttributes = {};
        if (firstName) attributes.given_name = firstName;
        const userResult = await Auth.updateUserAttributes(user, attributes);
        return userResult;
    } catch (error: any) {
        console.error(error);
        return null;
    }
}

export async function updateUserMiddleName(user: any, middleName: string) {
    try {
        const attributes: UserAttributes = {};
        if (middleName) attributes.middle_name = middleName;
        const userResult = await Auth.updateUserAttributes(user, attributes);
        return userResult;
    } catch (error: any) {
        console.error(error);
        return null;
    }
}

export async function updateUserFamilyName(user: any, lastName: string) {
    try {
        const attributes: UserAttributes = {};
        if (lastName) attributes.family_name = lastName;
        const userResult = await Auth.updateUserAttributes(user, attributes);
        return userResult;
    } catch (error: any) {
        console.error(error);
        return null;
    }
}

export async function updateUserEmail(user: any, email: string) {
    try {
        const attributes: UserAttributes = {};
        if (email) attributes.email = email;
        const userResult = await Auth.updateUserAttributes(user, attributes);
        return userResult;
    } catch (error: any) {
        console.error(error);
        return null;
    }
}

export async function updateUserPersonId(user: any, personId: string) {
    try {
        const attributes: UserAttributes = {};
        if (personId) attributes["custom:personid"] = personId;
        const userResult = await Auth.updateUserAttributes(user, attributes);
        return userResult;
    } catch (error: any) {
        console.error(error);
        return null;
    }
}

export async function updateUserOrganizationId(user: any, organizationId: string) {
    try {
        const attributes: UserAttributes = user.attributes;
        const prevOrganizationIds = attributes["custom:organizationids"] ?? "";
        if (organizationId) {
            if(prevOrganizationIds?.length > 0) {
                attributes["custom:organizationids"] = `${prevOrganizationIds},${organizationId}`;
            } else {
                attributes["custom:organizationids"] = organizationId;
            }
        }
        const userResult = await Auth.updateUserAttributes(user, attributes);
        return userResult;
    } catch (error: any) {
        console.error(error);
        return null;
    }
}

export async function updateUserRoles(roles: string) {
    const attributes: UserAttributes = {
        "custom:roles": roles
    };
    Auth.currentAuthenticatedUser()
        .then(async (user) => {
            await Auth.updateUserAttributes(user, attributes);
        });
};