import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import SuccessBanner from "../Banners/SuccessBanner";
import { BarnPerson } from "../../interfaces/Person";
import { getPersonContact, savePersonContactInfo } from "../../utilities/barn/BarnPerson"; 
import PhoneNumberTypeSelect from "../Contact/PhoneNumberTypeSelect";
import Spinner from "../Spinners/Spinner";

interface _Props {
    person: BarnPerson
    onSubmit: Function
}

const EditPersonContactForm: React.FC<_Props> = ({person, onSubmit}) => {    
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneType, setPhoneType] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const clearForm = () => {
        setPhoneNumber("");
        setPhoneType("");
        setEmailAddress("");
    }

    const getContactInfo = async (person: BarnPerson) => {
        const contact = await getPersonContact(person);
        if (contact) {
            if (contact.personalEmail) setEmailAddress(contact.personalEmail);
            else if (contact.workEmail) setEmailAddress(contact.workEmail);
            if (contact.cell) {
                setPhoneNumber(contact.cell);
                setPhoneType("cell");
            } else if (contact.work) {
                setPhoneNumber(contact.work);
                setPhoneType("work");
            } else if (contact.home) {
                setPhoneNumber(contact.home);
                setPhoneType("home");
            }
        }
    }

    useEffect(() => {
        if (person) {
            getContactInfo(person);
        } else {
            clearForm();
        }
    }, [person]);

    const handleEditPerson = async () => {
        setError("");
        setIsLoading(true);
        const result = await savePersonContactInfo(person, emailAddress, phoneNumber, phoneType);
        if (result.isSuccess) {
            setSuccess(result.message);
        } else {
            setError(result.message);
        }
        onSubmit();
        setIsLoading(false);
    }

    return (
        <>
            {success && <SuccessBanner success={success} />}
            {error && <ErrorAlert error={error} />}
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Phone Number</IonLabel>
                        <IonInput 
                            type="text"
                            value={phoneNumber}
                            aria-required={true}
                            onIonChange={e => {
                                setPhoneNumber(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <PhoneNumberTypeSelect selectedType={phoneType} onChange={(type: string) => setPhoneType(type)} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Email Address</IonLabel>
                        <IonInput 
                            type="text"
                            value={emailAddress}
                            aria-required={true}
                            onIonChange={e => {
                                setEmailAddress(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            {isLoading ?
                <Spinner />
                :
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeMd="4" className="ion-text-center">
                        <IonButton
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleEditPerson}
                        >
                            Save
                        </IonButton>
                    </IonCol>
                </IonRow>
            }
        </>
    );
};

export default EditPersonContactForm;