import {
    IonLabel,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { Rider } from "../../models";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    rider?: (Rider | null)
    isRequired?: boolean
    onChange: Function
}

const RiderSelectStatus: React.FC<_Props> = ({rider, isRequired, onChange}) => {
    const [status, setStatus] = useState<string>("-");

    useEffect(() => {
        if (rider) {
            if (rider.isProfessional === true) setStatus("Professional");
            else if (rider.isProfessional === false) setStatus("Amateur");
        } else {
            setStatus("-")
        }
    }, [rider]);

    return (
        <>
            <FormGroup>
                <IonLabel position="stacked" className="description pl-3">
                    Rider Status
                    {isRequired && <RequiredInputIndicator />}
                </IonLabel>
                <Input name="isProfessionalInput" type="select" value={status} onChange={e => {setStatus(e.target.value); onChange(e.target.value === "Professional");}}>
                    <option>-</option>
                    <option>Amateur</option>
                    <option>Professional</option>
                </Input>
            </FormGroup>
        </>
    );
};

export default RiderSelectStatus;