import React, { useEffect, useState } from "react";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";
import { IonLabel, isPlatform } from "@ionic/react";
import { isWindows } from "../../utilities/platform/Platform";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    menuPlacement?: (string | null)
    selectedValue?: (string | null)
    isDisabled?: boolean
    onSelect: Function
}

const AELDivisions = [
    "Open",
    "Intermediate",
    "Novice",
    "Advanced Beginner",
    "Beginner",
    "Minis"
];

const AELDivisionSelect: React.FC<_Props> = ({isDisabled, selectedValue, menuPlacement, onSelect}) => {

    const [formattedOptions, setFormattedOptions] = useState<formattedOption[] | undefined | null>();
    const [selected, setSelected] = useState<string | null | undefined>();

    const getFormattedOptions = () => {
        let options: any = [];
        for (let i = 0; i < AELDivisions.length; i++) {
            const current = AELDivisions[i];
            const label = current; 
            const formattedOption: formattedOption = {
                value: label, //This is how the selected value passed in through props will get matched
                label: label,
                object: label
            };
            options.push(formattedOption);
        }
        setFormattedOptions(options);
    }

    useEffect(() => {
        getFormattedOptions();
    }, []);

    useEffect(() => {
        if (selectedValue) setSelected(selectedValue)
    }, [selectedValue]);

    const handleOnChange = (person?: any) => {
        if (person) {
            onSelect(person);
        } else {
            onSelect();
        }
    }

    return (
        <>
            <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>Division <RequiredInputIndicator/></IonLabel>
            {formattedOptions ?
                <BasicSelect formattedValues={formattedOptions} menuPlacement={menuPlacement} selectedValue={selected} isClearable={false} isDisabled={isDisabled} onSelect={handleOnChange} />
                :
                <p>Loading options...</p>
            }
        </>
    )
};

export default AELDivisionSelect;
