import { Person, TeamMember } from "../../models";
import { getTeamMembersByCreatorId } from "./TeamMember";

export async function getTeamMembersCreatedByUser(user: Person) {
    let teamMembers: TeamMember[] = [];
    const queryResult = await getTeamMembersByCreatorId(user.id);
    if (queryResult.isSuccess) {
        teamMembers = queryResult.result;
    }
    return teamMembers;
}