import moment from "moment";
import { EventEntry, Fee } from "../../models";

/**
 * Check if a fee should be included based on the start date and entry submission date
 * Returns true if the fee should be included
 * Returns false if the fee should NOT be included
 * 
 * @param fee 
 * @param entry (optional) if there is no entry, it checks for today's date
 * @returns Boolean
 */
export function checkEventFeeStartDate(fee: Fee, entry?: EventEntry): boolean {
    if (fee.isStartedOnEntryDate) {
        if (fee.startDate) {
            const startDate: string =  fee.startDate;

            let entrySubmissionDate: string = entry?.createdOn || "";
            if (!entrySubmissionDate || entrySubmissionDate === "") {
                // Entry has not been submitted yet. Use today's date as check
                entrySubmissionDate = moment(new Date()).format("YYYY-MM-DD");
            } 

            if (entrySubmissionDate !== startDate && entrySubmissionDate < startDate) {
                // Haven't hit the fee implementation date yet, so do not include the fee
                return false;
            }
        }
    }
    return true;
}