import { getEventsByOrganizationId, getEventsByOrganizationIdByYear } from "../events/Event";
import { Event, EventResult, Membership, EventClassEntry, OrganizationDivision, OrganizationClass, PointTable, BacklogEventResult, EventDivision, EventClass } from "../../models";
import { getEventResultsByEventClassId, getEventResultsByEventDivisionId, getEventResultsByEventId } from "./EventResult";
import { getOrganizationDivisionById } from "../organizationDivision/OrganizationDivision";
import { getOrganizationClassById } from "../organizationClass/OrganizationClass";
import { getEventClassEntryById } from "../eventClassEntry/EventClassEntry";
import { getMembershipsByOrganizationId } from "../membership/Membership";
import { FormattedAccumulatedPointData, FormattedEventPointData, FormattedPointData, TableData } from "../../interfaces/Points";
import { calculatePointsFromTable } from "../pointTable/CaculatePointsFromTable";
import { getBacklogEventResultsByEventClassId, getBacklogEventResultsByEventId } from "../backlogEventResult/BacklogEventResult";
import { getEventDivisionsByEventId, getEventDivisionsByOrganizationDivisionId } from "../eventDivision/EventDivision";
import { getEventClassesByEventDivisionId, getEventClassesByEventId, getEventClassesByEventIdWithResults, getEventClassesByOrganizationClassId } from "../eventClass/EventClass";
import moment from "moment";

// Track to reduce number of queries needed
// We'll use the organization divisions and classes to present the accumulated points earned
// Example: at all shows with the "Adult Hunter" organization division, these are the total points earned by each horse
const organizationDivisionMap = new Map<string, OrganizationDivision>();
const organizationClassMap = new Map<string, OrganizationClass>();

// Track memberships in the organization
const memberMap = new Map<number, Membership>(); //string will be back number

// Track yearly results
const organizationDivisionResultsMap = new Map<string, TableData>(); //string is division name
const organizationClassResultsMap = new Map<string, TableData>(); //string is class name

// Track event results
const eventResultsMap = new Map<string, TableData[]>(); //string is event id
const eventClassResultsMap = new Map<number, TableData>(); //number is class number

const getPlace = (pointsValue: string) => {
    if (pointsValue === "6") return "1";
    if (pointsValue === "5") return "2";
    if (pointsValue === "4") return "3";
    if (pointsValue === "3") return "4";
    if (pointsValue === "2") return "5";
    if (pointsValue === "1") return "6";
    else return "0";
}

export async function getYearlyPointDataByOrganizationId(organizationId: string, selectedYear?: string, progressCallbackFunction?: Function, isMembersOnly?: boolean) {
    organizationDivisionMap.clear();
    organizationClassMap.clear();
    memberMap.clear();
    organizationDivisionResultsMap.clear();
    organizationClassResultsMap.clear();
    eventResultsMap.clear();
    eventClassResultsMap.clear();

    // First, populate the member map so we can go back later and use this info
    await getOrganizationMembers(organizationId, selectedYear);
    
    const events: Event[] = await getOrganizationEvents(organizationId, selectedYear);
    const event: Event | undefined = (events && events.length > 0) ? events[0] : undefined;

    let eventDivisions: EventDivision[] | undefined = undefined;
    if (event) {
        const eventDivisionResult = await getEventDivisionsByEventId(event.id);
        if (eventDivisionResult.isSuccess) {
            eventDivisions = eventDivisionResult.result;
        }
    }

    let eventClasses: EventClass[] | undefined = undefined;
    if (event) {
        const eventClassResult = await getEventClassesByEventId(event.id);
        if (eventClassResult.isSuccess) {
            eventClasses = eventClassResult.result;
        }
    }

    for (let i = 0; i < events.length; i++) {
        const currentEvent = events[i];

        const percentageDone = ((i/events.length) * 100).toFixed(1);
        if (progressCallbackFunction) progressCallbackFunction(percentageDone);

        const eventResults = await getEventResults(currentEvent.id);
        const backlogEventResults = await getBacklogEventResults(currentEvent.id);

        for (let j = 0; j < eventResults.length; j++) {
            const currentEventResult: EventResult = eventResults[j];

            const eventClass = currentEventResult.eventClass;
            const eventDivision = currentEventResult.eventDivision;
            let organizationClass: OrganizationClass | undefined = await getOrganizationClass(currentEventResult);
            let organizationDivision: OrganizationDivision | undefined = await getOrganizationDivision(currentEventResult);

            const backNumber: number = currentEventResult.entry.number || 0;
            const membership: Membership | undefined = memberMap.get(backNumber);

            // Check if the report is currently only looking at members
            if (isMembersOnly) {
                if (membership) {
                    const membershipTypeName = membership.organizationMembershipType?.name || membership.type || "";
                    if (!membershipTypeName.includes("Individual") && !membershipTypeName.includes("Family")) {
                        // A membership was connected to the back number, but the membership type is not valid for counting points
                        // Skip it
                        continue;
                    }
                } else {
                    // No membership was connected to the back number, so this back number is not a member 
                    // Skip it
                    continue;
                }
                
            }

            let currentRiderName = await getRiderName(currentEventResult) || "";
            if (currentRiderName === "") {
                currentRiderName = membership?.personName || ""; 
            }

            let currentHorseName = currentEventResult.entry.horseName || "";
            if (currentHorseName === "") {
                currentHorseName = membership?.horse?.name || ""; 
            }

            let currentBarnName = currentEventResult.entry.barn?.name || currentEventResult.entry.barnName || "";
            if (currentBarnName === "") {
                currentBarnName = membership?.barn?.name || ""; 
            }

            const riderName: string = currentRiderName;
            const horseName: string = currentHorseName;
            const ownerName: string = currentEventResult.entry.ownerName || "";
            const membershipId: string = membership?.membershipId || "";
            const showsAttended: string = membership?.showsAttended ? membership.showsAttended.toString() : "";
            const place = currentEventResult.place ? currentEventResult.place.toString() : "";
            const currentPointsEarned = await getPointsFromEventResult(currentEventResult);
            
            const formattedResult: FormattedEventPointData = {
                resultId: currentEventResult.id,
                result: currentEventResult,
                eventName: currentEvent.name || "",
                eventId: currentEvent.id,
                place: place,
                pointsEarned: currentPointsEarned === 0 ? "0" : currentPointsEarned.toString(),
                eventClassNumber: eventClass?.number ? eventClass?.number.toString() : "",
                eventClassName: eventClass?.name || "",
                eventDivisionNumber: eventDivision?.number ? eventDivision?.number.toString() : "",
                eventDivisionName: eventDivision?.name || "",
                horseName: horseName,
                ownerName: ownerName,
                riderName: riderName,
                barnName: currentBarnName, 
                backNumber: backNumber.toString(),
                memberId: membershipId,
                showsAttended: showsAttended
            };
            await updateYearlyMapData(formattedResult, organizationClass, organizationDivision);
            await updateEventMapData(formattedResult, currentEvent);
        }

        for (let j = 0; j < backlogEventResults.length; j++) {
            const currentEventResult: BacklogEventResult = backlogEventResults[j];

            const backNumber: number = currentEventResult.organizationMemberId ? parseInt(currentEventResult.organizationMemberId) : 0;
            const membership: Membership | undefined = memberMap.get(backNumber);

            let currentRiderName = currentEventResult.riderName || "";
            if (currentRiderName === "") {
                currentRiderName = membership?.personName || ""; 
            }

            let currentHorseName = currentEventResult.horseName || "";
            if (currentHorseName === "") {
                currentHorseName = membership?.horse?.name || ""; 
            }

            const riderName: string = currentRiderName;
            const horseName: string = currentHorseName;
            const ownerName: string = "";
            const membershipId: string = membership?.membershipId || "";
            const showsAttended: string = membership?.showsAttended ? membership.showsAttended.toString() : "";
            let place = currentEventResult.place ? currentEventResult.place.toString() : "";

            // if (!place) {
            //     if (currentEventResult.pointsEarned) {
            //         place = getPlace(currentEventResult.pointsEarned.toString());
            //     }
            // }

            let eventClass: EventClass | undefined = undefined;
            let eventDivision: EventDivision | undefined = undefined;
            let organizationClass: OrganizationClass | undefined = undefined;
            let organizationDivision: OrganizationDivision | undefined = undefined;
            
            if (eventDivisions) eventDivision = await getEventDivisionForBacklogResult(currentEventResult, eventDivisions);
            organizationDivision = await getOrganizationDivisionForBacklogResult(currentEventResult);
            if (eventClasses) eventClass = await getEventClassForBacklogResult(currentEventResult, eventClasses);
            organizationClass = await getOrganizationClassForBacklogResult(currentEventResult);

            const currentPointsEarned = await getPointsFromBacklogEventResult(currentEventResult, eventClass, organizationClass, eventDivision, organizationDivision);  

            const formattedResult: FormattedEventPointData = {
                resultId: currentEventResult.id,
                backlogResult: currentEventResult,
                eventName: currentEvent.name || "",
                eventId: currentEvent.id,
                place: place,
                pointsEarned: currentPointsEarned === 0 ? "0" : currentPointsEarned.toString(),
                eventClassNumber: eventClass?.number ? eventClass?.number.toString() : "",
                eventClassName: eventClass?.name || "",
                eventDivisionNumber: eventDivision?.number ? eventDivision?.number.toString() : "",
                eventDivisionName: eventDivision?.name || "",
                horseName: horseName,
                ownerName: ownerName,
                riderName: riderName,
                backNumber: backNumber.toString(),
                memberId: membershipId,
                showsAttended: showsAttended
            };
            await updateYearlyMapData(formattedResult, organizationClass, organizationDivision);
            await updateEventMapData(formattedResult, currentEvent);
        }
    }

    // After getting all of the data, sort it and return it
    let allDataArray: FormattedAccumulatedPointData[][] = [];
    organizationDivisionResultsMap.forEach(value => {
        const array = value.allEventData;
        if (array) {
            // Sort the entries from most points earned to least points earned
            const sorted = array?.sort((a: FormattedAccumulatedPointData, b: FormattedAccumulatedPointData) => (b.pointsEarned ? parseFloat(b.pointsEarned) : 0) - (a.pointsEarned ? parseFloat(a.pointsEarned) : 0));
            allDataArray.push(sorted);
        }
    });
    organizationClassResultsMap.forEach(value => {
        const array = value.allEventData;
        if (array) {
            // Sort the entries from most points earned to least points earned
            const sorted = array?.sort((a: FormattedAccumulatedPointData, b: FormattedAccumulatedPointData) => (b.pointsEarned ? parseFloat(b.pointsEarned) : 0) - (a.pointsEarned ? parseFloat(a.pointsEarned) : 0));
            allDataArray.push(sorted);
        }
    });
    // Sort the organization divisions and classes by smallest number to biggest number
    const sorted = allDataArray.sort((a, b) => (a[0] ? (a[0].eventClassNumber) ? parseInt(a[0].eventClassNumber) : (a[0].eventDivisionNumber ? parseInt(a[0].eventDivisionNumber) : 0) : 0) - (b[0] ? (b[0].eventClassNumber) ? parseInt(b[0].eventClassNumber) : (b[0].eventDivisionNumber ? parseInt(b[0].eventDivisionNumber) : 0) : 0));

    const formattedResult: FormattedPointData = {
        yearlyData: (sorted || allDataArray),
        eventData: eventResultsMap
    };

    return formattedResult;
}

export async function getPointDataByEvent(event: Event, ) {
    organizationDivisionMap.clear();
    organizationClassMap.clear();
    memberMap.clear();
    organizationDivisionResultsMap.clear();
    organizationClassResultsMap.clear();
    eventResultsMap.clear();
    eventClassResultsMap.clear();

    // First, populate the member map so we can go back later and use this info
    const eventYear = moment(event.createdOn).format("YYYY");
    if (event.organizationId) await getOrganizationMembers(event.organizationId, eventYear);
    
    const currentEvent = event;

    let eventDivisions: EventDivision[] | undefined = undefined;
    const eventDivisionResult = await getEventDivisionsByEventId(event.id);
    if (eventDivisionResult.isSuccess) {
        eventDivisions = eventDivisionResult.result;
    }

    let eventClasses: EventClass[] | undefined = undefined;
    const eventClassResult = await getEventClassesByEventId(event.id);
    if (eventClassResult.isSuccess) {
        eventClasses = eventClassResult.result;
    }
    
    const eventResults = await getEventResults(currentEvent.id);
    const backlogEventResults = await getBacklogEventResults(currentEvent.id);

    for (let j = 0; j < eventResults.length; j++) {
        const currentEventResult: EventResult = eventResults[j];

        const eventClass = currentEventResult.eventClass;
        const eventDivision = currentEventResult.eventDivision;

        const riderName: string = await getRiderName(currentEventResult);
        const horseName: string = currentEventResult.entry.horseName ? currentEventResult.entry.horseName.trim() : "";
        const ownerName: string = currentEventResult.entry.ownerName || "";
        const backNumber: number = currentEventResult.entry.number || 0;
        const membership: Membership | undefined = memberMap.get(backNumber);
        const membershipId: string = membership?.membershipId || "";
        const showsAttended: string = membership?.showsAttended ? membership.showsAttended.toString() : "";
        const place = currentEventResult.place ? currentEventResult.place.toString() : "";
        const currentPointsEarned = await getPointsFromEventResult(currentEventResult);
        
        const formattedResult: FormattedEventPointData = {
            resultId: currentEventResult.id,
            result: currentEventResult,
            eventName: currentEvent.name || "",
            eventId: currentEvent.id,
            place: place,
            pointsEarned: currentPointsEarned === 0 ? "" : currentPointsEarned.toString(),
            eventClassNumber: eventClass?.number ? eventClass?.number.toString() : "",
            eventClassName: eventClass?.name || "",
            eventDivisionNumber: eventDivision?.number ? eventDivision?.number.toString() : "",
            eventDivisionName: eventDivision?.name || "",
            horseName: horseName,
            ownerName: ownerName,
            riderName: riderName,
            backNumber: backNumber.toString(),
            memberId: membershipId,
            showsAttended: showsAttended
        };
        await updateEventMapData(formattedResult, currentEvent);
    }

    for (let j = 0; j < backlogEventResults.length; j++) {
        const currentEventResult: BacklogEventResult = backlogEventResults[j];

        const backNumber: number = currentEventResult.organizationMemberId ? parseInt(currentEventResult.organizationMemberId) : 0;
        const membership: Membership | undefined = memberMap.get(backNumber);

        let currentRiderName = currentEventResult.riderName || "";
        if (currentRiderName === "") {
            currentRiderName = membership?.personName || ""; 
        }

        let currentRiderHorse = currentEventResult.horseName || "";
        if (currentRiderHorse === "") {
            currentRiderHorse = membership?.horse?.name || ""; 
        }

        const riderName: string = currentRiderName;
        const horseName: string = currentRiderHorse;
        const ownerName: string = "";
        const membershipId: string = membership?.membershipId || "";
        const showsAttended: string = membership?.showsAttended ? membership.showsAttended.toString() : "";
        const place = currentEventResult.place ? currentEventResult.place.toString() : "";

        let eventClass: EventClass | undefined = undefined;
        let eventDivision: EventDivision | undefined = undefined;
        let organizationClass: OrganizationClass | undefined = undefined;
        let organizationDivision: OrganizationDivision | undefined = undefined;
        
        if (eventDivisions) eventDivision = await getEventDivisionForBacklogResult(currentEventResult, eventDivisions);
        organizationDivision = await getOrganizationDivisionForBacklogResult(currentEventResult);
        if (eventClasses) eventClass = await getEventClassForBacklogResult(currentEventResult, eventClasses);
        organizationClass = await getOrganizationClassForBacklogResult(currentEventResult);

        const currentPointsEarned = await getPointsFromBacklogEventResult(currentEventResult, eventClass, organizationClass, eventDivision, organizationDivision);  

        const formattedResult: FormattedEventPointData = {
            resultId: currentEventResult.id,
            backlogResult: currentEventResult,
            eventName: currentEvent.name || "",
            eventId: currentEvent.id,
            place: place,
            pointsEarned: currentPointsEarned === 0 ? "" : currentPointsEarned.toString(),
            eventClassNumber: eventClass?.number ? eventClass?.number.toString() : "",
            eventClassName: eventClass?.name || "",
            eventDivisionNumber: eventDivision?.number ? eventDivision?.number.toString() : "",
            eventDivisionName: eventDivision?.name || "",
            horseName: horseName,
            ownerName: ownerName,
            riderName: riderName,
            backNumber: backNumber.toString(),
            memberId: membershipId,
            showsAttended: showsAttended
        };
        await updateEventMapData(formattedResult, currentEvent);
    }

    const tableDataArray: TableData[] | undefined = eventResultsMap.get(event.id);
    return tableDataArray;
}

const getOrganizationMembers = async (organizationId: string, selectedYear?: string) => {
    const queryResult = await getMembershipsByOrganizationId(organizationId);
    if (queryResult.isSuccess) {
        let memberships: Membership[] = queryResult.result;
        if (memberships && memberships.length > 0) {
            const currentYear = selectedYear || moment(new Date()).format("YYYY");
            memberships.forEach((member: Membership) => {
                // Check that the backNumber has been set and that the membership is current
                if (member.backNumber && member.createdOn.includes(currentYear)) memberMap.set(member.backNumber, member);
            });
        }
    }
}

export async function getOrganizationEvents(organizationId: string, selectedYear?: string) {
    let events: Event[] = [];
    const currentYear = moment(new Date()).format("YYYY");
    const queryResult = await getEventsByOrganizationIdByYear(organizationId, selectedYear || currentYear);
    if (queryResult.isSuccess) {
        events = queryResult.result;
    }
    return events;
}

export async function getEventResults(eventId: string) {
    let eventResults: EventResult[] = [];
    const queryResult = await getEventResultsByEventId(eventId);
    if (queryResult.isSuccess) {
        eventResults = queryResult.result;
    } else {
        eventResults = queryResult.result;
    }
    return eventResults;
}

export async function getBacklogEventResults(eventId: string) {
    let eventResults: BacklogEventResult[] = [];
    const queryResult = await getBacklogEventResultsByEventId(eventId);
    if (queryResult.isSuccess) {
        eventResults = queryResult.result;
    }
    return eventResults;
}

export async function getRiderName(currentEventResult: EventResult) {
    let currentRiderName = "";
    let eventClassEntry = currentEventResult.eventClassEntry;
    if (eventClassEntry) {
        if (eventClassEntry.rider?.name) currentRiderName = eventClassEntry.rider?.name.trim();
        else {
            const queryEventClassEntryResult = await getEventClassEntryById(eventClassEntry.id);
            if (queryEventClassEntryResult.isSuccess) {
                const foundEventClassEntry: EventClassEntry = queryEventClassEntryResult.result;
                if (foundEventClassEntry) {
                    eventClassEntry = foundEventClassEntry;
                    if (!currentRiderName) currentRiderName = foundEventClassEntry.rider?.name.trim() || "";
                }
            }
        }
    }
    return currentRiderName;
}

export async function getPointsFromEventResult(currentEventResult: EventResult, orgClass?: OrganizationClass, orgDivision?: OrganizationDivision) {
    const eventClass = currentEventResult.eventClass;
    const eventDivision = currentEventResult.eventDivision;
    let organizationClass: OrganizationClass | undefined = orgClass || await getOrganizationClass(currentEventResult);
    let organizationDivision: OrganizationDivision | undefined = orgDivision || await getOrganizationDivision(currentEventResult);

    let pointTable: PointTable | null | undefined = undefined;
    if (organizationDivision && organizationDivision.pointTable) {
        pointTable = organizationDivision.pointTable;
    } else if (organizationClass && organizationClass.pointTable) {
        pointTable = organizationClass.pointTable;
    } else if (eventClass && eventClass.pointTable) {
        pointTable = eventClass.pointTable;
    } else if (eventDivision && eventDivision.pointTable) {
        pointTable = eventDivision.pointTable;
    } 

    let currentPointsEarned = 0;
    if (currentEventResult.place && pointTable) {
        let placeValue = currentEventResult.place;
        // Check if this is a champion result
        if (currentEventResult.eventDivision && placeValue === 1) {
            placeValue = -1;
        } else if (currentEventResult.eventDivision && placeValue === 2) {
            placeValue = -2;
        }
        const calculatedPointsEarned = calculatePointsFromTable(placeValue, pointTable);
        if (calculatedPointsEarned) currentPointsEarned = calculatedPointsEarned;
    }
    return currentPointsEarned;
}

export async function getPointsFromBacklogEventResult(currentEventResult: BacklogEventResult, evntClass?: EventClass, orgClass?: OrganizationClass, evntDiv?: EventDivision, orgDivision?: OrganizationDivision) {
    if (currentEventResult.pointsEarned) {
        return currentEventResult.pointsEarned;
    }
    
    let eventClass: EventClass | undefined = evntClass;
    let eventDivision: EventDivision | undefined = evntDiv;
    let organizationClass: OrganizationClass | undefined = orgClass;
    let organizationDivision: OrganizationDivision | undefined = orgDivision;
    
    // if (!eventDivision) eventDivision = await getEventDivisionForBacklogResult(currentEventResult, eventDivisions);
    if (!organizationDivision) organizationDivision = await getOrganizationDivisionForBacklogResult(currentEventResult);
    // if (!eventClass) eventClass = await getEventClassForBacklogResult(currentEventResult, eventClasses);
    if (!organizationClass) organizationClass = await getOrganizationClassForBacklogResult(currentEventResult);


    let pointTable: PointTable | null | undefined = undefined;
    if (organizationDivision) {
        pointTable = organizationDivision.pointTable;
    } else if (organizationClass) {
        pointTable = organizationClass.pointTable;
    } else if (eventClass) {
        pointTable = eventClass.pointTable;
    } else if (eventDivision) {
        pointTable = eventDivision.pointTable;
    } 


    let currentPointsEarned = 0;
    if (currentEventResult.place && pointTable) {
        let placeValue = currentEventResult.place;
        // Check if this is a champion results - might not have any champ results in RHSC backlog
        // if (currentEventResult.eventDivisionId && placeValue === 1) {
        //     placeValue = -1;
        // } else if (currentEventResult.eventDivisionId && placeValue === 2) {
        //     placeValue = -2;
        // }
        const calculatedPointsEarned = calculatePointsFromTable(placeValue, pointTable);
        if (calculatedPointsEarned) currentPointsEarned = calculatedPointsEarned;
    }

    return currentPointsEarned;
}

export async function getOrganizationDivision(currentEventResult: EventResult) {
    let organizationDivision: OrganizationDivision | undefined = undefined;
    const currentEventDivision = currentEventResult.eventDivision;
    let organizationDivisionId = currentEventDivision?.organizationDivisionId;
    if (!organizationDivisionId) {
        const currentEventClass = currentEventResult.eventClass;
        organizationDivisionId = currentEventClass?.organizationDivisionId;
    }
    if (organizationDivisionId) {
        const mapResult = organizationDivisionMap.get(organizationDivisionId);
        if (mapResult) {
            organizationDivision = mapResult;
        } else {
            const queryDivisionResult = await getOrganizationDivisionById(organizationDivisionId);
            if (queryDivisionResult.isSuccess) {
                const foundOrganizationDivision = queryDivisionResult.result;
                if (foundOrganizationDivision) {
                    organizationDivision = foundOrganizationDivision;
                    organizationDivisionMap.set(organizationDivisionId, foundOrganizationDivision);
                }
            }
        }
    }
    return organizationDivision;
}

export async function getOrganizationClass(currentEventResult: EventResult) {
    let organizationClass: OrganizationClass | undefined = undefined;
    const currentEventClass = currentEventResult.eventClass;
    const organizationClassId = currentEventClass?.organizationClassId;
    if (organizationClassId) {
        const mapResult = organizationClassMap.get(organizationClassId);
        if (mapResult) {
            organizationClass = mapResult;
        } else {
            const queryClassResult = await getOrganizationClassById(organizationClassId);
            if (queryClassResult.isSuccess) {
                const foundOrganizationClass = queryClassResult.result;
                if (foundOrganizationClass) {
                    organizationClass = foundOrganizationClass;
                    organizationClassMap.set(organizationClassId, foundOrganizationClass);
                }
            }
        }
    }
    return organizationClass;
}

export async function getEventDivisionForBacklogResult(currentEventResult: BacklogEventResult, eventDivisions: EventDivision[]) {
    let eventDivision: EventDivision | undefined = undefined;
    const currentEventDivisionId = currentEventResult.eventDivisionId;
    if (currentEventDivisionId) {
        eventDivision = eventDivisions.find(ed => ed.id === currentEventDivisionId);
    }
    return eventDivision;
}

export async function getOrganizationDivisionForBacklogResult(currentEventResult: BacklogEventResult) {
    let organizationDivision: OrganizationDivision | undefined = undefined;
    const organizationDivisionId = currentEventResult.organizationDivisionId;
    if (organizationDivisionId) {
        const mapResult = organizationDivisionMap.get(organizationDivisionId);
        if (mapResult) {
            organizationDivision = mapResult;
        } else {
            const queryDivisionResult = await getOrganizationDivisionById(organizationDivisionId);
            if (queryDivisionResult.isSuccess) {
                const foundOrganizationDivision = queryDivisionResult.result;
                if (foundOrganizationDivision) {
                    organizationDivision = foundOrganizationDivision;
                    organizationDivisionMap.set(organizationDivisionId, foundOrganizationDivision);
                }
            }
        }
    }
    return organizationDivision;
}

export async function getEventClassForBacklogResult(currentEventResult: BacklogEventResult, eventClasses: EventClass[]) {
    let eventClass: EventClass | undefined = undefined;
    const currentEventClassId = currentEventResult.eventClassId;
    if (currentEventClassId) {
        eventClass = eventClasses.find(ec => ec.id === currentEventClassId);
    }
    return eventClass;
}

export async function getOrganizationClassForBacklogResult(currentEventResult: BacklogEventResult) {
    let organizationClass: OrganizationClass | undefined = undefined;
    const organizationClassId = currentEventResult.organizationClassId;
    if (organizationClassId) {
        const mapResult = organizationClassMap.get(organizationClassId);
        if (mapResult) {
            organizationClass = mapResult;
        } else {
            const queryClassResult = await getOrganizationClassById(organizationClassId);
            if (queryClassResult.isSuccess) {
                const foundOrganizationClass = queryClassResult.result;
                if (foundOrganizationClass) {
                    organizationClass = foundOrganizationClass;
                    organizationClassMap.set(organizationClassId, foundOrganizationClass);
                }
            }
        }
    }
    return organizationClass;
}

async function updateEventMapData(formattedResult: FormattedEventPointData, event: Event) {
    const newTableData: TableData = {
        className: formattedResult.eventClassName || "",
        classNumber: formattedResult.eventClassNumber || "",
        divisionName: formattedResult.eventDivisionName || "",
        divisionNumber: formattedResult.eventDivisionNumber || "",
        eventData: [formattedResult]
    };

    // First, check which event this is
    const eventMapResult = eventResultsMap.get(event.id);

    // Set up the array for the updates
    let updatedTableArray: TableData[] = []

    if (eventMapResult) {
        // Already has some tables for this event. Try to find the table for this class
        const eventTableData = eventMapResult;
        const currentEventClassNumber = formattedResult.eventClassNumber;
        const tableDataIndex = eventTableData.findIndex((td) => td.classNumber === currentEventClassNumber);
        if (tableDataIndex > -1) {
            const oldTableData = eventTableData[tableDataIndex];
            const oldEventData = oldTableData.eventData;
            const updatedEventData = oldEventData?.concat([formattedResult]);
            const sortedData = updatedEventData?.sort((a, b) => (a.place ? parseInt(a.place) : 999) - (b.place ? parseInt(b.place) : 999));
            let updatedTableData = newTableData;
            if (sortedData) {
                updatedTableData = {
                    ...newTableData,
                    eventData: sortedData
                }
            }

            // Found the class' table. Update it to the updatedTable
            updatedTableArray = [
                ...eventTableData.slice(0, tableDataIndex),
                updatedTableData,
                ...eventTableData.slice(tableDataIndex + 1)
            ];
        } else {
            // This class doesn't have a table in the event map yet. Add it.
            updatedTableArray= eventTableData.concat([newTableData]);
        }
    } else {
        // If no data for this event is in the map yet, add this table in as the first entry
        updatedTableArray = [newTableData];
    }

    eventResultsMap.set(event.id, updatedTableArray);
}

async function updateYearlyMapData(formattedResult: FormattedEventPointData, organizationClass?: OrganizationClass, organizationDivision?: OrganizationDivision) {
    // If organization division
    if (organizationDivision && organizationDivision.name) {
        await updateYearlyMapDataForDivision(formattedResult, organizationDivision);
    } // If Organization Class 
    else if (organizationClass && organizationClass.name) {
        await updateYearlyMapDataForClass(formattedResult, organizationClass);
    }
}

async function updateYearlyMapDataForDivision(formattedResult: FormattedEventPointData, organizationDivision?: OrganizationDivision) {
    const currentEventResult = formattedResult.result;
    const currentBacklogEventResult = formattedResult.backlogResult;
    const backNumber: string = formattedResult.backNumber || "0";
    const memberId = formattedResult.memberId;
    const membership = memberMap.get(parseInt(backNumber));
    const currentPointsEarned = parseFloat(formattedResult.pointsEarned);
    const currentShowsAttended = formattedResult.showsAttended;

    let updatedTableData: TableData | undefined = undefined;

    // If organization division
    if (organizationDivision && organizationDivision.name) {
        // Update Yearly Info via the Organization Division
        const organizationDivisionName = organizationDivision.name;
        const organizationDivisionResult = organizationDivisionResultsMap.get(organizationDivisionName);

        // Update the accumulated yearly point data
        const newData: FormattedAccumulatedPointData = {
            eventDivisionNumber: organizationDivision.number ? organizationDivision.number.toString() : "",
            eventDivisionName: organizationDivisionName,
            barnName: currentEventResult?.entry.barnName || currentEventResult?.entry.barn?.name || "",
            horseName: currentEventResult?.entry.horseName  || currentBacklogEventResult?.horseName || "",
            ownerName: currentEventResult?.entry.ownerName || "",
            riderName: formattedResult.riderName,
            backNumber: backNumber,
            memberId: memberId,
            showsAttended: currentShowsAttended || "",
            pointsEarned: currentPointsEarned.toString(),
            meetingsAttended: membership?.meetingsAttended ? membership.meetingsAttended.toString() : "",
            volunteerHours: membership?.volunteerHours ? membership.volunteerHours.toString() : "",
            eventResults: formattedResult.result ? [formattedResult.result] : [],
            backlogResults: formattedResult.backlogResult ? [formattedResult.backlogResult] : [],
        };
        if (organizationDivisionResult?.allEventData) {
            const allEventData: FormattedAccumulatedPointData[] = organizationDivisionResult.allEventData;
            if (allEventData && allEventData.length > 0) {
                let matchingData: FormattedAccumulatedPointData | undefined = undefined;
                let matchingDataIndex: number = -1;
                // For RHSC, check based on the entry back number
                if (organizationDivision.organizationId === "ba239ae1-f8ee-4bb7-bdf1-8a089f49746e") {
                    for (let j = 0; j < allEventData.length; j++) {
                        const currentData: FormattedAccumulatedPointData = allEventData[j];
                        if (currentData.backNumber === formattedResult.backNumber) {
                            // Found the matching data - update it
                            matchingData = currentData;
                            matchingDataIndex = j;
                        }
                    }
                } else {
                    for (let j = 0; j < allEventData.length; j++) {
                        const currentData: FormattedAccumulatedPointData = allEventData[j];
                        const currentHorseName = currentData?.horseName ? currentData?.horseName.trim() : "";
                        const currentRiderName = currentData?.riderName ? currentData?.riderName.trim() : "";
                        if (currentHorseName === formattedResult.horseName && currentRiderName === formattedResult.riderName) {
                            // Found the matching data - update it
                            matchingData = currentData;
                            matchingDataIndex = j;
                        }
                    }
                }
                
                if (matchingData) {
                    // Found the matching data - update it
                    const pointsEarned = matchingData.pointsEarned ? (parseInt(matchingData.pointsEarned) + currentPointsEarned).toString() : currentPointsEarned.toString();
                    const eventResultsArray = matchingData.eventResults ? matchingData.eventResults.concat(newData?.eventResults ? newData?.eventResults : []) : (newData?.eventResults ? newData?.eventResults : []);
                    const backlogResultsArray = matchingData.backlogResults ? matchingData.backlogResults.concat(newData?.backlogResults ? newData?.backlogResults : []) : (newData?.backlogResults ? newData?.backlogResults : []);
                    const updatedData: FormattedAccumulatedPointData = {
                        ...matchingData,
                        pointsEarned: pointsEarned,
                        eventResults: eventResultsArray,
                        backlogResults: backlogResultsArray
                    };
                    const updatedDataArray: FormattedAccumulatedPointData[] = [
                        ...allEventData.slice(0, matchingDataIndex),
                        updatedData,
                        ...allEventData.slice(matchingDataIndex + 1)
                    ];
                    updatedTableData = {
                        ...organizationDivisionResult,
                        allEventData: updatedDataArray
                    };
                } else {
                    // Could not find the matching data - create it
                    const updatedDataArray: FormattedAccumulatedPointData[] = allEventData.concat([newData]);
                    const sortedDataArray = updatedDataArray.sort((a, b) => parseFloat(a.pointsEarned) - parseFloat(b.pointsEarned));
                    updatedTableData = {
                        ...organizationDivisionResult,
                        allEventData: sortedDataArray
                    };
                }
            }
        } else {
            // Map needs a new entry for this division
            const updatedDataArray: FormattedAccumulatedPointData[] = [newData];
            updatedTableData = {
                divisionNumber: organizationDivision.number ? organizationDivision.number.toString() : "",
                divisionName: organizationDivisionName,
                className: formattedResult.eventClassName || "",
                classNumber: formattedResult.eventClassNumber || "",
                allEventData: updatedDataArray
            };
        }
        
        if (updatedTableData) {
            organizationDivisionResultsMap.set(organizationDivisionName, updatedTableData);
        }
    }
}

async function updateYearlyMapDataForClass(formattedResult: FormattedEventPointData, organizationClass?: OrganizationClass) {
    const currentEventResult = formattedResult.result;
    const currentBacklogEventResult = formattedResult.backlogResult;
    const backNumber: string = formattedResult.backNumber || "0";
    const memberId = formattedResult.memberId;
    const membership = memberMap.get(parseInt(backNumber));
    const currentPointsEarned = parseFloat(formattedResult.pointsEarned);
    const currentShowsAttended = formattedResult.showsAttended;

    let updatedTableData: TableData | undefined = undefined;

    if (organizationClass && organizationClass.name) {
        const organizationClassName = organizationClass.name;
        const organizationClassResult = organizationClassResultsMap.get(organizationClassName);

        // Update the accumulated yearly point data
        const newData: FormattedAccumulatedPointData = {
            eventClassNumber: organizationClass.number ? organizationClass.number.toString() : "",
            eventClassName: organizationClassName,
            barnName: currentEventResult?.entry.barnName || currentEventResult?.entry.barn?.name || "",
            horseName: currentEventResult?.entry.horseName  || currentBacklogEventResult?.horseName || "",
            ownerName: currentEventResult?.entry.ownerName || "",
            riderName: formattedResult.riderName,
            backNumber: backNumber,
            memberId: memberId,
            showsAttended: currentShowsAttended || "",
            pointsEarned: currentPointsEarned.toString(),
            meetingsAttended: membership?.meetingsAttended ? membership.meetingsAttended.toString() : "",
            volunteerHours: membership?.volunteerHours ? membership.volunteerHours.toString() : "",
            eventResults: formattedResult.result ? [formattedResult.result] : [],
            backlogResults: formattedResult.backlogResult ? [formattedResult.backlogResult] : [],
        };
        if (organizationClassResult?.allEventData) {
            const allEventData: FormattedAccumulatedPointData[] = organizationClassResult.allEventData;
            if (allEventData && allEventData.length > 0) {
                let matchingData: FormattedAccumulatedPointData | undefined = undefined;
                let matchingDataIndex: number = -1;
                if (organizationClass.organizationId === "ba239ae1-f8ee-4bb7-bdf1-8a089f49746e") {
                    for (let j = 0; j < allEventData.length; j++) {
                        const currentData: FormattedAccumulatedPointData = allEventData[j];
                        if (currentData.backNumber === formattedResult.backNumber) {
                            // Found the matching data - update it
                            matchingData = currentData;
                            matchingDataIndex = j;
                        }
                    }
                } else {
                    for (let j = 0; j < allEventData.length; j++) {
                        const currentData: FormattedAccumulatedPointData = allEventData[j];
                        const currentHorseName = currentData?.horseName ? currentData?.horseName.trim() : "";
                        const currentRiderName = currentData?.riderName ? currentData?.riderName.trim() : "";
                        if (currentHorseName === formattedResult.horseName && currentRiderName === formattedResult.riderName) {
                            // Found the matching data - update it
                            matchingData = currentData;
                            matchingDataIndex = j;
                        }
                    }
                }
                if (matchingData) {
                    // Found the matching data - update it
                    const showsAttended = matchingData.showsAttended ? (parseInt(matchingData.showsAttended) + 1).toString() : "1";
                    const pointsEarned = (matchingData.pointsEarned && matchingData.pointsEarned !== "") ? (parseInt(matchingData.pointsEarned) + currentPointsEarned).toString() : currentPointsEarned.toString();
                    const eventResultsArray = matchingData.eventResults ? matchingData.eventResults.concat(newData?.eventResults ? newData?.eventResults : []) : (newData?.eventResults ? newData?.eventResults : []);
                    const backlogResultsArray = matchingData.backlogResults ? matchingData.backlogResults.concat(newData?.backlogResults ? newData?.backlogResults : []) : (newData?.backlogResults ? newData?.backlogResults : []);
                    const updatedData: FormattedAccumulatedPointData = {
                        ...matchingData,
                        pointsEarned: pointsEarned,
                        eventResults: eventResultsArray,
                        backlogResults: backlogResultsArray,
                        showsAttended: showsAttended
                    };
                    const updatedDataArray: FormattedAccumulatedPointData[] = [
                        ...allEventData.slice(0, matchingDataIndex),
                        updatedData,
                        ...allEventData.slice(matchingDataIndex + 1)
                    ];
                    updatedTableData = {
                        ...organizationClassResult,
                        allEventData: updatedDataArray
                    };
                } else {
                    // No matching data - create it
                    const updatedDataArray: FormattedAccumulatedPointData[] = allEventData.concat([newData]);
                    const sortedDataArray = updatedDataArray.sort((a, b) => parseFloat(a.pointsEarned) - parseFloat(b.pointsEarned));
                    updatedTableData = {
                        ...organizationClassResult,
                        allEventData: sortedDataArray
                    };
                }
            }
        } else {
            // Map needs a new entry for this class
            const updatedDataArray: FormattedAccumulatedPointData[] = [newData];
            updatedTableData = {
                classNumber: organizationClass?.number ? organizationClass.number.toString() : "",
                className: organizationClassName,
                allEventData: updatedDataArray
            };
        }

        if (updatedTableData) {
            organizationClassResultsMap.set(organizationClassName, updatedTableData);
        }
    }
}

export async function getOrganizationClassYearlyResults(organizationClassId: string, selectedYear?: string) {
    organizationDivisionMap.clear();
    organizationClassMap.clear();
    memberMap.clear();
    organizationDivisionResultsMap.clear();
    organizationClassResultsMap.clear();
    eventResultsMap.clear();
    eventClassResultsMap.clear();
    
    const classQuery = await getEventClassesByOrganizationClassId(organizationClassId);
    if (classQuery.isSuccess) {
        const eventClasses: EventClass[] = classQuery.result;

        for (let i = 0; i < eventClasses.length; i++) {
            const currentEventClass = eventClasses[i];

            const currentEventId = currentEventClass.eventId;

            const resultsQuery = await getEventResultsByEventClassId(currentEventClass.id);
            if (resultsQuery.isSuccess) {
                const eventResults: EventResult[] = resultsQuery.result;

                for (let j = 0; j < eventResults.length; j++) {
                    const currentEventResult: EventResult = eventResults[j];
        
                    const riderName: string = await getRiderName(currentEventResult);
                    const horseName: string = currentEventResult.entry.horseName || "";
                    const ownerName: string = currentEventResult.entry.ownerName || "";
                    const backNumber: number = currentEventResult.entry.number || 0;
                    const membership: Membership | undefined = memberMap.get(backNumber);
                    const membershipId: string = membership?.membershipId || "";
                    const showsAttended: string = membership?.showsAttended ? membership.showsAttended.toString() : "";
                    const place = currentEventResult.place ? currentEventResult.place.toString() : "";
        
                    const eventClass = currentEventResult.eventClass;
                    let organizationClass: OrganizationClass | undefined = await getOrganizationClass(currentEventResult);

                    const currentPointsEarned = await getPointsFromEventResult(currentEventResult, organizationClass);
                    
                    const formattedResult: FormattedEventPointData = {
                        resultId: currentEventResult.id,
                        result: currentEventResult,
                        eventName: "", //currentEvent?.name || "",
                        eventId: currentEventId,
                        place: place,
                        pointsEarned: currentPointsEarned === 0 ? "0" : currentPointsEarned.toString(),
                        eventClassNumber: eventClass?.number ? eventClass?.number.toString() : "",
                        eventClassName: eventClass?.name || "",
                        horseName: horseName,
                        ownerName: ownerName,
                        riderName: riderName,
                        backNumber: backNumber.toString(),
                        memberId: membershipId,
                        showsAttended: showsAttended
                    };
                    await updateYearlyMapData(formattedResult, organizationClass);
                }
            }

            const backlogEventResultsQuery = await getBacklogEventResultsByEventClassId(currentEventClass.id);
            if (backlogEventResultsQuery.isSuccess) {
                const backlogEventResults: BacklogEventResult[] = backlogEventResultsQuery.result;

                for (let j = 0; j < backlogEventResults.length; j++) {
                    const currentEventResult: BacklogEventResult = backlogEventResults[j];
        
                    const backNumber: number = currentEventResult.organizationMemberId ? parseInt(currentEventResult.organizationMemberId) : 0;
                    const membership: Membership | undefined = memberMap.get(backNumber);
        
                    let currentRiderName = currentEventResult.riderName || "";
                    if (currentRiderName === "") {
                        currentRiderName = membership?.personName || ""; 
                    }
        
                    let currentRiderHorse = currentEventResult.horseName || "";
                    if (currentRiderHorse === "") {
                        currentRiderHorse = membership?.horse?.name || ""; 
                    }
        
                    const riderName: string = currentRiderName;
                    const horseName: string = currentRiderHorse;
                    const ownerName: string = "";
                    const membershipId: string = membership?.membershipId || "";
                    const showsAttended: string = membership?.showsAttended ? membership.showsAttended.toString() : "";
                    const place = currentEventResult.place ? currentEventResult.place.toString() : "";
        
                    let eventClass: EventClass | undefined = undefined;
                    let organizationClass: OrganizationClass | undefined = undefined;
                    
                    eventClass = await getEventClassForBacklogResult(currentEventResult, eventClasses);
                    organizationClass = await getOrganizationClassForBacklogResult(currentEventResult);
        
                    const currentPointsEarned = await getPointsFromBacklogEventResult(currentEventResult, eventClass, organizationClass);
                    
                    const formattedResult: FormattedEventPointData = {
                        resultId: currentEventResult.id,
                        backlogResult: currentEventResult,
                        eventName: "", //currentEvent.name || "",
                        eventId: currentEventId,
                        place: place,
                        pointsEarned: currentPointsEarned === 0 ? "0" : currentPointsEarned.toString(),
                        eventClassNumber: eventClass?.number ? eventClass?.number.toString() : "",
                        eventClassName: eventClass?.name || "",
                        horseName: horseName,
                        ownerName: ownerName,
                        riderName: riderName,
                        backNumber: backNumber.toString(),
                        memberId: membershipId,
                        showsAttended: showsAttended
                    };
                    
                    await updateYearlyMapData(formattedResult, organizationClass);
                }
            }
        }
    }
    // After getting all of the data, sort it and return it
    let allDataArray: FormattedAccumulatedPointData[][] = [];
    organizationClassResultsMap.forEach(value => {
        const array = value.allEventData;
        if (array) {
            // Sort the entries from most points earned to least points earned
            const sorted = array?.sort((a: FormattedAccumulatedPointData, b: FormattedAccumulatedPointData) => (b.pointsEarned ? parseFloat(b.pointsEarned) : 0) - (a.pointsEarned ? parseFloat(a.pointsEarned) : 0));
            allDataArray.push(sorted);
        }
    });
    // Sort the organization classs and classes by smallest number to biggest number
    const sorted = allDataArray.sort((a, b) => (a[0] ? (a[0].eventClassNumber) ? parseInt(a[0].eventClassNumber) : (a[0].eventClassNumber ? parseInt(a[0].eventClassNumber) : 0) : 0) - (b[0] ? (b[0].eventClassNumber) ? parseInt(b[0].eventClassNumber) : (b[0].eventClassNumber ? parseInt(b[0].eventClassNumber) : 0) : 0));

    const formattedResult: FormattedPointData = {
        yearlyData: (sorted || allDataArray)
    };

    return formattedResult;
}

export async function getYearlyPointDataByOrganizationDivisionId(organizationDivision: OrganizationDivision, organizationId: string, selectedYear?: string) {
    organizationDivisionMap.clear();
    organizationClassMap.clear();
    memberMap.clear();
    organizationDivisionResultsMap.clear();
    organizationClassResultsMap.clear();
    eventResultsMap.clear();
    eventClassResultsMap.clear();

    // First, populate the member map so we can go back later and use this info
    await getOrganizationMembers(organizationId, selectedYear);
    
    const events: Event[] = await getOrganizationEvents(organizationId, selectedYear);

    for (let i = 0; i < events.length; i++) {
        const currentEvent = events[i];

        // To do: determine if should get event divisions by name
        let eventDivisions: EventDivision[] | undefined = undefined;
        const eventDivisionResult = await getEventDivisionsByOrganizationDivisionId(organizationDivision.id);
        if (eventDivisionResult.isSuccess) {
            eventDivisions = eventDivisionResult.result;
        }
        const eventDivision = (eventDivisions && eventDivisions[0]) ? eventDivisions[0] : undefined;

        let eventResults: EventResult[] = [];
        if (eventDivision) {
            const divisionResults = await getEventResultsByEventDivisionId(eventDivision.id);
            if (divisionResults.isSuccess) {
                const divisionResultArray: EventResult[] = divisionResults.result;
                if (divisionResultArray && divisionResultArray.length > 0) {
                    eventResults = eventResults.concat(divisionResultArray);
                }
            }
        }

        let eventClasses: EventClass[] | undefined = undefined;
        if (eventDivision) {
            const eventClassResult = await getEventClassesByEventDivisionId(eventDivision.id);
            if (eventClassResult.isSuccess) {
                eventClasses = eventClassResult.result;

                if (eventClasses && eventClasses.length > 0) {
                    for (let j = 0; j < eventClasses.length; j++) {
                        const eventClass = eventClasses[j];
                        
                        const resultQuery = await getEventResultsByEventClassId(eventClass.id);
                        if (resultQuery.isSuccess) {
                            const eventClassResults: EventResult[] = resultQuery.result;
                            if (eventClassResults && eventClassResults.length > 0) {
                                eventResults = eventResults.concat(eventClassResults);
                            }
                        }
                    }
                }
            }
        }

        for (let j = 0; j < eventResults.length; j++) {
            const currentEventResult: EventResult = eventResults[j];

            const eventClass = currentEventResult.eventClass;
            const eventDivision = currentEventResult.eventDivision;
            let organizationClass: OrganizationClass | undefined = await getOrganizationClass(currentEventResult);

            const backNumber: number = currentEventResult.entry.number || 0;
            const membership: Membership | undefined = memberMap.get(backNumber);

            let currentRiderName = await getRiderName(currentEventResult) || "";
            if (currentRiderName === "") {
                currentRiderName = membership?.personName || ""; 
            }

            let currentHorseName = currentEventResult.entry.horseName || "";
            if (currentHorseName === "") {
                currentHorseName = membership?.horse?.name || ""; 
            }

            const riderName: string = currentRiderName;
            const horseName: string = currentHorseName;
            const ownerName: string = currentEventResult.entry.ownerName || "";
            const membershipId: string = membership?.membershipId || "";
            const showsAttended: string = membership?.showsAttended ? membership.showsAttended.toString() : "";
            const place = currentEventResult.place ? currentEventResult.place.toString() : "";
            const currentPointsEarned = await getPointsFromEventResult(currentEventResult);
            
            const formattedResult: FormattedEventPointData = {
                resultId: currentEventResult.id,
                result: currentEventResult,
                eventName: currentEvent.name || "",
                eventId: currentEvent.id,
                place: place,
                pointsEarned: currentPointsEarned === 0 ? "0" : currentPointsEarned.toString(),
                eventClassNumber: eventClass?.number ? eventClass?.number.toString() : "",
                eventClassName: eventClass?.name || "",
                eventDivisionNumber: eventDivision?.number ? eventDivision?.number.toString() : "",
                eventDivisionName: eventDivision?.name || "",
                horseName: horseName,
                ownerName: ownerName,
                riderName: riderName,
                backNumber: backNumber.toString(),
                memberId: membershipId,
                showsAttended: showsAttended
            };
            await updateYearlyMapData(formattedResult, organizationClass, organizationDivision);
        }

        const backlogEventResults = await getBacklogEventResults(currentEvent.id);  

        const filteredBacklogEventResults = backlogEventResults.filter(ber => ber.organizationDivisionId === organizationDivision.id);   
        for (let j = 0; j < filteredBacklogEventResults.length; j++) {
            const currentEventResult: BacklogEventResult = filteredBacklogEventResults[j];

            const backNumber: number = currentEventResult.organizationMemberId ? parseInt(currentEventResult.organizationMemberId) : 0;
            const membership: Membership | undefined = memberMap.get(backNumber);

            let currentRiderName = currentEventResult.riderName || "";
            if (currentRiderName === "") {
                currentRiderName = membership?.personName || ""; 
            }

            let currentHorseName = currentEventResult.horseName || "";
            if (currentHorseName === "") {
                currentHorseName = membership?.horse?.name || ""; 
            }

            const riderName: string = currentRiderName;
            const horseName: string = currentHorseName;
            const ownerName: string = "";
            const membershipId: string = membership?.membershipId || "";
            const showsAttended: string = membership?.showsAttended ? membership.showsAttended.toString() : "";
            let place = currentEventResult.place ? currentEventResult.place.toString() : "";

            // if (!place) {
            //     if (currentEventResult.pointsEarned) {
            //         place = getPlace(currentEventResult.pointsEarned.toString());
            //     }
            // }

            let eventClass: EventClass | undefined = undefined;
            let eventDivision: EventDivision | undefined = undefined;
            let organizationClass: OrganizationClass | undefined = undefined;
            let organizationDivision: OrganizationDivision | undefined = undefined;
            
            if (eventDivisions) eventDivision = await getEventDivisionForBacklogResult(currentEventResult, eventDivisions);
            organizationDivision = await getOrganizationDivisionForBacklogResult(currentEventResult);
            if (eventClasses) eventClass = await getEventClassForBacklogResult(currentEventResult, eventClasses);
            organizationClass = await getOrganizationClassForBacklogResult(currentEventResult);

            const currentPointsEarned = await getPointsFromBacklogEventResult(currentEventResult, eventClass, organizationClass, eventDivision, organizationDivision);  

            const formattedResult: FormattedEventPointData = {
                resultId: currentEventResult.id,
                backlogResult: currentEventResult,
                eventName: currentEvent.name || "",
                eventId: currentEvent.id,
                place: place,
                pointsEarned: currentPointsEarned === 0 ? "0" : currentPointsEarned.toString(),
                eventClassNumber: eventClass?.number ? eventClass?.number.toString() : "",
                eventClassName: eventClass?.name || "",
                eventDivisionNumber: eventDivision?.number ? eventDivision?.number.toString() : "",
                eventDivisionName: eventDivision?.name || "",
                horseName: horseName,
                ownerName: ownerName,
                riderName: riderName,
                backNumber: backNumber.toString(),
                memberId: membershipId,
                showsAttended: showsAttended
            };
            await updateYearlyMapData(formattedResult, organizationClass, organizationDivision);
        }
    }

    // After getting all of the data, sort it and return it
    let allDataArray: FormattedAccumulatedPointData[][] = [];
    organizationDivisionResultsMap.forEach(value => {
        const array = value.allEventData;
        if (array) {
            // Sort the entries from most points earned to least points earned
            const sorted = array?.sort((a: FormattedAccumulatedPointData, b: FormattedAccumulatedPointData) => (b.pointsEarned ? parseFloat(b.pointsEarned) : 0) - (a.pointsEarned ? parseFloat(a.pointsEarned) : 0));
            allDataArray.push(sorted);
        }
    });
    organizationClassResultsMap.forEach(value => {
        const array = value.allEventData;
        if (array) {
            // Sort the entries from most points earned to least points earned
            const sorted = array?.sort((a: FormattedAccumulatedPointData, b: FormattedAccumulatedPointData) => (b.pointsEarned ? parseFloat(b.pointsEarned) : 0) - (a.pointsEarned ? parseFloat(a.pointsEarned) : 0));
            allDataArray.push(sorted);
        }
    });
    // Sort the organization divisions and classes by smallest number to biggest number
    const sorted = allDataArray.sort((a, b) => (a[0] ? (a[0].eventClassNumber) ? parseInt(a[0].eventClassNumber) : (a[0].eventDivisionNumber ? parseInt(a[0].eventDivisionNumber) : 0) : 0) - (b[0] ? (b[0].eventClassNumber) ? parseInt(b[0].eventClassNumber) : (b[0].eventDivisionNumber ? parseInt(b[0].eventDivisionNumber) : 0) : 0));

    const formattedResult: FormattedPointData = {
        yearlyData: (sorted || allDataArray),
    };

    return formattedResult;
}

export async function getYearlyPointDataByOrganizationClassId(organizationClass: OrganizationClass, organizationId: string, selectedYear?: string) {
    organizationDivisionMap.clear();
    organizationClassMap.clear();
    memberMap.clear();
    organizationDivisionResultsMap.clear();
    organizationClassResultsMap.clear();
    eventResultsMap.clear();
    eventClassResultsMap.clear();


    // First, populate the member map so we can go back later and use this info
    await getOrganizationMembers(organizationId, selectedYear);
    
    const events: Event[] = await getOrganizationEvents(organizationId, selectedYear);

    for (let i = 0; i < events.length; i++) {
        const currentEvent = events[i];

        let eventClasses: EventClass[] | undefined = undefined;
        let eventResults: EventResult[] = [];
        const eventClassResult = await getEventClassesByEventIdWithResults(currentEvent.id);
        if (eventClassResult.isSuccess) {
            eventClasses = eventClassResult.result;
            const eventClass = eventClasses?.find(ec => ec.organizationClassId === organizationClass.id);
            if (eventClass) {
                const resultQuery = await getEventResultsByEventClassId(eventClass.id);
                if (resultQuery.isSuccess) {
                    const eventClassResults: EventResult[] = resultQuery.result;
                    if (eventClassResults && eventClassResults.length > 0) {
                        eventResults = eventResults.concat(eventClassResults);
                    }
                }
                // const resultObject: any = eventClass.eventResults;
                // if (resultObject && resultObject.items && resultObject.items.length > 0) {
                //     resultObject.items.forEach((result: any) => {
                //         if (result) eventResults.push(result);
                //     });
                // } else {
                //     const resultQuery = await getEventResultsByEventClassId(eventClass.id);
                //     if (resultQuery.isSuccess) {
                //         const eventClassResults: EventResult[] = resultQuery.result;
                //         if (eventClassResults && eventClassResults.length > 0) {
                //             eventResults = eventResults.concat(eventClassResults);
                //         }
                //     }
                // }
            }
        }        

        for (let j = 0; j < eventResults.length; j++) {
            const currentEventResult: EventResult = eventResults[j];

            const eventClass = currentEventResult.eventClass;

            const backNumber: number = currentEventResult.entry.number || 0;
            const membership: Membership | undefined = memberMap.get(backNumber);

            let currentRiderName = await getRiderName(currentEventResult) || "";
            if (currentRiderName === "") {
                currentRiderName = membership?.personName || ""; 
            }

            let currentHorseName = currentEventResult.entry.horseName || "";
            if (currentHorseName === "") {
                currentHorseName = membership?.horse?.name || ""; 
            }

            const riderName: string = currentRiderName;
            const horseName: string = currentHorseName;
            const ownerName: string = currentEventResult.entry.ownerName || "";
            const membershipId: string = membership?.membershipId || "";
            const showsAttended: string = membership?.showsAttended ? membership.showsAttended.toString() : "";
            const place = currentEventResult.place ? currentEventResult.place.toString() : "";
            const currentPointsEarned = await getPointsFromEventResult(currentEventResult);
            
            const formattedResult: FormattedEventPointData = {
                resultId: currentEventResult.id,
                result: currentEventResult,
                eventName: currentEvent.name || "",
                eventId: currentEvent.id,
                place: place,
                pointsEarned: currentPointsEarned === 0 ? "0" : currentPointsEarned.toString(),
                eventClassNumber: eventClass?.number ? eventClass?.number.toString() : "",
                eventClassName: eventClass?.name || "",
                horseName: horseName,
                ownerName: ownerName,
                riderName: riderName,
                backNumber: backNumber.toString(),
                memberId: membershipId,
                showsAttended: showsAttended
            };
            await updateYearlyMapData(formattedResult, organizationClass);
        }

        // Removed Backlog event results
    }

    // After getting all of the data, sort it and return it
    let allDataArray: FormattedAccumulatedPointData[][] = [];
    organizationClassResultsMap.forEach(value => {
        const array = value.allEventData;
        if (array) {
            // Sort the entries from most points earned to least points earned
            const sorted = array?.sort((a: FormattedAccumulatedPointData, b: FormattedAccumulatedPointData) => (b.pointsEarned ? parseFloat(b.pointsEarned) : 0) - (a.pointsEarned ? parseFloat(a.pointsEarned) : 0));
            allDataArray.push(sorted);
        }
    });
    // Sort the organization divisions and classes by smallest number to biggest number
    const sorted = allDataArray.sort((a, b) => (a[0] ? (a[0].eventClassNumber) ? parseInt(a[0].eventClassNumber) : (a[0].eventDivisionNumber ? parseInt(a[0].eventDivisionNumber) : 0) : 0) - (b[0] ? (b[0].eventClassNumber) ? parseInt(b[0].eventClassNumber) : (b[0].eventDivisionNumber ? parseInt(b[0].eventDivisionNumber) : 0) : 0));

    const formattedResult: FormattedPointData = {
        yearlyData: (sorted || allDataArray),
    };

    return formattedResult;
}