import {
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React from "react";
import { Event } from "../../../models";
import { formatDisciplinesForDisplay } from "../../../utilities/events/EventDiscipline";

interface _Props {
    event: Event
}

const EventDisciplinesForm: React.FC<_Props> = ({event}) => {
    // TO DO: let user edit chosen disciplines
    return (
        <IonRow>
            <IonCol>
                <IonList className="bg-white">
                    {event.disciplines ?
                        <>
                            {formatDisciplinesForDisplay(event.disciplines).map((name, index) => (
                                <IonItem key={index}>
                                    <IonLabel>
                                        {name}
                                    </IonLabel>
                                </IonItem>
                            ))}
                        </>
                        :
                        <p>No disciplines were specified</p>
                    }
                </IonList>
            </IonCol>
        </IonRow>
    );
};

export default EventDisciplinesForm;