import { CreateBarnMemberInput, UpdateOwnerInput, UpdateRiderInput, UpdateTrainerInput } from "../../API";
import { Result } from "../../interfaces/Result";
import { Barn, Owner, Person, Rider, Trainer } from "../../models";
import { getOwnerByPersonId, updateOwner } from "../owner/Owner";
import { getRiderByPersonId, updateRider } from "../rider/Rider";
import { getTrainerByPersonId, updateTrainer } from "../trainer/Trainer";
import { createBarnMember } from "./BarnMember";

export async function createBarnPerson(person: Person, barn: Barn, permissionLevel?: (string | null), roles?: (string | null)) {
    let result: Result = {isSuccess: false, type: "BarnPerson", result: null, message: "Could not create the barn person."};
    
    const personName = person.firstName + " " + person.lastName;

    const barnMemberInput: CreateBarnMemberInput = {
        name: personName,
        barnId: barn.id,
        personId: person.id,
        permissionLevel: permissionLevel || "member",
        roles: roles || ""
    };
    const barnMemberResult = await createBarnMember(barnMemberInput);
    if (barnMemberResult.isSuccess) {
        // Look for a rider for this person
        const riderQueryResult = await getRiderByPersonId(person.id);
        if (riderQueryResult.isSuccess) {
            const rider: Rider = riderQueryResult.result;
            const riderInput: UpdateRiderInput = {
                id: rider.id,
                barnId: barn.id
            };
            const updateRiderResult = await updateRider(riderInput);
            if (!updateRiderResult.isSuccess) {
                result = {isSuccess: false, type: "BarnPerson", result: updateRiderResult.result, message: "Could not update the rider."};
                return result;
            }
        }

        // Look for a owner for this person
        const ownerQueryResult = await getOwnerByPersonId(person.id);
        if (ownerQueryResult.isSuccess) {
            const owner: Owner = ownerQueryResult.result;
            const ownerInput: UpdateOwnerInput = {
                id: owner.id,
                barnId: barn.id
            };
            const updateOwnerResult = await updateOwner(ownerInput);
            if (!updateOwnerResult.isSuccess) {
                result = {isSuccess: false, type: "BarnPerson", result: updateOwnerResult.result, message: "Could not update the owner."};
                return result;
            }
        }

        // Look for a trainer for this person
        const trainerQueryResult = await getTrainerByPersonId(person.id);
        if (trainerQueryResult.isSuccess) {
            const trainer: Trainer = trainerQueryResult.result;
            const trainerInput: UpdateTrainerInput = {
                id: trainer.id,
                barnId: barn.id
            };
            const updateTrainerResult = await updateTrainer(trainerInput);
            if (!updateTrainerResult.isSuccess) {
                result = {isSuccess: false, type: "BarnPerson", result: updateTrainerResult.result, message: "Could not update the trainer."};
                return result;
            }
        }

        result = {isSuccess: true, type: "BarnPerson", result: barnMemberResult.result, message: "Created the barn member."};
        return result;
    } else {
        result = {isSuccess: false, type: "BarnPerson", result: barnMemberResult.result, message: "Could not create the barn member."};
        return result;
    }
}