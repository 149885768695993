import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { getUSEFHorseById } from "../../utilities/USEFAPI/USEFHorse";
import { Event } from "../../models";
import BasicTooltip from "../Tooltip/BasicTooltip";

interface _Props {
    event?: Event
    onSubmit: Function
}

const USEFHorseForm: React.FC<_Props> = ({event, onSubmit}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [number, setNumber] = useState<string | null | undefined>();
    const [softwareKey, setSoftwareKey] = useState<string | null | undefined>();
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (event) {
            setSoftwareKey(event.usefSoftwareKey)
        }
    }, [event]);

    // Took the check out because now USEF requires a show manager's software key
    const checkUSEFNumber = async () => {
        if (number) {
            const queryResult = await getUSEFHorseById(number, softwareKey || "");
            if (queryResult.isSuccess) {
                // Return the USEF API result as well as the USEF Id number inputted
                onSubmit(number, queryResult.result);
                setNumber("");
            } else {
                // Return just the USEF Id number inputted
                onSubmit(number);
                setNumber("")
            }
        } else {
            setError("Error: no membership id number found.");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        onSubmit(number);
        if (number) await checkUSEFNumber();
        else onSubmit();
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                {(event) && (
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">
                                    Your Competition Software Key from USEF
                                    <BasicTooltip label="" tip="To access USEF database search, competition management must find their software key in their USEF competition dashboard. The time frame allowed for using the software key for a specific competition is 60 days before the start date through 30 days following the end date of the competition." />
                                </IonLabel>
                                <IonInput 
                                    type="text"
                                    value={softwareKey}
                                    aria-required={true}
                                    onIonChange={e => {
                                        if(isMounted) setIsDisabled(false);
                                        else setIsMounted(true);
                                        setSoftwareKey(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                )}
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Membership Number</IonLabel>
                            <IonInput 
                                type="text"
                                value={number}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setNumber(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeMd="4" className="ion-text-center">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default USEFHorseForm;