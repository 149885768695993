import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { createMembership } from "../../utilities/membership/Membership";
import { CreateMembershipInput } from "../../API";

interface _Props {
    horseId?: string
    riderId?: string
    ownerId?: string
    trainerId?: string
    membershipName?: string
    onSubmit: Function
}

const MembershipInfoForm: React.FC<_Props> = ({horseId, riderId, ownerId, trainerId, membershipName, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");

    useEffect(() => {
        if (membershipName && membershipName !== "") setName(membershipName);
    }, [membershipName]);

    const verifyForm = () => {
        if (!name) {
            setError("Please include a name for the organization that provides the membership.");
            return false;
        }
        if (!number) {
            setError("Please include a member id number.");
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            let input: CreateMembershipInput = {
                personId: user.id,
                name,
                membershipId: number
            };
            if (horseId) {
                input["horseId"] = horseId;
                // input["membershipHorseId"] = horseId;
            }
            if (riderId) {
                input["riderId"] = riderId;
                // input["membershipRiderId"] = riderId;
            }
            if (ownerId) {
                input["ownerId"] = ownerId;
                // input["membershipOwnerId"] = ownerId;
            }
            if (trainerId) {
                input["trainerId"] = trainerId;
                // input["membershipTrainerId"] = trainerId;
            }
            const createResult = await createMembership(input);
            if (createResult.isSuccess) {
                onSubmit(createResult.result);
                setName("");
                setNumber("");
            } else {
                setError(createResult.message);
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {isLoading ?
                <Spinner/>
                :
                <form>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Organization Name</IonLabel>
                                <IonInput 
                                    type="text"
                                    disabled={!!(membershipName)}
                                    value={name}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setName(e.detail.value!);
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Member ID Number</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={number}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setNumber(e.detail.value!);
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="text-center" sizeMd="4">
                            <IonButton
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                Save
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </form>
            }
        </>
    );
};

export default MembershipInfoForm;