import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import AddressFormGroup from "../Address/AddressFormGroup";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import { createAddress } from "../../utilities/address/Address";
import { createBarn } from "../../utilities/barn/Barn";
import {useHistory} from "react-router";
import { createContact } from "../../utilities/contact/Contact";
import { CreateAddressInput, CreateBarnInput, CreateBarnMemberInput, CreateContactInput } from "../../API";
import moment from "moment";
import { formatTwilioNumber } from "../../utilities/contact/FormatPhoneNumber";
import { formatURL } from "../../utilities/contact/FormatURL";
import { createBarnMember } from "../../utilities/barnMember/BarnMember";
import { Barn } from "../../models";
import SelectBarnType from "./SelectBarnType";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

const CreateBarnForm: React.FC = () => {
    const history = useHistory();
    const user = useContext(PersonContext);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [website, setWebsite] = useState("");
    const [facebookPage, setFacebookPage] = useState("");
    const [streetAddressLine1, setStreetAddressLine1] = useState("");
    const [streetAddressLine2, setStreetAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [provState, setProvState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [error, setError] = useState("");

    const verifyForm = () => {
        if (!name) {
            setError("Please include a name for the barn.");
            return false;
        }
        if (!type) {
            setError("Please include a type for the barn.");
            return false;
        }
        if (!streetAddressLine1) {
            setError("Please include a street address for the barn.");
            return false;
        }
        if (!city) {
            setError("Please include a city for the barn.");
            return false;
        }
        if (!provState) {
            setError("Please include a state or providence for the barn.");
            return false;
        }
        if (!zip) {
            setError("Please include a zip/area code for the barn.");
            return false;
        }
        if (!country) {
            setError("Please include a country for the barn.");
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            setIsLoading(true);
            setIsDisabled(true);
            await handleCreateBarn();
            setIsLoading(false);
        } 
    }

    const createBarnAddress = async () => {
        const addressName = name + "_mailing_address";
        const addressInput: CreateAddressInput = {
            name: addressName,
            type: "mailing",
            streetAddress1: streetAddressLine1,
            streetAddress2: streetAddressLine2,
            city: city,
            provState: provState,
            zip: zip,
            country: country
        };
        const createAddressResult = await createAddress(addressInput);
        if (createAddressResult.isSuccess) {
            return createAddressResult.result;
        } else {
            setError("Could not create the contact info.");
        }
    }

    const handleCreateBarn = async () => {
        try {
            // First, create the Address Object
            const createAddressResult = await createBarnAddress();

            // Next, create the contact
            let contactInput: CreateContactInput = {
                name
            };
            if (phoneNumber) contactInput["work"] = formatTwilioNumber(phoneNumber);
            if (emailAddress)contactInput["workEmail"] = emailAddress;
            if (website) contactInput["website"] = formatURL(website);
            if (facebookPage) contactInput["facebookPage"] = formatURL(facebookPage);
            const createContactResult = await createContact(contactInput);

            // Next, create the barn
            const ownerName = user.firstName + " " + user.lastName;
            let input: CreateBarnInput = {
                name: name, 
                ownerPersonId: user.id,
                ownerName: ownerName,
                type: type || "",
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            };
            if (createAddressResult) input["barnLocationId"] = createAddressResult.id;
            if (createContactResult.isSuccess) input["barnContactId"] = createContactResult.result.id;
            const createBarnResult = await createBarn(input);
            if (createBarnResult.isSuccess) {
                const barn: Barn = createBarnResult.result;
                const createBarnMemberInput: CreateBarnMemberInput = {
                    barnId: barn.id,
                    name: user.firstName + " " + user.lastName,
                    personId: user.id,
                    permissionLevel: "admin",
                    roles: user.roles
                };
                const createBarnMemberResult = await createBarnMember(createBarnMemberInput);
                if (createBarnMemberResult.isSuccess) {
                    navigateToPage();
                } else {
                    setError("Could not create the barn member.");
                }
            } else {
                setError("Could not create the barn");
            }
        } catch (error: any) {
            const message = "Could not create the barn: " + error;
            setError(message);
        }
    }

    const handleAddressInputChange = (type: string, value: string) => {
        setError("");
        if (type === "streetAddressLine1") setStreetAddressLine1(value);
        if (type === "streetAddressLine2") setStreetAddressLine2(value);
        if (type === "city") setCity(value);
        if (type === "provState") setProvState(value);
        if (type === "zip") setZip(value);
        if (type === "country") setCountry(value);
    };

    const navigateToPage = () => {
        const path = "/index/staff/barn"
        history.push(path);
    };

    return (
        <form id="barnForm">
            {error && <ErrorAlert width="12" error={error} />}
            <IonRow>
                <IonCol>
                    <IonText>
                        When you set up a new barn, you will be set as the primary contact person of this barn.
                    </IonText>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonText>
                        <h1>About</h1>
                    </IonText>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Name <RequiredInputIndicator /></IonLabel>
                        <IonInput 
                            id="barn-name-input"
                            type="text"
                            value={name}
                            aria-required={true}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setError("");
                                setName(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <SelectBarnType
                        isRequired={true}
                        onInputChange={(value: string) => {
                            setError("");
                            setType(value)
                        }}
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonText>
                        <h1>Address</h1>
                    </IonText>
                </IonCol>
            </IonRow>
            <AddressFormGroup
                id="create-barn"
                isRequired={true}
                isTextDarker={true}
                onChange={handleAddressInputChange}
                setIsDisabled={setIsDisabled}
            />
            <IonRow>
                <IonCol>
                    <IonText>
                        <h1>Contact Info</h1>
                    </IonText>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Barn Phone Number</IonLabel>
                        <IonInput 
                            id="barn-phoneNumber-input"
                            type="tel"
                            value={phoneNumber}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setPhoneNumber(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Barn Email Address</IonLabel>
                        <IonInput 
                            id="barn-email-input"
                            type="email"
                            value={emailAddress}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setEmailAddress(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Barn Website</IonLabel>
                        <IonInput 
                            id="barn-website-input"
                            type="text"
                            value={website}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setWebsite(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Barn Facebook Page</IonLabel>
                        <IonInput 
                            id="barn-facebook-input"
                            type="text"
                            value={facebookPage}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setFacebookPage(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            id="barn-create-btn"
                            disabled={isDisabled}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            Save
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </form>
    );
};

export default CreateBarnForm;