import {
    IonContent,
    IonPage
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import { Organization } from "../../../../../models";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import DeleteEventOrganization from "../../../../../components/EventOrganization/DeleteEventOrganization";

interface _Props extends RouteComponentProps<{
    id: string;
}> {}

const EventOrganizationDeletePage: React.FC<_Props> = ({match}) => {
    const [organization, setOrganization] = useState<Organization>();
    const [error, setError] = useState<string>("");

    useEffect(() => {
        async function getOrganization() {
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                const org: Organization = queryResult.result;
                setOrganization(org);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        getOrganization();
    }, [match, match.params.id]);
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={organization?.name ?? ""} />
                {error && <ErrorAlert error={error} width="12"/>}
                {organization ?
                    <DeleteEventOrganization organization={organization}/>
                :
                  <p>Loading organization....</p>
                }
            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationDeletePage;