import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonLabel,
    IonModal,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useState} from "react";

const OrganizationDivisionExplanationModal: React.FC = () => {
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <IonLabel>
            <IonButton 
                id="organizationDivisionExplanationBtn"
                onClick={() => setShowModal(true)}
                fill="clear"
                className="ion-no-padding"
            >
                <IonText className="font-weight-normal text-primary text-capitalize initialism ion-text-wrap ion-text-start">
                    Learn More
                </IonText>
            </IonButton>
            <IonModal backdropDismiss={false} isOpen={showModal} id="organizationDivisionExplanationModal">
                <IonHeader>
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                            Organization Divisions
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowModal(false)}
                            >
                                <p id="closeOrganizationDivisionExplanationModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    X
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonText>To save you time, we allow you to reuse any divisions that you create. When you add an "Organization Division" any member of your organization can quickly add this division to an event.</IonText>
                    <hr />
                    <IonText>We consider a "Division" to be a set of classes that will result in a Championship being awarded.</IonText>
                    <hr />
                    <IonText>We have a separate tab for "Organization Classes" that will allow you to add any singular classes that do not result in a Championship (i.e. Medals, Derbies, etc)</IonText>
                    <hr />
                    <IonText>Do not worry about creating the classes that make an "Organization Division" here. You can always add those later in a template.</IonText>
                    <hr />
                    <IonText>Lastly, this is where you edit an "Organization Division". You can update aspects such as the entry fee, amount of prize money, etc.</IonText>
                </IonContent>
                <IonButton id="CloseOrganizationDivisionExplanationModalBtn" onClick={() => handleClose()}>
                    Close
                </IonButton>
            </IonModal>
        </IonLabel>
    );
};

export default OrganizationDivisionExplanationModal;
