import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventBreakInput, CreateEventBreakMutation, DeleteEventBreakInput, DeleteEventBreakMutation, GetEventBreakQuery, ListEventBreaksQuery, UpdateEventBreakInput, UpdateEventBreakMutation } from "../../API";
import moment from "moment";
import { EventBreak } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventBreak. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventBreak.
*/
export async function createEventBreak(input: CreateEventBreakInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventBreak", "No input", "Create EventBreak received no input.");
   try {
        const fullInput: CreateEventBreakInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventBreak, { input: fullInput }))) as GraphQLResult<CreateEventBreakMutation>;
       else result = (await API.graphql({
           query: mutations.createEventBreak,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventBreakMutation>;
       const eventBreak = result.data?.createEventBreak;
       return formatResult(true, "EventBreak", eventBreak, "Successfully created the eventBreak.");
   } catch (error: any) {
       return formatResult(false, "EventBreak", error, "Error creating record in the database for type: eventBreak");
   }
}

/**
* Handle updating a new record in the database for type: eventBreak. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventBreak.
*/
export async function updateEventBreak(input: UpdateEventBreakInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventBreak", "No input", "Update EventBreak received no input.");
   try {
        const fullInput: UpdateEventBreakInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventBreak, { input: fullInput }))) as GraphQLResult<UpdateEventBreakMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventBreak,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventBreakMutation>;
       const eventBreak = result.data?.updateEventBreak;
       return formatResult(true, "EventBreak", eventBreak, "Successfully updated the eventBreak.");
   } catch (error: any) {
       return formatResult(false, "EventBreak", error, "Error updating record in the database for type: eventBreak");
   }
}

/**
* Handle deleting a new record in the database for type: eventBreak. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventBreak.
*/
export async function deleteEventBreak(input: DeleteEventBreakInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventBreak", "No input", "Delete EventBreak received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventBreak, { input: input }))) as GraphQLResult<DeleteEventBreakMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventBreak,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventBreakMutation>;
       const eventBreak = result.data?.deleteEventBreak;
       return formatResult(true, "EventBreak", eventBreak, "Successfully deleted the eventBreak.");
   } catch (error: any) {
       return formatResult(false, "EventBreak", error, "Error deleting record in the database for type: eventBreak");
   }
}

/**
* Get all records in the database for type: eventBreak. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventBreak object
*/
export async function getAllEventBreaks(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventBreaks,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventBreaksQuery>;

        let items = result.data?.listEventBreaks?.items as EventBreak[];
        let nextToken = result.data?.listEventBreaks?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventBreaks,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventBreaksQuery>;

            const nextItems = nextResult.data?.listEventBreaks?.items as EventBreak[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventBreaks?.nextToken;
        }

        return formatResult(true, "EventBreak", items, "Successfully got the EventBreaks.");
    } catch (error: any) {
        return formatResult(false, "EventBreak", error, "Error reading record in the database for type: eventBreaks");
    }
}

/**
* Read a specific record in the database for type: eventBreak. 
* 
* @param {string}  id                  The eventBreak id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventBreak object
*/
export async function getEventBreakById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventBreak,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventBreakQuery>;
       const eventBreak = result.data?.getEventBreak;
       return formatResult(true, "EventBreak", eventBreak, "Successfully got the eventBreak.");
   } catch (error: any) {
       return formatResult(false, "EventBreak", error, "Error reading record in the database for type: eventBreak");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventBreak. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventBreak object
*/
export async function getEventBreaksByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventBreaks,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventBreaksQuery>;

        let items = result.data?.listEventBreaks?.items as EventBreak[];
        let nextToken = result.data?.listEventBreaks?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventBreaks,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventBreaksQuery>;

            const nextItems = nextResult.data?.listEventBreaks?.items as EventBreak[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventBreaks?.nextToken;
        }

        return formatResult(true, "EventBreak", items, "Successfully got the EventBreaks.");
    } catch (error: any) {
        return formatResult(false, "EventBreak", error, "Error reading record in the database for type: eventBreaks");
    }
}