import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../../../../components/Headers/Header";
import { OrganizationDivision } from "../../../../../models";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps, useHistory } from "react-router";
import Spinner from "../../../../../components/Spinners/Spinner";
import { PersonContext } from "../../../../../context/PersonContext";
import { Table } from "reactstrap";
import SelectYear from "../../../../../components/DatePicker/SelectYear";
import SelectMembership from "../../../../../components/Memberships/SelectMembership";
import { FormattedAccumulatedPointData, FormattedPointData } from "../../../../../interfaces/Points";
import { getYearlyPointDataByOrganizationDivisionId } from "../../../../../utilities/eventResult/OrganizationPointData";
import { generatePointsByDivisionByYearReport } from "../../../../../utilities/reports/PointsReports";
import { getOrganizationDivisionById } from "../../../../../utilities/organizationDivision/OrganizationDivision";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import moment from "moment";
import constants from "../../../../../constant/constant";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

const yearChoices = [
    moment(new Date()).subtract(2, "year").format("YYYY"),
    moment(new Date()).subtract(1, "year").format("YYYY"),
    moment(new Date()).format("YYYY"),
    moment(new Date()).add(1, "year").format("YYYY"),
];

const EventOrganizationViewDivisionPointsPage: React.FC<OrganizationPageProps> = ({match}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [selectedYear, setSelectedYear] = useState(moment(new Date()).format("YYYY")); 
    const [organizationDivision, setOrganizationDivision] = useState<OrganizationDivision | null | undefined>();
    const [formattedAllResultsArray, setFormattedAllResultsArray] = useState<FormattedAccumulatedPointData[][] | null | undefined>(); 
    const [filteredFormattedAllResultsArray, setFilteredFormattedAllResultsArray] = useState<FormattedAccumulatedPointData[][] | null | undefined>(); 
    const [error, setError] = useState("");
    const [isDownloading, setIsDownloading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handlePrintYearlyPoints = async () => {
        if (organizationDivision) {
            setIsDownloading(true);
            await generatePointsByDivisionByYearReport(organizationDivision, selectedYear);
            setIsDownloading(false);
        }
    }

    const handleSelectedYear = async (year: string) => {
        setSelectedYear(year);
        await getOrganizationDivisionResults(year);
    }

    const handleSelectedMembership = (selected: string) => {
        setIsLoading(true);

        if (selected === "All Riders") {    
            setFilteredFormattedAllResultsArray(formattedAllResultsArray);
        } else {    
            let filteredAllResultsArray: FormattedAccumulatedPointData[][] = [];
    
            if (formattedAllResultsArray) {
                for (let i = 0; i < formattedAllResultsArray.length; i++) {
                    const pointDataArray: FormattedAccumulatedPointData[] = formattedAllResultsArray[i];
                    
                    if (pointDataArray && pointDataArray.length > 0) {
                        let innerArray = [];
                        for (let j = 0; j < pointDataArray.length; j++) {
                            const pointData: FormattedAccumulatedPointData = pointDataArray[j];
                            if (pointData.memberId) innerArray.push(pointData);
                        }
                        if (innerArray.length > 0) filteredAllResultsArray.push(innerArray);
                    }
                }
            }
    
            setFilteredFormattedAllResultsArray(filteredAllResultsArray);
        }
        setIsLoading(false);
    }

    async function getOrganizationDivisionResults(currentYear?: string) {
        try {
            setIsLoading(true);
            const queryResult = await getOrganizationDivisionById(match.params.id);
            if (queryResult.isSuccess) {
                const organizationDivision: OrganizationDivision = queryResult.result;
                setOrganizationDivision(organizationDivision);

                const formattedData: FormattedPointData = await getYearlyPointDataByOrganizationDivisionId(organizationDivision, organizationDivision.organizationId, currentYear || selectedYear);
                setFormattedAllResultsArray(formattedData.yearlyData);
                setFilteredFormattedAllResultsArray(formattedData.yearlyData);
                
                setIsLoading(false);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
                setIsLoading(false);
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOrganizationDivisionResults();
    }, [user, match.params.id]);

    const handleMemberClick = (memberId?: string) => {
        setError("");
        if (organizationDivision && organizationDivision.organizationId) {
            if (memberId && memberId !== "") {
                const path = "/index/staff/organization/points/view/" + organizationDivision.organizationId + "/member/" + memberId;
                history.push(path);
            } else {
                setError("No member id found.");
            }
        } else {
            setError("No organization found.");
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Organization Points"/>
                {isLoading ?
                    <Spinner />
                    :   
                    <>
                        {error && <ErrorAlert error={error} width="12" />}
                        {organizationDivision ?
                            <IonRow>
                                <IonCol>
                                    <IonCard mode="md" className="ion-padding bg-white">
                                        <IonCardTitle>Yearly Points</IonCardTitle>
                                        <IonCardSubtitle className="ion-text-wrap ion-text-right">
                                            {isDownloading ?
                                                <Spinner />
                                                :
                                                <p className="link" onClick={() => handlePrintYearlyPoints()}>
                                                    Print Report
                                                </p>
                                            }
                                        </IonCardSubtitle>

                                        <IonCardContent>
                                            {(filteredFormattedAllResultsArray && filteredFormattedAllResultsArray.length > 0) ?
                                                <>
                                                    {filteredFormattedAllResultsArray.map((dataArray: FormattedAccumulatedPointData[], index: number) => (
                                                        <span key={index}>
                                                            {(dataArray && dataArray.length > 0) ? 
                                                                <>
                                                                    <h1>{dataArray[0].eventClassNumber ? dataArray[0].eventClassNumber + " - " : (dataArray[0].eventDivisionNumber ? dataArray[0].eventDivisionNumber + " - " : "")} {dataArray[0].eventClassName || dataArray[0].eventDivisionName}</h1>
                                                                    <IonRow className="ion-justify-content-center">
                                                                        <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                                                            <SelectYear selectedValue={selectedYear} years={yearChoices} onSelect={(year: string) => handleSelectedYear(year)} />
                                                                        </IonCol>
                                                                        <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                                                            <SelectMembership selectedValue={"All Riders"} memberships={["All Riders", "Members Only"]} onSelect={(selected: string) => handleSelectedMembership(selected)} />
                                                                        </IonCol>
                                                                    </IonRow>
                                                                    <Table responsive hover key={index} className="py-3">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    <p className="description ion-text-wrap font-weight-bold">{(organizationDivision.organizationId === constants.RHSC_ORGANIZATION.id) ? "Back Number" : ""}</p>
                                                                                </th>
                                                                                <th>
                                                                                    <p className="description ion-text-wrap font-weight-bold">Horse Name</p>
                                                                                </th>
                                                                                <th>
                                                                                    <p className="description ion-text-wrap font-weight-bold">Rider Name</p>
                                                                                </th>
                                                                                {(organizationDivision.organizationId === constants.RHSC_ORGANIZATION.id) && (
                                                                                    <>
                                                                                        <th>
                                                                                            <p className="description ion-text-wrap font-weight-bold">Membership Id</p>
                                                                                        </th>
                                                                                        <th>
                                                                                            <p className="description ion-text-wrap font-weight-bold">Shows Attended</p>
                                                                                        </th>
                                                                                        <th>
                                                                                            <p className="description ion-text-wrap font-weight-bold">Meetings Attended</p>
                                                                                        </th>
                                                                                        <th>
                                                                                            <p className="description ion-text-wrap font-weight-bold">Volunteer Hours</p>
                                                                                        </th>
                                                                                    </>
                                                                                )}
                                                                                <th>
                                                                                    <p className="description ion-text-wrap font-weight-bold">Total Points</p>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {dataArray.map((data: FormattedAccumulatedPointData, i: number) => (
                                                                                <tr key={i} onClick={() => handleMemberClick(data.backNumber)}>
                                                                                    <td>
                                                                                        <p className="description ion-text-wrap">{(organizationDivision.organizationId === constants.RHSC_ORGANIZATION.id) ? data.backNumber : i+1}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="description ion-text-wrap">{data.horseName}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="description ion-text-wrap">{data.riderName}</p>
                                                                                    </td>
                                                                                    {(organizationDivision.organizationId === constants.RHSC_ORGANIZATION.id) && (
                                                                                        <>
                                                                                            <td>
                                                                                                <p className="description ion-text-wrap">{data.memberId}</p>
                                                                                            </td>
                                                                                            <td>
                                                                                                <p className="description ion-text-wrap">{data.showsAttended}</p>
                                                                                            </td>
                                                                                            <td>
                                                                                                <p className="description ion-text-wrap">{data.meetingsAttended}</p>
                                                                                            </td>
                                                                                            <td>
                                                                                                <p className="description ion-text-wrap">{data.volunteerHours}</p>
                                                                                            </td>
                                                                                        </>
                                                                                    )}
                                                                                    <td>
                                                                                        <p className="description ion-text-wrap">{data.pointsEarned}</p>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </>
                                                                :
                                                                <p>No data Found</p>
                                                            }
                                                        </span>
                                                    ))}
                                                </>
                                                :
                                                <p>No results found.</p>
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            :
                            <p>No organization found</p>
                        }
                    </>
                }

            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationViewDivisionPointsPage;