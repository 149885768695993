import {
    IonButton,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { EventRing } from "../../models";
import Spinner from "../Spinners/Spinner";
import { deleteEventRing, getEventRingsByEventId } from "../../utilities/eventRing/EventRing";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import { onCreateEventRing } from "../../graphql/subscriptions";

interface _Props {
    eventId: string
}

const EventRingsList: React.FC<_Props> = ({eventId}) => {

    const createSubscription = useSubscriptionByItself({
        config: {
            query: onCreateEventRing,
            key: "onCreateEventRing"
        }
    });

    const [createSubscriptionItem, setCreateSubscriptionItem] = useState<any>();
    const [rings, setRings] = useState<EventRing[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const getRings = async () => {
        setIsLoading(true);
        const queryResult = await getEventRingsByEventId(eventId);
        if (queryResult.isSuccess) {
            setRings(queryResult.result);
        } 
        setIsLoading(false);
    }

    useEffect(() => {
        if (eventId) getRings();
    }, [eventId]);

    useEffect(() => {
        if (createSubscription && createSubscription[0] !== undefined && createSubscription[0] !== createSubscriptionItem) {
            setCreateSubscriptionItem(createSubscription[0]);
            if (eventId) getRings();
        }
    }, [createSubscription]);

    const deleteRing = async(eventRing: EventRing) => {
        const deleteResult = await deleteEventRing({id: eventRing.id});
        if (deleteResult.isSuccess) {
            const newRingList = rings?.filter(ring => ring.id !== eventRing.id);
            setRings(newRingList);
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {rings && rings.length > 0 ? 
                        <IonList className="bg-white">
                            {rings.map((ring, index) => (
                                <IonItem key={index}>
                                    <IonLabel>
                                        <IonRow>
                                            <IonCol size="10">
                                                <p>{ring.name}</p>
                                            </IonCol>
                                            <IonCol size="2" className="ion-text-right">
                                                <IonButton color="danger" onClick={() => deleteRing(ring)}>X</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonLabel>
                                </IonItem>
                            ))}
                        </IonList>
                        :
                        <p>No Rings Found.</p>
                    }
                </>
            }
        </>
    );
};

export default EventRingsList;