import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { chevronDown, chevronUp, close } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { CreateEventEntryFeeInput } from "../../../API";
import { PersonContext } from "../../../context/PersonContext";
import { Event, EventEntry, EventEntryFee, EventFee, Fee } from "../../../models";
import { getEventClassEntriesByEventIdEntryId } from "../../../utilities/eventClassEntry/EventClassEntry";
import { createEventEntryFee, deleteEventEntryFee, getEventEntryFeesByEventIdByEntryId } from "../../../utilities/eventEntryFee/EventEntryFee";
import { getEventFeesByEventId } from "../../../utilities/eventFee/EventFee";
import ErrorAlert from "../../Errors/ErrorAlert";
import SelectEventFee from "../../EventFee/SelectEventFee";
import Spinner from "../../Spinners/Spinner";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";

interface _Props {
    entry: EventEntry
    event: Event
}

interface FormattedEventFee {
    id: string
    name: string
    amount: number
    quantity: number
    tax: string
    total: number
    isPerClass: boolean
    isAfterStartDate: boolean
    startDate?: string
    startTime?: string
}

const EditEntryFees: React.FC<_Props> = ({entry, event}) => {
    const user = useContext(PersonContext);

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalEventFees, setTotalEventFees] = useState<number | null | undefined>();
    const [previousEventEntryFees, setPreviousEventEntryFees] = useState<FormattedEventFee[] | null | undefined>();
    const [selectedEventEntryFeeToDelete, setSelectedEventEntryFeeToDelete] = useState<FormattedEventFee | null | undefined>();
    const [error, setError] = useState("");
    const [showAddFeeModal, setShowAddFeeModal] = useState(false);
    const [isSelectFee, setIsSelectFee] = useState(true);
    const [eventFees, setEventFees] = useState<EventFee[] | null | undefined>();
    const [selectedFee, setSelectedFee] = useState<EventFee | null | undefined>();
    const [selectedFeeQuantity, setSelectedFeeQuantity] = useState(1);
    const [miscFeeName, setMiscFeeName] = useState("");
    const [miscFeeAmount, setMiscFeeAmount] = useState(0);
    const [miscFeeQuantity, setMiscFeeQuantity] = useState(1);
    const [showDeleteFeeModal, setShowDeleteFeeModal] = useState(false);

    const calculateFeeQuantity = async (eventEntryFee: EventEntryFee) => {
        const fee: Fee | null | undefined = eventEntryFee.fee;
        if (fee && fee.isPerClass) {
            const classQueryResult = await getEventClassEntriesByEventIdEntryId(event.id, entry.id);
            if (classQueryResult.isSuccess) {
                const classList = classQueryResult.result;
                return classList.length;
            }
        } 
        return eventEntryFee.quantity || 0;
    }

    const calculateFeeCost = (eventEntryFee: EventEntryFee, quantity: number) => {
        const rawAmount = eventEntryFee.amount;
        const taxA = eventEntryFee.fee ? (eventEntryFee.fee.taxA ? eventEntryFee.fee.taxA/100 : 0) : 0;
        const taxB = eventEntryFee.fee ? (eventEntryFee.fee.taxB ? eventEntryFee.fee.taxB/100 : 0) : 0;

        let total = rawAmount;
        total = total * quantity;
        if (taxA && taxB) total = total + (total * (taxA + taxB));
        else if (taxA) total = total + (total * taxA);
        else if (taxB) total = total + (total * taxB);

        return total
    }

    const calculateEventFeeTotal = (formattedEventEntryFees?: FormattedEventFee[]) => {
        let total = 0;
        formattedEventEntryFees?.forEach(eventEntryFee => {
            total = total + eventEntryFee.total;
        })
        setTotalEventFees(total);
    }

    const formatEventFee = async (eventEntryFee: EventEntryFee) => {
        const fee = eventEntryFee.fee;
        const quantity = await calculateFeeQuantity(eventEntryFee);
        let formattedObject: FormattedEventFee = {
            id: eventEntryFee.id,
            name: eventEntryFee.name,
            amount: eventEntryFee.amount,
            quantity: quantity,
            tax: eventEntryFee.fee?.taxA ? eventEntryFee.fee?.taxA.toString() + "%" : "",
            total: calculateFeeCost(eventEntryFee, quantity),
            isPerClass: !!fee?.isPerClass,
            isAfterStartDate: !!fee?.isStartedOnEntryDate,
            startDate: fee?.isStartedOnEntryDate ? fee.startDate || "" : "",
            startTime: fee?.isStartedOnEntryDate ? fee.startTime || "" : ""
        };
        return formattedObject;
    }

    const sortFormattedEventFees = (formattedEventFees: FormattedEventFee[]) => {
        return formattedEventFees.sort((a, b) => a.name.localeCompare(b.name));
    }

    const formatEventFees = async (eventEntryFees: EventEntryFee[]) => {
        let result: FormattedEventFee[] = [];
        for (let i = 0; i < eventEntryFees.length; i++) {
            const currentEventEntryFee = eventEntryFees[i];
            const formattedObject = await formatEventFee(currentEventEntryFee);
            result.push(formattedObject);
        }
        const sorted = sortFormattedEventFees(result);
        setPreviousEventEntryFees(sorted || result);
        calculateEventFeeTotal(result);
    }

    useEffect(() => {
        const getEventEntryFeesByEntry = async (eventEntry: EventEntry) => {
            setIsLoading(true);
            const queryResult = await getEventEntryFeesByEventIdByEntryId(eventEntry.eventId, eventEntry.id);
            if (queryResult.isSuccess) {
                await formatEventFees(queryResult.result);
            }
            setIsLoading(false);
        }

        if (entry) getEventEntryFeesByEntry(entry);
    }, [event, entry]);

    useEffect(() => {
        async function getEventFees() {
            const queryResult = await getEventFeesByEventId(event.id);
            if (queryResult.isSuccess) {
                const eventFees = queryResult.result;
                setEventFees(eventFees);
            }
        }
        getEventFees();
    }, []);

    const handleSelectedEventFee = (eventFee: EventFee) => {
        setSelectedFee(eventFee);
    }

    const clearFeeForm = () => {
        setSelectedFee(undefined);
        setSelectedFeeQuantity(1);
        setMiscFeeName("");
        setMiscFeeAmount(0);
        setMiscFeeQuantity(1);
    }

    const handleSubmitSelectedFee = async () => {
        setIsLoading(true);
        if (selectedFee) {
            let input: CreateEventEntryFeeInput = {
                status: "accepted",
                name: selectedFee.name,
                description: selectedFee.description || "",
                amount: selectedFee.amount,
                quantity: selectedFeeQuantity,
                eventId: event.id,
                eventFeeId: selectedFee.id,
                feeId: selectedFee.feeId,
                entryId: entry.id,
                taxA: selectedFee.fee?.taxA || undefined,
                taxB: selectedFee.fee?.taxB || undefined,
                createdBy: user.id
            };
            const createResult = await createEventEntryFee(input);
            if (createResult.isSuccess) {
                const createdEventEntryFee: EventEntryFee = createResult.result;
                const newFormattedEventEntryFee = await formatEventFee(createdEventEntryFee);
                const result = previousEventEntryFees?.concat([newFormattedEventEntryFee]);
                const sorted = result ? sortFormattedEventFees(result) : [];
                setPreviousEventEntryFees(sorted);
                calculateEventFeeTotal(sorted);
                clearFeeForm();
            } else {
                setError("Could not create the new fee.");
            }
        } else {
            setError("Could not create the new fee.");
        }
        setShowAddFeeModal(false);
        setIsLoading(false);
    }

    const handleSubmitMiscFee = async () => {
        setIsLoading(true);
        if (miscFeeName) {
            if (miscFeeAmount) {
                let input: CreateEventEntryFeeInput = {
                    status: "accepted",
                    name: miscFeeName,
                    description: "",
                    amount: miscFeeAmount,
                    quantity: miscFeeQuantity,
                    eventId: event.id,
                    eventFeeId: "",
                    feeId: "",
                    entryId: entry.id,
                    taxA: undefined,
                    taxB: undefined,
                    createdBy: user.id
                };
                const createResult = await createEventEntryFee(input);
                if (createResult.isSuccess) {
                    const createdEventEntryFee: EventEntryFee = createResult.result;
                    const newFormattedEventEntryFee = await formatEventFee(createdEventEntryFee);
                    const result = previousEventEntryFees?.concat([newFormattedEventEntryFee]);
                    const sorted = result ? sortFormattedEventFees(result) : [];
                    setPreviousEventEntryFees(sorted);
                    calculateEventFeeTotal(sorted);
                    clearFeeForm();
                } else {
                    setError("Could not create the new fee.");
                }
            } else {
                setError("Please include an amount for the Miscellaneous Fee.");
            }
        } else {
            setError("Please include a name for the Miscellaneous Fee.");
        }
        setShowAddFeeModal(false);
        setIsLoading(false);
    }

    const handleDelete = async (eventEntryFee: FormattedEventFee) => {
        setIsLoading(true);
        const deleteResult = await deleteEventEntryFee({id: eventEntryFee.id});
        if (deleteResult) {
            const result: FormattedEventFee[] = [];
            if (previousEventEntryFees) {
                previousEventEntryFees.forEach(fee => {
                    if (fee.id !== eventEntryFee.id) result.push(fee);
                });
            }
            setPreviousEventEntryFees(result);
            calculateEventFeeTotal(result);
        } else {    
            setError("Could not delete this fee.");
        }
        setShowDeleteFeeModal(false);
        setIsLoading(false);
    }

    return (
        <>
            <IonRow className="ion-justify-content-right">
                <IonCol className="text-right">
                    <IonButton color="primary" onClick={() => setShowAddFeeModal(true)}>
                        Add New Fee
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {entry ? <IonRow><h3>Show Fees: {totalEventFees ? "$" + totalEventFees.toFixed(2) : ""}</h3></IonRow> : <h2>Loading event fees...</h2> }
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? <IonIcon icon={chevronDown} />  : <IonIcon icon={chevronUp} />}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {isLoading ?
                            <Spinner />
                            :
                            <IonList className="bg-white">
                                {previousEventEntryFees && (
                                    <>
                                        {previousEventEntryFees.map((formattedEventFee, index) => (
                                            <IonItem key={index}>
                                                <IonLabel>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p>Name: {formattedEventFee.name} ({formattedEventFee.amount ? "$" + formattedEventFee.amount.toFixed(2) : ""})</p>
                                                        </IonCol>
                                                        <IonCol className="ion-text-right">
                                                            <IonButton color="danger" onClick={() => {setSelectedEventEntryFeeToDelete(formattedEventFee); setShowDeleteFeeModal(true);}}><IonIcon icon={close}/></IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p>Quantity: {formattedEventFee.quantity}</p>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p>Tax: {formattedEventFee.tax ? formattedEventFee.tax.toString() : "n/a"}</p>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p>Total: ${formattedEventFee.total.toFixed(2)}</p>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonLabel>
                                            </IonItem>
                                        ))}
                                    </>
                                )}
                                <IonItem key="total">
                                    <IonLabel color="dark">
                                        <h3 className="font-weight-bold">
                                            Total: {totalEventFees ? "$" + totalEventFees.toFixed(2) : ""}
                                        </h3>
                                    </IonLabel>
                                </IonItem>
                            </IonList>
                        }
                    </IonCardContent>
                )}
            </IonCard>

            {/* Add Fee Modal */}
            <IonModal backdropDismiss={false} isOpen={showAddFeeModal} id="addNewEventEntryFeeModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Event Fees
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => {setShowAddFeeModal(false); clearFeeForm();}}
                        >
                            <p id="closeAddNewEventEntryFeeModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="6" className="ion-text-center">
                                <Spinner />
                            </IonCol>
                        </IonRow>
                        :
                        <>
                            <IonRow>
                                <IonCol size="6" className="ion-text-center">
                                    <IonButton color={isSelectFee ? "dark" : "light"} onClick={() => {setIsSelectFee(true); clearFeeForm();}}>Select Fee</IonButton>
                                </IonCol>
                                <IonCol size="6" className="ion-text-center">
                                    <IonButton color={isSelectFee ? "light" : "dark"} onClick={() => {setIsSelectFee(false); clearFeeForm();}}>Misc Fee</IonButton>
                                </IonCol>
                            </IonRow>
                            <hr/>
                            {isSelectFee ?
                                <>
                                    {eventFees ? 
                                        <>
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol size="10">
                                                    <IonLabel className="description ml-3">Select The Event Fee <RequiredInputIndicator/> </IonLabel>
                                                    <SelectEventFee eventFees={eventFees} onSelect={handleSelectedEventFee} />
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol size="10">
                                                <IonItem>
                                                        <IonLabel position="stacked">Fee Amount</IonLabel>
                                                        <IonInput
                                                            value={"$" + (selectedFee?.amount || 0)}
                                                            disabled={true}
                                                        />
                                                </IonItem>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-justify-content-center ion-padding-top">
                                                <IonCol size="10">
                                                    <IonItem>
                                                        <IonLabel position="stacked">Quantity <RequiredInputIndicator/></IonLabel>
                                                        <IonInput
                                                            type="number"
                                                            value={selectedFeeQuantity}
                                                            aria-required={true}
                                                            onIonChange={e => {
                                                                setSelectedFeeQuantity(parseFloat(e.detail.value!));
                                                            }}
                                                        />
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-justify-content-center ion-padding-top">
                                                <IonCol size="10" className="ion-text-center">
                                                    <IonButton expand="block" color="success" onClick={handleSubmitSelectedFee}>
                                                        Save
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </>
                                        :
                                        <p>No event fees were found for this event. You can create a new fee by clicking Misc Fee.</p>
                                    }
                                </>
                                :
                                <>
                                    <IonRow className="ion-justify-content-center ion-padding-top">
                                        <IonCol size="10">
                                            <IonItem>
                                                <IonLabel position="stacked" className="ion-text-primary">Fee Name <RequiredInputIndicator/></IonLabel>
                                                <IonInput
                                                    type="text"
                                                    value={miscFeeName}
                                                    aria-required={true}
                                                    onIonChange={e => {
                                                        setMiscFeeName(e.detail.value!);
                                                    }}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol size="10">
                                            <IonItem color="white">
                                                <IonLabel position="stacked" className="ion-text-primary">Fee Amount ($) <RequiredInputIndicator/></IonLabel>
                                                <IonInput
                                                    type="number"
                                                    value={miscFeeAmount}
                                                    aria-required={true}
                                                    onIonChange={e => {
                                                        setMiscFeeAmount(parseInt(e.detail.value!));
                                                    }}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center ion-padding-top">
                                        <IonCol size="10">
                                            <IonItem>
                                                <IonLabel position="stacked" className="ion-text-primary">Quantity <RequiredInputIndicator/></IonLabel>
                                                <IonInput
                                                    type="number"
                                                    value={miscFeeQuantity}
                                                    aria-required={true}
                                                    onIonChange={e => {
                                                        setMiscFeeQuantity(parseInt(e.detail.value!));
                                                    }}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center ion-padding-top">
                                        <IonCol size="10" className="ion-text-center">
                                            <IonButton expand="block" color="success" onClick={handleSubmitMiscFee}>
                                                Save
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </>
                            }
                        </>
                    }
                </IonContent>
            </IonModal>

            {/* Delete Fee Modal */}
            <IonModal backdropDismiss={false} isOpen={showDeleteFeeModal} id="deleteEventEntryFeeModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Delete Event Fee
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => {setShowDeleteFeeModal(false); setSelectedFee(undefined);}}
                        >
                            <p id="closeDeleteEventEntryFeeModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="6" className="ion-text-center">
                                <Spinner />
                            </IonCol>
                        </IonRow>
                        :
                        <>
                            {selectedEventEntryFeeToDelete ?
                                <>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol size="10">
                                            <h4 className="ion-text-center">Are you sure you want to delete the selected fee:</h4>
                                            <p>Fee Name: {selectedEventEntryFeeToDelete.name}</p>
                                            <p>Fee Amount: ${selectedEventEntryFeeToDelete.amount}</p>
                                            <p>Fee Quantity: {selectedEventEntryFeeToDelete.quantity}</p>
                                            <p className="font-weight-bold">Fee Total: ${selectedEventEntryFeeToDelete.total}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol size="6" className="ion-text-center">
                                            <IonButton color="danger" onClick={() => handleDelete(selectedEventEntryFeeToDelete)}>
                                                Delete
                                            </IonButton>
                                        </IonCol>
                                        <IonCol size="6" className="ion-text-center">
                                            <IonButton color="light" onClick={() => {setSelectedEventEntryFeeToDelete(undefined); setShowDeleteFeeModal(false);}}>
                                                Cancel
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </>
                                :
                                <p>No fee was found.</p>
                            }
                        </>
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default EditEntryFees;