import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps, useHistory } from "react-router";
import { Barn, Horse } from "../../../../models";
import { getBarnById } from "../../../../utilities/barn/Barn";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import HorseForm from "../../../../components/Horse/HorseForm";
import AddBarnHorseNavbar from "../../../../components/Navbars/AddBarnHorseNavbar";
import MoveHorseToBarnForm from "../../../../components/Horse/MoveHorsesToBarnForm";

interface BarnPageProps extends RouteComponentProps<{
    id: string;
}> {}

const NewHorsePage: React.FC<BarnPageProps> = ({match}) => {
    const history = useHistory();

    const [currentTab, setCurrentTab] = useState("new");
    const [barn, setBarn] = useState<Barn>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getBarn() {
            const queryResult = await getBarnById(match.params.id);
            if (queryResult.isSuccess) {
                setBarn(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getBarn();
    }, [match, match.params.id]);

    const handleTabSelection = (tab: string) => {
        setCurrentTab(tab);
    }

    const handleNewHorse = (horse: Horse) => {
        const path = "/index/staff/barn/horse/" + horse.id;
        history.push(path);
    }

    const handleMovedHorses = () => {
        const path = "/index/staff/barn/";
        history.push(path);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={barn ? barn.name + " - Add Horse" : "Add Horse"} />
                <AddBarnHorseNavbar active={currentTab} onSelect={handleTabSelection} />
                {error && <ErrorAlert width="12" error={error} />}
                <IonRow>
                    <IonCol>
                        <IonCard color="white" className="ion-padding">
                            {currentTab === "new" ? 
                                <>
                                    <IonCardTitle>
                                        New Horse
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <HorseForm selectedBarn={barn} onChange={handleNewHorse} />
                                    </IonCardContent>
                                </>
                                :
                                <>
                                    <IonCardTitle>
                                        Move Horse
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <MoveHorseToBarnForm selectedBarn={barn} onChange={handleMovedHorses} />
                                    </IonCardContent>
                                </>
                            }
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default NewHorsePage;