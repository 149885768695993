import {Auth} from "aws-amplify";
import {Result} from "../../interfaces/Result";

interface AWSCognitoErrorResponse {
    code: string;
    message: string;
    name: string;
}

/**
 * AWS Amplify utility function
 * 
 * @param email (string)
 * @param password (string)
 * 
 * @returns a Result object - success means it returns a user. otw it returns the error message
 */
export async function logIn(email: string, password: string): Promise<Result> {
    let result: Result;
    try {
        const input = {
            username: email.toLowerCase(), // emails will be case sensitive on login, so store in lowercase and login in lowercase
            password,
        };
        const user = await Auth.signIn(input);
        result = {isSuccess: true, type: "User", result: user, message: "Successfully signed in the user."};
        return result;
    } catch (error: any) {
        const message = handleErrors(error);
        result = {isSuccess: false, type: "User", result: error, message: message};
        return result;
    }
}

export function handleErrors (errorResponse: AWSCognitoErrorResponse) {
    if (errorResponse.code) {
        if (errorResponse.code === "UserNotFoundException") {
            return "Email address is not recognized.";
        }
        if (errorResponse.code === "NotAuthorizedException") {
            if (errorResponse.message.includes("User is disabled")) return "Your account has been disabled.";
            return "Incorrect email or password.";
        }
    } else if (errorResponse.message) {
        if (errorResponse.message === "Pending sign-in attempt already in progress") {
            return "Pending sign-in attempt already in progress";
        }
    }
    return "Please double-check your information.";
};