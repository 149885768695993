import React, { useEffect, useState } from "react";
import { getResponsesByPollId } from "../../utilities/polls/Response";
import ErrorAlert from "../Errors/ErrorAlert";
import { Pie } from "react-chartjs-2";
import { formatDiscoverMethodData } from "../../utilities/polls/Charts";
import { IonText, isPlatform } from "@ionic/react";

const DiscoverMethodPoll: React.FC = () => {

    const [responses, setResponses] = useState<any>();
    const [formattedPollData, setFormattedPollData] = useState<any>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getResponses() {
            const discoverMethodPollId = "04a99513-b6c7-4ac1-a422-1911c610a0c1";
            const responsesResult = await getResponsesByPollId(discoverMethodPollId);
            if (responsesResult.isSuccess) {
                setResponses(responsesResult.result);
                setError("");
                formatResponses(responsesResult.result);
            } else {
                setError("Could not get the poll responses.");
            }
        }

        getResponses();
    }, []);

    const formatResponses = (responses: any) => {
        const formattedPollDataResult = formatDiscoverMethodData(responses, isPlatform("mobile")); 
        if (formattedPollDataResult.isSuccess) {
            setFormattedPollData(formattedPollDataResult.result);
            setError("");
        } else {
            setError("Could not format the poll responses.");
        }
    }

    return (
        <>
           {error && <ErrorAlert width="12" error={error} />}
            {(formattedPollData && formattedPollData.data) && 
                <>
                    <IonText>Response Count: {responses.length}</IonText>
                    <Pie
                        data={formattedPollData.data}
                        options={formattedPollData.options}
                    />
                </>
            }
        </>
    );
};

export default DiscoverMethodPoll;
