import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Spinner from "../Spinners/Spinner";
import { Barn, EventEntry, Person, Trainer } from "../../models";
import { Table } from "reactstrap";
import moment from "moment";
import { getTrainersByPersonId, getTrainersByName, updateTrainer } from "../../utilities/trainer/Trainer";
import { formatDisplayName } from "../../utilities/person/PersonNameFormat";
import { getPersonByPersonId } from "../../utilities/person/Person";
import { formatAddress } from "../../utilities/address/FormatAddress";
import { getEventEntriesByTrainerId } from "../../utilities/eventEntry/EventEntry";
import { UpdateTrainerInput } from "../../API";
import SelectFromAllBarns from "../Barn/SelectFromAllBarns";
import SelectFromAllPeople from "../Person/SelectFromAllPeople";

interface FormattedTrainer {
    trainerId: string
    trainerName: string
    personId: string
    personName: string
    createdById: string
    createdByName: string
    birthdate: string
    age: string
    isProfessional: string
    barnId: string
    barnName: string
    contactId: string
    contactString: string
    location: string
    addressId: string
    addressString: string
    memberships: string
    entryCount: string,
    entries: string,
    showEntries: boolean,
    createdOn: string
    updatedOn: string
}
interface _Props {
    person: Person
}

const tableColumns = [
    "Trainer (Id)",
    "Trainer (Name)",
    "Person (Id)", 
    "Person (Name)", 
    "Select Person",
    "Created By (Id)", 
    "Created By (Name)", 
    "Birthdate",
    "Age",
    "Is Professional?",
    "Barn Id",
    "Barn Name",
    "Select Barn",
    "Contact Id",
    "Contact",
    "Location",
    "Address Id",
    "Address",
    "Memberships",
    "Entries",
    "Created On",
    "Updated On"
];

const AdminEditTrainersTable: React.FC<_Props> = ({person}) => {    
    
    const [formattedTrainers, setFormattedTrainers] = useState<FormattedTrainer[] | undefined | null>();     
    const [isLoading, setIsLoading] = useState(false);

    const getTrainers = async (person: Person) => {
        setIsLoading(true);
        let allTrainers: Trainer[] = [];

        // Get trainers that match this person's id
        const trainersQueryResult = await getTrainersByPersonId(person.id);
        if (trainersQueryResult.isSuccess) {
            const trainerArray: Trainer[] = trainersQueryResult.result;
            allTrainers = allTrainers.concat(trainerArray);
        }

        // Get trainers that match 
        const name = formatDisplayName(person.firstName || "", "", person.lastName || "");
        const trainersByNameQueryResult = await getTrainersByName(name);
        if (trainersByNameQueryResult.isSuccess) {
            const trainerArray: Trainer[] = trainersByNameQueryResult.result;
            trainerArray.forEach((trainer) => {
                const foundTrainerInAllArray = allTrainers.find((o: Trainer) => (trainer.id === o.id));
                if (!foundTrainerInAllArray) {
                    allTrainers.push(trainer);
                } 
            });
        }

        const formattedTrainerList = await formatTrainers(allTrainers);
        setFormattedTrainers(formattedTrainerList);

        setIsLoading(false);
    }

    const formatTrainers = async (allTrainers: Trainer[]) => {
        let formattedTrainers: FormattedTrainer[] = [];
        for (let i = 0; i < allTrainers.length; i++) {
            const currentTrainer = allTrainers[i];
            const formattedTrainer: FormattedTrainer = await formatTrainer(currentTrainer);
            formattedTrainers.push(formattedTrainer);
        }
        return formattedTrainers;
    }

    const formatTrainer = async (currentTrainer: Trainer) => {
        const personId = currentTrainer.personId;
        let personName = "";
        if (personId) {
            if (personId === person.id) {
                personName = formatDisplayName(person.firstName || "", "", person.lastName || "");
            }
            else {
                const getPersonQueryResult = await getPersonByPersonId(personId);
                if (getPersonQueryResult.isSuccess) {
                    const newPerson: Person = getPersonQueryResult.result;
                    personName = formatDisplayName(newPerson.firstName || "", "", newPerson.lastName || "");
                }
            }
        }

        const createdById = currentTrainer.createdBy;
        let createdByName = "";
        if (createdById) {
            if (createdById === person.id) {
                createdByName = formatDisplayName(person.firstName || "", "", person.lastName || "");
            }
            else {
                const getPersonQueryResult = await getPersonByPersonId(createdById);
                if (getPersonQueryResult.isSuccess) {
                    const newPerson: Person = getPersonQueryResult.result;
                    createdByName = formatDisplayName(newPerson.firstName || "", "", newPerson.lastName || "");
                }
            }
        }

        const formattedContact = 
        "Personal Email: " + currentTrainer.contact?.personalEmail +
        "\nCell: " + currentTrainer.contact?.cell +
        "\nHome: " + currentTrainer.contact?.home +
        "\nWork: " + currentTrainer.contact?.work +
        "\nMailing: " + currentTrainer.contact?.mailingAddress;

        const fomattedAddress = currentTrainer.address ? formatAddress(currentTrainer.address) : "";

        let memberships = "";
        if (currentTrainer.memberships && currentTrainer.memberships.length > 0) {
            currentTrainer.memberships?.forEach((membership) => {
                memberships = membership + (membership?.organizationName || "") + "; ";
            })
        }

        let entries = "";
        let entryCount = "";
        const entriesQuery = await getEventEntriesByTrainerId(currentTrainer.id);
        if (entriesQuery.isSuccess) {
            const entriesList: EventEntry[] = entriesQuery.result;
            if (entriesList && entriesList.length > 0) {
                entryCount = "# entries: " + entriesList.length.toString();
                entriesList.forEach((entry) => {
                    entries = entries + entry.eventId + ";\n";
                })
            }
        }

        let formattedTrainer: FormattedTrainer = {
            trainerId: currentTrainer.id,
            trainerName: currentTrainer.name,
            personId: personId || "",
            personName: personName,
            createdById: createdById || "",
            createdByName: createdByName,
            birthdate: "n/a",
            age: "n/a",
            isProfessional: "n/a",
            barnId: currentTrainer.barnId || "",
            barnName: currentTrainer.barnName || "",
            contactId: currentTrainer.contact?.id || "",
            contactString: formattedContact,
            location: currentTrainer.location || "",
            addressId: currentTrainer.addressId || "",
            addressString: fomattedAddress,
            memberships: memberships,
            entryCount: entryCount,
            entries: entries,
            showEntries: false,
            createdOn: moment(currentTrainer.createdOn).format("MM-DD-YYYY"),
            updatedOn: moment(currentTrainer.updatedOn).format("MM-DD-YYYY")
        };
        return formattedTrainer;
    }

    useEffect(() => {
        if (person) {
            getTrainers(person);
        }
    }, [person]);

    const handleSelectPerson = async (trainerId: string, person?: Person) => {
        const updateTrainerInput: UpdateTrainerInput = {
            id: trainerId,
            personId: person?.id || ""
        };
        const updateResult = await updateTrainer(updateTrainerInput);
        if (updateResult.isSuccess) {
            const updatedTrainer: Trainer = updateResult.result;
            if (formattedTrainers) {
                const formattedTrainer: FormattedTrainer = await formatTrainer(updatedTrainer);
                const index = formattedTrainers?.findIndex(formattedTrainer => formattedTrainer.trainerId === updatedTrainer.id);
                if (index > -1) {
                    const updatedList: FormattedTrainer[] = [
                        ...formattedTrainers.slice(0, index),
                        formattedTrainer,
                        ...formattedTrainers.slice(index + 1),
                    ];
                    setFormattedTrainers(updatedList);
                }
            }
        }
    }

    const handleSelectBarn = async (trainerId: string, barn?: Barn) => {
        const updateTrainerInput: UpdateTrainerInput = {
            id: trainerId,
            barnId: barn?.id || "",
            barnName: barn?.name || ""
        };
        const updateResult = await updateTrainer(updateTrainerInput);
        if (updateResult.isSuccess) {
            const updatedTrainer: Trainer = updateResult.result;
            if (formattedTrainers) {
                const formattedTrainer: FormattedTrainer = await formatTrainer(updatedTrainer);
                const index = formattedTrainers?.findIndex(formattedTrainer => formattedTrainer.trainerId === updatedTrainer.id);
                if (index > -1) {
                    const updatedList: FormattedTrainer[] = [
                        ...formattedTrainers.slice(0, index),
                        formattedTrainer,
                        ...formattedTrainers.slice(index + 1),
                    ];
                    setFormattedTrainers(updatedList);
                }
            }
        }
    }

    const handleClickShowEntries = (formattedTrainer: FormattedTrainer) => {
        const updatedFormattedTrainer: FormattedTrainer = {
            ...formattedTrainer,
            showEntries: !formattedTrainer.showEntries
        };
        if (formattedTrainers) {
            const index = formattedTrainers?.findIndex(x => formattedTrainer.trainerId === x.trainerId);
            if (index > -1) {
                const updatedList: FormattedTrainer[] = [
                    ...formattedTrainers.slice(0, index),
                    updatedFormattedTrainer,
                    ...formattedTrainers.slice(index + 1),
                ];
                setFormattedTrainers(updatedList);
            }
        }
    }

    return (
        <>
            <IonCard mode="md" className="ion-padding bg-white">
                <IonCardTitle>Trainer</IonCardTitle>
                <IonCardSubtitle><p className="description">Get Trainers by personId and personName.</p></IonCardSubtitle>
                <IonCardContent>
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            {(formattedTrainers && formattedTrainers.length > 0) ?
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            {tableColumns.map((tc, i) => (
                                                <th key={i}>{tc}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formattedTrainers.map((formattedTrainer, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.trainerId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.trainerName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.personId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.personName}</p>
                                                </td>
                                                <td className="extra-jumbo-input-width">
                                                    <SelectFromAllPeople onSelect={(p: Person) => handleSelectPerson(formattedTrainer.trainerId, p)} />
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.createdById}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.createdByName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.birthdate}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.age}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.isProfessional}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap"><a href={"/index/barn/info/" + formattedTrainer.barnId}>{formattedTrainer.barnId}</a></p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.barnName}</p>
                                                </td>
                                                <td className="extra-jumbo-input-width">
                                                    <SelectFromAllBarns 
                                                        showBarnIds
                                                        selectedValue={formattedTrainer.barnId} 
                                                        isHeightRequired={true}
                                                        height={"120px"}
                                                        onSelect={(b: Barn) => handleSelectBarn(formattedTrainer.trainerId, b)} 
                                                    />
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.contactId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.contactString}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.location}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.addressId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.addressString}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.memberships}</p>
                                                </td>
                                                <td onClick={() => handleClickShowEntries(formattedTrainer)}>
                                                    <p className="ion-text-wrap">{formattedTrainer.entryCount}</p>
                                                    {formattedTrainer.showEntries && (
                                                        <p className="ion-text-wrap">{formattedTrainer.entries}</p>
                                                    )}
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.createdOn}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedTrainer.updatedOn}</p>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                :
                                <p>No trainers found.</p>
                            }
                        </>
                    }
                </IonCardContent>
            </IonCard>
        </>
    );
};

export default AdminEditTrainersTable;