import {
    IonContent,
    IonIcon,
    IonLabel,
    IonPage,
    IonSegment,
    IonSegmentButton,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import Header from "../../../components/Headers/Header";
import { OrganizationMembershipType, Organization, Membership, Team } from "../../../models";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Spinner from "../../../components/Spinners/Spinner";
import ErrorAlert from "../../../components/Errors/ErrorAlert";
import { getOrganizationMembershipTypeById } from "../../../utilities/organizationMembershipType/OrganizationMembershipType";
import { getOrganizationById } from "../../../utilities/organization/Organization";
import MembershipApplicationPaymentForm from "../../../components/Applications/MembershipApplicationPaymentForm";
import MembershipApplicationDocumentsUpload from "../../../components/Applications/MembershipApplicationDocumentsUpload";
import MembershipApplicationWaiversList from "../../../components/Applications/MembershipApplicationWaiversList";
import MembershipApplicationBasicInfoTab from "../../../components/Applications/MembershipApplicationBasicInfoTab";
import { getActiveMembershipsByCreatedByIdByMembershipTypeId, getMembershipById, updateMembership } from "../../../utilities/membership/Membership";
import { PersonContext } from "../../../context/PersonContext";
import MembershipApplicationConfirmation from "../../../components/Applications/MembershipApplicationConfirmation";
import { CreateTeamInput, CreateTeamMemberInput, TeamPermissionLevel, TeamRole, TeamStatus, UpdateMembershipInput } from "../../../API";
import CONSTANT from "../../../constant/constant";
import { checkmarkCircleOutline } from "ionicons/icons";
import { sendConfirmationEmail, sendNewTeamEmail } from "../../../utilities/emails/MembershipEmail";
import { getPersonalInformationByPersonId } from "../../../utilities/personalInformation/PersonalInformation";
import { generateAELIndividualMembershipApplicationReport, generateAELTeamMembershipApplicationReport, membershipApplicationReports } from "../../../utilities/reports/MembershipApplicationReports";
import { createTeam } from "../../../utilities/team/Team";
import { createTeamMember } from "../../../utilities/teamMember/TeamMember";
import MembershipApplicationBasicInfo from "../../../components/Applications/MembershipApplicationBasicInfo";
import { formatDisplayNameForUser } from "../../../utilities/person/PersonNameFormat";

interface _Props extends RouteComponentProps<{
    organizationBackHalf: string;
    orgMembershipTypeId: string;
    membershipId?: string;
}> {
    setOrganizationHandler: Function;
}

const MembershipApplicationsPage: React.FC<_Props> = ({ match, setOrganizationHandler }) => {
    const user = useContext(PersonContext);
    const [isLoading, setIsLoading] = useState(false);
    const [membershipType, setMembershipType] = useState<OrganizationMembershipType>();
    const [memberships, setMemberships] = useState<Membership[]>([]);
    const [personId, setPersonId] = useState<string>("");
    const [organization, setOrganization] = useState<Organization>();
    const [segmentValue, setSegmentValue] = useState<string>(CONSTANT.MEMBERSHIP.APPLICATION_STAGES.BASIC);
    const [isMembershipComplete, setIsMembershipComplete] = useState<boolean>(false);
    const [isDoneWithBasicInfo, setIsDoneWithBasicInfo] = useState<boolean>(false);
    const [isDoneWithUploads, setIsDoneWithUploads] = useState<boolean>(false);
    const [isDoneWithWaivers, setIsDoneWithWaivers] = useState<boolean>(false);
    const [isDoneWithPayment, setIsDoneWithPayment] = useState<boolean>(false);
    const [isDoneWithConfirmation, setIsDoneWithConfirmation] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const [isAELOrganization, setIsAELOrganization] = useState(false);

    useEffect(() => {
        if (organization?.id === "e05fc919-2793-4ead-acef-3f6efb584b67") setIsAELOrganization(true);
    }, [organization]);

    async function getMembershipType() {
        try {
            setIsLoading(true);
            const queryResult = await getOrganizationMembershipTypeById(match.params.orgMembershipTypeId);
            if (queryResult.isSuccess) {
                const currentOrgMemType: OrganizationMembershipType = queryResult.result;
                setMembershipType(currentOrgMemType);
                getOrganization(currentOrgMemType);
            } else {
                setError(queryResult.message);
            }
            setIsLoading(false);
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }

    const resetFlags = () => {
        setSegmentValue(CONSTANT.MEMBERSHIP.APPLICATION_STAGES.BASIC);
        setIsMembershipComplete(false);
        setIsDoneWithBasicInfo(false);
        setIsDoneWithUploads(false);
        setIsDoneWithWaivers(false);
        setIsDoneWithPayment(false);
        setIsDoneWithConfirmation(false);
        setMemberships([]);
    }

    useEffect(() => {
        resetFlags();
        // Get the Organization Membership Type and Organization
        getMembershipType();
    }, [match, match.params.orgMembershipTypeId]);

    useEffect(() => {
        getMemberships(match.params.membershipId);
    }, [match, match.params.membershipId]);

    async function getOrganization(currentOrgMemType: OrganizationMembershipType) {
        try {
            if (currentOrgMemType && currentOrgMemType.organizationId) {
                setIsLoading(true);
                const queryResult = await getOrganizationById(currentOrgMemType.organizationId);
                if (queryResult.isSuccess) {
                    setOrganization(queryResult.result);
                    setOrganizationHandler(queryResult.result);
                } else {
                    setError(queryResult.message);
                }
                setIsLoading(false);
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }

    async function getMemberships(currentMembershipId?: string) {
        try {
            setIsLoading(true);

            // Track the current user
            const personId = user.id;
            setPersonId(personId);

            // Check if a particular membership id was passed via the URL
            if (currentMembershipId) {
                const queryResult = await getMembershipById(currentMembershipId);
                if (queryResult.isSuccess) {
                    const currentMembership: Membership = queryResult.result;
                    setMemberships([currentMembership]);
                }
            } 
            // OTW, check for a group membership
            else if (personId && membershipType?.id && !isAELOrganization && membershipType.category === "group") {
                const queryResult = await getActiveMembershipsByCreatedByIdByMembershipTypeId(personId, membershipType.id);
                if (queryResult.isSuccess) {
                    const memberships: Membership[] = queryResult.result;
                    setMemberships(memberships); 
                } else {
                    setError(queryResult.message);
                }
            }
            setIsLoading(false);
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }

    useEffect(() => {
        // TO DO - fix
        if(memberships && memberships.length) {
            setIsDoneWithBasicInfo(true);
        }
    }, [memberships]);

    useEffect(() => {
        for (let i = 0; i < memberships.length; i++) {
            const membership = memberships[i];
            setIsDoneWithUploads(isDoneWithUploadFile(membership));
        }
    }, [memberships, isDoneWithBasicInfo]);

    useEffect(() => {
        for (let i = 0; i < memberships.length; i++) {
            const membership = memberships[i];
            setIsDoneWithWaivers(isDoneWithWaiversList(membership));
        }
    }, [memberships, isDoneWithUploads]);

    useEffect(() => {
        for (let i = 0; i < memberships.length; i++) {
            const membership = memberships[i];
            setIsDoneWithPayment(isDoneWithPaymentProcess(membership));
        }
    }, [memberships, isDoneWithWaivers]);

    useEffect(() => {
        for (let i = 0; i < memberships.length; i++) {
            const membership = memberships[i];
            if(membership.membershipStatus === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE) {
                setIsDoneWithConfirmation(true);
                setIsMembershipComplete(true);
            }
        }
    }, [memberships, isDoneWithPayment]);

    const handleCreateTeam = async (updatedMembership: Membership) => {
        const teamInput: CreateTeamInput = {
            name: updatedMembership.groupContact?.name || "Team",
            creatorId: user.id,
            teamContactId: updatedMembership.groupContactId || ""
        };
        const createTeamResult = await createTeam(teamInput);
        if (createTeamResult.isSuccess) {
            const newTeam: Team = createTeamResult.result;
            // Update the membership to include the new team id
            const updateInput: UpdateMembershipInput = {
                id: updatedMembership.id,
                teamId: newTeam.id
            };
            const updateResult = await updateMembership(updateInput);
            if (!updateResult.isSuccess) {
                const message = "An error occurred. The team could not be saved to the membership. " + updateResult.message;
                setError(message); 
            }
        } else {
            const message = "An error occurred. The team could not be created. " + createTeamResult.message;
            setError(message);
        }
    }

    const handleCreateTeamMember = async (updatedMembership: Membership) => {
        if (updatedMembership.teamId) {
            if (updatedMembership.personId) {
                const teamMemberInput: CreateTeamMemberInput = {
                    teamId: updatedMembership.teamId,
                    personId: updatedMembership.personId,
                    status: TeamStatus.active,
                    permissionLevel: updatedMembership?.organizationMembershipType?.name.toLowerCase().includes("coach") ? TeamPermissionLevel.admin : TeamPermissionLevel.member,
                    role: updatedMembership.aelTeamRole ? getTeamRole(updatedMembership.aelTeamRole) : null,
                    creatorId: user.id
                };
                const createResult = await createTeamMember(teamMemberInput);
                if (!createResult.isSuccess) {
                    const message = "An error occurred. The new team member could not be created. " + createResult.message;
                    setError(message);
                }
            }
        }
    }

    const getTeamRole = (role: string) => {
        if (role) {
            if (role === "HEAD_COACH" || role === "headCoach") return TeamRole.headCoach;
            else if (role === "ASSISTANT_COACH" || role === "assistantCoach") return TeamRole.assistantCoach;
            else if (role === "PARENT_VOLUNTEER" || role === "parentVolunteer") return TeamRole.parentVolunteer;
            else return null;
        } 
        return null;
    }

    const handleCompletedApplication = async (updatedMembership: Membership, isLastMembershipInArray: boolean, updatedMemberships: Membership[]) => {
        setIsMembershipComplete(true);
        if (isAELOrganization) {
            // Check for AEL team creation
            if (membershipType?.category === "group") {
                // First, create the team
                await handleCreateTeam(updatedMembership);

                // Send a confirmation email for the team membership
                const currentMembership = memberships[0];
                const pdf = await generateAELTeamMembershipApplicationReport(currentMembership, membershipType, false);
                if (pdf && organization && membershipType) {
                    await sendConfirmationEmail(organization, membershipType?.name, updatedMemberships, pdf);
                    await sendNewTeamEmail(updatedMembership.groupContact?.name || "", formatDisplayNameForUser(user), pdf);
                }
            }
            
            // Check for AEL team member creation
            if (membershipType?.category === "individual") {
                // First, create the teamMember membership
                await handleCreateTeamMember(updatedMembership);

                // Send a confirmation email for the teamMember membership
                const currentMembership = memberships[0];
                const pdf = await generateAELIndividualMembershipApplicationReport(currentMembership, membershipType, false);
                if (pdf && organization && membershipType) {
                    await sendConfirmationEmail(organization, membershipType?.name, updatedMemberships, pdf);
                }
            }
        } else {
            // send confirmation email on last membership record
            if(isLastMembershipInArray) {
                if(organization && membershipType) {
                    const queryResult = await getPersonalInformationByPersonId(user.id);
                    const pdf = await membershipApplicationReports(memberships, membershipType, queryResult.result, organization, false);
                    if (pdf) {
                        await sendConfirmationEmail(organization, membershipType?.name, updatedMemberships, pdf);
                    }
                }
            }
        }
    }

    const handleUpdatedMemberships = async (updatedMemberships: Membership[]) => {
        setError("");
        setIsLoading(true);
        
        // Track the checked memberships, each membership will be updated if it is complete
        let checkedMemberships: Membership[] = [];

        // Need to check if the memberships should now have the completed status
        for (let i = 0; i < updatedMemberships.length; i++) {
            const updatedMembership = updatedMemberships[i];
            // First, if the membership is already complete, skip other checks
            let status = updatedMembership?.membershipStatus;

            if (status === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS) {
                const isDoneWithUploadStep = isDoneWithUploadFile(updatedMembership);
                const isDoneWithWaiverStep = isDoneWithWaiversList(updatedMembership);
                const isDoneWithPaymentStep = isDoneWithPaymentProcess(updatedMembership);

                if (isDoneWithUploadStep && isDoneWithWaiverStep && isDoneWithPaymentStep) {
                    // Update the status to be complete
                    const updateInput: UpdateMembershipInput = {
                        id: updatedMembership.id,
                        membershipStatus: CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE
                    };
                    const updateResult = await updateMembership(updateInput);
                    if (updateResult.isSuccess) {
                        const updatedMembership: Membership = updateResult.result;
                        checkedMemberships.push(updatedMembership);
                        setIsMembershipComplete(true);
                    } else {
                        const message = "An error occurred. The membership application could not be completed. " + updateResult.message;
                        console.error(message);
                    }

                    // Handle any other actions once the application has been completed
                    const isLastMembershipInArray = (i === (updatedMemberships.length - 1));
                    await handleCompletedApplication(updatedMembership, isLastMembershipInArray, updatedMemberships);
                } else {
                    checkedMemberships.push(updatedMembership);
                }
            } else {
                checkedMemberships.push(updatedMembership);
            }
        }
        setMemberships(checkedMemberships);
        setIsLoading(false);
    }
    
    function isDoneWithUploadFile(updatedMembership: Membership) {
        let flag = false;
        // Check if the user is done with documents
        if(isDoneWithBasicInfo) {
            if (membershipType && membershipType.organizationDocuments?.length) {
                if (updatedMembership?.documents?.length === membershipType.organizationDocuments?.length) flag = true;
            } else {
                // There are no documents to upload. Set to done.
                flag = true;
            }
        }
        return flag;
    }

    function isDoneWithWaiversList(updatedMembership: Membership) {
        let flag = true;
        // Check if the user is done with waivers
        if(isDoneWithUploads) {
            if (membershipType && membershipType.organizationRules?.length) {
                // if (updatedMembership?.organizationAgreements?.length === membershipType.organizationRules?.length) flag = true;
                for (let i = 0; i < membershipType.organizationRules.length; i++) {
                    const rule = membershipType.organizationRules[i];
                    if (rule?.isRequired) {
                        const aggreementToRequiredRule = updatedMembership?.organizationAgreements?.find(agreement => agreement?.organizationRuleId === rule.id);
                        if (!aggreementToRequiredRule) flag = false;
                    }
                }
            } else {
                // There are no waivers to upload. Set to done.
                flag = true;
            }
        }
        return flag;
    }

    function isDoneWithPaymentProcess(updatedMembership: Membership) {
        let flag = false;
        // Check if the user is done with payment
        if (isDoneWithWaivers) {
            if (membershipType && membershipType.price && (membershipType.applicationOptions?.acceptRSPPayment && organization?.stripeId)) {
                if (updatedMembership?.amountPaid === membershipType.price) flag = true;
            } else {
                // There is no payment due. Set to done.
                flag = true;
            }
        }
        return flag;
    }

    return (
        <IonPage className="bg-light" id="membership-application-page">
            <Header />
            <IonContent>
                <PageTitle title={`${(organization?.nickname || organization?.name) ?? "Organization"} Application`}/>
                {error && <ErrorAlert width="12" error={error} />}
                {isLoading && <Spinner />}
                {membershipType ? (
                    <>
                        <IonSegment
                            mode="md"
                            scrollable
                            value={segmentValue}
                            onIonChange={(e) => {
                                setSegmentValue(e.detail.value!);
                            }}
                        >
                            <IonSegmentButton value={CONSTANT.MEMBERSHIP.APPLICATION_STAGES.BASIC} id="membership-application-basicInfo-btn">
                                <IonLabel>
                                    <IonIcon
                                        icon={checkmarkCircleOutline}
                                        id="basic-info-checkmark-circle"
                                        size="large"
                                        className={isDoneWithBasicInfo && memberships?.length ? "waiver-list-green-check-box-show mr-1" : "waiver-list-green-check-box-hide mr-1"}
                                    />
                                    Basic Info
                                </IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value={CONSTANT.MEMBERSHIP.APPLICATION_STAGES.UPLOADS} id="membership-application-uploads-btn" disabled={!isDoneWithBasicInfo}>
                                <IonLabel>
                                    <IonIcon
                                        icon={checkmarkCircleOutline}
                                        id="uploads-checkmark-circle"
                                        size="large"
                                        className={isDoneWithUploads && memberships?.length ? "waiver-list-green-check-box-show mr-1" : "waiver-list-green-check-box-hide mr-1"}
                                    />
                                    Uploads
                                </IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value={CONSTANT.MEMBERSHIP.APPLICATION_STAGES.WAIVERS} id="membership-application-waivers-btn" disabled={!isDoneWithUploads}>
                                <IonLabel>
                                    <IonIcon
                                        icon={checkmarkCircleOutline}
                                        id="waiver-checkmark-circle"
                                        size="large"
                                        className={isDoneWithWaivers && memberships?.length ? "waiver-list-green-check-box-show mr-1" : "waiver-list-green-check-box-hide mr-1"}
                                    />
                                    Waivers
                                </IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value={CONSTANT.MEMBERSHIP.APPLICATION_STAGES.PAYMENT} id="membership-application-payment-btn" disabled={!isDoneWithWaivers}>
                                <IonLabel>
                                    <IonIcon
                                        icon={checkmarkCircleOutline}
                                        id="payment-checkmark-circle"
                                        size="large"
                                        className={isDoneWithPayment && memberships?.length ? "waiver-list-green-check-box-show mr-1" : "waiver-list-green-check-box-hide mr-1"}
                                    />
                                    Payment
                                </IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value={CONSTANT.MEMBERSHIP.APPLICATION_STAGES.CONFIRMATION} id="membership-application-confirmation-btn" disabled={!isMembershipComplete}>
                                <IonLabel>
                                    <IonIcon
                                        icon={checkmarkCircleOutline}
                                        id="confirmation-checkmark-circle"
                                        size="large"
                                        className={isDoneWithConfirmation && memberships?.length ? "waiver-list-green-check-box-show mr-1" : "waiver-list-green-check-box-hide mr-1"}
                                    />
                                    Confirmation
                                </IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                        {/* For AEL individual memberships */}
                        {segmentValue === CONSTANT.MEMBERSHIP.APPLICATION_STAGES.BASIC && isAELOrganization && (
                            <MembershipApplicationBasicInfoTab 
                                membershipType={membershipType}
                                setMemberships={handleUpdatedMemberships}
                                memberships={memberships} 
                                personId={personId} 
                                user={user}
                                setSegmentValue={setSegmentValue}
                                organization={organization}
                            />
                        )}
                        {/* For everything else */}
                        {segmentValue === CONSTANT.MEMBERSHIP.APPLICATION_STAGES.BASIC && !isAELOrganization && (
                            <MembershipApplicationBasicInfo 
                                membershipType={membershipType}
                                setMemberships={handleUpdatedMemberships}
                                memberships={memberships} 
                                personId={personId} 
                                user={user}
                                setSegmentValue={setSegmentValue}
                                organization={organization}
                            />
                        )}
                        {segmentValue === CONSTANT.MEMBERSHIP.APPLICATION_STAGES.UPLOADS && (
                            <MembershipApplicationDocumentsUpload
                                setMemberships={handleUpdatedMemberships}
                                setSegmentValue={setSegmentValue}
                                memberships={memberships} 
                                membershipType={membershipType}
                            />
                        )}
                        {segmentValue === CONSTANT.MEMBERSHIP.APPLICATION_STAGES.WAIVERS && (
                            <MembershipApplicationWaiversList 
                                memberships={memberships}
                                setMemberships={handleUpdatedMemberships}
                                membershipType={membershipType} 
                                setSegmentValue={setSegmentValue}
                                personId={personId}
                            />
                        )}
                        {segmentValue === CONSTANT.MEMBERSHIP.APPLICATION_STAGES.PAYMENT && (
                            <MembershipApplicationPaymentForm 
                                membershipType={membershipType} 
                                setMemberships={handleUpdatedMemberships}
                                memberships={memberships} 
                                setSegmentValue={setSegmentValue} 
                                organization={organization} 
                            />
                        )}
                        {segmentValue === CONSTANT.MEMBERSHIP.APPLICATION_STAGES.CONFIRMATION && (
                            <MembershipApplicationConfirmation 
                                membershipType={membershipType}
                                memberships={memberships} 
                                organization={organization} 
                            />
                        )}
                    </>
                ) : (
                    <p>No Membership Application was found. Please go back and try again.</p>
                )}
            </IonContent>
        </IonPage>
    );
};

export default MembershipApplicationsPage;
