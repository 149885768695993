import {
    IonCard,
    IonCardContent,
    IonCol,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { Barn, Horse, Trainer } from "../../models";
import { getTrainersByUserByBarn } from "../../utilities/trainer/TrainersByBarn";
import QuickAddTrainerForm from "./QuickAddTrainerForm";
import SelectTrainer from "./SelectTrainer";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    trainer?: (Trainer | null)
    horse?: (Horse | null)
    barn?: (Barn | null)
    onChange: Function
}

const TrainerEntryForm: React.FC<_Props> = ({trainer, horse, barn, onChange}) => {
    const user = useContext(PersonContext);

    const [trainerList, setTrainerList] = useState<Trainer[] | null | undefined>();
    const [selectedTrainer, setSelectedTrainer] = useState<Trainer | null | undefined>();

    const [isExpanded, setIsExpanded] = useState(false);

    async function getTrainers() {
        const trainers = await getTrainersByUserByBarn(user, barn!);
        if (trainers) setTrainerList(trainers);
    }

    useEffect(() => {
        getTrainers();
    }, []);

    useEffect(() => {
        if (trainer) setSelectedTrainer(trainer);
    }, [trainer]);

    const handleTrainerSelect = (trainer: Trainer, type?: string) => {
        if (type === "create") {
            const updatedTrainerList = (trainerList && trainerList.length) ? trainerList.concat([trainer]) : [trainer];
            setTrainerList(updatedTrainerList);
        }
        setSelectedTrainer(trainer);
        onChange(trainer);
    }

    return (
        <>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="12">
                    <IonLabel position="stacked" color="dark">Trainer <RequiredInputIndicator /></IonLabel>
                    <SelectTrainer barn={barn} selectedValue={selectedTrainer?.id} onSelect={handleTrainerSelect} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="12">
                    <p className="ion-text-center link" onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? "View Less" : (selectedTrainer ? "Edit Trainer" : "New Trainer")}</p>
                </IonCol>
            </IonRow>
            {isExpanded && (
                <IonCard color="white" className="ion-no-padding">
                    <IonCardContent className="ion-no-padding">
                        <QuickAddTrainerForm trainer={selectedTrainer} selectedBarn={barn} onChange={handleTrainerSelect}/>
                    </IonCardContent>
                </IonCard>
            )}
        </>
    );
};

export default TrainerEntryForm;