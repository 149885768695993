import { Result } from "../../interfaces/Result";
import { Organization } from "../../models";
import { deleteAddress } from "../address/Address";
import { deleteContact } from "../contact/Contact";
import { deleteOrganization } from "./Organization";


export async function deleteEntireOrganization(organization: Organization) {
    const result: Result = {isSuccess: true, type: "Organization", result: null, message: "Successfully deleted the organization."};

    await deleteOrganizationAddress(organization);
    await deleteOrganizationContact(organization);

    const deleteOrg = {
        id: organization.id
    };
    const deleteOrganizationResult = await deleteOrganization(deleteOrg);
    if (deleteOrganizationResult.isSuccess === false) {
        return deleteOrganizationResult;
    }
    return result;
}

const deleteOrganizationAddress = async (organization: Organization) => {
    if (organization.addresses) {
        const addresses = organization.addresses;
        for (const addressId in addresses ?? []) {
            await deleteAddress({id: addressId});
        }
    }
}

const deleteOrganizationContact = async (organization: Organization) => {
    if (organization.contact) {
        const contactId = organization.contact.id;
        await deleteContact({id: contactId});
    }
}