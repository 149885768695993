import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { getAccountBalance } from "../../../../../utilities/stripe/ExpressAccount";
import { createStandardConnectAccount, getStandardConnectAccountLink } from "../../../../../utilities/stripe/StandardConnectAccount";
import { getOrganizationById, updateOrganization } from "../../../../../utilities/organization/Organization";
import { formatUSDCurrency, formatCADCurrency } from "../../../../../utilities/currency/Currency";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import { Organization } from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import Spinner from "../../../../../components/Spinners/Spinner";
import { PersonContext } from "../../../../../context/PersonContext";
import { getSourceById } from "../../../../../utilities/stripe/GetSource";
import OrganizationPaymentMethod from "../../../../../components/Stripe/OrganizationPaymentMethod";
import { UpdateOrganizationInput } from "../../../../../API";
import ViewOrganizationBlocks from "../../../../../components/Event/Alerts/ViewOrganizationBlocks";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventFinancesPage: React.FC<OrganizationPageProps> = ({match}) => {

    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState<Boolean>(false);
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [balance, setBalance] = useState<any>(null);
    const [error, setError] = useState<string>("");

    const getSource = async (sourceId: string) => {
        await getSourceById(sourceId);
    }

    useEffect(() => {
        async function getOrganization() {
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                const org: Organization = queryResult.result;
                if (org) {
                    setOrganization(org);
                    handleGetAccountBalance(org);
                    if (org.paymentMethodId) {
                        getSource(org.paymentMethodId);
                    }
                }
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        async function handleGetAccountBalance(org: Organization) {
            setIsLoading(true);
            try {
                const stripeId = null; //org.stripeId;
                if (stripeId) {
                    const balanceResult = await getAccountBalance(stripeId);
                    if (balanceResult.isSuccess) {
                        const balanceObject = balanceResult.result;
                        setBalance(balanceObject);
                    }
                }
            } catch (error: any) {
                setError(error);
            }
            setIsLoading(false);
        }
        
        getOrganization();

    }, [user.id, match.params.id]);

    const handleCreateNewExpressAccount = async () => {
        setIsLoading(true);
        if (organization) {
            const organizationId = organization.id;
            const createResult = await createStandardConnectAccount(organizationId);
            if (createResult.isSuccess) {
                const data = createResult.result;
                const accountId = data.connectedAccountId;
                let orgResult;
                if (organization) {
                    const input: UpdateOrganizationInput = {
                        id: organization.id,
                        stripeId: accountId
                    };
                    orgResult = await updateOrganization(input);
                } else {
                    setIsLoading(false);
                    setError("Sorry, we could not perform the set up at this time. Please verify you have created an organization.");
                    return;
                }
    
                if (orgResult.isSuccess) {
                    const onboardingLink = data.onboardingLink;
                    window.open(onboardingLink);
                } else {
                    setError("Sorry, we could not perform the set up at this time. Please verify you have created an organization.");
                }
            }
        }
        setIsLoading(false);
    }

    const handleEditConnectAccount = async () => {
        setIsLoading(true);
        if (organization) {
            const organizationId = organization.id;
            if (organization.stripeId) {
                const createResult = await getStandardConnectAccountLink(organizationId, organization.stripeId);
                if (createResult.isSuccess) {
                    const accountLink = createResult.result;
                    window.open(accountLink);
                }
            } else {
                setError("Sorry, an error occurred. Please contact hello@ringsidepro.com for help accessing this feature.");
            }
        }
        setIsLoading(false);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Financial Info" />
                {error && <ErrorAlert width="12" error={error} />}
                {organization?.stripeCustomerId && (
                    <IonRow className="ion-justify-content-center">  
                        <IonCol sizeSm="12" sizeMd="10">
                            <IonCard mode="md" className="ion-padding bg-white">
                                <IonCardTitle>Payment Methods</IonCardTitle>
                                <IonCardContent>
                                    <OrganizationPaymentMethod organization={organization} />
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                )}
                {organization?.stripeId ?
                    <>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeSm="12" sizeMd="10">
                            <IonCard mode="md" className="ion-padding bg-white">
                                <IonCardTitle>Your Balance</IonCardTitle>
                                <IonCardContent>
                                    {isLoading ?
                                        <Spinner />
                                        :
                                        <IonRow>
                                            <IonCol>
                                                <IonRow>
                                                    <IonCol className="text-center">
                                                        <h2>{balance ? (balance.available[0].currency === "usd" ? (formatUSDCurrency(balance?.available[0].amount + balance?.pending[0].amount)) : (formatCADCurrency(balance?.available[0].amount + balance?.pending[0].amount))) : "$0"}</h2>
                                                        <h2>Total Balance</h2>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-justify-content-center">
                                                    <IonCol sizeXs="6" sizeMd="3" className="text-center">
                                                        <p>{balance ? (balance.available[0].currency === "usd" ? (formatUSDCurrency(balance?.available[0].amount)) : (formatCADCurrency(balance?.available[0].amount))) : "$0"}</p>
                                                        <p>Available Balance</p>
                                                    </IonCol>
                                                    <IonCol sizeXs="6" sizeMd="3" className="text-center">
                                                        <p>{balance ? (balance.pending[0].currency === "usd" ? (formatUSDCurrency(balance?.pending[0].amount)) : (formatCADCurrency(balance?.pending[0].amount))) : "$0"}</p>
                                                        <p>Pending Balance</p>
                                                    </IonCol>
                                                </IonRow>
                                                <hr/>
                                                <IonRow>
                                                    <IonCol className="ion-text-center">
                                                        <IonText>Your balance is automatically paid out daily to your chosen bank or debit card.</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                {/* <IonRow>
                                                    <IonCol className="text-center">
                                                        <IonButton onClick={() => handleSendToStripe()}>Go To Stripe</IonButton>
                                                    </IonCol>
                                                </IonRow> */}
                                                <IonRow className="ion-justify-content-center">  
                                                    <IonCol size="6" className="text-center">
                                                        <IonButton color="primary" onClick={() => handleEditConnectAccount()}>Update Your Financial Info</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                        </IonRow>
                                    }
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </>
                    :   
                    <>
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="10">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>Your Finances</IonCardTitle>
                                    <IonCardContent>
                                        {isLoading ?
                                            <Spinner/>
                                            :
                                            <>
                                                <IonText>
                                                    If you would like to accept online payments from event participants, you must first set up your payout information so we know where to send the funds.
                                                </IonText>
                                                <IonRow className="ion-justify-content-center">  
                                                    <IonCol size="6" className="text-center">
                                                        <IonButton color="primary" onClick={() => handleCreateNewExpressAccount()}>Set Up Your Financial Info</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </>
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                }
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Your Credit Blocks</IonCardTitle>
                            <IonCardContent>
                                <ViewOrganizationBlocks organizationId={organization?.id}/>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EventFinancesPage;