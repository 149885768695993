import { IonCard, IonCardTitle, IonCol, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Organization, Season } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import CreateSeasonButton from "./CreateSeasonButton";
import Spinner from "../Spinners/Spinner";
import DisplaySeasonTable from "./DisplaySeasonTable";
import { getSeasonsByOrganizationId } from "../../utilities/seasons/Seasons";

interface _Props {
    organization: Organization;
}

const OrganizationSeasonPageInfo: React.FC<_Props> = ({ organization }) => {

    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [seasons, setSeasons] = useState<Season[]>([]);

    async function getSeasonList(id: string) {
        setIsLoading(true);
        setError("");
        const queryResult = await getSeasonsByOrganizationId(id);
        if (queryResult.isSuccess) {
            const seasonList = queryResult.result;
            setSeasons(seasonList);
        } else {
            setError(queryResult.message);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (organization) {
            getSeasonList(organization.id);
        } else {
            setError("No organization was found. Please go back and try again.");
        }
    }, [organization]);
    

    return (
        <React.Fragment>
            <IonRow className="ion-justify-content-center">
                {error && <ErrorAlert width="12" error={error} />}
                {isLoading && <Spinner/>}
                <IonCol sizeXs="12" sizeMd="12">
                    <IonCard mode="md" className="ion-padding bg-white">
                        <IonRow className="ion-justify-content-between">
                            <IonCardTitle>
                                <h3>Seasons</h3>
                            </IonCardTitle>
                            <CreateSeasonButton organization={organization} />
                        </IonRow>
                        <DisplaySeasonTable seasons={seasons} setSeasons={setSeasons} />
                    </IonCard>
                </IonCol>
            </IonRow>
        </React.Fragment>
    );
};

export default OrganizationSeasonPageInfo;
