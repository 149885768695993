import moment from "moment";
import { FormattedStablingRequest } from "../../interfaces/Stabling";
import { Barn, BeddingRequest, Contact, Event, EventEntry, EventEntryFee, EventInvoice, EventResult, Horse, Membership, Owner, Rider, StablingRequest, Trainer } from "../../models";
import { formatAddress } from "../address/FormatAddress";
import { getBeddingRequestsByEntryId } from "../beddingRequest/BeddingRequest";
import { getContactById } from "../contact/Contact";
import { formatStartEndDatesStrings } from "../dates/FormatDates";
import { getEventClassEntriesByEventIdEntryId } from "../eventClassEntry/EventClassEntry";
import { formattedOptionGroup, getFormattedEntryClassList } from "../eventClassEntry/FormattedEventEntryClasses";
import { getAcceptedEventEntriesByEventId } from "../eventEntry/EventEntry";
import { sortEventEntriesByBarn, sortEventEntriesByNumber } from "../eventEntry/SortEventEntry";
import { getEventEntryFeesByEventIdByEntryId } from "../eventEntryFee/EventEntryFee";
import { isAfterEventFeeStartDate } from "../eventEntryFee/StartDateEventFee";
import { getEventInvoicesByEventIdEntryId } from "../eventInvoice/EventInvoice";
import { getEventResultsByEntryId } from "../eventResult/EventResult";
import { getMembershipsByHorseId, getMembershipsByOwnerId, getMembershipsByRiderId, getMembershipsByTrainerId } from "../membership/Membership";
import { formatStablingRequests } from "../stablingRequest/FormatStablingRequests";
import { getStablingRequestsByEventId } from "../stablingRequest/StablingRequest";
import { addFooters } from "./ReportFooter";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export interface InvoicePDFSettings {
    isDue?: boolean //Displays a message on the invoice explainging that this is a summary or the amount due
    isDownload?: boolean //If true, you can print or download. OTW, create a Blob for sending as email attachment
    isPrintOnly?: boolean //If true, you can print from UI view (won't download the file)
    sortBy?: ("number" | "barn")
    selectedEntry?: EventEntry //Filter on the selected entry - should result in one invoice
    selectedTrainer?: Trainer //Filter on the selected trainer - should result in a subset of entries
    selectedBarn?: Barn //Filter on the selected barn - should result in a subset of entries
    includeRiderInfo?: boolean
    includeHorseInfo?: boolean
    includeOwnerInfo?: boolean
    includeTrainerInfo?: boolean
    includeBarnInfo?: boolean
    includePrimaryContactInfo?: boolean
    includeEmails?: boolean
    includePhoneNumbers?: boolean
    includeMemberships?: boolean
}

export interface InvoicePDFData {
    formattedClassData?: (formattedOptionGroup[] | null)
    previousEventEntryFees?: (EventEntryFee[] | null)
    formattedStablingRequests?: (FormattedStablingRequest[] | null)
    beddingRequests?: (BeddingRequest[] | null)
    eventResults?: (EventResult[] | null)
    contact?: (Contact | null),
}

function debugBase64(base64URL: any){
    var win = window.open();
    if (win) {
        win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    }
}

const getRiderMemberships = async (rider: Rider) => {
    let membershipString = "";
    if (rider.id) {
        const membershipResult = await getMembershipsByRiderId(rider.id);
        if (membershipResult.isSuccess) {
            const membershipList: Membership[] = membershipResult.result;
            if (membershipList && membershipList.length > 0) {
                for (let i = 0; i < membershipList.length; i++) {
                    const membership = membershipList[i];
                    if (membership.name === "RHSC" && membership.membershipStatus === "complete") {
                        membershipString = membership.name + ": " + membership.membershipId + (membership.organizationMembershipType?.name ? (" (" + membership.organizationMembershipType?.name + "); ") : "; ");
                    } else if (membership.name !== "RHSC") {
                        membershipString = membership.name + ": " + membership.membershipId + "; ";
                    }
                }
            }
        }
    } 
    return membershipString;
}

const getRiderInfo = async (rider: Rider, settings: InvoicePDFSettings) => {
    const riderInfoRow: string[] = ["Rider"];
    const riderName = rider.name;
    riderInfoRow.push(riderName);
    if (settings.includeMemberships) {
        const riderMemberships = await getRiderMemberships(rider);
        riderInfoRow.push(riderMemberships);
    }
    if (settings.includeEmails || settings.includePhoneNumbers) {
        let contact = rider.contact;
        if (!contact && rider.riderContactId) {
            const contactQueryResult = await getContactById(rider.riderContactId);
            if (contactQueryResult.isSuccess) {
                contact = contactQueryResult.result;
            }
        }
        if (contact && settings.includeEmails) {
            const emailAddress = (contact.personalEmail ? contact.personalEmail : (contact.workEmail || ""));
            riderInfoRow.push(emailAddress);
        }
        if (contact && settings.includePhoneNumbers) {
            const phoneInfo = (contact.cell ? contact.cell : contact.home ? contact.home : contact.work ? contact.work : "");
            riderInfoRow.push(phoneInfo);
        }
    }
    return riderInfoRow;
}

const getHorseMemberships = async (horse: Horse) => {
    let membershipString = "";
    if (horse.id) {
        const membershipResult = await getMembershipsByHorseId(horse.id);
        if (membershipResult.isSuccess) {
            const membershipList: Membership[] = membershipResult.result;
            if (membershipList && membershipList.length > 0) {
                for (let i = 0; i < membershipList.length; i++) {
                    const membership = membershipList[i];
                    if (membership.name === "RHSC" && membership.membershipStatus === "complete") {
                        membershipString = membership.name + ": " + membership.membershipId + "; ";
                    } else if (membership.name !== "RHSC") {
                        membershipString = membership.name + ": " + membership.membershipId + "; ";
                    }
                }
            }
        }
    } 
    return membershipString;
}

const getHorseInfo = async (horse: Horse, settings: InvoicePDFSettings) => {
    const horseInfoRow: string[] = ["Horse"];
    let horseName = horse.name;
    if (horse.cogginsDate) horseName = horseName + " (Coggins Date: " + moment(horse.cogginsDate).format("MM/DD/YY") + ")";
    horseInfoRow.push(horseName);
    if (settings.includeMemberships) {
        const horseMemberships = await getHorseMemberships(horse);
        horseInfoRow.push(horseMemberships);
    }
    return horseInfoRow;
}

const getOwnerMemberships = async (owner: Owner) => {
    let membershipString = "";
    if (owner.id) {
        const membershipResult = await getMembershipsByOwnerId(owner.id);
        if (membershipResult.isSuccess) {
            const membershipList: Membership[] = membershipResult.result;
            if (membershipList && membershipList.length > 0) {
                for (let i = 0; i < membershipList.length; i++) {
                    const membership = membershipList[i];
                    if (membership.name === "RHSC" && membership.membershipStatus === "complete") {
                        membershipString = membership.name + ": " + membership.membershipId + "; ";
                    } else if (membership.name !== "RHSC") {
                        membershipString = membership.name + ": " + membership.membershipId + "; ";
                    }
                }
            }
        }
    } 
    return membershipString;
}

const getOwnerInfo = async (owner: Owner, settings: InvoicePDFSettings) => {
    const ownerInfoRow: string[] = ["Owner"];
    const ownerName = owner.name;
    ownerInfoRow.push(ownerName);
    if (settings.includeMemberships) {
        const ownerMemberships = await getOwnerMemberships(owner);
        ownerInfoRow.push(ownerMemberships);
    }
    if (settings.includeEmails || settings.includePhoneNumbers) {
        let contact = owner.contact;
        if (!contact && owner.ownerContactId) {
            const contactQueryResult = await getContactById(owner.ownerContactId);
            if (contactQueryResult.isSuccess) {
                contact = contactQueryResult.result;
            }
        }
        if (contact && settings.includeEmails) {
            const emailAddress = (contact.personalEmail ? contact.personalEmail : (contact.workEmail || ""));
            ownerInfoRow.push(emailAddress);
        }
        if (contact && settings.includePhoneNumbers) {
            const phoneInfo = (contact.cell ? contact.cell : contact.home ? contact.home : contact.work ? contact.work : "");
            ownerInfoRow.push(phoneInfo);
        }
    }
    return ownerInfoRow;
}

const getTrainerMemberships = async (trainer: Trainer) => {
    let membershipString = "";
    if (trainer.id) {
        const membershipResult = await getMembershipsByTrainerId(trainer.id);
        if (membershipResult.isSuccess) {
            const membershipList: Membership[] = membershipResult.result;
            if (membershipList && membershipList.length > 0) {
                for (let i = 0; i < membershipList.length; i++) {
                    const membership = membershipList[i];
                    if (membership.name === "RHSC" && membership.membershipStatus === "complete") {
                        membershipString = membership.name + ": " + membership.membershipId + "; ";
                    } else if (membership.name !== "RHSC") {
                        membershipString = membership.name + ": " + membership.membershipId + "; ";
                    }
                }
            }
        }
    } 
    return membershipString;
}

const getTrainerInfo = async (trainer: Trainer, settings: InvoicePDFSettings) => {
    const trainerInfoRow: string[] = ["Trainer"];
    const trainerName = trainer.name;
    trainerInfoRow.push(trainerName);
    if (settings.includeMemberships) {
        const trainerMemberships = await getTrainerMemberships(trainer);
        trainerInfoRow.push(trainerMemberships);
    }
    if (settings.includeEmails || settings.includePhoneNumbers) {
        let contact = trainer.contact;
        if (!contact && trainer.trainerContactId) {
            const contactQueryResult = await getContactById(trainer.trainerContactId);
            if (contactQueryResult.isSuccess) {
                contact = contactQueryResult.result;
            }
        }
        if (contact && settings.includeEmails) {
            const emailAddress = (contact.personalEmail ? contact.personalEmail : (contact.workEmail || ""));
            trainerInfoRow.push(emailAddress);
        }
        if (contact && settings.includePhoneNumbers) {
            const phoneInfo = (contact.cell ? contact.cell : contact.home ? contact.home : contact.work ? contact.work : "");
            trainerInfoRow.push(phoneInfo);
        }
    }
    return trainerInfoRow;
}

const getPersonalContactInfo = (contact: Contact, settings: InvoicePDFSettings) => {
    const contactInfoRow: string[] = ["Contact"];
    const contactName = contact.name || "";
    contactInfoRow.push(contactName);
    if (settings.includeMemberships) {
        // Skip for primary contact
        contactInfoRow.push("");
    }
    if (settings.includeEmails) {
        const contactEmailInfo = (contact.personalEmail ? contact.personalEmail : (contact.workEmail || ""));
        contactInfoRow.push(contactEmailInfo);
    }
    if (settings.includePhoneNumbers) {
        const contactPhoneInfo = (contact.cell ? contact.cell : contact.home ? contact.home : contact.work ? contact.work : "");
        contactInfoRow.push(contactPhoneInfo);
    }
    return contactInfoRow;
}

const getEntryTableRows = async (entry: EventEntry, settings: InvoicePDFSettings) => {
    const headerTableRows: string[][] = [];
    if (settings.includeRiderInfo) {
        if (entry.rider) {
            const rider1InfoRow = await getRiderInfo(entry.rider, settings);
            headerTableRows.push(rider1InfoRow);
        } else {
            headerTableRows.push(["Rider"]);
        }
        if (entry.secondRider) {
            const rider2InfoRow = await getRiderInfo(entry.secondRider, settings);
            headerTableRows.push(rider2InfoRow);
        }
    }
    if (settings.includeHorseInfo) {
        if (entry.horse) {
            const horseInfoRow = await getHorseInfo(entry.horse, settings);
            headerTableRows.push(horseInfoRow);
        } else {
            headerTableRows.push(["Horse"]);
        }
    }
    if (settings.includeOwnerInfo) {
        if (entry.owner) {
            const ownerInfoRow = await getOwnerInfo(entry.owner, settings);
            headerTableRows.push(ownerInfoRow);
        } else {
            headerTableRows.push(["Owner"]);
        }
    }
    if (settings.includeTrainerInfo) {
        if (entry.trainer) {
            const trainerInfoRow = await getTrainerInfo(entry.trainer, settings);
            headerTableRows.push(trainerInfoRow);
        } else {
            headerTableRows.push(["Trainer"]);
        }
    }
    if (settings.includeBarnInfo) {
        if (entry.barn?.name || entry.barnName) {
            const barnInfoRow = ["Barn", (entry.barn?.name || entry.barnName || "")]
            headerTableRows.push(barnInfoRow);
        } else {
            headerTableRows.push(["Barn"]);
        }
    }
    if (settings.includePrimaryContactInfo) {
        if (entry.primarycontact) {
            const contactInfoRow = getPersonalContactInfo(entry.primarycontact, settings);
            headerTableRows.push(contactInfoRow);
        } else {
            headerTableRows.push(["Contact"]);
        }
    }
    return headerTableRows;
}

export async function generateInvoicePDF(entry: EventEntry, event: Event, data: InvoicePDFData, settings: InvoicePDFSettings, currentDoc?: any) {
    // initialize jsPDF
    let doc = new jsPDF("p");
    // If a doc was passed in (to concatenate invoices), use that doc instead of a clean doc - gets returned at end
    if (currentDoc) {
        doc = currentDoc;
    }

    let currentY = 15;

    // page title: event information. and margin-top + margin-left
    doc.setFontSize(16);
    doc.text(`${event.name}`, 105, currentY, {align: "center"});
    currentY = currentY + 5;
    doc.setFontSize(12);
    doc.text(`${formatStartEndDatesStrings(event.startDate, event.endDate)}`, 105, currentY, {align: "center"});
    currentY = currentY + 5;
    if (event.address) {
        doc.text(`${event.address ? formatAddress(event.address) : ""}`, 105, currentY, {align: "center"});
        currentY = currentY + 5;
    }

    // page title: entry information
    doc.setFontSize(12);
    currentY = currentY + 2;
    doc.text(`Entry Number: ${entry.number || ""}`, 14, currentY);
    currentY = currentY + 5;
    
    const showEntryInfoTable = (
        settings.includeRiderInfo || 
        settings.includeHorseInfo || 
        settings.includeOwnerInfo ||
        settings.includeTrainerInfo ||
        settings.includeBarnInfo ||
        settings.includePrimaryContactInfo);

    // Check if settings require entry info - if yes, use a table to organize the info
    if (showEntryInfoTable) {
        // Set the columns based on the settings
        const headerTableColumns = ["", "Name"];
        if (settings.includeMemberships) headerTableColumns.push("Memberships");
        if (settings.includeEmails) headerTableColumns.push("Email");
        if (settings.includePhoneNumbers) headerTableColumns.push("Phone");

        // Get the rows of the table based on the settings
        const headerTableRows: string[][] = await getEntryTableRows(entry, settings);
    
        // startY is basically margin-top
        doc.autoTable(headerTableColumns, headerTableRows, { 
            theme: "grid",
            headStyles: {fillColor: "#73a4d3"},
            bodyStyles: {fontSize: 8},
            startY: currentY
        });

        currentY = doc.lastAutoTable.finalY + 10;
    }

    // Table title: class entry information. and margin-top + margin-left
    doc.setFontSize(12);
    doc.text(`Fees`, 14, currentY);
    currentY = currentY + 5;
    doc.setFontSize(12);

    // define the columns we want and their titles
    const tableColumn = ["Quantity", "Description", "Amount", "Tax", "Total"];
    // define an empty array of rows
    const tableRows = [];

    let totalFeeCost = 0;
    if (data.formattedClassData && data.formattedClassData.length > 0) {
        for (let i = 0; i < data.formattedClassData.length; i++) {
            const currentClassData = data.formattedClassData[i];
            let tableRow: any[] = [];
            let classTax = event.invoiceOptions?.classFeeTax;
            if (currentClassData.division) {
                const division = currentClassData.division;
                const total = division.entryFee + division.entryFee * (classTax ? classTax/100 : 0);
                totalFeeCost = totalFeeCost + total;

                // The division label should include all class numbers
                let divisionLabel = "Division: " + division.label;
                if (currentClassData.classes && currentClassData.classes.length > 0) {
                    divisionLabel = divisionLabel + " (Classes: ";
                    currentClassData.classes.forEach((currentClass) => {
                        if (currentClass.number) {
                            divisionLabel = divisionLabel + currentClass.number.toString() + ", ";
                        }
                    });
                    divisionLabel = divisionLabel.slice(0, (divisionLabel.length-2)) + ")";
                }

                tableRow = [
                    "1",
                    divisionLabel,
                    "$" + division.entryFee.toFixed(2).toString(),
                    classTax ? classTax + "%" : "",
                    "$" + total.toFixed(2).toString()
                ];
                tableRows.push(tableRow);
            } else if (currentClassData.class) {
                const c = currentClassData.class;
                const total = c.entryFee + c.entryFee * (classTax ? classTax/100 : 0);
                totalFeeCost = totalFeeCost + total;
                tableRow = [
                    "1",
                    "Class: " + (c.number ? c.number + ": " : "") + c.label,
                    "$" + c.entryFee.toFixed(2).toString(),
                    classTax ? classTax + "%" : "",
                    "$" + total.toFixed(2).toString()
                ];
                tableRows.push(tableRow);
            }
        };
    }
    
    if (data.previousEventEntryFees && data.previousEventEntryFees.length > 0) {
        for (let i = 0; i < data.previousEventEntryFees.length; i++) {
            const currentEventEntryFee = data.previousEventEntryFees[i];
            if (currentEventEntryFee.fee && currentEventEntryFee.fee.isStartedOnEntryDate) {
                const isAfterFeeStartDate = isAfterEventFeeStartDate(currentEventEntryFee);
                if (!isAfterFeeStartDate) continue;
            }
            let quantity = currentEventEntryFee.quantity || 1;
            if (currentEventEntryFee.fee && currentEventEntryFee.fee.isPerClass) {
                const classQueryResult = await getEventClassEntriesByEventIdEntryId(event.id, entry.id);
                if (classQueryResult.isSuccess) {
                    const classList = classQueryResult.result;
                    quantity = classList.length;
                }
            }
            const tax = currentEventEntryFee.fee?.taxA || 0;
            const taxPercentage = tax/100;
            const total = (currentEventEntryFee.amount + (currentEventEntryFee.amount*taxPercentage)) * quantity;
            totalFeeCost = totalFeeCost + total;
            let tableRow: any[] = [
                quantity,
                currentEventEntryFee.name + (currentEventEntryFee.description ? " (" + currentEventEntryFee.description + ")" : ""),
                "$" + currentEventEntryFee.amount.toFixed(2).toString(),
                (tax !== 0) ? tax.toFixed(0) + "%" : "",
                "$" + total.toFixed(2).toString()
            ];
            tableRows.push(tableRow);
        };
    }

    if (data.formattedStablingRequests) {
        for (let j = 0; j < data.formattedStablingRequests.length; j++) {
            const currentStablingRequest = data.formattedStablingRequests[j];
            const quantity = ((parseInt(currentStablingRequest.quantity as any as string) || 1) / (currentStablingRequest.entries?.length || 1));
            const tax = currentStablingRequest.tax ? parseFloat(currentStablingRequest.tax as string) : 0;
            const total = parseFloat(currentStablingRequest.totalPrice as any as string);
            // const totalWithTax = total + total * (tax ? tax/100 : 0);
            if (currentStablingRequest.totalPrice) {
                totalFeeCost = totalFeeCost + total;
            }
            let tableRow: any[] = [
                quantity,
                currentStablingRequest.type,
                "$" + (currentStablingRequest.price ? currentStablingRequest?.price : ""),
                tax ? tax + "%" : "",
                "$" + total.toFixed(2).toString()
            ];
            tableRows.push(tableRow);
        }
    }

    if (data.beddingRequests) {
        for (let j = 0; j < data.beddingRequests.length; j++) {
            const currentBeddingRequest = data.beddingRequests[j];
            const quantity = ((parseInt(currentBeddingRequest.quantityNeeded as any as string) || 1) / (currentBeddingRequest.entries?.length || 1));
            const tax = 0;
            const total = parseFloat(currentBeddingRequest.basePrice as any as string) * quantity;
            // const totalWithTax = total + total * (tax ? tax/100 : 0);
            if (currentBeddingRequest.totalPrice) {
                totalFeeCost = totalFeeCost + total;
            }
            let tableRow: any[] = [
                quantity,
                "Bedding",
                "$" + (currentBeddingRequest.basePrice ? currentBeddingRequest?.basePrice : ""),
                tax ? tax + "%" : "",
                "$" + total.toFixed(2).toString()
            ];
            tableRows.push(tableRow);
        }
    }

    const FeeTotalRow = [
        "",
        "",
        "",
        "Total: ",
        "$" + totalFeeCost.toFixed(2).toString()
    ];
    tableRows.push(FeeTotalRow);
    
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        bodyStyles: {fontSize: 8},
        startY: currentY,
        drawCell: function(cell: any, data: any) {
            if (data.row.index === data.table.rows.length - 1) {
                doc.setFontStyle('bold');
            }
        }  
    });

    let totalPrizeMoney = 0;
    if (data.eventResults) {
        doc.setFontSize(12);
        doc.text(`Results`, 14, doc.lastAutoTable.finalY + 10);
        doc.setFontSize(12);

        // define the columns we want and their titles
        const resultsTableColumn = ["Place", "Type", "Class #", "Class", "Prize $"];
        // define an empty array of rows
        const resultsTableRows = [];

        for (let k = 0; k < data.eventResults.length; k++) {
            const currentResult = data.eventResults[k];
            totalPrizeMoney = totalPrizeMoney + (currentResult.prizeMoney || 0);
            let eventClassName = currentResult.eventClassName || currentResult.eventClass?.name || "";
            let eventDivisionName = currentResult.eventDivisionName || currentResult.eventDivision?.name || "";
            const row = [
                currentResult.place,
                currentResult.eventDivision ? "Division" : "Class",
                currentResult.eventClass?.number,
                eventClassName || eventDivisionName,
                currentResult.prizeMoney ? "$" + currentResult.prizeMoney.toFixed(2).toString() : ""
            ];
            resultsTableRows.push(row);
        }

        const PrizeMoneyTotalRow = [
            "",
            "",
            "",
            "",
            "$" + totalPrizeMoney.toFixed(2).toString()
        ];
        resultsTableRows.push(PrizeMoneyTotalRow);

        // startY is basically margin-top
        doc.autoTable(resultsTableColumn, resultsTableRows, { 
            theme: "grid",
            headStyles: {fillColor: "#73a4d3"},
            bodyStyles: {fontSize: 8},
            startY: doc.lastAutoTable.finalY + 15,
            drawCell: function(cell: any, data: any) {
                if (data.row.index === data.table.rows.length - 1) {
                    doc.setFontStyle('bold');
                }
            }  
        });
    }

    // Track the payments already received
    let totalPaymentAmount = 0;

    const queryResult = await getEventInvoicesByEventIdEntryId(event.id, entry.id);
    if (queryResult.isSuccess) {
        const invoiceRecords = queryResult.result;

        if (invoiceRecords && invoiceRecords.length > 0) {
            doc.setFontSize(12);
            doc.text(`Payments`, 14, doc.lastAutoTable.finalY + 10);
            doc.setFontSize(12);

            // define the columns we want and their titles
            const paymentsTableColumn = ["Payer Name", "Amount Paid", "Payment Type", "Notes"];
            // define an empty array of rows
            const paymentsTableRows = [];

            for (let i = 0; i < invoiceRecords.length; i++) {
                const invoice: EventInvoice = invoiceRecords[i];
                const row = [
                    invoice.payerName || "",
                    (invoice?.amountToDeductFromInvoice?.toFixed(2) ? ("$" + invoice?.amountToDeductFromInvoice?.toFixed(2) ): ""),
                    invoice.payerType || "",
                    invoice.statusNote || ""
                ];
                paymentsTableRows.push(row);
                totalPaymentAmount = totalPaymentAmount + (invoice?.amountToDeductFromInvoice || 0);
            }

            const paymentTotalRow = [
                "Total: ",
                "$" + totalPaymentAmount.toFixed(2).toString(),
                "",
                ""                
            ];
            paymentsTableRows.push(paymentTotalRow);

            // startY is basically margin-top
            doc.autoTable(paymentsTableColumn, paymentsTableRows, { 
                theme: "grid",
                headStyles: {fillColor: "#73a4d3"},
                bodyStyles: {fontSize: 8},
                startY: doc.lastAutoTable.finalY + 15,
                drawCell: function(cell: any, data: any) {
                    if (data.row.index === data.table.rows.length - 1) {
                        doc.setFontStyle('bold');
                    }
                }  
            });
        }
    }

    doc.setFontSize(12);
    if (settings.isDue) {
        if (doc.lastAutoTable.finalY + 30 > doc.internal.pageSize.height) {
            doc.addPage();
            doc.text(`Balance Due $${(totalFeeCost - totalPrizeMoney - totalPaymentAmount).toFixed(2)}`, 14, 15);
        } else {
            doc.text(`Balance Due $${(totalFeeCost - totalPrizeMoney - totalPaymentAmount).toFixed(2)}`, 14, doc.lastAutoTable.finalY + 15);
        }
    } else {
        doc.text(`Current Total: $${(totalFeeCost - totalPrizeMoney - totalPaymentAmount).toFixed(2)}`, 14, doc.lastAutoTable.finalY + 20);
        doc.setFontSize(12);
        doc.text(`This is meant to be a summary of your current statement. It is not final.`, 14, doc.lastAutoTable.finalY + 25);
        doc.text(`The balance will be due during checkout from the horse show.`, 14, doc.lastAutoTable.finalY + 30);
    }

    if (currentDoc) {
        // Return the doc for this entry - used when mass downloading invoices so that all of them can be in one PDF
        return doc;
    } else {
        // Add page footer if this is the only page created
        addFooters(doc);
        if (settings.isDownload) {
            if (settings.isPrintOnly) {
                var base64string = doc.output('bloburl');
                debugBase64( base64string );
            } else {
                // we define the name of our PDF file.
                doc.save(`invoice_${entry.number ? entry.number + "_" : ""}${entry.horseName}.pdf`);
            }
        } else {
            var blobPDF =  new Blob([ doc.output() ], { type : 'application/pdf'});
            return blobPDF;
        }
    }
}

export async function generateEntryInvoicesPDF(event: Event, settings: InvoicePDFSettings, callbackFunction?: Function) {
    // initialize jsPDF
    let doc = new jsPDF();

    const eventEntryResult = await getAcceptedEventEntriesByEventId(event.id);
    if (eventEntryResult.isSuccess) {
        let eventEntries: EventEntry[] = eventEntryResult.result;
        
        if (settings && settings.selectedEntry) {
            eventEntries = eventEntries.filter(entry => entry.id === settings.selectedEntry?.id);
        } else if (settings && settings.selectedTrainer) {
            eventEntries = eventEntries.filter(entry => entry.trainerId === settings.selectedTrainer?.id || entry.trainerName?.toLowerCase() === settings.selectedTrainer?.name.toLowerCase());
        } else if (settings && settings.selectedBarn) {
            eventEntries = eventEntries.filter(entry => entry.barnId === settings.selectedBarn?.id || entry.barnName?.toLowerCase() === settings.selectedBarn?.name.toLowerCase());
        }

        let sortedEventEntries: (EventEntry[] | null) = [];
        if (settings && settings.sortBy === "number") {
            sortedEventEntries = sortEventEntriesByNumber(eventEntries);
        } else if (settings && settings.sortBy === "barn") {
            sortedEventEntries = sortEventEntriesByBarn(eventEntries);
        }
        
        if (sortedEventEntries && sortedEventEntries.length > 0) {
            for (let i = 0; i < sortedEventEntries.length; i++) {

                if (callbackFunction) {
                    const message = i+1 + " of " + sortedEventEntries.length;
                    callbackFunction(message);
                }

                const entry = sortedEventEntries[i];

                let formattedClassData: any = undefined;
                const formattedClassDataResult = await getFormattedEntryClassList(entry, true);
                if (formattedClassDataResult.isSuccess) {
                    formattedClassData = formattedClassDataResult.result.classList;
                }
                
                let previousEventEntryFees: EventEntryFee[] | null | undefined = undefined;
                const queryResult = await getEventEntryFeesByEventIdByEntryId(event.id, entry.id);
                if (queryResult.isSuccess) {
                    previousEventEntryFees = queryResult.result;
                }

                let stablingRequests: StablingRequest[] = [];
                let formattedStablingRequests: FormattedStablingRequest[] = [];
                const queryStablingResult = await getStablingRequestsByEventId(event.id);
                if (queryStablingResult.isSuccess) {
                    const allStablingRequests = queryStablingResult.result;
                    allStablingRequests.forEach((sr: StablingRequest) => {
                        const currentEntries = sr.entryIds;
                        if (currentEntries && currentEntries.length) {
                            currentEntries.forEach(currentEntry => {
                                if (currentEntry === entry.id) stablingRequests.push(sr);
                            });
                        }
                    });
                }

                if (stablingRequests) {
                    formattedStablingRequests = await formatStablingRequests(stablingRequests);
                }

                let beddingRequestList: BeddingRequest[] = [];
                const beddingQueryResult = await getBeddingRequestsByEntryId(entry.id);
                if (beddingQueryResult.isSuccess) {
                    beddingRequestList = beddingQueryResult.result;
                }

                const eventResultsResult = await getEventResultsByEntryId(entry.id);
                const eventResults: EventResult[] = eventResultsResult?.result;

                const dataObject: InvoicePDFData = {
                    formattedClassData,
                    previousEventEntryFees,
                    formattedStablingRequests,
                    beddingRequests: beddingRequestList,
                    eventResults
                };

                doc = await generateInvoicePDF(entry, event, dataObject, settings, doc);
                if (i !== (sortedEventEntries.length-1)) doc.addPage();
            }
        }
    }
    
    // After adding all pages, add the footers
    addFooters(doc);

    // we define the name of our PDF file.
    let pdfName = "_Invoices.pdf";
    if (settings?.selectedEntry) pdfName = `invoice_${settings?.selectedEntry.number ? settings?.selectedEntry.number + "_" : ""}${settings?.selectedEntry.horseName}.pdf`;
    else if (settings?.selectedTrainer) pdfName = settings?.selectedTrainer.name + pdfName;
    else if (settings?.selectedBarn) pdfName = settings?.selectedBarn.name + pdfName;
    else pdfName = "All" + pdfName;
    doc.save(pdfName);
}