import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, EventEntry } from "../../../models";
import DisplayEntryHorseInfo from "./DisplayEntryHorseInfo";
import DisplayEntryOwnerInfo from "./DisplayEntryOwnerInfo";
import DisplayEntryRiderInfo from "./DisplayEntryRiderInfo";
import DisplayEntryTrainerInfo from "./DisplayEntryTrainerInfo";
import DisplayEntryPrimaryContactInfo from "./DisplayEntryPrimaryContactInfo";
import { chevronDown, chevronUp } from "ionicons/icons";

interface _Props {
    entry: EventEntry
    isCamp?: Boolean
    isExpanded?: boolean
}

const DisplayEntryInfo: React.FC<_Props> = ({entry, isCamp, isExpanded}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    // useEffect(() => {
    //     if (entry) setIsCollapsed(true);
    // }, [entry]);

    useEffect(() => {
        if (isExpanded) setIsCollapsed(false);
        else setIsCollapsed(true);
    }, [isExpanded]);

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h3>{isCamp ? "Registration" : "Entry"} Info</h3>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? <IonIcon icon={chevronDown} />  : <IonIcon icon={chevronUp} />}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {!isCamp && (
                            <>
                                <DisplayEntryHorseInfo entry={entry} />
                                <DisplayEntryOwnerInfo entry={entry} />
                            </>
                        )}
                        <DisplayEntryRiderInfo entry={entry} />
                        <DisplayEntryRiderInfo entry={entry} secondary={true} />
                        {!isCamp && (<DisplayEntryTrainerInfo entry={entry} />)}
                        <DisplayEntryPrimaryContactInfo entry={entry} />
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default DisplayEntryInfo;