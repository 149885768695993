import { IonButton, IonCol, IonRow, isPlatform } from "@ionic/react";
import React from "react";
import AvatarEditor from "react-avatar-editor";

interface IProps {picture: File, handleEditedFile: Function, handleEditedURL: Function};

interface IState {picture: File, pictureURL: string, pictureBlob: Blob | null};

class PictureEditor extends React.Component<IProps, IState> {

    editor!: AvatarEditor;

    constructor(props: any) {
        super(props);

        this.state = {
            picture: this.props.picture,
            pictureURL: "",
            pictureBlob: null
        };
        
    }

    setEditorRef = (editor: any) => {
        if (editor) {
            this.editor = editor;

            const dataURL = this.editor.getImageScaledToCanvas().toDataURL('image/svg+xml');
            if (this.state.pictureURL !== dataURL) {
                this.setState({pictureURL: dataURL});
            }

            const currentThis = this;
            this.editor.getImageScaledToCanvas().toBlob(function(blob) {
                if (currentThis.state.pictureBlob !== blob) currentThis.setState({pictureBlob: blob})
            }, 'image/jpeg', 1);
        }
    }

    handleSubmit = () => {
        this.props.handleEditedURL(this.state.pictureURL);
        this.props.handleEditedFile(this.state.pictureBlob);
    }

    render() {
        return (
            <IonRow>
                <IonCol>
                    <IonRow className="ion-align-items-center">
                        <IonCol sizeSm="3" sizeMd="12" className="text-center">
                            <AvatarEditor
                                ref={(ref) => this.setEditorRef(ref)}
                                image={this.state.picture}
                                width={isPlatform("mobile") ? 175 : 250}
                                height={isPlatform("mobile") ? 175 : 250}
                                border={50}
                                borderRadius={200}
                                color={[255, 255, 255, 0.6]} // RGBA
                                scale={1}
                                rotate={0}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeMd="4">
                            <IonButton color="tertiary" onClick={() => this.handleSubmit()}>Save</IonButton>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        )
    }
}

export default PictureEditor