import { Event, S3Object } from "../../../models";
import {
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Storage from "@aws-amplify/storage";
import moment from "moment";

interface FormattedDocument {
    url: string
    document: S3Object
    lastUpdated: string
}

interface _Props {
    event: Event
}

const EventDisplayDocuments: React.FC<_Props> = ({event}) => {
    const [formattedDocuments, setFormattedDocuments] = useState<FormattedDocument[]>();

    async function formatDocuments(documents: any[]) {
        let formattedArray: FormattedDocument[] = [];
        if (documents) {
            for (var i = 0; i < documents.length; i++) {
                const document = documents[i];
                const url = await handleGetDocument(document.key);
                const formattedDocument: FormattedDocument = {
                    url: (url && typeof(url) === "string") ? url : "unknown",
                    document,
                    lastUpdated: moment(document.dateUploaded).format("MM/DD/YYYY hh:mm a")
                };
                formattedArray.push(formattedDocument);
            }
        }
        const sorted = formattedArray.sort((a, b) => b.lastUpdated.localeCompare(a.lastUpdated));
        setFormattedDocuments(sorted || formattedArray);
    }

    useEffect(() => {
        if (event && event.documents) {
            formatDocuments(event.documents);
        }
    }, [event]);

    async function handleGetDocument(fileName: string): Promise<object | string> {
        try {
            return await Storage.get(fileName);
        } catch (error: any) {
            console.error("Error while getting the document: ", error);
            return "";
        }
    }

    return (
        <>
            {formattedDocuments && formattedDocuments.length ?
                <>
                    <IonRow>
                        <IonCol>
                            <IonList className="bg-white">
                                {formattedDocuments.map((formattedDocument, index) => (
                                    <IonItem key={index}>
                                        <IonLabel>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <a href={formattedDocument.url} rel="noopener noreferrer"  target="_blank" >
                                                        <h2 className="ion-text-wrap link">{formattedDocument.document && formattedDocument.document.title}</h2>
                                                    </a>
                                                </IonCol>
                                                <IonCol size="12">
                                                    <p className="description ion-text-wrap">(Last Updated: {formattedDocument.lastUpdated})</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                    </IonItem>
                                ))}
                            </IonList>
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <IonRow>
                        <IonCol>
                            {/* <IonItem>
                                <IonLabel><h3>No documents listed.</h3></IonLabel>
                            </IonItem> */}
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default EventDisplayDocuments;