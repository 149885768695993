import { Membership } from "../../models";

var moment = require("moment");

/**
 * Sorts the memberships by createdOn from newest to oldest
 * 
 * @param data 
 * @returns memberships sorted newest to oldest
 */
export function sortMembershipsByDate(data: Membership[]) {
    try {
        let result: Membership[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => new moment(b.createdOn).format("YYYYMMDDHHmmss") - new moment(a.createdOn).format("YYYYMMDDHHmmss"));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: membership");
        console.error(error);
        return null;
    }
};