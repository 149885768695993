import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateTeamInput, CreateTeamMutation, DeleteTeamInput, DeleteTeamMutation, GetTeamQuery, ListTeamsQuery, UpdateTeamInput, UpdateTeamMutation } from "../../API";
import moment from "moment";
import { Team } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: team. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created team.
*/
export async function createTeam(input: CreateTeamInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Team", "No input", "Create Team received no input.");
   try {
        const fullInput: CreateTeamInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createTeam, { input: fullInput }))) as GraphQLResult<CreateTeamMutation>;
       else result = (await API.graphql({
           query: mutations.createTeam,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateTeamMutation>;
       const team = result.data?.createTeam;
       return formatResult(true, "Team", team, "Successfully created the team.");
   } catch (error: any) {
       return formatResult(false, "Team", error, "Error creating record in the database for type: team");
   }
}

/**
* Handle updating a new record in the database for type: team. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated team.
*/
export async function updateTeam(input: UpdateTeamInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Team", "No input", "Update Team received no input.");
   try {
        const fullInput: UpdateTeamInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateTeam, { input: fullInput }))) as GraphQLResult<UpdateTeamMutation>;
       else result = (await API.graphql({
           query: mutations.updateTeam,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateTeamMutation>;
       const team = result.data?.updateTeam;
       return formatResult(true, "Team", team, "Successfully updated the team.");
   } catch (error: any) {
       return formatResult(false, "Team", error, "Error updating record in the database for type: team");
   }
}

/**
* Handle deleting a new record in the database for type: team. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted team.
*/
export async function deleteTeam(input: DeleteTeamInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Team", "No input", "Delete Team received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteTeam, { input: input }))) as GraphQLResult<DeleteTeamMutation>;
       else result = (await API.graphql({
           query: mutations.deleteTeam,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteTeamMutation>;
       const team = result.data?.deleteTeam;
       return formatResult(true, "Team", team, "Successfully deleted the team.");
   } catch (error: any) {
       return formatResult(false, "Team", error, "Error deleting record in the database for type: team");
   }
}

/**
* Get all records in the database for type: team. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the team object
*/
export async function getAllTeams(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listTeams,
            variables: {
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTeamsQuery>;

        let items = result.data?.listTeams?.items as Team[];
        let nextToken = result.data?.listTeams?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listTeams,
                variables: {
                    limit: 1000,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTeamsQuery>;

            const nextItems = nextResult.data?.listTeams?.items as Team[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTeams?.nextToken;
        }

        return formatResult(true, "Team", items, "Successfully got the Teams.");
    } catch (error: any) {
        return formatResult(false, "Team", error, "Error reading record in the database for type: teams");
    }
}

/**
* Get all records in the database for type: team. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the team object
*/
export async function getSegmentOfTeams(segmentSize: number, previousNextToken?: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        let variables: any = {
            limit: segmentSize
        };
        if (previousNextToken) {
            variables["nextToken"] = previousNextToken;
        }
        const result = (await API.graphql({
            query: queries.listTeams,
            variables: variables,
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTeamsQuery>;

        let items = result.data?.listTeams?.items as Team[];
        let nextToken = result.data?.listTeams?.nextToken;

        const resultObject = {
            items: items,
            nextToken: nextToken
        };

        return formatResult(true, "Team", resultObject, "Successfully got the Teams.");
    } catch (error: any) {
        return formatResult(false, "Team", error, "Error reading record in the database for type: teams");
    }
}

/**
* Read a specific record in the database for type: team. 
* 
* @param {string}  id                  The team id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the team object
*/
export async function getTeamById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getTeam,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetTeamQuery>;
       const team = result.data?.getTeam;
       return formatResult(true, "Team", team, "Successfully got the team.");
   } catch (error: any) {
       return formatResult(false, "Team", error, "Error reading record in the database for type: team");
   }
}

/**
* Get all records in the database that match the given criteria for type: team. 
* 
* @param {string}  name                The state name of the team.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the team object
*/
export async function getTeamByName(name: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {name: {eq: name}};
        const result = (await API.graphql({
            query: queries.listTeams,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTeamsQuery>;
        const teams = result.data?.listTeams?.items;
        if (teams && teams[0]) return formatResult(true, "Team", teams[0], "Successfully got the team.");
        else return formatResult(false, "Team", null, "Could not find the team.");
    } catch (error: any) {
        return formatResult(false, "Team", error, "Error reading record in the database for type: team");
    }
}

/**
* Get all records in the database that match the given criteria for type: team. 
* 
* @param {string}  personId            The person id of the "owner" of the team
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the team object
*/
export async function getTeamsByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {creatorId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listTeams,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTeamsQuery>;

        let items = result.data?.listTeams?.items as Team[];
        let nextToken = result.data?.listTeams?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listTeams,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTeamsQuery>;

            const nextItems = nextResult.data?.listTeams?.items as Team[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTeams?.nextToken;
        }

        return formatResult(true, "Team", items, "Successfully got the Teams.");
    } catch (error: any) {
        return formatResult(false, "Team", error, "Error reading record in the database for type: teams");
    }
}