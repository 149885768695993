import { MembershipTypeSchedule } from "../../models/index";

export interface LengthTypeOption {
    name: string
    value: string
}

function formatTypeName(value: string) {
    let name = value.replace(/_/g, " ");
    switch(value) {
        case "season":
            return "Season"
        case "annual":
            return "Annual"
        case "life":
            return "Lifetime"
        default:
            return name;
    };
}

/**
 * Takes in the enum MembershipTypeSchedule from the models folder
 * Formats the object into an array that can be used to loop over display names with values
 * 
 * @returns an array of objects - {name, value}
 */
export function formatMembershipTypeLength(): LengthTypeOption[] {
    let results: LengthTypeOption[] = [];
    for (const [key, value] of Object.entries(MembershipTypeSchedule)) {
        const object = {
            key: key,
            name: formatTypeName(value),
            value: value
        };
        // Do not show monthly option in drop down
        if(value !== MembershipTypeSchedule.MONTHLY) {
            results.push(object);
        }
    }
    return results;
}
