import React, { useEffect, useState } from "react";
import { InstantSearch, RefinementList } from "react-instantsearch-dom";
import { IonCol, IonRow, IonSearchbar } from "@ionic/react";
import algoliasearch from "algoliasearch";
import moment from "moment";

// Plug in keys during local development, but remove before committing work
const appId: string = process.env.ALGOLIA_APP_ID|| "JWZGHNODNQ";
const apiKey: string = process.env.ALGOLIA_SEARCH_API_KEY || "6440e1993c2b821292f837add356ba37";
const searchClient = algoliasearch(appId, apiKey);

// const env = process.env.USER_BRANCH;
const indexName = "prod_events";
const index = searchClient.initIndex(indexName);

interface _Props {
    selectedPage: number
    onChangePage: Function
    selectedFilter: ("past" | "current" | "future" | "all")
    onSearch: Function
}

const EventSearchBar: React.FC<_Props> = ({selectedPage, onChangePage, selectedFilter, onSearch }) => {

    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [currentQuery, setCurrentQuery] = useState("");
    const [currentFilterString, setCurrentFilterString] = useState<string>("");

    useEffect(() => {
        handleSearchInput("");
    }, []);

    useEffect(() => {
        setCurrentPageNumber(selectedPage);
        performSearch(currentQuery, selectedPage, currentFilterString);
    }, [selectedPage]);

    const handleSearchInput = async (query: string) => {
        setCurrentQuery(query);
        performSearch(query, currentPageNumber || selectedPage, currentFilterString);
    }

    useEffect(() => {
        handleFilterInput(selectedFilter);
    }, [selectedFilter]);

    const handleFilterInput = async (filter: "all" | "past" | "current" | "future") => {
   
        const currentDateTimestamp = moment(new Date()).unix();
        let filterString = "";
        if (filter === "all") {
            // leave filter string empty
        } else if (filter === "past") {
            // The current date must be greater than the end date of the event
            filterString = `endDate_timestamp < ${currentDateTimestamp}`;
        } else if (filter === "future") {
            // The current date must be less than the start date of the event
            filterString = `startDate_timestamp > ${currentDateTimestamp}`;
        } else if (filter === "current") {
            // The current date must be greater than the start date of the event
            // The current date must be less than the end date of the event
            filterString = `startDate_timestamp <= ${currentDateTimestamp} AND endDate_timestamp >= ${currentDateTimestamp}`;
        }
        setCurrentFilterString(filterString);
        performSearch(currentQuery, 0, filterString);
    }
    
    const performSearch = (queryString: string, selectedPageNumber?: number, filterString?: string) => {
        index.search(queryString, {
            filters: filterString,
            page: selectedPageNumber ? selectedPageNumber : 0
        }).then((results) => {
            onSearch(results.hits);
            onChangePage(results.page, results.nbPages);
        }).catch((error: any) => {
            console.error(error);
        });
    };

    return (
        <>
            <InstantSearch searchClient={searchClient} indexName={indexName}>
                <IonRow className="ion-justify-content-center">
                    <IonCol>
                        <IonSearchbar 
                            color="white"  
                            placeholder="Search" 
                            onIonChange={e => {
                                handleSearchInput(e.detail.value!)
                            }}
                        ></IonSearchbar>
                        <RefinementList
                            attribute="type"
                        />
                    </IonCol>
                </IonRow>
            </InstantSearch>
        </>
    );
};

export default EventSearchBar;
