import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Membership, Organization, OrganizationMembershipType, Person, TeamMember } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { getPersonByPersonId } from "../../utilities/person/Person";
import SingleMemberContactInfo from "../Applications/ContactInfo.tsx/SingleMemberContactInfo";
import { getOrganizationById } from "../../utilities/organization/Organization";
import { getOrganizationMembershipTypeById } from "../../utilities/organizationMembershipType/OrganizationMembershipType";

interface _Props {
    membership?: (Membership | null)
    currentUserRoles?: ("admin" | "coach" | "creator" | "member")[]
}

const ViewTeamMemberContactInfo: React.FC<_Props> = ({membership, currentUserRoles}) => {

    const [currentOrganization, setCurrentOrganization] = useState<Organization | null | undefined>();
    const [currentOrganizationMemType, setCurrentOrganizationMemType] = useState<OrganizationMembershipType | null | undefined>();
    const [selectedPerson, setSelectedPerson] = useState<Person | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const getMembershipData = async (membership: Membership) => {
        // Try to find the organization
        let currentOrg: Organization | undefined | null = membership?.organization;
        if (!currentOrg && membership?.organizationId) {
            const organizationQueryResult = await getOrganizationById(membership.organizationId);
            if (organizationQueryResult.isSuccess) {
                currentOrg = organizationQueryResult.result;
            }
        }
        setCurrentOrganization(currentOrg);

        // Try to find the organization membership type along with it's application fields
        let currentOrgMemType: OrganizationMembershipType | undefined | null = membership?.organizationMembershipType;
        if ((!currentOrgMemType || !currentOrgMemType.applicationFields) && membership.organizationMembershipTypeId) {
            const organizationMemTypeQueryResult = await getOrganizationMembershipTypeById(membership.organizationMembershipTypeId);
            if (organizationMemTypeQueryResult.isSuccess) {
                currentOrgMemType = organizationMemTypeQueryResult.result;
            }
        }
        setCurrentOrganizationMemType(currentOrgMemType);

        // Try to find the person on the membership
        const personQueryResult = await getPersonByPersonId(membership.personId);
        if (personQueryResult.isSuccess) {
            const personOnMembership = personQueryResult.result;
            setSelectedPerson(personOnMembership);
        }
    }

    useEffect(() => {
        if (membership) getMembershipData(membership);
    }, [membership]);

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="4">
                        Contact Info
                    </IonCol>
                </IonRow>
            </IonCardTitle>
            {error && <ErrorAlert error={error} />}
            {isLoading ?
                <Spinner />
                :
                <IonCardContent>
                    <p>Test: {(currentUserRoles ? ((currentUserRoles?.includes("admin") || currentUserRoles?.includes("creator")) ? "1- true" : "1- false") : "2- true")}</p>
                    {/* Only org admins or the person who created the team member can update the team member's contact */}
                    {(membership && (membership.organization || currentOrganization) && currentOrganizationMemType) ?
                        <SingleMemberContactInfo 
                            memberships={[membership]}
                            personId={membership.personId}
                            user={selectedPerson}
                            setMemberships={() => console.log("Set Memberships was called")}
                            membershipType={currentOrganizationMemType} 
                            organization={membership.organization || currentOrganization}
                            isDisabled={!(currentUserRoles?.includes("admin") || currentUserRoles?.includes("creator"))} 
                        />
                        :
                        <p>Cannot display data</p>
                    }
                    
                </IonCardContent>
            }
        </IonCard>
    );
};

export default ViewTeamMemberContactInfo;