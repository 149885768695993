import "./../../theme/main.css";
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonHeader,
    IonModal,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import { Organization } from "../../models";
import { PersonContext } from "../../context/PersonContext";
import { createExpressAccount } from "../../utilities/stripe/ExpressAccount";
import { updateOrganization } from "../../utilities/organization/Organization";

import stripeLogo from "../../assets/img/partners/stripe.png";
import Spinner from "../Spinners/Spinner";
import { UpdateOrganizationInput } from "../../API";

interface _Props {
    organization: Organization
    isModalOpen: boolean
}

const CreateEventAddFinancialModal: React.FC<_Props> = ({isModalOpen, organization}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(isModalOpen);
    const [error, setError] = useState("");

    useEffect(() => {
        setShowModal(isModalOpen);
    }, [isModalOpen]);

    const handleContinue = async () => {
        setIsLoading(true);

        if (organization) {
            const setUpUserId = user.id;
            const organizationId = organization.id;
            const organizationName = organization.name;
            const organizationType = organization.type;
            const organizationWebsite = organization.website;

            const createResult = await createExpressAccount(setUpUserId, organizationId, organizationName, organizationType, organizationWebsite);
            if (createResult.isSuccess) {
                const data = createResult.result;
                const accountId = data.connectedAccountId;
                let orgResult;
                if (organization) {
                    const input: UpdateOrganizationInput = {
                        id: organization.id,
                        stripeId: accountId
                    };
                    orgResult = await updateOrganization(input);
                } else {
                    setIsLoading(false);
                    setError("Sorry, we could not perform the set up at this time. Please verify you have created an organization.");
                    return;
                }
    
                if (orgResult.isSuccess) {
                    const onboardingLink = data.onboardingLink;
                    window.open(onboardingLink);
                } else {
                    setIsLoading(false);
                    setError("Sorry, we could not perform the set up at this time. Please verify you have created an organization.");
                    return;
                }
            }
        }
        setShowModal(false);
    };

    return (
        <IonModal backdropDismiss={false} isOpen={showModal} id="termsAndConditionsModal" mode="md">
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Set Up Credit Card Payments
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeTermsAndConditionsModalBtn" className="font-weight-normal text-medium text-capitalize">
                                X
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {error && <ErrorAlert width="12" error={error} />}
                {isLoading ?
                    <Spinner />
                    :
                    <>
                        <IonRow>
                            <IonCol>
                                <IonText>Before setting up an event that accepts credit cards, you must set up your organization's financial information with Stripe, a secure online payment platform.</IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonText>This will allow us to transfer your organization's profits from the credit card transactions to your bank account or debit card.</IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonText>Stripe is a trusted online payment platform used industry-wide. If you would like to learn more, check out their information <span className="text-info link"><a href="www.stripe.com">here.</a></span></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <img alt="Stripe Image" src={stripeLogo} />
                            </IonCol>
                        </IonRow>
                    </>
                }
            </IonContent>
            <IonButton slot="end" id="continueCreateEventAddFinancialModalBtn" size="default" expand="block" onClick={() => handleContinue()}>
                Continue
            </IonButton>
        </IonModal>
    );
};

export default CreateEventAddFinancialModal;
