import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateHorseShowRatingInput, CreateHorseShowRatingMutation, DeleteHorseShowRatingInput, DeleteHorseShowRatingMutation, GetHorseShowRatingQuery, ListHorseShowRatingsQuery, UpdateHorseShowRatingInput, UpdateHorseShowRatingMutation } from "../../API";
import { HorseShowRating } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: HorseShowRating. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created HorseShowRating.
*/
export async function createHorseShowRating(input: CreateHorseShowRatingInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "HorseShowRating", "No input", "Create HorseShowRating received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createHorseShowRating, { input: input }))) as GraphQLResult<CreateHorseShowRatingMutation>;
        else result = (await API.graphql({
            query: mutations.createHorseShowRating,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<CreateHorseShowRatingMutation>;
        const HorseShowRating = result.data?.createHorseShowRating;
        return formatResult(true, "HorseShowRating", HorseShowRating, "Successfully created the HorseShowRating.");
    } catch (error: any) {
        return formatResult(false, "HorseShowRating", error, "Error creating record in the database for type: HorseShowRating");
    }
}

/**
* Handle updating a new record in the database for type: HorseShowRating. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated HorseShowRating.
*/
export async function updateHorseShowRating(input: UpdateHorseShowRatingInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "HorseShowRating", "No input", "Update HorseShowRating received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateHorseShowRating, { input: input }))) as GraphQLResult<UpdateHorseShowRatingMutation>;
        else result = (await API.graphql({
            query: mutations.updateHorseShowRating,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<UpdateHorseShowRatingMutation>;
        const HorseShowRating = result.data?.updateHorseShowRating;
        return formatResult(true, "HorseShowRating", HorseShowRating, "Successfully updated the HorseShowRating.");
    } catch (error: any) {
        return formatResult(false, "HorseShowRating", error, "Error updating record in the database for type: HorseShowRating");
    }
}

/**
* Handle deleting a new record in the database for type: HorseShowRating. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted HorseShowRating.
*/
export async function deleteHorseShowRating(input: DeleteHorseShowRatingInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "HorseShowRating", "No input", "Delete HorseShowRating received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteHorseShowRating, { input: input }))) as GraphQLResult<DeleteHorseShowRatingMutation>;
       else result = (await API.graphql({
           query: mutations.deleteHorseShowRating,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteHorseShowRatingMutation>;
       const HorseShowRating = result.data?.deleteHorseShowRating;
       return formatResult(true, "HorseShowRating", HorseShowRating, "Successfully deleted the HorseShowRating.");
   } catch (error: any) {
       return formatResult(false, "HorseShowRating", error, "Error deleting record in the database for type: HorseShowRating");
   }
}

/**
* Get all records in the database for type: HorseShowRating. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the HorseShowRating object
*/
export async function getAllHorseShowRatings(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listHorseShowRatings,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListHorseShowRatingsQuery>;

        let items = result.data?.listHorseShowRatings?.items as HorseShowRating[];
        let nextToken = result.data?.listHorseShowRatings?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listHorseShowRatings,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListHorseShowRatingsQuery>;

            const nextItems = nextResult.data?.listHorseShowRatings?.items as HorseShowRating[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listHorseShowRatings?.nextToken;
        }

        return formatResult(true, "HorseShowRating", items, "Successfully got the HorseShowRatings.");
    } catch (error: any) {
        return formatResult(false, "HorseShowRating", error, "Error reading record in the database for type: HorseShowRatings");
    }
}

/**
* Read a specific record in the database for type: HorseShowRating. 
* 
* @param {string}  id                  The HorseShowRating id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the HorseShowRating object
*/
export async function getHorseShowRatingById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getHorseShowRating,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetHorseShowRatingQuery>;
       const HorseShowRating = result.data?.getHorseShowRating;
       return formatResult(true, "HorseShowRating", HorseShowRating, "Successfully got the HorseShowRating.");
   } catch (error: any) {
       return formatResult(false, "HorseShowRating", error, "Error reading record in the database for type: HorseShowRating");
   }
}

/**
* Get all records in the database that match the given criteria for type: HorseShowRating. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the HorseShowRating object
*/
export async function getHorseShowRatingsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listHorseShowRatings,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListHorseShowRatingsQuery>;

        let items = result.data?.listHorseShowRatings?.items as HorseShowRating[];
        let nextToken = result.data?.listHorseShowRatings?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listHorseShowRatings,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListHorseShowRatingsQuery>;

            const nextItems = nextResult.data?.listHorseShowRatings?.items as HorseShowRating[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listHorseShowRatings?.nextToken;
        }

        return formatResult(true, "HorseShowRating", items, "Successfully got the HorseShowRatings.");
    } catch (error: any) {
        return formatResult(false, "HorseShowRating", error, "Error reading record in the database for type: HorseShowRatings");
    }
}