import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps, useHistory } from "react-router";
import { Barn, BarnMember } from "../../../../models";
import { getBarnById } from "../../../../utilities/barn/Barn";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import EditPeopleTable from "../../../../components/Rider/EditPeopleTable";
import { BarnPerson } from "../../../../interfaces/Person";


interface BarnPageProps extends RouteComponentProps<{
    id: string;
}> {}

const BarnPeoplePage: React.FC<BarnPageProps> = ({match}) => {
    const history = useHistory();

    const [barn, setBarn] = useState<Barn>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getBarn() {
            const queryResult = await getBarnById(match.params.id);
            if (queryResult.isSuccess) {
                setBarn(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getBarn();
    }, [match]);
    
    const handleSelectRider = (person: BarnPerson, member?: BarnMember) => {
        if (barn) {
            // let path = "/index/staff/barn/" + barn.id + "/member/" + member.id;
            let path = "/index/staff/barn/" + barn.id + "/person/";
            if (person.riderId) path = path + "rider/" + person.riderId;
            else if (person.ownerId) path = path + "owner/" + person.ownerId;
            else if (person.trainerId) path = path + "trainer/" + person.trainerId;
            history.push(path);
        } else {
            setError("Could not go to the edit person page.");
        }
    }   

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={barn ? barn.name : "People"} />
                {error && <ErrorAlert width="12" error={error} />}
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <EditPeopleTable barn={barn} onSelect={handleSelectRider} />
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default BarnPeoplePage;