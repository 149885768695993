import { IonLabel } from "@ionic/react";
import React, { useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { AddressTypeOption, addressTypes } from "./AddressTypes";

interface _Props {
    onInputChange: Function
}

const SelectAddressType: React.FC<_Props> = ({onInputChange}) => {
    const [type, setType] = useState<string>("-");

    return (
        <>
            <FormGroup>
                <IonLabel>Address Type</IonLabel>
                <Input 
                    name="type" 
                    type="select" 
                    value={type} 
                    onChange={e => {
                        setType(e.target.value);
                        onInputChange(e.target.value)
                    }}
                >
                    <option>-</option>
                    {addressTypes.map((option: AddressTypeOption, index: number) => (
                        <option value={option.value} key={option.name}>{option.name}</option>
                    ))}
                </Input>
            </FormGroup>
        </>
    );
};

export default SelectAddressType;