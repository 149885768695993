import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonIcon,
    IonLabel,
    IonRow,
    IonText,
    isPlatform,
} from "@ionic/react";
import React from "react";
import { add } from "ionicons/icons";
import { Event } from "../../models";
import { useHistory } from "react-router";

interface _Props {
    event: Event
}

const AddEventDivisionCard: React.FC<_Props> = ({event}) => {
    const history = useHistory();

    const navigateToPage = () => {
        if (event) {
            const path = "/index/staff/event/" + event.id + "/division/new";
            history.push(path);
        }
    }

    return (
        <>
            {isPlatform("mobile") ? 
                <IonButton expand="full" onClick={navigateToPage}>
                    <IonIcon icon={add} size="large" />
                    <IonText>Add Division</IonText>
                </IonButton>
                :
                <IonButton onClick={navigateToPage}>
                    <IonIcon icon={add} size="large" />
                    <IonText>Add Division</IonText>
                </IonButton>
            }
        </>
    );
};

export default AddEventDivisionCard;
