import React, { useEffect, useState } from "react";
import { IonItem, IonLabel, IonRow, IonCol, IonInput, IonText, IonButton } from "@ionic/react";
import BasicTooltip from "../../Tooltip/BasicTooltip";
import PriceField from "../../Forms/PriceField";

interface _Props {
    selectedEntryFee?: number
    onSelect: Function
}

const DivisionEntryFee: React.FC<_Props> = ({selectedEntryFee, onSelect}) => {
    const [isHidden, setIsHidden] = useState(true);
    const [entryFee, setEntryFee] = useState(0);

    useEffect(() => {
        if (selectedEntryFee) setEntryFee(selectedEntryFee);
        else setEntryFee(0);
    }, [selectedEntryFee]);

    const handleInput = (input: number) => {
        setEntryFee(input);
        onSelect("division", input);
    }
    
    return (
        <>
            {isHidden ?
                <IonText>
                    Entry Fee: ${entryFee.toFixed(2)} per division <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(false)}>Edit</IonButton>
                </IonText>
                :
                <>
                    <IonRow>
                        <IonCol className="text-center">
                            <BasicTooltip 
                                label="Division Entry Fee"
                                tip="Set the entry fee each exhibitor will be charged if they enter this division."
                            />
                            <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(true)}>Hide</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white" lines="none">
                                <IonLabel position="stacked">Division Entry Fee</IonLabel>
                                <PriceField value={entryFee} className="ion-margin-top" onChange={handleInput}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default DivisionEntryFee;
