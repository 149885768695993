import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCol, 
    IonContent, 
    IonHeader,  
    IonPage, 
    IonRow,
    IonText
} from "@ionic/react";
import React from "react";
import { Card, CardBody, Container } from "reactstrap";
import WebNavbar from "../../components/Navbars/WebNavbar";
import {Link} from "react-router-dom";
import Footer from "../../components/Footers/Footer";
import logo from "./../../assets/img/brand/LogoWithTextBlock.png";
import alex from "../../assets/img/general/Alex.jpg";
import addie from "../../assets/img/general/Addie.jpg";
import lindsey from "../../assets/img/general/Lindsey.jpg";
import heather from "../../assets/img/general/Heather.jpg";
import Testimonial from "../../components/Testimonials/Testimonial";
import DropDownCard from "../../components/Cards/DropdownCard";
import DisplayClinicianTable from "../../components/Clinician/DisplayClinicianTable";

const AboutClinicsPage: React.FC = () => {

    return (
        <IonPage id="webAboutClinicsPage">
            <IonHeader>
                <WebNavbar />
                <IonRow className="bg-white">
                    <IonCol className="ion-text-center">
                        <p>Interested in group clinics? To fill out the interest form <a className="link" href="/clinics">click here.</a></p>
                    </IonCol>
                </IonRow>
            </IonHeader>
            <IonContent>
                <section id="online-entries" className="py-5 bg-light overflow-hidden">
                    <Container fluid>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="8" className="text-center">
                                <IonRow className="ion-justify-content-center ion-align-items-center">
                                    <IonCol sizeXs="8" sizeLg="4" className="ion-text-center">
                                        <img
                                            className="img"
                                            alt="RingSide Pro"
                                            src={logo}
                                        />
                                    </IonCol>
                                </IonRow>
                                <IonRow className="justify-content-center mb-3">
                                    <IonCol size="12" className="text-center">
                                        <h1 className="text-primary pb-3">
                                            Online Clinics
                                        </h1>
                                        <h2 className="text-primary py-3">
                                            Elevate Your Riding.
                                        </h2>
                                        <h3 className="text-primary py-3">
                                            Supportive feedback helps you feel confident in the saddle.
                                        </h3>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" className="text-center">
                                <img className="shadow bg-white rounded-1 img" alt="Rider Img" src={lindsey}/>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-white">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">How It Works</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Upload Videos
                                        </h4>
                                        <p className="description mt-3">
                                            Your free account comes with a personal library where you can store and organize all of your riding videos. Select one of your videos to start a clinic.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Pick Clinicians
                                        </h4>
                                        <p className="description mt-3">
                                            Select as many clinicians as you like. Their experience includes hunters, jumpers, equitation, dressage and western. Learn more about them below.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Get Feedback
                                        </h4>
                                        <p className="description mt-3">
                                            Submit your video and questions. Receive your feedback in 2 weeks or less. The clinicians want to help you succeed and build your confidence. Experience it today.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-light">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">Clinicians</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center mb-3">
                            <IonCol size="12">
                                <IonCard className="bg-white ion-padding">
                                    <IonCardSubtitle>
                                        <p>Click on a row to see more about the clinician.</p>
                                    </IonCardSubtitle>
                                    <IonCardContent>
                                        <DisplayClinicianTable />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center mb-3">
                            <IonCol size="12" className="ion-text-center">
                                <a href="/sample-feedback">
                                    <IonButton>
                                        See Example Feedback
                                    </IonButton>
                                </a>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">Pricing</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary">
                            <IonCol size="12" className="ion-text-center">
                                <p className="text-primary">
                                    Each clinician sets their own prices.
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Fair Prices
                                        </h4>
                                        <p className="description mt-3">
                                            We encourage clinicians to set their prices based on the amount of time that they will spend reviewing your video and writing your feedback.  
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Group Savings
                                        </h4>
                                        <p className="description mt-3">
                                            Have friends that want to join you? We are able to work with you to provide discounts if you want to submit multiple videos as a group.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Gift Cards
                                        </h4>
                                        <p className="description mt-3">
                                            Looking for a gift for a rider? You can send them a gift card for an online clinic! Learn more and send a gift card <a className="link" href="https://bit.ly/RSPGiftCards">by clicking here.</a> 
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section id="testimonials" className="py-5 bg-primary overflow-hidden">
                    <Container fluid>
                        <IonRow className="justify-content-center">
                            <IonCol size="12" className="ion-text-center">
                                <h2 className="text-white pb-3">
                                    Rider Reviews
                                </h2>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeMd="9" sizeLg="9">
                                <IonCard color="white">
                                    <IonCardContent>
                                        <IonRow className="ion-align-items-center">
                                            <IonCol sizeXs="12" sizeMd="4">
                                                <IonRow className="justify-content-center">
                                                    <IonCol sizeXs="8" sizeMd="6" className="ion-text-center">
                                                        <img className="shadow bg-white text-center rounded-1-circle img" alt="testimonial" src={heather}/>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="justify-content-center">
                                                    <IonCol className="ion-text-center">
                                                        <h4>Heather T.</h4>
                                                        <p className="description">Rider</p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="8">
                                                <h2 className="text-primary pb-2">"My experience with RingSide Pro has been overwhelmingly positive. I was struggling with a specific issue, and the professionals at RingSide Pro suggested I submit a video to Mary O'Connor. Not only was Mary able to help with my specific questions, she was able to identify the root causes of our challenges and suggest exercises for improvement.</h2>
                                                <h2 className="text-primary"><span className="font-weight-bold">I feel like I have found a powerful new ally and mentor for my riding journey</span>....and it was so incredibly fast and easy to do. I highly recommend RingSide Pro to any rider who wants an experienced outside perspective and guidance."</h2>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeMd="4" sizeLg="3">
                                <Testimonial name="Lindsey D." label="Rider" image={lindsey} quote="The feedback I received was really helpful and educational! Overall, it was very in depth and each piece of advice was practical and something that I could apply to my riding. I recommend it to any rider!" />
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4" sizeLg="3">
                                <Testimonial name="Addie L." label="Rider" image={addie} quote="I was nervous about submitting a video and the feedback I would receive, but after submitting my video, I’m sad I didn’t try it sooner! The feedback was supportive and positive. It helped me feel prepared for my next show." />
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4" sizeLg="3">
                                <Testimonial name="Alex I." label="Rider" image={alex} quote="It was so easy to send in a video, and I got my feedback almost immediately! I loved that I not only got feedback on my riding, but I also received great training exercises for my horse. I’ve already seen an improvement!" />
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-light">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">FAQ</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="What types of videos can I submit?" 
                                    content="We leave that up to individual clinicians. When you click on a clinician in the table above, you will see more details including their disciplines and video preferences. We suggest that you keep video lengths on the shorter side. For example: if you submit a hunter video, we suggest a single trip." 
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="How do I submit my questions along with the video?" 
                                    content="When you select a video to submit, you can include a video description. This is where you should tell the clinician more about the video and ask any questions you have. Questions may be about a specific part of the video, about your riding in general or even just a general question. An example of a question you can include is: How do you handle nerves in the show ring?" 
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="How long will it take to get my feedback?" 
                                    content="We give each clinician 2 weeks to return your feedback. Most of the clinicians are active riders, trainers and/or judges. Depending on their schedules, they may be able to return your feedback sooner than 2 weeks from when you submit." 
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="Are there IHSA or IEA specific clinicians?" 
                                    content="While all of our clinicians have great feedback for IHSA or IEA riders, we do include information about their IHSA and IEA experience in the clinicians' profiles. You can see the clinician profiles when you click on a row in the table above."
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="How do I get a group discount?" 
                                    content="Contact us at hello@ringsidepro.com and we will be happy to arrange a group discount for you! We enjoy working with teams, barns or just a group that wants to learn from each other."
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="How do the live clinics work?" 
                                    content="We arrange for group clinics over Zoom so that participants can ask questions directlt to a clinician. If you would like to arrange a group live clinic, email us at hello@ringsidepro.com."
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="How do I use my Online Clinic Gift Card?" 
                                    content="When you check out, you will see a place for a promo code. Use the unique code that comes with your gift card as the promo code. You will see the discount when you click Apply."
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="How do I become a clinician?" 
                                    content="If you are interested in becoming a clinician, you can email us at hello@ringsidepro.com. We will gladly send you more information!"
                                />
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-white">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">We're Here to Help</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary py-3 ion-align-items-space-between">
                            <IonCol size="12" className="ion-text-center py-3">
                                <p>
                                    We're always happy to answer your questions and provide support to both you and your exhibitors.
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Call
                                        </h4>
                                        <p className="description mt-3">
                                            757-812-0385
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Email
                                        </h4>
                                        <p className="description mt-3">
                                            hello@ringsidepro.com
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5">
                    <Container>
                        <IonRow className="justify-content-center">
                            <IonCol size="12">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-center text-uppercase">
                                            Ready to get started?
                                        </h4>
                                        <p className="text-center">
                                            <Link to="/auth/register" className="link text-info">Create an Account</Link>
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default AboutClinicsPage;
