import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRadio,
    IonReorder,
    IonRow,
    IonSearchbar,
    IonTitle,
    IonToolbar,
    isPlatform,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Event, EventClass, EventDivision, PointTable } from "../../models";
import { getEventClassById, getEventClassesByEventIdWithClassesAndEventDivisions, updateEventClass } from "../../utilities/eventClass/EventClass";
import { getEventClassTypeAbbreviation } from "../../utilities/eventClass/EventClassTypes";
import { Input, InputGroup, InputGroupAddon, InputGroupText, Table, Tooltip } from "reactstrap";
import AddEventClassCard from "./AddEventClassCard";
import { getClassById } from "../../utilities/class/Class";
import { getEventDivisionById } from "../../utilities/eventDivision/EventDivision";
import Spinner from "../Spinners/Spinner";
import { onCreateEventClass, onDeleteEventClass, onUpdateEventClass } from "../../graphql/subscriptions";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import { isWindows } from "../../utilities/platform/Platform";
import { PersonContext } from "../../context/PersonContext";
import { close, copyOutline, createOutline } from "ionicons/icons";
import SelectCreatePointTable from "../PointTable/SelectCreatePointTable";
import { UpdateEventClassInput } from "../../API";
import BasicTooltip from "../Tooltip/BasicTooltip";
import SelectEventDivision from "../EventDivision/SelectEventDivision";


interface _Props {
    event: Event
    onSelect: Function
}

interface FormattedEventClass {
    id: string
    name: string
    number: string
    type?: string
    classFee?: string
    divisionId?: string
    divisionName?: string
    divisionFee?: string
    organizationDivisionId?: string
    prizeMoney?: string
    pointTableName: string
    pointTable?: PointTable
    eventClass: EventClass
    isEdited: boolean
}

const EventClassEditTable: React.FC<_Props> = ({event, onSelect}) => {
    const user = useContext(PersonContext);

    const createSubscription = useSubscriptionByItself({
        config: {
            query: onCreateEventClass,
            key: "onCreateEventClass"
        }
    });

    const updateSubscription = useSubscriptionByItself({
        config: {
            query: onUpdateEventClass,
            key: "onUpdateEventClass"
        }
    });

    const deleteSubscription = useSubscriptionByItself({
        config: {
            query: onDeleteEventClass,
            key: "onDeleteEventClass"
        }
    });

    const [currentCreateSubscriptionItem, setCreateCurrentSubscriptionItem] = useState<any>();
    const [currentUpdateSubscriptionItem, setUpdateCurrentSubscriptionItem] = useState<any>();
    const [currentDeleteSubscriptionItem, setDeleteCurrentSubscriptionItem] = useState<any>();

    useEffect(() => {
        if (createSubscription && createSubscription[0] !== undefined && createSubscription[0] !== currentCreateSubscriptionItem) {
            const item = createSubscription[0];
            setCreateCurrentSubscriptionItem(item);
            if (item && item.id) handleCreateEventClass(item.id);
        }
    }, [createSubscription]);

    useEffect(() => {
        if (updateSubscription && updateSubscription[0] !== undefined && updateSubscription[0] !== currentUpdateSubscriptionItem) {
            const item = updateSubscription[0];
            setUpdateCurrentSubscriptionItem(item);
            if (item) handleUpdateEventClass(item.id);
        }
    }, [updateSubscription]);

    useEffect(() => {
        if (deleteSubscription && deleteSubscription[0] !== undefined && deleteSubscription[0] !== currentDeleteSubscriptionItem) {
            const item = deleteSubscription[0];
            setDeleteCurrentSubscriptionItem(deleteSubscription[0]);
            if (item) handleDeleteEventClass(item.id);
        }
    }, [deleteSubscription]);

    const [formattedEventClasses, setFormattedEventClasses] = useState<FormattedEventClass[] | null | undefined>();
    const [filteredEventClasses, setFilteredEventClasses] = useState<FormattedEventClass[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [searchNumberText, setSearchNumberText] = useState("");
    const [searchNameText, setSearchNameText] = useState("");
    const [isEditNumbers, setIsEditNumbers] = useState(false);
    const [isEditNames, setIsEditNames] = useState(false);
    const [isEditFee, setIsEditFee] = useState(false);
    const [isEditEventDivision, setIsEditEventDivision] = useState(false);
    const [isEditPoints, setIsEditPoints] = useState(false);
    const [showEditAllPointTablesModal, setShowEditAllPointTablesModal] = useState(false);

    const handleCreateEventClass = async (eventClassId: string) => {
        const queryResult = await getEventClassById(eventClassId);
        if (queryResult.isSuccess) {
            const currentEventClass = queryResult.result;
            const formattedEventClass = await formatSingleEventClass(currentEventClass);
            const newFormattedList = formattedEventClasses?.concat([formattedEventClass]);
            const sortedFormattedList = newFormattedList?.sort((a: FormattedEventClass, b: FormattedEventClass) => parseInt(a.number) - parseInt(b.number));
            await setFormattedEventClasses((prevValue) => sortedFormattedList);
        }
    }

    const handleUpdateEventClass = async (eventClassId: string) => {
        const queryResult = await getEventClassById(eventClassId);
        if (queryResult.isSuccess) {
            const currentEventClass = queryResult.result;
            const formattedEventClass = await formatSingleEventClass(currentEventClass);
            if (formattedEventClasses) {
                const index = formattedEventClasses?.findIndex(formattedEventClass => formattedEventClass.id === eventClassId);
                const newFormattedList = [
                    ...formattedEventClasses.slice(0, index),
                    formattedEventClass,
                    ...formattedEventClasses.slice(index + 1)
                ];
                setFormattedEventClasses(newFormattedList)
            }
        }
    }

    const handleDeleteEventClass = async (eventClassId: string) => {
        if (formattedEventClasses) {
            const index = formattedEventClasses?.findIndex(formattedEventClass => formattedEventClass.id === eventClassId);
            const newFormattedList = [
                ...formattedEventClasses.slice(0, index),
                ...formattedEventClasses.slice(index + 1)
            ];
            setFormattedEventClasses(newFormattedList)
        }
    }

    const formatSingleEventClass = async (currentEventClass: EventClass) => {
        let object: FormattedEventClass = {
            id: currentEventClass.id,
            name: currentEventClass.name,
            number: currentEventClass.number ? currentEventClass.number.toString() : "",
            prizeMoney: (currentEventClass.prizeMoney && currentEventClass.prizeMoney !== "0") ? ("$" + currentEventClass.prizeMoney) : "",
            pointTableName: currentEventClass.pointTable?.name || "",
            pointTable: currentEventClass.pointTable || undefined,
            eventClass: currentEventClass,
            isEdited: false
        };

        let currentClass = currentEventClass.class;
        if (currentClass) {
            object.type = (currentClass.type ? (getEventClassTypeAbbreviation(currentClass.type)) : "");
            object.classFee = (currentEventClass.entryFee && currentEventClass.entryFee !== "0") ? currentEventClass.entryFee : "";
        } else {
            const classId = currentEventClass.classId;
            const queryClassResult = await getClassById(classId);
            if (queryClassResult.isSuccess) {
                currentClass = queryClassResult.result;
                if (currentClass) {
                    object.type = (currentClass.type ? (getEventClassTypeAbbreviation(currentClass.type)) : "");
                    object.classFee = (currentEventClass.entryFee && currentEventClass.entryFee !== "0") ? currentEventClass.entryFee : "";
                }
            }
        }
        
        let currentDivision = currentEventClass.eventDivision;
        if (currentDivision) {
            object.divisionId = currentDivision.id || "";
            object.divisionName = currentDivision.name;
            object.divisionFee = (currentDivision.entryFee ? "$" + currentDivision.entryFee : "");
            object.organizationDivisionId = currentDivision.organizationDivisionId || "";
        } else {
            const divisionId = currentEventClass.eventDivision?.id;
            if (divisionId) {
                const queryDivisionResult = await getEventDivisionById(divisionId);
                if (queryDivisionResult.isSuccess) {
                    const currentDivision = queryDivisionResult.result;
                    if (currentDivision) {
                        object.divisionId = currentDivision.id || "";
                        object.divisionName = currentDivision.name;
                        object.divisionFee = (currentDivision.entryFee ? "$" + currentDivision.entryFee : "");
                        object.organizationDivisionId = currentDivision.organizationDivisionId || "";
                    }
                }
            }
        }

        return object;
    }

    const formatEventClasses = async (eventClasses: EventClass[]) => {
        let formattedEventClasses = [];
        for (var i = 0; i < eventClasses.length; i++) {
            const currentEventClass = eventClasses[i];
            const formattedEventClass: FormattedEventClass = await formatSingleEventClass(currentEventClass);
            formattedEventClasses.push(formattedEventClass);
        }
        setFormattedEventClasses(formattedEventClasses);
        setFilteredEventClasses(formattedEventClasses);
        setIsLoading(false);
    }

    async function getEventClasses(event: Event) {
        setIsLoading(true);
        const queryResult = await getEventClassesByEventIdWithClassesAndEventDivisions(event.id);
        if (queryResult.isSuccess) {
            formatEventClasses(queryResult.result);
        } else {
            setIsLoading(false);
        }
        
    }

    useEffect(() => {
        if (event && !formattedEventClasses) getEventClasses(event);
    }, [event]);

    const handleSearchInput = (input: string, type: string) => {
        // If search input is empty, reset to all accepted entries
        if (!input || input === "") {
            setFilteredEventClasses(formattedEventClasses);
        }

        // Try search by entry number
        if (type === "number") {
            let result = formattedEventClasses?.filter(eventClass => eventClass.number?.toString().includes(input));
            if (result) {
                setFilteredEventClasses(result);
            }
        }

        // Try search by class name
        if (type === "name") {
            let result = formattedEventClasses?.filter(eventClass => eventClass.name?.toString().includes(input));
            if (result) {
                setFilteredEventClasses(result);
            }
        }
    }

    const handleNumberChange = (number: string, formattedItem: FormattedEventClass, index: number) => {
        if (filteredEventClasses) {
            let updated: FormattedEventClass = {
                ...formattedItem,
                number: number,
                isEdited: true
            }; 
            let updatedArray = [
                ...filteredEventClasses.slice(0, index),
                updated,
                ...filteredEventClasses.slice(index + 1)
            ];
            setFilteredEventClasses(updatedArray);
        }

        if (formattedEventClasses) {
            const foundIndex = formattedEventClasses.findIndex(fec => fec.id === formattedItem.id);
            if (foundIndex > -1) {
                const formattedEventClassObject = formattedEventClasses[foundIndex];
                const updatedObject: FormattedEventClass = {
                    ...formattedEventClassObject,
                    number: number,
                    isEdited: true
                };
                let updatedArray = [
                    ...formattedEventClasses.slice(0, foundIndex),
                    updatedObject,
                    ...formattedEventClasses.slice(foundIndex + 1)
                ];
                setFormattedEventClasses(updatedArray);
            }
        }
    }

    const handleNameChange = (name: string, formattedItem: FormattedEventClass, index: number) => {
        if (filteredEventClasses) {
            let updated: FormattedEventClass = {
                ...formattedItem,
                name: name,
                isEdited: true
            }; 
            let updatedArray = [
                ...filteredEventClasses.slice(0, index),
                updated,
                ...filteredEventClasses.slice(index + 1)
            ];
            setFilteredEventClasses(updatedArray);
        }

        if (formattedEventClasses) {
            const foundIndex = formattedEventClasses.findIndex(fec => fec.id === formattedItem.id);
            if (foundIndex > -1) {
                const formattedEventClassObject = formattedEventClasses[foundIndex];
                const updatedObject: FormattedEventClass = {
                    ...formattedEventClassObject,
                    name: name,
                    isEdited: true
                };
                let updatedArray = [
                    ...formattedEventClasses.slice(0, foundIndex),
                    updatedObject,
                    ...formattedEventClasses.slice(foundIndex + 1)
                ];
                setFormattedEventClasses(updatedArray);
            }
        }
    }

    const handleFeeChange = (fee: string, formattedItem: FormattedEventClass, index: number) => {
        if (filteredEventClasses) {
            let updated: FormattedEventClass = {
                ...formattedItem,
                classFee: fee,
                isEdited: true
            }; 
            let updatedArray = [
                ...filteredEventClasses.slice(0, index),
                updated,
                ...filteredEventClasses.slice(index + 1)
            ];
            setFilteredEventClasses(updatedArray);
        }

        if (formattedEventClasses) {
            const foundIndex = formattedEventClasses.findIndex(fec => fec.id === formattedItem.id);
            if (foundIndex > -1) {
                const formattedEventClassObject = formattedEventClasses[foundIndex];
                const updatedObject: FormattedEventClass = {
                    ...formattedEventClassObject,
                    classFee: fee,
                    isEdited: true
                };
                let updatedArray = [
                    ...formattedEventClasses.slice(0, foundIndex),
                    updatedObject,
                    ...formattedEventClasses.slice(foundIndex + 1)
                ];
                setFormattedEventClasses(updatedArray);
            }
        }
    }

    const handleEventDivisionChange = (formattedItem: FormattedEventClass, index: number, eventDivision?: EventDivision) => {
        if (filteredEventClasses) {
            let updated: FormattedEventClass = {
                ...formattedItem,
                divisionId: eventDivision?.id || undefined,
                divisionName: eventDivision?.name || "",
                divisionFee: (eventDivision?.entryFee ? "$" + eventDivision.entryFee : ""),
                isEdited: true,
                organizationDivisionId: eventDivision?.organizationDivisionId || undefined
            }; 
            let updatedArray = [
                ...filteredEventClasses.slice(0, index),
                updated,
                ...filteredEventClasses.slice(index + 1)
            ];
            setFilteredEventClasses(updatedArray);
        }

        if (formattedEventClasses) {
            const foundIndex = formattedEventClasses.findIndex(fec => fec.id === formattedItem.id);
            if (foundIndex > -1) {
                const formattedEventClassObject = formattedEventClasses[foundIndex];
                const updatedObject: FormattedEventClass = {
                    ...formattedEventClassObject,
                    divisionId: eventDivision?.id || undefined,
                    divisionName: eventDivision?.name || "",
                    divisionFee: (eventDivision?.entryFee ? "$" + eventDivision.entryFee : ""),
                    isEdited: true,
                    organizationDivisionId: eventDivision?.organizationDivisionId || undefined
                };
                let updatedArray = [
                    ...formattedEventClasses.slice(0, foundIndex),
                    updatedObject,
                    ...formattedEventClasses.slice(foundIndex + 1)
                ];
                setFormattedEventClasses(updatedArray);
            }
        }
    }

    const handlePointsTableChange = (formattedItem: FormattedEventClass, index: number, pointTable?: PointTable) => {
        if (filteredEventClasses) {
            let updated: FormattedEventClass = {
                ...formattedItem,
                pointTableName: pointTable?.name || "",
                pointTable: pointTable || undefined,
                isEdited: true
            }; 
            let updatedArray = [
                ...filteredEventClasses.slice(0, index),
                updated,
                ...filteredEventClasses.slice(index + 1)
            ];
            setFilteredEventClasses(updatedArray);
        }

        if (formattedEventClasses) {
            const foundIndex = formattedEventClasses.findIndex(fec => fec.id === formattedItem.id);
            if (foundIndex > -1) {
                const formattedEventClassObject = formattedEventClasses[foundIndex];
                const updatedObject: FormattedEventClass = {
                    ...formattedEventClassObject,
                    pointTableName: pointTable?.name || "",
                    pointTable: pointTable || undefined,
                    isEdited: true
                };
                let updatedArray = [
                    ...formattedEventClasses.slice(0, foundIndex),
                    updatedObject,
                    ...formattedEventClasses.slice(foundIndex + 1)
                ];
                setFormattedEventClasses(updatedArray);
            }
        }
    }

    const handleSelectAllPointsTables = (pointTable?: PointTable) => {
        setShowEditAllPointTablesModal(false);
        setIsLoading(true);
        let updatedFilteredEventClasses: FormattedEventClass[] = [];
        if (filteredEventClasses) {
            for (let i = 0; i < filteredEventClasses.length; i++) {
                const current: FormattedEventClass = filteredEventClasses[i];
                let updated: FormattedEventClass = {
                    ...current,
                    pointTableName: pointTable?.name || "",
                    pointTable: pointTable || undefined,
                    isEdited: true
                }; 
                updatedFilteredEventClasses.push(updated);
            }
            setFilteredEventClasses(updatedFilteredEventClasses);
        }

        let updatedFormattedEventClasses: FormattedEventClass[] = [];
        if (formattedEventClasses) {
            for (let i = 0; i < formattedEventClasses.length; i++) {
                const current: FormattedEventClass = formattedEventClasses[i];
                let updated: FormattedEventClass = {
                    ...current,
                    pointTableName: pointTable?.name || "",
                    pointTable: pointTable || undefined,
                    isEdited: true
                }; 
                updatedFormattedEventClasses.push(updated);
            }
            setFormattedEventClasses(updatedFormattedEventClasses);
        }
        setIsLoading(false);
    }

    const onSelectClass = (c: EventClass) => {
        onSelect(c);
    }

    const handleSaveChanges = async () => {
        setIsLoading(true);
        if (filteredEventClasses) {
            for (let i = 0; i < filteredEventClasses.length; i++) {
                const current: FormattedEventClass = filteredEventClasses[i];
                if (current.isEdited) {
                    let updateInput: UpdateEventClassInput = {
                        id: current.id,
                        name: current.name,
                        number: parseInt(current.number),
                        entryFee: current.classFee,
                        eventClassPointTableId: current.pointTable?.id || "",
                        eventDivisionId: current.divisionId || null,
                        organizationDivisionId: current.organizationDivisionId || null
                    };
                    await updateEventClass(updateInput);
                }
            }
        }
        setIsEditNumbers(false);
        setIsEditFee(false);
        setIsEditNames(false);
        setIsEditPoints(false);
        setIsLoading(false);
    }

    return (
        <>
            <IonCard mode="md" className="ion-padding bg-white">
                <IonCardTitle>
                    <IonRow>
                        <IonCol size="4">
                            Classes
                        </IonCol>
                        <IonCol size="12" className="ion-text-right">
                            <AddEventClassCard event={event} />
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {(isEditNumbers || isEditNames || isEditFee || isEditPoints || isEditEventDivision) && (
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonButton color="success" onClick={() => handleSaveChanges()}>Save Changes</IonButton>
                        </IonCol>
                    </IonRow>
                )}
                {isLoading ? 
                    <Spinner />
                    :
                    <>
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonSearchbar
                                    color="white"  
                                    placeholder="Search by #" 
                                    value={searchNumberText} 
                                    onIonChange={e => {
                                        setSearchNumberText(e.detail.value!)
                                        handleSearchInput(e.detail.value!, "number")
                                    }}
                                />
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonSearchbar
                                    color="white"  
                                    placeholder="Search by Name" 
                                    value={searchNameText} 
                                    onIonChange={e => {
                                        setSearchNameText(e.detail.value!)
                                        handleSearchInput(e.detail.value!, "name")
                                    }}
                                />
                            </IonCol>
                        </IonRow> 
                        <IonCardSubtitle>Click on a Class to edit it.</IonCardSubtitle>
                        {(isWindows() || (isPlatform("desktop"))) ?
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Number <IonIcon icon={createOutline} ariaLabel="Edit Numbers" onClick={() => setIsEditNumbers(!isEditNumbers)} /></th>
                                        <th>Class <IonIcon icon={createOutline} ariaLabel="Edit Names" onClick={() => setIsEditNames(!isEditNames)} /></th>
                                        <th>Type</th>
                                        <th>Class Fee <IonIcon icon={createOutline} ariaLabel="Edit Fee" onClick={() => setIsEditFee(!isEditFee)} /></th>
                                        <th>Division <IonIcon icon={createOutline} ariaLabel="Edit Division" onClick={() => setIsEditEventDivision(!isEditEventDivision)} /></th>
                                        <th>Division Fee <BasicTooltip label="" tip='Edit division fees by clicking the "Divisions" tab above.' /> </th>
                                        <th>
                                            Points Table 
                                            <IonIcon icon={createOutline} ariaLabel="Edit Points" onClick={() => setIsEditPoints(!isEditPoints)} />
                                            {isEditPoints && (
                                                <IonIcon icon={copyOutline} ariaLabel="Edit All" onClick={() => setShowEditAllPointTablesModal(true)} />
                                            )}
                                        </th>
                                        <th>Prize Money</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(filteredEventClasses && filteredEventClasses.length > 0) && (
                                        <>
                                            {filteredEventClasses.map((ec, index) => (
                                                <tr key={index}>
                                                    <td onClick={isEditNumbers ? () => console.log("Is editing numbers") : () => onSelectClass(ec.eventClass)}>
                                                        {isEditNumbers ? 
                                                            <>
                                                                <Input
                                                                    className="input-width"
                                                                    type="text"
                                                                    value={ec.number}
                                                                    onChange={e => {
                                                                        handleNumberChange(e.target.value, ec, index);
                                                                    }}
                                                                />
                                                            </>
                                                            :
                                                            <p className="ion-text-wrap description">{ec.number}</p>
                                                        }
                                                    </td>
                                                    <td onClick={isEditNames ? () => console.log("Is editing names") : () => onSelectClass(ec.eventClass)}>
                                                        {isEditNames ? 
                                                            <>
                                                                <Input
                                                                    className="input-width"
                                                                    type="text"
                                                                    value={ec.name}
                                                                    onChange={e => {
                                                                        handleNameChange(e.target.value, ec, index);
                                                                    }}
                                                                />
                                                            </>
                                                            :
                                                            <p className="ion-text-wrap description">{ec.name}</p>
                                                        }
                                                    </td>
                                                    <td onClick={() => onSelectClass(ec.eventClass)}>
                                                        <p className="ion-text-wrap description">{ec.type}</p>
                                                    </td>
                                                    <td onClick={isEditFee ? () => console.log("Is editing fees") : () => onSelectClass(ec.eventClass)}>
                                                        {isEditFee ? 
                                                            <InputGroup size="sm">
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>$</InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    className="input-width"
                                                                    type="text"
                                                                    value={ec.classFee || ""}
                                                                    onChange={e => {
                                                                        handleFeeChange(e.target.value, ec, index);
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                            :
                                                            <p className="ion-text-wrap description">{ec.classFee ? "$" + ec.classFee : ""}</p>
                                                        }
                                                    </td>
                                                    <td onClick={isEditEventDivision ? () => console.log("Is editing eventDivision") : () => onSelectClass(ec.eventClass)}>
                                                        {(isEditEventDivision && event.id) ?
                                                            <div className="extra-large-input-width">
                                                                <SelectEventDivision eventId={event.id} selectedValue={ec.divisionId} onSelect={(ed: EventDivision) => handleEventDivisionChange(ec, index, ed)} />
                                                            </div>
                                                            :
                                                            <p className="ion-text-wrap description">{ec.divisionName}</p>
                                                        }
                                                    </td>
                                                    <td onClick={() => onSelectClass(ec.eventClass)}>
                                                        <p className="ion-text-wrap description">{ec.divisionFee}</p>
                                                    </td>
                                                    <td onClick={isEditPoints ? () => console.log("Is editing points") : () => onSelectClass(ec.eventClass)}>
                                                        {(isEditPoints && event.organizationId) ?
                                                            <div className="extra-large-input-width">
                                                                <SelectCreatePointTable organizationId={event.organizationId} pointTable={ec.pointTable} onSelect={(pointTable: PointTable) => handlePointsTableChange(ec, index, pointTable)} />
                                                            </div>
                                                            :
                                                            <p className="ion-text-wrap description">{ec.pointTableName}</p>
                                                        }
                                                    </td>
                                                    <td onClick={() => onSelectClass(ec.eventClass)}>
                                                        <p className="ion-text-wrap description">{ec.prizeMoney}</p>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )}
                                </tbody>
                            </Table>
                            :
                            <IonList className="bg-white">
                                {(filteredEventClasses && filteredEventClasses.length > 0) && (
                                    <>
                                        {filteredEventClasses.map((ec, index) => (
                                            <IonItem key={index} onClick={() => onSelectClass(ec.eventClass)}>
                                                <IonLabel>
                                                    <p className="ion-text-wrap">{ec.number} - {ec.name} {ec.type}</p>
                                                </IonLabel>
                                                <IonRadio slot="start" color="tertiary" value={ec.id} />
                                                <IonReorder slot="start" />
                                            </IonItem>
                                        ))}
                                    </>
                                )}
                            </IonList>
                        }
                    </>
                }
            </IonCard>
            <IonModal backdropDismiss={false} isOpen={showEditAllPointTablesModal} id="editAllPointTablesModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Set All Points Tables
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowEditAllPointTablesModal(false)}
                        >
                            <p id="closeEditAllPointTablesModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow>
                        <IonCol>
                            <p>You can select a point table for all classes at once by selecting one of the organization's point tables below.</p>
                            <p>Or create a new point table by typing a new name and the selecting "Create".</p>
                            <hr/>
                            <IonLabel>Select the Point Table</IonLabel>
                            {event.organizationId ?
                                <SelectCreatePointTable organizationId={event.organizationId} onSelect={(pointTable: (PointTable | undefined)) => handleSelectAllPointsTables(pointTable)} />
                                :
                                <p>Sorry, no organization was found. An organization is needed to retrieve the point tables.</p>
                            }
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventClassEditTable;