import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { UpdateEventEntryInput, UpdateRiderInput, UpdateContactInput, CreateContactInput, CreateRiderInput } from "../../../../API";
import { EventEntry, Rider, Membership } from "../../../../models";
import { updateEventEntry } from "../../../../utilities/eventEntry/EventEntry";
import { createRider, updateRider } from "../../../../utilities/rider/Rider";
import { getMembershipsByRiderId } from "../../../../utilities/membership/Membership";
import SuccessBanner from "../../../Banners/SuccessBanner";
import ErrorAlert from "../../../Errors/ErrorAlert";
import Spinner from "../../../Spinners/Spinner";
import { formatTwilioNumber } from "../../../../utilities/contact/FormatPhoneNumber";
import { createContact, updateContact } from "../../../../utilities/contact/Contact";
import moment from "moment";
import RiderSelectStatus from "../../../Rider/RiderSelectStatus";
import { Table } from "reactstrap";

interface _Props {
    entry: EventEntry
}

const EntryInfoRider2: React.FC<_Props> = ({entry}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [riderName, setRiderName] = useState("");
    const [riderLocation, setRiderLocation] = useState("");
    const [riderAge, setRiderAge] = useState("");
    const [riderStatus, setRiderStatus] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [isProfessional, setIsProfessional] = useState(false);
    const [riderEmail, setRiderEmail] = useState("");
    const [riderPhone, setRiderPhone] = useState("");
    const [membershipData, setMembershipData] = useState<Membership[] | null | undefined>();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    async function setMemberships(rider: Rider) {
        let memberships: Membership[] = [];
        const queryResult = await getMembershipsByRiderId(rider.id);
        if (queryResult.isSuccess) {
            const membershipResults: Membership[] = queryResult.result;
            memberships = membershipResults.sort((a, b) => a.name.localeCompare(b.name));
        }
        setMembershipData(memberships);
    }

    const getEntryInformation = (entry: EventEntry) => {
        setCurrentEntry(entry);
        setRiderName(entry.secondRiderName || "");
        setRiderLocation(entry?.secondRider?.location || "");
        setRiderAge(entry?.secondRider?.age ? entry?.secondRider?.age?.toString() : "");
        setBirthDate(entry.secondRider?.birthdate ? moment(entry.secondRider?.birthdate).format("YYYY/MM/DD") : "");
        setRiderStatus(entry?.secondRider?.isProfessional ? "Professional" : "Amateur");
        setRiderEmail(entry.secondRider?.contact?.personalEmail || "");
        setRiderPhone(entry?.secondRider?.contact?.cell || "");
        if (entry.secondRider) setMemberships(entry.secondRider);
    }

    useEffect(() => {
        if (entry) {
            getEntryInformation(entry);
        }
    }, [entry]);

    const handleIsProfessionalInput = (isProfessional: boolean) => {
        setIsProfessional(isProfessional);
        setRiderStatus(isProfessional ? "Professional" : "Amateur");
    }

    const handleEdit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        let contactId = "";
        if (entry.secondRider?.contact?.id) {
            const updateContactInput: UpdateContactInput = {
                id: entry.secondRider.contact.id,
                personalEmail: riderEmail,
                cell: formatTwilioNumber(riderPhone) || ""
            };
            const updateContactResult = await updateContact(updateContactInput);
            if (updateContactResult) {
                contactId = updateContactResult.result.id;
            } else {
                setError("Could not update the contact info.");
                setIsLoading(false);
                return;
            }
        } else {
            const createContactInput: CreateContactInput = {
                personalEmail: riderEmail,
                cell: riderPhone ? formatTwilioNumber(riderPhone) : undefined
            };
            const createContactResult = await createContact(createContactInput);
            if (createContactResult) {
                contactId = createContactResult.result.id;
            } else {
                setError("Could not create the contact info.");
                setIsLoading(false);
                return;
            }
        }
        let riderId = "";
        if (entry.secondRiderId) {
            let input: UpdateRiderInput = {
                id: entry.secondRiderId,
                name: riderName,
                isProfessional: isProfessional
            };
            if (contactId) input["riderContactId"] = contactId || "";
            if (riderLocation) input["location"] = riderLocation || "";
            if (riderAge) input["age"] = riderAge ? parseInt(riderAge) : undefined;
            if (birthDate) input["birthdate"] = moment(birthDate).format("YYYY-MM-DD")
            const updateResult = await updateRider(input);
            if (updateResult.isSuccess) {
                riderId = updateResult.result.id;
            } else {
                setError("Could not update the rider");
                setIsLoading(false);
            }
        } else if (riderName) {
            const input: CreateRiderInput = {
                name: riderName,
                location: riderLocation,
                riderContactId: contactId,
                age: riderAge ? parseInt(riderAge) : undefined,
                isProfessional: isProfessional,
                createdOn: moment(new Date()).format(""),
                updatedOn: moment(new Date()).format("")
            };
            const createResult = await createRider(input);
            if (createResult.isSuccess) {
                riderId = createResult.result.id;
            } else {
                setError("Could not create the rider");
                setIsLoading(false);
            }
        } else {
            setError("No second rider.");
            setIsLoading(false);
            return;
        }
        const updateEntryInput: UpdateEventEntryInput = {
            id: entry.id,
            secondRiderName: riderName,
            secondRiderId: riderId
        };
        const updateEntryResult = await updateEventEntry(updateEntryInput);
        if (updateEntryResult.isSuccess) {
            const updatedEntry = updateEntryResult.result;
            setCurrentEntry(updatedEntry);
            getEntryInformation(updatedEntry);
            setMemberships(updatedEntry.secondRider);
            setSuccess("Updated the rider info.");
            setIsEdit(false);
            setIsLoading(false);
        } else {
            setError("Could not update the entry.");
            setIsLoading(false);
            return;
        }
    }

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h3>Rider #2</h3>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {success && <SuccessBanner width="12" success={success} />}
                        {isEdit ?
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Name</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={riderName}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setRiderName(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Location</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={riderLocation}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setRiderLocation(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-padding-top">
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Birth Date</IonLabel>
                                            <IonInput 
                                                type="date"
                                                value={birthDate}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setBirthDate(e.detail.value!);
                                                    if (e.detail.value!) setRiderAge(moment().diff(e.detail.value!, 'years').toString() || "")
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <RiderSelectStatus rider={currentEntry?.rider} onChange={handleIsProfessionalInput} />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Email</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={riderEmail}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setRiderEmail(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Cell Phone</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={riderPhone}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setRiderPhone(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Name</IonLabel>
                                            <IonText><p>{riderName}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Location</IonLabel>
                                            <IonText><p>{riderLocation}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Age</IonLabel>
                                            <IonText><p>{riderAge}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Status</IonLabel>
                                            <IonText><p>{riderStatus}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Email</IonLabel>
                                            <IonText><p>{riderEmail}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Cell Phone</IonLabel>
                                            <IonText><p>{riderPhone}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="11">
                                {(membershipData && membershipData.length > 0) ?
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Membership</th>
                                                <th>ID</th>
                                                <th>Type</th>
                                                <th>Date Expires</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {membershipData.map((membership, index) => (
                                                <tr key={index}>
                                                    <td>{membership.name}</td>
                                                    <td>{membership.membershipId}</td>
                                                    <td>{membership.type}</td>
                                                    <td>{membership.dateMembershipEnds ? moment(membership.dateMembershipEnds).format("MMM DD, YYYY") : ""}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    :
                                    <p>No rider memberships were found.</p>
                                }
                            </IonCol>
                        </IonRow>
                        {isLoading ?
                            <Spinner />
                            :
                            <IonRow className="ion-justify-content-center">
                                {isEdit && (
                                    <IonCol sizeMd="4" className="ion-text-center">
                                        <IonButton
                                            className="ion-margin-top"
                                            color="primary"
                                            expand="block"
                                            onClick={() => setIsEdit(false)}
                                        >
                                            Cancel
                                        </IonButton>
                                    </IonCol>
                                )}
                                <IonCol sizeMd="4" className="ion-text-center">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="tertiary"
                                        expand="block"
                                        onClick={isEdit ? handleEdit : (() => setIsEdit(true))}
                                    >
                                        {!isEdit && "Allow "}Edit
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        }
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default EntryInfoRider2;