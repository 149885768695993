import algoliasearch from "algoliasearch";
import { Person } from "../../models";

const appId: string = process.env.ALGOLIA_APP_ID || "JWZGHNODNQ";
const apiKey: string = process.env.ALGOLIA_SEARCH_API_KEY || "65e1b535fea4b59b81c60dbb1d758b2a";
const searchClient = algoliasearch(appId, apiKey);

// const env = process.env.AWS_BRANCH;
const indexName = "prod_people";
const index = searchClient.initIndex(indexName);

export async function addPersonToSearch(person: Person) {
    const name = person.firstName + " " + person.lastName;
    index.saveObject({
        objectID: person.id,
        name: name,
        email: person.email,
        roles: person.roles,
    }).then(({ objectID }) => {
        console.log(objectID);
    });
}

export async function updatePersonInSearch(person: Person) {
    const name = person.firstName + " " + person.lastName;
    index.saveObject({
        objectID: person.id,
        name: name,
        email: person.email,
        roles: person.roles,
    }).then(({ objectID }) => {
        console.log(objectID);
    });
}

export async function removePersonFromSearch(person: Person) {
    index.deleteObject(person.id).then(() => {
        console.log("removed person from search.");
    });
}