
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { Event, EventEntry, EventResult, Horse } from "../../models";
import { getEventEntriesByHorseId } from "../../utilities/eventEntry/EventEntry";
import { getEventResultsByEventIdEntryId } from "../../utilities/eventResult/EventResult";
import { getEventById } from "../../utilities/events/Event";
import Spinner from "../Spinners/Spinner";

interface _Props {
    horse: Horse
}

interface FormattedResult {
    id: string
    eventName: string
    className: string
    divisionName: string
    place: string
    date: string
}

const HorseResultsTable: React.FC<_Props> = ({horse}) => {
    const [results, setResults] = useState<FormattedResult[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const getResults = async (horseId: string) => {
        setIsLoading(true);
        let formattedArray: FormattedResult[] = [];
        const queryResult = await getEventEntriesByHorseId(horseId);
        if (queryResult.isSuccess) {
            const eventEntries: EventEntry[] = queryResult.result;
            if (eventEntries && eventEntries.length > 0) {
                for (let i = 0; i < eventEntries.length; i++) {
                    const currentEventEntry: EventEntry = eventEntries[i];
                    let eventName = "";
                    const eventQuery = await getEventById(currentEventEntry.eventId);
                    if (eventQuery.isSuccess) {
                        const event: Event = eventQuery.result;
                        if (event && event.name) eventName = event.name;
                    }
                    const eventResultsQuery = await getEventResultsByEventIdEntryId(currentEventEntry.eventId, currentEventEntry.id);
                    if (eventResultsQuery.isSuccess) {
                        const eventResults: EventResult[] = eventResultsQuery.result;
                        if (eventResults && eventResults.length > 0) {
                            for (let j = 0; j < eventResults.length; j++) {
                                const currentResult: EventResult = eventResults[j];
                                
                                const formattedItem: FormattedResult = {
                                    id: currentResult.id,
                                    eventName: eventName,
                                    className: currentResult.eventClassName || currentResult.eventClass?.name || "",
                                    divisionName: currentResult.eventDivisionName || "",
                                    place: currentResult.place ? currentResult.place.toString() : "",
                                    date: currentResult.createdOn ? moment(currentResult.createdOn).format("MM-DD-YYYY") : ""
                                };
                                formattedArray.push(formattedItem);
                            }
                        }
                    }
                }
            }
        }
        setResults(formattedArray);
        setIsLoading(false);
    }

    useEffect(() => {
        if (horse) getResults(horse.id);
    }, [horse]);

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>Event Name</th>
                            <th>Event Date</th>
                            <th>Division</th>
                            <th>Class</th>
                            <th>Place</th>
                        </tr>
                    </thead>
                    {(results && results.length > 0) && (
                        <tbody>
                            {results.map((formattedResult, index) => (
                                <tr key={index}>
                                    <td>
                                        <p className="ion-text-wrap description">{formattedResult.eventName}</p>
                                    </td>
                                    <td>
                                        <p className="ion-text-wrap description">{formattedResult.date}</p>
                                    </td>
                                    <td>
                                        <p className="ion-text-wrap description">{formattedResult.divisionName}</p>
                                    </td>
                                    <td>
                                        <p className="ion-text-wrap description">{formattedResult.className}</p>
                                    </td>
                                    <td>
                                        <p className="ion-text-wrap description">{formattedResult.place}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                </Table>
            }
        </>
    );
};

export default HorseResultsTable;