import {Role, Roles} from "./Roles";

/**
 * Takes in the roles string as it is formatted in Cognito User attributes
 * Returns a Role Array with each role object
 * 
 * @param roles the string as it is formatted in Cognito User attributes (role;role;)
 */
export function formatRolesForDisplay(roles: string): Role[] | null | undefined {
    try {
        if (!roles || roles.length === 0) return;
        let displayArray: Role[] = [];
        let roleArray = roles.split(";");
        roleArray.pop();
        roleArray.forEach(roleValue => {
            let roleObject = Roles.filter(roleObj => {
                return roleObj.value === roleValue;
            });
            displayArray.push(roleObject[0]);
        });  
        return displayArray;
    } catch (error: any) {
        console.error(error);
        throw new Error("Could not format the roles array for display.");
    }
}

/**
 * Takes in the roles as a string array 
 * Returns a string which is formatted to be the Cognito User custom roles attribute
 * 
 * @param roles an array of strings (the value attribute on the Role interface)
 */
export function formatRolesStringForUserAttribute(roleNames: (string[])): string | null | undefined {
    try {
        if (!roleNames || roleNames.length === 0) return;
        let displayString = "";
        roleNames.forEach(roleName => {
            let roleObject = Roles.filter(roleObj => {
                return roleObj.name === roleName;
            });
            displayString = displayString + roleObject[0].value + ";";
        });
        return displayString;
    } catch (error: any) {
        console.error(error);
        throw new Error("Could not format the roles string for the user attribute.");
    }
}

/**
 * Takes in the roles string as it is formatted in Cognito User attributes
 * Returns a string Array with each role name in the string
 * 
 * @param roles a string of roles separated by ;
 */
export function formatRolesStringToStringArray(roles: string): string[] {
    try {
        if (!roles || roles.length === 0) return [];
        let displayArray: string[] = [];
        let roleArray = roles.split(";");
        roleArray.pop();
        roleArray.forEach(roleValue => {
            let roleObject = Roles.filter(roleObj => {
                return roleObj.value === roleValue;
            });
            displayArray.push(roleObject[0].name);
        });  
        return displayArray;
    } catch (error: any) {
        console.error(error);
        throw new Error("Could not format the roles string for the user attribute.");
    }
}

/**
 * Uses the roles defined in the Roles file
 * Returns a string array of the diplay names of each role
 */
export function formatRolesToStringArray(): string[] {
    try {
        if (!Roles || Roles.length === 0) return [];
        let displayArray: string[] = [];
        Roles.forEach(role => {
            if (role.displayInWelcomeModal) displayArray.push(role.name);
        });
        return displayArray;
    } catch (error: any) {
        console.error(error);
        throw new Error("Could not format the roles string for the user attribute.");
    }
}