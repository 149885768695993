import Papa from "papaparse";
import { downloadCSVFile } from "./ReportDownloadCSV";

interface InputRow {
    Project: string;
    'Company Name': string;
    'Item Category': string;
    'Item Group': string;
    'Item Country': string;
    Item: string;
    Quantity: number | string;
    'Amount (USD)': string;
    'Account SID': string;
    'Account Type': string;
    'Parent SID': string;
    Month: number;
    Year: number;
    'Invoice Number': string;
}

interface OutputRow {
    Year: number;
    Month: number;
    'Item Country': string;
    'Item Category': string;
    'Item Group': string;
    'Inbound / Outbound': string;
    'Item Type': string;
    'Carrier Name': string;
    Item: string;
    Quantity: number | string;
    'Amount (USD)': string;
    'Cost Per Message': string;
}

export async function generateTwilioCostReport(file: File) {
    try {
        const inputRows: InputRow[] = await handleReadingFile(file) as InputRow[];

        // Sort the items
        const sortedInputRows = inputRows.sort(function(a: InputRow, b: InputRow): number {

            if (a && a.Item && b && b.Item) {
                // First, Sort by Item Category (Example: Taxes, Services)
                const byCategory = a["Item Category"].localeCompare(b["Item Category"]);

                // Next, Sort by Item Country (Example: Canada, United States)
                const byCountry = a["Item Country"].localeCompare(b["Item Country"]);
                
                // Next, Sort by Item Group (Example: Programmable Messaging, Lookups)
                const byGroup = a["Item Group"].localeCompare(b["Item Group"]);

                // Next, Sort by Item (Example: SMS, MMS, Carrier Fees)
                const byItem = a["Item"].localeCompare(b["Item"]);
                
                return byCategory || byCountry || byGroup || byItem;
            } else {
                return 0;
            }
        });

        const formattedData: OutputRow[] = sortedInputRows.map((row: InputRow) => ({
            Year: row.Year,
            Month: row.Month,
            'Item Country': row['Item Country'],
            'Item Category': row['Item Category'],
            'Item Group': row['Item Group'],
            'Inbound / Outbound': row.Item ? (row.Item.includes("Inbound") ? "Inbound" : (row.Item.includes("Outbound") ? "Outbound" : "n/a")) : "",
            'Item Type': row.Item ? (row.Item.includes("Carrier Fee") ? "Carrier Fee" : (row.Item.includes("SMS") ? "SMS" : (row.Item.includes("MMS") ? "MMS" : row["Item Group"]))) : "",
            'Carrier Name': row.Item ? ((row.Item.includes("SMS") || row.Item.includes("MMS")) ? (row.Item.split(" - ").pop()?.trim() || "") : "n/a") : "",
            Item: row.Item,
            Quantity: row.Quantity,
            'Amount (USD)': row['Amount (USD)'],
            'Cost Per Message': typeof(row.Quantity) === "number" ? "$" + ((Number((row['Amount (USD)'].replace(/[^0-9.-]+/g,"")))) / row.Quantity).toFixed(5) : "",
        }));

        let totalAmountSpent = 0;
        sortedInputRows.forEach((row) => {
            const amountAsString = row["Amount (USD)"];
            if (amountAsString && amountAsString.includes("$")) {
                const amountAsStringWithoutDollarSign =  amountAsString.split("$")[1];
                const amountAsFloat = parseFloat(amountAsStringWithoutDollarSign) || 0;
                totalAmountSpent = totalAmountSpent + amountAsFloat;
            }
        });

        downloadCSVFile("TwilioData.csv", formattedData);

    } catch (error) {
        console.error("Error: ", error);
    }
}

async function handleReadingFile(file: File) {
    try {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: true,
                dynamicTyping: true,
                complete (results, file) {
                    let inputRows: InputRow[] = results.data as InputRow[];
                    resolve(inputRows);
                },
                error (err, file) {
                    reject(err)
                }
            })
        });
    } catch (error: any) {
        console.error("Error while handling selected file: ", error);
        return [];
    }
}