import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, EventEntry } from "../../../models";
import { formattedOptionGroup, getFormattedEntryClassList } from "../../../utilities/eventClassEntry/FormattedEventEntryClasses";
import Spinner from "../../Spinners/Spinner";

interface _Props {
    entry: EventEntry
    event: Event
    onCalculate?: Function
}

const DisplayEntryClasses: React.FC<_Props> = ({entry, event, onCalculate}) => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [totalEntryFees, setTotalEntryFees] = useState<number | null | undefined>();
    const [taxEntryFees, setTaxEntryFees] = useState<number | null | undefined>();
    const [taxTotalEntryFees, setTaxTotalEntryFees] = useState<number | null | undefined>();
    const [formattedClassData, setFormattedClassData] = useState<formattedOptionGroup[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const getFinalFeeCost = (total?: (number | null), taxPercent?: (number | null)) => {
        if (!total) total = totalEntryFees;
        if (!taxPercent) taxPercent = taxEntryFees;
        if (total && taxPercent) {
            const finalCost = total + total * (taxPercent || 0)/100;
            setTaxTotalEntryFees(finalCost);
        }
    }

    const getFormattedClassData = async (entry: EventEntry) => {
        setIsLoading(true);
        // Get the EventClassEntry data, including statuses not accepted
        const dataResult = await getFormattedEntryClassList(entry, false);
        if (dataResult.isSuccess) {
            setTotalEntryFees(dataResult.result.totalEntryFees);
            setFormattedClassData(dataResult.result.classList);
            if (taxEntryFees) getFinalFeeCost(dataResult.result.totalEntryFees, taxEntryFees);
            if (onCalculate) onCalculate(dataResult.result.totalEntryFees);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (event && event.invoiceOptions && event.invoiceOptions.classFeeTax) {
            setTaxEntryFees(event.invoiceOptions.classFeeTax);
            if (totalEntryFees) getFinalFeeCost(totalEntryFees, event.invoiceOptions.classFeeTax);
        }
    }, [event]);

    useEffect(() => {
        if (entry) {
            setIsCollapsed(true);
            getFormattedClassData(entry);
        }
    }, [entry]);

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {entry ? <IonRow><h2>{event.type ? "Section" : "Entry"} Fees: {taxTotalEntryFees ? "$" + taxTotalEntryFees?.toFixed(2) : (totalEntryFees ? "$" + totalEntryFees.toFixed(2) : "")}</h2></IonRow> : <h2>Loading entry fees...</h2> }
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <>
                        {isLoading ?
                            <Spinner />
                            :
                            <IonCardContent>
                                {formattedClassData && (
                                    <IonList className="bg-white">
                                        {formattedClassData.map((formattedOption, index) => (
                                            <IonItem key={index}>
                                                <IonLabel>
                                                    <p>
                                                        {formattedOption.division ? 
                                                            "Division: " + formattedOption.division.label + " - $" + formattedOption.division.entryFee
                                                            : 
                                                            "Class: " + formattedOption.class?.label + " - $" + formattedOption.class?.entryFee
                                                        }
                                                    </p>
                                                </IonLabel>
                                            </IonItem>
                                        ))}
                                        {taxEntryFees && (
                                            <IonItem>
                                                <IonLabel>
                                                    <h3>
                                                        Tax: {taxEntryFees}%
                                                    </h3>
                                                </IonLabel>
                                            </IonItem>
                                        )}
                                    </IonList>
                                )}
                            </IonCardContent>
                        }
                    </>
                )}
            </IonCard>
        </>
    );
};

export default DisplayEntryClasses;