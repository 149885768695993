import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { chevronDown, chevronUp, close } from "ionicons/icons";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { CreateEventResultInput } from "../../../API";
import { PersonContext } from "../../../context/PersonContext";
import { Event, EventClass, EventClassEntry, EventDivision, EventEntry, EventResult } from "../../../models";
import { getEventClassTypeAbbreviation } from "../../../utilities/eventClass/EventClassTypes";
import { createEventResult, deleteEventResult, getEventResultsByEntryId } from "../../../utilities/eventResult/EventResult";
import ErrorAlert from "../../Errors/ErrorAlert";
import SelectEventClassEntryClasses from "../../EventClassEntry/SelectEventClassEntryClasses";
import SelectEventDivision from "../../EventDivision/SelectEventDivision";
import PriceField from "../../Forms/PriceField";

interface _Props {
    event: Event
    entry: EventEntry
}

const EditEntryResults: React.FC<_Props> = ({entry, event}) => {
    const user = useContext(PersonContext);

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");

    const [totalPrizeMoney, setTotalPrizeMoney] = useState<number | null | undefined>();
    const [eventResults, setEventResults] = useState<EventResult[] | null | undefined>();

    const [showModal, setShowModal] = useState(false);
    const [selectedEventClassEntry, setSelectedEventClassEntry] = useState<EventClassEntry | null | undefined>();
    const [selectedClass, setSelectedClass] = useState<EventClass | null | undefined>();
    const [selectedDivision, setSelectedDivision] = useState<EventDivision | null | undefined>();
    const [selectedPlace, setSelectedPlace] = useState(0);
    const [selectedPrizeMoney, setSelectedPrizeMoney] = useState(0);

    const calculateTotalPrizeMoney = (results: EventResult[]) => {
        let total = 0;
        results.forEach(result => {
            total = total + (result.prizeMoney || 0);
        });
        setTotalPrizeMoney(total);
    }

    const getEventResultsByEventByEntry = async (entry: EventEntry) => {
        setIsLoading(true);
        const queryResult = await getEventResultsByEntryId(entry.id);
        if (queryResult.isSuccess) {
            setEventResults(queryResult.result);
            calculateTotalPrizeMoney(queryResult.result);
        }
        
        setIsLoading(false);
    }

    useEffect(() => {
        if (entry) {
            getEventResultsByEventByEntry(entry);
        }
    }, [entry]);

    const handleSelectedEventDivision = (eventDivision: EventDivision) => {
        setSelectedClass(undefined);
        setSelectedDivision(eventDivision);
    }

    const handleSelectedEventClass = (eventClassEntry: EventClassEntry) => {
        setSelectedEventClassEntry(eventClassEntry);
        setSelectedClass(eventClassEntry.eventClass);  
        setSelectedDivision(undefined);
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");

        const input: CreateEventResultInput = {
            eventId: event.id,
            entryId: entry.id,
            eventResultEntryId: entry.id,
            eventClassEntryId: selectedEventClassEntry?.id || null, 
            eventResultEventClassEntryId: selectedEventClassEntry?.id || null,
            eventDivisionId: selectedDivision?.id || null,
            eventDivisionName: selectedDivision?.name || null,
            eventClassId: selectedClass?.id || null,
            eventClassName: selectedClass?.name || null,
            place: selectedPlace,
            prizeMoney: selectedPrizeMoney,
            createdBy: user.id,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
        const createResult = await createEventResult(input);
        if (createResult.isSuccess) {
            const updatedEventResults = [createResult.result].concat(eventResults);
            setEventResults(updatedEventResults);
            calculateTotalPrizeMoney(updatedEventResults);
        } else {
            const message = "Could not create the event result: " + createResult.message; 
            setError(message);
        }
        setShowModal(false);
        setIsLoading(false);
    }

    const handleDelete = async (eventResult: EventResult) => {
        const deleteResult = await deleteEventResult({id: eventResult.id});
        if (deleteResult) {
            const resultArray: EventResult[] = [];
            if (eventResults) {
                eventResults.forEach(result => {
                    if (result.id !== eventResult.id) resultArray.push(result);
                });
            }
            setEventResults(resultArray);
            calculateTotalPrizeMoney(resultArray);
        } else {    
            setError("Could not delete this result.");
        }
    }

    return (
        <>
            <IonRow className="ion-justify-content-right">
                <IonCol className="text-right">
                    <IonButton color="primary" onClick={() => setShowModal(true)}>
                        Add New
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonCard className="ion-padding" color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {eventResults ? <IonRow><h3>Results:</h3></IonRow> : <h2>Loading results...</h2> }
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? <IonIcon icon={chevronDown} />  : <IonIcon icon={chevronUp} />}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {eventResults && (
                            <IonList className="bg-white">
                                {eventResults.map((result, index) => (
                                    <IonItem key={index}>
                                        <IonLabel>
                                            <IonRow>
                                                {result.eventDivision?.id && (
                                                    <IonCol sizeXs="12" sizeMd="6" sizeLg="3">
                                                        <h3 className="ion-text-wrap">Division: {result.eventDivisionName}</h3>
                                                    </IonCol>
                                                )}
                                                {result.eventClass?.id && (
                                                    <IonCol sizeXs="12" sizeMd="6" sizeLg="3">
                                                        <h3 className="ion-text-wrap">Class: {result.eventClass?.number} - {result.eventClass?.name}</h3>
                                                    </IonCol>
                                                )}
                                                <IonCol sizeXs="12" sizeMd="6" sizeLg="3">
                                                    <h3 className="ion-text-wrap">Place: {result.place}</h3>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="6" sizeLg="3">
                                                    <h3 className="ion-text-wrap">Prize Money: {result.prizeMoney ? "$" + result.prizeMoney.toFixed(2) : ""}</h3>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="6" sizeLg="3">
                                                    <IonButton color="danger" onClick={() => handleDelete(result)}><IonIcon icon={close}/></IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                    </IonItem>
                                ))}
                                <IonItem>
                                        <IonLabel>
                                            <p className="text-primary font-weight-bold">
                                                Total Prize Money: ${totalPrizeMoney? totalPrizeMoney?.toFixed(2) : "0.00"}
                                            </p>
                                        </IonLabel>
                                </IonItem>
                            </IonList>
                        )}
                    </IonCardContent>
                )}
            </IonCard>
            <IonModal backdropDismiss={false} isOpen={showModal} id="termsAndConditionsModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Results
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {event && (
                        <>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="10">
                                    <IonLabel>Select Division</IonLabel>
                                    <SelectEventDivision eventId={event.id} onSelect={handleSelectedEventDivision} />
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="10" className="ion-text-center">
                                    <IonLabel>OR</IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="10">
                                    <IonLabel>Select Class</IonLabel>
                                    <SelectEventClassEntryClasses eventEntry={entry} onSelect={handleSelectedEventClass} />
                                </IonCol>
                            </IonRow>
                            <hr />
                            <IonRow className="ion-justify-content-center ion-padding-top">
                                <IonCol size="10" className="ion-text-center">
                                    <p>{selectedClass ? "Class: " + selectedClass.number + " - " + selectedClass.name + " " + getEventClassTypeAbbreviation(selectedClass.type || "") : ""}</p>
                                    <p>{selectedDivision ? "Division: " + selectedDivision.name : ""}</p>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center ion-padding-top">
                                <IonCol size="10">
                                    <IonItem>
                                        <IonLabel position="stacked">Place</IonLabel>
                                        <IonInput
                                            type="number"
                                            value={selectedPlace}
                                            aria-required={true}
                                            min={"0"}
                                            max={"50"}
                                            onIonChange={e => {
                                                setSelectedPlace(parseInt(e.detail.value!));
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center ion-padding-top">
                                <IonCol size="11">
                                    <IonItem lines="none">
                                        <IonLabel position="stacked">Prize Money</IonLabel>
                                        <PriceField value={selectedPrizeMoney} className="ion-margin-top" onChange={(value: any) => setSelectedPrizeMoney(value)} />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center ion-padding-top">
                                <IonCol size="10" className="ion-text-center">
                                    <IonButton expand="block" onClick={handleSubmit}>
                                        Save
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                </IonContent>
            </IonModal>
        </>
    );
};

export default EditEntryResults;