import {
    IonCol,
    IonDatetime,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    isPlatform,
} from "@ionic/react";
import moment from "moment";
import React, { useState } from "react";
import { isWindows } from "../../utilities/platform/Platform";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    onChange: Function
    label?: string
    isRequired?: boolean
    value?: string
}

const BirthdateInput: React.FC<_Props> = ({label, onChange, isRequired, value}) => {

    // Track date via the format YYYY-MM-DD
    const [date, setDate] = useState<string | null | undefined>(value ? moment(value).format("YYYY-MM-DD") : "");

    const handleDateChange = (value: string) => {
        setDate(value);
        onChange(value);
    }

    return (
        <>
            {/* If is a desktop device, allow the user to type in the form or use a calender widget */}
            {/* If is a mobile device, allow the user to use the scrolling pop-up native to ios */}
            {(isWindows() || (isPlatform("desktop"))) ?
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">{label ? label : "Birthday"} {isRequired && <RequiredInputIndicator />}</IonLabel>
                            <IonInput
                                type="date"
                                value={date}
                                onIonChange={e => {
                                    handleDateChange(e.detail.value!);
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                :
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">{label ? label : "Birthday"} {isRequired && <RequiredInputIndicator />}</IonLabel>
                            <IonDatetime
                                mode="ios"
                                displayFormat="MM/DD/YYYY"
                                value={date}
                                onIonChange={e => {
                                    handleDateChange(e.detail.value!);
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
            }
        </>
    );
};

export default BirthdateInput;
