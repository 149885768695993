import {
    IonButton,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Horse } from "../../../models";
import { updateHorse } from "../../../utilities/horse/Horse";
import ErrorAlert from "../../Errors/ErrorAlert";
import { UpdateHorseInput } from "../../../API";
import SuccessBanner from "../../Banners/SuccessBanner";
import { Input, Label } from "reactstrap";
import BasicTooltip from "../../Tooltip/BasicTooltip";
import { levels } from "../../../utilities/horse/HighestLevel";

interface _Props {
    horse: Horse
}

const EditHorseExperienceForm: React.FC<_Props> = ({horse}) => {    
    const [highestLevelCompeted, setHighestLevelCompeted] = useState("-");
    const [greenEligibility, setGreenEligibility] = useState("");
    
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const clearForm = () => {
        setHighestLevelCompeted("-");
        setGreenEligibility("");
    }

    useEffect(() => {
        if (horse) {
            setHighestLevelCompeted(horse.highestLevelCompeted || "-");
            setGreenEligibility(horse.greenEligibility || "");
        } else {
            clearForm();
        }
    }, [horse]);

    const handleEditHorse = async () => {
        const input: UpdateHorseInput = {
            id: horse.id,
            highestLevelCompeted,
            greenEligibility
        };
        const createResult = await updateHorse(input);
        if (createResult.isSuccess) {
            setError("");
            setSuccess("Successfully saved the updates.");
        } else {
            setSuccess("");
            setError(createResult.message);
        }
    }

    return (
        <>
            {success && <SuccessBanner success={success} />}
            {error && <ErrorAlert error={error} />}
            <IonRow>
                <IonCol size="12">
                    <Label for="colorSelect">Highest Level Competed</Label>
                    <Input 
                        type="select" 
                        name="select"
                        value={highestLevelCompeted}
                        id="colorSelect"
                        onChange={e => setHighestLevelCompeted(e.target.value)}
                    >
                        <option>-</option>
                        {levels.map((level, index) => (
                            <option key={index}>{level}</option>
                        ))}
                    </Input>
                </IonCol>
                <IonCol size="12">
                    <Label for="greenEligibility"><BasicTooltip label="Green Eligibility" tip="Used for hunter horses to track green eligibility." /> </Label>
                    <Input 
                        type="select" 
                        name="select"
                        value={greenEligibility}
                        id="colorSelect"
                        onChange={e => setGreenEligibility(e.target.value)}
                    >
                        <option>-</option>
                        <option>Never shown before</option>
                        <option>Eligible for Green 2'0"</option>
                        <option>Eligible for Green 2'6"</option>
                        <option>Eligible for Green 3'0"</option>
                        <option>Eligible for Green 3'3"</option>
                        <option>Eligible for Green 3'6"</option>
                        <option>Eligible for Green 3'9"</option>
                        <option>Not Eligible for Green Hunters</option>
                    </Input>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton
                        className="ion-margin-top"
                        color="tertiary"
                        expand="block"
                        onClick={handleEditHorse}
                    >
                        Save
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default EditHorseExperienceForm;