import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonRow
} from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { FormGroup, Input } from "reactstrap";
import moment from "moment";
import {
    CategoryTypeOption,
    formatMembershipTypeCategory
} from "../../utilities/organizationMembershipType/MembershipTypeCategory";
import CONSTANT from "../../constant/constant";
import ErrorAlert from "../../components/Errors/ErrorAlert";
import { OrganizationMembershipType, Organization, OrganizationRule, OrganizationDocument, Season } from "../../models";
import Spinner from "../../components/Spinners/Spinner";
import {
    createOrganizationMembershipType,
    createOrganizationMembershipTypeSeason,
    deleteOrganizationMembershipTypeSeason,
    updateOrganizationMembershipType
} from "../../utilities/organizationMembershipType/OrganizationMembershipType";
import {
    isMembershipIdValueValid,
    getNextAvailableMembershipId
} from "../../utilities/organizationMembershipType/MembershipIdValues";
import {
    CreateOrganizationMembershipTypeInput,
    CreateOrganizationMembershipTypeSeasonsInput,
    MembershipTypeCategory,
    MembershipTypeSchedule,
    UpdateOrganizationMembershipTypeInput
} from "../../API";
import PriceField from "../../components/Forms/PriceField";
import BasicTooltip from "../../components/Tooltip/BasicTooltip";
import MembershipTypeWaiversList from "./MembershipTypeWaiversList";
import MembershipDocumentUploadList from "./MembershipDocumentUploadList";
import MembershipTypeFormScheduleCard from "./MembershipTypeFormComponents/MembershipTypeFormScheduleCard";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    organization: Organization;
    membershipType?: OrganizationMembershipType;
}

const MembershipTypeForm: React.FC<_Props> = ({ organization, membershipType }) => {
    const categoryOptions = formatMembershipTypeCategory();
    const history = useHistory();
    const [isExpandedBasicDetails, setIsExpandedBasicDetails] = useState(true);
    const [isExpandedMembershipPrice, setIsExpandedMembershipPrice] = useState(true);
    const [isExpandedOnlineApplication, setIsExpandedOnlineApplication] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    // Online application checkbox state variables
    const gradeLevelEdit = membershipType?.applicationFields?.gradeLevel ? true : false;
    const barnNameEdit = membershipType?.applicationFields?.barnName ? true : false;
    const teamNameEdit = membershipType?.applicationFields?.teamName ? true : false;
    const backNumberEdit = membershipType?.applicationFields?.backNumber ? true : false;
    const dateOfBirthHorseEdit = membershipType?.applicationFields?.dateOfBirth ? true : false;
    const cogginsInfoEdit = membershipType?.applicationFields?.cogginsInfo ? true : false;
    const cogginsUploadEdit = membershipType?.applicationFields?.cogginsUpload ? true : false;
    const collectDetailTogetherEdit = membershipType?.applicationFields?.collectMemberDetailsTogether ? true : false;
    const groupNameEdit = membershipType?.applicationFields?.groupName ? true : false;
    const groupLeaderEdit = membershipType?.applicationFields?.groupLeader ? true : false;
    const groupLeaderContactInfoEdit = membershipType?.applicationFields?.groupLeaderContactInfo ? true : false;
    const isTeamEdit = membershipType?.applicationFields?.isTeam ? true : false;
    const groupContactEdit = membershipType?.applicationFields?.contact ? true : false;
    
    const [firstNameAndLastName, setFirstNameAndLastName] = useState<boolean>(true);  //required field for individual types
    const [dateOfBirth, setDateOfBirth] = useState<boolean>(true);  //required field for individual types
    const [contact, setContact] = useState<boolean>(true);  //required field for individual and horse types
    const [gradeLevel, setGradeLevel] = useState<boolean>(membershipType ? gradeLevelEdit : false);  //optional field for individual types
    const [barnName, setBarnName] = useState<boolean>(membershipType ? barnNameEdit : false);  //optional field for individual, group and horse types
    const [teamName, setTeamName] = useState<boolean>(membershipType ? teamNameEdit : false);  //optional field for individual types
    const [backNumber, setBackNumber] = useState<boolean>(membershipType ? backNumberEdit : false);  //optional field for individual types
    const [groupName, setGroupName] = useState<boolean>(membershipType ? groupNameEdit : false);
    const [groupLeader, setGroupLeader] = useState<boolean>(membershipType ? groupLeaderEdit : false);
    const [collectMemberDetailsTogether, setCollectMemberDetailsTogether] = useState<boolean>(membershipType ? collectDetailTogetherEdit : false);
    const [maxNumberOfGroupMembers, setMaxNumberOfGroupMembers] = useState<number | undefined>(
        (membershipType?.applicationFields?.maxNumberOfGroupMembers) ? membershipType?.applicationFields?.maxNumberOfGroupMembers : undefined
    );
    const [groupContact, setGroupContact] = useState<boolean>(membershipType?.category === "group" ? groupContactEdit : false);
    const [groupLeaderContact, setGroupLeaderContact] = useState<boolean>(membershipType ? groupLeaderContactInfoEdit : false);
    const [isTeam, setIsTeam] = useState<boolean>(membershipType ? isTeamEdit : false);
    const [horseName, setHorseName] = useState<boolean>(true);  //required field for horse types
    const [horseHeight, setHorseHeight] = useState<boolean>(true);  //required field for horse types
    const [horseOwnerName, setHorseOwnerName] = useState<boolean>(true);  //required field for horse types
    const [cogginsInfo, setCogginsInfo] = useState<boolean>(membershipType ? cogginsInfoEdit : false);  //optional field for horse types
    const [cogginsUpload, setCogginsUpload] = useState<boolean>(membershipType ? cogginsUploadEdit : false);  //optional field for horse types
    const [horseDOB, setHorseDOB] = useState<boolean>(membershipType?.category === "horse" ? dateOfBirthHorseEdit : false);  //optional field for horse types

    const allowOnlineEdit = membershipType?.acceptOnlineApplications === CONSTANT.MEMBERSHIP.ONLINE_APPLICATION.YES ? true : false;
    const autoAssignIdsEdit = membershipType?.applicationOptions?.autoAssignIds ? true : false;
    const requireApprovalEdit = membershipType?.applicationOptions?.requireApproval ? true : false;
    const acceptRSPPaymentEdit = membershipType?.applicationOptions?.acceptRSPPayment ? true : false;
    const acceptOtherPaymentEdit = membershipType?.applicationOptions?.acceptOtherPayment ? true : false;
    const startRuleEdit = membershipType?.isStartAtTimeOfPurchase ?
        CONSTANT.MEMBERSHIP.START_MEMBERSHIP_ON_PURCHASE_DATE.YES : CONSTANT.MEMBERSHIP.START_MEMBERSHIP_ON_PURCHASE_DATE.NO;

    const [allowOnline, setAllowOnline] = useState<boolean>(membershipType ? allowOnlineEdit : true);
    const [autoAssignIds, setAutoAssignIds] = useState<boolean>(membershipType ? autoAssignIdsEdit : true);
    const [requireApproval, setRequireApproval] = useState<boolean>(membershipType ? requireApprovalEdit : true);
    const [acceptRSPPayment, setAcceptRSPPayment] = useState<boolean>(membershipType ? acceptRSPPaymentEdit : true);
    const [acceptOtherPayment, setAcceptOtherPayment] = useState<boolean>(membershipType ? acceptOtherPaymentEdit : true);
    const [organizationDocuments, setOrganizationDocuments] = useState<(OrganizationDocument | null)[] | null | undefined>(membershipType ? membershipType.organizationDocuments : []);
    const [organizationRules, setOrganizationRules] = useState<(OrganizationRule | null)[] | null | undefined>(membershipType ? membershipType.organizationRules : []);

    const [name, setName] = useState(membershipType?.name ? membershipType?.name : "");
    const [type, setType] = useState(membershipType?.category ? membershipType?.category : MembershipTypeCategory.individual as string);
    const [description, setDescription] = useState(membershipType?.description ? membershipType?.description : "");
    const [idValues, setIdValues] = useState(membershipType?.membershipIdValues ? membershipType?.membershipIdValues : "");
    const [schedule, setSchedule] = useState<string>(membershipType?.schedule ? membershipType?.schedule : MembershipTypeSchedule.annual as string);
    const [seasons, setSeasons] = useState<Season[]>([]);
    const [startRule, setStartRule] = useState(membershipType ? startRuleEdit : CONSTANT.MEMBERSHIP.START_MEMBERSHIP_ON_PURCHASE_DATE.YES);
    const [startDate, setStartDate] = useState<any>(membershipType?.setStartDate ? membershipType?.setStartDate : moment(new Date).format("YYYY-MM-DD"));
    const [price, setPrice] = useState(membershipType?.price ? membershipType?.price : 0);

    const handleMembershipScheduleChange = (schedule: string) => {
        setSchedule(schedule);
    }

    const handleMembershipScheduleSeasonsChange = (selectedSeasons: Season[]) => {
        setSeasons(selectedSeasons);
    }

    const handleMembershipScheduleStartDateChange = (startDate: string) => {
        setStartDate(startDate);
    }

    const validateIdValues = () => {
        setError(isMembershipIdValueValid(idValues, membershipType));
    }

    const formatDateToAWSDate = (dateString: string) => {
        // Make the hour = 1:00 am
        return moment(dateString).hour(1).format("YYYY-MM-DDThh:mm:ss.sssZ");
    }

    const verifyIsFormValid = () => {
        if (!name) {
            setError("Please include a name in the Basic Info section.");
            return false;
        }
        if (!type) {
            setError("Please include a membership type in the Basic Info section.");
            return false;
        }
        if (!schedule) {
            setError("Please include a membership length in the Membership Length section.");
            return false;
        }
        if (!startRule) {
            setError("Please select an option for start membership on purchase date in the Membership Length section.");
            return false;
        }
        if (!startDate) {
            setError("Please select a start date for the membership in the Membership Length section.");
            return false;
        }
        if (isNaN(price) || (!price && price !== 0)) {
            setError("Please include a price in the Membership Price section.");
            return false;
        }
        const errorMsg = isMembershipIdValueValid(idValues, membershipType);
        if(errorMsg) {
            setError(errorMsg);
            return false;
        }
        return true;
    }

    const getApplicationFields = () => {
        const isIndividual = MembershipTypeCategory.individual;
        const isGroup = MembershipTypeCategory.group;
        const isHorsePony = MembershipTypeCategory.horse;
        const applicationFields = {
            firstName: type === isIndividual && allowOnline ? firstNameAndLastName : false,
            lastName: type === isIndividual && allowOnline ? firstNameAndLastName : false,
            dateOfBirth: type === isIndividual ? dateOfBirth : horseDOB,
            contact: allowOnline ? contact : false,
            gradeLevel: type === isIndividual && allowOnline ? gradeLevel : false,
            barnName: allowOnline ? barnName : false,
            teamName: type === isIndividual && allowOnline ? teamName : false,
            backNumber: (type === isIndividual || type === isGroup) && allowOnline ? backNumber : false,
            groupName: type === isGroup && allowOnline ? groupName : false,
            groupLeader: type === isGroup && allowOnline ? groupLeader : false,
            groupLeaderContactInfo: type === isGroup && allowOnline ? groupLeaderContact : false,
            isTeam: type === isGroup && allowOnline ? isTeam : false,
            collectMemberDetailsTogether: type === isGroup && allowOnline ? collectMemberDetailsTogether : false,
            maxNumberOfGroupMembers: type === isGroup && allowOnline ? maxNumberOfGroupMembers : 0,
            horseName: type === isHorsePony && allowOnline ? horseName : false,
            horseHeight: type === isHorsePony && allowOnline ? horseHeight : false,
            horseOwnerName: type === isHorsePony && allowOnline ? horseOwnerName : false,
            cogginsInfo: type === isHorsePony && allowOnline ? cogginsInfo : false,
            cogginsUpload: type === isHorsePony && allowOnline ? cogginsUpload : false,
        };
        if (type === isGroup) {
            applicationFields.contact = allowOnline ? groupContact : false;
        }
        // dateOfBirth is required in individual membership type and optional in horse membership type
        if(allowOnline === false) {
            applicationFields.dateOfBirth = false;
        }
        return applicationFields;
    }

    const getApplicationOptions = () => {
        return {
            requireApproval: allowOnline ? requireApproval : false,
            autoAssignIds: allowOnline ? autoAssignIds : false,
            acceptRSPPayment: allowOnline ? acceptRSPPayment : false,
            acceptOtherPayment: allowOnline ? acceptOtherPayment : false
        };
    }

    const handleUpdateLinkedSeasons = async (membershipType: OrganizationMembershipType) => {
        let oldSelectedSeasons: any[] = [];
        if (membershipType.seasons) {
            const seasonData: any = membershipType.seasons;
            oldSelectedSeasons = seasonData.items;
        }

        // Seasons to remove
        const seasonsToBeRemoved = oldSelectedSeasons.filter((oldSeason) => {
            if (oldSeason) {
                const oldSeasonId = oldSeason.seasonID;
                const foundInNewSelectedSeasons = seasons.findIndex(newSelectedSeason => newSelectedSeason.id === oldSeasonId);
                if (foundInNewSelectedSeasons === -1) return true;
            }
            return false;
        });

        for (let i = 0; i < seasonsToBeRemoved.length; i++) {
            const organizationMembershipTypeSeason = seasonsToBeRemoved[i];
            await deleteOrganizationMembershipTypeSeason({id: organizationMembershipTypeSeason.id});
        }

        // Seasons to add
        const seasonsToBeAdded = seasons.filter((newSeason) => {
            if (newSeason) {
                const newSeasonId = newSeason.id;
                const foundInOldSelectedSeasons = oldSelectedSeasons.findIndex(oldSelectedSeason => oldSelectedSeason?.seasonID === newSeasonId);
                if (foundInOldSelectedSeasons === -1) return true;
            }
            return false;
        });

        for (let i = 0; i < seasonsToBeAdded.length; i++) {
            const season = seasonsToBeAdded[i];
            const input: CreateOrganizationMembershipTypeSeasonsInput = {
                organizationMembershipTypeID: membershipType.id,
                seasonID: season.id
            };
            const createResult = await createOrganizationMembershipTypeSeason(input);
        }
    }

    const handleCreateMembershipType = async () => {
        const formattedDate = formatDateToAWSDate(startDate);
        const input: CreateOrganizationMembershipTypeInput = {
            name,
            description,
            category: type as MembershipTypeCategory,
            membershipIdValues: idValues,
            nextAvailableMembershipId: autoAssignIds ? getNextAvailableMembershipId(idValues, "") : "",
            schedule: schedule as MembershipTypeSchedule,
            isStartAtTimeOfPurchase: startRule === CONSTANT.MEMBERSHIP.START_MEMBERSHIP_ON_PURCHASE_DATE.YES,
            setStartDate: formattedDate,
            price,
            organizationId: organization.id,
            acceptOnlineApplications: allowOnline ? CONSTANT.MEMBERSHIP.ONLINE_APPLICATION.YES : CONSTANT.MEMBERSHIP.ONLINE_APPLICATION.NO,
            applicationFields: getApplicationFields(),
            applicationOptions: getApplicationOptions(),
            organizationDocuments: allowOnline ? organizationDocuments : [],
            organizationRules: allowOnline ? organizationRules : []
        };
        const createMTResult = await createOrganizationMembershipType(input);
        if (createMTResult.isSuccess) {
            const newMembershipType = createMTResult.result;
            await handleUpdateLinkedSeasons(newMembershipType);
            navigateBack(organization.id);
        } else {
            setError(createMTResult.message);
        }
        setIsLoading(false);
    }

    const handleUpdateMembershipType = async (membershipType: OrganizationMembershipType) => {
        const formattedDate = formatDateToAWSDate(startDate);
        const updateInput:  UpdateOrganizationMembershipTypeInput= {
            id: membershipType.id,
            name,
            description,
            category: type as MembershipTypeCategory,
            membershipIdValues: idValues,
            schedule: schedule as MembershipTypeSchedule,
            isStartAtTimeOfPurchase: startRule === CONSTANT.MEMBERSHIP.START_MEMBERSHIP_ON_PURCHASE_DATE.YES,
            setStartDate: formattedDate,
            price,
            acceptOnlineApplications: allowOnline ? CONSTANT.MEMBERSHIP.ONLINE_APPLICATION.YES : CONSTANT.MEMBERSHIP.ONLINE_APPLICATION.NO,
            applicationFields: getApplicationFields(),
            applicationOptions: getApplicationOptions(),
            organizationDocuments: allowOnline ? organizationDocuments : [],
            organizationRules: allowOnline ? organizationRules : [],
            
        };
        const updateMTResult = await updateOrganizationMembershipType(updateInput);
        if (updateMTResult.isSuccess) {
            await handleUpdateLinkedSeasons(membershipType);
            navigateBack(organization.id);
        } else {
            setError(updateMTResult.message);
        }
    }

    const handleSave = async () => {
        setError("");
        if (verifyIsFormValid()) {
            if(organization) {
                setIsLoading(true);
                if(membershipType) {
                    await handleUpdateMembershipType(membershipType);
                } else {
                    await handleCreateMembershipType();
                }
                setIsLoading(false);
            } else {
                setError("No organization found.")
            }   
        }
    }

    const navigateBack = (id: string) => {
        const path = "/index/staff/organization/membership-types/" + id;
        history.replace(path);
    }

    return (
        <IonRow className="ion-justify-content-center">
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading && <Spinner/>}
            <IonCol size="12">
                <IonCard mode="md" className="ion-padding bg-white">
                    <IonRow onClick={() => setIsExpandedBasicDetails(!isExpandedBasicDetails)}>
                        <IonCol size="10" className="ion-text-left">
                            <h5>Basic Details</h5>
                        </IonCol>
                        <IonCol size="2" className="ion-text-right">
                            <IonIcon size="large" icon={isExpandedBasicDetails ? chevronUp : chevronDown} />
                        </IonCol>
                    </IonRow>
                    {isExpandedBasicDetails && (<IonCardContent>
                        <IonRow className="pb-3">
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position="stacked">Name <RequiredInputIndicator /></IonLabel>
                                    <IonInput
                                        id="membership-type-name"
                                        type="text"
                                        value={name}
                                        aria-required={true}
                                        onIonChange={e => {
                                            setError("");
                                            setName(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white" lines="none">
                                    <IonLabel position="stacked">Membership Type <RequiredInputIndicator /></IonLabel>
                                    <Input
                                        id="membership-type"
                                        name="mt-type" 
                                        type="select"
                                        value={type} 
                                        className="mt-2"
                                        onChange={e => {
                                            setType(e.target.value);
                                        }}
                                    >
                                        {categoryOptions?.map((category: CategoryTypeOption, index: number) => (
                                            <option key={index} value={category.value}>{category.name}</option>
                                        ))}
                                    </Input>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow className="pb-3">
                            <IonCol sizeXs="12" sizeMd="12">
                                <IonLabel position="floating" className="text-darker pl-3">Description</IonLabel>
                                <Input
                                    id="membership-type-description"
                                    rows="3"
                                    type="textarea"
                                    name="mt-description"
                                    value={description}
                                    spellCheck="true"
                                    onChange={e => {
                                        setDescription(e.target.value!);
                                    }}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="pb-3">
                            <IonCol sizeXs="12" sizeMd="12">
                                <IonItem color="white">
                                    <IonLabel position="stacked">
                                    </IonLabel>
                                    <BasicTooltip 
                                        className="membership-id-values-label"
                                        label="Membership Id Values"
                                        tip="Should be a list of increasing number values separated by commas - Can be a range like 1-100 - Can be a single number like 234 - Example: 1-100,150-200,234,300-400"
                                    />
                                    <IonInput
                                        id="membership-id-values"
                                        type="text"
                                        value={idValues}
                                        aria-required={true}
                                        onIonBlur={validateIdValues}
                                        onIonChange={e => {
                                            setError("");
                                            setIdValues(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        {membershipType?.nextAvailableMembershipId && (
                            <IonRow className="pb-3">
                                <IonCol sizeXs="12" sizeMd="12">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">
                                            Next Unused Membership Id
                                        </IonLabel>
                                        <IonInput
                                            id="next-membership-id-value"
                                            type="text"
                                            value={membershipType.nextAvailableMembershipId}
                                            disabled={true}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        )}
                    </IonCardContent>
                    )}
                </IonCard>

                <MembershipTypeFormScheduleCard 
                    onChangeSchedule={handleMembershipScheduleChange} 
                    onChangeSeasons={handleMembershipScheduleSeasonsChange} 
                    onChangeStartDate={handleMembershipScheduleStartDateChange} 
                    organization={organization} 
                    membershipType={membershipType} 
                />

                <IonCard mode="md" className="ion-padding bg-white">
                    <IonRow onClick={() => setIsExpandedMembershipPrice(!isExpandedMembershipPrice)}>
                        <IonCol size="10" className="ion-text-left">
                            <h5>Membership Price</h5>
                        </IonCol>
                        <IonCol size="2" className="ion-text-right">
                            <IonIcon size="large" icon={isExpandedMembershipPrice ? chevronUp : chevronDown} />
                        </IonCol>
                    </IonRow>
                    {isExpandedMembershipPrice && (<IonCardContent>
                        <IonRow className="pb-3">
                            <IonCol sizeXs="12" sizeMd="6" className="mt--4">
                                <IonItem color="white" lines="none">
                                    <IonLabel position="stacked">Price <RequiredInputIndicator /></IonLabel>
                                </IonItem>
                                <IonItem color="white" lines="none" id="membership-type-price">
                                    <PriceField value={price} className="ion-margin-top" onChange={setPrice}/>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                    )}
                </IonCard>

                <IonCard mode="md" className="ion-padding bg-white">
                    <IonRow onClick={() => setIsExpandedOnlineApplication(!isExpandedOnlineApplication)}>
                        <IonCol size="10" className="ion-text-left">
                            <h5>Online Application</h5>
                        </IonCol>
                        <IonCol size="2" className="ion-text-right">
                            <IonIcon size="large" icon={isExpandedOnlineApplication ? chevronUp : chevronDown} />
                        </IonCol>
                    </IonRow>
                    {isExpandedOnlineApplication && (
                        <>
                            <IonGrid>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem lines="none">
                                            <IonCheckbox
                                                id="membership-type-online-application-check-box" 
                                                checked={allowOnline}
                                                onIonChange={(e) =>
                                                setAllowOnline(e.detail.checked)
                                                }
                                            />
                                            <IonLabel className="pl-3 ion-text-wrap">
                                                Allow Online Application Submissions
                                            </IonLabel>
                                        </IonItem>
                                    </IonCol>
                                    { allowOnline && 
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <IonItem lines="none">
                                                <IonCheckbox
                                                    id="admin-approval-of-applications"                               
                                                    checked={requireApproval}
                                                    onIonChange={(e) =>
                                                    setRequireApproval(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3 ion-text-wrap">
                                                    Require Org Admin Approval of Applications
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                    }
                                </IonRow> 
                                { allowOnline &&   
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <IonItem lines="none">
                                                <IonCheckbox
                                                    id="auto-assign-membership-ids"
                                                    checked={autoAssignIds}
                                                    onIonChange={(e) =>
                                                    setAutoAssignIds(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3 ion-text-wrap">
                                                    Auto-Assign Member IDs
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow> 
                                }
                            </IonGrid>
                            { type === MembershipTypeCategory.individual && allowOnline &&
                                <IonGrid>
                                    <h6>Application Fields for an Individual Member</h6>
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox
                                                    id="membership-type-first-and-last-name"
                                                    disabled={true} 
                                                    checked={firstNameAndLastName}
                                                    onIonChange={(e) =>
                                                        setFirstNameAndLastName(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel  className="pl-3">
                                                    First and Last Name
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox 
                                                    id="membership-type-individual-contact-info"
                                                    disabled={true} 
                                                    checked={contact}
                                                    onIonChange={(e) =>
                                                        setContact(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel  className="pl-3">
                                                    Contact Info
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox
                                                    id="membership-type-dob"
                                                    disabled={false} 
                                                    checked={dateOfBirth}
                                                    onIonChange={(e) =>
                                                        setDateOfBirth(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel  className="pl-3">
                                                    Date of Birth
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow> 
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox 
                                                    id="membership-type-individual-back-number"
                                                    checked={backNumber}
                                                    onIonChange={(e) =>
                                                        setBackNumber(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel  className="pl-3">
                                                    Back Number
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox 
                                                    id="membership-type-individual-grade-level"
                                                    checked={gradeLevel}
                                                    onIonChange={(e) =>
                                                        setGradeLevel(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel  className="pl-3">
                                                    Grade Level
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox 
                                                    id="membership-type-individual-team-name"
                                                    checked={teamName}
                                                    onIonChange={(e) =>
                                                        setTeamName(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel  className="pl-3">
                                                    Team Name
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow> 
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox
                                                    id="membershipType-barn-checkbox"
                                                    checked={barnName}
                                                    onIonChange={(e) =>
                                                        setBarnName(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel  className="pl-3">
                                                    Barn Name
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>    
                                </IonGrid>
                            }
                            { type === MembershipTypeCategory.group && allowOnline &&
                                <IonGrid>
                                    <h6>Application Fields for a Group Member</h6>
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox  
                                                    id="membership-type-group-name"
                                                    checked={groupName}
                                                    onIonChange={(e) =>
                                                        setGroupName(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3">
                                                    Group Name
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox 
                                                    id="membership-type-group-leader"
                                                    checked={groupLeader}
                                                    onIonChange={(e) =>
                                                        setGroupLeader(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3">
                                                    Group Leader
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox 
                                                    id="membership-type-group-barn-name"
                                                    checked={barnName}
                                                    onIonChange={(e) =>
                                                        setBarnName(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3">
                                                    Barn Name
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox 
                                                    id="membership-type-group-contact-info"
                                                    checked={groupContact}
                                                    onIonChange={(e) =>
                                                        setGroupContact(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3">
                                                    Group Contact Info
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox 
                                                    id="membership-type-group-leader-contact-info"
                                                    checked={groupLeaderContact}
                                                    onIonChange={(e) =>
                                                        setGroupLeaderContact(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3">
                                                    Group Leader Contact Info
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox 
                                                    id="membership-type-group-is-team-checkbox"
                                                    checked={isTeam}
                                                    onIonChange={(e) =>
                                                        setIsTeam(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3">
                                                    Is Team
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow> 
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox 
                                                    id="membership-type-group-back-number"
                                                    checked={backNumber}
                                                    onIonChange={(e) =>
                                                        setBackNumber(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel  className="pl-3">
                                                    Back Number
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="6" sizeLg="4">
                                            <IonItem lines="none">
                                                <IonCheckbox 
                                                    id="membership-type-group-collect-member-details-together"
                                                    checked={collectMemberDetailsTogether}
                                                    onIonChange={(e) =>
                                                        setCollectMemberDetailsTogether(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3 ion-text-wrap">
                                                    Collect All Member Info in One Application?
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="4" sizeLg="4">
                                            <FormGroup>
                                                {/* <IonLabel position="stacked">Max Number of Group Members</IonLabel> */}
                                                <IonLabel position="stacked" className="description pl-3 text-primary">Max Number of Group Members</IonLabel>
                                                <Input 
                                                    id="membership-type-group-max-number"
                                                    type="number"
                                                    value={maxNumberOfGroupMembers}
                                                    min={1}
                                                    max={99}
                                                    placeholder="1"
                                                    onChange={e => {
                                                        setMaxNumberOfGroupMembers(parseInt(e.target.value));
                                                    }}
                                                />
                                            </FormGroup>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            }
                            { type === MembershipTypeCategory.horse && allowOnline  &&
                                <IonGrid>
                                    <h6>Application Fields for a Horse/Pony Member</h6>
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox 
                                                    id="membership-type-horse-name"
                                                    disabled= {true} 
                                                    checked={horseName}
                                                    onIonChange={(e) =>
                                                        setHorseName(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3">
                                                    Horse Name
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox
                                                    id="membership-type-horse-height" 
                                                    disabled= {true} 
                                                    checked={horseHeight}
                                                    onIonChange={(e) =>
                                                        setHorseHeight(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3">
                                                    Height
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox 
                                                    id="membership-type-horse-coggins-info"
                                                    checked={cogginsInfo}
                                                    onIonChange={(e) =>
                                                        setCogginsInfo(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3">
                                                    Coggins Info
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox
                                                    id="membership-type-owner-name" 
                                                    disabled={true} 
                                                    checked={horseOwnerName}
                                                    onIonChange={(e) =>
                                                        setHorseOwnerName(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3">
                                                    Owner Name
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox
                                                    id="membership-type-horse-contact-info"
                                                    disabled={true}
                                                    checked={contact}
                                                    onIonChange={(e) =>
                                                        setContact(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3">
                                                    Contact Info
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox
                                                    id="membership-type-horse-coggins-document-upload"
                                                    checked={cogginsUpload}
                                                    onIonChange={(e) =>
                                                        setCogginsUpload(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3">
                                                    Coggins Document Upload
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow> 
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox
                                                    id="membership-type-horse-barn-name"
                                                    checked={barnName}
                                                    onIonChange={(e) =>
                                                        setBarnName(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3">
                                                    Barn Name
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="4">
                                            <IonItem lines="none">
                                                <IonCheckbox
                                                    id="membership-type-horse-dob"
                                                    checked={horseDOB}
                                                    onIonChange={(e) =>
                                                        setHorseDOB(e.detail.checked)
                                                    }
                                                />
                                                <IonLabel className="pl-3">
                                                    Date of Birth
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>    
                                </IonGrid>
                            }
                            {allowOnline && 
                                <>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="12">
                                                <IonItem lines="none">
                                                    <IonCheckbox
                                                        id="accept-credit-card-payments"
                                                        checked={acceptRSPPayment}
                                                        onIonChange={(e) =>
                                                            setAcceptRSPPayment(e.detail.checked)
                                                        }
                                                    />
                                                    <IonLabel className="pl-3 ion-text-wrap">
                                                        Accept Credit Card Payments through RingSide Pro (includes a fee of $5 per payment)
                                                    </IonLabel>
                                                </IonItem>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="12">
                                                <IonItem lines="none">
                                                    <IonCheckbox
                                                        id="payment-through-auxiliary-method"                                
                                                        checked={acceptOtherPayment}
                                                        onIonChange={(e) =>
                                                            setAcceptOtherPayment(e.detail.checked)
                                                        }
                                                    />
                                                    <IonLabel className="pl-3 ion-text-wrap">
                                                        Track Payments through an auxiliary method such as mailed checks
                                                    </IonLabel>
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                    <MembershipDocumentUploadList
                                        type={type}
                                        organizationDocuments={organizationDocuments}
                                        setOrganizationDocuments={setOrganizationDocuments}
                                        membershipType={membershipType}
                                    />
                                    <MembershipTypeWaiversList type={type} organizationRules={organizationRules} setOrganizationRules={setOrganizationRules} membershipType={membershipType}/>
                                </>
                            }
                        </>
                   
                    )}
                </IonCard>

                <IonRow className="ion-justify-content-center mb-5 mt-4">
                    <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                        {isLoading ?
                            <Spinner/>
                        :
                            <IonButton id="membership-type-save-btn" expand="block" color="tertiary" onClick={handleSave}>
                                Save
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
    );
};

export default MembershipTypeForm;
