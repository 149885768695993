import {
    IonButton,
    IonButtons,
    IonHeader,
    IonToolbar
} from "@ionic/react";
import React, { useState } from "react";
import { Organization } from "../../models";

interface _Props {
    organization: Organization
    active: string
}

const OrganizationDivisionClassNavbar: React.FC<_Props> = ({active, organization}) => {

    const [currentActiveBtn, setCurrentActiveBtn] = useState(active || "divisions");

    return (
        <IonHeader id="editEventMenu">
            <IonToolbar id="editEventMenu" color="light">
                <div className="scrollmenu">
                    <IonButtons>
                        <IonButton routerLink={"/index/staff/organization/org-divisions/" + organization.id} routerDirection="none" onClick={() => setCurrentActiveBtn("divisions")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "divisions" ? "active-border" : "")}>
                                Divisions
                            </p>
                        </IonButton>
                        <IonButton routerLink={"/index/staff/organization/org-classes/" + organization.id} routerDirection="none" onClick={() => setCurrentActiveBtn("classes")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "classes" ? "active-border" : "")}>
                                Classes
                            </p>
                        </IonButton>
                        <IonButton routerLink={"/index/staff/organization/division-templates/" + organization.id} routerDirection="none" onClick={() => setCurrentActiveBtn("templates")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "templates" ? "active-border" : "")}>
                                Templates
                            </p>
                        </IonButton>
                    </IonButtons>
                </div>
            </IonToolbar>
        </IonHeader>
    );
};

export default OrganizationDivisionClassNavbar;
