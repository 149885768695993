export interface EventClassTypeOption {
    name: string
    abbreviation?: string
    shorthand?: string
    value: string
}

export function getEventClassTypeAbbreviation(value: string) {
    let result = "";
    for (var i = 0; i < eventClassTypes.length; i++) {
        const currentType = eventClassTypes[i];
        if (currentType.value === value) {
            if (currentType.abbreviation) result = currentType.abbreviation;
            else result = currentType.name;
        }
    }
    return result;
}

export const eventClassTypes = [
    {
        "name": "Classic",
        "abbreviation": "Classic",
        "value": "classic"
    },
    {
        "name": "Classic (First Round)",
        "abbreviation": "1st Classic",
        "value": "classic_1",
        "shorthand": "*"
    },
    {
        "name": "Classic (Second Round)",
        "abbreviation": "2nd Classic",
        "value": "classic_2"
    },
    {
        "name": "Derby",
        "abbreviation": "Derby",
        "value": "derby"
    },
    {
        "name": "Derby (First Round)",
        "abbreviation": "1st Derby",
        "value": "derby_1"
    },
    {
        "name": "Derby (Second Round)",
        "abbreviation": "2nd Derby",
        "value": "derby_2"
    },
    {
        "name": "Equitation on the Flat",
        "abbreviation": "Eq on the Flat",
        "value": "eq_flat"
    },
    {
        "name": "Equitation Over Fences",
        "abbreviation": "Eq O/F",
        "value": "eq_fences"
    },
    {
        "name": "Medal",
        "abbreviation": "Medal",
        "value": "medal"
    },
    {
        "name": "Model",
        "abbreviation": "Model",
        "value": "model",
        "shorthand": "(c)"
    },
    {
        "name": "Hack",
        "abbreviation": "Hack",
        "value": "hack"
    },
    {
        "name": "Handy",
        "abbreviation": "Handy",
        "value": "handy",
        "shorthand": "(h)"
    },
    {
        "name": "Schooling (Judged)",
        "abbreviation": "Schooling",
        "value": "schooling_judged"
    },
    {
        "name": "Schooling (Unjudged)",
        "abbreviation": "Unjudged",
        "value": "schooling_unjudged"
    },
    {
        "name": "Table A Speed",
        "abbreviation": "Speed",
        "value": "table_a"
    },
    {
        "name": "Table A (FEI Article 238)",
        "abbreviation": "Immediate J/O",
        "value": "table_a_fei_238"
    },
    {
        "name": "Table II",
        "abbreviation": "II",
        "value": "table_II"
    },
    {
        "name": "Table II 2(a)",
        "abbreviation": "II2(a)",
        "value": "table_II2a"
    },
    {
        "name": "Table II 2(b)",
        "abbreviation": "II2(b)",
        "value": "table_II2b"
    },
    {
        "name": "Table II 2(c)",
        "abbreviation": "II2(c)",
        "value": "table_II2c"
    },
    {
        "name": "Table II Sec 1",
        "abbreviation": "II Sec1",
        "value": "table_IISec1"
    },
    {
        "name": "Over Fences",
        "abbreviation": "O/F",
        "value": "over_fences"
    },
    {
        "name": "Under Saddle",
        "abbreviation": "U/S",
        "value": "under_saddle",
        "shorthand": "(u/s)"
    },
    {
        "name": "break",
        "abbreviation": "break",
        "value": "break",
        "shorthand": "break"
    }
];