import {
    IonCol,
    IonDatetime,
    IonItem,
    IonLabel,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useState } from "react";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { FormGroup } from "reactstrap";
import { isWindows } from "../../utilities/platform/Platform";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    initialStartDate?: string
    initialEndDate?: string
    onChange: Function
    isRequired?: boolean
}

const StartToEndDatePicker: React.FC<_Props> = ({initialStartDate, initialEndDate, onChange, isRequired}) => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const [startDate, setStartDate] = useState(initialStartDate ? initialStartDate : today);
    const [endDate, setEndDate] = useState(initialEndDate ? initialEndDate : today);

    const handleMobileStartDateChange = (value: any) => {
        const formattedDate = moment(value).format("YYYY-MM-DD")
        setStartDate(formattedDate); 
        onChange(formattedDate, endDate);
    }

    const handleMobileEndDateChange = (value: any) => {
        const formattedDate = moment(value).format("YYYY-MM-DD")
        setEndDate(formattedDate); 
        onChange(startDate, formattedDate);
    }

    const handleReactDatetimeChange = (who: string, date: any) => {
        const currentDate = moment(date).format("YYYY-MM-DD");
        if (
            startDate &&
            who === "endDate" &&
            startDate > currentDate
        ) {
            setStartDate(currentDate);
            setEndDate(currentDate);
            onChange(currentDate, currentDate);
        } else if (
            endDate &&
            who === "startDate" &&
            endDate < currentDate
        ) {
            setStartDate(currentDate);
            setEndDate(currentDate);
            onChange(currentDate, currentDate);
        } else {
            if (who === "startDate") {
                setStartDate(currentDate);
                onChange(currentDate, endDate);
            }
            if (who === "endDate") {
                setEndDate(currentDate);
                onChange(startDate, currentDate);
            }
        }
    }

    /**
     * Adds classnames to calendar squares to give background colors
     * @param date 
     * @returns classname
     */
    const getClassNameReactDatetimeDays = (date: string) => {
        const currentDate = moment(date).format("YYYY-MM-DD");
        if (startDate < currentDate && currentDate < endDate) {
            return " middle-date";
        } else if (startDate === currentDate) {
            return " start-date";
        } else if (endDate === currentDate) {
            return " end-date";
        }
        return "";
    };

    return (
        <>
            {(isWindows() || (isPlatform("desktop"))) ?
                <IonRow className="input-daterange datepicker align-items-center">
                    <IonCol sizeXs="10" sizeMd="5">
                        <FormGroup>
                            <label className="form-control-label pl-3 text-darker">
                                Start Date { isRequired &&  <RequiredInputIndicator />}
                            </label>
                            <ReactDatetime
                                inputProps={{
                                    placeholder: "Date Picker Here",
                                    className: "date-picker",
                                    id: "start-date-input"
                                }}
                                value={startDate}
                                timeFormat={false}
                                onChange={e =>
                                    handleReactDatetimeChange("startDate", e)
                                }
                                renderDay={(props, currentDate, selectedDate) => {
                                    let classes = props.className;
                                    classes += getClassNameReactDatetimeDays(
                                        currentDate
                                    );
                                    return (
                                        <td {...props} className={classes}>
                                            {currentDate.date()}
                                        </td>
                                    );
                                }}
                            />
                        </FormGroup>
                    </IonCol>
                    <IonCol sizeXs="10" sizeMd="5">
                        <FormGroup>
                            <label className="form-control-label pl-3 text-darker">
                                End Date { isRequired &&  <RequiredInputIndicator />}
                            </label>
                            <ReactDatetime
                                inputProps={{
                                    placeholder: "Date Picker Here",
                                    className: "date-picker",
                                    id: "end-date-input"
                                }}
                                value={endDate}
                                timeFormat={false}
                                onChange={e =>
                                    handleReactDatetimeChange("endDate", e)
                                }
                                renderDay={(props, currentDate, selectedDate) => {
                                    let classes = props.className;
                                    classes += getClassNameReactDatetimeDays(
                                        currentDate
                                    );
                                    return (
                                        <td {...props} className={classes}>
                                            {currentDate.date()}
                                        </td>
                                    );
                                }}
                            />
                        </FormGroup>
                    </IonCol>
                </IonRow>
                :
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeXs="12" sizeMd="5" className="ion-no-padding">
                        <IonItem>
                            <IonLabel>Start Date</IonLabel>
                            <IonDatetime value={startDate} min={today} onIonChange={(e) => handleMobileStartDateChange(e.detail.value!)}></IonDatetime>
                        </IonItem>
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="5" className="ion-no-padding">
                        <IonItem>
                            <IonLabel>End Date</IonLabel>
                            <IonDatetime value={endDate} min={startDate} onIonChange={(e) => handleMobileEndDateChange(e.detail.value!)}></IonDatetime>
                        </IonItem>
                    </IonCol>
                </IonRow>
            }
        </>
    );
};

export default StartToEndDatePicker;
