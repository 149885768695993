import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateHorseShowInput, CreateHorseShowMutation, DeleteHorseShowInput, DeleteHorseShowMutation, GetHorseShowQuery, ListHorseShowsQuery, UpdateHorseShowInput, UpdateHorseShowMutation } from "../../API";
import moment from "moment";
import { HorseShow } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: horseShow. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created horseShow.
*/
export async function createHorseShow(input: CreateHorseShowInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "HorseShow", "No input", "Create HorseShow received no input.");
   try {
        const fullInput: CreateHorseShowInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createHorseShow, { input: fullInput }))) as GraphQLResult<CreateHorseShowMutation>;
       else result = (await API.graphql({
           query: mutations.createHorseShow,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateHorseShowMutation>;
       const horseShow = result.data?.createHorseShow;
       return formatResult(true, "HorseShow", horseShow, "Successfully created the horseShow.");
   } catch (error: any) {
       return formatResult(false, "HorseShow", error, "Error creating record in the database for type: horseShow");
   }
}

/**
* Handle updating a new record in the database for type: horseShow. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated horseShow.
*/
export async function updateHorseShow(input: UpdateHorseShowInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "HorseShow", "No input", "Update HorseShow received no input.");
   try {
        const fullInput: UpdateHorseShowInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateHorseShow, { input: fullInput }))) as GraphQLResult<UpdateHorseShowMutation>;
       else result = (await API.graphql({
           query: mutations.updateHorseShow,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateHorseShowMutation>;
       const horseShow = result.data?.updateHorseShow;
       return formatResult(true, "HorseShow", horseShow, "Successfully updated the horseShow.");
   } catch (error: any) {
       return formatResult(false, "HorseShow", error, "Error updating record in the database for type: horseShow");
   }
}

/**
* Handle deleting a new record in the database for type: horseShow. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted horseShow.
*/
export async function deleteHorseShow(input: DeleteHorseShowInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "HorseShow", "No input", "Delete HorseShow received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteHorseShow, { input: input }))) as GraphQLResult<DeleteHorseShowMutation>;
       else result = (await API.graphql({
           query: mutations.deleteHorseShow,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteHorseShowMutation>;
       const horseShow = result.data?.deleteHorseShow;
       return formatResult(true, "HorseShow", horseShow, "Successfully deleted the horseShow.");
   } catch (error: any) {
       return formatResult(false, "HorseShow", error, "Error deleting record in the database for type: horseShow");
   }
}

/**
* Get all records in the database for type: horseShow. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the horseShow object
*/
export async function getAllHorseShows(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listHorseShows,
            variables: {
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListHorseShowsQuery>;

        let items = result.data?.listHorseShows?.items as HorseShow[];
        let nextToken = result.data?.listHorseShows?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listHorseShows,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListHorseShowsQuery>;

            const nextItems = nextResult.data?.listHorseShows?.items as HorseShow[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listHorseShows?.nextToken;
        }
        return formatResult(true, "HorseShow", items, "Successfully got the horseShows.");
    } catch (error: any) {
        return formatResult(false, "HorseShow", error, "Error reading record in the database for type: horseShows");
    }
}

/**
* Read a specific record in the database for type: horseShow. 
* 
* @param {string}  id                  The horseShow id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the horseShow object
*/
export async function getHorseShowById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getHorseShow,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetHorseShowQuery>;
       const horseShow = result.data?.getHorseShow;
       return formatResult(true, "HorseShow", horseShow, "Successfully got the horseShow.");
   } catch (error: any) {
       return formatResult(false, "HorseShow", error, "Error reading record in the database for type: horseShow");
   }
}

/**
* Get all records in the database that match the given criteria for type: horseShow. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the horseShow object
*/
export async function getHorseShowByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listHorseShows,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListHorseShowsQuery>;

        let items = result.data?.listHorseShows?.items as HorseShow[];
        let nextToken = result.data?.listHorseShows?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listHorseShows,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListHorseShowsQuery>;

            const nextItems = nextResult.data?.listHorseShows?.items as HorseShow[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listHorseShows?.nextToken;
        }

        const horseShows = items;
        if (horseShows && horseShows[0]) return formatResult(true, "HorseShow", horseShows[0], "Successfully got the horseShow.");
        else return formatResult(false, "HorseShow", null, "Could not find the horseShow.");
    } catch (error: any) {
        return formatResult(false, "HorseShow", error, "Error reading record in the database for type: horseShow");
    }
}

/**
* Get all records in the database that match the given criteria for type: horseShow. 
* 
* @param {string}  name                The name of the horseShow
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the horseShow object
*/
export async function getHorseShowsByName(name: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {name: {eq: name}};
        const result = (await API.graphql({
            query: queries.listHorseShows,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListHorseShowsQuery>;

        let items = result.data?.listHorseShows?.items as HorseShow[];
        let nextToken = result.data?.listHorseShows?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listHorseShows,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListHorseShowsQuery>;

            const nextItems = nextResult.data?.listHorseShows?.items as HorseShow[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listHorseShows?.nextToken;
        }

        return formatResult(true, "HorseShow", items, "Successfully got the HorseShows.");
    } catch (error: any) {
        return formatResult(false, "HorseShow", error, "Error reading record in the database for type: horseShows");
    }
}