import {
    IonCard,
    IonCardContent,
    IonCol,
    IonIcon,
    IonLabel,
    IonRow,
    IonText,
    isPlatform,
} from "@ionic/react";
import React from "react";
import { add } from "ionicons/icons";

interface _Props {
    onSubmit: Function
}

const AddPointTableButton: React.FC<_Props> = ({onSubmit}) => {
    const handleSubmit = () => {
        onSubmit();
    }

    return (
        <>
            {isPlatform("mobile") ? 
                <IonCol size="12">
                    <IonCard className="bg-white" mode="md" onClick={handleSubmit}>
                        <IonCardContent>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="12" className="text-center">
                                    <IonLabel>
                                        <IonIcon icon={add} slot="start" />
                                        <IonText>Add Point Table</IonText>
                                    </IonLabel>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
                :
                <IonCol sizeXs="12" sizeSm="8" sizeMd="4" sizeLg="3">
                    <IonCard className="bg-white" mode="md" onClick={handleSubmit}>
                        <IonCardContent>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="6" className="text-center">
                                    <IonIcon icon={add} size="large" />
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="6" className="text-center">
                                    <IonText>Add Point Table</IonText>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            }
        </>
    );
};

export default AddPointTableButton;
