import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Event, EventEntry, EventRules, EventRulesAgreement } from "../../../models";
import { getEventRulesById } from "../../../utilities/eventRules/EventRules";
import { getEventRulesAgreementsByEntryId } from "../../../utilities/eventRules/EventRulesAgreement";

interface _Props {
    entry: EventEntry
    event: Event
}

interface formattedAgreement {
    rule: EventRules
    agreement: EventRulesAgreement
    isAgreed: boolean
}

const DisplayEntryAgreements: React.FC<_Props> = ({entry, event}) => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [formattedAgreements, setFormattedAgreements] = useState<formattedAgreement[] | null | undefined>();

    const formatAgreements = async (agreements: EventRulesAgreement[]) => {
        let formattedAgreements: formattedAgreement[] = [];
        for (var i = 0; i < agreements.length; i++) {
            const agreement = agreements[i];
            const ruleId = agreement.eventRule?.id;
            if (ruleId) {
                const queryResult = await getEventRulesById(ruleId);
                if (queryResult.isSuccess) {
                    const formattedAgreement: formattedAgreement = {
                        rule: queryResult.result,
                        agreement,
                        isAgreed: true
                    };
                    formattedAgreements.push(formattedAgreement);
                }
            }
        }
        setFormattedAgreements(formattedAgreements);
    }

    useEffect(() => {
        async function getEntryAgreements(entry: EventEntry) {
            const queryResult = await getEventRulesAgreementsByEntryId(entry.id);
            if (queryResult.isSuccess) {
                const currentAgreements = queryResult.result;
                formatAgreements(currentAgreements);
            }
        }
        if (entry) getEntryAgreements(entry);
    }, [entry]);

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {entry ? <IonRow><h3>Rule Agreements: </h3> </IonRow> : <p></p>}
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? <IonIcon icon={chevronDown} />  : <IonIcon icon={chevronUp} />}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {(formattedAgreements && formattedAgreements.length > 0) ? 
                            <IonList className="bg-white">
                                {formattedAgreements.map((agreement, index) => (
                                    <IonItem key={index}>
                                        <IonLabel>
                                            <p>
                                                {agreement.isAgreed ? "Agreed" : "Not Agreed"}: {agreement.rule.title} 
                                            </p>
                                        </IonLabel>
                                    </IonItem>
                                ))}
                            </IonList>
                            :
                            <p>No rule agreements found.</p>
                        }
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default DisplayEntryAgreements;