import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreatePersonalInformationInput, CreatePersonalInformationMutation, DeletePersonalInformationInput, DeletePersonalInformationMutation, GetPersonalInformationQuery, ListPersonalInformationsQuery, UpdatePersonalInformationInput, UpdatePersonalInformationMutation } from "../../API";
import { PersonalInformation } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: personalInformation. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created personalInformation.
*/
export async function createPersonalInformation(input: CreatePersonalInformationInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "PersonalInformation", "No input", "Create PersonalInformation received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createPersonalInformation, { input: input }))) as GraphQLResult<CreatePersonalInformationMutation>;
        else result = (await API.graphql({
            query: mutations.createPersonalInformation,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<CreatePersonalInformationMutation>;
        const personalInformation = result.data?.createPersonalInformation;
        return formatResult(true, "PersonalInformation", personalInformation, "Successfully created the personalInformation.");
    } catch (error: any) {
        return formatResult(false, "PersonalInformation", error, "Error creating record in the database for type: personalInformation");
    }
}

/**
* Handle updating a new record in the database for type: personalInformation. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated personalInformation.
*/
export async function updatePersonalInformation(input: UpdatePersonalInformationInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "PersonalInformation", "No input", "Update PersonalInformation received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updatePersonalInformation, { input: input }))) as GraphQLResult<UpdatePersonalInformationMutation>;
        else result = (await API.graphql({
            query: mutations.updatePersonalInformation,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<UpdatePersonalInformationMutation>;
        const personalInformation = result.data?.updatePersonalInformation;
        return formatResult(true, "PersonalInformation", personalInformation, "Successfully updated the personalInformation.");
    } catch (error: any) {
        return formatResult(false, "PersonalInformation", error, "Error updating record in the database for type: personalInformation");
    }
}

/**
* Handle deleting a new record in the database for type: personalInformation. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted personalInformation.
*/
export async function deletePersonalInformation(input: DeletePersonalInformationInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "PersonalInformation", "No input", "Delete PersonalInformation received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deletePersonalInformation, { input: input }))) as GraphQLResult<DeletePersonalInformationMutation>;
       else result = (await API.graphql({
           query: mutations.deletePersonalInformation,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeletePersonalInformationMutation>;
       const personalInformation = result.data?.deletePersonalInformation;
       return formatResult(true, "PersonalInformation", personalInformation, "Successfully deleted the personalInformation.");
   } catch (error: any) {
       return formatResult(false, "PersonalInformation", error, "Error deleting record in the database for type: personalInformation");
   }
}

/**
* Get all records in the database for type: personalInformation. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the personalInformation object
*/
export async function getAllPersonalInformations(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listPersonalInformations,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPersonalInformationsQuery>;

        let items = result.data?.listPersonalInformations?.items as PersonalInformation[];
        let nextToken = result.data?.listPersonalInformations?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listPersonalInformations,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListPersonalInformationsQuery>;

            const nextItems = nextResult.data?.listPersonalInformations?.items as PersonalInformation[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPersonalInformations?.nextToken;
        }

        return formatResult(true, "PersonalInformation", items, "Successfully got the PersonalInformations.");
    } catch (error: any) {
        return formatResult(false, "PersonalInformation", error, "Error reading record in the database for type: personalInformations");
    }
}

/**
* Read a specific record in the database for type: personalInformation. 
* 
* @param {string}  id                  The personalInformation id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the personalInformation object
*/
export async function getPersonalInformationById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getPersonalInformation,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetPersonalInformationQuery>;
       const personalInformation = result.data?.getPersonalInformation;
       return formatResult(true, "PersonalInformation", personalInformation, "Successfully got the personalInformation.");
   } catch (error: any) {
       return formatResult(false, "PersonalInformation", error, "Error reading record in the database for type: personalInformation");
   }
}

/**
* Get all records in the database that match the given criteria for type: personalInformation. 
* 
* @param {string}  personId            The person id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the personalInformation object
*/
export async function getPersonalInformationByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listPersonalInformations,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPersonalInformationsQuery>;

        let items = result.data?.listPersonalInformations?.items as PersonalInformation[];
        let nextToken = result.data?.listPersonalInformations?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listPersonalInformations,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListPersonalInformationsQuery>;

            const nextItems = nextResult.data?.listPersonalInformations?.items as PersonalInformation[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPersonalInformations?.nextToken;
        }

        const personalInformations = items;
        if (personalInformations && personalInformations[0]) return formatResult(true, "PersonalInformation", personalInformations[0], "Successfully got the personalInformation.");
        else return formatResult(false, "PersonalInformation", null, "Could not find the personalInformation.");
    } catch (error: any) {
        return formatResult(false, "PersonalInformation", error, "Error reading record in the database for type: personalInformation");
    }
}
