import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonRow,
} from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";
import React, { useState } from "react";
import { Event, EventEntry } from "../../../models";
import NewEventStablingForm from "../NewEventStablingForm";

interface _Props {
    event: Event
    entry: EventEntry
}

const EditEntryStablingRequest: React.FC<_Props> = ({entry, event}) => {

    const [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <>
            <IonCard className="ion-padding" color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {entry ? 
                                <IonRow>
                                    <h3>
                                        Stabling Fees:
                                    </h3>
                                </IonRow> 
                                : 
                                <h2>Loading stabling fees...</h2> 
                            }
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? <IonIcon icon={chevronDown} />  : <IonIcon icon={chevronUp} />}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        <NewEventStablingForm entry={entry} event={event} isAdminView={true} />
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default EditEntryStablingRequest;