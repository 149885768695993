import {
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonRow,
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import { CartItem } from "../../interfaces/Cart";

interface _Props {
    items?: (CartItem[] | null)
}

const PaymentSummary: React.FC<_Props> = ({items}) => {

    const [totalBasePrice, setTotalBasePrice] = useState(0);

    useEffect(() => {
        if (items && items.length > 0) {
            let total = 0;
            items.forEach(item => {
                total = total + (item.basePrice * item.quantity);
            });
            setTotalBasePrice(total);
        }
    }, [items]);

    return (
        <IonList className="bg-white" lines="full">
            <IonListHeader>
                <IonRow className="ion-align-items-center">
                    <IonCol size="12">
                        <h2>Order Summary</h2>
                    </IonCol>
                </IonRow>
            </IonListHeader>
            {items ? 
                <>
                    {items.map((item, index) => (
                        <IonItem key={index}>
                            <IonLabel>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeSm="4">
                                        <h2>{item.label}</h2>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeSm="4">
                                        <h3>${item.basePrice.toFixed(2)}</h3>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeSm="4">
                                        <h3>quantity: {item.quantity}</h3>
                                    </IonCol>
                                </IonRow>
                            </IonLabel>
                        </IonItem>
                    ))}
                </>
                :
                <IonItem>
                    <p>Your cart is empty.</p>
                </IonItem>
            }
            <IonItem>
                <IonLabel>
                    <h5 className="font-weight-bold">Total: ${totalBasePrice.toFixed(2)}</h5>
                </IonLabel>
            </IonItem>
        </IonList>
    );
};

export default PaymentSummary;
