import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../../../../components/Headers/Header";
import { Organization, OrganizationClass } from "../../../../../models";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import Spinner from "../../../../../components/Spinners/Spinner";
import { PersonContext } from "../../../../../context/PersonContext";
import { Table } from "reactstrap";
import { FormattedAccumulatedPointData, FormattedPointData } from "../../../../../interfaces/Points";
import { getYearlyPointDataByOrganizationClassId } from "../../../../../utilities/eventResult/OrganizationPointData";
import { getOrganizationClassById } from "../../../../../utilities/organizationClass/OrganizationClass";
import moment from "moment";
import SelectYear from "../../../../../components/DatePicker/SelectYear";
import SelectMembership from "../../../../../components/Memberships/SelectMembership";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import constants from "../../../../../constant/constant";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

const yearChoices = [
    moment(new Date()).subtract(2, "year").format("YYYY"),
    moment(new Date()).subtract(1, "year").format("YYYY"),
    moment(new Date()).format("YYYY"),
    moment(new Date()).add(1, "year").format("YYYY"),
];

const EventOrganizationViewClassPointsPage: React.FC<OrganizationPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [selectedYear, setSelectedYear] = useState(moment(new Date()).format("YYYY")); 
    const [organizationClass, setOrganizationClass] = useState<OrganizationClass | null | undefined>();
    const [formattedAllResultsArray, setFormattedAllResultsArray] = useState<FormattedAccumulatedPointData[][] | null | undefined>(); 
    const [filteredFormattedAllResultsArray, setFilteredFormattedAllResultsArray] = useState<FormattedAccumulatedPointData[][] | null | undefined>(); 
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSelectedYear = async (year: string) => {
        setSelectedYear(year);
        await getOrganizationClassResults(year);
    }

    const handleSelectedMembership = (selected: string) => {
        setIsLoading(true);

        if (selected === "All Riders") {    
            setFilteredFormattedAllResultsArray(formattedAllResultsArray);
        } else {    
            let filteredAllResultsArray: FormattedAccumulatedPointData[][] = [];
    
            if (formattedAllResultsArray) {
                for (let i = 0; i < formattedAllResultsArray.length; i++) {
                    const pointDataArray: FormattedAccumulatedPointData[] = formattedAllResultsArray[i];
                    
                    if (pointDataArray && pointDataArray.length > 0) {
                        let innerArray = [];
                        for (let j = 0; j < pointDataArray.length; j++) {
                            const pointData: FormattedAccumulatedPointData = pointDataArray[j];
                            if (pointData.memberId) innerArray.push(pointData);
                        }
                        if (innerArray.length > 0) filteredAllResultsArray.push(innerArray);
                    }
                }
            }
    
            setFilteredFormattedAllResultsArray(filteredAllResultsArray);
        }
        setIsLoading(false);
    }

    async function getOrganizationClassResults(currentYear?: string) {
        try {
            setIsLoading(true);
            const queryResult = await getOrganizationClassById(match.params.id);
            if (queryResult.isSuccess) {
                const organizationClass: OrganizationClass = queryResult.result;
                setOrganizationClass(organizationClass);

                const formattedData: FormattedPointData = await getYearlyPointDataByOrganizationClassId(organizationClass, organizationClass.organizationId, currentYear || selectedYear);
                setFormattedAllResultsArray(formattedData.yearlyData);
                setFilteredFormattedAllResultsArray(formattedData.yearlyData);
                
                setIsLoading(false);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
                setIsLoading(false);
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOrganizationClassResults();
    }, [user, match.params.id]);


    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Organization Points" />
                {isLoading ?
                    <Spinner />
                    :   
                    <>
                        {error && <ErrorAlert error={error} width="12" />}
                        {organizationClass ?
                            <IonRow>
                                <IonCol>
                                    <IonCard mode="md" className="ion-padding bg-white">
                                        <IonCardTitle>{organizationClass.name ? organizationClass.name + " - " : ""}Yearly Points</IonCardTitle>
                                        {/* <IonCardSubtitle className="ion-text-wrap ion-text-right">
                                            {isDownloading ?
                                                <Spinner />
                                                :
                                                <p className="link" onClick={() => handlePrintYearlyPoints()}>
                                                    Print Report
                                                </p>
                                            }
                                        </IonCardSubtitle> */}

                                        <IonCardContent>
                                            
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                                    <SelectYear selectedValue={selectedYear} years={yearChoices} onSelect={(year: string) => handleSelectedYear(year)} />
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                                    <SelectMembership selectedValue={"All Riders"} memberships={["All Riders", "Members Only"]} onSelect={(selected: string) => handleSelectedMembership(selected)} />
                                                </IonCol>
                                            </IonRow>
                                            {(filteredFormattedAllResultsArray && filteredFormattedAllResultsArray.length > 0) ?
                                                <>
                                                    {filteredFormattedAllResultsArray.map((dataArray: FormattedAccumulatedPointData[], index: number) => (
                                                        <>
                                                            {(dataArray && dataArray.length > 0) ? 
                                                                <>
                                                                    <h1>{dataArray[0].eventClassNumber ? dataArray[0].eventClassNumber + " - " : (dataArray[0].eventDivisionNumber ? dataArray[0].eventDivisionNumber + " - " : "")} {dataArray[0].eventClassName || dataArray[0].eventDivisionName}</h1>
                                                                    <Table responsive hover key={index} className="py-3">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    <p className="description ion-text-wrap font-weight-bold">{(organizationClass.organizationId === constants.RHSC_ORGANIZATION.id) ? "Back Number" : ""}</p>
                                                                                </th>
                                                                                <th>
                                                                                    <p className="description ion-text-wrap font-weight-bold">Horse Name</p>
                                                                                </th>
                                                                                <th>
                                                                                    <p className="description ion-text-wrap font-weight-bold">Rider Name</p>
                                                                                </th>
                                                                                {(organizationClass.organizationId === constants.RHSC_ORGANIZATION.id) && (
                                                                                    <>
                                                                                        <th>
                                                                                            <p className="description ion-text-wrap font-weight-bold">Membership Id</p>
                                                                                        </th>
                                                                                        <th>
                                                                                            <p className="description ion-text-wrap font-weight-bold">Shows Attended</p>
                                                                                        </th>
                                                                                        <th>
                                                                                            <p className="description ion-text-wrap font-weight-bold">Meetings Attended</p>
                                                                                        </th>
                                                                                        <th>
                                                                                            <p className="description ion-text-wrap font-weight-bold">Volunteer Hours</p>
                                                                                        </th>
                                                                                    </>
                                                                                )}
                                                                                <th>
                                                                                    <p className="description ion-text-wrap font-weight-bold">Total Points</p>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {dataArray.map((data: FormattedAccumulatedPointData, i: number) => (
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        <p className="description ion-text-wrap">{(organizationClass.organizationId === constants.RHSC_ORGANIZATION.id) ? data.backNumber : i+1}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="description ion-text-wrap">{data.horseName}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="description ion-text-wrap">{data.riderName}</p>
                                                                                    </td>
                                                                                    {(organizationClass.organizationId === constants.RHSC_ORGANIZATION.id) && (
                                                                                        <>
                                                                                            <td>
                                                                                                <p className="description ion-text-wrap">{data.memberId}</p>
                                                                                            </td>
                                                                                            <td>
                                                                                                <p className="description ion-text-wrap">{data.showsAttended}</p>
                                                                                            </td>
                                                                                            <td>
                                                                                                <p className="description ion-text-wrap">{data.meetingsAttended}</p>
                                                                                            </td>
                                                                                            <td>
                                                                                                <p className="description ion-text-wrap">{data.volunteerHours}</p>
                                                                                            </td>
                                                                                        </>
                                                                                    )}
                                                                                    <td>
                                                                                        <p className="description ion-text-wrap">{data.pointsEarned}</p>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </>
                                                                :
                                                                <p>No data found</p>
                                                            }
                                                        </>
                                                    ))}
                                                </>
                                                :
                                                <p>No results found.</p>
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            :
                            <p>No organization class found</p>
                        }
                    </>
                }

            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationViewClassPointsPage;