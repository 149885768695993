import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonLabel,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event, EventDivision} from "../../../../../models";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import EventDivisionForm from "../../../../../components/EventDivision/EventDivisionForm";
import EventDivisionAffiliationForm from "../../../../../components/EventDivision/EventDivisionAffiliationForm";
import { getEventDivisionById } from "../../../../../utilities/eventDivision/EventDivision";
import EventDivisionClassList from "../../../../../components/EventDivision/EventDivisionClassList";

interface EventPageProps extends RouteComponentProps<{
    id: string;
    divisionId: string;
}> {}

const EventDivisionEditPage: React.FC<EventPageProps> = ({match}) => {

    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [currentEventDivision, setCurrentEventDivision] = useState<EventDivision | null | undefined>();
    const [activeTab, setActiveTab] = useState("details");
    const [error, setError] = useState("");

    async function getEvent() {
        const queryResult = await getEventById(match.params.id);
        if (queryResult.isSuccess) {
            setCurrentEvent(queryResult.result);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getEventDivision() {
        const queryResult = await getEventDivisionById(match.params.divisionId);
        if (queryResult.isSuccess) {
            setCurrentEventDivision(queryResult.result);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    useEffect(() => {
        if (match.params.id) getEvent();
        if (match.params.divisionId) getEventDivision();
    }, [match]);

    const handleSubmit = () => {
        
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent ? currentEvent.name : "Event Settings"} />
                <IonRow>
                    <IonCol>
                    <IonSegment color="primary" mode="md" value={activeTab} scrollable={true} onIonChange={e => {setActiveTab(e.detail.value || "details");}}>
                        <IonSegmentButton value="details">
                            <IonLabel>Details</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="classes">
                            <IonLabel>Classes</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="affiliations">
                            <IonLabel>Affiliations</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                    </IonCol>
                </IonRow>
                {error && <ErrorAlert width="12" error={error} />}
                {activeTab === "details" && (
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <IonCard color="white" className="ion-padding">
                                <IonCardTitle>
                                    <IonRow>
                                        <IonCol>
                                            <IonText>
                                                <h3>Division</h3>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonCardTitle>
                                <IonCardContent>
                                    {currentEvent?.organizationId && currentEventDivision && (
                                        <EventDivisionForm organizationId={currentEvent.organizationId} eventDivision={currentEventDivision} onSubmit={() => handleSubmit()} />
                                    )}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                )}
                {activeTab === "classes" && (
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <IonCard color="white" className="ion-padding">
                                <IonCardTitle>
                                    <IonRow>
                                        <IonCol>
                                            <IonText>
                                                <h3>{currentEventDivision?.name} Classes</h3>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonCardTitle>
                                <IonCardContent>
                                    <EventDivisionClassList eventDivision={currentEventDivision} />
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                )}
                {activeTab === "affiliations" && (
                    <>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                <IonCard color="white" className="ion-padding">
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol>
                                                <IonText>
                                                    <h3>{currentEventDivision ? currentEventDivision.name + " " : ""}Affiliation</h3>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        {currentEvent?.organizationId && (
                                            <EventDivisionAffiliationForm eventDivision={currentEventDivision} onSubmit={handleSubmit} />
                                        )}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                <IonCard color="white" className="ion-padding">
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol>
                                                <IonText>
                                                    <h3>Organization Division</h3>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <p>The current "Organization Division" attached to the Event Division is:</p>
                                        <p>Name: {currentEventDivision?.organizationDivision?.name || "Not found."}</p>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventDivisionEditPage;