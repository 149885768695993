import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Contact } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import { createContact, updateContact } from "../../utilities/contact/Contact";
import { formatTwilioNumber, isValidPhoneNumber } from "../../utilities/contact/FormatPhoneNumber";
import PhoneNumberTypeSelect from "./PhoneNumberTypeSelect";
import { CreateContactInput, UpdateContactInput } from "../../API";
import { FormGroup, Input } from "reactstrap";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    contact?: Contact
    onSubmit: Function
}

const QuickAddContactForm: React.FC<_Props> = ({contact, onSubmit}) => {
    const user = useContext(PersonContext);

    const [contactType, setContactType] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberType, setPhoneNumberType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (contact) {
            if (contact.name) setName(contact.name);
            if (contact.personalEmail) setEmail(contact.personalEmail);
            if (contact.cell) {
                setPhoneNumberType("cell");
                setPhoneNumber(contact.cell);
            } else if (contact.home) {
                setPhoneNumberType("home");
                setPhoneNumber(contact.home);
            }
        }
    }, [contact]);

    const handlePhoneNumberTypeSelectInput = (phoneNumberTypeInput: string) => {
        setPhoneNumberType(phoneNumberTypeInput);
    }

    const verifyForm = () => {
        try {
            if (phoneNumber) {
                const isValidNumber = isValidPhoneNumber(phoneNumber);
                if (!isValidNumber) {
                    setError("Error: please check that the phone number is valid.");
                    return false;
                }
            } 
            if (!phoneNumber) {
                setError("Error: please include a phone number.");
                return false;
            }
            if (!email) {
                setError("Error: please include an email.");
                return false;
            }
            return true;
        } catch (error: any) {
            setError(error);
            return false;
        }
    }

    const handleCreateContact = async () => {
        try {
            const input: CreateContactInput = {
                name: name,
                cell: phoneNumberType === "cell" ? formatTwilioNumber(phoneNumber) : undefined,
                home: phoneNumberType === "home" ? formatTwilioNumber(phoneNumber) : undefined,
                work: phoneNumberType === "work" ? formatTwilioNumber(phoneNumber) : undefined,
                personalEmail: email.toLowerCase(),
                createdBy: user.id
            };
            const createResult = await createContact(input);
            if (createResult.isSuccess) {
                onSubmit(createResult.result, "create", contactType);
            } else {
                setError(createResult.message);
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleEditContact = async () => {
        try {
            if (contact) {
                const input: UpdateContactInput = {
                    id: contact.id,
                    name: name,
                    cell: phoneNumberType === "cell" ? formatTwilioNumber(phoneNumber) : undefined,
                    home: phoneNumberType === "home" ? formatTwilioNumber(phoneNumber) : undefined,
                    work: phoneNumberType === "work" ? formatTwilioNumber(phoneNumber) : undefined,
                    personalEmail: email.toLowerCase()
                };
                const updateResult = await updateContact(input);
                if (updateResult.isSuccess) {
                    onSubmit(updateResult.result, "update", contactType);
                } else {
                    setError(updateResult.message);
                }
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) {
            setError("");
            await handleCreateContact();
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol size="12">
                        <FormGroup>
                            <IonLabel>Who is the contact?</IonLabel>
                            <Input 
                                name="type" 
                                type="select" 
                                value={contactType} 
                                onChange={e => {
                                    setContactType(e.target.value);
                                }}
                            >
                                <option value="other">-</option>
                                <option value="owner">Owner</option>
                                <option value="rider1">Rider 1</option>
                                <option value="rider2">Rider 2</option>
                                <option value="trainer">Trainer</option>
                                <option value="other">Other</option>
                            </Input>
                        </FormGroup>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <IonItem color="white">
                            <IonLabel position="stacked">Primary Contact Name <RequiredInputIndicator /></IonLabel>
                            <IonInput 
                                type="text"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    setName(e.detail.value!);
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <IonItem color="white">
                            <IonLabel position="stacked">Primary Contact Email <RequiredInputIndicator /></IonLabel>
                            <IonInput 
                                type="email"
                                value={email}
                                aria-required={true}
                                onIonChange={e => {
                                    setEmail(e.detail.value!);
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <IonItem color="white">
                            <IonLabel position="stacked">Primary Contact Phone Number <RequiredInputIndicator /></IonLabel>
                            <IonInput 
                                type="tel"
                                value={phoneNumber}
                                aria-required={true}
                                onIonChange={e => {
                                    setPhoneNumber(e.detail.value!);
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <PhoneNumberTypeSelect selectedType={phoneNumberType} isRequired={true} onChange={handlePhoneNumberTypeSelectInput} />
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol className="ion-text-center" sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={contact ? handleEditContact : handleSubmit}
                            >
                                {contact ? "Edit Contact" : "Create Contact"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default QuickAddContactForm;