import { Event, EventClass, EventDivision, EventJudge, Rider } from "../../models";
import { formatStartEndDatesStrings } from "../dates/FormatDates";
import { getEventJudgesByEventId } from "../eventJudge/EventJudge";
import { getEventClassesByEventId } from "../eventClass/EventClass";
import mainLogo from "../../assets/img/brand/MainLogo.png";
import { getFormattedEntryClasses } from "../eventClass/FormattedEventClasses";
import { formattedOptionGroup } from "../../components/EventClass/EventClassCheckList";
import { getEventResultsByEventClassId, getEventResultsByEventDivisionId } from "../eventResult/EventResult";
import { EventResult, Horse } from "../../API";
import { getRiderAmateurStatus } from "../rider/CalculateRiderAmateurStatus";
import { getHeightAcronym } from "../horse/Height";
import { getHorseById } from "../horse/Horse";
import { getRiderById } from "../rider/Rider";
import { getEventDivisionById } from "../eventDivision/EventDivision";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export interface VHSAMembershipApplicationObject {
    name: string
    paymentMethod: string
}

async function getJudgesString(event: Event): Promise<string> {
    let result = "";
    const queryResult = await getEventJudgesByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventJudges = queryResult.result;
        if (eventJudges && eventJudges.length > 0) {
            for (let i = 0; i < eventJudges.length; i++) {
                const currentJudge: EventJudge = eventJudges[i];
                result = result + currentJudge.name;
                if (i !== (eventJudges.length-1)) result = result + ", ";
            }
        }
    }

    return result;
}

function getHorseHeightString(horse?: Horse): string {
    return horse?.height ? (getHeightAcronym(parseFloat(horse?.height)).toString()) : ""
}

export async function generateVHSAAssociateResultsReport(event: Event, comments?: string, judgeName?: string, judgeNumber?: string, secretaryName?: string, phoneNumber?: string, memberships?: (VHSAMembershipApplicationObject[] | null), divisionsCanceled?: (string[] | null), didHoldVHSAMedalClasses?: Boolean) {
    // initialize jsPDF
    const doc = new jsPDF();

    // Create a Results Cover Page
    doc.addImage(mainLogo, "PNG", 14, 10, 30, 30);
    doc.setFontSize(14);
    doc.setFont("serif");
    doc.text(`RingSide Pro`, 50, 15);
    doc.setFontSize(12);
    doc.text(`www.ringsidepro.com                   (757) 812-0385`, 50, 25);
    doc.text(`hello@ringsidepro.com`, 50, 30);
    doc.setFontSize(14);
    doc.text(`Results Cover Page`, 75, 40);

    // Break with a line
    doc.line(0, 45, 1000, 45);

    // page title. and margin-top + margin-left
    doc.setFontSize(12);
    const headerTableColumns: string[] = ["", ""];
    const headerTableRows = [];
    headerTableRows.push(["Event Name", event.name]);
    headerTableRows.push(["Event Date(s)", formatStartEndDatesStrings(event.startDate, event.endDate)]);
    headerTableRows.push(["Judge Name", judgeName || await getJudgesString(event)]);
    headerTableRows.push(["Judge Membership Number", judgeNumber || ""]);
    headerTableRows.push(["Show Secretary", secretaryName || ""]);
    headerTableRows.push(["Phone Number", phoneNumber || ""]);
    // startY is basically margin-top
    doc.autoTable(headerTableColumns, headerTableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 50
    });

    // Break with a line
    doc.line(0, 110, 1000, 110);

    if (memberships && memberships.length) {
        doc.text(`Membership/Horse Recording Applications enclosed?: ____Yes______`, 14, 115);
    } else {
        doc.text(`Membership/Horse Recording Applications enclosed?: _____________`, 14, 115);
    }

    doc.setFontSize(14);
    doc.text(`Membership/Horse Recording Applications Enclosed`, 50, 125);
    doc.text(`List Name/Horse`, 75, 130);

    doc.setFontSize(12);
    if (memberships && memberships.length) {
        let yIndex = 135;
        for (let i = 0; i < memberships.length; i++) {
            const currentItem = memberships[i];
            const name = currentItem.name;
            const paymentMethod = currentItem.paymentMethod;
            doc.text(`Name: ${name}`, 14, yIndex);
            doc.text(`Check # or Cash: ${paymentMethod}`, 90, yIndex);
            yIndex = yIndex + 5;
        }
    } else {
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 135);
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 140);
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 145);
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 150);
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 155);
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 160);
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 165);
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 170);
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 175);
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 180);
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 185);
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 190);
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 195);
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 200);
        doc.text(`Name: _____________________________________________________ Check # or Cash: ____________`, 14, 205);
    }

    doc.line(0, 210, 1000, 210);

    if (divisionsCanceled && divisionsCanceled.length) {
        var splitDivisions = doc.splitTextToSize(divisionsCanceled, 180);
        splitDivisions = ["List any Divisions Canceled: "].concat(splitDivisions);
        doc.text(splitDivisions, 14, 215);
    } else {
        doc.text(`List any Divisions Canceled: ______________________________________________________________`, 14, 215);
        doc.text(`______________________________________________________________________________________`, 14, 225);
        doc.text(`______________________________________________________________________________________`, 14, 235);
    }
    

    if (didHoldVHSAMedalClasses) {
        doc.text(`If you held VHSA Medal Classes, check here, and include check for VHSA Medal Fees. _____X______`, 14, 250);
    } else {
        doc.text(`If you held VHSA Medal Classes, check here, and include check for VHSA Medal Fees. _____________`, 14, 250);
    }
    

    if (comments) {
        var splitComments = doc.splitTextToSize(comments, 180);
        splitComments = ["Comments: "].concat(splitComments);
        doc.text(splitComments, 14, 265);
    } else {
        doc.text(`Comments: _____________________________________________________________________________`, 14, 265);
        doc.text(`_______________________________________________________________________________________`, 14, 275);
        doc.text(`_______________________________________________________________________________________`, 14, 285);
    }

    doc.addPage();

    // doc.text(`Class Results Report`, 14, 65);
    // doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 70);

    const queryResult = await getEventClassesByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventClasses = queryResult.result as EventClass[];
        const formattedEventClassesResult = await getFormattedEntryClasses(eventClasses);
        if (formattedEventClassesResult && formattedEventClassesResult.isSuccess) {
            const formattedEventClasses = formattedEventClassesResult.result;
            if (formattedEventClasses && formattedEventClasses.length > 0) {
                doc.page=2;
                for (let i = 0; i < formattedEventClasses.length; i++) {
                    let currentYIndex = 25;
                    const currentOptionGroup: formattedOptionGroup = formattedEventClasses[i];
                    let classList: EventClass[] = [];
                    if (currentOptionGroup.division) {
                        const division = currentOptionGroup.division;
                        const eventDivision: EventDivision = division.object;

                        doc.setFontSize(16);
                        doc.text(`Division: ${eventDivision.name}`, 14, currentYIndex, {
                            maxWidth: 100,
                            align: "left"
                        });
                        doc.setFontSize(12);
                        doc.text(`Show Name: ${event.name}`, 200, currentYIndex, {
                            maxWidth: 100,
                            align: "right"
                        });
                        currentYIndex = currentYIndex + 10;
                        doc.text(`Show Date(s): ${formatStartEndDatesStrings(event.startDate, event.endDate)}`, 200, currentYIndex, {
                            maxWidth: 100,
                            align: "right"
                        });

                        doc.setFontSize(12);
                        if (eventDivision.isCombined) {
                            doc.text(`Was this division combined with another?: Yes`, 14, currentYIndex);
                            currentYIndex = currentYIndex + 5;
                            if (eventDivision.combinedEventDivision) {
                                doc.text(`If yes, specify category: ${eventDivision.combinedEventDivision?.name || ""}`, 14, currentYIndex);
                            } else if (eventDivision.combinedEventDivisionId) {
                                const combinedEventDivisionIdQueryResult = await getEventDivisionById(eventDivision.combinedEventDivisionId);
                                if (combinedEventDivisionIdQueryResult.isSuccess) {
                                    const combinedEventDivision: EventDivision = combinedEventDivisionIdQueryResult.result;
                                    doc.text(`If yes, specify category: ${combinedEventDivision?.name || ""}`, 14, currentYIndex);
                                }
                            } else {
                                doc.text(`If yes, specify category: _____________________________________`, 14, currentYIndex);
                            }
                            currentYIndex = currentYIndex + 10;
                        } else {
                            doc.text(`Was this division combined with another?: __________________`, 14, currentYIndex);
                            currentYIndex = currentYIndex + 5;
                            doc.text(`If yes, specify category: _____________________________________`, 14, currentYIndex);
                            currentYIndex = currentYIndex + 10;
                        }

                        let champion = "";
                        let reserve = "";
                        const divisionResultsQueryResult = await getEventResultsByEventDivisionId(eventDivision.id);
                        if (divisionResultsQueryResult.isSuccess) {
                            const divisionResults: EventResult[] = divisionResultsQueryResult.result;
                        
                            const championResult = divisionResults.find(result => result.place === 1);
                            if (championResult) {
                                champion = championResult.entry.horseName || "";
                            }
                            const reserveResult = divisionResults.find(result => result.place === 2);
                            if (reserveResult) {
                                reserve = reserveResult.entry.horseName || "";
                            }
                        }

                        doc.setFontSize(12);
                        const championTableColumns: string[] = ["", ""];
                        const championTableRows = [];
                        championTableRows.push(["Champion", champion]);
                        championTableRows.push(["Reserve", reserve]);
                        doc.autoTable(championTableColumns, championTableRows, { 
                            theme: "grid",
                            headStyles: {fillColor: "#73a4d3"},
                            startY: currentYIndex
                        });
                        currentYIndex = doc.lastAutoTable.finalY + 10;

                        const eventClasses = currentOptionGroup.classes;
                        if (eventClasses && eventClasses.length > 0) {
                            for (let index = 0; index < eventClasses.length; index++) {
                                const currentEventClassOption = eventClasses[index];
                                const currentEventClass: EventClass = currentEventClassOption.object;
                                classList.push(currentEventClass);
                            }
                        }
                        
                    } else {
                        const currentClass = currentOptionGroup.class;
                        if (currentClass) {
                            const eventClass: EventClass = currentClass.object;
                            doc.setFontSize(16);
                            doc.text(`Class: #${eventClass.number} ${eventClass.name}`, 14, currentYIndex, {
                                maxWidth: 100,
                                align: "left"
                            });
                            doc.setFontSize(12);
                            doc.text(`Show Name: ${event.name}`, 200, currentYIndex, {
                                maxWidth: 100,
                                align: "right"
                            });
                            currentYIndex = currentYIndex + 10;
                            doc.text(`Show Date(s): ${formatStartEndDatesStrings(event.startDate, event.endDate)}`, 200, currentYIndex, {
                                maxWidth: 100,
                                align: "right"
                            });
                            doc.setFontSize(12);
                            doc.text(`Number of Entries: ${eventClass.numberEntriesThatCompeted || ""}`, 14, currentYIndex);
                            currentYIndex = currentYIndex + 10;
                            classList.push(eventClass);
                        }
                    }

                    // define the columns we want and their titles
                    const tableColumn = ["Place", "Entry #", "Horse", "Size", "Owner", "Rider", "Adult/Jr Age", "Note"];

                    if (classList && classList.length > 0) {
                        for (let j = 0; j < classList.length; j++) {
                            const currentEventClass: EventClass = classList[j];

                            // define an empty array of rows
                            const tableRows: string[][] = [];

                            if (classList.length > 1) {
                                if (currentYIndex > 220) {
                                    doc.setFontSize(10);
                                    doc.text(155,285, 'page ' + doc.page); //print number bottom right
                                    doc.addPage();
                                    doc.page ++;
                                    currentYIndex = 25;
                                }
                                doc.setFontSize(14);
                                doc.text(`Class: #${currentEventClass.number} ${currentEventClass.name}`, 14, currentYIndex);
                                currentYIndex = currentYIndex + 5;
                                doc.setFontSize(12);
                                doc.text(`Number of Entries: ${currentEventClass.numberEntriesThatCompeted || ""}`, 14, currentYIndex);
                                currentYIndex = currentYIndex + 5;
                            }

                            doc.setFontSize(12);
                            if (currentEventClass.note) {
                                doc.text(`Notes: ${currentEventClass.note}`, 14, currentYIndex);
                                currentYIndex = currentYIndex + 5;
                            }
                            
                            const dataResult = await getEventResultsByEventClassId(currentEventClass.id);
                            if (dataResult.isSuccess) {
                                const results: EventResult[] = dataResult.result;
                                if (results && results.length > 0) {
                                    for (let k = 0; k < results.length; k++) {
                                        const result: EventResult = results[k];
                                        let place = result.place ? result.place?.toString() : "";
                                        let time = result.time ? result.time?.toString() : "";
                                        let entryNumber = result.entry.number ? result.entry.number.toString() : "";
                                        let horseName = result.entry.horse?.name || result.entry.horseName || "";
                                        let horseHeight = result.entry.horse ? getHorseHeightString(result.entry.horse) : "";
                                        let ownerName = result.entry.owner?.name || result.entry.ownerName || "";
                                        let riderName = result.entry.rider?.name || result.entry.riderName || "";
                                        let riderStatus = (result.entry.rider) ? getRiderAmateurStatus(result.entry.rider as Rider) : "";

                                        if (!horseName || horseName === "" || !horseHeight || horseHeight === "") {
                                            if (result.entry.horseId) {
                                                const horseQueryResult = await getHorseById(result.entry.horseId);
                                                if (horseQueryResult.isSuccess) {
                                                    const horse: Horse = horseQueryResult.result;
                                                    horseName = horse.name;
                                                    horseHeight = getHorseHeightString(horse);
                                                }
                                            }
                                        }

                                        if (result.eventClassEntry?.riderId) {
                                            const riderQueryResult = await getRiderById(result.eventClassEntry?.riderId);
                                            if (riderQueryResult.isSuccess) {
                                                const rider: Horse = riderQueryResult.result;
                                                riderName = rider.name;
                                                riderStatus = rider ? getRiderAmateurStatus(rider as Rider) : "";
                                            }
                                        }

                                        if ((!riderName || riderName === "") || (!riderStatus || riderStatus === "")) {
                                            if (result.entry.riderId) {
                                                const riderQueryResult = await getRiderById(result.entry.riderId);
                                                if (riderQueryResult.isSuccess) {
                                                    const rider: Horse = riderQueryResult.result;
                                                    riderName = rider.name;
                                                    riderStatus = rider ? getRiderAmateurStatus(rider as Rider) : "";
                                                }
                                            }
                                        }

                                        if (place && parseInt(place)) {
                                            const row = [
                                                place,
                                                entryNumber,
                                                horseName,
                                                horseHeight,
                                                ownerName,
                                                riderName,
                                                riderStatus,
                                                time
                                            ];
                                            tableRows.push(row);
                                        }
                                    }

                                    // startY is basically margin-top
                                    doc.autoTable(tableColumn, tableRows, { 
                                        theme: "grid",
                                        headStyles: {fillColor: "#73a4d3"},
                                        startY: currentYIndex
                                    });

                                    currentYIndex = doc.lastAutoTable.finalY + 10;
                                } else {
                                    doc.text(`No results found for this class.`, 14, currentYIndex);
                                    currentYIndex = currentYIndex + 10;
                                }
                            } else {
                                doc.text(`No results found for this class.`, 14, currentYIndex);
                                currentYIndex = currentYIndex + 10;
                            }
                        }
                    }

                    doc.setFontSize(10);
                    doc.text(155,285, 'page ' + doc.page); //print number bottom right
                    doc.addPage();
                    doc.page ++;
                }
            }
        }
    }

    // we define the name of our PDF file.
    doc.save(`VHSA_Associate_Results_Form.pdf`);

    var blobPDF =  new Blob([ doc.output() ], { type : 'application/pdf'});
    return blobPDF;
}