import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { Barn, Horse, Owner } from "../../models";
import { getAllOwners, getOwnersByBarnId } from "../../utilities/owner/Owner";
import Spinner from "../Spinners/Spinner";
import { formatShortAddress } from "../../utilities/address/FormatAddress";
const { v4: uuidv4 } = require('uuid');

interface _Props {
    selectedBarn?: (Barn | null)
    selectedHorse?: (Horse | null)
    onSelect: Function
    isHeightRequired?: boolean
    height?: string
}

interface formattedOption {
    // What react-select is looking for
    value: string // Owner's id
    label: string // Identification Label: OwnerName + HorseName - used for filtering options as a user types
    // What is needed for selection display
    ownerName: string
    ownerNameLabel?: string
    emailLabel?: string
    locationLabel?: string
    owner?: Owner
}

interface formattedGroupOfOptions {
    label: string
    options: formattedOption[]
}

/**
 * This function is passed into the select component to define what should be shown when an option is selected
 * Instead of showing the 3 lines from the option,
 * it will only show the 1 line defined below: horseName
 * @param props 
 * @returns 
 */
const SingleValue = (props: any) => (
    <components.SingleValue {...props}>
        {props.data.ownerName || props.data.label}
    </components.SingleValue>
);

const SelectOrCreateOwner: React.FC<_Props> = ({ selectedBarn, selectedHorse, onSelect, isHeightRequired, height }) => {
    // We group the options into: 1-Currently Selected Barn's owners or 2-All Owners
    const [formattedBarnOwners, setFormattedBarnOwners] = useState<formattedOption[] | null | undefined>();
    const [formattedOwners, setFormattedOwners] = useState<formattedOption[] | null | undefined>();

    // We then put the formatted options into defined Groups
    const [barnOwnersGroup, setBarnOwnersGroup] = useState<formattedGroupOfOptions | null | undefined>();
    const [allOwnersGroup, setAllOwnersGroup] = useState<formattedGroupOfOptions | null | undefined>();
    
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    // Sort list alphabetically
    const sortOwnerOptions = (ownerArray: Owner[]) => {
        return ownerArray.sort((a,b) => a.name.trim().localeCompare(b.name.trim()));
    }

    const removeBarnOwnersFromGroupOfAllOwners = async (barnOwners: formattedOption[]) => {
        if (allOwnersGroup && formattedOwners) {
            const updatedFormattedOwners = formattedOwners.filter((el) => !barnOwners.find((bh => bh.value === el.value)));
            const allGroup: formattedGroupOfOptions = {
                label: "All Owners in RingSide Pro database",
                options: updatedFormattedOwners
            };
            setAllOwnersGroup(allGroup);
        }
    };

    const getBarnOwners = async () => {
        setIsLoading(true);

        // Get current list of owners
        let currentOwnerList: Owner[] = [];
        if (selectedBarn) {
            const queryOwnersByBarn = await getOwnersByBarnId(selectedBarn.id);
            if (queryOwnersByBarn.isSuccess) {
                currentOwnerList = queryOwnersByBarn.result;
            }
        }

        // Sort and format the list of owners in barn if any
        const sortedOwnerList = sortOwnerOptions(currentOwnerList);
        const currentFormattedOwners = await formatOwners(sortedOwnerList);

        // Set list of formatted Owners in the selectedBarn and create custom label for Owners in Barn
        setFormattedBarnOwners(currentFormattedOwners);
        const barnGroup: formattedGroupOfOptions = {
            label: (selectedBarn?.name || "Barn") + " Owners",
            options: currentFormattedOwners
        };
        setBarnOwnersGroup(barnGroup);

        // Remove the selected barn's owners in barnGroup from the list of all owners in allGroup
        removeBarnOwnersFromGroupOfAllOwners(currentFormattedOwners);

        setIsLoading(false);
    };

    const getOwners = async () => {
        setIsLoading(true);
    
        // Get the current list of owners
        let currentOwnerList: Owner[] = [];
        const queryResult = await getAllOwners();
        if (queryResult.isSuccess) {
            currentOwnerList = queryResult.result;
        }
        
        // Sort and format the list 
        const sortedOwnerList = sortOwnerOptions(currentOwnerList);
        let currentFormattedOwners = await formatOwners(sortedOwnerList);
            
        setFormattedOwners(currentFormattedOwners);
    
        // If barn owners are already in formattedBarnOwners list, remove them from the full list and set allGroup
        if (formattedBarnOwners) {
            removeBarnOwnersFromGroupOfAllOwners(formattedBarnOwners);
        } else {
            const allGroup: formattedGroupOfOptions = {
                label: "All Owners in RingSide Pro database",
                options: currentFormattedOwners
            };
            setAllOwnersGroup(allGroup);    
        }
    
        setIsLoading(false);
    };    

    /**
     * Format an Owner object to be the structure needed for react-select
     * @param owner Owner
     * @returns formattedOption
     */
    const formatSingleOwner = (owner: Owner) => {
        const value = owner.id;
        const name = owner.name || "Unknown - No name found";
        const email = owner.contact?.personalEmail || "No email found";
        const location = (owner.address ? formatShortAddress(owner.address) : "No Location found");
        
        let object: formattedOption = {
            value: value,
            label: name + " " + (selectedHorse?.name || "Horse"),
            ownerName: name,
            ownerNameLabel: name,
            emailLabel: email,
            locationLabel: location,
            owner: owner
        };
        return object;
    }

    const formatOwners = async (ownerArray: Owner[]) => {
        setIsLoading(true);
        
        let formattedOwners = [];
        for (var i = 0; i < ownerArray.length; i++) {
            const owner = ownerArray[i];
            let object: formattedOption = formatSingleOwner(owner);
            formattedOwners.push(object);
        }
        return formattedOwners;
    };

    // Refresh list of owners whenever component is reloaded
    useEffect(() => {
        getOwners();
    }, []);

    // If a selected horse is present, make the currenValue the associated formattedOption. 
    // Otherwise clear the currentValue, and display the placeholder.
    useEffect(() => {
        if (selectedHorse && selectedHorse.owner) {
            const formattedOwner = formatSingleOwner(selectedHorse.owner)
            setCurrentValue(formattedOwner);
        } else {
            setCurrentValue(undefined);
        }

    }, [selectedHorse])

    // Get all Owners in Barn if selectedBarn value changes
    useEffect(() => {
        getBarnOwners();
    }, [selectedBarn]);

    const handleOnChange = (event?: any) => {
        if (event && event.owner) {
            setCurrentValue(event);
            onSelect(event.owner);
        } else if (event && event.value) {

            // When a new option is selected from the "Create" option, it will only have a value
            if (event.ownerName) {
                // This option was created by the user and then selected again
                setCurrentValue(event);
                onSelect(null, event.ownerName);

            } else {
                // Format the new option
                const newFormattedOption: formattedOption = {
                    value: uuidv4(), // Generate a new ID for the created Owner
                    label: event.value + " " + (selectedHorse?.name || ""),
                    ownerName: event.value, // Set the entered name as ownerName
                    ownerNameLabel: event.value,
                }

                if (selectedBarn) {
                    // When Barn has already been selected, add the new Owner to that barn's list
                    const updatedFormattedBarnOwners = formattedBarnOwners ? formattedBarnOwners.concat([newFormattedOption]) : [newFormattedOption];
                    const sortedFormattedBarnOwners = updatedFormattedBarnOwners.sort((a,b) => a.ownerName.trim().localeCompare(b.ownerName.trim()));
                    setFormattedBarnOwners(sortedFormattedBarnOwners);
                    const barnGroup: formattedGroupOfOptions = {
                        label: (selectedBarn?.name || "Barn") + " Owners",
                        options: sortedFormattedBarnOwners
                    };
                    setBarnOwnersGroup(barnGroup);
                } else {
                    // When no barn has been selected, add the owner to the full list of all owners
                    const updatedFormattedOwners = formattedOwners ? formattedOwners.concat([newFormattedOption]) : [newFormattedOption];
                    setFormattedOwners(updatedFormattedOwners);
                    const allGroup: formattedGroupOfOptions = {
                        label: "All Owners in RingSide Pro database",
                        options: updatedFormattedOwners
                    };
                    setAllOwnersGroup(allGroup);
                }

                // Set new owner as currently selected option
                setCurrentValue(newFormattedOption);
                onSelect(null, event.value);
            }
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {(barnOwnersGroup && allOwnersGroup) && (
                        <CreatableSelect
                            id="select-or-create-owner"
                            inputId="select-or-create-owner-input"
                            placeholder="Type the owner's name"
                            styles={{
                                control: baseStyles =>(isHeightRequired ? {...baseStyles, height} : {...baseStyles}),
                                menu: provided => ({ ...provided, zIndex: 9999 }),
                            }}
                            defaultValue={currentValue}
                            value={currentValue}
                            menuPortalTarget={document.body}
                            isClearable
                            options={[
                                barnOwnersGroup,
                                allOwnersGroup
                            ]}
                            formatOptionLabel={(option: any) => (
                                option.ownerName ?
                                (
                                    <div className="p-0">
                                        <div className="font-weight-bold p-0">{option.ownerName}</div>
                                        <div className="display-5 text-medium">Location: {option.locationLabel}</div> 
                                        <div className="display-5 text-medium">Email: {option.emailLabel}</div>
                                    </div>
                                )
                                : 
                                (
                                    <div className="p-0">
                                        <div className="font-weight-bold text-default">Create New Owner: {option.value}</div>
                                        <div className="display-5 text-medium">For Horse: {selectedHorse ? selectedHorse.name : "None Selected"}</div> 
                                        <div className="display-5 text-medium">In Barn: {selectedBarn ? selectedBarn.name : "None Selected"}</div>
                                    </div>
                                )
                            )}
                            components={{ SingleValue }}
                            onChange={(event: any) => handleOnChange(event)}
                            createOptionPosition={"last"}
                        />
                    )}
                </>
            }
        </>
    )
}

export default SelectOrCreateOwner;
