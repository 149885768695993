import moment from "moment";
import { Event, EventClassEntry, EventEntry } from "../../models";
import { addFooters } from "./ReportFooter";
import { getAcceptedEventEntriesByEventId } from "../eventEntry/EventEntry";
import { getEventClassEntriesByEventIdEntryId } from "../eventClassEntry/EventClassEntry";
import { sortEventEntriesByBarn } from "../eventEntry/SortEventEntry";
import constants from "../../constant/constant";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

// List of jumping classes that RHSC restricts to only 8 or fewer classes.
const RHSCJumpingClasses = [
    "299", 
    "300", 
    "301",
    "302",
    "303",
    "304",
    "305",
    "306",
    "307",
    "308",
    "309",
    "322",
    "323",
    "324",
    "326",
    "327",
    "328",
    "331",
    "332",
    "334",
    "335",
    "336"
];

// RHSC special classes that can not cross enter with any of the above classes
const RHSCSpecialClasses = ["318", "319", "320"];

// MOHSS leadline and short stirrup classes
const MOHSSLeadLineAndShortStirrupClasses = [
    "1",
    "2", 
    "3", 
    "4", 
    "5"
];

// MOHSS beginner 10 and under classes
const MOHSSBegginer10AndUnderClasses = [
    "6", 
    "7",
    "8"
];

// MOHSS beginner 11 and over classes
const MOHSSBegginer11AndOverClasses = [
    "9", 
    "10",
    "11"
];

// MOHSS long stirrup adult eq classes
const MOHSSLongStirrupAdultEqClasses = [
    "12", 
    "13",
    "14"
];

// MOHSS crossrail hunter classes
const MOHSSCrossrailHunterClasses = [
    "16",
    "17"
];

// MOHSS modified hunter classes
const MOHSSModifiedHunterClasses = [
    "18", 
    "19",
    "20"
];

// MOHSS Junior Eq 13 And Under classes
const MOHSSJuniorEq13AndUnderClasses = [
    "21", 
    "22",
    "23"
];

// MOHSS Junior Eq 14 And Over classes
const MOHSSJuniorEq14AndOverClasses = [
    "24", 
    "25",
    "26"
];

// MOHSS Open Eq classes
const MOHSSOpenEqClasses = [
    "27", 
    "28",
    "29"
];

// MOHSS Medal classes
const MOHSSMedalClasses = [
    "30"
];

// MOHSS Pony Pleasure classes
const MOHSSPonyPleasureClasses = [
    "31",
    "32",
    "33"
];

// MOHSS Horse Pleasure classes
const MOHSSHorsePleasureClasses = [
    "34",
    "35",
    "36"
];

// MOHSS Trail classes
const MOHSSTrailClasses = [
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
];

// MOHSS Leadline and Short Stirrup Trail classes
const MOHSSLeadlineAndShortStirrupTrailClasses = [
    "38",
    "39",
];

const checkRHSCCrossEntryRules = (current: EventEntry, eventClassEntries: EventClassEntry[]) => {
    const allClassNumbers: string[] = [];
    const jumpingClassNumbersEntryIsIn: string[] = [];
    const specialClassNumbersEntryIsIn: string[] = [];
    
    eventClassEntries.forEach(eventClassEntry => {
        if (eventClassEntry.eventClass?.number) {
            const classNumber = eventClassEntry.eventClass?.number.toString();
            allClassNumbers.push(classNumber);
            if (RHSCJumpingClasses.includes(classNumber)) {
                jumpingClassNumbersEntryIsIn.push(classNumber);
            }
            if (RHSCSpecialClasses.includes(classNumber)) {
                specialClassNumbersEntryIsIn.push(classNumber);
            }
        }
    });

    const newRow = [
        current.number || "",
        current.horseName || current.horse?.name || "",
        current.riderName || current.rider?.name || "",
        current.trainerName || current.trainer?.name || "",
        allClassNumbers.length || "0",
        allClassNumbers.toString(),
        (jumpingClassNumbersEntryIsIn.length > 8 || (specialClassNumbersEntryIsIn.length > 1 && jumpingClassNumbersEntryIsIn.length > 1)) ? "Problem Detected" : ""
    ];
    return newRow;
}

const checkForCommonClassNumbersInArrays = (arr1: string[], arr2: string[]) => {
    return arr1.some(item => arr2.includes(item));
}

const doMOHSSCheck = (allClassNumbers: string[]) => {
    const hasLeadlineAndShortStirrupClass = checkForCommonClassNumbersInArrays(allClassNumbers, MOHSSLeadLineAndShortStirrupClasses);
    const hasBegginer10AndUnderClass = checkForCommonClassNumbersInArrays(allClassNumbers, MOHSSBegginer10AndUnderClasses);
    const hasMOHSSBegginer11AndOverClass = checkForCommonClassNumbersInArrays(allClassNumbers, MOHSSBegginer11AndOverClasses);
    const hasMOHSSLongStirrupAdultEqClass = checkForCommonClassNumbersInArrays(allClassNumbers, MOHSSLongStirrupAdultEqClasses);
    const hasMOHSSCrossrailHunterClass = checkForCommonClassNumbersInArrays(allClassNumbers, MOHSSCrossrailHunterClasses);
    const hasMOHSSModifiedHunterClass = checkForCommonClassNumbersInArrays(allClassNumbers, MOHSSModifiedHunterClasses);
    const hasMOHSSJuniorEq13AndUnderClass = checkForCommonClassNumbersInArrays(allClassNumbers, MOHSSJuniorEq13AndUnderClasses);
    const hasMOHSSJuniorEq14AndOverClass = checkForCommonClassNumbersInArrays(allClassNumbers, MOHSSJuniorEq14AndOverClasses);
    const hasMOHSSOpenEqClass = checkForCommonClassNumbersInArrays(allClassNumbers, MOHSSOpenEqClasses);
    const hasMOHSSMedalClass = checkForCommonClassNumbersInArrays(allClassNumbers, MOHSSMedalClasses);
    const hasMOHSSPonyPleasureClass = checkForCommonClassNumbersInArrays(allClassNumbers, MOHSSPonyPleasureClasses);
    const hasMOHSSHorsePleasureClass = checkForCommonClassNumbersInArrays(allClassNumbers, MOHSSHorsePleasureClasses);
    const hasMOHSSTrailClass = checkForCommonClassNumbersInArrays(allClassNumbers, MOHSSTrailClasses);
    const hasMOHSSLeadlineAndShortStirrupTrailClass = checkForCommonClassNumbersInArrays(allClassNumbers, MOHSSLeadlineAndShortStirrupTrailClasses);

    if (hasLeadlineAndShortStirrupClass && (
        hasBegginer10AndUnderClass || 
        hasMOHSSBegginer11AndOverClass || 
        hasMOHSSLongStirrupAdultEqClass || 
        hasMOHSSCrossrailHunterClass || 
        hasMOHSSModifiedHunterClass || 
        hasMOHSSJuniorEq13AndUnderClass || 
        hasMOHSSJuniorEq14AndOverClass || 
        hasMOHSSOpenEqClass || 
        hasMOHSSMedalClass ||
        hasMOHSSPonyPleasureClass || 
        hasMOHSSHorsePleasureClass || 
        checkForCommonClassNumbersInArrays(allClassNumbers, ["40", "41", "42", "43"])
    )) {return true};

    if (hasBegginer10AndUnderClass && (
        hasLeadlineAndShortStirrupClass || 
        hasMOHSSBegginer11AndOverClass || 
        hasMOHSSLongStirrupAdultEqClass || 
        hasMOHSSJuniorEq13AndUnderClass || 
        hasMOHSSJuniorEq14AndOverClass ||
        checkForCommonClassNumbersInArrays(allClassNumbers, ["38", "39", "41", "42", "43"])
    )) {return true};

    if (hasMOHSSBegginer11AndOverClass && (
        hasLeadlineAndShortStirrupClass || 
        hasBegginer10AndUnderClass || 
        hasMOHSSLongStirrupAdultEqClass || 
        hasMOHSSJuniorEq13AndUnderClass || 
        hasMOHSSJuniorEq14AndOverClass || 
        checkForCommonClassNumbersInArrays(allClassNumbers, ["38", "39", "40", "42", "43"])
    )) {return true};

    if (hasMOHSSLongStirrupAdultEqClass && (
        hasLeadlineAndShortStirrupClass || 
        hasBegginer10AndUnderClass || 
        hasMOHSSBegginer11AndOverClass || 
        hasMOHSSJuniorEq13AndUnderClass || 
        hasMOHSSJuniorEq14AndOverClass || 
        hasMOHSSTrailClass
    )) {return true};

    if (hasMOHSSCrossrailHunterClass && (
        hasLeadlineAndShortStirrupClass ||
        checkForCommonClassNumbersInArrays(allClassNumbers, ["38, 39"])
    )) {return true};

    if (hasMOHSSModifiedHunterClass && (
        hasLeadlineAndShortStirrupClass || 
        checkForCommonClassNumbersInArrays(allClassNumbers, ["38, 39"])
    )) {return true};

    if (hasMOHSSJuniorEq13AndUnderClass && (
        hasLeadlineAndShortStirrupClass || 
        hasBegginer10AndUnderClass || 
        hasMOHSSBegginer11AndOverClass || 
        hasMOHSSLongStirrupAdultEqClass || 
        hasMOHSSJuniorEq14AndOverClass ||
        checkForCommonClassNumbersInArrays(allClassNumbers, ["38", "39", "40", "41", "43"])
    )) {
        return true
    };

    if (hasMOHSSJuniorEq14AndOverClass && (
        hasLeadlineAndShortStirrupClass || 
        hasBegginer10AndUnderClass || 
        hasMOHSSBegginer11AndOverClass || 
        hasMOHSSLongStirrupAdultEqClass || 
        hasMOHSSJuniorEq13AndUnderClass ||
        checkForCommonClassNumbersInArrays(allClassNumbers, ["38", "39", "40", "41", "42"])
    )) {return true};

    if (hasMOHSSOpenEqClass && (
        hasLeadlineAndShortStirrupClass || 
        checkForCommonClassNumbersInArrays(allClassNumbers, ["38, 39"])
    )) {return true};

    if (hasMOHSSMedalClass && (
        hasLeadlineAndShortStirrupClass || 
        checkForCommonClassNumbersInArrays(allClassNumbers, ["38, 39"])
    )) {return true};

    if (hasMOHSSPonyPleasureClass && (
        hasLeadlineAndShortStirrupClass || 
        checkForCommonClassNumbersInArrays(allClassNumbers, ["38, 39"])
    )) {
        return true
    };

    if (hasMOHSSHorsePleasureClass && (
        hasLeadlineAndShortStirrupClass || 
        checkForCommonClassNumbersInArrays(allClassNumbers, ["38, 39"])
    )) {return true};

    if (hasMOHSSLeadlineAndShortStirrupTrailClass && (
        hasBegginer10AndUnderClass || 
        hasMOHSSBegginer11AndOverClass || 
        hasMOHSSLongStirrupAdultEqClass || 
        hasMOHSSCrossrailHunterClass || 
        hasMOHSSModifiedHunterClass || 
        hasMOHSSJuniorEq13AndUnderClass || 
        hasMOHSSJuniorEq14AndOverClass || 
        hasMOHSSOpenEqClass || 
        hasMOHSSMedalClass ||
        hasMOHSSPonyPleasureClass || 
        hasMOHSSHorsePleasureClass || 
        checkForCommonClassNumbersInArrays(allClassNumbers, ["40", "41", "42", "43"])
    )) {return true};

    //Beginner Rider (10 & Under) Trail
    if (checkForCommonClassNumbersInArrays(allClassNumbers, ["40"]) && (
        checkForCommonClassNumbersInArrays(allClassNumbers, ["1", "2", "3", "4", "5", "9", "10", "11", "12", "13", "14", "21", "22", "23", "24", "25", "26", "38", "39", "41", "42", "43"])
    )) {return true};

    // Beginner Rider (11-17) Trail
    if (checkForCommonClassNumbersInArrays(allClassNumbers, ["41"]) && (
        checkForCommonClassNumbersInArrays(allClassNumbers, ["1", "2", "3", "4", "5", "6", "7", "8", "12", "13", "14", "21", "22", "23", "24", "25", "26", "38", "39", "40", "42", "43"])
    )) {return true};
    
    // Junior (13 & Under) Trail
    if (checkForCommonClassNumbersInArrays(allClassNumbers, ["42"]) && (
        hasLeadlineAndShortStirrupClass || 
        hasBegginer10AndUnderClass || 
        hasMOHSSBegginer11AndOverClass ||
        hasMOHSSLongStirrupAdultEqClass || 
        hasMOHSSJuniorEq14AndOverClass || 
        checkForCommonClassNumbersInArrays(allClassNumbers, ["38", "39", "40", "41", "43"])
    )) {return true};

    // Junior (14-17) Trail
    if (checkForCommonClassNumbersInArrays(allClassNumbers, ["43"]) && (
        checkForCommonClassNumbersInArrays(allClassNumbers, ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "21", "22", "23", "38", "39",  "40", "41", "42"])
    )) {return true};

    return false;
}

const checkMOHSSCrossEntryRules = (current: EventEntry, eventClassEntries: EventClassEntry[]) => {
    const allClassNumbers: string[] = [];
    let problemFound = false;

    const sortedEventClassEntries = eventClassEntries.sort((a, b) => (a.eventClass?.number || 0) - (b.eventClass?.number || 0));
    
    sortedEventClassEntries.forEach(eventClassEntry => {
        if (eventClassEntry.eventClass?.number) {
            const classNumber = eventClassEntry.eventClass?.number.toString();
            allClassNumbers.push(classNumber);
        }
    });

    problemFound = doMOHSSCheck(allClassNumbers);

    const newRow = [
        current.number || "",
        current.horseName || current.horse?.name || "",
        current.riderName || current.rider?.name || "",
        current.trainerName || current.trainer?.name || "",
        allClassNumbers.length || "0",
        allClassNumbers.toString(),
        problemFound ? "Problem Detected" : ""
    ];

    return newRow;
}

export async function generateCrossEntryPDF(event: Event, setProgressMessage?: Function) {
    // initialize jsPDF
    const doc = new jsPDF("l");

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Cross Entry Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY h:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumns = [
        {header: "Entry #", dataKey: "number"}, 
        {header: "Horse", dataKey: "horse"}, 
        {header: "Rider(s)", dataKey: "rider"},
        {header: "Trainer", dataKey: "trainer"},
        {header: "Total # Classes", dataKey: "total"},
        {header: "Class Numbers", dataKey: "classes"},
        {header: "Problem?", dataKey: "problem"}
    ];

    // define an empty array of rows
    let tableRows: any[] = [];

    const acceptedEntriesResult = await getAcceptedEventEntriesByEventId(event.id);
    if (acceptedEntriesResult.isSuccess) {
        const acceptedEntries: EventEntry[] = acceptedEntriesResult.result;
        const sorted = sortEventEntriesByBarn(acceptedEntries) || acceptedEntries;
        if (sorted && sorted.length > 0) {
            for (let i = 0; i < sorted.length; i++) {
                const current = sorted[i];
                const progressMsg = ((i + 1) / sorted.length * 100).toFixed(1) + "% completed";
                if (setProgressMessage) setProgressMessage(progressMsg);
                const eventClassEntriesResult = await getEventClassEntriesByEventIdEntryId(event.id, current.id);
                if (eventClassEntriesResult.isSuccess) {
                    const eventClassEntries: EventClassEntry[] = eventClassEntriesResult.result;
                    if (eventClassEntries && eventClassEntries.length > 0) {
                        const newTableRow = (event.organizationId === constants.RHSC_ORGANIZATION.id) ? 
                        checkRHSCCrossEntryRules(current, eventClassEntries) 
                        : 
                        (event.organizationId === constants.MOHSS_ORGANIZATION.id ? checkMOHSSCrossEntryRules(current, eventClassEntries) : []);

                        tableRows.push(newTableRow);
                    }
                }
            }
        }
    } else {
        doc.text(`No accepted entries were found.`, 14, 30);
    }
    
    // startY is basically margin-top
    doc.autoTable(tableColumns, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 30 
    });

    addFooters(doc);

    // we define the name of our PDF file.
    doc.save(`Cross_Entry_Report.pdf`);
}