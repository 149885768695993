import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import BasicUserInfo from "../../components/ProfilePage/BasicUserInfo";
import Header from "../../components/Headers/Header";
import PageTitle from "../../components/PageTitle/PageTitle";
import React from "react";
import PageList from "../../components/Lists/PageList";
import { teamMemberProfileOptions } from "../../interfaces/Page";

const ProfilePage: React.FC = () => {

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Profile" />
                <IonRow className="ion-text-center ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="8">
                        <BasicUserInfo />
                    </IonCol>
                </IonRow>
                <IonRow className="ion-text-center ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="8">
                        <PageList includeId={false} listOptions={teamMemberProfileOptions} />
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default ProfilePage;