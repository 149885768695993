import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { UpdateEventEntryInput, UpdateBarnInput, UpdateContactInput, UpdateAddressInput, CreateAddressInput, CreateContactInput, CreateBarnInput } from "../../../../API";
import { EventEntry} from "../../../../models";
import { updateEventEntry } from "../../../../utilities/eventEntry/EventEntry";
import { createBarn, updateBarn } from "../../../../utilities/barn/Barn";
import SuccessBanner from "../../../Banners/SuccessBanner";
import ErrorAlert from "../../../Errors/ErrorAlert";
import Spinner from "../../../Spinners/Spinner";
import { formatTwilioNumber } from "../../../../utilities/contact/FormatPhoneNumber";
import { createContact, updateContact } from "../../../../utilities/contact/Contact";
import { createAddress, updateAddress } from "../../../../utilities/address/Address";

interface _Props {
    entry: EventEntry
}

const EntryInfoBarn: React.FC<_Props> = ({entry}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [barnName, setBarnName] = useState("");
    const [barnLocation, setBarnLocation] = useState("");
    const [barnEmail, setBarnEmail] = useState("");
    const [barnPhone, setBarnPhone] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const getEntryInformation = (entry: EventEntry) => {
        setCurrentEntry(entry);
        setBarnName(entry.barnName || entry?.barn?.name || "");
        setBarnLocation(entry?.barn?.location ? entry?.barn?.location.city + ", " + entry?.barn?.location.provState  : "");
        setBarnEmail(entry.barn?.contact?.workEmail || "");
        setBarnPhone(entry?.barn?.contact?.work || "");
    }

    useEffect(() => {
        if (entry) {
            getEntryInformation(entry);
        }
    }, [entry]);

    const handleEdit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        let contactId;
        if (entry.barn?.contact?.id) {
            const updateContactInput: UpdateContactInput = {
                id: entry.barn.contact.id,
                workEmail: barnEmail,
                work: barnPhone ? formatTwilioNumber(barnPhone) : undefined
            };
            const updateContactResult = await updateContact(updateContactInput);
            if (updateContactResult) {
                contactId = updateContactResult.result.id;
            } else {
                setError("Could not update the contact info.");
                setIsLoading(false);
                return;
            }
        } else if (barnEmail || barnPhone) {
            const createContactInput: CreateContactInput = {
                workEmail: barnEmail || "",
                work: barnPhone ? formatTwilioNumber(barnPhone) : undefined
            };
            const createContactResult = await createContact(createContactInput);
            if (createContactResult) {
                contactId = createContactResult.result.id;
            } else {
                setError("Could not create the contact info.");
                setIsLoading(false);
                return;
            }
        }
        let addressId = "";
        if (entry.barn?.location?.id) {
            const updateAddressInput: UpdateAddressInput = {
                id: entry.barn.location.id,
                name: barnName,
                city: barnLocation.split(",")[0] || "",
                provState: barnLocation.split(",")[1] || ""
            };
            const updateAddressResult = await updateAddress(updateAddressInput);
            if (updateAddressResult) {
                addressId = updateAddressResult.result.id;
            } else {
                setError("Could not update the address info.");
                setIsLoading(false);
                return;
            }
        } else if (barnLocation) {
            const createAddressInput: CreateAddressInput = {
                name: barnName,
                city: barnLocation.split(",")[0] || "",
                provState: barnLocation.split(",")[1] || ""
            };
            const createAddressResult = await createAddress(createAddressInput);
            if (createAddressResult) {
                addressId = createAddressResult.result.id;
            } else {
                setError("Could not create the address info.");
                setIsLoading(false);
                return;
            }
        }
        let barnId = "";
        if (entry.barnId) {
            const input: UpdateBarnInput = {
                id: entry.barnId,
                name: barnName,
                barnLocationId: addressId,
                barnContactId: contactId || ""
            };
            const updateResult = await updateBarn(input);
            if (updateResult.isSuccess) {
                barnId = updateResult.result.id;
            } else {
                setError("Could not update the barn");
                setIsLoading(false);
            }
        } else {
            const input: CreateBarnInput = {
                name: barnName,
                barnLocationId: addressId || "",
                barnContactId: contactId || ""
            };
            const createResult = await createBarn(input);
            if (createResult.isSuccess) {
                barnId = createResult.result.id;
            } else {
                setError("Could not create the barn");
                setIsLoading(false);
            }
        }

        const updateEntryInput: UpdateEventEntryInput = {
            id: entry.id,
            barnName: barnName,
            barnId: barnId,
            // eventEntryBarnId: barnId
        };
        const updateEntryResult = await updateEventEntry(updateEntryInput);
        if (updateEntryResult.isSuccess) {
            const updatedEntry = updateEntryResult.result;
            setCurrentEntry(updatedEntry);
            getEntryInformation(updatedEntry);
            setSuccess("Updated the barn info.");
            setIsEdit(false);
            setIsLoading(false);
        } else {
            setError("Could not update the entry.");
            setIsLoading(false);
            return;
        }
    }

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h3>Barn</h3>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {success && <SuccessBanner width="12" success={success} />}
                        {isEdit ?
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Barn Name</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={barnName}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setBarnName(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Barn Location</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={barnLocation}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setBarnLocation(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Barn Email</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={barnEmail}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setBarnEmail(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Barn Phone</IonLabel>
                                            <IonInput 
                                                type="tel"
                                                value={barnPhone}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setBarnPhone(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Barn Name</IonLabel>
                                            <IonText><p>{barnName}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Barn Location</IonLabel>
                                            <IonText><p>{barnLocation}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Barn Email</IonLabel>
                                            <IonText><p>{barnEmail}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Barn Phone</IonLabel>
                                            <IonText><p>{barnPhone}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                        {isLoading ?
                            <Spinner />
                            :
                            <IonRow className="ion-justify-content-center">
                                {isEdit && (
                                    <IonCol sizeMd="4" className="ion-text-center">
                                        <IonButton
                                            className="ion-margin-top"
                                            color="primary"
                                            expand="block"
                                            onClick={() => setIsEdit(false)}
                                        >
                                            Cancel
                                        </IonButton>
                                    </IonCol>
                                )}
                                <IonCol sizeMd="4" className="ion-text-center">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="tertiary"
                                        expand="block"
                                        onClick={isEdit ? handleEdit : (() => setIsEdit(true))}
                                    >
                                        {!isEdit && "Allow "}Edit
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        }
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default EntryInfoBarn;