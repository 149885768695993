import { 
    IonCard
} from "@ionic/react";
import React from "react";
import { Organization } from "../../models";
import OrganizationDescription from "../Organization/OrganizationDescription";
import OrganizationName from "../Organization/OrganizationName";

interface _Props {
    organization: Organization
}

const BasicEventOrganizationInfo: React.FC<_Props> = ({organization}) => {

    return (
        <IonCard className="bg-white ion-padding">
            <OrganizationName name={organization.name} />
            {organization.description && <OrganizationDescription description={organization.description} />}
            <p className="description">Organization id: {organization.id}</p>
        </IonCard>
    );
};

export default BasicEventOrganizationInfo;