import {
    IonButton, 
    IonCard, 
    IonCardContent, 
    IonCardTitle, 
    IonCol, 
    IonContent, 
    IonHeader,  
    IonIcon,  
    IonItem,  
    IonLabel,    
    IonPage, 
    IonRow, 
} from "@ionic/react";
import { callOutline, clipboardOutline, ellipsisVerticalCircleOutline, locationOutline, peopleOutline, phonePortraitOutline } from "ionicons/icons";
import React, {useEffect, useState} from "react";
import { RouteComponentProps } from "react-router";
import EventDisplayContact from "../../components/Event/About/EventDisplayContact";
import EventDisplayDocuments from "../../components/Event/About/EventDisplayDocuments";
import EventDisplayLocation from "../../components/Event/About/EventDisplayLocation";
import EventDisplayOfficials from "../../components/Event/About/EventDisplayOfficials";
import DisplayScheduleWithClassInfo from "../../components/Event/Schedule/DisplayScheduleWithClassInfo";
import EventAuditorSignUpButton from "../../components/EventAuditor/EventAuditorSignUpButton";
import WebNavbar from "../../components/Navbars/WebNavbar";
import { Event } from "../../models";
import { formatShortAddress } from "../../utilities/address/FormatAddress";
import { formatStartEndDatesStrings } from "../../utilities/dates/FormatDates";
import { getEventById } from "../../utilities/events/Event";
import ErrorAlert from "../../components/Errors/ErrorAlert";

interface ViewShowPageProps extends RouteComponentProps<{
    id: string;
}> {}

const ViewShowPage: React.FC<ViewShowPageProps> = ({match}) => {
    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent(id: string) {
            // Check for the 2023 USHJA Las Vegas show, reroute to 2024 USHJA show. OTW - use the current id to get the show.
            // Need this redirect to ensure the 2023 QR codes can be re-used for the 2024 show.
            const queryResult = await getEventById(id === "3120d9ae-a0d8-4a25-bb1c-a07a1eb00cce" ? "06adf5d3-16eb-448e-855c-d5766f7ce230" : id);
            if (queryResult.isSuccess) {
                setCurrentEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        if (match.params && match.params.id) {
            getEvent(match.params.id);
        } else {
            const path = match.path;
            const pathArray = path.split("/");
            const id = pathArray[pathArray.length-1];
            if (id) {
                getEvent(id);
            }
        }
    }, [match, match.params.id]);
    
    return (
        <IonPage id="viewShowPage" className="bg-light">
            <IonHeader>
                <WebNavbar />
            </IonHeader>
            <IonContent>
                {error && <ErrorAlert error={error} />}
                {currentEvent ? 
                    <>
                        {/* Basic Info */}
                        <IonRow className="ion-justify-content-center ion-padding-top">
                            <IonCol size="12" className="ion-text-center">
                                <h1>{currentEvent.name}</h1>
                                <h5>{currentEvent.address && formatShortAddress(currentEvent.address)} | {formatStartEndDatesStrings(currentEvent.startDate, currentEvent.endDate)}</h5>
                            </IonCol>
                        </IonRow>

                        {/* Sign Up Cards */}
                        <IonRow className="ion-justify-content-center">
                            {/* Text Alert Sign Up Card */}
                            {currentEvent.eventOptions?.usesTextAlerts && (
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonCard mode="md" className="ion-padding bg-white stretch">
                                        <IonCardTitle>
                                            <IonItem lines="none">
                                                <IonIcon icon={phonePortraitOutline} slot="start"/>
                                                <IonLabel> Text Alerts</IonLabel>
                                            </IonItem>
                                        </IonCardTitle>
                                        <IonCardContent>
                                            <IonRow className="ion-justify-content-center">
                                                <p>Click below to sign up to receive text messages about the show or adjust your alert settings.</p>
                                            </IonRow>
                                            <IonRow className="ion-justify-content-center">
                                                <EventAuditorSignUpButton event={currentEvent} />
                                            </IonRow>
                                            {currentEvent.id === "252985ba-02c1-4347-80cc-f890d29a6448" && (
                                                <IonRow className="ion-justify-content-center">
                                                    <p className="ion-text-center"><span className="font-weight-bold">PLEASE NOTE:</span> The 2024 Upperville Colt & Horse Show will use RingSide Pro to send out information about schedule changes, hospitality, vendors and weather updates.</p>
                                                    <p className="mt-3 ion-text-center font-weight-bold">You can find ring timing information here: <a className="link" href="https://horseshowsonline.com/ShowDetails?ShowGUID=4da236a5-857e-4c23-bd48-a845a9e2417b">Horse Shows Online</a>.</p>
                                                </IonRow>
                                            )}
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            )}
                            {/* Online Entry Sign Up Card - check for Cedar Valley Riders org */}
                            {(currentEvent.eventOptions?.usesEventManagement && (currentEvent.organizationId !== "a603e82a-a76b-4c76-bcbc-d6527ef878bc" || currentEvent.id === "04378e60-9038-4689-9275-5ac271c302d0")) && (
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonCard mode="md" className="ion-padding bg-white stretch">
                                        <IonCardTitle>
                                            <IonItem lines="none">
                                                <IonIcon icon={clipboardOutline} slot="start"/>
                                                <IonLabel> Enter Online</IonLabel>
                                            </IonItem>
                                        </IonCardTitle>
                                        <IonCardContent>
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                                    <p>Use your account information to quickly enter online.</p>
                                                    <a href="/login">
                                                        <IonButton color="tertiary">
                                                            Log In
                                                        </IonButton>
                                                    </a>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                                    <p>Create an account to get started and create an entry.</p>
                                                    <a href="/register">
                                                        <IonButton color="tertiary">
                                                            Create Account
                                                        </IonButton>
                                                    </a>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            )}
                        </IonRow>

                        {/* General Event Info */}
                        <IonRow>
                            {/* Resources Card */}
                            <IonCol sizeSm="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonItem lines="none">
                                            <IonIcon icon={ellipsisVerticalCircleOutline} slot="start"/>
                                            <IonLabel> Resources</IonLabel>
                                        </IonItem>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EventDisplayDocuments event={currentEvent} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                            {/* Contact Card */}
                            <IonCol sizeSm="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonItem lines="none">
                                            <IonIcon icon={callOutline} slot="start"/>
                                            <IonLabel> Contact</IonLabel>
                                        </IonItem>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EventDisplayContact event={currentEvent} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                            {/* Officials Card */}
                            <IonCol sizeSm="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonItem lines="none">
                                            <IonIcon icon={peopleOutline} slot="start"/>
                                            <IonLabel> Officials</IonLabel>
                                        </IonItem>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EventDisplayOfficials event={currentEvent} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                            {/* Location Card */}
                            <IonCol sizeSm="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonItem lines="none">
                                            <IonIcon icon={locationOutline} slot="start"/>
                                            <IonLabel> Location</IonLabel>
                                        </IonItem>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EventDisplayLocation event={currentEvent} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>

                        {/* Schedule Card */}
                        {currentEvent.eventOptions?.usesEventManagement && (
                            <IonRow className="mb-5">
                                <IonCol sizeSm="12" sizeMd="12">
                                    <DisplayScheduleWithClassInfo event={currentEvent}/>
                                </IonCol>
                            </IonRow>
                        )}
                    </>
                    :
                    <>  
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12" className="ion-text-center">
                                Loading...
                            </IonCol>
                        </IonRow>
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default ViewShowPage;
