import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorAlert from "../../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../../components/Headers/Header";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps, useHistory } from "react-router";
import { Class, Division, Organization } from "../../../../../../models";
import { getOrganizationById } from "../../../../../../utilities/organization/Organization";
import OrganizationDivisionClassNavbar from "../../../../../../components/Navbars/OrganizationDivisionClassNavbar";
import { FormGroup, Input } from "reactstrap";
import { vhsaAssocHunterData, divisionTemplateData } from "../../../../../../data/template-data/division-templates/VHSAHunterDivisions";
import { CreateClassInput, CreateDivisionInput, CreateOrganizationClassInput, CreateOrganizationDivisionInput } from "../../../../../../API";
import { PersonContext } from "../../../../../../context/PersonContext";
import { createDivision } from "../../../../../../utilities/division/Division";
import { createOrganizationDivision } from "../../../../../../utilities/organizationDivision/OrganizationDivision";
import { createClass } from "../../../../../../utilities/class/Class";
import { createOrganizationClass } from "../../../../../../utilities/organizationClass/OrganizationClass";
import Spinner from "../../../../../../components/Spinners/Spinner";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

interface formattedData {
    id: string
    name: string
    description?: string
    isSelected: boolean
    data: divisionTemplateData
}

const OrganizationDivisionTemplatesPage: React.FC<OrganizationPageProps> = ({match}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [organization, setOrganization] = useState<Organization>();
    const [template, setTemplate] = useState("");
    const [formattedData, setFormattedData] = useState<formattedData[] | undefined | null>();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function getOrganization() {
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                setOrganization(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getOrganization();
    }, [match, match.params.id]);

    const onSelectRow = (formattedDataItem: formattedData, index: number) => {
        setError("");
        if (formattedData) {
            const updatedData: formattedData = {
                ...formattedDataItem,
                isSelected: !formattedDataItem.isSelected
            };
            const updatedDataArray: formattedData[] = [
                ...formattedData.slice(0, index),
                updatedData,
                ...formattedData.slice(index + 1)
            ];
            setFormattedData(updatedDataArray);
        } else {
            setError("No data found.");
        }
    }

    const onSelectAll = (isCheckedValue: boolean) => {
        setError("");
        if (formattedData) {
            const updatedDataArray: formattedData[] = [];
            for (let i = 0; i < formattedData.length; i++) {
                const current = formattedData[i];
                const updatedData: formattedData = {
                    ...current,
                    isSelected: isCheckedValue
                };
                updatedDataArray.push(updatedData);
            }
            setFormattedData(updatedDataArray);
        } else {
            setError("No data found.");
        }
    }

    const formatData = (dataArray: divisionTemplateData[]) => {
        setError("");
        if (dataArray) {
            let formattedArray: formattedData[] = [];
            for (let i = 0; i < dataArray.length; i++) {
                const dataItem: divisionTemplateData = dataArray[i];
                const formattedItem: formattedData = {
                    id: i.toString(),
                    name: dataItem.name,
                    description: dataItem.description,
                    isSelected: true,
                    data: dataItem
                };
                formattedArray.push(formattedItem);
            }
            setFormattedData(formattedArray);
        } else {
            setError("No data found");
        }
    }

    const onSelectTemplate = (value: string) => {
        if (value === "vhsa_assoc_hunter") formatData(vhsaAssocHunterData);
    }

    const onImport = async () => {
        setError("");
        setIsLoading(true);
        let importIsSuccess = true;
        if (formattedData) {
            if (organization) {
                for (let i = 0; i < formattedData.length; i++) {
                    const dataItem: formattedData = formattedData[i];
                    // Check if it is a division or class
                    if (dataItem.data.isDivision) {
                        const divisionInput: CreateDivisionInput = {
                            name: dataItem.name,
                            description: dataItem.description,
                            discipline: dataItem.data.discipline,
                            createdBy: user.id,
                        };
                        const createDivisionResult = await createDivision(divisionInput);
                        if (createDivisionResult.isSuccess) {
                            const division: Division = createDivisionResult.result;
    
                            const organizationDivisionInput: CreateOrganizationDivisionInput = {
                                name: dataItem.name,
                                divisionId: division.id,
                                // organizationDivisionDivisionId: division.id,
                                organizationId: organization.id,
                                minNumberEntries: 3,
                                createdBy: user.id,
                            };
                            const createOrgDivisionResult = await createOrganizationDivision(organizationDivisionInput);
                            if (!createOrgDivisionResult.isSuccess) {
                                importIsSuccess = false;
                                console.error(createOrgDivisionResult);
                                const msg = "An error occurred. Could not create the organization division: " + dataItem.name;
                                setError(msg);
                            }
                        } else {
                            importIsSuccess = false;
                            console.error(createDivisionResult);
                            const msg = "An error occurred. Could not create the division: " + dataItem.name;
                            setError(msg);
                        }
                    } else {
                        const classInput: CreateClassInput = {
                            name: dataItem.name,
                            description: dataItem.description,
                            discipline: dataItem.data.discipline,
                            createdBy: user.id,
                        };
                        const createClassResult = await createClass(classInput);
                        if (createClassResult.isSuccess) {
                            const c: Class = createClassResult.result;
    
                            const organizationClassInput: CreateOrganizationClassInput = {
                                name: dataItem.name,
                                classId: c.id,
                                // organizationClassClassId: c.id,
                                organizationId: organization.id,
                                minNumberEntries: 3,
                                createdBy: user.id,
                            };
                            const createOrgClassResult = await createOrganizationClass(organizationClassInput);
                            if (!createOrgClassResult.isSuccess) {
                                importIsSuccess = false;
                                console.error(createOrgClassResult);
                                const msg = "An error occurred. Could not create the organization class: " + dataItem.name;
                                setError(msg);
                            }
                        } else {
                            importIsSuccess = false;
                            console.error(createClassResult);
                            const msg = "An error occurred. Could not create the class: " + dataItem.name;
                            setError(msg);
                        }
                    }
                }
            } else {
                setError("No organization found.");
            }
        } else {
            setError("No data found");
        }

        if (importIsSuccess && organization) {
            const path = "/index/staff/organization/org-divisions/" + organization.id;
            history.push(path);
        }

        setIsLoading(false);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={organization ? organization.name : "Event Settings"} />
                {organization && <OrganizationDivisionClassNavbar organization={organization} active="divisions" />}
                <IonRow className="ion-justify-content-center">
                    {error && <ErrorAlert width="12" error={error} />}
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Templates</IonCardTitle>
                            <IonCardSubtitle>
                                <p>Select from the following templates to quickly add in Organization Divisions and Classes.</p>
                            </IonCardSubtitle>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Select a Template</IonCardTitle>
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="8">
                                    <FormGroup>
                                        <IonLabel>Available Templates</IonLabel>
                                        <Input 
                                            name="type" 
                                            type="select" 
                                            value={template} 
                                            onChange={e => {
                                                setTemplate(e.target.value);
                                                onSelectTemplate(e.target.value)
                                            }}
                                        >
                                            <option>-</option>
                                            <option value={"vhsa_assoc_hunter"}>VHSA Associate Hunter Horse Show</option>
                                        </Input>
                                    </FormGroup>
                                </IonCol>
                            </IonRow>
                        </IonCard>
                    </IonCol>
                </IonRow>
                {(formattedData && formattedData.length > 0) && (
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <IonCard mode="md" className="ion-padding bg-white">
                                <IonCardTitle>Import Data</IonCardTitle>
                                {isLoading ?
                                    <Spinner />
                                :
                                    <IonCardContent>
                                         <IonRow className="ion-justify-content-center">
                                            <IonCol size="12" className="ion-text-center">
                                                <IonButton color="success" onClick={() => onImport()}>Import Selected Rows</IonButton>
                                            </IonCol>
                                        </IonRow>
                                        <hr />
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="3" offsetMd="3" className="ion-text-center">
                                                <IonButton color="primary" onClick={() => onSelectAll(true)}>Select All</IonButton>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                                <IonButton color="light" onClick={() => onSelectAll(false)}>Deselect All</IonButton>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="12">
                                                <IonList className="bg-white">
                                                    {formattedData.map((item, index) => (
                                                        <IonItem key={index}>
                                                            <IonCheckbox slot="start" value={item.id} checked={item.isSelected} onClick={() => onSelectRow(item, index)} />
                                                            <IonLabel>
                                                                <IonRow>
                                                                    <IonCol>
                                                                        {item.name}
                                                                    </IonCol>
                                                                </IonRow>
                                                                {item.description && (
                                                                    <IonRow>
                                                                        <IonCol>
                                                                            <p className="description ion-text-wrap">Description: {item.description}</p>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                )}
                                                            </IonLabel>
                                                        </IonItem>
                                                    ))}
                                                </IonList>  
                                            </IonCol>
                                        </IonRow>
                                        <hr />
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="12" className="ion-text-center">
                                                <IonButton color="success" onClick={() => onImport()}>Import Selected Rows</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                }
                            </IonCard>
                        </IonCol>
                    </IonRow>
                )}
            </IonContent>
        </IonPage>
    );
};

export default OrganizationDivisionTemplatesPage;