import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateClassInput, CreateClassMutation, DeleteClassInput, DeleteClassMutation, GetClassQuery, ListClassesQuery, UpdateClassInput, UpdateClassMutation } from "../../API";
import { Class } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: class. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created class.
*/
export async function createClass(input: CreateClassInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Class", "No input", "Create Class received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createClass, { input: input }))) as GraphQLResult<CreateClassMutation>;
       else result = (await API.graphql({
           query: mutations.createClass,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateClassMutation>;
       const c = result.data?.createClass;
       return formatResult(true, "Class", c, "Successfully created the class.");
   } catch (error: any) {
       return formatResult(false, "Class", error, "Error creating record in the database for type: class");
   }
}

/**
* Handle updating a new record in the database for type: class. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated class.
*/
export async function updateClass(input: UpdateClassInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Class", "No input", "Update Class received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateClass, { input: input }))) as GraphQLResult<UpdateClassMutation>;
       else result = (await API.graphql({
           query: mutations.updateClass,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateClassMutation>;
       const c = result.data?.updateClass;
       return formatResult(true, "Class", c, "Successfully updated the class.");
   } catch (error: any) {
       return formatResult(false, "Class", error, "Error updating record in the database for type: class");
   }
}

/**
* Handle deleting a new record in the database for type: class. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted class.
*/
export async function deleteClass(input: DeleteClassInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Class", "No input", "Delete Class received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteClass, { input: input }))) as GraphQLResult<DeleteClassMutation>;
       else result = (await API.graphql({
           query: mutations.deleteClass,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteClassMutation>;
       const c = result.data?.deleteClass;
       return formatResult(true, "Class", c, "Successfully deleted the class.");
   } catch (error: any) {
       return formatResult(false, "Class", error, "Error deleting record in the database for type: class");
   }
}

/**
* Read a specific record in the database for type: class. 
* 
* @param {string}  id                  The class id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the class object
*/
export async function getClassById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getClass,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetClassQuery>;
       const c = result.data?.getClass;
       return formatResult(true, "Class", c, "Successfully got the class.");
   } catch (error: any) {
       return formatResult(false, "Class", error, "Error reading record in the database for type: class");
   }
}

/**
* Get all records in the database that match the given criteria for type: class. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the class object
*/
export async function getAllClasses(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listClasses,
            variables: {
                limit: 1000,
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListClassesQuery>;

        let items = result.data?.listClasses?.items as Class[];
        let nextToken = result.data?.listClasses?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listClasses,
                variables: {
                    limit: 1000,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListClassesQuery>;

            const nextItems = nextResult.data?.listClasses?.items as Class[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listClasses?.nextToken;
        }

        if (items) return formatResult(true, "Class", items, "Successfully got the classes.");
        else return formatResult(false, "Class", null, "Could not find the classes.");
    } catch (error: any) {
        return formatResult(false, "Class", error, "Error reading record in the database for type: class");
    }
}

/**
* Get all records in the database that match the given criteria for type: class. 
* 
* @param {string}  personId            The person id of the "owner" of the class
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the class object
*/
export async function getClassesByCreator(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {createdBy: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listClasses,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListClassesQuery>;

        let items = result.data?.listClasses?.items as Class[];
        let nextToken = result.data?.listClasses?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listClasses,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListClassesQuery>;

            const nextItems = nextResult.data?.listClasses?.items as Class[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listClasses?.nextToken;
        }

        return formatResult(true, "Class", items, "Successfully got the Classes.");
    } catch (error: any) {
        return formatResult(false, "Class", error, "Error reading record in the database for type: classes");
    }
}