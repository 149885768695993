import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol, 
    IonContent, 
    IonHeader,  
    IonIcon,  
    IonModal,  
    IonPage, 
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from "@ionic/react";
import React, { useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import WebNavbar from "../../components/Navbars/WebNavbar";
import {Link} from "react-router-dom";
import logo from "./../../assets/img/brand/WhiteLogoAndBlock.png";
import banner from "./../../assets/img/general/Sarah2.jpg";
import emma from "./../../assets/img/general/Emma.jpg";
import onlineEntries from "../../assets/img/general/OnlineEntries.png";
import alerts from "../../assets/img/general/AlertsGray.png";
import pricing from "../../assets/img/general/Pricing.png";
import alex from "../../assets/img/general/Alex.jpg";
import addie from "../../assets/img/general/Addie.jpg";
import lindsey from "../../assets/img/general/Lindsey.jpg";
import clinicsPro from "../../assets/img/general/ClinicsProLogo.png";
import Footer from "../../components/Footers/Footer";
import { close } from "ionicons/icons";
import Testimonial from "../../components/Testimonials/Testimonial";
import { isWindows } from "../../utilities/platform/Platform";
import DisplayClinicianTable from "../../components/Clinician/DisplayClinicianTable";
import TestimonialCarousel from "../../components/Carousel/TestimonialCarousel";

const AboutPage: React.FC = () => {

    const [showModal, setShowModal] = useState(false);

    return (
        <IonPage id="webAboutPage">
            <IonHeader>
                <WebNavbar />
            </IonHeader>
            <IonContent>
                {(!isWindows() && isPlatform("mobile")) ?
                    <section className="bg-white overflow-hidden">
                        <div
                            className="header pb-6 d-flex align-items-center"
                            style={{
                                minHeight: "300px",
                                backgroundImage:
                                "url(\"" + banner + "\")",
                                backgroundSize: "cover",
                                backgroundPosition: "left top"
                            }}
                        >
                            <span className="mask bg-gradient-info opacity-7" />

                            <Container>
                                <IonRow className="justify-content-center">
                                    <IonCol sizeMd="6" sizeXs="8" className="pt-5 px-5">
                                        <img alt="RingSide Pro logo" src={logo}/>
                                    </IonCol>
                                </IonRow>
                            </Container>
                        </div>
                    </section>
                    :
                    <section className="bg-white overflow-hidden">
                        <div
                            className="header pb-6 d-flex align-items-center"
                            style={{
                                minHeight: "500px",
                                backgroundImage:
                                "url(\"" + banner + "\")",
                                backgroundSize: "cover",
                                backgroundPosition: "center top"
                            }}
                        >
                            <span className="mask bg-gradient-info opacity-7" />

                            <Container>
                                <IonRow className="justify-content-center">
                                    <IonCol sizeMd="6" sizeXs="10" className="pt-5 px-5">
                                        <img alt="RingSide Pro logo" src={logo}/>
                                    </IonCol>
                                </IonRow>
                            </Container>
                        </div>
                    </section>
                }
                <section className="py-5 bg-white overflow-hidden">
                    <Container fluid>
                        <IonRow className="justify-content-center mb-3">
                            <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                <h2 className="text-primary">
                                    New technologies enhancing the traditions of equestrian sports.
                                </h2>
                                <p className="text-primary">
                                    Through our online learning tools and competition management services, we are creating an online "One Stop Shop" for all equestrians.
                                </p>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section id="online-entries" className="py-5 bg-light overflow-hidden">
                    <Container fluid>
                        <IonRow className="justify-content-center mb-3">
                            <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                <h2 className="text-primary pb-3">
                                    Online Entries
                                </h2>
                                <p className="text-primary py-3">
                                    No matter the size of your event, we have you covered.
                                </p>
                                <p className="text-primary py-3">
                                    We developed this tool alongside show managers and secretaries to ensure it meets your needs.
                                </p>
                                <IonRow className="text-primary pt-3">
                                    <IonCol size="12" className="ion-text-center">
                                        <h2 className="text-primary">
                                            Get Started for $99
                                        </h2>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="text-primary pb-3">
                                    <IonCol size="12" className="ion-text-center">
                                        <a className="link" href="/online-entries">
                                            <IonButton color="white">
                                                Learn more
                                            </IonButton>
                                        </a>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                <img className="shadow bg-white rounded-1 img" alt="Online Entries" src={onlineEntries}/>
                            </IonCol>
                        </IonRow>
                        {/* <IonRow className="ion-justify-content-center text-primary py-3">
                            <IonCol size="12" className="ion-text-center">
                                <IonCard color="success">
                                    <IonCardContent>
                                        <h2 className="text-white font-weight-bold">Sign up now and get your first 3 shows for 50% off using promo code RSP50.</h2>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow> */}
                    </Container>
                </section>
                <section id="text-alerts" className="py-5 bg-white overflow-hidden">
                    <Container fluid>
                        <IonRow className="justify-content-center mb-3">
                            <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                <img className="shadow bg-white rounded-1 img" alt="Text Alerts" src={alerts}/>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                <h2 className="text-primary pb-3">
                                    Text Alerts
                                </h2>
                                <p className="text-primary py-3">
                                    Horse shows can easily send out mass text messages to exhibitors, trainers and spectators. Choose if you want to send simple announcements, daily reminders and/or ring updates.
                                </p>
                                <IonRow className="text-primary py-3 ion-align-items-space-between">
                                    <IonCol size="12" className="ion-text-center py-3">
                                        <p>
                                            Choose who will send the alerts: show manager, announcer, starters or volunteers.
                                        </p>
                                    </IonCol>
                                    <IonCol size="12" className="ion-text-center py-3">
                                        <p className="text-primary">
                                            Anyone can sign up to receive alerts for free.
                                        </p>
                                    </IonCol>
                                    <IonCol size="12" className="ion-text-center py-3">
                                        <p className="text-primary">
                                            Set filters so texts only go to relevant recipients.
                                        </p>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="text-primary py-3">
                                    <IonCol size="6" className="ion-text-right">
                                        <IonButton onClick={() => setShowModal(true)}>View Pricing</IonButton>
                                    </IonCol>
                                    <IonModal backdropDismiss={false} isOpen={showModal} id="termsAndConditionsModal">
                                        <IonToolbar color="light">
                                            <IonTitle className="ion-text-center">
                                                Text Alert Pricing
                                            </IonTitle>
                                            <IonButtons slot="end">
                                                <IonButton
                                                    fill="clear"
                                                    onClick={() => setShowModal(false)}
                                                >
                                                    <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                                        <IonIcon icon={close} />
                                                    </p>
                                                </IonButton>
                                            </IonButtons>
                                        </IonToolbar>
                                        <IonContent className="ion-padding">
                                            <IonRow className="justify-content-center">
                                                <IonCol size="10" className="text-center">
                                                    <img className="shadow bg-white rounded-1 img" alt="Pricing" src={pricing}/>
                                                </IonCol>
                                            </IonRow>
                                        </IonContent>
                                    </IonModal>
                                    <IonCol size="6" className="ion-text-left">
                                        <a href="/text-alerts">
                                            <IonButton color="success">Learn More</IonButton>
                                        </a>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section id="testimonials" className="py-5 bg-light overflow-hidden">
                    <Container fluid>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <h2 className="text-primary pb-3">
                                    Testimonials
                                </h2>
                            </IonCol>
                        </IonRow>
                        <TestimonialCarousel />
                    </Container>
                </section>
                <section id="online-clinics" className="py-5 bg-white overflow-hidden">
                    <Container fluid>
                        <IonRow className="justify-content-center mb-3">
                            <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                <h2 className="text-primary pb-3">
                                    Online Clinics
                                </h2>
                                <h3 className="text-success py-3">
                                    Elevate Your Riding.
                                </h3>
                                <IonRow className="text-primary py-3 ion-align-items-space-between">
                                    <IonCol size="12" className="ion-text-center py-3">
                                        <p>
                                            Store your riding videos in your personal video library.
                                        </p>
                                    </IonCol>
                                    <IonCol size="12" className="ion-text-center py-3">
                                        <p className="text-primary">
                                            Submit a video and your questions to a clinician.
                                        </p>
                                    </IonCol>
                                    <IonCol size="12" className="ion-text-center py-3">
                                        <p className="text-primary">
                                            Get friendly, supportive feedback. 
                                        </p>
                                    </IonCol>
                                    <IonCol size="12" className="ion-text-center">
                                        <a href="/online-clinics">
                                            <IonButton color="success">Learn More</IonButton>
                                        </a>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                <img className="shadow bg-white rounded-1 small-img" alt="Clinics Pro" src={clinicsPro}/>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center mb-3">
                            <IonCol size="12">
                                <IonCard className="bg-white ion-padding">
                                    <IonCardTitle>
                                        <h3>Available Clinicians</h3>
                                    </IonCardTitle>
                                    <IonCardSubtitle>
                                        <p>Click on a row to see more about the clinician.</p>
                                    </IonCardSubtitle>
                                    <IonCardContent>
                                        <DisplayClinicianTable />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section id="testimonials" className="py-5 bg-primary overflow-hidden">
                    <Container fluid>
                        <IonRow className="justify-content-center">
                            <IonCol size="12" className="ion-text-center">
                                <h2 className="text-white pb-3">
                                    Rider Reviews
                                </h2>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6" sizeLg="3">
                                <Testimonial name="Lindsey D." label="Rider" image={lindsey} quote="The feedback I received was really helpful and educational! Overall, it was very in depth and each piece of advice was practical and something that I could apply to my riding. I recommend it to any rider!" />
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" sizeLg="3">
                                <Testimonial name="Addie L." label="Rider" image={addie} quote="I was nervous about submitting a video and the feedback I would receive, but after submitting my video, I’m sad I didn’t try it sooner! The feedback was supportive and positive. It helped me feel prepared for my next show." />
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" sizeLg="3">
                                <Testimonial name="Alex I." label="Rider" image={alex} quote="It was so easy to send in a video, and I got my feedback almost immediately! I loved that I not only got feedback on my riding, but I also received great training exercises for my horse. I’ve already seen an improvement!" />
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-light overflow-hidden">
                    <Container fluid>
                        <IonRow className="justify-content-center">
                            <IonCol size="12" className="ion-text-center">
                                <h2 className="text-primary pb-3">
                                    RingSide Pro
                                </h2>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center mb-3">
                            <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                <p className="text-primary">
                                    Our mission is to create the highest quality technology to support equestrians of all levels and all disciplines.
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center mb-3">
                            <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                <p className="text-primary">
                                    Our founder, Emma Fass, grew up riding and competing in Virginia. She is a proud member of the equestrian community and is dedicated to creating tools to serve it.
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6" sizeLg="3">
                                <img className="shadow bg-white rounded-1 img" alt="Emma Fass" src={emma}/>
                                <p className="description text-primary">
                                    Emma Fass, photo courtesy of <a href="https://www.instagram.com/kkonophoto/">Kirsten Konopnicki</a>
                                </p>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-light">
                    <Container>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeLg="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Built by equestrians.
                                        </h4>
                                        <p className="description mt-3">
                                            Our technology is designed specifically for equestrians with the goal of making our sport simpler and more accessible to all.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeLg="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-danger text-uppercase">
                                            The latest technology.
                                        </h4>
                                        <p className="description mt-3">
                                            Our sport deserves the best. That's why RingSide Pro uses the latest web technologies to provide you with an incredible online experience.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeLg="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-success text-uppercase">
                                            Support the community.
                                        </h4>
                                        <p className="description mt-3">
                                            One of our goals is to help equestrian professionals create new revenue streams. Supporting them supports our sport and the entire industry.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol size="12">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-center text-uppercase">
                                            Ready to get started?
                                        </h4>
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="6" sizeXl="3" offsetXl="3">
                                                <p className="text-center">
                                                    <Link to="/auth/register" className="link text-info">Create an Account</Link>
                                                </p>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="6" sizeXl="3" >
                                                <p className="text-center">
                                                    <Link to="/auth/login" className="link text-info">Log In</Link>
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                        <hr />
                                        <IonRow>
                                            <IonCol size="12">
                                                <p className="text-center">
                                                    If you would like to support RingSide Pro, <a className="link" href="https://www.buymeacoffee.com/ringsidepro">click here.</a>
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default AboutPage;
