import React, { useEffect, useState } from "react";
import {
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import { Barn, Horse, Owner } from "../../../models";
import { Label } from "reactstrap";
import SelectOrCreateOwner from "../../Owner/SelectOrCreateOwner";
import SelectState from "../../Address/SelectState";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";

interface _Props {
    barn?: (Barn | null | undefined);
    selectedHorse?: (Horse | null | undefined);
}

const SelectOwnerSection: React.FC<_Props> = ({ barn, selectedHorse }) => {
    const [ownerCity, setOwnerCity] = useState<string>("");
    const [ownerState, setOwnerState] = useState<string>("");

    useEffect(() => {
        if (selectedHorse) {
            // Populate the fields with the selected horse's owner info
            setOwnerCity(selectedHorse.owner?.address?.city || "");
            setOwnerState(selectedHorse.owner?.address?.provState || "-");
        } else {
            // Clear the fields if no horse is selected or if it's cleared
            setOwnerCity("");
            setOwnerState("-");
        }
    }, [selectedHorse]);

    const handleSelectedOwner = (owner?: Owner, name?: string) => {
        if (owner && owner.address) {
            // Either set the owner's values or reset them to blank if the horse was cleared
            setOwnerCity(owner?.address?.city || "");
            setOwnerState(owner?.address?.provState || "");         
        } else {
            setOwnerCity("");
            setOwnerState("-");
        }
    }

    const handleStateSelection = (value: string) => {
        setOwnerState(value);
    };

    return (
        <>
            {/* First Row - Owner Name, City, and State */}
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <Label>
                        Name
                        <RequiredInputIndicator />
                    </Label>
                    <SelectOrCreateOwner 
                        selectedBarn={barn}
                        selectedHorse={selectedHorse}
                        onSelect={handleSelectedOwner}
                    />
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4.5">
                    <IonItem color="white">
                        <IonLabel position="stacked">
                            City
                            <RequiredInputIndicator />
                        </IonLabel>
                        <IonInput 
                            type="text"
                            value={ownerCity}
                            aria-required={true}
                            onIonChange={e => { setOwnerCity(e.detail.value!); }}
                        />
                    </IonItem>                
                </IonCol>
                <IonCol sizeXs="12" sizeMd="1.5">
                    <SelectState 
                        selectedValue={ownerState}
                        isRequired={true}
                        onInputChange={handleStateSelection} 
                    />
                </IonCol>
            </IonRow>
        </>
    );
};

export default SelectOwnerSection;
