import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { getS3ImageFileUrl } from "../s3Object/s3Object";
// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateOrganizationInput, CreateOrganizationMutation, DeleteOrganizationInput, DeleteOrganizationMutation, GetOrganizationQuery, ListOrganizationsQuery, LogoInput, UpdateOrganizationInput, UpdateOrganizationMutation } from "../../API";
import moment from "moment";
import { getOrganizationsFromGroups } from "./OrganizationGroup";
import { Organization } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: organization. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created organization.
*/
export async function createOrganization(input: CreateOrganizationInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Organization", "No input", "Create Organization received no input.");
   try {
        const fullInput: CreateOrganizationInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createOrganization, { input: fullInput }))) as GraphQLResult<CreateOrganizationMutation>;
       else result = (await API.graphql({
           query: mutations.createOrganization,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateOrganizationMutation>;
       const organization = result.data?.createOrganization;
       return formatResult(true, "Organization", organization, "Successfully created the organization.");
   } catch (error: any) {
       return formatResult(false, "Organization", error, "Error creating record in the database for type: organization");
   }
}

/**
* Handle updating a new record in the database for type: organization. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated organization.
*/
export async function updateOrganization(input: UpdateOrganizationInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Organization", "No input", "Update Organization received no input.");
   try {
        const fullInput: UpdateOrganizationInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateOrganization, { input: fullInput }))) as GraphQLResult<UpdateOrganizationMutation>;
       else result = (await API.graphql({
           query: mutations.updateOrganization,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateOrganizationMutation>;
       const organization = result.data?.updateOrganization;
       return formatResult(true, "Organization", organization, "Successfully updated the organization.");
   } catch (error: any) {
       return formatResult(false, "Organization", error, "Error updating record in the database for type: organization");
   }
}

/**
* Handle deleting a new record in the database for type: organization. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted organization.
*/
export async function deleteOrganization(input: DeleteOrganizationInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Organization", "No input", "Delete Organization received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteOrganization, { input: input }))) as GraphQLResult<DeleteOrganizationMutation>;
       else result = (await API.graphql({
           query: mutations.deleteOrganization,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteOrganizationMutation>;
       const organization = result.data?.deleteOrganization;
       return formatResult(true, "Organization", organization, "Successfully deleted the organization.");
   } catch (error: any) {
       return formatResult(false, "Organization", error, "Error deleting record in the database for type: organization");
   }
}

/**
* Get all records in the database for type: organization. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organization object
*/
export async function getAllOrganizations(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listOrganizations,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationsQuery>;

        let items = result.data?.listOrganizations?.items as Organization[];
        let nextToken = result.data?.listOrganizations?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizations,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationsQuery>;

            const nextItems = nextResult.data?.listOrganizations?.items as Organization[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizations?.nextToken;
        }

        return formatResult(true, "Organization", items, "Successfully got the Organizations.");
    } catch (error: any) {
        return formatResult(false, "Organization", error, "Error reading record in the database for type: organizations");
    }
}

/**
* Read a specific record in the database for type: organization. 
* 
* @param {string}  id                  The organization id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organization object
*/
export async function getOrganizationById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.getOrganization,
            variables: {
                id: id
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<GetOrganizationQuery>;
        const organization = result.data?.getOrganization;
        for (const l of organization?.logos ?? []) {
          const s3ImageUrlResult = await getS3ImageFileUrl(l?.logo?.key as string);
          if(s3ImageUrlResult.isSuccess) {
            l!.logoURL = s3ImageUrlResult.result;
          }
        }
        if (organization) {
            return formatResult(true, "Organization", organization, "Successfully got the organization.");
        } else {
            return formatResult(false, "Organization", null, "No organization was found.");
        }
    } catch (error: any) {
        return formatResult(false, "Organization", error, "Error reading record in the database for type: organization");
    }
}

/**
* Get all records in the database that match the given criteria for type: organization. 
* 
* @param {string}  personId            The person id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organization object
*/
export async function getOrganizationByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {createdBy: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listOrganizations,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationsQuery>;

        let items = result.data?.listOrganizations?.items as Organization[];
        let nextToken = result.data?.listOrganizations?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listOrganizations,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationsQuery>;

            const nextItems = nextResult.data?.listOrganizations?.items as Organization[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizations?.nextToken;
        }

        const organizations = items;
        if (organizations && organizations[0]) return formatResult(true, "Organization", organizations[0], "Successfully got the organization.");
        else return formatResult(false, "Organization", null, "Could not find the organization.");
    } catch (error: any) {
        return formatResult(false, "Organization", error, "Error reading record in the database for type: organization");
    }
}

/**
* Get all records in the database that match the given criteria for type: organization. 
* 
* @param {string}  personId            The person id of the "owner" of the organization
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organization object
*/
export async function getOrganizationsByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        let organizations: Organization[] = [];

        // First, get the organizations this user is apart of based on user pools
        const userPoolResult = await getOrganizationsFromGroups();
        if (userPoolResult.isSuccess) {
            organizations = userPoolResult.result;
        }

        // Next, get the organizations created by this user (may or may not also be a user group)
        const filter = {createdBy: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listOrganizations,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationsQuery>;

        let items = result.data?.listOrganizations?.items as Organization[];
        let nextToken = result.data?.listOrganizations?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizations,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationsQuery>;

            const nextItems = nextResult.data?.listOrganizations?.items as Organization[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizations?.nextToken;
        }

        const organizationList: Organization[] = items;

        // Only add in the "createdBy" organizations if not already in list
        if (organizationList && organizationList.length) {
            for (var i = 0; i < organizationList.length; i++) {
                const currentOrg = organizationList[i];
                for (const l of currentOrg?.logos ?? []) {
                    const logo: LogoInput = l as LogoInput;
                    const s3ImageUrlResult = await getS3ImageFileUrl(l?.key as string);
                    if(s3ImageUrlResult.isSuccess) {
                        logo!.logoURL = s3ImageUrlResult.result;
                    }
                }
                const filterResult = organizations?.filter(o => o.id === currentOrg.id);
                if (!filterResult.length) organizations.push(currentOrg);
            }
        }

        return formatResult(true, "Organization", organizations, "Successfully got the organizations.");
    } catch (error: any) {
        return formatResult(false, "Organization", error, "Error reading record in the database for type: organizations");
    }
}

/**
* Get all records in the database that match the given criteria for type: organization. 
* 
* @param {string}  urlBackHalf         The unique string used for the organization's back half
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organization object
*/
export async function getOrganizationByBackHalf(urlBackHalf: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.getOrganizationsByURLBackHalf,
            variables: {
                limit: 1000,
                urlBackHalf: urlBackHalf
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.getOrganizationsByURLBackHalf?.items as Organization[];
        let nextToken = result.data?.getOrganizationsByURLBackHalf?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.getOrganizationsByURLBackHalf,
                variables: {
                    limit: 100,
                    urlBackHalf: urlBackHalf,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.getOrganizationsByURLBackHalf?.items as Organization[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.getOrganizationsByURLBackHalf?.nextToken;
        }
        // get the required organization from list by urlBackHalf
        const found: (Organization | undefined) = items.find(org => org.urlBackHalf === urlBackHalf);
        // Get the latest s3 image URL for each logo object
        for (const l of found?.logos ?? []) {
            const logo: LogoInput = l as LogoInput;
            const s3ImageUrlResult = await getS3ImageFileUrl(l?.key as string);
            if(s3ImageUrlResult.isSuccess) {
                logo!.logoURL = s3ImageUrlResult.result;
            }
        }
        return formatResult(true, "Organization", found, "Successfully got the organizations.");
    } catch (error: any) {
        return formatResult(false, "Organization", error, "Error reading record in the database for type: organizations");
    }
}