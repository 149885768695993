import { IonButton, IonCard, IonCol, IonInput, IonItem, IonLabel, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { CreateTeamInput, UpdateMembershipInput } from "../../../API";
import { Membership, OrganizationMembershipType, Team } from "../../../models";
import { updateMembership } from "../../../utilities/membership/Membership";
import ErrorAlert from "../../Errors/ErrorAlert";
import Spinner from "../../Spinners/Spinner";
import CONSTANT from "../../../constant/constant";
import { createTeam } from "../../../utilities/team/Team";

interface _Props {
    membershipType: OrganizationMembershipType | undefined;
    membership: Membership | undefined;
    setMembership: Function
};

const EditMembershipApplicationPayment: React.FC<_Props> = ({membershipType, membership, setMembership}) => {
    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [membershipPrice, setMembershipPrice] = useState<number>();
    const [amountPaid, setAmountPaid] = useState<number>();
    const [isAELOrganization, setIsAELOrganization] = useState(false);

    useEffect(() => {
        if(membership) {
            setAmountPaid(membership.amountPaid || 0);
        }
    }, [membership]);

    useEffect(() => {
        if (membershipType) setMembershipPrice(membershipType.price || 0);
        if (membershipType && membershipType.organizationId === "e05fc919-2793-4ead-acef-3f6efb584b67") setIsAELOrganization(true);
    }, [membershipType]);

    const handleCreateTeam = async (updatedMembership: Membership) => {
        const teamInput: CreateTeamInput = {
            name: updatedMembership.groupContact?.name || "Team",
            creatorId: updatedMembership.createdBy || "",
            teamContactId: updatedMembership.groupContactId || ""
        };
        const createTeamResult = await createTeam(teamInput);
        if (createTeamResult.isSuccess) {
            const newTeam: Team = createTeamResult.result;
            // Update the membership to include the new team id
            const updateInput: UpdateMembershipInput = {
                id: updatedMembership.id,
                teamId: newTeam.id
            };
            const updateResult = await updateMembership(updateInput);
            if (!updateResult.isSuccess) {
                const message = "An error occurred. The team could not be saved to the membership. " + updateResult.message;
                setError(message); 
            }
        } else {
            const message = "An error occurred. The team could not be created. " + createTeamResult.message;
            setError(message);
        }
    }

    const handleCompletePaymentAndUpdateStatus = async () => {
        if(membership) {
            setIsLoading(true);
            const input: UpdateMembershipInput = {
                id: membership.id,
                amountPaid: membershipPrice,
                membershipStatus: CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE
            };
            const updateMembershipResult = await updateMembership(input);
            if (updateMembershipResult.isSuccess) {
                setMembership(updateMembershipResult.result);

                if (isAELOrganization && membershipType?.category === "group") {
                    // First, create the team
                    await handleCreateTeam(membership);
                }
            } else {
                setError(updateMembershipResult.message);
            }
            setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            <IonCard color="white" className="ion-padding">
                <h3>Payment</h3>
                {error && <ErrorAlert width="12" error={error} />}
                {isLoading ? 
                    <Spinner/>
                :
                    <>
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position="stacked">Membership Type Price</IonLabel>
                                        <IonInput
                                            id="membership-type-edit-price"
                                            type="text"
                                            disabled={true}
                                            value={membershipPrice}
                                            aria-required={true}
                                        />
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position="stacked">Amount Paid</IonLabel>
                                        <IonInput
                                            id="membership-amount-paid"
                                            type="text"
                                            value={amountPaid}
                                            disabled={true}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setAmountPaid(e.detail.value! ? parseInt(e.detail.value!) : 0);
                                            }}
                                        />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        {/* <IonRow className="ion-justify-content-center mb-5 mt-4">
                            <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                <IonButton id="edit-membership-payment-save-btn" expand="block" color="tertiary" onClick={handleEditMembershipPayment}>
                                    Save
                                </IonButton>
                            </IonCol>
                        </IonRow> */}
                        {amountPaid !== membershipPrice && (
                            <>
                                <hr />
                                <IonRow className="ion-justify-content-center mb-5 mt-4">
                                    <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                                        <IonButton id="mark-fully-paid-btn" expand="block" color="tertiary" onClick={handleCompletePaymentAndUpdateStatus}>
                                            Mark as Fully Paid and Complete
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                    </>
                }
            </IonCard>
        </React.Fragment>
    );
};

export default EditMembershipApplicationPayment;
