import { EventResult } from "../../models";

/**
 * Sorts the EventResults first by class name
 * 
 * @param data 
 * @returns scheduleItems sorted by class name
 */
export function sortEventResults(data: EventResult[]) {
    try {
        let result: EventResult[] = [];
        if (data && data.length) {
            result = data.sort(function(a, b): number {
                // To do - need a better way to ensure if a change in the order happens, the schedule correctly re-orders all the way day
                const nameResult = (a.eventClassName || "").localeCompare((b.eventClassName || ""));
                const numberResult = (a.eventClass?.number || 0) - (b.eventClass?.number || 0);
                return numberResult || nameResult;
            });
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: EventResult");
        console.error(error);
        return null;
    }
};