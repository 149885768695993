import React, { useContext, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";
import { Organization } from "../../models";
import SaveCard from "./SaveCard";
import { PersonContext } from "../../context/PersonContext";
import ErrorAlert from "../Errors/ErrorAlert";

interface _Props {
    organization: Organization
    handleSubmit: Function
}

const PaymentMethodForm: React.FC<_Props> = ({organization, handleSubmit}) => {
    const user = useContext(PersonContext);
    const clientSecret = "";

    const [error, setError] = useState("");

    const handleCheckoutResult = (isSuccess: boolean, message: string, result: any) => {
        if (isSuccess) handleSubmit(result);
        else setError(message);
    }

    return (
        <IonRow>
            {error && <ErrorAlert width="12" error={error} />}
            <IonCol className="text-center">
                {organization.stripeCustomerId &&
                    <SaveCard client_secret={clientSecret} personEmail={organization.contact?.workEmail || user.email} stripeAccountId={organization.stripeCustomerId} handleCheckoutResult={handleCheckoutResult}/>
                }
            </IonCol>
        </IonRow>
    );
};

export default PaymentMethodForm; 