import React, {useRef, useEffect} from "react";
import WebViewer from "@pdftron/webviewer";

interface _Props {
    file?: any
}

const FileViewer: React.FC<_Props> = ({file}) => {

    const viewerDiv = useRef<HTMLDivElement>(null);

    useEffect(() => {
        WebViewer({
            path: "lib", 
            extension: "pdf",
        }, viewerDiv.current as HTMLDivElement).then(instance => {
            const { documentViewer } = instance.Core;
            // you can now call WebViewer APIs here...
            instance.UI.loadDocument(file);
        });
    }, []);

    return (
        <div className="full-height" ref={viewerDiv}>
            
        </div>
    );
};

export default FileViewer;
