import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { Barn, Event, Horse, S3Object } from "../../models";
import { createHorse, updateHorse } from "../../utilities/horse/Horse";
import ErrorAlert from "../Errors/ErrorAlert";
import HorseHeightInput from "./HorseHeightInput";
import { CreateHorseInput, UpdateHorseInput } from "../../API";
import SelectState from "../Address/SelectState";
import CogginsDocumentForm from "../s3Object/CogginsDocumentForm";
import { createCogginsDocumentFileName, createS3Object, handleUploadS3File } from "../../utilities/s3Object/s3Object";
import moment from "moment";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    horse?: (Horse | null)
    selectedBarn?: (Barn | null)
    event?: (Event | null)
    onChange: Function
}

const QuickAddHorseForm: React.FC<_Props> = ({horse, selectedBarn, event, onChange}) => {
    const user = useContext(PersonContext);
    
    const [barn, setBarn] = useState<Barn | null | undefined>();
    const [name, setName] = useState("");
    const [height, setHeight] = useState(0);
    const [cogginsState, setCogginsState] = useState("");
    const [cogginsNumber, setCogginsNumber] = useState("");
    const [cogginsDate, setCogginsDate] = useState("");
    const [cogginsDocument, setCogginsDocument] = useState<any | null | undefined>();
    const [cogginsS3Object, setCogginsS3Object] = useState<S3Object | null | undefined>();
    const [error, setError] = useState("");

    const clearForm = () => {
        setName("");
        setHeight(0);
    }

    useEffect(() => {
        setBarn(selectedBarn);
    }, [selectedBarn]);

    useEffect(() => {
        if (horse) {
            setName(horse.name);
            if (horse.height) setHeight(parseFloat(horse.height));
            if (horse.cogginsNumber) setCogginsNumber(horse.cogginsNumber);
            if (horse.cogginsDate) setCogginsDate(horse.cogginsDate);
            if (horse.cogginsState) setCogginsState(horse.cogginsState);
            if (horse.coggins) setCogginsS3Object(horse.coggins);
        } else {
            clearForm();
        }
    }, [horse]);

    const verifyForm = () => {
        if (!name) {
            setError("Please include a show name for the horse.");
            return false;
        }
        if (event && event.entryOptions?.requiresCogginsData) {
            if (!cogginsDate) {
                setError("Please include a date for the coggins.");
                return false;
            }
            if (cogginsDate && !moment(new Date()).isBefore(moment(cogginsDate).add(1, "year"))) {
                setError("Please make sure the coggins test date is within the last year.");
                return false;
            }
            if (!cogginsNumber) {
                setError("Please include an accession number for the coggins.");
                return false;
            }
            if (!cogginsState) {
                setError("Please include a state for where the coggins test took place.");
                return false;
            }
        }
        if (event && event.entryOptions?.requiresCogginsDocument) {
            if (!cogginsDocument && !cogginsS3Object) {
                setError("Please include a document for the coggins.");
                return false;
            }
        }
        return true;
    }

    const handleHeightChange = (heightInInches: number) => {
        setHeight(heightInInches);
    }

    const handleSelectedDocument = (file: any) => {
        setCogginsDocument(file);
    }

    const handleUploadDocument = async (file: any) => {
        if (horse) {
            // Upload this to S3 
            const fileName = createCogginsDocumentFileName(horse);
            const uploadResult = await handleUploadS3File(fileName, file);
            if (uploadResult.isSuccess) {
                // If successfully added to S3, save to video list to the person
                const key = uploadResult.result;
                const title = "Coggins for " + horse.name;
                const description = "Coggins uploaded for " + horse.name + " by " + user.firstName + " " + user.lastName + " on " + moment(new Date()).format("MMM DD, YYYY h:mm a"); 
                const s3Object = createS3Object(key, title, file, description);
                return s3Object;
            } else {
                setError("Could not upload the video. Contact hello@ringsidepro.com for help.")
            }
        } else {
            setError("No horse found.")
        }
    }

    const handleCreateHorse = async () => {
        let input: CreateHorseInput = {
            personId: user.id,
            name,
            ownerName: "",
            height: height.toString(),
            barnId: barn?.id,
            // horseBarnId: barn?.id,
            barnName: barn?.name
        };
        if (cogginsState) input.cogginsState = cogginsState;
        if (cogginsDate) input.cogginsDate = cogginsDate;
        if (cogginsNumber) input.cogginsNumber = cogginsNumber;
        if (cogginsDocument) {
            const s3Object = await handleUploadDocument(cogginsDocument);
            input.coggins = s3Object;
        }
        const createResult = await createHorse(input);
        if (createResult.isSuccess) {
            onChange(createResult.result, "create");
        } else {
            setError(createResult.message);
        }
    }

    const handleUpdateHorse = async () => {
        if (horse) {
            let input: UpdateHorseInput = {
                id: horse.id,
                personId: user.id,
                name,
                height: height.toString(),
                barnId: barn?.id,
                // horseBarnId: barn?.id,
                barnName: barn?.name
            };
            if (cogginsState) input.cogginsState = cogginsState;
            if (cogginsDate) input.cogginsDate = cogginsDate;
            if (cogginsNumber) input.cogginsNumber = cogginsNumber;
            if (cogginsDocument) {
                const s3Object = await handleUploadDocument(cogginsDocument);
                input.coggins = s3Object;
            }
            const updateResult = await updateHorse(input);
            if (updateResult.isSuccess) {
                onChange(updateResult.result, "update");
            } else {
                setError(updateResult.message);
            }
        } else {
            setError("No horse to edit.");
        }
    }

    const handleAddHorse = async () => {
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            await handleCreateHorse();
        }
    }

    const handleEditHorse = async () => {
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            await handleUpdateHorse();
        }
    }

    return (
        <>
            {error && <ErrorAlert error={error} />}
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Show Name <RequiredInputIndicator showWordRequired /></IonLabel>
                        <IonInput 
                            type="text"
                            value={name}
                            aria-required={true}
                            onIonChange={e => {
                                setName(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <HorseHeightInput height={height} isRequired={false} onChange={handleHeightChange} />
                </IonCol>
            </IonRow>
            {event && event.entryOptions?.requiresCogginsData && (
                <>
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonItem color="white">
                                <IonLabel position="stacked">Coggins Accession Number <RequiredInputIndicator showWordRequired /></IonLabel>
                                <IonInput 
                                    type="text"
                                    value={cogginsNumber}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setCogginsNumber(e.detail.value!);
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonItem color="white">
                                <IonLabel position="stacked">Coggins Test Date <RequiredInputIndicator showWordRequired /></IonLabel>
                                <IonInput 
                                    type="date"
                                    value={cogginsDate}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setCogginsDate(e.detail.value!);
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="6">
                            <SelectState label="State of Test" isRequired={true} selectedValue={cogginsState} onInputChange={(state: string) => setCogginsState(state)} />
                        </IonCol>
                    </IonRow>
                </>
            )}
            {event && event.entryOptions?.requiresCogginsDocument && (
                <>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <CogginsDocumentForm horse={horse} onSubmit={handleSelectedDocument} />
                        </IonCol>
                    </IonRow>
                </>
            )}
            <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton
                        className="ion-margin-top"
                        color="tertiary"
                        expand="block"
                        onClick={horse ? handleEditHorse : handleAddHorse}
                    >
                        {horse ? "Edit " + horse.name : "Add Horse"}
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default QuickAddHorseForm;