import {CognitoUser} from "../interfaces/CognitoUser";
import React from "react";
import { Person } from "../models";

export const blankPerson: Person = {
    id: "",
    email: "",
    createdOn: "",
    updatedOn: ""
};

export const PersonContext = React.createContext(blankPerson);

export const defaultUserContextValue: CognitoUser = {
    username: "",
    pool: {},
    attributes: {
        sub: "",
        given_name: "",
        family_name: "",
        email: "",
        email_verified: false,
        "custom:personid": "",
        "custom:organizationids": "",
        "custom:roles": ""
    }
};