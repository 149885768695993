import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { CreateContactInput, UpdateContactInput, UpdateEventEntryInput } from "../../../../API";
import { EventEntry } from "../../../../models";
import { createContact, updateContact } from "../../../../utilities/contact/Contact";
import { formatTwilioNumber } from "../../../../utilities/contact/FormatPhoneNumber";
import { updateEventEntry } from "../../../../utilities/eventEntry/EventEntry";
import SuccessBanner from "../../../Banners/SuccessBanner";
import PhoneNumberTypeSelect from "../../../Contact/PhoneNumberTypeSelect";
import ErrorAlert from "../../../Errors/ErrorAlert";
import Spinner from "../../../Spinners/Spinner";

interface _Props {
    entry: EventEntry
}

const EditEntryAbout: React.FC<_Props> = ({entry}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [contactName, setContactName] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactPhoneType, setContactPhoneType] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const getEntryInformation = (entry: EventEntry) => {
        setCurrentEntry(entry);
        setContactName(entry.primarycontact?.name || "");
        setContactEmail(entry.primarycontact?.personalEmail || "");
        setContactPhone(entry?.primarycontact?.cell || entry?.primarycontact?.home || entry?.primarycontact?.work || "");
        setContactPhoneType(entry?.primarycontact?.cell ? "cell" : (entry?.primarycontact?.home ? "home" : (entry?.primarycontact?.work ? "work" : "")));
    }

    useEffect(() => {
        if (entry) {
            getEntryInformation(entry);
        }
    }, [entry]);

    const handlePhoneNumberTypeSelectInput = (phoneNumberTypeInput: string) => {
        setContactPhoneType(phoneNumberTypeInput);
    }

    const handleEdit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        let contactId;
        if (entry.primarycontact?.id) {
            let updateContactInput: UpdateContactInput = {
                id: entry.primarycontact?.id,
                name: contactName,
                personalEmail: contactEmail,
            };
            if (contactPhoneType === "cell") updateContactInput["cell"] = formatTwilioNumber(contactPhone) || undefined;
            if (contactPhoneType === "home") updateContactInput["home"] = formatTwilioNumber(contactPhone) || undefined;
            if (contactPhoneType === "work") updateContactInput["work"] = formatTwilioNumber(contactPhone) || undefined;
            const updateContactResult = await updateContact(updateContactInput);
            if (updateContactResult) {
                contactId = updateContactResult.result.id;
            } else {
                setError("Could not update the contact info.");
                setIsLoading(false);
                return;
            }
        } else {
            let createContactInput: CreateContactInput = {
                name: contactName,
                personalEmail: contactEmail,
            };
            if (contactPhoneType === "cell") createContactInput["cell"] = formatTwilioNumber(contactPhone) || undefined;
            if (contactPhoneType === "home") createContactInput["home"] = formatTwilioNumber(contactPhone) || undefined;
            if (contactPhoneType === "work") createContactInput["work"] = formatTwilioNumber(contactPhone) || undefined;
            const createContactResult = await createContact(createContactInput);
            if (createContactResult) {
                contactId = createContactResult.result.id;
            } else {
                setError("Could not create the contact info.");
                setIsLoading(false);
                return;
            }
        }
        const updateEntryInput: UpdateEventEntryInput = {
            id: entry.id,
            contactId: contactId
        };
        const updateEntryResult = await updateEventEntry(updateEntryInput);
        if (updateEntryResult.isSuccess) {
            const updatedEntry = updateEntryResult.result;
            setCurrentEntry(updatedEntry);
            getEntryInformation(updatedEntry);
            setSuccess("Updated the barn info.");
            setIsEdit(false);
            setIsLoading(false);
        } else {
            setError("Could not update the entry.");
            setIsLoading(false);
            return;
        }
    }

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h3>Primary Contact</h3>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {success && <SuccessBanner width="12" success={success} />}
                        {isEdit ?
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Name</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={contactName}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setContactName(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Email</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={contactEmail}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setContactEmail(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Phone Number</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={contactPhone}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setContactPhone(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <PhoneNumberTypeSelect selectedType={contactPhoneType} onChange={handlePhoneNumberTypeSelectInput} />
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Name</IonLabel>
                                            <IonText><p>{contactName}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Email</IonLabel>
                                            <IonText><p>{contactEmail}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Phone</IonLabel>
                                            <IonText><p>{contactPhone}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Phone Type</IonLabel>
                                            <IonText><p>{contactPhoneType}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                        {isLoading ?
                            <Spinner />
                            :
                            <IonRow className="ion-justify-content-center">
                                {isEdit && (
                                    <IonCol sizeMd="4" className="ion-text-center">
                                        <IonButton
                                            className="ion-margin-top"
                                            color="primary"
                                            expand="block"
                                            onClick={() => setIsEdit(false)}
                                        >
                                            Cancel
                                        </IonButton>
                                    </IonCol>
                                )}
                                <IonCol sizeMd="4" className="ion-text-center">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="tertiary"
                                        expand="block"
                                        onClick={isEdit ? handleEdit : (() => setIsEdit(true))}
                                    >
                                        {!isEdit && "Allow "}Edit
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        }
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default EditEntryAbout;