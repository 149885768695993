import { IonLabel } from "@ionic/react";
import React, { useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { ratingList } from "./Ratings";

interface _Props {
    onInputChange: Function
}

const SelectRating: React.FC<_Props> = ({onInputChange}) => {
    const [rating, setRating] = useState<string>("-");

    return (
        <>
            <FormGroup>
                <IonLabel>Rating</IonLabel>
                <Input 
                    name="rating" 
                    type="select" 
                    value={rating} 
                    onChange={e => {
                        setRating(e.target.value);
                        onInputChange(e.target.value)
                    }}
                >
                    <option>-</option>
                    {ratingList.map((state: {value: number, label: string}) => (
                        <option key={state.value}>{state.label}</option>
                    ))}
                </Input>
            </FormGroup>
        </>
    );
};

export default SelectRating;