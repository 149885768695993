import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateOrganizationStallTypeInput, CreateOrganizationStallTypeMutation, DeleteOrganizationStallTypeInput, DeleteOrganizationStallTypeMutation, GetOrganizationStallTypeQuery, ListOrganizationStallTypesQuery, UpdateOrganizationStallTypeInput, UpdateOrganizationStallTypeMutation } from "../../API";
import moment from "moment";
import { OrganizationStallType } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: organizationStallType. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created organizationStallType.
*/
export async function createOrganizationStallType(input: CreateOrganizationStallTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationStallType", "No input", "Create OrganizationStallType received no input.");
   try {
        const fullInput: CreateOrganizationStallTypeInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createOrganizationStallType, { input: fullInput }))) as GraphQLResult<CreateOrganizationStallTypeMutation>;
       else result = (await API.graphql({
           query: mutations.createOrganizationStallType,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateOrganizationStallTypeMutation>;
       const organizationStallType = result.data?.createOrganizationStallType;
       return formatResult(true, "OrganizationStallType", organizationStallType, "Successfully created the organizationStallType.");
   } catch (error: any) {
       return formatResult(false, "OrganizationStallType", error, "Error creating record in the database for type: organizationStallType");
   }
}

/**
* Handle updating a new record in the database for type: organizationStallType. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated organizationStallType.
*/
export async function updateOrganizationStallType(input: UpdateOrganizationStallTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationStallType", "No input", "Update OrganizationStallType received no input.");
   try {
        const fullInput: UpdateOrganizationStallTypeInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateOrganizationStallType, { input: fullInput }))) as GraphQLResult<UpdateOrganizationStallTypeMutation>;
       else result = (await API.graphql({
           query: mutations.updateOrganizationStallType,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateOrganizationStallTypeMutation>;
       const organizationStallType = result.data?.updateOrganizationStallType;
       return formatResult(true, "OrganizationStallType", organizationStallType, "Successfully updated the organizationStallType.");
   } catch (error: any) {
       return formatResult(false, "OrganizationStallType", error, "Error updating record in the database for type: organizationStallType");
   }
}

/**
* Handle deleting a new record in the database for type: organizationStallType. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted organizationStallType.
*/
export async function deleteOrganizationStallType(input: DeleteOrganizationStallTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationStallType", "No input", "Delete OrganizationStallType received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteOrganizationStallType, { input: input }))) as GraphQLResult<DeleteOrganizationStallTypeMutation>;
       else result = (await API.graphql({
           query: mutations.deleteOrganizationStallType,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteOrganizationStallTypeMutation>;
       const organizationStallType = result.data?.deleteOrganizationStallType;
       return formatResult(true, "OrganizationStallType", organizationStallType, "Successfully deleted the organizationStallType.");
   } catch (error: any) {
       return formatResult(false, "OrganizationStallType", error, "Error deleting record in the database for type: organizationStallType");
   }
}

/**
* Get all records in the database for type: organizationStallType. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationStallType object
*/
export async function getAllOrganizationStallTypes(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listOrganizationStallTypes,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationStallTypesQuery>;
        
        let items = result.data?.listOrganizationStallTypes?.items as OrganizationStallType[];
        let nextToken = result.data?.listOrganizationStallTypes?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizationStallTypes,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationStallTypesQuery>;

            const nextItems = nextResult.data?.listOrganizationStallTypes?.items as OrganizationStallType[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationStallTypes?.nextToken;
        }

        return formatResult(true, "OrganizationStallType", items, "Successfully got the OrganizationStallTypes.");
    } catch (error: any) {
        return formatResult(false, "OrganizationStallType", error, "Error reading record in the database for type: organizationStallTypes");
    }
}

/**
* Read a specific record in the database for type: organizationStallType. 
* 
* @param {string}  id                  The organizationStallType id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationStallType object
*/
export async function getOrganizationStallTypeById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getOrganizationStallType,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetOrganizationStallTypeQuery>;
       const organizationStallType = result.data?.getOrganizationStallType;
       return formatResult(true, "OrganizationStallType", organizationStallType, "Successfully got the organizationStallType.");
   } catch (error: any) {
       return formatResult(false, "OrganizationStallType", error, "Error reading record in the database for type: organizationStallType");
   }
}

/**
* Get all records in the database that match the given criteria for type: organizationStallType. 
* 
* @param {string}  organizationId      The organization id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationStallType object
*/
export async function getOrganizationStallTypesByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationId: {eq: organizationId}};
        const result = (await API.graphql({
            query: queries.listOrganizationStallTypes,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationStallTypesQuery>;
        
        let items = result.data?.listOrganizationStallTypes?.items as OrganizationStallType[];
        let nextToken = result.data?.listOrganizationStallTypes?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizationStallTypes,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationStallTypesQuery>;

            const nextItems = nextResult.data?.listOrganizationStallTypes?.items as OrganizationStallType[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationStallTypes?.nextToken;
        }

        return formatResult(true, "OrganizationStallType", items, "Successfully got the OrganizationStallTypes.");
    } catch (error: any) {
        return formatResult(false, "OrganizationStallType", error, "Error reading record in the database for type: organizationStallTypes");
    }
}