import { MembershipTypeCategory } from "../../models/index";

export interface CategoryTypeOption {
    name: string
    value: string
}

function formatTypeName(value: string) {
    let name = value.replace(/_/g, " ");
    switch(value) {
        case "individual":
            return "Individual"
        case "group":
            return "Group"
        case "horse":
            return "Horse/Pony"
        default:
            return name;
    };
}

/**
 * Takes in the enum MembershipTypeCategory from the models folder
 * Formats the object into an array that can be used to loop over display names with values
 * 
 * @returns an array of objects - {name, value}
 */
export function formatMembershipTypeCategory(): CategoryTypeOption[] {
    let results: CategoryTypeOption[] = [];
    for (const [key, value] of Object.entries(MembershipTypeCategory)) {
        const object = {
            key: key,
            name: formatTypeName(value),
            value: value
        };
        results.push(object);
    }
    return results;
}
