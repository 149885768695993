import { API } from "aws-amplify";
import { Result } from "../../interfaces/Result";

export async function sendInvoiceEmail(email: string, name: string, eventName: string, eventEmail: string, total: string, pdf: Blob) {
    try {
        
        let data = "";
        var reader = new FileReader();
        reader.readAsDataURL(pdf); 
        reader.onloadend = function() {
            var base64data = reader.result;                
            data = base64data as string;

            const queryData = {
                body: {
                    "email": email,
                    "name": name,
                    "eventName": eventName,
                    "showEmail": eventEmail,
                    "total": total,
                    "attachment": data
                }
            }

            const path = "/emails/invoice";
            API.post("Emails", path, queryData);
        }
        
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Email", result: error, message: "Error occurred while trying to create a new email." }
        return result;
    }
}