import React, { useContext, useEffect, useState } from "react";
import Select from 'react-select';
import { PersonContext } from "../../context/PersonContext";
import { onCreateRider } from "../../graphql/subscriptions";
import { Barn, Rider } from "../../models";
import { getRidersByUserByBarn } from "../../utilities/rider/RidersByBarn";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    onSelect: Function
    selectedValue?: string
    barn?: Barn
}

interface formattedOption {
    label: string
    value: string
    rider: Rider
}

const SelectRider: React.FC<_Props> = ({barn, onSelect, selectedValue}) => {
    const user = useContext(PersonContext);

    const riderSubscription = useSubscriptionByItself({
        config: {
            query: onCreateRider,
            key: "onCreateRider"
        }
    });

    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    const [riders, setRiders] = useState<Rider[] | null | undefined>();
    const [formattedRiders, setFormattedRiders] = useState<formattedOption[] | null | undefined>();
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    async function getRiders() {
        const riders = await getRidersByUserByBarn(user, barn);
        return riders;
    }

    const formatSingleRider = (rider: Rider) => {
        return {
            value: rider.id,
            label: rider.name,
            rider: rider
        };
    }

    const formatRiders = (riders: Rider[]) => {
        let formattedRiders = [];
        for (var i = 0; i < riders.length; i++) {
            let object = formatSingleRider(riders[i]);
            formattedRiders.push(object);
        }
        setFormattedRiders(formattedRiders);
        return formattedRiders;
    }

    const findValueInList = (value: string, riderList?: formattedOption[]) => {
        let optionArray = riderList || formattedRiders;
        console.log("optionArray: ", optionArray);
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    const getData = async () => {
        setIsLoading(true); 

        const riders = await getRiders();

        const formattedRiders = formatRiders(riders);

        if (selectedValue) findValueInList(selectedValue, formattedRiders);

        setIsLoading(false); 
    }

    useEffect(() => {
        getData();
    }, [barn, selectedValue]);

    useEffect(() => {
        if (riderSubscription && riderSubscription[0] !== undefined && riderSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(riderSubscription[0]);
            getRiders();
        }
    }, [riderSubscription]);

    const handleOnChange = async (event: any) => {
        if (event && event.rider) {
            setCurrentValue(event);
            onSelect(event.rider);
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {error && <ErrorAlert width="12" error={error} />}
                    <Select
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        defaultValue={currentValue}
                        value={currentValue}
                        menuPortalTarget={document.body}
                        isClearable
                        options={formattedRiders!}
                        onChange={handleOnChange}
                    />
                </>
            }
        </>
    );
};

export default SelectRider;
