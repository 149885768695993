import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { close, create } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Organization, TaxRate } from "../../models";
import TaxForm from "./TaxForm";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { getTaxRatesByOrganizationId } from "../../utilities/taxRate/TaxRate";

interface _Props {
    organization: Organization
}

const TaxTypeForm: React.FC<_Props> = ({organization}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [organizationTaxTypeArray, setOrganizationTaxTypeArray] = useState<(TaxRate | null)[] | null | undefined>();
    const [selectedTax, setSelectedOrganizationTaxType] = useState<TaxRate | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOrganizationTaxTypes(organizationId: string) {
            const queryResult = await getTaxRatesByOrganizationId(organizationId);
            if (queryResult.isSuccess) {
                setOrganizationTaxTypeArray(queryResult.result);
            }
        };
        if (organization) getOrganizationTaxTypes(organization.id);
    }, [organization]);

    const handleSubmit = async (isCreate: Boolean, newOrganizationTaxType: TaxRate) => {
        setIsLoading(true);
        if (organization) {
            setError("");
            let stallArray: (TaxRate | null)[] = [];
            if (isCreate) {
                if (organizationTaxTypeArray && organizationTaxTypeArray.length) {
                    stallArray = organizationTaxTypeArray.concat([newOrganizationTaxType]);
                } else {
                    stallArray.push(newOrganizationTaxType);
                }
            } else {
                // Updated the selectedTax
                if (organizationTaxTypeArray && organizationTaxTypeArray.length) {
                    for (var i = 0; i < organizationTaxTypeArray.length; i++) {
                        const organizationTaxType = organizationTaxTypeArray[i];
                        if (organizationTaxType && organizationTaxType.id === selectedTax?.id) stallArray.push(newOrganizationTaxType);
                        else stallArray.push(organizationTaxType);
                    }
                }
            }
            setOrganizationTaxTypeArray(stallArray);
        } else {
            setError("Error: no organization found. Please contact hello@ringsidepro.com");
        }
        setIsLoading(false);
        setShowModal(false);
    }

    const handleUpdate = async (organizationTaxType?: TaxRate) => {
        if (organizationTaxType) {
            setSelectedOrganizationTaxType(organizationTaxType);
            setShowModal(true);
        }
    }

    const handleAddNew = async () => {
        setSelectedOrganizationTaxType(undefined);
        setShowModal(true);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {organizationTaxTypeArray && (organizationTaxTypeArray.length > 0) ?
                <>
                    <IonList className="bg-white">
                        {organizationTaxTypeArray.map((organizationTaxType, index) => (
                            <IonItem key={index}>
                                <IonLabel><h3>{organizationTaxType && organizationTaxType.name}: {organizationTaxType?.percentage}%</h3></IonLabel>
                                <IonButton slot="end" color="tertiary" onClick={() => handleUpdate(organizationTaxType as TaxRate)}><IonIcon icon={create}/></IonButton>
                            </IonItem>
                        ))}
                    </IonList>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={handleAddNew}>
                                Add Tax Type
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={handleAddNew}>
                                Add Tax Type
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
            <IonModal backdropDismiss={false} isOpen={showModal} id="addOrganizationTaxTypeModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Tax Type
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeStallModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <TaxForm organizationId={organization.id} selectedTax={selectedTax} onSubmit={handleSubmit} />
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default TaxTypeForm;