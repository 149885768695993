import { Auth } from "aws-amplify";
import {
    IonButton,
    IonCol,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { alertCircle, checkmarkCircle } from "ionicons/icons";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import VerifyAccountModal from "../Modals/VerifyAccountModal";
import { updateUserEmail } from "../../utilities/user/User";
import ErrorBanner from "../Banners/ErrorBanner";
import { updatePerson } from "../../utilities/person/Person";

const EmailForm: React.FC = () => {
    const user = useContext(PersonContext);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [email, setEmail] = useState(user.email);
    const [error, setError] = useState("");

    const handleSubmit = async () => {
        setIsLoading(true);
        await updateUser();
        await updateCurrentPerson();
        setIsDisabled(true);
        setIsLoading(false);
    }

    const updateUser = async () => {
        Auth.currentAuthenticatedUser()
            .then(async (user) => {
                if (email) await updateUserEmail(user, email);
            });
    };

    const updateCurrentPerson = async () => {
        const personId = user.id;
        if (!personId) setError("Sorry, a problem has occurred. Please check your internet connection and try again.");
        await updatePerson({
            id: personId,
            email: email,
            isVerified: false
        })
    }; 

    const handleVerifyAccountClick = () => {
        Auth.verifyCurrentUserAttribute("email")
            .then(() => {
                setShowVerifyModal(true);
            }).catch((e) => {
                setError(e.message);
            });

        setShowVerifyModal(true);
    }

    return (
        <form>
            {error && (
                <IonRow>
                    <IonCol>
                        <ErrorBanner error={error} />
                    </IonCol>
                </IonRow>
            )}
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonIcon icon={user.isVerified ? checkmarkCircle : alertCircle } color={user.isVerified ? "success" : "warning"} slot="start" className="ion-padding-top" />
                        <IonLabel position="floating">Email Address</IonLabel>
                        <IonInput 
                            type="email"
                            value={email}
                            aria-required={true}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setEmail(e.detail.value!)
                            }}
                        />
                    </IonItem>
                    {user.isVerified ? 
                        <IonText color="success">Your email address is verified.</IonText>
                        : 
                        <IonText color="warning">Please verify your email address. <span className="link" onClick={handleVerifyAccountClick}>Verify</span></IonText>
                    }
                    <VerifyAccountModal openModal={showVerifyModal} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            disabled={isDisabled}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            {isDisabled ? "Saved" : "Save"}
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </form>
    );
};

export default EmailForm;