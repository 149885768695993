import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Spinner from "../Spinners/Spinner";
import { Organization, Season } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import SelectDisciplines from "../Discipline/SelectDisciplines";
import { useHistory } from "react-router";
import StartToEndDatePicker from "../DatePicker/StartToEndDatePicker";
import moment from "moment";
import { createSeason, updateSeason } from "../../utilities/seasons/Seasons";
import { CreateSeasonInput, UpdateSeasonInput } from "../../API";
import { getEventDisciplinesAsArray } from "../../utilities/events/EventDiscipline";

interface _Props {
    headerTitle: string;
    organization: Organization;
    season?: Season;
}

const SeasonForm: React.FC<_Props> = ({ organization, headerTitle, season }) => {
    const history = useHistory();
    const today = moment(new Date()).format("YYYY-MM-DD");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>(season && season.name ? season.name : "");
    const [seasonDiscipline, setSeasonDiscipline] = useState<string>(season && season.disciplines ? season.disciplines : "");
    const [seasonStatus, setSeasonStatus] = useState<boolean>(season && season.isActive ? true : false);
    const [startDate, setStartDate] = useState<string>(season && season.startDate ? season.startDate : today);
    const [endDate, setEndDate] = useState<string>(season && season.endDate ? season.endDate : today);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        setError("");
    }, [startDate]);

    useEffect(() => {
        setError("");
    }, [endDate]);

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyIsValid();
        if (isValid) {
            await handleCreateAndUpdateSeason();
        }
        setIsLoading(false);
    };

    const verifyIsValid = () => {
        if (!name) {
            setError("You must include a name for the Season.");
            return false;
        }
        if (startDate === "Invalid date") {
            setError("You must select a start date for the Season.");
            return false;
        }
        if (endDate === "Invalid date") {
            setError("You must select a end date for the Season.");
            return false;
        }

        return true;
    };

    const handleCreateAndUpdateSeason = async () => {
        setError("");
        if (season) {
            try {
                const input: UpdateSeasonInput = {
                    id: season.id,
                    name:name,
                    isActive: seasonStatus,
                    startDate: startDate,
                    endDate: endDate,
                    disciplines: seasonDiscipline 
                };
                const updateSeasonResult = await updateSeason(input);
                if (updateSeasonResult.isSuccess) {
                    navigateBack(organization.id);
                } else {
                    setError(updateSeasonResult.message);
                }
            } catch (error) {
                const message = "Could not update the Season: " + error;
                setError(message);
            }
        } else {
            try {
                const input: CreateSeasonInput = {
                    name: name,
                    isActive: seasonStatus,
                    startDate: startDate,
                    endDate: endDate,
                    disciplines: seasonDiscipline,
                    organizationId: organization.id,
                };
                const createSeasonResult = await createSeason(input);
                if (createSeasonResult.isSuccess) {
                    navigateBack(organization.id);
                } else {
                    setError(createSeasonResult.message);
                }
            } catch (error) {
                const message = "Could not create the Season: " + error;
                setError(message);
            }
            clearForm();
        }
    };

    const clearForm = () => {
        setName("");
        setSeasonStatus(false);
        setStartDate(today);
        setEndDate(today);
        setSeasonDiscipline("");
    };

    const navigateBack = (organizationId: string) => {
        const path = "/index/staff/organization/org-seasons/" + organizationId;
        history.replace(path);
    };

    const handleSeasonDisciplineChange = (value: string[]) => {
        if (value) {
            let result = "";
            for (let i = 0; i < value.length; i++) {
                result = result + value[i] + ";";
            }
            setSeasonDiscipline(result);
        }
    };

    const handleDatesChange = (startDate: any, endDate: any) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };

    return (
        <React.Fragment>
            <h1 className="text-darker">{headerTitle}</h1>
            <form>
                {error && <ErrorAlert width="12" error={error} />}
                <IonRow className="mb-4">
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonItem color="white">
                            <IonLabel position="floating">
                                Name{" "}
                                <span className="description text-danger">
                                    *
                                </span>
                            </IonLabel>
                            <IonInput
                                id="organization-season-name"
                                type="text"
                                value={name}
                                aria-required={true}
                                onIonChange={(e) => {
                                    setError("");
                                    setName(e.detail.value!);
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="10">
                        <StartToEndDatePicker
                            initialStartDate={startDate}
                            initialEndDate={endDate}
                            isRequired={true}
                            onChange={handleDatesChange}
                        />
                    </IonCol>
                </IonRow>
                <IonRow className="mb-4 ion-justify-content-between">
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonItem color="white" lines="none">
                            <IonLabel className="text-darker">
                                Disciplines
                            </IonLabel>
                        </IonItem>
                        <SelectDisciplines
                            selectedDisciplines={getEventDisciplinesAsArray(seasonDiscipline)}
                            onSelect={handleSeasonDisciplineChange}
                        />
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="4">
                        <IonItem lines="none">
                            <IonLabel className="text-darker">
                                Season Status
                            </IonLabel>
                        </IonItem>
                        <IonRow>
                            <IonItem lines="none">
                                <IonCheckbox
                                    id="organization-season-status"
                                    checked={seasonStatus}
                                    onIonChange={(e) =>
                                        setSeasonStatus(e.detail.checked)
                                    }
                                />
                                <IonLabel className="text-darker pl-3">
                                    Currently Active
                                </IonLabel>
                            </IonItem>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonRow className=" mb-7 ion-justify-content-center">
                    <IonCol sizeMd="4">
                        {isLoading ? (
                            <Spinner />
                        ) : (
                            <IonButton
                                id="submit-season-btn"
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                Save
                            </IonButton>
                        )}
                    </IonCol>
                </IonRow>
            </form>
        </React.Fragment>
    );
};

export default SeasonForm;
