import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    option?: string
    label?: string
    onAdd?: Function
    onDelete?: Function
}

const EventFilterOptionForm: React.FC<_Props> = ({option, label, onAdd, onDelete}) => {
    const [name, setName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (option) {
            setName(option);
            setError("");
        } else {
            setName("");
            setError("");
        }
    }, [option]);

    useEffect(() => {
        if (onAdd) {
            setName("");
            setError("");
        }
    }, [onAdd]);

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = !!name;
        if (isValid) {
            if (onAdd) onAdd(name);
            if (onDelete) onDelete(name);
        } else {
            setError("You must include a name for the option.");
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert error={error} width="12" />}
            <IonRow>
                <IonCol size="10">
                    <IonItem color="white">
                        <IonLabel position="stacked">{label || "Option Name"}</IonLabel>
                        <IonInput 
                            disabled={!!option}
                            type="text"
                            value={name}
                            aria-required={true}
                            onIonChange={e => {
                                setName(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeMd="2">
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            {option ? 
                                <IonButton
                                    className="ion-margin-top"
                                    color="danger"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    Delete
                                </IonButton>
                                :
                                <IonButton
                                    className="ion-margin-top"
                                    color="success"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    Add
                                </IonButton>
                            }
                        </>
                    }
                </IonCol>
            </IonRow>
        </>
    );
};

export default EventFilterOptionForm;