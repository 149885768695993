import React, { useEffect, useState } from "react";
import Select from "react-select";
import { onCreateBarn, onUpdateBarn } from "../../graphql/subscriptions";
import { Barn } from "../../models";
import { getAllBarns } from "../../utilities/barn/Barn";
import { getAddressById } from "../../utilities/address/Address";
import { getContactById } from "../../utilities/contact/Contact";
import { formatAddress } from "../../utilities/address/FormatAddress";
import { formatPhoneNumberForDisplay } from "../../utilities/contact/FormatPhoneNumber";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import Spinner from "../Spinners/Spinner";

interface _Props {
    onSelect: (barn: Barn | undefined) => void;
    selectedValue?: string;
    isDisabled?: boolean;
    placeholder?: string;
}

interface formattedOption {
    label: string;
    value: string;
    barn: Barn;
    addressString: string;
    contactString: string;
    websiteString?: string;
}

const SelectBarnFromAllBarns: React.FC<_Props> = ({
    onSelect,
    selectedValue,
    isDisabled,
    placeholder,
}) => {
    const [formattedBarns, setFormattedBarns] = useState<formattedOption[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const barnSubscription = useSubscriptionByItself({
        config: {
            query: onCreateBarn,
            key: "onCreateBarn"
        }
    });

    const barnUpdateSubscription = useSubscriptionByItself({
        config: {
            query: onUpdateBarn,
            key: "onUpdateBarn"
        }
    });

    const getBarns = async () => {
        setIsLoading(true);
        try {
            const allBarnsResult = await getAllBarns();
            if (allBarnsResult.isSuccess) {
                const allBarns = allBarnsResult.result;
                const sortedBarns = allBarns.sort((a: Barn, b: Barn) => (a?.name || "zzzzzz").localeCompare(b?.name || "zzzzzz"));
                const formattedBarns = await Promise.all(sortedBarns.map((barn: Barn) => formatBarn(barn)));
                setFormattedBarns(formattedBarns);
            }
        } catch (error) {
            console.error("Error fetching all barns:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const formatBarn = async (barn: Barn): Promise<formattedOption> => {
        let addressString = "Address not provided",
            contactString = "Contact not provided",
            websiteString = "Website not provided";

        if (barn.barnLocationId) {
            const addressResult = await getAddressById(barn.barnLocationId);
            if (addressResult.isSuccess) {
                const address = addressResult.result;
                addressString = formatAddress(address);
            }
        }

        if (barn.barnContactId) {
            const contactResult = await getContactById(barn.barnContactId);
            if (contactResult.isSuccess) {
                const contact = contactResult.result;
                if (contact.work) contactString = formatPhoneNumberForDisplay(contact.work);
                if (contact.website) websiteString = contact.website;
            }
        }

        return {
            value: barn.id,
            label: barn.name,
            barn,
            addressString,
            contactString,
            websiteString
        };
    };

    useEffect(() => {
        getBarns();
    }, []);

    useEffect(() => {
        if (barnSubscription && barnSubscription[0] !== undefined) {
            getBarns();
        }
    }, [barnSubscription]);

    useEffect(() => {
        if (barnUpdateSubscription && barnUpdateSubscription[0] !== undefined) {
            getBarns();
        }
    }, [barnUpdateSubscription]);
    
    const handleOnChange = (selectedOption: formattedOption | null) => {
        if (selectedOption) {
            onSelect(selectedOption.barn);
        } else {
            onSelect(undefined);
        }
    };

    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <Select
                    id="select-from-all-barn"
                    inputId="select-from-all-barn-input"
                    menuPortalTarget={document.body}
                    menuPlacement={"auto"}
                    isDisabled={isDisabled}
                    controlShouldRenderValue={false} //We want to have the search bar always display the "Search barns..." text
                    options={formattedBarns}
                    onChange={handleOnChange}
                    placeholder={placeholder || "Select a barn..."}
                    formatOptionLabel={({ label, addressString, contactString, websiteString }) => (
                        <div className="p-0">
                            <div className="font-weight-bold text-default">{label}</div>
                            <div className="display-5 text-medium">{addressString}</div>
                            <div className="display-5 text-medium">{`${contactString} | ${websiteString}`}</div>
                        </div>
                    )}
                    // Fixes the issue with showing the selected barn instead of the placeholder 
                    // TODO: Show the placeholder instead of nothing
                    styles={{ 
                        singleValue: (base) => ({
                            ...base,
                            visibility: "hidden",
                        }),
                    }}
                />
            )}
        </>
    );
};

export default SelectBarnFromAllBarns;