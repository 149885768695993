import { 
    IonButton,
    IonButtons,
    IonCheckbox,
    IonCol, 
    IonContent, 
    IonIcon, 
    IonInput, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonModal, 
    IonRow, 
    IonTitle, 
    IonToolbar
} from "@ionic/react";
import React, { useState } from "react";
import { Event, EventClass, EventDay, EventRing, ScheduleItem } from "../../../models";
import EventReportCard from "../EventReportCard";
import SelectEventDay from "../../EventDay/SelectEventDay";
import SelectEventRing from "../../EventRing/SelectEventRing";
import { close } from "ionicons/icons";
import { getScheduleItemsByEventId } from "../../../utilities/scheduleItem/ScheduleItem";
import { getEventClassesByEventIdWithFullEventDivisions } from "../../../utilities/eventClass/EventClass";
import { generateClassCountsPDF } from "../../../utilities/reports/ClassCountsReport";
import { generateQuickClassSheets } from "../../../utilities/reports/QuickClassSheets";
import { generateClassSheetGroupPDF } from "../../../utilities/reports/ClassSheetPDF";

interface _Props {
    event: Event
}

interface FormattedEventClass {
    isChecked: boolean
    id: string
    label: string
    eventClass: EventClass
    eventDay?: EventDay
    eventRing?: EventRing,
    eventDivisionName?: string
}

const EventReportClassSheetsSection: React.FC<_Props> = ({event}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [progressMessage, setProgressMessage] = useState("");
    const [error, setError] = useState("");

    const [showClassListModal, setShowClassListModal] = useState(false);
    const [sortByNumber, setSortByNumber] = useState(true);
    const [selectedEventDay, setSelectedEventDay] = useState<EventDay | null | undefined>();
    const [selectedEventRing, setSelectedEventRing] = useState<EventRing | null | undefined>();
    const [formattedEventClasses, setFormattedEventClasses] = useState<FormattedEventClass[] | null | undefined>();
    const [filteredEventClasses, setFilteredEventClasses] = useState<FormattedEventClass[] | null | undefined>();
    const [showFilters, setShowFilters] = useState(false);
    const [includeTrainerInfo, setIncludeTrainerInfo] = useState(false);
    const [includeOwnerInfo, setIncludeOwnerInfo] = useState(false);
    const [includeBreedInfo, setIncludeBreedInfo] = useState(false);
    const [includeHorseHeightInfo, setIncludeHorseHeightInfo] = useState(false);
    const [includeRiderStatusInfo, setIncludeRiderStatusInfo] = useState(false);
    const [includeResultsColumns, setIncludeResultsColumns] = useState(false);
    const [isLandscape, setIsLandscape] = useState(false);
    const [fontSize, setFontSize] = useState(10);

    const generateClassCounts = async () => {
        if (event) {
            setIsLoading(true);
            await generateClassCountsPDF(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateQuickSheetsPDF = async () => {
        if (event) {
            setIsLoading(true);
            await generateQuickClassSheets(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const formatEventClasses = async (scheduleItems?: ScheduleItem[]) => {
        if (event) {
            const eventClassList = await getEventClassesByEventIdWithFullEventDivisions(event.id);
            if (eventClassList.isSuccess) {
                const eventClasses = eventClassList.result;

                let formattedList = [];
                for (let i = 0; i < eventClasses.length; i++) {
                    const eventClass: EventClass = eventClasses[i];
                    const label = (eventClass.number || "") + " - " + eventClass.name;
                    let scheduleItem: ScheduleItem | undefined;
                    if (scheduleItems) {
                        scheduleItem = scheduleItems.find(si => si.classId === eventClass.id);
                    }
                    const formatted: FormattedEventClass = {
                        isChecked: false,
                        label: label,
                        id: eventClass.id,
                        eventDivisionName: eventClass.eventDivision?.name || "",
                        eventClass: eventClass,
                        eventDay: scheduleItem?.eventDay || undefined,
                        eventRing: scheduleItem?.eventRing || undefined
                    };
                    formattedList.push(formatted);
                }
                setFormattedEventClasses(formattedList);
                setFilteredEventClasses(formattedList);
            }
        }
    }

    const generateClassSheets = async () => {
        if (event) {
            setIsLoading(true);
            let scheduleItemArray: ScheduleItem[] = [];
            const scheduleItemsResult = await getScheduleItemsByEventId(event.id);
            if (scheduleItemsResult.isSuccess) {
                scheduleItemArray = scheduleItemsResult.result;
            }
            await formatEventClasses(scheduleItemArray);
            setShowClassListModal(true);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const handleSelectAll = async (value: boolean) => {
        let updatedFormattedClassArray: FormattedEventClass[] | undefined | null = formattedEventClasses;
        if (filteredEventClasses) {
            let updatedList: FormattedEventClass[] = [];
            for (let i = 0; i < filteredEventClasses.length; i++) {
                const current = filteredEventClasses[i];
                const updatedValue: FormattedEventClass = {
                    ...current,
                    isChecked: value
                };
                updatedList.push(updatedValue);

                if (updatedFormattedClassArray) {
                    const indexValue = updatedFormattedClassArray.findIndex(fec => fec.id === current.id);
                    if (indexValue > -1) {
                        updatedFormattedClassArray = [
                            ...updatedFormattedClassArray.slice(0, indexValue),
                            updatedValue,
                            ...updatedFormattedClassArray.slice(indexValue + 1)
                        ];
                    }
                }
            }
            setFilteredEventClasses(updatedList);
            setFormattedEventClasses(updatedFormattedClassArray);
        }
    }

    const handleSelect = async (formattedValue: FormattedEventClass, index: number) => {
        const updatedValue: FormattedEventClass = {
            ...formattedValue,
            isChecked: !formattedValue.isChecked
        };
        if (filteredEventClasses && filteredEventClasses.length) {
            const updatedList = [
                ...filteredEventClasses.slice(0, index),
                updatedValue,
                ...filteredEventClasses.slice(index + 1)
            ];
            setFilteredEventClasses(updatedList);
        } else {
            setFilteredEventClasses([updatedValue]);
        }
        if (formattedEventClasses && formattedEventClasses.length) {
            const indexValue = formattedEventClasses.findIndex(fec => fec.id === formattedValue.id);
            if (indexValue > -1) {
                const updatedList = [
                    ...formattedEventClasses.slice(0, indexValue),
                    updatedValue,
                    ...formattedEventClasses.slice(indexValue + 1)
                ];
                setFormattedEventClasses(updatedList);
            }
        }
    }

    const handleSelectDay = (day?: EventDay) => {
        if (day) {
            setSelectedEventDay(day);
            const updatedFilteredEventClasses = formattedEventClasses?.filter(ec => (ec.eventDay?.id === day.id) && (selectedEventRing ? ec.eventRing?.id === selectedEventRing.id : true));
            setFilteredEventClasses(updatedFilteredEventClasses);
        } else {
            setSelectedEventDay(undefined);
            const updatedFilteredEventClasses = formattedEventClasses?.filter(ec => (selectedEventRing ? ec.eventRing?.id === selectedEventRing.id : true));
            setFilteredEventClasses(updatedFilteredEventClasses);
        }
    }

    const handleSelectRing = (ring?: EventRing) => {
        if (ring) {
            setSelectedEventRing(ring);
            const updatedFilteredEventClasses = formattedEventClasses?.filter(ec => (ec.eventRing?.id === ring.id) && (selectedEventDay ? ec.eventDay?.id === selectedEventDay.id : true));
            setFilteredEventClasses(updatedFilteredEventClasses);
        } else {
            setSelectedEventRing(undefined);
            const updatedFilteredEventClasses = formattedEventClasses?.filter(ec => (selectedEventDay ? ec.eventDay?.id === selectedEventDay.id : true));
            setFilteredEventClasses(updatedFilteredEventClasses);
        }
    }

    const downloadClassSheets = async () => {
        if (event) {
            setIsLoading(true);
            setShowClassListModal(false);
            let classSheetList: EventClass[] = [];
            if (formattedEventClasses && formattedEventClasses.length) {
                for (let i = 0; i < formattedEventClasses.length; i++) {
                    const current = formattedEventClasses[i];
                    if (current.isChecked) {
                        const currentEventClass: EventClass = current.eventClass;
                        classSheetList.push(currentEventClass);
                    }
                }
            }
            await generateClassSheetGroupPDF(classSheetList, event.name, fontSize, sortByNumber, isLandscape, includeResultsColumns, includeRiderStatusInfo, includeBreedInfo, includeHorseHeightInfo, includeTrainerInfo, includeOwnerInfo, setProgressMessage);
            setProgressMessage("");
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const reportLinks = (
        <IonRow>
            <IonCol>
                <IonRow>
                    <IonCol size="12">
                        <p onClick={generateClassCounts} className="link ion-text-wrap">Class Counts</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <p onClick={generateQuickSheetsPDF} className="link ion-text-wrap">Quick Class Sheets</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <p onClick={generateClassSheets} className="link ion-text-wrap">Full Class Sheets</p>
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
    )

    return (
        <>
            <EventReportCard title="Class Sheets" content={reportLinks} isLoading={isLoading} progressMessage={progressMessage} error={error} />
            <IonModal backdropDismiss={false} isOpen={showClassListModal} id="classSheetsModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Select Class Sheets
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowClassListModal(false)}
                        >
                            <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                            <IonButton color="tertiary" onClick={() => setShowFilters(!showFilters)}>Filter By Ring</IonButton>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                            <IonButton color="success" onClick={() => handleSelectAll(true)}>Select All</IonButton>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                            <IonButton color="light" onClick={() => handleSelectAll(false)}>Deselect All</IonButton>
                        </IonCol>
                    </IonRow>
                    {(event && showFilters) && 
                        <>
                            <IonRow>
                                <IonCol>
                                    <IonLabel position="stacked">Filter By Day</IonLabel>
                                    <SelectEventDay eventId={event.id} selectedValue={selectedEventDay?.id} onSelect={(day: EventDay) => handleSelectDay(day)} />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel position="stacked">Filter By Ring</IonLabel>
                                    <SelectEventRing event={event} selectedValue={selectedEventRing?.id} onSelect={(ring: EventRing) => handleSelectRing(ring)} />
                                </IonCol>
                            </IonRow>
                            <hr/>
                        </>
                    }
                    <IonRow>
                        <IonCol>
                            <p>Select the classes you need to download.</p>
                        </IonCol>
                    </IonRow>
                    <IonRow className="bg-light px-3 py-3">
                        <IonCol>
                            {filteredEventClasses ?
                                <IonList className="bg-white scrollable">
                                    {filteredEventClasses.map((formattedEventClass, index) => (
                                        <IonItem>
                                            <IonCheckbox slot="start" value={formattedEventClass.id} checked={formattedEventClass.isChecked} onClick={() => handleSelect(formattedEventClass, index)}/>
                                            <IonLabel>
                                                <IonRow>
                                                    <IonCol>
                                                        <p>{formattedEventClass.label}</p>
                                                    </IonCol>
                                                </IonRow>
                                                {formattedEventClass.eventDivisionName && (
                                                    <IonRow>
                                                        <IonCol>
                                                            <p>(Division: {formattedEventClass.eventDivisionName})</p>
                                                        </IonCol>
                                                    </IonRow>
                                                )}
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                </IonList>
                                :
                                <p>No classes found.</p>
                            }
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center pt-3">
                        <IonCol className="ion-text-center">
                            <p>Settings:</p>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem>
                                <IonLabel><p className="ion-text-wrap">List Entries By Back Number</p></IonLabel>
                                <IonCheckbox slot="start" value="sort" checked={sortByNumber} onIonChange={e => setSortByNumber(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem>
                                <IonLabel><p className="ion-text-wrap">Include rider age info</p></IonLabel>
                                <IonCheckbox slot="start" value="trainer" checked={includeRiderStatusInfo} onIonChange={e => setIncludeRiderStatusInfo(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem>
                                <IonLabel><p className="ion-text-wrap">Include horse breed info</p></IonLabel>
                                <IonCheckbox slot="start" value="owner" checked={includeBreedInfo} onIonChange={e => setIncludeBreedInfo(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem>
                                <IonLabel><p className="ion-text-wrap">Include horse height info</p></IonLabel>
                                <IonCheckbox slot="start" value="owner" checked={includeHorseHeightInfo} onIonChange={e => setIncludeHorseHeightInfo(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem>
                                <IonLabel><p className="ion-text-wrap">Include trainer info</p></IonLabel>
                                <IonCheckbox slot="start" value="trainer" checked={includeTrainerInfo} onIonChange={e => setIncludeTrainerInfo(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem>
                                <IonLabel><p className="ion-text-wrap">Include owner info</p></IonLabel>
                                <IonCheckbox slot="start" value="owner" checked={includeOwnerInfo} onIonChange={e => setIncludeOwnerInfo(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem>
                                <IonLabel><p className="ion-text-wrap">Include space to write results</p></IonLabel>
                                <IonCheckbox slot="start" value="results" checked={includeResultsColumns} onIonChange={e => setIncludeResultsColumns(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem>
                                <IonLabel><p className="ion-text-wrap">Make orientation landscape</p></IonLabel>
                                <IonCheckbox slot="start" value="landscape" checked={isLandscape} onIonChange={e => setIsLandscape(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem color="white">
                                <IonLabel position="stacked">Font Size</IonLabel>
                                <IonInput 
                                    type="number"
                                    value={fontSize}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setFontSize(parseInt(e.detail.value!))
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonButton onClick={downloadClassSheets}>Download</IonButton>
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventReportClassSheetsSection;
