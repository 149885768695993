import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateOrganizationAuditorInput, CreateOrganizationAuditorMutation, DeleteOrganizationAuditorInput, DeleteOrganizationAuditorMutation, GetOrganizationAuditorQuery, ListOrganizationAuditorsQuery, UpdateOrganizationAuditorInput, UpdateOrganizationAuditorMutation } from "../../API";
import moment from "moment";
import { OrganizationAuditor } from "../../models";
import { listOrganizationAuditorsWithFilters } from "./OrganizationAuditorCustomQueries";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: organizationAuditor. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created organizationAuditor.
*/
export async function createOrganizationAuditor(input: CreateOrganizationAuditorInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationAuditor", "No input", "Create OrganizationAuditor received no input.");
   try {
        const fullInput: CreateOrganizationAuditorInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createOrganizationAuditor, { input: fullInput }))) as GraphQLResult<CreateOrganizationAuditorMutation>;
       else result = (await API.graphql({
           query: mutations.createOrganizationAuditor,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateOrganizationAuditorMutation>;
       const organizationAuditor = result.data?.createOrganizationAuditor;
       return formatResult(true, "OrganizationAuditor", organizationAuditor, "Successfully created the organizationAuditor.");
   } catch (error: any) {
       return formatResult(false, "OrganizationAuditor", error, "Error creating record in the database for type: organizationAuditor");
   }
}

/**
* Handle updating a new record in the database for type: organizationAuditor. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated organizationAuditor.
*/
export async function updateOrganizationAuditor(input: UpdateOrganizationAuditorInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationAuditor", "No input", "Update OrganizationAuditor received no input.");
   try {
        const fullInput: UpdateOrganizationAuditorInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateOrganizationAuditor, { input: fullInput }))) as GraphQLResult<UpdateOrganizationAuditorMutation>;
       else result = (await API.graphql({
           query: mutations.updateOrganizationAuditor,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateOrganizationAuditorMutation>;
       const organizationAuditor = result.data?.updateOrganizationAuditor;
       return formatResult(true, "OrganizationAuditor", organizationAuditor, "Successfully updated the organizationAuditor.");
   } catch (error: any) {
       return formatResult(false, "OrganizationAuditor", error, "Error updating record in the database for type: organizationAuditor");
   }
}

/**
* Handle deleting a new record in the database for type: organizationAuditor. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted organizationAuditor.
*/
export async function deleteOrganizationAuditor(input: DeleteOrganizationAuditorInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationAuditor", "No input", "Delete OrganizationAuditor received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteOrganizationAuditor, { input: input }))) as GraphQLResult<DeleteOrganizationAuditorMutation>;
       else result = (await API.graphql({
           query: mutations.deleteOrganizationAuditor,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteOrganizationAuditorMutation>;
       const organizationAuditor = result.data?.deleteOrganizationAuditor;
       return formatResult(true, "OrganizationAuditor", organizationAuditor, "Successfully deleted the organizationAuditor.");
   } catch (error: any) {
       return formatResult(false, "OrganizationAuditor", error, "Error deleting record in the database for type: organizationAuditor");
   }
}

/**
* Get all records in the database for type: organizationAuditor. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationAuditor object
*/
export async function getAllOrganizationAuditors(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listOrganizationAuditors,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationAuditorsQuery>;

        let items = result.data?.listOrganizationAuditors?.items as OrganizationAuditor[];
        let nextToken = result.data?.listOrganizationAuditors?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizationAuditors,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationAuditorsQuery>;

            const nextItems = nextResult.data?.listOrganizationAuditors?.items as OrganizationAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationAuditors?.nextToken;
        }

        return formatResult(true, "OrganizationAuditor", items, "Successfully got the organizationAuditors.");
    } catch (error: any) {
        return formatResult(false, "OrganizationAuditor", error, "Error reading record in the database for type: organizationAuditors");
    }
}

/**
* Read a specific record in the database for type: organizationAuditor. 
* 
* @param {string}  id                  The organizationAuditor id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationAuditor object
*/
export async function getOrganizationAuditorById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getOrganizationAuditor,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetOrganizationAuditorQuery>;
       const organizationAuditor = result.data?.getOrganizationAuditor;
       return formatResult(true, "OrganizationAuditor", organizationAuditor, "Successfully got the organizationAuditor.");
   } catch (error: any) {
       return formatResult(false, "OrganizationAuditor", error, "Error reading record in the database for type: organizationAuditor");
   }
}

/**
* Get all records in the database that match the given criteria for type: organizationAuditor. 
* 
* @param {string}  phoneNumber         The phone number of the event auditor.
* @param {string}  eventId             The event id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationAuditor object
*/
export async function getOrganizationAuditorsByPhoneNumberSearch(phoneNumber: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {phoneNumber: {contains: phoneNumber}};
        const result = (await API.graphql({
            query: queries.listOrganizationAuditors,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationAuditorsQuery>;

        let items = result.data?.listOrganizationAuditors?.items as OrganizationAuditor[];
        let nextToken = result.data?.listOrganizationAuditors?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizationAuditors,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationAuditorsQuery>;

            const nextItems = nextResult.data?.listOrganizationAuditors?.items as OrganizationAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationAuditors?.nextToken;
        }

        const organizationAuditors = items;
        const sorted = organizationAuditors.sort((a, b) => (b.updatedOn || b.createdOn).localeCompare((a.updatedOn || a.createdOn)));
        return formatResult(true, "OrganizationAuditor", sorted || organizationAuditors, "Successfully got the organizationAuditors.");
    } catch (error: any) {
        return formatResult(false, "OrganizationAuditor", error, "Error reading record in the database for type: organizationAuditor");
    }
}

/**
* Get all records in the database that match the given criteria for type: organizationAuditor. 
* 
* @param {string}  phoneNumber         The phone number of the org auditor.
* @param {string}  organizationId      The event id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationAuditor object
*/
export async function getOrganizationAuditorByPhoneNumber(phoneNumber: string, organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {phoneNumber: {eq: phoneNumber}, organizationId: {eq: organizationId}};
        const result = (await API.graphql({
            query: queries.listOrganizationAuditors,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationAuditorsQuery>;

        let items = result.data?.listOrganizationAuditors?.items as OrganizationAuditor[];
        let nextToken = result.data?.listOrganizationAuditors?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizationAuditors,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationAuditorsQuery>;

            const nextItems = nextResult.data?.listOrganizationAuditors?.items as OrganizationAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationAuditors?.nextToken;
        }

        const organizationAuditors = items;
        if (organizationAuditors && organizationAuditors[0]) return formatResult(true, "OrganizationAuditor", organizationAuditors[0], "Successfully got the organizationAuditor.");
        else return formatResult(false, "OrganizationAuditor", null, "Could not find the organizationAuditor.");
    } catch (error: any) {
        return formatResult(false, "OrganizationAuditor", error, "Error reading record in the database for type: organizationAuditor");
    }
}

/**
* Get all records in the database that match the given criteria for type: organizationAuditor. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationAuditor object
*/
export async function getOrganizationAuditorsByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {createdBy: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listOrganizationAuditors,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationAuditorsQuery>;

        let items = result.data?.listOrganizationAuditors?.items as OrganizationAuditor[];
        let nextToken = result.data?.listOrganizationAuditors?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizationAuditors,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationAuditorsQuery>;

            const nextItems = nextResult.data?.listOrganizationAuditors?.items as OrganizationAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationAuditors?.nextToken;
        }

        return formatResult(true, "OrganizationAuditor", items, "Successfully got the organizationAuditors.");
    } catch (error: any) {
        return formatResult(false, "OrganizationAuditor", error, "Error reading record in the database for type: organizationAuditors");
    }
}

/**
* Get all records in the database that match the given criteria for type: organizationAuditor. 
* 
* @param {string}  organizationId       The organizationId of the organization auditor.
* @param {string}  eventId              The event id.
* @param {string}  authMode             API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationAuditor object
*/
export async function getOrganizationAuditorsByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.organizationAuditorByOrganizationId,
            variables: {
                limit: 1000,
                organizationId: organizationId,
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.organizationAuditorByOrganizationId?.items as OrganizationAuditor[];
        let nextToken = result.data?.organizationAuditorByOrganizationId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.organizationAuditorByOrganizationId,
                variables: {
                    limit: 100,
                    organizationId: organizationId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.organizationAuditorByOrganizationId?.items as OrganizationAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.organizationAuditorByOrganizationId?.nextToken;
        }

        const organizationAuditors = items;
        const sorted = organizationAuditors.sort((a, b) => (b.updatedOn || b.createdOn).localeCompare((a.updatedOn || a.createdOn)));
        return formatResult(true, "OrganizationAuditor", sorted || organizationAuditors, "Successfully got the organizationAuditors.");
    } catch (error: any) {
        return formatResult(false, "OrganizationAuditor", error, "Error reading record in the database for type: organizationAuditor");
    }
}

/**
* Get all records in the database that match the given criteria for type: organizationAuditor. 
* 
* @param {string}  organizationId             The organization id 
* @param {string}  authMode                   API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationAuditor object
*/
export async function getOrganizationAuditorsWithFilterByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationId: {eq: organizationId}};
        const result = (await API.graphql({
            query: listOrganizationAuditorsWithFilters,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationAuditorsQuery>;

        let items = result.data?.listOrganizationAuditors?.items as OrganizationAuditor[];
        let nextToken = result.data?.listOrganizationAuditors?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: listOrganizationAuditorsWithFilters,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationAuditorsQuery>;

            const nextItems = nextResult.data?.listOrganizationAuditors?.items as OrganizationAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationAuditors?.nextToken;
        }

        return formatResult(true, "OrganizationAuditor", items, "Successfully got the OrganizationAuditors.");
    } catch (error: any) {
        return formatResult(false, "OrganizationAuditor", error, "Error reading record in the database for type: eventAuditors");
    }
}

/**
* Get all records in the database that match the given criteria for type: organizationAuditor. 
* 
* @param {string}  organizationId             The organization id 
* @param {string}  authMode                   API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationAuditor object
*/
export async function getActiveOrganizationAuditorsWithFilterByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {
            and: [
                { organizationId: {eq: organizationId} },
                { and: [
                    { status: {ne: "paused"} },
                    { status: {ne: "stopped"} }
                ] }
            ]
        };
        const result = (await API.graphql({
            query: listOrganizationAuditorsWithFilters,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationAuditorsQuery>;

        let items = result.data?.listOrganizationAuditors?.items as OrganizationAuditor[];
        let nextToken = result.data?.listOrganizationAuditors?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: listOrganizationAuditorsWithFilters,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationAuditorsQuery>;

            const nextItems = nextResult.data?.listOrganizationAuditors?.items as OrganizationAuditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationAuditors?.nextToken;
        }

        return formatResult(true, "OrganizationAuditor", items, "Successfully got the OrganizationAuditors.");
    } catch (error: any) {
        return formatResult(false, "OrganizationAuditor", error, "Error reading record in the database for type: eventAuditors");
    }
}