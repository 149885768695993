import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { UpdateEventEntryInput, UpdateHorseInput } from "../../../../API";
import { PersonContext } from "../../../../context/PersonContext";
import { Event, EventEntry, Horse, Membership } from "../../../../models";
import { updateEventEntry } from "../../../../utilities/eventEntry/EventEntry";
import { calculateHeightInHands } from "../../../../utilities/horse/Height";
import { updateHorse } from "../../../../utilities/horse/Horse";
import { getMembershipsByHorseId } from "../../../../utilities/membership/Membership";
import { createCogginsDocumentFileName, createS3Object, handleUploadS3File } from "../../../../utilities/s3Object/s3Object";
import SelectState from "../../../Address/SelectState";
import SuccessBanner from "../../../Banners/SuccessBanner";
import ErrorAlert from "../../../Errors/ErrorAlert";
import HorseHeightInput from "../../../Horse/HorseHeightInput";
import CogginsDocumentForm from "../../../s3Object/CogginsDocumentForm";
import Spinner from "../../../Spinners/Spinner";
import { Table } from "reactstrap";
import { addCircleOutline, close } from "ionicons/icons";
import HorseMembershipForm from "../../../Memberships/HorseMembershipForm";
import constants from "../../../../constant/constant";

interface _Props {
    entry: EventEntry
    event: Event
}

const EntryInfoHorse: React.FC<_Props> = ({entry, event}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [currentHorse, setCurrentHorse] = useState<Horse | null | undefined>();
    const [horseName, setHorseName] = useState("");
    const [height, setHeight] = useState(0);
    const [membershipData, setMembershipData] = useState<Membership[] | null | undefined>();
    const [cogginsState, setCogginsState] = useState("");
    const [cogginsNumber, setCogginsNumber] = useState("");
    const [cogginsDate, setCogginsDate] = useState("");
    const [cogginsDocument, setCogginsDocument] = useState<any | null | undefined>();
    const [cogginsS3Object, setCogginsS3Object] = useState<any | null | undefined>();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [showModal, setShowModal] = useState(false);

    async function setMemberships(horse: Horse) {
        let memberships: Membership[] = [];
        const queryResult = await getMembershipsByHorseId(horse.id);
        if (queryResult.isSuccess) {
            const membershipResults: Membership[] = queryResult.result;
            memberships = membershipResults.sort((a, b) => a.name.localeCompare(b.name));
        }
        setMembershipData(memberships);
    }

    const getEntryInformation = (entry: EventEntry) => {
        setCurrentEntry(entry);
        setHorseName(entry.horseName || "");
        setHeight(parseInt(entry?.horse?.height || "0") || 0);
        if (entry.horse) {
            setCurrentHorse(entry.horse);
            setMemberships(entry.horse);
            setCogginsNumber(entry.horse.cogginsNumber || "");
            setCogginsDate(entry.horse.cogginsDate || "");
            setCogginsState(entry.horse.cogginsState || "");
            setCogginsDocument(entry.horse.coggins || null);
        }
    }

    useEffect(() => {
        if (entry) {
            getEntryInformation(entry);
        }
    }, [entry]);

    const handleHeightChange = (heightInInches: number) => {
        setHeight(heightInInches);
    }

    const handleSelectedDocument = async (file: any) => {
        setCogginsDocument(file);
        await handleUploadDocument(file);
    }

    const handleUploadDocument = async (file: any) => {
        // Upload this to S3 
        if (entry && entry.horse) {
            const fileName = createCogginsDocumentFileName(entry.horse);
            const uploadResult = await handleUploadS3File(fileName, file);
            if (uploadResult.isSuccess) {
                // If successfully added to S3, save to video list to the person
                const key = uploadResult.result;
                const title = "Coggins for " + horseName;
                const description = "Coggins uploaded for " + horseName + " by " + user.firstName + " " + user.lastName + " on " + moment(new Date()).format("MMM DD, YYYY h:mm a"); 
                const s3Object = createS3Object(key, title, file, description);
                setCogginsS3Object(s3Object);
                return s3Object;
            } else {
                setError("Could not upload the document. Contact hello@ringsidepro.com for help.")
            }
        }
    }

    const handleEdit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        if (entry.horseId) {
            let input: UpdateHorseInput = {
                id: entry.horseId,
                name: horseName,
                height: height.toString()
            };
            if (cogginsDate) input.cogginsDate = cogginsDate;
            if (cogginsNumber) input.cogginsNumber = cogginsNumber;
            if (cogginsState) input.cogginsState = cogginsState;
            if (cogginsDocument) {
                const s3Object = await handleUploadDocument(cogginsDocument);
                if (s3Object) input["coggins"] = s3Object;
            }
            const updateResult = await updateHorse(input);
            if (updateResult.isSuccess) {
                const updatedHorse: Horse = updateResult.result;
                setCurrentHorse(() => updatedHorse);
                const updateEntryInput: UpdateEventEntryInput = {
                    id: entry.id,
                    horseName: horseName,
                    horseId: updateResult.result.id
                };
                const updateEntryResult = await updateEventEntry(updateEntryInput);
                if (updateEntryResult.isSuccess) {
                    const updatedEntry = updateEntryResult.result;
                    setCurrentEntry(updatedEntry);
                    getEntryInformation(updatedEntry);
                    setMemberships(updatedEntry.horse);
                    setSuccess("Updated the horse info.");
                    setIsEdit(false);
                    setIsLoading(false);
                } else {
                    setError("Could not update the entry.");
                    setIsLoading(false);
                    return;
                }
            } else {
                setError("Could not update the horse");
                setIsLoading(false);
            }
        } else {
            setError("Could not find the horse in the database.");
            setIsLoading(false);
        }
    }

    const handleCreateMembership = async () => {
        if (entry.horse) await setMemberships(entry.horse);
        setShowModal(false);
    }

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h3>Horse</h3>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {success && <SuccessBanner width="12" success={success} />}
                        {isEdit ?
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Horse Name</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={horseName}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setHorseName(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <HorseHeightInput height={height} onChange={handleHeightChange} />
                                    </IonCol>
                                </IonRow>
                                {event.entryOptions?.requiresCogginsData && (
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <IonItem color="white">
                                                <IonLabel position="stacked">Coggins Accession Number</IonLabel>
                                                <IonInput 
                                                    type="text"
                                                    value={cogginsNumber}
                                                    aria-required={true}
                                                    onIonChange={e => {
                                                        setCogginsNumber(e.detail.value!);
                                                    }}
                                                />
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <IonItem color="white">
                                                <IonLabel position="stacked">Coggins Test Date</IonLabel>
                                                <IonInput 
                                                    type="date"
                                                    value={cogginsDate}
                                                    aria-required={true}
                                                    onIonChange={e => {
                                                        setCogginsDate(e.detail.value!);
                                                    }}
                                                />
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <SelectState label="State of Test" selectedValue={cogginsState} onInputChange={(state: string) => setCogginsState(state)} />
                                        </IonCol>
                                        <IonCol size="12">
                                            <CogginsDocumentForm horse={currentHorse} onSubmit={handleSelectedDocument} />
                                        </IonCol>
                                    </IonRow>
                                )}
                            </>
                            :
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Horse Name</IonLabel>
                                            <IonText><p>{horseName}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Horse Height</IonLabel>
                                            <IonText><p>{calculateHeightInHands(height)} hands</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                {event.entryOptions?.requiresCogginsData && (
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <IonItem color="white">
                                                <IonLabel position="stacked">Coggins Accession Number</IonLabel>
                                                <IonText><p>{cogginsNumber}</p></IonText>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <IonItem color="white">
                                                <IonLabel position="stacked">Coggins Test Date</IonLabel>
                                                <IonText><p>{cogginsDate}</p></IonText>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <IonItem color="white">
                                                <IonLabel position="stacked">Coggins Test State</IonLabel>
                                                <IonText><p>{cogginsState}</p></IonText>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol size="12">
                                            <CogginsDocumentForm horse={currentHorse} isDisabled={true} onSubmit={handleSelectedDocument} />
                                            <p className="ion-text-center description text-primary">Click on the Edit button below before making changes.</p>
                                        </IonCol>
                                    </IonRow>
                                )}
                            </>
                        }
                        {(isEdit && event.organizationId !== constants.RHSC_ORGANIZATION.id) && (
                            <IonRow>
                                <IonCol>
                                    <IonButton
                                        color="success"
                                        size="small"
                                        onClick={() => setShowModal(true)}
                                    >
                                        <IonIcon icon={addCircleOutline} />
                                        Add Membership
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        )}
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="11">
                                {(membershipData && membershipData.length > 0) ?
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Membership</th>
                                                <th>ID</th>
                                                <th>Type</th>
                                                <th>Date Expires</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {membershipData.map((membership, index) => (
                                                <tr key={index}>
                                                    <td>{membership.name}</td>
                                                    <td>{membership.membershipId}</td>
                                                    <td>{membership.type}</td>
                                                    <td>{membership.dateMembershipEnds ? moment(membership.dateMembershipEnds).format("MMM DD, YYYY") : ""}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    :
                                    <p>No horse memberships were found.</p>
                                }
                            </IonCol>
                        </IonRow>
                        {isLoading ?
                            <Spinner />
                            :
                            <IonRow className="ion-justify-content-center">
                                {isEdit && (
                                    <IonCol sizeMd="4" className="ion-text-center">
                                        <IonButton
                                            className="ion-margin-top"
                                            color="primary"
                                            expand="block"
                                            onClick={() => setIsEdit(false)}
                                        >
                                            Cancel
                                        </IonButton>
                                    </IonCol>
                                )}
                                <IonCol sizeMd="4" className="ion-text-center">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="tertiary"
                                        expand="block"
                                        onClick={isEdit ? handleEdit : (() => setIsEdit(true))}
                                    >
                                        {!isEdit && "Allow "}Edit
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        }
                    </IonCardContent>
                )}
            </IonCard>

            {/* Create New Horse Membership */}
            <IonModal backdropDismiss={false} isOpen={showModal} id="usefIdModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Create Membership
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="createHorseMembershipCloseBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {entry.horse ?
                        <HorseMembershipForm horse={entry.horse} onSubmit={handleCreateMembership}/>
                        :
                        <p>No horse found.</p>
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default EntryInfoHorse;