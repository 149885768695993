import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateTeamMemberInput, CreateTeamMemberMutation, DeleteTeamMemberInput, DeleteTeamMemberMutation, GetTeamMemberQuery, ListTeamMembersQuery, UpdateTeamMemberInput, UpdateTeamMemberMutation } from "../../API";
import moment from "moment";
import { TeamMember } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: teamMember. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created teamMember.
*/
export async function createTeamMember(input: CreateTeamMemberInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "TeamMember", "No input", "Create TeamMember received no input.");
   try {
        const fullInput: CreateTeamMemberInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createTeamMember, { input: fullInput }))) as GraphQLResult<CreateTeamMemberMutation>;
       else result = (await API.graphql({
           query: mutations.createTeamMember,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateTeamMemberMutation>;
       const teamMember = result.data?.createTeamMember;
       return formatResult(true, "TeamMember", teamMember, "Successfully created the teamMember.");
   } catch (error: any) {
       return formatResult(false, "TeamMember", error, "Error creating record in the database for type: teamMember");
   }
}

/**
* Handle updating a new record in the database for type: teamMember. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated teamMember.
*/
export async function updateTeamMember(input: UpdateTeamMemberInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "TeamMember", "No input", "Update TeamMember received no input.");
   try {
        const fullInput: UpdateTeamMemberInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateTeamMember, { input: fullInput }))) as GraphQLResult<UpdateTeamMemberMutation>;
       else result = (await API.graphql({
           query: mutations.updateTeamMember,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateTeamMemberMutation>;
       const teamMember = result.data?.updateTeamMember;
       return formatResult(true, "TeamMember", teamMember, "Successfully updated the teamMember.");
   } catch (error: any) {
       return formatResult(false, "TeamMember", error, "Error updating record in the database for type: teamMember");
   }
}

/**
* Handle deleting a new record in the database for type: teamMember. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted teamMember.
*/
export async function deleteTeamMember(input: DeleteTeamMemberInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "TeamMember", "No input", "Delete TeamMember received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteTeamMember, { input: input }))) as GraphQLResult<DeleteTeamMemberMutation>;
       else result = (await API.graphql({
           query: mutations.deleteTeamMember,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteTeamMemberMutation>;
       const teamMember = result.data?.deleteTeamMember;
       return formatResult(true, "TeamMember", teamMember, "Successfully deleted the teamMember.");
   } catch (error: any) {
       return formatResult(false, "TeamMember", error, "Error deleting record in the database for type: teamMember");
   }
}

/**
* Get all records in the database for type: teamMember. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the teamMember object
*/
export async function getAllTeamMembers(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listTeamMembers,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTeamMembersQuery>;

        let items = result.data?.listTeamMembers?.items as TeamMember[];
        let nextToken = result.data?.listTeamMembers?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listTeamMembers,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTeamMembersQuery>;

            const nextItems = nextResult.data?.listTeamMembers?.items as TeamMember[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTeamMembers?.nextToken;
        }

        return formatResult(true, "TeamMember", items, "Successfully got the TeamMembers.");
    } catch (error: any) {
        return formatResult(false, "TeamMember", error, "Error reading record in the database for type: teamMembers");
    }
}

/**
* Read a specific record in the database for type: teamMember. 
* 
* @param {string}  id                  The teamMember id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the teamMember object
*/
export async function getTeamMemberById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getTeamMember,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetTeamMemberQuery>;
       const teamMember = result.data?.getTeamMember;
       return formatResult(true, "TeamMember", teamMember, "Successfully got the teamMember.");
   } catch (error: any) {
       return formatResult(false, "TeamMember", error, "Error reading record in the database for type: teamMember");
   }
}

/**
* Get all records in the database that match the given criteria for type: teamMember. 
* 
* @param {string}  personId            The person id of the "owner" of the teamMember
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the teamMember object
*/
export async function getTeamMembersByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listTeamMembers,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTeamMembersQuery>;

        let items = result.data?.listTeamMembers?.items as TeamMember[];
        let nextToken = result.data?.listTeamMembers?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listTeamMembers,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTeamMembersQuery>;

            const nextItems = nextResult.data?.listTeamMembers?.items as TeamMember[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTeamMembers?.nextToken;
        }

        return formatResult(true, "TeamMember", items, "Successfully got the TeamMembers.");
    } catch (error: any) {
        return formatResult(false, "TeamMember", error, "Error reading record in the database for type: teamMembers");
    }
}

/**
* Get all records in the database that match the given criteria for type: teamMember. 
* 
* @param {string}  creatorId           The person id of the "creator" of the teamMember (could be the person who filled out the team member application)
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the teamMember object
*/
export async function getTeamMembersByCreatorId(creatorId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {creatorId: {eq: creatorId}};
        const result = (await API.graphql({
            query: queries.listTeamMembers,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTeamMembersQuery>;

        let items = result.data?.listTeamMembers?.items as TeamMember[];
        let nextToken = result.data?.listTeamMembers?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listTeamMembers,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTeamMembersQuery>;

            const nextItems = nextResult.data?.listTeamMembers?.items as TeamMember[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTeamMembers?.nextToken;
        }

        return formatResult(true, "TeamMember", items, "Successfully got the TeamMembers.");
    } catch (error: any) {
        return formatResult(false, "TeamMember", error, "Error reading record in the database for type: teamMembers");
    }
}

/**
* Get all records in the database that match the given criteria for type: teamMember. 
* 
* @param {string}  teamId              The team id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the teamMember object
*/
export async function getTeamMembersByTeamId(teamId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {teamId: {eq: teamId}};
        const result = (await API.graphql({
            query: queries.listTeamMembers,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTeamMembersQuery>;

        let items = result.data?.listTeamMembers?.items as TeamMember[];
        let nextToken = result.data?.listTeamMembers?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listTeamMembers,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTeamMembersQuery>;

            const nextItems = nextResult.data?.listTeamMembers?.items as TeamMember[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTeamMembers?.nextToken;
        }

        return formatResult(true, "TeamMember", items, "Successfully got the TeamMembers.");
    } catch (error: any) {
        return formatResult(false, "TeamMember", error, "Error reading record in the database for type: teamMembers");
    }
}

/**
* Get all records in the database that match the given criteria for type: teamMember. 
* 
* @param {string}  teamId              The team id 
* @param {string}  personId            The person id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the teamMember object
*/
export async function getTeamMemberByTeamIdPersonId(teamId: string, personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {teamId: {eq: teamId}, personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listTeamMembers,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTeamMembersQuery>;

        let items = result.data?.listTeamMembers?.items as TeamMember[];
        let nextToken = result.data?.listTeamMembers?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listTeamMembers,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTeamMembersQuery>;

            const nextItems = nextResult.data?.listTeamMembers?.items as TeamMember[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTeamMembers?.nextToken;
        }

        return formatResult(true, "TeamMember", items[0], "Successfully got the TeamMembers.");
    } catch (error: any) {
        return formatResult(false, "TeamMember", error, "Error reading record in the database for type: teamMembers");
    }
}