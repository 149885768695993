import {
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRadio,
    IonReorder,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Barn, Horse } from "../../models";
import { getHorsesByPersonId } from "../../utilities/horse/Horse";
import { Table } from "reactstrap";
import RiderAddHorseCard from "./RiderAddHorseCard";
import { calculateHeightInHands } from "../../utilities/horse/Height";
import { PersonContext } from "../../context/PersonContext";
import EditHorseModal from "./EditHorseModal";
import { useHistory } from "react-router";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import { onCreateHorse, onDeleteHorse, onUpdateHorse } from "../../graphql/subscriptions";

interface _Props {
    barn?: Barn
    onSelect: Function
}

const RiderEditHorsesTable: React.FC<_Props> = ({barn, onSelect}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const createSubscription = useSubscriptionByItself({
        config: {
            query: onCreateHorse,
            key: "onCreateHorse"
        }
    });

    const updateSubscription = useSubscriptionByItself({
        config: {
            query: onUpdateHorse,
            key: "onUpdateHorse"
        }
    });

    const deleteSubscription = useSubscriptionByItself({
        config: {
            query: onDeleteHorse,
            key: "onDeleteHorse"
        }
    });

    const [currentCreateSubscriptionItem, setCreateCurrentSubscriptionItem] = useState<any>();
    const [currentUpdateSubscriptionItem, setUpdateCurrentSubscriptionItem] = useState<any>();
    const [currentDeleteSubscriptionItem, setDeleteCurrentSubscriptionItem] = useState<any>();

    useEffect(() => {
        if (createSubscription && createSubscription[0] !== undefined && createSubscription[0] !== currentCreateSubscriptionItem) {
            const item = createSubscription[0];
            setCreateCurrentSubscriptionItem(item);
            getHorses();
        }
    }, [createSubscription]);

    useEffect(() => {
        if (updateSubscription && updateSubscription[0] !== undefined && updateSubscription[0] !== currentUpdateSubscriptionItem) {
            const item = updateSubscription[0];
            setUpdateCurrentSubscriptionItem(item);
            getHorses();
        }
    }, [updateSubscription]);

    useEffect(() => {
        if (deleteSubscription && deleteSubscription[0] !== undefined && deleteSubscription[0] !== currentDeleteSubscriptionItem) {
            const item = deleteSubscription[0];
            setDeleteCurrentSubscriptionItem(item);
            getHorses();
        }
    }, [deleteSubscription]);

    const [horses, setHorses] = useState<Horse[] | null | undefined>();
    const [selectedHorse, setSelectedHorse] = useState<Horse | null | undefined>();

    async function getHorses() {
        const queryResult = await getHorsesByPersonId(user.id);
        if (queryResult.isSuccess) {
            setHorses(queryResult.result);
        }
    }

    useEffect(() => {
        getHorses();
    }, []);

    const onSelectHorse = (horse: Horse) => {
        const path = "/index/horse/" + horse.id;
        history.push(path);
    }

    const handleCreateHorse = (horse: Horse) => {
        let updated: Horse[] = [horse];
        if (horses) {
            updated = updated.concat(horses);
        }
        setHorses(updated);
    }

    const handleEditHorse = (updatedHorse: Horse) => {
        if (horses) {
            const index = horses.findIndex(horse => horse.id === updatedHorse.id);
            const updated = [
                ...horses.slice(0, index),
                updatedHorse,
                ...horses.slice(index + 1)
            ];
            setHorses(updated);
        } else {
            setHorses([updatedHorse]);
        }
    }

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="4">
                        My Horses
                    </IonCol>
                    <IonCol offsetMd="4" sizeXs="12" sizeMd="4" className="ion-text-right">
                        <RiderAddHorseCard handleNewHorse={handleCreateHorse} />
                    </IonCol>
                </IonRow>
            </IonCardTitle>
            <IonCardSubtitle>Click on a Horse record to edit it.</IonCardSubtitle>
            {isPlatform("mobile") ?
                <IonList className="bg-white">
                    {(horses && horses.length > 0) && (
                        <>
                            {horses.map((horse, index) => (
                                <IonItem key={index} onClick={() => onSelectHorse(horse)}>
                                    <IonLabel>
                                        <p className="ion-text-wrap">{horse.name}</p>
                                    </IonLabel>
                                    <IonRadio slot="start" color="tertiary" value={horse.id} />
                                    <IonReorder slot="start" />
                                </IonItem>
                            ))}
                        </>
                    )}
                </IonList>
                :
                <Table hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Nickname</th>
                            <th>Breed</th>
                            <th>Height</th>
                            <th>Owner</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(horses && horses.length > 0) && (
                            <>
                                {horses.map((horse, index) => (
                                    <tr key={index} onClick={() => onSelectHorse(horse)}>
                                        <td>
                                            {horse.name}
                                        </td>
                                        <td>
                                            {horse.nickname}
                                        </td>
                                        <td>
                                            {horse.breed}
                                        </td>
                                        <td>
                                            {horse.height ? calculateHeightInHands(parseInt(horse.height)) : ""}
                                        </td>
                                        <td>
                                            {horse.ownerName}
                                        </td>
                                    </tr>
                                ))}
                            </>
                        )}
                    </tbody>
                </Table>
            }
        </IonCard>
    );
};

export default RiderEditHorsesTable;