import { 
    IonButton,
    IonCard,
    IonCheckbox,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonIcon,
    isPlatform
} from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import React, { useState } from "react";
import { Input } from "reactstrap";
import moment from "moment";
import { OrganizationMembershipType, OrganizationAgreements, Membership} from "../../models";
import { isWindows } from "../../utilities/platform/Platform";
import ErrorAlert from "../Errors/ErrorAlert";
import { updateMembership } from "../../utilities/membership/Membership";
import { UpdateMembershipInput } from "../../API";
import CONSTANT from "../../constant/constant";
import Spinner from "../Spinners/Spinner";

interface _Props {
    membershipType: OrganizationMembershipType;
    setSegmentValue: Function;
    memberships: Membership[] | undefined;
    setMemberships: Function;
    personId: string;
};

const MembershipApplicationWaiversList: React.FC<_Props> = ({ membershipType, setSegmentValue, memberships, setMemberships, personId }) => {
    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [organizationAgreements, setOrganizationAgreements] = useState<(OrganizationAgreements | null)[] | null>((memberships && memberships[0] && memberships[0].organizationAgreements) ? memberships[0]?.organizationAgreements : []);
    
    const handleSubmit = async () => {
        setError("");
        try {
            // Track if the required signatures have been completed
            let allRequiredSignaturesArePresent = true;

            const organizationAgreementsArray: OrganizationAgreements[] = [];
            membershipType.organizationRules?.forEach((item, index) => {
                const iconElement: HTMLIonIconElement = document.getElementById(`green-check-box-${index}`) as HTMLIonIconElement;
                if(iconElement.getAttribute("data-visible") === "true") {
                    const firstNameID = `mt-waiver-signatures-first-name-${index}`;
                    const lastNameID = `mt-waiver-signatures-last-name-${index}`;
                    const firstNameElement: HTMLInputElement  = document.getElementById(firstNameID) as HTMLInputElement;
                    const lastNameElement: HTMLInputElement = document.getElementById(lastNameID) as HTMLInputElement;
                    const input: OrganizationAgreements = {
                        organizationRuleId: item?.id,
                        organizationRuleTitle: item?.title ?? "",
                        firstName: firstNameElement.value,
                        lastName: lastNameElement.value,
                        personId: personId,
                        createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                        updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                    };
                    organizationAgreementsArray.push(input);
                } else if (item?.isRequired) {
                    allRequiredSignaturesArePresent = false;
                }
            });
            
            if (!allRequiredSignaturesArePresent) {
                setError("Please ensure that all waivers have been signed.");
                return;
            }

            if (memberships?.length) {
                let updatedMembershipArray: Membership[] = [];
                for (let i = 0; i < memberships.length; i++) {
                    const membership: Membership = memberships[i];
                    setIsLoading(true);
                    const input: UpdateMembershipInput = {
                        id: membership.id,
                        organizationAgreements: organizationAgreementsArray
                    };
                    const updateResult = await updateMembership(input);
                    if (updateResult.isSuccess) {
                        updatedMembershipArray.push(updateResult.result);
                    } else {
                        setError(updateResult.message);
                    }
                    setIsLoading(false);
                }
                setMemberships(updatedMembershipArray);
                handleNavigateToNext();
            } else {
                setError("No membership was found.");
            }
        } catch (err) {
            setError("Please ensure that all waivers have been signed.");
        }
    };

    const showHideGreenCheckBox = (event: any) => {
        setError("");
        const index = event.target.getAttribute("data-index");
        const firstNameID = `mt-waiver-signatures-first-name-${index}`;
        const lastNameID = `mt-waiver-signatures-last-name-${index}`;
        const checkboxID = `mt-waiver-signatures-check-box-${index}`;
        const id = event.target.getAttribute("data-id");
        const firstNameElement: HTMLInputElement  = document.getElementById(firstNameID) as HTMLInputElement;
        const lastNameElement: HTMLInputElement = document.getElementById(lastNameID) as HTMLInputElement;
        const checkBoxElement: HTMLInputElement = document.getElementById(checkboxID) as HTMLInputElement;
        const iconElement: HTMLIonIconElement = document.getElementById(`green-check-box-${index}`) as HTMLIonIconElement;
        if(firstNameElement?.value?.length > 0 && lastNameElement?.value?.length > 0 && checkBoxElement.checked) {
            iconElement.style.display = "inline-block";
            iconElement.setAttribute("data-visible", "true");
        } else {
            iconElement.style.display = "none";
            iconElement.setAttribute("data-visible", "false");
        }
        const foundIndex = organizationAgreements?.findIndex((a)=> {
            return a?.organizationRuleId === id;
        });
        const found = organizationAgreements?.find((a)=> {
            return a?.organizationRuleId === id;
        });
        if(foundIndex && foundIndex !== -1 && found) {
            const input: OrganizationAgreements = {
                organizationRuleId: found.organizationRuleId,
                organizationRuleTitle: found.organizationRuleTitle,
                firstName: firstNameElement.value,
                lastName: lastNameElement.value,
                personId: personId,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
            if(organizationAgreements?.length) {
                const newArray = [
                    ...organizationAgreements?.slice(0, foundIndex) ,
                    input,
                    ...organizationAgreements?.slice(foundIndex + 1)
                ];
                setOrganizationAgreements(newArray);
            }
           
        }

    };

    const handleNavigateToNext = () => {
        setSegmentValue(CONSTANT.MEMBERSHIP.APPLICATION_STAGES.PAYMENT);
    };

    return(
        <IonCard color="white" className="ion-padding">
            <h3>{membershipType.name}</h3>
            {isLoading ?
                <Spinner/>
            :
                <form>
                    {error && <ErrorAlert width="12" error={error} />}
                    {membershipType.organizationRules && membershipType.organizationRules?.length === 0 ?
                        <>
                            <IonRow>
                                There are no waivers to sign. Continue to the next step.
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                    <IonButton
                                        color="tertiary"
                                        expand="block"
                                        onClick={handleNavigateToNext}
                                        id="mt-waiver-list-next-btn"
                                    >
                                        Next
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                    :
                        <>
                            {membershipType.organizationRules?.map((item, index) => {
                                let found;
                                if(organizationAgreements && organizationAgreements.length > 0) {
                                    found = organizationAgreements.find((doc) => {
                                        return doc?.organizationRuleId === item?.id;
                                    });
                                }
                                return (
                                    <div key={index}> 
                                        <IonRow className="ion-justify-content-center">
                                            <IonIcon
                                                icon={checkmarkCircleOutline}
                                                id={`green-check-box-${index}`}
                                                data-visible={found ? "true" : "false"}
                                                size="large"
                                                className={found ? "waiver-list-green-check-box-show mr-1" : "waiver-list-green-check-box-hide mr-1"}
                                            />
                                            <h4>
                                                {item?.title}
                                            </h4>
                                        </IonRow>
                                        <IonRow>
                                            <Input
                                                id="waiver-list-body-info"
                                                rows={(isWindows() || isPlatform("desktop")) ? "5" : "15"}
                                                type="textarea"
                                                name="waiver-sign-description"
                                                value={item?.body}
                                                className="mt-3"
                                                disabled={true}
                                            />
                                        </IonRow>
                                        <IonRow className= {(isWindows() || isPlatform("desktop")) ? "px-5" : ""}>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonItem color="white">
                                                    <IonLabel position="floating">

                                                        First Name
                                                        {item?.isRequired && (
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        )}
                                                    </IonLabel>
                                                    {found ?
                                                        <IonInput
                                                            data-index={index}
                                                            data-id={found.organizationRuleId}
                                                            id={`mt-waiver-signatures-first-name-${index}`}
                                                            type="text"
                                                            name="firstName"
                                                            value={found.firstName}
                                                            aria-required={true}
                                                            onIonChange={showHideGreenCheckBox}
                                                        />
                                                    :
                                                        <IonInput
                                                            data-index={index}
                                                            id={`mt-waiver-signatures-first-name-${index}`}
                                                            type="text"
                                                            name="firstName"
                                                            aria-required={true}
                                                            onIonChange={showHideGreenCheckBox}
                                                        />
                                                    }
                                                </IonItem>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonItem color="white">
                                                    <IonLabel position="floating">
                                                        Last Name
                                                        {item?.isRequired && (
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        )}
                                                    </IonLabel>
                                                    {found ?
                                                        <IonInput
                                                            data-index={index}
                                                            data-id={found.organizationRuleId}
                                                            id={`mt-waiver-signatures-last-name-${index}`}
                                                            type="text"
                                                            name="lastName"
                                                            value={found.lastName}
                                                            aria-required={true}
                                                            onIonChange={showHideGreenCheckBox}
                                                        />
                                                    :
                                                        <IonInput
                                                            data-index={index}
                                                            id={`mt-waiver-signatures-last-name-${index}`}
                                                            type="text"
                                                            name="lastName"
                                                            aria-required={true}
                                                            onIonChange={showHideGreenCheckBox}
                                                        />
                                                    }
                                                </IonItem>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="12">
                                                <IonItem color="white">
                                                    {found ?
                                                        <IonCheckbox
                                                            data-index={index}
                                                            data-id={found.organizationRuleId}
                                                            id={`mt-waiver-signatures-check-box-${index}`}
                                                            aria-required={true}
                                                            name="mtsignCheckedStatus"
                                                            checked={true}
                                                            onIonChange={showHideGreenCheckBox}
                                                        />
                                                    :
                                                        <IonCheckbox
                                                            data-index={index}
                                                            id={`mt-waiver-signatures-check-box-${index}`}
                                                            aria-required={true}
                                                            name="mtsignCheckedStatus"
                                                            onIonChange={showHideGreenCheckBox}
                                                        />
                                                    }
                                                    <IonLabel className="pl-3 ion-text-wrap">
                                                        I have read and agree to the above
                                                        {item?.isRequired && (
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        )}
                                                    </IonLabel>
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                    </div>
                                );
                            })}
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="tertiary"
                                        expand="block"
                                        onClick={handleSubmit}
                                        id="mt-waiver-list-save-btn"
                                    >
                                        Save
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                    }
                </form>
            }
        </IonCard>
    )
};

export default MembershipApplicationWaiversList;