import {
    IonButton,
    IonButtons,
    IonContent,
    IonIcon,
    IonModal,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useState} from "react";
import AddressForm from "./AddressForm";
import { close } from "ionicons/icons";
import { Address } from "../../models";
import AddAddressButton from "./AddAddressButton";

interface _Props {
    handleNewAddress: Function
}

const AddAddressCard: React.FC<_Props> = ({handleNewAddress}) => {
    const [showModal, setShowModal] = useState(false);

    const handleSubmit = (address: Address) => {
        handleNewAddress(address);
        setShowModal(false);
    }

    return (
        <>
            <AddAddressButton onSubmit={() => setShowModal(true)} />
            <IonModal backdropDismiss={false} isOpen={showModal} id="termsAndConditionsModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Address
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <AddressForm onSubmit={handleSubmit} />
                </IonContent>
            </IonModal>
        </>
    );
};

export default AddAddressCard;
