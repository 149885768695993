import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../../context/PersonContext";
import ErrorAlert from "../../Errors/ErrorAlert";
import { BeddingRequest, Event, EventEntry, EventStallType, StablingRequest } from "../../../models";
import Spinner from "../../Spinners/Spinner";
import { IonCol, IonRow } from "@ionic/react";
import { getEventStallTypesByEventId } from "../../../utilities/eventStallType/EventStallType";
import { getStablingRequestsByEntryId } from "../../../utilities/stablingRequest/StablingRequest";
import ViewStablingRequestCard from "./../EntryStabling/ViewStablingRequestCard";
import { getBeddingRequestsByEntryId } from "../../../utilities/beddingRequest/BeddingRequest";
import ViewBeddingRequestCard from "../EntryStabling/ViewBeddingRequestCard";

interface _Props {
    event: Event
    entry?: EventEntry
}

const DisplayStablingInfo: React.FC<_Props> = ({event, entry}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");
    const [eventStallTypes, setEventStallTypes] = useState<EventStallType[] | null | undefined>();
    const [previousStablingRequests, setPreviousStablingRequests] = useState<StablingRequest[] | null | undefined>();
    const [previousBeddingRequests, setPreviousBeddingRequests] = useState<BeddingRequest[] | null | undefined>();

    const getEventStallTypes = async (event: Event) => {
        const queryResult = await getEventStallTypesByEventId(event.id);
        if (queryResult.isSuccess) {
            setEventStallTypes(queryResult.result);
        }
    }

    async function getPreviousRequests(entry: EventEntry) {
        const queryResult = await getStablingRequestsByEntryId(entry.id);
        if (queryResult.isSuccess) {
            setPreviousStablingRequests(queryResult.result);
        }
    }

    async function getPreviousBeddingRequests(entry: EventEntry) {
        const queryResult = await getBeddingRequestsByEntryId(entry.id);
        if (queryResult.isSuccess) {
            setPreviousBeddingRequests(queryResult.result);
        }
    }

    useEffect(() => {    
        if (entry) {
            getPreviousRequests(entry);
            getPreviousBeddingRequests(entry);
        }
    }, [entry]);

    useEffect(() => {
        if (event) {
            getEventStallTypes(event);
        }
    }, [event]);

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {isLoading ?
                <Spinner/>
                :
                <>
                    {eventStallTypes ?
                        <>
                            {(previousStablingRequests && previousStablingRequests?.length > 0) && (
                                <>
                                    {previousStablingRequests?.map((sr: StablingRequest, index: number) => (
                                        <div key={index}>
                                            <ViewStablingRequestCard event={event} stablingRequest={sr}  />
                                        </div>
                                    ))}
                                </>
                            )}
                            {(previousBeddingRequests && previousBeddingRequests?.length > 0) && (
                                <>
                                    {previousBeddingRequests?.map((br: BeddingRequest, index: number) => (
                                        <div key={index}>
                                            <ViewBeddingRequestCard event={event} beddingRequest={br}  />
                                        </div>
                                    ))}
                                </>
                            )}
                        </>
                        :
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12" className="ion-text-center">
                                <p>This event does not offer stabling.</p>
                            </IonCol>
                        </IonRow>
                    }
                </> 
            }
        </>
    );
};

export default DisplayStablingInfo;