import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { Event, EventEntry, Barn } from "../../models";
import { getEventEntriesByEventId } from "../../utilities/eventEntry/EventEntry";
import Spinner from "../Spinners/Spinner";

interface _Props {
    onSelect: Function
    event: (Event | null)
}

interface formattedOption {
    label: string
    value: string
    barn: Barn
}

const SelectFromEventBarns: React.FC<_Props> = ({event, onSelect}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [formattedBarns, setFormattedBarns] = useState<formattedOption[] | null | undefined>();
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();

    const formatBarns = (barns: Barn[]) => {
        let formattedBarns = [];
        for (var i = 0; i < barns.length; i++) {
            let object = {
                value: barns[i].id,
                label: barns[i].name,
                barn: barns[i]
            };
            formattedBarns.push(object);
        }
        setFormattedBarns(formattedBarns);
    }

    useEffect(() => {
        async function getBarns(event: Event) {
            let barnMap = new Map<string, Barn>();
    
            setIsLoading(true);
            const queryResult = await getEventEntriesByEventId(event.id);
            if (queryResult.isSuccess) {
                const allEventEntries: EventEntry[] = queryResult.result;
                if (allEventEntries && allEventEntries.length > 0) {
                    for (let i = 0; i < allEventEntries.length; i++) {
                        const eventEntry = allEventEntries[i];
                        if (eventEntry.barnId) {
                            const barnMapResult = barnMap.get((eventEntry.barnName ? eventEntry.barnName.toLowerCase() : eventEntry.barn?.name ? eventEntry.barn.name.toLowerCase() : ""));
                            if (!barnMapResult && eventEntry.barn) barnMap.set((eventEntry.barnName ? eventEntry.barnName.toLowerCase() : eventEntry.barn?.name ? eventEntry.barn.name.toLowerCase() : ""), eventEntry.barn);
                        }
                    }
                }
            }
    
            let barnArray: Barn[] = [];
            barnMap.forEach((value: Barn) => {
                barnArray.push(value);
            });
    
            barnArray = barnArray.sort((a, b) => a.name.localeCompare(b.name));
    
            formatBarns(barnArray);
            setIsLoading(false);
        }
        
        if (event) getBarns(event);
    }, [event]);

    const handleOnChange = async (event: any) => {
        if (event && event.barn) {
            setCurrentValue(event);
            onSelect(event.barn);
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <>
            {isLoading ? 
                <Spinner />
                :
                <Select
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    value={currentValue}
                    menuPortalTarget={document.body}
                    isClearable
                    options={formattedBarns!}
                    onChange={handleOnChange}
                />
            }
        </>
    );
};

export default SelectFromEventBarns;
