import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event } from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import Spinner from "../../../../../components/Spinners/Spinner";
import EventReportTextAlert from "../../../../../components/Report/EventReportSections.tsx/EventReportTextAlertSection";
import EventReportDailySchedulesSection from "../../../../../components/Report/EventReportSections.tsx/EventReportDailySchedulesSection";
import EventReportClassSheetsSection from "../../../../../components/Report/EventReportSections.tsx/EventReportClassSheetsSection";
import EventReportEntrySection from "../../../../../components/Report/EventReportSections.tsx/EventReportEntrySection";
import EventReportStablingSection from "../../../../../components/Report/EventReportSections.tsx/EventReportStablingSection";
import EventReportResultsSection from "../../../../../components/Report/EventReportSections.tsx/EventReportResultsSection";
import EventReportFinancialSection from "../../../../../components/Report/EventReportSections.tsx/EventReportFinancialSection";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventReportsPage: React.FC<EventPageProps> = ({match}) => {

    const [event, setEvent] = useState<Event>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function getEvent() {
            setIsLoading(true);
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setEvent(queryResult.result);
            }
            setIsLoading(false);
        }

        getEvent();
    }, [match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header isEventPage={event ? true : false} event={event ? event : undefined} />
            <IonContent>
                <PageTitle title={event ? event.name : "Reports"} />
                {isLoading ?
                    <IonRow>
                        <IonCol>
                            <Spinner />
                        </IonCol>
                    </IonRow>
                    :
                    <>
                        {event ? 
                            <>
                                {event.eventOptions?.usesTextAlerts && (
                                        <EventReportTextAlert event={event} />
                                    )}
                                    {event.eventOptions?.usesEventManagement && (
                                        <>
                                            <EventReportDailySchedulesSection event={event} />
                                            <EventReportClassSheetsSection event={event} />
                                            <EventReportEntrySection event={event} />
                                            <EventReportStablingSection event={event} />
                                            <EventReportResultsSection event={event} />
                                            <EventReportFinancialSection event={event} />
                                        </>
                                    )}
                            </>
                            :
                            <p>No event found.</p>
                        }
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default EventReportsPage;