import React, { useContext, useEffect, useState } from "react";
import Select from 'react-select';
import { PersonContext } from "../../context/PersonContext";
import { onCreateTrainer, onUpdateTrainer } from "../../graphql/subscriptions";
import { Barn, Trainer } from "../../models";
import { getTrainersByUserByBarn } from "../../utilities/trainer/TrainersByBarn";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import ErrorAlert from "../Errors/ErrorAlert";

interface _Props {
    onSelect: Function
    selectedValue?: (string | null)
    barn?: (Barn | null)
}

interface formattedOption {
    label: string
    value: string
    trainer: Trainer
}

const SelectTrainer: React.FC<_Props> = ({barn, onSelect, selectedValue}) => {
    const user = useContext(PersonContext);

    const trainerSubscription = useSubscriptionByItself({
        config: {
            query: onCreateTrainer,
            key: "onCreateTrainer"
        }
    });

    const trainerUpdateSubscription = useSubscriptionByItself({
        config: {
            query: onUpdateTrainer,
            key: "onUpdateTrainer"
        }
    });

    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    const [currentUpdateSubscriptionItem, setUpdateCurrentSubscriptionItem] = useState<any>();
    const [formattedTrainers, setFormattedTrainers] = useState<formattedOption[] | null | undefined>();
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [error, setError] = useState("");

    async function getTrainers() {
        const trainers = await getTrainersByUserByBarn(user, barn);
        formatTrainers(trainers);
    }

    const formatTrainers = (trainers: Trainer[]) => {
        let formattedTrainers = [];
        for (var i = 0; i < trainers.length; i++) {
            let object = {
                value: trainers[i].id,
                label: trainers[i].name,
                trainer: trainers[i]
            };
            formattedTrainers.push(object);
        }
        setFormattedTrainers(formattedTrainers);
        if (selectedValue) findValueInList(selectedValue, formattedTrainers);
    }

    const findValueInList = (value: string, trainerList?: formattedOption[]) => {
        let optionArray = trainerList || formattedTrainers;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        getTrainers();
    }, [barn]);

    useEffect(() => {
        getTrainers();
    }, []);

    useEffect(() => {
        if (trainerSubscription && trainerSubscription[0] !== undefined && trainerSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(trainerSubscription[0]);
            getTrainers();
        }
    }, [trainerSubscription]);

    useEffect(() => {
        if (trainerUpdateSubscription && trainerUpdateSubscription[0] !== undefined && trainerUpdateSubscription[0] !== currentUpdateSubscriptionItem) {
            setCurrentSubscriptionItem(trainerUpdateSubscription[0]);
            getTrainers();
        }
    }, [trainerUpdateSubscription]);

    useEffect(() => {
        async function setValue() {
            if (selectedValue) {
                if (!formattedTrainers) {
                    await getTrainers();
                    findValueInList(selectedValue);
                } else {
                    findValueInList(selectedValue);
                }
                
            } else {
                setCurrentValue(undefined);
            }
        }
        setValue();
    }, [selectedValue]);

    const handleOnChange = async (event: any) => {
        if (event && event.trainer) {
            setCurrentValue(event);
            onSelect(event.trainer);
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            <Select
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                // defaultValue={currentValue}
                value={currentValue}
                menuPortalTarget={document.body}
                isClearable
                options={formattedTrainers!}
                onChange={handleOnChange}
            />
        </>
    );
};

export default SelectTrainer;
