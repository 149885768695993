import moment from "moment";
import { Rider } from "../../models";

export function getRiderAmateurStatus(rider: Rider): string {
    const isProfessional = rider.isProfessional;
    if (isProfessional) return "P";
    
    // if (rider.age) {
    //     if (rider.age > 18) return "A";
    //     else if (rider.age < 18) return rider.age.toString();
    //     else return "";
    // }

    const birthdate = rider.birthdate;
    if (!birthdate) {
        return "";
    }

    const currentDate = moment(new Date());
    const markerYear = currentDate.year() - 18;
    const markerDate = "December 1, " + markerYear;

    if (moment(birthdate).isBefore(markerDate)) {
        return "A";
    } else {
        return moment(new Date()).diff(birthdate, "years").toString();
    }
}