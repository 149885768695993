import moment from "moment";
import { EventEntryFee, Fee } from "../../models";

export function isAfterEventFeeStartDate(eventEntryFee: EventEntryFee) {

    if (eventEntryFee.fee) {
        const fee: Fee = eventEntryFee.fee;

        if (fee.isStartedOnEntryDate) {
            if (fee.startDate) {
                let startDate: string = fee.startDate;

                if (fee.startTime) {
                    startDate = moment(startDate + ' ' + fee.startTime).format("YYYY-MM-DDThh:mm:ss.sssZ")
                }

                const entry = eventEntryFee.entry;
                let entrySubmissionDate: string = entry?.createdOn || "";

                if (!entrySubmissionDate || entrySubmissionDate === "") {
                    // Entry has not been submitted yet. Use today's date as check
                    entrySubmissionDate = moment(new Date()).format("YYYY-MM-DDThh:mm:ss.sssZ");
                } 

                if (entrySubmissionDate === startDate || entrySubmissionDate > startDate) {
                    // Hit the start date, include this fee
                    return true;
                }
            }
        }
    }

    return false;
}