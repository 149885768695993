import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventRulesInput, CreateEventRulesMutation, DeleteEventRulesInput, DeleteEventRulesMutation, GetEventRulesQuery, ListEventRulesQuery, UpdateEventRulesInput, UpdateEventRulesMutation } from "../../API";
import moment from "moment";
import { EventRules } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventRules. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventRules.
*/
export async function createEventRules(input: CreateEventRulesInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventRules", "No input", "Create EventRules received no input.");
   try {
        const fullInput: CreateEventRulesInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventRules, { input: fullInput }))) as GraphQLResult<CreateEventRulesMutation>;
       else result = (await API.graphql({
           query: mutations.createEventRules,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventRulesMutation>;
       const eventRules = result.data?.createEventRules;
       return formatResult(true, "EventRules", eventRules, "Successfully created the eventRules.");
   } catch (error: any) {
       return formatResult(false, "EventRules", error, "Error creating record in the database for type: eventRules");
   }
}

/**
* Handle updating a new record in the database for type: eventRules. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventRules.
*/
export async function updateEventRules(input: UpdateEventRulesInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventRules", "No input", "Update EventRules received no input.");
   try {
        const fullInput: UpdateEventRulesInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventRules, { input: fullInput }))) as GraphQLResult<UpdateEventRulesMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventRules,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventRulesMutation>;
       const eventRules = result.data?.updateEventRules;
       return formatResult(true, "EventRules", eventRules, "Successfully updated the eventRules.");
   } catch (error: any) {
       return formatResult(false, "EventRules", error, "Error updating record in the database for type: eventRules");
   }
}

/**
* Handle deleting a new record in the database for type: eventRules. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventRules.
*/
export async function deleteEventRules(input: DeleteEventRulesInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventRules", "No input", "Delete EventRules received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventRules, { input: input }))) as GraphQLResult<DeleteEventRulesMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventRules,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventRulesMutation>;
       const eventRules = result.data?.deleteEventRules;
       return formatResult(true, "EventRules", eventRules, "Successfully deleted the eventRules.");
   } catch (error: any) {
       return formatResult(false, "EventRules", error, "Error deleting record in the database for type: eventRules");
   }
}

/**
* Get all records in the database for type: eventRules. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventRules object
*/
export async function getAllEventRuless(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventRules,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventRulesQuery>;

        let items = result.data?.listEventRules?.items as EventRules[];
        let nextToken = result.data?.listEventRules?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventRules,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventRulesQuery>;

            const nextItems = nextResult.data?.listEventRules?.items as EventRules[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventRules?.nextToken;
        }

        return formatResult(true, "EventRules", items, "Successfully got the EventRuless.");
    } catch (error: any) {
        return formatResult(false, "EventRules", error, "Error reading record in the database for type: eventRuless");
    }
}

/**
* Read a specific record in the database for type: eventRules. 
* 
* @param {string}  id                  The eventRules id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventRules object
*/
export async function getEventRulesById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventRules,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventRulesQuery>;
       const eventRules = result.data?.getEventRules;
       return formatResult(true, "EventRules", eventRules, "Successfully got the eventRules.");
   } catch (error: any) {
       return formatResult(false, "EventRules", error, "Error reading record in the database for type: eventRules");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventRules. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventRules object
*/
export async function getEventRulesByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventRules,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventRulesQuery>;

        let items = result.data?.listEventRules?.items as EventRules[];
        let nextToken = result.data?.listEventRules?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventRules,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventRulesQuery>;

            const nextItems = nextResult.data?.listEventRules?.items as EventRules[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventRules?.nextToken;
        }

        return formatResult(true, "EventRules", items, "Successfully got the EventRuless.");
    } catch (error: any) {
        return formatResult(false, "EventRules", error, "Error reading record in the database for type: eventRuless");
    }
}