import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event} from "../../../../../models";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import logo from "../../../../../assets/img/brand/LogoWithTextBlock.png";

import QRCode from "qrcode";
import { copyOutline } from "ionicons/icons";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

let subscription: any;

const EventResourcesPage: React.FC<EventPageProps> = ({match}) => {

    const [eventURL, setEventURL] = useState<string>("");
    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                const event = queryResult.result;
                setCurrentEvent(event);

                if (event.id) {
                    const URL = "www.ringsidepro.com/show/" + event.id;
                    setEventURL(URL);
                    QRCode.toCanvas(document.getElementById('canvas'), URL, function (error: any) {
                        if (error) console.error(error)
                    });
                } else {
                    setError("Sorry, we could not generate a QR Code for this event.");
                }
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        getEvent();
    }, [match, match.params.id]);
    
    useEffect(() => {
        return () => {
            if (subscription) subscription.unsubscribe();
        }
    }, [currentEvent]);

    function downloadQRCode(){
        var canvas = document.getElementById("canvas") as HTMLCanvasElement;
        if (canvas) {
            var url = canvas.toDataURL("image/png");
            var link = document.createElement('a');
            link.download = (currentEvent?.name + " " || "") + 'QR Code.png';
            link.href = url;
            link.click();
        } else {
            setError("Sorry, we could not download the QR Code.");
        }
    }

    const getXOffset = (pageWidth: number, textWidth: number, fontSize: number, scaleFactor: number) => {
        const totalTextWidth = textWidth*fontSize/scaleFactor;
        const startingWidth = ( pageWidth - totalTextWidth ) / 2;
        return startingWidth;
    }

    async function generateEventFlyer(event: Event) {
        const doc = new jsPDF();        
        const URL = eventURL || "www.ringsidepro.com/show/" + event.id;
        var QRCode = require('qrcode')
        QRCode.toDataURL(URL, {type: "png"})
            .then((img: any) => {
                const pageWidth = doc.internal.pageSize.width;
                
                // Add the Logo to the top of the page
                doc.addImage(logo, "PNG", 40, 20, 115, 40);

                // Set initial Y-Coord for text 
                var currentY = 80;

                doc.setTextColor(58,68,84);
                doc.setFontSize(36);
                var text = "Sign Up to Receive Text Alerts",
                    xOffset = getXOffset(pageWidth, doc.getStringUnitWidth(text), doc.internal.getFontSize(), doc.internal.scaleFactor); 
                doc.text(text, xOffset, currentY);
                currentY = currentY + 25;

                doc.setTextColor(115,164,211);
                doc.setFontSize(32);
                var textArray = doc.splitTextToSize("from " + event.name, 180);
                var xOffset1 = getXOffset(pageWidth, doc.getStringUnitWidth(textArray[0]), doc.internal.getFontSize(), doc.internal.scaleFactor); 
                doc.text(textArray[0], xOffset1, currentY);
                if (textArray[1]){
                    currentY = currentY + 15;
                    var xOffset2 = getXOffset(pageWidth, doc.getStringUnitWidth(textArray[1]), doc.internal.getFontSize(), doc.internal.scaleFactor); 
                    doc.text(textArray[1], xOffset2, currentY);
                }
                if (textArray[2]){
                    currentY = currentY + 15;
                    var xOffset3 = getXOffset(pageWidth, doc.getStringUnitWidth(textArray[2]), doc.internal.getFontSize(), doc.internal.scaleFactor); 
                    doc.text(textArray[2], xOffset3, currentY);
                }
                currentY = currentY + 25;

                doc.setTextColor(66,89,116);
                doc.setFontSize(28);
                text = "You'll receive show announcements";
                xOffset = getXOffset(pageWidth, doc.getStringUnitWidth(text), doc.internal.getFontSize(), doc.internal.scaleFactor); 
                doc.text(text, xOffset, currentY);
                currentY = currentY + 10;
                text = "straight to your phone for FREE!";
                xOffset = getXOffset(pageWidth, doc.getStringUnitWidth(text), doc.internal.getFontSize(), doc.internal.scaleFactor); 
                doc.text(text, xOffset, currentY);
                currentY = currentY + 20;
                doc.setTextColor(115,164,211);
                doc.setFontSize(16);
                text = 'Want a break? Reply "PAUSE" to stop the alerts.';
                xOffset = getXOffset(pageWidth, doc.getStringUnitWidth(text), doc.internal.getFontSize(), doc.internal.scaleFactor); 
                doc.text(text, xOffset, currentY);
                currentY = currentY + 12;
                doc.setTextColor(58,68,84);
                doc.setFontSize(20);
                text = "Sign up at www.RingSidePro.com";
                xOffset = getXOffset(pageWidth, doc.getStringUnitWidth(text), doc.internal.getFontSize(), doc.internal.scaleFactor); 
                doc.text(text, xOffset, currentY);
                currentY = currentY + 3;
                
                doc.addImage(img, 'png', 68, currentY, 75, 75);
                currentY = currentY + 80;

                doc.setFontSize(18);
                text = "Simply hover your phone camera over the square!";
                xOffset = getXOffset(pageWidth, doc.getStringUnitWidth(text), doc.internal.getFontSize(), doc.internal.scaleFactor); 
                doc.text(text, xOffset, currentY);
                currentY = currentY + 10;

                doc.setTextColor(115,164,211);
                text = "No app necessary!";
                xOffset = getXOffset(pageWidth, doc.getStringUnitWidth(text), doc.internal.getFontSize(), doc.internal.scaleFactor); 
                doc.text(text, xOffset, currentY);
            
                // we define the name of our PDF file.
                const fileName = event.nickname ? (event.nickname + " text alert flyer.pdf") : (event.name ? event.name + " text alert flyer.pdf" : "text alert flyer.pdf");
                doc.save(fileName);
            })   
    }

    const handleCopyURLClick = async () => {
        try {
            // Use navigator to copy to the user's clipboard
            await navigator.clipboard.writeText(eventURL);
        } catch (err: any) {
            console.error("Failed to copy");
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent ? (currentEvent.nickname ? currentEvent.nickname : currentEvent.name) : "Event"} />
                {currentEvent && (
                    <>
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonRow id="eventName" className="ion-align-items-stretch">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>QR Code</IonCardTitle>
                                    <IonCardContent>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="12" className="ion-text-center">
                                                <canvas id="canvas"></canvas>
                                            </IonCol>
                                            <IonCol size="12" className="ion-text-center">
                                                <IonButton onClick={downloadQRCode}>Download QR Code</IonButton>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="12" className="ion-text-center">
                                                <p>
                                                    The QR code will take users to your event's unique page: {" "}
                                                    <a href={eventURL} target="_blank" rel="noreferrer">
                                                        <span className="link">{eventURL} {" "}</span>
                                                    </a> 
                                                    <span className="link">
                                                        <IonIcon onClick={handleCopyURLClick} icon={copyOutline} aria-label="Copy to Clipboard" />
                                                    </span>
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        {currentEvent.eventOptions?.usesTextAlerts && (
                            <IonRow id="eventName" className="ion-align-items-stretch">
                                <IonCol size="12">
                                    <IonCard mode="md" className="ion-padding bg-white stretch">
                                        <IonCardTitle>Event Flyer</IonCardTitle>
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>
                                                    <p>
                                                        <span onClick={() => generateEventFlyer(currentEvent)} className="link">Download the flyer</span> 
                                                        {" "}with the instructions of how to sign up for text alerts using the QR Code!
                                                    </p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol size="12" className="ion-text-center">
                                                    <IonButton onClick={() => generateEventFlyer(currentEvent)}>Download Pre-Made Flyer</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        )}
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventResourcesPage;