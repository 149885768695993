import {
    IonContent,
    IonPage,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import { Organization } from "../../../../../models";
import OrganizationSeasonPageInfo from "../../../../../components/Season/OrganizationSeasonPageInfo";
import Spinner from "../../../../../components/Spinners/Spinner";

interface _Props extends RouteComponentProps<{
    id: string;
}> {}


const OrganizationSeasonPage: React.FC<_Props> = ({match}) => {

    const [organization, setOrganization] = useState<Organization>();
    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        async function getOrganization() {
            setIsLoading(true);
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                const org: Organization = queryResult.result;
                setOrganization(org);
            } else {
                setError(queryResult.message);
            }
            setIsLoading(false);
        }
        getOrganization();
    }, [match, match.params.id]);


    return (
        <IonPage className="bg-light" id="season-page">
            <Header />
            <IonContent>
                {error && <ErrorAlert width="12" error={error} />}
                {isLoading && <Spinner/>}
                <PageTitle title={`${(organization?.nickname || organization?.name) ?? ""}`}/>
                {organization ?
                    <OrganizationSeasonPageInfo organization={organization} />
                :   <p>Loading Seasons....</p>
                }
            </IonContent>
        </IonPage>
    );
};

export default OrganizationSeasonPage;