import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Clinician, Feedback, S3Object, Submission, SubmissionFeedback} from "../../../models";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import {getSubmissionFeedbackById} from "../../../utilities/submissionFeedback/SubmissionFeedback";
import { getSubmissionById } from "../../../utilities/submission/Submission";
import { getFeedbackById } from "../../../utilities/feedback/Feedback";
import { getClinicianById } from "../../../utilities/clinician/Clinician";
import VideoPlayer from "../../../components/s3Object/VideoPlayer";

interface SubmissionFeedbackPageProps extends RouteComponentProps<{
    id: string;
}> {}

const ViewSubmissionFeedbackPage: React.FC<SubmissionFeedbackPageProps> = ({match}) => {

    const [currentSubmissionFeedback, setCurrentSubmissionFeedback] = useState<SubmissionFeedback | null | undefined>();
    const [currentSubmission, setCurrentSubmission] = useState<Submission | null | undefined>();
    const [currentFeedback, setCurrentFeedback] = useState<Feedback | null | undefined>();
    const [currentClinician, setCurrentClinician] = useState<Clinician | null | undefined>();
    const [currentVideo, setCurrentVideo] = useState<S3Object | null | null | undefined>();
    const [error, setError] = useState("");

    async function getSubmissionFeedback() {
        const queryResult = await getSubmissionFeedbackById(match.params.id);
        if (queryResult.isSuccess) {
            const submissionFeedback: SubmissionFeedback = queryResult.result;
            if (submissionFeedback) {
                setCurrentSubmissionFeedback(submissionFeedback);
                if (submissionFeedback.submission?.id) getSubmission(submissionFeedback.submission?.id);
                if (submissionFeedback.feedbackId) getFeedback(submissionFeedback.feedbackId);
                if (submissionFeedback.clinicianId) getClinician(submissionFeedback.clinicianId);
            }
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getSubmission(id: string) {
        const queryResult = await getSubmissionById(id);
        if (queryResult.isSuccess) {
            const submission: Submission = queryResult.result;
            setCurrentSubmission(submission);
            setCurrentVideo(submission.video);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getFeedback(id: string) {
        const queryResult = await getFeedbackById(id);
        if (queryResult.isSuccess) {
            setCurrentFeedback(queryResult.result);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getClinician(id: string) {
        const queryResult = await getClinicianById(id);
        if (queryResult.isSuccess) {
            setCurrentClinician(queryResult.result);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    useEffect(() => {
        getSubmissionFeedback();
    }, [match, match.params.id]);
    
   
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Feedback" />
                {currentFeedback && (
                    <>
                        <IonRow id="submissionFeedbackName">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        Status
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <h5 color={currentFeedback.status === "complete" ? "success" : "primary"}>{currentFeedback.status}</h5>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {currentVideo && (
                    <>
                        <IonRow id="submissionFeedbackName">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        Video
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>
                                                <VideoPlayer videoObject={currentVideo} />
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {currentSubmission && (
                    <>
                        <IonRow id="submissionFeedbackName">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        Video Description
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>
                                                <p>{currentSubmission.description}</p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {currentFeedback && (
                    <>
                        <IonRow id="feedback">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        Feedback
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>
                                                <h2>Technical Feedback</h2>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <p>{currentFeedback.technical}</p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <h2>Style Feedback</h2>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <p>{currentFeedback.style}</p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <h2>Turnout Feedback</h2>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <p>{currentFeedback.turnout}</p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <h2>Other Feedback</h2>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <p>{currentFeedback.general}</p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default ViewSubmissionFeedbackPage;