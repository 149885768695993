import {
    IonButton,
    IonCol,
    IonItem,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonToggle,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Spinner from "../../Spinners/Spinner";
import { PersonContext } from "../../../context/PersonContext";
import { Event, EventAlertSetting } from "../../../models";
import ErrorAlert from "../../Errors/ErrorAlert";
import { createEventAlertSetting, getEventAlertSettingByEventId, updateEventAlertSetting } from "../../../utilities/eventAlertSetting/EventAlertSetting";
import { CreateEventAlertSettingInput, UpdateEventAlertSettingInput, UpdateEventInput } from "../../../API";
import { updateEvent } from "../../../utilities/events/Event";

interface _Props {
    event: Event
}

const EventAlertSettingsForm: React.FC<_Props> = ({event}) => {
    const user = useContext(PersonContext);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [eventAlertSetting, setEventAlertSetting] = useState<EventAlertSetting | null | undefined>();
    const [alertSetting, setAlertSetting] = useState("");
    const [isAllowingIncomingTexts, setIsAllowingIncomingTexts] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEventAlertSetting(event: Event) {
            const queryResult = await getEventAlertSettingByEventId(event.id);
            if (queryResult.isSuccess) {
                setEventAlertSetting(queryResult.result);
                setAlertSetting(queryResult.result.useDescription);
            }
        }

        if (event) {
            if (event.eventOptions?.acceptsIncomingTextAlerts) setIsAllowingIncomingTexts(true);
            getEventAlertSetting(event);
        }
    }, [event]);

    const handleSettingChoice = (setting: string) => {
        setIsDisabled(false);
        setAlertSetting(setting);
    }

    const updateCurrentEvent = async () => {
        try {
            if (eventAlertSetting) {
                const input: UpdateEventAlertSettingInput = {
                    id: eventAlertSetting.id,
                    eventId: event.id,
                    useDescription: alertSetting,
                    createdBy: user.id
                }
                const updateResult = await updateEventAlertSetting(input);
                if (updateResult.isSuccess) {
                    setEventAlertSetting(updateResult.result);
                } else {
                    setError("Could not update the setting.");
                }
            } else {
                const input: CreateEventAlertSettingInput = {
                    eventId: event.id,
                    useDescription: alertSetting,
                    createdBy: user.id
                }
                const createResult = await createEventAlertSetting(input);
                if (createResult.isSuccess) {
                    setEventAlertSetting(createResult.result);
                } else {
                    setError("Could not create the setting.");
                }
            }

            let eventOptions = event.eventOptions;
            let updatedEventOptions = {
                ...eventOptions,
                acceptsIncomingTextAlerts: isAllowingIncomingTexts
            };
            const eventInput: UpdateEventInput = {
                id: event.id,
                eventOptions: updatedEventOptions
            };
            await updateEvent(eventInput);
        } catch (error: any) {
            setError("Sorry, we could not update the event's name");
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setIsDisabled(true);
        await updateCurrentEvent();
        setIsLoading(false);
    }

    return (
        <form>
            <IonRow>
                {error && <ErrorAlert width="12" error={error} />}
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Choose how you will use the alerts</IonLabel>
                    </IonItem>
                    <IonRadioGroup
                        id="settingsGroup"
                        value={alertSetting}
                        onIonChange={e => handleSettingChoice(e.detail.value)}
                    >
                        <IonItem>
                            <IonLabel>
                                <IonRow>
                                    <h3>Premium Level</h3>
                                </IonRow>
                                <IonRow className="ion-text-wrap">
                                    <p>Reminders before the show, ring updates each day, schedule changes, and general announcements.</p>
                                </IonRow>
                            </IonLabel>
                            <IonRadio slot="end" value="premium" color="tertiary" />
                        </IonItem>  
                        <IonItem>
                            <IonLabel>
                                <IonRow>
                                    <h3>Standard Level</h3>
                                </IonRow>
                                <IonRow className="ion-text-wrap">
                                    <p>Reminders before the show, schedule changes, and general announcements.</p>
                                </IonRow>
                            </IonLabel>
                            <IonRadio slot="end" value="standard" color="tertiary" />
                        </IonItem>  
                        <IonItem>
                            <IonLabel>
                                <IonRow>
                                    <h3>Basic Level</h3>
                                </IonRow>
                                <IonRow className="ion-text-wrap">
                                    <p>Reminders before the show and general announcements.</p>
                                </IonRow>
                            </IonLabel>
                            <IonRadio slot="end" value="basic" color="tertiary" />
                        </IonItem>   
                    </IonRadioGroup>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <p>Allow incoming text messages? It costs 1 credit per incoming message.</p>
                    <IonItem>
                        <IonToggle color="tertiary" checked={isAllowingIncomingTexts} onIonChange={e => {setIsDisabled(false); setIsAllowingIncomingTexts(e.detail.checked)}} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            disabled={isDisabled}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            {isDisabled ? "Saved" : "Save"}
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </form>
    );
};

export default EventAlertSettingsForm;