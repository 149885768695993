import { Auth } from "aws-amplify";
import {
    IonButton,
    IonCol,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonTitle
} from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import { formatRolesStringForUserAttribute, formatRolesToStringArray } from "../../utilities/roles/FormatRoles";
import SelectAllButtons from "../Select/SelectAllButtons";
import { UserAttributes } from "../../utilities/user/User";
import { PersonContext } from "../../context/PersonContext";
import { createPersonName, getPersonNameByPersonId, updatePersonName } from "../../utilities/person/PersonName";
import { createRoleRecords } from "../../utilities/roles/CreateRoleRecords";
import { CreatePersonNameInput, UpdatePersonInput, UpdatePersonNameInput } from "../../API";
import { formatDisplayName } from "../../utilities/person/PersonNameFormat";
import { getPersonByPersonId } from "../../utilities/person/Person";
import { updatePerson } from "../../utilities/person/Person";
import { createResponse } from "../../utilities/polls/Response";
import { updateContactInHubspot } from "../../utilities/hubspot/SyncHubspotContacts";
import Spinner from "../Spinners/Spinner";

interface _Props {
    openModal: boolean
}

const initialRoles: string[] = [];

const WelcomeModal: React.FC<_Props> = ({openModal}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [displayName] = useState("");
    const [firstName, setFirstName] = useState(user.firstName || "");
    const [lastName, setLastName] = useState(user.lastName || "");
    const [referral, setReferral] = useState("");
    const [rolesToSelectFrom, setRolesToSelectFrom] = useState(initialRoles);
    const [selectedRoles, setSelectedRoles] = useState(initialRoles);

    useEffect(() => {
        setShowModal(openModal);
        setRolesToSelectFrom(formatRolesToStringArray());
    }, [openModal]);

    const handleSelectRoles = (selectedRoles: string[]) => {
        setSelectedRoles(selectedRoles);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        await updateUser();
        await createRoleRecords(user, selectedRoles);
        setShowModal(false);
        setIsLoading(false);
    };

    const handleSkip = () => {
        setShowModal(false);
    };

    const updateUser = async () => {
        await updateUserAttributes();
        await updateCurrentPerson();
        await createPollResponse();
    };

    const updateUserAttributes = async () => {
        const attributes: UserAttributes = {};
        if (firstName) attributes.given_name = firstName;
        if (lastName) attributes.family_name = lastName;
        if (selectedRoles && selectedRoles.length) attributes["custom:roles"] = formatRolesStringForUserAttribute(selectedRoles);
        Auth.currentAuthenticatedUser()
            .then(async (user) => {
                await Auth.updateUserAttributes(user, attributes);
            });
    };

    const updateCurrentPerson = async () => {
        if (!user.id) console.error("ERROR! No person id found.");
        if (firstName || lastName) {
            const nameResult = await getPersonNameByPersonId(user.id);
            if (nameResult.isSuccess) {
                const input: UpdatePersonNameInput = {
                    id: nameResult.result.id,
                    firstName: firstName,
                    lastName: lastName,
                    displayName: formatDisplayName(firstName, "", lastName)
                };
                await updatePersonName(input);
            } else {
                const input: CreatePersonNameInput = {
                    personId: user.id,
                    firstName: firstName,
                    lastName: lastName,
                    displayName: formatDisplayName(firstName, "", lastName)
                };
                await createPersonName(input);
            }

            const personInput: UpdatePersonInput = {
                id: user.id,
                firstName,
                lastName
            };
            await updatePerson(personInput);
        }
        updatePersonRoles();
        updateContactInHubspot(user.email, firstName, lastName, formatRolesStringForUserAttribute(selectedRoles));
    }; 

    const updatePersonRoles = async () => {
        try {
            const queryResult = await getPersonByPersonId(user.id);
            if (queryResult.isSuccess) {
                const person = queryResult.result;
                if (person) {
                    await updatePerson({
                        id: user.id,
                        roles: formatRolesStringForUserAttribute(selectedRoles)
                    });
                }
            }            
        } catch (error: any) {
            console.error("Could not update person roles: ", error);
        }
    }

    const createPollResponse = async () => {
        try {
            if (!referral) return;
            if (!user.id) console.error("ERROR! No person id found.");
            const createResult = await createResponse({
                pollId: "04a99513-b6c7-4ac1-a422-1911c610a0c1",
                personId: user.id,
                value: referral
            });
            if (createResult.isSuccess) {
                return createResult.result;
            }
        } catch (error: any) {
            console.error("Could not create a poll response.", error);
            return null;
        }
    };

    return (
        <IonLabel>
            <IonModal backdropDismiss={false} id="welcomeModal" isOpen={showModal} mode="md">
                <IonContent color="light" className="ion-padding">
                    <IonRow>
                        <IonCol>
                            <IonTitle className="ion-padding-top ion-text-center">
                                <h2>Welcome!</h2>
                                <h5 className="ion-text-wrap">Tell us a little more about yourself:</h5>
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    {isLoading ?
                        <Spinner />
                        :
                        <form>
                            {(!displayName || displayName === "") && (
                                <>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput
                                                    id="firstNameInput"
                                                    name="firstNameInput"
                                                    value={firstName}
                                                    placeholder="First Name"
                                                    onIonChange={e => setFirstName(e.detail.value!)}
                                                />
                                            </IonItem>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput
                                                    id="lastNameInput"
                                                    name="lastNameInput"
                                                    value={lastName}
                                                    placeholder="Last Name"
                                                    onIonChange={e => setLastName(e.detail.value!)}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    {/* <IonRow>
                                        <IonCol>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="discovermethod"
                                                >
                                                    How did you hear about us?
                                                </label>
                                                <Input name="discoverMethodInput" type="select" value={referral} onChange={e => setReferral(e.target.value)}>
                                                    <option>-</option>
                                                    <option>Facebook</option>
                                                    <option>Instagram</option>
                                                    <option>Word of Mouth</option>
                                                    <option>Horse Show</option>
                                                    <option>Email</option>
                                                    <option>Other</option>
                                                </Input>
                                            </FormGroup>
                                        </IonCol>
                                    </IonRow> */}
                                </>
                            )}
                            {!user.roles && (
                                <>
                                    <IonRow>
                                        <IonTitle className="ion-padding-top ion-text-center">
                                            <h5>I am:</h5>
                                        </IonTitle>
                                    </IonRow>
                                    <SelectAllButtons buttons={rolesToSelectFrom} onSelect={handleSelectRoles}/>
                                    <IonRow>
                                        <IonTitle className="ion-padding-top ion-text-center">
                                            <p className="description">*select all that apply</p>
                                        </IonTitle>
                                    </IonRow>
                                </>
                            )}
                            <IonRow className="ion-padding-top">
                                <IonCol className="ion-text-center">
                                    <IonButton id="welcomeModalSubmit" size="large" color="tertiary" onClick={() => handleSubmit()}>
                                        Get Started
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </form>
                    }
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton id="welcomeModalSkip" fill="clear" color="tertiary" onClick={() => handleSkip()}>
                                Skip
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
        </IonLabel>
    );
};

export default WelcomeModal;
