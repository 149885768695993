import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventInput, CreateEventMutation, DeleteEventInput, DeleteEventMutation, GetEventQuery, ListEventsQuery, UpdateEventInput, UpdateEventMutation } from "../../API";
import moment from "moment";
import { addEventToSearch, removeEventFromSearch } from "./EventSearch";
import { Event } from "../../models";
import { sortEventsByDate } from "./SortEvents";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: event. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created event.
*/
export async function createEvent(input: CreateEventInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Event", "No input", "Create Event received no input.");
   try {
        const fullInput: CreateEventInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEvent, { input: fullInput }))) as GraphQLResult<CreateEventMutation>;
       else result = (await API.graphql({
           query: mutations.createEvent,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventMutation>;
       const event = result.data?.createEvent;
       return formatResult(true, "Event", event, "Successfully created the event.");
   } catch (error: any) {
       return formatResult(false, "Event", error, "Error creating record in the database for type: event");
   }
}

/**
* Handle updating a new record in the database for type: event. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated event.
*/
export async function updateEvent(input: UpdateEventInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "Event", "No input", "Update Event received no input.");
    try {
            const fullInput: UpdateEventInput = {
                ...input,
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEvent, { input: fullInput }))) as GraphQLResult<UpdateEventMutation>;
        else result = (await API.graphql({
            query: mutations.updateEvent,
            variables: {
                input: fullInput
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<UpdateEventMutation>;
        const event = result.data?.updateEvent as Event;
        if (event && event.status && event.status !== "draft") {
            // When switching event from draft to another status, add it to index
            addEventToSearch(event);
        } else if (event && event.status && event.status === "draft") {
            // When switching event to draft status, remove it from index
            removeEventFromSearch(event);
        }
        return formatResult(true, "Event", event, "Successfully updated the event.");
    } catch (error: any) {
        return formatResult(false, "Event", error, "Error updating record in the database for type: event");
    }
}

/**
* Handle deleting a new record in the database for type: event. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted event.
*/
export async function deleteEvent(input: DeleteEventInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Event", "No input", "Delete Event received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEvent, { input: input }))) as GraphQLResult<DeleteEventMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEvent,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventMutation>;
       const event = result.data?.deleteEvent;
       return formatResult(true, "Event", event, "Successfully deleted the event.");
   } catch (error: any) {
       return formatResult(false, "Event", error, "Error deleting record in the database for type: event");
   }
}

/**
* Get all records in the database for type: event. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the event object
*/
export async function getAllEvents(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEvents,
            variables: {
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventsQuery>;
        
        let items = result.data?.listEvents?.items as Event[];
        let nextToken = result.data?.listEvents?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEvents,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventsQuery>;

            const nextItems = nextResult.data?.listEvents?.items as Event[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEvents?.nextToken;
        }

        const sorted = sortEventsByDate(items);
        if (sorted) {
            return formatResult(true, "Event", sorted, "Successfully got the events.");
        } else {
            return formatResult(true, "Event", items, "Successfully got the events.");
        }
    } catch (error: any) {
        return formatResult(false, "Event", error, "Error reading record in the database for type: events");
    }
}

/**
* Get all records in the database for type: event. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the event object
*/
export async function getAllPublishedEvents(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {status: {ne: "draft"}};
        const result = (await API.graphql({
            query: queries.listEvents,
            variables: {
                filter,
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventsQuery>;

        let items = result.data?.listEvents?.items as Event[];
        let nextToken = result.data?.listEvents?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEvents,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventsQuery>;

            const nextItems = nextResult.data?.listEvents?.items as Event[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEvents?.nextToken;
        }

        const sorted = sortEventsByDate(items);
        if (sorted) {
            return formatResult(true, "Event", sorted, "Successfully got the events.");
        } else {
            return formatResult(true, "Event", items, "Successfully got the events.");
        }
    } catch (error: any) {
        return formatResult(false, "Event", error, "Error reading record in the database for type: events");
    }
}

/**
* Read a specific record in the database for type: event. 
* 
* @param {string}  id                  The event id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the event object
*/
export async function getEventById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEvent,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventQuery>;
       const event = result.data?.getEvent;
       return formatResult(true, "Event", event, "Successfully got the event.");
   } catch (error: any) {
       return formatResult(false, "Event", error, "Error reading record in the database for type: event");
   }
}

/**
* Get all records in the database that match the given criteria for type: event. 
* 
* @param {string}  name                The state name of the event.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the event object
*/
export async function getEventByName(name: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {name: {eq: name}};
        const result = (await API.graphql({
            query: queries.listEvents,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventsQuery>;
        
        let items = result.data?.listEvents?.items as Event[];
        let nextToken = result.data?.listEvents?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listEvents,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventsQuery>;

            const nextItems = nextResult.data?.listEvents?.items as Event[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEvents?.nextToken;
        }

        const events = items;
        if (events && events[0]) return formatResult(true, "Event", events[0], "Successfully got the event.");
        else return formatResult(false, "Event", null, "Could not find the event.");
    } catch (error: any) {
        return formatResult(false, "Event", error, "Error reading record in the database for type: event");
    }
}

/**
* Get all records in the database that match the given criteria for type: event. 
* 
* @param {string}  personId            The person id of the "owner" of the event
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the event object
*/
export async function getEventsByCreator(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {createdBy: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listEvents,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventsQuery>;
        let items = result.data?.listEvents?.items as Event[];
        let nextToken = result.data?.listEvents?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEvents,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventsQuery>;

            const nextItems = nextResult.data?.listEvents?.items as Event[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEvents?.nextToken;
        }
        
        const sorted = sortEventsByDate(items);
        if (sorted) {
            return formatResult(true, "Event", sorted, "Successfully got the events.");
        } else {
            return formatResult(true, "Event", items, "Successfully got the events.");
        }
    } catch (error: any) {
        return formatResult(false, "Event", error, "Error reading record in the database for type: events");
    }
}

/**
* Get all records in the database that match the given criteria for type: event. 
* 
* @param {string}  organizationId      The organization id of the "owner" of the event
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the event object
*/
export async function getEventsByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventsByOrganizationIdByStartDate,
            variables: {
                limit: 1000,
                organizationId: organizationId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventsByOrganizationIdByStartDate?.items as Event[];
        let nextToken = result.data?.eventsByOrganizationIdByStartDate?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventsByOrganizationIdByStartDate,
                variables: {
                    limit: 100,
                    organizationId: organizationId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventsByOrganizationIdByStartDate?.items as Event[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventsByOrganizationIdByStartDate?.nextToken;
        }
        
        const sorted = sortEventsByDate(items);
        if (sorted) {
            return formatResult(true, "Event", sorted, "Successfully got the events.");
        } else {
            return formatResult(true, "Event", items, "Successfully got the events.");
        }
    } catch (error: any) {
        return formatResult(false, "Event", error, "Error reading record in the database for type: events");
    }
}

/**
* Get all records in the database that match the given criteria for type: event. 
* 
* @param {string}  organizationId      The organization id of the "owner" of the event
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the event object
*/
export async function getEventsByOrganizationIdByYear(organizationId: string, year: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventsByOrganizationIdByStartDate,
            variables: {
                limit: 1000,
                organizationId,
                startDate: {beginsWith: year}
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventsByOrganizationIdByStartDate?.items as Event[];
        let nextToken = result.data?.eventsByOrganizationIdByStartDate?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventsByOrganizationIdByStartDate,
                variables: {
                    limit: 100,
                    organizationId,
                    startDate: {beginsWith: year},
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventsByOrganizationIdByStartDate?.items as Event[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventsByOrganizationIdByStartDate?.nextToken;
        }
        
        const sorted = sortEventsByDate(items);
        if (sorted) {
            return formatResult(true, "Event", sorted, "Successfully got the events.");
        } else {
            return formatResult(true, "Event", items, "Successfully got the events.");
        }
    } catch (error: any) {
        return formatResult(false, "Event", error, "Error reading record in the database for type: events");
    }
}