import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import moment from "moment";
import React, { useContext, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { Barn, Rider } from "../../models";
import { createRider, updateRider} from "../../utilities/rider/Rider";
import ErrorAlert from "../Errors/ErrorAlert";
import RiderSelectStatus from "./RiderSelectStatus";
import { CreateRiderInput,  UpdateRiderInput } from "../../API";
import SuccessBanner from "../Banners/SuccessBanner";
import BirthdateInput from "../DatePicker/BirthdateInput";
import { updateBirthday } from "../../utilities/personalInformation/BirthDate";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    rider?: (Rider | null)
    selectedBarn?: (Barn | null)
    onChange: Function
}

const QuickAddRiderForm: React.FC<_Props> = ({rider, selectedBarn, onChange}) => {
    const user = useContext(PersonContext);

    const [name, setName] = useState<string>(rider ? rider.name : "");
    const [birthDate, setBirthDate] = useState<string>(rider ? (rider.birthdate ? moment(rider?.birthdate).format("YYYY-MM-DD") : "") : "");
    const [isProfessional, setIsProfessional] = useState<boolean | null | undefined>(rider?.isProfessional);

    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");

    const verifyForm = () => {
        if (!name) {
            setError("Please include a name for the rider");
            return false;
        }
        return true;
    }

    const handleCreateRider = async () => {
        let riderInput: CreateRiderInput = {
            name: name,
            isProfessional: isProfessional,
            createdBy: user.id,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        };
        if (birthDate) {
            riderInput["birthdate"] = birthDate;
            riderInput["age"] = moment().diff(birthDate, 'years');
        }
        if (selectedBarn) {
            riderInput["barnId"] = selectedBarn.id;
            riderInput["barnName"] = selectedBarn.name;
        }
        const createResult = await createRider(riderInput);
        if (createResult.isSuccess) {
            onChange(createResult.result);
            setSuccess("Successfully created the rider.");
        } else {
            setError(createResult.message);
        }
    }

    const handleUpdateRider = async () => {
        if (rider) {
            let riderInput: UpdateRiderInput = {
                id: rider.id,
                name: name,
                isProfessional: isProfessional
            };
            if (birthDate) {
                riderInput["birthdate"] = birthDate;
                riderInput["age"] = moment().diff(birthDate, 'years');
            }
            if (selectedBarn) {
                riderInput["barnId"] = selectedBarn.id;
                riderInput["barnName"] = selectedBarn.name;
            }
            const updateResult = await updateRider(riderInput);
            if (updateResult.isSuccess) {
                onChange(updateResult.result);
                setSuccess("Successfully updated the rider.");

                if (rider.personId) {
                    await updateBirthday(rider.personId, birthDate);
                }
            } else {
                setError(updateResult.message);
            }
        } else {
            setError("No rider to edit.");
        }
    }
    
    const handleAddRider = async () => {
        setError("");
        setSuccess("");
        const isValid = verifyForm();
        if (isValid) {
            await handleCreateRider();
        }
    }

    const handleEditRider = async () => {
        setError("");
        setSuccess("");
        const isValid = verifyForm();
        if (isValid) {
            await handleUpdateRider();
        }
    }

    return (
        <>
            {error && <ErrorAlert error={error} />}
            {success && (
                <IonRow>
                    <IonCol>
                        <SuccessBanner width="12" success={success} />
                    </IonCol>
                </IonRow>
            )}
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <IonItem color="white">
                        <IonLabel position="stacked">Name <RequiredInputIndicator /></IonLabel>
                        <IonInput 
                            type="text"
                            value={name}
                            aria-required={true}
                            onIonChange={e => {
                                setName(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <BirthdateInput value={birthDate} isRequired={true} onChange={(date: string) => setBirthDate(date)} />
                </IonCol>
            </IonRow>
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <RiderSelectStatus rider={rider} onChange={(value: boolean) => setIsProfessional(value)} />
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton
                        className="ion-margin-top"
                        color="tertiary"
                        expand="block"
                        onClick={rider ? handleEditRider : handleAddRider}
                    >
                        {rider ? "Edit Rider" : "Add Rider"}
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default QuickAddRiderForm;