import { SubmissionFeedback } from "../../models";
import { deleteFeedback } from "../feedback/Feedback";
import { deleteSubmission } from "../submission/Submission";
import { deleteSubmissionFeedback, getAllSubmissionFeedbacks } from "../submissionFeedback/SubmissionFeedback";

export async function removeSubmissionFeedback() {
    const submissionFeedbackResult = await getAllSubmissionFeedbacks();
    if (submissionFeedbackResult.isSuccess) {
        const submissionFeedbacks = submissionFeedbackResult.result;
        for (let i = 0; i < submissionFeedbacks.length; i++) {
            const sf: SubmissionFeedback = submissionFeedbacks[i];
            
            if (sf.submission?.id) await deleteSubmission({id: sf.submission?.id});
            if (sf.feedbackId) await deleteFeedback({id: sf.feedbackId});

            await deleteSubmissionFeedback({id: sf.id});
        }
    }
}