import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React, { useContext, useEffect, useState } from "react";
import { Block, Event, Organization } from "../../../../models";
import { createBlock, getAllBlocks } from "../../../../utilities/block/Block";
import moment from "moment";
import { getAllOrganizations, getOrganizationById } from "../../../../utilities/organization/Organization";
import { getPersonByPersonId } from "../../../../utilities/person/Person";
import { close } from "ionicons/icons";
import { CreateBlockInput, BlockStatus, UpdateEventInput, EventOptionsInput } from "../../../../API";
import SelectOrganization from "../../../../components/Organization/SelectOrganization";
import { PersonContext } from "../../../../context/PersonContext";
import { formatRolesForDisplay } from "../../../../utilities/roles/FormatRoles";
import SelectBlocks from "../../../../components/Blocks/SelectBlock";
import { BlockTier } from "../../../../data/content/CreditBlockPricing";
import Spinner from "../../../../components/Spinners/Spinner";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import SelectEvent from "../../../../components/Event/SelectEvent";
import { getAllEvents, updateEvent } from "../../../../utilities/events/Event";

interface FormattedBlock {
    orgName: string
    personName: string
    block: Block
}

const AdminCreditManagementPage: React.FC = () => {
    const user = useContext(PersonContext);
    
    const [blocks, setBlocks] = useState<Block[] | null | undefined>();
    const [formattedBlocks, setFormattedBlocks] = useState<FormattedBlock[] | null | undefined>();
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [userOrganizations, setUserOrganizations] = useState<Organization[] | null>(null);
    const [events, setEvents] = useState<Event[] | null>(null);
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const [selectedBlockTier, setSelectedBlockTier] = useState<BlockTier | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [showUnlimitedModal, setShowUnlimitedModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const formatBlocks = async (blocks: Block[]) => {
        setIsLoading(true);
        let formattedResults: FormattedBlock[] = [];
        for (let i = 0; i < blocks.length; i++) {
            const block = blocks[i];
            let orgName = "";
            if (block.organizationId) {
                const orgResult = await getOrganizationById(block.organizationId);
                if (orgResult.isSuccess) {
                    orgName = orgResult.result.name;
                }
            }
            let personName = "";
            if (block.personid) {
                const personResult = await getPersonByPersonId(block.personid);
                if (personResult.isSuccess) {
                    const firstName = personResult.result.firstName;
                    const lastName = personResult.result.lastName;
                    personName = firstName + " " + lastName;
                }
            }
            const formattedBlock: FormattedBlock = {
                orgName,
                personName,
                block
            };
            formattedResults.push(formattedBlock);
        }
        setFormattedBlocks(formattedResults);
        setIsLoading(false);
    }

    const getBlocks = async () => {
        const queryResult = await getAllBlocks();
        if (queryResult.isSuccess) {
            setBlocks(queryResult.result);
            await formatBlocks(queryResult.result);
        }
    }

    async function getOrganizations() {
        const queryResult = await getAllOrganizations();
        if (queryResult.isSuccess) {
            setUserOrganizations(queryResult.result);
            setOrganization(queryResult.result[0]); //To Do: make alphabetical or chronological?
        }
        setIsLoading(false);
    }

    async function getEvents() {
        const queryResult = await getAllEvents();
        if (queryResult.isSuccess) {
            setEvents(queryResult.result);
        }
        setIsLoading(false);
    }

    async function getData() {
        await getBlocks();
        await getOrganizations();
        await getEvents();
    }

    useEffect(() => {
        // Check user roles and only display Orgs if user is an admin
        if (user.roles) {
            const rolesArray = formatRolesForDisplay(user.roles);
            if (rolesArray) {
                rolesArray.forEach(role => {
                    if (role.value === "admin") {
                        getData();
                    }
                });
            }
        }
    }, [user, user.id]);

    const handleOrganizationSelection = (organization: Organization) => {
        setOrganization(organization);
    }

    const handleBlockSelection = (blockTier: BlockTier) => {
        setSelectedBlockTier(blockTier);
    }

    const handleEventSelection = (event: Event) => {
        setSelectedEvent(event);
    }

    const handleSubmit = async () => {
        setIsLoading(true);

        if (organization && selectedBlockTier) {
            const blockInput: CreateBlockInput = {
                name: "Free credits from RingSide Pro",
                personid: user.id,
                organizationId: organization.id,
                totalCredits: selectedBlockTier.numberCredits,
                usedCredits: 0,
                status: BlockStatus.valid,
                price: selectedBlockTier.cost,
                amountPaid: 0,
                isPromotion: "false",
                isFree: "true"
            };
            const createResult = await createBlock(blockInput);
            if (createResult.isSuccess) {
                const newBlock = createResult.result;
                const updatedBlocks = blocks?.concat(newBlock);
                setBlocks(updatedBlocks);
                if (updatedBlocks) formatBlocks(updatedBlocks);
                setError("");
            } else {
                setError("Could not create the block.");
            }
        } else {
            if (!organization) setError("No organization selected");
            if (!selectedBlockTier) setError("No block tier selected");
        }

        setIsLoading(false);
        setShowModal(false);
    }

    const handleUnlimitedCreditSubmit = async () => {
        setIsLoading(true);

        if (selectedEvent) {
            const eventOptions: EventOptionsInput = {
                ...selectedEvent.eventOptions,
                hasUnlimitedCredits: selectedEvent.eventOptions?.hasUnlimitedCredits ? false : true
            };
            const eventInput: UpdateEventInput = {
                id: selectedEvent.id,
                eventOptions: eventOptions
            };
            const updateResult = await updateEvent(eventInput);
            if (updateResult.isSuccess) {
                const updatedEvent: Event = updateResult.result;
                if (events) {
                    let updatedEvents = [];
                    for (let i = 0; i < events.length; i++) {
                        const current = events[i];
                        if (current.id === selectedEvent.id) updatedEvents.push(updatedEvent);
                        else updatedEvents.push(current);
                    }
                    setEvents(updatedEvents);
                }
                setSelectedEvent(null);
                setError("");
            } else {
                setError("Could not update the event.");
            }
        } else {
            setError("No event selected");
        }

        setIsLoading(false);
        setShowUnlimitedModal(false);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Credits" />
                <IonRow className="ion-justify-content-right">
                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-right">
                        <IonButton onClick={() => setShowModal(true)}>Add Block</IonButton>
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-right">
                        <IonButton onClick={() => setShowUnlimitedModal(true)}>Unlimited Credits</IonButton>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>All Credit Blocks</IonCardTitle>
                            <IonCardContent>
                                {error && (<ErrorAlert error={error} />)}
                                {isLoading ?
                                    <Spinner />
                                    :
                                    <>
                                        <IonList className="bg-white">
                                            {formattedBlocks ? (
                                                <>
                                                    {formattedBlocks.map((formattedBlock, index) => (
                                                        <IonItem key={index}>
                                                            <IonLabel>
                                                                <IonRow>
                                                                    <IonCol sizeXs="12" sizeMd="4">
                                                                        <IonText>{formattedBlock.orgName}</IonText>
                                                                    </IonCol>
                                                                    <IonCol sizeXs="12" sizeMd="4">
                                                                        <p>
                                                                            {formattedBlock.personName}
                                                                        </p>
                                                                    </IonCol>
                                                                    <IonCol sizeXs="12" sizeMd="4">
                                                                        <IonText>Paid: ${formattedBlock.block.amountPaid?.toFixed(2)}</IonText>
                                                                    </IonCol>
                                                                </IonRow>
                                                                <IonRow>
                                                                    <IonCol sizeXs="12" sizeMd="4">
                                                                        <IonText>{formattedBlock.block.usedCredits?.toLocaleString()}/{formattedBlock.block.totalCredits?.toLocaleString()}</IonText>
                                                                    </IonCol>
                                                                    <IonCol sizeXs="12" sizeMd="4">
                                                                        <p>
                                                                            {formattedBlock.block.status}
                                                                        </p>
                                                                    </IonCol>
                                                                    <IonCol sizeXs="12" sizeMd="4">
                                                                        <IonText>{moment(formattedBlock.block.createdOn).format("MMM Do YYYY")}</IonText>
                                                                    </IonCol>
                                                                </IonRow>
                                                            </IonLabel>
                                                        </IonItem>
                                                    ))}
                                                </>
                                            )
                                            :
                                            "No credit blocks found."
                                            }
                                        </IonList>
                                    </>
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonModal backdropDismiss={false} isOpen={showModal} id="eventClassModal">
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                           Add Credit Block
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowModal(false)}
                            >
                                <p id="closeEventClassModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    <IonIcon icon={close} />
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonContent className="ion-padding">
                        {isLoading ?
                            <Spinner />
                            :
                            <>
                                <IonRow>
                                    <IonCol>
                                        {(organization && userOrganizations) ?
                                            <SelectOrganization currentOrganization={organization} organizations={userOrganizations} onInputChange={handleOrganizationSelection} />
                                            :
                                            <p>No organizations to choose from</p>
                                        }
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <SelectBlocks onSelect={handleBlockSelection} />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonButton color="tertiary" onClick={handleSubmit}>
                                            Save
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                    </IonContent>
                </IonModal>
                <IonModal backdropDismiss={false} isOpen={showUnlimitedModal} id="unlimitedCreditsModal">
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                           Unlimited Credits
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowUnlimitedModal(false)}
                            >
                                <p id="closeUnlimitedCreditsModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    <IonIcon icon={close} />
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonContent className="ion-padding">
                        {isLoading ?
                            <Spinner />
                            :
                            <>
                                <IonRow>
                                    <IonCol>
                                        {(events) ?
                                            <SelectEvent selectedValue={selectedEvent?.id || null} events={events} onSelect={handleEventSelection} />
                                            :
                                            <p>No events to choose from.</p>
                                        }
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        {(selectedEvent) ?
                                            <p>Currently has Unlimited Credits? {selectedEvent.eventOptions?.hasUnlimitedCredits ? "Yes" : "No"}</p>
                                            :
                                            <p>No event selected.</p>
                                        }
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="6" className="ion-text-center">
                                        <IonButton color="light" expand="block" onClick={() => {setShowUnlimitedModal(false); setSelectedEvent(null);}}>
                                            Cancel
                                        </IonButton>
                                    </IonCol>
                                    {selectedEvent && (
                                        <IonCol size="6" className="ion-text-center">
                                            <IonButton color="tertiary" expand="block" onClick={handleUnlimitedCreditSubmit}>
                                                {selectedEvent.eventOptions?.hasUnlimitedCredits ? "Remove Unlimited" : "Grant Unlimited"}
                                            </IonButton>
                                        </IonCol>
                                    )}
                                </IonRow>
                            </>
                        }
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default AdminCreditManagementPage;