import {
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Spinner from "../Spinners/Spinner";
import { S3Object } from "../../models";
import Storage from "@aws-amplify/storage";
import ErrorBanner from "../Banners/ErrorBanner";

interface _Props {
    autoPlay?: boolean
    className?: string
    url?: string
    videoObject: S3Object
}

const VideoPlayer: React.FC<_Props> = ({autoPlay, className, url, videoObject}) => {

    const [URL, setURL] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (url) setURL(url);
    }, [url]);

    useEffect(() => {
        if (videoObject) {
            if (!URL) {
                handleGetVideo(videoObject.key);
            }
        }
    }, [videoObject]);

    async function handleGetVideo(fileName: string) {
        try {
            const url = await Storage.get(fileName);
            if (url && typeof(url) === "string") {
                setURL(url);
            }
        } catch (error: any) {
            const message = "Error while getting the video: " + error;
            setError(message)
            return "";
        }
    }

    return (
        <>
            {isLoading ?
                <IonRow>
                    <IonCol className="ion-text-center">
                        <Spinner />
                    </IonCol>
                </IonRow>
                :
                <IonRow>
                    {error && <ErrorBanner error={error}/>}
                    <IonCol>
                        {URL ?
                            <div>
                                <video controls width="100%" src={URL} autoPlay={autoPlay} playsInline className="video-height" data-hj-allow-iframe=""></video>
                            </div>
                            :
                            <p>Could not find the video.</p>
                        }
                    </IonCol>
                </IonRow>
            }
        </>
    );
};

export default VideoPlayer;