import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import {Event, EventDivision, OrganizationDivision} from "../../../../../models";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { PersonContext } from "../../../../../context/PersonContext";
import { RouteComponentProps, useHistory } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import { createEventDivision, deleteEventDivision, getEventDivisionsByEventId } from "../../../../../utilities/eventDivision/EventDivision";
import { getOrganizationDivisionsByOrganizationId } from "../../../../../utilities/organizationDivision/OrganizationDivision";
import { sortOrganizationDivisionsByName } from "../../../../../utilities/organizationDivision/SortOrganizationDivisions";
import { CreateEventDivisionInput } from "../../../../../API";
import { deleteEventClass, getEventClassesByEventDivisionId } from "../../../../../utilities/eventClass/EventClass";
import Spinner from "../../../../../components/Spinners/Spinner";
import { sortEventDivisionsByName } from "../../../../../utilities/eventDivision/SortEventDivisons";
import InformationBanner from "../../../../../components/Banners/InformationBanner";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

interface formattedOrganizationDivision {
    name: string
    isChecked: boolean
    organizationDivision: OrganizationDivision
    eventDivision?: EventDivision
}

const EventDivisionsEditPage: React.FC<EventPageProps> = ({match}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [currentEventDivisions, setCurrentEventDivisions] = useState<EventDivision[] | null | undefined>();
    const [organizationDivisions, setOrganizationDivisions] = useState<OrganizationDivision[] | null | undefined>();
    const [currentFormattedDivisions, setCurrentFormattedDivisions] = useState<formattedOrganizationDivision[] | null | undefined>();
    const [error, setError] = useState("");

    function formatDivisions(organizationDivisionList?: OrganizationDivision[], eventDivisionList?: EventDivision[]) {
        const orgDivisions = organizationDivisionList || organizationDivisions;
        const eventDivisions = eventDivisionList || currentEventDivisions;

        let formattedList: formattedOrganizationDivision[] = [];

        if (orgDivisions && orgDivisions.length) {
            for (let i = 0; i < orgDivisions.length; i++) {
                const od = orgDivisions[i];
                const found = eventDivisions?.find(eventDivison => eventDivison.name === od.name);
                const currentFormatted: formattedOrganizationDivision = {
                    name: od.name,
                    isChecked: !!found,
                    organizationDivision: od,
                    eventDivision: found
                };
                formattedList.push(currentFormatted);
            }
        }
        setCurrentFormattedDivisions(formattedList);
    }

    async function getOrganizationDivisions(event: Event) {
        if (event && event.organizationId) {
            const queryResult = await getOrganizationDivisionsByOrganizationId(event.organizationId);
            if (queryResult.isSuccess) {
                let list = queryResult.result;
                const sorted = sortOrganizationDivisionsByName(list);
                if (sorted) list = sorted;
                setOrganizationDivisions(list);
                await getEventDivisions(list);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        } else {
            setError("Sorry, there is no organization associated with your event.");
        }
    }

    async function getEventDivisions(organizationDivisions: OrganizationDivision[]) {
        const queryResult = await getEventDivisionsByEventId(match.params.id);
        if (queryResult.isSuccess) {
            let list = queryResult.result;
            const sorted = sortEventDivisionsByName(list);
            if (sorted) list = sorted;
            setCurrentEventDivisions(list);
            formatDivisions(organizationDivisions, list);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getEvent() {
        setIsLoading(true);
        const queryResult = await getEventById(match.params.id);
        if (queryResult.isSuccess) {
            setCurrentEvent(queryResult.result);
            await getOrganizationDivisions(queryResult.result);
            setIsLoading(false);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (match.params.id) getEvent();
    }, [match]);

    const handleSelectAll = () => {
        setIsLoading(true);
        let list: formattedOrganizationDivision[] = (currentFormattedDivisions ? [...currentFormattedDivisions] : []);
        if (list) {
            for (let i = 0; i < list.length; i++) {
                const current = list[i];
                current.isChecked = true;
            }
        } else {
            setError("Could not find the organization divisions.");
        }
        setCurrentFormattedDivisions(list);
        setIsLoading(false);
    }

    const handleDeselectAll = () => {
        setIsLoading(true);
        let list: formattedOrganizationDivision[] = (currentFormattedDivisions ? [...currentFormattedDivisions] : []);
        if (list) {
            for (let i = 0; i < list.length; i++) {
                const current = list[i];
                current.isChecked = false;
            }
        } else {
            setError("Could not find the organization divisions.");
        }
        setCurrentFormattedDivisions(list);
        setIsLoading(false);
    }

    const handleCheckbox = (isChecked: boolean, index: number, formattedValue: formattedOrganizationDivision) => {
        setIsEditing(true);
        if (currentFormattedDivisions) {
            const updatedItem: formattedOrganizationDivision = {
                ...formattedValue,
                isChecked: isChecked
            };

            const updatedItems: formattedOrganizationDivision[] = [
                ...currentFormattedDivisions.slice(0, index),
                updatedItem,
                ...currentFormattedDivisions.slice(index + 1)
            ];

            setCurrentFormattedDivisions(updatedItems);
        }
    }

    const handleNewEventDivison = async (organizationDivision: OrganizationDivision) => {
        if (currentEvent) {
            const input: CreateEventDivisionInput = {
                eventId: currentEvent.id,
                name: organizationDivision.name,
                divisionId: organizationDivision.divisionId,
                // eventDivisionDivisionId: organizationDivision.divisionId,
                organizationDivisionId: organizationDivision.id,
                eventDivisionOrganizationDivisionId: organizationDivision.id,
                entryFee: organizationDivision.entryFee,
                prizeMoney: organizationDivision.prizeMoney,
                eventDivisionPrizeMoneyTableId: organizationDivision.prizeMoneyTable?.id,
                minNumberEntriesPerClass: organizationDivision.minNumberEntries,
                maxNumberEntriesPerClass: organizationDivision.maxNumberEntries,
                lastUpdatedBy: user.id,
                createdBy: user.id
            };
            const createResult = await createEventDivision(input);
            if (createResult.isSuccess) {
                const newEventDivison = createResult.result;
                const newEventDivisionList = currentEventDivisions?.concat([newEventDivison]);
                setCurrentEventDivisions(newEventDivisionList);
                return newEventDivison;
            } else {
                setError("Could not create the new event division.");
            }
        } else {
            setError("Could not create an event divison because we could not find the event.");
        }
    }

    const handleRemoveEventDivison = async (eventDivision: EventDivision) => {
        let didRemoveClasses = true;
        const queryResult = await getEventClassesByEventDivisionId(eventDivision.id);
        if (queryResult.isSuccess) {
            const eventClasses = queryResult.result;
            for (let i = 0; i < eventClasses.length; i++) {
                const eventClass = eventClasses[i];
                const deleteClassResult = await deleteEventClass({id: eventClass.id});
                if (!deleteClassResult.isSuccess) {
                    didRemoveClasses = false;
                    const message = "Could not remove an event class: " + deleteClassResult.message; 
                    setError(message);
                }
            }
        }
        if (didRemoveClasses) {
            const deleteResult = await deleteEventDivision({id: eventDivision.id});
            if (deleteResult.isSuccess && currentEventDivisions) {
                const index = currentEventDivisions?.findIndex(current => eventDivision.id === current.id);
                if (index && index > -1) {
                    const newList = [
                        ...currentEventDivisions.slice(0, index),
                        ...currentEventDivisions.slice(index + 1)
                    ];
                    setCurrentEventDivisions(newList);
                }
            } else {
                setError("Could not remove an event division.");
            }
        } else {
            setError("Could not remove an event class.");
        }
    }

    const handleSave = async () => {
        setError("");
        setIsLoading(true);
        if (currentFormattedDivisions) {
            let list: formattedOrganizationDivision[] = [...currentFormattedDivisions];
            for (let i = 0; i < currentFormattedDivisions.length; i++) {
                const current = currentFormattedDivisions[i];
                if (current.isChecked) {
                    // Check for new additions. OTW, it already has been created
                    const found = !!(currentEventDivisions?.find(eventDivision => eventDivision.name === current.name));
                    if (!found) {
                        const result = await handleNewEventDivison(current.organizationDivision);
                        if (result) {
                            list[i].eventDivision = result;
                        }
                    }
                } else {
                    // Check if it needs to be removed. OTW, it was never included
                    const found = !!(currentEventDivisions?.find(eventDivision => eventDivision.name === current.name)); 
                    if (found) {
                        if (current.eventDivision) await handleRemoveEventDivison(current.eventDivision); 
                    }
                }
            }   
            setCurrentFormattedDivisions(list);
        }
        setIsEditing(false);
        setIsLoading(false);
    }

    const navigateToOrgSettingsPage = () => {
        if (currentEvent) {
            const path = "/index/organization/org-divisions/" + currentEvent.organizationId;
            history.push(path);
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent ? currentEvent.name : "Event Settings"} />
                {error && <ErrorAlert width="12" error={error} />}
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                Select Event Divisions
                            </IonCardTitle>
                            <IonCardSubtitle>
                                <IonRow>
                                    <IonCol>
                                        <p>Select the divisions to use from your <span className="link" onClick={navigateToOrgSettingsPage}>Organization's Division Templates.</span></p>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <InformationBanner info="Note: If you remove a division that already has event classes connected to it, those event classes will also be removed." />
                                    </IonCol>
                                </IonRow>
                            </IonCardSubtitle>
                            {isLoading ?
                                <Spinner />
                                :
                                <IonCardContent>
                                    <IonRow className="ion-align-items-center">
                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center"> 
                                            <IonButton color="primary" onClick={handleSelectAll}>Select All</IonButton>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center"> 
                                            <IonButton color="light" onClick={handleDeselectAll}>Deselect All</IonButton>
                                        </IonCol>
                                    </IonRow>
                                    {isEditing && (
                                        <IonRow className="ion-align-items-center">
                                            <IonCol size="12" className="ion-text-center"> 
                                                <IonButton color="success" onClick={handleSave}>Save Selections</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                    <IonRow className="ion-align-items-center">
                                        <IonCol size="12"> 
                                            {currentFormattedDivisions ?
                                                    <>
                                                        <IonList className="bg-white">                                                        
                                                            {currentFormattedDivisions.map((formattedDivison, index) => (
                                                                <IonItem key={index}>
                                                                    <IonCheckbox slot="start" checked={formattedDivison.isChecked} onIonChange={e => handleCheckbox(e.detail.checked, index, formattedDivison)} />
                                                                    <IonLabel>
                                                                        {index + 1} - {formattedDivison.name}
                                                                    </IonLabel>
                                                                </IonItem>
                                                            ))}
                                                        </IonList>
                                                    </>
                                                    :
                                                    <p>No organization divisions found.</p>
                                                }
                                        </IonCol>
                                    </IonRow>
                                    {isEditing && (
                                        <IonRow className="ion-align-items-center">
                                            <IonCol size="12" className="ion-text-center"> 
                                                <IonButton color="success" onClick={handleSave}>Save Selections</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                </IonCardContent>
                            }
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                Current Event Divisions
                            </IonCardTitle>
                            <IonCardSubtitle>
                                <p>All divisions currently offered for your event.</p>
                            </IonCardSubtitle>
                            <IonCardContent>
                                <IonRow className="ion-align-items-center">
                                    <IonCol size="12"> 
                                        {currentEventDivisions ?
                                                <>
                                                    <IonList className="bg-white">                                                        
                                                        {currentEventDivisions.map((eventDivision, index) => (
                                                            <IonItem key={index}>
                                                                <IonLabel>
                                                                    <IonRow>
                                                                        <IonCol>
                                                                            {eventDivision.name}
                                                                        </IonCol>
                                                                    </IonRow>
                                                                </IonLabel>
                                                            </IonItem>
                                                        ))}
                                                    </IonList>
                                                </>
                                                :
                                                <p>No organization divisions found.</p>
                                            }
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EventDivisionsEditPage;