import { SubmissionFeedback } from "../../models";

var moment = require("moment");

/**
 * Sort so pending and draft are at the top + sorted oldest to newest -> otw sort newest to oldest
 * 
 * @param data 
 * @returns submissionFeedbacks sorted lowest to highest
 */
export function sortSubmissionFeedbacksByStatus(data: SubmissionFeedback[]) {
    try {
        let result: SubmissionFeedback[] = [];
        if (data && data.length) {
            result = data.sort(function(a, b): number {
                const statusResult = (a.feedbackStatus === "pending" || a.feedbackStatus === "draft" ? 0 : 1) - (b.feedbackStatus === "pending" || b.feedbackStatus === "draft" ? 0 : 1);
                const dateResult = ((a.feedbackStatus === "pending" || a.feedbackStatus === "draft") ? new moment(a.createdOn).format("YYYYMMDDHHmmss") - new moment(b.createdOn).format("YYYYMMDDHHmmss") : new moment(b.createdOn).format("YYYYMMDDHHmmss") - new moment(a.createdOn).format("YYYYMMDDHHmmss"));
                return statusResult || dateResult;
            });
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: submissionFeedback");
        console.error(error);
        return null;
    }
};