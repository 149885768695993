import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Barn, Invitation, Person } from "../../models";
import { getPendingInvitationsByBarnId, updateInvitation } from "../../utilities/invitation/Invitation";
import { PersonContext } from "../../context/PersonContext";
import { getPersonByPersonId } from "../../utilities/person/Person";
import Spinner from "../Spinners/Spinner";
import { InvitationStatus, UpdateInvitationInput } from "../../API";
import { sendBarnRequestStatusUpdated } from "../../utilities/invitation/InvitationEmail";
import { createBarnPerson } from "../../utilities/barnMember/GenerateBarnMember";
import ErrorAlert from "../Errors/ErrorAlert";

interface _Props {
    selectedBarn: Barn
}

interface FormattedInvitation {
    createdBy: Person
    invitation: Invitation
}

const BarnRequests: React.FC<_Props> = ({selectedBarn}) => {
    const user = useContext(PersonContext);

    const [invitations, setInvitations] = useState<Invitation[] | null | undefined>();
    const [formattedInvitations, setFormattedInvitations] = useState<FormattedInvitation[] | null | undefined>();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const formatInvitations = async (invitations: Invitation[]) => {
        let result: FormattedInvitation[] = [];
        for (let i = 0; i < invitations.length; i++) {
            const currentInvitation = invitations[i];
            const personResult = await getPersonByPersonId(currentInvitation.createdBy);
            if (personResult.isSuccess) {
                const person = personResult.result;
                const formattedInvitation: FormattedInvitation = {
                    createdBy: person,
                    invitation: currentInvitation
                };
                result.push(formattedInvitation);
            }
        }
        setFormattedInvitations(result);
    }

    useEffect(() => {
        async function getInvitations() {
            if (selectedBarn) {
                setIsLoading(true);
                const queryResult = await getPendingInvitationsByBarnId(selectedBarn.id);
                if (queryResult.isSuccess) {
                    setInvitations(queryResult.result);
                    formatInvitations(queryResult.result);
                }
                setIsLoading(false);
            }
        }

        getInvitations();
    }, [selectedBarn]); 

    const handleUpdateInvitation = async (status: string, formattedInvitation: FormattedInvitation) => {
        setIsLoading(true);

        const invitation = formattedInvitation.invitation;
        const person = formattedInvitation.createdBy;

        const input: UpdateInvitationInput = {
            id: invitation.id,
            status: status === "accepted" ? InvitationStatus.accepted : InvitationStatus.declined
        };
        const updateResult = await updateInvitation(input);
        if (updateResult.isSuccess) {
            if (status === "accepted") {
                const barnMemberResult = await createBarnPerson(person, selectedBarn, invitation.permissionLevel, invitation.status);
                if (!barnMemberResult.isSuccess) {
                    setError("Could not create the new barn member.");
                    setIsLoading(false);
                    return;
                }
            }
            const updatedInvitations: Invitation[] | null | undefined = invitations?.filter(invite => invite.id !== invitation.id);
            setInvitations(updatedInvitations);
            if (updatedInvitations) formatInvitations(updatedInvitations);
            await sendBarnRequestStatusUpdated(person.email, selectedBarn.name, status);
            setIsLoading(false);
        } else {
            setError("Could not update the invitation. Contact support for help: hello@ringsidepro.com");
            setIsLoading(false);
        }
    }

    return (
        <>
            {(formattedInvitations && formattedInvitations.length > 0) && 
                <IonCard className="bg-white ion-padding">
                    <IonCardTitle>
                        <h3>Requests</h3>
                    </IonCardTitle>
                    <IonCardSubtitle>
                        <p className="description">Review requests to join the barn.</p>
                    </IonCardSubtitle>
                    {error && <ErrorAlert error={error} />}
                    <IonCardContent>
                        {isLoading ?
                            <Spinner />
                            :
                            <IonList className="bg-white">
                                {formattedInvitations.map((formattedInvitation, index) => (
                                    <IonItem key={index}>
                                        <IonLabel>
                                            <IonRow>
                                                <IonCol sizeXs="12" sizeMd="3">
                                                    <h4>{formattedInvitation.createdBy.firstName + " " + formattedInvitation.createdBy.lastName}</h4>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="3">
                                                    <p>{formattedInvitation.invitation.permissionLevel}</p>
                                                </IonCol>
                                                <IonCol sizeXs="6" sizeMd="3">
                                                    <IonButton color="success" onClick={() => handleUpdateInvitation("accepted", formattedInvitation)}>Accept</IonButton>
                                                </IonCol>
                                                <IonCol sizeXs="6" sizeMd="3">
                                                    <IonButton color="danger" onClick={() => handleUpdateInvitation("declined", formattedInvitation)}>Decline</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                    </IonItem>
                                ))}
                            </IonList>
                        }
                    </IonCardContent>
                </IonCard>
            }
        </>
    );
};

export default BarnRequests;