import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { CreateMembershipInput, UpdateEventEntryInput, UpdateOwnerInput, UpdateMembershipInput, UpdateContactInput, CreateContactInput } from "../../../../API";
import { PersonContext } from "../../../../context/PersonContext";
import { EventEntry, Owner, Membership } from "../../../../models";
import { updateEventEntry } from "../../../../utilities/eventEntry/EventEntry";
import { updateOwner } from "../../../../utilities/owner/Owner";
import { createMembership, getMembershipsByOwnerId, updateMembership } from "../../../../utilities/membership/Membership";
import SuccessBanner from "../../../Banners/SuccessBanner";
import ErrorAlert from "../../../Errors/ErrorAlert";
import Spinner from "../../../Spinners/Spinner";
import { formatTwilioNumber } from "../../../../utilities/contact/FormatPhoneNumber";
import { createContact, updateContact } from "../../../../utilities/contact/Contact";

interface _Props {
    entry: EventEntry
}

const EntryInfoOwner: React.FC<_Props> = ({entry}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [ownerName, setOwnerName] = useState("");
    const [ownerLocation, setOwnerLocation] = useState("");
    const [ownerEmail, setOwnerEmail] = useState("");
    const [ownerPhone, setOwnerPhone] = useState("");
    const [ecMembership, setECMembership] = useState<Membership | null | undefined>();
    const [ownerECNumber, setOwnerECNumber] = useState("");
    const [ownerECStatus, setOwnerECStatus] = useState("");
    const [otherMembership, setOtherMembership] = useState<Membership | null | undefined>();
    const [ownerOtherMembershipName, setOwnerOtherMembershipName] = useState("");
    const [ownerOtherMembershipNumber, setOwnerOtherMembershipNumber] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    async function setMemberships(owner: Owner) {
        const queryResult = await getMembershipsByOwnerId(owner.id);
        if (queryResult.isSuccess) {
            const memberships = queryResult.result;
            if (memberships) {
                for (let i = 0; i < memberships.length; i++) {
                    const membership = memberships[i];
                    if (membership?.name === "EC") {
                        setECMembership(membership);
                        setOwnerECNumber(membership.membershipId);
                        setOwnerECStatus(membership.membershipStatus || "");
                    } else {
                        setOtherMembership(membership);
                        setOwnerOtherMembershipName(membership.name);
                        setOwnerOtherMembershipNumber(membership.membershipId);
                    }
                }
            }
        }
    }

    const getEntryInformation = (entry: EventEntry) => {
        setCurrentEntry(entry);
        setOwnerName(entry.ownerName || "");
        setOwnerLocation(entry?.owner?.location || "");
        setOwnerEmail(entry.owner?.contact?.personalEmail || "");
        setOwnerPhone(entry?.owner?.contact?.cell || "");
        if (entry.owner) setMemberships(entry.owner);
    }

    useEffect(() => {
        if (entry) {
            getEntryInformation(entry);
        }
    }, [entry]);

    const handleEdit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        let contactId = "";
        if (entry.owner?.contact?.id) {
            const updateContactInput: UpdateContactInput = {
                id: entry.owner.contact.id,
                personalEmail: ownerEmail,
                cell: formatTwilioNumber(ownerPhone) || ""
            };
            const updateContactResult = await updateContact(updateContactInput);
            if (updateContactResult) {
                contactId = updateContactResult.result.id;
            } else {
                setError("Could not update the contact info.");
                setIsLoading(false);
                return;
            }
        } else {
            const createContactInput: CreateContactInput = {
                personalEmail: ownerEmail,
                cell: ownerPhone ? formatTwilioNumber(ownerPhone) : undefined
            };
            const createContactResult = await createContact(createContactInput);
            if (createContactResult) {
                contactId = createContactResult.result.id;
            } else {
                setError("Could not create the contact info.");
                setIsLoading(false);
                return;
            }
        }
        if (entry.ownerId) {
            const input: UpdateOwnerInput = {
                id: entry.ownerId,
                name: ownerName,
                location: ownerLocation,
                ownerContactId: contactId
            };
            const updateResult = await updateOwner(input);
            if (updateResult.isSuccess) {
                const updateEntryInput: UpdateEventEntryInput = {
                    id: entry.id,
                    ownerName: ownerName,
                    ownerId: updateResult.result.id,
                    // eventEntryOwnerId: updateResult.result.id
                };
                const updateEntryResult = await updateEventEntry(updateEntryInput);
                if (updateEntryResult.isSuccess) {
                    const updatedEntry = updateEntryResult.result;
                    setCurrentEntry(updatedEntry);
                    getEntryInformation(updatedEntry);
                    if (ownerECNumber) {
                        if (ecMembership) {
                            const ecUpdateInput: UpdateMembershipInput = {
                                id: ecMembership.id,
                                membershipId: ownerECNumber,
                                membershipStatus: ownerECStatus,
                                ownerId: entry.ownerId,
                                // ownerMembershipsId: entry.ownerId
                            };
                            const updateECResult = await updateMembership(ecUpdateInput);
                            if (updateECResult.isSuccess) {
                                setECMembership(updateECResult.result);
                            } else {
                                setError("Could not update the EC info.");
                                setIsLoading(false);
                                return;
                            }
                        } else {
                            const ecCreateInput: CreateMembershipInput = {
                                name: "EC",
                                membershipId: ownerECNumber,
                                membershipStatus: ownerECStatus,
                                personId: entry.ownerId || user.id,
                                ownerId: entry.ownerId,
                                // ownerMembershipsId: entry.ownerId
                            };
                            const createECResult = await createMembership(ecCreateInput);
                            if (createECResult.isSuccess) {
                                setECMembership(createECResult.result);
                            } else {
                                setError("Could not create the EC info.");
                                setIsLoading(false);
                                return;
                            }
                        }
                    }
                    if (ownerOtherMembershipNumber) {
                        if (otherMembership) {
                            const otherUpdateInput: UpdateMembershipInput = {
                                id: otherMembership.id,
                                name: ownerOtherMembershipName,
                                membershipId: ownerOtherMembershipNumber,
                                ownerId: entry.ownerId,
                                // ownerMembershipsId: entry.ownerId
                            };
                            const updateOtherMembershipResult = await updateMembership(otherUpdateInput);
                            if (updateOtherMembershipResult.isSuccess) {
                                setOtherMembership(updateOtherMembershipResult.result);
                            } else {
                                setError("Could not update the Other Membership info.");
                                setIsLoading(false);
                                return;
                            }
                        } else {
                            const otherCreateInput: CreateMembershipInput = {
                                name: ownerOtherMembershipName,
                                membershipId: ownerOtherMembershipNumber,
                                personId: entry.ownerId || user.id,
                                ownerId: entry.ownerId,
                                // ownerMembershipsId: entry.ownerId
                            };
                            const createOtherMembershipResult = await createMembership(otherCreateInput);
                            if (createOtherMembershipResult.isSuccess) {
                                setOtherMembership(createOtherMembershipResult.result);
                            } else {
                                setError("Could not create the Other Membership info.");
                                setIsLoading(false);
                                return;
                            }
                        }
                    }
                    setMemberships(updatedEntry.owner);
                    setSuccess("Updated the owner info.");
                    setIsEdit(false);
                    setIsLoading(false);
                } else {
                    setError("Could not update the entry.");
                    setIsLoading(false);
                    return;
                }
            } else {
                setError("Could not update the owner");
                setIsLoading(false);
            }
        } else {
            setError("Could not find the owner in the database.");
            setIsLoading(false);
        }
    }

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h3>Owner</h3>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {success && <SuccessBanner width="12" success={success} />}
                        {isEdit ?
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Name</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={ownerName}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setOwnerName(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Location</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={ownerLocation}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setOwnerLocation(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Email</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={ownerEmail}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setOwnerEmail(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Cell Phone</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={ownerPhone}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setOwnerPhone(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner EC</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={ownerECNumber}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setOwnerECNumber(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Status</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={ownerECStatus}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setOwnerECStatus(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Membership Name</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={ownerOtherMembershipName}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setOwnerOtherMembershipName(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Membership Number</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={ownerOtherMembershipNumber}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setOwnerOtherMembershipNumber(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                            <>
                                <IonRow>
                                    <IonCol>
                                        <h3>Owner</h3>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Name</IonLabel>
                                            <IonText><p>{ownerName}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Location</IonLabel>
                                            <IonText><p>{ownerLocation}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Email</IonLabel>
                                            <IonText><p>{ownerEmail}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Cell Phone</IonLabel>
                                            <IonText><p>{ownerPhone}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner EC Number</IonLabel>
                                            <IonText><p>{ownerECNumber}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner EC Status</IonLabel>
                                            <IonText><p>{ownerECStatus}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Other Membership</IonLabel>
                                            <IonText><p>{ownerOtherMembershipName}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Owner Other Membership Number</IonLabel>
                                            <IonText><p>{ownerOtherMembershipNumber}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                        {isLoading ?
                            <Spinner />
                            :
                            <IonRow className="ion-justify-content-center">
                                {isEdit && (
                                    <IonCol sizeMd="4" className="ion-text-center">
                                        <IonButton
                                            className="ion-margin-top"
                                            color="primary"
                                            expand="block"
                                            onClick={() => setIsEdit(false)}
                                        >
                                            Cancel
                                        </IonButton>
                                    </IonCol>
                                )}
                                <IonCol sizeMd="4" className="ion-text-center">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="tertiary"
                                        expand="block"
                                        onClick={isEdit ? handleEdit : (() => setIsEdit(true))}
                                    >
                                        Edit
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        }
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default EntryInfoOwner;