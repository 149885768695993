import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../../components/Headers/Header";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { Organization, PrizeMoneyTable } from "../../../../../../models";
import { getOrganizationById } from "../../../../../../utilities/organization/Organization";
import PrizeMoneyTableExplanationModal from "../../../../../../components/PrizeMoneyTable/PrizeMoneyTableExplanationModel";
import AddPrizeMoneyTableCard from "../../../../../../components/PrizeMoneyTable/AddPrizeMoneyTableCard";
import PrizeMoneyTableForm from "../../../../../../components/PrizeMoneyTable/PrizeMoneyTableForm";
import PrizeMoneyTableEditTable from "../../../../../../components/PrizeMoneyTable/PrizeMoneyTableEditTable";
import { close } from "ionicons/icons";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

const PrizeMoneyTablesPage: React.FC<OrganizationPageProps> = ({match}) => {
    const [organization, setOrganization] = useState<Organization>();
    const [selectedPrizeMoneyTable, setSelectedPrizeMoneyTable] = useState<PrizeMoneyTable | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOrganization() {
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                setOrganization(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getOrganization();
    }, [match, match.params.id]);

    const onSelectPrizeMoneyTemplate = (table: PrizeMoneyTable, isMobile: Boolean) => {
        setSelectedPrizeMoneyTable(table);
        if (isMobile) {
            setShowModal(true);
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={organization ? organization.name : "Organization"} />
                <IonRow className="ion-justify-content-center">
                    {error && <ErrorAlert width="12" error={error} />}
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Organization Prize Money Templates</IonCardTitle>
                            <IonCardSubtitle><PrizeMoneyTableExplanationModal /></IonCardSubtitle>
                        </IonCard>
                    </IonCol>
                </IonRow>
                {isPlatform("mobile") ?
                    <>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                {organization && (
                                    <AddPrizeMoneyTableCard organizationId={organization.id} handleNewPrizeMoneyTable={() => {console.log("Saw a new prize money table!")}} />
                                )}
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                {organization && (
                                    <PrizeMoneyTableEditTable onSelect={(c: PrizeMoneyTable) => onSelectPrizeMoneyTemplate(c, true)} organizationId={organization.id} />
                                )}
                            </IonCol>
                        </IonRow>
                        <IonModal backdropDismiss={false} isOpen={showModal} id="organizationPrizeMoneyTemplateModal">
                            <IonToolbar color="light">
                                <IonTitle className="ion-text-center">
                                    Edit Organization Prize Money Template
                                </IonTitle>
                                <IonButtons slot="end">
                                    <IonButton
                                        fill="clear"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <p id="closePrizeMoneyTableModalBtn" className="font-weight-normal text-medium text-capitalize">
                                            <IonIcon icon={close} />
                                        </p>
                                    </IonButton>
                                </IonButtons>
                            </IonToolbar>
                            <IonContent className="ion-padding">
                                {organization && selectedPrizeMoneyTable && (
                                    <PrizeMoneyTableForm organizationId={organization.id} prizeMoneyTable={selectedPrizeMoneyTable} onSubmit={() => setShowModal(false)} />
                                )}
                            </IonContent>
                        </IonModal>
                    </>
                    :
                    <>
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white">
                                   {organization && (
                                        <>
                                            <IonCardTitle>Table</IonCardTitle>
                                            <IonCardContent>
                                                <PrizeMoneyTableForm organizationId={organization.id} prizeMoneyTable={selectedPrizeMoneyTable} onSubmit={() => console.log("Desktop - saw a new prize money table")} />
                                            </IonCardContent>
                                        </>
                                   )}
                                </IonCard>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                {organization && (
                                    <PrizeMoneyTableEditTable onSelect={(table: PrizeMoneyTable) => onSelectPrizeMoneyTemplate(table, false)} organizationId={organization.id} />
                                )}
                            </IonCol>
                        </IonRow>
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default PrizeMoneyTablesPage;