import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventClassInput, CreateEventClassMutation, DeleteEventClassInput, DeleteEventClassMutation, GetEventClassQuery, ListEventClassesQuery, UpdateEventClassInput, UpdateEventClassMutation } from "../../API";
import moment from "moment";
import { sortEventClassesByNumber } from "./SortEventClass";
import { EventClass } from "../../models";
import { listBasicEventClasss, eventClassesByEventId, eventClassesByEventIdWithResults, eventClassesByEventIdWithFullEventDivisions } from "./EventClassCustomQueries";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventClass. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventClass.
*/
export async function createEventClass(input: CreateEventClassInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventClass", "No input", "Create EventClass received no input.");
   try {
        const fullInput: CreateEventClassInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventClass, { input: fullInput }))) as GraphQLResult<CreateEventClassMutation>;
       else result = (await API.graphql({
           query: mutations.createEventClass,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventClassMutation>;
       const eventClass = result.data?.createEventClass;
       return formatResult(true, "EventClass", eventClass, "Successfully created the eventClass.");
   } catch (error: any) {
       return formatResult(false, "EventClass", error, "Error creating record in the database for type: eventClass");
   }
}

/**
* Handle updating a new record in the database for type: eventClass. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventClass.
*/
export async function updateEventClass(input: UpdateEventClassInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventClass", "No input", "Update EventClass received no input.");
   try {
        const fullInput: UpdateEventClassInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventClass, { input: fullInput }))) as GraphQLResult<UpdateEventClassMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventClass,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventClassMutation>;
       const eventClass = result.data?.updateEventClass;
       return formatResult(true, "EventClass", eventClass, "Successfully updated the eventClass.");
   } catch (error: any) {
       return formatResult(false, "EventClass", error, "Error updating record in the database for type: eventClass");
   }
}

/**
* Handle deleting a new record in the database for type: eventClass. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventClass.
*/
export async function deleteEventClass(input: DeleteEventClassInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventClass", "No input", "Delete EventClass received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventClass, { input: input }))) as GraphQLResult<DeleteEventClassMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventClass,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventClassMutation>;
       const eventClass = result.data?.deleteEventClass;
       return formatResult(true, "EventClass", eventClass, "Successfully deleted the eventClass.");
   } catch (error: any) {
       return formatResult(false, "EventClass", error, "Error deleting record in the database for type: eventClass");
   }
}

/**
* Get all records in the database for type: eventClass. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventClass object
*/
export async function getAllEventClasses(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventClasses,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventClassesQuery>;
        
        let items = result.data?.listEventClasses?.items as EventClass[];
        let nextToken = result.data?.listEventClasses?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventClasses,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventClassesQuery>;

            const nextItems = nextResult.data?.listEventClasses?.items as EventClass[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventClasses?.nextToken;
        }

        const sorted = sortEventClassesByNumber(items);
        if (sorted) {
            return formatResult(true, "EventClass", sorted, "Successfully got the eventClasses.");
        } else {
            return formatResult(true, "EventClass", items, "Successfully got the eventClasses.");
        }
    } catch (error: any) {
        return formatResult(false, "EventClass", error, "Error reading record in the database for type: eventClasss");
    }
}

/**
* Read a specific record in the database for type: eventClass. 
* 
* @param {string}  id                  The eventClass id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventClass object
*/
export async function getEventClassById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventClass,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventClassQuery>;
       const eventClass = result.data?.getEventClass;
       return formatResult(true, "EventClass", eventClass, "Successfully got the eventClass.");
   } catch (error: any) {
       return formatResult(false, "EventClass", error, "Error reading record in the database for type: eventClass");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventClass. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventClass object
*/
export async function getEventClassesByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventClassesByEventId,
            variables: {
                limit: 1000,
                eventId: eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventClassesByEventId?.items as EventClass[];
        let nextToken = result.data?.eventClassesByEventId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventClassesByEventId,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventClassesByEventId?.items as EventClass[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventClassesByEventId?.nextToken;
        }

        const sorted = sortEventClassesByNumber(items);
        if (sorted) {
            return formatResult(true, "EventClass", sorted, "Successfully got the eventClasses.");
        } else {
            return formatResult(true, "EventClass", items, "Successfully got the eventClasses.");
        }
    } catch (error: any) {
        return formatResult(false, "EventClass", error, "Error reading record in the database for type: eventClasses");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventClass. 
* 
* @param {string}  organizationClassId The organization class id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventClass object
*/
export async function getEventClassesByOrganizationClassId(organizationClassId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationClassId: {eq: organizationClassId}};
        const result = (await API.graphql({
            query: queries.listEventClasses,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventClassesQuery>;

        let items = result.data?.listEventClasses?.items as EventClass[];
        let nextToken = result.data?.listEventClasses?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventClasses,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventClassesQuery>;

            const nextItems = nextResult.data?.listEventClasses?.items as EventClass[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventClasses?.nextToken;
        }

        const sorted = sortEventClassesByNumber(items);
        if (sorted) {
            return formatResult(true, "EventClass", sorted, "Successfully got the eventClasses.");
        } else {
            return formatResult(true, "EventClass", items, "Successfully got the eventClasses.");
        }
    } catch (error: any) {
        return formatResult(false, "EventClass", error, "Error reading record in the database for type: eventClasses");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventClass. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventClass object
*/
export async function getBasicEventClassesByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: listBasicEventClasss,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventClassesQuery>;
        
        let items = result.data?.listEventClasses?.items as EventClass[];
        let nextToken = result.data?.listEventClasses?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: listBasicEventClasss,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventClassesQuery>;

            const nextItems = nextResult.data?.listEventClasses?.items as EventClass[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventClasses?.nextToken;
        }

        const sorted = sortEventClassesByNumber(items);
        if (sorted) {
            return formatResult(true, "EventClass", sorted, "Successfully got the eventClasses.");
        } else {
            return formatResult(true, "EventClass", items, "Successfully got the eventClasses.");
        }
    } catch (error: any) {
        return formatResult(false, "EventClass", error, "Error reading record in the database for type: eventClasses");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventClass. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventClass object
*/
export async function getEventClassesByEventIdWithClassesAndEventDivisions(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: eventClassesByEventId,
            variables: {
                limit: 100,
                eventId: eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.eventClassesByEventId?.items as EventClass[];
        let nextToken = result.data?.eventClassesByEventId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: eventClassesByEventId,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventClassesByEventId?.items as EventClass[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventClassesByEventId?.nextToken;
        }

        const sorted = sortEventClassesByNumber(items);
        if (sorted) {
            return formatResult(true, "EventClass", sorted, "Successfully got the eventClasses.");
        } else {
            return formatResult(true, "EventClass", items, "Successfully got the eventClasses.");
        }
    } catch (error: any) {
        return formatResult(false, "EventClass", error, "Error reading record in the database for type: eventClasses");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventClass. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventClass object
*/
export async function getEventClassesByEventIdWithFullEventDivisions(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: eventClassesByEventIdWithFullEventDivisions,
            variables: {
                limit: 100,
                eventId: eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.eventClassesByEventId?.items as EventClass[];
        let nextToken = result.data?.eventClassesByEventId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: eventClassesByEventIdWithFullEventDivisions,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventClassesByEventId?.items as EventClass[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventClassesByEventId?.nextToken;
        }

        const sorted = sortEventClassesByNumber(items);
        if (sorted) {
            return formatResult(true, "EventClass", sorted, "Successfully got the eventClasses.");
        } else {
            return formatResult(true, "EventClass", items, "Successfully got the eventClasses.");
        }
    } catch (error: any) {
        return formatResult(false, "EventClass", error, "Error reading record in the database for type: eventClasses");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventClass. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventClass object
*/
export async function getEventClassesByEventIdWithResults(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: eventClassesByEventIdWithResults,
            variables: {
                limit: 100,
                eventId: eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.eventClassesByEventId?.items as EventClass[];
        let nextToken = result.data?.eventClassesByEventId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: eventClassesByEventIdWithResults,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventClassesByEventId?.items as EventClass[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventClassesByEventId?.nextToken;
        }

        const sorted = sortEventClassesByNumber(items);
        if (sorted) {
            return formatResult(true, "EventClass", sorted, "Successfully got the eventClasses.");
        } else {
            return formatResult(true, "EventClass", items, "Successfully got the eventClasses.");
        }
    } catch (error: any) {
        return formatResult(false, "EventClass", error, "Error reading record in the database for type: eventClasses");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventClass. 
* 
* @param {string}  eventDivisionId     The event division id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventClass objects
*/
export async function getEventClassesByEventDivisionId(eventDivisionId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventDivisionId: {eq: eventDivisionId}};
        const result = (await API.graphql({
            query: queries.listEventClasses,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventClassesQuery>;

        let items = result.data?.listEventClasses?.items as EventClass[];
        let nextToken = result.data?.listEventClasses?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventClasses,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventClassesQuery>;

            const nextItems = nextResult.data?.listEventClasses?.items as EventClass[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventClasses?.nextToken;
        }

        const sorted = sortEventClassesByNumber(items);
        if (sorted) {
            return formatResult(true, "EventClass", sorted, "Successfully got the eventClasses.");
        } else {
            return formatResult(true, "EventClass", items, "Successfully got the eventClasses.");
        }
    } catch (error: any) {
        return formatResult(false, "EventClass", error, "Error reading record in the database for type: eventClasses");
    }
}