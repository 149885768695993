import { Result } from "../../interfaces/Result";
import { EventClass } from "../../models";


interface formattedOption {
    id: string
    label: string
    number?: (number | null)
    object: any
}

export interface formattedOptionGroup {
    division?: formattedOption
    classes?: formattedOption[]
    class?: formattedOption
}

export async function getFormattedEntryClasses(eventClasses: EventClass[]): Promise<Result> {
    let optionGroups: formattedOptionGroup[] = [];

    let optionMap = new Map();

    if (eventClasses) {

        for (let i = 0; i < eventClasses.length; i++) {
            const currentEventClass: EventClass = eventClasses[i];

            const eventDivisionId = currentEventClass.eventDivision?.id;
            if (eventDivisionId) {
                // This class is part of a division
                const mapResult = optionMap.get(eventDivisionId);
                if (mapResult) {
                    // Have seen this division before -> update the option group
                    const currentOptionGroup: formattedOptionGroup = mapResult;

                    const currentClassArray: formattedOption[] | null | undefined = currentOptionGroup.classes;

                    const classFormattedOption: formattedOption = {
                        id: currentEventClass.id,
                        label: currentEventClass.name || "",
                        object: currentEventClass,
                        number: currentEventClass.number
                    }; 

                    let updatedClassArray: formattedOption[] = [];
                    if (currentClassArray) {
                        updatedClassArray = currentClassArray.concat([classFormattedOption])
                    } else {
                        updatedClassArray = [classFormattedOption];
                    }

                    let updatedOptionGroup: formattedOptionGroup = {
                        division: currentOptionGroup.division,
                        classes: updatedClassArray
                    }

                    optionMap.set(eventDivisionId, updatedOptionGroup);
                } else {
                    // Have not seen this division before -> create a new option group
                    const divisionFormattedOption: formattedOption = {
                        id: eventDivisionId,
                        label: currentEventClass.eventDivision?.name || "",
                        object: currentEventClass.eventDivision
                    };

                    const classFormattedOption: formattedOption = {
                        id: currentEventClass.id,
                        label: currentEventClass.name || "",
                        object: currentEventClass,
                        number: currentEventClass.number
                    }; 

                    const classArray = [classFormattedOption];

                    const optionGroup: formattedOptionGroup = {
                        division: divisionFormattedOption,
                        classes: classArray
                    };

                    optionMap.set(eventDivisionId, optionGroup);
                }
            } else {
                // This is a stand alone class -> create a new option group
                const classFormattedOption: formattedOption = {
                    id: currentEventClass.id,
                    label: currentEventClass.name || "",
                    object: currentEventClass,
                    number: currentEventClass.number
                }; 

                const optionGroup: formattedOptionGroup = {
                    class: classFormattedOption
                };

                optionMap.set(currentEventClass.id, optionGroup);
            }
        }

        optionMap.forEach(element => {
            optionGroups.push(element);
        });

        let result: Result = {isSuccess: true, type: "EventClass", result: optionGroups, message: "Successfully formatted the classes."};
        return result;
    } else {
        let result: Result = {isSuccess: false, type: "EventClass", result: null, message: "There were no classes."};
        return result;
    }
}
