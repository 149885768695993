import "../../theme/select.css";
import { Clinician } from "../../models";
import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { getAllClinicians } from "../../utilities/clinician/Clinician";

interface _Props {
    selectedClinicians?: Clinician[]
    onSelect: Function
}

interface formattedOption {
    label: string
    value: string
    clinician: Clinician
}

const SelectClinicians: React.FC<_Props> = ({selectedClinicians, onSelect}) => {

    const [clinicianOptions, setClinicianOptions] = useState<formattedOption[] | null | undefined>();
    const [selectedformattedClinicians, setSelectedFormattedClinicians] = useState<formattedOption[] | null | undefined>();

    const formatClinicians = (clinicians: Clinician[]) => {
        let formattedClinicians = [];
        for (var i = 0; i < clinicians.length; i++) {
            let object = {
                value: clinicians[i].id,
                label: clinicians[i].name,
                clinician: clinicians[i]
            };
            formattedClinicians.push(object);
        }
        setClinicianOptions(formattedClinicians);
    }

    const formatSelectedValues = (values: Clinician[]) => {
        if (values) {
            let result: formattedOption[] = [];
            for (var i = 0; i < values.length; i++) {
                const currentOption = values[i];
                let object = {
                    value: currentOption.id,
                    label: currentOption.name,
                    clinician: currentOption
                };
                result.push(object);
            }
            setSelectedFormattedClinicians(result);
        }
    };

    const getClinicians = async () => {
        const queryResult = await getAllClinicians();
        if (queryResult.isSuccess) {
            formatClinicians(queryResult.result);
        }
    }

    useEffect(() => {
        getClinicians();
    }, []);

    useEffect(() => {
        if (selectedClinicians) formatSelectedValues(selectedClinicians)
    }, [selectedClinicians]);

    const handleOnChange = (event: any) => {
        if (event && event.length) {
            let result: string[] = [];
            for (var i = 0; i < event.length; i++) {
                result.push(event[i].clinician);
            }
            onSelect(result);
        }
    }

    return (
        <>
            <Select
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                defaultValue={selectedformattedClinicians}
                value={selectedformattedClinicians}
                menuPortalTarget={document.body}
                isMulti
                isClearable
                options={clinicianOptions!}
                onChange={handleOnChange}
            />
        </>
    );
};

export default SelectClinicians;