import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { CreateEventStallSetInput } from "../../API";
import { Event, EventStallSet, OrganizationStallSet } from "../../models";
import { createEventStallSet, deleteEventStallSet, getEventStallSetsByEventId } from "../../utilities/eventStallSet/EventStallSet";
import { getOrganizationStallSetsByOrganizationId } from "../../utilities/organizationStallSet/OrganizationStallSet";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    organizationId: string
    event: Event
}

interface FormattedStallSet {
    isChecked: boolean
    object: OrganizationStallSet
}

const EventStallSetForm: React.FC<_Props> = ({event, organizationId}) => {

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [formattedStallSets, setFormattedStallSets] = useState<FormattedStallSet[] | null | undefined>();
    const [selectedStallSets, setSelectedStallSets] = useState<EventStallSet[] | null | undefined>();
    const [error, setError] = useState<string>("");

    const getEventStallSets = async (eventId: string) => {
        const queryResult = await getEventStallSetsByEventId(eventId);
        if (queryResult.isSuccess) {
            setSelectedStallSets(queryResult.result);
        }
        if (event.organizationId) await getOrganizationStallSets(event.organizationId, queryResult.result);
    }

    const getOrganizationStallSets = async (organizationId: string, selectedEventStallSets?: EventStallSet[]) => {
        const queryResult = await getOrganizationStallSetsByOrganizationId(organizationId);
        if (queryResult.isSuccess) {
            if (selectedEventStallSets) formatOrganizationStallSets(queryResult.result, selectedEventStallSets);
            else formatOrganizationStallSets(queryResult.result);
        } else {
            setError("No organization stall sets found.")
        }
    }

    const formatOrganizationStallSets = (stallSets: OrganizationStallSet[], selectedEventStallSets?: EventStallSet[]) => {
        let result: FormattedStallSet[] = [];
        for (var i = 0; i < stallSets.length; i++) {
            const stallSet = stallSets[i];
            let checked = false;
            if (selectedEventStallSets && selectedEventStallSets.length) {
                selectedEventStallSets.forEach(set => {
                    if (set.organizationStallSetId === stallSet.id) checked = true;
                });
            }
            const formatted: FormattedStallSet = {
                isChecked: checked,
                object: stallSet
            };
            result.push(formatted);
        }
        setFormattedStallSets(result);
    }

    useEffect(() => {
        if (event) getEventStallSets(event.id);
    }, [event]);

    const handleCreateEventStallSets = async (organizationStallSet: OrganizationStallSet) => {
        if (event && organizationId && organizationStallSet) {
            const input: CreateEventStallSetInput = {
                eventId: event.id,
                organizationId: organizationId,
                organizationStallSetId: organizationStallSet.id,
                name: organizationStallSet.name || "",
                location: organizationStallSet.location || "",
                available: organizationStallSet.available || ""
            };
            const createResult = await createEventStallSet(input);
            if (!createResult.isSuccess) {
                setError("Sorry, we had a problem creating the stall set.");
            }
        } else {
            setError("Sorry, we could not find your organization.")
        }
    }

    const handleDeleteEventStallSets = async (eventStallSet: EventStallSet) => {
        if (event) {
            const deleteResult = await deleteEventStallSet({id: eventStallSet.id});
            if (!deleteResult.isSuccess) {
                setError("Sorry, we had a problem removing the stall set.");
            }
        } else {
            setError("Sorry, we could not find your organization.")
        }
    }

    const handleSave = async () => {
        // First delete the old event stall sets
        if (selectedStallSets) {
            for (var i = 0 ; i < selectedStallSets.length; i++) {
                const currentEventStallSet: EventStallSet = selectedStallSets[i];
                if (currentEventStallSet) await handleDeleteEventStallSets(currentEventStallSet);
            }
        }

        // Then create new event stall sets
        if (formattedStallSets) {
            for (var j = 0 ; j < formattedStallSets.length; j++) {
                const currentFormattedStallSet: FormattedStallSet = formattedStallSets[j];
                if (currentFormattedStallSet.isChecked) {
                    const currentOrganizationStallSet: OrganizationStallSet = currentFormattedStallSet.object;
                    await handleCreateEventStallSets(currentOrganizationStallSet);
                }
            }
        }

        // Finally, make sure lists are updated
        if (event) getEventStallSets(event.id);
    }

    const handleOnChange = (index: number, stallSet: OrganizationStallSet) => {
        setIsDisabled(false);
        if (formattedStallSets) {
            const selectedStallSet = formattedStallSets[index];
            const updatedStallSet: FormattedStallSet = {
                isChecked: !selectedStallSet.isChecked,
                object: stallSet
            };
            const updatedArray: FormattedStallSet[] = [
                ...formattedStallSets.slice(0, index),
                updatedStallSet,
                ...formattedStallSets.slice(index + 1)
            ];
            setFormattedStallSets(updatedArray);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        await handleSave();
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {formattedStallSets ? 
                <>
                    <IonList className="bg-white">
                        {formattedStallSets.map((formattedStallSet: FormattedStallSet, index: number) => (
                            <IonItem key={index}>
                                <IonCheckbox slot="start" color="primary" checked={formattedStallSet.isChecked} value={formattedStallSet.object.id}  onClick={() => handleOnChange(index, formattedStallSet.object)}/>
                                <IonLabel className="ion-text-wrap">
                                    {formattedStallSet.object.name} 
                                </IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                    <IonRow>
                        <IonCol sizeMd="4">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    disabled={isDisabled}
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    {isDisabled ? "Saved" : "Save"}
                                </IonButton>
                            }
                        </IonCol>
                    </IonRow>
                </>
                :
                <p>No organization stall sets. Go to your organization settings to update.</p>
            }
        </>
    );
};

export default EventStallSetForm;