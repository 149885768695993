import React, { useState } from "react";
import { IonCol, IonRow } from "@ionic/react";
import { Barn, Horse } from "../../../models";
import HorseHeightInput from "../../Horse/HorseHeightInput";
import { Input, Label } from "reactstrap";
import { breeds } from "../../../utilities/horse/Breed";
import SelectOrCreateHorse from "../../Horse/SelectOrCreateHorse";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";

interface _Props {
    barn?: Barn | null;
    selectedHorse?: Horse | null;
    onSelectHorse?: (horse?: Horse) => void;
}

const SelectHorseSection: React.FC<_Props> = ({ barn, selectedHorse, onSelectHorse }) => {

    const [name, setName] = useState("");
    const [height, setHeight] = useState("");
    const [sex, setSex] = useState("");
    const [breed, setBreed] = useState("");

    const handleSelectedHorse = (horse?: Horse) => {
        onSelectHorse?.(horse);  // Call the prop function to update the parent state
        setName(horse?.name || "");
        setHeight(horse?.height || "");
        setSex(horse?.sex || "");
        setBreed(horse?.breed || "");
    };

    return (
        <>
            {/* First Row - Horse Name and Height */}
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <Label>
                        Name
                        <RequiredInputIndicator />
                    </Label>
                    <SelectOrCreateHorse selectedBarn={barn} selectedValue={selectedHorse ? selectedHorse.id : null} onSelect={handleSelectedHorse} />
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <HorseHeightInput isRequired height={selectedHorse?.height ? parseFloat(selectedHorse.height) : undefined} onChange={() => console.log("Horse height was updated.")} />
                </IonCol>
            </IonRow>

            {/* Second Row - Horse Sex and Breed */}
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <Label for="sexSelect">Sex</Label>
                    <Input
                        type="select" 
                        name="select"
                        value={sex}
                        id="sexSelect"
                        onChange={e => setSex(e.target.value)}
                    >
                        <option>-</option>
                        <option>Gelding</option>
                        <option>Mare</option>
                        <option>Stallion</option>
                        <option>Colt</option>
                    </Input>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <Label for="breedSelect">Breed</Label>
                    <Input 
                        type="select" 
                        name="select"
                        value={breed}
                        id="breedSelect"
                        onChange={e => setBreed(e.target.value)}
                    >
                        <option>-</option>
                        {breeds.map((breed, index) => (
                            <option key={index}>{breed}</option>
                        ))}
                    </Input>
                </IonCol>
            </IonRow>
        </>
    );
};

export default SelectHorseSection;
