import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { Barn } from "../../../../models";
import { getBarnById } from "../../../../utilities/barn/Barn";
import { formatAddress } from "../../../../utilities/address/FormatAddress";
import Spinner from "../../../../components/Spinners/Spinner";
import { PersonContext } from "../../../../context/PersonContext";
import UpdateBarnForm from "../../../../components/Barn/UpdateBarnForm";

interface BarnPageProps extends RouteComponentProps<{
    id: string;
}> {}

const BarnBasicInfoPage: React.FC<BarnPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [barn, setBarn] = useState<Barn>();
    const [userCanEditInfo, setUserCanEditInfo] = useState(false);
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getBarn() {
            setIsLoading(true);
            const queryResult = await getBarnById(match.params.id);
            if (queryResult.isSuccess) {
                const currentBarn: Barn = queryResult.result;
                setBarn(currentBarn);
                if (user.id === currentBarn.ownerPersonId) setUserCanEditInfo(true);
                else if (user.roles?.includes("admin")) setUserCanEditInfo(true);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
            setIsLoading(false);
        }

        getBarn();
    }, [match, match.params.id]);

    const handleBarnUpdate = (updatedBarn: Barn) => {
        setBarn(updatedBarn);
        setIsInEditMode(false);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={barn ? barn.name : "Org Info"} />
                <IonRow className="ion-justify-content-center">
                    {error && <ErrorAlert width="12" error={error} />}
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        Barn Info
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        {userCanEditInfo && (
                                            <IonRow>
                                                <IonCol>
                                                    <IonButton color={isInEditMode ? "light" : "tertiary"} onClick={() => setIsInEditMode(!isInEditMode)}>
                                                        {isInEditMode ? "Cancel" : "Edit Barn Info"}
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        )}
                                    </IonCol>
                                </IonRow>
                            </IonCardTitle>
                            {isLoading ?
                                <Spinner />
                                :
                                <IonCardContent>
                                    {barn ?
                                        <>
                                            {isInEditMode ?
                                                <UpdateBarnForm currentBarn={barn} onChange={(updatedBarn: Barn) => handleBarnUpdate(updatedBarn)} />
                                                :
                                                <>
                                                    <IonRow>
                                                        <IonCol>
                                                            <h2>{barn.name}</h2>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p>Created by: {barn.ownerName}</p>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p>Website: {barn.contact ? barn.contact.website : "unknown"}</p>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p>Phone Number: {barn.contact ? barn.contact.work : "unknown"}</p>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p>Email Address: {barn.contact ? barn.contact.workEmail : "unknown"}</p>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p>Location: {barn.location ? formatAddress(barn.location) : "unknown"}</p>
                                                        </IonCol>
                                                    </IonRow>
                                                </>
                                            }
                                        </>
                                        :
                                        <p>Loading barn....</p>
                                    }
                                </IonCardContent>
                            }
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default BarnBasicInfoPage;