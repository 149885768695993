export const ratingList = [
    {value: 1, label:"Premier"}, 
    {value: 2, label: "National"}, 
    {value: 3, label: "Regional I"}, 
    {value: 4, label: "Regional II"}, 
    {value: 5, label: "Local"}, 
    {value: 6, label: "Gold"},
    {value: 7, label: "Silver"},
    {value: 8, label: "Bronze"}, 
    {value: 9, label: "5*"}, 
    {value: 10, label: "4*"}, 
    {value: 11, label: "3*"}, 
    {value: 12, label: "2*"}, 
    {value: 13, label: "1*"}, 
    {value: 14, label: "1"}, 
    {value: 15, label: "2"}, 
    {value: 16, label: "3"}, 
    {value: 17, label: "4"}, 
    {value: 18, label: "5"}, 
    {value: 19, label: "6"}, 
    {value: 20, label:"AA"}, 
    {value: 21, label: "A"}, 
    {value: 22, label: "B"}, 
    {value: 23, label: "C"}, 
    {value: 24, label: "No Rating"}, 
    {value: 25, label: "Other"}, 
];