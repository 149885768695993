import {
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import { Event, EventAuditor, Organization, OrganizationAuditor } from "../../../models";
import { getEventAuditorsByEventId } from "../../../utilities/eventAuditor/EventAuditor";
import { getOrganizationAuditorsByOrganizationId } from "../../../utilities/organizationAuditor/OrganizationAuditor";
import ErrorAlert from "../../Errors/ErrorAlert";
import EventAuditorModal from "../../EventAuditor/EventAuditorModal";
import Spinner from "../../Spinners/Spinner";

interface _Props {
    event?: Event
    organization?: Organization
    eventAuditor?: EventAuditor
}

const EventAuditorForm: React.FC<_Props> = ({event, organization, eventAuditor}) => {    
    const [eventAuditors, setEventAuditors] = useState<EventAuditor[] | null | undefined>();
    const [selectedEventAuditor, setSelectedEventAuditor] = useState<EventAuditor | null | undefined>();

    const [organizationAuditors, setOrganizationAuditors] = useState<OrganizationAuditor[] | null | undefined>();
    const [selectedOrganizationAuditor, setSelectedOrganizationAuditor] = useState<OrganizationAuditor | null | undefined>();

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEventAuditors(event: Event) {
            setIsLoading(true);
            const queryResult = await getEventAuditorsByEventId(event.id);
            if (queryResult.isSuccess) {
                const eventAuditors: EventAuditor[] = queryResult.result;
                setEventAuditors(eventAuditors);
            }
            setIsLoading(false);
        }
        if (event) {
            getEventAuditors(event);
        }
    }, [event]);

    useEffect(() => {
        async function getOrganizationAuditors(organization: Organization) {
            const queryResult = await getOrganizationAuditorsByOrganizationId(organization.id);
            if (queryResult.isSuccess) {
                const organizationAuditors: OrganizationAuditor[] = queryResult.result;
                setOrganizationAuditors(organizationAuditors);
            }
        }
        if (organization) {
            getOrganizationAuditors(organization);
        }
    }, [organization]);

    useEffect(() => {
        if (eventAuditor) {
            if (eventAuditors) {
                const updated = eventAuditors.concat(eventAuditor);
                setEventAuditors(updated);
            } else {
                setEventAuditors([eventAuditor]);
            }
        }
    }, [eventAuditor]);

    const handleSelectedSubscriber = (eventAuditor: EventAuditor) => {
        setSelectedEventAuditor(eventAuditor);
        setShowModal(true);
    }

    const handleEventAuditorChange = (action: string, eventAuditor: EventAuditor) => {
        let updated: EventAuditor[] = [eventAuditor];
        if (action === "create") {
            if (eventAuditors) updated = updated.concat(eventAuditors);
        } else if (action === "delete") {
            if (eventAuditors) {
                const deletedIndex = eventAuditors.findIndex(eA => eventAuditor.id === eA.id);
                updated = [
                    ...eventAuditors.slice(0, deletedIndex),
                    ...eventAuditors.slice(deletedIndex + 1)
                ];
            }
        } else {
            if (eventAuditors) {
                const index = eventAuditors.findIndex(auditor => auditor.id === eventAuditor.id);
                updated = [
                    ...eventAuditors.slice(0, index),
                    eventAuditor,
                    ...eventAuditors.slice(index + 1)
                ];
            } 
        }
        setEventAuditors(updated);
        setShowModal(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ?
                <Spinner />
                :
                <>
                    {eventAuditors ? 
                        <IonList className="bg-white">
                            {eventAuditors.map((eventAuditor, index) => (
                                <IonItem key={index} onClick={() => handleSelectedSubscriber(eventAuditor)}>
                                    <IonLabel>
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="6" className="ion-text-wrap">{index+1 + ") " + eventAuditor.name}</IonCol>
                                            {/* <IonCol sizeXs="12" sizeMd="4" className="ion-text-wrap">{eventAuditor.phoneNumber}</IonCol> */}
                                            <IonCol sizeXs="12" sizeMd="6" className="ion-text-wrap">Status: <span className={(eventAuditor.status === "paused" || eventAuditor.status === "stopped") ? "text-danger" : "text-primary"}>{eventAuditor.status === "paused" ? "Paused" : eventAuditor.status === "stopped" ? "Stopped" : "Active"}</span></IonCol>
                                        </IonRow>
                                    </IonLabel>
                                </IonItem>
                            ))}
                        </IonList>
                        :
                        <>
                            {organizationAuditors ? 
                                <IonList className="bg-white">
                                    {organizationAuditors.map((organizationAuditor, index) => (
                                        <IonItem key={index}>
                                            <IonLabel>
                                                <IonRow>
                                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-wrap">{index+1 + ") " + organizationAuditor.name}</IonCol>
                                                    {/* <IonCol sizeXs="12" sizeMd="4" className="ion-text-wrap">{organizationAuditor.phoneNumber}</IonCol> */}
                                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-wrap">Status: <span className={(organizationAuditor.status === "paused" || organizationAuditor.status === "stopped") ? "text-danger" : "text-primary"}>{organizationAuditor.status === "paused" ? "Paused" : organizationAuditor.status === "stopped" ? "Stopped" : "Active"}</span></IonCol>
                                                </IonRow>
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                </IonList>
                                :
                                <p>No subscribers yet.</p>
                            }
                        </>
                    }
                </>
            }
            {event && (
                <EventAuditorModal show={showModal} eventAuditor={selectedEventAuditor} event={event} isAdminView={true} onChange={(action: string, eventAuditor: EventAuditor) => handleEventAuditorChange(action, eventAuditor)} />
            )}
        </>
    );
};

export default EventAuditorForm;
