import { UpdateBeddingRequestInput, UpdateStablingRequestInput } from "../../API";
import { Result } from "../../interfaces/Result";
import { StablingRequest } from "../../models";
import { getBeddingRequestsByEntryId, updateBeddingRequest } from "../beddingRequest/BeddingRequest";
import { getStablingRequestsByEventId, getStablingRequestsByPersonIdEventId, updateStablingRequest } from "./StablingRequest";

export async function updateStablingRequestStatusByEntryId(eventId: string, entryId: string, status: string) {
    let requestList: StablingRequest[] = [];
    const queryResult = await getStablingRequestsByEventId(eventId);
    if (queryResult.isSuccess) {
        const allStablingRequests = queryResult.result;
        allStablingRequests.forEach((sr: StablingRequest) => {
            const currentEntries = sr.entryIds;
            if (currentEntries && currentEntries.length) {
                currentEntries.forEach(currentEntry => {
                    if (currentEntry === entryId) requestList.push(sr);
                });
            }
        });
    }

    if (requestList && requestList.length > 0) {
        // Loop through the stabling requests and update their statuses
        let isSuccess = true;
        let message = "";
        for (var i = 0; i < requestList.length; i++) {
            const input: UpdateStablingRequestInput = {
                id: requestList[i].id,
                status: status
            };
            const updateResult = await updateStablingRequest(input);
            if (!updateResult.isSuccess) {
                isSuccess = false;
                message = message + "Could not update the status on stabling request for: " + requestList[i].id + ". ";
            }
        }
        if (isSuccess) {
            let result: Result = {isSuccess: true, type: "StablingRequest", result: null, message: "Successfully updated the stabling request statuses."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "StablingRequest", result: null, message: message};
            return result; 
        }
    } else {
        let result: Result = {isSuccess: true, type: "StablingRequest", result: null, message: "Could not find any stabling requests."};
        return result; 
    }
}

export async function updateBeddingRequestStatusByEntryId(entryId: string, status: string) {
    const queryResult = await getBeddingRequestsByEntryId(entryId);
    if (queryResult.isSuccess) {
        // Loop through the bedding requests and update their statuses
        let isSuccess = true;
        let message = "";
        const list = queryResult.result;
        for (var i = 0; i < list.length; i++) {
            const input: UpdateBeddingRequestInput = {
                id: list[i].id,
                status: status
            };
            const updateResult = await updateBeddingRequest(input);
            if (!updateResult.isSuccess) {
                isSuccess = false;
                message = message + "Could not update the status on bedding request for: " + list[i].id + ". ";
            }
        }
        if (isSuccess) {
            let result: Result = {isSuccess: true, type: "BeddingRequest", result: null, message: "Successfully updated the bedding request statuses."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "BeddingRequest", result: null, message: message};
            return result; 
        }
    } else {
        let result: Result = {isSuccess: true, type: "BeddingRequest", result: null, message: "Could not find any bedding requests."};
        return result; 
    }
}

export async function updateStablingRequestStatusByPersonByEvent(personId: string, eventId: string, status: string) {
    const queryResult = await getStablingRequestsByPersonIdEventId(personId, eventId);
    if (queryResult.isSuccess) {
        // Loop through the stabling requests and update their statuses
        let isSuccess = true;
        let message = "";
        const list = queryResult.result;
        for (var i = 0; i < list.length; i++) {
            const input: UpdateStablingRequestInput = {
                id: list[i].id,
                status: status
            };
            const updateResult = await updateStablingRequest(input);
            if (!updateResult.isSuccess) {
                isSuccess = false;
                message = message + "Could not update the status on stabling request for: " + list[i].id + ". ";
            }
        }
        if (isSuccess) {
            let result: Result = {isSuccess: true, type: "StablingRequest", result: null, message: "Successfully updated the stabling request statuses."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "StablingRequest", result: null, message: message};
            return result; 
        }
    } else {
        let result: Result = {isSuccess: true, type: "StablingRequest", result: null, message: "Could not find any stabling requests."};
        return result; 
    }
}

export async function updateStablingRequestStatusByPersonByEventByEntry(personId: string, eventId: string, entryId: string, status: string) {
    const queryResult = await getStablingRequestsByPersonIdEventId(personId, eventId);
    if (queryResult.isSuccess) {
        // Loop through the stabling requests and update their statuses
        let isSuccess = true;
        let message = "";
        const list = queryResult.result;
        for (var i = 0; i < list.length; i++) {
            const currentStablingRequest: StablingRequest = list[i];
            const index = currentStablingRequest.entryIds?.findIndex(e => e === entryId);
           if (index && index > -1) {
                const input: UpdateStablingRequestInput = {
                    id: currentStablingRequest.id,
                    status: status
                };
                const updateResult = await updateStablingRequest(input);
                if (!updateResult.isSuccess) {
                    isSuccess = false;
                    message = message + "Could not update the status on stabling request for: " + currentStablingRequest.id + ". ";
                }
           }
        }
        if (isSuccess) {
            let result: Result = {isSuccess: true, type: "StablingRequest", result: null, message: "Successfully updated the stabling request statuses."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "StablingRequest", result: null, message: message};
            return result; 
        }
    } else {
        let result: Result = {isSuccess: true, type: "StablingRequest", result: null, message: "Could not find any stabling requests."};
        return result; 
    }
}