import {
    IonContent,
    IonPage
} from "@ionic/react";
import Header from "../../../../../../components/Headers/Header";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import React, { useEffect, useState } from "react";
import MembershipTypeForm from "../../../../../../components/MembershipTypes/MembershipTypeForm";
import { getOrganizationById } from "../../../../../../utilities/organization/Organization";
import { Organization } from "../../../../../../models";
import Spinner from "../../../../../../components/Spinners/Spinner";
import ErrorAlert from "../../../../../../components/Errors/ErrorAlert";
import { RouteComponentProps } from "react-router";

interface _Props extends RouteComponentProps<{
    id: string;
}> {}

const CreateMembershipTypePage: React.FC<_Props> = ({match}) => {
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        async function getOrganization() {
            setIsLoading(true);
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                setOrganization(queryResult.result);
            } else {
                setError(queryResult.message);
            }
            setIsLoading(false);
        }
        getOrganization();
    }, [match, match.params.id]);
   
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent id="membership-type-form">
                {error && <ErrorAlert width="12" error={error} />}
                <PageTitle title={"Create Membership Type"} />
                    {organization && !isLoading ?
                        <MembershipTypeForm organization={organization}/>
                    :   
                        <Spinner/>
                    }
            </IonContent>
        </IonPage>
    );
};

export default CreateMembershipTypePage;
