import { IonCard, IonCardContent, IonCol, IonIcon, IonLabel, IonRow } from "@ionic/react";
import React from "react";
import {informationCircleOutline} from "ionicons/icons";

interface _Props {
    info: string
}

const InformationBanner: React.FC<_Props> = ({info}) => {
    return (
        <>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeSm="12" sizeMd="12" className="ion-no-padding">
                    <IonCard color="light" mode="md">
                        <IonCardContent>
                            <IonIcon icon={informationCircleOutline} />
                            <IonLabel id="infoMsg" className="ion-text-wrap description">
                                {" " + info}
                            </IonLabel>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </>
    );
};

export default InformationBanner;
