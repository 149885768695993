import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateResponseInput, CreateResponseMutation, DeleteResponseInput, DeleteResponseMutation, GetResponseQuery, ListResponsesQuery, UpdateResponseInput, UpdateResponseMutation } from "../../API";
import { Response } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: response. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created response.
*/
export async function createResponse(input: CreateResponseInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "Response", "No input", "Create Response received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createResponse, { input: input }))) as GraphQLResult<CreateResponseMutation>;
        else result = (await API.graphql({
            query: mutations.createResponse,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<CreateResponseMutation>;
        const response = result.data?.createResponse;
        return formatResult(true, "Response", response, "Successfully created the response.");
    } catch (error: any) {
        return formatResult(false, "Response", error, "Error creating record in the database for type: response");
    }
}

/**
* Handle updating a new record in the database for type: response. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated response.
*/
export async function updateResponse(input: UpdateResponseInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "Response", "No input", "Update Response received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateResponse, { input: input }))) as GraphQLResult<UpdateResponseMutation>;
        else result = (await API.graphql({
            query: mutations.updateResponse,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<UpdateResponseMutation>;
        const response = result.data?.updateResponse;
        return formatResult(true, "Response", response, "Successfully updated the response.");
    } catch (error: any) {
        return formatResult(false, "Response", error, "Error updating record in the database for type: response");
    }
}

/**
* Handle deleting a new record in the database for type: response. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted response.
*/
export async function deleteResponse(input: DeleteResponseInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Response", "No input", "Delete Response received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteResponse, { input: input }))) as GraphQLResult<DeleteResponseMutation>;
       else result = (await API.graphql({
           query: mutations.deleteResponse,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteResponseMutation>;
       const response = result.data?.deleteResponse;
       return formatResult(true, "Response", response, "Successfully deleted the response.");
   } catch (error: any) {
       return formatResult(false, "Response", error, "Error deleting record in the database for type: response");
   }
}

/**
* Get all records in the database for type: response. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the response object
*/
export async function getAllResponses(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listResponses,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListResponsesQuery>;
        
        let items = result.data?.listResponses?.items as Response[];
        let nextToken = result.data?.listResponses?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listResponses,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListResponsesQuery>;

            const nextItems = nextResult.data?.listResponses?.items as Response[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listResponses?.nextToken;
        }

        return formatResult(true, "Response", items, "Successfully got the Responses.");
    } catch (error: any) {
        return formatResult(false, "Response", error, "Error reading record in the database for type: responses");
    }
}

/**
* Read a specific record in the database for type: response. 
* 
* @param {string}  id                  The response id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the response object
*/
export async function getResponseById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getResponse,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetResponseQuery>;
       const response = result.data?.getResponse;
       return formatResult(true, "Response", response, "Successfully got the response.");
   } catch (error: any) {
       return formatResult(false, "Response", error, "Error reading record in the database for type: response");
   }
}

/**
* Get all records in the database that match the given criteria for type: response. 
* 
* @param {string}  pollId              The poll id
* @param {string}  personId            The person id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the response object
*/
export async function getResponseByPollIdPersonId(pollId: string, personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {pollId: {eq: pollId}, personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listResponses,
            variables: {
                limit: 10,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListResponsesQuery>;

        let items = result.data?.listResponses?.items as Response[];
        let nextToken = result.data?.listResponses?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listResponses,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListResponsesQuery>;

            const nextItems = nextResult.data?.listResponses?.items as Response[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listResponses?.nextToken;
        }
        
        const responses = items;
        if (responses && responses[0]) return formatResult(true, "Response", responses[0], "Successfully got the response.");
        else return formatResult(false, "Response", null, "Could not find the response.");
    } catch (error: any) {
        return formatResult(false, "Response", error, "Error reading record in the database for type: response");
    }
}

/**
* Get all records in the database that match the given criteria for type: response. 
* 
* @param {string}  personId            The person id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the response object
*/
export async function getResponsesByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listResponses,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListResponsesQuery>;

        let items = result.data?.listResponses?.items as Response[];
        let nextToken = result.data?.listResponses?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listResponses,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListResponsesQuery>;

            const nextItems = nextResult.data?.listResponses?.items as Response[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listResponses?.nextToken;
        }

        return formatResult(true, "Response", items, "Successfully got the Responses.");
    } catch (error: any) {
        return formatResult(false, "Response", error, "Error reading record in the database for type: responses");
    }
}

/**
* Get all records in the database that match the given criteria for type: response. 
* 
* @param {string}  pollId              The poll id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the response object
*/
export async function getResponsesByPollId(pollId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {pollId: {eq: pollId}};
        const result = (await API.graphql({
            query: queries.listResponses,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListResponsesQuery>;
        
        let items = result.data?.listResponses?.items as Response[];
        let nextToken = result.data?.listResponses?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listResponses,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListResponsesQuery>;

            const nextItems = nextResult.data?.listResponses?.items as Response[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listResponses?.nextToken;
        }

        return formatResult(true, "Response", items, "Successfully got the Responses.");
    } catch (error: any) {
        return formatResult(false, "Response", error, "Error reading record in the database for type: responses");
    }
}