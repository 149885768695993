
/**
 * Takes the membership id values and availableMembershipIdValues from the organization membership type record
 * and returns the next available membership id
 * 
 * @returns membership id 
 */

import { OrganizationMembershipType } from "../../models";
import CONSTANT from "../../constant/constant";

export function getNextAvailableMembershipId(idValues: string, currentMembershipId: string): string {
    let membershipId = "";
    if(idValues && idValues.length > 0) {
        const splitArr = idValues.split(",");
        splitArr.every((element: string, arrayIndex: number) => {
            // remove the empty space
            element.trim();
            // check if "-"" present in the text
            const index = element.indexOf("-");
            if(index >= 0) {
                const arr = element.split("-");
                const num1 = parseInt(arr[0]);
                const num2 = parseInt(arr[1]);
                // if currentMembershipId = "" then assign first number as initial id
                if(currentMembershipId === "" || parseInt(currentMembershipId) < num1) {
                    membershipId = num1.toString();
                    return false;
                } else if (num1 <= parseInt(currentMembershipId) &&
                parseInt(currentMembershipId) < num2) {
                    // if currentMembershipId is lies in between range then increment currentMembershipId
                    // by 1 and return id
                    membershipId = `${parseInt(currentMembershipId) + 1}`;
                    return false;
                }
                if (membershipId === "" && arrayIndex === splitArr.length - 1) {
                    // we are done the range and iterating over the last value of array then just increment currentMembershipId
                    // by 1 and return id 
                    membershipId = `${parseInt(currentMembershipId) + 1}`;
                    return false;
                }
                return true;
            } else {
                // if currentMembershipId = "" then assign number as initial id
                if (currentMembershipId === "") {
                    membershipId = element;
                    return false;
                }
                if (parseInt(element) !== parseInt(currentMembershipId) &&
                parseInt(element) > parseInt(currentMembershipId)) {
                    // check if currentMembershipId and single number not equal and single number is
                    // greater than currentMembershipId then return single number as id
                    membershipId = `${parseInt(element)}`;
                    return false;
                } else if (membershipId === "" && arrayIndex === splitArr.length - 1) {
                    // we are done the range and single numbers and iterating over the last value of array then just increment currentMembershipId
                    // by 1 and return id 
                    membershipId = `${parseInt(currentMembershipId) + 1}`;
                    return false;
                }
                return true;
            }
        });
    }
    return membershipId;
}

/**
 * Takes the membershipIdValues from input and membershipType object and checks if entered value is valid or not
 * 
 * @returns string  
 */
 export function isMembershipIdValueValid(idValues: string, membershipType: OrganizationMembershipType | undefined): string {
    let flag = true;
    let msg = "";
    let prevNumber = 0;
    if(idValues && idValues.length > 0) {
        const splitArr = idValues.split(",");
        splitArr.every((element: string) => {
            // remove the empty space
            element.trim();
            // check if "-"" present in the text
            const index = element.indexOf("-");
            if(index >= 0) {
                const arr = element.split("-");
                // check if split function returned array with two values
                if(arr.length === 2) {
                    // check if both values are valid number
                    let number = !isNaN(arr[0] as any) && !isNaN(arr[1] as any) &&
                        arr[0].length > 0 && arr[1].length > 0;
                    // check if both are valid numbers and first number is greater than previous number
                    if(number === true && parseInt(arr[0]) > prevNumber) {
                        // check if first number is greater than second number
                        if(parseInt(arr[0]) > parseInt(arr[1])) {
                            flag = false;
                            return flag;
                        }
                        prevNumber = parseInt(arr[1]);
                        return flag;
                    } else {
                        flag = false;
                        return flag;
                    }
                } else {
                    // split function returned array with less than or more than two values
                    flag = false;
                    return flag;
                }
            } else {
                // check if the single value entered is number or not
                if(isNaN(element as any)) {
                    flag = false;
                    return flag;
                }
                // check if current number is less or equal to than previous number
                if(parseInt(element) <= prevNumber) {
                    flag = false;
                    return flag;
                }
                prevNumber = parseInt(element);
                return flag;
            }            
        });
    }
    if(!flag) {
        return CONSTANT.MEMBERSHIP.MEMBERSHIP_ID_VALUES_ERROR_MSG;
    }
    // Check if user tries to edit the values which are less than the next available Membership Id.
    const nextId = membershipType?.nextAvailableMembershipId;
    if(flag && membershipType?.membershipIdValues && nextId) {
        const splitArrayCurrent = idValues.split(",");
        const splitArrayOld = membershipType?.membershipIdValues.split(",");
        splitArrayOld.every((oldValue: string, i: number) => {
            const newValue = splitArrayCurrent[i];
            if(!newValue) {
                if(isNextIdIsInRange(oldValue, nextId) === true) {
                    flag = false;
                    msg = CONSTANT.MEMBERSHIP.MEMBERSHIP_NEXT_AVAILABLE_ID_ERR_MSG_1;
                    return flag;
                }
                return true;
            }
            if(oldValue.trim() === newValue.trim()) {
                return true;
            } else {
                // check if "-" present in the text
                const index = newValue.indexOf("-");
                if(index >= 0) {
                    const arr = newValue.split("-");
                    const firstNo = parseInt(arr[0]);
                    const secondNo = parseInt(arr[1]);
                    if(isNextIdIsInRange(newValue, nextId) === true || isNextIdIsInRange(oldValue, nextId) === true) {
                        const oldValueArray = oldValue.split("-");
                        const firstNoOld = parseInt(oldValueArray[0]);
                        if(firstNo === firstNoOld && secondNo >= parseInt(nextId)) {
                            return true;
                        }
                        flag = false;
                        msg = CONSTANT.MEMBERSHIP.MEMBERSHIP_NEXT_AVAILABLE_ID_ERR_MSG_1;
                        return flag;
                    }
                    if(firstNo < parseInt(nextId) && secondNo <= parseInt(nextId)) {
                        flag = false;
                        msg = CONSTANT.MEMBERSHIP.MEMBERSHIP_NEXT_AVAILABLE_ID_ERR_MSG_2;
                        return flag;
                    }
                    return flag;
                } else {
                    if(parseInt(newValue) <= parseInt(nextId)) {
                        flag = false;
                        msg = CONSTANT.MEMBERSHIP.MEMBERSHIP_NEXT_AVAILABLE_ID_ERR_MSG_2;
                        return flag;
                    }
                    if(isNextIdIsInRange(newValue, nextId) === true || isNextIdIsInRange(oldValue, nextId) === true) {
                        flag = false;
                        msg = CONSTANT.MEMBERSHIP.MEMBERSHIP_NEXT_AVAILABLE_ID_ERR_MSG_1;
                        return flag;
                    }
                    return flag;
                }
            }
        });
    }
    return msg;
}

function isNextIdIsInRange(value: string, nextId: string) {
    let flag = false;
    const index = value.indexOf("-");
    if(index >= 0) {
        const arr = value.split("-");
        const firstNo = parseInt(arr[0]);
        const secondNo = parseInt(arr[1]);
        if(firstNo <= parseInt(nextId) && secondNo >= parseInt(nextId)) {
            flag = true;
        }
    } else if (parseInt(value) === parseInt(nextId)) {
        flag = true;
    }
    return flag;
}
