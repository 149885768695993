import { 
    IonButton,
    IonButtons,
    IonCheckbox,
    IonCol, 
    IonContent, 
    IonIcon, 
    IonInput, 
    IonItem, 
    IonLabel, 
    IonModal, 
    IonRow, 
    IonTitle, 
    IonToolbar
} from "@ionic/react";
import React, { useState } from "react";
import { Event, EventDay, EventRing } from "../../../models";
import EventReportCard from "../EventReportCard";
import SelectEventDay from "../../EventDay/SelectEventDay";
import SelectEventRing from "../../EventRing/SelectEventRing";
import { close, downloadOutline, printOutline } from "ionicons/icons";
import InformationBanner from "../../Banners/InformationBanner";
import { getScheduleItemsByEventIdByDayByRing } from "../../../utilities/scheduleItem/ScheduleItem";
import { generateSchedulePDF } from "../../../utilities/reports/SchedulePDF";

interface _Props {
    event: Event
}

const EventReportDailySchedulesSection: React.FC<_Props> = ({event}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [progressMessage, setProgressMessage] = useState("");
    const [error, setError] = useState("");

    const [showDailyScheduleModal, setShowDailyScheduleModal] = useState(false);
    const [includeTable, setIncludeTable] = useState(false);
    const [includeClassTotals, setIncludeClassTotals] = useState(false);
    const [selectedEventDay, setSelectedEventDay] = useState<EventDay | null | undefined>();
    const [selectedEventRing, setSelectedEventRing] = useState<EventRing | null | undefined>();
    const [fontSize, setFontSize] = useState(10);

    const generateDailySchedule = async (isDownload?: boolean) => {
        setShowDailyScheduleModal(false);
        if (event) {
            if (selectedEventDay && selectedEventRing) {
                setIsLoading(true);
                const scheduleItemsQuery = await getScheduleItemsByEventIdByDayByRing(event.id, selectedEventDay.id, selectedEventRing.id);
                if (scheduleItemsQuery.isSuccess) {
                    const scheduleItems = scheduleItemsQuery.result;
                    await generateSchedulePDF(scheduleItems, event.name, selectedEventDay.awsdate || "", selectedEventRing.name, setProgressMessage, fontSize, isDownload, includeClassTotals, includeTable);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else setError("Did not select both a ring and a day. Cannot generate report.");
        }
        else setError("No event found. Cannot generate report.");
    }

    const reportLinks = (
        <IonRow>
            <IonCol>
                <IonRow>
                    <IonCol>
                        <p onClick={() => setShowDailyScheduleModal(true)} className="link ion-text-wrap">Daily Schedules (PDF)</p>
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
    )

    return (
        <>
            <EventReportCard title="Daily Schedules" content={reportLinks} isLoading={isLoading} progressMessage={progressMessage} error={error} />
            <IonModal backdropDismiss={false} isOpen={showDailyScheduleModal} id="dailySchedulesModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Daily Schedules
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowDailyScheduleModal(false)}
                        >
                            <p id="closeDailySchedulesModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {(event) && 
                        <>
                            <IonRow>
                                <IonCol>
                                    <IonLabel position="stacked">Select Day</IonLabel>
                                    <SelectEventDay eventId={event.id} selectedValue={selectedEventDay?.id} isClearable={false} onSelect={(day: EventDay) => setSelectedEventDay(day)} />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel position="stacked">Select Ring</IonLabel>
                                    <SelectEventRing event={event} selectedValue={selectedEventRing?.id} isClearable={false} onSelect={(ring: EventRing) => setSelectedEventRing(ring)} />
                                </IonCol>
                            </IonRow>
                            <hr/>
                        </>
                    }
                    <IonRow className="ion-justify-content-center pt-3">
                        <IonCol className="ion-text-center">
                            <p>Settings:</p>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem>
                                <IonLabel><p className="ion-text-wrap">Display Data in Table</p></IonLabel>
                                <IonCheckbox slot="start" value="sort" checked={includeTable} onIonChange={e => setIncludeTable(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem>
                                <IonLabel><p className="ion-text-wrap">Show Total # Classes</p></IonLabel>
                                <IonCheckbox slot="start" value="sort" checked={includeClassTotals} onIonChange={e => setIncludeClassTotals(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem color="white">
                                <IonLabel position="stacked">Font Size</IonLabel>
                                <IonInput
                                    type="number"
                                    value={fontSize}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setFontSize(parseInt(e.detail.value!))
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="6" className="ion-text-center">
                            <IonButton color="success" onClick={() => generateDailySchedule(true)}><IonIcon icon={downloadOutline}/>Download</IonButton>
                        </IonCol>
                        <IonCol size="6" className="ion-text-center">
                            <IonButton color="success" onClick={() => generateDailySchedule(false)}><IonIcon icon={printOutline}/>Print</IonButton>
                        </IonCol>
                    </IonRow>
                    <InformationBanner info="If using the Print button, be sure that pop-ups are allowed."/>
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventReportDailySchedulesSection;
