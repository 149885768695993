/**
 * https://ionicframework.com/docs/react/slides
 */

import SwiperCore, {Autoplay, Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IonicSwiper } from '@ionic/react';
import React from "react";
import { IonCol, IonRow } from '@ionic/react';

import 'swiper/swiper-bundle.min.css';
import '@ionic/react/css/ionic-swiper.css';

import DRHS from "../../assets/img/partners/DRHS.png";
import IEA from "../../assets/img/partners/IEA.png";
import IHSA from "../../assets/img/partners/IHSA.png";
import Keswick from "../../assets/img/partners/Keswick.png";
import MHAPS from "../../assets/img/partners/MHAPS.png";
import Nilforushan from "../../assets/img/partners/Nilforushan.jpeg";
import PRHP from "../../assets/img/partners/PRHP.png";
import RMF from "../../assets/img/partners/RMF.png";
import Upperville from "../../assets/img/partners/Upperville.png";
import WIHS from "../../assets/img/partners/WIHS.png";

SwiperCore.use([Autoplay, IonicSwiper, Pagination]);

export interface PartnerSlide {
    title: string
    description: string
    image: string
}


const partners: PartnerSlide[] = [
    {
        title: "Rose Mount Farm Horse Show",
        description: "Horse Show",
        image: RMF
    },
    {
        title: "Washington International",
        description: "Regional Horse Show",
        image: WIHS
    },
    {
        title: "Paso Robles Horse Park",
        description: "Horse Show Series",
        image: PRHP
    },
    {
        title: "IEA",
        description: "National Finals",
        image: IEA
    },
    {
        title: "IHSA",
        description: "National Finals",
        image: IHSA
    },
    {
        title: "Nilforushan Equisport Events",
        description: "Horse Show Management",
        image: Nilforushan
    },
    {
        title: "Keswick Horse Show",
        description: "Horse Show",
        image: Keswick
    },
    {
        title: "Upperville Colt and Horse Show",
        description: "Horse Show",
        image: Upperville
    },
    {
        title: "Maryland Horse and Pony Show",
        description: "Horse Show",
        image: MHAPS
    },
    {
        title: "Deep Run Horse Show",
        description: "Horse Show",
        image: DRHS
    },
];

const PartnersCarousel: React.FC = () => {
    return (
        <IonRow className="ion-align-items-stretch">
            {partners ? 
                <Swiper 
                    slidesPerView={4} 
                    spaceBetween={30} 
                    breakpoints={{
                        // when window width is >= 320px
                        1: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        },
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 20
                        },
                        // when window width is >= 480px
                        480: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        }
                    }}
                    centeredSlides={true}
                    loop={true}
                    autoplay={true}
                    pagination={{"clickable": true}} 
                    className="mySwiper"
                >
                    {partners.map((item, index) => (
                        <SwiperSlide key={index} className="rsp-swiper-slide">
                            <div className="mb-4">
                                <IonRow className="justify-content-center">
                                    <IonCol size="6" className="text-center">
                                        <img className="shadow bg-white img" alt="testimonial" src={item.image}/>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-text-center">
                                        <h4>{item.title}</h4>
                                        <p className="description">{item.description}</p>
                                    </IonCol>
                                </IonRow>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                :
                <p>Loading...</p>
            }
            
        </IonRow>
    );
};

export default PartnersCarousel;
