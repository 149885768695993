import {
    IonButton,
    IonCard,
    IonCol,
    IonRow,
    IonText,
    isPlatform,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { isWindows } from "../../utilities/platform/Platform";
import { Membership, Organization, OrganizationMembershipType, Person, MembershipTypeCategory } from "../../models";
import CONSTANT from "../../constant/constant";
import SingleMemberInfo from "./BasicInfo/SingleMemberInfo";
import GroupMemberInfo from "./BasicInfo/GroupMembersInfo";
import HorseMemberInfo from "./BasicInfo/HorseMemberInfo";
import SingleMemberContactInfo from "./ContactInfo.tsx/SingleMemberContactInfo";

interface _Props {
    membershipType: OrganizationMembershipType;
    setSegmentValue: Function;
    memberships: Membership[] | undefined;
    personId: string;
    user: Person | undefined;
    setMemberships: Function;
    organization: Organization | undefined;
}

const MembershipApplicationBasicInfoTab: React.FC<_Props> = ({membershipType, setSegmentValue, memberships, setMemberships, personId, user, organization}) => {

    const [showDescription, setShowDescription] = useState<boolean>(true);
    const [currentMemberships, setCurrentMemberships] = useState<Membership[] | null | undefined>();

    const [isMemberInfoSectionDisabled, setIsMemberInfoSectionDisabled] = useState(false);
    const [isMemberContactSectionDisabled, setIsMemberContactSectionDisabled] = useState(false);
    const [showNextButton, setShowNextButton] = useState(false);

    useEffect(() => {
        setCurrentMemberships(memberships);

        if (memberships && memberships.length > 0) {
            const firstMembership = memberships[0];
            setIsMemberInfoSectionDisabled(true);
            if (membershipType.category === MembershipTypeCategory.INDIVIDUAL) {
                // Check if the membership has Contact Info completed
                if (firstMembership.contactPersonId) {
                    setIsMemberContactSectionDisabled(true);
                    setShowNextButton(true);
                }
            } else if (membershipType.category === MembershipTypeCategory.GROUP) {;
                setShowNextButton(true);
            }
        } else {
            setIsMemberInfoSectionDisabled(false);
            setIsMemberContactSectionDisabled(false);
        }
    }, [memberships]);

    const handleUpdatedBasicInfo = (updatedMemberships: Membership[]) => {
        setCurrentMemberships(updatedMemberships);
        if (membershipType.category === MembershipTypeCategory.INDIVIDUAL) setIsMemberInfoSectionDisabled(true);
        else if (membershipType.category === MembershipTypeCategory.GROUP) {
            setMemberships(updatedMemberships);
            setShowNextButton(true);
        }
    }

    const handleUpdatedContactInfo = (updatedMemberships: Membership[]) => {
        setCurrentMemberships(updatedMemberships);
        setIsMemberContactSectionDisabled(true);
        setMemberships(updatedMemberships);
        setShowNextButton(true);
    }

    const handleNavigateToNext = () => {
        setSegmentValue(CONSTANT.MEMBERSHIP.APPLICATION_STAGES.UPLOADS);
    }

    return (
        <React.Fragment>
            <IonCard color="white" className="ion-padding">
                {/* Display the Name of this membership type (application type name) */}
                <h3>{membershipType.name}</h3>

                {/* Membership Type Description */}
                {membershipType.description && 
                    <IonRow>
                        <IonCol size="12">
                            <IonText
                                class="ion-float-right"
                                onClick={() => setShowDescription(!showDescription)}
                            >
                                {showDescription ? "Hide Description" : "Show Description"}
                            </IonText>
                        </IonCol>
                        <IonCol size="12">
                            {showDescription && 
                            <Input
                                rows={(isWindows() || isPlatform("desktop")) ? "5" : "15"}
                                type="textarea"
                                value={membershipType.description ?? ""}
                                disabled={true}
                            />
                            }                                
                        </IonCol>
                    </IonRow>
                }

                {/* Single Person Membership */}
                {membershipType && membershipType.category === MembershipTypeCategory.INDIVIDUAL && (
                    <>                        
                        {/* Section Header */}
                        <IonRow className="mt-5 mb-0">
                            <IonCol className="ion-text-center">
                                <h4>Member Info</h4>
                            </IonCol>
                        </IonRow>

                        {/* Single Member Basic Info Form */}
                        {currentMemberships && (
                            <SingleMemberInfo 
                                isDisabled={isMemberInfoSectionDisabled}
                                membershipType={membershipType}
                                setMemberships={handleUpdatedBasicInfo}
                                memberships={currentMemberships} 
                                personId={personId} 
                                user={user}
                                organization={organization}
                            />
                        )}

                        {(currentMemberships && currentMemberships.length > 0) && (
                            <div className="mt-5 mb-0">
                                <hr />

                                {/* Section Header */}
                                <IonRow className="mt-5 mb-0">
                                    <IonCol className="ion-text-center">
                                        <h4>Contact Info</h4>
                                    </IonCol>
                                </IonRow>

                                {/* Single Member Contact Info Form */}
                                <SingleMemberContactInfo 
                                    isDisabled={isMemberContactSectionDisabled}
                                    membershipType={membershipType}
                                    setMemberships={handleUpdatedContactInfo}
                                    memberships={currentMemberships} 
                                    personId={personId} 
                                    user={user}
                                    organization={organization}
                                />

                                <hr />

                                {showNextButton && (
                                    <IonRow className="mt-5 mb-0 ion-justify-content-center">
                                        <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                            <IonButton
                                                id="mt-basic-info-save-btn"
                                                className="ion-margin-top"
                                                color="tertiary"
                                                expand="block"
                                                onClick={handleNavigateToNext}
                                            >
                                                Next Tab
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                )}
                            </div>
                        )}
                    </>
                )}

                {/* Group People Memberships */}
                {membershipType && membershipType.category === MembershipTypeCategory.GROUP && (
                    <>
                        <GroupMemberInfo
                            membershipType={membershipType}
                            setMemberships={handleUpdatedBasicInfo}
                            memberships={memberships} 
                            personId={personId} 
                            user={user}
                            organization={organization}
                        />

                        {showNextButton && (
                            <IonRow className="mt-5 mb-0 ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                    <IonButton
                                        id="mt-basic-info-save-btn"
                                        className="ion-margin-top"
                                        color="tertiary"
                                        expand="block"
                                        onClick={handleNavigateToNext}
                                    >
                                        Next Tab
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        )}
                    </>
                )}

                {/* Single Horse Membership */}
                {membershipType && membershipType.category === MembershipTypeCategory.HORSE && (
                    <>
                        <HorseMemberInfo
                            membershipType={membershipType}
                            setMemberships={handleUpdatedBasicInfo}
                            memberships={memberships} 
                            personId={personId} 
                            user={user}
                            organization={organization}
                        />
                    </>
                )}
            </IonCard>
        </React.Fragment>
    );
};

export default MembershipApplicationBasicInfoTab;
