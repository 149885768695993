import { Barn, Horse, Person } from "../../models";
import { getHorsesByBarnId, getHorsesByPersonId } from "./Horse";

export async function getHorsesByUserByBarn(user: Person, barn?: (Barn | null)) {
    let horses: Horse[] = [];
    let results: Horse[] = [];

    if (barn) {
        const queryResultByBarn = await getHorsesByBarnId(barn.id);
        if (queryResultByBarn.isSuccess) {
            horses = horses.concat(queryResultByBarn.result);
        }
    }

    const queryResultByPerson = await getHorsesByPersonId(user.id);
    if (queryResultByPerson.isSuccess) {
        horses = horses.concat(queryResultByPerson.result);
    }

    horses.forEach(horse => {
        const index = results.findIndex(currentHorse => currentHorse.id === horse.id);
        if (index === -1) {
            results.push(horse);
        }
    });

    const sorted = results.sort((a,b) => a.name.localeCompare(b.name));

    return sorted || results;
}