import {
    IonButton,
    IonCol,
    IonItem,
    IonLabel,
    IonRow,
    IonToggle
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { UpdateEventEntryInput } from "../../../API";
import { EventEntry } from "../../../models";
import { updateEventEntry } from "../../../utilities/eventEntry/EventEntry";
import SuccessBanner from "../../Banners/SuccessBanner";
import ErrorAlert from "../../Errors/ErrorAlert";
import Spinner from "../../Spinners/Spinner";

interface _Props {
    entry: EventEntry
    onSubmit: Function
}

const EntryCheckInForm: React.FC<_Props> = ({entry, onSubmit}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null | undefined>();
    const [success, setSuccess] = useState<string | null | undefined>();
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [isCheckedIn, setIsCheckedIn] = useState<boolean>(entry.isCheckedIn || false);
    const [isCheckedOut, setIsCheckedOut] = useState<boolean>(entry.isCheckedOut || false);

    const handleSubmit = async () => {
        setIsLoading(true);
        if (currentEntry) {
            const eventEntryInput: UpdateEventEntryInput = {
                id: currentEntry.id,
                isCheckedIn: isCheckedIn,
                isCheckedOut: isCheckedOut
            };
            const updateResult = await updateEventEntry(eventEntryInput);
            if (updateResult.isSuccess) {
                setSuccess("Successfully saved the entry status.");
                onSubmit(updateResult.result);
            } else {
                setError(updateResult.message);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setCurrentEntry(entry);
        setIsCheckedIn(entry.isCheckedIn || false);
        setIsCheckedOut(entry.isCheckedOut || false);
    }, [entry]);

    return (
        <>
            {isLoading ? 
                <Spinner />
                :
                <form>
                    {error && <ErrorAlert width="12" error={error} />}
                    {success && <SuccessBanner width="12" success={success} />}
                    <IonRow>
                        <IonCol sizeXs="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Checked In?</IonLabel>
                                <IonToggle color="success" checked={isCheckedIn} onIonChange={e => setIsCheckedIn(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXs="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Checked Out?</IonLabel>
                                <IonToggle color="success" checked={isCheckedOut} onIonChange={e => setIsCheckedOut(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="6" className="ion-text-center">
                            <IonButton color="tertiary" onClick={handleSubmit}>
                                Save
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </form>
            }
        </>
    );
};

export default EntryCheckInForm;