export const addFooters = (doc: any, skipCreatedText?: boolean) => {
    const pageCount = doc.internal.getNumberOfPages();
  
    doc.setFontSize(8);
    for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        if (!skipCreatedText) {
            doc.text('Created using RingSide Pro: www.ringsidepro.com', 14, (doc.internal.pageSize.height - 10), {
                align: 'left'
            });
        }
        doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width - 10, (doc.internal.pageSize.height - 10), {
            align: 'right'
        });
    }
}