import {
    IonCol,
    IonItem,
    IonRow,
    IonText,
} from "@ionic/react";
import React from "react";
import { Event } from "../../../models";
import { formatAddress } from "../../../utilities/address/FormatAddress";

interface _Props {
    event: Event
}

const EventDisplayLocation: React.FC<_Props> = ({event}) => {
    return (
        <IonRow>
            <IonCol>
                <IonItem color="white">
                    <IonText>{event.address && formatAddress(event.address)}</IonText>
                </IonItem>
            </IonCol>
        </IonRow>
    );
};

export default EventDisplayLocation;