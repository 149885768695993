import { Address, Event, EventEntry } from "../../models";
import moment from "moment";
import { getAcceptedEventEntriesByEventId } from "../eventEntry/EventEntry";
import { sortEventEntriesByNumber } from "../eventEntry/SortEventEntry";
import { formatAddress } from "../address/FormatAddress";
import { getAddressById } from "../address/Address";
import { formatStartEndDatesStrings } from "../dates/FormatDates";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export async function generateCogginsEquineEventReport(event: Event) {

    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(12);
    doc.text(`Location: ${event.address ? formatAddress(event.address): ""}`, 14, 25);
    doc.text(`Dates: ${event.startDate ? formatStartEndDatesStrings(event.startDate, event.endDate): ""}`, 14, 30);
    doc.text(`Equine Event Report generated on ${moment(new Date()).format("MM/DD/YYYY")}`, 14, 35);

   // define the columns we want and their titles
   const tableColumn = [
        {header: "Entry No", dataKey: "entryNo"}, 
        {header: "Name of Owner", dataKey: "owner"}, 
        {header: "Owner's Address", dataKey: "address"},
        {header: "Equidae Name", dataKey: "horse"},
        {header: "Test Date", dataKey: "date"},
        {header: "State", dataKey: "state"},
        {header: "Laboratory Accession No.", dataKey: "testNo"}
    ];

    // define an empty array of rows
    const tableRows = [];
    
    const queryResult = await getAcceptedEventEntriesByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventEntries = queryResult.result as EventEntry[];
        const sorted = sortEventEntriesByNumber(eventEntries);
        if (sorted && sorted.length > 0) {
            for (let i = 0; i < sorted.length; i++) {
                let currentRow: any[] = [];
                const eventEntry = sorted[i];
                currentRow.push(eventEntry.number || "-");
                currentRow.push(eventEntry.ownerName || "");
                const ownerAddressId = eventEntry.owner?.addressId;
                if (ownerAddressId) {
                    const addressResult = await getAddressById(ownerAddressId);
                    if (addressResult.isSuccess) {
                        const ownerAddress: Address | null | undefined = addressResult.result;
                        if (ownerAddress) {
                            const formattedAddress = formatAddress(ownerAddress);
                            currentRow.push(formattedAddress || "");
                        } else {
                            currentRow.push(eventEntry.owner?.location || "-");
                        }
                    } else {
                        currentRow.push(eventEntry.owner?.location || "-");
                    }
                } else {
                    currentRow.push(eventEntry.owner?.location || "-");
                }
                currentRow.push(eventEntry.horseName || "");
                currentRow.push(eventEntry.horse?.cogginsDate ? moment(eventEntry.horse?.cogginsDate).format("MM/DD/YYYY") : "-");
                currentRow.push(eventEntry.horse?.cogginsState || "-");
                currentRow.push(eventEntry.horse?.cogginsNumber || "-");
                tableRows.push(currentRow);
            }
        }
    }

   // startY is basically margin-top
   doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 40 
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    doc.save(`${event.name}_coggins_equine_event_report.pdf`);
}

export const headerRow = [
    "Entry No.",
    "Name of Owner",
    "Owner's Address",
    "Equidae Name",
    "Test Date",
    "State",
    "Laboratory Accession No.",
];