import { IonCard, IonCardContent, IonCol, IonLabel, IonRow } from "@ionic/react";
import React from "react";

import { Event, EventEntry } from "../../models";
import EntryInfoForm from "./EntryInfoForm";
import QuickEntryInfoForm from "./QuickEntryInfoForm";

interface _Props {
    event: Event
    entry?: EventEntry
    isQuickAdd?: Boolean
    onSubmit: Function
}

const EntryCard: React.FC<_Props> = ({entry, event, isQuickAdd, onSubmit}) => {

    const handleEntrySubmit = (entry: EventEntry) => {
        onSubmit(entry);
    }

    return (
        <>
            {!!isQuickAdd ? 
                <>  
                    <QuickEntryInfoForm event={event} onSubmit={handleEntrySubmit} />
                </>
                :
                <>
                    {(event.type === "camp") ?
                    (
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="12" className="ion-no-padding">
                                <IonCard color="light" mode="md">
                                    <IonCardContent>
                                        <IonLabel id="infoMsg" className="ion-text-wrap">
                                            <p>Check out a tutorial for camp registrations by clicking <a className="link" href="https://youtu.be/nC-mIgDfEeg<">here.</a></p>
                                        </IonLabel>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    )
                    :
                    (
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="12" className="ion-no-padding">
                                <IonCard color="light" mode="md">
                                    <IonCardContent>
                                        <IonLabel id="infoMsg" className="ion-text-wrap">
                                            <p>We recommend joining a barn and adding info there first. Check out a tutorial for submitting online entries here: <a className="link" href="https://youtu.be/txD9qnhNbfw<">https://youtu.be/txD9qnhNbfw</a></p>
                                        </IonLabel>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    )}
                    <EntryInfoForm entry={entry} event={event} onSubmit={handleEntrySubmit} />
                </>
            }
            
        </>
    );
};

export default EntryCard;