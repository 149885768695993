import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, EventClass, EventClassEntry, EventDay, EventRing, ScheduleItem } from "../../../models";
import { Table } from "reactstrap";
import { getEventDaysByEventId } from "../../../utilities/eventDay/EventDay";
import ScheduleDayNavbar from "../../Navbars/ScheduleDayNavbar";
import { getScheduleItemsByEventIdByDayByRing } from "../../../utilities/scheduleItem/ScheduleItem";
import { getEventRingsByEventId } from "../../../utilities/eventRing/EventRing";
import SelectEventRing from "../../EventRing/SelectEventRing";
import Spinner from "../../Spinners/Spinner";
import ErrorAlert from "../../Errors/ErrorAlert";
import DownloadLink from "../../PDF/DownloadLink";
import { getEventClassTypeAbbreviation } from "../../../utilities/eventClass/EventClassTypes";
import { close } from "ionicons/icons";
import { useSubscriptionByItself } from "../../../utilities/subscription/Subscription";
import { onCreateScheduleItem, onUpdateScheduleItem, onDeleteScheduleItem } from "../../../graphql/subscriptions";
import { getEventClassById } from "../../../utilities/eventClass/EventClass";
import { generateSchedulePDF } from "../../../utilities/reports/SchedulePDF";
import moment from "moment";
import { getAcceptedEventClassEntriesByEventClassId } from "../../../utilities/eventClassEntry/EventClassEntry";

interface _Props {
    event: Event
    onSelect?: Function
}

interface FormattedScheduleItem {
    number: number,
    name: string,
    type: string,
    time: string,
    si: ScheduleItem
}

const DisplayScheduleWithClassInfo: React.FC<_Props> = ({event, onSelect}) => {

    const createScheduleItemSubscription = useSubscriptionByItself({
        config: {
            query: onCreateScheduleItem,
            key: "onCreateScheduleItem"
        }
    });

    const updateScheduleItemSubscription = useSubscriptionByItself({
        config: {
            query: onUpdateScheduleItem,
            key: "onUpdateScheduleItem"
        }
    });

    const deleteScheduleItemSubscription = useSubscriptionByItself({
        config: {
            query: onDeleteScheduleItem,
            key: "onDeleteScheduleItem"
        }
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [currentScheduleItemSubscriptionItem, setCurrentScheduleItemSubscriptionItem] = useState<any>();
    const [days, setDays] = useState<EventDay[] | null | undefined>();
    const [rings, setRings] = useState<EventRing[] | null | undefined>();
    const [scheduleItems, setScheduleItems] = useState<ScheduleItem[] | null | undefined>();
    const [formattedScheduleItems, setFormattedScheduleItems] = useState<FormattedScheduleItem[] | null | undefined>();
    const [selectedDay, setSelectedDay] = useState<EventDay | null | undefined>();
    const [selectedRing, setSelectedRing] = useState<EventRing | null | undefined>();
    const [error, setError] = useState<string>(""); 

    const [showModal, setShowModal] = useState(false);
    const [selectedEventClass, setSelectedEventClass] = useState<EventClass | null | undefined>();
    const [selectedEventClassEntries, setSelectedEventClassEntries] = useState<EventClassEntry[] | null | undefined>();

    const formatScheduleItems = async (scheduleItems: ScheduleItem[]) => {
        let formattedEventClasses: FormattedScheduleItem[] = [];
        if (scheduleItems) {
            for (var i = 0; i < scheduleItems.length; i++) {
                const currentItem = scheduleItems[i];
                const classId = currentItem.classId;
                const eventClass = currentItem.eventClass;
                if (eventClass && eventClass.name) {
                    const formattedItem: FormattedScheduleItem = {
                        number: eventClass.number || 0,
                        name: eventClass.name,
                        type: eventClass?.type ? getEventClassTypeAbbreviation(eventClass.type) : "",
                        time: currentItem.startTime || "",
                        si: currentItem
                    }
                    formattedEventClasses.push(formattedItem);
                }
                else if (classId) {
                    const queryResult = await getEventClassById(classId);
                    if (queryResult.isSuccess) {
                        const currentClass: EventClass = queryResult.result;
                        const formattedItem: FormattedScheduleItem = {
                            number: currentClass.number || 0,
                            name: currentClass.name,
                            type: currentClass?.type ? getEventClassTypeAbbreviation(currentClass.type) : "",
                            time: currentItem.startTime || "",
                            si: currentItem
                        }
                        formattedEventClasses.push(formattedItem);
                    }
                }
            }
        }
        setFormattedScheduleItems(formattedEventClasses);
        setIsLoading(false);
    }

    const setUp = async (event: Event) => {
        let daysList: EventDay[] = [];
        let ringsList: EventRing[] = [];

        let defaultDay;
        let defaultRing;
        
        const queryDaysResult = await getEventDaysByEventId(event.id);
        if (queryDaysResult.isSuccess) {
            daysList = queryDaysResult.result;
            setDays(daysList);
            defaultDay = daysList[0];
            setSelectedDay(defaultDay);
        }

        const queryRingsResult = await getEventRingsByEventId(event.id);
        if (queryRingsResult.isSuccess) {
            ringsList = queryRingsResult.result;
            setRings(ringsList);
            defaultRing = ringsList[0];
            setSelectedRing(defaultRing);
        }

        if (defaultDay && defaultRing) getScheduleItemsByDayByRing(defaultDay.id, defaultRing.id);
    }

    useEffect(() => {
        if (event && (!days && !rings)) {
            setUp(event);
        }
    }, [event]);

    useEffect(() => {
        if (selectedDay && selectedRing) getScheduleItemsByDayByRing(selectedDay.id, selectedRing.id)
    }, [selectedRing, selectedDay]);

    useEffect(() => {
        if (createScheduleItemSubscription && createScheduleItemSubscription[0] !== undefined && createScheduleItemSubscription[0] !== currentScheduleItemSubscriptionItem) {
            setCurrentScheduleItemSubscriptionItem(createScheduleItemSubscription[0]);
            if (selectedDay && selectedRing) {
                getScheduleItems(selectedDay, selectedRing);
            }
        }

        if (updateScheduleItemSubscription && updateScheduleItemSubscription[0] !== undefined && updateScheduleItemSubscription[0] !== currentScheduleItemSubscriptionItem) {
            setCurrentScheduleItemSubscriptionItem(updateScheduleItemSubscription[0]);
            if (selectedDay && selectedRing) {
                getScheduleItems(selectedDay, selectedRing);
            }
        }

        if (deleteScheduleItemSubscription && deleteScheduleItemSubscription[0] !== undefined && deleteScheduleItemSubscription[0] !== currentScheduleItemSubscriptionItem) {
            setCurrentScheduleItemSubscriptionItem(deleteScheduleItemSubscription[0]);
            if (selectedDay && selectedRing) {
                getScheduleItems(selectedDay, selectedRing);
            }
        }
    }, [createScheduleItemSubscription, updateScheduleItemSubscription, deleteScheduleItemSubscription]);

    async function getScheduleItems(day: EventDay, ring: EventRing) {
        setIsLoading(true);
        if (day && ring) {
            const queryResult = await getScheduleItemsByEventIdByDayByRing(event.id, day.id, ring.id);
            if (queryResult.isSuccess) {
                setScheduleItems(queryResult.result);
                formatScheduleItems(queryResult.result);
            } else {
                setScheduleItems(undefined);
                setIsLoading(false);
            }
        } else {
            setError("Please reload the page to see the updates.");
            setIsLoading(false);
        }
    }

    async function getScheduleItemsByDayByRing(eventDayId: string, eventRingId: string) {
        setIsLoading(true);
        const queryResult = await getScheduleItemsByEventIdByDayByRing(event.id, eventDayId, eventRingId);
        if (queryResult.isSuccess) {
            setScheduleItems(queryResult.result);
            formatScheduleItems(queryResult.result);
        } else {
            setScheduleItems(undefined);
            setIsLoading(false);
        }
    }

    const handleSelectEventRing = (eventRing: EventRing) => {
        setIsLoading(true);
        setFormattedScheduleItems(undefined);
        setSelectedRing(eventRing);
        if (selectedDay) getScheduleItemsByDayByRing(selectedDay.id, eventRing.id);
    }

    const handleSelectEventDay = (index: number) => {
        setIsLoading(true);
        setFormattedScheduleItems(undefined);
        if (days) {
            setSelectedDay(days[index]);
            if (selectedRing) getScheduleItemsByDayByRing(days[index].id, selectedRing.id);
        }
    }

    const handleSelectedScheduleItem = async (scheduleItem: ScheduleItem) => {
        setError("");
        const eventClass = scheduleItem.eventClass;
        setSelectedEventClass(eventClass);
        if (eventClass) {
            setShowModal(true);
            const queryResult = await getAcceptedEventClassEntriesByEventClassId(eventClass.id);
            if (queryResult.isSuccess) {
                setSelectedEventClassEntries(queryResult.result);
            }
        }
    }

    const handleDownloadSchedule = async () => {
        setIsDownloading(true);
        if (scheduleItems) {
            await generateSchedulePDF(scheduleItems, event.name, (selectedDay && selectedDay.awsdate) ? moment(selectedDay.awsdate).format("dddd MMM D, YYYY") : "No Day", selectedRing ? selectedRing.name : "No Ring")
            setIsDownloading(false);
        } else {
            setIsDownloading(false);
        }
    }

    return (
        <IonRow>
            <IonCol>
                <IonCard mode="md" className="ion-padding bg-white stretch card-large-height bg-white">
                    <IonCardTitle>
                        Schedule
                    </IonCardTitle>
                    <IonCardSubtitle>
                        Scroll to see the full schedule.
                    </IonCardSubtitle>
                    <IonContent color="white">
                        {days && <ScheduleDayNavbar days={days} onSelect={handleSelectEventDay} />}
                        {event && <SelectEventRing event={event} isDisplayDefault={true} onSelect={handleSelectEventRing} />}
                        <IonRow>
                            <IonCol className="text-right" onClick={handleDownloadSchedule}>
                                {isDownloading ?
                                    <Spinner />
                                    :
                                    <p className="description">See class counts: <DownloadLink /></p>
                                }
                            </IonCol>
                        </IonRow>
                        {error && <ErrorAlert width="12" error={error} />}
                        {isLoading ?
                            <Spinner />
                            :
                            <>
                                {formattedScheduleItems && formattedScheduleItems.length ?
                                    <Table hover>
                                        <thead>
                                            <tr>
                                                <th>Order</th>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formattedScheduleItems.map((item, index) => (
                                                <tr key={index} onClick={() => handleSelectedScheduleItem(item.si)}>
                                                    <th scope="row">{index+1}</th>
                                                    <td>{item.number}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.type}</td>
                                                    <td>{item.time}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    :
                                    <p>No classes found.</p>
                                }
                            </>
                        }
                    </IonContent>
                </IonCard>
                <IonModal backdropDismiss={false} isOpen={showModal} id="eventClassModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        {selectedEventClass?.number} - {selectedEventClass?.name}
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10" className="ion-text-center">
                            <h2>{selectedEventClass?.name}</h2>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12" className="ion-text-center">
                            <p>{selectedEventClass?.class?.description}</p>
                        </IonCol>
                    </IonRow>
                    {(selectedEventClassEntries && selectedEventClassEntries.length > 0) && (
                        <>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="12" className="ion-text-center">
                                    <p>Number of Entries: {selectedEventClassEntries.length}</p>
                                </IonCol>
                            </IonRow>
                            <IonList className="bg-white">
                                <IonItem key="entryHeader">
                                    <IonLabel>
                                        <IonRow>
                                            <IonCol>
                                                <p className="ion-text-wrap">Number</p>
                                            </IonCol>
                                            <IonCol>
                                                <p className="ion-text-wrap">Horse</p>
                                            </IonCol>
                                            <IonCol>
                                                <p className="ion-text-wrap">Rider</p>
                                            </IonCol>
                                            <IonCol>
                                                <p className="ion-text-wrap">Owner</p>
                                            </IonCol>
                                            <IonCol>
                                                <p className="ion-text-wrap">Trainer</p>
                                            </IonCol>
                                        </IonRow>
                                    </IonLabel>
                                </IonItem>
                                {selectedEventClassEntries.map((entry, index) => (
                                    <IonItem key={index}>
                                        <IonLabel>
                                            <IonRow>
                                                <IonCol>
                                                    <p className="ion-text-wrap">{entry.eventEntry?.number}</p>
                                                </IonCol>
                                                <IonCol>
                                                    <p className="ion-text-wrap">{entry.eventEntry?.horseName}</p>
                                                </IonCol>
                                                <IonCol>
                                                    <p className="ion-text-wrap">{entry.eventEntry?.riderName}</p>
                                                </IonCol>
                                                <IonCol>
                                                    <p className="ion-text-wrap">{entry.eventEntry?.ownerName}</p>
                                                </IonCol>
                                                <IonCol>
                                                    <p className="ion-text-wrap">{entry.eventEntry?.trainerName}</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                    </IonItem>
                                ))}
                            </IonList>
                        </>
                    )}
                </IonContent>
            </IonModal>
            </IonCol>
        </IonRow>
    );
};

export default DisplayScheduleWithClassInfo;