import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { UpdateHorseInput } from "../../../../../API";
import { PersonContext } from "../../../../../context/PersonContext";
import { Event, EventEntry, Horse } from "../../../../../models";
import { updateHorse } from "../../../../../utilities/horse/Horse";
import { createCogginsDocumentFileName, createS3Object, handleUploadS3File } from "../../../../../utilities/s3Object/s3Object";
import SelectState from "../../../../Address/SelectState";
import SuccessBanner from "../../../../Banners/SuccessBanner";
import ErrorAlert from "../../../../Errors/ErrorAlert";
import CogginsDocumentForm from "../../../../s3Object/CogginsDocumentForm";
import Spinner from "../../../../Spinners/Spinner";
import { Storage } from "aws-amplify";

interface _Props {
    entry: EventEntry
    event: Event
}

const EditEntryHorseCogginsCard: React.FC<_Props> = ({entry, event}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentHorse, setCurrentHorse] = useState<Horse | null | undefined>();
    const [horseName, setHorseName] = useState("");
    const [url, setURL] = useState<any>();
    const [cogginsState, setCogginsState] = useState("");
    const [cogginsNumber, setCogginsNumber] = useState("");
    const [cogginsDate, setCogginsDate] = useState("");
    const [cogginsDocument, setCogginsDocument] = useState<any | null | undefined>();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const getEntryInformation = (entry: EventEntry) => {
        if (entry.horse) {
            setCurrentHorse(entry.horse);
            setHorseName(entry.horse.name || "");
            setCogginsNumber(entry.horse.cogginsNumber || "");
            setCogginsDate(entry.horse.cogginsDate || "");
            setCogginsState(entry.horse.cogginsState || "");
            setCogginsDocument(entry.horse.coggins || null);
            if (entry.horse.coggins?.key) handleGetDocument(entry.horse.coggins?.key);
        }
    }

    async function handleGetDocument(fileName: string) {
        try {
            const url = await Storage.get(fileName);
            setURL(url);
        } catch (error: any) {
            console.error("Error while getting the document: ", error);
            return "";
        }
    }

    useEffect(() => {
        if (entry) {
            getEntryInformation(entry);
        }
    }, [entry]);

    const handleSelectedDocument = async (file: any) => {
        setCogginsDocument(file);
        await handleUploadDocument(file);
    }

    const handleUploadDocument = async (file: any) => {
        // Upload this to S3 
        if (entry && entry.horse) {
            const fileName = createCogginsDocumentFileName(entry.horse);
            const uploadResult = await handleUploadS3File(fileName, file);
            if (uploadResult.isSuccess) {
                // If successfully added to S3, save to video list to the person
                const key = uploadResult.result;
                const title = "Coggins for " + horseName;
                const description = "Coggins uploaded for " + horseName + " by " + user.firstName + " " + user.lastName + " on " + moment(new Date()).format("MMM DD, YYYY h:mm a"); 
                const s3Object = createS3Object(key, title, file, description);
                return s3Object;
            } else {
                setError("Could not upload the document. Contact hello@ringsidepro.com for help.")
            }
        }
    }

    const handleEdit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        if (entry.horseId) {
            let input: UpdateHorseInput = {
                id: entry.horseId,
                cogginsDate: cogginsDate || "",
                cogginsNumber: cogginsNumber || "",
                cogginsState: cogginsState || "",
            };
            if (cogginsDocument) {
                const s3Object = await handleUploadDocument(cogginsDocument);
                if (s3Object) input["coggins"] = s3Object;
            }
            const updateResult = await updateHorse(input);
            if (updateResult.isSuccess) {
                setSuccess("Updated the horse info.");
                setIsEdit(false);
                setIsLoading(false);
            } else {
                setError("Could not update the horse");
                setIsLoading(false);
            }
        } else {
            setError("Could not find the horse in the database.");
            setIsLoading(false);
        }
    }

    return (
        <>
            <IonCard color="white" className="ion-padding">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h4>Horse Coggins</h4>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {success && <SuccessBanner width="12" success={success} />}
                        {isEdit ?
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Coggins Accession Number</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={cogginsNumber}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setCogginsNumber(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Coggins Test Date</IonLabel>
                                            <IonInput 
                                                type="date"
                                                value={cogginsDate}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setCogginsDate(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <SelectState label="State of Test" selectedValue={cogginsState} onInputChange={(state: string) => setCogginsState(state)} />
                                    </IonCol>
                                    <IonCol size="12">
                                        <CogginsDocumentForm horse={currentHorse} onSubmit={handleSelectedDocument} />
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Coggins Accession Number</IonLabel>
                                            <IonText><p>{cogginsNumber}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Coggins Test Date</IonLabel>
                                            <IonText><p>{cogginsDate}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Coggins Test State</IonLabel>
                                            <IonText><p>{cogginsState}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6" className="mt-2">
                                        <a href={url} rel="noopener noreferrer"  target="_blank">
                                            <p className="ion-text-wrap link">View Coggins Document</p>
                                        </a>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                        {isLoading ?
                            <Spinner />
                            :
                            <IonRow className="ion-justify-content-center">
                                {isEdit && (
                                    <IonCol sizeMd="4" className="ion-text-center">
                                        <IonButton
                                            className="ion-margin-top"
                                            color="primary"
                                            expand="block"
                                            onClick={() => setIsEdit(false)}
                                        >
                                            Cancel
                                        </IonButton>
                                    </IonCol>
                                )}
                                <IonCol sizeMd="4" className="ion-text-center">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="tertiary"
                                        expand="block"
                                        onClick={isEdit ? handleEdit : (() => setIsEdit(true))}
                                    >
                                        {!isEdit && "Allow "}Edit
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        }
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default EditEntryHorseCogginsCard;