import { IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { barnTypesList } from "./BarnTypes";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    typeValue?: string
    isRequired?: Boolean
    onInputChange: Function
}

const SelectBarnType: React.FC<_Props> = ({isRequired, typeValue, onInputChange}) => {

    const [type, setType] = useState<string>("-");

    useEffect(() => {
        if (typeValue) setType(typeValue);
        else setType("-");
    }, [typeValue]);

    return (
        <>
            <FormGroup>
                <IonLabel className="description pl-3">Barn Type {isRequired && (<RequiredInputIndicator />)}</IonLabel>
                <Input 
                    id="barn-type-input"
                    name="country" 
                    type="select" 
                    value={type} 
                    onChange={e => {
                        setType(e.target.value);
                        onInputChange(e.target.value)
                    }}
                >
                    <option>-</option>
                    {barnTypesList.map((type: string, index: number) => (
                        <option key={index}>{type}</option>
                    ))}
                </Input>
            </FormGroup>
        </>
    );
};

export default SelectBarnType;