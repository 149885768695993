import { API } from "aws-amplify";
import { Result } from "../../interfaces/Result";

export async function sendInvitationEmail(emailAddress: string, organizationName: string, invitorName?: string, inviteeName?: string) {
    try {
        let queryParams = "";
        queryParams = queryParams + "?email=" + emailAddress;
        queryParams = queryParams + "&organizationName=" + organizationName;
        if(invitorName) queryParams = queryParams + "&invitorName=" + invitorName;
        if(inviteeName) queryParams = queryParams + "&inviteeName=" + inviteeName;

        const path = "/emails/invitation" + queryParams;
        const response = await API.get("Emails", path, {});
        const responseBody = JSON.parse(response.body);
        const url = responseBody.url;

        let result: Result = {isSuccess: responseBody.success, type: "Invitation", result: url, message: "Successfully sent the email."}
        return result;
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Invitation", result: error, message: "Error occurred while trying to send the email." }
        return result;
    }
}

export async function sendBarnRequestJoinEmail(emailAddress: string, barnName: string, invitorName: string) {
    try {
        let queryParams = "";
        queryParams = queryParams + "?email=" + emailAddress;
        queryParams = queryParams + "&barnName=" + barnName;
        queryParams = queryParams + "&personName=" + invitorName;

        const path = "/emails/barn/request-join" + queryParams;
        const response = await API.get("Emails", path, {});
        const responseBody = JSON.parse(response.body);

        let result: Result = {isSuccess: responseBody.success, type: "Invitation", result: responseBody, message: "Successfully sent the email."}
        return result;
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Invitation", result: error, message: "Error occurred while trying to send the email." }
        return result;
    }
}

export async function sendBarnRequestStatusUpdated(emailAddress: string, barnName: string, status: string) {
    try {
        let queryParams = "";
        queryParams = queryParams + "?email=" + emailAddress;
        queryParams = queryParams + "&barnName=" + barnName;
        queryParams = queryParams + "&status=" + status;

        const path = "/emails/barn/status-update" + queryParams;
        const response = await API.get("Emails", path, {});
        const responseBody = JSON.parse(response.body);

        let result: Result = {isSuccess: responseBody.success, type: "Invitation", result: responseBody, message: "Successfully sent the email."}
        return result;
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Invitation", result: error, message: "Error occurred while trying to send the email." }
        return result;
    }
}