import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Membership, Person } from "../../models";
import { deleteMembership, getMembershipsByPersonId } from "../../utilities/membership/Membership";
import ViewMembershipInfoModal from "./ViewMembershipInfoModal";
import { readerOutline } from "ionicons/icons";
import ErrorAlert from "../Errors/ErrorAlert";
import * as subscriptions from "../../graphql/subscriptions";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import { BarnPerson } from "../../interfaces/Person";
import { getPersonMemberships } from "../../utilities/barn/BarnPerson";
import { Table } from "reactstrap";
import { PersonContext } from "../../context/PersonContext";
import { formatDisplayNameForUser } from "../../utilities/person/PersonNameFormat";
import { sortMembershipsByDate } from "../../utilities/membership/SortMemberships";
import Spinner from "../Spinners/Spinner";

interface _Props {
    currentBarnPerson?: BarnPerson
    hasAdminAccess?: Boolean
}

const EditPersonMembershipsTable: React.FC<_Props> = ({currentBarnPerson, hasAdminAccess}) => {
    const user = useContext(PersonContext);

    const createSubscription = useSubscriptionByItself({
        config: {
            query: subscriptions.onCreateMembership,
            key: "onCreateMembership"
        }
    });

    const [showMembershipModal, setShowMembershipModal] = useState(false);
    const [membership, setMembership] = useState<Membership | null | undefined>();
    const [memberships, setMemberships] = useState<Membership[] | null | undefined>();
    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const getMembershipsByPerson = async (currentPerson: Person) => {
        setIsLoading(true);
        const membershipsResult = await getMembershipsByPersonId(currentPerson.id);
        if (membershipsResult.isSuccess) {
            const memberships = membershipsResult.result as Membership[];
            const sorted = sortMembershipsByDate(memberships);
            setMemberships(sorted || memberships);
        }
        setIsLoading(false);
    }

    const getMembershipsByBarnPerson = async (currentBarnPerson: BarnPerson) => {
        setIsLoading(true);
        const memberships = await getPersonMemberships(currentBarnPerson) as Membership[];
        const sorted = sortMembershipsByDate(memberships);
        setMemberships(sorted || memberships);
        setIsLoading(false);
    }

    useEffect(() => {
        if (createSubscription && createSubscription[0] !== undefined && createSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(createSubscription[0]);
            if (currentBarnPerson) getMembershipsByBarnPerson(currentBarnPerson);
        }
    }, [createSubscription]);

    useEffect(() => {
        if (user && !currentBarnPerson) getMembershipsByPerson(user);
    }, []);

    useEffect(() => {
        if (currentBarnPerson) getMembershipsByBarnPerson(currentBarnPerson);
    }, [currentBarnPerson]);

    const onSelectMembership = (membership: Membership) => {
        setMembership(membership);
        setShowMembershipModal(true);
    }

    const handleDelete = async () => {
        setIsLoading(true);
        if (membership) {
            const deleteResult = await deleteMembership({id: membership.id});
            if (deleteResult.isSuccess) {
                let newMemberships: Membership[] = [];
                memberships?.forEach(current => {
                    if (membership.id !== current.id) newMemberships.push(current);
                });
                setMemberships(newMemberships);
            } else {
                setError("Could not delete the membership.");
            }
        } else {
            setError("Could not delete the membership.");
        }
        console.log("Set is loading to false");
        setIsLoading(false);
    }

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>
                <IonRow>
                    <IonCol size="12">
                        <h3>{currentBarnPerson?.name || formatDisplayNameForUser(user)} Memberships</h3>
                    </IonCol>
                </IonRow>
            </IonCardTitle>
            <IonCardSubtitle>Click on a row to view more about the membership.</IonCardSubtitle>
            {isLoading ?
                <Spinner />
                :
                <IonCardContent>
                    {error && <ErrorAlert error={error} />}
                    {memberships && memberships.length ?
                        <Table hover responsive striped>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Membership Id</th>
                                    <th>Status</th>
                                    <th>More...</th>
                                </tr>
                            </thead>
                            <tbody>
                                {memberships.map((membership, index) => (
                                    <tr key={index}>
                                        <td>
                                            <IonText className="ion-text-wrap">
                                                {membership.name}
                                            </IonText>
                                        </td>
                                        <td>
                                            <IonText className="ion-text-wrap">
                                                {membership.membershipId ? membership.membershipId : "unknown"}
                                            </IonText>
                                        </td>
                                        <td>
                                            <IonText className="ion-text-wrap">
                                                {membership.membershipStatus ? membership.membershipStatus : "unknown"}
                                            </IonText>
                                        </td>
                                        <td>
                                            <IonButton slot="end" color="tertiary" onClick={() => onSelectMembership(membership)}><IonIcon icon={readerOutline}/></IonButton>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        :
                        <p>No memberships yet.</p>
                    }
                </IonCardContent>
            }
            {membership && <ViewMembershipInfoModal membership={membership} show={showMembershipModal} allowOptionToDelete={(!!hasAdminAccess)} onDelete={handleDelete} />}
        </IonCard>
    );
};

export default EditPersonMembershipsTable;