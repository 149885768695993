import React, { useEffect, useState } from "react";
import { IonButton, IonCol, IonRow, IonText } from "@ionic/react";
import { Organization } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { getSourceById } from "../../utilities/stripe/GetSource";
import { StripeSource } from "../../interfaces/Stripe";
import { updateOrganization } from "../../utilities/organization/Organization";
import PaymentMethodForm from "./PaymentMethodForm";
import { UpdateOrganizationInput } from "../../API";

interface _Props {
    organization: Organization
    onSubmit?: Function
}

const OrganizationPaymentMethod: React.FC<_Props> = ({organization, onSubmit}) => {
    
    const [organizationSource, setOrganizationSource] = useState<StripeSource | null | undefined>();
    const [error, setError] = useState("");

    const getSource = async (sourceId: string) => {
        const queryResult = await getSourceById(sourceId);
        const source: StripeSource = queryResult.result;
        setOrganizationSource(source);
    }

    useEffect(() => {        
        if (organization && organization.paymentMethodId) {
            getSource(organization.paymentMethodId);
        }
    }, [organization]);

    const handlePaymentMethod = async (result: any) => {
        const source = result.source;
        if (organization) {
            const input: UpdateOrganizationInput = {
                id: organization.id,
                paymentMethodId: source.id
            };
            const updateResult = await updateOrganization(input);
            if (updateResult.isSuccess) {
                setOrganizationSource(source);
                if (onSubmit) onSubmit(source);
            } else {
                setError("Could not save the payment method.");
            }
        } else {
            setError("No Organization to attach payment method");
        }
    }

    const handleRemovePaymentMethod = async () => {
        if (organization) {
            const input: UpdateOrganizationInput = {
                id: organization.id,
                paymentMethodId: ""
            };
            const updateResult = await updateOrganization(input);
            if (updateResult.isSuccess) {
                setOrganizationSource(undefined);
            } else {
                setError("Could not remove the payment method.");
            }
        } else {
            setError("No Organization to remove payment method");
        }
    } 

    return (
        <>
            {error && <ErrorAlert error={error} width="12" />}
            { organizationSource ?
                <>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonText>
                                <h3>
                                    Your organization has a saved payment method with the last 4 digits: <span className="font-weight-bold">{organizationSource.card?.last4}</span>
                                </h3>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <hr/>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton
                                color="danger"
                                onClick={handleRemovePaymentMethod}
                            >
                                Remove Payment Method
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
                :
                <IonRow>
                    <IonCol>
                        <IonText>Add a payment method for organization members to use. Only the last 4 digits of the card will be displayed.</IonText>
                        {organization && 
                            <>
                                <PaymentMethodForm organization={organization} handleSubmit={handlePaymentMethod}/>
                            </>
                        }
                    </IonCol>
                </IonRow>
            }
        </>
    );
};

export default OrganizationPaymentMethod; 