import {
    IonButton,
    IonCol,
    IonItem,
    IonLabel,
    IonRow,
    IonToggle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { UpdateEventInput } from "../../API";
import { Event, EventOptions } from "../../models";
import { updateEvent } from "../../utilities/events/Event";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    event: Event
}

const EventAutoAcceptEntriesSettingsForm: React.FC<_Props> = ({event}) => {

    const [autoAccept, setAutoAccept] = useState(false);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (event) {
            if (event.eventOptions?.autoAcceptNewEntries) setAutoAccept(true);
        }
    }, [event]);

    const handleSave = async () => {
        const eventOptions: EventOptions = {
            ...event.eventOptions,
            autoAcceptNewEntries: autoAccept
        }
        const input: UpdateEventInput = {
            id: event.id,
            eventOptions: eventOptions
        };
        const updateResult = await updateEvent(input);
        if (updateResult.isSuccess) {
            setIsDisabled(true);
        } else {
            setError("Could not save the Entry Settings.");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        await handleSave();
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <IonRow>
                <IonCol size="12">
                    <p className="description">When people submit entries, you have a status of submitted.</p>
                    <p className="description">You have to review entries and accept them before they appear in class counts and class sheets.</p>
                    <p className="description">If you would like to skip the review process, you can use this setting.</p>
                    <IonItem lines="none">
                        <IonLabel slot="start" className="ion-text-wrap">Auto Accept New Entries</IonLabel>
                        {/* <p className="text-light">Require each entry to include the date, state, and accession number of the current coggins.</p> */}
                        <IonToggle color="tertiary" checked={autoAccept} onIonChange={e => {setAutoAccept(e.detail.checked); setIsDisabled(false);}}/>
                    </IonItem> 
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            disabled={isDisabled}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            {isDisabled ? "Saved" : "Save"}
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </>
    );
};

export default EventAutoAcceptEntriesSettingsForm;