import {
    IonButton,
    IonCol,
    IonItem,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Spinner from "../../Spinners/Spinner";
import { PersonContext } from "../../../context/PersonContext";
import { Event } from "../../../models";
import { updateEvent } from "../../../utilities/events/Event";
import ErrorAlert from "../../Errors/ErrorAlert";
import {entriesStatusOptions} from "../../../utilities/events/EventStatus";
import { UpdateEventInput } from "../../../API";

interface _Props {
    event: Event
}

const EventStatusForm: React.FC<_Props> = ({event}) => {
    const user = useContext(PersonContext);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState(event.status === "draft" ? "draft" : "published");
    const [entriesStatus, setEntriesStatus] = useState<string | null | undefined>(event.entriesStatus || undefined);
    const [error, setError] = useState("");

    useEffect(() => {
        if (event && event.status) setStatus(event.status === "draft" ? "draft" : "published");
        if (event && event.entriesStatus) setEntriesStatus(event.entriesStatus);
    }, [event]);

    const handleSubmit = async () => {
        setIsLoading(true);
        setIsDisabled(true);
        await updateCurrentEvent();
        setIsLoading(false);
    }

    const updateCurrentEvent = async () => {
        try {
            const eventInput: UpdateEventInput = {
                id: event.id,
                lastUpdatedBy: user.id,
                status: status,
                entriesStatus: entriesStatus
            };
            const updateResult = await updateEvent(eventInput);
            if (!updateResult.isSuccess) setError("Sorry, we could not update the event's status. Please refresh and try again.");
        } catch (error: any) {
            setError("Sorry, we could not update the event's status.");
        }
    };

    return (
        <form>
            {error && <ErrorAlert width="12" error={error} />}
            <IonRow>
                <IonCol>
                    <IonRadioGroup
                        id="draftGroup"
                        value={status}
                        onIonChange={e => {setIsDisabled(false); setStatus(e.detail.value); setEntriesStatus(undefined);}}
                    >
                        <IonItem>
                            <IonLabel>Draft <span className="text-info description">(the event is hidden from regular users while you make edits)</span></IonLabel>
                            <IonRadio slot="end" value="draft" color="tertiary" />
                        </IonItem>                     
                    </IonRadioGroup>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonRadioGroup
                        id="publishGroup"
                        value={status}
                        onIonChange={e => {setIsDisabled(false); setStatus(e.detail.value); setEntriesStatus(undefined);}}
                    >
                        <IonItem>
                            <IonLabel>Published <span className="text-info description">(the event is displayed to regular users and you are no longer able to edit)</span></IonLabel>
                            <IonRadio slot="end" value="published" color="tertiary" />
                        </IonItem>                        
                    </IonRadioGroup>
                </IonCol>
            </IonRow>
            {(status !== "draft" && event.eventOptions?.usesEventManagement) && (
                <>  
                    <IonRow>
                        <IonCol>
                            <IonRadioGroup
                                id="entriesGroup"
                                value={entriesStatus}
                                onIonChange={e => {setIsDisabled(false); setEntriesStatus(e.detail.value)}}
                            >
                                {entriesStatusOptions.map((option, index) => (
                                    <IonItem key={index}>
                                        <IonLabel>{option.name}</IonLabel>
                                        <IonRadio slot="end" value={option.value} color="tertiary" />
                                    </IonItem>
                                ))}                        
                            </IonRadioGroup>
                        </IonCol>
                    </IonRow>
                </>
            )}
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            disabled={isDisabled}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            {isDisabled ? "Saved" : "Save"}
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </form>
    );
};

export default EventStatusForm;