import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { EventEntry, Trainer } from "../../../models";
import { getTrainerById } from "../../../utilities/trainer/Trainer";

interface _Props {
    entry: EventEntry
}

const DisplayEntryTrainerInfo: React.FC<_Props> = ({entry}) => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [trainer, setTrainer] = useState<Trainer | null | undefined>();

    const getTrainer = async (entry: EventEntry) => {
        const trainerId = entry.trainerId;
        if (trainerId) {
            const queryResult = await getTrainerById(trainerId);
            if (queryResult.isSuccess) {
                setTrainer(queryResult.result);
            }
        }
    }

    useEffect(() => {
        if (entry) {
            setIsCollapsed(true);
            getTrainer(entry);
        }
    }, [entry]);

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {entry ? <IonRow><h2>Trainer: {entry.trainerName}</h2></IonRow> : <h2>No Trainer</h2> }
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {trainer && (
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <p>Trainer Name: {trainer.name}</p>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        {trainer.barnName ? <p>Barn: {trainer.barnName}</p> : ""}
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default DisplayEntryTrainerInfo;