import React, { createRef, useEffect, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import { Event, EventEntry, Membership } from "../../models";
import Spinner from "../Spinners/Spinner";
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCol, IonIcon, IonItem, IonLabel, IonList, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonToggle, isPlatform } from "@ionic/react";
import { getAcceptedEventEntriesByEventId, getEventEntriesByEventId, getInProgressEventEntriesByEventId, getSubmittedEventEntriesByEventId, updateEventEntry } from "../../utilities/eventEntry/EventEntry";
import moment from "moment";
import { Input, Table } from "reactstrap";
import { useHistory } from "react-router";
import { isWindows } from "../../utilities/platform/Platform";
import { sortEventEntries } from "../../utilities/eventEntry/SortEventEntry";
import { chevronDown, chevronUp, createOutline } from "ionicons/icons";
import { UpdateEventClassEntryInput, UpdateEventEntryInput, UpdateMembershipInput } from "../../API";
import { updateEventEntryFeeStatusByEventByEntry } from "../../utilities/eventEntryFee/UpdateEventEntryFeeStatus";
import { updateStablingRequestStatusByPersonByEventByEntry } from "../../utilities/stablingRequest/StablingRequestStatus";
import { getEventClassEntriesByEventIdEntryId, updateEventClassEntry } from "../../utilities/eventClassEntry/EventClassEntry";
import { generateAllEntriesReport } from "../../utilities/reports/AllEntriesReport";
import { getMembershipsByOrganizationId, updateMembership } from "../../utilities/membership/Membership";
import constants from "../../constant/constant";

interface _Props {
    event: Event
}

interface formattedEventEntry {
    id: string
    number?: string
    riderName?: string
    horseName?: string
    trainerName?: string
    barnName?: string
    membership?: Membership
    status: string
    timeSubmitted?: string
    isCheckedIn: boolean
    isCheckedOut: boolean
}

enum sortOptions {
    number1 = "SortByNumberSmallToLarge",
    number2 = "SortByNumberLargeToSmall",
    rider1 = "SortByRiderNameAtoZ",
    rider2 = "SortByRiderNameZtoA",
    horse1 = "SortByHorseNameAtoZ",
    horse2 = "SortByHorseNameZtoA",
    trainer1 = "SortByTrainerNameAtoZ",
    trainer2 = "SortByTrainerNameZtoA",
    barn1 = "SortByBarnNameAtoZ",
    barn2 = "SortByBarnNameZtoA",
    time1 = "SortByTimeNewestToOldest",
    time2 = "SortByTimeOldestToNewest",
}

const ViewAllEntries: React.FC<_Props> = ({event}) => {
    const history = useHistory();
    const searchbarRef = createRef<HTMLIonSearchbarElement>();

    const [initialLoad, setInitialLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditBackNumber, setIsEditBackNumber] = useState(false);
    const [isEditStatus, setIsEditStatus] = useState(false);
    const [isEditCheckedIn, setIsEditCheckedIn] = useState(false);
    const [isEditCheckedOut, setIsEditCheckedOut] = useState(false);
    const [error, setError] = useState<string>("");
    const [isDownloading, setIsDownloading] = useState(false);
    const [activeTab, setActiveTab] = useState("all");
    const [searchText, setSearchText] = useState("");
    const [entries, setEntries] = useState<EventEntry[] | null | undefined>();
    const [formattedEntries, setFormattedEntries] = useState<formattedEventEntry[] | null | undefined>();

    const [currentSortOption, setCurrentSortOption] = useState(sortOptions.number1);

    function sortEntries(currentSortOption: sortOptions) {
        if (formattedEntries) {
            if (currentSortOption === sortOptions.number1) {
                const sorted = formattedEntries.sort(function(a: formattedEventEntry, b: formattedEventEntry): number {
                    const numberResult = (parseInt(b.number || "0") || 0) - (parseInt(a.number || "0") || 0);
                    const nameResult = (a.horseName || "").localeCompare(b.horseName || "");
                    return numberResult || nameResult;
                });
                setFormattedEntries(sorted);
            } else if (currentSortOption === sortOptions.number2) {
                const sorted = formattedEntries.sort(function(a: formattedEventEntry, b: formattedEventEntry): number {
                    const numberResult = (parseInt(a.number || "0") || 0) - (parseInt(b.number || "0") || 0);
                    const nameResult = (a.horseName || "").localeCompare(b.horseName || "");
                    return numberResult || nameResult;
                });
                setFormattedEntries(sorted);
            } else if (currentSortOption === sortOptions.rider1) {
                const sorted = formattedEntries.sort(function(a: formattedEventEntry, b: formattedEventEntry): number {
                    const riderNameResult = (a.riderName || "").localeCompare(b.riderName || "");
                    const horseNameResult = (a.horseName || "").localeCompare(b.horseName || "");
                    return riderNameResult || horseNameResult;
                });
                setFormattedEntries(sorted);
            } else if (currentSortOption === sortOptions.rider2) {
                const sorted = formattedEntries.sort(function(a: formattedEventEntry, b: formattedEventEntry): number {
                    const riderNameResult = (b.riderName || "").localeCompare(a.riderName || "");
                    const horseNameResult = (b.horseName || "").localeCompare(a.horseName || "");
                    return riderNameResult || horseNameResult;
                });
                setFormattedEntries(sorted);
            } else if (currentSortOption === sortOptions.horse1) {
                const sorted = formattedEntries.sort(function(a: formattedEventEntry, b: formattedEventEntry): number {
                    const horseNameResult = (a.horseName || "").localeCompare(b.horseName || "");
                    const riderNameResult = (a.riderName || "").localeCompare(b.riderName || "");
                    return horseNameResult || riderNameResult;
                });
                setFormattedEntries(sorted);
            } else if (currentSortOption === sortOptions.horse2) {
                const sorted = formattedEntries.sort(function(a: formattedEventEntry, b: formattedEventEntry): number {
                    const horseNameResult = (b.horseName || "").localeCompare(a.horseName || "");
                    const riderNameResult = (b.riderName || "").localeCompare(a.riderName || "");
                    return horseNameResult || riderNameResult;
                });
                setFormattedEntries(sorted);
            } else if (currentSortOption === sortOptions.trainer1) {
                const sorted = formattedEntries.sort(function(a: formattedEventEntry, b: formattedEventEntry): number {
                    const trainerNameResult = (a.trainerName || "").localeCompare(b.trainerName || "");
                    const horseNameResult = (a.horseName || "").localeCompare(b.horseName || "");
                    return trainerNameResult || horseNameResult;
                });
                setFormattedEntries(sorted);
            } else if (currentSortOption === sortOptions.trainer2) {
                const sorted = formattedEntries.sort(function(a: formattedEventEntry, b: formattedEventEntry): number {
                    const trainerNameResult = (b.trainerName || "").localeCompare(a.trainerName || "");
                    const horseNameResult = (b.horseName || "").localeCompare(a.horseName || "");
                    return trainerNameResult || horseNameResult;
                });
                setFormattedEntries(sorted);
            } else if (currentSortOption === sortOptions.barn1) {
                const sorted = formattedEntries.sort(function(a: formattedEventEntry, b: formattedEventEntry): number {
                    const barnNameResult = (a.barnName || "").localeCompare(b.barnName || "");
                    const horseNameResult = (a.horseName || "").localeCompare(b.horseName || "");
                    return barnNameResult || horseNameResult;
                });
                setFormattedEntries(sorted);
            } else if (currentSortOption === sortOptions.barn2) {
                const sorted = formattedEntries.sort(function(a: formattedEventEntry, b: formattedEventEntry): number {
                    const barnNameResult = (b.barnName || "").localeCompare(a.barnName || "");
                    const horseNameResult = (b.horseName || "").localeCompare(a.horseName || "");
                    return barnNameResult || horseNameResult;
                });
                setFormattedEntries(sorted);
            } else if (currentSortOption === sortOptions.time1) {
                const sorted = formattedEntries.sort(function(a: formattedEventEntry, b: formattedEventEntry): number {
                    const timeUpdatedResult = (a.timeSubmitted || "").localeCompare(b.timeSubmitted || "");
                    const horseNameResult = (a.horseName || "").localeCompare(b.horseName || "");
                    return timeUpdatedResult || horseNameResult;
                });
                setFormattedEntries(sorted);
            } else if (currentSortOption === sortOptions.time2) {
                const sorted = formattedEntries.sort(function(a: formattedEventEntry, b: formattedEventEntry): number {
                    const timeUpdatedResult = (b.timeSubmitted || "").localeCompare(a.timeSubmitted || "");
                    const horseNameResult = (b.horseName || "").localeCompare(a.horseName || "");
                    return timeUpdatedResult || horseNameResult;
                });
                setFormattedEntries(sorted);
            }
        }
    }

    function formatSingleEntry(eventEntry: EventEntry, membershipsList?: Membership[]) {
        const selectedMembership = membershipsList?.find((mem) => {
            // First, must match rider
            let isRiderMatch = false;
            if (mem.rider?.id === eventEntry.riderId || mem.rider?.id === eventEntry.rider?.id) isRiderMatch = true;
            else if (mem.rider?.name.toLowerCase().trim() === eventEntry?.riderName?.toLowerCase().trim() || mem.rider?.name.toLowerCase().trim() === eventEntry?.rider?.name?.toLowerCase().trim()) isRiderMatch = true;

            if (!isRiderMatch) return false;

            // Then, must match horse
            let isHorseMatch = false;
            if (mem.horse?.id === eventEntry.horseId || mem.horse?.id === eventEntry.horse?.id) isHorseMatch = true;
            else if (mem.horse?.name.toLowerCase().trim() === eventEntry?.horseName?.toLowerCase().trim() || mem.horse?.name.toLowerCase().trim() === eventEntry?.horse?.name?.toLowerCase().trim()) isHorseMatch = true;

            if (!isHorseMatch) return false;

            // Then, must be active
            const isActive = (mem.dateMembershipEnds) ? moment(new Date()).isSameOrBefore(moment(mem.dateMembershipEnds)) : true;
            if (!isActive) return false;
            
            return true;
        });
        let formatted: formattedEventEntry = {
            id: eventEntry.id,
            status: eventEntry.status,
            number: eventEntry.number?.toString() || "",
            riderName: eventEntry.riderName || "",
            horseName: eventEntry.horseName || "", 
            trainerName: eventEntry.trainerName || "",
            barnName: eventEntry.barnName || eventEntry.barn?.name || "",
            membership: selectedMembership,
            timeSubmitted: moment(eventEntry.updatedOn).format("hh:mm a MM/DD/YY"),
            isCheckedIn: !!eventEntry.isCheckedIn,
            isCheckedOut: !!eventEntry.isCheckedOut
        };
        return formatted;
    };

    function formatEntries(eventEntries: EventEntry[], membershipsList?: Membership[]) {
        let formattedArray: formattedEventEntry[] = [];
        for (let i = 0; i < eventEntries.length; i++) {
            const currentEntry = eventEntries[i];
            const formattedEntry = formatSingleEntry(currentEntry, membershipsList);
            formattedArray.push(formattedEntry);
        }
        setFormattedEntries(formattedArray);
    }

    async function getAllEntries(event: Event, membershipsList?: Membership[]) {
        const queryResult = await getEventEntriesByEventId(event.id);
        if (queryResult.isSuccess) {
            const entryList = queryResult.result;
            const sorted = sortEventEntries(entryList);
            setEntries(sorted || entryList);
            formatEntries(sorted || entryList, membershipsList);
        }
    }

    async function getEntriesByEvent(event?: Event, tab?: string) {
        setIsLoading(true);
        if (event) {
            let membershipsList: Membership[] = [];
            
            // Removed RHSC ability to view memberships because it was slowing down page load time
            // if (event.organizationId === constants.RHSC_ORGANIZATION.id) {
            //     const queryResult = await getMembershipsByOrganizationId(event.organizationId);
            //     if (queryResult.isSuccess) {
            //         const orgMemberships: Membership[] = queryResult.result;
            //         if (orgMemberships && orgMemberships.length > 0) {
            //             membershipsList = orgMemberships;
            //         }
            //     }
            // }

            const value = tab || activeTab;
            if (value === "all") {
                await getAllEntries(event, membershipsList);
            }
            if (value === "accepted") {
                const queryResult = await getAcceptedEventEntriesByEventId(event.id);
                if (queryResult.isSuccess) {
                    const entryList = queryResult.result;
                    const sorted = sortEventEntries(entryList);
                    setEntries(sorted || entryList);
                    await formatEntries(sorted || entryList, membershipsList);
                }
            }
            if (value === "submitted") {
                const queryResult = await getSubmittedEventEntriesByEventId(event.id);
                if (queryResult.isSuccess) {
                    const entryList = queryResult.result;
                    const sorted = sortEventEntries(entryList);
                    setEntries(sorted || entryList);
                    await formatEntries(sorted || entryList, membershipsList);
                }
            }
            if (value === "in progress") {
                const queryResult = await getInProgressEventEntriesByEventId(event.id);
                if (queryResult.isSuccess) {
                    const entryList = queryResult.result;
                    const sorted = sortEventEntries(entryList);
                    setEntries(sorted || entryList);
                    await formatEntries(sorted || entryList, membershipsList);
                }
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (event && !initialLoad) {
            setInitialLoad(true);
            // Only load the entries in once. This will preserve the filtered data if the user 
            // views an entry and then navigates back to the All Entries page
            if (!entries) {
                getEntriesByEvent(event);
            }
        }
    }, [event]);

    useEffect(() => {
        // Set the focus to the search bar ref
        setTimeout(() => {
        if (searchbarRef.current) {
            searchbarRef.current.setFocus();
        }
        }, 1000);

    }, [searchbarRef]);

    const handleSelection = async (selection: string) => {
        setActiveTab(selection);
        if (event) getEntriesByEvent(event, selection);
    }

    const handleSearchInput = async (input: string,) => {
        let searchResults: EventEntry[] = [];

        // If search input is empty, reset to all accepted entries
        if (!input || input === "") {
            if (entries) formatEntries(entries);
            else await getEntriesByEvent(event);
        } else {
            // Try search by entry number
            let numberResult = entries?.filter(entry => {
                const found = (entry.number?.toString() === input ||
                entry?.horseName?.toLowerCase().includes(input.toLowerCase()) ||
                entry?.horse?.name?.toLowerCase().includes(input.toLowerCase()) ||
                entry?.trainerName?.toLowerCase().includes(input.toLowerCase()) ||
                entry?.trainer?.name?.toLowerCase().includes(input.toLowerCase()) ||
                entry?.riderName?.toLowerCase().includes(input.toLowerCase()) ||
                entry?.rider?.name?.toLowerCase().includes(input.toLowerCase()) ||
                entry?.secondRiderName?.toLowerCase().includes(input.toLowerCase()) ||
                entry?.secondRider?.name?.toLowerCase().includes(input.toLowerCase()) ||
                entry?.barnName?.toLowerCase().includes(input.toLowerCase())) ||
                entry?.barn?.name?.toLowerCase().includes(input.toLowerCase());
                return found;
            });
            if (numberResult) {
                searchResults = searchResults.concat(numberResult);
            }
            formatEntries(searchResults);
        }

    }

    const handleNumberChange = (number: string, eventEntry: formattedEventEntry, filteredIndex: number) => {
        if (formattedEntries) {
            let updated: formattedEventEntry = {
                ...eventEntry,
                number: number
            }; 
            let updatedArray = [
                ...formattedEntries.slice(0, filteredIndex),
                updated,
                ...formattedEntries.slice(filteredIndex + 1)
            ];
            setFormattedEntries(updatedArray);
        }

        if (entries) {
            let index = entries.findIndex((entry: EventEntry) => entry.id === eventEntry.id);
            if (index !== -1) {
                const oldEventEntry = entries[index];
                const updatedEventEntry: EventEntry = {
                    ...oldEventEntry,
                    number: parseInt(number) || 0
                };  
                const updatedEntryArray: EventEntry[] = [
                    ...entries.slice(0, index),
                    updatedEventEntry,
                    ...entries.slice(index + 1)
                ];
                setEntries(updatedEntryArray);
            }
        }
    }

    const handleSaveEntryNumbers = async() => {
        setIsLoading(true);
        if (entries) {
            for (let i = 0; i < entries.length; i++) {
                const currentEntry = entries[i];
                if (currentEntry) {
                    let updateInput: UpdateEventEntryInput = {
                        id: currentEntry.id,
                        number: currentEntry.number || 0
                    };
                    await updateEventEntry(updateInput);
                }
            }
        }
        setIsEditBackNumber(false);
        setIsLoading(false);
    }

    const handleStatusChange = async (status: string, eventEntry: formattedEventEntry, filteredIndex: number) => {
        if (formattedEntries) {
            let updated: formattedEventEntry = {
                ...eventEntry,
                status: status
            }; 
            let updatedArray = [
                ...formattedEntries.slice(0, filteredIndex),
                updated,
                ...formattedEntries.slice(filteredIndex + 1)
            ];
            setFormattedEntries(updatedArray);
        }

        if (entries) {
            let index = entries.findIndex((entry: EventEntry) => entry.id === eventEntry.id);
            if (index !== -1) {
                const oldEventEntry = entries[index];
                const updatedEventEntry: EventEntry = {
                    ...oldEventEntry,
                    status: status
                };  
                const updatedEntryArray: EventEntry[] = [
                    ...entries.slice(0, index),
                    updatedEventEntry,
                    ...entries.slice(index + 1)
                ];
                setEntries(updatedEntryArray);

                let updateInput: UpdateEventEntryInput = {
                    id: oldEventEntry.id,
                    status: status
                };
                const updateResult = await updateEventEntry(updateInput);
                if (updateResult.isSuccess) {
                    await updateEventEntryFeeStatusByEventByEntry(oldEventEntry.eventId, oldEventEntry.id, "accepted");
                    await updateStablingRequestStatusByPersonByEventByEntry(oldEventEntry.createdBy, oldEventEntry.eventId, oldEventEntry.id, "accepted");
                    await updateEventClassEntriesStatus(oldEventEntry);
                    if (eventEntry.membership) await updateMembershipShowsAttended(eventEntry.membership)
                } else {
                    setError(updateResult.message);
                }
            }
        }
    }

    const handleCheckedInChange = async (status: boolean, eventEntry: formattedEventEntry, filteredIndex: number) => {
        if (formattedEntries) {
            let updated: formattedEventEntry = {
                ...eventEntry,
                isCheckedIn: status
            }; 
            let updatedArray = [
                ...formattedEntries.slice(0, filteredIndex),
                updated,
                ...formattedEntries.slice(filteredIndex + 1)
            ];
            setFormattedEntries(updatedArray);
        }

        if (entries) {
            let index = entries.findIndex((entry: EventEntry) => entry.id === eventEntry.id);
            if (index !== -1) {
                const oldEventEntry = entries[index];
                const updatedEventEntry: EventEntry = {
                    ...oldEventEntry,
                    isCheckedIn: status
                };  
                const updatedEntryArray: EventEntry[] = [
                    ...entries.slice(0, index),
                    updatedEventEntry,
                    ...entries.slice(index + 1)
                ];
                setEntries(updatedEntryArray);

                let updateInput: UpdateEventEntryInput = {
                    id: oldEventEntry.id,
                    isCheckedIn: status
                };
                const updateResult = await updateEventEntry(updateInput);
                if (updateResult.isSuccess) {
                    await updateEventEntryFeeStatusByEventByEntry(oldEventEntry.eventId, oldEventEntry.id, "accepted");
                    await updateStablingRequestStatusByPersonByEventByEntry(oldEventEntry.createdBy, oldEventEntry.eventId, oldEventEntry.id, "accepted");
                    await updateEventClassEntriesStatus(oldEventEntry);
                } else {
                    setError(updateResult.message);
                }
            }
        }
    }

    const handleCheckedOutChange = async (status: boolean, eventEntry: formattedEventEntry, filteredIndex: number) => {
        if (formattedEntries) {
            let updated: formattedEventEntry = {
                ...eventEntry,
                isCheckedOut: status
            }; 
            let updatedArray = [
                ...formattedEntries.slice(0, filteredIndex),
                updated,
                ...formattedEntries.slice(filteredIndex + 1)
            ];
            setFormattedEntries(updatedArray);
        }

        if (entries) {
            let index = entries.findIndex((entry: EventEntry) => entry.id === eventEntry.id);
            if (index !== -1) {
                const oldEventEntry = entries[index];
                const updatedEventEntry: EventEntry = {
                    ...oldEventEntry,
                    isCheckedOut: status
                };  
                const updatedEntryArray: EventEntry[] = [
                    ...entries.slice(0, index),
                    updatedEventEntry,
                    ...entries.slice(index + 1)
                ];
                setEntries(updatedEntryArray);

                let updateInput: UpdateEventEntryInput = {
                    id: oldEventEntry.id,
                    isCheckedOut: status
                };
                const updateResult = await updateEventEntry(updateInput);
                if (updateResult.isSuccess) {
                    await updateEventEntryFeeStatusByEventByEntry(oldEventEntry.eventId, oldEventEntry.id, "accepted");
                    await updateStablingRequestStatusByPersonByEventByEntry(oldEventEntry.createdBy, oldEventEntry.eventId, oldEventEntry.id, "accepted");
                    await updateEventClassEntriesStatus(oldEventEntry);
                } else {
                    setError(updateResult.message);
                }
            }
        }
    }

    async function updateEventClassEntriesStatus(entry: EventEntry) {
        const queryResult = await getEventClassEntriesByEventIdEntryId(entry.eventId, entry.id);
        const currentEventEntryClasses = queryResult.result;
        let result = true;
        if (currentEventEntryClasses && currentEventEntryClasses.length) {
            for (var i = 0; i < currentEventEntryClasses.length; i++) {
                const currentEventClassEntry = currentEventEntryClasses[i];
                const updateInput: UpdateEventClassEntryInput = {
                    id: currentEventClassEntry.id,
                    status: "accepted"
                };
                const updateResult = await updateEventClassEntry(updateInput);
                if (!updateResult.isSuccess) {
                    result = false;
                    setError(updateResult.message);
                }
            }
        }
        return result;
    }

    async function updateMembershipShowsAttended(membership: Membership) {
        let updateMembershipInput: UpdateMembershipInput = {
            id: membership.id,
            showsAttended: (membership.showsAttended ? membership.showsAttended : 0) + 1
        };
        await updateMembership(updateMembershipInput);
    }

    const navigateToPage = (entryId: string) => {
        const path = "/index/staff/event/entry/" + entryId;
        history.push(path);
    }

    const navigateToContactListPage = () => {
        const path = "/index/staff/event/" + event.id + "/participants/contact-list";
        history.push(path);
    }

    const handleDownloadCSV = async () => {
        setIsDownloading(true);
        if (formattedEntries) {
            if (entries) await generateAllEntriesReport(event, entries);
            setIsDownloading(false);
        } else {
            setIsDownloading(false);
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {isLoading ?
                <Spinner/>
                :
                <form>
                    <IonCard color="white" className="ion-padding">
                        <IonCardTitle>
                            <h3>Entries</h3>
                        </IonCardTitle>
                        <IonCardContent>
                            <IonRow>
                                <IonCol>
                                    <IonSegment color="primary" mode="md" value={activeTab} scrollable={true} onIonChange={e => {handleSelection(e.detail.value || "about");}}>
                                        <IonSegmentButton value="all">
                                            <IonLabel>All</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="accepted">
                                            <IonLabel>Accepted</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="submitted">
                                            <IonLabel>Submitted</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="in progress">
                                            <IonLabel>In Progress</IonLabel>
                                        </IonSegmentButton>
                                    </IonSegment>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeXs="12" sizeMd="3" sizeLg="1">
                                    <p>Count: {entries?.length || "0"}</p> 
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="2">
                                    {isDownloading ?
                                        <Spinner />
                                        :
                                        <p className="link" onClick={handleDownloadCSV}>Download Entries</p>
                                    }
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="3" sizeLg="2">
                                    <p className="link" onClick={navigateToContactListPage}>Contact List</p> 
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeXs="12" sizeMd="12">
                                    <IonSearchbar
                                        color="white"  
                                        ref={searchbarRef}
                                        placeholder="Search by Number, Rider, Horse, Trainer or Barn" 
                                        value={searchText} 
                                        onIonChange={e => {
                                            setSearchText(e.detail.value!)
                                            handleSearchInput(e.detail.value!)
                                        }}
                                    />
                                </IonCol>
                            </IonRow> 
                            <IonRow>
                                {isEditBackNumber && (
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonButton color="success" onClick={() => handleSaveEntryNumbers()}>
                                            Save Back Numbers
                                        </IonButton>
                                    </IonCol>
                                )}
                            </IonRow>
                            {(formattedEntries && formattedEntries.length) ? 
                                <>
                                    {(isWindows() || isPlatform("desktop")) ? 
                                        <Table hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <p>
                                                            <span onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.number1 ? sortOptions.number2 : sortOptions.number1); sortEntries(currentSortOption === sortOptions.number1 ? sortOptions.number2 : sortOptions.number1);} }>
                                                                Number
                                                            </span>
                                                            <IonIcon icon={currentSortOption === sortOptions.number1 ? chevronDown : chevronUp} onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.number1 ? sortOptions.number2 : sortOptions.number1); sortEntries(currentSortOption === sortOptions.number1 ? sortOptions.number2 : sortOptions.number1);} } /> 
                                                            <IonIcon icon={createOutline} ariaLabel="Edit Numbers" onClick={() => setIsEditBackNumber(true)} />
                                                        </p>
                                                    </th>
                                                    <th>
                                                        <p onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.rider1 ? sortOptions.rider2 : sortOptions.rider1); sortEntries(currentSortOption === sortOptions.rider1 ? sortOptions.rider2 : sortOptions.rider1);} }>
                                                            Rider <IonIcon icon={currentSortOption === sortOptions.rider1 ? chevronDown : chevronUp} />
                                                        </p>
                                                    </th>
                                                    <th>
                                                        <p onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.horse1 ? sortOptions.horse2 : sortOptions.horse1); sortEntries(currentSortOption === sortOptions.horse1 ? sortOptions.horse2 : sortOptions.horse1);} }>
                                                            Horse <IonIcon icon={currentSortOption === sortOptions.horse1 ? chevronDown : chevronUp} />
                                                        </p>
                                                    </th>
                                                    <th>
                                                        <p onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.trainer1 ? sortOptions.trainer2 : sortOptions.trainer1); sortEntries(currentSortOption === sortOptions.trainer1 ? sortOptions.trainer2 : sortOptions.trainer1);} }>
                                                            Trainer <IonIcon icon={currentSortOption === sortOptions.trainer1 ? chevronDown : chevronUp} />
                                                        </p>
                                                    </th>
                                                    <th>
                                                        <p onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.barn1 ? sortOptions.barn2 : sortOptions.barn1); sortEntries(currentSortOption === sortOptions.barn1 ? sortOptions.barn2 : sortOptions.barn1);} }>
                                                            Barn <IonIcon icon={currentSortOption === sortOptions.barn1 ? chevronDown : chevronUp} />
                                                        </p>
                                                    </th>
                                                    {/* {event.organizationId === constants.RHSC_ORGANIZATION.id && (
                                                        <th>
                                                            <p>
                                                                Membership
                                                            </p>
                                                        </th>
                                                    )}  */}
                                                    <th>
                                                        <p>
                                                            Status <IonIcon icon={createOutline} ariaLabel="Edit Statuses" onClick={() => setIsEditStatus(!isEditStatus)} />
                                                        </p>
                                                    </th>
                                                    <th>
                                                        <p>
                                                            Checked In <IonIcon icon={createOutline} ariaLabel="Edit Checked In" onClick={() => setIsEditCheckedIn(!isEditCheckedIn)} />
                                                        </p>
                                                    </th>
                                                    <th>
                                                        <p>
                                                            Checked Out <IonIcon icon={createOutline} ariaLabel="Edit Checked Out" onClick={() => setIsEditCheckedOut(!isEditCheckedOut)} />
                                                        </p>
                                                    </th>
                                                    <th>
                                                        <p onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.time1 ? sortOptions.time2 : sortOptions.time1); sortEntries(currentSortOption === sortOptions.time1 ? sortOptions.time2 : sortOptions.time1);} }>
                                                            Last Updated <IonIcon icon={currentSortOption === sortOptions.time1 ? chevronDown : chevronUp} />
                                                        </p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formattedEntries.map((entry, index) => (
                                                    <tr key={index} onClick={(isEditBackNumber || isEditStatus || isEditCheckedIn || isEditCheckedOut) ? (() => console.log("Editing back number.")) : (() => navigateToPage(entry.id))} className={entry.isCheckedOut === true ? "bg-success" : (entry.isCheckedIn === true ? "bg-info" : "")}>
                                                        <td>
                                                            {isEditBackNumber ? 
                                                                <>
                                                                    <Input
                                                                        className="input-width"
                                                                        type="text"
                                                                        value={entry.number}
                                                                        onChange={e => {
                                                                            handleNumberChange(e.target.value, entry, index);
                                                                        }}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    {entry?.number || ""}
                                                                </>
                                                            }
                                                        </td>
                                                        <td onClick={(isEditBackNumber || isEditStatus) ? (() => navigateToPage(entry.id)) : (() => console.log("Is editing back number"))}>{entry.riderName}</td>
                                                        <td onClick={(isEditBackNumber || isEditStatus) ? (() => navigateToPage(entry.id)) : (() => console.log("Is editing back number"))}>{entry.horseName}</td>
                                                        <td onClick={(isEditBackNumber || isEditStatus) ? (() => navigateToPage(entry.id)) : (() => console.log("Is editing back number"))}>{entry.trainerName}</td>
                                                        <td onClick={(isEditBackNumber || isEditStatus) ? (() => navigateToPage(entry.id)) : (() => console.log("Is editing back number"))}>{entry.barnName}</td>
                                                        {/* <td onClick={(isEditBackNumber || isEditStatus) ? (() => navigateToPage(entry.id)) : (() => console.log("Is editing back number"))}>
                                                            {(entry.membership) ?
                                                                <span>
                                                                    <p>{entry.membership?.type}</p>
                                                                    <p>Back #: {entry.membership?.backNumber}</p>
                                                                    <p>Mem Id: {entry.membership?.membershipId}</p>
                                                                    <p>Rider: {entry.membership?.rider?.name}</p>
                                                                    <p>Horse: {entry.membership?.horse?.name}</p>
                                                                    <p># Shows: {entry.membership?.showsAttended}</p>
                                                                </span>
                                                                :
                                                                <p>Not Found</p>
                                                            }
                                                        </td> */}
                                                        <td>
                                                            {isEditStatus ? 
                                                                <>
                                                                    <Input
                                                                        className="large-input-width"
                                                                        type="select"
                                                                        value={entry.status}
                                                                        disabled={entry.status !== "submitted"}
                                                                        onChange={e => {
                                                                            handleStatusChange(e.target.value, entry, index);
                                                                        }}
                                                                    >
                                                                        <option value={entry.status}>{entry.status === "complete" ? "accepted" : entry.status}</option>
                                                                        <option value={"complete"}>accepted</option>
                                                                    </Input>
                                                                </>
                                                                :
                                                                <span className={(entry.isCheckedOut || entry.isCheckedIn) ? "text-primary" : (entry.status === "declined" ? "text-danger" : (entry.status === "complete" ? "text-success" : (entry.status === "in_progress" ? "text-warning" : "text-primary")))}>
                                                                    {entry.status === "declined" ? "declined" : (entry.status === "complete" ? "accepted" : (entry.status === "in_progress" ? "in progress" : "submitted"))}
                                                                </span>
                                                            }
                                                        </td>
                                                        <td>
                                                            {isEditCheckedIn ? 
                                                                <>
                                                                    <IonToggle color="light" checked={entry.isCheckedIn} onIonChange={e => handleCheckedInChange(e.detail.checked, entry, index)}/>
                                                                </>
                                                                :
                                                                <span>
                                                                    {entry.isCheckedIn ? "Yes" : "No"}
                                                                </span>
                                                            }
                                                        </td>
                                                        <td>
                                                            {isEditCheckedOut ? 
                                                                <>
                                                                    <IonToggle color="light" checked={entry.isCheckedOut} onIonChange={e => handleCheckedOutChange(e.detail.checked, entry, index)}/>
                                                                </>
                                                                :
                                                                <span>
                                                                    {entry.isCheckedOut ? "Yes" : "No"}
                                                                </span>
                                                            }
                                                        </td>
                                                        <td onClick={(isEditBackNumber || isEditStatus) ? (() => navigateToPage(entry.id)) : (() => console.log("Is editing back number"))}>{entry.timeSubmitted}</td>
                                                    </tr>
                                                ))}
                                            </tbody> 
                                        </Table>
                                        :
                                        <IonList className="bg-white">
                                            {formattedEntries.map((entry, index) => (
                                                <IonItem key={index} onClick={() => navigateToPage(entry.id)} color={entry.isCheckedOut === true ? "success" : (entry.isCheckedIn === true ? "info" : "")}>
                                                    <IonLabel>
                                                        <IonRow>
                                                            <IonCol size="12">
                                                                <p>Number: {entry.number}</p>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="12">
                                                                <p>Horse: {entry.horseName}</p>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="12">
                                                                <p>Rider: {entry.riderName}</p>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size="12">
                                                                <p>Trainer: {entry.trainerName}</p>
                                                            </IonCol>
                                                        </IonRow> 
                                                        <IonRow>
                                                            <IonCol size="12">
                                                                <p>Barn: {entry.barnName}</p>
                                                            </IonCol>
                                                        </IonRow>  
                                                        <IonRow>
                                                            <IonCol size="12">
                                                                <p>Status: {entry.status}</p>
                                                            </IonCol>
                                                        </IonRow>  
                                                        <IonRow>
                                                            <IonCol size="12">
                                                                <p>Time submitted: {entry.timeSubmitted}</p>
                                                            </IonCol>
                                                        </IonRow>  
                                                    </IonLabel>                                                  
                                                </IonItem>
                                            ))}
                                        </IonList>            
                                    }
                                </>
                                :
                                <IonRow className="ion-justify-content-center">
                                    <IonCol className="text-center" sizeMd="6">
                                        <p>Could not find any entries.</p>
                                    </IonCol>
                                </IonRow>
                            }
                        </IonCardContent>
                    </IonCard>
                </form> 
            }
        </>
    );
};

export default ViewAllEntries;