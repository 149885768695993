import { OrganizationType } from "../../models/index";

export interface OrganizationTypeOption {
    name: string
    value: string
}

function formatTypeName(value: string) {
    let name = value.replace(/_/g, " ");
    switch(value) {
        case "company":
            return "Company"
        case "individual":
            return "Individual"
        case "nonprofit":
            return "Non-Profit"
        default:
            return name;
    };
}

/**
 * Takes in the enum OrganizationType from the models folder
 * Formats the object into an array that can be used to loop over display names with values
 * 
 * @returns an array of objects - {name, value}
 */
export function formatOrganizationTypes(): OrganizationTypeOption[] {
    let results: OrganizationTypeOption[] = [];
    for (const [key, value] of Object.entries(OrganizationType)) {
        const object = {
            key: key,
            name: formatTypeName(value),
            value: value
        };
        results.push(object);
    }
    return results;
}