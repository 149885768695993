import { CreateEventClassInput, CreateEventDivisionInput, CreateEventFeeInput, CreateEventRingInput, CreateEventRulesInput, CreateEventStallTypeInput, CreateScheduleItemInput, DeleteEventResultInput, EventDay, UpdateEventInput } from "../../API";
import { EntryOptions, Event, EventClass, EventDivision, EventFee, EventOptions, EventRing, EventRules, EventStallType, Person, ScheduleItem } from "../../models";
import { SettingsOption } from "../../pages/dashboard/roles/eventStaff/events/SettingsPages/EventDuplicateSettingsPage";
import { createEventClass, deleteEventClass, getEventClassesByEventId } from "../eventClass/EventClass";
import { getEventDaysByEventId } from "../eventDay/EventDay";
import { createEventDivision, deleteEventDivision, getEventDivisionsByEventId } from "../eventDivision/EventDivision";
import { createEventFee, deleteEventFee, getEventFeesByEventId } from "../eventFee/EventFee";
import { createEventRing, deleteEventRing, getEventRingsByEventId } from "../eventRing/EventRing";
import { createEventRules, deleteEventRules, getEventRulesByEventId } from "../eventRules/EventRules";
import { createEventStallType, deleteEventStallType, getEventStallTypesByEventId } from "../eventStallType/EventStallType";
import { createScheduleItem, deleteScheduleItem, getScheduleItemsByEventId } from "../scheduleItem/ScheduleItem";
import { Result } from "../../interfaces/Result";
import { updateEvent } from "./Event";

export async function copyEventSettings(newEvent: Event, oldEvent: Event, settingsList: SettingsOption[], isAutoAcceptNewEntries?: boolean, user?: Person) {
    let result: Result = {isSuccess: true, type: "CopyEvent", result: null, message: "Successfully copied the event"};

    if (settingsList && settingsList.length > 0) {
        for (let i = 0; i < settingsList.length; i++) {
            const currentSettingOption = settingsList[i];
            
            if (currentSettingOption.val === "classes" && currentSettingOption.isChecked) {
                const {classResults, divisionResults} = await copyClassAndDivisionSettings(newEvent, oldEvent, user);
                const eventRingResult = await copyRingSettings(newEvent, oldEvent, user);
                await copyScheduleSettings(newEvent, oldEvent, classResults, divisionResults, eventRingResult, user);
            }

            if (currentSettingOption.val === "fees" && currentSettingOption.isChecked) {
                const feeResult = await copyFeeSettings(newEvent, oldEvent, user);
                
                if (!feeResult.isSuccess) {
                    result = {isSuccess: false, type: "CopyEvent", result: feeResult.result, message: "Could not copy the fees"};
                    return result;
                }
            }

            if (currentSettingOption.val === "stabling" && currentSettingOption.isChecked) {
                const stablingResult = await copyStablingSettings(newEvent, oldEvent, user);
                
                if (!stablingResult.isSuccess) {
                    result = {isSuccess: false, type: "CopyEvent", result: stablingResult.result, message: "Could not copy the fees"};
                    return result;
                }
            }

            if (currentSettingOption.val === "rules" && currentSettingOption.isChecked) {
                const rulesResult = await copyEventRulesSettings(newEvent, oldEvent, user);
                
                if (!rulesResult.isSuccess) {
                    result = {isSuccess: false, type: "CopyEvent", result: rulesResult.result, message: "Could not copy the fees"};
                    return result;
                }
            }

            if (currentSettingOption.val === "coggins" && currentSettingOption.isChecked) {
                const cogginsResult = await copyCogginsSettings(newEvent, oldEvent);
                
                if (!cogginsResult.isSuccess) {
                    result = {isSuccess: false, type: "CopyEvent", result: cogginsResult.result, message: "Could not copy the fees"};
                    return result;
                }
            }

            if (isAutoAcceptNewEntries) {
                const updatedEventOptions: EventOptions = {
                    ...oldEvent.eventOptions,
                    autoAcceptNewEntries: isAutoAcceptNewEntries
                };
                const updatedEventInput: UpdateEventInput = {
                    id: newEvent.id,
                    eventOptions: updatedEventOptions
                };
                await updateEvent(updatedEventInput);
            }
        }
    }

    return result;
}

async function copyClassAndDivisionSettings(newEvent: Event, oldEvent: Event, user?: Person) {
    let oldClasses: EventClass[] = [];
    let newClasses: EventClass[] = [];
    let oldDivisions: EventDivision[] = [];
    let newDivisions: EventDivision[] = [];

    let divisionMap = new Map();

    let classResults: EventClass[] = [];
    let divisionResults: EventDivision[] = [];

    const oldClassesResult = await getEventClassesByEventId(oldEvent.id);
    if (oldClassesResult.isSuccess) {
        oldClasses = oldClassesResult.result;
    }

    const newClassesResult = await getEventClassesByEventId(newEvent.id);
    if (newClassesResult.isSuccess) {
        newClasses = newClassesResult.result;
    }

    const oldDivisionsResult = await getEventDivisionsByEventId(oldEvent.id);
    if (oldDivisionsResult.isSuccess) {
        oldDivisions = oldDivisionsResult.result;
    }

    const newDivisionsResult = await getEventDivisionsByEventId(newEvent.id);
    if (newDivisionsResult.isSuccess) {
        newDivisions = newDivisionsResult.result;
    }

    let eventDivisionsToAdd: EventDivision[] = [];
    let eventDivisionsToDelete: EventDivision[] = [];

    for (let i = 0; i < oldDivisions.length; i++) {
        const eventDivision = oldDivisions[i];
        const findResult = newDivisions.findIndex(r => r.name === eventDivision.name && r.divisionId === eventDivision.divisionId && r.organizationDivisionId === eventDivision.organizationDivisionId);
        if (findResult === -1) {
            eventDivisionsToAdd.push(eventDivision);
        }
    }

    for (let i = 0; i < newDivisions.length; i++) {
        const eventDivision = newDivisions[i];
        const findResult = oldDivisions.findIndex(r => r.name === eventDivision.name && r.divisionId === eventDivision.divisionId && r.organizationDivisionId === eventDivision.organizationDivisionId);
        if (findResult === -1) {
            eventDivisionsToDelete.push(eventDivision);
        } else {
            divisionResults.push(eventDivision);
        }
    }

    for (let i = 0; i < eventDivisionsToAdd.length; i++) {
        const eventDivision = eventDivisionsToAdd[i];
        const eventDivisionInput: CreateEventDivisionInput = {
            name: eventDivision.name,
            eventId: newEvent.id,
            divisionId: eventDivision.divisionId,
            organizationDivisionId: eventDivision.organizationDivisionId,
            entryFee: eventDivision.entryFee,
            prizeMoney: eventDivision.prizeMoney,
            minNumberEntriesPerClass: eventDivision.minNumberEntriesPerClass,
            maxNumberEntriesPerClass: eventDivision.maxNumberEntriesPerClass,
            createdBy: eventDivision.createdBy,
            lastUpdatedBy: user?.id || "",
            // eventDivisionDivisionId: eventDivision.divisionId,
            eventDivisionOrganizationDivisionId: eventDivision.organizationDivisionId,
            eventDivisionPrizeMoneyTableId: eventDivision.prizeMoneyTable?.id || "",
        };
        const createResult = await createEventDivision(eventDivisionInput);
        if (createResult.isSuccess) {
            divisionResults.push(createResult.result);
            divisionMap.set(eventDivision.id, createResult.result);
        } else {
            console.error(createResult);
        }
    }

    for (let i = 0; i < eventDivisionsToDelete.length; i++) {
        const eventDivision = eventDivisionsToDelete[i];
        const eventDivisionInput: DeleteEventResultInput = {
            id: eventDivision.id,
        };
        const deleteResult = await deleteEventDivision(eventDivisionInput);
        if (!deleteResult.isSuccess) {
            console.error(deleteResult);
        }
    }

    let eventClassesToAdd: EventClass[] = [];
    let eventClassesToDelete: EventClass[] = [];

    for (let i = 0; i < oldClasses.length; i++) {
        const eventClass = oldClasses[i];
        const findResult = newClasses.findIndex(r => r.name === eventClass.name && r.number === eventClass.number && r.type === eventClass.type);
        if (findResult === -1) {
            eventClassesToAdd.push(eventClass);
        }
    }

    for (let i = 0; i < newClasses.length; i++) {
        const eventClass = newClasses[i];
        const findResult = oldClasses.findIndex(r => r.name === eventClass.name && r.number === eventClass.number && r.type === eventClass.type);
        if (findResult === -1) {
            eventClassesToDelete.push(eventClass);
        } else {
            classResults.push(eventClass);
        }
    }

    for (let i = 0; i < eventClassesToAdd.length; i++) {
        const eventClass = eventClassesToAdd[i];
        // Ignores any previous combinations of classes or divisions
        let eventClassInput: CreateEventClassInput = {
            name: eventClass.name,
            eventId: newEvent.id,
            classId: eventClass.classId,
            organizationClassId: eventClass?.organizationClassId,
            type: eventClass?.type,
            number: eventClass.number,
            entryFee: eventClass.entryFee,
            prizeMoney: eventClass.prizeMoney,
            minNumberEntries: eventClass.minNumberEntries,
            maxNumberEntries: eventClass.maxNumberEntries,
            estimatedTimePerRound: eventClass.estimatedTimePerRound,
            jumpHeight: eventClass.jumpHeight,
            createdBy: eventClass.createdBy,
            lastUpdatedBy: user?.id,
            // eventClassClassId: eventClass.classId,
            // eventClassOrganizationClassId: eventClass?.organizationClassId,
            eventClassPrizeMoneyTableId: eventClass?.prizeMoneyTable?.id,
        };
        const eventDivision: (EventDivision | null) = divisionMap.get(eventClass.eventDivision?.id);
        if (eventDivision) {
            eventClassInput.eventDivisionId = eventDivision.id;
            // eventClassInput.eventClassEventDivisionId = eventDivision.id;
            eventClassInput.organizationDivisionId = eventDivision.organizationDivisionId;
            // eventClassInput.eventClassOrganizationDivisionId = eventDivision.organizationDivisionId;
        }
        const createResult = await createEventClass(eventClassInput);
        if (createResult.isSuccess) {
            classResults.push(createResult.result);
        } else {
            console.error(createResult);
        }
    }

    for (let i = 0; i < eventClassesToDelete.length; i++) {
        const eventClass = eventClassesToDelete[i];
        const eventClassInput: DeleteEventResultInput = {
            id: eventClass.id,
        };
        const deleteResult = await deleteEventClass(eventClassInput);
        if (!deleteResult.isSuccess) {
            console.error(deleteResult);
        }
    }

    const results = {
        classResults,
        divisionResults
    }

    return results;
}

async function copyRingSettings(newEvent: Event, oldEvent: Event, user?: Person) {
    let oldRings: EventRing[] = [];
    let newRings: EventRing[] = [];

    let result: EventRing[] = [];

    const oldRingsResult = await getEventRingsByEventId(oldEvent.id);
    if (oldRingsResult.isSuccess) {
        oldRings = oldRingsResult.result;
    }

    const newRingsResult = await getEventRingsByEventId(newEvent.id);
    if (newRingsResult.isSuccess) {
        newRings = newRingsResult.result;
    }

    let eventRingsToAdd: EventRing[] = [];
    let eventRingsToDelete: EventRing[] = [];

    for (let i = 0; i < oldRings.length; i++) {
        const eventRing = oldRings[i];
        const findResult = newRings.findIndex(r => r.name === eventRing.name && r.ringId === eventRing.ringId);
        if (findResult === -1) {
            eventRingsToAdd.push(eventRing);
        }
    }

    for (let i = 0; i < newRings.length; i++) {
        const eventRing = newRings[i];
        const findResult = oldRings.findIndex(r => r.name === eventRing.name && r.ringId === eventRing.ringId);
        if (findResult === -1) {
            eventRingsToDelete.push(eventRing);
        } else {
            result.push(eventRing);
        }
    }

    for (let i = 0; i < eventRingsToAdd.length; i++) {
        const eventRing = eventRingsToAdd[i];
        const eventRingInput: CreateEventRingInput = {
            name: eventRing.name,
            eventId: newEvent.id,
            ringId: eventRing.ringId,
            // eventRingEventId: newEvent.id,
            // eventRingRingId: eventRing.ringId
        };
        const createResult = await createEventRing(eventRingInput);
        if (createResult.isSuccess) {
            result.push(createResult.result);
        } else {
            console.error(createResult);
        }
    }

    for (let i = 0; i < eventRingsToDelete.length; i++) {
        const eventRing = eventRingsToDelete[i];
        const eventRingInput: DeleteEventResultInput = {
            id: eventRing.id,
        };
        const deleteResult = await deleteEventRing(eventRingInput);
        if (!deleteResult.isSuccess) {
            console.error(deleteResult);
        }
    }

    return result;
}

async function copyScheduleSettings(newEvent: Event, oldEvent: Event, classes: EventClass[], divisions: EventDivision[], rings: EventRing[], user?: Person) {
    let oldScheduleItems: ScheduleItem[] = [];
    let newScheduleItems: ScheduleItem[] = [];

    let eventDays: EventDay[] = [];
    let daysResult = await getEventDaysByEventId(newEvent.id);
    if (daysResult.isSuccess) {
        eventDays = daysResult.result;
    }

    let result: ScheduleItem[] = [];

    const oldScheduleItemsResult = await getScheduleItemsByEventId(oldEvent.id);
    if (oldScheduleItemsResult.isSuccess) {
        oldScheduleItems = oldScheduleItemsResult.result;
    }

    const newScheduleItemsResult = await getScheduleItemsByEventId(newEvent.id);
    if (newScheduleItemsResult.isSuccess) {
        newScheduleItems = newScheduleItemsResult.result;
    }

    let scheduleItemsToAdd: ScheduleItem[] = [];
    let scheduleItemsToDelete: ScheduleItem[] = [];

    for (let i = 0; i < oldScheduleItems.length; i++) {
        const scheduleItem = oldScheduleItems[i];
        const findResult = newScheduleItems.findIndex(r => r.displayOrder === scheduleItem.displayOrder && r.eventRing?.name === scheduleItem.eventRing?.name && r.eventClass?.name === scheduleItem.eventClass?.name);
        if (findResult === -1) {
            scheduleItemsToAdd.push(scheduleItem);
        }
    }

    for (let i = 0; i < newScheduleItems.length; i++) {
        const scheduleItem = newScheduleItems[i];
        const findResult = oldScheduleItems.findIndex(r => r.displayOrder === scheduleItem.displayOrder && r.eventRing?.name === scheduleItem.eventRing?.name && r.eventClass?.name === scheduleItem.eventClass?.name);
        if (findResult === -1) {
            scheduleItemsToDelete.push(scheduleItem);
        } else {
            // scheduleItemsToDelete.push(scheduleItem);
            result.push(scheduleItem);
        }
    }

    for (let i = 0; i < scheduleItemsToAdd.length; i++) {
        const scheduleItem = scheduleItemsToAdd[i];
        const foundClass = classes.find(c => c.number === scheduleItem.eventClass?.number);
        if (foundClass) {
            const foundDay: (EventDay | null | undefined) = eventDays.find(day => day.dayofweek === scheduleItem.eventDay?.dayofweek);
            if (foundDay) {
                const foundRing: (EventRing | null | undefined) = rings.find(r => r.ringId === scheduleItem.eventRing?.ringId);;
                if (foundRing) {
                    const scheduleItemInput: CreateScheduleItemInput = {
                        eventId: newEvent.id,
                        classId: foundClass.id,
                        // scheduleItemEventClassId: foundClass.id,
                        displayOrder: scheduleItem.displayOrder,
                        startTime: scheduleItem.startTime,
                        eventRingId: foundRing.id,
                        // scheduleItemEventRingId: foundRing.id,
                        eventDayId: foundDay.id,
                        // scheduleItemEventDayId: foundDay.id
                    };
                    const createResult = await createScheduleItem(scheduleItemInput);
                    if (createResult.isSuccess) {
                        result.push(createResult.result);
                    } else {
                        console.error(createResult);
                    }
                }
            }
        }
    }

    for (let i = 0; i < scheduleItemsToDelete.length; i++) {
        const scheduleItem = scheduleItemsToDelete[i];
        const scheduleItemInput: DeleteEventResultInput = {
            id: scheduleItem.id,
        };
        const deleteResult = await deleteScheduleItem(scheduleItemInput);
        if (!deleteResult.isSuccess) {
            console.error(deleteResult);
        }
    }

    return result;
}

async function copyFeeSettings(newEvent: Event, oldEvent: Event, user?: Person) {
    let result: Result = {isSuccess: true, type: "CopyEvent", result: null, message: "Successfully copied the fee settings"};

    let oldFees: EventFee[] = [];
    let newFees: EventFee[] = [];

    const oldFeesResult = await getEventFeesByEventId(oldEvent.id);
    if (oldFeesResult.isSuccess) {
        oldFees = oldFeesResult.result;
    }

    const newFeesResult = await getEventFeesByEventId(newEvent.id);
    if (newFeesResult.isSuccess) {
        newFees = newFeesResult.result;
    }

    let eventFeesToAdd: EventFee[] = [];
    let eventFeesToDelete: EventFee[] = [];

    for (let i = 0; i < oldFees.length; i++) {
        const rule = oldFees[i];
        const findResult = newFees.findIndex(r => r.name === rule.name && r.amount === rule.amount && r.feeId === rule.feeId);
        if (findResult === -1) {
            eventFeesToAdd.push(rule);
        }
    }

    for (let i = 0; i < newFees.length; i++) {
        const rule = newFees[i];
        const findResult = oldFees.findIndex(r => r.name === rule.name && r.amount === rule.amount && r.feeId === rule.feeId);
        if (findResult === -1) {
            eventFeesToDelete.push(rule);
        }
    }

    for (let i = 0; i < eventFeesToAdd.length; i++) {
        const rule = eventFeesToAdd[i];
        const eventFeeInput: CreateEventFeeInput = {
            name: rule.name,
            amount: rule.amount,
            description: rule.description,
            eventId: newEvent.id,
            createdBy: rule.createdBy,
            splitAcrossEntries: rule.splitAcrossEntries,
            feeId: rule.feeId,
            // eventFeeFeeId: rule.feeId
        };
        const createResult = await createEventFee(eventFeeInput);
        if (!createResult.isSuccess) {
            result = {isSuccess: false, type: "CopyEvent", result: createResult.result, message: "Successfully copied the event"};
        }
    }

    for (let i = 0; i < eventFeesToDelete.length; i++) {
        const rule = eventFeesToDelete[i];
        const eventFeeInput: DeleteEventResultInput = {
            id: rule.id,
        };
        const deleteResult = await deleteEventFee(eventFeeInput);
        if (!deleteResult.isSuccess) {
            result = {isSuccess: false, type: "CopyEvent", result: deleteResult.result, message: "Successfully copied the event"};
        }
    }

    return result;
}

async function copyStablingSettings(newEvent: Event, oldEvent: Event, user?: Person) {
    let result: Result = {isSuccess: true, type: "CopyEvent", result: null, message: "Successfully copied the stabling settings"};

    let oldStallTypes: EventStallType[] = [];
    let newStallTypes: EventStallType[] = [];

    const oldStallTypesResult = await getEventStallTypesByEventId(oldEvent.id);
    if (oldStallTypesResult.isSuccess) {
        oldStallTypes = oldStallTypesResult.result;
    }

    const newStallTypesResult = await getEventStallTypesByEventId(newEvent.id);
    if (newStallTypesResult.isSuccess) {
        newStallTypes = newStallTypesResult.result;
    }

    let eventStallTypesToAdd: EventStallType[] = [];
    let eventStallTypesToDelete: EventStallType[] = [];

    for (let i = 0; i < oldStallTypes.length; i++) {
        const stallType = oldStallTypes[i];
        const findResult = newStallTypes.findIndex(r => r.name === stallType.name && r.pricePerStall === stallType.pricePerStall && r.type === stallType.type);
        if (findResult === -1) {
            eventStallTypesToAdd.push(stallType);
        }
    }

    for (let i = 0; i < newStallTypes.length; i++) {
        const stallType = newStallTypes[i];
        const findResult = oldStallTypes.findIndex(r => r.name === stallType.name && r.pricePerStall === stallType.pricePerStall && r.type === stallType.type);
        if (findResult === -1) {
            eventStallTypesToDelete.push(stallType);
        }
    }

    for (let i = 0; i < eventStallTypesToAdd.length; i++) {
        const stallType = eventStallTypesToAdd[i];
        const eventStallTypeInput: CreateEventStallTypeInput = {
            eventId: newEvent.id,
            organizationId: newEvent.organizationId,
            organizationStallTypeId: stallType.organizationStallTypeId,
            name: stallType.name,
            description: stallType.description,
            type: stallType.type,
            pricePerStall: stallType.pricePerStall,
            taxPerStall: stallType.taxPerStall
        };
        const createResult = await createEventStallType(eventStallTypeInput);
        if (!createResult.isSuccess) {
            result = {isSuccess: false, type: "CopyEvent", result: createResult.result, message: "Successfully copied the event"};
        }
    }

    for (let i = 0; i < eventStallTypesToDelete.length; i++) {
        const stallType = eventStallTypesToDelete[i];
        const eventStallTypeInput: DeleteEventResultInput = {
            id: stallType.id,
        };
        const deleteResult = await deleteEventStallType(eventStallTypeInput);
        if (!deleteResult.isSuccess) {
            result = {isSuccess: false, type: "CopyEvent", result: deleteResult.result, message: "Successfully copied the event"};
        }
    }

    return result;
}

async function copyEventRulesSettings(newEvent: Event, oldEvent: Event, user?: Person) {
    let result: Result = {isSuccess: true, type: "CopyEvent", result: null, message: "Successfully copied the event rules settings"};

    let oldRules: EventRules[] = [];
    let newRules: EventRules[] = [];

    const oldRulesResult = await getEventRulesByEventId(oldEvent.id);
    if (oldRulesResult.isSuccess) {
        oldRules = oldRulesResult.result;
    }

    const newRulesResult = await getEventRulesByEventId(newEvent.id);
    if (newRulesResult.isSuccess) {
        newRules = newRulesResult.result;
    }

    let eventRulesToAdd: EventRules[] = [];
    let eventRulesToDelete: EventRules[] = [];

    for (let i = 0; i < oldRules.length; i++) {
        const rule = oldRules[i];
        const findResult = newRules.findIndex(r => r.title === rule.title && r.text === rule.text && r.requiresAgreementPerEntry === rule.requiresAgreementPerEntry);
        if (findResult === -1) {
            eventRulesToAdd.push(rule);
        }
    }

    for (let i = 0; i < newRules.length; i++) {
        const rule = newRules[i];
        const findResult = oldRules.findIndex(r => r.title === rule.title && r.text === rule.text && r.requiresAgreementPerEntry === rule.requiresAgreementPerEntry);
        if (findResult === -1) {
            eventRulesToDelete.push(rule);
        }
    }

    for (let i = 0; i < eventRulesToAdd.length; i++) {
        const rule = eventRulesToAdd[i];
        const eventRuleInput: CreateEventRulesInput = {
            title: rule.title,
            text: rule.text,
            createdBy: rule.createdBy,
            requiresAgreementPerEntry: rule.requiresAgreementPerEntry,
            eventId: newEvent.id,
            lastUpdatedBy: user?.id || "",
        };
        const createResult = await createEventRules(eventRuleInput);
        if (!createResult.isSuccess) {
            result = {isSuccess: false, type: "CopyEvent", result: createResult.result, message: "Successfully copied the event"};
        }
    }

    for (let i = 0; i < eventRulesToDelete.length; i++) {
        const rule = eventRulesToDelete[i];
        const eventRuleInput: DeleteEventResultInput = {
            id: rule.id,
        };
        const deleteResult = await deleteEventRules(eventRuleInput);
        if (!deleteResult.isSuccess) {
            result = {isSuccess: false, type: "CopyEvent", result: deleteResult.result, message: "Successfully copied the event"};
        }
    }

    return result;
}

async function copyCogginsSettings(newEvent: Event, oldEvent: Event) {
    let result: Result = {isSuccess: true, type: "CopyEvent", result: null, message: "Successfully copied the coggins settings"};

    let entryOptions: EntryOptions = {
        requiresCogginsData: undefined,
        requiresCogginsDocument: undefined
    };

    if (oldEvent.entryOptions?.requiresCogginsData === newEvent.entryOptions?.requiresCogginsData && oldEvent.entryOptions?.requiresCogginsDocument === newEvent.entryOptions?.requiresCogginsDocument) {
        return result;
    } 
    
    if (oldEvent.entryOptions?.requiresCogginsData !== newEvent.entryOptions?.requiresCogginsData) {
        let updatedEntryOptions: EntryOptions = {
            ...entryOptions,
            requiresCogginsData: oldEvent.entryOptions ? oldEvent.entryOptions.requiresCogginsData : undefined
        };
        entryOptions = updatedEntryOptions;
    }

    if (oldEvent.entryOptions?.requiresCogginsDocument !== newEvent.entryOptions?.requiresCogginsDocument) {
        let updatedEntryOptions: EntryOptions = {
            ...entryOptions,
            requiresCogginsDocument: oldEvent.entryOptions ? oldEvent.entryOptions.requiresCogginsDocument : undefined
        };
        entryOptions = updatedEntryOptions;
    }

    const input: UpdateEventInput = {
        id: newEvent.id,
        entryOptions
    };

    const updatedEntryResult = await updateEvent(input);
    if (updatedEntryResult.isSuccess) {
        return result;
    } else {
        result = {isSuccess: false, type: "CopyEvent", result: updatedEntryResult.result, message: "Could not copy the coggins settings"};
        return result;
    }    
}