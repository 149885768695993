import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ViewEventNavbar from "../../../components/Navbars/ViewEventNavbar";
import {Event, EventClass, EventClassEntry, EventDivision, EventResult} from "../../../models";
import ErrorAlert from "../../../components/Errors/ErrorAlert";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../utilities/events/Event";
import { close, ribbon } from "ionicons/icons";
import DivisionClassNavbar from "../../../components/Navbars/DivisionClassNavbar";
import EventClassResultsTable from "../../../components/EventClass/EventClassResultsTable";
import EventDivisionResultsTable from "../../../components/EventDivision/EventDivisionResultsTable";
import EventDivisionResultsForm from "../../../components/EventDivision/EventDivisionResultsForm";
import OrganizationMainPointTable from "../../../components/PointTracking/OrganizationMainPointTable";
import { getEventResultsByEventClassId } from "../../../utilities/eventResult/EventResult";
import { getEventClassEntriesByEventClassId } from "../../../utilities/eventClassEntry/EventClassEntry";
import { Table } from "reactstrap";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventViewResultsPage: React.FC<EventPageProps> = ({match}) => {

    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [eventClassEntries, setEventClassEntries] = useState<EventClassEntry[] | undefined>();
    const [eventClassResults, setEventClassResults] = useState<EventResult[] | undefined>();
    const [tab, setTab] = useState("classes");
    const [selectedEventClass, setSelectedEventClass] = useState<EventClass | null | undefined>();
    const [selectedEventDivision, setSelectedEventDivision] = useState<EventDivision | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [showDivisionModal, setShowDivisionModal] = useState(false);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    async function getEvent() {
        const queryResult = await getEventById(match.params.id);
        if (queryResult.isSuccess) {
            setCurrentEvent(queryResult.result);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }
    
    useEffect(() => {
        getEvent();
    }, [match, match.params.id]);

    async function getEventClassEntries(eventClassId: string) {
        const queryResult = await getEventClassEntriesByEventClassId(eventClassId);
        if (queryResult.isSuccess) {
            const eventClassEntries = queryResult.result;
            setEventClassEntries(eventClassEntries);
            return eventClassEntries;
        } else {
            setError("Sorry, a problem occurred. No event class entries found. Please go back and try again.");
        }
    }

    async function getEventClassResults(eventClassId: string) {
        const queryResult = await getEventResultsByEventClassId(eventClassId);
        if (queryResult.isSuccess) {
            const eventResults: EventResult[] = queryResult.result;
            const sortedEventResults = eventResults.sort((a, b) => (!a.place ? 9999 : a.place) - (!b.place ? 9999 : b.place));
            setEventClassResults(sortedEventResults || eventResults);
            return sortedEventResults || eventResults;
        } else {
            setError("Sorry, a problem occurred. No class results found. Please go back and try again.");
        }
    }

    const getData = async (eventClassId: string) => {
        const eventClassEntries: EventClassEntry[] = await getEventClassEntries(eventClassId);
        const eventResults: EventResult[] | undefined = await getEventClassResults(eventClassId);
        setIsLoading(false);
    }

    const onSelectTab = (selectedTab: string) => {
        setTab(selectedTab);
    }

    const onSelectClass = async (c: EventClass) => {
        setSelectedEventClass(c);
        if (c) {
            await getData(c.id);
            setShowModal(true);
        }
    }

    const onSelectDivision = async (d: EventDivision) => {
        setSelectedEventDivision(d);
        if (d) {
            setShowDivisionModal(true);
        }
    }
    
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent ? (currentEvent.nickname ? currentEvent.nickname : currentEvent.name) : "Event"} />
                {currentEvent && (
                    <>
                        <ViewEventNavbar active="about" event={currentEvent} />
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonRow id="alerts">
                            <IonCol sizeSm="12" sizeMd="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonItem lines="none">
                                            <IonIcon icon={ribbon} slot="start"/>
                                            <IonLabel> Results</IonLabel>
                                        </IonItem>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <DivisionClassNavbar active={tab} includeYearly={currentEvent.organizationId === "ba239ae1-f8ee-4bb7-bdf1-8a089f49746e"} onSelect={onSelectTab}/>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        {tab === "classes" &&
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="12">
                                    {currentEvent && (
                                        <EventClassResultsTable onSelect={(c: EventClass) => onSelectClass(c)} event={currentEvent} />
                                    )}
                                </IonCol>
                            </IonRow>
                        }
                        {tab === "divisions" && 
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="12">
                                    {currentEvent && (
                                        <EventDivisionResultsTable onSelect={(d: EventDivision) => onSelectDivision(d)} event={currentEvent} />
                                    )}
                                </IonCol>
                            </IonRow>
                        }
                        {tab === "yearly" && 
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="12">
                                    {(currentEvent && currentEvent.organizationId) && (
                                        <OrganizationMainPointTable organizationId={currentEvent.organizationId} />
                                    )}
                                </IonCol>
                            </IonRow>
                        }
                    </>
                )}
                <IonModal backdropDismiss={false} isOpen={showModal} id="eventClassModal">
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                            Class Results
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowModal(false)}
                            >
                                <p id="closeEventClassModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    <IonIcon icon={close} />
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonContent className="ion-padding">
                        {(eventClassResults && eventClassResults.length > 0) && (
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Place</th>
                                        <th>Back #</th>
                                        <th>Horse Name</th>
                                        <th>Rider Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {eventClassResults.map((eventClassResult, i) => (
                                        <tr key={i}>
                                            <td>
                                                <p className="ion-text-wrap">{eventClassResult.place}</p>
                                            </td>
                                            <td>
                                                <p className="ion-text-wrap">{eventClassResult.entry.number}</p>
                                            </td>
                                            <td>
                                                <p className="ion-text-wrap">{eventClassResult.entry.horseName}</p>
                                            </td>
                                            <td>
                                                <p className="ion-text-wrap">{eventClassResult.entry.riderName}</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </IonContent>
                </IonModal>
                <IonModal backdropDismiss={false} isOpen={showDivisionModal} id="eventClassModal">
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                            Division Results
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowDivisionModal(false)}
                            >
                                <p id="closeEventClassModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    <IonIcon icon={close} />
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonContent className="ion-padding">
                        {currentEvent && selectedEventDivision && (
                            <EventDivisionResultsForm event={currentEvent} eventDivision={selectedEventDivision} onSubmit={() => setShowDivisionModal(false)} />
                        )}
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default EventViewResultsPage;