// export const listEventAuditorsWithFilters = /* GraphQL */ `
//   query ListEventAuditors(
//     $filter: ModelEventAuditorFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     listEventAuditors(filter: $filter, limit: $limit, nextToken: $nextToken) {
//       items {
//         id
//         personId
//         eventId
//         auditorId
//         auditor {
//           id
//           personId
//           name
//           phoneNumber
//           email
//           status
//           statusNote
//           createdBy
//           lastUpdatedBy
//           createdOn
//           updatedOn
//         }
//         filters {
//             items {
//                 id
//                 personId
//                 eventId
//                 eventAuditorId
//                 filterId
//                 filter {
//                   id
//                   name
//                 }
//                 options
//                 createdBy
//                 lastUpdatedBy
//                 createdOn
//                 updatedOn
//             }
//         }
//         name
//         phoneNumber
//         status
//         createdBy
//         lastUpdatedBy
//         createdOn
//         updatedOn
//       }
//       nextToken
//     }
//   }
// `;
export const listEventAuditorsWithFilters = /* GraphQL */ `
  query EventAuditorsByEvent(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventAuditorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventAuditorsByEvent(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personId
        eventId
        auditorId
        filters {
          items {
              id
              personId
              eventId
              eventAuditorId
              filterId
              filter {
                id
                name
              }
              options
              createdBy
              lastUpdatedBy
              createdOn
              updatedOn
          }
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      nextToken
    }
  }
`;

export const eventAuditorByPhoneNumberByEventWithFilters = /* GraphQL */ `
  query EventAuditorByPhoneNumberByEvent(
    $phoneNumber: String!
    $eventId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventAuditorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventAuditorByPhoneNumberByEvent(
      phoneNumber: $phoneNumber
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          items {
              id
              personId
              eventId
              eventAuditorId
              filterId
              filter {
                id
                name
              }
              options
              createdBy
              lastUpdatedBy
              createdOn
              updatedOn
          }
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;