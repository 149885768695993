import { Barn, Event, EventClassEntry, EventEntry, ScheduleItem, Trainer } from "../../models";
import { addFooters } from "./ReportFooter";
import moment from "moment";
import { getEventEntriesByEventId } from "../eventEntry/EventEntry";
import { getEventClassEntriesByEventIdEntryId } from "../eventClassEntry/EventClassEntry";
import { getScheduleItemsByEventId } from "../scheduleItem/ScheduleItem";
import { openTabForPrinting } from "./ReportUtils";
const { jsPDF } = require("jspdf");
require('jspdf-autotable');

function createEntryHeaderRow(doc: any, yCoord: number, eventEntry: EventEntry, isAllowedTwoRiders?: boolean, classNumbers?: string[]) {
    const entryNumber = eventEntry.number ? eventEntry.number.toString() : "";
    const horseName = eventEntry.horse?.name || eventEntry.horseName || "";
    const riderName = eventEntry.rider?.name || eventEntry.riderName || "";
    const secondRiderName = eventEntry.secondRider?.name || eventEntry.secondRiderName || "";
    doc.setFontSize(12);
    doc.text(`${entryNumber ? "#" + entryNumber + " - " : ""}Horse: ${horseName} - Rider: ${riderName} ${isAllowedTwoRiders ? " - Rider #2: " + secondRiderName : ""}`, 14, yCoord + 4);
    doc.setFontSize(10);
    doc.text(`All Classes for Entry: ${(classNumbers && classNumbers.length>0) ? classNumbers.toString() : "No classes found."}`, 14, yCoord + 10);
    doc.setFontSize(12);
}

function createEntryAddScratchRow(doc: any, yCoord: number, isLandscape?: boolean) {
    doc.setFontSize(10);
    doc.setTextColor(0, 150, 0);
    if (isLandscape) {
        doc.text(`Add Classes - Rider Name: _______________________________________ Classes: _________  _________  _________  _________  _________  _________`, 14, yCoord);
    } else {
        doc.text(`Add Classes - Rider Name: _____________________ Classes: _______  _______  _______  _______  _______  _______`, 14, yCoord);
    }
    doc.setTextColor(150, 0, 0);
    doc.text(`Scratch Classes - _________  _________  _________  _________  _________  _________`, 14, yCoord + 6);
    doc.setTextColor(0, 0, 0);
}

export async function generateEntriesAddScratchReport(event: Event, callbackFunction: Function, isDownload?: boolean, isLandscape?: boolean, isAllowedTwoRiders?: boolean, trainer?: (Trainer | null | undefined), barn?: (Barn | null | undefined), includeNameOfClass?: boolean, includeDayOfClass?: boolean) {
    // initialize jsPDF
    const doc = new jsPDF(isLandscape ? "l" : "p");

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Entries Add/Scratch Sheets Report ${trainer ? ("for " + trainer.name) : (barn ? ("for " + barn.name) : "")}`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    const tableColumns = [
        "Entry #",
        "Horse Name",
        "Rider Name",
        "Class #",
        "Class Name",
        (includeDayOfClass ? "Day" : ""),
        "Status",
        "Last Updated"
    ];

    let yCoord = 36;

    let entryList: EventEntry[] = [];

    // Get all entries for the current event and sort them as needed
    const entryQueryResult = await getEventEntriesByEventId(event.id);
    if (entryQueryResult.isSuccess) {
        const allEventEntries: EventEntry[] = entryQueryResult.result;
        if (allEventEntries && allEventEntries.length > 0) {
            entryList = allEventEntries.sort((a,b) => (a.trainerName || "").localeCompare((b.trainerName || "")));
            if (trainer) entryList = entryList.filter(e => e.trainerId === trainer.id || e.trainerName?.toLowerCase() === trainer.name.toLowerCase() || e.trainer?.name?.toLowerCase() === trainer.name.toLowerCase());
            else if (barn) entryList = entryList.filter(e => e.barnId === barn.id || e.barnName?.toLowerCase() === barn.name.toLowerCase() || e.barn?.name?.toLowerCase() === barn.name.toLowerCase());
        }
    }

    // Get all schedule items for the current event
    let allScheduleItems: ScheduleItem[] = [];
    if (includeDayOfClass) {
        const scheduleQueryResult = await getScheduleItemsByEventId(event.id);
        if (scheduleQueryResult.isSuccess) {
            allScheduleItems = scheduleQueryResult.result;
        }
    }

    let currentTrainerBarnHeader = "";

    if (entryList && entryList.length > 0) {
        for (let i = 0; i < entryList.length; i++) {
            const currentEntry = entryList[i];

            // Check if a new page is needed in order to display the next entry's info
            if ((yCoord + 30) > doc.internal.pageSize.height) {
                doc.addPage();
                yCoord = 15;
            }

            const callbackMessage = i+1 + " of " + entryList.length;
            if (callbackFunction) callbackFunction(callbackMessage);

            if (currentEntry) {
                // Display the current Trainer or Barn name for this set of entries
                if (currentTrainerBarnHeader === "" || 
                    (barn && currentTrainerBarnHeader !== (currentEntry.barn?.name || currentEntry.barnName)) ||
                    (!barn && currentTrainerBarnHeader !== (currentEntry.trainer?.name || currentEntry.trainerName))
                ) {
                    // If this is not the first set of tables, break to a new page
                    if (currentTrainerBarnHeader !== "") {
                        doc.addPage();
                        yCoord = 15;
                    } 

                    if (barn) currentTrainerBarnHeader = currentEntry.barn?.name || currentEntry.barnName || "";
                    else currentTrainerBarnHeader = currentEntry.trainer?.name || currentEntry.trainerName || "";

                    doc.setFontSize(12);
                    doc.text(`${currentTrainerBarnHeader}`, 44, yCoord);
                    yCoord = yCoord + 6;
                }

                // Get the class data for the entry
                let eventEntryClasses: EventClassEntry[] = [];
                const classQueryResult = await getEventClassEntriesByEventIdEntryId(currentEntry.eventId, currentEntry.id);
                if (classQueryResult.isSuccess) {
                    const entryClassList: EventClassEntry[] = classQueryResult.result;
                    const sortedClassList = entryClassList.sort((a: EventClassEntry, b: EventClassEntry) => (a.eventClass?.number || 0) - (b.eventClass?.number || 0));
                    eventEntryClasses = sortedClassList;
                }

                let tableRows: any[] = [];

                // Format the data depending on the settings
                if (eventEntryClasses && eventEntryClasses.length > 0 && includeNameOfClass) {
                    // There are classes associated with the entry, and the report should display a table of class data
                    for (let j = 0; j < eventEntryClasses.length; j++) {
                        const currentEventEntryClass = eventEntryClasses[j];

                        // Format the status message
                        let statusMessage = "";
                        switch (currentEventEntryClass?.status) {
                            case "accepted":
                                statusMessage = "accepted";
                                break;
                            case "submitted":
                                statusMessage = "submitted\nnot accepted";
                                break;
                            case "scratched":
                                statusMessage = "scratched";
                                break;
                            default:
                                statusMessage = "";
                                break;
                        }

                        // Find the schedule item for the event class so that the day of the class can be listed
                        const foundScheduleItem = allScheduleItems.find(scheduleItem => scheduleItem.eventClass?.id === currentEventEntryClass.eventClass?.id);

                        const classDataRow = [
                            currentEntry.number || "",
                            currentEntry.horse?.name || currentEntry.horseName || "",
                            currentEventEntryClass.rider?.name || "",
                            currentEventEntryClass?.eventClass?.number || 0,
                            currentEventEntryClass?.eventClass?.name || "",
                            (foundScheduleItem && foundScheduleItem.eventDay?.awsdate) ? moment(foundScheduleItem.eventDay?.awsdate).format("ddd") : "",
                            statusMessage,
                            currentEventEntryClass?.updatedOn ? moment(currentEventEntryClass?.updatedOn).format("M/DD h:mm a") : ""
                        ];

                        tableRows.push(classDataRow);
                    }

                    doc.autoTable(tableColumns, tableRows, { 
                        theme: "grid",
                        headStyles: {fillColor: "#73a4d3"},
                        startY: yCoord,
                        rowPageBreak: "avoid",
                        pageBreak: "avoid",
                        bodyStyles: {
                            fontSize: 10
                        },   
                    });
    
                    yCoord = doc.lastAutoTable.finalY + 6;
                } else if ((!eventEntryClasses || eventEntryClasses.length === 0) && includeNameOfClass) {
                    // There are no classes associated with the entry, and the report should display a table 
                    const classDataRow = [
                        currentEntry.number || "",
                        currentEntry.horse?.name || currentEntry.horseName || "",
                        currentEntry.rider?.name || "",
                        "",
                        "No classes were found for this entry.",
                        "",
                        "",
                        currentEntry?.updatedOn ? moment(currentEntry?.updatedOn).format("M/DD h:mm a") : ""
                    ];

                    tableRows.push(classDataRow);

                    doc.autoTable(tableColumns, tableRows, { 
                        theme: "grid",
                        headStyles: {fillColor: "#73a4d3"},
                        startY: yCoord,
                        rowPageBreak: "avoid",
                        pageBreak: "avoid",
                        bodyStyles: {
                            fontSize: 10
                        },   
                    });
    
                    yCoord = doc.lastAutoTable.finalY + 6;
                } else if (eventEntryClasses && eventEntryClasses.length > 0 && !includeNameOfClass) {
                    // There are classes associated with the entry, and the report should just display a list of classes
                    const classNumbers: string [] = [];
                    eventEntryClasses.forEach(eventEntryClass => {
                        if (eventEntryClass.eventClass?.number) classNumbers.push(eventEntryClass.eventClass?.number.toString());
                    })
                    createEntryHeaderRow(doc, yCoord, currentEntry, isAllowedTwoRiders, classNumbers);
                    yCoord = yCoord + 16;
                } else {
                    // There are no classes associated with this entry
                    createEntryHeaderRow(doc, yCoord, currentEntry, isAllowedTwoRiders, []);
                    yCoord = yCoord + 16;
                }
                
                createEntryAddScratchRow(doc, yCoord, isLandscape);

                yCoord = yCoord + 10;
            }
        }
    }    

    // page footer
    addFooters(doc, true);

    // Either Download to Computer or Print Only
    if (isDownload) {
        const fileName = `entries_add_scratch_sheets` + ((barn || trainer) ? (barn ? "_barn" : "_trainer") : "") + `.pdf`;
        doc.save(fileName);
    } else {
        openTabForPrinting(doc);
    }
}