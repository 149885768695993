import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateBeddingRequestInput, CreateBeddingRequestMutation, DeleteBeddingRequestInput, DeleteBeddingRequestMutation, GetBeddingRequestQuery, ListBeddingRequestsQuery, UpdateBeddingRequestInput, UpdateBeddingRequestMutation } from "../../API";
import moment from "moment";
import { BeddingRequest } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: stablingRequest. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created stablingRequest.
*/
export async function createBeddingRequest(input: CreateBeddingRequestInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "BeddingRequest", "No input", "Create BeddingRequest received no input.");
   try {
        const fullInput: CreateBeddingRequestInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createBeddingRequest, { input: fullInput }))) as GraphQLResult<CreateBeddingRequestMutation>;
       else result = (await API.graphql({
           query: mutations.createBeddingRequest,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateBeddingRequestMutation>;
       const stablingRequest = result.data?.createBeddingRequest;
       return formatResult(true, "BeddingRequest", stablingRequest, "Successfully created the stablingRequest.");
   } catch (error: any) {
       return formatResult(false, "BeddingRequest", error, "Error creating record in the database for type: stablingRequest");
   }
}

/**
* Handle updating a new record in the database for type: stablingRequest. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated stablingRequest.
*/
export async function updateBeddingRequest(input: UpdateBeddingRequestInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "BeddingRequest", "No input", "Update BeddingRequest received no input.");
   try {
        const fullInput: UpdateBeddingRequestInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateBeddingRequest, { input: fullInput }))) as GraphQLResult<UpdateBeddingRequestMutation>;
       else result = (await API.graphql({
           query: mutations.updateBeddingRequest,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateBeddingRequestMutation>;
       const stablingRequest = result.data?.updateBeddingRequest;
       return formatResult(true, "BeddingRequest", stablingRequest, "Successfully updated the stablingRequest.");
   } catch (error: any) {
       return formatResult(false, "BeddingRequest", error, "Error updating record in the database for type: stablingRequest");
   }
}

/**
* Handle deleting a new record in the database for type: stablingRequest. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted stablingRequest.
*/
export async function deleteBeddingRequest(input: DeleteBeddingRequestInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "BeddingRequest", "No input", "Delete BeddingRequest received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteBeddingRequest, { input: input }))) as GraphQLResult<DeleteBeddingRequestMutation>;
       else result = (await API.graphql({
           query: mutations.deleteBeddingRequest,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteBeddingRequestMutation>;
       const stablingRequest = result.data?.deleteBeddingRequest;
       return formatResult(true, "BeddingRequest", stablingRequest, "Successfully deleted the stablingRequest.");
   } catch (error: any) {
       return formatResult(false, "BeddingRequest", error, "Error deleting record in the database for type: stablingRequest");
   }
}

/**
* Get all records in the database for type: stablingRequest. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getAllBeddingRequests(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listBeddingRequests,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBeddingRequestsQuery>;
        
        let items = result.data?.listBeddingRequests?.items as BeddingRequest[];
        let nextToken = result.data?.listBeddingRequests?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBeddingRequests,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBeddingRequestsQuery>;

            const nextItems = nextResult.data?.listBeddingRequests?.items as BeddingRequest[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBeddingRequests?.nextToken;
        }

        return formatResult(true, "BeddingRequest", items, "Successfully got the BeddingRequests.");
    } catch (error: any) {
        return formatResult(false, "BeddingRequest", error, "Error reading record in the database for type: stablingRequests");
    }
}

/**
* Read a specific record in the database for type: stablingRequest. 
* 
* @param {string}  id                  The stablingRequest id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getBeddingRequestById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getBeddingRequest,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetBeddingRequestQuery>;
       const stablingRequest = result.data?.getBeddingRequest;
       return formatResult(true, "BeddingRequest", stablingRequest, "Successfully got the stablingRequest.");
   } catch (error: any) {
       return formatResult(false, "BeddingRequest", error, "Error reading record in the database for type: stablingRequest");
   }
}

/**
* Get all records in the database that match the given criteria for type: stablingRequest. 
* 
* @param {string}  personId            The person id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getBeddingRequestsByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listBeddingRequests,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBeddingRequestsQuery>;
        
        let items = result.data?.listBeddingRequests?.items as BeddingRequest[];
        let nextToken = result.data?.listBeddingRequests?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBeddingRequests,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBeddingRequestsQuery>;

            const nextItems = nextResult.data?.listBeddingRequests?.items as BeddingRequest[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBeddingRequests?.nextToken;
        }

        return formatResult(true, "BeddingRequest", items, "Successfully got the BeddingRequests.");
    } catch (error: any) {
        return formatResult(false, "BeddingRequest", error, "Error reading record in the database for type: stablingRequests");
    }
}

/**
* Get all records in the database that match the given criteria for type: stablingRequest. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getBeddingRequestsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listBeddingRequests,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBeddingRequestsQuery>;
        
        let items = result.data?.listBeddingRequests?.items as BeddingRequest[];
        let nextToken = result.data?.listBeddingRequests?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBeddingRequests,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBeddingRequestsQuery>;

            const nextItems = nextResult.data?.listBeddingRequests?.items as BeddingRequest[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBeddingRequests?.nextToken;
        }

        return formatResult(true, "BeddingRequest", items, "Successfully got the BeddingRequests.");
    } catch (error: any) {
        return formatResult(false, "BeddingRequest", error, "Error reading record in the database for type: stablingRequests");
    }
}

/**
* Get all records in the database that match the given criteria for type: stablingRequest. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getAcceptedBeddingRequestsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}, status: {eq: "accepted"}};
        const result = (await API.graphql({
            query: queries.listBeddingRequests,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBeddingRequestsQuery>;
        
        let items = result.data?.listBeddingRequests?.items as BeddingRequest[];
        let nextToken = result.data?.listBeddingRequests?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBeddingRequests,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBeddingRequestsQuery>;

            const nextItems = nextResult.data?.listBeddingRequests?.items as BeddingRequest[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBeddingRequests?.nextToken;
        }

        return formatResult(true, "BeddingRequest", items, "Successfully got the BeddingRequests.");
    } catch (error: any) {
        return formatResult(false, "BeddingRequest", error, "Error reading record in the database for type: stablingRequests");
    }
}

/**
* Get all records in the database that match the given criteria for type: stablingRequest. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getNotAcceptedBeddingRequestsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}, status: {ne: "accepted"}};
        const result = (await API.graphql({
            query: queries.listBeddingRequests,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBeddingRequestsQuery>;
        
        let items = result.data?.listBeddingRequests?.items as BeddingRequest[];
        let nextToken = result.data?.listBeddingRequests?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBeddingRequests,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBeddingRequestsQuery>;

            const nextItems = nextResult.data?.listBeddingRequests?.items as BeddingRequest[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBeddingRequests?.nextToken;
        }

        return formatResult(true, "BeddingRequest", items, "Successfully got the BeddingRequests.");
    } catch (error: any) {
        return formatResult(false, "BeddingRequest", error, "Error reading record in the database for type: stablingRequests");
    }
}


/**
* Get all records in the database that match the given criteria for type: stablingRequest. 
* 
* @param {string}  personId            The person id 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getBeddingRequestsByPersonIdEventId(personId: string, eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}, eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listBeddingRequests,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBeddingRequestsQuery>;
        
        let items = result.data?.listBeddingRequests?.items as BeddingRequest[];
        let nextToken = result.data?.listBeddingRequests?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBeddingRequests,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBeddingRequestsQuery>;

            const nextItems = nextResult.data?.listBeddingRequests?.items as BeddingRequest[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBeddingRequests?.nextToken;
        }

        return formatResult(true, "BeddingRequest", items, "Successfully got the BeddingRequests.");
    } catch (error: any) {
        return formatResult(false, "BeddingRequest", error, "Error reading record in the database for type: stablingRequests");
    }
}

/**
* Get all records in the database that match the given criteria for type: stablingRequest. 
* 
* @param {string}  entryId             The entry id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getBeddingRequestsByEntryId(entryId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.beddingRequestsByEntryId,
            variables: {
                limit: 1000,
                submittedEntry: entryId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.beddingRequestsByEntryId?.items as BeddingRequest[];
        let nextToken = result.data?.beddingRequestsByEntryId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.beddingRequestsByEntryId,
                variables: {
                    limit: 100,
                    submittedEntry: entryId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.beddingRequestsByEntryId?.items as BeddingRequest[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.beddingRequestsByEntryId?.nextToken;
        }

        return formatResult(true, "BeddingRequest", items, "Successfully got the BeddingRequests.");
    } catch (error: any) {
        return formatResult(false, "BeddingRequest", error, "Error reading record in the database for type: stablingRequests");
    }
}