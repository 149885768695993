import { Barn, Trainer, Person } from "../../models";
import { getTrainerByPersonId, getTrainersByBarnId, getTrainersByCreatedBy } from "./Trainer";

export async function getTrainersByUserByBarn(user: Person, barn?: (Barn | null)) {
    let trainers: Trainer[] = [];

    const queryResultByPerson = await getTrainerByPersonId(user.id);
    if (queryResultByPerson.isSuccess) {
        trainers = trainers.concat(queryResultByPerson.result);
    }
    if (barn) {
        const queryResultByBarn = await getTrainersByBarnId(barn.id);
        if (queryResultByBarn.isSuccess) {
            const trainerArray = queryResultByBarn.result;
            if (trainerArray) {
                trainerArray.forEach((trainer: Trainer) => {
                    const index = trainers.findIndex(r => r.id === trainer.id);
                    if (index === -1) trainers.push(trainer);
                });
            }
        }
    }

    const queryResultByCreatedPerson = await getTrainersByCreatedBy(user.id);
    if (queryResultByCreatedPerson.isSuccess) {
        const trainerArray = queryResultByCreatedPerson.result;
        if (trainerArray) {
            trainerArray.forEach((trainer: Trainer) => {
                const index = trainers.findIndex(r => r.id === trainer.id);
                if (index === -1) trainers.push(trainer);
            });
        }
    }
    
    const sorted = trainers.sort((a,b) => a.name.localeCompare(b.name));

    return sorted || trainers;
}