import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { UpdateEventEntryInput } from "../../../API";
import { EventEntry } from "../../../models";
import { updateEventEntry } from "../../../utilities/eventEntry/EventEntry";
import SuccessBanner from "../../Banners/SuccessBanner";
import ErrorAlert from "../../Errors/ErrorAlert";
import Spinner from "../../Spinners/Spinner";

interface _Props {
    entry: EventEntry
}

const EntryNumberForm: React.FC<_Props> = ({entry}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null | undefined>();
    const [success, setSuccess] = useState<string | null | undefined>();
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [number, setNumber] = useState<number | null | undefined>(entry.number || 0);

    useEffect(() => {
        setCurrentEntry(entry);
        setNumber(entry.number);
    }, [entry]);

    const verifyForm = () => {
        if (!number) {
            setError("Please include a number for this entry.");
            return false;
        } 
        setError("");
        return true;
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid && currentEntry) {
            const eventEntryInput: UpdateEventEntryInput = {
                id: currentEntry.id,
                number: number || undefined
            };
            const updateResult = await updateEventEntry(eventEntryInput);
            if (updateResult.isSuccess) {
                setSuccess("Successfully saved the entry number");
            } else {
                setError(updateResult.message);
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            {isLoading ? 
                <Spinner />
                :
                <form>
                    {error && <ErrorAlert width="12" error={error} />}
                    {success && <SuccessBanner width="12" success={success} />}
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Entry Number (back number)</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={number}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setNumber(parseInt(e.detail.value!))
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="6" className="ion-text-center">
                            <IonButton color="tertiary" onClick={handleSubmit}>
                                Save
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </form>
            }
        </>
    );
};

export default EntryNumberForm;