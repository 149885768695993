import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React from "react";
import { Horse } from "../../../../models";
import RiderEditHorsesTable from "../../../../components/Horse/RiderEditHorsesTable";

const RiderHorsesPage: React.FC = () => {

    const handleSelectHorse = (horse: Horse) => {

    }
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Horses" />
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="8">
                        <RiderEditHorsesTable onSelect={handleSelectHorse} />
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default RiderHorsesPage;