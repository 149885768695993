
import React, { useEffect, useState } from "react";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { Organization, OrganizationAuditor } from "../../../../../models";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonPage,
    IonRow,
    IonText,
} from "@ionic/react";
import { useContext } from "react";
import {Event, EventAuditor} from "../../../../../models";
import { useHistory } from "react-router";
import BuyCreditsForm from "../../../../../components/Event/Alerts/BuyCreditsForm";
import ViewOrganizationBlocks from "../../../../../components/Event/Alerts/ViewOrganizationBlocks";
import EventAlertsNavbar from "../../../../../components/Navbars/EventAlertsNavbar";
import Spinner from "../../../../../components/Spinners/Spinner";
import DownloadLink from "../../../../../components/PDF/DownloadLink";
import { generateEventSubscriberReport, generateOrganizationSubscriberReport } from "../../../../../utilities/reports/AlertsSubscriberReport";
import { generateEventAlertReport, generateOrganizationAlertReport } from "../../../../../utilities/reports/AlertsReports";
import { getCheckoutSessionInfo } from "../../../../../utilities/stripe/Checkout";
import { creditBlockPricingStructure } from "../../../../../data/content/CreditBlockPricing";
import { CreateBlockInput, BlockStatus } from "../../../../../API";
import { PersonContext } from "../../../../../context/PersonContext";
import { createBlock } from "../../../../../utilities/block/Block";
import EditOrganizationAuditorsFullTable from "../../../../../components/Event/Alerts/EditOrganizationAuditorsFullTable";
import OrganizationAuditorModal from "../../../../../components/OrganizationAuditor/OrganizationAuditorModal";
import EditEventAuditorsTable from "../../../../../components/Event/Alerts/EditEventAuditorsTable";
import InformationBanner from "../../../../../components/Banners/InformationBanner";
import EventFiltersForm from "../../../../../components/Event/Alerts/AlertFiltersForm";
import { chevronDown, chevronUp } from "ionicons/icons";
import SendOrgAlertForm from "../../../../../components/Event/Alerts/SendOrgAlertForm";
import ViewEventAlertsTable from "../../../../../components/Event/Alerts/ViewEventAlertsTable";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
    session_id?: string;
}> {}

const EventOrganizationTextAlertsPage: React.FC<OrganizationPageProps> = ({match}) => {
    const history = useHistory();
    const user = useContext(PersonContext);

    const [organization, setOrganization] = useState<Organization>();    

    const [event, setEvent] = useState<Event>();
    const [currentOrganizationAuditor, setCurrentOrganizationAuditor] = useState<OrganizationAuditor | undefined>();
    const [hasCreatedCreditBlock, setHasCreatedCreditBlock] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentTab, setCurrentTab] = useState("alerts");

    const [isFiltersCollapsed, setIsFiltersCollapsed] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    const [error, setError] = useState("");

    async function getOrganization() {
        const queryResult = await getOrganizationById(match.params.id);
        if (queryResult.isSuccess) {
            setOrganization(queryResult.result);
            return queryResult.result;
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    useEffect(() => {
        if (match.params.id) {
            getOrganization();
        }
    }, [user, match.params.id]);

    useEffect(() => {
        if (match.params.session_id) {
            if (!hasCreatedCreditBlock) {
                setHasCreatedCreditBlock(true);
                getStripeSessionInfo(match.params.session_id);
            }
        }
    }, [user, match.params.session_id]);

    const getStripeSessionInfo = async (sessionId: string) => {
        const session = await getCheckoutSessionInfo(sessionId);
        if (session.isSuccess) {
            const sessionObject: any = session.result;
            if (sessionObject.payment_status === "paid") {
                // Create the new credit block
                const priceId = sessionObject.metadata.priceId;
                let selectedPricingOption = null;
                for (var i = 0; i < creditBlockPricingStructure.length; i++) {
                    const currentOption = creditBlockPricingStructure[i];
                    if (currentOption.priceId === priceId) {
                        selectedPricingOption = currentOption;
                    }
                }
                if (selectedPricingOption) {
                    const isPromo = sessionObject.total_details.amount_discount > 0;

                    let currentOrganization = organization;
                    currentOrganization = await getOrganization();

                    if (currentOrganization) {
                        const blockInput: CreateBlockInput = {
                            organizationId: currentOrganization.id,
                            personid: user.id,
                            name: selectedPricingOption.useCase,
                            totalCredits: selectedPricingOption.numberCredits,
                            usedCredits: 0,
                            status: BlockStatus.valid,
                            price: selectedPricingOption.cost,
                            amountPaid: selectedPricingOption.cost - (sessionObject.total_details.amount_discount ? sessionObject.total_details.amount_discount/100 : 0),
                            isPromotion: isPromo.toString(),
                            isFree: "false"
                        };
                        const createResult = await createBlock(blockInput);
                        if (createResult.isSuccess) {
                            // Need to get rid of the URL parameter to avoid creating the credit block more than once.
                            const path = "/index/staff/organization/text-alerts" + currentOrganization.id;
                            history.push(path);
                        } else {
                            setError("Sorry a problem occurred. The credit block was not created. Please contact customer service at: hello@ringsidepro.com");
                        }
                    } else {
                        setError("Sorry a problem occurred. The organization id was unknown. Please contact customer service at: hello@ringsidepro.com");
                    }
                } else {
                    setError("Sorry a problem occurred. The tier was unknown. Please contact customer service at: hello@ringsidepro.com");
                }
            }
        }
    }

    const handleDownloadSubscriberReport = async () => {
        setError("");
        setIsDownloading(true);
        if (event) await generateEventSubscriberReport(event);
        else if (organization) await generateOrganizationSubscriberReport(organization);
        else setError("There is no event.");
        setIsDownloading(false);
    }

    const handleDownloadAlertReport = async () => {
        setError("");
        setIsDownloading(true);
        if (event) await generateEventAlertReport(event);
        else if (organization) await generateOrganizationAlertReport(organization);
        else setError("There is no event.");
        setIsDownloading(false);
    }

    const handleEventAuditorChange = (action: string, eventAuditor: EventAuditor) => {
        setCurrentOrganizationAuditor(eventAuditor);
        setShowModal(false);
    }

    const handleTabSelection = (tab: string) => {
        setCurrentTab(tab);
    }

    const navigateToBulkAdd = () => {
        if (event) {
            const path = "/index/staff/event/" + event.id + "/alerts/subscribers/add";
            history.push(path);
        } else {
            setError("No event found.");
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={organization ? organization.name : "Event Settings"} />
                {organization && (
                    <>
                        <EventAlertsNavbar active={currentTab} onSelect={handleTabSelection} />
                        {error && <ErrorAlert width="12" error={error} />}
                        {currentTab === "alerts" && (
                            <>
                                <IonRow id="eventName" className="ion-align-items-stretch">
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white stretch">
                                            <IonCardTitle>Send Alert</IonCardTitle>
                                            <IonCardContent>
                                                <SendOrgAlertForm organization={organization} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding pb-5 bg-white stretch card-min-height bg-white">
                                            <IonCardTitle>
                                                <IonRow>
                                                    <IonCol size="6">
                                                        Subscribers
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardTitle>
                                            <IonCardSubtitle>
                                                Scroll to see the subscribers.
                                            </IonCardSubtitle>
                                            <IonContent color="white">
                                                <IonCardContent>
                                                    <EditEventAuditorsTable organization={organization} />
                                                </IonCardContent>
                                            </IonContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow id="eventName" className="ion-align-items-stretch">
                                    <IonCol size="12">
                                        <IonCard mode="md" className="ion-padding pb-5 bg-white stretch card-large-height">
                                            <IonCardTitle>Alerts</IonCardTitle>
                                            <IonCardSubtitle>
                                                <IonRow>
                                                    <IonCol size="12">
                                                        <IonText className="ion-text-wrap">
                                                            Scroll to see all alerts.
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size="12">
                                                        <IonText className="ion-text-wrap">
                                                            Click an alert to see details.
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size="12">
                                                        <IonText className="ion-text-wrap" onClick={handleDownloadAlertReport}>
                                                            Click here to download list.
                                                            {isDownloading ?
                                                                <Spinner />
                                                                :
                                                                <DownloadLink />
                                                            }
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardSubtitle>
                                            <IonContent color="white">
                                                <IonCardContent>
                                                    <ViewEventAlertsTable organization={organization} />
                                                </IonCardContent>
                                            </IonContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                        {currentTab === "subscribers" && (
                            <>
                                <IonRow id="subscriber">
                                    <IonCol size="12">
                                        <IonCard mode="md" className="ion-padding bg-white">
                                            <IonCardTitle>
                                                <IonRow onClick={() => setIsFiltersCollapsed(!isFiltersCollapsed)}> 
                                                    <IonCol size="10" className="ion-text-left">
                                                        Filters
                                                    </IonCol>
                                                    <IonCol size="2" className="ion-text-right">
                                                        <IonIcon icon={isFiltersCollapsed ? chevronDown : chevronUp} />
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardTitle>
                                            {!isFiltersCollapsed && (
                                                <IonCardContent>
                                                    <InformationBanner info={"It's helpful to allow subscribers to choose filters on the alerts. This reduces the number of credits you need."} />
                                                    <EventFiltersForm organization={organization} />
                                                </IonCardContent>
                                            )}
                                        </IonCard>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonCard mode="md" className="ion-padding bg-white">
                                            <IonCardTitle>
                                                <IonRow>
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        Subscribers
                                                    </IonCol>
                                                    <IonCol sizeXs="6" sizeMd="2" className="ion-text-right">
                                                        <IonButton color="success" onClick={navigateToBulkAdd}>Bulk Add</IonButton>
                                                    </IonCol>
                                                    <IonCol sizeXs="6" sizeMd="2" className="ion-text-right">
                                                        <IonButton onClick={() => setShowModal(!showModal)}>Add</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardTitle>
                                            <IonCardSubtitle>
                                                <IonRow>
                                                    <IonCol sizeXs="12" sizeMd="3">
                                                        <IonText className="ion-text-wrap">
                                                            Click a subscriber to edit it.
                                                        </IonText>
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="3">
                                                        <IonText className="ion-text-wrap" onClick={handleDownloadSubscriberReport}>
                                                            Click here to download list 
                                                            {isDownloading ?
                                                                <Spinner />
                                                                :
                                                                <DownloadLink />
                                                            }
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardSubtitle>
                                            <OrganizationAuditorModal organization={organization} show={showModal} isAdminView={true} onChange={handleEventAuditorChange}/>
                                            <IonCardContent>
                                                <EditOrganizationAuditorsFullTable organization={organization} organizationAuditor={currentOrganizationAuditor} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                        {currentTab === "credits" && (
                            <>
                                <IonRow id="blocks" className="ion-align-items-stretch">
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white stretch card-min-height">
                                            <IonCardTitle>Your Credit Blocks</IonCardTitle>
                                            <IonCardSubtitle>
                                                Scroll to see your organization's previous purchases.
                                            </IonCardSubtitle>
                                            <IonContent color="white">
                                                <IonCardContent>
                                                    <ViewOrganizationBlocks organizationId={organization.id}/>
                                                </IonCardContent>
                                            </IonContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white stretch card-min-height bg-white">
                                            <IonCardTitle>Buy Credits</IonCardTitle>
                                            <IonCardSubtitle>
                                                Scroll to see the options.
                                            </IonCardSubtitle>
                                            <IonContent color="white">
                                                <IonCardContent className="bg-white">
                                                    <BuyCreditsForm organizationId={organization.id} />
                                                </IonCardContent>
                                            </IonContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationTextAlertsPage;