import { Barn, Rider, Person } from "../../models";
import { getRidersByBarnId, getRidersByCreatedBy } from "./Rider";

export async function getRidersByUserByBarn(user: Person, barn?: Barn) {
    let riders: Rider[] = [];
    if (barn) {
        const queryResultByBarn = await getRidersByBarnId(barn.id);
        if (queryResultByBarn.isSuccess) {
            riders = riders.concat(queryResultByBarn.result);
        }
    }

    const queryResultByCreatedPerson = await getRidersByCreatedBy(user.id);
    if (queryResultByCreatedPerson.isSuccess) {
        const riderArray = queryResultByCreatedPerson.result;
        if (riderArray) {
            riderArray.forEach((rider: Rider) => {
                const index = riders.findIndex(r => r.id === rider.id);
                if (index === -1) riders.push(rider);
            });
        }
    }

    const sorted = riders.sort((a,b) => a.name.localeCompare(b.name));
    
    return sorted || riders;
}