import { 
    IonCol, IonRow
} from "@ionic/react";
import React, { useState } from "react";
import { Event } from "../../../models";
import EventReportCard from "../EventReportCard";
import { generateEventSubscriberReport, generateHorseShowGITNBSubscriberReport } from "../../../utilities/reports/AlertsSubscriberReport";
import { generateEventCreditReport } from "../../../utilities/reports/AlertsCreditReport";
import { generateEventAlertReport } from "../../../utilities/reports/AlertsReports";

interface _Props {
    event: Event
}

const EventReportTextAlert: React.FC<_Props> = ({event}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const generateAlertSubscriberList = async () => {
        if (event) {
            setIsLoading(true); 
            await generateEventSubscriberReport(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateAlertList = async () => {
        if (event) {
            setIsLoading(true); 
            await generateEventAlertReport(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateAlertCreditReport = async () => {
        if (event) {
            setIsLoading(true); 
            await generateEventCreditReport(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateHorseShowSignUpReport = async () => {
        if (event) {
            setIsLoading(true); 
            await generateHorseShowGITNBSubscriberReport();
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const reportLinks = (
        <IonRow>
            <IonCol>
                <IonRow>
                    <IonCol>
                        <p onClick={generateAlertSubscriberList} className="link ion-text-wrap">Subscriber List (CSV)</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={generateAlertList} className="link ion-text-wrap">Alert List (CSV)</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={generateAlertCreditReport} className="link ion-text-wrap">Cost of Credits (PDF)</p>
                    </IonCol>
                </IonRow>
                {event.id === "4ddb21d4-ed5d-4941-ad6a-78e2e6ae64f0" && (
                    <IonRow>
                        <IonCol>
                            <p onClick={generateHorseShowSignUpReport} className="link ion-text-wrap">Horse Show Sign Ups Report (CSV)</p>
                        </IonCol>
                    </IonRow>
                )}
            </IonCol>
        </IonRow>
    )

    return (
        <EventReportCard title="Text Alert Reports" content={reportLinks} isLoading={isLoading} error={error} />
    );
};

export default EventReportTextAlert;
