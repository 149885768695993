import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { Barn, Horse, Invitation, Person } from "../../../../models";
import { getBarnById } from "../../../../utilities/barn/Barn";
import { formatAddress } from "../../../../utilities/address/FormatAddress";
import { CreateInvitationInput, InvitationStatus } from "../../../../API";
import { PersonContext } from "../../../../context/PersonContext";
import { getPersonByPersonId } from "../../../../utilities/person/Person";
import { createInvitation, getInvitationsByBarnIdInvitor } from "../../../../utilities/invitation/Invitation";
import moment from "moment";
import Spinner from "../../../../components/Spinners/Spinner";
import { sendBarnRequestJoinEmail } from "../../../../utilities/invitation/InvitationEmail";
import { getHorsesByBarnId } from "../../../../utilities/horse/Horse";
import { getStrictBarnPeople } from "../../../../utilities/barn/BarnPeople";
import { BarnPerson } from "../../../../interfaces/Person";
import { createBarnPerson } from "../../../../utilities/barnMember/GenerateBarnMember";

interface BarnPageProps extends RouteComponentProps<{
    id: string;
}> {}

const ViewBarnPage: React.FC<BarnPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [barn, setBarn] = useState<Barn>();
    const [barnOwner, setBarnOwner] = useState<Person | null | undefined>();
    const [horses, setHorses] = useState<Horse[] | null | undefined>();
    const [isLoadingHorses, setIsLoadingHorses] = useState(false);
    const [people, setPeople] = useState<BarnPerson[] | null | undefined>();
    const [isLoadingPeople, setIsLoadingPeople] = useState(false);
    const [currentInvitation, setCurrentInvitation] = useState<Invitation | null | undefined>();
    
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    async function getOwner(id: string) {
        const queryResult = await getPersonByPersonId(id);
        if (queryResult.isSuccess) {
            setBarnOwner(queryResult.result);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getHorses() {
        setIsLoadingHorses(true);
        const queryResult = await getHorsesByBarnId(match.params.id);
        if (queryResult.isSuccess) {
            setHorses(queryResult.result);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
        setIsLoadingHorses(false);
    }

    async function getPeople() {
        setIsLoadingPeople(true);
        const queryResult = await getStrictBarnPeople(match.params.id);
        setPeople(queryResult);
        setIsLoadingPeople(false);
    }

    useEffect(() => {
        async function getBarn() {
            const queryResult = await getBarnById(match.params.id);
            if (queryResult.isSuccess) {
                const barn: Barn = queryResult.result;
                setBarn(barn);
                if (barn.ownerPersonId) getOwner(barn.ownerPersonId);
                getHorses();
                getPeople();
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        async function checkForPreviousRequest() {
            setIsLoading(true);
            const queryResult = await getInvitationsByBarnIdInvitor(match.params.id, user.id);
            if (queryResult.isSuccess) {
                const invitiations: Invitation[] = queryResult.result;
                if (invitiations && invitiations.length > 0) setCurrentInvitation(invitiations[0]);
            }
            setIsLoading(false);
        }

        getBarn();
        checkForPreviousRequest();
    }, [match, match.params.id]);

    const handleJoinRequest = async () => {
        setError("");
        setIsLoading(true);
        if (barn) {
            let input: CreateInvitationInput = {
                createdBy: user.id,
                creatorEmail: user.email,
                status: InvitationStatus.pending,
                barnId: barn.id,
                permissionLevel: "admin",
                roles: user.roles,
                invitee: barn.ownerPersonId || "fe487dbd-fc7b-4f42-883f-abcddd45ae45",
                inviteeEmail: barn.contact?.workEmail || "emma@ringsidepro.com"
            };

            let email = "";

            if (barn.ownerPersonId) {
                const barnOwnerResult = await getPersonByPersonId(barn.ownerPersonId);
                if (barnOwnerResult.isSuccess) {
                    const barnOwner: Person = barnOwnerResult.result;
                    input.invitee = barnOwner.id || "fe487dbd-fc7b-4f42-883f-abcddd45ae45";
                    input.inviteeEmail = barnOwner.email || "emma@ringsidepro.com";
                    email = input.inviteeEmail;
                }
            }
            
            const createResult = await createInvitation(input);
            if (createResult.isSuccess) {
                setCurrentInvitation(createResult.result);
                if (email) {
                    const name = user.firstName + " " + user.lastName;
                    await sendBarnRequestJoinEmail(email, barn.name, name);
                }
                setIsLoading(false);
            } else {
                setError("Error: could not create the request.");
                setIsLoading(false);
            }
        } else {
            setError("No barn found.");
            setIsLoading(false);
        }
    }

    const handleMemberJoin = async () => {
        setError("");
        setIsLoading(true);
        if (barn) {
            let input: CreateInvitationInput = {
                createdBy: user.id,
                creatorEmail: user.email,
                status: InvitationStatus.accepted,
                barnId: barn.id,
                permissionLevel: "member",
                roles: user.roles
            };

            if (barn.ownerPersonId) {
                const barnOwnerResult = await getPersonByPersonId(barn.ownerPersonId);
                if (barnOwnerResult.isSuccess) {
                    const barnOwner: Person = barnOwnerResult.result;
                    input.invitee = barnOwner.id;
                    input.inviteeEmail = barnOwner.email;
                }
            }
            
            const createResult = await createInvitation(input);
            if (createResult.isSuccess) {
                const invitation = createResult.result;
                setCurrentInvitation(createResult.result);
                const barnMemberResult = await createBarnPerson(user, barn, invitation.permissionLevel, invitation.status);
                if (!barnMemberResult.isSuccess) {
                    setError("Could not create the new barn member.");
                    setIsLoading(false);
                    return;
                }
                setIsLoading(false);
            } else {
                setError("Error: could not create the request.");
                setIsLoading(false);
            }
        } else {
            setError("No barn found.");
            setIsLoading(false);
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={barn ? barn.name : "Org Info"} />
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Barn Info</IonCardTitle>
                            <IonCardContent>
                                {barn ?
                                    <IonRow>
                                        <IonCol>
                                            <h2>{barn.name}</h2>
                                            <IonRow>
                                                <IonCol>
                                                    <p>Created by: {barnOwner?.firstName + " " + barnOwner?.lastName}</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p>Website: {barn.contact ? barn.contact.website : "unknown"}</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p>Phone Number: {barn.contact ? barn.contact.work : "unknown"}</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p>Email Address: {barn.contact ? barn.contact.workEmail : "unknown"}</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p>Location: {barn.location ? formatAddress(barn.location) : "unknown"}</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                    :
                                    <p>Loading barn....</p>
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Join Barn</IonCardTitle>
                            {isLoading ?
                                <Spinner />
                                :
                                <IonCardContent>
                                    {error && <ErrorAlert width="12" error={error} />}
                                    {currentInvitation ?
                                        <>
                                            <IonRow>
                                                <IonCol>
                                                    <h2>Your request is {currentInvitation.status}</h2>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <hr/>
                                                    <p>Level: {currentInvitation.permissionLevel}</p>
                                                    <p>Sent On: {currentInvitation.createdOn ? moment(currentInvitation.createdOn).format("MM/DD/YYYY h:mm a") : ""}</p>
                                                    {currentInvitation.status !== "pending" && <p>Updated On: {currentInvitation.updatedOn ? moment(currentInvitation.updatedOn).format("MM/DD/YYYY h:mm a") : ""}</p>}
                                                </IonCol>
                                            </IonRow>
                                            {barn?.id === "226ede00-3d5d-477e-b6eb-bdb95da1ea16" && (
                                                <>
                                                    <hr />
                                                    <p>If you need to join the barn again, click here:</p>
                                                    <IonButton className="mt-3" color="tertiary" onClick={handleMemberJoin}>
                                                        Join Barn
                                                    </IonButton>
                                                </>
                                            )}
                                        </>
                                        :
                                        <>
                                            {barn ?
                                                <>
                                                    <IonRow>
                                                        <IonCol className="ion-text-center">
                                                            <p>Join this barn in order to share information about yourself and your horse(s).</p>
                                                            <IonButton className="mt-3" color="tertiary" onClick={handleMemberJoin}>
                                                                Join Barn
                                                            </IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonCol className="ion-text-center">
                                                            <p>If you the owner of the barn or a member of the barn staff, you can request to have admin privileges in this barn. </p>
                                                            <IonButton className="mt-3" onClick={handleJoinRequest}>
                                                                Send Admin Request
                                                            </IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </>
                                                :
                                                <p>Loading barn....</p>
                                            }
                                        </>
                                    }
                                </IonCardContent>
                            }
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Horses</IonCardTitle>
                            {isLoadingHorses ?
                                <Spinner />
                                :
                                <IonCardContent>
                                    {(horses && horses.length > 0) ?
                                        <>
                                            {horses.map((horse, index) => (
                                                <IonRow key={index}>
                                                    <IonCol>
                                                        <p>{horse.name}</p>
                                                    </IonCol>
                                                </IonRow>
                                            ))}
                                        </>
                                        :
                                        <p>No horses found.</p>
                                    }
                                </IonCardContent>
                            }
                        </IonCard>
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>People</IonCardTitle>
                            {isLoadingPeople ?
                                <Spinner />
                                :
                                <IonCardContent>
                                    {(people && people.length > 0) ?
                                        <>
                                            {people.map((person, index) => (
                                                <IonRow key={index}>
                                                    <IonCol>
                                                        <p>{person.name}</p>
                                                    </IonCol>
                                                </IonRow>
                                            ))}
                                        </>
                                        :
                                        <p>No people found.</p>
                                    }
                                </IonCardContent>
                            }
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default ViewBarnPage;