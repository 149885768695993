import {
    IonCard,
    IonCardContent,
    IonCardTitle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Spinner from "../Spinners/Spinner";
import { Barn, Event, EventEntry, Person } from "../../models";
import { Table } from "reactstrap";
import moment from "moment";
import { getEventEntriesByPersonId, updateEventEntry } from "../../utilities/eventEntry/EventEntry";
import { sortEventEntriesByUpdatedDate } from "../../utilities/eventEntry/SortEventEntry";
import { getEventById } from "../../utilities/events/Event";
import { formatStartEndDatesStrings } from "../../utilities/dates/FormatDates";
import SelectFromAllBarns from "../Barn/SelectFromAllBarns";
import { UpdateEventEntryInput } from "../../API";

interface FormattedEntry {
    entryId: string
    entryCreatedOn: string
    entryUpdatedOn: string
    eventName: string
    eventDates: string
    status: string
    backNumber: string
    horseName: string
    horseId: string
    riderName: string
    riderId: string
    secondRiderName: string
    secondRiderId: string
    ownerName: string
    ownerId: string
    trainerName: string
    trainerId: string
    barnName: string
    barnId: string
}
interface _Props {
    person: Person
}

const AdminEditPersonEntryInfo: React.FC<_Props> = ({person}) => {  

    const [isLoading, setIsLoading] = useState(false);
    const [entries, setEntries] = useState<EventEntry[] | undefined | null>();   
    const [formattedEntries, setFormattedEntries] = useState<FormattedEntry[] | undefined | null>();   
   
    const getEntries = async (personId: string) => {
        setIsLoading(true);
        const queryResult = await getEventEntriesByPersonId(personId);
        if (queryResult.isSuccess) {
            const entryList: EventEntry[] = queryResult.result;
            const sorted = sortEventEntriesByUpdatedDate(entryList);
            setEntries(sorted || entryList);
            await formatEntries(sorted || entryList);
        }
        setIsLoading(false);
    }
    
    const formatEntries = async (entryList: EventEntry[]) => {
        let formattedEntryArray = [];
        for (let i = 0; i < entryList.length; i++) {
            const currentEntry = entryList[i];
            const formattedEntry = await formatEntry(currentEntry);
            formattedEntryArray.push(formattedEntry);
        }
        setFormattedEntries(formattedEntryArray)
    }

    const formatEntry = async (entry: EventEntry) => {
        let eventName = "";
        let eventDates = "";
        const eventQueryResult = await getEventById(entry.eventId);
        if (eventQueryResult.isSuccess) {
            const currentEvent: Event = eventQueryResult.result;
            eventName = currentEvent.name;
            const eventStartDate = currentEvent.startDate;
            const eventEndDate = currentEvent.endDate;
            eventDates = formatStartEndDatesStrings(eventStartDate, eventEndDate);
        }
        const formattedEntry: FormattedEntry = {
            entryId: entry.id,
            entryCreatedOn: moment(entry.createdOn).format("MM-DD-YYYY"),
            entryUpdatedOn: moment(entry.updatedOn).format("MM-DD-YYYY"),
            eventName: eventName,
            eventDates: eventDates,
            status: entry.status,
            backNumber: entry.number ? entry.number.toString() : "",
            horseName: entry.horseName || "",
            horseId: entry.horseId || "",
            riderName: entry.riderName || "",
            riderId: entry.riderId || "",
            secondRiderName: entry.secondRiderName || "",
            secondRiderId: entry.secondRiderId || "",
            ownerName: entry.ownerName || "",
            ownerId: entry.ownerId || "",
            trainerName: entry.trainerName || "",
            trainerId: entry.trainerId || "",
            barnName: entry.barnName || "",
            barnId: entry.barnId || ""
        };
        return formattedEntry;
    }

    useEffect(() => {
        if (person) {
            getEntries(person.id);
        }
    }, [person]);

    const handleSelectBarn = async (entryId: string, barn?: Barn) => {
        const updateEventEntryInput: UpdateEventEntryInput = {
            id: entryId,
            barnId: barn?.id || "",
            barnName: barn?.name || ""
        };
        const updateResult = await updateEventEntry(updateEventEntryInput);
        if (updateResult.isSuccess) {
            const updatedEventEntry: EventEntry = updateResult.result;
            if (formattedEntries) {
                const formattedEventEntry: FormattedEntry = await formatEntry(updatedEventEntry);
                const index = formattedEntries?.findIndex(formattedEventEntry => formattedEventEntry.entryId === updatedEventEntry.id);
                if (index > -1) {
                    const updatedList: FormattedEntry[] = [
                        ...formattedEntries.slice(0, index),
                        formattedEventEntry,
                        ...formattedEntries.slice(index + 1),
                    ];
                    setFormattedEntries(updatedList);
                }
            }
        }
    }

    return (
        <>
            <IonCard mode="md" className="ion-padding bg-white">
                <IonCardTitle>Event Entries</IonCardTitle>
                <IonCardContent>
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            {(formattedEntries && formattedEntries.length > 0) ?
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Entry Created On</th>
                                            <th>Entry Updated On</th>
                                            <th>Entry Id</th>
                                            <th>Event Name</th>
                                            <th>Event Dates</th>
                                            <th>Status</th>
                                            <th>Back Number</th>
                                            <th>Horse Name</th>
                                            <th>Horse Id</th>
                                            <th>Rider Name</th>
                                            <th>Rider Id</th>
                                            <th>Second Rider Name</th>
                                            <th>Second Rider Id</th>
                                            <th>Owner Name</th>
                                            <th>Owner Id</th>
                                            <th>Trainer Name</th>
                                            <th>Trainer Id</th>
                                            <th>Barn Name</th>
                                            <th>Barn Id</th>
                                            <th>Select Barn</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formattedEntries.map((formattedEntry, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.entryCreatedOn}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.entryUpdatedOn}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.entryId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.eventName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.eventDates}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.status}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.backNumber}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.horseName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.horseId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.riderName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.riderId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.secondRiderName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.secondRiderId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.ownerName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.ownerId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.trainerName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.trainerId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.barnName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedEntry.barnId}</p>
                                                </td>
                                                <td className="extra-jumbo-input-width">
                                                    <SelectFromAllBarns
                                                        showBarnIds
                                                        selectedValue={formattedEntry.barnId} 
                                                        isHeightRequired={true}
                                                        height={"120px"}
                                                        onSelect={(b: Barn) => handleSelectBarn(formattedEntry.entryId, b)} 
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                :
                                <p>No entries found.</p>
                            }
                        </>
                    }
                </IonCardContent>
            </IonCard>
        </>
    );
};

export default AdminEditPersonEntryInfo;