import { Person } from "../../models";

export function formatDisplayName(firstName: string, middleName?: string, lastName?: string) {
    if (middleName && lastName) return capitalizeName(firstName.trim() + " " + middleName.trim() + " " + lastName.trim());
    if (lastName) return capitalizeName(firstName.trim() + " " + lastName.trim());
    if (middleName) return capitalizeName(firstName.trim() + " " + middleName.trim());
    return capitalizeName(firstName.trim());
}

export function formatDisplayNameForUser(user?: Person): string {
    if (user) {
        const firstName = user.firstName || "";
        const lastName = user.lastName || "";
        if (firstName && lastName) return capitalizeName(firstName.trim() + " " + lastName.trim());
        else if (lastName) return capitalizeName(lastName.trim());
        else if (firstName) return capitalizeName(firstName.trim());
        else return "";
    } else {
        return "";
    }
}

export function capitalizeName(name: string) {
    if (isAllUpperCase(name)) {
        name = name.toLowerCase();
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }
    return name.replace(/\b(\w)/g, s => s.toUpperCase());
}

export function isAllUpperCase(word: string): Boolean {
    if (word && (word === word.toUpperCase())) return true;
    else return false;
}