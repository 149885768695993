import {
    IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonIcon, IonInput,
    IonItem, IonLabel, IonModal, IonRow, IonText, IonTitle, IonToolbar, isPlatform
} from "@ionic/react";
import { chevronDown, chevronUp, close } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { Input } from "reactstrap";
import {
    Barn, Horse, Membership, MembershipTypeCategory,
    Organization, OrganizationMembershipType, Person, PersonalInformation, Rider
} from "../../../models";
import {
    getOrganizationMembershipTypesByOrganizationId, updateOrganizationMembershipType
} from "../../../utilities/organizationMembershipType/OrganizationMembershipType";
import ErrorAlert from "../../Errors/ErrorAlert";
import Spinner from "../../Spinners/Spinner";
import CONSTANT from "../../../constant/constant";
import {
    CreateBarnInput, CreateHorseInput, CreateRiderInput, UpdateHorseInput, UpdateMembershipInput,
    UpdateOrganizationMembershipTypeInput, UpdatePersonalInformationInput, UpdateRiderInput
} from "../../../API";
import {
    deleteMembership, getMembershipsByOrganizationIdByMembershipIdByMembershipTypeByActiveStatus,
    getMembershipsByOrganizationIdByRiderIdByActiveStatus, updateMembership
} from "../../../utilities/membership/Membership";
import SelectFromAllBarns from "../../Barn/SelectFromAllBarns";
import { createHorse, getHorsesByBarnId, updateHorse } from "../../../utilities/horse/Horse";
import SelectRiderFromBarn from "../../Rider/SelectRiderFromBarn";
import { getPersonByPersonId } from "../../../utilities/person/Person";
import SelectFromAllPeople from "../../Person/SelectFromAllPeople";
import SelectHorse from "../../Horse/SelectHorse";
import { formatStartEndDatesStrings } from "../../../utilities/dates/FormatDates";
import { useHistory } from "react-router";
import { isWindows } from "../../../utilities/platform/Platform";
import { getNextAvailableMembershipId } from "../../../utilities/organizationMembershipType/MembershipIdValues";
import moment from "moment";
import { createBarn, getBarnByName } from "../../../utilities/barn/Barn";
import { createRider, getRiderByPersonId, updateRider } from "../../../utilities/rider/Rider";
import { PersonContext } from "../../../context/PersonContext";
import {
    getPersonalInformationByPersonId, updatePersonalInformation
} from "../../../utilities/personalInformation/PersonalInformation";
import GradeLevel from "../GradeLevel";
import { createCogginsDocumentFileName, createS3Object, handleDeleteS3File, handleUploadS3File, handleUploadS3Image } from "../../../utilities/s3Object/s3Object";
import { generateAELIndividualMembershipApplicationReport, generateAELTeamMembershipApplicationReport, membershipApplicationReports } from "../../../utilities/reports/MembershipApplicationReports";
import HorseHeightInput from "../../Horse/HorseHeightInput";
import SelectState from "../../Address/SelectState";
import CogginsDocumentForm from "../../s3Object/CogginsDocumentForm";
import SelectTeam from "../../Team/SelectTeam";
import AELDivisionSelect from "../AELDivisionSelect";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";

interface _Props {
    membership: Membership
    membershipType: OrganizationMembershipType | undefined;
    organization: Organization | undefined;
    setMembership: Function
    setMembershipType: Function
}

const EditMembershipApplicationBasicInfo: React.FC<_Props> = ({membership, membershipType, organization, setMembership, setMembershipType}) => {
    const history = useHistory();
    const user = useContext(PersonContext);

    const membershipStatusList: string[] = [
        CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS_TEXT,
        CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE_TEXT
    ];

    const [isExpandedBasicDetails, setIsExpandedBasicDetails] = useState(true);
    const [isExpandedConnections, setIsExpandedConnections] = useState(true);
    const [isExpandedMemberValues, setIsExpandedMemberValues] = useState(true);
    const [isExpandedDeleteMembership, setIsExpandedDeleteMembership] = useState(true);

    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
    // Basic details card variables
    const [memberName, setMemberName] = useState<string>("");
    const [membershipId, setMembershipId] = useState<string>("");
    const [membershipTypeName, setMembershipTypeName] = useState<string>("");
    const [membershipTypeNames, setMembershipTypeNames] = useState<string[]>([]);
    const [membershipStatus, setMembershipStatus] = useState<string>("");
    const [membershipTypes, setMembershipTypes] = useState<OrganizationMembershipType[]>([]);
    const [membershipTypeBasicDetail, setMembershipTypeBasicDetail] = useState<OrganizationMembershipType>();
    const [groupMemberships, setGroupMemberships] = useState<Membership[]>([]);
    const [showMembershipIdModal, setShowMembershipIdModal] = useState<boolean>(false);

    // Connections card variables
    const [barn, setBarn] = useState<Barn | undefined | null>();
    const [barnName, setBarnName] = useState<string>("");
    const [horseOptions, setHorseOptions] = useState<Horse[] | undefined | null>([]);
    const [rider, setRider] = useState<Rider | null | undefined>();
    const [person, setPerson] = useState<Person | undefined | null>();
    const [personInfo, setPersonInfo] = useState<PersonalInformation>();
    const [horse, setHorse] = useState<Horse | undefined | null>();
    const [horseName, setHorseName] = useState("");

    // Member values card variables
    const [birthDate, setBirthDate] = useState<string>("");
    const [backNumber, setBackNumber] = useState<number>();
    const [gradeLevel, setGradeLevel] = useState<string>("");
    const [aelDivision, setAELDivision] = useState<string>("");
    const [showsAttended, setShowsAttended] = useState<number>();
    const [volunteerHours, setVolunteerHours] = useState<number>();
    const [meetingsAttended, setMeetingsAttended] = useState<number>();
    const [riderMemberships, setRiderMemberships] = useState<Membership[]>([]);
    const [showMemberValueModal, setShowMemberValueModal] = useState<boolean>(false);
    const [horseHeight, setHorseHeight] = useState<number>(0);
    const [horseOwnerName, setHorseOwnerName] = useState<string>("");
    const [cogginsState, setCogginsState] = useState<string>("");
    const [cogginsNumber, setCogginsNumber] = useState<string>("");
    const [cogginsDate, setCogginsDate] = useState<string>("");
    const [cogginsDocument, setCogginsDocument] = useState<any | null | undefined>();
    
    // Delete membership card variables
    const [showModal, setShowModal] = useState<boolean>(false);

    const [isAELOrganization, setIsAELOrganization] = useState(false);

    useEffect(() => {
        getMembershipTypes();
        fetchMemberships(membership.rider, organization);
        if (organization?.id === "e05fc919-2793-4ead-acef-3f6efb584b67") setIsAELOrganization(true);
    }, [organization]);

    useEffect(() => {
        if(membership) {
            if (membership.organizationMembershipType?.category === MembershipTypeCategory.HORSE) {
                setMemberName(membership.horse?.name || "");
                if (membership.horse?.height) setHorseHeight(parseFloat(membership.horse.height));
                if (membership.horse?.ownerName) setHorseOwnerName(membership.horse.ownerName);
                if (membership.horse?.cogginsNumber) setCogginsNumber(membership.horse.cogginsNumber);
                if (membership.horse?.cogginsDate) setCogginsDate(membership.horse.cogginsDate);
                if (membership.horse?.cogginsState) setCogginsState(membership.horse.cogginsState);
            } else {
                setMemberName(membership.personName || "");
            }
            setMembershipId(membership.membershipId);
            if(membership.membershipStatus === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS) {
                setMembershipStatus(CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS_TEXT);
            } else {
                setMembershipStatus(CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE_TEXT);
            }
            if (membership.organizationMembershipType?.category === MembershipTypeCategory.HORSE) {
                if(membership.horse?.dateOfBirth) setBirthDate(membership.horse.dateOfBirth);
            } else {
                getPerson(membership.personId);
            }
            if(membership.barn) {
                setBarn(membership.barn);
                setBarnName(membership.barn.name);
                getHorses(membership.barn);
            }
            if(membership.rider) {
                setRider(membership.rider);
                fetchMemberships(membership.rider, organization);
            }
            if(membership.horse) {
                setHorse(membership.horse);
                setHorseName(membership.horse.name);
            }
            if(membership.backNumber) {
                setBackNumber(membership.backNumber);
            }
            if(membership.gradeLevel) {
                setGradeLevel(membership.gradeLevel);
            }
            if(membership.aelDivision) {
                setAELDivision(membership.aelDivision);
            }
            if(membership.showsAttended) {
                setShowsAttended(membership.showsAttended);
            }
            if(membership.volunteerHours) {
                setVolunteerHours(membership.volunteerHours);
            }
            if(membership.meetingsAttended) {
                setMeetingsAttended(membership.meetingsAttended);
            }
        }
    }, [membership]);

    async function fetchMemberships(riderData: Rider | undefined | null, organization: Organization | undefined) {
        if(organization && riderData) {
            setIsLoading(true);
            setError("");
            const queryResult = await getMembershipsByOrganizationIdByRiderIdByActiveStatus(organization.id, riderData.id);
            if (queryResult.isSuccess) {
                setRiderMemberships(queryResult.result);
            } else {
                setError(queryResult.message);
            }
            setIsLoading(false);
        }
    }

    async function getHorses(barn: Barn) {
        setIsLoading(true);
        const queryResult = await getHorsesByBarnId(barn.id);
        if (queryResult.isSuccess) {
            setHorseOptions(queryResult.result);
        }
        setIsLoading(false);
    }

    async function getPerson(personId: string) {
        setIsLoading(true);
        const personResult = await getPersonByPersonId(personId);
        if (personResult.isSuccess) {
            setPerson(personResult.result);
        }
        const queryResult = await getPersonalInformationByPersonId(personId);
        if (queryResult.isSuccess) {
            const personalInformation: PersonalInformation = queryResult.result;
            setPersonInfo(personalInformation);
            setBirthDate(
                membership && membership.rider?.birthdate ? 
                membership.rider.birthdate : 
                personalInformation && personalInformation.dateOfBirth ? 
                personalInformation.dateOfBirth : ""
            );
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if(membershipType) {
            setMembershipTypeName(membershipType.name);
            setMembershipTypeBasicDetail(membershipType);
        }
    }, [membershipType]);

    useEffect(() => {
        if(membershipType && organization && membership) {
            if(membershipType.category === MembershipTypeCategory.GROUP) {
                fetchGroupMemberships(organization.id, membership.membershipId, membershipType.id);
            }
        }
    }, [membershipType, membership, organization]);

    async function fetchGroupMemberships (orgId: string, membershipId: string, membershipTypeId: string) {
        setIsLoading(true);
        const queryResult = await getMembershipsByOrganizationIdByMembershipIdByMembershipTypeByActiveStatus(orgId, membershipId, membershipTypeId);
        if (queryResult.isSuccess) {
            setGroupMemberships(queryResult.result);
        } else {
            setError(queryResult.message);
        }
        setIsLoading(false);
    }

    async function getMembershipTypes() {
        if (organization) {
            setIsLoading(true);
            const queryResult = await getOrganizationMembershipTypesByOrganizationId(organization.id);
            if (queryResult.isSuccess) {
                setMembershipTypes(queryResult.result);
                const types = queryResult.result.map((mt: OrganizationMembershipType) => {
                    return mt.name;
                });
                setMembershipTypeNames([...types]);
            } else {
                setError(queryResult.message);
            }
            setIsLoading(false);
        }
    }

    const handleMembershipId = () => {
        if (!membershipTypeBasicDetail?.membershipIdValues) {
            setError("Please add a range of Membership Id Values to the selected Membership Type.")
            return;
        }
        setMembershipId(membershipTypeBasicDetail?.nextAvailableMembershipId || "");
    }

    const handleMembershipTypeChange  = (e: any) => {
        setMembershipId("");
        const mtName = e.target.value;
        setMembershipTypeName(mtName);
        const selectedMT = membershipTypes.find((mt) => {
            return mt.name === mtName;
        });
        setMembershipTypeBasicDetail(selectedMT);
        if(mtName === membershipType?.name) {
            setMembershipId(membership.membershipId);
        }
    }

    const handleBasicDetailsSave = async () => {
        setError("");
        if (!memberName) {
            setError("Please include a value for Member Name.")
            return;
        }
        if (membershipTypeBasicDetail?.membershipIdValues &&
            membershipTypeBasicDetail.applicationOptions?.autoAssignIds && !membershipId) {
            setError("Please include a value for Membership Id.")
            return;
        }
        if (!membershipTypeName) {
            setError("Please include a value for Membership Type.")
            return;
        }
        if (!membershipStatus) {
            setError("Please include a value for Membership Status.")
            return;
        }
        if(membershipType?.category === MembershipTypeCategory.GROUP &&
            membershipTypeBasicDetail?.category === MembershipTypeCategory.GROUP &&
            membershipType.name !== membershipTypeBasicDetail.name) {
            setShowMembershipIdModal(true);
        } else {
            setIsLoading(true);
            await updateBasicDetailData();
            setIsLoading(false);
        }
    }

    const updateBasicDetailData = async () => {
        let horseData: Horse | null |undefined = horse;
        if (membership.organizationMembershipType?.category === MembershipTypeCategory.HORSE && horse) {
            if (memberName && horseData && memberName !== horseData.name) {
                const horseInput: UpdateHorseInput = {
                    id: horseData.id,
                    name: memberName,
                };
                const queryResult = await updateHorse(horseInput);
                if (queryResult.isSuccess) {
                    horseData = queryResult.result;
                    setHorse(queryResult.result);
                }
            }
        }
        const input: UpdateMembershipInput = {
            id: membership.id,
            membershipId: membershipId,
            organizationMembershipTypeId: membershipTypeBasicDetail?.id,
            type: membershipTypeName,
            membershipStatus: membershipStatus === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS_TEXT ?
                CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS : CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE
        };
        if (membership.organizationMembershipType?.category === MembershipTypeCategory.HORSE && horse) {
            input.horseId = horseData?.id;
        } else {
            input.personName = memberName;
        }
        const updateMembershipResult = await updateMembership(input);
        if (updateMembershipResult.isSuccess) {
            setMembership(updateMembershipResult.result);
            // Check if membership type is changed. If yes then update the nextAvailableMembershipId for
            // newly selected membership type. Also set the new membership type through props function
            if(membershipType?.name !== membershipTypeName) {
                if (membershipTypeBasicDetail?.membershipIdValues && membershipTypeBasicDetail.applicationOptions?.autoAssignIds) {
                    const newMembershipId = getNextAvailableMembershipId(membershipTypeBasicDetail?.membershipIdValues, membershipTypeBasicDetail.nextAvailableMembershipId || "");
                    const updateInput:  UpdateOrganizationMembershipTypeInput = {
                        id: membershipTypeBasicDetail.id,
                        nextAvailableMembershipId: newMembershipId
                    };
                    await updateOrganizationMembershipType(updateInput);
                }
                setMembershipType(membershipTypeBasicDetail);
            }
        } else {
            setError(updateMembershipResult.message);
        }
    }

    const updateMembershipId = async () => {
        setIsLoadingModal(true);
        for (const m of groupMemberships) {
            if (m.id === membership.id) {
                await updateBasicDetailData();
            } else {
                const input: UpdateMembershipInput = {
                    id: m.id,
                    membershipId: membershipId,
                    organizationMembershipTypeId: membershipTypeBasicDetail?.id,
                    type: membershipTypeName
                };
                await updateMembership(input);
            }
        }
        setShowMembershipIdModal(false);
        setIsLoadingModal(false);
    }

    const handleSelectBarn = async (barn?: Barn) => {
        if (barn) {
            setBarn(barn);
            setBarnName(barn.name);
            const queryResult = await getHorsesByBarnId(barn.id);
            if (queryResult.isSuccess) {
                setHorseOptions(queryResult.result);
            }
        } else {
            setBarn(undefined);
            setBarnName("");
            setHorseOptions(undefined);
        }
    }

    const handleSelectRider = async (rider?: Rider) => {
        if (rider) {
            setRider(rider);
        } else {
            setRider(undefined);
        }
    }

    const handleSelectPerson = async (person?: Person) => {
        if (person) {
            setPerson(person);
        } else {
            setPerson(undefined);
        }
    }

    const handleSelectHorse = async (horse?: Horse) => {
        if (horse) {
            setHorse(horse);
            setHorseName(horse.name || horseName || "");
        } else {
            setHorse(undefined);
            setHorseName("");
        }
    }

    const handleConnectionsSave = async () => {
        setIsLoading(true);
        setError("");

        let currentBarn: Barn | undefined | null = barn;
        if (!currentBarn && barnName && barnName !== "") {
            const queryBarnResult = await getBarnByName(barnName);
            if (queryBarnResult.isSuccess) {
                currentBarn = queryBarnResult.result;
            } else {
                const barnInput: CreateBarnInput = {
                    name: barnName
                };
                const createBarnResult = await createBarn(barnInput);
                if (createBarnResult.isSuccess) {
                    currentBarn = createBarnResult.result;
                }
            }
        }

        let currentHorse: Horse | undefined | null = horse;
        if (horseName && currentHorse && horseName !== currentHorse.name) {
            const horseInput: UpdateHorseInput = {
                id: currentHorse.id,
                name: horseName,
                barnId: currentBarn?.id,
                barnName: currentBarn?.name
            };
            await updateHorse(horseInput);
        } else if (horseName && !currentHorse) {
            if (horseName && barn) {
                // Try to find the horse in the barn
                const queryResult = await getHorsesByBarnId(barn.id);
                if (queryResult.isSuccess) {
                    const horsesInBarn: Horse[] = queryResult.result;
                    if (horsesInBarn && horsesInBarn.length > 0) {
                        currentHorse = horsesInBarn.find((h) => {
                            return h.name.toLowerCase() === horseName.toLowerCase();
                        });
                    }
                }
            }
            if (horseName && !currentHorse) {
                const horseInput: CreateHorseInput = {
                    name: horseName,
                    ownerName: "",
                    barnId: currentBarn?.id || "",
                    barnName: currentBarn?.name || "",
                    personId: person?.id || ""
                };
                const createHorseResult = await createHorse(horseInput);
                if (createHorseResult.isSuccess) {
                    currentHorse = createHorseResult.result;
                }
                setHorse(currentHorse);
            }
        }

        let currentRider: Rider | undefined | null = rider;
        if (currentRider) {
            const updateRiderInput: UpdateRiderInput = {
                id: currentRider.id,
                barnId: currentBarn?.id,
                barnName: currentBarn?.name,
            };
            const updateResult = await updateRider(updateRiderInput);
            currentRider = updateResult.result;
        } else if (!rider) {
            if (person) {
                const riderQueryResult = await getRiderByPersonId(person.id);
                if (riderQueryResult.isSuccess) {
                    currentRider = riderQueryResult.result;
                }
            } else {
                let riderInput: CreateRiderInput = {
                    name: memberName,
                    barnId: currentBarn?.id || "",
                    barnName: currentBarn?.name,
                    createdBy: user.id || "", 
                    createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                };
                const riderResult = await createRider(riderInput);
                if (riderResult.isSuccess) {
                    currentRider = riderResult.result;
                }
            }
        }
        if (membership) {
            let input: UpdateMembershipInput = {
                id: membership.id,
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };

            if (currentBarn?.id) {
                input.barnId = currentBarn?.id;
            } else {
                input.barnId = null;
            }
            if (currentHorse?.id) {
                input.horseId = currentHorse?.id;
            } else {
                input.horseId = null;
            }
            if (currentRider?.id) {
                input.riderId = currentRider?.id;
            } else {
                input.riderId = null;
            }
            const updatedResult = await updateMembership(input);
            if (updatedResult.isSuccess) {
                setMembership(updatedResult.result);
            } else {
                setError("The membership could not be updated.");
            }
            setIsLoading(false);
        }
    }

    const handleMemberValuesSave = async () => {
        if (membershipType?.applicationFields?.gradeLevel) {
            if(gradeLevel === "-") {
                setError("A required field is missing data: Grade Level");
                return;
            }
        }
        if (membershipType?.category ===  MembershipTypeCategory.HORSE) {
            if (!horseHeight) {
                setError("A required field is missing data: Height in Hands");
                return false;
            }
            if (!horseOwnerName) {
                setError("A required field is missing data: Owner's Name");
                return false;
            }
            if (membershipType.applicationFields?.dateOfBirth && !birthDate) {
                setError("A required field is missing data: Member Birth Date");
                return false;
            }
            if (membershipType.applicationFields?.cogginsInfo) {
                if (!cogginsNumber) {
                    setError("A required field is missing data: Coggins Accession Number");
                    return false;
                }
                if (!cogginsDate) {
                    setError("A required field is missing data: Coggins Test Date");
                    return false;
                }
                if (!cogginsState) {
                    setError("A required field is missing data: State of Test");
                    return false;
                }
            }
            if (horse?.coggins) {
                setError("");
            } else if (membershipType.applicationFields?.cogginsUpload && !cogginsDocument) {
                setError("A required field is missing data: Upload a Coggins");
                return false;
            }
        }
        if (riderMemberships && riderMemberships.length > 1 && membershipType?.category !== MembershipTypeCategory.HORSE) {
            setShowMemberValueModal(true);
        } else {
            await updateCurrentMemberships(false);
        }
    }

    const handleUploadDocument = async (file: any, horse: Horse) => {
        if (horse) {
            // Upload this to S3 
            const fileName = createCogginsDocumentFileName(horse);
            let uploadResult;
            if (file.type === "application/pdf") {
                uploadResult = await handleUploadS3File(fileName, file);
            } else {
                uploadResult = await handleUploadS3Image(fileName, file);
            }
            if (uploadResult.isSuccess) {
                // If successfully added to S3, save to video list to the person
                const key = uploadResult.result;
                const title = "Coggins for " + horse.name;
                const description = "Coggins uploaded for " + horse.name + " by " + user?.firstName + " " + user?.lastName + " on " + moment(new Date()).format("MMM DD, YYYY h:mm a"); 
                const s3Object = createS3Object(key, title, file, description);
                return s3Object;
            } else {
                setError("Could not upload the video. Contact hello@ringsidepro.com for help.")
            }
        } else {
            setError("No horse found.")
        }
    }
    
    const updateCurrentMemberships = async (hideModal: boolean) => {
        setError("");
        setIsLoading(true);
        // update birth date in Personal Information model
        if(membershipType?.category === MembershipTypeCategory.INDIVIDUAL || membershipType?.category === MembershipTypeCategory.GROUP) {
            if (membershipType?.applicationFields?.dateOfBirth || membershipType?.applicationFields?.collectMemberDetailsTogether) {
                if(personInfo?.id) {
                    const personalInformationInput: UpdatePersonalInformationInput = {
                        id: personInfo.id,
                        dateOfBirth: birthDate
                    };
                    const updatePersonInfoResult = await updatePersonalInformation(personalInformationInput);
                    if (updatePersonInfoResult.isSuccess) {
                        setPersonInfo(updatePersonInfoResult.result);
                    } else {
                        setError(updatePersonInfoResult.message);
                    }

                    // update birth date in rider model
                    const riderData: Rider | undefined | null = membership.rider;
                    if(riderData) {
                        const riderInput: UpdateRiderInput = {
                            id: riderData.id,
                            birthdate: birthDate
                        };
                        const updateRiderResult = await updateRider(riderInput);
                        if(updateRiderResult.isSuccess) {
                            setRider(updateRiderResult.result);
                        }
                    }
                }
            }
        }     

        let horseData: Horse | null |undefined = horse;
        if (membershipType?.category === MembershipTypeCategory.HORSE) {
            if (horseData) {
                const horseInput: UpdateHorseInput = {
                    id: horseData.id,
                    ownerName: horseOwnerName,
                    height: horseHeight.toString(),
                    dateOfBirth: birthDate ? birthDate : null,     
                };
                if(membershipType.applicationFields?.cogginsInfo) {
                    if (cogginsState) horseInput.cogginsState = cogginsState;
                    if (cogginsDate) horseInput.cogginsDate = cogginsDate;
                    if (cogginsNumber) horseInput.cogginsNumber = cogginsNumber;
                } else {
                    horseInput.cogginsState = "";
                    horseInput.cogginsDate = null;
                    horseInput.cogginsNumber = "";
                }
                if(membershipType.applicationFields?.cogginsUpload) {
                    if (horseData.coggins && !cogginsDocument) {
                        horseInput.coggins = horseData.coggins;
                    } else if (cogginsDocument) {
                        const s3Object = await handleUploadDocument(cogginsDocument, horseData);
                        horseInput.coggins = s3Object;
                    }
                } else {
                    horseInput.coggins = null;
                }
                const updateHorseResult = await updateHorse(horseInput);
                if (updateHorseResult.isSuccess) {
                    horseData = updateHorseResult.result;
                    setHorse(updateHorseResult.result)
                } else {
                    console.error(updateHorseResult.message);
                }
            }
        }

        const input: UpdateMembershipInput = {
            id: membership.id,
        };

        if (membershipType?.category === MembershipTypeCategory.HORSE && horse) {
            input.horseId = horse.id;
        } else {
            input.showsAttended = showsAttended;
            input.volunteerHours = volunteerHours;
            input.meetingsAttended = meetingsAttended;
        }

        if (membershipType?.applicationFields?.backNumber) {
            input.backNumber = backNumber;
        }
        if (membershipType?.applicationFields?.gradeLevel) {
            input.gradeLevel = gradeLevel;
        }
        if (aelDivision) {
            input.aelDivision = aelDivision;
        }
        const updateMembershipResult = await updateMembership(input);
        if (updateMembershipResult.isSuccess) {
            setMembership(updateMembershipResult.result);
        } else {
            setError("Sorry, a problem occurred. Please try again or contact hello@ringsidepro.com for help.");
        }
        setIsLoading(false);
        if (hideModal) {
            setShowMemberValueModal(false);
        }
    }

    const updateAllMemberships = async () => {
        if (riderMemberships.length) {
            setError("");
            setIsLoading(true);
            for (const riderMembership of riderMemberships) {
                if (riderMembership.id === membership.id) {
                    await updateCurrentMemberships(false);
                } else {
                    const input: UpdateMembershipInput = {
                        id: riderMembership.id,
                        volunteerHours: volunteerHours,
                        meetingsAttended: meetingsAttended
                    };
                    if (membershipType?.applicationFields?.gradeLevel) {
                        input.gradeLevel = gradeLevel;
                    }
                    await updateMembership(input);
                }
            }
            setShowMemberValueModal(false);
        }
    }

    const handleDeleteMembership = async () => {
        setError("");
        setIsLoading(true);
        if (membership?.id) {
            const deletedDocs = [];
            if(membership.documents?.length) {
                for(const s3Object of membership.documents) {
                    const deleteResult = await handleDeleteS3File(s3Object?.key || "");
                    if(deleteResult.isSuccess) {
                        deletedDocs.push(deleteResult);
                    }
                }
            }
            if(membership.documents && deletedDocs.length !== membership.documents?.length) {
                setError("A problem occurred while deleting the membership's documents. Please contact hello@ringsidepro.com for help.");
                setShowModal(false);
                setIsLoading(false);
                return;
            }
            const deleteMembershipData = {
                id: membership.id,
            };
            const deleteMembershipResult = await deleteMembership(deleteMembershipData);
            if (deleteMembershipResult.isSuccess) {
                const path = `/index/staff/organization/members/general/${organization?.id}`;
                history.push(path);
            } else {
                setError(deleteMembershipResult.message);
            }
        }
        setShowModal(false);
        setIsLoading(false);
    };

    const handleDownloadPdf = async () => {
        setIsLoading(true);
        const isDownload = true;
        const queryResult = await getPersonalInformationByPersonId(user.id);
        const personInfo = queryResult.result;
        if (membership.organizationMembershipType?.category === MembershipTypeCategory.GROUP) {
            if (membershipType) {
                if (isAELOrganization) await generateAELTeamMembershipApplicationReport(membership, membershipType, isDownload);
                else await membershipApplicationReports(groupMemberships, membershipType, personInfo, organization, isDownload);
            }
        } else if (membership.organizationMembershipType?.category === MembershipTypeCategory.INDIVIDUAL) {
            if (membershipType) {
                if (isAELOrganization) await generateAELIndividualMembershipApplicationReport(membership, membershipType, isDownload);
                else {
                    const individualMemberships: Membership[] = [membership];
                    await membershipApplicationReports(individualMemberships, membershipType, personInfo, organization, isDownload);
                }
            }
        } else if (membership.organizationMembershipType?.category === MembershipTypeCategory.HORSE) {
            if (membershipType) {
                const horseMemberships: Membership[] = [membership];
                await membershipApplicationReports(horseMemberships, membershipType, personInfo, organization, isDownload);
            }
        }
        setIsLoading(false);
    };

    const handleHeightChange = (heightInInches: number) => {
        setHorseHeight(heightInInches);
    }

    const handleSelectedDocument = async (file: any) => {
        setCogginsDocument(file);
    }

    const handleCogginsState = (state: string) => {
        if (state && state !== "-"){ 
            setCogginsState(state);
        } else {
            setCogginsState("");
        }
    }

    return (
        <IonRow className="ion-justify-content-center">
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ?
                <Spinner/>
            :
                <IonCol size="12">
                    <IonCard mode="md" className="ion-padding bg-white">
                        <IonRow onClick={() => setIsExpandedBasicDetails(!isExpandedBasicDetails)}>
                            <IonCol size="10" className="ion-text-left">
                                <h5>Basic Details</h5>
                            </IonCol>
                            <IonCol size="2" className="ion-text-right">
                                <IonIcon size="large" icon={isExpandedBasicDetails ? chevronUp : chevronDown} />
                            </IonCol>
                        </IonRow>
                        {isExpandedBasicDetails && (<IonCardContent>
                            <p className="ion-text-left">
                                <span className="link" onClick={handleDownloadPdf}>
                                    Download as PDF
                                </span>
                            </p>
                            <IonRow className="pb-3">
                                {(isWindows() || (isPlatform("desktop"))) && <IonCol sizeXs="12" sizeMd="12">
                                    <IonButton
                                         id="edit-membership-generate-id-btn"
                                        class="ion-float-right"
                                        className="ml-1"
                                        color="tertiary"
                                        disabled={membershipId.length === 0 ? false : true}
                                        onClick={handleMembershipId}
                                    >
                                        Generate Id
                                    </IonButton>
                                </IonCol>}
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Member Name <RequiredInputIndicator /></IonLabel>
                                        <IonInput
                                            id="edit-membership-member-name"
                                            type="text"
                                            value={memberName}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setError("");
                                                setMemberName(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                                {(!(isWindows() || isPlatform("desktop"))) && <IonCol sizeXs="12" sizeMd="12">
                                    <IonButton
                                        id="edit-membership-generate-id-btn"
                                        class="ion-float-right"
                                        className="ml-1"
                                        color="tertiary"
                                        disabled={membershipId.length === 0 ? false : true}
                                        onClick={handleMembershipId}
                                    >
                                        Generate Id
                                    </IonButton>
                                </IonCol>}
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked" class="text-primary">
                                            Membership Id
                                        </IonLabel>
                                        <IonInput
                                            id="edit-membership-membership-id-values"
                                            type="text"
                                            // readonly={true} //Allowing RHSC to test and be able to edit
                                            value={membershipId}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setError("");
                                                setMembershipId(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow className="pb-3">
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonLabel className="pl-3 description text-darker" position="stacked">
                                        Membership Type{" "}
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <Input
                                        id="membership-type-edit-name"
                                        name="membership-type-name"
                                        type="select"
                                        value={membershipTypeName}
                                        onChange={handleMembershipTypeChange}
                                    >
                                        {membershipTypeNames.map((value, index) => {
                                            return (<option key={index} value={value}> {value} </option>);
                                        })}
                                    </Input>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonLabel className="pl-3 description text-darker" position="stacked">
                                        Status{" "}
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <Input
                                        id="membership-application-status"
                                        name="membership-application-status"
                                        type="select"
                                        value={membershipStatus}
                                        onChange={(e) => {
                                            setError("");
                                            setMembershipStatus(e.target.value);
                                        }}
                                    >
                                        {membershipStatusList.map((value, index) => {
                                            return (<option key={index} value={value}> {value} </option>);
                                        })}
                                    </Input>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                    <IonButton id="edit-membership-basic-save-btn" expand="block" color="tertiary" onClick={handleBasicDetailsSave}>
                                        Save
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>)}
                    </IonCard>
                    <IonCard mode="md" className="ion-padding bg-white">
                        <IonRow onClick={() => setIsExpandedConnections(!isExpandedConnections)}>
                            <IonCol size="10" className="ion-text-left">
                                <h5>Connections</h5>
                            </IonCol>
                            <IonCol size="2" className="ion-text-right">
                                <IonIcon size="large" icon={isExpandedConnections ? chevronUp : chevronDown} />
                            </IonCol>
                        </IonRow>
                        {isExpandedConnections && (
                            <IonCardContent>
                                {isAELOrganization ?
                                    <>
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="12">
                                                <SelectTeam selectedValue={membership.teamId} isDisabled={true} onSelect={(team: any) => console.log("Selected team")} />
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="12">
                                                <IonLabel position="stacked">User</IonLabel>
                                                <SelectFromAllPeople person={person} selectedValue={person?.id} isDisabled={true} onSelect={(person: Person) => {handleSelectPerson(person)}} />
                                            </IonCol>
                                        </IonRow>
                                    </>
                                    :
                                    <>
                                        <p className="text-info description ion-text-wrap">Try finding the barn in the select. If it doesn't exist, type in the name of the barn.</p>
                                        <IonRow className="pb-3">
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonLabel position="stacked">Barn</IonLabel>
                                                <SelectFromAllBarns barn={barn} selectedValue={barn?.id} onSelect={(barn: Barn) => handleSelectBarn(barn)} />
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonItem color="white">
                                                    <IonLabel position="stacked">Barn Name</IonLabel>
                                                    <IonInput
                                                        id="edit-membership-barn-name"
                                                        type="text"
                                                        value={barnName}
                                                        aria-required={true}
                                                        onIonChange={e => {
                                                            setBarnName(e.detail.value!)
                                                        }}
                                                    />
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        { membershipType?.category !== MembershipTypeCategory.HORSE && <>
                                            <p className="text-info description ion-text-wrap">Try finding the rider in the select. If the rider doesn't exist, type in the name of the rider. Do not alter the User field.</p>
                                            <IonRow className="pb-3">
                                                <IonCol sizeXs="12" sizeMd="6">
                                                    <IonLabel position="stacked">Rider</IonLabel>
                                                    <SelectRiderFromBarn barn={barn || undefined} selectedValue={rider?.id} onSelect={(rider: Rider) => handleSelectRider(rider)} />
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="6">
                                                    <IonLabel position="stacked">User</IonLabel>
                                                    <SelectFromAllPeople person={person} selectedValue={person?.id} isDisabled={true} onSelect={(person: Person) => {handleSelectPerson(person)}} />
                                                </IonCol>
                                            </IonRow></>}
                                            <p className="text-info description ion-text-wrap">Try finding the horse in the select. Horses load after selecting the barn. If the horse doesn't exist, type in the name of the horse.</p>
                                            <IonRow className="pb-3">
                                                <IonCol sizeXs="12" sizeMd="6">
                                                    <IonLabel position="stacked">Horse</IonLabel>
                                                    <SelectHorse horses={horseOptions} selectedValue={horse?.id} onSelect={(horse: Horse) => {handleSelectHorse(horse)}} />
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="6">
                                                    <IonItem color="white">
                                                        <IonLabel position="stacked">Horse Name</IonLabel>
                                                        <IonInput
                                                            id="edit-membership-horse-name"
                                                            type="text"
                                                            value={horseName}
                                                            aria-required={true}
                                                            onIonChange={e => {
                                                                setHorseName(e.detail.value!)
                                                            }}
                                                        />
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                                    <IonButton id="edit-membership-connections-save-btn" expand="block" color="tertiary" onClick={handleConnectionsSave}>
                                                        Save
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                    </>
                                }
                            </IonCardContent>
                        )}
                    </IonCard>
                    <IonCard mode="md" className="ion-padding bg-white">
                        <IonRow onClick={() => setIsExpandedMemberValues(!isExpandedMemberValues)}>
                            <IonCol size="10" className="ion-text-left">
                                <h5>Member Values</h5>
                            </IonCol>
                            <IonCol size="2" className="ion-text-right">
                                <IonIcon size="large" icon={isExpandedMemberValues ? chevronUp : chevronDown} />
                            </IonCol>
                        </IonRow>
                        {isExpandedMemberValues && (<IonCardContent>
                            <IonRow className="pb-3">
                                {(membershipType?.category === MembershipTypeCategory.HORSE && membershipType?.applicationFields?.horseHeight) && 
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <HorseHeightInput height={horseHeight} isRequired={true} onChange={handleHeightChange} />
                                    </IonCol>}
                                {(membershipType?.category === MembershipTypeCategory.HORSE && membershipType?.applicationFields?.horseOwnerName) && 
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                                Owner's Name
                                                <RequiredInputIndicator />
                                            </IonLabel>
                                            <IonInput
                                                id="edit-membership-horse-owner-name"
                                                type="text"
                                                value={horseOwnerName}
                                                aria-required={true}
                                                onIonChange={(e) => {
                                                    setError("");
                                                    setHorseOwnerName(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>}
                                {(membershipType?.applicationFields?.dateOfBirth || membershipType?.applicationFields?.collectMemberDetailsTogether) && 
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">
                                            Member Birth Date
                                            {membershipType.category === MembershipTypeCategory.HORSE && <RequiredInputIndicator />}
                                        </IonLabel>
                                        <IonInput 
                                            id="membership-app-edit-birth-date"
                                            type="date"
                                            value={birthDate}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setBirthDate(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>}
                                {membershipType && membershipType.category === MembershipTypeCategory.HORSE &&
                                    membershipType.applicationFields?.cogginsInfo && <>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                                Coggins Accession Number
                                                <RequiredInputIndicator />
                                            </IonLabel>
                                            <IonInput
                                                id="edit-membership-coggins-accession-number"
                                                type="text"
                                                value={cogginsNumber}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setError("");
                                                    setCogginsNumber(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">
                                                Coggins Test Date
                                                <RequiredInputIndicator />
                                            </IonLabel>
                                            <IonInput 
                                                id="edit-membership-coggins-date"
                                                type="date"
                                                value={cogginsDate}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setError("");
                                                    setCogginsDate(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <SelectState id="edit-membership-coggins-state-of-test" isRequired={true} label="State of Test" selectedValue={cogginsState} onInputChange={handleCogginsState} />
                                    </IonCol>
                                </>}
                                {membershipType?.applicationFields?.backNumber && <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">
                                            {isAELOrganization ? "Arm" : "Back"} Number
                                        </IonLabel>
                                        <IonInput
                                            id="membership-app-edit-back-number"
                                            type="text"
                                            value={backNumber}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setBackNumber(e.detail.value! ? parseInt(e.detail.value!) : undefined)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>}
                                {membershipType?.applicationFields?.gradeLevel && <IonCol sizeXs="12" sizeMd="6">
                                    <GradeLevel
                                        onSelect={setGradeLevel}
                                        selectedValue={gradeLevel}
                                    />
                                </IonCol>}
                                {isAELOrganization &&
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <AELDivisionSelect
                                            onSelect={setAELDivision}
                                            selectedValue={aelDivision}
                                        />
                                    </IonCol>
                                }
                                {(membershipType?.category !== MembershipTypeCategory.HORSE) && !isAELOrganization && <>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">
                                            Shows Attended
                                        </IonLabel>
                                        <IonInput
                                            id="membership-app-edit-shows-attended"
                                            type="text"
                                            value={showsAttended}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setShowsAttended(e.detail.value! ? parseInt(e.detail.value!) : undefined)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">
                                            Volunteer Hours
                                        </IonLabel>
                                        <IonInput
                                            id="membership-app-edit-volunteer-hours"
                                            type="text"
                                            value={volunteerHours}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setVolunteerHours(e.detail.value! ? parseInt(e.detail.value!) : undefined)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="5">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">
                                            Meetings Attended
                                        </IonLabel>
                                        <IonInput
                                            id="membership-app-edit-meetings-attended"
                                            type="text"
                                            value={meetingsAttended}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setMeetingsAttended(e.detail.value! ? parseInt(e.detail.value!) : undefined)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                                </>}
                            </IonRow>
                            {(membershipType?.category === MembershipTypeCategory.HORSE && membershipType.applicationFields?.cogginsUpload) && (<IonRow className="ion-justify-content-center"> 
                                <IonCol sizeXs="12" sizeMd="6">
                                    <CogginsDocumentForm isRequired={true} horse={horse} onSubmit={handleSelectedDocument} />
                                </IonCol>
                            </IonRow>)}
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                    <IonButton id="edit-membership-member-values-save-btn" expand="block" color="tertiary" onClick={handleMemberValuesSave}>
                                        Save
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>)}
                    </IonCard>
                    <IonCard mode="md" className="ion-padding bg-white">
                        <IonRow onClick={() => setIsExpandedDeleteMembership(!isExpandedDeleteMembership)}>
                            <IonCol size="10" className="ion-text-left">
                                <h5>Delete Membership</h5>
                            </IonCol>
                            <IonCol size="2" className="ion-text-right">
                                <IonIcon size="large" icon={isExpandedDeleteMembership ? chevronUp : chevronDown} />
                            </IonCol>
                        </IonRow>
                        {isExpandedDeleteMembership && (<IonCardContent>
                            <p>If you delete this membership, it will be permanently removed. You cannot undo this action.</p>
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                    <IonButton
                                        id="delete-membership-btn"
                                        onClick={() => setShowModal(true)}
                                        color="danger"
                                    >
                                        Delete
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>)}
                    </IonCard>
                </IonCol>
            }
            <IonModal backdropDismiss={false} isOpen={showModal}>
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">Remove Membership</IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            id="delete-membership-modal-close-icon"
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p
                                id="deleteMembershipModalBtn"
                                className="font-weight-normal text-medium text-capitalize"
                            >
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <h3>
                                Are you sure you want to permanently remove this membership:{" "}
                            </h3>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10" className="ion-text-center"> 
                            <h4>Member Name: {membershipType?.category === MembershipTypeCategory.HORSE ? membership.horse?.name : membership.personName}</h4>
                            <h4>Membership Type: {membership.type}</h4>
                            <IonText>
                                Created on:{" "}
                                {formatStartEndDatesStrings(membership?.createdOn)}
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonContent>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <IonRow className="ion-justify-content-center ion-padding-top">
                        <IonCol size="6" className="ion-text-center">
                            <IonButton
                                id="delete-membership-modal-cancel-btn"
                                expand="block"
                                color="light"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </IonButton>
                        </IonCol>
                        <IonCol size="6" className="ion-text-center">
                            <IonButton
                                id="delete-membership-modal-delete-btn"
                                expand="block"
                                color="danger"
                                onClick={handleDeleteMembership}
                            >
                                Delete
                            </IonButton>
                        </IonCol>
                    </IonRow>
                )}
            </IonModal>
            <IonModal backdropDismiss={false} isOpen={showMemberValueModal}>
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">Update Membership</IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            id="update-membership-modal-close-icon"
                            fill="clear"
                            onClick={() => setShowMemberValueModal(false)}
                        >
                            <p
                                id="updateMembershipModalBtn"
                                className="font-weight-normal text-medium text-capitalize"
                            >
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <IonText>
                                Would you like to update these values:
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12" className="ion-text-center">
                            {(membershipType?.applicationFields?.dateOfBirth || membershipType?.applicationFields?.collectMemberDetailsTogether) && <h6 className="font-weight-bold">Birth Date</h6>}
                            {membershipType?.applicationFields?.gradeLevel && <h6 className="font-weight-bold">Grade Level</h6>}
                            <h6 className="font-weight-bold">Meetings Attended</h6>
                            <h6 className="font-weight-bold">Volunteer Hours</h6>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <IonText>For all memberships with this rider?</IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12" className="ion-text-center">
                            <h4>Memberships</h4>
                            {riderMemberships.map((m) => {
                                return (
                                    m.membershipId ?
                                        <h6 className="font-weight-bold">{m.membershipId} - {m.personName}</h6>
                                    :
                                        <h6 className="font-weight-bold">{m.personName}</h6>
                                )
                            })}
                        </IonCol>
                    </IonRow>
                </IonContent>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <IonRow className="ion-justify-content-center ion-padding-top">
                        <IonCol size="6" className="ion-text-center">
                            <IonButton
                                id="update-current-membership-modal-btn"
                                expand="block"
                                color="light"
                                onClick={() => updateCurrentMemberships(true)}
                            >
                                Update Current
                            </IonButton>
                        </IonCol>
                        <IonCol size="6" className="ion-text-center">
                            <IonButton
                                id="update-all-membership-modal-btn"
                                expand="block"
                                color="success"
                                onClick={updateAllMemberships}
                            >
                                Update All
                            </IonButton>
                        </IonCol>
                    </IonRow>
                )}
            </IonModal>
            <IonModal backdropDismiss={false} isOpen={showMembershipIdModal}>
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">Update Membership</IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            id="update-membership-id-modal-close-icon"
                            fill="clear"
                            onClick={() => setShowMembershipIdModal(false)}
                        >
                            <p
                                id="updateMembershipIdModalBtn"
                                className="font-weight-normal text-medium text-capitalize"
                            >
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <IonText>
                                Updating this membership id will update the each membership in this group:
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12" className="ion-text-center">
                            <h4 className="font-weight-bold">Member Names:</h4>
                            {groupMemberships.map((m) => {
                                return <h6 className="font-weight-bold">{m.personName}</h6>
                            })}
                        </IonCol>
                    </IonRow>
                </IonContent>
                {isLoadingModal ? (
                    <Spinner />
                ) : (
                    <IonRow className="ion-justify-content-center ion-padding-top">
                        <IonCol size="6" className="ion-text-center">
                            <IonButton
                                id="membership-id-cancel-modal-btn"
                                expand="block"
                                color="light"
                                onClick={() => setShowMembershipIdModal(false)}
                            >
                                Cancel
                            </IonButton>
                        </IonCol>
                        <IonCol size="6" className="ion-text-center">
                            <IonButton
                                id="membership-id-update-modal-btn"
                                expand="block"
                                color="success"
                                onClick={updateMembershipId}
                            >
                                Update
                            </IonButton>
                        </IonCol>
                    </IonRow>
                )}
            </IonModal>
        </IonRow>
    );
};

export default EditMembershipApplicationBasicInfo;
