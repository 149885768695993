import { IonCol, IonRow, IonSearchbar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Person } from "../../models";
import { getAllPeople } from "../../utilities/person/Person";
import { formatRolesForDisplay } from "../../utilities/roles/FormatRoles";
import { Role } from "../../utilities/roles/Roles";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { Table } from "reactstrap";

var moment = require("moment");

const PeopleListEditor: React.FC = () => {
    const history = useHistory();

    const [people, setPeople] = useState<Person[] | null | undefined>();
    const [searchText, setSearchText] = useState("");
    const [filteredPeople, setFilteredPeople] = useState<Person[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    
    async function getPeople() {
        setIsLoading(true);
        const peopleResult = await getAllPeople();
        if (peopleResult.isSuccess) {
            const allPeople: Person[] = peopleResult.result;
            const sorted = allPeople.sort((a, b) => (a.lastName || "")?.localeCompare((b.lastName || "")));
            setPeople(sorted);
            setFilteredPeople(sorted);
            setError("");
            setIsLoading(false);
        } else {
            setError("Could not get the people from the database.");
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getPeople();
    }, []);

    const handleSearchInput = (input: string) => {
        // If search input is empty, reset to all accepted entries
        if (!input || input === "") {
            setFilteredPeople(people);
        } else {
            // Try search by entry number
            let result = people?.filter(person => {
                const found = (person.firstName?.toLowerCase().includes(input.toLowerCase()) ||
                person.lastName?.toLowerCase().includes(input.toLowerCase()) ||
                person.email?.toLowerCase().includes(input.toLowerCase()));
                return found;
            });
            setFilteredPeople(result);
        }
    }

    const handleSelectedPerson = (id: string) => {
        const path = "/index/admin/users/" + id;
        history.push(path);
    }

    return (
        <>
           {error && <ErrorAlert width="12" error={error} />}
           <IonRow>
                <IonCol sizeXs="12" sizeMd="12">
                    <IonSearchbar
                        color="white"  
                        placeholder="Search by Name or Email" 
                        value={searchText} 
                        onIonChange={e => {
                            setSearchText(e.detail.value!)
                            handleSearchInput(e.detail.value!)
                        }}
                    />
                </IonCol>
            </IonRow> 
            {isLoading ?
                <Spinner />
                :
                <>
                    <p>Total number of people: {people?.length || 0}</p>
                    {(filteredPeople && filteredPeople.length > 0) ?
                        <>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Linked</th>
                                        <th>Profile Picture</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Roles</th>
                                        <th>Date Created</th>
                                        <th>Date Updated</th>
                                        <th>Id</th>
                                        <th>Created By</th>
                                        <th>Stripe Id</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredPeople.map((person, index) => (
                                        <tr key={index} onClick={() => handleSelectedPerson(person.id)}>
                                            <td>
                                                <p className="ion-text-wrap">{""}</p>
                                            </td>
                                            <td>
                                                <img className="small-img" alt="profile" src={person.pictureURL || "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjYzVkYmZmIiBkPSJNMCAwaDUxMnY1MTJIMHoiLz48cGF0aCBkPSJNMjU2IDMwNGM2MS42IDAgMTEyLTUwLjQgMTEyLTExMlMzMTcuNiA4MCAyNTYgODBzLTExMiA1MC40LTExMiAxMTIgNTAuNCAxMTIgMTEyIDExMnptMCA0MGMtNzQuMiAwLTIyNCAzNy44LTIyNCAxMTJ2NTZoNDQ4di01NmMwLTc0LjItMTQ5LjgtMTEyLTIyNC0xMTJ6IiBmaWxsPSIjODJhZWZmIi8+PC9zdmc+"} />
                                            </td>
                                            <td>
                                                <p className="ion-text-wrap">{person.firstName || ""}</p>
                                            </td>
                                            <td>
                                                <p className="ion-text-wrap">{person.lastName || ""}</p>
                                            </td>
                                            <td>
                                                <p className="ion-text-wrap">{person.email}</p>
                                            </td>
                                            <td>
                                                {formatRolesForDisplay(person.roles ? person.roles: "")?.map((role: Role, index: number) => (
                                                    <span className="ion-text-wrap" key={index}>{role?.name} </span>
                                                ))}
                                            </td>
                                            <td>
                                                <p className="ion-text-wrap">{moment(person.createdOn).format("MMM Do YYYY")}</p>
                                            </td>
                                            <td>
                                                <p className="ion-text-wrap">{moment(person.updatedOn).format("MMM Do YYYY")}</p>
                                            </td>
                                            <td>
                                                <p className="ion-text-wrap">{person.id}</p>
                                            </td>
                                            <td>
                                                <p className="ion-text-wrap">{""}</p>
                                            </td>
                                            <td>
                                                <p className="ion-text-wrap">{person.stripeId}</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                        :
                        <>
                            <p>No people found.</p>
                        </>
                    }
                </>
            }
        </>
    );
};

export default PeopleListEditor;
