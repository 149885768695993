import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, EventEntry, BeddingRequest } from "../../../models";
import { getEventEntryById } from "../../../utilities/eventEntry/EventEntry";

interface _Props {
    event: Event
    beddingRequest: BeddingRequest
}

const ViewBeddingRequestCard: React.FC<_Props> = ({beddingRequest, event}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    return (
        <>
            <IonCard>
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h2>Bedding Request</h2>
                            <h4>Total: ${beddingRequest.totalPrice?.toFixed(2)}</h4>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent className="bg-white">
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12" className="ion-text-center">
                                <h2>Request Status: {beddingRequest.status}</h2>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonRow>
                                    <IonCol>
                                        <h2>Total Price: ${beddingRequest.totalPrice?.toFixed(2)}</h2>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <h4>Base Price for bedding: ${beddingRequest.basePrice?.toFixed(2)}</h4>
                                        <h4>Quantity: {beddingRequest.quantityNeeded}</h4>
                                        <h4>Tax Rate: {beddingRequest.taxA || 0}%</h4>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                            <IonCol>
                                <IonRow>
                                    <IonCol>
                                        <h2>Trainer: {beddingRequest.trainer?.name}</h2>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <h2>Stable With: {beddingRequest.barn?.name}</h2>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default ViewBeddingRequestCard;