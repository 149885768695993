import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateDivisionInput, CreateDivisionMutation, DeleteDivisionInput, DeleteDivisionMutation, GetDivisionQuery, ListDivisionsQuery, UpdateDivisionInput, UpdateDivisionMutation } from "../../API";
import moment from "moment";
import { Division } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: division. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created division.
*/
export async function createDivision(input: CreateDivisionInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "Division", "No input", "Create Division received no input.");
    try {
        const fullInput: CreateDivisionInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createDivision, { input: fullInput }))) as GraphQLResult<CreateDivisionMutation>;
        else result = (await API.graphql({
            query: mutations.createDivision,
            variables: {
                input: fullInput
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<CreateDivisionMutation>;
        const division = result.data?.createDivision;
        return formatResult(true, "Division", division, "Successfully created the division.");
    } catch (error: any) {
        return formatResult(false, "Division", error, "Error creating record in the database for type: division");
    }
}

/**
* Handle updating a new record in the database for type: division. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated division.
*/
export async function updateDivision(input: UpdateDivisionInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Division", "No input", "Update Division received no input.");
    try {
        const fullInput: UpdateDivisionInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateDivision, { input: fullInput }))) as GraphQLResult<UpdateDivisionMutation>;
        else result = (await API.graphql({
            query: mutations.updateDivision,
            variables: {
                input: fullInput
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<UpdateDivisionMutation>;
        const division = result.data?.updateDivision;
        return formatResult(true, "Division", division, "Successfully updated the division.");
    } catch (error: any) {
        return formatResult(false, "Division", error, "Error updating record in the database for type: division");
    }
}

/**
* Handle deleting a new record in the database for type: division. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted division.
*/
export async function deleteDivision(input: DeleteDivisionInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Division", "No input", "Delete Division received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteDivision, { input: input }))) as GraphQLResult<DeleteDivisionMutation>;
       else result = (await API.graphql({
           query: mutations.deleteDivision,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteDivisionMutation>;
       const division = result.data?.deleteDivision;
       return formatResult(true, "Division", division, "Successfully deleted the division.");
   } catch (error: any) {
       return formatResult(false, "Division", error, "Error deleting record in the database for type: division");
   }
}

/**
* Get all records in the database for type: division. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the division object
*/
export async function getAllDivisions(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listDivisions,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListDivisionsQuery>;

        let items = result.data?.listDivisions?.items as Division[];
        let nextToken = result.data?.listDivisions?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listDivisions,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListDivisionsQuery>;

            const nextItems = nextResult.data?.listDivisions?.items as Division[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listDivisions?.nextToken;
        }

        return formatResult(true, "Division", items, "Successfully got the Divisions.");
    } catch (error: any) {
        return formatResult(false, "Division", error, "Error reading record in the database for type: divisions");
    }
}

/**
* Read a specific record in the database for type: division. 
* 
* @param {string}  id                  The division id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the division object
*/
export async function getDivisionById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getDivision,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetDivisionQuery>;
       const division = result.data?.getDivision;
       return formatResult(true, "Division", division, "Successfully got the division.");
   } catch (error: any) {
       return formatResult(false, "Division", error, "Error reading record in the database for type: division");
   }
}

/**
* Get all records in the database that match the given criteria for type: division. 
* 
* @param {string}  personId            The person id of the creator of the division
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the division object
*/
export async function getDivisionsByCreator(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {createdBy: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listDivisions,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListDivisionsQuery>;

        let items = result.data?.listDivisions?.items as Division[];
        let nextToken = result.data?.listDivisions?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listDivisions,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListDivisionsQuery>;

            const nextItems = nextResult.data?.listDivisions?.items as Division[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listDivisions?.nextToken;
        }

        return formatResult(true, "Division", items, "Successfully got the divisions.");
    } catch (error: any) {
        return formatResult(false, "Division", error, "Error reading record in the database for type: divisions");
    }
}