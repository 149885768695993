import { updateOwner } from "../owner/Owner";
import { Result } from "../../interfaces/Result";
import { updateHorse } from "../horse/Horse";
import { createContact, updateContact } from "../contact/Contact";
import { updateRider } from "../rider/Rider";
import { updateTrainer } from "../trainer/Trainer";
import { createEventEntry, updateEventEntry } from "./EventEntry";
import { Barn, Contact, EventEntry, Horse, Membership, Owner, Rider, Trainer } from "../../models";
import { CreateContactInput, CreateEventEntryInput, UpdateContactInput, UpdateEventEntryInput, UpdateHorseInput, UpdateOwnerInput, UpdateRiderInput, UpdateTrainerInput } from "../../API";
import { formatTwilioNumber } from "../contact/FormatPhoneNumber";
import { getRiderAndHorseActiveMemberships } from "../membership/EventEntryMembership";
import constants from "../../constant/constant";

export interface entryInfoInterface {
    userId: string,
    eventId: string,
    organizationId: string,
    horseName: string
    horseHeight: number
    ownerName: string
    ownerLocation?: string
    riderName: string
    riderLocation?: string
    riderAge: number
    isProfessional?: boolean
    trainerName: string
    barnName?: string
    primaryContactName: string
    primaryContactEmail: string
    primaryContactPhone: string
    primaryContactPhoneNumberType?: string
    currentPersonId: string
    currentEntry?: EventEntry,
    currentHorse?: Horse,
    currentOwner?: Owner,
    currentTrainer?: Trainer,
    currentRider?: Rider,
    currentRider2?: Rider,
    currentContact?: Contact,
    currentBarn?: Barn
}

export async function createEntryInfo(input: entryInfoInterface): Promise<Result> {
    let backNumber: number | undefined = undefined;
   
    // For RHSC, try to match the entry to an active membership and auto-assign a back number
    if (input.organizationId === constants.RHSC_ORGANIZATION.id) {
        if (input.currentRider && input.currentHorse) {
            const membershipList: Membership[] = await getRiderAndHorseActiveMemberships(input.currentRider, input.currentHorse, input.organizationId);
            if (membershipList && membershipList.length > 0) {
                for (let i = 0; i < membershipList.length; i++) {
                    const mem = membershipList[i];
                    if (mem.backNumber) {
                        backNumber = mem.backNumber;
                        break;
                    }
                }
            }
        }
    }
    
    // create entry with status = in_progress
    const eventEntryInput: CreateEventEntryInput = {
        createdBy: input.userId,
        eventId: input.eventId,
        status: "in_progress",
        number: backNumber,
        personId: input.currentPersonId, 
        contactId: input.currentContact?.id || "", 
        riderId: input.currentRider?.id, 
        riderName: input.currentRider?.name, 
        horseId: input.currentHorse?.id, 
        horseName: input.currentHorse?.name, 
        ownerId: input.currentOwner?.id, 
        ownerName: input.currentOwner?.name, 
        trainerId: input.currentTrainer?.id,
        trainerName: input.currentTrainer?.name,
        barnName: input.barnName,
        barnId: input.currentBarn?.id,
        secondRiderId: input.currentRider2?.id, 
        secondRiderName: input.currentRider2?.name, 
    };
    const entryResult = await createEventEntry(eventEntryInput);
    if (entryResult.isSuccess) {
        let result: Result = {isSuccess: true, type: "EventEntry", result: entryResult.result, message: "Successfully created the event entry!"};
        return result;
    } else {
        let result: Result = {isSuccess: false, type: "EventEntry", result: entryResult.result, message: "Could not create the entry. Please contact hello@ringsidepro.com for help."};
        return result;
    }
}

export async function updateEntryInfo(input: entryInfoInterface): Promise<Result> {
    let result: Result = {isSuccess: false, type: "EventEntry", result: null, message: "Could not update the entry. Please contact hello@ringsidepro.com for help."};

    // First, update the contact
    let contact: Contact | null | undefined;
    if (input.currentContact) {
        const contactInput: UpdateContactInput = {
            id: input.currentContact.id,
            name: input.primaryContactName,
            cell: input.primaryContactPhoneNumberType === "cell" ? formatTwilioNumber(input.primaryContactPhone) : undefined,
            home: input.primaryContactPhoneNumberType === "home" ? formatTwilioNumber(input.primaryContactPhone) : undefined,
            work: input.primaryContactPhoneNumberType === "work" ? formatTwilioNumber(input.primaryContactPhone) : undefined,
            personalEmail: input.primaryContactEmail
        };
        const contactResult = await updateContact(contactInput);
        if (contactResult.isSuccess) {
            contact = contactResult.result;
        } else {
            let result: Result = {isSuccess: false, type: "Contact", result: contactResult, message: "Could not update the primary contact. Please contact hello@ringsidepro.com for help."};
            return result;
        }
    } else {
        // If there was no contact, attach a new contact
        const contactInput: CreateContactInput = {
            name: input.primaryContactName,
            cell: input.primaryContactPhoneNumberType === "cell" ? formatTwilioNumber(input.primaryContactPhone) : undefined,
            home: input.primaryContactPhoneNumberType === "home" ? formatTwilioNumber(input.primaryContactPhone) : undefined,
            work: input.primaryContactPhoneNumberType === "work" ? formatTwilioNumber(input.primaryContactPhone) : undefined,
            personalEmail: input.primaryContactEmail
        };
        const contactResult = await createContact(contactInput);
        if (contactResult.isSuccess) {
            if (input.currentEntry) {
                const eventEntryInput: UpdateEventEntryInput = {
                    id: input.currentEntry.id,
                    eventId: input.eventId,
                    status: "in_progress",
                    contactId: contactResult.result.id, 
                    lastUpdatedBy: input.userId
                };
                await updateEventEntry(eventEntryInput);
            }
        } else {
            let result: Result = {isSuccess: false, type: "Contact", result: contactResult, message: "Could not update the primary contact. Please contact hello@ringsidepro.com for help."};
            return result;
        }
    }

    // Second, update owner
    if (input.currentOwner) {
        const updateOwnerInput: UpdateOwnerInput = {
            id: input.currentOwner.id,
            name: input.ownerName,
            location: input.ownerLocation
        };
        const ownerNameResult = await updateOwner(updateOwnerInput);
        if (!ownerNameResult.isSuccess) {
            let result: Result = {isSuccess: false, type: "Owner", result: ownerNameResult, message: "Could not update the horse owner. Please contact hello@ringsidepro.com for help."};
            return result;
        }
    }
    // Third, update horse
    if (input.currentHorse) {
        const horseUpdateInput: UpdateHorseInput = {
            id: input.currentHorse.id,
            name: input.horseName,
            height: input.horseHeight.toString(),
            ownerId: input.currentOwner?.id || "",
        };
        const horseResult = await updateHorse(horseUpdateInput);
        if (!horseResult.isSuccess) {
            let result: Result = {isSuccess: false, type: "Horse", result: horseResult, message: "Could not update the horse. Please contact hello@ringsidepro.com for help."};
            return result;
        }
    }
    // Fourth, update rider
    if (input.currentRider) {
        const riderInput: UpdateRiderInput = {
            id: input.currentRider.id,
            name: input.riderName,
            age: input.riderAge,
            location: input.riderLocation,
            riderContactId: input.currentRider.contact?.id,
            isProfessional: input.isProfessional
        };
        const riderResult = await updateRider(riderInput);
        if (!riderResult.isSuccess) {
            let result: Result = {isSuccess: false, type: "Rider", result: riderResult, message: "Could not update the rider. Please contact hello@ringsidepro.com for help."};
            return result;
        }
    }
    // Fifth, update secondary rider
    if (input.currentRider2) {
        const riderInput: UpdateRiderInput = {
            id: input.currentRider2.id,
            name: input.currentRider2.name,
            age: input.currentRider2.age,
            location: input.currentRider2.location,
            riderContactId: input.currentRider2.contact?.id,
            isProfessional: input.currentRider2.isProfessional
        };
        const riderResult = await updateRider(riderInput);
        if (!riderResult.isSuccess) {
            let result: Result = {isSuccess: false, type: "Rider", result: riderResult, message: "Could not update the rider. Please contact hello@ringsidepro.com for help."};
            return result;
        }
    }
    // Sixth, update trainer
    if (input.currentTrainer) {
        const updateTrainerInput: UpdateTrainerInput = {
            id: input.currentTrainer.id,
            name: input.trainerName,
            barnName: input.barnName
        };
        const trainerNameResult = await updateTrainer(updateTrainerInput);
        if (!trainerNameResult.isSuccess) {
            let result: Result = {isSuccess: false, type: "Trainer", result: trainerNameResult, message: "Could not update the trainer. Please contact hello@ringsidepro.com for help."};
            return result;
        }
    }
    // Seventh, update entry with status = in_progress
    let entryResult;
    if (input.currentEntry) {
        let eventEntryInput: UpdateEventEntryInput = {
            id: input.currentEntry.id,
            eventId: input.eventId,
            status: "in_progress",
            personId: input.currentPersonId, 
            riderId: input.currentRider?.id, 
            riderName: input.currentRider?.name, 
            horseId: input.currentHorse?.id, 
            horseName: input.currentHorse?.name, 
            ownerId: input.currentOwner?.id, 
            ownerName: input.currentOwner?.name, 
            trainerId: input.currentTrainer?.id,
            trainerName: input.currentTrainer?.name,
            barnName: input.barnName,
            barnId: input.currentBarn?.id,
            secondRiderId: input.currentRider2?.id, 
            secondRiderName: input.currentRider2?.name, 
            lastUpdatedBy: input.userId
        };
        if (contact && contact.id) eventEntryInput["contactId"] = contact.id;
        entryResult = await updateEventEntry(eventEntryInput);
        if (entryResult.isSuccess) {
            let result: Result = {isSuccess: true, type: "EventEntry", result: entryResult.result, message: "Successfully updated the event entry!"};
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "EventEntry", result: entryResult.result, message: "Could not update the entry. Please contact hello@ringsidepro.com for help."};
            return result;
        }
    }
    return result;
}