export function formatJumpHeightString(jumpHeightStringArray: (string | null)[]) {
    let result = "";
    for (let i = 0; i < jumpHeightStringArray.length; i++) {
        const current = jumpHeightStringArray[i];
        if (current) {
            result = result + current;
            if (i !== jumpHeightStringArray.length-1) {
                result = result + ", ";
            }
        }
    }
    return result;
}