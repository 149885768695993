import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Spinner from "../Spinners/Spinner";
import { EventEntry, Person, Owner, Barn } from "../../models";
import { Table } from "reactstrap";
import moment from "moment";
import { getOwnersByPersonId, getOwnersByName, updateOwner } from "../../utilities/owner/Owner";
import { formatDisplayName } from "../../utilities/person/PersonNameFormat";
import { getPersonByPersonId } from "../../utilities/person/Person";
import { formatAddress } from "../../utilities/address/FormatAddress";
import { getEventEntriesByOwnerId } from "../../utilities/eventEntry/EventEntry";
import { UpdateOwnerInput } from "../../API";
import SelectFromAllBarns from "../Barn/SelectFromAllBarns";
import SelectFromAllPeople from "../Person/SelectFromAllPeople";

interface FormattedOwner {
    ownerId: string
    ownerName: string
    personId: string
    personName: string
    createdById: string
    createdByName: string
    birthdate: string
    age: string
    isProfessional: string
    barnId: string
    barnName: string
    contactId: string
    contactString: string
    location: string
    addressId: string
    addressString: string
    memberships: string
    entryCount: string,
    entries: string,
    showEntries: boolean,
    createdOn: string
    updatedOn: string
}
interface _Props {
    person: Person
}

const tableColumns = [
    "Owner (Id)",
    "Owner (Name)",
    "Person (Id)", 
    "Person (Name)", 
    "Select Person",
    "Created By (Id)", 
    "Created By (Name)", 
    "Birthdate",
    "Age",
    "Is Professional?",
    "Barn Id",
    "Barn Name",
    "Select Barn",
    "Contact Id",
    "Contact",
    "Location",
    "Address Id",
    "Address",
    "Memberships",
    "Entries",
    "Created On",
    "Updated On"
];

const AdminEditOwnersTable: React.FC<_Props> = ({person}) => {    
    
    const [formattedOwners, setFormattedOwners] = useState<FormattedOwner[] | undefined | null>();     
    const [isLoading, setIsLoading] = useState(false);

    const getOwners = async (person: Person) => {
        setIsLoading(true);
        let allOwners: Owner[] = [];

        // Get owners that match this person's id
        const ownersQueryResult = await getOwnersByPersonId(person.id);
        if (ownersQueryResult.isSuccess) {
            const ownerArray: Owner[] = ownersQueryResult.result;
            allOwners = allOwners.concat(ownerArray);
        }

        // Get owners that match 
        const name = formatDisplayName(person.firstName || "", "", person.lastName || "");
        const ownersByNameQueryResult = await getOwnersByName(name);
        if (ownersByNameQueryResult.isSuccess) {
            const ownerArray: Owner[] = ownersByNameQueryResult.result;
            ownerArray.forEach((owner) => {
                const foundOwnerInAllArray = allOwners.find((o: Owner) => (owner.id === o.id));
                if (!foundOwnerInAllArray) {
                    allOwners.push(owner);
                } 
            });
        }

        const formattedOwnerList = await formatOwners(allOwners);
        setFormattedOwners(formattedOwnerList);

        setIsLoading(false);
    }

    const formatOwners = async (allOwners: Owner[]) => {
        let formattedOwners: FormattedOwner[] = [];
        for (let i = 0; i < allOwners.length; i++) {
            const currentOwner = allOwners[i];
            const formattedOwner: FormattedOwner = await formatOwner(currentOwner);
            formattedOwners.push(formattedOwner);
        }
        return formattedOwners;
    }

    const formatOwner = async (currentOwner: Owner) => {
        const personId = currentOwner.personId;
        let personName = "";
        if (personId) {
            if (personId === person.id) {
                personName = formatDisplayName(person.firstName || "", "", person.lastName || "");
            }
            else {
                const getPersonQueryResult = await getPersonByPersonId(personId);
                if (getPersonQueryResult.isSuccess) {
                    const newPerson: Person = getPersonQueryResult.result;
                    personName = formatDisplayName(newPerson.firstName || "", "", newPerson.lastName || "");
                }
            }
        }

        const createdById = currentOwner.createdBy;
        let createdByName = "";
        if (createdById) {
            if (createdById === person.id) {
                createdByName = formatDisplayName(person.firstName || "", "", person.lastName || "");
            }
            else {
                const getPersonQueryResult = await getPersonByPersonId(createdById);
                if (getPersonQueryResult.isSuccess) {
                    const newPerson: Person = getPersonQueryResult.result;
                    createdByName = formatDisplayName(newPerson.firstName || "", "", newPerson.lastName || "");
                }
            }
        }

        const formattedContact = 
        "Personal Email: " + currentOwner.contact?.personalEmail +
        "\nCell: " + currentOwner.contact?.cell +
        "\nHome: " + currentOwner.contact?.home +
        "\nWork: " + currentOwner.contact?.work +
        "\nMailing: " + currentOwner.contact?.mailingAddress;

        const fomattedAddress = currentOwner.address ? formatAddress(currentOwner.address) : "";

        let memberships = "";
        if (currentOwner.memberships && currentOwner.memberships.length > 0) {
            currentOwner.memberships?.forEach((membership) => {
                memberships = membership + (membership?.organizationName || "") + "; ";
            })
        }

        let entries = "";
        let entryCount = "";
        const entriesQuery = await getEventEntriesByOwnerId(currentOwner.id);
        if (entriesQuery.isSuccess) {
            const entriesList: EventEntry[] = entriesQuery.result;
            if (entriesList && entriesList.length > 0) {
                entryCount = "# entries: " + entriesList.length.toString();
                entriesList.forEach((entry) => {
                    entries = entries + entry.eventId + ";\n";
                })
            }
        }

        let formattedOwner: FormattedOwner = {
            ownerId: currentOwner.id,
            ownerName: currentOwner.name,
            personId: personId || "",
            personName: personName,
            createdById: createdById || "",
            createdByName: createdByName,
            birthdate: "n/a",
            age: "n/a",
            isProfessional: "n/a",
            barnId: currentOwner.barnId || "",
            barnName: "n/a",
            contactId: currentOwner.contact?.id || "",
            contactString: formattedContact,
            location: currentOwner.location || "",
            addressId: currentOwner.addressId || "",
            addressString: fomattedAddress,
            memberships: memberships,
            entryCount: entryCount,
            entries: entries,
            showEntries: false,
            createdOn: moment(currentOwner.createdOn).format("MM-DD-YYYY"),
            updatedOn: moment(currentOwner.updatedOn).format("MM-DD-YYYY")
        };
        return formattedOwner;
    }

    useEffect(() => {
        if (person) {
            getOwners(person);
        }
    }, [person]);
    
    const handleSelectPerson = async (ownerId: string, person?: Person) => {
        const updateOwnerInput: UpdateOwnerInput = {
            id: ownerId,
            personId: person?.id || ""
        };
        const updateResult = await updateOwner(updateOwnerInput);
        if (updateResult.isSuccess) {
            const updatedOwner: Owner = updateResult.result;
            if (formattedOwners) {
                const formattedOwner: FormattedOwner = await formatOwner(updatedOwner);
                const index = formattedOwners?.findIndex(formattedOwner => formattedOwner.ownerId === updatedOwner.id);
                if (index > -1) {
                    const updatedList: FormattedOwner[] = [
                        ...formattedOwners.slice(0, index),
                        formattedOwner,
                        ...formattedOwners.slice(index + 1),
                    ];
                    setFormattedOwners(updatedList);
                }
            }
        }
    }

    const handleSelectBarn = async (ownerId: string, barn?: Barn) => {
        const updateOwnerInput: UpdateOwnerInput = {
            id: ownerId,
            barnId: barn?.id || ""
        };
        const updateResult = await updateOwner(updateOwnerInput);
        if (updateResult.isSuccess) {
            const updatedOwner: Owner = updateResult.result;
            if (formattedOwners) {
                const formattedOwner: FormattedOwner = await formatOwner(updatedOwner);
                const index = formattedOwners?.findIndex(formattedOwner => formattedOwner.ownerId === updatedOwner.id);
                if (index > -1) {
                    const updatedList: FormattedOwner[] = [
                        ...formattedOwners.slice(0, index),
                        formattedOwner,
                        ...formattedOwners.slice(index + 1),
                    ];
                    setFormattedOwners(updatedList);
                }
            }
        }
    }

    const handleClickShowEntries = (formattedOwner: FormattedOwner) => {
        const updatedFormattedOwner: FormattedOwner = {
            ...formattedOwner,
            showEntries: !formattedOwner.showEntries
        };
        if (formattedOwners) {
            const index = formattedOwners?.findIndex(x => formattedOwner.ownerId === x.ownerId);
            if (index > -1) {
                const updatedList: FormattedOwner[] = [
                    ...formattedOwners.slice(0, index),
                    updatedFormattedOwner,
                    ...formattedOwners.slice(index + 1),
                ];
                setFormattedOwners(updatedList);
            }
        }
    }

    return (
        <>
            <IonCard mode="md" className="ion-padding bg-white">
                <IonCardTitle>Owner</IonCardTitle>
                <IonCardSubtitle><p className="description">Get Owners by personId and personName.</p></IonCardSubtitle>
                <IonCardContent>
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            {(formattedOwners && formattedOwners.length > 0) ?
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            {tableColumns.map((tc, i) => (
                                                <th key={i}>{tc}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formattedOwners.map((formattedOwner, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.ownerId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.ownerName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.personId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.personName}</p>
                                                </td>
                                                <td className="extra-jumbo-input-width">
                                                    <SelectFromAllPeople onSelect={(p: Person) => handleSelectPerson(formattedOwner.ownerId, p)} />
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.createdById}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.createdByName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.birthdate}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.age}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.isProfessional}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap"><a href={"/index/barn/info/" + formattedOwner.barnId}>{formattedOwner.barnId}</a></p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.barnName}</p>
                                                </td>
                                                <td className="extra-jumbo-input-width">
                                                    <SelectFromAllBarns 
                                                        showBarnIds
                                                        selectedValue={formattedOwner.barnId} 
                                                        isHeightRequired={true}
                                                        height={"120px"}
                                                        onSelect={(b: Barn) => handleSelectBarn(formattedOwner.ownerId, b)} 
                                                    />
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.contactId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.contactString}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.location}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.addressId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.addressString}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.memberships}</p>
                                                </td>
                                                <td onClick={() => handleClickShowEntries(formattedOwner)}>
                                                    <p className="ion-text-wrap">{formattedOwner.entryCount}</p>
                                                    {formattedOwner.showEntries && (
                                                        <p className="ion-text-wrap">{formattedOwner.entries}</p>
                                                    )}
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.createdOn}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{formattedOwner.updatedOn}</p>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                :
                                <p>No owners found.</p>
                            }
                        </>
                    }
                </IonCardContent>
            </IonCard>
        </>
    );
};

export default AdminEditOwnersTable;