import { IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { stateList } from "./States";

interface _Props {
    id?: string,
    label?: string,
    isRequired?: (boolean | null),
    selectedValue?: string,
    onInputChange: Function
    isTextDarker?: boolean
}

const SelectState: React.FC<_Props> = ({id, label, isRequired, selectedValue, onInputChange, isTextDarker}) => {
    const [provState, setProvState] = useState<string>(selectedValue || "-");

    useEffect(() => {
        if (selectedValue) setProvState(selectedValue.trim());
        else setProvState("-");
    }, [selectedValue]);

    return (
        <>
            <FormGroup>
                <IonLabel className={isTextDarker ? "description pl-3 text-darker" : "description pl-3"}>
                    {label || "State"} 
                    {isRequired && <span className="description text-danger">
                        *
                    </span>}
                </IonLabel>
                <Input 
                    id={id || "provState-input"}
                    name="provState" 
                    type="select" 
                    value={provState} 
                    onChange={e => {
                        setProvState(e.target.value);
                        onInputChange(e.target.value)
                    }}
                >
                    <option>-</option>
                    {stateList.map((state: {value: number, label: string}) => (
                        <option key={state.value}>{state.label}</option>
                    ))}
                </Input>
            </FormGroup>
        </>
    );
};

export default SelectState;