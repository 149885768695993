import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Event, EventEntry, EventEntryFee, Fee } from "../../../models";
import { getEventClassEntriesByEventIdEntryId } from "../../../utilities/eventClassEntry/EventClassEntry";
import { getEventEntryFeesByEventIdByEntryId } from "../../../utilities/eventEntryFee/EventEntryFee";
import { isAfterEventFeeStartDate } from "../../../utilities/eventEntryFee/StartDateEventFee";

interface _Props {
    entry: EventEntry
    event: Event
}

interface FormattedEventFee {
    id: string
    name: string
    amount: number
    quantity: number
    tax: string
    total: number
    isPerClass: boolean
    isAfterStartDate: boolean
    startDate?: string
    startTime?: string
}

const DisplayEntryFees: React.FC<_Props> = ({entry, event}) => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [totalEventFees, setTotalEventFees] = useState<number | null | undefined>();
    const [previousEventEntryFees, setPreviousEventEntryFees] = useState<FormattedEventFee[] | null | undefined>();

    const calculateFeeQuantity = async (eventEntryFee: EventEntryFee) => {
        const fee: Fee | null | undefined = eventEntryFee.fee;
        if (fee && fee.isPerClass) {
            const classQueryResult = await getEventClassEntriesByEventIdEntryId(event.id, entry.id);
            if (classQueryResult.isSuccess) {
                const classList = classQueryResult.result;
                return classList.length;
            }
        } 
        return eventEntryFee.quantity || 0;
    }
    
    const calculateFeeCost = (eventEntryFee: EventEntryFee, quantity: number) => {
        const rawAmount = eventEntryFee.amount;
        const taxA = eventEntryFee.fee ? (eventEntryFee.fee.taxA ? eventEntryFee.fee.taxA/100 : 0) : 0;
        const taxB = eventEntryFee.fee ? (eventEntryFee.fee.taxB ? eventEntryFee.fee.taxB/100 : 0) : 0;

        let total = rawAmount;
        total = total * quantity;
        if (taxA && taxB) total = total + (total * (taxA + taxB));
        else if (taxA) total = total + (total * taxA);
        else if (taxB) total = total + (total * taxB);

        return total
    }

    const calculateEventFeeTotal = (formattedEventEntryFees?: FormattedEventFee[]) => {
        let total = 0;
        formattedEventEntryFees?.forEach(eventEntryFee => {
            total = total + eventEntryFee.total;
        })
        setTotalEventFees(total);
    }

    const formatEventFees = async (eventEntryFees: EventEntryFee[]) => {
        let result: FormattedEventFee[] = [];
        for (let i = 0; i < eventEntryFees.length; i++) {
            const currentEventEntryFee = eventEntryFees[i];
            const fee = currentEventEntryFee.fee;
            const quantity = await calculateFeeQuantity(currentEventEntryFee);
            let formattedObject: FormattedEventFee = {
                id: currentEventEntryFee.id,
                name: currentEventEntryFee.name,
                amount: currentEventEntryFee.amount,
                quantity: quantity,
                tax: currentEventEntryFee.fee?.taxA ? currentEventEntryFee.fee?.taxA.toString() + "%" : "",
                total: calculateFeeCost(currentEventEntryFee, quantity),
                isPerClass: !!fee?.isPerClass,
                isAfterStartDate: !!fee?.isStartedOnEntryDate,
                startDate: fee?.isStartedOnEntryDate ? fee.startDate! : "",
                startTime: fee?.isStartedOnEntryDate ? fee.startTime! : ""
            };

            result.push(formattedObject);
        }

        setPreviousEventEntryFees(result);
        calculateEventFeeTotal(result);
    }

    const getEventEntryFeesByEntry = async (eventEntry: EventEntry) => {
        const queryResult = await getEventEntryFeesByEventIdByEntryId(eventEntry.eventId, eventEntry.id);
        if (queryResult.isSuccess) {
            await formatEventFees(queryResult.result);
        }
    }

    useEffect(() => {
        if (entry) getEventEntryFeesByEntry(entry);

    }, [event, entry]);

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {entry ? <IonRow><h3>Other Fees: {totalEventFees ? "$" + totalEventFees.toFixed(2) : ""}</h3></IonRow> : <h2>Loading other fees...</h2> }
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? <IonIcon icon={chevronDown} />  : <IonIcon icon={chevronUp} />}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        <IonList className="bg-white">
                            {previousEventEntryFees && (
                                <>
                                    {previousEventEntryFees.map((formattedEventFee, index) => (
                                        <IonItem key={index}>
                                            <IonLabel>
                                                <IonRow>
                                                    <IonCol>
                                                        <p>Name: {formattedEventFee.name} ({formattedEventFee.amount ? "$" + formattedEventFee.amount.toFixed(2) : ""})</p>
                                                    </IonCol>
                                                </IonRow>
                                                {formattedEventFee.isAfterStartDate && (
                                                    <IonRow>
                                                        <IonCol>
                                                            <p>Added to your entry because the entry was not submitted before: {moment(formattedEventFee.startDate + " " + formattedEventFee.startTime).format("MM-DD-YYYY h:mm a")}</p>
                                                        </IonCol>
                                                    </IonRow>
                                                )}
                                                <IonRow>
                                                    <IonCol>
                                                        <p>Quantity: {formattedEventFee.quantity} {formattedEventFee.isPerClass && <p>(is per class)</p>}</p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <p>Tax: {formattedEventFee.tax ? formattedEventFee.tax.toString() + "%" : "n/a"}</p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <p>Total: ${formattedEventFee.total.toFixed(2)}</p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                </>
                            )}
                            <IonItem key="total">
                                <IonLabel color="dark">
                                    <h3 className="font-weight-bold">
                                        Total: {totalEventFees ? "$" + totalEventFees.toFixed(2) : ""}
                                    </h3>
                                </IonLabel>
                            </IonItem>
                        </IonList>
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default DisplayEntryFees;