import {
    IonCheckbox,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { CreateEventRulesAgreementInput, UpdateEventEntryInput } from "../../API";
import { PersonContext } from "../../context/PersonContext";
import { Event, EventEntry, EventRules, EventRulesAgreement } from "../../models";
import { updateEventEntry } from "../../utilities/eventEntry/EventEntry";
import { createEventRulesAgreement, deleteEventRulesAgreement, getEventRulesAgreementsByEntryId } from "../../utilities/eventRules/EventRulesAgreement";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    event: Event
    eventRule: EventRules
    eventEntry?: EventEntry
    onSubmit: Function
}

const RuleAgreementForm: React.FC<_Props> = ({event, eventRule, eventEntry, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [isChecked, setIsChecked] = useState(false);
    const [previousAgreements, setPreviousAgreements] = useState<EventRulesAgreement[] | null | undefined>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getPreviousAgreements(eventEntry: EventEntry) {
            const queryResult = await getEventRulesAgreementsByEntryId(eventEntry.id);
            if (queryResult.isSuccess) {
                const previousAgreements: EventRulesAgreement[] = queryResult.result;
                if (previousAgreements.length > 0) {
                    setPreviousAgreements(previousAgreements);
                    let previousAgreement: EventRulesAgreement | null | undefined;
                    previousAgreements.forEach((previous: EventRulesAgreement) => {
                        if (previous.eventRule?.id === eventRule.id) previousAgreement = previous;
                    });
                    if (previousAgreement) {
                        setFirstName(previousAgreement.name ? previousAgreement.name.split(" ")[0] : "");
                        setLastName(previousAgreement.name ? previousAgreement.name.split(" ")[1] : "");
                        setIsChecked(true);
                    }
                }
            }
        }
        if (eventEntry) {
            clearForm();
            getPreviousAgreements(eventEntry);
        }
    }, [eventEntry]);

    const clearForm = () => {
        setFirstName("");
        setLastName("");
        setIsChecked(false);
    }

    const verifyForm = () => {
        if (!firstName) {
            setError("Please include your first name.");
            return false;
        }
        if (!lastName) {
            setError("Please include your last name.");
            return false;
        }
        return true;
    }

    const saveAgreement = async () => {
        if (previousAgreements) {
            // If already out there, need to delete previous
            let previousAgreement: EventRulesAgreement | null | undefined;
            previousAgreements.forEach(previous => {
                if (previous.eventRule?.id === eventRule.id) previousAgreement = previous;
            });
            if (previousAgreement) {
                await deleteEventRulesAgreement(previousAgreement);
            }
        } 

        // Create the new agreement
        const name = firstName + " " + lastName;
        const input: CreateEventRulesAgreementInput = {
            eventId: event.id,
            eventRuleId: eventRule.id,
            eventEntryId: eventEntry?.id,
            name: name,
            personId: user.id,
            personType: user.roles,
            createdBy: user.id,
            lastUpdatedBy: user.id
        };
        const createResult = await createEventRulesAgreement(input);
        if (!createResult.isSuccess) {
            setError("Error: could not save the agreement. Please contact hello@ringsidepro.com");
        }
        
        // Then update status of entry to in_progress
        if (eventEntry) {
            const eventEntryInput: UpdateEventEntryInput = {
                id: eventEntry.id,
                status: "in_progress"
            };
            const updateEntryResult = await updateEventEntry(eventEntryInput);
            if (!updateEntryResult.isSuccess) {
                setError(updateEntryResult.message);
            }
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setIsChecked(!isChecked);
        const isValid = verifyForm();
        if (isValid) {
            setError("");
            await saveAgreement();
            onSubmit(isChecked);
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {event && eventRule ?
                <IonRow>
                    {isLoading ?
                        <Spinner />
                        :
                        <IonCol size="12">
                            <IonRow>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">First Name</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={firstName}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setFirstName(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Last Name</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={lastName}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setLastName(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem>
                                        <IonCheckbox slot="start" checked={isChecked} color="success" onClick={() => handleSubmit()} />
                                        <IonLabel className="ion-text-wrap">
                                            <p className="text-primary">I have read and agree to the above rule.</p>
                                        </IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    }
                </IonRow>
                :
                <p>Loading agreement form...</p>
            }
        </>
    );
};

export default RuleAgreementForm;