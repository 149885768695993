import { Address, Contact, Membership, PersonalInformation, TeamMember } from "../../models";
import moment from "moment";
import { formatDisplayNameForUser } from "../person/PersonNameFormat";
import { getMembershipsByPersonId } from "../membership/Membership";
import { getPersonalInformationByPersonId } from "../personalInformation/PersonalInformation";
import { formatAddress } from "../address/FormatAddress";
import { downloadCSVFile } from "./ReportDownloadCSV";
require('jspdf-autotable');

export const headerRow = [
    "Name",
    "Arm Number",
    "Divsion",
    "Grade Level",
    "Status",
    "Date Joined",
    "Contact Person",
    "Phone Type",
    "Phone Number",
    "Address"
];

async function getMembership(teamMember: TeamMember) {
    const queryResult = await getMembershipsByPersonId(teamMember.personId);
    if (queryResult.isSuccess) {
        const memberships: Membership[] = queryResult.result;
        const membership = memberships.find((m => m.teamId === teamMember.team.id && m.membershipStatus === "complete" && !m.organizationMembershipType?.name.toLowerCase().includes("team")));
        return membership;
    }
}

export async function generateTeamRosterReport(teamMembers: TeamMember[]) {

    let rows = [];

    rows.push(headerRow);
    
    if (teamMembers && teamMembers.length > 0) {
        for (let i = 0; i < teamMembers.length; i++) {
            let currentRow: any[] = [];
            const teamMember = teamMembers[i];
            
            const name = teamMember.person ? formatDisplayNameForUser(teamMember.person) : "";

            const membership = await getMembership(teamMember);

            let contactName = "";
            let contact: Contact | undefined = undefined;
            let address: Address | undefined = undefined;

            if (membership?.contactPersonId) {
                const contactPersonalInformationQueryResult = await getPersonalInformationByPersonId(membership?.contactPersonId);
                if (contactPersonalInformationQueryResult.isSuccess) {
                    const personalInformation: PersonalInformation = contactPersonalInformationQueryResult.result;
                    contactName = personalInformation.contact?.name || "";
                    if (personalInformation.contact) contact = personalInformation.contact;
                    if (personalInformation.address) address = personalInformation.address;
                }
            }

            currentRow.push(name || "");
            currentRow.push(membership?.backNumber || "");
            currentRow.push(membership?.aelDivision || "");
            currentRow.push(membership?.gradeLevel || "");
            currentRow.push(membership?.membershipStatus || "");
            currentRow.push(teamMember.createdOn ? moment(teamMember.createdOn).format("MM/DD/YY h:mma") : "");
            currentRow.push(contactName || "");
            currentRow.push(contact?.cell ? "cell" : contact?.work ? "work" : contact?.home ? "home" : "");
            currentRow.push(contact?.cell ? contact?.cell : contact?.work ? contact?.work : contact?.home ? contact?.home : "");
            currentRow.push(address ? formatAddress(address) : "");

            rows.push(currentRow);
        }
    }

    const fileName = "Team-Roster.csv";
    downloadCSVFile(fileName, rows);
}