import "./../../theme/main.css";
import "./../../theme/images.css";

import {
    IonButton,
    IonButtons,
    IonHeader,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    isPlatform,
} from "@ionic/react";
import React from "react";
import logo from "./../../assets/img/brand/WhiteLogoAndBlock.png";
import { isWindows } from "../../utilities/platform/Platform";

const AuthNavbar: React.FC = () => {
    return (
        <IonHeader>
            <IonToolbar>
                {(isWindows() || (isPlatform("desktop"))) ? (
                    <>
                        <IonButtons slot="start">
                            <IonButton routerLink="/home">
                                <IonTitle>
                                    <img
                                        className="nav-img"
                                        alt="RingSide Pro"
                                        src={logo}
                                    />
                                </IonTitle>
                            </IonButton>
                            <IonButton routerLink="/home">
                                <p className="font-weight-normal text-white text-capitalize">
                                    Home
                                </p>
                            </IonButton>
                            <IonButton routerLink="/about">
                                <p className="font-weight-normal text-white text-capitalize">
                                    About
                                </p>
                            </IonButton>
                            <IonButton routerLink="/online-entries">
                                <p className="font-weight-normal text-white text-capitalize">
                                    Online Entry Software
                                </p>
                            </IonButton>
                            <IonButton routerLink="/text-alerts">
                                <p className="font-weight-normal text-white text-capitalize">
                                   Text Alert Software
                                </p>
                            </IonButton>
                            <IonButton routerLink="/online-clinics">
                                <p className="font-weight-normal text-white text-capitalize">
                                    Online Clinics
                                </p>
                            </IonButton>
                        </IonButtons>
                        <IonButtons slot="end">
                            <IonButton routerLink="/register">
                                <p className="font-weight-normal text-white text-capitalize">
                                    Create Account
                                </p>
                            </IonButton>
                            <IonButton routerLink="/login">
                                <p className="font-weight-normal text-white text-capitalize">
                                    Log In
                                </p>
                            </IonButton>
                        </IonButtons>
                    </>
                ) : (
                    <>
                        <IonButtons slot="start">
                            <IonButton routerLink="/">
                                <img
                                    className="nav-img"
                                    alt="RingSide Pro"
                                    src={logo}
                                />
                            </IonButton>
                        </IonButtons>
                        <IonButtons slot="end">
                            <IonMenuButton color="light" />
                        </IonButtons>
                    </>
                )}
            </IonToolbar>
        </IonHeader>
    );
};

export default AuthNavbar;
