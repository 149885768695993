import React from "react";
import { Post } from "../../models";
import { formatStartEndDatesStrings } from "../../utilities/dates/FormatDates";
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle
} from "@ionic/react";

interface _Props {
    selectedPost: (Post | null)
}

const DashboardPost: React.FC<_Props> = ({selectedPost}) => {
    return (
        <React.Fragment>
            {selectedPost && (
                <IonCard className="ion-card-post-info">
                    <IonCardHeader>
                        <IonCardTitle>{selectedPost.title}</IonCardTitle>
                        <IonCardSubtitle>Last updated on {formatStartEndDatesStrings(selectedPost.updatedOn)}</IonCardSubtitle>
                    </IonCardHeader>
          
                <IonCardContent>{selectedPost.content}</IonCardContent>
              </IonCard>
            )}
        </React.Fragment>
    );
};

export default DashboardPost;
