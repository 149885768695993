import React from "react";
import { IonButton, IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";

interface _Props {
    totalPageCount: number
    currentPage: number
    onSelectPage: Function
}

const PaginationButtons: React.FC<_Props> = ({currentPage, totalPageCount, onSelectPage}) => {

    const handleBackButton = () => {
        if (currentPage > 0) onSelectPage(currentPage-1);
    }

    const handleForwardButton = () => {
        if (currentPage < totalPageCount) onSelectPage(currentPage+1);
    }

    return (
        <IonRow >
            <IonCol sizeXs="4" sizeMd="2" sizeXl="1" offsetMd="3" offsetXl="4" className="ion-text-left">
                <IonButton onClick={handleBackButton} disabled={currentPage === 0}>
                    <IonIcon icon={chevronBackOutline} />
                </IonButton>
            </IonCol>
            <IonCol sizeXs="4" sizeMd="2" className="ion-text-center">
                <IonText>
                    <p className="mt-2">{totalPageCount === 0 ? 0 : currentPage + 1} of {totalPageCount}</p>
                </IonText>
            </IonCol>
            <IonCol sizeXs="4" sizeMd="2" sizeXl="1" className="ion-text-right">
                <IonButton onClick={handleForwardButton} disabled={(currentPage + 1) === totalPageCount}>
                    <IonIcon icon={chevronForwardOutline} />
                </IonButton>
            </IonCol>
        </IonRow>
    );
};

export default PaginationButtons;
