import {
    IonButton,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { getBirthdayByPersonId, updateBirthday  } from "../../utilities/personalInformation/BirthDate";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import { getRiderByPersonId, updateRider } from "../../utilities/rider/Rider";
import { UpdateRiderInput } from "../../API";
import moment from "moment";
import BirthdateInput from "../DatePicker/BirthdateInput";

const BirthDateForm: React.FC = () => {
    const user = useContext(PersonContext);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [birthday, setBirthday] = useState("");

    useEffect(() => {
        // Create a scoped async function in the hook
        async function setBirthDateInState() {
            setIsLoading(true);
            const birthdayResult = await getBirthdayByPersonId(user.id);
            if (birthdayResult.isSuccess) {
                const birthday = birthdayResult.result ? moment(birthdayResult.result).format("YYYY-MM-DD") : "";
                setBirthday(birthday);
            }
            setIsLoading(false);
        }
        // Execute the created function directly
        setBirthDateInState();
      }, [user.id]);

    const handleSubmit = async () => {
        setIsLoading(true);
        setIsDisabled(true);
        await updateBirthDate();
        setIsLoading(false);
    }

    const updateBirthDate = async () => {
        await updateBirthday(user.id, birthday);

        const queryResult = await getRiderByPersonId(user.id);
        if (queryResult.isSuccess) {
            const rider = queryResult.result;
            const riderInput: UpdateRiderInput = {
                id: rider.id,
                birthdate: moment(birthday).format("YYYY-MM-DD")
            }
            await updateRider(riderInput);
        }
    }; 

    return (
        <form>
            {isLoading ?
                <Spinner />
                :
                <>
                    <IonRow>
                        <IonCol>
                            <BirthdateInput value={birthday} isRequired={false} onChange={(date: string) => {setBirthday(date); setIsDisabled(false);}} />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonButton
                            disabled={isDisabled}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            {isDisabled ? "Saved" : "Save"}
                        </IonButton>
                    </IonRow>
                </>
            }
        </form>
    );
};

export default BirthDateForm;