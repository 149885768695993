import { formatTwilioNumber } from "../contact/FormatPhoneNumber";

export function getAreaCode(phoneNumber: string) {
    if (phoneNumber) {
        const twilioNumber = formatTwilioNumber(phoneNumber);

        const areaCode = twilioNumber.slice(2, 5);

        return areaCode;
    } else {
        return null;
    }
}

export const canadianAreaCodes = [
    '204',
    '226',
    '236',
    '249',
    '250',
    '289',
    '306',
    '343',
    '365',
    '367',
    '403',
    '416',
    '418',
    '431',
    '437',
    '438',
    '450',
    '506',
    '514',
    '519',
    '548',
    '579',
    '581',
    '587',
    '604',
    '613',
    '639',
    '647',
    '705',
    '709',
    '778',
    '780',
    '782',
    '807',
    '819',
    '825',
    '867',
    '873',
    '902',
    '905'
];