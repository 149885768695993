import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PointTable, PointTier } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import SuccessBanner from "../Banners/SuccessBanner";
import { createPointTable, deletePointTable, updatePointTable } from "../../utilities/pointTable/PointTable";
import { CreatePointTableInput, UpdatePointTableInput } from "../../API";
import { FormGroup, Input } from "reactstrap";
import BaseValuesTable from "./BaseValuesTable";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    organizationId: string
    pointTable?: PointTable
    tableName?: string
    onSubmit: Function
}

const PointTableForm: React.FC<_Props> = ({pointTable, tableName, organizationId, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [first, setFirst] = useState(30);
    const [second, setSecond] = useState(22);
    const [third, setThird] = useState(15);
    const [fourth, setFourth] = useState(12);
    const [fifth, setFifth] = useState(11);
    const [sixth, setSixth] = useState(10);
    const [seventh, setSeventh] = useState(0);
    const [eighth, setEighth] = useState(0);
    const [ninth, setNinth] = useState(0);
    const [tenth, setTenth] = useState(0);
    const [eleventh, setEleventh] = useState(0);
    const [twelfth, setTwelfth] = useState(0);
    const [champion, setChampion] = useState(0);
    const [reserve, setReserve] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (tableName) {
            setName(tableName)
        }
        if (pointTable) {
            if (pointTable.name) setName(pointTable.name);
            if (pointTable.description) setDescription(pointTable.description);
            if (pointTable.tiers && pointTable.tiers.length > 0) {
                const firstTier = pointTable.tiers[0];
                if ((firstTier && firstTier.first)) setFirst(parseFloat(firstTier.first));
                if ((firstTier && firstTier.second)) setSecond(parseFloat(firstTier.second));
                if ((firstTier && firstTier.third)) setThird(parseFloat(firstTier.third));
                if ((firstTier && firstTier.fourth)) setFourth(parseFloat(firstTier.fourth));
                if ((firstTier && firstTier.fifth)) setFifth(parseFloat(firstTier.fifth));
                if ((firstTier && firstTier.sixth)) setSixth(parseFloat(firstTier.sixth));
                if ((firstTier && firstTier.seventh)) setSeventh(parseFloat(firstTier.seventh));
                if ((firstTier && firstTier.eighth)) setEighth(parseFloat(firstTier.eighth));
                if ((firstTier && firstTier.ninth)) setNinth(parseFloat(firstTier.ninth));
                if ((firstTier && firstTier.tenth)) setTenth(parseFloat(firstTier.tenth));
                if ((firstTier && firstTier.eleventh)) setEleventh(parseFloat(firstTier.eleventh));
                if ((firstTier && firstTier.twelfth)) setTwelfth(parseFloat(firstTier.twelfth));
                if ((firstTier && firstTier.champion)) setChampion(parseFloat(firstTier.champion));
                if ((firstTier && firstTier.reserve)) setReserve(parseFloat(firstTier.reserve));
            }
            setSuccess("");
            setError("");
        }
    }, [pointTable, tableName]);

    const verifyForm = () => {
        try {
            if (!name) {
                setError("You must include a name.");
                return false;
            }

            return true;
        } catch (error) {
            setError("Sorry, an error occurred");
            return false;
        }
    }

    const clearForm = () => {
        setName("");
        setDescription("");
        setError("");
    } 

    const handleTableChange = (place: string, value: number) => {
        if (place === "first") setFirst(value);
        if (place === "second") setSecond(value);
        if (place === "third") setThird(value);
        if (place === "fourth") setFourth(value);
        if (place === "fifth") setFifth(value);
        if (place === "sixth") setSixth(value);
        if (place === "seventh") setSeventh(value);
        if (place === "eighth") setEighth(value);
        if (place === "ninth") setNinth(value);
        if (place === "tenth") setTenth(value);
        if (place === "eleventh") setEleventh(value);
        if (place === "twelfth") setTwelfth(value);
        if (place === "champion") setChampion(value);
        if (place === "reserve") setReserve(value);
    }

    const handleCreateTiers = () => {
        const tiers: PointTier = {
            first: first.toString(),
            second: second.toString(),
            third: third.toString(),
            fourth: fourth.toString(),
            fifth: fifth.toString(),
            sixth: sixth.toString(),
            seventh: seventh.toString(),
            eighth: eighth.toString(),
            ninth: ninth.toString(),
            tenth: tenth.toString(),
            eleventh: eleventh.toString(),
            twelfth: twelfth.toString(),
            champion: champion.toString(),
            reserve: reserve.toString(),
        };
        return [tiers];
    }

    const handleCreatePointTable = async () => {
        try {
            const tiers = handleCreateTiers();
            const input: CreatePointTableInput = {
                createdBy: user.id,
                name: name,
                description: description,
                organizationId: organizationId,
                tiers: tiers
            };
            const createResult = await createPointTable(input);
            if (createResult.isSuccess) {
                clearForm();
                const message = "Successfully created the prize money table: " + name;
                setSuccess(message);
                onSubmit(createResult.result);
            } else {
                setError(createResult.message);
            }
        } catch (error) {
            setError("Sorry, an error occurred.");
        }
    }

    const handleEditPointTable = async () => {
        try {
            if (pointTable) {
                const tiers = handleCreateTiers();
                const input: UpdatePointTableInput = {
                    id: pointTable.id,
                    name: name,
                    description: description,
                    organizationId: organizationId,
                    tiers: tiers
                };
                const updateResult = await updatePointTable(input);
                if (updateResult.isSuccess) {
                    onSubmit(updateResult.result);
                    clearForm();
                    const message = "Successfully updated the prize money table: " + name;
                    setSuccess(message);
                } else {
                    setError(updateResult.message);
                }
            } else {
                setError("Sorry, an error occurred. Please refresh and try again.");
            }
        } catch (error) {
            setError("Sorry, an error occurred.");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setSuccess("");
        const isValid = verifyForm();
        if (isValid) {
            setError("");
            if (pointTable) {
                await handleEditPointTable();
            } else {
                await handleCreatePointTable();
            }
        }
        setIsDisabled(true);
        setIsLoading(false);
    }

    const handleDelete = async () => {
        setIsLoading(true);
        if (pointTable) {
            const deleteResult = await deletePointTable({id: pointTable.id});
            if (deleteResult.isSuccess) {
                clearForm();
                setSuccess("Deleted the Prize Money Table");
            } else {
                setError(deleteResult.message);
            }
        }
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name <RequiredInputIndicator/></IonLabel>
                            <IonInput 
                                type="text"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <FormGroup>
                            <label
                                className="ml-3 description"
                                htmlFor="body"
                            >
                                Description 
                            </label>
                            <Input
                                placeholder="..."
                                rows="4"
                                type="textarea"
                                name="description"
                                value={description}
                                onChange={(event) => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setDescription(event.target.value)
                                }}
                            />
                        </FormGroup>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <h4>Base Values</h4>
                        <BaseValuesTable isEditable={true} tiers={pointTable?.tiers} onSubmit={handleTableChange} />
                    </IonCol>
                </IonRow>          
                {error && <ErrorAlert width="12" error={error}/>}
                {success && <SuccessBanner width="12" success={success}/>}
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                Save
                            </IonButton>
                        }
                    </IonCol>
                    {pointTable && (
                        <IonCol offsetMd="4" sizeMd="4">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    disabled={isDisabled}
                                    className="ion-margin-top"
                                    color="danger"
                                    expand="block"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </IonButton>
                            }
                        </IonCol>
                    )}
                </IonRow>
            </form>
        </>
    );
};

export default PointTableForm;