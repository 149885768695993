import {
    IonCard,
    IonCardContent,
    IonCol,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Contact } from "../../models";
import QuickAddContactForm from "./QuickAddContactForm";
import SelectContact from "./SelectContact";

interface _Props {
    contact?: Contact
    contacts?: Contact[]
    onChange: Function
}

const ContactEntryForm: React.FC<_Props> = ({contact, contacts, onChange}) => {

    const [contactList, setContactList] = useState<Contact[] | undefined>();
    const [selectedContact, setSelectedContact] = useState<Contact | undefined>();

    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setContactList(contacts);
    }, [contacts]);

    useEffect(() => {
        if (contact) setSelectedContact(contact);
    }, [contact]);

    const handleContactSelect = (contact: Contact, type?: string, contactType?: string) => {
        if (type === "create") {
            const updatedContactList = (contactList && contactList.length) ? contactList.concat([contact]) : [contact];
            setContactList(updatedContactList);
        }
        setSelectedContact(contact);
        onChange(contact, contactType);
    }

    return (
        <>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="12">
                    <IonLabel position="stacked" color="dark">Primary Contact</IonLabel>
                    <SelectContact selectedValue={selectedContact?.id} contacts={contactList} onSelect={handleContactSelect} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="12">
                    <p className="ion-text-center link" onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? "View Less" : (selectedContact ? "Edit Contact" : "New Contact")}</p>
                </IonCol>
            </IonRow>
            {isExpanded && (
                <IonCard color="white" className="ion-no-padding">
                    <IonCardContent className="ion-no-padding">
                        <QuickAddContactForm contact={selectedContact} onSubmit={handleContactSelect}/>
                    </IonCardContent>
                </IonCard>
            )}
        </>
    );
};

export default ContactEntryForm;