import { FormattedRoleOption } from "../../components/Event/Alerts/EventFilterRolesOptionForm";
import { FormattedFilter } from "../../components/Event/Alerts/SelectEventAlertFiltersForm";
import { Result } from "../../interfaces/Result";
import { EventAuditor, EventAuditorFilter } from "../../models";

export async function getEventAuditorsByFilters(eventAuditors: EventAuditor[], formattedOptions: FormattedRoleOption[], formattedFilters?: FormattedFilter[]) {
    let result: Result = {isSuccess: true, type: "EventFilter", result: [], message: "Found no event auditors based on these filters"};

    if (!eventAuditors || eventAuditors.length < 1) {
        result = {isSuccess: false, type: "EventFilter", result: [], message: "Did not receive any event auditors."};
        return result;
    }

    if ((!formattedOptions || formattedOptions.length < 1) && (!formattedFilters || formattedFilters.length < 1)) {
        result = {isSuccess: true, type: "EventFilter", result: eventAuditors, message: "Did not receive any filters. By default, we will send to all auditors if no filters are selected."};
        return result;
    }

    let selected: EventAuditor[] = [];
    for (var i = 0; i < eventAuditors.length; i++) {
        // First get each event auditor
        const currentEventAuditor = eventAuditors[i];

        // First, check the Roles filter (required filter on all events)
        let shouldAddToListBecauseOfRole = await checkEventAuditorRoles(currentEventAuditor, formattedOptions);

        // Then, check if there are any other filters (example: Days)
        if (formattedFilters) {
            const shouldAddToListBecauseOfCurrentFilter = await checkEventAuditor(currentEventAuditor, formattedFilters);

            // Should try to use both together (Example: send alert to all trainers subscribed to Wednesday alerts)
            if (shouldAddToListBecauseOfRole && shouldAddToListBecauseOfCurrentFilter) {
                selected.push(currentEventAuditor);
            }
        } else {
            // No filters other than role. Just use that criteria
            if (shouldAddToListBecauseOfRole) selected.push(currentEventAuditor);
        }

    }
    result = {isSuccess: true, type: "EventFilter", result: selected, message: "Searched the event auditors and added ones that match the given filters."};
    return result;
}

async function checkEventAuditor(currentEventAuditor: EventAuditor, formattedFilters: FormattedFilter[]) {
    let shouldAddToList = true;

    // Next, get this event auditor's filters
    const filters: any = currentEventAuditor.filters as any;
    const items: any = filters.items as any;
    const currentAuditorFilters = items;

    // If both the auditor has selected some filters and the user has selected some filters
    if (currentAuditorFilters && currentAuditorFilters.length > 0 && formattedFilters && formattedFilters.length > 0) {
        // Loop through the user selected filters
        for (let i = 0; i < formattedFilters.length; i++) {
            const currentFilter = formattedFilters[i];
            const formattedFilterOptions = currentFilter.options;

            // Loop through the current filter's options
            if (formattedFilterOptions && formattedFilterOptions.length > 0) {
                for (var j = 0; j < formattedFilterOptions.length; j++) {
                    const currentOption = formattedFilterOptions[j];
                    if (currentOption.isSelected) {
                        // The user is including this option, so we want to see if it's in the current event auditor's selections
                        const currentAuditorMatchingFilter = currentAuditorFilters.find((filter: any) => filter?.filterId === currentFilter.filter.id);
                        const hasCommonFilterOption = checkForCommonFilterOption(currentFilter, currentAuditorMatchingFilter);
                        shouldAddToList = shouldAddToList && hasCommonFilterOption;
                    }
                }
            }
        }
        return shouldAddToList;
    } else {
        return false;
    }
}

function checkForCommonFilterOption(filter1: FormattedFilter, filter2: EventAuditorFilter) {
    if (filter1 && filter2) {
        const options1 = filter1.options;
        const options2 = filter2.options;

        for (let i = 0; i < options1.length; i++) {
            const element1 = options1[i];
            if (element1.isSelected) {
                if (options2) {
                    for (let j = 0; j < options2.length; j++) {
                        const element2 = options2[j];
                        if (element2) {
                            if (element1.name === element2) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    } else {
        return false;
    }
}

export async function getEventAuditorsByRolesFilters(eventAuditors: EventAuditor[], formattedOptions: FormattedRoleOption[]) {
    let result: Result = {isSuccess: true, type: "EventFilter", result: [], message: "Found no event auditors based on these filters"};

    if (!eventAuditors || eventAuditors.length < 1) {
        result = {isSuccess: false, type: "EventFilter", result: [], message: "Did not receive any event auditors."};
        return result;
    }

    if (!formattedOptions || formattedOptions.length < 1) {
        result = {isSuccess: true, type: "EventFilter", result: eventAuditors, message: "Did not receive any filters. By default, we will send to all auditors if no filters are selected."};
        return result;
    }

    let selected: EventAuditor[] = [];
    for (var i = 0; i < eventAuditors.length; i++) {
        // First get each event auditor
        const currentEventAuditor = eventAuditors[i];
        const shouldAddToList = await checkEventAuditorRoles(currentEventAuditor, formattedOptions);
        if (shouldAddToList) selected.push(currentEventAuditor);
    }
    result = {isSuccess: true, type: "EventFilter", result: selected, message: "Searched the event auditors and added ones that match the given filters."};
    return result;
}

async function checkEventAuditorRoles(currentEventAuditor: EventAuditor, formattedOptions: FormattedRoleOption[]) {
    let shouldAddToList = false;

    // Next, get this event auditor's filters
    const filters: any = currentEventAuditor.filters as any;
    const items: any = filters.items as any;
    const currentAuditorFilters = items;

    // If both the auditor has selected some filters and the user has selected some filters
    if (currentAuditorFilters && currentAuditorFilters.length > 0 && formattedOptions && formattedOptions.length > 0) {
        // Loop through the user selected filters
        for (let i = 0; i < formattedOptions.length; i++) {
            const currentOption: FormattedRoleOption = formattedOptions[i];
            
            if (currentOption.isSelected) {
                // The user is including this option, so we want to see if it's in the current event auditor's selections
                const currentAuditorMatchingFilter = currentAuditorFilters.find((filter: EventAuditorFilter) => filter.filter?.name === "Roles");
                const hasCommonFilterOption = checkForCommonRolesFilterOption(currentOption, currentAuditorMatchingFilter);
                if (hasCommonFilterOption) return hasCommonFilterOption;
            }
        }

        return shouldAddToList;
    } else {
        return false;
    }
}

// To Do: Check for children
function checkForCommonRolesFilterOption(currentOption: FormattedRoleOption, filter2: EventAuditorFilter) {
    if (currentOption && currentOption.isSelected && filter2) {
        const options = filter2.options;
        if (options) {
            for (let j = 0; j < options.length; j++) {
                let found = false;
                const element2 = options[j];
                if (element2) {
                    if (currentOption.children) {
                        currentOption.children.forEach((child) => {
                            if (child.value === element2) found = true;
                        })
                    } 
                    
                    if (!found && currentOption.value === element2) {
                        found = true;
                    }
                }
                if (found) return true;
            }
        }
        return false;
    } else {
        return false;
    }
}