import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonLabel,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event, ScheduleItem} from "../../../../../models";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import DisplaySchedule from "../../../../../components/Event/Schedule/DisplaySchedule";
import ScheduleItemForm from "../../../../../components/Event/Schedule/ScheduleItemForm";
import EventRingsList from "../../../../../components/EventRing/EventRingsList";
import EventRingForm from "../../../../../components/EventRing/EventRingForm";
import EventScheduler from "../../../../../components/Event/Schedule/EventScheduler";
import EventBreakForm from "../../../../../components/EventBreak/EventBreakForm";
import EventBreakList from "../../../../../components/EventBreak/EventBreakList";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventSchedulePage: React.FC<EventPageProps> = ({match}) => {
    const [activeTab, setActiveTab] = useState("schedule-new");
    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [selectedScheduleItem, setSelectedScheduleItem] = useState<ScheduleItem | null | undefined>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setCurrentEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        getEvent();
    }, [match, match.params.id]);

    const handleSelection = async (selection: string) => {
        setActiveTab(selection);
    }

    const onSubmitScheduleItem = (scheduleItem?: ScheduleItem) => {
        setSelectedScheduleItem(scheduleItem);
    }   

    const onSelectScheduleItem = (scheduleItem: ScheduleItem) => {
        setSelectedScheduleItem(scheduleItem);
    }
    
    return (
        <IonPage className="bg-light">
            <Header isEventPage={currentEvent ? true : false} event={currentEvent ? currentEvent : undefined} />
            <IonContent>
                <PageTitle title={currentEvent ? (currentEvent.nickname ? currentEvent.nickname : currentEvent.name) : "Event"} />
                {currentEvent && (
                    <>
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonRow>
                            <IonCol>
                                <IonSegment color="dark" mode="md" value={activeTab} scrollable={true} onIonChange={e => {handleSelection(e.detail.value || "schedule-new");}}>
                                    <IonSegmentButton value="schedule-new">
                                        <IonLabel>Schedule - New</IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton value="schedule-old">
                                        <IonLabel>Schedule - Old</IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton value="rings">
                                        <IonLabel>Rings</IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton value="breaks">
                                        <IonLabel>Schedule Breaks</IonLabel>
                                    </IonSegmentButton>
                                </IonSegment>
                            </IonCol>
                        </IonRow>
                        {activeTab === "schedule-new" && (
                            <>
                                <IonRow id="schedule">
                                    <IonCol size="12">
                                        <EventScheduler currentEvent={currentEvent} />
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                        {activeTab === "schedule-old" && (
                            <>
                               <IonRow id="schedule">
                                    <IonCol sizeSm="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white">
                                            <IonCardTitle>Schedule Item</IonCardTitle>
                                            <IonCardContent>
                                                <ScheduleItemForm event={currentEvent} scheduleItem={selectedScheduleItem} onSubmit={onSubmitScheduleItem} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol sizeSm="12" sizeMd="6">
                                        <DisplaySchedule event={currentEvent} onSelect={onSelectScheduleItem} />
                                    </IonCol>
                                </IonRow>
                        </>
                        )}
                        {activeTab === "rings" && (
                            <IonRow id="rings">
                                <IonCol sizeSm="12" sizeMd="6">
                                    <IonCard mode="md" className="ion-padding bg-white">
                                        <IonCardTitle>Add Ring</IonCardTitle>
                                        <IonCardContent>
                                            <EventRingForm event={currentEvent} onSubmit={() => console.log("New ring!")} />
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeSm="12" sizeMd="6">
                                    <IonCard mode="md" className="ion-padding bg-white">
                                        <IonCardTitle>Event Rings</IonCardTitle>
                                        <IonCardContent>
                                            <EventRingsList eventId={currentEvent.id} />
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        )}
                        {activeTab === "breaks" && (
                            <IonRow id="breaks">
                                <IonCol sizeSm="12" sizeMd="6">
                                    <IonCard mode="md" className="ion-padding bg-white">
                                        <IonCardTitle>Add Schedule Break</IonCardTitle>
                                        <IonCardContent>
                                            <EventBreakForm event={currentEvent} onSubmit={() => console.log("New break!")} />
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeSm="12" sizeMd="6">
                                    <IonCard mode="md" className="ion-padding bg-white">
                                        <IonCardTitle>Schedule Breaks</IonCardTitle>
                                        <IonCardContent>
                                            <EventBreakList eventId={currentEvent.id}/>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        )}
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventSchedulePage;