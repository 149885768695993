import {
    IonCard,
    IonCardContent,
    IonCol,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Barn } from "../../models";
import QuickAddBarnForm from "./QuickAddBarnForm";
import SelectBarn from "./SelectBarn";

interface _Props {
    useAllBarns?: Boolean
    barn?: (Barn | null)
    label?: (string | null)
    onChange: Function
}

const BarnEntryForm: React.FC<_Props> = ({useAllBarns, barn, label, onChange}) => {
    const [selectedBarn, setSelectedBarn] = useState<Barn | null | undefined>();
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (barn) {
            setSelectedBarn(barn);
        }
    }, [barn]);

    const handleBarnSelect = (barn: Barn) => {
        setSelectedBarn(barn);
        onChange(barn);
    }

    return (
        <>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="12">
                    <IonLabel position="stacked" color="dark">{label || "Barn"}</IonLabel>
                    <SelectBarn selectedValue={selectedBarn?.id} isClearable={true} useAllBarns={useAllBarns} onSelect={handleBarnSelect} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="12">
                    <p className="ion-text-center link" onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? "View Less" : (selectedBarn ? "Edit Barn" : "New Barn")}</p>
                </IonCol>
            </IonRow>
            {isExpanded && (
                <IonCard color="white" className="ion-no-padding">
                    <IonCardContent className="ion-no-padding">
                        <QuickAddBarnForm selectedBarn={barn} onChange={handleBarnSelect}/>
                    </IonCardContent>
                </IonCard>
            )}
        </>
    );
};

export default BarnEntryForm;