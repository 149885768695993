import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateOrganizationMembershipTypeInput, CreateOrganizationMembershipTypeMutation, CreateOrganizationMembershipTypeSeasonsInput, CreateOrganizationMembershipTypeSeasonsMutation, DeleteOrganizationMembershipTypeInput, DeleteOrganizationMembershipTypeMutation, DeleteOrganizationMembershipTypeSeasonsInput, DeleteOrganizationMembershipTypeSeasonsMutation, GetMembershipTypesByOrganizationIDByAcceptOnlineApplicationsQuery, GetOrganizationMembershipTypeQuery, ListOrganizationMembershipTypesQuery, ModelSortDirection, UpdateOrganizationMembershipTypeInput, UpdateOrganizationMembershipTypeMutation } from "../../API";
import moment from "moment";
import { OrganizationMembershipType } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: organizationMembershipType. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created organizationMembershipType.
*/
export async function createOrganizationMembershipType(input: CreateOrganizationMembershipTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationMembershipType", "No input", "Create OrganizationMembershipType received no input.");
   try {
        const fullInput: CreateOrganizationMembershipTypeInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createOrganizationMembershipType, { input: fullInput }))) as GraphQLResult<CreateOrganizationMembershipTypeMutation>;
       else result = (await API.graphql({
           query: mutations.createOrganizationMembershipType,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateOrganizationMembershipTypeMutation>;
       const organizationMembershipType = result.data?.createOrganizationMembershipType;
       return formatResult(true, "OrganizationMembershipType", organizationMembershipType, "Successfully created the organizationMembershipType.");
   } catch (error: any) {
       return formatResult(false, "OrganizationMembershipType", error, "Error creating record in the database for type: organizationMembershipType");
   }
}


/**
* Handle creating a new record in the database for type: organizationMembershipTypeSeason. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created organizationMembershipTypeSeason.
*/
export async function createOrganizationMembershipTypeSeason(input: CreateOrganizationMembershipTypeSeasonsInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "OrganizationMembershipTypeSeason", "No input", "Create OrganizationMembershipTypeSeason received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createOrganizationMembershipTypeSeasons, { input: input }))) as GraphQLResult<CreateOrganizationMembershipTypeSeasonsMutation>;
        else result = (await API.graphql({
            query: mutations.createOrganizationMembershipTypeSeasons,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<CreateOrganizationMembershipTypeSeasonsMutation>;
        const organizationMembershipTypeSeason = result.data?.createOrganizationMembershipTypeSeasons;
        return formatResult(true, "OrganizationMembershipType", organizationMembershipTypeSeason, "Successfully created the organizationMembershipTypeSeason.");
    } catch (error: any) {
        return formatResult(false, "OrganizationMembershipType", error, "Error creating record in the database for type: organizationMembershipTypeSeason");
    }
 }

/**
* Handle updating a new record in the database for type: organizationMembershipType. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated organizationMembershipType.
*/
export async function updateOrganizationMembershipType(input: UpdateOrganizationMembershipTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationMembershipType", "No input", "Update OrganizationMembershipType received no input.");
   try {
        const fullInput: UpdateOrganizationMembershipTypeInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateOrganizationMembershipType, { input: fullInput }))) as GraphQLResult<UpdateOrganizationMembershipTypeMutation>;
       else result = (await API.graphql({
           query: mutations.updateOrganizationMembershipType,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateOrganizationMembershipTypeMutation>;
       const organizationMembershipType = result.data?.updateOrganizationMembershipType;
       return formatResult(true, "OrganizationMembershipType", organizationMembershipType, "Successfully updated the organizationMembershipType.");
   } catch (error: any) {
       return formatResult(false, "OrganizationMembershipType", error, "Error updating record in the database for type: organizationMembershipType");
   }
}

/**
* Handle deleting a new record in the database for type: organizationMembershipType. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted organizationMembershipType.
*/
export async function deleteOrganizationMembershipType(input: DeleteOrganizationMembershipTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationMembershipType", "No input", "Delete OrganizationMembershipType received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteOrganizationMembershipType, { input: input }))) as GraphQLResult<DeleteOrganizationMembershipTypeMutation>;
       else result = (await API.graphql({
           query: mutations.deleteOrganizationMembershipType,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteOrganizationMembershipTypeMutation>;
       const organizationMembershipType = result.data?.deleteOrganizationMembershipType;
       return formatResult(true, "OrganizationMembershipType", organizationMembershipType, "Successfully deleted the organizationMembershipType.");
   } catch (error: any) {
       return formatResult(false, "OrganizationMembershipType", error, "Error deleting record in the database for type: organizationMembershipType");
   }
}

/**
* Handle deleting a new record in the database for type: organizationMembershipType. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted organizationMembershipType.
*/
export async function deleteOrganizationMembershipTypeSeason(input: DeleteOrganizationMembershipTypeSeasonsInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "OrganizationMembershipTypeSeasons", "No input", "Delete OrganizationMembershipTypeSeasons received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteOrganizationMembershipTypeSeasons, { input: input }))) as GraphQLResult<DeleteOrganizationMembershipTypeSeasonsMutation>;
        else result = (await API.graphql({
            query: mutations.deleteOrganizationMembershipTypeSeasons,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<DeleteOrganizationMembershipTypeSeasonsMutation>;
        const organizationMembershipType = result.data?.deleteOrganizationMembershipTypeSeasons;
        return formatResult(true, "OrganizationMembershipTypeSeasons", organizationMembershipType, "Successfully deleted the organizationMembershipType.");
    } catch (error: any) {
        return formatResult(false, "OrganizationMembershipTypeSeasons", error, "Error deleting record in the database for type: organizationMembershipType");
    }
 }

/**
* Get all records in the database for type: organizationMembershipType. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationMembershipType object
*/
export async function getAllOrganizationMembershipTypes(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listOrganizationMembershipTypes,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationMembershipTypesQuery>;
        
        let items = result.data?.listOrganizationMembershipTypes?.items as OrganizationMembershipType[];
        let nextToken = result.data?.listOrganizationMembershipTypes?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizationMembershipTypes,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationMembershipTypesQuery>;

            const nextItems = nextResult.data?.listOrganizationMembershipTypes?.items as OrganizationMembershipType[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationMembershipTypes?.nextToken;
        }

        return formatResult(true, "OrganizationMembershipType", items, "Successfully got the organizationMembershipTypes.");
    } catch (error: any) {
        return formatResult(false, "OrganizationMembershipType", error, "Error reading record in the database for type: organizationMembershipTypes");
    }
}

/**
* Read a specific record in the database for type: organizationMembershipType. 
* 
* @param {string}  id                  The organizationMembershipType id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationMembershipType object
*/
export async function getOrganizationMembershipTypeById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getOrganizationMembershipType,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetOrganizationMembershipTypeQuery>;
       const organizationMembershipType = result.data?.getOrganizationMembershipType;
       return formatResult(true, "OrganizationMembershipType", organizationMembershipType, "Successfully got the organizationMembershipType.");
   } catch (error: any) {
       return formatResult(false, "OrganizationMembershipType", error, "Error reading record in the database for type: organizationMembershipType");
   }
}

/**
* Get all records in the database that match the given criteria for type: organizationMembershipType. 
* 
* @param {string}  organizationId      The organization id of the "organization" of the organizationMembershipType
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationMembershipType object
*/
export async function getOrganizationMembershipTypesByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.getMembershipTypesByOrganizationIDByCreatedOn,
            variables: {
                limit: 1000,
                organizationId: organizationId,
                sortDirection: ModelSortDirection.DESC
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.getMembershipTypesByOrganizationIDByCreatedOn?.items as OrganizationMembershipType[];
        let nextToken = result.data?.getMembershipTypesByOrganizationIDByCreatedOn?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.getMembershipTypesByOrganizationIDByCreatedOn,
                variables: {
                    limit: 100,
                    organizationId: organizationId,
                    sortDirection: ModelSortDirection.DESC
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.getMembershipTypesByOrganizationIDByCreatedOn?.items as OrganizationMembershipType[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.getMembershipTypesByOrganizationIDByCreatedOn?.nextToken;
        }

        return formatResult(true, "OrganizationMembershipType", items, "Successfully got the OrganizationMembershipTypes.");
    } catch (error: any) {
        return formatResult(false, "OrganizationMembershipType", error, "Error reading record in the database for type: organizationMembershipTypes");
    }
}

export async function getMembershipTypesByOrganizationIDByAcceptOnlineApplications(organizationId: string, acceptOnlineApplications: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.getMembershipTypesByOrganizationIDByAcceptOnlineApplications,
            variables: {
                limit: 1000,
                organizationId: organizationId,
                acceptOnlineApplications: { eq: acceptOnlineApplications }
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<GetMembershipTypesByOrganizationIDByAcceptOnlineApplicationsQuery>;

        let items = result.data?.getMembershipTypesByOrganizationIDByAcceptOnlineApplications?.items as OrganizationMembershipType[];
        let nextToken = result.data?.getMembershipTypesByOrganizationIDByAcceptOnlineApplications?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.getMembershipTypesByOrganizationIDByAcceptOnlineApplications,
                variables: {
                    limit: 1000,
                    organizationId: organizationId,
                    acceptOnlineApplications: { eq: acceptOnlineApplications }
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<GetMembershipTypesByOrganizationIDByAcceptOnlineApplicationsQuery>;

            const nextItems = nextResult.data?.getMembershipTypesByOrganizationIDByAcceptOnlineApplications?.items as OrganizationMembershipType[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.getMembershipTypesByOrganizationIDByAcceptOnlineApplications?.nextToken;
        }
        return formatResult(true, "MembershipType", items, "Successfully got the MembershipTypes.");
    } catch (error: any) {
        return formatResult(false, "MembershipType", error, "Error reading record in the database for type: MembershipTypes");
    }
}