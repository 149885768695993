import { createRider, deleteRider, updateRider } from "../rider/Rider";
import { createOwner, deleteOwner, updateOwner } from "../owner/Owner";
import { createTrainer, deleteTrainer, updateTrainer } from "../trainer/Trainer";
import { BarnPerson } from "../../interfaces/Person";
import { Contact, CreateAddressInput, CreateContactInput, CreateOwnerInput, CreatePersonalInformationInput, CreateRiderInput, CreateTrainerInput, Membership, Owner, Rider, Trainer, UpdateAddressInput, UpdateContactInput, UpdateOwnerInput, UpdatePersonalInformationInput, UpdateRiderInput, UpdateTrainerInput } from "../../API";
import moment from "moment";
import { Result } from "../../interfaces/Result";
import { Address, Person, PersonalInformation } from "../../models";
import { formatTwilioNumber } from "../contact/FormatPhoneNumber";
import { createContact, updateContact } from "../contact/Contact";
import { getMembershipsByOwnerId, getMembershipsByRiderId, getMembershipsByTrainerId } from "../membership/Membership";
import { createPersonalInformation, getPersonalInformationByPersonId, updatePersonalInformation } from "../personalInformation/PersonalInformation";
import { createAddress, updateAddress } from "../address/Address";

interface RoleObject {
    rider?: Rider
    owner?: Owner
    trainer?: Trainer
}
async function getRoleObjects(barnPerson: BarnPerson): Promise<RoleObject> {
    let roleObject: RoleObject = {};

    if (barnPerson.rider) roleObject.rider = barnPerson.rider as Rider;
    if (barnPerson.trainer) roleObject.trainer = barnPerson.trainer as Trainer;
    if (barnPerson.owner) roleObject.owner = barnPerson.owner as Owner;

    return roleObject;
}

export async function getPersonAddress(barnPerson: BarnPerson): Promise<Address | null> {
    let address: Address | null = null;

    if (barnPerson.rider && barnPerson.rider.address) {
        address = barnPerson.rider.address
    }
    if (barnPerson.owner && barnPerson.owner.address) {
        address = barnPerson.owner.address;
    }
    if (barnPerson.trainer && barnPerson.trainer.address) {
        address = barnPerson.trainer.address
    }
    
    return address;
}

export async function getPersonContact(barnPerson: BarnPerson): Promise<Contact | null> {
    let contact: Contact | null = null;

    if (barnPerson.rider && barnPerson.rider.contact) contact = barnPerson.rider.contact as Contact;
    if (barnPerson.owner && barnPerson.owner.contact) contact = barnPerson.owner.contact  as Contact;
    if (barnPerson.trainer && barnPerson.trainer.contact) contact = barnPerson.trainer.contact  as Contact;
    
    return contact;
}

export async function getPersonMemberships(BarnPerson: BarnPerson): Promise<Membership[]> {
    let memberships: Membership[] = [];

    const roleObject = await getRoleObjects(BarnPerson);
    if (roleObject.rider) {
        const riderResult = await getMembershipsByRiderId(roleObject.rider.id);
        if (riderResult.isSuccess) {
            const current = riderResult.result;
            if (current && current.length > 0) {
                current.forEach((membership: Membership) => {
                    const index = memberships.findIndex(m => m.membershipId === membership.membershipId);
                    if (index === -1) memberships.push(membership);
                });
            }
        }
    }
    if (roleObject.owner) {
        const ownerResult = await getMembershipsByOwnerId(roleObject.owner.id);
        if (ownerResult.isSuccess) {
            const current = ownerResult.result;
            if (current && current.length > 0) {
                current.forEach((membership: Membership) => {
                    const index = memberships.findIndex(m => m.membershipId === membership.membershipId);
                    if (index === -1) memberships.push(membership);
                });
            }
        }
    }
    if (roleObject.trainer) {
        const trainerResult = await getMembershipsByTrainerId(roleObject.trainer.id);
        if (trainerResult.isSuccess) {
            const current = trainerResult.result;
            if (current && current.length > 0) {
                current.forEach((membership: Membership) => {
                    const index = memberships.findIndex(m => m.membershipId === membership.membershipId);
                    if (index === -1) memberships.push(membership);
                });
            }
        }
    }
    
    return memberships;
}

export async function savePersonBasicInfo(barnPerson: BarnPerson, name?: string ) {
    let result: Result = {
        isSuccess: true,
        type: "BarnPerson",
        result: barnPerson,
        message: "Successfully updated the barn person"
    };

    if (barnPerson.rider) {
        const updateRiderInput: UpdateRiderInput = {
            id: barnPerson.rider.id,
            name: name
        };
        const updateRiderResult = await updateRider(updateRiderInput);
        if (!updateRiderResult.isSuccess) {
            result = {isSuccess: false, type: "BarnPerson", result: updateRiderResult.result, message: "Could not update the barn person."};
            return result;
        }
    }

    if (barnPerson.owner) {
        const updateOwnerInput: UpdateOwnerInput = {
            id: barnPerson.owner.id,
            name: name
        };
        const updateOwnerResult = await updateOwner(updateOwnerInput);
        if (!updateOwnerResult.isSuccess) {
            result = {isSuccess: false, type: "BarnPerson", result: updateOwnerResult.result, message: "Could not update the barn person."};
            return result;
        }
    }

    if (barnPerson.trainer) {
        const updateTrainerInput: UpdateTrainerInput = {
            id: barnPerson.trainer.id,
            name: name
        };
        const updateTrainerResult = await updateTrainer(updateTrainerInput);
        if (!updateTrainerResult.isSuccess) {
            result = {isSuccess: false, type: "BarnPerson", result: updateTrainerResult.result, message: "Could not update the barn person."};
            return result;
        }
    }

    return result;
}

export async function savePersonRiderInfo(barnPerson: BarnPerson, birthdate?: string, isProfessional?: boolean ) {
    let result: Result = {
        isSuccess: true,
        type: "BarnPerson",
        result: barnPerson,
        message: "Successfully updated the barn person"
    };

    if (barnPerson.rider) {
        let updateRiderInput: UpdateRiderInput = {
            id: barnPerson.rider.id,
            isProfessional: isProfessional
        };
        if (birthdate) updateRiderInput.birthdate = moment(birthdate).format("YYYY-MM-DD");
        else updateRiderInput.birthdate = null;
        const updateRiderResult = await updateRider(updateRiderInput);
        if (!updateRiderResult.isSuccess) {
            result = {isSuccess: false, type: "BarnPerson", result: updateRiderResult.result, message: "Could not update the barn person."};
            return result;
        }
    }

    if (barnPerson.personId) {
        const queryResult = await getPersonalInformationByPersonId(barnPerson.personId);
        if (queryResult.isSuccess) {
            const info: PersonalInformation = queryResult.result;
            let updateInfoInput: UpdatePersonalInformationInput = {
                id: info.id
            };
            if (birthdate) updateInfoInput.dateOfBirth = moment(birthdate).format("YYYY-MM-DD");
            else updateInfoInput.dateOfBirth = null;
            const updateResult = await updatePersonalInformation(updateInfoInput);
            if (!updateResult.isSuccess) {
                result = {isSuccess: false, type: "BarnPerson", result: updateResult.result, message: "Could not update the barn person."};
                return result;
            }
        } else if (birthdate) {
            const createInfoInput: CreatePersonalInformationInput = {
                personId: barnPerson.personId,
                dateOfBirth: moment(birthdate).format("YYYY-MM-DD"),
            };
            const createResult = await createPersonalInformation(createInfoInput);
            if (!createResult.isSuccess) {
                result = {isSuccess: false, type: "BarnPerson", result: createResult.result, message: "Could not update the barn person."};
                return result;
            }
        }
    }

    return result;
}

export async function savePersonContactInfo(barnPerson: BarnPerson, email?: string, phoneNumber?:string, phoneType?: string) {
    let result: Result = {isSuccess: true, type: "Person", result: null, message: "Successfully updated the contact info."};

    let contact: (Contact | null) = await getPersonContact(barnPerson);

    if (contact) {
        // Update the contact
        let contactInput: UpdateContactInput = {
            id: contact.id,
            name: barnPerson.name || "",
            mailingAddress: contact.mailingAddress || "",                
        };
        if (phoneNumber) {
            const formattedPhoneNumber = formatTwilioNumber(phoneNumber);
            if (phoneType === "cell") contactInput["cell"] = formattedPhoneNumber;
            if (phoneType === "home") contactInput["home"] = formattedPhoneNumber;
            if (phoneType === "work") contactInput["work"] = formattedPhoneNumber;
        }
        if (barnPerson.rider || barnPerson.owner) {
            contactInput["personalEmail"] = email;
        }
        if (barnPerson.trainer) {
            contactInput["workEmail"] = email;
        }
        const updateContactResult = await updateContact(contactInput);
        if (updateContactResult.isSuccess) {
            contact = updateContactResult.result;
        }
    } else {
        // Create the contact
        let contactInput: CreateContactInput = {
            name: barnPerson.name
        };
        if (phoneNumber) {
            const formattedPhoneNumber = formatTwilioNumber(phoneNumber);
            if (phoneType === "cell") contactInput["cell"] = formattedPhoneNumber;
            if (phoneType === "home") contactInput["home"] = formattedPhoneNumber;
            if (phoneType === "work") contactInput["work"] = formattedPhoneNumber;
        }
        if (barnPerson.rider || barnPerson.owner) {
            contactInput["personalEmail"] = email;
        }
        if (barnPerson.trainer) {
            contactInput["workEmail"] = email;
        }
        const createContactResult = await createContact(contactInput);
        if (createContactResult.isSuccess) {
            contact = createContactResult.result;
        }
    }

    if (contact) {
        // Now connect the contact to any rider, owner or trainer objects
        if (barnPerson.rider) {
            const updateRiderInput: UpdateRiderInput = {
                id: barnPerson.rider.id,
                riderContactId: contact?.id
            };
            const updateRiderResult = await updateRider(updateRiderInput);
            if (!updateRiderResult.isSuccess) {
                result = {isSuccess: false, type: "Person", result: null, message: "Could not update rider contact information"};
                return result;
            }
        }
        if (barnPerson.owner) {
            const updateOwnerInput: UpdateOwnerInput = {
                id: barnPerson.owner.id,
                ownerContactId: contact?.id
            };
            const updateOwnerResult = await updateOwner(updateOwnerInput);
            if (!updateOwnerResult.isSuccess) {
                result = {isSuccess: false, type: "Person", result: null, message: "Could not update owner contact information"};
                return result;
            }
        }
        if (barnPerson.trainer) {
            const updateTrainerInput: UpdateTrainerInput = {
                id: barnPerson.trainer.id,
                trainerContactId: contact?.id
            };
            const updateTrainerResult = await updateTrainer(updateTrainerInput);
            if (!updateTrainerResult.isSuccess) {
                result = {isSuccess: false, type: "Person", result: null, message: "Could not update trainer contact information"};
                return result;
            }
        }
        return result;
    } else {
        result = {isSuccess: false, type: "Person", result: null, message: "No contact information to update"};
        return result;
    }
}

export async function savePersonAddress(barnPerson: BarnPerson, streetAddress1?: string, streetAddress2?: string, city?: string, provState?: string, zip?: string, country?: string) {
    let result: Result = {isSuccess: true, type: "Person", result: null, message: "Successfully updated the address info."};

    let address: (Address | null) = await getPersonAddress(barnPerson);

    if (address) {
        // Update the address
        let addressInput: UpdateAddressInput = {
            id: address.id,
            name: barnPerson.name || "",
            streetAddress1: streetAddress1,
            streetAddress2: streetAddress2,
            city: city,
            provState: provState,
            zip: zip,
            country: country               
        };
        const updateAddressResult = await updateAddress(addressInput);
        if (updateAddressResult.isSuccess) {
            address = updateAddressResult.result;
        }
    } else {
        // Create the address
        let addressInput: CreateAddressInput = {
            name: barnPerson.name || "",
            streetAddress1: streetAddress1,
            streetAddress2: streetAddress2,
            city: city,
            provState: provState,
            zip: zip,
            country: country  
        };
        const createAddressResult = await createAddress(addressInput);
        if (createAddressResult.isSuccess) {
            address = createAddressResult.result;
        }
    }

    if (address) {
        // Now connect the address to any rider, owner or trainer objects
        if (barnPerson.rider) {
            const updateRiderInput: UpdateRiderInput = {
                id: barnPerson.rider.id,
                // riderAddressId: address?.id
            };
            const updateRiderResult = await updateRider(updateRiderInput);
            if (!updateRiderResult.isSuccess) {
                result = {isSuccess: false, type: "Person", result: null, message: "Could not update rider address information"};
                return result;
            }
        }
        if (barnPerson.owner) {
            const updateOwnerInput: UpdateOwnerInput = {
                id: barnPerson.owner.id,
                // ownerAddressId: address?.id
            };
            const updateOwnerResult = await updateOwner(updateOwnerInput);
            if (!updateOwnerResult.isSuccess) {
                result = {isSuccess: false, type: "Person", result: null, message: "Could not update owner address information"};
                return result;
            }
        }
        if (barnPerson.trainer) {
            const updateTrainerInput: UpdateTrainerInput = {
                id: barnPerson.trainer.id,
                // trainerAddressId: address?.id
            };
            const updateTrainerResult = await updateTrainer(updateTrainerInput);
            if (!updateTrainerResult.isSuccess) {
                result = {isSuccess: false, type: "Person", result: null, message: "Could not update trainer address information"};
                return result;
            }
        }
        return result;
    } else {
        result = {isSuccess: false, type: "Person", result: null, message: "No address information to update"};
        return result;
    }
}

export async function savePersonRoles(user: Person, BarnPerson: BarnPerson, isRider?: boolean, isOwner?: boolean, isTrainer?: boolean, isParent?: boolean, barnId?: string): Promise<Result> {
    const roleObject = await getRoleObjects(BarnPerson);

    let result: Result = {isSuccess: true, type: "Person", result: null, message: "Updated the roles."};

    if (roleObject.rider) {
        if (isRider) {
            // Already has the rider
        } else {
            // Remove the rider
            await deleteRider({id: roleObject.rider.id});
        }
    } else {
        if (isRider) {
            // Needs a new rider
            const createRiderInput: CreateRiderInput = {
                name: BarnPerson.name,
                location: BarnPerson.location,
                personId: user.id || roleObject.trainer?.personId || roleObject.owner?.personId || "",
                barnId: barnId || roleObject.trainer?.barnId || roleObject.owner?.barnId || "",
                createdBy: user.id,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")             
            };
            const createRiderResult = await createRider(createRiderInput);
            if (!createRiderResult.isSuccess) {
                const message = result.message + " Could not update the rider role. " + createRiderResult.message;
                result = {isSuccess: false, type: "Person", result: createRiderResult.result, message: message};
            }
        } else {
            // Do nothing
        }
    }

    if (roleObject.owner) {
        if (isOwner) {
            // Already has the owner
        } else {
            // Remove the owner
            await deleteOwner({id: roleObject.owner.id});
        }
    } else {
        if (isOwner) {
            // Needs a new owner
            const createOwnerInput: CreateOwnerInput = {
                name: BarnPerson.name,
                location: BarnPerson.location,
                personId: user.id || roleObject.trainer?.personId || roleObject.rider?.personId || "",
                barnId: barnId || roleObject.trainer?.barnId || roleObject.rider?.barnId || "",
                createdBy: user.id,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")             
            };
            const createOwnerResult = await createOwner(createOwnerInput);
            if (!createOwnerResult.isSuccess) {
                const message = result.message + " Could not update the owner role. " + createOwnerResult.message;
                result = {isSuccess: false, type: "Person", result: createOwnerResult.result, message: message};
            }
        } else {
            // Do nothing
        }
    }

    if (roleObject.trainer) {
        if (isTrainer) {
            // Already has the trainer
        } else {
            // Remove the trainer
            await deleteTrainer({id: roleObject.trainer.id});
        }
    } else {
        if (isTrainer) {
            // Needs a new trainer
            const createTrainerInput: CreateTrainerInput = {
                name: BarnPerson.name,
                location: BarnPerson.location,
                personId: user.id || roleObject.owner?.personId || roleObject.rider?.personId || "",
                barnId: barnId || roleObject.owner?.barnId || roleObject.rider?.barnId || "",
                createdBy: user.id,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")             
            };
            const createTrainerResult = await createTrainer(createTrainerInput);
            if (!createTrainerResult.isSuccess) {
                const message = result.message + " Could not update the trainer role. " + createTrainerResult.message;
                result = {isSuccess: false, type: "Person", result: createTrainerResult.result, message: message};
            }
        } else {
            // Do nothing
        }
    }

    return result;
}

export async function updatePersonRoles(BarnPerson: BarnPerson, isRider?: boolean, isOwner?: boolean, isTrainer?: boolean, isParent?: boolean, barnId?: string): Promise<Result> {
    const roleObject = await getRoleObjects(BarnPerson);

    let result: Result = {isSuccess: true, type: "Person", result: null, message: "Updated the roles."};

    if (roleObject.rider) {
        if (isRider) {
            // Already has the rider
        } else {
            // Remove the rider
            await deleteRider({id: roleObject.rider.id});
        }
    } else {
        if (isRider) {
            // Needs a new rider
            const createRiderInput: CreateRiderInput = {
                name: BarnPerson.name,
                location: BarnPerson.location,
                personId: BarnPerson.personId || roleObject.trainer?.personId || roleObject.owner?.personId || "",
                barnId: barnId || roleObject.trainer?.barnId || roleObject.owner?.barnId || "",
                createdBy: BarnPerson.personId,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")             
            };
            const createRiderResult = await createRider(createRiderInput);
            if (!createRiderResult.isSuccess) {
                const message = result.message + " Could not update the rider role. " + createRiderResult.message;
                result = {isSuccess: false, type: "Person", result: createRiderResult.result, message: message};
            }
        } else {
            // Do nothing
        }
    }

    if (roleObject.owner) {
        if (isOwner) {
            // Already has the owner
        } else {
            // Remove the owner
            await deleteOwner({id: roleObject.owner.id});
        }
    } else {
        if (isOwner) {
            // Needs a new owner
            const createOwnerInput: CreateOwnerInput = {
                name: BarnPerson.name,
                location: BarnPerson.location,
                personId: BarnPerson.personId || roleObject.trainer?.personId || roleObject.rider?.personId || "",
                barnId: barnId || roleObject.trainer?.barnId || roleObject.rider?.barnId || "",
                createdBy: BarnPerson.personId,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")             
            };
            const createOwnerResult = await createOwner(createOwnerInput);
            if (!createOwnerResult.isSuccess) {
                const message = result.message + " Could not update the owner role. " + createOwnerResult.message;
                result = {isSuccess: false, type: "Person", result: createOwnerResult.result, message: message};
            }
        } else {
            // Do nothing
        }
    }

    if (roleObject.trainer) {
        if (isTrainer) {
            // Already has the trainer
        } else {
            // Remove the trainer
            await deleteTrainer({id: roleObject.trainer.id});
        }
    } else {
        if (isTrainer) {
            // Needs a new trainer
            const createTrainerInput: CreateTrainerInput = {
                name: BarnPerson.name,
                location: BarnPerson.location,
                personId: BarnPerson.personId || roleObject.owner?.personId || roleObject.rider?.personId || "",
                barnId: barnId || roleObject.owner?.barnId || roleObject.rider?.barnId || "",
                createdBy: BarnPerson.personId,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")             
            };
            const createTrainerResult = await createTrainer(createTrainerInput);
            if (!createTrainerResult.isSuccess) {
                const message = result.message + " Could not update the trainer role. " + createTrainerResult.message;
                result = {isSuccess: false, type: "Person", result: createTrainerResult.result, message: message};
            }
        } else {
            // Do nothing
        }
    }

    return result;
}