import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Division, OrganizationDivision,  PointTable,  PrizeMoneyTable } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import { createDivision, getDivisionById } from "../../utilities/division/Division";
import SelectDiscipline from "../Discipline/SelectDiscipline";
import { createEventDivision, getEventDivisionsByEventIdByName } from "../../utilities/eventDivision/EventDivision";
import SuccessBanner from "../Banners/SuccessBanner";
import SelectECClass from "../Class/SelectECClass";
import SelectECClassType from "../Class/SelectECClassType";
import { ECClass, ECClassType } from "../../interfaces/ECData";
import { USEFSection } from "../../interfaces/USEFData";
import SelectUSEFClass from "../Class/SelectUSEFClass";
import { capitalizeName } from "../../utilities/person/PersonNameFormat";
import { getDisciplineFromName } from "../../utilities/discipline/FormatDisciplines";
import { CreateDivisionInput, CreateEventDivisionInput } from "../../API";
import { Input } from "reactstrap";
import DivisionEntryFee from "../EventClass/EventClassForm/DivisionEntryFee";
import ClassPrizeMoney from "../EventClass/EventClassForm/ClassPrizeMoney";
import AllowedNumberOfEntries from "../EventClass/EventClassForm/AllowedNumberOfEntries";
import ClassPointTable from "../EventClass/EventClassForm/ClassPointTable";
import SelectUSHJAOutreachClass from "../Class/SelectUSHJAOutreachClass";
import { USHJAOutreachSection } from "../../data/content/USHJAOutreachSections";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    eventId: string
    organizationId: string
    organizationDivision?: (OrganizationDivision | null)
    onSubmit: Function
}

const CreateEventDivisionForm: React.FC<_Props> = ({eventId, organizationId, organizationDivision, onSubmit}) => {
    const user = useContext(PersonContext);

    const [selectedValue, setSelectedValue] = useState<"custom" | "ec" | "usef" | "ushja">("custom");
    const [ecClassNumber, setECClassNumber] = useState<number | undefined>();
    const [ecClassTypeNumber, setECClassTypeNumber] = useState<number | undefined>();
    const [usefClassNumber, setUSEFClassNumber] = useState<number | undefined>();
    const [division, setDivision] = useState<Division | undefined>(); 
    const [name, setName] = useState(((organizationDivision && organizationDivision.division) ? organizationDivision.division.name : ""));
    const [ecName, setECName] = useState("");
    const [ecClassTypeName, setEClassTypeName] = useState("");
    const [ushjaName, setUSHJAName] = useState("");
    const [usefName, setUSEFName] = useState("");
    const [description, setDescription] = useState((organizationDivision && organizationDivision.division) ? organizationDivision.division.description : "");
    const [discipline, setDiscipline] = useState((organizationDivision && organizationDivision.division) ? organizationDivision.division.discipline : "hunter"); //Hunter is the default option in SelectDiscipline
    const [minNumberEntries, setMinNumberEntries] = useState(3);
    const [maxNumberEntries, setMaxNumberEntries] = useState<number | undefined>();
    const [entryFee, setEntryFee] = useState((organizationDivision && organizationDivision.entryFee) ? parseFloat(organizationDivision.entryFee) : 0);
    const [prizeMoneyTable, setPrizeMoneyTable] = useState<PrizeMoneyTable | undefined>();
    const [prizeMoney, setPrizeMoney] = useState((organizationDivision && organizationDivision.prizeMoney) ? parseFloat(organizationDivision.prizeMoney) : 0);
    const [pointTable, setPointTable] = useState<PointTable | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    const getDivision = async (divisionId: string) => {
        const queryResult = await getDivisionById(divisionId);
        if (queryResult.isSuccess) {
            const division = queryResult.result;
            setDivision(division);
            setName(division.name);
            setDescription(division.description);
            setDiscipline(division.discipline);
            if (division.ecNumber) {
                setSelectedValue("ec");
                setECClassNumber(parseInt(division.ecNumber));
                if (division.ecClassTypeNumber) setECClassTypeNumber(parseInt(division.ecClassTypeNumber));
                if (division.ecClassType) setEClassTypeName(division.ecClassType);
            }
            if (division.usefNumber) {
                setSelectedValue("usef");
                setUSEFClassNumber(parseInt(division.usefNumber));
            }
            if (division.ushjaName) {
                setSelectedValue("ushja");
                setUSHJAName(division.ushjaName);
            }
            return queryResult.result;
        } else {
            return null;
        }
    }

    useEffect(() => {
        if (organizationDivision) {
            getDivision(organizationDivision.divisionId);
            setDescription(organizationDivision.division?.description);
            setDiscipline(organizationDivision.division?.discipline);
            setMinNumberEntries(organizationDivision.minNumberEntries || 3);
            setMaxNumberEntries(organizationDivision.maxNumberEntries || 0);
            setEntryFee(organizationDivision.entryFee ? parseFloat(organizationDivision.entryFee) : 0);
            setPrizeMoney(organizationDivision.prizeMoney ? parseFloat(organizationDivision.prizeMoney) : 0);
            setPrizeMoneyTable(organizationDivision.prizeMoneyTable || undefined);
            setPointTable(organizationDivision.pointTable || undefined);
            setSuccess("");
            setError("");
        }
    }, [organizationDivision]);

    useEffect(() => {
        setError("");
        setSuccess("");
    }, []);

    const verifyName = async () => {
        const queryResult = await getEventDivisionsByEventIdByName(eventId, name);
        if (queryResult.isSuccess && queryResult.result.length) return false
        else return true;
    }

    const verifyForm = async () => {
        try {
            if (!name) {
                setError("You must include a name.");
                return false;
            }
            if (name) {
                const isUniqueName = await verifyName();
                if (!isUniqueName) {
                    setError("This name has already been used for another division in your event.");
                    return false;
                }
            }
            if (!discipline) {
                setError("You must include a discipline.");
                return false;
            }
            if (selectedValue === "usef" && !usefClassNumber) {
                setError("You must include a USEF Class.");
                return false;
            }
            if (selectedValue === "ec" && !ecClassNumber) {
                setError("You must include an EC Class.");
                return false;
            }
            if (selectedValue === "ec" && !ecClassTypeNumber) {
                setError("You must include an EC Class Type.");
                return false;
            }
            return true;
        } catch (error: any) {
            setError(error);
            return false;
        }
    }

    const clearForm = () => {
        setDivision(undefined);
        setDivision(undefined);
        setName("");
        setECName("");
        setEClassTypeName("");
        setUSEFName("");
        setUSHJAName("");
        setECClassNumber(undefined);
        setECClassTypeNumber(undefined);
        setUSEFClassNumber(undefined);
        setDescription("");
        setDiscipline("hunter");
        setEntryFee(0);
        setPrizeMoney(0);
        setError("");
    } 

    const handleSelectedECClass = (data: ECClass) => {
        const name = data.ClassDescription;
        setName(name);
        setECName(name);
        setDiscipline(getDisciplineFromName(name));
        setECClassNumber(data.ClassID);
    }

    const handleSelectedECClassType = (data: ECClassType) => {
        setEClassTypeName(data.ClassTypeDescription);
        setECClassTypeNumber(data.ClassTypeID);
    }

    const handleSelectedUSEFDivision = (data: USEFSection) => {
        const name = data.SectionName;
        setName(capitalizeName(name));
        setUSEFName(name);
        setDiscipline(getDisciplineFromName(name));
        setUSEFClassNumber(data.SectionCd);
    }

    const handleSelectedUSHJASection = (data?: USHJAOutreachSection) => {
        const name = data ? data.name : "";
        setUSHJAName(name);
        setName(name);
        setDiscipline(getDisciplineFromName(name));
    }

    const handleSelectedDiscipline = (discipline: string) => {
        setDiscipline(discipline);
    }

    const handleSelectedEntryNumber = (option: "min" | "max", number: number) => {
        if (option === "min") setMinNumberEntries(number);
        else setMaxNumberEntries(number);
    }

    const handleSelectedDivisionEntryFee = (option: "division", number: number) => {
        setEntryFee(number);
    }

    const handleSelectedPrizeMoney = (option: "prizeMoney", number: number) => {
        setPrizeMoney(number);
    }

    const handleSelectedPrizeMoneyTable = (option: "prizeMoneyTable", prizeMoneyTable: PrizeMoneyTable) => {
        setPrizeMoneyTable(prizeMoneyTable);
        if (!prizeMoneyTable.isPercentage && prizeMoneyTable.totalPot) {
            setPrizeMoney(prizeMoneyTable.totalPot);
        } else {
            setPrizeMoney(0);
        }
    }

    const handleSelectedPointTable = (option: "pointTable", pointTable: PointTable) => {
        setPointTable(pointTable);
    }

    const handleCreateDivision = async () => {
        try {
            const input: CreateDivisionInput = {
                createdBy: user.id,
                name: name,
                description: description,
                discipline: discipline,
                ushjaName: ushjaName,
                usefNumber: usefClassNumber ? usefClassNumber.toString() : "",
                ecNumber: ecClassNumber ? ecClassNumber?.toString() : "",
                ecClassTypeNumber: ecClassTypeNumber ? ecClassTypeNumber.toString() : "",
                ecClassType: ecClassTypeName
            };
            const createResult = await createDivision(input);
            if (createResult.isSuccess) {
                const input: CreateEventDivisionInput = {
                    createdBy: user.id,
                    name: name,
                    eventId: eventId, 
                    divisionId: createResult.result.id,
                    entryFee: entryFee.toString(),
                    prizeMoney: prizeMoney.toString(),
                    eventDivisionPrizeMoneyTableId: prizeMoneyTable?.id || "",
                    minNumberEntriesPerClass: minNumberEntries,
                    maxNumberEntriesPerClass: maxNumberEntries,
                    organizationDivisionId: organizationDivision?.id,
                    eventDivisionOrganizationDivisionId: organizationDivision?.id
                };
                const createEventDivisionResult = await createEventDivision(input);
                if (createEventDivisionResult.isSuccess) {
                    onSubmit(createResult.result, createEventDivisionResult.result);
                    clearForm();
                    const message = "Successfully created the division: " + name;
                    setSuccess(message);
                    
                } else {
                    setError(createEventDivisionResult.message);
                }
            } else {
                setError(createResult.message);
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        const isValid = await verifyForm();
        if (isValid) {
            setError("");
            await handleCreateDivision();
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            <form>
                {/* TO DO - Add back in the ability to check for USEF Classes */}
                {/* <IonRow>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "custom" ? "primary" : "light"} onClick={() => {setSelectedValue("custom"); clearForm();}}>Custom</IonButton>
                    </IonCol>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "ec" ? "primary" : "light"} onClick={() => {setSelectedValue("ec"); clearForm();}}>EC</IonButton>
                    </IonCol>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "usef" ? "primary" : "light"} onClick={() => {setSelectedValue("usef"); clearForm();}}>USEF</IonButton>
                    </IonCol>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "ushja" ? "primary" : "light"} onClick={() => {setSelectedValue("ushja"); }}>USHJA</IonButton>
                    </IonCol>
                </IonRow> */}
                <IonRow>
                    <IonCol>
                        <IonCard className="bg-light">
                            <IonCardContent>
                            {selectedValue === "ec" && (
                                <>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <p>Select from the list of recognized EC options.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectECClass selectedValue={ecClassNumber?.toString() || ""} onSelect={handleSelectedECClass} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">EC Number</IonLabel>
                                                <IonInput 
                                                    type="number"
                                                    value={ecClassNumber}
                                                    disabled={true}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectECClassType selectedValue={ecClassTypeNumber?.toString() || ""} onSelect={handleSelectedECClassType} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">EC Class Type ID</IonLabel>
                                                <IonInput 
                                                    type="number"
                                                    value={ecClassTypeNumber}
                                                    disabled={true}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {selectedValue === "usef" && (
                                <>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <p>Select from the list of recognized USEF options.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectUSEFClass onSelect={handleSelectedUSEFDivision} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">USEF Number</IonLabel>
                                                <IonInput 
                                                    type="number"
                                                    value={usefClassNumber}
                                                    disabled={true}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {selectedValue === "ushja" && (
                                <>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <p>Select from the list of USHJA Outreach options.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectUSHJAOutreachClass selectedValue={ushjaName} onSelect={handleSelectedUSHJASection} />
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {selectedValue === "custom" && (
                                <IonRow className="ion-padding-top">
                                    <IonCol>
                                     <p>Create your own division.</p>
                                        {/* <p>Create your own division. We will not report its results to EC or USEF.</p> */}
                                    </IonCol>
                                </IonRow>
                            )}
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name <RequiredInputIndicator/></IonLabel>
                            <IonInput 
                                type="text"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel position="stacked">Discipline <RequiredInputIndicator/></IonLabel>
                        <SelectDiscipline selectedValue={discipline} onSelect={(value: string) => handleSelectedDiscipline(value)} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel>Description</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <Input
                            rows="5"
                            type="textarea"
                            name="declineReason"
                            value={description!}
                            spellCheck="true"
                            onChange={(event) => setDescription(event.target.value)}
                            data-hj-whitelist
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <AllowedNumberOfEntries selectedMin={minNumberEntries} selectedMax={maxNumberEntries} onSelect={handleSelectedEntryNumber} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <DivisionEntryFee selectedEntryFee={entryFee} onSelect={handleSelectedDivisionEntryFee} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <ClassPointTable organizationId={organizationId} selectedPointTable={pointTable} onSelectPointTable={handleSelectedPointTable} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <ClassPrizeMoney organizationId={organizationId} selectedPrizeMoney={prizeMoney} selectedPrizeMoneyTable={prizeMoneyTable} onSelectPrizeMoney={handleSelectedPrizeMoney} onSelectPrizeMoneyTable={handleSelectedPrizeMoneyTable} />
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeMd="4" className="ion-text-center">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                Add
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default CreateEventDivisionForm;