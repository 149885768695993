import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PrizeMoneyTable, PrizeMoneyTiers } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import PrizeMoneyPercentageTable from "./PrizeMoneyPercentageTable";
import SuccessBanner from "../Banners/SuccessBanner";
import { createPrizeMoneyTable, deletePrizeMoneyTable, updatePrizeMoneyTable } from "../../utilities/prizeMoneyTable/PrizeMoneyTable";
import PrizeMoneyFixedTable from "./PrizeMoneyFixedTable";
import { CreatePrizeMoneyTableInput, UpdatePrizeMoneyTableInput } from "../../API";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    organizationId: string
    prizeMoneyTable?: (PrizeMoneyTable | null)
    tableName?: (string | null)
    onSubmit: Function
}

const PrizeMoneyTableForm: React.FC<_Props> = ({prizeMoneyTable, tableName, organizationId, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [isPercentage, setIsPercentage] = useState(true);
    const [totalPot, setTotalPot] = useState(100);
    const [first, setFirst] = useState(30);
    const [second, setSecond] = useState(22);
    const [third, setThird] = useState(15);
    const [fourth, setFourth] = useState(12);
    const [fifth, setFifth] = useState(11);
    const [sixth, setSixth] = useState(10);
    const [seventh, setSeventh] = useState(0);
    const [eighth, setEighth] = useState(0);
    const [ninth, setNinth] = useState(0);
    const [tenth, setTenth] = useState(0);
    const [eleventh, setEleventh] = useState(0);
    const [twelfth, setTwelfth] = useState(0);
    const [champion, setChampion] = useState(0);
    const [reserve, setReserve] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (tableName) {
            setName(tableName)
        }
        if (prizeMoneyTable) {
            if (prizeMoneyTable.name) setName(prizeMoneyTable.name);
            if (prizeMoneyTable.description) setDescription(prizeMoneyTable.description);
            if (prizeMoneyTable.isPercentage) setIsPercentage(prizeMoneyTable.isPercentage);
            if (prizeMoneyTable.totalPot) setTotalPot(prizeMoneyTable.totalPot);
            if ((prizeMoneyTable.tiers && prizeMoneyTable.tiers.first)) setFirst(parseFloat(prizeMoneyTable.tiers.first));
            if ((prizeMoneyTable.tiers && prizeMoneyTable.tiers.second)) setSecond(parseFloat(prizeMoneyTable.tiers.second));
            if ((prizeMoneyTable.tiers && prizeMoneyTable.tiers.third)) setThird(parseFloat(prizeMoneyTable.tiers.third));
            if ((prizeMoneyTable.tiers && prizeMoneyTable.tiers.fourth)) setFourth(parseFloat(prizeMoneyTable.tiers.fourth));
            if ((prizeMoneyTable.tiers && prizeMoneyTable.tiers.fifth)) setFifth(parseFloat(prizeMoneyTable.tiers.fifth));
            if ((prizeMoneyTable.tiers && prizeMoneyTable.tiers.sixth)) setSixth(parseFloat(prizeMoneyTable.tiers.sixth));
            if ((prizeMoneyTable.tiers && prizeMoneyTable.tiers.seventh)) setSeventh(parseFloat(prizeMoneyTable.tiers.seventh));
            if ((prizeMoneyTable.tiers && prizeMoneyTable.tiers.eighth)) setEighth(parseFloat(prizeMoneyTable.tiers.eighth));
            if ((prizeMoneyTable.tiers && prizeMoneyTable.tiers.ninth)) setNinth(parseFloat(prizeMoneyTable.tiers.ninth));
            if ((prizeMoneyTable.tiers && prizeMoneyTable.tiers.tenth)) setTenth(parseFloat(prizeMoneyTable.tiers.tenth));
            if ((prizeMoneyTable.tiers && prizeMoneyTable.tiers.eleventh)) setEleventh(parseFloat(prizeMoneyTable.tiers.eleventh));
            if ((prizeMoneyTable.tiers && prizeMoneyTable.tiers.twelfth)) setTwelfth(parseFloat(prizeMoneyTable.tiers.twelfth));
            if ((prizeMoneyTable.tiers && prizeMoneyTable.tiers.champion)) setChampion(parseFloat(prizeMoneyTable.tiers.champion));
            if ((prizeMoneyTable.tiers && prizeMoneyTable.tiers.reserve)) setReserve(parseFloat(prizeMoneyTable.tiers.reserve));
            setSuccess("");
            setError("");
        }
    }, [prizeMoneyTable, tableName]);

    const calulateSum = () => {
        let sum = 0;
        sum = sum + ((typeof(first) === "string") ? parseFloat(first) : first);
        sum = sum + ((typeof(second) === "string") ? parseFloat(second) : second);
        sum = sum + ((typeof(third) === "string") ? parseFloat(third) : third);
        sum = sum + ((typeof(fourth) === "string") ? parseFloat(fourth) : fourth);
        sum = sum + ((typeof(fifth) === "string") ? parseFloat(fifth) : fifth);
        sum = sum + ((typeof(sixth) === "string") ? parseFloat(sixth) : sixth);
        sum = sum + ((typeof(seventh) === "string") ? parseFloat(seventh) : seventh);
        sum = sum + ((typeof(eighth) === "string") ? parseFloat(eighth) : eighth);
        sum = sum + ((typeof(ninth) === "string") ? parseFloat(ninth) : ninth);
        sum = sum + ((typeof(tenth) === "string") ? parseFloat(tenth) : tenth);
        sum = sum + ((typeof(eleventh) === "string") ? parseFloat(eleventh) : eleventh);
        sum = sum + ((typeof(twelfth) === "string") ? parseFloat(twelfth) : twelfth);
        sum = sum + ((typeof(champion) === "string") ? parseFloat(champion) : champion);
        sum = sum + ((typeof(reserve) === "string") ? parseFloat(reserve) : reserve);
        return sum;
    }

    const verifyForm = () => {
        try {
            if (!name) {
                setError("You must include a name.");
                return false;
            }

            const sum = calulateSum();
            if (isPercentage) {
                if (sum > 100) {
                    let message = "The sum of the percentage amounts is greater than 100. It is currently: " + sum;
                    setError(message);
                    return false;
                }
            } else {
                if (sum !== totalPot) {
                    let message = "The sum of the amounts is different than your total amount.";
                    setError(message);
                    return false;
                }
            }
            return true;
        } catch (error: any) {
            setError(error);
            return false;
        }
    }

    const clearForm = () => {
        setName("");
        setDescription("");
        setError("");
    } 

    const handleTableChange = (place: string, value: number) => {
        if (place === "first") setFirst(value);
        if (place === "second") setSecond(value);
        if (place === "third") setThird(value);
        if (place === "fourth") setFourth(value);
        if (place === "fifth") setFifth(value);
        if (place === "sixth") setSixth(value);
        if (place === "seventh") setSeventh(value);
        if (place === "eighth") setEighth(value);
        if (place === "ninth") setNinth(value);
        if (place === "tenth") setTenth(value);
        if (place === "eleventh") setEleventh(value);
        if (place === "twelfth") setTwelfth(value);
        if (place === "champion") setChampion(value);
        if (place === "reserve") setReserve(value);
        if (place === "totalPot") setTotalPot(value);
    }

    const handleCreateTiers = () => {
        const tiers: PrizeMoneyTiers = {
            first: first.toString(),
            second: second.toString(),
            third: third.toString(),
            fourth: fourth.toString(),
            fifth: fifth.toString(),
            sixth: sixth.toString(),
            seventh: seventh.toString(),
            eighth: eighth.toString(),
            ninth: ninth.toString(),
            tenth: tenth.toString(),
            eleventh: eleventh.toString(),
            twelfth: twelfth.toString(),
            champion: champion.toString(),
            reserve: reserve.toString(),
        };
        return tiers;
    }

    const handleCreatePrizeMoneyTable = async () => {
        try {
            const tiers = handleCreateTiers();
            const input: CreatePrizeMoneyTableInput = {
                createdBy: user.id,
                name: name,
                description: description,
                displayOrder: 1,
                organizationId: organizationId,
                isPercentage: isPercentage,
                totalPot: totalPot,
                tiers: tiers
            };
            const createResult = await createPrizeMoneyTable(input);
            if (createResult.isSuccess) {
                clearForm();
                const message = "Successfully created the prize money table: " + name;
                setSuccess(message);
                onSubmit(createResult.result);
            } else {
                setError(createResult.message);
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleEditPrizeMoneyTable = async () => {
        try {
            if (prizeMoneyTable) {
                const tiers = handleCreateTiers();
                const input: UpdatePrizeMoneyTableInput = {
                    id: prizeMoneyTable.id,
                    name: name,
                    description: description,
                    displayOrder: 1,
                    organizationId: organizationId,
                    isPercentage: isPercentage,
                    totalPot: totalPot,
                    tiers: tiers
                };
                const updateResult = await updatePrizeMoneyTable(input);
                if (updateResult.isSuccess) {
                    onSubmit(updateResult.result);
                    clearForm();
                    const message = "Successfully updated the prize money table: " + name;
                    setSuccess(message);
                } else {
                    setError(updateResult.message);
                }
            } else {
                setError("Sorry, an error occurred. Please refresh and try again.");
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setSuccess("");
        const isValid = verifyForm();
        if (isValid) {
            setError("");
            if (prizeMoneyTable) {
                await handleEditPrizeMoneyTable();
            } else {
                await handleCreatePrizeMoneyTable();
            }
        }
        setIsDisabled(true);
        setIsLoading(false);
    }

    const handleDelete = async () => {
        setIsLoading(true);
        if (prizeMoneyTable) {
            const deleteResult = await deletePrizeMoneyTable({id: prizeMoneyTable.id});
            if (deleteResult.isSuccess) {
                clearForm();
                setSuccess("Deleted the Prize Money Table");
            } else {
                setError(deleteResult.message);
            }
        }
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name <RequiredInputIndicator/></IonLabel>
                            <IonInput 
                                type="text"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Description</IonLabel>
                            <IonInput 
                                type="text"
                                value={description}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setDescription(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonRadioGroup value={isPercentage} onIonChange={e => setIsPercentage(e.detail.value)}>
                            <IonItem color="white">
                                <IonLabel>Use Percentages</IonLabel>
                                <IonRadio slot="end" value={true} />
                            </IonItem>    
                            <IonItem color="white">
                                <IonLabel>Use Fixed Amounts</IonLabel>
                                <IonRadio slot="end" value={false} />
                            </IonItem>   
                        </IonRadioGroup>
                    </IonCol>
                </IonRow>
                {isPercentage ?
                    <PrizeMoneyPercentageTable tiers={prizeMoneyTable?.tiers} isEditable={true} onSubmit={handleTableChange} />
                    :
                    <PrizeMoneyFixedTable tiers={prizeMoneyTable?.tiers} totalPot={prizeMoneyTable?.totalPot} isEditable={true} onSubmit={handleTableChange} />
                }
                {error && <ErrorAlert width="12" error={error}/>}
                {success && <SuccessBanner width="12" success={success}/>}
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                Save
                            </IonButton>
                        }
                    </IonCol>
                    {prizeMoneyTable && (
                        <IonCol offsetMd="4" sizeMd="4">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    disabled={isDisabled}
                                    className="ion-margin-top"
                                    color="danger"
                                    expand="block"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </IonButton>
                            }
                        </IonCol>
                    )}
                </IonRow>
            </form>
        </>
    );
};

export default PrizeMoneyTableForm;