import { IonCard, IonCardContent, IonCol, IonIcon, IonLabel, IonRow } from "@ionic/react";
import React from "react";
import {alertCircleOutline} from "ionicons/icons";

interface _Props {
    error: string
}

const ErrorBanner: React.FC<_Props> = ({error}) => {
    return (
        <>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeSm="12" sizeMd="8" className="ion-no-padding">
                    <IonCard color="danger" mode="md">
                        <IonCardContent>
                            <IonIcon icon={alertCircleOutline} />
                            <IonLabel id="errorMsg" className="ion-text-wrap description font-weight-bold">
                                {" " + error}
                            </IonLabel>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </>
    );
};

export default ErrorBanner;
