import { PointTable } from "../../models";

export const calculatePointsFromTable = (placeValue: number, pointTable: PointTable) => {
    if (pointTable) {
        let pointValue = "0";
        if (pointTable && pointTable.tiers && pointTable.tiers[0]) {
            const tier = pointTable.tiers[0];
            if (placeValue === 1) pointValue = tier["first"] || "0";
            if (placeValue === 2) pointValue = tier["second"] || "0";
            if (placeValue === 3) pointValue = tier["third"] || "0";
            if (placeValue === 4) pointValue = tier["fourth"] || "0";
            if (placeValue === 5) pointValue = tier["fifth"] || "0";
            if (placeValue === 6) pointValue = tier["sixth"] || "0";
            if (placeValue === 7) pointValue = tier["seventh"] || "0";
            if (placeValue === 8) pointValue = tier["eighth"] || "0";
            if (placeValue === 9) pointValue = tier["ninth"] || "0";
            if (placeValue === 10) pointValue = tier["tenth"] || "0";
            if (placeValue === 11) pointValue = tier["eleventh"] || "0";
            if (placeValue === 12) pointValue = tier["twelfth"] || "0";
            if (placeValue === -1) pointValue = tier["champion"] || "0";
            if (placeValue === -2) pointValue = tier["reserve"] || "0";
        }
        const floatValue = parseFloat(pointValue);
        return floatValue || 0;
    }
}

export const calculateRHSCPlaceFromPoints = (pointValue: number) => {
    if (pointValue === 6) return 1;
    if (pointValue === 5) return 2;
    if (pointValue === 4) return 3;
    if (pointValue === 3) return 4;
    if (pointValue === 2) return 5;
    if (pointValue === 1) return 6;
}