import { Event, EventOfficial } from "../../../models";
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../../Errors/ErrorAlert";
import InformationBanner from "../../Banners/InformationBanner";
import OfficialForm from "../../EventOfficial/EventOfficialForm";
import Spinner from "../../Spinners/Spinner";
import { close } from "ionicons/icons";
import { DeleteEventOfficialInput } from "../../../API";
import { deleteEventOfficial, getEventOfficialsByEventId } from "../../../utilities/eventOfficial/EventOfficial";

interface _Props {
    event: Event
}

const EventOfficialsForm: React.FC<_Props> = ({event}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [eventOfficials, setEventOfficials] = useState<(EventOfficial | null)[] | null | undefined>(event.officials || undefined);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    async function getHorseShowOfficials(eventId: string) {
        const queryResult = await getEventOfficialsByEventId(eventId);
        if (queryResult.isSuccess) {
            const officials: EventOfficial[] = queryResult.result;
            const sorted = officials.sort((a, b) => (a.createdAt || "").localeCompare(b.createdAt || ""));
            setEventOfficials(sorted);
        }
    };

    useEffect(() => {
        if (event) {
            getHorseShowOfficials(event.id);
        }
    }, [event]);

    const handleSubmit = async (newOfficial: EventOfficial) => {
        setIsLoading(true);
        let officialArray: (EventOfficial | null)[] = [];
        if (eventOfficials && eventOfficials.length) {
            officialArray = eventOfficials.concat([newOfficial]);
        } else {
            officialArray.push(newOfficial);
        }
        setEventOfficials(officialArray);
        setIsLoading(false);
        setShowModal(false);
    }

    const handleDelete = async (eventOfficial?: EventOfficial) => {
        let newOfficialArray = [];
        if (event && eventOfficials && eventOfficials.length) {
            for (var i = 0; i < eventOfficials?.length; i++) {
                const current = eventOfficials[i];
                if (current && eventOfficial) {
                    if (current.name !== eventOfficial.name) {
                        newOfficialArray.push(current);
                    }
                }
            }
            setEventOfficials(newOfficialArray);

            if (eventOfficial) {
                const deleteInput: DeleteEventOfficialInput = {
                    id: eventOfficial?.id
                };
                const deleteResult = await deleteEventOfficial(deleteInput);
                if (!deleteResult.isSuccess) setError("Could not delete the event official.");
            } else {
                setError("No event official to delete.");
            }
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {eventOfficials && eventOfficials.length ?
                <>
                    <IonRow>
                        <IonCol>
                            <IonList className="bg-white">
                                {eventOfficials.map((official, index) => (
                                    <IonItem key={index}>
                                        <IonLabel>
                                            <IonRow>
                                                <IonCol sizeXs="12" sizeMd="6">
                                                    <h3 className="ion-text-wrap text-darker">{official && official.name}</h3>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="6">
                                                    <h4 className="ion-text-wrap text-darker">{official && official.position}</h4>
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                        <IonButton slot="end" color="danger" onClick={() => handleDelete(official as EventOfficial)}><IonIcon icon={close}/></IonButton>
                                    </IonItem>
                                ))}
                            </IonList>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                Add Official
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                Add Official
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
            <IonModal backdropDismiss={false} isOpen={showModal} id="addEventOfficialModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Official
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeOfficialModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            <InformationBanner info="Include any event managers, secretaries, stewards, etc." />
                            <OfficialForm onSubmit={handleSubmit} event={event} />
                        </>
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventOfficialsForm;