import React, { useEffect, useState } from "react";
import { OrganizationClass, OrganizationDivision } from "../../models";
import { getOrganizationClassesByOrganizationId } from "../../utilities/organizationClass/OrganizationClass";
import { sortOrganizationClassesByName } from "../../utilities/organizationClass/SortOrganizationClasses";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    menuPlacement?: string
    selectedValue?: string
    organizationId: string
    onSelect: Function
}

const SelectOrganizationClass: React.FC<_Props> = ({organizationId, selectedValue, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedOptions, setFormattedOptions] = useState<formattedOption[] | null | undefined>();

    const getClasses = async (organizationId: string) => {
        const queryResult = await getOrganizationClassesByOrganizationId(organizationId);
        if (queryResult.isSuccess) {
            const list = queryResult.result;
            const sorted = sortOrganizationClassesByName(list);
            return sorted || list;
        }
    }

    const formatOptions = (items: OrganizationClass[]) => {
        let formattedOptions = [];
        for (var i = 0; i < items.length; i++) {
            const value = items[i].id.toString();
            const label = items[i].name;
            let object: formattedOption = {
                value: value,
                label: label,
                object: items[i]
            };
            formattedOptions.push(object);
        }
        setFormattedOptions(formattedOptions);
    }

    const getOptions = async (organizationId: string) => {
        const classes = await getClasses(organizationId);
        formatOptions(classes);
    }

    useEffect(() => {
        if (organizationId) getOptions(organizationId);
    }, [organizationId]);

    useEffect(() => {
        if (selectedValue) setSelected(selectedValue)
    }, [selectedValue]);

    

    const handleOnChange = (event?: any) => {
        if (event) {
            onSelect(event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedOptions ?
                <BasicSelect formattedValues={formattedOptions} menuPlacement={menuPlacement} selectedValue={selected} onSelect={handleOnChange} />
                :
                <p>Loading options...</p>
            }
        </>
    )
};

export default SelectOrganizationClass;