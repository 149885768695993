import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow
} from "@ionic/react";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { CreateTaxRateInput, UpdateTaxRateInput } from "../../API";
import { Organization, TaxRate } from "../../models";
import { createTaxRate, deleteTaxRate, updateTaxRate } from "../../utilities/taxRate/TaxRate";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";

interface _Props {
    organizationId?: (string | null)
    selectedTax?: (TaxRate | null)
    onSubmit: Function
}

const TaxForm: React.FC<_Props> = ({selectedTax, organizationId, onSubmit}) => {
    const user = useContext(PersonContext)
    
    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState<string>("");
    const [percentage, setPercentage] = useState<number>(0);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (selectedTax) {
            setName(selectedTax.name || "");
            setPercentage(selectedTax?.percentage || 0);
        } else {
            clearForm();
        }
    }, [selectedTax]);

    const verifyForm = () => {
        if (!name) {
            setError("Please include an informative name so exhibitors know what this tax is. Example: Sales Tax.");
            return false;
        }
        if (!percentage) {
            setError("Please include a percentage amount. For a 10% tax, type 10");
            return false;
        }
        return true;
    }

    const clearForm = () => {
        setName("");
        setPercentage(0);
        selectedTax = undefined;
    }

    const handleCreateTaxRates = async () => {
        if (organizationId) {
            const input: CreateTaxRateInput = {
                organizationId: organizationId,
                name: name,
                percentage: percentage,
                createdBy: user.id
            };
            const createResult = await createTaxRate(input);
            if (createResult.isSuccess) {
                onSubmit(true, createResult.result);
                clearForm();
            } else {
                setError("Sorry, we had a problem creating the stall type.");
            }
        } else {
            setError("Sorry, we could not find your organization.")
        }
    }

    const handleUpdateTaxRate = async () => {
        if (organizationId && selectedTax) {
            const input: UpdateTaxRateInput = {
                id: selectedTax.id,
                organizationId: organizationId,
                name: name,
                percentage: percentage
            };
            const updateResult = await updateTaxRate(input);
            if (updateResult.isSuccess) {
                onSubmit(false, updateResult.result);
                clearForm();
            } else {
                setError("Sorry, we had a problem updating the stall type.");
            }
        } else {
            setError("Sorry, we could not find your organization.")
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            if (selectedTax) {
                await handleUpdateTaxRate();
            } else {
                await handleCreateTaxRates();
            }
            setIsDisabled(true);
        }
        setIsLoading(false);
    }

    const handleDelete = async () => {
        setIsLoading(true);
        setError("");
        if (selectedTax) {
            const deleteResult = await deleteTaxRate({id: selectedTax.id});
            if (deleteResult.isSuccess) {
                onSubmit(false, deleteResult.result);
                clearForm();
            } else {
                setError("Could not delete the organization stall type.");
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name (will display on invoices)</IonLabel>
                            <IonInput 
                                type="text"
                                placeholder="Ex: Sales Tax"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="6">
                        <IonItem color="white" lines="none">
                            <IonLabel position="stacked" className="ion-margin-bottom">Percentage</IonLabel>
                            <InputGroup className="ion-margin-top">
                                <Input
                                    type="number"
                                    value={percentage}
                                    min={0}
                                    max={100}
                                    onChange={e => {
                                        if(isMounted) setIsDisabled(false);
                                        else setIsMounted(true);
                                        setPercentage(parseFloat(e.target.value));
                                    }}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>%</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    {selectedTax && (
                        <IonCol sizeMd="4">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    className="ion-margin-top"
                                    color="danger"
                                    expand="block"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </IonButton>
                            }
                        </IonCol>
                    )}
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default TaxForm;