import {
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import PostForm from "../../../../../components/Posts/PostForm";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import { Organization, Post } from "../../../../../models";
import Spinner from "../../../../../components/Spinners/Spinner";
import { getPostById } from "../../../../../utilities/posts/Post";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";

interface _Props extends RouteComponentProps<{
    id: string;
}> {}

const EditPostPage: React.FC<_Props> = ({match}) => {

    const [organization, setOrganization] = useState<Organization | null>(null);
    const [post, setPost] = useState<Post | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        async function getOrganization() {
            if(post?.organizationId) {
                setIsLoading(true);
                const queryResult = await getOrganizationById(post.organizationId);
                if (queryResult.isSuccess) {
                    setOrganization(queryResult.result);
                } else {
                    setError(queryResult.message);
                }
                setIsLoading(false);
            }
        }
        getOrganization();
    }, [post]);
    
    useEffect(() => {
        async function getPost() {
            setIsLoading(true);
            const queryResult = await getPostById(match.params.id);
            if (queryResult.isSuccess) {
                setPost(queryResult.result);
            } else {
                setError(queryResult.message);
            }
            setIsLoading(false);
        }
        getPost();
    }, [match, match.params.id]);

   
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                {error && <ErrorAlert width="12" error={error} />}
                <PageTitle title={`${(organization?.nickname || organization?.name) ?? ""} Posts`} />
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeXs="12" sizeMd="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardContent>
                                {organization && post && !isLoading ?
                                    <PostForm post={post} organization={organization}  name="Edit Post"/>
                                :   
                                    <Spinner/> 
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EditPostPage;
