import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonLabel,
    IonModal,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useState} from "react";

const OrganizationStallsExplanationModal: React.FC = () => {
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <IonLabel>
            <IonButton 
                id="organizationDivisionExplanationBtn"
                onClick={() => setShowModal(true)}
                fill="clear"
                className="ion-no-padding"
            >
                <IonText className="font-weight-normal text-primary text-capitalize initialism ion-text-wrap ion-text-start">
                    Learn More
                </IonText>
            </IonButton>
            <IonModal backdropDismiss={false} isOpen={showModal} id="organizationDivisionExplanationModal">
                <IonHeader>
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                            Organization Stalls
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowModal(false)}
                            >
                                <p id="closeOrganizationDivisionExplanationModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    X
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonText>To save you time, we allow you to reuse any stall settings that you create. Any member of your organization can quickly use these settings for an event.</IonText>
                    <hr />
                    <IonText>We consider a "Stall Type" to be a combination of "what the stall is" + "price of the stall".</IonText>
                    <hr />
                    <IonText>For example: you can have a stall type with name "Overnight Stall" with price "$100 / stall". You can also have a stall type with name "Day Stall" with price "$60 / stall".</IonText>
                    <hr />
                    <IonText>When someone submits an entry, they can include a request for x quantity of "Overnight Stalls" and y quantity of "Day Stalls".</IonText>
                    <hr />
                    <IonText>If you want to charge different prices for different stalls, you must have multiple stall types.</IonText>
                    <hr />
                    <IonText>We consider a "Stall Set" to be a specific set of stalls you have on the property for use.</IonText>
                    <hr />
                    <IonText>Stall sets are for internal use by you and your staff. You will use them to help you track available vs used stalls.</IonText>
                    <hr />
                    <IonText>For example: a stall set may be "Barn A" has available: "Stalls 1-20".</IonText>
                    <hr />
                    <IonText>When a stabling request is submitted by an exhibitor, you can use your stall sets to see what barns have available stalls.</IonText>
                </IonContent>
                <IonButton id="CloseOrganizationDivisionExplanationModalBtn" onClick={() => handleClose()}>
                    Close
                </IonButton>
            </IonModal>
        </IonLabel>
    );
};

export default OrganizationStallsExplanationModal;
