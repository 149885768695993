import moment from "moment";
import { BacklogEventResult, Event, EventClass, EventClassEntry, EventResult } from "../../models";
import { getBacklogEventResultsByEventClassId, getBacklogEventResultsByEventId } from "../backlogEventResult/BacklogEventResult";
import { getEventClassesByEventId } from "../eventClass/EventClass";
import { sortEventClassesByNumber } from "../eventClass/SortEventClass";
import { getEventClassEntriesByEventClassId, getEventClassEntryById } from "../eventClassEntry/EventClassEntry";
import { getEventResultsByEventClassId } from "../eventResult/EventResult";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export async function generateClassResultsWithFewerThan6Report(event: Event) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Classes with Fewer than 6 Results Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    if (moment(event.startDate).isAfter("2022-05-01")) {
        // define the columns we want and their titles
        const tableColumn = ["Class Number", "Class Name", "Total Entries", "Results Set", "# Results in Set"];
    
        const tableRows: any[] = [];

        const queryResult = await getEventClassesByEventId(event.id);
        if (queryResult.isSuccess) {
            const eventClasses = queryResult.result as EventClass[];
            const sorted = sortEventClassesByNumber(eventClasses);
            if (sorted && sorted.length > 0) {
                for (let i = 0; i < sorted.length; i++) {
                    const eventClass = sorted[i];

                    // Start the class's row
                    const row = [
                        eventClass.number || "",
                        eventClass.name
                    ];

                    // Include total number of entries
                    let eventClassEntries: EventClassEntry[] = [];
                    const entryResult = await getEventClassEntriesByEventClassId(eventClass.id);
                    if (entryResult.isSuccess) {
                        eventClassEntries = entryResult.result;
                        row.push(eventClassEntries.length || 0);
                    }

                    // Get class' results
                    const dataResult = await getEventResultsByEventClassId(eventClass.id);
                    if (dataResult.isSuccess) {
                        const eventClassResults = dataResult.result;
                        if (eventClassResults.length > 0) {
                            let firstResultSet: EventResult[] = [];
                            let secondResultSet: EventResult[] = [];

                            eventClassResults.forEach((result: EventResult) => {
                                if (result.resultSet && result.resultSet === "2") {
                                    if (result.place && result.place > 0 && result.place <= 6) secondResultSet.push(result);
                                } else {
                                    if (result.place && result.place > 0 && result.place <= 6) firstResultSet.push(result);
                                }
                            });

                            row.push(1);

                            row.push(firstResultSet.length);

                            let foundErrorInFirst = false;

                            if (firstResultSet.length < 6) {
                                if (eventClassEntries.length !== firstResultSet.length) foundErrorInFirst = true;
                            }

                            if (foundErrorInFirst) tableRows.push(row);

                            if (secondResultSet && secondResultSet.length > 0) {
                                let foundErrorInSecond = false;
                                if (secondResultSet.length < 6) {
                                    if (eventClassEntries.length !== secondResultSet.length) foundErrorInSecond = true;
                                }

                                if (foundErrorInSecond || foundErrorInFirst) {

                                    // Since there is an error in teh 2nd set, push the 1st set as well
                                    if (!foundErrorInFirst) tableRows.push(row);
                                    
                                    // Push in a second row for the second set
                                    const secondRow = [
                                        "",
                                        "",
                                        "",
                                        2,
                                        secondResultSet.length || 0
                                    ];
                                    tableRows.push(secondRow);
                                }
                            }
                        }
                    };
                }
            }
        }

        // startY is basically margin-top
        doc.autoTable(tableColumn, tableRows, { 
            theme: "grid",
            headStyles: {fillColor: "#73a4d3"},
            startY: 35 
        });
    } else {
        // define the columns we want and their titles
        const tableColumn = ["Class Number", "Class Name", "Total Entries", "# Results in Set"];
    
        const tableRows: any[] = [];

        let eventClasses: EventClass[] = [];
        const eventClassesResult = await getEventClassesByEventId("0c98842f-66d7-49d7-8f9e-8d3ae99eaaf6");
        if (eventClassesResult.isSuccess) {
            eventClasses = eventClassesResult.result;
        }

        const backlogEventResultsQuery = await getBacklogEventResultsByEventId(event.id);
        if (backlogEventResultsQuery.isSuccess) {
            const backlogEventResults: BacklogEventResult[] = backlogEventResultsQuery.result;

            const resultsMap = new Map();

            if (backlogEventResults && backlogEventResults.length > 0) {
                for (let i = 0; i < backlogEventResults.length; i++) {
                    const backlogEventResult: BacklogEventResult = backlogEventResults[i];
                    
                    const result: BacklogEventResult[] | undefined = resultsMap.get(backlogEventResult.eventClassId);
                    console.log("Result: ", result);
                    if (result) {
                        const updatedArray = result.concat([backlogEventResult]);
                        resultsMap.set(backlogEventResult.eventClassId, updatedArray);
                    } else {
                        resultsMap.set(backlogEventResult.eventClassId, [backlogEventResult]);
                    }
                }
            }

            resultsMap.forEach((key, value) => {
                console.log("Key: ", key);
                console.log("Value: ", value);

                if (value) {
                    let eventClass: EventClass | undefined = eventClasses.find(ec => ec.id === value);

                    const currentRow = [
                        eventClass?.number || 0,
                        eventClass?.name || "",
                        "",
                        key.length
                    ];

                    tableRows.push(currentRow);
                }
            });
        }

        const sorted = tableRows.sort((a, b) => a[0] - b[0]);

        // startY is basically margin-top
        doc.autoTable(tableColumn, sorted, { 
            theme: "grid",
            headStyles: {fillColor: "#73a4d3"},
            startY: 35 
        });
    }

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    doc.save(`${event.name}_classes_less_than_6_results.pdf`);
}

export async function generateBasicClassResultsReport(event: Event) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Class Results Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = ["Class Number", "Class Name", "Place", "Entry Number", "Horse", "Rider", "Trainer"];
    // define an empty array of rows
    const tableRows: string[][] = [];

    const queryResult = await getEventClassesByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventClasses = queryResult.result as EventClass[];
        const sorted = sortEventClassesByNumber(eventClasses);
        if (sorted && sorted.length > 0) {
            for (let i = 0; i < sorted.length; i++) {
                const eventClass = sorted[i];
                const dataResult = await getEventResultsByEventClassId(eventClass.id);
                if (dataResult.isSuccess) {
                    const eventClassResults = dataResult.result;
                    if (eventClassResults.length > 0) {
                        const sortedResults = eventClassResults.sort((a: EventResult, b: EventResult) => (a.place || 0) - (b.place || 0));
                        for (let j = 0; j < sortedResults.length; j++) {
                            const result: EventResult = sortedResults[j];
                            let riderName = result.entry.riderName;
                            if (result.eventClassEntryId) {
                                const eventClassEntryResult = await getEventClassEntryById(result.eventClassEntryId);
                                if (eventClassEntryResult.isSuccess) {
                                    const eventClassEntry: EventClassEntry = eventClassEntryResult.result;
                                    if (eventClassEntry && eventClassEntry.rider && eventClassEntry.rider?.name) riderName = eventClassEntry.rider?.name;
                                }
                            }
                            const row = [
                                eventClass.number?.toString() || "",
                                eventClass.name || "",
                                result.place?.toString() || "",
                                result.entry.number?.toString() || "",
                                result.entry.horseName || "",
                                riderName || "",
                                result.entry.trainerName || ""
                            ];
                            tableRows.push(row);
                        }
                    }
                };
            }
        }
    }
     
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 35 
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    doc.save(`class_results.pdf`);
}