import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../../components/Headers/Header";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { Organization } from "../../../../../../models";
import { getOrganizationById } from "../../../../../../utilities/organization/Organization";
import OrganizationStallsExplanationModal from "../../../../../../components/Stalls/OrganizationStallsExplanationModal";
import OrganizationStallTypeForm from "../../../../../../components/Stalls/OrganizationStallTypeForm";
import OrganizationStallSetForm from "../../../../../../components/Stalls/OrganizationStallSetForm";
import OrganizationBeddingTypeForm from "../../../../../../components/Stalls/OrganizationBeddingTypeForm";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

const OrganizationStallsPage: React.FC<OrganizationPageProps> = ({match}) => {
    const [organization, setOrganization] = useState<Organization>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOrganization() {
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                setOrganization(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getOrganization();
    }, [match, match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={organization ? organization.name : "Event Settings"} />
                <IonRow className="ion-justify-content-center">
                    {error && <ErrorAlert width="12" error={error} />}
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Organization Stall Settings</IonCardTitle>
                            <IonCardSubtitle><OrganizationStallsExplanationModal /></IonCardSubtitle>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeSm="12" sizeMd="6">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Stall Types</IonCardTitle>
                            <IonCardContent>
                                {organization ? 
                                    <OrganizationStallTypeForm organization={organization} />
                                    :
                                    <p>Loading...</p>
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    <IonCol sizeSm="12" sizeMd="6">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Bedding Options</IonCardTitle>
                            <IonCardContent>
                                {organization ? 
                                    <OrganizationBeddingTypeForm organization={organization} />
                                    :
                                    <p>Loading...</p>
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeSm="12" sizeMd="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Stall Sets</IonCardTitle>
                            <IonCardContent>
                                {organization ? 
                                    <OrganizationStallSetForm organization={organization} />
                                    :
                                    <p>Loading...</p>
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default OrganizationStallsPage;