import React, { useEffect, useState } from "react";
import { Membership, Organization, OrganizationMembershipType, MembershipTypeCategory } from "../../models";
import { IonButton, IonCard, IonCardContent, IonCol, IonRow } from "@ionic/react";
import Spinner from "../Spinners/Spinner";
import StripeTransferPaymentForm from "../Stripe/StripeTransferPaymentForm";
import { UpdateMembershipInput } from "../../API";
import { updateMembership } from "../../utilities/membership/Membership";
import ErrorAlert from "../Errors/ErrorAlert";
import CONSTANT from "../../constant/constant";

interface _Props {
    membershipType: OrganizationMembershipType;
    memberships?: Membership[]
    organization?: Organization;
    setSegmentValue: Function
    setMemberships: Function
}

const MembershipApplicationPaymentForm: React.FC<_Props> = ({ membershipType, memberships, organization, setSegmentValue, setMemberships }) => {
    // If this is a indvidual membership and the memberships array has an item, we will use this membership
    const membership: (Membership | undefined) = (membershipType.category ===  MembershipTypeCategory.INDIVIDUAL && memberships) ? memberships[0] : undefined;

    const [returnURL, setReturnURL] = useState("/index/home");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    function getReturnURL(organization: Organization, membershipType: OrganizationMembershipType) {
        const baseURL = "http://localhost:8100";
        const path = "/index/" + organization.urlBackHalf + "/application/" + membershipType.id;
        const returnURL: string = baseURL + path;
        setReturnURL(returnURL);
    }

    useEffect(() => {
        function checkIfReadyForNextStep(membershipType: OrganizationMembershipType, membership?: Membership) {
            if (!membershipType.price || membershipType.price === 0) {
                setMessage("This application does not have a fee. Please continue to the next step.");
            } else if (!membershipType.applicationOptions?.acceptRSPPayment) {
                setMessage("Online payments are not enabled. Please pay the organization directly.");
            } else if (!(organization && organization?.stripeId)) {
                setMessage("This organization does not have online payments set up. Please pay the organization directly.");
            } else if (membership && membership.amountPaid === membershipType.price) {
                setMessage("The payment is complete!")
            }
        }

        if (membershipType) {
            checkIfReadyForNextStep(membershipType, membership);
            if (organization) {
                getReturnURL(organization, membershipType);
            }
        }
    }, [organization, membershipType]);

    async function handleCompletedPayment() {
        // Clear the error if it is on the page
        setError("");

        // Create the error message if the membership record update does not succeed
        const errorMsg = "The payment went through, but could not be recorded in the system. Please contact hello@ringsidepro.com to correct this.";

        if (memberships?.length) {
            const updatedMembershipArray: Membership[] = [];
            for (let i = 0; i < memberships.length; i++) {
                const membership: Membership = memberships[i];
                if (membership && membership.id) {
                    const membershipUpdateInput: UpdateMembershipInput = {
                        id: membership.id,
                        amountPaid: membershipType?.price
                    };
                    const updateResult = await updateMembership(membershipUpdateInput);
                    if (updateResult.isSuccess) {
                        updatedMembershipArray.push(updateResult.result);
                    } else {
                        setError(errorMsg);
                    }
                } else {
                    setError(errorMsg);
                }
            }
            setMemberships(updatedMembershipArray);
            navigateToNextStep();
        } else {
            setError("No membership was found.");
        }
    }

    function navigateToNextStep() {
        setMemberships(memberships);
        setSegmentValue(CONSTANT.MEMBERSHIP.APPLICATION_STAGES.CONFIRMATION);
    }

    return (
        <IonCard color="white" className="ion-padding card-max-height">
            <h3>{membershipType?.name}</h3>
            <IonCardContent>
                {error && <ErrorAlert width="12" error={error} />}
                <IonRow>
                    <IonCol className="ion-text-center">
                        <h1>Total Due: ${membershipType?.price}</h1>
                    </IonCol>
                </IonRow>
                {message ?
                    <IonRow className="mb-2 mt-4 ion-justify-content-center">
                        <IonCol sizeXs="12" sizeMd="12" className="ion-text-center">
                            <p>{message}</p>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                            <IonButton
                                    id="mt-payment-next-btn"
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={() => {navigateToNextStep()}}
                                >
                                    Next
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    :
                    <>
                        {/* First, load the membership type */}
                        {membershipType ?
                            <>
                                {(organization && membershipType.price) &&
                                    <StripeTransferPaymentForm organization={organization} price={membershipType.price} returnURL={returnURL} handleSubmit={handleCompletedPayment}/>
                                }
                            </>
                            :
                            <Spinner />
                        }
                    </>
                }
            </IonCardContent>
        </IonCard>
    );
};

export default MembershipApplicationPaymentForm;
