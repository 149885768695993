import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreatePaymentMethodInput, CreatePaymentMethodMutation, DeletePaymentMethodInput, DeletePaymentMethodMutation, GetPaymentMethodQuery, ListPaymentMethodsQuery, UpdatePaymentMethodInput, UpdatePaymentMethodMutation } from "../../API";
import moment from "moment";
import { PaymentMethod } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: paymentMethod. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created paymentMethod.
*/
export async function createPaymentMethod(input: CreatePaymentMethodInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "PaymentMethod", "No input", "Create PaymentMethod received no input.");
   try {
        const fullInput: CreatePaymentMethodInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createPaymentMethod, { input: fullInput }))) as GraphQLResult<CreatePaymentMethodMutation>;
       else result = (await API.graphql({
           query: mutations.createPaymentMethod,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreatePaymentMethodMutation>;
       const paymentMethod = result.data?.createPaymentMethod;
       return formatResult(true, "PaymentMethod", paymentMethod, "Successfully created the paymentMethod.");
   } catch (error: any) {
       return formatResult(false, "PaymentMethod", error, "Error creating record in the database for type: paymentMethod");
   }
}

/**
* Handle updating a new record in the database for type: paymentMethod. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated paymentMethod.
*/
export async function updatePaymentMethod(input: UpdatePaymentMethodInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "PaymentMethod", "No input", "Update PaymentMethod received no input.");
   try {
        const fullInput: UpdatePaymentMethodInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updatePaymentMethod, { input: fullInput }))) as GraphQLResult<UpdatePaymentMethodMutation>;
       else result = (await API.graphql({
           query: mutations.updatePaymentMethod,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdatePaymentMethodMutation>;
       const paymentMethod = result.data?.updatePaymentMethod;
       return formatResult(true, "PaymentMethod", paymentMethod, "Successfully updated the paymentMethod.");
   } catch (error: any) {
       return formatResult(false, "PaymentMethod", error, "Error updating record in the database for type: paymentMethod");
   }
}

/**
* Handle deleting a new record in the database for type: paymentMethod. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted paymentMethod.
*/
export async function deletePaymentMethod(input: DeletePaymentMethodInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "PaymentMethod", "No input", "Delete PaymentMethod received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deletePaymentMethod, { input: input }))) as GraphQLResult<DeletePaymentMethodMutation>;
       else result = (await API.graphql({
           query: mutations.deletePaymentMethod,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeletePaymentMethodMutation>;
       const paymentMethod = result.data?.deletePaymentMethod;
       return formatResult(true, "PaymentMethod", paymentMethod, "Successfully deleted the paymentMethod.");
   } catch (error: any) {
       return formatResult(false, "PaymentMethod", error, "Error deleting record in the database for type: paymentMethod");
   }
}

/**
* Get all records in the database for type: paymentMethod. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the paymentMethod object
*/
export async function getAllPaymentMethods(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listPaymentMethods,
            variables: {
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPaymentMethodsQuery>;

        let items = result.data?.listPaymentMethods?.items as PaymentMethod[];
        let nextToken = result.data?.listPaymentMethods?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listPaymentMethods,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListPaymentMethodsQuery>;

            const nextItems = nextResult.data?.listPaymentMethods?.items as PaymentMethod[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPaymentMethods?.nextToken;
        }

        return formatResult(true, "PaymentMethod", items, "Successfully got the PaymentMethods.");
    } catch (error: any) {
        return formatResult(false, "PaymentMethod", error, "Error reading record in the database for type: paymentMethods");
    }
}

/**
* Read a specific record in the database for type: paymentMethod. 
* 
* @param {string}  id                  The paymentMethod id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the paymentMethod object
*/
export async function getPaymentMethodById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getPaymentMethod,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetPaymentMethodQuery>;
       const paymentMethod = result.data?.getPaymentMethod;
       return formatResult(true, "PaymentMethod", paymentMethod, "Successfully got the paymentMethod.");
   } catch (error: any) {
       return formatResult(false, "PaymentMethod", error, "Error reading record in the database for type: paymentMethod");
   }
}

/**
* Get all records in the database that match the given criteria for type: paymentMethod. 
* 
* @param {string}  personId            The person id of the paymentMethod.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the paymentMethod object
*/
export async function getPaymentMethodByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listPaymentMethods,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPaymentMethodsQuery>;

        let items = result.data?.listPaymentMethods?.items as PaymentMethod[];
        let nextToken = result.data?.listPaymentMethods?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listPaymentMethods,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListPaymentMethodsQuery>;

            const nextItems = nextResult.data?.listPaymentMethods?.items as PaymentMethod[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPaymentMethods?.nextToken;
        }
        
        const paymentMethods = items;
        if (paymentMethods && paymentMethods[0]) return formatResult(true, "PaymentMethod", paymentMethods[0], "Successfully got the paymentMethod.");
        else return formatResult(false, "PaymentMethod", null, "Could not find the paymentMethod.");
    } catch (error: any) {
        return formatResult(false, "PaymentMethod", error, "Error reading record in the database for type: paymentMethod");
    }
}
