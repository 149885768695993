import { Address } from "../../models";

export function createAddressName(personId: string, type: string) {
    return personId + "_address_" + type;
}

export function formatAddress(address: Address): string {
    let addressString = "";
    if (address.streetAddress1) addressString = addressString + address.streetAddress1 + " ";
    if (address.streetAddress2) addressString = addressString + address.streetAddress2 + " ";
    if (address.city) addressString = addressString + address.city + ", ";
    if (address.provState) addressString = addressString + address.provState + " ";
    if (address.zip) addressString = addressString + address.zip + " ";
    if (address.country) addressString = addressString + address.country + " ";
    return addressString;
} 

export function formatShortAddress(address: Address): string {
    let addressString = "";
    if (address) {
        if (address.city) addressString = addressString + address.city;
        if (address.city && address.provState) addressString = addressString + ", ";
        if (address.provState) addressString = addressString + address.provState + " ";
    }
    return addressString;
} 