import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { BlockStatus, CreateBlockInput, CreateBlockMutation, DeleteBlockInput, DeleteBlockMutation, GetBlockQuery, ListBlocksQuery, UpdateBlockInput, UpdateBlockMutation } from "../../API";
import { sortBlocksByDateNewest } from "./SortBlocks";
import moment from "moment";
import { Block } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: block. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created block.
*/
export async function createBlock(input: CreateBlockInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Block", "No input", "Create Block received no input.");
   try {
        const fullInput: CreateBlockInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            purchasedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            expiresOn: moment(new Date()).add(2, "years").format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createBlock, { input: fullInput }))) as GraphQLResult<CreateBlockMutation>;
       else result = (await API.graphql({
           query: mutations.createBlock,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateBlockMutation>;
       const block = result.data?.createBlock;
       return formatResult(true, "Block", block, "Successfully created the block.");
   } catch (error: any) {
       return formatResult(false, "Block", error, "Error creating record in the database for type: block");
   }
}

/**
* Handle updating a new record in the database for type: block. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated block.
*/
export async function updateBlock(input: UpdateBlockInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Block", "No input", "Update Block received no input.");
   try {
        const fullInput: UpdateBlockInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateBlock, { input: fullInput }))) as GraphQLResult<UpdateBlockMutation>;
       else result = (await API.graphql({
           query: mutations.updateBlock,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateBlockMutation>;
       const block = result.data?.updateBlock;
       return formatResult(true, "Block", block, "Successfully updated the block.");
   } catch (error: any) {
       return formatResult(false, "Block", error, "Error updating record in the database for type: block");
   }
}

/**
* Handle deleting a new record in the database for type: block. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted block.
*/
export async function deleteBlock(input: DeleteBlockInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Block", "No input", "Delete Block received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteBlock, { input: input }))) as GraphQLResult<DeleteBlockMutation>;
       else result = (await API.graphql({
           query: mutations.deleteBlock,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteBlockMutation>;
       const block = result.data?.deleteBlock;
       return formatResult(true, "Block", block, "Successfully deleted the block.");
   } catch (error: any) {
       return formatResult(false, "Block", error, "Error deleting record in the database for type: block");
   }
}

/**
* Get all records in the database for type: block. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the block object
*/
export async function getAllBlocks(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listBlocks,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBlocksQuery>;

        let items = result.data?.listBlocks?.items as Block[];
        let nextToken = result.data?.listBlocks?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBlocks,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBlocksQuery>;

            const nextItems = nextResult.data?.listBlocks?.items as Block[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBlocks?.nextToken;
        }

        const sortedBlocks = sortBlocksByDateNewest(items);
        if (sortedBlocks) {
            return formatResult(true, "Block", sortedBlocks, "Successfully got the blocks.");
        } else {
            return formatResult(true, "Block", items, "Successfully got the blocks.");
        }
    } catch (error: any) {
        return formatResult(false, "Block", error, "Error reading record in the database for type: blocks");
    }
}

/**
* Read a specific record in the database for type: block. 
* 
* @param {string}  id                  The block id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the block object
*/
export async function getBlockById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getBlock,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetBlockQuery>;
       const block = result.data?.getBlock;
       return formatResult(true, "Block", block, "Successfully got the block.");
   } catch (error: any) {
       return formatResult(false, "Block", error, "Error reading record in the database for type: block");
   }
}

/**
* Get all records in the database that match the given criteria for type: block. 
* 
* @param {string}  personId            The person id of the "owner" of the block
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the block object
*/
export async function getBlocksByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personid: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listBlocks,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBlocksQuery>;

        let items = result.data?.listBlocks?.items as Block[];
        let nextToken = result.data?.listBlocks?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBlocks,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBlocksQuery>;

            const nextItems = nextResult.data?.listBlocks?.items as Block[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBlocks?.nextToken;
        }

        return formatResult(true, "Block", items, "Successfully got the Blocks.");
    } catch (error: any) {
        return formatResult(false, "Block", error, "Error reading record in the database for type: blocks");
    }
}

/**
* Get all records in the database that match the given criteria for type: block. 
* 
* @param {string}  organizationId      The organization id of the "owner" of the block
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the block object
*/
export async function getBlocksByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationId: {eq: organizationId}};
        const result = (await API.graphql({
            query: queries.listBlocks,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBlocksQuery>;

        let items = result.data?.listBlocks?.items as Block[];
        let nextToken = result.data?.listBlocks?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBlocks,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBlocksQuery>;

            const nextItems = nextResult.data?.listBlocks?.items as Block[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBlocks?.nextToken;
        }

        const sortedBlocks = sortBlocksByDateNewest(items);
        if (sortedBlocks) {
            return formatResult(true, "Block", sortedBlocks, "Successfully got the blocks.");
        } else {
            return formatResult(true, "Block", items, "Successfully got the blocks.");
        }
    } catch (error: any) {
        return formatResult(false, "Block", error, "Error reading record in the database for type: blocks");
    }
}

/**
* Get all records in the database that match the given criteria for type: block. 
* 
* @param {string}  organizationId      The organization id of the "owner" of the block
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the block object
*/
export async function getValidBlocksByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationId: {eq: organizationId}, status: {eq: BlockStatus.valid}};
        const result = (await API.graphql({
            query: queries.listBlocks,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBlocksQuery>;

        let items = result.data?.listBlocks?.items as Block[];
        let nextToken = result.data?.listBlocks?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBlocks,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBlocksQuery>;

            const nextItems = nextResult.data?.listBlocks?.items as Block[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBlocks?.nextToken;
        }

        return formatResult(true, "Block", items, "Successfully got the Blocks.");
    } catch (error: any) {
        return formatResult(false, "Block", error, "Error reading record in the database for type: blocks");
    }
}