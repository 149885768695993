import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventRingInput, CreateEventRingMutation, DeleteEventRingInput, DeleteEventRingMutation, GetEventRingQuery, ListEventRingsQuery, UpdateEventRingInput, UpdateEventRingMutation } from "../../API";
import moment from "moment";
import { EventRing } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventRing. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventRing.
*/
export async function createEventRing(input: CreateEventRingInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventRing", "No input", "Create EventRing received no input.");
   try {
        const fullInput: CreateEventRingInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventRing, { input: fullInput }))) as GraphQLResult<CreateEventRingMutation>;
       else result = (await API.graphql({
           query: mutations.createEventRing,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventRingMutation>;
       const eventRing = result.data?.createEventRing;
       return formatResult(true, "EventRing", eventRing, "Successfully created the eventRing.");
   } catch (error: any) {
       return formatResult(false, "EventRing", error, "Error creating record in the database for type: eventRing");
   }
}

/**
* Handle updating a new record in the database for type: eventRing. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventRing.
*/
export async function updateEventRing(input: UpdateEventRingInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventRing", "No input", "Update EventRing received no input.");
   try {
        const fullInput: UpdateEventRingInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventRing, { input: fullInput }))) as GraphQLResult<UpdateEventRingMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventRing,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventRingMutation>;
       const eventRing = result.data?.updateEventRing;
       return formatResult(true, "EventRing", eventRing, "Successfully updated the eventRing.");
   } catch (error: any) {
       return formatResult(false, "EventRing", error, "Error updating record in the database for type: eventRing");
   }
}

/**
* Handle deleting a new record in the database for type: eventRing. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventRing.
*/
export async function deleteEventRing(input: DeleteEventRingInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventRing", "No input", "Delete EventRing received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventRing, { input: input }))) as GraphQLResult<DeleteEventRingMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventRing,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventRingMutation>;
       const eventRing = result.data?.deleteEventRing;
       return formatResult(true, "EventRing", eventRing, "Successfully deleted the eventRing.");
   } catch (error: any) {
       return formatResult(false, "EventRing", error, "Error deleting record in the database for type: eventRing");
   }
}

/**
* Get all records in the database for type: eventRing. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventRing object
*/
export async function getAllEventRings(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventRings,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventRingsQuery>;

        let items = result.data?.listEventRings?.items as EventRing[];
        let nextToken = result.data?.listEventRings?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventRings,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventRingsQuery>;

            const nextItems = nextResult.data?.listEventRings?.items as EventRing[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventRings?.nextToken;
        }

        return formatResult(true, "EventRing", items, "Successfully got the EventRings.");
    } catch (error: any) {
        return formatResult(false, "EventRing", error, "Error reading record in the database for type: eventRings");
    }
}

/**
* Read a specific record in the database for type: eventRing. 
* 
* @param {string}  id                  The eventRing id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventRing object
*/
export async function getEventRingById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventRing,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventRingQuery>;
       const eventRing = result.data?.getEventRing;
       return formatResult(true, "EventRing", eventRing, "Successfully got the eventRing.");
   } catch (error: any) {
       return formatResult(false, "EventRing", error, "Error reading record in the database for type: eventRing");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventRing. 
* 
* @param {string}  eventId             The event id
* @param {string}  ringId              The ring id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventRing object
*/
export async function getEventRingByEventIdRingId(eventId: string, ringId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}, ringId: {eq: ringId}};
        const result = (await API.graphql({
            query: queries.listEventRings,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventRingsQuery>;
        
        let items = result.data?.listEventRings?.items as EventRing[];
        let nextToken = result.data?.listEventRings?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listEventRings,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventRingsQuery>;

            const nextItems = nextResult.data?.listEventRings?.items as EventRing[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventRings?.nextToken;
        }
        
        const eventRings = items;
        if (eventRings && eventRings[0]) return formatResult(true, "EventRing", eventRings[0], "Successfully got the eventRing.");
        else return formatResult(false, "EventRing", null, "Could not find the eventRing.");
    } catch (error: any) {
        return formatResult(false, "EventRing", error, "Error reading record in the database for type: eventRing");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventRing. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventRing object
*/
export async function getEventRingsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventRings,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventRingsQuery>;

        let items = result.data?.listEventRings?.items as EventRing[];
        let nextToken = result.data?.listEventRings?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventRings,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventRingsQuery>;

            const nextItems = nextResult.data?.listEventRings?.items as EventRing[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventRings?.nextToken;
        }

        return formatResult(true, "EventRing", items, "Successfully got the EventRings.");
    } catch (error: any) {
        return formatResult(false, "EventRing", error, "Error reading record in the database for type: eventRings");
    }
}