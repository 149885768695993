import { BeddingRequest, Contact, Event, EventEntry, Person, StablingRequest } from "../../models";
import { getContactById } from "../contact/Contact";
import { sendDeclinedEntryEmail, sendSubmittedEntryEmail } from "../emails/EntryEmail";
import { getFormattedEntryClassList } from "../../utilities/eventClassEntry/FormattedEventEntryClasses";
import { getEventEntryFeesByEventIdByEntryId } from "../eventEntryFee/EventEntryFee";
import { getEventById } from "../events/Event";
import { getPersonByPersonId } from "../person/Person";
import { generateInvoicePDF, InvoicePDFData, InvoicePDFSettings } from "../reports/InvoicePDF";
import { formatRiderNamesToString } from "../rider/FormatRiderNames";
import { getEventEntryById } from "./EventEntry";
import { getEventStallTypeById } from "../eventStallType/EventStallType";
import { getStablingRequestsByEventId } from "../stablingRequest/StablingRequest";
import { getBeddingRequestsByEntryId } from "../beddingRequest/BeddingRequest";

interface FormattedStablingRequest {
    horseName?: (string | null)
    trainerName?: (string | null)
    type?: (string | null)
    quantity?: (string | null)
    price?: (string | null)
    tax?: (string | null)
    totalPrice?: (string | null)
    splitCost?: (string | null)
    entries?: EventEntry[]
}

export async function sendEntryStatusSubmittedEmailWithInvoice(entry: EventEntry, emailAddress: string, personName: string) {
    const eventId = entry.eventId;
    const eventQueryResult = await getEventById(eventId);
    if (eventQueryResult.isSuccess) {
        const event = eventQueryResult.result;

        const dataResult = await getFormattedEntryClassList(entry, false);
        if (dataResult.isSuccess) {
            const formattedClassData = dataResult.result.classList;

            const feesQueryResult = await getEventEntryFeesByEventIdByEntryId(eventId, entry.id);
            const eventEntryFees = feesQueryResult.result || [];
                
            const formattedStablingRequests = await getStablingRequestsByEventByEntry(eventId, entry);

            let beddingRequests: BeddingRequest[] = [];
            const beddingQuery = await getBeddingRequestsByEntryId(entry.id);
            if (beddingQuery.isSuccess) {
                beddingRequests = beddingQuery.result;
            }

            const dataObject: InvoicePDFData = {
                formattedClassData,
                previousEventEntryFees: eventEntryFees,
                formattedStablingRequests,
                beddingRequests: beddingRequests
            };
            const settingsObject: InvoicePDFSettings = {
                isDue: false,
                isDownload: false,
                isPrintOnly: false,
                includeRiderInfo: true,
                includeHorseInfo: true,
                includeOwnerInfo: true,
                includeTrainerInfo: true,
                includeBarnInfo: true,
                includePrimaryContactInfo: true,
                includeEmails: true,
                includePhoneNumbers: true,
                includeMemberships: true
            };

            const pdf = await generateInvoicePDF(entry, event, dataObject, settingsObject);

            const eventEmail = await getShowEmail(event);

            const riderNames = [];
            if (entry.riderName) riderNames.push(entry.riderName);
            if (entry.secondRiderName) riderNames.push(entry.secondRiderName);
            const riderNameString = formatRiderNamesToString(riderNames);

            const horseName = entry.horseName || "";
            const trainerName = entry.trainerName || "";

            if (pdf) {
                sendSubmittedEntryEmail(emailAddress, personName, event.name || "an event", eventEmail, horseName, riderNameString, trainerName, pdf);
            }
        }
    }
}

export async function sendEntryStatusDeclinedEmail(entry: EventEntry, event: Event, declineReason: string) {
    let personWhoSubmittedEntry: Person | null | undefined;    

    const personIdWhoSubmittedEntry = entry.createdBy;
    const personResult = await getPersonByPersonId(personIdWhoSubmittedEntry);
    if (personResult) {
        personWhoSubmittedEntry = personResult.result;

        const eventEmail = await getShowEmail(event);

        const personEmail = personWhoSubmittedEntry?.email || "";
        const personName = personWhoSubmittedEntry?.firstName || "";
        const horseName = entry.horseName || "";
        const trainerName = entry.trainerName || "";
        const riderNames = [];
        if (entry.riderName) riderNames.push(entry.riderName);
        if (entry.secondRiderName) riderNames.push(entry.secondRiderName);
        const riderNameString = formatRiderNamesToString(riderNames);

        await sendDeclinedEntryEmail(personEmail, personName, event.name, declineReason, eventEmail, horseName, riderNameString, trainerName);
    }
}

async function getShowEmail(event: Event): Promise<string> {
    let eventEmail = "hello@ringsidepro.com";
    if (event.contactId) {
        const contactResult = await getContactById(event.contactId);
        if (contactResult.isSuccess) {
            const eventContact: Contact = contactResult.result;
            if (eventContact.workEmail) eventEmail = eventContact.workEmail;
        }
    }
    return eventEmail;
}

const getStablingRequestsByEventByEntry = async (eventId: string, entry: EventEntry) => {
    let requestList: StablingRequest[] = [];
    const queryResult = await getStablingRequestsByEventId(eventId);
    if (queryResult.isSuccess) {
        const allStablingRequests = queryResult.result;
        allStablingRequests.forEach((sr: StablingRequest) => {
            const currentEntries = sr.entryIds;
            if (currentEntries && currentEntries.length) {
                currentEntries.forEach(currentEntry => {
                    if (currentEntry === entry.id) requestList.push(sr);
                });
            }
        });
    }
    const formattedRequests = await formatStablingRequests(requestList);

    return formattedRequests;
}

const formatStablingRequests = async (stablingRequests: StablingRequest[]) => {
    let formattedArray: FormattedStablingRequest[] = [];
    if (stablingRequests && stablingRequests.length) {
        for (let i = 0; i < stablingRequests.length; i++) {
            const stablingRequest = stablingRequests[i];
            
            let formattedStablingRequest: FormattedStablingRequest = {
                quantity: stablingRequest.quantityNeeded?.toString(),
                totalPrice: stablingRequest.totalPrice ? stablingRequest.totalPrice.toFixed(2) : "0",
                splitCost: stablingRequest.totalPrice ? (stablingRequest.totalPrice / stablingRequests.length).toFixed(2) : "0"
            };

            if (stablingRequest.stallTypeId) {
                const queryStallTypeResult = await getEventStallTypeById(stablingRequest.stallTypeId);
                if (queryStallTypeResult.isSuccess) {
                    const stallType = queryStallTypeResult.result;
                    formattedStablingRequest.type = stallType.name;
                    formattedStablingRequest.price = parseFloat(stallType.pricePerStall || "0").toFixed(2);
                    formattedStablingRequest.tax = stallType.taxPerStall;
                }
            }
            
            const entryIdArray = stablingRequest.entryIds;
            let entryArray: EventEntry[] = [];
            if (entryIdArray && entryIdArray.length) {
                for (let j = 0; j < entryIdArray.length; j++) {
                    const currentEntryId = entryIdArray[j];
                    if (currentEntryId) {
                        const queryEntryResult = await getEventEntryById(currentEntryId);
                        if (queryEntryResult.isSuccess) {
                            const entry: EventEntry = queryEntryResult.result;
                            entryArray.push(entry);
                            formattedStablingRequest.horseName = entry.horseName;
                            formattedStablingRequest.trainerName = entry.trainerName;
                        }
                    }
                }
            };
            formattedStablingRequest.entries = entryArray;

            if (entryArray.length > 1) {
                const quantity = (stablingRequest.quantityNeeded || 1) / entryArray.length;
                formattedStablingRequest.quantity = quantity.toFixed(2);

                const splitCost = (stablingRequest.totalPrice || 0) * quantity;
                formattedStablingRequest.splitCost = splitCost.toFixed(2);
            }
            
            formattedArray.push(formattedStablingRequest);
        }
    }
    return formattedArray;
}