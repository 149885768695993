import {
    IonButton, 
    IonCard, 
    IonCardContent, 
    IonCardHeader, 
    IonCardTitle, 
    IonCol, 
    IonContent, 
    IonInput,  
    IonItem,  
    IonLabel,  
    IonPage, 
    IonRouterLink, 
    IonRow,
    IonText, 
} from "@ionic/react";
import React, {useEffect, useRef, useState} from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { Organization } from "../../models";
import { getOrganizationByBackHalf } from "../../utilities/organization/Organization";
import Footer from "../../components/Footers/Footer";
import OrganizationAuthNavbar from "../../components/Navbars/OrganizationAuthNavbar";
import AuthNavbar from "../../components/Navbars/AuthNavbar";
import Spinner from "../../components/Spinners/Spinner";
import ErrorAlert from "../../components/Errors/ErrorAlert";
import { logIn } from "../../utilities/auth/LoginUtil";
import { LogoInput } from "../../API";
import { getUser, updateUserOrganizationId, UserAttributes } from "../../utilities/user/User";

interface ViewOrgProps extends RouteComponentProps<{
    id: string;
}> {
    setOrganizationHandler: Function;
}

const OrganizationLoginPage: React.FC<ViewOrgProps> = ({match, setOrganizationHandler}) => {
    const history = useHistory();

    const [currentOrganization, setCurrentOrganization] = useState<Organization>();
    const [logos, setLogos] = useState<(LogoInput | null)[] | null[]>();
    const [orgLogoURL, setOrgLogoURL] = useState<string>();
    const [id, setId] = useState<string>("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const emailInputRef = useRef<HTMLIonInputElement>(null);
    const passwordInputRef = useRef<HTMLIonInputElement>(null);

    async function getOrganization(id: string) {
        setIsLoading(true);
        setError("");
        const queryResult = await getOrganizationByBackHalf(id);
        if (queryResult.isSuccess && queryResult.result) {
            const organization = queryResult.result;
            setCurrentOrganization(organization);
            setOrganizationHandler(queryResult.result);
        } else {
            setError("Error: please check that you have the correct URL or try navigating to www.ringsidepro.com/register"); 
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setLogos(currentOrganization?.logos ?? []);
    }, [currentOrganization]);

    useEffect(() => {
        setOrgLogoURL(logos?.[0]?.logoURL ?? "");
    }, [logos]);

    useEffect(() => {
        // First, check if the id is found in the URL parameters
        if (match.params && match.params.id) {
            setError("");
            setId(match.params.id);
            getOrganization(match.params.id);
        // Second, check if you can find the id by searching in the URL
        } else {
            // For ex - match.path = "/iea/login".
            // The pathArray will be ["", "iea", "login"]
            const path = match.path;
            const pathArray = path.split("/");
            const id = pathArray[pathArray.length - 2];
            if (id) {
                setError("");
                setId(id);
                getOrganization(id);
            } else {
                // Lastly, set an error message 
                setError("Error: please check that you have the correct URL or try navigating to www.ringsidepro.com/register"); 
            }
        }
    }, [match, match.params.id]);

    const validateLogInForm = () => {
        // Using a type assertion to ensure that the inputs contain strings
        const email = (emailInputRef.current!.value as string);
        const password = (passwordInputRef.current!.value as string);

        if (!email) {
            setError("Please enter your email address.");
            return false;
        }
        if (!password) {
            setError("Please enter your password.");
            return false;
        }
        setError("");
        return true;
    };

    const handleLogin = async () => {
        setIsLoading(true);
        try {
            // Validate the form fields
            const isValid = validateLogInForm();
            if (!isValid) {
                setIsLoading(false);
                return;
            }

            // Using a type assertion to ensure that the inputs contain strings
            const email = (emailInputRef.current!.value as string);
            const password = (passwordInputRef.current!.value as string);

            const logInResult = await logIn(email, password);
            if (logInResult.isSuccess) {
                const user = await getUser();
                if (user && currentOrganization) {
                    const attributes: UserAttributes = user.attributes;
                    const prevOrganizationIds = attributes["custom:organizationids"] ?? "";
                    const orgIdsArray = prevOrganizationIds.split(",");
                    // Add Organization Id to custom attribute if not present
                    if(!orgIdsArray.includes(currentOrganization.id)) {
                        const orgIdUpdateResult = await updateUserOrganizationId(user, currentOrganization.id);
                        if (!orgIdUpdateResult) {
                            setError("Sorry, a problem occurred. Please contact hello@ringsidepro.com.");
                        }
                    }
                }
                navigateToDashboard();
            } else setError(logInResult.message);
            setIsLoading(false);
        } catch (error: any) {
            setError("An error occurred.");
            setIsLoading(false);
        }
    };

    const navigateToDashboard = () => {
        // Check if the user is trying to get to a specific dashboard page
        const previousPath = history.location.pathname;
        if (previousPath && previousPath.includes("index")) {
            // Send the user to the specific Dashboard page the user is trying to get to
            history.push(previousPath);
        } else {
            // Otw, send the user to the general dashboard page
            const path = id ? ("/index/" + id + "/home") : "/index/home";
            history.push(path);
        }        
    }
    
    return (
        <IonPage id="orgLoginPage">
            <IonContent color="primary">
                {currentOrganization ? <OrganizationAuthNavbar organization={currentOrganization} /> : <AuthNavbar/>}
                <section className="section bg-primary">
                    <div>
                        <IonRow class="ion-justify-content-center">
                            <IonCol sizeXs="6" sizeMd="2">
                                {orgLogoURL && orgLogoURL.length > 0 && (<img
                                    src={orgLogoURL}
                                    alt={currentOrganization?.name}
                                />)}
                            </IonCol>
                        </IonRow>
                        <IonRow class="ion-justify-content-center ion-padding-bottom">
                            <IonCol sizeSm="8" sizeMd="6">
                                <IonCard color="light">
                                    <IonCardHeader className="ion-margin-top">
                                        <IonCardTitle color="primary" className="ion-text-center h3">
                                            Log In
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    {isLoading ?
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol className="ion-text-center">
                                                    <Spinner />
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                        :
                                        <IonCardContent>
                                            {error && (
                                                <ErrorAlert width="12" error={error} />
                                            )}
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeSm="12" sizeMd="8">
                                                    <form>
                                                        <IonItem color="light">
                                                            <IonLabel position="floating">Email Address</IonLabel>
                                                            <IonInput 
                                                                id="login-email"
                                                                type="email"
                                                                required={true}
                                                                aria-required={true}
                                                                ref={emailInputRef} 
                                                            />
                                                        </IonItem>
                                                        <IonItem color="light">
                                                            <IonLabel position="floating">Password</IonLabel>
                                                            <IonInput 
                                                                id="login-password"
                                                                type="password" 
                                                                required={true}
                                                                aria-required={true}
                                                                ref={passwordInputRef} 
                                                            />
                                                        </IonItem>
                                                        <IonItem 
                                                            button={false}
                                                            lines="none"
                                                            color="light"
                                                            className="ion-no-padding"
                                                        >
                                                            <IonRow className="ion-justify-content-center">
                                                                <IonCol sizeMd="12" className="">
                                                                    <IonText className="description">Forgot your password? <IonRouterLink routerLink={`/${currentOrganization?.urlBackHalf}/forgot-password`}>Reset It</IonRouterLink></IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonItem>
                                                        <IonButton
                                                            id="loginBtn"
                                                            className="ion-margin-top"
                                                            color="tertiary"
                                                            expand="block"
                                                            onClick={async () => await handleLogin()}
                                                        >
                                                            Log in
                                                        </IonButton>
                                                    </form>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeSm="12" sizeMd="8" className="ion-text-center">
                                                    <IonText>Are you new? <IonRouterLink id="registerLink" routerLink={`/${currentOrganization?.urlBackHalf}/register`}>Create an Account.</IonRouterLink></IonText>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    }
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </div>
                </section>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default OrganizationLoginPage;
