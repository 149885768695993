import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateOrganizationClassInput, CreateOrganizationClassMutation, DeleteOrganizationClassInput, DeleteOrganizationClassMutation, GetOrganizationClassQuery, ListOrganizationClassesQuery, UpdateOrganizationClassInput, UpdateOrganizationClassMutation } from "../../API";
import moment from "moment";
import { OrganizationClass } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: organizationClass. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created organizationClass.
*/
export async function createOrganizationClass(input: CreateOrganizationClassInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationClass", "No input", "Create OrganizationClass received no input.");
   try {
        const fullInput: CreateOrganizationClassInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createOrganizationClass, { input: fullInput }))) as GraphQLResult<CreateOrganizationClassMutation>;
       else result = (await API.graphql({
           query: mutations.createOrganizationClass,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateOrganizationClassMutation>;
       const organizationClass = result.data?.createOrganizationClass;
       return formatResult(true, "OrganizationClass", organizationClass, "Successfully created the organizationClass.");
   } catch (error: any) {
       return formatResult(false, "OrganizationClass", error, "Error creating record in the database for type: organizationClass");
   }
}

/**
* Handle updating a new record in the database for type: organizationClass. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated organizationClass.
*/
export async function updateOrganizationClass(input: UpdateOrganizationClassInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationClass", "No input", "Update OrganizationClass received no input.");
   try {
        const fullInput: UpdateOrganizationClassInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateOrganizationClass, { input: fullInput }))) as GraphQLResult<UpdateOrganizationClassMutation>;
       else result = (await API.graphql({
           query: mutations.updateOrganizationClass,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateOrganizationClassMutation>;
       const organizationClass = result.data?.updateOrganizationClass;
       return formatResult(true, "OrganizationClass", organizationClass, "Successfully updated the organizationClass.");
   } catch (error: any) {
       return formatResult(false, "OrganizationClass", error, "Error updating record in the database for type: organizationClass");
   }
}

/**
* Handle deleting a new record in the database for type: organizationClass. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted organizationClass.
*/
export async function deleteOrganizationClass(input: DeleteOrganizationClassInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationClass", "No input", "Delete OrganizationClass received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteOrganizationClass, { input: input }))) as GraphQLResult<DeleteOrganizationClassMutation>;
       else result = (await API.graphql({
           query: mutations.deleteOrganizationClass,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteOrganizationClassMutation>;
       const organizationClass = result.data?.deleteOrganizationClass;
       return formatResult(true, "OrganizationClass", organizationClass, "Successfully deleted the organizationClass.");
   } catch (error: any) {
       return formatResult(false, "OrganizationClass", error, "Error deleting record in the database for type: organizationClass");
   }
}

/**
* Get all records in the database for type: organizationClass. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationClass object
*/
export async function getAllOrganizationClasses(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listOrganizationClasses,
            variables: {
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.listOrganizationClasses?.items as OrganizationClass[];
        let nextToken = result.data?.listOrganizationClasses?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizationClasses,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.listOrganizationClasses?.items as OrganizationClass[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationClasses?.nextToken;
        }

        return formatResult(true, "OrganizationClass", items, "Successfully got the OrganizationClasss.");
    } catch (error: any) {
        return formatResult(false, "OrganizationClass", error, "Error reading record in the database for type: organizationClasss");
    }
}

/**
* Read a specific record in the database for type: organizationClass. 
* 
* @param {string}  id                  The organizationClass id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationClass object
*/
export async function getOrganizationClassById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getOrganizationClass,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetOrganizationClassQuery>;
       const organizationClass = result.data?.getOrganizationClass;
       return formatResult(true, "OrganizationClass", organizationClass, "Successfully got the organizationClass.");
   } catch (error: any) {
       return formatResult(false, "OrganizationClass", error, "Error reading record in the database for type: organizationClass");
   }
}

/**
* Get all records in the database that match the given criteria for type: organizationClass. 
* 
* @param {string}  organizationId      The organization id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationClass object
*/
export async function getOrganizationClassesByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.organizationClassesByOrganizationId,
            variables: {
                limit: 1000,
                organizationId: organizationId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.organizationClassesByOrganizationId?.items as OrganizationClass[];
        let nextToken = result.data?.organizationClassesByOrganizationId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.organizationClassesByOrganizationId,
                variables: {
                    limit: 100,
                    organizationId: organizationId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.organizationClassesByOrganizationId?.items as OrganizationClass[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.organizationClassesByOrganizationId?.nextToken;
        }

        return formatResult(true, "OrganizationClass", items, "Successfully got the OrganizationClasss.");
    } catch (error: any) {
        return formatResult(false, "OrganizationClass", error, "Error reading record in the database for type: organizationClasss");
    }
}