import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Spinner from "../../../../components/Spinners/Spinner";
import { Person, SubmissionFeedback } from "../../../../models";
import { getPersonByPersonId } from "../../../../utilities/person/Person";
import moment from "moment";
import { getAllSubmissionFeedbacks } from "../../../../utilities/submissionFeedback/SubmissionFeedback";
import { sortSubmissionFeedbacksByStatus } from "../../../../utilities/submissionFeedback/SortSubmissionFeedback";

interface formattedSubmissionFeedback {
    clinicianName: String
    personName: string
    status: string
    dateSent: string
    dateDue: string
    videoTitle: string
    sf: SubmissionFeedback
}

const AdminClinicManagementPage: React.FC = () => {
    const history = useHistory();

    const [submissionFeedback, setSubmissionFeedback] = useState<SubmissionFeedback[] | null | undefined>();
    const [formattedSubmissionFeedback, setFormattedSubmissionFeedback] = useState<formattedSubmissionFeedback[] | null | undefined>();

    const [isLoading, setIsLoading] = useState(false);

    const getPersonName = async (personId: string) => {
        const queryResult = await getPersonByPersonId(personId);
        if (queryResult.isSuccess) {
            const person: Person = queryResult.result;
            const name = person.firstName + " " + person.lastName;
            return name;
        }
    }

    const formatSubmissionFeedback = async (submissionFeedbackItems?: SubmissionFeedback[]) => {
        const items = submissionFeedbackItems || submissionFeedback;
        if (items) {
            let result: formattedSubmissionFeedback[] = [];
            for (let i = 0; i < items.length; i++) {
                const item: SubmissionFeedback = items[i];
                const formatted: formattedSubmissionFeedback = {
                    clinicianName: item.clinician?.name || "", 
                    personName: await getPersonName(item.personId || "") || "unknown",
                    status: item.feedbackStatus?.toString() || "unknown",
                    dateSent: moment(item.createdOn).format("MM/DD/YY"),
                    dateDue: (item.feedbackStatus === "pending" || item.feedbackStatus === "draft") ? moment(item.createdOn).add("weeks", 2).format("MM/DD/YY") : "n/a",
                    videoTitle: item.submission?.video?.title || "unknown",
                    sf: item
                };
                result.push(formatted);
            }
            setFormattedSubmissionFeedback(result);
        }
    }

    const getSubmissionFeedbackItems = async () => {
        setIsLoading(true);
        const queryResult = await getAllSubmissionFeedbacks();
        if (queryResult.isSuccess) {
            const sorted = sortSubmissionFeedbacksByStatus(queryResult.result);
            if (sorted) {
                setSubmissionFeedback(sorted);
                await formatSubmissionFeedback(sorted);
            } else {
                setSubmissionFeedback(queryResult.result);
                await formatSubmissionFeedback(queryResult.result);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getSubmissionFeedbackItems();
    }, []);

    const handleViewClinicians = () => {
        const path = "/index/admin/clinicians";
        history.push(path);
    }

    const handleViewPayments = () => {
        const path = "/index/admin/clinicians/payments";
        history.push(path);
    }

    const navigateToPage = (id: string) => {
        const path = "/index/judge/clinics/video/" + id;
        history.push(path);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Clinics" />
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>View Clinicians</IonCardTitle>
                            <IonCardContent>
                                <IonButton onClick={handleViewClinicians}>See Clinicians</IonButton>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>View Payments</IonCardTitle>
                            <IonCardContent>
                                <IonButton onClick={handleViewPayments}>See Payments</IonButton>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>All Videos {formattedSubmissionFeedback ? "(" + formattedSubmissionFeedback?.length + ")" : ""}</IonCardTitle>
                            {isLoading ?
                                <Spinner />
                                :
                                <IonCardContent>
                                    {formattedSubmissionFeedback ?
                                        <IonList className="bg-white">
                                            {formattedSubmissionFeedback.map((item, index) => (
                                                <IonItem key={index} onClick={() => navigateToPage(item.sf.id)}>
                                                    <IonLabel>
                                                        <IonRow>
                                                            <IonCol sizeXs="12" sizeMd="3">
                                                                <p className="ion-text-wrap">{item.clinicianName}</p>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="3">
                                                                <p className="ion-text-wrap">{item.personName}</p>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="2">
                                                                <p className="ion-text-wrap"><span className={item.status === "complete" ? "text-success" : (item.status === "declined" ? "text-warning" : "text-danger")}>{item.status}</span></p>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="2">
                                                                <p className="ion-text-wrap">Sent: {item.dateSent}</p>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="2">
                                                                <p className="ion-text-wrap">Due: {item.dateDue}</p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonLabel>
                                                </IonItem>
                                            ))}
                                        </IonList>
                                        :
                                        <p>No Videos</p>
                                    }
                            </IonCardContent>
                            }
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default AdminClinicManagementPage;