import React, { useEffect, useState } from "react";
import BasicSelect from "../Select/BasicSelect";
import CONSTANT from "../../constant/constant";

interface _Props {
    menuPlacement?: (string | null)
    selectedValue?: (string | null)
    onSelect: Function
}

const SelectMembershipStatus: React.FC<_Props> = ({selectedValue, menuPlacement, onSelect}) => {
    const membershipStatusOptions = [
        {
            value: CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS,
            label: CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS_TEXT
        },
        {
            value: CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE,
            label: CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE_TEXT
        }
    ];

    const [selected, setSelected] = useState<string | null | undefined>();

    useEffect(() => {
        if (selectedValue) setSelected(selectedValue);
        else setSelected(undefined);
    }, [selectedValue]);

    const handleOnChange = (option?: any) => {
        if (option) {
            onSelect(option);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {membershipStatusOptions ?
                <BasicSelect formattedValues={membershipStatusOptions} menuPlacement={menuPlacement} selectedValue={selected} onSelect={handleOnChange} />
                :
                <p>Loading options...</p>
            }
        </>
    )
};

export default SelectMembershipStatus;
