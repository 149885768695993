import { 
    IonCard
} from "@ionic/react";
import React from "react";
import { Team } from "../../models";

interface _Props {
    team: Team
}

const BasicTeamInfo: React.FC<_Props> = ({team}) => {

    return (
        <IonCard className="bg-white ion-padding">
            <h3>{team.name}</h3>
            <p className="description">Team id: {team.id}</p>
            <p className="ion-text-wrap">{team.description}</p>
        </IonCard>
    );
};

export default BasicTeamInfo;