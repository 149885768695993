import React, { useEffect, useState } from "react";
import { IonButton, IonCol, IonRow } from "@ionic/react";

interface _Props {
    isClassInDivision?: boolean
    onSelect: Function
}

const EntryFeeSelectTooltip: React.FC<_Props> = ({isClassInDivision, onSelect}) => {
    const [isPartOfDivision, setIsPartOfDivision] = useState(true);

    useEffect(() => {
        setIsPartOfDivision(isClassInDivision || false);
    }, [isClassInDivision]);


    const handleOptionSelection = (isDivision: boolean) => {
        setIsPartOfDivision(isDivision);
        onSelect(isDivision? "division" : "class");
    }

    return (
        <IonRow className="ion-align-items-center">
            <IonCol sizeXs="12" sizeMd="6" className="ion-text-center"> 
                <IonButton color={isPartOfDivision ? "dark" : "light"} onClick={() => handleOptionSelection(true)}>Part Of Division</IonButton>
            </IonCol>
            <IonCol sizeXs="12" sizeMd="6" className="ion-text-center"> 
                <IonButton color={isPartOfDivision ? "light" : "dark"} onClick={() => handleOptionSelection(false)}>Stand Alone Class</IonButton>
            </IonCol>
        </IonRow>
    );
};

export default EntryFeeSelectTooltip;
