export const breeds = [
    "American Saddlebred",
    "American Warmblood",
    "Andalusian",
    "Anglo-Arabian",
    "Appaloosa",
    "Appendix Quarter Horse",
    "Arabian",
    "Belgian Warmblood",
    "Canadian Sport Horse",
    "Canadian Warmblood",
    "Connemara",
    "Danish Warmblood",
    "Dutch Warmblood",
    "Friesian",
    "Hackney",
    "Haflinger",
    "Half-Arabian",
    "Hanoverian",
    "Holsteiner",
    "Irish Sport Horse",
    "KWPN",
    "Morgan",
    "Mustang",
    "North American Sport Pony",
    "Oldenburg",
    "Paint",
    "Paso Fino",
    "Quarter Horse",
    "Rhineland",
    "Saddlebred",
    "Selle Francais",
    "Swedish Warmblood",
    "Thoroughbred",
    "Thoroughbred Cross",
    "Thoroughbred/Hanoverian",
    "Thoroughbred/Quarter Horse",
    "Trakehner",
    "Warmblood",
    "Welsh Pony",
    "Welsh Pony Cross",
    "Westphalian",
    "Zweibrucker",
    "Unkown",
    "Other"
];