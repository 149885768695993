import React from "react";
import { Organization } from "../models";

export const blankOrganization: Organization = {
    id: "",
    name: "",
    createdBy: "",
    createdOn: "",
    updatedOn: ""
};
    
export const OrganizationContext = React.createContext(blankOrganization);
    