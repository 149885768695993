import {
    IonAvatar,
    IonChip,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPopover,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import {cogSharp, helpBuoySharp, logOutSharp} from "ionicons/icons";
import { PersonContext } from "../../../context/PersonContext";
import { getPersonNameByPersonId } from "../../../utilities/person/PersonName";
import { useHistory } from "react-router";
import { isWindows } from "../../../utilities/platform/Platform";

const ProfileChip: React.FC = () => {
    const history = useHistory();

    const user = useContext(PersonContext);

    const [displayName, setDisplayName] = useState("");
    const [profilePicture, setProfilePicture] = useState<string | null>(null);
    const [popoverState, setShowPopover] = useState({showPopover: false, event: undefined});

    useEffect(() => {
        async function setNameInState() {
            const nameResult = await getPersonNameByPersonId(user.id);
            if (nameResult.isSuccess) {
                const name = nameResult.result;
                if (name) {
                    setDisplayName(name.displayName || "");
                }
            }
            
            if (user.pictureURL && user.pictureURL.length) {
                setProfilePicture(user.pictureURL);
            }
        }
        
        // If the context changes, update the name
        setNameInState();
    }, [user]);

    return (
        <div className="ion-float-right">
            {(isWindows() || (isPlatform("desktop"))) ?
                <IonChip 
                    outline={true}
                    onClick={
                        (e: any) => {
                            e.persist();
                            setShowPopover({showPopover: true, event: e});
                        }}
                >
                    <IonAvatar>
                        <img alt="avatar" src={profilePicture ? profilePicture : "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjYzVkYmZmIiBkPSJNMCAwaDUxMnY1MTJIMHoiLz48cGF0aCBkPSJNMjU2IDMwNGM2MS42IDAgMTEyLTUwLjQgMTEyLTExMlMzMTcuNiA4MCAyNTYgODBzLTExMiA1MC40LTExMiAxMTIgNTAuNCAxMTIgMTEyIDExMnptMCA0MGMtNzQuMiAwLTIyNCAzNy44LTIyNCAxMTJ2NTZoNDQ4di01NmMwLTc0LjItMTQ5LjgtMTEyLTIyNC0xMTJ6IiBmaWxsPSIjODJhZWZmIi8+PC9zdmc+"} />
                    </IonAvatar>
                    <IonLabel>
                        {displayName}
                    </IonLabel>
                </IonChip>
                :
                <div>
                    <IonAvatar 
                        onClick={
                            (e: any) => {
                                e.persist();
                                setShowPopover({showPopover: true, event: e});
                            }}
                    >
                        <img className="profile-chip-img" alt="avatar" src={profilePicture ? profilePicture : "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjYzVkYmZmIiBkPSJNMCAwaDUxMnY1MTJIMHoiLz48cGF0aCBkPSJNMjU2IDMwNGM2MS42IDAgMTEyLTUwLjQgMTEyLTExMlMzMTcuNiA4MCAyNTYgODBzLTExMiA1MC40LTExMiAxMTIgNTAuNCAxMTIgMTEyIDExMnptMCA0MGMtNzQuMiAwLTIyNCAzNy44LTIyNCAxMTJ2NTZoNDQ4di01NmMwLTc0LjItMTQ5LjgtMTEyLTIyNC0xMTJ6IiBmaWxsPSIjODJhZWZmIi8+PC9zdmc+"} />
                    </IonAvatar>
                </div>
            }
            <IonPopover
                event={popoverState.event}
                isOpen={popoverState.showPopover}
                onDidDismiss={() => setShowPopover({showPopover: false, event: undefined})}
            > 
                <IonList lines="full">
                    <IonListHeader>
                        <IonRow className="ion-align-items-center">
                            <IonCol size="3">
                                <IonAvatar>
                                    <img alt="avatar" src={profilePicture ? profilePicture : "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjYzVkYmZmIiBkPSJNMCAwaDUxMnY1MTJIMHoiLz48cGF0aCBkPSJNMjU2IDMwNGM2MS42IDAgMTEyLTUwLjQgMTEyLTExMlMzMTcuNiA4MCAyNTYgODBzLTExMiA1MC40LTExMiAxMTIgNTAuNCAxMTIgMTEyIDExMnptMCA0MGMtNzQuMiAwLTIyNCAzNy44LTIyNCAxMTJ2NTZoNDQ4di01NmMwLTc0LjItMTQ5LjgtMTEyLTIyNC0xMTJ6IiBmaWxsPSIjODJhZWZmIi8+PC9zdmc+"} />
                                </IonAvatar>
                            </IonCol>
                            <IonCol size="9">
                                <IonLabel>
                                    <h4 className="ion-padding-start">{displayName}</h4>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        
                    </IonListHeader>
                    <IonItem
                        button
                        onClick={() => {
                            setShowPopover({showPopover: false, event: undefined})
                            history.push("/index/settings");
                        }}
                    >
                        <IonIcon slot="start" icon={cogSharp} />
                        <IonLabel>Settings</IonLabel>
                    </IonItem>
                    <IonItem
                        button
                        onClick={() => {
                            setShowPopover({showPopover: false, event: undefined})
                            history.push("/index/support");
                        }}
                    >
                        <IonIcon slot="start" icon={helpBuoySharp} />
                        <IonLabel>Support</IonLabel>
                    </IonItem>
                    <IonItem
                        button
                        onClick={() => {
                            setShowPopover({showPopover: false, event: undefined});
                            history.push("/index/logout");
                        }}
                    >
                        <IonIcon slot="start" icon={logOutSharp} />
                        <IonLabel>Log Out</IonLabel>
                    </IonItem>
                </IonList>
            </IonPopover>
        </div>
    );
};

export default ProfileChip;
