import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { CreateOrganizationStallSetInput, UpdateOrganizationStallSetInput } from "../../API";
import { Organization, OrganizationStallSet } from "../../models";
import { createOrganizationStallSet, deleteOrganizationStallSet, updateOrganizationStallSet } from "../../utilities/organizationStallSet/OrganizationStallSet";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    organization?: (Organization | null)
    selectedOrganizationStallSet?: (OrganizationStallSet | null)
    onSubmit: Function
}

const StallSetForm: React.FC<_Props> = ({selectedOrganizationStallSet, organization, onSubmit}) => {
    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [available, setAvailable] = useState<string>("");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (selectedOrganizationStallSet) {
            setName(selectedOrganizationStallSet.name || "");
            setLocation(selectedOrganizationStallSet?.location || "");
            setAvailable(selectedOrganizationStallSet.available || "");
        } else {
            clearForm();
        }
    }, [selectedOrganizationStallSet]);

    const verifyForm = () => {
        if (!name) {
            setError("Please include an informative name so exhibitors know what these stalls are. Example: Overnight Stalls.");
            return false;
        }
        if (!location) {
            setError("Please include a name for the barn / location of the stalls.");
            return false;
        }
        if (!available) {
            setError("Please include the available stalls you have in this barn / location.");
            return false;
        }
        return true;
    }

    const clearForm = () => {
        setName("");
        setLocation("");
        setAvailable("");
        selectedOrganizationStallSet = undefined;
    }

    const handleCreateOrganizationStallSets = async () => {
        if (organization) {
            const input: CreateOrganizationStallSetInput = {
                organizationId: organization.id,
                name: name,
                location: location,
                available: available
            };
            const createResult = await createOrganizationStallSet(input);
            if (createResult.isSuccess) {
                onSubmit(true, createResult.result);
                clearForm();
            } else {
                setError("Sorry, we had a problem creating the stall type.");
            }
        } else {
            setError("Sorry, we could not find your organization.")
        }
    }

    const handleUpdateOrganizationStallSet = async () => {
        if (organization && selectedOrganizationStallSet) {
            const input: UpdateOrganizationStallSetInput = {
                id: selectedOrganizationStallSet.id,
                organizationId: organization.id,
                name: name,
                location: location,
                available: available
            };
            const updateResult = await updateOrganizationStallSet(input);
            if (updateResult.isSuccess) {
                onSubmit(false, updateResult.result);
                clearForm();
            } else {
                setError("Sorry, we had a problem updating the stall type.");
            }
        } else {
            setError("Sorry, we could not find your organization.")
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            if (selectedOrganizationStallSet) {
                await handleUpdateOrganizationStallSet();
            } else {
                await handleCreateOrganizationStallSets();
            }
            setIsDisabled(true);
        }
        setIsLoading(false);
    }

    const handleDelete = async () => {
        setIsLoading(true);
        setError("");
        if (selectedOrganizationStallSet) {
            const deleteResult = await deleteOrganizationStallSet({id: selectedOrganizationStallSet.id});
            if (deleteResult.isSuccess) {
                onSubmit(false, deleteResult.result);
                clearForm();
            } else {
                setError("Could not delete the organization stall type.");
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name (for your use only)</IonLabel>
                            <IonInput 
                                type="text"
                                placeholder="Ex: Back Barn Stalls"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <IonItem color="white">
                            <IonLabel position="stacked">Barn / Location Name</IonLabel>
                            <IonInput 
                                type="text"
                                placeholder="Ex: Barn A or Tent on Hill"
                                value={location} 
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setLocation(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Available Stalls (format: 1-10; 15-30;)</IonLabel>
                            <IonInput 
                                type="text"
                                placeholder="Ex: 1-10; 15-30;"
                                value={available} 
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setAvailable(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    {selectedOrganizationStallSet && (
                        <IonCol sizeMd="4">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    className="ion-margin-top"
                                    color="danger"
                                    expand="block"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </IonButton>
                            }
                        </IonCol>
                    )}
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default StallSetForm;