import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import React, { useEffect, useState } from "react";
import ClinicianForm from "../../../../../components/Clinician/ClinicianForm";
import { RouteComponentProps } from "react-router";
import { getClinicianById } from "../../../../../utilities/clinician/Clinician";
import { Clinician } from "../../../../../models";
import AdminClinicianStatusForm from "../../../../../components/Clinician/AdminClinicianStatusForm";

interface ClinicianPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EditClinicianPage: React.FC<ClinicianPageProps> = ({match}) => {

    const [clinician, setClinician] = useState<Clinician | null | undefined>();

    useEffect(() => {
        async function getClinician() {
            const queryResult = await getClinicianById(match.params.id);
            if (queryResult.isSuccess) {
                setClinician(queryResult.result);
            }
        }

        getClinician();

    }, [match, match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={clinician ? clinician.name : "Clinician"} />
                <IonRow id="status" className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Clinician Status</IonCardTitle>
                            <IonCardContent>
                                <AdminClinicianStatusForm selectedClinician={clinician} />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow id="profile" className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Clinician Profile</IonCardTitle>
                            <IonCardContent>
                                <ClinicianForm selectedClinician={clinician} />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EditClinicianPage;