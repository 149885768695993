import { EventDivision } from "../../models";

/**
 * Sorts the eventDivisions alphabetically a -> z
 * 
 * @param data 
 * @returns eventDivisions sorted alphabetically
 */
export function sortEventDivisionsByName(data: EventDivision[]) {
    try {
        let result: EventDivision[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => a.name.localeCompare(b.name));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: eventDivision");
        console.error(error);
        return null;
    }
};

/**
 * Sorts the eventDivisions numerically smallest to biggest
 * 
 * @param data 
 * @returns eventDivisions sorted numerically
 */
 export function sortEventDivisionsByNumber(data: EventDivision[]) {
    try {
        let result: EventDivision[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => (a.number || 0) - (b.number || 0));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: eventDivision");
        console.error(error);
        return null;
    }
};