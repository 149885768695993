import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Membership, Person, TeamMember } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import GradeLevel from "../Applications/GradeLevel";
import AELDivisionSelect from "../Applications/AELDivisionSelect";
import { getPersonByPersonId, updatePerson } from "../../utilities/person/Person";
import { formatDisplayNameForUser } from "../../utilities/person/PersonNameFormat";
import { UpdateMembershipInput, UpdatePersonInput } from "../../API";
import { updateMembership } from "../../utilities/membership/Membership";

interface _Props {
    teamMember: TeamMember
    membership?: (Membership | null)
    currentUserRoles?: ("admin" | "coach" | "creator" | "member")[]
}

const ViewTeamMemberBasicInfo: React.FC<_Props> = ({teamMember, membership, currentUserRoles}) => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [backNumber, setBackNumber] = useState("");
    const [gradeLevel, setGradeLevel] = useState<string>(membership && membership.gradeLevel ? membership.gradeLevel : "-");
    const [aelDivision, setAELDivision] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const getTeamMemberData = async (teamMember: TeamMember) => {
        if (teamMember.person) {
            if (teamMember.person.firstName) setFirstName(teamMember.person.firstName);
            if (teamMember.person.lastName) setLastName(teamMember.person.lastName);
        }
    }

    useEffect(() => {
        getTeamMemberData(teamMember);
    }, [teamMember]);

    const getMembershipData = async (membership: Membership) => {
        if (membership.backNumber) setBackNumber(membership.backNumber ? membership.backNumber.toString() : "");
        if (membership.gradeLevel) setGradeLevel(membership.gradeLevel);
        if (membership.aelDivision) setAELDivision(membership.aelDivision);
    }

    useEffect(() => {
        if (membership) getMembershipData(membership);
    }, [membership]);

    const verifyForm = () => {
        if (!firstName) {
            setError("Please include a first name for the team member.");
            return false;
        }
        if (!lastName) {
            setError("Please include a last name for the team member.");
            return false;
        }
        if (!gradeLevel) {
            setError("Please include a grade level for the team member.");
            return false;
        }
        if (!aelDivision) {
            setError("Please include a division for the team member.");
            return false;
        }
        if (!backNumber) {
            setError("Please include an arm number for the team member.");
            return false;
        }
        return true;
    }

    const handleUpdateMembership = async () => {
        if (membership) {
            const updateInput: UpdateMembershipInput = {
                id: membership.id,
                personName: firstName + " " + lastName,
                gradeLevel: gradeLevel,
                aelDivision: aelDivision,
                backNumber: backNumber ? parseFloat(backNumber) : 0
            };
            const updateResult = await updateMembership(updateInput);
            if (updateResult.isSuccess) {

            } else {
                const msg = "Sorry, a problem occurred. The membership could not be updated. " + updateResult.message;
                setError(msg);
            }
        } else {
            setError("Sorry, no membership was found. Please try again or contact hello@ringsidepro.com for help.");
        }
    }

    const handleUpdatePersonName = async () => {
        if (membership && membership.personId) {
            const updateInput: UpdatePersonInput = {
                id: membership.personId,
                firstName: firstName,
                lastName: lastName
            };
            const updateResult = await updatePerson(updateInput);
            if (updateResult.isSuccess) {

            } else {
                const msg = "Sorry, a problem occurred. The person could not be updated. " + updateResult.message;
                setError(msg);
            }
        } else {
            setError("Sorry, no person was found. Please try again or contact hello@ringsidepro.com for help.");
        }
    }

    const handleSave = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) {
            await handleUpdateMembership();

            // Admin and Creators can edit the name of the person attached to the membership
            if (currentUserRoles?.includes("admin") || currentUserRoles?.includes("creator")) await handleUpdatePersonName(); 
        }
        setIsLoading(false);
    }

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="4">
                        Member Info
                    </IonCol>
                </IonRow>
            </IonCardTitle>
            {error && <ErrorAlert error={error} />}
            {isLoading ?
                <Spinner />
                :
                <IonCardContent>
                    {/* Only org admins or the person who created the team member can update the team member's name */}
                    {currentUserRoles?.includes("admin") || currentUserRoles?.includes("creator") ?
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position="stacked">First Name</IonLabel>
                                    <IonInput 
                                        type="text"
                                        value={firstName}
                                        aria-required={true}
                                        onIonChange={e => {
                                            setFirstName(e.detail.value!)
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position="stacked">Last Name</IonLabel>
                                    <IonInput 
                                        type="text"
                                        value={lastName}
                                        aria-required={true}
                                        onIonChange={e => {
                                            setLastName(e.detail.value!)
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        :
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white" lines="none">
                                    <IonLabel position="stacked">First Name</IonLabel>
                                    {firstName}
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white" lines="none">
                                    <IonLabel position="stacked">Last Name</IonLabel>
                                    {lastName}
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    }

                    {/* Only org admins can update the grade level and the division */}
                    {currentUserRoles?.includes("admin") ? 
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6">
                                <GradeLevel
                                    onSelect={setGradeLevel}
                                    selectedValue={gradeLevel}
                                />
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <AELDivisionSelect
                                    onSelect={setAELDivision}
                                    selectedValue={aelDivision}
                                />
                            </IonCol>
                        </IonRow>
                        :
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white" lines="none">
                                    <IonLabel position="stacked">Grade Level</IonLabel>
                                    {gradeLevel}
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white" lines="none">
                                    <IonLabel position="stacked">Division</IonLabel>
                                    {aelDivision}
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    }
                    
                    {/* Only org admins or coaches can update the team member's assigned number */}
                    {currentUserRoles?.includes("admin") || currentUserRoles?.includes("coach") ?
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position="stacked">Arm Number</IonLabel>
                                    <IonInput 
                                        type="text"
                                        value={backNumber}
                                        aria-required={true}
                                        onIonChange={e => {
                                            setBackNumber(e.detail.value!)
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        :
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white" lines="none">
                                    <IonLabel position="stacked">Arm Number</IonLabel>
                                    {backNumber}
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    }

                    {/* If the user can edit the above fields, allow them to save their edits */}
                    {(currentUserRoles?.includes("admin") || currentUserRoles?.includes("creator") || currentUserRoles?.includes("coach")) &&
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="3">
                                <IonButton expand="block" color="tertiary" onClick={handleSave}>
                                    Save
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    }
                    
                </IonCardContent>
            }
        </IonCard>
    );
};

export default ViewTeamMemberBasicInfo;