import {
    IonButton,
    IonCol,
    IonItem,
    IonLabel,
    IonRow,
    IonTextarea,
    IonToggle,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorBanner from "../Banners/ErrorBanner";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import SelectClinicians from "../../components/Clinician/SelectClinicians";
import SelectVideo from "../../components/s3Object/SelectVideo";
import { Clinician, Feedback, Person, S3Object, Submission } from "../../models";
import { CreateFeedbackInput, CreateSubmissionFeedbackInput, CreateSubmissionInput, FeedbackStatus, SubmissionStatus } from "../../API";
import { createSubmission } from "../../utilities/submission/Submission";
import { createFeedback } from "../../utilities/feedback/Feedback";
import { createSubmissionFeedback } from "../../utilities/submissionFeedback/SubmissionFeedback";

interface _Props {
    person?: Person
    mediaList: (S3Object | null)[]
    onSubmit: Function
}

const SubmissionForm: React.FC<_Props> = ({person, mediaList, onSubmit}) => {
    const user = useContext(PersonContext);

    const [clinician, setClinician] = useState<Clinician | null | undefined>();
    const [video, setVideo] = useState<S3Object | null | undefined>();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [technical, setTechnical] = useState("");
    const [style, setStyle] = useState("");
    const [turnout, setTurnout] = useState("");
    const [other, setOther] = useState("");
    const [permission, setPermission] = useState(false);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setTitle("");
        setDescription("");
        setVideo(undefined);
        setError("");
    }, []);

    const handleUpdatedClinician = async (clinicians: Clinician[]) => {
        setClinician(clinicians[0]);
    }

    const handleUpdatedVideo = async (videos: S3Object[], video: S3Object) => {
        setVideo(video);
        setTitle(video.title || "Submission");
    }

    const handleCreateSubmissionFeedback = async () => {
        // Create the Submission
        const submissionInput: CreateSubmissionInput = {
            title,
            description,
            video,
            status: SubmissionStatus.complete,
            totalPrice: clinician?.price || 0,
            personId: person?.id
        };
        const createSubmissionResult = await createSubmission(submissionInput);
        if (createSubmissionResult.isSuccess) {
            const feedbackInput: CreateFeedbackInput = {
                technical,
                style,
                turnout,
                general: other,
                status: FeedbackStatus.complete
            };
            const createFeedbackResult = await createFeedback(feedbackInput);
            if (createFeedbackResult.isSuccess) {
                const submission: Submission = createSubmissionResult.result;
                const feedback: Feedback = createFeedbackResult.result;

                const submissionFeedbackInput: CreateSubmissionFeedbackInput = {
                    submissionId: submission.id,
                    // submissionFeedbackSubmissionId: submission.id,
                    feedbackId: feedback.id,
                    // submissionFeedbackFeedbackId: feedback.id,
                    personId: person?.id,
                    permissionToPost: permission,
                    clinicianId: clinician?.id  || "",
                    submissionFeedbackClinicianId: clinician?.id || "",
                    clinicianAmount: clinician?.price
                };
                const createSubmissionFeedbackResult = await createSubmissionFeedback(submissionFeedbackInput);
            }
        }
    }

    return (
        <>
            {isLoading ?
                <IonRow>
                    <IonCol className="ion-text-center">
                        <Spinner />
                    </IonCol>
                </IonRow>
                :
                <form>
                    {error && (
                        <IonRow>
                            <IonCol>
                                <ErrorBanner error={error} />
                            </IonCol>
                        </IonRow>
                    )}
                    <IonRow>
                        <IonCol>
                            <IonLabel position="stacked">Select Clinician</IonLabel>
                            <SelectClinicians onSelect={handleUpdatedClinician}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonLabel position="stacked">Select Video</IonLabel>
                            {mediaList && <SelectVideo mediaList={mediaList} onSelect={handleUpdatedVideo}/>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Video Description</IonLabel>
                                <IonTextarea
                                    rows={5} 
                                    value={description}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setDescription(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Technical</IonLabel>
                                <IonTextarea
                                    rows={5} 
                                    value={technical}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setTechnical(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Style</IonLabel>
                                <IonTextarea
                                    rows={5} 
                                    value={style}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setStyle(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Turnout</IonLabel>
                                <IonTextarea
                                    rows={5} 
                                    value={turnout}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setTurnout(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Other</IonLabel>
                                <IonTextarea
                                    rows={5} 
                                    value={other}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setOther(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Permission to Share?</IonLabel>
                                <IonToggle color="success" checked={permission} onIonChange={e => setPermission(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeMd="4">
                            <IonButton
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleCreateSubmissionFeedback}
                                >
                                    Save
                                </IonButton>
                        </IonCol>
                    </IonRow>
                </form>
            }
        </>
    );
};

export default SubmissionForm;