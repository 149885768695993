import { EventTypeOption, eventTypes } from "../../utilities/events/EventType";
import { FormGroup, Input } from "reactstrap";
import React, { useState } from "react";

interface _Props {
    onInputChange: Function
}

const SelectEventType: React.FC<_Props> = ({onInputChange}) => {

    const [eventType, setEventType] = useState<string>("-");

    return (
        <>
            <FormGroup>
                <Input 
                    name="eventType" 
                    type="select" 
                    value={eventType} 
                    onChange={e => {
                        setEventType(e.target.value);
                        onInputChange(e.target.value)
                    }}
                >
                    <option>Type</option>
                    {eventTypes?.map((eventType: EventTypeOption, index: number) => (
                        <option key={index} value={eventType.value}>{eventType.name}</option>
                    ))}
                </Input>
            </FormGroup>
        </>
    );
};

export default SelectEventType;