import {
    IonCard,
    IonCardContent,
    IonCol,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { Barn, Event, Horse, Owner } from "../../models";
import { getOwnersByUserByBarn } from "../../utilities/owner/OwnersByBarn";
import QuickAddOwnerForm from "./QuickAddOwnerForm";
import SelectOwner from "./SelectOwner";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    owner?: (Owner | null)
    horse?: (Horse | null)
    barn?: (Barn | null)
    event?: (Event | null)
    onChange: Function
}

const OwnerEntryForm: React.FC<_Props> = ({owner, horse, barn, event, onChange}) => {
    const user = useContext(PersonContext);

    const [ownerList, setOwnerList] = useState<Owner[] | null | undefined>();
    const [selectedOwner, setSelectedOwner] = useState<Owner | null | undefined>();

    const [isExpanded, setIsExpanded] = useState(false);

    async function getOwners() {
        const owners = await getOwnersByUserByBarn(user, barn);
        if (owners) setOwnerList(owners);
    }

    useEffect(() => {
        getOwners();
    }, []);

    useEffect(() => {
        if (owner) setSelectedOwner(owner);
    }, [owner]);

    const handleOwnerSelect = (owner: Owner, type?: string) => {
        if (type === "create") {
            const updatedOwnerList = (ownerList && ownerList.length) ? ownerList.concat([owner]) : [owner];
            setOwnerList(updatedOwnerList);
        }
        setSelectedOwner(owner);
        onChange(owner);
    }

    return (
        <>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="12">
                    <IonLabel position="stacked" color="dark">Owner <RequiredInputIndicator /></IonLabel>
                    <SelectOwner barn={barn} selectedValue={selectedOwner?.id} onSelect={handleOwnerSelect} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="12">
                    <p className="ion-text-center link" onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? "View Less" : selectedOwner ? "Edit Owner" : "New Owner"}</p>
                </IonCol>
            </IonRow>
            {isExpanded && (
                <IonCard color="white" className="ion-no-padding">
                    <IonCardContent className="ion-no-padding">
                        <QuickAddOwnerForm owner={selectedOwner} horse={horse} event={event} onChange={handleOwnerSelect}/>
                    </IonCardContent>
                </IonCard>
            )}
        </>
    );
};

export default OwnerEntryForm;