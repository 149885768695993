import Storage from "@aws-amplify/storage";
import {
    IonCol,
    IonRow,
    IonButton,
    IonButtons,
    IonContent,
    IonIcon,
    IonModal,
    IonTitle,
    IonToolbar,
    IonText,
} from "@ionic/react";
import { close, copyOutline } from "ionicons/icons";
import moment from "moment";
import React, {useEffect, useState} from "react";
import { onCreateAlert } from "../../../graphql/subscriptions";
import { Alert, Event, EventAuditor, Organization } from "../../../models";
import { getAlertsByEventId, getAlertsByOrganizationId } from "../../../utilities/alerts/Alerts";
import { getEventAuditorByPhoneNumber } from "../../../utilities/eventAuditor/EventAuditor";
import { getOrganizationAuditorByPhoneNumber } from "../../../utilities/organizationAuditor/OrganizationAuditor";
import { useSubscriptionByItself } from "../../../utilities/subscription/Subscription";
import ErrorAlert from "../../Errors/ErrorAlert";
import Spinner from "../../Spinners/Spinner";
import { Table } from "reactstrap";

interface _Props {
    event?: Event
    organization?: Organization
}

const ViewEventAlertsTable: React.FC<_Props> = ({event, organization}) => {
    const alertSubscription = useSubscriptionByItself({
        config: {
            query: onCreateAlert,
            key: "onCreateAlert"
        }
    });
    
    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    const [eventAlerts, setEventAlerts] = useState<Alert[] | null | undefined>();
    const [filteredEventAlerts, setFilteredEventAlerts] = useState<Alert[] | null | undefined>();
    const [totalCreditsUsed, setTotalCreditsUsed] = useState(0);
    const [selectedEventAlert, setSelectedEventAlert] = useState<Alert | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [fileName, setFileName] = useState("");
    const [url, setURL] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isViewingOnlyIncoming, setIsViewingOnlyIncoming] = useState(false);
    const [selectedEventAuditor, setSelectedEventAuditor] = useState<EventAuditor | undefined>();
    const [error, setError] = useState("");

    function getTotalCreditCount(alerts: Alert[]) {
        let count = 0;
        if (alerts && alerts.length > 0) {
            for (let i = 0; i < alerts.length; i++) {
                const alert = alerts[i];
                const alertCreditCount = (alert.subscriberCount || 0) * (alert.messageSegments || 0);
                count = count + alertCreditCount;
            }
        }
        setTotalCreditsUsed(count);
    }

    async function getEventAlerts(event: Event) {
        setIsLoading(true);
        const queryResult = await getAlertsByEventId(event.id);
        if (queryResult.isSuccess) {
            const eventAlerts: Alert[] = queryResult.result;
            setEventAlerts(eventAlerts);
            setFilteredEventAlerts(eventAlerts);
            getTotalCreditCount(eventAlerts);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (event) {
            getEventAlerts(event);
        }
    }, [event]);

    async function getOrganizationAlerts(organization: Organization) {
        const queryResult = await getAlertsByOrganizationId(organization.id);
        if (queryResult.isSuccess) {
            const organizationAlerts: Alert[] = queryResult.result;
            setEventAlerts(organizationAlerts);
            setFilteredEventAlerts(organizationAlerts);
            getTotalCreditCount(organizationAlerts);
        }
    }

    useEffect(() => {
        if (organization) {
            setIsLoading(true);
            getOrganizationAlerts(organization);
            setIsLoading(false);
        }
    }, [organization]);

    useEffect(() => {
        if (alertSubscription && alertSubscription[0] !== undefined && alertSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(alertSubscription[0]);
            if (event) {
                getEventAlerts(event);
            }
            if (organization) {
                getOrganizationAlerts(organization);
            }
        }
    }, [alertSubscription]);

    const handleSelectedAlert = async (eventAlert: Alert) => {
        setSelectedEventAlert(eventAlert);
        if (eventAlert.imageKey) {
            if (eventAlert.imageKey.includes("pdf")) {
                setFileName(eventAlert.imageKey);
                handleGetURL(eventAlert.imageKey);
            }
            else {
                setFileName("");
                handleGetURL(eventAlert.imageKey);
            }
        } else {
            setURL("");
            setFileName("");
        }
        if (event && event.eventOptions?.acceptsIncomingTextAlerts && eventAlert.sentFromPhoneNumber) {
            await getEventAuditorFromPhoneNumber(eventAlert.sentFromPhoneNumber);
        }
        setShowModal(true);
    }

    const getEventAuditorFromPhoneNumber = async (phoneNumber: string) => {
        if (event) {
            const queryResult = await getEventAuditorByPhoneNumber(phoneNumber, event.id);
            if (queryResult.isSuccess) {
                const eventAuditor = queryResult.result;
                setSelectedEventAuditor(eventAuditor);
            } 
        } else if (organization) {
            const queryResult = await getOrganizationAuditorByPhoneNumber(phoneNumber, organization.id);
            if (queryResult.isSuccess) {
                const eventAuditor = queryResult.result;
                setSelectedEventAuditor(eventAuditor);
            } 
        }
    }

    const filterAlerts = (setToOnlyIncoming: boolean) => {
        if (setToOnlyIncoming) {
            setIsViewingOnlyIncoming(true);
            let filteredAlerts: Alert[] = [];
            if (eventAlerts) {
                for (let i = 0; i < eventAlerts.length; i++) {
                    const currentEventAlert = eventAlerts[i];
                    if (currentEventAlert.isIncoming) filteredAlerts.push(currentEventAlert);
                }
            }
            setFilteredEventAlerts(filteredAlerts);
        } else {
            setIsViewingOnlyIncoming(false);
            setFilteredEventAlerts(eventAlerts);
        }
    }

    const handleGetURL = async (key: string) => {
        const url = await Storage.get(key);
        if (url && typeof(url) === "string") {
            setURL(url);
        }
    }

    const handleCopyURLClick = async (text: string) => {
        try {
            // Use navigator to copy to the user's clipboard
            await navigator.clipboard.writeText(text);
        } catch (err: any) {
            console.error("Failed to copy");
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ?
                <Spinner />
                :
                <>
                    {filteredEventAlerts ? 
                        <>
                            <IonRow>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonText>Credits used at this event: {totalCreditsUsed.toLocaleString()}</IonText>
                                </IonCol>
                                {event && event.eventOptions?.acceptsIncomingTextAlerts && (
                                    <IonCol sizeXs="12" offsetMd="3" sizeMd="3" className="ion-text-center">
                                        <IonButton color={isViewingOnlyIncoming ? "warning" : "light"} onClick={() => filterAlerts(!isViewingOnlyIncoming)}>Only View Incoming</IonButton>
                                    </IonCol>
                                )}
                            </IonRow>
                            <IonRow>
                                {(filteredEventAlerts && filteredEventAlerts.length > 0) ?
                                    <>
                                        <Table striped responsive>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Body</th>
                                                    <th>Sent</th>
                                                    <th>Credits</th>
                                                    <th>In/Out</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredEventAlerts.map((eventAlert, index) => (
                                                    <tr key={index} onClick={() => handleSelectedAlert(eventAlert)} className={eventAlert.isIncoming ? "bg-warning" : ""}>
                                                        <td className="ion-text-wrap">{filteredEventAlerts.length - index}</td>
                                                        <td className="ion-text-wrap pre-wrap">{(eventAlert.body ? eventAlert.body : (eventAlert.imageKey ? "File" : ""))}</td>
                                                        <td className="ion-text-wrap">{moment(eventAlert.createdOn).format("M/D/YY h:mm a")}</td>
                                                        <td className="ion-text-wrap">{(eventAlert.subscriberCount || 0) * (eventAlert.messageSegments || 0)}</td>
                                                        <td className="ion-text-wrap">{eventAlert?.isIncoming ? "Inbound - Sent From: " + eventAlert.sentFromPhoneNumber : "Outbound"}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </>
                                    :
                                    <p></p>
                                }
                            </IonRow>
                        </>
                        :
                        <p>No alerts yet.</p>
                    }
                </>
            }
            <IonModal backdropDismiss={false} isOpen={showModal} id="alertModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Alert Information
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeAlertModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow>
                        <IonCol>
                            <p>
                                Alert Body:{" "} 
                                <span className="link">
                                    <IonIcon onClick={() => handleCopyURLClick(selectedEventAlert?.body || "")} icon={copyOutline} aria-label="Copy to Clipboard" />
                                </span>
                            </p>
                            
                            <div className="bg-light">
                                <p className="pre-wrap">{selectedEventAlert?.body}</p>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <p>File: </p>
                            {fileName ? 
                                <p><a href={url} target="_blank">{fileName}</a></p>
                                :
                                <img src={url} />
                            }
                        </IonCol>
                    </IonRow>
                    <hr />
                    <IonRow>
                        <IonCol>
                            <p>Number of Phones Sent To: {selectedEventAlert?.recipientPhoneNumbers?.length || ""}</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <p>Message Segments: {selectedEventAlert?.messageSegments || ""}</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <p>Credits Used: {(selectedEventAlert?.subscriberCount || 0) * (selectedEventAlert?.messageSegments || 0)} credits</p>
                        </IonCol>
                    </IonRow>
                    {selectedEventAlert?.isIncoming && (
                        <IonRow>
                            <IonCol>
                                <p>Sent From: {selectedEventAlert.sentFromPhoneNumber} {selectedEventAuditor?.name ? " (" + selectedEventAuditor?.name + ")" : ""} </p>
                            </IonCol>
                        </IonRow>
                    )}
                    <IonRow>
                        <IonCol>
                            <p>Sent Time: {selectedEventAlert?.createdOn && moment(selectedEventAlert.createdOn).format("M/D/YY h:mm a")}</p>
                        </IonCol>
                    </IonRow>
                    {selectedEventAlert?.errorSIDList && (
                        <IonRow>
                            <IonCol>
                                <p>Errors: </p>
                                {selectedEventAlert.errorSIDList.map((sid, index) => (
                                    <IonRow key={index}>
                                        <IonCol>
                                            <p>Message SID: {sid}</p>
                                        </IonCol>
                                    </IonRow>
                                ))}
                            </IonCol>
                        </IonRow>
                    )}
                </IonContent>
            </IonModal>
        </>
    );
};

export default ViewEventAlertsTable;
