import { IonCard, IonCardContent, IonCol, IonIcon, IonLabel, IonRow } from "@ionic/react";
import React from "react";
import {alertCircleOutline} from "ionicons/icons";

interface _Props {
    warning: string
}

const WarningBanner: React.FC<_Props> = ({warning}) => {
    return (
        <>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeSm="12" sizeMd="12" className="ion-no-padding">
                    <IonCard color="warning" mode="md">
                        <IonCardContent className="text-primary font-weight-bold">
                            <IonIcon icon={alertCircleOutline} />
                            <IonLabel id="warningMsg" className="ion-text-wrap description">
                                {" " + warning}
                            </IonLabel>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </>
    );
};

export default WarningBanner;
