import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventStallSetInput, CreateEventStallSetMutation, DeleteEventStallSetInput, DeleteEventStallSetMutation, GetEventStallSetQuery, ListEventStallSetsQuery, UpdateEventStallSetInput, UpdateEventStallSetMutation } from "../../API";
import moment from "moment";
import { EventStallSet } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventStallSet. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventStallSet.
*/
export async function createEventStallSet(input: CreateEventStallSetInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventStallSet", "No input", "Create EventStallSet received no input.");
   try {
        const fullInput: CreateEventStallSetInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventStallSet, { input: fullInput }))) as GraphQLResult<CreateEventStallSetMutation>;
       else result = (await API.graphql({
           query: mutations.createEventStallSet,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventStallSetMutation>;
       const eventStallSet = result.data?.createEventStallSet;
       return formatResult(true, "EventStallSet", eventStallSet, "Successfully created the eventStallSet.");
   } catch (error: any) {
       return formatResult(false, "EventStallSet", error, "Error creating record in the database for type: eventStallSet");
   }
}

/**
* Handle updating a new record in the database for type: eventStallSet. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventStallSet.
*/
export async function updateEventStallSet(input: UpdateEventStallSetInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventStallSet", "No input", "Update EventStallSet received no input.");
   try {
        const fullInput: UpdateEventStallSetInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventStallSet, { input: fullInput }))) as GraphQLResult<UpdateEventStallSetMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventStallSet,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventStallSetMutation>;
       const eventStallSet = result.data?.updateEventStallSet;
       return formatResult(true, "EventStallSet", eventStallSet, "Successfully updated the eventStallSet.");
   } catch (error: any) {
       return formatResult(false, "EventStallSet", error, "Error updating record in the database for type: eventStallSet");
   }
}

/**
* Handle deleting a new record in the database for type: eventStallSet. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventStallSet.
*/
export async function deleteEventStallSet(input: DeleteEventStallSetInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventStallSet", "No input", "Delete EventStallSet received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventStallSet, { input: input }))) as GraphQLResult<DeleteEventStallSetMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventStallSet,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventStallSetMutation>;
       const eventStallSet = result.data?.deleteEventStallSet;
       return formatResult(true, "EventStallSet", eventStallSet, "Successfully deleted the eventStallSet.");
   } catch (error: any) {
       return formatResult(false, "EventStallSet", error, "Error deleting record in the database for type: eventStallSet");
   }
}

/**
* Get all records in the database for type: eventStallSet. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventStallSet object
*/
export async function getAllEventStallSets(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventStallSets,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventStallSetsQuery>;
        
        let items = result.data?.listEventStallSets?.items as EventStallSet[];
        let nextToken = result.data?.listEventStallSets?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventStallSets,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventStallSetsQuery>;

            const nextItems = nextResult.data?.listEventStallSets?.items as EventStallSet[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventStallSets?.nextToken;
        }

        return formatResult(true, "EventStallSet", items, "Successfully got the EventStallSets.");
    } catch (error: any) {
        return formatResult(false, "EventStallSet", error, "Error reading record in the database for type: eventStallSets");
    }
}

/**
* Read a specific record in the database for type: eventStallSet. 
* 
* @param {string}  id                  The eventStallSet id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventStallSet object
*/
export async function getEventStallSetById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventStallSet,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventStallSetQuery>;
       const eventStallSet = result.data?.getEventStallSet;
       return formatResult(true, "EventStallSet", eventStallSet, "Successfully got the eventStallSet.");
   } catch (error: any) {
       return formatResult(false, "EventStallSet", error, "Error reading record in the database for type: eventStallSet");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventStallSet. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventStallSet object
*/
export async function getEventStallSetsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventStallSets,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventStallSetsQuery>;

        let items = result.data?.listEventStallSets?.items as EventStallSet[];
        let nextToken = result.data?.listEventStallSets?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventStallSets,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventStallSetsQuery>;

            const nextItems = nextResult.data?.listEventStallSets?.items as EventStallSet[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventStallSets?.nextToken;
        }

        return formatResult(true, "EventStallSet", items, "Successfully got the EventStallSets.");
    } catch (error: any) {
        return formatResult(false, "EventStallSet", error, "Error reading record in the database for type: eventStallSets");
    }
}