import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import {Clinician, Person, S3Object, Submission, SubmissionFeedback} from "../../../models";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { getSubmissionById, updateSubmission } from "../../../utilities/submission/Submission";
import VideoPlayer from "../../../components/s3Object/VideoPlayer";
import { FeedbackStatus, SubmissionStatus, UpdateSubmissionFeedbackInput, UpdateSubmissionInput } from "../../../API";
import { getSubmissionFeedbacksBySubmissionId, updateSubmissionFeedback } from "../../../utilities/submissionFeedback/SubmissionFeedback";
import ErrorBanner from "../../../components/Banners/ErrorBanner";
import { sendClinicianNewVideoEmail } from "../../../utilities/emails/ClinicsEmail";
import { PersonContext } from "../../../context/PersonContext";
import { getPersonByPersonId } from "../../../utilities/person/Person";

interface SubmissionPageProps extends RouteComponentProps<{
    id: string;
}> {}

const SuccessSubmissionPage: React.FC<SubmissionPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [currentSubmission, setCurrentSubmission] = useState<Submission | null | undefined>();
    const [currentVideo, setCurrentVideo] = useState<S3Object | null | null | undefined>();
    const [error, setError] = useState("");

    async function getSubmission(id: string) {
        const queryResult = await getSubmissionById(id);
        if (queryResult.isSuccess) {
            const submission: Submission = queryResult.result;
            setCurrentSubmission(submission);
            setCurrentVideo(submission.video);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    useEffect(() => {
        getSubmission(match.params.id);
        updateStatus(match.params.id);
    }, [match, match.params.id]);
    
    const updateStatus = async (submissionId: string) => {
        const updateSubmissionInput: UpdateSubmissionInput = {
            id: submissionId,
            status: SubmissionStatus.pending
        };
        const updateSubmissionResult = await updateSubmission(updateSubmissionInput);
        if (updateSubmissionResult.isSuccess) {
            // Look for submission feedback
            const queryResult = await getSubmissionFeedbacksBySubmissionId(submissionId);
            if (queryResult.isSuccess) {
                const submissionFeedbacks = queryResult.result;
                for (let i = 0; i < submissionFeedbacks.length; i++) {
                    const currentSF: SubmissionFeedback = submissionFeedbacks[i];
                    const updateSFinput: UpdateSubmissionFeedbackInput = {
                        id: currentSF.id,
                        // submissionFeedbackFeedbackId: currentSF.feedbackId,
                        submissionStatus: SubmissionStatus.pending,
                        feedbackStatus: FeedbackStatus.pending,
                        // submissionFeedbackFeedbackId: currentSF.feedbackId
                    };
                    const updateSFResult = await updateSubmissionFeedback(updateSFinput);
                    if (currentSF.clinician) await sendEmails(currentSF.clinician);
                    if (!updateSFResult.isSuccess) {
                        setError("Something went wrong. Please contact us at hello@ringsidepro.com");
                    }
                }
            }
        } else {
            setError("Something went wrong. Please contact us at hello@ringsidepro.com");
        }
    }
    
    const sendEmails = async (clinician: Clinician) => {
        if (clinician.personId) {
            const queryResult = await getPersonByPersonId(clinician.personId);
            if (queryResult.isSuccess) {
                const person: Person = queryResult.result;
                const name = user.firstName + " " + user.lastName;
                await sendClinicianNewVideoEmail(person.email, name);
            }
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Success" />
                {error && <ErrorBanner error={error} />}
                <IonRow id="submissionFeedbackName">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white stretch">
                            <IonCardTitle>
                                Status
                            </IonCardTitle>
                            <IonCardContent>
                                <h5 color="success">Submitted</h5> <p>You will receive your feedback within the next two weeks!</p>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                {currentVideo && (
                    <>
                        <IonRow id="submissionFeedbackName">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        Video
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>
                                                <VideoPlayer videoObject={currentVideo} />
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {currentSubmission && (
                    <>
                        <IonRow id="submissionFeedbackName">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        Video Description
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>
                                                <p>{currentSubmission.description}</p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default SuccessSubmissionPage;