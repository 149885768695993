import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonRow,
} from "@ionic/react";
import React from "react";

interface _Props {
    image: string
    name: string
    label: string
    quote: string
}

const Testimonial: React.FC<_Props> = ({image, name, label, quote}) => {
    return (
        <>
            <IonCard color="white stretch">
                <IonCardTitle>
                    <IonRow className="justify-content-center">
                        <IonCol size="6" className="text-center">
                            <img className="shadow bg-white rounded-circle img" alt="testimonial image" src={image}/>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                <IonCardSubtitle>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <h4>{name}</h4>
                            <p className="description">{label}</p>
                        </IonCol>
                    </IonRow>
                </IonCardSubtitle>
                <IonCardContent className="ion-text-center">
                    <p>"{quote}"</p>
                </IonCardContent>
            </IonCard>
        </>
    );
};

export default Testimonial;
