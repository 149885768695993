import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ViewEventNavbar from "../../../components/Navbars/ViewEventNavbar";
import {Event} from "../../../models";
import ErrorAlert from "../../../components/Errors/ErrorAlert";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../utilities/events/Event";
import DisplayScheduleWithClassInfo from "../../../components/Event/Schedule/DisplayScheduleWithClassInfo";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventViewSchedulePage: React.FC<EventPageProps> = ({match}) => {
    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setCurrentEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        getEvent();
    }, [match, match.params.id]);
    
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent ? (currentEvent.nickname ? currentEvent.nickname : currentEvent.name) : "Event"} />
                {currentEvent && (
                    <>
                        <ViewEventNavbar active="schedule" event={currentEvent} />
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonRow className="ion-justify-content-center" id="schedule">
                            <IonCol sizeSm="12" sizeMd="10">
                                <DisplayScheduleWithClassInfo event={currentEvent}/>
                            </IonCol>
                        </IonRow>
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventViewSchedulePage;