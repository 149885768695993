import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateBarnMemberInput, CreateBarnMemberMutation, DeleteBarnMemberInput, DeleteBarnMemberMutation, GetBarnMemberQuery, ListBarnMembersQuery, UpdateBarnMemberInput, UpdateBarnMemberMutation } from "../../API";
import moment from "moment";
import { BarnMember } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: barnMember. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created barnMember.
*/
export async function createBarnMember(input: CreateBarnMemberInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "BarnMember", "No input", "Create BarnMember received no input.");
   try {
        const fullInput: CreateBarnMemberInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createBarnMember, { input: fullInput }))) as GraphQLResult<CreateBarnMemberMutation>;
       else result = (await API.graphql({
           query: mutations.createBarnMember,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateBarnMemberMutation>;
       const barnMember = result.data?.createBarnMember;
       return formatResult(true, "BarnMember", barnMember, "Successfully created the barnMember.");
   } catch (error: any) {
       return formatResult(false, "BarnMember", error, "Error creating record in the database for type: barnMember");
   }
}

/**
* Handle updating a new record in the database for type: barnMember. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated barnMember.
*/
export async function updateBarnMember(input: UpdateBarnMemberInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "BarnMember", "No input", "Update BarnMember received no input.");
   try {
        const fullInput: UpdateBarnMemberInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateBarnMember, { input: fullInput }))) as GraphQLResult<UpdateBarnMemberMutation>;
       else result = (await API.graphql({
           query: mutations.updateBarnMember,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateBarnMemberMutation>;
       const barnMember = result.data?.updateBarnMember;
       return formatResult(true, "BarnMember", barnMember, "Successfully updated the barnMember.");
   } catch (error: any) {
       return formatResult(false, "BarnMember", error, "Error updating record in the database for type: barnMember");
   }
}

/**
* Handle deleting a new record in the database for type: barnMember. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted barnMember.
*/
export async function deleteBarnMember(input: DeleteBarnMemberInput, authMode?: GRAPHQL_AUTH_MODE) {  
   if (!input) return formatResult(false, "BarnMember", "No input", "Delete BarnMember received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteBarnMember, { input: input }))) as GraphQLResult<DeleteBarnMemberMutation>;
       else result = (await API.graphql({
           query: mutations.deleteBarnMember,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteBarnMemberMutation>;
       const barnMember = result.data?.deleteBarnMember;
       return formatResult(true, "BarnMember", barnMember, "Successfully deleted the barnMember.");
   } catch (error: any) {
       return formatResult(false, "BarnMember", error, "Error deleting record in the database for type: barnMember");
   }
}

/**
* Get all records in the database for type: barnMember. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the barnMember object
*/
export async function getAllBarnMembers(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listBarnMembers,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBarnMembersQuery>;

        let items = result.data?.listBarnMembers?.items as BarnMember[];
        let nextToken = result.data?.listBarnMembers?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBarnMembers,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBarnMembersQuery>;

            const nextItems = nextResult.data?.listBarnMembers?.items as BarnMember[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBarnMembers?.nextToken;
        }

        return formatResult(true, "BarnMember", items, "Successfully got the BarnMembers.");
    } catch (error: any) {
        return formatResult(false, "BarnMember", error, "Error reading record in the database for type: barnMembers");
    }
}

/**
* Read a specific record in the database for type: barnMember. 
* 
* @param {string}  id                  The barnMember id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the barnMember object
*/
export async function getBarnMemberById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getBarnMember,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetBarnMemberQuery>;
       const barnMember = result.data?.getBarnMember;
       return formatResult(true, "BarnMember", barnMember, "Successfully got the barnMember.");
   } catch (error: any) {
       return formatResult(false, "BarnMember", error, "Error reading record in the database for type: barnMember");
   }
}

/**
* Get all records in the database that match the given criteria for type: barnMember. 
* 
* @param {string}  personId            The person id of the barnMember
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the barnMember object
*/
export async function getBarnMembersByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.getBarnMembersByPersonId,
            variables: {
                personId: personId,
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.getBarnMembersByPersonId?.items as BarnMember[];
        let nextToken = result.data?.getBarnMembersByPersonId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.getBarnMembersByPersonId,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.getBarnMembersByPersonId?.items as BarnMember[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.getBarnMembersByPersonId?.nextToken;
        }

        return formatResult(true, "BarnMember", items, "Successfully got the BarnMembers.");
    } catch (error: any) {
        return formatResult(false, "BarnMember", error, "Error reading record in the database for type: barnMembers");
    }
}

/**
* Get all records in the database that match the given criteria for type: barnMember. 
* 
* @param {string}  barnId            The barn id of the "owner" of the barnMember
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the barnMember object
*/
export async function getBarnMembersByBarnId(barnId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.getBarnMembersByBarnId,
            variables: {
                barnId: barnId,
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.getBarnMembersByBarnId?.items as BarnMember[];
        let nextToken = result.data?.getBarnMembersByBarnId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.getBarnMembersByBarnId,
                variables: {
                    barnId: barnId,
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.getBarnMembersByBarnId?.items as BarnMember[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.getBarnMembersByBarnId?.nextToken;
        }

        return formatResult(true, "BarnMember", items, "Successfully got the BarnMembers.");
    } catch (error: any) {
        return formatResult(false, "BarnMember", error, "Error reading record in the database for type: barnMembers");
    }
}

/**
* Get all records in the database that match the given criteria for type: barnMember. 
* 
* @param {string}  barnId              The barn id 
* @param {string}  personId            The person id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the barnMember object
*/
export async function getBarnMemberByBarnIdPersonId(barnId: string, personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.getBarnMembersByBarnByPersonId,
            variables: {
                barnId: barnId,
                personId: {eq: personId},
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.getBarnMembersByBarnByPersonId?.items as BarnMember[];
        let nextToken = result.data?.getBarnMembersByBarnByPersonId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.getBarnMembersByBarnByPersonId,
                variables: {
                    barnId: barnId,
                    personId: {eq: personId},
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.getBarnMembersByBarnByPersonId?.items as BarnMember[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.getBarnMembersByBarnByPersonId?.nextToken;
        }

        return formatResult(true, "BarnMember", items[0], "Successfully got the BarnMembers.");
    } catch (error: any) {
        return formatResult(false, "BarnMember", error, "Error reading record in the database for type: barnMembers");
    }
}