import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { UpdateEventEntryInput, UpdateRiderInput, UpdateContactInput, CreateContactInput } from "../../../../../API";
import { EventEntry } from "../../../../../models";
import { updateEventEntry } from "../../../../../utilities/eventEntry/EventEntry";
import { updateRider } from "../../../../../utilities/rider/Rider";
import SuccessBanner from "../../../../Banners/SuccessBanner";
import ErrorAlert from "../../../../Errors/ErrorAlert";
import Spinner from "../../../../Spinners/Spinner";
import { formatTwilioNumber } from "../../../../../utilities/contact/FormatPhoneNumber";
import { createContact, updateContact } from "../../../../../utilities/contact/Contact";
import moment from "moment";
import RiderSelectStatus from "../../../../Rider/RiderSelectStatus";

interface _Props {
    entry: EventEntry
}

const EditEntryRiderDetailsCard: React.FC<_Props> = ({entry}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [riderName, setRiderName] = useState("");
    const [riderLocation, setRiderLocation] = useState("");
    const [riderAge, setRiderAge] = useState("");
    const [riderStatus, setRiderStatus] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [isProfessional, setIsProfessional] = useState(false);
    const [riderEmail, setRiderEmail] = useState("");
    const [riderPhone, setRiderPhone] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const getEntryInformation = (entry: EventEntry) => {
        setCurrentEntry(entry);
        setRiderName(entry.riderName || "");
        setRiderLocation(entry?.rider?.location || "");
        setRiderAge(entry?.rider?.birthdate ? (moment().diff(entry.rider.birthdate, "years").toString() || "") : "");
        setBirthDate(entry.rider?.birthdate?.toString() || "");
        setRiderStatus(entry?.rider?.isProfessional ? "Professional" : "Amateur");
        setRiderEmail(entry.rider?.contact?.personalEmail || "");
        setRiderPhone(entry?.rider?.contact?.cell || "");
    }

    useEffect(() => {
        if (entry) {
            getEntryInformation(entry);
        }
    }, [entry]);

    const handleIsProfessionalInput = (isProfessional: boolean) => {
        setIsProfessional(isProfessional);
        setRiderStatus(isProfessional ? "Professional" : "Amateur");
    }

    const handleEdit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        let contactId = "";
        if (entry.rider?.contact?.id) {
            const updateContactInput: UpdateContactInput = {
                id: entry.rider.contact.id,
                personalEmail: riderEmail,
                cell: formatTwilioNumber(riderPhone) || ""
            };
            const updateContactResult = await updateContact(updateContactInput);
            if (updateContactResult) {
                contactId = updateContactResult.result.id;
            } else {
                setError("Could not update the contact info.");
                setIsLoading(false);
                return;
            }
        } else {
            const createContactInput: CreateContactInput = {
                personalEmail: riderEmail,
                cell: riderPhone ? formatTwilioNumber(riderPhone) : undefined
            };
            const createContactResult = await createContact(createContactInput);
            if (createContactResult) {
                contactId = createContactResult.result.id;
            } else {
                setError("Could not create the contact info.");
                setIsLoading(false);
                return;
            }
        }
        if (entry.riderId) {
            let input: UpdateRiderInput = {
                id: entry.riderId,
                name: riderName,
                isProfessional: isProfessional
            };
            if (contactId) input["riderContactId"] = contactId || "";
            if (riderLocation) input["location"] = riderLocation || "";
            if (riderAge) input["age"] = riderAge ? parseInt(riderAge) : undefined;
            if (birthDate) input["birthdate"] = moment(birthDate).format("YYYY-MM-DD")
            const updateResult = await updateRider(input);
            if (updateResult.isSuccess) {
                const updateEntryInput: UpdateEventEntryInput = {
                    id: entry.id,
                    riderName: riderName,
                    riderId: updateResult.result.id
                };
                const updateEntryResult = await updateEventEntry(updateEntryInput);
                if (updateEntryResult.isSuccess) {
                    const updatedEntry = updateEntryResult.result;
                    setCurrentEntry(updatedEntry);
                    getEntryInformation(updatedEntry);
                    setSuccess("Updated the rider info.");
                    setIsEdit(false);
                    setIsLoading(false);
                } else {
                    setError("Could not update the entry.");
                    setIsLoading(false);
                    return;
                }
            } else {
                setError("Could not update the rider");
                setIsLoading(false);
            }
        } else {
            setError("Could not find the rider in the database.");
            setIsLoading(false);
        }
    }

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h3>Rider</h3>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {success && <SuccessBanner width="12" success={success} />}
                        {isEdit ?
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Name</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={riderName}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setRiderName(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Location</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={riderLocation}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setRiderLocation(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-padding-top">
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Birth Date</IonLabel>
                                            <IonInput 
                                                type="date"
                                                value={birthDate}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setBirthDate(e.detail.value!);
                                                    setRiderAge(moment().diff(e.detail.value!, 'years').toString())
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <RiderSelectStatus rider={currentEntry?.rider} onChange={handleIsProfessionalInput} />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Email</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={riderEmail}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setRiderEmail(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Cell Phone</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={riderPhone}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setRiderPhone(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Name</IonLabel>
                                            <IonText><p>{riderName}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Location</IonLabel>
                                            <IonText><p>{riderLocation}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Age</IonLabel>
                                            <IonText><p>{riderAge}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Status</IonLabel>
                                            <IonText><p>{riderStatus}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Email</IonLabel>
                                            <IonText><p>{riderEmail}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Rider Cell Phone</IonLabel>
                                            <IonText><p>{riderPhone}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                        {isLoading ?
                            <Spinner />
                            :
                            <IonRow className="ion-justify-content-center">
                                {isEdit && (
                                    <IonCol sizeMd="4" className="ion-text-center">
                                        <IonButton
                                            className="ion-margin-top"
                                            color="primary"
                                            expand="block"
                                            onClick={() => setIsEdit(false)}
                                        >
                                            Cancel
                                        </IonButton>
                                    </IonCol>
                                )}
                                <IonCol sizeMd="4" className="ion-text-center">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="tertiary"
                                        expand="block"
                                        onClick={isEdit ? handleEdit : (() => setIsEdit(true))}
                                    >
                                        {!isEdit && "Allow "}Edit
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        }
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default EditEntryRiderDetailsCard;