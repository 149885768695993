import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps, useHistory } from "react-router";
import { Barn, BarnMember, Person } from "../../../../models";
import { getBarnById } from "../../../../utilities/barn/Barn";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import { formatAddress } from "../../../../utilities/address/FormatAddress";
import { removeAllBarnData } from "../../../../utilities/barn/RemoveBarnData";
import Spinner from "../../../../components/Spinners/Spinner";
import { getBarnMemberByBarnIdPersonId } from "../../../../utilities/barnMember/BarnMember";
import { PersonContext } from "../../../../context/PersonContext";
import moment from "moment";
import { getPersonByPersonId } from "../../../../utilities/person/Person";

interface BarnPageProps extends RouteComponentProps<{
    id: string;
}> {}

const BarnSettingsPage: React.FC<BarnPageProps> = ({match}) => {
    const history = useHistory();
    const user = useContext(PersonContext);

    const [barn, setBarn] = useState<Barn>();
    const [barnCreatedByPerson, setBarnCreatedByPerson] = useState<Person | null | undefined>();
    const [barnMember, setBarnMember] = useState<BarnMember>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    async function getBarnMember(barnId: string) {
        const queryResult = await getBarnMemberByBarnIdPersonId(barnId, user.id);
        if (queryResult.isSuccess) {
            setBarnMember(queryResult.result);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    useEffect(() => {
        async function getBarn() {
            const queryResult = await getBarnById(match.params.id);
            if (queryResult.isSuccess) {
                const barn: Barn = queryResult.result;
                setBarn(barn);

                if (barn.ownerPersonId) {
                    const queryPersonResult = await getPersonByPersonId(barn.ownerPersonId);
                    if (queryPersonResult.isSuccess) {
                        setBarnCreatedByPerson(queryPersonResult.result);
                    }
                }

                await getBarnMember(barn.id);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getBarn();
    }, [match]);
      
    const deleteBarn = async () => {
        setIsLoading(true);
        setError("");
        if (barn) {
            const result = await removeAllBarnData(barn);
            if (result.isSuccess) {
                const path = "/index/barn";
                history.push(path);
            } else {
                setError(result.message);
                setIsLoading(false);
            }
        } else {
            setError("No barn found.");
            setIsLoading(false);
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Settings" />
                {error && <ErrorAlert width="12" error={error} />}
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        {(barnMember && barnMember.permissionLevel === "admin") ?
                            <IonCard mode="md" className="ion-padding bg-white">
                                <IonCardTitle>
                                    <IonRow>
                                        <IonCol size="12">
                                            Delete Barn
                                        </IonCol>
                                    </IonRow>
                                </IonCardTitle>
                                <IonCardSubtitle>Remove barn and all of its data.</IonCardSubtitle>
                                {isLoading ?
                                    <Spinner />
                                    :
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>
                                                <p>Removing this barn will permanently delete it along with all of its data. <span className="text-danger font-weight-bold">You cannot undo this action.</span></p>
                                            </IonCol>
                                        </IonRow>
                                        <hr/>
                                        <IonRow>
                                            <IonCol>
                                                <p>Barn you are removing: </p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <p>Name: {barn?.name}</p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <p>Address: {barn?.location ? formatAddress(barn.location) : ""}</p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <p>Created By: {barnCreatedByPerson ? barnCreatedByPerson.firstName + " " + barnCreatedByPerson.lastName : "unknown"}</p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <p>Created On: {barn?.createdOn ? moment(barn.createdOn).format("MM-DD-YYYY hh:mm a") : ""}</p>
                                            </IonCol>
                                        </IonRow>
                                        <hr/>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton id="remove-barn-btn" color="danger" onClick={() => deleteBarn()}>Delete Barn</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                }   
                            </IonCard>
                            :   
                            <IonCard mode="md" className="ion-padding bg-white">
                                <IonCardTitle>
                                    <IonRow>
                                        <IonCol size="12">
                                            Need Access
                                        </IonCol>
                                    </IonRow>
                                </IonCardTitle>
                                <IonCardContent>
                                        <IonRow>
                                            <IonCol>
                                                <p>You must be an admin member of this barn to access the settings.</p>
                                            </IonCol>
                                        </IonRow>
                                </IonCardContent>
                            </IonCard>
                        }
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default BarnSettingsPage;