import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useState, useEffect } from "react";
import BasicBarnInfo from "../../../../components/Barn/BasicBarnInfo";
import { Barn, BarnMember } from "../../../../models";
import PageList from "../../../../components/Lists/PageList";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import SelectBarn from "../../../../components/Barn/SelectBarn";
import Spinner from "../../../../components/Spinners/Spinner";
import { PersonContext } from "../../../../context/PersonContext";
import { getBarnById, getBarnsByPersonId } from "../../../../utilities/barn/Barn";
import { barnListOptions } from "../../../../interfaces/Page";
import BarnRequests from "../../../../components/Barn/BarnRequests";
import { getBarnMembersByPersonId } from "../../../../utilities/barnMember/BarnMember";
import SearchBarns from "../../../../components/Barn/SearchBarns";
import { useSubscriptionByItself } from "../../../../utilities/subscription/Subscription";
import { onCreateBarn } from "../../../../graphql/subscriptions";

const BarnPage: React.FC = () => {
    const user = useContext(PersonContext);

    const barnSubscription = useSubscriptionByItself({
        config: {
            query: onCreateBarn,
            key: "onCreateBarn"
        }
    });

    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();

    const [barnMembers, setBarnMembers] = useState<BarnMember[] | null | undefined>();
    const [currentBarn, setCurrentBarn] = useState<Barn | null | undefined>();
    const [currentStatus, setCurrentStatus] = useState("");

    const [isLoading, setIsLoading] = useState(true);

    /**
     * To determine if this person already has joined at least one barn
     * Check to see if there are any "BarnMember" records with this person id
     */
    const getBarnMembers = async () => {
        const queryResult = await getBarnMembersByPersonId(user.id);
        console.log("Query for barn members: ", queryResult);
        if (queryResult.isSuccess) {
            const memberList = queryResult.result;
            console.log("Member list: ", memberList);
            setBarnMembers(memberList);

            if (memberList && memberList.length > 0) {
                const currentMember: BarnMember = memberList[0];
                setCurrentStatus(currentMember.permissionLevel || "member");
                const barnResult = await getBarnById(currentMember.barn.id);
                if(barnResult.isSuccess) {
                    setCurrentBarn(barnResult.result);
                }
            } else {
                const queryResultByPerson = await getBarnsByPersonId(user.id);
                console.log("Query ... ", queryResultByPerson)
                if (queryResultByPerson.isSuccess) {
                    setCurrentBarn(queryResultByPerson.result[0]);
                    setCurrentStatus("admin");
                }
            }
            setIsLoading(false);
        } else {
            const queryResultByPerson = await getBarnsByPersonId(user.id);
            if (queryResultByPerson.isSuccess) {
                setCurrentBarn(queryResultByPerson.result[0]);
                setCurrentStatus("admin");
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (user) getBarnMembers();
    }, [user, user.id]);

    useEffect(() => {
        if (barnSubscription && barnSubscription[0] !== undefined && barnSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(barnSubscription[0]);
            getBarnMembers();
        }
    }, [barnSubscription]);

    const handleBarnSelection = (selectedBarn: Barn) => {
        setCurrentBarn(selectedBarn);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Barn" />
                {currentBarn && barnMembers ?
                    <>
                        <IonRow>
                            <IonCol sizeXs="8" sizeMd="4">
                                {barnMembers.length > 1 && (
                                    <SelectBarn selectedValue={currentBarn.id} isClearable={false} barnMemberOptions={barnMembers} onSelect={handleBarnSelection} />
                                )}
                            </IonCol>
                            <IonCol sizeXs="12" offsetMd="4" sizeMd="4" className="ion-text-right">
                                <IonButton
                                    color="primary"
                                    routerLink="/index/barn/add"    
                                >
                                    Add Barn
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <BasicBarnInfo barn={currentBarn} />
                            </IonCol>
                        </IonRow>
                        {(currentStatus === "admin" || user.id === "fe487dbd-fc7b-4f42-883f-abcddd45ae45" || user.id === "ab683fe0-383b-405d-a1a8-ce6177b28231") && 
                            <IonRow className="ion-text-center ion-justify-content-center">
                                <IonCol sizeSm="12" sizeMd="8">
                                    <BarnRequests selectedBarn={currentBarn}/>
                                </IonCol>
                            </IonRow>
                        }
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <PageList id={currentBarn.id} includeId={true} listOptions={barnListOptions} />
                            </IonCol>
                        </IonRow>
                    </>
                    :
                    <>
                        {isLoading ?
                            <Spinner />
                            :
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" offsetMd="8" sizeMd="4" className="ion-text-right">
                                        <IonButton
                                            color="primary"
                                            routerLink="/index/staff/barn/create"    
                                        >
                                            Create Barn
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol sizeSm="12" sizeMd="12">
                                        <IonCard mode="md" className="ion-padding bg-white">
                                            <IonCardTitle>Join a Barn</IonCardTitle>
                                            <IonCardContent>
                                                <p className="ion-text-center">Search for barns and ask to join a barn. This will allow you to share horse and people information with barn staff.</p>
                                                <SearchBarns />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default BarnPage;