import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { Event, EventEntry, StablingRequest } from "../../../models";
import { getEventEntryById } from "../../../utilities/eventEntry/EventEntry";
import { deleteStablingRequest } from "../../../utilities/stablingRequest/StablingRequest";

interface _Props {
    event: Event
    stablingRequest: StablingRequest
    // onSubmit: Function
}

const ViewStablingRequestCard: React.FC<_Props> = ({stablingRequest, event}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [entries, setEntries] = useState<EventEntry[] | null | undefined>();

    const getEventEntriesByIds = async (entryIds: (string | null)[]) => {
        let result: EventEntry[] = [];
        if (entryIds && entryIds.length) {
            for (var i = 0; i< entryIds.length; i++) {
                const currentId = entryIds[i];
                if (currentId) {
                    const eventEntryResult = await getEventEntryById(currentId);
                    if (eventEntryResult.isSuccess) {
                        const eventEntry = eventEntryResult.result;
                        result.push(eventEntry);
                    }
                }
            }
        }
        setEntries(result);
    }

    useEffect(() => {
        if (stablingRequest.entryIds) getEventEntriesByIds(stablingRequest.entryIds);
    }, [stablingRequest]);

    const getCardTitle = () => {
        if (entries && entries.length > 1) {
            return (
                <>
                    <h2>Split Across Entries: {stablingRequest.stallType?.name}</h2> <h6 className="pl-2">(status: {stablingRequest.status === "submitted" ? <span className="text-success">saved and submitted</span> : <span className="text-warning">saved - need to submit</span>})</h6>
                    <h4>Total: ${stablingRequest.totalPrice?.toFixed(2)}</h4>
                </>
            );
        } else if (entries && entries.length === 1) {
            return (
                <>
                    <h2>{entries[0]?.horseName}: {stablingRequest.stallType?.name}</h2> <h6 className="pl-2">(status: {stablingRequest.status === "submitted" ? <span className="text-success">saved and submitted</span> : <span className="text-warning">saved - need to submit</span>})</h6> 
                    <h4>Total: ${stablingRequest.totalPrice?.toFixed(2)}</h4>
                </>
            );
        } else {
            return <h2>Other: {stablingRequest.stallType?.name} (${stablingRequest.totalPrice?.toFixed(2)})</h2>;
        }
    }

    // const handleDelete = async () => {
    //     const deleteResult = await deleteStablingRequest({id: stablingRequest.id});
    //     onSubmit();
    // }

    return (
        <>
            <IonCard>
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {getCardTitle()}
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent className="bg-white">
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12" className="ion-text-center">
                                <h2>Request Status: {stablingRequest.status}</h2>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonRow>
                                    <IonCol>
                                        <h2>Total Price: ${stablingRequest.totalPrice?.toFixed(2)}</h2>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <h4>Base Price for {stablingRequest.stallType?.name}: ${stablingRequest.basePrice?.toFixed(2)}</h4>
                                        <h4>Quantity: {stablingRequest.quantityNeeded}</h4>
                                        <h4>Tax Rate: {stablingRequest.taxA}%</h4>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                            <IonCol>
                                <IonRow>
                                    <IonCol>
                                        <h2>Trainer: {stablingRequest.trainer?.name}</h2>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <h2>Stable With: {stablingRequest.barn?.name}</h2>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonLabel>Stabling Notes</IonLabel>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <Input
                                    rows="2"
                                    disabled
                                    type="textarea"
                                    name="note"
                                    value={stablingRequest.requestNote || ""}
                                    spellCheck="true"
                                    data-hj-whitelist
                                />
                            </IonCol>
                        </IonRow>
                        {/* <IonRow>
                                <IonCol className="text-center" sizeXs="4" sizeMd="2">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="danger"
                                        expand="block"
                                        onClick={handleDelete}
                                    >
                                        Delete Request
                                    </IonButton>
                                </IonCol>
                        </IonRow> */}
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default ViewStablingRequestCard;