import {
    IonButton,
    IonCol,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Event, ScheduleItem, EventRing, EventDay, EventClass, Ring, EventBreak } from "../../../models";
import ErrorAlert from "../../Errors/ErrorAlert";
import { PersonContext } from "../../../context/PersonContext";
import Spinner from "../../Spinners/Spinner";
import { createScheduleItem, deleteScheduleItem, getScheduleItemsByEventIdByDayByRing, updateScheduleItem } from "../../../utilities/scheduleItem/ScheduleItem";
import SuccessBanner from "../../Banners/SuccessBanner";
import SelectCreateRing from "../../Ring/SelectCreateRing";
import SelectEventDay from "../../EventDay/SelectEventDay";
import SelectEventClass from "../../EventClass/SelectEventClass";
import TimePicker from "../../TimePicker";
import BasicTooltip from "../../Tooltip/BasicTooltip";
import { FormGroup, Input, Label } from "reactstrap";
import { CreateScheduleItemInput, UpdateScheduleItemInput } from "../../../API";
import { getEventClassById } from "../../../utilities/eventClass/EventClass";
import { getEventDayById } from "../../../utilities/eventDay/EventDay";
import { getEventRingById } from "../../../utilities/eventRing/EventRing";
import SelectEventRing from "../../EventRing/SelectEventRing";
import { getEventBreakById } from "../../../utilities/eventBreak/EventBreak";
import SelectEventBreak from "../../EventBreak/SelectEventBreak";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";

interface _Props {
    event: Event
    scheduleItem?: (ScheduleItem | null)
    onSubmit: Function
}

const ScheduleItemForm: React.FC<_Props> = ({scheduleItem, event, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectedEventClass, setSelectedEventClass] = useState<EventClass | null | undefined>(); 
    const [selectedEventBreak, setSelectedEventBreak] = useState<EventBreak | null | undefined>(); 
    const [selectedEventDay, setSelectedEventDay] = useState<EventDay | null | undefined>(); 
    const [selectedEventRing, setSelectedEventRing] = useState<EventRing | null | undefined>(); 
    const [displayOrder, setDisplayOrder] = useState<number | null | undefined>(0); 
    const [isNewOrder, setIsNewOrder] = useState(false); 
    const [startTime, setStartTime] = useState<string | null | undefined>(""); 
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    const setEventClass = async (eventClassId: string) => {
        const queryResult = await getEventClassById(eventClassId);
        if (queryResult.isSuccess) {
            const eventClass = queryResult.result;
            setSelectedEventClass(eventClass);
        }
    }

    const setEventBreak = async (eventBreakId: string) => {
        const queryResult = await getEventBreakById(eventBreakId);
        if (queryResult.isSuccess) {
            const eventBreak = queryResult.result;
            setSelectedEventBreak(eventBreak);
        }
    }

    const setEventDay = async (eventDayId: string) => {
        const queryResult = await getEventDayById(eventDayId);
        if (queryResult.isSuccess) {
            const eventDay = queryResult.result;
            setSelectedEventDay(eventDay);
        }
    }

    const setEventRing = async (eventRingId: string) => {
        const queryResult = await getEventRingById(eventRingId);
        if (queryResult.isSuccess) {
            const eventRing = queryResult.result;
            setSelectedEventRing(eventRing);
        }
    }

    useEffect(() => {
        if (scheduleItem) {
            if (scheduleItem.classId) setEventClass(scheduleItem.classId);
            if (scheduleItem.breakId) setEventBreak(scheduleItem.breakId);
            if (scheduleItem.eventDayId) setEventDay(scheduleItem.eventDayId);
            if (scheduleItem.eventRingId) setEventRing(scheduleItem.eventRingId);
            setDisplayOrder(scheduleItem.displayOrder);
            setStartTime(scheduleItem.startTime);
            setSuccess("");
            setError("");
            setIsDisabled(false);
        }
    }, [scheduleItem]);

    const clearForm = () => {
        setSelectedEventClass(undefined);
        setSelectedEventBreak(undefined);
        setSelectedEventDay(undefined);
        setSelectedEventRing(undefined);
        setDisplayOrder(0);
        setStartTime(undefined);
        setSuccess("");
        setError("");
        scheduleItem = undefined;
        onSubmit();
    }

    const verifyForm = () => {
        try {
            if (!selectedEventClass && !selectedEventBreak) {
                setError("You must select a class or a break.");
                return false;
            }
            return true;
        } catch (error: any) {
            setError(error);
            return false;
        }
    }

    const handleSelectedRing = (eventRing: EventRing) => {
        setSelectedEventRing(eventRing);
    }

    const handleSelectedDay = (day: EventDay) => {
        setSelectedEventDay(day);
    }

    const handleSelectedEventClass = (eventClass: EventClass) => {
        setSelectedEventClass(eventClass);
        setSelectedEventBreak(undefined);
    }

    const handleSelectedEventBreak = (eventBreak: EventBreak) => {
        setSelectedEventBreak(eventBreak);
        setSelectedEventClass(undefined);
    }

    const handleSelectedOrder = (order: number) => {
        if (scheduleItem && order !== displayOrder) setIsNewOrder(true);
        setDisplayOrder(order);
    }

    const handleSelectedTime = (time: string) => {
        setStartTime(time);
    }

    const handleCreateScheduleItem = async () => {
        if (selectedEventRing && selectedEventDay) {
            const input: CreateScheduleItemInput = {
                eventId: event.id,
                eventRingId: selectedEventRing.id,
                // scheduleItemEventRingId: selectedEventRing.id,
                eventDayId: selectedEventDay.id,
                // scheduleItemEventDayId: selectedEventDay.id,
                classId: selectedEventClass?.id,
                // scheduleItemEventClassId: selectedEventClass?.id,
                breakId: selectedEventBreak?.id,
                // scheduleItemEventBreakId: selectedEventBreak?.id,
                displayOrder: displayOrder,
                startTime: startTime
            }
            const createScheduleItemResult = await createScheduleItem(input);
            if (createScheduleItemResult.isSuccess) {
                setError("");
                setSuccess("Successfully created the schedule item");
            } else {
                setSuccess("");
                const message = "Error occurred: " + createScheduleItemResult.message;
                setError(message);
            }
        } else {
            setError("Please select a day and a ring.");
        }
    }

    const handleUpdateScheduleItem = async () => {
        if (scheduleItem) {
            if (selectedEventRing && selectedEventDay) {
                const input: UpdateScheduleItemInput = {
                    id: scheduleItem.id,
                    eventId: event.id,
                    eventRingId: selectedEventRing.id,
                    // scheduleItemEventRingId: selectedEventRing.id,
                    eventDayId: selectedEventDay.id,
                    // scheduleItemEventDayId: selectedEventDay.id,
                    classId: selectedEventClass?.id,
                    // scheduleItemEventClassId: selectedEventClass?.id,
                    displayOrder: displayOrder,
                    startTime: startTime
                };
                const createScheduleItemResult = await updateScheduleItem(input);
                if (createScheduleItemResult.isSuccess) {
                    if (isNewOrder) {
                        // Next, reorder the items for this day / ring
                        const queryResult = await getScheduleItemsByEventIdByDayByRing(event.id, selectedEventDay.id, selectedEventRing.id);
                        if (queryResult.isSuccess) {
                            const scheduleItems = queryResult.result;
                            if (scheduleItems && scheduleItems.length) {
                                for (var i = 0; i < scheduleItems.length; i++) {
                                    const current = scheduleItems[i];
                                    const itemInput: UpdateScheduleItemInput = {
                                        id: current.id,
                                        displayOrder: i+1
                                    };
                                    await updateScheduleItem(itemInput);
                                }
                            }
                        } else {
                            // Could not find other items to update
                            setError("");
                            setSuccess("Successfully updated the schedule item");
                        }
                    } else {
                        setError("");
                        setSuccess("Successfully updated the schedule item");
                    }
                } else {
                    setSuccess("");
                    const message = "Error occurred: " + createScheduleItemResult.message;
                    setError(message);
                }
            } else {
                setError("Please select a day and a ring.");
            }
        } else {
            setError("Sorry, there was no schedule item to update.");
        }
    }

    const handleSubmit = async () => {
        const isValid = verifyForm();
        if (!isValid) {
            setIsLoading(false);
            return;
        }

        if (scheduleItem) {
            await handleUpdateScheduleItem()
        } else {
            await handleCreateScheduleItem();
        }
    }

    const handleDelete = async () => {
        if (scheduleItem) {
            const deleteResult = await deleteScheduleItem({id: scheduleItem.id});
            if (deleteResult.isSuccess) {
                clearForm();
                setSuccess("Successfully deleted the schedule item.");
            } else {
                setError("Error occurred while deleting the schedule item.");
            }
        }
    }
    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonLabel position="stacked">Day <RequiredInputIndicator/></IonLabel>
                        <SelectEventDay eventId={event.id} selectedValue={selectedEventDay?.id} onSelect={handleSelectedDay} />
                    </IonCol>
                </IonRow>
                {event && event.organizationId && (
                    <IonRow>
                        <IonCol>
                            <IonLabel position="stacked">Ring <RequiredInputIndicator/> <span className="description">Start typing to see your rings or create a ring.</span></IonLabel>
                            {/* <SelectCreateRing currentEvent={event} selectedValue={selectedEventRing?.ringId} organizationId={event.organizationId} onSelect={handleSelectedRing} /> */}
                            <SelectEventRing event={event} isDisplayDefault={true} onSelect={handleSelectedRing} />
                        </IonCol>
                    </IonRow>
                )}
                <hr />
                <IonRow>
                    <IonCol>
                        <IonLabel position="stacked">Class <span className="description">Start typing to see your event classes.</span></IonLabel>
                        <SelectEventClass eventId={event.id} selectedValue={selectedEventClass?.id} onSelect={handleSelectedEventClass} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel position="stacked">Break <span className="description">Start typing to see your event breaks.</span></IonLabel>
                        <SelectEventBreak eventId={event.id} selectedValue={selectedEventBreak?.id} onSelect={handleSelectedEventBreak} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <FormGroup>
                            <Label>Order in the Schedule</Label>
                            <Input 
                                type="number"
                                min="0"
                                value={displayOrder!}
                                placeholder="0"
                                onChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    handleSelectedOrder(parseInt(e.target.value))
                                }}
                            />
                        </FormGroup>
                    </IonCol>
                    <IonCol>
                        <BasicTooltip label="Estimated Start Time" tip="You can add a start time for the class. If you do not want a start time, leave it blank or use the DELETE key to remove the time." />
                        <TimePicker selectedTime={startTime} onChange={handleSelectedTime}/>
                    </IonCol>
                </IonRow>
                
                <IonRow>
                    <IonCol sizeMd="12">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {scheduleItem ? "Edit" : "Add"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
                <IonRow>
                    {scheduleItem && (
                        <>
                            <IonCol sizeMd="4">
                                {isLoading ?
                                    <Spinner />
                                    :
                                    <IonButton
                                        className="ion-margin-top"
                                        color="primary"
                                        expand="block"
                                        onClick={clearForm}
                                    >
                                        Reset Form
                                    </IonButton>
                                }
                            </IonCol>
                            <IonCol offsetMd="4" sizeMd="4">
                                {isLoading ?
                                    <Spinner />
                                    :
                                    <IonButton
                                        className="ion-margin-top"
                                        color="danger"
                                        expand="block"
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </IonButton>
                                }
                            </IonCol>
                        </>
                    )}
                </IonRow>
            </form>
        </>
    );
};

export default ScheduleItemForm;