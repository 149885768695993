 import * as mutations from "../../graphql/mutations";
 import * as queries from "../../graphql/queries";
 import { API, graphqlOperation } from "aws-amplify";
 import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

 // Configure Amplify
 import Amplify from "aws-amplify";
 import awsmobile from "../../aws-exports";
 
import formatResult from "../ReturnType/ReturnType";
import { CreateAddressInput, CreateAddressMutation, DeleteAddressInput, DeleteAddressMutation, GetAddressQuery, ListAddressesQuery, UpdateAddressInput, UpdateAddressMutation } from "../../API";
import { Address } from "../../models";
 
 Amplify.configure(awsmobile);
 
/**
 * Handle creating a new record in the database for type: address. 
 * 
 * @param {object}  input                   Check schema for input.
 * @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
 * 
 * @return {string} Returns the newly created address.
 */
export async function createAddress(input: CreateAddressInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "Address", "No input", "Create Address received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createAddress, { input: input }))) as GraphQLResult<CreateAddressMutation>;
        else result = (await API.graphql({
            query: mutations.createAddress,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<CreateAddressMutation>;
        const address = result.data?.createAddress;
        return formatResult(true, "Address", address, "Successfully created the address.");
    } catch (error: any) {
        return formatResult(false, "Address", error, "Error creating record in the database for type: address");
    }
}

/**
 * Handle updating a new record in the database for type: address. 
 * 
 * @param {object}  input                   Check schema for input.
 * @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
 * 
 * @return {string} Returns the newly updated address.
 */
export async function updateAddress(input: UpdateAddressInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "Address", "No input", "Update Address received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateAddress, { input: input }))) as GraphQLResult<UpdateAddressMutation>;
        else result = (await API.graphql({
            query: mutations.updateAddress,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<UpdateAddressMutation>;
        const address = result.data?.updateAddress;
        return formatResult(true, "Address", address, "Successfully updated the address.");
    } catch (error: any) {
        return formatResult(false, "Address", error, "Error updating record in the database for type: address");
    }
}

/**
 * Handle deleting a new record in the database for type: address. 
 * 
 * @param {object}  input                   Just requires the ID of the object.
 * @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
 * 
 * @return {string} Returns the newly deleted address.
 */
 export async function deleteAddress(input: DeleteAddressInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "Address", "No input", "Delete Address received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteAddress, { input: input }))) as GraphQLResult<DeleteAddressMutation>;
        else result = (await API.graphql({
            query: mutations.deleteAddress,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<DeleteAddressMutation>;
        const address = result.data?.deleteAddress;
        return formatResult(true, "Address", address, "Successfully deleted the address.");
    } catch (error: any) {
        return formatResult(false, "Address", error, "Error deleting record in the database for type: address");
    }
}

/**
* Get all records in the database for type: address. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the address object
*/
export async function getAllAddresses(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listAddresses,
            variables: {
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListAddressesQuery>;

        let items = result.data?.listAddresses?.items as Address[];
        let nextToken = result.data?.listAddresses?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listAddresses,
                variables: {
                    limit: 1000,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListAddressesQuery>;

            const nextItems = nextResult.data?.listAddresses?.items as Address[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listAddresses?.nextToken;
        }

        return formatResult(true, "Alert", items, "Successfully got the addresses.");
    } catch (error: any) {
        return formatResult(false, "Alert", error, "Error reading record in the database for type: addresses");
    }
}

/**
 * Read a specific record in the database for type: address. 
 * 
 * @param {string}  id                  The address id.
 * @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
 * 
 * @return {object} Returns the address object
 */
export async function getAddressById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.getAddress,
            variables: {
                id: id
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<GetAddressQuery>;
        const address = result.data?.getAddress;
        return formatResult(true, "Address", address, "Successfully got the address.");
    } catch (error: any) {
        return formatResult(false, "Address", error, "Error reading record in the database for type: address");
    }
}