import React, { useContext, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { Barn, Horse } from "../../models";
import { getAllHorses, getHorsesByBarnId, getHorsesByPersonId } from "../../utilities/horse/Horse";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import { IonCol } from "@ionic/react";
import ErrorAlert from "../Errors/ErrorAlert";
import { getPersonNameByPersonId } from "../../utilities/person/PersonName";
const { v4: uuidv4 } = require("uuid");

interface _Props {
    selectedBarn?: Barn | null;
    selectedValue?: string | null;
    onSelect: Function;
    isHeightRequired?: boolean;
    height?: string;
}

interface formattedOption {
    value: string; //Horse's id
    label: string; //Used for filtering options as a user types
    horseName: string; //Horse's Show Name - will display when item is selected
    horseNameLabel?: string; //Horse's Show Name + Nickname - will display in options
    barnLabel?: string; //Barn Name - will display in options
    ownerLabel?: string; //Date the horse was created - will display in options
    createdByLabel?: string;
    horse?: Horse;
}

interface formattedGroupOfOptions {
    label: string;
    options: formattedOption[];
}

/**
 * This function is passed into the select component to define what should be shown when an option is selected
 * Instead of showing the 3 lines from the option,
 * it will only show the 1 line defined below: horseName
 * @param props
 * @returns
 */
const SingleValue = (props: any) => <components.SingleValue {...props}>{props.data.horseName || props.data.label}</components.SingleValue>;

const SelectOrCreateHorse: React.FC<_Props> = ({ selectedBarn, selectedValue, onSelect, isHeightRequired, height }) => {
    const user = useContext(PersonContext);

    // Dropdown Section Titles
    const allHorsesGroupLabel = "All Horses in RingSide Pro database";
    const createdByUserGroupLabel = "Horses created by you";
    const barnHorsesGroupLabel = "Barn Horses";

    // We group the options into: 1-Currently Selected Barn's horses or 2-All Horses
    const [formattedHorses, setFormattedHorses] = useState<formattedOption[] | null | undefined>();
    const [formattedBarnHorses, setFormattedBarnHorses] = useState<formattedOption[] | null | undefined>();
    const [formattedHorsesCreatedByUser, setFormattedHorsesCreatedByUser] = useState<formattedOption[] | null | undefined>();

    // We then put the formatted options into defined Groups
    const [allHorsesGroup, setAllHorsesGroup] = useState<formattedGroupOfOptions | null | undefined>();
    const [barnHorsesGroup, setBarnHorsesGroup] = useState<formattedGroupOfOptions>({ label: barnHorsesGroupLabel, options: [] });
    const [horsesCreatedByUserGroup, setHorsesCreatedByUserGroup] = useState<formattedGroupOfOptions>({
        label: createdByUserGroupLabel,
        options: [],
    });

    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAllHorses, setIsLoadingAllHorses] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        getHorses();
    }, []);

    useEffect(() => {
        if (user) {
            getHorsesCreatedByUser();
        }
    }, [user]);

    // QUERIES
    useEffect(() => {
        if (selectedBarn) {
            getBarnHorses();
        }
    }, [selectedBarn]);

    useEffect(() => {
        if (selectedBarn) {
            removeBarnHorsesFromCreatedByGroup(formattedHorsesCreatedByUser!, formattedBarnHorses!);
        } else {
            updateCreatedByGroup(formattedHorsesCreatedByUser!);
        }
    }, [formattedHorsesCreatedByUser]);

    // QUERIES
    const getHorses = async () => {
        setIsLoadingAllHorses(true);
        try {
            // Get the current list of horses
            let currentHorseList: Horse[] = [];
            const queryResult = await getAllHorses();
            if (queryResult.isSuccess) {
                currentHorseList = queryResult.result;
            }

            // Sort and format the list
            const currentFormattedHorses = await formatHorses(currentHorseList);

            // Set the formatted options and group in state
            setFormattedHorses(currentFormattedHorses);

            // If barn horses are already there, remove them from the full list and set All Horses Group
            if (formattedBarnHorses) {
                removeBarnHorsesFromAllHorsesGroup(formattedBarnHorses);
            } else {
                const allGroup: formattedGroupOfOptions = {
                    label: allHorsesGroupLabel,
                    options: currentFormattedHorses,
                };
                setAllHorsesGroup(allGroup);
            }

            // If the parent component has passed in an already selected value, set it from the current options
            if (selectedValue) findValueInList(selectedValue, currentFormattedHorses);
        } catch (error) {
            console.error("Error fetching horses created by user:", error);
        } finally {
            setIsLoadingAllHorses(false);
        }
    };

    const removeBarnHorsesFromAllHorsesGroup = async (barnHorses: formattedOption[]) => {
        if (allHorsesGroup && formattedHorses) {
            const updatedFormattedHorses = formattedHorses.filter((el) => !barnHorses.find((bh) => bh.value === el.value));
            const allGroup: formattedGroupOfOptions = {
                label: allHorsesGroupLabel,
                options: updatedFormattedHorses,
            };
            setAllHorsesGroup(allGroup);
        }
    };

    const findValueInList = (value: string, horseList?: formattedOption[]) => {
        let optionArray = horseList || formattedHorses;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    const getBarnHorses = async () => {
        setIsLoading(true);
        try {
            if (selectedBarn) {
                const queryHorsesByBarn = await getHorsesByBarnId(selectedBarn.id);
                if (queryHorsesByBarn.isSuccess) {
                    const result = queryHorsesByBarn.result;
                    if (result.length === 0) {
                        setFormattedBarnHorses([]);
                        const textToDisplay = "No horses found in " + (selectedBarn?.name || "barn");
                        const barnHorsesGroup: formattedGroupOfOptions = {
                            label: barnHorsesGroupLabel,
                            options: [{ value: textToDisplay, label: "", horseName: "" }],
                        };
                        setBarnHorsesGroup(barnHorsesGroup);
                    } else {
                        // Add CreatedByLabel populated with personName to formattedBarnHorses
                        const formattedList = await Promise.all(
                            result.map(async (horse: Horse) => {
                                const formattedHorse = await formatSingleHorse(horse);
                                const personName = await getPersonNameById(horse.personId!);

                                return {
                                    ...formattedHorse,
                                    createdByLabel: personName || "Unknown", // Ensure createdByLabel is assigned
                                } as formattedOption;
                            }),
                        );

                        setFormattedBarnHorses(formattedList);
                        setBarnHorsesGroup({
                            label: barnHorsesGroupLabel,
                            options: formattedList,
                        });
                    }
                    if (selectedValue) findValueInList(selectedValue, formattedBarnHorses!);

                    // Fetch horses created by user after barn horses are set
                    await getHorsesCreatedByUser();
                }
            }
        } catch (error) {
            console.error("Error fetching barn horses:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getPersonNameById = async (personId: string) => {
        const person = await getPersonNameByPersonId(personId);
        let personName = "Undefined";

        if (person.isSuccess) {
            personName = `${person.result.firstName} ${person.result.lastName}`;
        }
        console.log(`Person Name: ${JSON.stringify(personName)}`);
        return personName;
    };

    const getHorsesCreatedByUser = async () => {
        setIsLoading(true);
        try {
            const queryHorsesByCreatedByUser = await getHorsesByPersonId(user.id);
            if (queryHorsesByCreatedByUser.isSuccess) {
                const result = queryHorsesByCreatedByUser.result;
                if (result.length === 0) {
                    setFormattedHorsesCreatedByUser([]);
                    const textToDisplay = "You have not created any horses yet.";
                    const createdByUserGroup: formattedGroupOfOptions = {
                        label: createdByUserGroupLabel,
                        options: [{ value: textToDisplay, label: "", horseName: "" }],
                    };
                    setHorsesCreatedByUserGroup(createdByUserGroup);
                } else {
                    let formattedList = await formatHorses(result);
                    setFormattedHorsesCreatedByUser(formattedList);
                    // Triggers useEffect that runs the filtering function, this is necessary to ensure action only happens when lists are populated with current data
                }
            }
        } catch (error) {
            console.error("Error fetching horses created by user:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const removeBarnHorsesFromCreatedByGroup = (createdByList: formattedOption[], barnHorsesList: formattedOption[]) => {
        // Remove all of the barnHorses from the CreatedBy list
        const filteredList = createdByList.filter(
            (createdByHorse) => !barnHorsesList.some((barnHorse) => barnHorse.value === createdByHorse.value),
        );
        // Update the HorsesCreatedByGroup with filteredList that eliminates barn horses
        updateCreatedByGroup(filteredList);
    };

    const updateCreatedByGroup = (createdByList: formattedOption[]) => {
        setHorsesCreatedByUserGroup({
            label: createdByUserGroupLabel,
            options: createdByList,
        });
    };

    // FORMATTERS
    const formatHorses = async (horseArray: Horse[]) => {
        setIsLoading(true);

        let formattedHorses = [];

        for (var i = 0; i < horseArray.length; i++) {
            const horse = horseArray[i];
            let object: formattedOption = formatSingleHorse(horse);
            formattedHorses.push(object);
        }

        return formattedHorses;
    };

    const formatSingleHorse = (horse: Horse) => {
        const value = horse.id;
        const name = horse.name;
        const nickname = horse.nickname || "";
        const horseNameLabel = (name || "error") + (nickname ? " (" + nickname + ")" : "");
        const barnName = horse.barn?.name || horse.barnName || "unknown";
        const ownerLabel = horse.ownerName || horse.owner?.name || "unknown";

        let object: formattedOption = {
            value: value,
            label: horseNameLabel + " " + barnName,
            horseName: name,
            horseNameLabel: horseNameLabel,
            barnLabel: barnName,
            ownerLabel: ownerLabel,
            horse: horse,
        };
        return object;
    };

    // EVENT HANDLERS
    const handleOnChange = (event?: any) => {
        if (event && event.horse) {
            setCurrentValue(event);
            onSelect(event.horse);
        } else if (event && event.value) {
            // When a new option is selected from the "Create" option, it will only have a value
            if (event.horseName) {
                // This option was created by the user and then selected again
                setCurrentValue(event);
                onSelect(null, event.horseName);
            } else {
                // Format the new option
                const newFormattedOption: formattedOption = {
                    value: uuidv4(),
                    label: event.value + " " + (selectedBarn?.name || ""),
                    horseName: event.value,
                    horseNameLabel: event.value,
                    barnLabel: selectedBarn?.name || "",
                };

                if (selectedBarn) {
                    // If a barn is currently selected, then add the new option to the list of barn horses
                    const updatedFormattedBarnHorses = formattedBarnHorses
                        ? formattedBarnHorses.concat([newFormattedOption])
                        : [newFormattedOption];
                    const sortedFormattedBarnHorses = updatedFormattedBarnHorses.sort((a, b) =>
                        a.horseName.trim().localeCompare(b.horseName.trim()),
                    );
                    setFormattedBarnHorses(updatedFormattedBarnHorses);
                    const barnGroup: formattedGroupOfOptions = {
                        label: barnHorsesGroupLabel,
                        options: sortedFormattedBarnHorses,
                    };
                    setBarnHorsesGroup(barnGroup);
                } else {
                    // Add the horse to the full list of options
                    const updatedFormattedHorsesCreatedByUser = formattedHorses
                        ? formattedHorses.concat([newFormattedOption])
                        : [newFormattedOption];
                    setFormattedHorsesCreatedByUser(updatedFormattedHorsesCreatedByUser);
                    const horsesCreatedByUserGroup: formattedGroupOfOptions = {
                        label: createdByUserGroupLabel,
                        options: updatedFormattedHorsesCreatedByUser,
                    };
                    setHorsesCreatedByUserGroup(horsesCreatedByUserGroup);
                }
                // Set this option as the currently selected option
                setCurrentValue(newFormattedOption);
                onSelect(null, event.value);
            }
        } else {
            setCurrentValue(null);
            onSelect(null);
        }
    };

    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <IonCol size="10">
                    {error && <ErrorAlert error={error} width="12" />}
                    <CreatableSelect
                        id="select-or-create-horse"
                        inputId="select-or-create-horse-input"
                        placeholder="Type the horse's name"
                        styles={{
                            // Fixes the overlapping problem of the component
                            control: (baseStyles) => (isHeightRequired ? { ...baseStyles, height } : { ...baseStyles }),
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                            group: (base, state) => ({
                                ...base,
                                // Group background color
                                backgroundColor:
                                    state.label === barnHorsesGroupLabel ? "var(--ion-color-info)" : "var(--ion-color-secondary)",
                                padding: "10px",
                            }),
                            groupHeading: (base, state) => ({
                                ...base,
                                fontSize: "1em",
                                fontWeight: "bold",
                                // Section title color
                                color: "var(--ion-color-white)",
                            }),
                            option: (base, { isFocused, isSelected }) => ({
                                ...base,
                                // Hover vs. no hover colors for horses in list
                                backgroundColor: isFocused || isSelected ? "var(--ion-color-light)" : "var(--ion-color-white)",
                                cursor: "pointer",
                                color: "var(--ion-color-primary)",
                            }),
                        }}
                        defaultValue={currentValue}
                        value={currentValue}
                        menuPortalTarget={document.body}
                        menuPlacement={"auto"}
                        isClearable
                        options={[barnHorsesGroup, horsesCreatedByUserGroup]}
                        noOptionsMessage={({ inputValue }) => (!inputValue ? "No input found" : "No results found")}
                        formatOptionLabel={(option: any) =>
                            option.horseNameLabel ? (
                                <div className="px-10">
                                    <div className="font-weight-bold text-default">{option.horseNameLabel}</div>
                                    {barnHorsesGroup.options.includes(option) ? (
                                        <div className="display-5 text-medium">Created By: {option.createdByLabel}</div>
                                    ) : (
                                        <div className="display-5 text-medium">Barn: {option.barnLabel}</div>
                                    )}
                                    <div className="display-5 text-medium">Owner: {option.ownerLabel}</div>
                                </div>
                            ) : (
                                <div className="p-0">
                                    {/* TODO: Should be unclickable */}
                                    <div className="font-weight-bold text-default">Other Horse: {option.value}</div>
                                </div>
                            )
                        }
                        components={{ SingleValue }}
                        onChange={(event: any) => handleOnChange(event)}
                        createOptionPosition={"first"}
                    />
                </IonCol>
            )}
        </>
    );
};

export default SelectOrCreateHorse;
