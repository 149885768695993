import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Horse } from "../../../models";
import { updateHorse } from "../../../utilities/horse/Horse";
import ErrorAlert from "../../Errors/ErrorAlert";
import HorseHeightInput from "../HorseHeightInput";
import { UpdateHorseInput } from "../../../API";
import SuccessBanner from "../../Banners/SuccessBanner";
import { Input, Label } from "reactstrap";
import { breeds } from "../../../utilities/horse/Breed";

interface _Props {
    horse: Horse
}

const EditHorseBasicInfoForm: React.FC<_Props> = ({horse}) => {    
    const [name, setName] = useState("");
    const [nickname, setNickname] = useState("");
    const [height, setHeight] = useState(0);
    const [yearBorn, setYearBorn] = useState("");
    const [breed, setBreed] = useState("");
    const [sex, setSex] = useState("");
    
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const clearForm = () => {
        setName("");
        setNickname("");
        setHeight(0);
        setYearBorn("");
        setBreed("");
        setSex("");
    }

    useEffect(() => {
        if (horse) {
            setName(horse.name);
            setNickname(horse.nickname || "");
            setYearBorn(horse.dateOfBirth ? moment(horse.dateOfBirth).format("YYYY"): "");
            setBreed(horse.breed || "");
            setSex(horse.sex || "");
            if (horse.height) setHeight(parseFloat(horse.height));
        } else {
            clearForm();
        }
    }, [horse]);

    const verifyForm = () => {
        if (!name) {
            setError("Please include a registered name for the horse");
            return false;
        }
        if (!height) {
            setError("Please include a height for the horse");
            return false;
        }
        return true;
    }

    const handleHeightChange = (heightInInches: number) => {
        setHeight(heightInInches);
    }

    const handleUpdateHorse = async () => {
        let input: UpdateHorseInput = {
            id: horse.id,
            name,
            nickname,
            height: height.toString(),
            breed,
            sex
        };
        if (yearBorn) input.dateOfBirth = moment(yearBorn).format("YYYY-MM-DD");
        const updateResult = await updateHorse(input);
        if (updateResult.isSuccess) {
            setError("");
            setSuccess("Successfully saved the updates.");
        } else {
            setSuccess("");
            setError(updateResult.message);
        }
    }

    const handleEditHorse = async () => {
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            await handleUpdateHorse();
        }
    }

    return (
        <>
            {success && <SuccessBanner success={success} />}
            {error && <ErrorAlert error={error} />}
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Registered Name</IonLabel>
                        <IonInput 
                            type="text"
                            value={name}
                            aria-required={true}
                            onIonChange={e => {
                                setName(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Nickname</IonLabel>
                        <IonInput 
                            type="text"
                            value={nickname}
                            aria-required={true}
                            onIonChange={e => {
                                setNickname(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <HorseHeightInput height={height} onChange={handleHeightChange} />
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Year Born</IonLabel>
                        <IonInput 
                            type="text"
                            value={yearBorn}
                            aria-required={true}
                            onIonChange={e => {
                                setYearBorn(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <Label for="breedSelect">Breed</Label>
                    <Input 
                        type="select" 
                        name="select"
                        value={breed}
                        id="breedSelect"
                        onChange={e => setBreed(e.target.value)}
                    >
                        <option>-</option>
                        {breeds.map((breed, index) => (
                            <option key={index}>{breed}</option>
                        ))}
                    </Input>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <Label for="sexSelect">Sex</Label>
                    <Input 
                        type="select" 
                        name="select"
                        value={sex}
                        id="sexSelect"
                        onChange={e => setSex(e.target.value)}
                    >
                        <option>-</option>
                        <option>Gelding</option>
                        <option>Mare</option>
                        <option>Stallion</option>
                        <option>Colt</option>
                    </Input>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton
                        className="ion-margin-top"
                        color="tertiary"
                        expand="block"
                        onClick={handleEditHorse}
                    >
                        Save
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default EditHorseBasicInfoForm;