import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreatePersonNameInput, CreatePersonNameMutation, DeletePersonNameInput, DeletePersonNameMutation, GetPersonNameQuery, ListPersonNamesQuery, UpdatePersonNameInput, UpdatePersonNameMutation } from "../../API";
import { PersonName } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: personName. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created personName.
*/
export async function createPersonName(input: CreatePersonNameInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "PersonName", "No input", "Create PersonName received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createPersonName, { input: input }))) as GraphQLResult<CreatePersonNameMutation>;
       else result = (await API.graphql({
           query: mutations.createPersonName,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreatePersonNameMutation>;
       const personName = result.data?.createPersonName;
       return formatResult(true, "PersonName", personName, "Successfully created the personName.");
   } catch (error: any) {
       return formatResult(false, "PersonName", error, "Error creating record in the database for type: personName");
   }
}

/**
* Handle updating a new record in the database for type: personName. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated personName.
*/
export async function updatePersonName(input: UpdatePersonNameInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "PersonName", "No input", "Update PersonName received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updatePersonName, { input: input }))) as GraphQLResult<UpdatePersonNameMutation>;
       else result = (await API.graphql({
           query: mutations.updatePersonName,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdatePersonNameMutation>;
       const personName = result.data?.updatePersonName;
       return formatResult(true, "PersonName", personName, "Successfully updated the personName.");
   } catch (error: any) {
       return formatResult(false, "PersonName", error, "Error updating record in the database for type: personName");
   }
}

/**
* Handle deleting a new record in the database for type: personName. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted personName.
*/
export async function deletePersonName(input: DeletePersonNameInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "PersonName", "No input", "Delete PersonName received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deletePersonName, { input: input }))) as GraphQLResult<DeletePersonNameMutation>;
       else result = (await API.graphql({
           query: mutations.deletePersonName,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeletePersonNameMutation>;
       const personName = result.data?.deletePersonName;
       return formatResult(true, "PersonName", personName, "Successfully deleted the personName.");
   } catch (error: any) {
       return formatResult(false, "PersonName", error, "Error deleting record in the database for type: personName");
   }
}

/**
* Get all records in the database for type: personName. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the personName object
*/
export async function getAllPersonNames(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listPersonNames,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPersonNamesQuery>;
        
        let items = result.data?.listPersonNames?.items as PersonName[];
        let nextToken = result.data?.listPersonNames?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listPersonNames,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListPersonNamesQuery>;

            const nextItems = nextResult.data?.listPersonNames?.items as PersonName[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPersonNames?.nextToken;
        }

        return formatResult(true, "PersonName", items, "Successfully got the PersonNames.");
    } catch (error: any) {
        return formatResult(false, "PersonName", error, "Error reading record in the database for type: personNames");
    }
}

/**
* Read a specific record in the database for type: personName. 
* 
* @param {string}  id                  The personName id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the personName object
*/
export async function getPersonNameById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getPersonName,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetPersonNameQuery>;
       const personName = result.data?.getPersonName;
       return formatResult(true, "PersonName", personName, "Successfully got the personName.");
   } catch (error: any) {
       return formatResult(false, "PersonName", error, "Error reading record in the database for type: personName");
   }
}

/**
* Get record in the database that match the given criteria for type: personName. 
* 
* @param {string}  personId            The person id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the personName object
*/
export async function getPersonNameByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        let personName;

        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listPersonNames,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPersonNamesQuery>;

        const items = result.data?.listPersonNames?.items;
        let nextToken = result.data?.listPersonNames?.nextToken;

        if (items && items.length) {
            personName = items[0];
        } else {
            while (nextToken) {
                const nextResult = await API.graphql({
                    query: queries.listPersonNames,
                    variables: {
                        limit: 1000,
                        filter,
                        nextToken
                    },
                    authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
                }) as GraphQLResult<ListPersonNamesQuery>;

                const nextItems = nextResult.data?.listPersonNames?.items;
                if (nextItems && nextItems.length) {
                    personName = nextItems[0];
                }

                nextToken = nextResult.data?.listPersonNames?.nextToken;
            }
        }

        if (personName) return formatResult(true, "PersonName", personName, "Successfully got the personName.");
        else return formatResult(false, "PersonName", null, "Could not find the personName.");
    } catch (error: any) {
        return formatResult(false, "PersonName", error, "Error reading record in the database for type: personName");
    }
}

/**
* Get record in the database that match the given criteria for type: personName. 
* 
* @param {string}  personId            The person id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the personName object
*/
export async function getDisplayName(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listPersonNames,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPersonNamesQuery>;

        let items = result.data?.listPersonNames?.items as PersonName[];
        let nextToken = result.data?.listPersonNames?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listPersonNames,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListPersonNamesQuery>;

            const nextItems = nextResult.data?.listPersonNames?.items as PersonName[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPersonNames?.nextToken;
        }

        const personNames = items;
        if (personNames && personNames[0]) return formatResult(true, "PersonName", personNames[0].displayName, "Successfully got the personName.");
        else return formatResult(false, "PersonName", null, "Could not find the personName.");
    } catch (error: any) {
        return formatResult(false, "PersonName", error, "Error reading record in the database for type: personName");
    }
}