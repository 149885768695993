import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateOrganizationBeddingTypeInput, CreateOrganizationBeddingTypeMutation, DeleteOrganizationBeddingTypeInput, DeleteOrganizationBeddingTypeMutation, GetOrganizationBeddingTypeQuery, ListOrganizationBeddingTypesQuery, UpdateOrganizationBeddingTypeInput, UpdateOrganizationBeddingTypeMutation } from "../../API";
import moment from "moment";
import { OrganizationBeddingType } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: organizationBeddingType. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created organizationBeddingType.
*/
export async function createOrganizationBeddingType(input: CreateOrganizationBeddingTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationBeddingType", "No input", "Create OrganizationBeddingType received no input.");
   try {
        const fullInput: CreateOrganizationBeddingTypeInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createOrganizationBeddingType, { input: fullInput }))) as GraphQLResult<CreateOrganizationBeddingTypeMutation>;
       else result = (await API.graphql({
           query: mutations.createOrganizationBeddingType,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateOrganizationBeddingTypeMutation>;
       const organizationBeddingType = result.data?.createOrganizationBeddingType;
       return formatResult(true, "OrganizationBeddingType", organizationBeddingType, "Successfully created the organizationBeddingType.");
   } catch (error: any) {
       return formatResult(false, "OrganizationBeddingType", error, "Error creating record in the database for type: organizationBeddingType");
   }
}

/**
* Handle updating a new record in the database for type: organizationBeddingType. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated organizationBeddingType.
*/
export async function updateOrganizationBeddingType(input: UpdateOrganizationBeddingTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationBeddingType", "No input", "Update OrganizationBeddingType received no input.");
   try {
        const fullInput: UpdateOrganizationBeddingTypeInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateOrganizationBeddingType, { input: fullInput }))) as GraphQLResult<UpdateOrganizationBeddingTypeMutation>;
       else result = (await API.graphql({
           query: mutations.updateOrganizationBeddingType,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateOrganizationBeddingTypeMutation>;
       const organizationBeddingType = result.data?.updateOrganizationBeddingType;
       return formatResult(true, "OrganizationBeddingType", organizationBeddingType, "Successfully updated the organizationBeddingType.");
   } catch (error: any) {
       return formatResult(false, "OrganizationBeddingType", error, "Error updating record in the database for type: organizationBeddingType");
   }
}

/**
* Handle deleting a new record in the database for type: organizationBeddingType. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted organizationBeddingType.
*/
export async function deleteOrganizationBeddingType(input: DeleteOrganizationBeddingTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationBeddingType", "No input", "Delete OrganizationBeddingType received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteOrganizationBeddingType, { input: input }))) as GraphQLResult<DeleteOrganizationBeddingTypeMutation>;
       else result = (await API.graphql({
           query: mutations.deleteOrganizationBeddingType,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteOrganizationBeddingTypeMutation>;
       const organizationBeddingType = result.data?.deleteOrganizationBeddingType;
       return formatResult(true, "OrganizationBeddingType", organizationBeddingType, "Successfully deleted the organizationBeddingType.");
   } catch (error: any) {
       return formatResult(false, "OrganizationBeddingType", error, "Error deleting record in the database for type: organizationBeddingType");
   }
}

/**
* Get all records in the database for type: organizationBeddingType. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationBeddingType object
*/
export async function getAllOrganizationBeddingTypes(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listOrganizationBeddingTypes,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationBeddingTypesQuery>;
        
        let items = result.data?.listOrganizationBeddingTypes?.items as OrganizationBeddingType[];
        let nextToken = result.data?.listOrganizationBeddingTypes?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizationBeddingTypes,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationBeddingTypesQuery>;

            const nextItems = nextResult.data?.listOrganizationBeddingTypes?.items as OrganizationBeddingType[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationBeddingTypes?.nextToken;
        }

        return formatResult(true, "OrganizationBeddingType", items, "Successfully got the OrganizationBeddingTypes.");
    } catch (error: any) {
        return formatResult(false, "OrganizationBeddingType", error, "Error reading record in the database for type: organizationBeddingTypes");
    }
}

/**
* Read a specific record in the database for type: organizationBeddingType. 
* 
* @param {string}  id                  The organizationBeddingType id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationBeddingType object
*/
export async function getOrganizationBeddingTypeById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getOrganizationBeddingType,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetOrganizationBeddingTypeQuery>;
       const organizationBeddingType = result.data?.getOrganizationBeddingType;
       return formatResult(true, "OrganizationBeddingType", organizationBeddingType, "Successfully got the organizationBeddingType.");
   } catch (error: any) {
       return formatResult(false, "OrganizationBeddingType", error, "Error reading record in the database for type: organizationBeddingType");
   }
}

/**
* Get all records in the database that match the given criteria for type: organizationBeddingType. 
* 
* @param {string}  organizationId      The organization id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationBeddingType object
*/
export async function getOrganizationBeddingTypesByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationId: {eq: organizationId}};
        const result = (await API.graphql({
            query: queries.listOrganizationBeddingTypes,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationBeddingTypesQuery>;
        
        let items = result.data?.listOrganizationBeddingTypes?.items as OrganizationBeddingType[];
        let nextToken = result.data?.listOrganizationBeddingTypes?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizationBeddingTypes,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationBeddingTypesQuery>;

            const nextItems = nextResult.data?.listOrganizationBeddingTypes?.items as OrganizationBeddingType[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationBeddingTypes?.nextToken;
        }

        return formatResult(true, "OrganizationBeddingType", items, "Successfully got the OrganizationBeddingTypes.");
    } catch (error: any) {
        return formatResult(false, "OrganizationBeddingType", error, "Error reading record in the database for type: organizationBeddingTypes");
    }
}