import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateTextInput, CreateTextMutation, DeleteTextInput, DeleteTextMutation, GetTextQuery, ListTextsQuery, UpdateTextInput, UpdateTextMutation } from "../../API";
import moment from "moment";
import { Text } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: text. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created text.
*/
export async function createText(input: CreateTextInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Text", "No input", "Create Text received no input.");
   try {
        const fullInput: CreateTextInput = {
            ...input,
            timeSent: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createText, { input: fullInput }))) as GraphQLResult<CreateTextMutation>;
       else result = (await API.graphql({
           query: mutations.createText,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateTextMutation>;
       const text = result.data?.createText;
       return formatResult(true, "Text", text, "Successfully created the text.");
   } catch (error: any) {
       return formatResult(false, "Text", error, "Error creating record in the database for type: text");
   }
}

/**
* Handle updating a new record in the database for type: text. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated text.
*/
export async function updateText(input: UpdateTextInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Text", "No input", "Update Text received no input.");
   try {
        const fullInput: UpdateTextInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateText, { input: fullInput }))) as GraphQLResult<UpdateTextMutation>;
       else result = (await API.graphql({
           query: mutations.updateText,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateTextMutation>;
       const text = result.data?.updateText;
       return formatResult(true, "Text", text, "Successfully updated the text.");
   } catch (error: any) {
       return formatResult(false, "Text", error, "Error updating record in the database for type: text");
   }
}

/**
* Handle deleting a new record in the database for type: text. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted text.
*/
export async function deleteText(input: DeleteTextInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Text", "No input", "Delete Text received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteText, { input: input }))) as GraphQLResult<DeleteTextMutation>;
       else result = (await API.graphql({
           query: mutations.deleteText,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteTextMutation>;
       const text = result.data?.deleteText;
       return formatResult(true, "Text", text, "Successfully deleted the text.");
   } catch (error: any) {
       return formatResult(false, "Text", error, "Error deleting record in the database for type: text");
   }
}

/**
* Get all records in the database for type: text. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the text object
*/
export async function getAllTexts(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listTexts,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTextsQuery>;
        
        let items = result.data?.listTexts?.items as Text[];
        let nextToken = result.data?.listTexts?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listTexts,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTextsQuery>;

            const nextItems = nextResult.data?.listTexts?.items as Text[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTexts?.nextToken;
        }

        return formatResult(true, "Text", items, "Successfully got the Texts.");
    } catch (error: any) {
        return formatResult(false, "Text", error, "Error reading record in the database for type: texts");
    }
}

/**
* Read a specific record in the database for type: text. 
* 
* @param {string}  id                  The text id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the text object
*/
export async function getTextById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getText,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetTextQuery>;
       const text = result.data?.getText;
       return formatResult(true, "Text", text, "Successfully got the text.");
   } catch (error: any) {
       return formatResult(false, "Text", error, "Error reading record in the database for type: text");
   }
}

/**
* Get all records in the database that match the given criteria for type: text. 
* 
* @param {string}  personId            The person id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the text object
*/
export async function getTextsByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listTexts,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTextsQuery>;
        
        let items = result.data?.listTexts?.items as Text[];
        let nextToken = result.data?.listTexts?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listTexts,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTextsQuery>;

            const nextItems = nextResult.data?.listTexts?.items as Text[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTexts?.nextToken;
        }

        return formatResult(true, "Text", items, "Successfully got the Texts.");
    } catch (error: any) {
        return formatResult(false, "Text", error, "Error reading record in the database for type: texts");
    }
}

/**
* Get all records in the database that match the given criteria for type: text. 
* 
* @param {string}  alertId             The alert id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the text object
*/
export async function getTextsByAlertId(alertId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {alertId: {eq: alertId}};
        const result = (await API.graphql({
            query: queries.listTexts,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTextsQuery>;

        let items = result.data?.listTexts?.items as Text[];
        let nextToken = result.data?.listTexts?.nextToken;

        if (nextToken) {
            while (nextToken) {
                const nextResult = await API.graphql({
                    query: queries.listTexts,
                    variables: {
                        filter,
                        limit: 1000,
                        nextToken
                    },
                    authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
                }) as GraphQLResult<ListTextsQuery>;
        
                const nextItems = nextResult.data?.listTexts?.items as Text[];
                if (nextItems && nextItems.length) {
                    items = items?.concat(nextItems);
                }
                nextToken = nextResult.data?.listTexts?.nextToken;
            }
        }

        if (items && items.length > 0) {
            return formatResult(true, "Text", items, "Successfully got the texts.");
        } else {
            return formatResult(false, "Text", null, "Found no texts.");
        }
    } catch (error: any) {
        return formatResult(false, "Text", error, "Error reading record in the database for type: texts");
    }
}

/**
* Get all records in the database that match the given criteria for type: text. 
* 
* @param {string}  auditorId           The auditor id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the text object
*/
export async function getTextsByAuditorId(auditorId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {auditorId: {eq: auditorId}};
        const result = (await API.graphql({
            query: queries.listTexts,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTextsQuery>;
        
        let items = result.data?.listTexts?.items as Text[];
        let nextToken = result.data?.listTexts?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listTexts,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTextsQuery>;

            const nextItems = nextResult.data?.listTexts?.items as Text[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTexts?.nextToken;
        }

        return formatResult(true, "Text", items, "Successfully got the Texts.");
    } catch (error: any) {
        return formatResult(false, "Text", error, "Error reading record in the database for type: texts");
    }
}

/**
* Get all records in the database that match the given criteria for type: text. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the text object
*/
export async function getTextsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.textsByEventId,
            variables: {
                limit: 1000,
                eventId: eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as any;

        let items = result.data?.textsByEventId?.items as Text[];
        let nextToken = result.data?.textsByEventId?.nextToken;
        
        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.textsByEventId,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as any;

            const nextItems = nextResult.data?.textsByEventId?.items as Text[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.textsByEventId?.nextToken;

        }

        return formatResult(true, "Text", items, "Successfully got the Texts.");
    } catch (error: any) {
        return formatResult(false, "Text", error, "Error reading record in the database for type: texts");
    }
}