import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateMembershipInput, CreateMembershipMutation, DeleteMembershipInput, DeleteMembershipMutation, GetMembershipQuery, ListMembershipsQuery, UpdateMembershipInput, UpdateMembershipMutation } from "../../API";
import moment from "moment";
import { Membership } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: membership. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created membership.
*/
export async function createMembership(input: CreateMembershipInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Membership", "No input", "Create Membership received no input.");
   try {
        const fullInput: CreateMembershipInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createMembership, { input: fullInput }))) as GraphQLResult<CreateMembershipMutation>;
       else result = (await API.graphql({
           query: mutations.createMembership,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateMembershipMutation>;
       const membership = result.data?.createMembership;
       return formatResult(true, "Membership", membership, "Successfully created the membership.");
   } catch (error: any) {
       return formatResult(false, "Membership", error, "Error creating record in the database for type: membership");
   }
}

/**
* Handle updating a new record in the database for type: membership. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated membership.
*/
export async function updateMembership(input: UpdateMembershipInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Membership", "No input", "Update Membership received no input.");
   try {
        const fullInput: UpdateMembershipInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateMembership, { input: fullInput }))) as GraphQLResult<UpdateMembershipMutation>;
       else result = (await API.graphql({
           query: mutations.updateMembership,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateMembershipMutation>;
       const membership = result.data?.updateMembership;
       return formatResult(true, "Membership", membership, "Successfully updated the membership.");
   } catch (error: any) {
       return formatResult(false, "Membership", error, "Error updating record in the database for type: membership");
   }
}

/**
* Handle deleting a new record in the database for type: membership. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted membership.
*/
export async function deleteMembership(input: DeleteMembershipInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Membership", "No input", "Delete Membership received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteMembership, { input: input }))) as GraphQLResult<DeleteMembershipMutation>;
       else result = (await API.graphql({
           query: mutations.deleteMembership,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteMembershipMutation>;
       const membership = result.data?.deleteMembership;
       return formatResult(true, "Membership", membership, "Successfully deleted the membership.");
   } catch (error: any) {
       return formatResult(false, "Membership", error, "Error deleting record in the database for type: membership");
   }
}

/**
* Get all records in the database for type: membership. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getAllMemberships(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listMemberships,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListMembershipsQuery>;
        
        let items = result.data?.listMemberships?.items as Membership[];
        let nextToken = result.data?.listMemberships?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listMemberships,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListMembershipsQuery>;

            const nextItems = nextResult.data?.listMemberships?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listMemberships?.nextToken;
        }

        return formatResult(true, "Membership", items, "Successfully got the memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Read a specific record in the database for type: membership. 
* 
* @param {string}  id                  The membership id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getMembershipById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getMembership,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetMembershipQuery>;
       const membership = result.data?.getMembership;
       return formatResult(true, "Membership", membership, "Successfully got the membership.");
   } catch (error: any) {
       return formatResult(false, "Membership", error, "Error reading record in the database for type: membership");
   }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  personId            The person id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getUSEFMembershipByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}, name: {eq: "USEF"}};
        const result = (await API.graphql({
            query: queries.listMemberships,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListMembershipsQuery>;
        
        let items = result.data?.listMemberships?.items as Membership[];
        let nextToken = result.data?.listMemberships?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listMemberships,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListMembershipsQuery>;

            const nextItems = nextResult.data?.listMemberships?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listMemberships?.nextToken;
        }

        const memberships = items;
        if (memberships && memberships[0]) return formatResult(true, "Membership", memberships[0], "Successfully got the membership.");
        else return formatResult(false, "Membership", null, "Could not find the membership.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: membership");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  riderId             The rider id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getUSEFMembershipByRiderId(riderId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {riderId: {eq: riderId}, name: {eq: "USEF"}};
        const result = (await API.graphql({
            query: queries.listMemberships,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListMembershipsQuery>;
        
        let items = result.data?.listMemberships?.items as Membership[];
        let nextToken = result.data?.listMemberships?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listMemberships,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListMembershipsQuery>;

            const nextItems = nextResult.data?.listMemberships?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listMemberships?.nextToken;
        }

        const memberships = items;
        if (memberships && memberships[0]) return formatResult(true, "Membership", memberships[0], "Successfully got the membership.");
        else return formatResult(false, "Membership", null, "Could not find the membership.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: membership");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  personId            The person id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getECMembershipByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}, name: {eq: "EC"}};
        const result = (await API.graphql({
            query: queries.listMemberships,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListMembershipsQuery>;
        
        let items = result.data?.listMemberships?.items as Membership[];
        let nextToken = result.data?.listMemberships?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listMemberships,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListMembershipsQuery>;

            const nextItems = nextResult.data?.listMemberships?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listMemberships?.nextToken;
        }

        const memberships = items;
        if (memberships && memberships[0]) return formatResult(true, "Membership", memberships[0], "Successfully got the membership.");
        else return formatResult(false, "Membership", null, "Could not find the membership.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: membership");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  riderId             The rider id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getECMembershipByRiderId(riderId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {riderId: {eq: riderId}, name: {eq: "EC"}};
        const result = (await API.graphql({
            query: queries.listMemberships,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListMembershipsQuery>;

        let items = result.data?.listMemberships?.items as Membership[];
        let nextToken = result.data?.listMemberships?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listMemberships,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListMembershipsQuery>;

            const nextItems = nextResult.data?.listMemberships?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listMemberships?.nextToken;
        }

        const memberships = items;
        if (memberships && memberships[0]) return formatResult(true, "Membership", memberships[0], "Successfully got the membership.");
        else return formatResult(false, "Membership", null, "Could not find the membership.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: membership");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  personId            The person id of the "owner" of the membership
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getMembershipsByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listMemberships,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListMembershipsQuery>;

        let items = result.data?.listMemberships?.items as Membership[];
        let nextToken = result.data?.listMemberships?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listMemberships,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListMembershipsQuery>;

            const nextItems = nextResult.data?.listMemberships?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listMemberships?.nextToken;
        }

        return formatResult(true, "Membership", items, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  personName          The person name of the "owner" of the membership
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getMembershipsByPersonName(personName: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personName: {eq: personName}};
        const result = (await API.graphql({
            query: queries.listMemberships,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListMembershipsQuery>;

        let items = result.data?.listMemberships?.items as Membership[];
        let nextToken = result.data?.listMemberships?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listMemberships,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListMembershipsQuery>;

            const nextItems = nextResult.data?.listMemberships?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listMemberships?.nextToken;
        }

        return formatResult(true, "Membership", items, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  riderId             The rider id of the "owner" of the membership
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getMembershipsByRiderId(riderId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.membershipsByRiderId,
            variables: {
                limit: 1000,
                riderId: riderId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.membershipsByRiderId?.items as Membership[];
        let nextToken = result.data?.membershipsByRiderId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.membershipsByRiderId,
                variables: {
                    limit: 100,
                    riderId: riderId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.membershipsByRiderId?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.membershipsByRiderId?.nextToken;
        }

        return formatResult(true, "Membership", items, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  trainerId           The trainer id of the "owner" of the membership
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getMembershipsByTrainerId(trainerId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.membershipsByTrainerId,
            variables: {
                limit: 1000,
                trainerId: trainerId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.membershipsByTrainerId?.items as Membership[];
        let nextToken = result.data?.membershipsByTrainerId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.membershipsByTrainerId,
                variables: {
                    limit: 100,
                    trainerId: trainerId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.membershipsByTrainerId?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.membershipsByTrainerId?.nextToken;
        }

        return formatResult(true, "Membership", items, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  ownerId           The owner id of the "owner" of the membership
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getMembershipsByOwnerId(ownerId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.membershipsByOwnerId,
            variables: {
                limit: 1000,
                ownerId: ownerId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.membershipsByOwnerId?.items as Membership[];
        let nextToken = result.data?.membershipsByOwnerId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.membershipsByOwnerId,
                variables: {
                    limit: 100,
                    ownerId: ownerId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.membershipsByOwnerId?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.membershipsByOwnerId?.nextToken;
        }

        return formatResult(true, "Membership", items, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  horseId           The horse id of the "horse" of the membership
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getMembershipsByHorseId(horseId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.membershipsByHorseId,
            variables: {
                limit: 1000,
                horseId: horseId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.membershipsByHorseId?.items as Membership[];
        let nextToken = result.data?.membershipsByHorseId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.membershipsByHorseId,
                variables: {
                    limit: 100,
                    horseId: horseId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.membershipsByHorseId?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.membershipsByHorseId?.nextToken;
        }

        return formatResult(true, "Membership", items, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  organizationId      The organization id of the "organization" of the membership
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getMembershipsByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.membershipsByOrganizationId,
            variables: {
                limit: 1000,
                organizationId: organizationId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.membershipsByOrganizationId?.items as Membership[];
        let nextToken = result.data?.membershipsByOrganizationId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.membershipsByOrganizationId,
                variables: {
                    limit: 100,
                    organizationId: organizationId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.membershipsByOrganizationId?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.membershipsByOrganizationId?.nextToken;
        }

        return formatResult(true, "Membership", items, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  organizationId      The organization id of the "organization" of the membership
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getMembershipsByOrganizationIdByActiveStatus(organizationId: string, status: ("active" | "expired"), authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const todayDate = moment(new Date()).format("YYYY-MM-DD");
        let filter = {};
        if (status === "expired") {
            // Only get records that have an expiration date in the past
            filter = {dateMembershipEnds: {le: todayDate}};
        } else {
            // Only get records that have an expiration date in the future
            filter = {dateMembershipEnds: {ge: todayDate}};
        }
        const result = (await API.graphql({
            query: queries.membershipsByOrganizationId,
            variables: {
                limit: 1000,
                filter,
                organizationId: organizationId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.membershipsByOrganizationId?.items as Membership[];
        let nextToken = result.data?.membershipsByOrganizationId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.membershipsByOrganizationId,
                variables: {
                    limit: 100,
                    filter,
                    organizationId: organizationId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.membershipsByOrganizationId?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.membershipsByOrganizationId?.nextToken;
        }

        return formatResult(true, "Membership", items, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  organizationId      The organization id of the "organization" of the membership
* @param {string}  riderId      The rider id of the "rider" of the membership
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getMembershipsByOrganizationIdByRiderIdByActiveStatus(organizationId: string, riderId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const todayDate = moment(new Date()).format("YYYY-MM-DD");
        let filter = {
            riderId: { eq: riderId },
            dateMembershipEnds: {ge: todayDate}
        };
        const result = (await API.graphql({
            query: queries.membershipsByOrganizationId,
            variables: {
                limit: 1000,
                filter,
                organizationId: organizationId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.membershipsByOrganizationId?.items as Membership[];
        let nextToken = result.data?.membershipsByOrganizationId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.membershipsByOrganizationId,
                variables: {
                    limit: 100,
                    filter,
                    organizationId: organizationId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.membershipsByOrganizationId?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.membershipsByOrganizationId?.nextToken;
        }

        return formatResult(true, "Membership", items, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  organizationId      The organization id of the "organization" of the membership
* @param {string}  membershipId        The membership id of the membership
* @param {string}  membershipId        The membershipType id of the membership type
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getMembershipsByOrganizationIdByMembershipIdByMembershipTypeByActiveStatus(organizationId: string, membershipId: string, membershipTypeId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const todayDate = moment(new Date()).format("YYYY-MM-DD");
        let filter = {
            membershipId: { eq: membershipId },
            organizationMembershipTypeId: {eq: membershipTypeId},
            dateMembershipEnds: {ge: todayDate}
        };
        const result = (await API.graphql({
            query: queries.membershipsByOrganizationId,
            variables: {
                limit: 1000,
                filter,
                organizationId: organizationId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.membershipsByOrganizationId?.items as Membership[];
        let nextToken = result.data?.membershipsByOrganizationId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.membershipsByOrganizationId,
                variables: {
                    limit: 100,
                    filter,
                    organizationId: organizationId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.membershipsByOrganizationId?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.membershipsByOrganizationId?.nextToken;
        }

        return formatResult(true, "Membership", items, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  personId                                The person id of the "owner" of the membership
* @param {string}  organizationMembershipTypeId            The organization membership type id of the membership type
* @param {string}  authMode                                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getMembershipsByPersonIdByMembershipTypeId(personId: string, organizationMembershipTypeId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {
            personId: { eq: personId },
            organizationMembershipTypeId: { eq: organizationMembershipTypeId }
        };
        const result = (await API.graphql({
            query: queries.listMemberships,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListMembershipsQuery>;

        let items = result.data?.listMemberships?.items as Membership[];
        let nextToken = result.data?.listMemberships?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listMemberships,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListMembershipsQuery>;

            const nextItems = nextResult.data?.listMemberships?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listMemberships?.nextToken;
        }

        return formatResult(true, "Membership", items, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  organizationMembershipTypeId            The organization membership type id of the membership type
* @param {string}  authMode                                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getMembershipsByMembershipTypeId(organizationMembershipTypeId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {
            organizationMembershipTypeId: { eq: organizationMembershipTypeId }
        };
        const result = (await API.graphql({
            query: queries.listMemberships,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListMembershipsQuery>;

        let items = result.data?.listMemberships?.items as Membership[];
        let nextToken = result.data?.listMemberships?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listMemberships,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListMembershipsQuery>;

            const nextItems = nextResult.data?.listMemberships?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listMemberships?.nextToken;
        }

        return formatResult(true, "Membership", items, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  personId                                The person id of the "owner" of the membership
* @param {string}  organizationId                          The organization id of the "organization" of the membership
* @param {string}  authMode                                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getMembershipsByPersonIdByOrganizationId(personId: string, organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {
            personId: { eq: personId },
            organizationId: { eq: organizationId }
        };
        const result = (await API.graphql({
            query: queries.listMemberships,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListMembershipsQuery>;

        let items = result.data?.listMemberships?.items as Membership[];
        let nextToken = result.data?.listMemberships?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listMemberships,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListMembershipsQuery>;

            const nextItems = nextResult.data?.listMemberships?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listMemberships?.nextToken;
        }

        //  check if the membership record has not passed the dateMembershipEnds
        const activeMemberships = items.filter((m) => {
            if (m.dateMembershipEnds?.length) {
                const today = moment();
                const membershipEndDate = moment(m.dateMembershipEnds, "YYYY-MM-DD");
                return membershipEndDate.diff(today, "days") >= 0;
            } else {
                return true;
            }
        });

        return formatResult(true, "Membership", activeMemberships, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  createdBy                                The person id of the "owner" of the membership
* @param {string}  organizationId                          The organization id of the "organization" of the membership
* @param {string}  authMode                                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getActiveMembershipsByCreatedByIdByOrganizationId(createdBy: string, organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {
            createdBy: { eq: createdBy },
            organizationId: { eq: organizationId }
        };
        const result = (await API.graphql({
            query: queries.listMemberships,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListMembershipsQuery>;

        let items = result.data?.listMemberships?.items as Membership[];
        let nextToken = result.data?.listMemberships?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listMemberships,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListMembershipsQuery>;

            const nextItems = nextResult.data?.listMemberships?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listMemberships?.nextToken;
        }

        //  check if the membership record has not passed the dateMembershipEnds
        const activeMemberships = items.filter((m) => {
            if (m.dateMembershipEnds?.length) {
                const today = moment();
                const membershipEndDate = moment(m.dateMembershipEnds, "YYYY-MM-DD");
                return membershipEndDate.diff(today, "days") >= 0;
            } else {
                return true;
            }
        });

        return formatResult(true, "Membership", activeMemberships, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}

/**
* Get all records in the database that match the given criteria for type: membership. 
* 
* @param {string}  createdBy                                The person id of the "owner" of the membership
* @param {string}  membershipTypeId                          The organization id of the "organization" of the membership
* @param {string}  authMode                                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the membership object
*/
export async function getActiveMembershipsByCreatedByIdByMembershipTypeId(createdBy: string, membershipTypeId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {
            createdBy: { eq: createdBy },
            organizationMembershipTypeId: { eq: membershipTypeId }
        };
        const result = (await API.graphql({
            query: queries.listMemberships,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListMembershipsQuery>;

        let items = result.data?.listMemberships?.items as Membership[];
        let nextToken = result.data?.listMemberships?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listMemberships,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListMembershipsQuery>;

            const nextItems = nextResult.data?.listMemberships?.items as Membership[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listMemberships?.nextToken;
        }

        //  check if the membership record has not passed the dateMembershipEnds
        const activeMemberships = items.filter((m) => {
            if (m.dateMembershipEnds?.length) {
                const today = moment();
                const membershipEndDate = moment(m.dateMembershipEnds, "YYYY-MM-DD");
                return membershipEndDate.diff(today, "days") >= 0;
            } else {
                return true;
            }
        });

        return formatResult(true, "Membership", activeMemberships, "Successfully got the Memberships.");
    } catch (error: any) {
        return formatResult(false, "Membership", error, "Error reading record in the database for type: memberships");
    }
}