import { Auditor, BarnMember, EventAuditor, EventEntry, Feedback, Horse, Membership, Owner, Person, PersonalInformation, PersonName, Rider, Submission, SubmissionFeedback, Trainer } from "../../models";
import { getAuditorsByPersonId } from "../auditor/Auditor";
import { getBarnMembersByPersonId } from "../barnMember/BarnMember";
import { getEventAuditorsByPersonId } from "../eventAuditor/EventAuditor";
import { getEventEntriesByPersonId, getEventEntriesByRiderId } from "../eventEntry/EventEntry";
import { getFeedbacksByPersonId } from "../feedback/Feedback";
import { getHorsesByPersonId } from "../horse/Horse";
import { getMembershipsByPersonId } from "../membership/Membership";
import { getOwnerByPersonId } from "../owner/Owner";
import { getRiderByPersonId } from "../rider/Rider";
import { getSubmissionsByPersonId } from "../submission/Submission";
import { getSubmissionFeedbacksByPersonId } from "../submissionFeedback/SubmissionFeedback";
import { getTrainerByPersonId } from "../trainer/Trainer";
import { getPersonNameByPersonId } from "./PersonName";

export interface FullPersonDetails {
    id: string
    person: Person
    personalInformation?: PersonalInformation
    personName?: PersonName
    rider?: Rider
    owner?: Owner
    trainer?: Trainer
    barnMembers?: BarnMember[]
    memberships?: Membership[]
    horses?: Horse[]
    entriesCreatedByPerson?: EventEntry[]
    entriesWithRider?: EventEntry[]
    auditors?: Auditor[]
    eventAuditors?: EventAuditor[]
    clinicSubmissions?: Submission[]
    clinicSubmissionFeedback?: SubmissionFeedback[]
    clinicFeedback?: Feedback[]
}

export const getFullPersonDetails = async (person: Person) => {
    // personName
    let personName: PersonName | undefined;
    const personNameResult = await getPersonNameByPersonId(person.id);
    if (personNameResult.isSuccess) {
        personName = personNameResult.result;
    }

    // rider
    let rider: Rider | undefined;
    const riderResult = await getRiderByPersonId(person.id);
    if (riderResult.isSuccess) {
        rider = riderResult.result;
    }

    // owner
    let owner: Owner | undefined;
    const ownerResult = await getOwnerByPersonId(person.id);
    if (ownerResult.isSuccess) {
        owner = ownerResult.result;
    }

    // trainer
    let trainer: Trainer | undefined;
    const trainerResult = await getTrainerByPersonId(person.id);
    if (trainerResult.isSuccess) {
        trainer = trainerResult.result;
    }

    // barnMemberships
    let barnMembers: BarnMember[] | undefined;
    const barnMembershipsResult = await getBarnMembersByPersonId(person.id);
    if (barnMembershipsResult.isSuccess) {
        barnMembers = barnMembershipsResult.result;
    }

    // memberships
    let memberships: Membership[] | undefined;
    const membershipsResult = await getMembershipsByPersonId(person.id);
    if (membershipsResult.isSuccess) {
        memberships = membershipsResult.result;
    }

    // horses
    let horses: Horse[] | undefined;
    const horsesResult = await getHorsesByPersonId(person.id);
    if (horsesResult.isSuccess) {
        horses = horsesResult.result;
    }

    // entriesCreatedByPerson
    let entriesCreatedByPerson: EventEntry[] | undefined;
    const entriesResult = await getEventEntriesByPersonId(person.id);
    if (entriesResult.isSuccess) {
        entriesCreatedByPerson = entriesResult.result;
    }

    // entriesWithRider
    let entriesWithRider: EventEntry[] | undefined;
    if (rider) {
        const entriesResult = await getEventEntriesByRiderId(rider.id);
        if (entriesResult.isSuccess) {
            entriesWithRider = entriesResult.result;
        }
    }

    // auditors
    let auditors: Auditor[] | undefined;
    const auditorsResult = await getAuditorsByPersonId(person.id);
    if (auditorsResult.isSuccess) {
        auditors = auditorsResult.result;
    }

    // event auditors
    let eventAuditors: EventAuditor[] | undefined;
    const eventAuditorsResult = await getEventAuditorsByPersonId(person.id);
    if (eventAuditorsResult.isSuccess) {
        eventAuditors = eventAuditorsResult.result;
    }

    // submissions
    let clinicSubmissions: Submission[] | undefined;
    const submissionsResult = await getSubmissionsByPersonId(person.id);
    if (submissionsResult.isSuccess) {
        clinicSubmissions = submissionsResult.result;
    }

    // submission feedback
    let clinicSubmissionFeedback: SubmissionFeedback[] | undefined;
    const submissionFeedbackResult = await getSubmissionFeedbacksByPersonId(person.id);
    if (submissionFeedbackResult.isSuccess) {
        clinicSubmissionFeedback = submissionFeedbackResult.result;
    }

    // feedback
    let clinicFeedback: Feedback[] | undefined;
    const feedbackResult = await getFeedbacksByPersonId(person.id);
    if (feedbackResult.isSuccess) {
        clinicFeedback = feedbackResult.result;
    }

    const result: FullPersonDetails = {
        id: person.id,
        person: person,
        personalInformation: person.personalInformation || undefined,
        personName,
        rider,
        owner,
        trainer,
        barnMembers,
        memberships,
        horses,
        entriesCreatedByPerson,
        entriesWithRider,
        auditors,
        eventAuditors,
        clinicSubmissions,
        clinicSubmissionFeedback,
        clinicFeedback
    };

    return result;
}