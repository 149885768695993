import * as queries from "../../graphql/queries";
import { API } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreatePersonalInformationInput, ListPersonalInformationsQuery, UpdatePersonalInformationInput } from "../../API";
import { createPersonalInformation, getPersonalInformationByPersonId, updatePersonalInformation } from "./PersonalInformation";
import { PersonalInformation } from "../../models";
import moment from "moment";

Amplify.configure(awsmobile);

/**
* Get all records in the database that match the given criteria for type: personalInformation. 
* 
* @param {string}  personId            The person id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the personalInformation object
*/
export async function getBirthdayByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listPersonalInformations,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPersonalInformationsQuery>;

        let items = result.data?.listPersonalInformations?.items as PersonalInformation[];
        let nextToken = result.data?.listPersonalInformations?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listPersonalInformations,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListPersonalInformationsQuery>;

            const nextItems = nextResult.data?.listPersonalInformations?.items as PersonalInformation[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPersonalInformations?.nextToken;
        }

        if (items && items[0]) return formatResult(true, "PersonalInformation", items[0].dateOfBirth, "Successfully got the birthday.");
        else return formatResult(false, "PersonalInformation", null, "Could not find the personalInformation.");
    } catch (error: any) {
        return formatResult(false, "PersonalInformation", error, "Error reading record in the database for type: personalInformation");
    }
}

/**
* Handle updating a new record in the database for type: personalInformation. 
* 
* @param {object}  personId                Person Id
* @param {object}  birthday                Birthday formatted like YYYY-MM-DD
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated personalInformation.
*/
export async function updateBirthday(personId: string, birthday: string, authMode?: GRAPHQL_AUTH_MODE) {
    if (!personId) return formatResult(false, "PersonalInformation", "No input", "Update PersonalInformation received no personId input.");
    if (!birthday) return formatResult(false, "PersonalInformation", "No input", "Update PersonalInformation received no birthday input.");
    try {
        let result;
        const birthdayResult = await getPersonalInformationByPersonId(personId);
        if (birthdayResult.isSuccess) {
            // Update the given personal information object
            const input: UpdatePersonalInformationInput = {
                id: birthdayResult.result.id,
                dateOfBirth: moment(birthday).format("YYYY-MM-DD") //AWSDate is YYYY-MM-DD
            };
            result = await updatePersonalInformation(input, authMode);
            return result;
        } else {
            // Need to create a personal information object
            const input: CreatePersonalInformationInput = {
                personId: personId,
                dateOfBirth: moment(birthday).format("YYYY-MM-DD")
            };
            result = await createPersonalInformation(input, authMode);
            return result;
        }
    } catch (error: any) {
        return formatResult(false, "PersonalInformation", error, "Error updating record in the database for type: personalInformation");
    }
}