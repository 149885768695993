import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { EventEntry } from "../../../models";
import { chevronDown, chevronUp } from "ionicons/icons";

interface _Props {
    entry: EventEntry
    isExpanded?: boolean
}

const DisplayEntryNote: React.FC<_Props> = ({entry, isExpanded}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    // useEffect(() => {
    //     if (entry) setIsCollapsed(true);
    // }, [entry]);

    useEffect(() => {
        if (isExpanded) setIsCollapsed(false);
        else setIsCollapsed(true);
    }, [isExpanded]);

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h3>Entry Note</h3>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? <IonIcon icon={chevronDown} />  : <IonIcon icon={chevronUp} />}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="6">
                                {entry.note ? 
                                    <p>{entry.note}</p>
                                    :
                                    <p>No note included.</p>
                                }
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default DisplayEntryNote;