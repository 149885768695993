import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React from "react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import SearchBarns from "../../../../components/Barn/SearchBarns";

const AddBarnPage: React.FC = () => {
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Barn" />
                <IonRow>
                    <IonCol sizeXs="12" offsetMd="8" sizeMd="4" className="ion-text-right">
                        <IonButton
                            color="primary"
                            routerLink="/index/staff/barn/create"    
                        >
                            Create Barn
                        </IonButton>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Join a Barn</IonCardTitle>
                            <IonCardContent>
                                <p className="ion-text-center">Search for barns and ask to join a barn. This will allow you to share horse and people information with barn staff.</p>
                                <SearchBarns />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default AddBarnPage;