import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateOrganizationStallSetInput, CreateOrganizationStallSetMutation, DeleteOrganizationStallSetInput, DeleteOrganizationStallSetMutation, GetOrganizationStallSetQuery, ListOrganizationStallSetsQuery, UpdateOrganizationStallSetInput, UpdateOrganizationStallSetMutation } from "../../API";
import moment from "moment";
import { OrganizationStallSet } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: organizationStallSet. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created organizationStallSet.
*/
export async function createOrganizationStallSet(input: CreateOrganizationStallSetInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationStallSet", "No input", "Create OrganizationStallSet received no input.");
   try {
        const fullInput: CreateOrganizationStallSetInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createOrganizationStallSet, { input: fullInput }))) as GraphQLResult<CreateOrganizationStallSetMutation>;
       else result = (await API.graphql({
           query: mutations.createOrganizationStallSet,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateOrganizationStallSetMutation>;
       const organizationStallSet = result.data?.createOrganizationStallSet;
       return formatResult(true, "OrganizationStallSet", organizationStallSet, "Successfully created the organizationStallSet.");
   } catch (error: any) {
       return formatResult(false, "OrganizationStallSet", error, "Error creating record in the database for type: organizationStallSet");
   }
}

/**
* Handle updating a new record in the database for type: organizationStallSet. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated organizationStallSet.
*/
export async function updateOrganizationStallSet(input: UpdateOrganizationStallSetInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationStallSet", "No input", "Update OrganizationStallSet received no input.");
   try {
        const fullInput: UpdateOrganizationStallSetInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateOrganizationStallSet, { input: fullInput }))) as GraphQLResult<UpdateOrganizationStallSetMutation>;
       else result = (await API.graphql({
           query: mutations.updateOrganizationStallSet,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateOrganizationStallSetMutation>;
       const organizationStallSet = result.data?.updateOrganizationStallSet;
       return formatResult(true, "OrganizationStallSet", organizationStallSet, "Successfully updated the organizationStallSet.");
   } catch (error: any) {
       return formatResult(false, "OrganizationStallSet", error, "Error updating record in the database for type: organizationStallSet");
   }
}

/**
* Handle deleting a new record in the database for type: organizationStallSet. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted organizationStallSet.
*/
export async function deleteOrganizationStallSet(input: DeleteOrganizationStallSetInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationStallSet", "No input", "Delete OrganizationStallSet received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteOrganizationStallSet, { input: input }))) as GraphQLResult<DeleteOrganizationStallSetMutation>;
       else result = (await API.graphql({
           query: mutations.deleteOrganizationStallSet,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteOrganizationStallSetMutation>;
       const organizationStallSet = result.data?.deleteOrganizationStallSet;
       return formatResult(true, "OrganizationStallSet", organizationStallSet, "Successfully deleted the organizationStallSet.");
   } catch (error: any) {
       return formatResult(false, "OrganizationStallSet", error, "Error deleting record in the database for type: organizationStallSet");
   }
}

/**
* Get all records in the database for type: organizationStallSet. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationStallSet object
*/
export async function getAllOrganizationStallSets(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listOrganizationStallSets,
            variables: {
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationStallSetsQuery>;
        
        let items = result.data?.listOrganizationStallSets?.items as OrganizationStallSet[];
        let nextToken = result.data?.listOrganizationStallSets?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizationStallSets,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationStallSetsQuery>;

            const nextItems = nextResult.data?.listOrganizationStallSets?.items as OrganizationStallSet[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationStallSets?.nextToken;
        }

        return formatResult(true, "OrganizationStallSet", items, "Successfully got the OrganizationStallSets.");
    } catch (error: any) {
        return formatResult(false, "OrganizationStallSet", error, "Error reading record in the database for type: organizationStallSets");
    }
}

/**
* Read a specific record in the database for type: organizationStallSet. 
* 
* @param {string}  id                  The organizationStallSet id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationStallSet object
*/
export async function getOrganizationStallSetById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getOrganizationStallSet,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetOrganizationStallSetQuery>;
       const organizationStallSet = result.data?.getOrganizationStallSet;
       return formatResult(true, "OrganizationStallSet", organizationStallSet, "Successfully got the organizationStallSet.");
   } catch (error: any) {
       return formatResult(false, "OrganizationStallSet", error, "Error reading record in the database for type: organizationStallSet");
   }
}

/**
* Get all records in the database that match the given criteria for type: organizationStallSet. 
* 
* @param {string}  organizationId      The organization id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationStallSet object
*/
export async function getOrganizationStallSetsByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationId: {eq: organizationId}};
        const result = (await API.graphql({
            query: queries.listOrganizationStallSets,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOrganizationStallSetsQuery>;

        let items = result.data?.listOrganizationStallSets?.items as OrganizationStallSet[];
        let nextToken = result.data?.listOrganizationStallSets?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizationStallSets,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOrganizationStallSetsQuery>;

            const nextItems = nextResult.data?.listOrganizationStallSets?.items as OrganizationStallSet[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationStallSets?.nextToken;
        }

        return formatResult(true, "OrganizationStallSet", items, "Successfully got the OrganizationStallSets.");
    } catch (error: any) {
        return formatResult(false, "OrganizationStallSet", error, "Error reading record in the database for type: organizationStallSets");
    }
}