import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import SuccessBanner from "../Banners/SuccessBanner";
import { BarnPerson } from "../../interfaces/Person";
import { updatePersonRoles } from "../../utilities/barn/BarnPerson";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";

interface _Props {
    person: BarnPerson
    onSubmit: Function
}

const EditPersonRolesForm: React.FC<_Props> = ({person, onSubmit}) => {    
    const user = useContext(PersonContext);

    const [roles, setRoles] = useState("");
    const [isRider, setIsRider] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [isTrainer, setIsTrainer] = useState(false);
    // const [isParent, setIsParent] = useState(false);
    // const [isOther, setIsOther] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const clearForm = () => {
        setIsRider(false);
        setIsOwner(false);
        setIsTrainer(false);
        // setIsParent(false);
        // setIsOther(false);
    }

    useEffect(() => {
        if (person) {
            setIsRider(!!person.riderId);
            setIsOwner(!!person.owner);
            setIsTrainer(!!person.trainer);
            // setIsParent(person.roles.includes("parent"));
            // setIsOther(person.roles.includes("other"));
        } else {
            clearForm();
        }
    }, [person]);

    const handleEditPerson = async () => {
        setIsLoading(true);
        const roleResult = await updatePersonRoles(person, isRider, isOwner, isTrainer);
        if (roleResult.isSuccess) {
            setSuccess("Successfully updated the roles.");
        } else {
            setError(roleResult.message);
        }
        setIsLoading(false);
        onSubmit();
    }

    return (
        <>
            {success && <SuccessBanner success={success} />}
            {error && <ErrorAlert error={error} />}
            <IonRow>
                <IonCol size="12">
                    <IonItem>
                        <IonLabel>Rider</IonLabel>
                        <IonCheckbox slot="start" value="rider" checked={isRider} onIonChange={e => setIsRider(e.detail.checked)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    <IonItem>
                        <IonLabel>Owner</IonLabel>
                        <IonCheckbox slot="start" value="owner" checked={isOwner} onIonChange={e => setIsOwner(e.detail.checked)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    <IonItem>
                        <IonLabel>Trainer</IonLabel>
                        <IonCheckbox slot="start" value="trainer" checked={isTrainer} onIonChange={e => setIsTrainer(e.detail.checked)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            {/* <IonRow>
                <IonCol size="12">
                    <IonItem>
                        <IonLabel>Parent</IonLabel>
                        <IonCheckbox slot="start" value="parent" checked={isParent} onIonChange={e => setIsParent(e.detail.checked)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    <IonItem>
                        <IonLabel>Other</IonLabel>
                        <IonCheckbox slot="start" value="other" checked={isOther} onIonChange={e => setIsOther(e.detail.checked)} />
                    </IonItem>
                </IonCol>
            </IonRow> */}
            {isLoading ? 
                <Spinner />
                :
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeMd="4" className="ion-text-center">
                        <IonButton
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleEditPerson}
                        >
                            Save
                        </IonButton>
                    </IonCol>
                </IonRow>
            }
        </>
    );
};

export default EditPersonRolesForm;