import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorBanner from "../Banners/ErrorBanner";
import FileBrowser from "../FileBrowser/FileBrowser";
import { PersonContext } from "../../context/PersonContext";
import { createPersonVideoFileName, handleUploadVideoFile } from "../../utilities/s3Object/s3Object";
import Spinner from "../Spinners/Spinner";
import { createS3Object } from "../../utilities/s3Object/s3Object";
import { UpdatePersonInput } from "../../API";
import { updatePerson } from "../../utilities/person/Person";
import { isVideo } from "../../utilities/files/File";
import { Person } from "../../models";

interface _Props {
    person?: Person
    onSubmit: Function
}

const VideoForm: React.FC<_Props> = ({person, onSubmit}) => {
    const user = useContext(PersonContext);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [video, setVideo] = useState<any>();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setTitle("");
        setDescription("");
        setVideo(undefined);
        setError("");
    }, []);

    const handleSelectedFile = (file: File) => {
        setIsLoading(true);
        try {
            setTitle(file.name);
            if (isVideo(file)) {
                setVideo(file);
                setError("");
            }
            else setError("Please check that your video is one of: .mp4, .mov, .qt, .mpv");
        } catch (error: any) {
            setError(error);
        }
        setIsLoading(false);
    }

    const handleSaveFile = async () => {
        setIsLoading(true);

        // Track all videos associated with this person
        let videoList: any[] = [];

        // Add any videos already asscoiated with this person
        const currentVideos: any[] = person ? (person.media ? person.media : []) : (user.media ? user.media : []);
        if (currentVideos && currentVideos.length > 0) {
            videoList = videoList.concat(currentVideos);
        } 

        // Upload this to S3 
        const fileName = createPersonVideoFileName(title, person ? person : user);
        const uploadResult = await handleUploadVideoFile(video, fileName, title, description);
        if (uploadResult.isSuccess) {
            // If successfully added to S3, save to video list to the person
            const key = uploadResult.result;
            const s3Object = createS3Object(key, title, video, description);
            videoList.push(s3Object);
            if (user) {
                const personInput: UpdatePersonInput = {
                    id: person?.id || user.id,
                    media: videoList
                };
                const updateResult = await updatePerson(personInput);
                if (updateResult.isSuccess) {
                    const updatedPerson = updateResult.result;
                    const updatedVideos = updatedPerson.media;
                    setVideo(undefined);
                    setTitle("");
                    setDescription("");
                    onSubmit(updatedVideos, s3Object);
                } else {
                    setError("Could not save the video to your profile.");
                }
            } else {
                setError("Could not save the video to your profile.");
            }
        } else {
            setError("Could not upload the video. Contact hello@ringsidepro.com for help.")
        }
        setIsLoading(false);
    }

    return (
        <>
            {isLoading ?
                <IonRow>
                    <IonCol className="ion-text-center">
                        <Spinner />
                        <p>Uploading your video...</p>
                    </IonCol>
                </IonRow>
                :
                <form>
                    {error && (
                        <IonRow>
                            <IonCol>
                                <ErrorBanner error={error} />
                            </IonCol>
                        </IonRow>
                    )}
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12" className="text-center">
                            <FileBrowser handleSelectedFile={handleSelectedFile}/>
                        </IonCol>
                    </IonRow>
                    {video && 
                        <>
                            <IonRow className="ion-margin">
                                <IonCol sizeMd="12">
                                    <IonItem>
                                        <IonLabel position="floating">Title</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={title}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setTitle(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-margin">
                                <IonCol sizeMd="12">
                                    <IonItem>
                                        <IonLabel position="floating">Description</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={description}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setDescription(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-text-center">
                                    <IonButton color="tertiary" onClick={() => handleSaveFile()}>Save</IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                    }
                </form>
            }
        </>
    );
};

export default VideoForm;