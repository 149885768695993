import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventFeeInput, CreateEventFeeMutation, DeleteEventFeeInput, DeleteEventFeeMutation, GetEventFeeQuery, ListEventFeesQuery, UpdateEventFeeInput, UpdateEventFeeMutation } from "../../API";
import moment from "moment";
import { EventFee } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventFee. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventFee.
*/
export async function createEventFee(input: CreateEventFeeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventFee", "No input", "Create EventFee received no input.");
   try {
        const fullInput: CreateEventFeeInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventFee, { input: fullInput }))) as GraphQLResult<CreateEventFeeMutation>;
       else result = (await API.graphql({
           query: mutations.createEventFee,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventFeeMutation>;
       const eventFee = result.data?.createEventFee;
       return formatResult(true, "EventFee", eventFee, "Successfully created the eventFee.");
   } catch (error: any) {
       return formatResult(false, "EventFee", error, "Error creating record in the database for type: eventFee");
   }
}

/**
* Handle updating a new record in the database for type: eventFee. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventFee.
*/
export async function updateEventFee(input: UpdateEventFeeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventFee", "No input", "Update EventFee received no input.");
   try {
        const fullInput: UpdateEventFeeInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventFee, { input: fullInput }))) as GraphQLResult<UpdateEventFeeMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventFee,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventFeeMutation>;
       const eventFee = result.data?.updateEventFee;
       return formatResult(true, "EventFee", eventFee, "Successfully updated the eventFee.");
   } catch (error: any) {
       return formatResult(false, "EventFee", error, "Error updating record in the database for type: eventFee");
   }
}

/**
* Handle deleting a new record in the database for type: eventFee. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventFee.
*/
export async function deleteEventFee(input: DeleteEventFeeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventFee", "No input", "Delete EventFee received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventFee, { input: input }))) as GraphQLResult<DeleteEventFeeMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventFee,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventFeeMutation>;
       const eventFee = result.data?.deleteEventFee;
       return formatResult(true, "EventFee", eventFee, "Successfully deleted the eventFee.");
   } catch (error: any) {
       return formatResult(false, "EventFee", error, "Error deleting record in the database for type: eventFee");
   }
}

/**
* Get all records in the database for type: eventFee. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventFee object
*/
export async function getAllEventFees(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventFees,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventFeesQuery>;

        let items = result.data?.listEventFees?.items as EventFee[];
        let nextToken = result.data?.listEventFees?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventFees,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventFeesQuery>;

            const nextItems = nextResult.data?.listEventFees?.items as EventFee[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventFees?.nextToken;
        }

        return formatResult(true, "EventFee", items, "Successfully got the EventFees.");
    } catch (error: any) {
        return formatResult(false, "EventFee", error, "Error reading record in the database for type: eventFees");
    }
}

/**
* Read a specific record in the database for type: eventFee. 
* 
* @param {string}  id                  The eventFee id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventFee object
*/
export async function getEventFeeById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventFee,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventFeeQuery>;
       const eventFee = result.data?.getEventFee;
       return formatResult(true, "EventFee", eventFee, "Successfully got the eventFee.");
   } catch (error: any) {
       return formatResult(false, "EventFee", error, "Error reading record in the database for type: eventFee");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventFee. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventFee object
*/
export async function getEventFeesByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventFees,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventFeesQuery>;

        let items = result.data?.listEventFees?.items as EventFee[];
        let nextToken = result.data?.listEventFees?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventFees,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventFeesQuery>;

            const nextItems = nextResult.data?.listEventFees?.items as EventFee[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventFees?.nextToken;
        }

        return formatResult(true, "EventFee", items, "Successfully got the EventFees.");
    } catch (error: any) {
        return formatResult(false, "EventFee", error, "Error reading record in the database for type: eventFees");
    }
}