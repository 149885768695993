import React, { useEffect, useState } from "react";
import { IonItem, IonLabel, IonRow, IonCol, IonInput, IonText, IonButton } from "@ionic/react";
import BasicTooltip from "../../Tooltip/BasicTooltip";
import SelectCreatePrizeMoneyTable from "../../PrizeMoneyTable/SelectCreatePrizeMoneyTable";
import { PrizeMoneyTable } from "../../../models";

interface _Props {
    organizationId: string
    selectedPrizeMoney?: (number | null)
    selectedPrizeMoneyTable?: (PrizeMoneyTable | null)
    onSelectPrizeMoney: Function
    onSelectPrizeMoneyTable: Function
}

const ClassPrizeMoney: React.FC<_Props> = ({organizationId, selectedPrizeMoney, selectedPrizeMoneyTable, onSelectPrizeMoney, onSelectPrizeMoneyTable}) => {
    const [isHidden, setIsHidden] = useState(true);
    const [prizeMoney, setPrizeMoney] = useState(0);
    const [prizeMoneyTable, setPrizeMoneyTable] = useState<PrizeMoneyTable | null | undefined>();

    useEffect(() => {
        if (selectedPrizeMoney) setPrizeMoney(selectedPrizeMoney);
        else setPrizeMoney(0);
    }, [selectedPrizeMoney]);

    useEffect(() => {
        if (selectedPrizeMoneyTable) setPrizeMoneyTable(selectedPrizeMoneyTable);
        else setPrizeMoneyTable(undefined);
    }, [selectedPrizeMoneyTable]);

    const handleInput = (input: number) => {
        setPrizeMoney(input);
        onSelectPrizeMoney("prizeMoney", input);
    }

    const handlePrizeMoneyTable = (prizeMoneyTable: PrizeMoneyTable) => {
        setPrizeMoneyTable(prizeMoneyTable);
        onSelectPrizeMoneyTable("prizeMoneyTable", prizeMoneyTable);
    }
    
    return (
        <>
            {isHidden ?
                <IonText>
                    Prize Money: ${prizeMoney.toFixed(2)} per class <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(false)}>Edit</IonButton>
                    <br/>
                    Prize Money Table: {prizeMoneyTable?.name} <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(false)}>Edit</IonButton>
                </IonText>
                :
                <>
                    <IonRow>
                        <IonCol className="text-center">
                            <BasicTooltip 
                                label="Class Prize Money"
                                tip="Set the prize money you will give out for this class and how it should be distributed."
                            />
                            <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(true)}>Hide</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Class Prize Money</IonLabel>
                                <IonInput 
                                    type="number"
                                    value={prizeMoney}
                                    disabled={(!!prizeMoneyTable && !prizeMoneyTable.isPercentage)}
                                    placeholder="$0.00"
                                    inputmode="decimal"
                                    onIonChange={e => {
                                        handleInput(parseFloat(e.detail.value!))
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <BasicTooltip 
                                label="Class Prize Money Table"
                                tip='If you have not created a prize money table before, type in "My Table" and then select "Create". Leave blank if this class has no prize money.'
                            />
                            {organizationId ?
                                <SelectCreatePrizeMoneyTable organizationId={organizationId} prizeMoneyTable={prizeMoneyTable} onSelect={handlePrizeMoneyTable}/>
                                :
                                <p>Error</p>
                            }
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default ClassPrizeMoney;
