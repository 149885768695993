import {
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonSearchbar
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Barn} from "../../models";
import { getAllBarns } from "../../utilities/barn/Barn";
import Spinner from "../Spinners/Spinner";
import { formatAddress } from "../../utilities/address/FormatAddress";
import { useHistory } from "react-router";

const SearchBarns: React.FC = () => {
    const history = useHistory();

    const [barns, setBarns] = useState<Barn[]>([]);
    const [filteredBarns, setFilteredBarns] = useState<Barn[]>([]);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function getBarns() {
            setIsLoading(true);
            const queryResult = await getAllBarns();
            if (queryResult.isSuccess) {
                const barnList = queryResult.result;
                setBarns(barnList);
                setFilteredBarns(barnList);
            }
            setIsLoading(false);
        }

        getBarns();
    }, []);

    const filterBarns = (value: string) => {
        const result = barns.filter(barn => barn.name.toLocaleLowerCase().toString().includes(value.toLocaleLowerCase().toString()));
        setFilteredBarns(result);
    }

    const handleSearchInput = async (value: string) => {
        setSearchText(value);
        if (value) {
            filterBarns(value);
        } else {
            setFilteredBarns([]);
        }
    }

    const handleBarnSelect = async (id: string) => {
        const path = "/index/barn/info/" + id;
        history.push(path);
    }

    return (
        <>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeSm="12" sizeMd="10">
                    <IonSearchbar value={searchText} onIonChange={e => handleSearchInput(e.detail.value!)} color="white"></IonSearchbar>
                </IonCol>
            </IonRow>
            {isLoading ? 
                <Spinner />
                :
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        {(filteredBarns && filteredBarns.length > 0) ? 
                            <IonList color="white" className="bg-white">
                                {filteredBarns.map((barn, i) => (
                                    <IonItem key={i} detail={true} onClick={() => handleBarnSelect(barn.id)}>
                                        <IonLabel>
                                            <IonRow>
                                                <IonCol>
                                                    <h2>{barn.name}</h2>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p>{barn.ownerName || ""}</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p>{barn.location ? formatAddress(barn.location) : ""}</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                    </IonItem>
                                ))}
                            </IonList>
                            :
                            <div className="ion-text-center">
                                {searchText ?
                                    <p>No results found.</p>
                                    :
                                    <p>Start typing the name of the barn to search.</p>
                                }
                            </div>
                        }
                    </IonCol>
                </IonRow>
            }
        </>
    );
};

export default SearchBarns;