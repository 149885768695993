import {
    IonButton,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorBanner from "../../Banners/ErrorBanner";
import FileBrowser from "../../FileBrowser/FileBrowser";
import { getTwilioCompliantFile, isPDF, isTwilioCompliantImage } from "../../../utilities/files/File";
import Spinner from "../../Spinners/Spinner";

interface _Props {
    selectedImage?: (File | null)
    onSelect: Function
}

const AlertImageForm: React.FC<_Props> = ({selectedImage, onSelect}) => {

    const [picture, setPicture] = useState<any>();
    const [pictureURL, setPictureURL] = useState<string | null | undefined>();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!selectedImage) {
            setPictureURL(undefined);
            setPicture(undefined);
        }
    }, [selectedImage]);

    const handleSelectedFile = (file: File) => {
        setIsLoading(true);
        try {
            // Allow PDFs to be included as well as images
            if (isTwilioCompliantImage(file) || isPDF(file)) {
                setPicture(file);
                if (isTwilioCompliantImage(file)) {
                    const updatedImageFile = getTwilioCompliantFile(file);
                    setPictureURL(URL.createObjectURL(updatedImageFile));
                    onSelect(updatedImageFile);
                } else {
                    onSelect(file);
                }
                setError("");
            }
            else setError("Please check that your image is one of: .jpg, .jpeg, .png, .gif, .pdf.")
        } catch (error: any) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const handlRemoveFile = () => {
        try {
            setPicture(undefined);
            setPictureURL(undefined);
            onSelect(undefined);
        } catch (error: any) {
            console.error(error);
        }
        setIsLoading(false);
    }

    return (
        <>
            {isLoading ?
                <IonRow>
                    <IonCol className="ion-text-center">
                        <Spinner />
                    </IonCol>
                </IonRow>
                :
                <>
                    {error && (
                        <IonRow>
                            <IonCol>
                                <ErrorBanner error={error} />
                            </IonCol>
                        </IonRow>
                    )}
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12" className="text-center">
                            <p className="description text-info ion-text-wrap">Adding a file will increase credits used.</p>
                            {picture ? 
                                <>
                                    {pictureURL ?
                                        <IonRow>
                                            <IonCol>
                                                <img src={pictureURL} />
                                            </IonCol>
                                        </IonRow>
                                        :
                                        <IonRow>
                                            <IonCol>
                                                <p>You are including the file: </p>
                                                <p>{picture.name}</p>
                                            </IonCol>
                                        </IonRow>
                                    }
                                    <IonRow>
                                        <IonCol size="12" className="text-center">
                                            <IonButton color="danger" onClick={handlRemoveFile}>
                                                Remove File
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </>
                                :
                                <IonRow>
                                    <IonCol>
                                        <FileBrowser handleSelectedFile={handleSelectedFile}/>
                                    </IonCol>
                                </IonRow>
                                }
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default AlertImageForm;