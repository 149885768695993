import { Result } from "../../interfaces/Result";
import { Event } from "../../models";
import { deleteAddress } from "../address/Address";
import { deleteEventDay, getEventDaysByEventId } from "../eventDay/EventDay";
import { deleteEventFilter, getEventFiltersByEventId } from "../eventFilter/EventFilter";
import { deleteEvent } from "./Event";

export async function deleteEntireEvent(event: Event) {
    let result: Result = {isSuccess: true, type: "Event", result: null, message: "Successfully deleted the event."};

    await removeEventFilters(event.id);
    await removeEventDays(event.id);
    await removeEventAddress(event);

    await deleteEvent({id: event.id});
    
    return result;
}

const removeEventFilters = async (eventId: string) => {
    const queryResult = await getEventFiltersByEventId(eventId);
    if (queryResult.isSuccess) {
        const eventFilters = queryResult.result;
        if (eventFilters && eventFilters.length) {
            for (let i = 0; i < eventFilters.length; i++) {
                const currentItem = eventFilters[i];
                await deleteEventFilter({id: currentItem.id});
            }
        }
    }
}

const removeEventDays = async (eventId: string) => {
    const queryResult = await getEventDaysByEventId(eventId);
    if (queryResult.isSuccess) {
        const eventDays = queryResult.result;
        if (eventDays && eventDays.length) {
            for (let i = 0; i < eventDays.length; i++) {
                const currentItem = eventDays[i];
                await deleteEventDay({id: currentItem.id});
            }
        }
    }
}

const removeEventAddress = async (event: Event) => {
    if (event.address) {
        const addressId = event.address.id;
        await deleteAddress({id: addressId});
    }
}