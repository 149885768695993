import React, { useEffect, useState } from "react";
import { IonItem, IonLabel, IonRow, IonCol, IonInput, IonText, IonButton } from "@ionic/react";
import BasicTooltip from "../../Tooltip/BasicTooltip";

interface _Props {
    selectedMin?: number
    selectedSec?: number
    onSelect: Function
}

const EstimatedTimePerRound: React.FC<_Props> = ({selectedMin, selectedSec, onSelect}) => {
    const [isHidden, setIsHidden] = useState(true);
    const [estimatedTimePerRoundMin, setEstimatedTimePerRoundMin] = useState(2);
    const [estimatedTimePerRoundSec, setEstimatedTimePerRoundSec] = useState(0);

    useEffect(() => {
        if (selectedMin) {
            setEstimatedTimePerRoundMin(selectedMin);
        }
        else setEstimatedTimePerRoundMin(0);
    }, [selectedMin]);

    useEffect(() => {
        if (selectedSec) setEstimatedTimePerRoundSec(selectedSec);
        else setEstimatedTimePerRoundSec(0);
    }, [selectedSec]);

    const handleMin = (input: number) => {
        setEstimatedTimePerRoundMin(input);
        onSelect("min", input);
    }

    const handleSec = (input: number) => {
        setEstimatedTimePerRoundSec(input);
        onSelect("max", input);
    }
    
    return (
        <>
            {isHidden ?
                <IonText>
                    Time estimate per round: {estimatedTimePerRoundMin} min. and {estimatedTimePerRoundSec} sec. <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(false)}>Edit</IonButton>
                </IonText>
                :
                <>
                    <IonRow>
                        <IonCol className="text-center">
                            <BasicTooltip 
                                label="Estimated time per round"
                                tip="Adding estimated times will help create the schedule. You can leave blank if you do not want to display time estimates to exhibitors."
                            />
                            <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(true)}>Hide</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Minutes</IonLabel>
                                <IonInput 
                                    type="number"
                                    value={estimatedTimePerRoundMin}
                                    placeholder="2"
                                    onIonChange={e => {
                                        handleMin(parseInt(e.detail.value!))
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Seconds</IonLabel>
                                <IonInput 
                                    type="number"
                                    value={estimatedTimePerRoundSec}
                                    placeholder="0"
                                    inputmode="decimal"
                                    onIonChange={e => {
                                        handleSec(parseInt(e.detail.value!))
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default EstimatedTimePerRound;
