import { Address, Organization } from "../../../../../models";
import {
    IonCard,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useState, useEffect } from "react";
import { getOrganizationById, updateOrganization } from "../../../../../utilities/organization/Organization";
import AddAddressCard from "../../../../../components/Address/AddAddressCard";
import AddressLabel from "../../../../../components/Address/AddressLabel";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { PersonContext } from "../../../../../context/PersonContext";
import { getAddressById } from "../../../../../utilities/address/Address";
import { UpdateOrganizationInput } from "../../../../../API";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

const OrganizationAddressesPage: React.FC<OrganizationPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [organization, setOrganization] = useState<Organization | null>(null);
    const [mailingAddress, setMailingAddress] = useState<Address | null>(null);
    const [billingAddress, setBillingAddress] = useState<Address | null>(null);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOrganization() {
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                const currentOrganization = queryResult.result;
                setOrganization(queryResult.result);
                
                const addressList = currentOrganization.addresses;

                if (addressList) {
                    for (let i = 0; i < addressList.length; i++) {
                        const addressId = addressList[i];
                        if (addressId) {
                            const queryResult = await getAddressById(addressId);
                            if (queryResult.isSuccess) {
                                const address = queryResult.result;
                                if (address.type === "mailing") setMailingAddress(address);
                                else setBillingAddress(address);
                            }
                        }
                    }
                }

            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
            
        }
        getOrganization();
    }, [user.id, match.params.id]);

    const handleNewAddress = async (address: Address) => {
        try {
            if (organization) {
                const input: UpdateOrganizationInput = {
                    id: organization.id,
                    addresses: [address.id]
                };
                const updateResult = await updateOrganization(input);
                if (updateResult.isSuccess) {
                    if (address.type === "mailing") setMailingAddress(address);
                    if (address.type === "billing") setBillingAddress(address);
                } else {
                    setError("Could not set a new address for the organization.");
                }
            } else {
                setError("Sorry, a problem occurred. Please try refreshing the page");
            }
        } catch (error: any) {
            const message = "Sorry, a problem occurred. Please try refreshing the page. " + error;
            setError(message);
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Organization Addresses" />
                {organization ?
                    <>
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonRow className="ion-margin">
                            <AddAddressCard handleNewAddress={handleNewAddress} />
                        </IonRow>
                        <IonCard className="bg-white ion-padding">
                            <IonRow className="ion-text-center ion-justify-content-center">
                                <IonCol sizeSm="12" sizeMd="8">
                                    <IonList className="bg-white">
                                        {mailingAddress && (
                                            <IonItem>
                                                <IonLabel>
                                                    <h4>Mailing Address:</h4> <AddressLabel address={mailingAddress} />
                                                </IonLabel>
                                            </IonItem>
                                        )}
                                        {billingAddress && (
                                            <IonItem>
                                                <IonLabel>
                                                    <h4>Billing Address:</h4> <AddressLabel address={billingAddress} />
                                                </IonLabel>
                                            </IonItem>
                                        )}
                                    </IonList>
                                </IonCol>
                            </IonRow>
                        </IonCard>
                    </>
                    :
                    <p>Loading organization info ...</p>
                }
            </IonContent>
        </IonPage>
    );
};

export default OrganizationAddressesPage;