import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import ErrorAlert from "../Errors/ErrorAlert";
import { Barn, BarnMember, Event, EventEntry, Horse, Membership, OrganizationMembershipType, Owner, Person, Rider, Trainer } from "../../models";
import Spinner from "../Spinners/Spinner";
import SuccessBanner from "../Banners/SuccessBanner";
import PhoneNumberTypeSelect from "../Contact/PhoneNumberTypeSelect";
import { CreateBarnInput, CreateBarnMemberInput, CreateContactInput, CreateEventEntryInput, CreateHorseInput, CreateMembershipInput, CreateOwnerInput, CreatePersonInput, CreatePersonNameInput, CreateRiderInput, CreateTrainerInput, UpdateHorseInput, UpdateMembershipInput, UpdateOrganizationMembershipTypeInput, UpdateRiderInput } from "../../API";
import { createHorse, getHorsesByBarnId } from "../../utilities/horse/Horse";
import { createOwner, getOwnersByBarnId } from "../../utilities/owner/Owner";
import { createRider, getRiderByPersonId } from "../../utilities/rider/Rider";
import moment from "moment";
import { createTrainer, getTrainersByBarnId } from "../../utilities/trainer/Trainer";
import { createContact } from "../../utilities/contact/Contact";
import { createEventEntry } from "../../utilities/eventEntry/EventEntry";
import { addRequiredEventEntryFeesByEventByEntry } from "../../utilities/eventEntryFee/AddRequiredEventEntryFees";
import SelectFromAllBarns from "../Barn/SelectFromAllBarns";
import SelectRiderFromBarn from "../Rider/SelectRiderFromBarn";
import { createMembership, updateMembership, getMembershipsByOrganizationIdByActiveStatus } from "../../utilities/membership/Membership";
import { createPerson, getPersonByPersonId } from "../../utilities/person/Person";
import { createBarnMember, getBarnMemberByBarnIdPersonId } from "../../utilities/barnMember/BarnMember";
import { createBarn, getBarnByName } from "../../utilities/barn/Barn";
import SelectHorse from "../Horse/SelectHorse";
import SelectMembershipByBackNumber from "../Memberships/SelectMembershipByBackNumber";
import SelectOrganizationMembershipType from "../Memberships/SelectOrganizationMembershipType";
import { getOrganizationMembershipTypesByOrganizationId, updateOrganizationMembershipType } from "../../utilities/organizationMembershipType/OrganizationMembershipType";
import CONSTANT from "../../constant/constant";
import SelectMembershipStatus from "../Memberships/SelectMembershipStatus";
import PriceField from "../Forms/PriceField";
import { isWindows } from "../../utilities/platform/Platform";
import { getNextAvailableMembershipId } from "../../utilities/organizationMembershipType/MembershipIdValues";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";
import { getDateMembershipEnds } from "../../utilities/membership/MembershipStatus";
import constants from "../../constant/constant";

interface _Props {
    event: Event
    onSubmit: Function
}

const QuickEntryInfoForm: React.FC<_Props> = ({event, onSubmit}) => {
    const user = useContext(PersonContext);

    // Determine how many shows this event should count as based on if it is the special Double Judged show
    const numberOfShowsToAddToMembership = event.id === "d2e6edb0-0eca-4c02-91e4-da588e36d7ba" ? 2 : 1;

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");
    const [reminderToSave, setReminderToSave] = useState(false);

    const [orgMembershipTypes, setOrgMembershipTypes] = useState<OrganizationMembershipType[] | undefined | null>();
    const [orgMemberships, setOrgMemberships] = useState<Membership[] | undefined | null>();
    const [currentMembership, setCurrentMembership] = useState<Membership | undefined | null>();
    const [barnMember, setBarnMember] = useState<BarnMember | undefined | null>();
    const [rider, setRider] = useState<Rider | null | undefined>();
    const [membershipId, setMembershipId] = useState("");
    const [horse, setHorse] = useState<Horse | undefined | null>();
    const [horseOptions, setHorseOptions] = useState<Horse[] | undefined | null>();
    const [barn, setBarn] = useState<Barn | undefined | null>();
    const [barnName, setBarnName] = useState<string>("");
    const [trainer, setTrainer] = useState<Trainer | undefined | null>();
    const [owner, setOwner] = useState<Owner | undefined | null>();
    const [type, setType] = useState<OrganizationMembershipType | undefined | null>();
    const [membershipStatus, setMembershipStatus] = useState<string>("");
    const [amountPaid, setAmountPaid] = useState("");
    const [person, setPerson] = useState<Person | undefined | null>();
    const [entryNumber, setEntryNumber] = useState<number | null>();
    const [numberShowsAttended, setNumberShowsAttended] = useState<number>(numberOfShowsToAddToMembership);
    const [horseName, setHorseName] = useState("");
    const [ownerName, setOwnerName] = useState("");
    const [riderName, setRiderName] = useState("");
    const [rider2Name, setRider2Name] = useState("");
    const [trainerName, setTrainerName] = useState("");
    const [primaryContactName, setPrimaryContactName] = useState("");
    const [primaryContactEmail, setPrimaryContactEmail] = useState("");
    const [primaryContactPhone, setPrimaryContactPhone] = useState("");
    const [primaryContactPhoneNumberType, setPrimaryContactPhoneNumberType] = useState("");
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();

    const clearMembership = () => {
        setCurrentMembership(undefined);
        setMembershipId("");
        setType(undefined);
        setAmountPaid("");
        setMembershipStatus("");
        setNumberShowsAttended(numberOfShowsToAddToMembership);
        setEntryNumber(undefined);
        setBarn(undefined);
        setBarnName("");
        setRider(undefined);
        setRiderName("");
        setHorse(undefined);
        setHorseName("");
        setOwnerName("");
        setTrainerName("");
        setPerson(undefined);
        setPrimaryContactName("");
        setPrimaryContactEmail("");
    }

    const verifyForm = () => {
        if (!horseName) {
            setError("Please include a name for the horse.");
            return false;
        }
        if (!riderName) {
            setError("Please include a name for the rider.");
            return false;
        }
        return true;
    }

    const getOrganizationMemberships = async (organizationId: string) => {
        const queryResult = await getMembershipsByOrganizationIdByActiveStatus(organizationId, "active");
        if (queryResult.isSuccess) {
            const memberships: Membership[] = queryResult.result;
            const sorted = memberships.sort((a, b) => (a.backNumber || 0) - (b.backNumber || 0));
            setOrgMemberships(sorted || memberships);
        }
    }

    const getCurrentOrganizationMembershipTypes = async (organizationId: string) => {
        const queryResult = await getOrganizationMembershipTypesByOrganizationId(organizationId);
        if (queryResult.isSuccess) {
            const membershipTypes: OrganizationMembershipType[] = queryResult.result;
            const sorted = membershipTypes.sort((a, b) => a.name.localeCompare(b.name));
            setOrgMembershipTypes(sorted || membershipTypes);
        }
    }

    useEffect(() => {
        if (event && event.organizationId) {
            getCurrentOrganizationMembershipTypes(event.organizationId);
            getOrganizationMemberships(event.organizationId);
        }
    }, [event]);

    const handleSelectedMembershipRecord = async (membership?: Membership) => {
        if (membership) {
            setIsLoading(true);
            setCurrentMembership(membership);
            setEntryNumber(membership.backNumber || 0);

            if (membership.barn) setBarn(membership.barn);
            else setBarn(undefined);

            if (membership.barn?.name) setBarnName(membership.barn.name);
            else setBarnName("");

            if (membership.trainer) setTrainerName(membership.trainer.name);
            else setTrainerName("");

            if (membership.rider) setRider(membership.rider);
            else setRider(undefined);

            if (membership.rider?.name) {
                setRiderName(membership.rider.name);
            }
            else setRiderName("");

            if (membership.horse) setHorse(membership.horse);
            else setHorse(undefined);

            if (membership.horse?.name) setHorseName(membership.horse?.name);
            else setHorseName("");

            if (membership.horse?.ownerName) setOwnerName(membership.horse.ownerName);
            else setOwnerName("");

            if (membership.membershipId) setMembershipId(membership.membershipId);
            else setMembershipId("");

            if (membership.organizationMembershipType) setType(membership.organizationMembershipType);
            else setType(undefined);

            if (membership.membershipStatus) setMembershipStatus(membership.membershipStatus);
            else setMembershipStatus("");

            if (membership.amountPaid) setAmountPaid(membership.amountPaid ? membership.amountPaid.toString() : "");
            else setAmountPaid("");

            if (membership.showsAttended) setNumberShowsAttended(membership.showsAttended + numberOfShowsToAddToMembership);
            else setNumberShowsAttended(numberOfShowsToAddToMembership);

            let personId = "";
            if (rider?.personId) {
                personId = rider.personId;
            } else {
                personId = membership.personId;
            }

            setIsLoading(false);

            if (membership.barnId) {
                await handleGetHorseOptions(membership.barnId);
            }
            else setHorseOptions(undefined);

            if (personId) {
                const personResult = await getPersonByPersonId(personId);
                if (personResult.isSuccess) {
                    const person: Person = personResult.result;
                    setPerson(person);
                    if (person.firstName && person.lastName) setPrimaryContactName(person.firstName + " " + person.lastName);
                    if (person.email) setPrimaryContactEmail(person.email);
                } else {
                    setPerson(undefined);
                    setPrimaryContactName("");
                    setPrimaryContactEmail("");
                }
            } else {
                setPerson(undefined);
                setPrimaryContactName("");
                setPrimaryContactEmail("");
            }
        } else {
            clearMembership();
        }
    }

    const handleSelectedMembershipType = (selectedType?: OrganizationMembershipType) => {
        setError("");
        setType(selectedType);
        if (selectedType) {
            setNumberShowsAttended(numberOfShowsToAddToMembership);
            setMembershipStatus(CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE);
            setMembershipId("");
            if (selectedType.price) setAmountPaid(selectedType.price ? selectedType.price.toString() : "");
        } else {
            setNumberShowsAttended(numberOfShowsToAddToMembership);
            setMembershipStatus("");
            setMembershipId("");
            setAmountPaid("");
        }
    }

    const handleMembershipId = () => {
        setError("");
        if (!type) {
            setError("Select the Membership Type first.");
            return;
        } 
        if (!type?.membershipIdValues) {
            setError("Please add a range of Membership Id Values to the selected Membership Type.")
            return;
        }
        setMembershipId(type?.nextAvailableMembershipId || "");
    }

    const handleSelectRider = async (rider?: Rider) => {
        if (rider) {
            setRider(rider);
            setRiderName(rider.name);

            const personId = rider.personId;
            if (personId) {
                const personResult = await getPersonByPersonId(personId);
                if (personResult.isSuccess) {
                    setPerson(personResult.result);
                }
            }
        } else {
            setRider(undefined);
        }
    }

    const handleGetHorseOptions = async (barnId: string) => {
        const queryResult = await getHorsesByBarnId(barnId);
        if (queryResult.isSuccess) {
            setHorseOptions(queryResult.result);
        }
    }

    const handleSelectBarn = async (barn?: Barn) => {
        if (barn) {
            setBarn(barn);
            setBarnName(barn.name);
            await handleGetHorseOptions(barn.id);
        } else {
            setBarn(undefined);
            setBarnName("");
            setHorseOptions(undefined);
        }
    }

    const handleSelectHorse = async (horse?: Horse) => {
        if (horse) {
            setHorse(horse);
            setHorseName(horse.name || horseName || "");
        } else {
            setHorse(undefined);
            setHorseName(horseName || "");
        }
    }

    const handleCreateMembership = async (givenRider?: (Rider | null | undefined), givenHorse?: (Horse | null | undefined)) => {
        setIsLoading(true);
        setSuccess("");
        setError("");

        let currentPerson: Person | undefined | null = person;
        if (!currentPerson) {
            currentPerson = await getPersonRecord();
        }

        let currentBarn: Barn | undefined | null = barn;
        if (!currentBarn) {
            currentBarn = await getBarnRecord();
        }

        let currentBarnMember: BarnMember | undefined | null = barnMember;
        if (!currentBarnMember) {
            currentBarnMember = await getBarnMemberRecord();
        }

        let currentHorse: Horse | undefined | null = givenHorse || horse;
        if (!currentHorse) {
            currentHorse = await getHorseRecord();
        }

        let currentRider: Rider | undefined | null = givenRider || rider;
        if (!currentRider) {
            currentRider = await getRiderRecord(currentBarn || undefined);
        }

        let currentTrainer: Trainer | undefined | null = trainer;
        if (!currentTrainer) {
            currentTrainer = await getTrainerRecord(currentBarn || undefined);
        }

        const dateMemEnds = type ? getDateMembershipEnds(type) : "";

        let input: CreateMembershipInput = {
            name: "RHSC",
            membershipId: membershipId,
            backNumber: entryNumber || null,
            membershipStatus: membershipStatus,
            amountPaid: parseFloat(amountPaid),
            organizationMembershipTypeId: type?.id || null,
            type: type?.name || null,
            personName: riderName || "",
            personId: currentPerson?.id || "",
            personEmail: primaryContactEmail || "",
            riderId: currentRider?.id || "",
            horseId: currentHorse?.id || "",
            barnId: currentBarn?.id || "",
            trainerId: currentTrainer?.id || null,
            organizationId: event.organizationId || "",
            organizationName: "RHSC",
            membershipOrganizationId: event.organizationId || "",
            dateMembershipEnds: dateMemEnds,
            showsAttended: numberShowsAttended || 0,
            createdBy: user.id,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
        const createResult = await createMembership(input);
        if (createResult.isSuccess) {
            const newMembership: Membership = createResult.result;

            if (type && type.membershipIdValues) {
                const newMembershipId = getNextAvailableMembershipId(type?.membershipIdValues, type.nextAvailableMembershipId || "");
                const updateInput:  UpdateOrganizationMembershipTypeInput = {
                    id: type.id,
                    nextAvailableMembershipId: newMembershipId
                };
                await updateOrganizationMembershipType(updateInput);
            }

            return newMembership;
        } else {
            setError("Could not create the member");
        }
    }

    const handleUpdateMembership = async () => {
        setIsLoading(true);
        setSuccess("");
        setError("");

        if (currentMembership) {
            let input: UpdateMembershipInput = {
                id: currentMembership.id,
                name: "RHSC",
                membershipId: membershipId,
                backNumber: entryNumber,
                membershipStatus: membershipStatus,
                amountPaid: parseFloat(amountPaid),
                organizationMembershipTypeId: type?.id || null,
                type: type?.name || null,
                personName: riderName,
                personEmail: currentMembership.personEmail || primaryContactEmail || "",
                riderId: rider?.id || "",
                horseId: horse?.id || "",
                barnId: barn?.id || "",
                showsAttended: numberShowsAttended || 0,
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
            const updatedResult = await updateMembership(input);
            if (updatedResult.isSuccess) {
                const updatedMembership: Membership = updatedResult.result;
                return updatedMembership;
            } else {
                setError("Could not updated the member");
            }
        }
    }

    const getPersonRecord = async () => {
        let currentPerson: Person | undefined | null = person;
        if (!currentPerson) {
            const personInput: CreatePersonInput = {
                email: "",
                firstName: riderName.split(" ")[0],
                lastName: riderName.split(" ")[1],
                isVerified: false,
                roles: "rider;",
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
            const createPersonResult = await createPerson(personInput);
            if (createPersonResult.isSuccess) {
                currentPerson = createPersonResult.result;
                setPerson(createPersonResult.result);
            }
        }
        return currentPerson;
    }

    const getRiderRecord = async (currentBarn?: Barn) => {
        let currentRider: Rider | undefined | null = rider;
        if (!currentRider) {
            if (person) {
                const riderQueryResult = await getRiderByPersonId(person.id);
                if (riderQueryResult.isSuccess) {
                    currentRider = riderQueryResult.result;
                }
            }

            if (!currentRider) {
                let riderInput: CreateRiderInput = {
                    name: riderName,
                    barnId: currentBarn?.id || "",
                    createdBy: person?.id || user.id || "", 
                    personId: person?.id || "",
                    createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                };
        
                const riderResult = await createRider(riderInput);
                if (riderResult.isSuccess) {
                    currentRider = riderResult.result;
                }
            }
            setRider(currentRider);
        }
        return currentRider;
    }

    const getBarnRecord = async () => {
        let currentBarn: Barn | undefined | null = barn;
        if (!currentBarn && barnName && barnName !== "") {
            const queryBarnResult = await getBarnByName(barnName);
            if (queryBarnResult.isSuccess) {
                currentBarn = queryBarnResult.result;
            } else {
                const barnInput: CreateBarnInput = {
                    name: barnName
                };
                const createBarnResult = await createBarn(barnInput);
                if (createBarnResult.isSuccess) {
                    currentBarn = createBarnResult.result;
                }
            }
            setBarn(currentBarn);
        }
        return currentBarn;
    }

    const getBarnMemberRecord = async (currentBarn?: Barn, currentPerson?: Person) => {
        let currentBarnMember: BarnMember | undefined;
        if (currentBarn && currentPerson) {
            const barnMemberResult = await getBarnMemberByBarnIdPersonId(currentBarn?.id, currentPerson?.id);
            if (barnMemberResult.isSuccess) {
                const barnMember:BarnMember = barnMemberResult.result;
                if (barnMember) {
                    currentBarnMember = barnMember;
                }
            }

            if (!currentBarnMember) {
                const barnMemberInput: CreateBarnMemberInput = {
                    barnId: currentBarn.id,
                    personId: currentPerson.id,
                    permissionLevel: "member",
                    name: riderName,
                    roles: "rider;",
                    createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                };
                const createBarnMemberResult = await createBarnMember(barnMemberInput);
                if (createBarnMemberResult.isSuccess) {
                    const result: BarnMember = createBarnMemberResult.result;
                    currentBarnMember = result;
                }
            }
        }
        setBarnMember(currentBarnMember);
        return currentBarnMember;
    }

    const getHorseRecord = async (horseBarn?: Barn) => {
        let currentHorse: Horse | undefined | null = horse;
        let currentBarn = horseBarn || barn;

        if (!currentHorse) {
            if (horseName && currentBarn) {
                const queryResult = await getHorsesByBarnId(currentBarn.id);
                if (queryResult.isSuccess) {
                    const horsesInBarn: Horse[] = queryResult.result;
                    if (horsesInBarn && horsesInBarn.length > 0) {
                        for (let i = 0; i < horsesInBarn.length; i++) {
                            const current = horsesInBarn[i];
                            if (current.name.toLowerCase() === horseName.toLowerCase()) {
                                currentHorse = current;
                                break;
                            }
                        }
                    }
                }
            }
            if (!currentHorse && horseName) {
                const horseInput: CreateHorseInput = {
                    name: horseName,
                    ownerName: ownerName || "",
                    barnId: currentBarn?.id || "",
                    barnName: currentBarn?.name || "",
                    personId: person?.id || ""
                };
                const createHorseResult = await createHorse(horseInput);
                if (createHorseResult.isSuccess) {
                    currentHorse = createHorseResult.result;
                }
            }
            setHorse(currentHorse);
        }
        return currentHorse;
    }

    const getTrainerRecord = async (trainerBarn?: Barn) => {
        let currentTrainer: Trainer | undefined | null = trainer;
        let currentBarn = trainerBarn || barn;

        if (!currentTrainer) {
            if (trainerName && currentBarn) {
                const queryResult = await getTrainersByBarnId(currentBarn.id);
                if (queryResult.isSuccess) {
                    const trainersInBarn: Trainer[] = queryResult.result;
                    if (trainersInBarn && trainersInBarn.length > 0) {
                        for (let i = 0; i < trainersInBarn.length; i++) {
                            const current = trainersInBarn[i];
                            if (current.name.toLowerCase() === trainerName.toLowerCase()) {
                                currentTrainer = current;
                                break;
                            }
                        }
                    }
                }
            }
            if (!currentTrainer && trainerName) {
                const trainerInput: CreateTrainerInput = {
                    name: trainerName,
                    barnId: currentBarn?.id || "",
                    barnName: currentBarn?.name || "",
                    createdBy: user.id
                };
                const createTrainerResult = await createTrainer(trainerInput);
                if (createTrainerResult.isSuccess) {
                    currentTrainer = createTrainerResult.result;
                }
            }
            setTrainer(currentTrainer);
        }
        return currentTrainer;
    }

    const getOwnerRecord = async (ownerBarn?: Barn) => {
        let currentOwner: Owner | undefined | null = owner;
        let currentBarn = ownerBarn || barn;

        if (!currentOwner) {
            if (ownerName && currentBarn) {
                const queryResult = await getOwnersByBarnId(currentBarn.id);
                if (queryResult.isSuccess) {
                    const ownersInBarn: Owner[] = queryResult.result;
                    if (ownersInBarn && ownersInBarn.length > 0) {
                        for (let i = 0; i < ownersInBarn.length; i++) {
                            const current = ownersInBarn[i];
                            if (current.name.toLowerCase() === ownerName.toLowerCase()) {
                                currentOwner = current;
                                break;
                            }
                        }
                    }
                }
            }
            if (!currentOwner && ownerName) {
                const ownerInput: CreateOwnerInput = {
                    name: ownerName,
                    barnId: currentBarn?.id || "",
                    createdBy: user.id
                };
                const createOwnerResult = await createOwner(ownerInput);
                if (createOwnerResult.isSuccess) {
                    currentOwner = createOwnerResult.result;
                }
            }
            setOwner(currentOwner);
        }
        return currentOwner;
    }

    const createNewOwner = async () => {
        const input: CreateOwnerInput = {
            name: ownerName
        };
        const result = await createOwner(input);
        if (result.isSuccess) {
            return result.result;
        } else {
            const message = "Could not create the owner. " + result.message;
            setError(message);
            return null;
        }
    }

    const createNewTrainer = async () => {
        const input: CreateTrainerInput = {
            name: trainerName
        };
        const result = await createTrainer(input);
        if (result.isSuccess) {
            return result.result;
        } else {
            const message = "Could not create the trainer. " + result.message;
            setError(message);
            return null;
        }
    }

    const saveEventEntry = async () => {
        const rider = await getRiderRecord(barn || undefined);
        let rider2;
        if (rider2Name) rider2 = await getRiderRecord(barn || undefined);
        const horse = await getHorseRecord();
        const owner = await getOwnerRecord();
        const trainer = await getTrainerRecord();

        let contactInput: CreateContactInput = {
            name: primaryContactName || "",
            personalEmail: primaryContactEmail || "",
        };
        if (primaryContactPhoneNumberType && primaryContactPhoneNumberType === "cell") contactInput.cell = primaryContactPhone;
        if (primaryContactPhoneNumberType && primaryContactPhoneNumberType === "work") contactInput.work = primaryContactPhone;
        if (primaryContactPhoneNumberType && primaryContactPhoneNumberType === "home") contactInput.home = primaryContactPhone;

        const result = await createContact(contactInput);
        const contact = result.result;

        const entryInput: CreateEventEntryInput = {
            eventId: event.id,
            status: "in_progress",
            createdBy: user.id,
            personId: user.id, 
            contactId: contact.id || "",
            number: entryNumber || 0,
            // eventEntryRiderId: rider?.id, 
            riderId: rider?.id, 
            riderName: rider?.name, 
            horseId: horse?.id, 
            horseName: horse?.name, 
            ownerId: owner?.id || " ", 
            ownerName: owner?.name || " ", 
            trainerId: trainer?.id || " ",
            trainerName: trainer?.name || " ",
            secondRiderId: rider2?.id, 
            secondRiderName: rider2?.name, 
        };
        console.log("Entry input: ", entryInput);
        const entryResult = await createEventEntry(entryInput);
        console.log("Entry ResuktL ", entryResult);
        if (entryResult.isSuccess) {
            const entry: EventEntry = entryResult.result;
            setCurrentEntry(entry);
            await addRequiredEventEntryFeesByEventByEntry(user.id, entry.eventId, entry);
            onSubmit(entry);
            setSuccess("Successfully saved the entry info!");
        } else {
            setError(entryResult.message);
        }
    }

    const saveRHSCEventEntry = async () => {
        const currentRider = await getRiderRecord(barn || undefined);
        let currentRider2;
        if (rider2Name) currentRider2 = await getRiderRecord(barn || undefined);
        const currentHorse = await getHorseRecord();
        const currentOwner = await getOwnerRecord();
        const currentTrainer = await getTrainerRecord();

        // First, handle the membership
        if (!currentMembership) {
            await handleCreateMembership(currentRider, currentHorse);
        } else {
            await handleUpdateMembership();
        }

        // Next, handle the contact
        let contactInput: CreateContactInput = {
            name: primaryContactName || "",
            personalEmail: primaryContactEmail || "",
        };
        if (primaryContactPhoneNumberType && primaryContactPhoneNumberType === "cell") contactInput.cell = primaryContactPhone;
        if (primaryContactPhoneNumberType && primaryContactPhoneNumberType === "work") contactInput.work = primaryContactPhone;
        if (primaryContactPhoneNumberType && primaryContactPhoneNumberType === "home") contactInput.home = primaryContactPhone;

        const result = await createContact(contactInput);
        const contact = result.result;

        // Next, handle the entry
        const entryInput: CreateEventEntryInput = {
            eventId: event.id,
            status: "in_progress",
            createdBy: user.id,
            personId: person?.id || rider?.personId || null, 
            contactId: contact.id || "",
            number: entryNumber || 0,
            riderId: currentRider?.id, 
            riderName: riderName || currentRider?.name ||  "",
            secondRiderId: currentRider2?.id || "",
            secondRiderName: rider2Name || currentRider2?.name || "", 
            horseId: currentHorse?.id, 
            horseName: horseName || currentHorse?.name || "", 
            barnId: barn?.id || "",
            barnName: barn?.name || barnName || null,
            ownerId: currentOwner?.id || null, 
            ownerName: ownerName || currentOwner?.name || null, 
            trainerId: currentTrainer?.id || null,
            trainerName: trainerName || currentTrainer?.name || " ",
            beddingRequestId: null,
            stablingRequestId: null,
        };

        const entryResult = await createEventEntry(entryInput);
        if (entryResult.isSuccess) {
            const entry: EventEntry = entryResult.result;
            setCurrentEntry(entry);
            await addRequiredEventEntryFeesByEventByEntry(user.id, entry.eventId, entry);
            onSubmit(entry);
            setSuccess("Successfully saved the entry info!");
        } else {
            setError(entryResult.message);
        }
    }

    const handleSubmitRHSCInfo = async () => {
        setReminderToSave(false);
        setIsLoading(true);
        setError("");
        setSuccess("");
        const isValid = verifyForm();
        if (isValid) await saveRHSCEventEntry();
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        setReminderToSave(false);
        setIsLoading(true);
        setError("");
        setSuccess("");
        const isValid = verifyForm();
        if (isValid) await saveEventEntry();
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            {reminderToSave && <ErrorAlert width="12" error="Remember to save your updates. Scroll down to save." />}
            {isLoading ?
                <Spinner/>
                :
                <form>
                    {(event.organizationId === constants.RHSC_ORGANIZATION.id) ?
                        <>
                            <IonRow>
                                <IonCol size="12">
                                    <h5>Membership Info</h5>
                                </IonCol>
                            </IonRow>
                            <p className="text-info description ion-text-wrap">Try finding the member by back number in the select. Or create a new membership.</p>
                            <IonRow className="pb-3">
                                <IonCol sizeXs="12" sizeMd="12">
                                    <IonLabel className="pl-3 description text-darker" position="stacked">Active Memberships (by back number)</IonLabel>
                                    <SelectMembershipByBackNumber selectedValue={currentMembership?.id} memberships={orgMemberships} onSelect={(membership: Membership) => handleSelectedMembershipRecord(membership)} />
                                    {currentMembership && <p className="text-light description ion-text-wrap">Clear this membership if you want to create a new membership.</p>}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                {(isWindows() || (isPlatform("desktop"))) && (
                                    <IonCol sizeXs="12" sizeMd="12">
                                        <IonButton
                                            id="edit-membership-generate-id-btn"
                                            class="ion-float-right"
                                            className="ml-1"
                                            color="tertiary"
                                            disabled={membershipId.length === 0 ? false : true}
                                            onClick={handleMembershipId}
                                        >
                                            Generate Id
                                        </IonButton>
                                    </IonCol>
                                )}
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonLabel className="pl-3 description text-darker" position="stacked">
                                        Membership Type
                                    </IonLabel>
                                    <SelectOrganizationMembershipType membershipTypes={orgMembershipTypes} selectedValue={type?.id} onSelect={(orgMembershipType: OrganizationMembershipType) => handleSelectedMembershipType(orgMembershipType)}/>
                                </IonCol>
                                {(!(isWindows() || isPlatform("desktop"))) && <IonCol sizeXs="12" sizeMd="12">
                                    <IonButton
                                        id="edit-membership-generate-id-btn"
                                        class="ion-float-right"
                                        className="ml-1"
                                        color="tertiary"
                                        disabled={membershipId.length === 0 ? false : true}
                                        onClick={handleMembershipId}
                                    >
                                        Generate Id
                                    </IonButton>
                                </IonCol>}
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel className="text-darker" position="stacked">
                                            Member Id
                                        </IonLabel>
                                        <IonInput
                                            type="text"
                                            value={membershipId}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setMembershipId(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonLabel className="pl-3 mb-0 description text-darker" position="stacked">Amount Paid</IonLabel>
                                    <PriceField value={amountPaid ? parseFloat(amountPaid) : undefined} onChange={(amount: number) => setAmountPaid(amount ? amount.toPrecision(2) : "")} />
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonLabel className="pl-3 description text-darker" position="stacked">
                                        Status
                                    </IonLabel>
                                    <SelectMembershipStatus selectedValue={membershipStatus} onSelect={(status: string) => setMembershipStatus(status)} />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Back Number</IonLabel>
                                        <IonInput
                                            type="text"
                                            value={entryNumber ? entryNumber : 0}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setEntryNumber(e.detail.value! !== "" ? parseInt(e.detail.value!) : 0)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Number Shows Attended</IonLabel>
                                        <IonInput
                                            type="number"
                                            min="0"
                                            value={numberShowsAttended}
                                            onIonChange={e => {
                                                setNumberShowsAttended(e.detail.value! ? parseInt(e.detail.value!) : 0);
                                            }}
                                        />
                                    </IonItem>
                                    <p className="description">{currentMembership ? "This member previously attended " + (currentMembership.showsAttended ? currentMembership.showsAttended : "0") + " show(s)." : ""}</p>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <hr />
                                    <h5>Entry's Info</h5>
                                </IonCol>
                            </IonRow>
                            <p className="text-info description ion-text-wrap">Try finding the barn in the select. If it doesn't exist, type in the name of the barn.</p>
                            <IonRow className="pb-5">
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonLabel position="stacked">Barn</IonLabel>
                                    <SelectFromAllBarns barn={barn} onSelect={(barn: Barn) => handleSelectBarn(barn)} />
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Barn Name</IonLabel>
                                        <IonInput
                                            type="text"
                                            value={barnName}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setBarnName(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                                <p className="text-light description ion-text-wrap">{currentMembership ? ("The current membership " + (currentMembership.barn ? ("is linked to " + currentMembership.barn.name) + "." : "is not linked to a barn.")) : ""}</p>
                            </IonRow>
                            <p className="text-info description ion-text-wrap">Try finding the rider in the select. Riders load after selecting the barn. If the rider doesn't exist, type in the name of the rider.</p>
                            <IonRow className="pb-5">
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonLabel position="stacked">Rider</IonLabel>
                                    <SelectRiderFromBarn barn={barn || undefined} selectedValue={rider?.id} onSelect={(rider: Rider) => handleSelectRider(rider)} />
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Rider Name <RequiredInputIndicator showWordRequired/></IonLabel>
                                        <IonInput
                                            type="text"
                                            value={riderName}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setRiderName(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                                <p className="text-light description ion-text-wrap">{currentMembership ? ("The current membership " + (currentMembership.rider ? ("is linked to " + currentMembership.rider.name) + "." : "is not linked to a rider.")) : ""}</p>
                            </IonRow>
                            <p className="text-info description ion-text-wrap">Try finding the horse in the select. Horses load after selecting the barn. If the horse doesn't exist, type in the name of the horse.</p>
                            <IonRow className="pb-5">
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonLabel position="stacked">Horse</IonLabel>
                                    <SelectHorse horses={horseOptions} selectedValue={horse?.id} onSelect={(horse: Horse) => {handleSelectHorse(horse)}} />
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Horse Name <RequiredInputIndicator showWordRequired/></IonLabel>
                                        <IonInput
                                            type="text"
                                            value={horseName}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setHorseName(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                                <p className="text-light description ion-text-wrap">{currentMembership ? ("The current membership " + (currentMembership.horse ? ("is linked to " + currentMembership.horse.name) + "." : "is not linked to a horse.")) : ""}</p>
                            </IonRow>
                            <p className="text-info description ion-text-wrap">Be sure to include the name of the horse's owner and the name of the trainer.</p>
                            <IonRow className="pb-5">
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Owner Name</IonLabel>
                                        <IonInput
                                            type="text"
                                            value={ownerName}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setOwnerName(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Trainer Name</IonLabel>
                                        <IonInput
                                            type="text"
                                            value={trainerName}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setTrainerName(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <hr />
                                    <h5>Entry's Contact Info</h5>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="floating">Primary Contact Name</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={primaryContactName}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setPrimaryContactName(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="floating">Primary Contact Email</IonLabel>
                                        <IonInput 
                                            type="email"
                                            value={primaryContactEmail}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setPrimaryContactEmail(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="floating">Primary Contact Phone Number</IonLabel>
                                        <IonInput 
                                            type="tel"
                                            value={primaryContactPhone}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setPrimaryContactPhone(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <PhoneNumberTypeSelect onChange={(value: string) => setPrimaryContactPhoneNumberType(value)} />
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol className="text-center" sizeMd="4">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="success"
                                        expand="block"
                                        onClick={handleSubmitRHSCInfo}
                                    >
                                        Save Entry Info
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                        :
                        <>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="floating">Entry Number</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={entryNumber || 0}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setEntryNumber(parseInt(e.detail.value!));
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="floating">Rider Name <RequiredInputIndicator /></IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={riderName}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setRiderName(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="floating">Rider #2 Name</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={rider2Name}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setRider2Name(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="floating">Horse Name <RequiredInputIndicator /></IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={horseName}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setHorseName(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="floating">Owner Name</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={ownerName}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setOwnerName(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="floating">Trainer Name</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={trainerName}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setTrainerName(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <hr />
                                    <h5>Entry's Contact Info</h5>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="floating">Primary Contact Name</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={primaryContactName}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setPrimaryContactName(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="floating">Primary Contact Email</IonLabel>
                                        <IonInput 
                                            type="email"
                                            value={primaryContactEmail}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setPrimaryContactEmail(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem color="white">
                                        <IonLabel position="floating">Primary Contact Phone Number</IonLabel>
                                        <IonInput 
                                            type="tel"
                                            value={primaryContactPhone}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setPrimaryContactPhone(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <PhoneNumberTypeSelect onChange={(value: string) => setPrimaryContactPhoneNumberType(value)} />
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol className="text-center" sizeMd="4">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="success"
                                        expand="block"
                                        onClick={handleSubmit}
                                    >
                                        Save Entry Info
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                    }
                </form>
            }
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
        </>
    );
};

export default QuickEntryInfoForm;