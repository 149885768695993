import {
    IonCard,
    IonCardContent,
    IonCardTitle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Spinner from "../Spinners/Spinner";
import { Person } from "../../models";
import { FullPersonDetails, getFullPersonDetails } from "../../utilities/person/FullPersonDetails";
import SelectFromAllPeople from "../Person/SelectFromAllPeople";
import { Table } from "reactstrap";

interface _Props {
    person: Person
}

const AdminDeduplicatePerson: React.FC<_Props> = ({person}) => {    
    
    const [peopleToCompare, setPeopleToCompare] = useState<Person[] | null | undefined>();
    const [fullPeopleToCompare, setFullPeopleToCompare] = useState<FullPersonDetails[] | null | undefined>();

    const [isLoading, setIsLoading] = useState(false);

    const addPersonToCompare = async (person: Person) => {
        setIsLoading(true);
        const fullPersonDetails: FullPersonDetails = await getFullPersonDetails(person);
        const updatedPeople = (peopleToCompare || []).concat([person]);
        await setPeopleToCompare(updatedPeople);
        const updatedFullPeople = (fullPeopleToCompare || []).concat([fullPersonDetails]);
        await setFullPeopleToCompare(updatedFullPeople);
        setIsLoading(false);
    }

    useEffect(() => {
        if (person) {
            addPersonToCompare(person);
        }
    }, [person]);

    return (
        <>
            <IonCard mode="md" className="ion-padding bg-white">
                <IonCardTitle>Select People to Compare</IonCardTitle>
                <IonCardContent>
                    <p>Select Additional People</p>
                    <SelectFromAllPeople onSelect={(person: Person) => addPersonToCompare(person)} />
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            {(fullPeopleToCompare && fullPeopleToCompare.length > 0) ?
                                <>
                                    <Table hover responsive>
                                        <thead>
                                            <tr>
                                                {fullPeopleToCompare.map((fullPerson, index) => (
                                                    <th key={index}>
                                                        <p className="ion-text-wrap">
                                                            {fullPerson.person.firstName} {fullPerson.person.lastName}
                                                        </p>
                                                        <p className="ion-text-wrap">
                                                            {fullPerson.person.id}
                                                        </p>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fullPeopleToCompare.map((fullPerson, index) => (
                                                <tr key={index}>
                                                        <p className="ion-text-wrap">
                                                            {fullPerson.person.firstName} {fullPerson.person.lastName}
                                                        </p>
                                                        <p className="ion-text-wrap">
                                                            {fullPerson.person.id}
                                                        </p>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </>
                                :
                                <p>No people selected</p>
                            }
                        </>
                    }
                </IonCardContent>
            </IonCard>
        </>
    );
};

export default AdminDeduplicatePerson;