import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Horse } from "../../../models";
import { updateHorse } from "../../../utilities/horse/Horse";
import ErrorAlert from "../../Errors/ErrorAlert";
import { UpdateHorseInput } from "../../../API";
import SuccessBanner from "../../Banners/SuccessBanner";

interface _Props {
    horse: Horse
}

const EditHorseIdentificationForm: React.FC<_Props> = ({horse}) => {    
    const [cogginsNumber, setCogginsNumber] = useState("");
    const [passportNumber, setPassportNumber] = useState("");
    const [microchipNumber, setMicrochipNumber] = useState("");
    
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const clearForm = () => {
        setCogginsNumber("");
        setPassportNumber("");
        setMicrochipNumber("");
    }

    useEffect(() => {
        if (horse) {
            setCogginsNumber(horse.cogginsNumber || "");
            setPassportNumber(horse.passportNumber || "");
            setMicrochipNumber(horse.microchipNumber || "");
        } else {
            clearForm();
        }
    }, [horse]);

    const handleEditHorse = async () => {
        const input: UpdateHorseInput = {
            id: horse.id,
            microchipNumber,
            cogginsNumber,
            passportNumber
        };
        const createResult = await updateHorse(input);
        if (createResult.isSuccess) {
            setError("");
            setSuccess("Successfully saved the updates.");
        } else {
            setSuccess("");
            setError(createResult.message);
        }
    }

    return (
        <>
            {success && <SuccessBanner success={success} />}
            {error && <ErrorAlert error={error} />}
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Microchip Number</IonLabel>
                        <IonInput 
                            type="text"
                            value={microchipNumber}
                            aria-required={true}
                            onIonChange={e => {
                                setMicrochipNumber(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Passport Number</IonLabel>
                        <IonInput 
                            type="text"
                            value={passportNumber}
                            aria-required={true}
                            onIonChange={e => {
                                setPassportNumber(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Coggins Number</IonLabel>
                        <IonInput 
                            type="text"
                            value={cogginsNumber}
                            aria-required={true}
                            onIonChange={e => {
                                setCogginsNumber(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton
                        className="ion-margin-top"
                        color="tertiary"
                        expand="block"
                        onClick={handleEditHorse}
                    >
                        Save
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default EditHorseIdentificationForm;