import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import Spinner from "../../Spinners/Spinner";
import { PersonContext } from "../../../context/PersonContext";
import { Event } from "../../../models";
import { updateEvent } from "../../../utilities/events/Event";
import ErrorAlert from "../../Errors/ErrorAlert";
import { UpdateEventInput } from "../../../API";

interface _Props {
    event: Event
}

const EventNameForm: React.FC<_Props> = ({event}) => {
    const user = useContext(PersonContext);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [eventName, setEventName] = useState(event.name);
    const [eventNickname, setEventNickname] = useState(event.nickname);
    const [error, setError] = useState("");

    const handleSubmit = async () => {
        setIsLoading(true);
        setIsDisabled(true);
        await updateCurrentEvent();
        setIsLoading(false);
    }

    const updateCurrentEvent = async () => {
        try {
            const eventInput: UpdateEventInput = {
                id: event.id,
                lastUpdatedBy: user.id,
                name: eventName,
                nickname: eventNickname
            };
            const updateResult = await updateEvent(eventInput)
            //TO DO - figure out why it works on first attempt, but must refresh before second attempt (might have to do with DS being turned off at the moment)
            if (!updateResult.isSuccess) setError("Sorry, we could not update the event's name. Please refresh and try again.");
        } catch (error: any) {
            setError("Sorry, we could not update the event's name");
        }
    };

    return (
        <form>
            <IonRow>
                {error && <ErrorAlert width="12" error={error} />}
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Event Name</IonLabel>
                        <IonInput 
                            type="text"
                            value={eventName}
                            aria-required={true}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setEventName(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Abbreviation</IonLabel>
                        <IonInput 
                            type="text"
                            value={eventNickname}
                            aria-required={true}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setEventNickname(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            disabled={isDisabled}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            {isDisabled ? "Saved" : "Save"}
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </form>
    );
};

export default EventNameForm;