import { StablingRequest } from "../../models";

/**
 * Sorts the StablingRequests first by trainer id and then by stall type id
 * 
 * @param data 
 * @returns scheduleItems sorted by trainer and stall type
 */
export function sortStablingRequestsByTrainer(data: StablingRequest[]) {
    try {
        let result: StablingRequest[] = [];
        if (data && data.length) {
            result = data.sort((a: any, b: any) => a.trainer?.name.localeCompare(b.trainer?.name) || a.stallType?.name.localeCompare(b.stallType?.name));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: StablingRequest");
        console.error(error);
        return null;
    }
};

/**
 * Sorts the StablingRequests first by stall type id and then by trainer id
 * 
 * @param data 
 * @returns scheduleItems sorted by stall type and trainer
 */
 export function sortStablingRequestsByStallType(data: StablingRequest[]) {
    try {
        let result: StablingRequest[] = [];
        if (data && data.length) {
            result = data.sort((a: any, b: any) => a.stallType?.name.localeCompare(b.stallType?.name) || a.trainer?.name.localeCompare(b.trainer?.name));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: StablingRequest");
        console.error(error);
        return null;
    }
};