import { OrganizationDivision } from "../../models";

/**
 * Sorts the organizationDivisions alphabetically a -> z
 * 
 * @param data 
 * @returns organizationDivisions sorted alphabetically
 */
export function sortOrganizationDivisionsByName(data: OrganizationDivision[]) {
    try {
        let result: OrganizationDivision[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => a.name.localeCompare(b.name));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: organizationDivision");
        console.error(error);
        return null;
    }
};

/**
 * Sorts the organizationDivisions numerically smallest to greatest
 * If there are not numbers, sort alphabetically a -> z
 * @param data 
 * @returns organizationDivisions sorted numerically
 */
export function sortOrganizationDivisionsByNumber(data: OrganizationDivision[]) {
    try {
        let result: OrganizationDivision[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => {
                const numberResult = (a.number || 0) - (b.number || 0);
                const nameResult = a.name.localeCompare(b.name);
                return numberResult || nameResult;
            });
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: organizationDivision");
        console.error(error);
        return null;
    }
};