import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { Organization } from "../../../../../models";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import { generateYearlyRevenueByRing } from "../../../../../utilities/reports/OrganizationReports";
import { Spinner } from "reactstrap";
import UpdateEventOrganizationForm from "../../../../../components/EventOrganization/UpdateEventOrganizationForm";
import constants from "../../../../../constant/constant";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventOrganizationBasicInfoPage: React.FC<OrganizationPageProps> = ({match}) => {
    const [organization, setOrganization] = useState<Organization>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOrganization() {
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                const org: Organization = queryResult.result;
                setOrganization(org);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getOrganization();
    }, [match, match.params.id]);

    const downloadRingReport = async () => {
        if (organization) {
            setIsLoading(true);
            await generateYearlyRevenueByRing(organization);
            setIsLoading(false);
        } else {
            setError("Sorry, no organization was found.");
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={organization ? organization.name : "Org Info"} />
                <IonRow className="ion-justify-content-center">
                    {error && <ErrorAlert width="12" error={error} />}
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Org Info</IonCardTitle>
                            <IonCardContent>
                                {organization ?
                                    <>
                                        <UpdateEventOrganizationForm organization={organization}/>
                                    </>
                                    :
                                    <p>Loading organization....</p>
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                {organization?.id === constants.RHSC_ORGANIZATION.id && (
                    <IonRow className="ion-justify-content-center">
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonCol size="12">
                            <IonCard mode="md" className="ion-padding bg-white">
                                <IonCardTitle>Org Reports</IonCardTitle>
                                {isLoading ? 
                                    <Spinner />
                                    :   
                                    <IonCardContent>
                                        {organization ?
                                            <p className="link" onClick={() => downloadRingReport()}>Download Revenue by Ring Report</p>
                                            :
                                            <p>Loading organization....</p>
                                        }
                                    </IonCardContent>
                                }
                            </IonCard>
                        </IonCol>
                    </IonRow>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationBasicInfoPage;