import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import { useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import {Event} from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import EditEntryForm from "../../../../../components/Entry/EditEntryForm";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import ViewAllEntries from "../../../../../components/Entry/ViewAllEntries";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventParticipantsPage: React.FC<EventPageProps> = ({match}) => {
    const history = useHistory();

    const [event, setEvent] = useState<Event>();
    const [error, setError] = useState("");
    const [showHelpRow, setShowHelpRow] = useState(true);

    useEffect(() => {
        async function getEvent() {
            if (!match.params.id) {
                setError("Sorry, we could not find the event");
                return;
            }
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getEvent();
    }, [match]);

    const handleEntryFormUpdate = () => {
        
    }

    const handleNavigateToAddEntryPage = () => {
        if (event) {
            const path = "/index/staff/event/" + event.id + "/quick-add-entry";
            history.push(path);
        }
    }

    return (
        <IonPage className="bg-light">
            <Header isEventPage={event ? true : false} event={event ? event : undefined} />
            <IonContent>
                <PageTitle title={event ? event.name : "participants"} />
                {error && <ErrorAlert width="12" error={error} />}
                {event && (
                    <>
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol size="10">
                                                Online Entries
                                            </IonCol>
                                            <IonCol size="2">
                                                <p className="link" onClick={() => setShowHelpRow(!showHelpRow)}>{showHelpRow ? "Hide" : "Show"}</p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                   {showHelpRow && (
                                        <IonCardSubtitle>
                                            <IonRow>
                                                <IonCol>
                                                    {event && event.status === "draft" && <p className="description">This is where you will format the online entry form your exhibitors will complete. When you are done formatting, make sure the status of you show is "Published" and the Entries Status is "Accepting Entries."</p>} 
                                                    {event && event.status !== "draft" && <p className="description">This is where you will view pending entries. Once you accept entries, you will be able to see how many people are entered in each class. If you decline an entry, it will send an email to the primary contact listed with the decline reason.</p>}
                                                </IonCol>
                                            </IonRow>
                                        </IonCardSubtitle>
                                   )}
                                </IonCard>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol size="10">
                                                Quick Add
                                            </IonCol>
                                            <IonCol size="2">
                                                <p className="link" onClick={() => setShowHelpRow(!showHelpRow)}>{showHelpRow ? "Hide" : "Show"}</p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    {showHelpRow && (
                                        <IonCardSubtitle>
                                            <IonRow>
                                                <IonCol>
                                                    <p className="description">Allows the show office staff to quickly create entries without extensive entry info.</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol className="ion-text-center">
                                                    <IonButton color="tertiary" onClick={handleNavigateToAddEntryPage}>Add Entry</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardSubtitle>
                                    )}
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        {event && event.status === "draft" && (
                            <>
                                <IonRow id="entryFormExplanation" className="ion-align-items-stretch">
                                    <IonCol size="12">
                                        <IonCard mode="md" className="ion-padding bg-white stretch">
                                            <IonCardContent>
                                                <p>Below is the entry form as it currently stands. Make sure that you have completed your class list, stall types, show fees and show rules.</p>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow id="entryForm" className="ion-align-items-stretch">
                                    <IonCol size="12">
                                        <IonCard mode="md" className="ion-padding bg-white stretch">
                                            <IonCardTitle>
                                                Entry Form
                                            </IonCardTitle>
                                            <IonCardContent>
                                                <EditEntryForm event={event} onSubmit={handleEntryFormUpdate} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </>
                        )} 
                        {event && event.status !== "draft" && (
                            <>
                               <IonRow>
                                   <IonCol>
                                       <ViewAllEntries event={event} />
                                   </IonCol>
                               </IonRow>
                            </>
                        )} 
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventParticipantsPage;