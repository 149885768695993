import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonLabel,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps, useHistory } from "react-router";
import { Barn, Owner, Rider, Trainer } from "../../../../models";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import { BarnPerson } from "../../../../interfaces/Person";
import { getBarnPeople, removeBarnPerson } from "../../../../utilities/barn/BarnPeople";
import { PersonContext } from "../../../../context/PersonContext";
import EditPersonBasicInfoForm from "../../../../components/Person/EditPersonBasicInfoForm";
import EditPersonRolesForm from "../../../../components/Person/EditPersonRolesForm";
import EditPersonContactForm from "../../../../components/Person/EditPersonContactForm";
import { getBarnById } from "../../../../utilities/barn/Barn";
import PersonMembershipForm from "../../../../components/Memberships/PersonMembershipForm";
import EditPersonMembershipsTable from "../../../../components/Memberships/EditPersonMembershipsTable";
import { BarnMember } from "../../../../API";
import { getBarnMemberByBarnIdPersonId } from "../../../../utilities/barnMember/BarnMember";
import EditPersonAddressForm from "../../../../components/Person/EditPersonAddressForm";
import Spinner from "../../../../components/Spinners/Spinner";

interface HorsePageProps extends RouteComponentProps<{
    barnId: string
    id: string
    type: string
}> {}

const EditBarnPersonPage: React.FC<HorsePageProps> = ({match}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [type, setType] = useState("");
    const [person, setPerson] = useState<BarnPerson | null | undefined>();
    const [barn, setBarn] = useState<Barn | null | undefined>();
    const [barnMember, setBarnMember] = useState<BarnMember | null | undefined>();

    const [currentSegment, setCurrentSegment] = useState("basic");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const getBarn = async (id: string) => {
        const queryResult = await getBarnById(id);
        if (queryResult.isSuccess) setBarn(queryResult.result);
    }

    const getBarnMember = async (barnId: string) => {
        const queryResult = await getBarnMemberByBarnIdPersonId(barnId, user.id);
        if (queryResult.isSuccess) setBarnMember(queryResult.result);
    }

    const getPeople = async (barnId: string) => {
        const people: BarnPerson[] = await getBarnPeople(barnId, user.id);
        let result;
        people.forEach(currentPerson => {
            if (type === "rider" && currentPerson.riderId === match.params.id) {
                result = currentPerson;
            } else if (type === "owner" && currentPerson.ownerId === match.params.id) {
                result = currentPerson;
            } else if (type === "trainer" && currentPerson.trainerId === match.params.id) {
                result = currentPerson;
            }
        });
        setPerson(result);
    }

    useEffect(() => {
        async function getParams() {
            const type = match.params.type;
            setType(type);

            const barnId = match.params.barnId;
            if (barnId) {
                setIsLoading(true);
                await getPeople(barnId);
                await getBarn(barnId);
                await getBarnMember(barnId);
                setIsLoading(false);
            }
        }
        getParams();
    }, [match, match.params.id]);

    const handlePersonUpdate = async () => {
        await getPeople(match.params.barnId);
    }

    const handleRemovePerson = async () => {
        if (person) {
            if (barn) {
                const result = await removeBarnPerson(person, barn.id, user.id);
                if (result.isSuccess) {
                    history.goBack();
                } else {
                    setError("Could not remove the person.");
                }
            } else {
                setError("No barn found.");
            }
        } else {
            setError("No person to remove.");
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={person ? person.name : "Edit Person"} />
                {error && <ErrorAlert width="12" error={error} />}
                <IonSegment scrollable mode="md" value={currentSegment} onIonChange={e => setCurrentSegment(e.detail.value || "basic")}>
                    <IonSegmentButton value="basic" >
                        <IonLabel>About</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="ids">
                        <IonLabel>ID Numbers</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="connections">
                        <IonLabel>Connections</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="results">
                        <IonLabel>Results</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                {isLoading ?
                    <Spinner />
                    :
                    <>
                        {(currentSegment === "basic" && person) && (
                            <> 
                                <IonRow className="ion-justify-content-center" id="basic">
                                    <IonCol sizeSm="12" sizeMd="8">
                                        <IonCard color="white" className="ion-padding">
                                            <IonCardTitle>
                                                <h3>Basic Info</h3>
                                            </IonCardTitle>
                                            <IonCardContent>
                                                <EditPersonBasicInfoForm person={person} onSubmit={handlePersonUpdate} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center" id="basic">
                                    <IonCol sizeSm="12" sizeMd="8">
                                        <IonCard color="white" className="ion-padding">
                                            <IonCardTitle>
                                                <h3>Address</h3>
                                            </IonCardTitle>
                                            <IonCardContent>
                                                <EditPersonAddressForm person={person} onSubmit={handlePersonUpdate} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center" id="basic">
                                    <IonCol sizeSm="12" sizeMd="8">
                                        <IonCard color="white" className="ion-padding">
                                            <IonCardTitle>
                                                <h3>Contact Info</h3>
                                            </IonCardTitle>
                                            <IonCardContent>
                                                <EditPersonContactForm person={person} onSubmit={handlePersonUpdate} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center" id="basic">
                                    <IonCol sizeSm="12" sizeMd="8">
                                        <IonCard color="white" className="ion-padding">
                                            <IonCardTitle>
                                                <h3>Roles</h3>
                                            </IonCardTitle>
                                            <IonCardContent>
                                                <p>Select as many as apply.</p>
                                                <EditPersonRolesForm person={person} onSubmit={handlePersonUpdate} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                {barnMember && barnMember.permissionLevel === "admin" && (
                                    <IonRow className="ion-justify-content-center" id="deleteBarnPerson">
                                        <IonCol sizeSm="12" sizeMd="8">
                                            <IonCard color="white" className="ion-padding">
                                                <IonCardTitle>
                                                    <h3>Remove From Barn</h3>
                                                </IonCardTitle>
                                                <IonCardContent>
                                                    <p>As an admin, you can remove this person from the barn (includes all of their roles and data).</p>
                                                    <IonButton color="danger" onClick={handleRemovePerson}>Remove</IonButton>
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                )}
                            </>
                        )}
                        {(currentSegment === "ids" && person) && (
                            <> 
                                <IonRow className="ion-justify-content-center" id="ids">
                                    <IonCol sizeSm="12" sizeMd="8">
                                        <IonCard color="white" className="ion-padding">
                                            <IonCardTitle>
                                                <h3>Add Membership</h3>
                                            </IonCardTitle>
                                            <IonCardContent>
                                                <PersonMembershipForm person={person} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center" id="ids">
                                    <IonCol sizeSm="12" sizeMd="8">
                                        <EditPersonMembershipsTable currentBarnPerson={person} hasAdminAccess={!!(barnMember && barnMember.permissionLevel === "admin")} />
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                        {(currentSegment === "connections" && person) && (
                            <>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol sizeSm="12" sizeMd="8">
                                        <IonCard color="white" className="ion-padding">
                                            <IonCardTitle>
                                                <h3>Barn</h3>
                                            </IonCardTitle>
                                            <IonCardContent>
                                                <h4>{barn?.name}</h4>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                        {(currentSegment === "results" && person) && (
                            <> 
                                <IonRow className="ion-justify-content-center">
                                    <IonCol sizeSm="12" sizeMd="8">
                                        <IonCard color="white" className="ion-padding">
                                            <IonCardTitle>
                                                <h3>Results</h3>
                                            </IonCardTitle>
                                            <IonCardContent>
                                                <p>Coming soon...</p>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default EditBarnPersonPage;