import { EventEntryFee } from "../../models";

/**
 * Sorts the eventFees in alphabetical order
 * 
 * @param data 
 * @returns eventFees sorted alphabetically
 */
export function sortEventEntryFeesByName(data: EventEntryFee[]) {
    try {
        let result: EventEntryFee[] = [];
        if (data && data.length) {
            result = data.sort((a: any, b: any) => a.name - b.name);
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: eventFee");
        console.error(error);
        return null;
    }
};