import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import SelectState from "./SelectState";
import Spinner from "../Spinners/Spinner";
import ErrorAlert from "../Errors/ErrorAlert";
import { Address } from "../../models";

interface _Props {
    address?: (Address | null)
    selectedCity?: (string | null)
    selectedProvState?: (string | null)
    onSubmit: Function
}

const LocationForm: React.FC<_Props> = ({address, selectedCity, selectedProvState, onSubmit}) => {

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [city, setCity] = useState<string>(address?.city || selectedCity || "");
    const [provState, setProvState] = useState<string>(address?.provState || selectedProvState || "");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (selectedCity) setCity(selectedCity);
    }, [selectedCity]);

    const verifyForm = () => {
        if (!city) {
            setError("Please include the city.");
            return false;
        }
        if (!provState) {
            setError("Please include the state or province.");
            return false;
        }
        return true;
    }

    const handleProvStateInputChange = (value: string) => {
        setIsDisabled(false);
        if (value && value !== "-") setProvState(value);
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) onSubmit(city, provState);
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonItem color="white">
                            <IonLabel position="stacked">City</IonLabel>
                            <IonInput 
                                type="text"
                                value={city}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setCity(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6">
                        <SelectState selectedValue={provState} onInputChange={handleProvStateInputChange} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default LocationForm;