import { CreateEventDayInput } from "../../API";

export function formatEventDayInput(eventId: string, dayMoment: any) {
    const input: CreateEventDayInput = {
        eventId: eventId,
        dayofweek: dayMoment.format("dddd"),
        date: dayMoment.format("DD"),
        month: dayMoment.format("MMMM"),
        year: dayMoment.format("YYYY"),
        awsdate: dayMoment.format("YYYY-MM-DD")
    };
    return input;
}