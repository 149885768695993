import { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { IonButton, IonCol, IonRow } from "@ionic/react";
import SuccessBanner from "../Banners/SuccessBanner";
import ErrorBanner from "../Banners/ErrorBanner";
import Spinner from "../Spinners/Spinner";

export default function CheckoutForm(params) {
    const stripe = useStripe();
    const elements = useElements();

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setSuccess("Payment succeeded!");
                    break;
                case "processing":
                    setSuccess("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setError("Your payment was not successful, please try again.");
                    break;
                default:
                    setError("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError("");
        setSuccess("");

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        // The return_url param is required, but we can redirect to the return_url only if required.
        // Example: some payment methods (like iDEAL) the customer will be redirected to an intermediate 
        // site first to authorize the payment, then redirected to the `return_url`.
        // Currently, we only accept credit card payments, so we will avoid the redirect
        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this in prod
                return_url: params.returnURL,
            },
            redirect: "if_required"
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. 
            if (error.type === "card_error" || error.type === "validation_error") {
                setError(error.message);
            } else {
                setError("An unexpected error occurred.");
            }
        } else {
            // The payment succeeded - display the message
            setSuccess("The payment was a success!");
            params.handleSubmit();
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            {success && <SuccessBanner width="12" success={success}/>}
            {error && <ErrorBanner width="12" error={error}/>}
            <IonRow className="ion-justify-content-center card-section">
                <IonCol sizeXs="12" sizeMd="6" sizeLg="4" className="payment-element-section ion-text-center">
                    <PaymentElement id="payment-element" options={paymentElementOptions} />
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton color="tertiary" expand="block" disabled={isLoading || !stripe || !elements} id="submit" type="submit">
                            <span id="button-text">
                                Pay Now
                            </span>
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </form>
    );
}