import {
    IonCol, 
    IonContent, 
    IonHeader,  
    IonPage, 
    IonRow,
    IonText
} from "@ionic/react";
import React from "react";
import { Card, CardBody, Container } from "reactstrap";
import WebNavbar from "../../components/Navbars/WebNavbar";
import {Link} from "react-router-dom";
import onlineEntries from "../../assets/img/general/OnlineEntries.png";
import Footer from "../../components/Footers/Footer";
import { isWindows } from "../../utilities/platform/Platform";
import { isPlatform } from "@ionic/core";
import DropDownCard from "../../components/Cards/DropdownCard";

const AboutOnlineEntriesPage: React.FC = () => {

    return (
        <IonPage id="webAboutOnlineEntriesPage">
            <IonHeader>
                <WebNavbar />
            </IonHeader>
            <IonContent>
                <section id="online-entries" className="py-5 bg-light overflow-hidden">
                    <Container fluid>
                        <IonRow className="justify-content-center mb-3">
                            <IonCol sizeXs="12" sizeLg="6" className="text-center">
                                <img className="shadow bg-white rounded-1 img" alt="Online Entries" src={onlineEntries}/>
                            </IonCol>
                            <IonCol sizeXs="12" sizeLg="6" className="text-center">
                                <h1 className="text-primary pb-3">
                                    Online Entries and Results
                                </h1>
                                <h3 className="text-primary py-3">
                                    No matter the size of your event, we have you covered.
                                </h3>
                                <h3 className="text-primary py-3">
                                    We developed this tool alongside show managers and secretaries to ensure it meets your needs.
                                </h3>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-white">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">All of the Tools You Need</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Quick Set Up
                                        </h4>
                                        <p className="description mt-3">
                                            Set up your events once. Then, use your previous events as templates to avoid re-doing any work. 
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Easy Entries
                                        </h4>
                                        <p className="description mt-3">
                                            Easily send out links so exhibitors can submit entries. Review and edit entries as they come in. 
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Every Report
                                        </h4>
                                        <p className="description mt-3">
                                            Download class sheets, time tables, and financial reports. Plus, show results in the formats you need. 
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                        <IonRow className="mt-5 ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                <IonText>
                                    <h2 className="font-weight-bold text-primary">Event Management</h2>
                                    <h6>Quick Event Setup</h6>
                                    <h6>Daily Schedules</h6>
                                    <h6>Stabling Requests</h6>
                                    <h6>Charge Required or Optional Fees</h6>
                                    <h6>Require Rule Agreements or Waiver Signatures</h6>
                                    <h6>Collect Coggins Information</h6>
                                    <h6>Track Results, Prize Money and Points</h6>
                                    <h6>Print or Email Invoices</h6>
                                    <h6>Track Payments</h6>
                                    <h6>Automatted Reports</h6>
                                </IonText>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                <IonText>
                                    <h2 className="font-weight-bold">Exhibitors</h2>
                                    <h6>View Event Information With or Without a RingSide Pro Account</h6>
                                    <h6>With a Free Account, Create Or Join a Barn</h6>
                                    <h6>Save Info For Future Use</h6>
                                    <h6>Submit Entries Online For Free</h6>
                                    <h6>Receive an Automatic Email Confirmation</h6>
                                    <h6>View Event Results</h6>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-light">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">Tutorial Videos</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary py-3 ion-align-items-space-between">
                            <IonCol size="12" className="ion-text-center py-3">
                                <p>
                                    Learn more about our Online Entry system and what it can do: <a href="https://www.youtube.com/channel/UC62yedG12RVpiKifqZkM1fA/featured" className="link">RingSide Pro Youtube Channel.</a>
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary py-3 ion-align-items-space-between">
                            <IonCol sizeXs="6" sizeMd="12" className="ion-text-center py-3">
                                {(isWindows() || isPlatform("desktop")) ?
                                    <div>
                                        <iframe width="560" height="315" title="tutorial video"
                                            src="https://youtube.com/embed/V1BrmQl0vp4">
                                        </iframe>
                                    </div>
                                    :
                                    <div></div>
                                }
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">Pricing</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pt-3">
                            <IonCol size="12" className="ion-text-center">
                                <h2 className="text-primary">
                                    All for just <span className="font-weight-bold">$99</span>
                                </h2>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol sizeXs="12" sizeMd="6" className="">
                                <ul>
                                    <li>
                                        <p className="text-primary">
                                            Simple, affordable pricing
                                        </p>
                                    </li>
                                    <li>
                                        <p className="text-primary">
                                            Takes the guesswork out of budgeting
                                        </p>
                                    </li>
                                </ul>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" className="">
                                <ul>
                                    <li>
                                        <p className="text-primary">
                                            Free for your exhibitors to submit entries
                                        </p>
                                    </li>
                                    <li>
                                        <p className="text-primary">
                                            Association discounts available
                                        </p>
                                    </li>
                                </ul>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-light">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">FAQ</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="Do I have to download software to my computer?" 
                                    content="Nope, RingSide Pro is web-based. That means you can access your account using any computer or smart phone. You don't have to worry about having data stored on your device because it is all stored in the cloud. This also means you can easily collaborate with other people in your organization." 
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title='What is an "organization" in RingSide Pro?' 
                                    content="The purpose of an organization is to share settings between all of the events you host. Within the same organization, you can copy settings from an old event when you create a new event to save yourself time. You can invite as many people as you like to join your organization and help you run events." 
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="Can I use this software for events other than horse shows?" 
                                    content='Yes! The software is helpful for all kinds of compeitions as well as camps, clinics and other events. If you have questions about using the software for your event, you can contact us at hello@ringsidepro.com.' 
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="How do I create an event?" 
                                    content='Once you have created an Organization, you can set up your event. To get started, you just need to include the name, dates and type of event. To see tutorial videos of exactly how to do it, check out our YouTube channel!' 
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title='What does "draft" mode mean?' 
                                    content='When you create a new event will be in "draft" mode. You can keep it in "draft" mode while you make your edits and no one else on the site will be able to see the event. Once you are happy with all of your event settings, you can "publish" it so that other users will now be able to see it.'
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title='Can I collect online payments for my event through the software?' 
                                    content='Not yet. We are working to provide the ability for you to collect online payments through RingSide Pro and plan to have this feature available by Spring 2023. Currently, you can keep track of payments you receive from entries through the software including payer name, payment type, amount and notes. You can record multiple payments on one entry in case you receive payments from multiple people for one entry.'
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="If I use the online entries, do I have to use the Text Alert service?" 
                                    content="No, our Text Alert service is separate from the Online Entry service. If you would like to use them together, you are easily able to use both services for one event."
                                />
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-white">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">We're Here to Help</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary py-3 ion-align-items-space-between">
                            <IonCol size="12" className="ion-text-center py-3">
                                <p>
                                    We're always happy to answer your questions and provide support to both you and your exhibitors.
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Call
                                        </h4>
                                        <p className="description mt-3">
                                            757-812-0385
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Email
                                        </h4>
                                        <p className="description mt-3">
                                            hello@ringsidepro.com
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5">
                    <Container>
                        <IonRow className="justify-content-center">
                            <IonCol size="12">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-center text-uppercase">
                                            Ready to get started?
                                        </h4>
                                        <p className="text-center">
                                            <Link to="/auth/register" className="link text-info">Create an Account</Link>
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default AboutOnlineEntriesPage;
