import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
    isPlatform,
} from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import { AuditorStatus, CreateAuditorInput, CreateEventAuditorFilterInput, CreateEventAuditorInput, UpdateAuditorInput, UpdateEventAuditorInput } from "../../API";
import { PersonContext } from "../../context/PersonContext";
import { Auditor, Event, EventAlertSetting, EventAuditor, EventAuditorFilter, EventFilter } from "../../models";
import { createAuditor, getAuditorByPhoneNumber, updateAuditor } from "../../utilities/auditor/Auditor";
import { getEventAlertSettingByEventId } from "../../utilities/eventAlertSetting/EventAlertSetting";
import { getDescription } from "../../utilities/eventAlertSetting/Settings";
import { createEventAuditor, deleteEventAuditor, getEventAuditorById, getEventAuditorByPhoneNumber, getEventAuditorWithFiltersByPhoneNumber, updateEventAuditor } from "../../utilities/eventAuditor/EventAuditor";
import { createEventAuditorFilter, deleteEventAuditorFilter, getEventAuditorFiltersByEventIdAuditorId } from "../../utilities/eventAuditorFilter/EventAuditorFilter";
import { getEventFiltersByEventId } from "../../utilities/eventFilter/EventFilter";
import { addNoteToHubspot } from "../../utilities/hubspot/HubspotNote";
import { lookupPhoneNumber } from "../../utilities/twilio/TwilioLookup";
import InformationBanner from "../Banners/InformationBanner";
import ErrorAlert from "../Errors/ErrorAlert";
import SelectEventFiltersForm, { FormattedFilter, FormattedOption } from "../Event/Alerts/SelectEventAlertFiltersForm";
import Spinner from "../Spinners/Spinner";
import AlertsChoiceNavbar from "../Navbars/AlertsChoiceNavbar";
import SelectEventFilterRolesOptionForm, { FormattedRoleOption } from "../Event/Alerts/EventFilterRolesOptionForm";
import { sendGITNBWelcomeTexts, sendRSPUpdateTextsForEvent, sendRSPWelcomeTextsForEvent } from "../../utilities/twilio/AuditorMessages";
import { TextAlertRoleFilter, TextAlertRolesFilters, TextAlertRolesFiltersForIHSAFinals, TextAlertRolesFiltersForLAECOrg, TextAlertRolesFiltersForPonyFinals } from "../../utilities/roles/Roles";
// Import the NPM package to select a country code on phone number input
import PhoneInput, {isPossiblePhoneNumber} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";
import constants from "../../constant/constant";
import { Input } from "reactstrap";
interface _Props {
    event: Event
    eventAuditor?: (EventAuditor | null)
    onCreate?: Function
    onEdit?: Function
    onDelete?: Function
    isAdminView?: Boolean
    onFindCurrentEventAuditor?: Function
}
const gitnbEventId = "4ddb21d4-ed5d-4941-ad6a-78e2e6ae64f0";
const EventAuditorForm: React.FC<_Props> = ({event, eventAuditor, onCreate, onEdit, onDelete, isAdminView, onFindCurrentEventAuditor}) => {
    const user = useContext(PersonContext);
    
    const [currentStep, setCurrentStep] = useState(1);
    const [totalSteps, setTotalSteps] = useState(4); //Base case is 1-PhoneNumber, 2-PersonName, 3-Roles, 4-Submit. Could include 3.1-EventFilters, 4-Submit+OptIn
    const [useDescription, setUseDescription] = useState("");
    const [currentEventAuditor, setCurrentEventAuditor] = useState<EventAuditor | null | undefined>();
    const [currentAuditor, setCurrentAuditor] = useState<Auditor | null | undefined>();
    const [willSendUpdateAlert, setWillSendUpdateAlert] = useState(true);
    const [phoneNumberInputValue, setPhoneNumberInputValue] = useState<any>(undefined); //The package requires this to be an E164Number type
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [name, setName] = useState("");
    const [formattedRoleOptions, setFormattedRoleOptions] = useState<FormattedRoleOption[] | null | undefined>();
    const [navbarState, setNavbarState] = useState<"all" | "filter" | "stop">("filter");
    const [eventFilters, setEventFilters] = useState<EventFilter[] | null | undefined>();
    const [selectedFilters, setSelectedFilters] = useState<EventAuditorFilter[] | null | undefined>();
    const [hasNoSelectedFilters, setHasNoSelectedFilters] = useState(true);
    const [formattedFilters, setFormattedFilters] = useState<FormattedFilter[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [isMainLoading, setIsMainLoading] = useState(false);
    const [error, setError] = useState("");
    const [restartTextMessageInfo, setRestartTextMessageInfo] = useState("");
    const [displayLinkToStartTexts, setDisplayLinkToStartTexts] = useState(false);
    const [rspPhoneNumber, setRSPPhoneNumber] = useState("+1-757-210-6404.");
    
    // Do this here to set it on mount, but do it again in filter form component
    const formatEventFilters = (eventFilters: EventFilter[], selectedFilters?: EventAuditorFilter[]) => {
        setIsLoading(true);
        let formattedArray: FormattedFilter[] = [];
        let selectedArray: string[] = [];
        if (eventFilters) {
            for (var i = 0; i < eventFilters.length; i++) {
                const currentEventFilter = eventFilters[i];
                if (currentEventFilter.name !== "Roles") {
                    if (currentEventFilter.options && currentEventFilter.options.length > 0) {
                        let options: FormattedOption[] = [];
                        // Use formatted options to track which options on a filter are selected
                        for (var j = 0; j < currentEventFilter.options.length; j++) {
                            const currentOption: string | null = currentEventFilter.options[j];
                            if (currentOption) {
                                // Normally, we want to select all filters to assume user will subscribe to all alerts (probably a new subscriber)
                                let isSelected = true; 
                                if (selectedFilters) {
                                    // If the user has already subscribed to alerts, assume each option is unselected until it is seen
                                    isSelected = false; 
                                    selectedFilters.forEach(currentFilter => {
                                        // Cycle through the EventAuditorFilter objects to determine which options to check
                                        const filterId = currentFilter.filterId;
                                        if (filterId === currentEventFilter.id) {
                                            // Once you find the EventAuditorFilter that matches the current filter, compare the options
                                            const currentFilterOptions = currentFilter.options;
                                            if (currentFilterOptions) {
                                                currentFilterOptions.forEach(option => {
                                                    if (option === currentOption) isSelected = true;
                                                });
                                            }
                                        }
                                    });
                                } else if (currentEventAuditor && hasNoSelectedFilters) {
                                    // If this is a current subscriber who has selected to have 0 filters, set selected to false
                                    isSelected = false; 
                                }
                                const formattedOption: FormattedOption = {
                                    name: currentOption,
                                    isSelected: isSelected
                                };
                                options.push(formattedOption);
                                selectedArray.push(currentOption)
                            }
                        }
                        const formatted: FormattedFilter = {
                            name: currentEventFilter.name || "",
                            isExpanded: false,
                            options: options,
                            filter: currentEventFilter
                        };
                        formattedArray.push(formatted);
                    }
                }
            }
        }
        setFormattedFilters(formattedArray);
        setIsLoading(false);
    }
    // Do this here to set it on mount, but do it again in filter form component
    const formatEventRoleFilters = (currentSelections?: (EventAuditorFilter[] | null)) => {
        let options: FormattedRoleOption[] = [];
        let selectedArray: string[] = [];
        let rolesList = TextAlertRolesFilters;
        if (event?.id === "c9edd954-6a8d-473e-88f3-a03f74e1bb53") rolesList = TextAlertRolesFiltersForPonyFinals;
        else if (event?.organizationId === constants.IHSA_ORGANIZATION.id) rolesList = TextAlertRolesFiltersForIHSAFinals;
        else if (event?.organizationId === "4413e377-8501-46db-a284-6e252b2d82eb") rolesList = TextAlertRolesFiltersForLAECOrg;
        if (rolesList) {
            for (var i = 0; i < rolesList.length; i++) {
                const currentEventFilter: TextAlertRoleFilter = rolesList[i];
                // For the roles filter, we typically will start with none selected and make the person pick
                let isSelected = false; 
                // Check if this is an update, and the person has already selected certain roles
                if (currentSelections) {
                    // Cycle through the EventAuditorFilter objects to determine which options to check
                    currentSelections.forEach(currentSelection => {
                        
                        // If the current selection belongs to the roles filter
                        if (currentSelection.filter?.name === "Roles") {
                            if (currentSelection.options) {
                                currentSelection.options.forEach(option => {
                                    if (option === currentEventFilter.value) isSelected = true;
                                });
                            }
                        }
                    });
                }
                let childrenArray: (FormattedRoleOption[] | null) = null;
                if (currentEventFilter.children) {
                    childrenArray = [];
                    const childArray = currentEventFilter.children;
                    for (var j = 0; j < childArray.length; j++) {
                        const currentEventFilter: TextAlertRoleFilter = childArray[j];
        
                        // For the roles filter, we typically will start with none selected and make the person pick
                        let isChildSelected = false
        
                        // Check if this is an update, and the person has already selected certain roles
                        if (currentSelections) {
        
                            // Cycle through the EventAuditorFilter objects to determine which options to check
                            currentSelections.forEach(currentSelection => {
                                
                                // If the current selection belongs to the roles filter
                                if (currentSelection.filter?.name === "Roles") {
                                    if (currentSelection.options) {
                                        currentSelection.options.forEach(option => {
                                            if (option === currentEventFilter.value) {
                                                isChildSelected = true;
                                                isSelected = true; //Mark parent as selected too
                                            }
                                        });
                                    }
                                }
                            });
                        }
                        const formattedOption: FormattedRoleOption = {
                            name: currentEventFilter.name,
                            value: currentEventFilter.value,
                            isSelected: isChildSelected
                        };
                        childrenArray.push(formattedOption);
                    }
                }
                const formattedOption: FormattedRoleOption = {
                    name: currentEventFilter.name,
                    value: currentEventFilter.value,
                    isSelected: isSelected,
                    children: childrenArray || undefined
                };
                options.push(formattedOption);
                selectedArray.push(currentEventFilter.value);
            }
        }
        setFormattedRoleOptions(options);
    }
    async function getEventFilters(event: Event) {
        const queryResult = await getEventFiltersByEventId(event.id);
        if (queryResult.isSuccess) {
            const eventFiltersList = queryResult.result;
            if (eventFiltersList && eventFiltersList.length > 1) {
                // If we need to include a step for event specific filters, then include an extra page for that
                setTotalSteps(() => totalSteps + 1);
            }
            setEventFilters(eventFiltersList);
            if (selectedFilters) {
                formatEventRoleFilters(selectedFilters);
                formatEventFilters(eventFiltersList, selectedFilters);
            }
            else {
                formatEventFilters(eventFiltersList);
            }
        }
    }
    async function getEventAuditorFilters(eventAuditor: EventAuditor) {
        let eventAuditorFilters: EventAuditorFilter[] = [];
        // First, check if the query for this event auditor also returned the filters
        if (eventAuditor && eventAuditor.filters) {
            const filters: any = eventAuditor.filters;
            if (filters.items) {
                eventAuditorFilters = filters.items;
            }
        }
        // If not, grab the filters from its own query
        if (eventAuditorFilters.length === 0) {
            const queryResult = await getEventAuditorFiltersByEventIdAuditorId(eventAuditor.id);
            if (queryResult.isSuccess) {
                eventAuditorFilters = queryResult.result;
            }
        }
        // Check for found filters and set state 
        if (eventAuditorFilters.length > 0) {
            setSelectedFilters(eventAuditorFilters);
            if (eventFilters) {
                formatEventFilters(eventFilters, eventAuditorFilters);
                formatEventRoleFilters(eventAuditorFilters);
            }
        } else {
            setHasNoSelectedFilters(true);
        }
    }
    function getRestartTextMessageInfo() {
        // Format the string so that it will set up a new text message in iOS or Android
        let msgString = "sms:" + rspPhoneNumber + (isPlatform("ios") ? "&" : "?") + "body:START"; 
        setRestartTextMessageInfo(msgString);
    }
    useEffect(() => {
        async function getEventAlertSetting(event: Event) {
            const queryResult = await getEventAlertSettingByEventId(event.id);
            if (queryResult.isSuccess) {
                const eventAlertSetting: EventAlertSetting = queryResult.result;
                const value = eventAlertSetting.useDescription;
                if (value) {
                    const description = getDescription(value);
                    setUseDescription(description);
                }
            }
        }
        if (event) {
            // If Coveside Stables (Canada) - show a different phone number - TO DO - make all Canadian shows
            if (event.organizationId === constants.Coveside_ORGANIZATION.id || event?.id === constants.European_Classic_Event_Id) setRSPPhoneNumber("+1-866-264-7670");
            getEventAlertSetting(event);
            formatEventRoleFilters();
            getEventFilters(event);
            getRestartTextMessageInfo();
        }
    }, [event]);
    useEffect(() => {
        if (eventAuditor){
            getEventAuditorFilters(eventAuditor);
            setCurrentEventAuditor(eventAuditor);
            setCurrentAuditor(eventAuditor.auditor);
            setPhoneNumber(eventAuditor.phoneNumber || "");
            setPhoneNumberInputValue(eventAuditor.phoneNumber || "");
            setName(eventAuditor.name || "");
            if (eventAuditor.filters) setNavbarState("filter");
        } else {
            setHasNoSelectedFilters(false);
        }
    }, [eventAuditor]);

    const checkEventAuditorStatus = (eventAuditor: EventAuditor) => {
        if (eventAuditor.status === "stopped") {
            const errorMessage = "This phone number previously responded STOP. You must send START to the RingSide Pro phone number before you can receive new messages: " + rspPhoneNumber;
            setError(errorMessage);
            setDisplayLinkToStartTexts(true);
            return false;
        }
        return true;
    }
    const checkAuditorStatus = (auditor: Auditor) => {
        if (auditor.status === "stopped") {
            const errorMessage = "This phone number previously responded STOP. You must send START to the RingSide Pro phone number before you can receive new messages: " + rspPhoneNumber;
            setError(errorMessage);
            setDisplayLinkToStartTexts(true);
            return false;
        }
        return true;
    }
    const checkPhoneNumber = async () => {
        setIsLoading(true);
        setError("");
        // First, check if there is already an Event Auditor with this number
        const queryEventAuditorResult = await getEventAuditorWithFiltersByPhoneNumber(phoneNumber, event.id);
        if (queryEventAuditorResult.isSuccess) {
            // Event Auditor already exists, set the currentEventAuditor and make an update operation
            const eventAuditor: EventAuditor = queryEventAuditorResult.result;
            setCurrentEventAuditor(eventAuditor);
            // Tell parent component the eventAuditor was found - changes "Add Subscriber" to "Edit Subscriber" wording
            if (onFindCurrentEventAuditor) onFindCurrentEventAuditor(eventAuditor);
            // Check the eventAuditor status - if not verified to receive alerts, prompt the user to verify
            let statusResult = checkEventAuditorStatus(eventAuditor);
            if (!statusResult) {
                setIsLoading(false);
                return false;
            }
            // Check the Auditor status - if not verified to receive alerts, prompt the user to verify
            setCurrentAuditor(eventAuditor.auditor);
            if (eventAuditor.auditor) {
                statusResult = checkAuditorStatus(eventAuditor.auditor);
                if (!statusResult) {
                    setIsLoading(false);
                    return false;
                }
            }
            
            // Set the other info for this eventAuditor
            setName(eventAuditor.name || "");
            await getEventAuditorFilters(eventAuditor);            
            handleChangeStep(1, 2);
            if (eventAuditor.filters) {
                setNavbarState("filter");
            }
            setIsLoading(false);
            return true;
        } else {
            // Event Auditor does not already exist, set the currentEventAuditor to null
            // Handles case where user completes step 1 with existing phone number, goes back, re-does step 1 with new phone number
            setCurrentEventAuditor(null);
            setCurrentAuditor(null);
            setName("");
            setSelectedFilters(null);
            // Tell parent component the eventAuditor was NOT found - changes "Edit Subscriber" to "Add Subscriber" wording
            if (onFindCurrentEventAuditor) onFindCurrentEventAuditor();
        }
        // Second, verify there is not already an Auditor with this number
        const queryAuditorResult = await getAuditorByPhoneNumber(phoneNumber);
        if (queryAuditorResult.isSuccess) {
            // Event Auditor already exists, set the currentEventAuditor and make an update operation
            const auditor: Auditor = queryAuditorResult.result;
            setCurrentAuditor(auditor);
            setName(auditor.name || "");
            // Check the Auditor status - if not verified to receive alerts, prompt the user to verify
            let statusResult = checkAuditorStatus(auditor);
            if (!statusResult) {
                setIsLoading(false);
                return false;
            }
            
            handleChangeStep(1, 2);
            setIsLoading(false);
            return true;
        }
        // Third, check that this is a possible format for a phone number
        try {
            const isValidFormat = isPossiblePhoneNumber(phoneNumber);
            if (!isValidFormat) {
                setError("The phone number format is invalid.");
                setIsLoading(false);
                return false;
            }
        } catch (error: any) {
            setIsLoading(false);
            return false;
        }
        // Fourth, verify there is not a person with this phone number as a contact
        // TO DO
       
        // Fifth, this is definitely a new phone number, verify it is a cell number //TO DO - fix canadian numbers
        const lookupResult = await lookupPhoneNumber(phoneNumber, true);
        if (lookupResult.isSuccess) {
            const carrier = lookupResult.result;
            if (carrier && carrier.type) {
                const type = carrier?.type;
                if (type !== "mobile") {
                    setError("Could not confirm this phone number is a cell phone number. It cannot be added.");
                    setIsLoading(false);
                    return false;
                }
            } else {
                setError("Could not confirm this phone number is a cell phone number. It cannot be added.");
                setIsLoading(false);
                return false;
            }
        } else {
            setError("Could not confirm this phone number is a cell phone number. It cannot be added.");
            setIsLoading(false);
            return false;
        }
        // Lastly, phone is valid, move on to step 2
        handleChangeStep(1, 2);
        setIsLoading(false);
    }
    const checkPhoneNumberForShortenedForm = async () => {
        setError("");
        // First, check if there is already an Event Auditor with this number
        const queryEventAuditorResult = await getEventAuditorByPhoneNumber(phoneNumber, event.id);
        if (queryEventAuditorResult.isSuccess) {
            // Event Auditor already exists, set the currentEventAuditor and make an update operation
            const eventAuditor: EventAuditor = queryEventAuditorResult.result;
            setCurrentEventAuditor(eventAuditor);
            // Tell parent component the eventAuditor was found - changes "Add Subscriber" to "Edit Subscriber" wording
            if (onFindCurrentEventAuditor) onFindCurrentEventAuditor(eventAuditor);
            // Check the eventAuditor status - if not verified to receive alerts, prompt the user to verify
            let statusResult = checkEventAuditorStatus(eventAuditor);
            if (!statusResult) {
                return false;
            }
            // Check the Auditor status - if not verified to receive alerts, prompt the user to verify
            setCurrentAuditor(eventAuditor.auditor);
            if (eventAuditor.auditor) {
                statusResult = checkAuditorStatus(eventAuditor.auditor);
                if (!statusResult) {
                    return false;
                }
            }
            
            // Set the other info for this eventAuditor
            setName(eventAuditor.name || "");          
            return true;
        } else {
            // Event Auditor does not already exist, set the currentEventAuditor to null
            setCurrentEventAuditor(null);
            setCurrentAuditor(null);
            setName("");
            setSelectedFilters(null);
            // Tell parent component the eventAuditor was NOT found - changes "Edit Subscriber" to "Add Subscriber" wording
            if (onFindCurrentEventAuditor) onFindCurrentEventAuditor();
        }
        // Second, verify there is not already an Auditor with this number
        const queryAuditorResult = await getAuditorByPhoneNumber(phoneNumber);
        if (queryAuditorResult.isSuccess) {
            // Event Auditor already exists, set the currentEventAuditor and make an update operation
            const auditor: Auditor = queryAuditorResult.result;
            setCurrentAuditor(auditor);
            // Check the Auditor status - if not verified to receive alerts, prompt the user to verify
            let statusResult = checkAuditorStatus(auditor);
            if (!statusResult) {
                return false;
            }
            return true;
        }
        // Third, check that this is a possible format for a phone number
        try {
            const isValidFormat = isPossiblePhoneNumber(phoneNumber);
            if (!isValidFormat) {
                setError("The phone number format is invalid.");
                return false;
            }
        } catch (error: any) {
            return false;
        }
       
        // Next, this is definitely a new phone number, verify it is a cell number //TO DO - fix canadian numbers
        const lookupResult = await lookupPhoneNumber(phoneNumber, true);
        if (lookupResult.isSuccess) {
            const carrier = lookupResult.result;
            if (carrier && carrier.type) {
                const type = carrier?.type;
                if (type !== "mobile") {
                    setError("Could not confirm this phone number is a cell phone number. It cannot be added.");
                    return false;
                }
            } else {
                setError("Could not confirm this phone number is a cell phone number. It cannot be added.");
                return false;
            }
        } else {
            setError("Could not confirm this phone number is a cell phone number. It cannot be added.");
            return false;
        }
        return true;
    }

    const handleFilterSelect = (formattedFilters: FormattedFilter[]) => {
        setError("");
        setFormattedFilters(formattedFilters);
    }
    const handleRoleFilterSelect = (formattedOptions: FormattedRoleOption[]) => {
        setError("");
        setFormattedRoleOptions(formattedOptions);
    }
    const handleCreateEventAuditorFilters = async (eventAuditor: EventAuditor) => {
        if (navbarState === "all") {
            // User is on "Get All Alerts" page, add all filters
            if (eventFilters) {
                // Will reset the formattedFilters array to have all options selected
                formatEventFilters(eventFilters);
            }
        }
        if (formattedFilters && formattedFilters.length > 0) {
            for (var i = 0; i < formattedFilters.length; i++) {
                const currentFilter = formattedFilters[i];
                let selectedOptions: string[] = [];
                currentFilter.options.forEach(element => {
                    if (element.isSelected) selectedOptions.push(element.name);
                });
                if (selectedOptions && selectedOptions.length > 0) {
                    const eventAuditorFilterInput: CreateEventAuditorFilterInput = {
                        eventId: event.id,
                        eventAuditorId: eventAuditor.id,
                        filterId: currentFilter.filter.id,
                        options: selectedOptions,
                        createdBy: user.id
                    };
                    await createEventAuditorFilter(eventAuditorFilterInput);
                }
            }
        }
    }
    const handleRemoveEventAuditorFilters = async () => {
        // Will remove role filters + other event filters
        let didClearFilters = true;
        if (selectedFilters) {
            for (var i = 0; i < selectedFilters.length; i++) {
                const current = selectedFilters[i];
                const deleteResult = await deleteEventAuditorFilter({id: current.id});
                if (!deleteResult.isSuccess) {
                    didClearFilters = false;
                }
            }
        }
        return didClearFilters;
    }
    const handleCreateEventAuditorRoleFilters = async (eventId: string, newEventAuditor: EventAuditor) => {
        let currentEventFilters: (EventFilter[] | undefined) = undefined;
        // Make sure that the filter you use matches the event (could be a horse show event or the GITNB event)
        const queryResult = await getEventFiltersByEventId(eventId);
        if (queryResult.isSuccess) {
            currentEventFilters = queryResult.result;
        }
        if (currentEventFilters) {
            const roleFilter: (EventFilter | undefined) = currentEventFilters.find(eventFilter => eventFilter.name === "Roles");
            if (roleFilter) {
                
                let selectedOptions: string[] = [];
                if (formattedRoleOptions && formattedRoleOptions.length > 0) {
                    for (var i = 0; i < formattedRoleOptions.length; i++) {
                        const currentRoleOption: FormattedRoleOption = formattedRoleOptions[i];
                        if (currentRoleOption.isSelected) {
                            if (currentRoleOption.children) {
                                currentRoleOption.children.forEach(child => {
                                    if (child.isSelected) {
                                        selectedOptions.push(child.value);
                                    }
                                });
                            } else {
                                selectedOptions.push(currentRoleOption.value);
                            }
                        }
                    }
                }
        
                const eventAuditorFilterInput: CreateEventAuditorFilterInput = {
                    eventId: eventId,
                    eventAuditorId: newEventAuditor.id,
                    filterId: roleFilter.id,
                    options: selectedOptions,
                    createdBy: user.id
                };
                await createEventAuditorFilter(eventAuditorFilterInput);
            } 
            const methodFilter: (EventFilter | undefined) = currentEventFilters.find(eventFilter => eventFilter.name?.includes("Sign Up"));
            if (methodFilter) {        
                const eventAuditorFilterInput: CreateEventAuditorFilterInput = {
                    eventId: eventId,
                    // eventAuditorFilterEventId: eventId,
                    eventAuditorId: newEventAuditor.id,
                    // eventAuditorFilterEventAuditorId: newEventAuditor.id,
                    filterId: methodFilter.id,
                    // eventAuditorFilterFilterId: methodFilter.id,
                    options: [event.name.includes("Green ProTips") ? "RingSide Pro Website" : event.name],
                    createdBy: user.id
                };
                await createEventAuditorFilter(eventAuditorFilterInput);
            } 
        }
    }

    const handleCreateEventAuditor = async () => {
        let auditor = currentAuditor;
        let eventAuditor: EventAuditor | null = null;
        let isNewAuditor = false;
        if (!auditor) {
            // Create a new auditor
            const auditorInput: CreateAuditorInput = {
                name: name,
                phoneNumber: phoneNumber,
                status: AuditorStatus.verified,
                createdBy: user.id
            }
            const createAuditorResult = await createAuditor(auditorInput);
            if (createAuditorResult.isSuccess) {
                auditor = createAuditorResult.result;
                isNewAuditor = true;
                setCurrentAuditor(auditor);
            } else {
                setError("Could not create the auditor.");
            }
        } 
        if (auditor) {
            // Create a new event auditor
            const eventAuditorInput: CreateEventAuditorInput = {
                eventId: event.id,
                eventAuditorEventId: event.id,
                auditorId: auditor.id,
                name: name,
                phoneNumber: phoneNumber,
                status: "verified",
                createdBy: user.id
            };
            const createResult = await createEventAuditor(eventAuditorInput);
            eventAuditor = createResult.result;
            if (createResult.isSuccess) {
                const newlyCreatedEventAuditor = createResult.result;
                setCurrentEventAuditor(newlyCreatedEventAuditor);
                // Create the role filters for this event auditor
                await handleCreateEventAuditorRoleFilters(event.id, newlyCreatedEventAuditor);
                // Create the filters for this event auditor
                await handleCreateEventAuditorFilters(newlyCreatedEventAuditor);
                
                // Update the event auditor now that it has it's filters
                const queryResult = await getEventAuditorById(newlyCreatedEventAuditor.id);
                if (queryResult.isSuccess) {
                    eventAuditor = queryResult.result;
                }

                // Make note in hubspot
                if (user && user.email) {
                    const note = "User signed up for text alerts for the event: " + event.name;
                    addNoteToHubspot(user.email, note);
                }

                if (event.id !== gitnbEventId) {
                    // Send a welcome message
                    const sendSMSConfirmationMessagesResult = await sendRSPWelcomeTextsForEvent(auditor, event, isAdminView, isNewAuditor);
                    if (!sendSMSConfirmationMessagesResult.isSuccess) {
                        setError(sendSMSConfirmationMessagesResult.message);
                        if (sendSMSConfirmationMessagesResult.message.includes("text START to +1-757-210-6404")) {
                            setDisplayLinkToStartTexts(true);
                        }
                        setIsMainLoading(false);
                        return;
                    }
                } else if (event) {
                    // Send a welcome message
                    const sendSMSConfirmationMessagesResult = await sendGITNBWelcomeTexts(auditor);
                    if (!sendSMSConfirmationMessagesResult.isSuccess) {
                        setError(sendSMSConfirmationMessagesResult.message);
                        setIsMainLoading(false);
                        return;
                    }
                }
                if (onCreate && eventAuditor) {
                    onCreate(eventAuditor);
                }
                setIsMainLoading(false);
            } else {
                setError("Could not create the event auditor.");
                setIsMainLoading(false);
            }
        } else {
            setIsMainLoading(false);
        }
    }
    const handleUpdateEventAuditor = async () => {
        if (currentAuditor) {
            // May need to update auditor
            const auditorInput: UpdateAuditorInput = {
                id: currentAuditor.id,
                name: name,
                phoneNumber: phoneNumber,
                status: AuditorStatus.verified,
                lastUpdatedBy: user.id
            };
            const updateAuditorResult = await updateAuditor(auditorInput);
            if (updateAuditorResult.isSuccess) {
                const auditor = updateAuditorResult.result;
                setCurrentAuditor(updateAuditorResult.result);
                if (currentEventAuditor) {
                    const eventAuditorInput: UpdateEventAuditorInput = {
                        id: currentEventAuditor.id,
                        eventId: event.id,
                        auditorId: auditor.id,
                        name: name,
                        phoneNumber: phoneNumber,
                        status: "verified",
                        createdBy: user.id
                    };
                    const updateResult = await updateEventAuditor(eventAuditorInput);
                    if (updateResult.isSuccess) {
                        // First, remove the old selected filters
                        const didSuccessfullyClearFilters = await handleRemoveEventAuditorFilters();
                        if (didSuccessfullyClearFilters) {
                            const newlyUpdatedEventAuditor: EventAuditor = updateResult.result;
                            // Create the role filters for this event auditor
                            await handleCreateEventAuditorRoleFilters(event.id, newlyUpdatedEventAuditor);
                            
                            // Create the filters for this event auditor
                            await handleCreateEventAuditorFilters(newlyUpdatedEventAuditor);
                        } else {
                            setError("Sorry, we were not able to update your filters.");
                        }
                        if (event.id === gitnbEventId) {
                            // if GITNB event, send a gitnb welcome message
                            const sendSMSConfirmationMessagesResult = await sendGITNBWelcomeTexts(auditor);
                            if (!sendSMSConfirmationMessagesResult.isSuccess) {
                                setError(sendSMSConfirmationMessagesResult.message);
                                setIsMainLoading(false);
                                return;
                            }
                        }
                        // Make note in hubspot
                        if (user && user.email) {
                            const note = "User updated their text alerts for the event: " + event.name;
                            addNoteToHubspot(user.email, note);
                        }
                        // Send an update message
                        if (willSendUpdateAlert) { 
                            const sendSMSUpdateMessagesResult = await sendRSPUpdateTextsForEvent(auditor, event, isAdminView);
                            if (!sendSMSUpdateMessagesResult.isSuccess) {
                                setError(sendSMSUpdateMessagesResult.message);
                                if (sendSMSUpdateMessagesResult.message.includes("text START to +1-757-210-6404")) {
                                    setDisplayLinkToStartTexts(true);
                                }
                            }
                        }
                        
                        if (onEdit) onEdit(updateResult.result);
                        setIsMainLoading(false);
                    } else {
                        setError("Could not update the event auditor.");
                        setIsMainLoading(false);
                    }
                } else {
                    setError("Could not save the event auditor.");
                    setIsMainLoading(false);
                }
            } else {
                setError("Could not save the auditor.");
                setIsMainLoading(false);
            }
        }  else {
            setError("No current auditor.");
            setIsMainLoading(false);
        }
    }
    const handleRemoveEventAuditor = async () => {
        if (currentEventAuditor) {
            setIsMainLoading(true);
            const deleteInput = {id: currentEventAuditor.id};
            const deleteResult = await deleteEventAuditor(deleteInput);
            if (deleteResult.isSuccess) {
                await handleRemoveEventAuditorFilters();
                if (onDelete) onDelete(deleteResult.result);
                setIsMainLoading(false);
            } else {
                setError("Could not remove this subscriber from the event.");
                console.error("Delete Result failed: ", deleteResult.result);
            }
        } 
    }
    const handleSubmit = async () => {
        setIsMainLoading(true);
        if (currentEventAuditor) {
            if (navbarState === "stop") {
                await handleRemoveEventAuditor();
            } else {
                await handleUpdateEventAuditor();
            }
        } else {
            await handleCreateEventAuditor();
        }
    }
    const handleChangeStep = (current: number, next: number) => {
        setCurrentStep(next);
    }
    const handleButtonClick = async () => {
        setDisplayLinkToStartTexts(false);
        if (currentStep === 1) {
            if (!phoneNumber) {
                setError("Error: You did not include a phone number.");
                return;
            }
            await checkPhoneNumber();
        } else if (currentStep === 2) {
            if (!name) {
                setError("Error: You must include a name so that we know who has signed up for alerts.");
                return;
            }
            handleChangeStep(2, 3);
        } else if (currentStep === 3) {
            let selectedAtLeastOneRole = false; 
            if (formattedRoleOptions && formattedRoleOptions.length) {
                formattedRoleOptions.forEach(option => {
                    if (option.children) {
                        option.children.forEach(child => {
                            if (child.isSelected) selectedAtLeastOneRole = true;
                        })
                    } else {
                        if (option.isSelected) selectedAtLeastOneRole = true;
                    }
                })
            }
            if (!selectedAtLeastOneRole) {
                setError("Error: You must select at least one of the options.");
                return;
            }
            handleChangeStep(3, 4);
        } else if (totalSteps === 4 && currentStep === 4) {
            handleSubmit();
        } else if (totalSteps === 5 && currentStep === 4) {
            handleChangeStep(4, 5);
        } else if (currentStep === 5) {
            handleSubmit();
        } else {
            console.error("Error! Current step: ", currentStep);
        }
    }
    const handleShortenedFormSubmit = async () => {
        setIsMainLoading(true);
        // Validate form
        if (!phoneNumber) {
            setError("Error: You must include a valid phone number.");
            return;
        }
        if (!name) {
            setError("Error: You must include a name so that event staff can send alerts to the correct people.");
            return;
        }
        // Check if there is already an event auditor
        const phoneCheckStatus = await checkPhoneNumberForShortenedForm();
        if (phoneCheckStatus) {
            // Then either update it or create a new event auditor
            if (currentEventAuditor) {
                let auditor = currentAuditor || currentEventAuditor.auditor;
                if (!auditor) {
                    const queryAuditorResult = await getAuditorByPhoneNumber(phoneNumber);
                    if (queryAuditorResult.isSuccess) {
                        // Event Auditor already exists, set the currentEventAuditor and make an update operation
                        auditor = queryAuditorResult.result;
                        setCurrentAuditor(auditor);
                    }
                }
                if (auditor) {
                    // May need to update auditor
                    const auditorInput: UpdateAuditorInput = {
                        id: auditor.id,
                        name: name,
                        phoneNumber: phoneNumber,
                        status: AuditorStatus.verified,
                        lastUpdatedBy: user.id
                    };
                    const updateAuditorResult = await updateAuditor(auditorInput);
                    if (updateAuditorResult.isSuccess) {
                        const auditor = updateAuditorResult.result;
                        setCurrentAuditor(updateAuditorResult.result);
                        if (currentEventAuditor) {
                            const eventAuditorInput: UpdateEventAuditorInput = {
                                id: currentEventAuditor.id,
                                eventId: event.id,
                                auditorId: auditor.id,
                                name: name,
                                phoneNumber: phoneNumber,
                                status: "verified",
                                createdBy: user.id
                            };
                            const updateResult = await updateEventAuditor(eventAuditorInput);
                            if (updateResult.isSuccess) {
                                // Send an update message
                                if (willSendUpdateAlert) { 
                                    const sendSMSUpdateMessagesResult = await sendRSPUpdateTextsForEvent(auditor, event, isAdminView);
                                    if (!sendSMSUpdateMessagesResult.isSuccess) {
                                        setError(sendSMSUpdateMessagesResult.message);
                                        if (sendSMSUpdateMessagesResult.message.includes("text START to +1-757-210-6404")) {
                                            setDisplayLinkToStartTexts(true);
                                        }
                                        setIsMainLoading(false);
                                        return;
                                    }
                                }
        
                                if (onEdit) onEdit(updateResult.result);
                                setIsMainLoading(false);
                            } else {
                                setError("Could not update the event auditor.");
                                setIsMainLoading(false);
                            }
                        } else {
                            setError("Could not save the event auditor.");
                            setIsMainLoading(false);
                        }
                    } else {
                        setError("Could not save the auditor.");
                        setIsMainLoading(false);
                    }
                }  else {
                    setError("No current auditor.");
                    setIsMainLoading(false);
                }
            } else {
                let auditor = currentAuditor;
                let eventAuditor: EventAuditor | null = null;
                let isNewAuditor = false;
                if (!auditor) {
                    // Create a new auditor
                    const auditorInput: CreateAuditorInput = {
                        name: name,
                        phoneNumber: phoneNumber,
                        status: AuditorStatus.verified,
                        createdBy: user.id
                    }
                    const createAuditorResult = await createAuditor(auditorInput);
                    if (createAuditorResult.isSuccess) {
                        auditor = createAuditorResult.result;
                        isNewAuditor = true;
                        setCurrentAuditor(auditor);
                    } else {
                        setError("Could not create the auditor.");
                    }
                } 
                if (auditor) {
                    // Create a new event auditor
                    const eventAuditorInput: CreateEventAuditorInput = {
                        eventId: event.id,
                        eventAuditorEventId: event.id,
                        auditorId: auditor.id,
                        name: name,
                        phoneNumber: phoneNumber,
                        status: "verified",
                        createdBy: user.id
                    };
                    const createResult = await createEventAuditor(eventAuditorInput);
                    eventAuditor = createResult.result;
                    if (createResult.isSuccess) {
                        const newlyCreatedEventAuditor = createResult.result;
                        setCurrentEventAuditor(newlyCreatedEventAuditor);
                        
                        // Update the event auditor now that it has it's filters
                        const queryResult = await getEventAuditorById(newlyCreatedEventAuditor.id);
                        if (queryResult.isSuccess) {
                            eventAuditor = queryResult.result;
                        }
                        // Send a welcome message
                        const sendSMSConfirmationMessagesResult = await sendRSPWelcomeTextsForEvent(auditor, event, isAdminView, isNewAuditor);
                        if (!sendSMSConfirmationMessagesResult.isSuccess) {
                            setError(sendSMSConfirmationMessagesResult.message);
                            if (sendSMSConfirmationMessagesResult.message.includes("text START to +1-757-210-6404")) {
                                setDisplayLinkToStartTexts(true);
                            }
                            setIsMainLoading(false);
                            return;
                        }
                        if (onCreate && eventAuditor) {
                            onCreate(eventAuditor);
                        }
                        setIsMainLoading(false);
                    } else {
                        setError("Could not create the event auditor.");
                        setIsMainLoading(false);
                    }
                } else {
                    setIsMainLoading(false);
                }
            }
        } else {
            setIsMainLoading(false);
        }
    }
    return (
        <>
            {(isMainLoading || isLoading) ?
                <Spinner />
                :
                <>
                    {(event.organizationId === constants.SHOT_ORGANIZATION.id) ?
                        <form>
                            {error && <ErrorAlert width="12" error={error} />}
                            {(displayLinkToStartTexts && (isPlatform('ios') || isPlatform('android'))) && (
                                <IonRow className="ion-justify-content-center">
                                    <IonCol className="ion-text-center">
                                        <a className="link" href={restartTextMessageInfo}>Click Here to START messages.</a>
                                    </IonCol>
                                </IonRow>
                            )}
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="8">
                                    <IonLabel position="stacked"><p className="description text-primary ml-3">Phone Number <RequiredInputIndicator showWordRequired/></p></IonLabel>
                                    <PhoneInput
                                        placeholder="Enter phone number"
                                        defaultCountry="US"
                                        withDarkTheme={false}
                                        withShadow={false}
                                        autoFocus
                                        inputStyle={{
                                            background: "white"
                                        }}
                                        containerStyle={{ backgroundColor: 'white' }}
                                        inputClass="bg-white"
                                        value={phoneNumberInputValue}
                                        onChange={(phoneNumberValue?: any) => {
                                            setError("");
                                            setPhoneNumber(phoneNumberValue ? phoneNumberValue.toString() : "");
                                            setPhoneNumberInputValue(phoneNumberValue);
                                        }}
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center mt-4">
                                <IonCol sizeXs="12" sizeMd="8">
                                    <IonLabel position="stacked"><p className="description text-primary ml-3">Name <RequiredInputIndicator showWordRequired/></p></IonLabel>
                                    <Input
                                        type="text"
                                        value={name}
                                        aria-required={true}
                                        onChange={e => {
                                            setError("");
                                            setName(e.target.value);
                                        }}
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="12">
                                    {isLoading ?
                                        <Spinner />
                                        :
                                        <IonButton
                                            className="ion-margin-top"
                                            color="success"
                                            disabled={!!error}
                                            expand="block"
                                            onClick={() => handleShortenedFormSubmit()}
                                        >
                                            <p className="ion-text-wrap">
                                                Submit
                                            </p>
                                        </IonButton>
                                    }
                                </IonCol>
                            </IonRow>
                        </form>
                        :
                        <form>
                            {error && <ErrorAlert width="12" error={error} />}
                            {(displayLinkToStartTexts && (isPlatform('ios') || isPlatform('android'))) && (
                                <IonRow className="ion-justify-content-center">
                                    <IonCol className="ion-text-center">
                                        <a className="link" href={restartTextMessageInfo}>Click Here to START messages.</a>
                                    </IonCol>
                                </IonRow>
                            )}
                            <IonRow className="ion-justify-content-center">
                                <IonCol className="ion-text-center">
                                    <p className="text-primary">Step {currentStep} of {totalSteps}</p>
                                </IonCol>
                            </IonRow>
                            {currentStep === 1 && (
                                <>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol sizeXs="12" sizeMd="8">
                                            <IonLabel position="stacked" className="description">Phone Number <RequiredInputIndicator showWordRequired/></IonLabel>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol sizeXs="12" sizeMd="8" className="ion-text-center">
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                defaultCountry="US"
                                                withDarkTheme={false}
                                                withShadow={false}
                                                autoFocus
                                                inputStyle={{
                                                    background: "white"
                                                }}
                                                containerStyle={{ backgroundColor: 'white' }}
                                                inputClass="bg-white"
                                                value={phoneNumberInputValue}
                                                onChange={(phoneNumberValue?: any) => {
                                                    setError("");
                                                    setPhoneNumber(phoneNumberValue ? phoneNumberValue.toString() : "");
                                                    setPhoneNumberInputValue(phoneNumberValue);
                                                }}
                                            />
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {currentStep === 2 && (
                                <>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">Name <RequiredInputIndicator showWordRequired/></IonLabel>
                                                <IonInput 
                                                    type="text"
                                                    value={name}
                                                    aria-required={true}
                                                    onIonChange={e => {
                                                        setError("");
                                                        setName(e.detail.value!);
                                                    }}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {currentStep === 3 && (
                                <>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol size="12" className="ion-text-center">
                                            <p className="description">(Select at least one)</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol size="12" className="ion-text-center">
                                            <h4>I am a ... </h4>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="pt-1 ion-justify-content-center scrollable">
                                        <IonCol size="12">
                                            <SelectEventFilterRolesOptionForm startWithoutSelections={true} isExpandedOnStart={true} currentSelectedFilters={selectedFilters} onSelect={handleRoleFilterSelect} />
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {totalSteps === 5 && currentStep === 4 && (
                                <>
                                    <IonRow className="pt-1 ion-justify-content-center">
                                        <IonCol size="12" className="ion-text-center">
                                            <p>Select one:</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="pt-1 ion-justify-content-center">
                                        <IonCol size="12" className="ion-text-center">
                                            <AlertsChoiceNavbar active={navbarState} onSelect={(selected: ("all" | "filter" | "stop")) => setNavbarState(selected)}  />
                                        </IonCol>
                                    </IonRow>
                                    {navbarState === "all" && (
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="6" className="ion-text-center">
                                                <p className="description">You will receive all alerts for this event.</p>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                    {navbarState === "filter" && 
                                        <SelectEventFiltersForm event={event} currentSelectedFilters={selectedFilters} startWithoutSelections={true} isExpandedOnStart={true} onSelect={handleFilterSelect} />
                                    }
                                    {navbarState === "stop" && (
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="6" className="ion-text-center">
                                                <p className="description">You will stop receiving text alerts for this event.</p>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                </>
                            )}
                            {(displayLinkToStartTexts && (isPlatform('ios') || isPlatform('android'))) && (
                                <IonRow className="ion-justify-content-center">
                                    <IonCol className="ion-text-center">
                                        <a className="link" href={restartTextMessageInfo}>Click Here to START messages.</a>
                                    </IonCol>
                                </IonRow>
                            )}
                            <IonRow className="ion-justify-content-center">
                                {currentStep !== 1 && (
                                    <IonCol size="6">
                                        {isLoading ?
                                            <></>
                                            :
                                            <IonButton
                                                className="ion-margin-top"
                                                color="light"
                                                expand="block"
                                                onClick={() => {setCurrentStep((prevState) => prevState-1); setError("")}}
                                            >
                                                Go Back 
                                            </IonButton>
                                        }
                                    </IonCol>
                                )}
                                <IonCol size="6">
                                    {isLoading ?
                                        <Spinner />
                                        :
                                        <IonButton
                                            className="ion-margin-top"
                                            color={(currentStep === 5 || (currentStep === 4 && totalSteps === 4)) ? "success" : (navbarState === "stop" ? "danger" : "tertiary")}
                                            disabled={!!error}
                                            expand="block"
                                            onClick={() => handleButtonClick()}
                                        >
                                            <p className="ion-text-wrap">
                                                {(currentStep === 5 || (currentStep === 4 && totalSteps === 4)) ? "Complete " + (eventAuditor ? "Update" : (navbarState === "stop" ? "Cancel" : "Sign Up")) : "Next"}
                                            </p>
                                        </IonButton>
                                    }
                                </IonCol>
                            </IonRow>
                            {isAdminView && currentEventAuditor && (currentStep === 1 || currentStep === 5 || (currentStep === 4 && totalSteps === 4)) && (
                                <>
                                    <IonRow className="pt-2 ion-justify-content-center">
                                        <IonCol size={((currentStep === 5) || (currentStep === 4 && totalSteps === 4)) ? "12" : "6"}>
                                            {isLoading ?
                                                <Spinner />
                                                :
                                                <>
                                                    <IonCheckbox 
                                                        checked={willSendUpdateAlert}
                                                        onIonChange={(e) =>
                                                            setWillSendUpdateAlert(e.detail.checked)
                                                        }
                                                    />
                                                    <IonLabel>Send an alert to the subscriber letting them know their settings have been updated?</IonLabel>
                                                </>
                                            }
                                        </IonCol>
                                    </IonRow>
                                    <div className="pt-3">
                                        <hr />
                                    </div>
                                    <IonRow className="ion-justify-content-center pt-5">
                                        <IonCol size="12" className="ion-text-center">
                                            <p className="description">You can remove a subscriber and all of it's data. This cannot be undone.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                            {isLoading ?
                                                <Spinner />
                                                :
                                                <IonButton
                                                    className="ion-margin-top"
                                                    color="danger"
                                                    disabled={!!error}
                                                    expand="block"
                                                    onClick={() => handleRemoveEventAuditor()}
                                                >
                                                    <p className="ion-text-wrap">
                                                        Remove Subscriber
                                                    </p>
                                                </IonButton>
                                            }
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            <hr/>
                            {currentStep === 1 && (
                                <>
                                    {useDescription && (
                                        <InformationBanner info={"Sign up to get: " + useDescription}/>
                                    )}
                                </>
                            )}
                            {(((currentStep === 5) || (currentStep === 4 && totalSteps === 4)) && !(navbarState === "stop")) && (
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="12" className="ion-text-center">
                                        <IonText><p className="description">You'll receive a confirmation text message after you submit.</p></IonText>
                                        <InformationBanner info={event.eventOptions?.acceptsIncomingTextAlerts ? "You can respond to the messages with questions for the event staff" : "Note: the messages are automatted. You cannot respond to the messages."} />
                                    </IonCol>
                                </IonRow>
                            )}
                            <p className="ion-text-center description">Need help? Contact us at hello@ringsidepro.com.</p>
                        </form>
                    }
                </>
            }
        </>
    );
};
export default EventAuditorForm;