import "../../theme/select.css";
import { EventEntry, Rider } from "../../models";
import React, { useEffect, useState } from "react";
import Select from 'react-select';

interface _Props {
    entry: EventEntry
    selectedValue?: string
    onSelect: Function
}

interface formattedOption {
    label: string
    value: string
    object: Rider
}

const SelectRiderFromEntry: React.FC<_Props> = ({entry, selectedValue, onSelect}) => {

    const [options, setOptions] = useState<formattedOption[] | null | undefined>();
    const [selected, setSelected] = useState<formattedOption | null | undefined>();

    const formatOptions = (entry: EventEntry) => {
        let formattedOptions: formattedOption[] = [];
        if (entry.rider) {
            let formatted: formattedOption = {
                value: entry.rider.id,
                label: entry.rider.name,
                object: entry.rider
            };
            formattedOptions.push(formatted);
        }
        if (entry.secondRider) {
            let formatted: formattedOption = {
                value: entry.secondRider.id,
                label: entry.secondRider.name,
                object: entry.secondRider
            };
            formattedOptions.push(formatted);
        }
        setOptions(formattedOptions);
        return formattedOptions;
    }

    const findSelectedValueInList = (selectedValue: string, optionArray?: formattedOption[]) => {
        const values = optionArray || options;
        if (values) {
            values.forEach(option => {
                if (option.value === selectedValue) {
                    setSelected(option);
                }
            });
        }
    }

    useEffect(() => {
        if (entry) {
            const result = formatOptions(entry);
            if (selectedValue) findSelectedValueInList(selectedValue, result);
            else {
                setSelected(undefined)
            }
        }
        
    }, [entry]);

    useEffect(() => {
        if (selectedValue && options) findSelectedValueInList(selectedValue)
    }, [selectedValue]);

    const handleOnChange = (event: any) => {
        if (event && event.object) {
            onSelect(event.object);
        }
    }

    return (
        <>
            <Select
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                defaultValue={selected}
                value={selected}
                menuPortalTarget={document.body}
                isClearable
                options={options!}
                onChange={handleOnChange}
            />
        </>
    );
};

export default SelectRiderFromEntry;