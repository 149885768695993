export function getDisciplineFromName(name: string) {
    name = name.toLowerCase();
    if (name.includes("hunter")) {
        if (name.includes("equitation") || name.includes("eq")) {
            return "hunter_equitation";
        }
        if (name.includes("breeding")) {
            return "hunter_breeding";
        } 
        else return "hunter";
    }
    else if (name.includes("jumper")) {
        if (name.includes("equitation") || name.includes("eq")) {
            return "jumping_seat_equitation";
        }
        else return "jumper";
    } else if (name.includes("equitation") || name.includes("eq")) {
        return "hunter_equitation";
    }
    else return "";
};

export function getFormattedDisciplineString(disciplineString: string) {
    let array = disciplineString.split(";");

    if (array[array.length - 1] === "") array = array.slice(0, array.length-1); 

    let result = "";

    for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if (i !== (array.length-1)) result = result + element.replaceAll("_", " ") + ", ";
        else result = result + element.replaceAll("_", " ")
    }

    return result;
}