import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event} from "../../../../../../models";
import Header from "../../../../../../components/Headers/Header";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../../utilities/events/Event";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import EventStallTypeForm from "../../../../../../components/Stalls/EventStallTypeForm";
import EventStallSetForm from "../../../../../../components/Stalls/EventStallSetForm";
import EventBeddingTypeForm from "../../../../../../components/Stalls/EventBeddingTypeForm";
import ErrorAlert from "../../../../../../components/Errors/ErrorAlert";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventStallSettingsPage: React.FC<EventPageProps> = ({match}) => {

    const [event, setEvent] = useState<Event>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getEvent();
    }, [match, match.params.id]);


    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={event ? event.name : "Settings"} />
                {event && (
                    <>
                        <IonRow id="stalls">
                            {error && <ErrorAlert error={error}/>}
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>Stall Types</IonCardTitle>
                                    <IonCardContent>
                                        {event.status === "draft" ?
                                            <>
                                                {(event && event.organizationId) && (
                                                    <EventStallTypeForm event={event} organizationId={event.organizationId} />
                                                )}
                                            </>
                                            :
                                            <p>You cannot edit the stall types after publishing you event. If you need to edit them, contact us at hello@ringsidepro.com.</p>
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>Bedding Options</IonCardTitle>
                                    <IonCardContent>
                                        {event.status === "draft" ?
                                            <>
                                                {(event && event.organizationId) && (
                                                    <EventBeddingTypeForm event={event} organizationId={event.organizationId} />
                                                )}
                                            </>
                                            :
                                            <p>You cannot edit the bedding types after publishing you event. If you need to edit them, contact us at hello@ringsidepro.com.</p>
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow id="stallSets">
                            <IonCol sizeXs="12" sizeMd="12">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>Stall Sets</IonCardTitle>
                                    <IonCardContent>
                                        {(event && event.organizationId) && (
                                            <EventStallSetForm event={event} organizationId={event.organizationId} />
                                        )}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventStallSettingsPage;