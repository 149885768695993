import Papa from "papaparse";

/***
 * Call this function to download formatted rows to a CSV file
 * 
 * fileName: Should include .csv file extension example: MyData.csv
 * rows: Should include the header row
 * delimiter: (optional) character to be used to separate data in the file (default is a comma)
 */
export function downloadCSVFile(fileName: string, rows: any[], delimiter?: string) {
    var currentDelimiter = delimiter || ",";
    var csv = Papa.unparse(rows, {delimiter: currentDelimiter});
    var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    var csvURL =  null;
    
    csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL as string;
    tempLink.setAttribute('download', fileName);
    tempLink.click();
}