import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React from "react";
import EditPersonMembershipsTable from "../../../../components/Memberships/EditPersonMembershipsTable";

const OwnerMembershipsPage: React.FC = () => {
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Memberships" />
                {/* TO DO - Add back in the ability to create USEF and EC Memberships */}
                {/* <IonRow className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="6">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                USEF Membership
                            </IonCardTitle>
                            <IonCardContent>
                                <USEFMembershipForm />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    <IonCol sizeSm="12" sizeMd="6">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                EC Membership
                            </IonCardTitle>
                            <IonCardContent>
                                <ECMembershipForm />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow> */}
                <IonRow>
                    <IonCol>
                        <EditPersonMembershipsTable hasAdminAccess={true} />
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default OwnerMembershipsPage;