import {
    IonCol,
    IonItem,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event } from "../../../models";
import { getStartDate, getEndDate } from "../../../utilities/dates/EventDates";
import { formatDateLongFormat } from "../../../utilities/dates/FormatDates";
import { getEventDaysByEventId } from "../../../utilities/eventDay/EventDay";

interface _Props {
    event: Event
}

const EventDisplayDates: React.FC<_Props> = ({event}) => {
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");

    useEffect(() => {
        async function getEventDatesByEventId(eventId: string) {
            const queryResult = await getEventDaysByEventId(eventId);
            if (queryResult.isSuccess) {
                const dates = queryResult.result;
                if (dates && dates.length) {
                    const startDateObject = getStartDate(dates);
                    const endDateObject = getEndDate(dates); 
                    if (startDateObject) setStartDate(formatDateLongFormat(startDateObject));
                    if (endDateObject) setEndDate(formatDateLongFormat(endDateObject));
                }
            }
        }
        if (event) getEventDatesByEventId(event.id);
    }, [event]);

    return (
        <>
            {startDate && endDate && (startDate !== endDate) ? 
                <>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonText>Start Date: {startDate}</IonText>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonText>End Date: {endDate}</IonText>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </>
                :
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonText>{startDate}</IonText>
                        </IonItem>
                    </IonCol>
                </IonRow>
            }
        </>
    );
};

export default EventDisplayDates;