import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRadio,
    IonReorder,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, EventDivision, PointTable } from "../../models";
import { getEventDivisionsByEventId, updateEventDivision } from "../../utilities/eventDivision/EventDivision";
import { Input, InputGroup, InputGroupAddon, InputGroupText, Table } from "reactstrap";
import AddEventDivisionCard from "./AddEventDivisionCard";
import Spinner from "../Spinners/Spinner";
import EditEventDivisionCard from "./EditDivisionsCard";
import { sortEventDivisionsByName, sortEventDivisionsByNumber } from "../../utilities/eventDivision/SortEventDivisons";
import { close, copyOutline, createOutline } from "ionicons/icons";
import SelectCreatePointTable from "../PointTable/SelectCreatePointTable";
import { UpdateEventDivisionInput } from "../../API";
import { isWindows } from "../../utilities/platform/Platform";

interface _Props {
    event: Event
    onSelect: Function
}

interface FormattedEventDivision {
    id: string
    name: string
    divisionFee?: (string | null)
    usefNumber?: (string | null)
    ushjaName?: (string | null)
    ecNumber?: (string | null)
    eventDivision: EventDivision
    number?: (number | null)
    pointsTableName: string
    pointsTable?: PointTable
    prizeMoneyTableName: string
}

const EventDivisionEditTable: React.FC<_Props> = ({event, onSelect}) => {

    const [formattedEventDivisions, setFormattedEventDivisions] = useState<FormattedEventDivision[] | null | undefined>();
    const [isEditNames, setIsEditNames] = useState(false);
    const [isEditFee, setIsEditFee] = useState(false);
    const [isEditPoints, setIsEditPoints] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showEditAllPointTablesModal, setShowEditAllPointTablesModal] = useState(false);

    const formatEventDivisions = async (eventDivisions: EventDivision[]) => {
        let formattedEventDivisions = [];
        for (var i = 0; i < eventDivisions.length; i++) {
            const currentEventDivision = eventDivisions[i];
            let object: FormattedEventDivision = {
                id: currentEventDivision.id,
                name: currentEventDivision.name,
                divisionFee: currentEventDivision.entryFee,
                ecNumber: currentEventDivision.division?.ecNumber,
                usefNumber: currentEventDivision.division?.usefNumber,
                ushjaName: currentEventDivision.division?.ushjaName,
                eventDivision: currentEventDivision,
                number: currentEventDivision.number || null,
                pointsTableName: currentEventDivision.pointTable?.name || "",
                pointsTable: currentEventDivision.pointTable || undefined,
                prizeMoneyTableName: currentEventDivision.prizeMoneyTable?.name || ""
            };
            formattedEventDivisions.push(object);
        }
        setFormattedEventDivisions(formattedEventDivisions);
        setIsLoading(false);
    }

    async function getEventDivisions(event: Event) {
        setIsLoading(true);
        const queryResult = await getEventDivisionsByEventId(event.id);
        if (queryResult.isSuccess) {
            const list = queryResult.result;
            const sorted = (list[0] && list[0].number) ? sortEventDivisionsByNumber(list) : sortEventDivisionsByName(list);
            formatEventDivisions(sorted || list);
        } else {
            setIsLoading(false);
        }
        
    }

    useEffect(() => {
        if (event) getEventDivisions(event);
    }, [event]);

    const handleNameChange = (name: string, formattedItem: FormattedEventDivision, index: number) => {
        if (formattedEventDivisions) {
            let updated: FormattedEventDivision = {
                ...formattedItem,
                name: name
            }; 
            let updatedArray = [
                ...formattedEventDivisions.slice(0, index),
                updated,
                ...formattedEventDivisions.slice(index + 1)
            ];
            setFormattedEventDivisions(updatedArray);
        }
    }

    const handleFeeChange = (fee: string, formattedItem: FormattedEventDivision, index: number) => {
        if (formattedEventDivisions) {
            let updated: FormattedEventDivision = {
                ...formattedItem,
                divisionFee: fee
            }; 
            let updatedArray = [
                ...formattedEventDivisions.slice(0, index),
                updated,
                ...formattedEventDivisions.slice(index + 1)
            ];
            setFormattedEventDivisions(updatedArray);
        }
    }

    const handlePointsTableChange = (formattedItem: FormattedEventDivision, index: number, pointTable?: PointTable) => {
        if (formattedEventDivisions) {
            let updated: FormattedEventDivision = {
                ...formattedItem,
                pointsTableName: pointTable?.name || "",
                pointsTable: pointTable || undefined
            }; 
            let updatedArray = [
                ...formattedEventDivisions.slice(0, index),
                updated,
                ...formattedEventDivisions.slice(index + 1)
            ];
            setFormattedEventDivisions(updatedArray);
        }
    }

    const handleSelectAllPointsTables = (pointTable?: PointTable) => {
        setShowEditAllPointTablesModal(false);
        setIsLoading(true);
        let updatedFormattedEventDivisions: FormattedEventDivision[] = [];
        if (formattedEventDivisions) {
            for (let i = 0; i < formattedEventDivisions.length; i++) {
                const current: FormattedEventDivision = formattedEventDivisions[i];
                let updated: FormattedEventDivision = {
                    ...current,
                    pointsTableName: pointTable?.name || "",
                    pointsTable: pointTable || undefined
                }; 
                updatedFormattedEventDivisions.push(updated);
            }
            setFormattedEventDivisions(updatedFormattedEventDivisions);
        }
        setIsLoading(false);
    }

    const handleSaveChanges = async () => {
        setIsLoading(true);
        if (formattedEventDivisions) {
            for (let i = 0; i < formattedEventDivisions.length; i++) {
                const current = formattedEventDivisions[i];
                let updateInput: UpdateEventDivisionInput = {
                    id: current.id,
                    name: current.name,
                    entryFee: current.divisionFee,
                    eventDivisionPointTableId: current.pointsTable?.id || ""
                };
                await updateEventDivision(updateInput);
            }
        }
        setIsEditFee(false);
        setIsEditNames(false);
        setIsEditPoints(false);
        setIsLoading(false);
    }

    const onSelectClass = (c: EventDivision) => {
        onSelect(c);
    }

    return (
        <>
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="4">
                        Divisions
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="4" className="ion-text-right">
                        <AddEventDivisionCard event={event} />
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="4" className="ion-text-right">
                        <EditEventDivisionCard event={event} />
                    </IonCol>
                </IonRow>
            </IonCardTitle>
            <IonCardSubtitle>Click on a Division to edit it.</IonCardSubtitle>
            {(isEditNames || isEditFee || isEditPoints) && (
                <IonRow className="ion-justify-content-center">
                    <IonCol className="ion-text-center">
                        <IonButton color="success" onClick={() => handleSaveChanges()}>Save Changes</IonButton>
                    </IonCol>
                </IonRow>
            )}
            {isLoading ? 
                <Spinner />
                :
                <>
                    {!(isWindows() || isPlatform("desktop")) ?
                        <IonList className="bg-white">
                            {(formattedEventDivisions && formattedEventDivisions.length > 0) && (
                                <>
                                    {formattedEventDivisions.map((ed, index) => (
                                        <IonItem key={index} onClick={() => onSelectClass(ed.eventDivision)}>
                                            <IonLabel>
                                                <p className="ion-text-wrap">{ed.name}</p>
                                            </IonLabel>
                                            <IonRadio slot="start" color="tertiary" value={ed.id} />
                                            <IonReorder slot="start" />
                                        </IonItem>
                                    ))}
                                </>
                            )}
                        </IonList>
                        :
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name <IonIcon icon={createOutline} ariaLabel="Edit Names" onClick={() => setIsEditNames(!isEditNames)} /></th>
                                    <th>Division Fee <IonIcon icon={createOutline} ariaLabel="Edit Fees" onClick={() => setIsEditFee(!isEditFee)} /></th>
                                    <th>
                                        Points Table 
                                        <IonIcon icon={createOutline} ariaLabel="Edit Points" onClick={() => setIsEditPoints(!isEditPoints)} />
                                        {isEditPoints && (
                                            <IonIcon icon={copyOutline} ariaLabel="Edit All" onClick={() => setShowEditAllPointTablesModal(true)} />
                                        )}
                                    </th>
                                    <th>Prize Money Table</th>
                                    <th>USEF</th>
                                    <th>USHJA</th>
                                    <th>EC</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(formattedEventDivisions && formattedEventDivisions.length > 0) && (
                                    <>
                                        {formattedEventDivisions.map((ed, index) => (
                                            <tr key={index}>
                                                <td onClick={() => onSelectClass(ed.eventDivision)}>
                                                    {ed.number || index+1}
                                                </td>
                                                <td onClick={isEditNames ? () => console.log("Is editing names") : () => onSelectClass(ed.eventDivision)}>
                                                    {isEditNames ? 
                                                        <>
                                                            <Input
                                                                className="input-width"
                                                                type="text"
                                                                value={ed.name}
                                                                onChange={e => {
                                                                    handleNameChange(e.target.value, ed, index);
                                                                }}
                                                            />
                                                        </>
                                                        :
                                                        <p className="ion-text-wrap">{ed.name}</p>
                                                    }
                                                </td>
                                                <td onClick={isEditFee ? () => console.log("Is editing fee") : () => onSelectClass(ed.eventDivision)}>
                                                    {isEditFee ? 
                                                        <InputGroup size="sm">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>$</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                className="input-width"
                                                                type="text"
                                                                value={ed.divisionFee || ""}
                                                                onChange={e => {
                                                                    handleFeeChange(e.target.value, ed, index);
                                                                }}
                                                            />
                                                        </InputGroup>
                                                        :
                                                        <p className="ion-text-wrap">{ed.divisionFee ? "$" + ed.divisionFee : ""}</p>
                                                    }
                                                </td>
                                                <td onClick={isEditPoints ? () => console.log("Is editing points") : () => onSelectClass(ed.eventDivision)}>
                                                    {(isEditPoints && event.organizationId) ?
                                                        <div className="extra-large-input-width">
                                                            <SelectCreatePointTable organizationId={event.organizationId} pointTable={ed.pointsTable} onSelect={(pointTable: PointTable) => handlePointsTableChange(ed, index, pointTable)} />
                                                        </div>
                                                        :
                                                        <p className="ion-text-wrap">{ed.pointsTableName}</p>
                                                    }
                                                </td>
                                                <td onClick={() => onSelectClass(ed.eventDivision)}>
                                                    <p className="ion-text-wrap">{ed.prizeMoneyTableName}</p>
                                                </td>
                                                <td onClick={() => onSelectClass(ed.eventDivision)}>
                                                    {ed.usefNumber}
                                                </td>
                                                <td onClick={() => onSelectClass(ed.eventDivision)}>
                                                    {ed.ushjaName}
                                                </td>
                                                <td onClick={() => onSelectClass(ed.eventDivision)}>
                                                    {ed.ecNumber}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </Table>
                    }
                </>
            }
        </IonCard>
            <IonModal backdropDismiss={false} isOpen={showEditAllPointTablesModal} id="editAllPointTablesModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Set All Points Tables
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowEditAllPointTablesModal(false)}
                        >
                            <p id="closeEditAllPointTablesModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow>
                        <IonCol>
                            <p>You can select a point table for all divisions at once by selecting one of the organization's point tables below.</p>
                            <p>Or create a new point table by typing a new name and the selecting "Create".</p>
                            <hr/>
                            <IonLabel>Select the Point Table</IonLabel>
                            {event.organizationId ?
                                <SelectCreatePointTable organizationId={event.organizationId} onSelect={(pointTable: (PointTable | undefined)) => handleSelectAllPointsTables(pointTable)} />
                                :
                                <p>Sorry, no organization was found. An organization is needed to retrieve the point tables.</p>
                            }
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventDivisionEditTable;