import React from "react";

interface IProps {
    handleSelectedFile: Function
}

interface IState {
    label: string
}

class FileBrowser extends React.Component<IProps, IState> {
    fileInput: React.RefObject<any>;

    constructor(props: any) {
        super(props);
        this.state = {
            label: "Select file"
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fileInput = React.createRef();
    }

    handleSubmit(event: any) {
        event.preventDefault();
        this.setState({label: this.fileInput.current.files[0].name});
        this.props.handleSelectedFile(this.fileInput.current.files[0]);
    }
  
    render() {
        const {label} = this.state;
        return (
            <div>
                <input 
                    id="customFileLang"
                    type="file" 
                    ref={this.fileInput} 
                    onChange={this.handleSubmit} 
                />
                <label
                    className="custom-file-label"
                    htmlFor="customFileLang"
                >
                    {label}
                </label>
            </div>
        );
    }
}

export default FileBrowser;