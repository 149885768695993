export const listEventDivisionsWithResults = /* GraphQL */ `
  query ListEventDivisions(
    $filter: ModelEventDivisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventDivisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        name
        number
        divisionId
        organizationDivisionId
        eventResults {
          items {
            createdBy
            id
            place
            eventClassEntryId
          }
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;