import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { CreateEventStallTypeInput } from "../../API";
import { Event, EventStallType, OrganizationStallType } from "../../models";
import { createEventStallType, deleteEventStallType, getEventStallTypesByEventId } from "../../utilities/eventStallType/EventStallType";
import { getOrganizationStallTypesByOrganizationId } from "../../utilities/organizationStallType/OrganizationStallType";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    organizationId?: string
    event: Event
}

interface FormattedStallType {
    isChecked: boolean
    object: OrganizationStallType
}

const EventStallTypeForm: React.FC<_Props> = ({event, organizationId}) => {

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [formattedStallTypes, setFormattedStallTypes] = useState<FormattedStallType[] | null | undefined>();
    const [selectedStallTypes, setSelectedStallTypes] = useState<EventStallType[] | null | undefined>();
    const [error, setError] = useState<string>("");

    const getEventStallTypes = async (event: Event) => {
        const queryResult = await getEventStallTypesByEventId(event.id);
        if (queryResult.isSuccess) {
            setSelectedStallTypes(queryResult.result);
        }
        if (event.organizationId) await getOrganizationStallTypes(event.organizationId, queryResult.result);
    }

    const getOrganizationStallTypes = async (organizationId: string, selectedEventStallTypes?: EventStallType[]) => {
        const queryResult = await getOrganizationStallTypesByOrganizationId(organizationId);
        if (queryResult.isSuccess) {
            if (selectedEventStallTypes) formatOrganizationStallTypes(queryResult.result, selectedEventStallTypes);
            else formatOrganizationStallTypes(queryResult.result);
        } else {
            setError("No organization stall types found.")
        }
    }

    const formatOrganizationStallTypes = (stallTypes: OrganizationStallType[], selectedEventStallTypes?: EventStallType[]) => {
        let result: FormattedStallType[] = [];
        for (var i = 0; i < stallTypes.length; i++) {
            const stallType = stallTypes[i];
            let checked = false;
            if (selectedEventStallTypes && selectedEventStallTypes.length) {
                selectedEventStallTypes.forEach(type => {
                    if (type.organizationStallTypeId === stallType.id) checked = true;
                });
            }
            const formatted: FormattedStallType = {
                isChecked: checked,
                object: stallType
            };
            result.push(formatted);
        }
        setFormattedStallTypes(result);
    }

    useEffect(() => {
        if (event) getEventStallTypes(event);
    }, [event]);

    const handleCreateEventStallTypes = async (organizationStallType: OrganizationStallType) => {
        if (event && organizationId && organizationStallType) {
            // TO DO: find different way to handle taxes?
            const taxAmount = ((organizationStallType.taxA || 0) + (organizationStallType.taxB || 0)).toString();
            const input: CreateEventStallTypeInput = {
                eventId: event.id,
                name: organizationStallType.name || "",
                pricePerStall: organizationStallType.pricePerStall?.toString() || "",
                description: organizationStallType.description || "",
                organizationId: organizationId,
                organizationStallTypeId: organizationStallType.id,
                type: organizationStallType.type,
                taxPerStall: taxAmount
            };
            const createResult = await createEventStallType(input);
            if (!createResult.isSuccess) {
                setError("Sorry, we had a problem creating the stall type.");
            }
        } else {
            setError("Sorry, we could not find your organization.")
        }
    }

    const handleDeleteEventStallTypes = async (eventStallType: EventStallType) => {
        if (event) {
            const deleteResult = await deleteEventStallType({id: eventStallType.id});
            if (!deleteResult.isSuccess) {
                setError("Sorry, we had a problem removing the stall type.");
            }
        } else {
            setError("Sorry, we could not find your organization.")
        }
    }

    const handleSave = async () => {
        // First delete the old event stall types
        if (selectedStallTypes) {
            for (var i = 0 ; i < selectedStallTypes.length; i++) {
                const currentEventStallType: EventStallType = selectedStallTypes[i];
                if (currentEventStallType) await handleDeleteEventStallTypes(currentEventStallType);
            }
        }

        // Then create new event stall types
        if (formattedStallTypes) {
            for (var i = 0 ; i < formattedStallTypes.length; i++) {
                const currentFormattedStallType: FormattedStallType = formattedStallTypes[i];
                if (currentFormattedStallType.isChecked) {
                    const currentOrganizationStallType: OrganizationStallType = currentFormattedStallType.object;
                    await handleCreateEventStallTypes(currentOrganizationStallType);
                }
            }
        }

        // Finally, make sure lists are updated
        if (event) getEventStallTypes(event);
    }

    const handleOnChange = (index: number, stallType: OrganizationStallType) => {
        setIsDisabled(false);
        if (formattedStallTypes) {
            const selectedStallType = formattedStallTypes[index];
            const updatedStallType: FormattedStallType = {
                isChecked: !selectedStallType.isChecked,
                object: stallType
            };
            const updatedArray: FormattedStallType[] = [
                ...formattedStallTypes.slice(0, index),
                updatedStallType,
                ...formattedStallTypes.slice(index + 1)
            ];
            setFormattedStallTypes(updatedArray);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        await handleSave();
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {formattedStallTypes ? 
                <>
                    <IonList className="bg-white">
                        {formattedStallTypes.map((formattedStallType: FormattedStallType, index: number) => (
                            <IonItem key={index}>
                                <IonCheckbox slot="start" color="primary" checked={formattedStallType.isChecked} value={formattedStallType.object.id}  onClick={() => handleOnChange(index, formattedStallType.object)}/>
                                <IonLabel className="ion-text-wrap">
                                    {formattedStallType.object.name} {formattedStallType.object.pricePerStall ? " - $" + formattedStallType.object.pricePerStall : ""} 
                                </IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                    <IonRow>
                        <IonCol sizeMd="4">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    disabled={isDisabled}
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    {isDisabled ? "Saved" : "Save"}
                                </IonButton>
                            }
                        </IonCol>
                    </IonRow>
                </>
                :
                <p>No organization stall types. Go to your organization settings to update.</p>
            }
        </>
    );
};

export default EventStallTypeForm;