import { IonAlert, IonButton, IonIcon, IonLabel } from "@ionic/react";
import React, { useState } from "react";
import { add } from "ionicons/icons";
import { Organization } from "../../models";
import { useHistory } from "react-router";

const CreateEventButton: React.FC<{organization?: Organization}> = ({organization}) => {
    const history = useHistory();
    const [showAlert, setShowAlert] = useState(false);
    return (
        <>
            <IonButton 
                onClick={() => {!organization && setShowAlert(true)}}
                routerLink="/index/staff/event/new"
                routerDirection="forward"
            >
                <IonIcon icon={add} />
                <IonLabel>Add</IonLabel>
            </IonButton>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={"Organization Not Found"}
                message={"Before creating an event, finish setting up an organization."}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => {
                            history.push("/index/staff/events");
                        }
                    },
                    {
                        text: 'Set Up',
                        handler: () => {
                            history.push("/index/staff/organization");
                        }
                    }
                ]}
            />
        </>
    );
};

export default CreateEventButton;
