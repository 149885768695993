import React, { useEffect, useState } from "react";
import {eventClassTypes} from "../../../utilities/eventClass/EventClassTypes";
import BasicSelect, {formattedOption} from "../../Select/BasicSelect";

interface _Props {
    menuPlacement?: string
    selectedValue?: string
    onSelect: Function
}

const defaultOption: formattedOption = {
    label: "Over Fences",
    value: "over_fences",
    object: {
        "name": "Over Fences",
        "abbreviation": "O/F",
        "value": "over_fences"
    }
}

const SelectEventClassType: React.FC<_Props> = ({menuPlacement, selectedValue, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedEventClassTypes, setFormattedEventClassTypes] = useState<formattedOption[] | null | undefined>();

    useEffect(() => {
        formatEventClassTypes();
    }, []);

    useEffect(() => {
        if (selectedValue) setSelected(selectedValue)
    }, [selectedValue]);

    const formatEventClassTypes = () => {
        let formattedEventClassTypes = [];
        for (var i = 0; i < eventClassTypes.length; i++) {
            let object = {
                value: eventClassTypes[i].value,
                label: eventClassTypes[i].name,
                object: eventClassTypes[i]
            };
            formattedEventClassTypes.push(object);
        }
        setFormattedEventClassTypes(formattedEventClassTypes);
    }

    const handleOnChange = (event: any) => {
        if (event && event.value) {
            onSelect(event.value);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedEventClassTypes ?
                <BasicSelect formattedValues={formattedEventClassTypes} defaultValue={defaultOption.value} selectedValue={selected} menuPlacement={menuPlacement} onSelect={handleOnChange}/>
                :
                <p>Loading options...</p>
            }
        </>
    );
};

export default SelectEventClassType;
