import { Alert, Event, Organization } from "../../models";
import moment from "moment";
import { getAlertsByEventId, getAlertsByOrganizationId } from "../alerts/Alerts";
import { sortAlertsByNewest } from "../alerts/SortAlerts";
import { downloadCSVFile } from "./ReportDownloadCSV";
require('jspdf-autotable');

export const headerRow = [
    "Time Sent",
    "Message",
    "Incoming / Outgoing",
    "Credits Used",
    "Number of Subscribers",
    "Message Segments (160 characters)",
    "Character Count"
];

export async function generateEventAlertReport(event: Event) {

    let rows = [];

    rows.push(headerRow);
    
    const queryResult = await getAlertsByEventId(event.id);
    if (queryResult.isSuccess) {
        const alerts = queryResult.result as Alert[];
        const sorted = sortAlertsByNewest(alerts);
        if (sorted && sorted.length > 0) {
            for (let i = 0; i < sorted.length; i++) {
                let currentRow: any[] = [];
                const alert = sorted[i];
                currentRow.push(alert.createdOn ? moment(alert.createdOn).format("MM/DD/YY h:mma") : "");
                currentRow.push(alert.body || "");
                currentRow.push(alert.isIncoming ? "incoming" : "outgoing");
                currentRow.push((alert.messageSegments || 0) * (alert.subscriberCount || 0));
                currentRow.push(alert.subscriberCount || 0);
                currentRow.push(alert.messageSegments || 0);
                currentRow.push(alert.body?.length || "");
                rows.push(currentRow);
            }
        }
    }

    const fileName = event.nickname ? (event.nickname + " alerts.csv") : (event.name ? event.name + " alerts.csv" : "alerts.csv");
    downloadCSVFile(fileName, rows);
}

export async function generateOrganizationAlertReport(organization: Organization) {

    let rows = [];

    rows.push(headerRow);
    
    const queryResult = await getAlertsByOrganizationId(organization.id);
    if (queryResult.isSuccess) {
        const alerts = queryResult.result as Alert[];
        const sorted = sortAlertsByNewest(alerts);
        if (sorted && sorted.length > 0) {
            for (let i = 0; i < sorted.length; i++) {
                let currentRow: any[] = [];
                const alert = sorted[i];
                currentRow.push(alert.createdOn ? moment(alert.createdOn).format("MM/DD/YY h:mma") : "");
                currentRow.push(alert.body || "");
                currentRow.push(alert.isIncoming ? "incoming" : "outgoing");
                currentRow.push((alert.messageSegments || 0) * (alert.subscriberCount || 0));
                currentRow.push(alert.subscriberCount || 0);
                currentRow.push(alert.messageSegments || 0);
                currentRow.push(alert.body?.length || "");
                rows.push(currentRow);
            }
        }
    }

    const fileName = organization.name ? (organization.name + " alerts.csv") : "alerts.csv";
    downloadCSVFile(fileName, rows);
}