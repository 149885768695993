import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import SuccessBanner from "../Banners/SuccessBanner";
import { BarnPerson } from "../../interfaces/Person";
import { getPersonAddress, savePersonAddress } from "../../utilities/barn/BarnPerson"; 
import Spinner from "../Spinners/Spinner";
import SelectCountry from "../Address/SelectCountry";
import SelectState from "../Address/SelectState";

interface _Props {
    person: BarnPerson
    onSubmit: Function
}

const EditPersonAddressForm: React.FC<_Props> = ({person, onSubmit}) => {    
    
    const [streetAddressLine1, setStreetAddressLine1] = useState<string>("");
    const [streetAddressLine2, setStreetAddressLine2] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [provState, setProvState] = useState<string>("");
    const [zip, setZip] = useState<string>("");
    const [country, setCountry] = useState<string>("");

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const clearForm = () => {

    }

    const validateForm = () => {
        if (!city) {
            setError("Include a city.");
            return false
        };

        if (!provState) {
            setError("Include a state or providence.");
            return false
        };

        return true;
    }

    const getAddressInfo = async (person: BarnPerson) => {
        const address = await getPersonAddress(person);
        if (address) {
            if (address.streetAddress1) setStreetAddressLine1(address.streetAddress1);
            if (address.streetAddress2) setStreetAddressLine2(address.streetAddress2);
            if (address.city) setCity(address.city);
            if (address.provState) setProvState(address.provState);
            if (address.zip) setZip(address.zip);
            if (address.country) setCountry(address.country);
        }
    }

    useEffect(() => {
        if (person) {
            getAddressInfo(person);
        } else {
            clearForm();
        }
    }, [person]);

    const handleProvStateInputChange = (value: string) => {
        if (value && value !== "-") setProvState(value);
    }

    const handleCountryInputChange = (value: string) => {
        if (value && value !== "-") setCountry(value);
    }

    const handleEditPerson = async () => {
        setError("");
        setSuccess("");
        setIsLoading(true);
        const isValid = validateForm();
        if (isValid) {
            const result = await savePersonAddress(person, streetAddressLine1, streetAddressLine2, city, provState, zip, country);
            if (result.isSuccess) {
                setSuccess(result.message);
            } else {
                setError(result.message);
            }
            onSubmit();
        }
        setIsLoading(false);
    }

    return (
        <>
        {   success && <SuccessBanner success={success} />}
            {error && <ErrorAlert error={error} />}
            <IonRow>
                <IonCol size="12">
                    <IonItem color="white">
                        <IonLabel position="stacked">Street Address Line 1</IonLabel>
                        <IonInput 
                            type="text"
                            value={streetAddressLine1}
                            aria-required={true}
                            onIonChange={e => {
                                setStreetAddressLine1(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    <IonItem color="white">
                        <IonLabel position="stacked">Street Address Line 2</IonLabel>
                        <IonInput 
                            type="text"
                            value={streetAddressLine2}
                            aria-required={true}
                            onIonChange={e => {
                                setStreetAddressLine2(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">City</IonLabel>
                        <IonInput 
                            type="text"
                            value={city}
                            aria-required={true}
                            onIonChange={e => {
                                setCity(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <SelectState selectedValue={provState} onInputChange={handleProvStateInputChange} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Postal Code</IonLabel>
                        <IonInput 
                            type="text"
                            value={zip}
                            aria-required={true}
                            onIonChange={e => {
                                setZip(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <SelectCountry countryValue={country} onInputChange={handleCountryInputChange} />
                </IonCol>
            </IonRow>
            {isLoading ?
                <Spinner />
                :
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeMd="4" className="ion-text-center">
                        <IonButton
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleEditPerson}
                        >
                            Save
                        </IonButton>
                    </IonCol>
                </IonRow>
            }
        </>
    );
};

export default EditPersonAddressForm;