import moment from "moment";
import { Event, EventResult } from "../../models";
import { getEventResultsByEventId } from "../eventResult/EventResult";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export async function generateExpensesFromPrizeMoney(event: Event) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Prize Money Analysis Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = ["Expense", "Amount"];
    // define an empty array of rows
    const tableRows = [];

    let totalPrizeMoneyAmount = 0;

    const queryResult = await getEventResultsByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventResults = queryResult.result;
        if (eventResults.length > 0) {
            for (let i = 0; i < eventResults.length; i++) {
                const eventResult: EventResult = eventResults[i];
                totalPrizeMoneyAmount = totalPrizeMoneyAmount + (eventResult.prizeMoney || 0);
            }
        }
    }

    const totalEntryFeeRow = [
        "Total Prize Money Given",
        "$" + totalPrizeMoneyAmount.toLocaleString()
    ];

    tableRows.push(totalEntryFeeRow);
     
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 35 
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    doc.save(`expenses_from_prize_money.pdf`);
}