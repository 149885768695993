import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonToggle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { UpdateEventInput } from "../../API";
import { EntryOptions, Event } from "../../models";
import { updateEvent } from "../../utilities/events/Event";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    event: Event
}

const EventRiderSettingsForm: React.FC<_Props> = ({event}) => {

    const [includeRider2Input, setIncludeRider2Input] = useState(false);
    const [limitNumberOfClassesInput, setLimitNumberOfClassesInput] = useState(false);
    const [numberOfClasses, setNumberOfClasses] = useState<number | null | undefined>();

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (event) {
            if (event.entryOptions?.displayRider2Input) setIncludeRider2Input(true);
            if (event.entryOptions?.maximumNumberClassesOnEntry) {
                setLimitNumberOfClassesInput(true);
                setNumberOfClasses(event.entryOptions?.maximumNumberClassesOnEntry);
            }
        }
    }, [event]);

    const handleSave = async () => {
        let entryOptions: EntryOptions = {
            displayRider2Input: includeRider2Input,
            maximumNumberClassesOnEntry: (limitNumberOfClassesInput ? (numberOfClasses || null) : null)
        };
        const input: UpdateEventInput = {
            id: event.id,
            entryOptions: entryOptions
        };
        const updateResult = await updateEvent(input);
        if (updateResult.isSuccess) {
            setIsDisabled(true);
        } else {
            setError("Could not save the Entry Settings.");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        await handleSave();
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <IonRow>
                <IonCol size="12">
                    <IonItem lines="none">
                        <IonLabel slot="start" className="ion-text-wrap">Display Rider 2 Input on Entry Form</IonLabel>
                        <IonToggle color="tertiary" checked={includeRider2Input} onIonChange={e => {setIncludeRider2Input(e.detail.checked); setIsDisabled(false);}}/>
                    </IonItem> 
                </IonCol>
            </IonRow>
            {/* <IonRow>
                <IonCol size="12">
                    <IonItem lines="none">
                        <IonLabel slot="start" className="ion-text-wrap">Limit the Number of Classes Entered by a Single Entry</IonLabel>
                        <IonToggle color="tertiary" checked={limitNumberOfClassesInput} onIonChange={e => {setLimitNumberOfClassesInput(e.detail.checked); setIsDisabled(false);}}/>
                    </IonItem> 
                </IonCol>
            </IonRow>
            {limitNumberOfClassesInput &&
                <IonRow>
                    <IonCol size="12">
                        <IonItem>
                            <IonLabel position="floating" className="ion-text-wrap">Maximum Number of Classes</IonLabel>
                            <IonInput
                                type="number"
                                value={numberOfClasses}
                                aria-required={true}
                                onIonChange={e => {
                                    setIsDisabled(false);
                                    setNumberOfClasses(e.detail.value! ? parseInt(e.detail.value!) : undefined);
                                }}
                            />
                        </IonItem> 
                    </IonCol>
                </IonRow>
            } */}
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            disabled={isDisabled}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            {isDisabled ? "Saved" : "Save"}
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </>
    );
};

export default EventRiderSettingsForm;