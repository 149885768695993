import {
    IonButton,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorBanner from "../Banners/ErrorBanner";
import FileBrowser from "../FileBrowser/FileBrowser";
import PictureEditor from "../Pictures/PictureEditor";
import { PersonContext } from "../../context/PersonContext";
import { createProfilePictureFileName, createS3Object, handleDeleteS3Item, handleUploadS3Image } from "../../utilities/s3Object/s3Object";
import { updatePerson } from "../../utilities/person/Person";
import ProfilePicture from "../ProfilePage/ProfilePicture";
import { isImage } from "../../utilities/files/File";
import Spinner from "../Spinners/Spinner";
import { UpdatePersonInput } from "../../API";

const ProfilePictureForm: React.FC = () => {
    const user = useContext(PersonContext);

    const [picture, setPicture] = useState<any>(user.picture);
    const [pictureURL, setPictureURL] = useState<string | null | null | undefined>();
    const [userPicture, setUserPicture] = useState<any>(null);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (user && user.pictureURL) {
            setPictureURL(user.pictureURL);
        }
    }, [user]);

    const handleSelectedFile = (file: File) => {
        setIsLoading(true);
        try {
            if (isImage(file)) {
                setPicture(file);
                setError("");
            }
            else setError("Please check that your image is one of: .jpg, .jpeg, .png, .svg")
        } catch (error: any) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const handleEditedURL = async (editedURL: string) => {
        try {
            if (editedURL) {
                setIsLoading(true);
                await setPictureURL(() => editedURL);

                const personInput: UpdatePersonInput = {
                    id: user.id,
                    pictureURL: editedURL
                };
                const uploadResult = await updatePerson(personInput);
                if (uploadResult.isSuccess) {
                    setError("");
                }
                else setError(uploadResult.message);
            }
        } catch (error: any) {
            console.error(error);
        }
    }

    const handleEditedFile = async (editedFile: any) => {
        try {
            setPicture(editedFile);
            
            const uploadResult = await handleUploadS3Image(createProfilePictureFileName(user), picture);
            if (uploadResult.isSuccess) {
                const key = uploadResult.result;

                // Create a unique S3 Object 
                const s3Object = createS3Object(key, "Profile Picture", editedFile);
                
                const personInput: UpdatePersonInput = {
                    id: user.id,
                    picture: s3Object
                };
                const updatePersonPictureResult = await updatePerson(personInput);

                if (updatePersonPictureResult.isSuccess) setError("");
                else setError(updatePersonPictureResult.message);
                
                setUserPicture({key});
            } else {
                setError(uploadResult.message);
            }
        } catch (error: any) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const handleDelete = async () => {
        setIsLoading(true);
        try {
            setError("");
            
            if (userPicture?.key) {
                const deleteFromS3Result = await handleDeleteS3Item(userPicture?.key);
                if (!deleteFromS3Result.isSuccess) setError(deleteFromS3Result.message);
            } else {
                if (picture?.key) {
                    const deleteFromS3Result = await handleDeleteS3Item(picture?.key);
                    if (!deleteFromS3Result.isSuccess) setError(deleteFromS3Result.message);  
                }
            }

            const deleteInput: UpdatePersonInput = {
                id: user.id,
                picture: {key: "", bucket: "", region: ""},
                pictureURL: ""
            }
            const deleteFromPerson = await updatePerson(deleteInput);
            if (!deleteFromPerson.isSuccess) setError(deleteFromPerson.message);
            
            await setPictureURL((prevValue) => "");
            setPicture(null);
            setUserPicture(null);
        } catch (error: any) {
            setError("Sorry, unable to delete the picture at this time.");
        }
        setIsLoading(false);
    }

    return (
        <>
            {isLoading ?
                <IonRow>
                    <IonCol className="ion-text-center">
                        <Spinner />
                    </IonCol>
                </IonRow>
                :
                <form>
                    {error && (
                        <IonRow>
                            <IonCol>
                                <ErrorBanner error={error} />
                            </IonCol>
                        </IonRow>
                    )}
                    {/* If user already has a picture set, show the image and a delete option */}
                    {pictureURL ? 
                        <>
                            <IonRow>
                                <IonCol sizeMd="12" className="text-center">
                                    <ProfilePicture pictureURL={pictureURL} />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-text-center">
                                    <IonButton color="danger" onClick={() => handleDelete()}>Remove Profile Picture</IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                        :
                        <>
                        {/* If user does not already have a picture set, show the file browser and the picture editor */}
                            {picture && (picture.key || picture.name) ?
                                <>
                                    <PictureEditor picture={picture} handleEditedFile={handleEditedFile} handleEditedURL={handleEditedURL} />
                                </>
                                :
                                <IonRow className="ion-justify-content-center">
                                    <IonCol sizeMd="6" className="text-center">
                                        <FileBrowser handleSelectedFile={handleSelectedFile}/>
                                    </IonCol>
                                </IonRow>
                            }
                        </>
                    }
                </form>
            }
        </>
    );
};

export default ProfilePictureForm;