import { PaymentOptionsInput } from "../../API";

export function formatPaymentOptions(acceptsChecks?: Boolean, acceptsCash?: Boolean, acceptsACH?: Boolean, acceptsCC?: Boolean): PaymentOptionsInput {
    const options: PaymentOptionsInput = {
        acceptsACH: !!acceptsACH,
        acceptsCC: !!acceptsCC,
        acceptsCash: !!acceptsCash,
        acceptsChecks: !!acceptsChecks
    }
    return options;
}