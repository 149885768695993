import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonLabel,
    IonModal,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { add, close, createOutline, trashOutline } from "ionicons/icons";
import DataTable from "react-data-table-component";
import { Membership, OrganizationMembershipType } from "../../models";
import { getMembershipsByOrganizationIdByActiveStatus } from "../../utilities/membership/Membership";
import {
    getOrganizationMembershipTypesByOrganizationId,
    deleteOrganizationMembershipType
} from "../../utilities/organizationMembershipType/OrganizationMembershipType";
import Spinner from "../Spinners/Spinner";
import { isWindows } from "../../utilities/platform/Platform";
import CONSTANT from "../../constant/constant";
import ErrorAlert from "../Errors/ErrorAlert";

interface _Props {
    organizationId: string
}
const OrganizationMembershipTypesTable: React.FC<_Props> = ({organizationId}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [membershipTypeSelected, setMembershipTypeSelected] = useState<OrganizationMembershipType>();
    const [membershipTypes, setMembershipTypes] = useState<OrganizationMembershipType[]>();
    const [memberships, setMemberships] = useState<Membership[]>();
    const [isActive, setIsActive] = useState(false);
    const [isShowRemoveModal,setIsShowRemoveModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const history = useHistory();

    async function getMembershipTypes(organizationId: string) {
        setIsLoading(true);
        const queryResult = await getOrganizationMembershipTypesByOrganizationId(organizationId);
        if (queryResult.isSuccess) {
            setMembershipTypes(queryResult.result);
        } else {
            setError(queryResult.message);
        }
        setIsLoading(false);
    }

    const getAllMembershipsOfOrganization = async (organizationId: string, status: "active" | "expired") => {
        setIsLoading(true);
        const queryResult = await getMembershipsByOrganizationIdByActiveStatus(organizationId, status);
        if (queryResult.isSuccess) {
            setMemberships(queryResult.result);
        } else {
            setError(queryResult.message);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (organizationId) {
            getMembershipTypes(organizationId);
            getAllMembershipsOfOrganization(organizationId, "active");
        }
    }, [organizationId]);

    function handleRemoveClick(type: OrganizationMembershipType) {
        setMembershipTypeSelected(type);
        const found = memberships?.find((m: Membership) =>
            m.organizationMembershipTypeId === type.id
        );
        if (found) {
            setIsActive(true);
            setIsShowRemoveModal(false);
        } else {
            setIsActive(false);
            setIsShowRemoveModal(true);
        }
        setShowModal(true);
    }

    const handleEditClick = (type: OrganizationMembershipType) => {
        const path = "/index/staff/organization/membership-types/edit/" + type.id;
        history.push(path);
    }

    const handleNavigateToCreateMembershipPage = () => {
        const path = "/index/staff/organization/membership-types/create/" + organizationId;
        history.push(path);
    }

    const handleDeleteMembershipType = async () => {
        setError("");
        setIsLoading(true);
        if (membershipTypeSelected?.id) {
                const deleteMembershipTypeData = {
                    id: membershipTypeSelected.id
                };
                const deleteMembershipTypeResult = await deleteOrganizationMembershipType(deleteMembershipTypeData);
                if (deleteMembershipTypeResult.isSuccess) {
                    if (membershipTypeSelected?.organizationId) {
                        const queryResult = await getOrganizationMembershipTypesByOrganizationId(membershipTypeSelected.organizationId);
                        setMembershipTypes(queryResult.result);
                    }
                } else {
                    setError(deleteMembershipTypeResult.message);
                }
            }
        setShowModal(false);
        setIsActive(false);
        setIsShowRemoveModal(false);
        setIsLoading(false);
    };

    const columns = [{
        id: 1,
        name: "Name",
        selector: (row: OrganizationMembershipType) => row.name,
        width: isWindows() || isPlatform("desktop") ? "30%" : "35%"
    }, {
        id: 2,
        name: "Price",
        selector: (row: OrganizationMembershipType) => row.price ? `$${row.price}` : "$0",
        center: true
    }, {
        id: 3,
        name: "Length",
        selector: (row: OrganizationMembershipType) => row.schedule || "",
        center: true
    }, {
        id: 4,
        name: "Application",
        center: true,
        cell: (row: OrganizationMembershipType) =>
            row.acceptOnlineApplications === CONSTANT.MEMBERSHIP.ONLINE_APPLICATION.YES ? (
                <IonText color="success">{row.acceptOnlineApplications}</IonText>
            ) : (
                <IonText color="danger">{row.acceptOnlineApplications}</IonText>
            )
    }, {
        id: 5,
        name: "Edit",
        center: true,
        button: true,
        cell: (row: OrganizationMembershipType ) => 
            isWindows() || isPlatform("desktop") ? (
                <IonButton
                    className="membership-type-edit-btn"
                    onClick={() => handleEditClick(row)}
                    color="tertiary"
                >
                    Edit
                </IonButton>
            ) : (
                <IonIcon
                    size="large"
                    slot="start"
                    icon={createOutline}
                    color="tertiary"
                    onClick={() => handleEditClick(row)}
                />
            )
    }, {
        id: 6,
        name: "Remove",
        center: true,
        button: true,
        cell: (row: OrganizationMembershipType) => 
            isWindows() || isPlatform("desktop") ? (
                <IonButton
                    className="membership-type-remove-btn"
                    onClick={() => handleRemoveClick(row)}
                    color="danger"
                >
                    Remove
                </IonButton>
            ) : (
                <IonIcon
                    size="large"
                    slot="start"
                    icon={trashOutline}
                    color="danger"
                    onClick={() => handleRemoveClick(row)}
                />
            )
    }];

    const tableCustomStyles = {
        headCells: {
            style: {
                fontSize: "14px",
                fontWeight: "bold",
                width: "9rem"
            }
        }
    };

    return (
        <>
            <IonCard mode="md" className="ion-padding bg-white">
                {error && <ErrorAlert width="12" error={error} />}
                <IonRow className="ion-justify-content-between">
                    <IonCardTitle>
                        <h3>Membership Types</h3>
                    </IonCardTitle>
                    <IonButton onClick={handleNavigateToCreateMembershipPage} color="primary">
                        <IonIcon icon={add}/> 
                        {(isWindows() || (isPlatform("desktop"))) && <IonLabel> Add New</IonLabel>}
                    </IonButton>
                </IonRow>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <>
                        {(membershipTypes && membershipTypes.length > 0) ? (
                            <DataTable
                                columns={columns}
                                data={membershipTypes}
                                pagination
                                customStyles={tableCustomStyles}
                                highlightOnHover
                            />
                        ) : (
                            <p>No Membership Types found</p>
                        )}
                    </>
                )}
            </IonCard>
            <IonModal backdropDismiss={false} isOpen={showModal}>
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">Remove Membership Type</IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            id="delete-membership-type-modal-close-icon"
                            fill="clear"
                            onClick={() => {
                                setShowModal(false);
                                setIsActive(false);
                                setIsShowRemoveModal(false);
                            }}
                        >
                            <p
                                id="closeMembershipTypeAddressModalBtn"
                                className="font-weight-normal text-medium text-capitalize"
                            >
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                {isActive && <IonContent className="ion-padding">
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10" className="ion-text-center">
                            <h2 className="font-weight-bold">
                                {membershipTypeSelected?.name}
                            </h2>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center mt-5">
                        <IonCol size="12">
                            <h4>
                                Sorry, you cannot remove this membership type because you have active memberships that link to it.
                            </h4>
                            <h4>
                                If you remove those memberships or link them to a different membership type, you will be able to remove this.
                            </h4>
                        </IonCol>
                    </IonRow>
                </IonContent>}
                {isShowRemoveModal && <IonContent className="ion-padding">
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <h3>
                                Are you sure you want to permanently remove this membership type:{" "}
                            </h3>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10" className="ion-text-center">
                            <h2 className="font-weight-bold">
                                {membershipTypeSelected?.name}
                            </h2>
                        </IonCol>
                    </IonRow>
                </IonContent>}
                {isLoading ? (
                    <Spinner />
                ) : <>
                        {isActive && <IonRow className="ion-justify-content-center ion-padding-top">
                            <IonCol size="6" className="ion-text-center">
                                <IonButton
                                    id="delete-membership-type-modal-cancel-btn"
                                    expand="block"
                                    color="light"
                                    onClick={() => {
                                        setShowModal(false);
                                        setIsActive(false);
                                    }}
                                >
                                    Cancel
                                </IonButton>
                            </IonCol>
                        </IonRow>}
                        {isShowRemoveModal && <IonRow className="ion-justify-content-center ion-padding-top">
                            <IonCol size="6" className="ion-text-center">
                                <IonButton
                                    id="delete-membership-type-modal-cancel-btn"
                                    expand="block"
                                    color="light"
                                    onClick={() => {
                                        setShowModal(false);
                                        setIsShowRemoveModal(false);
                                    }}
                                >
                                    Cancel
                                </IonButton>
                            </IonCol>
                            <IonCol size="6" className="ion-text-center">
                                <IonButton
                                    id="delete-membership-type-modal-delete-btn"
                                    expand="block"
                                    color="danger"
                                    onClick={handleDeleteMembershipType}
                                >
                                    Remove
                                </IonButton>
                            </IonCol>
                        </IonRow>}
                    </>
                }
            </IonModal>
        </>
    );
};

export default OrganizationMembershipTypesTable;
