import {
    IonButton,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonReorder,
    IonReorderGroup,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { ItemReorderEventDetail } from '@ionic/core';
import { Organization, PointTable } from "../../models";
import { getOrganizationById } from "../../utilities/organization/Organization";
import { getPointTablesByOrganizationId } from "../../utilities/pointTable/PointTable";

interface _Props {
    organizationId: string
    onSelect: Function
}

let subscription: any;

const PointTableEditTable: React.FC<_Props> = ({organizationId, onSelect}) => {

    const [organization, setOrganization] = useState<Organization>();
    const [pointTables, setPointTables] = useState<PointTable[] | undefined>();
    const [enableReorder, setEnableReorder] = useState(false);
    const [isOrderDifferent, setIsOrderDifferent] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOrganization() {
            const queryResult = await getOrganizationById(organizationId);
            if (queryResult.isSuccess) {
                setOrganization(queryResult.result);
                getPointTables(queryResult.result);
                setPointTableSubscription(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        async function setPointTableSubscription(organization: Organization) { 
            // subscription = DataStore.observe(PointTable).subscribe(() => {
            //     if (organization) getPointTables(organization);
            //     else console.error("No organization to check for new prize money tables")
            // });
        }

        getOrganization();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
    }, [organizationId]);

    async function getPointTables(organization: Organization) {
        const queryResult = await getPointTablesByOrganizationId(organization.id);
        if (queryResult.isSuccess) {
            setPointTables(queryResult.result);
        }
    }

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        // The `from` and `to` properties contain the index of the item
        // when the drag started and ended, respectively
      
        setIsOrderDifferent(true);

        // Finish the reorder and position the item in the DOM based on
        // where the gesture ended. This method can also be called directly
        // by the reorder group
        event.detail.complete();
    }

    const handleNewOrder = () => {
        setIsOrderDifferent(false);
    }

    const onSelectPointTable = (table: PointTable) => {
        onSelect(table);
    }

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>Your Points Tables</IonCardTitle>
            <IonCardSubtitle color="danger">Refresh page to see changes.</IonCardSubtitle>
            <IonCardSubtitle>Click on a table to edit it.</IonCardSubtitle>
            {/* <IonRow>
                <IonCol sizeXs="12" sizeMd="8" sizeLg="6">
                    <IonItem lines="none">
                        <IonLabel slot="start">Re-Order?</IonLabel>
                        <IonToggle color="tertiary" checked={enableReorder} onIonChange={e => setEnableReorder(e.detail.checked)}/>
                    </IonItem> 
                </IonCol>
            </IonRow> */}
            {isOrderDifferent && (
                <IonRow>
                    <IonCol>
                        <IonButton color="tertiary" onClick={handleNewOrder}>Save New Order</IonButton>
                    </IonCol>
                </IonRow>
            )}
            <IonList className="bg-white">
                {pointTables && pointTables.length ?
                    <IonReorderGroup disabled={!enableReorder} onIonItemReorder={doReorder}>
                        {pointTables.map((pmt, index) => (
                            <IonItem key={index} onClick={() => onSelectPointTable(pmt)}>
                                <IonLabel>
                                    {pmt.name} 
                                    <br/>
                                    <p className="description">{pmt.description}</p>
                                </IonLabel>
                                <IonReorder slot="start" />
                            </IonItem>
                        ))}
                    </IonReorderGroup>
                    :
                    <p>No tables yet.</p>
                }
            </IonList>
        </IonCard>
    );
};

export default PointTableEditTable;