import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import React, { useEffect, useState } from "react";
import { Clinician } from "../../../../../models";
import Spinner from "../../../../../components/Spinners/Spinner";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import { getAllClinicians } from "../../../../../utilities/clinician/Clinician";
import { useHistory } from "react-router";

const ViewClinicianPage: React.FC = () => {
    const history = useHistory();
    
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [clinicians, setClincians] = useState<Clinician[] | null | undefined>();

    useEffect(() => {
        async function getClinicians() {
            setIsLoading(true);
            const queryResult = await getAllClinicians();
            if (queryResult.isSuccess) {
                setClincians(queryResult.result);
            } else {
                setError("No clinicians found.");
            }
            setIsLoading(false);
        }

        getClinicians();
    }, []);

    const handleEditClinician = (selectedClinician: Clinician) => {
        const path = "/index/admin/clinicians/" + selectedClinician.id;
        history.push(path);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Clinicians" />
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>All Clinicians</IonCardTitle>
                            <IonCardContent>
                                {error && (<ErrorAlert error={error} />)}
                                {isLoading ?
                                    <Spinner />
                                    :
                                    <>
                                        {clinicians ? 
                                            <IonList className="bg-white">
                                                {clinicians.map((clinician, index) => (
                                                    <IonItem key={index}>
                                                        <IonLabel>
                                                            <IonRow>
                                                                <IonCol>
                                                                    {clinician.name}
                                                                </IonCol>
                                                                <IonCol>
                                                                    {clinician.status}
                                                                </IonCol>
                                                                <IonCol className="ion-text-right">
                                                                    <IonButton onClick={() => handleEditClinician(clinician)}>Edit</IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonLabel>
                                                    </IonItem>
                                                ))}
                                            </IonList>
                                            :
                                            <p>None found</p>
                                        }
                                    </>
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default ViewClinicianPage;