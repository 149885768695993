import { 
    IonCol, 
    IonRow
} from "@ionic/react";
import React, { useState } from "react";
import { Event } from "../../../models";
import EventReportCard from "../EventReportCard";
import { generateEntriesWithoutStablingPDF, generateStablingPDF } from "../../../utilities/reports/StablingPDF";

interface _Props {
    event: Event
}

const EventReportStablingSection: React.FC<_Props> = ({event}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [progressMessage, setProgressMessage] = useState("");
    const [error, setError] = useState("");

    const generateStablingReportByTrainer = async () => {
        if (event) {
            setIsLoading(true); 
            await generateStablingPDF(event, false);
            setIsLoading(false); 
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateStablingReportByStallType = async () => {
        if (event) {
            setIsLoading(true); 
            await generateStablingPDF(event, true);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateStablingReportNotAcceptedStallsByTrainer = async () => {
        if (event) {
            setIsLoading(true); 
            await generateStablingPDF(event, false, true);
            setIsLoading(false); 
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateEntriesWithoutStablingReport = async () => {
        if (event) {
            setIsLoading(true); 
            await generateEntriesWithoutStablingPDF(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const reportLinks = (
        <IonRow>
            <IonCol>
                <IonRow>
                    <IonCol>
                        <p onClick={generateStablingReportByTrainer} className="link ion-text-wrap">Accepted Stall Requests - Sorted By Trainer</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={generateStablingReportByStallType} className="link ion-text-wrap">Accepted Stall Requests - Sorted By Stall Type</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={generateStablingReportNotAcceptedStallsByTrainer} className="link ion-text-wrap">Not Accepted Stall Requests - Sorted By Trainer</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={generateEntriesWithoutStablingReport} className="link ion-text-wrap">Entries without Stabling Requested</p>
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
    )

    return (
        <>
            <EventReportCard title="Stabling Reports" content={reportLinks} isLoading={isLoading} progressMessage={progressMessage} error={error} />
        </>
    );
};

export default EventReportStablingSection;
