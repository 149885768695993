import moment from "moment";
import { Event } from "../../models";
import { getFormattedEntryClassList } from "../eventClassEntry/FormattedEventEntryClasses";
import { getAcceptedEventEntriesByEventId } from "../eventEntry/EventEntry";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export async function generateRevenueFromEntryFees(event: Event) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Entry Fee Analysis Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = ["Charge", "Amount", "Tax", "Total"];
    // define an empty array of rows
    const tableRows = [];

    const tax = event.invoiceOptions?.classFeeTax;

    let totalEntryFeeAmount = 0;

    const queryResult = await getAcceptedEventEntriesByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventEntries = queryResult.result;
        if (eventEntries.length > 0) {
            for (let i = 0; i < eventEntries.length; i++) {
                const eventEntry = eventEntries[i];
                const dataResult = await getFormattedEntryClassList(eventEntry, true);
                if (dataResult.isSuccess) {
                    const totalEntryFeesForEntry = dataResult.result.totalEntryFees;
                    totalEntryFeeAmount = totalEntryFeeAmount + totalEntryFeesForEntry;
                };
            }
        }
    }

    const totalEntryFeeRow = [
        "Total Entry Fees",
        "$" + totalEntryFeeAmount.toLocaleString(),
        tax + "%",
        "$" + (totalEntryFeeAmount + (totalEntryFeeAmount * (tax || 0)/100)).toLocaleString()
    ];

    tableRows.push(totalEntryFeeRow);
     
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 35 
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    doc.save(`revenue_from_entry_fees.pdf`);
}