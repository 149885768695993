import {
    IonContent,
    IonPage
} from "@ionic/react";
import Header from "../../../../../../components/Headers/Header";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import React, { useEffect, useState } from "react";
import MembershipTypeForm from "../../../../../../components/MembershipTypes/MembershipTypeForm";
import { getOrganizationById } from "../../../../../../utilities/organization/Organization";
import { Organization, OrganizationMembershipType } from "../../../../../../models";
import Spinner from "../../../../../../components/Spinners/Spinner";
import ErrorAlert from "../../../../../../components/Errors/ErrorAlert";
import { RouteComponentProps } from "react-router";
import { getOrganizationMembershipTypeById } from "../../../../../../utilities/organizationMembershipType/OrganizationMembershipType";

interface _Props extends RouteComponentProps<{
    id: string;
}> {}

const EditMembershipTypePage: React.FC<_Props> = ({match}) => {
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [membershipType, setMembershipType] = useState<OrganizationMembershipType | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        async function getOrganization() {
            if(membershipType?.organizationId) {
                setIsLoading(true);
                const queryResult = await getOrganizationById(membershipType.organizationId);
                if (queryResult.isSuccess) {
                    setOrganization(queryResult.result);
                } else {
                    setError(queryResult.message);
                }
                setIsLoading(false);
            }
        }
        getOrganization();
    }, [membershipType]);
    
    useEffect(() => {
        async function getMembershipType() {
            setIsLoading(true);
            const queryResult = await getOrganizationMembershipTypeById(match.params.id);
            if (queryResult.isSuccess) {
                setMembershipType(queryResult.result);
            } else {
                setError(queryResult.message);
            }
            setIsLoading(false);
        }
        getMembershipType();
    }, [match, match.params.id]);

   
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                {error && <ErrorAlert width="12" error={error} />}
                <PageTitle title={"Edit Membership Type"} />
                    {organization && membershipType && !isLoading ?
                        <MembershipTypeForm membershipType={membershipType} organization={organization}/>
                    :   
                        <Spinner/> 
                    }
            </IonContent>
        </IonPage>
    );
};

export default EditMembershipTypePage;
