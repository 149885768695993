import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { close } from "ionicons/icons";
import moment from "moment";
import { Membership } from "../../models";

interface _Props {
    show: boolean
    membership: Membership
    allowOptionToDelete: Boolean
    onDelete?: Function
}

const ViewMembershipInfoModal: React.FC<_Props> = ({show, membership, allowOptionToDelete, onDelete}) => {
    const [data, setData] = useState<Membership | null | undefined>(membership);
    const [showModal, setShowModal] = useState(show);

    useEffect(() => {
        setShowModal(show);
    }, [show]);

    useEffect(() => {
        setShowModal(true);
        setData(membership);
    }, [membership]);

    const handleDeleteClick = () => {
        if (onDelete) onDelete();
        setShowModal(false);
    }

    const handleCloseClick = () => {
        setShowModal(false);
    }

    return (
        <>
            <IonModal backdropDismiss={false} isOpen={showModal} id="membershipInfoModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        {data ? data.name : ""}
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeMembershipInfoModal" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                {data ?
                    <>
                        <IonRow>
                            <IonCol>
                                <IonText><h6>Membership: {data.name}</h6></IonText>
                            </IonCol>
                        </IonRow>
                        {data.name === "RHSC" && (
                            <IonRow>
                                <IonCol>
                                    <IonText><h6>Back Number: {data.backNumber}</h6></IonText>
                                </IonCol>
                            </IonRow>
                        )}
                        <IonRow>
                            <IonCol>
                                <IonText><h6>Member ID: {data.membershipId}</h6></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonText><h6>Type: {data.type}</h6></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonText><h6>Status: {data.membershipStatus}</h6></IonText>
                            </IonCol>
                        </IonRow>
                        {data.name === "RHSC" && (
                            <IonRow>
                                <IonCol>
                                    <IonText><h6>Volunteer Hours: {data.volunteerHours}</h6></IonText>
                                </IonCol>
                            </IonRow>
                        )}
                        {data.name === "RHSC" && (
                            <IonRow>
                                <IonCol>
                                    <IonText><h6>Meetings Attended: {data.meetingsAttended}</h6></IonText>
                                </IonCol>
                            </IonRow>
                        )}
                        <IonRow>
                            <IonCol>
                                <IonText><h6>Date Membership Ends: {data.dateMembershipEnds ? moment(data.dateMembershipEnds).format("MMM DD, YYYY") : ""}</h6></IonText>
                            </IonCol>
                        </IonRow>
                        {allowOptionToDelete && (
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeMd="6" className="ion-text-center">
                                    <IonButton
                                        id="confirmDeleteBtn"
                                        className="ion-margin-top"
                                        color="danger"
                                        expand="block"
                                        onClick={handleDeleteClick}

                                    >
                                        Delete Membership
                                    </IonButton>
                                </IonCol>
                                <IonCol sizeMd="6" className="ion-text-center">
                                    <IonButton
                                        id="cancelButton"
                                        className="ion-margin-top"
                                        color="light"
                                        expand="block"
                                        onClick={handleCloseClick}
                                    >
                                        Close
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        )}
                    </>
                    :
                    <p>Sorry, a problem occured and we could not display the membership information.</p>
                }
                </IonContent>
            </IonModal>
        </>
    );
};

export default ViewMembershipInfoModal;