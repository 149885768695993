import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../../../../components/Headers/Header";
import { BacklogEventResult, Event, EventClass, Membership, Organization, OrganizationClass, OrganizationDivision, PointTable } from "../../../../../models";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import Spinner from "../../../../../components/Spinners/Spinner";
import { PersonContext } from "../../../../../context/PersonContext";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import FileBrowser from "../../../../../components/FileBrowser/FileBrowser";
import { isCSV } from "../../../../../utilities/files/File";

import { getOrganizationClassesByOrganizationId } from "../../../../../utilities/organizationClass/OrganizationClass";
import { getOrganizationDivisionsByOrganizationId } from "../../../../../utilities/organizationDivision/OrganizationDivision";
import { Table } from "reactstrap";
import { getMembershipsByOrganizationId } from "../../../../../utilities/membership/Membership";
import {ExcelRenderer} from "react-excel-renderer";
import moment from "moment";
import { getEventClassesByEventId } from "../../../../../utilities/eventClass/EventClass";
import { getEventsByOrganizationId } from "../../../../../utilities/events/Event";
import { CreateBacklogEventResultInput } from "../../../../../API";
import { createBacklogEventResult, deleteBacklogEventResult, getAllBacklogEventResults } from "../../../../../utilities/backlogEventResult/BacklogEventResult";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import constants from "../../../../../constant/constant";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

interface FormattedData {
    organizationMemberId?: string
    organizationResultId?: string
    eventId?: string
    eventName?: string
    eventStartDate?: string
    eventEndDate?: string
    eventDivisionId?: string
    eventDivisionName?: string
    organizationDivisionId?: string
    organziationDivisionName?: string
    eventClassId?: string
    eventClassNumber?: string
    eventClassName?: string
    eventClass?: EventClass
    organizationClassId?: string
    organziationClass?: OrganizationClass
    place?: string
    pointsEarned?: string
    score?: string
    time?: string
    pointTable?: PointTable
    riderName?: string
    horseName?: string
}

const EventOrganizationUploadPointDataPage: React.FC<OrganizationPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [organization, setOrganization] = useState<Organization>();
    const [events, setEvents] = useState<Event[] | null | undefined>();
    const [title, setTitle] = useState("");
    const [formattedData, setFormattedData] = useState<FormattedData[] | null | undefined>();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    async function getOrganization() {
        try {
            setIsLoading(true);
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                const organization: Organization = queryResult.result;
                setOrganization(organization);
                getOrganizationEvents(organization.id);
                setIsLoading(false);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
                setIsLoading(false);
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }
    async function getOrganizationEvents(organizationId: string) {
        try {
            setIsLoading(true);
            const queryResult = await getEventsByOrganizationId(organizationId);
            if (queryResult.isSuccess) {
                const events: Event[] = queryResult.result;
                setEvents(events);
                setIsLoading(false);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
                setIsLoading(false);
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOrganization();
    }, [user, match.params.id]);

    const ExcelDateToJSDate = (date: any) => {
        let converted_date: any = new Date(Math.round((date - 25568) * 864e5));
        return moment(converted_date).format("MMM DD, YYYY");
    }

    const getPlace = (pointsValue: string) => {
        if (pointsValue === "6") return "1";
        if (pointsValue === "5") return "2";
        if (pointsValue === "4") return "3";
        if (pointsValue === "3") return "4";
        if (pointsValue === "2") return "5";
        if (pointsValue === "1") return "6";
        else return "0";
    }

    const newFormatData = async (file: File) => {
        try {
            if (organization) {
                let organizationClasses: OrganizationClass[] = [];
                const organizationClassesResult = await getOrganizationClassesByOrganizationId(organization.id);
                if (organizationClassesResult.isSuccess) {
                    organizationClasses = organizationClassesResult.result;
                }
                let eventClasses: EventClass[] = [];
                const eventClassesResult = await getEventClassesByEventId("0c98842f-66d7-49d7-8f9e-8d3ae99eaaf6"); //Change in prod
                if (eventClassesResult.isSuccess) {
                    eventClasses = eventClassesResult.result;
                }
                
                let organizationMembers: Membership[] = [];
                const organizationMembersResult = await getMembershipsByOrganizationId(organization.id);
                if (organizationMembersResult.isSuccess) {
                    organizationMembers = organizationMembersResult.result;
                }
                let formattedArray: FormattedData[] = [];
                
                ExcelRenderer(file, async (err: any, resp: any) => {
                    if(err){
                        setError("Sorry, we could not upload the file. If the issue persists, contact customer service.");
                        console.error(err);   
                        setIsLoading(false);         
                    }
                    else{
                        let rows = resp.rows;

                        for (let i = 1; i < rows.length; i++) {
                            const rowData = rows[i];

                            const resultId = rowData[0];
                            const showDate = ExcelDateToJSDate(rowData[1]);

                            const currentEvent: Event | undefined = await getEvent(showDate);
                            
                            const eventClassId = rowData[2];
                            const backNumber = rowData[3];
                            const points:string = rowData[4];
                            const place = getPlace(points);
                            let organizationClass = organizationClasses.find(oc => oc.number === eventClassId);
                            let eventClass = eventClasses.find(ec => ec.number === eventClassId);
                            const organziationDivisionName = eventClass?.organizationDivision?.name;

                            const organizationMember = organizationMembers.find(om => om.backNumber === backNumber);
                            const formattedData: FormattedData = {
                                eventId: currentEvent?.id || "",
                                eventName: currentEvent?.name || "Not found",
                                organizationMemberId: backNumber,
                                organizationResultId: resultId,
                                eventStartDate: showDate ? showDate : "",
                                eventEndDate: showDate ? showDate : "",
                                pointsEarned: points,
                                place: place,
                                eventClassNumber: eventClassId,
                                eventClassId: eventClass?.id || "",
                                eventClass: eventClass,
                                eventClassName: eventClass?.name || "",
                                eventDivisionId: eventClass?.eventDivision?.id || "",
                                eventDivisionName: eventClass?.eventDivision?.name || "",
                                organziationClass: organizationClass,
                                organizationClassId: organizationClass?.id || "",
                                organziationDivisionName: organziationDivisionName || "",
                                organizationDivisionId:  eventClass?.eventDivision?.organizationDivisionId || "",
                                riderName: organizationMember?.rider?.name || "",
                                horseName: organizationMember?.horse?.name || "",
                            };
                            formattedArray.push(formattedData);
                        }
                        setFormattedData(formattedArray);
                    }
                });
            }
        } catch (e: any) {
            console.error(e);
        }
    }

    const getEvent = (eventDate: string) => {
        if (events) {
            for (let i = 0; i < events.length; i++) {
                const event: Event = events[i];
                const eventStartDate = event.startDate;
                const eventEndDate = event.endDate;
                
                const currentEventDate = moment(eventDate).format("YYYY-MM-DD");

                if (currentEventDate === eventDate) return event;
                if (moment(currentEventDate).isBetween(moment(eventStartDate), moment(eventEndDate), undefined, "[]")) return event;
            }
        }
    }

    const handleAddData = async () => {
        setError("");
        setIsLoading(true);
        if (formattedData && formattedData.length > 0) {
            for (let i = 0; i < formattedData.length; i++) {
                const data: FormattedData = formattedData[i];

                let event: Event | undefined = undefined;
                if (data.eventStartDate) event = getEvent(data.eventStartDate);
                let eventName = event?.name || "RHSC " + moment(data.eventStartDate).format("MMM DD") + " Show";
                
                const input: CreateBacklogEventResultInput = {
                    organizationId: constants.RHSC_ORGANIZATION.id,
                    organizationMemberId: data.organizationMemberId,
                    organizationResultId: data.organizationResultId,
                    eventId: event?.id || "",
                    eventName: eventName,
                    eventStartDate: data.eventStartDate ? moment(data.eventStartDate).format("YYYY-MM-DD") : "",
                    eventEndDate: data.eventEndDate ? moment(data.eventEndDate).format("YYYY-MM-DD") : "",
                    eventDivisionId: data.eventDivisionId,
                    eventDivisionName: data.eventDivisionName,
                    eventClassId: data.eventClassId,
                    eventClassName: data.eventClassName,
                    organizationDivisionId: data.organizationDivisionId,
                    organizationClassId: data.organizationClassId,
                    riderName: data?.riderName || "",
                    horseName: data?.horseName || "",
                    place: data.place ? parseInt(data.place) : 0,
                    pointsEarned: data.pointsEarned ? parseInt(data.pointsEarned) : 0,
                    score: data.score ? parseFloat(data.score) : 0,
                    time: data.time,
                    createdBy: user.id
                };
                await createBacklogEventResult(input);
            }
        } else {
            setError("No data found");
        }
        setIsLoading(false);
    }

    const handleRemoveBacklogData = async () => {
        setError("");
        setIsLoading(true);
        const oldResults = await getAllBacklogEventResults();
        if (oldResults.isSuccess) {
            const array: BacklogEventResult[] = oldResults.result;
            for (let i = 0; i < array.length; i++) {
                const element = array[i];
                await deleteBacklogEventResult({id: element.id});
            }
        }
        setIsLoading(false);
    }

    const handleSelectedFile = async (file: File) => {
        setIsLoading(true);
        try {
            setTitle(file.name);
            if (isCSV(file)) {
                await newFormatData(file);
                setError("");
            }
            else setError("Please check that your file is one of: .xlsx, .xls, .xlt, .csv");
        } catch (error: any) {
            setError(error);
        }
        setIsLoading(false);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Upload Points" />
                {isLoading ?
                    <Spinner />
                    :   
                    <>
                        {organization ?
                            <IonRow>
                                {error && <ErrorAlert error={error} />}
                                <IonCol>
                                    <IonCard mode="md" className="ion-padding bg-white">
                                        <IonCardTitle>Choose a File</IonCardTitle>
                                        <IonCardContent>
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                                    <FileBrowser handleSelectedFile={handleSelectedFile}/>
                                                </IonCol>
                                            </IonRow>
                                            {title && (
                                                <IonRow className="py-3 ion-justify-content-center">
                                                    <IonCol size="12">
                                                        <h1>{title}</h1>
                                                    </IonCol>
                                                </IonRow>
                                            )}
                                            {(formattedData && formattedData.length > 0) && (
                                                <IonRow>
                                                    <IonCol size="6">
                                                        <IonButton color="success" onClick={() => handleAddData()}>
                                                            Add Backlog Data
                                                        </IonButton>
                                                    </IonCol>
                                                    <IonCol size="6">
                                                        <IonButton color="danger" onClick={() => handleRemoveBacklogData()}>
                                                            Remove Backlog Data
                                                        </IonButton>
                                                    </IonCol>
                                                    <IonCol>
                                                        <Table hover responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th>Event Date</th>
                                                                    <th>Event Name</th>
                                                                    <th>Class Number</th>
                                                                    <th>Class</th>
                                                                    <th>Division</th>
                                                                    <th>Rider's Back Number</th>
                                                                    <th>Rider's Name</th>
                                                                    <th>Horse's Name</th>
                                                                    <th>Points</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {formattedData.map((data, index) => (
                                                                    <tr key={index}>
                                                                        <td>{data.eventStartDate}</td>
                                                                        <td>{data.eventName}</td>
                                                                        <td>{data.eventClassId}</td>
                                                                        <td>{data.organziationClass?.name || ""}</td>
                                                                        <td>{data.organziationDivisionName || ""}</td>
                                                                        <td>{data.organizationMemberId || ""}</td>
                                                                        <td>{data.riderName || ""}</td>
                                                                        <td>{data.horseName || ""}</td>
                                                                        <td>{data.pointsEarned || ""}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </IonCol>
                                                </IonRow>
                                            )}
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                            :
                            <p>No organization found</p>
                        }
                    </>
                }

            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationUploadPointDataPage;