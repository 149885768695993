import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useState, useEffect } from "react";
import PageList from "../../../../components/Lists/PageList";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import Spinner from "../../../../components/Spinners/Spinner";
import { PersonContext } from "../../../../context/PersonContext";
import { Team } from "../../../../models";
import BasicTeamInfo from "../../../../components/Team/BasicTeamInfo";
import { teamListOptions } from "../../../../interfaces/Page";
import { getTeamsByPersonId } from "../../../../utilities/team/Team";
import { getTeamMembersCreatedByUser } from "../../../../utilities/teamMember/TeamMembersByUser";
import moment from "moment";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";

const TeamPage: React.FC = () => {
    const user = useContext(PersonContext);

    const [currentTeam, setCurrentTeam] = useState<Team | null | undefined>();
    const [allTeams, setAllTeams] = useState<Team[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        /**
         * To determine if this person already has joined at least one team
         * Check to see if there are any "TeamMember" records created by this person id
         */
        const getTeamMembers = async () => {
            setError("");
            // Check for team members created by the current user
            const teamMembersCreatedByCurrentUser = await getTeamMembersCreatedByUser(user);
            if (teamMembersCreatedByCurrentUser.length > 0) {
                // Assume all team members created by a user will belong to one team
                // TO DO - allow a user to have team members on multiple teams
                teamMembersCreatedByCurrentUser.forEach(teamMember => {
                    if (moment(teamMember.createdOn).isAfter("07-31-2024")) {
                        const team = teamMember.team;
                        setCurrentTeam(team);
                    }
                    setAllTeams(prev => prev ? prev.concat([teamMember.team]) : []);
                });
            } else {
                const queryResultByPerson = await getTeamsByPersonId(user.id);
                if (queryResultByPerson.isSuccess) {
                    const allTeamsCreatedByCurrentUser = queryResultByPerson.result;
                    // Check for only teams that are current
                    allTeamsCreatedByCurrentUser.forEach((team: Team) => {
                        if (moment(team.createdOn).isAfter("07-31-2024")) {
                            setCurrentTeam(team);
                        }
                    });
                }
                setIsLoading(false);
            }
        };
        if (user && user.id) getTeamMembers();
        else setError("No user info found. Please reload the page. If you need customer support, contact hello@ringsidepro.com.");
    }, [user.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Team" />
                {error && <ErrorAlert error={error} />}
                {isLoading ?
                    <Spinner />
                    :
                    <>
                        {currentTeam ?
                            <>
                                <IonRow className="ion-text-center ion-justify-content-center">
                                    <IonCol sizeSm="12" sizeMd="8">
                                        <BasicTeamInfo team={currentTeam} />
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-text-center ion-justify-content-center">
                                    <IonCol sizeSm="12" sizeMd="8">
                                        <PageList id={currentTeam.id} includeId={true} listOptions={teamListOptions} />
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeSm="12" sizeMd="12">
                                    <IonCard mode="md" className="ion-padding bg-white">
                                        <IonCardTitle>Join a Team</IonCardTitle>
                                        <IonCardContent className="ion-text-center">
                                            <p className="ion-text-center pb-4">
                                                You are not currently a member of a team. 
                                                Go to the Dashboard page and complete an application in order to join a team.
                                            </p>
                                            <a href="/index/ael/home">
                                                <IonButton>
                                                    Go to Dashboard
                                                </IonButton>
                                            </a>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        }
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default TeamPage;