import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventAlertSettingInput, CreateEventAlertSettingMutation, DeleteEventAlertSettingInput, DeleteEventAlertSettingMutation, GetEventAlertSettingQuery, ListEventAlertSettingsQuery, UpdateEventAlertSettingInput, UpdateEventAlertSettingMutation } from "../../API";
import moment from "moment";
import { EventAlertSetting } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventAlertSetting. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventAlertSetting.
*/
export async function createEventAlertSetting(input: CreateEventAlertSettingInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventAlertSetting", "No input", "Create EventAlertSetting received no input.");
   try {
        const fullInput: CreateEventAlertSettingInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventAlertSetting, { input: fullInput }))) as GraphQLResult<CreateEventAlertSettingMutation>;
       else result = (await API.graphql({
           query: mutations.createEventAlertSetting,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventAlertSettingMutation>;
       const eventAlertSetting = result.data?.createEventAlertSetting;
       return formatResult(true, "EventAlertSetting", eventAlertSetting, "Successfully created the eventAlertSetting.");
   } catch (error: any) {
       return formatResult(false, "EventAlertSetting", error, "Error creating record in the database for type: eventAlertSetting");
   }
}

/**
* Handle updating a new record in the database for type: eventAlertSetting. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventAlertSetting.
*/
export async function updateEventAlertSetting(input: UpdateEventAlertSettingInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventAlertSetting", "No input", "Update EventAlertSetting received no input.");
   try {
        const fullInput: UpdateEventAlertSettingInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventAlertSetting, { input: fullInput }))) as GraphQLResult<UpdateEventAlertSettingMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventAlertSetting,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventAlertSettingMutation>;
       const eventAlertSetting = result.data?.updateEventAlertSetting;
       return formatResult(true, "EventAlertSetting", eventAlertSetting, "Successfully updated the eventAlertSetting.");
   } catch (error: any) {
       return formatResult(false, "EventAlertSetting", error, "Error updating record in the database for type: eventAlertSetting");
   }
}

/**
* Handle deleting a new record in the database for type: eventAlertSetting. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventAlertSetting.
*/
export async function deleteEventAlertSetting(input: DeleteEventAlertSettingInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventAlertSetting", "No input", "Delete EventAlertSetting received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventAlertSetting, { input: input }))) as GraphQLResult<DeleteEventAlertSettingMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventAlertSetting,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventAlertSettingMutation>;
       const eventAlertSetting = result.data?.deleteEventAlertSetting;
       return formatResult(true, "EventAlertSetting", eventAlertSetting, "Successfully deleted the eventAlertSetting.");
   } catch (error: any) {
       return formatResult(false, "EventAlertSetting", error, "Error deleting record in the database for type: eventAlertSetting");
   }
}

/**
* Get all records in the database for type: eventAlertSetting. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAlertSetting object
*/
export async function getAllEventAlertSettings(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventAlertSettings,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventAlertSettingsQuery>;

        let items = result.data?.listEventAlertSettings?.items as EventAlertSetting[];
        let nextToken = result.data?.listEventAlertSettings?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventAlertSettings,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventAlertSettingsQuery>;

            const nextItems = nextResult.data?.listEventAlertSettings?.items as EventAlertSetting[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventAlertSettings?.nextToken;
        }

        return formatResult(true, "EventAlertSetting", items, "Successfully got the EventAlertSettings.");
    } catch (error: any) {
        return formatResult(false, "EventAlertSetting", error, "Error reading record in the database for type: eventAlertSettings");
    }
}

/**
* Read a specific record in the database for type: eventAlertSetting. 
* 
* @param {string}  id                  The eventAlertSetting id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAlertSetting object
*/
export async function getEventAlertSettingById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventAlertSetting,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventAlertSettingQuery>;
       const eventAlertSetting = result.data?.getEventAlertSetting;
       return formatResult(true, "EventAlertSetting", eventAlertSetting, "Successfully got the eventAlertSetting.");
   } catch (error: any) {
       return formatResult(false, "EventAlertSetting", error, "Error reading record in the database for type: eventAlertSetting");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventAlertSetting. 
* 
* @param {string}  eventId             The id of the event
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAlertSetting object
*/
export async function getEventAlertSettingByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventAlertSettings,
            variables: {
                limit: 10,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventAlertSettingsQuery>;

        let items = result.data?.listEventAlertSettings?.items as EventAlertSetting[];
        let nextToken = result.data?.listEventAlertSettings?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventAlertSettings,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventAlertSettingsQuery>;

            const nextItems = nextResult.data?.listEventAlertSettings?.items as EventAlertSetting[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventAlertSettings?.nextToken;
        }

        const eventAlertSettings = items;
        if (eventAlertSettings && eventAlertSettings[0]) return formatResult(true, "EventAlertSetting", eventAlertSettings[0], "Successfully got the eventAlertSetting.");
        else return formatResult(false, "EventAlertSetting", null, "Could not find the eventAlertSetting.");
    } catch (error: any) {
        return formatResult(false, "EventAlertSetting", error, "Error reading record in the database for type: eventAlertSetting");
    }
}