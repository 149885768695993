import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import AddressFormGroup from "../Address/AddressFormGroup";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import { createAddress, updateAddress } from "../../utilities/address/Address";
import { updateBarn } from "../../utilities/barn/Barn";
import { createContact, updateContact } from "../../utilities/contact/Contact";
import { CreateAddressInput, CreateContactInput, UpdateAddressInput, UpdateBarnInput, UpdateContactInput } from "../../API";
import moment from "moment";
import { formatTwilioNumber } from "../../utilities/contact/FormatPhoneNumber";
import { formatURL } from "../../utilities/contact/FormatURL";
import { Address, Barn, Contact } from "../../models";
import SelectBarnType from "./SelectBarnType";
import { Result } from "../../interfaces/Result";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    currentBarn: Barn
    onChange: Function
}


const UpdateBarnForm: React.FC<_Props> = ({currentBarn, onChange}) => {
    const user = useContext(PersonContext);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState(currentBarn.name || "");
    const [type, setType] = useState(currentBarn.type || "");
    const [phoneNumber, setPhoneNumber] = useState(currentBarn.contact?.work || "");
    const [emailAddress, setEmailAddress] = useState(currentBarn.contact?.workEmail || "");
    const [website, setWebsite] = useState(currentBarn.contact?.website || "");
    const [facebookPage, setFacebookPage] = useState(currentBarn.contact?.facebookPage || "");
    const [streetAddressLine1, setStreetAddressLine1] = useState(currentBarn.location?.streetAddress1 || "");
    const [streetAddressLine2, setStreetAddressLine2] = useState(currentBarn.location?.streetAddress2 || "");
    const [city, setCity] = useState(currentBarn.location?.city || "");
    const [provState, setProvState] = useState(currentBarn.location?.provState || "");
    const [zip, setZip] = useState(currentBarn.location?.zip || "");
    const [country, setCountry] = useState(currentBarn.location?.country || "");
    const [error, setError] = useState("");

    const verifyForm = () => {
        if (!name) {
            setError("Please include a name for the barn.");
            return false;
        }
        if (!type) {
            setError("Please include a type for the barn.");
            return false;
        }
        if (!streetAddressLine1) {
            setError("Please include a street address for the barn.");
            return false;
        }
        if (!city) {
            setError("Please include a city for the barn.");
            return false;
        }
        if (!provState) {
            setError("Please include a state or providence for the barn.");
            return false;
        }
        if (!zip) {
            setError("Please include a zip/area code for the barn.");
            return false;
        }
        if (!country) {
            setError("Please include a country for the barn.");
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            setIsLoading(true);
            setIsDisabled(true);
            await handleUpdateBarn();
            setIsLoading(false);
        } 
    }

    const updateBarnAddress = async () => {
        if (currentBarn.location) {
            const addressName = name + "_mailing_address";
            const addressInput: UpdateAddressInput = {
                id: currentBarn.location?.id,
                name: addressName,
                type: "mailing",
                streetAddress1: streetAddressLine1,
                streetAddress2: streetAddressLine2,
                city: city,
                provState: provState,
                zip: zip,
                country: country
            };
            const updateAddressResult = await updateAddress(addressInput);
            if (updateAddressResult.isSuccess) {
                return updateAddressResult.result;
            } else {
                setError("Could not update the address info.");
            }
        } else {
            const addressName = name + "_mailing_address";
            const addressInput: CreateAddressInput = {
                name: addressName,
                type: "mailing",
                streetAddress1: streetAddressLine1,
                streetAddress2: streetAddressLine2,
                city: city,
                provState: provState,
                zip: zip,
                country: country
            };
            const createAddressResult = await createAddress(addressInput);
            if (createAddressResult.isSuccess) {
                return createAddressResult.result;
            } else {
                setError("Could not create the address info.");
            }
        }
    }

    const updateBarnContact = async () => {
        if (currentBarn.barnContactId) {
            let contactInput: UpdateContactInput = {
                id: currentBarn.barnContactId,
                name: name,
                work: formatTwilioNumber(phoneNumber) || "",
                workEmail: emailAddress || "",
                website: formatURL(website) || "",
                facebookPage: formatURL(facebookPage) || ""
            };
            const updateContactResult = await updateContact(contactInput);
            if (updateContactResult.isSuccess) {
                return updateContactResult.result;
            } else {
                setError("Could not update the contact info.");
            }
        } else {
            let contactInput: CreateContactInput = {
                name: name,
                work: formatTwilioNumber(phoneNumber) || "",
                workEmail: emailAddress || "",
                website: formatURL(website) || "",
                facebookPage: formatURL(facebookPage) || ""
            };
            const createContactResult = await createContact(contactInput);
            if (createContactResult.isSuccess) {
                return createContactResult.result;
            } else {
                setError("Could not create the contact info.");
            }
        }
    }

    const handleUpdateBarn = async () => {
        try {
            // First, update the Address Object
            const updateAddressResult: (Address | null | undefined) = await updateBarnAddress();

            // Next, update the contact
            const updateContactResult: (Contact | null | undefined) = await updateBarnContact();

            // Next, update the barn
            let input: UpdateBarnInput = {
                id: currentBarn.id,
                name: name, 
                type: type || "",
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            };
            if (updateAddressResult) input["barnLocationId"] = updateAddressResult.id;
            if (updateContactResult) input["barnContactId"] = updateContactResult.id;
            const updateBarnResult = await updateBarn(input);
            if (updateBarnResult.isSuccess) {
                if (onChange) onChange(updateBarnResult.result);
            } else {
                setError("Could not update the barn");
            }
        } catch (error: any) {
            const message = "Could not update the barn: " + error;
            setError(message);
        }
    }

    const handleAddressInputChange = (type: string, value: string) => {
        setError("");
        if (type === "streetAddressLine1") setStreetAddressLine1(value);
        if (type === "streetAddressLine2") setStreetAddressLine2(value);
        if (type === "city") setCity(value);
        if (type === "provState") setProvState(value);
        if (type === "zip") setZip(value);
        if (type === "country") setCountry(value);
    };

    return (
        <form id="barnForm">
            {error && <ErrorAlert width="12" error={error} />}
            <IonRow>
                <IonCol>
                    <IonText>
                        <h1>About</h1>
                    </IonText>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Name <RequiredInputIndicator /></IonLabel>
                        <IonInput 
                            id="barn-name-input"
                            type="text"
                            value={name}
                            aria-required={true}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setError("");
                                setName(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <SelectBarnType
                        isRequired={true}
                        typeValue={type}
                        onInputChange={(value: string) => {
                            setError("");
                            setType(value)
                        }}
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonText>
                        <h1>Address</h1>
                    </IonText>
                </IonCol>
            </IonRow>
            <AddressFormGroup
                id="update-barn"
                address={currentBarn.location || undefined}
                isRequired={true}
                isTextDarker={true}
                onChange={handleAddressInputChange}
                setIsDisabled={setIsDisabled}
            />
            <IonRow>
                <IonCol>
                    <IonText>
                        <h1>Contact Info</h1>
                    </IonText>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Barn Phone Number</IonLabel>
                        <IonInput 
                            id="barn-phoneNumber-input"
                            type="tel"
                            value={phoneNumber}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setPhoneNumber(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Barn Email Address</IonLabel>
                        <IonInput 
                            id="barn-email-input"
                            type="email"
                            value={emailAddress}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setEmailAddress(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Barn Website</IonLabel>
                        <IonInput 
                            id="barn-website-input"
                            type="text"
                            value={website}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setWebsite(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Barn Facebook Page</IonLabel>
                        <IonInput 
                            id="barn-facebook-input"
                            type="text"
                            value={facebookPage}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setFacebookPage(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            id="barn-update-btn"
                            disabled={isDisabled}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            Save
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </form>
    );
};

export default UpdateBarnForm;