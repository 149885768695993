import {Auth} from "aws-amplify";
import {Result} from "../../interfaces/Result";

interface AWSCognitoErrorResponse {
    code: string;
    message: string;
    name: string;
}

/**
 * AWS Amplify utility function
 * 
 * @param email (string)
 * @param password (string)
 * 
 * @returns a Result object - success means it returns a user. otw it returns the error message
 */
export async function forgotPassword(email: string): Promise<Result> {
    let result: Result;
    try {
        const username = email.toLowerCase();
        await Auth.forgotPassword(username);
        result = {isSuccess: true, type: "User", result: null, message: "Successfully sent the link to reset your password."};
        return result;
    } catch (error: any) {
        const message = handleErrors(error);
        result = {isSuccess: false, type: "User", result: error, message: message};
        return result;
    }
}

/**
 * AWS Amplify utility function
 * 
 * @param email (string)
 * @param password (string)
 * 
 * @returns a Result object - success means it returns a user. otw it returns the error message
 */
export async function resetPassword(email: string, code: string, password: string): Promise<Result> {
    let result: Result;
    try {
        const username = email.toLowerCase();
        await Auth.forgotPasswordSubmit(username, code, password);
        result = {isSuccess: true, type: "User", result: null, message: "Successfully reset your password."};
        return result;
    } catch (error: any) {
        console.error("error resetting password:", error);
        const message = handleErrors(error);
        result = {isSuccess: false, type: "User", result: error, message: message};
        return result;
    }
}

export function handleErrors(errorResponse: AWSCognitoErrorResponse) {
    if (errorResponse.code) {
        if (errorResponse.code === "UserNotFoundException") {
            return "Email address is not recognized.";
        }
        if (errorResponse.code === "NotAuthorizedException") {
            return "Incorrect email.";
        }
        if (errorResponse.code === "UserLambdaValidationException") {
            if (errorResponse.message.includes("PostConfirmation failed with error SyntaxError: await is only valid in async function")) {
                return "Sorry, an error occurred.";
            }
        }
        if (errorResponse.code === "CodeMismatchException") {
            return "The validation code is incorrect or not the newest code.";
        }
        if (errorResponse.code === "InvalidParameterException") {
            return "Password must include at least 8 characters.";
        }
    }
    return "Please double-check your information.";
};