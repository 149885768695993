import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { EventDay } from "../../models";
import { getEventDaysByEventId } from "../../utilities/eventDay/EventDay";
import Spinner from "../Spinners/Spinner";

interface _Props {
    eventId: string
    onSelect: Function
    selectedValue?: string
    isClearable?: boolean
}

interface formattedOption {
    label: string
    value: string
    eventDay: EventDay
}

const SelectEventDay: React.FC<_Props> = ({eventId, onSelect, selectedValue, isClearable}) => {

    const [formattedEventDays, setFormattedEventDays] = useState<formattedOption[] | null | undefined>();
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const formatEventDays = (eventDays: EventDay[]) => {
        let formattedEventDays = [];
        for (var i = 0; i < eventDays.length; i++) {
            const label = eventDays[i].dayofweek + " - " + eventDays[i].month + " " + eventDays[i].date + ", " + eventDays[i].year
            let object = {
                value: eventDays[i].id,
                label: label,
                eventDay: eventDays[i]
            };
            formattedEventDays.push(object);
        }
        setFormattedEventDays(formattedEventDays);
    }

    const findValueInList = (value: string) => {
        if (formattedEventDays) {
            let result: formattedOption;
            for (var i = 0; i < formattedEventDays.length; i++) {
                const currentOption = formattedEventDays[i];
                if (currentOption.value === value) {
                    result = currentOption;
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        async function getEventDays(eventId: string) {
            setIsLoading(true);
            const queryResult = await getEventDaysByEventId(eventId);
            if (queryResult.isSuccess) {
                formatEventDays(queryResult.result);
            }
            setIsLoading(false);
        }

        if (eventId) getEventDays(eventId);
    }, [eventId]);

    useEffect(() => {
        if (selectedValue) {
            findValueInList(selectedValue);
        } else {
            setCurrentValue(undefined);
        }
    }, [selectedValue]);

    const handleOnChange = async (event: any) => {
        if (event && event.eventDay) {
            onSelect(event.eventDay);
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <Select
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    defaultValue={currentValue}
                    value={currentValue}
                    menuPortalTarget={document.body}
                    isClearable={isClearable}
                    options={formattedEventDays!}
                    onChange={handleOnChange}
                />
            }
        </>
    );
};

export default SelectEventDay;
