import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Clinician} from "../../../../models";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { CreatePaymentMethodInput, PaymentMethodType, UpdateClinicianInput, UpdatePaymentMethodInput } from "../../../../API";
import { getClinicianByPersonId, updateClinician } from "../../../../utilities/clinician/Clinician";
import { createPaymentMethod, updatePaymentMethod } from "../../../../utilities/paymentMethod/PaymentMethod";
import ErrorBanner from "../../../../components/Banners/ErrorBanner";
import Spinner from "../../../../components/Spinners/Spinner";

interface SubmissionFeedbackPageProps extends RouteComponentProps<{
    personId: string;
    stripeId: string;
}> {}

const ClinicianStripeSuccessPage: React.FC<SubmissionFeedbackPageProps> = ({match}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    async function setStripeId(clinician: Clinician, stripeId: string) {
        let method = clinician.paymentChoice;
        if (method) {
            const updateInput: UpdatePaymentMethodInput = {
                id: method.id,
                type: PaymentMethodType.ach,
                stripeId: stripeId,
                altId: "",
                value: ""
            };
            const updateResult = await updatePaymentMethod(updateInput);
            if (updateResult.isSuccess) {
                method = updateResult.result;
            } else {
                setError("Could not update the account.");
            }
        } else {
            const createInput: CreatePaymentMethodInput = {
                type: PaymentMethodType.ach,
                stripeId: stripeId,
                altId: "",
                value: ""
            };
            const createResult = await createPaymentMethod(createInput);
            if (createResult.isSuccess) {
                method = createResult.result;
            } else {
                setError("Could not update the account.");
            }
        }

        if (method && method.id) {
            const updateClinicianInput: UpdateClinicianInput = {
                id: clinician.id,
                clinicianPaymentChoiceId: method?.id
            };
            const updateClinicianResult = await updateClinician(updateClinicianInput);
            if (updateClinicianResult.isSuccess) {
                method = updateClinicianResult.result;
            }
        }
        
    }

    async function getClinician(personId: string, stripeId: string) {
        setIsLoading(true);
        const queryResult = await getClinicianByPersonId(personId);
        if (queryResult.isSuccess) {
            const clinician: Clinician = queryResult.result;
            setStripeId(clinician, stripeId);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setError("");
        const personId = match.params.personId;
        const stripeId = match.params.stripeId;
        if (personId && stripeId) {
            getClinician(personId, stripeId);
        } else if (!personId) {
            setError("Could not connect the person id. Check with us to make sure we get it fixed: hello@ringsidepro.com.")
        } else if (!stripeId) {
            setError("Could not connect the stripe id. Check with us to make sure we get it fixed: hello@ringsidepro.com.")
        }
    }, [match, match.params.personId, match.params.stripeId]);
    
   
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Direct Deposit" />
                <IonRow>
                    <IonCol size="12">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonCard mode="md" className="ion-padding bg-white stretch">
                                <IonCardTitle>
                                    Success!
                                </IonCardTitle>
                                <IonCardContent>
                                    {error && <ErrorBanner error={error} />}
                                    <p>You have set up direct deposit! We will initiate your payments on the 1st of each month. Please allow up to 2 days to see the deposit.</p>
                                </IonCardContent>
                            </IonCard>
                        }
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default ClinicianStripeSuccessPage;