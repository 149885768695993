import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventDivisionInput, CreateEventDivisionMutation, DeleteEventDivisionInput, DeleteEventDivisionMutation, GetEventDivisionQuery, ListEventDivisionsQuery, UpdateEventDivisionInput, UpdateEventDivisionMutation } from "../../API";
import moment from "moment";
import { EventDivision } from "../../models";
import { listEventDivisionsWithResults } from "./EventDivisionCustomQueries";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventDivision. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventDivision.
*/
export async function createEventDivision(input: CreateEventDivisionInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventDivision", "No input", "Create EventDivision received no input.");
   try {
        const fullInput: CreateEventDivisionInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventDivision, { input: fullInput }))) as GraphQLResult<CreateEventDivisionMutation>;
       else result = (await API.graphql({
           query: mutations.createEventDivision,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventDivisionMutation>;
       const eventDivision = result.data?.createEventDivision;
       return formatResult(true, "EventDivision", eventDivision, "Successfully created the eventDivision.");
   } catch (error: any) {
       return formatResult(false, "EventDivision", error, "Error creating record in the database for type: eventDivision");
   }
}

/**
* Handle updating a new record in the database for type: eventDivision. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventDivision.
*/
export async function updateEventDivision(input: UpdateEventDivisionInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventDivision", "No input", "Update EventDivision received no input.");
   try {
        const fullInput: UpdateEventDivisionInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventDivision, { input: fullInput }))) as GraphQLResult<UpdateEventDivisionMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventDivision,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventDivisionMutation>;
       const eventDivision = result.data?.updateEventDivision;
       return formatResult(true, "EventDivision", eventDivision, "Successfully updated the eventDivision.");
   } catch (error: any) {
       return formatResult(false, "EventDivision", error, "Error updating record in the database for type: eventDivision");
   }
}

/**
* Handle deleting a new record in the database for type: eventDivision. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventDivision.
*/
export async function deleteEventDivision(input: DeleteEventDivisionInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventDivision", "No input", "Delete EventDivision received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventDivision, { input: input }))) as GraphQLResult<DeleteEventDivisionMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventDivision,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventDivisionMutation>;
       const eventDivision = result.data?.deleteEventDivision;
       return formatResult(true, "EventDivision", eventDivision, "Successfully deleted the eventDivision.");
   } catch (error: any) {
       return formatResult(false, "EventDivision", error, "Error deleting record in the database for type: eventDivision");
   }
}

/**
* Get all records in the database for type: eventDivision. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventDivision object
*/
export async function getAllEventDivisions(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventDivisions,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventDivisionsQuery>;

        let items = result.data?.listEventDivisions?.items as EventDivision[];
        let nextToken = result.data?.listEventDivisions?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventDivisions,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventDivisionsQuery>;

            const nextItems = nextResult.data?.listEventDivisions?.items as EventDivision[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventDivisions?.nextToken;
        }

        return formatResult(true, "EventDivision", items, "Successfully got the EventDivisions.");
    } catch (error: any) {
        return formatResult(false, "EventDivision", error, "Error reading record in the database for type: eventDivisions");
    }
}

/**
* Read a specific record in the database for type: eventDivision. 
* 
* @param {string}  id                  The eventDivision id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventDivision object
*/
export async function getEventDivisionById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventDivision,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventDivisionQuery>;
       const eventDivision = result.data?.getEventDivision;
       return formatResult(true, "EventDivision", eventDivision, "Successfully got the eventDivision.");
   } catch (error: any) {
       return formatResult(false, "EventDivision", error, "Error reading record in the database for type: eventDivision");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventDivision. 
* 
* @param {string}  organizationDivisionId   The organization division id
* @param {string}  authMode                 API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventDivision object
*/
export async function getEventDivisionByOrganizationDivisionId(organizationDivisionId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationDivisionId: {eq: organizationDivisionId}};
        const result = (await API.graphql({
            query: queries.listEventDivisions,
            variables: {
                limit: 10,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventDivisionsQuery>;

        let items = result.data?.listEventDivisions?.items as EventDivision[];
        let nextToken = result.data?.listEventDivisions?.nextToken;

        while ((!items || (items && items.length === 0)) && nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventDivisions,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventDivisionsQuery>;

            const nextItems = nextResult.data?.listEventDivisions?.items as EventDivision[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventDivisions?.nextToken;
        }

        const eventDivisions = items;
        if (eventDivisions && eventDivisions[0]) return formatResult(true, "EventDivision", eventDivisions[0], "Successfully got the eventDivision.");
        else return formatResult(false, "EventDivision", null, "Could not find the eventDivision.");
    } catch (error: any) {
        return formatResult(false, "EventDivision", error, "Error reading record in the database for type: eventDivisions");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventDivision. 
* 
* @param {string}  organizationDivisionId      The organization id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventDivision object
*/
export async function getEventDivisionsByOrganizationDivisionId(organizationDivisionId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationDivisionId: {eq: organizationDivisionId}};
        const result = (await API.graphql({
            query: queries.listEventDivisions,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventDivisionsQuery>;

        let items = result.data?.listEventDivisions?.items as EventDivision[];
        let nextToken = result.data?.listEventDivisions?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventDivisions,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventDivisionsQuery>;

            const nextItems = nextResult.data?.listEventDivisions?.items as EventDivision[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventDivisions?.nextToken;
        }

        return formatResult(true, "EventDivision", items, "Successfully got the EventDivisions.");
    } catch (error: any) {
        return formatResult(false, "EventDivision", error, "Error reading record in the database for type: eventDivisions");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventDivision. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventDivision object
*/
export async function getEventDivisionsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventDivisionsByEventId,
            variables: {
                limit: 1000,
                eventId: eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventDivisionsByEventId?.items as EventDivision[];
        let nextToken = result.data?.eventDivisionsByEventId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventDivisionsByEventId,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventDivisionsByEventId?.items as EventDivision[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventDivisionsByEventId?.nextToken;
        }

        return formatResult(true, "EventDivision", items, "Successfully got the EventDivisions.");
    } catch (error: any) {
        return formatResult(false, "EventDivision", error, "Error reading record in the database for type: eventDivisions");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventDivision. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventDivision object
*/
export async function getEventDivisionsByEventIdWithResults(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: listEventDivisionsWithResults,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventDivisionsQuery>;

        let items = result.data?.listEventDivisions?.items as EventDivision[];
        let nextToken = result.data?.listEventDivisions?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: listEventDivisionsWithResults,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventDivisionsQuery>;

            const nextItems = nextResult.data?.listEventDivisions?.items as EventDivision[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventDivisions?.nextToken;
        }

        return formatResult(true, "EventDivision", items, "Successfully got the EventDivisions.");
    } catch (error: any) {
        return formatResult(false, "EventDivision", error, "Error reading record in the database for type: eventDivisions");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventDivision. 
* 
* @param {string}  eventId             The event id
* @param {string}  name                The event division name
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventDivision object
*/
export async function getEventDivisionsByEventIdByName(eventId: string, name: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}, name: {eq: name}};
        const result = (await API.graphql({
            query: queries.listEventDivisions,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventDivisionsQuery>;

        let items = result.data?.listEventDivisions?.items as EventDivision[];
        let nextToken = result.data?.listEventDivisions?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventDivisions,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventDivisionsQuery>;

            const nextItems = nextResult.data?.listEventDivisions?.items as EventDivision[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventDivisions?.nextToken;
        }

        return formatResult(true, "EventDivision", items, "Successfully got the EventDivisions.");
    } catch (error: any) {
        return formatResult(false, "EventDivision", error, "Error reading record in the database for type: eventDivisions");
    }
}