import {
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Block } from "../../../models";
import ErrorAlert from "../../Errors/ErrorAlert";
import { getBlocksByOrganizationId } from "../../../utilities/block/Block";
import moment from "moment";
import { useSubscriptionByItself } from "../../../utilities/subscription/Subscription";
import { onCreateBlock } from "../../../graphql/subscriptions";

interface _Props {
    organizationId?: (string | null)
}

const ViewOrganizationBlocks: React.FC<_Props> = ({organizationId}) => {
    const blockSubscription = useSubscriptionByItself({
        config: {
            query: onCreateBlock,
            key: "onCreateBlock"
        }
    });

    const [isLoading, setIsLoading] = useState(false);
    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    const [organizationBlocks, setOrganizationBlocks] = useState<Block[] | null | undefined>();
    const [error, setError] = useState("");
    
    const getOrganizationBlocks = async (organizationId: string) => {
        const queryResult = await getBlocksByOrganizationId(organizationId);
        if (queryResult.isSuccess) {
            setOrganizationBlocks(queryResult.result);
        }
    }

    useEffect(() => {
        if (organizationId) {
            setIsLoading(true);
            getOrganizationBlocks(organizationId);
            setIsLoading(false);
        }
    }, [organizationId]);

    useEffect(() => {
        if (blockSubscription && blockSubscription[0] !== undefined && blockSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(blockSubscription[0]);
            if (organizationId) {
                getOrganizationBlocks(organizationId);
            }
        }
    }, [blockSubscription]);

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {organizationBlocks ?
                <>
                    <IonRow >
                        <IonCol>
                            {organizationBlocks.map((block, index) => (
                                <div key={index}>
                                    <IonRow>
                                        <IonCol size="12">
                                            <h2>{block.totalCredits?.toLocaleString()} credits</h2>
                                            <h3>Remaining: {((block.totalCredits || 0) - (block.usedCredits || 0))?.toLocaleString()} credits</h3>
                                            <h3>Date Purchased: {moment(block.purchasedOn).format("MMM DD, YYYY")}</h3>
                                        </IonCol>
                                    </IonRow>
                                    <hr/>
                                </div>
                            ))}
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <p>Your organization does not have any credits yet.</p>
                </>
            }
            
        </>
    );
};

export default ViewOrganizationBlocks;