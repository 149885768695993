import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreatePointTableInput, CreatePointTableMutation, DeletePointTableInput, DeletePointTableMutation, GetPointTableQuery, ListPointTablesQuery, UpdatePointTableInput, UpdatePointTableMutation } from "../../API";
import moment from "moment";
import { PointTable } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: pointTable. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created pointTable.
*/
export async function createPointTable(input: CreatePointTableInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "PointTable", "No input", "Create PointTable received no input.");
   try {
        const fullInput: CreatePointTableInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createPointTable, { input: fullInput }))) as GraphQLResult<CreatePointTableMutation>;
       else result = (await API.graphql({
           query: mutations.createPointTable,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreatePointTableMutation>;
       const pointTable = result.data?.createPointTable;
       return formatResult(true, "PointTable", pointTable, "Successfully created the pointTable.");
   } catch (error) {
       return formatResult(false, "PointTable", error, "Error creating record in the database for type: pointTable");
   }
}

/**
* Handle updating a new record in the database for type: pointTable. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated pointTable.
*/
export async function updatePointTable(input: UpdatePointTableInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "PointTable", "No input", "Update PointTable received no input.");
   try {
        const fullInput: UpdatePointTableInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updatePointTable, { input: fullInput }))) as GraphQLResult<UpdatePointTableMutation>;
       else result = (await API.graphql({
           query: mutations.updatePointTable,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdatePointTableMutation>;
       const pointTable = result.data?.updatePointTable;
       return formatResult(true, "PointTable", pointTable, "Successfully updated the pointTable.");
   } catch (error) {
       return formatResult(false, "PointTable", error, "Error updating record in the database for type: pointTable");
   }
}

/**
* Handle deleting a new record in the database for type: pointTable. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted pointTable.
*/
export async function deletePointTable(input: DeletePointTableInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "PointTable", "No input", "Delete PointTable received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deletePointTable, { input: input }))) as GraphQLResult<DeletePointTableMutation>;
       else result = (await API.graphql({
           query: mutations.deletePointTable,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeletePointTableMutation>;
       const pointTable = result.data?.deletePointTable;
       return formatResult(true, "PointTable", pointTable, "Successfully deleted the pointTable.");
   } catch (error) {
       return formatResult(false, "PointTable", error, "Error deleting record in the database for type: pointTable");
   }
}

/**
* Get all records in the database for type: pointTable. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the pointTable object
*/
export async function getAllPointTables(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listPointTables,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPointTablesQuery>;
        
        let items = result.data?.listPointTables?.items as PointTable[];
        let nextToken = result.data?.listPointTables?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listPointTables,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListPointTablesQuery>;

            const nextItems = nextResult.data?.listPointTables?.items as PointTable[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPointTables?.nextToken;
        }

        return formatResult(true, "PointTable", items, "Successfully got the PointTables.");
    } catch (error) {
        return formatResult(false, "PointTable", error, "Error reading record in the database for type: pointTables");
    }
}

/**
* Read a specific record in the database for type: pointTable. 
* 
* @param {string}  id                  The pointTable id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the pointTable object
*/
export async function getPointTableById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getPointTable,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetPointTableQuery>;
       const pointTable = result.data?.getPointTable;
       return formatResult(true, "PointTable", pointTable, "Successfully got the pointTable.");
   } catch (error) {
       return formatResult(false, "PointTable", error, "Error reading record in the database for type: pointTable");
   }
}

/**
* Get all records in the database that match the given criteria for type: pointTable. 
* 
* @param {string}  organizationId      The organization id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the pointTable object
*/
export async function getPointTablesByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationId: {eq: organizationId}};
        const result = (await API.graphql({
            query: queries.listPointTables,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPointTablesQuery>;
        
        let items = result.data?.listPointTables?.items as PointTable[];
        let nextToken = result.data?.listPointTables?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listPointTables,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListPointTablesQuery>;

            const nextItems = nextResult.data?.listPointTables?.items as PointTable[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPointTables?.nextToken;
        }

        return formatResult(true, "PointTable", items, "Successfully got the PointTables.");
    } catch (error) {
        return formatResult(false, "PointTable", error, "Error reading record in the database for type: pointTables");
    }
}