import { Event, EventOfficial } from "../../../models";
import {
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getEventOfficialsByEventId } from "../../../utilities/eventOfficial/EventOfficial";

interface _Props {
    event: Event
}

const EventDisplayOfficials: React.FC<_Props> = ({event}) => {

    const [eventOfficials, setEventOfficials] = useState<(EventOfficial | null)[] | null | undefined>(event.officials || undefined);

    async function getHorseShowOfficials(eventId: string) {
        const queryResult = await getEventOfficialsByEventId(eventId);
        if (queryResult.isSuccess) {
            const officials = queryResult.result;
            setEventOfficials(officials);
        }
    };

    useEffect(() => {
        if (event) {
            getHorseShowOfficials(event.id);
        }
    }, [event]);
    return (
        <>
            {eventOfficials && eventOfficials.length ?
                <>
                    <IonRow>
                        <IonCol>
                            <IonList className="bg-white">
                                {eventOfficials.map((official, index) => (
                                    <IonItem key={index}>
                                        <IonLabel>
                                            <IonRow>
                                                <IonCol sizeXs="12" sizeMd="6">
                                                    <h3 className="ion-text-wrap text-darker">{official && official.name}</h3>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="6">
                                                    <h4 className="ion-text-wrap text-darker">{official && official.position}</h4>
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                    </IonItem>
                                ))}
                            </IonList>
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel><h3>No officials listed.</h3></IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default EventDisplayOfficials;