import moment from "moment";
import { Event, EventInvoice } from "../../models";
import { getEventInvoicesByEventId } from "../eventInvoice/EventInvoice";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

interface paymentData {
    name: string
    quantity: number
    amount: number
}

export async function generateRevenueFromCollectedPayments(event: Event) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Payment Analysis Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = ["Payment Type", "Quantity", "Total Amount"];
    // define an empty array of rows
    const tableRows = [];

    let paymentMap = new Map();

    let totalPaymentAmount = 0;

    const queryResult = await getEventInvoicesByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventInvoices = queryResult.result;
        if (eventInvoices.length > 0) {
            for (let i = 0; i < eventInvoices.length; i++) {
                const eventInvoice: EventInvoice = eventInvoices[i];
                totalPaymentAmount = totalPaymentAmount + (eventInvoice.amountToDeductFromInvoice || 0);

                const result = paymentMap.get(eventInvoice.payerType);
                const currentQuantityTotal = (result?.quantity ? result?.quantity : 0) + 1;
                const currentAmountTotal = (result?.amount ? result?.amount : 0) + (eventInvoice.amountToDeductFromInvoice || 0);

                const paymentData: paymentData = {
                    name: eventInvoice.payerType || "unknown",
                    quantity: currentQuantityTotal,
                    amount: currentAmountTotal
                };
                paymentMap.set(eventInvoice.payerType, paymentData);
            }
        }
    }

    paymentMap.forEach(element => {
        const name = element?.name || "unknown";
        const quantity = element?.quantity.toString() || "unknown quantity";
        const amount = "$" + element?.amount.toLocaleString() || "unknown amount";
        const row = [
            name, 
            quantity,
            amount
        ];
        tableRows.push(row);
    });

    const totalRow = [
        "Total",
        "",
        "$" + totalPaymentAmount.toLocaleString()
    ];

    tableRows.push(totalRow);
     
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 35 
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    doc.save(`revenue_from_collected_payments.pdf`);
}