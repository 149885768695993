import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { close, create } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../../context/PersonContext";
import { Event, EventFee } from "../../../models";
import FeeForm from "../../Fee/FeeForm";
import ErrorAlert from "../../Errors/ErrorAlert";
import Spinner from "../../Spinners/Spinner";
import { getEventFeesByEventId } from "../../../utilities/eventFee/EventFee";
import moment from "moment";

interface _Props {
    event: Event
}

const EventFeeForm: React.FC<_Props> = ({event}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [eventFeeArray, setEventFeeArray] = useState<(EventFee | null)[] | null | undefined>();
    const [selectedEventFee, setSelectedEventFee] = useState<EventFee | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEventFees(eventId: string) {
            const queryResult = await getEventFeesByEventId(eventId);
            if (queryResult.isSuccess) {
                const eventFees: EventFee[] = queryResult.result;
                const sorted = eventFees.sort((a, b) => a.name.localeCompare(b.name));
                setEventFeeArray(sorted || eventFees);
            }
        };
        if (event) getEventFees(event.id);
    }, [event]);

    const handleSubmit = async (type: "create" | "update" | "delete", newEventFee: EventFee) => {
        setIsLoading(true);
        if (event) {
            setError("");
            let feeArray: (EventFee | null)[] = [];
            if (type == "create") {
                if (eventFeeArray && eventFeeArray.length) {
                    feeArray = eventFeeArray.concat([newEventFee]);
                } else {
                    feeArray.push(newEventFee);
                }
            } else if (type === "update") {
                // Updated the selectedEventFee
                if (eventFeeArray && eventFeeArray.length) {
                    for (var i = 0; i < eventFeeArray.length; i++) {
                        const eventFee = eventFeeArray[i];
                        if (eventFee && eventFee.id === selectedEventFee?.id) feeArray.push(newEventFee);
                        else feeArray.push(eventFee);
                    }
                }
            } else {
                if (eventFeeArray) {
                    feeArray = eventFeeArray.filter(fee => fee ? fee.id !== newEventFee.id : false);
                } else {
                    feeArray = [];
                }
            }
            setEventFeeArray(feeArray);
        } else {
            setError("Error: no event found. Please contact hello@ringsidepro.com");
        }
        setIsLoading(false);
        setShowModal(false);
    }

    const handleUpdate = async (eventFee?: EventFee) => {
        if (eventFee) {
            setSelectedEventFee(eventFee);
            setShowModal(true);
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {eventFeeArray && eventFeeArray.length ?
                <>
                    <IonList className="bg-white">
                        {eventFeeArray.map((eventFee, index) => (
                            <IonItem key={index}>
                                <IonLabel>
                                    <IonRow>
                                        <IonCol>
                                            <h3 className="font-weight-bold">{eventFee && eventFee.name}</h3>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <h3>{(eventFee && eventFee.fee && eventFee.fee.isPerEntry) ? "Required" : "Optional"}</h3>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <h3>{(eventFee && eventFee.fee && eventFee.fee.totalPrice) ? <span>${eventFee.fee.totalPrice.toFixed(2)}</span>: <></>}</h3>
                                        </IonCol>
                                    </IonRow>
                                    {(eventFee && eventFee?.fee && eventFee?.fee?.isPerClass) && (
                                        <IonRow>
                                            <IonCol>
                                                <h3>Muliplied by # classes</h3>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                    {(eventFee && eventFee?.fee && eventFee?.fee?.taxA && eventFee?.fee?.taxA !== 0 && eventFee?.fee?.taxA !== undefined) && (
                                        <IonRow>
                                            <IonCol>
                                                <h3>{(eventFee && eventFee.fee && eventFee.fee.taxA) ? <span>{eventFee.fee.taxA}%</span>: <></>} Tax</h3>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                    {(eventFee && eventFee?.fee && eventFee?.fee?.taxB && eventFee?.fee?.taxB !== 0 && eventFee?.fee?.taxB !== undefined) && (
                                        <IonRow>
                                            {eventFee?.fee?.taxB}
                                            <IonCol>
                                                <h3>{(eventFee && eventFee.fee && eventFee.fee.taxB) ? <span>{eventFee.fee.taxB}%</span>: <></>} Tax</h3>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                    {(eventFee && eventFee?.fee && eventFee?.fee?.isStartedOnEntryDate && eventFee?.fee?.startDate) && (
                                        <IonRow>
                                            <IonCol>
                                                <h3>Start: {moment(eventFee?.fee?.startDate).format("MM-DD-YYYY")} {eventFee?.fee?.startTime ? moment(eventFee.fee.startTime.replace(":", ""), "Hmm").format("h:mm a"): ""}</h3>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                </IonLabel>
                                <IonButton slot="end" color="tertiary" onClick={() => handleUpdate(eventFee as EventFee)}><IonIcon icon={create}/></IonButton>
                            </IonItem>
                        ))}
                    </IonList>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                Add Fee
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                Add Fee
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
            <IonModal backdropDismiss={false} isOpen={showModal} id="addEventFeeModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Fee
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeFeeModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <FeeForm event={event} selectedEventFee={selectedEventFee!} onSubmit={handleSubmit} />
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventFeeForm;