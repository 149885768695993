import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Horse } from "../../models";
import { getAllHorses } from "../../utilities/horse/Horse";
import moment from "moment";
import Spinner from "../Spinners/Spinner";

interface _Props {
    selectedValue?: (string | null)
    onSelect: Function
    isHeightRequired?: boolean
    height?: string
}

interface formattedOption {
    label: string
    value: string
    horse: Horse
}

const SelectFromAllHorses: React.FC<_Props> = ({selectedValue, onSelect, isHeightRequired, height}) => {

    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [formattedHorses, setFormattedHorses] = useState<formattedOption[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const getHorses = async () => {
        const queryResult = await getAllHorses();
        if (queryResult.isSuccess) {
            const horsesArray: Horse[] = queryResult.result;
            const sorted = horsesArray.sort((a,b) => a.name.trim().localeCompare(b.name.trim()));
            return sorted || horsesArray;
        }
        return [];
    }

    const formatHorses = async () => {
        setIsLoading(true);

        let formattedHorses = [];

        const allHorses = await getHorses();

        for (var i = 0; i < allHorses.length; i++) {
            const horse = allHorses[i];
            const value = horse.id;
            const name = horse.name;
            const nickname = horse.nickname || "";
            const barnName = horse.barn?.name || horse.barnName || "unknown";
            const createdOn = horse.createdOn ? moment(horse.createdOn).format("MM/DD/YYYY") : "unknown";
            const label = (name || "error") + (nickname ? " (" + nickname +  ")" : "") + "\nIn Barn: " + barnName  + "\nCreated on: " + createdOn;
            let object: formattedOption = {
                value: value,
                label: label,
                horse: horse
            };
            formattedHorses.push(object);
        }
        setFormattedHorses(formattedHorses);
        if (selectedValue) findValueInList(selectedValue, formattedHorses);

        setIsLoading(false);
    }

    const findValueInList = (value: string, horseList?: formattedOption[]) => {
        let optionArray = horseList || formattedHorses;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        formatHorses();
    }, []);

    useEffect(() => {
        function setValue() {
            if (selectedValue) {
                findValueInList(selectedValue);
            } else {
                setCurrentValue(undefined);
            }
        }
        setValue();
    }, [selectedValue]);

    const handleOnChange = (event?: any) => {
        if (event && event.horse) {
            setCurrentValue(event);
            onSelect(event.horse);
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <Select
                    id="select-from-horses"
                    inputId="select-from-all-horses-input"
                    classNamePrefix="react-select"
                    styles={{
                        // Fixes the overlapping problem of the component
                        control: baseStyles =>(isHeightRequired ? {...baseStyles, height} : {...baseStyles}),
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        
                    }}
                    defaultValue={currentValue}
                    value={currentValue}
                    menuPortalTarget={document.body}
                    isClearable
                    options={formattedHorses!}
                    onChange={handleOnChange}
                />
            }
        </>
    )
};

export default SelectFromAllHorses;
