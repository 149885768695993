import Papa from "papaparse";

/***
 * About .dat files
 * 
 * USEF and USHJA require a .dat file for results submissions:
 * https://www.ushja.org/application/files/8716/5530/2921/Submitting_Outreach_Results_101.pdf
 * 
 * What is a .dat file?
 * https://www.adobe.com/uk/acrobat/resources/document-files/data-files/dat.html
 * 
 * Example .dat file
 * - Melanie Mader sent an example .dat file on 05-07-2024
 * - This file was comma-deliminated
 */

/***
 * Call this function to download formatted rows to a DAT file
 * 
 * fileName: Should include .dat file extension example: MyData.dat
 * rows: Should include the header row
 * delimiter: (optional) character to be used to separate data in the file (default is a comma)
 */
export function downloadDATFile(fileName: string, rows: any[], delimiter?: string) {
    var currentDelimiter = delimiter || ",";
    var dat = Papa.unparse(rows, {delimiter: currentDelimiter});
    var datData = new Blob([dat], {type: 'application/vnd.ms-excel;charset=utf-8;'});
    var datURL =  null;
    
    datURL = window.URL.createObjectURL(datData);
    var tempLink = document.createElement('a');
    tempLink.href = datURL as string;
    tempLink.setAttribute('download', fileName);
    tempLink.click();
}