import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonItem,
    IonLabel,
    IonListHeader,
    IonRadio,
    IonRadioGroup,
    IonRow,
} from "@ionic/react";
import React, { useState } from "react";
import { Barn, Event, EventEntry, Trainer } from "../../../models";
import { InvoicePDFSettings } from "../../../utilities/reports/InvoicePDF";
import SelectFromEventBarns from "../../Barn/SelectFromEventBarns";
import SelectEventEntry from "../../Entry/SelectEventEntry";
import ErrorAlert from "../../Errors/ErrorAlert";
import SelectFromEventTrainers from "../../Trainer/SelectFromEventTrainers";

interface _Props {
    event?: (Event | null)
    onSubmit: Function
}

const EntryInvoiceReportForm: React.FC<_Props> = ({event, onSubmit}) => {

    const [entryReportSelectedOption, setEntryReportSelectedOption] = useState<"all" | "trainer" | "barn" | "single">("all");
    const [sortBy, setSortBy] = useState<"number" | "barn">("number");
    const [selectedTrainer, setSelectedTrainer] = useState<Trainer | undefined>();
    const [selectedBarn, setSelectedBarn] = useState<Barn | undefined>();
    const [selectedEventEntry, setSelectedEventEntry] = useState<EventEntry | undefined>();
    const [includeInvoiceRider, setIncludeInvoiceRider] = useState(true);
    const [includeInvoiceHorse, setIncludeInvoiceHorse] = useState(true);
    const [includeInvoiceTrainer, setIncludeInvoiceTrainer] = useState(true);
    const [includeInvoiceOwner, setIncludeInvoiceOwner] = useState(true);
    const [includeInvoiceBarn, setIncludeInvoiceBarn] = useState(true);
    const [includeInvoicePrimaryContact, setIncludeInvoicePrimaryContact] = useState(true);
    const [includeInvoiceEmail, setIncludeInvoiceEmail] = useState(true);
    const [includeInvoicePhoneNumber, setIncludeInvoicePhoneNumber] = useState(true);
    const [includeInvoiceMemberships, setIncludeInvoiceMemberships] = useState(true);
    const [error, setError] = useState<string>("");

    const createInvoicePDFSettings = () => {
        const settingsObject: InvoicePDFSettings = {
            isDue: true,
            isDownload: true,
            sortBy: sortBy,
            selectedEntry: selectedEventEntry,
            selectedTrainer: selectedTrainer,
            selectedBarn: selectedBarn,
            includeRiderInfo: includeInvoiceRider,
            includeHorseInfo: includeInvoiceHorse,
            includeOwnerInfo: includeInvoiceOwner,
            includeTrainerInfo: includeInvoiceTrainer,
            includeBarnInfo: includeInvoiceBarn,
            includePrimaryContactInfo: includeInvoicePrimaryContact,
            includeEmails: includeInvoiceEmail,
            includePhoneNumbers: includeInvoicePhoneNumber,
            includeMemberships: includeInvoiceMemberships
        };
        return settingsObject;
    }

    const handleSubmit = async () => {
        setError("");
        const settingsObject = createInvoicePDFSettings();
        onSubmit(settingsObject);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <IonRow>
                <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                    <IonButton color={entryReportSelectedOption === "all" ? "primary" : "light"} onClick={() => setEntryReportSelectedOption("all")}>All</IonButton>
                </IonCol>
                <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                    <IonButton color={entryReportSelectedOption === "trainer" ? "primary" : "light"} onClick={() => setEntryReportSelectedOption("trainer")}>Trainer</IonButton>
                </IonCol>
                <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                    <IonButton color={entryReportSelectedOption === "barn" ? "primary" : "light"} onClick={() => setEntryReportSelectedOption("barn")}>Barn</IonButton>
                </IonCol>
                <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                    <IonButton color={entryReportSelectedOption === "single" ? "primary" : "light"} onClick={() => setEntryReportSelectedOption("single")}>Single</IonButton>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center pt-3">
                <IonCol className="ion-text-center">
                    {entryReportSelectedOption === "all" && (
                        <>
                            <p>This will download all entry invoices in one set.</p>
                            <IonRadioGroup value={sortBy} onIonChange={(e) => setSortBy(e.detail.value)}>
                                <IonListHeader>
                                    <IonLabel>Sort By</IonLabel>
                                </IonListHeader>
                                <IonItem>
                                    <IonLabel>Back Number</IonLabel>
                                    <IonRadio slot="start" value="number" />
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Barn Name</IonLabel>
                                    <IonRadio slot="start" value="barn" />
                                </IonItem>
                            </IonRadioGroup>
                        </>
                    )}
                    {(entryReportSelectedOption === "trainer" && event) && (
                        <>
                            <p>Select the Trainer</p>
                            <SelectFromEventTrainers event={event} onSelect={(trainer: Trainer) => {setSelectedTrainer(trainer); setSelectedBarn(undefined); setSelectedEventEntry(undefined);}} />
                        </>
                    )}
                    {(entryReportSelectedOption === "barn" && event) && (
                        <>
                            <p>Select the Barn</p>
                            <SelectFromEventBarns event={event} onSelect={(barn: Barn) => {setSelectedBarn(barn); setSelectedTrainer(undefined); setSelectedEventEntry(undefined);}} />
                        </>
                    )}
                    {(entryReportSelectedOption === "single" && event) && (
                        <>
                            <p>Select the Entry</p>
                            <SelectEventEntry event={event} onSelect={(eventEntry: EventEntry) => {setSelectedEventEntry(eventEntry); setSelectedTrainer(undefined); setSelectedBarn(undefined);}} />
                        </>
                    )}
                </IonCol>
            </IonRow>
            <hr />
            <IonRow className="ion-justify-content-center pt-3">
                <IonCol className="ion-text-center">
                    <p>Settings:</p>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol className="ion-text-center">
                    <IonItem>
                        <IonLabel><p className="ion-text-wrap">Include Rider Info on Invoice</p></IonLabel>
                        <IonCheckbox slot="start" value="includeEntryInfo" checked={includeInvoiceRider} onIonChange={e => setIncludeInvoiceRider(e.detail.checked)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol className="ion-text-center">
                    <IonItem>
                        <IonLabel><p className="ion-text-wrap">Include Horse Info on Invoice</p></IonLabel>
                        <IonCheckbox slot="start" value="includeEntryInfo" checked={includeInvoiceHorse} onIonChange={e => setIncludeInvoiceHorse(e.detail.checked)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol className="ion-text-center">
                    <IonItem>
                        <IonLabel><p className="ion-text-wrap">Include Owner Info on Invoice</p></IonLabel>
                        <IonCheckbox slot="start" value="includeEntryInfo" checked={includeInvoiceOwner} onIonChange={e => setIncludeInvoiceOwner(e.detail.checked)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol className="ion-text-center">
                    <IonItem>
                        <IonLabel><p className="ion-text-wrap">Include Trainer Info on Invoice</p></IonLabel>
                        <IonCheckbox slot="start" value="includeEntryInfo" checked={includeInvoiceTrainer} onIonChange={e => setIncludeInvoiceTrainer(e.detail.checked)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol className="ion-text-center">
                    <IonItem>
                        <IonLabel><p className="ion-text-wrap">Include Barn Info on Invoice</p></IonLabel>
                        <IonCheckbox slot="start" value="includeEntryInfo" checked={includeInvoiceBarn} onIonChange={e => setIncludeInvoiceBarn(e.detail.checked)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol className="ion-text-center">
                    <IonItem>
                        <IonLabel><p className="ion-text-wrap">Include Primary Contact Info on Invoice</p></IonLabel>
                        <IonCheckbox slot="start" value="includeEntryInfo" checked={includeInvoicePrimaryContact} onIonChange={e => setIncludeInvoicePrimaryContact(e.detail.checked)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <hr /> 
            <IonRow className="ion-justify-content-center">
                <IonCol className="ion-text-center">
                    <IonItem>
                        <IonLabel><p className="ion-text-wrap">Include Email Addresses on Invoice</p></IonLabel>
                        <IonCheckbox slot="start" value="includeEntryInfo" checked={includeInvoiceEmail} onIonChange={e => setIncludeInvoiceEmail(e.detail.checked)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol className="ion-text-center">
                    <IonItem>
                        <IonLabel><p className="ion-text-wrap">Include Phone Numbers on Invoice</p></IonLabel>
                        <IonCheckbox slot="start" value="includeEntryInfo" checked={includeInvoicePhoneNumber} onIonChange={e => setIncludeInvoicePhoneNumber(e.detail.checked)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol className="ion-text-center">
                    <IonItem>
                        <IonLabel><p className="ion-text-wrap">Include Memberships on Invoice</p></IonLabel>
                        <IonCheckbox slot="start" value="includeEntryInfo" checked={includeInvoiceMemberships} onIonChange={e => setIncludeInvoiceMemberships(e.detail.checked)} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol className="ion-text-center">
                    <IonButton color="primary" onClick={handleSubmit}>Download</IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default EntryInvoiceReportForm;