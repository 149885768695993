import {  Barn } from "../../models";
import { CreateBarnInput, UpdateBarnInput } from "../../API";
import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import { createBarn, updateBarn} from "../../utilities/barn/Barn";
import moment from "moment";
import SuccessBanner from "../Banners/SuccessBanner";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    selectedBarn?: (Barn | null)
    onChange: Function
}

const QuickAddBarnForm: React.FC<_Props> = ({selectedBarn, onChange}) => {
    const user = useContext(PersonContext);
    
    const [barn, setBarn] = useState<Barn | null | undefined>();
    const [name, setName] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const clearForm = () => {
        setName("");
    }

    useEffect(() => {
        clearForm();
    }, []);

    useEffect(() => {
        setBarn(selectedBarn);
    }, [selectedBarn]);

    useEffect(() => {
        if (barn) {
            // Set barn details
            setName(barn.name);
        } else {
            clearForm();
        }
    }, [barn]);

    const verifyForm = () => {
        if (!name) {
            setError("Please include a name for the barn");
            return false;
        }
        return true;
    }

    const handleCreateBarn = async () => {
        let input: CreateBarnInput = {
            name: name, 
            ownerPersonId: user.id,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        };
        const createResult = await createBarn(input);
        if (createResult.isSuccess) {
            onChange(createResult.result);
            setSuccess("Successfully created the barn.");
        } else {
            setError(createResult.message);
        }
    }

    const handleUpdateBarn = async () => {
        if (barn) {
            const input: UpdateBarnInput = {
                id: barn.id,
                name: name,
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
            const updateResult = await updateBarn(input);
            if (updateResult.isSuccess) {
                onChange(updateResult.result);
                setSuccess("Successfully updated the barn.");
            } else {
                setError(updateResult.message);
            }
        } else {
            setError("No barn to edit.");
        }
    }

    const handleAddBarn = async () => {
        setSuccess("");
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            await handleCreateBarn();
        }
    }

    const handleEditBarn = async () => {
        setSuccess("");
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            await handleUpdateBarn();
        }
    }

    return (
        <>
            {error && <ErrorAlert error={error} />}
            {success && <SuccessBanner width="12" success={success} />}
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <IonItem color="white">
                        <IonLabel position="stacked">Name <RequiredInputIndicator /></IonLabel>
                        <IonInput 
                            type="text"
                            value={name}
                            aria-required={true}
                            onIonChange={e => {
                                setName(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton
                        className="ion-margin-top"
                        color="tertiary"
                        expand="block"
                        onClick={barn ? handleEditBarn : handleAddBarn}
                    >
                        {barn ? "Edit " + barn.name : "Add Barn"}
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default QuickAddBarnForm;