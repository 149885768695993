import {
    IonCard,
    IonCardContent,
    IonCol,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useState } from "react";
import { Barn, Rider } from "../../models";
import QuickAddRiderForm from "./QuickAddRiderForm";
import SelectRider from "./SelectRider";
import Spinner from "../Spinners/Spinner";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    label: string
    isRequired?: boolean
    rider?: Rider
    barn?: Barn
    onChange: Function
}

const RiderEntryForm: React.FC<_Props> = ({rider, label, isRequired, barn, onChange}) => {
    const [selectedRider, setSelectedRider] = useState<Rider | null | undefined>(rider);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleRiderSelect = (rider: Rider | null | undefined) => {
        setSelectedRider(rider);
        onChange(rider);
    }

    const handleRiderUpdate = (rider: Rider | null | undefined) => {
        onChange(rider);
    }

    return (
        <>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="12">
                    <IonLabel position="stacked" color="dark">{label} {isRequired && (<RequiredInputIndicator />)}</IonLabel>
                    <SelectRider selectedValue={selectedRider?.id} barn={barn} onSelect={handleRiderSelect} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="12">
                    <p className="ion-text-center link" onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? "View Less" : (selectedRider ? "Edit Rider" : "New Rider")}</p>
                </IonCol>
            </IonRow>
            {isExpanded && (
                <IonCard color="white" className="ion-no-padding">
                    <IonCardContent className="ion-no-padding">
                        <QuickAddRiderForm key={selectedRider?.id || ""} rider={selectedRider} selectedBarn={barn} onChange={handleRiderUpdate}/>
                    </IonCardContent>
                </IonCard>
            )}
        </>
    );
};

export default RiderEntryForm;