import moment from "moment";
import { Membership, PersonalInformation, Organization, OrganizationMembershipType, MembershipTypeCategory, Team} from "../../models";
import { getAddressById }  from "../address/Address";
import { getContactById } from "../contact/Contact";
import { formatAddress } from "../address/FormatAddress";
import { getRiderByPersonIdAndName } from "../rider/Rider";
import { getHorseById } from "../horse/Horse";
import { getTeamById } from "../team/Team";
import { getPersonalInformationByPersonId } from "../personalInformation/PersonalInformation";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export async function membershipApplicationReports(memberships: Membership[], membershipType: OrganizationMembershipType, personInfo: PersonalInformation, organization:Organization | undefined, isDownload:Boolean) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`Membership Confirmation`, 14, 20);
    doc.setFontSize(10);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = ["", ""];

    // define an empty array of rows
    const tableRows: any[] = [];
    let addressData;
    let contactData;
    let horse;
    const membership = memberships[0];
    if (membershipType.category === MembershipTypeCategory.HORSE) {
        const phoneResult = await getContactById(membership.horse?.contactId ?? "");
        contactData = phoneResult.result;

        const queryResult = await getAddressById(contactData.mailingAddress ?? "");
        addressData = queryResult.result;

        const horseData = await getHorseById(membership.horse?.id || "");
        horse = horseData.result;
    } else {
        const queryResult = await getAddressById(personInfo?.addressId ?? "");
        addressData = queryResult.result;
    
        const phoneResult = await getContactById(personInfo?.contactId ?? "");
        contactData = phoneResult.result;
    }

    tableRows.push(["Organization Name", organization?.name]);
    tableRows.push(["Membership Type Name", membership.type]);
    let index = 1;
    for (const m of memberships) {
        if(membershipType.category === MembershipTypeCategory.GROUP) {
            tableRows.push([`Member ${index} Name`, m.personName]);
            const riderResult = await getRiderByPersonIdAndName(m.personId, m.personName || "");
            if (riderResult.isSuccess && riderResult.result) {
                tableRows.push([`Member ${index} Date of Birth`, riderResult.result.birthdate]);
            }
            if(membership.backNumber) {
                tableRows.push([`Member ${index} Back Number`, membership.backNumber]);
            }
            index++;
        } else if (membershipType.category === MembershipTypeCategory.HORSE) {
            if (horse.name) {
                tableRows.push(["Horse Name", horse.name]);
            }
            if (horse.dateOfBirth) {
                tableRows.push(["Horse Date of Birth", horse.dateOfBirth]);
            }
            if (horse.height) {
                tableRows.push(["Horse Height", `${horse.height} inches`]);
            }
            if(membership.backNumber) {
                tableRows.push(["Member Back Number", membership.backNumber]);
            }
        } else {
            tableRows.push(["Member Name", m.personName]);
            tableRows.push(["Member Date of Birth", personInfo.dateOfBirth]);
            if(membership.backNumber) {
                tableRows.push(["Member Back Number", membership.backNumber]);
            }
        }
    }
    if (membershipType.category === MembershipTypeCategory.HORSE) {
        if (horse.contact?.personalEmail) {
            tableRows.push(["Email Address", horse.contact?.personalEmail]);
        }
    } else {
        tableRows.push(["Email Address", membership.personEmail]);
    }
    let phone = "";
    if(contactData.cell) {
        phone = `${contactData.cell}`
    } else if (contactData.home) {
        phone = `${contactData.home}`
    } else {
        phone = `${contactData.work}`
    }
    tableRows.push(["Phone Number", phone]);
    tableRows.push(["Address", formatAddress(addressData)]);
    tableRows.push(["Membership Id", membership.membershipId === "" ? "Not assigned yet" : membership.membershipId]);
    tableRows.push(["Date Membership Ends", membership.dateMembershipEnds === "" ? "This membership does not expire" : moment(membership.dateMembershipEnds, "YYYY-MM-DD").format("MM-DD-YYYY")]);
    
    if(membership.barn) {
        tableRows.push(["Barn Name", membership.barn.name]);
    }
    if(membership.gradeLevel) {
        tableRows.push(["Grade level", membership.gradeLevel]);
    }

    if(membershipType.category === MembershipTypeCategory.GROUP) {
        let groupContactData;
        let groupLeaderContactData;
        let groupLeaderAddress;
        let groupAddress;
        const isTeamOrGroup = membershipType.applicationFields?.isTeam ? "Team" : "Group";
        
        for (const m of memberships) {
            if (m.groupContactId?.length) {
                const groupContactResult = await getContactById(m.groupContactId ?? "");
                groupContactData = groupContactResult.result;
            }

            if(groupContactData && groupContactData.mailingAddress) {
                const groupLeaderAddressResult = await getAddressById(groupContactData.mailingAddress);
                groupAddress = groupLeaderAddressResult.result;
            }

            if (m.groupLeaderContactId?.length) {
                const groupLeaderContactResult = await getContactById(m.groupLeaderContactId ?? "");
                groupLeaderContactData = groupLeaderContactResult.result;
            }

            if(groupLeaderContactData && groupLeaderContactData.mailingAddress) {
                const groupLeaderAddressResult = await getAddressById(groupLeaderContactData.mailingAddress);
                groupLeaderAddress = groupLeaderAddressResult.result;
            }
        };

        if (groupContactData) {
            if (groupContactData.name) {
                tableRows.push([`${isTeamOrGroup} Name`, groupContactData.name]);
            }
            if (groupContactData.personalEmail) {
                tableRows.push([`${isTeamOrGroup} Email Address`, groupContactData.personalEmail]);
            }
            let phone = "";
            if(groupContactData.cell) {
                phone = `${groupContactData.cell}`
            } else if (groupContactData.home) {
                phone = `${groupContactData.home}`
            } else {
                phone = `${groupContactData.work}`
            }
            if (groupContactData.cell || groupContactData.home || groupContactData.work) {
                tableRows.push([`${isTeamOrGroup} Phone Number`, phone]);
            }
        }

        if (groupAddress) {
            tableRows.push([`${isTeamOrGroup} Address`, formatAddress(groupAddress)]);
        }

        if (groupLeaderContactData) {
            if(groupLeaderContactData.name) {
                tableRows.push([`${isTeamOrGroup} Leader Name`, groupLeaderContactData.name]);
            }
            if(groupLeaderContactData.personalEmail) {
                tableRows.push([`${isTeamOrGroup} Leader Email Address`, groupLeaderContactData.personalEmail]);
            }
            let leaderPhone = "";
            if(groupLeaderContactData.cell) {
                leaderPhone = `${groupLeaderContactData.cell}`
            } else if (groupLeaderContactData.home) {
                leaderPhone = `${groupLeaderContactData.home}`
            } else {
                leaderPhone = `${groupLeaderContactData.work}`
            }
            if (groupLeaderContactData.cell || groupLeaderContactData.home || groupLeaderContactData.work) {
                tableRows.push([`${isTeamOrGroup} Leader Phone Number`, leaderPhone]);
            }
        }

        if (groupLeaderAddress) {
            tableRows.push([`${isTeamOrGroup} Leader Address`, formatAddress(groupLeaderAddress)]);
        }
    }

    if(membershipType.category === MembershipTypeCategory.HORSE) {
        if(membership.horse) {
            if (horse.ownerName) {
                tableRows.push(["Owner Name", horse.ownerName]);
            }
            if (horse.cogginsNumber) {
                tableRows.push(["Coggins Accession Number", horse.cogginsNumber]);
            }
            if (horse.cogginsDate) {
                tableRows.push(["Coggins Test Date", moment(horse.cogginsDate).format("MM/DD/YYYY")]);
            }
            if (horse.cogginsState) {
                tableRows.push(["Coggins State of Test", horse.cogginsState]);
            }
            if (horse.coggins) {
                tableRows.push(["Coggins File Uploaded", horse.coggins.title]);
            }
        }
    }

    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 35
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    if (isDownload === true) {
        const pdfName = `${organization?.nickname || organization?.name}_${membership.type}_Confirmation.pdf`;
        doc.save(pdfName);
    } else {
        const blobPDF =  new Blob([ doc.output() ], { type : 'application/pdf'});
        return blobPDF;
    }
};

export async function generateAELIndividualMembershipApplicationReport(membership: Membership, membershipType: OrganizationMembershipType, isDownload?: Boolean) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`AEL Membership Confirmation`, 14, 20);
    doc.setFontSize(10);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);


    // define the columns we want and their titles
    const tableColumn = ["", ""];

    // define an empty array of rows
    const tableRows: any[] = [];

    // Basic details of an Individual Membership
    tableRows.push(["Membership Type Name", membership.type]);
    tableRows.push(["Membership Id", membership.membershipId === "" ? "Not assigned yet" : membership.membershipId]);
    tableRows.push(["Amount Paid", membership.amountPaid ? "$" + membership.amountPaid.toFixed(2) : "$0.00"]);
    tableRows.push(["Membership Status", membership.membershipStatus]);
    tableRows.push(["Date Membership Ends", membership.dateMembershipEnds === "" ? "This membership does not expire" : moment(membership.dateMembershipEnds, "YYYY-MM-DD").format("MM-DD-YYYY")]);
    tableRows.push(["Member Name", membership.personName]);

    // Include the team
    if (membership.groupContact?.name) tableRows.push(["Team Name", membership.groupContact?.name]);
    else if (membership.teamId) {
        let teamName = "";
        const teamQuery = await getTeamById(membership.teamId);
        if (teamQuery.isSuccess) {
            const team: Team = teamQuery.result;
            teamName = team.name;
        } else {
            teamName = "ERROR";
        }
        tableRows.push(["Team Name", teamName]);
    }

    // Include AEL membership details
    if (membershipType.name.toLowerCase().includes("coach")) tableRows.push(["Team Role", membership.aelTeamRole]);
    if (membership.backNumber) tableRows.push(["Arm Number", membership.backNumber]);
    if (membership.gradeLevel) tableRows.push(["Grade Level", membership.gradeLevel]);
    if (membership.aelDivision) tableRows.push(["Division", membership.aelDivision]);
    

    let addressData;
    let contactData;
    let personInfo: PersonalInformation | undefined = undefined;
    let contactPersonId = membership.contactPersonId;
    if (contactPersonId) {
        const queryResult = await getPersonalInformationByPersonId(contactPersonId);
        if (queryResult.isSuccess) {
            personInfo = queryResult.result;
        }
    }

    const queryResult = await getAddressById(personInfo?.addressId ?? "");
    addressData = queryResult.result;

    const phoneResult = await getContactById(personInfo?.contactId ?? "");
    contactData = phoneResult.result;

    let phone = "";
    if(contactData.cell) {
        phone = `${contactData.cell}`
    } else if (contactData.home) {
        phone = `${contactData.home}`
    } else {
        phone = `${contactData.work}`
    }
    tableRows.push(["Contact Person", contactData.name]);
    tableRows.push(["Email Address", contactData.personalEmail]);
    tableRows.push(["Phone Number", phone]);
    tableRows.push(["Address", formatAddress(addressData)]);

    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 35
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    if (isDownload === true) {
        const pdfName = `AEL_Membership_Confirmation.pdf`;
        doc.save(pdfName);
    } else {
        const blobPDF =  new Blob([ doc.output() ], { type : 'application/pdf'});
        return blobPDF;
    }
};

export async function generateAELTeamMembershipApplicationReport(membership: Membership, membershipType: OrganizationMembershipType, isDownload?: Boolean) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`AEL Membership Confirmation`, 14, 20);
    doc.setFontSize(10);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);


    // define the columns we want and their titles
    const tableColumn = ["", ""];

    // define an empty array of rows
    const tableRows: any[] = [];

    // Basic details of a Team Membership
    tableRows.push(["Membership Type Name", membership.type]);
    tableRows.push(["Membership Id", membership.membershipId === "" ? "Not assigned yet" : membership.membershipId]);
    tableRows.push(["Date Membership Ends", membership.dateMembershipEnds === "" ? "This membership does not expire" : moment(membership.dateMembershipEnds, "YYYY-MM-DD").format("MM-DD-YYYY")]);
    
    let groupContactData;
    let groupLeaderContactData;
    let groupLeaderAddress;
    let groupAddress;
    const isTeamOrGroup = membershipType.applicationFields?.isTeam ? "Team" : "Group";
    
    if (membership.groupContactId?.length) {
        const groupContactResult = await getContactById(membership.groupContactId ?? "");
        groupContactData = groupContactResult.result;
    }

    if(groupContactData && groupContactData.mailingAddress) {
        const groupLeaderAddressResult = await getAddressById(groupContactData.mailingAddress);
        groupAddress = groupLeaderAddressResult.result;
    }

    if (membership.groupLeaderContactId?.length) {
        const groupLeaderContactResult = await getContactById(membership.groupLeaderContactId ?? "");
        groupLeaderContactData = groupLeaderContactResult.result;
    }

    if(groupLeaderContactData && groupLeaderContactData.mailingAddress) {
        const groupLeaderAddressResult = await getAddressById(groupLeaderContactData.mailingAddress);
        groupLeaderAddress = groupLeaderAddressResult.result;
    }

    if (groupContactData) {
        if (groupContactData.name) {
            tableRows.push([`${isTeamOrGroup} Name`, groupContactData.name]);
        }
        if (groupContactData.personalEmail) {
            tableRows.push([`${isTeamOrGroup} Email Address`, groupContactData.personalEmail]);
        }
        let phone = "";
        if(groupContactData.cell) {
            phone = `${groupContactData.cell}`
        } else if (groupContactData.home) {
            phone = `${groupContactData.home}`
        } else {
            phone = `${groupContactData.work}`
        }
        if (groupContactData.cell || groupContactData.home || groupContactData.work) {
            tableRows.push([`${isTeamOrGroup} Phone Number`, phone]);
        }
    }

    if (groupAddress) {
        tableRows.push([`${isTeamOrGroup} Address`, formatAddress(groupAddress)]);
    }

    if (groupLeaderContactData) {
        if(groupLeaderContactData.name) {
            tableRows.push([`${isTeamOrGroup} Head Coach Name`, groupLeaderContactData.name]);
        }
        if(groupLeaderContactData.personalEmail) {
            tableRows.push([`${isTeamOrGroup} Leader Email Address`, groupLeaderContactData.personalEmail]);
        }
        let leaderPhone = "";
        if(groupLeaderContactData.cell) {
            leaderPhone = `${groupLeaderContactData.cell}`
        } else if (groupLeaderContactData.home) {
            leaderPhone = `${groupLeaderContactData.home}`
        } else {
            leaderPhone = `${groupLeaderContactData.work}`
        }
        if (groupLeaderContactData.cell || groupLeaderContactData.home || groupLeaderContactData.work) {
            tableRows.push([`${isTeamOrGroup} Leader Phone Number`, leaderPhone]);
        }
    }

    if (groupLeaderAddress) {
        tableRows.push([`${isTeamOrGroup} Leader Address`, formatAddress(groupLeaderAddress)]);
    }

    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 35
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    if (isDownload === true) {
        const pdfName = `AEL_Team_Membership_Confirmation.pdf`;
        doc.save(pdfName);
    } else {
        const blobPDF =  new Blob([ doc.output() ], { type : 'application/pdf'});
        return blobPDF;
    }
};