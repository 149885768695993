import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventEntryInput, CreateEventEntryMutation, DeleteEventEntryInput, DeleteEventEntryMutation, GetEventEntryQuery, ListEventEntriesQuery, UpdateEventEntryInput, UpdateEventEntryMutation } from "../../API";
import moment from "moment";
import { EventEntry } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventEntry. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventEntry.
*/
export async function createEventEntry(input: CreateEventEntryInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventEntry", "No input", "Create EventEntry received no input.");
   try {
        const fullInput: CreateEventEntryInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventEntry, { input: fullInput }))) as GraphQLResult<CreateEventEntryMutation>;
       else result = (await API.graphql({
           query: mutations.createEventEntry,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventEntryMutation>;
       const eventEntry = result.data?.createEventEntry;
       return formatResult(true, "EventEntry", eventEntry, "Successfully created the eventEntry.");
   } catch (error: any) {
       return formatResult(false, "EventEntry", error, "Error creating record in the database for type: eventEntry");
   }
}

/**
* Handle updating a new record in the database for type: eventEntry. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventEntry.
*/
export async function updateEventEntry(input: UpdateEventEntryInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventEntry", "No input", "Update EventEntry received no input.");
   try {
        const fullInput: UpdateEventEntryInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventEntry, { input: fullInput }))) as GraphQLResult<UpdateEventEntryMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventEntry,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventEntryMutation>;
       const eventEntry = result.data?.updateEventEntry;
       return formatResult(true, "EventEntry", eventEntry, "Successfully updated the eventEntry.");
   } catch (error: any) {
       return formatResult(false, "EventEntry", error, "Error updating record in the database for type: eventEntry");
   }
}

/**
* Handle deleting a new record in the database for type: eventEntry. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventEntry.
*/
export async function deleteEventEntry(input: DeleteEventEntryInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventEntry", "No input", "Delete EventEntry received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventEntry, { input: input }))) as GraphQLResult<DeleteEventEntryMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventEntry,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventEntryMutation>;
       const eventEntry = result.data?.deleteEventEntry;
       return formatResult(true, "EventEntry", eventEntry, "Successfully deleted the eventEntry.");
   } catch (error: any) {
       return formatResult(false, "EventEntry", error, "Error deleting record in the database for type: eventEntry");
   }
}

/**
* Get all records in the database for type: eventEntry. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntry object
*/
export async function getAllEventEntries(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventEntries,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventEntriesQuery>;

        let items = result.data?.listEventEntries?.items as EventEntry[];
        let nextToken = result.data?.listEventEntries?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventEntries,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventEntriesQuery>;

            const nextItems = nextResult.data?.listEventEntries?.items as EventEntry[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventEntries?.nextToken;
        }

        return formatResult(true, "EventEntry", items, "Successfully got the EventEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventEntry", error, "Error reading record in the database for type: eventEntries");
    }
}

/**
* Read a specific record in the database for type: eventEntry. 
* 
* @param {string}  id                  The eventEntry id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntry object
*/
export async function getEventEntryById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventEntry,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventEntryQuery>;
       const eventEntry = result.data?.getEventEntry;
       return formatResult(true, "EventEntry", eventEntry, "Successfully got the eventEntry.");
   } catch (error: any) {
       return formatResult(false, "EventEntry", error, "Error reading record in the database for type: eventEntry");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventEntry. 
* 
* @param {string}  personId            The person id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntry object
*/
export async function getEventEntriesByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventEntriesByPersonId,
            variables: {
                limit: 1000,
                personId: personId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventEntriesByPersonId?.items as EventEntry[];
        let nextToken = result.data?.eventEntriesByPersonId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventEntriesByPersonId,
                variables: {
                    limit: 1000,
                    personId: personId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventEntriesByPersonId?.items as EventEntry[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventEntriesByPersonId?.nextToken;
        }

        return formatResult(true, "EventEntry", items, "Successfully got the EventEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventEntry", error, "Error reading record in the database for type: eventEntries");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventEntry. 
* 
* @param {string}  riderId            The rider id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntry object
*/
export async function getEventEntriesByRiderId(riderId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {or: [{riderId: {eq: riderId}}, {secondRiderId: {eq: riderId}}]};
        const result = (await API.graphql({
            query: queries.listEventEntries,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.listEventEntries?.items as EventEntry[];
        let nextToken = result.data?.listEventEntries?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventEntries,
                variables: {
                    limit: 1000,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.listEventEntries?.items as EventEntry[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventEntries?.nextToken;
        }

        return formatResult(true, "EventEntry", items, "Successfully got the EventEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventEntry", error, "Error reading record in the database for type: eventEntries");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventEntry. 
* 
* @param {string}  ownerId             The owner id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntry object
*/
export async function getEventEntriesByOwnerId(ownerId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {ownerId: {eq: ownerId}};
        const result = (await API.graphql({
            query: queries.listEventEntries,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.listEventEntries?.items as EventEntry[];
        let nextToken = result.data?.listEventEntries?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventEntries,
                variables: {
                    limit: 1000,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.listEventEntries?.items as EventEntry[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventEntries?.nextToken;
        }

        return formatResult(true, "EventEntry", items, "Successfully got the EventEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventEntry", error, "Error reading record in the database for type: eventEntries");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventEntry. 
* 
* @param {string}  trainerId           The trainer id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntry object
*/
export async function getEventEntriesByTrainerId(trainerId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {trainerId: {eq: trainerId}};
        const result = (await API.graphql({
            query: queries.listEventEntries,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.listEventEntries?.items as EventEntry[];
        let nextToken = result.data?.listEventEntries?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventEntries,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.listEventEntries?.items as EventEntry[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventEntries?.nextToken;
        }

        return formatResult(true, "EventEntry", items, "Successfully got the EventEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventEntry", error, "Error reading record in the database for type: eventEntries");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventEntry. 
* 
* @param {string}  eventId             The event id
* @param {string}  personId            The person id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntry object
*/
export async function getEventEntriesByEventIdPersonId(eventId: string, personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventEntriesByEventIdByPersonId,
            variables: {
                limit: 100,
                eventId: eventId, 
                personId: {eq: personId}
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventEntriesByEventIdByPersonId?.items as EventEntry[];
        let nextToken = result.data?.eventEntriesByEventIdByPersonId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventEntriesByEventIdByPersonId,
                variables: {
                    limit: 100,
                    eventId: eventId, 
                    personId: {eq: personId},
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventEntriesByEventIdByPersonId?.items as EventEntry[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventEntriesByEventIdByPersonId?.nextToken;
        }

        return formatResult(true, "EventEntry", items, "Successfully got the EventEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventEntry", error, "Error reading record in the database for type: eventEntries");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventEntry. 
* 
* @param {string}  eventId             The event id
* @param {string}  personId            The person id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntry object
*/
export async function getEventEntriesByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventEntriesByEventId,
            variables: {
                limit: 100,
                eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventEntriesByEventId?.items as EventEntry[];
        let nextToken = result.data?.eventEntriesByEventId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventEntriesByEventId,
                variables: {
                    limit: 100,
                    eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventEntriesByEventId?.items as EventEntry[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventEntriesByEventId?.nextToken;
        }

        return formatResult(true, "EventEntry", items, "Successfully got the EventEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventEntry", error, "Error reading record in the database for type: eventEntries");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventEntry. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntry object
*/
export async function getInProgressEventEntriesByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventEntriesByEventIdByStatus,
            variables: {
                limit: 100,
                eventId: eventId, 
                status: {eq: "in_progress"}
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventEntriesByEventIdByStatus?.items as EventEntry[];
        let nextToken = result.data?.eventEntriesByEventIdByStatus?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventEntriesByEventIdByStatus,
                variables: {
                    limit: 100,
                    eventId: eventId, 
                    status: {eq: "in_progress"},
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventEntriesByEventIdByStatus?.items as EventEntry[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventEntriesByEventIdByStatus?.nextToken;
        }

        return formatResult(true, "EventEntry", items, "Successfully got the EventEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventEntry", error, "Error reading record in the database for type: eventEntries");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventEntry. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntry object
*/
export async function getSubmittedEventEntriesByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventEntriesByEventIdByStatus,
            variables: {
                limit: 100,
                eventId: eventId, 
                status: {eq: "submitted"}
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventEntriesByEventIdByStatus?.items as EventEntry[];
        let nextToken = result.data?.eventEntriesByEventIdByStatus?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventEntriesByEventIdByStatus,
                variables: {
                    limit: 100,
                    eventId: eventId, 
                    status: {eq: "submitted"},
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventEntriesByEventIdByStatus?.items as EventEntry[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventEntriesByEventIdByStatus?.nextToken;
        }

        return formatResult(true, "EventEntry", items, "Successfully got the EventEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventEntry", error, "Error reading record in the database for type: eventEntries");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventEntry. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntry object
*/
export async function getAcceptedEventEntriesByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventEntriesByEventIdByStatus,
            variables: {
                limit: 100,
                eventId: eventId, 
                status: {eq: "complete"}
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventEntriesByEventIdByStatus?.items as EventEntry[];
        let nextToken = result.data?.eventEntriesByEventIdByStatus?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventEntriesByEventIdByStatus,
                variables: {
                    limit: 100,
                    eventId: eventId, 
                    status: {eq: "complete"},
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventEntriesByEventIdByStatus?.items as EventEntry[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventEntriesByEventIdByStatus?.nextToken;
        }

        return formatResult(true, "EventEntry", items, "Successfully got the EventEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventEntry", error, "Error reading record in the database for type: eventEntries");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventEntry. 
* 
* @param {string}  horseId             The horse id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntry object
*/
export async function getEventEntriesByHorseId(horseId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {horseId: {eq: horseId}};
        const result = (await API.graphql({
            query: queries.listEventEntries,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.listEventEntries?.items as EventEntry[];
        let nextToken = result.data?.listEventEntries?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventEntries,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.listEventEntries?.items as EventEntry[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventEntries?.nextToken;
        }

        return formatResult(true, "EventEntry", items, "Successfully got the EventEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventEntry", error, "Error reading record in the database for type: eventEntries");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventDivision. 
* 
* @param {string}  eventId                  The event id
* @param {string}  authMode                 API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventDivision object
*/
export async function getEventEntryByEventIdByBackNumber(eventId: string, backNumber: number, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventEntriesByEventIdByNumber,
            variables: {
                limit: 10,
                eventId: eventId,
                number: {eq: backNumber}
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventEntriesByEventIdByNumber?.items as EventEntry[];
        let nextToken = result.data?.eventEntriesByEventIdByNumber?.nextToken;

        while ((!items || (items && items.length === 0)) && nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventEntriesByEventIdByNumber,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    number: {eq: backNumber},
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventEntriesByEventIdByNumber?.items as EventEntry[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventEntriesByEventIdByNumber?.nextToken;
        }

        const eventEntrys = items;
        if (eventEntrys && eventEntrys[0]) return formatResult(true, "EventEntry", eventEntrys[0], "Successfully got the eventEntry.");
        else return formatResult(false, "EventEntry", null, "Could not find the eventEntry.");
    } catch (error: any) {
        console.error(error);
        return formatResult(false, "EventEntry", error, "Error reading record in the database for type: eventEntrys");
    }
}