import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { Membership } from "../../models";
import { getMembershipsByOrganizationIdByActiveStatus } from "../../utilities/membership/Membership";

interface _Props {
    onSelect: Function
    selectedValue?: string
    organizationId?: string
    membership?: Membership
}

export interface formattedOption {
    label: string
    value: string
    membership: Membership | null
}

const AddGroupMemberships: React.FC<_Props> = ({membership, organizationId, onSelect, selectedValue}) => {

    const defaultOption: formattedOption = {
        label: "-",
        value: "-",
        membership: null
    }
   
    const [formattedMemberships, setFormattedMemberships] = useState<formattedOption[] | null | undefined>();
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();

    const getAllMembershipsOfOrganization = async (organizationId: string, status: "active" | "expired") => {
        const queryResult = await getMembershipsByOrganizationIdByActiveStatus(organizationId, status);
        if (queryResult.isSuccess) {
            const allMemberships: Membership[] = [];
            if (membership) {
                queryResult.result.forEach((element: Membership) => {
                    if (element.membershipId !== membership.membershipId) {
                        allMemberships.push(element);
                    }
                });
                formatMemberships(allMemberships);
            }
        }
    };

    const formatMemberships = (memberships: Membership[]) => {
        let formattedMemberships = [];
        for (var i = 0; i < memberships.length; i++) {
            const membership = memberships[i];
            if (membership) {
                let object: formattedOption = {
                    value: membership.id,
                    label: (membership.membershipId ? `${membership.membershipId} - ${membership.personName}` : membership.personName) || "",
                    membership: membership
                };
                formattedMemberships.push(object);
            }
        }
        const membershipsWithoutMembershipId = formattedMemberships.filter((i: formattedOption) => {return i.membership?.membershipId === ""});
        const membershipsWithMembershipId = formattedMemberships.filter((i: formattedOption) => {return i.membership?.membershipId !== ""});
        const sorted = membershipsWithMembershipId.sort((a, b) => parseInt(a.membership?.membershipId || "") - parseInt(b.membership?.membershipId || ""));
        sorted.unshift(defaultOption);
        sorted.push(...membershipsWithoutMembershipId);
        setFormattedMemberships(sorted);
        if (selectedValue) findValueInList(selectedValue, sorted);
    }

    const findValueInList = (value: string, MembershipsList?: formattedOption[]) => {
        let optionArray = MembershipsList || formattedMemberships;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        if (organizationId) {
            getAllMembershipsOfOrganization(organizationId, "active");
        }
    }, [organizationId]);

    useEffect(() => {
        if (organizationId) {
            getAllMembershipsOfOrganization(organizationId, "active");
        }
    }, []);

    useEffect(() => {
        const setValue = async () => {
            if (selectedValue) {
                if (!formattedMemberships && organizationId) {
                    await getAllMembershipsOfOrganization(organizationId, "active");
                } else {
                    findValueInList(selectedValue);
                }
                
            } else {
                setCurrentValue(undefined);
            }
        }
        setValue();
    }, [selectedValue]);

    const handleOnChange = async (event: any) => {
        if (event) {
            setCurrentValue(event);
            onSelect(event);
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <Select
            styles={{
                // Fixes the overlapping problem of the component
                menu: provided => ({ ...provided, zIndex: 9999 })
            }}
            value={currentValue}
            menuPortalTarget={document.body}
            options={formattedMemberships!}
            onChange={handleOnChange}
        />
    );
};

export default AddGroupMemberships;
