import {
    IonCheckbox,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Spinner from "../../Spinners/Spinner";
import { Event, EventAuditorFilter } from "../../../models";
import { TextAlertRoleFilter, TextAlertRolesFilters, TextAlertRolesFiltersForIHSAFinals, TextAlertRolesFiltersForLAECOrg, TextAlertRolesFiltersForPonyFinals } from "../../../utilities/roles/Roles";
import constants from "../../../constant/constant";

export interface FormattedRoleOption {
    name: string
    value: string
    isSelected: boolean
    children?: FormattedRoleOption[]
}

interface _Props {
    event?: (Event | null)
    startWithoutSelections?: boolean
    currentSelectedFilters?: (EventAuditorFilter[] | null)
    isExpandedOnStart?: boolean
    isCollapsable?: boolean
    organizationId?: string
    onSelect: Function
}

const SelectEventFilterRolesOptionForm: React.FC<_Props> = ({event, organizationId, currentSelectedFilters, onSelect, startWithoutSelections, isCollapsable, isExpandedOnStart}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(!isExpandedOnStart);
    const [formattedOptions, setFormattedOptions] = useState<FormattedRoleOption[] | null | undefined>();

    const formatEventRoleFilters = (currentSelections?: (EventAuditorFilter[] | null)) => {
        setIsLoading(true);
        let options: FormattedRoleOption[] = [];
        let selectedArray: string[] = [];
        let rolesList = TextAlertRolesFilters;
        if (event?.id === "c9edd954-6a8d-473e-88f3-a03f74e1bb53") rolesList = TextAlertRolesFiltersForPonyFinals;
        else if (event?.organizationId === constants.IHSA_ORGANIZATION.id) rolesList = TextAlertRolesFiltersForIHSAFinals;
        else if (organizationId === "4413e377-8501-46db-a284-6e252b2d82eb") rolesList = TextAlertRolesFiltersForLAECOrg;
        if (rolesList) {
            for (var i = 0; i < rolesList.length; i++) {
                const currentEventFilter: TextAlertRoleFilter = rolesList[i];

                // For the roles filter, we typically will start with none selected and make the person pick
                let isSelected = startWithoutSelections ? false : true; 

                // Check if this is an update, and the person has already selected certain roles
                if (currentSelections) {
                    // If the user has already subscribed to alerts, assume each option is unselected until it is seen
                    isSelected = false; 

                    // Cycle through the EventAuditorFilter objects to determine which options to check
                    currentSelections.forEach(currentSelection => {
                        
                        // If the current selection belongs to the roles filter
                        if (currentSelection.filter?.name === "Roles") {
                            if (currentSelection.options) {
                                currentSelection.options.forEach(option => {
                                    if (option === currentEventFilter.value) isSelected = true;
                                });
                            }
                        }
                    });
                }

                let childrenArray: (FormattedRoleOption[] | null) = null;

                if (currentEventFilter.children) {
                    childrenArray = [];
                    const childArray = currentEventFilter.children;
                    for (var j = 0; j < childArray.length; j++) {
                        const currentEventFilter: TextAlertRoleFilter = childArray[j];
        
                        // For the roles filter, we typically will start with none selected and make the person pick
                        let isChildSelected = startWithoutSelections ? false : true; 
        
                        // Check if this is an update, and the person has already selected certain roles
                        if (currentSelections) {
                            // If the user has already subscribed to alerts, assume each option is unselected until it is seen
                            isChildSelected = false; 
        
                            // Cycle through the EventAuditorFilter objects to determine which options to check
                            currentSelections.forEach(currentSelection => {
                                
                                // If the current selection belongs to the roles filter
                                if (currentSelection.filter?.name === "Roles") {
                                    if (currentSelection.options) {
                                        currentSelection.options.forEach(option => {
                                            if (option === currentEventFilter.value) {
                                                isChildSelected = true;
                                                isSelected = true; //Mark parent as selected too
                                            }
                                        });
                                    }
                                }
                            });
                        }

                        const formattedOption: FormattedRoleOption = {
                            name: currentEventFilter.name,
                            value: currentEventFilter.value,
                            isSelected: isChildSelected
                        };

                        childrenArray.push(formattedOption);
                    }
                }

                const formattedOption: FormattedRoleOption = {
                    name: currentEventFilter.name,
                    value: currentEventFilter.value,
                    isSelected: isSelected,
                    children: childrenArray || undefined
                };

                options.push(formattedOption);
                selectedArray.push(currentEventFilter.value);
            }
        }
        setFormattedOptions(options);
        setIsLoading(false);
    }

    useEffect(() => {
        formatEventRoleFilters(currentSelectedFilters);
    }, [currentSelectedFilters]);

    const handleSelect = (index: number, option: FormattedRoleOption, childIndex?: number) => {
        if (formattedOptions) {
            let updatedChildrenArray: (FormattedRoleOption[] | null) = option.children || null;
            if (option.children && childIndex !== undefined) {
                const oldChildOption = option.children[childIndex];
                if (oldChildOption.isSelected) {
                    // Now we are deselection this child option
                    const updatedChildOption: FormattedRoleOption = {
                        ...oldChildOption,
                        isSelected: !oldChildOption.isSelected
                    };
                    updatedChildrenArray = [
                        ...option.children.slice(0, childIndex),
                        updatedChildOption,
                        ...option.children.slice(childIndex+1)
                    ]; 
                } else {
                    // Now we are selecting this child option. Only ONE child option can be selected
                    let newChildArray = [];
                    for (let i = 0; i < option.children.length; i++) {
                        const currentOption = option.children[i];
                        if (i === childIndex) {
                            // This is the only one that should be selected
                            const updatedOption: FormattedRoleOption = {
                                ...currentOption,
                                isSelected: true
                            };
                            newChildArray.push(updatedOption);
                        } else {
                            // This is the one that should be NOT selected
                            const updatedOption: FormattedRoleOption = {
                                ...currentOption,
                                isSelected: false
                            };
                            newChildArray.push(updatedOption);
                        }
                    }
                    updatedChildrenArray = newChildArray;
                }
            }
            const updatedOption: FormattedRoleOption = {
                name: option.name,
                value: option.value,
                isSelected: childIndex === undefined ? !option.isSelected : option.isSelected,
                children: updatedChildrenArray || undefined
            };
            const updatedOptions: FormattedRoleOption[] = [
                ...formattedOptions.slice(0, index),
                updatedOption,
                ...formattedOptions.slice(index+1)
            ];
            setFormattedOptions(updatedOptions);
            onSelect(updatedOptions);
        }
    }

    return (
        <div>
            {isLoading ?
                <Spinner />
                :
                <>
                    {isCollapsable && (
                        <IonRow>
                            <IonCol size="12">
                                <h6>Roles <span className="description link" onClick={() => setIsCollapsed(!isCollapsed)}>({isCollapsed ? "Edit" : "Collapse"})</span></h6>
                            </IonCol>
                        </IonRow>
                    )}
                    {(!isCollapsed) ?
                        <>
                            <IonRow>
                                <IonCol>
                                    {(formattedOptions && formattedOptions.length > 0) && (
                                        <IonList className="bg-white">  
                                            {formattedOptions.map((formattedOption, index) => (
                                                <>
                                                    <IonItem key={index}>
                                                        <IonLabel>{formattedOption.name}</IonLabel>
                                                        <IonCheckbox color="primary" slot="start" checked={formattedOption.isSelected} onClick={() => handleSelect(index, formattedOption)} />
                                                    </IonItem>
                                                    {(formattedOption.isSelected && formattedOption.children) && (
                                                        <>
                                                            <p className="description text-danger pl-5 mt-1">Select One: </p>
                                                            {formattedOption.children.map((childOption, j) => (
                                                                <IonItem key={j} className="pl-5">
                                                                    <IonLabel>{childOption.name}</IonLabel>
                                                                    <IonCheckbox color="primary" slot="start" checked={childOption.isSelected} onClick={() => handleSelect(index, formattedOption, j)} />
                                                                </IonItem>
                                                            ))}
                                                        </>
                                                    )}
                                                </>
                                            ))}
                                        </IonList>
                                    )}
                                </IonCol>
                            </IonRow>
                        </>
                        :
                        <IonRow>
                            <IonCol>
                                {(formattedOptions && formattedOptions.length > 0) && (
                                    <ul>  
                                        {formattedOptions.map((formattedOption, i) => (
                                            <div key={i}>
                                                {(formattedOption.isSelected && formattedOption.children) ?
                                                    <li>
                                                        {formattedOption.children.map((child, j) => (
                                                            <span key={j}>
                                                                {child.isSelected && <span><IonLabel>{child.name}, </IonLabel></span>}
                                                            </span>
                                                        ))}
                                                    </li>
                                                    :
                                                    <>
                                                        {formattedOption.isSelected && <li><IonLabel>{formattedOption.name}</IonLabel></li>}
                                                    </>
                                                }
                                            </div>
                                        ))}
                                    </ul>
                                )}
                            </IonCol>
                        </IonRow>
                    }
                </>
            }
        </div>
    );
};

export default SelectEventFilterRolesOptionForm;