import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateContactInput, CreateContactMutation, DeleteContactInput, DeleteContactMutation, GetContactQuery, ListContactsQuery, UpdateContactInput, UpdateContactMutation } from "../../API";
import { Contact } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: contact. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created contact.
*/
export async function createContact(input: CreateContactInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "Contact", "No input", "Create Contact received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createContact, { input: input }))) as GraphQLResult<CreateContactMutation>;
        else result = (await API.graphql({
            query: mutations.createContact,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<CreateContactMutation>;
        const contact = result.data?.createContact;
        return formatResult(true, "Contact", contact, "Successfully created the contact.");
    } catch (error: any) {
        return formatResult(false, "Contact", error, "Error creating record in the database for type: contact");
    }
}

/**
* Handle updating a new record in the database for type: contact. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated contact.
*/
export async function updateContact(input: UpdateContactInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Contact", "No input", "Update Contact received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateContact, { input: input }))) as GraphQLResult<UpdateContactMutation>;
       else result = (await API.graphql({
           query: mutations.updateContact,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateContactMutation>;
       const contact = result.data?.updateContact;
       return formatResult(true, "Contact", contact, "Successfully updated the contact.");
   } catch (error: any) {
       return formatResult(false, "Contact", error, "Error updating record in the database for type: contact");
   }
}

/**
* Handle deleting a new record in the database for type: contact. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted contact.
*/
export async function deleteContact(input: DeleteContactInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Contact", "No input", "Delete Contact received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteContact, { input: input }))) as GraphQLResult<DeleteContactMutation>;
       else result = (await API.graphql({
           query: mutations.deleteContact,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteContactMutation>;
       const contact = result.data?.deleteContact;
       return formatResult(true, "Contact", contact, "Successfully deleted the contact.");
   } catch (error: any) {
       return formatResult(false, "Contact", error, "Error deleting record in the database for type: contact");
   }
}

/**
* Get all records in the database for type: contact. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the contact object
*/
export async function getAllContacts(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listContacts,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListContactsQuery>;

        let items = result.data?.listContacts?.items as Contact[];
        let nextToken = result.data?.listContacts?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listContacts,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListContactsQuery>;

            const nextItems = nextResult.data?.listContacts?.items as Contact[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listContacts?.nextToken;
        }

        return formatResult(true, "Contact", items, "Successfully got the Contacts.");
    } catch (error: any) {
        return formatResult(false, "Contact", error, "Error reading record in the database for type: contacts");
    }
}

/**
* Read a specific record in the database for type: contact. 
* 
* @param {string}  id                  The contact id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the contact object
*/
export async function getContactById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getContact,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetContactQuery>;
       const contact = result.data?.getContact;
       return formatResult(true, "Contact", contact, "Successfully got the contact.");
   } catch (error: any) {
       return formatResult(false, "Contact", error, "Error reading record in the database for type: contact");
   }
}