import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event} from "../../../../../../models";
import Header from "../../../../../../components/Headers/Header";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../../utilities/events/Event";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import EventECSettingsForm from "../../../../../../components/EventSettings/EventECSettingsForm";
import EventUSEFSettingsForm from "../../../../../../components/EventSettings/EventUSEFSettingsForm";
import ErrorAlert from "../../../../../../components/Errors/ErrorAlert";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventAffiliationSettingsPage: React.FC<EventPageProps> = ({match}) => {

    const [event, setEvent] = useState<Event>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getEvent();
    }, [match, match.params.id]);


    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={event ? event.name : "Settings"} />
                {event && (
                    <>
                        {error && <ErrorAlert error={error} />}
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>EC Settings</IonCardTitle>
                                    <IonCardContent>
                                        {(event && event.organizationId) && (
                                            <EventECSettingsForm event={event} />
                                        )}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>USEF Settings</IonCardTitle>
                                    <IonCardContent>
                                        {(event && event.organizationId) && (
                                            <EventUSEFSettingsForm event={event} />
                                        )}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                
            </IonContent>
        </IonPage>
    );
};

export default EventAffiliationSettingsPage;