import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import React, { useState } from "react";
import {attachSourceToCustomer} from "../../utilities/stripe/AttachSource";
import ErrorAlert from "../Errors/ErrorAlert";
import { IonButton } from "@ionic/react";

interface _Props {
    client_secret: string
    personEmail: string
    stripeAccountId: string
    handleCheckoutResult: Function
}

const SaveCard: React.FC<_Props> = ({client_secret, personEmail, stripeAccountId, handleCheckoutResult}) => {

    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState("");

    const stripe = useStripe();
    const elements = useElements();

    // A unique key you need to pass to Stripe on the client side (here) to collect card details
    let clientSecret = client_secret;

    const handleAddCreditCard = async () => {
        if (elements) {
            const card = elements.getElement(CardElement);
            if (stripe && card) {
                // The client_secret comes from an API call - make sure the promise has resolved
                const result = await stripe.createSource(card, {
                    type: "card",
                    owner: {
                        email: personEmail || ""
                    }
                });
                if (result.source?.id) {
                    const attachResult = await attachSourceToCustomer(stripeAccountId, result.source?.id);
                    if (attachResult.isSuccess) {
                        handleCheckoutResult(true, "Created a new source for the organization.", result);
                    } else {
                        handleCheckoutResult(false, "Could not attach the new source to the organization.");
                        setIsProcessing(false);
                    }
                } else {
                    handleCheckoutResult(false, "Could not create the new source.");
                    setIsProcessing(false);
                }
            } else {
                setError("Sorry, we could not attach your card to your account at this time.");
                setIsProcessing(false);
            }
        } else {
            setError("Sorry, we could not attach your card to your account at this time.");
            setIsProcessing(false);
        }
        
    };

    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here, which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded. - Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }
        try {
            setIsProcessing(true);
            await handleAddCreditCard();
        } catch (err) {
            setError("Sorry, something went wrong. Please contact customer service if this continues.");
            console.error(err);
            handleCheckoutResult(false, err);
            setIsProcessing(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} data-secret={clientSecret}>
            { error && <ErrorAlert width="12" error={error} />}
            <CardSection />
            {isProcessing ?
                <IonButton disabled>Submit</IonButton>
                :
                <IonButton disabled={!stripe} type="submit">Submit</IonButton>
            }
        </form>
    );
};

export default SaveCard;