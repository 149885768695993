import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { UpdateEventEntryInput, UpdateHorseInput } from "../../../../../API";
import { Event, EventEntry } from "../../../../../models";
import { updateEventEntry } from "../../../../../utilities/eventEntry/EventEntry";
import { calculateHeightInHands } from "../../../../../utilities/horse/Height";
import { updateHorse } from "../../../../../utilities/horse/Horse";
import SuccessBanner from "../../../../Banners/SuccessBanner";
import ErrorAlert from "../../../../Errors/ErrorAlert";
import HorseHeightInput from "../../../../Horse/HorseHeightInput";
import Spinner from "../../../../Spinners/Spinner";
import { breeds } from "../../../../../utilities/horse/Breed";
import { Input } from "reactstrap";

interface _Props {
    entry: EventEntry
    event: Event
}

const EditEntryHorseDetailsCard: React.FC<_Props> = ({entry, event}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [horseName, setHorseName] = useState("");
    const [height, setHeight] = useState(0);
    const [yearBorn, setYearBorn] = useState("");
    const [breed, setBreed] = useState("");
    const [sex, setSex] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const getEntryInformation = (entry: EventEntry) => {
        setHorseName(entry.horseName || "");
        setHeight(parseInt(entry?.horse?.height || "0") || 0);
    }

    useEffect(() => {
        if (entry) {
            getEntryInformation(entry);
        }
    }, [entry]);

    const handleHeightChange = (heightInInches: number) => {
        setHeight(heightInInches);
    }

    const handleEdit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        if (entry.horseId) {
            let input: UpdateHorseInput = {
                id: entry.horseId,
                name: horseName,
                height: height.toString()
            };
            const updateResult = await updateHorse(input);
            if (updateResult.isSuccess) {
                const updateEntryInput: UpdateEventEntryInput = {
                    id: entry.id,
                    horseName: horseName,
                    horseId: updateResult.result.id
                };
                const updateEntryResult = await updateEventEntry(updateEntryInput);
                if (updateEntryResult.isSuccess) {
                    setSuccess("Updated the horse info.");
                    setIsEdit(false);
                    setIsLoading(false);
                } else {
                    setError("Could not update the entry.");
                    setIsLoading(false);
                    return;
                }
            } else {
                setError("Could not update the horse");
                setIsLoading(false);
            }
        } else {
            setError("Could not find the horse in the database.");
            setIsLoading(false);
        }
    }

    return (
        <>
            <IonCard color="white" className="ion-padding">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h4>Horse Details</h4>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {success && <SuccessBanner width="12" success={success} />}
                        {isEdit ?
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Horse Name</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={horseName}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setHorseName(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <HorseHeightInput height={height} onChange={handleHeightChange} />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Year Born</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={yearBorn}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setYearBorn(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel position="stacked">Breed</IonLabel>
                                        <Input 
                                            type="select" 
                                            name="select"
                                            value={breed}
                                            id="breedSelect"
                                            onChange={e => setBreed(e.target.value)}
                                        >
                                            <option>-</option>
                                            {breeds.map((breed, index) => (
                                                <option key={index}>{breed}</option>
                                            ))}
                                        </Input>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel position="stacked">Sex</IonLabel>
                                        <Input 
                                            type="select" 
                                            name="select"
                                            value={sex}
                                            id="sexSelect"
                                            onChange={e => setSex(e.target.value)}
                                        >
                                            <option>-</option>
                                            <option>Gelding</option>
                                            <option>Mare</option>
                                            <option>Stallion</option>
                                            <option>Colt</option>
                                        </Input>
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Horse Name</IonLabel>
                                            <IonText><p>{horseName}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Horse Height</IonLabel>
                                            <IonText><p>{calculateHeightInHands(height)} hands</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Year Born</IonLabel>
                                            <IonText><p>{yearBorn}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Breed</IonLabel>
                                            <IonText><p>{breed}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Sex</IonLabel>
                                            <IonText><p>{sex}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                        {isLoading ?
                            <Spinner />
                            :
                            <IonRow className="ion-justify-content-center">
                                {isEdit && (
                                    <IonCol sizeMd="4" className="ion-text-center">
                                        <IonButton
                                            className="ion-margin-top"
                                            color="primary"
                                            expand="block"
                                            onClick={() => setIsEdit(false)}
                                        >
                                            Cancel
                                        </IonButton>
                                    </IonCol>
                                )}
                                <IonCol sizeMd="4" className="ion-text-center">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="tertiary"
                                        expand="block"
                                        onClick={isEdit ? handleEdit : (() => setIsEdit(true))}
                                    >
                                        {!isEdit && "Allow "}Edit
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        }
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default EditEntryHorseDetailsCard;