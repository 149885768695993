import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event, OrganizationDivision} from "../../../../../models";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import SelectOrganizationDivision from "../../../../../components/OrganizationDivision/SelectOrganizationDivision";
import CreateEventDivisionForm from "../../../../../components/EventDivision/CreateEventDivisionForm";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventDivisionCreatePage: React.FC<EventPageProps> = ({match}) => {
    const [isFromTemplate, setIsFromTemplate] = useState(true);
    const [template, setTemplate] = useState<OrganizationDivision | null | undefined>();

    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [error, setError] = useState("");

    async function getEvent() {
        const queryResult = await getEventById(match.params.id);
        if (queryResult.isSuccess) {
            setCurrentEvent(queryResult.result);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    useEffect(() => {
        if (match.params.id) getEvent();
    }, [match]);

    const handleSelectTemplate = (item: OrganizationDivision | null | undefined) => {
        setTemplate(item);
    }

    const handleSubmit = () => {
        
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent ? currentEvent.name : "Event Settings"} />
                {error && <ErrorAlert width="12" error={error} />}
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard color="white" className="ion-padding">
                            <IonCardSubtitle>
                                <p>You can use a template for a division using your Organization's Division Templates.</p>
                            </IonCardSubtitle>
                            <IonCardContent>
                                <IonRow className="ion-align-items-center">
                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-center"> 
                                        <IonButton color={isFromTemplate ? "dark" : "light"} onClick={() => setIsFromTemplate(true)}>Use Template</IonButton>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-center"> 
                                        <IonButton color={isFromTemplate ? "light" : "dark"} onClick={() => {setIsFromTemplate(false); setTemplate(undefined);}}>Create New</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                {isFromTemplate &&
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <IonCard color="white" className="ion-padding">
                                <IonCardTitle>
                                    <IonRow>
                                        <IonCol>
                                            <IonText>
                                                <h3>Select Template</h3>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonCardTitle>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>
                                            {currentEvent && currentEvent.organizationId && (
                                                <SelectOrganizationDivision organizationId={currentEvent?.organizationId} onSelect={handleSelectTemplate} />
                                            )}
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                }
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                <IonRow>
                                    <IonCol>
                                        <IonText>
                                            <h3>Division</h3>
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                            </IonCardTitle>
                            <IonCardContent>
                                {currentEvent && currentEvent.id && currentEvent.organizationId && (
                                    <CreateEventDivisionForm eventId={currentEvent.id} organizationId={currentEvent?.organizationId} organizationDivision={template} onSubmit={handleSubmit} />
                                )}
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EventDivisionCreatePage;