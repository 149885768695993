import { Event, EventEntry, Membership } from "../../models";
import { getMembershipsByOrganizationId } from "../membership/Membership";
import moment from "moment";
import { FormattedEntryContactData } from "../../pages/dashboard/roles/eventStaff/events/EventParticipantContactListPage";
import { getEventEntriesByEventId } from "../eventEntry/EventEntry";
import { downloadCSVFile } from "./ReportDownloadCSV";
import constants from "../../constant/constant";
require('jspdf-autotable');

export async function generateAllEntriesReport(event: Event, allEntries?: EventEntry[]) {
    let headerRow = [
        "Status",
        "Back Number",
        "Rider Name",
        "Horse Name",
        "Barn Name",
        "Last Updated"
    ];

    if (event.organizationId === constants.RHSC_ORGANIZATION.id) {
        headerRow = [
            ...headerRow.slice(0, 2),
            "Member Id",
            ...headerRow.slice(2)
        ];
    }

    let memberMap = new Map<string, Membership>(); //string will be rider's name

    if (event.organizationId) {
        const queryResult = await getMembershipsByOrganizationId(event.organizationId);
        if (queryResult.isSuccess) {
            let memberships: Membership[] = queryResult.result;
            if (memberships && memberships.length > 0) {
                memberships.forEach(member => {
                    if (member.personName) memberMap.set(member.personName?.toLowerCase() || "", member);
                });
            }
        }
    }

    let rows = [];

    rows.push(headerRow);

    let entriesList = allEntries;

    if (!entriesList || entriesList.length === 0) {
        const queryResult = await getEventEntriesByEventId(event.id);
        if (queryResult.isSuccess) {
            entriesList = queryResult.result;
        }
    }
    
    if (entriesList && entriesList.length > 0) {
        const sorted = entriesList.sort((a, b) => a.status.localeCompare(b.status));
        if (sorted && sorted.length > 0) {
            for (let i = 0; i < sorted.length; i++) {
                let currentRow: any[] = [];
                const entry: EventEntry = sorted[i];
                const foundMembership = memberMap.get(entry.riderName?.toLowerCase() || "")
                currentRow.push(entry.status);
                currentRow.push(entry.number|| "");
                if (event.organizationId === constants.RHSC_ORGANIZATION.id) currentRow.push(foundMembership?.membershipId || "");
                currentRow.push(entry.rider?.name || entry.riderName || "");
                currentRow.push(entry.horse?.name || entry.horseName || "");
                currentRow.push(entry.barn?.name || entry.barnName || "");
                currentRow.push(entry.updatedOn ? moment(entry.updatedOn).format("MMM DD, YYYY h:mm:ss a") : "");
                rows.push(currentRow);

                if (entry.secondRiderName) {
                    let currentRow: any[] = [];
                    const entry: EventEntry = sorted[i];
                    const foundMembership = memberMap.get(entry.riderName?.toLowerCase() || "")
                    currentRow.push(entry.status);
                    currentRow.push(entry.number|| "");
                    if (event.organizationId === constants.RHSC_ORGANIZATION.id) currentRow.push(foundMembership?.membershipId || "");
                    currentRow.push(entry.secondRiderName || "");
                    currentRow.push(entry.horse?.name || entry.horseName || "");
                    currentRow.push(entry.barn?.name || entry.barnName || "");
                    currentRow.push(entry.updatedOn ? moment(entry.updatedOn).format("MM/DD/YYYY hh:mm:ss a") : "");
                    rows.push(currentRow);
                }
            }
        }
    }

    const fileName = "all_entries_report.csv";
    await downloadCSVFile(fileName, rows);
}

export function generateAllEntriesContactReport(data: FormattedEntryContactData[]) {
    const headerRow = [
        "Status",
        "Back Number",
        "Horse Name",
        "Primary Contact Name",
        "Primary Contact Phone Number",
        "Primary Contact Email Address",
        "Rider Name",
        "Rider Phone Number",
        "Rider Email Address",
        "Secondary Rider Name",
        "Secondary Rider Phone Number",
        "Secondary Rider Email Address",
        "Trainer Name",
        "Trainer Phone Number",
        "Trainer Email Address",
        "Creator Name",
        "Creator Email Address",
    ];

    let rows = [];
    rows.push(headerRow);

    if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            let currentRow: any[] = [];
            const contactData: FormattedEntryContactData = data[i];
            currentRow.push(contactData.status);
            currentRow.push(contactData.backNumber);
            currentRow.push(contactData.horseName);
            currentRow.push(contactData.primaryContactName);
            currentRow.push(contactData.primaryContactPhone);
            currentRow.push(contactData.primaryContactEmail);
            currentRow.push(contactData.riderName);
            currentRow.push(contactData.riderPhone);
            currentRow.push(contactData.riderEmail);
            currentRow.push(contactData.secondRiderName);
            currentRow.push(contactData.secondRiderPhone);
            currentRow.push(contactData.secondRiderEmail);
            currentRow.push(contactData.trainerName);
            currentRow.push(contactData.trainerPhone);
            currentRow.push(contactData.trainerEmail);
            currentRow.push(contactData.createdByName);
            currentRow.push(contactData.createdByEmail);
            rows.push(currentRow);
        }
    }
    
    const fileName = "entries_contact_list.csv";
    downloadCSVFile(fileName, rows);
}