import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreatePrizeMoneyTableInput, CreatePrizeMoneyTableMutation, DeletePrizeMoneyTableInput, DeletePrizeMoneyTableMutation, GetPrizeMoneyTableQuery, ListPrizeMoneyTablesQuery, UpdatePrizeMoneyTableInput, UpdatePrizeMoneyTableMutation } from "../../API";
import moment from "moment";
import { PrizeMoneyTable } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: prizeMoneyTable. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created prizeMoneyTable.
*/
export async function createPrizeMoneyTable(input: CreatePrizeMoneyTableInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "PrizeMoneyTable", "No input", "Create PrizeMoneyTable received no input.");
   try {
        const fullInput: CreatePrizeMoneyTableInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createPrizeMoneyTable, { input: fullInput }))) as GraphQLResult<CreatePrizeMoneyTableMutation>;
       else result = (await API.graphql({
           query: mutations.createPrizeMoneyTable,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreatePrizeMoneyTableMutation>;
       const prizeMoneyTable = result.data?.createPrizeMoneyTable;
       return formatResult(true, "PrizeMoneyTable", prizeMoneyTable, "Successfully created the prizeMoneyTable.");
   } catch (error: any) {
       return formatResult(false, "PrizeMoneyTable", error, "Error creating record in the database for type: prizeMoneyTable");
   }
}

/**
* Handle updating a new record in the database for type: prizeMoneyTable. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated prizeMoneyTable.
*/
export async function updatePrizeMoneyTable(input: UpdatePrizeMoneyTableInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "PrizeMoneyTable", "No input", "Update PrizeMoneyTable received no input.");
   try {
        const fullInput: UpdatePrizeMoneyTableInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updatePrizeMoneyTable, { input: fullInput }))) as GraphQLResult<UpdatePrizeMoneyTableMutation>;
       else result = (await API.graphql({
           query: mutations.updatePrizeMoneyTable,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdatePrizeMoneyTableMutation>;
       const prizeMoneyTable = result.data?.updatePrizeMoneyTable;
       return formatResult(true, "PrizeMoneyTable", prizeMoneyTable, "Successfully updated the prizeMoneyTable.");
   } catch (error: any) {
       return formatResult(false, "PrizeMoneyTable", error, "Error updating record in the database for type: prizeMoneyTable");
   }
}

/**
* Handle deleting a new record in the database for type: prizeMoneyTable. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted prizeMoneyTable.
*/
export async function deletePrizeMoneyTable(input: DeletePrizeMoneyTableInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "PrizeMoneyTable", "No input", "Delete PrizeMoneyTable received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deletePrizeMoneyTable, { input: input }))) as GraphQLResult<DeletePrizeMoneyTableMutation>;
       else result = (await API.graphql({
           query: mutations.deletePrizeMoneyTable,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeletePrizeMoneyTableMutation>;
       const prizeMoneyTable = result.data?.deletePrizeMoneyTable;
       return formatResult(true, "PrizeMoneyTable", prizeMoneyTable, "Successfully deleted the prizeMoneyTable.");
   } catch (error: any) {
       return formatResult(false, "PrizeMoneyTable", error, "Error deleting record in the database for type: prizeMoneyTable");
   }
}

/**
* Get all records in the database for type: prizeMoneyTable. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the prizeMoneyTable object
*/
export async function getAllPrizeMoneyTables(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listPrizeMoneyTables,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPrizeMoneyTablesQuery>;
        
        let items = result.data?.listPrizeMoneyTables?.items as PrizeMoneyTable[];
        let nextToken = result.data?.listPrizeMoneyTables?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listPrizeMoneyTables,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListPrizeMoneyTablesQuery>;

            const nextItems = nextResult.data?.listPrizeMoneyTables?.items as PrizeMoneyTable[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPrizeMoneyTables?.nextToken;
        }

        return formatResult(true, "PrizeMoneyTable", items, "Successfully got the PrizeMoneyTables.");
    } catch (error: any) {
        return formatResult(false, "PrizeMoneyTable", error, "Error reading record in the database for type: prizeMoneyTables");
    }
}

/**
* Read a specific record in the database for type: prizeMoneyTable. 
* 
* @param {string}  id                  The prizeMoneyTable id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the prizeMoneyTable object
*/
export async function getPrizeMoneyTableById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getPrizeMoneyTable,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetPrizeMoneyTableQuery>;
       const prizeMoneyTable = result.data?.getPrizeMoneyTable;
       return formatResult(true, "PrizeMoneyTable", prizeMoneyTable, "Successfully got the prizeMoneyTable.");
   } catch (error: any) {
       return formatResult(false, "PrizeMoneyTable", error, "Error reading record in the database for type: prizeMoneyTable");
   }
}

/**
* Get all records in the database that match the given criteria for type: prizeMoneyTable. 
* 
* @param {string}  organizationId      The organization id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the prizeMoneyTable object
*/
export async function getPrizeMoneyTablesByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationId: {eq: organizationId}};
        const result = (await API.graphql({
            query: queries.listPrizeMoneyTables,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPrizeMoneyTablesQuery>;
        
        let items = result.data?.listPrizeMoneyTables?.items as PrizeMoneyTable[];
        let nextToken = result.data?.listPrizeMoneyTables?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listPrizeMoneyTables,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListPrizeMoneyTablesQuery>;

            const nextItems = nextResult.data?.listPrizeMoneyTables?.items as PrizeMoneyTable[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPrizeMoneyTables?.nextToken;
        }

        return formatResult(true, "PrizeMoneyTable", items, "Successfully got the PrizeMoneyTables.");
    } catch (error: any) {
        return formatResult(false, "PrizeMoneyTable", error, "Error reading record in the database for type: prizeMoneyTables");
    }
}