import { Event, EventEntry } from "../../models";
import {
    IonButton,
    IonCol,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import EntryFormHeader from "./EntryFormHeader";
import EntryFormNavbar from "../Navbars/EntryFormNavbar";
import EntryCard from "./EntryCard";
import { updateEventEntry } from "../../utilities/eventEntry/EventEntry";
import EntryFeeForm from "./EventFeeForm";
import EntryRuleForm from "./EntryRulesForm";
import EntrySummaryCard from "./EntrySummaryCard";
import Spinner from "../Spinners/Spinner";
import NewEventStablingForm from "./NewEventStablingForm";
import DisplayStablingInfo from "./EntrySummary/DisplayStablingInfo";
import { updateBeddingRequestStatusByEntryId, updateStablingRequestStatusByEntryId } from "../../utilities/stablingRequest/StablingRequestStatus";
import { updateEventEntryFeeStatusByEventByEntry } from "../../utilities/eventEntryFee/UpdateEventEntryFeeStatus";
import { UpdateEventClassEntryInput, UpdateEventEntryInput } from "../../API";
import { useHistory } from "react-router";
import { sendEntryStatusSubmittedEmailWithInvoice } from "../../utilities/eventEntry/EventEntryEmail";
import { Input } from "reactstrap";
import { getEventClassEntriesByEventIdEntryId, updateEventClassEntry } from "../../utilities/eventClassEntry/EventClassEntry";
import ErrorBanner from "../Banners/ErrorBanner";
import EventClassAddToEntryForm from "../EventClass/EventClassAddToEntryForm";

interface _Props {
    event?: Event
    entry?: EventEntry
    onSubmit?: Function
}

const CreateEntriesForm: React.FC<_Props> = ({event, entry, onSubmit}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [currentEntry, setCurrentEntry] = useState<EventEntry | undefined>();
    const [note, setNote] = useState("");
    const [activeTab, setActiveTab] = useState<string>("info");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        if (entry) {
            setCurrentEntry(entry);
            if (entry.note) setNote(entry.note);
        }
        else {
            setCurrentEntry(undefined);
        }
    }, [entry]);

    const handleNavbarSelect = (selection: string) => {
        setActiveTab(selection);
    }

    const handleSubmitEntry = async (entry: EventEntry) => {
        setCurrentEntry(entry);
    }

    const handleNextPage = () => {
        if (activeTab === "info") setActiveTab("classes");
        if (activeTab === "classes") setActiveTab("stalls");
        if (activeTab === "stalls") setActiveTab("fees");
        if (activeTab === "fees") setActiveTab("rules");
        if (activeTab === "rules") setActiveTab("submit");
    }

    const handleUpdateStabling = async () => {
        if (entry) {
            const status = event?.eventOptions?.autoAcceptNewEntries ? "accepted" : "submitted";
            await updateBeddingRequestStatusByEntryId(entry.id, status);
            return await updateStablingRequestStatusByEntryId(entry.eventId, entry.id, status);
        } else {
            setError("Stabling Error");
        }
    }

    const handleUpdateFees = async (entry: EventEntry) => {
        const updateResult = await updateEventEntryFeeStatusByEventByEntry(entry.eventId, entry.id, event?.eventOptions?.autoAcceptNewEntries ? "accepted" : "submitted");
        return updateResult;
    } 

    async function updateEventClassEntriesStatus(entry: EventEntry) {
        const queryResult = await getEventClassEntriesByEventIdEntryId(entry.eventId, entry.id);
        const currentEventEntryClasses = queryResult.result;
        let result = true;
        if (currentEventEntryClasses && currentEventEntryClasses.length) {
            for (var i = 0; i < currentEventEntryClasses.length; i++) {
                const currentEventClassEntry = currentEventEntryClasses[i];
                const updateInput: UpdateEventClassEntryInput = {
                    id: currentEventClassEntry.id,
                    status: event?.eventOptions?.autoAcceptNewEntries ? "accepted" : "submitted"
                };
                const updateResult = await updateEventClassEntry(updateInput);
                if (!updateResult.isSuccess) {
                    result = false;
                    setError(updateResult.message);
                }
            }
        }
        return result;
    }

    const handleUpdateEntryStatus = async (entry: EventEntry): Promise<EventEntry | undefined> => {
        const eventEntryInput: UpdateEventEntryInput = {
            id: entry.id,
            note: note,
            status: event?.eventOptions?.autoAcceptNewEntries ? "complete" : "submitted",
        };
        const updateResult = await updateEventEntry(eventEntryInput);
        if (updateResult.isSuccess) {
            const updatedEntry = updateResult.result;
            return updatedEntry;
        }
    }

    const handleUpdateEntries = async () => {
        let isSuccess = true;
        if (currentEntry) {
            setIsLoading(true);

            // Next, update entry info
            if (currentEntry.status !== "complete") {
                // First update any stabling requests
                const updateStablingResult = await handleUpdateStabling();
                if (updateStablingResult && !updateStablingResult.isSuccess) {
                    isSuccess = false;
                    setError(updateStablingResult.message);
                    setIsLoading(false);
                }

                const updateFeesResult = await handleUpdateFees(currentEntry);
                if (!updateFeesResult.isSuccess) {
                    isSuccess = false;
                    setError(updateFeesResult.message);
                    setIsLoading(false);
                }
                if (event?.eventOptions?.autoAcceptNewEntries) await updateEventClassEntriesStatus(currentEntry);
                const updateEntryStatusResult = await handleUpdateEntryStatus(currentEntry);
                if (updateEntryStatusResult) {
                    await sendEntryStatusSubmittedEmailWithInvoice(currentEntry, user.email, user.firstName || "");
                } else {
                    isSuccess = false;
                    setError("Could not update the entry status.");
                    setIsLoading(false);
                }
            }  else {
                setIsLoading(false);
            }
            if (isSuccess) {
                setIsLoading(false);
                await navigateToEntriesPage();
            }  else {
                setIsLoading(false);
            }
        } else {
            setError("Could not find any entries to submit.");
            setIsLoading(false);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        await handleUpdateEntries();
    }

    const navigateToEntriesPage = async () => {
        if (event) {
            const path = "/index/event/entries/" + event.id;
            history.push(path);
        } else {
            setError("Sorry, we could not load the new page.")
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {error && <ErrorBanner error={error} />}
                    <EntryFormHeader event={event} />
                    <hr />
                    <EntryFormNavbar active={activeTab} event={event} onSubmit={handleNavbarSelect}/>
                    {event && (
                        <div className="ion-padding-top">
                            {activeTab === "info" && (
                                <>
                                    <EntryCard event={event} entry={currentEntry} onSubmit={handleSubmitEntry}/>
                                </>
                            )}
                            {activeTab === "classes" && (
                                <>
                                    <p className="text-info ion-text-center">For your current entry: {entry?.horseName ? entry?.horseName + " - " : ""} {entry?.riderName}</p>
                                    {/* <EntryClassForm event={event} entry={currentEntry} key={currentEntry?.id} onSubmit={(updatedEntry: EventEntry) => setCurrentEntry(updatedEntry)} /> */}
                                    {currentEntry ?
                                        <EventClassAddToEntryForm entry={currentEntry} event={event} isQuickAdd={false} onUpdateEntry={(updated: EventEntry) => setCurrentEntry(updated)} />
                                        :
                                        <p>No Entry Found.</p>
                                    }
                                </>
                            )}
                            {activeTab === "stalls" && (
                                <>
                                    <p className="text-info ion-text-center">For your current entry: {entry?.horseName ? entry?.horseName + " - " : ""} {entry?.riderName}</p>
                                    <NewEventStablingForm event={event} entry={currentEntry} />
                                </>
                            )}
                            {activeTab === "fees" && (
                                <>
                                    <p className="text-info ion-text-center">For your current entry: {entry?.horseName ? entry?.horseName + " - " : ""} {entry?.riderName}</p>
                                    <EntryFeeForm event={event} entry={currentEntry} />
                                </>
                            )}
                            {activeTab === "rules" && (
                                <>
                                    <p className="text-info ion-text-center">For your current entry: {entry?.horseName ? entry?.horseName + " - " : ""} {entry?.riderName}</p>
                                    <EntryRuleForm event={event} entry={currentEntry} />
                                </>
                            )}
                            {activeTab === "submit" && (
                                <>
                                    <IonText className="ion-text-center">{event.type === "camp" ? "Registration" : "Entry"} Summary</IonText>
                                    {currentEntry ?
                                        <EntrySummaryCard event={event} entry={currentEntry} />
                                        :
                                        <p>Could not find entry information.</p>
                                    }
                                    <hr/>
                                    <IonText className="ion-text-center">Stabling Summary</IonText>
                                    <DisplayStablingInfo event={event} entry={currentEntry} />
                                    <hr />
                                    <IonRow>
                                        <IonCol>
                                            <IonLabel>Notes</IonLabel>
                                            {event.id === "579a142d-e1cc-4170-ae20-cbae8b58fb67" && <p>If showing in NWAHJA Scholarship Equitation or Open Hunter, please list what height you would like to be entered in.</p>}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <Input
                                                placeholder="Add notes for this entry."
                                                rows="5"
                                                type="textarea"
                                                name="note"
                                                value={note}
                                                spellCheck="true"
                                                onChange={(event) => setNote(event.target.value)}
                                                data-hj-whitelist
                                            />
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                        </div>
                    )}
                    <hr/>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="text-center" sizeMd="4">
                            {activeTab === "submit" ?
                                <IonButton
                                    className="ion-margin-top"
                                    color="success"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </IonButton>
                                :
                                <IonButton
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleNextPage}
                                >
                                    Next Step
                                </IonButton>
                            }
                            
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default CreateEntriesForm;