import React, { useEffect, useState } from "react";
import {creditBlockPricingStructure} from "../../data/content/CreditBlockPricing";
import { Event } from "../../models";
import { formatStartEndDatesStrings } from "../../utilities/dates/FormatDates";
import { getEventsByOrganizationId } from "../../utilities/events/Event";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    menuPlacement?: string
    selectedValue?: string
    organizationId: string
    onSelect: Function
}

const SelectOrganizationEvent: React.FC<_Props> = ({selectedValue, organizationId, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedEvents, setFormattedEvents] = useState<formattedOption[] | null | undefined>();

    const getEvents = async (organizationId: string) => {
        const queryResult = await getEventsByOrganizationId(organizationId);
        if (queryResult.isSuccess) {
            formatEvents(queryResult.result);
        }
    }

    const formatEvents = (events: Event[]) => {
        let formattedEvents = [];
        for (var i = 0; i < events.length; i++) {
            const value = events[i].id.toString();
            const label = events[i].name + (events[i].startDate ? (" (" + formatStartEndDatesStrings(events[i].startDate, events[i].endDate) + ")") : "");
            let object: formattedOption = {
                value: value,
                label: label,
                object: events[i]
            };
            formattedEvents.push(object);
        }
        setFormattedEvents(formattedEvents);
    }

    useEffect(() => {
        getEvents(organizationId);
    }, [organizationId]);

    useEffect(() => {
        if (selectedValue) setSelected(selectedValue)
    }, [selectedValue]);

    const handleOnChange = (event?: any) => {
        if (event) {
            onSelect(event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedEvents ?
                <BasicSelect formattedValues={formattedEvents} menuPlacement={menuPlacement} selectedValue={selected} onSelect={handleOnChange} />
                :
                <p>Loading options...</p>
            }
        </>
    )
};

export default SelectOrganizationEvent;
