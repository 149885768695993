import React, { useEffect, useState } from "react";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";
import { S3Object } from "../../models";

interface _Props {
    selectedVideo?: (S3Object | null)
    mediaList: ((S3Object | null)[] | null)
    onSelect: Function
}

const SelectVideo: React.FC<_Props> = ({mediaList, selectedVideo, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedVideos, setFormattedVideos] = useState<formattedOption[] | null | undefined>();

    const formatVideos = async (videos: (S3Object | null)[]) => {
        let formattedVideos = [];
        for (var i = 0; i < videos.length; i++) {
            const currentVideo = videos[i];
            if (currentVideo) {
                let object: formattedOption = {
                    value: currentVideo.key,
                    label: currentVideo.title || "unknown ring",
                    object: currentVideo
                };
                formattedVideos.push(object);
            }
        }
        setFormattedVideos(formattedVideos);
    }
    
    useEffect(() => {
        if (mediaList) formatVideos(mediaList);
    }, [mediaList]);

    useEffect(() => {
        if (selectedVideo && selectedVideo.key) setSelected(selectedVideo.key);
    }, [selectedVideo]);


    const handleOnChange = (event?: any) => {
        if (event && event) {
            onSelect(null, event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedVideos ?
                <BasicSelect formattedValues={formattedVideos} selectedValue={selected} onSelect={handleOnChange}/>
                :
                <p></p>
            }
        </>
    );
};

export default SelectVideo;
