import { API } from 'aws-amplify';
import { Result } from "../../interfaces/Result";

/**
 * Functionality to create a new Stripe Customer.
 * 
 * Needed if you want to save a payment method. Additionally, creates a customer for organizations in order to save PMs.
 * 
 * More details here: https://stripe.com/docs/api/customers/create
 * 
 * @param {string} name 
 * @param {string} email 
 * @param {string} personId 
 * @param {string} organizationId 
 */
 export async function createStripeCustomer(name: string, email: string, personId?: string, organizationId?: string) {
    try {
        let queryParams = "?name=" + name;
        if (email) queryParams = queryParams + "&email=" + email;
        if (personId) queryParams = queryParams + "&personid=" + personId;
        if (organizationId) queryParams = queryParams + "&organizationid=" + organizationId;

        const path = "/payments/create-customer" + queryParams;
        
        const response = await API.get("Payments", path, {});

        const responseBody = JSON.parse(response.body);
        const customer = responseBody.customer;

        if (customer) {
            let result: Result = {isSuccess: true, type: "Stripe", result: customer, message: "Successfully created the customer."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "Stripe", result: customer, message: "Could not create the customer."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Could not create the customer."};
        return result; 
    }
}