import { Result } from "../../interfaces/Result";
import { BeddingRequest, EventClassEntry, EventEntry, EventEntryFee, StablingRequest } from "../../models";
import { deleteBeddingRequest, getBeddingRequestsByEntryId } from "../beddingRequest/BeddingRequest";
import { deleteEventClassEntry, getEventClassEntriesByEventIdEntryId } from "../eventClassEntry/EventClassEntry";
import { deleteEventEntryFee, getEventEntryFeesByEventIdByEntryId } from "../eventEntryFee/EventEntryFee";
import { deleteEventResult, getEventResultsByEventIdEntryId } from "../eventResult/EventResult";
import { deleteEventRulesAgreement, getEventRulesAgreementsByEntryId } from "../eventRules/EventRulesAgreement";
import { deleteStablingRequest, getStablingRequestsByEventId } from "../stablingRequest/StablingRequest";
import { deleteEventEntry } from "./EventEntry";

export async function removeEventEntryData(eventEntry: EventEntry) {
    let errors: string[] = [];

    if (eventEntry && eventEntry.eventId) {
        const eventId = eventEntry.eventId;

        const classEntryResults = await getEventClassEntriesByEventIdEntryId(eventId, eventEntry.id);
        if (classEntryResults.isSuccess) {
            const classEntries: EventClassEntry[] = classEntryResults.result;
            if (classEntries && classEntries.length > 0) {
                for (let i = 0; i < classEntries.length; i++) {
                    const currentClassEntry = classEntries[i];
                    if (currentClassEntry) {
                        const deleteResult = await deleteEventClassEntry({id: currentClassEntry.id});
                        if (!deleteResult.isSuccess) {
                            const message = "Could not delete the class entry: " + currentClassEntry.eventClass?.number + ": " + currentClassEntry.eventClass?.name;
                            errors.push(message);
                        }
                    }
                }
            }
        }

        const eventEntryFeeResults = await getEventEntryFeesByEventIdByEntryId(eventId, eventEntry.id);
        if (eventEntryFeeResults.isSuccess) {
            const eventEntryFees: EventEntryFee[] = eventEntryFeeResults.result;
            if (eventEntryFees && eventEntryFees.length > 0) {
                for (let i = 0; i < eventEntryFees.length; i++) {
                    const currentEventEntryFee = eventEntryFees[i];
                    if (currentEventEntryFee) {
                        const deleteResult = await deleteEventEntryFee({id: currentEventEntryFee.id});
                        if (!deleteResult.isSuccess) {
                            const message = "Could not delete the fee: " + currentEventEntryFee.name;
                            errors.push(message);
                        }
                    }
                }
            }
        }

        const eventStablingResult = await getStablingRequestsByEventId(eventId);
        if (eventStablingResult.isSuccess) {
            const allStablingRequests = eventStablingResult.result;
            allStablingRequests.forEach(async (sr: StablingRequest) => {
                const currentEntries = sr.entryIds;
                if (currentEntries && currentEntries.length) {
                    currentEntries.forEach(async (currentEntry) => {
                        if (currentEntry === eventEntry.id) {
                            const deleteStablingRequestResult = await deleteStablingRequest({id: sr.id});
                            if (!deleteStablingRequestResult.isSuccess) {
                                const message = "Could not delete the stabling request: " + sr.id;
                                errors.push(message);
                            }
                        }
                    });
                }
            });
        }

        const eventBeddingResult = await getBeddingRequestsByEntryId(eventEntry.id);
        if (eventBeddingResult.isSuccess) {
            const allBeddingRequests = eventBeddingResult.result;
            allBeddingRequests.forEach(async (br: BeddingRequest) => {
                await deleteBeddingRequest({id: br.id});
            });
        }

        const eventRulesAggreementsResults = await getEventRulesAgreementsByEntryId(eventEntry.id);
        if (eventRulesAggreementsResults.isSuccess) {
            const rulesAggreements = eventStablingResult.result;
            if (rulesAggreements && rulesAggreements.length > 0) {
                for (let i = 0; i < rulesAggreements.length; i++) {
                    const currentRuleAggreement = rulesAggreements[i];
                    if (currentRuleAggreement) {
                        const deleteResult = await deleteEventRulesAgreement({id: currentRuleAggreement.id});
                        if (!deleteResult.isSuccess) {
                            const message = "Could not delete the rule aggreement: " + currentRuleAggreement.name;
                            errors.push(message);
                        }
                    }
                }
            }
        }

        const eventResultsResult = await getEventResultsByEventIdEntryId(eventId, eventEntry.id);
        if (eventResultsResult.isSuccess) {
            const eventResults = eventStablingResult.result;
            if (eventResults && eventResults.length > 0) {
                for (let i = 0; i < eventResults.length; i++) {
                    const currentResult = eventResults[i];
                    if (currentResult) {
                        const deleteResult = await deleteEventResult({id: currentResult.id});
                        if (!deleteResult.isSuccess) {
                            const message = "Could not delete the event result: " + currentResult.id;
                            errors.push(message);
                        }
                    }
                }
            }
        }
        // If there were no errors, delete the entry
        if (errors.length === 0){
            const deleteResult = await deleteEventEntry({id: eventEntry?.id});
            if (deleteResult.isSuccess) {
                const result: Result = {isSuccess: true, type: "EventEntry", result: deleteResult.result, message: "Successfully deleted the event entry."};
                return result;
            } else {
                return deleteResult;
            }
        } else {
            const result: Result = {isSuccess: false, type: "EventEntry", result: errors, message: "Could not delete the event entry."};
            return result;
        }
    } else {
        const result: Result = {isSuccess: false, type: "EventEntry", result: "No entry found.", message: "Could not delete the event entry."};
        return result;
    }
}