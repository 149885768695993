import {
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React from "react";
import ClinicsGuide from "../../../../assets/img/general/ClinicianGuide.pdf";
import ClinicianOptionsList from "../../../../components/Clinician/ClinicianOptionsList";

const ClinicianPage: React.FC = () => {
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Clinics" />
                <IonRow id="clinics" className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="8">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardContent className="ion-text-center">
                                <h2>Online Clinics</h2>
                                <p>Licensed officials can sign up to review riding videos. <a href={ClinicsGuide} rel="noopener noreferrer"  target="_blank"><span className="link">Learn more.</span></a></p>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-text-center ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="8">
                        <ClinicianOptionsList />
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default ClinicianPage;