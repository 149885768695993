import moment from "moment";
import { Result } from "../../interfaces/Result";
import { USEFSection } from "../../interfaces/USEFData";

// Import Basic USEF Values
import { apiURL, createUSEFSignature } from "./USEFValues";

// Use the current year to get the valid USEF sections (what RingSide Pro calls divisions)
function createURLtoGetSections() {
    const year = moment(new Date()).format("YYYY");
    return apiURL + "sections/" + year;
}

/**
 * Using documentation here: https://api.usef.org/documentation/cs/generic-data-calls/sections
 * You must be logged in to the Competition Software USEF Account to access this site
 * 
 * @returns Result object with section array or null
 */
export async function getUSEFSections(): Promise<Result> {
    try {
        // Create the needed URL for this API call
        const currentAPIURL = createURLtoGetSections();

        // Use a GET request
        const response = await fetch(currentAPIURL, {
            method: "GET",
            headers: {
                'Authorization': createUSEFSignature(),
                'Content-Type': "application/json"
            }
        });

        console.log("response: ",response);

        // Handle the response
        if (response) {
            const myJson = await response.json();
            console.log("myJson: ",myJson);
            if (myJson.sections) {
                const sections: USEFSection[] = myJson.sections;
                let result: Result = {isSuccess: true, type: "USEFSearch", result: sections, message: "We found the USEF sections."};
                return result;
            } else {
                let result: Result = {isSuccess: false, type: "USEFSearch", result: null, message: "We could not find any USEF sections. Please contact hello@ringsidepro.com."};
                return result;
            }
        } else {
            let result: Result = {isSuccess: false, type: "USEFSearch", result: null, message: "An error occurred. We could not find any USEF sections. Please contact hello@ringsidepro.com."};
            return result;
        }
    } catch (error) {
        console.error("Caught an error: ", error);
        let result: Result = {isSuccess: false, type: "USEFSearch", result: null, message: "An error was caught. We could not find any USEF sections. Please contact hello@ringsidepro.com."};
        return result;
    }
}