import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateScheduleItemInput, CreateScheduleItemMutation, DeleteScheduleItemInput, DeleteScheduleItemMutation, GetScheduleItemQuery, ListScheduleItemsQuery, UpdateScheduleItemInput, UpdateScheduleItemMutation } from "../../API";
import moment from "moment";
import { sortScheduleItemsByDisplayOrder } from "./SortScheduleItem";
import { ScheduleItem } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: scheduleItem. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created scheduleItem.
*/
export async function createScheduleItem(input: CreateScheduleItemInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "ScheduleItem", "No input", "Create ScheduleItem received no input.");
   try {
        const fullInput: CreateScheduleItemInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createScheduleItem, { input: fullInput }))) as GraphQLResult<CreateScheduleItemMutation>;
       else result = (await API.graphql({
           query: mutations.createScheduleItem,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateScheduleItemMutation>;
       const scheduleItem = result.data?.createScheduleItem;
       return formatResult(true, "ScheduleItem", scheduleItem, "Successfully created the scheduleItem.");
   } catch (error: any) {
       return formatResult(false, "ScheduleItem", error, "Error creating record in the database for type: scheduleItem");
   }
}

/**
* Handle updating a new record in the database for type: scheduleItem. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated scheduleItem.
*/
export async function updateScheduleItem(input: UpdateScheduleItemInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "ScheduleItem", "No input", "Update ScheduleItem received no input.");
   try {
        const fullInput: UpdateScheduleItemInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateScheduleItem, { input: fullInput }))) as GraphQLResult<UpdateScheduleItemMutation>;
       else result = (await API.graphql({
           query: mutations.updateScheduleItem,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateScheduleItemMutation>;
       const scheduleItem = result.data?.updateScheduleItem;
       return formatResult(true, "ScheduleItem", scheduleItem, "Successfully updated the scheduleItem.");
   } catch (error: any) {
       return formatResult(false, "ScheduleItem", error, "Error updating record in the database for type: scheduleItem");
   }
}

/**
* Handle deleting a new record in the database for type: scheduleItem. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted scheduleItem.
*/
export async function deleteScheduleItem(input: DeleteScheduleItemInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "ScheduleItem", "No input", "Delete ScheduleItem received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteScheduleItem, { input: input }))) as GraphQLResult<DeleteScheduleItemMutation>;
       else result = (await API.graphql({
           query: mutations.deleteScheduleItem,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteScheduleItemMutation>;
       const scheduleItem = result.data?.deleteScheduleItem;
       return formatResult(true, "ScheduleItem", scheduleItem, "Successfully deleted the scheduleItem.");
   } catch (error: any) {
       return formatResult(false, "ScheduleItem", error, "Error deleting record in the database for type: scheduleItem");
   }
}

/**
* Get all records in the database for type: scheduleItem. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the scheduleItem object
*/
export async function getAllScheduleItems(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listScheduleItems,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListScheduleItemsQuery>;
        
        let items = result.data?.listScheduleItems?.items as ScheduleItem[];
        let nextToken = result.data?.listScheduleItems?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listScheduleItems,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListScheduleItemsQuery>;

            const nextItems = nextResult.data?.listScheduleItems?.items as ScheduleItem[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listScheduleItems?.nextToken;
        }

        return formatResult(true, "ScheduleItem", items, "Successfully got the ScheduleItems.");
    } catch (error: any) {
        return formatResult(false, "ScheduleItem", error, "Error reading record in the database for type: scheduleItems");
    }
}

/**
* Read a specific record in the database for type: scheduleItem. 
* 
* @param {string}  id                  The scheduleItem id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the scheduleItem object
*/
export async function getScheduleItemById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getScheduleItem,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetScheduleItemQuery>;
       const scheduleItem = result.data?.getScheduleItem;
       return formatResult(true, "ScheduleItem", scheduleItem, "Successfully got the scheduleItem.");
   } catch (error: any) {
       return formatResult(false, "ScheduleItem", error, "Error reading record in the database for type: scheduleItem");
   }
}

/**
* Get all records in the database that match the given criteria for type: scheduleItem. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the scheduleItem object
*/
export async function getScheduleItemsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.scheduleItemsByEventId,
            variables: {
                limit: 1000,
                eventId: eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.scheduleItemsByEventId?.items as ScheduleItem[];
        let nextToken = result.data?.scheduleItemsByEventId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.scheduleItemsByEventId,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.scheduleItemsByEventId?.items as ScheduleItem[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.scheduleItemsByEventId?.nextToken;
        }

        return formatResult(true, "ScheduleItem", items, "Successfully got the ScheduleItems.");
    } catch (error: any) {
        return formatResult(false, "ScheduleItem", error, "Error reading record in the database for type: scheduleItems");
    }
}

/**
* Get all records in the database that match the given criteria for type: scheduleItem. 
* 
* @param {string}  eventId             The event id
* @param {string}  eventDayId          The event day id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the scheduleItem object
*/
export async function getScheduleItemsByEventIdByDay(eventId: string, eventDayId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}, eventDayId: {eq: eventDayId}};
        const result = (await API.graphql({
            query: queries.listScheduleItems,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListScheduleItemsQuery>;
        
        let items = result.data?.listScheduleItems?.items as ScheduleItem[];
        let nextToken = result.data?.listScheduleItems?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listScheduleItems,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListScheduleItemsQuery>;

            const nextItems = nextResult.data?.listScheduleItems?.items as ScheduleItem[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listScheduleItems?.nextToken;
        }

        return formatResult(true, "ScheduleItem", items, "Successfully got the ScheduleItems.");
    } catch (error: any) {
        return formatResult(false, "ScheduleItem", error, "Error reading record in the database for type: scheduleItems");
    }
}

/**
* Get all records in the database that match the given criteria for type: scheduleItem. 
* 
* @param {string}  eventId             The event id
* @param {string}  eventDayId          The event day id 
* @param {string}  eventRingId         The event ring id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the scheduleItem object
*/
export async function getScheduleItemsByEventIdByDayByRing(eventId: string, eventDayId: string, eventRingId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}, eventDayId: {eq: eventDayId}, eventRingId: {eq: eventRingId}};
        const result = (await API.graphql({
            query: queries.listScheduleItems,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListScheduleItemsQuery>;
        
        let items = result.data?.listScheduleItems?.items as ScheduleItem[];
        let nextToken = result.data?.listScheduleItems?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listScheduleItems,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListScheduleItemsQuery>;

            const nextItems = nextResult.data?.listScheduleItems?.items as ScheduleItem[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listScheduleItems?.nextToken;
        }
        
        const sorted = sortScheduleItemsByDisplayOrder(items);
        if (sorted) {
            return formatResult(true, "ScheduleItem", sorted, "Successfully got the scheduleItems.");
        } else {
            return formatResult(true, "ScheduleItem", items, "Successfully got the scheduleItems.");
        }
    } catch (error: any) {
        return formatResult(false, "ScheduleItem", error, "Error reading record in the database for type: scheduleItems");
    }
}