import React, { useEffect, useState } from "react";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    menuPlacement?: (string | null)
    selectedValue?: (string | null)
    onSelect: Function
}

const options: formattedOption[] = [
    {
        label: "Submitted (waiting to be accepted)",
        value: "submitted",
        object: "submitted"
    },
    {
        label: "Accepted (will show on class sheets)",
        value: "accepted",
        object: "accepted"
    },
    {
        label: "Scratched (removed from class sheet)",
        value: "scratched",
        object: "scratched"
    }
];

const SelectEventClassEntryStatus: React.FC<_Props> = ({selectedValue, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | null | undefined>();

    useEffect(() => {
        setSelected(selectedValue);
    }, [selectedValue]);

    const handleOnChange = (event?: any) => {
        if (event) {
            onSelect(event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            <BasicSelect formattedValues={options} selectedValue={selected} menuPlacement={menuPlacement} onSelect={handleOnChange}/>
        </>
    );
};

export default SelectEventClassEntryStatus;
