import "./../../theme/main.css";
import {
    IonButton,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, {useEffect, useState} from "react";

interface _Props {
    buttons : string[],
    selectedButtons?: string[],
    onSelect : Function
}

interface FormattedButton {
    id: number
    text: string
    isSelected: boolean
}

const initialButtons: FormattedButton[] = [];

const SelectAllButtons: React.FC<_Props> = ({buttons, selectedButtons, onSelect}) => {
    const [displayButtons, setDisplayButtons] = useState(initialButtons);

    useEffect(() => {
        const formatButtons = () => {
            const formattedButtons: FormattedButton[] = [];
            for (let i = 0; i < buttons.length; i++) {
                formattedButtons.push({
                    id: i,
                    text: buttons[i] as string,
                    isSelected: (selectedButtons ? selectedButtons.includes(buttons[i]) : false)
                });
            }
            setDisplayButtons(formattedButtons);
        };
        formatButtons();
    }, [buttons, selectedButtons]);

    const handleSelect = (buttonId: number) => {
        const selectedButton = displayButtons[buttonId];
        selectedButton.isSelected = !selectedButton.isSelected;
        const updatedButtons = [
            ...displayButtons.slice(0, buttonId),
            selectedButton,
            ...displayButtons.slice(buttonId + 1)
        ];
        setDisplayButtons(updatedButtons);
        handleOnSelect();
    };

    const handleOnSelect = () => {
        const selectedItems: string[] = [];
        for (let i = 0; i < displayButtons.length; i++) {
            if (displayButtons[i].isSelected) selectedItems.push(displayButtons[i].text);
        }
        onSelect(selectedItems);
    };

    return (
        <IonRow>
            {displayButtons.map((button, index) => (
                <IonCol key={index} className="ion-text-center" size="4">
                    <IonButton id={button.text.replace(/\s+/g, '')} expand="full" className="ion-text-wrap" color={button.isSelected ? "primary" : "light"} onClick={() => handleSelect(button.id)}>{button.text}</IonButton>
                </IonCol>
            ))}
        </IonRow>
    );
};

export default SelectAllButtons;
