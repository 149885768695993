import {
    IonButton,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Horse } from "../../../models";
import { updateHorse } from "../../../utilities/horse/Horse";
import ErrorAlert from "../../Errors/ErrorAlert";
import { UpdateHorseInput } from "../../../API";
import SuccessBanner from "../../Banners/SuccessBanner";
import { Input, Label } from "reactstrap";
import BasicTooltip from "../../Tooltip/BasicTooltip";

interface _Props {
    horse: Horse
}

const EditHorseColorForm: React.FC<_Props> = ({horse}) => {    
    const [color, setColor] = useState("-");
    const [markings, setMarkings] = useState("");
    
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const clearForm = () => {
        setColor("-");
        setMarkings("");
    }

    useEffect(() => {
        if (horse) {
            setColor(horse.color || "-");
            setMarkings(horse.markings || "");
        } else {
            clearForm();
        }
    }, [horse]);

    const handleEditHorse = async () => {
        const input: UpdateHorseInput = {
            id: horse.id,
            color,
            markings
        };
        const createResult = await updateHorse(input);
        if (createResult.isSuccess) {
            setError("");
            setSuccess("Successfully saved the updates.");
        } else {
            setSuccess("");
            setError(createResult.message);
        }
    }

    return (
        <>
            {success && <SuccessBanner success={success} />}
            {error && <ErrorAlert error={error} />}
            <IonRow>
                <IonCol size="12">
                    <Label for="colorSelect">Color</Label>
                    <Input 
                        type="select" 
                        name="select"
                        value={color}
                        id="colorSelect"
                        onChange={e => setColor(e.target.value)}
                    >
                        <option>-</option>
                        <option>Albino</option>
                        <option>Bay</option>
                        <option>Black</option>
                        <option>Brown</option>
                        <option>Buckskin</option>
                        <option>Chestnut</option>
                        <option>Cremello</option>
                        <option>Dun</option>
                        <option>Grey</option>
                        <option>Paint</option>
                        <option>Palomino</option>
                        <option>Pinto</option>
                        <option>Roan</option>
                        <option>Sorrel</option>
                        <option>White</option>
                        <option>Other</option>
                    </Input>
                </IonCol>
                <IonCol size="12">
                    <Label for="colorSelect"><BasicTooltip label="Markings" tip="Please describe markings and other distinguishing characteristics, including tattoos and brands. If no markings, type 'None'." /> </Label>
                    <Input 
                        type="textarea" 
                        name="text"
                        value={markings}
                        onChange={e => setMarkings(e.target.value)}
                    />
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton
                        className="ion-margin-top"
                        color="tertiary"
                        expand="block"
                        onClick={handleEditHorse}
                    >
                        Save
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default EditHorseColorForm;