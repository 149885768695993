import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Contact, Event, EventEntry, Person, Rider, Trainer} from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import { Table } from "reactstrap";
import { getEventEntriesByEventId } from "../../../../../utilities/eventEntry/EventEntry";
import Spinner from "../../../../../components/Spinners/Spinner";
import { getRiderById } from "../../../../../utilities/rider/Rider";
import { sortEventEntries } from "../../../../../utilities/eventEntry/SortEventEntry";
import { getTrainerById } from "../../../../../utilities/trainer/Trainer";
import { getPersonByPersonId } from "../../../../../utilities/person/Person";
import { generateAllEntriesContactReport } from "../../../../../utilities/reports/AllEntriesReport";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

export interface FormattedEntryContactData {
    entryId: string
    status: string
    backNumber: string
    horseName: string
    primaryContactName: string
    primaryContactPhone: string
    primaryContactEmail: string
    riderName: string
    riderPhone: string
    riderEmail: string
    secondRiderName: string
    secondRiderPhone: string
    secondRiderEmail: string
    trainerName: string
    trainerPhone: string
    trainerEmail: string
    createdByName: string
    createdByEmail: string
}

const EventParticipantsContactListPage: React.FC<EventPageProps> = ({match}) => {

    const [event, setEvent] = useState<Event>();
    const [formattedEntries, setFormattedEntries] = useState<FormattedEntryContactData[] | null | undefined>();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function formatData(entryList: EventEntry[]) {
            let formattedData: FormattedEntryContactData[] = [];
    
            for (let i = 0; i < entryList.length; i++) {
                const entry = entryList[i];
                
                let riderContact: (Contact | null | undefined) = undefined;
                if (entry.riderId) {
                    const riderQuery = await getRiderById(entry.riderId);
                    if (riderQuery.isSuccess) {
                        const rider: Rider = riderQuery.result
                        if (rider) riderContact = rider.contact;
                    }
                }
    
                let secondRiderContact: (Contact | null | undefined) = undefined;
                if (entry.secondRiderId) {
                    const riderQuery = await getRiderById(entry.secondRiderId);
                    if (riderQuery.isSuccess) {
                        const rider: Rider = riderQuery.result
                        if (rider) secondRiderContact = rider.contact;
                    }
                }
    
                let trainerContact: (Contact | null | undefined) = undefined;
                if (entry.trainerId) {
                    const trainerQuery = await getTrainerById(entry.trainerId);
                    if (trainerQuery.isSuccess) {
                        const trainer: Trainer = trainerQuery.result
                        if (trainer) trainerContact = trainer.contact;
                    }
                }
    
                let createdByName = "";
                let createdByEmail = "";
                if (entry.createdBy) {
                    const personQuery = await getPersonByPersonId(entry.createdBy);
                    if (personQuery.isSuccess) {
                        const person: Person = personQuery.result
                        if (person) {
                            createdByName = person.firstName + " " + person.lastName;
                            createdByEmail = person.email;
                        }
                    }
                }
    
                const data: FormattedEntryContactData = {
                    entryId: entry.id,
                    status: entry.status === "declined" ? "declined" : (entry.status === "complete" ? "accepted" : (entry.status === "in_progress" ? "in progress" : "submitted")),
                    backNumber: entry.number ? entry.number.toString() : "",
                    horseName: entry.horse?.name || entry.horseName || "",
                    primaryContactName: entry.primarycontact?.name || "",
                    primaryContactPhone: entry.primarycontact?.cell || entry.primarycontact?.home || entry.primarycontact?.work || "",
                    primaryContactEmail: entry.primarycontact?.personalEmail || entry.primarycontact?.workEmail || "",
                    riderName: entry.rider?.name || "",
                    riderPhone: riderContact?.cell || riderContact?.home || riderContact?.work || "",
                    riderEmail: riderContact?.personalEmail || riderContact?.workEmail || "",
                    secondRiderName: entry.secondRider?.name || "",
                    secondRiderPhone: secondRiderContact?.cell || secondRiderContact?.home || secondRiderContact?.work || "",
                    secondRiderEmail: secondRiderContact?.personalEmail || secondRiderContact?.workEmail || "",
                    trainerName: entry.trainer?.name || "",
                    trainerPhone: trainerContact?.cell || trainerContact?.home || trainerContact?.work || "",
                    trainerEmail: trainerContact?.personalEmail || trainerContact?.workEmail || "",
                    createdByName: createdByName,
                    createdByEmail: createdByEmail
                };
    
                formattedData.push(data);
            }
    
            setFormattedEntries(formattedData);
        }

        async function getEventEntries(id: string) {
            const queryResult = await getEventEntriesByEventId(id);
            if (queryResult.isSuccess) {
                const entryList = queryResult.result;
                const sorted = sortEventEntries(entryList);
                await formatData(sorted || entryList);
            }
        }
        
        async function getEvent(id: string) {
            setError("");
            setIsLoading(true);
            const queryResult = await getEventById(id);
            if (queryResult.isSuccess) {
                const e: Event = queryResult.result;
                setEvent(e);
                await getEventEntries(e.id);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
            setIsLoading(false);
        }

        if (match.params.id) getEvent(match.params.id);
        else setError("Sorry, we could not find the event");
    }, [match, match.params.id]);

    function handleDownloadContactList() {
        setIsLoading(true);
        if (formattedEntries) generateAllEntriesContactReport(formattedEntries);
        setIsLoading(false);
    }
    
    return (
        <IonPage className="bg-light">
            <Header isEventPage={event ? true : false} event={event ? event : undefined} />
            <IonContent>
                <PageTitle title={event ? event.name : "Contact List"} />
                {error && <ErrorAlert width="12" error={error} />}
                <IonRow>
                    <IonCol>
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>Contact List</IonCardTitle>
                            {isLoading ?
                                <Spinner />
                                :
                                <IonCardContent>
                                    {(formattedEntries && formattedEntries.length > 0) ?
                                        <>
                                            <IonRow>
                                                <IonCol>
                                                    <p className="link" onClick={handleDownloadContactList}>Download</p>
                                                </IonCol>
                                            </IonRow>
                                            <Table hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Status</th>
                                                        <th>Back Number</th>
                                                        <th>Horse Name</th>
                                                        <th>Primary Contact Name</th>
                                                        <th>Primary Contact Phone Number</th>
                                                        <th>Primary Contact Email</th>
                                                        <th>Rider Name</th>
                                                        <th>Rider Phone Number</th>
                                                        <th>Rider Email</th>
                                                        <th>Second Rider Name</th>
                                                        <th>Second Rider Phone Number</th>
                                                        <th>Second Rider Email</th>
                                                        <th>Trainer Name</th>
                                                        <th>Trainer Phone Number</th>
                                                        <th>Trainer Email</th>
                                                        <th>Created By</th>
                                                        <th>Creator Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {formattedEntries.map((entry, index) => (
                                                        <tr key={index}>
                                                            <td className={(entry.status === "declined" ? "text-danger" : (entry.status === "accepted" ? "text-success" : (entry.status === "in progress" ? "text-warning" : "text-primary")))}>
                                                                {entry.status} 
                                                            </td>
                                                            <td>{entry.backNumber}</td>
                                                            <td>{entry.horseName}</td>
                                                            <td>{entry.primaryContactName}</td>
                                                            <td>{entry.primaryContactPhone}</td>
                                                            <td>{entry.primaryContactEmail}</td>
                                                            <td>{entry.riderName}</td>
                                                            <td>{entry.riderPhone}</td>
                                                            <td>{entry.riderEmail}</td>
                                                            <td>{entry.secondRiderName}</td>
                                                            <td>{entry.secondRiderPhone}</td>
                                                            <td>{entry.secondRiderEmail}</td>
                                                            <td>{entry.trainerName}</td>
                                                            <td>{entry.trainerPhone}</td>
                                                            <td>{entry.trainerEmail}</td>
                                                            <td>{entry.createdByName}</td>
                                                            <td>{entry.createdByEmail}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </>
                                        :
                                        <p>No entries found.</p>
                                    }
                                </IonCardContent>
                            }
                            
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EventParticipantsContactListPage;