import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRadio,
    IonReorder,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Membership, Organization, Person, Team, TeamMember } from "../../models";
import { Table } from "reactstrap";
import { calculateHeightInHands } from "../../utilities/horse/Height";
import { getTeamMembersByTeamId } from "../../utilities/teamMember/TeamMember";
import { sortTeamMembersByPersonName } from "../../utilities/teamMember/SortTeamMembers";
import { getMembershipsByPersonIdByOrganizationId } from "../../utilities/membership/Membership";
import { formatDisplayName } from "../../utilities/person/PersonNameFormat";
import moment from "moment";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { isWindows } from "../../utilities/platform/Platform";
import { getOrganizationById } from "../../utilities/organization/Organization";
import { getUser } from "../../utilities/user/User";
import { getGroupsForUser } from "../../utilities/adminQueries/userGroups";
import { findOrgInGroups } from "../../utilities/organization/OrganizationGroup";
import { PersonContext } from "../../context/PersonContext";
import { generateTeamRosterReport } from "../../utilities/reports/TeamRosterReport";

interface _Props {
    team: Team
    onSelect: Function
}

interface FormattedRosterRow {
    teamMemberId: string
    teamMemberName: string
    armNumber: string
    division: string
    gradeLevel: string
    status: string
    dateCreated: string
}

const ViewTeamMembersRosterTable: React.FC<_Props> = ({team, onSelect}) => {
    const user = useContext(PersonContext);

    const [currentUserRoles, setCurrentUserRoles] = useState<("admin" | "coach" | "creator" | "member")[]>([]);
    const [teamMembers, setTeamMembers] = useState<TeamMember[] | null | undefined>();
    const [formattedRows, setFormattedRows] = useState<FormattedRosterRow[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [error, setError] = useState("");

    const getTeamMembersOnRoster = async (teamMemberList: TeamMember[]) => {
        let teamMembersOnRoster: FormattedRosterRow[] = [];
        for (let i = 0; i < teamMemberList.length; i++) {
            const currentTeamMember = teamMemberList[i];
            // Get the membership for this team member (team functionality is currently just for AEL organization)
            const membershipResult = await getMembershipsByPersonIdByOrganizationId(currentTeamMember.personId, "e05fc919-2793-4ead-acef-3f6efb584b67");
            if (membershipResult.isSuccess) {
                const activeMembershipsForPersonForTeam: Membership[] = membershipResult.result;
                if (activeMembershipsForPersonForTeam && activeMembershipsForPersonForTeam.length > 0) {
                    for (let j = 0; j < activeMembershipsForPersonForTeam.length; j++) {
                        const currentMembership = activeMembershipsForPersonForTeam[j];
                        
                        // On the Team Roster, do not include coach
                        if (!currentMembership.organizationMembershipType?.name.toLowerCase().includes("coach") && !currentMembership.organizationMembershipType?.name.toLowerCase().includes("team")) {
                            const formattedRow: FormattedRosterRow = {
                                teamMemberId: currentTeamMember.id,
                                teamMemberName: formatDisplayName((currentTeamMember.person?.firstName || ""), "", (currentTeamMember.person?.lastName || "")),
                                armNumber: currentMembership.backNumber ? currentMembership.backNumber.toString() : "",
                                division: currentMembership.aelDivision || "",
                                gradeLevel: currentMembership.gradeLevel || "",
                                status: currentTeamMember.status || "",
                                dateCreated: currentTeamMember.createdOn ? moment(currentTeamMember.createdOn).format("MM/DD/YYYY") : ""
                            };
    
                            teamMembersOnRoster.push(formattedRow);
                        }
                    }
                }
            }
        }
        return teamMembersOnRoster;
    }

    async function getTeamMembers() {
        if (team) {
            setIsLoading(true);
            const queryResult = await getTeamMembersByTeamId(team.id);
            if (queryResult.isSuccess) {
                const teamMembers: TeamMember[] = queryResult.result;
                setTeamMembers(teamMembers);
                const sorted = sortTeamMembersByPersonName(teamMembers);
                const formattedRows: FormattedRosterRow[] = await getTeamMembersOnRoster(sorted || teamMembers);
                setFormattedRows(formattedRows);
            } else {
                const message = "An error occurred. " + queryResult.message;
                setError(message);
            }
            setIsLoading(false);
        } else {
            setError("No team was found.");
        }
    }

    const checkUser = async (teamId: string, user: Person) => {
        // Try to find the organization
        let currentOrganization: Organization | undefined | null = null;
        const organizationQueryResult = await getOrganizationById("e05fc919-2793-4ead-acef-3f6efb584b67");
        if (organizationQueryResult.isSuccess) {
            currentOrganization = organizationQueryResult.result;
        }

        // Check if the user is an organization admin - can edit any fields
        if (currentOrganization) {
            const userIsOrgAdmin = await checkIfUserIsOrganizationAdmin(currentOrganization);
            if (userIsOrgAdmin) setCurrentUserRoles(prevValue => prevValue.concat("admin"));
        }

        // Check if the user is a coach role
        const queryResult = await getTeamMembersByTeamId(teamId);
        if (queryResult.isSuccess) {
            const teamMembers: TeamMember[] = queryResult.result;
            if (teamMembers) {
                const foundCoach = teamMembers.find(teamMember => teamMember.personId === user.id && (teamMember.role?.toLowerCase().includes("coach") || teamMember.permissionLevel === "admin"));
                if (foundCoach) setCurrentUserRoles(prevValue => prevValue.concat("coach"));
            }
        }
    }

    async function checkIfUserIsOrganizationAdmin(org: Organization) {
        const userResult = await getUser();
        if (userResult && userResult.username) {
            // All users should belong to at least 1 group (rsp-users)
            const result = await getGroupsForUser(userResult.username);
            const groupsArray = result.Groups;
            if (groupsArray && groupsArray.length > 0) {
                // This user also belongs to at least 1 org group
                const searchResult = findOrgInGroups(org, groupsArray);
                if (searchResult.isSuccess) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    useEffect(() => {
        getTeamMembers();

        checkUser(team.id, user);
    }, [team]);

    const onSelectTeamMember = (row: FormattedRosterRow) => {
        onSelect(row.teamMemberId);
    }

    const handleDownloadRoster = async () => {
        setIsDownloading(true);
        if (teamMembers) {
            await generateTeamRosterReport(teamMembers);
        } else {
            setError("A problem occurred. No team members were found.")
        }
        setIsDownloading(false);
    }

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="4">
                        Roster
                    </IonCol>
                </IonRow>
            </IonCardTitle>
            {error && <ErrorAlert error={error} />}
            <IonCardSubtitle>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="6">
                        <p className="ion-text-wrap">Click on a row to view more about the team member.</p>
                    </IonCol>
                    {(currentUserRoles?.includes("admin") || currentUserRoles?.includes("coach")) && (
                        <IonCol sizeXs="12" sizeMd="6">
                            {isDownloading ?
                                <Spinner />
                                :
                                <p className="ion-text-wrap link" onClick={handleDownloadRoster}>Download Roster</p>
                            }
                        </IonCol>
                    )}
                </IonRow>
                <IonRow>
                    <IonCol>
                        Count: {formattedRows ? formattedRows.length : 0}
                    </IonCol>
                </IonRow>
            </IonCardSubtitle>
            {isLoading ?
                <Spinner />
                :
                <IonCardContent>
                    {((!isWindows()) && isPlatform("mobile")) ?
                        <IonList className="bg-white">
                            {(formattedRows && formattedRows.length > 0) && (
                                <>
                                    {formattedRows.map((formattedRow, index) => (
                                        <IonItem key={index} onClick={() => onSelectTeamMember(formattedRow)}>
                                            <IonLabel>
                                                <p className="ion-text-wrap">{formattedRow.teamMemberName}</p>
                                            </IonLabel>
                                            <IonRadio slot="start" color="tertiary" value={formattedRow.teamMemberId} />
                                            <IonReorder slot="start" />
                                        </IonItem>
                                    ))}
                                </>
                            )}
                        </IonList>
                        :
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Arm Number</th>
                                    <th>Division</th>
                                    <th>Grade</th>
                                    <th>Status</th>
                                    <th>Date Joined</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(formattedRows && formattedRows.length > 0) && (
                                    <>
                                        {formattedRows.map((formattedRow, index) => (
                                            <tr key={index} onClick={() => onSelectTeamMember(formattedRow)}>
                                                <td className="ion-text-wrap">
                                                    {formattedRow.teamMemberName}
                                                </td>
                                                <td className="ion-text-wrap">
                                                    {formattedRow.armNumber}
                                                </td>
                                                <td className="ion-text-wrap">
                                                    {formattedRow.division}
                                                </td>
                                                <td className="ion-text-wrap">
                                                    {formattedRow.gradeLevel}
                                                </td>
                                                <td className="ion-text-wrap">
                                                    {formattedRow.status}
                                                </td>
                                                <td className="ion-text-wrap">
                                                    {formattedRow.dateCreated}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </Table>
                    }
                </IonCardContent>
            }
        </IonCard>
    );
};

export default ViewTeamMembersRosterTable;