import { EventOptionsInput } from "../../API";

export function formatEventOptions(usesTextAlerts?: Boolean, usesEventManagement?: Boolean, usesVendorManagement?: Boolean, usesMediaTools?: Boolean, usesMarketingTools?: Boolean, usesFinanceTools?: Boolean): EventOptionsInput {
    const options: EventOptionsInput = {
        usesTextAlerts: !!usesTextAlerts,
        usesEventManagement: !!usesEventManagement,
        usesVendorManagement: !!usesVendorManagement,
        usesMediaTools: !!usesMediaTools,
        usesFinanceTools: false,
        usesMarketingTools: false,
        usesStablingManagement: false
    }
    return options;
}