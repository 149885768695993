import moment from "moment";
import { Event, Organization, ScheduleItem } from "../../models";
import { getEventClassEntriesCountByEventClassId } from "../eventClassEntry/EventClassEntry";
import { getEventsByOrganizationId } from "../events/Event";
import { getScheduleItemsByEventId } from "../scheduleItem/ScheduleItem";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

interface ringData {
    numberEntries: number
    feeAmount: number 
}

export async function generateYearlyRevenueByRing(organization: Organization) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${organization.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Entry Fee By Ring Analysis Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = ["Event Name", "Ring Name", "Total Entries", "Total Entry Fees"];
    // define an empty array of rows
    const tableRows: any[] = [];

    const eventQuery = await getEventsByOrganizationId(organization.id);
    if (eventQuery.isSuccess) {
        const events: Event[] = eventQuery.result;

        for (let i = 0; i < events.length; i++) {
            const event = events[i];

            const tax = event.invoiceOptions?.classFeeTax;

            // Key = ring name
            const eventRingMap = new Map<string, ringData>();
            
            const scheduleItemQuery = await getScheduleItemsByEventId(event.id);
            if (scheduleItemQuery.isSuccess) {
                const scheduleItems: ScheduleItem[] = scheduleItemQuery.result;

                // Sort the schedule items by eventRing
                const sortedScheduleItems = scheduleItems.sort((a, b) => a.eventRingId.localeCompare(b.eventRingId));

                for (let i = 0; i < sortedScheduleItems.length; i++) {
                    const scheduleItem = sortedScheduleItems[i];

                    const eventRingName = scheduleItem.eventRing?.name || "";
                    
                    // Check if this schedule item is for an EventClass or EventBreak
                    const eventClass = scheduleItem?.eventClass;
                    if (eventClass) {
                        // Calculate the class fee based on the class' entry fee plus tax
                        const classFee = eventClass.entryFee ? parseFloat(eventClass.entryFee) : 0;
                        const classFeeTotalPrice = classFee + (tax ? (tax * classFee) : 0);

                        const eventClassEntriesQuery = await getEventClassEntriesCountByEventClassId(eventClass.id);
                        if (eventClassEntriesQuery.isSuccess) {
                            const eventClassEntriesCount: number = eventClassEntriesQuery.result;

                            const totalClassRevenue = (classFeeTotalPrice * eventClassEntriesCount);

                            let currentEntryCount = 0;
                            let currentFeeTotal = 0;
                            const mapResult = eventRingMap.get(eventRingName);
                            if (mapResult) {
                                currentEntryCount = mapResult.numberEntries;
                                currentFeeTotal = mapResult.feeAmount;
                            }
                            let updatedEntryCount = currentEntryCount + eventClassEntriesCount;
                            let updatedFeeTotal = currentFeeTotal + totalClassRevenue;

                            const updatedData: ringData = {
                                numberEntries: updatedEntryCount,
                                feeAmount: updatedFeeTotal
                            };

                            eventRingMap.set(eventRingName, updatedData);
                        }
                    }
                }
            } else {
                console.error("No schedule items found.");
            }

            eventRingMap.forEach((value, key) => {
                const eventRingName = key;
                const totalEntryFeeRow = [
                    event.name,
                    eventRingName,
                    value.numberEntries ? value.numberEntries.toString() : "",
                    "$" + value.feeAmount
                ];
    
                tableRows.push(totalEntryFeeRow);
            });
        }
    } else {
        console.error("No events found.");
    }
     
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 35 
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    doc.save(`revenue_for_year_by_ring.pdf`);
}