import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { onCreateContact, onUpdateContact } from "../../graphql/subscriptions";
import { Contact } from "../../models";
import { getAllContacts } from "../../utilities/contact/Contact";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import Spinner from "../Spinners/Spinner";

interface _Props {
    onSelect: Function
    selectedValue?: string
    contact?: (Contact | null)
    isDisabled?: boolean
    showContactIds?: boolean
    isHeightRequired?: boolean
    height?: string
}

interface formattedOption {
    label: string
    value: string
    contact: Contact
}

const SelectFromAllContacts: React.FC<_Props> = ({contact, onSelect, selectedValue, isDisabled, showContactIds, isHeightRequired, height}) => {

    const contactSubscription = useSubscriptionByItself({
        config: {
            query: onCreateContact,
            key: "onCreateContact"
        }
    });

    const contactUpdateSubscription = useSubscriptionByItself({
        config: {
            query: onUpdateContact,
            key: "onUpdateContact"
        }
    });

    const [isLoading, setIsLoading] = useState(false);
    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    const [currentUpdateSubscriptionItem, setUpdateCurrentSubscriptionItem] = useState<any>();
    const [formattedContacts, setFormattedContacts] = useState<formattedOption[] | null | undefined>();
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();

    const getContacts = async () => {
        setIsLoading(true);
        const queryResult = await getAllContacts();
        if (queryResult.isSuccess) {
            const contacts = queryResult.result;
            const sorted = contacts.sort((a: Contact, b: Contact) => (a?.name || "zzzzzz").localeCompare(b?.name || "zzzzzz"))
            formatContacts(sorted || contacts);          
        }
        setIsLoading(false);
    };

    const formatContact = (contact: Contact) => {
        // const contactName = contact.name || "";
        // const contactCreatedOn = contact.createdOn ? moment(contact.createdOn).format("MM-DD-YY") : "unknown";
        // const contactLocation = contact.location ? formatShortAddress(contact.location) : "unknown";

        // const label = contactName + "\nCreated On: " + contactCreatedOn + "\nLocation: " + contactLocation + (showContactIds ? ("\nId: " + contact.id) : "");

        const emailAddress = contact.personalEmail || contact.workEmail || "none";
        const phoneNumber = contact.cell || contact.home || contact.work || "none";
        const label = "Email: " + emailAddress + "\nPhone: " + phoneNumber;
        let object: formattedOption = {
            value: contact.id,
            label: label,
            contact: contact as Contact
        };
        return object;
    }

    const formatContacts = (contacts: Contact[]) => {
        let formattedContacts = [];
        for (var i = 0; i < contacts.length; i++) {
            const contact = contacts[i];
            if (contact) {
                let object: formattedOption = formatContact(contact);
                formattedContacts.push(object);
            }
        }
        setFormattedContacts(formattedContacts);
        if (selectedValue) findValueInList(selectedValue, formattedContacts);
    }

    const findValueInList = (value: string, contactList?: formattedOption[]) => {
        let optionArray = contactList || formattedContacts;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        getContacts();
    }, []);

    useEffect(() => {
        if (contact) {
            const formattedContact = formatContact(contact);
            setCurrentValue(formattedContact);
        } else {
            setCurrentValue(undefined);
        }
    }, [contact]);

    useEffect(() => {
        if (contactSubscription && contactSubscription[0] !== undefined && contactSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(contactSubscription[0]);
            getContacts();
        }
    }, [contactSubscription]);

    useEffect(() => {
        if (contactUpdateSubscription && contactUpdateSubscription[0] !== undefined && contactUpdateSubscription[0] !== currentUpdateSubscriptionItem) {
            setUpdateCurrentSubscriptionItem(contactUpdateSubscription[0]);
            getContacts();
        }
    }, [contactUpdateSubscription]);

    useEffect(() => {
        async function setValue() {
            if (selectedValue) {
                if (!formattedContacts) {
                    await getContacts();
                } else {
                    findValueInList(selectedValue);
                }
                
            } else {
                setCurrentValue(undefined);
            }
        }
        setValue();
    }, [selectedValue]);

    const handleOnChange = async (event: any) => {
        if (event && event.contact) {
            setCurrentValue(event);
            onSelect(event.contact);
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <Select
                    id = "select-from-all-contact"
                    inputId="select-from-all-contact-input"
                    classNamePrefix="react-select"
                    styles={{
                        // Fixes the overlapping problem of the component
                        control: baseStyles =>(isHeightRequired ? {...baseStyles, height} : {...baseStyles}),
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    defaultValue={currentValue}
                    value={currentValue}
                    menuPortalTarget={document.body}
                    menuPlacement={"auto"}
                    isClearable
                    isDisabled={isDisabled}
                    options={formattedContacts!}
                    onChange={handleOnChange}
                />
            }
        </>
    );
};

export default SelectFromAllContacts;
