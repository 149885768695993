import { Result } from "../../interfaces/Result";
import { EventEntry, EventFee, Fee } from "../../models";
import { createEventEntryFee } from "./EventEntryFee";
import { getEventFeesByEventId } from "../eventFee/EventFee";
import { CreateEventEntryFeeInput } from "../../API";
import { isAfterEventFeeStartDate } from "./StartDateEventFee";

export async function addRequiredEventEntryFeesByEventByEntry(personId: string, eventId: string, entry: EventEntry) {
    const queryResult = await getEventFeesByEventId(eventId);
    if (queryResult.isSuccess) {
        const allEventFees: EventFee[] = queryResult.result;

        // Loop through the eventFee and add the required fees (isPerEntry === true)
        let isSuccess = true;
        let message = "";
        for (var i = 0; i < allEventFees.length; i++) {
            const currentFee: EventFee = allEventFees[i];

            if (currentFee.fee) {
                const fee: Fee = currentFee.fee;

                // Check if the fee has a specific date when it starts to be added to new entries
                if (fee.isStartedOnEntryDate) {
                    const isAfterFeeStartDate = isAfterEventFeeStartDate(currentFee);
                    if (!isAfterFeeStartDate) {
                        continue;
                    }
                }

                if (fee.isPerEntry) {
                    const input: CreateEventEntryFeeInput = {
                        eventId: eventId,
                        name: currentFee.name,
                        status: "submitted",
                        amount: currentFee.amount,
                        quantity: 1,
                        entryId: entry?.id, 
                        // eventEntryFeeEntryId: entry?.id, 
                        eventFeeId: currentFee.id,
                        // eventEntryFeeEventFeeId: currentFee.id,
                        feeId: currentFee.feeId, 
                        // eventEntryFeeFeeId: currentFee.feeId, 
                        taxA: currentFee.fee?.taxA,
                        taxB: currentFee.fee?.taxB,
                        description: currentFee.description,
                        createdBy: personId
                    };
                    const createResult = await createEventEntryFee(input);
                    if (!createResult.isSuccess) {
                        isSuccess = false;
                        message = message + "Could not update the status on event entry fee for: " + currentFee.id + ". ";
                    }
                }       
            }    
        }
        if (isSuccess) {
            let result: Result = {isSuccess: true, type: "EventEntryFee", result: null, message: "Successfully created the required event entry fees."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "EventEntryFee", result: null, message: message};
            return result; 
        }
    } else {
        let result: Result = {isSuccess: true, type: "EventEntryFee", result: null, message: "Could find any event entry fees."};
        return result; 
    }
}