import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import React, { useState } from "react";
import VideoForm from "../../../components/s3Object/VideoForm";
import VideoList from "../../../components/s3Object/VideoList";

const LibraryPage: React.FC = () => {

    const [updatedVideoList, setUpdatedVideoList] = useState();

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Library" />
                <IonRow id="profile-picture" className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Add Video</IonCardTitle>
                            <IonCardContent>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <VideoForm onSubmit={setUpdatedVideoList}/>
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow id="name" className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Your Video Library</IonCardTitle>
                            <IonCardContent>
                                <VideoList updatedVideoList={updatedVideoList}/>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default LibraryPage;