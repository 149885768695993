import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonContent,
    IonHeader,
    IonLabel,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../../../../components/Headers/Header";
import { Event, EventEntry, Horse, Organization } from "../../../../../models";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import Spinner from "../../../../../components/Spinners/Spinner";
import { PersonContext } from "../../../../../context/PersonContext";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import { getEventsByOrganizationId } from "../../../../../utilities/events/Event";
import { getEventEntriesByEventId } from "../../../../../utilities/eventEntry/EventEntry";
import { Table } from "reactstrap";
import { Storage } from "aws-amplify";
import { getHorseById } from "../../../../../utilities/horse/Horse";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

interface FormattedHorse {
    id: string
    horse: Horse
    cogginsLink: string
}

const EventOrganizationEntriesPage: React.FC<OrganizationPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(true);
    const [organization, setOrganization] = useState<Organization>();
    const [error, setError] = useState("");
    const [currentActiveBtn, setCurrentActiveBtn] = useState("horses");
    const [horses, setHorses] = useState<FormattedHorse[] | undefined | null>();

    const handleSelection = async (selection: string) => {
        setCurrentActiveBtn(selection);
    }

    async function handleGetDocument(fileName: string) {
        try {
            const url = await Storage.get(fileName);
            return url;
        } catch (error: any) {
            console.error("Error while getting the document: ", error);
            return "";
        }
    }

    const getEntriesFromEvents = async (events: Event[]) => {
        let entryArray: EventEntry[] = [];
        let horseArray: FormattedHorse[] = [];
        for (let i = 0; i < events.length; i++) {
            const event = events[i];
            const queryResult = await getEventEntriesByEventId(event.id);
            if (queryResult.isSuccess) {
                const entries: EventEntry[] = queryResult.result;
                entryArray = entryArray.concat(entries);

                for (let j = 0; j < entries.length; j++) {
                    const entry = entries[j];
                    if (entry.horse) {
                        const found = horseArray.findIndex(a => a.id === entry.horseId);
                        if (found === -1 && entry.horseId) {
                            const queryHorseResult = await getHorseById(entry.horseId);
                            if (queryHorseResult.isSuccess) {
                                const horse: Horse = queryHorseResult.result;
                                const cogginsLink: any = horse.coggins?.key ? (await handleGetDocument(horse.coggins?.key)) : "";
                                const formattedHorse: FormattedHorse = {
                                    id: horse.id,
                                    horse: horse,
                                    cogginsLink: cogginsLink
                                };
                                horseArray.push(formattedHorse);
                            }
                            
                        }
                    }
                }
            }
        }

        setHorses(horseArray.sort((a, b) => a.horse.name.localeCompare(b.horse.name)));
    }

    const getOrganizationEvents = async (organizationId: string) => {
        const queryResult = await getEventsByOrganizationId(organizationId);
        if (queryResult.isSuccess) {
            const events: Event[] = queryResult.result;
            await getEntriesFromEvents(events);
        } else {
            setError("No events found.");
        }
    }

    async function getOrganization() {
        try {
            setIsLoading(true);
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                setOrganization(queryResult.result);
                setIsLoading(false);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
                setIsLoading(false);
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOrganization();
        getOrganizationEvents(match.params.id);
    }, [user, match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={organization ? organization.name : "Org Past Entries"} />
                <IonHeader id="entryChoiceMenu">
                    <IonSegment color="primary" mode="md" value={currentActiveBtn} scrollable={true} onIonChange={e => {handleSelection(e.detail.value || "horses");}}>
                        <IonSegmentButton value="horses">
                            <IonLabel>Horses</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="riders">
                            <IonLabel>Riders</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonHeader>
                {error && <ErrorAlert error={error} />}
                {isLoading ?
                    <Spinner />
                    :   
                    <>
                        {organization ?
                            <>
                                {currentActiveBtn === "horses" && (
                                    <IonRow>
                                        <IonCol>
                                        <IonCard className="bg-white ion-padding">
                                            <IonCardHeader>Horses</IonCardHeader>
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol>
                                                        <Table striped responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        <p className="ion-text-wrap">
                                                                            Name
                                                                        </p>
                                                                    </th>
                                                                    <th>
                                                                        <p className="ion-text-wrap">
                                                                            Coggins Accession Number
                                                                        </p>
                                                                    </th>
                                                                    <th>
                                                                        <p className="ion-text-wrap">
                                                                            Coggins State
                                                                        </p>
                                                                    </th>
                                                                    <th>
                                                                        <p className="ion-text-wrap">
                                                                            Coggins Test Date
                                                                        </p>
                                                                    </th>
                                                                    <th>
                                                                        <p className="ion-text-wrap">
                                                                            Coggins PDF
                                                                        </p>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(horses && horses.length > 0) ?
                                                                    <>
                                                                        {horses.map((formattedHorse, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <p className="ion-text-wrap">
                                                                                        {formattedHorse.horse?.name}
                                                                                    </p>
                                                                                </td>
                                                                                <td>
                                                                                    <p className="ion-text-wrap">
                                                                                        {formattedHorse.horse?.cogginsNumber}
                                                                                    </p>
                                                                                </td>
                                                                                <td>
                                                                                    <p className="ion-text-wrap">
                                                                                        {formattedHorse.horse?.cogginsState}
                                                                                    </p>
                                                                                </td>
                                                                                <td>
                                                                                    <p className="ion-text-wrap">
                                                                                        {formattedHorse.horse?.cogginsDate}
                                                                                    </p>
                                                                                </td>
                                                                                <td>
                                                                                    {formattedHorse.cogginsLink ? 
                                                                                        <a href={formattedHorse.cogginsLink} className="ion-text-info link">Download</a>
                                                                                        :
                                                                                        <p className="ion-text-light">none found</p>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </>
                                                                    :
                                                                    <tr>
                                                                        <td>
                                                                            <p>No data found.</p>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                        </IonCol>
                                    </IonRow>
                                )}

                                {currentActiveBtn === "riders" && (
                                    <p>Coming Soon...</p>
                                )}
                            </>
                            :
                            <p>No organization found</p>
                        }
                    </>
                }

            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationEntriesPage;