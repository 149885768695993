import React, { useEffect, useState } from "react";
import { Event, EventClassEntry, EventEntry, EventEntryFee } from "../../../models";
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCol, IonRow } from "@ionic/react";
import DisplayEntryInfo from "../EntrySummary/DisplayEntryInfo";
import DisplayEntryFees from "../EntrySummary/DisplayEntryFees";
import DisplayEntryAgreements from "../EntrySummary/DisplayEntryAgreements";
import ViewEntryPageClassList from "./ViewEntryPageClassList";
import AcceptEntryForm from "./AcceptEntryForm";
import ViewEntryStablingRequest from "./ViewEntryStablingRequest";
import ViewEntryCurrentInvoice from "./ViewEntryCurrentInvoice";
import { useHistory } from "react-router";
import { Spinner } from "reactstrap";
import DisplayEntryNote from "../EntrySummary/DisplayEntryNote";
import { removeEventEntryData } from "../../../utilities/eventEntry/RemoveEventEntryData";
import ErrorAlert from "../../Errors/ErrorAlert";

interface _Props {
    currentEvent: Event
    entry: EventEntry
}

const ViewPendingEntryPage: React.FC<_Props> = ({currentEvent, entry}) => {
    const history = useHistory();

    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);

    const [event, setEvent] = useState<Event | null | undefined>();
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();

    useEffect(() => {
        if (entry) setCurrentEntry(entry);
    }, [entry]);

    useEffect(() => {
        if (currentEvent) setEvent(currentEvent)
    }, [currentEvent]);

    const handleDeleteEntry = async() => {
        setError("");
        if (currentEntry) {
            setIsLoading(true);
            const result = await removeEventEntryData(currentEntry);
            if (result.isSuccess){
                setIsLoading(false);
                const path = "/index/staff/event/" + event?.id + "/participants";
                history.push(path); 
            } else {
                let errorMessage = "Error: ";
                const errors = result.result;
                if (errors.length > 0) {
                    errors.forEach((errorMsg: string) => {
                        errorMessage = errorMessage + errorMsg + " ";
                    });
                } else {
                    errorMessage = errorMessage + result;
                }
                setError(errorMessage);
                setIsLoading(false);
            }
        } else {
            setError("No entry to delete.");
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            <IonRow className="ion-justify-content-center">
                <IonCol size="12">
                    <IonCard mode="md" className="ion-padding bg-white">
                        <IonCardTitle>
                            <IonRow>
                                <IonCol>
                                    Entry Status
                                </IonCol>
                            </IonRow>
                        </IonCardTitle>
                        <IonCardContent>
                            {event && currentEntry && (<AcceptEntryForm entry={currentEntry} event={event} />)}
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="12">
                    {currentEntry && (<ViewEntryPageClassList event={event} entry={currentEntry}/>)}
                </IonCol>
                <IonCol sizeXs="12" sizeMd="12">
                    {(event && currentEntry) && (
                        <>
                            <DisplayEntryInfo entry={currentEntry} isExpanded={true}/>
                            <DisplayEntryNote entry={currentEntry} isExpanded={true}/>
                            <DisplayEntryFees event={event} entry={currentEntry} />
                            <DisplayEntryAgreements event={event} entry={currentEntry} />
                            <ViewEntryStablingRequest event={event} entry={currentEntry} />
                        </>
                    )}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="12">
                    {event && (
                        <>
                            {currentEntry && (<ViewEntryCurrentInvoice event={event} entry={currentEntry} key={currentEntry.id} onUpdateEntry={(updatedEventEntry: EventEntry) => setCurrentEntry(updatedEventEntry)} />)}
                        </>
                    )}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="12">
                    <IonCard color="white" className="ion-padding">
                        <IonCardContent>
                            <p>Deleting this entry will delete all of the data associated with it including the classes and divisions it's entered in, the fees, stabling, results, rules agreements, etc.</p>
                            <p>If you want to scratch a class from the entry, you can remove a class from the entry on the Classes tab after you accept the entry.</p>
                            <p>If you want the person who submitted the entry to correct a mistake on the entry, you can decline the entry and include the reason. The system will send an email to the person giving them the reason and telling them how to fix it.</p>
                            <IonRow>
                                <IonCol className="ion-text-center">
                                    {isLoading ?
                                        <Spinner />
                                        :
                                        <IonButton color="danger" onClick={handleDeleteEntry}>Delete This Entry</IonButton>
                                    }
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </>
    );
};

export default ViewPendingEntryPage;