import {
    IonButton,
    IonCol,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonText,
    isPlatform,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import Spinner from "../Spinners/Spinner";
import ErrorAlert from "../Errors/ErrorAlert";
import { isWindows } from "../../utilities/platform/Platform";
import { AlgoliaEvent } from "../../interfaces/AlgoliaEvent";
import { formatStartEndDatesStrings } from "../../utilities/dates/FormatDates";

interface _Props {
    eventsList?: (AlgoliaEvent[] | null)
    onSelect: Function
    onFilter?: Function
}

const DisplayEventTable: React.FC<_Props> = ({eventsList, onSelect, onFilter}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<string>("all");
    const [events, setEvents] = useState<AlgoliaEvent[] | null | undefined>();
    const [error, setError] = useState<string>(""); 

    useEffect(() => {
        setEvents(eventsList);
    }, [eventsList]);

    const handleSelectedFilter = (selection: string) => {
        setSelectedFilter(selection);
        if (onFilter) onFilter(selection);
    }

    const handleSelectedEvent = (event: AlgoliaEvent) => {
        setError("");
        if (onSelect) onSelect(event);
    }

    return (
        <>
            {((!isWindows()) && isPlatform("mobile")) ?
                <div>
                    {error && <ErrorAlert width="12" error={error} />}
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            <IonRow className="ion-align-items-center">
                                <IonHeader id="selectEventFilter">
                                    <IonSegment color="tertiary" value={selectedFilter} scrollable={true} onIonChange={e => {handleSelectedFilter(e.detail.value || "all");}}>
                                        <IonSegmentButton value="all">
                                            <IonLabel>All</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="future">
                                            <IonLabel>Future</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="current">
                                            <IonLabel>Current</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="past">
                                            <IonLabel>Past</IonLabel>
                                        </IonSegmentButton>
                                    </IonSegment>
                                </IonHeader>
                            </IonRow>
                            {events && events.length ?
                                <IonList className="bg-white">
                                    {events.map((event, index) => (
                                        <IonItem key={index} detail={true} onClick={() => handleSelectedEvent(event)}>
                                            <IonRow>
                                                <IonCol>
                                                    <IonRow>
                                                        <IonText><h2 className="ion-text-wrap text-darker">{event.name}</h2></IonText>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonText><h4 className="ion-text-wrap text-darker">{formatStartEndDatesStrings(event.startDate, event.endDate)}</h4></IonText>
                                                    </IonRow>
                                                    <IonRow>
                                                        <IonText><h5 className="ion-text-wrap text-darker">{event.location ? event.location : ""}</h5></IonText>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>
                                        </IonItem>
                                    ))}
                                </IonList>
                                :
                                <p>No events found.</p>
                            }
                        </>
                    }
                </div>
                :
                <div>
                    {error && <ErrorAlert width="12" error={error} />}
                    <IonRow className="ion-align-items-center">
                        <IonCol sizeXs="2" sizeMd="3" className="ion-text-center">
                            <IonButton 
                                onClick={() => handleSelectedFilter("past")} 
                                color={selectedFilter === "past" ? "tertiary" : "white"}
                            >
                                    Past
                            </IonButton>
                        </IonCol>
                        <IonCol sizeXs="4" sizeMd="3" className="ion-text-center">
                            <IonButton 
                                onClick={() => handleSelectedFilter("current")} 
                                color={selectedFilter === "current" ? "tertiary" : "white"}
                            >
                                    Current
                            </IonButton>
                        </IonCol>
                        <IonCol sizeXs="3" sizeMd="3" className="ion-text-center">
                            <IonButton 
                                onClick={() => handleSelectedFilter("future")} 
                                color={selectedFilter === "future" ? "tertiary" : "white"}
                            >
                                    Future
                            </IonButton>
                        </IonCol>
                        <IonCol sizeXs="2" sizeMd="3" className="ion-text-center">
                            <IonButton 
                                onClick={() => handleSelectedFilter("all")} 
                                color={selectedFilter === "all" ? "tertiary" : "white"}
                            >
                                    All
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            {events && events.length ?
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>Dates</th>
                                            <th>Name</th>
                                            <th>Location</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {events.map((event, index) => (
                                            <tr key={index} onClick={() => handleSelectedEvent(event)}>
                                                <td className="ion-text-wrap text-darker">{formatStartEndDatesStrings(event.startDate, event.endDate)}</td>
                                                <td className="ion-text-wrap text-darker">{event.name}</td>
                                                <td className="ion-text-wrap text-darker">{event.provState ? (event.country ? event.provState + ", " + event.country : "") : ""}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                :
                                <p>No events found.</p>
                            }
                        </>
                    }
                </div>
            }
        </>
    );
};

export default DisplayEventTable;