import { API } from "aws-amplify";
import { Result } from "../../interfaces/Result";

/**
 * Functionality to create a new Stripe Standard Connect Customer.
 * 
 * More details here: https://stripe.com/docs/connect/standard-accounts
 * 
 * @param {string} organizationId 
 */
export async function createStandardConnectAccount(organizationId: string) {
    try {
        let queryParams = "";
        if (!organizationId) {
            let result: Result = {isSuccess: false, type: "Stripe", result: "No organization id provided.", message: "Failed to create a new Stripe Standard Connect Account." }
            return result;
        }
        else queryParams = queryParams + "?organizationId=" + organizationId;

        const path = "/payments/create-standard-account" + queryParams;
        const response = await API.get("Payments", path, {});
        const responseBody = JSON.parse(response.body);
        const connectedAccountId = responseBody.connectedAccountId;
        const onboardingLink = responseBody.accountLink;

        const data = {
            onboardingLink,
            connectedAccountId
        };

        let result: Result = {isSuccess: true, type: "Stripe", result: data, message: "Successfully created a new customer."}
        return result;
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Error occurred while trying to create a new Stripe customer." }
        return result;
    }
}

/**
 * Functionality to get an account to for a Stripe Standard Connect Customer to update the Connect Account.
 * 
 * More details here: https://stripe.com/docs/connect/standard-accounts
 * 
 * @param {string} organizationId 
 * @param {string} connectedAccountId 
 */
export async function getStandardConnectAccountLink(organizationId: string, connectedAccountId: string) {
    try {
        let queryParams = "";
        if (!organizationId) {
            let result: Result = {isSuccess: false, type: "Stripe", result: "No organization id provided.", message: "Failed to get the account link for the Stripe Standard Connect Account." }
            return result;
        }
        else queryParams = queryParams + "?organizationId=" + organizationId;

        if (!connectedAccountId) {
            let result: Result = {isSuccess: false, type: "Stripe", result: "No connect account id provided.", message: "Failed to get the account link for the Stripe Standard Connect Account." }
            return result;
        }
        else queryParams = queryParams + "&connectedAccountId=" + connectedAccountId;

        const path = "/payments/get-account-link" + queryParams;
        const response = await API.get("Payments", path, {});
        const responseBody = JSON.parse(response.body);
        const accountLink = responseBody.accountLink;

        let result: Result = {isSuccess: true, type: "Stripe", result: accountLink, message: "Successfully created a the account link."}
        return result;
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Error occurred while trying to create a new account link." }
        return result;
    }
}
