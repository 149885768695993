import { Clinician } from "../../models";
import { ClinicianStatus, UpdateClinicianInput } from "../../API";
import {
    IonButton,
    IonCol,
    IonItem,
    IonLabel,
    IonRow,
    IonToggle,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";

import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";

import { updateClinician, getClinicianByPersonId} from "../../utilities/clinician/Clinician";

import moment from "moment";
import InformationBanner from "../Banners/InformationBanner";
import Spinner from "../Spinners/Spinner";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import { onCreateClinician } from "../../graphql/subscriptions";

interface _Props {
    selectedClinician?: Clinician
    onChange?: Function
}

const ClinicianStatusForm: React.FC<_Props> = ({selectedClinician, onChange}) => {
    const user = useContext(PersonContext);

    const clinicianSubscription = useSubscriptionByItself({
        config: {
            query: onCreateClinician,
            key: "onCreateClinician"
        }
    });

    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    
    const [isEditing, setIsEditing] = useState(false);
    const [clinician, setClinician] = useState<Clinician | null | undefined>();
    const [isAvailable, setIsAvailable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const getClinician = async (personId: string) => {
        const queryResult = await getClinicianByPersonId(personId);
        if (queryResult.isSuccess) {
            const clinician: Clinician = queryResult.result;
            setClinician(clinician);
            setIsAvailable(clinician.status === "AVAILABLE");
        }
    }

    useEffect(() => {
        if (user) {
            getClinician(user.id);
        }
    }, []);

    useEffect(() => {
        setClinician(selectedClinician);
        setIsAvailable(selectedClinician?.status === "AVAILABLE");
    }, [selectedClinician]);

    useEffect(() => {
        if (clinicianSubscription && clinicianSubscription[0] !== undefined && clinicianSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(clinicianSubscription[0]);
            if (user) {
                getClinician(user.id);
            }
        }
    }, [clinicianSubscription]);

    const handleUpdateClinician = async () => {
        if (clinician) {
            const input: UpdateClinicianInput = {
                id: clinician.id,
                status: isAvailable ? ClinicianStatus.AVAILABLE : ClinicianStatus.PAUSED,
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
            const updateResult = await updateClinician(input);
            if (updateResult.isSuccess) {
                if (onChange) onChange(updateResult.result);
                setClinician(updateResult.result);
            } else {
                setError(updateResult.message);
            }
        } else {
            setError("No clinician to edit.");
        }
    }

    const handleEditClinician = async () => {
        setIsLoading(true);
        await handleUpdateClinician();
        setIsEditing(false);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert error={error} />}
            {isEditing ?
                <IonRow>
                    <IonCol size="12">
                        <IonItem lines="none">
                            <IonLabel>
                                <IonRow>
                                    <IonCol size="10">
                                       <p className="ion-text-wrap">Do you want to accept new videos?</p> 
                                    </IonCol>
                                    <IonCol size="2">
                                        <IonToggle color="success" checked={isAvailable} onIonChange={e => setIsAvailable(e.detail.checked)}/>
                                    </IonCol>
                                </IonRow>
                            </IonLabel>
                        </IonItem> 
                    </IonCol>
                </IonRow>
                :
                <IonRow>
                    <IonCol size="12">
                        <InformationBanner info={
                            clinician?.status === "AVAILABLE" ? "You are currently accepting new videos. Edit your status if you would like to pause incoming videos." :
                            clinician?.status === "PENDING" ? "Your account is waiting for verification by RingSide Pro staff. We verify every clinician's identity so that people do not pose as you or another clinician." :
                            clinician?.status === "PAUSED" ? "You are currently paused and will not receive new videos. Edit your status if you would like to receive new videos from riders." :
                            clinician?.status === "BANNED" ? "Your ability to accept new videos has been deactivated. Please contact us if you have any questions: hello@ringsidepro.com" :
                            clinician?.status === "INACTIVE" ? "Your ability to accept new videos has been deactivated. Please contact us if you have any questions: hello@ringsidepro.com" : 
                            !clinician?.status ? "Fill out the profile form below to get started!" : ""
                        } />
                        <IonItem lines="none">
                            {clinician?.status ? <h3>Status: <span className={clinician?.status === "AVAILABLE" ? "text-success" : "text-primary"}>{clinician?.status}</span></h3> : ""}
                        </IonItem> 
                    </IonCol>
                </IonRow>
            }
            {(clinician?.status === "AVAILABLE" || clinician?.status === "PAUSED") && (
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeMd="4" className="ion-text-center">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={() => (isEditing ? handleEditClinician() : setIsEditing(true))}
                            >
                                {isEditing ? "Save" : "Edit"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            )}
        </>
    );
};

export default ClinicianStatusForm;