import { IonCheckbox, IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import { Event } from "../../models";
import React, { useState } from "react";

interface _Props {
    event?: Event
    onInputChange: Function
}

const SelectEventOptions: React.FC<_Props> = ({event, onInputChange}) => {

    const [textAlertOption, setTextAlertOption] = useState(true);
    const [onlineEntryOption, setOnlineEntryOption] = useState(true);
    const [acceptsChecks, setAcceptsChecks] = useState(true);
    const [acceptsCC, setAcceptsCC] = useState(true);

    const onTextAlertChange = async () => {
        const currentValue = textAlertOption;
        setTextAlertOption(!currentValue);
        onInputChange(!currentValue, onlineEntryOption, true, acceptsCC);
    }

    const onOnlineEntryChange = async () => {
        const currentValue = onlineEntryOption;
        const currentCCValue = acceptsCC;
        setOnlineEntryOption(!currentValue);
        setAcceptsCC(!currentValue);
        onInputChange(textAlertOption, !currentValue, true, !currentValue);
    }

    const onCCChange = async () => {
        const currentValue = acceptsCC;
        setAcceptsCC(!currentValue);
        onInputChange(textAlertOption, onlineEntryOption, true, !currentValue);
    }

    return (
        <>
            <IonList className="bg-white">
                <IonListHeader color="white">Select: </IonListHeader>
                <IonItem className="ion-no-padding">
                    <IonLabel>Use Text Alerts (only pay for what you use)</IonLabel>
                    <IonCheckbox color="primary" checked={textAlertOption} slot="start" onClick={() => onTextAlertChange()}></IonCheckbox>
                </IonItem>
                <IonItem className="ion-no-padding">
                    <IonLabel>Use Online Entries ($99 flat fee)</IonLabel>
                    <IonCheckbox color="primary" checked={onlineEntryOption} slot="start" onClick={() => onOnlineEntryChange()}></IonCheckbox>
                </IonItem>
                {/* TO DO: Accept Online Payments for events */}
                {/* <div className="ion-margin-left">
                    <IonItem> 
                        <IonLabel className="ion-margin-left">Checks accepted from participants</IonLabel>
                        <IonCheckbox className="ion-margin-left" color="primary" checked={acceptsChecks} disabled={true} slot="start"></IonCheckbox>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Credit Cards accepted from participants</IonLabel>
                        <IonCheckbox color="primary" checked={onlineEntryOption ? acceptsCC : false} slot="start" onClick={() => onCCChange()}></IonCheckbox>
                    </IonItem>
                </div> */}
            </IonList>
        </>
    );
};

export default SelectEventOptions;