import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { UpdateEventInput } from "../../API";
import { Event } from "../../models";
import { updateEvent } from "../../utilities/events/Event";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    event: Event
}

const EventECSettingsForm: React.FC<_Props> = ({event}) => {

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [number, setNumber] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string>("");


    useEffect(() => {
        if (event) {
            if (event.ecID) setNumber(event.ecID);
            if (event.ecPassword) setPassword(event.ecPassword);
        }
    }, [event]);

    const handleSave = async () => {
        const input: UpdateEventInput = {
            id: event.id,
            ecID: number,
            ecPassword: password
        };
        const updateResult = await updateEvent(input);
        if (updateResult.isSuccess) {
            setIsDisabled(true);
        } else {
            setError("Could not save the EC Settings.");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        await handleSave();
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <IonRow>
                <IonCol sizeMd="12">
                    <IonItem color="white">
                        <IonLabel position="stacked">EC Number</IonLabel>
                        <IonInput 
                            type="text"
                            value={number}
                            aria-required={true}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setNumber(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="12">
                    <IonItem color="white">
                        <IonLabel position="stacked">EC Password</IonLabel>
                        <IonInput 
                            type="text"
                            value={password}
                            aria-required={true}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setPassword(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            disabled={isDisabled}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            {isDisabled ? "Saved" : "Save"}
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </>
    );
};

export default EventECSettingsForm;