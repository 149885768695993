import React, { useEffect, useState } from "react";
import { EventClassEntry } from "../../models";
import { getEventClassEntriesByEventClassId } from "../../utilities/eventClassEntry/EventClassEntry";
import { sortEventClassEntriesByNumber } from "../../utilities/eventClassEntry/SortEventClassEntries";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    eventClassId: string
    values?: (EventClassEntry[] | null)
    menuPlacement?: (string | null)
    selectedValue?: (string | null)
    onSelect: Function
}

const SelectEventClassEntry: React.FC<_Props> = ({eventClassId, values, selectedValue, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | null | undefined>();
    const [formattedEventClassEntryEntries, setFormattedEventClassEntryEntries] = useState<formattedOption[] | null | undefined>();

    const formatEventClassEntryEntries = (eventClassEntries: EventClassEntry[]) => {
        let formattedEventClassEntryEntries = [];
        let sortedEventClassEntries = sortEventClassEntriesByNumber(eventClassEntries);
        const eventClassEntryList = sortedEventClassEntries || eventClassEntries;
        for (var i = 0; i < eventClassEntryList.length; i++) {
            const currentClassEntry = eventClassEntryList[i];
            const label = currentClassEntry.eventEntry?.number + " - " + currentClassEntry.eventEntry?.horseName + (currentClassEntry.eventEntry?.riderName ? " - " + currentClassEntry.eventEntry?.riderName : "");
            let object = {
                value: currentClassEntry.id,
                label: label,
                object: currentClassEntry
            };
            formattedEventClassEntryEntries.push(object);
        }
        setFormattedEventClassEntryEntries(formattedEventClassEntryEntries);
    }

    useEffect(() => {
        if (values) formatEventClassEntryEntries(values);
    }, [values]);

    useEffect(() => {
        async function getEventClassEntryEntries(eventClassId: string) {
            const queryResult = await getEventClassEntriesByEventClassId(eventClassId);
            if (queryResult.isSuccess) {
                formatEventClassEntryEntries(queryResult.result);
            }
        }

        if (eventClassId && !values) getEventClassEntryEntries(eventClassId);

        // When a new class has been chosen, reset the form
        setSelected(undefined);
    }, [eventClassId]);

    useEffect(() => {
        setSelected(selectedValue);
    }, [selectedValue]);

    const handleOnChange = (event?: any) => {
        if (event) {
            onSelect(event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedEventClassEntryEntries ?
                <BasicSelect formattedValues={formattedEventClassEntryEntries} selectedValue={selected} menuPlacement={menuPlacement} onSelect={handleOnChange}/>
                :
                <p>Did not find any entries...</p>
            }
        </>
    );
};

export default SelectEventClassEntry;