import React, { useEffect, useState } from "react";
import { IonRow, IonCol, IonText, IonButton } from "@ionic/react";
import BasicTooltip from "../../Tooltip/BasicTooltip";
import SelectCreatePointTable from "../../PointTable/SelectCreatePointTable";
import { PointTable } from "../../../models";

interface _Props {
    organizationId: string
    selectedPointTable?: (PointTable | null)
    onSelectPointTable: Function
}

const ClassPointTable: React.FC<_Props> = ({organizationId, selectedPointTable, onSelectPointTable}) => {
    const [isHidden, setIsHidden] = useState(true);
    const [pointTable, setPointTable] = useState<PointTable | null | undefined>();

    useEffect(() => {
        if (selectedPointTable) setPointTable(selectedPointTable);
        else setPointTable(undefined);
    }, [selectedPointTable]);

    const handlePointTable = (pointTable: PointTable) => {
        setPointTable(pointTable);
        onSelectPointTable("pointTable", pointTable);
    }
    
    return (
        <>
            {isHidden ?
                <IonText>
                    Point Table: {pointTable?.name} <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(false)}>Edit</IonButton>
                </IonText>
                :
                <>
                    <IonRow>
                        <IonCol className="text-center">
                            <BasicTooltip 
                                label="Class Point Table"
                                tip="Set how points should be distributed."
                            />
                            <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(true)}>Hide</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <BasicTooltip 
                                label="Point Table"
                                tip='If you have not created a point table before, type in "My Table" and then select "Create". Leave blank if this class has no points.'
                            />
                            {organizationId ?
                                <SelectCreatePointTable organizationId={organizationId} pointTable={pointTable} onSelect={handlePointTable}/>
                                :
                                <p>Error</p>
                            }
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default ClassPointTable;
