import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
    IonTextarea,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Class, PrizeMoneyTable, Event, EventClass, OrganizationClass, OrganizationDivision, EventDivision, PointTable } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import { createClass, updateClass } from "../../utilities/class/Class";
import SelectDiscipline from "../Discipline/SelectDiscipline";
import { createEventClass, deleteEventClass, updateEventClass } from "../../utilities/eventClass/EventClass";
import SuccessBanner from "../Banners/SuccessBanner";
import { createOrganizationClass, updateOrganizationClass } from "../../utilities/organizationClass/OrganizationClass";
import SelectEventClassType from "./EventClassForm/SelectEventClassType";
import SelectDivisionOrStandAloneClass from "./EventClassForm/SelectDivisionOrStandAloneClass";
import SelectEntryType, { EntryType } from "./EventClassForm/SelectEntryType";
import AllowedNumberOfEntries from "./EventClassForm/AllowedNumberOfEntries";
import EstimatedTimePerRound from "./EventClassForm/EstimatedTimePerRound";
import ClassEntryFee from "./EventClassForm/ClassEntryFee";
import DivisionEntryFee from "./EventClassForm/DivisionEntryFee";
import ClassPrizeMoney from "./EventClassForm/ClassPrizeMoney";
import { createDivision, getDivisionById } from "../../utilities/division/Division";
import { createOrganizationDivision, updateOrganizationDivision } from "../../utilities/organizationDivision/OrganizationDivision";
import { createEventDivision, updateEventDivision, getEventDivisionByOrganizationDivisionId, getEventDivisionById } from "../../utilities/eventDivision/EventDivision";
import { CreateClassInput, CreateDivisionInput, CreateEventClassInput, CreateEventDivisionInput, CreateOrganizationClassInput, CreateOrganizationDivisionInput, UpdateClassInput, UpdateEventClassInput, UpdateEventDivisionInput, UpdateOrganizationClassInput, UpdateOrganizationDivisionInput } from "../../API";
import { getTimestamp } from "../Timestamp";
import SelectEventDivision from "../EventDivision/SelectEventDivision";
import SelectOrganizationClass from "../OrganizationClass/SelectOrganizationClass";
import { Input } from "reactstrap";
import ClassPointTable from "./EventClassForm/ClassPointTable";
import JumpHeightOptionsForm from "./EventClassForm/JumpHeightOptionsForm";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    event: Event
    template?: (OrganizationClass | OrganizationDivision | null)
    eventClass?: (EventClass | null)
    onSubmit: Function
}

const EventClassForm: React.FC<_Props> = ({eventClass, template, event, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isPartOfDivision, setIsPartOfDivision] = useState(true);
    const [currentClass, setCurrentClass] = useState<Class | null | undefined>();
    const [eventDivision, setEventDivision] = useState<EventDivision | null | undefined>();
    const [organizationClass, setOrganizationClass] = useState<OrganizationClass | null | undefined>();
    const [organizationDivision, setOrganizationDivision] = useState<OrganizationDivision | null | undefined>();
    const [name, setName] = useState("");
    const [divisionName, setDivisionName] = useState("");
    const [number, setNumber] = useState(0);
    const [description, setDescription] = useState("");
    const [discipline, setDiscipline] = useState("hunter"); //Hunter is the default option in SelectDiscipline
    const [eventClassType, setEventClassType] = useState<string>("over_fences");
    const [entryFee, setEntryFee] = useState(0);
    const [divisionEntryFee, setDivisionEntryFee] = useState(0);
    const [prizeMoney, setPrizeMoney] = useState(0);
    const [divisionPrizeMoney, setDivisionPrizeMoney] = useState(0);
    const [prizeMoneyTable, setPrizeMoneyTable] = useState<PrizeMoneyTable | null | undefined>();
    const [pointTable, setPointTable] = useState<PointTable | null | undefined>();
    const [entryType, setEntryType] = useState<EntryType>(EntryType.both);
    const [minNumberEntries, setMinNumberEntries] = useState(3);
    const [maxNumberEntries, setMaxNumberEntries] = useState<number | null | undefined>();
    const [estimatedTimePerRoundMin, setEstimatedTimePerRoundMin] = useState(2);
    const [estimatedTimePerRoundSec, setEstimatedTimePerRoundSec] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    const getEventDivision = async (divisionId: string) => {
        const queryResult = await getEventDivisionById(divisionId);
        if (queryResult.isSuccess) {
            setEventDivision(queryResult.result);
        } 
    }

    useEffect(() => {
        if (eventClass) {
            setSuccess("");
            if (!eventClass.organizationDivisionId) {
                setIsPartOfDivision(false);
                setEventClassType("");
            } else {
                setIsPartOfDivision(true);
            }
            if (eventClass.class) {
                setCurrentClass(eventClass.class);
                if (eventClass.name) setName(eventClass.name);
                else if (eventClass.class.name) setName(eventClass.class.name);
                if (eventClass.class.description) setDescription(eventClass.class.description);
                if (eventClass.class.discipline) setDiscipline(eventClass.class.discipline);
                if (eventClass.class.type) setEventClassType(eventClass.class.type);
            }
            if (eventClass.organizationClass) {
                if (eventClass.organizationClass) setOrganizationClass(eventClass.organizationClass);
            } 
            if (eventClass.organizationDivision) {
                if (eventClass.organizationDivision) setOrganizationDivision(eventClass.organizationDivision);
                if (eventClass.organizationDivision.name) setDivisionName(eventClass.organizationDivision.name);
                if (eventClass.organizationDivision.entryFee) setDivisionEntryFee(parseInt(eventClass.organizationDivision.entryFee));
            }
            if (eventClass.entryFee && eventClass.organizationDivision && eventClass.organizationDivision.entryFee) {
                setEntryType(EntryType.both);
            } else if (eventClass.entryFee) {
                setEntryType(EntryType.class);
            } else {
                setEntryType(EntryType.division);
            }
            if (eventClass.eventDivision) setEventDivision(eventClass.eventDivision);
            if (eventClass.entryFee) setEntryFee(parseFloat(eventClass.entryFee));
            if (eventClass.prizeMoney) setPrizeMoney(parseFloat(eventClass.prizeMoney));
            if (eventClass.prizeMoneyTable) setPrizeMoneyTable(eventClass.prizeMoneyTable);
            if (eventClass.pointTable) setPointTable(eventClass.pointTable);
            if (eventClass.number) setNumber(eventClass.number);
            if (eventClass.minNumberEntries) setMinNumberEntries(eventClass.minNumberEntries);
            if (eventClass.maxNumberEntries) setMaxNumberEntries(eventClass.maxNumberEntries);
            if (eventClass.estimatedTimePerRound) {
                setEstimatedTimePerRoundMin(calculateEstimatedTimePerRoundMin(eventClass?.estimatedTimePerRound || 0));
                setEstimatedTimePerRoundSec(calculateEstimatedTimePerRoundSec(eventClass?.estimatedTimePerRound || 0));
            }    

            setSuccess("");
            setError("");
        } else {
            clearForm();
        }
    }, [eventClass]);

    useEffect(() => {
        if (template) {
            setSuccess("");
            setError("");
            if ((template as any).divisionId) {
                setIsPartOfDivision(true);
                setOrganizationDivision(template as OrganizationDivision);
                setDivisionName(template.name);
            } else {
                setIsPartOfDivision(false);
                setOrganizationClass(template as OrganizationClass);
            }
            setName(template.name);
        } else {
            clearForm();
        }
    }, [template]);

    const verifyForm = () => {
        try {
            if (!name) {
                setError("Please include a name for this class.");
                return false;
            }
            if (!number) {
                setError("Please include a number for this class.");
                return false;
            }
            return true;
        } catch (error: any) {
            return false;
        }
    }

    const clearForm = () => {
        setName("");
        setNumber(0);
        setDescription("");
        setCurrentClass(undefined);
        setOrganizationClass(undefined);
        setOrganizationDivision(undefined);
        setEventDivision(undefined);
        // setEventClassType(""); //This messes up the first class to be added if it's included
        setError("");
    }
    
    const clearAllForm = () => {
        setName("");
        setDivisionName("");
        setEventDivision(undefined);
        setNumber(0);
        setDescription("");
        setMinNumberEntries(1);
        setMaxNumberEntries(undefined);
        setEstimatedTimePerRoundMin(0);
        setEstimatedTimePerRoundSec(0);
        setEntryFee(0);
        setDivisionEntryFee(0);
        setPrizeMoney(0);
        setDivisionPrizeMoney(0);
        setPrizeMoneyTable(undefined);
        setPointTable(undefined);
        setCurrentClass(undefined);
        setOrganizationClass(undefined);
        setOrganizationDivision(undefined);
        setError("");
        eventClass = undefined;
    }

    const calculateEstimatedTimePerRound = () => {
        try {
            return estimatedTimePerRoundMin * 60 + estimatedTimePerRoundSec;
        } catch (error: any) {
            return 0;
        }
    }

    const calculateEstimatedTimePerRoundMin = (total: number) => {
        try {
            return Math.floor(total/60);
        } catch (error: any) {
            return 0;
        }
    }

    const calculateEstimatedTimePerRoundSec = (total: number) => {
        try {
            return total - Math.floor(total/60) * 60;
        } catch (error: any) {
            return 0;
        }
    }

    const handleSelectedDivisionOrClass = (option: string) => {
        if (option === "class") {
            setIsPartOfDivision(false);
            clearForm();
            setEntryType(EntryType.class);
        } else if (option === "division") {
            setIsPartOfDivision(true);
            clearForm();
            setEntryType(EntryType.division);
        }
        else {
            setIsPartOfDivision(true);
            clearForm();
            setEntryType(EntryType.both);
        }
        setIsDisabled(false);
    }

    const handleSingleClassSelect = (currentOrganizationClass?: OrganizationClass) => {
        if (currentOrganizationClass) {
            setName(currentOrganizationClass.name || "");
            setOrganizationClass(currentOrganizationClass || "");
            setPrizeMoneyTable(currentOrganizationClass.prizeMoneyTable ? currentOrganizationClass.prizeMoneyTable : undefined);
            setNumber(currentOrganizationClass.number || 0);
            setDescription(currentOrganizationClass.class.description || "");
            setDiscipline(currentOrganizationClass.class.discipline || "");
            setEntryFee(currentOrganizationClass.entryFee ? parseFloat(currentOrganizationClass.entryFee) : 0);
            setPrizeMoney(currentOrganizationClass.prizeMoney ? parseFloat(currentOrganizationClass.prizeMoney) : 0);
        }
        setIsDisabled(false);
    }

    const handleDivisionSelect = async (division: EventDivision) => {
        setEventDivision(division);
        setName(division.name);
        setDivisionName(division.name);
        setDescription(division.division?.description || "");
        setDiscipline(division.division?.discipline || "");
        setEntryFee(parseFloat(division?.entryFee || "0") || 0);
        setDivisionEntryFee(parseFloat(division?.entryFee || "0") || 0);
        setPrizeMoney(parseFloat(division.prizeMoney || "0"));
        setDivisionPrizeMoney(parseFloat(division.prizeMoney || "0"));
        setPrizeMoneyTable(division.prizeMoneyTable);
        if (division.organizationDivision) {
            setOrganizationDivision(division.organizationDivision);                
        }
    }

    const handleSelectedDiscipline = (discipline: string) => {
        if (discipline) setDiscipline(discipline);
        else setDiscipline("");
        setIsDisabled(false);
    }

    const handleSelectedEventClassType = (eventClassType?: string) => {
        if (eventClassType) setEventClassType(eventClassType);
        else setEventClassType("");
        setIsDisabled(false);
    }

    const handleSelectedEntryType = (entryType: EntryType) => {
        setEntryType(entryType);
        if (entryType === EntryType.class) setDivisionEntryFee(0);
        if (entryType === EntryType.division) setEntryFee(0);
        setIsDisabled(false);
    }

    const handleSelectedEntryNumber = (option: "min" | "max", number: number) => {
        if (option === "min") setMinNumberEntries(number);
        else setMaxNumberEntries(number);
        setIsDisabled(false);
    }

    const handleSelectedTimePerRound = (option: "min" | "sec", number: number) => {
        if (option === "min") setEstimatedTimePerRoundMin(number);
        else setEstimatedTimePerRoundSec(number);
        setIsDisabled(false);
    }

    const handleSelectedClassEntryFee = (option: "class", number: number) => {
        setEntryFee(number);
        setIsDisabled(false);
    }

    const handleSelectedDivisionEntryFee = (option: "division", number: number) => {
        setDivisionEntryFee(number);
        setIsDisabled(false);
    }

    const handleSelectedPrizeMoney = (option: "prizeMoney", number: number) => {
        setPrizeMoney(number);
        setIsDisabled(false);
    }

    const handleSelectedPrizeMoneyTable = (option: "prizeMoneyTable", prizeMoneyTable: PrizeMoneyTable) => {
        setPrizeMoneyTable(prizeMoneyTable);
        if (prizeMoneyTable && !prizeMoneyTable.isPercentage && prizeMoneyTable.totalPot) {
            setPrizeMoney(prizeMoneyTable.totalPot);
        } else {
            setPrizeMoney(0);
        }
        setIsDisabled(false);
    }

    const handleSelectedPointTable = (option: "pointTable", pointTable: PointTable) => {
        setPointTable(pointTable);
        setIsDisabled(false);
    }

    const handleCreateNewClass = async (currentEventDivision?: EventDivision, currentOrganizationDivision?: OrganizationDivision) => {
        let currentClass;
        let currentOrganizationClass;
        if (!organizationClass) {
            // First create the class
            const input: CreateClassInput = {
                name: name,
                description: description,
                discipline: discipline,
                type: eventClassType,
                createdBy: user.id,
                lastUpdatedBy: user.id,
                createdOn: getTimestamp(),
                updatedOn: getTimestamp()
            };
            const createClassResult = await createClass(input);
            if (createClassResult.isSuccess) {
                currentClass = createClassResult.result;

                // Next, create organization class (only if this is not a part of a division (org class is only for single classes))
                if (!currentOrganizationDivision) {
                    if (event && event.organizationId) {
                        const input: CreateOrganizationClassInput = {
                            createdBy: user.id,
                            name: name,
                            classId: createClassResult.result.id,
                            // organizationClassClassId: createClassResult.result.id,
                            organizationId: event.organizationId,
                            number: 0,
                            entryFee: entryFee.toString(),
                            prizeMoney: prizeMoney.toString()
                        };
                        const createOrganizationClassResult = await createOrganizationClass(input);
                        if (createOrganizationClassResult.isSuccess) {
                            currentOrganizationClass = createOrganizationClassResult.result;
                        } else {
                            setError("A problem occurred and we could not create the class. Please refresh the page and try again.");
                            return;
                        }
                    } else {
                        setError("A problem occurred while creating the class and we could not find your organization. Please refresh the page and try again.");
                        return;
                    }
                }
            } else {
                setError("A problem occurred and we could not create the class. Please refresh the page and try again.");
                return;
            }
        } else {
            // Already has an organizationClass because the user selected it from the list.
            currentClass = organizationClass.class;
            currentOrganizationClass = organizationClass;

            // To do: update the organization class if any of the fields are changing (ie. number)
        }

        const eventClassInput: CreateEventClassInput = {
            createdBy: user.id,
            eventId: event.id,
            name: name,
            classId: currentClass.id,
            // eventClassClassId: currentClass.id,
            type: eventClassType,
            organizationClassId: currentOrganizationClass?.id,
            // eventClassOrganizationClassId: currentOrganizationClass?.id,
            eventDivisionId: currentEventDivision?.id,
            // eventClassEventDivisionId: currentEventDivision?.id,
            organizationDivisionId: currentOrganizationDivision?.id,
            // eventClassOrganizationDivisionId: currentOrganizationDivision?.id,
            number: number,
            entryFee: ((entryType === "both" || entryType === "class") ? entryFee.toString() : ""),
            prizeMoney: prizeMoney.toString(),
            eventClassPrizeMoneyTableId: prizeMoneyTable?.id,
            minNumberEntries: minNumberEntries,
            maxNumberEntries: maxNumberEntries,
            estimatedTimePerRound: calculateEstimatedTimePerRound()
        };
        const createEventClassResult = await createEventClass(eventClassInput);
        if (createEventClassResult.isSuccess) {
            const message = "Successfully created the class: " + name;
            setSuccess(message);
            clearForm();
            onSubmit(createEventClassResult.result);
        } else {
            setError(createEventClassResult.message);
        }
    }

    const handleEditClass = async (currentEventDivision?: (EventDivision | null), currentOrganizationDivision?: (OrganizationDivision | null)) => {
        if (currentClass) {
            // First update the class
            const input: UpdateClassInput = {
                id: currentClass.id,
                name: name,
                description: description,
                discipline: discipline,
                type: eventClassType,
                lastUpdatedBy: user.id,
                updatedOn: getTimestamp()
            };
            const updateClassResult = await updateClass(input);
            if (!updateClassResult.isSuccess) {
                setError("Could not update the class.");
                return;
            }

        }
        if (organizationClass && currentClass && event.organizationId) {
            // Next, update the organizationClass
            const input: UpdateOrganizationClassInput = {
                id: organizationClass.id,
                // organizationClassClassId: organizationClass.classId,
                name: name,
                number: 0,
                entryFee: entryFee.toString(),
                prizeMoney: prizeMoney.toString(),
                // organizationClassClassId: organizationClass.classId
            };
            const updateOrganizationClassResult = await updateOrganizationClass(input);
            if (!updateOrganizationClassResult.isSuccess) {
                setError("Could not update the organization class.");
                return;
            }

        }  
        if (eventClass && currentClass) {
            // Next, update the eventClass
            const eventClassInput: UpdateEventClassInput = {
                id: eventClass.id,
                createdBy: user.id,
                eventId: event.id,
                name: name,
                classId: currentClass.id,
                // eventClassClassId: currentClass.id,
                type: eventClassType,
                organizationClassId: organizationClass?.id,
                // eventClassOrganizationClassId: organizationClass?.id,
                eventDivisionId: currentEventDivision?.id || eventDivision?.id,
                // eventClassEventDivisionId: currentEventDivision?.id || eventDivision?.id,
                organizationDivisionId: currentOrganizationDivision?.id || organizationDivision?.id,
                // eventClassOrganizationDivisionId: currentOrganizationDivision?.id || organizationDivision?.id,
                number: number,
                entryFee: ((entryType === "both" || entryType === "class") ? entryFee.toString() : ""),
                prizeMoney: prizeMoney.toString(),
                eventClassPrizeMoneyTableId: prizeMoneyTable?.id,
                eventClassPointTableId: pointTable?.id,
                minNumberEntries: minNumberEntries,
                maxNumberEntries: maxNumberEntries,
                estimatedTimePerRound: calculateEstimatedTimePerRound()
            };
            const updateEventClassResult = await updateEventClass(eventClassInput);
            if (!updateEventClassResult.isSuccess) {
                setError("Could not update the event class");
            } else {
                const message = "Successfully updated the class: " + currentClass.name;
                setSuccess(message);
                clearForm();
                onSubmit(updateEventClassResult.result);
            }
        }      
    }

    const handleCreateNewPartOfDivision = async () => {
        let currentDivision;
        let currentOrganizationDivision;
        if (!organizationDivision) {
            // First create the division
            const input: CreateDivisionInput = {
                createdBy: user.id,
                name: name,
                description: description,
                discipline: discipline
            };
            const createDivisionResult = await createDivision(input);

            if (createDivisionResult.isSuccess) {
                currentDivision = createDivisionResult.result;

                // Next, create organization division
                if (event && event.organizationId) {
                    const input: CreateOrganizationDivisionInput = {
                        createdBy: user.id,
                        name: divisionName,
                        divisionId: currentDivision.id,
                        // organizationDivisionDivisionId: currentDivision.id,
                        organizationId: event.organizationId,
                        entryFee: (entryType === "both" || entryType === "division") ? divisionEntryFee.toString() : "",
                        prizeMoney: divisionPrizeMoney.toString(),
                        organizationDivisionPrizeMoneyTableId: prizeMoneyTable?.id || ""
                    };
                    const createOrganizationDivisionResult = await createOrganizationDivision(input);

                    if (createOrganizationDivisionResult.isSuccess) {
                        currentOrganizationDivision = createOrganizationDivisionResult.result;
                    } else {
                        setError("A problem occurred and we could not create the division. Please refresh the page and try again.");
                        return;
                    }
                } else {
                    setError("A problem occurred while creating the division's class and we could not find your organization. Please refresh the page and try again.");
                    return;
                }
            } else {
                setError("A problem occurred and we could not create the division. Please refresh the page and try again.");
                return;
            }
        } else {
            // Already has an organizationDivision because the user selected it from the list.
            currentDivision = organizationDivision.division;
            currentOrganizationDivision = organizationDivision;

            // To do: update the organization division if any of the fields are changing (ie. number)
        }
        if (eventDivision) {
            await handleCreateNewClass(eventDivision, currentOrganizationDivision);
        } else {
            const input: CreateEventDivisionInput = {
                name: divisionName,
                divisionId: currentDivision.id,
                eventId: event.id,
                organizationDivisionId: currentOrganizationDivision.id,
                eventDivisionOrganizationDivisionId: currentOrganizationDivision.id,
                entryFee: (entryType === "both" || entryType === "division") ? divisionEntryFee.toString() : "",
                prizeMoney: divisionPrizeMoney.toString(),
                eventDivisionPrizeMoneyTableId: prizeMoneyTable?.id,
                minNumberEntriesPerClass: minNumberEntries,
                maxNumberEntriesPerClass: maxNumberEntries,
                createdBy: user.id,
                lastUpdatedBy: user.id
            };
            const createEventDivisionResult = await createEventDivision(input);
            if (createEventDivisionResult.isSuccess) {
                setEventDivision(createEventDivisionResult.result);
                await handleCreateNewClass(createEventDivisionResult.result, currentOrganizationDivision);
            } else {
                setError(createEventDivisionResult.message);
            }
        }
    }

    const handleEditPartOfDivision = async () => {
        let updatedEventDivision: EventDivision | null | undefined;
        let updatedOrganizationDivision: OrganizationDivision | null | undefined;

        if (organizationDivision && event.organizationId) {
            // Next, update the organizationDivision
            const input: UpdateOrganizationDivisionInput = {
                id: organizationDivision.id,
                createdBy: user.id,
                name: divisionName,
                organizationId: event.organizationId,
                entryFee: (entryType === "both" || entryType === "division") ? divisionEntryFee.toString() : "",
                prizeMoney: divisionPrizeMoney.toString(),
                organizationDivisionPrizeMoneyTableId: prizeMoneyTable?.id || "",
            };
            const updateOrganizationDivisionResult = await updateOrganizationDivision(input);
            if (updateOrganizationDivisionResult.isSuccess) {
                updatedOrganizationDivision = updateOrganizationDivisionResult.result;
            } else {
                setError("Could not update the organization division.");
                return;
            }

        }  
        if (eventDivision && organizationDivision) {
            // Next, update the eventDivision
            const input: UpdateEventDivisionInput = {
                id: eventDivision.id,
                name: divisionName,
                divisionId: eventDivision.divisionId,
                eventId: event.id,
                organizationDivisionId: organizationDivision.id,
                eventDivisionOrganizationDivisionId: organizationDivision.id,
                entryFee: (entryType === "both" || entryType === "division") ? divisionEntryFee.toString() : "",
                prizeMoney: divisionPrizeMoney.toString(),
                eventDivisionPrizeMoneyTableId: prizeMoneyTable?.id,
                minNumberEntriesPerClass: minNumberEntries,
                maxNumberEntriesPerClass: maxNumberEntries,
                createdBy: user.id,
                lastUpdatedBy: user.id
            };
            const updateEventDivisionResult = await updateEventDivision(input);
            if (updateEventDivisionResult.isSuccess) {
                updatedEventDivision = updateEventDivisionResult.result;
            } else {
                setError("Could not update the event division");
                return;
            }
        } 
        await handleEditClass(updatedEventDivision, updatedOrganizationDivision);     
    }

    const handleSubmit = async () => {
        setIsLoading(true);

        const isValid = verifyForm();
        if (!isValid) {
            setIsLoading(false);
            return;
        }

        if (isPartOfDivision) {
            if (eventClass) {
                await handleEditPartOfDivision();
            } else {
                await handleCreateNewPartOfDivision();
            }
        } else {
            if (eventClass) {
                await handleEditClass();
            } else {
                await handleCreateNewClass();
            }
        }
        setIsLoading(false);
    }

    const handleDelete = async () => {
        setIsLoading(true);
        if (eventClass) {
            const deleteResult = await deleteEventClass({id: eventClass.id});
            if (deleteResult.isSuccess) {
                clearForm();
                setSuccess("Deleted the Class.");
            }
        }
        setIsDisabled(true);
        setIsLoading(false);
        onSubmit();
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            {isLoading ?
                <Spinner />
                :
                <form>
                    <IonRow>
                        <IonCol>
                            <SelectDivisionOrStandAloneClass isClassInDivision={isPartOfDivision} onSelect={handleSelectedDivisionOrClass} />
                        </IonCol>
                    </IonRow>
                    <hr/>
                    {isPartOfDivision ?
                        <>
                            <IonRow>
                                <IonCol>
                                    {template ?
                                        <>
                                            <IonLabel>
                                                <IonText>
                                                    <h2>Division Name: {divisionName}</h2>
                                                </IonText>
                                            </IonLabel>
                                            <hr/>
                                        </>
                                        :
                                        <>
                                            <IonLabel>Division <RequiredInputIndicator/></IonLabel>
                                            {event.organizationId ?
                                                <SelectEventDivision onSelect={handleDivisionSelect} selectedValue={eventDivision?.id} eventId={event.id} />
                                                :
                                                <p>Error</p>
                                            }
                                        </>
                                    }
                                </IonCol>
                            </IonRow>
                            {(divisionName || organizationDivision) && (
                                <>
                                    <SelectEntryType selectedEntryType={entryType} onSelect={handleSelectedEntryType} />
                                    {(entryType === "division" || entryType === "both") && (
                                        <>
                                            <br />
                                            <DivisionEntryFee selectedEntryFee={divisionEntryFee} onSelect={handleSelectedDivisionEntryFee} />
                                        </>
                                    )}
                                    <hr/>
                                    <IonRow>
                                        <IonCol>
                                            <p>This class maps to the Organization Class: {eventClass?.class?.name || organizationClass?.name || "unknown"}</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">Class Name <RequiredInputIndicator/></IonLabel>
                                                <IonInput 
                                                    type="text"
                                                    value={name}
                                                    onIonChange={e => {
                                                        if(isMounted) setIsDisabled(false);
                                                        else setIsMounted(true);
                                                        setName(e.detail.value!)
                                                    }}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonLabel position="stacked">Discipline <RequiredInputIndicator/></IonLabel>
                                            <SelectDiscipline selectedValue={discipline} onSelect={(value: string) => handleSelectedDiscipline(value)} />
                                        </IonCol>
                                        <IonCol>
                                            <IonLabel>Class Type</IonLabel>
                                            <SelectEventClassType selectedValue={eventClassType} onSelect={(value: string) => handleSelectedEventClassType(value)}/>
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                        </>
                        :    
                        <>
                            <IonRow>
                                <IonCol>
                                    {template ?
                                        <>
                                            <IonLabel>
                                                <IonText>
                                                    <h2>Class Name: {organizationClass?.name}</h2>
                                                </IonText>
                                            </IonLabel>
                                            <hr/>
                                        </>
                                        :
                                        <>
                                            <IonLabel>Class Template</IonLabel>
                                            {event.organizationId ?
                                                <SelectOrganizationClass organizationId={event.organizationId} selectedValue={organizationClass?.id} onSelect={handleSingleClassSelect}/>
                                                :
                                                <p>Error</p>
                                            }
                                            <hr />
                                            <IonRow>
                                                <IonCol>
                                                    <IonItem color="white">
                                                        <IonLabel position="stacked">Class Name</IonLabel>
                                                        <IonInput 
                                                            type="text"
                                                            value={name}
                                                            onIonChange={e => {
                                                                setName(e.detail.value!)
                                                            }}
                                                        />
                                                    </IonItem>
                                                </IonCol>
                                            </IonRow>
                                        </>
                                    }
                                </IonCol>
                            </IonRow>
                            {organizationClass && (
                                <IonRow>
                                    <IonCol>
                                        <IonLabel position="stacked">Discipline <RequiredInputIndicator/></IonLabel>
                                        <SelectDiscipline selectedValue={discipline} onSelect={(value: string) => handleSelectedDiscipline(value)} />
                                    </IonCol>
                                    <IonCol>
                                        <IonLabel>Class Type</IonLabel>
                                        <SelectEventClassType selectedValue={eventClassType} onSelect={(value: string) => handleSelectedEventClassType(value)}/>
                                    </IonCol>
                                </IonRow>
                            )}
                        </>
                    } 
                    {(divisionName || organizationClass || organizationDivision || !isPartOfDivision) && (
                        <>
                            <IonRow>
                                <IonCol>
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Class Number</IonLabel>
                                        <IonInput 
                                            type="number"
                                            min="0"
                                            value={number}
                                            placeholder="0"
                                            onIonChange={e => {
                                                if(isMounted) setIsDisabled(false);
                                                else setIsMounted(true);
                                                setNumber(parseInt(e.detail.value!))
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>Description</IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <Input
                                        rows="5"
                                        type="textarea"
                                        name="declineReason"
                                        value={description}
                                        spellCheck="true"
                                        onChange={(event) => setDescription(event.target.value)}
                                        data-hj-whitelist
                                    />
                                </IonCol>
                            </IonRow>
                            <br />
                            <JumpHeightOptionsForm selectedJumpHeightOptions={[]} onSelect={handleSelectedEntryNumber} />
                            <br />
                            <AllowedNumberOfEntries selectedMin={minNumberEntries} selectedMax={maxNumberEntries} onSelect={handleSelectedEntryNumber} />
                            <br />
                            <EstimatedTimePerRound selectedMin={estimatedTimePerRoundMin} selectedSec={estimatedTimePerRoundSec} onSelect={handleSelectedTimePerRound} />
                            {(entryType === "class" || entryType === "both") && (
                                <>
                                    <br />
                                    <ClassEntryFee selectedEntryFee={entryFee} onSelect={handleSelectedClassEntryFee} />
                                </>
                            )}
                            <br/>
                            {event && event.organizationId && (<ClassPointTable organizationId={event.organizationId} selectedPointTable={pointTable} onSelectPointTable={handleSelectedPointTable} />)}
                            <br/>
                            {event && event.organizationId && (<ClassPrizeMoney organizationId={event.organizationId} selectedPrizeMoney={prizeMoney} selectedPrizeMoneyTable={prizeMoneyTable} onSelectPrizeMoney={handleSelectedPrizeMoney} onSelectPrizeMoneyTable={handleSelectedPrizeMoneyTable} />)}
                        </>
                    )}
                    {error && <ErrorAlert width="12" error={error}/>}
                    <IonRow>
                        <IonCol sizeMd="12">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    // disabled={isDisabled}
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    {currentClass ? "Edit Class" : "Add To List"}
                                </IonButton>
                            }
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        {eventClass && (
                            <>
                                <IonCol sizeMd="4">
                                    {isLoading ?
                                        <Spinner />
                                        :
                                        <IonButton
                                            className="ion-margin-top"
                                            color="primary"
                                            expand="block"
                                            onClick={clearAllForm}
                                        >
                                            Reset Form
                                        </IonButton>
                                    }
                                </IonCol>
                                <IonCol offsetMd="4" sizeMd="4">
                                    {isLoading ?
                                        <Spinner />
                                        :
                                        <IonButton
                                            // disabled={isDisabled}
                                            className="ion-margin-top"
                                            color="danger"
                                            expand="block"
                                            onClick={handleDelete}
                                        >
                                            Delete
                                        </IonButton>
                                    }
                                </IonCol>
                            </>
                        )}
                    </IonRow>
                </form>
            }
        </>
    );
};

export default EventClassForm;