import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import SuccessBanner from "../Banners/SuccessBanner";
import { BarnPerson } from "../../interfaces/Person";
import { savePersonBasicInfo, savePersonRiderInfo } from "../../utilities/barn/BarnPerson"; 
import RiderSelectStatus from "../Rider/RiderSelectStatus";
import Spinner from "../Spinners/Spinner";

interface _Props {
    person: BarnPerson
    onSubmit: Function
}

const EditPersonBasicInfoForm: React.FC<_Props> = ({person, onSubmit}) => {    
    const [name, setName] = useState("");

    const [birthdate, setBirthdate] = useState("");
    const [isProfessional, setIsProfessional] = useState(false);
    
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const clearForm = () => {
        setName("");
        setBirthdate("");
        setIsProfessional(false);
    }

    useEffect(() => {
        if (person && person.name) {
            setName(person.name);
        } else {
            clearForm();
        }

        if (person.rider) {
            if (person.rider.birthdate) setBirthdate(person.rider.birthdate);
            if (person.rider.isProfessional) setIsProfessional(person.rider.isProfessional);
        }
    }, [person]);

    const handleIsProfessionalInput = (isProfessional: boolean) => {
        setIsProfessional(isProfessional);
    }

    const verifyForm = () => {
        if (!name) {
            setError("Please include a name for the person");
            return false;
        }
        return true;
    }

    const handleEditPerson = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        const isValid = verifyForm();
        if (isValid) {
            setError("");
            const result = await savePersonBasicInfo(person, name);
            if (result.isSuccess) {
                if (person.riderId) {
                    const riderResult = await savePersonRiderInfo(person, birthdate, isProfessional);
                    if (riderResult.isSuccess) {
                        setSuccess("Successfully updated the info!");
                    } else {
                        setError("Could not update the info.");
                    }
                } else {
                    if (result.isSuccess) {
                        setSuccess("Successfully updated the info!");
                    } else {
                        setError("Could not update the info.");
                        
                    }
                }
            }
            onSubmit();
        }
        setIsLoading(false);
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {success && <SuccessBanner success={success} />}
                    {error && <ErrorAlert error={error} />}
                    <IonRow>
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Full Name</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={name}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setName(e.detail.value!);
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    {person.riderId && (
                        <>
                            <IonRow>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">Birth Date</IonLabel>
                                        <IonInput 
                                            type="date"
                                            value={birthdate}
                                            aria-required={false}
                                            onIonChange={e => {
                                                setBirthdate(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <RiderSelectStatus rider={person.rider} onChange={handleIsProfessionalInput} />
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="4" className="ion-text-center">
                            <IonButton
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleEditPerson}
                            >
                                Save
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default EditPersonBasicInfoForm;