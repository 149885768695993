import { Event } from "../../models";

var moment = require("moment");

/**
 * Sorts the events from newest to oldest
 * 
 * @param data 
 * @returns events sorted from newest to oldest
 */
export function sortEventsByDate(data: Event[]) {
    try {
        let result: Event[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => new moment(b.startDate).format("YYYYMMDDHHmmss") - new moment(a.startDate).format("YYYYMMDDHHmmss"));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: event");
        console.error(error);
        return null;
    }
};