import React, { useEffect, useState } from "react";
import { Event, EventEntry } from "../../../models";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonRow } from "@ionic/react";
import DisplayEntryAgreements from "../EntrySummary/DisplayEntryAgreements";
import ViewEntryCurrentInvoice from "./ViewEntryCurrentInvoice";
import EntryPageNavbar from "../../Navbars/EntryPageNavbar";
import EditEntryAbout from "./EditEntryAbout";
import EntryNumberForm from "./EntryNumberForm";
import EditEntryFees from "./EditEntryFees";
import EditEntryClasses from "./EditEntryClasses";
import EditEntryStablingRequest from "./EditEntryStablingRequest";
import EntryCheckInForm from "./EntryCheckInForm";
import EditEntryResults from "./EditEntryResults";
import { getRiderAmateurStatus } from "../../../utilities/rider/CalculateRiderAmateurStatus";
import DisplayEntryNote from "../EntrySummary/DisplayEntryNote";
import ErrorAlert from "../../Errors/ErrorAlert";
import { removeEventEntryData } from "../../../utilities/eventEntry/RemoveEventEntryData";
import { useHistory } from "react-router";
import Spinner from "../../Spinners/Spinner";
import EntryPageInfoNavbar from "../../Navbars/EntryPageInfoNavbar";
import EditEntrySelectedHorseCard from "./EntryInfo/EntryHorseInfo/EditEntrySelectedHorseCard";
import EntryInfoOwner from "./EntryInfo/EntryInfoOwner";
import EntryInfoTrainer from "./EntryInfo/EntryInfoTrainer";
import EntryInfoBarn from "./EntryInfo/EntryInfoBarn";
import EntryInfoPrimaryContact from "./EntryInfo/EntryInfoPrimaryContact";
import EditEntryHorseDetailsCard from "./EntryInfo/EntryHorseInfo/EditEntryHorseDetailsCard";
import EditEntryHorseCogginsCard from "./EntryInfo/EntryHorseInfo/EditEntryHorseCogginsCard";
import EditEntryHorseMembershipsCard from "./EntryInfo/EntryHorseInfo/EditEntryHorseMembershipsCard";
import EditEntrySelectedRiderCard from "./EntryInfo/EntryRiderInfo/EditEntrySelectedRiderCard";
import EditEntryRiderDetailsCard from "./EntryInfo/EntryRiderInfo/EditEntryRiderDetailsCard";
import EditEntryRiderMembershipsCard from "./EntryInfo/EntryRiderInfo/EditEntryRiderMembershipsCard";
import EditEntrySelectedBarnCard from "./EntryInfo/EntryBarnInfo/EditEntrySelectedBarnCard";

interface _Props {
    event: Event
    entry: EventEntry
}

const ViewAcceptedEntryPage: React.FC<_Props> = ({event, entry}) => {
    const history = useHistory();

    const [currentEvent, setCurrentEvent] = useState<Event | null | undefined>();
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [active, setActive] = useState("about");
    const [infoNavbarActiveTab, setInfoNavbarActiveTab] = useState("general");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (entry) setCurrentEntry(entry);

        if (entry.rider) {
            getRiderAmateurStatus(entry.rider);
        }
    }, [entry]);

    useEffect(() => {
        if (event) setCurrentEvent(event)
    }, [event]);

    const handleNavbarSelect = (value: string) => {
        setActive(value);
    }

    const handleDeleteEntry = async() => {
        setError("");
        if (currentEntry) {
            setIsLoading(true);
            const result = await removeEventEntryData(currentEntry);
            if (result.isSuccess){
                setIsLoading(false);
                const path = "/index/staff/event/" + event?.id + "/participants";
                history.push(path); 
            } else {
                let errorMessage = "Error: ";
                const errors = result.result;
                if (errors.length > 0) {
                    errors.forEach((errorMsg: string) => {
                        errorMessage = errorMessage + errorMsg + " ";
                    });
                } else {
                    errorMessage = errorMessage + result;
                }
                setError(errorMessage);
                setIsLoading(false);
            }
        } else {
            setError("No entry to delete.");
        }
    }

    return (
        <>
            <IonRow>
                <IonCol>
                    <EntryPageNavbar active={active} onSelect={handleNavbarSelect} />
                </IonCol>
            </IonRow>
            {(active === "about" && currentEntry && currentEvent) && (
                <>
                    <IonRow>
                        <IonCol>
                            <EntryPageInfoNavbar active={infoNavbarActiveTab} onSelect={(selectedValue: string) => setInfoNavbarActiveTab(selectedValue)} />
                        </IonCol>
                    </IonRow>
                    {error && <ErrorAlert width="12" error={error} />}
                    {infoNavbarActiveTab === "general" && (
                        <>
                            <IonRow>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonCard color="white">
                                        <IonCardHeader>
                                            <h3>Entry Number</h3>
                                        </IonCardHeader>   
                                        <IonCardContent>
                                            <EntryNumberForm entry={currentEntry} />    
                                        </IonCardContent> 
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonCard color="white">
                                        <IonCardHeader>
                                            <h3>Status</h3>
                                        </IonCardHeader>   
                                        <IonCardContent>
                                            <EntryCheckInForm entry={currentEntry} onSubmit={(updatedEventEntry: EventEntry) => setCurrentEntry(updatedEventEntry)} />
                                        </IonCardContent> 
                                    </IonCard>
                                </IonCol>
                            </IonRow>  
                            <EditEntryAbout event={currentEvent} entry={currentEntry} />
                            <IonRow>
                                <IonCol sizeMd="12">
                                    <IonCard color="white" className="ion-padding">
                                        <IonCardContent>
                                            <p>Deleting this entry will delete all of the data associated with it including the classes and divisions it's entered in, the fees, stabling, results, rules agreements, etc.</p>
                                            <p>If you want to scratch a class from the entry, you can remove a class from the entry on the Classes tab above.</p>
                                            <IonRow>
                                                <IonCol className="ion-text-center">
                                                    {isLoading ?
                                                        <Spinner />
                                                        :
                                                        <IonButton color="danger" onClick={handleDeleteEntry}>Delete This Entry</IonButton>
                                                    }
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    {infoNavbarActiveTab === "horse" && (
                        <>
                            <IonRow>
                                <IonCol sizeXs="12" sizeMd="12">
                                    <EditEntrySelectedHorseCard entry={currentEntry} event={event} onSubmit={(updatedEntry: EventEntry) => setCurrentEntry(updatedEntry)}/>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <EditEntryHorseDetailsCard event={event} entry={currentEntry} />
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <EditEntryHorseCogginsCard event={event} entry={currentEntry} />
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="12">
                                    <EditEntryHorseMembershipsCard event={event} entry={currentEntry}/>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    {infoNavbarActiveTab === "owner" && (
                        <>
                            <EntryInfoOwner entry={currentEntry} />
                        </>
                    )}
                    {infoNavbarActiveTab === "riders" && (
                        <>
                            <IonRow>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <EditEntrySelectedRiderCard entry={currentEntry} event={event} onSubmit={(updatedEntry: EventEntry) => setCurrentEntry(updatedEntry)}/>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <EditEntryRiderDetailsCard entry={currentEntry} />
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="12">
                                    <EditEntryRiderMembershipsCard event={event} entry={currentEntry}/>
                                </IonCol> 
                            </IonRow>
                        </>
                    )}
                    {infoNavbarActiveTab === "trainer" && (
                        <>
                            <EntryInfoTrainer entry={currentEntry} />
                        </>
                    )}
                    {infoNavbarActiveTab === "barn" && (
                        <>
                            <IonRow>
                                <IonCol sizeXs="12" sizeMd="12">
                                    <EditEntrySelectedBarnCard entry={currentEntry} event={event} onSubmit={(updatedEntry: EventEntry) => setCurrentEntry(updatedEntry)}/>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="12">
                                    <EntryInfoBarn entry={currentEntry} />
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    {infoNavbarActiveTab === "contact" && (
                        <>
                            <EntryInfoPrimaryContact entry={currentEntry}/>
                        </>
                    )}
                    {infoNavbarActiveTab === "notes" && (
                        <>
                            <DisplayEntryNote entry={currentEntry} isExpanded={true}/>
                        </>
                    )}
                    {infoNavbarActiveTab === "agreements" && (
                        <>
                            <DisplayEntryAgreements event={currentEvent} entry={currentEntry} />
                        </>
                    )}
                </>
            )}
            {(active === "classes" && currentEntry && currentEvent) && (
                <EditEntryClasses event={currentEvent} key={currentEntry.id} entry={currentEntry}/>
            )}
            {(active === "fees" && currentEntry && currentEvent) && (
                <EditEntryFees event={currentEvent} key={currentEntry.id} entry={currentEntry} />
            )}
            {(active === "stabling" && currentEntry && currentEvent) && (
                <EditEntryStablingRequest event={currentEvent} key={currentEntry.id} entry={currentEntry} />
            )}
            {(active === "results" && currentEntry && currentEvent) && (
                <EditEntryResults event={currentEvent} key={currentEntry.id} entry={currentEntry} />
            )}
            {(active === "invoice" && currentEntry && currentEvent) && (
                <ViewEntryCurrentInvoice event={currentEvent} entry={currentEntry} key={currentEntry.id} onUpdateEntry={(updatedEventEntry: EventEntry) => setCurrentEntry(updatedEventEntry)} />
            )}
        </>
    );
};

export default ViewAcceptedEntryPage;