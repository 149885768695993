import {
    IonHeader,
    IonLabel,
    IonSegment,
    IonSegmentButton,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

interface _Props {
    active: string
    onSelect: Function
}

const AddBarnHorseNavbar: React.FC<_Props> = ({active, onSelect}) => {
    const [currentActiveBtn, setCurrentActiveBtn] = useState(active || "new");

    useEffect(() => {
        setCurrentActiveBtn(active);
    }, [active]);

    const handleSelection = async (selection: string) => {
        await setCurrentActiveBtn(selection);
        onSelect(selection);
    }

    return (
        <IonHeader id="addBarnHorseMenu">
            <IonSegment color="primary" mode="md" value={currentActiveBtn || active} scrollable={true} onIonChange={e => {handleSelection(e.detail.value || "new");}}>
                <IonSegmentButton value="new">
                    <IonLabel>Create New</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="move">
                    <IonLabel>Move to Barn</IonLabel>
                </IonSegmentButton>
            </IonSegment>
        </IonHeader>
    );
};

export default AddBarnHorseNavbar;
