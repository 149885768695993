import {
    IonCard,
    IonCardContent,
    IonCol,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { Barn, Event, Horse } from "../../models";
import { getHorsesByUserByBarn } from "../../utilities/horse/HorseByBarn";
import QuickAddHorseForm from "./QuickAddHorseForm";
import SelectHorse from "./SelectHorse";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    horse?: (Horse | null)
    barn?: (Barn | null)
    event?: (Event | null)
    onChange: Function
}

const HorseEntryForm: React.FC<_Props> = ({horse, barn, event, onChange}) => {
    const user = useContext(PersonContext);

    const [horseList, setHorseList] = useState<Horse[] | null | undefined>();
    const [selectedHorse, setSelectedHorse] = useState<Horse | null | undefined>();

    const [isExpanded, setIsExpanded] = useState(false);

    async function getHorses() {
        const horses = await getHorsesByUserByBarn(user, barn);
        if (horses) setHorseList(horses);
    }

    useEffect(() => {
        getHorses();
    }, []);

    useEffect(() => {
        if (barn) getHorses();
    }, [barn]);

    useEffect(() => {
        if (horse) setSelectedHorse(horse);
    }, [horse]);

    const handleHorseSelect = (horse: Horse, type?: string) => {
        if (type === "create") {
            const updatedHorseList = (horseList && horseList.length) ? horseList.concat([horse]) : [horse];
            setHorseList(updatedHorseList);
        }
        setSelectedHorse(horse);
        onChange(horse);
    }

    return (
        <>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="12">
                    <IonLabel position="stacked" color="dark">Horse/Pony <RequiredInputIndicator /> {(event && event.entryOptions && (event.entryOptions.requiresCogginsData || event.entryOptions.requiresCogginsDocument) && <p>This event requires a valid coggins. Click View More to add a coggins.</p>)}</IonLabel>
                    <SelectHorse horses={horseList} selectedValue={selectedHorse?.id} onSelect={handleHorseSelect} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="12">
                    <p className="ion-text-center link" onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? "View Less" : (selectedHorse ? "Edit Horse" : "New Horse")}</p>
                </IonCol>
            </IonRow>
            {isExpanded && (
                <IonCard color="white" className="ion-no-padding">
                    <IonCardContent className="ion-no-padding">
                        <QuickAddHorseForm event={event} horse={selectedHorse} onChange={handleHorseSelect}/>
                    </IonCardContent>
                </IonCard>
            )}
        </>
    );
};

export default HorseEntryForm;