import {
    IonButton
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import { Event, EventAuditor } from "../../models";
import { getEventAuditorsByEventId } from "../../utilities/eventAuditor/EventAuditor";
import ErrorAlert from "../Errors/ErrorAlert";
import EventAuditorModal from "../EventAuditor/EventAuditorModal";
import Spinner from "../Spinners/Spinner";

interface _Props {
    event: Event
    eventAuditor?: EventAuditor
}

const EventAuditorSignUpButton: React.FC<_Props> = ({event, eventAuditor}) => {    
    const [eventAuditors, setEventAuditors] = useState<EventAuditor[] | null | undefined>();
    const [selectedEventAuditor, setSelectedEventAuditor] = useState<EventAuditor | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEventAuditors(event: Event) {
            const queryResult = await getEventAuditorsByEventId(event.id);
            if (queryResult.isSuccess) {
                const eventAuditors: EventAuditor[] = queryResult.result;
                setEventAuditors(eventAuditors);
            }
        }
        if (event) {
            getEventAuditors(event);
        }
    }, [event]);

    useEffect(() => {
        if (eventAuditor) {
            if (eventAuditors) {
                const updated = eventAuditors.concat(eventAuditor);
                setEventAuditors(updated);
            } else {
                setEventAuditors([eventAuditor]);
            }
        }
    }, [eventAuditor]);

    const handleSelectedSubscriber = (eventAuditor: EventAuditor) => {
        setSelectedEventAuditor(eventAuditor);
        setShowModal(true);
    }

    const handleEventAuditorChange = (action: string, eventAuditor: EventAuditor) => {
        let updated: EventAuditor[] = [eventAuditor];
        if (action === "create") {
            if (eventAuditors) updated = updated.concat(eventAuditors);
        } else if (action === "delete") {
            if (eventAuditors) {
                const deletedIndex = eventAuditors.findIndex(eA => eventAuditor.id === eA.id);
                updated = [
                    ...eventAuditors.slice(0, deletedIndex),
                    ...eventAuditors.slice(deletedIndex + 1)
                ];
            }
        } else {
            if (eventAuditors) {
                const index = eventAuditors.findIndex(auditor => auditor.id === eventAuditor.id);
                updated = [
                    ...eventAuditors.slice(0, index),
                    eventAuditor,
                    ...eventAuditors.slice(index + 1)
                ];
            } 
        }
        setEventAuditors(updated);
        setShowModal(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ?
                <Spinner />
                :
                <>
                    <IonButton color="success" onClick={() => setShowModal(true)}>Get Alerts</IonButton>
                </>
            }
            <EventAuditorModal show={showModal} eventAuditor={selectedEventAuditor} event={event} onChange={(action: string, eventAuditor: EventAuditor) => handleEventAuditorChange(action, eventAuditor)} />
        </>
    );
};

export default EventAuditorSignUpButton;
