import React, { useEffect, useState } from "react";
import { EventClassEntry, EventEntry } from "../../models";
import { getEventClassEntriesByEventIdEntryId } from "../../utilities/eventClassEntry/EventClassEntry";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    eventEntry: EventEntry
    menuPlacement?: (string | null)
    selectedValue?: (string | null)
    onSelect: Function
}

const SelectEventClassEntryClasses: React.FC<_Props> = ({eventEntry, selectedValue, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedEventClassEntryClasses, setFormattedEventClassEntryClasses] = useState<formattedOption[] | null | undefined>();

    const formatEventClassEntryEntries = (eventClassEntries: EventClassEntry[]) => {
        // First, sort the array by class number
        eventClassEntries = eventClassEntries.sort((a, b) => (a.eventClass?.number || 0) - (b.eventClass?.number || 0));

        // Then, format the array
        let formattedEventClassEntryClasses = [];
        for (var i = 0; i < eventClassEntries.length; i++) {
            const currentClassEntry = eventClassEntries[i];
            const label = currentClassEntry.eventClass?.number + " - " + currentClassEntry.eventClass?.name;
            let object = {
                value: currentClassEntry.id,
                label: label,
                object: currentClassEntry
            };
            formattedEventClassEntryClasses.push(object);
        }
        setFormattedEventClassEntryClasses(formattedEventClassEntryClasses);
    }

    useEffect(() => {
        async function getEventClassEntryClasses(eventEntry: EventEntry) {
            const queryResult = await getEventClassEntriesByEventIdEntryId(eventEntry.eventId, eventEntry.id);
            if (queryResult.isSuccess) {
                formatEventClassEntryEntries(queryResult.result);
            }
        }

        // If values are not already set, get the class entries
        if (!formattedEventClassEntryClasses) {
            if (eventEntry) getEventClassEntryClasses(eventEntry);
        }

        // When a new class has been chosen, reset the form
        setSelected(undefined);
    }, [eventEntry]);

    useEffect(() => {
        setSelected(selectedValue);
    }, [selectedValue]);

    const handleOnChange = (event?: any) => {
        if (event) {
            onSelect(event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedEventClassEntryClasses ?
                <BasicSelect formattedValues={formattedEventClassEntryClasses} selectedValue={selected} menuPlacement={menuPlacement} onSelect={handleOnChange}/>
                :
                <p>Did not find any entries...</p>
            }
        </>
    );
};

export default SelectEventClassEntryClasses;
