/**
 * https://ionicframework.com/docs/react/slides
 */

import SwiperCore, {Autoplay, Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IonicSwiper } from '@ionic/react';
import React, { useEffect, useState } from "react";
import { IonRow } from '@ionic/react';

import 'swiper/swiper-bundle.min.css';
import '@ionic/react/css/ionic-swiper.css';
 
import maddie from "../../assets/img/general/Maddie.jpg";
import tamra from "../../assets/img/general/Tamra.jpg";
import katieC from "../../assets/img/general/KatieCooper.jpeg";
import jessi from "../../assets/img/general/Jessi.jpeg";
import katieB from "../../assets/img/general/Katie.jpeg";
import Testimonial from '../Testimonials/Testimonial';
 
SwiperCore.use([Autoplay, IonicSwiper, Pagination]);

export interface CarouselSlide {
    title: string
    description: string
    body: string
    image: string
}
 
 const carouselItems: CarouselSlide[] = [
    {
        title: "Katie Cooper",
        description: "Trainer / Professional",
        body: "The text messages that we received throughout the day enabled us to be informed and therefore prompt to the ring. They really helped us avoid wasting staff efforts and time and therefore reduced everyone's anxiety. I implore management to please utilize this incredible resource.",
        image: katieC
    },
    {
        title: "Tamara Wetherbee",
        description: "Show Secretary",
        body: "I love this software. Period. I have been a trainer, an exhibitor and the show secretary for a large Virginia Associate show circuit. I had a chance to see first-hand how Emma has taken information from ALL sides - riders, trainers, show managers and secretaries. This software will change the way you horse show.",
        image: tamra
    },
    {
        title: "Maddie Bonner",
        description: "Trainer / Professional",
        body: "As a professional, RingSide Pro is such a convenience when we are all back at the barn taking care of our horses. If something changes with the ring timing or class updates, you immediately know with a text on your phone. There’s nothing else like it! It has become an integral part of our horse showing routine.",
        image: maddie
    },
    {
        title: "Jessica Lohman",
        description: "Show Manager / Exhibitor",
        body: "Having not just the text alerts but also the full service option (a RSP rep at the show sending alerts) played such a huge role in the success of our show. With 5 rings and so many moving parts, I absolutely intend to use the full service option again next year!! That’s a no brainer. RingSide Pro made it all so easy!",
        image: jessi
    },
    {
        title: "Katie Benson",
        description: "Show Manager / Secretary",
        body: "I love the system! It is easy, quick and the QR code provided really helped to get people hooked up easily. Now I just need to get everyone signed up!",
        image: katieB
    },
];
 
const TestimonailCarousel: React.FC = () => {

    const [items, setItems] = useState<CarouselSlide[]>([]);

    useEffect(() => {
        setItems(carouselItems);
    }, [carouselItems]);

    return (
        <IonRow className="ion-align-items-stretch">
            {(items && items.length > 0) ?
                <Swiper 
                    slidesPerView={4} 
                    spaceBetween={30} 
                    breakpoints={{
                        // when window width is >= 320px
                        1: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        },
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 20
                        },
                        // when window width is >= 480px
                        480: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        }
                    }}
                    centeredSlides={true}
                    loop={true}
                    autoplay={true}
                    pagination={{"clickable": true}} 
                    className="mySwiper"
                >
                    {items.map((item, index) => (
                        <SwiperSlide key={index} className="rsp-swiper-slide">
                            <div className="mb-4">
                                <Testimonial image={item.image} name={item.title} label={item.description} quote={item.body} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                :
                <p className="ion-text-center text-white">Loading ...</p>
            }
        </IonRow>
    );
};

export default TestimonailCarousel;
