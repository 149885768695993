import { Event, EventClass, EventDivision, EventEntry, Membership, OrganizationDivision, PointTable, Rider } from "../../models";
import { formatStartEndDatesStrings } from "../dates/FormatDates";
import { getEventClassesByEventId } from "../eventClass/EventClass";
import { getFormattedEntryClasses } from "../eventClass/FormattedEventClasses";
import { formattedOptionGroup } from "../../components/EventClass/EventClassCheckList";
import { getEventResultsByEventClassId, getEventResultsByEventDivisionId } from "../eventResult/EventResult";
import { EventResult, Horse } from "../../API";
import { getHorseById } from "../horse/Horse";
import { getRiderById } from "../rider/Rider";
import { getEventDivisionById } from "../eventDivision/EventDivision";
import { getPointTableById } from "../pointTable/PointTable";
import moment from "moment";
import { getPointsFromEventResult, getYearlyPointDataByOrganizationDivisionId, getYearlyPointDataByOrganizationId } from "../eventResult/OrganizationPointData";
import { FormattedAccumulatedPointData, FormattedPointData } from "../../interfaces/Points";
import { getEventById, getEventsByOrganizationId } from "../events/Event";
import { getMembershipsByOrganizationId } from "../membership/Membership";
import { getEventEntriesByEventId } from "../eventEntry/EventEntry";
import { downloadCSVFile } from "./ReportDownloadCSV";
import { addFooters } from "./ReportFooter";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

const getPointValueFromResult = (pointTable: PointTable, result: EventResult) => {
    const placeValue = result.place;
    let pointValue = "0";
    if (pointTable && pointTable.tiers && pointTable.tiers[0]) {
        const tier = pointTable.tiers[0];
        if (placeValue === 1) pointValue = tier["first"] || "0";
        if (placeValue === 2) pointValue = tier["second"] || "0";
        if (placeValue === 3) pointValue = tier["third"] || "0";
        if (placeValue === 4) pointValue = tier["fourth"] || "0";
        if (placeValue === 5) pointValue = tier["fifth"] || "0";
        if (placeValue === 6) pointValue = tier["sixth"] || "0";
        if (placeValue === 7) pointValue = tier["seventh"] || "0";
        if (placeValue === 8) pointValue = tier["eighth"] || "0";
        if (placeValue === 9) pointValue = tier["ninth"] || "0";
        if (placeValue === 10) pointValue = tier["tenth"] || "0";
        if (placeValue === 11) pointValue = tier["eleventh"] || "0";
        if (placeValue === 12) pointValue = tier["twelfth"] || "0";
    }
    return pointValue;
}

export async function generateClassPointsReport(event: Event, download?: boolean) {
    // initialize jsPDF
    const doc = new jsPDF();

    const queryResult = await getEventClassesByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventClasses = queryResult.result as EventClass[];
        const formattedEventClassesResult = await getFormattedEntryClasses(eventClasses);
        if (formattedEventClassesResult && formattedEventClassesResult.isSuccess) {
            const formattedEventClasses = formattedEventClassesResult.result;
            if (formattedEventClasses && formattedEventClasses.length > 0) {
                doc.page=2;
                for (let i = 0; i < formattedEventClasses.length; i++) {
                    let pointTotalMap = new Map<number, number>();
                    let currentYIndex = 25;
                    const currentOptionGroup: formattedOptionGroup = formattedEventClasses[i];
                    let classList: EventClass[] = [];
                    if (currentOptionGroup.division) {
                        const division = currentOptionGroup.division;
                        const eventDivision: EventDivision = division.object;

                        doc.setFontSize(16);
                        doc.text(`Division: ${eventDivision.name}`, 14, currentYIndex, {
                            maxWidth: 100,
                            align: "left"
                        });
                        doc.setFontSize(12);
                        doc.text(`Show Name: ${event.name}`, 200, currentYIndex, {
                            maxWidth: 100,
                            align: "right"
                        });
                        currentYIndex = currentYIndex + 10;
                        doc.text(`Show Date(s): ${formatStartEndDatesStrings(event.startDate, event.endDate)}`, 200, currentYIndex, {
                            maxWidth: 100,
                            align: "right"
                        });

                        doc.setFontSize(12);
                        if (eventDivision.isCombined) {
                            doc.text(`Was this division combined with another?: Yes`, 14, currentYIndex);
                            currentYIndex = currentYIndex + 5;
                            if (eventDivision.combinedEventDivision) {
                                doc.text(`If yes, specify category: ${eventDivision.combinedEventDivision?.name || ""}`, 14, currentYIndex);
                            } else if (eventDivision.combinedEventDivisionId) {
                                const combinedEventDivisionIdQueryResult = await getEventDivisionById(eventDivision.combinedEventDivisionId);
                                if (combinedEventDivisionIdQueryResult.isSuccess) {
                                    const combinedEventDivision: EventDivision = combinedEventDivisionIdQueryResult.result;
                                    doc.text(`If yes, specify: ${combinedEventDivision?.name || ""}`, 14, currentYIndex);
                                }
                            } else {
                                doc.text(`If yes, specify: _____________________________________`, 14, currentYIndex);
                            }
                            currentYIndex = currentYIndex + 10;
                        } else {
                            doc.text(`Was this division combined with another?: __________________`, 14, currentYIndex);
                            currentYIndex = currentYIndex + 5;
                            doc.text(`If yes, specify: _____________________________________`, 14, currentYIndex);
                            currentYIndex = currentYIndex + 10;
                        }

                        doc.setFontSize(12);

                        let champion = "";
                        let reserve = "";
                        const divisionResultsQueryResult = await getEventResultsByEventDivisionId(eventDivision.id);
                        if (divisionResultsQueryResult.isSuccess) {
                            const divisionResults: EventResult[] = divisionResultsQueryResult.result;
                        
                            const championResult = divisionResults.find(result => result.place === 1);
                            if (championResult) {
                                champion = championResult.entry.horseName || "";
                            }
                            const reserveResult = divisionResults.find(result => result.place === 2);
                            if (reserveResult) {
                                reserve = reserveResult.entry.horseName || "";
                            }
                        }
                        
                        const championTableColumns: string[] = ["", ""];
                        const championTableRows = [];
                        championTableRows.push(["Champion", champion]);
                        championTableRows.push(["Reserve", reserve]);
                        doc.autoTable(championTableColumns, championTableRows, { 
                            theme: "grid",
                            headStyles: {fillColor: "#73a4d3"},
                            startY: currentYIndex
                        });
                        currentYIndex = doc.lastAutoTable.finalY + 10;

                        const eventClasses = currentOptionGroup.classes;
                        if (eventClasses && eventClasses.length > 0) {
                            for (let index = 0; index < eventClasses.length; index++) {
                                const currentEventClassOption = eventClasses[index];
                                const currentEventClass: EventClass = currentEventClassOption.object;
                                classList.push(currentEventClass);
                            }
                        }
                        
                    } else {
                        const currentClass = currentOptionGroup.class;
                        if (currentClass) {
                            const eventClass: EventClass = currentClass.object;
                            doc.setFontSize(16);
                            doc.text(`Class: #${eventClass.number} ${eventClass.name}`, 14, currentYIndex, {
                                maxWidth: 100,
                                align: "left"
                            });
                            doc.setFontSize(12);
                            doc.text(`Show Name: ${event.name}`, 200, currentYIndex, {
                                maxWidth: 100,
                                align: "right"
                            });
                            currentYIndex = currentYIndex + 10;
                            doc.text(`Show Date(s): ${formatStartEndDatesStrings(event.startDate, event.endDate)}`, 200, currentYIndex, {
                                maxWidth: 100,
                                align: "right"
                            });
                            doc.setFontSize(12);
                            doc.text(`Number of Entries: ${eventClass.currentNumberEntries || ""}`, 14, currentYIndex);
                            currentYIndex = currentYIndex + 10;
                            classList.push(eventClass);
                        }
                    }

                    // define the columns we want and their titles
                    const tableColumn = ["Place", "Points", "Entry #", "Horse", "Rider"];

                    if (classList && classList.length > 0) {
                        for (let j = 0; j < classList.length; j++) {
                            const currentEventClass: EventClass = classList[j];

                            // define an empty array of rows
                            const tableRows: string[][] = [];

                            if (classList.length > 1) {
                                if (currentYIndex > 220) {
                                    doc.setFontSize(10);
                                    doc.text(155,285, 'page ' + doc.page); //print number bottom right
                                    doc.addPage();
                                    doc.page ++;
                                    currentYIndex = 25;
                                }
                                doc.setFontSize(14);
                                doc.text(`Class: #${currentEventClass.number} ${currentEventClass.name}`, 14, currentYIndex);
                                currentYIndex = currentYIndex + 5;
                                doc.setFontSize(12);
                                doc.text(`Number of Entries: ${currentEventClass.currentNumberEntries || ""}`, 14, currentYIndex);
                                currentYIndex = currentYIndex + 5;
                            }

                            doc.setFontSize(12);
                            if (currentEventClass.note) {
                                doc.text(`Notes: ${currentEventClass.note}`, 14, currentYIndex);
                                currentYIndex = currentYIndex + 5;
                            }
                            
                            let pointTable: PointTable | null | undefined = null;
                            pointTable = currentEventClass.pointTable; // may not have the tiers
                            if (pointTable) {
                                const pointTableResult = await getPointTableById(pointTable.id);
                                if (pointTableResult.isSuccess) {
                                    pointTable = pointTableResult.result; //should have the tiers
                                }
                            }

                            const dataResult = await getEventResultsByEventClassId(currentEventClass.id);
                            if (dataResult.isSuccess) {
                                const results: EventResult[] = dataResult.result;
                                if (results && results.length > 0) {
                                    for (let k = 0; k < results.length; k++) {
                                        const result: EventResult = results[k];
                                        let pointValue: string = "0";
                                        if (pointTable) pointValue = getPointValueFromResult(pointTable, result);
                                        let currentPointTotal = pointTotalMap.get(result.entry.number || 0) || 0;
                                        pointTotalMap.set(result.entry.number || 0, currentPointTotal + parseFloat(pointValue));
                                        let place = result.place ? result.place?.toString() : "";
                                        let entryNumber = result.entry.number ? result.entry.number.toString() : "";
                                        let horseName = result.entry.horse?.name || result.entry.horseName || "";
                                        let riderName = result.entry.rider?.name || result.entry.riderName || "";

                                        if (!horseName || horseName === "") {
                                            if (result.entry.horseId) {
                                                const horseQueryResult = await getHorseById(result.entry.horseId);
                                                if (horseQueryResult.isSuccess) {
                                                    const horse: Horse = horseQueryResult.result;
                                                    horseName = horse.name;
                                                }
                                            }
                                        }

                                        if (result.eventClassEntry && result.eventClassEntry?.riderId) {
                                            const riderQueryResult = await getRiderById(result.eventClassEntry?.riderId);
                                            if (riderQueryResult.isSuccess) {
                                                const rider: Rider = riderQueryResult.result;
                                                riderName = rider?.name || "";
                                            }
                                        }

                                        const row = [
                                            place,
                                            pointValue,
                                            entryNumber,
                                            horseName,
                                            riderName,
                                        ];
                                        if (place && place !== "0") tableRows.push(row);
                                    }

                                    // Event Class Results Table
                                    doc.autoTable(tableColumn, tableRows, { 
                                        theme: "grid",
                                        headStyles: {fillColor: "#73a4d3"},
                                        startY: currentYIndex
                                    });

                                    currentYIndex = doc.lastAutoTable.finalY + 10;
                                } else {
                                    doc.text(`No results found for this class.`, 14, currentYIndex);
                                    currentYIndex = currentYIndex + 10;
                                }
                            } else {
                                doc.text(`No results found for this class.`, 14, currentYIndex);
                                currentYIndex = currentYIndex + 10;
                            }
                        }

                        if (currentOptionGroup.division) {
                            const pointTableColumn = ["Total Points", "Entry #"];
                            let pointTableRows: any[] = [];
                            pointTotalMap.forEach((element, value) => {
                                const newRow = [
                                    element,
                                    value
                                ];
                                pointTableRows.push(newRow);
                            });

                            const sortedRows = pointTableRows.sort((a, b) => b[0] - a[0]);
                            // Event Class Results Table
                            doc.autoTable(pointTableColumn, sortedRows, { 
                                theme: "grid",
                                headStyles: {fillColor: "#73a4d3"},
                                startY: currentYIndex
                            }); 

                            currentYIndex = doc.lastAutoTable.finalY + 10;
                        }
                    }

                    doc.setFontSize(10);
                    doc.text(155,285, 'page ' + doc.page); //print number bottom right
                    doc.addPage();
                    doc.page ++;
                }
            }
        }
    }

    if (download) doc.save(`Points_By_Class_Report.pdf`);

    var blobPDF =  new Blob([ doc.output() ], { type : 'application/pdf'});
    return blobPDF;
}

export async function generatePointsByYearReport(organizationId: string, selectedYear?: string, isCSV?: boolean, isOnlyChampAndReserve?: boolean, isMembersOnly?: boolean, progressCallbackFunction?: Function) {
    // First, get the point data 
    const formattedData: FormattedPointData = await getYearlyPointDataByOrganizationId(organizationId, undefined, progressCallbackFunction, isMembersOnly);
    const yearlyData = formattedData.yearlyData;

    // Next, store any membership data for the organization
    let organizationMemberships: Membership[] = [];
    const queryResult = await getMembershipsByOrganizationId(organizationId);
    if (queryResult.isSuccess) {
        const allOrgMemberships: Membership[] = queryResult.result;
        
        if (selectedYear) {
            const currentOrgMemberships = allOrgMemberships.filter(mem => mem.createdOn.includes(selectedYear));
            organizationMemberships = currentOrgMemberships;
        } else {
            organizationMemberships = allOrgMemberships
        }
    }

    // Use the same column names for PDF or CSV
    const tableColumns = [
        "Back Number",
        "Barn Name",
        "Horse Name",
        "Rider Name",
        "Shows Attended",
        "Meetings Attended",
        "Volunteer Hours",
        "Total Points"
    ];
    // Add two extra columns in the CSV
    const csvColumns = ["Division/Class Number", "Division/Class", "Place"].concat(tableColumns);

    // Set up both PDF and CSV
    let rows = [];
    rows.push(csvColumns);
    const doc = new jsPDF();
    doc.text(`Yearly Points Report`, 14, 20);
    doc.setFontSize(10);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    if (yearlyData) {
        for (let i = 0; i < yearlyData.length; i++) {
            const dataArray: FormattedAccumulatedPointData[] = yearlyData[i];
            
            const firstElement: FormattedAccumulatedPointData = dataArray[0];
            const number: string = firstElement.eventClassNumber || firstElement.eventDivisionNumber || "";
            const name: string = firstElement.eventClassName || firstElement.eventDivisionName || "";
            
            let yCoord: number = 35;
            doc.setFontSize(12);
            doc.text(`${number ? number + " - " : ""} ${name ? name : ""}`, 14, yCoord);
            yCoord = yCoord + 10;
            
            const tableRows: any[] = [];

            for (let j = 0; j < dataArray.length; j++) {
                const data = dataArray[j];

                let barnName = data.barnName;
                if (!barnName || barnName === "") {
                    const found = organizationMemberships.find(membership => membership.backNumber === (data.backNumber ? parseInt(data.backNumber) : 0));
                    if (found) barnName = found.barn?.name;
                }

                const newRow: string[] = [
                    data.backNumber || "",
                    barnName || "",
                    data.horseName || "",
                    data.riderName || "",
                    data.showsAttended,
                    data.meetingsAttended || "",
                    data.volunteerHours || "",
                    data.pointsEarned
                ];
                tableRows.push(newRow);

                // Add data to the CSV version
                const newCSVRow = [
                    data.eventDivisionNumber || data.eventClassNumber || "",
                    data.eventDivisionName || data.eventClassName || "",
                    j+1 //Current place in the rankings
                ];
                const combinedCSVRow = newCSVRow.concat(newRow);
                rows.push(combinedCSVRow);

                // If you've hit the second row, may need to break
                if (isOnlyChampAndReserve && j >= 1) {
                    break;
                }
            }

            // Next, print out the table of stall info
            doc.autoTable(tableColumns, tableRows, { 
                theme: "grid",
                headStyles: {fillColor: "#73a4d3"},
                startY: yCoord,
                rowPageBreak: "avoid"
            }); 
            doc.addPage();           
        }        
    }  
    
    if (progressCallbackFunction) progressCallbackFunction(100);

    if (isCSV) {
        const fileName = "Points_By_Year.csv";
        downloadCSVFile(fileName, rows);
    } else {
        const fileName = "Points_By_Year.pdf";
        doc.save(fileName);
    }
}

export async function generatePointsByYearWithPointBreakdownReport(organizationId: string, selectedYear?: string, isMembersOnly?: boolean, isFindDuplicates?: boolean, progressCallback?: Function) {
    // initialize jsPDF
    const doc = new jsPDF("l");
    
    doc.text(`Yearly Points Report - With Breakdown`, 14, 20);
    doc.setFontSize(10);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    const formattedData: FormattedPointData = await getYearlyPointDataByOrganizationId(organizationId, selectedYear, progressCallback, isMembersOnly);

    const yearlyData = formattedData.yearlyData;

    // Next, store any membership data for the organization
    let organizationMemberships: Membership[] = [];
    const queryResult = await getMembershipsByOrganizationId(organizationId);
    if (queryResult.isSuccess) {
        const allOrgMemberships: Membership[] = queryResult.result;
        
        if (selectedYear) {
            const currentOrgMemberships = allOrgMemberships.filter(mem => mem.createdOn.includes(selectedYear));
            organizationMemberships = currentOrgMemberships;
        } else {
            organizationMemberships = allOrgMemberships
        }
    }

    const tableColumns = [
        "Back #",
        "Barn Name",
        "Horse Name",
        "Rider Name",
        "Show Date",
        "Event",
        "Place",
        "Set",
        "Points",
    ];

    if (isFindDuplicates) tableColumns.push("Flag");

    const eventMap = new Map<string, Event>(); //Store seen events here to avoid getting them each time to check the date(s)

    if (yearlyData) {
        for (let i = 0; i < yearlyData.length; i++) {
            const dataArray: FormattedAccumulatedPointData[] = yearlyData[i];
            
            const firstElement: FormattedAccumulatedPointData = dataArray[0];
            const number: string = firstElement.eventClassNumber || firstElement.eventDivisionNumber || "";
            const name: string = firstElement.eventClassName || firstElement.eventDivisionName || "";
            
            let yCoord: number = 35;
            doc.setFontSize(12);
            doc.text(`${number ? number + " - " : ""} ${name ? name : ""}`, 14, yCoord);
            yCoord = yCoord + 10;
            
            const tableRows: any[] = [];

            for (let j = 0; j < dataArray.length; j++) {
                const data: FormattedAccumulatedPointData = dataArray[j];

                let barnName = data.barnName;
                if (!barnName || barnName === "") {
                    const found = organizationMemberships.find(membership => membership.backNumber === (data.backNumber ? parseInt(data.backNumber) : 0));
                    if (found) barnName = found.barn?.name;
                }
                
                let rowDataArray = [];

                const eventResultArray = data.eventResults;
                if (eventResultArray && eventResultArray.length > 0) {
                    for (let k = 0; k < eventResultArray.length; k++) {
                        const currentEventResult = eventResultArray[k];
                        let eventDate = "";
                        const eventMapResult = eventMap.get(currentEventResult.eventId);
                        if (eventMapResult) {
                            // Event was already put in the map - get the date
                            eventDate = moment(eventMapResult.startDate).format("MM/DD/YYYY");
                        } else {
                            // Event was NOT already put in the map - get the event
                            const eventQuery = await getEventById(currentEventResult.eventId);
                            if (eventQuery.isSuccess) {
                                const event: Event = eventQuery.result;
                                // Get the event date
                                eventDate = moment(event.startDate).format("MM/DD/YYYY");
                                // Put the event in the map
                                eventMap.set(event.id, event);
                            }
                        }

                        let label = "";
                        if (currentEventResult.eventClass) {
                            label = currentEventResult.eventClass.number + " - " + currentEventResult.eventClass.name;
                        } else if (currentEventResult.eventDivision) {
                            label = "Division: " + (currentEventResult.eventDivision.number ? currentEventResult.eventDivision.number + " - " : "") + currentEventResult.eventDivision.name;
                        }

                        const currentPointsEarned = await getPointsFromEventResult(currentEventResult);

                        const rowData: any = {
                            backNumber: data.backNumber,
                            barnName: barnName || "",
                            horseName: data.horseName,
                            riderName: data.riderName, 
                            eventDate: eventDate,
                            label: label,
                            place: currentEventResult.place,
                            set: currentEventResult.resultSet,
                            points: currentPointsEarned
                        };

                        rowDataArray.push(rowData);
                    }
                }

                const backlogResultArray = data.backlogResults;
                if (backlogResultArray && backlogResultArray.length > 0) {
                    for (let k = 0; k < backlogResultArray.length; k++) {
                        const currentBacklogResult = backlogResultArray[k];

                        const rowData: any = {
                            backNumber: data.backNumber,
                            barnName: barnName || "",
                            horseName: data.horseName,
                            riderName: data.riderName, 
                            eventDate: moment(currentBacklogResult.eventStartDate).format("MM/DD/YYYY"),
                            label: currentBacklogResult.eventClassName,
                            points: currentBacklogResult.pointsEarned
                        };

                        rowDataArray.push(rowData);
                    }
                }

                const sortedRowDataArray = rowDataArray.sort(function(a, b): number {
                    const dateResult = (moment(a.eventDate).format("YYYYMMDDHHmmss").localeCompare(moment(b.eventDate).format("YYYYMMDDHHmmss")));
                    const labelResult = (a.label.localeCompare(b.label));
                    const setResult = ((a.set || "1").localeCompare(b.set || "1"));
                    return dateResult || labelResult || setResult;
                });

                let backNumberTracker = "";
                let eventDateTracker = "";
                let eventClassTracker = "";
                let eventClassPointsTracker = "";
                let setTracker = "";
                let pointTracker = 0;
                for (let l = 0; l < sortedRowDataArray.length; l++) {
                    const currentRow = sortedRowDataArray[l];
                    let newRow: any[] = [];

                    if (currentRow.backNumber === backNumberTracker) {
                        // Skip the row input with back number and rider info
                        newRow = [
                            "",
                            "",
                            "",
                            "",
                            currentRow.eventDate,
                            currentRow.label,
                            currentRow.place || "",
                            currentRow.set || "",
                            currentRow.points
                        ];

                        if (isFindDuplicates) {
                            // Check if this should be flagged
                            const isSameEventData = eventDateTracker === currentRow.eventDate;
                            const isSameClass = eventClassTracker === currentRow.label;
                            const isSamePoints = eventClassPointsTracker === currentRow.points;
                            const isSameSet = setTracker === currentRow.set;
                            if (isSameEventData && isSameClass && isSamePoints && isSameSet) {
                                newRow.push("FLAGGED");
                            }
                        }

                    } else if (currentRow.backNumber !== backNumberTracker) {
                        newRow = [
                            currentRow.backNumber,
                            currentRow.barnName,
                            currentRow.horseName,
                            currentRow.riderName,
                            currentRow.eventDate,
                            currentRow.label,
                            currentRow.place || "",
                            currentRow.set || "",
                            currentRow.points
                        ];
                    }

                    // At the end of each row, track accumulated points
                    pointTracker = pointTracker + (currentRow.points || 0);
                    backNumberTracker = currentRow.backNumber;
                    eventDateTracker = currentRow.eventDate;
                    eventClassTracker = currentRow.label;
                    eventClassPointsTracker = currentRow.points;
                    setTracker = currentRow.set;
                    
                    tableRows.push(newRow);
                }  
                // Conclude last rider's point total
                const totalRow = [
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "Total: ",
                    pointTracker.toString()
                ];
                tableRows.push(totalRow);       
            }

            // Next, print out the table of stall info
            doc.autoTable(tableColumns, tableRows, { 
                theme: "grid",
                headStyles: {fillColor: "#73a4d3"},
                startY: yCoord,
                rowPageBreak: "avoid"
            }); 
            doc.addPage();
        }        
    }

    doc.save(`Points_By_Year_With_Breakdown.pdf`);
}

export async function generatePointsByDivisionByYearReport(organizationDivision: OrganizationDivision, selectedYear?: string) {
    // initialize jsPDF
    const doc = new jsPDF();
    
    doc.text(`Yearly Points Report`, 14, 20);
    doc.setFontSize(10);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    const formattedData: FormattedPointData = await getYearlyPointDataByOrganizationDivisionId(organizationDivision, organizationDivision.organizationId, selectedYear);

    const yearlyData = formattedData.yearlyData;

    const tableColumns = [
        "Back Number",
        "Horse Name",
        "Rider Name",
        "Shows Attended",
        "Meetings Attended",
        "Volunteer Hours",
        "Total Points"
    ];

    if (yearlyData) {
        for (let i = 0; i < yearlyData.length; i++) {
            const dataArray: FormattedAccumulatedPointData[] = yearlyData[i];
            
            const firstElement: FormattedAccumulatedPointData = dataArray[0];
            const number: string = firstElement.eventClassNumber || firstElement.eventDivisionNumber || "";
            const name: string = firstElement.eventClassName || firstElement.eventDivisionName || "";
            
            let yCoord: number = 35;
            doc.setFontSize(12);
            doc.text(`${number ? number + " - " : ""} ${name ? name : ""}`, 14, yCoord);
            yCoord = yCoord + 10;
            
            const tableRows: any[] = [];

            for (let j = 0; j < dataArray.length; j++) {
                const data = dataArray[j];
                
                const newRow = [
                    data.backNumber,
                    data.horseName,
                    data.riderName,
                    data.showsAttended,
                    data.meetingsAttended,
                    data.volunteerHours,
                    data.pointsEarned
                ];
                tableRows.push(newRow);
            }

            // Next, print out the table of stall info
            doc.autoTable(tableColumns, tableRows, { 
                theme: "grid",
                headStyles: {fillColor: "#73a4d3"},
                startY: yCoord,
                rowPageBreak: "avoid"
            }); 
            doc.addPage();           
        }        
    }

    doc.save(`Points_By_Year.pdf`);
}

export async function generateMemberListOfShowsReport(organizationId: string, selectedYear?: string, isMismatchedReport?: boolean, progressCallbackFunction?: Function) {
    if (progressCallbackFunction) progressCallbackFunction(10);

    // Fist, store any membership data for the organization
    let organizationMemberships: Membership[] = [];
    const queryResult = await getMembershipsByOrganizationId(organizationId);
    if (progressCallbackFunction) progressCallbackFunction(15);
    if (queryResult.isSuccess) {
        const allOrgMemberships: Membership[] = queryResult.result;
        if (selectedYear) {
            const currentOrgMemberships = allOrgMemberships.filter(mem => mem.createdOn.includes(selectedYear));
            organizationMemberships = currentOrgMemberships;
        } else {
            organizationMemberships = allOrgMemberships;
        }
    }

    if (progressCallbackFunction) progressCallbackFunction(20);

    // Next, create a map to store membership data and event attendance data
    let memberEventsAttendedMap = new Map<number, Event[]>(); //key is backNumber and value is the list of events attended

    // Next, store any events data for the organization
    let organizationEvents: Event[] = [];
    const eventsQuery = await getEventsByOrganizationId(organizationId);
    if (progressCallbackFunction) progressCallbackFunction(25);
    if (eventsQuery.isSuccess) {
        const allOrganizationEvents = eventsQuery.result;
        if (selectedYear) {
            const currentOrgMemberships = allOrganizationEvents.filter((e: Event) => e?.startDate?.includes(selectedYear) || e?.endDate?.includes(selectedYear));
            organizationEvents = currentOrgMemberships;
        } else {
            organizationEvents = allOrganizationEvents;
        }
    }

    if (progressCallbackFunction) progressCallbackFunction(30);

    // Use the same column names for PDF or CSV
    const tableColumns = [
        "Back #",
        "Mem Id",
        "Rider",
        "Horse",
        "Barn",
        "Shows List",
        "# Shows",
        "Meetings",
        "Hours",
    ];

    const doc = new jsPDF();
    doc.text(`Members List - Shows Attended Report`, 14, 20);
    doc.setFontSize(10);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    doc.text(`*** designates a difference in the Shows List number and recorded number of shows on the membership.`, 14, 30);

    const tableRows: any[] = [];

    // Fill the memberEventsAttendedMap
    for (let i = 0; i < organizationEvents.length; i++) {
        const currentEvent = organizationEvents[i];

        if (i === Math.floor(organizationEvents.length/2) && progressCallbackFunction) progressCallbackFunction(50);
        
        const eventEntryQuery = await getEventEntriesByEventId(currentEvent.id);
        if (eventEntryQuery.isSuccess) {
            const allEventEntries: EventEntry[] = eventEntryQuery.result;

            if (allEventEntries && allEventEntries.length > 0) {
                for (let j = 0; j < allEventEntries.length; j++) {
                    const currentEventEntry: EventEntry = allEventEntries[j];
                    
                    // Check the map for this entry's back number
                    const currentBackNumber = currentEventEntry.number || 0;
                    const eventsList = memberEventsAttendedMap.get(currentBackNumber);

                    if (eventsList) {
                        // If the back number has been seen, check if this event should be added
                        const eventFoundIndex = eventsList.findIndex((e) => e.id === currentEvent.id);
                        if (eventFoundIndex === -1) memberEventsAttendedMap.set(currentBackNumber, eventsList.concat([currentEvent]));
                    } else {
                        // OTW, add this backNumber to the map
                        memberEventsAttendedMap.set(currentBackNumber, [currentEvent]);
                    }
                }
            }
        }
    }

    if (progressCallbackFunction) progressCallbackFunction(90);

    // Loop through the memberships and create a row in the table for each membership
    for (let i = 0; i < organizationMemberships.length; i++) {
        const currentMembership = organizationMemberships[i];
        let eventsString = "";
        let numberOfEventsAttended = 0;
        if (currentMembership.backNumber) {
            const eventList = memberEventsAttendedMap.get(currentMembership.backNumber);
            if (eventList) {
                eventsString = eventList.map((e, index) => {
                    const formattedIndex: number = index + 1;
                    const formattedEventData: string = moment(e.startDate).format("MM-DD-YY");
                    const formattedFullString = "(" + formattedIndex.toString() + ") " + formattedEventData;
                    return (formattedFullString);
                }).join(",\n");
                numberOfEventsAttended = eventList.length;
            }
        }

        const formattedNumberShowsAttended = currentMembership?.showsAttended === numberOfEventsAttended ? 
        currentMembership?.showsAttended 
        : 
        (currentMembership?.showsAttended ? currentMembership?.showsAttended.toString() + "***" : "0");
        
        const newRow: any[] = [
            currentMembership.backNumber || "",
            currentMembership?.membershipId || "",
            currentMembership?.rider?.name || "",
            currentMembership?.horse?.name || "",
            currentMembership?.barn?.name || "",
            eventsString,
            formattedNumberShowsAttended,
            currentMembership?.meetingsAttended || "",
            currentMembership?.volunteerHours || "",
        ];
        tableRows.push(newRow);
    }

    // Sort all rows by the rider name column
    const sortedTableRows = tableRows.sort((a, b) => {
        const initialRowRiderName: string = a[2];
        const initialRowRiderNameCleaned: string = initialRowRiderName.toLocaleLowerCase().trim();
        const currentRowRiderName: string = b[2];
        const currentRowRiderNameCleaned: string = currentRowRiderName.toLocaleLowerCase().trim();
        return initialRowRiderNameCleaned.localeCompare(currentRowRiderNameCleaned);
    });

    if (isMismatchedReport) {
        // If the flag is on for the mismatched report, only print rows where rider name is the same and other data differs
        let riderRows = [];
        let isFirstTable = true;
        for (let i = 0; i < sortedTableRows.length; i++) {
            const row = sortedTableRows[i];
            const currentRowRiderName: string = row[2];
            const currentRowRiderNameCleaned: string = currentRowRiderName.toLocaleLowerCase().trim();

            if (riderRows.length === 0) {
                riderRows.push(row);
            } else {
                // Check if the rider name in the riderRows is the same as the current row
                const initialRowRiderName: string = riderRows[0][2];
                const initialRowRiderNameCleaned: string = initialRowRiderName.toLocaleLowerCase().trim();
                if (initialRowRiderNameCleaned === currentRowRiderNameCleaned) {
                    riderRows.push(row);
                } else {
                    // Make the table based on collected riderRows
                    let shouldPrintTable = false;
                    for (let j = 0; j < riderRows.length; j++) {
                        const riderRow = riderRows[j];
                        const currentRowNumberOfShows = riderRow[6].toString().split("***")[0];
                        const initialRowNumberOfShows = riderRows[0][6].toString().split("***")[0];
    
                        let isNumberOfShowsMismatched = currentRowNumberOfShows !== initialRowNumberOfShows;
                        let isNumberOfMeetingsMismatched = riderRow[7] !== riderRows[0][7];
                        let isNumberOfHoursMismatched = riderRow[8] !== riderRows[0][8];

                        if (isNumberOfShowsMismatched || isNumberOfMeetingsMismatched || isNumberOfHoursMismatched) {
                            shouldPrintTable = true;
                            break;
                        }
                    }
                    if (shouldPrintTable) {
                        doc.autoTable(tableColumns, riderRows, { 
                            theme: "grid",
                            headStyles: {fillColor: "#73a4d3"},
                            startY: isFirstTable ? 35 : (doc.lastAutoTable.finalY + 15),
                            rowPageBreak: "avoid"
                        }); 
                        isFirstTable = false;
                    }
                    riderRows = [row];
                }
            }
        }
        addFooters(doc);
        doc.save(`Members_List_Mismatched_Data.pdf`);
    } else {
        // OTW, print out the full table
        doc.autoTable(tableColumns, sortedTableRows, { 
            theme: "grid",
            headStyles: {fillColor: "#73a4d3"},
            startY: 35,
            rowPageBreak: "avoid"
        }); 
        doc.addPage(); 
        addFooters(doc);
        doc.save(`Members_List_Shows_Attended.pdf`);
    }
}