import {
    IonText,
} from "@ionic/react";
import React from "react";
import { Address } from "../../models";

interface _Props {
    address: Address
}

const AddressLabel: React.FC<_Props> = ({address}) => {
    const formatAddress = () => {
        let addressString = "";
        if (address.streetAddress1) addressString = addressString + address.streetAddress1 + " ";
        if (address.streetAddress2) addressString = addressString + address.streetAddress2 + " ";
        if (address.city) addressString = addressString + address.city + ", ";
        if (address.provState) addressString = addressString + address.provState + " ";
        if (address.zip) addressString = addressString + address.zip + " ";
        if (address.country) addressString = addressString + address.country + " ";
        return addressString;
    } 

    return (
        <IonText className="ion-text-wrap">
            {formatAddress()}
        </IonText>
    );
};

export default AddressLabel;