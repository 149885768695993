import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { Barn, Trainer } from "../../models";
import { createTrainer, updateTrainer} from "../../utilities/trainer/Trainer";
import ErrorAlert from "../Errors/ErrorAlert";
import { CreateTrainerInput, UpdateTrainerInput } from "../../API";
import SuccessBanner from "../Banners/SuccessBanner";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    trainer?: (Trainer | null)
    selectedBarn?: (Barn | null)
    onChange: Function
}

const QuickAddTrainerForm: React.FC<_Props> = ({trainer, selectedBarn, onChange}) => {
    const user = useContext(PersonContext);
    
    const [barn, setBarn] = useState<Barn | null | undefined>();
    const [name, setName] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const clearForm = () => {
        setName("");
    }

    useEffect(() => {
        clearForm();
    }, []);

    useEffect(() => {
        setBarn(selectedBarn);
    }, [selectedBarn]);

    useEffect(() => {
        if (trainer) {
            setName(trainer.name);
        } else {
            clearForm();
        }
    }, [trainer]);

    const verifyForm = () => {
        if (!name) {
            setError("Please include a name for the trainer");
            return false;
        }
        return true;
    }

    const handleCreateTrainer = async () => {
        const input: CreateTrainerInput = {
            name,
            barnName: barn?.name,
            barnId: barn?.id,
            // trainerBarnId: barn?.id,
            createdBy: user.id
        };
        const createResult = await createTrainer(input);
        if (createResult.isSuccess) {
            onChange(createResult.result);
            setSuccess("Successfully created the trainer");
        } else {
            setError(createResult.message);
        }
    }

    const handleUpdateTrainer = async () => {
        if (trainer) {
            const input: UpdateTrainerInput = {
                id: trainer.id,
                name,
                barnName: barn?.name,
                barnId: barn?.id,
                // trainerBarnId: barn?.id
            };
            const updateResult = await updateTrainer(input);
            if (updateResult.isSuccess) {
                onChange(updateResult.result);
                setSuccess("Successfully updated the trainer");
            } else {
                setError(updateResult.message);
            }
        } else {
            setError("No trainer to edit.");
        }
    }

    const handleAddTrainer = async () => {
        setSuccess("");
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            await handleCreateTrainer();
        }
    }

    const handleEditTrainer = async () => {
        setSuccess("");
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            await handleUpdateTrainer();
        }
    }

    return (
        <>
            {error && <ErrorAlert error={error} />}
            {success && <SuccessBanner width="12" success={success} />}
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <IonItem color="white">
                        <IonLabel position="stacked">Name <RequiredInputIndicator /></IonLabel>
                        <IonInput 
                            type="text"
                            value={name}
                            aria-required={true}
                            onIonChange={e => {
                                setName(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton
                        className="ion-margin-top"
                        color="tertiary"
                        expand="block"
                        onClick={trainer ? handleEditTrainer : handleAddTrainer}
                    >
                        {trainer ? "Edit " + trainer.name : "Add Trainer"}
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default QuickAddTrainerForm;