import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event, OrganizationClass, OrganizationDivision} from "../../../../../models";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import EventClassForm from "../../../../../components/EventClass/EventClassForm";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventClassCreatePage: React.FC<EventPageProps> = ({match}) => {
    const isFromTemplate = true;
    const template: ((OrganizationClass | OrganizationDivision) | null | undefined) = null;

    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setCurrentEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        if (match.params.id) getEvent();
    }, [match]);

    const handleSubmit = () => {}

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent ? currentEvent.name : "Event Settings"} />
                {error && <ErrorAlert width="12" error={error} />}
                {/* <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard color="white" className="ion-padding">
                            <IonCardSubtitle>
                                <p>You can use a template for a class using your Organization's Class Templates.</p>
                            </IonCardSubtitle>
                            <IonCardContent>
                                <IonRow className="ion-align-items-center">
                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-center"> 
                                        <IonButton color={isFromTemplate ? "dark" : "light"} onClick={() => setIsFromTemplate(true)}>Use Template</IonButton>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-center"> 
                                        <IonButton color={isFromTemplate ? "light" : "dark"} onClick={() => {setIsFromTemplate(false); setTemplate(undefined);}}>Create New</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                {isFromTemplate &&
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <IonCard color="white" className="ion-padding">
                                <IonCardTitle>
                                    <IonRow>
                                        <IonCol>
                                            <IonText>
                                                <h3>Select Template</h3>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonCardTitle>
                                <IonCardContent>
                                    <IonRow>
                                        <IonCol>
                                            {currentEvent && currentEvent.organizationId && (
                                                <SelectOrganizationClassOrDivision organizationId={currentEvent?.organizationId} onSelect={handleSelectTemplate} />
                                            )}
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                } */}
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                <IonRow>
                                    <IonCol>
                                        <IonText>
                                            <h3>Class</h3>
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                            </IonCardTitle>
                            <IonCardContent>
                                {currentEvent && (
                                    <EventClassForm event={currentEvent} template={isFromTemplate ? template : undefined} onSubmit={() => handleSubmit()} />
                                )}
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EventClassCreatePage;