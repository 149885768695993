import {
    IonButton,
    IonCol,
    IonIcon,
    IonModal,
    IonTitle,
    IonToolbar,
    IonRow,
    IonButtons,
    IonContent,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { UpdateEventClassInput } from "../../API";
import { EventClass, EventDivision } from "../../models";
import { getEventClassesByEventDivisionId, updateEventClass } from "../../utilities/eventClass/EventClass";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    eventDivision?: (EventDivision | null)
}

const EventDivisionClassList: React.FC<_Props> = ({eventDivision}) => {

    const [eventClassList, setEventClassList] = useState<EventClass[] | null | undefined>();
    const [selectedEventClass, setSelectedEventClass] = useState<EventClass | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");
   
    const getClasses = async (divisionId: string) => {
        setIsLoading(true);
        const queryResult = await getEventClassesByEventDivisionId(divisionId);
        if (queryResult.isSuccess) {
            const list = queryResult.result;
            setEventClassList(list);
        } else {
            return null;
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (eventDivision) {
            getClasses(eventDivision.id);
        }
    }, [eventDivision]);

    /**
     * Remove this event class from this event division
     * @param selectedEventClass EventClass
     */
    const handleRemoveEventClass = async () => {
        setError("");
        if (selectedEventClass) {
            const updateEventClassInput: UpdateEventClassInput = {
                id: selectedEventClass.id,
                eventDivisionId: null,
                organizationDivisionId: null,
                combinedEventDivisionId: "",
            };
            const updateResult = await updateEventClass(updateEventClassInput);
            if (updateResult.isSuccess) {
                if (eventClassList) {
                    const updatedList: EventClass[] = eventClassList.filter(ec => ec.id !== selectedEventClass.id);
                    setEventClassList(updatedList);
                }
            } else {
                setError(updateResult.message);
            }
        } else {
            setError("No class was found.")
        }
        setShowModal(false);
    }

    return (
        <>
            {error && <ErrorAlert error={error} />}
            {isLoading ?
                <Spinner />
                :
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeXs="12" sizeMd="8">
                        {(eventClassList && eventClassList.length > 0) ?
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Number</th>
                                        <th>Class Name</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {eventClassList.map((eventClass, index) => (
                                    <tr key={index}>
                                        <td className="ion-text-wrap">{eventClass.number}</td>
                                        <td className="ion-text-wrap">{eventClass.name}</td>
                                        <td>
                                            <IonButton color="danger" onClick={() => {setSelectedEventClass(eventClass); setShowModal(true);}}>
                                                X
                                            </IonButton>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            :
                            <p>No classes found.</p>
                        }
                    </IonCol>
                </IonRow>
            }
            <IonModal backdropDismiss={false} isOpen={showModal} id="removeEventClassFromEventDivisionModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Remove Class
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow className="ion-justify-content-center mt-3">
                        <IonCol size="12" className="ion-text-center">
                            <h5>Are you sure you want to remove the following class: </h5>
                            <h6>{selectedEventClass?.number}: {selectedEventClass?.name}</h6>
                            <h5 className="mt-3">From the following division:</h5>
                            <h6>{eventDivision?.name}</h6>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center mt-3">
                        <IonCol size="6" className="ion-text-center">
                            <IonButton color="light" onClick={() => setShowModal(false)}>
                                Clear
                            </IonButton>
                        </IonCol>
                        <IonCol size="6" className="ion-text-center">
                            <IonButton color="danger" onClick={() => handleRemoveEventClass()}>
                                Remove
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventDivisionClassList;