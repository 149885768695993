import { 
    IonCard
} from "@ionic/react";
import React from "react";
import { Barn } from "../../models";

interface _Props {
    barn: Barn
}

const BasicBarnInfo: React.FC<_Props> = ({barn}) => {

    return (
        <IonCard className="bg-white ion-padding">
            <h3>{barn.name}</h3>
            <p className="description">Barn id: {barn.id}</p>
        </IonCard>
    );
};

export default BasicBarnInfo;