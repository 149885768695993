import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventStallTypeInput, CreateEventStallTypeMutation, DeleteEventStallTypeInput, DeleteEventStallTypeMutation, GetEventStallTypeQuery, ListEventStallTypesQuery, UpdateEventStallTypeInput, UpdateEventStallTypeMutation } from "../../API";
import moment from "moment";
import { EventStallType } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventStallType. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventStallType.
*/
export async function createEventStallType(input: CreateEventStallTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventStallType", "No input", "Create EventStallType received no input.");
   try {
        const fullInput: CreateEventStallTypeInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventStallType, { input: fullInput }))) as GraphQLResult<CreateEventStallTypeMutation>;
       else result = (await API.graphql({
           query: mutations.createEventStallType,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventStallTypeMutation>;
       const eventStallType = result.data?.createEventStallType;
       return formatResult(true, "EventStallType", eventStallType, "Successfully created the eventStallType.");
   } catch (error: any) {
       return formatResult(false, "EventStallType", error, "Error creating record in the database for type: eventStallType");
   }
}

/**
* Handle updating a new record in the database for type: eventStallType. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventStallType.
*/
export async function updateEventStallType(input: UpdateEventStallTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventStallType", "No input", "Update EventStallType received no input.");
   try {
        const fullInput: UpdateEventStallTypeInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventStallType, { input: fullInput }))) as GraphQLResult<UpdateEventStallTypeMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventStallType,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventStallTypeMutation>;
       const eventStallType = result.data?.updateEventStallType;
       return formatResult(true, "EventStallType", eventStallType, "Successfully updated the eventStallType.");
   } catch (error: any) {
       return formatResult(false, "EventStallType", error, "Error updating record in the database for type: eventStallType");
   }
}

/**
* Handle deleting a new record in the database for type: eventStallType. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventStallType.
*/
export async function deleteEventStallType(input: DeleteEventStallTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventStallType", "No input", "Delete EventStallType received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventStallType, { input: input }))) as GraphQLResult<DeleteEventStallTypeMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventStallType,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventStallTypeMutation>;
       const eventStallType = result.data?.deleteEventStallType;
       return formatResult(true, "EventStallType", eventStallType, "Successfully deleted the eventStallType.");
   } catch (error: any) {
       return formatResult(false, "EventStallType", error, "Error deleting record in the database for type: eventStallType");
   }
}

/**
* Get all records in the database for type: eventStallType. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventStallType object
*/
export async function getAllEventStallTypes(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventStallTypes,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventStallTypesQuery>;
        
        let items = result.data?.listEventStallTypes?.items as EventStallType[];
        let nextToken = result.data?.listEventStallTypes?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventStallTypes,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventStallTypesQuery>;

            const nextItems = nextResult.data?.listEventStallTypes?.items as EventStallType[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventStallTypes?.nextToken;
        }

        return formatResult(true, "EventStallType", items, "Successfully got the EventStallTypes.");
    } catch (error: any) {
        return formatResult(false, "EventStallType", error, "Error reading record in the database for type: eventStallTypes");
    }
}

/**
* Read a specific record in the database for type: eventStallType. 
* 
* @param {string}  id                  The eventStallType id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventStallType object
*/
export async function getEventStallTypeById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventStallType,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventStallTypeQuery>;
       const eventStallType = result.data?.getEventStallType;
       return formatResult(true, "EventStallType", eventStallType, "Successfully got the eventStallType.");
   } catch (error: any) {
       return formatResult(false, "EventStallType", error, "Error reading record in the database for type: eventStallType");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventStallType. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventStallType object
*/
export async function getEventStallTypesByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventStallTypeByEventId,
            variables: {
                limit: 1000,
                eventId: eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.eventStallTypeByEventId?.items as EventStallType[];
        let nextToken = result.data?.eventStallTypeByEventId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventStallTypeByEventId,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventStallTypeByEventId?.items as EventStallType[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventStallTypeByEventId?.nextToken;
        }

        return formatResult(true, "EventStallType", items, "Successfully got the EventStallTypes.");
    } catch (error: any) {
        return formatResult(false, "EventStallType", error, "Error reading record in the database for type: eventStallTypes");
    }
}