import {
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import moment from "moment";
import "react-phone-input-2/lib/style.css";
import { isWindows } from "../../../utilities/platform/Platform";
import { Membership, Organization, OrganizationMembershipType, Person, MembershipTypeSchedule, Barn, MembershipTypeCategory, Horse } from "../../../models";
import SelectFromAllBarns from "../../Barn/SelectFromAllBarns";
import { CreateHorseInput, CreateMembershipInput, UpdateHorseInput, UpdateMembershipInput, UpdateOrganizationMembershipTypeInput } from "../../../API";
import { createMembership, updateMembership } from "../../../utilities/membership/Membership";
import Spinner from "../../Spinners/Spinner";
import CONSTANT from "../../../constant/constant";
import { getNextAvailableMembershipId } from "../../../utilities/organizationMembershipType/MembershipIdValues";
import { updateOrganizationMembershipType } from "../../../utilities/organizationMembershipType/OrganizationMembershipType";
import { createHorse, getHorseById, updateHorse } from "../../../utilities/horse/Horse";
import SelectHorse from "../../Horse/SelectHorse";
import HorseHeightInput from "../../Horse/HorseHeightInput";
import { getHorsesByUserByBarn } from "../../../utilities/horse/HorseByBarn";
import { createCogginsDocumentFileName, createS3Object, handleUploadS3File, handleUploadS3Image } from "../../../utilities/s3Object/s3Object";
import SelectState from "../../Address/SelectState";
import CogginsDocumentForm from "../../s3Object/CogginsDocumentForm";
import ErrorAlert from "../../Errors/ErrorAlert";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";

interface _Props {
    membershipType: OrganizationMembershipType;
    memberships: Membership[] | undefined;
    personId: string;
    user: Person | undefined;
    setMemberships: Function;
    organization: Organization | undefined;
}

const HorseMemberInfo: React.FC<_Props> = ({membershipType, memberships, setMemberships, personId, user, organization}) => {

    const membership: (Membership | undefined) = (memberships?.length) ? memberships[0] : undefined;
    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Horse
    const [horseList, setHorseList] = useState<Horse[] | null | undefined>();
    const [selectedHorse, setSelectedHorse] = useState<Horse | null | undefined>();
    const [horseName, setHorseName] = useState<string>("");
    const [horseHeight, setHorseHeight] = useState<number>(0);
    const [birthDate, setBirthDate] = useState<string>("");
    const [horseOwnerName, setHorseOwnerName] = useState<string>("");
    const [cogginsState, setCogginsState] = useState("");
    const [cogginsNumber, setCogginsNumber] = useState("");
    const [cogginsDate, setCogginsDate] = useState("");
    const [cogginsDocument, setCogginsDocument] = useState<any | null | undefined>();
    const [barn, setBarn] = useState<Barn | undefined | null>();

    const clearForm = () => {
        setSelectedHorse(undefined);
        setBarn(undefined);
        setHorseName("");
        setHorseHeight(0);
        setHorseOwnerName("");
        setBirthDate("");
        setCogginsDate("");
        setCogginsNumber("");
        setCogginsState("");
        setCogginsDocument(undefined);
    }

    const getDataForHorse = (horse: Horse) => {
        if(horse.barn) setBarn(horse.barn);
        if(horse.name) setHorseName(horse.name || "");
        if(horse.dateOfBirth) setBirthDate(horse.dateOfBirth || "");
        if(horse.ownerName) setHorseOwnerName(horse.ownerName || "");
        if(horse.height) setHorseHeight(parseFloat(horse.height));
        if(horse.cogginsNumber) setCogginsNumber(horse.cogginsNumber || "");
        if(horse.cogginsDate) setCogginsDate(horse.cogginsDate || "");
        if(horse.cogginsState) setCogginsState(horse.cogginsState || "");
        if(horse.coggins) setCogginsDocument(horse.coggins.title);
    }

    useEffect(() => {
        const getPrePopulatedDataForHorseMembership = async () => {
            if(membership) {
                if(membership.horse) {
                    const queryResult = await getHorseById(membership.horse.id);
                    if (queryResult.isSuccess) {
                        const horse: Horse = queryResult.result;
                        getDataForHorse(horse);
                        setSelectedHorse(horse);
                    }
                }
                if(membership.barn) setBarn(membership.barn || undefined);
            } else {
                clearForm();
            }
        }

        getPrePopulatedDataForHorseMembership();
    }, [membership]);

    useEffect(() => {
        async function getHorses() {
            if (user) {
                const horses = await getHorsesByUserByBarn(user);
                if (horses) setHorseList(horses);
            }
        }

        getHorses();
    }, []);

    const handleSelectBarn = async (barn?: Barn) => {
        setError("");
        if (barn && user) {
            setBarn(barn);
            const horses = await getHorsesByUserByBarn(user, barn);
            setHorseList(horses);
        } else {
            setBarn(undefined);
            setHorseList(undefined);
        }
    };

    const handleHorseSelect = (horse: Horse) => {
        if(horse) {
            getDataForHorse(horse);
            setSelectedHorse(horse);
        } else {
            clearForm();
        }
    }

    const handleHeightChange = (heightInInches: number) => {
        setHorseHeight(heightInInches);
    }

    const handleCogginsState = (state: string) => {
        if (state && state !== "-"){ 
            setCogginsState(state);
        } else {
            setCogginsState("");
        }
    }

    const handleSelectedDocument = async (file: any) => {
        setCogginsDocument(file);
    }

    const handleUploadDocument = async (file: any, horse: Horse) => {
        if (horse) {
            // Upload this to S3 
            const fileName = createCogginsDocumentFileName(horse);
            let uploadResult;
            if (file.type === "application/pdf") {
                uploadResult = await handleUploadS3File(fileName, file);
            } else {
                uploadResult = await handleUploadS3Image(fileName, file);
            }
            if (uploadResult.isSuccess) {
                // If successfully added to S3, save to video list to the person
                const key = uploadResult.result;
                const title = "Coggins for " + horse.name;
                const description = "Coggins uploaded for " + horse.name + " by " + user?.firstName + " " + user?.lastName + " on " + moment(new Date()).format("MMM DD, YYYY h:mm a"); 
                const s3Object = createS3Object(key, title, file, description);
                return s3Object;
            } else {
                setError("Could not upload the video. Contact hello@ringsidepro.com for help.")
            }
        } else {
            setError("No horse found.")
        }
    }

    const handleUpdateHorse = async (selectedHorse: Horse) => {
        try {
            const horseInput: UpdateHorseInput = {
                id: selectedHorse.id,
                personId: personId,
                name: horseName,
                ownerName: horseOwnerName,
                height: horseHeight.toString(),
                barnId: barn?.id,
                barnName: barn?.name,
                dateOfBirth: birthDate ? birthDate : null          
            };
            if(membershipType.applicationFields?.cogginsInfo) {
                if (cogginsState) horseInput.cogginsState = cogginsState;
                if (cogginsDate) horseInput.cogginsDate = cogginsDate;
                if (cogginsNumber) horseInput.cogginsNumber = cogginsNumber;
            } else {
                horseInput.cogginsState = "";
                horseInput.cogginsDate = null;
                horseInput.cogginsNumber = "";
            }
            if(membershipType.applicationFields?.cogginsUpload) {
                if (selectedHorse.coggins && !cogginsDocument) {
                    horseInput.coggins = selectedHorse.coggins;
                } else if (cogginsDocument) {
                    const s3Object = await handleUploadDocument(cogginsDocument, selectedHorse);
                    horseInput.coggins = s3Object;
                }
            } else {
                horseInput.coggins = null;
            }
            const updateHorseResult = await updateHorse(horseInput);
            if (updateHorseResult.isSuccess) {
                setSelectedHorse(updateHorseResult.result);
                return updateHorseResult.result;
            } else {
                console.error(updateHorseResult.message);
            }
        } catch (error) {
            setError("Could not update the horse.");
        }
    }

    const handleCreateHorse = async () => {
        try {
            const horseInput: CreateHorseInput = {
                personId: personId,
                name: horseName,
                ownerName: horseOwnerName,
                height: horseHeight.toString(),
                dateOfBirth: birthDate ? birthDate : null,
                barnId: barn?.id,
                barnName: barn?.name,           
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
            if(membershipType.applicationFields?.cogginsInfo) {
                if (cogginsState) horseInput.cogginsState = cogginsState;
                if (cogginsDate) horseInput.cogginsDate = cogginsDate;
                if (cogginsNumber) horseInput.cogginsNumber = cogginsNumber;
            } else {
                horseInput.cogginsState = "";
                horseInput.cogginsDate = null;
                horseInput.cogginsNumber = "";
            }
            const createHorseResult = await createHorse(horseInput);
            if (createHorseResult.isSuccess) {
                setSelectedHorse(createHorseResult.result);
                const horse: Horse = createHorseResult.result;
                if (cogginsDocument) {
                    const s3Object = await handleUploadDocument(cogginsDocument, createHorseResult.result);
                    const horseUpdateInput: UpdateHorseInput = {
                        id: horse.id,
                        coggins: s3Object
                    };
                    const updateHorseResult = await updateHorse(horseUpdateInput);
                    if (updateHorseResult.isSuccess) {
                        setSelectedHorse(updateHorseResult.result);
                        return updateHorseResult.result;
                    } else {
                        setError(updateHorseResult.message);
                    }
                } else {
                    return createHorseResult.result;
                }
            } else {
                setError(createHorseResult.message);
            }
        } catch (error) {
            setError("Could not create new horse.");
        }
    }
    
    const handleHorse = async () => {
        let horse: Horse | undefined = undefined;
        if (selectedHorse) {
            await handleUpdateHorse(selectedHorse);
        } else {
            await handleCreateHorse();
        }
        return horse;
    }

    const getDateMembershipEnds = () => {
        try {
            const schedule =  membershipType.schedule;
            const isStartAtTimeOfPurchase = membershipType.isStartAtTimeOfPurchase;
            const setStartDate = membershipType.setStartDate;
            let dateMembershipEnds;
            if(schedule === MembershipTypeSchedule.ANNUAL) {
                if(isStartAtTimeOfPurchase) {
                    // End date should be one year after today's date (because the membership starts on the date of purchase)
                    dateMembershipEnds = moment().add(1, "year").format("YYYY-MM-DD");
                } else {
                    // End date should be one year after the set start date
                    dateMembershipEnds = moment(setStartDate).year((new Date()).getFullYear()).add(1, "year").format("YYYY-MM-DD");
                }
            } else if (schedule === MembershipTypeSchedule.LIFE) {
                dateMembershipEnds = "";
            }
            return dateMembershipEnds;
        } catch (error) {
            const message = "Could not get the date the membership should end: " + error;
            setError(message);
        }
    }

    const getNameOfCurrentUser = () => {
        let personName = "";
        if (user) {
            personName = (user.firstName ? user.firstName.trim() : "") + 
            (user.firstName && user.lastName ? " " : "") + 
            (user.lastName ? user.lastName.trim() : "");
        }
        return personName;
    }

    const handleUpdateMembership = async (horseData: Horse, membership: Membership) => {
        const input: UpdateMembershipInput = {
            id: membership.id,
            name: organization && organization.name ? organization.name : "",
            personId: personId,
            personName: getNameOfCurrentUser(),
            organizationMembershipTypeId: membershipType.id,
            type: membershipType.name,
            horseId: horseData && horseData.id ? horseData.id : selectedHorse?.id,
            organizationName: organization?.name,
            organizationId: organization?.id,
            barnId: horseData.barnId
        };
        const updateMembershipResult = await updateMembership(input);
        if (updateMembershipResult.isSuccess) {
            setMemberships([updateMembershipResult.result]);
        } else {
            setError(updateMembershipResult.message);
        }
    }

    const handleCreateMembership = async (horseData: Horse) => {
        const dateMembershipEnds = getDateMembershipEnds();
        const input: CreateMembershipInput = {
            name: organization && organization.name ? organization.name : "",
            membershipId: membershipType.nextAvailableMembershipId || "",
            personName: getNameOfCurrentUser(),
            membershipStatus: CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS,
            organizationMembershipTypeId: membershipType.id,
            type: membershipType.name,
            amountPaid: 0,
            horseId: horseData && horseData.id ? horseData.id : selectedHorse?.id,
            personId: personId,
            organizationName: organization?.name,
            organizationId: organization?.id,
            barnId: horseData.barnId,
            dateMembershipEnds: dateMembershipEnds
        };
        const createMembershipResult = await createMembership(input);
        if (createMembershipResult.isSuccess) {
            if(membershipType.applicationOptions?.autoAssignIds && membershipType?.membershipIdValues) {
                const newMembershipId = getNextAvailableMembershipId(membershipType?.membershipIdValues, membershipType.nextAvailableMembershipId || "");
                const updateInput:  UpdateOrganizationMembershipTypeInput = {
                    id: membershipType.id,
                    nextAvailableMembershipId: newMembershipId
                };
                await updateOrganizationMembershipType(updateInput);
            }
            setMemberships([createMembershipResult.result]);
        } else {
            setError(createMembershipResult.message);
        }
    }

    const handleHorseMembership = async (horseData: Horse) => {
        if (membership) {
            await handleUpdateMembership(horseData, membership);
        } else {
            await handleCreateMembership(horseData);
        }
    }

    const verifyIsValid = () => {
        if (!horseName) {
            setError("A required field is missing data: Horse Name");
            return false;
        }
        if (!horseHeight) {
            setError("A required field is missing data: Height in Hands");
            return false;
        }
        if (!horseOwnerName) {
            setError("A required field is missing data: Owner's Name");
            return false;
        }
        if (membershipType.applicationFields?.dateOfBirth && !birthDate) {
            setError("A required field is missing data: Birth Date");
            return false;
        }
        if (membershipType.applicationFields?.barnName && !barn) {
            setError("A required field is missing data: Barn");
            return false;
        }
        if (membershipType.applicationFields?.cogginsInfo) {
            if (!cogginsNumber) {
                setError("A required field is missing data: Coggins Accession Number");
                return false;
            }
            if (!cogginsDate) {
                setError("A required field is missing data: Coggins Test Date");
                return false;
            }
            if (!cogginsState) {
                setError("A required field is missing data: State of Test");
                return false;
            }
        }
        if (selectedHorse?.coggins) {
            setError("");
        } else if (membershipType.applicationFields?.cogginsUpload && !cogginsDocument) {
            setError("A required field is missing data: Upload a Coggins");
            return false;
        }
        return true;
    };

    return (
        <React.Fragment>
            {isLoading ?
                <Spinner />
                :
                <>
                    {error && <ErrorAlert error={error} />}

                    {/* Header */}
                    <IonRow className="mt-3 mb-0">
                        <IonCol className="ion-text-center">
                            <h4>Horse Basic Info</h4>
                        </IonCol>
                    </IonRow>

                    {/* Select Barn & Select Horse */}
                    <IonRow className="mt-3 mb-0">
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonLabel className="pl-3 text-darker" position="stacked">
                                Barn{" "}
                                {membershipType.applicationFields?.barnName && <RequiredInputIndicator />} 
                            </IonLabel>
                            <SelectFromAllBarns
                                barn={barn}
                                selectedValue={barn?.id}
                                isHeightRequired={true}
                                height={"45px"}
                                onSelect={(barn: Barn) =>
                                    handleSelectBarn(barn)
                                }
                            />
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonLabel position="stacked" color="dark">
                                Select Existing Horse
                            </IonLabel>
                            <SelectHorse
                                horses={horseList} 
                                selectedValue={selectedHorse?.id} 
                                onSelect={handleHorseSelect}
                                isHeightRequired={true}
                                height={"45px"}
                            />
                        </IonCol>
                    </IonRow>

                    {/* Horse Details */}
                    <IonRow>
                        {membershipType.applicationFields?.horseName &&
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                        Horse Name
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput
                                        id="membership-app-basicInfo-horse-name"
                                        type="text"
                                        value={horseName}
                                        aria-required={true}
                                        onIonChange={(e) => {
                                            setError("");
                                            setHorseName(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                        }
                        {membershipType.applicationFields?.horseHeight && 
                            <IonCol sizeXs="12" sizeMd="6">
                                <HorseHeightInput height={horseHeight} isRequired={true} onChange={handleHeightChange} />
                            </IonCol>
                        }
                        {membershipType.applicationFields?.horseOwnerName && 
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                        Owner's Name
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput
                                        id="membership-app-basicInfo-horse-owner-name"
                                        type="text"
                                        value={horseOwnerName}
                                        aria-required={true}
                                        onIonChange={(e) => {
                                            setError("");
                                            setHorseOwnerName(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                        }
                        {membershipType.applicationFields?.dateOfBirth && 
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position="stacked">
                                        Birth Date
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput 
                                        id="membership-app-basicInfo-horse-birth-date"
                                        type="date"
                                        value={birthDate}
                                        aria-required={true}
                                        onIonChange={e => {
                                            setError("");
                                            setBirthDate(e.detail.value!)
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                        }
                    </IonRow>

                    {/* Horse Coggins Details */}
                    {membershipType && membershipType.applicationFields?.cogginsInfo && (
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                        Coggins Accession Number
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput 
                                        id="membership-app-coggins-accession-number"
                                        type="text"
                                        value={cogginsNumber}
                                        aria-required={true}
                                        onIonChange={e => {
                                            setError("");
                                            setCogginsNumber(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position="stacked">
                                        Coggins Test Date
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput 
                                        id="membership-app-coggins-test-date"
                                        type="date"
                                        value={cogginsDate}
                                        aria-required={true}
                                        onIonChange={e => {
                                            setError("");
                                            setCogginsDate(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <SelectState
                                    id="membership-app-state-of-test"
                                    isRequired={true}
                                    label="State of Test"
                                    selectedValue={cogginsState}
                                    onInputChange={handleCogginsState}
                                />
                            </IonCol>
                        </IonRow>
                    )}

                    {/* Horse Coggins Upload */}
                    {membershipType && membershipType.applicationFields?.cogginsUpload && (
                        <IonRow className="ion-justify-content-center"> 
                            <IonCol sizeXs="12" sizeMd="6">
                                <CogginsDocumentForm isRequired={true} horse={selectedHorse} onSubmit={handleSelectedDocument} />
                            </IonCol>
                        </IonRow>
                    )}
                </>
            }
        </React.Fragment>
    );
};

export default HorseMemberInfo;
