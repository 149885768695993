import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useState } from "react";
import Spinner from "../Spinners/Spinner";
import { Organization, Post, PostStatus,  } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { Input } from "reactstrap";
import { CreatePostInput, UpdatePostInput } from "../../API";
import { createPost, updatePost } from "../../utilities/posts/Post";
import { useHistory } from "react-router";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    name: string;
    organization: Organization;
    post?: Post;
}

const PostForm: React.FC<_Props> = ({ organization, name, post }) => {
    const history = useHistory();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(
        post && post?.title ? post.title : "");
    const [content, setContent] = useState<string>(
        post && post?.content ? post.content : ""
    );
    const [status, setStatus] = useState<boolean>(
        post && post?.status === PostStatus.PUBLISHED ? true : false
    );
    const [error, setError] = useState<string>("");

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyIsValid();
        if (isValid) {
            await handleCreateAndUpdatePost();
        }
        setIsLoading(false);
    };

    const verifyIsValid = () => {
        if (!title) {
            setError("You must include a title for the Post.");
            return false;
        }
        if (!content) {
            setError("You must include some content for the Post.");
            return false;
        }
        return true;
    };

    const handleCreateAndUpdatePost = async () => {
        if (post) {
            try {
                const input: UpdatePostInput = {
                    id: post.id,
                    title: title,
                    content: content,
                    status: status ? PostStatus.PUBLISHED : PostStatus.DRAFT,
                };
                const updatePostResult = await updatePost(input);
                if (updatePostResult.isSuccess) {
                    navigateBack(organization.id);
                } else {
                    setError(updatePostResult.message);
                }
            } catch (error: any) {
                const message = "Could not update the Post: " + error;
                setError(message);
            }
        } else {
            try {
                const input: CreatePostInput = {
                    title: title,
                    content: content,
                    status: status ? PostStatus.PUBLISHED : PostStatus.DRAFT,
                    organizationId: organization.id,
                };
                const createPostResult = await createPost(input);
                if (createPostResult.isSuccess) {
                    navigateBack(organization.id);
                } else {
                    setError(createPostResult.message);
                }
            } catch (error: any) {
                const message = "Could not create the Post: " + error;
                setError(message);
            }
            setTitle("");
            setContent("");
            setStatus(false);
        }
    };

    const navigateBack = (organizationId: string) => {
        const path = "/index/staff/organization/posts/" + organizationId;
        history.replace(path);
    };

    return (
        <React.Fragment>
            <h1 className="text-darker">{name}</h1>
            <form>
                {error && <ErrorAlert width="12" error={error} />}
                <IonRow className="mb-3">
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonItem color="white">
                            <IonLabel position="floating">
                                Title{" "}
                                <span className="description text-danger">
                                    *
                                </span>
                            </IonLabel>
                            <IonInput
                                id="organization-post-title"
                                type="text"
                                value={title}
                                aria-required={true}
                                onIonChange={(e) => {
                                    setError("");
                                    setTitle(e.detail.value!);
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow className="mb-3">
                    <IonCol sizeXs="12" sizeMd="12">
                        <IonLabel
                            position="floating"
                            className="text-darker pl-3"
                        >
                            Content{" "}
                            <RequiredInputIndicator />
                        </IonLabel>
                        <Input
                            id="organization-post-content"
                            rows="3"
                            type="textarea"
                            name="description"
                            value={content}
                            spellCheck="true"
                            aria-required={true}
                            onChange={(e) => {
                                setError("");
                                setContent(e.target.value!);
                            }}
                        />
                    </IonCol>
                </IonRow>
                <IonRow className="mb-3">
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonLabel className="text-darker pl-3">Status</IonLabel>
                        <IonRow className="pl-3">
                            <IonItem lines="none">
                                <IonCheckbox
                                    id="organization-post-status"
                                    checked={status}
                                    onIonChange={(e) => setStatus(e.detail.checked)}
                                />
                                <IonLabel className="text-darker pl-3">
                                    Published
                                </IonLabel>
                            </IonItem>
                        </IonRow>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeMd="4">
                        {isLoading ? (
                            <Spinner />
                        ) : (
                            <IonButton
                                id="submit-post-btn"
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                Save
                            </IonButton>
                        )}
                    </IonCol>
                </IonRow>
            </form>
        </React.Fragment>
    );
};

export default PostForm;
