import { Block } from "../../models";

var moment = require("moment");

/**
 * Sorts the blocks so that the first element is the oldest and the last element is the newest
 * 
 * @param data 
 * @returns blocks sorted oldest to newest
 */
export function sortBlocksByDateOldest(data: Block[]) {
    try {
        let result: Block[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => new moment(a.purchasedOn).format("YYYYMMDDHHmmss") - new moment(b.purchasedOn).format("YYYYMMDDHHmmss"));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: block");
        console.error(error);
        return null;
    }
};

/**
 * Sorts the blocks so that the first element is the newest and the last element is the oldest
 * @param data 
 * @returns blocks sorted newest to oldest
 */
export function sortBlocksByDateNewest(data: Block[]) {
    try {
        let result: Block[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => new moment(b.purchasedOn).format("YYYYMMDDHHmmss") - new moment(a.purchasedOn).format("YYYYMMDDHHmmss"));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: block");
        console.error(error);
        return null;
    }
};