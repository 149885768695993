import React, { useState, useEffect } from "react";
import { IonItem, IonLabel, IonInput, IonDatetime, IonButton, IonIcon, IonRow, IonCol } from "@ionic/react";
import { Horse, EntryOptions, Event } from "../../../models";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";
import SelectState from "../../Address/SelectState";
import CogginsDocumentForm from "../../s3Object/CogginsDocumentForm";
import DatePicker from "../../DatePicker/DatePicker";

interface CogginsSectionProps {
    event: Event;
    selectedHorse?: Horse | null | undefined;
    entryOptions?: EntryOptions;
}

const CogginsSection: React.FC<CogginsSectionProps> = ({ selectedHorse, event }) => {
    const [textInput, setTextInput] = useState<string | null | undefined>("");
    const [date, setDate] = useState<string | null | undefined>("");
    const [file, setFile] = useState<Object | null | undefined>(null);
    const [state, setState] = useState<string | undefined>("");

    const requiresCogginsData = event.entryOptions?.requiresCogginsData;
    const requiresCogginsDocument = event.entryOptions?.requiresCogginsDocument;


    useEffect(() => {
        if (selectedHorse) {
            setTextInput(selectedHorse.cogginsNumber);
            setDate(selectedHorse.cogginsDate);
            setFile(selectedHorse.coggins);
            setState(selectedHorse.cogginsState || "-");
        } else {
            setTextInput("");
            setDate("");
            setFile(null);
            setState("");
        }
    }, [selectedHorse]);

    const handleTextInputChange = (e: CustomEvent) => {
        setTextInput(e.detail.value!);
    };

    const handleDateChange = (date: string) => {
        setDate(date);
    };

    const handleStateChange = (state: string) => {
        setState(state);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    };

    return (
        <>
            {/* First Row - Coggins Accession # and Test Date */}
            <IonRow>
                {/* Text Input - Coggins Accession # */}
                <IonCol sizeXs="12" sizeSm="6">
                    <IonItem>
                        <IonLabel position="stacked">
                            Accession # 
                            {requiresCogginsData && <RequiredInputIndicator />}
                        </IonLabel>
                        <IonInput 
                            value={textInput} 
                            onIonChange={handleTextInputChange} 
                        />
                    </IonItem>
                </IonCol>

                {/* Test State Selection */}
                <IonCol sizeXs="12" sizeSm="3" className="pl-2 pt-1 pb-0">
                    <SelectState 
                        id="state-select"
                        label="State"
                        isRequired={requiresCogginsData}
                        selectedValue={state}
                        onInputChange={handleStateChange}
                        isTextDarker={true}
                    />
                </IonCol>

                {/* Calendar Date Selection - Test Date */}
                <IonCol sizeXs="12" sizeSm="3" className="pt-0">
                    <DatePicker label="Test Date" isRequired={!!requiresCogginsData} onChange={handleDateChange} value={selectedHorse?.cogginsDate || ""}/>
                </IonCol>
            </IonRow>

            {/* Second Row - Coggins Test State and File Upload */}
            <IonRow>
                {/* File Upload */}
                <IonCol size="12" className="pt-0">
                    <CogginsDocumentForm horse={selectedHorse} isRequired={requiresCogginsDocument} onSubmit={handleFileChange} />
                </IonCol>
            </IonRow>
        </>
    );
};

export default CogginsSection;
