import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { Barn, Trainer } from "../../models";
import { getAllTrainers, getTrainersByBarnId } from "../../utilities/trainer/Trainer";
import Spinner from "../Spinners/Spinner";
const { v4: uuidv4 } = require('uuid');

interface _Props {
    selectedBarn?: (Barn | null)
    onSelect: Function
    isHeightRequired?: boolean
    height?: string
}

interface formattedOption {
    value: string //Trainer's id
    label: string //Used for filtering options as a user types
    trainer?: Trainer
    trainerName: string
    trainerNameLabel?: string
    barnLabel?: string
}

interface formattedGroupOfOptions {
    label: string
    options: formattedOption[]
}

/**
 * This function is passed into the select component to define what should be shown when an option is selected
 * Instead of showing the 3 lines from the option,
 * it will only show the 1 line defined below: trainerName
 * @param props 
 * @returns 
 */
const SingleValue = (props: any) => (
    <components.SingleValue {...props}>
        {props.data.trainerName || props.data.label}
    </components.SingleValue>
);

const SelectOrCreateTrainer: React.FC<_Props> = ({selectedBarn, onSelect, isHeightRequired, height}) => {

    // We group the options into: 1-Currently Selected Barn's trainers or 2-All Trainers
    const [formattedBarnTrainers, setFormattedBarnTrainers] = useState<formattedOption[] | null | undefined>();
    const [formattedTrainers, setFormattedTrainers] = useState<formattedOption[] | null | undefined>();

    // We then put the formatted options into defined Groups
    const [barnTrainersGroup, setBarnTrainersGroup] = useState<formattedGroupOfOptions | null | undefined>();
    const [allTrainersGroup, setAllTrainersGroup] = useState<formattedGroupOfOptions | null | undefined>();

    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getBarnTrainers();
    }, [selectedBarn]);

    const sortTrainerOptions = (trainerArray: Trainer[]) => {
        return trainerArray.sort((a,b) => a.name.trim().localeCompare(b.name.trim()));
    }

    const removeBarnTrainersFromAllTrainersGroup = async (barnTrainers: formattedOption[]) => {
        if (allTrainersGroup && formattedTrainers) {
            const updatedFormattedTrainers = formattedTrainers.filter((el) => !barnTrainers.find((bh => bh.value === el.value)));
            const allGroup: formattedGroupOfOptions = {
                label: "All Trainers in RingSide Pro database",
                options: updatedFormattedTrainers
            };
            setAllTrainersGroup(allGroup);
        }
    };

    const getBarnTrainers = async () => {
        setIsLoading(true);

        // Get the current list of trainers
        let currentTrainerList: Trainer[] = [];
        if (selectedBarn) {
            const queryTrainersByBarn = await getTrainersByBarnId(selectedBarn.id);
            if (queryTrainersByBarn.isSuccess) {
                currentTrainerList = queryTrainersByBarn.result;
            }
        }

        // Sort and format the list
        const sortedTrainerList = sortTrainerOptions(currentTrainerList);
        const currentFormattedTrainers = await formatTrainers(sortedTrainerList);

        // Set the formatted options and group in state
        setFormattedBarnTrainers(currentFormattedTrainers);
        const barnGroup: formattedGroupOfOptions = {
            label: (selectedBarn?.name || "Barn") + " Trainers",
            options: currentFormattedTrainers
        };
        setBarnTrainersGroup(barnGroup);

        // Remove the selected barn's trainers from the list of all trainers
        removeBarnTrainersFromAllTrainersGroup(currentFormattedTrainers);

        // Set the first Trainer to be the selected Trainer
        if (currentFormattedTrainers.length > 0) {
            const firstTrainer = currentFormattedTrainers[0];
            setCurrentValue(firstTrainer);
        }

        setIsLoading(false);
    };

    const getTrainers = async () => {
        setIsLoading(true);

        // Get the current list of trainers
        let currentTrainerList: Trainer[] = [];
        const queryResult = await getAllTrainers();
        if (queryResult.isSuccess) {
            currentTrainerList = queryResult.result;
        }

        // Sort and format the list
        const sortedTrainerList = sortTrainerOptions(currentTrainerList);
        const currentFormattedTrainers = await formatTrainers(sortedTrainerList);
        
        // Set the formatted options and group in state
        setFormattedTrainers(currentFormattedTrainers);


        // If barn trainers are already there, remove them from the full list and set All Trainers Group
        if (formattedBarnTrainers) {
            removeBarnTrainersFromAllTrainersGroup(formattedBarnTrainers);
        } else {
            const allGroup: formattedGroupOfOptions = {
                label: "All Trainers in RingSide Pro database",
                options: currentFormattedTrainers
            };
            setAllTrainersGroup(allGroup);    
        }
        
        setIsLoading(false);
    };

    const formatSingleTrainer = (trainer: Trainer) => {
        const value = trainer.id;
        const name = trainer.name || "Unknown - No name found";
        const barnName = trainer?.barnName || trainer.barn?.name || "No Associated Barn Found";

        let object: formattedOption = {
            value: value,
            label: name + " " + barnName,
            trainerName: name,
            trainerNameLabel: name,
            barnLabel: barnName,
            trainer: trainer
        };
        return object;
    }

    const formatTrainers = async (trainerArray: Trainer[]) => {
        setIsLoading(true);

        let formattedTrainers = [];

        for (var i = 0; i < trainerArray.length; i++) {
            const trainer = trainerArray[i];
            let object: formattedOption = formatSingleTrainer(trainer);
            formattedTrainers.push(object);
        }
       
        return formattedTrainers;
    };

    const findValueInList = (value: string, trainerList?: formattedOption[]) => {
        let optionArray = trainerList || formattedTrainers;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        getTrainers();
    }, []);

    useEffect(() => {
        getBarnTrainers();
    }, [selectedBarn]);

    const handleOnChange = (event?: any) => {
        if (event && event.trainer) {
            setCurrentValue(event);
            onSelect(event.trainer);
        } else if (event && event.value) {
            // When a new option is selected from the "Create" option, it will only have a value
            if (event.trainerName) {
                // This option was created by the user and then selected again
                setCurrentValue(event);
                onSelect(null, event.trainerName);
            } else {
                // Format the new option
                const newFormattedOption: formattedOption = {
                    value: uuidv4(),
                    label: event.value + " " + (selectedBarn?.name || ""),
                    trainerName: event.value,
                    trainerNameLabel: event.value,
                    barnLabel: selectedBarn?.name || ""
                };

                if (selectedBarn) {
                    // If a barn is currently selected, then add the new option to the list of barn trainers
                    const updatedFormattedBarnTrainers = formattedBarnTrainers ? formattedBarnTrainers.concat([newFormattedOption]) : [newFormattedOption];
                    const sortedFormattedBarnTrainers = updatedFormattedBarnTrainers.sort((a,b) => a.trainerName.trim().localeCompare(b.trainerName.trim()));
                    setFormattedBarnTrainers(updatedFormattedBarnTrainers);
                    const barnGroup: formattedGroupOfOptions = {
                        label: (selectedBarn?.name || "Barn") + " Trainers",
                        options: sortedFormattedBarnTrainers
                    };
                    setBarnTrainersGroup(barnGroup);
                } else {
                    // Add the trainer to the full list of options
                    const updatedFormattedTrainers = formattedTrainers ? formattedTrainers.concat([newFormattedOption]) : [newFormattedOption];
                    setFormattedTrainers(updatedFormattedTrainers);
                    const allGroup: formattedGroupOfOptions = {
                        label: "All Trainers in RingSide Pro database",
                        options: updatedFormattedTrainers
                    };
                    setAllTrainersGroup(allGroup);
                }
                // Set this option as the currently selected option
                setCurrentValue(newFormattedOption);
                console.log("send back event.value: ", event.value);
                onSelect(null, event.value);
            }
        } else {
            setCurrentValue(null);
            onSelect(null);
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {(barnTrainersGroup && allTrainersGroup) && (
                        <CreatableSelect
                            id="select-or-create-trainer"
                            inputId="select-or-create-trainer-input"
                            placeholder="Type the trainer's name"
                            styles={{
                                // Fixes the overlapping problem of the component
                                control: baseStyles =>(isHeightRequired ? {...baseStyles, height} : {...baseStyles}),
                                menu: provided => ({ ...provided, zIndex: 9999 }),
                            }}
                            defaultValue={currentValue}
                            value={currentValue}
                            menuPortalTarget={document.body}
                            isClearable
                            options={[
                                barnTrainersGroup,
                                allTrainersGroup
                            ]}
                            formatOptionLabel={(option: any) => (
                                option.trainerNameLabel ?
                                (
                                    <div className="p-0">
                                        <div className="font-weight-bold text-default">{option.trainerNameLabel}</div>
                                        <div className="display-5 text-medium">Barn: {option.barnLabel}</div>
                                    </div>
                                )
                                :
                                (
                                    <div className="p-0">
                                        <div className="font-weight-bold text-default">Add New Trainer: {option.value}</div>
                                        <div className="display-5 text-medium">To Barn: {selectedBarn ? selectedBarn.name : "None Selected"}</div>
                                    </div>
                                )
                            )}
                            components={{ SingleValue }}
                            onChange={(event: any) => handleOnChange(event)}
                            createOptionPosition={"last"}
                        />
                    )}
                </>
            }
        </>
    )
};

export default SelectOrCreateTrainer;
