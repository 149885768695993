import { EventEntry, Horse, Membership, Rider } from "../../models";
import { getMembershipsByOrganizationIdByActiveStatus } from "./Membership";
import { getMembershipStatus } from "./MembershipStatus";

const normalizeString = (a: string) => {
    return a.toLowerCase().trim();
}

export function checkRiderNamesMatchOnMembershipAndEntry(mem: Membership, entry: EventEntry) {
    return (normalizeString(mem.rider?.name || "") === normalizeString(entry.rider?.name || "")) || 
    (normalizeString(mem.rider?.name || "") === normalizeString(entry.riderName || "")) ||
    (normalizeString(mem.personName || "") === normalizeString(entry.rider?.name || "")) || 
    (normalizeString(mem.personName || "") === normalizeString(entry.riderName || ""));
}

export function checkHorseNamesMatchOnMembershipAndEntry(mem: Membership, entry: EventEntry) {
    return normalizeString(mem.horse?.name || "") === normalizeString(entry.horseName || "") ||
    normalizeString(mem.horse?.name || "") === normalizeString(entry.horse?.name || "");
}
export async function getEventEntryRiderAndHorseActiveMemberships(entry: EventEntry, organizationId: string, organizationMembershipArray?: Membership[]) {

    // First, check for a rider and horse, and try to use the main function (which is below this function)
    if (entry.rider && entry.horse) {
        let allActiveMembershipsForRiderAndHorse = await getRiderAndHorseActiveMemberships(entry.rider, entry.horse, organizationId, organizationMembershipArray);
        return allActiveMembershipsForRiderAndHorse;
    }

    // If that does not work, continue on
    let allActiveMembershipList: Membership[] = organizationMembershipArray || [];

    // Next, check if organization memberships were provided or not
    if (allActiveMembershipList.length === 0) {
        // If not provided, then get the list of Active Memberships
        const queryResult = await getMembershipsByOrganizationIdByActiveStatus(organizationId, "active");
        if (queryResult.isSuccess) {
            allActiveMembershipList = queryResult.result;
        }
    }

    // Next check the active memberships to find ones that match the current entry
    const matchedMembershipsOnRiderId = allActiveMembershipList.filter(mem => mem.rider?.id === entry.riderId);  
    const matchedMembershipsOnRiderName = allActiveMembershipList.filter(mem => checkRiderNamesMatchOnMembershipAndEntry(mem, entry));

    // Combine the arrays without any duplicates
    const uniqueMatchedMemberships = matchedMembershipsOnRiderId;
    matchedMembershipsOnRiderName.forEach(mem => {
        const found = uniqueMatchedMemberships.findIndex(m => m.id === mem.id);
        if (found === -1) uniqueMatchedMemberships.push(mem);
    });

    // OrganizationMembershipArray could pass in memberships that are expired, so do one more check.
    // And check for horse name
    let allActiveMembershipsForRiderAndHorse = [];
    for (let i = 0; i < uniqueMatchedMemberships.length; i++) {
        const currentMembership = uniqueMatchedMemberships[i];
        // First, check the horse name
        const matchedHorseName = checkHorseNamesMatchOnMembershipAndEntry(currentMembership, entry);
        if (matchedHorseName) {
            // Next, check the membership status
            const membershipStatus = getMembershipStatus(currentMembership);
            if (membershipStatus !== "Expired") {
                allActiveMembershipsForRiderAndHorse.push(currentMembership);
            }
        }
    }

    return allActiveMembershipsForRiderAndHorse;
}

/**
 * This function is used to search for Active Memberships by an organizationId
 * The memberships will match on both Rider name and Horse name
 * 
 * Use Case: RHSC (Rolling Hills Saddle Club) requires a membership per horse+rider combination
 * This function will find the memberships that match the horse and rider from an entry.
 * @param rider 
 * @param horse 
 * @param organizationId 
 * @param organizationMembershipArray 
 * @returns Membership[]
 */
export async function getRiderAndHorseActiveMemberships(rider: Rider, horse: Horse, organizationId: string, organizationMembershipArray?: Membership[]) {
    let allActiveMembershipList: Membership[] = organizationMembershipArray || [];

    // First, check if organization memberships were provided or not
    if (allActiveMembershipList.length === 0) {
        // If not provided, then get the list of Active Memberships
        const queryResult = await getMembershipsByOrganizationIdByActiveStatus(organizationId, "active");
        if (queryResult.isSuccess) {
            allActiveMembershipList = queryResult.result;
        }
    }

    // Next check the active memberships to find ones that match the current entry
    const matchedMembershipsOnRiderId = allActiveMembershipList.filter(mem => mem.rider?.id === rider.id);  
    const matchedMembershipsOnRiderName = allActiveMembershipList.filter(mem => (
        (normalizeString(mem.rider?.name || "") === normalizeString(rider.name || "")) || 
        (normalizeString(mem.personName || "") === normalizeString(rider.name || ""))
    ));

    // Combine the arrays without any duplicates
    const uniqueMatchedMemberships = matchedMembershipsOnRiderId;
    matchedMembershipsOnRiderName.forEach(mem => {
        const found = uniqueMatchedMemberships.findIndex(m => m.id === mem.id);
        if (found === -1) uniqueMatchedMemberships.push(mem);
    });

    // OrganizationMembershipArray could pass in memberships that are expired, so do one more check.
    // And check for horse name
    let allActiveMembershipsForRiderAndHorse = [];
    for (let i = 0; i < uniqueMatchedMemberships.length; i++) {
        const currentMembership = uniqueMatchedMemberships[i];
        // First, check the horse name
        const matchedHorseName = normalizeString(currentMembership.horse?.name || "") === normalizeString(horse?.name || "");
        if (matchedHorseName) {
            // Next, check the membership status
            const membershipStatus = getMembershipStatus(currentMembership);
            if (membershipStatus !== "Expired") {
                allActiveMembershipsForRiderAndHorse.push(currentMembership);
            }
        }
    }

    return allActiveMembershipsForRiderAndHorse;
}