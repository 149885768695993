import { API } from 'aws-amplify';
import { Result } from "../../interfaces/Result";

/**
 * Account is a Stripe connect account - aka an organization
 * More details here: https://stripe.com/docs/payments/payment-methods/connect#creating-paymentmethods-directly-on-the-connected-account 
 * 
 * @param {string} customerid 
 * @param {string} sourceid 
 */
 export async function getSourceById(sourceid: string) {
    try {
        let queryParams = "?sourceid=" + sourceid;
        const path = "/payments/get-source" + queryParams;
        
        const response = await API.get("Payments", path, {});

        const responseBody = JSON.parse(response.body);
        const requestResult = responseBody.response;

        if (requestResult) {
            let result: Result = {isSuccess: true, type: "Stripe", result: requestResult, message: "Successfully got the source."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "Stripe", result: requestResult, message: "Could not get the source."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Could not get the source."};
        return result; 
    }
}