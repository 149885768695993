import { IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { countryList } from "./Country";

interface _Props {
    id?: string,
    countryValue?: string
    isRequired?: Boolean
    onInputChange: Function
    isTextDarker?: boolean
}

const SelectCountry: React.FC<_Props> = ({id, isRequired, countryValue, onInputChange, isTextDarker}) => {

    const [country, setCountry] = useState<string>(countryValue || "-");

    useEffect(() => {
        if (countryValue) setCountry(countryValue);
        else setCountry("-");
    }, [countryValue]);

    return (
        <>
            <FormGroup>
                <IonLabel className={isTextDarker ? "description pl-3 text-darker" : "description pl-3"}>
                    Country 
                    {isRequired && (<span className="description text-danger">
                        *
                    </span>)}
                </IonLabel>
                <Input 
                    id={id || "country-input"}
                    name="country" 
                    type="select" 
                    value={country} 
                    onChange={e => {
                        setCountry(e.target.value);
                        onInputChange(e.target.value)
                    }}
                >
                    <option>-</option>
                    {countryList.map((country: string, index: number) => (
                        <option key={index}>{country}</option>
                    ))}
                </Input>
            </FormGroup>
        </>
    );
};

export default SelectCountry;