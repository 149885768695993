import { 
    IonText
} from "@ionic/react";
import React from "react";

interface _Props {
    description: string
}

const OrganizationDescription: React.FC<_Props> = ({description}) => {
    return (
        <IonText className="ion-justify-content-center">
            <h6 className="text-center">{description}</h6>
        </IonText>
    );
};

export default OrganizationDescription;
