import "./../../theme/main.css";

import {
    IonButton,
    IonCol,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonText,
    IonTitle
} from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import { Auth } from "aws-amplify";
import ErrorBanner from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import { updatePerson } from "../../utilities/person/Person";
import { UpdatePersonInput } from "../../API";

interface _Props {
    openModal: boolean
}

const VerifyAccountModal: React.FC<_Props> = ({openModal}) => {
    const [showModal, setShowModal] = useState(false);
    const [code, setCode] = useState("");
    const [error, setError] = useState("");

    const user = useContext(PersonContext);

    useEffect(() => {
        setShowModal(openModal);
    }, [openModal]);

    const handleSubmit = async () => {
        try {
            Auth.verifyCurrentUserAttributeSubmit("email", code)
            .then(() => {
                if (user) {
                    const personInput: UpdatePersonInput = {
                        id: user.id,
                        isVerified: true
                    };
                    updatePerson(personInput);
                }
                setShowModal(false);
            }).catch((e) => {
                setError(e.message);
            });
        } catch (error: any) {
            setError(error);
        }
    };

    const handleResendEmail = async () => {
        Auth.verifyCurrentUserAttribute("email")
            .then(() => {
                setShowModal(false);
                setShowModal(true);
            }).catch((e) => {
                setError(e.message);
            });
    };

    const handleContactUs = () => {
        setShowModal(false);
    };


    return (
        <IonLabel>
            <IonModal backdropDismiss={false} isOpen={showModal} mode="md">
                <IonContent color="light" className="ion-padding">
                    <IonRow>
                        <IonCol>
                            <IonTitle className="ion-padding-top ion-text-center">
                                <h2>Verify Account</h2>
                                <h5 className="ion-text-wrap">You just received a verification email to: {user.email}.</h5>
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <hr />
                    <IonRow>
                        <IonCol>
                            <IonText className="ion-padding-top">
                                <p>Search for "RingSide Pro" in your inbox. The email may have been sent to a promotions or spam folder.</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <hr/>
                    <form>
                        {error && (
                            <IonRow>
                                <IonCol>
                                    <ErrorBanner error={error} />
                                </IonCol>
                            </IonRow>
                        )}
                        <IonRow>
                            <IonCol offsetMd="3" sizeMd="6" class="ion-text-center">
                                <p>Enter 6 digit the code below.</p>
                                <IonItem color="light">
                                    <IonLabel position="stacked">Code</IonLabel>
                                    <IonInput 
                                        required={true}
                                        aria-required={true}
                                        value={code} 
                                        onIonChange={e => {
                                            setCode(e.detail.value!)
                                        }}
                                    />
                                </IonItem>
                                <IonButton
                                    color="success"
                                    onClick={handleSubmit}
                                >
                                    Submit Code
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </form>
                    <hr/>
                    <IonRow>
                        <IonCol>
                            <IonTitle className="ion-padding-top ion-text-center">
                                <p className="ion-text-wrap">Didn't receive an email?</p>
                            </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton color="tertiary" onClick={() => handleResendEmail()}>
                                Resend Email
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <a href="mailto:hello@ringsidepro.com">
                                <IonButton fill="clear" color="tertiary" onClick={() => handleContactUs()}>
                                    Contact Us
                                </IonButton>
                            </a>
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
        </IonLabel>
    );
};

export default VerifyAccountModal;
