/**
 * Utility function to handle removing all of a user's data
 * 
 * Note: Datastore uses soft deletes to flag a record in Dynamo DB as deleted
 * It does not actually remove the record, because that would cause syncing issues with local devices
 * For more information: watch the video starting at minute 27
 * https://docs.amplify.aws/lib/datastore/how-it-works/q/platform/js
 * If you want to force the "hard delete" you can change the TTL
 * https://docs.aws.amazon.com/appsync/latest/devguide/conflict-detection-and-sync.html
 * 
 * Note: Amplify Admin Queries does not allow a Cognito user to actually be deleted
 * It can only disable Cognito Users and remove them from groups
 */
import { Person, S3Object } from "../../models";
import { Result } from "../../interfaces/Result";
import { handleDeleteS3Item } from "../s3Object/s3Object";

import { disableUser, removeFromGroup } from "../adminQueries/removeUser";
import { getUser } from "../user/User";
import { deletePerson } from "../person/Person";
import { getGroupsForUser } from "../adminQueries/userGroups";

export async function deleteAllUserData(person: Person): Promise<Result> {
    let result: Result;

    try {
        // Delete any content in S3
        if (person.picture) {
            const profilePicture: S3Object = person.picture;
            const fileName = profilePicture.key;
            await handleDeleteS3Item(fileName);
        }

        // Delete user data in Dynamo DB
        // TO DO

        await deletePerson({id: person.id})

        // Disable in Cognito
        const user = await getUser();
        if (user) {
            const groups = await getGroupsForUser(user.username);
            if (groups.Groups.length > 0) {
                const groupsArray: any[] = groups.Groups;
                for (let i = 0; i < groupsArray.length; i++) {
                    const currentGroup: any = groupsArray[i];
                    const groupName = currentGroup.GroupName;
                    await removeFromGroup(user, groupName);
                }
            }
            await disableUser(user);
        } else {
            result = {isSuccess: false, type: "deleteAllUserData", result: user, message: "Could not delete the user."};
            return result;
        }

        result = {isSuccess: true, type: "deleteAllUserData", result: "Successfully deleted all of the user data.", message: "Completed the delete."};
        return result;
        
    } catch (error: any) {
        console.error("Error: ", error);
        result = {isSuccess: false, type: "deleteAllUserData", result: error, message: "Could not delete the user."};
        return result;
    }
}