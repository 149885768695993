import React from "react";

interface _Props {
    showWordRequired?: boolean
}

const RequiredInputIndicator: React.FC<_Props> = ({showWordRequired}) => {

    return (
        <>
            <span className="text-danger description">*{showWordRequired && "required"}</span>
        </>
    );
};

export default RequiredInputIndicator;