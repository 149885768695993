import { IonCard, IonCol, IonRow } from "@ionic/react";
import moment from "moment";
import React from "react";
import { OrganizationMembershipType, Membership } from "../../../models";

interface _Props {
    membershipType: OrganizationMembershipType | undefined;
    membership: Membership | undefined;
};

const EditMembershipApplicationWaivers: React.FC<_Props> = ({membershipType, membership}) => {
    return (
        <React.Fragment>
            <IonCard color="white" className="ion-padding">
                <h3>Waivers</h3>
                {membershipType?.organizationRules && membershipType?.organizationRules?.length === 0 ?
                    <p>This membership type does not have any waivers to sign.</p>
                :
                    (membershipType?.organizationRules?.map((rule, index) => {
                        const found = membership?.organizationAgreements?.find((item) => {
                            return rule?.id === item?.organizationRuleId;
                        });
                        return(found ?
                            <div key={index}>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        {found?.organizationRuleTitle}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        Signed by: {found?.firstName} {found?.lastName}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol/>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        Signed on: {moment(found?.createdOn).format("MM/DD/YYYY")}
                                    </IonCol>
                                </IonRow>
                                <hr/>
                            </div>
                        :
                            <div key={index}>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        {rule?.title}
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        No signature found.
                                    </IonCol>
                                </IonRow>
                                <hr/>
                            </div>
                        )
                    }))
                }
            </IonCard>
        </React.Fragment>
    );
};

export default EditMembershipApplicationWaivers;