import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Membership, Rider, Trainer } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import { getUSEFPersonById } from "../../utilities/USEFAPI/USEFIndividual";
import { USEFIndividual } from "../../interfaces/USEFData";
import { getUSEFMembershipByRiderId } from "../../utilities/membership/Membership";

interface _Props {
    rider?: Rider
    onChange: Function
}

const USEFRiderMembershipForm: React.FC<_Props> = ({rider, onChange}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [number, setNumber] = useState<string | null | undefined>();
    const [usefData, setUsefData] = useState<USEFIndividual | null | undefined>();
    const [membership, setMembership] = useState<Membership | null | undefined>();
    const [error, setError] = useState<string>("");

    const getUSEFMembership = async (rider: Rider) => {
        const queryResult = await getUSEFMembershipByRiderId(rider.id);
        if (queryResult.isSuccess) {
            setMembership(queryResult.result);
        }
    }

    useEffect(() => {
        if (rider) getUSEFMembership(rider);
    }, [rider]);

    const verifyForm = () => {
        try {
            if (!number) {
                setError("You must include a number.");
                return false;
            }
            return true;
        } catch (error: any) {
            setError(error);
            return false;
        }
    }

    const checkUSEFNumber = async () => {
        if (number) {
            const queryResult = await getUSEFPersonById(number);
            if (queryResult.isSuccess) {
                setUsefData(queryResult.result);
                onChange(queryResult.result);
            }
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) {
            setError("");
            // await checkUSEFNumber();
            onChange(number);
        }
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Membership Number</IonLabel>
                            <IonInput 
                                type="text"
                                value={number}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setNumber(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default USEFRiderMembershipForm;