import {
    IonAlert,
    IonButton,
    IonCol,
    IonRow,
    IonText,
} from "@ionic/react";
import ErrorBanner from "../Banners/ErrorBanner";
import React, { useContext, useState } from "react";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import { deleteAllUserData } from "../../utilities/delete/deleteAllUserData";
import { useHistory } from "react-router";

const DeleteAccountForm: React.FC = () => {
    const history = useHistory();

    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [error, setError] = useState("");

    const handleSubmit = async () => {
        setShowAlert(true);
    }

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            const deleteResult = await deleteAllUserData(user);
            if (deleteResult.isSuccess) {
                history.push("/index/logout");
            } else {
                setError(deleteResult.message);
            }
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
        }
    }


    return (
        <form>
            {error && (
                <IonRow>
                    <IonCol>
                        <ErrorBanner error={error} />
                    </IonCol>
                </IonRow>
            )}
            <IonRow>
                <IonCol>
                    <IonText>Deleting your account will remove all of your data. This action cannot be undone.</IonText>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol offsetMd="4" sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            className="ion-margin-top"
                            color="danger"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            Delete
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={"Alert!"}
                subHeader={"This cannot be undone."}
                message={"You are about to delete your account and all information linked to it. You will not be able to reactivate this account."}
                buttons={[
                    {
                        text: "Cancel",
                        role: "cancel",
                        cssClass: "secondary"
                    },
                    {
                        text: "Delete",
                        cssClass: "danger",
                        handler: () => {
                            handleDelete();
                        }
                    }
                ]}
            />
        </form>
    );
};

export default DeleteAccountForm;