import {
    IonButton,
    IonButtons,
    IonCol,
    IonToolbar
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { EventDay } from "../../models";

import monBlueIcon from "../../assets/img/icons/mon-blue.png";
import tuesBlueIcon from "../../assets/img/icons/tues-blue.png";
import wedBlueIcon from "../../assets/img/icons/wed-blue.png";
import thursBlueIcon from "../../assets/img/icons/thurs-blue.png";
import friBlueIcon from "../../assets/img/icons/fri-blue.png";
import satBlueIcon from "../../assets/img/icons/sat-blue.png";
import sunBlueIcon from "../../assets/img/icons/sun-blue.png";

import monGrayIcon from "../../assets/img/icons/mon-gray.png";
import tuesGrayIcon from "../../assets/img/icons/tues-gray.png";
import wedGrayIcon from "../../assets/img/icons/wed-gray.png";
import thursGrayIcon from "../../assets/img/icons/thurs-gray.png";
import friGrayIcon from "../../assets/img/icons/fri-gray.png";
import satGrayIcon from "../../assets/img/icons/sat-gray.png";
import sunGrayIcon from "../../assets/img/icons/sun-gray.png";

import moment from "moment";

interface _Props {
    days: EventDay[]
    onSelect: Function
}

interface dayTab {
    icon: string
    activeIcon: string
    date: string //Like MM/DD
}

const ScheduleDayNavbar: React.FC<_Props> = ({days, onSelect}) => {

    const [dayTabs, setDayTabs] = useState<dayTab[] | null | undefined>();
    const [activeDayTabIndex, setActiveDayTabIndex] = useState<number | null | undefined>(0);

    const getIcon = (dayOfWeek: string) => {
        if (dayOfWeek === "Monday") return monGrayIcon;
        if (dayOfWeek === "Tuesday") return tuesGrayIcon;
        if (dayOfWeek === "Wednesday") return wedGrayIcon;
        if (dayOfWeek === "Thursday") return thursGrayIcon;
        if (dayOfWeek === "Friday") return friGrayIcon;
        if (dayOfWeek === "Saturday") return satGrayIcon;
        if (dayOfWeek === "Sunday") return sunGrayIcon;
        if (dayOfWeek === "") return monGrayIcon;
    }

    const getActiveIcon = (dayOfWeek: string) => {
        if (dayOfWeek === "Monday") return monBlueIcon;
        if (dayOfWeek === "Tuesday") return tuesBlueIcon;
        if (dayOfWeek === "Wednesday") return wedBlueIcon;
        if (dayOfWeek === "Thursday") return thursBlueIcon;
        if (dayOfWeek === "Friday") return friBlueIcon;
        if (dayOfWeek === "Saturday") return satBlueIcon;
        if (dayOfWeek === "Sunday") return sunBlueIcon;
        if (dayOfWeek === "") return monBlueIcon;
    }

    const formatDaysIntoTabs = (days: EventDay[]) => {
        let result = [];
        for (var i = 0; i < days.length; i++) {
            const day = days[i];
            const date = moment(day.awsdate).format("MM/DD");
            const icon = getIcon(day.dayofweek || "");
            const activeIcon = getActiveIcon(day.dayofweek || "");
            let tab: dayTab = {
                icon: icon || monGrayIcon,
                activeIcon: activeIcon || monBlueIcon,
                date
            };
            result.push(tab);
        }
        setDayTabs(result);
    }

    useEffect(() => {
        if (days) formatDaysIntoTabs(days);
    }, [days]);

    const toggleDays = (index: number) => {
        setActiveDayTabIndex(index);
        onSelect(index);
    }

    return (
        <IonToolbar id="editEventMenu" color="white">
            {(dayTabs && dayTabs?.length > 3) && <p className="small">You may need to scroll horizationally to see all days.</p>}
            <div className="scrollmenu">
                <IonButtons>
                    {dayTabs?.map((tab, index) => (
                        <IonCol key={index} sizeXs="4" sizeMd="3">
                            <IonButton onClick={() => toggleDays(index)}>
                                <img src={activeDayTabIndex === index ? tab.activeIcon : tab.icon} className="nav-img" />
                                <p color={activeDayTabIndex === index ? "primary" : "secondary"}>{tab.date}</p>
                            </IonButton>
                        </IonCol>
                    ))}
                </IonButtons>
            </div>
        </IonToolbar>
    );
};

export default ScheduleDayNavbar;
