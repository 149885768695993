import { jumpHeight } from "../../../interfaces/JumpHeight"

export interface divisionTemplateData {
    name: string
    isDivision: boolean //if not division, then class
    description?: string
    discipline?: string
    number?: number
    canBeCombined?: boolean,
    canBeCombinedWith?: string[]
    jumpHeights?: jumpHeight[],
    minHorseHeight?: number,
    maxHorseHeight?: number
    minRiderAge?: number
    maxRiderAge?: number
    minHorseAge?: number
    maxHorseAge?: number
    maxHorsePreviousYearsofShowing?: number
}

export const vhsaAssocHunterData: divisionTemplateData[] = [
    {
        name: "Working Hunter",
        discipline: "hunter",
        description: "Open to animals that are not entered in a pony division at the same show. As a clarification, any animal that competes in a VHSA Associate recognized horse division is ineligible to compete in any VHSA Associate Pony Division at the same show. The VHSA will only accept points for the Working Hunter Division. If a show does not have a Working Hunter Division, but does have an Open Division then we will accept those points toward the Working Hunter Division as long as there are at least three Working Hunter exhibitors competing in the Division and they meet the VHSA division specifications. In order to accrue VHSA Associate points the height for this division shall be no lower than 2' 6\". The Working Hunter Division can only be combined with the Green Hunter Horse Division and the Thoroughbred Hunter Division. It cannot be combined with any other Division, including nonVHSA Divisions. It is the Show Secretary's responsibility to indicate on the results which division each horse’s points should be awarded to. At the VHSA Associate Championship Horse Show the Jump Height for this Division will be 3'.",
        isDivision: true,
        canBeCombined: true,
        canBeCombinedWith: ["Green Hunter Horse Division", "Thoroughbred Hunter Division"],
        jumpHeights:[
            {
                feet: 2,
                inches: 6
            },
            {
                feet: 2,
                inches: 9
            },
            {
                feet: 3
            }
        ]
    },
    {
        name: "Green Hunter Horse",
        description: "A green horse is a horse of any age in his first or second year of showing in any classes that require horses to jump the designated height for green horses. The individual shows, show series or Associations determine that height, the only requirement is that in order to accrue points, the jump height be no lower than 2'6\". The Green Hunter may be combined with Working Hunter Division and the Thoroughbred Hunter Division. It cannot be combined with any other division, including non-VHSA Divisions. The Green Hunter Division may be combined with the Green Pony Division if it is not combined with Working Hunter and/or Thoroughbred Hunter at the same show. Do not report points for ponies that were ridden by adults. It is the Show Secretary's responsibility to indicate on the results which division each horse’s points should be awarded to. A horse’s green status is not affected if the horse has not been shown at more than four (4) competitions prior to September 1st and if it was not shown at that height after September 1st in the same year. In order to maintain green status for the next competition year, the horse must be withdrawn from competition over regulation height and the owner must notify the VHSA office by letter, via certified/return receipt mail on or before November 30th of the year of withdrawal. The horse’s name and recording number must be included in the letter. In order to accrue VHSA Associate points the height for this division shall be no lower than 2'6\". At the VHSA Associate Championship Horse Show the Jump Height for this Division will be 2'9\" to 3'.",
        discipline: "hunter",
        isDivision: true,
        maxHorsePreviousYearsofShowing: 1,
        canBeCombined: true,
        canBeCombinedWith: ["Working Hunter Division", "Thoroughbred Hunter Division"],
        jumpHeights:[
            {
                feet: 2,
                inches: 6
            },
            {
                feet: 2,
                inches: 9
            },
            {
                feet: 3
            }
        ]
    },
    {
        name: "Thoroughbred Hunter",
        description: "The VHSA Associate Thoroughbred Hunter Division is limited to horses who are registered with or are eligible to be registered with the Jockey Club. In order to accrue VHSA Associate points the height for this division shall be no lower than 2'6\". The VHSA Associate Thoroughbred Hunter Division may only be combined with the Working Hunter and the Green Hunter. It cannot be combined with any other Division, including non-VHSA Divisions. It is the Show Secretary's responsibility to indicate on the results which division each horse’s points should be awarded to. At the VHSA Associate Championship Horse Show the jump height for this division will be 2'9\" to 3'0\". Only the top twenty point accruers, including ties, will be eligible to compete at the VHSA Associate Championship Horse Show. Horses must be registered with the VHSA and owners must be current VHSA members in order to accrue points towards VHSA Associate year-end awards. ",
        discipline: "hunter",
        isDivision: true,
        canBeCombined: true,
        canBeCombinedWith: ["Working Hunter Division", "Green Hunter Horse Division"],
        jumpHeights:[
            {
                feet: 2,
                inches: 6
            },
            {
                feet: 2,
                inches: 9
            },
            {
                feet: 3
            }
        ]
    },
    {
        name: "Junior/Children’s Hunter",
        description: "Open to horses ridden by Juniors. If a show sends in results for a Junior's Division and results in for a Children's Division, the VHSA will count points for the same horse from only one division. If the Children's and Adult's Divisions are combined, then it is the Show Secretary's responsibility to indicate which is the Child and which is the Adult. The VHSA will not accept any points for any Juniors on ponies. In order to accrue VHSA Associate points the height for this division shall be no lower than 2' 6\". The Junior/Children’s Division may only be combined with the Amateur Division. At the VHSA Associate Championship Horse Show, jump height for this Division will be 3'.",
        discipline: "hunter",
        isDivision: true,
        canBeCombined: true,
        canBeCombinedWith: ["Adult Amateur Division"],
        minHorseHeight: 14.3,
        maxRiderAge: 17,
        jumpHeights:[
            {
                feet: 2,
                inches: 6
            },
            {
                feet: 2,
                inches: 9
            },
            {
                feet: 3
            }
        ]
    },
    {
        name: "Adult Amateur Hunter",
        description: "Open to horses ridden by an Adult Amateur. The Adult Amateur definition is the same as the definition accepted by the VHSA Regular Program. Each Amateur/Adult should be capable of signing a statement to that effect. It is the responsibility of each show and/or horse show organization to police the Amateur status of its participants. If the Children’s and Adult Divisions are combined, then it is the Show Secretary’s responsibility to indicate which is the child and which is the adult. Amateurs on ponies may not accumulate points in this division. In order to accrue VHSA Associate points the height for this division shall be no lower than 2' 6\". The Adult Amateur Division may only be combined with the Junior/Children’s Division. At the VHSA Associate Championship Horse Show the Jump Height for this Division will be 3'.",
        discipline: "hunter",
        isDivision: true,
        canBeCombined: true,
        canBeCombinedWith: ["Junior/Children’s Division"],
        minHorseHeight: 14.3,
        minRiderAge: 18,
        jumpHeights:[
            {
                feet: 2,
                inches: 6
            },
            {
                feet: 2,
                inches: 9
            },
            {
                feet: 3
            }
        ]
    },
    {
        name: "Small-Medium Pony Hunter",
        description: "Open to ponies ridden by Juniors. If this division is combined with the Large Pony Division, then it is the Show Secretary's responsibility to indicate the size of each pony. All VHSA Associate Pony Divisions including unjudged and warm-up classes are restricted to Junior riders only. In order to accrue VHSA Associate points the height for this division shall be no lower than Small 2', Medium2'3\". The Small/Medium Pony Division may be combined with the Large Pony Division and the Green Pony Division. At the VHSA Associate Championship Horse Show the Jump Heights for this Division will be Small - 2', Medium - 2'3\"",
        discipline: "hunter",
        isDivision: true,
        canBeCombined: true,
        canBeCombinedWith: ["Large Pony Hunter Division", "Green Pony Hunter Division"],
        maxHorseHeight: 13.2,
        maxRiderAge: 17,
        jumpHeights:[
            {
                feet: 2
            },
            {
                feet: 2,
                inches: 3
            }
        ]
    },
    {
        name: "Large Pony Hunter",
        description: "Open to ponies ridden by Juniors. If this division is combined with the Small-Medium Pony Division, then it is the Show Secretary's responsibility to indicate the size of each pony. All VHSA Associate Pony Divisions including unjudged and warm-up classes are restricted to Junior riders only. In order to accrue VHSA Associate points the height for this division shall be no lower than 2'6\". The Large Pony Division may be combined with the Small/Medium Pony Division and the Green Pony Division. At the VHSA Associate Championship Horse Show the Jump Height for this Division will be 2'9\".",
        discipline: "hunter",
        isDivision: true,
        canBeCombined: true,
        canBeCombinedWith: ["Small-Medium Pony Hunter Division", "Green Pony Hunter Division"],
        minHorseHeight: 13.3,
        maxHorseHeight: 14.2,
        maxRiderAge: 17,
        jumpHeights:[
            {
                feet: 2,
                inches: 6
            },
            {
                feet: 2,
                inches: 9
            }
        ]
    },
    {
        name: "Green Pony Hunter",
        description: "Open to ponies ridden by Juniors. If this division is combined with the Green Horse Division, then it is the Show Secretary's responsibility to indicate which is the horse and which is the pony. All VHSA Associate Pony Divisions including unjudged and warm-up classes are restricted to Junior riders only. A Green Pony is a pony of any age in his first year of showing in any classes that require ponies to jump the designated height for green pony. (Ponies are only allowed to show one year as Green Ponies). A pony’s green status is not affected if the pony has not been shown at more than four (4) competitions prior to September 1st and if it was not shown at that height after September 1st in the same year. In order to maintain green status for the next competition year, the pony must be withdrawn from competition over regulation height and the owner must notify the VHSA office by letter, via certified/return receipt mail on or before November 30th of the year of withdrawal. The pony’s name and recording number must be included in the letter. In order to accrue VHSA Associate points the height for this division shall be no lower than Small 2', Medium 2'3\", Large 2'6\". The Green Pony Division may be combined with the Pony Hunter  Division or the Green Hunter Division if the Green Hunter Division has not combined with the Working Hunter Division. At the VHSA Associate Championship Horse Show the Jump Heights for this Division will be Small - 2', Medium - 2'3\", Large - 2'6\".",
        discipline: "hunter",
        isDivision: true,
        canBeCombined: true,
        canBeCombinedWith: ["Small-Medium Pony Hunter Division", "Large Pony Hunter Division", "Green Horse Hunter Division"],
        maxHorseHeight: 14.2,
        maxRiderAge: 17,
        maxHorsePreviousYearsofShowing: 0,
        jumpHeights:[
            {
                feet: 2
            },
            {
                feet: 2,
                inches: 3
            },
            {
                feet: 2,
                inches: 6
            }
        ]
    },
    {
        name: "Adult Hunter Pleasure Horse",
        description: "Open to horses shown with hunter tack on the flat and, if applicable, over fences ridden by an Adult. Points for over fences will be accepted along with on the flat only if over fences is currently part of the show’s Hunter Pleasure Division. It is acceptable for Associate Horse Shows to combine the Adult Hunter Pleasure and the Junior Pleasure Hunter Divisions, however, it is the Show Secretary’s responsibility to indicate on the results which is the Adult rider and which is the Junior rider. The Adult Hunter Pleasure Horse Division may only be combined with the Junior Hunter Pleasure Horse and/or the Pleasure Pony Hunter Division. Horses may cross enter into another VHSA Associate Pleasure Division at same show.",
        discipline: "hunter",
        isDivision: true,
        canBeCombined: true,
        canBeCombinedWith: ["Junior Hunter Pleasure Horse Division", "Hunter Pleasure Pony Division"],
        minHorseHeight: 14.3,
        minRiderAge: 18,
    },
    {
        name: "Junior Hunter Pleasure Horse",
        description: "Open to horses shown with hunter tack on the flat and, if applicable, over fences ridden by a Junior. Points for over fences will be accepted along with on the flat only if over fences is currently part of the show’s Hunter Pleasure Division. It is acceptable for Associate Horse Shows to combine the Adult Hunter Pleasure and the Junior Pleasure Hunter Divisions, however, it is the Show Secretary’s responsibility to indicate on the results which is the Adult rider and which is the Junior rider. The Junior Hunter Pleasure Horse Division may only be combined with the Adult Hunter Pleasure Horse and/or the Pleasure Pony Hunter Division. Horses may cross enter into another VHSA Associate Pleasure Division at same show.",
        discipline: "hunter",
        isDivision: true,
        canBeCombined: true,
        canBeCombinedWith: ["Adult Hunter Pleasure Horse Division", "Hunter Pleasure Pony Division"],
        minHorseHeight: 14.3,
        maxRiderAge: 17,
    },
    {
        name: "Hunter Pleasure Pony",
        description: "Open to ponies shown with hunter tack on the flat and, if applicable, over fences, ridden by a Junior. Points for over fences will be accepted along with on the flat only if over fences is currently part of the show’s Hunter Pleasure Division. The Hunter Pleasure Pony Division may only be combined with the Adult Hunter Pleasure Horse and/or the Junior Hunter Pleasure Division. All VHSA Associate Pony Divisions including unjudged and warm-up classes is restricted to Junior riders only.",
        discipline: "hunter",
        isDivision: true,
        canBeCombined: true,
        canBeCombinedWith: ["Junior Hunter Pleasure Horse Division", "Adult Hunter Pleasure Horse Division"],
        maxHorseHeight: 14.2,
        maxRiderAge: 17,
    },
    {
        name: "Hunter Short Stirrup",
        description: "Open to horses and ponies ridden by children 12 years and under, however, shows may specify a lower age limit, i.e., 10 years and under. The division will consist of three (3) classes, two over fence classes and a walk, trot, canter under saddle class. Short Stirrup riders, ponies and horses may not cross enter into any other VHSA Associate Division or a VHSA Associate rated Equitation Class with the exception of VHSA Associate Junior Equitation On The Flat and Pleasure Pony or Pleasure Horse at any show in which they exhibit in the Short Stirrup Division. Short Stirrup riders will be limited to no more than two (2) entries in the Short Stirrup Division at any one horse show. The jump height for this division shall be decided by individual horse shows, but should be no lower than 18\" and no higher than 2' with a minimum of four (4) jumps. The Short Stirrup division cannot be combined with any other Division. At the year-end Championship show Short Stirrup riders may cross enter into the Equitation on the Flat, Pleasure Horse or Pleasure Pony Division. At the year-end Championship show Short Stirrup ponies and horses may cross enter into the Equitation on the Flat, Pleasure Horse, Pleasure Pony and any non-point division, i.e., Special Hunters. At the VHSA Associate Championship Horse Show the Short Stirrup Division will be split nine (9) years and under and ten to twelve (10-12) years old (show age). At the VHSA Associate Championship show the jump height will be approximately 18\" and may include changes of direction.",
        discipline: "hunter",
        isDivision: true,
        canBeCombined: false,
        maxRiderAge: 12,
        jumpHeights:[
            {
                inches: 18
            },
            {
                feet: 2
            }
        ]
    },
    {
        name: "VHSA Pony Equitation Class",
        description: "Open to Juniors mounted on ponies. The judges may select at least one-fourth of the class to show at a walk, trot, and canter and to perform additional tests at the judge’s discretion. Jump heights can be determined by each show. Shows are allowed to combine any of the three Equitation classes, however, the secretary must specify on the results sheet by each rider’s name as to which class points should be applied to (i.e., Pony, Junior or Adult). A rider can only participate in one Associate Equitation Class per show, with the exception of the VHSA Equitation on the Flat Class. The Associate Equitation Classes cannot be a part of the \“Regular\” VHSA Equitation Medal Classes, however, they can be a part of any other Equitation Class, for example: XYZ Horse Show Association/VHSA Associate Pony Equitation Class and must be listed in the horse show’s Prize List as such. The rider must be a VHSA member or the points will not count towards the Associate year-end Championship. If anyone shows in this class and is not a member they will not receive points, nor will anyone be moved up in placement due to a non-member showing in that particular class. Equitation Classes at the Associate Championship Horse Show will be double points. In order to accrue VHSA Associate points the heights for this class shall be no lower than small 2', medium 2'3\", and large 2' 6\". At the VHSA Associate Championship Horse Show the Jump Heights for this Class will be Small - 2', Medium - 2'3\", Large - 2'9\".",
        discipline: "hunter_equitation",
        isDivision: false,
        canBeCombined: true,
        canBeCombinedWith: ["VHSA Children’s/Junior Equitation Class", "VHSA Adult Amateur Equitation Class"],
        maxRiderAge: 17,
        maxHorseHeight: 14.2,
        jumpHeights:[
            {
                feet: 2
            },
            {
                feet: 2,
                inches: 3
            },
            {
                feet: 2,
                inches: 6
            },
            {
                feet: 2,
                inches: 9
            },
        ]
    },
    {
        name: "VHSA Children’s/Junior Equitation Class",
        description: "Open to Juniors mounted on horses. The judges may select at least one-fourth of the class to show at a walk, trot, and canter and to perform additional tests at the judge’s discretion. Jump heights can be determined by each show. Shows are allowed to combine any of the three Equitation classes, however, the secretary must specify on the results sheet by each rider’s name as to which class points should be applied to (i.e., Pony, Junior or Adult). A rider can only participate in one Associate Equitation Class per show, with the exception of the VHSA Equitation on the Flat Class. The Associate Equitation Classes cannot be a part of the \“Regular\” VHSA Equitation Medal Classes, however, they can be a part of any other Equitation Class, for example: XYZ Horse Show Association/VHSA Associate PonyEquitationClass and must be listed in the horse show’s Prize List as such. The rider must be a VHSA member or the points will not count towards the Associate year-end Championship. If anyone shows in this class and is not a member they will not receive points, nor will anyone be moved up in placement due to a non-member showing in that particular class. Equitation Classes at the Associate Championship Horse Show will be double points. In order to accrue VHSA Associate points the height for this class shall be no lower than 2'6\". At the VHSA Associate Championship Horse Show the Jump Height for this Class will be 3'.",
        discipline: "hunter_equitation",
        isDivision: false,
        canBeCombined: true,
        canBeCombinedWith: ["VHSA Pony Equitation Class", "VHSA Adult Amateur Equitation Class"],
        maxRiderAge: 17,
        minHorseHeight: 14.3,
        jumpHeights:[
            {
                feet: 2,
                inches: 6
            },
            {
                feet: 2,
                inches: 9
            },
            {
                feet: 3
            },
        ]
    },
    {
        name: "VHSA Adult Amateur Equitation Class",
        description: "Open to  Adult Amateurs mounted on horses. The judges may select at least one-fourth of the class to show at a walk, trot, and canter and to perform additional tests at the judge’s discretion. Jump heights can be determined by each show. Shows are allowed to combine any of the three Equitation classes, however, the secretary must specify on the results sheet by each rider’s name as to which class points should be applied to (i.e., Pony, Junior or Adult). A rider can only participate in one Associate Equitation Class per show, with the exception of the VHSA Equitation on the Flat Class. The Associate Equitation Classes cannot be a part of the \“Regular\” VHSA Equitation Medal Classes, however, they can be a part of any other Equitation Class, for example: XYZ Horse Show Association/VHSA Associate PonyEquitationClass and must be listed in the horse show’s Prize List as such. The rider must be a VHSA member or the points will not count towards the Associate year-end Championship. If anyone shows in this class and is not a member they will not receive points, nor will anyone be moved up in placement due to a non-member showing in that particular class. Equitation Classes at the Associate Championship Horse Show will be double points. In order to accrue VHSA Associate points the height for this class shall be no lower than 2'6\". At the VHSA Associate Championship Horse Show the Jump Height for this Class will be 3'.",
        discipline: "hunter_equitation",
        isDivision: false,
        canBeCombined: true,
        canBeCombinedWith: ["VHSA Pony Equitation Class", "VHSA Children’s/Junior Equitation Class"],
        minRiderAge: 18,
        minHorseHeight: 14.3,
        jumpHeights:[
            {
                feet: 2,
                inches: 6
            },
            {
                feet: 2,
                inches: 9
            },
            {
                feet: 3
            },
        ]
    },
    {
        name: "VHSA Junior Equitation on the Flat 15-17",
        description: "Open to riders who have reached their 15th birthday by December 1st of the calendar year, but not reached their 18th birthday as of December 1st of the calendar year. To be shown at a walk, trot and canter both ways of the ring and judged on position, control and guidance. Tests 1-19 may be used, excluding those requiring work over fences. Three entries are necessary to fill this class. The rider must be a VHSA member or the points will not count towards the Associate year-end Championship. If anyone shows in this class and is not a member they will not receive points, nor will anyone be moved up in placement due to a non-member showing in that particular class. Equitation Classes at the Associate Championship Horse Show will be double points. If there are insufficient entries, this class may be combined with the Junior Equitation on the Flat 14 & under and/or the Adult Amateur Equitation on the Flat.",
        discipline: "hunter_equitation",
        isDivision: false,
        canBeCombined: true,
        canBeCombinedWith: ["VHSA Junior Equitation on the Flat 14 & Under", "VHSA Adult Amateur Equitation on the Flat"],
        minRiderAge: 15,
        maxRiderAge: 17
    },
    {
        name: "VHSA Junior Equitation on the Flat 14 & Under",
        description: "Open to riders who have not reached their 15th birthday by December 1st of the calendar year. To be shown at a walk, trot and canter both ways of the ring and judged on position, control and guidance. Tests 1-19 may be used, excluding those requiring work over fences. Three entries are necessary to fill this class. The rider must be a VHSA member or the points will not count towards the Associate year-end Championship. If anyone shows in this class and is not a member they will not receive points, nor will anyone be moved up in placement due to a non-member showing in that particular class. Equitation Classes at the Associate Championship Horse Show will be double points. If there are insufficient entries, this class may be combined with the Junior Equitation on the Flat 15-17 and/or the Adult Amateur Equitation on the Flat.",
        discipline: "hunter_equitation",
        isDivision: false,
        canBeCombined: true,
        canBeCombinedWith: ["VHSA Junior Equitation on the Flat 15-17", "VHSA Adult Amateur Equitation on the Flat"],
        minRiderAge: 15,
        maxRiderAge: 17
    },
    {
        name: "VHSA Adult Amateur Equitation on the Flat",
        description: "Open to amateurs 18 years of age and older. To be shown at a walk, trot and canter both ways of the ring and judged on position, control and guidance. Tests 1-19 may be used, excluding those requiring work over fences. Three entries are necessary to fill this class. The rider must be a VHSA member or the points will not count towards the Associate year-end Championship. If anyone shows in this class and is not a member they will not receive points, nor will anyone be moved up in placement due to a non-member showing in that particular class. Equitation Classes at the Associate Championship Horse Show will be double points. If there are insufficient entries, this class may be combined with the Junior Equitation on the Flat 15-17 and/or the Junior Equitation on the Flat 14 & Under.",
        discipline: "hunter_equitation",
        isDivision: false,
        canBeCombined: true,
        canBeCombinedWith: ["VHSA Junior Equitation on the Flat 15-17", "VHSA Junior Equitation on the Flat 14 & Under"],
        minRiderAge: 18
    }
];