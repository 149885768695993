import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonTitle,
    IonToggle,
    IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Division, EventDivision, PointTable, PrizeMoneyTable } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import { getDivisionById, updateDivision } from "../../utilities/division/Division";
import SelectDiscipline from "../Discipline/SelectDiscipline";
import SuccessBanner from "../Banners/SuccessBanner";
import { UpdateDivisionInput, UpdateEventDivisionInput } from "../../API";
import { Input } from "reactstrap";
import DivisionEntryFee from "../EventClass/EventClassForm/DivisionEntryFee";
import ClassPrizeMoney from "../EventClass/EventClassForm/ClassPrizeMoney";
import AllowedNumberOfEntries from "../EventClass/EventClassForm/AllowedNumberOfEntries";
import { deleteEventDivision, getEventDivisionById, updateEventDivision } from "../../utilities/eventDivision/EventDivision";
import { deleteEventClass, getEventClassesByEventDivisionId } from "../../utilities/eventClass/EventClass";
import { close } from "ionicons/icons";
import SelectEventDivision from "./SelectEventDivision";
import ClassPointTable from "../EventClass/EventClassForm/ClassPointTable";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    organizationId: string
    eventDivision: EventDivision
    onSubmit: Function
}

const EventDivisionForm: React.FC<_Props> = ({eventDivision, organizationId, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [division, setDivision] = useState<Division | undefined>(); 
    const [name, setName] = useState(eventDivision.name);
    const [description, setDescription] = useState((eventDivision && eventDivision.division) ? eventDivision.division.description : "");
    const [discipline, setDiscipline] = useState((eventDivision && eventDivision.division) ? eventDivision.division.discipline : ""); 
    const [minNumberEntries, setMinNumberEntries] = useState(3);
    const [maxNumberEntries, setMaxNumberEntries] = useState<number | undefined>();
    const [entryFee, setEntryFee] = useState((eventDivision && eventDivision.entryFee) ? parseFloat(eventDivision.entryFee) : 0);
    const [prizeMoneyTable, setPrizeMoneyTable] = useState<PrizeMoneyTable | undefined>();
    const [prizeMoney, setPrizeMoney] = useState((eventDivision && eventDivision.prizeMoney) ? parseFloat(eventDivision.prizeMoney) : 0);
    const [pointTable, setPointTable] = useState<PointTable | null | undefined>();
    const [isCombined, setIsCombined] = useState(false);
    const [combinedDivision, setCombinedDivision] = useState<EventDivision | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    const getDivision = async (divisionId: string) => {
        const queryResult = await getDivisionById(divisionId);
        if (queryResult.isSuccess) {
            const division = queryResult.result;
            setDivision(division);
            return queryResult.result;
        } else {
            return null;
        }
    }

    const getCombinedEventDivision = async (eventDivisionId: string) => {
        const queryResult = await getEventDivisionById(eventDivisionId);
        if (queryResult.isSuccess) {
            const eventDivision: EventDivision = queryResult.result;
            setCombinedDivision(eventDivision);
        } else {
            return null;
        }
    }

    useEffect(() => {
        if (eventDivision) {
            getDivision(eventDivision.divisionId);
            setName(eventDivision.name);
            setDescription(eventDivision.division?.description);
            setDiscipline(eventDivision.division?.discipline);
            setMinNumberEntries(eventDivision.minNumberEntriesPerClass || 3);
            setMaxNumberEntries(eventDivision.maxNumberEntriesPerClass || 0);
            setEntryFee(eventDivision.entryFee ? parseFloat(eventDivision.entryFee) : 0);
            setPrizeMoney(eventDivision.prizeMoney ? parseFloat(eventDivision.prizeMoney) : 0);
            setPrizeMoneyTable(eventDivision.prizeMoneyTable || undefined);
            setPointTable(eventDivision.pointTable || undefined);
            if (eventDivision.isCombined) {
                setIsCombined(true);
                if (eventDivision.combinedEventDivisionId) getCombinedEventDivision(eventDivision.combinedEventDivisionId);
            }
            setSuccess("");
            setError("");
        }
    }, [eventDivision]);

    const verifyForm = () => {
        try {
            if (!name) {
                setError("You must include a name.");
                return false;
            }
            return true;
        } catch (error: any) {
            return false;
        }
    }

    const clearForm = () => {
        setDivision(undefined);
        setName("");
        setDescription("");
        setDiscipline("");
        setEntryFee(0);
        setPrizeMoney(0);
        setError("");
    } 

    const handleSelectedDiscipline = (discipline: string) => {
        setDiscipline(discipline);
        setIsDisabled(false);
    }

    const handleSelectedEntryNumber = (option: "min" | "max", number: number) => {
        if (option === "min") setMinNumberEntries(number);
        else setMaxNumberEntries(number);
        setIsDisabled(false);
    }

    const handleSelectedDivisionEntryFee = (option: "division", number: number) => {
        setEntryFee(number);
        setIsDisabled(false);
    }

    const handleSelectedPrizeMoney = (option: "prizeMoney", number: number) => {
        setPrizeMoney(number);
        setIsDisabled(false);
    }

    const handleCombinedEventDivision = (combinedEventDivision: EventDivision) => {
        setCombinedDivision(combinedEventDivision);
        setIsDisabled(false);
    }

    const handleSelectedPrizeMoneyTable = (option: "prizeMoneyTable", prizeMoneyTable: PrizeMoneyTable) => {
        setPrizeMoneyTable(prizeMoneyTable);
        if (prizeMoneyTable && !prizeMoneyTable.isPercentage && prizeMoneyTable.totalPot) {
            setPrizeMoney(prizeMoneyTable.totalPot);
        } else {
            setPrizeMoney(0);
        }
        setIsDisabled(false);
    }

    const handleSelectedPointTable = (option: "pointTable", pointTable: PointTable) => {
        setPointTable(pointTable);
        setIsDisabled(false);
    }

    const handleEditDivision = async () => {
        try {
            if (eventDivision) {
                const input: UpdateDivisionInput = {
                    id: eventDivision.organizationDivision?.divisionId || eventDivision.divisionId,
                    name: name,
                    description: description ? description : "",
                    discipline: discipline ? discipline : ""
                };
                const updateResult = await updateDivision(input);
                if (updateResult.isSuccess) {
                    if (eventDivision) {
                        const input: UpdateEventDivisionInput = {
                            id: eventDivision.id,
                            divisionId: eventDivision.organizationDivision?.divisionId || eventDivision.divisionId,
                            name: name,
                            entryFee: entryFee ? entryFee.toString() : "",
                            prizeMoney: prizeMoney ? prizeMoney.toString() : "",
                            eventDivisionPrizeMoneyTableId: prizeMoneyTable?.id || "",
                            minNumberEntriesPerClass: minNumberEntries,
                            maxNumberEntriesPerClass: maxNumberEntries,
                            isCombined: isCombined,
                            combinedEventDivisionId: isCombined ? (combinedDivision?.id || "") : "",
                        };
                        const updateEventDivisionResult = await updateEventDivision(input);
                        if (updateEventDivisionResult.isSuccess) {
                            onSubmit(updateResult.result, updateEventDivisionResult.result);
                            const message = "Successfully updated the division: " + name;
                            setSuccess(message);
                        } else {
                            setError(updateEventDivisionResult.message);
                        }
                    } else {
                        setError("Could not update the event division.");
                    }
                } else {
                    setError(updateResult.message);
                }
            }
        } catch (error: any) {
            
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) {
            setError("");
            if (eventDivision) {
                await handleEditDivision();
            }
        }
        setIsDisabled(true);
        setIsLoading(false);
    }

    const handleDelete = async () => {
        if (eventDivision) {
            setShowModal(true);
        }
    }

    const handleRemoveEventDivison = async () => {
        setError("");
        setSuccess("");
        setIsLoading(true);
        setShowModal(false);
        let didRemoveClasses = true;
        const queryResult = await getEventClassesByEventDivisionId(eventDivision.id);
        if (queryResult.isSuccess) {
            const eventClasses = queryResult.result;
            for (let i = 0; i < eventClasses.length; i++) {
                const eventClass = eventClasses[i];
                const deleteClassResult = await deleteEventClass({id: eventClass.id});
                if (!deleteClassResult.isSuccess) {
                    didRemoveClasses = false;
                    const message = "Could not remove an event class: " + deleteClassResult.message; 
                    setError(message);
                }
            }
        }
        if (didRemoveClasses) {
            const deleteResult = await deleteEventDivision({id: eventDivision.id});
            if (deleteResult.isSuccess) {
                setSuccess("Successfully deleted the division.")
            } else {
                setError("Could not remove the division.");
            }
        } else {
            setError("Could not remove an event class.");
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name<RequiredInputIndicator/></IonLabel>
                            <IonInput 
                                type="text"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel position="stacked">Discipline <RequiredInputIndicator/></IonLabel>
                        <SelectDiscipline selectedValue={discipline} onSelect={(value: string) => handleSelectedDiscipline(value)} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel>Description</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <Input
                            rows="5"
                            type="textarea"
                            name="declineReason"
                            value={description!}
                            spellCheck="true"
                            onChange={(event) => setDescription(event.target.value)}
                            data-hj-whitelist
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <AllowedNumberOfEntries selectedMin={minNumberEntries} selectedMax={maxNumberEntries} onSelect={handleSelectedEntryNumber} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <DivisionEntryFee selectedEntryFee={entryFee} onSelect={handleSelectedDivisionEntryFee} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <ClassPointTable organizationId={organizationId} selectedPointTable={pointTable} onSelectPointTable={handleSelectedPointTable} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <ClassPrizeMoney organizationId={organizationId} selectedPrizeMoney={prizeMoney} selectedPrizeMoneyTable={prizeMoneyTable} onSelectPrizeMoney={handleSelectedPrizeMoney} onSelectPrizeMoneyTable={handleSelectedPrizeMoneyTable} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel position="stacked"><p>Combine with another division?</p></IonLabel>
                        <IonToggle color="tertiary" checked={isCombined} onIonChange={e => setIsCombined(e.detail.checked)}/>
                    </IonCol>
                </IonRow>
                {isCombined && (
                    <IonRow>
                        <IonCol size="12">
                            <SelectEventDivision eventId={eventDivision.eventId} selectedValue={combinedDivision?.id || ""} onSelect={handleCombinedEventDivision} />
                        </IonCol>
                    </IonRow>
                )}
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                    {division && (
                        <IonCol offsetMd="4" sizeMd="4">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    className="ion-margin-top"
                                    color="danger"
                                    expand="block"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </IonButton>
                            }
                        </IonCol>
                    )}
                </IonRow>
                <IonModal backdropDismiss={false} isOpen={showModal} id="termsAndConditionsModal">
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                            Confirm Division Deletion
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowModal(false)}
                            >
                                <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    <IonIcon icon={close} />
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonContent className="ion-padding">
                        <IonRow>
                            <IonCol className="ion-text-center">
                                <h2>
                                    Are you sure you want to delete {eventDivision.name}?
                                </h2>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <p>If you delete this division from your event, all classes associated with this division will also be deleted. If anyone has already entered any classes in this division, those entries will no longer be entered in the classes.</p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                <IonButton color="light" onClick={() => setShowModal(false)}>
                                    Cancel
                                </IonButton>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                <IonButton color="danger" onClick={handleRemoveEventDivison}>
                                    Delete
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonContent>
                </IonModal>
            </form>
        </>
    );
};

export default EventDivisionForm;