import { 
    IonText
} from "@ionic/react";

import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { getResponseByPollIdPersonId } from "../../utilities/polls/Response";

const ProfileReferral: React.FC = () => {

    const [referral, setReferral] = useState("No referral method.");

    const user = useContext(PersonContext);

    useEffect(() => {
        getReferralMethod();
    })

    const getReferralMethod = async () => {
        let referralMethod = "No referral method.";
        const queryResult = await getResponseByPollIdPersonId("04a99513-b6c7-4ac1-a422-1911c610a0c1", user.id);
        if (queryResult.isSuccess) {
            referralMethod = queryResult.result.value;
            setReferral(referralMethod);
        }
    }

    return (
        <IonText className="ion-justify-content-center">
            <p className="text-center">Referral Method: {referral}</p>
        </IonText>
    );
};

export default ProfileReferral;