import { IonCard, IonCardTitle, IonCol, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Organization, Post } from "../../models";
import { getPostsByOrganizationId } from "../../utilities/posts/Post";
import ErrorAlert from "../Errors/ErrorAlert";
import CreatePostButton from "./CreatePostButton";
import DisplayPostTable from "./DisplayPostTable";
import Spinner from "../Spinners/Spinner";

interface _Props {
    organization: Organization;
}

const EventOrganizationPostInfo: React.FC<_Props> = ({ organization }) => {
    
    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [posts, setPosts] = useState<Post[]>([]);

    async function getPost(id: string) {
        setIsLoading(true);
        setError("");
        const queryResult = await getPostsByOrganizationId(id);
        if (queryResult.isSuccess) {
            const post = queryResult.result;
            setPosts(post);
        } else {
            setError(queryResult.message);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (organization) {
            getPost(organization.id);
        }
    }, [organization]);

    return (
        <React.Fragment>
            <IonRow className="ion-justify-content-center">
                {error && <ErrorAlert width="12" error={error} />}
                {isLoading && <Spinner/>}
                <IonCol sizeXs="12" sizeMd="12">
                    <IonCard mode="md" className="ion-padding bg-white">
                        <IonRow className="ion-justify-content-between">
                            <IonCardTitle>
                                <h3>Dashboard Posts</h3>
                            </IonCardTitle>
                            <CreatePostButton organization={organization} />
                        </IonRow>
                        <DisplayPostTable posts={posts} setPosts={setPosts} />
                    </IonCard>
                </IonCol>
            </IonRow>
        </React.Fragment>
    );
};

export default EventOrganizationPostInfo;
