import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { Membership, Organization, Person, TeamMember } from "../../../../models";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import Spinner from "../../../../components/Spinners/Spinner";
import { getTeamMemberById, getTeamMembersByTeamId } from "../../../../utilities/teamMember/TeamMember";
import ViewTeamMemberBasicInfo from "../../../../components/TeamMember/ViewTeamMemberBasicInfo";
import { getMembershipsByPersonId } from "../../../../utilities/membership/Membership";
import { PersonContext } from "../../../../context/PersonContext";
import { getGroupsForUser } from "../../../../utilities/adminQueries/userGroups";
import { getUser } from "../../../../utilities/user/User";
import { findOrgInGroups } from "../../../../utilities/organization/OrganizationGroup";
import { getOrganizationById } from "../../../../utilities/organization/Organization";
import ViewTeamMemberContactInfo from "../../../../components/TeamMember/ViewTeamMemberContactInfo";

interface TeamPageProps extends RouteComponentProps<{
    id: string;
}> {}

const ViewTeamMemberPage: React.FC<TeamPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [currentUserRoles, setCurrentUserRoles] = useState<("admin" | "coach" | "creator" | "member")[]>([]);
    const [teamMember, setTeamMember] = useState<TeamMember | undefined | null>();
    const [membership, setMembership] = useState<Membership | undefined | null>();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        const checkUser = async (teamId: string, user: Person, membership?: Membership) => {
            // Try to find the organization
            let currentOrganization: Organization | undefined | null = membership?.organization;
            if (!currentOrganization && membership?.organizationId) {
                const organizationQueryResult = await getOrganizationById(membership.organizationId);
                if (organizationQueryResult.isSuccess) {
                    currentOrganization = organizationQueryResult.result;
                }
            }
    
            // Check if the user is an organization admin - can edit any fields
            if (currentOrganization) {
                const userIsOrgAdmin = await checkIfUserIsOrganizationAdmin(currentOrganization);
                if (userIsOrgAdmin) setCurrentUserRoles(prevValue => prevValue.concat("admin"));
            }
    
            // Check if the user is the parent or the member - can update name and contact details
            if (membership?.createdBy === user.id) setCurrentUserRoles(prevValue => prevValue.concat("creator"));
            else if (membership?.personId === user.id) setCurrentUserRoles(prevValue => prevValue.concat("creator"));
            else if (teamMember?.personId === user.id) setCurrentUserRoles(prevValue => prevValue.concat("creator"));
    
            // Check if the user is a coach role
            const queryResult = await getTeamMembersByTeamId(teamId);
            if (queryResult.isSuccess) {
                const teamMembers: TeamMember[] = queryResult.result;
                if (teamMembers) {
                    const foundCoach = teamMembers.find(teamMember => teamMember.personId === user.id && (teamMember.role?.toLowerCase().includes("coach") || teamMember.permissionLevel === "admin"));
                    if (foundCoach) setCurrentUserRoles(prevValue => prevValue.concat("coach"));
                }
            }
        }

        async function getTeamMember() {
            setIsLoading(true);
            const queryResult = await getTeamMemberById(match.params.id);
            if (queryResult.isSuccess) {
                const teamMember: TeamMember = queryResult.result;
                setTeamMember(teamMember);
                const membershipResult = await getMembership(teamMember);
                if (user && teamMember.team.id && (!currentUserRoles || currentUserRoles.length === 0)) {
                    await checkUser(teamMember.team.id, user, membershipResult);
                }
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
            setIsLoading(false);
        }

        getTeamMember();
    }, [match, user, currentUserRoles]);

    async function checkIfUserIsOrganizationAdmin(org: Organization) {
        const userResult = await getUser();
        if (userResult && userResult.username) {
            // All users should belong to at least 1 group (rsp-users)
            const result = await getGroupsForUser(userResult.username);
            const groupsArray = result.Groups;
            if (groupsArray && groupsArray.length > 0) {
                // This user also belongs to at least 1 org group
                const searchResult = findOrgInGroups(org, groupsArray);
                if (searchResult.isSuccess) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    async function getMembership(teamMember: TeamMember) {
        const queryResult = await getMembershipsByPersonId(teamMember.personId);
        if (queryResult.isSuccess) {
            const memberships: Membership[] = queryResult.result;
            const membership = memberships.find((m => m.teamId === teamMember.team.id && m.membershipStatus === "complete"));
            setMembership(membership);
            return membership;
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={teamMember ? teamMember.team.name : "Team Member"} />
                {error && <ErrorAlert width="12" error={error} />}
                {isLoading ?
                    <Spinner />
                    :
                    <>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                {teamMember ?
                                    <ViewTeamMemberBasicInfo teamMember={teamMember} membership={membership} currentUserRoles={currentUserRoles} />
                                    :
                                    <p>No team member found.</p>
                                }
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                {/* First, check if the user is an organization admin or the creator of this team member */}
                                {(currentUserRoles?.includes("admin") || currentUserRoles?.includes("creator")) && (
                                    <>
                                        {teamMember ?
                                            <ViewTeamMemberContactInfo membership={membership} currentUserRoles={currentUserRoles} />
                                            :
                                            <p>No team member found.</p>
                                        }
                                    </>
                                )}
                            </IonCol>
                        </IonRow>
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default ViewTeamMemberPage;