import { API } from "aws-amplify";
import { Result } from "../../interfaces/Result";
import { Membership, MembershipTypeCategory, Organization } from "../../models";

export async function sendConfirmationEmail(organization: Organization, membershipTypeName: string, memberships: Membership[], pdf: Blob) {
    const membership = memberships[0];
    try {
        let names = "";
        memberships.forEach((m, index) => {
            if(index === 0) {
                names = (m.organizationMembershipType?.category === MembershipTypeCategory.HORSE && m.horse) ? m.horse.name : ((m.organizationMembershipType?.category === MembershipTypeCategory.GROUP && m.groupContact) ? (m.groupContact.name || "") :  (m.personName || ""));
            } else {
                names = names + ", " + m.personName;
            }
            
        });
        const attachmentFileName = `${organization?.nickname || organization?.name}_${membershipTypeName}_Confirmation.pdf`        
        let data = "";
        const reader = new FileReader();
        reader.readAsDataURL(pdf); 
        reader.onloadend = async function() {
            const base64data = reader.result;                
            data = base64data as string;
            const queryData = {
                body: {
                    "email": membership.personEmail,
                    "name": membership.personName,
                    "memberNames": names,
                    "organizationName": organization.name,
                    "organizationEmail": organization.contact?.workEmail,
                    "membershipTypeName": membershipTypeName,
                    "membershipId": membership.membershipId ? membership.membershipId : "",
                    "backNumber": membership.backNumber ? membership.backNumber : "",
                    "dateMembershipEnds": membership.dateMembershipEnds ? membership.dateMembershipEnds : "",
                    "attachmentFileName": attachmentFileName,
                    "attachment": data
                }
            };
            const path = "/emails/membership/confirmation";
            const response = await API.post("Emails", path, queryData);
            const result: Result = {isSuccess: response.success, type: "Confirmation", result: response.url, message: "Successfully sent the email."};
            return result;
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Confirmation", result: error, message: "Error occurred while trying to send the email." }
        return result;
    }
}

export async function sendNewTeamEmail(teamName: string, creatorName: string, pdf: Blob) {
    try {    
        let data = "";
        const reader = new FileReader();
        reader.readAsDataURL(pdf); 
        reader.onloadend = async function() {
            const base64data = reader.result;                
            data = base64data as string;
            const queryData = {
                body: {
                    "teamName": teamName,
                    "creatorName": creatorName,
                    "attachment": data
                }
            };
            const path = "/emails/membership/new-team";
            const response = await API.post("Emails", path, queryData);
            const result: Result = {isSuccess: response.success, type: "Confirmation", result: response.url, message: "Successfully sent the email."};
            return result;
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Confirmation", result: error, message: "Error occurred while trying to send the email." }
        return result;
    }
}