import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event, EventClass, EventDivision} from "../../../../../models";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps, useHistory } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import EventClassForm from "../../../../../components/EventClass/EventClassForm";
import EventClassEditTable from "../../../../../components/EventClass/EventClassEditTable";
import EventClassDisplayTable from "../../../../../components/EventClass/EventClassDisplayTable";
import { close } from "ionicons/icons";
import EventClassExplanationModal from "../../../../../components/EventClass/EventClassExplanationModel";
import EventClassNavbar from "../../../../../components/Navbars/EventClassNavbar";
import EventDivisionEditTable from "../../../../../components/EventDivision/EventDivisionEditTable";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventClassesPage: React.FC<EventPageProps> = ({match}) => {
    const history = useHistory();

    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [selectedEventClass, setSelectedEventClass] = useState<EventClass | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [currentTab, setCurrentTab] = useState("classes");
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setCurrentEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        getEvent();
    }, [match, match.params.id]);

    const handleTabSelection = (tab: string) => {
        setCurrentTab(tab);
    }

    const onSelectDivision = (eventDivision: EventDivision, isMobile: Boolean) => {
        if (eventDivision) {
            navigateToDivisionPage(eventDivision);
        }
    }

    const onSelectClass = (c: EventClass, isMobile: Boolean) => {
        setSelectedEventClass(c);
        if (c) {
            navigateToPage(c);
        }
    }

    const navigateToPage = (eventClass: EventClass) => {
        if (currentEvent) {
            const path = "/index/staff/event/" + currentEvent.id + "/class/" + eventClass.id;
            history.push(path);
        } else {
            setError("Sorry, a problem occurred.")
        }
    }

    const navigateToDivisionPage = (eventDivision: EventDivision) => {
        if (currentEvent) {
            const path = "/index/staff/event/" + currentEvent.id + "/division/" + eventDivision.id;
            history.push(path);
        } else {
            setError("Sorry, a problem occurred.")
        }
    }

    return (
        <IonPage className="bg-light">
            <Header isEventPage={currentEvent ? true : false} event={currentEvent ? currentEvent : undefined} />
            <IonContent>
                <PageTitle title={currentEvent ? currentEvent.name : "Event Settings"} />
                <EventClassNavbar active={currentTab} onSelect={handleTabSelection} />
                {currentTab === "classes" && (
                    <>
                        <IonRow className="ion-justify-content-center">
                            {error && <ErrorAlert width="12" error={error} />}
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol>
                                                Event Classes List 
                                            </IonCol>
                                            <IonCol className="ion-text-right">
                                                <p>
                                                    <EventClassExplanationModal />
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    <IonCardSubtitle>
                                        This is where you will add any classes that your event participants can enter. To make edits, the event must be in "draft" mode.
                                    </IonCardSubtitle>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        {(currentEvent?.status === "published" && currentEvent?.entriesStatus !== "will_accept") ?
                            <>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="12">
                                        {currentEvent && (
                                            <EventClassDisplayTable event={currentEvent} />
                                        )}
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                            <>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="12">
                                        {currentEvent && (
                                            <EventClassEditTable onSelect={(c: EventClass) => onSelectClass(c, true)} event={currentEvent} />
                                        )}
                                    </IonCol>
                                </IonRow>
                                <IonModal backdropDismiss={false} isOpen={showModal} id="eventClassModal">
                                    <IonToolbar color="light">
                                        <IonTitle className="ion-text-center">
                                            Edit Event Class
                                        </IonTitle>
                                        <IonButtons slot="end">
                                            <IonButton
                                                fill="clear"
                                                onClick={() => setShowModal(false)}
                                            >
                                                <p id="closeEventClassModalBtn" className="font-weight-normal text-medium text-capitalize">
                                                    <IonIcon icon={close} />
                                                </p>
                                            </IonButton>
                                        </IonButtons>
                                    </IonToolbar>
                                    <IonContent className="ion-padding">
                                        {currentEvent && selectedEventClass && (
                                            <EventClassForm event={currentEvent} eventClass={selectedEventClass} onSubmit={() => setShowModal(false)} />
                                        )}
                                    </IonContent>
                                </IonModal>
                            </>
                        }
                    </>
                )}
                {currentTab === "divisions" && (
                    <>
                        <IonRow className="ion-justify-content-center">
                            {error && <ErrorAlert width="12" error={error} />}
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol>
                                                Event Divisions List 
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    <IonCardSubtitle>
                                        These are the divisions currently offered for your event.
                                    </IonCardSubtitle>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        {(currentEvent?.status === "published" && currentEvent?.entriesStatus !== "will_accept") ?
                            <>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="12">
                                        {currentEvent && (
                                            <EventDivisionEditTable onSelect={(d: EventDivision) => onSelectDivision(d, true)} event={currentEvent} />
                                        )}
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                            <>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size="12">
                                        {currentEvent && (
                                            <EventDivisionEditTable onSelect={(d: EventDivision) => onSelectDivision(d, true)} event={currentEvent} />
                                        )}
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventClassesPage;