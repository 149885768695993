import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { CreateEventClassEntryInput, UpdateEventClassEntryInput, UpdateEventEntryInput } from "../../API";
import { PersonContext } from "../../context/PersonContext";
import { Event, EventEntry, EventClass, EventDivision, Rider } from "../../models";
import { getEventClassTypeAbbreviation } from "../../utilities/eventClass/EventClassTypes";
import { createEventClassEntry, deleteEventClassEntry, getEventClassEntryByEventClassIdEntryId, updateEventClassEntry } from "../../utilities/eventClassEntry/EventClassEntry";
import { formattedOptionGroup, getFormattedEntryClassList } from "../../utilities/eventClassEntry/FormattedEventEntryClasses";
import ErrorAlert from "../Errors/ErrorAlert";
import { getEventClassesByEventDivisionId } from "../../utilities/eventClass/EventClass";
import SelectEventClass from "../EventClass/SelectEventClass";
import SelectEventDivision from "../EventDivision/SelectEventDivision";
import Spinner from "../Spinners/Spinner";
import { updateEventEntry } from "../../utilities/eventEntry/EventEntry";
import SelectRiderFromEntry from "../Rider/SelectRiderFromEntry";
import EventClassCheckList from "./EventClassCheckList";
import constants from "../../constant/constant";

interface _Props {
    event: Event
    entry: EventEntry
    onCalculate?: Function
    isQuickAdd?: Boolean
    onUpdateEntry?: Function
}

const EventClassAddToEntryForm: React.FC<_Props> = ({event, entry, isQuickAdd, onCalculate, onUpdateEntry}) => {
    const user = useContext(PersonContext);

    const [showChecklistView, setShowChecklistView] = useState(false);
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [selectedClass, setSelectedClass] = useState<EventClass | null | undefined>();
    const [selectedDivision, setSelectedDivision] = useState<EventDivision | null | undefined>();
    const [totalEntryFees, setTotalEntryFees] = useState<number | null | undefined>();
    const [tax, setTax] = useState<number | null | undefined>();
    const [totalCost, setTotalCost] = useState<number | null | undefined>();
    const [formattedClassData, setFormattedClassData] = useState<formattedOptionGroup[] | null | undefined>();
    const [selectedFormattedClassData, setSelectedFormattedClassData] = useState<formattedOptionGroup[] | null | undefined>();
    const [currentDivisionList, setCurrentDivisionList] = useState<(string | null)[] | null | undefined>();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSettingRider, setIsSettingRider] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const getFinalFeeCost = (total?: (number | null), taxPercent?: (number | null)) => {
        if (!total) total = totalEntryFees;
        if (!taxPercent) taxPercent = tax || 0;
        if (total && taxPercent) {
            const finalCost = total + total * (taxPercent || 0)/100;
            setTotalCost(finalCost);
        } else if (total) {
            setTotalCost(total);
        }
    }

    const getFormattedClassData = async (entry: EventEntry) => {
        setIsLoading(true);
        try {
            // Want to get all class data including not accepted
            const dataResult = await getFormattedEntryClassList(entry, false);
            if (dataResult.isSuccess) {
                setTotalEntryFees(dataResult.result.totalEntryFees);
                if (tax) getFinalFeeCost(dataResult.result.totalEntryFees, tax);
                else if (event) getFinalFeeCost(dataResult.result.totalEntryFees, event.invoiceOptions?.classFeeTax);
                if (onCalculate) onCalculate(dataResult.result.totalEntryFees);
                
                setFormattedClassData(dataResult.result.classList);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error: ", error);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (event) {
            if (isQuickAdd || event.type === "camp" || event.organizationId === "bd534265-fdc1-4f8a-a960-d006b846f2b3" || event.organizationId === constants.RHSC_ORGANIZATION.id) {
                setShowChecklistView(true);
            } else {
                // Show the Add / Delete class and division individually view 
                // also show fee info with this view
                if (event.invoiceOptions && event.invoiceOptions.classFeeTax) {
                    setTax(event.invoiceOptions.classFeeTax);
                    if (totalEntryFees) getFinalFeeCost(totalEntryFees, event.invoiceOptions.classFeeTax);
                    else if (entry) getFormattedClassData(entry);
                }
            }
        } 
    }, [event]);

    useEffect(() => {
        if (entry) {
            setCurrentEntry(entry);
            getFormattedClassData(entry);
            setCurrentDivisionList(entry.divisionList);
        }
    }, [entry]);

    const handleSelectedRider = async (rider: Rider, formattedOption: formattedOptionGroup) => {
        setIsSettingRider(true);
        if (formattedOption.division) {
            const queryResult = await getEventClassesByEventDivisionId(formattedOption.division.object.eventDivisionId);
            if (queryResult.isSuccess) {
                const eventClasses = queryResult.result;
                if (eventClasses && eventClasses.length > 0) {
                    for (let i = 0; i < eventClasses.length; i++) {
                        const eventClass = eventClasses[i];
                        const queryEventClassEntryResult = await getEventClassEntryByEventClassIdEntryId(eventClass.id, entry.id);
                        if (queryEventClassEntryResult.isSuccess) {
                            const currentEventClassEntry = queryEventClassEntryResult.result;
                            if (rider) {
                                const updateInput: UpdateEventClassEntryInput = {
                                    id: currentEventClassEntry.id,
                                    riderId: rider.id,
                                    // eventClassEntryRiderId: rider.id
                                };
                                const updateResult = await updateEventClassEntry(updateInput);
                                if (!updateResult.isSuccess) {
                                    const errorMessage = "Could not update the rider for class: " + eventClass.number + " - " + eventClass.name;
                                    setError(errorMessage);
                                }
                            }
                        }
                    }
                }
            }
        } else if (formattedOption.class) {
            const eventClass = formattedOption.class.object;
            const queryEventClassEntryResult = await getEventClassEntryByEventClassIdEntryId(eventClass.id, entry.id);
            if (queryEventClassEntryResult.isSuccess) {
                const currentEventClassEntry = queryEventClassEntryResult.result;
                if (rider) {
                    const updateInput: UpdateEventClassEntryInput = {
                        id: currentEventClassEntry.id,
                        riderId: rider.id,
                        // eventClassEntryRiderId: rider.id
                    };
                    const updateResult = await updateEventClassEntry(updateInput);
                    if (!updateResult.isSuccess) {
                        const errorMessage = "Could not update the rider for class: " + eventClass.number + " - " + eventClass.name;
                        setError(errorMessage);
                    }
                }
            }
        }
        if (entry) await getFormattedClassData(entry);
        setIsSettingRider(false);
    }

    const handleSelectedEventDivision = (eventDivision: EventDivision) => {
        setSelectedClass(undefined);
        setSelectedDivision(eventDivision);
    }

    const handleSelectedEventClass = (eventClass: EventClass) => {
        setSelectedClass(eventClass);
        setSelectedDivision(undefined);
    }

    const handleChecklistChange = (newFormattedGroups: any[]) => {
        setSelectedFormattedClassData(newFormattedGroups);
    }

    const handleSubmit = async () => {
        setError("");
        setIsLoading(true);
        if (selectedDivision) {
            // Need to add the classes in this division 
            const queryResult = await getEventClassesByEventDivisionId(selectedDivision.id);
            if (queryResult.isSuccess) {
                const eventClassArray: EventClass[] = queryResult.result;
                for (let i = 0; i < eventClassArray.length; i++) {
                    const eventClass = eventClassArray[i];
                    const eventClassEntryInput: CreateEventClassEntryInput = {
                        createdBy: user.id,
                        eventId: event.id,
                        eventClassId: eventClass.id,
                        eventEntryId: entry.id,
                        status: (isQuickAdd ? "accepted" : (entry.status === "accepted" || entry.status === "complete") ? "accepted" : "submitted"),
                        riderId: entry.riderId,
                    };
                    await createEventClassEntry(eventClassEntryInput);
                }
            }

            // Need to add the divisionId to the entry's division list
            let newDivisionList = [];
            if (currentDivisionList) {
                newDivisionList = currentDivisionList.concat([selectedDivision.id]);
            } else {
                newDivisionList = [selectedDivision.id];
            }
            setCurrentDivisionList(newDivisionList);
            const updateInput: UpdateEventEntryInput = {
                id: entry.id,
                divisionList: newDivisionList
            };
            const updateEventEntryResult = await updateEventEntry(updateInput);
            if (updateEventEntryResult.isSuccess) {
                const eventEntry = updateEventEntryResult.result;
                if (onUpdateEntry) onUpdateEntry(eventEntry);
                await getFormattedClassData(eventEntry);
            } else {
                setError(updateEventEntryResult.message);
            }
        } else if (selectedClass) {
            // Need to add the class
            const eventClassEntryInput: CreateEventClassEntryInput = {
                createdBy: user.id,
                eventId: event.id,
                eventClassId: selectedClass.id,
                eventEntryId: entry.id,
                status: (isQuickAdd ? "accepted" : (entry.status === "accepted" || entry.status === "complete") ? "accepted" : "submitted"),
                riderId: entry.riderId,
            };
            const createResult = await createEventClassEntry(eventClassEntryInput);
            if (createResult.isSuccess) {
                setError("");
                await getFormattedClassData(entry);
            } else {
                const message = "Could not add " + selectedClass.number + ": " + selectedClass.name + " to the entry";
                setError(message);
            }
        } else {
            setError("Did not add because no class or division selected.");
        }
        setIsLoading(false);
        setShowModal(false);
    }

    const handleDelete = async (formattedOptionGroup: formattedOptionGroup) => {
        setIsLoading(true);
        if (formattedOptionGroup.class) {
            // Delete this EventClassEntry
            const queryResult = await getEventClassEntryByEventClassIdEntryId(formattedOptionGroup.class.value, entry.id);
            if (queryResult.isSuccess) {
                const classEntryId = queryResult.result.id;
                const deleteResult = await deleteEventClassEntry({id: classEntryId});
                if (deleteResult.isSuccess) {
                    await getFormattedClassData(entry);
                } else {
                    setError("Could not delete the class - check id.");
                }
            } else {
                setError("Could not delete the class - no class.");
            }
            setIsLoading(false);
        } else if (formattedOptionGroup.division) {
            // Delete the division classes
            const queryResult = await getEventClassesByEventDivisionId(formattedOptionGroup.division.value);
            if (queryResult.isSuccess) {
                const eventClassArray: EventClass[] = queryResult.result;
                for (let i = 0; i < eventClassArray.length; i++) {
                    const eventClass = eventClassArray[i];
                    const eventClassEntryResult = await getEventClassEntryByEventClassIdEntryId(eventClass.id, entry.id);
                    if (eventClassEntryResult.isSuccess) {
                        const deleteResult = await deleteEventClassEntry({id: eventClassEntryResult.result.id});
                    }
                }
            }

            // Remove the division id from entry division list
            let newDivisionList: string[] = [];
            if (currentDivisionList && formattedOptionGroup.division.value) {
                currentDivisionList.forEach(eventDivisionId => {
                    if (eventDivisionId && (eventDivisionId !== formattedOptionGroup?.division?.value)) newDivisionList.push(eventDivisionId);
                });
            }
            const updateInput: UpdateEventEntryInput = {
                id: entry.id,
                divisionList: newDivisionList
            };
            const updateEventEntryResult = await updateEventEntry(updateInput);
            if (updateEventEntryResult.isSuccess) {
                const updatedEventEntry: EventEntry = updateEventEntryResult.result;
                if (onUpdateEntry) onUpdateEntry(updatedEventEntry);
                await getFormattedClassData(updatedEventEntry);
            } else {
                setError(updateEventEntryResult.message);
            }
            setIsLoading(false);
        }
    }

    return (
        <>
           {showChecklistView ?
                <>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="text-center">
                            <p>Select the {event.type === "camp" ? "section your child should be in." : "classes you would like to enter."}</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <EventClassCheckList entry={currentEntry || entry} eventId={event.id} isQuickAdd={isQuickAdd} onSelect={handleChecklistChange} onUpdateEntry={onUpdateEntry} />
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="text-center">
                            <IonButton color="primary" onClick={() => setShowModal(true)}>
                                Add Class / Division
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    {error && <ErrorAlert width="12" error={error} />}
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            {(formattedClassData && formattedClassData.length > 0) ?
                                <>
                                    {formattedClassData.map((formattedOption, index) => (
                                        <IonItem key={index}>
                                            <IonLabel>
                                                <IonRow>
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <IonRow>
                                                            <IonCol>
                                                                <p>
                                                                    {formattedOption.division ? 
                                                                        "Division: " + formattedOption.division.label + " - $" + formattedOption.division.entryFee
                                                                        : 
                                                                        "Class: " + (formattedOption.class?.number ? + formattedOption.class?.number + " - " : "") + formattedOption.class?.label + " - $" + formattedOption.class?.entryFee
                                                                    }
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol>
                                                                {isSettingRider ?
                                                                    <Spinner />
                                                                    :
                                                                    <div>
                                                                        <p>Rider: </p>
                                                                        <SelectRiderFromEntry entry={entry} selectedValue={formattedOption.division ? formattedOption.division?.rider?.id : formattedOption.class?.rider?.id} onSelect={(rider: Rider) => handleSelectedRider(rider, formattedOption)} />
                                                                    </div>
                                                                }
                                                            </IonCol>
                                                        </IonRow>
                                                        {(formattedOption.division && formattedOption.classes) && 
                                                            <IonRow>
                                                                <IonCol>
                                                                    {formattedOption.classes?.map((c, index) => (
                                                                        <p key={index}>
                                                                            {c.number ? c.number + ": " : ""}{c.label}
                                                                        </p>
                                                                    ))}
                                                                </IonCol>
                                                            </IonRow>
                                                        }
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="3" offsetMd="3" className="pt-3">
                                                        <IonButton color="danger" onClick={() => handleDelete(formattedOption)}><IonIcon icon={close}/>Delete {formattedOption.division ? "Div" : "Class"}</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                    <IonList className="bg-white">
                                        <IonItem key="total">
                                            <IonLabel color="dark">
                                                <h3 className="font-weight-bold">
                                                    Tax: {tax ? tax + "%" : "none"}
                                                </h3>
                                            </IonLabel>
                                        </IonItem>
                                    </IonList>
                                    <IonList className="bg-white">
                                        <IonItem key="total">
                                            <IonLabel color="dark">
                                                <h3 className="font-weight-bold">
                                                    Total: {totalCost ? "$" + totalCost.toFixed(2) : ""}
                                                </h3>
                                            </IonLabel>
                                        </IonItem>
                                    </IonList>
                                </>
                            :
                                <p>You haven't added any classes or divisions. Use the button above to add classes / divisions.</p>
                            }
                        </>
                    }
                    <IonModal backdropDismiss={false} isOpen={showModal} id="termsAndConditionsModal">
                        <IonToolbar color="light">
                            <IonTitle className="ion-text-center">
                                Add Class / Division
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    fill="clear"
                                    onClick={() => setShowModal(false)}
                                >
                                    <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                        <IonIcon icon={close} />
                                    </p>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                        {isLoading ?
                            <Spinner />
                            :
                            <IonContent className="ion-padding">
                                {event && (
                                    <>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="10">
                                                <IonLabel>Select Division</IonLabel>
                                                <SelectEventDivision eventId={event.id} onSelect={handleSelectedEventDivision} />
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="10" className="ion-text-center">
                                                <IonLabel>OR</IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="10">
                                                <IonLabel>Select Class</IonLabel>
                                                <SelectEventClass eventId={event.id} onSelect={handleSelectedEventClass} />
                                            </IonCol>
                                        </IonRow>
                                        <hr />
                                        <IonRow className="ion-justify-content-center ion-padding-top">
                                            <IonCol size="10" className="ion-text-center">
                                                <p>{selectedClass ? "Class: " + selectedClass.number + " - " + selectedClass.name + " " + getEventClassTypeAbbreviation(selectedClass.type || "") : ""}</p>
                                                <p>{selectedDivision ? "Division: " + selectedDivision.name : ""}</p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center ion-padding-top">
                                            <IonCol size="10" className="ion-text-center">
                                                <IonButton expand="block" onClick={handleSubmit}>
                                                    Add {selectedDivision ? selectedDivision.name : (selectedClass ? selectedClass.name : "")}
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </>
                                )}
                            </IonContent>
                        }
                    </IonModal>
                </>
            } 
        </>
    );
};

export default EventClassAddToEntryForm;