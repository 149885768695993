import {
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorBanner from "../Banners/ErrorBanner";
import FileBrowser from "../FileBrowser/FileBrowser";
import Spinner from "../Spinners/Spinner";
import { isImage, isPDF } from "../../utilities/files/File";
import { Horse } from "../../models";
import { Storage } from "aws-amplify";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    horse?: (Horse | null)
    isRequired?: (Boolean | null)
    isDisabled?: (Boolean | null)
    onSubmit: Function
}

const CogginsDocumentForm: React.FC<_Props> = ({horse, isRequired, isDisabled, onSubmit}) => {

    const [displayFileSelector, setDisplayFileSelector] = useState(false);
    const [document, setDocument] = useState<File>();
    const [url, setURL] = useState<any>();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setDocument(undefined);
        setError("");
    }, []);

    useEffect(() => {
        if (horse && horse.coggins) {
            handleGetDocument(horse.coggins.key);
        }
    }, [horse]);

    async function handleGetDocument(fileName: string) {
        try {
            const url = await Storage.get(fileName);
            setURL(url);
        } catch (error: any) {
            console.error("Error while getting the document: ", error);
            return "";
        }
    }

    const handleSelectedFile = (file: File) => {
        setIsLoading(true);
        try {
            if (isPDF(file) || isImage(file)) {
                setDocument(file);
                onSubmit(file);
                setError("");
            }
            else setError("Please check that your upload is one of: .pdf, .jpg, .png, .wepb");
        } catch (error: any) {
        }
        setIsLoading(false);
    }

    return (
        <>
            {isLoading ?
                <IonRow>
                    <IonCol className="ion-text-center">
                        <Spinner />
                        <p>Uploading your file...</p>
                    </IonCol>
                </IonRow>
                :
                <>
                    <form>
                        {error && (
                            <IonRow>
                                <IonCol>
                                    <ErrorBanner error={error} />
                                </IonCol>
                            </IonRow>
                        )}
                        <hr/>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12" className="ion-text-center">
                                {(horse && horse.coggins) && (
                                    <>
                                        <a href={url} rel="noopener noreferrer"  target="_blank" >
                                            <h2 className="ion-text-wrap link">View Coggins</h2>
                                        </a>
                                        or
                                        <h2 className="link" onClick={() => setDisplayFileSelector(true)}>
                                            Upload New
                                        </h2>
                                    </>
                                )}
                                {(!horse || !horse.coggins || displayFileSelector) && (
                                    <>
                                         <IonRow className="ion-justify-content-center">
                                            <IonCol size="12" className="text-center">
                                                <p>Upload a Coggins {isRequired && <RequiredInputIndicator /> }</p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol size="12" className="text-center">
                                                <FileBrowser handleSelectedFile={handleSelectedFile}/>
                                            </IonCol>
                                        </IonRow>
                                    </>
                                )}
                            </IonCol>
                        </IonRow>
                       
                    </form>
                </>
            }
        </>
    );
};

export default CogginsDocumentForm;