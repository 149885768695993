import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, EventEntry } from "../../models";
import DisplayEntryInfo from "./EntrySummary/DisplayEntryInfo";
import DisplayEntryClasses from "./EntrySummary/DisplayEntryClasses";
import DisplayEntryFees from "./EntrySummary/DisplayEntryFees";
import DisplayEntryAgreements from "./EntrySummary/DisplayEntryAgreements";

interface _Props {
    event: Event
    entry: EventEntry
}

const EntrySummaryCard: React.FC<_Props> = ({entry, event}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [entryFeeCost, setEntryFeeCost] = useState(0);

    useEffect(() => {
        if (entry) setIsCollapsed(false);
    }, [entry]);

    const getCardTitle = () => {
        if (entry) {
            if (entry?.status === "in_progress") {
                return (
                    <>
                        <IonRow><h2>{entry.horseName}</h2> <h6 className="pl-2 text-warning">(status: saved - need to submit)</h6></IonRow>
                    </>
                );
            }
            else if (entry?.status === "submitted") {
                return (
                    <>
                        <IonRow><h2>{entry.horseName}</h2> <h6 className="pl-2 text-success">(status: saved and submitted)</h6></IonRow>
                    </>
                );
            }
            else if (entry?.status === "complete") {
                return (
                    <>
                        <IonRow><h2>{entry.horseName}</h2> <h6 className="pl-2 text-success">(status: accepted)</h6></IonRow>
                    </>
                );
            }
            else if (entry?.status === "declined") {
                return (
                    <>
                        <IonRow><h2>{entry.horseName}</h2> <h6 className="pl-2 text-danger">(status: declined)</h6></IonRow>
                        <IonRow><h6 className="text-danger">{entry.statusNote}</h6></IonRow>
                    </>
                );
            }
            else {
                return (
                    <>
                        <IonRow><h2>{entry.horseName}</h2> <h6 className="pl-2 text-danger">(status: error - no saved status)</h6></IonRow>
                        <IonRow><h6 className="text-danger">{entry.statusNote}</h6></IonRow>
                    </>
                );
            }
        } else {
            return <h2>New {event.type === "camp" ? "Registration" : "Entry"}</h2>;
        }
    }

    const handleCalculateEntryFee = (total: number) => {
        setEntryFeeCost(total);
    }

    return (
        <>
            <IonCard>
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {getCardTitle()}
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        <DisplayEntryInfo isCamp={event.type === "camp"} entry={entry} />
                        <DisplayEntryClasses event={event} entry={entry} onCalculate={handleCalculateEntryFee} />
                        <DisplayEntryFees event={event} entry={entry} />
                        <DisplayEntryAgreements event={event} entry={entry} />
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default EntrySummaryCard;