import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect } from "react";
import { Event } from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import { RouteComponentProps, useHistory } from "react-router";
import { getCheckoutSessionInfo } from "../../../../../utilities/stripe/Checkout";
import { deleteEvent, getEventById } from "../../../../../utilities/events/Event";
import { deleteEventFilter, getEventFiltersByEventId } from "../../../../../utilities/eventFilter/EventFilter";
import { deleteEventDay, getEventDaysByEventId } from "../../../../../utilities/eventDay/EventDay";
import { deleteAddress } from "../../../../../utilities/address/Address";

interface EventPageProps extends RouteComponentProps<{
    session_id: string;
}> {}

const StripeDeleteEventPage: React.FC<EventPageProps> = ({match}) => {    
    const history = useHistory();

    const getStripeSessionInfo = async (sessionId: string) => {
        const session = await getCheckoutSessionInfo(sessionId);
        if (session.isSuccess) {
            const sessionObject: any = session.result;
            if (sessionObject.metadata && sessionObject.metadata.eventId) {
                const eventId = sessionObject.metadata.eventId;
                const eventQueryResult = await getEventById(eventId);
                if (eventQueryResult.isSuccess) {
                    const event: Event = eventQueryResult.result;
                    await removeEventFilters(event.id);
                    await removeEventDays(event.id);
                    await removeEventAddress(event);

                    await deleteEvent({id: event.id});
                    navigateToEventsPage();
                } else {
                    navigateToEventsPage();
                }
            } else {
                navigateToEventsPage();
            }
        } else {
            navigateToEventsPage();
        }
    }

    const removeEventFilters = async (eventId: string) => {
        const queryResult = await getEventFiltersByEventId(eventId);
        if (queryResult.isSuccess) {
            const eventFilters = queryResult.result;
            if (eventFilters && eventFilters.length) {
                for (let i = 0; i < eventFilters.length; i++) {
                    const currentItem = eventFilters[i];
                    await deleteEventFilter({id: currentItem.id});
                }
            }
        }
    }

    const removeEventDays = async (eventId: string) => {
        const queryResult = await getEventDaysByEventId(eventId);
        if (queryResult.isSuccess) {
            const eventDays = queryResult.result;
            if (eventDays && eventDays.length) {
                for (let i = 0; i < eventDays.length; i++) {
                    const currentItem = eventDays[i];
                    await deleteEventDay({id: currentItem.id});
                }
            }
        }
    }

    const removeEventAddress = async (event: Event) => {
        if (event.address) {
            const addressId = event.address.id;
            await deleteAddress({id: addressId});
        }
    }

    useEffect(() => {
        if (match.params.session_id) {
            getStripeSessionInfo(match.params.session_id);
        }
    }, [match, match.params.session_id]);

    const navigateToEventsPage = () => {
        const path = "/index/staff/events";
        history.push(path);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <IonRow>
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white stretch">
                            <IonCardTitle>Loading...</IonCardTitle>
                            <IonCardContent>
                                <p>One moment while we load the next page ... </p>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>   
            </IonContent>
        </IonPage>
    );
};

export default StripeDeleteEventPage;