import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ViewEventNavbar from "../../../components/Navbars/ViewEventNavbar";
import {Event} from "../../../models";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { PersonContext } from "../../../context/PersonContext";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../utilities/events/Event";
import ErrorAlert from "../../../components/Errors/ErrorAlert";
import CreateEntriesForm from "../../../components/Entry/CreateEntriesForm";
import UserEventEntryTable from "../../../components/Entry/UserEventEntryTable";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

let subscription: any;

const EventViewEntriesPage: React.FC<EventPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setCurrentEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        getEvent();
    }, [match, match.params.id]);
    
    useEffect(() => {
        async function setEventSubscription() {
            // Check for an authenticated user before setting a subscription
            if (user) {
                // subscription = DataStore.observe(Event).subscribe(msg => {
                //     const updatedEventId = msg.element.id;
                //     if (currentEvent && currentEvent.id === updatedEventId) {
                //         const updatedEventProperties = msg.element;
                //         let updatedEvent = {
                //             ...currentEvent
                //         };
                //         // Possibly need to update the PageTitle
                //         if (updatedEventProperties.name) updatedEvent.name = updatedEventProperties.name;
                //         if (updatedEventProperties.nickname) updatedEvent.nickname = updatedEventProperties.nickname;
                //         setCurrentEvent(updatedEvent);
                //     }
                // });
            }
        }
        if (currentEvent) setEventSubscription();
        return () => {
            if (subscription) subscription.unsubscribe();
        }
    }, [currentEvent]);


    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent ? (currentEvent.nickname ? currentEvent.nickname : currentEvent.name) : "Event"} />
                {currentEvent && (
                    <>
                        <ViewEventNavbar active="entries" event={currentEvent} />
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonRow id="entryForm" className="ion-align-items-stretch">
                            <IonCol size="12">
                                <UserEventEntryTable event={currentEvent} organizationId={currentEvent.organizationId}/>
                            </IonCol>
                        </IonRow>
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventViewEntriesPage;