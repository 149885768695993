import {Auth} from "aws-amplify";
import {Result} from "../../interfaces/Result";

/**
 * AWS Amplify utility function
 * 
 * @param email (string)
 * @param password (string)
 * @param personId (string) - optional
 * 
 * @returns a Result object - success means it returns a user. otw it returns the error message
 */
export async function signUp(email: string, password: string, personId?: string): Promise<Result> {
    let result: Result;
    try {
        let input: any = {
            username: email.toLowerCase(), // emails will be case sensitive on login, so store in lowercase and login in lowercase
            password
        };
        if (personId) {
            input["attributes"]= {
                "custom:personid": personId
            };
        }
        const {user} = await Auth.signUp(input);
        result = {isSuccess: true, type: "User", result: user, message: "Successfully created the user."};
        return result;
    } catch (error: any) {
        const message = handleErrors(error);
        result = {isSuccess: false, type: "User", result: error, message: message};
        return result;
    }
}

export function handleErrors(errorResponse: any) {
    if (errorResponse.code) {
        if (errorResponse.code === "UserLambdaValidationException") {
            // Post Confirmation lambda had an error, ignore for now
        }
        else if (errorResponse.code === "InvalidParameterException") {
            if (errorResponse.message.includes("Member must have length greater than or equal to 6")) {
                return "Passwords must be 8 characters or more.";
            }
            else if (errorResponse.message.includes("Invalid email address format")) {
                return "You entered an invalid email address.";
            }
            else if (errorResponse.message.includes("Please make sure that the username you have entered is between 1 and 128 characters")) {
                return "Please double-check your information.";
            }
            else if (errorResponse.message.includes("Username should be either an email or a phone number")) {
                return "You entered an invalid email address.";
            }
        }
        else if (errorResponse.code === "UsernameExistsException") {
            return "That email address is already in use.";
        }
        else if (errorResponse.code === "InvalidPasswordException") {
            return "Passwords must be 8 characters or more.";
        }
        else if (errorResponse.code === "InvalidLambdaResponseException") {
            return "Sorry, there was a problem on our end. Please contact hello@ringsidepro.com.";
        }
        else if (errorResponse.code === "UserLambdaValidationException") {
            return "Sorry, there was a problem on our end. Please contact hello@ringsidepro.com.";
        }
        else if (errorResponse.code === "NetworkError") {
            return "Sorry, there was a network error. Please confirm you have internet service.";
        }
    }
    return "Please double-check your information.";
};