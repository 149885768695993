import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { close, create } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Organization, OrganizationBeddingType } from "../../models";
import BeddingTypeForm from "./BeddingTypeForm";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { getOrganizationBeddingTypesByOrganizationId } from "../../utilities/organizationBeddingType/OrganizationBeddingType";

interface _Props {
    organization: Organization
}

const OrganizationBeddingTypeForm: React.FC<_Props> = ({organization}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [organizationBeddingTypeArray, setOrganizationBeddingTypeArray] = useState<(OrganizationBeddingType | null)[] | null | undefined>();
    const [selectedOrganizationBeddingType, setSelectedOrganizationBeddingType] = useState<OrganizationBeddingType | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOrganizationBeddingTypes(organizationId: string) {
            const queryResult = await getOrganizationBeddingTypesByOrganizationId(organizationId);
            if (queryResult.isSuccess) {
                setOrganizationBeddingTypeArray(queryResult.result);
            }
        };
        if (organization) getOrganizationBeddingTypes(organization.id);
    }, [organization]);

    const handleSubmit = async (isCreate: Boolean, newOrganizationBeddingType: OrganizationBeddingType) => {
        setIsLoading(true);
        if (organization) {
            setError("");
            let stallArray: (OrganizationBeddingType | null)[] = [];
            if (isCreate) {
                if (organizationBeddingTypeArray && organizationBeddingTypeArray.length) {
                    stallArray = organizationBeddingTypeArray.concat([newOrganizationBeddingType]);
                } else {
                    stallArray.push(newOrganizationBeddingType);
                }
            } else {
                // Updated the selectedOrganizationBeddingType
                if (organizationBeddingTypeArray && organizationBeddingTypeArray.length) {
                    for (var i = 0; i < organizationBeddingTypeArray.length; i++) {
                        const organizationBeddingType = organizationBeddingTypeArray[i];
                        if (organizationBeddingType && organizationBeddingType.id === selectedOrganizationBeddingType?.id) stallArray.push(newOrganizationBeddingType);
                        else stallArray.push(organizationBeddingType);
                    }
                }
            }
            setOrganizationBeddingTypeArray(stallArray);
        } else {
            setError("Error: no organization found. Please contact hello@ringsidepro.com");
        }
        setIsLoading(false);
        setShowModal(false);
    }

    const handleUpdate = async (organizationBeddingType?: OrganizationBeddingType) => {
        if (organizationBeddingType) {
            setSelectedOrganizationBeddingType(organizationBeddingType);
            setShowModal(true);
        }
    }

    const handleAddNew = async () => {
        setSelectedOrganizationBeddingType(undefined);
        setShowModal(true);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {organizationBeddingTypeArray && organizationBeddingTypeArray.length ?
                <>
                    <IonList className="bg-white">
                        {organizationBeddingTypeArray.map((organizationBeddingType, index) => (
                            <IonItem key={index}>
                                <IonLabel>
                                    <IonRow>
                                        <IonCol>
                                            <h3 className="font-weight-bold">{organizationBeddingType && organizationBeddingType.name}</h3>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <h3>{organizationBeddingType?.pricePerBag ? "$" + organizationBeddingType.pricePerBag.toFixed(2) + " / bag" : ""}</h3>
                                        </IonCol>
                                    </IonRow>
                                    {organizationBeddingType?.taxRateA && (
                                        <IonRow>
                                            <IonCol>
                                                <h3>Tax #1: {organizationBeddingType.taxRateA.percentage + "%"}</h3>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                    {organizationBeddingType?.taxRateB && (
                                        <IonRow>
                                            <IonCol>
                                                <h3>Tax #2: {organizationBeddingType.taxRateB.percentage + "%"}</h3>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                </IonLabel>
                                <IonButton slot="end" color="tertiary" onClick={() => handleUpdate(organizationBeddingType as OrganizationBeddingType)}><IonIcon icon={create}/></IonButton>
                            </IonItem>
                        ))}
                    </IonList>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={handleAddNew}>
                                Add Bedding Type
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={handleAddNew}>
                                Add Bedding Type
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
            <IonModal backdropDismiss={false} isOpen={showModal} id="addOrganizationBeddingTypeModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Bedding Type
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeBeddingModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <BeddingTypeForm organization={organization} selectedOrganizationBeddingType={selectedOrganizationBeddingType} onSubmit={handleSubmit} />
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default OrganizationBeddingTypeForm;