import { 
    IonCard
} from "@ionic/react";
import React from "react";
import ProfileName from "./ProfileName";
import ProfilePicture from "./ProfilePicture";
import ProfileReferral from "./ProfileReferral";
import ProfileRoles from "./ProfileRoles";

const RiderEditProfileHomePage: React.FC = () => {

    return (
        <IonCard className="bg-white ion-padding">
            <ProfilePicture hasLink={true} />
            <ProfileName/>
            <ProfileRoles />
        </IonCard>
    );
};

export default RiderEditProfileHomePage;
