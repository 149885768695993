import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import ErrorAlert from "../Errors/ErrorAlert";
import { Barn, BeddingRequest, Event, EventBeddingType, EventEntry, EventStallType, StablingRequest, Trainer } from "../../models";
import { IonButton, IonCol, IonIcon, IonItem, IonLabel, IonList, IonRow } from "@ionic/react";
import { getEventStallTypesByEventId } from "../../utilities/eventStallType/EventStallType";
import { createStablingRequest, deleteStablingRequest, getStablingRequestsByEventId, updateStablingRequest } from "../../utilities/stablingRequest/StablingRequest";
import { Input } from "reactstrap";
import { getEventBeddingTypesByEventId } from "../../utilities/eventBeddingType/EventBeddingType";
import { createBeddingRequest, deleteBeddingRequest, getBeddingRequestsByEntryId, updateBeddingRequest } from "../../utilities/beddingRequest/BeddingRequest";
import { CreateBeddingRequestInput, CreateStablingRequestInput, UpdateBeddingRequestInput, UpdateStablingRequestInput } from "../../API";
import TrainerEntryForm from "../Trainer/TrainerEntryForm";
import BarnEntryForm from "../Barn/BarnEntryForm";
import { FormattedOption } from "../../interfaces/StablingRequest";
import SuccessBanner from "../Banners/SuccessBanner";
import { close } from "ionicons/icons";
import Spinner from "../Spinners/Spinner";
import WarningBanner from "../Banners/WarningBanner";

interface _Props {
    event: Event
    entry?: EventEntry
    isAdminView?: boolean
}

const EntryStablingForm: React.FC<_Props> = ({entry, event, isAdminView}) => {
    const user = useContext(PersonContext);

    // Basic state
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    // State to track the stabling and bedding requests for this entry
    const [formattedOptions, setFormattedOptions] = useState<FormattedOption[] | null | undefined>();
    const [totalStablingFees, setTotalStablingFees] = useState<number>(0);

    // State to track the additional settings for stabling for this entry
    const [trainer, setTrainer] = useState<Trainer | null | undefined>();
    const [barn, setBarn] = useState<Barn | null | undefined>();
    const [note, setNote] = useState("");

    useEffect(() => {    
        if (entry) {
            // Default - pre-select the barn and trainer from the entry.
            if (entry.barn) setBarn(entry.barn);
            if (entry.trainer) setTrainer(entry.trainer);

            // Check if this entry has any stabling and/or bedding data
            if (event) {
                getData(event, entry);
            }
        }
    }, [entry, event]);

    const getData = async (event: Event, entry: EventEntry) => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        const stallTypes = await getEventStallTypes(event);
        const beddingTypes = await getEventBeddingTypes(event);
        const previousStablingRequests = await getPreviousStablingRequests(entry);
        const previousBeddingRequests = await getPreviousBeddingRequests(entry);
        await getFormattedOptions(stallTypes, beddingTypes, previousStablingRequests, previousBeddingRequests);
        setIsLoading(false);
    }

    const getEventStallTypes = async (event: Event) => {
        const queryResult = await getEventStallTypesByEventId(event.id);
        if (queryResult.isSuccess) {
            return queryResult.result;
        }
    }

    const getEventBeddingTypes = async (event: Event) => {
        const queryResult = await getEventBeddingTypesByEventId(event.id);
        if (queryResult.isSuccess) {
            return queryResult.result;
        }
    }

    const getPreviousStablingRequests = async (entry: EventEntry) => {
        let requestList: StablingRequest[] = [];
        const queryResult = await getStablingRequestsByEventId(event.id);
        if (queryResult.isSuccess) {
            const allStablingRequests = queryResult.result;
            allStablingRequests.forEach((sr: StablingRequest) => {
                const currentEntries = sr.entryIds;
                if (currentEntries && currentEntries.length) {
                    currentEntries.forEach(currentEntry => {
                        if (currentEntry === entry.id) requestList.push(sr);
                    });
                }
            });
        }
        return requestList;
    }

    const getPreviousBeddingRequests = async (entry: EventEntry) => {
        let beddingRequests: BeddingRequest[] = [];
        const queryResult = await getBeddingRequestsByEntryId(entry.id);
        if (queryResult.isSuccess) {
            beddingRequests = queryResult.result;
        }
        return beddingRequests;
    }

    const getFormattedOptions = async (eventStallTypes: EventStallType[], eventBeddingTypes: EventBeddingType[], previousStablingRequests?: StablingRequest[], previousBeddingRequests?: BeddingRequest[]) => {
        let result: FormattedOption[] = [];
        for (let i = 0; i < eventStallTypes.length; i++) {
            const eventStallType = eventStallTypes[i];
            const formattedOptions: FormattedOption[] = formatStallTypeStablingRequests(eventStallType, previousStablingRequests);
            result = result.concat(formattedOptions);
        }
        for (let i = 0; i < eventBeddingTypes.length; i++) {
            const eventBeddingType: EventBeddingType = eventBeddingTypes[i];
            const formattedOptions: FormattedOption[] = formatBeddingTypeBeddingRequests(eventBeddingType, previousBeddingRequests);
            result = result.concat(formattedOptions);
        }
        const sorted = result.sort((a, b) => a.optionName.localeCompare(b.optionName));
        setFormattedOptions(sorted || result);

        // Calculate the total stabling cost
        calculateTotalPrice(sorted || result);
    }

    const formatStallTypeStablingRequests = (eventStallType: EventStallType, previousStablingRequests?: StablingRequest[]) => {
        const formattedOptions: FormattedOption[] = [];

        // Get pricing info unique to this eventStallType
        const pricePerStall = eventStallType.pricePerStall ? parseFloat(eventStallType.pricePerStall) : 0;
        const taxAmount = eventStallType.taxPerStall ? parseFloat(eventStallType.taxPerStall) : 0;

        // Check for any previous stabling requests that this entry submitted
        const matchingStablingRequests = previousStablingRequests?.filter((sr: StablingRequest) => sr.stallTypeId === eventStallType.id);
        if (matchingStablingRequests && matchingStablingRequests.length > 0) {
            for (let index = 0; index < matchingStablingRequests.length; index++) {
                const found = matchingStablingRequests[index];

                if (found?.requestNote) setNote(found.requestNote);
                if (found?.trainer) setTrainer(found.trainer);
                if (found?.barn) setBarn(found.barn);

                const quantity = found.quantityNeeded || 0;
                const basePrice = pricePerStall * quantity;
                const totalPrice = basePrice + (basePrice * taxAmount);

                const formattedOption: FormattedOption = {
                    optionName: eventStallType.name || "unknown",
                    description: eventStallType.description || "",
                    type: "stall",
                    basePrice: pricePerStall,
                    taxAmount: taxAmount,
                    quantity: quantity,
                    totalPrice: totalPrice,
                    status: found.status || "",
                    eventStallType: eventStallType,
                    stablingRequest: found,
                    isEditedAndUnSaved: false
                };

                formattedOptions.push(formattedOption);
            }
        } 
        // If there are no matching previous stabling requests - create a blank option for this stall type
        else {
            const formattedOption: FormattedOption = {
                optionName: eventStallType.name || "unknown",
                description: eventStallType.description || "",
                type: "stall",
                basePrice: pricePerStall,
                taxAmount: taxAmount,
                quantity: 0,
                totalPrice: 0, //Current total price is $0 if the quantity is 0
                status: "",
                eventStallType: eventStallType,
                isEditedAndUnSaved: false
            };
            formattedOptions.push(formattedOption);
        }

        return formattedOptions;
    }

    const formatBeddingTypeBeddingRequests = (eventBeddingType: EventBeddingType, previousBeddingRequests?: BeddingRequest[]) => {
        const formattedOptions: FormattedOption[] = [];

        // Get pricing info unique to this eventBeddingType
        const pricePerBag = eventBeddingType.pricePerBag ? parseFloat(eventBeddingType.pricePerBag) : 0;
        const taxAmount = eventBeddingType.taxPerBag ? parseFloat(eventBeddingType.taxPerBag) : 0;

        // Check for any previous bedding requests that this entry submitted
        const matchingStablingRequests = previousBeddingRequests?.filter((br: BeddingRequest) => br.beddingType === eventBeddingType.id);
        if (matchingStablingRequests && matchingStablingRequests.length > 0) {
            for (let index = 0; index < matchingStablingRequests.length; index++) {
                const found = matchingStablingRequests[index];

                if (found?.trainer) setTrainer(found.trainer);
                if (found?.barn) setBarn(found.barn);

                const quantity = found.quantityNeeded || 0;
                const basePrice = pricePerBag * quantity;
                const totalPrice = basePrice + (basePrice * taxAmount);

                const formattedOption: FormattedOption = {
                    optionName: eventBeddingType.name || "unknown",
                    description: eventBeddingType.description || "",
                    type: "bedding",
                    basePrice: pricePerBag,
                    taxAmount: taxAmount,
                    quantity: quantity,
                    totalPrice: totalPrice,
                    status: found?.status || "",
                    eventBeddingType: eventBeddingType,
                    beddingRequest: found,
                    isEditedAndUnSaved: false
                };

                formattedOptions.push(formattedOption);
            }
        } 
        // If there are no matching previous bedding requests - create a blank option for this bedding type
        else {
            const formattedOption: FormattedOption = {
                optionName: eventBeddingType.name || "unknown",
                description: eventBeddingType.description || "",
                type: "bedding",
                basePrice: pricePerBag,
                taxAmount: taxAmount,
                quantity: 0,
                totalPrice: 0, //Current total price is $0 if the quantity is 0
                status: "",
                eventBeddingType: eventBeddingType,
                isEditedAndUnSaved: false
            };
            formattedOptions.push(formattedOption);
        }

        return formattedOptions;
    }

    const handleTrainerInputChange = async (trainer: Trainer) => {
        setTrainer(trainer);
    }

    const handleBarnInputChange = async (barn: Barn) => {
        setBarn(barn);
    }

    const handleSaveBarnAndTrainer = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        const updatedFormattedOptions: FormattedOption[] = [];
        // All stabling and bedding for this entry will have the same trainer info
        // Update all formatted options with stabling / bedding requests attached
        if (formattedOptions && formattedOptions.length > 0) {
            for (let i = 0; i < formattedOptions.length; i++) {
                const currentFormattedOption = formattedOptions[i];
                if (currentFormattedOption.stablingRequest) {
                    const updateInput: UpdateStablingRequestInput = {
                        id: currentFormattedOption.stablingRequest.id,
                        trainerId: trainer?.id || "",
                        barnId: barn?.id || ""
                    };
                    const updateResult = await updateStablingRequest(updateInput);
                    if (updateResult.isSuccess) {
                        const updatedStablingRequest: StablingRequest = updateResult.result;
                        const updatedFormattedOption: FormattedOption = {
                            ...currentFormattedOption,
                            stablingRequest: updatedStablingRequest
                        };
                        updatedFormattedOptions.push(updatedFormattedOption);
                    } else {
                        // Update failed. Keep the old version of the request in the FormattedOptions
                        updatedFormattedOptions.push(currentFormattedOption);
                    }
                } else if (currentFormattedOption.beddingRequest) {
                    const updateInput: UpdateBeddingRequestInput = {
                        id: currentFormattedOption.beddingRequest.id,
                        trainerId: trainer?.id,
                        barnId: barn?.id || ""
                    };
                    const updateResult =  await updateBeddingRequest(updateInput);
                    if (updateResult.isSuccess) {
                        const updatedBeddingRequest: BeddingRequest = updateResult.result;
                        const updatedFormattedOption: FormattedOption = {
                            ...currentFormattedOption,
                            beddingRequest: updatedBeddingRequest
                        };
                        updatedFormattedOptions.push(updatedFormattedOption);
                    } else {
                        // Update failed. Keep the old version of the request in the FormattedOptions
                        updatedFormattedOptions.push(currentFormattedOption);
                    }
                } else {
                    updatedFormattedOptions.push(currentFormattedOption);
                }
            }
        }
        setFormattedOptions(updatedFormattedOptions);
        setIsLoading(false);
    }

    const handleSaveStablingNote = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        const updatedFormattedOptions: FormattedOption[] = [];
        // All stabling this entry will have the same note info
        // Update all formatted options with stabling requests attached
        if (formattedOptions && formattedOptions.length > 0) {
            for (let i = 0; i < formattedOptions.length; i++) {
                const currentFormattedOption = formattedOptions[i];
                if (currentFormattedOption.stablingRequest) {
                    const updateInput: UpdateStablingRequestInput = {
                        id: currentFormattedOption.stablingRequest.id,
                        requestNote: note
                    };
                    const updateResult = await updateStablingRequest(updateInput);
                    if (updateResult.isSuccess) {
                        const updatedStablingRequest: StablingRequest = updateResult.result;
                        const updatedFormattedOption: FormattedOption = {
                            ...currentFormattedOption,
                            stablingRequest: updatedStablingRequest
                        };
                        updatedFormattedOptions.push(updatedFormattedOption);
                    } else {
                        // Update failed. Keep the old version of the request in the FormattedOptions
                        updatedFormattedOptions.push(currentFormattedOption);
                    }
                } else {
                    updatedFormattedOptions.push(currentFormattedOption);
                }
            }
        }
        setFormattedOptions(updatedFormattedOptions);
        setIsLoading(false);
    }

    const calculatePrice = (pricePerStall: number, tax: number, quantity: number) => {
        const basePrice = pricePerStall * quantity;
        const taxRate = tax / 100;
        const fullPrice = basePrice * taxRate + basePrice;
        return fullPrice;
    }

    const calculateTotalPrice = (currentFormattedOptions: FormattedOption[]) => {
        let totalStablingFee = 0;
        for (let i = 0; i < currentFormattedOptions.length; i++) {
            const currentOption = currentFormattedOptions[i];

            if (currentOption.eventStallType) {
                const eventStallType: EventStallType = currentOption.eventStallType;

                // Get the pricing info for this stall type
                const pricePerStall = eventStallType.pricePerStall ? parseFloat(eventStallType.pricePerStall) : 0;
                const taxAmount = eventStallType.taxPerStall ? parseFloat(eventStallType.taxPerStall) : 0;

                const quantity = (currentOption && currentOption.quantity) ? currentOption.quantity : 0;
                const basePrice = pricePerStall * quantity;
                const totalPrice = basePrice + (basePrice * (taxAmount/100));

                totalStablingFee = totalStablingFee + totalPrice;
            } else if (currentOption.eventBeddingType) {
                const eventBeddingType: EventBeddingType = currentOption.eventBeddingType;

                // Get the pricing info for this bedding type
                const pricePerStall = eventBeddingType.pricePerBag ? parseFloat(eventBeddingType.pricePerBag) : 0;
                const taxAmount = eventBeddingType.taxPerBag ? parseFloat(eventBeddingType.taxPerBag) : 0;

                const quantity = (currentOption && currentOption.quantity) ? currentOption.quantity : 0;
                const basePrice = pricePerStall * quantity;
                const totalPrice = basePrice + (basePrice * (taxAmount/100));

                totalStablingFee = totalStablingFee + totalPrice;
            }
        }
        setTotalStablingFees(totalStablingFee);
    }

    const handleUpdateQuantity = (index: number, option: FormattedOption, quantity: number) => {
        if (formattedOptions) {
            const updatedOption: FormattedOption = {
                ...option,
                quantity: quantity,
                status: isAdminView ? (quantity > 0 ? "accepted" : "saved") : "saved", 
                isEditedAndUnSaved: true
            };
            const newOptions = [
                ...formattedOptions.slice(0, index),
                updatedOption,
                ...formattedOptions.slice(index + 1)
            ];
            setFormattedOptions(() => newOptions);
        }
    }

    const handleStatusChange = async (formattedOption: FormattedOption, index: number, status: string, stablingRequest?: StablingRequest, beddingRequest?: BeddingRequest) => {
        if (stablingRequest) {
            const input: UpdateStablingRequestInput = {
                id: stablingRequest.id,
                status: status
            };
            const updateStablingRequestResult = await updateStablingRequest(input);
            if (updateStablingRequestResult.isSuccess) {
                const updatedFormattedOption: FormattedOption = {
                    ...formattedOption,
                    status: status,
                    isEditedAndUnSaved: true
                };
                if (formattedOptions) {
                    const updatedFormattedOptions: FormattedOption[] = [
                        ...formattedOptions.slice(0, index),
                        updatedFormattedOption,
                        ...formattedOptions.slice(index + 1),
                    ];
                    setFormattedOptions(updatedFormattedOptions);
                }
            }
        } else if (beddingRequest) {
            const input: UpdateBeddingRequestInput = {
                id: beddingRequest.id,
                status: status
            };
            const updateBeddingRequestResult = await updateBeddingRequest(input);
            if (updateBeddingRequestResult.isSuccess) {
                const updatedFormattedOption: FormattedOption = {
                    ...formattedOption,
                    status: status,
                    isEditedAndUnSaved: true
                };
                if (formattedOptions) {
                    const updatedFormattedOptions: FormattedOption[] = [
                        ...formattedOptions.slice(0, index),
                        updatedFormattedOption,
                        ...formattedOptions.slice(index + 1),
                    ];
                    setFormattedOptions(updatedFormattedOptions);
                }
            }
        } else {
            setError("No option found.");
        }
    }

    const handleSubmitRequests = async (updatedFormattedOptions?: FormattedOption[]) => {
        setError("");
        // While saving changes, also track any updates to the formatted options
        let newFormattedOptions: FormattedOption[] = [];

        // Loop through the formatted options to find changes and save them
        const currentFormattedOptions: (FormattedOption[] | null | undefined) = updatedFormattedOptions || formattedOptions;
        if (currentFormattedOptions) {
            for (let i = 0; i < currentFormattedOptions.length; i++) {
                const element = currentFormattedOptions[i];

                // First, check if this option has been changed and needs to be saved
                if (element.isEditedAndUnSaved) {
                    if (element.type === "stall") {
                        const eventStallType: (EventStallType | undefined) = element.eventStallType;
                        const pricePerStall = parseFloat(eventStallType?.pricePerStall || "0");
                        const tax = parseFloat(eventStallType?.taxPerStall || "0");
                        const quantity = element.quantity;
                        const totalPrice = calculatePrice(pricePerStall, tax, quantity);

                        // Check if it matched to a previous stall request from this entry
                        if (element.stablingRequest) {
                            const currentStablingRequest = element.stablingRequest;
                            // Check if quantity should change
                            if (element.quantity > 0) {
                                // Then update the stabling request
                                const input: UpdateStablingRequestInput = {
                                    id: currentStablingRequest.id,
                                    quantityNeeded: element.quantity,
                                    totalPrice: totalPrice,
                                    requestNote: note,
                                };
                                const updateResult = await updateStablingRequest(input);
                                if (updateResult.isSuccess) {
                                    const newStablingRequest: StablingRequest = updateResult.result;
                                    const updatedFormattedOption: FormattedOption = {
                                        ...element,
                                        stablingRequest: newStablingRequest,
                                        totalPrice: totalPrice,
                                        isEditedAndUnSaved: false
                                    };
                                    newFormattedOptions.push(updatedFormattedOption);
                                }
                            } else {
                                // Remove the stabling request
                                const deleteResult = await deleteStablingRequest({id: currentStablingRequest.id});
                                if (deleteResult.isSuccess) {
                                    const updatedFormattedOption: FormattedOption = {
                                        ...element,
                                        stablingRequest: undefined,
                                        totalPrice: 0,
                                        isEditedAndUnSaved: false
                                    };
                                    newFormattedOptions.push(updatedFormattedOption);
                                }
                            }
                        } else {
                            if (element.quantity > 0) {
                                // Then create the stabling request
                                const input: CreateStablingRequestInput = {
                                    eventId: event.id,
                                    personId: user.id,
                                    stallTypeId: eventStallType?.id || "",
                                    quantityNeeded: quantity,
                                    trainerId: trainer?.id || "",
                                    basePrice: pricePerStall,
                                    taxA: tax,
                                    totalPrice: totalPrice,
                                    status: isAdminView ? "accepted" : "saved",
                                    requestNote: note, 
                                    barnId: barn?.id || entry?.barnId,
                                    entryIds: entry ? [entry.id] : [],
                                    submittedEntry: entry?.id || ""
                                };
                                const createResult = await createStablingRequest(input);
                                if (createResult.isSuccess) {
                                    const newStablingRequest: StablingRequest = createResult.result;
                                    const updatedFormattedOption: FormattedOption = {
                                        ...element,
                                        stablingRequest: newStablingRequest,
                                        totalPrice: totalPrice,
                                        isEditedAndUnSaved: false
                                    };
                                    newFormattedOptions.push(updatedFormattedOption);
                                }
                            } else {
                                newFormattedOptions.push(element);
                            }
                        }
                    } else if (element.type === "bedding") {
                        const eventBeddingType: (EventBeddingType | undefined) = element.eventBeddingType;
                        const pricePerBag = parseFloat(eventBeddingType?.pricePerBag || "0");
                        const tax = parseFloat(eventBeddingType?.taxPerBag || "0");
                        const quantity = element.quantity;
                        const totalPrice = calculatePrice(pricePerBag, tax, quantity);

                        // Check if it matched to a previous bedding request from this entry
                        if (element.beddingRequest) {
                            const currentBeddingRequest = element.beddingRequest;
                            if (element.quantity > 0) {
                                // Then update the bedding request
                                const input: UpdateBeddingRequestInput = {
                                    id: currentBeddingRequest.id,
                                    quantityNeeded: element.quantity,
                                    totalPrice: totalPrice,
                                };
                                const updateResult = await updateBeddingRequest(input);
                                if (updateResult.isSuccess) {
                                    const newBeddingRequest: BeddingRequest = updateResult.result;
                                    const updatedFormattedOption: FormattedOption = {
                                        ...element,
                                        beddingRequest: newBeddingRequest,
                                        totalPrice: totalPrice,
                                        isEditedAndUnSaved: false
                                    };
                                    newFormattedOptions.push(updatedFormattedOption);
                                }
                            } else {
                                // Remove the bedding request
                                const deleteResult = await deleteBeddingRequest({id: currentBeddingRequest.id});
                                if (deleteResult.isSuccess) {
                                    const updatedFormattedOption: FormattedOption = {
                                        ...element,
                                        beddingRequest: undefined,
                                        totalPrice: 0,
                                        isEditedAndUnSaved: false
                                    };
                                    newFormattedOptions.push(updatedFormattedOption);
                                }
                            }
                        } else {
                            if (element.quantity > 0) {
                                // Then create the bedding request
                                const input: CreateBeddingRequestInput = {
                                    eventId: event.id,
                                    personId: user.id,
                                    beddingType: eventBeddingType?.id || "",
                                    quantityNeeded: quantity,
                                    trainerId: trainer?.id || "",
                                    basePrice: pricePerBag,
                                    totalPrice: totalPrice,
                                    status: isAdminView ? "accepted" : "saved",
                                    barnId: barn?.id || entry?.barnId,
                                    entryIds: entry ? [entry.id] : [],
                                    submittedEntry: entry?.id || ""
                                };
                                const createResult = await createBeddingRequest(input);
                                if (createResult.isSuccess) {
                                    const newBeddingRequest: BeddingRequest = createResult.result;
                                    const updatedFormattedOption: FormattedOption = {
                                        ...element,
                                        beddingRequest: newBeddingRequest,
                                        totalPrice: totalPrice,
                                        isEditedAndUnSaved: false
                                    };
                                    newFormattedOptions.push(updatedFormattedOption);
                                }
                            } else {
                                newFormattedOptions.push(element);
                            }
                        }
                    } 
                }
                else {
                    // The current element did not have an edit that needed to be saved.
                    newFormattedOptions.push(element);
                }
            }
            setSuccess("Successfully updated the stabling info for this entry.")
        } else {
            setError("No info found.");
        }
        setFormattedOptions(newFormattedOptions);
    }

    // Handles saving any change to the stabling / bedding for this entry
    const handleSubmit = async (updatedFormattedOptions?: FormattedOption[]) => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        // Quantities have all been updated - calculate new price
        if (updatedFormattedOptions) await calculateTotalPrice(updatedFormattedOptions);
        else if (formattedOptions) await calculateTotalPrice(formattedOptions);
        // Then, loop through FormattedOptions and handle saving any changes
        await handleSubmitRequests(updatedFormattedOptions);
        setIsLoading(false);
    }

    // List View - Allows the user to update a line item to 0 quantity and then save
    const handleDelete = async (index: number, selectedFormattedOption: FormattedOption) => {
        if (formattedOptions && formattedOptions.length > 0) {
            // TO DO - Update the price as well
            const updatedOption: FormattedOption = {
                ...selectedFormattedOption,
                quantity: 0,
                totalPrice: 0,
                status: "",
                isEditedAndUnSaved: true //At this point, the quantity is updated and the underlying record still needs to be dealt with
            };
            const newOptions = [
                ...formattedOptions.slice(0, index),
                updatedOption,
                ...formattedOptions.slice(index + 1)
            ];
            await setFormattedOptions(newOptions);
            await handleSubmit(newOptions);
        } else {
            setError("No stabling or bedding options were found.");
        }
    }

    return (
        <>
            {success && <SuccessBanner width="12" success={success}/>}
            {error && <ErrorAlert width="12" error={error}/>}
            {isLoading ?
                <Spinner/>
                :
                <>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="text-center" sizeMd="10">
                            <p>Event Stabling Options</p>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="10">
                            {formattedOptions && formattedOptions.length > 0 ?
                                <>
                                    {/* Stabling Line Items Section */}
                                    <IonList className="bg-white">
                                        {formattedOptions.map((formattedOption, index) => (
                                            <IonItem key={index}>
                                                <IonLabel className="ion-text-wrap">
                                                    <IonRow>
                                                        <IonCol sizeXs="12" sizeMd="4">
                                                            <p className="text-primary ion-text-wrap">{formattedOption.optionName}</p>
                                                        </IonCol>
                                                        <IonCol sizeXs="12" sizeMd="4">
                                                            <p className="text-primary ion-text-wrap">Cost: ${formattedOption.basePrice.toFixed(2)}</p>
                                                        </IonCol>
                                                        <IonCol sizeXs="12" sizeMd="4">
                                                            <Input
                                                                valid={formattedOption.quantity > 0}
                                                                type="number"
                                                                min={0}
                                                                value={formattedOption.quantity}
                                                                onChange={e => {
                                                                    handleUpdateQuantity(index, formattedOption, parseFloat(e.target.value))
                                                                }}
                                                            />
                                                        </IonCol>
                                                    </IonRow>
                                                    {formattedOption.description && (
                                                        <IonRow>
                                                            <IonCol>
                                                                <p className="text-primary">Description: {formattedOption.description}</p>
                                                            </IonCol>
                                                        </IonRow>
                                                    )}
                                                    {(isAdminView && formattedOption.quantity > 0 && formattedOption.status !== undefined) && (
                                                        <>
                                                            {(formattedOption.status !== "accepted" && formattedOption.status !== "declined") && (
                                                                <IonRow>
                                                                    <IonCol>
                                                                        <p className="text-warning"></p>
                                                                        <WarningBanner warning='An admin needs to adjust the status to "Accepted". Click on the dropdown below to adjust the status.' />
                                                                    </IonCol>
                                                                </IonRow>
                                                            )}
                                                            <IonRow>
                                                                <IonCol>
                                                                    <Input 
                                                                        name="type" 
                                                                        type="select" 
                                                                        value={formattedOption.status} 
                                                                        onChange={e => {
                                                                            handleStatusChange(formattedOption, index, e.target.value, formattedOption.stablingRequest, formattedOption.beddingRequest);
                                                                        }}
                                                                    >   
                                                                        <option value="saved" key="saved">In Progress</option>
                                                                        <option value="submitted" key="submitted">Submitted</option>
                                                                        <option value="accepted" key="accepted">Accepted</option>
                                                                        <option value="declined" key="declined">Declined</option>
                                                                    </Input>
                                                                </IonCol>
                                                            </IonRow>
                                                        </>
                                                    )}
                                                    {formattedOption.isEditedAndUnSaved && (
                                                        <IonRow>
                                                            <IonCol>
                                                                <p className="text-danger">This row has been edited but not yet saved. Click the "Save" button below.</p>
                                                            </IonCol>
                                                        </IonRow>
                                                    )}
                                                </IonLabel>
                                            </IonItem>
                                        ))}
                                    </IonList>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol className="text-center" sizeMd="4">
                                            <IonButton
                                                className="ion-margin-top"
                                                color="success"
                                                expand="block"
                                                onClick={() => handleSubmit()}
                                            >
                                                Save Stabling Options
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                    <hr/>
                                    {/* Stable With Section */}
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol className="text-center" sizeMd="10">
                                            <p>Stable With Info</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <TrainerEntryForm trainer={trainer} barn={barn} onChange={handleTrainerInputChange} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <BarnEntryForm barn={barn} useAllBarns={true} label="Stable With (barn)" onChange={handleBarnInputChange} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol className="text-center" sizeMd="4">
                                            <IonButton
                                                className="ion-margin-top"
                                                color="success"
                                                expand="block"
                                                onClick={() => handleSaveBarnAndTrainer()}
                                            >
                                                Save Barn and Trainer
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                    <hr/>
                                    {/* Notes Section */}
                                    <IonRow>
                                        <IonCol sizeMd="12">
                                            <p className="text-center">Stabling Notes</p>
                                            <p className="description">Include any arrival and departure info, mare and stallion info, etc. You may include barn or stall location preferences, but they are not guarenteed to be met.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <Input
                                                placeholder="Add notes for this entry."
                                                rows="5"
                                                type="textarea"
                                                name="note"
                                                value={note}
                                                spellCheck="true"
                                                onChange={(event) => setNote(event.target.value)}
                                                data-hj-whitelist
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol className="text-center" sizeMd="4">
                                            <IonButton
                                                className="ion-margin-top"
                                                color="success"
                                                expand="block"
                                                onClick={() => handleSaveStablingNote()}
                                            >
                                                Save Stabling Notes
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </>
                                :
                                <p>This event does not have any stabling options.</p>
                            }
                        </IonCol>
                    </IonRow>

                    {/* Only show the list view to admin users */}
                    {isAdminView &&
                        <>
                            {/* If the event offers stabling options, then show the list view */}
                            {(formattedOptions && formattedOptions.length > 0) && ( 
                                <>
                                    <hr />
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol className="text-center">
                                            <h2>Entry's Saved Stabling Info</h2>
                                            <p>The list below shows all of the items currently saved to this entry. You can edit the items above, or delete them using the X buttons below.</p>
                                        </IonCol>
                                    </IonRow>
                                    <hr/>
                                    <IonList className="bg-white">
                                        {formattedOptions.map((formattedOption, index) => (
                                            <>
                                                {(formattedOption.quantity > 0 && !formattedOption.isEditedAndUnSaved) && (
                                                    <IonItem key={index}>
                                                        <IonLabel>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p>Name: {formattedOption.optionName} ({formattedOption.basePrice ? "$" + formattedOption.basePrice.toFixed(2) : ""})</p>
                                                                </IonCol>
                                                                <IonCol className="ion-text-right">
                                                                    <IonButton 
                                                                        color="danger" 
                                                                        onClick={() => {
                                                                            handleDelete(index, formattedOption);
                                                                        }}
                                                                    >
                                                                        <IonIcon icon={close}/>
                                                                    </IonButton>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p>Trainer: {formattedOption.stablingRequest?.trainer?.name || formattedOption.beddingRequest?.trainer?.name}</p>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p>Barn: {formattedOption.stablingRequest?.barn?.name || formattedOption.beddingRequest?.barn?.name}</p>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p className={formattedOption.beddingRequest ? "text-warning" : ""}>Note: {formattedOption.stablingRequest?.requestNote || (formattedOption.beddingRequest ? "No note - bedding requests do not have notes attached to them." : "")}</p>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p>Quantity: {formattedOption.quantity}</p>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p>Tax: {formattedOption.taxAmount ? formattedOption.taxAmount.toString() + "%" : "n/a"}</p>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p>Total: ${(formattedOption.totalPrice).toFixed(2)}</p>
                                                                </IonCol>
                                                            </IonRow>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p>Status: {formattedOption.status}</p>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonLabel>
                                                    </IonItem>
                                                )}
                                            </>
                                        ))}
                                        <IonItem key="total">
                                            <IonLabel color="dark">
                                                <h3 className="font-weight-bold">
                                                    Total: {totalStablingFees ? "$" + totalStablingFees.toFixed(2) : ""}
                                                </h3>
                                            </IonLabel>
                                        </IonItem>
                                    </IonList>
                                </>
                            )}
                        </>
                    }
                </> 
            }
        </>
    );
};

export default EntryStablingForm;