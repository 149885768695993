import {
    IonButton,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRadio,
    IonRadioGroup,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, EventDivision, EventResult } from "../../models";
import { getEventDivisionsByEventIdWithResults } from "../../utilities/eventDivision/EventDivision";
import { Table } from "reactstrap";
import { isWindows } from "../../utilities/platform/Platform";
import Spinner from "../Spinners/Spinner";

interface formattedEventDivision {
    name: string,
    hasResults: boolean,
    ed: EventDivision
}

interface _Props {
    event: Event
    onSelect: Function
}

const EventDivisionResultsTable: React.FC<_Props> = ({event, onSelect}) => {

    const [eventDivisions, setEventDivisions] = useState<EventDivision[] | null | undefined>();
    const [formattedEventDivisions, setFormattedEventDivisions] = useState<formattedEventDivision[] | null | undefined>();
    const [selectedDivision, setSelectedDivision] = useState<EventDivision | null | undefined>();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const formatEventDivisions = async (eventDivisions: EventDivision[]) => {
        setIsLoading(true);
        let formattedEventDivisions: formattedEventDivision[] = [];
        if (eventDivisions) {
            for (var i = 0; i < eventDivisions.length; i++) {
                const currentDivision = eventDivisions[i];
                const resultsObject: any = currentDivision.eventResults;
                const resultsArray: EventResult[] | null = resultsObject.items;
                let hasResults = false;
                if (resultsArray && resultsArray.length > 0) hasResults = true;
                const formattedClass: formattedEventDivision = {
                    name: currentDivision.name,
                    hasResults: hasResults,
                    ed: currentDivision
                }
                formattedEventDivisions.push(formattedClass);
            }
        }
        const sorted = formattedEventDivisions.sort((a, b) => a.name.localeCompare(b.name));
        setFormattedEventDivisions(sorted || formattedEventDivisions);
        setIsLoading(false);
    }

    useEffect(() => {
        async function getEventDivisions(event: Event) {
            const queryResult = await getEventDivisionsByEventIdWithResults(event.id);
            if (queryResult.isSuccess) {
                setEventDivisions(queryResult.result);
                formatEventDivisions(queryResult.result);
            }
        }

        if (event) getEventDivisions(event);
    }, [event]);

    const onSelectDivision = (c: EventDivision) => {
        onSelect(c);
    }

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>
                <IonRow>
                    <IonCol size="4">
                        Divisions
                    </IonCol>
                </IonRow>
            </IonCardTitle>
            {selectedDivision && (
                <IonCardSubtitle>
                    <IonButton size="small" onClick={() => {setSelectedDivision(undefined); onSelect();}}>
                        Clear Selection
                    </IonButton>
                </IonCardSubtitle>
            )}
            {isLoading ?
                <Spinner />
                :
                <>
                    {(!isWindows() && isPlatform("mobile")) ?
                        <IonList className="bg-white">
                            {(formattedEventDivisions && formattedEventDivisions.length > 0) && (
                                <>
                                    {formattedEventDivisions.map((formattedEventDivision, index) => (
                                        <IonItem key={index} onClick={() => onSelectDivision(formattedEventDivision.ed)}>
                                            <IonLabel>
                                                <IonRow>
                                                    <IonCol>
                                                        <p className="ion-text-wrap"><p className="ion-text-wrap">{formattedEventDivision.name}</p></p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <p>Championship Results: <span color={formattedEventDivision.hasResults ? "success" : "secondary"}>{formattedEventDivision.hasResults ? "yes" : "no"}</span></p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonLabel>
                                            <IonRadio slot="start" color="tertiary" value={formattedEventDivision.ed.id} />
                                        </IonItem>
                                    ))}
                                </>
                            )}
                        </IonList>
                        :
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Championship Results</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(formattedEventDivisions && formattedEventDivisions.length > 0) && (
                                    <>
                                        {formattedEventDivisions.map((formattedEventDivision, index) => (
                                            <tr key={index} onClick={() => onSelectDivision(formattedEventDivision.ed)}>
                                                <td>
                                                    {formattedEventDivision.name}
                                                </td>
                                                <td>
                                                <span className={formattedEventDivision.hasResults ? "text-success font-weight-bold" : ""}>{formattedEventDivision.hasResults ? "Yes" : "No"}</span>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </Table>
                    }
                </>
            }
        </IonCard>
    );
};

export default EventDivisionResultsTable;