import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useState } from "react";
import { CreateEventOfficialInput } from "../../API";
import { Event, EventOfficial } from "../../models";
import { createEventOfficial } from "../../utilities/eventOfficial/EventOfficial";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    event: Event
    eventOfficial?: EventOfficial
    onSubmit: Function
}

const EventOfficialForm: React.FC<_Props> = ({eventOfficial, event, onSubmit}) => {
    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [name, setName] = useState(eventOfficial?.name || "");
    const [position, setPosition] = useState(eventOfficial?.position || "");
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState<string>("");

    const verifyForm = () => {
        if (!name) {
            setError("Error: include a name.");
            return false;
        }
        if (!position) {
            setError("Error: include a position. Examples include: show manager, secretary, announcer, etc.");
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) {
            const input: CreateEventOfficialInput = {
                name,
                position,
                eventId: event.id
            };
            const createResult = await createEventOfficial(input);
            if (createResult.isSuccess) {
                onSubmit(createResult.result);
            } else {
                setError("Error: could not create the official.");
            }
            setIsDisabled(true);
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name</IonLabel>
                            <IonInput 
                                type="text"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Position</IonLabel>
                            <IonInput 
                                type="text"
                                value={position}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setPosition(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default EventOfficialForm;