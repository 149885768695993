import React, { useEffect, useState } from "react";
import { Address } from "../../models";
import BasicSelect, { formattedOption } from "../Select/BasicSelect";
import { formatAddress } from "../../utilities/address/FormatAddress";
import { Spinner } from "reactstrap";
import {  getAllAddresses } from "../../utilities/address/Address";

interface _Props {
    addressValue?: string
    onSelect: Function
}

const SelectFromAllAddresses: React.FC<_Props> = ({addressValue, onSelect}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [addressId, setAddressId] = useState<string>(addressValue || "-");
    const [formattedData, setFormattedData] = useState<formattedOption[] | null | undefined>();

    const getAddresses = async () => {
        setIsLoading(true);
        const queryResult = await getAllAddresses();
        if (queryResult.isSuccess) {
            let addressList: Address[] = queryResult.result;
            formatAddresses(addressList);
        } else {
            setFormattedData([]);
        }
        setIsLoading(false);
    }

    const formatAddresses = (addressList: Address[]) => {
        let formattedAddresses = [];
        for (var i = 0; i < addressList.length; i++) {
            const currentAddress: Address = addressList[i];
            const value = currentAddress?.id || "";
            const label = currentAddress ? formatAddress(currentAddress) : "error";
            let object: formattedOption = {
                value: value,
                label: label,
                object: currentAddress
            };
            formattedAddresses.push(object);
        }
        const sorted = formattedAddresses.sort((a, b) => a.label.localeCompare(b.label));
        setFormattedData(sorted || formattedAddresses);
    }
    
    const getData = async () => {
        await getAddresses();
    }

    useEffect(() => {
        if (!formattedData) getData();
    }, []);

    useEffect(() => {
        if (addressValue) setAddressId(addressValue);
        else setAddressId("-");
    }, [addressValue]);

    const handleOnChange = (event?: any) => {
        if (event) {
            onSelect(event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {formattedData ?
                        <BasicSelect formattedValues={formattedData} isClearable={true} selectedValue={addressId} onSelect={handleOnChange} />
                        :
                        <p>No address options were found.</p>
                    }
                </>
            }
        </>
    );
};

export default SelectFromAllAddresses;