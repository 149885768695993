import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ViewEventNavbar from "../../../components/Navbars/ViewEventNavbar";
import {Event} from "../../../models";
import EventDisplayContact from "../../../components/Event/About/EventDisplayContact";
import EventDisciplinesForm from "../../../components/Event/About/EventDisciplinesForm";
import ErrorAlert from "../../../components/Errors/ErrorAlert";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../utilities/events/Event";
import EventDisplayDates from "../../../components/Event/About/EventDisplayDates";
import { calendarOutline, callOutline, checkboxOutline, documentsOutline, ellipsisVerticalOutline, locationOutline, peopleOutline, ribbon } from "ionicons/icons";
import EventDisplayLocation from "../../../components/Event/About/EventDisplayLocation";
import EventDisplayJudges from "../../../components/Event/About/EventDisplayJudges";
import EventDisplayOfficials from "../../../components/Event/About/EventDisplayOfficials";
import EventDisplayRating from "../../../components/Event/About/EventDisplayRating";
import EventDisplayDocuments from "../../../components/Event/About/EventDisplayDocuments";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventViewInfoPage: React.FC<EventPageProps> = ({match}) => {

    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setCurrentEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        getEvent();
    }, [match, match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent ? (currentEvent.nickname ? currentEvent.nickname : currentEvent.name) : "Event"} />
                {currentEvent && (
                    <>
                        <ViewEventNavbar active="about" event={currentEvent} />
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonRow id="eventName">
                            <IonCol sizeSm="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonItem lines="none">
                                            <IonIcon icon={calendarOutline} slot="start"/>
                                            <IonLabel> Dates</IonLabel>
                                        </IonItem>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EventDisplayDates event={currentEvent} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol sizeSm="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonItem lines="none">
                                            <IonIcon icon={locationOutline} slot="start"/>
                                            <IonLabel> Location</IonLabel>
                                        </IonItem>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EventDisplayLocation event={currentEvent} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow id="eventContact" className="ion-align-items-stretch">
                            <IonCol sizeSm="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonItem lines="none">
                                            <IonIcon icon={callOutline} slot="start"/>
                                            <IonLabel> Contact</IonLabel>
                                        </IonItem>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EventDisplayContact event={currentEvent} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol sizeSm="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonItem lines="none">
                                            <IonIcon icon={ribbon} slot="start"/>
                                            <IonLabel> Judges</IonLabel>
                                        </IonItem>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EventDisplayJudges event={currentEvent} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow id="eventOfficials" className="ion-align-items-stretch">
                            <IonCol sizeSm="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonItem lines="none">
                                            <IonIcon icon={peopleOutline} slot="start"/>
                                            <IonLabel> Officials</IonLabel>
                                        </IonItem>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EventDisplayOfficials event={currentEvent} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol sizeSm="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonItem lines="none">
                                            <IonIcon icon={ellipsisVerticalOutline} slot="start"/>
                                            <IonLabel> Disciplines</IonLabel>
                                        </IonItem>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EventDisciplinesForm event={currentEvent} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        {currentEvent.type === "horse_show" && (
                            <IonRow id="eventRating" className="ion-align-items-stretch">
                                <IonCol sizeSm="12" sizeMd="6">
                                    <IonCard mode="md" className="ion-padding bg-white stretch">
                                        <IonCardTitle>
                                            <IonItem lines="none">
                                                <IonIcon icon={checkboxOutline} slot="start"/>
                                                <IonLabel> Rating</IonLabel>
                                            </IonItem>
                                        </IonCardTitle>
                                        <IonCardContent>
                                            <EventDisplayRating event={currentEvent} />
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol sizeSm="12" sizeMd="6">
                                    <IonCard mode="md" className="ion-padding bg-white stretch">
                                        <IonCardTitle>
                                            <IonItem lines="none">
                                                <IonIcon icon={documentsOutline} slot="start"/>
                                                <IonLabel> Documents</IonLabel>
                                            </IonItem>
                                        </IonCardTitle>
                                        <IonCardContent>
                                            <EventDisplayDocuments event={currentEvent} />
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        )} 
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventViewInfoPage;