import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Horse, S3Object } from "../../../models";
import { updateHorse } from "../../../utilities/horse/Horse";
import ErrorAlert from "../../Errors/ErrorAlert";
import { UpdateHorseInput } from "../../../API";
import SuccessBanner from "../../Banners/SuccessBanner";
import CogginsDocumentForm from "../../s3Object/CogginsDocumentForm";
import { createCogginsDocumentFileName, createS3Object, handleUploadS3File } from "../../../utilities/s3Object/s3Object";
import moment from "moment";
import { PersonContext } from "../../../context/PersonContext";
import Spinner from "../../Spinners/Spinner";
import SelectState from "../../Address/SelectState";

interface _Props {
    horse: Horse
    onSubmit?: Function
}

const EditHorseCogginsForm: React.FC<_Props> = ({horse, onSubmit}) => {    
    const user = useContext(PersonContext);

    const [cogginsState, setCogginsState] = useState("");
    const [cogginsNumber, setCogginsNumber] = useState("");
    const [cogginsDate, setCogginsDate] = useState("");
    const [cogginsDocument, setCogginsDocument] = useState<any | null | undefined>();
    const [cogginsS3Object, setCogginsS3Object] = useState<S3Object | null | undefined>();
    
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const clearForm = () => {
        setCogginsNumber("");
        setCogginsDate("");
    }

    useEffect(() => {
        setSuccess("");
        if (horse) {
            if (horse.cogginsNumber) setCogginsNumber(horse.cogginsNumber || "");
            if (horse.cogginsDate) setCogginsDate(horse.cogginsDate || "");
            if (horse.cogginsState) setCogginsState(horse.cogginsState || "");
            if (horse.coggins) setCogginsS3Object(horse.coggins);
        } else {
            clearForm();
        }
    }, [horse]);

    const handleSelectedDocument = (file: any) => {
        setCogginsDocument(file);
    }

    const handleUploadDocument = async (file: any) => {
        // Upload this to S3 
        const fileName = createCogginsDocumentFileName(horse);
        const uploadResult = await handleUploadS3File(fileName, file);
        if (uploadResult.isSuccess) {
            // If successfully added to S3, save to video list to the person
            const key = uploadResult.result;
            const title = "Coggins for " + horse.name;
            const description = "Coggins uploaded for " + horse.name + " by " + user.firstName + " " + user.lastName + " on " + moment(new Date()).format("MMM DD, YYYY h:mm a"); 
            const s3Object = createS3Object(key, title, file, description);
            return s3Object;
        } else {
            setError("Could not upload the document. Contact hello@ringsidepro.com for help.")
        }
    }

    const handleUpdateHorse = async () => {
        setIsLoading(true);
        let input: UpdateHorseInput = {
            id: horse.id
        };
        if (cogginsDate) input.cogginsDate = cogginsDate;
        if (cogginsNumber) input.cogginsNumber = cogginsNumber;
        if (cogginsState) input.cogginsState = cogginsState;
        if (cogginsDocument) {
            const s3Object = await handleUploadDocument(cogginsDocument);
            input.coggins = s3Object;
        }
        const updateResult = await updateHorse(input);
        if (updateResult.isSuccess) {
            setError("");
            setSuccess("Successfully saved the updates.");
            if (onSubmit) onSubmit();
        } else {
            setSuccess("");
            setError(updateResult.message);
        }
        setIsLoading(false);
    }

    const handleEditHorse = async () => {
        await handleUpdateHorse();
    }

    return (
        <>
            {success && <SuccessBanner success={success} />}
            {error && <ErrorAlert error={error} />}
            <IonRow className="ion-justify-content-center">
                <IonCol size="12">
                    <p>Fill out the number, date, state and upload the horse's coggins.</p>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Coggins Accession Number</IonLabel>
                        <IonInput 
                            type="text"
                            value={cogginsNumber}
                            aria-required={true}
                            onIonChange={e => {
                                setCogginsNumber(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">Coggins Test Date</IonLabel>
                        <IonInput 
                            type="date"
                            value={cogginsDate}
                            aria-required={true}
                            onIonChange={e => {
                                setCogginsDate(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <SelectState label="State of Test" selectedValue={cogginsState} onInputChange={(state: string) => setCogginsState(state)} />
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol size="12">
                    <CogginsDocumentForm horse={horse} onSubmit={handleSelectedDocument} />
                </IonCol>
            </IonRow>
            {isLoading ?
                <Spinner />
                :
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeMd="4" className="ion-text-center">
                        <IonButton
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleEditHorse}
                        >
                            Save
                        </IonButton>
                    </IonCol>
                </IonRow>
            }
        </>
    );
};

export default EditHorseCogginsForm;