import { API } from "aws-amplify";
import { Result } from "../../interfaces/Result";

export async function sendNewCreatedHorseShowEmail(eventName: string, eventEmail: string) {
    try {
        const queryData = {
            body: {
                "eventName": eventName,
                "showEmail": eventEmail
            }
        }

        const path = "/emails/event/created";
        const emailResult = API.post("Emails", path, queryData);
        
        let result: Result = {isSuccess: true, type: "Email", result: emailResult, message: "Sent the email." }
        return result;

    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Email", result: error, message: "Error occurred while trying to create a new email." }
        return result;
    }
}
