import React, { useContext, useEffect, useState } from "react";
import { OrganizationMembershipType, Membership, Organization } from "../../models";
import { IonButton, IonCard, IonCol, IonRow } from "@ionic/react";
import CONSTANT from "../../constant/constant";
import { PersonContext } from "../../context/PersonContext";
import { generateAELIndividualMembershipApplicationReport, generateAELTeamMembershipApplicationReport, membershipApplicationReports } from "../../utilities/reports/MembershipApplicationReports";
import { getPersonalInformationByPersonId } from "../../utilities/personalInformation/PersonalInformation";
import Spinner from "../Spinners/Spinner";

interface _Props {
    memberships: Membership[] | undefined;
    membershipType: OrganizationMembershipType;
    organization: Organization | undefined;
}

const MembershipApplicationConfirmation: React.FC<_Props> = ({ memberships, membershipType, organization }) => {  
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [statusMsg, setStatusMsg] = useState("Not Started");
    const [color, setColor] = useState("danger");
    const [line1, setLine1] = useState("Please go to the Basic Info tab to start the application.");
    const [line2, setLine2] = useState("If you need assistance, contact an organization admin.");

    const [isAELOrganization, setIsAELOrganization] = useState(false);

    useEffect(() => {
        if (organization?.id === "e05fc919-2793-4ead-acef-3f6efb584b67") {
            setIsAELOrganization(true);
            setLine2("If you need assistance, contact an AEL admin at athleticequestrian@gmail.com.")
        }
    }, [organization]);
    
    const downloadPDF = async () => {
        setIsLoading(true);
        const isDownload = true;
        if (memberships && memberships.length > 0 && memberships[0]) {
            if (isAELOrganization) {
                const currentMembership = memberships[0];
                if (currentMembership.type?.toLowerCase().includes('team')) await generateAELTeamMembershipApplicationReport(currentMembership, membershipType, isDownload);
                else generateAELIndividualMembershipApplicationReport(currentMembership, membershipType, isDownload);
            } else {
                const queryResult = await getPersonalInformationByPersonId(user.id);
                const personInfo = queryResult.result;
                await membershipApplicationReports(memberships, membershipType, personInfo, organization, isDownload);
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        function getStatusMsg(memberships: Membership[]) {
            setIsLoading(true);
            if (memberships.length > 0) {
                const membership: Membership = memberships[0];
                if (membership.membershipStatus === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE) {
                    setStatusMsg("Complete");
                    setColor("success");
                    setLine1("You've submitted the application.");
                    if (isAELOrganization) setLine2("Your application can no longer be edited. If you need to make an adjustment, navigate to your Team Roster.");
                    else setLine2("Your application can no longer be edited. If you need to make an adjustment, contact an organization admin.");
                } else if (membership.membershipStatus === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS) {
                    setStatusMsg("In Progress");
                    setColor("warning");
                    setLine1("Please use the tabs above to continue working on your application.");
                }
            }
            setIsLoading(false);
        }
        
        if (memberships) getStatusMsg(memberships);
    }, [memberships]);
 
    return (
        <IonCard color="white" className="ion-padding">
            <h3>{membershipType.name}</h3>
            {isLoading ?
                <Spinner/>
            :
                <IonRow className="ion-align-items-center">
                    <IonCol className="ion-text-center">
                        <h3>Status: <span className={"text-" + color}>{statusMsg}</span></h3>
                        <p>{line1}</p>
                        <p>{line2}</p>
                        <IonButton
                            onClick={downloadPDF}
                            className="mt-4"
                            color="tertiary"
                        >
                            Download PDF
                        </IonButton>
                        <IonButton
                            routerLink={"/index" + (organization?.urlBackHalf ? ("/" + organization?.urlBackHalf) : "") + "/home"}
                            id="mt-back-to-dashboard-btn"
                            className="mt-4"
                            color="tertiary"
                        >
                            Back to Dashboard
                        </IonButton>
                    </IonCol>
                </IonRow>
            }
        </IonCard>
    );
};

export default MembershipApplicationConfirmation;
