import { FormattedStablingRequest } from "../../interfaces/Stabling";
import { EventEntry, StablingRequest } from "../../models";
import { getEventEntryById } from "../eventEntry/EventEntry";
import { getEventStallTypeById } from "../eventStallType/EventStallType";

export const formatStablingRequests = async (stablingRequests: StablingRequest[]) => {
    let formattedArray: FormattedStablingRequest[] = [];
    if (stablingRequests && stablingRequests.length) {
        for (let i = 0; i < stablingRequests.length; i++) {
            const stablingRequest = stablingRequests[i];
            
            let formattedStablingRequest: FormattedStablingRequest = {
                quantity: stablingRequest.quantityNeeded?.toString(),
                totalPrice: stablingRequest.totalPrice ? stablingRequest.totalPrice.toFixed(2) : "0",
                splitCost: stablingRequest.totalPrice ? (stablingRequest.totalPrice / stablingRequests.length).toFixed(2) : "0"
            };

            if (stablingRequest.stallTypeId) {
                const queryStallTypeResult = await getEventStallTypeById(stablingRequest.stallTypeId);
                if (queryStallTypeResult.isSuccess) {
                    const stallType = queryStallTypeResult.result;
                    if (stallType) {
                        formattedStablingRequest.type = stallType.name;
                        formattedStablingRequest.price = parseFloat(stallType.pricePerStall || "0").toFixed(2);
                        formattedStablingRequest.tax = stallType.taxPerStall;
                    }
                }
            }
            
            const entryIdArray = stablingRequest.entryIds;
            let entryArray: EventEntry[] = [];
            if (entryIdArray && entryIdArray.length) {
                for (let j = 0; j < entryIdArray.length; j++) {
                    const currentEntryId = entryIdArray[j];
                    if (currentEntryId) {
                        const queryEntryResult = await getEventEntryById(currentEntryId);
                        if (queryEntryResult.isSuccess) {
                            const entry: EventEntry = queryEntryResult.result;
                            entryArray.push(entry);
                            formattedStablingRequest.horseName = entry.horseName || "";
                            formattedStablingRequest.trainerName = entry.trainerName || "";
                        }
                    }
                }
            };
            formattedStablingRequest.entries = entryArray;

            if (entryArray.length > 1) {
                const quantity = (stablingRequest.quantityNeeded || 1) / entryArray.length;
                formattedStablingRequest.quantity = quantity.toFixed(2);

                const splitCost = (stablingRequest.totalPrice || 0) * quantity;
                formattedStablingRequest.splitCost = splitCost.toFixed(2);
            }
            
            formattedArray.push(formattedStablingRequest);
        }
    }
    return formattedArray;
}
