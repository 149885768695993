import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonModal,
    IonTitle,
    IonButtons,
    IonContent,
    IonIcon,
    IonToolbar
} from "@ionic/react";
import CONSTANT from '../../constant/constant';
import { SketchPicker } from 'react-color';
import React, { useEffect, useState } from "react";
import { Organization } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import SuccessBanner from "../Banners/SuccessBanner";
import { isImage } from "../../utilities/files/File";
import { add, close } from "ionicons/icons";
import FileBrowser from "../FileBrowser/FileBrowser";
import { updateOrganization, getAllOrganizations } from "../../utilities/organization/Organization";
import { LogoInput, UpdateOrganizationInput, S3ObjectInput } from "../../API";
import { createOrgLogoFileName, createS3Object, handleDeleteS3Item, handleUploadS3Image, getS3ImageFileUrl } from "../../utilities/s3Object/s3Object";
import Spinner from "../Spinners/Spinner";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    organization: Organization
}

const EventOrganizationBranding: React.FC<_Props> = ({organization}) => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [customUrl, setCustomUrl] = useState<string>(organization.urlBackHalf || '');
    const [color, setColor] = useState('#fff');
    const [disableAddColorBtn, setDisableAddColorBtn] = useState(false);
    const [colors, setColors] = useState<(string | null)[] | null>(organization.colors ?? []);
    const [orgLogos, setOrgLogos] = useState<(LogoInput | null)[] | null[]>(organization.logos ?? []);
    const [newLogos, setNewLogos] = useState<(LogoInput | null)[] | null[]>([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
  
    useEffect(() => {
        if(colors?.length === CONSTANT.ORGANIZATION.BRANDING_MAX_COLORS) {
            setDisableAddColorBtn(true);
        } else {
            setDisableAddColorBtn(false);
        }
    }, [colors]);

    const setOrganizationData = (organization: Organization) => {
        if (organization) {
            setCustomUrl(organization.urlBackHalf || '');
            setColors(organization.colors ?? []);
            setOrgLogos(organization.logos ?? []);
        }
    }

    const verifyIsValid = () => {
        if (!customUrl) {
            setError("You must include a unique Custom URL.");
            return false;
        }
        return true;
    }

    const saveColorHandler = () => {
        setShowModal(false);
        setIsDisabled(false);
        if(colors?.includes(color)) {
            setError('Sorry, you need to select two different colors.');
            return;
        }
        setColors([...colors ?? [], color]);
    }

    const removeColorHandler = (event: any) => {
        setIsDisabled(false);
        const selectedColor = event.target.id;
        const colorNewArray = colors?.filter(c => {
            return c !== selectedColor;
        });
        setColors([...colorNewArray ?? []]);
    }

    const addColorHandler = () => {
        setError('');
        setShowModal(true);
    }

    const fileSelectionHandler = (file: File) => {
        setError('');
        setIsDisabled(false);
        setIsLoading(true);
        try {
            // Allow images only
            if (isImage(file)) {
                const imageDetails = {
                    logoURL: URL.createObjectURL(file),
                    logo: file as unknown as S3ObjectInput
                };
                setNewLogos([...newLogos ?? [], imageDetails]);
            } else {
                setError('Please check that your image is one of: .jpg, .jpeg, .png, .svg');
            }
        } catch (error: any) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const removeFileHandler = (event: any) => {
        try {
            const selectedImage = event.target.id;
            const updatedLogos = newLogos?.filter(logo => {
                return logo?.logoURL !== selectedImage;
            });
            setNewLogos(updatedLogos);
        } catch (error: any) {
            console.error(error);
        }
    }

    const deleteLogoHandler = async (event: any) => {
        setIsLoading(true);
        try {
            const deleteResult = await handleDeleteS3Item(event.target.id);
            if(deleteResult.isSuccess) {
                const organizationInput: UpdateOrganizationInput = {
                    id: organization.id,
                    logos: null
                };
                const updateOrgLogoResult = await updateOrganization(organizationInput);
                if (updateOrgLogoResult.isSuccess) {
                    setOrganizationData(updateOrgLogoResult.result);
                    setSuccess('File deleted and organization updated successfully.');
                } else {
                    setError(updateOrgLogoResult.message);
                }
            } else {
                setError(deleteResult.message);
            }
        } catch (error: any) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        const isValid = verifyIsValid();
        if (!isValid) {
            return;
        }
        setIsLoading(true);
        setError('');
        setSuccess('');
        try {
            const isUrlUnique = await isCustomUrlUnique();
            if(!isUrlUnique) {
                return;
            }
            for (const l of newLogos ?? []) {
                const uploadResult = await handleUploadS3Image(createOrgLogoFileName(organization), l?.logo);
                if (uploadResult.isSuccess) {
                    const key = uploadResult.result;
                    // Create a unique S3 Object 
                    const s3Object = createS3Object(key, "Organization Logo", l?.logo);
                    l!.key = key;
                    l!.logo = s3Object;
                    const s3ImageUrlResult = await getS3ImageFileUrl(key);
                    if(s3ImageUrlResult.isSuccess) {
                        l!.logoURL = s3ImageUrlResult.result;
                    } else {
                        setError(s3ImageUrlResult.message);
                    }
                } else {
                    setError(uploadResult.message);
                }
            }
            const organizationInput: UpdateOrganizationInput = {
                id: organization.id,
                urlBackHalf: customUrl,
                colors: colors,
                logos: newLogos?.length > 0 ? newLogos : orgLogos
            };
            const updateOrgLogoResult = await updateOrganization(organizationInput);
            if (updateOrgLogoResult.isSuccess) {
                setOrganizationData(updateOrgLogoResult.result);
                setNewLogos([]);
                setSuccess('Successfully updated the organization.');
                setIsDisabled(true);
            } else {
                setError(updateOrgLogoResult.message);
            }
        } catch (error: any) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const isCustomUrlUnique = async () => {
        const allOrganizationsResult = await getAllOrganizations();
        if(allOrganizationsResult.result.length > 0) {
            // remove the current selected organization from list
            const otherOrganizations = allOrganizationsResult.result.filter((org: Organization) => org.id !== organization.id);
            // check if custom url is already present in any other organization
            const result = otherOrganizations.filter((org: Organization) => org.urlBackHalf === customUrl);
            if(result?.length === 1) {
                setError('Sorry, that URL has already been taken. Please try a different custom URL.');
                setIsLoading(false);
                return false;
            }
        }
        return true;
    }

    return (
        <form>
            {error && <ErrorAlert width="12" error={error} />}
            {success && <SuccessBanner width="12" success={success} />}
            {isLoading && <Spinner />}
            <IonRow>
                <IonCol>
                    <IonItem color="white" lines="none">
                        <IonLabel position="stacked">
                            Custom URL <RequiredInputIndicator />
                        </IonLabel>
                    </IonItem>
                    <IonItem color="white">
                        <IonLabel>www.ringsidepro.com/</IonLabel>
                        <IonInput 
                            id="custom-url"
                            type="text"
                            maxlength={CONSTANT.ORGANIZATION.BRANDING_CUSTOM_URL_LENGTH}
                            value={customUrl}
                            aria-required={true}
                            onIonChange={e => {
                                setCustomUrl(e.detail.value!)
                                setIsDisabled(false)
                                setError('')
                            }}
                        />
                    </IonItem>
                    <IonItem className="mt-3" color="white" lines="none">
                        <IonLabel position="fixed">Colors</IonLabel>
                        {colors?.map( e =>
                            <div className="org-colors-div" style={{backgroundColor: e ? e : ''}} key={e}>
                                <IonIcon
                                className="close-icon-button"
                                icon={close}
                                size="large"
                                onClick={removeColorHandler}
                                id={e ? e : ''}
                                />
                            </div>
                        )}
                        <IonButton
                            id="add-color"
                            color="dark"
                            className="p-0 mb-3 ion-padding-vertical"
                            fill="outline"
                            disabled={disableAddColorBtn}
                            onClick={addColorHandler}
                        >
                            <IonIcon icon={add} size="large" />
                        </IonButton>
                    </IonItem>
                    <IonItem className="mt-3" color="white" lines="none">
                        <IonLabel position="fixed">Logos</IonLabel>
                    </IonItem>

                    {orgLogos && orgLogos.length > 0 &&
                        <IonItem className="ml-7" color="white" lines="none">
                            <IonLabel>Existing Logo</IonLabel>
                        </IonItem>
                    }
                    {orgLogos?.map((logo, index) => (
                        <IonRow className="ml-7" key={index}>
                            <IonCol sizeXs="12" sizeMd="4">
                                <img src={logo?.logoURL ? logo.logoURL : ''} />
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" className="delete-logo-button">
                                <IonButton
                                    className="remove-logo"
                                    id={logo?.logo?.key}
                                    color="danger"
                                    onClick={deleteLogoHandler}
                                >
                                    Remove Logo
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    ))}
                    <IonItem className="ml-7" color="white" lines="none">
                        <IonLabel>New Logo</IonLabel>
                    </IonItem>
                    {newLogos && newLogos.length > 0 ? 
                        newLogos.map((logo, index) => (
                            <IonRow className="ml-7" key={index}>
                                <IonCol sizeXs="12" sizeMd="4">
                                    <img className="new-uploaded-logo" src={logo?.logoURL ? logo.logoURL : ''} />
                                    <IonIcon
                                        id={logo?.logoURL ? logo.logoURL : ''}
                                        className="close-icon-button"
                                        icon={close}
                                        size="large"
                                        onClick={removeFileHandler}
                                    />
                                </IonCol>
                            </IonRow>
                        ))
                    :
                        <IonRow className="ml-7">
                            <IonCol sizeXs="12" sizeMd="4">
                                <FileBrowser handleSelectedFile={fileSelectionHandler}/>
                            </IonCol>
                        </IonRow>
                    }
                    
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="2">
                            <IonButton
                                id="save-btn"
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            > 
                                Save
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    
                    <IonModal backdropDismiss={false} isOpen={showModal} id="brandingColorPickerModal">
                        <IonToolbar color="light">
                            <IonTitle className="ion-text-center">
                                Select Color
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    fill="clear"
                                    onClick={() => setShowModal(false)}
                                >
                                    <p id="closeColorPickerModalBtn" className="font-weight-normal text-medium text-capitalize">
                                        <IonIcon icon={close} />
                                    </p>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                        <IonContent className="ion-text-center">
                            <IonRow class="ion-justify-content-center">
                                <IonCol sizeXs="8" sizeMd="6">
                                    <SketchPicker
                                        width='250px'
                                        color={color}
                                        onChangeComplete={(color) => {setColor(color.hex);}}
                                    />
                                    <IonButton
                                        id="select-color"
                                        className="ion-margin-top"
                                        color="tertiary"
                                        expand="block"
                                        onClick={saveColorHandler}
                                    >
                                        Select Color
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonContent>
                    </IonModal>
                </IonCol>
            </IonRow>
        </form>
    );
};

export default EventOrganizationBranding;
