import {
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useState } from "react";
import { Event } from "../../../models";
import ErrorAlert from "../../Errors/ErrorAlert";

interface _Props {
    event?: Event
}

const CreditCalculator: React.FC<_Props> = ({event}) => {

    const [days, setDays] = useState<number>(1);
    const [rings, setRings] = useState<number>(1);
    const [subscribers, setSubscribers] = useState<number>(100);
    const [textsPerRing, setTextsPerRing] = useState<number>(15);
    const [error, setError] = useState("");

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            <IonRow>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating">Number of Subscribers</IonLabel>
                        <IonInput
                            type="number"
                            value={subscribers}
                            onIonChange={(e) => {
                                setSubscribers(parseInt(e.detail.value || "0"));
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating">Number of Days</IonLabel>
                        <IonInput
                            type="number"
                            value={days}
                            onIonChange={(e) => {
                                setDays(parseInt(e.detail.value || "0"));
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating">Number of Rings</IonLabel>
                        <IonInput
                            type="number"
                            value={rings}
                            onIonChange={(e) => {
                                setRings(parseInt(e.detail.value || "0"));
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem>
                        <IonLabel position="floating">Alerts Per Ring</IonLabel>
                        <IonInput
                            type="number"
                            value={textsPerRing}
                            onIonChange={(e) => {
                                setTextsPerRing(parseInt(e.detail.value || "0"));
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonText>Total Credits Needed: {(days*rings*subscribers*textsPerRing).toLocaleString()}</IonText>
                </IonCol>
            </IonRow>
        </>
    );
};

export default CreditCalculator;