import { 
    IonText
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { getDisplayName } from "../../utilities/person/PersonName";

const ProfileName: React.FC = () => {
    const [displayName, setDisplayName] = useState("");

    const user = useContext(PersonContext);

    useEffect(() => {
        // Create an scoped async function in the hook
        async function setNameInState() {
            const nameResult = await getDisplayName(user.id);
            if (nameResult.isSuccess) {
                setDisplayName(nameResult.result);
            }
        }
        // Execute the created function directly
        setNameInState();
      }, [user]);

    return (
        <IonText className="ion-justify-content-center">
            <h3 className="text-center">{displayName}</h3>
        </IonText>
    );
};

export default ProfileName;
