import {
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Contact, Event } from "../../../models";
import { getContactById } from "../../../utilities/contact/Contact";
import { formatPhoneNumberForDisplay } from "../../../utilities/contact/FormatPhoneNumber";

interface _Props {
    event: Event
}

const EventDisplayContact: React.FC<_Props> = ({event}) => {
    const [eventContacts, setEventContacts] = useState<Contact[]>([]);

    async function getContact(contactId: string) {
        const queryResult = await getContactById(contactId);
        if (queryResult.isSuccess) {
            return queryResult.result;
        }
    }

    async function getEventContacts(contactIds: (string | null)[]) {
        const contactArray: Contact[] = [];
        for (let i = 0; i < contactIds.length; i++) {
            const contactId = contactIds[i];
            if (contactId) {
                const contact = await getContact(contactId);
                if (contact) contactArray.push(contact);
            }
        }
        setEventContacts(contactArray);
    }

    useEffect(() => {
        if (event.contactIds) getEventContacts(event.contactIds);
        else if (event.contactId) getEventContacts([event.contactId]);
    }, [event]);

    return (
        <>
            {(eventContacts && eventContacts.length > 0) ?
                <>
                    {eventContacts.map((eventContact, i) => (
                        <div key={i}>
                            <IonRow>
                                <IonCol>
                                    <h2 className="ion-text-wrap">{eventContact.name}</h2>
                                    <p className="ion-text-wrap text-darker">Phone Number: {eventContact.work && formatPhoneNumberForDisplay(eventContact.work)}</p>
                                    <p className="ion-text-wrap text-darker">Fax Number: {eventContact.fax && formatPhoneNumberForDisplay(eventContact.fax)}</p>
                                    <p className="ion-text-wrap text-darker">Email Address: {eventContact.workEmail}</p>
                                    <p className="ion-text-wrap text-darker">Facebook Page: {eventContact.facebookPage}</p>
                                    <p className="ion-text-wrap text-darker">Instagram: {eventContact.instagramHandle}</p>
                                    <p className="ion-text-wrap text-darker">Twitter: {eventContact.twitterHandle}</p>
                                </IonCol>
                            </IonRow>
                            <hr />
                        </div>
                    ))}
                </>
                :
                <>
                    <p>No Contact Info Found</p>
                </>
            }
        </>
    );
};

export default EventDisplayContact;