import moment from "moment";
import { Event } from "../../models";
import { getEventClassesByEventId } from "../eventClass/EventClass";
import { sortEventClassesByNumber } from "../eventClass/SortEventClass";
import { getEventClassEntriesCountByEventClassId } from "../eventClassEntry/EventClassEntry";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export async function generateClassCountsPDF(event: Event, isSortedByNumber?: boolean) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Class Counts Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY h:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = [
        {header: "Class No", dataKey: "number"}, 
        {header: "Class", dataKey: "class"}, 
        {header: "Entry Count", dataKey: "count"}
    ];
    // define an empty array of rows
    const tableRows = [];

    const eventClassResult = await getEventClassesByEventId(event.id);
    if (eventClassResult.isSuccess) {
        const eventClasses = eventClassResult.result;
        const sortedEventClasses = sortEventClassesByNumber(eventClasses);
        if (sortedEventClasses && sortedEventClasses.length > 0) {
            for (let i = 0; i < sortedEventClasses.length; i++) {
                const currentEventClass = sortedEventClasses[i];
                let count = 0;
                const countResult = await getEventClassEntriesCountByEventClassId(currentEventClass.id);
                if (countResult.isSuccess) {
                    count = countResult.result;
                }
                const currentData = [
                    currentEventClass.number || "",
                    currentEventClass.name || "",
                    count
                ];
                tableRows.push(currentData);
            }
        }
    }
    
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 30 
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    doc.save(`Class_Counts.pdf`);
}