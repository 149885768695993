import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { Barn, Horse } from "../../models";
import { getHorsesByPersonId, updateHorse } from "../../utilities/horse/Horse";
import ErrorAlert from "../Errors/ErrorAlert";
import { UpdateHorseInput } from "../../API";
import moment from "moment";
import Spinner from "../Spinners/Spinner";

interface _Props {
    selectedBarn?: (Barn | null)
    onChange: Function
}

interface FormattedHorse {
    id: string
    name: string
    currentBarn?: Barn
    currentBarnId?: string
    currentBarnName?: string
    createdOn: string
    isDisabled: boolean
    isSelected: boolean
}

const MoveHorseToBarnForm: React.FC<_Props> = ({selectedBarn, onChange}) => {
    const user = useContext(PersonContext);
    
    const [barn, setBarn] = useState<Barn | null | undefined>();
    const [formattedHorses, setFormattedHorses] = useState<FormattedHorse[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const getHorsesByPerson = async (personId: string, barnId: string) => {
        const queryResult = await getHorsesByPersonId(personId);
        if (queryResult.isSuccess) {
            const horses: Horse[] = queryResult.result;
            formatHorses(horses, barnId);
        }
    }

    useEffect(() => {
        if (selectedBarn && user) {
            getHorsesByPerson(user.id, selectedBarn.id);
        }
        setBarn(selectedBarn);
    }, [selectedBarn]);

    const formatHorses = (horses: Horse[], barnId: string) => {
        setIsLoading(true);
        let formattedArray: FormattedHorse[] = [];
        for (let i = 0; i < horses.length; i++) {
            const currentHorse = horses[i];
            const formattedItem: FormattedHorse = {
                id: currentHorse.id,
                name: currentHorse.name + (currentHorse.nickname ? " (" + currentHorse.nickname + ")" : ""),
                currentBarn: currentHorse.barn || undefined,
                currentBarnId: currentHorse.barnId || "",
                currentBarnName: currentHorse.barnName || "",
                createdOn: moment(currentHorse.createdOn).format("MM-DD-YYYY"),
                isDisabled: !!(currentHorse.barnId && barnId && currentHorse.barnId === barnId),
                isSelected: false
            };
            formattedArray.push(formattedItem);
        }
        setFormattedHorses(formattedArray);
        setIsLoading(false);
    }

    const handleSelectHorse = (selectedFormattedHorse: FormattedHorse, index: number) => {
        setError("");
        if (formattedHorses) {
            const updatedFormattedHorse: FormattedHorse = {
                ...selectedFormattedHorse,
                isSelected: !selectedFormattedHorse.isSelected
            };
            const updatedFormattedHorses: FormattedHorse[] = [
                ...formattedHorses.slice(0, index),
                updatedFormattedHorse,
                ...formattedHorses.slice(index + 1)
            ];
            setFormattedHorses(updatedFormattedHorses);
        } else {
            setError("No horses found.");
        }
    }

    const handleUpdateHorse = async (horse: FormattedHorse) => {
        if (horse) {
            const input: UpdateHorseInput = {
                id: horse.id,
                barnId: barn?.id,
                // horseBarnId: barn?.id,
                barnName: barn?.name
            };
            const updateResult = await updateHorse(input);
            if (!updateResult.isSuccess) {
                setError(updateResult.message);
            }
        } else {
            setError("No horse to edit.");
        }
    }

    const handleEditHorses = async () => {
        setError("");
        setIsLoading(true);
        if (formattedHorses) {
            for (let i = 0; i < formattedHorses.length; i++) {
                const current: FormattedHorse = formattedHorses[i];
                if (current.isSelected) {
                    await handleUpdateHorse(current);
                }
            }
            onChange();
        } else {
            setError("No horses found.");
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert error={error} />}
            <IonRow>
                <IonCol>
                    <p className="description ion-text-wrap">Please note: a horse can only belong to one barn. If you add a horse to this barn, it may remove the horse from another barn.</p>
                </IonCol>
            </IonRow>
            {isLoading ?
                <Spinner />
                :
                <IonRow>
                    <IonCol>
                        {(formattedHorses && formattedHorses.length > 0) ?
                            <IonList className="bg-white">
                                {formattedHorses.map((formattedHorse, index) => (
                                    <IonItem color="white" key={index}>
                                        <IonCheckbox slot="start" color="tertiary" checked={formattedHorse.isSelected} disabled={formattedHorse.isDisabled} onClick={() => handleSelectHorse(formattedHorse, index)}/>
                                        <IonLabel>
                                            <IonRow>
                                                <IonCol sizeXs="12" sizeMd="3">
                                                    <p className="ion-text-wrap description">{formattedHorse.name}</p>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="3">
                                                    <p className="ion-text-wrap description">{(formattedHorse.currentBarnName && formattedHorse.currentBarnName !== "") ? formattedHorse.currentBarnName : "No barn assigned"}</p>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="3">
                                                    <p className="ion-text-wrap description">Created: {formattedHorse.createdOn}</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                    </IonItem>
                                ))}
                            </IonList>
                            :
                            <p>No horses found.</p>
                        }
                    </IonCol>
                </IonRow>
            }
            <IonRow>
                <IonCol sizeXs="12" sizeMd="4" offsetMd="4" className="ion-text-center">
                    <IonButton
                        id="horseSubmitBtn"
                        className="ion-margin-top"
                        color="success"
                        expand="block"
                        onClick={() => handleEditHorses()}
                    >
                        Save Updates
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default MoveHorseToBarnForm;