import {
    IonButton,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRadio,
    IonRow,
    IonSearchbar,
    isPlatform,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, EventClass, EventResult } from "../../models";
import { getEventClassesByEventIdWithResults } from "../../utilities/eventClass/EventClass";
import { getEventClassTypeAbbreviation } from "../../utilities/eventClass/EventClassTypes";
import { Table } from "reactstrap";
import { isWindows } from "../../utilities/platform/Platform";
import Spinner from "../Spinners/Spinner";

interface formattedEventClass {
    number: number,
    name: string,
    divisionName: string,
    entryCount?: number, 
    hasResults: boolean,
    prizeMoney: string,
    ec: EventClass
}

interface _Props {
    event: Event
    onSelect: Function
}

const EventClassResultsTable: React.FC<_Props> = ({event, onSelect}) => {

    const [searchText, setSearchText] = useState("");
    const [eventClasses, setEventClasses] = useState<EventClass[] | null | undefined>();
    const [formattedEventClasses, setFormattedEventClasses] = useState<formattedEventClass[] | null | undefined>();
    const [filteredEventClasses, setFilteredEventClasses] = useState<formattedEventClass[] | null | undefined>();
    const [selectedClass, setSelectedClass] = useState<EventClass | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const formatEventClasses = async (eventClasses: EventClass[]) => {
        setIsLoading(true);
        let formattedEventClasses: formattedEventClass[] = [];
        if (eventClasses) {
            for (var i = 0; i < eventClasses.length; i++) {
                const currentClass = eventClasses[i];
                const resultsObject: any = currentClass.eventResults;
                const resultsArray: EventResult[] | null = resultsObject.items;
                let hasResults = false;
                if (resultsArray && resultsArray.length > 0) hasResults = true;
                const formattedClass: formattedEventClass = {
                    number: currentClass.number || 0,
                    name: currentClass.name,
                    divisionName: currentClass.eventDivision?.name || "",
                    hasResults: hasResults,
                    entryCount: currentClass.currentNumberEntries ? currentClass.currentNumberEntries : undefined,
                    prizeMoney: currentClass.prizeMoney || "",
                    ec: currentClass
                }
                formattedEventClasses.push(formattedClass);
            }
        }
        setFormattedEventClasses(formattedEventClasses);
        setFilteredEventClasses(formattedEventClasses);
        setIsLoading(false);
    }

    async function getEventClasses(event: Event) {
        const queryResult = await getEventClassesByEventIdWithResults(event.id);
        if (queryResult.isSuccess) {
            setEventClasses(queryResult.result);
            formatEventClasses(queryResult.result);
        }
    }

    useEffect(() => {
        if (event) getEventClasses(event);
    }, [event]);

    const onSelectClass = (c: EventClass) => {
        onSelect(c);
    }

    const handleSearchInput = (input: string) => {
        // If search input is empty, reset to all 
        if (!input || input === "") {
            if (eventClasses) formatEventClasses(eventClasses);
        }

        let result = formattedEventClasses?.filter(formatted => formatted.name.toLowerCase().includes(input.toLowerCase()));
        if (result) {
            setFilteredEventClasses(result);
        }
    }

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>
                <IonRow>
                    <IonCol size="4">
                        Classes
                    </IonCol>
                </IonRow>
            </IonCardTitle>
            {selectedClass && (
                <IonCardSubtitle>
                    <IonButton size="small" onClick={() => {setSelectedClass(undefined); onSelect();}}>
                        Clear Selection
                    </IonButton>
                </IonCardSubtitle>
            )}
            <IonRow>
                <IonCol size="12">
                    <IonSearchbar
                        color="white"  
                        placeholder="Search by class name" 
                        value={searchText} 
                        onIonChange={e => {
                            setSearchText(e.detail.value!)
                            handleSearchInput(e.detail.value!)
                        }}
                    />
                </IonCol>
            </IonRow>
            {isLoading ?
                <Spinner />
                :
                <>
                    {(!isWindows() && isPlatform("mobile")) ?
                        <IonList className="bg-white">
                            {(filteredEventClasses && filteredEventClasses.length > 0) && (
                                <>
                                    {filteredEventClasses.map((formattedEventClass, index) => (
                                        <IonItem key={index} onClick={() => onSelectClass(formattedEventClass.ec)}>
                                            <IonLabel>
                                                <IonRow>
                                                    <IonCol>
                                                        <p className="ion-text-wrap"><p className="ion-text-wrap">{formattedEventClass.number} - {formattedEventClass.name} {formattedEventClass.ec.type ? ("- " + getEventClassTypeAbbreviation(formattedEventClass.ec.type)) : ""}</p></p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <p>Results: <span color={formattedEventClass.hasResults ? "success" : "secondary"}>{formattedEventClass.hasResults ? "yes" : "no"}</span></p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonLabel>
                                            <IonRadio slot="start" color="tertiary" value={formattedEventClass.ec.id} />
                                        </IonItem>
                                    ))}
                                </>
                            )}
                        </IonList>
                        :
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Number</th>
                                    <th>Class</th>
                                    <th>Class Results</th>
                                    <th>Prize Money</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(filteredEventClasses && filteredEventClasses.length > 0) && (
                                    <>
                                        {filteredEventClasses.map((formattedEventClass, index) => (
                                            <tr key={index} onClick={() => onSelectClass(formattedEventClass.ec)}>
                                                <td>
                                                    {formattedEventClass.number}
                                                </td>
                                                <td>
                                                    {formattedEventClass.name}
                                                </td>
                                                <td>
                                                    <span className={formattedEventClass.hasResults ? "text-success font-weight-bold" : ""}>{formattedEventClass.hasResults ? "Yes" : "No"}</span>
                                                </td>
                                                <td>
                                                    {(formattedEventClass.prizeMoney && formattedEventClass.prizeMoney !== "0") ? ("$" + formattedEventClass.prizeMoney) : ""}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </Table>
                    }
                </>
            }
        </IonCard>
    );
};

export default EventClassResultsTable;