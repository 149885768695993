import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToggle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event, EventClass, EventDivision} from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import { RouteComponentProps, useHistory } from "react-router";

import {getEventById} from "../../../../../utilities/events/Event";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import { close } from "ionicons/icons";
import EventClassResultsTable from "../../../../../components/EventClass/EventClassResultsTable";
import EventClassResultsForm from "../../../../../components/EventClass/EventClassResultsForm";
import DivisionClassNavbar from "../../../../../components/Navbars/DivisionClassNavbar";
import EventDivisionResultsTable from "../../../../../components/EventDivision/EventDivisionResultsTable";
import EventDivisionResultsForm from "../../../../../components/EventDivision/EventDivisionResultsForm";
import { isWindows } from "../../../../../utilities/platform/Platform";
import { isPlatform } from "@ionic/core";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventResultsPage: React.FC<EventPageProps> = ({match}) => {
    const history = useHistory();

    const [tab, setTab] = useState("classes");
    const [event, setEvent] = useState<Event>();
    const [selectedEventClass, setSelectedEventClass] = useState<EventClass | null | undefined>();
    const [selectedEventDivision, setSelectedEventDivision] = useState<EventDivision | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [showDivisionModal, setShowDivisionModal] = useState(false);
    const [isNewResultPage, setIsNewResultPage] = useState((isWindows() || isPlatform("desktop")) ? true : false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getEvent();
    }, [match, match.params.id]);

    const onSelectTab = (selectedTab: string) => {
        setTab(selectedTab);
    }

    const onSelectClass = (c: EventClass) => {
        setSelectedEventClass(c);
        if (!isNewResultPage) setShowModal(true);
        else {
            if (event && c) {
                const path = `/index/staff/event/${event.id}/results/class/${c.id}`;
                history.push(path);
            }
        }
    }

    const onSelectDivision = (d: EventDivision) => {
        setSelectedEventDivision(d);
        if (!isNewResultPage) setShowDivisionModal(true);
        else {
            if (event && d) {
                const path = `/index/staff/event/${event.id}/results/division/${d.id}`;
                history.push(path);
            }
        }
    }

    return (
        <IonPage className="bg-light">
            <Header isEventPage={true} event={event} />
            <IonContent>
                <PageTitle title={event ? event.name : "results"} />
                <IonRow className="ion-justify-content-center">
                    {error && <ErrorAlert width="12" error={error} />}
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>
                                <IonRow>
                                    <IonCol>
                                        Results
                                    </IonCol>
                                </IonRow>
                            </IonCardTitle>
                            <IonCardSubtitle>
                                This is where you will add any classes results as well as division championship results.
                            </IonCardSubtitle>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardSubtitle>
                                <IonRow>
                                    <IonCol>
                                        {isNewResultPage ? "Click to see pop up results window. Leave off to use new results page." : "Click to use new results page. Leave off to see pop up window."}
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <IonToggle color="success" checked={isNewResultPage} onIonChange={e => setIsNewResultPage(e.detail.checked)}/>
                                    </IonCol>
                                </IonRow>
                            </IonCardSubtitle>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <DivisionClassNavbar active={tab} onSelect={onSelectTab}/>
                {tab === "classes" ?
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            {event && (
                                <EventClassResultsTable onSelect={(c: EventClass) => onSelectClass(c)} event={event} />
                            )}
                        </IonCol>
                    </IonRow>
                    :
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            {event && (
                                <EventDivisionResultsTable onSelect={(d: EventDivision) => onSelectDivision(d)} event={event} />
                            )}
                        </IonCol>
                    </IonRow>
                }
                <IonModal backdropDismiss={false} isOpen={showModal} id="eventClassModal">
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                            Class Results
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowModal(false)}
                            >
                                <p id="closeEventClassModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    <IonIcon icon={close} />
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonContent className="ion-padding">
                        {event && selectedEventClass && (
                            <EventClassResultsForm event={event} eventClass={selectedEventClass} canEdit={true} onSubmit={() => setShowModal(false)} />
                        )}
                    </IonContent>
                </IonModal>
                <IonModal backdropDismiss={false} isOpen={showDivisionModal} id="eventDivisionModal">
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                            Division Results
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowDivisionModal(false)}
                            >
                                <p id="closeEventClassModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    <IonIcon icon={close} />
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonContent className="ion-padding">
                        {event && selectedEventDivision && (
                            <EventDivisionResultsForm event={event} eventDivision={selectedEventDivision} canEdit={true} onSubmit={() => setShowDivisionModal(false)} />
                        )}
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default EventResultsPage;