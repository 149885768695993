import { EventClassEntry } from "../../models";

/**
 * Sorts the EventClassEntries first by trainer name
 * 
 * @param data 
 * @returns EventClassEntries sorted by trainer
 */
export function sortEventClassEntriesByTrainer(data: EventClassEntry[]) {
    try {
        let result: EventClassEntry[] = [];
        if (data && data.length) {
            result = data.sort((a: EventClassEntry, b: EventClassEntry) => (a.eventEntry?.trainerName || "").localeCompare((b.eventEntry?.trainerName || "")));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: EventClassEntry");
        console.error(error);
        return null;
    }
};

/**
 * Sorts the EventClassEntries by horse name
 * 
 * @param data 
 * @returns EventClassEntries sorted by horse
 */
 export function sortEventClassEntriesByHorse(data: EventClassEntry[]) {
    try {
        let result: EventClassEntry[] = [];
        if (data && data.length) {
            result = data.sort((a: EventClassEntry, b: EventClassEntry) => (a.eventEntry?.horseName || "").localeCompare((b.eventEntry?.horseName || "")));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: EventClassEntry");
        console.error(error);
        return null;
    }
};

/**
 * Sorts the EventClassEntries by entry number
 * 
 * @param data 
 * @returns EventClassEntries sorted by entry number
 */
 export function sortEventClassEntriesByNumber(data: EventClassEntry[]) {
    try {
        let result: EventClassEntry[] = [];
        if (data && data.length) {
            result = data.sort((a: EventClassEntry, b: EventClassEntry) => (a.eventEntry?.number || 0) - (b.eventEntry?.number || 0));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: EventClassEntry");
        console.error(error);
        return null;
    }
};