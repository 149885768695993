import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React from "react";
import PeopleListEditor from "../../../../components/Admin/PeopleListEditor";

const AdminPeoplePage: React.FC = () => {
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="RSP Users" />
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>All Users</IonCardTitle>
                            <IonCardContent>
                                <PeopleListEditor />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default AdminPeoplePage;