import React, { useEffect, useState } from "react";
import { EventFee } from "../../models";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    eventFees: EventFee[]
    menuPlacement?: string
    selectedValue?: string
    onSelect: Function
}

const SelectEventFee: React.FC<_Props> = ({eventFees, selectedValue, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedEventFees, setFormattedEventFees] = useState<formattedOption[] | null | undefined>();

    const formatEventFees = (eventFees: EventFee[]) => {
        let formattedEventFees = [];
        for (var i = 0; i < eventFees.length; i++) {
            const currentFee = eventFees[i];
            const label = currentFee.name || "error";
            let object = {
                value: currentFee.id,
                label: label,
                object: currentFee
            };
            formattedEventFees.push(object);
        }
        const sorted = formattedEventFees.sort((a, b) => a.label.localeCompare(b.label));
        setFormattedEventFees(sorted);
    }

    useEffect(() => {
        if (eventFees) formatEventFees(eventFees);
    }, [eventFees]);

    useEffect(() => {
        setSelected(selectedValue);
    }, [selectedValue]);

    const handleOnChange = (event?: any) => {
        if (event) {
            onSelect(event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedEventFees ?
                <BasicSelect formattedValues={formattedEventFees} selectedValue={selected} menuPlacement={menuPlacement} onSelect={handleOnChange}/>
                :
                <p>Loading options...</p>
            }
        </>
    );
};

export default SelectEventFee;
