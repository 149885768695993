import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useState } from "react";
import { CreateEventJudgeInput } from "../../API";
import { Event, EventJudge } from "../../models";
import { createEventJudge } from "../../utilities/eventJudge/EventJudge";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    event: Event
    eventJudge?: EventJudge
    onSubmit: Function
}

const EventJudgeForm: React.FC<_Props> = ({eventJudge, event, onSubmit}) => {
    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [name, setName] = useState(eventJudge?.name || "");
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState<string>("");

    const verifyForm = () => {
        if (!name) {
            setError("Error: include a name.");
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) {
            const input: CreateEventJudgeInput = {
                eventId: event.id,
                name: name
            }
            const createResult = await createEventJudge(input);
            if (createResult.isSuccess) {
                onSubmit(createResult.result);
            } else {
                setError("Error: could not create the judge.");
            }
            setIsDisabled(true);
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name</IonLabel>
                            <IonInput 
                                type="text"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default EventJudgeForm;