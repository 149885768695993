export const listOrganizationAuditorsWithFilters = /* GraphQL */ `
  query ListOrganizationAuditors(
    $filter: ModelOrganizationAuditorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationAuditors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          pictureURL
          disciplines
          stripeId
          createdOn
          updatedOn
        }
        howSignedUp
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
            items {
                id
                personId
                eventId
                eventAuditorId
                filterId
                filter {
                  id
                  name
                }
                options
                createdBy
                lastUpdatedBy
                createdOn
                updatedOn
            }
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;