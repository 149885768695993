import {
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonToggle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { PrizeMoneyTiers } from "../../models";
import { Table } from "reactstrap";

interface _Props {
    tiers?: (PrizeMoneyTiers | null)
    isEditable?: (Boolean | null)
    onSubmit?: (Function | null)
}

const PrizeMoneyPercentageTable: React.FC<_Props> = ({tiers, isEditable, onSubmit}) => {

    const [exampleAmount, setExampleAmount] = useState(100);
    const [viewThroughTwelve, setViewThroughTwelve] = useState(false);
    const [viewChampion, setViewChampion] = useState(false);
    const [first, setFirst] = useState((tiers && tiers.first) ? parseFloat(tiers.first) : 30);
    const [second, setSecond] = useState((tiers && tiers.second) ? parseFloat(tiers.second) : 22);
    const [third, setThird] = useState((tiers && tiers.third) ? parseFloat(tiers.third) : 15);
    const [fourth, setFourth] = useState((tiers && tiers.fourth) ? parseFloat(tiers.fourth) : 12);
    const [fifth, setFifth] = useState((tiers && tiers.fifth) ? parseFloat(tiers.fifth) : 11);
    const [sixth, setSixth] = useState((tiers && tiers.sixth) ? parseFloat(tiers.sixth) : 10);
    const [seventh, setSeventh] = useState((tiers && tiers.seventh) ? parseFloat(tiers.seventh) : 0);
    const [eighth, setEighth] = useState((tiers && tiers.eighth) ? parseFloat(tiers.eighth) : 0);
    const [ninth, setNinth] = useState((tiers && tiers.ninth) ? parseFloat(tiers.ninth) : 0);
    const [tenth, setTenth] = useState((tiers && tiers.tenth) ? parseFloat(tiers.tenth) : 0);
    const [eleventh, setEleventh] = useState((tiers && tiers.eleventh) ? parseFloat(tiers.eleventh) : 0);
    const [twelfth, setTwelfth] = useState((tiers && tiers.twelfth) ? parseFloat(tiers.twelfth) : 0);
    const [champion, setChampion] = useState((tiers && tiers.champion) ? parseFloat(tiers.champion) : 0);
    const [reserve, setReserve] = useState((tiers && tiers.reserve) ? parseFloat(tiers.reserve) : 0);

    useEffect(() => {
        if (tiers) {
            if ((tiers && tiers.first)) setFirst(parseFloat(tiers.first));
            if ((tiers && tiers.second)) setSecond(parseFloat(tiers.second));
            if ((tiers && tiers.third)) setThird(parseFloat(tiers.third));
            if ((tiers && tiers.fourth)) setFourth(parseFloat(tiers.fourth));
            if ((tiers && tiers.fifth)) setFifth(parseFloat(tiers.fifth));
            if ((tiers && tiers.sixth)) setSixth(parseFloat(tiers.sixth));
            if ((tiers && tiers.seventh)) setSeventh(parseFloat(tiers.seventh));
            if ((tiers && tiers.eighth)) setEighth(parseFloat(tiers.eighth));
            if ((tiers && tiers.ninth)) setNinth(parseFloat(tiers.ninth));
            if ((tiers && tiers.tenth)) setTenth(parseFloat(tiers.tenth));
            if ((tiers && tiers.eleventh)) setEleventh(parseFloat(tiers.eleventh));
            if ((tiers && tiers.twelfth)) setTwelfth(parseFloat(tiers.twelfth));
            if ((tiers && tiers.champion)) setChampion(parseFloat(tiers.champion));
            if ((tiers && tiers.reserve)) setReserve(parseFloat(tiers.reserve));
            if ((tiers && tiers.seventh)) setViewThroughTwelve(true);
        }
    }, [tiers]);

    const calulateSum = () => {
        const sum = first + second + third + fourth + fifth + sixth + seventh + eighth + ninth + tenth + eleventh + twelfth;
        return sum;
    }

    return (
        <>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel>Display 7-12</IonLabel>
                        <IonToggle color="tertiary" slot="end" checked={viewThroughTwelve} onIonChange={e => setViewThroughTwelve(e.detail.checked)} />
                    </IonItem>   
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Example Amount</IonLabel>
                        <IonInput 
                            type="number"
                            value={exampleAmount}
                            onIonChange={e => {;
                                setExampleAmount(parseFloat(e.detail.value! || "0"))
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            {/* Probably do not need Champion and Reserve in Prize Money Table */}
            {/* <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel>Display Champion and Reserve</IonLabel>
                        <IonToggle color="tertiary" slot="end" checked={viewChampion} onIonChange={e => setViewChampion(e.detail.checked)} />
                    </IonItem>   
                </IonCol>
            </IonRow> */}
            <IonRow>
                <IonCol>
                    <Table>
                        <thead>
                            <tr>
                                <th>Place</th>
                                <th>Percentage</th>
                                {isEditable && <th>Example</th>}
                            </tr>
                        </thead>
                        {isEditable ?
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <th>
                                        <IonInput
                                            type="number"
                                            value={first}
                                            min="0"
                                            onIonChange={e => {
                                                setFirst(parseFloat(e.detail.value! || "0"));
                                                if (onSubmit) onSubmit("first", parseFloat(e.detail.value! || "0"));
                                            }}
                                        />
                                    </th>
                                    <th>${(first / 100 * exampleAmount).toFixed(2)}</th>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <th>
                                        <IonInput
                                            type="number"
                                            value={second}
                                            min="0"
                                            onIonChange={e => {
                                                setSecond(parseFloat(e.detail.value! || "0"));
                                                if (onSubmit) onSubmit("second", parseFloat(e.detail.value! || "0"));
                                            }}
                                        />
                                    </th>
                                    <th>${(second / 100 * exampleAmount).toFixed(2)}</th>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <th>
                                        <IonInput
                                            type="number"
                                            value={third}
                                            min="0"
                                            onIonChange={e => {
                                                setThird(parseFloat(e.detail.value! || "0"));
                                                if (onSubmit) onSubmit("third", parseFloat(e.detail.value! || "0"));
                                            }}
                                        />
                                    </th>
                                    <th>${(third / 100 * exampleAmount).toFixed(2)}</th>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <th>
                                        <IonInput
                                            type="number"
                                            value={fourth}
                                            min="0"
                                            onIonChange={e => {
                                                setFourth(parseFloat(e.detail.value! || "0"));
                                                if (onSubmit) onSubmit("fourth", parseFloat(e.detail.value! || "0"));
                                            }}
                                        />
                                    </th>
                                    <th>${(fourth / 100 * exampleAmount).toFixed(2)}</th>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <th>
                                        <IonInput
                                            type="number"
                                            value={fifth}
                                            min="0"
                                            onIonChange={e => {
                                                setFifth(parseFloat(e.detail.value! || "0"));
                                                if (onSubmit) onSubmit("fifth", parseFloat(e.detail.value! || "0"));
                                            }}
                                        />
                                    </th>
                                    <th>${(fifth / 100 * exampleAmount).toFixed(2)}</th>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <th>
                                        <IonInput
                                            type="number"
                                            value={sixth}
                                            min="0"
                                            onIonChange={e => {
                                                setSixth(parseFloat(e.detail.value! || "0"));
                                                if (onSubmit) onSubmit("sixth", parseFloat(e.detail.value! || "0"));
                                            }}
                                        />
                                    </th>
                                    <th>${(sixth / 100 * exampleAmount).toFixed(2)}</th>
                                </tr>
                                {viewThroughTwelve && (
                                    <>
                                        <tr>
                                            <th scope="row">7</th>
                                            <th>
                                                <IonInput
                                                    type="number"
                                                    value={seventh}
                                                    min="0"
                                                    onIonChange={e => {
                                                        setSeventh(parseFloat(e.detail.value! || "0"));
                                                        if (onSubmit) onSubmit("seventh", parseFloat(e.detail.value! || "0"));
                                                    }}
                                                />
                                            </th>
                                            <th>${(seventh / 100 * exampleAmount).toFixed(2)}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">8</th>
                                            <th>
                                                <IonInput
                                                    type="number"
                                                    value={eighth}
                                                    min="0"
                                                    onIonChange={e => {
                                                        setEighth(parseFloat(e.detail.value! || "0"));
                                                        if (onSubmit) onSubmit("eighth", parseFloat(e.detail.value! || "0"));
                                                    }}
                                                />
                                            </th>
                                            <th>${(eighth / 100 * exampleAmount).toFixed(2)}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">9</th>
                                            <th>
                                                <IonInput
                                                    type="number"
                                                    value={ninth}
                                                    min="0"
                                                    onIonChange={e => {
                                                        setNinth(parseFloat(e.detail.value! || "0"));
                                                        if (onSubmit) onSubmit("ninth", parseFloat(e.detail.value! || "0"));
                                                    }}
                                                />
                                            </th>
                                            <th>${(ninth / 100 * exampleAmount).toFixed(2)}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">10</th>
                                            <th>
                                                <IonInput
                                                    type="number"
                                                    value={tenth}
                                                    min="0"
                                                    onIonChange={e => {
                                                        setTenth(parseFloat(e.detail.value! || "0"));
                                                        if (onSubmit) onSubmit("tenth", parseFloat(e.detail.value! || "0"));
                                                    }}
                                                />
                                            </th>
                                            <th>${(tenth / 100 * exampleAmount).toFixed(2)}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">11</th>
                                            <th>
                                                <IonInput
                                                    type="number"
                                                    value={eleventh}
                                                    min="0"
                                                    onIonChange={e => {
                                                        setEleventh(parseFloat(e.detail.value! || "0"));
                                                        if (onSubmit) onSubmit("eleventh", parseFloat(e.detail.value! || "0"));
                                                    }}
                                                />
                                            </th>
                                            <th>${(eleventh / 100 * exampleAmount).toFixed(2)}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">12</th>
                                            <th>
                                                <IonInput
                                                    type="number"
                                                    value={twelfth}
                                                    min="0"
                                                    onIonChange={e => {
                                                        setTwelfth(parseFloat(e.detail.value! || "0"));
                                                        if (onSubmit) onSubmit("twelfth", parseFloat(e.detail.value! || "0"));
                                                    }}
                                                />
                                            </th>
                                            <th>${(twelfth / 100 * exampleAmount).toFixed(2)}</th>
                                        </tr>
                                    </>
                                )}
                                {viewChampion && (
                                    <>
                                        <tr>
                                            <th scope="row">Champion</th>
                                            <th>
                                                <IonInput
                                                    type="number"
                                                    value={champion}
                                                    min="0"
                                                    onIonChange={e => {
                                                        setChampion(parseFloat(e.detail.value! || "0"))
                                                    }}
                                                />
                                            </th>
                                            <th>{champion / 100 * exampleAmount}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">Reserve</th>
                                            <th>
                                                <IonInput
                                                    type="number"
                                                    value={reserve}
                                                    min="0"
                                                    onIonChange={e => {
                                                        setReserve(parseFloat(e.detail.value! || "0"))
                                                    }}
                                                />
                                            </th>
                                            <th>{reserve / 100 * exampleAmount}</th>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <th>{first}</th>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <th>{second}</th>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <th>{third}</th>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <th>{fourth}</th>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <th>{fifth}</th>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <th>{sixth}</th>
                                </tr>
                                {viewThroughTwelve && (
                                    <>
                                        <tr>
                                            <th scope="row">7</th>
                                            <th>{seventh}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">8</th>
                                            <th>{eighth}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">9</th>
                                            <th>{ninth}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">10</th>
                                            <th>{tenth}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">11</th>
                                            <th>{eleventh}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">12</th>
                                            <th>{twelfth}</th>
                                        </tr>
                                    </>
                                )}
                                {viewChampion && (
                                    <>
                                        <tr>
                                            <th scope="row">Champion</th>
                                            <th>{champion}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">Reserve</th>
                                            <th>{reserve}</th>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        }
                    </Table>
                </IonCol>
            </IonRow>
            {isEditable && (
                <IonRow>
                    <IonCol>
                        Sum of the percentages: {calulateSum()}
                    </IonCol>
                </IonRow>
            )}
        </>
    );
};

export default PrizeMoneyPercentageTable;