import { 
    IonText
} from "@ionic/react";
import React from "react";

interface _Props {
    name: string
}

const OrganizationName: React.FC<_Props> = ({name}) => {
    return (
        <IonText className="ion-justify-content-center">
            <h3 className="text-center">{name}</h3>
        </IonText>
    );
};

export default OrganizationName;
