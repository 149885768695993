import {
    IonCard, 
    IonCardContent, 
    IonCardTitle, 
    IonCol, 
    IonContent, 
    IonHeader,  
    IonIcon,  
    IonItem,  
    IonLabel,  
    IonPage, 
    IonRow, 
} from "@ionic/react";
import { phonePortraitOutline } from "ionicons/icons";
import React, {useEffect, useState} from "react";
import { RouteComponentProps } from "react-router";
import OrganizationAuditorSignUpButton from "../../components/OrganizationAuditor/OrganizationAuditorSignUpButton";
import WebNavbar from "../../components/Navbars/WebNavbar";
import { Organization } from "../../models";
import { getAllOrganizations } from "../../utilities/organization/Organization";
import ErrorBanner from "../../components/Banners/ErrorBanner";

interface ViewOrgProps extends RouteComponentProps<{
    id: string;
}> {}

const ViewOrgPage: React.FC<ViewOrgProps> = ({match}) => {

    const [currentOrganization, setCurrentOrganization] = useState<Organization>();
    const [error, setError] = useState("");

    async function getOrganization(id: string) {
        const queryResult = await getAllOrganizations();
        if (queryResult.isSuccess) {
            const organizationList: Organization[] = queryResult.result;
            if (organizationList && organizationList.length > 0) {
                const foundByBackHalf: (Organization | undefined) = organizationList.find(org => org.urlBackHalf === id);
                if (foundByBackHalf) {
                    setCurrentOrganization(foundByBackHalf);
                } else {
                    const foundById: (Organization | undefined) = organizationList.find(org => org.id === id);
                    if (foundById) {
                        setCurrentOrganization(foundById);
                    }
                }
            }
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    useEffect(() => {
        if (match.params && match.params.id) {
            getOrganization(match.params.id);
        } else {
            const path = match.path;
            const pathArray = path.split("/");
            const id = pathArray[pathArray.length-1];
            if (id) {
                getOrganization(id);
            }
        }
    }, [match, match.params.id]);
    
    return (
        <IonPage id="viewOrgPage" className="bg-light">
            <IonHeader>
                <WebNavbar />
            </IonHeader>
            <IonContent>
                {error && <ErrorBanner error={error} />}
                {currentOrganization ? 
                    <>
                        <IonRow className="ion-justify-content-center ion-padding-top">
                            <IonCol size="12" className="ion-text-center">
                                <h1>{currentOrganization.name}</h1>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="8">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonItem lines="none">
                                            <IonIcon icon={phonePortraitOutline} slot="start"/>
                                            <IonLabel> Text Alerts</IonLabel>
                                        </IonItem>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <IonRow className="ion-justify-content-center">
                                            <p>Click below to sign up for alerts or adjust your alert settings.</p>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center">
                                            <OrganizationAuditorSignUpButton organization={currentOrganization} />
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                    :
                    <>  
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12" className="ion-text-center">
                                Loading...
                            </IonCol>
                        </IonRow>
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default ViewOrgPage;
