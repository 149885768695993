import React, { useEffect, useState } from "react";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";
import { Event, EventRing, Ring } from "../../models";
import { getEventRingsByEventId } from "../../utilities/eventRing/EventRing";
import { getRingById } from "../../utilities/ring/Ring";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import { onCreateEventRing } from "../../graphql/subscriptions";
import Spinner from "../Spinners/Spinner";

interface _Props {
    event: Event
    menuPlacement?: string
    selectedValue?: string
    isDisplayDefault?: boolean
    isClearable?: boolean
    onSelect: Function
}

const SelectEventRing: React.FC<_Props> = ({event, selectedValue, menuPlacement, isDisplayDefault, isClearable, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedEventRings, setFormattedEventRings] = useState<formattedOption[] | null | undefined>();
    const [currentRingSubscriptionItem, setCurrentRingSubscriptionItem] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);

    const createRingSubscription = useSubscriptionByItself({
        config: {
            query: onCreateEventRing,
            key: "onCreateEventRing"
        }
    });

    const formatEventRings = async (eventRings: EventRing[]) => {
        let formattedEventRings = [];
        for (var i = 0; i < eventRings.length; i++) {
            const eventRing: EventRing = eventRings[i];
            let ringName = eventRing.ring?.name;
            if (!ringName) {
                const queryResult = await getRingById(eventRing.ringId);
                if (queryResult.isSuccess) {
                    const ring: Ring = queryResult.result;
                    ringName = ring.name;
                }
            }
            let object: formattedOption = {
                value: eventRing.id,
                label: ringName || "unknown ring",
                object: eventRing
            };
            formattedEventRings.push(object);
        }
        setFormattedEventRings(formattedEventRings);
        if (!selected && formattedEventRings && formattedEventRings[0]) setSelected(formattedEventRings[0].value)
    }

    async function getEventRings(event: Event) {
        setIsLoading(true);
        const queryResult = await getEventRingsByEventId(event.id);
        if (queryResult.isSuccess) {
            await formatEventRings(queryResult.result);
        }
        setIsLoading(false);
    }

    function handleUpdate(event: Event) {
        getEventRings(event);
    }
    
    useEffect(() => {
        if (event) getEventRings(event);
    }, [event]);

    useEffect(() => {
        if (selectedValue) {
            setSelected(selectedValue)
        } else {
            setSelected(undefined);
        }
    }, [selectedValue]);

    useEffect(() => {
        if (createRingSubscription && createRingSubscription[0] !== undefined && createRingSubscription[0] !== currentRingSubscriptionItem) {
            setCurrentRingSubscriptionItem(createRingSubscription[0]);
            if (event) {
                handleUpdate(event);
            }
        }
    }, [createRingSubscription]);

    const handleOnChange = (event?: any) => {
        if (event && event) {
            onSelect(event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {formattedEventRings ?
                        <BasicSelect formattedValues={formattedEventRings} selectedValue={selected} menuPlacement={menuPlacement} isClearable={isClearable} onSelect={handleOnChange}/>
                        :
                        <p></p>
                    }
                </>
            }
        </>
    );
};

export default SelectEventRing;
