import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Barn, BarnMember } from "../../models";
import { Table } from "reactstrap";
import { PersonContext } from "../../context/PersonContext";
import ErrorAlert from "../Errors/ErrorAlert";
import { BarnPerson } from "../../interfaces/Person";
import { getBarnPeople, getStrictBarnPeople } from "../../utilities/barn/BarnPeople";
import AddBarnPersonCard from "../Barn/AddBarnPersonCard";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import { onCreatePerson, onDeletePerson, onUpdatePerson } from "../../graphql/subscriptions";
import Spinner from "../Spinners/Spinner";
import { getBarnMembersByBarnId } from "../../utilities/barnMember/BarnMember";
import { isWindows } from "../../utilities/platform/Platform";

interface _Props {
    barn?: Barn
    onSelect: Function
}

const EditPeopleTable: React.FC<_Props> = ({barn, onSelect}) => {
    const user = useContext(PersonContext);

    const createSubscription = useSubscriptionByItself({
        config: {
            query: onCreatePerson,
            key: "onCreatePerson"
        }
    });

    const updateSubscription = useSubscriptionByItself({
        config: {
            query: onUpdatePerson,
            key: "onUpdatePerson"
        }
    });

    const deleteSubscription = useSubscriptionByItself({
        config: {
            query: onDeletePerson,
            key: "onDeletePerson"
        }
    });

    const [people, setPeople] = useState<BarnPerson[] | null | undefined>();
    const [barnMembers, setBarnMembers] = useState<BarnMember[] | null | undefined>();
    const [currentCreateSubscriptionItem, setCreateCurrentSubscriptionItem] = useState<any>();
    const [currentUpdateSubscriptionItem, setUpdateCurrentSubscriptionItem] = useState<any>();
    const [currentDeleteSubscriptionItem, setDeleteCurrentSubscriptionItem] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const getPeople = async (barnId: string) => {
        setIsLoading(true);
        const people = await getStrictBarnPeople(barnId);
        setPeople(people);
        setIsLoading(false);
    }

    const getBarnMembers = async (barnId: string) => {
        setIsLoading(true);
        const queryResult = await getBarnMembersByBarnId(barnId);
        if (queryResult.isSuccess) {
            const barnMemberArray: BarnMember[] = queryResult.result;
            setBarnMembers(barnMemberArray);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (barn) {
            getPeople(barn.id);
            // getBarnMembers(barn.id);
        }
    }, [barn]);

    useEffect(() => {
        if (createSubscription && createSubscription[0] !== undefined && createSubscription[0] !== currentCreateSubscriptionItem) {
            setCreateCurrentSubscriptionItem(createSubscription[0]);
            if (barn) getPeople(barn.id);
        }
    }, [createSubscription]);

    useEffect(() => {
        if (updateSubscription && updateSubscription[0] !== undefined && updateSubscription[0] !== currentUpdateSubscriptionItem) {
            setUpdateCurrentSubscriptionItem(updateSubscription[0]);
            if (barn) getPeople(barn.id);
        }
    }, [updateSubscription]);

    useEffect(() => {
        if (deleteSubscription && deleteSubscription[0] !== undefined && deleteSubscription[0] !== currentDeleteSubscriptionItem) {
            setDeleteCurrentSubscriptionItem(deleteSubscription[0]);
            if (barn) getPeople(barn.id);
        }
    }, [deleteSubscription]);

    const onSelectPerson = (person: BarnPerson) => {
        onSelect(person);
    }

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="4">
                        Barn Members
                    </IonCol>
                    <IonCol offsetMd="4" sizeXs="12" sizeMd="4" className="ion-text-right">
                        <AddBarnPersonCard barn={barn} />
                    </IonCol>
                </IonRow>
            </IonCardTitle>
            <IonCardSubtitle>Click on a Person record to edit it.</IonCardSubtitle>
            {isLoading ?
                <Spinner />
                :
                <IonCardContent id="peopleList">
                    {error && <ErrorAlert width="12" error={error}/>}
                    {/* {(barnMembers && barnMembers.length > 0) ?
                        <>
                            {((!isWindows()) && isPlatform("mobile")) ?
                                <IonList className="bg-white">
                                    {barnMembers.map((barnMember, index) => (
                                        <IonItem key={index} onClick={() => onSelectPerson(barnMember)}>
                                            <IonLabel>
                                                <IonRow>
                                                    <IonCol>
                                                        <h2 className="ion-text-wrap">{barnMember.name}</h2>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <p className="ion-text-wrap">Roles: {barnMember.roles}</p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                </IonList>
                                :
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Roles</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {barnMembers.map((barnMember, index) => (
                                            <tr key={index} onClick={() => onSelectPerson(barnMember)}>
                                                <td>
                                                    {barnMember.name}
                                                </td>
                                                <td>
                                                    {barnMember.roles}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            }
                        </>
                        :
                        <p>No members found.</p>
                    } */}
                    {isPlatform("mobile") ?
                        <IonList className="bg-white">
                            {(people && people.length > 0) && (
                                <>
                                    {people.map((person, index) => (
                                        <IonItem key={index} onClick={() => onSelectPerson(person)}>
                                            <IonLabel>
                                                <IonRow>
                                                    <IonCol>
                                                        <h2 className="ion-text-wrap">{person.name}</h2>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <p className="ion-text-wrap">Roles: {person.roles}</p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <p className="ion-text-wrap">Created By: {person.creatorName}</p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                </>
                            )}
                        </IonList>
                        :
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Roles</th>
                                    <th>Created By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(people && people.length > 0) && (
                                    <>
                                        {people.map((person, index) => (
                                            <tr key={index} onClick={() => onSelectPerson(person)}>
                                                <td>
                                                    {person.name}
                                                </td>
                                                <td>
                                                    {person.roles}
                                                </td>
                                                <td>
                                                    {person.creatorName}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </Table>
                    }
                </IonCardContent>
            }
        </IonCard>
    );
};

export default EditPeopleTable;