import {
    IonContent,
    IonPage,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../../../../components/Headers/Header";
import { Organization } from "../../../../../models";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import Spinner from "../../../../../components/Spinners/Spinner";
import { PersonContext } from "../../../../../context/PersonContext";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import OrganizationMembershipsTable from "../../../../../components/Memberships/OrganizationMembershipsTable";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventOrganizationMembersPage: React.FC<OrganizationPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(true);
    const [organization, setOrganization] = useState<Organization>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOrganization() {
            try {
                setIsLoading(true);
                const queryResult = await getOrganizationById(match.params.id);
                if (queryResult.isSuccess) {
                    setOrganization(queryResult.result);
                    setIsLoading(false);
                } else {
                    setError("Sorry, a problem occurred. Please go back and try again.");
                    setIsLoading(false);
                }
            } catch (error: any) {
                setError("Sorry, a problem occurred. Please go back and try again.");
                setIsLoading(false);
            }
        }

        getOrganization();
    }, [user, match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Members" />
                {error && <ErrorAlert error={error} />}
                {isLoading ?
                    <Spinner />
                    :   
                    <>
                        {organization ?
                            <OrganizationMembershipsTable organizationId={organization.id} />
                            :
                            <p>No organization found</p>
                        }
                    </>
                }

            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationMembersPage;