import moment from "moment";

export function formatTimeToAMPM(input: string) {
    const time = input.replace("am", "").replace("pm", "");
    const timeArray = time.split(":");
    const hours = timeArray[0];
    const minutes = timeArray[1];
    const fullDateTime = moment().hour(parseInt(hours)).minute(parseInt(minutes));
    const formattedTime = moment(fullDateTime).format("h:mm a");
    return formattedTime;
}

export function formatTimeTo24Hour(input: string) {
    const timeArray = input.split(":");
    const hours = timeArray[0];
    const minutes = timeArray[1];
    const fullDateTime = moment().hour(parseInt(hours)).minute(parseInt(minutes));
    const formattedTime = moment(fullDateTime).format("kk:mm");
    return formattedTime;
}

export function formatDateTimeTo24Hours(dateInput: string, timeInput: string) {
    const dateTimeString = dateInput + " " + timeInput;
    const momentObject = moment(dateTimeString, "YYYY-MM-DD h:mm a");
    return momentObject.format("HH:mm:ss.sss");
}