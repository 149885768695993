import React, { useEffect, useState } from "react";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    menuPlacement?: (string | null)
    selectedValue?: (string | null)
    memberships?: (string[] | null)
    onSelect: Function
}

const SelectMembership: React.FC<_Props> = ({selectedValue, memberships, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedMemberships, setFormattedMemberships] = useState<formattedOption[] | null | undefined>();

    const formatMemberships = (memberships: string[]) => {
        let formattedMemberships = [];
        for (var i = 0; i < memberships.length; i++) {
            const membership = memberships[i];
            const value = membership;
            const label = (membership || "error");
            let object: formattedOption = {
                value: value,
                label: label,
                object: memberships[i]
            };
            formattedMemberships.push(object);
        }
        setFormattedMemberships(formattedMemberships);
    }

    useEffect(() => {
        if (memberships) formatMemberships(memberships);
    }, [memberships]);

    useEffect(() => {
        if (selectedValue) setSelected(selectedValue)
    }, [selectedValue]);

    const handleOnChange = (membership?: any) => {
        if (membership) {
            onSelect(membership);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedMemberships ?
                <BasicSelect formattedValues={formattedMemberships} menuPlacement={menuPlacement} selectedValue={selected} onSelect={handleOnChange} />
                :
                <p>Loading options...</p>
            }
        </>
    )
};

export default SelectMembership;
