export const levels = [
    "0.65M",
    "0.70M",
    "0.75M",
    "0.80M",
    "0.85M",
    "0.90M",
    "0.95M",
    "1.00M",
    "1.05M",
    "1.15M",
    "1.20M",
    "1.25M",
    "1.30M",
    "1.35M",
    "1.40M",
    "1.45M",
    "1.50M",
    "1.55M",
    "1.60M",
    "<2'0\"",
    "2'0\"",
    "2'3\"",
    "2'6\"",
    "2'9\"",
    "3'0\"",
    "3'3\"",
    "3'6\"",
    "3'9\"",
    "4'0\"",
    "4'3\"",
    "4'6\"",
    "4'9\"",
    "5'0\"",
    "NA",
    "Introductory",
    "Elementary",
    "Beginner Novice",
    "Novice",
    "Training/Prelim",
    "Modified",
    "Preliminary",
    "Intermediate",
    "Advanced",
    "Other"
];