import { 
    IonCol,
    IonRow,
    isPlatform
} from "@ionic/react";
import ProfileChip from "../Chips/ProfileChip/ProfileChip";
import SwitchOrganization from "../Organization/SwitchOrganization";
import React from "react";
import { isWindows } from "../../utilities/platform/Platform";

interface _Props {
    title: string,
    setOrganizationHandler?: Function,
    showSwitchDropdown?: boolean,
    newStyles?: boolean
}

const PageTitle: React.FC<_Props> = ({title, setOrganizationHandler, showSwitchDropdown, newStyles = false}) => {

    return (
        <React.Fragment>
            {newStyles ? (
                <IonRow className="ion-justify-content-between bg-white">
                    <IonCol sizeSm="10" sizeMd={showSwitchDropdown ? "4" : "8"}>
                        <h2 className="display-3 text-monospace font-weight-700 text-default pl-2 m-0">{title}</h2>
                    </IonCol>
                    {(isWindows() || (isPlatform("desktop"))) && showSwitchDropdown && (
                        <IonCol sizeMd="4">
                            <SwitchOrganization setOrganizationHandler={setOrganizationHandler}/>
                        </IonCol>
                    )}
                    {(isWindows() || (isPlatform("desktop"))) && (
                        <IonCol sizeSm="2" sizeMd="4">
                            <ProfileChip />
                        </IonCol>
                    )}
                </IonRow>
            ) : (
                <IonRow className="ion-justify-content-between">
                    <IonCol sizeSm="10" sizeMd={showSwitchDropdown ? "4" : "8"}>
                        <h2>{title}</h2>
                    </IonCol>
                    {(isWindows() || (isPlatform("desktop"))) && showSwitchDropdown && (
                        <IonCol sizeMd="4">
                            <SwitchOrganization setOrganizationHandler={setOrganizationHandler}/>
                        </IonCol>
                    )}
                    {(isWindows() || (isPlatform("desktop"))) && (
                        <IonCol sizeSm="2" sizeMd="4">
                            <ProfileChip />
                        </IonCol>
                    )}
                </IonRow>
            )}
        </React.Fragment>
    );
};

export default PageTitle;
