import { IonButton, IonCard, IonCol, IonRow, IonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { S3ObjectInput, UpdateMembershipInput } from "../../../API";
import { Membership, OrganizationMembershipType, S3Object } from "../../../models";
import { downloadBlob, isImage, isPDF } from "../../../utilities/files/File";
import { updateMembership } from "../../../utilities/membership/Membership";
import { createMembershipUploadFileName, createS3Object, handleDeleteS3File, handleDownloadS3File, handleUploadS3File } from "../../../utilities/s3Object/s3Object";
import ErrorAlert from "../../Errors/ErrorAlert";
import Spinner from "../../Spinners/Spinner";

interface _Props {
    membershipType: OrganizationMembershipType | undefined;
    membership: Membership | undefined;
    setMembership: Function;
};

const EditMembershipApplicationUploads: React.FC<_Props> = ({membershipType, membership, setMembership}) => {
    const length = membershipType?.organizationDocuments?.length;
    const [files, setFiles] = useState<(File | undefined)[]>(Array(length));
    const [documents, setDocuments] = useState<(S3Object | null)[] | null>([]);
    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if(membership) {
            setDocuments(membership.documents ?? []);
        }
    }, [membership]);

    const downloadFile = async (item: S3Object | null | undefined) => {
        if(item) {
            setIsLoading(true);
            const downloadResult = await handleDownloadS3File(item.key);
            if(downloadResult.isSuccess) {
                downloadBlob(downloadResult.result.Body, item.description || "");
            } else {
                setError(downloadResult.message);
            }
            setIsLoading(false);
        }
    }

    const handleFileUpload = (event: any) => {
        setError("");
        event.preventDefault();
        const index = parseInt(event.target.getAttribute("data-index"));
        const file = event.target.files.length > 0 ? event.target.files[0] : undefined;
        if (file && (isPDF(file) || isImage(file))) {
            files[index] = file;
            setFiles([...files]);
        } else {
            setError('Error: File must be a PDF or image.');
        }
    }

    const removeFile = async (event: any) => {
        const index = parseInt(event.target.getAttribute("data-index"));
        files[index] = undefined;
        setFiles([...files]);
        const id = event.target.getAttribute("data-id");
        if(id && documents?.length) {
            const matchIndex = documents?.findIndex((n) => {
                return n?.title === id;
            });
            const s3Object = documents[matchIndex];
            if(matchIndex !== -1 && s3Object && membership) {
                setIsLoading(true);
                const deleteResult = await handleDeleteS3File(s3Object.key);
                if(deleteResult.isSuccess) {
                    const documentsArray = [
                        ...documents.slice(0, matchIndex),
                        ...documents.slice(matchIndex + 1)
                    ];
                    // Update the membership record with updated data
                    const membershipInput: UpdateMembershipInput = {
                        id: membership.id,
                        documents: documentsArray
                    };
                    const updateMembershipResult = await updateMembership(membershipInput);
                    if (updateMembershipResult.isSuccess) {
                        setMembership(updateMembershipResult.result);
                    } else {
                        setError(updateMembershipResult.message);
                    }
                } else {
                    setError(deleteResult.message);
                }
                setIsLoading(false);
            }
        }
    }

    const handleFileSave = async () => {
        setError("");
        let index = 0;
        try {
            if (membership) {
                const arrayWithFile = files.filter((file) => {
                    return file !== undefined;
                });
                let totalLength = arrayWithFile.length;
                if (documents?.length) {
                    totalLength += documents?.length;
                }
                if(totalLength !== membershipType?.organizationDocuments?.length) {
                    setError("Please ensure that all documents have been uploaded.")
                    return;
                }
                const fileFoundIndex = files.findIndex((file) => {
                    return file !== undefined;
                });
                if(fileFoundIndex === -1) {
                    return;
                }
                setIsLoading(true);
                // Upload the documents to S3
                const documentsArray: S3ObjectInput[] = [];
                for (const file of files) {
                    const document = membershipType?.organizationDocuments && membershipType?.organizationDocuments[index];
                    if(file) {
                        const uploadResult = await handleUploadS3File(
                            createMembershipUploadFileName(membership as Membership, file?.name || ""),
                            file
                        );
                        if (uploadResult.isSuccess) {
                            const key = uploadResult.result;
                            // saved document id from membership type in title field of S3Object
                            // saved uploaded file in description field of S3Object
                            const s3Object = createS3Object(key, document?.id, file, file?.name);
                            documentsArray.push(s3Object);
                        } else {
                            setError(uploadResult.message);
                        }
                    } else {
                        const found = documents?.find((d) => {
                            return d?.title === document?.id;
                        });
                        if(found) {
                            documentsArray.push(found);
                        }
                    }
                    index++;
                }
                // Update the membership record
                const membershipInput: UpdateMembershipInput = {
                    id: membership?.id ? membership?.id : "",
                    documents: documentsArray
                };
                const updateMembershipResult = await updateMembership(membershipInput);
                if (updateMembershipResult.isSuccess) {
                    setMembership(updateMembershipResult.result);
                    setFiles(Array(length));
                } else {
                    setError(updateMembershipResult.message);
                }
            } else {
                setError("No membership was found.");
            }
        } catch (error: any) {
            console.error(error);
        }
        setIsLoading(false);
    }

    return (
        <React.Fragment>
            <IonCard color="white" className="ion-padding">
                <h3>Uploads</h3>
                {error && <ErrorAlert width="12" error={error} />}
                {isLoading ? 
                    <Spinner/>
                :
                    <>
                        {membershipType?.organizationDocuments && membershipType?.organizationDocuments?.length === 0 ?
                            <p>This membership type does not have any documents to upload.</p>
                        :
                            (membershipType?.organizationDocuments?.map((document, index) => {
                                const found: S3Object | null | undefined = membership?.documents?.find((item) => {
                                    return item?.title === document?.id;
                                });
                                return (files[index] || found ? 
                                    <div key={index}>
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                {document?.name}
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonRow className="mt-2">
                                                    <IonCol sizeXs="9" sizeMd="9">
                                                        {files[index]?.name ?
                                                            <p>
                                                                {files[index]?.name}
                                                            </p>
                                                        :
                                                            <p className="link text-info"
                                                                onClick={() => downloadFile(found)}
                                                            >
                                                                {found?.description}
                                                            </p>
                                                        }
                                                    </IonCol>
                                                    <IonCol sizeXs="3" sizeMd="3">
                                                        <IonText 
                                                            data-index={index}
                                                            data-id={found?.title}
                                                            id={`removeText-${index}`}
                                                            onClick={(e) => removeFile(e)}
                                                            className="text-danger link"
                                                            class="ion-float-right"
                                                        >
                                                            remove
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                        </IonRow>
                                        <hr/>
                                    </div>
                                :
                                <div key={index}>
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="6">
                                            {document?.name}
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <input
                                                data-index={index}
                                                id={`customEditFileUpload-${index}`}
                                                type="file" 
                                                onChange={(e) => handleFileUpload(e)} 
                                            />
                                            <label
                                                className="custom-file-label"
                                                htmlFor={`customEditFileUpload-${index}`}
                                            >
                                                Select File
                                            </label>
                                        </IonCol>
                                    </IonRow>
                                    <hr/>
                                </div>)
                            }))
                        }
                        <IonRow className="ion-justify-content-center mb-2 mt-4">
                            <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                <IonButton
                                    id="mt-edit-upload-save-btn"
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleFileSave}
                                >
                                    Save
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </>
                }
            </IonCard>
        </React.Fragment>
    );
};

export default EditMembershipApplicationUploads;
