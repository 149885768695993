export function getDescription(value: string) {
    let description = "";
    alertSettingsOptions.forEach(element => {
        if (element.value === value) description = element["description"];
    });
    return description;
}

export const alertSettingsOptions = [
    {
        "name": "Premium Support",
        "description": "Reminders before the show, ring updates each day, schedule changes, and general announcements.",
        "value": "premium"
    },
    {
        "name": "Standard Support",
        "description": "Reminders before the show, morning and evening announcements, schedule changes, and general announcements.",
        "value": "standard"
    },
    {
        "name": "Basic Support",
        "description": "Morning and evening announcements and any general announcements.",
        "value": "basic"
    }
];