import { IonCol, IonIcon, IonLabel, IonRow, IonText } from "@ionic/react";
import React from "react";
import {download, printOutline} from "ionicons/icons";

interface _Props {
    document?: any
}

const DownloadLink: React.FC<_Props> = ({document}) => {

    return (
        <>
            <IonIcon icon={printOutline} color="primary"/>
        </>
    );
};

export default DownloadLink;
