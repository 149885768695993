import { Result } from "../../interfaces/Result";
import { EventClass, EventClassEntry, EventDivision, EventEntry, Rider } from "../../models";
import { getEventDivisionById } from "../eventDivision/EventDivision";
import { getEventEntryById } from "../eventEntry/EventEntry";
import { getEventClassEntriesWithEventClassByEventEntryId } from "./EventClassEntry";

interface formattedOption {
    isEnteredByDivision: boolean
    entryFee: number
    label: string
    number?: (number | null)
    value: string
    eventClassEntry?: (EventClassEntry | null)
    rider?: (Rider | null)
    object: any
    status?: string
}

export interface formattedOptionGroup {
    division?: formattedOption
    classes?: formattedOption[]
    class?: formattedOption
}

export interface entryClassListObject {
    classList: formattedOptionGroup[]
    totalEntryFees: number
}

interface classData {
    eventClass: EventClass
    eventClassEntry: EventClassEntry
    rider?: Rider 
}

async function getEventClassEntriesByEntryId(entry: EventEntry) {
    const queryResult = await getEventClassEntriesWithEventClassByEventEntryId(entry.id);
    const eventClassEntries: EventClassEntry[] = queryResult.result;
    if (eventClassEntries && eventClassEntries.length) {
        let classArray: classData[] = [];
        for (var i = 0; i < eventClassEntries.length; i++) {
            const currentEventClassEntry = eventClassEntries[i];
            const eventClass = currentEventClassEntry.eventClass;
            if (eventClass) {
                const object: classData = {
                    eventClass: eventClass,
                    eventClassEntry: currentEventClassEntry,
                    rider: currentEventClassEntry.rider || undefined,
                };
                classArray.push(object);
            }
        }
        const sorted = classArray.sort((a, b) => ((a.eventClass && a.eventClass.number) ? a.eventClass.number : 0) - ((b.eventClass && b.eventClass.number) ? b.eventClass.number : 0));
        return sorted;
    } else {
        return [];
    }
}

export async function getEventEntryDivisions(entry: EventEntry) {
    // First, try to get the divisions from the entry object
    let currentEventDivisions: (string | null)[] | null | undefined = undefined;

    // Get the fresh Entry object each time
    const queryResult = await getEventEntryById(entry.id);
    if (queryResult.isSuccess) {
        entry = queryResult.result;
        if (entry.divisionList) currentEventDivisions = entry.divisionList;
    }

    // If there are divisions, return the event divisions
    if (currentEventDivisions) {
        let divisionArray: EventDivision[] = [];
        for (var i = 0; i < currentEventDivisions.length; i++) {
            const currentEventDivisionId = currentEventDivisions[i];
            const foundDivisionIdInDivisionArray = divisionArray.findIndex(d => d.id === currentEventDivisionId);
            if (currentEventDivisionId && foundDivisionIdInDivisionArray === -1) {
                const queryResult = await getEventDivisionById(currentEventDivisionId);
                if (queryResult.isSuccess) {
                    const eventDivision = queryResult.result;
                    divisionArray.push(eventDivision);
                }
            }
        }
        return divisionArray;
    } else {
        return [];
    }
}

export const findDivisionInList = (eventDivisionId?: string, eventDivisionList?: EventDivision[]) => {
    let result = false;
    if (eventDivisionId && eventDivisionList) {
        for (var i = 0; i < eventDivisionList.length; i++) {
            if (eventDivisionList[i] && eventDivisionId === eventDivisionList[i].id) {
                result = true;
            }
        }
    }
    return result;
}

export async function getFormattedEntryClassList(entry: EventEntry, onlyIncludeAcceptedClasses?: boolean): Promise<Result> {
    // Key = eventDivisionId OR eventClassId
    // Value = formattedOptionGroup
    let optionMap = new Map();
    const classList: classData[] = await getEventClassEntriesByEntryId(entry);

    let totalEntryFeeCost = 0;
    if (classList && classList.length) {

        const divisionList = await getEventEntryDivisions(entry);

        // Loop through the classData objects from getting EventClassEntries
        for (var i = 0; i < classList.length; i++) {
            const currentClassData: classData = classList[i];

            // Pull the data retreived when getting the EventClassEntry info
            const currentEventClassEntry = currentClassData.eventClassEntry;
            const currentClass: EventClass | null | undefined = currentClassData.eventClass;

            const selectedRider = currentClassData.rider;

            if (onlyIncludeAcceptedClasses && currentEventClassEntry.status !== "accepted") {
                continue;
            }

            // Check if this EventClass belongs to an EventDivision
            if (currentClass?.eventDivision?.id) {
                // Check if the Entry has this division in thier list of "Whole Divisions they Entered"
                const foundDivisionInList = findDivisionInList(currentClass?.eventDivision?.id, divisionList);
                
                // Check cost of the division
                const eventDivision = currentClass.eventDivision;
                const eventDivisionFee = eventDivision?.entryFee;
                const feeNumber = eventDivisionFee ? parseFloat(eventDivisionFee) : 0;
                const isEnteredByDivision = foundDivisionInList && (feeNumber > 0);

                // Create the formattedOption for this class
                const currentClassOption: formattedOption = {
                    label: currentClass.name,
                    value: currentClass.id,
                    object: currentClass,
                    entryFee: currentClass?.entryFee ? parseFloat(currentClass.entryFee) : 0,
                    isEnteredByDivision: isEnteredByDivision,
                    rider: selectedRider,
                    eventClassEntry: currentEventClassEntry,
                    number: currentClass.number,
                    status: currentEventClassEntry.status || ""
                };

                if (isEnteredByDivision) {
                    // Add the division once, and map all of its classes to this division
                    const currentDivisionId = currentClass?.eventDivision?.id;

                    // Check the optionMap to see if we've already come across this division
                    const optionMapResult: formattedOptionGroup = optionMap.get(currentDivisionId);

                    if (optionMapResult) {
                        // Seen this division already - get the classes array and update it
                        const classesOptions: formattedOption[] | undefined = optionMapResult.classes;
                        let updatedClassesOptions: formattedOption[] | undefined = [];

                        // If there are already classes in the array, add this class. OTW, create a new array.
                        if (classesOptions && classesOptions.length > 0) {
                            updatedClassesOptions = classesOptions.concat([currentClassOption]);
                        } else {
                            updatedClassesOptions = [currentClassOption];
                        }

                        // Update the formattedOptionGroup
                        let updatedOptionGroup: formattedOptionGroup = {
                            ...optionMapResult,
                            classes: updatedClassesOptions
                        };

                        // Finally, update the optionMap to include the updated classes array
                        optionMap.set(currentDivisionId, updatedOptionGroup);
                    } else {
                        // Create a new division for the optionMap

                        // Need to query for the division in order to retrieve all of it's fields
                        const queryResult = await getEventDivisionById(currentDivisionId);
                        const currentDivision = queryResult.result;

                        // Track the division fee - entry will just pay the division fee instead of the sum of each class fee in the division
                        const divisionEntryFee = currentDivision?.entryFee ? parseFloat(currentDivision.entryFee) : 0
                        totalEntryFeeCost = totalEntryFeeCost + divisionEntryFee;

                        // Create the formattedOption for this division
                        const currentDivisionOption: formattedOption = {
                            label: currentDivision.name,
                            value: currentDivision.id,
                            object: currentDivision,
                            entryFee: currentDivision?.entryFee ? parseFloat(currentDivision.entryFee) : 0,
                            isEnteredByDivision: isEnteredByDivision,
                            rider: selectedRider,
                            eventClassEntry: currentEventClassEntry,
                        };
                        
                        // Create the formattedOptionGroup with the division set and the current class in the classes array
                        const newFormattedOptionGroup: formattedOptionGroup = {
                            division: currentDivisionOption,
                            classes: [currentClassOption]
                        };

                        // Set the data in the optionMap
                        optionMap.set(currentDivisionId, newFormattedOptionGroup);
                    }
                } else {
                    // Entered individual classes instead of grouped by division

                    // Track the class fee
                    totalEntryFeeCost = totalEntryFeeCost + (currentClass?.entryFee ? parseFloat(currentClass.entryFee) : 0);
                    
                    // Create the formattedOptionGroup for this class
                    const newFormattedOptionGroup: formattedOptionGroup = {
                        class: currentClassOption
                    };

                    // Add the formattedOptionGroup for this class to the option Map (i is the unique id)
                    optionMap.set(i, newFormattedOptionGroup);
                }
            } else {
                // Class is not associated with a division. 
                if (currentClass) {
                    // Track the class fee
                    totalEntryFeeCost = totalEntryFeeCost + (currentClass?.entryFee ? parseFloat(currentClass.entryFee) : 0);
                    
                    // Create the formattedOption for this class
                    const currentClassOption: formattedOption = {
                        label: currentClass.name,
                        value: currentClass.id,
                        object: currentClass,
                        entryFee: currentClass?.entryFee ? parseFloat(currentClass.entryFee) : 0,
                        isEnteredByDivision: false,
                        rider: selectedRider,
                        eventClassEntry: currentEventClassEntry,
                        number: currentClass.number,
                        status: currentEventClassEntry.status || ""
                    };

                    // Create the formattedOptionGroup for this class
                    const newFormattedOptionGroup: formattedOptionGroup = {
                        class: currentClassOption
                    };

                    // Add the formattedOptionGroup for this class to the option Map (i is the unique id)
                    optionMap.set(i, newFormattedOptionGroup);
                }
            }
        }

        let formattedOptionGroups: formattedOptionGroup[] = [];

        optionMap.forEach(element => {
            formattedOptionGroups.push(element);
        });

        const entryClassListObject: entryClassListObject = {
            classList: formattedOptionGroups,
            totalEntryFees: totalEntryFeeCost
        };

        let result: Result = {isSuccess: true, type: "EventClass", result: entryClassListObject, message: "Successfully formatted the classes associated with the entry."};
        return result;
    } else {
        let result: Result = {isSuccess: false, type: "EventClass", result: null, message: "There were no classes associated with the entry."};
        return result;
    }
}