import React, { useEffect, useState } from "react";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    menuPlacement?: (string | null)
    selectedValue?: (string | null)
    years?: (string[] | null)
    onSelect: Function
}

const SelectYear: React.FC<_Props> = ({selectedValue, years, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedYears, setFormattedYears] = useState<formattedOption[] | null | undefined>();

    const formatYears = (years: string[]) => {
        let formattedYears = [];
        for (var i = 0; i < years.length; i++) {
            const year = years[i];
            const value = year;
            const label = year;
            let object: formattedOption = {
                value: value,
                label: label,
                object: years[i]
            };
            formattedYears.push(object);
        }
        setFormattedYears(formattedYears);
    }

    useEffect(() => {
        if (years) formatYears(years);
    }, [years]);

    useEffect(() => {
        if (selectedValue) setSelected(selectedValue)
    }, [selectedValue]);

    const handleOnChange = (year?: any) => {
        if (year) {
            onSelect(year);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedYears ?
                <BasicSelect formattedValues={formattedYears} menuPlacement={menuPlacement} selectedValue={selected} onSelect={handleOnChange} />
                :
                <p>Loading options...</p>
            }
        </>
    )
};

export default SelectYear;
