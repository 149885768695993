import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../../../context/PersonContext";
import RiderSelectStatus from "../../../../components/Rider/RiderSelectStatus";
import BirthDateForm from "../../../../components/SettingsPage/BirthDateForm";
import SelectDisciplines from "../../../../components/Discipline/SelectDisciplines";
import { EventDisciplineOption, formatDisciplinesForDisplay, getDisciplineOptionByName, getDisciplineOptionByValue } from "../../../../utilities/events/EventDiscipline";
import { Rider } from "../../../../models";
import { getRiderByPersonId, updateRider } from "../../../../utilities/rider/Rider";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import { updatePerson } from "../../../../utilities/person/Person";
import LocationForm from "../../../../components/Address/LocationForm";
import { UpdatePersonInput, UpdateRiderInput } from "../../../../API";

const RiderInfoPage: React.FC = () => {
    const user = useContext(PersonContext);

    const [currentRider, setCurrentRider] = useState<Rider | null | undefined>();
    const [isProfessional, setIsProfessional] = useState(false);
    const [disciplines, setDisciplines] = useState<EventDisciplineOption[] | null | undefined>();
    const [city, setCity] = useState<string | null | undefined>();
    const [provState, setProvState] = useState<string | null | undefined>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getRider() {
            const queryResult = await getRiderByPersonId(user.id);
            if (queryResult.isSuccess) {
                const rider = queryResult.result;
                setCurrentRider(rider);
                setIsProfessional(rider.isProfessional);
                if (rider.location) {
                    const addressArray = rider.location.split(",");
                    setCity(addressArray.length > 1 ? addressArray[0] : undefined);
                    setProvState(addressArray.length > 1 ? addressArray[1] : addressArray[0]);
                }
            } else {
                setError("No rider found.");
            }
        }

        function getDisciplines() {
            let disciplines = [];
            if (user.disciplines) {
                const disciplineArray = formatDisciplinesForDisplay(user.disciplines);
                for (var i = 0; i < disciplineArray.length; i++) {
                    const discipline = disciplineArray[i];
                    const formattedOption = getDisciplineOptionByName(discipline);
                    disciplines.push(formattedOption);
                }
            } 
            setDisciplines(disciplines);
        }

        if (user && user.id) {
            getRider();
            getDisciplines();
        }
    }, [user]);

    const handleStatusSelection = async (selection: boolean) => {
        if (selection !== isProfessional) {
            if (currentRider) {
                const input: UpdateRiderInput = {
                    id: currentRider.id,
                    isProfessional: selection, 
                };
                const updateResult = await updateRider(input);
                if (!updateResult) {
                    setError("Could not update the rider's amateur status.");
                }
            } else {
                setError("Could not find the current rider.");
            }
        } 
    }

    const handleDisciplinesSelection = async (value: string[]) => {
        if (value) {
            let displayString = "";
            let optionArray: EventDisciplineOption[] = [];
            for (var i = 0; i < value.length; i++) {
                displayString = displayString + value[i] + ";";
                const option = getDisciplineOptionByValue(value[i]);
                optionArray.push(option);
            }
            const personInput: UpdatePersonInput = {
                id: user.id,
                disciplines: displayString
            };
            await updatePerson(personInput);
            setDisciplines(optionArray);
        }
    }

    const handleLocationSelection = async (city: string, state: string) => {
        const locationValue = city + ", " + state;
        if (currentRider) {
            const input: UpdateRiderInput = {
                id: currentRider.id,
                location: locationValue, 
            };
            const updateResult = await updateRider(input);
            if (!updateResult) {
                setError("Could not update the rider's location.");
            }
        } else {
            setError("Could not find the current rider.");
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Rider Info" />
                {error && <ErrorAlert error={error} />}
                <IonRow className="ion-align-items-stretch">
                    <IonCol sizeSm="12" sizeMd="6">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                Your age
                            </IonCardTitle>
                            <IonCardSubtitle className="ion-margin-top">
                                Setting your age will allow us to ensure you qualify for certain classes you enter at horse shows.
                            </IonCardSubtitle>
                            <IonCardContent>
                                <BirthDateForm />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    <IonCol sizeSm="12" sizeMd="6">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                Amateur Status
                            </IonCardTitle>
                            <IonCardSubtitle className="ion-margin-top">
                                Setting your status as a professional will disqualify you from entering any amateur level classes at horse shows.
                            </IonCardSubtitle>
                            <IonCardContent>
                                <RiderSelectStatus rider={currentRider} onChange={handleStatusSelection} />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" sizeMd="6">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                Your Location
                            </IonCardTitle>
                            <IonCardContent className="ion-margin">
                                <LocationForm selectedCity={city} selectedProvState={provState} onSubmit={handleLocationSelection} />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    <IonCol size="12" sizeMd="6">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                Your Disciplines
                            </IonCardTitle>
                            <IonCardContent className="ion-margin">
                                <SelectDisciplines selectedDisciplines={disciplines} onSelect={handleDisciplinesSelection} />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default RiderInfoPage;