import moment from "moment";
import { Event, EventEntryFee, StablingRequest } from "../../models";
import { getFormattedEntryClassList } from "../eventClassEntry/FormattedEventEntryClasses";
import { getAcceptedEventEntriesByEventId } from "../eventEntry/EventEntry";
import { getEventEntryFeesByEventId } from "../eventEntryFee/EventEntryFee";
import { getFeeById } from "../fee/Fee";
import { getStablingRequestsByEventId } from "../stablingRequest/StablingRequest";
import { addFooters } from "./ReportFooter";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export async function generateRevenueTotal(event: Event) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Revenue Analysis Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = ["Description", "Total (no tax)", "Total (tax)"];
    // define an empty array of rows
    const tableRows = [];

    // Event Fees
    const tax = event.invoiceOptions?.classFeeTax;
    let totalEntryFeeAmount = 0;
    const queryEntryFeeResult = await getAcceptedEventEntriesByEventId(event.id);
    if (queryEntryFeeResult.isSuccess) {
        const eventEntries = queryEntryFeeResult.result;
        if (eventEntries.length > 0) {
            for (let i = 0; i < eventEntries.length; i++) {
                const eventEntry = eventEntries[i];
                const dataResult = await getFormattedEntryClassList(eventEntry, true);
                if (dataResult.isSuccess) {
                    const totalEntryFeesForEntry = dataResult.result.totalEntryFees;
                    totalEntryFeeAmount = totalEntryFeeAmount + totalEntryFeesForEntry;
                };
            }
        }
    }
    const totalEntryFeeRow = [
        "Total Entry Fees",
        "$" + totalEntryFeeAmount.toLocaleString(),
        "$" + (totalEntryFeeAmount + (totalEntryFeeAmount * (tax || 0)/100)).toLocaleString()
    ];
    tableRows.push(totalEntryFeeRow);

    // Event Fees
    let totalEventFeeAmount = 0;
    let totalEventFeeAmountWithTax = 0;

    const queryResult = await getEventEntryFeesByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventEntryFees: EventEntryFee[] = queryResult.result;
        if (eventEntryFees.length > 0) {
            for (let i = 0; i < eventEntryFees.length; i++) {
                const eventEntryFee = eventEntryFees[i];

                // Check that both the fee is accepted and the entry is accepted
                if (eventEntryFee.status === "accepted" && eventEntryFee.entry?.status === "complete") {
                    const eventFee = eventEntryFee.eventFee;

                    // Check for a standard EventFee
                    if (eventFee && !eventFee.eventClass) {
                        const feeQueryResult = await getFeeById(eventFee.feeId);
                        totalEventFeeAmount = totalEventFeeAmount + eventFee.amount;
                        totalEventFeeAmountWithTax = totalEventFeeAmountWithTax + (eventFee.amount + (eventFee.amount * (feeQueryResult.result?.taxA || 0)/100));
                    } else {
                        // If the EventEntryFee does not have an EventFee attached, then it was created as a Miscellaneous Fee by event admin
                        const currentFeeAmount = eventEntryFee.amount;
                        const currentQuantityTotal = eventEntryFee.quantity || 0;
                        const currentTotal = currentFeeAmount * currentQuantityTotal;
                        totalEventFeeAmount = totalEventFeeAmount + currentTotal;
                        totalEventFeeAmountWithTax = totalEventFeeAmountWithTax + (currentTotal + (eventEntryFee.amount * (eventEntryFee?.taxA || 0)/100));
                    }
                }
            }
        }
    }
    const totalEventFeeRow = [
        "Total Event Fees",
        "$" + totalEventFeeAmount.toLocaleString(),
        "$" + totalEventFeeAmountWithTax.toLocaleString()
    ];
    tableRows.push(totalEventFeeRow);

    // Stall Fees
    let totalStallFeeAmount = 0;
    let totalStallFeeWithTaxAmount = 0;
    const queryStallsResult = await getStablingRequestsByEventId(event.id);
    if (queryStallsResult.isSuccess) {
        const stablingRequests = queryStallsResult.result;
        if (stablingRequests) {
            for (let i = 0; i < stablingRequests.length; i++) {
                const stablingRequest: StablingRequest = stablingRequests[i];
                const amount = stablingRequest.basePrice || 0;
                const tax = (stablingRequest.taxA || 0) + (stablingRequest.taxB || 0);
                totalStallFeeAmount = totalStallFeeAmount + amount;
                totalStallFeeWithTaxAmount = totalStallFeeWithTaxAmount + (amount + (amount * tax/100));
            }
        }
    }
    const totalStallFeeRow = [
        "Total Stall Fees",
        "$" + totalStallFeeAmount.toLocaleString(),
        "$" + totalStallFeeWithTaxAmount.toLocaleString(),
    ];
    tableRows.push(totalStallFeeRow);

    const totalRow = [
        "TOTAL",
        "$" + (totalEventFeeAmount + totalEntryFeeAmount + totalStallFeeAmount).toLocaleString(),
        "$" + (totalEventFeeAmountWithTax + (totalEntryFeeAmount + (totalEntryFeeAmount * (tax || 0)/100)) + totalStallFeeWithTaxAmount).toLocaleString()
    ];

    tableRows.push(totalRow);
     
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 35 
    });

    // page footer
    addFooters(doc);

    // we define the name of our PDF file.
    doc.save(`revenue_total.pdf`);
}