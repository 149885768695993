import React, { useEffect, useState } from "react";
import { USEFSection } from "../../interfaces/USEFData";
import { getUSEFSections } from "../../utilities/USEFAPI/USEFSections";
import BasicSelect from "../Select/BasicSelect";

interface _Props {
    menuPlacement?: string
    selectedValue?: string
    onSelect: Function
}

interface formattedOption {
    label: string
    value: string
    object: USEFSection
}

const SelectUSEFClasses: React.FC<_Props> = ({selectedValue, menuPlacement, onSelect}) => {

    const [formattedClasses, setFormattedClasses] = useState<formattedOption[] | null | undefined>();
    const [selectedClass, setSelectedClass] = useState<USEFSection | null | undefined>();

    const formatClasses = (classList: USEFSection[]) => {
        let formattedClasses = [];
        for (var i = 0; i < classList.length; i++) {
            const label = classList[i].SectionCd + " - " + classList[i].SectionName;
            let object = {
                value: classList[i].SectionCd ? classList[i].SectionCd.toString() : "",
                label: label,
                object: classList[i]
            };
            formattedClasses.push(object);
        }
        setFormattedClasses(formattedClasses);
    }

    useEffect(() => {
        const getUSEFClassesList = async () => {
            const queryResult = await getUSEFSections();
            if (queryResult.isSuccess) {
                const list: USEFSection[] = queryResult.result;
                formatClasses(list);
            } 
        }
        
        async function getClasses() {
            await getUSEFClassesList();
        }
        getClasses();
    }, []);

    const handleOnChange = (event: any) => {
        if (event) {
            setSelectedClass(event);
            onSelect(event);
        } else {
            setSelectedClass(undefined);
        }
    }

    return (
        <>
            {formattedClasses ?
                <BasicSelect formattedValues={formattedClasses} selectedValue={selectedClass?.SectionCd ? selectedClass?.SectionCd.toString() : ""} menuPlacement={menuPlacement} onSelect={handleOnChange}/>
                :
                <p>Loading options...</p>
            }
        </>
    );
};

export default SelectUSEFClasses;
