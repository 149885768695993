import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Spinner from "../Spinners/Spinner";
import ErrorAlert from "../Errors/ErrorAlert";
import { Barn } from "../../models";
import { CreateBarnMemberInput, CreatePersonInput } from "../../API";
import { createPerson } from "../../utilities/person/Person";
import { formattedPerson } from "../../interfaces/Person";
import { savePersonRoles } from "../../utilities/person/FormattedPerson";
import { formatRolesStringForUserAttribute } from "../../utilities/roles/FormatRoles";
import { PersonContext } from "../../context/PersonContext";
import { createBarnMember } from "../../utilities/barnMember/BarnMember";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    barn?: Barn
    onSubmit: Function
}

const CreateBarnPersonForm: React.FC<_Props> = ({barn, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedBarn, setSelectedBarn] = useState<Barn | null | undefined>();
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [isRider, setIsRider] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [isTrainer, setIsTrainer] = useState(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (barn) setSelectedBarn(barn);
    }, [barn]);

    const verifyForm = () => {
        if (!firstName) {
            setError("Please include the first name.");
            return false;
        }
        if (!lastName) {
            setError("Please include the last name.");
            return false;
        }
        return true;
    }

    const formatRoles = () => {
        let roleNames: string[] = [];
        if (isRider) roleNames.push("Rider");
        if (isOwner) roleNames.push("Horse Owner");
        if (isTrainer) roleNames.push("Trainer or Coach");

        const result = formatRolesStringForUserAttribute(roleNames);
        return result;
    }

    const handleCreatePerson = async () => {
        const formattedRoles = formatRoles();

        // First, create the new person in the system
        const createPersonInput: CreatePersonInput = {
            firstName: firstName,
            lastName: lastName,
            email: emailAddress || " ",
            roles: formattedRoles || ""
        };
        const createPersonResult = await createPerson(createPersonInput);
        if (createPersonResult.isSuccess) {
            const newPerson = createPersonResult.result;

            // Next, handle adding any Rider, Owner, Trainer records for this person
            const formattedNewPerson: formattedPerson = {
                name: firstName + " " + lastName,
                id: "",
                ids: [],
                roles: formattedRoles || "",
                location: ""
            };
            const roleResult = await savePersonRoles(newPerson, user, formattedNewPerson, isRider, isOwner, isTrainer, false, selectedBarn?.id);
            if (roleResult.isSuccess) {

                // Create the BarnMember for this person
                if (barn) {
                    const barnMemberInput: CreateBarnMemberInput = {
                        barnId: barn?.id,
                        name: firstName + " " + lastName,
                        personId: newPerson.id,
                        permissionLevel: "member",
                        roles: formattedRoles
                    };
                    await createBarnMember(barnMemberInput);
                }

                onSubmit();
            } else {
                setError(roleResult.message);
            }
        } else {
            setError("Error: could not create the person");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) await handleCreatePerson();
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {isLoading ?
                <Spinner />
                :
                <form>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">First Name <RequiredInputIndicator/></IonLabel>
                                <IonInput 
                                    type="text"
                                    value={firstName}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setFirstName(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Last Name <RequiredInputIndicator/></IonLabel>
                                <IonInput 
                                    type="text"
                                    value={lastName}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setLastName(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Email</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={emailAddress}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setEmailAddress(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonLabel position="stacked">Roles <RequiredInputIndicator/></IonLabel>
                            <p>Select as many as apply.</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem>
                                <IonLabel>Rider</IonLabel>
                                <IonCheckbox slot="start" value="rider" checked={isRider} onIonChange={e => setIsRider(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem>
                                <IonLabel>Owner</IonLabel>
                                <IonCheckbox slot="start" value="owner" checked={isOwner} onIonChange={e => setIsOwner(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem>
                                <IonLabel>Trainer</IonLabel>
                                <IonCheckbox slot="start" value="trainer" checked={isTrainer} onIonChange={e => setIsTrainer(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="4" className="ion-text-center">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    Create
                                </IonButton>
                            }
                        </IonCol>
                    </IonRow>
                </form>
            }
        </>
    );
};

export default CreateBarnPersonForm;