import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import SelectState from "./SelectState";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import SelectCountry from "./SelectCountry";
import SelectAddressType from "./SelectAddressType";
import { createAddress, getAddressById, updateAddress } from "../../utilities/address/Address";
import ErrorAlert from "../Errors/ErrorAlert";
import { Address, Person, PersonalInformation } from "../../models";
import { createAddressName } from "../../utilities/address/FormatAddress";
import { CreateAddressInput, CreatePersonalInformationInput, UpdateAddressInput, UpdatePersonInput, UpdatePersonalInformationInput } from "../../API";
import { createPersonalInformation, getPersonalInformationByPersonId, updatePersonalInformation } from "../../utilities/personalInformation/PersonalInformation";
import { updatePerson } from "../../utilities/person/Person";

interface _Props {
    preselectedType?: string
    onSubmit: Function
}

const UserAddressForm: React.FC<_Props> = ({preselectedType, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");

    const [currentPersonalInformation, setCurrentPersonalInformation] = useState<PersonalInformation | null | undefined>();
    const [currentAddress, setCurrentAddress] = useState<Address | null | undefined>();

    const [streetAddressLine1, setStreetAddressLine1] = useState<string>("");
    const [streetAddressLine2, setStreetAddressLine2] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [provState, setProvState] = useState<string>("");
    const [zip, setZip] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [type, setType] = useState<string>("-");

    const getDataFromAddress = (address: Address) => {
        if (address.type === "mailing") {
            if (address.streetAddress1) setStreetAddressLine1(address.streetAddress1);
            if (address.streetAddress2) setStreetAddressLine2(address.streetAddress2);
            if (address.city) setCity(address.city);
            if (address.provState) setProvState(address.provState);
            if (address.zip) setZip(address.zip);
            if (address.country) setCountry(address.country);
        }
    }

    const getAddressFromUser = async (person: Person) => {
        let personalInformationRecord = person.personalInformation;

        if (!personalInformationRecord) {
            const queryResult = await getPersonalInformationByPersonId(person.id);
            if (queryResult.isSuccess) {
                personalInformationRecord = queryResult.result;
            }
        }

        if (personalInformationRecord) {
            setCurrentPersonalInformation(personalInformationRecord);

            let personAddress = personalInformationRecord.address;
            if (!personAddress && personalInformationRecord.addressId) {
                const addressQueryResult = await getAddressById(personalInformationRecord.addressId);
                if (addressQueryResult.isSuccess) {
                    personAddress = addressQueryResult.result;
                }
            }

            if (personAddress) {
                setCurrentAddress(personAddress);
                getDataFromAddress(personAddress);
            }
        }
    }

    useEffect(() => {
        if (preselectedType) setType(preselectedType);
    }, [preselectedType]);

    useEffect(() => {
        if (user) getAddressFromUser(user);
    }, [user]);

    const handleProvStateInputChange = (value: string) => {
        setIsDisabled(false);
        if (value && value !== "-") setProvState(value);
    }

    const handleCountryInputChange = (value: string) => {
        setIsDisabled(false);
        if (value && value !== "-") setCountry(value);
    }

    const handleAddressTypeInputChange = (value: string) => {
        setIsDisabled(false);
        if (value && value !== "-") setType(value);
    }

    const handleCreateAddress = async () => {
        try {
            const addressInput: CreateAddressInput = {
                name: createAddressName(user.id, type),
                type: type,
                streetAddress1: streetAddressLine1,
                streetAddress2: streetAddressLine2,
                city: city,
                provState: provState,
                zip: zip,
                country: country
            };
            const createResult = await createAddress(addressInput);
            if (createResult.isSuccess) {
                onSubmit(createResult.result);
                return createResult.result;
            } else {
                const message = "Sorry, an error occurred. " + createResult.message;
                setError(message);
            }
        } catch (error: any) {
            const message = "Sorry, an error occurred. The address could not be created.";
            setError(message);
        }
    }

    const handleUpdateAddress = async (currentAddress: Address) => {
        try {
            const addressInput: UpdateAddressInput = {
                id: currentAddress.id,
                name: createAddressName(user.id, type),
                type: type,
                streetAddress1: streetAddressLine1,
                streetAddress2: streetAddressLine2,
                city: city,
                provState: provState,
                zip: zip,
                country: country
            };
            const updateResult = await updateAddress(addressInput);
            if (updateResult.isSuccess) {
                onSubmit(updateResult.result);
            } else {
                const message = "Sorry, an error occurred. " + updateResult.message;
                setError(message);
            }
        } catch (error: any) {
            const message = "Sorry, an error occurred. The address could not be updated.";
            setError(message);
        }
    }

    const handleUpdateUser = async () => {
        if (currentAddress) {
            // Just update the address record
            // This record is already attached to PersonalInformation
            await handleUpdateAddress(currentAddress);
        } else {
            // First, create the new address record
            const newAddress = await handleCreateAddress();

            // Then, try to attach it to the PersonalInformation
            if (newAddress) {
                // Check that this user has a PersonalInformation record already
                if (currentPersonalInformation?.id) {
                    const updatePersonalInfoInput: UpdatePersonalInformationInput = {
                        id: currentPersonalInformation.id,
                        addressId: newAddress.id
                    };
                    const updateResult = await updatePersonalInformation(updatePersonalInfoInput);
                    if (!updateResult.isSuccess) {
                        const message = "An error occurred. The address could not be added to your profile.";
                        setError(message);
                    }
                } 
                // If the user does not have a PersonalInformation record, add one
                else if (user) {
                    const createPersonalInfoInput: CreatePersonalInformationInput = {
                        personId: user.id,
                        addressId: newAddress.id
                    };
                    const createPersonalInfoResult = await createPersonalInformation(createPersonalInfoInput);
                    if (createPersonalInfoResult.isSuccess) {
                        // Then, update the person record to include the new personal info record
                        const newPersonalInformationRecord = createPersonalInfoResult.result;
                        const updatePersonInput: UpdatePersonInput = {
                            id: user.id,
                            personalInformationId: newPersonalInformationRecord.id
                        };
                        const updatePersonResult = await updatePerson(updatePersonInput);
                        if (!updatePersonResult.isSuccess) {
                            const message = "An error occurred. The address could not be added to your profile.";
                            setError(message);
                        }
                    } else {
                        const message = "An error occurred. The address could not be added to your profile.";
                        setError(message);
                    }
                }
            }
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        await handleUpdateUser();
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonItem color="white">
                            <IonLabel position="stacked">Street Address Line 1</IonLabel>
                            <IonInput 
                                type="text"
                                value={streetAddressLine1}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setStreetAddressLine1(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonItem color="white">
                            <IonLabel position="stacked">Street Address Line 2</IonLabel>
                            <IonInput 
                                type="text"
                                value={streetAddressLine2}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setStreetAddressLine2(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonItem color="white">
                            <IonLabel position="stacked">City</IonLabel>
                            <IonInput 
                                type="text"
                                value={city}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setCity(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6">
                        <SelectState selectedValue={provState} onInputChange={handleProvStateInputChange} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonItem color="white">
                            <IonLabel position="stacked">Postal Code</IonLabel>
                            <IonInput 
                                type="text"
                                value={zip}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setZip(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6">
                        <SelectCountry countryValue={country} onInputChange={handleCountryInputChange} />
                    </IonCol>
                </IonRow>
                {!preselectedType && (
                    <IonRow>
                        <IonCol>
                            <SelectAddressType onInputChange={handleAddressTypeInputChange} />
                        </IonCol>
                    </IonRow>
                )}
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default UserAddressForm;