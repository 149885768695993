import { Alert } from "../../models";

var moment = require("moment");

/**
 * Sorts the alerts so that the first element is the oldest and the last element is the newest
 * 
 * @param data 
 * @returns alerts sorted oldest to newest
 */
export function sortAlertsByOldest(data: Alert[]) {
    try {
        let result: Alert[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => new moment(a.createdOn).format("YYYYMMDDHHmmss") - new moment(b.createdOn).format("YYYYMMDDHHmmss"));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: alert");
        console.error(error);
        return null;
    }
};

/**
 * Sorts the alerts so that the first element is the newest and the last element is the oldest
 * 
 * @param data 
 * @returns alerts sorted newest to oldest
 */
 export function sortAlertsByNewest(data: Alert[]) {
    try {
        let result: Alert[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => new moment(b.createdOn).format("YYYYMMDDHHmmss") - new moment(a.createdOn).format("YYYYMMDDHHmmss"));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: alert");
        console.error(error);
        return null;
    }
};