import {
    IonCol,
    IonDatetime,
    IonItem,
    IonLabel,
    IonRow,
    isPlatform,
} from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatetimeClass from "react-datetime";
import { FormGroup } from "reactstrap";
import { isWindows } from "../../utilities/platform/Platform";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    onChange: Function
    label?: string
    isRequired?: boolean
    value?: string
}

const DatePicker: React.FC<_Props> = ({label, onChange, isRequired, value}) => {

    const today = moment(new Date()).format("YYYY-MM-DD");
    const [date, setDate] = useState<string>(value || today);

    useEffect(() => {
        if (value) setDate(value);
    }, [value]);

    const handleMobileDateChange = (value: any) => {
        const formattedDate = moment(value).format("YYYY-MM-DD")
        setDate(formattedDate); 
        onChange(formattedDate);
    }

    const handleReactDatetimeChange = (who: string, date: any) => {
        const currentDate = moment(date).format("YYYY-MM-DD");
        setDate(currentDate);
        onChange(currentDate);
    }

    const getClassNameReactDatetimeDays = (currentDate: string) => {
        const formattedDate = moment(currentDate).format("YYYY-MM-DD");
        if (date === formattedDate) {
            return " middle-date";
        }
        return "";
    };

    return (
        <>
            {(isWindows() || (isPlatform("desktop"))) ?
                <IonRow className="input-daterange datepicker align-items-center">
                    <IonCol sizeMd="6" sizeXs="12">
                        <FormGroup>
                            <IonLabel className="form-control-label pl-3">
                                {label || "Date"}{" "}
                                {isRequired && <RequiredInputIndicator />}
                            </IonLabel>
                            <ReactDatetimeClass
                                inputProps={{
                                    placeholder: "MM/DD/YYYY",
                                    className: "date-picker"
                                }}
                                value={moment(date)}
                                timeFormat={false}
                                onChange={e =>
                                    handleReactDatetimeChange("date", e)
                                }
                                renderDay={(props, currentDate, selectedDate) => {
                                    let classes = props.className;
                                    classes += getClassNameReactDatetimeDays(currentDate);
                                    return (
                                        <td {...props} className={classes}>
                                            {currentDate.date()}
                                        </td>
                                    );
                                }}
                            />
                        </FormGroup>
                    </IonCol>
                </IonRow>
                :
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeMd="6" sizeXs="12" className="ion-no-padding">
                        <IonItem>
                            <IonLabel className="text-darker">
                                {label || "Date"}{" "}
                                {isRequired && <RequiredInputIndicator />}
                            </IonLabel>
                            <IonDatetime value={date} onIonChange={(e) => handleMobileDateChange(e.detail.value!)}></IonDatetime>
                        </IonItem>
                    </IonCol>
                </IonRow>
            }
        </>
    );
};

export default DatePicker;
