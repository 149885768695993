export function isFacebookPageURLValid(url: string) {
    const isFacebookURL = url.includes("facebook.com");
    if (!isFacebookURL) {
        throw new Error("Please include the facebook.com portion of the URL");
    }
}

export function formatURL(urlString: string) {
    let formattedURL = urlString;
    if (!/^(?:f|ht)tps?:\/\//.test(urlString)) {
        formattedURL = "https://" + formattedURL;
    }
    return formattedURL; 
}