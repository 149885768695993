import React, { useEffect, useState } from "react";
import { OrganizationMembershipType } from "../../models";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    menuPlacement?: (string | null)
    selectedValue?: (string | null)
    membershipTypes?: (OrganizationMembershipType[] | null)
    onSelect: Function
    isDisabled?: boolean
}

const SelectOrganizationMembershipType: React.FC<_Props> = ({selectedValue, membershipTypes, menuPlacement, onSelect, isDisabled}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedMemberships, setFormattedMemberships] = useState<formattedOption[] | null | undefined>();

    const formatMemberships = (membershipTypes: OrganizationMembershipType[]) => {
        let formattedMemberships = [];
        for (var i = 0; i < membershipTypes.length; i++) {
            const membershipType = membershipTypes[i];
            const value = membershipType.id;
            const label = (membershipType.name || "error");
            let object: formattedOption = {
                value: value,
                label: label,
                object: membershipTypes[i]
            };
            formattedMemberships.push(object);
        }
        setFormattedMemberships(formattedMemberships);
    }

    useEffect(() => {
        if (membershipTypes) formatMemberships(membershipTypes);
    }, [membershipTypes]);

    useEffect(() => {
        if (selectedValue) setSelected(selectedValue);
        else setSelected("");
    }, [selectedValue]);

    const handleOnChange = (membershipType?: any) => {
        if (membershipType) {
            onSelect(membershipType);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedMemberships ?
                <BasicSelect formattedValues={formattedMemberships}  menuPlacement={menuPlacement} selectedValue={selected} onSelect={handleOnChange} isDisabled={isDisabled} />
                :
                <p>Loading options...</p>
            }
        </>
    )
};

export default SelectOrganizationMembershipType;
