import { IonChip, IonCol, IonLabel, IonRow, IonText} from "@ionic/react";
import React, { useContext } from "react";
import { PersonContext } from "../../context/PersonContext";
import { formatRolesForDisplay } from "../../utilities/roles/FormatRoles";
import { Role } from "../../utilities/roles/Roles";

const ProfileRoles: React.FC = () => {
    const user = useContext(PersonContext);
    return (
        <>
            {user.roles ? (
                <IonRow>
                    
                    {formatRolesForDisplay(user.roles ? user.roles : "") ?
                        <>
                            {formatRolesForDisplay(user.roles ? user.roles : "")?.map((role: Role) => (
                                <IonCol key={role.id}>
                                    <IonChip color={role.color}>
                                        <IonLabel>{role.name}</IonLabel>
                                    </IonChip>
                                </IonCol>
                            ))}
                        </>
                        :
                        <IonText>You currently do not have any roles.</IonText>
                    }
                </IonRow>
            )
            :
            <p>Please go to "Personal Information" and update your roles.</p>
            }
        </>
    );
};

export default ProfileRoles;
