export const listBasicEventClassEntrys = /* GraphQL */ `
  query ListEventClassEntrys(
    $filter: ModelEventClassEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventClassEntrys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventClassId
        eventEntryId
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;

export const getEventClassEntryByEventByClassByEntry = /* GraphQL */ `
  query GetEventClassEntryByEventByClassByEntry($eventId: ID!, $classId: ID!, $entryId: ID!) {
    getEventClassEntryByEventByClassByEntry(eventId: $eventId, classId: $classId, entryId: $entryId) {
      id
      eventId
      eventClassId
      eventEntryId
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;

export const eventClassEntriesCountByEventClassIdByStatus = /* GraphQL */ `
  query EventClassEntriesByEventClassIdByStatus(
    $eventClassId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventClassEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassEntriesByEventClassIdByStatus(
      eventClassId: $eventClassId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventClassId
        eventEntryId
        riderId
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;

export const eventClassEntriesWithEventClassByEventEntryId = /* GraphQL */ `
  query EventClassEntriesByEventEntryId(
    $eventEntryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventClassEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassEntriesByEventEntryId(
      eventEntryId: $eventEntryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          class {
            id
            name
            nickname
            divisionId
            type
            description
            discipline
            usefNumber
            ecNumber
            ecClassTypeNumber
            ecClassType
            code
            specs
            judgedOn
            createdBy
            lastUpdatedBy
            createdOn
            updatedOn
          }
          eventDivisionId
          eventDivision {
            id
            eventId
            name
            divisionId
            organizationDivisionId
            entryFee
            prizeMoney
            minNumberEntriesPerClass
            maxNumberEntriesPerClass
            isCaliforniaSplit
            createdBy
            lastUpdatedBy
            createdOn
            updatedOn
          }
          organizationClassId
          organizationClass {
            id
            name
            classId
            organizationId
            organizationDivisionId
            hasChampionship
            type
            number
            entryFee
            prizeMoney
            minNumberEntries
            maxNumberEntries
            estimatedTimePerRound
            createdBy
            displayOrder
            lastUpdatedBy
            createdOn
            updatedOn
          }
          organizationDivisionId
          organizationDivision {
            id
            name
            divisionId
            organizationId
            minNumberEntries
            maxNumberEntries
            entryFee
            prizeMoney
            entryFeePerClass
            prizeMoneyPerClass
            createdBy
            lastUpdatedBy
            displayOrder
            createdOn
            updatedOn
          }
          pointTable {
            id
            name
            description
            organizationId
            discipline
            createdBy
            lastUpdatedBy
            createdOn
            updatedOn
          }
          prizeMoneyTable {
            id
            name
            description
            displayOrder
            organizationId
            discipline
            isPercentage
            totalPot
            createdBy
            lastUpdatedBy
            createdOn
            updatedOn
          }
          type
          number
          entryFee
          fees {
            nextToken
          }
          prizeMoney
          entries {
            nextToken
          }
          currentNumberEntries
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          jumpHeight
          isScheduled
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventEntryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
        }
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;