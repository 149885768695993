import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRouterLink,
    IonRow,
    IonText,
} from "@ionic/react";
import React, {useRef, useState} from "react";

import {forgotPassword, resetPassword} from "../../utilities/auth/ForgotPasswordUtil";

import AuthNavbar from "../../components/Navbars/AuthNavbar";
import ErrorAlert from "../../components/Errors/ErrorAlert";
import Footer from "../../components/Footers/Footer";
import { useHistory } from "react-router";
import { logIn } from "../../utilities/auth/LoginUtil";
import Spinner from "../../components/Spinners/Spinner";
import InformationBanner from "../../components/Banners/InformationBanner";
import { informationCircleOutline } from "ionicons/icons";
import RequiredInputIndicator from "../../components/Forms/RequiredInputIndicator";


const ForgotPasswordPage: React.FC = () => {
    const history = useHistory();

    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [isEmailDisabled, setIsEmailDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const codeInputRef = useRef<HTMLIonInputElement>(null);
    const passwordInputRef = useRef<HTMLIonInputElement>(null);

    const validateEmailForm = () => {
    // Using a type assertion to ensure that the inputs contain strings
        if (!email) {
            setError("Please enter your email address below.");
            return false;
        }
        setError("");
        return true;
    };

    const handleSendResetCode = async () => {
        try {
            // Validate the form fields
            const isValid = validateEmailForm();
            if (!isValid) return;

            const forgotPasswordResult = await forgotPassword(email);
            if (forgotPasswordResult.isSuccess) {
                setIsEmailDisabled(true);
            } else {
                setError(forgotPasswordResult.message);
            }
        } catch (error: any) {
            setError(error);
        }
    };

    const validateNewPasswordForm = () => {
        // Using a type assertion to ensure that the inputs contain strings
        const code = (codeInputRef.current!.value as string);
        const password = (passwordInputRef.current!.value as string);

        if (!email) {
            setError("Please enter your email address.");
            return false;
        }
        if (!code) {
            setError("Please enter your code.");
            return false;
        }
        if (!password) {
            setError("Please enter a new password.");
            return false;
        }
        if (password && password.length < 8) {
            setError("Password must have 8 characters or more.");
            return false;
        }
        setError("");
        return true;
    };

    const handlePasswordReset = async () => {
        try {
            setIsLoading(true);
            // Validate the form fields
            const isValid = validateNewPasswordForm();
            if (!isValid) {
                setIsLoading(false);
                return;
            }

            // Using a type assertion to ensure that the inputs contain strings
            const code = (codeInputRef.current!.value as string);
            const password = (passwordInputRef.current!.value as string);

            const resetPasswordResult = await resetPassword(email, code, password);
            if (resetPasswordResult && resetPasswordResult.isSuccess) {
                const logInResult = await logIn(email, password);
                if (logInResult.isSuccess) {
                    navigateToDashboard();
                } else setError(logInResult.message);
            }
            if (!(resetPasswordResult && resetPasswordResult.isSuccess)) setError(resetPasswordResult.message);
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
        setIsLoading(false);
    };

    const navigateToDashboard = () => {
        history.push("/index/home");
        window.location.reload();
    }

    return (
        <IonPage>
            <IonContent color="primary">
                <AuthNavbar />
                <section className="section bg-primary">
                    <div>
                        <IonRow class="ion-justify-content-center ion-padding-bottom">
                            <IonCol sizeSm="8" sizeMd="6">
                                <IonCard color="light">
                                    <IonCardHeader className="ion-margin-top">
                                        <IonCardTitle color="primary" className="ion-text-center h3">
                                            Forgot Password
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        {error && (
                                            <ErrorAlert width="12" error={error} />
                                        )}
                                        {isEmailDisabled ? 
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeSm="12" sizeMd="8" className="ion-no-padding">
                                                    <IonText>
                                                        <IonIcon icon={informationCircleOutline}/> 
                                                        Check your email (including spam and promotions folders) for a 6 digit code.
                                                    </IonText>
                                                </IonCol>
                                            </IonRow>
                                            :
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeSm="12" sizeMd="8" className="ion-no-padding">
                                                    <IonText>
                                                        <IonIcon icon={informationCircleOutline}/> 
                                                        First, enter the email address for your account. Then, click the button and we will email you a code. 
                                                    </IonText>
                                                </IonCol>
                                            </IonRow>
                                        }
                                        {isLoading ?
                                            <Spinner />
                                            :
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeSm="12" sizeMd="8">
                                                    <form>
                                                        <IonItem color="light">
                                                            <IonLabel position="stacked">Email Address <RequiredInputIndicator/></IonLabel>
                                                            <IonInput 
                                                                id="reset-password-email-input"
                                                                type="email"
                                                                required={true}
                                                                aria-required={true}
                                                                value={email}
                                                                disabled={isEmailDisabled}
                                                                onIonChange={e => {
                                                                    setEmail(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                        {isEmailDisabled && (
                                                            <>
                                                                <IonItem color="light">
                                                                    <IonLabel position="stacked">6 Digit Code <RequiredInputIndicator/></IonLabel>
                                                                    <IonInput 
                                                                        id="reset-password-code-input"
                                                                        required={true}
                                                                        minlength={6}
                                                                        maxlength={6}
                                                                        aria-required={true}
                                                                        ref={codeInputRef} 
                                                                    />
                                                                </IonItem>
                                                                <IonItem color="light">
                                                                    <IonLabel position="stacked">New Password <RequiredInputIndicator/></IonLabel>
                                                                    <IonInput 
                                                                        id="new-password-input"
                                                                        type="password"
                                                                        minlength={8}
                                                                        required={true}
                                                                        aria-required={true}
                                                                        ref={passwordInputRef} 
                                                                    />
                                                                </IonItem>
                                                                <p className="description text-light">Password must be 8 characters or longer.</p>
                                                            </>
                                                        )}
                                                        <IonButton
                                                            id="registerBtn"
                                                            className="ion-margin-top"
                                                            color="tertiary"
                                                            expand="block"
                                                            onClick={isEmailDisabled ? handlePasswordReset : handleSendResetCode}
                                                        >
                                                            {isEmailDisabled ? "Reset Password" : "Send Me A Code"}
                                                        </IonButton>
                                                    </form>
                                                </IonCol>
                                            </IonRow>
                                        }
                                        <hr />
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol sizeSm="12" sizeMd="8" className="ion-text-center">
                                                <IonText>
                                                    Are you new?{" "}
                                                    <IonRouterLink routerLink="/register">
                                                        <span className="link">Create an Account.</span>
                                                    </IonRouterLink>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </div>
                </section>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default ForgotPasswordPage;
