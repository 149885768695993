import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventJudgeInput, CreateEventJudgeMutation, DeleteEventJudgeInput, DeleteEventJudgeMutation, GetEventJudgeQuery, ListEventJudgesQuery, UpdateEventJudgeInput, UpdateEventJudgeMutation } from "../../API";
import { EventJudge } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventJudge. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventJudge.
*/
export async function createEventJudge(input: CreateEventJudgeInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "EventJudge", "No input", "Create EventJudge received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventJudge, { input: input }))) as GraphQLResult<CreateEventJudgeMutation>;
        else result = (await API.graphql({
            query: mutations.createEventJudge,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<CreateEventJudgeMutation>;
        const eventJudge = result.data?.createEventJudge;
        return formatResult(true, "EventJudge", eventJudge, "Successfully created the eventJudge.");
    } catch (error: any) {
        return formatResult(false, "EventJudge", error, "Error creating record in the database for type: eventJudge");
    }
}

/**
* Handle updating a new record in the database for type: eventJudge. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventJudge.
*/
export async function updateEventJudge(input: UpdateEventJudgeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventJudge", "No input", "Update EventJudge received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventJudge, { input: input }))) as GraphQLResult<UpdateEventJudgeMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventJudge,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventJudgeMutation>;
       const eventJudge = result.data?.updateEventJudge;
       return formatResult(true, "EventJudge", eventJudge, "Successfully updated the eventJudge.");
   } catch (error: any) {
       return formatResult(false, "EventJudge", error, "Error updating record in the database for type: eventJudge");
   }
}

/**
* Handle deleting a new record in the database for type: eventJudge. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventJudge.
*/
export async function deleteEventJudge(input: DeleteEventJudgeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventJudge", "No input", "Delete EventJudge received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventJudge, { input: input }))) as GraphQLResult<DeleteEventJudgeMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventJudge,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventJudgeMutation>;
       const eventJudge = result.data?.deleteEventJudge;
       return formatResult(true, "EventJudge", eventJudge, "Successfully deleted the eventJudge.");
   } catch (error: any) {
       return formatResult(false, "EventJudge", error, "Error deleting record in the database for type: eventJudge");
   }
}

/**
* Get all records in the database for type: eventJudge. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventJudge object
*/
export async function getAllEventJudges(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventJudges,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventJudgesQuery>;

        let items = result.data?.listEventJudges?.items as EventJudge[];
        let nextToken = result.data?.listEventJudges?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventJudges,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventJudgesQuery>;

            const nextItems = nextResult.data?.listEventJudges?.items as EventJudge[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventJudges?.nextToken;
        }

        return formatResult(true, "EventJudge", items, "Successfully got the EventJudges.");
    } catch (error: any) {
        return formatResult(false, "EventJudge", error, "Error reading record in the database for type: eventJudges");
    }
}

/**
* Read a specific record in the database for type: eventJudge. 
* 
* @param {string}  id                  The eventJudge id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventJudge object
*/
export async function getEventJudgeById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventJudge,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventJudgeQuery>;
       const eventJudge = result.data?.getEventJudge;
       return formatResult(true, "EventJudge", eventJudge, "Successfully got the eventJudge.");
   } catch (error: any) {
       return formatResult(false, "EventJudge", error, "Error reading record in the database for type: eventJudge");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventJudge. 
* 
* @param {string}  name                The state name of the eventJudge.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventJudge object
*/
export async function getEventJudgeByName(name: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {name: {eq: name}};
        const result = (await API.graphql({
            query: queries.listEventJudges,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventJudgesQuery>;

        let items = result.data?.listEventJudges?.items as EventJudge[];
        let nextToken = result.data?.listEventJudges?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventJudges,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventJudgesQuery>;

            const nextItems = nextResult.data?.listEventJudges?.items as EventJudge[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventJudges?.nextToken;
        }        
        const eventJudges = items;
        if (eventJudges && eventJudges[0]) return formatResult(true, "EventJudge", eventJudges[0], "Successfully got the eventJudge.");
        else return formatResult(false, "EventJudge", null, "Could not find the eventJudge.");
    } catch (error: any) {
        return formatResult(false, "EventJudge", error, "Error reading record in the database for type: eventJudge");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventJudge. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventJudge object
*/
export async function getEventJudgesByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventJudges,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventJudgesQuery>;

        let items = result.data?.listEventJudges?.items as EventJudge[];
        let nextToken = result.data?.listEventJudges?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventJudges,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventJudgesQuery>;

            const nextItems = nextResult.data?.listEventJudges?.items as EventJudge[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventJudges?.nextToken;
        }

        return formatResult(true, "EventJudge", items, "Successfully got the EventJudges.");
    } catch (error: any) {
        return formatResult(false, "EventJudge", error, "Error reading record in the database for type: eventJudges");
    }
}