import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateAlertInput, CreateAlertMutation, DeleteAlertInput, DeleteAlertMutation, GetAlertQuery, ListAlertsQuery, UpdateAlertInput, UpdateAlertMutation } from "../../API";
import moment from "moment";
import { sortAlertsByNewest } from "./SortAlerts";
import { Alert } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: alert. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created alert.
*/
export async function createAlert(input: CreateAlertInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Alert", "No input", "Create Alert received no input.");
   try {
        const fullInput: CreateAlertInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createAlert, { input: fullInput }))) as GraphQLResult<CreateAlertMutation>;
       else result = (await API.graphql({
           query: mutations.createAlert,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateAlertMutation>;
       const alert = result.data?.createAlert;
       return formatResult(true, "Alert", alert, "Successfully created the alert.");
   } catch (error: any) {
       return formatResult(false, "Alert", error, "Error creating record in the database for type: alert");
   }
}

/**
* Handle updating a new record in the database for type: alert. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated alert.
*/
export async function updateAlert(input: UpdateAlertInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Alert", "No input", "Update Alert received no input.");
   try {
        const fullInput: UpdateAlertInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateAlert, { input: fullInput }))) as GraphQLResult<UpdateAlertMutation>;
       else result = (await API.graphql({
           query: mutations.updateAlert,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateAlertMutation>;
       const alert = result.data?.updateAlert;
       return formatResult(true, "Alert", alert, "Successfully updated the alert.");
   } catch (error: any) {
       return formatResult(false, "Alert", error, "Error updating record in the database for type: alert");
   }
}

/**
* Handle deleting a new record in the database for type: alert. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted alert.
*/
export async function deleteAlert(input: DeleteAlertInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Alert", "No input", "Delete Alert received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteAlert, { input: input }))) as GraphQLResult<DeleteAlertMutation>;
       else result = (await API.graphql({
           query: mutations.deleteAlert,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteAlertMutation>;
       const alert = result.data?.deleteAlert;
       return formatResult(true, "Alert", alert, "Successfully deleted the alert.");
   } catch (error: any) {
       return formatResult(false, "Alert", error, "Error deleting record in the database for type: alert");
   }
}

/**
* Get all records in the database for type: alert. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the alert object
*/
export async function getAllAlerts(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listAlerts,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListAlertsQuery>;

        let items = result.data?.listAlerts?.items as Alert[];
        let nextToken = result.data?.listAlerts?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listAlerts,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListAlertsQuery>;

            const nextItems = nextResult.data?.listAlerts?.items as Alert[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listAlerts?.nextToken;
        }

        return formatResult(true, "Alert", items, "Successfully got the alerts.");
    } catch (error: any) {
        return formatResult(false, "Alert", error, "Error reading record in the database for type: alerts");
    }
}

/**
* Read a specific record in the database for type: alert. 
* 
* @param {string}  id                  The alert id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the alert object
*/
export async function getAlertById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getAlert,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetAlertQuery>;
       const alert = result.data?.getAlert;
       return formatResult(true, "Alert", alert, "Successfully got the alert.");
   } catch (error: any) {
       return formatResult(false, "Alert", error, "Error reading record in the database for type: alert");
   }
}

/**
* Get all records in the database that match the given criteria for type: alert. 
* 
* @param {string}  personId            The person id of the "owner" of the alert
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the alert object
*/
export async function getAlertsByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {createdBy: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listAlerts,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListAlertsQuery>;
        
        let items = result.data?.listAlerts?.items as Alert[];
        const nextToken = result.data?.listAlerts?.nextToken;

        if (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listAlerts,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListAlertsQuery>;

            const nextItems = nextResult.data?.listAlerts?.items as Alert[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }
        }

        return formatResult(true, "Alert", items, "Successfully got the alerts.");
    } catch (error: any) {
        return formatResult(false, "Alert", error, "Error reading record in the database for type: alerts");
    }
}

/**
* Get all records in the database that match the given criteria for type: alert. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the alert object
*/
export async function getAlertsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.alertsByEventId,
            variables: {
                limit: 1000,
                eventId: eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.alertsByEventId?.items as Alert[];
        let nextToken = result.data?.alertsByEventId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.alertsByEventId,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.alertsByEventId?.items as Alert[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.alertsByEventId?.nextToken;
        }

        const sorted = sortAlertsByNewest(items);
        if (sorted) {
            return formatResult(true, "Alert", sorted, "Successfully got the alerts.");
        } else {
            return formatResult(true, "Alert", items, "Successfully got the alerts.");
        }
    } catch (error: any) {
        return formatResult(false, "Alert", error, "Error reading record in the database for type: alerts");
    }
}

/**
* Get all records in the database that match the given criteria for type: alert. 
* 
* @param {string}  organizationId      The organization id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the alert object
*/
export async function getAlertsByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.alertsByOrganizationId,
            variables: {
                limit: 1000,
                organizationId: organizationId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.alertsByOrganizationId?.items as Alert[];
        let nextToken = result.data?.alertsByOrganizationId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.alertsByOrganizationId,
                variables: {
                    limit: 100,
                    organizationId: organizationId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.alertsByOrganizationId?.items as Alert[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.alertsByOrganizationId?.nextToken;
        }

        const sorted = sortAlertsByNewest(items);
        if (sorted) {
            return formatResult(true, "Alert", sorted, "Successfully got the alerts.");
        } else {
            return formatResult(true, "Alert", items, "Successfully got the alerts.");
        }
    } catch (error: any) {
        return formatResult(false, "Alert", error, "Error reading record in the database for type: alerts");
    }
}