import {
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../../components/Headers/Header";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { Organization } from "../../../../../../models";
import { getOrganizationById } from "../../../../../../utilities/organization/Organization";
import OrganizationClassExplanationModal from "../../../../../../components/OrganizationClass/OrganizationClassExplanationModel";
import OrganizationClassEditTable from "../../../../../../components/OrganizationClass/OrganizationClassEditTable";
import OrganizationClassClassNavbar from "../../../../../../components/Navbars/OrganizationDivisionClassNavbar";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

const OrganizationClassesPage: React.FC<OrganizationPageProps> = ({match}) => {
    const [organization, setOrganization] = useState<Organization>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOrganization() {
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                setOrganization(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getOrganization();
    }, [match, match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={organization ? organization.name : "Event Settings"} />
                {organization && <OrganizationClassClassNavbar organization={organization} active="classes" />}
                <IonRow className="ion-justify-content-center">
                    {error && <ErrorAlert width="12" error={error} />}
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Organization Classes</IonCardTitle>
                            <IonCardSubtitle><OrganizationClassExplanationModal /></IonCardSubtitle>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeXs="12" sizeMd="12">
                        {organization && (
                            <OrganizationClassEditTable organizationId={organization.id} />
                        )}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default OrganizationClassesPage;