import { TeamMember } from "../../models";

/**
 * Sorts the teamMembers so that the person last names are a -> z
 * 
 * @param data 
 * @returns teamMembers sorted a->z
 */
 export function sortTeamMembersByPersonName(data: TeamMember[]) {
    try {
        let result: TeamMember[] = [];
        if (data && data.length) {
            result = data.sort(function(a: TeamMember, b: TeamMember): number {
                const lastNameResult = (a.person?.lastName || "").localeCompare(b.person?.lastName || "");
                const firstNameResult = (a.person?.firstName || "").localeCompare(b.person?.firstName || "");
                return lastNameResult || firstNameResult;
            });
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: TeamMember");
        console.error(error);
        return null;
    }
};