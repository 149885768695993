import React, { useEffect, useState } from "react";
import ErrorAlert from "../../Errors/ErrorAlert";
import { Event, EventEntry } from "../../../models";
import { RouteComponentProps, useHistory } from "react-router";
import { getEventEntriesByEventId, getEventEntryById } from "../../../utilities/eventEntry/EventEntry";
import { IonButton, IonCol, IonContent, IonIcon, IonPage, IonRow } from "@ionic/react";
import Header from "../../Headers/Header";
import PageTitle from "../../PageTitle/PageTitle";
import { getEventById } from "../../../utilities/events/Event";
import ViewPendingEntryPage from "./ViewPendingEntryPage";
import ViewAcceptedEntryPage from "./ViewAcceptedEntryPage";
import { sortEventEntries } from "../../../utilities/eventEntry/SortEventEntry";
import { chevronBack, chevronForward } from "ionicons/icons";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const ViewEntryPage: React.FC<EventPageProps> = ({match}) => {
    const history = useHistory();

    const [event, setEvent] = useState<Event | null | undefined>();
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [eventEntryNext, setEventEntryNext] = useState<EventEntry | null | undefined>();
    const [error, setError] = useState<string>("");

    async function getNextEventEntry(eventId: string, currentEntry: EventEntry) {
        const queryResult = await getEventEntriesByEventId(eventId);
        if (queryResult.isSuccess) {
            const entryList: EventEntry[] = queryResult.result;
            const sortedEntryList: (EventEntry[] | null) = sortEventEntries(entryList);
            const list = sortedEntryList || entryList;
            if (list) {
                const currentEntryIndex = list.findIndex((entry: EventEntry) => entry.id === currentEntry.id);
                if (currentEntryIndex > -1 && currentEntryIndex < (list.length-1)) {
                    const nextEntry = list[currentEntryIndex + 1];
                    setEventEntryNext(nextEntry);
                }
            } 
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    useEffect(() => {
        async function getEntry() {
            const queryResult = await getEventEntryById(match.params.id);
            if (queryResult.isSuccess) {
                const eventEntry: EventEntry = queryResult.result;
                setCurrentEntry(eventEntry);
                if (eventEntry && eventEntry.eventId) {
                    getEvent(eventEntry.eventId);
                    getNextEventEntry(eventEntry.eventId, eventEntry);
                }
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        async function getEvent(eventId: string) {
            const queryResult = await getEventById(eventId);
            if (queryResult.isSuccess) {
                setEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getEntry();
    }, [match, match.params.id]);

    const handleNavigateToNextEntry = async () => {
        if (event && eventEntryNext) {
            const path = "/index/staff/event/entry/" + eventEntryNext.id;
            history.push(path);
        }
    }

    const handleNavigateToAllEntries = async () => {
        if (event) {
            const path = "/index/staff/event/" + event.id + "/participants";
            history.push(path);
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={((currentEntry) ? (currentEntry.number ? currentEntry.number + " - " : "") + (currentEntry.horseName ? currentEntry.horseName : "") : "Entry")} />
                {eventEntryNext && (
                    <IonRow>
                        <IonCol sizeXs="6" sizeMd="4" className="ion-text-left">
                            <IonButton size="small" color="tertiary" onClick={handleNavigateToAllEntries}><IonIcon icon={chevronBack}/>Back to All Entries</IonButton>
                        </IonCol>
                        <IonCol sizeXs="6" offsetMd="4" sizeMd="4" className="ion-text-right">
                            <IonButton size="small" color="tertiary" onClick={handleNavigateToNextEntry}>Go to Entry {eventEntryNext.number}<IonIcon icon={chevronForward}/></IonButton>
                        </IonCol>
                    </IonRow>  
                )}
                {error && <ErrorAlert width="12" error={error} />}
                {event && currentEntry && (currentEntry.status === "submitted" || currentEntry.status === "in_progress" || currentEntry.status === "declined") && (
                    <ViewPendingEntryPage currentEvent={event} entry={currentEntry}/>
                )}
                {event && currentEntry && currentEntry.status === "complete" && (
                    <ViewAcceptedEntryPage event={event} entry={currentEntry}/>
                )}
            </IonContent>
        </IonPage>
    );
};

export default ViewEntryPage;