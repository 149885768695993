import React, { useEffect, useState } from "react";
import { IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow, IonCol, IonText, IonButton } from "@ionic/react";
import EntryFeeSelectTooltip from "./EntryFeeSelectTooltip";

export enum EntryType {
    "division" = "division",
    "class" = "class",
    "both" = "both"
}

interface _Props {
    selectedEntryType?: EntryType
    onSelect: Function
}

const SelectEntryType: React.FC<_Props> = ({selectedEntryType, onSelect}) => {
    const [isHidden, setIsHidden] = useState(true);
    const [entryType, setEntryType] = useState<EntryType>(EntryType.both);

    useEffect(() => {
        if (selectedEntryType) setEntryType(selectedEntryType);
        else setEntryType(EntryType.both);
    }, [selectedEntryType]);

    const handleOptionSelection = (option: EntryType) => {
        setEntryType(option);
        onSelect(option);
    }
    
    return (
        <>
            {isHidden ?
                <IonText>
                    Allow people to enter by {entryType === "division" && "division only"} {entryType === "class" && "class only"} {entryType === "both" && "division or class"}. <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(false)}>Edit</IonButton>
                </IonText>
                :
                <>
                    <IonRow>
                        <IonCol className="text-center">
                            <EntryFeeSelectTooltip />
                            <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(true)}>Hide</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRadioGroup value={entryType} onIonChange={e => handleOptionSelection(e.detail.value)}>
                        <IonItem color="white">
                            <IonLabel>Allow Entries by Division Only</IonLabel>
                            <IonRadio slot="end" value={"division"} />
                        </IonItem>    
                        <IonItem color="white">
                            <IonLabel>Allow Entries by Class Only</IonLabel>
                            <IonRadio slot="end" value={"class"} />
                        </IonItem> 
                        <IonItem color="white">
                            <IonLabel>Allow Entries by Division or Class</IonLabel>
                            <IonRadio slot="end" value={"both"} />
                        </IonItem>   
                    </IonRadioGroup>
                </>
            }
        </>
    );
};

export default SelectEntryType;
