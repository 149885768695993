import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonToggle,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Event, EventClass, EventClassEntry, EventResult } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import SuccessBanner from "../Banners/SuccessBanner";
import SelectEventClassEntry from "../EventClassEntry/SelectEventClassEntry";
import { getEventClassEntriesByEventClassId } from "../../utilities/eventClassEntry/EventClassEntry";
import { CreateEventResultInput, UpdateEventClassInput } from "../../API";
import { getPrizeMoneyAmountFromTable } from "../../utilities/prizeMoneyTable/PrizeMoney";
import moment from "moment";
import { createEventResult, deleteEventResult, getEventResultsByEventClassId } from "../../utilities/eventResult/EventResult";
import { getEventClassById, updateEventClass } from "../../utilities/eventClass/EventClass";
import { Input, Label } from "reactstrap";

interface _Props {
    eventClass?: EventClass
    canEdit?: boolean
    onSubmit: Function
}

const EventClassKeyInResultsForm: React.FC<_Props> = ({eventClass, canEdit, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [currentClass, setCurrentClass] = useState<EventClass | null | undefined>();
    const [currentClassEntries, setCurrentClassEntries] = useState<EventClassEntry[] | null | undefined>();
 
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    const [viewThroughTwelve, setViewThroughTwelve] = useState(false);

    const [numberEntriesCompeted, setNumberEntriesCompeted] = useState("0");
    const [notes, setNotes] = useState("");

    const [first, setFirst] = useState<string | null | undefined>();
    const [second, setSecond] = useState<string | null | undefined>();
    const [third, setThird] = useState<string | null | undefined>();
    const [fourth, setFourth] = useState<string | null | undefined>();
    const [fifth, setFifth] = useState<string | null | undefined>();
    const [sixth, setSixth] = useState<string | null | undefined>();
    const [seventh, setSeventh] = useState<string | null | undefined>();
    const [eighth, setEighth] = useState<string | null | undefined>();
    const [ninth, setNinth] = useState<string | null | undefined>();
    const [tenth, setTenth] = useState<string | null | undefined>();
    const [eleventh, setEleventh] = useState<string | null | undefined>();
    const [twelfth, setTwelfth] = useState<string | null | undefined>();


    const [firstPlace, setFirstPlace] = useState<EventClassEntry | null | undefined>();
    const [secondPlace, setSecondPlace] = useState<EventClassEntry | null | undefined>();
    const [thirdPlace, setThirdPlace] = useState<EventClassEntry | null | undefined>();
    const [fourthPlace, setFourthPlace] = useState<EventClassEntry | null | undefined>();
    const [fifthPlace, setFifthPlace] = useState<EventClassEntry | null | undefined>();
    const [sixthPlace, setSixthPlace] = useState<EventClassEntry | null | undefined>();
    const [seventhPlace, setSeventhPlace] = useState<EventClassEntry | null | undefined>();
    const [eighthPlace, setEighthPlace] = useState<EventClassEntry | null | undefined>();
    const [ninthPlace, setNinthPlace] = useState<EventClassEntry | null | undefined>();
    const [tenthPlace, setTenthPlace] = useState<EventClassEntry | null | undefined>();
    const [eleventhPlace, setEleventhPlace] = useState<EventClassEntry | null | undefined>();
    const [twelfthPlace, setTwelfthPlace] = useState<EventClassEntry | null | undefined>();

    const [firstPlaceResult, setFirstPlaceResult] = useState<EventResult | null | undefined>();
    const [secondPlaceResult, setSecondPlaceResult] = useState<EventResult | null | undefined>();
    const [thirdPlaceResult, setThirdPlaceResult] = useState<EventResult | null | undefined>();
    const [fourthPlaceResult, setFourthPlaceResult] = useState<EventResult | null | undefined>();
    const [fifthPlaceResult, setFifthPlaceResult] = useState<EventResult | null | undefined>();
    const [sixthPlaceResult, setSixthPlaceResult] = useState<EventResult | null | undefined>();
    const [seventhPlaceResult, setSeventhPlaceResult] = useState<EventResult | null | undefined>();
    const [eighthPlaceResult, setEighthPlaceResult] = useState<EventResult | null | undefined>();
    const [ninthPlaceResult, setNinthPlaceResult] = useState<EventResult | null | undefined>();
    const [tenthPlaceResult, setTenthPlaceResult] = useState<EventResult | null | undefined>();
    const [eleventhPlaceResult, setEleventhPlaceResult] = useState<EventResult | null | undefined>();
    const [twelfthPlaceResult, setTwelfthPlaceResult] = useState<EventResult | null | undefined>();

    async function getEventClassEntries(eventClassId: string) {
        const queryResult = await getEventClassEntriesByEventClassId(eventClassId);
        if (queryResult.isSuccess) {
            setCurrentClassEntries(queryResult.result);
        }
    }

    function setResult(currentEventResult: EventResult) {
        if (currentEventResult.place === 1) setFirstPlaceResult(currentEventResult);
        if (currentEventResult.place === 2) setSecondPlaceResult(currentEventResult);
        if (currentEventResult.place === 3) setThirdPlaceResult(currentEventResult);
        if (currentEventResult.place === 4) setFourthPlaceResult(currentEventResult);
        if (currentEventResult.place === 5) setFifthPlaceResult(currentEventResult);
        if (currentEventResult.place === 6) setSixthPlaceResult(currentEventResult);
        if (currentEventResult.place === 7) setSeventhPlaceResult(currentEventResult);
        if (currentEventResult.place === 8) setEighthPlaceResult(currentEventResult);
        if (currentEventResult.place === 9) setNinthPlaceResult(currentEventResult);
        if (currentEventResult.place === 10) setTenthPlaceResult(currentEventResult);
        if (currentEventResult.place === 11) setEleventhPlaceResult(currentEventResult);
        if (currentEventResult.place === 12) setTwelfthPlaceResult(currentEventResult);
    }

    async function getEventClassResults(eventClassId: string) {
        const queryResult = await getEventResultsByEventClassId(eventClassId);
        if (queryResult.isSuccess) {
            setIsEditing(false);
            const resultsArray: EventResult[] = queryResult.result;
            resultsArray.forEach(currentEventResult => {
                setResult(currentEventResult);
            });
            resultsArray.forEach(currentEventResult => {
                const place = currentEventResult.place || 0;
                const eventClassEntry: EventClassEntry | null | null | undefined = currentEventResult.eventClassEntry;
                if (place && eventClassEntry) {
                    handleSelectedEventClassEntry(place, eventClassEntry);
                }
            });
        }
    }

    useEffect(() => {
        async function setEventClass(eventClass: EventClass) {
            clearAllForm();
            const queryResult = await getEventClassById(eventClass.id);
            if (queryResult.isSuccess) {
                const eventClassResult: EventClass = queryResult.result;
                setCurrentClass(eventClassResult);
                if (eventClassResult && eventClassResult.id) {
                    getEventClassEntries(eventClassResult.id);
                    getEventClassResults(eventClassResult.id);
                }
                if (eventClassResult.currentNumberEntries) {
                    setNumberEntriesCompeted(eventClassResult.currentNumberEntries.toString());
                }
                if (eventClassResult.note) {
                    setNotes(eventClassResult.note);
                }
            } else {
                setError("Could not find the class");
            }
        }
        
        if (eventClass) setEventClass(eventClass);
    }, [eventClass]);

    const verifyForm = () => {
        try {
            return true;
        } catch (error: any) {
            return false;
        }
    }

    const clearPlaces = () => {
        setFirstPlace(undefined);
        setSecondPlace(undefined);
        setThirdPlace(undefined);
        setFourthPlace(undefined);
        setFifthPlace(undefined);
        setSixthPlace(undefined);
        setSeventhPlace(undefined);
        setEighthPlace(undefined);
        setNinthPlace(undefined);
        setTenthPlace(undefined);
        setEleventhPlace(undefined);
        setTwelfthPlace(undefined);
    }

    const clearForm = () => {
        clearPlaces();
        setError("");
    }
    
    const clearAllForm = () => {
        clearPlaces();
        setError("");
        eventClass = undefined;
    }

    const handleSelectedEventClassEntry = (place: (string | number), eventClassEntry: EventClassEntry) => {
        if (place && eventClassEntry) {
            if (place === "first" || place === 1) setFirstPlace(eventClassEntry);
            if (place === "second" || place === 2) setSecondPlace(eventClassEntry);
            if (place === "third" || place === 3) setThirdPlace(eventClassEntry);
            if (place === "fourth" || place === 4) setFourthPlace(eventClassEntry);
            if (place === "fifth" || place === 5) setFifthPlace(eventClassEntry);
            if (place === "sixth" || place === 6) setSixthPlace(eventClassEntry);
            if (place === "seventh" || place === 7) setSeventhPlace(eventClassEntry);
            if (place === "eighth" || place === 8) setEighthPlace(eventClassEntry);
            if (place === "ninth" || place === 9) setNinthPlace(eventClassEntry);
            if (place === "tenth" || place === 10) setTenthPlace(eventClassEntry);
            if (place === "eleventh" || place === 11) setEleventhPlace(eventClassEntry);
            if (place === "twelfth" || place === 12) setTwelfthPlace(eventClassEntry);
        } else if (place) {
            if (place === "first" || place === 1) setFirstPlace(undefined);
            if (place === "second" || place === 2) setSecondPlace(undefined);
            if (place === "third" || place === 3) setThirdPlace(undefined);
            if (place === "fourth" || place === 4) setFourthPlace(undefined);
            if (place === "fifth" || place === 5) setFifthPlace(undefined);
            if (place === "sixth" || place === 6) setSixthPlace(undefined);
            if (place === "seventh" || place === 7) setSeventhPlace(undefined);
            if (place === "eighth" || place === 8) setEighthPlace(undefined);
            if (place === "ninth" || place === 9) setNinthPlace(undefined);
            if (place === "tenth" || place === 10) setTenthPlace(undefined);
            if (place === "eleventh" || place === 11) setEleventhPlace(undefined);
            if (place === "twelfth" || place === 12) setTwelfthPlace(undefined);
        }
        
        setIsDisabled(false);
    }

    const createResult = async (place: number, eventClassEntry: EventClassEntry) => {
        if (eventClassEntry.eventId && currentClass) {
            let prizeMoneyAmount = 0;
            if (currentClass.prizeMoneyTable) {
                const value = await getPrizeMoneyAmountFromTable(currentClass.prizeMoneyTable, place);
                if (value) prizeMoneyAmount = parseFloat(value);
            }
            const input: CreateEventResultInput = {
                eventId: eventClassEntry.eventId,
                entryId: eventClassEntry.eventEntry?.id || "",
                eventResultEntryId: eventClassEntry.eventEntry?.id || "",
                eventClassEntryId: eventClassEntry.id,
                eventResultEventClassEntryId: eventClassEntry.id,
                eventClassId: currentClass.id,
                // eventResultEventClassId: currentClass.id,
                eventClassName: currentClass.name,
                place: place,
                prizeMoney: prizeMoneyAmount,
                createdBy: user.id,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
            const createResult = await createEventResult(input);
            if (createResult.isSuccess) setResult(createResult.result);
            
        } else {
            setError("Could not find the class.");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);

        const isValid = verifyForm();
        if (!isValid) {
            setIsLoading(false);
            return;
        }

        if (firstPlaceResult) await deleteEventResult({id: firstPlaceResult.id});
        if (secondPlaceResult) await deleteEventResult({id: secondPlaceResult.id});
        if (thirdPlaceResult) await deleteEventResult({id: thirdPlaceResult.id});
        if (fourthPlaceResult) await deleteEventResult({id: fourthPlaceResult.id});
        if (fifthPlaceResult) await deleteEventResult({id: fifthPlaceResult.id});
        if (sixthPlaceResult) await deleteEventResult({id: sixthPlaceResult.id});
        if (seventhPlaceResult) await deleteEventResult({id: seventhPlaceResult.id});
        if (eighthPlaceResult) await deleteEventResult({id: eighthPlaceResult.id});
        if (ninthPlaceResult) await deleteEventResult({id: ninthPlaceResult.id});
        if (tenthPlaceResult) await deleteEventResult({id: tenthPlaceResult.id});
        if (eleventhPlaceResult) await deleteEventResult({id: eleventhPlaceResult.id});
        if (twelfthPlaceResult) await deleteEventResult({id: twelfthPlaceResult.id});

        if (firstPlace) await createResult(1, firstPlace);
        if (secondPlace) await createResult(2, secondPlace);
        if (thirdPlace) await createResult(3, thirdPlace);
        if (fourthPlace) await createResult(4, fourthPlace);
        if (fifthPlace) await createResult(5, fifthPlace);
        if (sixthPlace) await createResult(6, sixthPlace);
        if (seventhPlace) await createResult(7, seventhPlace);
        if (eighthPlace) await createResult(8, eighthPlace);
        if (ninthPlace) await createResult(9, ninthPlace);
        if (tenthPlace) await createResult(10, tenthPlace);
        if (eleventhPlace) await createResult(11, eleventhPlace);
        if (twelfthPlace) await createResult(12, twelfthPlace);

        if (currentClass) {
            const updateEventClassInput: UpdateEventClassInput = {
                id: currentClass.id,
                currentNumberEntries: parseInt(numberEntriesCompeted) || 0,
                note: notes
            };
            await updateEventClass(updateEventClassInput);
        }

        setIsLoading(false);
        onSubmit();
    }

    const handleDelete = async () => {
        setIsLoading(true);
        setIsDisabled(true);
        setIsLoading(false);
        onSubmit();
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            <IonRow>
                <IonCol size="1" className="ion-text-right">
                    <Label>1:</Label>
                </IonCol>
                <IonCol size="11">
                    <Input
                        type="text"
                        value={first || ""}
                        onChange={e => {
                            setFirst(e.target.value);
                        }}
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="12">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            Save Results
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
            <IonRow>
                {eventClass && (
                    <>
                        <IonCol sizeMd="4">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    className="ion-margin-top"
                                    color="primary"
                                    expand="block"
                                    onClick={clearForm}
                                >
                                    Reset Form
                                </IonButton>
                            }
                        </IonCol>
                        <IonCol offsetMd="4" sizeMd="4">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    disabled={isDisabled}
                                    className="ion-margin-top"
                                    color="danger"
                                    expand="block"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </IonButton>
                            }
                        </IonCol>
                    </>
                )}
            </IonRow>
        </>
    );
};

export default EventClassKeyInResultsForm;