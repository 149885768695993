import React, { useEffect, useState } from "react";
import { Event, EventEntry } from "../../models";
import EventClassAddToEntryForm from "../EventClass/EventClassAddToEntryForm";

interface _Props {
    event: Event
    entry?: EventEntry
    isQuickAdd?: Boolean
    onSubmit?: Function
}

const EntryClassForm: React.FC<_Props> = ({entry, isQuickAdd, event, onSubmit}) => {

    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();

    useEffect(() => {
        if (entry) {
            setCurrentEntry(entry);
        }
    }, [entry]);


    const handleUpdateEntry = (updated: EventEntry) => {
        setCurrentEntry(updated);
        if (onSubmit) onSubmit(updated);
    }

    return (
        <>
            {currentEntry ?
                <EventClassAddToEntryForm isQuickAdd={isQuickAdd} entry={currentEntry} event={event} onUpdateEntry={(updated: EventEntry) => handleUpdateEntry(updated)} />
                :
                <p>No Entry Found.</p>
            }
        </>
    );
};

export default EntryClassForm;