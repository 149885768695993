import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { close, create } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Organization, OrganizationStallType } from "../../models";
import StallTypeForm from "./StallTypeForm";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { getOrganizationStallTypesByOrganizationId } from "../../utilities/organizationStallType/OrganizationStallType";

interface _Props {
    organization: Organization
}

const OrganizationStallTypeForm: React.FC<_Props> = ({organization}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [organizationStallTypeArray, setOrganizationStallTypeArray] = useState<(OrganizationStallType | null)[] | null | undefined>();
    const [selectedOrganizationStallType, setSelectedOrganizationStallType] = useState<OrganizationStallType | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOrganizationStallTypes(organizationId: string) {
            const queryResult = await getOrganizationStallTypesByOrganizationId(organizationId);
            if (queryResult.isSuccess) {
                setOrganizationStallTypeArray(queryResult.result);
            }
        };
        if (organization) getOrganizationStallTypes(organization.id);
    }, [organization]);

    const handleSubmit = async (isCreate: Boolean, newOrganizationStallType: OrganizationStallType) => {
        setIsLoading(true);
        if (organization) {
            setError("");
            let stallArray: (OrganizationStallType | null)[] = [];
            if (isCreate) {
                if (organizationStallTypeArray && organizationStallTypeArray.length) {
                    stallArray = organizationStallTypeArray.concat([newOrganizationStallType]);
                } else {
                    stallArray.push(newOrganizationStallType);
                }
            } else {
                // Updated the selectedOrganizationStallType
                if (organizationStallTypeArray && organizationStallTypeArray.length) {
                    for (var i = 0; i < organizationStallTypeArray.length; i++) {
                        const organizationStallType = organizationStallTypeArray[i];
                        if (organizationStallType && organizationStallType.id === selectedOrganizationStallType?.id) stallArray.push(newOrganizationStallType);
                        else stallArray.push(organizationStallType);
                    }
                }
            }
            setOrganizationStallTypeArray(stallArray);
        } else {
            setError("Error: no organization found. Please contact hello@ringsidepro.com");
        }
        setIsLoading(false);
        setShowModal(false);
    }

    const handleUpdate = async (organizationStallType?: OrganizationStallType) => {
        if (organizationStallType) {
            setSelectedOrganizationStallType(organizationStallType);
            setShowModal(true);
        }
    }

    const handleAddNew = async () => {
        setSelectedOrganizationStallType(undefined);
        setShowModal(true);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {organizationStallTypeArray && organizationStallTypeArray.length ?
                <>
                    <IonList className="bg-white">
                        {organizationStallTypeArray.map((organizationStallType, index) => (
                            <IonItem key={index}>
                                <IonLabel>
                                    <IonRow>
                                        <IonCol>
                                            <h3 className="font-weight-bold">{organizationStallType && organizationStallType.name}</h3>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <h3>{organizationStallType?.pricePerStall ? "$" + organizationStallType.pricePerStall.toFixed(2) + " / stall" : ""}</h3>
                                        </IonCol>
                                    </IonRow>
                                    {organizationStallType?.taxRateA && (
                                        <IonRow>
                                            <IonCol>
                                                <h3>Tax #1: {organizationStallType.taxRateA.percentage + "%"}</h3>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                    {organizationStallType?.taxRateB && (
                                        <IonRow>
                                            <IonCol>
                                                <h3>Tax #2: {organizationStallType.taxRateB.percentage + "%"}</h3>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                </IonLabel>
                                <IonButton slot="end" color="tertiary" onClick={() => handleUpdate(organizationStallType as OrganizationStallType)}><IonIcon icon={create}/></IonButton>
                            </IonItem>
                        ))}
                    </IonList>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={handleAddNew}>
                                Add Stall Type
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={handleAddNew}>
                                Add Stall Type
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
            <IonModal backdropDismiss={false} isOpen={showModal} id="addOrganizationStallTypeModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Stall Type
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeStallModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <StallTypeForm organization={organization} selectedOrganizationStallType={selectedOrganizationStallType} onSubmit={handleSubmit} />
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default OrganizationStallTypeForm;