import { Organization } from "../../models";
import { chevronDownCircleOutline } from "ionicons/icons";
import { IonIcon, IonPopover, isPlatform } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import { getOrganizationById } from "../../utilities/organization/Organization";
import { useHistory } from "react-router";
import SelectOrganization from "../Organization/SelectOrganization";
import Spinner from "../Spinners/Spinner";
import { OrganizationContext } from "../../context/OrganizationContext";
import CONSTANT from "../../constant/constant";
import { isWindows } from "../../utilities/platform/Platform";
import { getUser, UserAttributes } from "../../utilities/user/User";

interface _Props {
    setOrganizationHandler?: Function
}

const SwitchOrganization: React.FC<_Props> = ({setOrganizationHandler}) => {
    const history = useHistory();
    const user = useContext(PersonContext);
    const organizationCtx = useContext(OrganizationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [organization, setOrganization] = useState<Organization | null>(organizationCtx);
    const [userOrganizations, setUserOrganizations] = useState<Organization[] | null>(null);
    const [popoverState, setShowPopover] = useState({showPopover: false, event: undefined});
    const [error, setError] = useState<string>("");

    useEffect(() => {
        async function getOrganizations() {
            setIsLoading(true);
            const cognitoUser = await getUser();
            const organizations: Organization[] = [];
            organizations.push(CONSTANT.RINGSIDE_PRO_ORGANIZATION);
            // organizations.push(CONSTANT.ADD_NEW_ORGANIZATION);
            if (cognitoUser) {
                const attributes: UserAttributes = cognitoUser.attributes;
                const organizationIds = attributes["custom:organizationids"] ?? "";
                const organizationIdsArray = organizationIds.split(",");
                for (const id of organizationIdsArray) {
                    const queryResult = await getOrganizationById(id);
                    if (queryResult.isSuccess) {
                        organizations.push(queryResult.result);
                    }
                }
                setUserOrganizations(organizations);
                setIsLoading(false);
            }
        }

        getOrganizations();
    }, [user, user.id]);

    useEffect(() => {
        // In case of blank organization name is assigned to empty string.
        if(organizationCtx?.name?.length === 0) {
            setOrganization(CONSTANT.RINGSIDE_PRO_ORGANIZATION);
        } else {
            setOrganization(organizationCtx);
        }
    }, [organizationCtx, organizationCtx.name]);

    const handleOrganizationSelection = (selectedOrganization: Organization) => {
        if(selectedOrganization.id !== CONSTANT.ADD_NEW_ORGANIZATION.id){
            setOrganization(selectedOrganization);
            if(setOrganizationHandler) {
                setOrganizationHandler(selectedOrganization)
            }
        }
        let path = `/index/${selectedOrganization.urlBackHalf}/home`;
        if (selectedOrganization.id === CONSTANT.RINGSIDE_PRO_ORGANIZATION.id) {
            path = "/index/home";
        } else if (selectedOrganization.id === CONSTANT.ADD_NEW_ORGANIZATION.id) {
            path = "/index/staff/organization"; 
        }
        history.push(path);
    }

    return (
        <React.Fragment>
            {error && <ErrorAlert width="12" error={error}/>}
            {isLoading && <Spinner />}
            {(userOrganizations && userOrganizations?.length > 0 && organization) && (
                (isWindows() || (isPlatform("desktop"))) ?
                    <SelectOrganization
                        canClear={false}
                        currentOrganization={organization}
                        organizations={userOrganizations}
                        onInputChange={handleOrganizationSelection}
                    />
                :
                    <div>
                        <IonIcon
                            className="switch-org-icon"
                            slot="start"
                            color="primary"
                            icon={chevronDownCircleOutline}
                            onClick={
                                (e: any) => {
                                    e.persist();
                                    setShowPopover({showPopover: true, event: e});
                                }
                            }
                        />

                        <IonPopover
                            event={popoverState.event}
                            isOpen={popoverState.showPopover}
                            onDidDismiss={() => setShowPopover({showPopover: false, event: undefined})}
                        >
                            <div
                                onClick={() => {
                                    setShowPopover({showPopover: false, event: undefined})
                                }}
                            >
                                <SelectOrganization
                                    currentOrganization={organization}
                                    organizations={userOrganizations}
                                    onInputChange={handleOrganizationSelection}
                                    canClear={false}
                                />
                            </div> 
                        </IonPopover>
                    </div>
                )
            }
        </React.Fragment>
    );
};

export default SwitchOrganization;
