import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateTrainerInput, CreateTrainerMutation, DeleteTrainerInput, DeleteTrainerMutation, GetTrainerQuery, ListTrainersQuery, UpdateTrainerInput, UpdateTrainerMutation } from "../../API";
import moment from "moment";
import { Trainer } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: trainer. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created trainer.
*/
export async function createTrainer(input: CreateTrainerInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Trainer", "No input", "Create Trainer received no input.");
   try {
        const fullInput: CreateTrainerInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createTrainer, { input: fullInput }))) as GraphQLResult<CreateTrainerMutation>;
       else result = (await API.graphql({
           query: mutations.createTrainer,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateTrainerMutation>;
       const trainer = result.data?.createTrainer;
       return formatResult(true, "Trainer", trainer, "Successfully created the trainer.");
   } catch (error: any) {
       return formatResult(false, "Trainer", error, "Error creating record in the database for type: trainer");
   }
}

/**
* Handle updating a new record in the database for type: trainer. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated trainer.
*/
export async function updateTrainer(input: UpdateTrainerInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Trainer", "No input", "Update Trainer received no input.");
   try {
        const fullInput: UpdateTrainerInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateTrainer, { input: fullInput }))) as GraphQLResult<UpdateTrainerMutation>;
       else result = (await API.graphql({
           query: mutations.updateTrainer,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateTrainerMutation>;
       const trainer = result.data?.updateTrainer;
       return formatResult(true, "Trainer", trainer, "Successfully updated the trainer.");
   } catch (error: any) {
       return formatResult(false, "Trainer", error, "Error updating record in the database for type: trainer");
   }
}

/**
* Handle deleting a new record in the database for type: trainer. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted trainer.
*/
export async function deleteTrainer(input: DeleteTrainerInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Trainer", "No input", "Delete Trainer received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteTrainer, { input: input }))) as GraphQLResult<DeleteTrainerMutation>;
       else result = (await API.graphql({
           query: mutations.deleteTrainer,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteTrainerMutation>;
       const trainer = result.data?.deleteTrainer;
       return formatResult(true, "Trainer", trainer, "Successfully deleted the trainer.");
   } catch (error: any) {
       return formatResult(false, "Trainer", error, "Error deleting record in the database for type: trainer");
   }
}

/**
* Get all records in the database for type: trainer. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the trainer object
*/
export async function getAllTrainers(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listTrainers,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTrainersQuery>;
        
        let items = result.data?.listTrainers?.items as Trainer[];
        let nextToken = result.data?.listTrainers?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listTrainers,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTrainersQuery>;

            const nextItems = nextResult.data?.listTrainers?.items as Trainer[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTrainers?.nextToken;
        }

        return formatResult(true, "Trainer", items, "Successfully got the Trainers.");
    } catch (error: any) {
        return formatResult(false, "Trainer", error, "Error reading record in the database for type: trainers");
    }
}

/**
* Read a specific record in the database for type: trainer. 
* 
* @param {string}  id                  The trainer id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the trainer object
*/
export async function getTrainerById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getTrainer,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetTrainerQuery>;
       const trainer = result.data?.getTrainer;
       return formatResult(true, "Trainer", trainer, "Successfully got the trainer.");
   } catch (error: any) {
       return formatResult(false, "Trainer", error, "Error reading record in the database for type: trainer");
   }
}

/**
* Get all records in the database that match the given criteria for type: trainer. 
* 
* @param {string}  personId            The person id of the trainer.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the trainer object
*/
export async function getTrainerByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listTrainers,
            variables: {
                limit: 10,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTrainersQuery>;
        
        let items = result.data?.listTrainers?.items as Trainer[];
        let nextToken = result.data?.listTrainers?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listTrainers,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTrainersQuery>;

            const nextItems = nextResult.data?.listTrainers?.items as Trainer[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTrainers?.nextToken;
        }

        const trainers = items;        
        if (trainers && trainers[0]) return formatResult(true, "Trainer", trainers[0], "Successfully got the trainer.");
        else return formatResult(false, "Trainer", null, "Could not find the trainer.");
    } catch (error: any) {
        return formatResult(false, "Trainer", error, "Error reading record in the database for type: trainer");
    }
}

/**
* Get all records in the database that match the given criteria for type: trainer. 
* 
* @param {string}  personId            The person id of the trainer.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the trainer object
*/
export async function getTrainersByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listTrainers,
            variables: {
                limit: 10,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTrainersQuery>;
        
        let items = result.data?.listTrainers?.items as Trainer[];
        let nextToken = result.data?.listTrainers?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listTrainers,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTrainersQuery>;

            const nextItems = nextResult.data?.listTrainers?.items as Trainer[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTrainers?.nextToken;
        }

        return formatResult(true, "Trainer", items, "Successfully got the trainer.");
    } catch (error: any) {
        return formatResult(false, "Trainer", error, "Error reading record in the database for type: trainer");
    }
}

/**
* Get all records in the database that match the given criteria for type: trainer. 
* 
* @param {string}  name                The name of the trainer
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the trainer objects
*/
export async function getTrainersByName(name: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {name: {contains: name}};
        const result = (await API.graphql({
            query: queries.listTrainers,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTrainersQuery>;
        
        let items = result.data?.listTrainers?.items as Trainer[];
        let nextToken = result.data?.listTrainers?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listTrainers,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTrainersQuery>;

            const nextItems = nextResult.data?.listTrainers?.items as Trainer[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTrainers?.nextToken;
        }

        return formatResult(true, "Trainer", items, "Successfully got the Trainers.");
    } catch (error: any) {
        return formatResult(false, "Trainer", error, "Error reading record in the database for type: trainers");
    }
}

/**
* Get all records in the database that match the given criteria for type: trainer. 
* 
* @param {string}  personId            The person id of the creator
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the trainer object
*/
export async function getTrainersByCreatedBy(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {createdBy: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listTrainers,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTrainersQuery>;
        
        let items = result.data?.listTrainers?.items as Trainer[];
        let nextToken = result.data?.listTrainers?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listTrainers,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTrainersQuery>;

            const nextItems = nextResult.data?.listTrainers?.items as Trainer[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTrainers?.nextToken;
        }

        return formatResult(true, "Trainer", items, "Successfully got the Trainers.");
    } catch (error: any) {
        return formatResult(false, "Trainer", error, "Error reading record in the database for type: trainers");
    }
}

/**
* Get all records in the database that match the given criteria for type: trainer. 
* 
* @param {string}  barnId              The barn id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the trainer object
*/
export async function getTrainersByBarnId(barnId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {barnId: {eq: barnId}};
        const result = (await API.graphql({
            query: queries.listTrainers,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListTrainersQuery>;
        
        let items = result.data?.listTrainers?.items as Trainer[];
        let nextToken = result.data?.listTrainers?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listTrainers,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListTrainersQuery>;

            const nextItems = nextResult.data?.listTrainers?.items as Trainer[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listTrainers?.nextToken;
        }

        return formatResult(true, "Trainer", items, "Successfully got the Trainers.");
    } catch (error: any) {
        return formatResult(false, "Trainer", error, "Error reading record in the database for type: trainers");
    }
}