import {
    IonButton,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Barn, Horse, Owner } from "../../../models";
import ErrorAlert from "../../Errors/ErrorAlert";
import SuccessBanner from "../../Banners/SuccessBanner";
import { UpdateHorseInput } from "../../../API";
import { getOwnerById } from "../../../utilities/owner/Owner";
import { updateHorse } from "../../../utilities/horse/Horse";
import SelectOwner from "../../Owner/SelectOwner";
import OwnerForm from "../../Owner/OwnerForm";
import { getBarnById } from "../../../utilities/barn/Barn";

interface _Props {
    horse: Horse
}

const EditHorseOwnerForm: React.FC<_Props> = ({horse}) => {    
    const [owner, setOwner] = useState<Owner | null | undefined>();
    const [barn, setBarn] = useState<Barn | null | undefined>();
    const [addNewOwner, setAddNewOwner] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const clearForm = () => {
        setOwner(undefined);
    }

    const getOwner = async (id: string) => {
        const queryResult = await getOwnerById(id);
        if (queryResult.isSuccess) {
            setOwner(queryResult.result);
        }
    }

    const getBarn = async (id: string) => {
        const queryResult = await getBarnById(id);
        if (queryResult.isSuccess) {
            setBarn(queryResult.result);
        }
    }

    useEffect(() => {
        if (horse && horse.owner) {
            setOwner(horse.owner);
        } else if (horse && horse.ownerId) {
            getOwner(horse.ownerId);
        } else {
            clearForm();
        }

        if (horse && horse.barn) {
            setBarn(horse.barn);
        } else if (horse && horse.barnId) {
            getBarn(horse.barnId);
        } else {
            clearForm();
        }
    }, [horse]);

    const handleEditHorse = async (selectedOwner?: Owner) => {
        const input: UpdateHorseInput = {
            id: horse.id,
            ownerId: selectedOwner?.id || owner?.id,
            ownerName: selectedOwner?.name || owner?.name,
            // horseOwnerId: selectedOwner?.id || owner?.id
        };
        const createResult = await updateHorse(input);
        if (createResult.isSuccess) {
            setSuccess("Successfully saved the updates.");
        } else {
            setError(createResult.message);
        }
    }

    const handleSelectedOwner = (owner: Owner) => {
        setOwner(owner);
    }

    const handleNewOwner = (owner: Owner) => {
        setOwner(owner);
        handleEditHorse();
        setAddNewOwner(false);
    }

    return (
        <>
            {success && <SuccessBanner success={success} />}
            {error && <ErrorAlert error={error} />}
            <IonRow>
                <IonCol>
                    <p>Select an owner from the drop down, or create a new owner. Then click save.</p>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="8">
                    <SelectOwner selectedValue={owner?.id} barn={barn} onSelect={handleSelectedOwner} />
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                    <IonButton onClick={() => setAddNewOwner(true)}>Create New</IonButton>
                </IonCol>
            </IonRow>
            {addNewOwner ? 
                <IonRow>
                    <IonCol>
                        <OwnerForm horse={horse} onChange={handleNewOwner} />
                    </IonCol>
                </IonRow>
                :
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeMd="4" className="ion-text-center">
                        <IonButton
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={() => handleEditHorse()}
                        >
                            Save
                        </IonButton>
                    </IonCol>
                </IonRow>
            }
        </>
    );
};

export default EditHorseOwnerForm;