import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventAuditorFilterInput, CreateEventAuditorFilterMutation, DeleteEventAuditorFilterInput, DeleteEventAuditorFilterMutation, GetEventAuditorFilterQuery, ListEventAuditorFiltersQuery, UpdateEventAuditorFilterInput, UpdateEventAuditorFilterMutation } from "../../API";
import moment from "moment";
import { EventAuditorFilter } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventAuditorFilter. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventAuditorFilter.
*/
export async function createEventAuditorFilter(input: CreateEventAuditorFilterInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventAuditorFilter", "No input", "Create EventAuditorFilter received no input.");
   try {
        const fullInput: CreateEventAuditorFilterInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventAuditorFilter, { input: fullInput }))) as GraphQLResult<CreateEventAuditorFilterMutation>;
       else result = (await API.graphql({
           query: mutations.createEventAuditorFilter,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventAuditorFilterMutation>;
       const eventAuditorFilter = result.data?.createEventAuditorFilter;
       return formatResult(true, "EventAuditorFilter", eventAuditorFilter, "Successfully created the eventAuditorFilter.");
   } catch (error: any) {
       return formatResult(false, "EventAuditorFilter", error, "Error creating record in the database for type: eventAuditorFilter");
   }
}

/**
* Handle updating a new record in the database for type: eventAuditorFilter. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventAuditorFilter.
*/
export async function updateEventAuditorFilter(input: UpdateEventAuditorFilterInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventAuditorFilter", "No input", "Update EventAuditorFilter received no input.");
   try {
        const fullInput: UpdateEventAuditorFilterInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventAuditorFilter, { input: fullInput }))) as GraphQLResult<UpdateEventAuditorFilterMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventAuditorFilter,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventAuditorFilterMutation>;
       const eventAuditorFilter = result.data?.updateEventAuditorFilter;
       return formatResult(true, "EventAuditorFilter", eventAuditorFilter, "Successfully updated the eventAuditorFilter.");
   } catch (error: any) {
       return formatResult(false, "EventAuditorFilter", error, "Error updating record in the database for type: eventAuditorFilter");
   }
}

/**
* Handle deleting a new record in the database for type: eventAuditorFilter. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventAuditorFilter.
*/
export async function deleteEventAuditorFilter(input: DeleteEventAuditorFilterInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventAuditorFilter", "No input", "Delete EventAuditorFilter received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventAuditorFilter, { input: input }))) as GraphQLResult<DeleteEventAuditorFilterMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventAuditorFilter,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventAuditorFilterMutation>;
       const eventAuditorFilter = result.data?.deleteEventAuditorFilter;
       return formatResult(true, "EventAuditorFilter", eventAuditorFilter, "Successfully deleted the eventAuditorFilter.");
   } catch (error: any) {
       return formatResult(false, "EventAuditorFilter", error, "Error deleting record in the database for type: eventAuditorFilter");
   }
}

/**
* Get all records in the database for type: eventAuditorFilter. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAuditorFilter object
*/
export async function getAllEventAuditorFilters(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventAuditorFilters,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventAuditorFiltersQuery>;

        let items = result.data?.listEventAuditorFilters?.items as EventAuditorFilter[];
        let nextToken = result.data?.listEventAuditorFilters?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventAuditorFilters,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventAuditorFiltersQuery>;

            const nextItems = nextResult.data?.listEventAuditorFilters?.items as EventAuditorFilter[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventAuditorFilters?.nextToken;
        }

        return formatResult(true, "EventAuditorFilter", items, "Successfully got the EventAuditorFilters.");
    } catch (error: any) {
        return formatResult(false, "EventAuditorFilter", error, "Error reading record in the database for type: eventAuditorFilters");
    }
}

/**
* Read a specific record in the database for type: eventAuditorFilter. 
* 
* @param {string}  id                  The eventAuditorFilter id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAuditorFilter object
*/
export async function getEventAuditorFilterById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventAuditorFilter,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventAuditorFilterQuery>;
       const eventAuditorFilter = result.data?.getEventAuditorFilter;
       return formatResult(true, "EventAuditorFilter", eventAuditorFilter, "Successfully got the eventAuditorFilter.");
   } catch (error: any) {
       return formatResult(false, "EventAuditorFilter", error, "Error reading record in the database for type: eventAuditorFilter");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventAuditorFilter. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAuditorFilter object
*/
export async function getEventAuditorFiltersByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventAuditorFilters,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventAuditorFiltersQuery>;

        let items = result.data?.listEventAuditorFilters?.items as EventAuditorFilter[];
        let nextToken = result.data?.listEventAuditorFilters?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventAuditorFilters,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventAuditorFiltersQuery>;

            const nextItems = nextResult.data?.listEventAuditorFilters?.items as EventAuditorFilter[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventAuditorFilters?.nextToken;
        }

        return formatResult(true, "EventAuditorFilter", items, "Successfully got the EventAuditorFilters.");
    } catch (error: any) {
        return formatResult(false, "EventAuditorFilter", error, "Error reading record in the database for type: eventAuditorFilters");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventAuditorFilter. 
* 
* @param {string}  eventAuditorId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAuditorFilter object
*/
export async function getEventAuditorFiltersByEventIdAuditorId(eventAuditorId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventAuditorId: {eq: eventAuditorId}};
        const result = (await API.graphql({
            query: queries.listEventAuditorFilters,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventAuditorFiltersQuery>;

        let items = result.data?.listEventAuditorFilters?.items as EventAuditorFilter[];
        let nextToken = result.data?.listEventAuditorFilters?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventAuditorFilters,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventAuditorFiltersQuery>;

            const nextItems = nextResult.data?.listEventAuditorFilters?.items as EventAuditorFilter[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventAuditorFilters?.nextToken;
        }

        return formatResult(true, "EventAuditorFilter", items, "Successfully got the EventAuditorFilters.");
    } catch (error: any) {
        return formatResult(false, "EventAuditorFilter", error, "Error reading record in the database for type: eventAuditorFilters");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventAuditorFilter. 
* 
* @param {string}  organizationAuditorId        The organization id
* @param {string}  authMode                     API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventAuditorFilter object
*/
export async function getEventAuditorFiltersByOrganizationAuditorId(organizationAuditorId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationAuditorId: {eq: organizationAuditorId}};
        const result = (await API.graphql({
            query: queries.listEventAuditorFilters,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventAuditorFiltersQuery>;

        let items = result.data?.listEventAuditorFilters?.items as EventAuditorFilter[];
        let nextToken = result.data?.listEventAuditorFilters?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventAuditorFilters,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventAuditorFiltersQuery>;

            const nextItems = nextResult.data?.listEventAuditorFilters?.items as EventAuditorFilter[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventAuditorFilters?.nextToken;
        }

        return formatResult(true, "EventAuditorFilter", items, "Successfully got the EventAuditorFilters.");
    } catch (error: any) {
        return formatResult(false, "EventAuditorFilter", error, "Error reading record in the database for type: eventAuditorFilters");
    }
}