import { IonCol, IonRow } from "@ionic/react";
import {CardElement} from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: "\"Helvetica Neue\", Helvetica, sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#757f8a",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

function CardSection() {
    return (
        <IonRow>
            <IonCol className="mb-3 text-center" sizeMd="3" sizeXs="12">
                Credit or Debit Card
            </IonCol>
            <IonCol className="mb-3 text-center" sizeMd="9" sizeXs="12">
                <CardElement options={CARD_ELEMENT_OPTIONS} />
            </IonCol>
        </IonRow>
    );
};

export default CardSection;