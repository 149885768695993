import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { EventEntry, Contact } from "../../../models";
import { getContactById } from "../../../utilities/contact/Contact";

interface _Props {
    entry: EventEntry
}

const DisplayEntryPrimaryContactInfo: React.FC<_Props> = ({entry}) => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [contact, setContact] = useState<Contact | null | undefined>();

    const getContact = async (entry: EventEntry) => {
        const contactId = entry.contactId;
        if (contactId) {
            const queryResult = await getContactById(contactId);
            if (queryResult.isSuccess) {
                setContact(queryResult.result);
            }
        }
    }

    useEffect(() => {
        if (entry) {
            setIsCollapsed(true);
            getContact(entry);
        }
    }, [entry]);

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {entry ? <IonRow><h2>Contact: {entry.primarycontact?.name}</h2></IonRow> : <h2>No Contact</h2> }
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {contact && (
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <p>Primary Contact Name: {contact.name}</p>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <p>Email Address: {contact.personalEmail}</p>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <p>Phone Number: {contact.cell ? contact.cell : contact.home ? contact.home : ""}</p>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <p>Phone Number Type: {contact.cell ? "cell" : contact.home ? "home" : "work"}</p>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default DisplayEntryPrimaryContactInfo;