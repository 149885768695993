export interface EventTypeOption {
    name: string
    value: string
}

export const eventTypes = [
    {
        "name": "Horse Show",
        "value": "horse_show"
    },
    {
        "name": "Horse Trials",
        "value": "horse_trails"
    },
    {
        "name": "Clinic",
        "value": "clinic"
    },
    {
        "name": "Camp",
        "value": "camp"
    },
    {
        "name": "Other",
        "value": "other"
    }
];

export function getEventTypeDisplayName(typeValue: string) {
    let result = "";
    eventTypes.forEach(element => {
        if (element.value === typeValue) result = element.name;
    });
    return result;
}