import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonIcon,
    IonLabel,
    IonRow,
    IonText,
    isPlatform,
} from "@ionic/react";
import React from "react";
import { add } from "ionicons/icons";

interface _Props {
    onSubmit: Function
}

const AddBarnPersonButton: React.FC<_Props> = ({onSubmit}) => {
    const handleSubmit = () => {
        onSubmit();
    }

    return (
        <>
            {isPlatform("mobile") ? 
                <IonCol size="12">
                    <IonCard color="tertiary" mode="md" onClick={handleSubmit}>
                        <IonCardContent>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="12" className="text-center">
                                    <IonLabel>
                                        <IonIcon icon={add} slot="start" />
                                        <IonText>Add Person</IonText>
                                    </IonLabel>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
                :
                <IonButton onClick={handleSubmit}>
                    <IonIcon icon={add} size="large" />
                    <IonText>Add</IonText>
                </IonButton>
            }
        </>
    );
};

export default AddBarnPersonButton;
