import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import ErrorAlert from "../Errors/ErrorAlert";
import { Event, EventEntry, EventEntryFee, EventFee, Fee } from "../../models";
import Spinner from "../Spinners/Spinner";
import { IonButton, IonCol, IonItem, IonLabel, IonList, IonRow } from "@ionic/react";
import { updateEventEntry } from "../../utilities/eventEntry/EventEntry";
import { getEventFeesByEventId } from "../../utilities/eventFee/EventFee";
import { Input } from "reactstrap";
import { createEventEntryFee, deleteEventEntryFee, getEventEntryFeesByEventIdByEntryId } from "../../utilities/eventEntryFee/EventEntryFee";
import { CreateEventEntryFeeInput, UpdateEventEntryInput } from "../../API";
import { getFeeById } from "../../utilities/fee/Fee";
import { getEventClassEntriesByEventIdEntryId } from "../../utilities/eventClassEntry/EventClassEntry";
import moment from "moment";

interface FormattedOption {
    quantity: number
    required: boolean
    object: EventFee
}

interface _Props {
    event: Event
    entry?: EventEntry
    isAdminView?: Boolean
}

const EntryFeeForm: React.FC<_Props> = ({entry, event, isAdminView}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");
    const [eventFees, setEventFees] = useState<EventFee[] | null | undefined>();
    const [formattedEventFees, setFormattedEventFees] = useState<FormattedOption[] | null | undefined>();
    const [previousEventEntryFees, setPreviousEventEntryFees] = useState<EventEntryFee[] | null | undefined>();
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();

    const getEventEntryFeesByEntry = async (eventEntry: EventEntry, currentEventFees?: EventFee[]) => {
        setIsLoading(true);
        const queryResult = await getEventEntryFeesByEventIdByEntryId(eventEntry.eventId, eventEntry.id);
        if (queryResult.isSuccess) {
            setPreviousEventEntryFees(queryResult.result);
            setIsLoading(false);
            const fees: EventFee[] | null | undefined = eventFees || currentEventFees;
            if (fees) {
                formatEventFees(fees, queryResult.result);
            }
        } else {
            setIsLoading(false);
            const fees: EventFee[] | null | undefined = eventFees || currentEventFees;
            if (fees) {
                formatEventFees(fees, queryResult.result);
            }
        }
    }

    const formatEventFees = async (eventFees: EventFee[], entryEventFees?: (EventEntryFee[] | null)) => {
        setIsLoading(true);
        let classCount = 0;
        let result: FormattedOption[] = [];
        if (eventFees && eventFees.length > 0) {
            for (let i = 0; i < eventFees.length; i++) {
                const eventFee = eventFees[i];
                if (eventFee.fee?.isHiddenFromEntryForm && (!isAdminView)) {
                    // skip this event fee
                } else {
                    let isRequired = false;
                    let isPerClass = false;
                    let isSkipped = false;
                    const queryFeeResult = await getFeeById(eventFee.feeId);
                    if (queryFeeResult.isSuccess) {
                        const fee: Fee = queryFeeResult.result;
                        
                        if (fee.isStartedOnEntryDate) {
                            if (fee.startDate) {
                                const startDate: string = fee.startDate;
                                const startTime = fee.startTime || "11:59:59.999";
                                let entrySubmissionDate: string = entry?.createdOn ? moment(entry?.createdOn).format("YYYY-MM-DD") : "";
                                let entrySubmissionTime: string = entry?.createdOn ? moment(entry?.createdOn).format("hh:mm:ss.sss") : "";
                                
                                if (!entrySubmissionDate || entrySubmissionDate === "") {
                                    // Entry has not been submitted yet. Use today's date as check
                                    entrySubmissionDate = moment(new Date()).format("YYYY-MM-DD");
                                } 

                                const checkIsBeforeFeeStartDate = (entrySubmissionDate !== startDate && entrySubmissionDate < startDate);
                                const checkIsSameDateBeforeFeeStartTime = (entrySubmissionDate === startDate && entrySubmissionTime < startTime);

                                // Check if the implementation date has been hit, and check if the date is the same, but it's not implementation time yet.
                                if (checkIsBeforeFeeStartDate || checkIsSameDateBeforeFeeStartTime) {
                                    // Haven't hit the fee implementation datetime yet, so do not include the fee
                                    isSkipped = true;
                                }
                            }
                        }
                        if (fee) {
                            isRequired = !!fee.isPerEntry;
                            if (fee.isPerClass) {
                                isPerClass = true;
                                if (entry) {
                                    const classQueryResult = await getEventClassEntriesByEventIdEntryId(event.id, entry?.id);
                                    if (classQueryResult.isSuccess) {
                                        const classList = classQueryResult.result;
                                        classCount = classList.length;
                                    }
                                }
                            }
                        }
                        
                    }
                    const savedIndex = entryEventFees && entryEventFees.findIndex(n => n.eventFeeId === eventFee.id);
                    let quantity = 0;
                    if (savedIndex !== undefined && savedIndex !== null && savedIndex > -1) quantity = (entryEventFees && entryEventFees[savedIndex].quantity) || 0;
                    const formatted: FormattedOption = {
                        required: isRequired,
                        quantity: isRequired ? (isPerClass ? classCount : 1) : quantity,
                        object: eventFee
                    };
                    if (!isSkipped) result.push(formatted);
                }
            };
        }
        setFormattedEventFees(result.sort((a: any, b: any) => b.required - a.required));
        setIsLoading(false);
    }

    async function getEventFees(event: Event) {
        setIsLoading(true);
        const queryResult = await getEventFeesByEventId(event.id);
        if (queryResult.isSuccess) {
            const eventFees = queryResult.result;
            setEventFees(eventFees);
            setIsLoading(false);
            if (previousEventEntryFees) {
                formatEventFees(eventFees, previousEventEntryFees);
            }
            else {
                if (entry) {
                    getEventEntryFeesByEntry(entry, eventFees);
                }
                else {
                    formatEventFees(eventFees, previousEventEntryFees);
                }
            }
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (event) getEventFees(event);
    }, [event]);

    useEffect(() => {
        if (entry) {
            setCurrentEntry(entry);
            getEventEntryFeesByEntry(entry);
        }
    }, [entry]);

    const handleUpdateQuantity = (index: number, option: FormattedOption, quantity: number) => {
        if (formattedEventFees) {
            const updatedOption = {
                required: option.required,
                quantity,
                object: option.object
            };
            const newOptions = [
                ...formattedEventFees.slice(0, index),
                updatedOption,
                ...formattedEventFees.slice(index + 1)
            ];
            setFormattedEventFees(newOptions);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        if (formattedEventFees) {
            if (previousEventEntryFees && previousEventEntryFees.length > 0) {
                for (var i = 0; i < previousEventEntryFees.length; i++) {
                    const current = previousEventEntryFees[i];
                    if (!current.fee?.isPerEntry) {
                        // Remove any previous, non-essential fees
                        const deleteResult = await deleteEventEntryFee({id: current.id});
                        if (!deleteResult.isSuccess) {
                            setError("Could not remove the previously saved fee on this entry.");
                        }
                    }
                }
            }
            for (var i = 0; i < formattedEventFees.length; i++) {
                const currentOption = formattedEventFees[i];
                if (currentEntry && currentOption.quantity > 0 && !currentOption.required) {
                    const input: CreateEventEntryFeeInput = {
                        eventId: event.id,
                        name: currentOption.object.name,
                        status: "saved",
                        amount: currentOption.object.amount,
                        quantity: currentOption.quantity,
                        entryId: currentEntry?.id, 
                        // eventEntryFeeEntryId: currentEntry?.id, 
                        eventFeeId: currentOption.object.id,
                        // eventEntryFeeEventFeeId: currentOption.object.id,
                        feeId: currentOption.object.feeId, 
                        // eventEntryFeeFeeId: currentOption.object.feeId, 
                        taxA: currentOption.object.fee?.taxA,
                        taxB: currentOption.object.fee?.taxB,
                        description: currentOption.object.description,
                        createdBy: user.id
                    };
                    const createResult = await createEventEntryFee(input);
                    if (!createResult.isSuccess) {
                        const message = "Could not save the fee: " + currentOption.object.name;
                        setError(message);
                    }
                }
            }
        }
        // Then update status of entry to in_progress
        if (currentEntry) {
            const eventEntryInput: UpdateEventEntryInput = {
                id: currentEntry.id,
                status: "in_progress"
            };
            const updateEntryResult = await updateEventEntry(eventEntryInput);
            if (!updateEntryResult.isSuccess) {
                setError(updateEntryResult.message);
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {isLoading ?
                <Spinner/>
                :
                <>
                    {currentEntry && (
                        <>
                            <IonRow className="ion-justify-content-center">
                                <IonCol className="text-center" sizeMd="10">
                                    <p>Event Fees</p>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeMd="10">
                                    {formattedEventFees && formattedEventFees.length > 0 ?
                                        <IonList className="bg-white">
                                            {formattedEventFees.map((formattedOption, index) => (
                                                <IonItem key={index}>
                                                    <IonLabel className="ion-text-wrap">
                                                        <IonRow>
                                                            <IonCol sizeXs="12" sizeMd="4">
                                                                <p className="text-primary">Fee: {formattedOption.object.name}</p>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="4">
                                                                <p className="text-primary">Amount: ${formattedOption.object.amount.toFixed(2)}</p>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="4">
                                                                <Input
                                                                    type="number"
                                                                    valid={formattedOption.quantity > 0}
                                                                    min={0}
                                                                    disabled={formattedOption.required} 
                                                                    value={formattedOption.quantity}
                                                                    onChange={e => {
                                                                        handleUpdateQuantity(index, formattedOption, parseInt(e.target.value))
                                                                    }}
                                                                />
                                                            </IonCol>
                                                        </IonRow>
                                                        {formattedOption.object.description && (
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p className="text-primary">Description: {formattedOption.object.description}</p>
                                                                </IonCol>
                                                            </IonRow>
                                                        )}
                                                    </IonLabel>
                                                </IonItem>
                                            ))}
                                        </IonList>
                                        :
                                        <p>This event does not have any additional fees.</p>
                                    }
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol className="text-center" sizeMd="4">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="success"
                                        expand="block"
                                        onClick={handleSubmit}
                                    >
                                        Save Additional Fees
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                </> 
            }
        </>
    );
};

export default EntryFeeForm;