import React, { useEffect, useState } from "react";
import { IonRow, IonCol, IonText, IonButton } from "@ionic/react";
import BasicTooltip from "../../Tooltip/BasicTooltip";
import EditJumpHeightOptionForm from "./EditJumpHeightOptionForm";

interface _Props {
    selectedJumpHeightOptions?: ((string | null)[] | null | undefined)
    onSelect: Function
}

const JumpHeightOptionsForm: React.FC<_Props> = ({selectedJumpHeightOptions, onSelect}) => {
    const [isHidden, setIsHidden] = useState(true);
    const [currentJumpHeightOptions, setCurrentJumpHeightOptions] = useState<(string | null)[] | null | undefined>();

    useEffect(() => {
        if (selectedJumpHeightOptions) setCurrentJumpHeightOptions(selectedJumpHeightOptions);
        else setCurrentJumpHeightOptions(undefined);
    }, [selectedJumpHeightOptions]);

    const createCurrentJumpHeightOptionsString = () => {
        let string = "";
        if (currentJumpHeightOptions) {
            for (let i = 0; i < currentJumpHeightOptions.length; i++) {
                const current = currentJumpHeightOptions[i];
                string = string + " " + current + ";"
            }
        }
        return string;
    }

    const handleNewJumpHeightOption = (newOption: string) => {
        let updatedJumpHeightOptions = [];
        if (currentJumpHeightOptions) {
            updatedJumpHeightOptions = currentJumpHeightOptions.concat(newOption);
        } else {
            updatedJumpHeightOptions = [newOption];
        }
        setCurrentJumpHeightOptions(updatedJumpHeightOptions);
        onSelect(updatedJumpHeightOptions);
    }

    const handleRemoveJumpHeightOption = (index: number) => {
        if (currentJumpHeightOptions) {
            const updatedJumpHeightOptions = [
                ...currentJumpHeightOptions?.slice(0, index),
                ...currentJumpHeightOptions?.slice(index + 1),
            ];
            setCurrentJumpHeightOptions(updatedJumpHeightOptions);
            onSelect(updatedJumpHeightOptions);
        }
    }
    
    return (
        <>
            {isHidden ?
                <IonText>
                    Jump height(s): 
                    <span>{createCurrentJumpHeightOptionsString()}</span>
                    <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(false)}>Edit</IonButton>
                </IonText>
                :
                <>
                    <IonRow>
                        <IonCol className="text-center">
                            <BasicTooltip 
                                label="Jump Height Options"
                                tip="Add any jump height options for this class."
                            />
                            <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(true)}>Hide</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <EditJumpHeightOptionForm onSelect={(newOption: string) => handleNewJumpHeightOption(newOption)} />
                        </IonCol>
                    </IonRow>
                    {(currentJumpHeightOptions && currentJumpHeightOptions.length > 0) && (
                        <>
                            {currentJumpHeightOptions.map((option: (string | null),  index: number) => (
                                <IonRow key={index} className="ion-justify-content-center">
                                    <IonCol size="6" className="ion-text-right">
                                        <p>Height {index+1}: {option ? option : ""}</p>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonButton 
                                            color="danger"
                                            size="small"
                                            onClick={e => {
                                                handleRemoveJumpHeightOption(index);
                                            }}
                                        >
                                            Remove
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            ))}
                        </>
                    )}
                </>
            }
        </>
    );
};

export default JumpHeightOptionsForm;
