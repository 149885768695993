import { Event, EventEntry } from "../../models";
import {
    IonButton,
    IonCol,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import EntryFormHeader from "./EntryFormHeader";
import EntryFormNavbar from "../Navbars/EntryFormNavbar";
import EntryCard from "./EntryCard";
import { updateEventEntry } from "../../utilities/eventEntry/EventEntry";
import EntryClassForm from "./EntryClassForm";
import EntryFeeForm from "./EventFeeForm";
import EntryRuleForm from "./EntryRulesForm";
import EntrySummaryCard from "./EntrySummaryCard";
import Spinner from "../Spinners/Spinner";
import EntryStablingForm from "./EventStablingForm";
import DisplayStablingInfo from "./EntrySummary/DisplayStablingInfo";
import { updateBeddingRequestStatusByEntryId, updateStablingRequestStatusByEntryId } from "../../utilities/stablingRequest/StablingRequestStatus";
import { updateEventEntryFeeStatusByEventByEntry } from "../../utilities/eventEntryFee/UpdateEventEntryFeeStatus";
import { UpdateEventClassEntryInput, UpdateEventEntryInput } from "../../API";
import { useHistory } from "react-router";
import { sendEntryStatusSubmittedEmailWithInvoice } from "../../utilities/eventEntry/EventEntryEmail";
import { Input } from "reactstrap";
import { getEventClassEntriesByEventIdEntryId, updateEventClassEntry } from "../../utilities/eventClassEntry/EventClassEntry";
import ErrorAlert from "../Errors/ErrorAlert";

interface _Props {
    event?: Event
    entry?: EventEntry
    onSubmit: Function
}

const EditEntryForm: React.FC<_Props> = ({event, entry, onSubmit}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const currentEntries: (EventEntry[] | undefined) = [];
    const [note, setNote] = useState("");
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [activeTab, setActiveTab] = useState<string>("info");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");


    useEffect(() => {
        if (entry) setCurrentEntry(entry);
    }, [entry]);

    const handleNavbarSelect = (selection: string) => {
        setActiveTab(selection);
    }

    const handleSubmitEntry = async (entry: EventEntry) => {
        setActiveTab("classes");
    }

    const handleNextPage = () => {
        if (activeTab === "info") setActiveTab("classes");
        if (activeTab === "classes") setActiveTab("stalls");
        if (activeTab === "stalls") setActiveTab("fees");
        if (activeTab === "fees") setActiveTab("rules");
        if (activeTab === "rules") setActiveTab("submit");
    }

    const handleUpdateStabling = async () => {
        if (entry) {
            await updateBeddingRequestStatusByEntryId(entry.id, event?.eventOptions?.autoAcceptNewEntries ? "accepted" : "submitted");
            return await updateStablingRequestStatusByEntryId(entry.eventId, entry.id, event?.eventOptions?.autoAcceptNewEntries ? "accepted" : "submitted");
        } else {
            setError("Stabling Error");
        }
    }

    const handleUpdateFees = async (entry: EventEntry) => {
        const updateResult = await updateEventEntryFeeStatusByEventByEntry(entry.eventId, entry.id, event?.eventOptions?.autoAcceptNewEntries ? "accepted" : "submitted");
        return updateResult;
    } 

    async function updateEventClassEntriesStatus(entry: EventEntry) {
        const queryResult = await getEventClassEntriesByEventIdEntryId(entry.eventId, entry.id);
        const currentEventEntryClasses = queryResult.result;
        let result = true;
        if (currentEventEntryClasses && currentEventEntryClasses.length) {
            for (var i = 0; i < currentEventEntryClasses.length; i++) {
                const currentEventClassEntry = currentEventEntryClasses[i];
                const updateInput: UpdateEventClassEntryInput = {
                    id: currentEventClassEntry.id,
                    status: event?.eventOptions?.autoAcceptNewEntries ? "accepted" : "submitted"
                };
                const updateResult = await updateEventClassEntry(updateInput);
                if (!updateResult.isSuccess) {
                    result = false;
                    setError(updateResult.message);
                }
            }
        }
        return result;
    }

    const handleUpdateEntryStatus = async (entry: EventEntry): Promise<EventEntry | null | undefined> => {
        const eventEntryInput: UpdateEventEntryInput = {
            id: entry.id,
            note: note,
            status: event?.eventOptions?.autoAcceptNewEntries ? "complete" : "submitted"
        };
        const updateResult = await updateEventEntry(eventEntryInput);
        if (updateResult.isSuccess) {
            const updatedEntry = updateResult.result;
            return updatedEntry;
        }
    }

    const handleUpdateEntries = async () => {
        let isSuccess = true;
        if (currentEntry) {
            // First update any stabling requests
            const updateStablingResult = await handleUpdateStabling();
            if (updateStablingResult && !updateStablingResult.isSuccess) {
                isSuccess = false;
                setError(updateStablingResult.message);
            }

            // Next, update entry info
            if (currentEntry.status !== "complete") {
                const updateFeesResult = await handleUpdateFees(currentEntry);
                if (!updateFeesResult.isSuccess) {
                    isSuccess = false;
                    setError(updateFeesResult.message);
                }
                if (event?.eventOptions?.autoAcceptNewEntries) await updateEventClassEntriesStatus(currentEntry);
                const updateEntryStatusResult = await handleUpdateEntryStatus(currentEntry);
                if (updateEntryStatusResult) {
                    await sendEntryStatusSubmittedEmailWithInvoice(currentEntry, user.email, user.firstName || "");
                }
            }
            if (isSuccess) {
                await navigateToEntriesPage();
            }
        } else {
            setError("Could not find any entries to submit.");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        handleUpdateEntries();
        setIsLoading(false);
    }

    const navigateToEntriesPage = async () => {
        if (event) {
            const path = "/index/event/entries/" + event.id;
            history.push(path);
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    <EntryFormHeader event={event} />
                    <hr />
                    <EntryFormNavbar active={activeTab} event={event} onSubmit={handleNavbarSelect}/>
                    {error && <ErrorAlert error={error} />}
                    {event && (
                        <div className="ion-padding-top">
                            {activeTab === "info" && (
                                <>
                                    <EntryCard event={event} entry={currentEntry!} onSubmit={handleSubmitEntry}/>
                                </>
                            )}
                            {activeTab === "classes" && (
                                <>
                                    <EntryClassForm event={event} entry={currentEntry!} />
                                </>
                            )}
                            {activeTab === "stalls" && (
                                <>
                                    <EntryStablingForm event={event} entry={currentEntry!} onSubmit={() => console.log("Stabling")}/>
                                </>
                            )}
                            {activeTab === "fees" && (
                                <>
                                    <EntryFeeForm event={event} entry={currentEntry!} />
                                </>
                            )}
                            {activeTab === "rules" && (
                                <>
                                    <EntryRuleForm event={event} entry={currentEntry!} />
                                </>
                            )}
                            {activeTab === "submit" && (
                                <>
                                    <IonText className="ion-text-center">Entry Summary</IonText>
                                    {currentEntries && currentEntries.length && (
                                        <>
                                            {currentEntries.map((entry, index) => (
                                                <div key={index}>
                                                    <EntrySummaryCard event={event} entry={entry} />
                                                </div>
                                            ))}
                                        </>
                                    )}
                                     <IonRow>
                                        <IonCol>
                                            <IonLabel>Notes</IonLabel>
                                            {event.id === "579a142d-e1cc-4170-ae20-cbae8b58fb67" && <p>If showing in NWAHJA Scholarship Equitation or Open Hunter, please list what height you would like to be entered in.</p>}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <Input
                                                placeholder="Add notes for this entry."
                                                rows="5"
                                                type="textarea"
                                                name="note"
                                                value={note}
                                                spellCheck="true"
                                                onChange={(event) => setNote(event.target.value)}
                                                data-hj-whitelist
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonLabel>Notes</IonLabel>
                                            {event.id === "579a142d-e1cc-4170-ae20-cbae8b58fb67" && <p>If showing in NWAHJA Scholarship Equitation or Open Hunter, please list what height you would like to be entered in.</p>}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <Input
                                                placeholder="Add notes for this entry."
                                                rows="5"
                                                type="textarea"
                                                name="note"
                                                value={note}
                                                spellCheck="true"
                                                onChange={(event) => setNote(event.target.value)}
                                                data-hj-whitelist
                                            />
                                        </IonCol>
                                    </IonRow>
                                    {/* <hr/>
                                    <IonText className="ion-text-center">Stabling Summary</IonText>
                                    <DisplayStablingInfo event={event} /> */}
                                </>
                            )}
                        </div>
                    )}
                    <hr/>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="text-center" sizeMd="4">
                            {activeTab === "submit" ?
                                <IonButton
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </IonButton>
                                :
                                <IonButton
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleNextPage}
                                >
                                    Next
                                </IonButton>
                            }
                            
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default EditEntryForm;