import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { Barn } from "../../../../models";
import { getBarnById } from "../../../../utilities/barn/Barn";
import BasicBarnInfo from "../../../../components/Barn/BasicBarnInfo";
import BarnRequests from "../../../../components/Barn/BarnRequests";
import PageList from "../../../../components/Lists/PageList";
import { barnListOptions } from "../../../../interfaces/Page";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";


interface BarnPageProps extends RouteComponentProps<{
    id: string;
}> {}

const AdminBarnManagementPage: React.FC<BarnPageProps> = ({match}) => {
    const [currentBarn, setCurrentBarn] = useState<Barn | null | undefined>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getBarn() {
            if (match.params.id) {
                const queryResult = await getBarnById(match.params.id);
                if (queryResult.isSuccess) {
                    setCurrentBarn(queryResult.result);
                } else {
                    setError("Sorry, a problem occurred. Please go back and try again.");
                }
            }
        }

        getBarn();
    }, [match.params.id]); 

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Barn" />
                {currentBarn && 
                    <>
                        {error && <ErrorAlert error={error} />}
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <BasicBarnInfo barn={currentBarn} />
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <BarnRequests selectedBarn={currentBarn}/>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <PageList id={currentBarn.id} includeId={true} listOptions={barnListOptions} />
                            </IonCol>
                        </IonRow>
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default AdminBarnManagementPage;