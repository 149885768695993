import { Barn, Owner, Person } from "../../models";
import { getOwnersByBarnId, getOwnersByCreatedBy, getOwnersByPersonId } from "./Owner";

export async function getOwnersByUserByBarn(user: Person, barn?: (Barn | null)) {
    let owners: Owner[] = [];

    if (barn) {
        const queryResultByBarn = await getOwnersByBarnId(barn.id);
        if (queryResultByBarn.isSuccess) {
            owners = owners.concat(queryResultByBarn.result);
        }
    }
    
    const queryResultByPerson = await getOwnersByPersonId(user.id);
    if (queryResultByPerson.isSuccess) {
        const ownerArray = queryResultByPerson.result;
        if (ownerArray) {
            ownerArray.forEach((owner: Owner) => {
                const index = owners.findIndex(r => r.id === owner.id);
                if (index === -1) owners.push(owner);
            });
        }
    }

    const queryResultByCreatedPerson = await getOwnersByCreatedBy(user.id);
    if (queryResultByCreatedPerson.isSuccess) {
        const ownerArray = queryResultByCreatedPerson.result;
        if (ownerArray) {
            ownerArray.forEach((owner: Owner) => {
                const index = owners.findIndex(r => r.id === owner.id);
                if (index === -1) owners.push(owner);
            });
        }
    }

    const sorted = owners.sort((a,b) => a.name.localeCompare(b.name));
    
    return sorted || owners;
}