import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Membership,  Organization, OrganizationMembershipType, Person } from "../../models";
import { getMembershipTypesByOrganizationIDByAcceptOnlineApplications } from "../../utilities/organizationMembershipType/OrganizationMembershipType";
import Spinner from "../Spinners/Spinner";
import CONSTANT from "../../constant/constant";
import ErrorAlert from "../Errors/ErrorAlert";
import {PersonContext} from "../../context/PersonContext";
import { getActiveMembershipsByCreatedByIdByOrganizationId } from "../../utilities/membership/Membership";

interface _Props {
    organization: Organization | null;
}

interface FormattedUserApplication {
    membershipId: string
    membership: Membership
    status: string
    memberName: string
    displayString: string
    applicationLink: string
}

interface FormattedApplicationOption {
    membershipTypeId: string
    membershipTypeName: string
    membershipType: OrganizationMembershipType
    newApplicationLink: string
    userApplications?: FormattedUserApplication[]
}

const MembershipApplicationsListNew: React.FC<_Props> = ({ organization }) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [formattedOptions, setFormattedOptions] = useState<FormattedApplicationOption[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    
    const getStatusString = (status?: (string | null | undefined)) => {
        const statusString = (status === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS) ?
        (CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS_TEXT + " - Click to Finish") :
        (status === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE) ?
        CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE_TEXT : "";
        return statusString;
    }

    const getApplicationLink = (organizationMembershipType: OrganizationMembershipType, currentMembership?: Membership) => {
        const organizationBackHalfURL = organization?.urlBackHalf || "";
        let link = `/index/${organizationBackHalfURL}/application/${organizationMembershipType.id}`;
        if (currentMembership) link = link + "/" + currentMembership.id;
        return link;
    }

    function formatApplicationOptions(organizationMembershipTypes: OrganizationMembershipType[], membershipsCreatedByCurrentUser?: Membership[]) {
        let formattedOptionList: FormattedApplicationOption[] = [];
        for (let i = 0; i < organizationMembershipTypes.length; i++) {
            const currentMembershipType = organizationMembershipTypes[i];

            // Check for any current applications for this user
            let currentFormattedUserApplications: FormattedUserApplication[] = [];
            const membershipsForCurrentMembershipType = membershipsCreatedByCurrentUser?.filter((membership: Membership) => membership.organizationMembershipTypeId === currentMembershipType.id);
            if (membershipsForCurrentMembershipType) {
                for (let j = 0; j < membershipsForCurrentMembershipType.length; j++) {
                    const currentMembership = membershipsForCurrentMembershipType[j];
                    const memberName = currentMembership.personName || "";
                    const status = getStatusString(currentMembership.membershipStatus);
                    const displayString = (currentMembershipType.category === "group") ? (currentMembership?.groupContact?.name ? currentMembership.groupContact?.name + " - " + status : status) : (memberName + " - " + status);
                    const formattedUserApplication: FormattedUserApplication = {
                        membershipId: currentMembership.id,
                        membership: currentMembership,
                        memberName: memberName,
                        displayString: displayString,
                        applicationLink: getApplicationLink(currentMembershipType, currentMembership),
                        status: status
                    };
                    currentFormattedUserApplications.push(formattedUserApplication);
                }
            }

            const formattedApplicationOption: FormattedApplicationOption = {
                membershipTypeId: currentMembershipType.id,
                membershipTypeName: currentMembershipType.name,
                membershipType: currentMembershipType,
                newApplicationLink: getApplicationLink(currentMembershipType),
                userApplications: (currentFormattedUserApplications && currentFormattedUserApplications.length > 0) ? currentFormattedUserApplications : undefined 
            };
            formattedOptionList.push(formattedApplicationOption);
        }

        setFormattedOptions(formattedOptionList);
    }   

    async function getMembershipApplications(id: string) {
        const queryResult = await getMembershipTypesByOrganizationIDByAcceptOnlineApplications(id, CONSTANT.MEMBERSHIP.ONLINE_APPLICATION.YES);
        if (queryResult.isSuccess) {
            const membershipApplicationList: OrganizationMembershipType[] = queryResult.result;
            const sorted = membershipApplicationList.sort((a, b) => a.name.localeCompare(b.name));
            return sorted;
        } else {
            setError(queryResult.message);
        }
    }

    async function getMemberships(id: string, organizationId: string) {
        const queryResult = await getActiveMembershipsByCreatedByIdByOrganizationId(id, organizationId);
        if (queryResult.isSuccess) {
            const membershipList: Membership[] = queryResult.result;
            return membershipList;
        } else {
            setError(queryResult.message);
        }
    }

    useEffect(() => {
        async function getData(organization?: (Organization | undefined | null), currentUser?: (Person | undefined | null)) {
            setError("");
            if (organization) {
                setIsLoading(true);
                const membershipTypes = await getMembershipApplications(organization.id);
                let memberships: Membership[] | undefined = undefined;
                if (currentUser) memberships = await getMemberships(currentUser.id, organization.id);
                if (membershipTypes) formatApplicationOptions(membershipTypes, memberships);
                setIsLoading(false);
            }
        }
        
        getData(organization, user);
    }, [user, organization]);

    const handleClick = (link: string) => {
        history.push(link);
    }   

    return (
        <React.Fragment>
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ? 
                <Spinner />
                : 
                <>
                    {(formattedOptions && formattedOptions.length > 0) ?
                        <>
                            {formattedOptions.map((formattedOption, index) => (
                                <div key={index} className="ion-text-wrap">
                                    <h4>
                                        {formattedOption.membershipTypeName}
                                    </h4>
                                    <ul>
                                        {formattedOption.userApplications?.map((userApplication, i) => (
                                            <li key={i}>
                                                <p className={"link " + (userApplication.status === "Completed" ? "text-success" : (userApplication.status === "In Progress" ? "text-warning" : "text-primary"))} onClick={() => handleClick(userApplication.applicationLink)}>
                                                    {userApplication.displayString}
                                                </p>
                                            </li>
                                        ))}
                                        <li>
                                            <p className="link" onClick={() => handleClick(formattedOption.newApplicationLink)}>
                                                Start New Application
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            ))}
                        </>
                        :
                        <p>No applications were found.</p>
                    }
                </>
            }
        </React.Fragment>
    );
};

export default MembershipApplicationsListNew;
