import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Spinner from "../../Spinners/Spinner";
import { Event, EventAuditorFilter, EventFilter, Organization } from "../../../models";
import ErrorAlert from "../../Errors/ErrorAlert";
import { getEventFiltersByEventId, getEventFiltersByOrganizationId } from "../../../utilities/eventFilter/EventFilter";

export interface FormattedOption {
    name: string
    isSelected: boolean
}

export interface FormattedFilter {
    name: string
    isExpanded: boolean
    options: FormattedOption[]
    filter: EventFilter
}

interface _Props {
    startWithoutSelections?: boolean
    isExpandedOnStart?: boolean
    event?: Event
    organization?: Organization
    currentSelectedFilters?: (EventAuditorFilter[] | null),
    onSelect: Function
}

const SelectEventFiltersForm: React.FC<_Props> = ({event, organization, currentSelectedFilters, isExpandedOnStart, onSelect, startWithoutSelections}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [eventFilters, setEventFilters] = useState<EventFilter[] | null | undefined>();
    const [formattedFilters, setFormattedFilters] = useState<FormattedFilter[] | null | undefined>();
    const [error, setError] = useState("");

    const formatEventFilters = (eventFilters: EventFilter[], currentSelections?: (EventAuditorFilter[] | null)) => {
        setIsLoading(true);
        let formattedArray: FormattedFilter[] = [];
        let selectedArray: string[] = [];
        if (eventFilters) {
            for (var i = 0; i < eventFilters.length; i++) {
                const currentEventFilter = eventFilters[i];
                if (currentEventFilter.name !== "Roles") {
                    if (currentEventFilter.options && currentEventFilter.options.length > 0) {
                        let options: FormattedOption[] = [];
                        // Use formatted options to track which options on a filter are selected
                        for (var j = 0; j < currentEventFilter.options.length; j++) {
                            const currentOption: string | null = currentEventFilter.options[j];
                            if (currentOption) {
                                // Normally, we want to select all filters to assume user will subscribe to all alerts
                                let isSelected = startWithoutSelections ? false : true; 
                                if (currentSelections) {
                                    // If the user has already subscribed to alerts, assume each option is unselected until it is seen
                                    isSelected = false; 
                                    currentSelections.forEach(currentFilter => {
                                        // Cycle through the EventAuditorFilter objects to determine which options to check
                                        const filterId = currentFilter.filterId;
                                        if (filterId === currentEventFilter.id) {
                                            // Once you find the EventAuditorFilter that matches the current filter, compare the options
                                            const currentFilterOptions = currentFilter.options;
                                            if (currentFilterOptions) {
                                                currentFilterOptions.forEach(option => {
                                                    if (option === currentOption) isSelected = true;
                                                });
                                            }
                                        }
                                    });
                                }
                                const formattedOption: FormattedOption = {
                                    name: currentOption,
                                    isSelected: isSelected
                                };
                                options.push(formattedOption);
                                selectedArray.push(currentOption)
                            }
                        }
                        const formatted: FormattedFilter = {
                            name: currentEventFilter.name || "",
                            options: options,
                            isExpanded: isExpandedOnStart || false,
                            filter: currentEventFilter
                        };
                        formattedArray.push(formatted);
                    }
                }
            }
        }
        setFormattedFilters(formattedArray);
        setIsLoading(false);
    }
    
    useEffect(() => {
        async function getEventFilters(event: Event) {
            const queryResult = await getEventFiltersByEventId(event.id);
            if (queryResult.isSuccess) {
                setEventFilters(queryResult.result);
                formatEventFilters(queryResult.result, currentSelectedFilters);
            }
        }

        async function getOrganizationFilters(organization: Organization) {
            const queryResult = await getEventFiltersByOrganizationId(organization.id);
            if (queryResult.isSuccess) {
                setEventFilters(queryResult.result);
                formatEventFilters(queryResult.result, currentSelectedFilters);
            }
        }

        if (event) getEventFilters(event);
        if (organization) getOrganizationFilters(organization);
    }, [event, organization, currentSelectedFilters]);

    useEffect(() => {
        if (eventFilters) {
            formatEventFilters(eventFilters, currentSelectedFilters);
        }
    }, [currentSelectedFilters]);

    const handleExpandCollapse = (formattedFilterIndex: number, formattedFilter: FormattedFilter, isExpand: boolean) => {
        if (formattedFilters) {
            const updatedFilter: FormattedFilter = {
                name: formattedFilter.name,
                filter: formattedFilter.filter,
                isExpanded: isExpand,
                options: formattedFilter.options
            }
            const updatedFilters: FormattedFilter[] = [
                ...formattedFilters.slice(0, formattedFilterIndex),
                updatedFilter,
                ...formattedFilters.slice(formattedFilterIndex+1)
            ];
            setFormattedFilters(updatedFilters);
            onSelect(updatedFilters);
        }
    }

    const handleSelect = (formattedFilterIndex: number, formattedFilter: FormattedFilter, index: number, option: FormattedOption) => {
        if (formattedFilters) {
            const updatedOption: FormattedOption = {
                name: option.name,
                isSelected: !option.isSelected
            };
            const updatedFilter: FormattedFilter = {
                name: formattedFilter.name,
                filter: formattedFilter.filter,
                isExpanded: formattedFilter.isExpanded,
                options: [
                    ...formattedFilter.options.slice(0, index),
                    updatedOption,
                    ...formattedFilter.options.slice(index+1),
                ]
            }
            const updatedFilters: FormattedFilter[] = [
                ...formattedFilters.slice(0, formattedFilterIndex),
                updatedFilter,
                ...formattedFilters.slice(formattedFilterIndex+1)
            ];
            setFormattedFilters(updatedFilters);
            onSelect(updatedFilters);
        }
    }

    const handleSelectAll = (formattedFilterIndex: number, formattedFilter: FormattedFilter, isSelectTrue: boolean) => {
        if (formattedFilters) {
            const currentFilterOptions = formattedFilter.options;

            let updatedOptions: FormattedOption[] = [];
            for (let i = 0; i < currentFilterOptions.length; i++) {
                const currentOption = currentFilterOptions[i];
                const updatedOption = {
                    ...currentOption,
                    isSelected: isSelectTrue
                };
                updatedOptions.push(updatedOption);
            }

            const updatedFilter: FormattedFilter = {
                name: formattedFilter.name,
                filter: formattedFilter.filter,
                isExpanded: formattedFilter.isExpanded,
                options: updatedOptions
            }
            const updatedFilters: FormattedFilter[] = [
                ...formattedFilters.slice(0, formattedFilterIndex),
                updatedFilter,
                ...formattedFilters.slice(formattedFilterIndex+1)
            ];

            setFormattedFilters(updatedFilters);
            onSelect(updatedFilters);
        }
    }

    return (
        <div>
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ?
                <Spinner />
                :
                <>
                    {(formattedFilters && formattedFilters.length > 0) && (
                        <>
                            {formattedFilters.map((formattedFilter, index) => (
                                <div key={index}>
                                    <IonRow>
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <h6>
                                                {formattedFilter.name} 
                                                <span className="description link" onClick={() => handleExpandCollapse(index, formattedFilter, !formattedFilter.isExpanded)}>
                                                    ({formattedFilter.isExpanded ? "Collapse" : "Edit"})
                                                </span>
                                            </h6>
                                        </IonCol>
                                        {formattedFilter.isExpanded && (
                                            <>
                                                <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="3">
                                                    <IonButton size="small" onClick={() => handleSelectAll(index, formattedFilter, true)}>
                                                        Select All
                                                    </IonButton>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="3">
                                                    <IonButton size="small" color="light" onClick={() => handleSelectAll(index, formattedFilter, false)}>
                                                        Deselect All
                                                    </IonButton>
                                                </IonCol>
                                            </>
                                        )}
                                    </IonRow>
                                    {formattedFilter.isExpanded ? 
                                        <IonRow>
                                            <IonCol>
                                                {(formattedFilter.options && formattedFilter.options.length > 0) && (
                                                    <IonList className="bg-white">  
                                                        {formattedFilter.options.map((formattedOption, i) => (
                                                            <IonItem key={i}>
                                                                <IonLabel className="ion-text-wrap">{formattedOption.name}</IonLabel>
                                                                <IonCheckbox color="primary" slot="start" checked={formattedOption.isSelected} onClick={() => handleSelect(index, formattedFilter, i, formattedOption)} />
                                                            </IonItem>
                                                        ))}
                                                    </IonList>
                                                )}
                                            </IonCol>
                                        </IonRow>
                                        :
                                        <IonRow>
                                            <IonCol>
                                                {(formattedFilter.options && formattedFilter.options.length > 0) && (
                                                    <ul>  
                                                        {formattedFilter.options.map((formattedOption, i) => (
                                                            <div key={i}>
                                                                {formattedOption.isSelected && (
                                                                    <li><IonLabel className="ion-text-wrap">{formattedOption.name}</IonLabel></li>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </ul>
                                                )}
                                            </IonCol>
                                        </IonRow>
                                    }
                                </div>
                            ))}
                        </>
                    )}
                </>
            }
        </div>
    );
};

export default SelectEventFiltersForm;