import React, { useEffect, useState } from "react";
import { Event, EventEntry, Barn } from "../../../../../models";
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCol, IonLabel, IonRow } from "@ionic/react";
import SelectFromAllBarns from "../../../../Barn/SelectFromAllBarns";
import Spinner from "../../../../Spinners/Spinner";
import { updateEventEntry } from "../../../../../utilities/eventEntry/EventEntry";
import { UpdateEventEntryInput } from "../../../../../API";
import SuccessBanner from "../../../../Banners/SuccessBanner";
import ErrorAlert from "../../../../Errors/ErrorAlert";

interface _Props {
    entry: EventEntry
    event: Event
    onSubmit: Function
}

const EditEntrySelectedBarnCard: React.FC<_Props> = ({entry, onSubmit}) => {

    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [currentBarn, setCurrentBarn] = useState<Barn | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const getEntryInfo = (entry: EventEntry) => {
        setCurrentEntry(entry);
        if (entry.barn) {
            setCurrentBarn(entry.barn);
        }
    }

    useEffect(() => {
        if (entry) {
            getEntryInfo(entry);
        }
    }, [entry]);

    const handleSave = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");

        const input: UpdateEventEntryInput = {
            id: entry.id,
            barnId: currentBarn?.id || "",
            barnName: currentBarn?.name || "",
        };

        const updateResult = await updateEventEntry(input);
        if (updateResult.isSuccess) {
            setSuccess("Successfully updated the barn listed on this entry.");

            const updatedEntry = updateResult.result;
            onSubmit(updatedEntry);
        } else {
            setError("Error - this entry could not be updated with the selected barn.")
        }

        setIsLoading(false);
    }

    return (
        <>
            {currentEntry && (
                <>
                    <IonCard color="white" className="ion-padding">
                        <IonCardTitle>
                            <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                                <IonCol>
                                    <h4>Barn in Database</h4>
                                </IonCol>
                                <IonCol className="ion-text-right">
                                    <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                                </IonCol>
                            </IonRow>
                        </IonCardTitle>
                        {!isCollapsed && (
                            <IonCardContent>
                                <IonRow>
                                    <IonCol size="12">
                                        <p className="description">This entry has selected the following barn from the RingSide Pro database.</p>
                                        <p className="description">You can find a different barn in the database to list on this entry. Then click save to update this entry.</p>
                                        <p className="description">Or keep the selected barn, and scroll down to edit the info for this barn.</p>
                                    </IonCol>
                                </IonRow>
                                <hr/>
                                <IonRow className="ion-justify-content-center">
                                    {success && <SuccessBanner success={success} width="12" />}
                                    {error && <ErrorAlert error={error} width="12" />}
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <IonLabel position="stacked">
                                            <p>Selected Barn from Database</p>
                                            <span className="description-smaller">(start typing to filter options in the dropdown)</span>
                                        </IonLabel>
                                        <SelectFromAllBarns selectedValue={currentBarn?.id} onSelect={(barn: Barn) => setCurrentBarn(barn)} />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <p className="description">Barn's RingSide Pro id: {currentBarn ? currentBarn.id : "unknown"}</p>
                                    </IonCol>
                                </IonRow>
                                {isLoading ?
                                    <Spinner />
                                    :
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol sizeMd="4" className="ion-text-center">
                                            <IonButton
                                                className="ion-margin-top"
                                                color="tertiary"
                                                expand="block"
                                                onClick={handleSave}
                                            >
                                                Save
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                }
                            </IonCardContent>
                        )}
                    </IonCard>
                </>
            )}
        </>
    );
};

export default EditEntrySelectedBarnCard;