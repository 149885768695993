import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";
import formatResult from "../ReturnType/ReturnType";
import { CreateSeasonInput, CreateSeasonMutation, DeleteSeasonInput, DeleteSeasonMutation, GetSeasonQuery, GetSeasonsByOrganizationIdQuery, ModelSortDirection, UpdateSeasonInput, UpdateSeasonMutation} from "../../API";
import moment from "moment";
import { Season } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: Season. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created season.
*/
export async function createSeason(input: CreateSeasonInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Season", "No input", "Create season received no input.");
   try {
        const fullInput: CreateSeasonInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) {
           result = (await API.graphql(
               graphqlOperation(mutations.createSeason, { input: fullInput })
           )) as GraphQLResult<CreateSeasonMutation>;
       } else {
           result = (await API.graphql({
               query: mutations.createSeason,
               variables: {
                   input: fullInput
               },
               authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY,
           })) as GraphQLResult<CreateSeasonMutation>;
       }
       const season = result.data?.createSeason;
       return formatResult(true, "Season", season, "Successfully created the season.");
   } catch (error: any) {
       return formatResult(false, "Season", error, "Error creating record in the database for type: season");
   }
}

/**
* Handle updating a new record in the database for type: season. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated season.
*/
export async function updateSeason(input: UpdateSeasonInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "season", "No input", "Update season received no input.");
    try {
         const fullInput: UpdateSeasonInput = {
             ...input,
             updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
         };
        let result;
        if (!authMode) {
            result = (await API.graphql(
                graphqlOperation(mutations.updateSeason, { input: fullInput })
            )) as GraphQLResult<UpdateSeasonMutation>;
        } else {
            result = (await API.graphql({
                query: mutations.updateSeason,
                variables: {
                    input: fullInput
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY,
            })) as GraphQLResult<UpdateSeasonMutation>;
        }
        const season = result.data?.updateSeason;
        return formatResult(true, "Season", season, "Successfully updated the season.");
    } catch (error: any) {
        return formatResult(false, "Season", error, "Error updating record in the database for type: season");
    }
 }

/**
* Handle deleting a new record in the database for type: season. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted season.
*/
export async function deleteSeason(input: DeleteSeasonInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "Season", "No input", "Delete Season received no input.");
    try {
        let result;
        if (!authMode) {
            result = (await API.graphql(
                graphqlOperation(mutations.deleteSeason, { input: input })
            )) as GraphQLResult<DeleteSeasonMutation>;
        } else {
            result = (await API.graphql({
                query: mutations.deleteSeason,
                variables: {
                    input: input
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY,
            })) as GraphQLResult<DeleteSeasonMutation>;
        }
        const season = result.data?.deleteSeason;
        return formatResult(true, "Season", season, "Successfully deleted the season.");
    } catch (error: any) {
        return formatResult(false, "Season", error, "Error deleting record in the database for type: season");
    }
}

  /**
* Read a specific record in the database for type: season. 
* 
* @param {string}  id                  The season id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the season object
*/
export async function getSeasonById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.getSeason,
            variables: {
                id: id
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<GetSeasonQuery>;
        const season = result.data?.getSeason;
        if (season) {
            return formatResult(true, "Season", season, "Successfully got the season.");
        } else {
            return formatResult(false, "Season", null, "No season was found.");
        }
    } catch (error: any) {
        return formatResult(false, "Season", error, "Error reading record in the database for type: season");
    }
}

/**
* Get all records in the database that match the given criteria for type: season. 
* 
* @param {string}  organizationId         The organization Id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the season object
*/
export async function getSeasonsByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.getSeasonsByOrganizationId,
            variables: {
                limit: 1000,
                organizationId: organizationId,
                sortDirection: ModelSortDirection.DESC
            },
            
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<GetSeasonsByOrganizationIdQuery>;

        let items = result.data?.getSeasonsByOrganizationId?.items as Season[];
        let nextToken = result.data?.getSeasonsByOrganizationId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.getSeasonsByOrganizationId,
                variables: {
                    limit: 100,
                    organizationId: organizationId,
                    sortDirection: ModelSortDirection.DESC,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<GetSeasonsByOrganizationIdQuery>;

            const nextItems = nextResult.data?.getSeasonsByOrganizationId?.items as Season[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.getSeasonsByOrganizationId?.nextToken;
        }
        return formatResult(true, "Season", items, "Successfully got the Seasons.");
    } catch (error: any) {
        return formatResult(false, "Season", error, "Error reading record in the database for type: Seasons");
    }
}
