import {
    IonButton
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import { Organization, OrganizationAuditor } from "../../models";
import { getOrganizationAuditorsByOrganizationId } from "../../utilities/organizationAuditor/OrganizationAuditor";
import ErrorAlert from "../Errors/ErrorAlert";
import OrganizationAuditorModal from "../OrganizationAuditor/OrganizationAuditorModal";
import Spinner from "../Spinners/Spinner";

interface _Props {
    organization: Organization
    organizationAuditor?: OrganizationAuditor
}

const OrganizationAuditorSignUpButton: React.FC<_Props> = ({organization, organizationAuditor}) => {    
    const [organizationAuditors, setOrganizationAuditors] = useState<OrganizationAuditor[] | null | undefined>();
    const [selectedOrganizationAuditor, setSelectedOrganizationAuditor] = useState<OrganizationAuditor | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOrganizationAuditors(organization: Organization) {
            const queryResult = await getOrganizationAuditorsByOrganizationId(organization.id);
            if (queryResult.isSuccess) {
                const organizationAuditors: OrganizationAuditor[] = queryResult.result;
                setOrganizationAuditors(organizationAuditors);
            }
        }
        if (organization) {
            getOrganizationAuditors(organization);
        }
    }, [organization]);

    useEffect(() => {
        if (organizationAuditor) {
            if (organizationAuditors) {
                const updated = organizationAuditors.concat(organizationAuditor);
                setOrganizationAuditors(updated);
            } else {
                setOrganizationAuditors([organizationAuditor]);
            }
        }
    }, [organizationAuditor]);

    const handleSelectedSubscriber = (organizationAuditor: OrganizationAuditor) => {
        setSelectedOrganizationAuditor(organizationAuditor);
        setShowModal(true);
    }

    const handleOrganizationAuditorChange = (action: string, organizationAuditor: OrganizationAuditor) => {
        let updated: OrganizationAuditor[] = [organizationAuditor];
        if (action === "create") {
            if (organizationAuditors) updated = updated.concat(organizationAuditors);
        } else if (action === "delete") {
            if (organizationAuditors) {
                const deletedIndex = organizationAuditors.findIndex(eA => organizationAuditor.id === eA.id);
                updated = [
                    ...organizationAuditors.slice(0, deletedIndex),
                    ...organizationAuditors.slice(deletedIndex + 1)
                ];
            }
        } else {
            if (organizationAuditors) {
                const index = organizationAuditors.findIndex(auditor => auditor.id === organizationAuditor.id);
                updated = [
                    ...organizationAuditors.slice(0, index),
                    organizationAuditor,
                    ...organizationAuditors.slice(index + 1)
                ];
            } 
        }
        setOrganizationAuditors(updated);
        setShowModal(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ?
                <Spinner />
                :
                <>
                    <IonButton color="success" onClick={() => setShowModal(true)}>Get Alerts</IonButton>
                </>
            }
            <OrganizationAuditorModal show={showModal} organizationAuditor={selectedOrganizationAuditor} organization={organization} onChange={(action: string, organizationAuditor: OrganizationAuditor) => handleOrganizationAuditorChange(action, organizationAuditor)} />
        </>
    );
};

export default OrganizationAuditorSignUpButton;
