import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventEntryFeeInput, CreateEventEntryFeeMutation, DeleteEventEntryFeeInput, DeleteEventEntryFeeMutation, GetEventEntryFeeQuery, ListEventEntryFeesQuery, UpdateEventEntryFeeInput, UpdateEventEntryFeeMutation } from "../../API";
import moment from "moment";
import { EventEntryFee } from "../../models";
import { sortEventEntryFeesByName } from "./SortEventEntryFees";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventEntryFee. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventEntryFee.
*/
export async function createEventEntryFee(input: CreateEventEntryFeeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventEntryFee", "No input", "Create EventEntryFee received no input.");
   try {
        const fullInput: CreateEventEntryFeeInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventEntryFee, { input: fullInput }))) as GraphQLResult<CreateEventEntryFeeMutation>;
       else result = (await API.graphql({
           query: mutations.createEventEntryFee,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventEntryFeeMutation>;
       const eventEntryFee = result.data?.createEventEntryFee;
       return formatResult(true, "EventEntryFee", eventEntryFee, "Successfully created the eventEntryFee.");
   } catch (error: any) {
       return formatResult(false, "EventEntryFee", error, "Error creating record in the database for type: eventEntryFee");
   }
}

/**
* Handle updating a new record in the database for type: eventEntryFee. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventEntryFee.
*/
export async function updateEventEntryFee(input: UpdateEventEntryFeeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventEntryFee", "No input", "Update EventEntryFee received no input.");
   try {
        const fullInput: UpdateEventEntryFeeInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventEntryFee, { input: fullInput }))) as GraphQLResult<UpdateEventEntryFeeMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventEntryFee,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventEntryFeeMutation>;
       const eventEntryFee = result.data?.updateEventEntryFee;
       return formatResult(true, "EventEntryFee", eventEntryFee, "Successfully updated the eventEntryFee.");
   } catch (error: any) {
       return formatResult(false, "EventEntryFee", error, "Error updating record in the database for type: eventEntryFee");
   }
}

/**
* Handle deleting a new record in the database for type: eventEntryFee. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventEntryFee.
*/
export async function deleteEventEntryFee(input: DeleteEventEntryFeeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventEntryFee", "No input", "Delete EventEntryFee received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventEntryFee, { input: input }))) as GraphQLResult<DeleteEventEntryFeeMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventEntryFee,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventEntryFeeMutation>;
       const eventEntryFee = result.data?.deleteEventEntryFee;
       return formatResult(true, "EventEntryFee", eventEntryFee, "Successfully deleted the eventEntryFee.");
   } catch (error: any) {
       return formatResult(false, "EventEntryFee", error, "Error deleting record in the database for type: eventEntryFee");
   }
}

/**
* Get all records in the database for type: eventEntryFee. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntryFee object
*/
export async function getAllEventEntryFees(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventEntryFees,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventEntryFeesQuery>;

        let items = result.data?.listEventEntryFees?.items as EventEntryFee[];
        let nextToken = result.data?.listEventEntryFees?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventEntryFees,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventEntryFeesQuery>;

            const nextItems = nextResult.data?.listEventEntryFees?.items as EventEntryFee[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventEntryFees?.nextToken;
        }

        return formatResult(true, "EventEntryFee", items, "Successfully got the EventEntryFees.");
    } catch (error: any) {
        return formatResult(false, "EventEntryFee", error, "Error reading record in the database for type: eventEntryFees");
    }
}

/**
* Read a specific record in the database for type: eventEntryFee. 
* 
* @param {string}  id                  The eventEntryFee id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntryFee object
*/
export async function getEventEntryFeeById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventEntryFee,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventEntryFeeQuery>;
       const eventEntryFee = result.data?.getEventEntryFee;
       return formatResult(true, "EventEntryFee", eventEntryFee, "Successfully got the eventEntryFee.");
   } catch (error: any) {
       return formatResult(false, "EventEntryFee", error, "Error reading record in the database for type: eventEntryFee");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventEntryFee. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntryFee object
*/
export async function getEventEntryFeesByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventEntryFees,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventEntryFeesQuery>;

        let items = result.data?.listEventEntryFees?.items as EventEntryFee[];
        let nextToken = result.data?.listEventEntryFees?.nextToken;

        if (nextToken) {
            while (nextToken) {
                const nextResult = await API.graphql({
                    query: queries.listEventEntryFees,
                    variables: {
                        filter,
                        limit: 1000,
                        nextToken
                    },
                    authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
                }) as GraphQLResult<ListEventEntryFeesQuery>;
        
                const nextItems = nextResult.data?.listEventEntryFees?.items as EventEntryFee[];
                if (nextItems && nextItems.length) {
                    items = items?.concat(nextItems);
                }
                nextToken = nextResult.data?.listEventEntryFees?.nextToken;
            }
        }

        if (items && items.length > 0) {
            return formatResult(true, "EventEntryFee", items, "Successfully got the eventEntryFees.");
        } else {
            return formatResult(false, "EventEntryFee", null, "Found no EventEntryFees.");
        }
    } catch (error: any) {
        return formatResult(false, "EventEntryFee", error, "Error reading record in the database for type: eventEntryFees");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventEntryFee. 
* 
* @param {string}  eventId             The event id
* @param {string}  entryId             The entry id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventEntryFee object
*/
export async function getEventEntryFeesByEventIdByEntryId(eventId: string, entryId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventEntryFeesByEventEntryId,
            variables: {
                limit: 1000,
                entryId: entryId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.eventEntryFeesByEventEntryId?.items as EventEntryFee[];
        let nextToken = result.data?.eventEntryFeesByEventEntryId?.nextToken;

        if (nextToken) {
            while (nextToken) {
                const nextResult = await API.graphql({
                    query: queries.eventEntryFeesByEventEntryId,
                    variables: {
                        entryId: entryId,
                        limit: 1000,
                        nextToken
                    },
                    authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
                }) as GraphQLResult<any>;
        
                const nextItems = nextResult.data?.eventEntryFeesByEventEntryId?.items as EventEntryFee[];
                if (nextItems && nextItems.length) {
                    items = items?.concat(nextItems);
                }
                nextToken = nextResult.data?.eventEntryFeesByEventEntryId?.nextToken;
            }
        }

        if (items && items.length > 0) {
            const sorted = sortEventEntryFeesByName(items);
            if (sorted) {
                return formatResult(true, "EventEntryFee", sorted, "Successfully got the eventEntryFees.");
            } else {    
                return formatResult(true, "EventEntryFee", items, "Successfully got the eventEntryFees.");
            }
        } else {
            return formatResult(false, "EventEntryFee", null, "Found no EventEntryFees.");
        }
    } catch (error: any) {
        return formatResult(false, "EventEntryFee", error, "Error reading record in the database for type: eventEntryFees");
    }
}