import {
    IonContent,
    IonPage,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Header from "../../../../../../components/Headers/Header";
import { Organization } from "../../../../../../models";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import Spinner from "../../../../../../components/Spinners/Spinner";
import { getOrganizationById } from "../../../../../../utilities/organization/Organization";
import OrganizationMembershipTypesTable from "../../../../../../components/MembershipTypes/OrganizationMembershipTypesTable";
import ErrorAlert from "../../../../../../components/Errors/ErrorAlert";

interface _Props extends RouteComponentProps<{
    id: string;
}> {}

const EventOrganizationMembershipTypesInfoPage: React.FC<_Props> = ({match}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [organization, setOrganization] = useState<Organization>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOrganization() {
            try {
                setIsLoading(true);
                const queryResult = await getOrganizationById(match.params.id);
                if (queryResult.isSuccess) {
                    setOrganization(queryResult.result);
                } else {
                    setError(queryResult.message);
                }
                setIsLoading(false);
            } catch (error: any) {
                setError("Sorry, a problem occurred. Please go back and try again.");
                setIsLoading(false);
            }
        }

        getOrganization();
    }, [match, match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={`${(organization?.nickname || organization?.name) ?? "Org Member Types"}`} />
                {error && <ErrorAlert width="12" error={error} />}
                {isLoading ?
                    <Spinner />
                    :
                    <>
                        {organization ?
                            <OrganizationMembershipTypesTable organizationId={organization.id} />
                            :
                            <p>No organization found</p>
                        }
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationMembershipTypesInfoPage;
