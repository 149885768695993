import {
    IonButton,
    IonButtons,
    IonHeader,
    IonToolbar
} from "@ionic/react";
import React, { useState } from "react";

interface _Props {
    active: string
    onSelect: Function
}

const EntryPageNavbar: React.FC<_Props> = ({active, onSelect}) => {

    const [currentActiveBtn, setCurrentActiveBtn] = useState(active || "about");

    const handleClick = (value: string) => {
        setCurrentActiveBtn(value);
        onSelect(value);
    }

    return (
        <IonHeader id="editEntryMenu">
            <IonToolbar id="editEntryMenu" color="light">
                <div className="scrollmenu">
                    <IonButtons>
                        <IonButton onClick={() => handleClick("about")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "about" ? "active-border" : "")}>
                                About
                            </p>
                        </IonButton>
                        <IonButton onClick={() => handleClick("classes")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "classes" ? "active-border" : "")}>
                                Classes
                            </p>
                        </IonButton>
                        <IonButton onClick={() => handleClick("fees")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "fees" ? "active-border" : "")}>
                                Fees
                            </p>
                        </IonButton>
                        <IonButton onClick={() => handleClick("stabling")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "stabling" ? "active-border" : "")}>
                                Stabling
                            </p>
                        </IonButton>
                        <IonButton onClick={() => handleClick("results")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "results" ? "active-border" : "")}>
                                Results
                            </p>
                        </IonButton>
                        <IonButton onClick={() => handleClick("invoice")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "invoice" ? "active-border" : "")}>
                                Invoice
                            </p>
                        </IonButton>
                    </IonButtons>
                </div>
            </IonToolbar>
        </IonHeader>
    );
};

export default EntryPageNavbar;
