import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../../context/PersonContext";
import { Event, HorseShow, HorseShowRating } from "../../../models";
import { getHorseShowByEventId, updateHorseShow } from "../../../utilities/horseShow/HorseShow";
import HorseShowRatingForm from "../../HorseShowRating/HorseShowRatingForm";
import ErrorAlert from "../../Errors/ErrorAlert";
import Spinner from "../../Spinners/Spinner";
import { CreateHorseShowRatingInput, UpdateHorseShowInput } from "../../../API";
import { getHorseShowRatingsByEventId } from "../../../utilities/horseShowRating/HorseShowRating";

interface _Props {
    event: Event
}

const EventRatingForm: React.FC<_Props> = ({event}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [horseShow, setHorseShow] = useState<HorseShow | null | undefined>();
    const [eventRatingArray, setEventRatingArray] = useState<(HorseShowRating | null)[] | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    async function getHorseShow(eventId: string) {
        const queryResult = await getHorseShowByEventId(eventId);
        if (queryResult.isSuccess) {
            const horseShow = queryResult.result;
            setHorseShow(horseShow);
        }
    };

    async function getHorseShowRatings(eventId: string) {
        const queryResult = await getHorseShowRatingsByEventId(eventId);
        if (queryResult.isSuccess) {
            const ratings = queryResult.result;
            setEventRatingArray(ratings);
        }
    };

    useEffect(() => {
        if (event) {
            getHorseShow(event.id); 
            getHorseShowRatings(event.id);
        }
    }, [event]);

    const handleSubmit = async (newRating: HorseShowRating) => {
        setIsLoading(true);
        if (event) {
            setError("");
            let ratingArray: (HorseShowRating | null)[] = [];
            if (eventRatingArray && eventRatingArray.length) {
                ratingArray = eventRatingArray.concat([newRating]);
            } else {
                ratingArray.push(newRating);
            }
            setEventRatingArray(ratingArray);
        } else {
            setError("Error: no horse show found. Please contact hello@ringsidepro.com");
        }
        setIsLoading(false);
        setShowModal(false);
    }
    
    const handleDelete = async (horseShowRating?: HorseShowRating) => {
        let newRatingArray = [];
        if (horseShow && eventRatingArray && eventRatingArray.length) {
            for (var i = 0; i < eventRatingArray?.length; i++) {
                const current = eventRatingArray[i];
                if (current && horseShowRating) {
                    if (current.governingBody !== horseShowRating.governingBody || current.rating !== horseShowRating.rating) {
                        newRatingArray.push(current);
                    }
                }
            }
            setEventRatingArray(newRatingArray);
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {eventRatingArray && eventRatingArray.length ?
                <>
                    <IonList className="bg-white">
                        {eventRatingArray.map((rating, index) => (
                            <IonItem key={index}>
                                <IonLabel><h3 className="ion-text-wrap text-darker">{rating && rating.governingBody} {rating && rating.rating ? "- " + rating.rating : ""}</h3></IonLabel>
                                <IonButton slot="end" color="danger" onClick={() => handleDelete(rating as HorseShowRating)}><IonIcon icon={close}/></IonButton>
                            </IonItem>
                        ))}
                    </IonList>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                Add Rating
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                Add Rating
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
            <IonModal backdropDismiss={false} isOpen={showModal} id="addEventRatingModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Rating
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeRatingModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <HorseShowRatingForm event={event} onSubmit={handleSubmit} />
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventRatingForm;