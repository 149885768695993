import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateOrganizationDivisionInput, CreateOrganizationDivisionMutation, DeleteOrganizationDivisionInput, DeleteOrganizationDivisionMutation, GetOrganizationDivisionQuery, ListOrganizationDivisionsQuery, UpdateOrganizationDivisionInput, UpdateOrganizationDivisionMutation } from "../../API";
import moment from "moment";
import { OrganizationDivision } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: organizationDivision. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created organizationDivision.
*/
export async function createOrganizationDivision(input: CreateOrganizationDivisionInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationDivision", "No input", "Create OrganizationDivision received no input.");
   try {
        const fullInput: CreateOrganizationDivisionInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createOrganizationDivision, { input: fullInput }))) as GraphQLResult<CreateOrganizationDivisionMutation>;
       else result = (await API.graphql({
           query: mutations.createOrganizationDivision,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateOrganizationDivisionMutation>;
       const organizationDivision = result.data?.createOrganizationDivision;
       return formatResult(true, "OrganizationDivision", organizationDivision, "Successfully created the organizationDivision.");
   } catch (error: any) {
       return formatResult(false, "OrganizationDivision", error, "Error creating record in the database for type: organizationDivision");
   }
}

/**
* Handle updating a new record in the database for type: organizationDivision. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated organizationDivision.
*/
export async function updateOrganizationDivision(input: UpdateOrganizationDivisionInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationDivision", "No input", "Update OrganizationDivision received no input.");
   try {
        const fullInput: UpdateOrganizationDivisionInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateOrganizationDivision, { input: fullInput }))) as GraphQLResult<UpdateOrganizationDivisionMutation>;
       else result = (await API.graphql({
           query: mutations.updateOrganizationDivision,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateOrganizationDivisionMutation>;
       const organizationDivision = result.data?.updateOrganizationDivision;
       return formatResult(true, "OrganizationDivision", organizationDivision, "Successfully updated the organizationDivision.");
   } catch (error: any) {
       return formatResult(false, "OrganizationDivision", error, "Error updating record in the database for type: organizationDivision");
   }
}

/**
* Handle deleting a new record in the database for type: organizationDivision. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted organizationDivision.
*/
export async function deleteOrganizationDivision(input: DeleteOrganizationDivisionInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "OrganizationDivision", "No input", "Delete OrganizationDivision received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteOrganizationDivision, { input: input }))) as GraphQLResult<DeleteOrganizationDivisionMutation>;
       else result = (await API.graphql({
           query: mutations.deleteOrganizationDivision,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteOrganizationDivisionMutation>;
       const organizationDivision = result.data?.deleteOrganizationDivision;
       return formatResult(true, "OrganizationDivision", organizationDivision, "Successfully deleted the organizationDivision.");
   } catch (error: any) {
       return formatResult(false, "OrganizationDivision", error, "Error deleting record in the database for type: organizationDivision");
   }
}

/**
* Get all records in the database for type: organizationDivision. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationDivision object
*/
export async function getAllOrganizationDivisions(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listOrganizationDivisions,
            variables: {
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.listOrganizationDivisions?.items;
        let nextToken = result.data?.listOrganizationDivisions?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOrganizationDivisions,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.listOrganizationDivisions?.items;
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOrganizationDivisions?.nextToken;
        }

        return formatResult(true, "OrganizationDivision", items, "Successfully got the OrganizationDivisions.");
    } catch (error: any) {
        return formatResult(false, "OrganizationDivision", error, "Error reading record in the database for type: organizationDivisions");
    }
}

/**
* Read a specific record in the database for type: organizationDivision. 
* 
* @param {string}  id                  The organizationDivision id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationDivision object
*/
export async function getOrganizationDivisionById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.getOrganizationDivision,
            variables: {
                id: id
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<GetOrganizationDivisionQuery>;
        const organizationDivision = result.data?.getOrganizationDivision;
        return formatResult(true, "OrganizationDivision", organizationDivision, "Successfully got the organizationDivision.");
    } catch (error: any) {
        return formatResult(false, "OrganizationDivision", error, "Error reading record in the database for type: organizationDivision");
    }
}

/**
* Get all records in the database that match the given criteria for type: organizationDivision. 
* 
* @param {string}  organizationId      The organization id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationDivision object
*/
export async function getOrganizationDivisionsByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.organizationDivisionsByOrganizationId,
            variables: {
                limit: 1000,
                organizationId: organizationId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.organizationDivisionsByOrganizationId?.items as OrganizationDivision[];
        let nextToken = result.data?.organizationDivisionsByOrganizationId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.organizationDivisionsByOrganizationId,
                variables: {
                    limit: 100,
                    organizationId: organizationId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.organizationDivisionsByOrganizationId?.items as OrganizationDivision[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.organizationDivisionsByOrganizationId?.nextToken;
        }

        return formatResult(true, "OrganizationDivision", items, "Successfully got the OrganizationDivisions.");
    } catch (error: any) {
        return formatResult(false, "OrganizationDivision", error, "Error reading record in the database for type: organizationDivisions");
    }
}