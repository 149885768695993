import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import { Organization } from "../../../../../models";
import OrganizationAddMembersForm from "../../../../../components/Organization/OrganizationAddMembersForm";
import OrganizationInvitationsList from "../../../../../components/Organization/OrganizationInvitationsList";
import OrganizationMemberList from "../../../../../components/Organization/OrganizationMemberList";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import Spinner from "../../../../../components/Spinners/Spinner";
import { PersonContext } from "../../../../../context/PersonContext";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import { getUser } from "../../../../../utilities/user/User";
import { getGroupsForUser } from "../../../../../utilities/adminQueries/userGroups";
import { findOrgInGroups } from "../../../../../utilities/organization/OrganizationGroup";
import { UserGroup } from "../../../../../interfaces/UserGroup";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventOrganizationAdminMembersPage: React.FC<OrganizationPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(true);
    const [organization, setOrganization] = useState<Organization>();
    const [userGroup, setUserGroup] = useState<UserGroup | null | undefined>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOrganization() {
            try {
                setIsLoading(true);
                const queryResult = await getOrganizationById(match.params.id);
                if (queryResult.isSuccess) {
                    setOrganization(queryResult.result);
                    getCurrentUser(queryResult.result);
                } else {
                    setError("Sorry, a problem occurred. Please go back and try again.");
                    setIsLoading(false);
                }
            } catch (error: any) {
                setError("Sorry, a problem occurred. Please go back and try again.");
                setIsLoading(false);
            }
        }

        async function getCurrentUser(organization: Organization) {
            const result = await getUser();
            if (result && result.username) {
                getCurrentUserGroups(result.username, organization);
            }
            else setIsLoading(false);
        }

        async function getCurrentUserGroups(username: string, organization: Organization) {
            try {
                // All users should belong to at least 1 group (rsp-users)
                const result = await getGroupsForUser(username);
                const groupsArray = result.Groups;
                if (groupsArray && groupsArray.length > 0) {
                    // This user also belongs to at least 1 org group
                    const searchResult = findOrgInGroups(organization, groupsArray);
                    if (searchResult.isSuccess) {
                        setUserGroup(searchResult.result);
                    } else {
                        setUserGroup(undefined);
                    }
                } else {
                    setUserGroup(undefined);
                }
                setIsLoading(false);
            } catch (error: any) {
                setIsLoading(false);
            }
        }

        getOrganization();
    }, [user, match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Admin Members" />
                {isLoading ?
                    <Spinner />
                    :   
                    <IonRow className="ion-justify-content-center">
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonCol size="12">
                            <IonCard mode="md" className="ion-padding bg-white">
                                <IonCardTitle>Admin Members</IonCardTitle>
                                <IonCardContent>
                                    {organization ?
                                        <OrganizationMemberList organization={organization} userGroup={userGroup} />
                                        :
                                        <p>Loading organization....</p>
                                    }
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                }
                {userGroup && (
                    <>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>Invite Other Admins</IonCardTitle>
                                    <IonCardContent>
                                        {organization ?
                                            <OrganizationAddMembersForm organization={organization} />
                                            :
                                            <p>Loading organization....</p>
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <IonCard mode="md" className="ion-padding bg-white">
                                <IonCardTitle>Pending Invitations</IonCardTitle>
                                <IonCardSubtitle color="danger">Refresh page to see changes.</IonCardSubtitle>
                                <IonCardContent>
                                    {organization ?
                                        <OrganizationInvitationsList organization={organization} />
                                        :
                                        <p>Loading organization....</p>
                                    }
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationAdminMembersPage;