import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React from "react";
import RiderEditProfileHomePage from "../../../../components/ProfilePage/RiderEditProfileHomePage";
import PageList from "../../../../components/Lists/PageList";
import { ownerListOptions } from "../../../../interfaces/Page";

const OwnerProfilePage: React.FC = () => {
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Profile" />
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="8" className="ion-text-center">
                        <RiderEditProfileHomePage />
                    </IonCol>
                </IonRow>
                <IonRow className="ion-text-center ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="8">
                        <PageList includeId={false} listOptions={ownerListOptions} />
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default OwnerProfilePage;