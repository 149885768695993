import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventBeddingTypeInput, CreateEventBeddingTypeMutation, DeleteEventBeddingTypeInput, DeleteEventBeddingTypeMutation, GetEventBeddingTypeQuery, ListEventBeddingTypesQuery, UpdateEventBeddingTypeInput, UpdateEventBeddingTypeMutation } from "../../API";
import moment from "moment";
import { EventBeddingType } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventBeddingType. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventBeddingType.
*/
export async function createEventBeddingType(input: CreateEventBeddingTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventBeddingType", "No input", "Create EventBeddingType received no input.");
   try {
        const fullInput: CreateEventBeddingTypeInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventBeddingType, { input: fullInput }))) as GraphQLResult<CreateEventBeddingTypeMutation>;
       else result = (await API.graphql({
           query: mutations.createEventBeddingType,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventBeddingTypeMutation>;
       const eventBeddingType = result.data?.createEventBeddingType;
       return formatResult(true, "EventBeddingType", eventBeddingType, "Successfully created the eventBeddingType.");
   } catch (error: any) {
       return formatResult(false, "EventBeddingType", error, "Error creating record in the database for type: eventBeddingType");
   }
}

/**
* Handle updating a new record in the database for type: eventBeddingType. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventBeddingType.
*/
export async function updateEventBeddingType(input: UpdateEventBeddingTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventBeddingType", "No input", "Update EventBeddingType received no input.");
   try {
        const fullInput: UpdateEventBeddingTypeInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventBeddingType, { input: fullInput }))) as GraphQLResult<UpdateEventBeddingTypeMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventBeddingType,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventBeddingTypeMutation>;
       const eventBeddingType = result.data?.updateEventBeddingType;
       return formatResult(true, "EventBeddingType", eventBeddingType, "Successfully updated the eventBeddingType.");
   } catch (error: any) {
       return formatResult(false, "EventBeddingType", error, "Error updating record in the database for type: eventBeddingType");
   }
}

/**
* Handle deleting a new record in the database for type: eventBeddingType. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventBeddingType.
*/
export async function deleteEventBeddingType(input: DeleteEventBeddingTypeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventBeddingType", "No input", "Delete EventBeddingType received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventBeddingType, { input: input }))) as GraphQLResult<DeleteEventBeddingTypeMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventBeddingType,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventBeddingTypeMutation>;
       const eventBeddingType = result.data?.deleteEventBeddingType;
       return formatResult(true, "EventBeddingType", eventBeddingType, "Successfully deleted the eventBeddingType.");
   } catch (error: any) {
       return formatResult(false, "EventBeddingType", error, "Error deleting record in the database for type: eventBeddingType");
   }
}

/**
* Get all records in the database for type: eventBeddingType. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventBeddingType object
*/
export async function getAllEventBeddingTypes(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventBeddingTypes,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventBeddingTypesQuery>;
        
        let items = result.data?.listEventBeddingTypes?.items as EventBeddingType[];
        let nextToken = result.data?.listEventBeddingTypes?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventBeddingTypes,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventBeddingTypesQuery>;

            const nextItems = nextResult.data?.listEventBeddingTypes?.items as EventBeddingType[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventBeddingTypes?.nextToken;
        }

        return formatResult(true, "EventBeddingType", items, "Successfully got the EventBeddingTypes.");
    } catch (error: any) {
        return formatResult(false, "EventBeddingType", error, "Error reading record in the database for type: eventBeddingTypes");
    }
}

/**
* Read a specific record in the database for type: eventBeddingType. 
* 
* @param {string}  id                  The eventBeddingType id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventBeddingType object
*/
export async function getEventBeddingTypeById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventBeddingType,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventBeddingTypeQuery>;
       const eventBeddingType = result.data?.getEventBeddingType;
       return formatResult(true, "EventBeddingType", eventBeddingType, "Successfully got the eventBeddingType.");
   } catch (error: any) {
       return formatResult(false, "EventBeddingType", error, "Error reading record in the database for type: eventBeddingType");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventBeddingType. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventBeddingType object
*/
export async function getEventBeddingTypesByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventBeddingTypeByEventId,
            variables: {
                limit: 1000,
                eventId: eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.eventBeddingTypeByEventId?.items as EventBeddingType[];
        let nextToken = result.data?.eventBeddingTypeByEventId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventBeddingTypeByEventId,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventBeddingTypeByEventId?.items as EventBeddingType[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventBeddingTypeByEventId?.nextToken;
        }

        return formatResult(true, "EventBeddingType", items, "Successfully got the EventBeddingTypes.");
    } catch (error: any) {
        return formatResult(false, "EventBeddingType", error, "Error reading record in the database for type: eventBeddingTypes");
    }
}