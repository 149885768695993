import React, { useEffect, useState } from "react";
import { Event, EventEntry } from "../../models";
import { getAcceptedEventEntriesByEventId } from "../../utilities/eventEntry/EventEntry";
import { sortEventEntriesByNumber } from "../../utilities/eventEntry/SortEventEntry";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    event: Event
    menuPlacement?: string
    selectedValue?: string
    onSelect: Function
}

const SelectEventEntry: React.FC<_Props> = ({event, selectedValue, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedEventEntries, setFormattedEventEntries] = useState<formattedOption[] | null | undefined>();

    const getEventEntries = async (event: Event) => {
        const queryResult = await getAcceptedEventEntriesByEventId(event.id);
        if (queryResult.isSuccess) {
            const eventEntries: EventEntry[] = queryResult.result;
            const sorted = sortEventEntriesByNumber(eventEntries);
            formatEventEntries(eventEntries);
        }
    }

    const formatEventEntries = (eventEntries: EventEntry[]) => {
        let formattedEventEntries = [];
        for (var i = 0; i < eventEntries.length; i++) {
            const currentEntry = eventEntries[i];
            const label = (currentEntry.number ? currentEntry.number : "") + " - " + (currentEntry.horse?.name || currentEntry.horseName || "");
            let object = {
                value: currentEntry.id,
                label: label,
                object: currentEntry
            };
            formattedEventEntries.push(object);
        }
        setFormattedEventEntries(formattedEventEntries);
    }

    useEffect(() => {
        if (event) getEventEntries(event);
    }, [event]);

    useEffect(() => {
        setSelected(selectedValue);
    }, [selectedValue]);

    const handleOnChange = (event?: any) => {
        if (event) {
            onSelect(event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedEventEntries ?
                <BasicSelect formattedValues={formattedEventEntries} selectedValue={selected} menuPlacement={menuPlacement} onSelect={handleOnChange}/>
                :
                <p>Loading options...</p>
            }
        </>
    );
};

export default SelectEventEntry;
