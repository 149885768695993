import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateFeeInput, CreateFeeMutation, DeleteFeeInput, DeleteFeeMutation, GetFeeQuery, ListFeesQuery, UpdateFeeInput, UpdateFeeMutation } from "../../API";
import moment from "moment";
import { Fee } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: fee. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created fee.
*/
export async function createFee(input: CreateFeeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Fee", "No input", "Create Fee received no input.");
   try {
        const fullInput: CreateFeeInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createFee, { input: fullInput }))) as GraphQLResult<CreateFeeMutation>;
       else result = (await API.graphql({
           query: mutations.createFee,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateFeeMutation>;
       const fee = result.data?.createFee;
       return formatResult(true, "Fee", fee, "Successfully created the fee.");
   } catch (error: any) {
       return formatResult(false, "Fee", error, "Error creating record in the database for type: fee");
   }
}

/**
* Handle updating a new record in the database for type: fee. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated fee.
*/
export async function updateFee(input: UpdateFeeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Fee", "No input", "Update Fee received no input.");
   try {
        const fullInput: UpdateFeeInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateFee, { input: fullInput }))) as GraphQLResult<UpdateFeeMutation>;
       else result = (await API.graphql({
           query: mutations.updateFee,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateFeeMutation>;
       const fee = result.data?.updateFee;
       return formatResult(true, "Fee", fee, "Successfully updated the fee.");
   } catch (error: any) {
       return formatResult(false, "Fee", error, "Error updating record in the database for type: fee");
   }
}

/**
* Handle deleting a new record in the database for type: fee. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted fee.
*/
export async function deleteFee(input: DeleteFeeInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Fee", "No input", "Delete Fee received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteFee, { input: input }))) as GraphQLResult<DeleteFeeMutation>;
       else result = (await API.graphql({
           query: mutations.deleteFee,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteFeeMutation>;
       const fee = result.data?.deleteFee;
       return formatResult(true, "Fee", fee, "Successfully deleted the fee.");
   } catch (error: any) {
       return formatResult(false, "Fee", error, "Error deleting record in the database for type: fee");
   }
}

/**
* Get all records in the database for type: fee. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the fee object
*/
export async function getAllFees(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listFees,
            variables: {
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListFeesQuery>;

        let items = result.data?.listFees?.items as Fee[];
        let nextToken = result.data?.listFees?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listFees,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListFeesQuery>;

            const nextItems = nextResult.data?.listFees?.items as Fee[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listFees?.nextToken;
        }

        return formatResult(true, "Fee", items, "Successfully got the Fees.");
    } catch (error: any) {
        return formatResult(false, "Fee", error, "Error reading record in the database for type: fees");
    }
}

/**
* Read a specific record in the database for type: fee. 
* 
* @param {string}  id                  The fee id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the fee object
*/
export async function getFeeById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getFee,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetFeeQuery>;
       const fee = result.data?.getFee;
       if (fee) {
            return formatResult(true, "Fee", fee, "Successfully got the fee.");
       } else {
            return formatResult(false, "Fee", null, "Could not find a fee.");
       }
   } catch (error: any) {
       return formatResult(false, "Fee", error, "Error reading record in the database for type: fee");
   }
}

/**
* Get all records in the database that match the given criteria for type: fee. 
* 
* @param {string}  organizationId      The organization id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the fee object
*/
export async function getFeesByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationId: {eq: organizationId}};
        const result = (await API.graphql({
            query: queries.listFees,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListFeesQuery>;

        let items = result.data?.listFees?.items as Fee[];
        let nextToken = result.data?.listFees?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listFees,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListFeesQuery>;

            const nextItems = nextResult.data?.listFees?.items as Fee[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listFees?.nextToken;
        }

        return formatResult(true, "Fee", items, "Successfully got the Fees.");
    } catch (error: any) {
        return formatResult(false, "Fee", error, "Error reading record in the database for type: fees");
    }
}