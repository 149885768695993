import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import React, { useEffect, useState } from "react";

interface _Props {
    value?: number
    className?: string
    onChange: Function
}

const PriceField: React.FC<_Props> = ({value, className, onChange}) => {
    
    const [amount, setAmount] = useState<string>("0");

    useEffect(() => {
        if (isNaN(value ?? 0)) {
            setAmount("0");
        } else {
            setAmount((value ?? 0).toString());
        }
    }, [value]);

    const isNumeric = (str: string) => {
        return !(isNaN(str as any));
    }

    const handleChange = (input: string) => {
        try {
            if (input !== "") {
                if (isNumeric(input)) {
                    setAmount(input);

                    const rawValue = input.replaceAll(",", "");
                    const decimalValue = parseFloat(rawValue).toFixed(2); 
                    onChange(parseFloat(decimalValue));
                } else {
                    setAmount("0");
                    onChange(0)
                }
            } else {
                setAmount("");
                onChange("");
            }
        } catch (error: any) {
            setAmount("0");
        }
    }

    return (
        <>
            <InputGroup className={className || ""}>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <Input
                    type="text"
                    value={amount}
                    onChange={e => {
                        handleChange(e.target.value);
                    }}
                />
            </InputGroup>
        </>
    );
};

export default PriceField;