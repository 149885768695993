import {
    IonButton,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { EventBreak } from "../../models";
import Spinner from "../Spinners/Spinner";
import { deleteEventBreak, getEventBreaksByEventId } from "../../utilities/eventBreak/EventBreak";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import { onCreateEventBreak } from "../../graphql/subscriptions";

interface _Props {
    eventId: string
}

const EventBreakList: React.FC<_Props> = ({eventId}) => {

    const createSubscription = useSubscriptionByItself({
        config: {
            query: onCreateEventBreak,
            key: "onCreateEventBreak"
        }
    });

    const [createSubscriptionItem, setCreateSubscriptionItem] = useState<any>();
    const [breaks, setBreaks] = useState<EventBreak[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const getBreaks = async () => {
        setIsLoading(true);
        const queryResult = await getEventBreaksByEventId(eventId);
        if (queryResult.isSuccess) {
            setBreaks(queryResult.result);
        } 
        setIsLoading(false);
    }

    useEffect(() => {
        if (eventId) getBreaks();
    }, [eventId]);

    useEffect(() => {
        if (createSubscription && createSubscription[0] !== undefined && createSubscription[0] !== createSubscriptionItem) {
            setCreateSubscriptionItem(createSubscription[0]);
            if (eventId) getBreaks();
        }
    }, [createSubscription]);

    const deleteBreak = async(eventBreak: EventBreak) => {
        const deleteResult = await deleteEventBreak({id: eventBreak.id});
        if (deleteResult.isSuccess) {
            const newRingList = breaks?.filter(ring => ring.id !== eventBreak.id);
            setBreaks(newRingList);
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {breaks && breaks.length > 0 ? 
                        <IonList className="bg-white">
                            {breaks.map((eventBreak, index) => (
                                <IonItem key={index}>
                                    <IonLabel>
                                        <IonRow>
                                            <IonCol size="10">
                                                <p>{eventBreak.name} ({eventBreak.time ? eventBreak.time + " minutes" : "No time estimate"})</p>
                                            </IonCol>
                                            <IonCol size="10">
                                                <p>Display on exhibitors' schedule: <span className={eventBreak.isDisplayedOnSchedule ? "text-success": "text-danger"}>{eventBreak.isDisplayedOnSchedule ? "yes": "no"}</span></p>
                                            </IonCol>
                                            <IonCol size="2" className="ion-text-right">
                                                <IonButton color="danger" onClick={() => deleteBreak(eventBreak)}>X</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonLabel>
                                </IonItem>
                            ))}
                        </IonList>
                        :
                        <p>No Rings Found.</p>
                    }
                </>
            }
        </>
    );
};

export default EventBreakList;