import React, { useEffect, useState } from "react";
import { OrganizationMembershipType, Membership, S3Object } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { IonButton, IonCard, IonCol, IonItem, IonLabel, IonRow, IonText } from "@ionic/react";
import { downloadBlob, isImage, isPDF } from "../../utilities/files/File";
import { S3ObjectInput, UpdateMembershipInput } from "../../API";
import {
    createMembershipUploadFileName,
    createS3Object,
    handleDeleteS3File,
    handleDownloadS3File,
    handleUploadS3File
} from "../../utilities/s3Object/s3Object";
import { updateMembership } from "../../utilities/membership/Membership";
import Spinner from "../Spinners/Spinner";
import CONSTANT from "../../constant/constant";

interface _Props {
    setMemberships: Function;
    membershipType: OrganizationMembershipType;
    setSegmentValue: Function;
    memberships: Membership[] | undefined;
}

const MembershipApplicationDocumentsUpload: React.FC<_Props> = ({ setMemberships, membershipType, setSegmentValue, memberships }) => {

    const length = membershipType.organizationDocuments?.length;
    const [files, setFiles] = useState<(File | undefined)[]>(Array(length));
    const [documents, setDocuments] = useState<(S3Object | null)[] | null>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if(memberships?.length) {
            setDocuments(memberships[0]?.documents ?? []);
        }
    }, [memberships]);

    const removeFile = async (event: any) => {
        const index = parseInt(event.target.getAttribute("data-index"));
        files[index] = undefined;
        setFiles([...files]);
        const id = event.target.getAttribute("data-id");
        if(id && documents?.length) {
            const matchIndex = documents?.findIndex((n) => {
                return n?.title === id;
            });
            const s3Object = documents[matchIndex];
            if(matchIndex !== -1 && s3Object && memberships?.length) {
                setIsLoading(true);
                const deleteResult = await handleDeleteS3File(s3Object.key);
                if(deleteResult.isSuccess) {
                    const documentsArray = [
                        ...documents.slice(0, matchIndex),
                        ...documents.slice(matchIndex + 1)
                    ];
                    let updatedMembershipArray: Membership[] = [];
                    for (let i = 0; i < memberships.length; i++) {
                        const membership = memberships[i];
                        // Update the membership record with updated data
                        const membershipInput: UpdateMembershipInput = {
                            id: membership.id,
                            documents: documentsArray
                        };
                        const updateMembershipResult = await updateMembership(membershipInput);
                        if (updateMembershipResult.isSuccess) {
                            updatedMembershipArray.push(updateMembershipResult.result);
                        } else {
                            setError(updateMembershipResult.message);
                        }
                    }
                    // Send the updated memberships back to the parent component
                    setMemberships(updatedMembershipArray);
                    setDocuments(documentsArray);
                } else {
                    setError(deleteResult.message);
                }
                setIsLoading(false);
            }
        }
    }

    const handleFileUpload = (event: any) => {
        setError("");
        event.preventDefault();
        const index = parseInt(event.target.getAttribute("data-index"));
        const file = event.target.files.length > 0 ? event.target.files[0] : undefined;
        if (file && (isPDF(file) || isImage(file))) {
            files[index] = file;
            setFiles([...files]);
        } else {
            setError('Error: File must be a PDF or image.');
        }
    }

    const handleFileSave = async () => {
        setError("");
        // user navigates back to the Upload tab and save button clicked.
        // Check if uploaded files data present in documents object of membership record.
        if(documents?.length === membershipType.organizationDocuments?.length) {
            handleNavigateToNext();
            return;
        }
        try {
            if (memberships?.length) {
                const arrayWithFile = files.filter((file) => {
                    return file !== undefined;
                });
                let totalLength = arrayWithFile.length;
                if (documents?.length) {
                    totalLength += documents?.length;
                }
                if(totalLength !== membershipType.organizationDocuments?.length) {
                    setError("Please ensure that all documents have been uploaded.")
                    return;
                }
                setIsLoading(true);
                let updatedMembershipArray: Membership[] = [];
                for (let i = 0; i < memberships.length; i++) {
                    const membership = memberships[i];

                    // Upload the documents to S3
                    let index = 0;
                    const documentsArray: S3ObjectInput[] = [];
                    for (const file of files) {
                        const document = membershipType.organizationDocuments && membershipType.organizationDocuments[index];
                        if(file) {
                            const uploadResult = await handleUploadS3File(
                                createMembershipUploadFileName(membership as Membership, file?.name || ""),
                                file
                            );
                            if (uploadResult.isSuccess) {
                                const key = uploadResult.result;
                                // saved document id from membership type in title field of S3Object
                                // saved uploaded file in description field of S3Object
                                const s3Object = createS3Object(key, document?.id, file, file?.name);
                                documentsArray.push(s3Object);
                            } else {
                                setError(uploadResult.message);
                            }
                        } else {
                            const found = documents?.find((d) => {
                                return d?.title === document?.id;
                            });
                            if(found) {
                                documentsArray.push(found);
                            }
                        }
                        index++;
                    }

                    // Update the membership record
                    const membershipInput: UpdateMembershipInput = {
                        id: membership?.id ? membership?.id : "",
                        documents: documentsArray
                    };
                    const updateMembershipResult = await updateMembership(membershipInput);
                    if (updateMembershipResult.isSuccess) {
                        updatedMembershipArray.push(updateMembershipResult.result);
                    } else {
                        setError(updateMembershipResult.message);
                    }
                }
                // Send the updated memberships back to the parent component
                setMemberships(updatedMembershipArray);
                handleNavigateToNext();
            } else {
                setError("No membership was found.");
            }
        } catch (error: any) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const handleNavigateToNext = () => {
        setSegmentValue(CONSTANT.MEMBERSHIP.APPLICATION_STAGES.WAIVERS);
    }

    const downloadFile = async (item: S3Object | null | undefined) => {
        if(item) {
            setIsLoading(true);
            const downloadResult = await handleDownloadS3File(item.key);
            if(downloadResult.isSuccess) {
                downloadBlob(downloadResult.result.Body, item.description || "");
            } else {
                setError(downloadResult.message);
            }
            setIsLoading(false);
        }
    }

    return (
        <IonCard color="white" className="ion-padding">
            {error && <ErrorAlert width="12" error={error} />}
            <h3>{membershipType.name}</h3>
            {isLoading ?
                <Spinner />
            :
                <form>
                    {membershipType.organizationDocuments?.length === 0 ? (
                        <IonRow className="mb-2 mt-4 ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="12">
                                <p>There are no documents to upload. Continue to the next step.</p>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                <IonButton
                                    id="mt-upload-next-btn"
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleNavigateToNext}
                                >
                                    Next
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    ) : (
                        membershipType.organizationDocuments?.map((document, index) => {
                            let found: S3Object | null | undefined;
                            if(documents && documents.length > 0) {
                                found = documents.find((doc) => {
                                    return doc?.title === document?.id;
                                });
                            }
                            return (
                                <div key={index}>
                                    <IonRow className="mb-3">
                                        <IonCol sizeXs="12" sizeMd="12">
                                            <h4>Upload Document {index + 1}:</h4>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <IonItem color="white" lines="none">
                                                <IonLabel>{document?.name}</IonLabel>
                                            </IonItem>
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="6">
                                            {files[index] || found ? 
                                                <IonRow className="mt-2">
                                                    <IonCol sizeXs="9" sizeMd="9">
                                                        {files[index]?.name ? files[index]?.name :
                                                            <p
                                                                className="link text-info"
                                                                onClick={() => downloadFile(found)}
                                                            >
                                                                {found?.description}
                                                            </p>
                                                        }
                                                    </IonCol>
                                                    <IonCol sizeXs="3" sizeMd="3">
                                                        <IonText 
                                                            data-index={index}
                                                            data-id={found?.title}
                                                            id={`removeText-${index}`}
                                                            onClick={(e) => removeFile(e)}
                                                            className="text-danger link"
                                                            class="ion-float-right"
                                                        >
                                                            remove
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                            :
                                                <div>
                                                    <input
                                                        data-index={index}
                                                        id={`customFileUpload-${index}`}
                                                        type="file" 
                                                        onChange={(e) => handleFileUpload(e)} 
                                                    />
                                                    <label
                                                        className="custom-file-label"
                                                        htmlFor={`customFileUpload-${index}`}
                                                    >
                                                        Select File
                                                    </label>
                                                </div>
                                            }
                                        </IonCol>
                                    </IonRow>
                                <hr/>
                                </div>
                            );
                        })
                    )}
                    {membershipType.organizationDocuments?.length !== 0 && (
                        <IonRow className="ion-justify-content-center mb-2 mt-4">
                            <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                <IonButton
                                    id="mt-upload-save-btn"
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleFileSave}
                                >
                                    Save
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    )}
                </form>
            }
        </IonCard>
    );
};

export default MembershipApplicationDocumentsUpload;
