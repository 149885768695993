import React, { useEffect, useState } from "react";
import { EventDivision } from "../../models";
import { getDivisionById } from "../../utilities/division/Division";
import { getEventDivisionsByEventId } from "../../utilities/eventDivision/EventDivision";
import { sortEventDivisionsByName } from "../../utilities/eventDivision/SortEventDivisons";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    eventId: string
    menuPlacement?: string
    selectedValue?: string
    onSelect: Function
}

const SelectEventDivision: React.FC<_Props> = ({eventId, selectedValue, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedEventDivisions, setFormattedEventDivisions] = useState<formattedOption[] | null | undefined>();

    const formatEventDivisions = async (eventDivisions: EventDivision[]) => {
        let formattedEventDivisions = [];
        for (var i = 0; i < eventDivisions.length; i++) {
            const currentEventDivision = eventDivisions[i];
            const label = currentEventDivision.name;
            let object = {
                value: currentEventDivision.id,
                label: label,
                object: currentEventDivision
            };
            formattedEventDivisions.push(object);
        }
        setFormattedEventDivisions(formattedEventDivisions);
    }

    useEffect(() => {
        async function getEventDivisions(eventId: string) {
            const queryResult = await getEventDivisionsByEventId(eventId);
            if (queryResult.isSuccess) {
                const list = queryResult.result;
                const sorted = sortEventDivisionsByName(list);
                formatEventDivisions(sorted || list);
            }
        }

        if (eventId) getEventDivisions(eventId);
    }, [eventId]);

    useEffect(() => {
        setSelected(selectedValue);
    }, [selectedValue]);

    const handleOnChange = (event?: any) => {
        if (event) {
            onSelect(event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedEventDivisions ?
                <BasicSelect formattedValues={formattedEventDivisions} selectedValue={selected} menuPlacement={menuPlacement} onSelect={handleOnChange}/>
                :
                <p>Loading options...</p>
            }
        </>
    );
};

export default SelectEventDivision;
