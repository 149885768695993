import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Clinician, Owner, Person, Rider, S3Object, Submission, SubmissionFeedback, Trainer} from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { getPersonByPersonId, updatePerson } from "../../../../../utilities/person/Person";
import { UpdatePersonInput } from "../../../../../API";
import SelectAllButtons from "../../../../../components/Select/SelectAllButtons";
import { formatRolesStringForUserAttribute, formatRolesStringToStringArray, formatRolesToStringArray } from "../../../../../utilities/roles/FormatRoles";
import VideoForm from "../../../../../components/s3Object/VideoForm";
import SubmissionForm from "../../../../../components/Submission/SubmissionForm";
import { getSubmissionFeedbacksByPersonId } from "../../../../../utilities/submissionFeedback/SubmissionFeedback";
import { getSubmissionById } from "../../../../../utilities/submission/Submission";
import { getClinicianById } from "../../../../../utilities/clinician/Clinician";
import ViewPersonNavbar from "../../../../../components/Navbars/ViewPersonNavbar";
import moment from "moment";
import AdminEditPersonBarnMemberInfo from "../../../../../components/BarnMember/AdminEditPersonBarnMemberInfo";
import AdminDeduplicatePerson from "../../../../../components/BarnMember/AdminDedupilcatePerson";
import { getRidersByPersonId } from "../../../../../utilities/rider/Rider";
import { Table } from "reactstrap";
import { getOwnersByPersonId } from "../../../../../utilities/owner/Owner";
import { getTrainerByPersonId } from "../../../../../utilities/trainer/Trainer";
import AdminEditPersonEntryInfo from "../../../../../components/Entry/AdminEditPersonEntryInfo";
import Spinner from "../../../../../components/Spinners/Spinner";
import AdminEditRidersTable from "../../../../../components/Rider/AdminEditRidersTable";
import AdminEditOwnersTable from "../../../../../components/Owner/AdminEditOwnersTable";
import AdminEditTrainersTable from "../../../../../components/Trainer/AdminEditTrainersTable";

interface PersonPageProps extends RouteComponentProps<{
    id: string;
}> {}

interface formattedSubmissionFeedback {
    title: string
    clinicianName: string
}

const initialRoles: string[] = [];

const PersonPage: React.FC<PersonPageProps> = ({match}) => {
    
    const [isLoadingPerson, setIsLoadingPerson] = useState(false);
    const [pageTitle, setPageTitle] = useState("Person");
    const [currentActiveBtn, setCurrentActiveBtn] = useState("about");
    const [currentPerson, setCurrentPerson] = useState<Person>();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [rolesToSelectFrom, setRolesToSelectFrom] = useState(initialRoles);
    const [selectedRoles, setSelectedRoles] = useState(initialRoles);
    const [stripeId, setStripeId] = useState("");
    const [mediaList, setMediaList] = useState<(S3Object | null)[] | null | undefined>();
    const [submissionFeedbackList, setSubmissionFeedbackList] = useState<formattedSubmissionFeedback[] | null | undefined>();

    async function formatSubmissionFeedback(submissionFeedback: SubmissionFeedback[]) {
        let formatted: formattedSubmissionFeedback[] = [];
        for (let i = 0; i < submissionFeedback.length; i++) {
            const current = submissionFeedback[i];
            if (current.submission?.id) {
                const querySubmissionResult = await getSubmissionById(current.submission?.id);
                const queryClinicianResult = await getClinicianById(current.clinicianId || "");
                if (querySubmissionResult.isSuccess && queryClinicianResult.isSuccess) {
                    const submission: Submission = querySubmissionResult.result;
                    const clinician: Clinician = queryClinicianResult.result;
                    let formattedObject: formattedSubmissionFeedback = {
                        title: submission.title,
                        clinicianName: clinician.name
                    };
                    formatted.push(formattedObject);
                }
            }
        }
        setSubmissionFeedbackList(formatted);
    }

    async function getSubmissionFeedback(personId: string) {
        const queryResult = await getSubmissionFeedbacksByPersonId(personId);
        if (queryResult.isSuccess) {
            formatSubmissionFeedback(queryResult.result);
        }
    }

    useEffect(() => {
        async function getPerson() {
            setIsLoadingPerson(true);
            const queryResult = await getPersonByPersonId(match.params.id);
            if (queryResult.isSuccess) {
                let title = "";
                const person: Person = queryResult.result;
                const firstName = person.firstName;
                const lastName = person.lastName;
                const roles = person.roles;
                const stripeId = person.stripeId;
                const media = person.media;

                if (firstName) {
                    setFirstName(firstName);
                    title = firstName;
                } 
                if (lastName) {
                    setLastName(lastName);
                    title = title + " " + lastName;
                }
                if (roles) {
                    setSelectedRoles(formatRolesStringToStringArray(roles));
                }
                if (stripeId) {
                    setStripeId(stripeId);
                }
                if (media) {
                    setMediaList(media);
                }

                setCurrentPerson(person);
                setPageTitle(title);

                setIsLoadingPerson(false);
            }
            
        }
        getPerson();
        setRolesToSelectFrom(formatRolesToStringArray());
        getSubmissionFeedback(match.params.id);
    }, [match, match.params.id]);

    const handleUpdateName = async () => {
        if (currentPerson) {
            const input: UpdatePersonInput = {
                id: currentPerson.id,
                firstName: firstName,
                lastName: lastName
            };
            const updateResult = await updatePerson(input);
            if (!updateResult.isSuccess) {
                console.error(updateResult);
            }
        }
    }

    const handleSelectRoles = (newSelectedRoles: string[]) => {
        setSelectedRoles(newSelectedRoles);
    };

    const handleUpdatRoles = async () => {
        if (currentPerson) {
            const input: UpdatePersonInput = {
                id: currentPerson.id,
                roles: formatRolesStringForUserAttribute(selectedRoles)
            };
            const updateResult = await updatePerson(input);
            if (!updateResult.isSuccess) {
                console.error(updateResult);
            }
        }
    } 

    const handleUpdateStripeId = async () => {
        if (currentPerson) {
            const input: UpdatePersonInput = {
                id: currentPerson.id,
                stripeId: stripeId
            };
            const updateResult = await updatePerson(input);
            if (!updateResult.isSuccess) {
                console.error(updateResult);
            }
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={pageTitle} />
                <p>Person Id: {currentPerson?.id}</p>
                <ViewPersonNavbar active={currentActiveBtn} onSelect={(selectedValue: string) => setCurrentActiveBtn(selectedValue)} />
                {currentActiveBtn === "about" && (
                    <>
                        {isLoadingPerson ?
                            <Spinner />
                            :
                            <>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white">
                                            <IonCardTitle>Name</IonCardTitle>
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">First Name</IonLabel>
                                                            <IonInput 
                                                                type="text"
                                                                value={firstName}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setFirstName(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                    <IonCol>
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Last Name</IonLabel>
                                                            <IonInput 
                                                                type="text"
                                                                value={lastName}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setLastName(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol sizeMd="4">
                                                        <IonButton
                                                                className="ion-margin-top"
                                                                color="tertiary"
                                                                expand="block"
                                                                onClick={handleUpdateName}
                                                            >
                                                                Save
                                                            </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white">
                                            <IonCardTitle>About</IonCardTitle>
                                            <IonCardContent>
                                                <p>Email Address: {currentPerson ? currentPerson.email : ""}</p>
                                                <p>Created On: {currentPerson ? moment(currentPerson.createdOn).format("MMM Do YYYY") : ""}</p>
                                                <p>Updated On: {currentPerson ? moment(currentPerson.updatedOn).format("MMM Do YYYY") : ""}</p>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white">
                                            <IonCardTitle>Roles</IonCardTitle>
                                            <IonCardContent>
                                                <IonRow>
                                                    <SelectAllButtons buttons={rolesToSelectFrom} selectedButtons={selectedRoles} onSelect={handleSelectRoles}/>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol sizeMd="4">
                                                        <IonButton
                                                                className="ion-margin-top"
                                                                color="tertiary"
                                                                expand="block"
                                                                onClick={handleUpdatRoles}
                                                            >
                                                                Save
                                                            </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white">
                                            <IonCardTitle>Stripe Id</IonCardTitle>
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Stripe Id</IonLabel>
                                                            <IonInput 
                                                                type="text"
                                                                value={stripeId}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setStripeId(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol sizeMd="4">
                                                        <IonButton
                                                                className="ion-margin-top"
                                                                color="tertiary"
                                                                expand="block"
                                                                onClick={handleUpdateStripeId}
                                                            >
                                                                Save
                                                            </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                {currentPerson && (
                                    <>
                                        <AdminEditRidersTable person={currentPerson} key={currentPerson.id} />
                                        <AdminEditOwnersTable person={currentPerson} key={currentPerson.id} />
                                        <AdminEditTrainersTable person={currentPerson} key={currentPerson.id} />
                                    </>
                                )}
                            </>
                        }
                    </>
                )}
                {currentActiveBtn === "clinics" && (
                    <>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>Media</IonCardTitle>
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>
                                                <VideoForm person={currentPerson} onSubmit={() => setMediaList}/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                {mediaList ?
                                                    <IonList className="bg-white">
                                                        {mediaList.map((object: (S3Object | null), index: number) => (
                                                            <IonItem key={index}>
                                                                <IonLabel>
                                                                    <IonRow>
                                                                        <p>Title: {object?.title}</p>
                                                                    </IonRow>
                                                                    <IonRow>
                                                                        <p>Key: {object?.key}</p>
                                                                    </IonRow>
                                                                </IonLabel>
                                                            </IonItem>
                                                        ))}
                                                    </IonList>
                                                    :
                                                    <p>No media.</p>
                                                }
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>Previous Submission Feedback</IonCardTitle>
                                    <IonCardContent>
                                        {submissionFeedbackList ?
                                            <IonList className="bg-white">
                                                {submissionFeedbackList.map((submissionFeedback, index) => (
                                                    <IonItem key={index}>
                                                        <IonLabel>
                                                            {submissionFeedback.title} - {submissionFeedback.clinicianName}
                                                        </IonLabel>
                                                    </IonItem>
                                                ))}
                                            </IonList>
                                            :
                                            <p>No Submission Feedback</p>
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>Submission Feedback</IonCardTitle>
                                    <IonCardContent>
                                        {mediaList && <SubmissionForm person={currentPerson} mediaList={mediaList} onSubmit={() => console.log("Submit")}/>}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {currentActiveBtn === "barns" && (
                    <>
                        {currentPerson && <AdminEditPersonBarnMemberInfo person={currentPerson} />}
                    </>
                )}
                {currentActiveBtn === "entries" && (
                    <>
                        {currentPerson && <AdminEditPersonEntryInfo person={currentPerson} />}
                    </>
                )}
                {currentActiveBtn === "duplicate" && (
                    <>
                        {currentPerson && <AdminDeduplicatePerson person={currentPerson} />}
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default PersonPage;