import { Auth, API } from "aws-amplify";
import { CognitoUser } from "../../interfaces/CognitoUser";

export async function removeFromGroup(user: CognitoUser, group: string) { 
    let apiName = "AdminQueries";
    let path = "/removeUserFromGroup";
    let myInit = {
        body: {
            "username" : user.username,
            "groupname": group
        }, 
        headers: {
            "Content-Type" : "application/json",
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        } 
    }
    return await API.post(apiName, path, myInit);
}

export async function disableUser(user: CognitoUser){
    let apiName = "AdminQueries";
    let path = "/disableUser";
    let myInit = { 
        body: {
            "username": user.username
        },
        headers: {
            "Content-Type" : "application/json",
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.post(apiName, path, myInit);
}