import React, { useEffect, useState } from "react";
import { IonItem, IonLabel, IonRow, IonCol, IonInput, IonText, IonButton } from "@ionic/react";
import BasicTooltip from "../../Tooltip/BasicTooltip";

interface _Props {
    selectedMin?: (number | null)
    selectedMax?: (number | null)
    isExpandedByDefault?: boolean
    onSelect: Function
}

const AllowedNumberOfEntries: React.FC<_Props> = ({selectedMin, selectedMax, isExpandedByDefault, onSelect}) => {
    const [isHidden, setIsHidden] = useState(isExpandedByDefault ? false : true);
    const [minNumberEntries, setMinNumberEntries] = useState(3);
    const [maxNumberEntries, setMaxNumberEntries] = useState<number | null | undefined>();

    useEffect(() => {
        if (selectedMin) setMinNumberEntries(selectedMin);
        else setMinNumberEntries(3);
    }, [selectedMin]);

    useEffect(() => {
        if (selectedMax) setMaxNumberEntries(selectedMax);
        else setMaxNumberEntries(undefined);
    }, [selectedMax]);

    const handleMin = (input: number) => {
        setMinNumberEntries(input);
        onSelect("min", input);
    }

    const handleMax = (input: number) => {
        setMaxNumberEntries(input);
        onSelect("max", input);
    }
    
    return (
        <>
            {isHidden ?
                <IonText>
                    Must have {minNumberEntries} or more entries to fill. <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(false)}>Edit</IonButton>
                </IonText>
                :
                <>
                    <IonRow>
                        <IonCol className="text-center">
                            <BasicTooltip 
                                label="Allowed Number of Entries"
                                tip="Set a minimum number of entries needed to run this class. Set a cap on how many entries you will take or 0 for no cap. (0 = infinite entries)"
                            />
                            <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(true)}>Hide</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Min</IonLabel>
                                <IonInput 
                                    type="number"
                                    min="1"
                                    value={minNumberEntries}
                                    placeholder="1"
                                    onIonChange={e => {
                                        handleMin(parseInt(e.detail.value!))
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Max</IonLabel>
                                <IonInput 
                                    type="number"
                                    min="0"
                                    value={maxNumberEntries}
                                    placeholder="No max"
                                    inputmode="decimal"
                                    onIonChange={e => {
                                        handleMax(parseInt(e.detail.value! || "0"))
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default AllowedNumberOfEntries;
