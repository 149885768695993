import {
  IonCard,
  IonCol,
  IonContent,
  IonPage,
  IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { Organization } from "../../../../../models";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import OrganizationName from "../../../../../components/Organization/OrganizationName";
import { organizationBasicSettingsListOptions } from "../../../../../interfaces/Page";
import PageList from "../../../../../components/Lists/PageList";

interface OrganizationPageProps extends RouteComponentProps<{
  id: string;
}> {}

const EventOrganizationBasicSettingsPage: React.FC<OrganizationPageProps> = ({match}) => {
  const [organization, setOrganization] = useState<Organization>();
  const [error, setError] = useState("");

  useEffect(() => {
    async function getOrganization() {
      const queryResult = await getOrganizationById(match.params.id);
      if (queryResult.isSuccess) {
        setOrganization(queryResult.result);
      } else {
        setError("Sorry, a problem occurred. Please go back and try again.");
      }
    }
    getOrganization();
  }, [match, match.params.id]);

  return (
    <IonPage className="bg-light">
      <Header />
      <IonContent>
        <PageTitle title={organization ? organization.name : "Basic Settings"} />
        {error && <ErrorAlert width="12" error={error} />}
        {organization && (
          <>
            <IonRow className="ion-text-center ion-justify-content-center">
              <IonCol sizeSm="12" sizeMd="8">
                <IonCard className="bg-white ion-padding">
                  <OrganizationName name={organization.name} />
                  <OrganizationName name="Basic Settings" />
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center ion-justify-content-center">
              <IonCol sizeSm="12" sizeMd="8">
                <PageList id={organization.id} includeId={true} listOptions={organizationBasicSettingsListOptions} />
              </IonCol>
            </IonRow>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default EventOrganizationBasicSettingsPage;
