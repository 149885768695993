import { API } from "aws-amplify";
import { Result } from "../../interfaces/Result";

export async function addNoteToHubspot(email: string, note: string) {
    try {
        let queryParams = "";
        queryParams = queryParams + "?email=" + email;
        queryParams = queryParams + "&note=" + note;
        const path = "/hubspot/add/note" + queryParams;
        const response = await API.get("Hubspot", path, {});
        const responseBody = JSON.parse(response.body);
        
        let result: Result = {isSuccess: true, type: "Hubspot", result: responseBody, message: "Successfully added the note."}
        return result;
    } catch (error) {
        let result: Result = {isSuccess: false, type: "Hubspot", result: error, message: "Error occurred while trying to add the note." }
        return result;
    }
}