import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import EventAboutNavbar from "../../../../../components/Navbars/EventAboutNavbar";
import {Event} from "../../../../../models";
import EventContactForm from "../../../../../components/Event/About/EventContactForm";
import EventDatesForm from "../../../../../components/Event/About/EventDatesForm";
import EventDisciplinesForm from "../../../../../components/Event/About/EventDisciplinesForm";
import EventJudgesForm from "../../../../../components/Event/About/EventJudgesForm";
import EventLocationForm from "../../../../../components/Event/About/EventLocationForm";
import EventNameForm from "../../../../../components/Event/About/EventNameForm";
import EventOfficialsForm from "../../../../../components/Event/About/EventOfficialsForm";
import EventRatingForm from "../../../../../components/Event/About/EventRatingForm";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import EventDocumentsForm from "../../../../../components/Event/About/EventDocumentsForm";
import EventECSettingsForm from "../../../../../components/EventSettings/EventECSettingsForm";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

let subscription: any;

const EventAboutPage: React.FC<EventPageProps> = ({match}) => {

    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [currentTab, setCurrentTab] = useState("about");
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setCurrentEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        getEvent();
    }, [match, match.params.id]);
    
    useEffect(() => {
        return () => {
            if (subscription) subscription.unsubscribe();
        }
    }, [currentEvent]);


    const handleTabSelection = (tab: string) => {
        setCurrentTab(tab);
    }

    return (
        <IonPage className="bg-light">
            <Header isEventPage={currentEvent ? true : false} event={currentEvent ? currentEvent : undefined} />
            <IonContent>
                <PageTitle title={currentEvent ? (currentEvent.nickname ? currentEvent.nickname : currentEvent.name) : "Event"} />
                {currentEvent && (
                    <>
                        <EventAboutNavbar active={currentTab} onSelect={handleTabSelection} />
                        {error && <ErrorAlert width="12" error={error} />}
                        {(currentTab === "about") && (
                            <>
                                <IonRow id="eventName" className="ion-align-items-stretch">
                                    <IonCol sizeSm="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white stretch">
                                            <IonCardTitle>Event Name</IonCardTitle>
                                            <IonCardContent>
                                                <EventNameForm event={currentEvent} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol sizeSm="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white stretch">
                                            <IonCardTitle>Dates</IonCardTitle>
                                            <IonCardContent>
                                                <EventDatesForm event={currentEvent} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow id="eventLocation" className="ion-align-items-stretch">
                                    <IonCol sizeSm="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white stretch">
                                            <IonCardTitle>Location</IonCardTitle>
                                            <IonCardContent>
                                                <EventLocationForm event={currentEvent} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol sizeSm="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white stretch">
                                            <IonCardTitle>Contact Info</IonCardTitle>
                                            <IonCardContent>
                                                <EventContactForm event={currentEvent} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                {currentEvent.type === "horse_show" && (
                                    <IonRow id="eventOfficials" className="ion-align-items-stretch">
                                        <IonCol sizeSm="12" sizeMd="6">
                                            <IonCard mode="md" className="ion-padding bg-white stretch">
                                                <IonCardTitle>Officials</IonCardTitle>
                                                <IonCardContent>
                                                    <EventOfficialsForm event={currentEvent} />
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                        <IonCol sizeSm="12" sizeMd="6">
                                            <IonCard mode="md" className="ion-padding bg-white stretch">
                                                <IonCardTitle>Judges</IonCardTitle>
                                                <IonCardContent>
                                                    <EventJudgesForm event={currentEvent} />
                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>
                                )} 
                                <IonRow id="eventOfficials" className="ion-align-items-stretch">
                                    <IonCol sizeSm="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white stretch">
                                            <IonCardTitle>Disciplines</IonCardTitle>
                                            <IonCardContent>
                                                <EventDisciplinesForm event={currentEvent} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                        {(currentTab === "affiliations") && (
                            <>
                                <IonRow id="eventRating" className="ion-align-items-stretch">
                                    <IonCol sizeSm="12" sizeMd="12">
                                        <IonCard mode="md" className="ion-padding bg-white stretch">
                                            <IonCardTitle>Rating</IonCardTitle>
                                            <IonCardContent>
                                                <EventRatingForm event={currentEvent} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonCard mode="md" className="ion-padding bg-white">
                                            <IonCardTitle>EC Settings</IonCardTitle>
                                            <IonCardContent>
                                                {(currentEvent && currentEvent.organizationId) && (
                                                    <EventECSettingsForm event={currentEvent} />
                                                )}
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                        {(currentTab === "documents") && (
                            <>
                                <IonRow id="eventOfficials" className="ion-align-items-stretch">
                                    <IonCol sizeSm="12" sizeMd="12">
                                        <IonCard mode="md" className="ion-padding bg-white stretch">
                                            <IonCardTitle>Documents</IonCardTitle>
                                            <IonCardContent>
                                                <EventDocumentsForm event={currentEvent} />
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventAboutPage;