import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { USEFIndividual, USEFPhone } from "../../interfaces/USEFData";
import { Barn, Horse, Membership, Owner } from "../../models";
import { createOwner, deleteOwner, updateOwner} from "../../utilities/owner/Owner";
import { createMembership, getMembershipsByPersonId, updateMembership } from "../../utilities/membership/Membership";
import { capitalizeName } from "../../utilities/person/PersonNameFormat";
import ErrorAlert from "../Errors/ErrorAlert";
import { ECIndividual } from "../../interfaces/ECData";
import SelectState from "../Address/SelectState";
import PhoneNumberTypeSelect from "../Contact/PhoneNumberTypeSelect";
import { createContact, updateContact } from "../../utilities/contact/Contact";
import USEFRiderMembershipForm from "../Memberships/USEFRiderMembershipForm";
import ECRiderMembershipForm from "../Memberships/ECRiderMembershipForm";
import { updateHorse } from "../../utilities/horse/Horse";
import { CreateContactInput, CreateMembershipInput, CreateOwnerInput, UpdateContactInput, UpdateHorseInput, UpdateMembershipInput, UpdateOwnerInput } from "../../API";
import { formatTwilioNumber } from "../../utilities/contact/FormatPhoneNumber";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    owner?: Owner
    horse?: Horse
    selectedBarn?: Barn
    onChange: Function
}

const OwnerForm: React.FC<_Props> = ({owner, horse, selectedBarn, onChange}) => {
    const user = useContext(PersonContext);
    
    const [barn, setBarn] = useState<Barn | null | undefined>();
    const [showUSEFForm, setShowUSEFForm] = useState(false);
    const [showECForm, setShowECForm] = useState(false);
    const [usefNumber, setUSEFNumber] = useState("");
    const [ecNumber, setECNumber] = useState("");
    const [usefData, setUSEFData] = useState<USEFIndividual | null | undefined>();
    const [ecData, setECData] = useState<ECIndividual | null | undefined>();
    const [name, setName] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneType, setPhoneType] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [error, setError] = useState("");

    const clearForm = () => {
        setName("");
        setCity("");
        setState("");
        setPhoneNumber("");
        setPhoneType("");
        setEmailAddress("");
        setShowUSEFForm(false);
        setShowECForm(false);
    }

    useEffect(() => {
        clearForm();
    }, []);

    useEffect(() => {
        setBarn(selectedBarn);
    }, [selectedBarn]);

    useEffect(() => {
        if (owner) {
            // Set owner details
            setName(owner.name);
            if (owner.location){
                const locationArray = owner.location.split(",");
                setCity(locationArray[0].trim());
                setState(locationArray[1].trim());
            }
            if (owner.contact) {
                if (owner.contact.cell) {
                    setPhoneType("cell");
                    setPhoneNumber(owner.contact.cell);
                }
                if (owner.contact.home) {
                    setPhoneType("home");
                    setPhoneNumber(owner.contact.home);
                }
                if (owner.contact.work) {
                    setPhoneType("work");
                    setPhoneNumber(owner.contact.work);
                }
                if (owner.contact?.personalEmail) setEmailAddress(owner.contact?.personalEmail);
            }
        } else {
            clearForm();
        }
    }, [owner]);

    const verifyForm = () => {
        if (!name) {
            setError("Please include a show name for the owner");
            return false;
        }
        if (!city) {
            setError("Please include a city for the owner");
            return false;
        }
        if (!state) {
            setError("Please include a state for the owner");
            return false;
        }
        return true;
    }

    const handleProvStateInputChange = (value: string) => {
        if (value && value !== "-") setState(value);
    }

    const handlePhoneNumberTypeSelectInput = (phoneNumberTypeInput: string) => {
        setPhoneType(phoneNumberTypeInput);
    }

    const onUSEFNumberSubmit = (number?: string, data?: USEFIndividual) => {
        if (number) {
            setUSEFNumber(number);
        }
        if (data) {
            setUSEFData(data);
            setUSEFNumber(data.Id.toString());
            setName(capitalizeName(data.Name));
            setCity(capitalizeName(data.City));
            setState(data.State.trim());
            setEmailAddress(data.EmailAddress);
            const phonesList = data.Phones;
            if (phonesList && phonesList.length) {
                phonesList.forEach((phone: USEFPhone) => {
                    if (phone.Type === "Cell Phone") {
                        setPhoneType("cell");
                        setPhoneNumber(phone.Number);
                    }
                    else {
                        setPhoneType("home");
                        setPhoneNumber(phone.Number);
                    }
                });
            }
        } else {
            setError("Could not find data for this owner.");
        }
    }

    const onECNumberSubmit = (data?: ECIndividual) => {
        if (data) {
            setECData(data);
            setECNumber(data.EC_Sport_License_Number.toString() || "");
            const name = data.Firstname + " " + data.Lastname;
            setName(capitalizeName(name));
            setCity(capitalizeName(data.City));
            setState(data.Province.trim());
        } else {
            setError("Could not find data for this owner.");
        }
    }

    const createOwnerContact = async () => {
        let input: CreateContactInput = {
            name: name
        };
        if (phoneNumber) {
            const formattedNumber = formatTwilioNumber(phoneNumber);
            if (formattedNumber) {
                if (phoneType === "cell") input["cell"] = formattedNumber;
                if (phoneType === "home") input["home"] = formattedNumber;
                if (phoneType === "work") input["work"] = formattedNumber;
            }
        }
        if (emailAddress) input["personalEmail"] = emailAddress.toLowerCase();

        const createContactResult = await createContact(input);
        if (createContactResult.isSuccess) {
            return createContactResult.result;
        } else {
            setError("Could not create the contact info.");
        }
    }

    const handleUpdateHorseOwner = async (horse: Horse, owner: Owner) => {
        const input: UpdateHorseInput = {
            id: horse.id,
            ownerId: owner.id,
            // horseOwnerId: owner.id,
            ownerName: owner.name
        };
        await updateHorse(input);
    }

    const handleCreateOwner = async () => {
        const contact = await createOwnerContact();
        const location = city + ", " + state;
        const ownerInput: CreateOwnerInput = {
            name: name,
            createdBy: user.id,
            location: location,
            ownerContactId: contact?.id || "",
            barnId: barn?.id
        };
        const createResult = await createOwner(ownerInput);
        if (createResult.isSuccess) {
            if (horse) {
                handleUpdateHorseOwner(horse, createResult.result);
            }
            if (usefNumber) {
                await handleCreateUSEFMembership(createResult.result);
            } 
            if (ecNumber) {
                await handleCreateECMembership(createResult.result);
            }
            onChange(createResult.result);
            clearForm();
        } else {
            setError(createResult.message);
        }
    }

    const handleCreateUSEFMembership = async (owner: Owner) => {
        const membershipsList = usefData?.Memberships;
        if (membershipsList && membershipsList.length) {
            for (var i = 0; i < membershipsList.length; i++) {
                const membership = membershipsList[i];
                const input: CreateMembershipInput = {
                    personId: user.id,
                    name: membership.OrgName,
                    membershipId: membership.MemberNumber,
                    membershipStatus: capitalizeName(membership.Status),
                    type: membership.MembershipType,
                    dateMembershipEnds: membership.MemberEndDate,
                    ownerId: owner.id,
                    // membershipOwnerId: owner.id,
                    personName: owner.name
                };
                await createMembership(input);
            }
        }
    }

    const handleCreateECMembership = async (owner: Owner) => {
        if (ecData) {
            const name = ecData.Firstname + " " + ecData.Lastname;
            const ecDataYear = ecData?.Last_Year_Active.toString();
            const formattedDataYear = moment(ecDataYear).format("YYYY");
            const formattedCurrentYear = moment(new Date).format("YYYY");
            const status = formattedDataYear === formattedCurrentYear ? "Active" : "Inactive";
            const dateMembershipEnds = (status === "Active" && ecData.Sport_License_Type !== "Temporary") ? (moment(new Date).format("YYYY") + "-12-31") : "";
            const input: CreateMembershipInput = {
                personId: user.id,
                name: "EC",
                membershipId: ecData.EC_Sport_License_Number.toString(),
                membershipStatus: status,
                type: ecData.Sport_License_Type,
                dateMembershipEnds,
                personName: name,
                ownerId: owner.id,
                // membershipOwnerId: owner.id,
                isProfessional: !ecData.Amateur
            };
            await createMembership(input);
        }
    }

    const updateOwnerContact = async () => {
        if (owner?.contact) {
            const input: UpdateContactInput = {
                id: owner.contact.id,
                name: name,
                cell: phoneType === "cell" ? formatTwilioNumber(phoneNumber) : undefined,
                home: phoneType === "home" ? formatTwilioNumber(phoneNumber) : undefined,
                work: phoneType === "work" ? formatTwilioNumber(phoneNumber) : undefined,
                personalEmail: emailAddress.toLowerCase()
            };
            const updateContactResult = await updateContact(input);
            if (updateContactResult.isSuccess) {
                return updateContactResult.result;
            } else {
                setError("Could not update the contact info.");
            }
        }
    }

    const handleUpdateOwner = async () => {
        if (owner) {
            const contact = await updateOwnerContact();
            const location = city + ", " + state;
            const input: UpdateOwnerInput = {
                id: owner.id,
                name: name,
                location: location,
                ownerContactId: contact.id,
                barnId: barn?.id
            };
            const updateResult = await updateOwner(input);
            if (updateResult.isSuccess) {
                if (horse) {
                    handleUpdateHorseOwner(horse, updateResult.result);
                }
                if (usefNumber) {
                    await handleUpdateUSEFMembership(updateResult.result);
                } 
                if (ecNumber) {
                    await handleUpdateECMembership(updateResult.result);
                }
                onChange(updateResult.result);
                clearForm();
            } else {
                setError(updateResult.message);
            }
        } else {
            setError("No owner to edit.");
        }
    }

    const handleUpdateUSEFMembership = async (owner: Owner) => {
        const queryResult = await getMembershipsByPersonId(owner.id);
        const currentMemberships = queryResult.result;
        const newMemberships = usefData?.Memberships;
        if (newMemberships && newMemberships.length) {
            for (var i = 0; i < newMemberships.length; i++) {
                const membership = newMemberships[i];
                let found = false;
                for (var j = 0 ; j < currentMemberships.length; j++) {
                    const currentMembership = currentMemberships[j];
                    if (currentMembership.name === membership.OrgName) {
                        found = true;
                        // Update a current membership
                        const input: UpdateMembershipInput = {
                            id: currentMembership.id,
                            personId: user.id,
                            name: membership.OrgName,
                            membershipId: membership.MemberNumber,
                            membershipStatus: capitalizeName(membership.Status),
                            type: membership.MembershipType,
                            dateMembershipEnds: membership.MemberEndDate,
                            ownerId: owner.id,
                            // membershipOwnerId: owner.id,
                            personName: owner.name
                        };
                        await updateMembership(input);
                    }
                    if (!found) {
                        // Create a new membership
                        const input: CreateMembershipInput = {
                            personId: user.id,
                            name: membership.OrgName,
                            membershipId: membership.MemberNumber,
                            membershipStatus: capitalizeName(membership.Status),
                            type: membership.MembershipType,
                            dateMembershipEnds: membership.MemberEndDate,
                            ownerId: owner.id,
                            // membershipOwnerId: owner.id,
                            personName: owner.name
                        };
                        await createMembership(input);
                    }
                }
            }
        }
    }

    const handleUpdateECMembership = async (owner: Owner) => {
        if (ecData) {
            const queryResult = await getMembershipsByPersonId(owner.id);
            const currentMemberships = queryResult.result;
            let found = false;
            let existingMembership: Membership | null | undefined;
            currentMemberships.forEach((membership: Membership) => {
                if (membership.name === "EC") {
                    found = true;
                    existingMembership = membership;
                }
            });
            const name = ecData.Firstname + " " + ecData.Lastname;
            const ecDataYear = ecData?.Last_Year_Active.toString();
            const formattedDataYear = moment(ecDataYear).format("YYYY");
            const formattedCurrentYear = moment(new Date).format("YYYY");
            const status = formattedDataYear === formattedCurrentYear ? "Active" : "Inactive";
            const dateMembershipEnds = (status === "Active" && ecData.Sport_License_Type !== "Temporary") ? (moment(new Date).format("YYYY") + "-12-31") : "";
            if (found && existingMembership) {
                const input: UpdateMembershipInput = {
                    id: existingMembership.id,
                    personId: user.id,
                    name: "EC",
                    membershipId: ecData.EC_Sport_License_Number.toString(),
                    membershipStatus: status,
                    type: ecData.Sport_License_Type,
                    dateMembershipEnds,
                    personName: name,
                    ownerId: owner.id,
                    // membershipOwnerId: owner.id,
                    isProfessional: !ecData.Amateur
                };
                await updateMembership(input);
            } else {
                const input: CreateMembershipInput = {
                    personId: user.id,
                    name: "EC",
                    membershipId: ecData.EC_Sport_License_Number.toString(),
                    membershipStatus: status,
                    type: ecData.Sport_License_Type,
                    dateMembershipEnds,
                    personName: name,
                    ownerId: owner.id,
                    // membershipOwnerId: owner.id,
                    isProfessional: !ecData.Amateur
                };
                await createMembership(input);
            }
        }
    }

    const handleAddOwner = async () => {
        const isValid = verifyForm();
        if (isValid) {
            await handleCreateOwner();
        }
    }

    const handleEditOwner = async () => {
        const isValid = verifyForm();
        if (isValid) {
            await handleUpdateOwner();
        }
    }

    const handleDeleteOwner = async () => {
        if (owner) {
            const deleteResult = await deleteOwner({id: owner.id});
            if (deleteResult.isSuccess) {
                onChange();
                clearForm();
            } else {
                setError(deleteResult.message);
            }
        } else {
            setError("No owner to delete.");
        }
    }

    return (
        <>
            {/* <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton onClick={() => {setShowECForm(false); setShowUSEFForm(true);}}>
                        Add From USEF
                    </IonButton>
                </IonCol>
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton onClick={() => {setShowUSEFForm(false); setShowECForm(true);}}>
                        Add From EC
                    </IonButton>
                </IonCol>
            </IonRow> */}
            {showUSEFForm && (
                <>
                    <USEFRiderMembershipForm rider={owner} onChange={onUSEFNumberSubmit}/>
                    <hr/>
                </>
            )}
            {showECForm && (
                <>
                    <ECRiderMembershipForm rider={owner} onChange={onECNumberSubmit} />
                    <hr/>
                </>
            )}
            {error && <ErrorAlert error={error} />}
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <IonItem color="white">
                        <IonLabel position="stacked">Name <RequiredInputIndicator /></IonLabel>
                        <IonInput 
                            type="text"
                            value={name}
                            aria-required={true}
                            onIonChange={e => {
                                setName(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <IonItem color="white">
                        <IonLabel position="stacked">City <RequiredInputIndicator /></IonLabel>
                        <IonInput 
                            type="text"
                            value={city}
                            aria-required={true}
                            onIonChange={e => {
                                setCity(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <SelectState isRequired={true} selectedValue={state} onInputChange={handleProvStateInputChange} />
                </IonCol>
            </IonRow>
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <IonItem color="white">
                        <IonLabel position="stacked">Email Address</IonLabel>
                        <IonInput 
                            type="email"
                            value={emailAddress}
                            aria-required={true}
                            onIonChange={e => {
                                setEmailAddress(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <IonItem color="white">
                        <IonLabel position="stacked">Phone Number</IonLabel>
                        <IonInput 
                            type="tel"
                            value={phoneNumber}
                            aria-required={true}
                            onIonChange={e => {
                                setPhoneNumber(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <PhoneNumberTypeSelect selectedType={phoneType} onChange={handlePhoneNumberTypeSelectInput} />
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
             {owner && (
                    <IonCol sizeMd="4" className="ion-text-center">
                        <IonButton
                            className="ion-margin-top"
                            color="danger"
                            expand="block"
                            onClick={handleDeleteOwner}
                        >
                            Delete Owner
                        </IonButton>
                    </IonCol>
                )}
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton
                        className="ion-margin-top"
                        color="tertiary"
                        expand="block"
                        onClick={owner ? handleEditOwner : handleAddOwner}
                    >
                        {owner ? "Edit Owner" : "Add Owner"}
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default OwnerForm;