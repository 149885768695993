import { PrizeMoneyTable, PrizeMoneyTiers } from "../../models";
import { getPrizeMoneyTableById } from "./PrizeMoneyTable";

export async function getPrizeMoneyAmountFromTable(prizeMoneyTable: PrizeMoneyTable, place: number): Promise<(string | null)> {
    let tiers: PrizeMoneyTiers = {};

    // Make sure the table has the tiers
    if (prizeMoneyTable && prizeMoneyTable.tiers && prizeMoneyTable.tiers.first) {
        tiers = prizeMoneyTable.tiers
    } else {
        const queryResult = await getPrizeMoneyTableById(prizeMoneyTable.id);
        if (queryResult.isSuccess) {
            const result: PrizeMoneyTable = queryResult.result;
            if (result && result.tiers && result.tiers.first) tiers = result.tiers;
        }
    }

    // Next get the value of the given tier
    if (place === 1 && tiers.first) return tiers.first;
    else if (place === 2 && tiers.second) return tiers.second;
    else if (place === 3 && tiers.third) return tiers.third;
    else if (place === 4 && tiers.fourth) return tiers.fourth;
    else if (place === 5 && tiers.fifth) return tiers.fifth;
    else if (place === 6 && tiers.sixth) return tiers.sixth;
    else return null;
}