import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import { S3Object } from "../../models";
import { close } from "ionicons/icons";
import VideoPlayer from "./VideoPlayer";
import { handleDeleteS3Item } from "../../utilities/s3Object/s3Object";
import { UpdatePersonInput } from "../../API";
import { updatePerson } from "../../utilities/person/Person";
import { createS3Object } from "../../utilities/s3Object/s3Object";
import moment from "moment";

interface _Props {
    updatedVideoList?: S3Object[]
}

const VideoList: React.FC<_Props> = ({updatedVideoList}) => {
    const user = useContext(PersonContext);

    const [selectedVideo, setSelectedVideo] = useState<S3Object | null | undefined>();
    const [selectedTitle, setSelectedTitle] = useState("");
    const [editedTitle, setEditedTitle] = useState("");
    const [editedDescription, setEditedDescription] = useState("");
    const [videos, setVideos] = useState<any>();
    const [error, setError] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (user && user.media) {
            setVideos(user.media);
        }
        setError("");
    }, []);

    useEffect(() => {
        if (updatedVideoList) {
            setVideos(updatedVideoList);
        }
        setError("");
    }, [updatedVideoList]);

    const handleSelectedFile = (file: S3Object) => {
        setShowModal(true);
        try {
            setSelectedTitle(file.title || "");
            setSelectedVideo(file);
            setError("");
        } catch (error: any) {
        }
    }

    const handleUpdateSelectedFile = async (file?: S3Object) => {
        try {
            if (file) {
                setIsLoading(true);

                // Track all videos associated with this person
                let videoList: any[] = [];

                // Add any videos already asscoiated with this person
                const currentVideos: any[] = user.media ? user.media : [];
                if (currentVideos && currentVideos.length > 0) {
                    videoList = videoList.concat(currentVideos);
                } 

                const s3Object = {
                    ...file,
                    title: editedTitle,
                    description: editedDescription
                }

                const index = videoList.findIndex(currentVideo => currentVideo.key === file.key);
                const updatedVideoList = [
                    ...videoList.slice(0, index),
                    s3Object,
                    ...videoList.slice(index + 1)
                ];
                if (user) {
                    const personInput: UpdatePersonInput = {
                        id: user.id,
                        media: updatedVideoList
                    };
                    const updateResult = await updatePerson(personInput);
                    if (updateResult.isSuccess) {
                        const updatedPerson = updateResult.result;
                        const updatedVideos = updatedPerson.media;
                        setVideos(updatedVideos);
                        setIsEditing(false);
                        setSelectedTitle("");
                        setSelectedVideo(undefined);
                        setShowModal(false);
                        setIsLoading(false);
                    } else {
                        setError("Could not save the video to your profile.");
                        setIsLoading(false);
                    }
                } else {
                    setError("Could not save the video to your profile.");
                    setIsLoading(false);
                }
            }
        } catch (error: any) {
            setIsLoading(false);
        }
    }

    const handleDeleteSelectedFile = async (file?: S3Object) => {
        try {
            if (file) {
                const deleteResult = await handleDeleteS3Item(file.key);
                if (deleteResult.isSuccess) {
                    let updatedVideos: S3Object[] = [];
                    if (videos && videos.length) {
                        videos.forEach((video: S3Object | null ) => {
                            if (video && video.key !== file.key) {
                                updatedVideos.push(video);
                            }
                        });
                    }
                    const personInput: UpdatePersonInput = {
                        id: user.id,
                        media: updatedVideos
                    };
                    const updateResult = await updatePerson(personInput);
                    if (updateResult.isSuccess) { 
                        setSelectedTitle("");
                        setSelectedVideo(undefined);
                        setVideos(updatedVideos);
                        setError("");
                        setShowModal(false);
                    } else {
                        setError("Could not remove the video from your library.");
                    }
                } else {
                    setError("Could not remove the video.");
                }
            }
        } catch (error: any) {
        }
    }

    return (
        <>
            {isLoading ?
                <IonRow>
                    <IonCol className="ion-text-center">
                        <Spinner />
                    </IonCol>
                </IonRow>
                :
                <>
                    {videos ?
                        <IonList className="bg-white">
                            {videos.map((video: S3Object, index: number) => (
                                <IonItem key={index}>
                                    <IonLabel onClick={() => handleSelectedFile(video)}>
                                        <p>{video.title}</p>
                                    </IonLabel>
                                </IonItem>
                            ))}
                        </IonList>
                        :
                        <p>No videos yet.</p>
                    }
                    <IonModal backdropDismiss={false} isOpen={showModal} id="contactModal">
                        <IonToolbar color="light">
                            <IonTitle className="ion-text-center">
                                {selectedTitle}
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    fill="clear"
                                    onClick={() => setShowModal(false)}
                                >
                                    <p id="closeContactModalBtn" className="font-weight-normal text-medium text-capitalize">
                                        <IonIcon icon={close} />
                                    </p>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                        <IonContent className="ion-padding">
                            {selectedVideo ?
                                <div>
                                    <VideoPlayer videoObject={selectedVideo}/>
                                    <IonRow>
                                        <IonCol className="ion-padding">
                                            <p>Date Uploaded: {moment(selectedVideo.dateUploaded).format("MMMM D, YYYY")}</p>
                                            <p>Original File Extension: {selectedVideo.originalExtension}</p>
                                            {isEditing ?
                                                <IonRow>
                                                    <IonCol size="12">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Title</IonLabel>
                                                            <IonInput 
                                                                type="text"
                                                                value={editedTitle}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setEditedTitle(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                    <IonCol size="12">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Description</IonLabel>
                                                            <IonInput 
                                                                type="text"
                                                                value={editedDescription}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setEditedDescription(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                    <IonCol size="12" className="ion-text-center">
                                                        <IonButton color="success" expand="block" onClick={() => handleUpdateSelectedFile(selectedVideo)}>
                                                            Save
                                                        </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                                :
                                                <p>Description: {selectedVideo.description}</p>
                                            }
                                        </IonCol>
                                    </IonRow>
                                </div>
                                :
                                <p>Could not find the video.</p>
                            }
                        </IonContent>
                        <IonFooter>
                            <IonRow className="ion-justify-content-center ion-padding">
                                <IonCol sizeMd="6" className="text-center">
                                    <IonButton color="danger" onClick={() => handleDeleteSelectedFile(selectedVideo!)}>
                                        Delete Video
                                    </IonButton>
                                </IonCol>
                                <IonCol sizeMd="6" className="text-center">
                                    <IonButton color="primary" onClick={() => setIsEditing(true)}>
                                        Edit Title
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonFooter>
                    </IonModal>
                </>
            }
        </>
    );
};

export default VideoList;