import React, { useEffect, useState } from "react";
import { sectionList, USHJAOutreachSection } from "../../data/content/USHJAOutreachSections";
import BasicSelect from "../Select/BasicSelect";

interface _Props {
    menuPlacement?: string
    selectedValue?: string
    onSelect: Function
}

interface formattedOption {
    label: string
    value: string
    object: USHJAOutreachSection
}

const SelectUSHJAOutreachClass: React.FC<_Props> = ({selectedValue, menuPlacement, onSelect}) => {

    const [formattedClasses, setFormattedClasses] = useState<formattedOption[] | null | undefined>();
    const [selectedClass, setSelectedClass] = useState<USHJAOutreachSection | null | undefined>();

    const formatClasses = () => {
        let formattedClasses = [];
        for (var i = 0; i < sectionList.length; i++) {
            const label = sectionList[i].name;
            let object = {
                value: sectionList[i].name || "",
                label: label,
                object: sectionList[i]
            };
            formattedClasses.push(object);
        }
        setFormattedClasses(formattedClasses);
    }

    useEffect(() => {
        formatClasses();
    }, []);

    useEffect(() => {
        if (selectedValue) findSelectedValue(selectedValue);
    }, [selectedValue]);

    const findSelectedValue = (name: string) => {
        for (var i = 0; i < sectionList.length; i++) {
            const section = sectionList[i];
            const label = section.name;
            if (label === name) {
                setSelectedClass(section);
                break;
            }
        }
    }

    const handleOnChange = (event: any) => {
        if (event) {
            setSelectedClass(event);
            onSelect(event);
        } else {
            setSelectedClass(undefined);
            onSelect(event);
        }
    }

    return (
        <>
            {formattedClasses ?
                <BasicSelect formattedValues={formattedClasses} selectedValue={selectedClass?.name} menuPlacement={menuPlacement} onSelect={handleOnChange}/>
                :
                <p>Loading options...</p>
            }
        </>
    );
};

export default SelectUSHJAOutreachClass;
