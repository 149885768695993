import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Address, Barn, Contact, EventEntry, Membership, Person, Rider } from "../../models";
import { formatDisplayNameForUser } from "../../utilities/person/PersonNameFormat";
import ErrorAlert from "../Errors/ErrorAlert";
import RiderSelectStatus from "./RiderSelectStatus";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";
import SelectFromAllPeople from "../Person/SelectFromAllPeople";
import BirthdateInput from "../DatePicker/BirthdateInput";
import SelectFromAllBarns from "../Barn/SelectFromAllBarns";
import SelectFromAllAddresses from "../Address/SelectFromAllAddresses";
import SelectFromAllContacts from "../Contact/SelectFromAllContacts";
import { getMembershipsByRiderId } from "../../utilities/membership/Membership";
import { getEventEntriesByRiderId } from "../../utilities/eventEntry/EventEntry";
import { UpdateRiderInput } from "../../API";
import { updateRider } from "../../utilities/rider/Rider";

interface _Props {
    rider: Rider
    onChange: Function
}

const EditRiderForm: React.FC<_Props> = ({rider, onChange}) => {
    
    // Basic Info
    const [selectedPersonId, setSelectedPersonId] = useState<string | null | undefined>(rider.personId);
    const [selectedPerson, setSelectedPerson] = useState<Person | null | undefined>(null);
    const [name, setName] = useState<string | null | undefined>(rider.name);
    const [birthDate, setBirthDate] = useState<string | null | undefined>(rider.birthdate);
    const [age, setAge] = useState<number | null | undefined>(rider.age);
    const [professionalStatus, setProfessionalStatus] = useState<boolean | null | undefined>(rider.isProfessional);

    // Barn Info
    const [barn, setBarn] = useState<Barn | null | undefined>(rider.barn);

    // Contact Info
    const [selectedContact, setSelectedContact] = useState<Contact | null | undefined>(rider.contact);

    // Address Info
    const [selectedAddress, setSelectedAddress] = useState<Address | null | undefined>(rider.address);

    // Membership Info
    const [riderMemberships, setRiderMemberships] = useState<Membership[] | null | undefined>();

    // Entry Info
    const [riderEntries, setRiderEntries] = useState<EventEntry[] | null | undefined>();

    const [error, setError] = useState("");

    const getRiderMemberships = async () => {
        const queryResult = await getMembershipsByRiderId(rider.id);
        if (queryResult.isSuccess) {
            const membershipList: Membership[] = queryResult.result;
            setRiderMemberships(membershipList);
        } else {
            setRiderMemberships([]);
        }
    }

    const getRiderEntries = async () => {
        const queryResult = await getEventEntriesByRiderId(rider.id);
        if (queryResult.isSuccess) {
            const entryList: EventEntry[] = queryResult.result;
            setRiderEntries(entryList);
        } else {
            setRiderEntries([]);
        }
    }

    const getRiderData = async () => {
        await getRiderMemberships();
        await getRiderEntries();
    }

    useEffect(() => {
        if (!riderMemberships) getRiderData();
    }, [rider]);

    const verifyForm = () => {
        if (!name) {
            setError("Please provide a name for the rider.");
            return false;
        }
        return true;
    }

    const handleSelectedPerson = (p: Person) => {
        setSelectedPersonId(p.id);
        setSelectedPerson(p);
        const formattedName = formatDisplayNameForUser(p);
        setName(formattedName);
    }

    const handleSelectedBirthdate = (birthdate: string) => {
        setBirthDate(birthdate);
        const age = moment().diff(birthdate, 'years');
        setAge(age);
    }

    const handleUpdateRider = async () => {
        let riderInput: UpdateRiderInput = {
            id: rider.id,
            name: name,
            birthdate: birthDate ? moment(birthDate).format("YYYY-MM-DD") : null,
            age: null,
            isProfessional: professionalStatus,
            personId: selectedPersonId || null,
            barnId: barn?.id || null,
            barnName: barn?.name || null,
        };
        if (selectedContact) {
            riderInput["riderContactId"] = selectedContact.id;
        }
        if (selectedAddress) {
            riderInput["addressId"] = selectedAddress.id;
            const location = (selectedAddress.city ? selectedAddress.city + ", " : "") + (selectedAddress.provState ? selectedAddress.provState : "");
            riderInput["location"] = location;
        }
        const updateResult = await updateRider(riderInput);
        if (updateResult.isSuccess) {
            const updatedRider: Rider = updateResult.result;
            onChange(updatedRider);
        } else {
            setError(updateResult.message);
        }
    }

    const handleEditRider = async () => {
        const isValid = verifyForm();
        if (isValid) {
            await handleUpdateRider();
        }
    }

    return (
        <>
            {error && <ErrorAlert error={error} />}
            <IonRow>
                <IonCol>
                    <h5>Basic Info</h5>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    <IonLabel position="stacked" className="description ml-3">Select Person</IonLabel>
                    <SelectFromAllPeople selectedValue={selectedPersonId || ""} person={selectedPerson} onSelect={(p: Person) => handleSelectedPerson(p)} />
                </IonCol>
            </IonRow>
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <IonItem color="white">
                        <IonLabel position="stacked">Rider Name <RequiredInputIndicator /></IonLabel>
                        <IonInput 
                            type="text"
                            value={name}
                            aria-required={true}
                            onIonChange={e => {
                                setName(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <BirthdateInput value={birthDate || undefined} isRequired={false} onChange={(date: string) => handleSelectedBirthdate(date)} />
                </IonCol>
            </IonRow>
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <IonItem color="white">
                        <IonLabel position="stacked">Age</IonLabel>
                        <IonInput 
                            type="number"
                            value={age}
                            disabled
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <RiderSelectStatus rider={rider} onChange={(value: boolean) => setProfessionalStatus(value)} />
                </IonCol>
            </IonRow>
            <hr />
            <IonRow>
                <IonCol>
                    <h5>Barn Info</h5>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    <IonLabel position="stacked" className="description ml-3">Select Barn</IonLabel>
                    <SelectFromAllBarns barn={barn} selectedValue={barn?.id || rider.barnId || ""} onSelect={(b: Barn) => setBarn(b)} />
                </IonCol>
            </IonRow>
            <hr />
            <IonRow>
                <IonCol>
                    <h5>Contact Info</h5>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    <IonLabel position="stacked" className="description ml-3">Current Contact Id</IonLabel>
                    <p className="description ml-3">{selectedContact?.id || "none"}</p>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    <IonLabel position="stacked" className="description ml-3">Select Contact</IonLabel>
                    <SelectFromAllContacts selectedValue={selectedContact?.id} contact={selectedContact} onSelect={(c: Contact) => setSelectedContact(c)}/>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    <IonLabel position="stacked" className="description ml-3">Current Contact Mailing Address Id</IonLabel>
                    <p className="description ml-3">{selectedContact?.mailingAddress || "none"}</p>
                </IonCol>
            </IonRow>
            <hr />
            <IonRow>
                <IonCol>
                    <h5>Address Info</h5>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    <IonLabel position="stacked" className="description ml-3">Current Address Id</IonLabel>
                    <p className="description ml-3">{selectedAddress?.id || "none"}</p>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    <IonLabel position="stacked" className="description ml-3">Select Address</IonLabel>
                    <SelectFromAllAddresses addressValue={selectedAddress?.id} onSelect={(a: Address) => setSelectedAddress(a)}/>
                </IonCol>
            </IonRow>
            <hr />
            <IonRow>
                <IonCol>
                    <h5>Membership Info</h5>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    {(riderMemberships && riderMemberships.length > 0) ?
                        <>
                            {riderMemberships.map((membership, i) => (
                                <div key={i} className="ml-3">
                                    <p className="ion-text-wrap description">{i+1}:</p>
                                    <p className="ion-text-wrap description">Name: {membership?.name}</p>
                                    <p className="ion-text-wrap description">Id: {membership?.membershipId}</p>
                                    <p className="ion-text-wrap description">Status: {membership?.membershipStatus}</p>
                                    <p className="ion-text-wrap description">Created On: {membership?.createdOn ? moment(membership?.createdOn).format("MM/DD/YYYY") : ""}</p>
                                    <hr/>
                                </div>
                            ))}
                        </>
                        :
                        <p>None</p>
                    }
                </IonCol>
            </IonRow>
            <hr />
            <IonRow>
                <IonCol>
                    <h5>Entry Info</h5>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="12">
                    {(riderEntries && riderEntries.length > 0) ?
                        <>
                            {riderEntries.map((entry, i) => (
                                <div key={i} className="ml-3">
                                    <p className="ion-text-wrap description">{i+1}:</p>
                                    <p className="ion-text-wrap description">Entry Id: <a href={`/index/staff/event/entry/${entry.id}`} target="_blank" rel="noreferrer">{entry.id}</a></p>
                                    <p className="ion-text-wrap description">Event Id: {entry.eventId}</p>
                                    <p className="ion-text-wrap description">Status: {entry?.status}</p>
                                    <p className="ion-text-wrap description">Created On: {entry?.createdOn ? moment(entry?.createdOn).format("MM/DD/YYYY") : ""}</p>
                                    <hr/>
                                </div>
                            ))}
                        </>
                        :
                        <p>None</p>
                    }
                </IonCol>
            </IonRow>
            <hr/>
            {error && <ErrorAlert error={error} />}
            <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton
                        className="ion-margin-top"
                        color="tertiary"
                        expand="block"
                        onClick={handleEditRider}
                    >
                        Edit Rider
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default EditRiderForm;