import { 
    IonCard, IonCardContent, IonCol, IonIcon, IonItem, IonLabel, IonRow
} from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";
import React, { useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    title: string
    content: any

    isLoading: boolean
    progressMessage?: string

    error: string
}

const EventReportCard: React.FC<_Props> = ({title, content, isLoading, progressMessage, error}) => {

    const [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <IonCard color="white" className="ion-padding">
            <IonCardContent>
                <IonItem>
                    <IonLabel>
                        <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                            <IonCol>
                                <h1>{title}</h1>
                            </IonCol>
                            <IonCol className="ion-text-right">
                                <p>{isCollapsed ? <IonIcon icon={chevronDown} />  : <IonIcon icon={chevronUp} />}</p>
                            </IonCol>
                        </IonRow>
                        {!isCollapsed &&
                            <>
                                {isLoading ?
                                    <>
                                        <Spinner />
                                        {progressMessage && <p>{progressMessage}</p>}
                                    </>
                                    :
                                    <IonRow>
                                        <IonCol>
                                            {error && <ErrorAlert error={error} />}
                                            {content}
                                        </IonCol>
                                    </IonRow>
                                }
                            </>
                        }
                    </IonLabel>
                </IonItem>
            </IonCardContent>
        </IonCard>
    );
};

export default EventReportCard;
