export const typeList = [
    "Mailing Address",
    "Billing Address"
];

export interface AddressTypeOption {
    name: string
    value: string
}

export const addressTypes = [
    {
        "name": "Mailing Address",
        "value": "mailing"
    },
    {
        "name": "Billing Address",
        "value": "billing"
    },
];