/**
 * Takes in a height in hands (ex: 14.2) and returns a height in inches (ex: 58)
 * @param heightInHands number
 */
export function calculateHeightInInches(heightInHands: number) {
    const heightString = heightInHands.toString();
    let handsString = "";
    let fractionString = "0";
    if (heightString.includes(".")) {
        const heightArray = heightString.split(".");
        handsString = heightArray[0];
        fractionString = heightArray[1];
    } else {
        handsString = heightString;
    }
    const hands = parseInt(handsString);
    const fraction = parseInt(fractionString);
    const inches = hands * 4 + fraction;
    return inches;
}

/**
 * Takes in a height in inches (ex: 58) and returns a height in hands (ex: 14.2)
 * @param heightInHands number
 */
 export function calculateHeightInHands(heightInInches: number) {
    const hands = Math.floor(heightInInches / 4);
    const remainder = heightInInches % 4;
    const heightString = hands.toString() + "." + remainder.toString();
    return parseFloat(heightString);
}

export function getHeightAcronym(heightInInches: number) {
    const hands = Math.floor(heightInInches / 4);
    const remainder = heightInInches % 4;
    const heightString = hands.toString() + "." + remainder.toString();
    const heightInHands = parseFloat(heightString);

    if (heightInHands >= 14.2) return "H";
    else if (heightInHands < 14.2 && heightInHands >= 13.2) return "L";
    else if (heightInHands < 13.2 && heightInHands > 12.2) return "M";
    else if (heightInHands <= 12.2) return "S";
    else return "";
}