import React, { useEffect, useState } from "react";
import { IonCol, IonContent, IonPage, IonRow } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import Header from "../../../components/Headers/Header";
import { getEventById } from "../../../utilities/events/Event";
import ErrorAlert from "../../../components/Errors/ErrorAlert";
import EntrySectionCard from "../../../components/Cards/EntrySectionCard";
import EntryPageHeader from "../../../components/Entry/EntryForm/EntryPageHeader";
import PageTitle from "../../../components/PageTitle/PageTitle"
import { Event } from "../../../models";
import Spinner from "../../../components/Spinners/Spinner";

interface EventNewEntryPageProps extends RouteComponentProps<{ id: string }> {}

export type ExpandedCardStates = "header" | "entry" | "stabling" | "waivers" | "review" | undefined;

const EventNewEntryPage: React.FC<EventNewEntryPageProps> = ({ match }) => {
    const [expandedCard, setExpandedCard] = useState<ExpandedCardStates>("header");
    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            setIsLoading(true);
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setCurrentEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
            setIsLoading(false);
        }
        getEvent();
    }, [match.params.id]);

    const handleCollapseOrExpand = (card: ExpandedCardStates) => (isExpand: boolean) => {
        if (isExpand) setExpandedCard(card);
        else setExpandedCard(undefined);
    };

    const navigateToNextCard = () => {
        if (expandedCard === "header") setExpandedCard("entry");
        if (expandedCard === "entry") setExpandedCard("stabling");
        if (expandedCard === "stabling") setExpandedCard("waivers");
        if (expandedCard === "waivers") setExpandedCard("review");
        if (expandedCard === "review") setExpandedCard("header");
    };

    return (
        <IonPage className="bg-medium p-0 m-0"> 
            <Header />
            <IonContent className="p-0 m-0">
                <PageTitle title="New Entry" newStyles={true} />
                {error && <ErrorAlert width="12" error={error} />}
                {isLoading ? (
                    <Spinner />
                ) : (
                    currentEvent ? (
                        <IonCol className="p-0 m-0">
                            <EntryPageHeader
                                event={currentEvent}
                                expandedCard={expandedCard}
                                handleCollapseOrExpand={handleCollapseOrExpand}
                                navigateToNextCard={navigateToNextCard}
                            />

                            <EntrySectionCard
                                event={currentEvent}
                                title="Horse 1"
                                subtitle="New Horse"
                                chipTitle="Not Started"
                                trashIcon={true}
                                expandButtonTitle="Start"
                                isExpand={expandedCard === "entry"}
                                cardContent={expandedCard}
                                toggleExpansion={handleCollapseOrExpand("entry")}
                                onSubmit={navigateToNextCard}
                            />

                            <EntrySectionCard
                                event={currentEvent}
                                title="Stabling"
                                subtitle="Subtitle"
                                chipTitle="Not Started"
                                trashIcon={false}
                                expandButtonTitle="Start"
                                isExpand={expandedCard === "stabling"}
                                cardContent={expandedCard}
                                toggleExpansion={handleCollapseOrExpand("stabling")}
                                onSubmit={navigateToNextCard}
                            />

                            <EntrySectionCard
                                event={currentEvent}
                                title="Waivers"
                                subtitle="Subtitle"
                                chipTitle="Not Started"
                                trashIcon={false}
                                expandButtonTitle="Start"
                                isExpand={expandedCard === "waivers"}
                                cardContent={expandedCard}
                                toggleExpansion={handleCollapseOrExpand("waivers")}
                                onSubmit={navigateToNextCard}
                            />
                        </IonCol>
                    ) : (
                        <p>No event was found.</p>
                    )
                )};
            </IonContent>
        </IonPage>
    );
};

export default EventNewEntryPage;
