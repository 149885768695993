import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event, EventClass} from "../../../../../models";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import EventClassForm from "../../../../../components/EventClass/EventClassForm";
import { getEventClassById } from "../../../../../utilities/eventClass/EventClass";

interface EventPageProps extends RouteComponentProps<{
    id: string;
    classId: string;
}> {}


const EventClassPage: React.FC<EventPageProps> = ({match}) => {
    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [currentEventClass, setCurrentEventClass] = useState<EventClass | null | undefined>();
    const [error, setError] = useState("");

    async function getEvent() {
        const queryResult = await getEventById(match.params.id);
        if (queryResult.isSuccess) {
            setCurrentEvent(queryResult.result);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getEventClass() {
        const queryResult = await getEventClassById(match.params.classId);
        if (queryResult.isSuccess) {
            setCurrentEventClass(queryResult.result);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    useEffect(() => {
        if (match.params.id) getEvent();
        if (match.params.classId) getEventClass();
    }, [match]);

    const handleSubmit = () => {
        
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent ? currentEvent.name : "Event Settings"} />
                <IonRow className="ion-justify-content-center">
                    {error && <ErrorAlert width="12" error={error} />}
                    <IonCol size="12">
                        {currentEvent && currentEventClass && (
                            <IonCard color="white" className="ion-padding">
                                <IonCardTitle>
                                    <IonRow>
                                        <IonCol>
                                            <IonText>
                                                <h3>Class</h3>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonCardTitle>
                                <IonCardContent>
                                    <EventClassForm event={currentEvent} eventClass={currentEventClass} onSubmit={() => handleSubmit()} />
                                </IonCardContent>
                            </IonCard>
                        )}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EventClassPage;