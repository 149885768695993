import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React from "react";

const TrainerCalendarPage: React.FC = () => {
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Calendar" />
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="8" className="ion-text-center">
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default TrainerCalendarPage;