import { FormattedMembership } from "../../components/Memberships/OrganizationMembershipsTable";
import { getAddressById } from "../address/Address";
import { getContactById } from "../contact/Contact";
import CONSTANT from "../../constant/constant"
import { getPersonalInformationByPersonId } from "../personalInformation/PersonalInformation";
import { downloadCSVFile } from "./ReportDownloadCSV";
import constants from "../../constant/constant";
require('jspdf-autotable');

export const headerRow = [
    "Membership Type",
    "Status",
    "Member Id",
    "Back Number",
    "Rider Name",
];

export function generateOrgMemberReport(formattedMemberships: FormattedMembership[], organizationId?: string) {

    if (organizationId === constants.RHSC_ORGANIZATION.id) {
        headerRow.push("Horse Name");
        headerRow.push("Barn Name");
        headerRow.push("Volunteer Hours");
        headerRow.push("Meetings Attended");
        headerRow.push("Shows Attended");
    }

    if (organizationId === "e05fc919-2793-4ead-acef-3f6efb584b67" || organizationId === "ddc2fd3c-0bde-4b39-bbbb-5e00d2a35ba0") {
        headerRow.push("Team Name");
    }

    let rows = [];

    rows.push(headerRow);

    for (let i = 0; i < formattedMemberships.length; i++) {
        const membership = formattedMemberships[i];
        let currentRow: any[] = [];

        currentRow.push(membership.type);
        if(membership.membershipStatus === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS) {
            currentRow.push(CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS_TEXT);
        } else {
            currentRow.push(CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE_TEXT);
        }
        currentRow.push(membership.membershipNumber);
        currentRow.push(membership.backNumber);
        currentRow.push(membership.personName);
        
        if (organizationId === "e05fc919-2793-4ead-acef-3f6efb584b67" || organizationId === "ddc2fd3c-0bde-4b39-bbbb-5e00d2a35ba0") {
            currentRow.push(membership.barnName);
        }
        
        if (organizationId === constants.RHSC_ORGANIZATION.id) {
            currentRow.push(membership.horseName);
            currentRow.push(membership.barnName);
            currentRow.push(membership.volunteerHours);
            currentRow.push(membership.meetingsAttended);
            currentRow.push(membership.showsAttended);
        }
        rows.push(currentRow);
    }

    const fileName = "members.csv"
    downloadCSVFile(fileName, rows);
}

export async function generateOrgMemberContactReport(formattedMemberships: FormattedMembership[]) {
    const contactHeaderRow = [
        "Member Id",
        "Rider Name",
        "Barn Name",
        "Email",
        "Phone Number Type",
        "Phone Number",
        "Street Address Line 1",
        "Street Address Line 2",
        "City",
        "State",
        "Zip",
        "Country"
    ];

    let rows = [];

    rows.push(contactHeaderRow);

    for (let i = 0; i < formattedMemberships.length; i++) {
        const formattedMembership = formattedMemberships[i];
        const queryResult = await getPersonalInformationByPersonId(formattedMembership.membership.contactPersonId || formattedMembership.membership.personId);
        const personInfo = queryResult.result;

        const addressResult = await getAddressById(personInfo?.addressId ?? "");
        const addressData = addressResult.result;
        
        const phoneResult = await getContactById(personInfo?.contactId ?? "");
        const contactData = phoneResult.result;

        let currentRow: any[] = [];
        currentRow.push(formattedMembership.membershipNumber);
        currentRow.push(formattedMembership.personName);
        currentRow.push(formattedMembership.barnName);
        currentRow.push(formattedMembership.membership.personEmail);
        if(contactData.cell) {
            currentRow.push("Cell");
            currentRow.push(contactData.cell);
        } else if (contactData.home) {
            currentRow.push("Home");
            currentRow.push(contactData.home);
        } else if(contactData.work) {
            currentRow.push("Work");
            currentRow.push(contactData.work);
        } else {
            currentRow.push("");
            currentRow.push("");
        }
        currentRow.push(addressData.streetAddress1);
        currentRow.push(addressData.streetAddress2);
        currentRow.push(addressData.city);
        currentRow.push(addressData.provState);
        currentRow.push(addressData.zip);
        currentRow.push(addressData.country);

        rows.push(currentRow);
    }

    const fileName = "Members-Contacts.csv"
    downloadCSVFile(fileName, rows);
}