import {
    IonLabel,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    id?: string
    isRequired?: boolean
    selectedType?: string 
    onChange: Function
}

const PhoneNumberTypeSelect: React.FC<_Props> = ({id, isRequired, selectedType, onChange}) => {
    const [type, setType] = useState<string>("-");

    useEffect(() => {
        if (selectedType) setType(selectedType);
    }, [selectedType]);
    return (
        <>
            <FormGroup>
                <IonLabel position="stacked" className="description pl-3">Phone Number Type {isRequired && <RequiredInputIndicator />}</IonLabel>
                <Input name="isProfessionalInput" type="select" id={ id ? `${id}-type-select` : "phone-number-type-select"} value={type} onChange={e => {setType(e.target.value); onChange(e.target.value);}}>
                    <option>-</option>
                    <option>cell</option>
                    <option>home</option>
                    <option>work</option>
                </Input>
            </FormGroup>
        </>
    );
};

export default PhoneNumberTypeSelect;