import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorBanner from "../Banners/ErrorBanner";
import FileBrowser from "../FileBrowser/FileBrowser";
import { PersonContext } from "../../context/PersonContext";
import { createEventDocumentFileName, handleUploadS3File } from "../../utilities/s3Object/s3Object";
import Spinner from "../Spinners/Spinner";
import { Event } from "../../models";
import { createS3Object } from "../../utilities/s3Object/s3Object";
import { updateEvent } from "../../utilities/events/Event";
import { UpdateEventInput } from "../../API";

interface _Props {
    event?: Event
    onSubmit: Function
}

const DocumentForm: React.FC<_Props> = ({event, onSubmit}) => {
    const user = useContext(PersonContext);

    const [title, setTitle] = useState("");
    const [document, setDocument] = useState<any>();
    const [documentList, setDocumentList] = useState<any[]>(event?.documents || []);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setTitle("");
        setDocument(undefined);
        setError("");
    }, []);

    useEffect(() => {
        setDocumentList(event?.documents || []);
    }, [event]);

    const handleSelectedFile = (file: File) => {
        setIsLoading(true);
        try {
            setDocument(file);
            setTitle(file.name);
            setError("");
        } catch (error: any) {
            setError(error);
        }
        setIsLoading(false);
    }

    const handleSaveFile = async () => {
        setIsLoading(true);
        
        // Upload this to S3 
        const fileName = createEventDocumentFileName(event);
        const uploadResult = await handleUploadS3File(fileName, document);
        if (uploadResult.isSuccess) {
            // If successfully added to S3, save to document list on event
            const key = uploadResult.result;
            const s3Object = createS3Object(key, title, document);
            documentList.push(s3Object);
            if (event) {
                const eventInput: UpdateEventInput = {
                    id: event.id,
                    lastUpdatedBy: user.id,
                    documents: documentList
                };
                const updateResult = await updateEvent(eventInput);
                if (updateResult.isSuccess) {
                    const updatedEvent = updateResult.result;
                    const updatedDocuments = updatedEvent.documents;
                    setDocumentList(updatedDocuments);
                    onSubmit(updatedDocuments);
                } else {
                    setError("Could not save the document to your event.");
                }
            } else {
                setError("Could not save the document to your event.");
            }
        } else {
            setError("Could not upload the document. Contact hello@ringsidepro.com for help.")
        }
        setIsLoading(false);
    }

    return (
        <>
            {isLoading ?
                <IonRow>
                    <IonCol className="ion-text-center">
                        <Spinner />
                    </IonCol>
                </IonRow>
                :
                <form>
                    {error && (
                        <IonRow>
                            <IonCol>
                                <ErrorBanner error={error} />
                            </IonCol>
                        </IonRow>
                    )}
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12" className="text-center">
                            <FileBrowser handleSelectedFile={handleSelectedFile}/>
                        </IonCol>
                    </IonRow>
                    {document && 
                        <>
                            <IonRow className="ion-margin">
                                <IonCol sizeMd="12">
                                    <IonItem>
                                        <IonLabel position="floating">Title</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={title}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setTitle(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-text-center">
                                    <IonButton color="tertiary" onClick={() => handleSaveFile()}>Save</IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                    }
                </form>
            }
        </>
    );
};

export default DocumentForm;