import {
    IonCard,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonSearchbar,
} from "@ionic/react";
import { Event } from "../../models";
import React, { useState } from "react";
import { ribbon } from "ionicons/icons";
import { formatStartEndDatesStrings } from "../../utilities/dates/FormatDates";

interface _Props {
    events: Event[]
}

const EventsList: React.FC<_Props> = ({events}) => {
    const [searchText, setSearchText] = useState("");
    const [filteredEvents, setFilteredEvents] = useState<Event[]>(events);

    const handleSearchInput = async (value: string) => {
        setSearchText(value);
        if (value) {
            filterEvents(value);
        } else if (value === "" && events) {
            setFilteredEvents(events);
        } else {
            setFilteredEvents([]);
        }
    }

    const filterEvents = (value: string) => {
        if (events) {
            const result = events.filter(event => event.name.toLocaleLowerCase().toString().includes(value.toLocaleLowerCase().toString()));
            setFilteredEvents(result);
        }
    }

    return (
        <IonCard className="bg-white ion-padding" mode="md">
            <IonRow className="ion-justify-content-center">
                <IonCol sizeSm="12" sizeMd="10">
                    <IonSearchbar value={searchText} placeholder="Search by Name" onIonChange={e => handleSearchInput(e.detail.value!)} color="white"></IonSearchbar>
                </IonCol>
            </IonRow>

            {filteredEvents && filteredEvents.length ?
                <IonList className="bg-white">
                    {filteredEvents.map((event: Event, index: number) => (
                        <IonItem 
                            key={index} 
                            detail={true}
                            routerLink={"/index/staff/event/" + event.id} 
                            routerDirection="none"
                        >
                            <IonIcon icon={ribbon} slot="start" />
                            <IonLabel>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="4">
                                        <h2 className="ion-text-wrap">{event.name} {event.nickname ? (" (" + event.nickname + ")") : ""}</h2>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="4">
                                        <h2 className="ion-text-wrap">{event.status}</h2>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="4">
                                        <h2 className="ion-text-wrap">{event.startDate ? formatStartEndDatesStrings(event.startDate, event.endDate) : ""}</h2>
                                    </IonCol>
                                </IonRow>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
                :
                <p>No events yet.</p>
            }
        </IonCard>
    );
};

export default EventsList;