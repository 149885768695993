import { IonCol, IonInput, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { Event, EventEntry, EventStallType, StablingRequest } from "../../../models";
import { getEventStallTypesByEventId } from "../../../utilities/eventStallType/EventStallType";

interface _Props {
    event: Event
    isHeaderRow?: boolean
    entry?: EventEntry
    stablingRequest?: StablingRequest
    onSubmit: Function
}

interface formattedOption {
    eventStallType: EventStallType
    quantity: number
}

const EntryStablingFormRow: React.FC<_Props> = ({isHeaderRow, stablingRequest, event, entry, onSubmit}) => {

    const [error, setError] = useState<string>("");
    const [eventStallTypes, setEventStallTypes] = useState<EventStallType[] | null | undefined>();
    const [formattedOptions, setFormattedOptions] = useState<formattedOption[] | null | undefined>();
    const [currentStablingRequest, setCurrentStablingRequest] = useState<StablingRequest | null | undefined>();

    const getEventStallTypes = async (event: Event) => {
        const queryResult = await getEventStallTypesByEventId(event.id);
        if (queryResult.isSuccess) {
            setEventStallTypes(queryResult.result);
            formatEventStallTypeOptions(queryResult.result);
        } else {
            setError("Could not find the stall type options.");
        }
    }

    const formatEventStallTypeOptions = (eventStallTypes: EventStallType[]) => {
        if (eventStallTypes) {
            let result: formattedOption[] = [];
            eventStallTypes.forEach(type => {
                const formattedOption: formattedOption = {
                    eventStallType: type,
                    quantity: 0
                };
                result.push(formattedOption);
            });
            setFormattedOptions(result);
        }
    }

    useEffect(() => {
        if (event) getEventStallTypes(event);
    }, [event]);

    useEffect(() => {
        if (stablingRequest) {
            setCurrentStablingRequest(stablingRequest);
        }
    }, [stablingRequest]);

    const calculateTotal = () => {
        let result = 0;
        if (formattedOptions) {
            formattedOptions.forEach(option => {
                const pricePerStall = parseFloat(option.eventStallType.pricePerStall || "0");
                const taxPerStall = parseFloat(option.eventStallType.taxPerStall || "0") / 100;
                const basePrice = pricePerStall * option.quantity;
                const fullPrice = basePrice * taxPerStall + basePrice;
                result = result + fullPrice;
            });
        }
        return result;
    }

    const handleQuantityChange = (index: number, stallType: EventStallType, quantity: number) => {
        if (formattedOptions){
            const updatedOption = {
                eventStallType: stallType,
                quantity
            };
            const updatedOptions = [
                ...formattedOptions.slice(0, index),
                updatedOption,
                ...formattedOptions.slice(index + 1)
            ];
            setFormattedOptions(updatedOptions);
            onSubmit(entry, updatedOptions);
        }
    }

    return (
        <>
            {isHeaderRow ? 
                <IonRow>
                    <IonCol>
                        <h4>Who to charge</h4>
                    </IonCol>
                    {formattedOptions && (
                        <>
                            {formattedOptions.map((option, index) => (
                                <IonCol key={index}>
                                    <h4>{option.eventStallType.name}</h4>
                                </IonCol>
                            ))}
                        </>
                    )}
                    <IonCol>
                        <h4>Cost</h4>
                    </IonCol>
                </IonRow>
                :
                <>
                    {entry ? 
                        <IonRow>
                            <IonCol>
                                <h2>{entry.horseName}</h2>
                            </IonCol>
                            {formattedOptions && (
                                <>
                                    {formattedOptions.map((option, index) => (
                                        <IonCol key={index}>
                                            <Input
                                                type="number"
                                                min={0}
                                                value={option.quantity.toString()}
                                                onChange={e => {
                                                    handleQuantityChange(index, option.eventStallType, parseInt(e.target.value))
                                                }}
                                            />
                                        </IonCol>
                                    ))}
                                </>
                            )}
                            <IonCol>
                                ${calculateTotal().toFixed(2)}
                            </IonCol>
                        </IonRow>
                        :
                        <>
                            <hr/>
                            <IonRow>
                                <IonCol>
                                    <h4>Split</h4>
                                </IonCol>
                                {formattedOptions && (
                                    <>
                                        {formattedOptions.map((option, index) => (
                                            <IonCol key={index}>
                                                <IonInput 
                                                    type="text"
                                                    value={option.quantity.toString()}
                                                    onIonChange={e => {
                                                        if (e.detail.value && parseInt(e.detail.value!)) {
                                                            handleQuantityChange(index, option.eventStallType, parseInt(e.detail.value!))
                                                        } else {
                                                            handleQuantityChange(index, option.eventStallType, 0)
                                                        }
                                                    }}
                                                />
                                            </IonCol>
                                        ))}
                                    </>
                                )}
                                <IonCol>
                                    ${calculateTotal().toFixed(2)}
                                </IonCol>
                            </IonRow>
                        </>
                    }
                </>
            }
        </>
    );
};

export default EntryStablingFormRow;