import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonRow,
} from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";
import React, { useState } from "react";

interface _Props {
    title: string
    content: string
}

const DropDownCard: React.FC<_Props> = ({title, content}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    return (
        <>
            <IonRow className="ion-justify-content-center">
                <IonCol size="12" className="ion-no-padding">
                    <IonCard color="white" mode="md">
                        <IonCardTitle>
                            <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                                <IonCol size="10" className="ion-text-left">
                                    <h5>{title}</h5>
                                </IonCol>
                                <IonCol size="2" className="ion-text-right">
                                    <IonIcon icon={isCollapsed ? chevronUp : chevronDown} />
                                </IonCol>
                            </IonRow>
                        </IonCardTitle>
                        {!isCollapsed && (
                            <IonCardContent>
                                <p className="description">
                                    {content}
                                </p>
                            </IonCardContent>
                        )}
                    </IonCard>
                </IonCol>
            </IonRow>
        </>
    );
};

export default DropDownCard;
