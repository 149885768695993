import { Class, Division, Event, EventClass, EventClassEntry, Rider } from "../../models";
import { getEventClassesByEventId } from "../eventClass/EventClass";
import { sortEventClassesByNumber } from "../eventClass/SortEventClass";
import { getEventClassEntriesByEventClassId } from "../eventClassEntry/EventClassEntry";
import { getEventResultByEventIdClassIdEntryId } from "../eventResult/EventResult";
import { ecFields } from "../../data/content/ECResultsReportFields";
import { calculateJulianDate } from "../dates/Dates";
import { getClassById } from "../class/Class";
import { getDivisionById } from "../division/Division";
import { getMembershipsByHorseId, getMembershipsByOwnerId, getMembershipsByRiderId } from "../membership/Membership";
import { getRiderById } from "../rider/Rider";
import { getEventEntryById } from "../eventEntry/EventEntry";
import { getECHorseById } from "../ECAPI/ECHorse";
import { ECHorse } from "../../interfaces/ECData";
import moment from "moment";
import { downloadCSVFile } from "./ReportDownloadCSV";
require('jspdf-autotable');

async function getECClassId(eventClass: EventClass) {
    if (eventClass.class?.ecNumber) return eventClass.class?.ecNumber;
    if (eventClass.organizationDivision) {
        const divisionId = eventClass.organizationDivision.divisionId;
        const queryResult = await getDivisionById(divisionId);
        if (queryResult.isSuccess) {
            const division: Division = queryResult.result;
            return division.ecNumber;
        }
    } 
    if (eventClass.organizationClass) {
        const classId = eventClass.organizationClass.classId;
        const queryResult = await getClassById(classId);
        if (queryResult.isSuccess) {
            const c: Class = queryResult.result;
            return c.ecNumber;
        }
    }
}

async function getECClassTypeId(eventClass: EventClass) {
    if (eventClass.class?.ecClassTypeNumber) return eventClass.class?.ecClassTypeNumber;
    if (eventClass.organizationDivision) {
        const divisionId = eventClass.organizationDivision.divisionId;
        const queryResult = await getDivisionById(divisionId);
        if (queryResult.isSuccess) {
            const division: Division = queryResult.result;
            return division.ecClassTypeNumber;
        }
    } 
    if (eventClass.organizationClass) {
        const classId = eventClass.organizationClass.classId;
        const queryResult = await getClassById(classId);
        if (queryResult.isSuccess) {
            const c: Class = queryResult.result;
            return c.ecClassTypeNumber;
        }
    }
}

async function getECClassType(eventClass: EventClass) {
    if (eventClass.class?.ecClassType) return eventClass.class?.ecClassType;
    if (eventClass.organizationDivision) {
        const divisionId = eventClass.organizationDivision.divisionId;
        const queryResult = await getDivisionById(divisionId);
        if (queryResult.isSuccess) {
            const division: Division = queryResult.result;
            return division.ecClassType;
        }
    } 
    if (eventClass.organizationClass) {
        const classId = eventClass.organizationClass.classId;
        const queryResult = await getClassById(classId);
        if (queryResult.isSuccess) {
            const c: Class = queryResult.result;
            return c.ecClassType;
        }
    }
}

async function getECNumberForRider(rider: Rider) {
    const queryResult = await getMembershipsByRiderId(rider.id);
    if (queryResult.isSuccess) {
        const memberships = queryResult.result;
        if (memberships) {
            for (let i = 0; i < memberships.length; i++) {
                const membership = memberships[i];
                if (membership?.name === "EC" || membership?.name === "EC Rider") {
                    return membership.membershipId;
                }
            }
        }
    }
}

async function getECNumberForOwner(ownerId: string) {
    const queryResult = await getMembershipsByOwnerId(ownerId);
    if (queryResult.isSuccess) {
        const memberships = queryResult.result;
        if (memberships) {
            for (let i = 0; i < memberships.length; i++) {
                const membership = memberships[i];
                if (membership?.name === "EC" || membership?.name === "EC Owner") {
                    return membership.membershipId;
                }
            }
        }
    }
}

async function getECNumberForHorse(horseId: string) {
    const queryResult = await getMembershipsByHorseId(horseId);
    if (queryResult.isSuccess) {
        const memberships = queryResult.result;
        if (memberships) {
            for (let i = 0; i < memberships.length; i++) {
                const membership = memberships[i];
                if (membership?.name === "EC" || membership?.name === "EC Horse") {
                    return membership.membershipId;
                }
            }
        }
    }
}

async function getECLifetimeNumberForHorse(horseId: string) {
    const queryResult = await getECHorseById(horseId);
    if (queryResult.isSuccess) {
        const ecData: ECHorse = queryResult.result;
        if (ecData) {
            return ecData.EC_LifeTimeNumber;
        }
    }
}

export async function generateECResultsReportDataRows(event: Event) {

    let rows = [];

    rows.push(headerRow);
    
    const queryResult = await getEventClassesByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventClasses = queryResult.result as EventClass[];
        const sorted = sortEventClassesByNumber(eventClasses);
        if (sorted && sorted.length > 0) {
            for (let i = 0; i < sorted.length; i++) {
                const eventClass = sorted[i];
                const entryResult = await getEventClassEntriesByEventClassId(eventClass.id);
                if (entryResult.isSuccess) {
                    const eventClassEntries: EventClassEntry[] = entryResult.result;
                    const entryCount = eventClassEntries.length;

                    for (let j = 0; j < eventClassEntries.length; j++) {
                        const eventClassEntry = eventClassEntries[j];
                        const entryId = eventClassEntry.eventEntry?.id;
                        if (entryId) {
                            const entryResult = await getEventEntryById(entryId);
                            if (entryResult.isSuccess) {
                                const entry = entryResult.result;
                                let rider = entry.rider;
                                let riderName = entry.riderName;
                                if (eventClassEntry.riderId) {
                                    const riderResult = await getRiderById(eventClassEntry.riderId);
                                    if (riderResult.isSuccess) {
                                        const selectedRider: Rider = riderResult.result;
                                        if (selectedRider) rider = selectedRider;
                                        if (selectedRider.name) riderName = selectedRider.name;
                                    }
                                }
                                let place = 0;
                                let prizeMoney = 0;
                                const dataResult = await getEventResultByEventIdClassIdEntryId(event.id, eventClass.id, entry.id);
                                if (dataResult.isSuccess) {
                                    place = dataResult.result.place;
                                    prizeMoney = dataResult.result.prizeMoney;
                                };
                                let currentRow: any[] = [];
                                let horsePassportNumber = "";
                                for (let j = 0; j < ecFields.length; j++) {
                                    const field = ecFields[j];
                                    if (field.name === "ShowNumber") {
                                        currentRow.push(event.ecID);
                                    } else if (field.name === "ShowJulian") {
                                        let date = "";
                                        if (event.startDate) date = calculateJulianDate(event.startDate);
                                        currentRow.push(date);
                                    } else if (field.name === "DisciplineCode") {
                                        currentRow.push("HJ");
                                    } else if (field.name === "ClassID") {
                                        const classId = await getECClassId(eventClass);
                                        currentRow.push(classId);
                                    } else if (field.name === "Category") {
                                        currentRow.push(" ");
                                    } else if (field.name === "ClassTypeID") {
                                        const classTypeId = await getECClassTypeId(eventClass);
                                        currentRow.push(classTypeId);
                                    } else if (field.name === "ClassType") {
                                        const classTypeId = await getECClassType(eventClass);
                                        currentRow.push(classTypeId);
                                    } else if (field.name === "SanctionLevel") {
                                        currentRow.push("Silver");
                                    } else if (field.name === "SanctionLevelID") {
                                        currentRow.push(3);
                                    } else if (field.name === "Province") {
                                        currentRow.push("ON");
                                    } else if (field.name === "Year") {
                                        currentRow.push(2021);
                                    } else if (field.name === "ShowInstanceName") {
                                        currentRow.push(event.name);
                                    } else if (field.name === "ShowInstanceStartDate") {
                                        currentRow.push(moment(event.startDate).format("MM/DD/YYYY"));
                                    } else if (field.name === "ClientShowInstanceClassName") {
                                        currentRow.push(eventClass.name);
                                    } else if (field.name === "ClientShowInstanceClassNumber") {
                                        currentRow.push(eventClass.number);
                                    } else if (field.name === "ClassDate") {
                                        currentRow.push(" ");
                                    } else if (field.name === "HeadEntryNumber") {
                                        currentRow.push(entry.number);
                                    } else if (field.name === "FirstName") {
                                        currentRow.push(riderName?.split(" ")[0]);
                                    } else if (field.name === "LastName") {
                                        currentRow.push(riderName?.split(" ")[1]);
                                    } else if (field.name === "FullName") {
                                        currentRow.push(riderName);
                                    } else if (field.name === "SportLicenseNumber") {
                                        if (rider) {
                                            const ecNumber = await getECNumberForRider(rider);
                                            currentRow.push(ecNumber);
                                        } else {
                                            currentRow.push(" ");
                                        }
                                    } else if (field.name === "MemberNumber") {
                                        currentRow.push(" ");
                                    } else if (field.name === "HorseName") {
                                        currentRow.push(entry.horseName);
                                    } else if (field.name === "PassportNumber") {
                                        if (entry.horseId) {
                                            const ecNumber = await getECNumberForHorse(entry.horseId);
                                            horsePassportNumber = ecNumber;
                                            currentRow.push(ecNumber);
                                        } else {
                                            currentRow.push(" ");
                                        }
                                    } else if (field.name === "HorseLifetimeNumber") {
                                        if (horsePassportNumber) {
                                            const ecLifetimeNumber = await getECLifetimeNumberForHorse(horsePassportNumber);
                                            currentRow.push(ecLifetimeNumber);
                                        } else {
                                            currentRow.push(" ");
                                        }
                                    } else if (field.name === "Owners") {
                                        currentRow.push(entry.ownerName);
                                    } else if (field.name === "OwnersSLN") {
                                        if (entry.ownerId) {
                                            const ecNumber = await getECNumberForOwner(entry.ownerId);
                                            currentRow.push(ecNumber);
                                        } else {
                                            currentRow.push(" ");
                                        }
                                    } else if (field.name === "Starts") {
                                        currentRow.push(entryCount);
                                    } else if (field.name === "Entries") {
                                        currentRow.push(entryCount);
                                    } else if (field.name === "StatusID") {
                                        currentRow.push(1);
                                    } else if (field.name === "StatusCode") {
                                        currentRow.push("C");
                                    } else if (field.name === "Placing") {
                                        currentRow.push(place);
                                    } else if (field.name === "PrizeMoneyWon") {
                                        currentRow.push(prizeMoney.toFixed(2));
                                    } else if (field.name === "IsJunior") {
                                        currentRow.push(0);
                                    } else if (field.name === "IsYoungRider") {
                                        currentRow.push(0);
                                    } else if (field.name === "EV_CrossBFallHorse") {
                                        currentRow.push(0);
                                    } else if (field.name === "EV_CrossBFallRider") {
                                        currentRow.push(0);
                                    } else if (field.name === "EV_CrossDFallHorse") {
                                        currentRow.push(0);
                                    } else if (field.name === "EV_CrossDFallRider") {
                                        currentRow.push(0);
                                    } else if (field.name === "EV_JumpingFallHorse") {
                                        currentRow.push(0);
                                    } else if (field.name === "EV_JumpingFallRider") {
                                        currentRow.push(0);
                                    } else if (field.name === "EV_QualificationResult") {
                                        currentRow.push(0);
                                    } else if (field.name === "ClassDescription") {
                                        currentRow.push(1);
                                    } else if (field.name === "LangID") {
                                        currentRow.push(1);
                                    } else if (field.name === "ClientShowInstanceClassID") {
                                        currentRow.push(1);
                                    } else if (field.name === "ClientShowInstanceID") {
                                        currentRow.push(1);
                                    } else if (field.name === "EV_Placing_After_DR") {
                                        currentRow.push(1);
                                    } else if (field.name === "EV_Placing_After_CC") {
                                        currentRow.push(1);
                                    } else {
                                        currentRow.push(" ");
                                    }
                                }
                                rows.push(currentRow);
                            }
                        }
                    }
                }
            }
        }
    }

    const fileName = `${event.ecID}_ringsidepro.txt`;
    downloadCSVFile(fileName, rows, "|");
}

export const headerRow = [
    "ShowNumber",
    "ShowJulian",
    "DisciplineCode",
    "ClassID",
    "Category",
    "ClassTypeID",
    "ClassType",
    "SanctionLevel",
    "SanctionLevelID",
    "Province",
    "Year",
    "ShowInstanceName",
    "ShowInstanceStartDate",
    "ClientShowInstanceClassName",
    "ClientShowInstanceClassNumber",
    "ClassDate",
    "HeadEntryNumber",
    "FirstName",
    "LastName",
    "FullName",
    "SportLicenseNumber",
    "MemberNumber",
    "HorseName",
    "PassportNumber",
    "HorseLifetimeNumber",
    "Owners",
    "OwnersSLN",
    "Starts",
    "Entries",
    "StatusID",
    "StatusCode",
    "Placing",
    "PrizeMoneyWon",
    "DR_TotalGoodScore",
    "DR_TotalTechnicalScore",
    "DR_TotalArtisticScore",
    "DR_JudgeE_Tech",
    "DR_JudgeE_Tech_Percent",
    "DR_JudgeE_Art",
    "DR_JudgeE_Total",
    "DR_JudgeH_Tech",
    "DR_JudgeH_Tech_Percent",
    "DR_JudgeH_Art",
    "DR_JudgeH_Total",
    "DR_JudgeC_Tech",
    "DR_JudgeC_Tech_Percent",
    "DR_JudgeC_Art",
    "DR_JudgeC_Total",
    "DR_JudgeM_Tech",
    "DR_JudgeM_Tech_Percent",
    "DR_JudgeM_Art",
    "DR_JudgeM_Total",
    "DR_JudgeB_Tech",
    "DR_JudgeB_Tech_Percent",
    "DR_JudgeB_Art",
    "DR_JudgeB_Total",
    "DR_Percentage",
    "DR_TotalPoints",
    "IsJunior",
    "IsYoungRider",
    "EV_FirstHorseInspection",
    "EV_DR_ClassID",
    "EV_DressageTest",
    "EV_DressageMaxGoodMarks",
    "EV_DressageGoodMarks",
    "EV_DressagePercentage",
    "EV_DressageTotalPenalties",
    "EV_DressageStatus",
    "EV_CrossATimePenal",
    "EV_CrossAStatus",
    "EV_CrossBJumpPenal",
    "EV_CrossBTimePenal",
    "EV_CrossBFallHorse",
    "EV_CrossBFallRider",
    "EV_CrossBStatus",
    "EV_CrossCTimePenal",
    "EV_CrossCStatus",
    "EV_SecondHorseInspection",
    "EV_CrossDJumpPenal",
    "EV_CrossDTimePenal",
    "EV_CrossDFallHorse",
    "EV_CrossDFallRider",
    "EV_CrossDStatus",
    "EV_CrossTotalPenalties",
    "EV_ThirdHorseInspection",
    "EV_JumpingJumpPenal",
    "EV_JumpingTimePenal",
    "EV_JumpingFallHorse",
    "EV_JumpingFallRider",
    "EV_JumpingTotalPenalties",
    "EV_JumpingStatus",
    "EV_QualificationResult",
    "EV_TotalPenalties",
    "JU_FirstRoundFaults",
    "JU_FirstRoundTime",
    "JU_SecondRoundFaults",
    "JU_SecondRoundTime",
    "JU_JumpOffFaults",
    "JU_JumpOffTime",
    "ClassDescription",
    "LangID",
    "ClientShowInstanceClassID",
    "ClientShowInstanceID",
    "EV_Placing_After_DR",
    "EV_Placing_After_CC",
    "EV_Dressage_Error",
    "Association",
    "AssociationNumber",
    "PSOProvince",
    "PSONumber",
    "RiderFederation",
    "HorseFederation",
    "OwnerFederation",
    "ReasonHorseAdd",
    "ReasonRiderAdd",
    "ReasonOwnerAdd"
];