import {
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonSearchbar,
    isPlatform,
} from "@ionic/react";
import moment from "moment";
import React, {useEffect, useState} from "react";
import { Table } from "reactstrap";
import { EventFilter } from "../../../API";
import { Event, EventAuditor, EventAuditorFilter } from "../../../models";
import { getEventAuditorsWithFilterByEventId } from "../../../utilities/eventAuditor/EventAuditor";
import { sortEventAuditorsByName } from "../../../utilities/eventAuditor/SortEventAuditors";
import { getEventAuditorFiltersByEventIdAuditorId } from "../../../utilities/eventAuditorFilter/EventAuditorFilter";
import { getEventFiltersByEventId } from "../../../utilities/eventFilter/EventFilter";
import { isWindows } from "../../../utilities/platform/Platform";
import ErrorAlert from "../../Errors/ErrorAlert";
import EventAuditorModal from "../../EventAuditor/EventAuditorModal";
import Spinner from "../../Spinners/Spinner";

interface _Props {
    event: Event
    eventAuditor?: EventAuditor
}

const EditEventAuditorsFullTable: React.FC<_Props> = ({event, eventAuditor}) => {    

    const [eventAuditors, setEventAuditors] = useState<EventAuditor[] | null | undefined>();
    const [filteredEventAuditors, setFilteredEventAuditors] = useState<EventAuditor[] | null | undefined>();
    const [searchText, setSearchText] = useState('');
    const [selectedEventAuditor, setSelectedEventAuditor] = useState<EventAuditor | null | undefined>();
    const [eventFilters, setEventFilters] = useState<EventFilter[] | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    async function getEventAuditors(event: Event, eventFilters: EventFilter[]) {
        setIsLoading(true);
        const queryResult = await getEventAuditorsWithFilterByEventId(event.id);
        console.log("Result: ", queryResult);
        if (queryResult.isSuccess) {
            let eventAuditors: EventAuditor[] = sortEventAuditorsByName(queryResult.result) || queryResult.result;
            let updatedAuditors: EventAuditor[] = [];
            if (eventAuditors && eventAuditors.length > 0) {
                for (let i = 0; i < eventAuditors.length; i++) {
                    const currentEventAuditor = eventAuditors[i];
                    const filterResult: any = currentEventAuditor.filters;
                    const filters = filterResult?.items;
                    if (eventFilters) {
                        if (filters && filters.length) {
                            let sorted: (EventAuditorFilter | null)[] | null = [];
                            for (let j = 0; j < eventFilters.length; j++) {
                                const eventFilter = eventFilters[j];
                                let found = false;
                                for (let k = 0; k < filters.length; k++) {
                                    const currentFilter = filters[k];
                                    if (currentFilter && currentFilter.filterId === eventFilter.id) {
                                        sorted.push(currentFilter);
                                        found = true;
                                        break;
                                    } 
                                }
                                if (!found) sorted.push(null);
                            }
                            const updatedEventAuditor = {
                                ...currentEventAuditor,
                                filters: sorted
                            };
                            updatedAuditors.push(updatedEventAuditor);
                        } else {
                            updatedAuditors.push(currentEventAuditor);
                        }
                    }  else {
                        updatedAuditors.push(currentEventAuditor);
                    }
                }
            }
            setEventAuditors(updatedAuditors);
            setFilteredEventAuditors(updatedAuditors);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    async function getEventFilters(event: Event) {
        const queryResult = await getEventFiltersByEventId(event.id);
        if (queryResult.isSuccess) {
            const eventFilters: EventFilter[] = queryResult.result;
            const sorted = eventFilters.sort((a, b) => (a.name || "").localeCompare((b.name || "")));
            setEventFilters(sorted);
            getEventAuditors(event, sorted);
        }
    }

    useEffect(() => {
        if (event) {
            getEventFilters(event);
        }
    }, [event]);

    useEffect(() => {
        if (eventAuditor) {
            if (eventAuditors) {
                const updated = eventAuditors.concat(eventAuditor);
                const sorted = updated.sort((a, b) => (a.name || "").localeCompare((b.name || "")));
                setEventAuditors(sorted);
            } else {
                setEventAuditors([eventAuditor]);
            }
        }
    }, [eventAuditor]);

    const handleSelectedSubscriber = (eventAuditor: EventAuditor) => {
        setSelectedEventAuditor(eventAuditor);
        setShowModal(true);
    }

    const handleEventAuditorChange = async (action: string, eventAuditor: EventAuditor) => {
        setIsLoading(true);
        setShowModal(false);
        let updatedEventAuditorArray: EventAuditor[] = [eventAuditor];
        let updatedFilteredEventAuditorArray: EventAuditor[] = [eventAuditor];
        if (action === "create") {
            if (eventAuditors) updatedEventAuditorArray = updatedEventAuditorArray.concat(eventAuditors);
            if (filteredEventAuditors) updatedFilteredEventAuditorArray = updatedFilteredEventAuditorArray.concat(filteredEventAuditors);
        } else if (action === "update") {
            if (eventAuditors) {
                let updatedEventAuditor: EventAuditor = eventAuditor;
                if (eventFilters) {
                    const filterQueryResult = await getEventAuditorFiltersByEventIdAuditorId(eventAuditor.id);
                    if (filterQueryResult.isSuccess) {
                        const filters: EventAuditorFilter[] = filterQueryResult.result;
                        let sorted: EventAuditorFilter[] = [];
                        for (let j = 0; j < eventFilters.length; j++) {
                            const eventFilter = eventFilters[j];
                            const findResult = filters.find(eventAuditorFilter => eventAuditorFilter.filterId === eventFilter.id);
                            if (findResult) {
                                sorted.push(findResult);
                            } else {
                                const blankEventAuditorFilter: EventAuditorFilter = {
                                    id: "1",
                                    options: [],
                                    createdOn: moment(new Date()).format("MM/DD/YY"),
                                    updatedOn: moment(new Date()).format("MM/DD/YY")
                                };
                                sorted.push(blankEventAuditorFilter);
                            }
                        }
                        updatedEventAuditor = {
                            ...eventAuditor,
                            filters: sorted
                        };
                        
                    }
                }
                const index = eventAuditors.findIndex(auditor => auditor.id === eventAuditor.id);
                updatedEventAuditorArray = [
                    ...eventAuditors.slice(0, index),
                    updatedEventAuditor,
                    ...eventAuditors.slice(index + 1)
                ];
                if (filteredEventAuditors) {
                    const filteredIndex = filteredEventAuditors.findIndex(auditor => auditor.id === eventAuditor.id);
                    updatedFilteredEventAuditorArray = [
                        ...filteredEventAuditors.slice(0, filteredIndex),
                        updatedEventAuditor,
                        ...filteredEventAuditors.slice(filteredIndex + 1)
                    ];
                }
            } 
        } else if (action === "delete") {
            if (eventAuditors) {
                const deletedIndex = eventAuditors.findIndex(eA => eventAuditor.id === eA.id);
                updatedEventAuditorArray = [
                    ...eventAuditors.slice(0, deletedIndex),
                    ...eventAuditors.slice(deletedIndex + 1)
                ];
                if (filteredEventAuditors) {
                    const filteredIndex = filteredEventAuditors.findIndex(eA => eA.id === eventAuditor.id);
                    updatedFilteredEventAuditorArray = [
                        ...filteredEventAuditors.slice(0, filteredIndex),
                        ...filteredEventAuditors.slice(filteredIndex + 1)
                    ];
                }
            }
        }
        setEventAuditors(updatedEventAuditorArray);
        setFilteredEventAuditors(updatedFilteredEventAuditorArray);
        setIsLoading(false);
    }

    const checkIfEventAuditorIsDuplicate = (current: EventAuditor) => {
        if (eventAuditors) {
            const foundArray = eventAuditors.filter((ea: EventAuditor) => ea.phoneNumber === current.phoneNumber);
            if (foundArray && foundArray.length > 1) return true;
        }
        return false;
    }

    const filterEventAuditors = (value: string) => {
        const nameResult = eventAuditors ? eventAuditors.filter(ea => (ea.name ? ea.name : "").toLocaleLowerCase().toString().includes(value.toLocaleLowerCase().toString())) : [];
        const phoneNumberResult = eventAuditors ? eventAuditors.filter(ea => (ea.phoneNumber ? ea.phoneNumber : "").toString().includes(value.toString())) : [];
        const result = nameResult.concat(phoneNumberResult);
        setFilteredEventAuditors(result);
    }

    const handleSearchInput = async (value: string) => {
        setSearchText(value);
        if (value) {
            filterEventAuditors(value);
        } else {
            setFilteredEventAuditors(eventAuditors);
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            <IonSearchbar value={searchText} placeholder="Type Name or Phone #" onIonChange={e => handleSearchInput(e.detail.value!)} color="white"></IonSearchbar>
            {isLoading ?
                <Spinner />
                :
                <>
                    {filteredEventAuditors ? 
                        <>
                            {(isWindows() || (isPlatform("desktop"))) ?
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Phone Number</th>
                                            <th>Status</th>
                                            <th>Last Updated</th>
                                            {eventFilters?.map((eventFilter, index) => (
                                                <th key={index}>{eventFilter?.name}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredEventAuditors.map((eventAuditor, index) => (
                                            <tr key={index} className={checkIfEventAuditorIsDuplicate(eventAuditor) ? "bg-warning" : ""}  onClick={() => handleSelectedSubscriber(eventAuditor)}>
                                                <td>
                                                    {index+1}
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{eventAuditor.name}</p>
                                                </td>
                                                <td>
                                                    {eventAuditor.phoneNumber}
                                                </td>
                                                <td>
                                                    <span className={(eventAuditor.status === "paused" || eventAuditor.status === "stopped") ? "text-danger" : "text-primary"}>{eventAuditor.status === "paused" ? "Paused" : eventAuditor.status === "stopped" ? "Stopped" : "Active"}</span>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{eventAuditor.updatedOn ? moment(eventAuditor.updatedOn).format("MM/DD/YY h:mm a") : ""}</p>
                                                </td>
                                                {(eventAuditor.filters && eventAuditor.filters.length > 0) ? 
                                                    (eventAuditor.filters?.map((eventAuditorFilter, i) => (
                                                        <td key={i}>
                                                            {(eventAuditorFilter?.options && eventAuditorFilter.options.length > 0) ? 
                                                                <p>
                                                                    {eventAuditorFilter?.options?.map((option, j) => (
                                                                        <span key={j}>
                                                                            {option}{((j+1) < (eventAuditorFilter?.options?.length || 0)) ? ", " : ""}
                                                                        </span>
                                                                    ))}
                                                                </p>
                                                                :
                                                                <p>none</p>
                                                            }   
                                                        </td>
                                                    )))
                                                    :
                                                    <>
                                                        {eventFilters?.map((eventFilter, index) => (
                                                            <th key={index}>*none</th>
                                                        ))}
                                                    </>
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            :
                                <IonList className="bg-white">
                                    {filteredEventAuditors.map((eventAuditor, index) => (
                                        <IonItem key={index} onClick={() => handleSelectedSubscriber(eventAuditor)}>
                                            <IonLabel>
                                                <IonRow className={checkIfEventAuditorIsDuplicate(eventAuditor) ? "bg-warning" : ""}>
                                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-wrap font-weight-bold"><h2>{index+1 + ") " + eventAuditor.name}</h2></IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-wrap"><p>{eventAuditor.phoneNumber}</p></IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-wrap">
                                                        <p>
                                                            Status: <span className={(eventAuditor.status === "paused" || eventAuditor.status === "stopped") ? "text-danger" : "text-primary"}>{eventAuditor.status === "paused" ? "Paused" : eventAuditor.status === "stopped" ? "Stopped" : "Active"}</span>
                                                        </p>
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-wrap">
                                                        <p>
                                                            <p className="ion-text-wrap">Last Updated On: {eventAuditor.updatedOn ? moment(eventAuditor.updatedOn).format("MM/DD/YY h:mm a") : ""}</p>
                                                        </p>
                                                    </IonCol>
                                                    {(eventAuditor.filters && eventAuditor.filters.length > 0) ? (eventAuditor.filters?.map((eventAuditorFilter, i) => (
                                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-wrap" key={i}>
                                                            <p><span className="font-weight-bold">{eventAuditorFilter?.filter?.name}</span>: {eventAuditorFilter?.options?.toString()}</p>
                                                        </IonCol>
                                                    )))
                                                        :
                                                        <p></p>
                                                    }
                                                </IonRow>
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                </IonList>
                            }
                        </>
                        
                        :
                        <p>No subscribers yet.</p>
                    }
                </>
            }
            <EventAuditorModal show={showModal} eventAuditor={selectedEventAuditor} isAdminView={true} event={event} onChange={(action: string, eventAuditor: EventAuditor) => handleEventAuditorChange(action, eventAuditor)} />
        </>
    );
};

export default EditEventAuditorsFullTable;
