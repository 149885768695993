import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateSubmissionFeedbackInput, CreateSubmissionFeedbackMutation, DeleteSubmissionFeedbackInput, DeleteSubmissionFeedbackMutation, FeedbackStatus, GetSubmissionFeedbackQuery, ListSubmissionFeedbacksQuery, UpdateSubmissionFeedbackInput, UpdateSubmissionFeedbackMutation } from "../../API";
import moment from "moment";
import { SubmissionFeedback } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: submissionFeedback. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created submissionFeedback.
*/
export async function createSubmissionFeedback(input: CreateSubmissionFeedbackInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "SubmissionFeedback", "No input", "Create SubmissionFeedback received no input.");
   try {
        const fullInput: CreateSubmissionFeedbackInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createSubmissionFeedback, { input: fullInput }))) as GraphQLResult<CreateSubmissionFeedbackMutation>;
       else result = (await API.graphql({
           query: mutations.createSubmissionFeedback,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateSubmissionFeedbackMutation>;
       const submissionFeedback = result.data?.createSubmissionFeedback;
       return formatResult(true, "SubmissionFeedback", submissionFeedback, "Successfully created the submissionFeedback.");
   } catch (error: any) {
       return formatResult(false, "SubmissionFeedback", error, "Error creating record in the database for type: submissionFeedback");
   }
}

/**
* Handle updating a new record in the database for type: submissionFeedback. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated submissionFeedback.
*/
export async function updateSubmissionFeedback(input: UpdateSubmissionFeedbackInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "SubmissionFeedback", "No input", "Update SubmissionFeedback received no input.");
   try {
        const fullInput: UpdateSubmissionFeedbackInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateSubmissionFeedback, { input: fullInput }))) as GraphQLResult<UpdateSubmissionFeedbackMutation>;
       else result = (await API.graphql({
           query: mutations.updateSubmissionFeedback,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateSubmissionFeedbackMutation>;
       const submissionFeedback = result.data?.updateSubmissionFeedback;
       return formatResult(true, "SubmissionFeedback", submissionFeedback, "Successfully updated the submissionFeedback.");
   } catch (error: any) {
       return formatResult(false, "SubmissionFeedback", error, "Error updating record in the database for type: submissionFeedback");
   }
}

/**
* Handle deleting a new record in the database for type: submissionFeedback. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted submissionFeedback.
*/
export async function deleteSubmissionFeedback(input: DeleteSubmissionFeedbackInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "SubmissionFeedback", "No input", "Delete SubmissionFeedback received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteSubmissionFeedback, { input: input }))) as GraphQLResult<DeleteSubmissionFeedbackMutation>;
       else result = (await API.graphql({
           query: mutations.deleteSubmissionFeedback,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteSubmissionFeedbackMutation>;
       const submissionFeedback = result.data?.deleteSubmissionFeedback;
       return formatResult(true, "SubmissionFeedback", submissionFeedback, "Successfully deleted the submissionFeedback.");
   } catch (error: any) {
       return formatResult(false, "SubmissionFeedback", error, "Error deleting record in the database for type: submissionFeedback");
   }
}

/**
* Get all records in the database for type: submissionFeedback. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the submissionFeedback object
*/
export async function getAllSubmissionFeedbacks(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listSubmissionFeedbacks,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.listSubmissionFeedbacks?.items;
        let nextToken = result.data?.listSubmissionFeedbacks?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listSubmissionFeedbacks,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.listSubmissionFeedbacks?.items;
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listSubmissionFeedbacks?.nextToken;
        }

        return formatResult(true, "SubmissionFeedback", items, "Successfully got the SubmissionFeedbacks.");
    } catch (error: any) {
        return formatResult(false, "SubmissionFeedback", error, "Error reading record in the database for type: submissionFeedbacks");
    }
}

/**
* Read a specific record in the database for type: submissionFeedback. 
* 
* @param {string}  id                  The submissionFeedback id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the submissionFeedback object
*/
export async function getSubmissionFeedbackById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getSubmissionFeedback,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetSubmissionFeedbackQuery>;
       const submissionFeedback = result.data?.getSubmissionFeedback;
       return formatResult(true, "SubmissionFeedback", submissionFeedback, "Successfully got the submissionFeedback.");
   } catch (error: any) {
       return formatResult(false, "SubmissionFeedback", error, "Error reading record in the database for type: submissionFeedback");
   }
}

/**
* Get all records in the database that match the given criteria for type: submissionFeedback. 
* 
* @param {string}  personId            The person id of the "owner" of the submissionFeedback
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the submissionFeedback object
*/
export async function getSubmissionFeedbacksByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listSubmissionFeedbacks,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.listSubmissionFeedbacks?.items;
        let nextToken = result.data?.listSubmissionFeedbacks?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listSubmissionFeedbacks,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.listSubmissionFeedbacks?.items;
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listSubmissionFeedbacks?.nextToken;
        }

        return formatResult(true, "SubmissionFeedback", items, "Successfully got the SubmissionFeedbacks.");
    } catch (error: any) {
        return formatResult(false, "SubmissionFeedback", error, "Error reading record in the database for type: submissionFeedbacks");
    }
}

/**
* Get all records in the database that match the given criteria for type: submissionFeedback. 
* 
* @param {string}  submissionId        The submission id of the "owner" of the submissionFeedback
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the submissionFeedback object
*/
export async function getSubmissionFeedbacksBySubmissionId(submissionId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {submissionId: {eq: submissionId}};
        const result = (await API.graphql({
            query: queries.listSubmissionFeedbacks,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.listSubmissionFeedbacks?.items;
        let nextToken = result.data?.listSubmissionFeedbacks?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listSubmissionFeedbacks,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.listSubmissionFeedbacks?.items;
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listSubmissionFeedbacks?.nextToken;
        }

        return formatResult(true, "SubmissionFeedback", items, "Successfully got the SubmissionFeedbacks.");
    } catch (error: any) {
        return formatResult(false, "SubmissionFeedback", error, "Error reading record in the database for type: submissionFeedbacks");
    }
}

/**
* Get all records in the database that match the given criteria for type: submissionFeedback. 
* 
* @param {string}  clinicianId         The clinician id of the submissionFeedback
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the submissionFeedback object
*/
export async function getSubmissionFeedbacksByClinicianId(clinicianId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {clinicianId: {eq: clinicianId}};
        const result = (await API.graphql({
            query: queries.listSubmissionFeedbacks,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.listSubmissionFeedbacks?.items;
        let nextToken = result.data?.listSubmissionFeedbacks?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listSubmissionFeedbacks,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.listSubmissionFeedbacks?.items;
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listSubmissionFeedbacks?.nextToken;
        }

        return formatResult(true, "SubmissionFeedback", items, "Successfully got the SubmissionFeedbacks.");
    } catch (error: any) {
        return formatResult(false, "SubmissionFeedback", error, "Error reading record in the database for type: submissionFeedbacks");
    }
}

/**
* Get all records in the database that match the given criteria for type: submissionFeedback. 
* 
* @param {string}  clinicianId         The clinician id of the submissionFeedback
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the submissionFeedback object
*/
export async function getCompleteSubmissionFeedbacksByClinicianId(clinicianId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.submissionFeedbackByClinicianByStatus,
            variables: {
                limit: 100,
                clinicianId: clinicianId,
                feedbackStatus: {eq: "complete"}
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.submissionFeedbackByClinicianByStatus?.items as SubmissionFeedback[];
        let nextToken = result.data?.submissionFeedbackByClinicianByStatus?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.submissionFeedbackByClinicianByStatus,
                variables: {
                    limit: 100,
                    clinicianId: clinicianId,
                    feedbackStatus: {eq: "complete"},
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.submissionFeedbackByClinicianByStatus?.items as SubmissionFeedback[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.paymentsByClinicianByDate?.nextToken;
        }

        if (items && items.length > 0) {
            return formatResult(true, "SubmissionFeedback", items, "Successfully got the submissionFeedbacks.");
        } else {
            return formatResult(false, "SubmissionFeedback", null, "Found no submissionFeedbacks.");
        }
    } catch (error: any) {
        return formatResult(false, "SubmissionFeedback", error, "Error reading record in the database for type: submissionFeedbacks");
    }
}

/**
* Get all records in the database that match the given criteria for type: submissionFeedback. 
* 
* @param {string}  clinicianId         The clinician id of the submissionFeedback
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the submissionFeedback object
*/
export async function getPaymentsByClinicianId(clinicianId: string, date: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.paymentsByClinicianByDate,
            variables: {
                limit: 100,
                feedbackStatus: FeedbackStatus.complete, 
                clinicianIdDateCompleted: {
                    beginsWith: {
                        dateCompleted: date, 
                        clinicianId: clinicianId
                    }
                }
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.paymentsByClinicianByDate?.items as SubmissionFeedback[];
        let nextToken = result.data?.paymentsByClinicianByDate?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.paymentsByClinicianByDate,
                variables: {
                    limit: 100,
                    feedbackStatus: FeedbackStatus.complete, 
                    clinicianIdDateCompleted: {
                        beginsWith: {
                            dateCompleted: date, 
                            clinicianId: clinicianId
                        }
                    },
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.paymentsByClinicianByDate?.items as SubmissionFeedback[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.paymentsByClinicianByDate?.nextToken;
        }

        if (items && items.length > 0) {
            return formatResult(true, "SubmissionFeedback", items, "Successfully got the submissionFeedbacks.");
        } else {
            return formatResult(false, "SubmissionFeedback", null, "Found no submissionFeedbacks.");
        }
    } catch (error: any) {
        return formatResult(false, "SubmissionFeedback", error, "Error reading record in the database for type: submissionFeedbacks");
    }
}

/**
* Get all records in the database that match the given criteria for type: submissionFeedback. 
* 
* @param {string}  clinicianId         The clinician id of the submissionFeedback
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the submissionFeedback object
*/
export async function getAnnualTotalPaymentsByClinicianId(clinicianId: string, year: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const beginningDate = year + "-01-01";
        const endDate = year + "-12-31";
        const result = (await API.graphql({
            query: queries.paymentsByClinicianByDate,
            variables: {
                limit: 100,
                feedbackStatus: FeedbackStatus.complete, 
                clinicianIdDateCompleted: {
                    between: [
                        {
                            dateCompleted: beginningDate, 
                            clinicianId: clinicianId
                        },
                        {
                            dateCompleted: endDate, 
                            clinicianId: clinicianId
                        }
                    ]
                }
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.paymentsByClinicianByDate?.items as SubmissionFeedback[];
        let nextToken = result.data?.paymentsByClinicianByDate?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.paymentsByClinicianByDate,
                variables: {
                    limit: 100,
                    feedbackStatus: FeedbackStatus.complete, 
                    clinicianIdDateCompleted: {
                        between: [
                            {
                                dateCompleted: beginningDate, 
                                clinicianId: clinicianId
                            },
                            {
                                dateCompleted: endDate, 
                                clinicianId: clinicianId
                            }
                        ]
                    },
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.paymentsByClinicianByDate?.items as SubmissionFeedback[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.paymentsByClinicianByDate?.nextToken;
        }

        if (items && items.length > 0) {
            return formatResult(true, "SubmissionFeedback", items, "Successfully got the submissionFeedbacks.");
        } else {
            return formatResult(false, "SubmissionFeedback", null, "Found no submissionFeedbacks.");
        }
    } catch (error: any) {
        return formatResult(false, "SubmissionFeedback", error, "Error reading record in the database for type: submissionFeedbacks");
    }
}