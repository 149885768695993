import {
    IonChip,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPopover,
    IonRow,
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {cartSharp} from "ionicons/icons";
import { CartItem } from "../../interfaces/Cart";

interface _Props {
    items?: (CartItem[] | null)
}

const CartChip: React.FC<_Props> = ({items}) => {

    const [popoverState, setShowPopover] = useState({showPopover: false, event: undefined});
    const [totalBasePrice, setTotalBasePrice] = useState(0);

    useEffect(() => {
        if (items && items.length > 0) {
            let total = 0;
            items.forEach(item => {
                total = total + (item.basePrice * item.quantity);
            });
            setTotalBasePrice(total);
        }
    }, [items]);

    return (
        <div className="ion-float-right">
            <div>
                <IonChip
                    outline={true}
                    onClick={
                        (e: any) => {
                            e.persist();
                            setShowPopover({showPopover: true, event: e});
                        }
                    }
                >
                    <IonIcon color="primary" icon={cartSharp} className="ion-no-margin" /><p className="ml-2">{(items && items.length) ? items.length : ""}</p>
                </IonChip>
            </div>
            <IonPopover
                event={popoverState.event}
                isOpen={popoverState.showPopover}
                onDidDismiss={() => setShowPopover({showPopover: false, event: undefined})}
            > 
                <div className="card-min-height">
                    <IonContent>
                        <IonList lines="full">
                            <IonListHeader>
                                <IonRow className="ion-align-items-center">
                                    <IonCol size="12">
                                        <h3>Cart</h3>
                                    </IonCol>
                                </IonRow>
                            </IonListHeader>
                            {items ? 
                                <>
                                    {items.map((item, index) => (
                                        <IonItem key={index}>
                                            <IonLabel>
                                                <IonRow>
                                                    <IonCol>
                                                        <h2>{item.label}</h2>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <h3>${item.basePrice.toFixed(2)}</h3>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <h3>quantity: {item.quantity}</h3>
                                                    </IonCol>
                                                </IonRow>
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                </>
                                :
                                <IonItem>
                                    <p>Your cart is empty.</p>
                                </IonItem>
                            }
                            <IonItem>
                                <h6>Total: ${totalBasePrice.toFixed(2)}</h6>
                            </IonItem>
                        </IonList>
                    </IonContent>
                </div>
            </IonPopover>
        </div>
    );
};

export default CartChip;
