import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonText,
} from "@ionic/react";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import React, { useEffect, useState } from "react";
import { Clinician, PaymentMethod, SubmissionFeedback } from "../../../../../models";
import Spinner from "../../../../../components/Spinners/Spinner";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import { getAllClinicians } from "../../../../../utilities/clinician/Clinician";
import { Input } from "reactstrap";
import { getAnnualTotalPaymentsByClinicianId, getPaymentsByClinicianId } from "../../../../../utilities/submissionFeedback/SubmissionFeedback";
import { getPersonByPersonId } from "../../../../../utilities/person/Person";
import moment from "moment";
import { Result } from "../../../../../interfaces/Result";

interface formattedPayment {
    payer: string
    amount: string
    date: string
}

interface formattedClinician {
    clinician: String 
    total: number
    paymentMethod?: (PaymentMethod | null)
    payments: formattedPayment[]
}

const ViewPaymentsPage: React.FC = () => {    
    const [isLoading, setIsLoading] = useState(false);
    const [clinicians, setClincians] = useState<Clinician[] | null | undefined>();
    const [formattedClinicians, setFormattedClinicians] = useState<formattedClinician[] | null | undefined>();
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());

    const getPaymentsTotal = async (submissionFeedback: SubmissionFeedback[]): Promise<number> => {
        let total = 0;
        if (submissionFeedback && submissionFeedback.length > 0) {
            for (let i = 0; i < submissionFeedback.length; i++) {
                total = total + (submissionFeedback[i]?.clinicianAmount || 0);
            }
        }
        return total;
    }

    const formatPayments = async (submissionFeedback: SubmissionFeedback[]): Promise<formattedPayment[]> => {
        let list = [];
        if (submissionFeedback && submissionFeedback.length > 0) {
            for (let i = 0; i < submissionFeedback.length; i++) {
                const sf = submissionFeedback[i];
                let personName = "";
                if (sf.personId) {
                    const personResult = await getPersonByPersonId(sf.personId);
                    if (personResult.isSuccess) {
                        const person = personResult.result;
                        personName = person.firstName + " " + person.lastName;
                    }
                }
                
                const formatted: formattedPayment = {
                    payer: personName,
                    amount: (sf?.clinicianAmount || 0).toString(),
                    date: moment(sf.dateCompleted).format("MMM DD, YYYY") || ""
                }

                list.push(formatted);
            }
        }
        return list;
    }

    const getPayments = async (clinicians: Clinician[], monthVal?: number, yearVal?: number) => {
        setIsLoading(true);
        let clinicianList: formattedClinician[] = [];
        for (let i = 0; i < clinicians.length; i++) {
            const clinician = clinicians[i];

            let queryResult: Result | null = null;

            // Check if the current selected option is the yearly total
            if (monthVal === 13) {
                queryResult = await getAnnualTotalPaymentsByClinicianId(clinician.id, (yearVal ? yearVal : (new Date().getFullYear())).toString());
            } 
            // Otherwise, get the payment amount for that month
            else {
                let formattedMonth = (monthVal || month).toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })
                const date = (yearVal ? yearVal : year.toString()) + "-" + formattedMonth.toString();
                queryResult = await getPaymentsByClinicianId(clinician.id, date);
            }

            let paymentList: formattedPayment[] = [];
            let total = 0;
            if (queryResult && queryResult.isSuccess) {
                const payments = queryResult.result;
                paymentList = await formatPayments(payments)
                total = await getPaymentsTotal(queryResult.result);
            }
            
            const formattedClinician: formattedClinician = {
                clinician: clinician.name,
                total: total,
                paymentMethod: clinician.paymentChoice,
                payments: paymentList
            };

            clinicianList.push(formattedClinician);
        }
        setFormattedClinicians(clinicianList);
        setIsLoading(false);
    }

    async function getClinicians() {
        const queryResult = await getAllClinicians();
        if (queryResult.isSuccess) {
            setClincians(queryResult.result);
            getPayments(queryResult.result);
        }
    }

    useEffect(() => {
        getClinicians();
    }, []);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Payments" />
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Payments</IonCardTitle>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol>
                                        <Input 
                                            name="month" 
                                            type="select" 
                                            value={month} 
                                            onChange={e => {
                                                setMonth(parseInt(e.target.value));
                                                if (clinicians) getPayments(clinicians, parseInt(e.target.value), year);
                                            }}
                                        >
                                                <option value={1}>Jan</option>
                                                <option value={2}>Feb</option>
                                                <option value={3}>Mar</option>
                                                <option value={4}>Apr</option>
                                                <option value={5}>May</option>
                                                <option value={6}>Jun</option>
                                                <option value={7}>Jul</option>
                                                <option value={8}>Aug</option>
                                                <option value={9}>Sept</option>
                                                <option value={10}>Oct</option>
                                                <option value={11}>Nov</option>
                                                <option value={12}>Dec</option>
                                                <option value={13}>Yearly Total</option>
                                        </Input>
                                    </IonCol>
                                    <IonCol>
                                        <Input 
                                            name="year" 
                                            type="select" 
                                            value={year} 
                                            onChange={e => {
                                                setYear(parseInt(e.target.value));
                                                if (clinicians) getPayments(clinicians, month, parseInt(e.target.value));
                                            }}
                                        >
                                                <option value={"2023"}>2023</option>
                                                <option value={"2022"}>2022</option>
                                                <option value={"2021"}>2021</option>
                                                <option value={"2020"}>2020</option>
                                        </Input>
                                    </IonCol>
                                </IonRow>
                                {isLoading ?
                                    <Spinner />
                                    :
                                    <>
                                        {formattedClinicians ? 
                                            <IonList className="bg-white">
                                                {formattedClinicians.map((formattedClinician, index) => (
                                                    <IonItem key={index}>
                                                        <IonLabel>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <h2 className="font-weight-bold">{formattedClinician.clinician}</h2>
                                                                </IonCol>
                                                                <IonCol>
                                                                    <h2 className="font-weight-bold">${formattedClinician.total.toFixed(2)}</h2>
                                                                </IonCol>
                                                                <IonCol>
                                                                    <IonText className="ion-text-wrap"><h2 className="font-weight-bold">{formattedClinician.paymentMethod?.type} {formattedClinician.paymentMethod?.altId} {formattedClinician.paymentMethod?.value}</h2></IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                            {(formattedClinician.payments && formattedClinician.payments.length > 0) && (
                                                                <IonList className="bg-white">
                                                                    {formattedClinician.payments.map((payment, i) => (
                                                                        <IonLabel key={i}>
                                                                            <IonRow>
                                                                            <IonCol className="ion-text-wrap">{payment.payer}</IonCol>
                                                                            <IonCol className="ion-text-wrap">${payment.amount}</IonCol>
                                                                            <IonCol className="ion-text-wrap">{payment.date}</IonCol>
                                                                            </IonRow>
                                                                        </IonLabel>
                                                                    ))}
                                                                </IonList>
                                                            )}
                                                        </IonLabel>
                                                    </IonItem>
                                                ))}
                                            </IonList>
                                            :
                                            <p>None found</p>
                                        }
                                    </>
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default ViewPaymentsPage;