import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { close, create } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Organization, OrganizationStallSet } from "../../models";
import StallSetForm from "./StallSetForm";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { getOrganizationStallSetsByOrganizationId } from "../../utilities/organizationStallSet/OrganizationStallSet";

interface _Props {
    organization: Organization
}

const OrganizationStallSetForm: React.FC<_Props> = ({organization}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [organizationStallSetArray, setOrganizationStallSetArray] = useState<(OrganizationStallSet | null)[] | null | undefined>();
    const [selectedOrganizationStallSet, setSelectedOrganizationStallSet] = useState<OrganizationStallSet | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOrganizationStallSets(organizationId: string) {
            const queryResult = await getOrganizationStallSetsByOrganizationId(organizationId);
            if (queryResult.isSuccess) {
                setOrganizationStallSetArray(queryResult.result);
            }
        };
        if (organization) getOrganizationStallSets(organization.id);
    }, [organization]);

    const handleSubmit = async (isCreate: Boolean, newOrganizationStallSet: OrganizationStallSet) => {
        setIsLoading(true);
        if (organization) {
            setError("");
            let stallArray: (OrganizationStallSet | null)[] = [];
            if (isCreate) {
                if (organizationStallSetArray && organizationStallSetArray.length) {
                    stallArray = organizationStallSetArray.concat([newOrganizationStallSet]);
                } else {
                    stallArray.push(newOrganizationStallSet);
                }
            } else {
                // Updated the selectedOrganizationStallSet
                if (organizationStallSetArray && organizationStallSetArray.length) {
                    for (var i = 0; i < organizationStallSetArray.length; i++) {
                        const organizationStallSet = organizationStallSetArray[i];
                        if (organizationStallSet && organizationStallSet.id === selectedOrganizationStallSet?.id) stallArray.push(newOrganizationStallSet);
                        else stallArray.push(organizationStallSet);
                    }
                }
            }
            setOrganizationStallSetArray(stallArray);
        } else {
            setError("Error: no organization found. Please contact hello@ringsidepro.com");
        }
        setIsLoading(false);
        setShowModal(false);
    }

    const handleUpdate = async (organizationStallSet?: OrganizationStallSet) => {
        if (organizationStallSet) {
            setSelectedOrganizationStallSet(organizationStallSet);
            setShowModal(true);
        }
    }

    const handleAddNew = async () => {
        setSelectedOrganizationStallSet(undefined);
        setShowModal(true);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {organizationStallSetArray && organizationStallSetArray.length ?
                <>
                    <IonList className="bg-white">
                        {organizationStallSetArray.map((organizationStallSet, index) => (
                            <IonItem key={index}>
                                <IonLabel><h3>{organizationStallSet && organizationStallSet.name}</h3></IonLabel>
                                <IonButton slot="end" color="tertiary" onClick={() => handleUpdate(organizationStallSet as OrganizationStallSet)}><IonIcon icon={create}/></IonButton>
                            </IonItem>
                        ))}
                    </IonList>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={handleAddNew}>
                                Add Stall Set
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={handleAddNew}>
                                Add Stall Set
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
            <IonModal backdropDismiss={false} isOpen={showModal} id="addOrganizationStallSetModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Stall Set
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeStallModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <StallSetForm organization={organization} selectedOrganizationStallSet={selectedOrganizationStallSet} onSubmit={handleSubmit} />
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default OrganizationStallSetForm;