import React from "react";
import { Barn, Horse } from "../../models";
import AddHorseButton from "./AddHorseButton";
import { useHistory } from "react-router";

interface _Props {
    horse?: Horse
    barn?: Barn
    handleNewHorse: Function
}

const AddHorseCard: React.FC<_Props> = ({barn}) => {
    const history = useHistory();

    const handleSubmit = () => {
        let path = "/index/staff/barn/horse/create/";
        if (barn) {
            path = path + barn.id;
        }
        history.push(path);
    }

    return (
        <>
            <AddHorseButton onSubmit={handleSubmit} />
        </>
    );
};

export default AddHorseCard;
