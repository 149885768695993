import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React from "react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { useHistory } from "react-router";
import { Horse } from "../../../../models";
import HorseForm from "../../../../components/Horse/HorseForm";

const RiderNewHorsePage: React.FC = () => {
    const history = useHistory();

    const handleNewHorse = (horse: Horse) => {
        const path = "/index/horse/" + horse.id;
        history.replace(path);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Add Horse" />
                <IonRow>
                    <IonCol>
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                New Horse
                            </IonCardTitle>
                            <IonCardContent>
                                <HorseForm onChange={handleNewHorse} />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default RiderNewHorsePage;