import React, { useContext, useEffect, useState } from "react";
import Select from 'react-select';
import { PersonContext } from "../../context/PersonContext";
import { onCreateOwner } from "../../graphql/subscriptions";
import { Barn, Owner } from "../../models";
import { getOwnersByUserByBarn } from "../../utilities/owner/OwnersByBarn";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    onSelect: Function
    selectedValue?: (string | null)
    barn?: (Barn | null)
}

interface formattedOption {
    label: string
    value: string
    owner: Owner
}

const SelectOwner: React.FC<_Props> = ({barn, onSelect, selectedValue}) => {
    const user = useContext(PersonContext);

    const ownerSubscription = useSubscriptionByItself({
        config: {
            query: onCreateOwner,
            key: "onCreateOwner"
        }
    });

    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    const [formattedOwners, setFormattedOwners] = useState<formattedOption[] | null | undefined>();
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    async function getOwners() {
        setIsLoading(true);
        const owners = await getOwnersByUserByBarn(user, barn);
        formatOwners(owners);
        setIsLoading(false);
    }

    const formatOwners = (owners: Owner[]) => {
        let formattedOwners = [];
        for (var i = 0; i < owners.length; i++) {
            let object = {
                value: owners[i].id,
                label: owners[i].name,
                owner: owners[i]
            };
            formattedOwners.push(object);
        }
        setFormattedOwners(formattedOwners);
        if (selectedValue) findValueInList(selectedValue, formattedOwners);
    }

    const findValueInList = (value: string, ownerList?: formattedOption[]) => {
        let optionArray = ownerList || formattedOwners;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        getOwners();
    }, [barn]);

    useEffect(() => {
        getOwners();
    }, []);

    useEffect(() => {
        if (ownerSubscription && ownerSubscription[0] !== undefined && ownerSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(ownerSubscription[0]);
            getOwners();
        }
    }, [ownerSubscription]);

    useEffect(() => {
        async function setValue() {
            if (selectedValue) {
                if (!formattedOwners) {
                    await getOwners();
                } else {
                    findValueInList(selectedValue);
                }
                
            } else {
                setCurrentValue(undefined);
            }
        }
        setValue();
    }, [selectedValue]);

    const handleOnChange = async (event: any) => {
        if (event && event.owner) {
            setCurrentValue(event);
            onSelect(event.owner);
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ?
                <Spinner />
                :
                <Select
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    // defaultValue={currentValue}
                    value={currentValue}
                    menuPortalTarget={document.body}
                    isClearable
                    options={formattedOwners!}
                    onChange={handleOnChange}
                />
            }
        </>
    );
};

export default SelectOwner;
