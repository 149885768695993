import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { EventEntry, Horse } from "../../../models";
import { calculateHeightInHands } from "../../../utilities/horse/Height";
import { getHorseById } from "../../../utilities/horse/Horse";

interface _Props {
    entry: EventEntry
}

const DisplayEntryHorseInfo: React.FC<_Props> = ({entry}) => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [horse, setHorse] = useState<Horse | null | undefined>();

    const getHorse = async (entry: EventEntry) => {
        const horseId = entry.horseId;
        if (horseId) {
            const queryResult = await getHorseById(horseId);
            if (queryResult.isSuccess) {
                setHorse(queryResult.result);
            }
        }
    }

    useEffect(() => {
        if (entry) {
            setIsCollapsed(true);
            getHorse(entry);
        }
    }, [entry]);

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {entry ? <IonRow><h2>Horse: {entry.horseName}</h2></IonRow> : <h2>No Horse</h2> }
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {horse && (
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <p>Horse Name: {horse.name}</p>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        {horse.height ? <p>Horse Height: {calculateHeightInHands(parseInt(horse.height))} hands</p> : ""}
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default DisplayEntryHorseInfo;