import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreatePersonInput, CreatePersonMutation, DeletePersonInput, DeletePersonMutation, GetPersonQuery, ListPeopleQuery, UpdatePersonInput, UpdatePersonMutation } from "../../API";
import moment from "moment";
import { Person } from "../../models";
import { addPersonToSearch, removePersonFromSearch, updatePersonInSearch } from "./PersonSearch";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: person. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created person.
*/
export async function createPerson(input: CreatePersonInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Person", "No input", "Create Person received no input.");
   try {
        const fullInput: CreatePersonInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createPerson, { input: fullInput }))) as GraphQLResult<CreatePersonMutation>;
       else result = (await API.graphql({
           query: mutations.createPerson,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreatePersonMutation>;
       const person = result.data?.createPerson as Person;
       if (person) {
           addPersonToSearch(person);
       }
       return formatResult(true, "Person", person, "Successfully created the person.");
   } catch (error: any) {
       return formatResult(false, "Person", error, "Error creating record in the database for type: person");
   }
}

/**
* Handle updating a new record in the database for type: person. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated person.
*/
export async function updatePerson(input: UpdatePersonInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Person", "No input", "Update Person received no input.");
   try {
        const fullInput: UpdatePersonInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updatePerson, { input: fullInput }))) as GraphQLResult<UpdatePersonMutation>;
       else result = (await API.graphql({
           query: mutations.updatePerson,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdatePersonMutation>;
       const person = result.data?.updatePerson as Person;
       if (person) {
            updatePersonInSearch(person);
       }
       return formatResult(true, "Person", person, "Successfully updated the person.");
   } catch (error: any) {
       return formatResult(false, "Person", error, "Error updating record in the database for type: person");
   }
}

/**
* Handle deleting a new record in the database for type: person. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted person.
*/
export async function deletePerson(input: DeletePersonInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Person", "No input", "Delete Person received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deletePerson, { input: input }))) as GraphQLResult<DeletePersonMutation>;
       else result = (await API.graphql({
           query: mutations.deletePerson,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeletePersonMutation>;
       const person = result.data?.deletePerson as Person;
       if (person) {
           removePersonFromSearch(person);
       }
       return formatResult(true, "Person", person, "Successfully deleted the person.");
   } catch (error: any) {
       return formatResult(false, "Person", error, "Error deleting record in the database for type: person");
   }
}

/**
* Get all records in the database for type: person. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the person object
*/
export async function getAllPeople(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listPeople,
            variables: {
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPeopleQuery>;
        
        let items = result.data?.listPeople?.items as Person[];
        let nextToken = result.data?.listPeople?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listPeople,
                variables: {
                    limit: 1000,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListPeopleQuery>;

            const nextItems = nextResult.data?.listPeople?.items as Person[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPeople?.nextToken;
        }

        return formatResult(true, "Person", items, "Successfully got the Persons.");
    } catch (error: any) {
        return formatResult(false, "Person", error, "Error reading record in the database for type: persons");
    }
}

/**
* Read a specific record in the database for type: person. 
* 
* @param {string}  personId            The person id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the person object
*/
export async function getPersonByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.getPerson,
            variables: {
                id: personId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<GetPersonQuery>;
        const person = result.data?.getPerson;
        return formatResult(true, "Person", person, "Successfully got the person.");
    } catch (error: any) {
        return formatResult(false, "Person", error, "Error reading record in the database for type: person");
    }
}

/**
* Get all records in the database that match the given criteria for type: person. 
* 
* @param {string}  email               The email of the person.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the person object
*/
export async function getPersonByEmail(email: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {email: {eq: email}};
        const result = (await API.graphql({
            query: queries.listPeople,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPeopleQuery>;

        let items = result.data?.listPeople?.items as Person[];
        let nextToken = result.data?.listPeople?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listPeople,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListPeopleQuery>;

            const nextItems = nextResult.data?.listPeople?.items as Person[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPeople?.nextToken;
        }

        const persons = items;
        if (persons && persons[0]) return formatResult(true, "Person", persons[0], "Successfully got the person.");
        else return formatResult(false, "Person", null, "Could not find the person.");
    } catch (error: any) {
        return formatResult(false, "Person", error, "Error reading record in the database for type: person");
    }
}

/**
* Get all records in the database that match the given criteria for type: person. 
* 
* @param {string}  firstName           The first name of the person.
* @param {string}  lastName            The last name of the person.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the person object
*/
export async function getPeopleByName(firstName: string, lastName: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {firstName: {contains: firstName}, lastName: {contains: lastName}};
        const result = (await API.graphql({
            query: queries.listPeople,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListPeopleQuery>;

        let items = result.data?.listPeople?.items as Person[];
        let nextToken = result.data?.listPeople?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listPeople,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListPeopleQuery>;

            const nextItems = nextResult.data?.listPeople?.items as Person[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPeople?.nextToken;
        }

        return formatResult(true, "Person", items, "Successfully got the people.");
    } catch (error: any) {
        return formatResult(false, "Person", error, "Error reading record in the database for type: person");
    }
}