import "./../../theme/main.css";
import "./../../theme/images.css";

import {
    IonButton,
    IonButtons,
    IonHeader,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    isPlatform,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Organization } from "../../models";
import logo from "./../../assets/img/brand/WhiteLogo.png";
import { isWindows } from "../../utilities/platform/Platform";
import { LogoInput } from "../../API";
import CONSTANT from '../../constant/constant';

interface _Props {
    organization?: Organization
}
const OrganizationAuthNavbar: React.FC<_Props> = ({organization}) => {
    const [logos, setLogos] = useState<(LogoInput | null)[] | null[]>(organization?.logos ?? []);
    const [urlBackHalf, setUrlBackHalf] = useState<string>(organization?.urlBackHalf ?? '');
    const [logoUrl, setLogoUrl] = useState<string>(logos[0]?.logoURL ?? '');
    const [colors, setColors] = useState<(string | null)[]>(organization?.colors ?? []);
    useEffect(() => {
        setUrlBackHalf(organization?.urlBackHalf ?? '');
        setLogos(organization?.logos ?? []);
        setLogoUrl(logos[0]?.logoURL ?? '');
        setColors(organization?.colors ?? []);
        // White labelling color change code. The below code replaces the values of --ion-color-tertiary and
        // --ion-color-primary color variables with dynamic colors selected for the organization. This will
        // affect for all Auth and other pages.
        if(colors.length > 0) {
            document.documentElement.style.setProperty('--ion-color-tertiary', colors[0]);
        }
        if(colors.length === CONSTANT.ORGANIZATION.BRANDING_MAX_COLORS) {
            document.documentElement.style.setProperty('--ion-color-primary', colors[1]);
        }
    }, [organization]);
    return (
        <IonHeader>
            <IonToolbar>
                {(isWindows() || (isPlatform("desktop"))) ? (
                    <>
                        <IonButtons slot="start">
                            <IonButton class="pr-3" expand="block">
                                <p className="font-weight-bold display-4 text-white text-capitalize">
                                    {organization?.name}
                                </p>
                            </IonButton>
                            <IonButton class="pr-3">
                                <a href="/home">
                                    <p className="font-weight-normal display-5 text-white text-capitalize">
                                        Powered by RingSide Pro
                                    </p>
                                </a>
                            </IonButton>
                            <IonButton class="pr-3">
                                <IonTitle>
                                    <a href="/home">
                                        <img
                                            className="nav-img"
                                            alt="RingSide Pro"
                                            src={logo}
                                        />
                                    </a>
                                </IonTitle>
                            </IonButton>
                        </IonButtons>
                        <IonButtons slot="end">
                            <IonButton routerLink={`/${urlBackHalf}/register`}>
                                <p className="font-weight-normal text-white text-capitalize">
                                    Create Account
                                </p>
                            </IonButton>
                            <IonButton routerLink={`/${urlBackHalf}/login`}>
                                <p className="font-weight-normal text-white text-capitalize">
                                    Log In
                                </p>
                            </IonButton>
                        </IonButtons>
                    </>
                ) : (
                    <>
                        <IonButtons slot="start">
                            <IonButton
                                routerDirection="none"
                                routerLink={`/${urlBackHalf}/login`}
                            >
                                <img
                                    className="nav-img"
                                    alt={organization?.name}
                                    src={logoUrl.length > 0 ? logoUrl : logo}
                                />
                            </IonButton>
                        </IonButtons>
                        <IonButtons slot="end">
                            <IonMenuButton color="light" />
                        </IonButtons>
                    </>
                )}
            </IonToolbar>
        </IonHeader>
    );
};
export default OrganizationAuthNavbar;