import React, { useContext, useEffect, useState } from "react";
import Select from 'react-select';
import { PersonContext } from "../../context/PersonContext";
import { onCreatePerson, onUpdatePerson } from "../../graphql/subscriptions";
import { Person } from "../../models";
import { getAllPeople } from "../../utilities/person/Person";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import ErrorAlert from "../Errors/ErrorAlert";

interface _Props {
    onSelect: Function
    selectedValue?: string
    person?: (Person | null)
    isDisabled?: boolean
}

interface formattedOption {
    label: string
    value: string
    person: Person
}

const SelectFromAllPeople: React.FC<_Props> = ({person, onSelect, selectedValue, isDisabled}) => {
    const user = useContext(PersonContext);

    const personSubscription = useSubscriptionByItself({
        config: {
            query: onCreatePerson,
            key: "onCreatePerson"
        }
    });

    const personUpdateSubscription = useSubscriptionByItself({
        config: {
            query: onUpdatePerson,
            key: "onUpdatePerson"
        }
    });

    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    const [currentUpdateSubscriptionItem, setUpdateCurrentSubscriptionItem] = useState<any>();
    const [formattedPersons, setFormattedPersons] = useState<formattedOption[] | null | undefined>();
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [error, setError] = useState("");
    const [disabled, setDisabled] = useState<boolean>(false);

    const getPersons = async () => {
        const queryResult = await getAllPeople();
        if (queryResult.isSuccess) {
            const persons = queryResult.result;
            const sorted = persons.sort((a: Person, b: Person) => (a.firstName || "zzzzzz").localeCompare(b.firstName || "zzzzzz"))
            formatPersons(sorted || persons);          
        }
    };

    const formatPerson = (person: Person) => {
        let label = person.firstName + " " + person.lastName + " - " + (person.email && person.email !== "" ? person.email : "no email address found");
        let object: formattedOption = {
            value: person.id,
            label: label,
            person: person as Person
        };
        return object;
    }

    const formatPersons = (persons: Person[]) => {
        let formattedPersons = [];
        for (var i = 0; i < persons.length; i++) {
            const person = persons[i];
            if (person) {
                let object: formattedOption = formatPerson(person);
                formattedPersons.push(object);
            }
        }
        setFormattedPersons(formattedPersons);
        if (selectedValue) findValueInList(selectedValue, formattedPersons);
    }

    const findValueInList = (value: string, personList?: formattedOption[]) => {
        let optionArray = personList || formattedPersons;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        getPersons();
    }, []);

    useEffect(() => {
        if (person) {
            const formattedPerson = formatPerson(person);
            setCurrentValue(formattedPerson);
        }
    }, [person]);


    useEffect(() => {
        if(isDisabled) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [isDisabled]);

    useEffect(() => {
        if (personSubscription && personSubscription[0] !== undefined && personSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(personSubscription[0]);
            getPersons();
        }
    }, [personSubscription]);

    useEffect(() => {
        if (personUpdateSubscription && personUpdateSubscription[0] !== undefined && personUpdateSubscription[0] !== currentUpdateSubscriptionItem) {
            setUpdateCurrentSubscriptionItem(personUpdateSubscription[0]);
            getPersons();
        }
    }, [personUpdateSubscription]);

    useEffect(() => {
        async function setValue() {
            if (selectedValue) {
                if (!formattedPersons) {
                    await getPersons();
                } else {
                    findValueInList(selectedValue);
                }
                
            } else {
                setCurrentValue(undefined);
            }
        }
        setValue();
    }, [selectedValue]);

    const handleOnChange = async (event: any) => {
        if (event && event.person) {
            setCurrentValue(event);
            onSelect(event.person);
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            <Select
                id="select-from-all-people"
                inputId="select-from-all-people-input"
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                defaultValue={currentValue}
                value={currentValue}
                isDisabled={disabled}
                menuPortalTarget={document.body}
                isClearable
                options={formattedPersons!}
                onChange={handleOnChange}
            />
        </>
    );
};

export default SelectFromAllPeople;