import {
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonToggle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { PointTier } from "../../models";
import { Table } from "reactstrap";

interface _Props {
    tiers?: ((PointTier | null)[] | null)
    isEditable?: Boolean
    onSubmit?: Function
}

const BaseValuesTable: React.FC<_Props> = ({tiers, isEditable, onSubmit}) => {

    const [viewThroughTwelve, setViewThroughTwelve] = useState(false);
    const [first, setFirst] = useState(30);
    const [second, setSecond] = useState(22);
    const [third, setThird] = useState(15);
    const [fourth, setFourth] = useState(12);
    const [fifth, setFifth] = useState(11);
    const [sixth, setSixth] = useState(10);
    const [seventh, setSeventh] = useState(0);
    const [eighth, setEighth] = useState(0);
    const [ninth, setNinth] = useState(0);
    const [tenth, setTenth] = useState(0);
    const [eleventh, setEleventh] = useState(0);
    const [twelfth, setTwelfth] = useState(0);
    const [champion, setChampion] = useState(0);
    const [reserve, setReserve] = useState(0);

    useEffect(() => {
        if (tiers && tiers.length > 0) {
            const firstTier = tiers[0];
            if ((firstTier && firstTier.first)) setFirst(parseFloat(firstTier.first));
            if ((firstTier && firstTier.second)) setSecond(parseFloat(firstTier.second));
            if ((firstTier && firstTier.third)) setThird(parseFloat(firstTier.third));
            if ((firstTier && firstTier.fourth)) setFourth(parseFloat(firstTier.fourth));
            if ((firstTier && firstTier.fifth)) setFifth(parseFloat(firstTier.fifth));
            if ((firstTier && firstTier.sixth)) setSixth(parseFloat(firstTier.sixth));
            if ((firstTier && firstTier.seventh)) setSeventh(parseFloat(firstTier.seventh));
            if ((firstTier && firstTier.eighth)) setEighth(parseFloat(firstTier.eighth));
            if ((firstTier && firstTier.ninth)) setNinth(parseFloat(firstTier.ninth));
            if ((firstTier && firstTier.tenth)) setTenth(parseFloat(firstTier.tenth));
            if ((firstTier && firstTier.eleventh)) setEleventh(parseFloat(firstTier.eleventh));
            if ((firstTier && firstTier.twelfth)) setTwelfth(parseFloat(firstTier.twelfth));
            if ((firstTier && firstTier.champion)) setChampion(parseFloat(firstTier.champion));
            if ((firstTier && firstTier.reserve)) setReserve(parseFloat(firstTier.reserve));
        }
    }, [tiers]);

    return (
        <>
            {isEditable && (
                <>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel>Display 7-12</IonLabel>
                                <IonToggle color="tertiary" slot="end" checked={viewThroughTwelve} onIonChange={e => setViewThroughTwelve(e.detail.checked)} />
                            </IonItem>   
                        </IonCol>
                    </IonRow>
                </>
            )}
            <IonRow>
                <IonCol>
                    <Table>
                        <thead>
                            <tr>
                                <th>Place</th>
                                <th>Point Value</th>
                            </tr>
                        </thead>
                        {isEditable ?
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <th>
                                        <IonInput
                                            type="number"
                                            value={first}
                                            inputmode="numeric"
                                            mode="ios"
                                            min="0"
                                            onIonChange={e => {
                                                setFirst(parseFloat(e.detail.value! || "0"));
                                                if (onSubmit) onSubmit("first", parseFloat(e.detail.value! || "0"));
                                            }}
                                        />
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <th>
                                        <IonInput
                                            type="number"
                                            value={second}
                                            inputmode="numeric"
                                            min="0"
                                            onIonChange={e => {
                                                setSecond(parseFloat(e.detail.value! || "0"));
                                                if (onSubmit) onSubmit("second", parseFloat(e.detail.value! || "0"));
                                            }}
                                        />
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <th>
                                        <IonInput
                                            type="number"
                                            value={third}
                                            inputmode="numeric"
                                            min="0"
                                            onIonChange={e => {
                                                setThird(parseFloat(e.detail.value! || "0"));
                                                if (onSubmit) onSubmit("third", parseFloat(e.detail.value! || "0"));
                                            }}
                                        />
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <th>
                                        <IonInput
                                            type="number"
                                            value={fourth}
                                            inputmode="numeric"
                                            min="0"
                                            onIonChange={e => {
                                                setFourth(parseFloat(e.detail.value! || "0"));
                                                if (onSubmit) onSubmit("fourth", parseFloat(e.detail.value! || "0"));
                                            }}
                                        />
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <th>
                                        <IonInput
                                            type="number"
                                            value={fifth}
                                            inputmode="numeric"
                                            min="0"
                                            onIonChange={e => {
                                                setFifth(parseFloat(e.detail.value! || "0"));
                                                if (onSubmit) onSubmit("fifth", parseFloat(e.detail.value! || "0"));
                                            }}
                                        />
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <th>
                                        <IonInput
                                            type="number"
                                            value={sixth}
                                            inputmode="numeric"
                                            min="0"
                                            onIonChange={e => {
                                                setSixth(parseFloat(e.detail.value! || "0"));
                                                if (onSubmit) onSubmit("sixth", parseFloat(e.detail.value! || "0"));
                                            }}
                                        />
                                    </th>
                                </tr>
                                {viewThroughTwelve && (
                                    <>
                                        <tr>
                                            <th scope="row">7</th>
                                            <th>
                                                <IonInput
                                                    type="number"
                                                    value={seventh}
                                                    inputmode="numeric"
                                                    min="0"
                                                    onIonChange={e => {
                                                        setSeventh(parseFloat(e.detail.value! || "0"));
                                                        if (onSubmit) onSubmit("seventh", parseFloat(e.detail.value! || "0"));
                                                    }}
                                                />
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="row">8</th>
                                            <th>
                                                <IonInput
                                                    type="number"
                                                    value={eighth}
                                                    inputmode="numeric"
                                                    min="0"
                                                    onIonChange={e => {
                                                        setEighth(parseFloat(e.detail.value! || "0"));
                                                        if (onSubmit) onSubmit("eighth", parseFloat(e.detail.value! || "0"));
                                                    }}
                                                />
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="row">9</th>
                                            <th>
                                                <IonInput
                                                    type="number"
                                                    value={ninth}
                                                    inputmode="numeric"
                                                    min="0"
                                                    onIonChange={e => {
                                                        setNinth(parseFloat(e.detail.value! || "0"));
                                                        if (onSubmit) onSubmit("ninth", parseFloat(e.detail.value! || "0"));
                                                    }}
                                                />
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="row">10</th>
                                            <th>
                                                <IonInput
                                                    type="number"
                                                    value={tenth}
                                                    inputmode="numeric"
                                                    min="0"
                                                    onIonChange={e => {
                                                        setTenth(parseFloat(e.detail.value! || "0"));
                                                        if (onSubmit) onSubmit("tenth", parseFloat(e.detail.value! || "0"));
                                                    }}
                                                />
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="row">11</th>
                                            <th>
                                                <IonInput
                                                    type="number"
                                                    value={eleventh}
                                                    inputmode="numeric"
                                                    min="0"
                                                    onIonChange={e => {
                                                        setEleventh(parseFloat(e.detail.value! || "0"));
                                                        if (onSubmit) onSubmit("eleventh", parseFloat(e.detail.value! || "0"));
                                                    }}
                                                />
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="row">12</th>
                                            <th>
                                                <IonInput
                                                    type="number"
                                                    value={twelfth}
                                                    inputmode="numeric"
                                                    min="0"
                                                    onIonChange={e => {
                                                        setTwelfth(parseFloat(e.detail.value! || "0"));
                                                        if (onSubmit) onSubmit("twelfth", parseFloat(e.detail.value! || "0"));
                                                    }}
                                                />
                                            </th>
                                        </tr>
                                    </>
                                )}
                                
                                <tr>
                                    <th scope="row">Champion</th>
                                    <th>
                                        <IonInput
                                            type="number"
                                            value={champion}
                                            min="0"
                                            onIonChange={e => {
                                                setChampion(parseFloat(e.detail.value! || "0"))
                                                if (onSubmit) onSubmit("champion", parseFloat(e.detail.value! || "0"));
                                            }}
                                        />
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="row">Reserve</th>
                                    <th>
                                        <IonInput
                                            type="number"
                                            value={reserve}
                                            min="0"
                                            onIonChange={e => {
                                                setReserve(parseFloat(e.detail.value! || "0"))
                                                if (onSubmit) onSubmit("reserve", parseFloat(e.detail.value! || "0"));
                                            }}
                                        />
                                    </th>
                                </tr>
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <th>{first}</th>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <th>{second}</th>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <th>{third}</th>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <th>{fourth}</th>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <th>{fifth}</th>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <th>{sixth}</th>
                                </tr>
                                {viewThroughTwelve && (
                                    <>
                                        <tr>
                                            <th scope="row">7</th>
                                            <th>{seventh}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">8</th>
                                            <th>{eighth}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">9</th>
                                            <th>{ninth}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">10</th>
                                            <th>{tenth}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">11</th>
                                            <th>{eleventh}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">12</th>
                                            <th>{twelfth}</th>
                                        </tr>
                                    </>
                                )}
                                <tr>
                                    <th scope="row">Champion</th>
                                    <th>{champion}</th>
                                </tr>
                                <tr>
                                    <th scope="row">Reserve</th>
                                    <th>{reserve}</th>
                                </tr>
                            </tbody>
                        }
                    </Table>
                </IonCol>
            </IonRow>
        </>
    );
};

export default BaseValuesTable;