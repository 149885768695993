import {
    IonCard,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
} from "@ionic/react";
import React from "react";
import { Page, clinicianMenuOptions } from "../../interfaces/Page";

const ClinicianOptionsList: React.FC = () => {

    return (
        <IonCard className="bg-white ion-padding">
            <IonList className="bg-white">
                {clinicianMenuOptions.map((page: Page, index: number) => (
                    <IonItem 
                        key={index} 
                        detail={true}
                        routerLink={page.path} 
                        routerDirection="none"
                    >
                        <IonIcon icon={page.icon} slot="start" />
                        <IonLabel>
                            <h2>{page.title}</h2>
                            <p>{page.description}</p>
                        </IonLabel>
                    </IonItem>
                ))}
            </IonList>
        </IonCard>
    );
};

export default ClinicianOptionsList;