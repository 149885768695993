import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Event, EventEntry, Rider, Membership } from "../../../../../models";
import { getMembershipsByRiderId } from "../../../../../utilities/membership/Membership";
import SuccessBanner from "../../../../Banners/SuccessBanner";
import ErrorAlert from "../../../../Errors/ErrorAlert";
import Spinner from "../../../../Spinners/Spinner";
import { Table } from "reactstrap";
import { addCircleOutline, close } from "ionicons/icons";
import PersonMembershipForm from "../../../../Memberships/PersonMembershipForm";
import { BarnPerson } from "../../../../../interfaces/Person";
import constants from "../../../../../constant/constant";

interface _Props {
    entry: EventEntry
    event: Event
}

const EditEntryRiderMembershipsCard: React.FC<_Props> = ({entry, event}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [riderName, setRiderName] = useState("");
    const [barnPerson, setBarnPerson] = useState<BarnPerson | null | undefined>();
    const [membershipData, setMembershipData] = useState<Membership[] | null | undefined>();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [showModal, setShowModal] = useState(false);

    async function setMemberships(rider: Rider) {
        setIsLoading(true);
        let memberships: Membership[] = [];
        const queryResult = await getMembershipsByRiderId(rider.id);
        if (queryResult.isSuccess) {
            const membershipResults: Membership[] = queryResult.result;
            memberships = membershipResults.sort((a, b) => a.name.localeCompare(b.name));
        }
        setMembershipData(memberships);
        setIsLoading(false);
    }

    function getBarnPersonInfo(entry: EventEntry) {
        const barnPerson: BarnPerson = {
            name: entry.rider?.name || entry.riderName || "",
            personId: entry.rider?.personId || "",
            riderId: entry.riderId || "",
            rider: entry.rider,
            roles: "rider;",
            createdBy: "",
            creatorName: ""
        };
        setBarnPerson(barnPerson);
    }

    const getEntryInformation = async (entry: EventEntry) => {
        setRiderName(entry.riderName || "");
        if (entry.rider) {
            await setMemberships(entry.rider);
            getBarnPersonInfo(entry);
        }
    }

    useEffect(() => {
        if (entry) {
            getEntryInformation(entry);
        }
    }, [entry]);

    const handleCreateMembership = async () => {
        setShowModal(false);
        if (entry.rider) await setMemberships(entry.rider);
    }

    const handleAddMembershipClick = () => {
        if (event.organizationId === constants.RHSC_ORGANIZATION.id) {
            const path = "/index/staff/organization/" + constants.RHSC_ORGANIZATION.id + "/member/create";
            window.open(path, "_blank", "noreferrer");
        } else {
            setShowModal(true);
        }
    }

    const handleRowClick = (membership: Membership) => {
        if (membership.name === "RHSC") {
            const path = "/index/staff/organization/membership/edit/" + membership.id;
            window.open(path, "_blank", "noreferrer");
        }
    }

    return (
        <>
            <IonCard color="white" className="ion-padding">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h4>Rider Memberships</h4>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {success && <SuccessBanner width="12" success={success} />}
                        {(isEdit) && (
                            <IonRow className="ion-text-right">
                                <IonCol>
                                    <IonButton
                                        color="success"
                                        onClick={() => handleAddMembershipClick()}
                                    >
                                        <IonIcon icon={addCircleOutline} />
                                        Add Membership
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        )}
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="11">
                                {(membershipData && membershipData.length > 0) ?
                                    <Table hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Membership</th>
                                                <th>ID</th>
                                                <th>Type</th>
                                                <th>Date Expires</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {membershipData.map((membership, index) => (
                                                <tr key={index} onClick={() => handleRowClick(membership)}>
                                                    <td>{membership.name}</td>
                                                    <td>{membership.membershipId}</td>
                                                    <td>{membership.type}</td>
                                                    <td>{membership.dateMembershipEnds ? moment(membership.dateMembershipEnds).format("MMM DD, YYYY") : ""}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    :
                                    <p>No rider memberships were found.</p>
                                }
                            </IonCol>
                        </IonRow>
                        {isLoading ?
                            <Spinner />
                            :
                            <IonRow className="ion-justify-content-center">
                                {isEdit ? 
                                    <IonCol sizeMd="4" className="ion-text-center">
                                        <IonButton
                                            className="ion-margin-top"
                                            color="primary"
                                            expand="block"
                                            onClick={() => setIsEdit(false)}
                                        >
                                            Cancel
                                        </IonButton>
                                    </IonCol>
                                    :
                                    <IonCol sizeMd="4" className="ion-text-center">
                                        <IonButton
                                            className="ion-margin-top"
                                            color="tertiary"
                                            expand="block"
                                            onClick={() => setIsEdit(true)}
                                        >
                                            Allow Edit
                                        </IonButton>
                                    </IonCol>
                                }
                            </IonRow>
                        }
                    </IonCardContent>
                )}
            </IonCard>

            {/* Create New Rider Membership */}
            <IonModal backdropDismiss={false} isOpen={showModal} id="usefIdModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Create Membership
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="createRiderMembershipCloseBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {entry.rider ?
                        <>
                            {barnPerson && <PersonMembershipForm person={barnPerson} onSubmit={handleCreateMembership} />}
                        </>
                        :
                        <p>No rider found.</p>
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default EditEntryRiderMembershipsCard;