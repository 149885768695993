import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Spinner from "../Spinners/Spinner";
import { Barn, BarnMember, Horse, Person } from "../../models";
import { createBarnMember, getBarnMembersByPersonId, updateBarnMember } from "../../utilities/barnMember/BarnMember";
import { Table } from "reactstrap";
import moment from "moment";
import { getHorsesByPersonId, updateHorse } from "../../utilities/horse/Horse";
import { close } from "ionicons/icons";
import { CreateBarnMemberInput, UpdateBarnMemberInput, UpdateHorseInput } from "../../API";
import SelectFromAllBarns from "../Barn/SelectFromAllBarns";

interface _Props {
    person: Person
}

const AdminEditPersonBarnMemberInfo: React.FC<_Props> = ({person}) => {    
    
    const [barnMembers, setBarnMembers] = useState<BarnMember[] | undefined | null>();   
    const [horses, setHorses] = useState<Horse[] | undefined | null>();    
    const [isLoadingBarnMembers, setIsLoadingBarnMembers] = useState(false);
    const [isLoadingHorses, setIsLoadingHorses] = useState(false);
    const [error, setError] = useState("");

    const [showAddBarnMemberModal, setShowAddBarnMemberModal] = useState(false);
    const [selectedBarn, setSelectedBarn] = useState<Barn | null | undefined>();

    const getBarnMembers = async (personId: string) => {
        setIsLoadingBarnMembers(true);
        const queryResult = await getBarnMembersByPersonId(personId);
        if (queryResult.isSuccess) {
            const barnMemberList: BarnMember[] = queryResult.result;
            const sorted = barnMemberList.sort((a, b) => (a.barn.name || "").localeCompare((b.barn.name || "")));
            setBarnMembers(sorted || barnMemberList);
        }
        setIsLoadingBarnMembers(false);
    }

    const getHorses = async (personId: string) => {
        setIsLoadingHorses(true);
        const queryResult = await getHorsesByPersonId(personId);
        if (queryResult.isSuccess) {
            const horseList: Horse[] = queryResult.result;
            const sorted = horseList.sort((a, b) => (a.name || "").localeCompare((b.name || "")));
            setHorses(sorted || horseList);
        }
        setIsLoadingHorses(false);
    }

    useEffect(() => {
        if (person) {
            getBarnMembers(person.id);
            getHorses(person.id);
        }
    }, [person]);

    const handleAddNewBarnMember = async () => {
        setError("");
        setShowAddBarnMemberModal(false);
        if (selectedBarn) {
            setIsLoadingBarnMembers(true);
            const personName = person.firstName + " " + person.lastName;

            const barnMemberInput: CreateBarnMemberInput = {
                name: personName,
                barnId: selectedBarn.id,
                personId: person.id,
                permissionLevel: "member",
                roles: person.roles || "",
                status: "accepted"
            };
            const barnMemberResult = await createBarnMember(barnMemberInput);
            if (barnMemberResult.isSuccess) {
                const newBarnMember: BarnMember = barnMemberResult.result;
                const updatedBarnMembers = (barnMembers || []).concat([newBarnMember]);
                const sorted = updatedBarnMembers.sort((a, b) => (a.barn.name || "").localeCompare((b.barn.name || "")));
                setBarnMembers(sorted);
            } else {
                setError("Could not create the new barn member.");
            }
            setIsLoadingBarnMembers(false);
        } else {
            setError("No barn was selected.");
        }
    }

    const handleSelectBarnForBarnMember = async (barnMemberId: string, barn?: Barn) => {
        const updateBarnMemberInput: UpdateBarnMemberInput = {
            id: barnMemberId,
            barnId: barn?.id || ""
        };
        const updateResult = await updateBarnMember(updateBarnMemberInput);
        if (updateResult.isSuccess) {
            const updatedBarnMember: BarnMember = updateResult.result;
            if (barnMembers) {
                const index = barnMembers?.findIndex(bm => bm.id === updatedBarnMember.id);
                if (index > -1) {
                    const updatedList: BarnMember[] = [
                        ...barnMembers.slice(0, index),
                        updatedBarnMember,
                        ...barnMembers.slice(index + 1),
                    ];
                    setBarnMembers(updatedList);
                }
            }
        }
    }

    const handleSelectBarnForHorse = async (horseId: string, barn?: Barn) => {
        const updateHorseInput: UpdateHorseInput = {
            id: horseId,
            barnId: barn?.id || "",
            barnName: barn?.name || ""
        };
        const updateResult = await updateHorse(updateHorseInput);
        if (updateResult.isSuccess) {
            const updatedHorse: Horse = updateResult.result;
            if (horses) {
                const index = horses?.findIndex(h => h.id === updatedHorse.id);
                if (index > -1) {
                    const updatedList: Horse[] = [
                        ...horses.slice(0, index),
                        updatedHorse,
                        ...horses.slice(index + 1),
                    ];
                    setHorses(updatedList);
                }
            }
        }
    }

    return (
        <>
            <IonCard mode="md" className="ion-padding bg-white">
                <IonCardTitle>Barn Members</IonCardTitle>
                <IonCardSubtitle>
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="6">
                            <p className="description">Get BarnMembers by personId.</p>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-right">
                            <IonButton color="success" onClick={() => setShowAddBarnMemberModal(true)}>Add New</IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardSubtitle>
                <IonCardContent>
                    {isLoadingBarnMembers ?
                        <Spinner />
                        :
                        <>
                            {(barnMembers && barnMembers.length > 0) ?
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Member Created On</th>
                                            <th>Member Name</th>
                                            <th>Member Permission</th>
                                            <th>Member Status</th>
                                            <th>Member Roles</th>
                                            <th>Barn Id</th>
                                            <th>Barn Name</th>
                                            <th>Barn Select</th>
                                            <th>Barn Owner Id</th>
                                            <th>Barn Owner Name</th>
                                            <th>Barn Created On</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {barnMembers.map((barnMember, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <p className="ion-text-wrap">{barnMember.createdOn ? moment(barnMember.createdOn).format("MMM Do YYYY") : ""}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{barnMember.name}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{barnMember.permissionLevel}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{barnMember.status}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{barnMember.roles}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap"><a href={"/index/barn/info/" + barnMember.barn.id}>{barnMember.barn.id}</a></p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{barnMember.barn.name}</p>
                                                </td>
                                                <td className="extra-jumbo-input-width">
                                                    <SelectFromAllBarns 
                                                        showBarnIds
                                                        selectedValue={barnMember.barn?.id || ""} 
                                                        isHeightRequired={true}
                                                        height={"120px"}
                                                        onSelect={(b: Barn) => handleSelectBarnForBarnMember(barnMember.id, b)} 
                                                    />
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{barnMember.barn.ownerPersonId}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{barnMember.barn.ownerName}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{barnMember.barn.createdOn ? moment(barnMember.barn.createdOn).format("MMM Do YYYY") : ""}</p>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                :
                                <p>No barn members found.</p>
                            }
                        </>
                    }
                </IonCardContent>
            </IonCard>
            <IonCard mode="md" className="ion-padding bg-white">
                <IonCardTitle>Horses</IonCardTitle>
                <IonCardSubtitle><p className="description">Get Horses by personId.</p></IonCardSubtitle>
                <IonCardContent>
                    {isLoadingHorses ?
                        <Spinner />
                        :
                        <>
                            {(horses && horses.length > 0) ?
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Horse Created On</th>
                                            <th>Horse Id</th>
                                            <th>Horse Name</th>
                                            <th>Horse Barn Id</th>
                                            <th>Horse Barn Name</th>
                                            <th>Horse Barn Select</th>
                                            <th>Horse Owner Id</th>
                                            <th>Horse Owner Name</th>
                                            <th>Horse Coggins Number</th>
                                            <th>Horse Coggins Date</th>
                                            <th>Horse Coggins Key</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {horses.map((horse, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <p className="ion-text-wrap">{horse.createdOn ? moment(horse.createdOn).format("MMM Do YYYY") : ""}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{horse.id}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{horse.name}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap"><a href={"/index/barn/info/" + horse.barnId}>{horse.barnId}</a></p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{horse.barn?.name}</p>
                                                </td>
                                                <td className="extra-jumbo-input-width">
                                                    <SelectFromAllBarns 
                                                        showBarnIds
                                                        selectedValue={horse?.barnId || horse.barn?.id || ""} 
                                                        isHeightRequired={true}
                                                        height={"120px"}
                                                        onSelect={(b: Barn) => handleSelectBarnForHorse(horse.id, b)} 
                                                    />
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{horse.owner?.id}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{horse.owner?.name}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{horse.cogginsNumber}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{horse.cogginsDate}</p>
                                                </td>
                                                <td>
                                                    <p className="ion-text-wrap">{horse.coggins?.key}</p>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                :
                                <p>No horses found.</p>
                            }
                        </>
                    }
                </IonCardContent>
            </IonCard>
            <IonModal backdropDismiss={false} isOpen={showAddBarnMemberModal} id="addBarnMemberModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Barn Member
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowAddBarnMemberModal(false)}
                        >
                            <p id="closeAddBarnMemberModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow>
                        <IonCol>
                            <p>Select the barn. Then, the save button will generate a new barn member for this person where the status is "member" (as opposed to "admin" status for the barn membership).</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <SelectFromAllBarns 
                                showBarnIds
                                selectedValue={selectedBarn?.id} 
                                isHeightRequired={true}
                                height={"120px"}
                                onSelect={(b: Barn) => setSelectedBarn(b)}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10">
                           <IonButton
                                color="success"
                                expand="block"
                                onClick={handleAddNewBarnMember}
                           >
                                Save
                           </IonButton>
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
        </>
    );
};

export default AdminEditPersonBarnMemberInfo;