import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useState } from "react";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import Spinner from "../../../../../components/Spinners/Spinner";
import { RouteComponentProps } from "react-router";
import { generateMemberListOfShowsReport, generatePointsByYearReport, generatePointsByYearWithPointBreakdownReport } from "../../../../../utilities/reports/PointsReports";
import moment from "moment";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventOrganizationDownloadPointReportsPage: React.FC<OrganizationPageProps> = ({match}) => {

    const [progress, setProgress] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);

    const progressCallback = (progress: number) => {
        setProgress(() => progress);
    }

    const handlePrintYearlyPoints = async (includeBreakdown: boolean, isCSV: boolean, isOnlyChampAndReserve?: boolean, isMembersOnly?: boolean, isFindDuplicates?: boolean) => {
        if (match.params.id) {
            setIsDownloading(true);
            setProgress(0);
            const currentYear = moment(new Date()).format("YYYY");
            if (includeBreakdown) await generatePointsByYearWithPointBreakdownReport(match.params.id, currentYear, isMembersOnly, isFindDuplicates, progressCallback);
            else await generatePointsByYearReport(match.params.id, currentYear, isCSV, isOnlyChampAndReserve, isMembersOnly, progressCallback);
            setIsDownloading(false);
        }
    }

    const handlePrintMemberReport = async (isMismatchedReport: boolean) => {
        if (match.params.id) {
            setIsDownloading(true);
            setProgress(0);
            const currentYear = moment(new Date()).format("YYYY");
            await generateMemberListOfShowsReport(match.params.id, currentYear, isMismatchedReport, setProgress);
            setIsDownloading(false);
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Points Reports" />
                <>
                    {(match.params.id) ?
                        <IonRow>
                            <IonCol>
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>Points Reports</IonCardTitle>
                                    {isDownloading ?
                                        <>
                                            <Spinner />
                                            <p>Completed {progress}%</p>
                                        </>
                                        :
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>
                                                    <p onClick={() => handlePrintYearlyPoints(false, false)} className="link">Yearly Points Earned (PDF)</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p onClick={() => handlePrintYearlyPoints(false, false, false, true)} className="link">Yearly Points Earned - Members Only (PDF)</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p onClick={() => handlePrintYearlyPoints(false, true)} className="link">Yearly Points Earned (CSV)</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p onClick={() => handlePrintYearlyPoints(false, true, true)} className="link">Yearly Champions and Reserve Champions (CSV)</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p onClick={() => handlePrintYearlyPoints(true, false)} className="link">Yearly Points Earned with Breakdown by Event (PDF)</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p onClick={() => handlePrintYearlyPoints(true, false, false, true, false)} className="link">Yearly Points Earned with Breakdown by Event - Members Only (PDF)</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p onClick={() => handlePrintYearlyPoints(true, false, false, false, true)} className="link">Yearly Points Earned - Find Any Duplicates (PDF)</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p onClick={() => handlePrintMemberReport(false)} className="link">Members List - Shows Attended, Meetings Attended, Volunteer Hours (PDF)</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <p onClick={() => handlePrintMemberReport(true)} className="link">Members List - Mismatched Shows, Meetings or Hours (PDF)</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    }
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        :
                        <p>No organization found</p>
                    }
                </>
            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationDownloadPointReportsPage;