import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { Event, EventEntry, Trainer } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { getEventEntriesByEventId } from "../../utilities/eventEntry/EventEntry";
import Spinner from "../Spinners/Spinner";

interface _Props {
    onSelect: Function
    event: (Event | null)
}

interface formattedOption {
    label: string
    value: string
    trainer: Trainer
}

const SelectFromEventTrainers: React.FC<_Props> = ({event, onSelect}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [formattedTrainers, setFormattedTrainers] = useState<formattedOption[] | null | undefined>();
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [error, setError] = useState("");

    async function getTrainers(event: Event) {
        let trainerMap = new Map<string, Trainer>();

        setIsLoading(true);
        const queryResult = await getEventEntriesByEventId(event.id);
        if (queryResult.isSuccess) {
            const allEventEntries: EventEntry[] = queryResult.result;
            if (allEventEntries && allEventEntries.length > 0) {
                for (let i = 0; i < allEventEntries.length; i++) {
                    const eventEntry = allEventEntries[i];
                    if (eventEntry.trainerId) {
                        const trainerNameString = (eventEntry.trainerName ? eventEntry.trainerName.toLowerCase() : eventEntry.trainer?.name ? eventEntry.trainer.name.toLowerCase() : "");
                        const trainerMapResult = trainerMap.get(trainerNameString);
                        if (!trainerMapResult && eventEntry.trainer) trainerMap.set(trainerNameString, eventEntry.trainer);
                    }
                }
            }
        }

        let trainerArray: Trainer[] = [];
        trainerMap.forEach((value: Trainer) => {
            trainerArray.push(value);
        });

        trainerArray = trainerArray.sort((a, b) => a.name.localeCompare(b.name));

        formatTrainers(trainerArray);

        setIsLoading(false);
    }

    const formatTrainers = (trainers: Trainer[]) => {
        let formattedTrainers = [];
        for (var i = 0; i < trainers.length; i++) {
            let object = {
                value: trainers[i].id,
                label: trainers[i].name,
                trainer: trainers[i]
            };
            formattedTrainers.push(object);
        }
        setFormattedTrainers(formattedTrainers);
    }

    useEffect(() => {
        if (event) getTrainers(event);
    }, [event]);

    const handleOnChange = async (event: any) => {
        if (event && event.trainer) {
            setCurrentValue(event);
            onSelect(event.trainer);
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ? 
                <Spinner />
                :
                <Select
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    value={currentValue}
                    menuPortalTarget={document.body}
                    isClearable
                    options={formattedTrainers!}
                    onChange={handleOnChange}
                />
            }
        </>
    );
};

export default SelectFromEventTrainers;
