import constants from "../../constant/constant";
import { Result } from "../../interfaces/Result";
import { Auditor, Event, Organization } from "../../models";
import { getErrorMessageFromCode } from "./TwilioErrorCodes";
import { handleSendCanadianSMS, handleSendGITNBSMS, handleSendOrgSMS, handleSendSMS } from "./TwilioSMS";

function formatResultFromError(smsResult: any) {
    console.error(smsResult);
    let message = "An error occurred: ";
    if (smsResult.result && smsResult.result.error) {
        console.error(smsResult.result.error);
        const errorCode = smsResult.result.error.code;
        message = message + getErrorMessageFromCode(errorCode);
    } else {
        message = message + "unknown error."
    }

    const result: Result = {isSuccess: false, type: "SMS", result: smsResult, message: message};
    return result;
}

function formatResultForMissingPhoneNumber() {
    const result: Result = {isSuccess: false, type: "SMS", result: null, message: "The auditor in the database has no phone number."};
    return result;
}

export async function sendRSPWelcomeTextsForEvent(auditor: Auditor, event: Event, isAdminView?: Boolean, isSendContactCard?: Boolean) {
    let result: Result = {isSuccess: true, type: "SMS", result: null, message: "Successfully sent the messages!"};

    let initialMessage = "RingSide Pro: Welcome! Msg and data rates may apply. Reply HELP for help, PAUSE to stop texts for this event, STOP to unsubscribe forever, START to resubscribe.";
    let welcomeMessage;
    if (event && event.name && isAdminView) {
        welcomeMessage = "RingSide Pro: Show staff subscribed you to " + event.name + "! You can adjust your settings at ringsidepro.com.";
    } else if (event && event.name) {
        welcomeMessage = "RingSide Pro: Thank you for subscribing to " + event.name + "! You can adjust your settings at ringsidepro.com.";
    } else welcomeMessage = "RingSide Pro: Thank you for subscribing to horse show alerts! You can adjust your settings at ringsidepro.com.";
    if (event && event.eventOptions && event.eventOptions.acceptsIncomingTextAlerts) {
        welcomeMessage = welcomeMessage + " This event has 2-way messaging. Respond to ask questions to the event staff.";
    } else {
        welcomeMessage = welcomeMessage + " Please note, these are automated messages.";
    }

    // In prod, set isTest to false
    if (auditor?.phoneNumber) {
        let smsResult: any;

        // If it is a Coveside Stables show (Canada), use the toll free number
        if (event.organizationId === constants.Coveside_ORGANIZATION.id || event?.id === constants.European_Classic_Event_Id) {
            smsResult = await handleSendCanadianSMS(initialMessage, auditor.phoneNumber, false);
            await handleSendCanadianSMS(welcomeMessage, auditor.phoneNumber, false);
        } else {
            smsResult = await handleSendSMS(initialMessage, auditor.phoneNumber, false);
            await handleSendSMS(welcomeMessage, auditor.phoneNumber, false);
        }

        // Send the contact info to people to save in their phone's contacts
        if (isSendContactCard) await sendRSPContactCardInfo(auditor, event);

        if (smsResult && smsResult.isSuccess) {
            return result;
        } else {
            result = formatResultFromError(smsResult);
            return result;
        }
    } else {
        result = formatResultForMissingPhoneNumber();
        return result;
    }
}

export async function sendRSPUpdateTextsForEvent(auditor: Auditor, event: Event, isAdminView?: Boolean) {
    let result: Result = {isSuccess: true, type: "SMS", result: null, message: "Successfully sent the messages!"};

    let updateMessage;
    if (event && event.name && isAdminView) updateMessage = "Show staff updated your alert settings for " + event.name + "!";
    else if (event && event.name) updateMessage = "You successfully updated your alert settings for " + event.name + "!";
    else updateMessage = "Thank you for updating your alerts via the RingSide Pro system!";

    // In prod, set isTest to false
    if (auditor?.phoneNumber) {
        let smsResult: any;

        // If it is a Coveside Stables show (Canada), use the toll free number
        if (event.organizationId === constants.Coveside_ORGANIZATION.id || event?.id === constants.European_Classic_Event_Id) {
            smsResult = await handleSendCanadianSMS(updateMessage, auditor.phoneNumber, false);
        } else {
            smsResult = await handleSendSMS(updateMessage, auditor.phoneNumber, false);
        }

        if (smsResult && smsResult.isSuccess) {
            return result;
        } else {
            result = formatResultFromError(smsResult);
            return result;
        }
    } else {
        result = formatResultForMissingPhoneNumber();
        return result;
    }
}

export async function sendRSPContactCardInfo(auditor: Auditor, event: Event) {
    let result: Result = {isSuccess: true, type: "SMS", result: null, message: "Successfully sent the messages!"};

    const contactCardURL = "https://rspbucket110446-prod.s3.amazonaws.com/public/RingSide_Pro.vcf";
    const message = "RingSide Pro: Use this contact card to save the RingSide Pro contact in your phone."

    // In prod, set isTest to false
    if (auditor?.phoneNumber) {
        let smsResult: any;

        // If it is a Coveside Stables show (Canada), use the toll free number
        if (event.organizationId === constants.Coveside_ORGANIZATION.id || event?.id === constants.European_Classic_Event_Id) {
            smsResult = await handleSendCanadianSMS(message, auditor.phoneNumber, false, contactCardURL);
        } else {
            smsResult = await handleSendSMS(message, auditor.phoneNumber, false, contactCardURL);
        }
        
        if (smsResult && smsResult.isSuccess) {
            return result;
        } else {
            result = formatResultFromError(smsResult);
            return result;
        }
    } else {
        result = formatResultForMissingPhoneNumber();
        return result;
    }
}

export async function sendGITNBWelcomeTexts(auditor: Auditor) {
    let result: Result = {isSuccess: true, type: "SMS", result: null, message: "Successfully sent the messages!"};

    let initialMessage = "Green Is the New Blue: Welcome! Msg and data rates may apply. Reply HELP for help, STOP to unsubscribe, START to resubscribe.";
    let welcomeMessage = "Welcome to Green ProTips! 🌎 Thanks for showing up to be a part of the movement. ♻️ Each month, we'll send you a tip or action item related to sustainability and horse sports. Follow us on IG (https://ringside.cc/GITNB-Insta.) to keep up with the work we're doing and join in. Let's make strides toward a more sustainable sport! 🐴";

    // In prod, set isTest to false
    if (auditor?.phoneNumber) {
        const smsResult = await handleSendGITNBSMS(initialMessage, auditor.phoneNumber, false);
        await handleSendGITNBSMS(welcomeMessage, auditor.phoneNumber, false);

        // Send the contact info to people to save in their phone's contacts
        await sendGITNBContactCardInfo(auditor);

        if (smsResult.isSuccess) {
            return result;
        } else {
            result = formatResultFromError(smsResult);
            return result;
        }
    } else {
        result = formatResultForMissingPhoneNumber();
        return result;
    }
}

export async function sendGITNBContactCardInfo(auditor: Auditor) {
    let result: Result = {isSuccess: true, type: "SMS", result: null, message: "Successfully sent the messages!"};

    const contactCardURL = "https://rspbucket110446-prod.s3.amazonaws.com/public/Green_Alerts.vcf";
    const message = "Green Is the New Blue: Use this contact card to save our contact in your phone for future use."

    // In prod, set isTest to false
    if (auditor?.phoneNumber) {
        const smsResult = await handleSendGITNBSMS(message, auditor.phoneNumber, false, contactCardURL);
        if (smsResult.isSuccess) {
            return result;
        } else {
            result = formatResultFromError(smsResult);
            return result;
        }
    } else {
        result = formatResultForMissingPhoneNumber();
        return result;
    }
}

export async function sendOrganizationWelcomeTexts(auditor: Auditor, organization: Organization, isAdminView?: Boolean) {
    let result: Result = {isSuccess: true, type: "SMS", result: null, message: "Successfully sent the messages!"};

    let initialMessage = "RingSide Pro: Welcome! Msg and data rates may apply. Reply HELP for help, PAUSE to stop texts for this event, STOP to unsubscribe forever, START to resubscribe.";
    let welcomeMessage;
    if (organization && organization.name && isAdminView) {
        welcomeMessage = "RingSide Pro: Staff subscribed you to text alerts from " + organization.name + "! You can adjust your settings at ringsidepro.com.";
    } else if (organization && organization.name) {
        welcomeMessage = "RingSide Pro: Thank you for subscribing to text alerts from " + organization.name + "! You can adjust your settings at ringsidepro.com.";
    } else welcomeMessage = "RingSide Pro: Thank you for subscribing to text alerts! You can adjust your settings at ringsidepro.com.";
    
    // TO DO: Once organization can set 2-way messaging
    // if (organization && organization.organizationOptions && organization.organizationOptions.acceptsIncomingTextAlerts) {
    //     welcomeMessage = welcomeMessage + " This event has 2-way messaging. Respond to ask questions to the event staff.";
    // } else {
    //     welcomeMessage = welcomeMessage + " Please note, these are automated messages.";
    // }

    // In prod, set isTest to false
    if (auditor?.phoneNumber) {
        const smsResult = await handleSendOrgSMS(initialMessage, auditor.phoneNumber, false);
        await handleSendOrgSMS(welcomeMessage, auditor.phoneNumber, false);

        if (smsResult.isSuccess) {
            return result;
        } else {
            result = formatResultFromError(smsResult);
            return result;
        }
    } else {
        result = formatResultForMissingPhoneNumber();
        return result;
    }
}

export async function sendOrganizationUpdateTexts(auditor: Auditor, event: Event, isAdminView?: Boolean) {
    let result: Result = {isSuccess: true, type: "SMS", result: null, message: "Successfully sent the messages!"};

    let updateMessage;
    if (event && event.name && isAdminView) updateMessage = "Show staff updated your alert settings for " + event.name + "!";
    else if (event && event.name) updateMessage = "You successfully updated your alert settings for " + event.name + "!";
    else updateMessage = "Thank you for updating your alerts via the RingSide Pro system!";

    // In prod, set isTest to false
    if (auditor?.phoneNumber) {
        const smsResult = await handleSendOrgSMS(updateMessage, auditor.phoneNumber, false);
        if (smsResult.isSuccess) {
            return result;
        } else {
            result = formatResultFromError(smsResult);
            return result;
        }
    } else {
        result = formatResultForMissingPhoneNumber();
        return result;
    }
}