import { IonButton, IonCol, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Select from 'react-select';
import { PersonContext } from "../../context/PersonContext";
import { onCreateEvent, onUpdateEvent } from "../../graphql/subscriptions";
import { Event, EventAuditor, Person } from "../../models";
import { formatStartEndDatesStrings } from "../../utilities/dates/FormatDates";
import { getEventAuditorsByEventId } from "../../utilities/eventAuditor/EventAuditor";
import { getAllEvents } from "../../utilities/events/Event";
import { generateAdminEventAuditorReport } from "../../utilities/reports/AlertsSubscriberReport";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import ErrorAlert from "../Errors/ErrorAlert";

interface formattedOption {
    label: string
    value: string
    event: Event
}

const AdminEventAuditorInfo: React.FC = () => {
    const user = useContext(PersonContext);

    const eventSubscription = useSubscriptionByItself({
        config: {
            query: onCreateEvent,
            key: "onCreateEvent"
        }
    });

    const eventUpdateSubscription = useSubscriptionByItself({
        config: {
            query: onUpdateEvent,
            key: "onUpdateEvent"
        }
    });

    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    const [currentUpdateSubscriptionItem, setUpdateCurrentSubscriptionItem] = useState<any>();
    const [events, setEvents] = useState<Event[] | null | undefined>();
    const [formattedEvents, setFormattedEvents] = useState<formattedOption[] | null | undefined>();
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [contacts, setContacts] = useState("");
    const [people, setPeople] = useState<Person[] | null | undefined>();
    const [error, setError] = useState("");

    const getEvents = async () => {
        const queryResult = await getAllEvents();
        if (queryResult.isSuccess) {
            const eventList = queryResult.result;
            setEvents(eventList);  
            formatEvents(eventList);          
        }
    };

    const formatEvents = (eventList: Event[]) => {
        let formattedEvents = [];
        for (var i = 0; i < eventList.length; i++) {
            const event = eventList[i];
            if (event) {
                const label = event.name + ": " + (event.startDate ? formatStartEndDatesStrings(event.startDate, event.endDate) : "");
                let object: formattedOption = {
                    value: event.id,
                    label: label,
                    event: event as Event
                };
                formattedEvents.push(object);
            }
        }
        setFormattedEvents(formattedEvents);
    }

    const findValueInList = (value: string, eventList?: formattedOption[]) => {
        let optionArray = eventList || formattedEvents;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        getEvents();
    }, []);

    useEffect(() => {
        if (eventSubscription && eventSubscription[0] !== undefined && eventSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(eventSubscription[0]);
            getEvents();
        }
    }, [eventSubscription]);

    useEffect(() => {
        if (eventUpdateSubscription && eventUpdateSubscription[0] !== undefined && eventUpdateSubscription[0] !== currentUpdateSubscriptionItem) {
            setCurrentSubscriptionItem(eventUpdateSubscription[0]);
            getEvents();
        }
    }, [eventUpdateSubscription]);


    const handleOnChange = async (event: any) => {
        if (event && event.event) {
            setCurrentValue(event);
            await getEventAuditorsBySelectedEvent(event.event);
        } else {
            setCurrentValue(undefined);
        }
    }

    const getEventAuditorsBySelectedEvent = async (selectedEvent: Event) => {
        const queryResult = await getEventAuditorsByEventId(selectedEvent.id);
        if (queryResult.isSuccess) {
            const eventAuditors: EventAuditor[] = queryResult.result;
            if (eventAuditors && eventAuditors.length > 0) {
                let contactString = "";
                let peopleArray: Person[] = [];
                for (let i = 0; i < eventAuditors.length; i++) {
                    const auditor = eventAuditors[i];
                    if (auditor.person) {
                        contactString = contactString + auditor.person.email + ",";
                        peopleArray.push(auditor.person);
                    }
                }
                setContacts(contactString);
                setPeople(peopleArray);
            }
        } 
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            <IonRow className="ion-justify-content-center">
                <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                    <p>Select an event</p>
                    <Select
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        // defaultValue={currentValue}
                        value={currentValue}
                        menuPortalTarget={document.body}
                        isClearable
                        options={formattedEvents!}
                        onChange={handleOnChange}
                    />
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                    <p>Email addresses of event auditors: </p>
                    <p>{contacts}</p>
                </IonCol>
            </IonRow>
            {(currentValue?.event && people) && (
                <>
                    <hr />
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                            <p>Download CSV of email addresses </p>
                            <IonButton color="tertiary" onClick={() => generateAdminEventAuditorReport(currentValue.event, people)}>Download</IonButton>
                        </IonCol>
                    </IonRow>
                </>
            )}
        </>
    );
};

export default AdminEventAuditorInfo;
