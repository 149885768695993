import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import React, { useState } from "react";
import DisplayEventTable from "../../../components/Event/DisplayEventTable";
import { useHistory } from "react-router";
import EventSearchBar from "../../../components/Search/EventSearchBar";
import { AlgoliaEvent } from "../../../interfaces/AlgoliaEvent";
import PaginationButtons from "../../../components/Search/PaginationButtons";

const EventSelectionPage: React.FC = () => {
    const history = useHistory();

    const [eventsList, setEventsList] = useState<AlgoliaEvent[] | null | undefined>();
    const [selectedFilter, setSelectedFilter] = useState<"past" | "current" | "future" | "all">("all");
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const handleSearchResults = (results: AlgoliaEvent[]) => {
        setEventsList(results);
    };

    const handlePageReset = (page: number, totalPages: number) => {
        setCurrentPage(page);
        setTotalPages(totalPages);
    };

    const handlePageSelection = (page: number) => {
        setCurrentPage(page);
    };

    const handleSelectedEvent = (event: AlgoliaEvent) => {
        const path = "/index/event/about/" + event.objectID;
        history.push(path);
    };

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="All Events" />
                <EventSearchBar selectedPage={currentPage} onChangePage={handlePageReset} selectedFilter={selectedFilter} onSearch={handleSearchResults} />
                <IonRow>
                    <IonCol>
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Select An Event</IonCardTitle>
                            <IonCardContent>
                                <DisplayEventTable eventsList={eventsList} onFilter={setSelectedFilter} onSelect={handleSelectedEvent} />
                                <PaginationButtons totalPageCount={totalPages} currentPage={currentPage} onSelectPage={handlePageSelection} />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EventSelectionPage;