import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, EventJudge, HorseShow } from "../../../models";
import { getHorseShowByEventId } from "../../../utilities/horseShow/HorseShow";
import ErrorAlert from "../../Errors/ErrorAlert";
import JudgeForm from "../../EventJudge/EventJudgeForm";
import Spinner from "../../Spinners/Spinner";
import { close } from "ionicons/icons";
import { deleteEventJudge, getEventJudgesByEventId } from "../../../utilities/eventJudge/EventJudge";

interface _Props {
    event: Event
}

const EventJudgesForm: React.FC<_Props> = ({event}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [horseShow, setHorseShow] = useState<HorseShow | null | undefined>();
    const [eventJudges, setEventJudges] = useState<(EventJudge | null)[] | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getHorseShow(eventId: string) {
            const queryResult = await getHorseShowByEventId(eventId);
            if (queryResult.isSuccess) {
                setHorseShow(queryResult.result);
            }
        }

        async function getJudges(eventId: string) {
            const queryResult = await getEventJudgesByEventId(eventId);
            if (queryResult.isSuccess) {
                const judges: EventJudge[] = queryResult.result;
                const sorted = judges.sort((a, b) => (a.createdAt || "").localeCompare(b.createdAt || ""));
                setEventJudges(sorted);
            }
        }

        if (event) {
            getHorseShow(event.id);
            getJudges(event.id);
        }
    }, [event]);

    const handleSubmit = async (newJudge: EventJudge) => {
        setIsLoading(true);
        if (horseShow) {
            setError("");
            let judgeArray: (EventJudge | null)[] = [];
            if (eventJudges && eventJudges.length) {
                judgeArray = eventJudges.concat([newJudge]);
            } else {
                judgeArray.push(newJudge);
            }
            setEventJudges(judgeArray);
        } else {
            setError("Error: no horse show found. Please contact hello@ringsidepro.com");
        }
        setIsLoading(false);
        setShowModal(false);
    }

    const handleDelete = async (eventJudge?: EventJudge) => {
        if (eventJudge) {
            let newJudgeArray = [];
            if (horseShow && eventJudges && eventJudges.length) {
                for (var i = 0; i < eventJudges?.length; i++) {
                    const current = eventJudges[i];
                    if (current && eventJudge) {
                        if (current.name !== eventJudge.name) {
                            newJudgeArray.push(current);
                        }
                    }
                }
                setEventJudges(newJudgeArray);
                const deleteResult = await deleteEventJudge({id: eventJudge?.id});
                if (!deleteResult.isSuccess) setError("Error: could not remove judge from event. Please refresh the page and try again.");
            }
        } else {
            setError("Error: could not find the judge. Please refresh the page and try again.");
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {eventJudges && eventJudges.length ?
                <>
                    <IonRow>
                        <IonCol>
                            <IonList className="bg-white">
                                {eventJudges.map((judge, index) => (
                                    <IonItem key={index}>
                                        <IonLabel><h3 className="ion-text-wrap text-darker">{judge && judge.name}</h3></IonLabel>
                                        <IonButton slot="end" color="danger" onClick={() => handleDelete(judge as EventJudge)}><IonIcon icon={close}/></IonButton>
                                    </IonItem>
                                ))}
                            </IonList>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                Add Judge
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                Add Judge
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
            <IonModal backdropDismiss={false} isOpen={showModal} id="addEventJudgeModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Judge
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeJudgeModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <JudgeForm onSubmit={handleSubmit} event={event} />
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventJudgesForm;