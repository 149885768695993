import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, EventEntry, StablingRequest } from "../../models";
import { isWindows } from "../../utilities/platform/Platform";
import StablingRequestInfoForm from "./StablingRequestInfoForm";

interface _Props {
    event: Event
    entry?: EventEntry
    stablingRequest?: StablingRequest
    onSubmit: Function
}

const StablingCard: React.FC<_Props> = ({stablingRequest, event, entry, onSubmit}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    useEffect(() => {
        if (stablingRequest) setIsCollapsed(false);
    }, [stablingRequest]);

    const getCardTitle = () => {
        if (stablingRequest) {
            if (stablingRequest?.status === "in_progress") {
                return (
                    <>
                        <IonRow><h2>{stablingRequest.stallType?.name}</h2> <h6 className="pl-2 text-warning">(status: saved - need to submit)</h6></IonRow>
                    </>
                );
            }
            else if (stablingRequest?.status === "submitted") {
                return (
                    <>
                        <IonRow><h2>{stablingRequest.stallType?.name}</h2> <h6 className="pl-2 text-success">(status: saved and submitted)</h6></IonRow>
                    </>
                );
            }
            else if (stablingRequest?.status === "complete") {
                return (
                    <>
                        <IonRow><h2>{stablingRequest.stallType?.name}</h2> <h6 className="pl-2 text-success">(status: accepted)</h6></IonRow>
                    </>
                );
            }
            else if (stablingRequest?.status === "declined") {
                return (
                    <>
                        <IonRow><h2>{stablingRequest.stallType?.name}</h2> <h6 className="pl-2 text-danger">(status: declined)</h6></IonRow>
                        <IonRow><h6 className="text-danger">{stablingRequest.statusNote}</h6></IonRow>
                    </>
                );
            }
            else {
                return (
                    <>
                        <IonRow><h2>{stablingRequest.stallType?.name}</h2> <h6 className="pl-2 text-danger">(status: error - no saved status)</h6></IonRow>
                        <IonRow><h6 className="text-danger">{stablingRequest.statusNote}</h6></IonRow>
                    </>
                );
            }
        } else {
            return <h2>New Stabling Request</h2>;
        }
    }

    const handleRequestSubmit = (stablingRequest: StablingRequest) => {
        setIsCollapsed(true);
        onSubmit(stablingRequest);
    }

    return (
        <>
            <IonCard>
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {getCardTitle()}
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent className="bg-white">
                        {(isWindows() || (isPlatform("desktop"))) ?
                            <StablingRequestInfoForm stablingRequest={stablingRequest} event={event} entry={entry} onSubmit={onSubmit} />
                            :
                            <p>Cannot complete stabling requests on mobile devices.</p>
                        }
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default StablingCard;