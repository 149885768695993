import {
    IonContent,
    IonPage,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../../../../components/Headers/Header";
import { Organization } from "../../../../../models";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { PersonContext } from "../../../../../context/PersonContext";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import OrganizationMainPointTable from "../../../../../components/PointTracking/OrganizationMainPointTable";

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventOrganizationViewPointsPage: React.FC<OrganizationPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [organization, setOrganization] = useState<Organization>();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    async function getOrganization() {
        try {
            setIsLoading(true);
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                const organization: Organization = queryResult.result;
                setOrganization(organization);                
                setIsLoading(false);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
                setIsLoading(false);
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOrganization();
    }, [user, match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Organization Points" />
                {organization && <OrganizationMainPointTable organizationId={organization.id} />}
            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationViewPointsPage;