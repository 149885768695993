import { 
    IonAvatar
} from "@ionic/react";

import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { Person } from "../../models";

let profilePicturePersonSubscription: any;

interface _Props {
    hasLink?: boolean
    pictureURL?: string
}

const ProfilePicture: React.FC<_Props> = ({hasLink, pictureURL}) => {
    const user = useContext(PersonContext);
    
    const [profilePicture, setProfilePicture] = useState<string | null | undefined>(undefined);

    useEffect(() => {
        if (pictureURL) setProfilePicture(pictureURL);
    }, [pictureURL]);

    useEffect(() => {
        function setProfilePictureInState() {
            if (user.pictureURL) setProfilePicture(user.pictureURL);
        }

        function setProfilePictureSubscription() {
            // profilePicturePersonSubscription = DataStore.observe(Person).subscribe(msg => {
            //     setProfilePicture(msg.element.pictureURL || undefined);
            // });
        }

        if (user) setProfilePictureSubscription();

        setProfilePictureInState();

        return () => {
            if (profilePicturePersonSubscription) profilePicturePersonSubscription.unsubscribe();
        }
    }, [user, user.id, user.pictureURL]);

    return (
        <>
            {hasLink ?
                <a href="/index/settings/profile">
                    <IonAvatar 
                        className="img-center profile-img"
                        onClick={
                            (e: any) => {
                                e.persist();
                            }
                        }
                    >
                        <img alt="profile" src={profilePicture ? profilePicture : "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjYzVkYmZmIiBkPSJNMCAwaDUxMnY1MTJIMHoiLz48cGF0aCBkPSJNMjU2IDMwNGM2MS42IDAgMTEyLTUwLjQgMTEyLTExMlMzMTcuNiA4MCAyNTYgODBzLTExMiA1MC40LTExMiAxMTIgNTAuNCAxMTIgMTEyIDExMnptMCA0MGMtNzQuMiAwLTIyNCAzNy44LTIyNCAxMTJ2NTZoNDQ4di01NmMwLTc0LjItMTQ5LjgtMTEyLTIyNC0xMTJ6IiBmaWxsPSIjODJhZWZmIi8+PC9zdmc+"} />
                    </IonAvatar>
                </a>
                :    
                <IonAvatar 
                    className="img-center profile-img"
                    onClick={
                        (e: any) => {
                            e.persist();
                        }
                    }
                >
                    <img alt="profile" src={profilePicture ? profilePicture : "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjYzVkYmZmIiBkPSJNMCAwaDUxMnY1MTJIMHoiLz48cGF0aCBkPSJNMjU2IDMwNGM2MS42IDAgMTEyLTUwLjQgMTEyLTExMlMzMTcuNiA4MCAyNTYgODBzLTExMiA1MC40LTExMiAxMTIgNTAuNCAxMTIgMTEyIDExMnptMCA0MGMtNzQuMiAwLTIyNCAzNy44LTIyNCAxMTJ2NTZoNDQ4di01NmMwLTc0LjItMTQ5LjgtMTEyLTIyNC0xMTJ6IiBmaWxsPSIjODJhZWZmIi8+PC9zdmc+"} />
                </IonAvatar>
            }
        </>
    );
};

export default ProfilePicture;
