import React, { useEffect, useState } from "react";
import { ECClass } from "../../interfaces/ECData";
import { getECClasses } from "../../utilities/ECAPI/ECClasses";
import BasicSelect from "../Select/BasicSelect";

interface _Props {
    menuPlacement?: string
    selectedValue?: string
    onSelect: Function
}

interface formattedOption {
    label: string
    value: string
    object: ECClass
}

const SelectECClass: React.FC<_Props> = ({selectedValue, menuPlacement, onSelect}) => {

    const [formattedClasses, setFormattedClasses] = useState<formattedOption[] | null | undefined>();
    const [selectedLabel, setSelectedLabel] = useState<string | null | undefined>();

    const formatClasses = (classList: ECClass[]) => {
        let formattedClasses = [];
        for (var i = 0; i < classList.length; i++) {
            let object = {
                value: classList[i].ClassID ? classList[i].ClassID.toString() : "",
                label: classList[i].ClassDescription,
                object: classList[i]
            };
            formattedClasses.push(object);
        }
        setFormattedClasses(formattedClasses);
    }

    useEffect(() => {
        const getECClassList = async () => {
            const queryResult = await getECClasses();
            if (queryResult.isSuccess) {
                const list: ECClass[] = queryResult.result;
                formatClasses(list);
            } 
        }
        async function getClasses() {
            await getECClassList();
        }
        getClasses();
    }, []);

    useEffect(() => {
        if (selectedValue) setSelectedLabel(selectedValue);
        else setSelectedLabel("");
    }, [selectedValue]);

    const handleOnChange = (event: ECClass) => {
        if (event) {
            setSelectedLabel(event.ClassID.toString())
            onSelect(event);
        }
    }

    return (
        <>
            {formattedClasses ?
                <BasicSelect formattedValues={formattedClasses} selectedValue={selectedLabel} menuPlacement={menuPlacement} onSelect={handleOnChange}/>
                :
                <p>Loading options...</p>
            }
        </>
    );
};

export default SelectECClass;
