import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps, useHistory } from "react-router";
import { Team } from "../../../../models";
import { getTeamById } from "../../../../utilities/team/Team";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import Spinner from "../../../../components/Spinners/Spinner";
import ViewTeamMembersCoachesTable from "../../../../components/TeamMember/ViewTeamMemberCoachesTable";

interface TeamPageProps extends RouteComponentProps<{
    id: string;
}> {}

const TeamCoachesPage: React.FC<TeamPageProps> = ({match}) => {
    const history = useHistory();

    const [team, setTeam] = useState<Team>();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function getTeam() {
            setIsLoading(true);
            const queryResult = await getTeamById(match.params.id);
            if (queryResult.isSuccess) {
                setTeam(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
            setIsLoading(false);
        }

        getTeam();
    }, [match, match.params.id]);
    
    const handleSelectTeamCoach = (teamMemberId: string) => {
        const path = "/index/team/roster/coach/" + teamMemberId;
        history.push(path);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={team?.name || "Team Roster"} />
                {error && <ErrorAlert width="12" error={error} />}
                {isLoading ?
                    <Spinner />
                    :
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            {team ?
                                <ViewTeamMembersCoachesTable team={team} onSelect={handleSelectTeamCoach} />
                                :
                                <p>No team found.</p>
                            }
                        </IonCol>
                    </IonRow>
                }
            </IonContent>
        </IonPage>
    );
};

export default TeamCoachesPage;