export function formatRiderNamesToString(nameArray: string[]): string {
    let result = "";

    for (let i = 0; i < nameArray.length; i++) {
        const name = nameArray[i];
        result = result + name;
        if (i !== (nameArray.length-1)) result = result + ", ";
    }

    return result;
}