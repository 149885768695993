import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonLabel,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps, useHistory } from "react-router";
import { BarnMember, Horse } from "../../../../models";
import { getHorseById, updateHorse } from "../../../../utilities/horse/Horse";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import EditHorseBasicInfoForm from "../../../../components/Horse/EditHorse/EditHorseBasicInfoForm";
import EditHorseColorForm from "../../../../components/Horse/EditHorse/EditHorseColorForm";
import EditHorseOwnerForm from "../../../../components/Horse/EditHorse/EditHorseOwnerInfoForm";
import EditHorseIdentificationForm from "../../../../components/Horse/EditHorse/EditHorseIdentificationForm";
import EditHorseExperienceForm from "../../../../components/Horse/EditHorse/EditHorseExperienceForm";
import EditHorseMembershipsTable from "../../../../components/Memberships/EditHorseMembershipsTable";
import HorseMembershipForm from "../../../../components/Memberships/HorseMembershipForm";
import EditHorseCogginsForm from "../../../../components/Horse/EditHorse/EditHorseCogginsForm";
import moment from "moment";
import { checkmarkCircle } from "ionicons/icons";
import HorseResultsTable from "../../../../components/Horse/HorseResultsTable";
import { PersonContext } from "../../../../context/PersonContext";
import { getBarnMemberByBarnIdPersonId } from "../../../../utilities/barnMember/BarnMember";
import { UpdateHorseInput } from "../../../../API";

interface HorsePageProps extends RouteComponentProps<{
    id: string;
}> {}

const EditHorsePage: React.FC<HorsePageProps> = ({match}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [barnMember, setBarnMember] = useState<BarnMember | undefined | null>();
    const [horse, setHorse] = useState<Horse>();
    const [currentSegment, setCurrentSegment] = useState("basic");
    const [error, setError] = useState("");

    async function getBarnMember(barnId: string) {
        const queryResult = await getBarnMemberByBarnIdPersonId(barnId, user.id);
        if (queryResult.isSuccess) {
            setBarnMember(queryResult.result);
        }
    }

    async function getHorse() {
        const queryResult = await getHorseById(match.params.id);
        if (queryResult.isSuccess) {
            const horse: Horse = queryResult.result;
            setHorse(horse);
            if (horse && horse.barnId) getBarnMember(horse.barnId);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    useEffect(() => {
        getHorse();
    }, [match, match.params.id]);

    async function removeHorseFromBarn() {
        setError("");
        if (horse) {
            const input: UpdateHorseInput = {
                id: horse.id,
                barnId: "",
                barnName: "",
                // horseBarnId: ""
            };
            const updateResult = await updateHorse(input);
            if (updateResult.isSuccess) {
                history.goBack();
            } else {
                setError("Could not update the horse's barn info.");
            }
        }
    }

    async function removeHorse() {
        setError("");
        if (horse) {
            const input: UpdateHorseInput = {
                id: horse.id,
                personId: "",
                barnId: "",
                barnName: "",
                // horseBarnId: ""
            };
            const updateResult = await updateHorse(input);
            if (updateResult.isSuccess) {
                history.goBack();
            } else {
                setError("Could not remove the horse's info.");
            }
        } else {
            setError("No horse found.");
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={horse ? horse.name : "Edit Horse"} />
                {error && <ErrorAlert width="12" error={error} />}
                <IonSegment scrollable mode="md" value={currentSegment} onIonChange={e => setCurrentSegment(e.detail.value || "basic")}>
                    <IonSegmentButton value="basic" >
                        <IonLabel>About</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="coggins">
                        <IonLabel>Coggins</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="ids">
                        <IonLabel>ID Numbers</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="connections">
                        <IonLabel>Connections</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="results">
                        <IonLabel>Results</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                {(currentSegment === "basic" && horse) && (
                    <> 
                        <IonRow className="ion-justify-content-center" id="basic">
                            <IonCol sizeSm="12" sizeMd="8">
                                <IonCard color="white" className="ion-padding">
                                    <IonCardTitle>
                                        <h3>Basic Info</h3>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EditHorseBasicInfoForm horse={horse} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center" id="color">
                            <IonCol sizeSm="12" sizeMd="8">
                                <IonCard color="white" className="ion-padding">
                                    <IonCardTitle>
                                        <h3>Color</h3>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EditHorseColorForm horse={horse}/>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center" id="experience">
                            <IonCol sizeSm="12" sizeMd="8">
                                <IonCard color="white" className="ion-padding">
                                    <IonCardTitle>
                                        <h3>Experience</h3>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EditHorseExperienceForm horse={horse}/>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        {((horse && horse.barnId && horse.personId === user.id) || (barnMember && barnMember.permissionLevel === "admin")) && (
                            <IonRow className="ion-justify-content-center" id="experience">
                                <IonCol sizeSm="12" sizeMd="8">
                                    <IonCard color="white" className="ion-padding">
                                        <IonCardTitle>
                                            <h3>Remove From Barn</h3>
                                        </IonCardTitle>
                                        <IonCardContent>
                                            <p className="ion-text-wrap description">If you remove this horse from its current barn, the horse will still exist. The person who created the horse will still be able to access it.</p>
                                            <hr />
                                            <p>Are you sure you want to remove {horse.name}{horse.nickname ? " (" + horse.nickname + ")" : ""} from {horse.barnName}?</p>
                                            <IonRow>
                                                <IonCol className="ion-text-center">
                                                    <IonButton color="warning" onClick={() => removeHorseFromBarn()}>
                                                        Remove From Barn
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        )}
                        {((horse && horse.personId === user.id)) && (
                            <IonRow className="ion-justify-content-center" id="experience">
                                <IonCol sizeSm="12" sizeMd="8">
                                    <IonCard color="white" className="ion-padding">
                                        <IonCardTitle>
                                            <h3>Delete Horse</h3>
                                        </IonCardTitle>
                                        <IonCardContent>
                                            <p className="ion-text-wrap description">If you remove this horse, you will lose access to the horse's data including it's horse show entries and results.</p>
                                            <hr />
                                            <p>Are you sure you want to permanently remove {horse.name}{horse.nickname ? " (" + horse.nickname + ")" : ""}?</p>
                                            <IonRow>
                                                <IonCol className="ion-text-center">
                                                    <IonButton color="danger" onClick={() => removeHorse()}>
                                                        Delete Horse
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        )}
                    </>
                )}
                {(currentSegment === "coggins" && horse) && (
                    <> 
                        <IonRow className="ion-justify-content-center" id="ids">
                            <IonCol sizeSm="12" sizeMd="8">
                                <IonCard color="white" className="ion-padding">
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol>
                                                <h3>
                                                    Coggins
                                                    {(horse.coggins && moment(new Date()).isBefore(moment(horse.cogginsDate).add(1, "year"))) ? 
                                                        <IonIcon icon={checkmarkCircle} color="success" /> 
                                                    : 
                                                        <p className="text-danger">To Do</p>
                                                    }
                                                </h3> 
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EditHorseCogginsForm horse={horse} onSubmit={getHorse}/>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {(currentSegment === "ids" && horse) && (
                    <> 
                        <IonRow className="ion-justify-content-center" id="ids">
                            <IonCol sizeSm="12" sizeMd="8">
                                <IonCard color="white" className="ion-padding">
                                    <IonCardTitle>
                                        <h3>ID Numbers</h3>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EditHorseIdentificationForm horse={horse}/>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center" id="ids">
                            <IonCol sizeSm="12" sizeMd="8">
                                <IonCard color="white" className="ion-padding">
                                    <IonCardTitle>
                                        <h3>Add Membership</h3>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <HorseMembershipForm horse={horse} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center" id="ids">
                            <IonCol sizeSm="12" sizeMd="8">
                                <EditHorseMembershipsTable horse={horse} />
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {(currentSegment === "connections" && horse) && (
                    <> 
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <IonCard color="white" className="ion-padding">
                                    <IonCardTitle>
                                        <h3>Owner</h3>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <EditHorseOwnerForm horse={horse}/>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <IonCard color="white" className="ion-padding">
                                    <IonCardTitle>
                                        <h3>Barn</h3>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <h4>{horse.barnName}</h4>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {(currentSegment === "results" && horse) && (
                    <> 
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <IonCard color="white" className="ion-padding">
                                    <IonCardTitle>
                                        <h3>Results</h3>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <HorseResultsTable horse={horse} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EditHorsePage;