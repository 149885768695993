import React, { useEffect, useState } from "react";
import Spinner from "../Spinners/Spinner";
import ErrorAlert from "../Errors/ErrorAlert";
import { getPostsByOrganizationIdStatus } from "../../utilities/posts/Post";
import { Post, PostStatus } from "../../models";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { Organization } from "../../models";
import DashboardPost from "./DashboardPost";

interface _Props {
    organization: (Organization | null)
}

const DashboardPosts: React.FC<_Props> = ({organization}) => {
    const [posts, setPosts] = useState<Post[]>([]);
    const [post, setPost] = useState<Post | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");
    const [counter, setCounter] = useState<number>(0);

    async function getPosts(id: string) {
        setIsLoading(true);
        setError("");
        const queryResult = await getPostsByOrganizationIdStatus(id, PostStatus.PUBLISHED);
        if (queryResult.isSuccess) {
            setPosts(queryResult.result);
            setCounter(0);
        } else {
            setError(queryResult.message);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (organization) {
            getPosts(organization.id);
        }
    }, [organization]);

    useEffect(() => {
        setPost(posts[counter]);
    }, [posts]);

    useEffect(() => {
        setPost(posts[counter]);
    }, [counter]);

    const handleForwardClick = () => {
        setCounter(counter + 1);
    };

    const handleBackwardClick = () => {
        setCounter(counter - 1);
    };

    return (
        <React.Fragment>
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    {posts && posts.length ? (
                        <>
                            {counter !== 0 &&
                                <IonIcon
                                    id="post-back-btn"
                                    className="post-backward-icon"
                                    icon={chevronBackOutline}
                                    size="large"
                                    onClick={handleBackwardClick}
                                />
                            }
                            <DashboardPost selectedPost={post}/>
                            {counter !== posts.length - 1 &&
                                <IonIcon
                                    id="post-next-btn"
                                    className="post-forward-icon"
                                    icon={chevronForwardOutline}
                                    size="large"
                                    onClick={handleForwardClick}
                                />
                            }
                        </>
                    ) : (
                        <p>There are currently no posts.</p>
                    )}
                </>
            )}
        </React.Fragment>
    );
};

export default DashboardPosts;
