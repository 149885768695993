import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React, { useContext, useEffect, useState } from "react";
import { EventEntry } from "../../../../models";
import { PersonContext } from "../../../../context/PersonContext";
import { useHistory } from "react-router";
import { getEventEntriesByPersonId } from "../../../../utilities/eventEntry/EventEntry";
import { getEventById } from "../../../../utilities/events/Event";
import moment from "moment";
import { sortEventEntriesByUpdatedDate } from "../../../../utilities/eventEntry/SortEventEntry";
import Spinner from "../../../../components/Spinners/Spinner";

interface formattedEntry {
    entryId: string
    eventName?: string
    horseName: string
    status: string
    dateUpdated: string
}

const UserEntriesPage: React.FC = () => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [formattedEntries, setFormattedEntries] = useState<formattedEntry[] | null | undefined>();

    const formatEntries = async (entries: EventEntry[]) => {
        const sortedEntries = sortEventEntriesByUpdatedDate(entries);
        const entryList = sortedEntries || entries;
        let result: formattedEntry[] = [];
        for (let i = 0; i < entryList.length; i++) {
            const currentEntry = entryList[i];

            let formattedEntry: formattedEntry = {
                entryId: currentEntry.id,
                horseName: currentEntry.horseName || "Unknown",
                status: currentEntry.status,
                dateUpdated: moment(currentEntry.updatedOn).format("MMM DD, YYYY")
            };

            const eventQueryResult = await getEventById(currentEntry.eventId);
            if (eventQueryResult.isSuccess) {
                formattedEntry["eventName"] = eventQueryResult.result.name;
            }

            result.push(formattedEntry);
        }

        setFormattedEntries(result);
        setIsLoading(false);
    }

    const getEventAuditors = async () => {
        setIsLoading(true);
        const queryResult = await getEventEntriesByPersonId(user.id);
        if (queryResult.isSuccess) {
            const entryList = queryResult.result
            formatEntries(entryList)
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getEventAuditors();
    }, []);

    const navigateToPage = (id: string) => {
        const path = "/index/event/entry/" + id;
        history.push(path);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Your Entries" />
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Your Entries</IonCardTitle>
                            <IonCardSubtitle>
                                <IonRow>
                                    <IonCol>
                                        All of the entries you've created.
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        Click on a row to adjust the settings.
                                    </IonCol>
                                </IonRow>
                            </IonCardSubtitle>
                            {isLoading ?
                                <Spinner />
                                :
                                <IonCardContent>
                                    {(formattedEntries && formattedEntries.length > 0) ? 
                                        <IonList className="bg-white">
                                            {formattedEntries.map((formattedEntry, index) => (
                                                <IonItem key={index} onClick={() => navigateToPage(formattedEntry.entryId)}>
                                                    <IonLabel>
                                                        <IonRow>
                                                            <IonCol sizeXs="12" sizeMd="3">
                                                                <p className="ion-text-wrap">{formattedEntry.eventName || "Unknown Event"}</p>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="3">
                                                                <p className="ion-text-wrap">{formattedEntry.horseName}</p>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="3">
                                                                <p className="ion-text-wrap">Updated: {formattedEntry.dateUpdated}</p>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="3">
                                                                <p className="ion-text-wrap">
                                                                    <span className={formattedEntry.status === "complete" ? "text-success" : (formattedEntry.status === "in_progress" ? "text-warning" : (formattedEntry.status === "declined") ? "text-danger" : "text-primary")}>
                                                                        {formattedEntry.status === "complete" ? "accepted" : (formattedEntry.status === "in_progress" ? "in progress" : (formattedEntry.status === "declined") ? "declined" : "submitted")}
                                                                    </span>
                                                                </p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonLabel>
                                                </IonItem>
                                            ))}
                                        </IonList>
                                        :
                                        <p>None found.</p>
                                    }
                                </IonCardContent>
                            }
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default UserEntriesPage;