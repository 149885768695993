import { API } from "aws-amplify";
import { Result } from "../../interfaces/Result";

export async function sendNewContactUsMessageEmail(firstName: string, lastName: string, email: string, message: string) {
    try {
        
        const queryData = {
            body: {
                "email": email,
                "firstName": firstName,
                "lastName": lastName,
                "message": message
            }
        }

        const path = "/emails/contact-us";
        await API.post("Emails", path, queryData);

        let result: Result = {isSuccess: true, type: "Email", result: "Email was sent.", message: "Email was sent." }
        return result;
        
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Email", result: error, message: "Error occurred while trying to create a new email." }
        return result;
    }
}