import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonToggle,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Event, EventClass, EventClassEntry, EventResult } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import SuccessBanner from "../Banners/SuccessBanner";
import SelectEventClassEntry from "../EventClassEntry/SelectEventClassEntry";
import { getEventClassEntriesByEventClassId } from "../../utilities/eventClassEntry/EventClassEntry";
import { CreateEventResultInput, UpdateEventClassInput } from "../../API";
import { getPrizeMoneyAmountFromTable } from "../../utilities/prizeMoneyTable/PrizeMoney";
import moment from "moment";
import { createEventResult, deleteEventResult, getEventResultsByEventClassId } from "../../utilities/eventResult/EventResult";
import { getEventClassById, updateEventClass } from "../../utilities/eventClass/EventClass";
import { Input } from "reactstrap";

interface _Props {
    event: Event
    eventClass?: EventClass
    canEdit?: boolean
    onSubmit: Function
}

const EventClassResultsForm: React.FC<_Props> = ({eventClass, event, canEdit, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [currentClass, setCurrentClass] = useState<EventClass | null | undefined>();
    const [currentClassEntries, setCurrentClassEntries] = useState<EventClassEntry[] | null | undefined>();
 
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    const [viewThroughTwelve, setViewThroughTwelve] = useState(false);

    const [numberEntriesCompeted, setNumberEntriesCompeted] = useState("0");
    const [notes, setNotes] = useState("");

    const [firstPlace, setFirstPlace] = useState<EventClassEntry | null | undefined>();
    const [secondPlace, setSecondPlace] = useState<EventClassEntry | null | undefined>();
    const [thirdPlace, setThirdPlace] = useState<EventClassEntry | null | undefined>();
    const [fourthPlace, setFourthPlace] = useState<EventClassEntry | null | undefined>();
    const [fifthPlace, setFifthPlace] = useState<EventClassEntry | null | undefined>();
    const [sixthPlace, setSixthPlace] = useState<EventClassEntry | null | undefined>();
    const [seventhPlace, setSeventhPlace] = useState<EventClassEntry | null | undefined>();
    const [eighthPlace, setEighthPlace] = useState<EventClassEntry | null | undefined>();
    const [ninthPlace, setNinthPlace] = useState<EventClassEntry | null | undefined>();
    const [tenthPlace, setTenthPlace] = useState<EventClassEntry | null | undefined>();
    const [eleventhPlace, setEleventhPlace] = useState<EventClassEntry | null | undefined>();
    const [twelfthPlace, setTwelfthPlace] = useState<EventClassEntry | null | undefined>();

    const [firstPlaceResult, setFirstPlaceResult] = useState<EventResult | null | undefined>();
    const [secondPlaceResult, setSecondPlaceResult] = useState<EventResult | null | undefined>();
    const [thirdPlaceResult, setThirdPlaceResult] = useState<EventResult | null | undefined>();
    const [fourthPlaceResult, setFourthPlaceResult] = useState<EventResult | null | undefined>();
    const [fifthPlaceResult, setFifthPlaceResult] = useState<EventResult | null | undefined>();
    const [sixthPlaceResult, setSixthPlaceResult] = useState<EventResult | null | undefined>();
    const [seventhPlaceResult, setSeventhPlaceResult] = useState<EventResult | null | undefined>();
    const [eighthPlaceResult, setEighthPlaceResult] = useState<EventResult | null | undefined>();
    const [ninthPlaceResult, setNinthPlaceResult] = useState<EventResult | null | undefined>();
    const [tenthPlaceResult, setTenthPlaceResult] = useState<EventResult | null | undefined>();
    const [eleventhPlaceResult, setEleventhPlaceResult] = useState<EventResult | null | undefined>();
    const [twelfthPlaceResult, setTwelfthPlaceResult] = useState<EventResult | null | undefined>();

    async function getEventClassEntries(eventClassId: string) {
        const queryResult = await getEventClassEntriesByEventClassId(eventClassId);
        if (queryResult.isSuccess) {
            setCurrentClassEntries(queryResult.result);
        }
    }

    function setResult(currentEventResult: EventResult) {
        if (currentEventResult.place === 1) setFirstPlaceResult(currentEventResult);
        if (currentEventResult.place === 2) setSecondPlaceResult(currentEventResult);
        if (currentEventResult.place === 3) setThirdPlaceResult(currentEventResult);
        if (currentEventResult.place === 4) setFourthPlaceResult(currentEventResult);
        if (currentEventResult.place === 5) setFifthPlaceResult(currentEventResult);
        if (currentEventResult.place === 6) setSixthPlaceResult(currentEventResult);
        if (currentEventResult.place === 7) setSeventhPlaceResult(currentEventResult);
        if (currentEventResult.place === 8) setEighthPlaceResult(currentEventResult);
        if (currentEventResult.place === 9) setNinthPlaceResult(currentEventResult);
        if (currentEventResult.place === 10) setTenthPlaceResult(currentEventResult);
        if (currentEventResult.place === 11) setEleventhPlaceResult(currentEventResult);
        if (currentEventResult.place === 12) setTwelfthPlaceResult(currentEventResult);
    }

    async function getEventClassResults(eventClassId: string) {
        const queryResult = await getEventResultsByEventClassId(eventClassId);
        if (queryResult.isSuccess) {
            setIsEditing(false);
            const resultsArray: EventResult[] = queryResult.result;
            resultsArray.forEach(currentEventResult => {
                setResult(currentEventResult);
            });
            resultsArray.forEach(currentEventResult => {
                const place = currentEventResult.place || 0;
                const eventClassEntry: EventClassEntry | null | null | undefined = currentEventResult.eventClassEntry;
                if (place && eventClassEntry) {
                    handleSelectedEventClassEntry(place, eventClassEntry);
                }
            });
        }
    }

    useEffect(() => {
        async function setEventClass(eventClass: EventClass) {
            clearAllForm();
            const queryResult = await getEventClassById(eventClass.id);
            if (queryResult.isSuccess) {
                const eventClassResult: EventClass = queryResult.result;
                setCurrentClass(eventClassResult);
                if (eventClassResult && eventClassResult.id) {
                    getEventClassEntries(eventClassResult.id);
                    getEventClassResults(eventClassResult.id);
                }
                if (eventClassResult.currentNumberEntries) {
                    setNumberEntriesCompeted(eventClassResult.currentNumberEntries.toString());
                }
                if (eventClassResult.note) {
                    setNotes(eventClassResult.note);
                }
            } else {
                setError("Could not find the class");
            }
        }
        
        if (eventClass) setEventClass(eventClass);
    }, [eventClass]);

    const verifyForm = () => {
        try {
            return true;
        } catch (error: any) {
            return false;
        }
    }

    const clearPlaces = () => {
        setFirstPlace(undefined);
        setSecondPlace(undefined);
        setThirdPlace(undefined);
        setFourthPlace(undefined);
        setFifthPlace(undefined);
        setSixthPlace(undefined);
        setSeventhPlace(undefined);
        setEighthPlace(undefined);
        setNinthPlace(undefined);
        setTenthPlace(undefined);
        setEleventhPlace(undefined);
        setTwelfthPlace(undefined);
    }

    const clearForm = () => {
        clearPlaces();
        setError("");
    }
    
    const clearAllForm = () => {
        clearPlaces();
        setError("");
        eventClass = undefined;
    }

    const handleSelectedEventClassEntry = (place: (string | number), eventClassEntry: EventClassEntry) => {
        if (place && eventClassEntry) {
            if (place === "first" || place === 1) setFirstPlace(eventClassEntry);
            if (place === "second" || place === 2) setSecondPlace(eventClassEntry);
            if (place === "third" || place === 3) setThirdPlace(eventClassEntry);
            if (place === "fourth" || place === 4) setFourthPlace(eventClassEntry);
            if (place === "fifth" || place === 5) setFifthPlace(eventClassEntry);
            if (place === "sixth" || place === 6) setSixthPlace(eventClassEntry);
            if (place === "seventh" || place === 7) setSeventhPlace(eventClassEntry);
            if (place === "eighth" || place === 8) setEighthPlace(eventClassEntry);
            if (place === "ninth" || place === 9) setNinthPlace(eventClassEntry);
            if (place === "tenth" || place === 10) setTenthPlace(eventClassEntry);
            if (place === "eleventh" || place === 11) setEleventhPlace(eventClassEntry);
            if (place === "twelfth" || place === 12) setTwelfthPlace(eventClassEntry);
        } else if (place) {
            if (place === "first" || place === 1) setFirstPlace(undefined);
            if (place === "second" || place === 2) setSecondPlace(undefined);
            if (place === "third" || place === 3) setThirdPlace(undefined);
            if (place === "fourth" || place === 4) setFourthPlace(undefined);
            if (place === "fifth" || place === 5) setFifthPlace(undefined);
            if (place === "sixth" || place === 6) setSixthPlace(undefined);
            if (place === "seventh" || place === 7) setSeventhPlace(undefined);
            if (place === "eighth" || place === 8) setEighthPlace(undefined);
            if (place === "ninth" || place === 9) setNinthPlace(undefined);
            if (place === "tenth" || place === 10) setTenthPlace(undefined);
            if (place === "eleventh" || place === 11) setEleventhPlace(undefined);
            if (place === "twelfth" || place === 12) setTwelfthPlace(undefined);
        }
        
        setIsDisabled(false);
    }

    const createResult = async (place: number, eventClassEntry: EventClassEntry) => {
        if (currentClass) {
            let prizeMoneyAmount = 0;
            if (currentClass.prizeMoneyTable) {
                const value = await getPrizeMoneyAmountFromTable(currentClass.prizeMoneyTable, place);
                if (value) prizeMoneyAmount = parseFloat(value);
            }
            const input: CreateEventResultInput = {
                eventId: event.id,
                entryId: eventClassEntry.eventEntry?.id || "",
                eventResultEntryId: eventClassEntry.eventEntry?.id || "",
                eventClassEntryId: eventClassEntry.id,
                eventResultEventClassEntryId: eventClassEntry.id,
                eventClassId: currentClass.id,
                // eventResultEventClassId: currentClass.id,
                eventClassName: currentClass.name,
                place: place,
                prizeMoney: prizeMoneyAmount,
                createdBy: user.id,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
            const createResult = await createEventResult(input);
            if (createResult.isSuccess) setResult(createResult.result);
            
        } else {
            setError("Could not find the class.");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);

        const isValid = verifyForm();
        if (!isValid) {
            setIsLoading(false);
            return;
        }

        if (firstPlaceResult) await deleteEventResult({id: firstPlaceResult.id});
        if (secondPlaceResult) await deleteEventResult({id: secondPlaceResult.id});
        if (thirdPlaceResult) await deleteEventResult({id: thirdPlaceResult.id});
        if (fourthPlaceResult) await deleteEventResult({id: fourthPlaceResult.id});
        if (fifthPlaceResult) await deleteEventResult({id: fifthPlaceResult.id});
        if (sixthPlaceResult) await deleteEventResult({id: sixthPlaceResult.id});
        if (seventhPlaceResult) await deleteEventResult({id: seventhPlaceResult.id});
        if (eighthPlaceResult) await deleteEventResult({id: eighthPlaceResult.id});
        if (ninthPlaceResult) await deleteEventResult({id: ninthPlaceResult.id});
        if (tenthPlaceResult) await deleteEventResult({id: tenthPlaceResult.id});
        if (eleventhPlaceResult) await deleteEventResult({id: eleventhPlaceResult.id});
        if (twelfthPlaceResult) await deleteEventResult({id: twelfthPlaceResult.id});

        if (firstPlace) await createResult(1, firstPlace);
        if (secondPlace) await createResult(2, secondPlace);
        if (thirdPlace) await createResult(3, thirdPlace);
        if (fourthPlace) await createResult(4, fourthPlace);
        if (fifthPlace) await createResult(5, fifthPlace);
        if (sixthPlace) await createResult(6, sixthPlace);
        if (seventhPlace) await createResult(7, seventhPlace);
        if (eighthPlace) await createResult(8, eighthPlace);
        if (ninthPlace) await createResult(9, ninthPlace);
        if (tenthPlace) await createResult(10, tenthPlace);
        if (eleventhPlace) await createResult(11, eleventhPlace);
        if (twelfthPlace) await createResult(12, twelfthPlace);

        if (currentClass) {
            const updateEventClassInput: UpdateEventClassInput = {
                id: currentClass.id,
                currentNumberEntries: parseInt(numberEntriesCompeted) || 0,
                note: notes
            };
            await updateEventClass(updateEventClassInput);
        }

        setIsLoading(false);
        onSubmit();
    }

    const handleDelete = async () => {
        setIsLoading(true);
        setIsDisabled(true);
        setIsLoading(false);
        onSubmit();
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            <IonRow>
                <IonCol>
                    {eventClass ?
                        <h3>{eventClass.number}: {eventClass.name}</h3>
                        :
                        <p>Error</p>
                    }
                </IonCol>
            </IonRow>
            {!isEditing ?
                <>
                    <IonRow>
                        <IonCol>
                            {firstPlaceResult && <p>1st: {firstPlaceResult.entry.number} - {firstPlaceResult.entry.horseName}</p>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {secondPlaceResult && <p>2nd: {secondPlaceResult.entry.number} - {secondPlaceResult.entry.horseName}</p>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {thirdPlaceResult && <p>3rd: {thirdPlaceResult.entry.number} - {thirdPlaceResult.entry.horseName}</p>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {fourthPlaceResult && <p>4th: {fourthPlaceResult.entry.number} - {fourthPlaceResult.entry.horseName}</p>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {fifthPlaceResult && <p>5th: {fifthPlaceResult.entry.number} - {fifthPlaceResult.entry.horseName}</p>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {sixthPlaceResult && <p>6th: {sixthPlaceResult.entry.number} - {sixthPlaceResult.entry.horseName}</p>}
                        </IonCol>
                    </IonRow>
                    {seventhPlaceResult && (
                        <>
                            <IonRow>
                                <IonCol>
                                    {seventhPlaceResult && <p>7th: {seventhPlaceResult.entry.number} - {seventhPlaceResult.entry.horseName}</p>}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    {eighthPlaceResult && <p>8th: {eighthPlaceResult.entry.number} - {eighthPlaceResult.entry.horseName}</p>}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    {ninthPlaceResult && <p>9th: {ninthPlaceResult.entry.number} - {ninthPlaceResult.entry.horseName}</p>}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    {tenthPlaceResult && <p>10th: {tenthPlaceResult.entry.number} - {tenthPlaceResult.entry.horseName}</p>}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    {eleventhPlaceResult && <p>11th: {eleventhPlaceResult.entry.number} - {eleventhPlaceResult.entry.horseName}</p>}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    {twelfthPlaceResult && <p>12th: {twelfthPlaceResult.entry.number} - {twelfthPlaceResult.entry.horseName}</p>}
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    {canEdit && (
                        <IonRow>
                            <IonCol sizeMd="12">
                                <IonButton
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={() => setIsEditing(true)}
                                >
                                    Edit Results
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    )}
                </>
                :
                <>
                    {isLoading ?
                        <Spinner />
                        :
                        <form>
                            <IonRow>
                                <IonCol>
                                    <IonItem>
                                        <IonLabel position="stacked">Number of Entries Competed: </IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={numberEntriesCompeted}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setNumberEntriesCompeted(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>Notes</IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <Input
                                        rows="3"
                                        type="textarea"
                                        name="notes"
                                        value={notes}
                                        spellCheck="true"
                                        onChange={(event) => setNotes(event.target.value)}
                                        data-hj-whitelist
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                <IonLabel>First Place</IonLabel>
                                    {eventClass ?
                                        <SelectEventClassEntry eventClassId={eventClass.id} values={currentClassEntries} selectedValue={firstPlace?.id || firstPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("first", event)} />
                                        :
                                        <p>Error</p>
                                    }
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                <IonLabel>Second Place</IonLabel>
                                    {eventClass ?
                                        <SelectEventClassEntry eventClassId={eventClass.id} values={currentClassEntries} selectedValue={secondPlace?.id || secondPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("second", event)} />
                                        :
                                        <p>Error</p>
                                    }
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                <IonLabel>Third Place</IonLabel>
                                    {eventClass ?
                                        <SelectEventClassEntry eventClassId={eventClass.id} values={currentClassEntries} selectedValue={thirdPlace?.id || thirdPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("third", event)} />
                                        :
                                        <p>Error</p>
                                    }
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                <IonLabel>Fourth Place</IonLabel>
                                    {eventClass ?
                                        <SelectEventClassEntry eventClassId={eventClass.id} values={currentClassEntries} selectedValue={fourthPlace?.id || fourthPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("fourth", event)} />
                                        :
                                        <p>Error</p>
                                    }
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                <IonLabel>Fifth Place</IonLabel>
                                    {eventClass ?
                                        <SelectEventClassEntry eventClassId={eventClass.id} values={currentClassEntries} selectedValue={fifthPlace?.id || fifthPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("fifth", event)} />
                                        :
                                        <p>Error</p>
                                    }
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                <IonLabel>Sixth Place</IonLabel>
                                    {eventClass ?
                                        <SelectEventClassEntry eventClassId={eventClass.id} values={currentClassEntries} selectedValue={sixthPlace?.id || sixthPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("sixth", event)} />
                                        :
                                        <p>Error</p>
                                    }
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonItem color="white">
                                        <IonLabel>Display 7-12</IonLabel>
                                        <IonToggle color="tertiary" slot="end" checked={viewThroughTwelve} onIonChange={e => setViewThroughTwelve(e.detail.checked)} />
                                    </IonItem>   
                                </IonCol>
                            </IonRow>
                            {viewThroughTwelve && (
                                <>
                                    <IonRow>
                                        <IonCol>
                                        <IonLabel>Seventh Place</IonLabel>
                                            {eventClass ?
                                                <SelectEventClassEntry eventClassId={eventClass.id} values={currentClassEntries} selectedValue={seventhPlace?.id || seventhPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("seventh", event)} />
                                                :
                                                <p>Error</p>
                                            }
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                        <IonLabel>Eighth Place</IonLabel>
                                            {eventClass ?
                                                <SelectEventClassEntry eventClassId={eventClass.id} values={currentClassEntries} selectedValue={eighthPlace?.id || eighthPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("eighth", event)} />
                                                :
                                                <p>Error</p>
                                            }
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                        <IonLabel>Ninth Place</IonLabel>
                                            {eventClass ?
                                                <SelectEventClassEntry eventClassId={eventClass.id} values={currentClassEntries} selectedValue={ninthPlace?.id || ninthPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("ninth", event)} />
                                                :
                                                <p>Error</p>
                                            }
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                        <IonLabel>Tenth Place</IonLabel>
                                            {eventClass ?
                                                <SelectEventClassEntry eventClassId={eventClass.id} values={currentClassEntries} selectedValue={tenthPlace?.id || tenthPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("tenth", event)} />
                                                :
                                                <p>Error</p>
                                            }
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                        <IonLabel>Eleventh Place</IonLabel>
                                            {eventClass ?
                                                <SelectEventClassEntry eventClassId={eventClass.id} values={currentClassEntries} selectedValue={eleventhPlace?.id || eleventhPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("eleventh", event)} />
                                                :
                                                <p>Error</p>
                                            }
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                        <IonLabel>Twelfth Place</IonLabel>
                                            {eventClass ?
                                                <SelectEventClassEntry eventClassId={eventClass.id} values={currentClassEntries} selectedValue={twelfthPlace?.id || twelfthPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("twelfth", event)} />
                                                :
                                                <p>Error</p>
                                            }
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {error && <ErrorAlert width="12" error={error}/>}
                            <IonRow>
                                <IonCol sizeMd="12">
                                    {isLoading ?
                                        <Spinner />
                                        :
                                        <IonButton
                                            className="ion-margin-top"
                                            color="tertiary"
                                            expand="block"
                                            onClick={handleSubmit}
                                        >
                                            Save Results
                                        </IonButton>
                                    }
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                {eventClass && (
                                    <>
                                        <IonCol sizeMd="4">
                                            {isLoading ?
                                                <Spinner />
                                                :
                                                <IonButton
                                                    className="ion-margin-top"
                                                    color="primary"
                                                    expand="block"
                                                    onClick={clearForm}
                                                >
                                                    Reset Form
                                                </IonButton>
                                            }
                                        </IonCol>
                                        <IonCol offsetMd="4" sizeMd="4">
                                            {isLoading ?
                                                <Spinner />
                                                :
                                                <IonButton
                                                    disabled={isDisabled}
                                                    className="ion-margin-top"
                                                    color="danger"
                                                    expand="block"
                                                    onClick={handleDelete}
                                                >
                                                    Delete
                                                </IonButton>
                                            }
                                        </IonCol>
                                    </>
                                )}
                            </IonRow>
                        </form>
                    }
                </>
            }
        </>
    );
};

export default EventClassResultsForm;