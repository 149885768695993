import React, { useEffect, useState } from "react";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";
import { IonLabel, isPlatform } from "@ionic/react";
import { isWindows } from "../../utilities/platform/Platform";
import { TeamRole } from "../../API";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    menuPlacement?: (string | null)
    selectedValue?: (string | null)
    isDisabled?: boolean
    onSelect: Function
}

const TeamRolesList = [
    {
        label: "Head Coach",
        value: TeamRole.headCoach,
        object: TeamRole.headCoach,
    },
    {
        label: "Assistant Coach",
        value: TeamRole.assistantCoach,
        object: TeamRole.assistantCoach,
    },
    {
        label: "Parent Volunteer",
        value: TeamRole.parentVolunteer,
        object: TeamRole.parentVolunteer,
    }
];

const AELCoachTypeSelect: React.FC<_Props> = ({isDisabled, selectedValue, menuPlacement, onSelect}) => {

    const [formattedOptions, setFormattedOptions] = useState<formattedOption[] | undefined | null>();
    const [selected, setSelected] = useState<string | null | undefined>();

    const getFormattedOptions = () => {
        let options: any = [];
        for (let i = 0; i < TeamRolesList.length; i++) {
            const current = TeamRolesList[i];
            const formattedOption: formattedOption = {
                value: current.value, //This is how the selected value passed in through props will get matched
                label: current.label,
                object: current.object
            };
            options.push(formattedOption);
        }
        setFormattedOptions(options);
    }

    useEffect(() => {
        getFormattedOptions();
    }, []);

    useEffect(() => {
        if (selectedValue) setSelected(selectedValue)
    }, [selectedValue]);

    const handleOnChange = (person?: any) => {
        if (person) {
            onSelect(person);
        } else {
            onSelect();
        }
    }

    return (
        <>
            <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>Member Role <RequiredInputIndicator/></IonLabel>
            {formattedOptions ?
                <BasicSelect formattedValues={formattedOptions} menuPlacement={menuPlacement} selectedValue={selected} isClearable={false} isDisabled={isDisabled} onSelect={handleOnChange} />
                :
                <p>Loading options...</p>
            }
        </>
    )
};

export default AELCoachTypeSelect;
