/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAddressInput = {
  id?: string | null,
  name?: string | null,
  type?: string | null,
  isDefault?: boolean | null,
  streetAddress1?: string | null,
  streetAddress2?: string | null,
  city?: string | null,
  provState?: string | null,
  zip?: string | null,
  country?: string | null,
  notes?: string | null,
};

export type ModelAddressConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  isDefault?: ModelBooleanInput | null,
  streetAddress1?: ModelStringInput | null,
  streetAddress2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  provState?: ModelStringInput | null,
  zip?: ModelStringInput | null,
  country?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  and?: Array< ModelAddressConditionInput | null > | null,
  or?: Array< ModelAddressConditionInput | null > | null,
  not?: ModelAddressConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Address = {
  __typename: "Address",
  id: string,
  name?: string | null,
  type?: string | null,
  isDefault?: boolean | null,
  streetAddress1?: string | null,
  streetAddress2?: string | null,
  city?: string | null,
  provState?: string | null,
  zip?: string | null,
  country?: string | null,
  notes?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAddressInput = {
  id: string,
  name?: string | null,
  type?: string | null,
  isDefault?: boolean | null,
  streetAddress1?: string | null,
  streetAddress2?: string | null,
  city?: string | null,
  provState?: string | null,
  zip?: string | null,
  country?: string | null,
  notes?: string | null,
};

export type DeleteAddressInput = {
  id: string,
};

export type CreateAlertInput = {
  id?: string | null,
  type?: string | null,
  title?: string | null,
  body?: string | null,
  imageKey?: string | null,
  category?: string | null,
  organizationId?: string | null,
  eventId?: string | null,
  messageSegments?: number | null,
  subscriberCount?: number | null,
  totalCost?: number | null,
  blockIdsUsed?: Array< string | null > | null,
  fromUnlimitedEvent?: boolean | null,
  isIncoming?: string | null,
  sentFromPhoneNumber?: string | null,
  recipientPhoneNumbers?: Array< string | null > | null,
  successSIDList?: Array< string | null > | null,
  errorSIDList?: Array< string | null > | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  alertEventId?: string | null,
};

export type ModelAlertConditionInput = {
  type?: ModelStringInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  imageKey?: ModelStringInput | null,
  category?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  messageSegments?: ModelIntInput | null,
  subscriberCount?: ModelIntInput | null,
  totalCost?: ModelFloatInput | null,
  blockIdsUsed?: ModelStringInput | null,
  fromUnlimitedEvent?: ModelBooleanInput | null,
  isIncoming?: ModelStringInput | null,
  sentFromPhoneNumber?: ModelStringInput | null,
  recipientPhoneNumbers?: ModelStringInput | null,
  successSIDList?: ModelStringInput | null,
  errorSIDList?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelAlertConditionInput | null > | null,
  or?: Array< ModelAlertConditionInput | null > | null,
  not?: ModelAlertConditionInput | null,
  alertEventId?: ModelIDInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Alert = {
  __typename: "Alert",
  id: string,
  type?: string | null,
  title?: string | null,
  body?: string | null,
  imageKey?: string | null,
  category?: string | null,
  organizationId?: string | null,
  eventId?: string | null,
  event?: Event | null,
  messageSegments?: number | null,
  subscriberCount?: number | null,
  totalCost?: number | null,
  blockIdsUsed?: Array< string | null > | null,
  fromUnlimitedEvent?: boolean | null,
  isIncoming?: string | null,
  sentFromPhoneNumber?: string | null,
  recipientPhoneNumbers?: Array< string | null > | null,
  successSIDList?: Array< string | null > | null,
  errorSIDList?: Array< string | null > | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  alertEventId?: string | null,
};

export type Event = {
  __typename: "Event",
  id: string,
  name: string,
  nickname?: string | null,
  createdBy: string,
  manager?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  address?: Address | null,
  contactId?: string | null,
  contactIds?: Array< string | null > | null,
  organizationId?: string | null,
  organization?: Organization | null,
  type?: string | null,
  disciplines?: string | null,
  eventOptions?: EventOptions | null,
  paymentOptions?: PaymentOptions | null,
  invoiceOptions?: InvoiceOptions | null,
  entryOptions?: EntryOptions | null,
  officials?: ModelEventOfficialConnection | null,
  status?: string | null,
  entriesStatus?: string | null,
  showStatus?: string | null,
  usefID?: string | null,
  usefSoftwareKey?: string | null,
  ecID?: string | null,
  ecPassword?: string | null,
  documents?:  Array<S3Object | null > | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  eventAddressId?: string | null,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  name: string,
  nickname?: string | null,
  description?: string | null,
  type?: OrganizationType | null,
  industry?: string | null,
  addresses?: Array< string | null > | null,
  contactId?: string | null,
  contact?: Contact | null,
  stripeId?: string | null,
  stripeCustomerId?: string | null,
  paymentMethodId?: string | null,
  website?: string | null,
  media?:  Array<S3Object | null > | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  organizationOptions?: OrganizationOptions | null,
  urlBackHalf?: string | null,
  colors?: Array< string | null > | null,
  logos?:  Array<Logo | null > | null,
  createdOn: string,
  updatedOn: string,
};

export enum OrganizationType {
  individual = "individual",
  company = "company",
  nonprofit = "nonprofit",
}


export type Contact = {
  __typename: "Contact",
  id: string,
  name?: string | null,
  cell?: string | null,
  home?: string | null,
  work?: string | null,
  fax?: string | null,
  personalEmail?: string | null,
  personalEmailIsVerified?: boolean | null,
  workEmail?: string | null,
  workEmailIsVerified?: boolean | null,
  mailingAddress?: string | null,
  website?: string | null,
  facebookPage?: string | null,
  instagramHandle?: string | null,
  twitterHandle?: string | null,
  tiktokHandle?: string | null,
  emergencyContactName?: string | null,
  emergencyContactPhone?: string | null,
  createdBy?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type S3Object = {
  __typename: "S3Object",
  title?: string | null,
  description?: string | null,
  originalExtension?: string | null,
  dateUploaded?: string | null,
  url?: string | null,
  bucket: string,
  region: string,
  key: string,
};

export type OrganizationOptions = {
  __typename: "OrganizationOptions",
  usesTextAlerts?: boolean | null,
  acceptsIncomingTextAlerts?: boolean | null,
};

export type Logo = {
  __typename: "Logo",
  logoURL?: string | null,
  key?: string | null,
  logo?: S3Object | null,
};

export type EventOptions = {
  __typename: "EventOptions",
  autoAcceptNewEntries?: boolean | null,
  usesTextAlerts?: boolean | null,
  acceptsIncomingTextAlerts?: boolean | null,
  hasUnlimitedCredits?: boolean | null,
  usesEventManagement?: boolean | null,
  usesStablingManagement?: boolean | null,
  usesVendorManagement?: boolean | null,
  usesMediaTools?: boolean | null,
  usesMarketingTools?: boolean | null,
  usesFinanceTools?: boolean | null,
  usesJudgeApp?: boolean | null,
};

export type PaymentOptions = {
  __typename: "PaymentOptions",
  acceptsChecks?: boolean | null,
  acceptsCash?: boolean | null,
  acceptsACH?: boolean | null,
  acceptsCC?: boolean | null,
};

export type InvoiceOptions = {
  __typename: "InvoiceOptions",
  classFeeTax?: number | null,
  sendEmails?: boolean | null,
};

export type EntryOptions = {
  __typename: "EntryOptions",
  displayHorse2Input?: boolean | null,
  displayRider2Input?: boolean | null,
  displayRider3Input?: boolean | null,
  requiresCogginsDocument?: boolean | null,
  requiresCogginsData?: boolean | null,
  maximumNumberClassesOnEntry?: number | null,
};

export type ModelEventOfficialConnection = {
  __typename: "ModelEventOfficialConnection",
  items:  Array<EventOfficial | null >,
  nextToken?: string | null,
};

export type EventOfficial = {
  __typename: "EventOfficial",
  id: string,
  name: string,
  position: string,
  eventId: string,
  event?: Event | null,
  personId?: string | null,
  contactId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAlertInput = {
  id: string,
  type?: string | null,
  title?: string | null,
  body?: string | null,
  imageKey?: string | null,
  category?: string | null,
  organizationId?: string | null,
  eventId?: string | null,
  messageSegments?: number | null,
  subscriberCount?: number | null,
  totalCost?: number | null,
  blockIdsUsed?: Array< string | null > | null,
  fromUnlimitedEvent?: boolean | null,
  isIncoming?: string | null,
  sentFromPhoneNumber?: string | null,
  recipientPhoneNumbers?: Array< string | null > | null,
  successSIDList?: Array< string | null > | null,
  errorSIDList?: Array< string | null > | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  alertEventId?: string | null,
};

export type DeleteAlertInput = {
  id: string,
};

export type CreateAuditorInput = {
  id?: string | null,
  personId?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
  status?: AuditorStatus | null,
  statusNote?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export enum AuditorStatus {
  unverified = "unverified",
  verified = "verified",
  failed = "failed",
  stopped = "stopped",
}


export type ModelAuditorConditionInput = {
  personId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  email?: ModelStringInput | null,
  status?: ModelAuditorStatusInput | null,
  statusNote?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelAuditorConditionInput | null > | null,
  or?: Array< ModelAuditorConditionInput | null > | null,
  not?: ModelAuditorConditionInput | null,
};

export type ModelAuditorStatusInput = {
  eq?: AuditorStatus | null,
  ne?: AuditorStatus | null,
};

export type Auditor = {
  __typename: "Auditor",
  id: string,
  personId?: string | null,
  person?: Person | null,
  name?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
  status?: AuditorStatus | null,
  statusNote?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type Person = {
  __typename: "Person",
  id: string,
  email: string,
  firstName?: string | null,
  lastName?: string | null,
  isVerified?: boolean | null,
  roles?: string | null,
  memberships?: ModelMembershipConnection | null,
  personalInformationId?: string | null,
  personalInformation?: PersonalInformation | null,
  pictureURL?: string | null,
  picture?: S3Object | null,
  disciplines?: string | null,
  stripeId?: string | null,
  media?:  Array<S3Object | null > | null,
  createdBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type ModelMembershipConnection = {
  __typename: "ModelMembershipConnection",
  items:  Array<Membership | null >,
  nextToken?: string | null,
};

export type Membership = {
  __typename: "Membership",
  id: string,
  name: string,
  description?: string | null,
  membershipId: string,
  backNumber?: number | null,
  membershipStatus?: string | null,
  amountPaid?: number | null,
  organizationMembershipTypeId?: string | null,
  organizationMembershipType?: OrganizationMembershipType | null,
  type?: string | null,
  personName?: string | null,
  personId: string,
  riderId?: string | null,
  rider?: Rider | null,
  horseId?: string | null,
  horse?: Horse | null,
  ownerId?: string | null,
  owner?: Owner | null,
  trainerId?: string | null,
  trainer?: Trainer | null,
  barnId?: string | null,
  barn?: Barn | null,
  teamId?: string | null,
  organizationId?: string | null,
  organization?: Organization | null,
  organizationName?: string | null,
  isJunior?: boolean | null,
  isProfessional?: boolean | null,
  dateMembershipEnds?: string | null,
  volunteerHours?: number | null,
  meetingsAttended?: number | null,
  showsAttended?: number | null,
  personEmail?: string | null,
  gradeLevel?: string | null,
  aelDivision?: string | null,
  aelTeamRole?: TeamRole | null,
  documents?:  Array<S3Object | null > | null,
  organizationAgreements?:  Array<OrganizationAgreements | null > | null,
  contactPersonId?: string | null,
  groupContactId?: string | null,
  groupContact?: Contact | null,
  groupLeaderContactId?: string | null,
  groupLeaderContact?: Contact | null,
  createdBy?: string | null,
  createdOn: string,
  updatedOn: string,
  personMembershipsId?: string | null,
  membershipOrganizationId?: string | null,
};

export type OrganizationMembershipType = {
  __typename: "OrganizationMembershipType",
  id: string,
  name: string,
  description?: string | null,
  order?: number | null,
  organizationId?: string | null,
  organization?: Organization | null,
  membershipIdValues?: string | null,
  nextAvailableMembershipId?: string | null,
  price?: number | null,
  category?: MembershipTypeCategory | null,
  schedule?: MembershipTypeSchedule | null,
  isStartAtTimeOfPurchase?: boolean | null,
  setStartDate?: string | null,
  acceptOnlineApplications?: string | null,
  applicationFields?: ApplicationFields | null,
  applicationOptions?: ApplicationOptions | null,
  organizationDocuments?:  Array<OrganizationDocument | null > | null,
  organizationRules?:  Array<OrganizationRule | null > | null,
  seasons?: ModelOrganizationMembershipTypeSeasonsConnection | null,
  createdOn: string,
  updatedOn: string,
  organizationMembershipTypeOrganizationId?: string | null,
};

export enum MembershipTypeCategory {
  individual = "individual",
  group = "group",
  horse = "horse",
}


export enum MembershipTypeSchedule {
  monthly = "monthly",
  season = "season",
  annual = "annual",
  life = "life",
}


export type ApplicationFields = {
  __typename: "ApplicationFields",
  firstName?: boolean | null,
  lastName?: boolean | null,
  dateOfBirth?: boolean | null,
  contact?: boolean | null,
  gradeLevel?: boolean | null,
  barnName?: boolean | null,
  teamName?: boolean | null,
  backNumber?: boolean | null,
  groupName?: boolean | null,
  groupLeader?: boolean | null,
  groupLeaderContactInfo?: boolean | null,
  collectMemberDetailsTogether?: boolean | null,
  maxNumberOfGroupMembers?: number | null,
  isTeam?: boolean | null,
  horseName?: boolean | null,
  horseHeight?: boolean | null,
  horseOwnerName?: boolean | null,
  cogginsInfo?: boolean | null,
  cogginsUpload?: boolean | null,
};

export type ApplicationOptions = {
  __typename: "ApplicationOptions",
  requireApproval?: boolean | null,
  autoAssignIds?: boolean | null,
  acceptRSPPayment?: boolean | null,
  acceptOtherPayment?: boolean | null,
};

export type OrganizationDocument = {
  __typename: "OrganizationDocument",
  id: string,
  name: string,
};

export type OrganizationRule = {
  __typename: "OrganizationRule",
  id: string,
  title: string,
  body: string,
  isRequired?: boolean | null,
};

export type ModelOrganizationMembershipTypeSeasonsConnection = {
  __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
  items:  Array<OrganizationMembershipTypeSeasons | null >,
  nextToken?: string | null,
};

export type OrganizationMembershipTypeSeasons = {
  __typename: "OrganizationMembershipTypeSeasons",
  id: string,
  organizationMembershipTypeID: string,
  seasonID: string,
  organizationMembershipType: OrganizationMembershipType,
  season: Season,
  createdAt: string,
  updatedAt: string,
};

export type Season = {
  __typename: "Season",
  id: string,
  name: string,
  description?: string | null,
  organizationId?: string | null,
  organization?: Organization | null,
  organizationMembershipTypes?: ModelOrganizationMembershipTypeSeasonsConnection | null,
  isActive?: boolean | null,
  startDate: string,
  endDate: string,
  disciplines?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  createdAt: string,
  updatedAt: string,
  seasonOrganizationId?: string | null,
};

export type Rider = {
  __typename: "Rider",
  id: string,
  name: string,
  location?: string | null,
  contact?: Contact | null,
  addressId?: string | null,
  address?: Address | null,
  personId?: string | null,
  age?: number | null,
  birthdate?: string | null,
  isProfessional?: boolean | null,
  memberships?: ModelMembershipConnection | null,
  barnName?: string | null,
  barnId?: string | null,
  barn?: Barn | null,
  createdBy?: string | null,
  createdOn: string,
  updatedOn: string,
  createdAt: string,
  updatedAt: string,
  riderContactId?: string | null,
};

export type Barn = {
  __typename: "Barn",
  id: string,
  name: string,
  nickname?: string | null,
  description?: string | null,
  type?: string | null,
  contact?: Contact | null,
  ownerName?: string | null,
  ownerPersonId?: string | null,
  secondaryOwnerName?: string | null,
  secondaryOwnerPersonId?: string | null,
  members?: ModelBarnMemberConnection | null,
  location?: Address | null,
  media?:  Array<S3Object | null > | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  barnContactId?: string | null,
  barnLocationId?: string | null,
};

export type ModelBarnMemberConnection = {
  __typename: "ModelBarnMemberConnection",
  items:  Array<BarnMember | null >,
  nextToken?: string | null,
};

export type BarnMember = {
  __typename: "BarnMember",
  id: string,
  name: string,
  barnId: string,
  barn: Barn,
  personId: string,
  person?: Person | null,
  permissionLevel?: string | null,
  status?: string | null,
  roles?: string | null,
  createdBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type Horse = {
  __typename: "Horse",
  id: string,
  name: string,
  nickname?: string | null,
  previousNames?: Array< string | null > | null,
  personId?: string | null,
  ownerId?: string | null,
  owner?: Owner | null,
  ownerName: string,
  barnName?: string | null,
  barnId?: string | null,
  barn?: Barn | null,
  contactId?: string | null,
  contact?: Contact | null,
  microchipNumber?: string | null,
  passportNumber?: string | null,
  dateOfBirth?: string | null,
  breed?: string | null,
  sex?: string | null,
  color?: string | null,
  markings?: string | null,
  height?: string | null,
  foalState?: string | null,
  cogginsNumber?: string | null,
  cogginsDate?: string | null,
  cogginsState?: string | null,
  coggins?: S3Object | null,
  memberships?: ModelMembershipConnection | null,
  highestLevelCompeted?: string | null,
  greenEligibility?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type Owner = {
  __typename: "Owner",
  id: string,
  name: string,
  type?: string | null,
  location?: string | null,
  addressId?: string | null,
  address?: Address | null,
  contact?: Contact | null,
  personId?: string | null,
  memberships?: ModelMembershipConnection | null,
  barnId?: string | null,
  createdBy?: string | null,
  createdOn: string,
  updatedOn: string,
  ownerContactId?: string | null,
};

export type Trainer = {
  __typename: "Trainer",
  id: string,
  name: string,
  location?: string | null,
  addressId?: string | null,
  address?: Address | null,
  contact?: Contact | null,
  personId?: string | null,
  memberships?: ModelMembershipConnection | null,
  barnName?: string | null,
  barnId?: string | null,
  barn?: Barn | null,
  createdBy?: string | null,
  createdOn: string,
  updatedOn: string,
  trainerContactId?: string | null,
};

export enum TeamRole {
  headCoach = "headCoach",
  assistantCoach = "assistantCoach",
  parentVolunteer = "parentVolunteer",
  athlete = "athlete",
  parent = "parent",
}


export type OrganizationAgreements = {
  __typename: "OrganizationAgreements",
  organizationRuleId?: string | null,
  organizationRuleTitle: string,
  firstName: string,
  lastName: string,
  personId?: string | null,
  createdOn: string,
  updatedOn?: string | null,
};

export type PersonalInformation = {
  __typename: "PersonalInformation",
  id: string,
  personId: string,
  person?: Person | null,
  dateOfBirth?: string | null,
  gender?: Gender | null,
  contactId?: string | null,
  contact?: Contact | null,
  addressId?: string | null,
  address?: Address | null,
  createdAt: string,
  updatedAt: string,
};

export enum Gender {
  Male = "Male",
  Female = "Female",
  Other = "Other",
  Prefer_Not_To_Say = "Prefer_Not_To_Say",
}


export type UpdateAuditorInput = {
  id: string,
  personId?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
  status?: AuditorStatus | null,
  statusNote?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteAuditorInput = {
  id: string,
};

export type CreateBacklogEventResultInput = {
  id?: string | null,
  organizationId?: string | null,
  organizationMemberId?: string | null,
  organizationResultId?: string | null,
  eventId?: string | null,
  eventName?: string | null,
  eventStartDate?: string | null,
  eventEndDate?: string | null,
  eventDivisionId?: string | null,
  eventDivisionName?: string | null,
  organizationDivisionId?: string | null,
  eventClassId?: string | null,
  eventClassName?: string | null,
  eventClassNumber?: string | null,
  organizationClassId?: string | null,
  riderName?: string | null,
  horseName?: string | null,
  place?: number | null,
  pointsEarned?: number | null,
  score?: number | null,
  time?: string | null,
  didNotCompete?: boolean | null,
  prizeMoney?: number | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  backlogEventResultPrizeMoneyTableId?: string | null,
};

export type ModelBacklogEventResultConditionInput = {
  organizationId?: ModelIDInput | null,
  organizationMemberId?: ModelStringInput | null,
  organizationResultId?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  eventName?: ModelStringInput | null,
  eventStartDate?: ModelStringInput | null,
  eventEndDate?: ModelStringInput | null,
  eventDivisionId?: ModelStringInput | null,
  eventDivisionName?: ModelStringInput | null,
  organizationDivisionId?: ModelIDInput | null,
  eventClassId?: ModelStringInput | null,
  eventClassName?: ModelStringInput | null,
  eventClassNumber?: ModelStringInput | null,
  organizationClassId?: ModelIDInput | null,
  riderName?: ModelStringInput | null,
  horseName?: ModelStringInput | null,
  place?: ModelIntInput | null,
  pointsEarned?: ModelIntInput | null,
  score?: ModelFloatInput | null,
  time?: ModelStringInput | null,
  didNotCompete?: ModelBooleanInput | null,
  prizeMoney?: ModelFloatInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelBacklogEventResultConditionInput | null > | null,
  or?: Array< ModelBacklogEventResultConditionInput | null > | null,
  not?: ModelBacklogEventResultConditionInput | null,
  backlogEventResultPrizeMoneyTableId?: ModelIDInput | null,
};

export type BacklogEventResult = {
  __typename: "BacklogEventResult",
  id: string,
  organizationId?: string | null,
  organizationMemberId?: string | null,
  organizationResultId?: string | null,
  eventId?: string | null,
  eventName?: string | null,
  eventStartDate?: string | null,
  eventEndDate?: string | null,
  eventDivisionId?: string | null,
  eventDivisionName?: string | null,
  organizationDivisionId?: string | null,
  organizationDivision?: OrganizationDivision | null,
  eventClassId?: string | null,
  eventClassName?: string | null,
  eventClassNumber?: string | null,
  organizationClassId?: string | null,
  organizationClass?: OrganizationClass | null,
  riderName?: string | null,
  horseName?: string | null,
  place?: number | null,
  pointsEarned?: number | null,
  score?: number | null,
  time?: string | null,
  didNotCompete?: boolean | null,
  prizeMoney?: number | null,
  prizeMoneyTable?: PrizeMoneyTable | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  backlogEventResultPrizeMoneyTableId?: string | null,
};

export type OrganizationDivision = {
  __typename: "OrganizationDivision",
  id: string,
  name: string,
  number?: number | null,
  divisionId: string,
  division: Division,
  organizationId: string,
  pointTable?: PointTable | null,
  prizeMoneyTable?: PrizeMoneyTable | null,
  jumpHeight?: Array< string | null > | null,
  minNumberEntries?: number | null,
  maxNumberEntries?: number | null,
  entryFee?: string | null,
  prizeMoney?: string | null,
  entryFeePerClass?: string | null,
  prizeMoneyPerClass?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  displayOrder?: number | null,
  createdOn: string,
  updatedOn: string,
  organizationDivisionPointTableId?: string | null,
  organizationDivisionPrizeMoneyTableId?: string | null,
};

export type Division = {
  __typename: "Division",
  id: string,
  name: string,
  nickname?: string | null,
  description?: string | null,
  discipline?: string | null,
  isSearchable?: boolean | null,
  ushjaName?: string | null,
  usefNumber?: string | null,
  ecNumber?: string | null,
  ecClassTypeNumber?: string | null,
  ecClassType?: string | null,
  code?: string | null,
  specs?: string | null,
  judgedOn?: string | null,
  rules?: DivisionRules | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  eventInvoiceDivisionsId?: string | null,
};

export type DivisionRules = {
  __typename: "DivisionRules",
  horseSex?: string | null,
  horseBreed?: string | null,
  horseMinHeight?: string | null,
  horseMaxHeight?: string | null,
  horseMinAge?: string | null,
  horseMaxAge?: string | null,
  riderMinAge?: string | null,
  riderMaxAge?: string | null,
  riderProfessionalStatus?: string | null,
  riderRecord?: string | null,
  horseRecord?: string | null,
  crossEntry?: string | null,
  other?: string | null,
};

export type PointTable = {
  __typename: "PointTable",
  id: string,
  name: string,
  description?: string | null,
  displayOrder?: number | null,
  organizationId?: string | null,
  discipline?: string | null,
  isAddValueToRegularFromEntryCount?: boolean | null,
  isAddValueToChampionshipFromEntryCount?: boolean | null,
  isUseChampionRule?: boolean | null,
  isUseReserveRule?: boolean | null,
  tiers?:  Array<PointTier | null > | null,
  numberEntriesToReceiveSpecialClassPoints?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  eventResultPointTablesId?: string | null,
};

export type PointTier = {
  __typename: "PointTier",
  lowEntryCount?: number | null,
  highEntryCount?: number | null,
  first?: string | null,
  second?: string | null,
  third?: string | null,
  fourth?: string | null,
  fifth?: string | null,
  sixth?: string | null,
  seventh?: string | null,
  eighth?: string | null,
  ninth?: string | null,
  tenth?: string | null,
  eleventh?: string | null,
  twelfth?: string | null,
  champion?: string | null,
  reserve?: string | null,
};

export type PrizeMoneyTable = {
  __typename: "PrizeMoneyTable",
  id: string,
  name: string,
  description?: string | null,
  displayOrder?: number | null,
  organizationId?: string | null,
  discipline?: string | null,
  isPercentage?: boolean | null,
  totalPot?: number | null,
  tiers?: PrizeMoneyTiers | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type PrizeMoneyTiers = {
  __typename: "PrizeMoneyTiers",
  first?: string | null,
  second?: string | null,
  third?: string | null,
  fourth?: string | null,
  fifth?: string | null,
  sixth?: string | null,
  seventh?: string | null,
  eighth?: string | null,
  ninth?: string | null,
  tenth?: string | null,
  eleventh?: string | null,
  twelfth?: string | null,
  champion?: string | null,
  reserve?: string | null,
};

export type OrganizationClass = {
  __typename: "OrganizationClass",
  id: string,
  name: string,
  classId: string,
  class: Class,
  organizationId: string,
  organizationDivisionId?: string | null,
  hasChampionship?: boolean | null,
  pointTable?: PointTable | null,
  prizeMoneyTable?: PrizeMoneyTable | null,
  type?: string | null,
  number?: number | null,
  entryFee?: string | null,
  prizeMoney?: string | null,
  minNumberEntries?: number | null,
  maxNumberEntries?: number | null,
  jumpHeight?: Array< string | null > | null,
  estimatedTimePerRound?: number | null,
  createdBy: string,
  displayOrder?: number | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  organizationClassPointTableId?: string | null,
  organizationClassPrizeMoneyTableId?: string | null,
};

export type Class = {
  __typename: "Class",
  id: string,
  name: string,
  nickname?: string | null,
  divisionId?: string | null,
  type?: string | null,
  description?: string | null,
  discipline?: string | null,
  ushjaName?: string | null,
  usefNumber?: string | null,
  ecNumber?: string | null,
  ecClassTypeNumber?: string | null,
  ecClassType?: string | null,
  code?: string | null,
  specs?: string | null,
  judgedOn?: string | null,
  rules?: DivisionRules | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  eventInvoiceClassesId?: string | null,
};

export type UpdateBacklogEventResultInput = {
  id: string,
  organizationId?: string | null,
  organizationMemberId?: string | null,
  organizationResultId?: string | null,
  eventId?: string | null,
  eventName?: string | null,
  eventStartDate?: string | null,
  eventEndDate?: string | null,
  eventDivisionId?: string | null,
  eventDivisionName?: string | null,
  organizationDivisionId?: string | null,
  eventClassId?: string | null,
  eventClassName?: string | null,
  eventClassNumber?: string | null,
  organizationClassId?: string | null,
  riderName?: string | null,
  horseName?: string | null,
  place?: number | null,
  pointsEarned?: number | null,
  score?: number | null,
  time?: string | null,
  didNotCompete?: boolean | null,
  prizeMoney?: number | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  backlogEventResultPrizeMoneyTableId?: string | null,
};

export type DeleteBacklogEventResultInput = {
  id: string,
};

export type CreateBarnInput = {
  id?: string | null,
  name: string,
  nickname?: string | null,
  description?: string | null,
  type?: string | null,
  ownerName?: string | null,
  ownerPersonId?: string | null,
  secondaryOwnerName?: string | null,
  secondaryOwnerPersonId?: string | null,
  media?: Array< S3ObjectInput | null > | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  barnContactId?: string | null,
  barnLocationId?: string | null,
};

export type S3ObjectInput = {
  title?: string | null,
  description?: string | null,
  originalExtension?: string | null,
  dateUploaded?: string | null,
  url?: string | null,
  bucket: string,
  region: string,
  key: string,
};

export type ModelBarnConditionInput = {
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  ownerName?: ModelStringInput | null,
  ownerPersonId?: ModelIDInput | null,
  secondaryOwnerName?: ModelStringInput | null,
  secondaryOwnerPersonId?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelBarnConditionInput | null > | null,
  or?: Array< ModelBarnConditionInput | null > | null,
  not?: ModelBarnConditionInput | null,
  barnContactId?: ModelIDInput | null,
  barnLocationId?: ModelIDInput | null,
};

export type UpdateBarnInput = {
  id: string,
  name?: string | null,
  nickname?: string | null,
  description?: string | null,
  type?: string | null,
  ownerName?: string | null,
  ownerPersonId?: string | null,
  secondaryOwnerName?: string | null,
  secondaryOwnerPersonId?: string | null,
  media?: Array< S3ObjectInput | null > | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  barnContactId?: string | null,
  barnLocationId?: string | null,
};

export type DeleteBarnInput = {
  id: string,
};

export type CreateBarnMemberInput = {
  id?: string | null,
  name: string,
  barnId: string,
  personId: string,
  permissionLevel?: string | null,
  status?: string | null,
  roles?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelBarnMemberConditionInput = {
  name?: ModelStringInput | null,
  barnId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  permissionLevel?: ModelStringInput | null,
  status?: ModelStringInput | null,
  roles?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelBarnMemberConditionInput | null > | null,
  or?: Array< ModelBarnMemberConditionInput | null > | null,
  not?: ModelBarnMemberConditionInput | null,
};

export type UpdateBarnMemberInput = {
  id: string,
  name?: string | null,
  barnId?: string | null,
  personId?: string | null,
  permissionLevel?: string | null,
  status?: string | null,
  roles?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteBarnMemberInput = {
  id: string,
};

export type CreateBlockInput = {
  id?: string | null,
  name?: string | null,
  type?: string | null,
  personid?: string | null,
  organizationId?: string | null,
  purchasedOn?: string | null,
  expiresOn?: string | null,
  totalCredits?: number | null,
  usedCredits?: number | null,
  status?: BlockStatus | null,
  price?: number | null,
  amountPaid?: number | null,
  isPromotion?: string | null,
  isFree?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export enum BlockStatus {
  valid = "valid",
  invalid = "invalid",
  expired = "expired",
  empty = "empty",
}


export type ModelBlockConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  personid?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  purchasedOn?: ModelStringInput | null,
  expiresOn?: ModelStringInput | null,
  totalCredits?: ModelIntInput | null,
  usedCredits?: ModelIntInput | null,
  status?: ModelBlockStatusInput | null,
  price?: ModelFloatInput | null,
  amountPaid?: ModelFloatInput | null,
  isPromotion?: ModelStringInput | null,
  isFree?: ModelStringInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelBlockConditionInput | null > | null,
  or?: Array< ModelBlockConditionInput | null > | null,
  not?: ModelBlockConditionInput | null,
};

export type ModelBlockStatusInput = {
  eq?: BlockStatus | null,
  ne?: BlockStatus | null,
};

export type Block = {
  __typename: "Block",
  id: string,
  name?: string | null,
  type?: string | null,
  personid?: string | null,
  organizationId?: string | null,
  purchasedOn?: string | null,
  expiresOn?: string | null,
  totalCredits?: number | null,
  usedCredits?: number | null,
  status?: BlockStatus | null,
  price?: number | null,
  amountPaid?: number | null,
  isPromotion?: string | null,
  isFree?: string | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateBlockInput = {
  id: string,
  name?: string | null,
  type?: string | null,
  personid?: string | null,
  organizationId?: string | null,
  purchasedOn?: string | null,
  expiresOn?: string | null,
  totalCredits?: number | null,
  usedCredits?: number | null,
  status?: BlockStatus | null,
  price?: number | null,
  amountPaid?: number | null,
  isPromotion?: string | null,
  isFree?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteBlockInput = {
  id: string,
};

export type CreateClassInput = {
  id?: string | null,
  name: string,
  nickname?: string | null,
  divisionId?: string | null,
  type?: string | null,
  description?: string | null,
  discipline?: string | null,
  ushjaName?: string | null,
  usefNumber?: string | null,
  ecNumber?: string | null,
  ecClassTypeNumber?: string | null,
  ecClassType?: string | null,
  code?: string | null,
  specs?: string | null,
  judgedOn?: string | null,
  rules?: DivisionRulesInput | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventInvoiceClassesId?: string | null,
};

export type DivisionRulesInput = {
  horseSex?: string | null,
  horseBreed?: string | null,
  horseMinHeight?: string | null,
  horseMaxHeight?: string | null,
  horseMinAge?: string | null,
  horseMaxAge?: string | null,
  riderMinAge?: string | null,
  riderMaxAge?: string | null,
  riderProfessionalStatus?: string | null,
  riderRecord?: string | null,
  horseRecord?: string | null,
  crossEntry?: string | null,
  other?: string | null,
};

export type ModelClassConditionInput = {
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  divisionId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  description?: ModelStringInput | null,
  discipline?: ModelStringInput | null,
  ushjaName?: ModelStringInput | null,
  usefNumber?: ModelStringInput | null,
  ecNumber?: ModelStringInput | null,
  ecClassTypeNumber?: ModelStringInput | null,
  ecClassType?: ModelStringInput | null,
  code?: ModelStringInput | null,
  specs?: ModelStringInput | null,
  judgedOn?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelClassConditionInput | null > | null,
  or?: Array< ModelClassConditionInput | null > | null,
  not?: ModelClassConditionInput | null,
  eventInvoiceClassesId?: ModelIDInput | null,
};

export type UpdateClassInput = {
  id: string,
  name?: string | null,
  nickname?: string | null,
  divisionId?: string | null,
  type?: string | null,
  description?: string | null,
  discipline?: string | null,
  ushjaName?: string | null,
  usefNumber?: string | null,
  ecNumber?: string | null,
  ecClassTypeNumber?: string | null,
  ecClassType?: string | null,
  code?: string | null,
  specs?: string | null,
  judgedOn?: string | null,
  rules?: DivisionRulesInput | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventInvoiceClassesId?: string | null,
};

export type DeleteClassInput = {
  id: string,
};

export type CreateClassOrderInput = {
  id?: string | null,
  eventId: string,
  eventClassId: string,
  entryId: string,
  order?: number | null,
  isRandom?: boolean | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelClassOrderConditionInput = {
  eventId?: ModelIDInput | null,
  eventClassId?: ModelIDInput | null,
  entryId?: ModelIDInput | null,
  order?: ModelIntInput | null,
  isRandom?: ModelBooleanInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelClassOrderConditionInput | null > | null,
  or?: Array< ModelClassOrderConditionInput | null > | null,
  not?: ModelClassOrderConditionInput | null,
};

export type ClassOrder = {
  __typename: "ClassOrder",
  id: string,
  eventId: string,
  eventClassId: string,
  eventClass?: EventClass | null,
  entryId: string,
  eventEntry?: EventEntry | null,
  order?: number | null,
  isRandom?: boolean | null,
  createdOn: string,
  updatedOn: string,
};

export type EventClass = {
  __typename: "EventClass",
  id: string,
  name: string,
  eventId: string,
  classId: string,
  class?: Class | null,
  eventDivisionId?: string | null,
  eventDivision?: EventDivision | null,
  organizationClassId?: string | null,
  organizationClass?: OrganizationClass | null,
  organizationDivisionId?: string | null,
  organizationDivision?: OrganizationDivision | null,
  eventResults?: ModelEventResultConnection | null,
  pointTable?: PointTable | null,
  prizeMoneyTable?: PrizeMoneyTable | null,
  type?: string | null,
  number?: number | null,
  entryOption?: EntryOption | null,
  entryFee?: string | null,
  fees?: ModelEventFeeConnection | null,
  prizeMoney?: string | null,
  entries?: ModelEventClassEntryConnection | null,
  completedVerifyEntries?: boolean | null,
  currentNumberEntries?: number | null,
  numberEntriesThatCompeted?: number | null,
  minNumberEntries?: number | null,
  maxNumberEntries?: number | null,
  estimatedTimePerRound?: number | null,
  isCaliforniaSplit?: boolean | null,
  isCombined?: boolean | null,
  combinedEventClassId?: string | null,
  combinedEventDivisionId?: string | null,
  jumpHeight?: Array< string | null > | null,
  isScheduled?: boolean | null,
  note?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  eventClassPointTableId?: string | null,
  eventClassPrizeMoneyTableId?: string | null,
};

export type EventDivision = {
  __typename: "EventDivision",
  id: string,
  eventId: string,
  name: string,
  number?: number | null,
  divisionId: string,
  division?: Division | null,
  organizationDivisionId?: string | null,
  organizationDivision?: OrganizationDivision | null,
  eventClasses?: ModelEventClassConnection | null,
  eventResults?: ModelEventResultConnection | null,
  pointTable?: PointTable | null,
  prizeMoneyTable?: PrizeMoneyTable | null,
  entryFee?: string | null,
  prizeMoney?: string | null,
  jumpHeight?: Array< string | null > | null,
  minNumberEntriesPerClass?: number | null,
  maxNumberEntriesPerClass?: number | null,
  isCaliforniaSplit?: boolean | null,
  isCombined?: boolean | null,
  combinedEventDivisionId?: string | null,
  combinedEventDivision?: EventDivision | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  eventDivisionOrganizationDivisionId?: string | null,
  eventDivisionPointTableId?: string | null,
  eventDivisionPrizeMoneyTableId?: string | null,
};

export type ModelEventClassConnection = {
  __typename: "ModelEventClassConnection",
  items:  Array<EventClass | null >,
  nextToken?: string | null,
};

export type ModelEventResultConnection = {
  __typename: "ModelEventResultConnection",
  items:  Array<EventResult | null >,
  nextToken?: string | null,
};

export type EventResult = {
  __typename: "EventResult",
  id: string,
  eventId: string,
  entryId: string,
  entry: EventEntry,
  eventClassEntryId?: string | null,
  eventClassEntry?: EventClassEntry | null,
  eventDivisionId?: string | null,
  eventDivision?: EventDivision | null,
  eventDivisionName?: string | null,
  eventClassId?: string | null,
  eventClass?: EventClass | null,
  eventClassName?: string | null,
  resultSet?: string | null,
  place?: number | null,
  score?: number | null,
  time?: string | null,
  didNotCompete?: boolean | null,
  prizeMoney?: number | null,
  prizeMoneyTable?: PrizeMoneyTable | null,
  pointTables?: ModelPointTableConnection | null,
  note?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventResultEntryId: string,
  eventResultEventClassEntryId?: string | null,
  eventResultPrizeMoneyTableId?: string | null,
};

export type EventEntry = {
  __typename: "EventEntry",
  id: string,
  eventId: string,
  status: string,
  statusNote?: string | null,
  personId?: string | null,
  number?: number | null,
  isCheckedIn?: boolean | null,
  isCheckedOut?: boolean | null,
  contactId: string,
  primarycontact?: Contact | null,
  riderName?: string | null,
  riderId?: string | null,
  rider?: Rider | null,
  secondRiderName?: string | null,
  secondRiderId?: string | null,
  secondRider?: Rider | null,
  thirdRiderName?: string | null,
  thirdRiderId?: string | null,
  thirdRider?: Rider | null,
  horseName?: string | null,
  horseId?: string | null,
  horse?: Horse | null,
  ownerName?: string | null,
  ownerId?: string | null,
  owner?: Owner | null,
  secondOwnerName?: string | null,
  secondOwnerId?: string | null,
  secondOwner?: Owner | null,
  thirdOwnerName?: string | null,
  thirdOwnerId?: string | null,
  thirdOwner?: Owner | null,
  trainerName?: string | null,
  trainerId?: string | null,
  trainer?: Trainer | null,
  secondTrainerName?: string | null,
  secondTrainerId?: string | null,
  secondTrainer?: Trainer | null,
  thirdTrainerName?: string | null,
  thirdTrainerId?: string | null,
  thirdTrainer?: Trainer | null,
  barnName?: string | null,
  barnId?: string | null,
  barn?: Barn | null,
  stablingRequestId?: string | null,
  stablingRequest?: StablingRequest | null,
  beddingRequestId?: string | null,
  beddingRequest?: BeddingRequest | null,
  markedDoesNotNeedStalls?: boolean | null,
  payerName?: string | null,
  payerId?: string | null,
  payer?: Payer | null,
  prizeMoneyRecipientName?: string | null,
  prizeMoneyRecipientId?: string | null,
  prizeMoneyRecipient?: Person | null,
  prizeMoneyRecipientContact?: Contact | null,
  prizeMoneyRecipientType?: string | null,
  eventRulesAgreements?: ModelEventRulesAgreementConnection | null,
  note?: string | null,
  divisionList?: Array< string | null > | null,
  classList?: ModelEventClassEntryConnection | null,
  fees?:  Array<EventFee | null > | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  eventEntryPrizeMoneyRecipientContactId?: string | null,
};

export type StablingRequest = {
  __typename: "StablingRequest",
  id: string,
  eventId: string,
  trainerId?: string | null,
  trainer?: Trainer | null,
  barnId?: string | null,
  barn?: Barn | null,
  personId?: string | null,
  person?: Person | null,
  arrivalDateTime?: string | null,
  departureDateTime?: string | null,
  stallTypeId?: string | null,
  stallType?: EventStallType | null,
  quantityNeeded?: number | null,
  basePrice?: number | null,
  taxA?: number | null,
  taxB?: number | null,
  totalPrice?: number | null,
  status?: string | null,
  statusNote?: string | null,
  requestNote?: string | null,
  stallSetId?: string | null,
  stallSet?: EventStallSet | null,
  stallLocation?: string | null,
  stallNumbers?: string | null,
  payerId?: string | null,
  payerName?: string | null,
  payer?: Payer | null,
  splitAcrossEntries?: boolean | null,
  entries?: ModelEventEntryConnection | null,
  entryIds?: Array< string | null > | null,
  submittedEntry?: string | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type EventStallType = {
  __typename: "EventStallType",
  id: string,
  eventId: string,
  organizationId?: string | null,
  organizationStallTypeId?: string | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  pricePerStall?: string | null,
  taxPerStall?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type EventStallSet = {
  __typename: "EventStallSet",
  id: string,
  eventId: string,
  organizationId?: string | null,
  organizationStallSetId?: string | null,
  name?: string | null,
  location?: string | null,
  available?: string | null,
  totalAvailable?: number | null,
  used?: string | null,
  totalUsed?: number | null,
  createdOn: string,
  updatedOn: string,
};

export type Payer = {
  __typename: "Payer",
  id: string,
  name: string,
  contact?: Contact | null,
  personId?: string | null,
  paymentMethod?: PaymentMethod | null,
  createdOn: string,
  updatedOn: string,
  payerContactId?: string | null,
  payerPaymentMethodId?: string | null,
};

export type PaymentMethod = {
  __typename: "PaymentMethod",
  id: string,
  personId?: string | null,
  organizationId?: string | null,
  type?: PaymentMethodType | null,
  stripeId?: string | null,
  altId?: string | null,
  value?: string | null,
  createdOn: string,
  updatedOn: string,
};

export enum PaymentMethodType {
  check = "check",
  debit_card = "debit_card",
  credit_card = "credit_card",
  ach = "ach",
  venmo = "venmo",
  gift_card = "gift_card",
}


export type ModelEventEntryConnection = {
  __typename: "ModelEventEntryConnection",
  items:  Array<EventEntry | null >,
  nextToken?: string | null,
};

export type BeddingRequest = {
  __typename: "BeddingRequest",
  id: string,
  eventId: string,
  trainerId?: string | null,
  trainer?: Trainer | null,
  barnId?: string | null,
  barn?: Barn | null,
  personId?: string | null,
  person?: Person | null,
  arrivalDateTime?: string | null,
  dateTimeNeeded?: string | null,
  deliveryInfo?: string | null,
  beddingType?: string | null,
  quantityNeeded?: number | null,
  basePrice?: number | null,
  taxA?: TaxRate | null,
  taxB?: TaxRate | null,
  totalPrice?: number | null,
  status?: string | null,
  statusNote?: string | null,
  payerId?: string | null,
  payerName?: string | null,
  payer?: Payer | null,
  splitAcrossEntries?: boolean | null,
  entries?: ModelEventEntryConnection | null,
  entryIds?: Array< string | null > | null,
  submittedEntry?: string | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  beddingRequestTaxAId?: string | null,
  beddingRequestTaxBId?: string | null,
};

export type TaxRate = {
  __typename: "TaxRate",
  id: string,
  name?: string | null,
  createdBy?: string | null,
  organizationId?: string | null,
  stripeTaxRateId?: string | null,
  percentage?: number | null,
  createdOn: string,
  updatedOn: string,
};

export type ModelEventRulesAgreementConnection = {
  __typename: "ModelEventRulesAgreementConnection",
  items:  Array<EventRulesAgreement | null >,
  nextToken?: string | null,
};

export type EventRulesAgreement = {
  __typename: "EventRulesAgreement",
  id: string,
  eventId: string,
  eventRuleId: string,
  eventRule?: EventRules | null,
  eventEntryId?: string | null,
  eventEntry?: EventEntry | null,
  name?: string | null,
  personId?: string | null,
  personType?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type EventRules = {
  __typename: "EventRules",
  id: string,
  eventId: string,
  title?: string | null,
  text?: string | null,
  requiresAgreementPerEntry?: boolean | null,
  agreements?: ModelEventRulesAgreementConnection | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type ModelEventClassEntryConnection = {
  __typename: "ModelEventClassEntryConnection",
  items:  Array<EventClassEntry | null >,
  nextToken?: string | null,
};

export type EventClassEntry = {
  __typename: "EventClassEntry",
  id: string,
  eventId: string,
  eventClassId: string,
  eventClass?: EventClass | null,
  eventEntryId: string,
  eventEntry?: EventEntry | null,
  riderId?: string | null,
  rider?: Rider | null,
  status?: string | null,
  selectedEntryOption?: EntryOption | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export enum EntryOption {
  classOnly = "classOnly",
  divisionOnly = "divisionOnly",
  classOrDivision = "classOrDivision",
}


export type EventFee = {
  __typename: "EventFee",
  id: string,
  name: string,
  amount: number,
  description?: string | null,
  eventId: string,
  createdBy: string,
  eventClassId?: string | null,
  eventClass?: EventClass | null,
  feeId: string,
  fee?: Fee | null,
  splitAcrossEntries?: boolean | null,
  entries?:  Array<EventEntry | null > | null,
  createdOn: string,
  updatedOn: string,
};

export type Fee = {
  __typename: "Fee",
  id: string,
  name: string,
  description?: string | null,
  createdBy: string,
  organizationId?: string | null,
  stripeId?: string | null,
  altId?: string | null,
  isFixed?: boolean | null,
  isPercentage?: boolean | null,
  totalPrice?: number | null,
  quantity?: number | null,
  isPerEntry?: boolean | null,
  isPerHorse?: boolean | null,
  isPerRider?: boolean | null,
  isPerClass?: boolean | null,
  isPerTrainer?: boolean | null,
  isRefundable?: boolean | null,
  isChargedAtTimeOfEntry?: boolean | null,
  isStartedOnEntryDate?: boolean | null,
  isHiddenFromEntryForm?: boolean | null,
  startDate?: string | null,
  startTime?: string | null,
  taxA?: number | null,
  taxB?: number | null,
  taxRateA?: TaxRate | null,
  taxRateB?: TaxRate | null,
  createdOn: string,
  updatedOn: string,
  eventInvoiceFeesId?: string | null,
  feeTaxRateAId?: string | null,
  feeTaxRateBId?: string | null,
};

export type ModelPointTableConnection = {
  __typename: "ModelPointTableConnection",
  items:  Array<PointTable | null >,
  nextToken?: string | null,
};

export type ModelEventFeeConnection = {
  __typename: "ModelEventFeeConnection",
  items:  Array<EventFee | null >,
  nextToken?: string | null,
};

export type UpdateClassOrderInput = {
  id: string,
  eventId?: string | null,
  eventClassId?: string | null,
  entryId?: string | null,
  order?: number | null,
  isRandom?: boolean | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteClassOrderInput = {
  id: string,
};

export type CreateClinicianInput = {
  id?: string | null,
  name: string,
  personId?: string | null,
  judgeId?: string | null,
  contactId?: string | null,
  rating?: Array< JudgeRatingInput | null > | null,
  usefNumber?: string | null,
  ecNumber?: string | null,
  price?: number | null,
  status?: ClinicianStatus | null,
  paymentVerified?: boolean | null,
  stripeAccountId?: string | null,
  totalEarned?: number | null,
  location?: string | null,
  disciplines?: string | null,
  experience?: string | null,
  personal?: string | null,
  preferences?: string | null,
  hasIHSAExperience?: boolean | null,
  hasIEAExperience?: boolean | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  clinicianPaymentChoiceId?: string | null,
};

export type JudgeRatingInput = {
  type?: JudgeType | null,
  license?: JudgeLicense | null,
};

export enum JudgeType {
  R = "R",
  r = "r",
  S = "S",
  C = "C",
  I = "I",
  O = "O",
}


export enum JudgeLicense {
  Eventing = "Eventing",
  Eventing_CD = "Eventing_CD",
  Eventing_JCD = "Eventing_JCD",
  Eventing_TD = "Eventing_TD",
  FEI_Dressage = "FEI_Dressage",
  FEI_Eventing = "FEI_Eventing",
  FEI_Veterinarian = "FEI_Veterinarian",
  Hunter = "Hunter",
  Hunter_Breeding = "Hunter_Breeding",
  Hunter_CD = "Hunter_CD",
  Hunter_Jumping_Seat_Equitation = "Hunter_Jumping_Seat_Equitation",
  Jumper = "Jumper",
  Jumper_CD = "Jumper_CD",
  Western = "Western",
  Western_Dressage = "Western_Dressage",
}


export enum ClinicianStatus {
  AVAILABLE = "AVAILABLE",
  PENDING = "PENDING",
  PAUSED = "PAUSED",
  INACTIVE = "INACTIVE",
  BANNED = "BANNED",
}


export type ModelClinicianConditionInput = {
  name?: ModelStringInput | null,
  personId?: ModelIDInput | null,
  judgeId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  usefNumber?: ModelStringInput | null,
  ecNumber?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  status?: ModelClinicianStatusInput | null,
  paymentVerified?: ModelBooleanInput | null,
  stripeAccountId?: ModelStringInput | null,
  totalEarned?: ModelFloatInput | null,
  location?: ModelStringInput | null,
  disciplines?: ModelStringInput | null,
  experience?: ModelStringInput | null,
  personal?: ModelStringInput | null,
  preferences?: ModelStringInput | null,
  hasIHSAExperience?: ModelBooleanInput | null,
  hasIEAExperience?: ModelBooleanInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelClinicianConditionInput | null > | null,
  or?: Array< ModelClinicianConditionInput | null > | null,
  not?: ModelClinicianConditionInput | null,
  clinicianPaymentChoiceId?: ModelIDInput | null,
};

export type ModelClinicianStatusInput = {
  eq?: ClinicianStatus | null,
  ne?: ClinicianStatus | null,
};

export type Clinician = {
  __typename: "Clinician",
  id: string,
  name: string,
  personId?: string | null,
  person?: Person | null,
  judgeId?: string | null,
  contactId?: string | null,
  rating?:  Array<JudgeRating | null > | null,
  usefNumber?: string | null,
  ecNumber?: string | null,
  price?: number | null,
  status?: ClinicianStatus | null,
  paymentChoice?: PaymentMethod | null,
  paymentVerified?: boolean | null,
  stripeAccountId?: string | null,
  totalEarned?: number | null,
  location?: string | null,
  disciplines?: string | null,
  experience?: string | null,
  personal?: string | null,
  preferences?: string | null,
  hasIHSAExperience?: boolean | null,
  hasIEAExperience?: boolean | null,
  createdOn: string,
  updatedOn: string,
  clinicianPaymentChoiceId?: string | null,
};

export type JudgeRating = {
  __typename: "JudgeRating",
  type?: JudgeType | null,
  license?: JudgeLicense | null,
};

export type UpdateClinicianInput = {
  id: string,
  name?: string | null,
  personId?: string | null,
  judgeId?: string | null,
  contactId?: string | null,
  rating?: Array< JudgeRatingInput | null > | null,
  usefNumber?: string | null,
  ecNumber?: string | null,
  price?: number | null,
  status?: ClinicianStatus | null,
  paymentVerified?: boolean | null,
  stripeAccountId?: string | null,
  totalEarned?: number | null,
  location?: string | null,
  disciplines?: string | null,
  experience?: string | null,
  personal?: string | null,
  preferences?: string | null,
  hasIHSAExperience?: boolean | null,
  hasIEAExperience?: boolean | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  clinicianPaymentChoiceId?: string | null,
};

export type DeleteClinicianInput = {
  id: string,
};

export type CreateCompetitionInput = {
  id?: string | null,
  name: string,
  startDate?: string | null,
  endDate?: string | null,
  organizationId?: string | null,
  teamId?: string | null,
  addressId?: string | null,
  competitionOrganizerName: string,
  competitionOrganizerContactId?: string | null,
  judgeName?: string | null,
  secretaryName?: string | null,
  StewardName?: string | null,
  isOpenOffered?: boolean | null,
  isIntermediateOffered?: boolean | null,
  isNoviceOffered?: boolean | null,
  isAdvancedBeginnerOffered?: boolean | null,
  isBeginnerOffered?: boolean | null,
  isMinisOffered?: boolean | null,
  isAdultOffered?: boolean | null,
  isAELMedalOffered?: boolean | null,
  entryFeePerRider?: number | null,
  entryFeePerRiderForMedal?: number | null,
  feePaymentOptions?: string | null,
  entryClosingDate?: string | null,
  entryClosingTime?: string | null,
  isEntryLimitInPlace?: boolean | null,
  entryLimitDescription?: string | null,
  otherNotes?: string | null,
  status?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelCompetitionConditionInput = {
  name?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  teamId?: ModelIDInput | null,
  addressId?: ModelIDInput | null,
  competitionOrganizerName?: ModelStringInput | null,
  competitionOrganizerContactId?: ModelIDInput | null,
  judgeName?: ModelStringInput | null,
  secretaryName?: ModelStringInput | null,
  StewardName?: ModelStringInput | null,
  isOpenOffered?: ModelBooleanInput | null,
  isIntermediateOffered?: ModelBooleanInput | null,
  isNoviceOffered?: ModelBooleanInput | null,
  isAdvancedBeginnerOffered?: ModelBooleanInput | null,
  isBeginnerOffered?: ModelBooleanInput | null,
  isMinisOffered?: ModelBooleanInput | null,
  isAdultOffered?: ModelBooleanInput | null,
  isAELMedalOffered?: ModelBooleanInput | null,
  entryFeePerRider?: ModelFloatInput | null,
  entryFeePerRiderForMedal?: ModelFloatInput | null,
  feePaymentOptions?: ModelStringInput | null,
  entryClosingDate?: ModelStringInput | null,
  entryClosingTime?: ModelStringInput | null,
  isEntryLimitInPlace?: ModelBooleanInput | null,
  entryLimitDescription?: ModelStringInput | null,
  otherNotes?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelCompetitionConditionInput | null > | null,
  or?: Array< ModelCompetitionConditionInput | null > | null,
  not?: ModelCompetitionConditionInput | null,
};

export type Competition = {
  __typename: "Competition",
  id: string,
  name: string,
  startDate?: string | null,
  endDate?: string | null,
  organizationId?: string | null,
  teamId?: string | null,
  addressId?: string | null,
  address?: Address | null,
  competitionOrganizerName: string,
  competitionOrganizerContactId?: string | null,
  contact?: Contact | null,
  judgeName?: string | null,
  secretaryName?: string | null,
  StewardName?: string | null,
  isOpenOffered?: boolean | null,
  isIntermediateOffered?: boolean | null,
  isNoviceOffered?: boolean | null,
  isAdvancedBeginnerOffered?: boolean | null,
  isBeginnerOffered?: boolean | null,
  isMinisOffered?: boolean | null,
  isAdultOffered?: boolean | null,
  isAELMedalOffered?: boolean | null,
  entryFeePerRider?: number | null,
  entryFeePerRiderForMedal?: number | null,
  feePaymentOptions?: string | null,
  entryClosingDate?: string | null,
  entryClosingTime?: string | null,
  isEntryLimitInPlace?: boolean | null,
  entryLimitDescription?: string | null,
  otherNotes?: string | null,
  status?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateCompetitionInput = {
  id: string,
  name?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  organizationId?: string | null,
  teamId?: string | null,
  addressId?: string | null,
  competitionOrganizerName?: string | null,
  competitionOrganizerContactId?: string | null,
  judgeName?: string | null,
  secretaryName?: string | null,
  StewardName?: string | null,
  isOpenOffered?: boolean | null,
  isIntermediateOffered?: boolean | null,
  isNoviceOffered?: boolean | null,
  isAdvancedBeginnerOffered?: boolean | null,
  isBeginnerOffered?: boolean | null,
  isMinisOffered?: boolean | null,
  isAdultOffered?: boolean | null,
  isAELMedalOffered?: boolean | null,
  entryFeePerRider?: number | null,
  entryFeePerRiderForMedal?: number | null,
  feePaymentOptions?: string | null,
  entryClosingDate?: string | null,
  entryClosingTime?: string | null,
  isEntryLimitInPlace?: boolean | null,
  entryLimitDescription?: string | null,
  otherNotes?: string | null,
  status?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteCompetitionInput = {
  id: string,
};

export type CreateContactInput = {
  id?: string | null,
  name?: string | null,
  cell?: string | null,
  home?: string | null,
  work?: string | null,
  fax?: string | null,
  personalEmail?: string | null,
  personalEmailIsVerified?: boolean | null,
  workEmail?: string | null,
  workEmailIsVerified?: boolean | null,
  mailingAddress?: string | null,
  website?: string | null,
  facebookPage?: string | null,
  instagramHandle?: string | null,
  twitterHandle?: string | null,
  tiktokHandle?: string | null,
  emergencyContactName?: string | null,
  emergencyContactPhone?: string | null,
  createdBy?: string | null,
};

export type ModelContactConditionInput = {
  name?: ModelStringInput | null,
  cell?: ModelStringInput | null,
  home?: ModelStringInput | null,
  work?: ModelStringInput | null,
  fax?: ModelStringInput | null,
  personalEmail?: ModelStringInput | null,
  personalEmailIsVerified?: ModelBooleanInput | null,
  workEmail?: ModelStringInput | null,
  workEmailIsVerified?: ModelBooleanInput | null,
  mailingAddress?: ModelIDInput | null,
  website?: ModelStringInput | null,
  facebookPage?: ModelStringInput | null,
  instagramHandle?: ModelStringInput | null,
  twitterHandle?: ModelStringInput | null,
  tiktokHandle?: ModelStringInput | null,
  emergencyContactName?: ModelStringInput | null,
  emergencyContactPhone?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  and?: Array< ModelContactConditionInput | null > | null,
  or?: Array< ModelContactConditionInput | null > | null,
  not?: ModelContactConditionInput | null,
};

export type UpdateContactInput = {
  id: string,
  name?: string | null,
  cell?: string | null,
  home?: string | null,
  work?: string | null,
  fax?: string | null,
  personalEmail?: string | null,
  personalEmailIsVerified?: boolean | null,
  workEmail?: string | null,
  workEmailIsVerified?: boolean | null,
  mailingAddress?: string | null,
  website?: string | null,
  facebookPage?: string | null,
  instagramHandle?: string | null,
  twitterHandle?: string | null,
  tiktokHandle?: string | null,
  emergencyContactName?: string | null,
  emergencyContactPhone?: string | null,
  createdBy?: string | null,
};

export type DeleteContactInput = {
  id: string,
};

export type CreateDivisionInput = {
  id?: string | null,
  name: string,
  nickname?: string | null,
  description?: string | null,
  discipline?: string | null,
  isSearchable?: boolean | null,
  ushjaName?: string | null,
  usefNumber?: string | null,
  ecNumber?: string | null,
  ecClassTypeNumber?: string | null,
  ecClassType?: string | null,
  code?: string | null,
  specs?: string | null,
  judgedOn?: string | null,
  rules?: DivisionRulesInput | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventInvoiceDivisionsId?: string | null,
};

export type ModelDivisionConditionInput = {
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  description?: ModelStringInput | null,
  discipline?: ModelStringInput | null,
  isSearchable?: ModelBooleanInput | null,
  ushjaName?: ModelStringInput | null,
  usefNumber?: ModelStringInput | null,
  ecNumber?: ModelStringInput | null,
  ecClassTypeNumber?: ModelStringInput | null,
  ecClassType?: ModelStringInput | null,
  code?: ModelStringInput | null,
  specs?: ModelStringInput | null,
  judgedOn?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelDivisionConditionInput | null > | null,
  or?: Array< ModelDivisionConditionInput | null > | null,
  not?: ModelDivisionConditionInput | null,
  eventInvoiceDivisionsId?: ModelIDInput | null,
};

export type UpdateDivisionInput = {
  id: string,
  name?: string | null,
  nickname?: string | null,
  description?: string | null,
  discipline?: string | null,
  isSearchable?: boolean | null,
  ushjaName?: string | null,
  usefNumber?: string | null,
  ecNumber?: string | null,
  ecClassTypeNumber?: string | null,
  ecClassType?: string | null,
  code?: string | null,
  specs?: string | null,
  judgedOn?: string | null,
  rules?: DivisionRulesInput | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventInvoiceDivisionsId?: string | null,
};

export type DeleteDivisionInput = {
  id: string,
};

export type CreateEventInput = {
  id?: string | null,
  name: string,
  nickname?: string | null,
  createdBy: string,
  manager?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  contactId?: string | null,
  contactIds?: Array< string | null > | null,
  organizationId?: string | null,
  type?: string | null,
  disciplines?: string | null,
  eventOptions?: EventOptionsInput | null,
  paymentOptions?: PaymentOptionsInput | null,
  invoiceOptions?: InvoiceOptionsInput | null,
  entryOptions?: EntryOptionsInput | null,
  status?: string | null,
  entriesStatus?: string | null,
  showStatus?: string | null,
  usefID?: string | null,
  usefSoftwareKey?: string | null,
  ecID?: string | null,
  ecPassword?: string | null,
  documents?: Array< S3ObjectInput | null > | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventAddressId?: string | null,
};

export type EventOptionsInput = {
  autoAcceptNewEntries?: boolean | null,
  usesTextAlerts?: boolean | null,
  acceptsIncomingTextAlerts?: boolean | null,
  hasUnlimitedCredits?: boolean | null,
  usesEventManagement?: boolean | null,
  usesStablingManagement?: boolean | null,
  usesVendorManagement?: boolean | null,
  usesMediaTools?: boolean | null,
  usesMarketingTools?: boolean | null,
  usesFinanceTools?: boolean | null,
  usesJudgeApp?: boolean | null,
};

export type PaymentOptionsInput = {
  acceptsChecks?: boolean | null,
  acceptsCash?: boolean | null,
  acceptsACH?: boolean | null,
  acceptsCC?: boolean | null,
};

export type InvoiceOptionsInput = {
  classFeeTax?: number | null,
  sendEmails?: boolean | null,
};

export type EntryOptionsInput = {
  displayHorse2Input?: boolean | null,
  displayRider2Input?: boolean | null,
  displayRider3Input?: boolean | null,
  requiresCogginsDocument?: boolean | null,
  requiresCogginsData?: boolean | null,
  maximumNumberClassesOnEntry?: number | null,
};

export type ModelEventConditionInput = {
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  manager?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  contactId?: ModelIDInput | null,
  contactIds?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  disciplines?: ModelStringInput | null,
  status?: ModelStringInput | null,
  entriesStatus?: ModelStringInput | null,
  showStatus?: ModelStringInput | null,
  usefID?: ModelStringInput | null,
  usefSoftwareKey?: ModelStringInput | null,
  ecID?: ModelStringInput | null,
  ecPassword?: ModelStringInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
  eventAddressId?: ModelIDInput | null,
};

export type UpdateEventInput = {
  id: string,
  name?: string | null,
  nickname?: string | null,
  createdBy?: string | null,
  manager?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  contactId?: string | null,
  contactIds?: Array< string | null > | null,
  organizationId?: string | null,
  type?: string | null,
  disciplines?: string | null,
  eventOptions?: EventOptionsInput | null,
  paymentOptions?: PaymentOptionsInput | null,
  invoiceOptions?: InvoiceOptionsInput | null,
  entryOptions?: EntryOptionsInput | null,
  status?: string | null,
  entriesStatus?: string | null,
  showStatus?: string | null,
  usefID?: string | null,
  usefSoftwareKey?: string | null,
  ecID?: string | null,
  ecPassword?: string | null,
  documents?: Array< S3ObjectInput | null > | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventAddressId?: string | null,
};

export type DeleteEventInput = {
  id: string,
};

export type CreateEventAddInput = {
  id?: string | null,
  eventId: string,
  entryId: string,
  eventDivisionId?: string | null,
  eventDivisionName?: string | null,
  eventClassId?: string | null,
  eventClassName?: string | null,
  status?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventAddConditionInput = {
  eventId?: ModelIDInput | null,
  entryId?: ModelIDInput | null,
  eventDivisionId?: ModelIDInput | null,
  eventDivisionName?: ModelStringInput | null,
  eventClassId?: ModelIDInput | null,
  eventClassName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventAddConditionInput | null > | null,
  or?: Array< ModelEventAddConditionInput | null > | null,
  not?: ModelEventAddConditionInput | null,
};

export type EventAdd = {
  __typename: "EventAdd",
  id: string,
  eventId: string,
  entryId: string,
  entry: EventEntry,
  eventDivisionId?: string | null,
  eventDivision?: EventDivision | null,
  eventDivisionName?: string | null,
  eventClassId?: string | null,
  eventClass?: EventClass | null,
  eventClassName?: string | null,
  status?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateEventAddInput = {
  id: string,
  eventId?: string | null,
  entryId?: string | null,
  eventDivisionId?: string | null,
  eventDivisionName?: string | null,
  eventClassId?: string | null,
  eventClassName?: string | null,
  status?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventAddInput = {
  id: string,
};

export type CreateEventAlertSettingInput = {
  id?: string | null,
  eventId?: string | null,
  categories?: Array< string | null > | null,
  useDescription?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventAlertSettingConditionInput = {
  eventId?: ModelIDInput | null,
  categories?: ModelStringInput | null,
  useDescription?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventAlertSettingConditionInput | null > | null,
  or?: Array< ModelEventAlertSettingConditionInput | null > | null,
  not?: ModelEventAlertSettingConditionInput | null,
};

export type EventAlertSetting = {
  __typename: "EventAlertSetting",
  id: string,
  eventId?: string | null,
  categories?: Array< string | null > | null,
  useDescription?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateEventAlertSettingInput = {
  id: string,
  eventId?: string | null,
  categories?: Array< string | null > | null,
  useDescription?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventAlertSettingInput = {
  id: string,
};

export type CreateEventAuditorInput = {
  id?: string | null,
  personId?: string | null,
  eventId?: string | null,
  auditorId?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  status?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventAuditorEventId?: string | null,
};

export type ModelEventAuditorConditionInput = {
  personId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  auditorId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventAuditorConditionInput | null > | null,
  or?: Array< ModelEventAuditorConditionInput | null > | null,
  not?: ModelEventAuditorConditionInput | null,
  eventAuditorEventId?: ModelIDInput | null,
};

export type EventAuditor = {
  __typename: "EventAuditor",
  id: string,
  personId?: string | null,
  person?: Person | null,
  eventId?: string | null,
  event?: Event | null,
  auditorId?: string | null,
  auditor?: Auditor | null,
  filters?: ModelEventAuditorFilterConnection | null,
  name?: string | null,
  phoneNumber?: string | null,
  status?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  eventAuditorEventId?: string | null,
};

export type ModelEventAuditorFilterConnection = {
  __typename: "ModelEventAuditorFilterConnection",
  items:  Array<EventAuditorFilter | null >,
  nextToken?: string | null,
};

export type EventAuditorFilter = {
  __typename: "EventAuditorFilter",
  id: string,
  personId?: string | null,
  person?: Person | null,
  eventId?: string | null,
  event?: Event | null,
  eventauditorid?: string | null,
  eventAuditorId?: string | null,
  eventAuditor?: EventAuditor | null,
  organizationId?: string | null,
  organization?: Organization | null,
  organizationAuditorId?: string | null,
  organizationAuditor?: OrganizationAuditor | null,
  filterId?: string | null,
  filter?: EventFilter | null,
  options?: Array< string | null > | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  eventAuditorFilterOrganizationId?: string | null,
};

export type OrganizationAuditor = {
  __typename: "OrganizationAuditor",
  id: string,
  personId?: string | null,
  person?: Person | null,
  howSignedUp?: string | null,
  organizationId?: string | null,
  organization?: Organization | null,
  auditorId?: string | null,
  auditor?: Auditor | null,
  filters?: ModelEventAuditorFilterConnection | null,
  name?: string | null,
  phoneNumber?: string | null,
  status?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  organizationAuditorPersonId?: string | null,
  organizationAuditorOrganizationId?: string | null,
};

export type EventFilter = {
  __typename: "EventFilter",
  id: string,
  eventId?: string | null,
  organizationID?: string | null,
  name?: string | null,
  options?: Array< string | null > | null,
  isRequired?: boolean | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateEventAuditorInput = {
  id: string,
  personId?: string | null,
  eventId?: string | null,
  auditorId?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  status?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventAuditorEventId?: string | null,
};

export type DeleteEventAuditorInput = {
  id: string,
};

export type CreateOrganizationAuditorInput = {
  id?: string | null,
  personId?: string | null,
  howSignedUp?: string | null,
  organizationId?: string | null,
  auditorId?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  status?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  organizationAuditorPersonId?: string | null,
  organizationAuditorOrganizationId?: string | null,
};

export type ModelOrganizationAuditorConditionInput = {
  personId?: ModelIDInput | null,
  howSignedUp?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  auditorId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationAuditorConditionInput | null > | null,
  or?: Array< ModelOrganizationAuditorConditionInput | null > | null,
  not?: ModelOrganizationAuditorConditionInput | null,
  organizationAuditorPersonId?: ModelIDInput | null,
  organizationAuditorOrganizationId?: ModelIDInput | null,
};

export type UpdateOrganizationAuditorInput = {
  id: string,
  personId?: string | null,
  howSignedUp?: string | null,
  organizationId?: string | null,
  auditorId?: string | null,
  name?: string | null,
  phoneNumber?: string | null,
  status?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  organizationAuditorPersonId?: string | null,
  organizationAuditorOrganizationId?: string | null,
};

export type DeleteOrganizationAuditorInput = {
  id: string,
};

export type CreateEventAuditorFilterInput = {
  id?: string | null,
  personId?: string | null,
  eventId?: string | null,
  eventauditorid?: string | null,
  eventAuditorId?: string | null,
  organizationId?: string | null,
  organizationAuditorId?: string | null,
  filterId?: string | null,
  options?: Array< string | null > | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventAuditorFilterOrganizationId?: string | null,
};

export type ModelEventAuditorFilterConditionInput = {
  personId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  eventauditorid?: ModelStringInput | null,
  eventAuditorId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  organizationAuditorId?: ModelIDInput | null,
  filterId?: ModelIDInput | null,
  options?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventAuditorFilterConditionInput | null > | null,
  or?: Array< ModelEventAuditorFilterConditionInput | null > | null,
  not?: ModelEventAuditorFilterConditionInput | null,
  eventAuditorFilterOrganizationId?: ModelIDInput | null,
};

export type UpdateEventAuditorFilterInput = {
  id: string,
  personId?: string | null,
  eventId?: string | null,
  eventauditorid?: string | null,
  eventAuditorId?: string | null,
  organizationId?: string | null,
  organizationAuditorId?: string | null,
  filterId?: string | null,
  options?: Array< string | null > | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventAuditorFilterOrganizationId?: string | null,
};

export type DeleteEventAuditorFilterInput = {
  id: string,
};

export type CreateEventBeddingTypeInput = {
  id?: string | null,
  eventId: string,
  organizationId?: string | null,
  organizationBeddingTypeId?: string | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  pricePerBag?: string | null,
  taxPerBag?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventBeddingTypeConditionInput = {
  eventId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  organizationBeddingTypeId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  pricePerBag?: ModelStringInput | null,
  taxPerBag?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventBeddingTypeConditionInput | null > | null,
  or?: Array< ModelEventBeddingTypeConditionInput | null > | null,
  not?: ModelEventBeddingTypeConditionInput | null,
};

export type EventBeddingType = {
  __typename: "EventBeddingType",
  id: string,
  eventId: string,
  organizationId?: string | null,
  organizationBeddingTypeId?: string | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  pricePerBag?: string | null,
  taxPerBag?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateEventBeddingTypeInput = {
  id: string,
  eventId?: string | null,
  organizationId?: string | null,
  organizationBeddingTypeId?: string | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  pricePerBag?: string | null,
  taxPerBag?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventBeddingTypeInput = {
  id: string,
};

export type CreateEventBreakInput = {
  id?: string | null,
  eventId: string,
  name?: string | null,
  time?: string | null,
  isDisplayedOnSchedule?: boolean | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventBreakConditionInput = {
  eventId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  time?: ModelStringInput | null,
  isDisplayedOnSchedule?: ModelBooleanInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventBreakConditionInput | null > | null,
  or?: Array< ModelEventBreakConditionInput | null > | null,
  not?: ModelEventBreakConditionInput | null,
};

export type EventBreak = {
  __typename: "EventBreak",
  id: string,
  eventId: string,
  name?: string | null,
  time?: string | null,
  isDisplayedOnSchedule?: boolean | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateEventBreakInput = {
  id: string,
  eventId?: string | null,
  name?: string | null,
  time?: string | null,
  isDisplayedOnSchedule?: boolean | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventBreakInput = {
  id: string,
};

export type CreateEventClassInput = {
  id?: string | null,
  name: string,
  eventId: string,
  classId: string,
  eventDivisionId?: string | null,
  organizationClassId?: string | null,
  organizationDivisionId?: string | null,
  type?: string | null,
  number?: number | null,
  entryOption?: EntryOption | null,
  entryFee?: string | null,
  prizeMoney?: string | null,
  completedVerifyEntries?: boolean | null,
  currentNumberEntries?: number | null,
  numberEntriesThatCompeted?: number | null,
  minNumberEntries?: number | null,
  maxNumberEntries?: number | null,
  estimatedTimePerRound?: number | null,
  isCaliforniaSplit?: boolean | null,
  isCombined?: boolean | null,
  combinedEventClassId?: string | null,
  combinedEventDivisionId?: string | null,
  jumpHeight?: Array< string | null > | null,
  isScheduled?: boolean | null,
  note?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventClassPointTableId?: string | null,
  eventClassPrizeMoneyTableId?: string | null,
};

export type ModelEventClassConditionInput = {
  name?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  classId?: ModelIDInput | null,
  eventDivisionId?: ModelIDInput | null,
  organizationClassId?: ModelIDInput | null,
  organizationDivisionId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  number?: ModelIntInput | null,
  entryOption?: ModelEntryOptionInput | null,
  entryFee?: ModelStringInput | null,
  prizeMoney?: ModelStringInput | null,
  completedVerifyEntries?: ModelBooleanInput | null,
  currentNumberEntries?: ModelIntInput | null,
  numberEntriesThatCompeted?: ModelIntInput | null,
  minNumberEntries?: ModelIntInput | null,
  maxNumberEntries?: ModelIntInput | null,
  estimatedTimePerRound?: ModelIntInput | null,
  isCaliforniaSplit?: ModelBooleanInput | null,
  isCombined?: ModelBooleanInput | null,
  combinedEventClassId?: ModelIDInput | null,
  combinedEventDivisionId?: ModelIDInput | null,
  jumpHeight?: ModelStringInput | null,
  isScheduled?: ModelBooleanInput | null,
  note?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventClassConditionInput | null > | null,
  or?: Array< ModelEventClassConditionInput | null > | null,
  not?: ModelEventClassConditionInput | null,
  eventClassPointTableId?: ModelIDInput | null,
  eventClassPrizeMoneyTableId?: ModelIDInput | null,
};

export type ModelEntryOptionInput = {
  eq?: EntryOption | null,
  ne?: EntryOption | null,
};

export type UpdateEventClassInput = {
  id: string,
  name?: string | null,
  eventId?: string | null,
  classId?: string | null,
  eventDivisionId?: string | null,
  organizationClassId?: string | null,
  organizationDivisionId?: string | null,
  type?: string | null,
  number?: number | null,
  entryOption?: EntryOption | null,
  entryFee?: string | null,
  prizeMoney?: string | null,
  completedVerifyEntries?: boolean | null,
  currentNumberEntries?: number | null,
  numberEntriesThatCompeted?: number | null,
  minNumberEntries?: number | null,
  maxNumberEntries?: number | null,
  estimatedTimePerRound?: number | null,
  isCaliforniaSplit?: boolean | null,
  isCombined?: boolean | null,
  combinedEventClassId?: string | null,
  combinedEventDivisionId?: string | null,
  jumpHeight?: Array< string | null > | null,
  isScheduled?: boolean | null,
  note?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventClassPointTableId?: string | null,
  eventClassPrizeMoneyTableId?: string | null,
};

export type DeleteEventClassInput = {
  id: string,
};

export type CreateEventClassEntryInput = {
  id?: string | null,
  eventId: string,
  eventClassId: string,
  eventEntryId: string,
  riderId?: string | null,
  status?: string | null,
  selectedEntryOption?: EntryOption | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventClassEntryConditionInput = {
  eventId?: ModelIDInput | null,
  eventClassId?: ModelIDInput | null,
  eventEntryId?: ModelIDInput | null,
  riderId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  selectedEntryOption?: ModelEntryOptionInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventClassEntryConditionInput | null > | null,
  or?: Array< ModelEventClassEntryConditionInput | null > | null,
  not?: ModelEventClassEntryConditionInput | null,
};

export type UpdateEventClassEntryInput = {
  id: string,
  eventId?: string | null,
  eventClassId?: string | null,
  eventEntryId?: string | null,
  riderId?: string | null,
  status?: string | null,
  selectedEntryOption?: EntryOption | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventClassEntryInput = {
  id: string,
};

export type CreateEventDayInput = {
  id?: string | null,
  eventId?: string | null,
  awsdate?: string | null,
  dayofweek?: string | null,
  date?: string | null,
  month?: string | null,
  year?: string | null,
};

export type ModelEventDayConditionInput = {
  eventId?: ModelIDInput | null,
  awsdate?: ModelStringInput | null,
  dayofweek?: ModelStringInput | null,
  date?: ModelStringInput | null,
  month?: ModelStringInput | null,
  year?: ModelStringInput | null,
  and?: Array< ModelEventDayConditionInput | null > | null,
  or?: Array< ModelEventDayConditionInput | null > | null,
  not?: ModelEventDayConditionInput | null,
};

export type EventDay = {
  __typename: "EventDay",
  id: string,
  eventId?: string | null,
  awsdate?: string | null,
  dayofweek?: string | null,
  date?: string | null,
  month?: string | null,
  year?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateEventDayInput = {
  id: string,
  eventId?: string | null,
  awsdate?: string | null,
  dayofweek?: string | null,
  date?: string | null,
  month?: string | null,
  year?: string | null,
};

export type DeleteEventDayInput = {
  id: string,
};

export type CreateEventDivisionInput = {
  id?: string | null,
  eventId: string,
  name: string,
  number?: number | null,
  divisionId: string,
  organizationDivisionId?: string | null,
  entryFee?: string | null,
  prizeMoney?: string | null,
  jumpHeight?: Array< string | null > | null,
  minNumberEntriesPerClass?: number | null,
  maxNumberEntriesPerClass?: number | null,
  isCaliforniaSplit?: boolean | null,
  isCombined?: boolean | null,
  combinedEventDivisionId?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventDivisionOrganizationDivisionId?: string | null,
  eventDivisionPointTableId?: string | null,
  eventDivisionPrizeMoneyTableId?: string | null,
};

export type ModelEventDivisionConditionInput = {
  eventId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  number?: ModelIntInput | null,
  divisionId?: ModelIDInput | null,
  organizationDivisionId?: ModelIDInput | null,
  entryFee?: ModelStringInput | null,
  prizeMoney?: ModelStringInput | null,
  jumpHeight?: ModelStringInput | null,
  minNumberEntriesPerClass?: ModelIntInput | null,
  maxNumberEntriesPerClass?: ModelIntInput | null,
  isCaliforniaSplit?: ModelBooleanInput | null,
  isCombined?: ModelBooleanInput | null,
  combinedEventDivisionId?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventDivisionConditionInput | null > | null,
  or?: Array< ModelEventDivisionConditionInput | null > | null,
  not?: ModelEventDivisionConditionInput | null,
  eventDivisionOrganizationDivisionId?: ModelIDInput | null,
  eventDivisionPointTableId?: ModelIDInput | null,
  eventDivisionPrizeMoneyTableId?: ModelIDInput | null,
};

export type UpdateEventDivisionInput = {
  id: string,
  eventId?: string | null,
  name?: string | null,
  number?: number | null,
  divisionId?: string | null,
  organizationDivisionId?: string | null,
  entryFee?: string | null,
  prizeMoney?: string | null,
  jumpHeight?: Array< string | null > | null,
  minNumberEntriesPerClass?: number | null,
  maxNumberEntriesPerClass?: number | null,
  isCaliforniaSplit?: boolean | null,
  isCombined?: boolean | null,
  combinedEventDivisionId?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventDivisionOrganizationDivisionId?: string | null,
  eventDivisionPointTableId?: string | null,
  eventDivisionPrizeMoneyTableId?: string | null,
};

export type DeleteEventDivisionInput = {
  id: string,
};

export type CreateEventEntryInput = {
  id?: string | null,
  eventId: string,
  status: string,
  statusNote?: string | null,
  personId?: string | null,
  number?: number | null,
  isCheckedIn?: boolean | null,
  isCheckedOut?: boolean | null,
  contactId: string,
  riderName?: string | null,
  riderId?: string | null,
  secondRiderName?: string | null,
  secondRiderId?: string | null,
  thirdRiderName?: string | null,
  thirdRiderId?: string | null,
  horseName?: string | null,
  horseId?: string | null,
  ownerName?: string | null,
  ownerId?: string | null,
  secondOwnerName?: string | null,
  secondOwnerId?: string | null,
  thirdOwnerName?: string | null,
  thirdOwnerId?: string | null,
  trainerName?: string | null,
  trainerId?: string | null,
  secondTrainerName?: string | null,
  secondTrainerId?: string | null,
  thirdTrainerName?: string | null,
  thirdTrainerId?: string | null,
  barnName?: string | null,
  barnId?: string | null,
  stablingRequestId?: string | null,
  beddingRequestId?: string | null,
  markedDoesNotNeedStalls?: boolean | null,
  payerName?: string | null,
  payerId?: string | null,
  prizeMoneyRecipientName?: string | null,
  prizeMoneyRecipientId?: string | null,
  prizeMoneyRecipientType?: string | null,
  note?: string | null,
  divisionList?: Array< string | null > | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventEntryPrizeMoneyRecipientContactId?: string | null,
};

export type ModelEventEntryConditionInput = {
  eventId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  statusNote?: ModelStringInput | null,
  personId?: ModelStringInput | null,
  number?: ModelIntInput | null,
  isCheckedIn?: ModelBooleanInput | null,
  isCheckedOut?: ModelBooleanInput | null,
  contactId?: ModelIDInput | null,
  riderName?: ModelStringInput | null,
  riderId?: ModelIDInput | null,
  secondRiderName?: ModelStringInput | null,
  secondRiderId?: ModelIDInput | null,
  thirdRiderName?: ModelStringInput | null,
  thirdRiderId?: ModelIDInput | null,
  horseName?: ModelStringInput | null,
  horseId?: ModelIDInput | null,
  ownerName?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  secondOwnerName?: ModelStringInput | null,
  secondOwnerId?: ModelIDInput | null,
  thirdOwnerName?: ModelStringInput | null,
  thirdOwnerId?: ModelIDInput | null,
  trainerName?: ModelStringInput | null,
  trainerId?: ModelIDInput | null,
  secondTrainerName?: ModelStringInput | null,
  secondTrainerId?: ModelIDInput | null,
  thirdTrainerName?: ModelStringInput | null,
  thirdTrainerId?: ModelIDInput | null,
  barnName?: ModelStringInput | null,
  barnId?: ModelIDInput | null,
  stablingRequestId?: ModelIDInput | null,
  beddingRequestId?: ModelIDInput | null,
  markedDoesNotNeedStalls?: ModelBooleanInput | null,
  payerName?: ModelStringInput | null,
  payerId?: ModelIDInput | null,
  prizeMoneyRecipientName?: ModelStringInput | null,
  prizeMoneyRecipientId?: ModelIDInput | null,
  prizeMoneyRecipientType?: ModelStringInput | null,
  note?: ModelStringInput | null,
  divisionList?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventEntryConditionInput | null > | null,
  or?: Array< ModelEventEntryConditionInput | null > | null,
  not?: ModelEventEntryConditionInput | null,
  eventEntryPrizeMoneyRecipientContactId?: ModelIDInput | null,
};

export type UpdateEventEntryInput = {
  id: string,
  eventId?: string | null,
  status?: string | null,
  statusNote?: string | null,
  personId?: string | null,
  number?: number | null,
  isCheckedIn?: boolean | null,
  isCheckedOut?: boolean | null,
  contactId?: string | null,
  riderName?: string | null,
  riderId?: string | null,
  secondRiderName?: string | null,
  secondRiderId?: string | null,
  thirdRiderName?: string | null,
  thirdRiderId?: string | null,
  horseName?: string | null,
  horseId?: string | null,
  ownerName?: string | null,
  ownerId?: string | null,
  secondOwnerName?: string | null,
  secondOwnerId?: string | null,
  thirdOwnerName?: string | null,
  thirdOwnerId?: string | null,
  trainerName?: string | null,
  trainerId?: string | null,
  secondTrainerName?: string | null,
  secondTrainerId?: string | null,
  thirdTrainerName?: string | null,
  thirdTrainerId?: string | null,
  barnName?: string | null,
  barnId?: string | null,
  stablingRequestId?: string | null,
  beddingRequestId?: string | null,
  markedDoesNotNeedStalls?: boolean | null,
  payerName?: string | null,
  payerId?: string | null,
  prizeMoneyRecipientName?: string | null,
  prizeMoneyRecipientId?: string | null,
  prizeMoneyRecipientType?: string | null,
  note?: string | null,
  divisionList?: Array< string | null > | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventEntryPrizeMoneyRecipientContactId?: string | null,
};

export type DeleteEventEntryInput = {
  id: string,
};

export type CreateEventEntryFeeInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  status?: string | null,
  amount: number,
  quantity?: number | null,
  taxA?: number | null,
  taxB?: number | null,
  eventId: string,
  feeId: string,
  eventFeeId?: string | null,
  entryId?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventEntryFeeConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  quantity?: ModelIntInput | null,
  taxA?: ModelFloatInput | null,
  taxB?: ModelFloatInput | null,
  eventId?: ModelIDInput | null,
  feeId?: ModelIDInput | null,
  eventFeeId?: ModelIDInput | null,
  entryId?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventEntryFeeConditionInput | null > | null,
  or?: Array< ModelEventEntryFeeConditionInput | null > | null,
  not?: ModelEventEntryFeeConditionInput | null,
};

export type EventEntryFee = {
  __typename: "EventEntryFee",
  id: string,
  name: string,
  description?: string | null,
  status?: string | null,
  amount: number,
  quantity?: number | null,
  taxA?: number | null,
  taxB?: number | null,
  eventId: string,
  feeId: string,
  fee?: Fee | null,
  eventFeeId?: string | null,
  eventFee?: EventFee | null,
  entryId?: string | null,
  entry?: EventEntry | null,
  createdBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateEventEntryFeeInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  status?: string | null,
  amount?: number | null,
  quantity?: number | null,
  taxA?: number | null,
  taxB?: number | null,
  eventId?: string | null,
  feeId?: string | null,
  eventFeeId?: string | null,
  entryId?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventEntryFeeInput = {
  id: string,
};

export type CreateEventEntryNumbersInput = {
  id?: string | null,
  eventId: string,
  name?: string | null,
  available?: string | null,
  totalAvailable?: number | null,
  used?: string | null,
  totalUsed?: number | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventEntryNumbersConditionInput = {
  eventId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  available?: ModelStringInput | null,
  totalAvailable?: ModelIntInput | null,
  used?: ModelStringInput | null,
  totalUsed?: ModelIntInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventEntryNumbersConditionInput | null > | null,
  or?: Array< ModelEventEntryNumbersConditionInput | null > | null,
  not?: ModelEventEntryNumbersConditionInput | null,
};

export type EventEntryNumbers = {
  __typename: "EventEntryNumbers",
  id: string,
  eventId: string,
  name?: string | null,
  available?: string | null,
  totalAvailable?: number | null,
  used?: string | null,
  totalUsed?: number | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateEventEntryNumbersInput = {
  id: string,
  eventId?: string | null,
  name?: string | null,
  available?: string | null,
  totalAvailable?: number | null,
  used?: string | null,
  totalUsed?: number | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventEntryNumbersInput = {
  id: string,
};

export type CreateEventFeeInput = {
  id?: string | null,
  name: string,
  amount: number,
  description?: string | null,
  eventId: string,
  createdBy: string,
  eventClassId?: string | null,
  feeId: string,
  splitAcrossEntries?: boolean | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventFeeConditionInput = {
  name?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  description?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  eventClassId?: ModelIDInput | null,
  feeId?: ModelIDInput | null,
  splitAcrossEntries?: ModelBooleanInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventFeeConditionInput | null > | null,
  or?: Array< ModelEventFeeConditionInput | null > | null,
  not?: ModelEventFeeConditionInput | null,
};

export type UpdateEventFeeInput = {
  id: string,
  name?: string | null,
  amount?: number | null,
  description?: string | null,
  eventId?: string | null,
  createdBy?: string | null,
  eventClassId?: string | null,
  feeId?: string | null,
  splitAcrossEntries?: boolean | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventFeeInput = {
  id: string,
};

export type CreateEventFilterInput = {
  id?: string | null,
  eventId?: string | null,
  organizationID?: string | null,
  name?: string | null,
  options?: Array< string | null > | null,
  isRequired?: boolean | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventFilterConditionInput = {
  eventId?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  options?: ModelStringInput | null,
  isRequired?: ModelBooleanInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventFilterConditionInput | null > | null,
  or?: Array< ModelEventFilterConditionInput | null > | null,
  not?: ModelEventFilterConditionInput | null,
};

export type UpdateEventFilterInput = {
  id: string,
  eventId?: string | null,
  organizationID?: string | null,
  name?: string | null,
  options?: Array< string | null > | null,
  isRequired?: boolean | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventFilterInput = {
  id: string,
};

export type CreateEventInvoiceInput = {
  id?: string | null,
  eventId: string,
  status: string,
  statusNote?: string | null,
  number: number,
  stripeInvoiceNumber?: string | null,
  entryNumber?: number | null,
  entryId?: string | null,
  payerName?: string | null,
  payerId?: string | null,
  payerType?: string | null,
  payerStripeId?: string | null,
  prizeMoneyRecipientName?: string | null,
  prizeMoneyRecipientId?: string | null,
  prizeMoneyRecipientStripeId?: string | null,
  prizeMoneyRecipientType?: string | null,
  entryFeeTotalAmount?: number | null,
  otherFeeTotalAmount?: number | null,
  stablingAmount?: number | null,
  beddingAmount?: number | null,
  feedAmount?: number | null,
  prizeMoneyAmount?: number | null,
  amountToDeductFromInvoice?: number | null,
  amountToPayRecipientViaCheck?: number | null,
  totalDue?: number | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventInvoicePayerContactId?: string | null,
  eventInvoicePrizeMoneyRecipientId?: string | null,
  eventInvoicePrizeMoneyRecipientContactId?: string | null,
};

export type ModelEventInvoiceConditionInput = {
  eventId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  statusNote?: ModelStringInput | null,
  number?: ModelIntInput | null,
  stripeInvoiceNumber?: ModelStringInput | null,
  entryNumber?: ModelIntInput | null,
  entryId?: ModelIDInput | null,
  payerName?: ModelStringInput | null,
  payerId?: ModelIDInput | null,
  payerType?: ModelStringInput | null,
  payerStripeId?: ModelStringInput | null,
  prizeMoneyRecipientName?: ModelStringInput | null,
  prizeMoneyRecipientId?: ModelIDInput | null,
  prizeMoneyRecipientStripeId?: ModelStringInput | null,
  prizeMoneyRecipientType?: ModelStringInput | null,
  entryFeeTotalAmount?: ModelFloatInput | null,
  otherFeeTotalAmount?: ModelFloatInput | null,
  stablingAmount?: ModelFloatInput | null,
  beddingAmount?: ModelFloatInput | null,
  feedAmount?: ModelFloatInput | null,
  prizeMoneyAmount?: ModelFloatInput | null,
  amountToDeductFromInvoice?: ModelFloatInput | null,
  amountToPayRecipientViaCheck?: ModelFloatInput | null,
  totalDue?: ModelFloatInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventInvoiceConditionInput | null > | null,
  or?: Array< ModelEventInvoiceConditionInput | null > | null,
  not?: ModelEventInvoiceConditionInput | null,
  eventInvoicePayerContactId?: ModelIDInput | null,
  eventInvoicePrizeMoneyRecipientId?: ModelIDInput | null,
  eventInvoicePrizeMoneyRecipientContactId?: ModelIDInput | null,
};

export type EventInvoice = {
  __typename: "EventInvoice",
  id: string,
  eventId: string,
  status: string,
  statusNote?: string | null,
  number: number,
  stripeInvoiceNumber?: string | null,
  entryNumber?: number | null,
  entryId?: string | null,
  entry?: EventEntry | null,
  payerName?: string | null,
  payerId?: string | null,
  payer?: Payer | null,
  payerType?: string | null,
  payerStripeId?: string | null,
  payerContact?: Contact | null,
  prizeMoneyRecipientName?: string | null,
  prizeMoneyRecipientId?: string | null,
  prizeMoneyRecipientStripeId?: string | null,
  prizeMoneyRecipient?: Person | null,
  prizeMoneyRecipientContact?: Contact | null,
  prizeMoneyRecipientType?: string | null,
  divisions?: ModelDivisionConnection | null,
  classes?: ModelClassConnection | null,
  fees?: ModelFeeConnection | null,
  entryFeeTotalAmount?: number | null,
  otherFeeTotalAmount?: number | null,
  stablingAmount?: number | null,
  beddingAmount?: number | null,
  feedAmount?: number | null,
  results?:  Array<EventResult | null > | null,
  prizeMoneyAmount?: number | null,
  amountToDeductFromInvoice?: number | null,
  amountToPayRecipientViaCheck?: number | null,
  totalDue?: number | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
  eventInvoicePayerContactId?: string | null,
  eventInvoicePrizeMoneyRecipientId?: string | null,
  eventInvoicePrizeMoneyRecipientContactId?: string | null,
};

export type ModelDivisionConnection = {
  __typename: "ModelDivisionConnection",
  items:  Array<Division | null >,
  nextToken?: string | null,
};

export type ModelClassConnection = {
  __typename: "ModelClassConnection",
  items:  Array<Class | null >,
  nextToken?: string | null,
};

export type ModelFeeConnection = {
  __typename: "ModelFeeConnection",
  items:  Array<Fee | null >,
  nextToken?: string | null,
};

export type UpdateEventInvoiceInput = {
  id: string,
  eventId?: string | null,
  status?: string | null,
  statusNote?: string | null,
  number?: number | null,
  stripeInvoiceNumber?: string | null,
  entryNumber?: number | null,
  entryId?: string | null,
  payerName?: string | null,
  payerId?: string | null,
  payerType?: string | null,
  payerStripeId?: string | null,
  prizeMoneyRecipientName?: string | null,
  prizeMoneyRecipientId?: string | null,
  prizeMoneyRecipientStripeId?: string | null,
  prizeMoneyRecipientType?: string | null,
  entryFeeTotalAmount?: number | null,
  otherFeeTotalAmount?: number | null,
  stablingAmount?: number | null,
  beddingAmount?: number | null,
  feedAmount?: number | null,
  prizeMoneyAmount?: number | null,
  amountToDeductFromInvoice?: number | null,
  amountToPayRecipientViaCheck?: number | null,
  totalDue?: number | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventInvoicePayerContactId?: string | null,
  eventInvoicePrizeMoneyRecipientId?: string | null,
  eventInvoicePrizeMoneyRecipientContactId?: string | null,
};

export type DeleteEventInvoiceInput = {
  id: string,
};

export type CreateEventJudgeInput = {
  id?: string | null,
  name: string,
  eventId: string,
  personId?: string | null,
  judgeId?: string | null,
  contactId?: string | null,
  rating?: Array< JudgeRatingInput | null > | null,
};

export type ModelEventJudgeConditionInput = {
  name?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  judgeId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  and?: Array< ModelEventJudgeConditionInput | null > | null,
  or?: Array< ModelEventJudgeConditionInput | null > | null,
  not?: ModelEventJudgeConditionInput | null,
};

export type EventJudge = {
  __typename: "EventJudge",
  id: string,
  name: string,
  eventId: string,
  personId?: string | null,
  judgeId?: string | null,
  contactId?: string | null,
  rating?:  Array<JudgeRating | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateEventJudgeInput = {
  id: string,
  name?: string | null,
  eventId?: string | null,
  personId?: string | null,
  judgeId?: string | null,
  contactId?: string | null,
  rating?: Array< JudgeRatingInput | null > | null,
};

export type DeleteEventJudgeInput = {
  id: string,
};

export type CreateEventOfficialInput = {
  id?: string | null,
  name: string,
  position: string,
  eventId: string,
  personId?: string | null,
  contactId?: string | null,
};

export type ModelEventOfficialConditionInput = {
  name?: ModelStringInput | null,
  position?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  and?: Array< ModelEventOfficialConditionInput | null > | null,
  or?: Array< ModelEventOfficialConditionInput | null > | null,
  not?: ModelEventOfficialConditionInput | null,
};

export type UpdateEventOfficialInput = {
  id: string,
  name?: string | null,
  position?: string | null,
  eventId?: string | null,
  personId?: string | null,
  contactId?: string | null,
};

export type DeleteEventOfficialInput = {
  id: string,
};

export type CreateEventResultInput = {
  id?: string | null,
  eventId: string,
  entryId: string,
  eventClassEntryId?: string | null,
  eventDivisionId?: string | null,
  eventDivisionName?: string | null,
  eventClassId?: string | null,
  eventClassName?: string | null,
  resultSet?: string | null,
  place?: number | null,
  score?: number | null,
  time?: string | null,
  didNotCompete?: boolean | null,
  prizeMoney?: number | null,
  note?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventResultEntryId: string,
  eventResultEventClassEntryId?: string | null,
  eventResultPrizeMoneyTableId?: string | null,
};

export type ModelEventResultConditionInput = {
  eventId?: ModelIDInput | null,
  entryId?: ModelIDInput | null,
  eventClassEntryId?: ModelIDInput | null,
  eventDivisionId?: ModelIDInput | null,
  eventDivisionName?: ModelStringInput | null,
  eventClassId?: ModelIDInput | null,
  eventClassName?: ModelStringInput | null,
  resultSet?: ModelStringInput | null,
  place?: ModelIntInput | null,
  score?: ModelFloatInput | null,
  time?: ModelStringInput | null,
  didNotCompete?: ModelBooleanInput | null,
  prizeMoney?: ModelFloatInput | null,
  note?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventResultConditionInput | null > | null,
  or?: Array< ModelEventResultConditionInput | null > | null,
  not?: ModelEventResultConditionInput | null,
  eventResultEntryId?: ModelIDInput | null,
  eventResultEventClassEntryId?: ModelIDInput | null,
  eventResultPrizeMoneyTableId?: ModelIDInput | null,
};

export type UpdateEventResultInput = {
  id: string,
  eventId?: string | null,
  entryId?: string | null,
  eventClassEntryId?: string | null,
  eventDivisionId?: string | null,
  eventDivisionName?: string | null,
  eventClassId?: string | null,
  eventClassName?: string | null,
  resultSet?: string | null,
  place?: number | null,
  score?: number | null,
  time?: string | null,
  didNotCompete?: boolean | null,
  prizeMoney?: number | null,
  note?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventResultEntryId: string,
  eventResultEventClassEntryId?: string | null,
  eventResultPrizeMoneyTableId?: string | null,
};

export type DeleteEventResultInput = {
  id: string,
};

export type CreateEventRingInput = {
  id?: string | null,
  name: string,
  eventId: string,
  ringId: string,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventRingConditionInput = {
  name?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  ringId?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventRingConditionInput | null > | null,
  or?: Array< ModelEventRingConditionInput | null > | null,
  not?: ModelEventRingConditionInput | null,
};

export type EventRing = {
  __typename: "EventRing",
  id: string,
  name: string,
  eventId: string,
  ringId: string,
  event?: Event | null,
  ring?: Ring | null,
  createdOn: string,
  updatedOn: string,
};

export type Ring = {
  __typename: "Ring",
  id: string,
  name: string,
  displayOrder?: number | null,
  organizationId?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateEventRingInput = {
  id: string,
  name?: string | null,
  eventId?: string | null,
  ringId?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventRingInput = {
  id: string,
};

export type CreateEventRulesInput = {
  id?: string | null,
  eventId: string,
  title?: string | null,
  text?: string | null,
  requiresAgreementPerEntry?: boolean | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventRulesConditionInput = {
  eventId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  text?: ModelStringInput | null,
  requiresAgreementPerEntry?: ModelBooleanInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventRulesConditionInput | null > | null,
  or?: Array< ModelEventRulesConditionInput | null > | null,
  not?: ModelEventRulesConditionInput | null,
};

export type UpdateEventRulesInput = {
  id: string,
  eventId?: string | null,
  title?: string | null,
  text?: string | null,
  requiresAgreementPerEntry?: boolean | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventRulesInput = {
  id: string,
};

export type CreateEventRulesAgreementInput = {
  id?: string | null,
  eventId: string,
  eventRuleId: string,
  eventEntryId?: string | null,
  name?: string | null,
  personId?: string | null,
  personType?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventRulesAgreementConditionInput = {
  eventId?: ModelIDInput | null,
  eventRuleId?: ModelIDInput | null,
  eventEntryId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  personId?: ModelIDInput | null,
  personType?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventRulesAgreementConditionInput | null > | null,
  or?: Array< ModelEventRulesAgreementConditionInput | null > | null,
  not?: ModelEventRulesAgreementConditionInput | null,
};

export type UpdateEventRulesAgreementInput = {
  id: string,
  eventId?: string | null,
  eventRuleId?: string | null,
  eventEntryId?: string | null,
  name?: string | null,
  personId?: string | null,
  personType?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventRulesAgreementInput = {
  id: string,
};

export type CreateEventScratchInput = {
  id?: string | null,
  eventId: string,
  entryId: string,
  eventDivisionId?: string | null,
  eventDivisionName?: string | null,
  eventClassId?: string | null,
  eventClassName?: string | null,
  status?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventScratchConditionInput = {
  eventId?: ModelIDInput | null,
  entryId?: ModelIDInput | null,
  eventDivisionId?: ModelIDInput | null,
  eventDivisionName?: ModelStringInput | null,
  eventClassId?: ModelIDInput | null,
  eventClassName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventScratchConditionInput | null > | null,
  or?: Array< ModelEventScratchConditionInput | null > | null,
  not?: ModelEventScratchConditionInput | null,
};

export type EventScratch = {
  __typename: "EventScratch",
  id: string,
  eventId: string,
  entryId: string,
  entry: EventEntry,
  eventDivisionId?: string | null,
  eventDivision?: EventDivision | null,
  eventDivisionName?: string | null,
  eventClassId?: string | null,
  eventClass?: EventClass | null,
  eventClassName?: string | null,
  status?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateEventScratchInput = {
  id: string,
  eventId?: string | null,
  entryId?: string | null,
  eventDivisionId?: string | null,
  eventDivisionName?: string | null,
  eventClassId?: string | null,
  eventClassName?: string | null,
  status?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventScratchInput = {
  id: string,
};

export type CreateEventStallSetInput = {
  id?: string | null,
  eventId: string,
  organizationId?: string | null,
  organizationStallSetId?: string | null,
  name?: string | null,
  location?: string | null,
  available?: string | null,
  totalAvailable?: number | null,
  used?: string | null,
  totalUsed?: number | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventStallSetConditionInput = {
  eventId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  organizationStallSetId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  location?: ModelStringInput | null,
  available?: ModelStringInput | null,
  totalAvailable?: ModelIntInput | null,
  used?: ModelStringInput | null,
  totalUsed?: ModelIntInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventStallSetConditionInput | null > | null,
  or?: Array< ModelEventStallSetConditionInput | null > | null,
  not?: ModelEventStallSetConditionInput | null,
};

export type UpdateEventStallSetInput = {
  id: string,
  eventId?: string | null,
  organizationId?: string | null,
  organizationStallSetId?: string | null,
  name?: string | null,
  location?: string | null,
  available?: string | null,
  totalAvailable?: number | null,
  used?: string | null,
  totalUsed?: number | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventStallSetInput = {
  id: string,
};

export type CreateEventStallTypeInput = {
  id?: string | null,
  eventId: string,
  organizationId?: string | null,
  organizationStallTypeId?: string | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  pricePerStall?: string | null,
  taxPerStall?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelEventStallTypeConditionInput = {
  eventId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  organizationStallTypeId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  pricePerStall?: ModelStringInput | null,
  taxPerStall?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventStallTypeConditionInput | null > | null,
  or?: Array< ModelEventStallTypeConditionInput | null > | null,
  not?: ModelEventStallTypeConditionInput | null,
};

export type UpdateEventStallTypeInput = {
  id: string,
  eventId?: string | null,
  organizationId?: string | null,
  organizationStallTypeId?: string | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  pricePerStall?: string | null,
  taxPerStall?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteEventStallTypeInput = {
  id: string,
};

export type CreateFeeInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  createdBy: string,
  organizationId?: string | null,
  stripeId?: string | null,
  altId?: string | null,
  isFixed?: boolean | null,
  isPercentage?: boolean | null,
  totalPrice?: number | null,
  quantity?: number | null,
  isPerEntry?: boolean | null,
  isPerHorse?: boolean | null,
  isPerRider?: boolean | null,
  isPerClass?: boolean | null,
  isPerTrainer?: boolean | null,
  isRefundable?: boolean | null,
  isChargedAtTimeOfEntry?: boolean | null,
  isStartedOnEntryDate?: boolean | null,
  isHiddenFromEntryForm?: boolean | null,
  startDate?: string | null,
  startTime?: string | null,
  taxA?: number | null,
  taxB?: number | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventInvoiceFeesId?: string | null,
  feeTaxRateAId?: string | null,
  feeTaxRateBId?: string | null,
};

export type ModelFeeConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  stripeId?: ModelStringInput | null,
  altId?: ModelStringInput | null,
  isFixed?: ModelBooleanInput | null,
  isPercentage?: ModelBooleanInput | null,
  totalPrice?: ModelFloatInput | null,
  quantity?: ModelFloatInput | null,
  isPerEntry?: ModelBooleanInput | null,
  isPerHorse?: ModelBooleanInput | null,
  isPerRider?: ModelBooleanInput | null,
  isPerClass?: ModelBooleanInput | null,
  isPerTrainer?: ModelBooleanInput | null,
  isRefundable?: ModelBooleanInput | null,
  isChargedAtTimeOfEntry?: ModelBooleanInput | null,
  isStartedOnEntryDate?: ModelBooleanInput | null,
  isHiddenFromEntryForm?: ModelBooleanInput | null,
  startDate?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  taxA?: ModelFloatInput | null,
  taxB?: ModelFloatInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelFeeConditionInput | null > | null,
  or?: Array< ModelFeeConditionInput | null > | null,
  not?: ModelFeeConditionInput | null,
  eventInvoiceFeesId?: ModelIDInput | null,
  feeTaxRateAId?: ModelIDInput | null,
  feeTaxRateBId?: ModelIDInput | null,
};

export type UpdateFeeInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  createdBy?: string | null,
  organizationId?: string | null,
  stripeId?: string | null,
  altId?: string | null,
  isFixed?: boolean | null,
  isPercentage?: boolean | null,
  totalPrice?: number | null,
  quantity?: number | null,
  isPerEntry?: boolean | null,
  isPerHorse?: boolean | null,
  isPerRider?: boolean | null,
  isPerClass?: boolean | null,
  isPerTrainer?: boolean | null,
  isRefundable?: boolean | null,
  isChargedAtTimeOfEntry?: boolean | null,
  isStartedOnEntryDate?: boolean | null,
  isHiddenFromEntryForm?: boolean | null,
  startDate?: string | null,
  startTime?: string | null,
  taxA?: number | null,
  taxB?: number | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventInvoiceFeesId?: string | null,
  feeTaxRateAId?: string | null,
  feeTaxRateBId?: string | null,
};

export type DeleteFeeInput = {
  id: string,
};

export type CreateFeedbackInput = {
  id?: string | null,
  clinicianId?: string | null,
  personId?: string | null,
  technical?: string | null,
  style?: string | null,
  turnout?: string | null,
  general?: string | null,
  score?: number | null,
  status?: FeedbackStatus | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  feedbackSubmissionFeedbackId?: string | null,
};

export enum FeedbackStatus {
  unpaid = "unpaid",
  pending = "pending",
  draft = "draft",
  complete = "complete",
  declined = "declined",
  error = "error",
}


export type ModelFeedbackConditionInput = {
  clinicianId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  technical?: ModelStringInput | null,
  style?: ModelStringInput | null,
  turnout?: ModelStringInput | null,
  general?: ModelStringInput | null,
  score?: ModelFloatInput | null,
  status?: ModelFeedbackStatusInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelFeedbackConditionInput | null > | null,
  or?: Array< ModelFeedbackConditionInput | null > | null,
  not?: ModelFeedbackConditionInput | null,
  feedbackSubmissionFeedbackId?: ModelIDInput | null,
};

export type ModelFeedbackStatusInput = {
  eq?: FeedbackStatus | null,
  ne?: FeedbackStatus | null,
};

export type Feedback = {
  __typename: "Feedback",
  id: string,
  clinicianId?: string | null,
  personId?: string | null,
  technical?: string | null,
  style?: string | null,
  turnout?: string | null,
  general?: string | null,
  score?: number | null,
  status?: FeedbackStatus | null,
  submissionFeedback?: SubmissionFeedback | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  feedbackSubmissionFeedbackId?: string | null,
};

export type SubmissionFeedback = {
  __typename: "SubmissionFeedback",
  id: string,
  type?: string | null,
  submission?: Submission | null,
  submissionId: string,
  feedback: Feedback,
  feedbackId: string,
  personId?: string | null,
  rider?: Rider | null,
  riderId?: string | null,
  clinician?: Clinician | null,
  clinicianId?: string | null,
  submissionStatus?: SubmissionStatus | null,
  feedbackStatus?: FeedbackStatus | null,
  clinicianAmount?: number | null,
  declineReason?: string | null,
  permissionToPost?: boolean | null,
  dateSubmitted?: string | null,
  dateCompleted?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  submissionFeedbackClinicianId?: string | null,
};

export type Submission = {
  __typename: "Submission",
  id: string,
  title: string,
  description?: string | null,
  video?: S3Object | null,
  videoLength?: string | null,
  rider?: Rider | null,
  riderId?: string | null,
  personId?: string | null,
  clinicians?:  Array<Clinician | null > | null,
  totalPrice: number,
  status?: SubmissionStatus | null,
  submissionFeedback?: ModelSubmissionFeedbackConnection | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export enum SubmissionStatus {
  unpaid = "unpaid",
  pending = "pending",
  complete = "complete",
  declined = "declined",
  void = "void",
  error = "error",
}


export type ModelSubmissionFeedbackConnection = {
  __typename: "ModelSubmissionFeedbackConnection",
  items:  Array<SubmissionFeedback | null >,
  nextToken?: string | null,
};

export type UpdateFeedbackInput = {
  id: string,
  clinicianId?: string | null,
  personId?: string | null,
  technical?: string | null,
  style?: string | null,
  turnout?: string | null,
  general?: string | null,
  score?: number | null,
  status?: FeedbackStatus | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  feedbackSubmissionFeedbackId?: string | null,
};

export type DeleteFeedbackInput = {
  id: string,
};

export type CreateGameInputInput = {
  id?: string | null,
  eventId?: string | null,
  eventClassId?: string | null,
  backNumber?: string | null,
  round?: string | null,
  score?: number | null,
  isMasterRecord: boolean,
  createdBy: string,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelGameInputConditionInput = {
  eventId?: ModelIDInput | null,
  eventClassId?: ModelIDInput | null,
  backNumber?: ModelStringInput | null,
  round?: ModelStringInput | null,
  score?: ModelFloatInput | null,
  isMasterRecord?: ModelBooleanInput | null,
  createdBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelGameInputConditionInput | null > | null,
  or?: Array< ModelGameInputConditionInput | null > | null,
  not?: ModelGameInputConditionInput | null,
};

export type GameInput = {
  __typename: "GameInput",
  id: string,
  eventId?: string | null,
  eventClassId?: string | null,
  backNumber?: string | null,
  round?: string | null,
  score?: number | null,
  isMasterRecord: boolean,
  createdBy: string,
  createdOn: string,
  updatedOn: string,
};

export type UpdateGameInputInput = {
  id: string,
  eventId?: string | null,
  eventClassId?: string | null,
  backNumber?: string | null,
  round?: string | null,
  score?: number | null,
  isMasterRecord?: boolean | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteGameInputInput = {
  id: string,
};

export type CreateHorseInput = {
  id?: string | null,
  name: string,
  nickname?: string | null,
  previousNames?: Array< string | null > | null,
  personId?: string | null,
  ownerId?: string | null,
  ownerName: string,
  barnName?: string | null,
  barnId?: string | null,
  contactId?: string | null,
  microchipNumber?: string | null,
  passportNumber?: string | null,
  dateOfBirth?: string | null,
  breed?: string | null,
  sex?: string | null,
  color?: string | null,
  markings?: string | null,
  height?: string | null,
  foalState?: string | null,
  cogginsNumber?: string | null,
  cogginsDate?: string | null,
  cogginsState?: string | null,
  coggins?: S3ObjectInput | null,
  highestLevelCompeted?: string | null,
  greenEligibility?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelHorseConditionInput = {
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  previousNames?: ModelStringInput | null,
  personId?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  ownerName?: ModelStringInput | null,
  barnName?: ModelStringInput | null,
  barnId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  microchipNumber?: ModelStringInput | null,
  passportNumber?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  breed?: ModelStringInput | null,
  sex?: ModelStringInput | null,
  color?: ModelStringInput | null,
  markings?: ModelStringInput | null,
  height?: ModelStringInput | null,
  foalState?: ModelStringInput | null,
  cogginsNumber?: ModelStringInput | null,
  cogginsDate?: ModelStringInput | null,
  cogginsState?: ModelStringInput | null,
  highestLevelCompeted?: ModelStringInput | null,
  greenEligibility?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelHorseConditionInput | null > | null,
  or?: Array< ModelHorseConditionInput | null > | null,
  not?: ModelHorseConditionInput | null,
};

export type UpdateHorseInput = {
  id: string,
  name?: string | null,
  nickname?: string | null,
  previousNames?: Array< string | null > | null,
  personId?: string | null,
  ownerId?: string | null,
  ownerName?: string | null,
  barnName?: string | null,
  barnId?: string | null,
  contactId?: string | null,
  microchipNumber?: string | null,
  passportNumber?: string | null,
  dateOfBirth?: string | null,
  breed?: string | null,
  sex?: string | null,
  color?: string | null,
  markings?: string | null,
  height?: string | null,
  foalState?: string | null,
  cogginsNumber?: string | null,
  cogginsDate?: string | null,
  cogginsState?: string | null,
  coggins?: S3ObjectInput | null,
  highestLevelCompeted?: string | null,
  greenEligibility?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteHorseInput = {
  id: string,
};

export type CreatePedigreeInput = {
  id?: string | null,
  horseId: string,
  sireId?: string | null,
  sireName?: string | null,
  damId?: string | null,
  damName?: string | null,
  breederName?: string | null,
  breederId?: string | null,
  breedRegistryInfo?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  pedigreeBirthplaceId?: string | null,
  pedigreeBreederContactId?: string | null,
};

export type ModelPedigreeConditionInput = {
  horseId?: ModelIDInput | null,
  sireId?: ModelIDInput | null,
  sireName?: ModelStringInput | null,
  damId?: ModelIDInput | null,
  damName?: ModelStringInput | null,
  breederName?: ModelStringInput | null,
  breederId?: ModelIDInput | null,
  breedRegistryInfo?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPedigreeConditionInput | null > | null,
  or?: Array< ModelPedigreeConditionInput | null > | null,
  not?: ModelPedigreeConditionInput | null,
  pedigreeBirthplaceId?: ModelIDInput | null,
  pedigreeBreederContactId?: ModelIDInput | null,
};

export type Pedigree = {
  __typename: "Pedigree",
  id: string,
  horseId: string,
  horse?: Horse | null,
  sireId?: string | null,
  sireName?: string | null,
  sire?: Horse | null,
  damId?: string | null,
  damName?: string | null,
  dam?: Horse | null,
  birthplace?: Address | null,
  breederName?: string | null,
  breederContact?: Contact | null,
  breederId?: string | null,
  breedRegistryInfo?: string | null,
  createdOn: string,
  updatedOn: string,
  pedigreeBirthplaceId?: string | null,
  pedigreeBreederContactId?: string | null,
};

export type UpdatePedigreeInput = {
  id: string,
  horseId?: string | null,
  sireId?: string | null,
  sireName?: string | null,
  damId?: string | null,
  damName?: string | null,
  breederName?: string | null,
  breederId?: string | null,
  breedRegistryInfo?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  pedigreeBirthplaceId?: string | null,
  pedigreeBreederContactId?: string | null,
};

export type DeletePedigreeInput = {
  id: string,
};

export type CreateMeasurementRecordInput = {
  id?: string | null,
  horseId: string,
  date?: string | null,
  status?: string | null,
  height?: string | null,
  heightOfHeel?: string | null,
  shoeingStatus?: string | null,
  steward?: string | null,
  official?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelMeasurementRecordConditionInput = {
  horseId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  status?: ModelStringInput | null,
  height?: ModelStringInput | null,
  heightOfHeel?: ModelStringInput | null,
  shoeingStatus?: ModelStringInput | null,
  steward?: ModelStringInput | null,
  official?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelMeasurementRecordConditionInput | null > | null,
  or?: Array< ModelMeasurementRecordConditionInput | null > | null,
  not?: ModelMeasurementRecordConditionInput | null,
};

export type MeasurementRecord = {
  __typename: "MeasurementRecord",
  id: string,
  horseId: string,
  horse?: Horse | null,
  date?: string | null,
  status?: string | null,
  height?: string | null,
  heightOfHeel?: string | null,
  shoeingStatus?: string | null,
  steward?: string | null,
  official?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateMeasurementRecordInput = {
  id: string,
  horseId?: string | null,
  date?: string | null,
  status?: string | null,
  height?: string | null,
  heightOfHeel?: string | null,
  shoeingStatus?: string | null,
  steward?: string | null,
  official?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteMeasurementRecordInput = {
  id: string,
};

export type CreateHorseShowInput = {
  id?: string | null,
  name: string,
  nickname?: string | null,
  eventId?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelHorseShowConditionInput = {
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelHorseShowConditionInput | null > | null,
  or?: Array< ModelHorseShowConditionInput | null > | null,
  not?: ModelHorseShowConditionInput | null,
};

export type HorseShow = {
  __typename: "HorseShow",
  id: string,
  name: string,
  nickname?: string | null,
  eventId?: string | null,
  ratings?:  Array<HorseShowRating | null > | null,
  judges?:  Array<EventJudge | null > | null,
  lastUpdatedBy?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type HorseShowRating = {
  __typename: "HorseShowRating",
  id: string,
  eventId: string,
  governingBody?: string | null,
  rating?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateHorseShowInput = {
  id: string,
  name?: string | null,
  nickname?: string | null,
  eventId?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteHorseShowInput = {
  id: string,
};

export type CreateHorseShowRatingInput = {
  id?: string | null,
  eventId: string,
  governingBody?: string | null,
  rating?: string | null,
};

export type ModelHorseShowRatingConditionInput = {
  eventId?: ModelIDInput | null,
  governingBody?: ModelStringInput | null,
  rating?: ModelStringInput | null,
  and?: Array< ModelHorseShowRatingConditionInput | null > | null,
  or?: Array< ModelHorseShowRatingConditionInput | null > | null,
  not?: ModelHorseShowRatingConditionInput | null,
};

export type UpdateHorseShowRatingInput = {
  id: string,
  eventId?: string | null,
  governingBody?: string | null,
  rating?: string | null,
};

export type DeleteHorseShowRatingInput = {
  id: string,
};

export type CreateInvitationInput = {
  id?: string | null,
  createdBy: string,
  creatorEmail?: string | null,
  invitee?: string | null,
  inviteeEmail?: string | null,
  status?: InvitationStatus | null,
  organizationId?: string | null,
  barnId?: string | null,
  permissionLevel?: string | null,
  roles?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export enum InvitationStatus {
  pending = "pending",
  accepted = "accepted",
  declined = "declined",
}


export type ModelInvitationConditionInput = {
  createdBy?: ModelIDInput | null,
  creatorEmail?: ModelStringInput | null,
  invitee?: ModelIDInput | null,
  inviteeEmail?: ModelStringInput | null,
  status?: ModelInvitationStatusInput | null,
  organizationId?: ModelIDInput | null,
  barnId?: ModelIDInput | null,
  permissionLevel?: ModelStringInput | null,
  roles?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelInvitationConditionInput | null > | null,
  or?: Array< ModelInvitationConditionInput | null > | null,
  not?: ModelInvitationConditionInput | null,
};

export type ModelInvitationStatusInput = {
  eq?: InvitationStatus | null,
  ne?: InvitationStatus | null,
};

export type Invitation = {
  __typename: "Invitation",
  id: string,
  createdBy: string,
  creatorEmail?: string | null,
  invitee?: string | null,
  inviteeEmail?: string | null,
  status?: InvitationStatus | null,
  organizationId?: string | null,
  barnId?: string | null,
  permissionLevel?: string | null,
  roles?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateInvitationInput = {
  id: string,
  createdBy?: string | null,
  creatorEmail?: string | null,
  invitee?: string | null,
  inviteeEmail?: string | null,
  status?: InvitationStatus | null,
  organizationId?: string | null,
  barnId?: string | null,
  permissionLevel?: string | null,
  roles?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteInvitationInput = {
  id: string,
};

export type CreateMembershipInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  membershipId: string,
  backNumber?: number | null,
  membershipStatus?: string | null,
  amountPaid?: number | null,
  organizationMembershipTypeId?: string | null,
  type?: string | null,
  personName?: string | null,
  personId: string,
  riderId?: string | null,
  horseId?: string | null,
  ownerId?: string | null,
  trainerId?: string | null,
  barnId?: string | null,
  teamId?: string | null,
  organizationId?: string | null,
  organizationName?: string | null,
  isJunior?: boolean | null,
  isProfessional?: boolean | null,
  dateMembershipEnds?: string | null,
  volunteerHours?: number | null,
  meetingsAttended?: number | null,
  showsAttended?: number | null,
  personEmail?: string | null,
  gradeLevel?: string | null,
  aelDivision?: string | null,
  aelTeamRole?: TeamRole | null,
  documents?: Array< S3ObjectInput | null > | null,
  organizationAgreements?: Array< OrganizationAgreementsInput | null > | null,
  contactPersonId?: string | null,
  groupContactId?: string | null,
  groupLeaderContactId?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  personMembershipsId?: string | null,
  membershipOrganizationId?: string | null,
};

export type OrganizationAgreementsInput = {
  organizationRuleId?: string | null,
  organizationRuleTitle: string,
  firstName: string,
  lastName: string,
  personId?: string | null,
  createdOn: string,
  updatedOn?: string | null,
};

export type ModelMembershipConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  membershipId?: ModelStringInput | null,
  backNumber?: ModelIntInput | null,
  membershipStatus?: ModelStringInput | null,
  amountPaid?: ModelFloatInput | null,
  organizationMembershipTypeId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  personName?: ModelStringInput | null,
  personId?: ModelIDInput | null,
  riderId?: ModelIDInput | null,
  horseId?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  trainerId?: ModelIDInput | null,
  barnId?: ModelIDInput | null,
  teamId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  organizationName?: ModelStringInput | null,
  isJunior?: ModelBooleanInput | null,
  isProfessional?: ModelBooleanInput | null,
  dateMembershipEnds?: ModelStringInput | null,
  volunteerHours?: ModelFloatInput | null,
  meetingsAttended?: ModelFloatInput | null,
  showsAttended?: ModelFloatInput | null,
  personEmail?: ModelStringInput | null,
  gradeLevel?: ModelStringInput | null,
  aelDivision?: ModelStringInput | null,
  aelTeamRole?: ModelTeamRoleInput | null,
  contactPersonId?: ModelIDInput | null,
  groupContactId?: ModelIDInput | null,
  groupLeaderContactId?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelMembershipConditionInput | null > | null,
  or?: Array< ModelMembershipConditionInput | null > | null,
  not?: ModelMembershipConditionInput | null,
  personMembershipsId?: ModelIDInput | null,
  membershipOrganizationId?: ModelIDInput | null,
};

export type ModelTeamRoleInput = {
  eq?: TeamRole | null,
  ne?: TeamRole | null,
};

export type UpdateMembershipInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  membershipId?: string | null,
  backNumber?: number | null,
  membershipStatus?: string | null,
  amountPaid?: number | null,
  organizationMembershipTypeId?: string | null,
  type?: string | null,
  personName?: string | null,
  personId?: string | null,
  riderId?: string | null,
  horseId?: string | null,
  ownerId?: string | null,
  trainerId?: string | null,
  barnId?: string | null,
  teamId?: string | null,
  organizationId?: string | null,
  organizationName?: string | null,
  isJunior?: boolean | null,
  isProfessional?: boolean | null,
  dateMembershipEnds?: string | null,
  volunteerHours?: number | null,
  meetingsAttended?: number | null,
  showsAttended?: number | null,
  personEmail?: string | null,
  gradeLevel?: string | null,
  aelDivision?: string | null,
  aelTeamRole?: TeamRole | null,
  documents?: Array< S3ObjectInput | null > | null,
  organizationAgreements?: Array< OrganizationAgreementsInput | null > | null,
  contactPersonId?: string | null,
  groupContactId?: string | null,
  groupLeaderContactId?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  personMembershipsId?: string | null,
  membershipOrganizationId?: string | null,
};

export type DeleteMembershipInput = {
  id: string,
};

export type CreateOrganizationMembershipTypeInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  order?: number | null,
  organizationId?: string | null,
  membershipIdValues?: string | null,
  nextAvailableMembershipId?: string | null,
  price?: number | null,
  category?: MembershipTypeCategory | null,
  schedule?: MembershipTypeSchedule | null,
  isStartAtTimeOfPurchase?: boolean | null,
  setStartDate?: string | null,
  acceptOnlineApplications?: string | null,
  applicationFields?: ApplicationFieldsInput | null,
  applicationOptions?: ApplicationOptionsInput | null,
  organizationDocuments?: Array< OrganizationDocumentInput | null > | null,
  organizationRules?: Array< OrganizationRuleInput | null > | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  organizationMembershipTypeOrganizationId?: string | null,
};

export type ApplicationFieldsInput = {
  firstName?: boolean | null,
  lastName?: boolean | null,
  dateOfBirth?: boolean | null,
  contact?: boolean | null,
  gradeLevel?: boolean | null,
  barnName?: boolean | null,
  teamName?: boolean | null,
  backNumber?: boolean | null,
  groupName?: boolean | null,
  groupLeader?: boolean | null,
  groupLeaderContactInfo?: boolean | null,
  collectMemberDetailsTogether?: boolean | null,
  maxNumberOfGroupMembers?: number | null,
  isTeam?: boolean | null,
  horseName?: boolean | null,
  horseHeight?: boolean | null,
  horseOwnerName?: boolean | null,
  cogginsInfo?: boolean | null,
  cogginsUpload?: boolean | null,
};

export type ApplicationOptionsInput = {
  requireApproval?: boolean | null,
  autoAssignIds?: boolean | null,
  acceptRSPPayment?: boolean | null,
  acceptOtherPayment?: boolean | null,
};

export type OrganizationDocumentInput = {
  id: string,
  name: string,
};

export type OrganizationRuleInput = {
  id: string,
  title: string,
  body: string,
  isRequired?: boolean | null,
};

export type ModelOrganizationMembershipTypeConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  order?: ModelIntInput | null,
  organizationId?: ModelIDInput | null,
  membershipIdValues?: ModelStringInput | null,
  nextAvailableMembershipId?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  category?: ModelMembershipTypeCategoryInput | null,
  schedule?: ModelMembershipTypeScheduleInput | null,
  isStartAtTimeOfPurchase?: ModelBooleanInput | null,
  setStartDate?: ModelStringInput | null,
  acceptOnlineApplications?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationMembershipTypeConditionInput | null > | null,
  or?: Array< ModelOrganizationMembershipTypeConditionInput | null > | null,
  not?: ModelOrganizationMembershipTypeConditionInput | null,
  organizationMembershipTypeOrganizationId?: ModelIDInput | null,
};

export type ModelMembershipTypeCategoryInput = {
  eq?: MembershipTypeCategory | null,
  ne?: MembershipTypeCategory | null,
};

export type ModelMembershipTypeScheduleInput = {
  eq?: MembershipTypeSchedule | null,
  ne?: MembershipTypeSchedule | null,
};

export type UpdateOrganizationMembershipTypeInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  order?: number | null,
  organizationId?: string | null,
  membershipIdValues?: string | null,
  nextAvailableMembershipId?: string | null,
  price?: number | null,
  category?: MembershipTypeCategory | null,
  schedule?: MembershipTypeSchedule | null,
  isStartAtTimeOfPurchase?: boolean | null,
  setStartDate?: string | null,
  acceptOnlineApplications?: string | null,
  applicationFields?: ApplicationFieldsInput | null,
  applicationOptions?: ApplicationOptionsInput | null,
  organizationDocuments?: Array< OrganizationDocumentInput | null > | null,
  organizationRules?: Array< OrganizationRuleInput | null > | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  organizationMembershipTypeOrganizationId?: string | null,
};

export type DeleteOrganizationMembershipTypeInput = {
  id: string,
};

export type CreateOrganizationInput = {
  id?: string | null,
  name: string,
  nickname?: string | null,
  description?: string | null,
  type?: OrganizationType | null,
  industry?: string | null,
  addresses?: Array< string | null > | null,
  contactId?: string | null,
  stripeId?: string | null,
  stripeCustomerId?: string | null,
  paymentMethodId?: string | null,
  website?: string | null,
  media?: Array< S3ObjectInput | null > | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  organizationOptions?: OrganizationOptionsInput | null,
  urlBackHalf?: string | null,
  colors?: Array< string | null > | null,
  logos?: Array< LogoInput | null > | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type OrganizationOptionsInput = {
  usesTextAlerts?: boolean | null,
  acceptsIncomingTextAlerts?: boolean | null,
};

export type LogoInput = {
  logoURL?: string | null,
  key?: string | null,
  logo?: S3ObjectInput | null,
};

export type ModelOrganizationConditionInput = {
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelOrganizationTypeInput | null,
  industry?: ModelStringInput | null,
  addresses?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  stripeId?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  paymentMethodId?: ModelStringInput | null,
  website?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  urlBackHalf?: ModelStringInput | null,
  colors?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
};

export type ModelOrganizationTypeInput = {
  eq?: OrganizationType | null,
  ne?: OrganizationType | null,
};

export type UpdateOrganizationInput = {
  id: string,
  name?: string | null,
  nickname?: string | null,
  description?: string | null,
  type?: OrganizationType | null,
  industry?: string | null,
  addresses?: Array< string | null > | null,
  contactId?: string | null,
  stripeId?: string | null,
  stripeCustomerId?: string | null,
  paymentMethodId?: string | null,
  website?: string | null,
  media?: Array< S3ObjectInput | null > | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  organizationOptions?: OrganizationOptionsInput | null,
  urlBackHalf?: string | null,
  colors?: Array< string | null > | null,
  logos?: Array< LogoInput | null > | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteOrganizationInput = {
  id: string,
};

export type CreateOrganizationBeddingTypeInput = {
  id?: string | null,
  organizationId: string,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  pricePerBag?: number | null,
  taxA?: number | null,
  taxB?: number | null,
  taxRateIdA?: string | null,
  taxRateIdB?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelOrganizationBeddingTypeConditionInput = {
  organizationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  pricePerBag?: ModelFloatInput | null,
  taxA?: ModelFloatInput | null,
  taxB?: ModelFloatInput | null,
  taxRateIdA?: ModelIDInput | null,
  taxRateIdB?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationBeddingTypeConditionInput | null > | null,
  or?: Array< ModelOrganizationBeddingTypeConditionInput | null > | null,
  not?: ModelOrganizationBeddingTypeConditionInput | null,
};

export type OrganizationBeddingType = {
  __typename: "OrganizationBeddingType",
  id: string,
  organizationId: string,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  pricePerBag?: number | null,
  taxA?: number | null,
  taxB?: number | null,
  taxRateIdA?: string | null,
  taxRateIdB?: string | null,
  taxRateA?: TaxRate | null,
  taxRateB?: TaxRate | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateOrganizationBeddingTypeInput = {
  id: string,
  organizationId?: string | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  pricePerBag?: number | null,
  taxA?: number | null,
  taxB?: number | null,
  taxRateIdA?: string | null,
  taxRateIdB?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteOrganizationBeddingTypeInput = {
  id: string,
};

export type CreateOrganizationClassInput = {
  id?: string | null,
  name: string,
  classId: string,
  organizationId: string,
  organizationDivisionId?: string | null,
  hasChampionship?: boolean | null,
  type?: string | null,
  number?: number | null,
  entryFee?: string | null,
  prizeMoney?: string | null,
  minNumberEntries?: number | null,
  maxNumberEntries?: number | null,
  jumpHeight?: Array< string | null > | null,
  estimatedTimePerRound?: number | null,
  createdBy: string,
  displayOrder?: number | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  organizationClassPointTableId?: string | null,
  organizationClassPrizeMoneyTableId?: string | null,
};

export type ModelOrganizationClassConditionInput = {
  name?: ModelStringInput | null,
  classId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  organizationDivisionId?: ModelIDInput | null,
  hasChampionship?: ModelBooleanInput | null,
  type?: ModelStringInput | null,
  number?: ModelIntInput | null,
  entryFee?: ModelStringInput | null,
  prizeMoney?: ModelStringInput | null,
  minNumberEntries?: ModelIntInput | null,
  maxNumberEntries?: ModelIntInput | null,
  jumpHeight?: ModelStringInput | null,
  estimatedTimePerRound?: ModelIntInput | null,
  createdBy?: ModelIDInput | null,
  displayOrder?: ModelIntInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationClassConditionInput | null > | null,
  or?: Array< ModelOrganizationClassConditionInput | null > | null,
  not?: ModelOrganizationClassConditionInput | null,
  organizationClassPointTableId?: ModelIDInput | null,
  organizationClassPrizeMoneyTableId?: ModelIDInput | null,
};

export type UpdateOrganizationClassInput = {
  id: string,
  name?: string | null,
  classId?: string | null,
  organizationId?: string | null,
  organizationDivisionId?: string | null,
  hasChampionship?: boolean | null,
  type?: string | null,
  number?: number | null,
  entryFee?: string | null,
  prizeMoney?: string | null,
  minNumberEntries?: number | null,
  maxNumberEntries?: number | null,
  jumpHeight?: Array< string | null > | null,
  estimatedTimePerRound?: number | null,
  createdBy?: string | null,
  displayOrder?: number | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  organizationClassPointTableId?: string | null,
  organizationClassPrizeMoneyTableId?: string | null,
};

export type DeleteOrganizationClassInput = {
  id: string,
};

export type CreateOrganizationDivisionInput = {
  id?: string | null,
  name: string,
  number?: number | null,
  divisionId: string,
  organizationId: string,
  jumpHeight?: Array< string | null > | null,
  minNumberEntries?: number | null,
  maxNumberEntries?: number | null,
  entryFee?: string | null,
  prizeMoney?: string | null,
  entryFeePerClass?: string | null,
  prizeMoneyPerClass?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  displayOrder?: number | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  organizationDivisionPointTableId?: string | null,
  organizationDivisionPrizeMoneyTableId?: string | null,
};

export type ModelOrganizationDivisionConditionInput = {
  name?: ModelStringInput | null,
  number?: ModelIntInput | null,
  divisionId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  jumpHeight?: ModelStringInput | null,
  minNumberEntries?: ModelIntInput | null,
  maxNumberEntries?: ModelIntInput | null,
  entryFee?: ModelStringInput | null,
  prizeMoney?: ModelStringInput | null,
  entryFeePerClass?: ModelStringInput | null,
  prizeMoneyPerClass?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  displayOrder?: ModelIntInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationDivisionConditionInput | null > | null,
  or?: Array< ModelOrganizationDivisionConditionInput | null > | null,
  not?: ModelOrganizationDivisionConditionInput | null,
  organizationDivisionPointTableId?: ModelIDInput | null,
  organizationDivisionPrizeMoneyTableId?: ModelIDInput | null,
};

export type UpdateOrganizationDivisionInput = {
  id: string,
  name?: string | null,
  number?: number | null,
  divisionId?: string | null,
  organizationId?: string | null,
  jumpHeight?: Array< string | null > | null,
  minNumberEntries?: number | null,
  maxNumberEntries?: number | null,
  entryFee?: string | null,
  prizeMoney?: string | null,
  entryFeePerClass?: string | null,
  prizeMoneyPerClass?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  displayOrder?: number | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  organizationDivisionPointTableId?: string | null,
  organizationDivisionPrizeMoneyTableId?: string | null,
};

export type DeleteOrganizationDivisionInput = {
  id: string,
};

export type CreateOrganizationStallSetInput = {
  id?: string | null,
  organizationId: string,
  name?: string | null,
  location?: string | null,
  available?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelOrganizationStallSetConditionInput = {
  organizationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  location?: ModelStringInput | null,
  available?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationStallSetConditionInput | null > | null,
  or?: Array< ModelOrganizationStallSetConditionInput | null > | null,
  not?: ModelOrganizationStallSetConditionInput | null,
};

export type OrganizationStallSet = {
  __typename: "OrganizationStallSet",
  id: string,
  organizationId: string,
  name?: string | null,
  location?: string | null,
  available?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateOrganizationStallSetInput = {
  id: string,
  organizationId?: string | null,
  name?: string | null,
  location?: string | null,
  available?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteOrganizationStallSetInput = {
  id: string,
};

export type CreateOrganizationStallTypeInput = {
  id?: string | null,
  organizationId: string,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  pricePerStall?: number | null,
  taxA?: number | null,
  taxB?: number | null,
  taxRateIdA?: string | null,
  taxRateIdB?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelOrganizationStallTypeConditionInput = {
  organizationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  pricePerStall?: ModelFloatInput | null,
  taxA?: ModelFloatInput | null,
  taxB?: ModelFloatInput | null,
  taxRateIdA?: ModelIDInput | null,
  taxRateIdB?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationStallTypeConditionInput | null > | null,
  or?: Array< ModelOrganizationStallTypeConditionInput | null > | null,
  not?: ModelOrganizationStallTypeConditionInput | null,
};

export type OrganizationStallType = {
  __typename: "OrganizationStallType",
  id: string,
  organizationId: string,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  pricePerStall?: number | null,
  taxA?: number | null,
  taxB?: number | null,
  taxRateIdA?: string | null,
  taxRateIdB?: string | null,
  taxRateA?: TaxRate | null,
  taxRateB?: TaxRate | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateOrganizationStallTypeInput = {
  id: string,
  organizationId?: string | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  pricePerStall?: number | null,
  taxA?: number | null,
  taxB?: number | null,
  taxRateIdA?: string | null,
  taxRateIdB?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteOrganizationStallTypeInput = {
  id: string,
};

export type CreateOwnerInput = {
  id?: string | null,
  name: string,
  type?: string | null,
  location?: string | null,
  addressId?: string | null,
  personId?: string | null,
  barnId?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  ownerContactId?: string | null,
};

export type ModelOwnerConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  location?: ModelStringInput | null,
  addressId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  barnId?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOwnerConditionInput | null > | null,
  or?: Array< ModelOwnerConditionInput | null > | null,
  not?: ModelOwnerConditionInput | null,
  ownerContactId?: ModelIDInput | null,
};

export type UpdateOwnerInput = {
  id: string,
  name?: string | null,
  type?: string | null,
  location?: string | null,
  addressId?: string | null,
  personId?: string | null,
  barnId?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  ownerContactId?: string | null,
};

export type DeleteOwnerInput = {
  id: string,
};

export type CreatePayerInput = {
  id?: string | null,
  name: string,
  personId?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  payerContactId?: string | null,
  payerPaymentMethodId?: string | null,
};

export type ModelPayerConditionInput = {
  name?: ModelStringInput | null,
  personId?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPayerConditionInput | null > | null,
  or?: Array< ModelPayerConditionInput | null > | null,
  not?: ModelPayerConditionInput | null,
  payerContactId?: ModelIDInput | null,
  payerPaymentMethodId?: ModelIDInput | null,
};

export type UpdatePayerInput = {
  id: string,
  name?: string | null,
  personId?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  payerContactId?: string | null,
  payerPaymentMethodId?: string | null,
};

export type DeletePayerInput = {
  id: string,
};

export type CreatePaymentMethodInput = {
  id?: string | null,
  personId?: string | null,
  organizationId?: string | null,
  type?: PaymentMethodType | null,
  stripeId?: string | null,
  altId?: string | null,
  value?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelPaymentMethodConditionInput = {
  personId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  type?: ModelPaymentMethodTypeInput | null,
  stripeId?: ModelStringInput | null,
  altId?: ModelStringInput | null,
  value?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPaymentMethodConditionInput | null > | null,
  or?: Array< ModelPaymentMethodConditionInput | null > | null,
  not?: ModelPaymentMethodConditionInput | null,
};

export type ModelPaymentMethodTypeInput = {
  eq?: PaymentMethodType | null,
  ne?: PaymentMethodType | null,
};

export type UpdatePaymentMethodInput = {
  id: string,
  personId?: string | null,
  organizationId?: string | null,
  type?: PaymentMethodType | null,
  stripeId?: string | null,
  altId?: string | null,
  value?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeletePaymentMethodInput = {
  id: string,
};

export type CreatePersonInput = {
  id?: string | null,
  email: string,
  firstName?: string | null,
  lastName?: string | null,
  isVerified?: boolean | null,
  roles?: string | null,
  personalInformationId?: string | null,
  pictureURL?: string | null,
  picture?: S3ObjectInput | null,
  disciplines?: string | null,
  stripeId?: string | null,
  media?: Array< S3ObjectInput | null > | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelPersonConditionInput = {
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  isVerified?: ModelBooleanInput | null,
  roles?: ModelStringInput | null,
  personalInformationId?: ModelIDInput | null,
  pictureURL?: ModelStringInput | null,
  disciplines?: ModelStringInput | null,
  stripeId?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPersonConditionInput | null > | null,
  or?: Array< ModelPersonConditionInput | null > | null,
  not?: ModelPersonConditionInput | null,
};

export type UpdatePersonInput = {
  id: string,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  isVerified?: boolean | null,
  roles?: string | null,
  personalInformationId?: string | null,
  pictureURL?: string | null,
  picture?: S3ObjectInput | null,
  disciplines?: string | null,
  stripeId?: string | null,
  media?: Array< S3ObjectInput | null > | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeletePersonInput = {
  id: string,
};

export type CreatePersonNameInput = {
  id?: string | null,
  personId: string,
  title?: Title | null,
  firstName?: string | null,
  middleName?: string | null,
  maidenName?: string | null,
  lastName?: string | null,
  suffix?: string | null,
  legalName?: string | null,
  displayName?: string | null,
};

export enum Title {
  Mr = "Mr",
  Mrs = "Mrs",
  Ms = "Ms",
  Mx = "Mx",
}


export type ModelPersonNameConditionInput = {
  personId?: ModelIDInput | null,
  title?: ModelTitleInput | null,
  firstName?: ModelStringInput | null,
  middleName?: ModelStringInput | null,
  maidenName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  suffix?: ModelStringInput | null,
  legalName?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  and?: Array< ModelPersonNameConditionInput | null > | null,
  or?: Array< ModelPersonNameConditionInput | null > | null,
  not?: ModelPersonNameConditionInput | null,
};

export type ModelTitleInput = {
  eq?: Title | null,
  ne?: Title | null,
};

export type PersonName = {
  __typename: "PersonName",
  id: string,
  personId: string,
  title?: Title | null,
  firstName?: string | null,
  middleName?: string | null,
  maidenName?: string | null,
  lastName?: string | null,
  suffix?: string | null,
  legalName?: string | null,
  displayName?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePersonNameInput = {
  id: string,
  personId?: string | null,
  title?: Title | null,
  firstName?: string | null,
  middleName?: string | null,
  maidenName?: string | null,
  lastName?: string | null,
  suffix?: string | null,
  legalName?: string | null,
  displayName?: string | null,
};

export type DeletePersonNameInput = {
  id: string,
};

export type CreatePersonalInformationInput = {
  id?: string | null,
  personId: string,
  dateOfBirth?: string | null,
  gender?: Gender | null,
  contactId?: string | null,
  addressId?: string | null,
};

export type ModelPersonalInformationConditionInput = {
  personId?: ModelIDInput | null,
  dateOfBirth?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  contactId?: ModelIDInput | null,
  addressId?: ModelIDInput | null,
  and?: Array< ModelPersonalInformationConditionInput | null > | null,
  or?: Array< ModelPersonalInformationConditionInput | null > | null,
  not?: ModelPersonalInformationConditionInput | null,
};

export type ModelGenderInput = {
  eq?: Gender | null,
  ne?: Gender | null,
};

export type UpdatePersonalInformationInput = {
  id: string,
  personId?: string | null,
  dateOfBirth?: string | null,
  gender?: Gender | null,
  contactId?: string | null,
  addressId?: string | null,
};

export type DeletePersonalInformationInput = {
  id: string,
};

export type CreatePointTableInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  displayOrder?: number | null,
  organizationId?: string | null,
  discipline?: string | null,
  isAddValueToRegularFromEntryCount?: boolean | null,
  isAddValueToChampionshipFromEntryCount?: boolean | null,
  isUseChampionRule?: boolean | null,
  isUseReserveRule?: boolean | null,
  tiers?: Array< PointTierInput | null > | null,
  numberEntriesToReceiveSpecialClassPoints?: string | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventResultPointTablesId?: string | null,
};

export type PointTierInput = {
  lowEntryCount?: number | null,
  highEntryCount?: number | null,
  first?: string | null,
  second?: string | null,
  third?: string | null,
  fourth?: string | null,
  fifth?: string | null,
  sixth?: string | null,
  seventh?: string | null,
  eighth?: string | null,
  ninth?: string | null,
  tenth?: string | null,
  eleventh?: string | null,
  twelfth?: string | null,
  champion?: string | null,
  reserve?: string | null,
};

export type ModelPointTableConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  organizationId?: ModelIDInput | null,
  discipline?: ModelStringInput | null,
  isAddValueToRegularFromEntryCount?: ModelBooleanInput | null,
  isAddValueToChampionshipFromEntryCount?: ModelBooleanInput | null,
  isUseChampionRule?: ModelBooleanInput | null,
  isUseReserveRule?: ModelBooleanInput | null,
  numberEntriesToReceiveSpecialClassPoints?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPointTableConditionInput | null > | null,
  or?: Array< ModelPointTableConditionInput | null > | null,
  not?: ModelPointTableConditionInput | null,
  eventResultPointTablesId?: ModelIDInput | null,
};

export type UpdatePointTableInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  displayOrder?: number | null,
  organizationId?: string | null,
  discipline?: string | null,
  isAddValueToRegularFromEntryCount?: boolean | null,
  isAddValueToChampionshipFromEntryCount?: boolean | null,
  isUseChampionRule?: boolean | null,
  isUseReserveRule?: boolean | null,
  tiers?: Array< PointTierInput | null > | null,
  numberEntriesToReceiveSpecialClassPoints?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  eventResultPointTablesId?: string | null,
};

export type DeletePointTableInput = {
  id: string,
};

export type CreatePollInput = {
  id?: string | null,
  title: string,
  description?: string | null,
};

export type ModelPollConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelPollConditionInput | null > | null,
  or?: Array< ModelPollConditionInput | null > | null,
  not?: ModelPollConditionInput | null,
};

export type Poll = {
  __typename: "Poll",
  id: string,
  title: string,
  description?: string | null,
  responses?: ModelResponseConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelResponseConnection = {
  __typename: "ModelResponseConnection",
  items:  Array<Response | null >,
  nextToken?: string | null,
};

export type Response = {
  __typename: "Response",
  id: string,
  pollId?: string | null,
  personId: string,
  person?: Person | null,
  value?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePollInput = {
  id: string,
  title?: string | null,
  description?: string | null,
};

export type DeletePollInput = {
  id: string,
};

export type CreatePostInput = {
  id?: string | null,
  title: string,
  status: PostStatus,
  rating?: number | null,
  content?: string | null,
  organizationId?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export enum PostStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}


export type ModelPostConditionInput = {
  title?: ModelStringInput | null,
  status?: ModelPostStatusInput | null,
  rating?: ModelIntInput | null,
  content?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPostConditionInput | null > | null,
  or?: Array< ModelPostConditionInput | null > | null,
  not?: ModelPostConditionInput | null,
};

export type ModelPostStatusInput = {
  eq?: PostStatus | null,
  ne?: PostStatus | null,
};

export type Post = {
  __typename: "Post",
  id: string,
  title: string,
  status: PostStatus,
  rating?: number | null,
  content?: string | null,
  organizationId?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePostInput = {
  id: string,
  title?: string | null,
  status?: PostStatus | null,
  rating?: number | null,
  content?: string | null,
  organizationId?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeletePostInput = {
  id: string,
};

export type CreatePrizeMoneyTableInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  displayOrder?: number | null,
  organizationId?: string | null,
  discipline?: string | null,
  isPercentage?: boolean | null,
  totalPot?: number | null,
  tiers?: PrizeMoneyTiersInput | null,
  createdBy: string,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type PrizeMoneyTiersInput = {
  first?: string | null,
  second?: string | null,
  third?: string | null,
  fourth?: string | null,
  fifth?: string | null,
  sixth?: string | null,
  seventh?: string | null,
  eighth?: string | null,
  ninth?: string | null,
  tenth?: string | null,
  eleventh?: string | null,
  twelfth?: string | null,
  champion?: string | null,
  reserve?: string | null,
};

export type ModelPrizeMoneyTableConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  organizationId?: ModelIDInput | null,
  discipline?: ModelStringInput | null,
  isPercentage?: ModelBooleanInput | null,
  totalPot?: ModelFloatInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPrizeMoneyTableConditionInput | null > | null,
  or?: Array< ModelPrizeMoneyTableConditionInput | null > | null,
  not?: ModelPrizeMoneyTableConditionInput | null,
};

export type UpdatePrizeMoneyTableInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  displayOrder?: number | null,
  organizationId?: string | null,
  discipline?: string | null,
  isPercentage?: boolean | null,
  totalPot?: number | null,
  tiers?: PrizeMoneyTiersInput | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeletePrizeMoneyTableInput = {
  id: string,
};

export type CreatePromoCodeInput = {
  id?: string | null,
  name: string,
  stripeId?: string | null,
  isvalid?: boolean | null,
  isfundraiser?: boolean | null,
  ispercentdiscount?: boolean | null,
  isfixeddiscount?: boolean | null,
  percent?: number | null,
  fixed?: number | null,
  minamount?: number | null,
  startdate?: string | null,
  enddate?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelPromoCodeConditionInput = {
  name?: ModelStringInput | null,
  stripeId?: ModelStringInput | null,
  isvalid?: ModelBooleanInput | null,
  isfundraiser?: ModelBooleanInput | null,
  ispercentdiscount?: ModelBooleanInput | null,
  isfixeddiscount?: ModelBooleanInput | null,
  percent?: ModelFloatInput | null,
  fixed?: ModelFloatInput | null,
  minamount?: ModelFloatInput | null,
  startdate?: ModelStringInput | null,
  enddate?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPromoCodeConditionInput | null > | null,
  or?: Array< ModelPromoCodeConditionInput | null > | null,
  not?: ModelPromoCodeConditionInput | null,
};

export type PromoCode = {
  __typename: "PromoCode",
  id: string,
  name: string,
  stripeId?: string | null,
  isvalid?: boolean | null,
  isfundraiser?: boolean | null,
  ispercentdiscount?: boolean | null,
  isfixeddiscount?: boolean | null,
  percent?: number | null,
  fixed?: number | null,
  minamount?: number | null,
  startdate?: string | null,
  enddate?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdatePromoCodeInput = {
  id: string,
  name?: string | null,
  stripeId?: string | null,
  isvalid?: boolean | null,
  isfundraiser?: boolean | null,
  ispercentdiscount?: boolean | null,
  isfixeddiscount?: boolean | null,
  percent?: number | null,
  fixed?: number | null,
  minamount?: number | null,
  startdate?: string | null,
  enddate?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeletePromoCodeInput = {
  id: string,
};

export type CreateResponseInput = {
  id?: string | null,
  pollId?: string | null,
  personId: string,
  value?: string | null,
};

export type ModelResponseConditionInput = {
  pollId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  value?: ModelStringInput | null,
  and?: Array< ModelResponseConditionInput | null > | null,
  or?: Array< ModelResponseConditionInput | null > | null,
  not?: ModelResponseConditionInput | null,
};

export type UpdateResponseInput = {
  id: string,
  pollId?: string | null,
  personId?: string | null,
  value?: string | null,
};

export type DeleteResponseInput = {
  id: string,
};

export type CreateRiderInput = {
  id?: string | null,
  name: string,
  location?: string | null,
  addressId?: string | null,
  personId?: string | null,
  age?: number | null,
  birthdate?: string | null,
  isProfessional?: boolean | null,
  barnName?: string | null,
  barnId?: string | null,
  createdBy?: string | null,
  createdOn: string,
  updatedOn: string,
  riderContactId?: string | null,
};

export type ModelRiderConditionInput = {
  name?: ModelStringInput | null,
  location?: ModelStringInput | null,
  addressId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  age?: ModelIntInput | null,
  birthdate?: ModelStringInput | null,
  isProfessional?: ModelBooleanInput | null,
  barnName?: ModelStringInput | null,
  barnId?: ModelIDInput | null,
  createdBy?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelRiderConditionInput | null > | null,
  or?: Array< ModelRiderConditionInput | null > | null,
  not?: ModelRiderConditionInput | null,
  riderContactId?: ModelIDInput | null,
};

export type UpdateRiderInput = {
  id: string,
  name?: string | null,
  location?: string | null,
  addressId?: string | null,
  personId?: string | null,
  age?: number | null,
  birthdate?: string | null,
  isProfessional?: boolean | null,
  barnName?: string | null,
  barnId?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  riderContactId?: string | null,
};

export type DeleteRiderInput = {
  id: string,
};

export type CreateRingInput = {
  id?: string | null,
  name: string,
  displayOrder?: number | null,
  organizationId?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelRingConditionInput = {
  name?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  organizationId?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelRingConditionInput | null > | null,
  or?: Array< ModelRingConditionInput | null > | null,
  not?: ModelRingConditionInput | null,
};

export type UpdateRingInput = {
  id: string,
  name?: string | null,
  displayOrder?: number | null,
  organizationId?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteRingInput = {
  id: string,
};

export type CreateScheduleItemInput = {
  id?: string | null,
  eventId: string,
  eventRingId: string,
  eventDayId: string,
  displayOrder?: number | null,
  classId?: string | null,
  breakId?: string | null,
  startTime?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelScheduleItemConditionInput = {
  eventId?: ModelIDInput | null,
  eventRingId?: ModelIDInput | null,
  eventDayId?: ModelIDInput | null,
  displayOrder?: ModelIntInput | null,
  classId?: ModelIDInput | null,
  breakId?: ModelIDInput | null,
  startTime?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelScheduleItemConditionInput | null > | null,
  or?: Array< ModelScheduleItemConditionInput | null > | null,
  not?: ModelScheduleItemConditionInput | null,
};

export type ScheduleItem = {
  __typename: "ScheduleItem",
  id: string,
  eventId: string,
  eventRingId: string,
  eventDayId: string,
  eventRing?: EventRing | null,
  eventDay?: EventDay | null,
  displayOrder?: number | null,
  classId?: string | null,
  eventClass?: EventClass | null,
  breakId?: string | null,
  eventBreak?: EventBreak | null,
  startTime?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateScheduleItemInput = {
  id: string,
  eventId?: string | null,
  eventRingId?: string | null,
  eventDayId?: string | null,
  displayOrder?: number | null,
  classId?: string | null,
  breakId?: string | null,
  startTime?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteScheduleItemInput = {
  id: string,
};

export type CreateSeasonInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  organizationId?: string | null,
  isActive?: boolean | null,
  startDate: string,
  endDate: string,
  disciplines?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  seasonOrganizationId?: string | null,
};

export type ModelSeasonConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  isActive?: ModelBooleanInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  disciplines?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelSeasonConditionInput | null > | null,
  or?: Array< ModelSeasonConditionInput | null > | null,
  not?: ModelSeasonConditionInput | null,
  seasonOrganizationId?: ModelIDInput | null,
};

export type UpdateSeasonInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  organizationId?: string | null,
  isActive?: boolean | null,
  startDate?: string | null,
  endDate?: string | null,
  disciplines?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  seasonOrganizationId?: string | null,
};

export type DeleteSeasonInput = {
  id: string,
};

export type CreateStablingRequestInput = {
  id?: string | null,
  eventId: string,
  trainerId?: string | null,
  barnId?: string | null,
  personId?: string | null,
  arrivalDateTime?: string | null,
  departureDateTime?: string | null,
  stallTypeId?: string | null,
  quantityNeeded?: number | null,
  basePrice?: number | null,
  taxA?: number | null,
  taxB?: number | null,
  totalPrice?: number | null,
  status?: string | null,
  statusNote?: string | null,
  requestNote?: string | null,
  stallSetId?: string | null,
  stallLocation?: string | null,
  stallNumbers?: string | null,
  payerId?: string | null,
  payerName?: string | null,
  splitAcrossEntries?: boolean | null,
  entryIds?: Array< string | null > | null,
  submittedEntry?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelStablingRequestConditionInput = {
  eventId?: ModelIDInput | null,
  trainerId?: ModelIDInput | null,
  barnId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  arrivalDateTime?: ModelStringInput | null,
  departureDateTime?: ModelStringInput | null,
  stallTypeId?: ModelIDInput | null,
  quantityNeeded?: ModelFloatInput | null,
  basePrice?: ModelFloatInput | null,
  taxA?: ModelFloatInput | null,
  taxB?: ModelFloatInput | null,
  totalPrice?: ModelFloatInput | null,
  status?: ModelStringInput | null,
  statusNote?: ModelStringInput | null,
  requestNote?: ModelStringInput | null,
  stallSetId?: ModelIDInput | null,
  stallLocation?: ModelStringInput | null,
  stallNumbers?: ModelStringInput | null,
  payerId?: ModelIDInput | null,
  payerName?: ModelStringInput | null,
  splitAcrossEntries?: ModelBooleanInput | null,
  entryIds?: ModelStringInput | null,
  submittedEntry?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelStablingRequestConditionInput | null > | null,
  or?: Array< ModelStablingRequestConditionInput | null > | null,
  not?: ModelStablingRequestConditionInput | null,
};

export type UpdateStablingRequestInput = {
  id: string,
  eventId?: string | null,
  trainerId?: string | null,
  barnId?: string | null,
  personId?: string | null,
  arrivalDateTime?: string | null,
  departureDateTime?: string | null,
  stallTypeId?: string | null,
  quantityNeeded?: number | null,
  basePrice?: number | null,
  taxA?: number | null,
  taxB?: number | null,
  totalPrice?: number | null,
  status?: string | null,
  statusNote?: string | null,
  requestNote?: string | null,
  stallSetId?: string | null,
  stallLocation?: string | null,
  stallNumbers?: string | null,
  payerId?: string | null,
  payerName?: string | null,
  splitAcrossEntries?: boolean | null,
  entryIds?: Array< string | null > | null,
  submittedEntry?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteStablingRequestInput = {
  id: string,
};

export type CreateBeddingRequestInput = {
  id?: string | null,
  eventId: string,
  trainerId?: string | null,
  barnId?: string | null,
  personId?: string | null,
  arrivalDateTime?: string | null,
  dateTimeNeeded?: string | null,
  deliveryInfo?: string | null,
  beddingType?: string | null,
  quantityNeeded?: number | null,
  basePrice?: number | null,
  totalPrice?: number | null,
  status?: string | null,
  statusNote?: string | null,
  payerId?: string | null,
  payerName?: string | null,
  splitAcrossEntries?: boolean | null,
  entryIds?: Array< string | null > | null,
  submittedEntry?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  beddingRequestTaxAId?: string | null,
  beddingRequestTaxBId?: string | null,
};

export type ModelBeddingRequestConditionInput = {
  eventId?: ModelIDInput | null,
  trainerId?: ModelIDInput | null,
  barnId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  arrivalDateTime?: ModelStringInput | null,
  dateTimeNeeded?: ModelStringInput | null,
  deliveryInfo?: ModelStringInput | null,
  beddingType?: ModelStringInput | null,
  quantityNeeded?: ModelFloatInput | null,
  basePrice?: ModelFloatInput | null,
  totalPrice?: ModelFloatInput | null,
  status?: ModelStringInput | null,
  statusNote?: ModelStringInput | null,
  payerId?: ModelIDInput | null,
  payerName?: ModelStringInput | null,
  splitAcrossEntries?: ModelBooleanInput | null,
  entryIds?: ModelStringInput | null,
  submittedEntry?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelBeddingRequestConditionInput | null > | null,
  or?: Array< ModelBeddingRequestConditionInput | null > | null,
  not?: ModelBeddingRequestConditionInput | null,
  beddingRequestTaxAId?: ModelIDInput | null,
  beddingRequestTaxBId?: ModelIDInput | null,
};

export type UpdateBeddingRequestInput = {
  id: string,
  eventId?: string | null,
  trainerId?: string | null,
  barnId?: string | null,
  personId?: string | null,
  arrivalDateTime?: string | null,
  dateTimeNeeded?: string | null,
  deliveryInfo?: string | null,
  beddingType?: string | null,
  quantityNeeded?: number | null,
  basePrice?: number | null,
  totalPrice?: number | null,
  status?: string | null,
  statusNote?: string | null,
  payerId?: string | null,
  payerName?: string | null,
  splitAcrossEntries?: boolean | null,
  entryIds?: Array< string | null > | null,
  submittedEntry?: string | null,
  lastUpdatedBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  beddingRequestTaxAId?: string | null,
  beddingRequestTaxBId?: string | null,
};

export type DeleteBeddingRequestInput = {
  id: string,
};

export type CreateSubmissionInput = {
  id?: string | null,
  title: string,
  description?: string | null,
  video?: S3ObjectInput | null,
  videoLength?: string | null,
  riderId?: string | null,
  personId?: string | null,
  totalPrice: number,
  status?: SubmissionStatus | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelSubmissionConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  videoLength?: ModelStringInput | null,
  riderId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  totalPrice?: ModelFloatInput | null,
  status?: ModelSubmissionStatusInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelSubmissionConditionInput | null > | null,
  or?: Array< ModelSubmissionConditionInput | null > | null,
  not?: ModelSubmissionConditionInput | null,
};

export type ModelSubmissionStatusInput = {
  eq?: SubmissionStatus | null,
  ne?: SubmissionStatus | null,
};

export type UpdateSubmissionInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  video?: S3ObjectInput | null,
  videoLength?: string | null,
  riderId?: string | null,
  personId?: string | null,
  totalPrice?: number | null,
  status?: SubmissionStatus | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteSubmissionInput = {
  id: string,
};

export type CreateSubmissionFeedbackInput = {
  id?: string | null,
  type?: string | null,
  submissionId: string,
  feedbackId: string,
  personId?: string | null,
  riderId?: string | null,
  clinicianId?: string | null,
  submissionStatus?: SubmissionStatus | null,
  feedbackStatus?: FeedbackStatus | null,
  clinicianAmount?: number | null,
  declineReason?: string | null,
  permissionToPost?: boolean | null,
  dateSubmitted?: string | null,
  dateCompleted?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  submissionFeedbackClinicianId?: string | null,
};

export type ModelSubmissionFeedbackConditionInput = {
  type?: ModelStringInput | null,
  submissionId?: ModelIDInput | null,
  feedbackId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  riderId?: ModelIDInput | null,
  clinicianId?: ModelIDInput | null,
  submissionStatus?: ModelSubmissionStatusInput | null,
  feedbackStatus?: ModelFeedbackStatusInput | null,
  clinicianAmount?: ModelFloatInput | null,
  declineReason?: ModelStringInput | null,
  permissionToPost?: ModelBooleanInput | null,
  dateSubmitted?: ModelStringInput | null,
  dateCompleted?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelSubmissionFeedbackConditionInput | null > | null,
  or?: Array< ModelSubmissionFeedbackConditionInput | null > | null,
  not?: ModelSubmissionFeedbackConditionInput | null,
  submissionFeedbackClinicianId?: ModelIDInput | null,
};

export type UpdateSubmissionFeedbackInput = {
  id: string,
  type?: string | null,
  submissionId?: string | null,
  feedbackId?: string | null,
  personId?: string | null,
  riderId?: string | null,
  clinicianId?: string | null,
  submissionStatus?: SubmissionStatus | null,
  feedbackStatus?: FeedbackStatus | null,
  clinicianAmount?: number | null,
  declineReason?: string | null,
  permissionToPost?: boolean | null,
  dateSubmitted?: string | null,
  dateCompleted?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  submissionFeedbackClinicianId?: string | null,
};

export type DeleteSubmissionFeedbackInput = {
  id: string,
};

export type CreateTaxRateInput = {
  id?: string | null,
  name?: string | null,
  createdBy?: string | null,
  organizationId?: string | null,
  stripeTaxRateId?: string | null,
  percentage?: number | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type ModelTaxRateConditionInput = {
  name?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  stripeTaxRateId?: ModelStringInput | null,
  percentage?: ModelFloatInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelTaxRateConditionInput | null > | null,
  or?: Array< ModelTaxRateConditionInput | null > | null,
  not?: ModelTaxRateConditionInput | null,
};

export type UpdateTaxRateInput = {
  id: string,
  name?: string | null,
  createdBy?: string | null,
  organizationId?: string | null,
  stripeTaxRateId?: string | null,
  percentage?: number | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteTaxRateInput = {
  id: string,
};

export type CreateTeamInput = {
  id?: string | null,
  name: string,
  nickname?: string | null,
  description?: string | null,
  creatorId?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  teamContactId?: string | null,
  teamLocationId?: string | null,
  teamCreatorId?: string | null,
};

export type ModelTeamConditionInput = {
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  description?: ModelStringInput | null,
  creatorId?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelTeamConditionInput | null > | null,
  or?: Array< ModelTeamConditionInput | null > | null,
  not?: ModelTeamConditionInput | null,
  teamContactId?: ModelIDInput | null,
  teamLocationId?: ModelIDInput | null,
  teamCreatorId?: ModelIDInput | null,
};

export type Team = {
  __typename: "Team",
  id: string,
  name: string,
  nickname?: string | null,
  description?: string | null,
  contact?: Contact | null,
  location?: Address | null,
  members?: ModelTeamMemberConnection | null,
  creatorId?: string | null,
  creator?: Person | null,
  createdOn: string,
  updatedOn: string,
  teamContactId?: string | null,
  teamLocationId?: string | null,
  teamCreatorId?: string | null,
};

export type ModelTeamMemberConnection = {
  __typename: "ModelTeamMemberConnection",
  items:  Array<TeamMember | null >,
  nextToken?: string | null,
};

export type TeamMember = {
  __typename: "TeamMember",
  id: string,
  teamId: string,
  team: Team,
  personId: string,
  person?: Person | null,
  status?: TeamStatus | null,
  role?: TeamRole | null,
  permissionLevel?: TeamPermissionLevel | null,
  creatorId?: string | null,
  creator?: Person | null,
  createdOn: string,
  updatedOn: string,
  teamMemberCreatorId?: string | null,
};

export enum TeamStatus {
  pending = "pending",
  inProgress = "inProgress",
  active = "active",
  inactive = "inactive",
}


export enum TeamPermissionLevel {
  admin = "admin",
  member = "member",
}


export type UpdateTeamInput = {
  id: string,
  name?: string | null,
  nickname?: string | null,
  description?: string | null,
  creatorId?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  teamContactId?: string | null,
  teamLocationId?: string | null,
  teamCreatorId?: string | null,
};

export type DeleteTeamInput = {
  id: string,
};

export type CreateTeamMemberInput = {
  id?: string | null,
  teamId: string,
  personId: string,
  status?: TeamStatus | null,
  role?: TeamRole | null,
  permissionLevel?: TeamPermissionLevel | null,
  creatorId?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  teamMemberCreatorId?: string | null,
};

export type ModelTeamMemberConditionInput = {
  teamId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  status?: ModelTeamStatusInput | null,
  role?: ModelTeamRoleInput | null,
  permissionLevel?: ModelTeamPermissionLevelInput | null,
  creatorId?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelTeamMemberConditionInput | null > | null,
  or?: Array< ModelTeamMemberConditionInput | null > | null,
  not?: ModelTeamMemberConditionInput | null,
  teamMemberCreatorId?: ModelIDInput | null,
};

export type ModelTeamStatusInput = {
  eq?: TeamStatus | null,
  ne?: TeamStatus | null,
};

export type ModelTeamPermissionLevelInput = {
  eq?: TeamPermissionLevel | null,
  ne?: TeamPermissionLevel | null,
};

export type UpdateTeamMemberInput = {
  id: string,
  teamId?: string | null,
  personId?: string | null,
  status?: TeamStatus | null,
  role?: TeamRole | null,
  permissionLevel?: TeamPermissionLevel | null,
  creatorId?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  teamMemberCreatorId?: string | null,
};

export type DeleteTeamMemberInput = {
  id: string,
};

export type CreateTextInput = {
  id?: string | null,
  eventId?: string | null,
  blockId?: string | null,
  alertId?: string | null,
  auditorId?: string | null,
  status?: TextStatus | null,
  statusNote?: string | null,
  errorCode?: string | null,
  twilioSID?: string | null,
  twilioMessagingServiceSID?: string | null,
  twilioNote?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  timeSent?: string | null,
  timeDelivered?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export enum TextStatus {
  pending = "pending",
  sent = "sent",
  failed = "failed",
}


export type ModelTextConditionInput = {
  eventId?: ModelStringInput | null,
  blockId?: ModelIDInput | null,
  alertId?: ModelIDInput | null,
  auditorId?: ModelIDInput | null,
  status?: ModelTextStatusInput | null,
  statusNote?: ModelStringInput | null,
  errorCode?: ModelStringInput | null,
  twilioSID?: ModelStringInput | null,
  twilioMessagingServiceSID?: ModelStringInput | null,
  twilioNote?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  timeSent?: ModelStringInput | null,
  timeDelivered?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelTextConditionInput | null > | null,
  or?: Array< ModelTextConditionInput | null > | null,
  not?: ModelTextConditionInput | null,
};

export type ModelTextStatusInput = {
  eq?: TextStatus | null,
  ne?: TextStatus | null,
};

export type Text = {
  __typename: "Text",
  id: string,
  eventId?: string | null,
  blockId?: string | null,
  block?: Block | null,
  alertId?: string | null,
  alert?: Alert | null,
  auditorId?: string | null,
  auditor?: EventAuditor | null,
  status?: TextStatus | null,
  statusNote?: string | null,
  errorCode?: string | null,
  twilioSID?: string | null,
  twilioMessagingServiceSID?: string | null,
  twilioNote?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  timeSent?: string | null,
  timeDelivered?: string | null,
  createdOn: string,
  updatedOn: string,
};

export type UpdateTextInput = {
  id: string,
  eventId?: string | null,
  blockId?: string | null,
  alertId?: string | null,
  auditorId?: string | null,
  status?: TextStatus | null,
  statusNote?: string | null,
  errorCode?: string | null,
  twilioSID?: string | null,
  twilioMessagingServiceSID?: string | null,
  twilioNote?: string | null,
  createdBy?: string | null,
  lastUpdatedBy?: string | null,
  timeSent?: string | null,
  timeDelivered?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
};

export type DeleteTextInput = {
  id: string,
};

export type CreateTrainerInput = {
  id?: string | null,
  name: string,
  location?: string | null,
  addressId?: string | null,
  personId?: string | null,
  barnName?: string | null,
  barnId?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  trainerContactId?: string | null,
};

export type ModelTrainerConditionInput = {
  name?: ModelStringInput | null,
  location?: ModelStringInput | null,
  addressId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  barnName?: ModelStringInput | null,
  barnId?: ModelIDInput | null,
  createdBy?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelTrainerConditionInput | null > | null,
  or?: Array< ModelTrainerConditionInput | null > | null,
  not?: ModelTrainerConditionInput | null,
  trainerContactId?: ModelIDInput | null,
};

export type UpdateTrainerInput = {
  id: string,
  name?: string | null,
  location?: string | null,
  addressId?: string | null,
  personId?: string | null,
  barnName?: string | null,
  barnId?: string | null,
  createdBy?: string | null,
  createdOn?: string | null,
  updatedOn?: string | null,
  trainerContactId?: string | null,
};

export type DeleteTrainerInput = {
  id: string,
};

export type CreateOrganizationMembershipTypeSeasonsInput = {
  id?: string | null,
  organizationMembershipTypeID: string,
  seasonID: string,
};

export type ModelOrganizationMembershipTypeSeasonsConditionInput = {
  organizationMembershipTypeID?: ModelIDInput | null,
  seasonID?: ModelIDInput | null,
  and?: Array< ModelOrganizationMembershipTypeSeasonsConditionInput | null > | null,
  or?: Array< ModelOrganizationMembershipTypeSeasonsConditionInput | null > | null,
  not?: ModelOrganizationMembershipTypeSeasonsConditionInput | null,
};

export type UpdateOrganizationMembershipTypeSeasonsInput = {
  id: string,
  organizationMembershipTypeID?: string | null,
  seasonID?: string | null,
};

export type DeleteOrganizationMembershipTypeSeasonsInput = {
  id: string,
};

export type ModelAddressFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  isDefault?: ModelBooleanInput | null,
  streetAddress1?: ModelStringInput | null,
  streetAddress2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  provState?: ModelStringInput | null,
  zip?: ModelStringInput | null,
  country?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  and?: Array< ModelAddressFilterInput | null > | null,
  or?: Array< ModelAddressFilterInput | null > | null,
  not?: ModelAddressFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAddressConnection = {
  __typename: "ModelAddressConnection",
  items:  Array<Address | null >,
  nextToken?: string | null,
};

export type ModelAlertFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  imageKey?: ModelStringInput | null,
  category?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  messageSegments?: ModelIntInput | null,
  subscriberCount?: ModelIntInput | null,
  totalCost?: ModelFloatInput | null,
  blockIdsUsed?: ModelStringInput | null,
  fromUnlimitedEvent?: ModelBooleanInput | null,
  isIncoming?: ModelStringInput | null,
  sentFromPhoneNumber?: ModelStringInput | null,
  recipientPhoneNumbers?: ModelStringInput | null,
  successSIDList?: ModelStringInput | null,
  errorSIDList?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelAlertFilterInput | null > | null,
  or?: Array< ModelAlertFilterInput | null > | null,
  not?: ModelAlertFilterInput | null,
  alertEventId?: ModelIDInput | null,
};

export type ModelAlertConnection = {
  __typename: "ModelAlertConnection",
  items:  Array<Alert | null >,
  nextToken?: string | null,
};

export type ModelAuditorFilterInput = {
  id?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  email?: ModelStringInput | null,
  status?: ModelAuditorStatusInput | null,
  statusNote?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelAuditorFilterInput | null > | null,
  or?: Array< ModelAuditorFilterInput | null > | null,
  not?: ModelAuditorFilterInput | null,
};

export type ModelAuditorConnection = {
  __typename: "ModelAuditorConnection",
  items:  Array<Auditor | null >,
  nextToken?: string | null,
};

export type ModelBacklogEventResultFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  organizationMemberId?: ModelStringInput | null,
  organizationResultId?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  eventName?: ModelStringInput | null,
  eventStartDate?: ModelStringInput | null,
  eventEndDate?: ModelStringInput | null,
  eventDivisionId?: ModelStringInput | null,
  eventDivisionName?: ModelStringInput | null,
  organizationDivisionId?: ModelIDInput | null,
  eventClassId?: ModelStringInput | null,
  eventClassName?: ModelStringInput | null,
  eventClassNumber?: ModelStringInput | null,
  organizationClassId?: ModelIDInput | null,
  riderName?: ModelStringInput | null,
  horseName?: ModelStringInput | null,
  place?: ModelIntInput | null,
  pointsEarned?: ModelIntInput | null,
  score?: ModelFloatInput | null,
  time?: ModelStringInput | null,
  didNotCompete?: ModelBooleanInput | null,
  prizeMoney?: ModelFloatInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelBacklogEventResultFilterInput | null > | null,
  or?: Array< ModelBacklogEventResultFilterInput | null > | null,
  not?: ModelBacklogEventResultFilterInput | null,
  backlogEventResultPrizeMoneyTableId?: ModelIDInput | null,
};

export type ModelBacklogEventResultConnection = {
  __typename: "ModelBacklogEventResultConnection",
  items:  Array<BacklogEventResult | null >,
  nextToken?: string | null,
};

export type ModelBarnFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  ownerName?: ModelStringInput | null,
  ownerPersonId?: ModelIDInput | null,
  secondaryOwnerName?: ModelStringInput | null,
  secondaryOwnerPersonId?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelBarnFilterInput | null > | null,
  or?: Array< ModelBarnFilterInput | null > | null,
  not?: ModelBarnFilterInput | null,
  barnContactId?: ModelIDInput | null,
  barnLocationId?: ModelIDInput | null,
};

export type ModelBarnConnection = {
  __typename: "ModelBarnConnection",
  items:  Array<Barn | null >,
  nextToken?: string | null,
};

export type ModelBarnMemberFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  barnId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  permissionLevel?: ModelStringInput | null,
  status?: ModelStringInput | null,
  roles?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelBarnMemberFilterInput | null > | null,
  or?: Array< ModelBarnMemberFilterInput | null > | null,
  not?: ModelBarnMemberFilterInput | null,
};

export type ModelBlockFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  personid?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  purchasedOn?: ModelStringInput | null,
  expiresOn?: ModelStringInput | null,
  totalCredits?: ModelIntInput | null,
  usedCredits?: ModelIntInput | null,
  status?: ModelBlockStatusInput | null,
  price?: ModelFloatInput | null,
  amountPaid?: ModelFloatInput | null,
  isPromotion?: ModelStringInput | null,
  isFree?: ModelStringInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelBlockFilterInput | null > | null,
  or?: Array< ModelBlockFilterInput | null > | null,
  not?: ModelBlockFilterInput | null,
};

export type ModelBlockConnection = {
  __typename: "ModelBlockConnection",
  items:  Array<Block | null >,
  nextToken?: string | null,
};

export type ModelClassFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  divisionId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  description?: ModelStringInput | null,
  discipline?: ModelStringInput | null,
  ushjaName?: ModelStringInput | null,
  usefNumber?: ModelStringInput | null,
  ecNumber?: ModelStringInput | null,
  ecClassTypeNumber?: ModelStringInput | null,
  ecClassType?: ModelStringInput | null,
  code?: ModelStringInput | null,
  specs?: ModelStringInput | null,
  judgedOn?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelClassFilterInput | null > | null,
  or?: Array< ModelClassFilterInput | null > | null,
  not?: ModelClassFilterInput | null,
  eventInvoiceClassesId?: ModelIDInput | null,
};

export type ModelClassOrderFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  eventClassId?: ModelIDInput | null,
  entryId?: ModelIDInput | null,
  order?: ModelIntInput | null,
  isRandom?: ModelBooleanInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelClassOrderFilterInput | null > | null,
  or?: Array< ModelClassOrderFilterInput | null > | null,
  not?: ModelClassOrderFilterInput | null,
};

export type ModelClassOrderConnection = {
  __typename: "ModelClassOrderConnection",
  items:  Array<ClassOrder | null >,
  nextToken?: string | null,
};

export type ModelClinicianFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  personId?: ModelIDInput | null,
  judgeId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  usefNumber?: ModelStringInput | null,
  ecNumber?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  status?: ModelClinicianStatusInput | null,
  paymentVerified?: ModelBooleanInput | null,
  stripeAccountId?: ModelStringInput | null,
  totalEarned?: ModelFloatInput | null,
  location?: ModelStringInput | null,
  disciplines?: ModelStringInput | null,
  experience?: ModelStringInput | null,
  personal?: ModelStringInput | null,
  preferences?: ModelStringInput | null,
  hasIHSAExperience?: ModelBooleanInput | null,
  hasIEAExperience?: ModelBooleanInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelClinicianFilterInput | null > | null,
  or?: Array< ModelClinicianFilterInput | null > | null,
  not?: ModelClinicianFilterInput | null,
  clinicianPaymentChoiceId?: ModelIDInput | null,
};

export type ModelClinicianConnection = {
  __typename: "ModelClinicianConnection",
  items:  Array<Clinician | null >,
  nextToken?: string | null,
};

export type ModelCompetitionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  teamId?: ModelIDInput | null,
  addressId?: ModelIDInput | null,
  competitionOrganizerName?: ModelStringInput | null,
  competitionOrganizerContactId?: ModelIDInput | null,
  judgeName?: ModelStringInput | null,
  secretaryName?: ModelStringInput | null,
  StewardName?: ModelStringInput | null,
  isOpenOffered?: ModelBooleanInput | null,
  isIntermediateOffered?: ModelBooleanInput | null,
  isNoviceOffered?: ModelBooleanInput | null,
  isAdvancedBeginnerOffered?: ModelBooleanInput | null,
  isBeginnerOffered?: ModelBooleanInput | null,
  isMinisOffered?: ModelBooleanInput | null,
  isAdultOffered?: ModelBooleanInput | null,
  isAELMedalOffered?: ModelBooleanInput | null,
  entryFeePerRider?: ModelFloatInput | null,
  entryFeePerRiderForMedal?: ModelFloatInput | null,
  feePaymentOptions?: ModelStringInput | null,
  entryClosingDate?: ModelStringInput | null,
  entryClosingTime?: ModelStringInput | null,
  isEntryLimitInPlace?: ModelBooleanInput | null,
  entryLimitDescription?: ModelStringInput | null,
  otherNotes?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelCompetitionFilterInput | null > | null,
  or?: Array< ModelCompetitionFilterInput | null > | null,
  not?: ModelCompetitionFilterInput | null,
};

export type ModelCompetitionConnection = {
  __typename: "ModelCompetitionConnection",
  items:  Array<Competition | null >,
  nextToken?: string | null,
};

export type ModelContactFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  cell?: ModelStringInput | null,
  home?: ModelStringInput | null,
  work?: ModelStringInput | null,
  fax?: ModelStringInput | null,
  personalEmail?: ModelStringInput | null,
  personalEmailIsVerified?: ModelBooleanInput | null,
  workEmail?: ModelStringInput | null,
  workEmailIsVerified?: ModelBooleanInput | null,
  mailingAddress?: ModelIDInput | null,
  website?: ModelStringInput | null,
  facebookPage?: ModelStringInput | null,
  instagramHandle?: ModelStringInput | null,
  twitterHandle?: ModelStringInput | null,
  tiktokHandle?: ModelStringInput | null,
  emergencyContactName?: ModelStringInput | null,
  emergencyContactPhone?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  and?: Array< ModelContactFilterInput | null > | null,
  or?: Array< ModelContactFilterInput | null > | null,
  not?: ModelContactFilterInput | null,
};

export type ModelContactConnection = {
  __typename: "ModelContactConnection",
  items:  Array<Contact | null >,
  nextToken?: string | null,
};

export type ModelDivisionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  description?: ModelStringInput | null,
  discipline?: ModelStringInput | null,
  isSearchable?: ModelBooleanInput | null,
  ushjaName?: ModelStringInput | null,
  usefNumber?: ModelStringInput | null,
  ecNumber?: ModelStringInput | null,
  ecClassTypeNumber?: ModelStringInput | null,
  ecClassType?: ModelStringInput | null,
  code?: ModelStringInput | null,
  specs?: ModelStringInput | null,
  judgedOn?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelDivisionFilterInput | null > | null,
  or?: Array< ModelDivisionFilterInput | null > | null,
  not?: ModelDivisionFilterInput | null,
  eventInvoiceDivisionsId?: ModelIDInput | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  manager?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  contactId?: ModelIDInput | null,
  contactIds?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  disciplines?: ModelStringInput | null,
  status?: ModelStringInput | null,
  entriesStatus?: ModelStringInput | null,
  showStatus?: ModelStringInput | null,
  usefID?: ModelStringInput | null,
  usefSoftwareKey?: ModelStringInput | null,
  ecID?: ModelStringInput | null,
  ecPassword?: ModelStringInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
  eventAddressId?: ModelIDInput | null,
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
};

export type ModelEventAddFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  entryId?: ModelIDInput | null,
  eventDivisionId?: ModelIDInput | null,
  eventDivisionName?: ModelStringInput | null,
  eventClassId?: ModelIDInput | null,
  eventClassName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventAddFilterInput | null > | null,
  or?: Array< ModelEventAddFilterInput | null > | null,
  not?: ModelEventAddFilterInput | null,
};

export type ModelEventAddConnection = {
  __typename: "ModelEventAddConnection",
  items:  Array<EventAdd | null >,
  nextToken?: string | null,
};

export type ModelEventAlertSettingFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  categories?: ModelStringInput | null,
  useDescription?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventAlertSettingFilterInput | null > | null,
  or?: Array< ModelEventAlertSettingFilterInput | null > | null,
  not?: ModelEventAlertSettingFilterInput | null,
};

export type ModelEventAlertSettingConnection = {
  __typename: "ModelEventAlertSettingConnection",
  items:  Array<EventAlertSetting | null >,
  nextToken?: string | null,
};

export type ModelEventAuditorFilterInput = {
  id?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  auditorId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventAuditorFilterInput | null > | null,
  or?: Array< ModelEventAuditorFilterInput | null > | null,
  not?: ModelEventAuditorFilterInput | null,
  eventAuditorEventId?: ModelIDInput | null,
};

export type ModelEventAuditorConnection = {
  __typename: "ModelEventAuditorConnection",
  items:  Array<EventAuditor | null >,
  nextToken?: string | null,
};

export type ModelOrganizationAuditorFilterInput = {
  id?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  howSignedUp?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  auditorId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationAuditorFilterInput | null > | null,
  or?: Array< ModelOrganizationAuditorFilterInput | null > | null,
  not?: ModelOrganizationAuditorFilterInput | null,
  organizationAuditorPersonId?: ModelIDInput | null,
  organizationAuditorOrganizationId?: ModelIDInput | null,
};

export type ModelOrganizationAuditorConnection = {
  __typename: "ModelOrganizationAuditorConnection",
  items:  Array<OrganizationAuditor | null >,
  nextToken?: string | null,
};

export type ModelEventAuditorFilterFilterInput = {
  id?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  eventauditorid?: ModelStringInput | null,
  eventAuditorId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  organizationAuditorId?: ModelIDInput | null,
  filterId?: ModelIDInput | null,
  options?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventAuditorFilterFilterInput | null > | null,
  or?: Array< ModelEventAuditorFilterFilterInput | null > | null,
  not?: ModelEventAuditorFilterFilterInput | null,
  eventAuditorFilterOrganizationId?: ModelIDInput | null,
};

export type ModelEventBeddingTypeFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  organizationBeddingTypeId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  pricePerBag?: ModelStringInput | null,
  taxPerBag?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventBeddingTypeFilterInput | null > | null,
  or?: Array< ModelEventBeddingTypeFilterInput | null > | null,
  not?: ModelEventBeddingTypeFilterInput | null,
};

export type ModelEventBeddingTypeConnection = {
  __typename: "ModelEventBeddingTypeConnection",
  items:  Array<EventBeddingType | null >,
  nextToken?: string | null,
};

export type ModelEventBreakFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  time?: ModelStringInput | null,
  isDisplayedOnSchedule?: ModelBooleanInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventBreakFilterInput | null > | null,
  or?: Array< ModelEventBreakFilterInput | null > | null,
  not?: ModelEventBreakFilterInput | null,
};

export type ModelEventBreakConnection = {
  __typename: "ModelEventBreakConnection",
  items:  Array<EventBreak | null >,
  nextToken?: string | null,
};

export type ModelEventClassFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  classId?: ModelIDInput | null,
  eventDivisionId?: ModelIDInput | null,
  organizationClassId?: ModelIDInput | null,
  organizationDivisionId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  number?: ModelIntInput | null,
  entryOption?: ModelEntryOptionInput | null,
  entryFee?: ModelStringInput | null,
  prizeMoney?: ModelStringInput | null,
  completedVerifyEntries?: ModelBooleanInput | null,
  currentNumberEntries?: ModelIntInput | null,
  numberEntriesThatCompeted?: ModelIntInput | null,
  minNumberEntries?: ModelIntInput | null,
  maxNumberEntries?: ModelIntInput | null,
  estimatedTimePerRound?: ModelIntInput | null,
  isCaliforniaSplit?: ModelBooleanInput | null,
  isCombined?: ModelBooleanInput | null,
  combinedEventClassId?: ModelIDInput | null,
  combinedEventDivisionId?: ModelIDInput | null,
  jumpHeight?: ModelStringInput | null,
  isScheduled?: ModelBooleanInput | null,
  note?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventClassFilterInput | null > | null,
  or?: Array< ModelEventClassFilterInput | null > | null,
  not?: ModelEventClassFilterInput | null,
  eventClassPointTableId?: ModelIDInput | null,
  eventClassPrizeMoneyTableId?: ModelIDInput | null,
};

export type ModelEventClassEntryFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  eventClassId?: ModelIDInput | null,
  eventEntryId?: ModelIDInput | null,
  riderId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  selectedEntryOption?: ModelEntryOptionInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventClassEntryFilterInput | null > | null,
  or?: Array< ModelEventClassEntryFilterInput | null > | null,
  not?: ModelEventClassEntryFilterInput | null,
};

export type ModelEventDayFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  awsdate?: ModelStringInput | null,
  dayofweek?: ModelStringInput | null,
  date?: ModelStringInput | null,
  month?: ModelStringInput | null,
  year?: ModelStringInput | null,
  and?: Array< ModelEventDayFilterInput | null > | null,
  or?: Array< ModelEventDayFilterInput | null > | null,
  not?: ModelEventDayFilterInput | null,
};

export type ModelEventDayConnection = {
  __typename: "ModelEventDayConnection",
  items:  Array<EventDay | null >,
  nextToken?: string | null,
};

export type ModelEventDivisionFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  number?: ModelIntInput | null,
  divisionId?: ModelIDInput | null,
  organizationDivisionId?: ModelIDInput | null,
  entryFee?: ModelStringInput | null,
  prizeMoney?: ModelStringInput | null,
  jumpHeight?: ModelStringInput | null,
  minNumberEntriesPerClass?: ModelIntInput | null,
  maxNumberEntriesPerClass?: ModelIntInput | null,
  isCaliforniaSplit?: ModelBooleanInput | null,
  isCombined?: ModelBooleanInput | null,
  combinedEventDivisionId?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventDivisionFilterInput | null > | null,
  or?: Array< ModelEventDivisionFilterInput | null > | null,
  not?: ModelEventDivisionFilterInput | null,
  eventDivisionOrganizationDivisionId?: ModelIDInput | null,
  eventDivisionPointTableId?: ModelIDInput | null,
  eventDivisionPrizeMoneyTableId?: ModelIDInput | null,
};

export type ModelEventDivisionConnection = {
  __typename: "ModelEventDivisionConnection",
  items:  Array<EventDivision | null >,
  nextToken?: string | null,
};

export type ModelEventEntryFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  statusNote?: ModelStringInput | null,
  personId?: ModelStringInput | null,
  number?: ModelIntInput | null,
  isCheckedIn?: ModelBooleanInput | null,
  isCheckedOut?: ModelBooleanInput | null,
  contactId?: ModelIDInput | null,
  riderName?: ModelStringInput | null,
  riderId?: ModelIDInput | null,
  secondRiderName?: ModelStringInput | null,
  secondRiderId?: ModelIDInput | null,
  thirdRiderName?: ModelStringInput | null,
  thirdRiderId?: ModelIDInput | null,
  horseName?: ModelStringInput | null,
  horseId?: ModelIDInput | null,
  ownerName?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  secondOwnerName?: ModelStringInput | null,
  secondOwnerId?: ModelIDInput | null,
  thirdOwnerName?: ModelStringInput | null,
  thirdOwnerId?: ModelIDInput | null,
  trainerName?: ModelStringInput | null,
  trainerId?: ModelIDInput | null,
  secondTrainerName?: ModelStringInput | null,
  secondTrainerId?: ModelIDInput | null,
  thirdTrainerName?: ModelStringInput | null,
  thirdTrainerId?: ModelIDInput | null,
  barnName?: ModelStringInput | null,
  barnId?: ModelIDInput | null,
  stablingRequestId?: ModelIDInput | null,
  beddingRequestId?: ModelIDInput | null,
  markedDoesNotNeedStalls?: ModelBooleanInput | null,
  payerName?: ModelStringInput | null,
  payerId?: ModelIDInput | null,
  prizeMoneyRecipientName?: ModelStringInput | null,
  prizeMoneyRecipientId?: ModelIDInput | null,
  prizeMoneyRecipientType?: ModelStringInput | null,
  note?: ModelStringInput | null,
  divisionList?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventEntryFilterInput | null > | null,
  or?: Array< ModelEventEntryFilterInput | null > | null,
  not?: ModelEventEntryFilterInput | null,
  eventEntryPrizeMoneyRecipientContactId?: ModelIDInput | null,
};

export type ModelEventEntryFeeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  quantity?: ModelIntInput | null,
  taxA?: ModelFloatInput | null,
  taxB?: ModelFloatInput | null,
  eventId?: ModelIDInput | null,
  feeId?: ModelIDInput | null,
  eventFeeId?: ModelIDInput | null,
  entryId?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventEntryFeeFilterInput | null > | null,
  or?: Array< ModelEventEntryFeeFilterInput | null > | null,
  not?: ModelEventEntryFeeFilterInput | null,
};

export type ModelEventEntryFeeConnection = {
  __typename: "ModelEventEntryFeeConnection",
  items:  Array<EventEntryFee | null >,
  nextToken?: string | null,
};

export type ModelEventEntryNumbersFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  available?: ModelStringInput | null,
  totalAvailable?: ModelIntInput | null,
  used?: ModelStringInput | null,
  totalUsed?: ModelIntInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventEntryNumbersFilterInput | null > | null,
  or?: Array< ModelEventEntryNumbersFilterInput | null > | null,
  not?: ModelEventEntryNumbersFilterInput | null,
};

export type ModelEventEntryNumbersConnection = {
  __typename: "ModelEventEntryNumbersConnection",
  items:  Array<EventEntryNumbers | null >,
  nextToken?: string | null,
};

export type ModelEventFeeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  description?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  eventClassId?: ModelIDInput | null,
  feeId?: ModelIDInput | null,
  splitAcrossEntries?: ModelBooleanInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventFeeFilterInput | null > | null,
  or?: Array< ModelEventFeeFilterInput | null > | null,
  not?: ModelEventFeeFilterInput | null,
};

export type ModelEventFilterFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  options?: ModelStringInput | null,
  isRequired?: ModelBooleanInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventFilterFilterInput | null > | null,
  or?: Array< ModelEventFilterFilterInput | null > | null,
  not?: ModelEventFilterFilterInput | null,
};

export type ModelEventFilterConnection = {
  __typename: "ModelEventFilterConnection",
  items:  Array<EventFilter | null >,
  nextToken?: string | null,
};

export type ModelEventInvoiceFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  statusNote?: ModelStringInput | null,
  number?: ModelIntInput | null,
  stripeInvoiceNumber?: ModelStringInput | null,
  entryNumber?: ModelIntInput | null,
  entryId?: ModelIDInput | null,
  payerName?: ModelStringInput | null,
  payerId?: ModelIDInput | null,
  payerType?: ModelStringInput | null,
  payerStripeId?: ModelStringInput | null,
  prizeMoneyRecipientName?: ModelStringInput | null,
  prizeMoneyRecipientId?: ModelIDInput | null,
  prizeMoneyRecipientStripeId?: ModelStringInput | null,
  prizeMoneyRecipientType?: ModelStringInput | null,
  entryFeeTotalAmount?: ModelFloatInput | null,
  otherFeeTotalAmount?: ModelFloatInput | null,
  stablingAmount?: ModelFloatInput | null,
  beddingAmount?: ModelFloatInput | null,
  feedAmount?: ModelFloatInput | null,
  prizeMoneyAmount?: ModelFloatInput | null,
  amountToDeductFromInvoice?: ModelFloatInput | null,
  amountToPayRecipientViaCheck?: ModelFloatInput | null,
  totalDue?: ModelFloatInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventInvoiceFilterInput | null > | null,
  or?: Array< ModelEventInvoiceFilterInput | null > | null,
  not?: ModelEventInvoiceFilterInput | null,
  eventInvoicePayerContactId?: ModelIDInput | null,
  eventInvoicePrizeMoneyRecipientId?: ModelIDInput | null,
  eventInvoicePrizeMoneyRecipientContactId?: ModelIDInput | null,
};

export type ModelEventInvoiceConnection = {
  __typename: "ModelEventInvoiceConnection",
  items:  Array<EventInvoice | null >,
  nextToken?: string | null,
};

export type ModelEventJudgeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  judgeId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  and?: Array< ModelEventJudgeFilterInput | null > | null,
  or?: Array< ModelEventJudgeFilterInput | null > | null,
  not?: ModelEventJudgeFilterInput | null,
};

export type ModelEventJudgeConnection = {
  __typename: "ModelEventJudgeConnection",
  items:  Array<EventJudge | null >,
  nextToken?: string | null,
};

export type ModelEventOfficialFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  position?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  and?: Array< ModelEventOfficialFilterInput | null > | null,
  or?: Array< ModelEventOfficialFilterInput | null > | null,
  not?: ModelEventOfficialFilterInput | null,
};

export type ModelEventResultFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  entryId?: ModelIDInput | null,
  eventClassEntryId?: ModelIDInput | null,
  eventDivisionId?: ModelIDInput | null,
  eventDivisionName?: ModelStringInput | null,
  eventClassId?: ModelIDInput | null,
  eventClassName?: ModelStringInput | null,
  resultSet?: ModelStringInput | null,
  place?: ModelIntInput | null,
  score?: ModelFloatInput | null,
  time?: ModelStringInput | null,
  didNotCompete?: ModelBooleanInput | null,
  prizeMoney?: ModelFloatInput | null,
  note?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventResultFilterInput | null > | null,
  or?: Array< ModelEventResultFilterInput | null > | null,
  not?: ModelEventResultFilterInput | null,
  eventResultEntryId?: ModelIDInput | null,
  eventResultEventClassEntryId?: ModelIDInput | null,
  eventResultPrizeMoneyTableId?: ModelIDInput | null,
};

export type ModelEventRingFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  ringId?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventRingFilterInput | null > | null,
  or?: Array< ModelEventRingFilterInput | null > | null,
  not?: ModelEventRingFilterInput | null,
};

export type ModelEventRingConnection = {
  __typename: "ModelEventRingConnection",
  items:  Array<EventRing | null >,
  nextToken?: string | null,
};

export type ModelEventRulesFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  text?: ModelStringInput | null,
  requiresAgreementPerEntry?: ModelBooleanInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventRulesFilterInput | null > | null,
  or?: Array< ModelEventRulesFilterInput | null > | null,
  not?: ModelEventRulesFilterInput | null,
};

export type ModelEventRulesConnection = {
  __typename: "ModelEventRulesConnection",
  items:  Array<EventRules | null >,
  nextToken?: string | null,
};

export type ModelEventRulesAgreementFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  eventRuleId?: ModelIDInput | null,
  eventEntryId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  personId?: ModelIDInput | null,
  personType?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventRulesAgreementFilterInput | null > | null,
  or?: Array< ModelEventRulesAgreementFilterInput | null > | null,
  not?: ModelEventRulesAgreementFilterInput | null,
};

export type ModelEventScratchFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  entryId?: ModelIDInput | null,
  eventDivisionId?: ModelIDInput | null,
  eventDivisionName?: ModelStringInput | null,
  eventClassId?: ModelIDInput | null,
  eventClassName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventScratchFilterInput | null > | null,
  or?: Array< ModelEventScratchFilterInput | null > | null,
  not?: ModelEventScratchFilterInput | null,
};

export type ModelEventScratchConnection = {
  __typename: "ModelEventScratchConnection",
  items:  Array<EventScratch | null >,
  nextToken?: string | null,
};

export type ModelEventStallSetFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  organizationStallSetId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  location?: ModelStringInput | null,
  available?: ModelStringInput | null,
  totalAvailable?: ModelIntInput | null,
  used?: ModelStringInput | null,
  totalUsed?: ModelIntInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventStallSetFilterInput | null > | null,
  or?: Array< ModelEventStallSetFilterInput | null > | null,
  not?: ModelEventStallSetFilterInput | null,
};

export type ModelEventStallSetConnection = {
  __typename: "ModelEventStallSetConnection",
  items:  Array<EventStallSet | null >,
  nextToken?: string | null,
};

export type ModelEventStallTypeFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  organizationStallTypeId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  pricePerStall?: ModelStringInput | null,
  taxPerStall?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelEventStallTypeFilterInput | null > | null,
  or?: Array< ModelEventStallTypeFilterInput | null > | null,
  not?: ModelEventStallTypeFilterInput | null,
};

export type ModelEventStallTypeConnection = {
  __typename: "ModelEventStallTypeConnection",
  items:  Array<EventStallType | null >,
  nextToken?: string | null,
};

export type ModelFeeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  stripeId?: ModelStringInput | null,
  altId?: ModelStringInput | null,
  isFixed?: ModelBooleanInput | null,
  isPercentage?: ModelBooleanInput | null,
  totalPrice?: ModelFloatInput | null,
  quantity?: ModelFloatInput | null,
  isPerEntry?: ModelBooleanInput | null,
  isPerHorse?: ModelBooleanInput | null,
  isPerRider?: ModelBooleanInput | null,
  isPerClass?: ModelBooleanInput | null,
  isPerTrainer?: ModelBooleanInput | null,
  isRefundable?: ModelBooleanInput | null,
  isChargedAtTimeOfEntry?: ModelBooleanInput | null,
  isStartedOnEntryDate?: ModelBooleanInput | null,
  isHiddenFromEntryForm?: ModelBooleanInput | null,
  startDate?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  taxA?: ModelFloatInput | null,
  taxB?: ModelFloatInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelFeeFilterInput | null > | null,
  or?: Array< ModelFeeFilterInput | null > | null,
  not?: ModelFeeFilterInput | null,
  eventInvoiceFeesId?: ModelIDInput | null,
  feeTaxRateAId?: ModelIDInput | null,
  feeTaxRateBId?: ModelIDInput | null,
};

export type ModelFeedbackFilterInput = {
  id?: ModelIDInput | null,
  clinicianId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  technical?: ModelStringInput | null,
  style?: ModelStringInput | null,
  turnout?: ModelStringInput | null,
  general?: ModelStringInput | null,
  score?: ModelFloatInput | null,
  status?: ModelFeedbackStatusInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelFeedbackFilterInput | null > | null,
  or?: Array< ModelFeedbackFilterInput | null > | null,
  not?: ModelFeedbackFilterInput | null,
  feedbackSubmissionFeedbackId?: ModelIDInput | null,
};

export type ModelFeedbackConnection = {
  __typename: "ModelFeedbackConnection",
  items:  Array<Feedback | null >,
  nextToken?: string | null,
};

export type ModelGameInputFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  eventClassId?: ModelIDInput | null,
  backNumber?: ModelStringInput | null,
  round?: ModelStringInput | null,
  score?: ModelFloatInput | null,
  isMasterRecord?: ModelBooleanInput | null,
  createdBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelGameInputFilterInput | null > | null,
  or?: Array< ModelGameInputFilterInput | null > | null,
  not?: ModelGameInputFilterInput | null,
};

export type ModelGameInputConnection = {
  __typename: "ModelGameInputConnection",
  items:  Array<GameInput | null >,
  nextToken?: string | null,
};

export type ModelHorseFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  previousNames?: ModelStringInput | null,
  personId?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  ownerName?: ModelStringInput | null,
  barnName?: ModelStringInput | null,
  barnId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  microchipNumber?: ModelStringInput | null,
  passportNumber?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  breed?: ModelStringInput | null,
  sex?: ModelStringInput | null,
  color?: ModelStringInput | null,
  markings?: ModelStringInput | null,
  height?: ModelStringInput | null,
  foalState?: ModelStringInput | null,
  cogginsNumber?: ModelStringInput | null,
  cogginsDate?: ModelStringInput | null,
  cogginsState?: ModelStringInput | null,
  highestLevelCompeted?: ModelStringInput | null,
  greenEligibility?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelHorseFilterInput | null > | null,
  or?: Array< ModelHorseFilterInput | null > | null,
  not?: ModelHorseFilterInput | null,
};

export type ModelHorseConnection = {
  __typename: "ModelHorseConnection",
  items:  Array<Horse | null >,
  nextToken?: string | null,
};

export type ModelPedigreeFilterInput = {
  id?: ModelIDInput | null,
  horseId?: ModelIDInput | null,
  sireId?: ModelIDInput | null,
  sireName?: ModelStringInput | null,
  damId?: ModelIDInput | null,
  damName?: ModelStringInput | null,
  breederName?: ModelStringInput | null,
  breederId?: ModelIDInput | null,
  breedRegistryInfo?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPedigreeFilterInput | null > | null,
  or?: Array< ModelPedigreeFilterInput | null > | null,
  not?: ModelPedigreeFilterInput | null,
  pedigreeBirthplaceId?: ModelIDInput | null,
  pedigreeBreederContactId?: ModelIDInput | null,
};

export type ModelPedigreeConnection = {
  __typename: "ModelPedigreeConnection",
  items:  Array<Pedigree | null >,
  nextToken?: string | null,
};

export type ModelMeasurementRecordFilterInput = {
  id?: ModelIDInput | null,
  horseId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  status?: ModelStringInput | null,
  height?: ModelStringInput | null,
  heightOfHeel?: ModelStringInput | null,
  shoeingStatus?: ModelStringInput | null,
  steward?: ModelStringInput | null,
  official?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelMeasurementRecordFilterInput | null > | null,
  or?: Array< ModelMeasurementRecordFilterInput | null > | null,
  not?: ModelMeasurementRecordFilterInput | null,
};

export type ModelMeasurementRecordConnection = {
  __typename: "ModelMeasurementRecordConnection",
  items:  Array<MeasurementRecord | null >,
  nextToken?: string | null,
};

export type ModelHorseShowFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelHorseShowFilterInput | null > | null,
  or?: Array< ModelHorseShowFilterInput | null > | null,
  not?: ModelHorseShowFilterInput | null,
};

export type ModelHorseShowConnection = {
  __typename: "ModelHorseShowConnection",
  items:  Array<HorseShow | null >,
  nextToken?: string | null,
};

export type ModelHorseShowRatingFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  governingBody?: ModelStringInput | null,
  rating?: ModelStringInput | null,
  and?: Array< ModelHorseShowRatingFilterInput | null > | null,
  or?: Array< ModelHorseShowRatingFilterInput | null > | null,
  not?: ModelHorseShowRatingFilterInput | null,
};

export type ModelHorseShowRatingConnection = {
  __typename: "ModelHorseShowRatingConnection",
  items:  Array<HorseShowRating | null >,
  nextToken?: string | null,
};

export type ModelInvitationFilterInput = {
  id?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  creatorEmail?: ModelStringInput | null,
  invitee?: ModelIDInput | null,
  inviteeEmail?: ModelStringInput | null,
  status?: ModelInvitationStatusInput | null,
  organizationId?: ModelIDInput | null,
  barnId?: ModelIDInput | null,
  permissionLevel?: ModelStringInput | null,
  roles?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelInvitationFilterInput | null > | null,
  or?: Array< ModelInvitationFilterInput | null > | null,
  not?: ModelInvitationFilterInput | null,
};

export type ModelInvitationConnection = {
  __typename: "ModelInvitationConnection",
  items:  Array<Invitation | null >,
  nextToken?: string | null,
};

export type ModelMembershipFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  membershipId?: ModelStringInput | null,
  backNumber?: ModelIntInput | null,
  membershipStatus?: ModelStringInput | null,
  amountPaid?: ModelFloatInput | null,
  organizationMembershipTypeId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  personName?: ModelStringInput | null,
  personId?: ModelIDInput | null,
  riderId?: ModelIDInput | null,
  horseId?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  trainerId?: ModelIDInput | null,
  barnId?: ModelIDInput | null,
  teamId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  organizationName?: ModelStringInput | null,
  isJunior?: ModelBooleanInput | null,
  isProfessional?: ModelBooleanInput | null,
  dateMembershipEnds?: ModelStringInput | null,
  volunteerHours?: ModelFloatInput | null,
  meetingsAttended?: ModelFloatInput | null,
  showsAttended?: ModelFloatInput | null,
  personEmail?: ModelStringInput | null,
  gradeLevel?: ModelStringInput | null,
  aelDivision?: ModelStringInput | null,
  aelTeamRole?: ModelTeamRoleInput | null,
  contactPersonId?: ModelIDInput | null,
  groupContactId?: ModelIDInput | null,
  groupLeaderContactId?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelMembershipFilterInput | null > | null,
  or?: Array< ModelMembershipFilterInput | null > | null,
  not?: ModelMembershipFilterInput | null,
  personMembershipsId?: ModelIDInput | null,
  membershipOrganizationId?: ModelIDInput | null,
};

export type ModelOrganizationMembershipTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  order?: ModelIntInput | null,
  organizationId?: ModelIDInput | null,
  membershipIdValues?: ModelStringInput | null,
  nextAvailableMembershipId?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  category?: ModelMembershipTypeCategoryInput | null,
  schedule?: ModelMembershipTypeScheduleInput | null,
  isStartAtTimeOfPurchase?: ModelBooleanInput | null,
  setStartDate?: ModelStringInput | null,
  acceptOnlineApplications?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationMembershipTypeFilterInput | null > | null,
  or?: Array< ModelOrganizationMembershipTypeFilterInput | null > | null,
  not?: ModelOrganizationMembershipTypeFilterInput | null,
  organizationMembershipTypeOrganizationId?: ModelIDInput | null,
};

export type ModelOrganizationMembershipTypeConnection = {
  __typename: "ModelOrganizationMembershipTypeConnection",
  items:  Array<OrganizationMembershipType | null >,
  nextToken?: string | null,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelOrganizationTypeInput | null,
  industry?: ModelStringInput | null,
  addresses?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  stripeId?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  paymentMethodId?: ModelStringInput | null,
  website?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  urlBackHalf?: ModelStringInput | null,
  colors?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
};

export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
};

export type ModelOrganizationBeddingTypeFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  pricePerBag?: ModelFloatInput | null,
  taxA?: ModelFloatInput | null,
  taxB?: ModelFloatInput | null,
  taxRateIdA?: ModelIDInput | null,
  taxRateIdB?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationBeddingTypeFilterInput | null > | null,
  or?: Array< ModelOrganizationBeddingTypeFilterInput | null > | null,
  not?: ModelOrganizationBeddingTypeFilterInput | null,
};

export type ModelOrganizationBeddingTypeConnection = {
  __typename: "ModelOrganizationBeddingTypeConnection",
  items:  Array<OrganizationBeddingType | null >,
  nextToken?: string | null,
};

export type ModelOrganizationClassFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  classId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  organizationDivisionId?: ModelIDInput | null,
  hasChampionship?: ModelBooleanInput | null,
  type?: ModelStringInput | null,
  number?: ModelIntInput | null,
  entryFee?: ModelStringInput | null,
  prizeMoney?: ModelStringInput | null,
  minNumberEntries?: ModelIntInput | null,
  maxNumberEntries?: ModelIntInput | null,
  jumpHeight?: ModelStringInput | null,
  estimatedTimePerRound?: ModelIntInput | null,
  createdBy?: ModelIDInput | null,
  displayOrder?: ModelIntInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationClassFilterInput | null > | null,
  or?: Array< ModelOrganizationClassFilterInput | null > | null,
  not?: ModelOrganizationClassFilterInput | null,
  organizationClassPointTableId?: ModelIDInput | null,
  organizationClassPrizeMoneyTableId?: ModelIDInput | null,
};

export type ModelOrganizationClassConnection = {
  __typename: "ModelOrganizationClassConnection",
  items:  Array<OrganizationClass | null >,
  nextToken?: string | null,
};

export type ModelOrganizationDivisionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  number?: ModelIntInput | null,
  divisionId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  jumpHeight?: ModelStringInput | null,
  minNumberEntries?: ModelIntInput | null,
  maxNumberEntries?: ModelIntInput | null,
  entryFee?: ModelStringInput | null,
  prizeMoney?: ModelStringInput | null,
  entryFeePerClass?: ModelStringInput | null,
  prizeMoneyPerClass?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  displayOrder?: ModelIntInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationDivisionFilterInput | null > | null,
  or?: Array< ModelOrganizationDivisionFilterInput | null > | null,
  not?: ModelOrganizationDivisionFilterInput | null,
  organizationDivisionPointTableId?: ModelIDInput | null,
  organizationDivisionPrizeMoneyTableId?: ModelIDInput | null,
};

export type ModelOrganizationDivisionConnection = {
  __typename: "ModelOrganizationDivisionConnection",
  items:  Array<OrganizationDivision | null >,
  nextToken?: string | null,
};

export type ModelOrganizationStallSetFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  location?: ModelStringInput | null,
  available?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationStallSetFilterInput | null > | null,
  or?: Array< ModelOrganizationStallSetFilterInput | null > | null,
  not?: ModelOrganizationStallSetFilterInput | null,
};

export type ModelOrganizationStallSetConnection = {
  __typename: "ModelOrganizationStallSetConnection",
  items:  Array<OrganizationStallSet | null >,
  nextToken?: string | null,
};

export type ModelOrganizationStallTypeFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  pricePerStall?: ModelFloatInput | null,
  taxA?: ModelFloatInput | null,
  taxB?: ModelFloatInput | null,
  taxRateIdA?: ModelIDInput | null,
  taxRateIdB?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOrganizationStallTypeFilterInput | null > | null,
  or?: Array< ModelOrganizationStallTypeFilterInput | null > | null,
  not?: ModelOrganizationStallTypeFilterInput | null,
};

export type ModelOrganizationStallTypeConnection = {
  __typename: "ModelOrganizationStallTypeConnection",
  items:  Array<OrganizationStallType | null >,
  nextToken?: string | null,
};

export type ModelOwnerFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  location?: ModelStringInput | null,
  addressId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  barnId?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelOwnerFilterInput | null > | null,
  or?: Array< ModelOwnerFilterInput | null > | null,
  not?: ModelOwnerFilterInput | null,
  ownerContactId?: ModelIDInput | null,
};

export type ModelOwnerConnection = {
  __typename: "ModelOwnerConnection",
  items:  Array<Owner | null >,
  nextToken?: string | null,
};

export type ModelPayerFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  personId?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPayerFilterInput | null > | null,
  or?: Array< ModelPayerFilterInput | null > | null,
  not?: ModelPayerFilterInput | null,
  payerContactId?: ModelIDInput | null,
  payerPaymentMethodId?: ModelIDInput | null,
};

export type ModelPayerConnection = {
  __typename: "ModelPayerConnection",
  items:  Array<Payer | null >,
  nextToken?: string | null,
};

export type ModelPaymentMethodFilterInput = {
  id?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  type?: ModelPaymentMethodTypeInput | null,
  stripeId?: ModelStringInput | null,
  altId?: ModelStringInput | null,
  value?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPaymentMethodFilterInput | null > | null,
  or?: Array< ModelPaymentMethodFilterInput | null > | null,
  not?: ModelPaymentMethodFilterInput | null,
};

export type ModelPaymentMethodConnection = {
  __typename: "ModelPaymentMethodConnection",
  items:  Array<PaymentMethod | null >,
  nextToken?: string | null,
};

export type ModelPersonFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  isVerified?: ModelBooleanInput | null,
  roles?: ModelStringInput | null,
  personalInformationId?: ModelIDInput | null,
  pictureURL?: ModelStringInput | null,
  disciplines?: ModelStringInput | null,
  stripeId?: ModelStringInput | null,
  createdBy?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPersonFilterInput | null > | null,
  or?: Array< ModelPersonFilterInput | null > | null,
  not?: ModelPersonFilterInput | null,
};

export type ModelPersonConnection = {
  __typename: "ModelPersonConnection",
  items:  Array<Person | null >,
  nextToken?: string | null,
};

export type ModelPersonNameFilterInput = {
  id?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  title?: ModelTitleInput | null,
  firstName?: ModelStringInput | null,
  middleName?: ModelStringInput | null,
  maidenName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  suffix?: ModelStringInput | null,
  legalName?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  and?: Array< ModelPersonNameFilterInput | null > | null,
  or?: Array< ModelPersonNameFilterInput | null > | null,
  not?: ModelPersonNameFilterInput | null,
};

export type ModelPersonNameConnection = {
  __typename: "ModelPersonNameConnection",
  items:  Array<PersonName | null >,
  nextToken?: string | null,
};

export type ModelPersonalInformationFilterInput = {
  id?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  dateOfBirth?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  contactId?: ModelIDInput | null,
  addressId?: ModelIDInput | null,
  and?: Array< ModelPersonalInformationFilterInput | null > | null,
  or?: Array< ModelPersonalInformationFilterInput | null > | null,
  not?: ModelPersonalInformationFilterInput | null,
};

export type ModelPersonalInformationConnection = {
  __typename: "ModelPersonalInformationConnection",
  items:  Array<PersonalInformation | null >,
  nextToken?: string | null,
};

export type ModelPointTableFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  organizationId?: ModelIDInput | null,
  discipline?: ModelStringInput | null,
  isAddValueToRegularFromEntryCount?: ModelBooleanInput | null,
  isAddValueToChampionshipFromEntryCount?: ModelBooleanInput | null,
  isUseChampionRule?: ModelBooleanInput | null,
  isUseReserveRule?: ModelBooleanInput | null,
  numberEntriesToReceiveSpecialClassPoints?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPointTableFilterInput | null > | null,
  or?: Array< ModelPointTableFilterInput | null > | null,
  not?: ModelPointTableFilterInput | null,
  eventResultPointTablesId?: ModelIDInput | null,
};

export type ModelPollFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelPollFilterInput | null > | null,
  or?: Array< ModelPollFilterInput | null > | null,
  not?: ModelPollFilterInput | null,
};

export type ModelPollConnection = {
  __typename: "ModelPollConnection",
  items:  Array<Poll | null >,
  nextToken?: string | null,
};

export type ModelPostFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  status?: ModelPostStatusInput | null,
  rating?: ModelIntInput | null,
  content?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPostFilterInput | null > | null,
  or?: Array< ModelPostFilterInput | null > | null,
  not?: ModelPostFilterInput | null,
};

export type ModelPostConnection = {
  __typename: "ModelPostConnection",
  items:  Array<Post | null >,
  nextToken?: string | null,
};

export type ModelPrizeMoneyTableFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  organizationId?: ModelIDInput | null,
  discipline?: ModelStringInput | null,
  isPercentage?: ModelBooleanInput | null,
  totalPot?: ModelFloatInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPrizeMoneyTableFilterInput | null > | null,
  or?: Array< ModelPrizeMoneyTableFilterInput | null > | null,
  not?: ModelPrizeMoneyTableFilterInput | null,
};

export type ModelPrizeMoneyTableConnection = {
  __typename: "ModelPrizeMoneyTableConnection",
  items:  Array<PrizeMoneyTable | null >,
  nextToken?: string | null,
};

export type ModelPromoCodeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  stripeId?: ModelStringInput | null,
  isvalid?: ModelBooleanInput | null,
  isfundraiser?: ModelBooleanInput | null,
  ispercentdiscount?: ModelBooleanInput | null,
  isfixeddiscount?: ModelBooleanInput | null,
  percent?: ModelFloatInput | null,
  fixed?: ModelFloatInput | null,
  minamount?: ModelFloatInput | null,
  startdate?: ModelStringInput | null,
  enddate?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelPromoCodeFilterInput | null > | null,
  or?: Array< ModelPromoCodeFilterInput | null > | null,
  not?: ModelPromoCodeFilterInput | null,
};

export type ModelPromoCodeConnection = {
  __typename: "ModelPromoCodeConnection",
  items:  Array<PromoCode | null >,
  nextToken?: string | null,
};

export type ModelResponseFilterInput = {
  id?: ModelIDInput | null,
  pollId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  value?: ModelStringInput | null,
  and?: Array< ModelResponseFilterInput | null > | null,
  or?: Array< ModelResponseFilterInput | null > | null,
  not?: ModelResponseFilterInput | null,
};

export type ModelRiderFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  location?: ModelStringInput | null,
  addressId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  age?: ModelIntInput | null,
  birthdate?: ModelStringInput | null,
  isProfessional?: ModelBooleanInput | null,
  barnName?: ModelStringInput | null,
  barnId?: ModelIDInput | null,
  createdBy?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelRiderFilterInput | null > | null,
  or?: Array< ModelRiderFilterInput | null > | null,
  not?: ModelRiderFilterInput | null,
  riderContactId?: ModelIDInput | null,
};

export type ModelRiderConnection = {
  __typename: "ModelRiderConnection",
  items:  Array<Rider | null >,
  nextToken?: string | null,
};

export type ModelRingFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  organizationId?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelRingFilterInput | null > | null,
  or?: Array< ModelRingFilterInput | null > | null,
  not?: ModelRingFilterInput | null,
};

export type ModelRingConnection = {
  __typename: "ModelRingConnection",
  items:  Array<Ring | null >,
  nextToken?: string | null,
};

export type ModelScheduleItemFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  eventRingId?: ModelIDInput | null,
  eventDayId?: ModelIDInput | null,
  displayOrder?: ModelIntInput | null,
  classId?: ModelIDInput | null,
  breakId?: ModelIDInput | null,
  startTime?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelScheduleItemFilterInput | null > | null,
  or?: Array< ModelScheduleItemFilterInput | null > | null,
  not?: ModelScheduleItemFilterInput | null,
};

export type ModelScheduleItemConnection = {
  __typename: "ModelScheduleItemConnection",
  items:  Array<ScheduleItem | null >,
  nextToken?: string | null,
};

export type ModelSeasonFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  isActive?: ModelBooleanInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  disciplines?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelSeasonFilterInput | null > | null,
  or?: Array< ModelSeasonFilterInput | null > | null,
  not?: ModelSeasonFilterInput | null,
  seasonOrganizationId?: ModelIDInput | null,
};

export type ModelSeasonConnection = {
  __typename: "ModelSeasonConnection",
  items:  Array<Season | null >,
  nextToken?: string | null,
};

export type ModelStablingRequestFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  trainerId?: ModelIDInput | null,
  barnId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  arrivalDateTime?: ModelStringInput | null,
  departureDateTime?: ModelStringInput | null,
  stallTypeId?: ModelIDInput | null,
  quantityNeeded?: ModelFloatInput | null,
  basePrice?: ModelFloatInput | null,
  taxA?: ModelFloatInput | null,
  taxB?: ModelFloatInput | null,
  totalPrice?: ModelFloatInput | null,
  status?: ModelStringInput | null,
  statusNote?: ModelStringInput | null,
  requestNote?: ModelStringInput | null,
  stallSetId?: ModelIDInput | null,
  stallLocation?: ModelStringInput | null,
  stallNumbers?: ModelStringInput | null,
  payerId?: ModelIDInput | null,
  payerName?: ModelStringInput | null,
  splitAcrossEntries?: ModelBooleanInput | null,
  entryIds?: ModelStringInput | null,
  submittedEntry?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelStablingRequestFilterInput | null > | null,
  or?: Array< ModelStablingRequestFilterInput | null > | null,
  not?: ModelStablingRequestFilterInput | null,
};

export type ModelStablingRequestConnection = {
  __typename: "ModelStablingRequestConnection",
  items:  Array<StablingRequest | null >,
  nextToken?: string | null,
};

export type ModelBeddingRequestFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  trainerId?: ModelIDInput | null,
  barnId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  arrivalDateTime?: ModelStringInput | null,
  dateTimeNeeded?: ModelStringInput | null,
  deliveryInfo?: ModelStringInput | null,
  beddingType?: ModelStringInput | null,
  quantityNeeded?: ModelFloatInput | null,
  basePrice?: ModelFloatInput | null,
  totalPrice?: ModelFloatInput | null,
  status?: ModelStringInput | null,
  statusNote?: ModelStringInput | null,
  payerId?: ModelIDInput | null,
  payerName?: ModelStringInput | null,
  splitAcrossEntries?: ModelBooleanInput | null,
  entryIds?: ModelStringInput | null,
  submittedEntry?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelBeddingRequestFilterInput | null > | null,
  or?: Array< ModelBeddingRequestFilterInput | null > | null,
  not?: ModelBeddingRequestFilterInput | null,
  beddingRequestTaxAId?: ModelIDInput | null,
  beddingRequestTaxBId?: ModelIDInput | null,
};

export type ModelBeddingRequestConnection = {
  __typename: "ModelBeddingRequestConnection",
  items:  Array<BeddingRequest | null >,
  nextToken?: string | null,
};

export type ModelSubmissionFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  videoLength?: ModelStringInput | null,
  riderId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  totalPrice?: ModelFloatInput | null,
  status?: ModelSubmissionStatusInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelSubmissionFilterInput | null > | null,
  or?: Array< ModelSubmissionFilterInput | null > | null,
  not?: ModelSubmissionFilterInput | null,
};

export type ModelSubmissionConnection = {
  __typename: "ModelSubmissionConnection",
  items:  Array<Submission | null >,
  nextToken?: string | null,
};

export type ModelSubmissionFeedbackFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  submissionId?: ModelIDInput | null,
  feedbackId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  riderId?: ModelIDInput | null,
  clinicianId?: ModelIDInput | null,
  submissionStatus?: ModelSubmissionStatusInput | null,
  feedbackStatus?: ModelFeedbackStatusInput | null,
  clinicianAmount?: ModelFloatInput | null,
  declineReason?: ModelStringInput | null,
  permissionToPost?: ModelBooleanInput | null,
  dateSubmitted?: ModelStringInput | null,
  dateCompleted?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelSubmissionFeedbackFilterInput | null > | null,
  or?: Array< ModelSubmissionFeedbackFilterInput | null > | null,
  not?: ModelSubmissionFeedbackFilterInput | null,
  submissionFeedbackClinicianId?: ModelIDInput | null,
};

export type ModelTaxRateFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  stripeTaxRateId?: ModelStringInput | null,
  percentage?: ModelFloatInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelTaxRateFilterInput | null > | null,
  or?: Array< ModelTaxRateFilterInput | null > | null,
  not?: ModelTaxRateFilterInput | null,
};

export type ModelTaxRateConnection = {
  __typename: "ModelTaxRateConnection",
  items:  Array<TaxRate | null >,
  nextToken?: string | null,
};

export type ModelTeamFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  description?: ModelStringInput | null,
  creatorId?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelTeamFilterInput | null > | null,
  or?: Array< ModelTeamFilterInput | null > | null,
  not?: ModelTeamFilterInput | null,
  teamContactId?: ModelIDInput | null,
  teamLocationId?: ModelIDInput | null,
  teamCreatorId?: ModelIDInput | null,
};

export type ModelTeamConnection = {
  __typename: "ModelTeamConnection",
  items:  Array<Team | null >,
  nextToken?: string | null,
};

export type ModelTeamMemberFilterInput = {
  id?: ModelIDInput | null,
  teamId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  status?: ModelTeamStatusInput | null,
  role?: ModelTeamRoleInput | null,
  permissionLevel?: ModelTeamPermissionLevelInput | null,
  creatorId?: ModelIDInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelTeamMemberFilterInput | null > | null,
  or?: Array< ModelTeamMemberFilterInput | null > | null,
  not?: ModelTeamMemberFilterInput | null,
  teamMemberCreatorId?: ModelIDInput | null,
};

export type ModelTextFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelStringInput | null,
  blockId?: ModelIDInput | null,
  alertId?: ModelIDInput | null,
  auditorId?: ModelIDInput | null,
  status?: ModelTextStatusInput | null,
  statusNote?: ModelStringInput | null,
  errorCode?: ModelStringInput | null,
  twilioSID?: ModelStringInput | null,
  twilioMessagingServiceSID?: ModelStringInput | null,
  twilioNote?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  timeSent?: ModelStringInput | null,
  timeDelivered?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelTextFilterInput | null > | null,
  or?: Array< ModelTextFilterInput | null > | null,
  not?: ModelTextFilterInput | null,
};

export type ModelTextConnection = {
  __typename: "ModelTextConnection",
  items:  Array<Text | null >,
  nextToken?: string | null,
};

export type ModelTrainerFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  location?: ModelStringInput | null,
  addressId?: ModelIDInput | null,
  personId?: ModelIDInput | null,
  barnName?: ModelStringInput | null,
  barnId?: ModelIDInput | null,
  createdBy?: ModelStringInput | null,
  createdOn?: ModelStringInput | null,
  updatedOn?: ModelStringInput | null,
  and?: Array< ModelTrainerFilterInput | null > | null,
  or?: Array< ModelTrainerFilterInput | null > | null,
  not?: ModelTrainerFilterInput | null,
  trainerContactId?: ModelIDInput | null,
};

export type ModelTrainerConnection = {
  __typename: "ModelTrainerConnection",
  items:  Array<Trainer | null >,
  nextToken?: string | null,
};

export type ModelOrganizationMembershipTypeSeasonsFilterInput = {
  id?: ModelIDInput | null,
  organizationMembershipTypeID?: ModelIDInput | null,
  seasonID?: ModelIDInput | null,
  and?: Array< ModelOrganizationMembershipTypeSeasonsFilterInput | null > | null,
  or?: Array< ModelOrganizationMembershipTypeSeasonsFilterInput | null > | null,
  not?: ModelOrganizationMembershipTypeSeasonsFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelSubmissionFeedbackPaymentsByClinicianByDateCompositeKeyConditionInput = {
  eq?: ModelSubmissionFeedbackPaymentsByClinicianByDateCompositeKeyInput | null,
  le?: ModelSubmissionFeedbackPaymentsByClinicianByDateCompositeKeyInput | null,
  lt?: ModelSubmissionFeedbackPaymentsByClinicianByDateCompositeKeyInput | null,
  ge?: ModelSubmissionFeedbackPaymentsByClinicianByDateCompositeKeyInput | null,
  gt?: ModelSubmissionFeedbackPaymentsByClinicianByDateCompositeKeyInput | null,
  between?: Array< ModelSubmissionFeedbackPaymentsByClinicianByDateCompositeKeyInput | null > | null,
  beginsWith?: ModelSubmissionFeedbackPaymentsByClinicianByDateCompositeKeyInput | null,
};

export type ModelSubmissionFeedbackPaymentsByClinicianByDateCompositeKeyInput = {
  clinicianId?: string | null,
  dateCompleted?: string | null,
};

export type CreateAddressMutationVariables = {
  input: CreateAddressInput,
  condition?: ModelAddressConditionInput | null,
};

export type CreateAddressMutation = {
  createAddress?:  {
    __typename: "Address",
    id: string,
    name?: string | null,
    type?: string | null,
    isDefault?: boolean | null,
    streetAddress1?: string | null,
    streetAddress2?: string | null,
    city?: string | null,
    provState?: string | null,
    zip?: string | null,
    country?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAddressMutationVariables = {
  input: UpdateAddressInput,
  condition?: ModelAddressConditionInput | null,
};

export type UpdateAddressMutation = {
  updateAddress?:  {
    __typename: "Address",
    id: string,
    name?: string | null,
    type?: string | null,
    isDefault?: boolean | null,
    streetAddress1?: string | null,
    streetAddress2?: string | null,
    city?: string | null,
    provState?: string | null,
    zip?: string | null,
    country?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAddressMutationVariables = {
  input: DeleteAddressInput,
  condition?: ModelAddressConditionInput | null,
};

export type DeleteAddressMutation = {
  deleteAddress?:  {
    __typename: "Address",
    id: string,
    name?: string | null,
    type?: string | null,
    isDefault?: boolean | null,
    streetAddress1?: string | null,
    streetAddress2?: string | null,
    city?: string | null,
    provState?: string | null,
    zip?: string | null,
    country?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAlertMutationVariables = {
  input: CreateAlertInput,
  condition?: ModelAlertConditionInput | null,
};

export type CreateAlertMutation = {
  createAlert?:  {
    __typename: "Alert",
    id: string,
    type?: string | null,
    title?: string | null,
    body?: string | null,
    imageKey?: string | null,
    category?: string | null,
    organizationId?: string | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    messageSegments?: number | null,
    subscriberCount?: number | null,
    totalCost?: number | null,
    blockIdsUsed?: Array< string | null > | null,
    fromUnlimitedEvent?: boolean | null,
    isIncoming?: string | null,
    sentFromPhoneNumber?: string | null,
    recipientPhoneNumbers?: Array< string | null > | null,
    successSIDList?: Array< string | null > | null,
    errorSIDList?: Array< string | null > | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    alertEventId?: string | null,
  } | null,
};

export type UpdateAlertMutationVariables = {
  input: UpdateAlertInput,
  condition?: ModelAlertConditionInput | null,
};

export type UpdateAlertMutation = {
  updateAlert?:  {
    __typename: "Alert",
    id: string,
    type?: string | null,
    title?: string | null,
    body?: string | null,
    imageKey?: string | null,
    category?: string | null,
    organizationId?: string | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    messageSegments?: number | null,
    subscriberCount?: number | null,
    totalCost?: number | null,
    blockIdsUsed?: Array< string | null > | null,
    fromUnlimitedEvent?: boolean | null,
    isIncoming?: string | null,
    sentFromPhoneNumber?: string | null,
    recipientPhoneNumbers?: Array< string | null > | null,
    successSIDList?: Array< string | null > | null,
    errorSIDList?: Array< string | null > | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    alertEventId?: string | null,
  } | null,
};

export type DeleteAlertMutationVariables = {
  input: DeleteAlertInput,
  condition?: ModelAlertConditionInput | null,
};

export type DeleteAlertMutation = {
  deleteAlert?:  {
    __typename: "Alert",
    id: string,
    type?: string | null,
    title?: string | null,
    body?: string | null,
    imageKey?: string | null,
    category?: string | null,
    organizationId?: string | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    messageSegments?: number | null,
    subscriberCount?: number | null,
    totalCost?: number | null,
    blockIdsUsed?: Array< string | null > | null,
    fromUnlimitedEvent?: boolean | null,
    isIncoming?: string | null,
    sentFromPhoneNumber?: string | null,
    recipientPhoneNumbers?: Array< string | null > | null,
    successSIDList?: Array< string | null > | null,
    errorSIDList?: Array< string | null > | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    alertEventId?: string | null,
  } | null,
};

export type CreateAuditorMutationVariables = {
  input: CreateAuditorInput,
  condition?: ModelAuditorConditionInput | null,
};

export type CreateAuditorMutation = {
  createAuditor?:  {
    __typename: "Auditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    email?: string | null,
    status?: AuditorStatus | null,
    statusNote?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateAuditorMutationVariables = {
  input: UpdateAuditorInput,
  condition?: ModelAuditorConditionInput | null,
};

export type UpdateAuditorMutation = {
  updateAuditor?:  {
    __typename: "Auditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    email?: string | null,
    status?: AuditorStatus | null,
    statusNote?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteAuditorMutationVariables = {
  input: DeleteAuditorInput,
  condition?: ModelAuditorConditionInput | null,
};

export type DeleteAuditorMutation = {
  deleteAuditor?:  {
    __typename: "Auditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    email?: string | null,
    status?: AuditorStatus | null,
    statusNote?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateBacklogEventResultMutationVariables = {
  input: CreateBacklogEventResultInput,
  condition?: ModelBacklogEventResultConditionInput | null,
};

export type CreateBacklogEventResultMutation = {
  createBacklogEventResult?:  {
    __typename: "BacklogEventResult",
    id: string,
    organizationId?: string | null,
    organizationMemberId?: string | null,
    organizationResultId?: string | null,
    eventId?: string | null,
    eventName?: string | null,
    eventStartDate?: string | null,
    eventEndDate?: string | null,
    eventDivisionId?: string | null,
    eventDivisionName?: string | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventClassId?: string | null,
    eventClassName?: string | null,
    eventClassNumber?: string | null,
    organizationClassId?: string | null,
    organizationClass?:  {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null,
    riderName?: string | null,
    horseName?: string | null,
    place?: number | null,
    pointsEarned?: number | null,
    score?: number | null,
    time?: string | null,
    didNotCompete?: boolean | null,
    prizeMoney?: number | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    backlogEventResultPrizeMoneyTableId?: string | null,
  } | null,
};

export type UpdateBacklogEventResultMutationVariables = {
  input: UpdateBacklogEventResultInput,
  condition?: ModelBacklogEventResultConditionInput | null,
};

export type UpdateBacklogEventResultMutation = {
  updateBacklogEventResult?:  {
    __typename: "BacklogEventResult",
    id: string,
    organizationId?: string | null,
    organizationMemberId?: string | null,
    organizationResultId?: string | null,
    eventId?: string | null,
    eventName?: string | null,
    eventStartDate?: string | null,
    eventEndDate?: string | null,
    eventDivisionId?: string | null,
    eventDivisionName?: string | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventClassId?: string | null,
    eventClassName?: string | null,
    eventClassNumber?: string | null,
    organizationClassId?: string | null,
    organizationClass?:  {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null,
    riderName?: string | null,
    horseName?: string | null,
    place?: number | null,
    pointsEarned?: number | null,
    score?: number | null,
    time?: string | null,
    didNotCompete?: boolean | null,
    prizeMoney?: number | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    backlogEventResultPrizeMoneyTableId?: string | null,
  } | null,
};

export type DeleteBacklogEventResultMutationVariables = {
  input: DeleteBacklogEventResultInput,
  condition?: ModelBacklogEventResultConditionInput | null,
};

export type DeleteBacklogEventResultMutation = {
  deleteBacklogEventResult?:  {
    __typename: "BacklogEventResult",
    id: string,
    organizationId?: string | null,
    organizationMemberId?: string | null,
    organizationResultId?: string | null,
    eventId?: string | null,
    eventName?: string | null,
    eventStartDate?: string | null,
    eventEndDate?: string | null,
    eventDivisionId?: string | null,
    eventDivisionName?: string | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventClassId?: string | null,
    eventClassName?: string | null,
    eventClassNumber?: string | null,
    organizationClassId?: string | null,
    organizationClass?:  {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null,
    riderName?: string | null,
    horseName?: string | null,
    place?: number | null,
    pointsEarned?: number | null,
    score?: number | null,
    time?: string | null,
    didNotCompete?: boolean | null,
    prizeMoney?: number | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    backlogEventResultPrizeMoneyTableId?: string | null,
  } | null,
};

export type CreateBarnMutationVariables = {
  input: CreateBarnInput,
  condition?: ModelBarnConditionInput | null,
};

export type CreateBarnMutation = {
  createBarn?:  {
    __typename: "Barn",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    type?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ownerName?: string | null,
    ownerPersonId?: string | null,
    secondaryOwnerName?: string | null,
    secondaryOwnerPersonId?: string | null,
    members?:  {
      __typename: "ModelBarnMemberConnection",
      items:  Array< {
        __typename: "BarnMember",
        id: string,
        name: string,
        barnId: string,
        personId: string,
        permissionLevel?: string | null,
        status?: string | null,
        roles?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    barnContactId?: string | null,
    barnLocationId?: string | null,
  } | null,
};

export type UpdateBarnMutationVariables = {
  input: UpdateBarnInput,
  condition?: ModelBarnConditionInput | null,
};

export type UpdateBarnMutation = {
  updateBarn?:  {
    __typename: "Barn",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    type?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ownerName?: string | null,
    ownerPersonId?: string | null,
    secondaryOwnerName?: string | null,
    secondaryOwnerPersonId?: string | null,
    members?:  {
      __typename: "ModelBarnMemberConnection",
      items:  Array< {
        __typename: "BarnMember",
        id: string,
        name: string,
        barnId: string,
        personId: string,
        permissionLevel?: string | null,
        status?: string | null,
        roles?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    barnContactId?: string | null,
    barnLocationId?: string | null,
  } | null,
};

export type DeleteBarnMutationVariables = {
  input: DeleteBarnInput,
  condition?: ModelBarnConditionInput | null,
};

export type DeleteBarnMutation = {
  deleteBarn?:  {
    __typename: "Barn",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    type?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ownerName?: string | null,
    ownerPersonId?: string | null,
    secondaryOwnerName?: string | null,
    secondaryOwnerPersonId?: string | null,
    members?:  {
      __typename: "ModelBarnMemberConnection",
      items:  Array< {
        __typename: "BarnMember",
        id: string,
        name: string,
        barnId: string,
        personId: string,
        permissionLevel?: string | null,
        status?: string | null,
        roles?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    barnContactId?: string | null,
    barnLocationId?: string | null,
  } | null,
};

export type CreateBarnMemberMutationVariables = {
  input: CreateBarnMemberInput,
  condition?: ModelBarnMemberConditionInput | null,
};

export type CreateBarnMemberMutation = {
  createBarnMember?:  {
    __typename: "BarnMember",
    id: string,
    name: string,
    barnId: string,
    barn:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    },
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    permissionLevel?: string | null,
    status?: string | null,
    roles?: string | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateBarnMemberMutationVariables = {
  input: UpdateBarnMemberInput,
  condition?: ModelBarnMemberConditionInput | null,
};

export type UpdateBarnMemberMutation = {
  updateBarnMember?:  {
    __typename: "BarnMember",
    id: string,
    name: string,
    barnId: string,
    barn:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    },
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    permissionLevel?: string | null,
    status?: string | null,
    roles?: string | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteBarnMemberMutationVariables = {
  input: DeleteBarnMemberInput,
  condition?: ModelBarnMemberConditionInput | null,
};

export type DeleteBarnMemberMutation = {
  deleteBarnMember?:  {
    __typename: "BarnMember",
    id: string,
    name: string,
    barnId: string,
    barn:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    },
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    permissionLevel?: string | null,
    status?: string | null,
    roles?: string | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateBlockMutationVariables = {
  input: CreateBlockInput,
  condition?: ModelBlockConditionInput | null,
};

export type CreateBlockMutation = {
  createBlock?:  {
    __typename: "Block",
    id: string,
    name?: string | null,
    type?: string | null,
    personid?: string | null,
    organizationId?: string | null,
    purchasedOn?: string | null,
    expiresOn?: string | null,
    totalCredits?: number | null,
    usedCredits?: number | null,
    status?: BlockStatus | null,
    price?: number | null,
    amountPaid?: number | null,
    isPromotion?: string | null,
    isFree?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateBlockMutationVariables = {
  input: UpdateBlockInput,
  condition?: ModelBlockConditionInput | null,
};

export type UpdateBlockMutation = {
  updateBlock?:  {
    __typename: "Block",
    id: string,
    name?: string | null,
    type?: string | null,
    personid?: string | null,
    organizationId?: string | null,
    purchasedOn?: string | null,
    expiresOn?: string | null,
    totalCredits?: number | null,
    usedCredits?: number | null,
    status?: BlockStatus | null,
    price?: number | null,
    amountPaid?: number | null,
    isPromotion?: string | null,
    isFree?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteBlockMutationVariables = {
  input: DeleteBlockInput,
  condition?: ModelBlockConditionInput | null,
};

export type DeleteBlockMutation = {
  deleteBlock?:  {
    __typename: "Block",
    id: string,
    name?: string | null,
    type?: string | null,
    personid?: string | null,
    organizationId?: string | null,
    purchasedOn?: string | null,
    expiresOn?: string | null,
    totalCredits?: number | null,
    usedCredits?: number | null,
    status?: BlockStatus | null,
    price?: number | null,
    amountPaid?: number | null,
    isPromotion?: string | null,
    isFree?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateClassMutationVariables = {
  input: CreateClassInput,
  condition?: ModelClassConditionInput | null,
};

export type CreateClassMutation = {
  createClass?:  {
    __typename: "Class",
    id: string,
    name: string,
    nickname?: string | null,
    divisionId?: string | null,
    type?: string | null,
    description?: string | null,
    discipline?: string | null,
    ushjaName?: string | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    ecClassTypeNumber?: string | null,
    ecClassType?: string | null,
    code?: string | null,
    specs?: string | null,
    judgedOn?: string | null,
    rules?:  {
      __typename: "DivisionRules",
      horseSex?: string | null,
      horseBreed?: string | null,
      horseMinHeight?: string | null,
      horseMaxHeight?: string | null,
      horseMinAge?: string | null,
      horseMaxAge?: string | null,
      riderMinAge?: string | null,
      riderMaxAge?: string | null,
      riderProfessionalStatus?: string | null,
      riderRecord?: string | null,
      horseRecord?: string | null,
      crossEntry?: string | null,
      other?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceClassesId?: string | null,
  } | null,
};

export type UpdateClassMutationVariables = {
  input: UpdateClassInput,
  condition?: ModelClassConditionInput | null,
};

export type UpdateClassMutation = {
  updateClass?:  {
    __typename: "Class",
    id: string,
    name: string,
    nickname?: string | null,
    divisionId?: string | null,
    type?: string | null,
    description?: string | null,
    discipline?: string | null,
    ushjaName?: string | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    ecClassTypeNumber?: string | null,
    ecClassType?: string | null,
    code?: string | null,
    specs?: string | null,
    judgedOn?: string | null,
    rules?:  {
      __typename: "DivisionRules",
      horseSex?: string | null,
      horseBreed?: string | null,
      horseMinHeight?: string | null,
      horseMaxHeight?: string | null,
      horseMinAge?: string | null,
      horseMaxAge?: string | null,
      riderMinAge?: string | null,
      riderMaxAge?: string | null,
      riderProfessionalStatus?: string | null,
      riderRecord?: string | null,
      horseRecord?: string | null,
      crossEntry?: string | null,
      other?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceClassesId?: string | null,
  } | null,
};

export type DeleteClassMutationVariables = {
  input: DeleteClassInput,
  condition?: ModelClassConditionInput | null,
};

export type DeleteClassMutation = {
  deleteClass?:  {
    __typename: "Class",
    id: string,
    name: string,
    nickname?: string | null,
    divisionId?: string | null,
    type?: string | null,
    description?: string | null,
    discipline?: string | null,
    ushjaName?: string | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    ecClassTypeNumber?: string | null,
    ecClassType?: string | null,
    code?: string | null,
    specs?: string | null,
    judgedOn?: string | null,
    rules?:  {
      __typename: "DivisionRules",
      horseSex?: string | null,
      horseBreed?: string | null,
      horseMinHeight?: string | null,
      horseMaxHeight?: string | null,
      horseMinAge?: string | null,
      horseMaxAge?: string | null,
      riderMinAge?: string | null,
      riderMaxAge?: string | null,
      riderProfessionalStatus?: string | null,
      riderRecord?: string | null,
      horseRecord?: string | null,
      crossEntry?: string | null,
      other?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceClassesId?: string | null,
  } | null,
};

export type CreateClassOrderMutationVariables = {
  input: CreateClassOrderInput,
  condition?: ModelClassOrderConditionInput | null,
};

export type CreateClassOrderMutation = {
  createClassOrder?:  {
    __typename: "ClassOrder",
    id: string,
    eventId: string,
    eventClassId: string,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    entryId: string,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    order?: number | null,
    isRandom?: boolean | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateClassOrderMutationVariables = {
  input: UpdateClassOrderInput,
  condition?: ModelClassOrderConditionInput | null,
};

export type UpdateClassOrderMutation = {
  updateClassOrder?:  {
    __typename: "ClassOrder",
    id: string,
    eventId: string,
    eventClassId: string,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    entryId: string,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    order?: number | null,
    isRandom?: boolean | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteClassOrderMutationVariables = {
  input: DeleteClassOrderInput,
  condition?: ModelClassOrderConditionInput | null,
};

export type DeleteClassOrderMutation = {
  deleteClassOrder?:  {
    __typename: "ClassOrder",
    id: string,
    eventId: string,
    eventClassId: string,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    entryId: string,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    order?: number | null,
    isRandom?: boolean | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateClinicianMutationVariables = {
  input: CreateClinicianInput,
  condition?: ModelClinicianConditionInput | null,
};

export type CreateClinicianMutation = {
  createClinician?:  {
    __typename: "Clinician",
    id: string,
    name: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    judgeId?: string | null,
    contactId?: string | null,
    rating?:  Array< {
      __typename: "JudgeRating",
      type?: JudgeType | null,
      license?: JudgeLicense | null,
    } | null > | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    price?: number | null,
    status?: ClinicianStatus | null,
    paymentChoice?:  {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    paymentVerified?: boolean | null,
    stripeAccountId?: string | null,
    totalEarned?: number | null,
    location?: string | null,
    disciplines?: string | null,
    experience?: string | null,
    personal?: string | null,
    preferences?: string | null,
    hasIHSAExperience?: boolean | null,
    hasIEAExperience?: boolean | null,
    createdOn: string,
    updatedOn: string,
    clinicianPaymentChoiceId?: string | null,
  } | null,
};

export type UpdateClinicianMutationVariables = {
  input: UpdateClinicianInput,
  condition?: ModelClinicianConditionInput | null,
};

export type UpdateClinicianMutation = {
  updateClinician?:  {
    __typename: "Clinician",
    id: string,
    name: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    judgeId?: string | null,
    contactId?: string | null,
    rating?:  Array< {
      __typename: "JudgeRating",
      type?: JudgeType | null,
      license?: JudgeLicense | null,
    } | null > | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    price?: number | null,
    status?: ClinicianStatus | null,
    paymentChoice?:  {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    paymentVerified?: boolean | null,
    stripeAccountId?: string | null,
    totalEarned?: number | null,
    location?: string | null,
    disciplines?: string | null,
    experience?: string | null,
    personal?: string | null,
    preferences?: string | null,
    hasIHSAExperience?: boolean | null,
    hasIEAExperience?: boolean | null,
    createdOn: string,
    updatedOn: string,
    clinicianPaymentChoiceId?: string | null,
  } | null,
};

export type DeleteClinicianMutationVariables = {
  input: DeleteClinicianInput,
  condition?: ModelClinicianConditionInput | null,
};

export type DeleteClinicianMutation = {
  deleteClinician?:  {
    __typename: "Clinician",
    id: string,
    name: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    judgeId?: string | null,
    contactId?: string | null,
    rating?:  Array< {
      __typename: "JudgeRating",
      type?: JudgeType | null,
      license?: JudgeLicense | null,
    } | null > | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    price?: number | null,
    status?: ClinicianStatus | null,
    paymentChoice?:  {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    paymentVerified?: boolean | null,
    stripeAccountId?: string | null,
    totalEarned?: number | null,
    location?: string | null,
    disciplines?: string | null,
    experience?: string | null,
    personal?: string | null,
    preferences?: string | null,
    hasIHSAExperience?: boolean | null,
    hasIEAExperience?: boolean | null,
    createdOn: string,
    updatedOn: string,
    clinicianPaymentChoiceId?: string | null,
  } | null,
};

export type CreateCompetitionMutationVariables = {
  input: CreateCompetitionInput,
  condition?: ModelCompetitionConditionInput | null,
};

export type CreateCompetitionMutation = {
  createCompetition?:  {
    __typename: "Competition",
    id: string,
    name: string,
    startDate?: string | null,
    endDate?: string | null,
    organizationId?: string | null,
    teamId?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    competitionOrganizerName: string,
    competitionOrganizerContactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    judgeName?: string | null,
    secretaryName?: string | null,
    StewardName?: string | null,
    isOpenOffered?: boolean | null,
    isIntermediateOffered?: boolean | null,
    isNoviceOffered?: boolean | null,
    isAdvancedBeginnerOffered?: boolean | null,
    isBeginnerOffered?: boolean | null,
    isMinisOffered?: boolean | null,
    isAdultOffered?: boolean | null,
    isAELMedalOffered?: boolean | null,
    entryFeePerRider?: number | null,
    entryFeePerRiderForMedal?: number | null,
    feePaymentOptions?: string | null,
    entryClosingDate?: string | null,
    entryClosingTime?: string | null,
    isEntryLimitInPlace?: boolean | null,
    entryLimitDescription?: string | null,
    otherNotes?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateCompetitionMutationVariables = {
  input: UpdateCompetitionInput,
  condition?: ModelCompetitionConditionInput | null,
};

export type UpdateCompetitionMutation = {
  updateCompetition?:  {
    __typename: "Competition",
    id: string,
    name: string,
    startDate?: string | null,
    endDate?: string | null,
    organizationId?: string | null,
    teamId?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    competitionOrganizerName: string,
    competitionOrganizerContactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    judgeName?: string | null,
    secretaryName?: string | null,
    StewardName?: string | null,
    isOpenOffered?: boolean | null,
    isIntermediateOffered?: boolean | null,
    isNoviceOffered?: boolean | null,
    isAdvancedBeginnerOffered?: boolean | null,
    isBeginnerOffered?: boolean | null,
    isMinisOffered?: boolean | null,
    isAdultOffered?: boolean | null,
    isAELMedalOffered?: boolean | null,
    entryFeePerRider?: number | null,
    entryFeePerRiderForMedal?: number | null,
    feePaymentOptions?: string | null,
    entryClosingDate?: string | null,
    entryClosingTime?: string | null,
    isEntryLimitInPlace?: boolean | null,
    entryLimitDescription?: string | null,
    otherNotes?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteCompetitionMutationVariables = {
  input: DeleteCompetitionInput,
  condition?: ModelCompetitionConditionInput | null,
};

export type DeleteCompetitionMutation = {
  deleteCompetition?:  {
    __typename: "Competition",
    id: string,
    name: string,
    startDate?: string | null,
    endDate?: string | null,
    organizationId?: string | null,
    teamId?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    competitionOrganizerName: string,
    competitionOrganizerContactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    judgeName?: string | null,
    secretaryName?: string | null,
    StewardName?: string | null,
    isOpenOffered?: boolean | null,
    isIntermediateOffered?: boolean | null,
    isNoviceOffered?: boolean | null,
    isAdvancedBeginnerOffered?: boolean | null,
    isBeginnerOffered?: boolean | null,
    isMinisOffered?: boolean | null,
    isAdultOffered?: boolean | null,
    isAELMedalOffered?: boolean | null,
    entryFeePerRider?: number | null,
    entryFeePerRiderForMedal?: number | null,
    feePaymentOptions?: string | null,
    entryClosingDate?: string | null,
    entryClosingTime?: string | null,
    isEntryLimitInPlace?: boolean | null,
    entryLimitDescription?: string | null,
    otherNotes?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateContactMutationVariables = {
  input: CreateContactInput,
  condition?: ModelContactConditionInput | null,
};

export type CreateContactMutation = {
  createContact?:  {
    __typename: "Contact",
    id: string,
    name?: string | null,
    cell?: string | null,
    home?: string | null,
    work?: string | null,
    fax?: string | null,
    personalEmail?: string | null,
    personalEmailIsVerified?: boolean | null,
    workEmail?: string | null,
    workEmailIsVerified?: boolean | null,
    mailingAddress?: string | null,
    website?: string | null,
    facebookPage?: string | null,
    instagramHandle?: string | null,
    twitterHandle?: string | null,
    tiktokHandle?: string | null,
    emergencyContactName?: string | null,
    emergencyContactPhone?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateContactMutationVariables = {
  input: UpdateContactInput,
  condition?: ModelContactConditionInput | null,
};

export type UpdateContactMutation = {
  updateContact?:  {
    __typename: "Contact",
    id: string,
    name?: string | null,
    cell?: string | null,
    home?: string | null,
    work?: string | null,
    fax?: string | null,
    personalEmail?: string | null,
    personalEmailIsVerified?: boolean | null,
    workEmail?: string | null,
    workEmailIsVerified?: boolean | null,
    mailingAddress?: string | null,
    website?: string | null,
    facebookPage?: string | null,
    instagramHandle?: string | null,
    twitterHandle?: string | null,
    tiktokHandle?: string | null,
    emergencyContactName?: string | null,
    emergencyContactPhone?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteContactMutationVariables = {
  input: DeleteContactInput,
  condition?: ModelContactConditionInput | null,
};

export type DeleteContactMutation = {
  deleteContact?:  {
    __typename: "Contact",
    id: string,
    name?: string | null,
    cell?: string | null,
    home?: string | null,
    work?: string | null,
    fax?: string | null,
    personalEmail?: string | null,
    personalEmailIsVerified?: boolean | null,
    workEmail?: string | null,
    workEmailIsVerified?: boolean | null,
    mailingAddress?: string | null,
    website?: string | null,
    facebookPage?: string | null,
    instagramHandle?: string | null,
    twitterHandle?: string | null,
    tiktokHandle?: string | null,
    emergencyContactName?: string | null,
    emergencyContactPhone?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDivisionMutationVariables = {
  input: CreateDivisionInput,
  condition?: ModelDivisionConditionInput | null,
};

export type CreateDivisionMutation = {
  createDivision?:  {
    __typename: "Division",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    discipline?: string | null,
    isSearchable?: boolean | null,
    ushjaName?: string | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    ecClassTypeNumber?: string | null,
    ecClassType?: string | null,
    code?: string | null,
    specs?: string | null,
    judgedOn?: string | null,
    rules?:  {
      __typename: "DivisionRules",
      horseSex?: string | null,
      horseBreed?: string | null,
      horseMinHeight?: string | null,
      horseMaxHeight?: string | null,
      horseMinAge?: string | null,
      horseMaxAge?: string | null,
      riderMinAge?: string | null,
      riderMaxAge?: string | null,
      riderProfessionalStatus?: string | null,
      riderRecord?: string | null,
      horseRecord?: string | null,
      crossEntry?: string | null,
      other?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceDivisionsId?: string | null,
  } | null,
};

export type UpdateDivisionMutationVariables = {
  input: UpdateDivisionInput,
  condition?: ModelDivisionConditionInput | null,
};

export type UpdateDivisionMutation = {
  updateDivision?:  {
    __typename: "Division",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    discipline?: string | null,
    isSearchable?: boolean | null,
    ushjaName?: string | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    ecClassTypeNumber?: string | null,
    ecClassType?: string | null,
    code?: string | null,
    specs?: string | null,
    judgedOn?: string | null,
    rules?:  {
      __typename: "DivisionRules",
      horseSex?: string | null,
      horseBreed?: string | null,
      horseMinHeight?: string | null,
      horseMaxHeight?: string | null,
      horseMinAge?: string | null,
      horseMaxAge?: string | null,
      riderMinAge?: string | null,
      riderMaxAge?: string | null,
      riderProfessionalStatus?: string | null,
      riderRecord?: string | null,
      horseRecord?: string | null,
      crossEntry?: string | null,
      other?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceDivisionsId?: string | null,
  } | null,
};

export type DeleteDivisionMutationVariables = {
  input: DeleteDivisionInput,
  condition?: ModelDivisionConditionInput | null,
};

export type DeleteDivisionMutation = {
  deleteDivision?:  {
    __typename: "Division",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    discipline?: string | null,
    isSearchable?: boolean | null,
    ushjaName?: string | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    ecClassTypeNumber?: string | null,
    ecClassType?: string | null,
    code?: string | null,
    specs?: string | null,
    judgedOn?: string | null,
    rules?:  {
      __typename: "DivisionRules",
      horseSex?: string | null,
      horseBreed?: string | null,
      horseMinHeight?: string | null,
      horseMaxHeight?: string | null,
      horseMinAge?: string | null,
      horseMaxAge?: string | null,
      riderMinAge?: string | null,
      riderMaxAge?: string | null,
      riderProfessionalStatus?: string | null,
      riderRecord?: string | null,
      horseRecord?: string | null,
      crossEntry?: string | null,
      other?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceDivisionsId?: string | null,
  } | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    nickname?: string | null,
    createdBy: string,
    manager?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contactId?: string | null,
    contactIds?: Array< string | null > | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    disciplines?: string | null,
    eventOptions?:  {
      __typename: "EventOptions",
      autoAcceptNewEntries?: boolean | null,
      usesTextAlerts?: boolean | null,
      acceptsIncomingTextAlerts?: boolean | null,
      hasUnlimitedCredits?: boolean | null,
      usesEventManagement?: boolean | null,
      usesStablingManagement?: boolean | null,
      usesVendorManagement?: boolean | null,
      usesMediaTools?: boolean | null,
      usesMarketingTools?: boolean | null,
      usesFinanceTools?: boolean | null,
      usesJudgeApp?: boolean | null,
    } | null,
    paymentOptions?:  {
      __typename: "PaymentOptions",
      acceptsChecks?: boolean | null,
      acceptsCash?: boolean | null,
      acceptsACH?: boolean | null,
      acceptsCC?: boolean | null,
    } | null,
    invoiceOptions?:  {
      __typename: "InvoiceOptions",
      classFeeTax?: number | null,
      sendEmails?: boolean | null,
    } | null,
    entryOptions?:  {
      __typename: "EntryOptions",
      displayHorse2Input?: boolean | null,
      displayRider2Input?: boolean | null,
      displayRider3Input?: boolean | null,
      requiresCogginsDocument?: boolean | null,
      requiresCogginsData?: boolean | null,
      maximumNumberClassesOnEntry?: number | null,
    } | null,
    officials?:  {
      __typename: "ModelEventOfficialConnection",
      items:  Array< {
        __typename: "EventOfficial",
        id: string,
        name: string,
        position: string,
        eventId: string,
        personId?: string | null,
        contactId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: string | null,
    entriesStatus?: string | null,
    showStatus?: string | null,
    usefID?: string | null,
    usefSoftwareKey?: string | null,
    ecID?: string | null,
    ecPassword?: string | null,
    documents?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAddressId?: string | null,
  } | null,
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    nickname?: string | null,
    createdBy: string,
    manager?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contactId?: string | null,
    contactIds?: Array< string | null > | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    disciplines?: string | null,
    eventOptions?:  {
      __typename: "EventOptions",
      autoAcceptNewEntries?: boolean | null,
      usesTextAlerts?: boolean | null,
      acceptsIncomingTextAlerts?: boolean | null,
      hasUnlimitedCredits?: boolean | null,
      usesEventManagement?: boolean | null,
      usesStablingManagement?: boolean | null,
      usesVendorManagement?: boolean | null,
      usesMediaTools?: boolean | null,
      usesMarketingTools?: boolean | null,
      usesFinanceTools?: boolean | null,
      usesJudgeApp?: boolean | null,
    } | null,
    paymentOptions?:  {
      __typename: "PaymentOptions",
      acceptsChecks?: boolean | null,
      acceptsCash?: boolean | null,
      acceptsACH?: boolean | null,
      acceptsCC?: boolean | null,
    } | null,
    invoiceOptions?:  {
      __typename: "InvoiceOptions",
      classFeeTax?: number | null,
      sendEmails?: boolean | null,
    } | null,
    entryOptions?:  {
      __typename: "EntryOptions",
      displayHorse2Input?: boolean | null,
      displayRider2Input?: boolean | null,
      displayRider3Input?: boolean | null,
      requiresCogginsDocument?: boolean | null,
      requiresCogginsData?: boolean | null,
      maximumNumberClassesOnEntry?: number | null,
    } | null,
    officials?:  {
      __typename: "ModelEventOfficialConnection",
      items:  Array< {
        __typename: "EventOfficial",
        id: string,
        name: string,
        position: string,
        eventId: string,
        personId?: string | null,
        contactId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: string | null,
    entriesStatus?: string | null,
    showStatus?: string | null,
    usefID?: string | null,
    usefSoftwareKey?: string | null,
    ecID?: string | null,
    ecPassword?: string | null,
    documents?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAddressId?: string | null,
  } | null,
};

export type DeleteEventMutationVariables = {
  input: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    nickname?: string | null,
    createdBy: string,
    manager?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contactId?: string | null,
    contactIds?: Array< string | null > | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    disciplines?: string | null,
    eventOptions?:  {
      __typename: "EventOptions",
      autoAcceptNewEntries?: boolean | null,
      usesTextAlerts?: boolean | null,
      acceptsIncomingTextAlerts?: boolean | null,
      hasUnlimitedCredits?: boolean | null,
      usesEventManagement?: boolean | null,
      usesStablingManagement?: boolean | null,
      usesVendorManagement?: boolean | null,
      usesMediaTools?: boolean | null,
      usesMarketingTools?: boolean | null,
      usesFinanceTools?: boolean | null,
      usesJudgeApp?: boolean | null,
    } | null,
    paymentOptions?:  {
      __typename: "PaymentOptions",
      acceptsChecks?: boolean | null,
      acceptsCash?: boolean | null,
      acceptsACH?: boolean | null,
      acceptsCC?: boolean | null,
    } | null,
    invoiceOptions?:  {
      __typename: "InvoiceOptions",
      classFeeTax?: number | null,
      sendEmails?: boolean | null,
    } | null,
    entryOptions?:  {
      __typename: "EntryOptions",
      displayHorse2Input?: boolean | null,
      displayRider2Input?: boolean | null,
      displayRider3Input?: boolean | null,
      requiresCogginsDocument?: boolean | null,
      requiresCogginsData?: boolean | null,
      maximumNumberClassesOnEntry?: number | null,
    } | null,
    officials?:  {
      __typename: "ModelEventOfficialConnection",
      items:  Array< {
        __typename: "EventOfficial",
        id: string,
        name: string,
        position: string,
        eventId: string,
        personId?: string | null,
        contactId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: string | null,
    entriesStatus?: string | null,
    showStatus?: string | null,
    usefID?: string | null,
    usefSoftwareKey?: string | null,
    ecID?: string | null,
    ecPassword?: string | null,
    documents?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAddressId?: string | null,
  } | null,
};

export type CreateEventAddMutationVariables = {
  input: CreateEventAddInput,
  condition?: ModelEventAddConditionInput | null,
};

export type CreateEventAddMutation = {
  createEventAdd?:  {
    __typename: "EventAdd",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventAddMutationVariables = {
  input: UpdateEventAddInput,
  condition?: ModelEventAddConditionInput | null,
};

export type UpdateEventAddMutation = {
  updateEventAdd?:  {
    __typename: "EventAdd",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventAddMutationVariables = {
  input: DeleteEventAddInput,
  condition?: ModelEventAddConditionInput | null,
};

export type DeleteEventAddMutation = {
  deleteEventAdd?:  {
    __typename: "EventAdd",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventAlertSettingMutationVariables = {
  input: CreateEventAlertSettingInput,
  condition?: ModelEventAlertSettingConditionInput | null,
};

export type CreateEventAlertSettingMutation = {
  createEventAlertSetting?:  {
    __typename: "EventAlertSetting",
    id: string,
    eventId?: string | null,
    categories?: Array< string | null > | null,
    useDescription?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventAlertSettingMutationVariables = {
  input: UpdateEventAlertSettingInput,
  condition?: ModelEventAlertSettingConditionInput | null,
};

export type UpdateEventAlertSettingMutation = {
  updateEventAlertSetting?:  {
    __typename: "EventAlertSetting",
    id: string,
    eventId?: string | null,
    categories?: Array< string | null > | null,
    useDescription?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventAlertSettingMutationVariables = {
  input: DeleteEventAlertSettingInput,
  condition?: ModelEventAlertSettingConditionInput | null,
};

export type DeleteEventAlertSettingMutation = {
  deleteEventAlertSetting?:  {
    __typename: "EventAlertSetting",
    id: string,
    eventId?: string | null,
    categories?: Array< string | null > | null,
    useDescription?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventAuditorMutationVariables = {
  input: CreateEventAuditorInput,
  condition?: ModelEventAuditorConditionInput | null,
};

export type CreateEventAuditorMutation = {
  createEventAuditor?:  {
    __typename: "EventAuditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    filters?:  {
      __typename: "ModelEventAuditorFilterConnection",
      items:  Array< {
        __typename: "EventAuditorFilter",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        eventauditorid?: string | null,
        eventAuditorId?: string | null,
        organizationId?: string | null,
        organizationAuditorId?: string | null,
        filterId?: string | null,
        options?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorFilterOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    status?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAuditorEventId?: string | null,
  } | null,
};

export type UpdateEventAuditorMutationVariables = {
  input: UpdateEventAuditorInput,
  condition?: ModelEventAuditorConditionInput | null,
};

export type UpdateEventAuditorMutation = {
  updateEventAuditor?:  {
    __typename: "EventAuditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    filters?:  {
      __typename: "ModelEventAuditorFilterConnection",
      items:  Array< {
        __typename: "EventAuditorFilter",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        eventauditorid?: string | null,
        eventAuditorId?: string | null,
        organizationId?: string | null,
        organizationAuditorId?: string | null,
        filterId?: string | null,
        options?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorFilterOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    status?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAuditorEventId?: string | null,
  } | null,
};

export type DeleteEventAuditorMutationVariables = {
  input: DeleteEventAuditorInput,
  condition?: ModelEventAuditorConditionInput | null,
};

export type DeleteEventAuditorMutation = {
  deleteEventAuditor?:  {
    __typename: "EventAuditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    filters?:  {
      __typename: "ModelEventAuditorFilterConnection",
      items:  Array< {
        __typename: "EventAuditorFilter",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        eventauditorid?: string | null,
        eventAuditorId?: string | null,
        organizationId?: string | null,
        organizationAuditorId?: string | null,
        filterId?: string | null,
        options?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorFilterOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    status?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAuditorEventId?: string | null,
  } | null,
};

export type CreateOrganizationAuditorMutationVariables = {
  input: CreateOrganizationAuditorInput,
  condition?: ModelOrganizationAuditorConditionInput | null,
};

export type CreateOrganizationAuditorMutation = {
  createOrganizationAuditor?:  {
    __typename: "OrganizationAuditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    howSignedUp?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    filters?:  {
      __typename: "ModelEventAuditorFilterConnection",
      items:  Array< {
        __typename: "EventAuditorFilter",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        eventauditorid?: string | null,
        eventAuditorId?: string | null,
        organizationId?: string | null,
        organizationAuditorId?: string | null,
        filterId?: string | null,
        options?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorFilterOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    status?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    organizationAuditorPersonId?: string | null,
    organizationAuditorOrganizationId?: string | null,
  } | null,
};

export type UpdateOrganizationAuditorMutationVariables = {
  input: UpdateOrganizationAuditorInput,
  condition?: ModelOrganizationAuditorConditionInput | null,
};

export type UpdateOrganizationAuditorMutation = {
  updateOrganizationAuditor?:  {
    __typename: "OrganizationAuditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    howSignedUp?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    filters?:  {
      __typename: "ModelEventAuditorFilterConnection",
      items:  Array< {
        __typename: "EventAuditorFilter",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        eventauditorid?: string | null,
        eventAuditorId?: string | null,
        organizationId?: string | null,
        organizationAuditorId?: string | null,
        filterId?: string | null,
        options?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorFilterOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    status?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    organizationAuditorPersonId?: string | null,
    organizationAuditorOrganizationId?: string | null,
  } | null,
};

export type DeleteOrganizationAuditorMutationVariables = {
  input: DeleteOrganizationAuditorInput,
  condition?: ModelOrganizationAuditorConditionInput | null,
};

export type DeleteOrganizationAuditorMutation = {
  deleteOrganizationAuditor?:  {
    __typename: "OrganizationAuditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    howSignedUp?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    filters?:  {
      __typename: "ModelEventAuditorFilterConnection",
      items:  Array< {
        __typename: "EventAuditorFilter",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        eventauditorid?: string | null,
        eventAuditorId?: string | null,
        organizationId?: string | null,
        organizationAuditorId?: string | null,
        filterId?: string | null,
        options?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorFilterOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    status?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    organizationAuditorPersonId?: string | null,
    organizationAuditorOrganizationId?: string | null,
  } | null,
};

export type CreateEventAuditorFilterMutationVariables = {
  input: CreateEventAuditorFilterInput,
  condition?: ModelEventAuditorFilterConditionInput | null,
};

export type CreateEventAuditorFilterMutation = {
  createEventAuditorFilter?:  {
    __typename: "EventAuditorFilter",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    eventauditorid?: string | null,
    eventAuditorId?: string | null,
    eventAuditor?:  {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationAuditorId?: string | null,
    organizationAuditor?:  {
      __typename: "OrganizationAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      howSignedUp?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationAuditorPersonId?: string | null,
      organizationAuditorOrganizationId?: string | null,
    } | null,
    filterId?: string | null,
    filter?:  {
      __typename: "EventFilter",
      id: string,
      eventId?: string | null,
      organizationID?: string | null,
      name?: string | null,
      options?: Array< string | null > | null,
      isRequired?: boolean | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    options?: Array< string | null > | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAuditorFilterOrganizationId?: string | null,
  } | null,
};

export type UpdateEventAuditorFilterMutationVariables = {
  input: UpdateEventAuditorFilterInput,
  condition?: ModelEventAuditorFilterConditionInput | null,
};

export type UpdateEventAuditorFilterMutation = {
  updateEventAuditorFilter?:  {
    __typename: "EventAuditorFilter",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    eventauditorid?: string | null,
    eventAuditorId?: string | null,
    eventAuditor?:  {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationAuditorId?: string | null,
    organizationAuditor?:  {
      __typename: "OrganizationAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      howSignedUp?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationAuditorPersonId?: string | null,
      organizationAuditorOrganizationId?: string | null,
    } | null,
    filterId?: string | null,
    filter?:  {
      __typename: "EventFilter",
      id: string,
      eventId?: string | null,
      organizationID?: string | null,
      name?: string | null,
      options?: Array< string | null > | null,
      isRequired?: boolean | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    options?: Array< string | null > | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAuditorFilterOrganizationId?: string | null,
  } | null,
};

export type DeleteEventAuditorFilterMutationVariables = {
  input: DeleteEventAuditorFilterInput,
  condition?: ModelEventAuditorFilterConditionInput | null,
};

export type DeleteEventAuditorFilterMutation = {
  deleteEventAuditorFilter?:  {
    __typename: "EventAuditorFilter",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    eventauditorid?: string | null,
    eventAuditorId?: string | null,
    eventAuditor?:  {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationAuditorId?: string | null,
    organizationAuditor?:  {
      __typename: "OrganizationAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      howSignedUp?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationAuditorPersonId?: string | null,
      organizationAuditorOrganizationId?: string | null,
    } | null,
    filterId?: string | null,
    filter?:  {
      __typename: "EventFilter",
      id: string,
      eventId?: string | null,
      organizationID?: string | null,
      name?: string | null,
      options?: Array< string | null > | null,
      isRequired?: boolean | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    options?: Array< string | null > | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAuditorFilterOrganizationId?: string | null,
  } | null,
};

export type CreateEventBeddingTypeMutationVariables = {
  input: CreateEventBeddingTypeInput,
  condition?: ModelEventBeddingTypeConditionInput | null,
};

export type CreateEventBeddingTypeMutation = {
  createEventBeddingType?:  {
    __typename: "EventBeddingType",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationBeddingTypeId?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerBag?: string | null,
    taxPerBag?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventBeddingTypeMutationVariables = {
  input: UpdateEventBeddingTypeInput,
  condition?: ModelEventBeddingTypeConditionInput | null,
};

export type UpdateEventBeddingTypeMutation = {
  updateEventBeddingType?:  {
    __typename: "EventBeddingType",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationBeddingTypeId?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerBag?: string | null,
    taxPerBag?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventBeddingTypeMutationVariables = {
  input: DeleteEventBeddingTypeInput,
  condition?: ModelEventBeddingTypeConditionInput | null,
};

export type DeleteEventBeddingTypeMutation = {
  deleteEventBeddingType?:  {
    __typename: "EventBeddingType",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationBeddingTypeId?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerBag?: string | null,
    taxPerBag?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventBreakMutationVariables = {
  input: CreateEventBreakInput,
  condition?: ModelEventBreakConditionInput | null,
};

export type CreateEventBreakMutation = {
  createEventBreak?:  {
    __typename: "EventBreak",
    id: string,
    eventId: string,
    name?: string | null,
    time?: string | null,
    isDisplayedOnSchedule?: boolean | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventBreakMutationVariables = {
  input: UpdateEventBreakInput,
  condition?: ModelEventBreakConditionInput | null,
};

export type UpdateEventBreakMutation = {
  updateEventBreak?:  {
    __typename: "EventBreak",
    id: string,
    eventId: string,
    name?: string | null,
    time?: string | null,
    isDisplayedOnSchedule?: boolean | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventBreakMutationVariables = {
  input: DeleteEventBreakInput,
  condition?: ModelEventBreakConditionInput | null,
};

export type DeleteEventBreakMutation = {
  deleteEventBreak?:  {
    __typename: "EventBreak",
    id: string,
    eventId: string,
    name?: string | null,
    time?: string | null,
    isDisplayedOnSchedule?: boolean | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventClassMutationVariables = {
  input: CreateEventClassInput,
  condition?: ModelEventClassConditionInput | null,
};

export type CreateEventClassMutation = {
  createEventClass?:  {
    __typename: "EventClass",
    id: string,
    name: string,
    eventId: string,
    classId: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    } | null,
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    organizationClassId?: string | null,
    organizationClass?:  {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventResults?:  {
      __typename: "ModelEventResultConnection",
      items:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    number?: number | null,
    entryOption?: EntryOption | null,
    entryFee?: string | null,
    fees?:  {
      __typename: "ModelEventFeeConnection",
      items:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prizeMoney?: string | null,
    entries?:  {
      __typename: "ModelEventClassEntryConnection",
      items:  Array< {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedVerifyEntries?: boolean | null,
    currentNumberEntries?: number | null,
    numberEntriesThatCompeted?: number | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    estimatedTimePerRound?: number | null,
    isCaliforniaSplit?: boolean | null,
    isCombined?: boolean | null,
    combinedEventClassId?: string | null,
    combinedEventDivisionId?: string | null,
    jumpHeight?: Array< string | null > | null,
    isScheduled?: boolean | null,
    note?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventClassPointTableId?: string | null,
    eventClassPrizeMoneyTableId?: string | null,
  } | null,
};

export type UpdateEventClassMutationVariables = {
  input: UpdateEventClassInput,
  condition?: ModelEventClassConditionInput | null,
};

export type UpdateEventClassMutation = {
  updateEventClass?:  {
    __typename: "EventClass",
    id: string,
    name: string,
    eventId: string,
    classId: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    } | null,
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    organizationClassId?: string | null,
    organizationClass?:  {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventResults?:  {
      __typename: "ModelEventResultConnection",
      items:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    number?: number | null,
    entryOption?: EntryOption | null,
    entryFee?: string | null,
    fees?:  {
      __typename: "ModelEventFeeConnection",
      items:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prizeMoney?: string | null,
    entries?:  {
      __typename: "ModelEventClassEntryConnection",
      items:  Array< {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedVerifyEntries?: boolean | null,
    currentNumberEntries?: number | null,
    numberEntriesThatCompeted?: number | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    estimatedTimePerRound?: number | null,
    isCaliforniaSplit?: boolean | null,
    isCombined?: boolean | null,
    combinedEventClassId?: string | null,
    combinedEventDivisionId?: string | null,
    jumpHeight?: Array< string | null > | null,
    isScheduled?: boolean | null,
    note?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventClassPointTableId?: string | null,
    eventClassPrizeMoneyTableId?: string | null,
  } | null,
};

export type DeleteEventClassMutationVariables = {
  input: DeleteEventClassInput,
  condition?: ModelEventClassConditionInput | null,
};

export type DeleteEventClassMutation = {
  deleteEventClass?:  {
    __typename: "EventClass",
    id: string,
    name: string,
    eventId: string,
    classId: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    } | null,
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    organizationClassId?: string | null,
    organizationClass?:  {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventResults?:  {
      __typename: "ModelEventResultConnection",
      items:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    number?: number | null,
    entryOption?: EntryOption | null,
    entryFee?: string | null,
    fees?:  {
      __typename: "ModelEventFeeConnection",
      items:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prizeMoney?: string | null,
    entries?:  {
      __typename: "ModelEventClassEntryConnection",
      items:  Array< {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedVerifyEntries?: boolean | null,
    currentNumberEntries?: number | null,
    numberEntriesThatCompeted?: number | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    estimatedTimePerRound?: number | null,
    isCaliforniaSplit?: boolean | null,
    isCombined?: boolean | null,
    combinedEventClassId?: string | null,
    combinedEventDivisionId?: string | null,
    jumpHeight?: Array< string | null > | null,
    isScheduled?: boolean | null,
    note?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventClassPointTableId?: string | null,
    eventClassPrizeMoneyTableId?: string | null,
  } | null,
};

export type CreateEventClassEntryMutationVariables = {
  input: CreateEventClassEntryInput,
  condition?: ModelEventClassEntryConditionInput | null,
};

export type CreateEventClassEntryMutation = {
  createEventClassEntry?:  {
    __typename: "EventClassEntry",
    id: string,
    eventId: string,
    eventClassId: string,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventEntryId: string,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    status?: string | null,
    selectedEntryOption?: EntryOption | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventClassEntryMutationVariables = {
  input: UpdateEventClassEntryInput,
  condition?: ModelEventClassEntryConditionInput | null,
};

export type UpdateEventClassEntryMutation = {
  updateEventClassEntry?:  {
    __typename: "EventClassEntry",
    id: string,
    eventId: string,
    eventClassId: string,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventEntryId: string,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    status?: string | null,
    selectedEntryOption?: EntryOption | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventClassEntryMutationVariables = {
  input: DeleteEventClassEntryInput,
  condition?: ModelEventClassEntryConditionInput | null,
};

export type DeleteEventClassEntryMutation = {
  deleteEventClassEntry?:  {
    __typename: "EventClassEntry",
    id: string,
    eventId: string,
    eventClassId: string,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventEntryId: string,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    status?: string | null,
    selectedEntryOption?: EntryOption | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventDayMutationVariables = {
  input: CreateEventDayInput,
  condition?: ModelEventDayConditionInput | null,
};

export type CreateEventDayMutation = {
  createEventDay?:  {
    __typename: "EventDay",
    id: string,
    eventId?: string | null,
    awsdate?: string | null,
    dayofweek?: string | null,
    date?: string | null,
    month?: string | null,
    year?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventDayMutationVariables = {
  input: UpdateEventDayInput,
  condition?: ModelEventDayConditionInput | null,
};

export type UpdateEventDayMutation = {
  updateEventDay?:  {
    __typename: "EventDay",
    id: string,
    eventId?: string | null,
    awsdate?: string | null,
    dayofweek?: string | null,
    date?: string | null,
    month?: string | null,
    year?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventDayMutationVariables = {
  input: DeleteEventDayInput,
  condition?: ModelEventDayConditionInput | null,
};

export type DeleteEventDayMutation = {
  deleteEventDay?:  {
    __typename: "EventDay",
    id: string,
    eventId?: string | null,
    awsdate?: string | null,
    dayofweek?: string | null,
    date?: string | null,
    month?: string | null,
    year?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventDivisionMutationVariables = {
  input: CreateEventDivisionInput,
  condition?: ModelEventDivisionConditionInput | null,
};

export type CreateEventDivisionMutation = {
  createEventDivision?:  {
    __typename: "EventDivision",
    id: string,
    eventId: string,
    name: string,
    number?: number | null,
    divisionId: string,
    division?:  {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    } | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventClasses?:  {
      __typename: "ModelEventClassConnection",
      items:  Array< {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventResults?:  {
      __typename: "ModelEventResultConnection",
      items:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    jumpHeight?: Array< string | null > | null,
    minNumberEntriesPerClass?: number | null,
    maxNumberEntriesPerClass?: number | null,
    isCaliforniaSplit?: boolean | null,
    isCombined?: boolean | null,
    combinedEventDivisionId?: string | null,
    combinedEventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventDivisionOrganizationDivisionId?: string | null,
    eventDivisionPointTableId?: string | null,
    eventDivisionPrizeMoneyTableId?: string | null,
  } | null,
};

export type UpdateEventDivisionMutationVariables = {
  input: UpdateEventDivisionInput,
  condition?: ModelEventDivisionConditionInput | null,
};

export type UpdateEventDivisionMutation = {
  updateEventDivision?:  {
    __typename: "EventDivision",
    id: string,
    eventId: string,
    name: string,
    number?: number | null,
    divisionId: string,
    division?:  {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    } | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventClasses?:  {
      __typename: "ModelEventClassConnection",
      items:  Array< {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventResults?:  {
      __typename: "ModelEventResultConnection",
      items:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    jumpHeight?: Array< string | null > | null,
    minNumberEntriesPerClass?: number | null,
    maxNumberEntriesPerClass?: number | null,
    isCaliforniaSplit?: boolean | null,
    isCombined?: boolean | null,
    combinedEventDivisionId?: string | null,
    combinedEventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventDivisionOrganizationDivisionId?: string | null,
    eventDivisionPointTableId?: string | null,
    eventDivisionPrizeMoneyTableId?: string | null,
  } | null,
};

export type DeleteEventDivisionMutationVariables = {
  input: DeleteEventDivisionInput,
  condition?: ModelEventDivisionConditionInput | null,
};

export type DeleteEventDivisionMutation = {
  deleteEventDivision?:  {
    __typename: "EventDivision",
    id: string,
    eventId: string,
    name: string,
    number?: number | null,
    divisionId: string,
    division?:  {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    } | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventClasses?:  {
      __typename: "ModelEventClassConnection",
      items:  Array< {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventResults?:  {
      __typename: "ModelEventResultConnection",
      items:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    jumpHeight?: Array< string | null > | null,
    minNumberEntriesPerClass?: number | null,
    maxNumberEntriesPerClass?: number | null,
    isCaliforniaSplit?: boolean | null,
    isCombined?: boolean | null,
    combinedEventDivisionId?: string | null,
    combinedEventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventDivisionOrganizationDivisionId?: string | null,
    eventDivisionPointTableId?: string | null,
    eventDivisionPrizeMoneyTableId?: string | null,
  } | null,
};

export type CreateEventEntryMutationVariables = {
  input: CreateEventEntryInput,
  condition?: ModelEventEntryConditionInput | null,
};

export type CreateEventEntryMutation = {
  createEventEntry?:  {
    __typename: "EventEntry",
    id: string,
    eventId: string,
    status: string,
    statusNote?: string | null,
    personId?: string | null,
    number?: number | null,
    isCheckedIn?: boolean | null,
    isCheckedOut?: boolean | null,
    contactId: string,
    primarycontact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    riderName?: string | null,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    secondRiderName?: string | null,
    secondRiderId?: string | null,
    secondRider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    thirdRiderName?: string | null,
    thirdRiderId?: string | null,
    thirdRider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    horseName?: string | null,
    horseId?: string | null,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    ownerName?: string | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    secondOwnerName?: string | null,
    secondOwnerId?: string | null,
    secondOwner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    thirdOwnerName?: string | null,
    thirdOwnerId?: string | null,
    thirdOwner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    trainerName?: string | null,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    secondTrainerName?: string | null,
    secondTrainerId?: string | null,
    secondTrainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    thirdTrainerName?: string | null,
    thirdTrainerId?: string | null,
    thirdTrainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    stablingRequestId?: string | null,
    stablingRequest?:  {
      __typename: "StablingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      departureDateTime?: string | null,
      stallTypeId?: string | null,
      stallType?:  {
        __typename: "EventStallType",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallTypeId?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        pricePerStall?: string | null,
        taxPerStall?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?: number | null,
      taxB?: number | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      requestNote?: string | null,
      stallSetId?: string | null,
      stallSet?:  {
        __typename: "EventStallSet",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallSetId?: string | null,
        name?: string | null,
        location?: string | null,
        available?: string | null,
        totalAvailable?: number | null,
        used?: string | null,
        totalUsed?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      stallLocation?: string | null,
      stallNumbers?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    beddingRequestId?: string | null,
    beddingRequest?:  {
      __typename: "BeddingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      dateTimeNeeded?: string | null,
      deliveryInfo?: string | null,
      beddingType?: string | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      beddingRequestTaxAId?: string | null,
      beddingRequestTaxBId?: string | null,
    } | null,
    markedDoesNotNeedStalls?: boolean | null,
    payerName?: string | null,
    payerId?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    prizeMoneyRecipientName?: string | null,
    prizeMoneyRecipientId?: string | null,
    prizeMoneyRecipient?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    prizeMoneyRecipientContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientType?: string | null,
    eventRulesAgreements?:  {
      __typename: "ModelEventRulesAgreementConnection",
      items:  Array< {
        __typename: "EventRulesAgreement",
        id: string,
        eventId: string,
        eventRuleId: string,
        eventEntryId?: string | null,
        name?: string | null,
        personId?: string | null,
        personType?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    note?: string | null,
    divisionList?: Array< string | null > | null,
    classList?:  {
      __typename: "ModelEventClassEntryConnection",
      items:  Array< {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fees?:  Array< {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null > | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventEntryPrizeMoneyRecipientContactId?: string | null,
  } | null,
};

export type UpdateEventEntryMutationVariables = {
  input: UpdateEventEntryInput,
  condition?: ModelEventEntryConditionInput | null,
};

export type UpdateEventEntryMutation = {
  updateEventEntry?:  {
    __typename: "EventEntry",
    id: string,
    eventId: string,
    status: string,
    statusNote?: string | null,
    personId?: string | null,
    number?: number | null,
    isCheckedIn?: boolean | null,
    isCheckedOut?: boolean | null,
    contactId: string,
    primarycontact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    riderName?: string | null,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    secondRiderName?: string | null,
    secondRiderId?: string | null,
    secondRider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    thirdRiderName?: string | null,
    thirdRiderId?: string | null,
    thirdRider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    horseName?: string | null,
    horseId?: string | null,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    ownerName?: string | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    secondOwnerName?: string | null,
    secondOwnerId?: string | null,
    secondOwner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    thirdOwnerName?: string | null,
    thirdOwnerId?: string | null,
    thirdOwner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    trainerName?: string | null,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    secondTrainerName?: string | null,
    secondTrainerId?: string | null,
    secondTrainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    thirdTrainerName?: string | null,
    thirdTrainerId?: string | null,
    thirdTrainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    stablingRequestId?: string | null,
    stablingRequest?:  {
      __typename: "StablingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      departureDateTime?: string | null,
      stallTypeId?: string | null,
      stallType?:  {
        __typename: "EventStallType",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallTypeId?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        pricePerStall?: string | null,
        taxPerStall?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?: number | null,
      taxB?: number | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      requestNote?: string | null,
      stallSetId?: string | null,
      stallSet?:  {
        __typename: "EventStallSet",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallSetId?: string | null,
        name?: string | null,
        location?: string | null,
        available?: string | null,
        totalAvailable?: number | null,
        used?: string | null,
        totalUsed?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      stallLocation?: string | null,
      stallNumbers?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    beddingRequestId?: string | null,
    beddingRequest?:  {
      __typename: "BeddingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      dateTimeNeeded?: string | null,
      deliveryInfo?: string | null,
      beddingType?: string | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      beddingRequestTaxAId?: string | null,
      beddingRequestTaxBId?: string | null,
    } | null,
    markedDoesNotNeedStalls?: boolean | null,
    payerName?: string | null,
    payerId?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    prizeMoneyRecipientName?: string | null,
    prizeMoneyRecipientId?: string | null,
    prizeMoneyRecipient?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    prizeMoneyRecipientContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientType?: string | null,
    eventRulesAgreements?:  {
      __typename: "ModelEventRulesAgreementConnection",
      items:  Array< {
        __typename: "EventRulesAgreement",
        id: string,
        eventId: string,
        eventRuleId: string,
        eventEntryId?: string | null,
        name?: string | null,
        personId?: string | null,
        personType?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    note?: string | null,
    divisionList?: Array< string | null > | null,
    classList?:  {
      __typename: "ModelEventClassEntryConnection",
      items:  Array< {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fees?:  Array< {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null > | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventEntryPrizeMoneyRecipientContactId?: string | null,
  } | null,
};

export type DeleteEventEntryMutationVariables = {
  input: DeleteEventEntryInput,
  condition?: ModelEventEntryConditionInput | null,
};

export type DeleteEventEntryMutation = {
  deleteEventEntry?:  {
    __typename: "EventEntry",
    id: string,
    eventId: string,
    status: string,
    statusNote?: string | null,
    personId?: string | null,
    number?: number | null,
    isCheckedIn?: boolean | null,
    isCheckedOut?: boolean | null,
    contactId: string,
    primarycontact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    riderName?: string | null,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    secondRiderName?: string | null,
    secondRiderId?: string | null,
    secondRider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    thirdRiderName?: string | null,
    thirdRiderId?: string | null,
    thirdRider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    horseName?: string | null,
    horseId?: string | null,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    ownerName?: string | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    secondOwnerName?: string | null,
    secondOwnerId?: string | null,
    secondOwner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    thirdOwnerName?: string | null,
    thirdOwnerId?: string | null,
    thirdOwner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    trainerName?: string | null,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    secondTrainerName?: string | null,
    secondTrainerId?: string | null,
    secondTrainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    thirdTrainerName?: string | null,
    thirdTrainerId?: string | null,
    thirdTrainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    stablingRequestId?: string | null,
    stablingRequest?:  {
      __typename: "StablingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      departureDateTime?: string | null,
      stallTypeId?: string | null,
      stallType?:  {
        __typename: "EventStallType",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallTypeId?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        pricePerStall?: string | null,
        taxPerStall?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?: number | null,
      taxB?: number | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      requestNote?: string | null,
      stallSetId?: string | null,
      stallSet?:  {
        __typename: "EventStallSet",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallSetId?: string | null,
        name?: string | null,
        location?: string | null,
        available?: string | null,
        totalAvailable?: number | null,
        used?: string | null,
        totalUsed?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      stallLocation?: string | null,
      stallNumbers?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    beddingRequestId?: string | null,
    beddingRequest?:  {
      __typename: "BeddingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      dateTimeNeeded?: string | null,
      deliveryInfo?: string | null,
      beddingType?: string | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      beddingRequestTaxAId?: string | null,
      beddingRequestTaxBId?: string | null,
    } | null,
    markedDoesNotNeedStalls?: boolean | null,
    payerName?: string | null,
    payerId?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    prizeMoneyRecipientName?: string | null,
    prizeMoneyRecipientId?: string | null,
    prizeMoneyRecipient?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    prizeMoneyRecipientContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientType?: string | null,
    eventRulesAgreements?:  {
      __typename: "ModelEventRulesAgreementConnection",
      items:  Array< {
        __typename: "EventRulesAgreement",
        id: string,
        eventId: string,
        eventRuleId: string,
        eventEntryId?: string | null,
        name?: string | null,
        personId?: string | null,
        personType?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    note?: string | null,
    divisionList?: Array< string | null > | null,
    classList?:  {
      __typename: "ModelEventClassEntryConnection",
      items:  Array< {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fees?:  Array< {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null > | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventEntryPrizeMoneyRecipientContactId?: string | null,
  } | null,
};

export type CreateEventEntryFeeMutationVariables = {
  input: CreateEventEntryFeeInput,
  condition?: ModelEventEntryFeeConditionInput | null,
};

export type CreateEventEntryFeeMutation = {
  createEventEntryFee?:  {
    __typename: "EventEntryFee",
    id: string,
    name: string,
    description?: string | null,
    status?: string | null,
    amount: number,
    quantity?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    eventId: string,
    feeId: string,
    fee?:  {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null,
    eventFeeId?: string | null,
    eventFee?:  {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    entryId?: string | null,
    entry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventEntryFeeMutationVariables = {
  input: UpdateEventEntryFeeInput,
  condition?: ModelEventEntryFeeConditionInput | null,
};

export type UpdateEventEntryFeeMutation = {
  updateEventEntryFee?:  {
    __typename: "EventEntryFee",
    id: string,
    name: string,
    description?: string | null,
    status?: string | null,
    amount: number,
    quantity?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    eventId: string,
    feeId: string,
    fee?:  {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null,
    eventFeeId?: string | null,
    eventFee?:  {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    entryId?: string | null,
    entry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventEntryFeeMutationVariables = {
  input: DeleteEventEntryFeeInput,
  condition?: ModelEventEntryFeeConditionInput | null,
};

export type DeleteEventEntryFeeMutation = {
  deleteEventEntryFee?:  {
    __typename: "EventEntryFee",
    id: string,
    name: string,
    description?: string | null,
    status?: string | null,
    amount: number,
    quantity?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    eventId: string,
    feeId: string,
    fee?:  {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null,
    eventFeeId?: string | null,
    eventFee?:  {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    entryId?: string | null,
    entry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventEntryNumbersMutationVariables = {
  input: CreateEventEntryNumbersInput,
  condition?: ModelEventEntryNumbersConditionInput | null,
};

export type CreateEventEntryNumbersMutation = {
  createEventEntryNumbers?:  {
    __typename: "EventEntryNumbers",
    id: string,
    eventId: string,
    name?: string | null,
    available?: string | null,
    totalAvailable?: number | null,
    used?: string | null,
    totalUsed?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventEntryNumbersMutationVariables = {
  input: UpdateEventEntryNumbersInput,
  condition?: ModelEventEntryNumbersConditionInput | null,
};

export type UpdateEventEntryNumbersMutation = {
  updateEventEntryNumbers?:  {
    __typename: "EventEntryNumbers",
    id: string,
    eventId: string,
    name?: string | null,
    available?: string | null,
    totalAvailable?: number | null,
    used?: string | null,
    totalUsed?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventEntryNumbersMutationVariables = {
  input: DeleteEventEntryNumbersInput,
  condition?: ModelEventEntryNumbersConditionInput | null,
};

export type DeleteEventEntryNumbersMutation = {
  deleteEventEntryNumbers?:  {
    __typename: "EventEntryNumbers",
    id: string,
    eventId: string,
    name?: string | null,
    available?: string | null,
    totalAvailable?: number | null,
    used?: string | null,
    totalUsed?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventFeeMutationVariables = {
  input: CreateEventFeeInput,
  condition?: ModelEventFeeConditionInput | null,
};

export type CreateEventFeeMutation = {
  createEventFee?:  {
    __typename: "EventFee",
    id: string,
    name: string,
    amount: number,
    description?: string | null,
    eventId: string,
    createdBy: string,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    feeId: string,
    fee?:  {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null > | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventFeeMutationVariables = {
  input: UpdateEventFeeInput,
  condition?: ModelEventFeeConditionInput | null,
};

export type UpdateEventFeeMutation = {
  updateEventFee?:  {
    __typename: "EventFee",
    id: string,
    name: string,
    amount: number,
    description?: string | null,
    eventId: string,
    createdBy: string,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    feeId: string,
    fee?:  {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null > | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventFeeMutationVariables = {
  input: DeleteEventFeeInput,
  condition?: ModelEventFeeConditionInput | null,
};

export type DeleteEventFeeMutation = {
  deleteEventFee?:  {
    __typename: "EventFee",
    id: string,
    name: string,
    amount: number,
    description?: string | null,
    eventId: string,
    createdBy: string,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    feeId: string,
    fee?:  {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null > | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventFilterMutationVariables = {
  input: CreateEventFilterInput,
  condition?: ModelEventFilterConditionInput | null,
};

export type CreateEventFilterMutation = {
  createEventFilter?:  {
    __typename: "EventFilter",
    id: string,
    eventId?: string | null,
    organizationID?: string | null,
    name?: string | null,
    options?: Array< string | null > | null,
    isRequired?: boolean | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventFilterMutationVariables = {
  input: UpdateEventFilterInput,
  condition?: ModelEventFilterConditionInput | null,
};

export type UpdateEventFilterMutation = {
  updateEventFilter?:  {
    __typename: "EventFilter",
    id: string,
    eventId?: string | null,
    organizationID?: string | null,
    name?: string | null,
    options?: Array< string | null > | null,
    isRequired?: boolean | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventFilterMutationVariables = {
  input: DeleteEventFilterInput,
  condition?: ModelEventFilterConditionInput | null,
};

export type DeleteEventFilterMutation = {
  deleteEventFilter?:  {
    __typename: "EventFilter",
    id: string,
    eventId?: string | null,
    organizationID?: string | null,
    name?: string | null,
    options?: Array< string | null > | null,
    isRequired?: boolean | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventInvoiceMutationVariables = {
  input: CreateEventInvoiceInput,
  condition?: ModelEventInvoiceConditionInput | null,
};

export type CreateEventInvoiceMutation = {
  createEventInvoice?:  {
    __typename: "EventInvoice",
    id: string,
    eventId: string,
    status: string,
    statusNote?: string | null,
    number: number,
    stripeInvoiceNumber?: string | null,
    entryNumber?: number | null,
    entryId?: string | null,
    entry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    payerName?: string | null,
    payerId?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    payerType?: string | null,
    payerStripeId?: string | null,
    payerContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientName?: string | null,
    prizeMoneyRecipientId?: string | null,
    prizeMoneyRecipientStripeId?: string | null,
    prizeMoneyRecipient?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    prizeMoneyRecipientContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientType?: string | null,
    divisions?:  {
      __typename: "ModelDivisionConnection",
      items:  Array< {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fees?:  {
      __typename: "ModelFeeConnection",
      items:  Array< {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryFeeTotalAmount?: number | null,
    otherFeeTotalAmount?: number | null,
    stablingAmount?: number | null,
    beddingAmount?: number | null,
    feedAmount?: number | null,
    results?:  Array< {
      __typename: "EventResult",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventClassEntryId?: string | null,
      eventClassEntry?:  {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      resultSet?: string | null,
      place?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      pointTables?:  {
        __typename: "ModelPointTableConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      eventResultEntryId: string,
      eventResultEventClassEntryId?: string | null,
      eventResultPrizeMoneyTableId?: string | null,
    } | null > | null,
    prizeMoneyAmount?: number | null,
    amountToDeductFromInvoice?: number | null,
    amountToPayRecipientViaCheck?: number | null,
    totalDue?: number | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoicePayerContactId?: string | null,
    eventInvoicePrizeMoneyRecipientId?: string | null,
    eventInvoicePrizeMoneyRecipientContactId?: string | null,
  } | null,
};

export type UpdateEventInvoiceMutationVariables = {
  input: UpdateEventInvoiceInput,
  condition?: ModelEventInvoiceConditionInput | null,
};

export type UpdateEventInvoiceMutation = {
  updateEventInvoice?:  {
    __typename: "EventInvoice",
    id: string,
    eventId: string,
    status: string,
    statusNote?: string | null,
    number: number,
    stripeInvoiceNumber?: string | null,
    entryNumber?: number | null,
    entryId?: string | null,
    entry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    payerName?: string | null,
    payerId?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    payerType?: string | null,
    payerStripeId?: string | null,
    payerContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientName?: string | null,
    prizeMoneyRecipientId?: string | null,
    prizeMoneyRecipientStripeId?: string | null,
    prizeMoneyRecipient?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    prizeMoneyRecipientContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientType?: string | null,
    divisions?:  {
      __typename: "ModelDivisionConnection",
      items:  Array< {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fees?:  {
      __typename: "ModelFeeConnection",
      items:  Array< {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryFeeTotalAmount?: number | null,
    otherFeeTotalAmount?: number | null,
    stablingAmount?: number | null,
    beddingAmount?: number | null,
    feedAmount?: number | null,
    results?:  Array< {
      __typename: "EventResult",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventClassEntryId?: string | null,
      eventClassEntry?:  {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      resultSet?: string | null,
      place?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      pointTables?:  {
        __typename: "ModelPointTableConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      eventResultEntryId: string,
      eventResultEventClassEntryId?: string | null,
      eventResultPrizeMoneyTableId?: string | null,
    } | null > | null,
    prizeMoneyAmount?: number | null,
    amountToDeductFromInvoice?: number | null,
    amountToPayRecipientViaCheck?: number | null,
    totalDue?: number | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoicePayerContactId?: string | null,
    eventInvoicePrizeMoneyRecipientId?: string | null,
    eventInvoicePrizeMoneyRecipientContactId?: string | null,
  } | null,
};

export type DeleteEventInvoiceMutationVariables = {
  input: DeleteEventInvoiceInput,
  condition?: ModelEventInvoiceConditionInput | null,
};

export type DeleteEventInvoiceMutation = {
  deleteEventInvoice?:  {
    __typename: "EventInvoice",
    id: string,
    eventId: string,
    status: string,
    statusNote?: string | null,
    number: number,
    stripeInvoiceNumber?: string | null,
    entryNumber?: number | null,
    entryId?: string | null,
    entry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    payerName?: string | null,
    payerId?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    payerType?: string | null,
    payerStripeId?: string | null,
    payerContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientName?: string | null,
    prizeMoneyRecipientId?: string | null,
    prizeMoneyRecipientStripeId?: string | null,
    prizeMoneyRecipient?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    prizeMoneyRecipientContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientType?: string | null,
    divisions?:  {
      __typename: "ModelDivisionConnection",
      items:  Array< {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fees?:  {
      __typename: "ModelFeeConnection",
      items:  Array< {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryFeeTotalAmount?: number | null,
    otherFeeTotalAmount?: number | null,
    stablingAmount?: number | null,
    beddingAmount?: number | null,
    feedAmount?: number | null,
    results?:  Array< {
      __typename: "EventResult",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventClassEntryId?: string | null,
      eventClassEntry?:  {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      resultSet?: string | null,
      place?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      pointTables?:  {
        __typename: "ModelPointTableConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      eventResultEntryId: string,
      eventResultEventClassEntryId?: string | null,
      eventResultPrizeMoneyTableId?: string | null,
    } | null > | null,
    prizeMoneyAmount?: number | null,
    amountToDeductFromInvoice?: number | null,
    amountToPayRecipientViaCheck?: number | null,
    totalDue?: number | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoicePayerContactId?: string | null,
    eventInvoicePrizeMoneyRecipientId?: string | null,
    eventInvoicePrizeMoneyRecipientContactId?: string | null,
  } | null,
};

export type CreateEventJudgeMutationVariables = {
  input: CreateEventJudgeInput,
  condition?: ModelEventJudgeConditionInput | null,
};

export type CreateEventJudgeMutation = {
  createEventJudge?:  {
    __typename: "EventJudge",
    id: string,
    name: string,
    eventId: string,
    personId?: string | null,
    judgeId?: string | null,
    contactId?: string | null,
    rating?:  Array< {
      __typename: "JudgeRating",
      type?: JudgeType | null,
      license?: JudgeLicense | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEventJudgeMutationVariables = {
  input: UpdateEventJudgeInput,
  condition?: ModelEventJudgeConditionInput | null,
};

export type UpdateEventJudgeMutation = {
  updateEventJudge?:  {
    __typename: "EventJudge",
    id: string,
    name: string,
    eventId: string,
    personId?: string | null,
    judgeId?: string | null,
    contactId?: string | null,
    rating?:  Array< {
      __typename: "JudgeRating",
      type?: JudgeType | null,
      license?: JudgeLicense | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEventJudgeMutationVariables = {
  input: DeleteEventJudgeInput,
  condition?: ModelEventJudgeConditionInput | null,
};

export type DeleteEventJudgeMutation = {
  deleteEventJudge?:  {
    __typename: "EventJudge",
    id: string,
    name: string,
    eventId: string,
    personId?: string | null,
    judgeId?: string | null,
    contactId?: string | null,
    rating?:  Array< {
      __typename: "JudgeRating",
      type?: JudgeType | null,
      license?: JudgeLicense | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEventOfficialMutationVariables = {
  input: CreateEventOfficialInput,
  condition?: ModelEventOfficialConditionInput | null,
};

export type CreateEventOfficialMutation = {
  createEventOfficial?:  {
    __typename: "EventOfficial",
    id: string,
    name: string,
    position: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    personId?: string | null,
    contactId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEventOfficialMutationVariables = {
  input: UpdateEventOfficialInput,
  condition?: ModelEventOfficialConditionInput | null,
};

export type UpdateEventOfficialMutation = {
  updateEventOfficial?:  {
    __typename: "EventOfficial",
    id: string,
    name: string,
    position: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    personId?: string | null,
    contactId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEventOfficialMutationVariables = {
  input: DeleteEventOfficialInput,
  condition?: ModelEventOfficialConditionInput | null,
};

export type DeleteEventOfficialMutation = {
  deleteEventOfficial?:  {
    __typename: "EventOfficial",
    id: string,
    name: string,
    position: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    personId?: string | null,
    contactId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEventResultMutationVariables = {
  input: CreateEventResultInput,
  condition?: ModelEventResultConditionInput | null,
};

export type CreateEventResultMutation = {
  createEventResult?:  {
    __typename: "EventResult",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventClassEntryId?: string | null,
    eventClassEntry?:  {
      __typename: "EventClassEntry",
      id: string,
      eventId: string,
      eventClassId: string,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventEntryId: string,
      eventEntry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      status?: string | null,
      selectedEntryOption?: EntryOption | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    resultSet?: string | null,
    place?: number | null,
    score?: number | null,
    time?: string | null,
    didNotCompete?: boolean | null,
    prizeMoney?: number | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    pointTables?:  {
      __typename: "ModelPointTableConnection",
      items:  Array< {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    note?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    eventResultEntryId: string,
    eventResultEventClassEntryId?: string | null,
    eventResultPrizeMoneyTableId?: string | null,
  } | null,
};

export type UpdateEventResultMutationVariables = {
  input: UpdateEventResultInput,
  condition?: ModelEventResultConditionInput | null,
};

export type UpdateEventResultMutation = {
  updateEventResult?:  {
    __typename: "EventResult",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventClassEntryId?: string | null,
    eventClassEntry?:  {
      __typename: "EventClassEntry",
      id: string,
      eventId: string,
      eventClassId: string,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventEntryId: string,
      eventEntry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      status?: string | null,
      selectedEntryOption?: EntryOption | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    resultSet?: string | null,
    place?: number | null,
    score?: number | null,
    time?: string | null,
    didNotCompete?: boolean | null,
    prizeMoney?: number | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    pointTables?:  {
      __typename: "ModelPointTableConnection",
      items:  Array< {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    note?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    eventResultEntryId: string,
    eventResultEventClassEntryId?: string | null,
    eventResultPrizeMoneyTableId?: string | null,
  } | null,
};

export type DeleteEventResultMutationVariables = {
  input: DeleteEventResultInput,
  condition?: ModelEventResultConditionInput | null,
};

export type DeleteEventResultMutation = {
  deleteEventResult?:  {
    __typename: "EventResult",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventClassEntryId?: string | null,
    eventClassEntry?:  {
      __typename: "EventClassEntry",
      id: string,
      eventId: string,
      eventClassId: string,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventEntryId: string,
      eventEntry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      status?: string | null,
      selectedEntryOption?: EntryOption | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    resultSet?: string | null,
    place?: number | null,
    score?: number | null,
    time?: string | null,
    didNotCompete?: boolean | null,
    prizeMoney?: number | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    pointTables?:  {
      __typename: "ModelPointTableConnection",
      items:  Array< {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    note?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    eventResultEntryId: string,
    eventResultEventClassEntryId?: string | null,
    eventResultPrizeMoneyTableId?: string | null,
  } | null,
};

export type CreateEventRingMutationVariables = {
  input: CreateEventRingInput,
  condition?: ModelEventRingConditionInput | null,
};

export type CreateEventRingMutation = {
  createEventRing?:  {
    __typename: "EventRing",
    id: string,
    name: string,
    eventId: string,
    ringId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    ring?:  {
      __typename: "Ring",
      id: string,
      name: string,
      displayOrder?: number | null,
      organizationId?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventRingMutationVariables = {
  input: UpdateEventRingInput,
  condition?: ModelEventRingConditionInput | null,
};

export type UpdateEventRingMutation = {
  updateEventRing?:  {
    __typename: "EventRing",
    id: string,
    name: string,
    eventId: string,
    ringId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    ring?:  {
      __typename: "Ring",
      id: string,
      name: string,
      displayOrder?: number | null,
      organizationId?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventRingMutationVariables = {
  input: DeleteEventRingInput,
  condition?: ModelEventRingConditionInput | null,
};

export type DeleteEventRingMutation = {
  deleteEventRing?:  {
    __typename: "EventRing",
    id: string,
    name: string,
    eventId: string,
    ringId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    ring?:  {
      __typename: "Ring",
      id: string,
      name: string,
      displayOrder?: number | null,
      organizationId?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventRulesMutationVariables = {
  input: CreateEventRulesInput,
  condition?: ModelEventRulesConditionInput | null,
};

export type CreateEventRulesMutation = {
  createEventRules?:  {
    __typename: "EventRules",
    id: string,
    eventId: string,
    title?: string | null,
    text?: string | null,
    requiresAgreementPerEntry?: boolean | null,
    agreements?:  {
      __typename: "ModelEventRulesAgreementConnection",
      items:  Array< {
        __typename: "EventRulesAgreement",
        id: string,
        eventId: string,
        eventRuleId: string,
        eventEntryId?: string | null,
        name?: string | null,
        personId?: string | null,
        personType?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventRulesMutationVariables = {
  input: UpdateEventRulesInput,
  condition?: ModelEventRulesConditionInput | null,
};

export type UpdateEventRulesMutation = {
  updateEventRules?:  {
    __typename: "EventRules",
    id: string,
    eventId: string,
    title?: string | null,
    text?: string | null,
    requiresAgreementPerEntry?: boolean | null,
    agreements?:  {
      __typename: "ModelEventRulesAgreementConnection",
      items:  Array< {
        __typename: "EventRulesAgreement",
        id: string,
        eventId: string,
        eventRuleId: string,
        eventEntryId?: string | null,
        name?: string | null,
        personId?: string | null,
        personType?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventRulesMutationVariables = {
  input: DeleteEventRulesInput,
  condition?: ModelEventRulesConditionInput | null,
};

export type DeleteEventRulesMutation = {
  deleteEventRules?:  {
    __typename: "EventRules",
    id: string,
    eventId: string,
    title?: string | null,
    text?: string | null,
    requiresAgreementPerEntry?: boolean | null,
    agreements?:  {
      __typename: "ModelEventRulesAgreementConnection",
      items:  Array< {
        __typename: "EventRulesAgreement",
        id: string,
        eventId: string,
        eventRuleId: string,
        eventEntryId?: string | null,
        name?: string | null,
        personId?: string | null,
        personType?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventRulesAgreementMutationVariables = {
  input: CreateEventRulesAgreementInput,
  condition?: ModelEventRulesAgreementConditionInput | null,
};

export type CreateEventRulesAgreementMutation = {
  createEventRulesAgreement?:  {
    __typename: "EventRulesAgreement",
    id: string,
    eventId: string,
    eventRuleId: string,
    eventRule?:  {
      __typename: "EventRules",
      id: string,
      eventId: string,
      title?: string | null,
      text?: string | null,
      requiresAgreementPerEntry?: boolean | null,
      agreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventEntryId?: string | null,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    name?: string | null,
    personId?: string | null,
    personType?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventRulesAgreementMutationVariables = {
  input: UpdateEventRulesAgreementInput,
  condition?: ModelEventRulesAgreementConditionInput | null,
};

export type UpdateEventRulesAgreementMutation = {
  updateEventRulesAgreement?:  {
    __typename: "EventRulesAgreement",
    id: string,
    eventId: string,
    eventRuleId: string,
    eventRule?:  {
      __typename: "EventRules",
      id: string,
      eventId: string,
      title?: string | null,
      text?: string | null,
      requiresAgreementPerEntry?: boolean | null,
      agreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventEntryId?: string | null,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    name?: string | null,
    personId?: string | null,
    personType?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventRulesAgreementMutationVariables = {
  input: DeleteEventRulesAgreementInput,
  condition?: ModelEventRulesAgreementConditionInput | null,
};

export type DeleteEventRulesAgreementMutation = {
  deleteEventRulesAgreement?:  {
    __typename: "EventRulesAgreement",
    id: string,
    eventId: string,
    eventRuleId: string,
    eventRule?:  {
      __typename: "EventRules",
      id: string,
      eventId: string,
      title?: string | null,
      text?: string | null,
      requiresAgreementPerEntry?: boolean | null,
      agreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventEntryId?: string | null,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    name?: string | null,
    personId?: string | null,
    personType?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventScratchMutationVariables = {
  input: CreateEventScratchInput,
  condition?: ModelEventScratchConditionInput | null,
};

export type CreateEventScratchMutation = {
  createEventScratch?:  {
    __typename: "EventScratch",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventScratchMutationVariables = {
  input: UpdateEventScratchInput,
  condition?: ModelEventScratchConditionInput | null,
};

export type UpdateEventScratchMutation = {
  updateEventScratch?:  {
    __typename: "EventScratch",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventScratchMutationVariables = {
  input: DeleteEventScratchInput,
  condition?: ModelEventScratchConditionInput | null,
};

export type DeleteEventScratchMutation = {
  deleteEventScratch?:  {
    __typename: "EventScratch",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventStallSetMutationVariables = {
  input: CreateEventStallSetInput,
  condition?: ModelEventStallSetConditionInput | null,
};

export type CreateEventStallSetMutation = {
  createEventStallSet?:  {
    __typename: "EventStallSet",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationStallSetId?: string | null,
    name?: string | null,
    location?: string | null,
    available?: string | null,
    totalAvailable?: number | null,
    used?: string | null,
    totalUsed?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventStallSetMutationVariables = {
  input: UpdateEventStallSetInput,
  condition?: ModelEventStallSetConditionInput | null,
};

export type UpdateEventStallSetMutation = {
  updateEventStallSet?:  {
    __typename: "EventStallSet",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationStallSetId?: string | null,
    name?: string | null,
    location?: string | null,
    available?: string | null,
    totalAvailable?: number | null,
    used?: string | null,
    totalUsed?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventStallSetMutationVariables = {
  input: DeleteEventStallSetInput,
  condition?: ModelEventStallSetConditionInput | null,
};

export type DeleteEventStallSetMutation = {
  deleteEventStallSet?:  {
    __typename: "EventStallSet",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationStallSetId?: string | null,
    name?: string | null,
    location?: string | null,
    available?: string | null,
    totalAvailable?: number | null,
    used?: string | null,
    totalUsed?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateEventStallTypeMutationVariables = {
  input: CreateEventStallTypeInput,
  condition?: ModelEventStallTypeConditionInput | null,
};

export type CreateEventStallTypeMutation = {
  createEventStallType?:  {
    __typename: "EventStallType",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationStallTypeId?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerStall?: string | null,
    taxPerStall?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateEventStallTypeMutationVariables = {
  input: UpdateEventStallTypeInput,
  condition?: ModelEventStallTypeConditionInput | null,
};

export type UpdateEventStallTypeMutation = {
  updateEventStallType?:  {
    __typename: "EventStallType",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationStallTypeId?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerStall?: string | null,
    taxPerStall?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteEventStallTypeMutationVariables = {
  input: DeleteEventStallTypeInput,
  condition?: ModelEventStallTypeConditionInput | null,
};

export type DeleteEventStallTypeMutation = {
  deleteEventStallType?:  {
    __typename: "EventStallType",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationStallTypeId?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerStall?: string | null,
    taxPerStall?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateFeeMutationVariables = {
  input: CreateFeeInput,
  condition?: ModelFeeConditionInput | null,
};

export type CreateFeeMutation = {
  createFee?:  {
    __typename: "Fee",
    id: string,
    name: string,
    description?: string | null,
    createdBy: string,
    organizationId?: string | null,
    stripeId?: string | null,
    altId?: string | null,
    isFixed?: boolean | null,
    isPercentage?: boolean | null,
    totalPrice?: number | null,
    quantity?: number | null,
    isPerEntry?: boolean | null,
    isPerHorse?: boolean | null,
    isPerRider?: boolean | null,
    isPerClass?: boolean | null,
    isPerTrainer?: boolean | null,
    isRefundable?: boolean | null,
    isChargedAtTimeOfEntry?: boolean | null,
    isStartedOnEntryDate?: boolean | null,
    isHiddenFromEntryForm?: boolean | null,
    startDate?: string | null,
    startTime?: string | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceFeesId?: string | null,
    feeTaxRateAId?: string | null,
    feeTaxRateBId?: string | null,
  } | null,
};

export type UpdateFeeMutationVariables = {
  input: UpdateFeeInput,
  condition?: ModelFeeConditionInput | null,
};

export type UpdateFeeMutation = {
  updateFee?:  {
    __typename: "Fee",
    id: string,
    name: string,
    description?: string | null,
    createdBy: string,
    organizationId?: string | null,
    stripeId?: string | null,
    altId?: string | null,
    isFixed?: boolean | null,
    isPercentage?: boolean | null,
    totalPrice?: number | null,
    quantity?: number | null,
    isPerEntry?: boolean | null,
    isPerHorse?: boolean | null,
    isPerRider?: boolean | null,
    isPerClass?: boolean | null,
    isPerTrainer?: boolean | null,
    isRefundable?: boolean | null,
    isChargedAtTimeOfEntry?: boolean | null,
    isStartedOnEntryDate?: boolean | null,
    isHiddenFromEntryForm?: boolean | null,
    startDate?: string | null,
    startTime?: string | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceFeesId?: string | null,
    feeTaxRateAId?: string | null,
    feeTaxRateBId?: string | null,
  } | null,
};

export type DeleteFeeMutationVariables = {
  input: DeleteFeeInput,
  condition?: ModelFeeConditionInput | null,
};

export type DeleteFeeMutation = {
  deleteFee?:  {
    __typename: "Fee",
    id: string,
    name: string,
    description?: string | null,
    createdBy: string,
    organizationId?: string | null,
    stripeId?: string | null,
    altId?: string | null,
    isFixed?: boolean | null,
    isPercentage?: boolean | null,
    totalPrice?: number | null,
    quantity?: number | null,
    isPerEntry?: boolean | null,
    isPerHorse?: boolean | null,
    isPerRider?: boolean | null,
    isPerClass?: boolean | null,
    isPerTrainer?: boolean | null,
    isRefundable?: boolean | null,
    isChargedAtTimeOfEntry?: boolean | null,
    isStartedOnEntryDate?: boolean | null,
    isHiddenFromEntryForm?: boolean | null,
    startDate?: string | null,
    startTime?: string | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceFeesId?: string | null,
    feeTaxRateAId?: string | null,
    feeTaxRateBId?: string | null,
  } | null,
};

export type CreateFeedbackMutationVariables = {
  input: CreateFeedbackInput,
  condition?: ModelFeedbackConditionInput | null,
};

export type CreateFeedbackMutation = {
  createFeedback?:  {
    __typename: "Feedback",
    id: string,
    clinicianId?: string | null,
    personId?: string | null,
    technical?: string | null,
    style?: string | null,
    turnout?: string | null,
    general?: string | null,
    score?: number | null,
    status?: FeedbackStatus | null,
    submissionFeedback?:  {
      __typename: "SubmissionFeedback",
      id: string,
      type?: string | null,
      submission?:  {
        __typename: "Submission",
        id: string,
        title: string,
        description?: string | null,
        videoLength?: string | null,
        riderId?: string | null,
        personId?: string | null,
        totalPrice: number,
        status?: SubmissionStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
      } | null,
      submissionId: string,
      feedback:  {
        __typename: "Feedback",
        id: string,
        clinicianId?: string | null,
        personId?: string | null,
        technical?: string | null,
        style?: string | null,
        turnout?: string | null,
        general?: string | null,
        score?: number | null,
        status?: FeedbackStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        feedbackSubmissionFeedbackId?: string | null,
      },
      feedbackId: string,
      personId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      clinician?:  {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null,
      clinicianId?: string | null,
      submissionStatus?: SubmissionStatus | null,
      feedbackStatus?: FeedbackStatus | null,
      clinicianAmount?: number | null,
      declineReason?: string | null,
      permissionToPost?: boolean | null,
      dateSubmitted?: string | null,
      dateCompleted?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      submissionFeedbackClinicianId?: string | null,
    } | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    feedbackSubmissionFeedbackId?: string | null,
  } | null,
};

export type UpdateFeedbackMutationVariables = {
  input: UpdateFeedbackInput,
  condition?: ModelFeedbackConditionInput | null,
};

export type UpdateFeedbackMutation = {
  updateFeedback?:  {
    __typename: "Feedback",
    id: string,
    clinicianId?: string | null,
    personId?: string | null,
    technical?: string | null,
    style?: string | null,
    turnout?: string | null,
    general?: string | null,
    score?: number | null,
    status?: FeedbackStatus | null,
    submissionFeedback?:  {
      __typename: "SubmissionFeedback",
      id: string,
      type?: string | null,
      submission?:  {
        __typename: "Submission",
        id: string,
        title: string,
        description?: string | null,
        videoLength?: string | null,
        riderId?: string | null,
        personId?: string | null,
        totalPrice: number,
        status?: SubmissionStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
      } | null,
      submissionId: string,
      feedback:  {
        __typename: "Feedback",
        id: string,
        clinicianId?: string | null,
        personId?: string | null,
        technical?: string | null,
        style?: string | null,
        turnout?: string | null,
        general?: string | null,
        score?: number | null,
        status?: FeedbackStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        feedbackSubmissionFeedbackId?: string | null,
      },
      feedbackId: string,
      personId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      clinician?:  {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null,
      clinicianId?: string | null,
      submissionStatus?: SubmissionStatus | null,
      feedbackStatus?: FeedbackStatus | null,
      clinicianAmount?: number | null,
      declineReason?: string | null,
      permissionToPost?: boolean | null,
      dateSubmitted?: string | null,
      dateCompleted?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      submissionFeedbackClinicianId?: string | null,
    } | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    feedbackSubmissionFeedbackId?: string | null,
  } | null,
};

export type DeleteFeedbackMutationVariables = {
  input: DeleteFeedbackInput,
  condition?: ModelFeedbackConditionInput | null,
};

export type DeleteFeedbackMutation = {
  deleteFeedback?:  {
    __typename: "Feedback",
    id: string,
    clinicianId?: string | null,
    personId?: string | null,
    technical?: string | null,
    style?: string | null,
    turnout?: string | null,
    general?: string | null,
    score?: number | null,
    status?: FeedbackStatus | null,
    submissionFeedback?:  {
      __typename: "SubmissionFeedback",
      id: string,
      type?: string | null,
      submission?:  {
        __typename: "Submission",
        id: string,
        title: string,
        description?: string | null,
        videoLength?: string | null,
        riderId?: string | null,
        personId?: string | null,
        totalPrice: number,
        status?: SubmissionStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
      } | null,
      submissionId: string,
      feedback:  {
        __typename: "Feedback",
        id: string,
        clinicianId?: string | null,
        personId?: string | null,
        technical?: string | null,
        style?: string | null,
        turnout?: string | null,
        general?: string | null,
        score?: number | null,
        status?: FeedbackStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        feedbackSubmissionFeedbackId?: string | null,
      },
      feedbackId: string,
      personId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      clinician?:  {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null,
      clinicianId?: string | null,
      submissionStatus?: SubmissionStatus | null,
      feedbackStatus?: FeedbackStatus | null,
      clinicianAmount?: number | null,
      declineReason?: string | null,
      permissionToPost?: boolean | null,
      dateSubmitted?: string | null,
      dateCompleted?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      submissionFeedbackClinicianId?: string | null,
    } | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    feedbackSubmissionFeedbackId?: string | null,
  } | null,
};

export type CreateGameInputMutationVariables = {
  input: CreateGameInputInput,
  condition?: ModelGameInputConditionInput | null,
};

export type CreateGameInputMutation = {
  createGameInput?:  {
    __typename: "GameInput",
    id: string,
    eventId?: string | null,
    eventClassId?: string | null,
    backNumber?: string | null,
    round?: string | null,
    score?: number | null,
    isMasterRecord: boolean,
    createdBy: string,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateGameInputMutationVariables = {
  input: UpdateGameInputInput,
  condition?: ModelGameInputConditionInput | null,
};

export type UpdateGameInputMutation = {
  updateGameInput?:  {
    __typename: "GameInput",
    id: string,
    eventId?: string | null,
    eventClassId?: string | null,
    backNumber?: string | null,
    round?: string | null,
    score?: number | null,
    isMasterRecord: boolean,
    createdBy: string,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteGameInputMutationVariables = {
  input: DeleteGameInputInput,
  condition?: ModelGameInputConditionInput | null,
};

export type DeleteGameInputMutation = {
  deleteGameInput?:  {
    __typename: "GameInput",
    id: string,
    eventId?: string | null,
    eventClassId?: string | null,
    backNumber?: string | null,
    round?: string | null,
    score?: number | null,
    isMasterRecord: boolean,
    createdBy: string,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateHorseMutationVariables = {
  input: CreateHorseInput,
  condition?: ModelHorseConditionInput | null,
};

export type CreateHorseMutation = {
  createHorse?:  {
    __typename: "Horse",
    id: string,
    name: string,
    nickname?: string | null,
    previousNames?: Array< string | null > | null,
    personId?: string | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    ownerName: string,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    microchipNumber?: string | null,
    passportNumber?: string | null,
    dateOfBirth?: string | null,
    breed?: string | null,
    sex?: string | null,
    color?: string | null,
    markings?: string | null,
    height?: string | null,
    foalState?: string | null,
    cogginsNumber?: string | null,
    cogginsDate?: string | null,
    cogginsState?: string | null,
    coggins?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    highestLevelCompeted?: string | null,
    greenEligibility?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateHorseMutationVariables = {
  input: UpdateHorseInput,
  condition?: ModelHorseConditionInput | null,
};

export type UpdateHorseMutation = {
  updateHorse?:  {
    __typename: "Horse",
    id: string,
    name: string,
    nickname?: string | null,
    previousNames?: Array< string | null > | null,
    personId?: string | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    ownerName: string,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    microchipNumber?: string | null,
    passportNumber?: string | null,
    dateOfBirth?: string | null,
    breed?: string | null,
    sex?: string | null,
    color?: string | null,
    markings?: string | null,
    height?: string | null,
    foalState?: string | null,
    cogginsNumber?: string | null,
    cogginsDate?: string | null,
    cogginsState?: string | null,
    coggins?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    highestLevelCompeted?: string | null,
    greenEligibility?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteHorseMutationVariables = {
  input: DeleteHorseInput,
  condition?: ModelHorseConditionInput | null,
};

export type DeleteHorseMutation = {
  deleteHorse?:  {
    __typename: "Horse",
    id: string,
    name: string,
    nickname?: string | null,
    previousNames?: Array< string | null > | null,
    personId?: string | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    ownerName: string,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    microchipNumber?: string | null,
    passportNumber?: string | null,
    dateOfBirth?: string | null,
    breed?: string | null,
    sex?: string | null,
    color?: string | null,
    markings?: string | null,
    height?: string | null,
    foalState?: string | null,
    cogginsNumber?: string | null,
    cogginsDate?: string | null,
    cogginsState?: string | null,
    coggins?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    highestLevelCompeted?: string | null,
    greenEligibility?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreatePedigreeMutationVariables = {
  input: CreatePedigreeInput,
  condition?: ModelPedigreeConditionInput | null,
};

export type CreatePedigreeMutation = {
  createPedigree?:  {
    __typename: "Pedigree",
    id: string,
    horseId: string,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    sireId?: string | null,
    sireName?: string | null,
    sire?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    damId?: string | null,
    damName?: string | null,
    dam?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    birthplace?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    breederName?: string | null,
    breederContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    breederId?: string | null,
    breedRegistryInfo?: string | null,
    createdOn: string,
    updatedOn: string,
    pedigreeBirthplaceId?: string | null,
    pedigreeBreederContactId?: string | null,
  } | null,
};

export type UpdatePedigreeMutationVariables = {
  input: UpdatePedigreeInput,
  condition?: ModelPedigreeConditionInput | null,
};

export type UpdatePedigreeMutation = {
  updatePedigree?:  {
    __typename: "Pedigree",
    id: string,
    horseId: string,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    sireId?: string | null,
    sireName?: string | null,
    sire?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    damId?: string | null,
    damName?: string | null,
    dam?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    birthplace?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    breederName?: string | null,
    breederContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    breederId?: string | null,
    breedRegistryInfo?: string | null,
    createdOn: string,
    updatedOn: string,
    pedigreeBirthplaceId?: string | null,
    pedigreeBreederContactId?: string | null,
  } | null,
};

export type DeletePedigreeMutationVariables = {
  input: DeletePedigreeInput,
  condition?: ModelPedigreeConditionInput | null,
};

export type DeletePedigreeMutation = {
  deletePedigree?:  {
    __typename: "Pedigree",
    id: string,
    horseId: string,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    sireId?: string | null,
    sireName?: string | null,
    sire?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    damId?: string | null,
    damName?: string | null,
    dam?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    birthplace?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    breederName?: string | null,
    breederContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    breederId?: string | null,
    breedRegistryInfo?: string | null,
    createdOn: string,
    updatedOn: string,
    pedigreeBirthplaceId?: string | null,
    pedigreeBreederContactId?: string | null,
  } | null,
};

export type CreateMeasurementRecordMutationVariables = {
  input: CreateMeasurementRecordInput,
  condition?: ModelMeasurementRecordConditionInput | null,
};

export type CreateMeasurementRecordMutation = {
  createMeasurementRecord?:  {
    __typename: "MeasurementRecord",
    id: string,
    horseId: string,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    date?: string | null,
    status?: string | null,
    height?: string | null,
    heightOfHeel?: string | null,
    shoeingStatus?: string | null,
    steward?: string | null,
    official?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateMeasurementRecordMutationVariables = {
  input: UpdateMeasurementRecordInput,
  condition?: ModelMeasurementRecordConditionInput | null,
};

export type UpdateMeasurementRecordMutation = {
  updateMeasurementRecord?:  {
    __typename: "MeasurementRecord",
    id: string,
    horseId: string,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    date?: string | null,
    status?: string | null,
    height?: string | null,
    heightOfHeel?: string | null,
    shoeingStatus?: string | null,
    steward?: string | null,
    official?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteMeasurementRecordMutationVariables = {
  input: DeleteMeasurementRecordInput,
  condition?: ModelMeasurementRecordConditionInput | null,
};

export type DeleteMeasurementRecordMutation = {
  deleteMeasurementRecord?:  {
    __typename: "MeasurementRecord",
    id: string,
    horseId: string,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    date?: string | null,
    status?: string | null,
    height?: string | null,
    heightOfHeel?: string | null,
    shoeingStatus?: string | null,
    steward?: string | null,
    official?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateHorseShowMutationVariables = {
  input: CreateHorseShowInput,
  condition?: ModelHorseShowConditionInput | null,
};

export type CreateHorseShowMutation = {
  createHorseShow?:  {
    __typename: "HorseShow",
    id: string,
    name: string,
    nickname?: string | null,
    eventId?: string | null,
    ratings?:  Array< {
      __typename: "HorseShowRating",
      id: string,
      eventId: string,
      governingBody?: string | null,
      rating?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    judges?:  Array< {
      __typename: "EventJudge",
      id: string,
      name: string,
      eventId: string,
      personId?: string | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateHorseShowMutationVariables = {
  input: UpdateHorseShowInput,
  condition?: ModelHorseShowConditionInput | null,
};

export type UpdateHorseShowMutation = {
  updateHorseShow?:  {
    __typename: "HorseShow",
    id: string,
    name: string,
    nickname?: string | null,
    eventId?: string | null,
    ratings?:  Array< {
      __typename: "HorseShowRating",
      id: string,
      eventId: string,
      governingBody?: string | null,
      rating?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    judges?:  Array< {
      __typename: "EventJudge",
      id: string,
      name: string,
      eventId: string,
      personId?: string | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteHorseShowMutationVariables = {
  input: DeleteHorseShowInput,
  condition?: ModelHorseShowConditionInput | null,
};

export type DeleteHorseShowMutation = {
  deleteHorseShow?:  {
    __typename: "HorseShow",
    id: string,
    name: string,
    nickname?: string | null,
    eventId?: string | null,
    ratings?:  Array< {
      __typename: "HorseShowRating",
      id: string,
      eventId: string,
      governingBody?: string | null,
      rating?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    judges?:  Array< {
      __typename: "EventJudge",
      id: string,
      name: string,
      eventId: string,
      personId?: string | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateHorseShowRatingMutationVariables = {
  input: CreateHorseShowRatingInput,
  condition?: ModelHorseShowRatingConditionInput | null,
};

export type CreateHorseShowRatingMutation = {
  createHorseShowRating?:  {
    __typename: "HorseShowRating",
    id: string,
    eventId: string,
    governingBody?: string | null,
    rating?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateHorseShowRatingMutationVariables = {
  input: UpdateHorseShowRatingInput,
  condition?: ModelHorseShowRatingConditionInput | null,
};

export type UpdateHorseShowRatingMutation = {
  updateHorseShowRating?:  {
    __typename: "HorseShowRating",
    id: string,
    eventId: string,
    governingBody?: string | null,
    rating?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteHorseShowRatingMutationVariables = {
  input: DeleteHorseShowRatingInput,
  condition?: ModelHorseShowRatingConditionInput | null,
};

export type DeleteHorseShowRatingMutation = {
  deleteHorseShowRating?:  {
    __typename: "HorseShowRating",
    id: string,
    eventId: string,
    governingBody?: string | null,
    rating?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateInvitationMutationVariables = {
  input: CreateInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type CreateInvitationMutation = {
  createInvitation?:  {
    __typename: "Invitation",
    id: string,
    createdBy: string,
    creatorEmail?: string | null,
    invitee?: string | null,
    inviteeEmail?: string | null,
    status?: InvitationStatus | null,
    organizationId?: string | null,
    barnId?: string | null,
    permissionLevel?: string | null,
    roles?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateInvitationMutationVariables = {
  input: UpdateInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type UpdateInvitationMutation = {
  updateInvitation?:  {
    __typename: "Invitation",
    id: string,
    createdBy: string,
    creatorEmail?: string | null,
    invitee?: string | null,
    inviteeEmail?: string | null,
    status?: InvitationStatus | null,
    organizationId?: string | null,
    barnId?: string | null,
    permissionLevel?: string | null,
    roles?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteInvitationMutationVariables = {
  input: DeleteInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type DeleteInvitationMutation = {
  deleteInvitation?:  {
    __typename: "Invitation",
    id: string,
    createdBy: string,
    creatorEmail?: string | null,
    invitee?: string | null,
    inviteeEmail?: string | null,
    status?: InvitationStatus | null,
    organizationId?: string | null,
    barnId?: string | null,
    permissionLevel?: string | null,
    roles?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateMembershipMutationVariables = {
  input: CreateMembershipInput,
  condition?: ModelMembershipConditionInput | null,
};

export type CreateMembershipMutation = {
  createMembership?:  {
    __typename: "Membership",
    id: string,
    name: string,
    description?: string | null,
    membershipId: string,
    backNumber?: number | null,
    membershipStatus?: string | null,
    amountPaid?: number | null,
    organizationMembershipTypeId?: string | null,
    organizationMembershipType?:  {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    } | null,
    type?: string | null,
    personName?: string | null,
    personId: string,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    horseId?: string | null,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    teamId?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationName?: string | null,
    isJunior?: boolean | null,
    isProfessional?: boolean | null,
    dateMembershipEnds?: string | null,
    volunteerHours?: number | null,
    meetingsAttended?: number | null,
    showsAttended?: number | null,
    personEmail?: string | null,
    gradeLevel?: string | null,
    aelDivision?: string | null,
    aelTeamRole?: TeamRole | null,
    documents?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    organizationAgreements?:  Array< {
      __typename: "OrganizationAgreements",
      organizationRuleId?: string | null,
      organizationRuleTitle: string,
      firstName: string,
      lastName: string,
      personId?: string | null,
      createdOn: string,
      updatedOn?: string | null,
    } | null > | null,
    contactPersonId?: string | null,
    groupContactId?: string | null,
    groupContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    groupLeaderContactId?: string | null,
    groupLeaderContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    personMembershipsId?: string | null,
    membershipOrganizationId?: string | null,
  } | null,
};

export type UpdateMembershipMutationVariables = {
  input: UpdateMembershipInput,
  condition?: ModelMembershipConditionInput | null,
};

export type UpdateMembershipMutation = {
  updateMembership?:  {
    __typename: "Membership",
    id: string,
    name: string,
    description?: string | null,
    membershipId: string,
    backNumber?: number | null,
    membershipStatus?: string | null,
    amountPaid?: number | null,
    organizationMembershipTypeId?: string | null,
    organizationMembershipType?:  {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    } | null,
    type?: string | null,
    personName?: string | null,
    personId: string,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    horseId?: string | null,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    teamId?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationName?: string | null,
    isJunior?: boolean | null,
    isProfessional?: boolean | null,
    dateMembershipEnds?: string | null,
    volunteerHours?: number | null,
    meetingsAttended?: number | null,
    showsAttended?: number | null,
    personEmail?: string | null,
    gradeLevel?: string | null,
    aelDivision?: string | null,
    aelTeamRole?: TeamRole | null,
    documents?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    organizationAgreements?:  Array< {
      __typename: "OrganizationAgreements",
      organizationRuleId?: string | null,
      organizationRuleTitle: string,
      firstName: string,
      lastName: string,
      personId?: string | null,
      createdOn: string,
      updatedOn?: string | null,
    } | null > | null,
    contactPersonId?: string | null,
    groupContactId?: string | null,
    groupContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    groupLeaderContactId?: string | null,
    groupLeaderContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    personMembershipsId?: string | null,
    membershipOrganizationId?: string | null,
  } | null,
};

export type DeleteMembershipMutationVariables = {
  input: DeleteMembershipInput,
  condition?: ModelMembershipConditionInput | null,
};

export type DeleteMembershipMutation = {
  deleteMembership?:  {
    __typename: "Membership",
    id: string,
    name: string,
    description?: string | null,
    membershipId: string,
    backNumber?: number | null,
    membershipStatus?: string | null,
    amountPaid?: number | null,
    organizationMembershipTypeId?: string | null,
    organizationMembershipType?:  {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    } | null,
    type?: string | null,
    personName?: string | null,
    personId: string,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    horseId?: string | null,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    teamId?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationName?: string | null,
    isJunior?: boolean | null,
    isProfessional?: boolean | null,
    dateMembershipEnds?: string | null,
    volunteerHours?: number | null,
    meetingsAttended?: number | null,
    showsAttended?: number | null,
    personEmail?: string | null,
    gradeLevel?: string | null,
    aelDivision?: string | null,
    aelTeamRole?: TeamRole | null,
    documents?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    organizationAgreements?:  Array< {
      __typename: "OrganizationAgreements",
      organizationRuleId?: string | null,
      organizationRuleTitle: string,
      firstName: string,
      lastName: string,
      personId?: string | null,
      createdOn: string,
      updatedOn?: string | null,
    } | null > | null,
    contactPersonId?: string | null,
    groupContactId?: string | null,
    groupContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    groupLeaderContactId?: string | null,
    groupLeaderContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    personMembershipsId?: string | null,
    membershipOrganizationId?: string | null,
  } | null,
};

export type CreateOrganizationMembershipTypeMutationVariables = {
  input: CreateOrganizationMembershipTypeInput,
  condition?: ModelOrganizationMembershipTypeConditionInput | null,
};

export type CreateOrganizationMembershipTypeMutation = {
  createOrganizationMembershipType?:  {
    __typename: "OrganizationMembershipType",
    id: string,
    name: string,
    description?: string | null,
    order?: number | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    membershipIdValues?: string | null,
    nextAvailableMembershipId?: string | null,
    price?: number | null,
    category?: MembershipTypeCategory | null,
    schedule?: MembershipTypeSchedule | null,
    isStartAtTimeOfPurchase?: boolean | null,
    setStartDate?: string | null,
    acceptOnlineApplications?: string | null,
    applicationFields?:  {
      __typename: "ApplicationFields",
      firstName?: boolean | null,
      lastName?: boolean | null,
      dateOfBirth?: boolean | null,
      contact?: boolean | null,
      gradeLevel?: boolean | null,
      barnName?: boolean | null,
      teamName?: boolean | null,
      backNumber?: boolean | null,
      groupName?: boolean | null,
      groupLeader?: boolean | null,
      groupLeaderContactInfo?: boolean | null,
      collectMemberDetailsTogether?: boolean | null,
      maxNumberOfGroupMembers?: number | null,
      isTeam?: boolean | null,
      horseName?: boolean | null,
      horseHeight?: boolean | null,
      horseOwnerName?: boolean | null,
      cogginsInfo?: boolean | null,
      cogginsUpload?: boolean | null,
    } | null,
    applicationOptions?:  {
      __typename: "ApplicationOptions",
      requireApproval?: boolean | null,
      autoAssignIds?: boolean | null,
      acceptRSPPayment?: boolean | null,
      acceptOtherPayment?: boolean | null,
    } | null,
    organizationDocuments?:  Array< {
      __typename: "OrganizationDocument",
      id: string,
      name: string,
    } | null > | null,
    organizationRules?:  Array< {
      __typename: "OrganizationRule",
      id: string,
      title: string,
      body: string,
      isRequired?: boolean | null,
    } | null > | null,
    seasons?:  {
      __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
      items:  Array< {
        __typename: "OrganizationMembershipTypeSeasons",
        id: string,
        organizationMembershipTypeID: string,
        seasonID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdOn: string,
    updatedOn: string,
    organizationMembershipTypeOrganizationId?: string | null,
  } | null,
};

export type UpdateOrganizationMembershipTypeMutationVariables = {
  input: UpdateOrganizationMembershipTypeInput,
  condition?: ModelOrganizationMembershipTypeConditionInput | null,
};

export type UpdateOrganizationMembershipTypeMutation = {
  updateOrganizationMembershipType?:  {
    __typename: "OrganizationMembershipType",
    id: string,
    name: string,
    description?: string | null,
    order?: number | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    membershipIdValues?: string | null,
    nextAvailableMembershipId?: string | null,
    price?: number | null,
    category?: MembershipTypeCategory | null,
    schedule?: MembershipTypeSchedule | null,
    isStartAtTimeOfPurchase?: boolean | null,
    setStartDate?: string | null,
    acceptOnlineApplications?: string | null,
    applicationFields?:  {
      __typename: "ApplicationFields",
      firstName?: boolean | null,
      lastName?: boolean | null,
      dateOfBirth?: boolean | null,
      contact?: boolean | null,
      gradeLevel?: boolean | null,
      barnName?: boolean | null,
      teamName?: boolean | null,
      backNumber?: boolean | null,
      groupName?: boolean | null,
      groupLeader?: boolean | null,
      groupLeaderContactInfo?: boolean | null,
      collectMemberDetailsTogether?: boolean | null,
      maxNumberOfGroupMembers?: number | null,
      isTeam?: boolean | null,
      horseName?: boolean | null,
      horseHeight?: boolean | null,
      horseOwnerName?: boolean | null,
      cogginsInfo?: boolean | null,
      cogginsUpload?: boolean | null,
    } | null,
    applicationOptions?:  {
      __typename: "ApplicationOptions",
      requireApproval?: boolean | null,
      autoAssignIds?: boolean | null,
      acceptRSPPayment?: boolean | null,
      acceptOtherPayment?: boolean | null,
    } | null,
    organizationDocuments?:  Array< {
      __typename: "OrganizationDocument",
      id: string,
      name: string,
    } | null > | null,
    organizationRules?:  Array< {
      __typename: "OrganizationRule",
      id: string,
      title: string,
      body: string,
      isRequired?: boolean | null,
    } | null > | null,
    seasons?:  {
      __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
      items:  Array< {
        __typename: "OrganizationMembershipTypeSeasons",
        id: string,
        organizationMembershipTypeID: string,
        seasonID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdOn: string,
    updatedOn: string,
    organizationMembershipTypeOrganizationId?: string | null,
  } | null,
};

export type DeleteOrganizationMembershipTypeMutationVariables = {
  input: DeleteOrganizationMembershipTypeInput,
  condition?: ModelOrganizationMembershipTypeConditionInput | null,
};

export type DeleteOrganizationMembershipTypeMutation = {
  deleteOrganizationMembershipType?:  {
    __typename: "OrganizationMembershipType",
    id: string,
    name: string,
    description?: string | null,
    order?: number | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    membershipIdValues?: string | null,
    nextAvailableMembershipId?: string | null,
    price?: number | null,
    category?: MembershipTypeCategory | null,
    schedule?: MembershipTypeSchedule | null,
    isStartAtTimeOfPurchase?: boolean | null,
    setStartDate?: string | null,
    acceptOnlineApplications?: string | null,
    applicationFields?:  {
      __typename: "ApplicationFields",
      firstName?: boolean | null,
      lastName?: boolean | null,
      dateOfBirth?: boolean | null,
      contact?: boolean | null,
      gradeLevel?: boolean | null,
      barnName?: boolean | null,
      teamName?: boolean | null,
      backNumber?: boolean | null,
      groupName?: boolean | null,
      groupLeader?: boolean | null,
      groupLeaderContactInfo?: boolean | null,
      collectMemberDetailsTogether?: boolean | null,
      maxNumberOfGroupMembers?: number | null,
      isTeam?: boolean | null,
      horseName?: boolean | null,
      horseHeight?: boolean | null,
      horseOwnerName?: boolean | null,
      cogginsInfo?: boolean | null,
      cogginsUpload?: boolean | null,
    } | null,
    applicationOptions?:  {
      __typename: "ApplicationOptions",
      requireApproval?: boolean | null,
      autoAssignIds?: boolean | null,
      acceptRSPPayment?: boolean | null,
      acceptOtherPayment?: boolean | null,
    } | null,
    organizationDocuments?:  Array< {
      __typename: "OrganizationDocument",
      id: string,
      name: string,
    } | null > | null,
    organizationRules?:  Array< {
      __typename: "OrganizationRule",
      id: string,
      title: string,
      body: string,
      isRequired?: boolean | null,
    } | null > | null,
    seasons?:  {
      __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
      items:  Array< {
        __typename: "OrganizationMembershipTypeSeasons",
        id: string,
        organizationMembershipTypeID: string,
        seasonID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdOn: string,
    updatedOn: string,
    organizationMembershipTypeOrganizationId?: string | null,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    type?: OrganizationType | null,
    industry?: string | null,
    addresses?: Array< string | null > | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeId?: string | null,
    stripeCustomerId?: string | null,
    paymentMethodId?: string | null,
    website?: string | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    organizationOptions?:  {
      __typename: "OrganizationOptions",
      usesTextAlerts?: boolean | null,
      acceptsIncomingTextAlerts?: boolean | null,
    } | null,
    urlBackHalf?: string | null,
    colors?: Array< string | null > | null,
    logos?:  Array< {
      __typename: "Logo",
      logoURL?: string | null,
      key?: string | null,
      logo?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
    } | null > | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    type?: OrganizationType | null,
    industry?: string | null,
    addresses?: Array< string | null > | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeId?: string | null,
    stripeCustomerId?: string | null,
    paymentMethodId?: string | null,
    website?: string | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    organizationOptions?:  {
      __typename: "OrganizationOptions",
      usesTextAlerts?: boolean | null,
      acceptsIncomingTextAlerts?: boolean | null,
    } | null,
    urlBackHalf?: string | null,
    colors?: Array< string | null > | null,
    logos?:  Array< {
      __typename: "Logo",
      logoURL?: string | null,
      key?: string | null,
      logo?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
    } | null > | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    type?: OrganizationType | null,
    industry?: string | null,
    addresses?: Array< string | null > | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeId?: string | null,
    stripeCustomerId?: string | null,
    paymentMethodId?: string | null,
    website?: string | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    organizationOptions?:  {
      __typename: "OrganizationOptions",
      usesTextAlerts?: boolean | null,
      acceptsIncomingTextAlerts?: boolean | null,
    } | null,
    urlBackHalf?: string | null,
    colors?: Array< string | null > | null,
    logos?:  Array< {
      __typename: "Logo",
      logoURL?: string | null,
      key?: string | null,
      logo?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
    } | null > | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateOrganizationBeddingTypeMutationVariables = {
  input: CreateOrganizationBeddingTypeInput,
  condition?: ModelOrganizationBeddingTypeConditionInput | null,
};

export type CreateOrganizationBeddingTypeMutation = {
  createOrganizationBeddingType?:  {
    __typename: "OrganizationBeddingType",
    id: string,
    organizationId: string,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerBag?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateIdA?: string | null,
    taxRateIdB?: string | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateOrganizationBeddingTypeMutationVariables = {
  input: UpdateOrganizationBeddingTypeInput,
  condition?: ModelOrganizationBeddingTypeConditionInput | null,
};

export type UpdateOrganizationBeddingTypeMutation = {
  updateOrganizationBeddingType?:  {
    __typename: "OrganizationBeddingType",
    id: string,
    organizationId: string,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerBag?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateIdA?: string | null,
    taxRateIdB?: string | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteOrganizationBeddingTypeMutationVariables = {
  input: DeleteOrganizationBeddingTypeInput,
  condition?: ModelOrganizationBeddingTypeConditionInput | null,
};

export type DeleteOrganizationBeddingTypeMutation = {
  deleteOrganizationBeddingType?:  {
    __typename: "OrganizationBeddingType",
    id: string,
    organizationId: string,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerBag?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateIdA?: string | null,
    taxRateIdB?: string | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateOrganizationClassMutationVariables = {
  input: CreateOrganizationClassInput,
  condition?: ModelOrganizationClassConditionInput | null,
};

export type CreateOrganizationClassMutation = {
  createOrganizationClass?:  {
    __typename: "OrganizationClass",
    id: string,
    name: string,
    classId: string,
    class:  {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    },
    organizationId: string,
    organizationDivisionId?: string | null,
    hasChampionship?: boolean | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    number?: number | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    jumpHeight?: Array< string | null > | null,
    estimatedTimePerRound?: number | null,
    createdBy: string,
    displayOrder?: number | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    organizationClassPointTableId?: string | null,
    organizationClassPrizeMoneyTableId?: string | null,
  } | null,
};

export type UpdateOrganizationClassMutationVariables = {
  input: UpdateOrganizationClassInput,
  condition?: ModelOrganizationClassConditionInput | null,
};

export type UpdateOrganizationClassMutation = {
  updateOrganizationClass?:  {
    __typename: "OrganizationClass",
    id: string,
    name: string,
    classId: string,
    class:  {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    },
    organizationId: string,
    organizationDivisionId?: string | null,
    hasChampionship?: boolean | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    number?: number | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    jumpHeight?: Array< string | null > | null,
    estimatedTimePerRound?: number | null,
    createdBy: string,
    displayOrder?: number | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    organizationClassPointTableId?: string | null,
    organizationClassPrizeMoneyTableId?: string | null,
  } | null,
};

export type DeleteOrganizationClassMutationVariables = {
  input: DeleteOrganizationClassInput,
  condition?: ModelOrganizationClassConditionInput | null,
};

export type DeleteOrganizationClassMutation = {
  deleteOrganizationClass?:  {
    __typename: "OrganizationClass",
    id: string,
    name: string,
    classId: string,
    class:  {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    },
    organizationId: string,
    organizationDivisionId?: string | null,
    hasChampionship?: boolean | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    number?: number | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    jumpHeight?: Array< string | null > | null,
    estimatedTimePerRound?: number | null,
    createdBy: string,
    displayOrder?: number | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    organizationClassPointTableId?: string | null,
    organizationClassPrizeMoneyTableId?: string | null,
  } | null,
};

export type CreateOrganizationDivisionMutationVariables = {
  input: CreateOrganizationDivisionInput,
  condition?: ModelOrganizationDivisionConditionInput | null,
};

export type CreateOrganizationDivisionMutation = {
  createOrganizationDivision?:  {
    __typename: "OrganizationDivision",
    id: string,
    name: string,
    number?: number | null,
    divisionId: string,
    division:  {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    },
    organizationId: string,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    jumpHeight?: Array< string | null > | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    entryFeePerClass?: string | null,
    prizeMoneyPerClass?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    displayOrder?: number | null,
    createdOn: string,
    updatedOn: string,
    organizationDivisionPointTableId?: string | null,
    organizationDivisionPrizeMoneyTableId?: string | null,
  } | null,
};

export type UpdateOrganizationDivisionMutationVariables = {
  input: UpdateOrganizationDivisionInput,
  condition?: ModelOrganizationDivisionConditionInput | null,
};

export type UpdateOrganizationDivisionMutation = {
  updateOrganizationDivision?:  {
    __typename: "OrganizationDivision",
    id: string,
    name: string,
    number?: number | null,
    divisionId: string,
    division:  {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    },
    organizationId: string,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    jumpHeight?: Array< string | null > | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    entryFeePerClass?: string | null,
    prizeMoneyPerClass?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    displayOrder?: number | null,
    createdOn: string,
    updatedOn: string,
    organizationDivisionPointTableId?: string | null,
    organizationDivisionPrizeMoneyTableId?: string | null,
  } | null,
};

export type DeleteOrganizationDivisionMutationVariables = {
  input: DeleteOrganizationDivisionInput,
  condition?: ModelOrganizationDivisionConditionInput | null,
};

export type DeleteOrganizationDivisionMutation = {
  deleteOrganizationDivision?:  {
    __typename: "OrganizationDivision",
    id: string,
    name: string,
    number?: number | null,
    divisionId: string,
    division:  {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    },
    organizationId: string,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    jumpHeight?: Array< string | null > | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    entryFeePerClass?: string | null,
    prizeMoneyPerClass?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    displayOrder?: number | null,
    createdOn: string,
    updatedOn: string,
    organizationDivisionPointTableId?: string | null,
    organizationDivisionPrizeMoneyTableId?: string | null,
  } | null,
};

export type CreateOrganizationStallSetMutationVariables = {
  input: CreateOrganizationStallSetInput,
  condition?: ModelOrganizationStallSetConditionInput | null,
};

export type CreateOrganizationStallSetMutation = {
  createOrganizationStallSet?:  {
    __typename: "OrganizationStallSet",
    id: string,
    organizationId: string,
    name?: string | null,
    location?: string | null,
    available?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateOrganizationStallSetMutationVariables = {
  input: UpdateOrganizationStallSetInput,
  condition?: ModelOrganizationStallSetConditionInput | null,
};

export type UpdateOrganizationStallSetMutation = {
  updateOrganizationStallSet?:  {
    __typename: "OrganizationStallSet",
    id: string,
    organizationId: string,
    name?: string | null,
    location?: string | null,
    available?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteOrganizationStallSetMutationVariables = {
  input: DeleteOrganizationStallSetInput,
  condition?: ModelOrganizationStallSetConditionInput | null,
};

export type DeleteOrganizationStallSetMutation = {
  deleteOrganizationStallSet?:  {
    __typename: "OrganizationStallSet",
    id: string,
    organizationId: string,
    name?: string | null,
    location?: string | null,
    available?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateOrganizationStallTypeMutationVariables = {
  input: CreateOrganizationStallTypeInput,
  condition?: ModelOrganizationStallTypeConditionInput | null,
};

export type CreateOrganizationStallTypeMutation = {
  createOrganizationStallType?:  {
    __typename: "OrganizationStallType",
    id: string,
    organizationId: string,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerStall?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateIdA?: string | null,
    taxRateIdB?: string | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateOrganizationStallTypeMutationVariables = {
  input: UpdateOrganizationStallTypeInput,
  condition?: ModelOrganizationStallTypeConditionInput | null,
};

export type UpdateOrganizationStallTypeMutation = {
  updateOrganizationStallType?:  {
    __typename: "OrganizationStallType",
    id: string,
    organizationId: string,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerStall?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateIdA?: string | null,
    taxRateIdB?: string | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteOrganizationStallTypeMutationVariables = {
  input: DeleteOrganizationStallTypeInput,
  condition?: ModelOrganizationStallTypeConditionInput | null,
};

export type DeleteOrganizationStallTypeMutation = {
  deleteOrganizationStallType?:  {
    __typename: "OrganizationStallType",
    id: string,
    organizationId: string,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerStall?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateIdA?: string | null,
    taxRateIdB?: string | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateOwnerMutationVariables = {
  input: CreateOwnerInput,
  condition?: ModelOwnerConditionInput | null,
};

export type CreateOwnerMutation = {
  createOwner?:  {
    __typename: "Owner",
    id: string,
    name: string,
    type?: string | null,
    location?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnId?: string | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    ownerContactId?: string | null,
  } | null,
};

export type UpdateOwnerMutationVariables = {
  input: UpdateOwnerInput,
  condition?: ModelOwnerConditionInput | null,
};

export type UpdateOwnerMutation = {
  updateOwner?:  {
    __typename: "Owner",
    id: string,
    name: string,
    type?: string | null,
    location?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnId?: string | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    ownerContactId?: string | null,
  } | null,
};

export type DeleteOwnerMutationVariables = {
  input: DeleteOwnerInput,
  condition?: ModelOwnerConditionInput | null,
};

export type DeleteOwnerMutation = {
  deleteOwner?:  {
    __typename: "Owner",
    id: string,
    name: string,
    type?: string | null,
    location?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnId?: string | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    ownerContactId?: string | null,
  } | null,
};

export type CreatePayerMutationVariables = {
  input: CreatePayerInput,
  condition?: ModelPayerConditionInput | null,
};

export type CreatePayerMutation = {
  createPayer?:  {
    __typename: "Payer",
    id: string,
    name: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    paymentMethod?:  {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    payerContactId?: string | null,
    payerPaymentMethodId?: string | null,
  } | null,
};

export type UpdatePayerMutationVariables = {
  input: UpdatePayerInput,
  condition?: ModelPayerConditionInput | null,
};

export type UpdatePayerMutation = {
  updatePayer?:  {
    __typename: "Payer",
    id: string,
    name: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    paymentMethod?:  {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    payerContactId?: string | null,
    payerPaymentMethodId?: string | null,
  } | null,
};

export type DeletePayerMutationVariables = {
  input: DeletePayerInput,
  condition?: ModelPayerConditionInput | null,
};

export type DeletePayerMutation = {
  deletePayer?:  {
    __typename: "Payer",
    id: string,
    name: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    paymentMethod?:  {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    payerContactId?: string | null,
    payerPaymentMethodId?: string | null,
  } | null,
};

export type CreatePaymentMethodMutationVariables = {
  input: CreatePaymentMethodInput,
  condition?: ModelPaymentMethodConditionInput | null,
};

export type CreatePaymentMethodMutation = {
  createPaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    personId?: string | null,
    organizationId?: string | null,
    type?: PaymentMethodType | null,
    stripeId?: string | null,
    altId?: string | null,
    value?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdatePaymentMethodMutationVariables = {
  input: UpdatePaymentMethodInput,
  condition?: ModelPaymentMethodConditionInput | null,
};

export type UpdatePaymentMethodMutation = {
  updatePaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    personId?: string | null,
    organizationId?: string | null,
    type?: PaymentMethodType | null,
    stripeId?: string | null,
    altId?: string | null,
    value?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeletePaymentMethodMutationVariables = {
  input: DeletePaymentMethodInput,
  condition?: ModelPaymentMethodConditionInput | null,
};

export type DeletePaymentMethodMutation = {
  deletePaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    personId?: string | null,
    organizationId?: string | null,
    type?: PaymentMethodType | null,
    stripeId?: string | null,
    altId?: string | null,
    value?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreatePersonMutationVariables = {
  input: CreatePersonInput,
  condition?: ModelPersonConditionInput | null,
};

export type CreatePersonMutation = {
  createPerson?:  {
    __typename: "Person",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    isVerified?: boolean | null,
    roles?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    personalInformationId?: string | null,
    personalInformation?:  {
      __typename: "PersonalInformation",
      id: string,
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      dateOfBirth?: string | null,
      gender?: Gender | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pictureURL?: string | null,
    picture?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    disciplines?: string | null,
    stripeId?: string | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdatePersonMutationVariables = {
  input: UpdatePersonInput,
  condition?: ModelPersonConditionInput | null,
};

export type UpdatePersonMutation = {
  updatePerson?:  {
    __typename: "Person",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    isVerified?: boolean | null,
    roles?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    personalInformationId?: string | null,
    personalInformation?:  {
      __typename: "PersonalInformation",
      id: string,
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      dateOfBirth?: string | null,
      gender?: Gender | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pictureURL?: string | null,
    picture?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    disciplines?: string | null,
    stripeId?: string | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeletePersonMutationVariables = {
  input: DeletePersonInput,
  condition?: ModelPersonConditionInput | null,
};

export type DeletePersonMutation = {
  deletePerson?:  {
    __typename: "Person",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    isVerified?: boolean | null,
    roles?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    personalInformationId?: string | null,
    personalInformation?:  {
      __typename: "PersonalInformation",
      id: string,
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      dateOfBirth?: string | null,
      gender?: Gender | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pictureURL?: string | null,
    picture?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    disciplines?: string | null,
    stripeId?: string | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreatePersonNameMutationVariables = {
  input: CreatePersonNameInput,
  condition?: ModelPersonNameConditionInput | null,
};

export type CreatePersonNameMutation = {
  createPersonName?:  {
    __typename: "PersonName",
    id: string,
    personId: string,
    title?: Title | null,
    firstName?: string | null,
    middleName?: string | null,
    maidenName?: string | null,
    lastName?: string | null,
    suffix?: string | null,
    legalName?: string | null,
    displayName?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePersonNameMutationVariables = {
  input: UpdatePersonNameInput,
  condition?: ModelPersonNameConditionInput | null,
};

export type UpdatePersonNameMutation = {
  updatePersonName?:  {
    __typename: "PersonName",
    id: string,
    personId: string,
    title?: Title | null,
    firstName?: string | null,
    middleName?: string | null,
    maidenName?: string | null,
    lastName?: string | null,
    suffix?: string | null,
    legalName?: string | null,
    displayName?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePersonNameMutationVariables = {
  input: DeletePersonNameInput,
  condition?: ModelPersonNameConditionInput | null,
};

export type DeletePersonNameMutation = {
  deletePersonName?:  {
    __typename: "PersonName",
    id: string,
    personId: string,
    title?: Title | null,
    firstName?: string | null,
    middleName?: string | null,
    maidenName?: string | null,
    lastName?: string | null,
    suffix?: string | null,
    legalName?: string | null,
    displayName?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePersonalInformationMutationVariables = {
  input: CreatePersonalInformationInput,
  condition?: ModelPersonalInformationConditionInput | null,
};

export type CreatePersonalInformationMutation = {
  createPersonalInformation?:  {
    __typename: "PersonalInformation",
    id: string,
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    dateOfBirth?: string | null,
    gender?: Gender | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePersonalInformationMutationVariables = {
  input: UpdatePersonalInformationInput,
  condition?: ModelPersonalInformationConditionInput | null,
};

export type UpdatePersonalInformationMutation = {
  updatePersonalInformation?:  {
    __typename: "PersonalInformation",
    id: string,
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    dateOfBirth?: string | null,
    gender?: Gender | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePersonalInformationMutationVariables = {
  input: DeletePersonalInformationInput,
  condition?: ModelPersonalInformationConditionInput | null,
};

export type DeletePersonalInformationMutation = {
  deletePersonalInformation?:  {
    __typename: "PersonalInformation",
    id: string,
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    dateOfBirth?: string | null,
    gender?: Gender | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePointTableMutationVariables = {
  input: CreatePointTableInput,
  condition?: ModelPointTableConditionInput | null,
};

export type CreatePointTableMutation = {
  createPointTable?:  {
    __typename: "PointTable",
    id: string,
    name: string,
    description?: string | null,
    displayOrder?: number | null,
    organizationId?: string | null,
    discipline?: string | null,
    isAddValueToRegularFromEntryCount?: boolean | null,
    isAddValueToChampionshipFromEntryCount?: boolean | null,
    isUseChampionRule?: boolean | null,
    isUseReserveRule?: boolean | null,
    tiers?:  Array< {
      __typename: "PointTier",
      lowEntryCount?: number | null,
      highEntryCount?: number | null,
      first?: string | null,
      second?: string | null,
      third?: string | null,
      fourth?: string | null,
      fifth?: string | null,
      sixth?: string | null,
      seventh?: string | null,
      eighth?: string | null,
      ninth?: string | null,
      tenth?: string | null,
      eleventh?: string | null,
      twelfth?: string | null,
      champion?: string | null,
      reserve?: string | null,
    } | null > | null,
    numberEntriesToReceiveSpecialClassPoints?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventResultPointTablesId?: string | null,
  } | null,
};

export type UpdatePointTableMutationVariables = {
  input: UpdatePointTableInput,
  condition?: ModelPointTableConditionInput | null,
};

export type UpdatePointTableMutation = {
  updatePointTable?:  {
    __typename: "PointTable",
    id: string,
    name: string,
    description?: string | null,
    displayOrder?: number | null,
    organizationId?: string | null,
    discipline?: string | null,
    isAddValueToRegularFromEntryCount?: boolean | null,
    isAddValueToChampionshipFromEntryCount?: boolean | null,
    isUseChampionRule?: boolean | null,
    isUseReserveRule?: boolean | null,
    tiers?:  Array< {
      __typename: "PointTier",
      lowEntryCount?: number | null,
      highEntryCount?: number | null,
      first?: string | null,
      second?: string | null,
      third?: string | null,
      fourth?: string | null,
      fifth?: string | null,
      sixth?: string | null,
      seventh?: string | null,
      eighth?: string | null,
      ninth?: string | null,
      tenth?: string | null,
      eleventh?: string | null,
      twelfth?: string | null,
      champion?: string | null,
      reserve?: string | null,
    } | null > | null,
    numberEntriesToReceiveSpecialClassPoints?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventResultPointTablesId?: string | null,
  } | null,
};

export type DeletePointTableMutationVariables = {
  input: DeletePointTableInput,
  condition?: ModelPointTableConditionInput | null,
};

export type DeletePointTableMutation = {
  deletePointTable?:  {
    __typename: "PointTable",
    id: string,
    name: string,
    description?: string | null,
    displayOrder?: number | null,
    organizationId?: string | null,
    discipline?: string | null,
    isAddValueToRegularFromEntryCount?: boolean | null,
    isAddValueToChampionshipFromEntryCount?: boolean | null,
    isUseChampionRule?: boolean | null,
    isUseReserveRule?: boolean | null,
    tiers?:  Array< {
      __typename: "PointTier",
      lowEntryCount?: number | null,
      highEntryCount?: number | null,
      first?: string | null,
      second?: string | null,
      third?: string | null,
      fourth?: string | null,
      fifth?: string | null,
      sixth?: string | null,
      seventh?: string | null,
      eighth?: string | null,
      ninth?: string | null,
      tenth?: string | null,
      eleventh?: string | null,
      twelfth?: string | null,
      champion?: string | null,
      reserve?: string | null,
    } | null > | null,
    numberEntriesToReceiveSpecialClassPoints?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventResultPointTablesId?: string | null,
  } | null,
};

export type CreatePollMutationVariables = {
  input: CreatePollInput,
  condition?: ModelPollConditionInput | null,
};

export type CreatePollMutation = {
  createPoll?:  {
    __typename: "Poll",
    id: string,
    title: string,
    description?: string | null,
    responses?:  {
      __typename: "ModelResponseConnection",
      items:  Array< {
        __typename: "Response",
        id: string,
        pollId?: string | null,
        personId: string,
        value?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePollMutationVariables = {
  input: UpdatePollInput,
  condition?: ModelPollConditionInput | null,
};

export type UpdatePollMutation = {
  updatePoll?:  {
    __typename: "Poll",
    id: string,
    title: string,
    description?: string | null,
    responses?:  {
      __typename: "ModelResponseConnection",
      items:  Array< {
        __typename: "Response",
        id: string,
        pollId?: string | null,
        personId: string,
        value?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePollMutationVariables = {
  input: DeletePollInput,
  condition?: ModelPollConditionInput | null,
};

export type DeletePollMutation = {
  deletePoll?:  {
    __typename: "Poll",
    id: string,
    title: string,
    description?: string | null,
    responses?:  {
      __typename: "ModelResponseConnection",
      items:  Array< {
        __typename: "Response",
        id: string,
        pollId?: string | null,
        personId: string,
        value?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePostMutationVariables = {
  input: CreatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type CreatePostMutation = {
  createPost?:  {
    __typename: "Post",
    id: string,
    title: string,
    status: PostStatus,
    rating?: number | null,
    content?: string | null,
    organizationId?: string | null,
    createdBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePostMutationVariables = {
  input: UpdatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type UpdatePostMutation = {
  updatePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    status: PostStatus,
    rating?: number | null,
    content?: string | null,
    organizationId?: string | null,
    createdBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePostMutationVariables = {
  input: DeletePostInput,
  condition?: ModelPostConditionInput | null,
};

export type DeletePostMutation = {
  deletePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    status: PostStatus,
    rating?: number | null,
    content?: string | null,
    organizationId?: string | null,
    createdBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePrizeMoneyTableMutationVariables = {
  input: CreatePrizeMoneyTableInput,
  condition?: ModelPrizeMoneyTableConditionInput | null,
};

export type CreatePrizeMoneyTableMutation = {
  createPrizeMoneyTable?:  {
    __typename: "PrizeMoneyTable",
    id: string,
    name: string,
    description?: string | null,
    displayOrder?: number | null,
    organizationId?: string | null,
    discipline?: string | null,
    isPercentage?: boolean | null,
    totalPot?: number | null,
    tiers?:  {
      __typename: "PrizeMoneyTiers",
      first?: string | null,
      second?: string | null,
      third?: string | null,
      fourth?: string | null,
      fifth?: string | null,
      sixth?: string | null,
      seventh?: string | null,
      eighth?: string | null,
      ninth?: string | null,
      tenth?: string | null,
      eleventh?: string | null,
      twelfth?: string | null,
      champion?: string | null,
      reserve?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdatePrizeMoneyTableMutationVariables = {
  input: UpdatePrizeMoneyTableInput,
  condition?: ModelPrizeMoneyTableConditionInput | null,
};

export type UpdatePrizeMoneyTableMutation = {
  updatePrizeMoneyTable?:  {
    __typename: "PrizeMoneyTable",
    id: string,
    name: string,
    description?: string | null,
    displayOrder?: number | null,
    organizationId?: string | null,
    discipline?: string | null,
    isPercentage?: boolean | null,
    totalPot?: number | null,
    tiers?:  {
      __typename: "PrizeMoneyTiers",
      first?: string | null,
      second?: string | null,
      third?: string | null,
      fourth?: string | null,
      fifth?: string | null,
      sixth?: string | null,
      seventh?: string | null,
      eighth?: string | null,
      ninth?: string | null,
      tenth?: string | null,
      eleventh?: string | null,
      twelfth?: string | null,
      champion?: string | null,
      reserve?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeletePrizeMoneyTableMutationVariables = {
  input: DeletePrizeMoneyTableInput,
  condition?: ModelPrizeMoneyTableConditionInput | null,
};

export type DeletePrizeMoneyTableMutation = {
  deletePrizeMoneyTable?:  {
    __typename: "PrizeMoneyTable",
    id: string,
    name: string,
    description?: string | null,
    displayOrder?: number | null,
    organizationId?: string | null,
    discipline?: string | null,
    isPercentage?: boolean | null,
    totalPot?: number | null,
    tiers?:  {
      __typename: "PrizeMoneyTiers",
      first?: string | null,
      second?: string | null,
      third?: string | null,
      fourth?: string | null,
      fifth?: string | null,
      sixth?: string | null,
      seventh?: string | null,
      eighth?: string | null,
      ninth?: string | null,
      tenth?: string | null,
      eleventh?: string | null,
      twelfth?: string | null,
      champion?: string | null,
      reserve?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreatePromoCodeMutationVariables = {
  input: CreatePromoCodeInput,
  condition?: ModelPromoCodeConditionInput | null,
};

export type CreatePromoCodeMutation = {
  createPromoCode?:  {
    __typename: "PromoCode",
    id: string,
    name: string,
    stripeId?: string | null,
    isvalid?: boolean | null,
    isfundraiser?: boolean | null,
    ispercentdiscount?: boolean | null,
    isfixeddiscount?: boolean | null,
    percent?: number | null,
    fixed?: number | null,
    minamount?: number | null,
    startdate?: string | null,
    enddate?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdatePromoCodeMutationVariables = {
  input: UpdatePromoCodeInput,
  condition?: ModelPromoCodeConditionInput | null,
};

export type UpdatePromoCodeMutation = {
  updatePromoCode?:  {
    __typename: "PromoCode",
    id: string,
    name: string,
    stripeId?: string | null,
    isvalid?: boolean | null,
    isfundraiser?: boolean | null,
    ispercentdiscount?: boolean | null,
    isfixeddiscount?: boolean | null,
    percent?: number | null,
    fixed?: number | null,
    minamount?: number | null,
    startdate?: string | null,
    enddate?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeletePromoCodeMutationVariables = {
  input: DeletePromoCodeInput,
  condition?: ModelPromoCodeConditionInput | null,
};

export type DeletePromoCodeMutation = {
  deletePromoCode?:  {
    __typename: "PromoCode",
    id: string,
    name: string,
    stripeId?: string | null,
    isvalid?: boolean | null,
    isfundraiser?: boolean | null,
    ispercentdiscount?: boolean | null,
    isfixeddiscount?: boolean | null,
    percent?: number | null,
    fixed?: number | null,
    minamount?: number | null,
    startdate?: string | null,
    enddate?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateResponseMutationVariables = {
  input: CreateResponseInput,
  condition?: ModelResponseConditionInput | null,
};

export type CreateResponseMutation = {
  createResponse?:  {
    __typename: "Response",
    id: string,
    pollId?: string | null,
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateResponseMutationVariables = {
  input: UpdateResponseInput,
  condition?: ModelResponseConditionInput | null,
};

export type UpdateResponseMutation = {
  updateResponse?:  {
    __typename: "Response",
    id: string,
    pollId?: string | null,
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteResponseMutationVariables = {
  input: DeleteResponseInput,
  condition?: ModelResponseConditionInput | null,
};

export type DeleteResponseMutation = {
  deleteResponse?:  {
    __typename: "Response",
    id: string,
    pollId?: string | null,
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRiderMutationVariables = {
  input: CreateRiderInput,
  condition?: ModelRiderConditionInput | null,
};

export type CreateRiderMutation = {
  createRider?:  {
    __typename: "Rider",
    id: string,
    name: string,
    location?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    age?: number | null,
    birthdate?: string | null,
    isProfessional?: boolean | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    createdAt: string,
    updatedAt: string,
    riderContactId?: string | null,
  } | null,
};

export type UpdateRiderMutationVariables = {
  input: UpdateRiderInput,
  condition?: ModelRiderConditionInput | null,
};

export type UpdateRiderMutation = {
  updateRider?:  {
    __typename: "Rider",
    id: string,
    name: string,
    location?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    age?: number | null,
    birthdate?: string | null,
    isProfessional?: boolean | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    createdAt: string,
    updatedAt: string,
    riderContactId?: string | null,
  } | null,
};

export type DeleteRiderMutationVariables = {
  input: DeleteRiderInput,
  condition?: ModelRiderConditionInput | null,
};

export type DeleteRiderMutation = {
  deleteRider?:  {
    __typename: "Rider",
    id: string,
    name: string,
    location?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    age?: number | null,
    birthdate?: string | null,
    isProfessional?: boolean | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    createdAt: string,
    updatedAt: string,
    riderContactId?: string | null,
  } | null,
};

export type CreateRingMutationVariables = {
  input: CreateRingInput,
  condition?: ModelRingConditionInput | null,
};

export type CreateRingMutation = {
  createRing?:  {
    __typename: "Ring",
    id: string,
    name: string,
    displayOrder?: number | null,
    organizationId?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateRingMutationVariables = {
  input: UpdateRingInput,
  condition?: ModelRingConditionInput | null,
};

export type UpdateRingMutation = {
  updateRing?:  {
    __typename: "Ring",
    id: string,
    name: string,
    displayOrder?: number | null,
    organizationId?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteRingMutationVariables = {
  input: DeleteRingInput,
  condition?: ModelRingConditionInput | null,
};

export type DeleteRingMutation = {
  deleteRing?:  {
    __typename: "Ring",
    id: string,
    name: string,
    displayOrder?: number | null,
    organizationId?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateScheduleItemMutationVariables = {
  input: CreateScheduleItemInput,
  condition?: ModelScheduleItemConditionInput | null,
};

export type CreateScheduleItemMutation = {
  createScheduleItem?:  {
    __typename: "ScheduleItem",
    id: string,
    eventId: string,
    eventRingId: string,
    eventDayId: string,
    eventRing?:  {
      __typename: "EventRing",
      id: string,
      name: string,
      eventId: string,
      ringId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      ring?:  {
        __typename: "Ring",
        id: string,
        name: string,
        displayOrder?: number | null,
        organizationId?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventDay?:  {
      __typename: "EventDay",
      id: string,
      eventId?: string | null,
      awsdate?: string | null,
      dayofweek?: string | null,
      date?: string | null,
      month?: string | null,
      year?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    displayOrder?: number | null,
    classId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    breakId?: string | null,
    eventBreak?:  {
      __typename: "EventBreak",
      id: string,
      eventId: string,
      name?: string | null,
      time?: string | null,
      isDisplayedOnSchedule?: boolean | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    startTime?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateScheduleItemMutationVariables = {
  input: UpdateScheduleItemInput,
  condition?: ModelScheduleItemConditionInput | null,
};

export type UpdateScheduleItemMutation = {
  updateScheduleItem?:  {
    __typename: "ScheduleItem",
    id: string,
    eventId: string,
    eventRingId: string,
    eventDayId: string,
    eventRing?:  {
      __typename: "EventRing",
      id: string,
      name: string,
      eventId: string,
      ringId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      ring?:  {
        __typename: "Ring",
        id: string,
        name: string,
        displayOrder?: number | null,
        organizationId?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventDay?:  {
      __typename: "EventDay",
      id: string,
      eventId?: string | null,
      awsdate?: string | null,
      dayofweek?: string | null,
      date?: string | null,
      month?: string | null,
      year?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    displayOrder?: number | null,
    classId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    breakId?: string | null,
    eventBreak?:  {
      __typename: "EventBreak",
      id: string,
      eventId: string,
      name?: string | null,
      time?: string | null,
      isDisplayedOnSchedule?: boolean | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    startTime?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteScheduleItemMutationVariables = {
  input: DeleteScheduleItemInput,
  condition?: ModelScheduleItemConditionInput | null,
};

export type DeleteScheduleItemMutation = {
  deleteScheduleItem?:  {
    __typename: "ScheduleItem",
    id: string,
    eventId: string,
    eventRingId: string,
    eventDayId: string,
    eventRing?:  {
      __typename: "EventRing",
      id: string,
      name: string,
      eventId: string,
      ringId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      ring?:  {
        __typename: "Ring",
        id: string,
        name: string,
        displayOrder?: number | null,
        organizationId?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventDay?:  {
      __typename: "EventDay",
      id: string,
      eventId?: string | null,
      awsdate?: string | null,
      dayofweek?: string | null,
      date?: string | null,
      month?: string | null,
      year?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    displayOrder?: number | null,
    classId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    breakId?: string | null,
    eventBreak?:  {
      __typename: "EventBreak",
      id: string,
      eventId: string,
      name?: string | null,
      time?: string | null,
      isDisplayedOnSchedule?: boolean | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    startTime?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateSeasonMutationVariables = {
  input: CreateSeasonInput,
  condition?: ModelSeasonConditionInput | null,
};

export type CreateSeasonMutation = {
  createSeason?:  {
    __typename: "Season",
    id: string,
    name: string,
    description?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationMembershipTypes?:  {
      __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
      items:  Array< {
        __typename: "OrganizationMembershipTypeSeasons",
        id: string,
        organizationMembershipTypeID: string,
        seasonID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    startDate: string,
    endDate: string,
    disciplines?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    createdAt: string,
    updatedAt: string,
    seasonOrganizationId?: string | null,
  } | null,
};

export type UpdateSeasonMutationVariables = {
  input: UpdateSeasonInput,
  condition?: ModelSeasonConditionInput | null,
};

export type UpdateSeasonMutation = {
  updateSeason?:  {
    __typename: "Season",
    id: string,
    name: string,
    description?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationMembershipTypes?:  {
      __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
      items:  Array< {
        __typename: "OrganizationMembershipTypeSeasons",
        id: string,
        organizationMembershipTypeID: string,
        seasonID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    startDate: string,
    endDate: string,
    disciplines?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    createdAt: string,
    updatedAt: string,
    seasonOrganizationId?: string | null,
  } | null,
};

export type DeleteSeasonMutationVariables = {
  input: DeleteSeasonInput,
  condition?: ModelSeasonConditionInput | null,
};

export type DeleteSeasonMutation = {
  deleteSeason?:  {
    __typename: "Season",
    id: string,
    name: string,
    description?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationMembershipTypes?:  {
      __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
      items:  Array< {
        __typename: "OrganizationMembershipTypeSeasons",
        id: string,
        organizationMembershipTypeID: string,
        seasonID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    startDate: string,
    endDate: string,
    disciplines?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    createdAt: string,
    updatedAt: string,
    seasonOrganizationId?: string | null,
  } | null,
};

export type CreateStablingRequestMutationVariables = {
  input: CreateStablingRequestInput,
  condition?: ModelStablingRequestConditionInput | null,
};

export type CreateStablingRequestMutation = {
  createStablingRequest?:  {
    __typename: "StablingRequest",
    id: string,
    eventId: string,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    arrivalDateTime?: string | null,
    departureDateTime?: string | null,
    stallTypeId?: string | null,
    stallType?:  {
      __typename: "EventStallType",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallTypeId?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      pricePerStall?: string | null,
      taxPerStall?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    quantityNeeded?: number | null,
    basePrice?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    totalPrice?: number | null,
    status?: string | null,
    statusNote?: string | null,
    requestNote?: string | null,
    stallSetId?: string | null,
    stallSet?:  {
      __typename: "EventStallSet",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallSetId?: string | null,
      name?: string | null,
      location?: string | null,
      available?: string | null,
      totalAvailable?: number | null,
      used?: string | null,
      totalUsed?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    stallLocation?: string | null,
    stallNumbers?: string | null,
    payerId?: string | null,
    payerName?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  {
      __typename: "ModelEventEntryConnection",
      items:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryIds?: Array< string | null > | null,
    submittedEntry?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateStablingRequestMutationVariables = {
  input: UpdateStablingRequestInput,
  condition?: ModelStablingRequestConditionInput | null,
};

export type UpdateStablingRequestMutation = {
  updateStablingRequest?:  {
    __typename: "StablingRequest",
    id: string,
    eventId: string,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    arrivalDateTime?: string | null,
    departureDateTime?: string | null,
    stallTypeId?: string | null,
    stallType?:  {
      __typename: "EventStallType",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallTypeId?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      pricePerStall?: string | null,
      taxPerStall?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    quantityNeeded?: number | null,
    basePrice?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    totalPrice?: number | null,
    status?: string | null,
    statusNote?: string | null,
    requestNote?: string | null,
    stallSetId?: string | null,
    stallSet?:  {
      __typename: "EventStallSet",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallSetId?: string | null,
      name?: string | null,
      location?: string | null,
      available?: string | null,
      totalAvailable?: number | null,
      used?: string | null,
      totalUsed?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    stallLocation?: string | null,
    stallNumbers?: string | null,
    payerId?: string | null,
    payerName?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  {
      __typename: "ModelEventEntryConnection",
      items:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryIds?: Array< string | null > | null,
    submittedEntry?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteStablingRequestMutationVariables = {
  input: DeleteStablingRequestInput,
  condition?: ModelStablingRequestConditionInput | null,
};

export type DeleteStablingRequestMutation = {
  deleteStablingRequest?:  {
    __typename: "StablingRequest",
    id: string,
    eventId: string,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    arrivalDateTime?: string | null,
    departureDateTime?: string | null,
    stallTypeId?: string | null,
    stallType?:  {
      __typename: "EventStallType",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallTypeId?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      pricePerStall?: string | null,
      taxPerStall?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    quantityNeeded?: number | null,
    basePrice?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    totalPrice?: number | null,
    status?: string | null,
    statusNote?: string | null,
    requestNote?: string | null,
    stallSetId?: string | null,
    stallSet?:  {
      __typename: "EventStallSet",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallSetId?: string | null,
      name?: string | null,
      location?: string | null,
      available?: string | null,
      totalAvailable?: number | null,
      used?: string | null,
      totalUsed?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    stallLocation?: string | null,
    stallNumbers?: string | null,
    payerId?: string | null,
    payerName?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  {
      __typename: "ModelEventEntryConnection",
      items:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryIds?: Array< string | null > | null,
    submittedEntry?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateBeddingRequestMutationVariables = {
  input: CreateBeddingRequestInput,
  condition?: ModelBeddingRequestConditionInput | null,
};

export type CreateBeddingRequestMutation = {
  createBeddingRequest?:  {
    __typename: "BeddingRequest",
    id: string,
    eventId: string,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    arrivalDateTime?: string | null,
    dateTimeNeeded?: string | null,
    deliveryInfo?: string | null,
    beddingType?: string | null,
    quantityNeeded?: number | null,
    basePrice?: number | null,
    taxA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    totalPrice?: number | null,
    status?: string | null,
    statusNote?: string | null,
    payerId?: string | null,
    payerName?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  {
      __typename: "ModelEventEntryConnection",
      items:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryIds?: Array< string | null > | null,
    submittedEntry?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    beddingRequestTaxAId?: string | null,
    beddingRequestTaxBId?: string | null,
  } | null,
};

export type UpdateBeddingRequestMutationVariables = {
  input: UpdateBeddingRequestInput,
  condition?: ModelBeddingRequestConditionInput | null,
};

export type UpdateBeddingRequestMutation = {
  updateBeddingRequest?:  {
    __typename: "BeddingRequest",
    id: string,
    eventId: string,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    arrivalDateTime?: string | null,
    dateTimeNeeded?: string | null,
    deliveryInfo?: string | null,
    beddingType?: string | null,
    quantityNeeded?: number | null,
    basePrice?: number | null,
    taxA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    totalPrice?: number | null,
    status?: string | null,
    statusNote?: string | null,
    payerId?: string | null,
    payerName?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  {
      __typename: "ModelEventEntryConnection",
      items:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryIds?: Array< string | null > | null,
    submittedEntry?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    beddingRequestTaxAId?: string | null,
    beddingRequestTaxBId?: string | null,
  } | null,
};

export type DeleteBeddingRequestMutationVariables = {
  input: DeleteBeddingRequestInput,
  condition?: ModelBeddingRequestConditionInput | null,
};

export type DeleteBeddingRequestMutation = {
  deleteBeddingRequest?:  {
    __typename: "BeddingRequest",
    id: string,
    eventId: string,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    arrivalDateTime?: string | null,
    dateTimeNeeded?: string | null,
    deliveryInfo?: string | null,
    beddingType?: string | null,
    quantityNeeded?: number | null,
    basePrice?: number | null,
    taxA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    totalPrice?: number | null,
    status?: string | null,
    statusNote?: string | null,
    payerId?: string | null,
    payerName?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  {
      __typename: "ModelEventEntryConnection",
      items:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryIds?: Array< string | null > | null,
    submittedEntry?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    beddingRequestTaxAId?: string | null,
    beddingRequestTaxBId?: string | null,
  } | null,
};

export type CreateSubmissionMutationVariables = {
  input: CreateSubmissionInput,
  condition?: ModelSubmissionConditionInput | null,
};

export type CreateSubmissionMutation = {
  createSubmission?:  {
    __typename: "Submission",
    id: string,
    title: string,
    description?: string | null,
    video?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    videoLength?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    riderId?: string | null,
    personId?: string | null,
    clinicians?:  Array< {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null > | null,
    totalPrice: number,
    status?: SubmissionStatus | null,
    submissionFeedback?:  {
      __typename: "ModelSubmissionFeedbackConnection",
      items:  Array< {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdOn?: string | null,
    updatedOn?: string | null,
  } | null,
};

export type UpdateSubmissionMutationVariables = {
  input: UpdateSubmissionInput,
  condition?: ModelSubmissionConditionInput | null,
};

export type UpdateSubmissionMutation = {
  updateSubmission?:  {
    __typename: "Submission",
    id: string,
    title: string,
    description?: string | null,
    video?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    videoLength?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    riderId?: string | null,
    personId?: string | null,
    clinicians?:  Array< {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null > | null,
    totalPrice: number,
    status?: SubmissionStatus | null,
    submissionFeedback?:  {
      __typename: "ModelSubmissionFeedbackConnection",
      items:  Array< {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdOn?: string | null,
    updatedOn?: string | null,
  } | null,
};

export type DeleteSubmissionMutationVariables = {
  input: DeleteSubmissionInput,
  condition?: ModelSubmissionConditionInput | null,
};

export type DeleteSubmissionMutation = {
  deleteSubmission?:  {
    __typename: "Submission",
    id: string,
    title: string,
    description?: string | null,
    video?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    videoLength?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    riderId?: string | null,
    personId?: string | null,
    clinicians?:  Array< {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null > | null,
    totalPrice: number,
    status?: SubmissionStatus | null,
    submissionFeedback?:  {
      __typename: "ModelSubmissionFeedbackConnection",
      items:  Array< {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdOn?: string | null,
    updatedOn?: string | null,
  } | null,
};

export type CreateSubmissionFeedbackMutationVariables = {
  input: CreateSubmissionFeedbackInput,
  condition?: ModelSubmissionFeedbackConditionInput | null,
};

export type CreateSubmissionFeedbackMutation = {
  createSubmissionFeedback?:  {
    __typename: "SubmissionFeedback",
    id: string,
    type?: string | null,
    submission?:  {
      __typename: "Submission",
      id: string,
      title: string,
      description?: string | null,
      video?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      videoLength?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      personId?: string | null,
      clinicians?:  Array< {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null > | null,
      totalPrice: number,
      status?: SubmissionStatus | null,
      submissionFeedback?:  {
        __typename: "ModelSubmissionFeedbackConnection",
        nextToken?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
    } | null,
    submissionId: string,
    feedback:  {
      __typename: "Feedback",
      id: string,
      clinicianId?: string | null,
      personId?: string | null,
      technical?: string | null,
      style?: string | null,
      turnout?: string | null,
      general?: string | null,
      score?: number | null,
      status?: FeedbackStatus | null,
      submissionFeedback?:  {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      feedbackSubmissionFeedbackId?: string | null,
    },
    feedbackId: string,
    personId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    riderId?: string | null,
    clinician?:  {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null,
    clinicianId?: string | null,
    submissionStatus?: SubmissionStatus | null,
    feedbackStatus?: FeedbackStatus | null,
    clinicianAmount?: number | null,
    declineReason?: string | null,
    permissionToPost?: boolean | null,
    dateSubmitted?: string | null,
    dateCompleted?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    submissionFeedbackClinicianId?: string | null,
  } | null,
};

export type UpdateSubmissionFeedbackMutationVariables = {
  input: UpdateSubmissionFeedbackInput,
  condition?: ModelSubmissionFeedbackConditionInput | null,
};

export type UpdateSubmissionFeedbackMutation = {
  updateSubmissionFeedback?:  {
    __typename: "SubmissionFeedback",
    id: string,
    type?: string | null,
    submission?:  {
      __typename: "Submission",
      id: string,
      title: string,
      description?: string | null,
      video?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      videoLength?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      personId?: string | null,
      clinicians?:  Array< {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null > | null,
      totalPrice: number,
      status?: SubmissionStatus | null,
      submissionFeedback?:  {
        __typename: "ModelSubmissionFeedbackConnection",
        nextToken?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
    } | null,
    submissionId: string,
    feedback:  {
      __typename: "Feedback",
      id: string,
      clinicianId?: string | null,
      personId?: string | null,
      technical?: string | null,
      style?: string | null,
      turnout?: string | null,
      general?: string | null,
      score?: number | null,
      status?: FeedbackStatus | null,
      submissionFeedback?:  {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      feedbackSubmissionFeedbackId?: string | null,
    },
    feedbackId: string,
    personId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    riderId?: string | null,
    clinician?:  {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null,
    clinicianId?: string | null,
    submissionStatus?: SubmissionStatus | null,
    feedbackStatus?: FeedbackStatus | null,
    clinicianAmount?: number | null,
    declineReason?: string | null,
    permissionToPost?: boolean | null,
    dateSubmitted?: string | null,
    dateCompleted?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    submissionFeedbackClinicianId?: string | null,
  } | null,
};

export type DeleteSubmissionFeedbackMutationVariables = {
  input: DeleteSubmissionFeedbackInput,
  condition?: ModelSubmissionFeedbackConditionInput | null,
};

export type DeleteSubmissionFeedbackMutation = {
  deleteSubmissionFeedback?:  {
    __typename: "SubmissionFeedback",
    id: string,
    type?: string | null,
    submission?:  {
      __typename: "Submission",
      id: string,
      title: string,
      description?: string | null,
      video?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      videoLength?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      personId?: string | null,
      clinicians?:  Array< {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null > | null,
      totalPrice: number,
      status?: SubmissionStatus | null,
      submissionFeedback?:  {
        __typename: "ModelSubmissionFeedbackConnection",
        nextToken?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
    } | null,
    submissionId: string,
    feedback:  {
      __typename: "Feedback",
      id: string,
      clinicianId?: string | null,
      personId?: string | null,
      technical?: string | null,
      style?: string | null,
      turnout?: string | null,
      general?: string | null,
      score?: number | null,
      status?: FeedbackStatus | null,
      submissionFeedback?:  {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      feedbackSubmissionFeedbackId?: string | null,
    },
    feedbackId: string,
    personId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    riderId?: string | null,
    clinician?:  {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null,
    clinicianId?: string | null,
    submissionStatus?: SubmissionStatus | null,
    feedbackStatus?: FeedbackStatus | null,
    clinicianAmount?: number | null,
    declineReason?: string | null,
    permissionToPost?: boolean | null,
    dateSubmitted?: string | null,
    dateCompleted?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    submissionFeedbackClinicianId?: string | null,
  } | null,
};

export type CreateTaxRateMutationVariables = {
  input: CreateTaxRateInput,
  condition?: ModelTaxRateConditionInput | null,
};

export type CreateTaxRateMutation = {
  createTaxRate?:  {
    __typename: "TaxRate",
    id: string,
    name?: string | null,
    createdBy?: string | null,
    organizationId?: string | null,
    stripeTaxRateId?: string | null,
    percentage?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateTaxRateMutationVariables = {
  input: UpdateTaxRateInput,
  condition?: ModelTaxRateConditionInput | null,
};

export type UpdateTaxRateMutation = {
  updateTaxRate?:  {
    __typename: "TaxRate",
    id: string,
    name?: string | null,
    createdBy?: string | null,
    organizationId?: string | null,
    stripeTaxRateId?: string | null,
    percentage?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteTaxRateMutationVariables = {
  input: DeleteTaxRateInput,
  condition?: ModelTaxRateConditionInput | null,
};

export type DeleteTaxRateMutation = {
  deleteTaxRate?:  {
    __typename: "TaxRate",
    id: string,
    name?: string | null,
    createdBy?: string | null,
    organizationId?: string | null,
    stripeTaxRateId?: string | null,
    percentage?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateTeamMutationVariables = {
  input: CreateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type CreateTeamMutation = {
  createTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    location?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    members?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        teamId: string,
        personId: string,
        status?: TeamStatus | null,
        role?: TeamRole | null,
        permissionLevel?: TeamPermissionLevel | null,
        creatorId?: string | null,
        createdOn: string,
        updatedOn: string,
        teamMemberCreatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    creatorId?: string | null,
    creator?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    teamContactId?: string | null,
    teamLocationId?: string | null,
    teamCreatorId?: string | null,
  } | null,
};

export type UpdateTeamMutationVariables = {
  input: UpdateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type UpdateTeamMutation = {
  updateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    location?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    members?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        teamId: string,
        personId: string,
        status?: TeamStatus | null,
        role?: TeamRole | null,
        permissionLevel?: TeamPermissionLevel | null,
        creatorId?: string | null,
        createdOn: string,
        updatedOn: string,
        teamMemberCreatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    creatorId?: string | null,
    creator?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    teamContactId?: string | null,
    teamLocationId?: string | null,
    teamCreatorId?: string | null,
  } | null,
};

export type DeleteTeamMutationVariables = {
  input: DeleteTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type DeleteTeamMutation = {
  deleteTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    location?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    members?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        teamId: string,
        personId: string,
        status?: TeamStatus | null,
        role?: TeamRole | null,
        permissionLevel?: TeamPermissionLevel | null,
        creatorId?: string | null,
        createdOn: string,
        updatedOn: string,
        teamMemberCreatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    creatorId?: string | null,
    creator?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    teamContactId?: string | null,
    teamLocationId?: string | null,
    teamCreatorId?: string | null,
  } | null,
};

export type CreateTeamMemberMutationVariables = {
  input: CreateTeamMemberInput,
  condition?: ModelTeamMemberConditionInput | null,
};

export type CreateTeamMemberMutation = {
  createTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    teamId: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      members?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      creator?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      teamContactId?: string | null,
      teamLocationId?: string | null,
      teamCreatorId?: string | null,
    },
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    status?: TeamStatus | null,
    role?: TeamRole | null,
    permissionLevel?: TeamPermissionLevel | null,
    creatorId?: string | null,
    creator?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    teamMemberCreatorId?: string | null,
  } | null,
};

export type UpdateTeamMemberMutationVariables = {
  input: UpdateTeamMemberInput,
  condition?: ModelTeamMemberConditionInput | null,
};

export type UpdateTeamMemberMutation = {
  updateTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    teamId: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      members?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      creator?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      teamContactId?: string | null,
      teamLocationId?: string | null,
      teamCreatorId?: string | null,
    },
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    status?: TeamStatus | null,
    role?: TeamRole | null,
    permissionLevel?: TeamPermissionLevel | null,
    creatorId?: string | null,
    creator?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    teamMemberCreatorId?: string | null,
  } | null,
};

export type DeleteTeamMemberMutationVariables = {
  input: DeleteTeamMemberInput,
  condition?: ModelTeamMemberConditionInput | null,
};

export type DeleteTeamMemberMutation = {
  deleteTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    teamId: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      members?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      creator?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      teamContactId?: string | null,
      teamLocationId?: string | null,
      teamCreatorId?: string | null,
    },
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    status?: TeamStatus | null,
    role?: TeamRole | null,
    permissionLevel?: TeamPermissionLevel | null,
    creatorId?: string | null,
    creator?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    teamMemberCreatorId?: string | null,
  } | null,
};

export type CreateTextMutationVariables = {
  input: CreateTextInput,
  condition?: ModelTextConditionInput | null,
};

export type CreateTextMutation = {
  createText?:  {
    __typename: "Text",
    id: string,
    eventId?: string | null,
    blockId?: string | null,
    block?:  {
      __typename: "Block",
      id: string,
      name?: string | null,
      type?: string | null,
      personid?: string | null,
      organizationId?: string | null,
      purchasedOn?: string | null,
      expiresOn?: string | null,
      totalCredits?: number | null,
      usedCredits?: number | null,
      status?: BlockStatus | null,
      price?: number | null,
      amountPaid?: number | null,
      isPromotion?: string | null,
      isFree?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    alertId?: string | null,
    alert?:  {
      __typename: "Alert",
      id: string,
      type?: string | null,
      title?: string | null,
      body?: string | null,
      imageKey?: string | null,
      category?: string | null,
      organizationId?: string | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      messageSegments?: number | null,
      subscriberCount?: number | null,
      totalCost?: number | null,
      blockIdsUsed?: Array< string | null > | null,
      fromUnlimitedEvent?: boolean | null,
      isIncoming?: string | null,
      sentFromPhoneNumber?: string | null,
      recipientPhoneNumbers?: Array< string | null > | null,
      successSIDList?: Array< string | null > | null,
      errorSIDList?: Array< string | null > | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      alertEventId?: string | null,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null,
    status?: TextStatus | null,
    statusNote?: string | null,
    errorCode?: string | null,
    twilioSID?: string | null,
    twilioMessagingServiceSID?: string | null,
    twilioNote?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    timeSent?: string | null,
    timeDelivered?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type UpdateTextMutationVariables = {
  input: UpdateTextInput,
  condition?: ModelTextConditionInput | null,
};

export type UpdateTextMutation = {
  updateText?:  {
    __typename: "Text",
    id: string,
    eventId?: string | null,
    blockId?: string | null,
    block?:  {
      __typename: "Block",
      id: string,
      name?: string | null,
      type?: string | null,
      personid?: string | null,
      organizationId?: string | null,
      purchasedOn?: string | null,
      expiresOn?: string | null,
      totalCredits?: number | null,
      usedCredits?: number | null,
      status?: BlockStatus | null,
      price?: number | null,
      amountPaid?: number | null,
      isPromotion?: string | null,
      isFree?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    alertId?: string | null,
    alert?:  {
      __typename: "Alert",
      id: string,
      type?: string | null,
      title?: string | null,
      body?: string | null,
      imageKey?: string | null,
      category?: string | null,
      organizationId?: string | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      messageSegments?: number | null,
      subscriberCount?: number | null,
      totalCost?: number | null,
      blockIdsUsed?: Array< string | null > | null,
      fromUnlimitedEvent?: boolean | null,
      isIncoming?: string | null,
      sentFromPhoneNumber?: string | null,
      recipientPhoneNumbers?: Array< string | null > | null,
      successSIDList?: Array< string | null > | null,
      errorSIDList?: Array< string | null > | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      alertEventId?: string | null,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null,
    status?: TextStatus | null,
    statusNote?: string | null,
    errorCode?: string | null,
    twilioSID?: string | null,
    twilioMessagingServiceSID?: string | null,
    twilioNote?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    timeSent?: string | null,
    timeDelivered?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type DeleteTextMutationVariables = {
  input: DeleteTextInput,
  condition?: ModelTextConditionInput | null,
};

export type DeleteTextMutation = {
  deleteText?:  {
    __typename: "Text",
    id: string,
    eventId?: string | null,
    blockId?: string | null,
    block?:  {
      __typename: "Block",
      id: string,
      name?: string | null,
      type?: string | null,
      personid?: string | null,
      organizationId?: string | null,
      purchasedOn?: string | null,
      expiresOn?: string | null,
      totalCredits?: number | null,
      usedCredits?: number | null,
      status?: BlockStatus | null,
      price?: number | null,
      amountPaid?: number | null,
      isPromotion?: string | null,
      isFree?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    alertId?: string | null,
    alert?:  {
      __typename: "Alert",
      id: string,
      type?: string | null,
      title?: string | null,
      body?: string | null,
      imageKey?: string | null,
      category?: string | null,
      organizationId?: string | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      messageSegments?: number | null,
      subscriberCount?: number | null,
      totalCost?: number | null,
      blockIdsUsed?: Array< string | null > | null,
      fromUnlimitedEvent?: boolean | null,
      isIncoming?: string | null,
      sentFromPhoneNumber?: string | null,
      recipientPhoneNumbers?: Array< string | null > | null,
      successSIDList?: Array< string | null > | null,
      errorSIDList?: Array< string | null > | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      alertEventId?: string | null,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null,
    status?: TextStatus | null,
    statusNote?: string | null,
    errorCode?: string | null,
    twilioSID?: string | null,
    twilioMessagingServiceSID?: string | null,
    twilioNote?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    timeSent?: string | null,
    timeDelivered?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type CreateTrainerMutationVariables = {
  input: CreateTrainerInput,
  condition?: ModelTrainerConditionInput | null,
};

export type CreateTrainerMutation = {
  createTrainer?:  {
    __typename: "Trainer",
    id: string,
    name: string,
    location?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    trainerContactId?: string | null,
  } | null,
};

export type UpdateTrainerMutationVariables = {
  input: UpdateTrainerInput,
  condition?: ModelTrainerConditionInput | null,
};

export type UpdateTrainerMutation = {
  updateTrainer?:  {
    __typename: "Trainer",
    id: string,
    name: string,
    location?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    trainerContactId?: string | null,
  } | null,
};

export type DeleteTrainerMutationVariables = {
  input: DeleteTrainerInput,
  condition?: ModelTrainerConditionInput | null,
};

export type DeleteTrainerMutation = {
  deleteTrainer?:  {
    __typename: "Trainer",
    id: string,
    name: string,
    location?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    trainerContactId?: string | null,
  } | null,
};

export type CreateOrganizationMembershipTypeSeasonsMutationVariables = {
  input: CreateOrganizationMembershipTypeSeasonsInput,
  condition?: ModelOrganizationMembershipTypeSeasonsConditionInput | null,
};

export type CreateOrganizationMembershipTypeSeasonsMutation = {
  createOrganizationMembershipTypeSeasons?:  {
    __typename: "OrganizationMembershipTypeSeasons",
    id: string,
    organizationMembershipTypeID: string,
    seasonID: string,
    organizationMembershipType:  {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    },
    season:  {
      __typename: "Season",
      id: string,
      name: string,
      description?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationMembershipTypes?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      startDate: string,
      endDate: string,
      disciplines?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      createdAt: string,
      updatedAt: string,
      seasonOrganizationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrganizationMembershipTypeSeasonsMutationVariables = {
  input: UpdateOrganizationMembershipTypeSeasonsInput,
  condition?: ModelOrganizationMembershipTypeSeasonsConditionInput | null,
};

export type UpdateOrganizationMembershipTypeSeasonsMutation = {
  updateOrganizationMembershipTypeSeasons?:  {
    __typename: "OrganizationMembershipTypeSeasons",
    id: string,
    organizationMembershipTypeID: string,
    seasonID: string,
    organizationMembershipType:  {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    },
    season:  {
      __typename: "Season",
      id: string,
      name: string,
      description?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationMembershipTypes?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      startDate: string,
      endDate: string,
      disciplines?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      createdAt: string,
      updatedAt: string,
      seasonOrganizationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrganizationMembershipTypeSeasonsMutationVariables = {
  input: DeleteOrganizationMembershipTypeSeasonsInput,
  condition?: ModelOrganizationMembershipTypeSeasonsConditionInput | null,
};

export type DeleteOrganizationMembershipTypeSeasonsMutation = {
  deleteOrganizationMembershipTypeSeasons?:  {
    __typename: "OrganizationMembershipTypeSeasons",
    id: string,
    organizationMembershipTypeID: string,
    seasonID: string,
    organizationMembershipType:  {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    },
    season:  {
      __typename: "Season",
      id: string,
      name: string,
      description?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationMembershipTypes?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      startDate: string,
      endDate: string,
      disciplines?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      createdAt: string,
      updatedAt: string,
      seasonOrganizationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetAddressQueryVariables = {
  id: string,
};

export type GetAddressQuery = {
  getAddress?:  {
    __typename: "Address",
    id: string,
    name?: string | null,
    type?: string | null,
    isDefault?: boolean | null,
    streetAddress1?: string | null,
    streetAddress2?: string | null,
    city?: string | null,
    provState?: string | null,
    zip?: string | null,
    country?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAddressesQueryVariables = {
  id?: string | null,
  filter?: ModelAddressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAddressesQuery = {
  listAddresses?:  {
    __typename: "ModelAddressConnection",
    items:  Array< {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAlertQueryVariables = {
  id: string,
};

export type GetAlertQuery = {
  getAlert?:  {
    __typename: "Alert",
    id: string,
    type?: string | null,
    title?: string | null,
    body?: string | null,
    imageKey?: string | null,
    category?: string | null,
    organizationId?: string | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    messageSegments?: number | null,
    subscriberCount?: number | null,
    totalCost?: number | null,
    blockIdsUsed?: Array< string | null > | null,
    fromUnlimitedEvent?: boolean | null,
    isIncoming?: string | null,
    sentFromPhoneNumber?: string | null,
    recipientPhoneNumbers?: Array< string | null > | null,
    successSIDList?: Array< string | null > | null,
    errorSIDList?: Array< string | null > | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    alertEventId?: string | null,
  } | null,
};

export type ListAlertsQueryVariables = {
  id?: string | null,
  filter?: ModelAlertFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAlertsQuery = {
  listAlerts?:  {
    __typename: "ModelAlertConnection",
    items:  Array< {
      __typename: "Alert",
      id: string,
      type?: string | null,
      title?: string | null,
      body?: string | null,
      imageKey?: string | null,
      category?: string | null,
      organizationId?: string | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      messageSegments?: number | null,
      subscriberCount?: number | null,
      totalCost?: number | null,
      blockIdsUsed?: Array< string | null > | null,
      fromUnlimitedEvent?: boolean | null,
      isIncoming?: string | null,
      sentFromPhoneNumber?: string | null,
      recipientPhoneNumbers?: Array< string | null > | null,
      successSIDList?: Array< string | null > | null,
      errorSIDList?: Array< string | null > | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      alertEventId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAuditorQueryVariables = {
  id: string,
};

export type GetAuditorQuery = {
  getAuditor?:  {
    __typename: "Auditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    email?: string | null,
    status?: AuditorStatus | null,
    statusNote?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListAuditorsQueryVariables = {
  id?: string | null,
  filter?: ModelAuditorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAuditorsQuery = {
  listAuditors?:  {
    __typename: "ModelAuditorConnection",
    items:  Array< {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBacklogEventResultQueryVariables = {
  id: string,
};

export type GetBacklogEventResultQuery = {
  getBacklogEventResult?:  {
    __typename: "BacklogEventResult",
    id: string,
    organizationId?: string | null,
    organizationMemberId?: string | null,
    organizationResultId?: string | null,
    eventId?: string | null,
    eventName?: string | null,
    eventStartDate?: string | null,
    eventEndDate?: string | null,
    eventDivisionId?: string | null,
    eventDivisionName?: string | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventClassId?: string | null,
    eventClassName?: string | null,
    eventClassNumber?: string | null,
    organizationClassId?: string | null,
    organizationClass?:  {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null,
    riderName?: string | null,
    horseName?: string | null,
    place?: number | null,
    pointsEarned?: number | null,
    score?: number | null,
    time?: string | null,
    didNotCompete?: boolean | null,
    prizeMoney?: number | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    backlogEventResultPrizeMoneyTableId?: string | null,
  } | null,
};

export type ListBacklogEventResultsQueryVariables = {
  id?: string | null,
  filter?: ModelBacklogEventResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBacklogEventResultsQuery = {
  listBacklogEventResults?:  {
    __typename: "ModelBacklogEventResultConnection",
    items:  Array< {
      __typename: "BacklogEventResult",
      id: string,
      organizationId?: string | null,
      organizationMemberId?: string | null,
      organizationResultId?: string | null,
      eventId?: string | null,
      eventName?: string | null,
      eventStartDate?: string | null,
      eventEndDate?: string | null,
      eventDivisionId?: string | null,
      eventDivisionName?: string | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClassId?: string | null,
      eventClassName?: string | null,
      eventClassNumber?: string | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      riderName?: string | null,
      horseName?: string | null,
      place?: number | null,
      pointsEarned?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      backlogEventResultPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBarnQueryVariables = {
  id: string,
};

export type GetBarnQuery = {
  getBarn?:  {
    __typename: "Barn",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    type?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ownerName?: string | null,
    ownerPersonId?: string | null,
    secondaryOwnerName?: string | null,
    secondaryOwnerPersonId?: string | null,
    members?:  {
      __typename: "ModelBarnMemberConnection",
      items:  Array< {
        __typename: "BarnMember",
        id: string,
        name: string,
        barnId: string,
        personId: string,
        permissionLevel?: string | null,
        status?: string | null,
        roles?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    barnContactId?: string | null,
    barnLocationId?: string | null,
  } | null,
};

export type ListBarnsQueryVariables = {
  id?: string | null,
  filter?: ModelBarnFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBarnsQuery = {
  listBarns?:  {
    __typename: "ModelBarnConnection",
    items:  Array< {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBarnMemberQueryVariables = {
  id: string,
};

export type GetBarnMemberQuery = {
  getBarnMember?:  {
    __typename: "BarnMember",
    id: string,
    name: string,
    barnId: string,
    barn:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    },
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    permissionLevel?: string | null,
    status?: string | null,
    roles?: string | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListBarnMembersQueryVariables = {
  id?: string | null,
  filter?: ModelBarnMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBarnMembersQuery = {
  listBarnMembers?:  {
    __typename: "ModelBarnMemberConnection",
    items:  Array< {
      __typename: "BarnMember",
      id: string,
      name: string,
      barnId: string,
      barn:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      },
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      permissionLevel?: string | null,
      status?: string | null,
      roles?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBlockQueryVariables = {
  id: string,
};

export type GetBlockQuery = {
  getBlock?:  {
    __typename: "Block",
    id: string,
    name?: string | null,
    type?: string | null,
    personid?: string | null,
    organizationId?: string | null,
    purchasedOn?: string | null,
    expiresOn?: string | null,
    totalCredits?: number | null,
    usedCredits?: number | null,
    status?: BlockStatus | null,
    price?: number | null,
    amountPaid?: number | null,
    isPromotion?: string | null,
    isFree?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListBlocksQueryVariables = {
  id?: string | null,
  filter?: ModelBlockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBlocksQuery = {
  listBlocks?:  {
    __typename: "ModelBlockConnection",
    items:  Array< {
      __typename: "Block",
      id: string,
      name?: string | null,
      type?: string | null,
      personid?: string | null,
      organizationId?: string | null,
      purchasedOn?: string | null,
      expiresOn?: string | null,
      totalCredits?: number | null,
      usedCredits?: number | null,
      status?: BlockStatus | null,
      price?: number | null,
      amountPaid?: number | null,
      isPromotion?: string | null,
      isFree?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClassQueryVariables = {
  id: string,
};

export type GetClassQuery = {
  getClass?:  {
    __typename: "Class",
    id: string,
    name: string,
    nickname?: string | null,
    divisionId?: string | null,
    type?: string | null,
    description?: string | null,
    discipline?: string | null,
    ushjaName?: string | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    ecClassTypeNumber?: string | null,
    ecClassType?: string | null,
    code?: string | null,
    specs?: string | null,
    judgedOn?: string | null,
    rules?:  {
      __typename: "DivisionRules",
      horseSex?: string | null,
      horseBreed?: string | null,
      horseMinHeight?: string | null,
      horseMaxHeight?: string | null,
      horseMinAge?: string | null,
      horseMaxAge?: string | null,
      riderMinAge?: string | null,
      riderMaxAge?: string | null,
      riderProfessionalStatus?: string | null,
      riderRecord?: string | null,
      horseRecord?: string | null,
      crossEntry?: string | null,
      other?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceClassesId?: string | null,
  } | null,
};

export type ListClassesQueryVariables = {
  id?: string | null,
  filter?: ModelClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClassesQuery = {
  listClasses?:  {
    __typename: "ModelClassConnection",
    items:  Array< {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClassOrderQueryVariables = {
  id: string,
};

export type GetClassOrderQuery = {
  getClassOrder?:  {
    __typename: "ClassOrder",
    id: string,
    eventId: string,
    eventClassId: string,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    entryId: string,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    order?: number | null,
    isRandom?: boolean | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListClassOrdersQueryVariables = {
  id?: string | null,
  filter?: ModelClassOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClassOrdersQuery = {
  listClassOrders?:  {
    __typename: "ModelClassOrderConnection",
    items:  Array< {
      __typename: "ClassOrder",
      id: string,
      eventId: string,
      eventClassId: string,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      entryId: string,
      eventEntry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      order?: number | null,
      isRandom?: boolean | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClinicianQueryVariables = {
  id: string,
};

export type GetClinicianQuery = {
  getClinician?:  {
    __typename: "Clinician",
    id: string,
    name: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    judgeId?: string | null,
    contactId?: string | null,
    rating?:  Array< {
      __typename: "JudgeRating",
      type?: JudgeType | null,
      license?: JudgeLicense | null,
    } | null > | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    price?: number | null,
    status?: ClinicianStatus | null,
    paymentChoice?:  {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    paymentVerified?: boolean | null,
    stripeAccountId?: string | null,
    totalEarned?: number | null,
    location?: string | null,
    disciplines?: string | null,
    experience?: string | null,
    personal?: string | null,
    preferences?: string | null,
    hasIHSAExperience?: boolean | null,
    hasIEAExperience?: boolean | null,
    createdOn: string,
    updatedOn: string,
    clinicianPaymentChoiceId?: string | null,
  } | null,
};

export type ListCliniciansQueryVariables = {
  id?: string | null,
  filter?: ModelClinicianFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCliniciansQuery = {
  listClinicians?:  {
    __typename: "ModelClinicianConnection",
    items:  Array< {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompetitionQueryVariables = {
  id: string,
};

export type GetCompetitionQuery = {
  getCompetition?:  {
    __typename: "Competition",
    id: string,
    name: string,
    startDate?: string | null,
    endDate?: string | null,
    organizationId?: string | null,
    teamId?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    competitionOrganizerName: string,
    competitionOrganizerContactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    judgeName?: string | null,
    secretaryName?: string | null,
    StewardName?: string | null,
    isOpenOffered?: boolean | null,
    isIntermediateOffered?: boolean | null,
    isNoviceOffered?: boolean | null,
    isAdvancedBeginnerOffered?: boolean | null,
    isBeginnerOffered?: boolean | null,
    isMinisOffered?: boolean | null,
    isAdultOffered?: boolean | null,
    isAELMedalOffered?: boolean | null,
    entryFeePerRider?: number | null,
    entryFeePerRiderForMedal?: number | null,
    feePaymentOptions?: string | null,
    entryClosingDate?: string | null,
    entryClosingTime?: string | null,
    isEntryLimitInPlace?: boolean | null,
    entryLimitDescription?: string | null,
    otherNotes?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListCompetitionsQueryVariables = {
  id?: string | null,
  filter?: ModelCompetitionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCompetitionsQuery = {
  listCompetitions?:  {
    __typename: "ModelCompetitionConnection",
    items:  Array< {
      __typename: "Competition",
      id: string,
      name: string,
      startDate?: string | null,
      endDate?: string | null,
      organizationId?: string | null,
      teamId?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      competitionOrganizerName: string,
      competitionOrganizerContactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      judgeName?: string | null,
      secretaryName?: string | null,
      StewardName?: string | null,
      isOpenOffered?: boolean | null,
      isIntermediateOffered?: boolean | null,
      isNoviceOffered?: boolean | null,
      isAdvancedBeginnerOffered?: boolean | null,
      isBeginnerOffered?: boolean | null,
      isMinisOffered?: boolean | null,
      isAdultOffered?: boolean | null,
      isAELMedalOffered?: boolean | null,
      entryFeePerRider?: number | null,
      entryFeePerRiderForMedal?: number | null,
      feePaymentOptions?: string | null,
      entryClosingDate?: string | null,
      entryClosingTime?: string | null,
      isEntryLimitInPlace?: boolean | null,
      entryLimitDescription?: string | null,
      otherNotes?: string | null,
      status?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContactQueryVariables = {
  id: string,
};

export type GetContactQuery = {
  getContact?:  {
    __typename: "Contact",
    id: string,
    name?: string | null,
    cell?: string | null,
    home?: string | null,
    work?: string | null,
    fax?: string | null,
    personalEmail?: string | null,
    personalEmailIsVerified?: boolean | null,
    workEmail?: string | null,
    workEmailIsVerified?: boolean | null,
    mailingAddress?: string | null,
    website?: string | null,
    facebookPage?: string | null,
    instagramHandle?: string | null,
    twitterHandle?: string | null,
    tiktokHandle?: string | null,
    emergencyContactName?: string | null,
    emergencyContactPhone?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListContactsQueryVariables = {
  id?: string | null,
  filter?: ModelContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListContactsQuery = {
  listContacts?:  {
    __typename: "ModelContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDivisionQueryVariables = {
  id: string,
};

export type GetDivisionQuery = {
  getDivision?:  {
    __typename: "Division",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    discipline?: string | null,
    isSearchable?: boolean | null,
    ushjaName?: string | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    ecClassTypeNumber?: string | null,
    ecClassType?: string | null,
    code?: string | null,
    specs?: string | null,
    judgedOn?: string | null,
    rules?:  {
      __typename: "DivisionRules",
      horseSex?: string | null,
      horseBreed?: string | null,
      horseMinHeight?: string | null,
      horseMaxHeight?: string | null,
      horseMinAge?: string | null,
      horseMaxAge?: string | null,
      riderMinAge?: string | null,
      riderMaxAge?: string | null,
      riderProfessionalStatus?: string | null,
      riderRecord?: string | null,
      horseRecord?: string | null,
      crossEntry?: string | null,
      other?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceDivisionsId?: string | null,
  } | null,
};

export type ListDivisionsQueryVariables = {
  id?: string | null,
  filter?: ModelDivisionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDivisionsQuery = {
  listDivisions?:  {
    __typename: "ModelDivisionConnection",
    items:  Array< {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    nickname?: string | null,
    createdBy: string,
    manager?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contactId?: string | null,
    contactIds?: Array< string | null > | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    disciplines?: string | null,
    eventOptions?:  {
      __typename: "EventOptions",
      autoAcceptNewEntries?: boolean | null,
      usesTextAlerts?: boolean | null,
      acceptsIncomingTextAlerts?: boolean | null,
      hasUnlimitedCredits?: boolean | null,
      usesEventManagement?: boolean | null,
      usesStablingManagement?: boolean | null,
      usesVendorManagement?: boolean | null,
      usesMediaTools?: boolean | null,
      usesMarketingTools?: boolean | null,
      usesFinanceTools?: boolean | null,
      usesJudgeApp?: boolean | null,
    } | null,
    paymentOptions?:  {
      __typename: "PaymentOptions",
      acceptsChecks?: boolean | null,
      acceptsCash?: boolean | null,
      acceptsACH?: boolean | null,
      acceptsCC?: boolean | null,
    } | null,
    invoiceOptions?:  {
      __typename: "InvoiceOptions",
      classFeeTax?: number | null,
      sendEmails?: boolean | null,
    } | null,
    entryOptions?:  {
      __typename: "EntryOptions",
      displayHorse2Input?: boolean | null,
      displayRider2Input?: boolean | null,
      displayRider3Input?: boolean | null,
      requiresCogginsDocument?: boolean | null,
      requiresCogginsData?: boolean | null,
      maximumNumberClassesOnEntry?: number | null,
    } | null,
    officials?:  {
      __typename: "ModelEventOfficialConnection",
      items:  Array< {
        __typename: "EventOfficial",
        id: string,
        name: string,
        position: string,
        eventId: string,
        personId?: string | null,
        contactId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: string | null,
    entriesStatus?: string | null,
    showStatus?: string | null,
    usefID?: string | null,
    usefSoftwareKey?: string | null,
    ecID?: string | null,
    ecPassword?: string | null,
    documents?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAddressId?: string | null,
  } | null,
};

export type ListEventsQueryVariables = {
  id?: string | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventAddQueryVariables = {
  id: string,
};

export type GetEventAddQuery = {
  getEventAdd?:  {
    __typename: "EventAdd",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventAddsQueryVariables = {
  id?: string | null,
  filter?: ModelEventAddFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventAddsQuery = {
  listEventAdds?:  {
    __typename: "ModelEventAddConnection",
    items:  Array< {
      __typename: "EventAdd",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      status?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventAlertSettingQueryVariables = {
  id: string,
};

export type GetEventAlertSettingQuery = {
  getEventAlertSetting?:  {
    __typename: "EventAlertSetting",
    id: string,
    eventId?: string | null,
    categories?: Array< string | null > | null,
    useDescription?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventAlertSettingsQueryVariables = {
  id?: string | null,
  filter?: ModelEventAlertSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventAlertSettingsQuery = {
  listEventAlertSettings?:  {
    __typename: "ModelEventAlertSettingConnection",
    items:  Array< {
      __typename: "EventAlertSetting",
      id: string,
      eventId?: string | null,
      categories?: Array< string | null > | null,
      useDescription?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventAuditorQueryVariables = {
  id: string,
};

export type GetEventAuditorQuery = {
  getEventAuditor?:  {
    __typename: "EventAuditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    filters?:  {
      __typename: "ModelEventAuditorFilterConnection",
      items:  Array< {
        __typename: "EventAuditorFilter",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        eventauditorid?: string | null,
        eventAuditorId?: string | null,
        organizationId?: string | null,
        organizationAuditorId?: string | null,
        filterId?: string | null,
        options?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorFilterOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    status?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAuditorEventId?: string | null,
  } | null,
};

export type ListEventAuditorsQueryVariables = {
  id?: string | null,
  filter?: ModelEventAuditorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventAuditorsQuery = {
  listEventAuditors?:  {
    __typename: "ModelEventAuditorConnection",
    items:  Array< {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationAuditorQueryVariables = {
  id: string,
};

export type GetOrganizationAuditorQuery = {
  getOrganizationAuditor?:  {
    __typename: "OrganizationAuditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    howSignedUp?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    filters?:  {
      __typename: "ModelEventAuditorFilterConnection",
      items:  Array< {
        __typename: "EventAuditorFilter",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        eventauditorid?: string | null,
        eventAuditorId?: string | null,
        organizationId?: string | null,
        organizationAuditorId?: string | null,
        filterId?: string | null,
        options?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorFilterOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    status?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    organizationAuditorPersonId?: string | null,
    organizationAuditorOrganizationId?: string | null,
  } | null,
};

export type ListOrganizationAuditorsQueryVariables = {
  id?: string | null,
  filter?: ModelOrganizationAuditorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrganizationAuditorsQuery = {
  listOrganizationAuditors?:  {
    __typename: "ModelOrganizationAuditorConnection",
    items:  Array< {
      __typename: "OrganizationAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      howSignedUp?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationAuditorPersonId?: string | null,
      organizationAuditorOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventAuditorFilterQueryVariables = {
  id: string,
};

export type GetEventAuditorFilterQuery = {
  getEventAuditorFilter?:  {
    __typename: "EventAuditorFilter",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    eventauditorid?: string | null,
    eventAuditorId?: string | null,
    eventAuditor?:  {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationAuditorId?: string | null,
    organizationAuditor?:  {
      __typename: "OrganizationAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      howSignedUp?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationAuditorPersonId?: string | null,
      organizationAuditorOrganizationId?: string | null,
    } | null,
    filterId?: string | null,
    filter?:  {
      __typename: "EventFilter",
      id: string,
      eventId?: string | null,
      organizationID?: string | null,
      name?: string | null,
      options?: Array< string | null > | null,
      isRequired?: boolean | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    options?: Array< string | null > | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAuditorFilterOrganizationId?: string | null,
  } | null,
};

export type ListEventAuditorFiltersQueryVariables = {
  id?: string | null,
  filter?: ModelEventAuditorFilterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventAuditorFiltersQuery = {
  listEventAuditorFilters?:  {
    __typename: "ModelEventAuditorFilterConnection",
    items:  Array< {
      __typename: "EventAuditorFilter",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      eventauditorid?: string | null,
      eventAuditorId?: string | null,
      eventAuditor?:  {
        __typename: "EventAuditor",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        auditorId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        status?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorEventId?: string | null,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationAuditorId?: string | null,
      organizationAuditor?:  {
        __typename: "OrganizationAuditor",
        id: string,
        personId?: string | null,
        howSignedUp?: string | null,
        organizationId?: string | null,
        auditorId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        status?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationAuditorPersonId?: string | null,
        organizationAuditorOrganizationId?: string | null,
      } | null,
      filterId?: string | null,
      filter?:  {
        __typename: "EventFilter",
        id: string,
        eventId?: string | null,
        organizationID?: string | null,
        name?: string | null,
        options?: Array< string | null > | null,
        isRequired?: boolean | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      options?: Array< string | null > | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorFilterOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventBeddingTypeQueryVariables = {
  id: string,
};

export type GetEventBeddingTypeQuery = {
  getEventBeddingType?:  {
    __typename: "EventBeddingType",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationBeddingTypeId?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerBag?: string | null,
    taxPerBag?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventBeddingTypesQueryVariables = {
  id?: string | null,
  filter?: ModelEventBeddingTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventBeddingTypesQuery = {
  listEventBeddingTypes?:  {
    __typename: "ModelEventBeddingTypeConnection",
    items:  Array< {
      __typename: "EventBeddingType",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationBeddingTypeId?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      pricePerBag?: string | null,
      taxPerBag?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventBreakQueryVariables = {
  id: string,
};

export type GetEventBreakQuery = {
  getEventBreak?:  {
    __typename: "EventBreak",
    id: string,
    eventId: string,
    name?: string | null,
    time?: string | null,
    isDisplayedOnSchedule?: boolean | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventBreaksQueryVariables = {
  id?: string | null,
  filter?: ModelEventBreakFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventBreaksQuery = {
  listEventBreaks?:  {
    __typename: "ModelEventBreakConnection",
    items:  Array< {
      __typename: "EventBreak",
      id: string,
      eventId: string,
      name?: string | null,
      time?: string | null,
      isDisplayedOnSchedule?: boolean | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventClassQueryVariables = {
  id: string,
};

export type GetEventClassQuery = {
  getEventClass?:  {
    __typename: "EventClass",
    id: string,
    name: string,
    eventId: string,
    classId: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    } | null,
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    organizationClassId?: string | null,
    organizationClass?:  {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventResults?:  {
      __typename: "ModelEventResultConnection",
      items:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    number?: number | null,
    entryOption?: EntryOption | null,
    entryFee?: string | null,
    fees?:  {
      __typename: "ModelEventFeeConnection",
      items:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prizeMoney?: string | null,
    entries?:  {
      __typename: "ModelEventClassEntryConnection",
      items:  Array< {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedVerifyEntries?: boolean | null,
    currentNumberEntries?: number | null,
    numberEntriesThatCompeted?: number | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    estimatedTimePerRound?: number | null,
    isCaliforniaSplit?: boolean | null,
    isCombined?: boolean | null,
    combinedEventClassId?: string | null,
    combinedEventDivisionId?: string | null,
    jumpHeight?: Array< string | null > | null,
    isScheduled?: boolean | null,
    note?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventClassPointTableId?: string | null,
    eventClassPrizeMoneyTableId?: string | null,
  } | null,
};

export type ListEventClassesQueryVariables = {
  id?: string | null,
  filter?: ModelEventClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventClassesQuery = {
  listEventClasses?:  {
    __typename: "ModelEventClassConnection",
    items:  Array< {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventClassEntryQueryVariables = {
  id: string,
};

export type GetEventClassEntryQuery = {
  getEventClassEntry?:  {
    __typename: "EventClassEntry",
    id: string,
    eventId: string,
    eventClassId: string,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventEntryId: string,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    status?: string | null,
    selectedEntryOption?: EntryOption | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventClassEntriesQueryVariables = {
  id?: string | null,
  filter?: ModelEventClassEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventClassEntriesQuery = {
  listEventClassEntries?:  {
    __typename: "ModelEventClassEntryConnection",
    items:  Array< {
      __typename: "EventClassEntry",
      id: string,
      eventId: string,
      eventClassId: string,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventEntryId: string,
      eventEntry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      status?: string | null,
      selectedEntryOption?: EntryOption | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventDayQueryVariables = {
  id: string,
};

export type GetEventDayQuery = {
  getEventDay?:  {
    __typename: "EventDay",
    id: string,
    eventId?: string | null,
    awsdate?: string | null,
    dayofweek?: string | null,
    date?: string | null,
    month?: string | null,
    year?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventDaysQueryVariables = {
  id?: string | null,
  filter?: ModelEventDayFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventDaysQuery = {
  listEventDays?:  {
    __typename: "ModelEventDayConnection",
    items:  Array< {
      __typename: "EventDay",
      id: string,
      eventId?: string | null,
      awsdate?: string | null,
      dayofweek?: string | null,
      date?: string | null,
      month?: string | null,
      year?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventDivisionQueryVariables = {
  id: string,
};

export type GetEventDivisionQuery = {
  getEventDivision?:  {
    __typename: "EventDivision",
    id: string,
    eventId: string,
    name: string,
    number?: number | null,
    divisionId: string,
    division?:  {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    } | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventClasses?:  {
      __typename: "ModelEventClassConnection",
      items:  Array< {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventResults?:  {
      __typename: "ModelEventResultConnection",
      items:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    jumpHeight?: Array< string | null > | null,
    minNumberEntriesPerClass?: number | null,
    maxNumberEntriesPerClass?: number | null,
    isCaliforniaSplit?: boolean | null,
    isCombined?: boolean | null,
    combinedEventDivisionId?: string | null,
    combinedEventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventDivisionOrganizationDivisionId?: string | null,
    eventDivisionPointTableId?: string | null,
    eventDivisionPrizeMoneyTableId?: string | null,
  } | null,
};

export type ListEventDivisionsQueryVariables = {
  id?: string | null,
  filter?: ModelEventDivisionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventDivisionsQuery = {
  listEventDivisions?:  {
    __typename: "ModelEventDivisionConnection",
    items:  Array< {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventEntryQueryVariables = {
  id: string,
};

export type GetEventEntryQuery = {
  getEventEntry?:  {
    __typename: "EventEntry",
    id: string,
    eventId: string,
    status: string,
    statusNote?: string | null,
    personId?: string | null,
    number?: number | null,
    isCheckedIn?: boolean | null,
    isCheckedOut?: boolean | null,
    contactId: string,
    primarycontact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    riderName?: string | null,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    secondRiderName?: string | null,
    secondRiderId?: string | null,
    secondRider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    thirdRiderName?: string | null,
    thirdRiderId?: string | null,
    thirdRider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    horseName?: string | null,
    horseId?: string | null,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    ownerName?: string | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    secondOwnerName?: string | null,
    secondOwnerId?: string | null,
    secondOwner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    thirdOwnerName?: string | null,
    thirdOwnerId?: string | null,
    thirdOwner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    trainerName?: string | null,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    secondTrainerName?: string | null,
    secondTrainerId?: string | null,
    secondTrainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    thirdTrainerName?: string | null,
    thirdTrainerId?: string | null,
    thirdTrainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    stablingRequestId?: string | null,
    stablingRequest?:  {
      __typename: "StablingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      departureDateTime?: string | null,
      stallTypeId?: string | null,
      stallType?:  {
        __typename: "EventStallType",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallTypeId?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        pricePerStall?: string | null,
        taxPerStall?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?: number | null,
      taxB?: number | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      requestNote?: string | null,
      stallSetId?: string | null,
      stallSet?:  {
        __typename: "EventStallSet",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallSetId?: string | null,
        name?: string | null,
        location?: string | null,
        available?: string | null,
        totalAvailable?: number | null,
        used?: string | null,
        totalUsed?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      stallLocation?: string | null,
      stallNumbers?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    beddingRequestId?: string | null,
    beddingRequest?:  {
      __typename: "BeddingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      dateTimeNeeded?: string | null,
      deliveryInfo?: string | null,
      beddingType?: string | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      beddingRequestTaxAId?: string | null,
      beddingRequestTaxBId?: string | null,
    } | null,
    markedDoesNotNeedStalls?: boolean | null,
    payerName?: string | null,
    payerId?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    prizeMoneyRecipientName?: string | null,
    prizeMoneyRecipientId?: string | null,
    prizeMoneyRecipient?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    prizeMoneyRecipientContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientType?: string | null,
    eventRulesAgreements?:  {
      __typename: "ModelEventRulesAgreementConnection",
      items:  Array< {
        __typename: "EventRulesAgreement",
        id: string,
        eventId: string,
        eventRuleId: string,
        eventEntryId?: string | null,
        name?: string | null,
        personId?: string | null,
        personType?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    note?: string | null,
    divisionList?: Array< string | null > | null,
    classList?:  {
      __typename: "ModelEventClassEntryConnection",
      items:  Array< {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fees?:  Array< {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null > | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventEntryPrizeMoneyRecipientContactId?: string | null,
  } | null,
};

export type ListEventEntriesQueryVariables = {
  id?: string | null,
  filter?: ModelEventEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventEntriesQuery = {
  listEventEntries?:  {
    __typename: "ModelEventEntryConnection",
    items:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventEntryFeeQueryVariables = {
  id: string,
};

export type GetEventEntryFeeQuery = {
  getEventEntryFee?:  {
    __typename: "EventEntryFee",
    id: string,
    name: string,
    description?: string | null,
    status?: string | null,
    amount: number,
    quantity?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    eventId: string,
    feeId: string,
    fee?:  {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null,
    eventFeeId?: string | null,
    eventFee?:  {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    entryId?: string | null,
    entry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventEntryFeesQueryVariables = {
  id?: string | null,
  filter?: ModelEventEntryFeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventEntryFeesQuery = {
  listEventEntryFees?:  {
    __typename: "ModelEventEntryFeeConnection",
    items:  Array< {
      __typename: "EventEntryFee",
      id: string,
      name: string,
      description?: string | null,
      status?: string | null,
      amount: number,
      quantity?: number | null,
      taxA?: number | null,
      taxB?: number | null,
      eventId: string,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      eventFeeId?: string | null,
      eventFee?:  {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryId?: string | null,
      entry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventEntryNumbersQueryVariables = {
  id: string,
};

export type GetEventEntryNumbersQuery = {
  getEventEntryNumbers?:  {
    __typename: "EventEntryNumbers",
    id: string,
    eventId: string,
    name?: string | null,
    available?: string | null,
    totalAvailable?: number | null,
    used?: string | null,
    totalUsed?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventEntryNumbersQueryVariables = {
  id?: string | null,
  filter?: ModelEventEntryNumbersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventEntryNumbersQuery = {
  listEventEntryNumbers?:  {
    __typename: "ModelEventEntryNumbersConnection",
    items:  Array< {
      __typename: "EventEntryNumbers",
      id: string,
      eventId: string,
      name?: string | null,
      available?: string | null,
      totalAvailable?: number | null,
      used?: string | null,
      totalUsed?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventFeeQueryVariables = {
  id: string,
};

export type GetEventFeeQuery = {
  getEventFee?:  {
    __typename: "EventFee",
    id: string,
    name: string,
    amount: number,
    description?: string | null,
    eventId: string,
    createdBy: string,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    feeId: string,
    fee?:  {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null > | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventFeesQueryVariables = {
  id?: string | null,
  filter?: ModelEventFeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventFeesQuery = {
  listEventFees?:  {
    __typename: "ModelEventFeeConnection",
    items:  Array< {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventFilterQueryVariables = {
  id: string,
};

export type GetEventFilterQuery = {
  getEventFilter?:  {
    __typename: "EventFilter",
    id: string,
    eventId?: string | null,
    organizationID?: string | null,
    name?: string | null,
    options?: Array< string | null > | null,
    isRequired?: boolean | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventFiltersQueryVariables = {
  id?: string | null,
  filter?: ModelEventFilterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventFiltersQuery = {
  listEventFilters?:  {
    __typename: "ModelEventFilterConnection",
    items:  Array< {
      __typename: "EventFilter",
      id: string,
      eventId?: string | null,
      organizationID?: string | null,
      name?: string | null,
      options?: Array< string | null > | null,
      isRequired?: boolean | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventInvoiceQueryVariables = {
  id: string,
};

export type GetEventInvoiceQuery = {
  getEventInvoice?:  {
    __typename: "EventInvoice",
    id: string,
    eventId: string,
    status: string,
    statusNote?: string | null,
    number: number,
    stripeInvoiceNumber?: string | null,
    entryNumber?: number | null,
    entryId?: string | null,
    entry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    payerName?: string | null,
    payerId?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    payerType?: string | null,
    payerStripeId?: string | null,
    payerContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientName?: string | null,
    prizeMoneyRecipientId?: string | null,
    prizeMoneyRecipientStripeId?: string | null,
    prizeMoneyRecipient?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    prizeMoneyRecipientContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientType?: string | null,
    divisions?:  {
      __typename: "ModelDivisionConnection",
      items:  Array< {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fees?:  {
      __typename: "ModelFeeConnection",
      items:  Array< {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryFeeTotalAmount?: number | null,
    otherFeeTotalAmount?: number | null,
    stablingAmount?: number | null,
    beddingAmount?: number | null,
    feedAmount?: number | null,
    results?:  Array< {
      __typename: "EventResult",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventClassEntryId?: string | null,
      eventClassEntry?:  {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      resultSet?: string | null,
      place?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      pointTables?:  {
        __typename: "ModelPointTableConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      eventResultEntryId: string,
      eventResultEventClassEntryId?: string | null,
      eventResultPrizeMoneyTableId?: string | null,
    } | null > | null,
    prizeMoneyAmount?: number | null,
    amountToDeductFromInvoice?: number | null,
    amountToPayRecipientViaCheck?: number | null,
    totalDue?: number | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoicePayerContactId?: string | null,
    eventInvoicePrizeMoneyRecipientId?: string | null,
    eventInvoicePrizeMoneyRecipientContactId?: string | null,
  } | null,
};

export type ListEventInvoicesQueryVariables = {
  id?: string | null,
  filter?: ModelEventInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventInvoicesQuery = {
  listEventInvoices?:  {
    __typename: "ModelEventInvoiceConnection",
    items:  Array< {
      __typename: "EventInvoice",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      number: number,
      stripeInvoiceNumber?: string | null,
      entryNumber?: number | null,
      entryId?: string | null,
      entry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      payerType?: string | null,
      payerStripeId?: string | null,
      payerContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipientStripeId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      divisions?:  {
        __typename: "ModelDivisionConnection",
        nextToken?: string | null,
      } | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      fees?:  {
        __typename: "ModelFeeConnection",
        nextToken?: string | null,
      } | null,
      entryFeeTotalAmount?: number | null,
      otherFeeTotalAmount?: number | null,
      stablingAmount?: number | null,
      beddingAmount?: number | null,
      feedAmount?: number | null,
      results?:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null > | null,
      prizeMoneyAmount?: number | null,
      amountToDeductFromInvoice?: number | null,
      amountToPayRecipientViaCheck?: number | null,
      totalDue?: number | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoicePayerContactId?: string | null,
      eventInvoicePrizeMoneyRecipientId?: string | null,
      eventInvoicePrizeMoneyRecipientContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventJudgeQueryVariables = {
  id: string,
};

export type GetEventJudgeQuery = {
  getEventJudge?:  {
    __typename: "EventJudge",
    id: string,
    name: string,
    eventId: string,
    personId?: string | null,
    judgeId?: string | null,
    contactId?: string | null,
    rating?:  Array< {
      __typename: "JudgeRating",
      type?: JudgeType | null,
      license?: JudgeLicense | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEventJudgesQueryVariables = {
  id?: string | null,
  filter?: ModelEventJudgeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventJudgesQuery = {
  listEventJudges?:  {
    __typename: "ModelEventJudgeConnection",
    items:  Array< {
      __typename: "EventJudge",
      id: string,
      name: string,
      eventId: string,
      personId?: string | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventOfficialQueryVariables = {
  id: string,
};

export type GetEventOfficialQuery = {
  getEventOfficial?:  {
    __typename: "EventOfficial",
    id: string,
    name: string,
    position: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    personId?: string | null,
    contactId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEventOfficialsQueryVariables = {
  id?: string | null,
  filter?: ModelEventOfficialFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventOfficialsQuery = {
  listEventOfficials?:  {
    __typename: "ModelEventOfficialConnection",
    items:  Array< {
      __typename: "EventOfficial",
      id: string,
      name: string,
      position: string,
      eventId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      personId?: string | null,
      contactId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventResultQueryVariables = {
  id: string,
};

export type GetEventResultQuery = {
  getEventResult?:  {
    __typename: "EventResult",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventClassEntryId?: string | null,
    eventClassEntry?:  {
      __typename: "EventClassEntry",
      id: string,
      eventId: string,
      eventClassId: string,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventEntryId: string,
      eventEntry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      status?: string | null,
      selectedEntryOption?: EntryOption | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    resultSet?: string | null,
    place?: number | null,
    score?: number | null,
    time?: string | null,
    didNotCompete?: boolean | null,
    prizeMoney?: number | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    pointTables?:  {
      __typename: "ModelPointTableConnection",
      items:  Array< {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    note?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    eventResultEntryId: string,
    eventResultEventClassEntryId?: string | null,
    eventResultPrizeMoneyTableId?: string | null,
  } | null,
};

export type ListEventResultsQueryVariables = {
  id?: string | null,
  filter?: ModelEventResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventResultsQuery = {
  listEventResults?:  {
    __typename: "ModelEventResultConnection",
    items:  Array< {
      __typename: "EventResult",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventClassEntryId?: string | null,
      eventClassEntry?:  {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      resultSet?: string | null,
      place?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      pointTables?:  {
        __typename: "ModelPointTableConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      eventResultEntryId: string,
      eventResultEventClassEntryId?: string | null,
      eventResultPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventRingQueryVariables = {
  id: string,
};

export type GetEventRingQuery = {
  getEventRing?:  {
    __typename: "EventRing",
    id: string,
    name: string,
    eventId: string,
    ringId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    ring?:  {
      __typename: "Ring",
      id: string,
      name: string,
      displayOrder?: number | null,
      organizationId?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventRingsQueryVariables = {
  id?: string | null,
  filter?: ModelEventRingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventRingsQuery = {
  listEventRings?:  {
    __typename: "ModelEventRingConnection",
    items:  Array< {
      __typename: "EventRing",
      id: string,
      name: string,
      eventId: string,
      ringId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      ring?:  {
        __typename: "Ring",
        id: string,
        name: string,
        displayOrder?: number | null,
        organizationId?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventRulesQueryVariables = {
  id: string,
};

export type GetEventRulesQuery = {
  getEventRules?:  {
    __typename: "EventRules",
    id: string,
    eventId: string,
    title?: string | null,
    text?: string | null,
    requiresAgreementPerEntry?: boolean | null,
    agreements?:  {
      __typename: "ModelEventRulesAgreementConnection",
      items:  Array< {
        __typename: "EventRulesAgreement",
        id: string,
        eventId: string,
        eventRuleId: string,
        eventEntryId?: string | null,
        name?: string | null,
        personId?: string | null,
        personType?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventRulesQueryVariables = {
  id?: string | null,
  filter?: ModelEventRulesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventRulesQuery = {
  listEventRules?:  {
    __typename: "ModelEventRulesConnection",
    items:  Array< {
      __typename: "EventRules",
      id: string,
      eventId: string,
      title?: string | null,
      text?: string | null,
      requiresAgreementPerEntry?: boolean | null,
      agreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventRulesAgreementQueryVariables = {
  id: string,
};

export type GetEventRulesAgreementQuery = {
  getEventRulesAgreement?:  {
    __typename: "EventRulesAgreement",
    id: string,
    eventId: string,
    eventRuleId: string,
    eventRule?:  {
      __typename: "EventRules",
      id: string,
      eventId: string,
      title?: string | null,
      text?: string | null,
      requiresAgreementPerEntry?: boolean | null,
      agreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventEntryId?: string | null,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    name?: string | null,
    personId?: string | null,
    personType?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventRulesAgreementsQueryVariables = {
  id?: string | null,
  filter?: ModelEventRulesAgreementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventRulesAgreementsQuery = {
  listEventRulesAgreements?:  {
    __typename: "ModelEventRulesAgreementConnection",
    items:  Array< {
      __typename: "EventRulesAgreement",
      id: string,
      eventId: string,
      eventRuleId: string,
      eventRule?:  {
        __typename: "EventRules",
        id: string,
        eventId: string,
        title?: string | null,
        text?: string | null,
        requiresAgreementPerEntry?: boolean | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventEntryId?: string | null,
      eventEntry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      name?: string | null,
      personId?: string | null,
      personType?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventScratchQueryVariables = {
  id: string,
};

export type GetEventScratchQuery = {
  getEventScratch?:  {
    __typename: "EventScratch",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventScratchesQueryVariables = {
  id?: string | null,
  filter?: ModelEventScratchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventScratchesQuery = {
  listEventScratches?:  {
    __typename: "ModelEventScratchConnection",
    items:  Array< {
      __typename: "EventScratch",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      status?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventStallSetQueryVariables = {
  id: string,
};

export type GetEventStallSetQuery = {
  getEventStallSet?:  {
    __typename: "EventStallSet",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationStallSetId?: string | null,
    name?: string | null,
    location?: string | null,
    available?: string | null,
    totalAvailable?: number | null,
    used?: string | null,
    totalUsed?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventStallSetsQueryVariables = {
  id?: string | null,
  filter?: ModelEventStallSetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventStallSetsQuery = {
  listEventStallSets?:  {
    __typename: "ModelEventStallSetConnection",
    items:  Array< {
      __typename: "EventStallSet",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallSetId?: string | null,
      name?: string | null,
      location?: string | null,
      available?: string | null,
      totalAvailable?: number | null,
      used?: string | null,
      totalUsed?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventStallTypeQueryVariables = {
  id: string,
};

export type GetEventStallTypeQuery = {
  getEventStallType?:  {
    __typename: "EventStallType",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationStallTypeId?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerStall?: string | null,
    taxPerStall?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListEventStallTypesQueryVariables = {
  id?: string | null,
  filter?: ModelEventStallTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEventStallTypesQuery = {
  listEventStallTypes?:  {
    __typename: "ModelEventStallTypeConnection",
    items:  Array< {
      __typename: "EventStallType",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallTypeId?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      pricePerStall?: string | null,
      taxPerStall?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFeeQueryVariables = {
  id: string,
};

export type GetFeeQuery = {
  getFee?:  {
    __typename: "Fee",
    id: string,
    name: string,
    description?: string | null,
    createdBy: string,
    organizationId?: string | null,
    stripeId?: string | null,
    altId?: string | null,
    isFixed?: boolean | null,
    isPercentage?: boolean | null,
    totalPrice?: number | null,
    quantity?: number | null,
    isPerEntry?: boolean | null,
    isPerHorse?: boolean | null,
    isPerRider?: boolean | null,
    isPerClass?: boolean | null,
    isPerTrainer?: boolean | null,
    isRefundable?: boolean | null,
    isChargedAtTimeOfEntry?: boolean | null,
    isStartedOnEntryDate?: boolean | null,
    isHiddenFromEntryForm?: boolean | null,
    startDate?: string | null,
    startTime?: string | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceFeesId?: string | null,
    feeTaxRateAId?: string | null,
    feeTaxRateBId?: string | null,
  } | null,
};

export type ListFeesQueryVariables = {
  id?: string | null,
  filter?: ModelFeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFeesQuery = {
  listFees?:  {
    __typename: "ModelFeeConnection",
    items:  Array< {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFeedbackQueryVariables = {
  id: string,
};

export type GetFeedbackQuery = {
  getFeedback?:  {
    __typename: "Feedback",
    id: string,
    clinicianId?: string | null,
    personId?: string | null,
    technical?: string | null,
    style?: string | null,
    turnout?: string | null,
    general?: string | null,
    score?: number | null,
    status?: FeedbackStatus | null,
    submissionFeedback?:  {
      __typename: "SubmissionFeedback",
      id: string,
      type?: string | null,
      submission?:  {
        __typename: "Submission",
        id: string,
        title: string,
        description?: string | null,
        videoLength?: string | null,
        riderId?: string | null,
        personId?: string | null,
        totalPrice: number,
        status?: SubmissionStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
      } | null,
      submissionId: string,
      feedback:  {
        __typename: "Feedback",
        id: string,
        clinicianId?: string | null,
        personId?: string | null,
        technical?: string | null,
        style?: string | null,
        turnout?: string | null,
        general?: string | null,
        score?: number | null,
        status?: FeedbackStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        feedbackSubmissionFeedbackId?: string | null,
      },
      feedbackId: string,
      personId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      clinician?:  {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null,
      clinicianId?: string | null,
      submissionStatus?: SubmissionStatus | null,
      feedbackStatus?: FeedbackStatus | null,
      clinicianAmount?: number | null,
      declineReason?: string | null,
      permissionToPost?: boolean | null,
      dateSubmitted?: string | null,
      dateCompleted?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      submissionFeedbackClinicianId?: string | null,
    } | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    feedbackSubmissionFeedbackId?: string | null,
  } | null,
};

export type ListFeedbacksQueryVariables = {
  id?: string | null,
  filter?: ModelFeedbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFeedbacksQuery = {
  listFeedbacks?:  {
    __typename: "ModelFeedbackConnection",
    items:  Array< {
      __typename: "Feedback",
      id: string,
      clinicianId?: string | null,
      personId?: string | null,
      technical?: string | null,
      style?: string | null,
      turnout?: string | null,
      general?: string | null,
      score?: number | null,
      status?: FeedbackStatus | null,
      submissionFeedback?:  {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      feedbackSubmissionFeedbackId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGameInputQueryVariables = {
  id: string,
};

export type GetGameInputQuery = {
  getGameInput?:  {
    __typename: "GameInput",
    id: string,
    eventId?: string | null,
    eventClassId?: string | null,
    backNumber?: string | null,
    round?: string | null,
    score?: number | null,
    isMasterRecord: boolean,
    createdBy: string,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListGameInputsQueryVariables = {
  id?: string | null,
  filter?: ModelGameInputFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGameInputsQuery = {
  listGameInputs?:  {
    __typename: "ModelGameInputConnection",
    items:  Array< {
      __typename: "GameInput",
      id: string,
      eventId?: string | null,
      eventClassId?: string | null,
      backNumber?: string | null,
      round?: string | null,
      score?: number | null,
      isMasterRecord: boolean,
      createdBy: string,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHorseQueryVariables = {
  id: string,
};

export type GetHorseQuery = {
  getHorse?:  {
    __typename: "Horse",
    id: string,
    name: string,
    nickname?: string | null,
    previousNames?: Array< string | null > | null,
    personId?: string | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    ownerName: string,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    microchipNumber?: string | null,
    passportNumber?: string | null,
    dateOfBirth?: string | null,
    breed?: string | null,
    sex?: string | null,
    color?: string | null,
    markings?: string | null,
    height?: string | null,
    foalState?: string | null,
    cogginsNumber?: string | null,
    cogginsDate?: string | null,
    cogginsState?: string | null,
    coggins?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    highestLevelCompeted?: string | null,
    greenEligibility?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListHorsesQueryVariables = {
  id?: string | null,
  filter?: ModelHorseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHorsesQuery = {
  listHorses?:  {
    __typename: "ModelHorseConnection",
    items:  Array< {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPedigreeQueryVariables = {
  id: string,
};

export type GetPedigreeQuery = {
  getPedigree?:  {
    __typename: "Pedigree",
    id: string,
    horseId: string,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    sireId?: string | null,
    sireName?: string | null,
    sire?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    damId?: string | null,
    damName?: string | null,
    dam?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    birthplace?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    breederName?: string | null,
    breederContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    breederId?: string | null,
    breedRegistryInfo?: string | null,
    createdOn: string,
    updatedOn: string,
    pedigreeBirthplaceId?: string | null,
    pedigreeBreederContactId?: string | null,
  } | null,
};

export type ListPedigreesQueryVariables = {
  id?: string | null,
  filter?: ModelPedigreeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPedigreesQuery = {
  listPedigrees?:  {
    __typename: "ModelPedigreeConnection",
    items:  Array< {
      __typename: "Pedigree",
      id: string,
      horseId: string,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      sireId?: string | null,
      sireName?: string | null,
      sire?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      damId?: string | null,
      damName?: string | null,
      dam?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      birthplace?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      breederName?: string | null,
      breederContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      breederId?: string | null,
      breedRegistryInfo?: string | null,
      createdOn: string,
      updatedOn: string,
      pedigreeBirthplaceId?: string | null,
      pedigreeBreederContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMeasurementRecordQueryVariables = {
  id: string,
};

export type GetMeasurementRecordQuery = {
  getMeasurementRecord?:  {
    __typename: "MeasurementRecord",
    id: string,
    horseId: string,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    date?: string | null,
    status?: string | null,
    height?: string | null,
    heightOfHeel?: string | null,
    shoeingStatus?: string | null,
    steward?: string | null,
    official?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListMeasurementRecordsQueryVariables = {
  id?: string | null,
  filter?: ModelMeasurementRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMeasurementRecordsQuery = {
  listMeasurementRecords?:  {
    __typename: "ModelMeasurementRecordConnection",
    items:  Array< {
      __typename: "MeasurementRecord",
      id: string,
      horseId: string,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      date?: string | null,
      status?: string | null,
      height?: string | null,
      heightOfHeel?: string | null,
      shoeingStatus?: string | null,
      steward?: string | null,
      official?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHorseShowQueryVariables = {
  id: string,
};

export type GetHorseShowQuery = {
  getHorseShow?:  {
    __typename: "HorseShow",
    id: string,
    name: string,
    nickname?: string | null,
    eventId?: string | null,
    ratings?:  Array< {
      __typename: "HorseShowRating",
      id: string,
      eventId: string,
      governingBody?: string | null,
      rating?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    judges?:  Array< {
      __typename: "EventJudge",
      id: string,
      name: string,
      eventId: string,
      personId?: string | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListHorseShowsQueryVariables = {
  id?: string | null,
  filter?: ModelHorseShowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHorseShowsQuery = {
  listHorseShows?:  {
    __typename: "ModelHorseShowConnection",
    items:  Array< {
      __typename: "HorseShow",
      id: string,
      name: string,
      nickname?: string | null,
      eventId?: string | null,
      ratings?:  Array< {
        __typename: "HorseShowRating",
        id: string,
        eventId: string,
        governingBody?: string | null,
        rating?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      judges?:  Array< {
        __typename: "EventJudge",
        id: string,
        name: string,
        eventId: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHorseShowRatingQueryVariables = {
  id: string,
};

export type GetHorseShowRatingQuery = {
  getHorseShowRating?:  {
    __typename: "HorseShowRating",
    id: string,
    eventId: string,
    governingBody?: string | null,
    rating?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListHorseShowRatingsQueryVariables = {
  id?: string | null,
  filter?: ModelHorseShowRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHorseShowRatingsQuery = {
  listHorseShowRatings?:  {
    __typename: "ModelHorseShowRatingConnection",
    items:  Array< {
      __typename: "HorseShowRating",
      id: string,
      eventId: string,
      governingBody?: string | null,
      rating?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInvitationQueryVariables = {
  id: string,
};

export type GetInvitationQuery = {
  getInvitation?:  {
    __typename: "Invitation",
    id: string,
    createdBy: string,
    creatorEmail?: string | null,
    invitee?: string | null,
    inviteeEmail?: string | null,
    status?: InvitationStatus | null,
    organizationId?: string | null,
    barnId?: string | null,
    permissionLevel?: string | null,
    roles?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListInvitationsQueryVariables = {
  id?: string | null,
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInvitationsQuery = {
  listInvitations?:  {
    __typename: "ModelInvitationConnection",
    items:  Array< {
      __typename: "Invitation",
      id: string,
      createdBy: string,
      creatorEmail?: string | null,
      invitee?: string | null,
      inviteeEmail?: string | null,
      status?: InvitationStatus | null,
      organizationId?: string | null,
      barnId?: string | null,
      permissionLevel?: string | null,
      roles?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMembershipQueryVariables = {
  id: string,
};

export type GetMembershipQuery = {
  getMembership?:  {
    __typename: "Membership",
    id: string,
    name: string,
    description?: string | null,
    membershipId: string,
    backNumber?: number | null,
    membershipStatus?: string | null,
    amountPaid?: number | null,
    organizationMembershipTypeId?: string | null,
    organizationMembershipType?:  {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    } | null,
    type?: string | null,
    personName?: string | null,
    personId: string,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    horseId?: string | null,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    teamId?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationName?: string | null,
    isJunior?: boolean | null,
    isProfessional?: boolean | null,
    dateMembershipEnds?: string | null,
    volunteerHours?: number | null,
    meetingsAttended?: number | null,
    showsAttended?: number | null,
    personEmail?: string | null,
    gradeLevel?: string | null,
    aelDivision?: string | null,
    aelTeamRole?: TeamRole | null,
    documents?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    organizationAgreements?:  Array< {
      __typename: "OrganizationAgreements",
      organizationRuleId?: string | null,
      organizationRuleTitle: string,
      firstName: string,
      lastName: string,
      personId?: string | null,
      createdOn: string,
      updatedOn?: string | null,
    } | null > | null,
    contactPersonId?: string | null,
    groupContactId?: string | null,
    groupContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    groupLeaderContactId?: string | null,
    groupLeaderContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    personMembershipsId?: string | null,
    membershipOrganizationId?: string | null,
  } | null,
};

export type ListMembershipsQueryVariables = {
  id?: string | null,
  filter?: ModelMembershipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMembershipsQuery = {
  listMemberships?:  {
    __typename: "ModelMembershipConnection",
    items:  Array< {
      __typename: "Membership",
      id: string,
      name: string,
      description?: string | null,
      membershipId: string,
      backNumber?: number | null,
      membershipStatus?: string | null,
      amountPaid?: number | null,
      organizationMembershipTypeId?: string | null,
      organizationMembershipType?:  {
        __typename: "OrganizationMembershipType",
        id: string,
        name: string,
        description?: string | null,
        order?: number | null,
        organizationId?: string | null,
        membershipIdValues?: string | null,
        nextAvailableMembershipId?: string | null,
        price?: number | null,
        category?: MembershipTypeCategory | null,
        schedule?: MembershipTypeSchedule | null,
        isStartAtTimeOfPurchase?: boolean | null,
        setStartDate?: string | null,
        acceptOnlineApplications?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationMembershipTypeOrganizationId?: string | null,
      } | null,
      type?: string | null,
      personName?: string | null,
      personId: string,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      teamId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationName?: string | null,
      isJunior?: boolean | null,
      isProfessional?: boolean | null,
      dateMembershipEnds?: string | null,
      volunteerHours?: number | null,
      meetingsAttended?: number | null,
      showsAttended?: number | null,
      personEmail?: string | null,
      gradeLevel?: string | null,
      aelDivision?: string | null,
      aelTeamRole?: TeamRole | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      organizationAgreements?:  Array< {
        __typename: "OrganizationAgreements",
        organizationRuleId?: string | null,
        organizationRuleTitle: string,
        firstName: string,
        lastName: string,
        personId?: string | null,
        createdOn: string,
        updatedOn?: string | null,
      } | null > | null,
      contactPersonId?: string | null,
      groupContactId?: string | null,
      groupContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      groupLeaderContactId?: string | null,
      groupLeaderContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      personMembershipsId?: string | null,
      membershipOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationMembershipTypeQueryVariables = {
  id: string,
};

export type GetOrganizationMembershipTypeQuery = {
  getOrganizationMembershipType?:  {
    __typename: "OrganizationMembershipType",
    id: string,
    name: string,
    description?: string | null,
    order?: number | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    membershipIdValues?: string | null,
    nextAvailableMembershipId?: string | null,
    price?: number | null,
    category?: MembershipTypeCategory | null,
    schedule?: MembershipTypeSchedule | null,
    isStartAtTimeOfPurchase?: boolean | null,
    setStartDate?: string | null,
    acceptOnlineApplications?: string | null,
    applicationFields?:  {
      __typename: "ApplicationFields",
      firstName?: boolean | null,
      lastName?: boolean | null,
      dateOfBirth?: boolean | null,
      contact?: boolean | null,
      gradeLevel?: boolean | null,
      barnName?: boolean | null,
      teamName?: boolean | null,
      backNumber?: boolean | null,
      groupName?: boolean | null,
      groupLeader?: boolean | null,
      groupLeaderContactInfo?: boolean | null,
      collectMemberDetailsTogether?: boolean | null,
      maxNumberOfGroupMembers?: number | null,
      isTeam?: boolean | null,
      horseName?: boolean | null,
      horseHeight?: boolean | null,
      horseOwnerName?: boolean | null,
      cogginsInfo?: boolean | null,
      cogginsUpload?: boolean | null,
    } | null,
    applicationOptions?:  {
      __typename: "ApplicationOptions",
      requireApproval?: boolean | null,
      autoAssignIds?: boolean | null,
      acceptRSPPayment?: boolean | null,
      acceptOtherPayment?: boolean | null,
    } | null,
    organizationDocuments?:  Array< {
      __typename: "OrganizationDocument",
      id: string,
      name: string,
    } | null > | null,
    organizationRules?:  Array< {
      __typename: "OrganizationRule",
      id: string,
      title: string,
      body: string,
      isRequired?: boolean | null,
    } | null > | null,
    seasons?:  {
      __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
      items:  Array< {
        __typename: "OrganizationMembershipTypeSeasons",
        id: string,
        organizationMembershipTypeID: string,
        seasonID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdOn: string,
    updatedOn: string,
    organizationMembershipTypeOrganizationId?: string | null,
  } | null,
};

export type ListOrganizationMembershipTypesQueryVariables = {
  id?: string | null,
  filter?: ModelOrganizationMembershipTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrganizationMembershipTypesQuery = {
  listOrganizationMembershipTypes?:  {
    __typename: "ModelOrganizationMembershipTypeConnection",
    items:  Array< {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    type?: OrganizationType | null,
    industry?: string | null,
    addresses?: Array< string | null > | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeId?: string | null,
    stripeCustomerId?: string | null,
    paymentMethodId?: string | null,
    website?: string | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    organizationOptions?:  {
      __typename: "OrganizationOptions",
      usesTextAlerts?: boolean | null,
      acceptsIncomingTextAlerts?: boolean | null,
    } | null,
    urlBackHalf?: string | null,
    colors?: Array< string | null > | null,
    logos?:  Array< {
      __typename: "Logo",
      logoURL?: string | null,
      key?: string | null,
      logo?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
    } | null > | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  id?: string | null,
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationBeddingTypeQueryVariables = {
  id: string,
};

export type GetOrganizationBeddingTypeQuery = {
  getOrganizationBeddingType?:  {
    __typename: "OrganizationBeddingType",
    id: string,
    organizationId: string,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerBag?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateIdA?: string | null,
    taxRateIdB?: string | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListOrganizationBeddingTypesQueryVariables = {
  id?: string | null,
  filter?: ModelOrganizationBeddingTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrganizationBeddingTypesQuery = {
  listOrganizationBeddingTypes?:  {
    __typename: "ModelOrganizationBeddingTypeConnection",
    items:  Array< {
      __typename: "OrganizationBeddingType",
      id: string,
      organizationId: string,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      pricePerBag?: number | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateIdA?: string | null,
      taxRateIdB?: string | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationClassQueryVariables = {
  id: string,
};

export type GetOrganizationClassQuery = {
  getOrganizationClass?:  {
    __typename: "OrganizationClass",
    id: string,
    name: string,
    classId: string,
    class:  {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    },
    organizationId: string,
    organizationDivisionId?: string | null,
    hasChampionship?: boolean | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    number?: number | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    jumpHeight?: Array< string | null > | null,
    estimatedTimePerRound?: number | null,
    createdBy: string,
    displayOrder?: number | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    organizationClassPointTableId?: string | null,
    organizationClassPrizeMoneyTableId?: string | null,
  } | null,
};

export type ListOrganizationClassesQueryVariables = {
  id?: string | null,
  filter?: ModelOrganizationClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrganizationClassesQuery = {
  listOrganizationClasses?:  {
    __typename: "ModelOrganizationClassConnection",
    items:  Array< {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationDivisionQueryVariables = {
  id: string,
};

export type GetOrganizationDivisionQuery = {
  getOrganizationDivision?:  {
    __typename: "OrganizationDivision",
    id: string,
    name: string,
    number?: number | null,
    divisionId: string,
    division:  {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    },
    organizationId: string,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    jumpHeight?: Array< string | null > | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    entryFeePerClass?: string | null,
    prizeMoneyPerClass?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    displayOrder?: number | null,
    createdOn: string,
    updatedOn: string,
    organizationDivisionPointTableId?: string | null,
    organizationDivisionPrizeMoneyTableId?: string | null,
  } | null,
};

export type ListOrganizationDivisionsQueryVariables = {
  id?: string | null,
  filter?: ModelOrganizationDivisionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrganizationDivisionsQuery = {
  listOrganizationDivisions?:  {
    __typename: "ModelOrganizationDivisionConnection",
    items:  Array< {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationStallSetQueryVariables = {
  id: string,
};

export type GetOrganizationStallSetQuery = {
  getOrganizationStallSet?:  {
    __typename: "OrganizationStallSet",
    id: string,
    organizationId: string,
    name?: string | null,
    location?: string | null,
    available?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListOrganizationStallSetsQueryVariables = {
  id?: string | null,
  filter?: ModelOrganizationStallSetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrganizationStallSetsQuery = {
  listOrganizationStallSets?:  {
    __typename: "ModelOrganizationStallSetConnection",
    items:  Array< {
      __typename: "OrganizationStallSet",
      id: string,
      organizationId: string,
      name?: string | null,
      location?: string | null,
      available?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationStallTypeQueryVariables = {
  id: string,
};

export type GetOrganizationStallTypeQuery = {
  getOrganizationStallType?:  {
    __typename: "OrganizationStallType",
    id: string,
    organizationId: string,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerStall?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateIdA?: string | null,
    taxRateIdB?: string | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListOrganizationStallTypesQueryVariables = {
  id?: string | null,
  filter?: ModelOrganizationStallTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrganizationStallTypesQuery = {
  listOrganizationStallTypes?:  {
    __typename: "ModelOrganizationStallTypeConnection",
    items:  Array< {
      __typename: "OrganizationStallType",
      id: string,
      organizationId: string,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      pricePerStall?: number | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateIdA?: string | null,
      taxRateIdB?: string | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOwnerQueryVariables = {
  id: string,
};

export type GetOwnerQuery = {
  getOwner?:  {
    __typename: "Owner",
    id: string,
    name: string,
    type?: string | null,
    location?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnId?: string | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    ownerContactId?: string | null,
  } | null,
};

export type ListOwnersQueryVariables = {
  id?: string | null,
  filter?: ModelOwnerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOwnersQuery = {
  listOwners?:  {
    __typename: "ModelOwnerConnection",
    items:  Array< {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPayerQueryVariables = {
  id: string,
};

export type GetPayerQuery = {
  getPayer?:  {
    __typename: "Payer",
    id: string,
    name: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    paymentMethod?:  {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    payerContactId?: string | null,
    payerPaymentMethodId?: string | null,
  } | null,
};

export type ListPayersQueryVariables = {
  id?: string | null,
  filter?: ModelPayerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPayersQuery = {
  listPayers?:  {
    __typename: "ModelPayerConnection",
    items:  Array< {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentMethodQueryVariables = {
  id: string,
};

export type GetPaymentMethodQuery = {
  getPaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    personId?: string | null,
    organizationId?: string | null,
    type?: PaymentMethodType | null,
    stripeId?: string | null,
    altId?: string | null,
    value?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListPaymentMethodsQueryVariables = {
  id?: string | null,
  filter?: ModelPaymentMethodFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPaymentMethodsQuery = {
  listPaymentMethods?:  {
    __typename: "ModelPaymentMethodConnection",
    items:  Array< {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPersonQueryVariables = {
  id: string,
};

export type GetPersonQuery = {
  getPerson?:  {
    __typename: "Person",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    isVerified?: boolean | null,
    roles?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    personalInformationId?: string | null,
    personalInformation?:  {
      __typename: "PersonalInformation",
      id: string,
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      dateOfBirth?: string | null,
      gender?: Gender | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pictureURL?: string | null,
    picture?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    disciplines?: string | null,
    stripeId?: string | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListPeopleQueryVariables = {
  id?: string | null,
  filter?: ModelPersonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPeopleQuery = {
  listPeople?:  {
    __typename: "ModelPersonConnection",
    items:  Array< {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPersonNameQueryVariables = {
  id: string,
};

export type GetPersonNameQuery = {
  getPersonName?:  {
    __typename: "PersonName",
    id: string,
    personId: string,
    title?: Title | null,
    firstName?: string | null,
    middleName?: string | null,
    maidenName?: string | null,
    lastName?: string | null,
    suffix?: string | null,
    legalName?: string | null,
    displayName?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPersonNamesQueryVariables = {
  id?: string | null,
  filter?: ModelPersonNameFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPersonNamesQuery = {
  listPersonNames?:  {
    __typename: "ModelPersonNameConnection",
    items:  Array< {
      __typename: "PersonName",
      id: string,
      personId: string,
      title?: Title | null,
      firstName?: string | null,
      middleName?: string | null,
      maidenName?: string | null,
      lastName?: string | null,
      suffix?: string | null,
      legalName?: string | null,
      displayName?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPersonalInformationQueryVariables = {
  id: string,
};

export type GetPersonalInformationQuery = {
  getPersonalInformation?:  {
    __typename: "PersonalInformation",
    id: string,
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    dateOfBirth?: string | null,
    gender?: Gender | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPersonalInformationsQueryVariables = {
  id?: string | null,
  filter?: ModelPersonalInformationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPersonalInformationsQuery = {
  listPersonalInformations?:  {
    __typename: "ModelPersonalInformationConnection",
    items:  Array< {
      __typename: "PersonalInformation",
      id: string,
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      dateOfBirth?: string | null,
      gender?: Gender | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPointTableQueryVariables = {
  id: string,
};

export type GetPointTableQuery = {
  getPointTable?:  {
    __typename: "PointTable",
    id: string,
    name: string,
    description?: string | null,
    displayOrder?: number | null,
    organizationId?: string | null,
    discipline?: string | null,
    isAddValueToRegularFromEntryCount?: boolean | null,
    isAddValueToChampionshipFromEntryCount?: boolean | null,
    isUseChampionRule?: boolean | null,
    isUseReserveRule?: boolean | null,
    tiers?:  Array< {
      __typename: "PointTier",
      lowEntryCount?: number | null,
      highEntryCount?: number | null,
      first?: string | null,
      second?: string | null,
      third?: string | null,
      fourth?: string | null,
      fifth?: string | null,
      sixth?: string | null,
      seventh?: string | null,
      eighth?: string | null,
      ninth?: string | null,
      tenth?: string | null,
      eleventh?: string | null,
      twelfth?: string | null,
      champion?: string | null,
      reserve?: string | null,
    } | null > | null,
    numberEntriesToReceiveSpecialClassPoints?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventResultPointTablesId?: string | null,
  } | null,
};

export type ListPointTablesQueryVariables = {
  id?: string | null,
  filter?: ModelPointTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPointTablesQuery = {
  listPointTables?:  {
    __typename: "ModelPointTableConnection",
    items:  Array< {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPollQueryVariables = {
  id: string,
};

export type GetPollQuery = {
  getPoll?:  {
    __typename: "Poll",
    id: string,
    title: string,
    description?: string | null,
    responses?:  {
      __typename: "ModelResponseConnection",
      items:  Array< {
        __typename: "Response",
        id: string,
        pollId?: string | null,
        personId: string,
        value?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPollsQueryVariables = {
  id?: string | null,
  filter?: ModelPollFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPollsQuery = {
  listPolls?:  {
    __typename: "ModelPollConnection",
    items:  Array< {
      __typename: "Poll",
      id: string,
      title: string,
      description?: string | null,
      responses?:  {
        __typename: "ModelResponseConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPostQueryVariables = {
  id: string,
};

export type GetPostQuery = {
  getPost?:  {
    __typename: "Post",
    id: string,
    title: string,
    status: PostStatus,
    rating?: number | null,
    content?: string | null,
    organizationId?: string | null,
    createdBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPostsQueryVariables = {
  id?: string | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPostsQuery = {
  listPosts?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      title: string,
      status: PostStatus,
      rating?: number | null,
      content?: string | null,
      organizationId?: string | null,
      createdBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPrizeMoneyTableQueryVariables = {
  id: string,
};

export type GetPrizeMoneyTableQuery = {
  getPrizeMoneyTable?:  {
    __typename: "PrizeMoneyTable",
    id: string,
    name: string,
    description?: string | null,
    displayOrder?: number | null,
    organizationId?: string | null,
    discipline?: string | null,
    isPercentage?: boolean | null,
    totalPot?: number | null,
    tiers?:  {
      __typename: "PrizeMoneyTiers",
      first?: string | null,
      second?: string | null,
      third?: string | null,
      fourth?: string | null,
      fifth?: string | null,
      sixth?: string | null,
      seventh?: string | null,
      eighth?: string | null,
      ninth?: string | null,
      tenth?: string | null,
      eleventh?: string | null,
      twelfth?: string | null,
      champion?: string | null,
      reserve?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListPrizeMoneyTablesQueryVariables = {
  id?: string | null,
  filter?: ModelPrizeMoneyTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPrizeMoneyTablesQuery = {
  listPrizeMoneyTables?:  {
    __typename: "ModelPrizeMoneyTableConnection",
    items:  Array< {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPromoCodeQueryVariables = {
  id: string,
};

export type GetPromoCodeQuery = {
  getPromoCode?:  {
    __typename: "PromoCode",
    id: string,
    name: string,
    stripeId?: string | null,
    isvalid?: boolean | null,
    isfundraiser?: boolean | null,
    ispercentdiscount?: boolean | null,
    isfixeddiscount?: boolean | null,
    percent?: number | null,
    fixed?: number | null,
    minamount?: number | null,
    startdate?: string | null,
    enddate?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListPromoCodesQueryVariables = {
  id?: string | null,
  filter?: ModelPromoCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPromoCodesQuery = {
  listPromoCodes?:  {
    __typename: "ModelPromoCodeConnection",
    items:  Array< {
      __typename: "PromoCode",
      id: string,
      name: string,
      stripeId?: string | null,
      isvalid?: boolean | null,
      isfundraiser?: boolean | null,
      ispercentdiscount?: boolean | null,
      isfixeddiscount?: boolean | null,
      percent?: number | null,
      fixed?: number | null,
      minamount?: number | null,
      startdate?: string | null,
      enddate?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetResponseQueryVariables = {
  id: string,
};

export type GetResponseQuery = {
  getResponse?:  {
    __typename: "Response",
    id: string,
    pollId?: string | null,
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListResponsesQueryVariables = {
  id?: string | null,
  filter?: ModelResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListResponsesQuery = {
  listResponses?:  {
    __typename: "ModelResponseConnection",
    items:  Array< {
      __typename: "Response",
      id: string,
      pollId?: string | null,
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      value?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRiderQueryVariables = {
  id: string,
};

export type GetRiderQuery = {
  getRider?:  {
    __typename: "Rider",
    id: string,
    name: string,
    location?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    age?: number | null,
    birthdate?: string | null,
    isProfessional?: boolean | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    createdAt: string,
    updatedAt: string,
    riderContactId?: string | null,
  } | null,
};

export type ListRidersQueryVariables = {
  id?: string | null,
  filter?: ModelRiderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListRidersQuery = {
  listRiders?:  {
    __typename: "ModelRiderConnection",
    items:  Array< {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRingQueryVariables = {
  id: string,
};

export type GetRingQuery = {
  getRing?:  {
    __typename: "Ring",
    id: string,
    name: string,
    displayOrder?: number | null,
    organizationId?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListRingsQueryVariables = {
  id?: string | null,
  filter?: ModelRingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListRingsQuery = {
  listRings?:  {
    __typename: "ModelRingConnection",
    items:  Array< {
      __typename: "Ring",
      id: string,
      name: string,
      displayOrder?: number | null,
      organizationId?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetScheduleItemQueryVariables = {
  id: string,
};

export type GetScheduleItemQuery = {
  getScheduleItem?:  {
    __typename: "ScheduleItem",
    id: string,
    eventId: string,
    eventRingId: string,
    eventDayId: string,
    eventRing?:  {
      __typename: "EventRing",
      id: string,
      name: string,
      eventId: string,
      ringId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      ring?:  {
        __typename: "Ring",
        id: string,
        name: string,
        displayOrder?: number | null,
        organizationId?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventDay?:  {
      __typename: "EventDay",
      id: string,
      eventId?: string | null,
      awsdate?: string | null,
      dayofweek?: string | null,
      date?: string | null,
      month?: string | null,
      year?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    displayOrder?: number | null,
    classId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    breakId?: string | null,
    eventBreak?:  {
      __typename: "EventBreak",
      id: string,
      eventId: string,
      name?: string | null,
      time?: string | null,
      isDisplayedOnSchedule?: boolean | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    startTime?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListScheduleItemsQueryVariables = {
  id?: string | null,
  filter?: ModelScheduleItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListScheduleItemsQuery = {
  listScheduleItems?:  {
    __typename: "ModelScheduleItemConnection",
    items:  Array< {
      __typename: "ScheduleItem",
      id: string,
      eventId: string,
      eventRingId: string,
      eventDayId: string,
      eventRing?:  {
        __typename: "EventRing",
        id: string,
        name: string,
        eventId: string,
        ringId: string,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDay?:  {
        __typename: "EventDay",
        id: string,
        eventId?: string | null,
        awsdate?: string | null,
        dayofweek?: string | null,
        date?: string | null,
        month?: string | null,
        year?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      displayOrder?: number | null,
      classId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      breakId?: string | null,
      eventBreak?:  {
        __typename: "EventBreak",
        id: string,
        eventId: string,
        name?: string | null,
        time?: string | null,
        isDisplayedOnSchedule?: boolean | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      startTime?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSeasonQueryVariables = {
  id: string,
};

export type GetSeasonQuery = {
  getSeason?:  {
    __typename: "Season",
    id: string,
    name: string,
    description?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationMembershipTypes?:  {
      __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
      items:  Array< {
        __typename: "OrganizationMembershipTypeSeasons",
        id: string,
        organizationMembershipTypeID: string,
        seasonID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    startDate: string,
    endDate: string,
    disciplines?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    createdAt: string,
    updatedAt: string,
    seasonOrganizationId?: string | null,
  } | null,
};

export type ListSeasonsQueryVariables = {
  id?: string | null,
  filter?: ModelSeasonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSeasonsQuery = {
  listSeasons?:  {
    __typename: "ModelSeasonConnection",
    items:  Array< {
      __typename: "Season",
      id: string,
      name: string,
      description?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationMembershipTypes?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      startDate: string,
      endDate: string,
      disciplines?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      createdAt: string,
      updatedAt: string,
      seasonOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStablingRequestQueryVariables = {
  id: string,
};

export type GetStablingRequestQuery = {
  getStablingRequest?:  {
    __typename: "StablingRequest",
    id: string,
    eventId: string,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    arrivalDateTime?: string | null,
    departureDateTime?: string | null,
    stallTypeId?: string | null,
    stallType?:  {
      __typename: "EventStallType",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallTypeId?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      pricePerStall?: string | null,
      taxPerStall?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    quantityNeeded?: number | null,
    basePrice?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    totalPrice?: number | null,
    status?: string | null,
    statusNote?: string | null,
    requestNote?: string | null,
    stallSetId?: string | null,
    stallSet?:  {
      __typename: "EventStallSet",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallSetId?: string | null,
      name?: string | null,
      location?: string | null,
      available?: string | null,
      totalAvailable?: number | null,
      used?: string | null,
      totalUsed?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    stallLocation?: string | null,
    stallNumbers?: string | null,
    payerId?: string | null,
    payerName?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  {
      __typename: "ModelEventEntryConnection",
      items:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryIds?: Array< string | null > | null,
    submittedEntry?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListStablingRequestsQueryVariables = {
  id?: string | null,
  filter?: ModelStablingRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStablingRequestsQuery = {
  listStablingRequests?:  {
    __typename: "ModelStablingRequestConnection",
    items:  Array< {
      __typename: "StablingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      departureDateTime?: string | null,
      stallTypeId?: string | null,
      stallType?:  {
        __typename: "EventStallType",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallTypeId?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        pricePerStall?: string | null,
        taxPerStall?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?: number | null,
      taxB?: number | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      requestNote?: string | null,
      stallSetId?: string | null,
      stallSet?:  {
        __typename: "EventStallSet",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallSetId?: string | null,
        name?: string | null,
        location?: string | null,
        available?: string | null,
        totalAvailable?: number | null,
        used?: string | null,
        totalUsed?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      stallLocation?: string | null,
      stallNumbers?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBeddingRequestQueryVariables = {
  id: string,
};

export type GetBeddingRequestQuery = {
  getBeddingRequest?:  {
    __typename: "BeddingRequest",
    id: string,
    eventId: string,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    arrivalDateTime?: string | null,
    dateTimeNeeded?: string | null,
    deliveryInfo?: string | null,
    beddingType?: string | null,
    quantityNeeded?: number | null,
    basePrice?: number | null,
    taxA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    totalPrice?: number | null,
    status?: string | null,
    statusNote?: string | null,
    payerId?: string | null,
    payerName?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  {
      __typename: "ModelEventEntryConnection",
      items:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryIds?: Array< string | null > | null,
    submittedEntry?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    beddingRequestTaxAId?: string | null,
    beddingRequestTaxBId?: string | null,
  } | null,
};

export type ListBeddingRequestsQueryVariables = {
  filter?: ModelBeddingRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBeddingRequestsQuery = {
  listBeddingRequests?:  {
    __typename: "ModelBeddingRequestConnection",
    items:  Array< {
      __typename: "BeddingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      dateTimeNeeded?: string | null,
      deliveryInfo?: string | null,
      beddingType?: string | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      beddingRequestTaxAId?: string | null,
      beddingRequestTaxBId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSubmissionQueryVariables = {
  id: string,
};

export type GetSubmissionQuery = {
  getSubmission?:  {
    __typename: "Submission",
    id: string,
    title: string,
    description?: string | null,
    video?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    videoLength?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    riderId?: string | null,
    personId?: string | null,
    clinicians?:  Array< {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null > | null,
    totalPrice: number,
    status?: SubmissionStatus | null,
    submissionFeedback?:  {
      __typename: "ModelSubmissionFeedbackConnection",
      items:  Array< {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdOn?: string | null,
    updatedOn?: string | null,
  } | null,
};

export type ListSubmissionsQueryVariables = {
  id?: string | null,
  filter?: ModelSubmissionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSubmissionsQuery = {
  listSubmissions?:  {
    __typename: "ModelSubmissionConnection",
    items:  Array< {
      __typename: "Submission",
      id: string,
      title: string,
      description?: string | null,
      video?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      videoLength?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      personId?: string | null,
      clinicians?:  Array< {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null > | null,
      totalPrice: number,
      status?: SubmissionStatus | null,
      submissionFeedback?:  {
        __typename: "ModelSubmissionFeedbackConnection",
        nextToken?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSubmissionFeedbackQueryVariables = {
  id: string,
};

export type GetSubmissionFeedbackQuery = {
  getSubmissionFeedback?:  {
    __typename: "SubmissionFeedback",
    id: string,
    type?: string | null,
    submission?:  {
      __typename: "Submission",
      id: string,
      title: string,
      description?: string | null,
      video?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      videoLength?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      personId?: string | null,
      clinicians?:  Array< {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null > | null,
      totalPrice: number,
      status?: SubmissionStatus | null,
      submissionFeedback?:  {
        __typename: "ModelSubmissionFeedbackConnection",
        nextToken?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
    } | null,
    submissionId: string,
    feedback:  {
      __typename: "Feedback",
      id: string,
      clinicianId?: string | null,
      personId?: string | null,
      technical?: string | null,
      style?: string | null,
      turnout?: string | null,
      general?: string | null,
      score?: number | null,
      status?: FeedbackStatus | null,
      submissionFeedback?:  {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      feedbackSubmissionFeedbackId?: string | null,
    },
    feedbackId: string,
    personId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    riderId?: string | null,
    clinician?:  {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null,
    clinicianId?: string | null,
    submissionStatus?: SubmissionStatus | null,
    feedbackStatus?: FeedbackStatus | null,
    clinicianAmount?: number | null,
    declineReason?: string | null,
    permissionToPost?: boolean | null,
    dateSubmitted?: string | null,
    dateCompleted?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    submissionFeedbackClinicianId?: string | null,
  } | null,
};

export type ListSubmissionFeedbacksQueryVariables = {
  id?: string | null,
  filter?: ModelSubmissionFeedbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSubmissionFeedbacksQuery = {
  listSubmissionFeedbacks?:  {
    __typename: "ModelSubmissionFeedbackConnection",
    items:  Array< {
      __typename: "SubmissionFeedback",
      id: string,
      type?: string | null,
      submission?:  {
        __typename: "Submission",
        id: string,
        title: string,
        description?: string | null,
        videoLength?: string | null,
        riderId?: string | null,
        personId?: string | null,
        totalPrice: number,
        status?: SubmissionStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
      } | null,
      submissionId: string,
      feedback:  {
        __typename: "Feedback",
        id: string,
        clinicianId?: string | null,
        personId?: string | null,
        technical?: string | null,
        style?: string | null,
        turnout?: string | null,
        general?: string | null,
        score?: number | null,
        status?: FeedbackStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        feedbackSubmissionFeedbackId?: string | null,
      },
      feedbackId: string,
      personId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      clinician?:  {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null,
      clinicianId?: string | null,
      submissionStatus?: SubmissionStatus | null,
      feedbackStatus?: FeedbackStatus | null,
      clinicianAmount?: number | null,
      declineReason?: string | null,
      permissionToPost?: boolean | null,
      dateSubmitted?: string | null,
      dateCompleted?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      submissionFeedbackClinicianId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTaxRateQueryVariables = {
  id: string,
};

export type GetTaxRateQuery = {
  getTaxRate?:  {
    __typename: "TaxRate",
    id: string,
    name?: string | null,
    createdBy?: string | null,
    organizationId?: string | null,
    stripeTaxRateId?: string | null,
    percentage?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListTaxRatesQueryVariables = {
  id?: string | null,
  filter?: ModelTaxRateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTaxRatesQuery = {
  listTaxRates?:  {
    __typename: "ModelTaxRateConnection",
    items:  Array< {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTeamQueryVariables = {
  id: string,
};

export type GetTeamQuery = {
  getTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    location?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    members?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        teamId: string,
        personId: string,
        status?: TeamStatus | null,
        role?: TeamRole | null,
        permissionLevel?: TeamPermissionLevel | null,
        creatorId?: string | null,
        createdOn: string,
        updatedOn: string,
        teamMemberCreatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    creatorId?: string | null,
    creator?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    teamContactId?: string | null,
    teamLocationId?: string | null,
    teamCreatorId?: string | null,
  } | null,
};

export type ListTeamsQueryVariables = {
  id?: string | null,
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTeamsQuery = {
  listTeams?:  {
    __typename: "ModelTeamConnection",
    items:  Array< {
      __typename: "Team",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      members?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      creator?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      teamContactId?: string | null,
      teamLocationId?: string | null,
      teamCreatorId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTeamMemberQueryVariables = {
  id: string,
};

export type GetTeamMemberQuery = {
  getTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    teamId: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      members?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      creator?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      teamContactId?: string | null,
      teamLocationId?: string | null,
      teamCreatorId?: string | null,
    },
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    status?: TeamStatus | null,
    role?: TeamRole | null,
    permissionLevel?: TeamPermissionLevel | null,
    creatorId?: string | null,
    creator?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    teamMemberCreatorId?: string | null,
  } | null,
};

export type ListTeamMembersQueryVariables = {
  id?: string | null,
  filter?: ModelTeamMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTeamMembersQuery = {
  listTeamMembers?:  {
    __typename: "ModelTeamMemberConnection",
    items:  Array< {
      __typename: "TeamMember",
      id: string,
      teamId: string,
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        creatorId?: string | null,
        createdOn: string,
        updatedOn: string,
        teamContactId?: string | null,
        teamLocationId?: string | null,
        teamCreatorId?: string | null,
      },
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      status?: TeamStatus | null,
      role?: TeamRole | null,
      permissionLevel?: TeamPermissionLevel | null,
      creatorId?: string | null,
      creator?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      teamMemberCreatorId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTextQueryVariables = {
  id: string,
};

export type GetTextQuery = {
  getText?:  {
    __typename: "Text",
    id: string,
    eventId?: string | null,
    blockId?: string | null,
    block?:  {
      __typename: "Block",
      id: string,
      name?: string | null,
      type?: string | null,
      personid?: string | null,
      organizationId?: string | null,
      purchasedOn?: string | null,
      expiresOn?: string | null,
      totalCredits?: number | null,
      usedCredits?: number | null,
      status?: BlockStatus | null,
      price?: number | null,
      amountPaid?: number | null,
      isPromotion?: string | null,
      isFree?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    alertId?: string | null,
    alert?:  {
      __typename: "Alert",
      id: string,
      type?: string | null,
      title?: string | null,
      body?: string | null,
      imageKey?: string | null,
      category?: string | null,
      organizationId?: string | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      messageSegments?: number | null,
      subscriberCount?: number | null,
      totalCost?: number | null,
      blockIdsUsed?: Array< string | null > | null,
      fromUnlimitedEvent?: boolean | null,
      isIncoming?: string | null,
      sentFromPhoneNumber?: string | null,
      recipientPhoneNumbers?: Array< string | null > | null,
      successSIDList?: Array< string | null > | null,
      errorSIDList?: Array< string | null > | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      alertEventId?: string | null,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null,
    status?: TextStatus | null,
    statusNote?: string | null,
    errorCode?: string | null,
    twilioSID?: string | null,
    twilioMessagingServiceSID?: string | null,
    twilioNote?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    timeSent?: string | null,
    timeDelivered?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type ListTextsQueryVariables = {
  id?: string | null,
  filter?: ModelTextFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTextsQuery = {
  listTexts?:  {
    __typename: "ModelTextConnection",
    items:  Array< {
      __typename: "Text",
      id: string,
      eventId?: string | null,
      blockId?: string | null,
      block?:  {
        __typename: "Block",
        id: string,
        name?: string | null,
        type?: string | null,
        personid?: string | null,
        organizationId?: string | null,
        purchasedOn?: string | null,
        expiresOn?: string | null,
        totalCredits?: number | null,
        usedCredits?: number | null,
        status?: BlockStatus | null,
        price?: number | null,
        amountPaid?: number | null,
        isPromotion?: string | null,
        isFree?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      alertId?: string | null,
      alert?:  {
        __typename: "Alert",
        id: string,
        type?: string | null,
        title?: string | null,
        body?: string | null,
        imageKey?: string | null,
        category?: string | null,
        organizationId?: string | null,
        eventId?: string | null,
        messageSegments?: number | null,
        subscriberCount?: number | null,
        totalCost?: number | null,
        blockIdsUsed?: Array< string | null > | null,
        fromUnlimitedEvent?: boolean | null,
        isIncoming?: string | null,
        sentFromPhoneNumber?: string | null,
        recipientPhoneNumbers?: Array< string | null > | null,
        successSIDList?: Array< string | null > | null,
        errorSIDList?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        alertEventId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "EventAuditor",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        auditorId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        status?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorEventId?: string | null,
      } | null,
      status?: TextStatus | null,
      statusNote?: string | null,
      errorCode?: string | null,
      twilioSID?: string | null,
      twilioMessagingServiceSID?: string | null,
      twilioNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      timeSent?: string | null,
      timeDelivered?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTrainerQueryVariables = {
  id: string,
};

export type GetTrainerQuery = {
  getTrainer?:  {
    __typename: "Trainer",
    id: string,
    name: string,
    location?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    trainerContactId?: string | null,
  } | null,
};

export type ListTrainersQueryVariables = {
  id?: string | null,
  filter?: ModelTrainerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTrainersQuery = {
  listTrainers?:  {
    __typename: "ModelTrainerConnection",
    items:  Array< {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationMembershipTypeSeasonsQueryVariables = {
  id: string,
};

export type GetOrganizationMembershipTypeSeasonsQuery = {
  getOrganizationMembershipTypeSeasons?:  {
    __typename: "OrganizationMembershipTypeSeasons",
    id: string,
    organizationMembershipTypeID: string,
    seasonID: string,
    organizationMembershipType:  {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    },
    season:  {
      __typename: "Season",
      id: string,
      name: string,
      description?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationMembershipTypes?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      startDate: string,
      endDate: string,
      disciplines?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      createdAt: string,
      updatedAt: string,
      seasonOrganizationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrganizationMembershipTypeSeasonsQueryVariables = {
  filter?: ModelOrganizationMembershipTypeSeasonsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationMembershipTypeSeasonsQuery = {
  listOrganizationMembershipTypeSeasons?:  {
    __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
    items:  Array< {
      __typename: "OrganizationMembershipTypeSeasons",
      id: string,
      organizationMembershipTypeID: string,
      seasonID: string,
      organizationMembershipType:  {
        __typename: "OrganizationMembershipType",
        id: string,
        name: string,
        description?: string | null,
        order?: number | null,
        organizationId?: string | null,
        membershipIdValues?: string | null,
        nextAvailableMembershipId?: string | null,
        price?: number | null,
        category?: MembershipTypeCategory | null,
        schedule?: MembershipTypeSchedule | null,
        isStartAtTimeOfPurchase?: boolean | null,
        setStartDate?: string | null,
        acceptOnlineApplications?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationMembershipTypeOrganizationId?: string | null,
      },
      season:  {
        __typename: "Season",
        id: string,
        name: string,
        description?: string | null,
        organizationId?: string | null,
        isActive?: boolean | null,
        startDate: string,
        endDate: string,
        disciplines?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        createdAt: string,
        updatedAt: string,
        seasonOrganizationId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AlertsByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAlertFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AlertsByOrganizationIdQuery = {
  alertsByOrganizationId?:  {
    __typename: "ModelAlertConnection",
    items:  Array< {
      __typename: "Alert",
      id: string,
      type?: string | null,
      title?: string | null,
      body?: string | null,
      imageKey?: string | null,
      category?: string | null,
      organizationId?: string | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      messageSegments?: number | null,
      subscriberCount?: number | null,
      totalCost?: number | null,
      blockIdsUsed?: Array< string | null > | null,
      fromUnlimitedEvent?: boolean | null,
      isIncoming?: string | null,
      sentFromPhoneNumber?: string | null,
      recipientPhoneNumbers?: Array< string | null > | null,
      successSIDList?: Array< string | null > | null,
      errorSIDList?: Array< string | null > | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      alertEventId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AlertsByEventIdQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAlertFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AlertsByEventIdQuery = {
  alertsByEventId?:  {
    __typename: "ModelAlertConnection",
    items:  Array< {
      __typename: "Alert",
      id: string,
      type?: string | null,
      title?: string | null,
      body?: string | null,
      imageKey?: string | null,
      category?: string | null,
      organizationId?: string | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      messageSegments?: number | null,
      subscriberCount?: number | null,
      totalCost?: number | null,
      blockIdsUsed?: Array< string | null > | null,
      fromUnlimitedEvent?: boolean | null,
      isIncoming?: string | null,
      sentFromPhoneNumber?: string | null,
      recipientPhoneNumbers?: Array< string | null > | null,
      successSIDList?: Array< string | null > | null,
      errorSIDList?: Array< string | null > | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      alertEventId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AlertsByEventIdByIsIncomingQueryVariables = {
  eventId: string,
  isIncoming?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAlertFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AlertsByEventIdByIsIncomingQuery = {
  alertsByEventIdByIsIncoming?:  {
    __typename: "ModelAlertConnection",
    items:  Array< {
      __typename: "Alert",
      id: string,
      type?: string | null,
      title?: string | null,
      body?: string | null,
      imageKey?: string | null,
      category?: string | null,
      organizationId?: string | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      messageSegments?: number | null,
      subscriberCount?: number | null,
      totalCost?: number | null,
      blockIdsUsed?: Array< string | null > | null,
      fromUnlimitedEvent?: boolean | null,
      isIncoming?: string | null,
      sentFromPhoneNumber?: string | null,
      recipientPhoneNumbers?: Array< string | null > | null,
      successSIDList?: Array< string | null > | null,
      errorSIDList?: Array< string | null > | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      alertEventId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BacklogEventResultsByEventIdQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBacklogEventResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BacklogEventResultsByEventIdQuery = {
  backlogEventResultsByEventId?:  {
    __typename: "ModelBacklogEventResultConnection",
    items:  Array< {
      __typename: "BacklogEventResult",
      id: string,
      organizationId?: string | null,
      organizationMemberId?: string | null,
      organizationResultId?: string | null,
      eventId?: string | null,
      eventName?: string | null,
      eventStartDate?: string | null,
      eventEndDate?: string | null,
      eventDivisionId?: string | null,
      eventDivisionName?: string | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClassId?: string | null,
      eventClassName?: string | null,
      eventClassNumber?: string | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      riderName?: string | null,
      horseName?: string | null,
      place?: number | null,
      pointsEarned?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      backlogEventResultPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBarnMembersByBarnByPersonIdQueryVariables = {
  barnId: string,
  personId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBarnMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetBarnMembersByBarnByPersonIdQuery = {
  getBarnMembersByBarnByPersonId?:  {
    __typename: "ModelBarnMemberConnection",
    items:  Array< {
      __typename: "BarnMember",
      id: string,
      name: string,
      barnId: string,
      barn:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      },
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      permissionLevel?: string | null,
      status?: string | null,
      roles?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBarnMembersByBarnIdQueryVariables = {
  barnId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBarnMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetBarnMembersByBarnIdQuery = {
  getBarnMembersByBarnId?:  {
    __typename: "ModelBarnMemberConnection",
    items:  Array< {
      __typename: "BarnMember",
      id: string,
      name: string,
      barnId: string,
      barn:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      },
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      permissionLevel?: string | null,
      status?: string | null,
      roles?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBarnMembersByPersonIdQueryVariables = {
  personId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBarnMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetBarnMembersByPersonIdQuery = {
  getBarnMembersByPersonId?:  {
    __typename: "ModelBarnMemberConnection",
    items:  Array< {
      __typename: "BarnMember",
      id: string,
      name: string,
      barnId: string,
      barn:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      },
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      permissionLevel?: string | null,
      status?: string | null,
      roles?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CliniciansByStatusQueryVariables = {
  status: ClinicianStatus,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicianFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CliniciansByStatusQuery = {
  cliniciansByStatus?:  {
    __typename: "ModelClinicianConnection",
    items:  Array< {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventsByOrganizationIdByStartDateQueryVariables = {
  organizationId: string,
  startDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventsByOrganizationIdByStartDateQuery = {
  eventsByOrganizationIdByStartDate?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventAuditorsByEventQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventAuditorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventAuditorsByEventQuery = {
  eventAuditorsByEvent?:  {
    __typename: "ModelEventAuditorConnection",
    items:  Array< {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventAuditorByPhoneNumberByEventQueryVariables = {
  phoneNumber: string,
  eventId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventAuditorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventAuditorByPhoneNumberByEventQuery = {
  eventAuditorByPhoneNumberByEvent?:  {
    __typename: "ModelEventAuditorConnection",
    items:  Array< {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrganizationAuditorByPersonIdQueryVariables = {
  personId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationAuditorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrganizationAuditorByPersonIdQuery = {
  organizationAuditorByPersonId?:  {
    __typename: "ModelOrganizationAuditorConnection",
    items:  Array< {
      __typename: "OrganizationAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      howSignedUp?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationAuditorPersonId?: string | null,
      organizationAuditorOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrganizationAuditorByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationAuditorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrganizationAuditorByOrganizationIdQuery = {
  organizationAuditorByOrganizationId?:  {
    __typename: "ModelOrganizationAuditorConnection",
    items:  Array< {
      __typename: "OrganizationAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      howSignedUp?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationAuditorPersonId?: string | null,
      organizationAuditorOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrganizationAuditorByOrganizationIdByStatusQueryVariables = {
  organizationId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationAuditorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrganizationAuditorByOrganizationIdByStatusQuery = {
  organizationAuditorByOrganizationIdByStatus?:  {
    __typename: "ModelOrganizationAuditorConnection",
    items:  Array< {
      __typename: "OrganizationAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      howSignedUp?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationAuditorPersonId?: string | null,
      organizationAuditorOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrganizationAuditorByPhoneNumberQueryVariables = {
  phoneNumber: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationAuditorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrganizationAuditorByPhoneNumberQuery = {
  organizationAuditorByPhoneNumber?:  {
    __typename: "ModelOrganizationAuditorConnection",
    items:  Array< {
      __typename: "OrganizationAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      howSignedUp?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationAuditorPersonId?: string | null,
      organizationAuditorOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventAuditorFilterByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventAuditorFilterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventAuditorFilterByOrganizationIdQuery = {
  eventAuditorFilterByOrganizationId?:  {
    __typename: "ModelEventAuditorFilterConnection",
    items:  Array< {
      __typename: "EventAuditorFilter",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      eventauditorid?: string | null,
      eventAuditorId?: string | null,
      eventAuditor?:  {
        __typename: "EventAuditor",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        auditorId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        status?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorEventId?: string | null,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationAuditorId?: string | null,
      organizationAuditor?:  {
        __typename: "OrganizationAuditor",
        id: string,
        personId?: string | null,
        howSignedUp?: string | null,
        organizationId?: string | null,
        auditorId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        status?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationAuditorPersonId?: string | null,
        organizationAuditorOrganizationId?: string | null,
      } | null,
      filterId?: string | null,
      filter?:  {
        __typename: "EventFilter",
        id: string,
        eventId?: string | null,
        organizationID?: string | null,
        name?: string | null,
        options?: Array< string | null > | null,
        isRequired?: boolean | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      options?: Array< string | null > | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorFilterOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventAuditorFilterByOrganizationAuditorIdQueryVariables = {
  organizationAuditorId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventAuditorFilterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventAuditorFilterByOrganizationAuditorIdQuery = {
  eventAuditorFilterByOrganizationAuditorId?:  {
    __typename: "ModelEventAuditorFilterConnection",
    items:  Array< {
      __typename: "EventAuditorFilter",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      eventauditorid?: string | null,
      eventAuditorId?: string | null,
      eventAuditor?:  {
        __typename: "EventAuditor",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        auditorId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        status?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorEventId?: string | null,
      } | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationAuditorId?: string | null,
      organizationAuditor?:  {
        __typename: "OrganizationAuditor",
        id: string,
        personId?: string | null,
        howSignedUp?: string | null,
        organizationId?: string | null,
        auditorId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        status?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationAuditorPersonId?: string | null,
        organizationAuditorOrganizationId?: string | null,
      } | null,
      filterId?: string | null,
      filter?:  {
        __typename: "EventFilter",
        id: string,
        eventId?: string | null,
        organizationID?: string | null,
        name?: string | null,
        options?: Array< string | null > | null,
        isRequired?: boolean | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      options?: Array< string | null > | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorFilterOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventBeddingTypeByEventIdQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventBeddingTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventBeddingTypeByEventIdQuery = {
  eventBeddingTypeByEventId?:  {
    __typename: "ModelEventBeddingTypeConnection",
    items:  Array< {
      __typename: "EventBeddingType",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationBeddingTypeId?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      pricePerBag?: string | null,
      taxPerBag?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventClassesByEventIdQueryVariables = {
  eventId: string,
  number?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventClassesByEventIdQuery = {
  eventClassesByEventId?:  {
    __typename: "ModelEventClassConnection",
    items:  Array< {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventClassEntriesByEventClassIdQueryVariables = {
  eventClassId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventClassEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventClassEntriesByEventClassIdQuery = {
  eventClassEntriesByEventClassId?:  {
    __typename: "ModelEventClassEntryConnection",
    items:  Array< {
      __typename: "EventClassEntry",
      id: string,
      eventId: string,
      eventClassId: string,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventEntryId: string,
      eventEntry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      status?: string | null,
      selectedEntryOption?: EntryOption | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventClassEntriesByEventClassIdByStatusQueryVariables = {
  eventClassId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventClassEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventClassEntriesByEventClassIdByStatusQuery = {
  eventClassEntriesByEventClassIdByStatus?:  {
    __typename: "ModelEventClassEntryConnection",
    items:  Array< {
      __typename: "EventClassEntry",
      id: string,
      eventId: string,
      eventClassId: string,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventEntryId: string,
      eventEntry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      status?: string | null,
      selectedEntryOption?: EntryOption | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventClassEntriesByEventEntryIdQueryVariables = {
  eventEntryId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventClassEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventClassEntriesByEventEntryIdQuery = {
  eventClassEntriesByEventEntryId?:  {
    __typename: "ModelEventClassEntryConnection",
    items:  Array< {
      __typename: "EventClassEntry",
      id: string,
      eventId: string,
      eventClassId: string,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventEntryId: string,
      eventEntry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      status?: string | null,
      selectedEntryOption?: EntryOption | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventClassEntriesByEventEntryIdByStatusQueryVariables = {
  eventEntryId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventClassEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventClassEntriesByEventEntryIdByStatusQuery = {
  eventClassEntriesByEventEntryIdByStatus?:  {
    __typename: "ModelEventClassEntryConnection",
    items:  Array< {
      __typename: "EventClassEntry",
      id: string,
      eventId: string,
      eventClassId: string,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventEntryId: string,
      eventEntry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      status?: string | null,
      selectedEntryOption?: EntryOption | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventDivisionsByEventIdQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventDivisionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventDivisionsByEventIdQuery = {
  eventDivisionsByEventId?:  {
    __typename: "ModelEventDivisionConnection",
    items:  Array< {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventDivisionsByOrganizationDivisionIdQueryVariables = {
  organizationDivisionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventDivisionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventDivisionsByOrganizationDivisionIdQuery = {
  eventDivisionsByOrganizationDivisionId?:  {
    __typename: "ModelEventDivisionConnection",
    items:  Array< {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventEntriesByEventIdQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventEntriesByEventIdQuery = {
  eventEntriesByEventId?:  {
    __typename: "ModelEventEntryConnection",
    items:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventEntriesByEventIdByStatusQueryVariables = {
  eventId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventEntriesByEventIdByStatusQuery = {
  eventEntriesByEventIdByStatus?:  {
    __typename: "ModelEventEntryConnection",
    items:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventEntriesByEventIdByPersonIdQueryVariables = {
  eventId: string,
  personId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventEntriesByEventIdByPersonIdQuery = {
  eventEntriesByEventIdByPersonId?:  {
    __typename: "ModelEventEntryConnection",
    items:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventEntriesByEventIdByTrainerNameQueryVariables = {
  eventId: string,
  trainerName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventEntriesByEventIdByTrainerNameQuery = {
  eventEntriesByEventIdByTrainerName?:  {
    __typename: "ModelEventEntryConnection",
    items:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventEntriesByEventIdByNumberQueryVariables = {
  eventId: string,
  number?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventEntriesByEventIdByNumberQuery = {
  eventEntriesByEventIdByNumber?:  {
    __typename: "ModelEventEntryConnection",
    items:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventEntriesByPersonIdQueryVariables = {
  personId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventEntriesByPersonIdQuery = {
  eventEntriesByPersonId?:  {
    __typename: "ModelEventEntryConnection",
    items:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventEntriesByPersonIdByStatusQueryVariables = {
  personId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventEntriesByPersonIdByStatusQuery = {
  eventEntriesByPersonIdByStatus?:  {
    __typename: "ModelEventEntryConnection",
    items:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventEntryFeesByEventEntryIdQueryVariables = {
  entryId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventEntryFeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventEntryFeesByEventEntryIdQuery = {
  eventEntryFeesByEventEntryId?:  {
    __typename: "ModelEventEntryFeeConnection",
    items:  Array< {
      __typename: "EventEntryFee",
      id: string,
      name: string,
      description?: string | null,
      status?: string | null,
      amount: number,
      quantity?: number | null,
      taxA?: number | null,
      taxB?: number | null,
      eventId: string,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      eventFeeId?: string | null,
      eventFee?:  {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryId?: string | null,
      entry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventFeesByEventClassIdQueryVariables = {
  eventClassId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventFeesByEventClassIdQuery = {
  eventFeesByEventClassId?:  {
    __typename: "ModelEventFeeConnection",
    items:  Array< {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventInvoicesByEventEntryIdQueryVariables = {
  entryId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventInvoicesByEventEntryIdQuery = {
  eventInvoicesByEventEntryId?:  {
    __typename: "ModelEventInvoiceConnection",
    items:  Array< {
      __typename: "EventInvoice",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      number: number,
      stripeInvoiceNumber?: string | null,
      entryNumber?: number | null,
      entryId?: string | null,
      entry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      payerType?: string | null,
      payerStripeId?: string | null,
      payerContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipientStripeId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      divisions?:  {
        __typename: "ModelDivisionConnection",
        nextToken?: string | null,
      } | null,
      classes?:  {
        __typename: "ModelClassConnection",
        nextToken?: string | null,
      } | null,
      fees?:  {
        __typename: "ModelFeeConnection",
        nextToken?: string | null,
      } | null,
      entryFeeTotalAmount?: number | null,
      otherFeeTotalAmount?: number | null,
      stablingAmount?: number | null,
      beddingAmount?: number | null,
      feedAmount?: number | null,
      results?:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null > | null,
      prizeMoneyAmount?: number | null,
      amountToDeductFromInvoice?: number | null,
      amountToPayRecipientViaCheck?: number | null,
      totalDue?: number | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoicePayerContactId?: string | null,
      eventInvoicePrizeMoneyRecipientId?: string | null,
      eventInvoicePrizeMoneyRecipientContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventResultsByEventIdQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventResultsByEventIdQuery = {
  eventResultsByEventId?:  {
    __typename: "ModelEventResultConnection",
    items:  Array< {
      __typename: "EventResult",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventClassEntryId?: string | null,
      eventClassEntry?:  {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      resultSet?: string | null,
      place?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      pointTables?:  {
        __typename: "ModelPointTableConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      eventResultEntryId: string,
      eventResultEventClassEntryId?: string | null,
      eventResultPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventResultsByEventIdByEventClassQueryVariables = {
  eventId: string,
  eventClassId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventResultsByEventIdByEventClassQuery = {
  eventResultsByEventIdByEventClass?:  {
    __typename: "ModelEventResultConnection",
    items:  Array< {
      __typename: "EventResult",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventClassEntryId?: string | null,
      eventClassEntry?:  {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      resultSet?: string | null,
      place?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      pointTables?:  {
        __typename: "ModelPointTableConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      eventResultEntryId: string,
      eventResultEventClassEntryId?: string | null,
      eventResultPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventClassResultsByEntryIdQueryVariables = {
  entryId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventClassResultsByEntryIdQuery = {
  eventClassResultsByEntryId?:  {
    __typename: "ModelEventResultConnection",
    items:  Array< {
      __typename: "EventResult",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventClassEntryId?: string | null,
      eventClassEntry?:  {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      resultSet?: string | null,
      place?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      pointTables?:  {
        __typename: "ModelPointTableConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      eventResultEntryId: string,
      eventResultEventClassEntryId?: string | null,
      eventResultPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventClassResultsByEventClassEntryIdQueryVariables = {
  eventClassEntryId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventClassResultsByEventClassEntryIdQuery = {
  eventClassResultsByEventClassEntryId?:  {
    __typename: "ModelEventResultConnection",
    items:  Array< {
      __typename: "EventResult",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventClassEntryId?: string | null,
      eventClassEntry?:  {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      resultSet?: string | null,
      place?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      pointTables?:  {
        __typename: "ModelPointTableConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      eventResultEntryId: string,
      eventResultEventClassEntryId?: string | null,
      eventResultPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventDivisionResultsByEventDivisionIdQueryVariables = {
  eventDivisionId: string,
  place?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventDivisionResultsByEventDivisionIdQuery = {
  eventDivisionResultsByEventDivisionId?:  {
    __typename: "ModelEventResultConnection",
    items:  Array< {
      __typename: "EventResult",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventClassEntryId?: string | null,
      eventClassEntry?:  {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      resultSet?: string | null,
      place?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      pointTables?:  {
        __typename: "ModelPointTableConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      eventResultEntryId: string,
      eventResultEventClassEntryId?: string | null,
      eventResultPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventClassResultsByEventClassIdQueryVariables = {
  eventClassId: string,
  place?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventClassResultsByEventClassIdQuery = {
  eventClassResultsByEventClassId?:  {
    __typename: "ModelEventResultConnection",
    items:  Array< {
      __typename: "EventResult",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventClassEntryId?: string | null,
      eventClassEntry?:  {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      resultSet?: string | null,
      place?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      pointTables?:  {
        __typename: "ModelPointTableConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      eventResultEntryId: string,
      eventResultEventClassEntryId?: string | null,
      eventResultPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventRulesbyEventIdQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventRulesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventRulesbyEventIdQuery = {
  eventRulesbyEventId?:  {
    __typename: "ModelEventRulesConnection",
    items:  Array< {
      __typename: "EventRules",
      id: string,
      eventId: string,
      title?: string | null,
      text?: string | null,
      requiresAgreementPerEntry?: boolean | null,
      agreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventStallTypeByEventIdQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventStallTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventStallTypeByEventIdQuery = {
  eventStallTypeByEventId?:  {
    __typename: "ModelEventStallTypeConnection",
    items:  Array< {
      __typename: "EventStallType",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallTypeId?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      pricePerStall?: string | null,
      taxPerStall?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GameInputsByEventIdQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGameInputFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GameInputsByEventIdQuery = {
  gameInputsByEventId?:  {
    __typename: "ModelGameInputConnection",
    items:  Array< {
      __typename: "GameInput",
      id: string,
      eventId?: string | null,
      eventClassId?: string | null,
      backNumber?: string | null,
      round?: string | null,
      score?: number | null,
      isMasterRecord: boolean,
      createdBy: string,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type HorsesByPersonIdByNameQueryVariables = {
  personId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHorseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HorsesByPersonIdByNameQuery = {
  horsesByPersonIdByName?:  {
    __typename: "ModelHorseConnection",
    items:  Array< {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type HorsesByBarnIdByNameQueryVariables = {
  barnId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHorseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HorsesByBarnIdByNameQuery = {
  horsesByBarnIdByName?:  {
    __typename: "ModelHorseConnection",
    items:  Array< {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByBarnByInvitorQueryVariables = {
  barnId: string,
  createdBy?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByBarnByInvitorQuery = {
  ByBarnByInvitor?:  {
    __typename: "ModelInvitationConnection",
    items:  Array< {
      __typename: "Invitation",
      id: string,
      createdBy: string,
      creatorEmail?: string | null,
      invitee?: string | null,
      inviteeEmail?: string | null,
      status?: InvitationStatus | null,
      organizationId?: string | null,
      barnId?: string | null,
      permissionLevel?: string | null,
      roles?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ByBarnByInviteeQueryVariables = {
  barnId: string,
  invitee?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByBarnByInviteeQuery = {
  ByBarnByInvitee?:  {
    __typename: "ModelInvitationConnection",
    items:  Array< {
      __typename: "Invitation",
      id: string,
      createdBy: string,
      creatorEmail?: string | null,
      invitee?: string | null,
      inviteeEmail?: string | null,
      status?: InvitationStatus | null,
      organizationId?: string | null,
      barnId?: string | null,
      permissionLevel?: string | null,
      roles?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MembershipsByRiderIdQueryVariables = {
  riderId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMembershipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MembershipsByRiderIdQuery = {
  membershipsByRiderId?:  {
    __typename: "ModelMembershipConnection",
    items:  Array< {
      __typename: "Membership",
      id: string,
      name: string,
      description?: string | null,
      membershipId: string,
      backNumber?: number | null,
      membershipStatus?: string | null,
      amountPaid?: number | null,
      organizationMembershipTypeId?: string | null,
      organizationMembershipType?:  {
        __typename: "OrganizationMembershipType",
        id: string,
        name: string,
        description?: string | null,
        order?: number | null,
        organizationId?: string | null,
        membershipIdValues?: string | null,
        nextAvailableMembershipId?: string | null,
        price?: number | null,
        category?: MembershipTypeCategory | null,
        schedule?: MembershipTypeSchedule | null,
        isStartAtTimeOfPurchase?: boolean | null,
        setStartDate?: string | null,
        acceptOnlineApplications?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationMembershipTypeOrganizationId?: string | null,
      } | null,
      type?: string | null,
      personName?: string | null,
      personId: string,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      teamId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationName?: string | null,
      isJunior?: boolean | null,
      isProfessional?: boolean | null,
      dateMembershipEnds?: string | null,
      volunteerHours?: number | null,
      meetingsAttended?: number | null,
      showsAttended?: number | null,
      personEmail?: string | null,
      gradeLevel?: string | null,
      aelDivision?: string | null,
      aelTeamRole?: TeamRole | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      organizationAgreements?:  Array< {
        __typename: "OrganizationAgreements",
        organizationRuleId?: string | null,
        organizationRuleTitle: string,
        firstName: string,
        lastName: string,
        personId?: string | null,
        createdOn: string,
        updatedOn?: string | null,
      } | null > | null,
      contactPersonId?: string | null,
      groupContactId?: string | null,
      groupContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      groupLeaderContactId?: string | null,
      groupLeaderContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      personMembershipsId?: string | null,
      membershipOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MembershipsByHorseIdQueryVariables = {
  horseId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMembershipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MembershipsByHorseIdQuery = {
  membershipsByHorseId?:  {
    __typename: "ModelMembershipConnection",
    items:  Array< {
      __typename: "Membership",
      id: string,
      name: string,
      description?: string | null,
      membershipId: string,
      backNumber?: number | null,
      membershipStatus?: string | null,
      amountPaid?: number | null,
      organizationMembershipTypeId?: string | null,
      organizationMembershipType?:  {
        __typename: "OrganizationMembershipType",
        id: string,
        name: string,
        description?: string | null,
        order?: number | null,
        organizationId?: string | null,
        membershipIdValues?: string | null,
        nextAvailableMembershipId?: string | null,
        price?: number | null,
        category?: MembershipTypeCategory | null,
        schedule?: MembershipTypeSchedule | null,
        isStartAtTimeOfPurchase?: boolean | null,
        setStartDate?: string | null,
        acceptOnlineApplications?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationMembershipTypeOrganizationId?: string | null,
      } | null,
      type?: string | null,
      personName?: string | null,
      personId: string,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      teamId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationName?: string | null,
      isJunior?: boolean | null,
      isProfessional?: boolean | null,
      dateMembershipEnds?: string | null,
      volunteerHours?: number | null,
      meetingsAttended?: number | null,
      showsAttended?: number | null,
      personEmail?: string | null,
      gradeLevel?: string | null,
      aelDivision?: string | null,
      aelTeamRole?: TeamRole | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      organizationAgreements?:  Array< {
        __typename: "OrganizationAgreements",
        organizationRuleId?: string | null,
        organizationRuleTitle: string,
        firstName: string,
        lastName: string,
        personId?: string | null,
        createdOn: string,
        updatedOn?: string | null,
      } | null > | null,
      contactPersonId?: string | null,
      groupContactId?: string | null,
      groupContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      groupLeaderContactId?: string | null,
      groupLeaderContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      personMembershipsId?: string | null,
      membershipOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MembershipsByOwnerIdQueryVariables = {
  ownerId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMembershipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MembershipsByOwnerIdQuery = {
  membershipsByOwnerId?:  {
    __typename: "ModelMembershipConnection",
    items:  Array< {
      __typename: "Membership",
      id: string,
      name: string,
      description?: string | null,
      membershipId: string,
      backNumber?: number | null,
      membershipStatus?: string | null,
      amountPaid?: number | null,
      organizationMembershipTypeId?: string | null,
      organizationMembershipType?:  {
        __typename: "OrganizationMembershipType",
        id: string,
        name: string,
        description?: string | null,
        order?: number | null,
        organizationId?: string | null,
        membershipIdValues?: string | null,
        nextAvailableMembershipId?: string | null,
        price?: number | null,
        category?: MembershipTypeCategory | null,
        schedule?: MembershipTypeSchedule | null,
        isStartAtTimeOfPurchase?: boolean | null,
        setStartDate?: string | null,
        acceptOnlineApplications?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationMembershipTypeOrganizationId?: string | null,
      } | null,
      type?: string | null,
      personName?: string | null,
      personId: string,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      teamId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationName?: string | null,
      isJunior?: boolean | null,
      isProfessional?: boolean | null,
      dateMembershipEnds?: string | null,
      volunteerHours?: number | null,
      meetingsAttended?: number | null,
      showsAttended?: number | null,
      personEmail?: string | null,
      gradeLevel?: string | null,
      aelDivision?: string | null,
      aelTeamRole?: TeamRole | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      organizationAgreements?:  Array< {
        __typename: "OrganizationAgreements",
        organizationRuleId?: string | null,
        organizationRuleTitle: string,
        firstName: string,
        lastName: string,
        personId?: string | null,
        createdOn: string,
        updatedOn?: string | null,
      } | null > | null,
      contactPersonId?: string | null,
      groupContactId?: string | null,
      groupContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      groupLeaderContactId?: string | null,
      groupLeaderContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      personMembershipsId?: string | null,
      membershipOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MembershipsByTrainerIdQueryVariables = {
  trainerId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMembershipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MembershipsByTrainerIdQuery = {
  membershipsByTrainerId?:  {
    __typename: "ModelMembershipConnection",
    items:  Array< {
      __typename: "Membership",
      id: string,
      name: string,
      description?: string | null,
      membershipId: string,
      backNumber?: number | null,
      membershipStatus?: string | null,
      amountPaid?: number | null,
      organizationMembershipTypeId?: string | null,
      organizationMembershipType?:  {
        __typename: "OrganizationMembershipType",
        id: string,
        name: string,
        description?: string | null,
        order?: number | null,
        organizationId?: string | null,
        membershipIdValues?: string | null,
        nextAvailableMembershipId?: string | null,
        price?: number | null,
        category?: MembershipTypeCategory | null,
        schedule?: MembershipTypeSchedule | null,
        isStartAtTimeOfPurchase?: boolean | null,
        setStartDate?: string | null,
        acceptOnlineApplications?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationMembershipTypeOrganizationId?: string | null,
      } | null,
      type?: string | null,
      personName?: string | null,
      personId: string,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      teamId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationName?: string | null,
      isJunior?: boolean | null,
      isProfessional?: boolean | null,
      dateMembershipEnds?: string | null,
      volunteerHours?: number | null,
      meetingsAttended?: number | null,
      showsAttended?: number | null,
      personEmail?: string | null,
      gradeLevel?: string | null,
      aelDivision?: string | null,
      aelTeamRole?: TeamRole | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      organizationAgreements?:  Array< {
        __typename: "OrganizationAgreements",
        organizationRuleId?: string | null,
        organizationRuleTitle: string,
        firstName: string,
        lastName: string,
        personId?: string | null,
        createdOn: string,
        updatedOn?: string | null,
      } | null > | null,
      contactPersonId?: string | null,
      groupContactId?: string | null,
      groupContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      groupLeaderContactId?: string | null,
      groupLeaderContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      personMembershipsId?: string | null,
      membershipOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MembershipsByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMembershipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MembershipsByOrganizationIdQuery = {
  membershipsByOrganizationId?:  {
    __typename: "ModelMembershipConnection",
    items:  Array< {
      __typename: "Membership",
      id: string,
      name: string,
      description?: string | null,
      membershipId: string,
      backNumber?: number | null,
      membershipStatus?: string | null,
      amountPaid?: number | null,
      organizationMembershipTypeId?: string | null,
      organizationMembershipType?:  {
        __typename: "OrganizationMembershipType",
        id: string,
        name: string,
        description?: string | null,
        order?: number | null,
        organizationId?: string | null,
        membershipIdValues?: string | null,
        nextAvailableMembershipId?: string | null,
        price?: number | null,
        category?: MembershipTypeCategory | null,
        schedule?: MembershipTypeSchedule | null,
        isStartAtTimeOfPurchase?: boolean | null,
        setStartDate?: string | null,
        acceptOnlineApplications?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationMembershipTypeOrganizationId?: string | null,
      } | null,
      type?: string | null,
      personName?: string | null,
      personId: string,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      teamId?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationName?: string | null,
      isJunior?: boolean | null,
      isProfessional?: boolean | null,
      dateMembershipEnds?: string | null,
      volunteerHours?: number | null,
      meetingsAttended?: number | null,
      showsAttended?: number | null,
      personEmail?: string | null,
      gradeLevel?: string | null,
      aelDivision?: string | null,
      aelTeamRole?: TeamRole | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      organizationAgreements?:  Array< {
        __typename: "OrganizationAgreements",
        organizationRuleId?: string | null,
        organizationRuleTitle: string,
        firstName: string,
        lastName: string,
        personId?: string | null,
        createdOn: string,
        updatedOn?: string | null,
      } | null > | null,
      contactPersonId?: string | null,
      groupContactId?: string | null,
      groupContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      groupLeaderContactId?: string | null,
      groupLeaderContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      personMembershipsId?: string | null,
      membershipOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMembershipTypesByOrganizationIDByCreatedOnQueryVariables = {
  organizationId: string,
  createdOn?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationMembershipTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetMembershipTypesByOrganizationIDByCreatedOnQuery = {
  getMembershipTypesByOrganizationIDByCreatedOn?:  {
    __typename: "ModelOrganizationMembershipTypeConnection",
    items:  Array< {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMembershipTypesByOrganizationIDByAcceptOnlineApplicationsQueryVariables = {
  organizationId: string,
  acceptOnlineApplications?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationMembershipTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetMembershipTypesByOrganizationIDByAcceptOnlineApplicationsQuery = {
  getMembershipTypesByOrganizationIDByAcceptOnlineApplications?:  {
    __typename: "ModelOrganizationMembershipTypeConnection",
    items:  Array< {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationsByURLBackHalfQueryVariables = {
  urlBackHalf: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetOrganizationsByURLBackHalfQuery = {
  getOrganizationsByURLBackHalf?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrganizationClassesByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrganizationClassesByOrganizationIdQuery = {
  organizationClassesByOrganizationId?:  {
    __typename: "ModelOrganizationClassConnection",
    items:  Array< {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrganizationDivisionsByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationDivisionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrganizationDivisionsByOrganizationIdQuery = {
  organizationDivisionsByOrganizationId?:  {
    __typename: "ModelOrganizationDivisionConnection",
    items:  Array< {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPostsByOrganizationIDByCreatedOnQueryVariables = {
  organizationId: string,
  createdOn?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetPostsByOrganizationIDByCreatedOnQuery = {
  getPostsByOrganizationIDByCreatedOn?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      title: string,
      status: PostStatus,
      rating?: number | null,
      content?: string | null,
      organizationId?: string | null,
      createdBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RidersByPersonIdByNameQueryVariables = {
  personId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRiderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RidersByPersonIdByNameQuery = {
  ridersByPersonIdByName?:  {
    __typename: "ModelRiderConnection",
    items:  Array< {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RidersByBarnIdByNameQueryVariables = {
  barnId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRiderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RidersByBarnIdByNameQuery = {
  ridersByBarnIdByName?:  {
    __typename: "ModelRiderConnection",
    items:  Array< {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ScheduleItemsByEventIdQueryVariables = {
  eventId: string,
  displayOrder?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelScheduleItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ScheduleItemsByEventIdQuery = {
  scheduleItemsByEventId?:  {
    __typename: "ModelScheduleItemConnection",
    items:  Array< {
      __typename: "ScheduleItem",
      id: string,
      eventId: string,
      eventRingId: string,
      eventDayId: string,
      eventRing?:  {
        __typename: "EventRing",
        id: string,
        name: string,
        eventId: string,
        ringId: string,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDay?:  {
        __typename: "EventDay",
        id: string,
        eventId?: string | null,
        awsdate?: string | null,
        dayofweek?: string | null,
        date?: string | null,
        month?: string | null,
        year?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      displayOrder?: number | null,
      classId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      breakId?: string | null,
      eventBreak?:  {
        __typename: "EventBreak",
        id: string,
        eventId: string,
        name?: string | null,
        time?: string | null,
        isDisplayedOnSchedule?: boolean | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      startTime?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSeasonsByOrganizationIdQueryVariables = {
  organizationId: string,
  createdOn?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSeasonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSeasonsByOrganizationIdQuery = {
  getSeasonsByOrganizationId?:  {
    __typename: "ModelSeasonConnection",
    items:  Array< {
      __typename: "Season",
      id: string,
      name: string,
      description?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationMembershipTypes?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      startDate: string,
      endDate: string,
      disciplines?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      createdAt: string,
      updatedAt: string,
      seasonOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StablingRequestsByEventIdQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStablingRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StablingRequestsByEventIdQuery = {
  stablingRequestsByEventId?:  {
    __typename: "ModelStablingRequestConnection",
    items:  Array< {
      __typename: "StablingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      departureDateTime?: string | null,
      stallTypeId?: string | null,
      stallType?:  {
        __typename: "EventStallType",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallTypeId?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        pricePerStall?: string | null,
        taxPerStall?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?: number | null,
      taxB?: number | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      requestNote?: string | null,
      stallSetId?: string | null,
      stallSet?:  {
        __typename: "EventStallSet",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallSetId?: string | null,
        name?: string | null,
        location?: string | null,
        available?: string | null,
        totalAvailable?: number | null,
        used?: string | null,
        totalUsed?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      stallLocation?: string | null,
      stallNumbers?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StablingRequestsByEntryIdQueryVariables = {
  submittedEntry: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStablingRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StablingRequestsByEntryIdQuery = {
  stablingRequestsByEntryId?:  {
    __typename: "ModelStablingRequestConnection",
    items:  Array< {
      __typename: "StablingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      departureDateTime?: string | null,
      stallTypeId?: string | null,
      stallType?:  {
        __typename: "EventStallType",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallTypeId?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        pricePerStall?: string | null,
        taxPerStall?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?: number | null,
      taxB?: number | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      requestNote?: string | null,
      stallSetId?: string | null,
      stallSet?:  {
        __typename: "EventStallSet",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallSetId?: string | null,
        name?: string | null,
        location?: string | null,
        available?: string | null,
        totalAvailable?: number | null,
        used?: string | null,
        totalUsed?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      stallLocation?: string | null,
      stallNumbers?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BeddingRequestsByEventIdQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBeddingRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BeddingRequestsByEventIdQuery = {
  beddingRequestsByEventId?:  {
    __typename: "ModelBeddingRequestConnection",
    items:  Array< {
      __typename: "BeddingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      dateTimeNeeded?: string | null,
      deliveryInfo?: string | null,
      beddingType?: string | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      beddingRequestTaxAId?: string | null,
      beddingRequestTaxBId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BeddingRequestsByEntryIdQueryVariables = {
  submittedEntry: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBeddingRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BeddingRequestsByEntryIdQuery = {
  beddingRequestsByEntryId?:  {
    __typename: "ModelBeddingRequestConnection",
    items:  Array< {
      __typename: "BeddingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      dateTimeNeeded?: string | null,
      deliveryInfo?: string | null,
      beddingType?: string | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      beddingRequestTaxAId?: string | null,
      beddingRequestTaxBId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SubmissionFeedbackByClinicianByStatusQueryVariables = {
  clinicianId: string,
  feedbackStatus?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSubmissionFeedbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SubmissionFeedbackByClinicianByStatusQuery = {
  submissionFeedbackByClinicianByStatus?:  {
    __typename: "ModelSubmissionFeedbackConnection",
    items:  Array< {
      __typename: "SubmissionFeedback",
      id: string,
      type?: string | null,
      submission?:  {
        __typename: "Submission",
        id: string,
        title: string,
        description?: string | null,
        videoLength?: string | null,
        riderId?: string | null,
        personId?: string | null,
        totalPrice: number,
        status?: SubmissionStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
      } | null,
      submissionId: string,
      feedback:  {
        __typename: "Feedback",
        id: string,
        clinicianId?: string | null,
        personId?: string | null,
        technical?: string | null,
        style?: string | null,
        turnout?: string | null,
        general?: string | null,
        score?: number | null,
        status?: FeedbackStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        feedbackSubmissionFeedbackId?: string | null,
      },
      feedbackId: string,
      personId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      clinician?:  {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null,
      clinicianId?: string | null,
      submissionStatus?: SubmissionStatus | null,
      feedbackStatus?: FeedbackStatus | null,
      clinicianAmount?: number | null,
      declineReason?: string | null,
      permissionToPost?: boolean | null,
      dateSubmitted?: string | null,
      dateCompleted?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      submissionFeedbackClinicianId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PaymentsByClinicianByDateQueryVariables = {
  feedbackStatus: FeedbackStatus,
  clinicianIdDateCompleted?: ModelSubmissionFeedbackPaymentsByClinicianByDateCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSubmissionFeedbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaymentsByClinicianByDateQuery = {
  paymentsByClinicianByDate?:  {
    __typename: "ModelSubmissionFeedbackConnection",
    items:  Array< {
      __typename: "SubmissionFeedback",
      id: string,
      type?: string | null,
      submission?:  {
        __typename: "Submission",
        id: string,
        title: string,
        description?: string | null,
        videoLength?: string | null,
        riderId?: string | null,
        personId?: string | null,
        totalPrice: number,
        status?: SubmissionStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
      } | null,
      submissionId: string,
      feedback:  {
        __typename: "Feedback",
        id: string,
        clinicianId?: string | null,
        personId?: string | null,
        technical?: string | null,
        style?: string | null,
        turnout?: string | null,
        general?: string | null,
        score?: number | null,
        status?: FeedbackStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        feedbackSubmissionFeedbackId?: string | null,
      },
      feedbackId: string,
      personId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      clinician?:  {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null,
      clinicianId?: string | null,
      submissionStatus?: SubmissionStatus | null,
      feedbackStatus?: FeedbackStatus | null,
      clinicianAmount?: number | null,
      declineReason?: string | null,
      permissionToPost?: boolean | null,
      dateSubmitted?: string | null,
      dateCompleted?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      submissionFeedbackClinicianId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTeamMembersByTeamIdQueryVariables = {
  teamId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTeamMembersByTeamIdQuery = {
  getTeamMembersByTeamId?:  {
    __typename: "ModelTeamMemberConnection",
    items:  Array< {
      __typename: "TeamMember",
      id: string,
      teamId: string,
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        creatorId?: string | null,
        createdOn: string,
        updatedOn: string,
        teamContactId?: string | null,
        teamLocationId?: string | null,
        teamCreatorId?: string | null,
      },
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      status?: TeamStatus | null,
      role?: TeamRole | null,
      permissionLevel?: TeamPermissionLevel | null,
      creatorId?: string | null,
      creator?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      teamMemberCreatorId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTeamMembersByPersonIdQueryVariables = {
  personId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTeamMembersByPersonIdQuery = {
  getTeamMembersByPersonId?:  {
    __typename: "ModelTeamMemberConnection",
    items:  Array< {
      __typename: "TeamMember",
      id: string,
      teamId: string,
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        creatorId?: string | null,
        createdOn: string,
        updatedOn: string,
        teamContactId?: string | null,
        teamLocationId?: string | null,
        teamCreatorId?: string | null,
      },
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      status?: TeamStatus | null,
      role?: TeamRole | null,
      permissionLevel?: TeamPermissionLevel | null,
      creatorId?: string | null,
      creator?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      teamMemberCreatorId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TextsByEventIdQueryVariables = {
  eventId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTextFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TextsByEventIdQuery = {
  textsByEventId?:  {
    __typename: "ModelTextConnection",
    items:  Array< {
      __typename: "Text",
      id: string,
      eventId?: string | null,
      blockId?: string | null,
      block?:  {
        __typename: "Block",
        id: string,
        name?: string | null,
        type?: string | null,
        personid?: string | null,
        organizationId?: string | null,
        purchasedOn?: string | null,
        expiresOn?: string | null,
        totalCredits?: number | null,
        usedCredits?: number | null,
        status?: BlockStatus | null,
        price?: number | null,
        amountPaid?: number | null,
        isPromotion?: string | null,
        isFree?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      alertId?: string | null,
      alert?:  {
        __typename: "Alert",
        id: string,
        type?: string | null,
        title?: string | null,
        body?: string | null,
        imageKey?: string | null,
        category?: string | null,
        organizationId?: string | null,
        eventId?: string | null,
        messageSegments?: number | null,
        subscriberCount?: number | null,
        totalCost?: number | null,
        blockIdsUsed?: Array< string | null > | null,
        fromUnlimitedEvent?: boolean | null,
        isIncoming?: string | null,
        sentFromPhoneNumber?: string | null,
        recipientPhoneNumbers?: Array< string | null > | null,
        successSIDList?: Array< string | null > | null,
        errorSIDList?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        alertEventId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "EventAuditor",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        auditorId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        status?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorEventId?: string | null,
      } | null,
      status?: TextStatus | null,
      statusNote?: string | null,
      errorCode?: string | null,
      twilioSID?: string | null,
      twilioMessagingServiceSID?: string | null,
      twilioNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      timeSent?: string | null,
      timeDelivered?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateAddressSubscription = {
  onCreateAddress?:  {
    __typename: "Address",
    id: string,
    name?: string | null,
    type?: string | null,
    isDefault?: boolean | null,
    streetAddress1?: string | null,
    streetAddress2?: string | null,
    city?: string | null,
    provState?: string | null,
    zip?: string | null,
    country?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAddressSubscription = {
  onUpdateAddress?:  {
    __typename: "Address",
    id: string,
    name?: string | null,
    type?: string | null,
    isDefault?: boolean | null,
    streetAddress1?: string | null,
    streetAddress2?: string | null,
    city?: string | null,
    provState?: string | null,
    zip?: string | null,
    country?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAddressSubscription = {
  onDeleteAddress?:  {
    __typename: "Address",
    id: string,
    name?: string | null,
    type?: string | null,
    isDefault?: boolean | null,
    streetAddress1?: string | null,
    streetAddress2?: string | null,
    city?: string | null,
    provState?: string | null,
    zip?: string | null,
    country?: string | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAlertSubscription = {
  onCreateAlert?:  {
    __typename: "Alert",
    id: string,
    type?: string | null,
    title?: string | null,
    body?: string | null,
    imageKey?: string | null,
    category?: string | null,
    organizationId?: string | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    messageSegments?: number | null,
    subscriberCount?: number | null,
    totalCost?: number | null,
    blockIdsUsed?: Array< string | null > | null,
    fromUnlimitedEvent?: boolean | null,
    isIncoming?: string | null,
    sentFromPhoneNumber?: string | null,
    recipientPhoneNumbers?: Array< string | null > | null,
    successSIDList?: Array< string | null > | null,
    errorSIDList?: Array< string | null > | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    alertEventId?: string | null,
  } | null,
};

export type OnUpdateAlertSubscription = {
  onUpdateAlert?:  {
    __typename: "Alert",
    id: string,
    type?: string | null,
    title?: string | null,
    body?: string | null,
    imageKey?: string | null,
    category?: string | null,
    organizationId?: string | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    messageSegments?: number | null,
    subscriberCount?: number | null,
    totalCost?: number | null,
    blockIdsUsed?: Array< string | null > | null,
    fromUnlimitedEvent?: boolean | null,
    isIncoming?: string | null,
    sentFromPhoneNumber?: string | null,
    recipientPhoneNumbers?: Array< string | null > | null,
    successSIDList?: Array< string | null > | null,
    errorSIDList?: Array< string | null > | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    alertEventId?: string | null,
  } | null,
};

export type OnDeleteAlertSubscription = {
  onDeleteAlert?:  {
    __typename: "Alert",
    id: string,
    type?: string | null,
    title?: string | null,
    body?: string | null,
    imageKey?: string | null,
    category?: string | null,
    organizationId?: string | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    messageSegments?: number | null,
    subscriberCount?: number | null,
    totalCost?: number | null,
    blockIdsUsed?: Array< string | null > | null,
    fromUnlimitedEvent?: boolean | null,
    isIncoming?: string | null,
    sentFromPhoneNumber?: string | null,
    recipientPhoneNumbers?: Array< string | null > | null,
    successSIDList?: Array< string | null > | null,
    errorSIDList?: Array< string | null > | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    alertEventId?: string | null,
  } | null,
};

export type OnCreateAuditorSubscription = {
  onCreateAuditor?:  {
    __typename: "Auditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    email?: string | null,
    status?: AuditorStatus | null,
    statusNote?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateAuditorSubscription = {
  onUpdateAuditor?:  {
    __typename: "Auditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    email?: string | null,
    status?: AuditorStatus | null,
    statusNote?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteAuditorSubscription = {
  onDeleteAuditor?:  {
    __typename: "Auditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    email?: string | null,
    status?: AuditorStatus | null,
    statusNote?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateBacklogEventResultSubscription = {
  onCreateBacklogEventResult?:  {
    __typename: "BacklogEventResult",
    id: string,
    organizationId?: string | null,
    organizationMemberId?: string | null,
    organizationResultId?: string | null,
    eventId?: string | null,
    eventName?: string | null,
    eventStartDate?: string | null,
    eventEndDate?: string | null,
    eventDivisionId?: string | null,
    eventDivisionName?: string | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventClassId?: string | null,
    eventClassName?: string | null,
    eventClassNumber?: string | null,
    organizationClassId?: string | null,
    organizationClass?:  {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null,
    riderName?: string | null,
    horseName?: string | null,
    place?: number | null,
    pointsEarned?: number | null,
    score?: number | null,
    time?: string | null,
    didNotCompete?: boolean | null,
    prizeMoney?: number | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    backlogEventResultPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnUpdateBacklogEventResultSubscription = {
  onUpdateBacklogEventResult?:  {
    __typename: "BacklogEventResult",
    id: string,
    organizationId?: string | null,
    organizationMemberId?: string | null,
    organizationResultId?: string | null,
    eventId?: string | null,
    eventName?: string | null,
    eventStartDate?: string | null,
    eventEndDate?: string | null,
    eventDivisionId?: string | null,
    eventDivisionName?: string | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventClassId?: string | null,
    eventClassName?: string | null,
    eventClassNumber?: string | null,
    organizationClassId?: string | null,
    organizationClass?:  {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null,
    riderName?: string | null,
    horseName?: string | null,
    place?: number | null,
    pointsEarned?: number | null,
    score?: number | null,
    time?: string | null,
    didNotCompete?: boolean | null,
    prizeMoney?: number | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    backlogEventResultPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnDeleteBacklogEventResultSubscription = {
  onDeleteBacklogEventResult?:  {
    __typename: "BacklogEventResult",
    id: string,
    organizationId?: string | null,
    organizationMemberId?: string | null,
    organizationResultId?: string | null,
    eventId?: string | null,
    eventName?: string | null,
    eventStartDate?: string | null,
    eventEndDate?: string | null,
    eventDivisionId?: string | null,
    eventDivisionName?: string | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventClassId?: string | null,
    eventClassName?: string | null,
    eventClassNumber?: string | null,
    organizationClassId?: string | null,
    organizationClass?:  {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null,
    riderName?: string | null,
    horseName?: string | null,
    place?: number | null,
    pointsEarned?: number | null,
    score?: number | null,
    time?: string | null,
    didNotCompete?: boolean | null,
    prizeMoney?: number | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    backlogEventResultPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnCreateBarnSubscription = {
  onCreateBarn?:  {
    __typename: "Barn",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    type?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ownerName?: string | null,
    ownerPersonId?: string | null,
    secondaryOwnerName?: string | null,
    secondaryOwnerPersonId?: string | null,
    members?:  {
      __typename: "ModelBarnMemberConnection",
      items:  Array< {
        __typename: "BarnMember",
        id: string,
        name: string,
        barnId: string,
        personId: string,
        permissionLevel?: string | null,
        status?: string | null,
        roles?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    barnContactId?: string | null,
    barnLocationId?: string | null,
  } | null,
};

export type OnUpdateBarnSubscription = {
  onUpdateBarn?:  {
    __typename: "Barn",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    type?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ownerName?: string | null,
    ownerPersonId?: string | null,
    secondaryOwnerName?: string | null,
    secondaryOwnerPersonId?: string | null,
    members?:  {
      __typename: "ModelBarnMemberConnection",
      items:  Array< {
        __typename: "BarnMember",
        id: string,
        name: string,
        barnId: string,
        personId: string,
        permissionLevel?: string | null,
        status?: string | null,
        roles?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    barnContactId?: string | null,
    barnLocationId?: string | null,
  } | null,
};

export type OnDeleteBarnSubscription = {
  onDeleteBarn?:  {
    __typename: "Barn",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    type?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    ownerName?: string | null,
    ownerPersonId?: string | null,
    secondaryOwnerName?: string | null,
    secondaryOwnerPersonId?: string | null,
    members?:  {
      __typename: "ModelBarnMemberConnection",
      items:  Array< {
        __typename: "BarnMember",
        id: string,
        name: string,
        barnId: string,
        personId: string,
        permissionLevel?: string | null,
        status?: string | null,
        roles?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    barnContactId?: string | null,
    barnLocationId?: string | null,
  } | null,
};

export type OnCreateBarnMemberSubscription = {
  onCreateBarnMember?:  {
    __typename: "BarnMember",
    id: string,
    name: string,
    barnId: string,
    barn:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    },
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    permissionLevel?: string | null,
    status?: string | null,
    roles?: string | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateBarnMemberSubscription = {
  onUpdateBarnMember?:  {
    __typename: "BarnMember",
    id: string,
    name: string,
    barnId: string,
    barn:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    },
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    permissionLevel?: string | null,
    status?: string | null,
    roles?: string | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteBarnMemberSubscription = {
  onDeleteBarnMember?:  {
    __typename: "BarnMember",
    id: string,
    name: string,
    barnId: string,
    barn:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    },
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    permissionLevel?: string | null,
    status?: string | null,
    roles?: string | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateBlockSubscription = {
  onCreateBlock?:  {
    __typename: "Block",
    id: string,
    name?: string | null,
    type?: string | null,
    personid?: string | null,
    organizationId?: string | null,
    purchasedOn?: string | null,
    expiresOn?: string | null,
    totalCredits?: number | null,
    usedCredits?: number | null,
    status?: BlockStatus | null,
    price?: number | null,
    amountPaid?: number | null,
    isPromotion?: string | null,
    isFree?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateBlockSubscription = {
  onUpdateBlock?:  {
    __typename: "Block",
    id: string,
    name?: string | null,
    type?: string | null,
    personid?: string | null,
    organizationId?: string | null,
    purchasedOn?: string | null,
    expiresOn?: string | null,
    totalCredits?: number | null,
    usedCredits?: number | null,
    status?: BlockStatus | null,
    price?: number | null,
    amountPaid?: number | null,
    isPromotion?: string | null,
    isFree?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteBlockSubscription = {
  onDeleteBlock?:  {
    __typename: "Block",
    id: string,
    name?: string | null,
    type?: string | null,
    personid?: string | null,
    organizationId?: string | null,
    purchasedOn?: string | null,
    expiresOn?: string | null,
    totalCredits?: number | null,
    usedCredits?: number | null,
    status?: BlockStatus | null,
    price?: number | null,
    amountPaid?: number | null,
    isPromotion?: string | null,
    isFree?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateClassSubscription = {
  onCreateClass?:  {
    __typename: "Class",
    id: string,
    name: string,
    nickname?: string | null,
    divisionId?: string | null,
    type?: string | null,
    description?: string | null,
    discipline?: string | null,
    ushjaName?: string | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    ecClassTypeNumber?: string | null,
    ecClassType?: string | null,
    code?: string | null,
    specs?: string | null,
    judgedOn?: string | null,
    rules?:  {
      __typename: "DivisionRules",
      horseSex?: string | null,
      horseBreed?: string | null,
      horseMinHeight?: string | null,
      horseMaxHeight?: string | null,
      horseMinAge?: string | null,
      horseMaxAge?: string | null,
      riderMinAge?: string | null,
      riderMaxAge?: string | null,
      riderProfessionalStatus?: string | null,
      riderRecord?: string | null,
      horseRecord?: string | null,
      crossEntry?: string | null,
      other?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceClassesId?: string | null,
  } | null,
};

export type OnUpdateClassSubscription = {
  onUpdateClass?:  {
    __typename: "Class",
    id: string,
    name: string,
    nickname?: string | null,
    divisionId?: string | null,
    type?: string | null,
    description?: string | null,
    discipline?: string | null,
    ushjaName?: string | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    ecClassTypeNumber?: string | null,
    ecClassType?: string | null,
    code?: string | null,
    specs?: string | null,
    judgedOn?: string | null,
    rules?:  {
      __typename: "DivisionRules",
      horseSex?: string | null,
      horseBreed?: string | null,
      horseMinHeight?: string | null,
      horseMaxHeight?: string | null,
      horseMinAge?: string | null,
      horseMaxAge?: string | null,
      riderMinAge?: string | null,
      riderMaxAge?: string | null,
      riderProfessionalStatus?: string | null,
      riderRecord?: string | null,
      horseRecord?: string | null,
      crossEntry?: string | null,
      other?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceClassesId?: string | null,
  } | null,
};

export type OnDeleteClassSubscription = {
  onDeleteClass?:  {
    __typename: "Class",
    id: string,
    name: string,
    nickname?: string | null,
    divisionId?: string | null,
    type?: string | null,
    description?: string | null,
    discipline?: string | null,
    ushjaName?: string | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    ecClassTypeNumber?: string | null,
    ecClassType?: string | null,
    code?: string | null,
    specs?: string | null,
    judgedOn?: string | null,
    rules?:  {
      __typename: "DivisionRules",
      horseSex?: string | null,
      horseBreed?: string | null,
      horseMinHeight?: string | null,
      horseMaxHeight?: string | null,
      horseMinAge?: string | null,
      horseMaxAge?: string | null,
      riderMinAge?: string | null,
      riderMaxAge?: string | null,
      riderProfessionalStatus?: string | null,
      riderRecord?: string | null,
      horseRecord?: string | null,
      crossEntry?: string | null,
      other?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceClassesId?: string | null,
  } | null,
};

export type OnCreateClassOrderSubscription = {
  onCreateClassOrder?:  {
    __typename: "ClassOrder",
    id: string,
    eventId: string,
    eventClassId: string,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    entryId: string,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    order?: number | null,
    isRandom?: boolean | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateClassOrderSubscription = {
  onUpdateClassOrder?:  {
    __typename: "ClassOrder",
    id: string,
    eventId: string,
    eventClassId: string,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    entryId: string,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    order?: number | null,
    isRandom?: boolean | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteClassOrderSubscription = {
  onDeleteClassOrder?:  {
    __typename: "ClassOrder",
    id: string,
    eventId: string,
    eventClassId: string,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    entryId: string,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    order?: number | null,
    isRandom?: boolean | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateClinicianSubscription = {
  onCreateClinician?:  {
    __typename: "Clinician",
    id: string,
    name: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    judgeId?: string | null,
    contactId?: string | null,
    rating?:  Array< {
      __typename: "JudgeRating",
      type?: JudgeType | null,
      license?: JudgeLicense | null,
    } | null > | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    price?: number | null,
    status?: ClinicianStatus | null,
    paymentChoice?:  {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    paymentVerified?: boolean | null,
    stripeAccountId?: string | null,
    totalEarned?: number | null,
    location?: string | null,
    disciplines?: string | null,
    experience?: string | null,
    personal?: string | null,
    preferences?: string | null,
    hasIHSAExperience?: boolean | null,
    hasIEAExperience?: boolean | null,
    createdOn: string,
    updatedOn: string,
    clinicianPaymentChoiceId?: string | null,
  } | null,
};

export type OnUpdateClinicianSubscription = {
  onUpdateClinician?:  {
    __typename: "Clinician",
    id: string,
    name: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    judgeId?: string | null,
    contactId?: string | null,
    rating?:  Array< {
      __typename: "JudgeRating",
      type?: JudgeType | null,
      license?: JudgeLicense | null,
    } | null > | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    price?: number | null,
    status?: ClinicianStatus | null,
    paymentChoice?:  {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    paymentVerified?: boolean | null,
    stripeAccountId?: string | null,
    totalEarned?: number | null,
    location?: string | null,
    disciplines?: string | null,
    experience?: string | null,
    personal?: string | null,
    preferences?: string | null,
    hasIHSAExperience?: boolean | null,
    hasIEAExperience?: boolean | null,
    createdOn: string,
    updatedOn: string,
    clinicianPaymentChoiceId?: string | null,
  } | null,
};

export type OnDeleteClinicianSubscription = {
  onDeleteClinician?:  {
    __typename: "Clinician",
    id: string,
    name: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    judgeId?: string | null,
    contactId?: string | null,
    rating?:  Array< {
      __typename: "JudgeRating",
      type?: JudgeType | null,
      license?: JudgeLicense | null,
    } | null > | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    price?: number | null,
    status?: ClinicianStatus | null,
    paymentChoice?:  {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    paymentVerified?: boolean | null,
    stripeAccountId?: string | null,
    totalEarned?: number | null,
    location?: string | null,
    disciplines?: string | null,
    experience?: string | null,
    personal?: string | null,
    preferences?: string | null,
    hasIHSAExperience?: boolean | null,
    hasIEAExperience?: boolean | null,
    createdOn: string,
    updatedOn: string,
    clinicianPaymentChoiceId?: string | null,
  } | null,
};

export type OnCreateCompetitionSubscription = {
  onCreateCompetition?:  {
    __typename: "Competition",
    id: string,
    name: string,
    startDate?: string | null,
    endDate?: string | null,
    organizationId?: string | null,
    teamId?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    competitionOrganizerName: string,
    competitionOrganizerContactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    judgeName?: string | null,
    secretaryName?: string | null,
    StewardName?: string | null,
    isOpenOffered?: boolean | null,
    isIntermediateOffered?: boolean | null,
    isNoviceOffered?: boolean | null,
    isAdvancedBeginnerOffered?: boolean | null,
    isBeginnerOffered?: boolean | null,
    isMinisOffered?: boolean | null,
    isAdultOffered?: boolean | null,
    isAELMedalOffered?: boolean | null,
    entryFeePerRider?: number | null,
    entryFeePerRiderForMedal?: number | null,
    feePaymentOptions?: string | null,
    entryClosingDate?: string | null,
    entryClosingTime?: string | null,
    isEntryLimitInPlace?: boolean | null,
    entryLimitDescription?: string | null,
    otherNotes?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateCompetitionSubscription = {
  onUpdateCompetition?:  {
    __typename: "Competition",
    id: string,
    name: string,
    startDate?: string | null,
    endDate?: string | null,
    organizationId?: string | null,
    teamId?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    competitionOrganizerName: string,
    competitionOrganizerContactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    judgeName?: string | null,
    secretaryName?: string | null,
    StewardName?: string | null,
    isOpenOffered?: boolean | null,
    isIntermediateOffered?: boolean | null,
    isNoviceOffered?: boolean | null,
    isAdvancedBeginnerOffered?: boolean | null,
    isBeginnerOffered?: boolean | null,
    isMinisOffered?: boolean | null,
    isAdultOffered?: boolean | null,
    isAELMedalOffered?: boolean | null,
    entryFeePerRider?: number | null,
    entryFeePerRiderForMedal?: number | null,
    feePaymentOptions?: string | null,
    entryClosingDate?: string | null,
    entryClosingTime?: string | null,
    isEntryLimitInPlace?: boolean | null,
    entryLimitDescription?: string | null,
    otherNotes?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteCompetitionSubscription = {
  onDeleteCompetition?:  {
    __typename: "Competition",
    id: string,
    name: string,
    startDate?: string | null,
    endDate?: string | null,
    organizationId?: string | null,
    teamId?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    competitionOrganizerName: string,
    competitionOrganizerContactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    judgeName?: string | null,
    secretaryName?: string | null,
    StewardName?: string | null,
    isOpenOffered?: boolean | null,
    isIntermediateOffered?: boolean | null,
    isNoviceOffered?: boolean | null,
    isAdvancedBeginnerOffered?: boolean | null,
    isBeginnerOffered?: boolean | null,
    isMinisOffered?: boolean | null,
    isAdultOffered?: boolean | null,
    isAELMedalOffered?: boolean | null,
    entryFeePerRider?: number | null,
    entryFeePerRiderForMedal?: number | null,
    feePaymentOptions?: string | null,
    entryClosingDate?: string | null,
    entryClosingTime?: string | null,
    isEntryLimitInPlace?: boolean | null,
    entryLimitDescription?: string | null,
    otherNotes?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateContactSubscription = {
  onCreateContact?:  {
    __typename: "Contact",
    id: string,
    name?: string | null,
    cell?: string | null,
    home?: string | null,
    work?: string | null,
    fax?: string | null,
    personalEmail?: string | null,
    personalEmailIsVerified?: boolean | null,
    workEmail?: string | null,
    workEmailIsVerified?: boolean | null,
    mailingAddress?: string | null,
    website?: string | null,
    facebookPage?: string | null,
    instagramHandle?: string | null,
    twitterHandle?: string | null,
    tiktokHandle?: string | null,
    emergencyContactName?: string | null,
    emergencyContactPhone?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateContactSubscription = {
  onUpdateContact?:  {
    __typename: "Contact",
    id: string,
    name?: string | null,
    cell?: string | null,
    home?: string | null,
    work?: string | null,
    fax?: string | null,
    personalEmail?: string | null,
    personalEmailIsVerified?: boolean | null,
    workEmail?: string | null,
    workEmailIsVerified?: boolean | null,
    mailingAddress?: string | null,
    website?: string | null,
    facebookPage?: string | null,
    instagramHandle?: string | null,
    twitterHandle?: string | null,
    tiktokHandle?: string | null,
    emergencyContactName?: string | null,
    emergencyContactPhone?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteContactSubscription = {
  onDeleteContact?:  {
    __typename: "Contact",
    id: string,
    name?: string | null,
    cell?: string | null,
    home?: string | null,
    work?: string | null,
    fax?: string | null,
    personalEmail?: string | null,
    personalEmailIsVerified?: boolean | null,
    workEmail?: string | null,
    workEmailIsVerified?: boolean | null,
    mailingAddress?: string | null,
    website?: string | null,
    facebookPage?: string | null,
    instagramHandle?: string | null,
    twitterHandle?: string | null,
    tiktokHandle?: string | null,
    emergencyContactName?: string | null,
    emergencyContactPhone?: string | null,
    createdBy?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDivisionSubscription = {
  onCreateDivision?:  {
    __typename: "Division",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    discipline?: string | null,
    isSearchable?: boolean | null,
    ushjaName?: string | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    ecClassTypeNumber?: string | null,
    ecClassType?: string | null,
    code?: string | null,
    specs?: string | null,
    judgedOn?: string | null,
    rules?:  {
      __typename: "DivisionRules",
      horseSex?: string | null,
      horseBreed?: string | null,
      horseMinHeight?: string | null,
      horseMaxHeight?: string | null,
      horseMinAge?: string | null,
      horseMaxAge?: string | null,
      riderMinAge?: string | null,
      riderMaxAge?: string | null,
      riderProfessionalStatus?: string | null,
      riderRecord?: string | null,
      horseRecord?: string | null,
      crossEntry?: string | null,
      other?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceDivisionsId?: string | null,
  } | null,
};

export type OnUpdateDivisionSubscription = {
  onUpdateDivision?:  {
    __typename: "Division",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    discipline?: string | null,
    isSearchable?: boolean | null,
    ushjaName?: string | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    ecClassTypeNumber?: string | null,
    ecClassType?: string | null,
    code?: string | null,
    specs?: string | null,
    judgedOn?: string | null,
    rules?:  {
      __typename: "DivisionRules",
      horseSex?: string | null,
      horseBreed?: string | null,
      horseMinHeight?: string | null,
      horseMaxHeight?: string | null,
      horseMinAge?: string | null,
      horseMaxAge?: string | null,
      riderMinAge?: string | null,
      riderMaxAge?: string | null,
      riderProfessionalStatus?: string | null,
      riderRecord?: string | null,
      horseRecord?: string | null,
      crossEntry?: string | null,
      other?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceDivisionsId?: string | null,
  } | null,
};

export type OnDeleteDivisionSubscription = {
  onDeleteDivision?:  {
    __typename: "Division",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    discipline?: string | null,
    isSearchable?: boolean | null,
    ushjaName?: string | null,
    usefNumber?: string | null,
    ecNumber?: string | null,
    ecClassTypeNumber?: string | null,
    ecClassType?: string | null,
    code?: string | null,
    specs?: string | null,
    judgedOn?: string | null,
    rules?:  {
      __typename: "DivisionRules",
      horseSex?: string | null,
      horseBreed?: string | null,
      horseMinHeight?: string | null,
      horseMaxHeight?: string | null,
      horseMinAge?: string | null,
      horseMaxAge?: string | null,
      riderMinAge?: string | null,
      riderMaxAge?: string | null,
      riderProfessionalStatus?: string | null,
      riderRecord?: string | null,
      horseRecord?: string | null,
      crossEntry?: string | null,
      other?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceDivisionsId?: string | null,
  } | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    nickname?: string | null,
    createdBy: string,
    manager?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contactId?: string | null,
    contactIds?: Array< string | null > | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    disciplines?: string | null,
    eventOptions?:  {
      __typename: "EventOptions",
      autoAcceptNewEntries?: boolean | null,
      usesTextAlerts?: boolean | null,
      acceptsIncomingTextAlerts?: boolean | null,
      hasUnlimitedCredits?: boolean | null,
      usesEventManagement?: boolean | null,
      usesStablingManagement?: boolean | null,
      usesVendorManagement?: boolean | null,
      usesMediaTools?: boolean | null,
      usesMarketingTools?: boolean | null,
      usesFinanceTools?: boolean | null,
      usesJudgeApp?: boolean | null,
    } | null,
    paymentOptions?:  {
      __typename: "PaymentOptions",
      acceptsChecks?: boolean | null,
      acceptsCash?: boolean | null,
      acceptsACH?: boolean | null,
      acceptsCC?: boolean | null,
    } | null,
    invoiceOptions?:  {
      __typename: "InvoiceOptions",
      classFeeTax?: number | null,
      sendEmails?: boolean | null,
    } | null,
    entryOptions?:  {
      __typename: "EntryOptions",
      displayHorse2Input?: boolean | null,
      displayRider2Input?: boolean | null,
      displayRider3Input?: boolean | null,
      requiresCogginsDocument?: boolean | null,
      requiresCogginsData?: boolean | null,
      maximumNumberClassesOnEntry?: number | null,
    } | null,
    officials?:  {
      __typename: "ModelEventOfficialConnection",
      items:  Array< {
        __typename: "EventOfficial",
        id: string,
        name: string,
        position: string,
        eventId: string,
        personId?: string | null,
        contactId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: string | null,
    entriesStatus?: string | null,
    showStatus?: string | null,
    usefID?: string | null,
    usefSoftwareKey?: string | null,
    ecID?: string | null,
    ecPassword?: string | null,
    documents?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAddressId?: string | null,
  } | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    nickname?: string | null,
    createdBy: string,
    manager?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contactId?: string | null,
    contactIds?: Array< string | null > | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    disciplines?: string | null,
    eventOptions?:  {
      __typename: "EventOptions",
      autoAcceptNewEntries?: boolean | null,
      usesTextAlerts?: boolean | null,
      acceptsIncomingTextAlerts?: boolean | null,
      hasUnlimitedCredits?: boolean | null,
      usesEventManagement?: boolean | null,
      usesStablingManagement?: boolean | null,
      usesVendorManagement?: boolean | null,
      usesMediaTools?: boolean | null,
      usesMarketingTools?: boolean | null,
      usesFinanceTools?: boolean | null,
      usesJudgeApp?: boolean | null,
    } | null,
    paymentOptions?:  {
      __typename: "PaymentOptions",
      acceptsChecks?: boolean | null,
      acceptsCash?: boolean | null,
      acceptsACH?: boolean | null,
      acceptsCC?: boolean | null,
    } | null,
    invoiceOptions?:  {
      __typename: "InvoiceOptions",
      classFeeTax?: number | null,
      sendEmails?: boolean | null,
    } | null,
    entryOptions?:  {
      __typename: "EntryOptions",
      displayHorse2Input?: boolean | null,
      displayRider2Input?: boolean | null,
      displayRider3Input?: boolean | null,
      requiresCogginsDocument?: boolean | null,
      requiresCogginsData?: boolean | null,
      maximumNumberClassesOnEntry?: number | null,
    } | null,
    officials?:  {
      __typename: "ModelEventOfficialConnection",
      items:  Array< {
        __typename: "EventOfficial",
        id: string,
        name: string,
        position: string,
        eventId: string,
        personId?: string | null,
        contactId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: string | null,
    entriesStatus?: string | null,
    showStatus?: string | null,
    usefID?: string | null,
    usefSoftwareKey?: string | null,
    ecID?: string | null,
    ecPassword?: string | null,
    documents?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAddressId?: string | null,
  } | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    nickname?: string | null,
    createdBy: string,
    manager?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contactId?: string | null,
    contactIds?: Array< string | null > | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    disciplines?: string | null,
    eventOptions?:  {
      __typename: "EventOptions",
      autoAcceptNewEntries?: boolean | null,
      usesTextAlerts?: boolean | null,
      acceptsIncomingTextAlerts?: boolean | null,
      hasUnlimitedCredits?: boolean | null,
      usesEventManagement?: boolean | null,
      usesStablingManagement?: boolean | null,
      usesVendorManagement?: boolean | null,
      usesMediaTools?: boolean | null,
      usesMarketingTools?: boolean | null,
      usesFinanceTools?: boolean | null,
      usesJudgeApp?: boolean | null,
    } | null,
    paymentOptions?:  {
      __typename: "PaymentOptions",
      acceptsChecks?: boolean | null,
      acceptsCash?: boolean | null,
      acceptsACH?: boolean | null,
      acceptsCC?: boolean | null,
    } | null,
    invoiceOptions?:  {
      __typename: "InvoiceOptions",
      classFeeTax?: number | null,
      sendEmails?: boolean | null,
    } | null,
    entryOptions?:  {
      __typename: "EntryOptions",
      displayHorse2Input?: boolean | null,
      displayRider2Input?: boolean | null,
      displayRider3Input?: boolean | null,
      requiresCogginsDocument?: boolean | null,
      requiresCogginsData?: boolean | null,
      maximumNumberClassesOnEntry?: number | null,
    } | null,
    officials?:  {
      __typename: "ModelEventOfficialConnection",
      items:  Array< {
        __typename: "EventOfficial",
        id: string,
        name: string,
        position: string,
        eventId: string,
        personId?: string | null,
        contactId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    status?: string | null,
    entriesStatus?: string | null,
    showStatus?: string | null,
    usefID?: string | null,
    usefSoftwareKey?: string | null,
    ecID?: string | null,
    ecPassword?: string | null,
    documents?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAddressId?: string | null,
  } | null,
};

export type OnCreateEventAddSubscription = {
  onCreateEventAdd?:  {
    __typename: "EventAdd",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventAddSubscription = {
  onUpdateEventAdd?:  {
    __typename: "EventAdd",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventAddSubscription = {
  onDeleteEventAdd?:  {
    __typename: "EventAdd",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateEventAlertSettingSubscription = {
  onCreateEventAlertSetting?:  {
    __typename: "EventAlertSetting",
    id: string,
    eventId?: string | null,
    categories?: Array< string | null > | null,
    useDescription?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventAlertSettingSubscription = {
  onUpdateEventAlertSetting?:  {
    __typename: "EventAlertSetting",
    id: string,
    eventId?: string | null,
    categories?: Array< string | null > | null,
    useDescription?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventAlertSettingSubscription = {
  onDeleteEventAlertSetting?:  {
    __typename: "EventAlertSetting",
    id: string,
    eventId?: string | null,
    categories?: Array< string | null > | null,
    useDescription?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateEventAuditorSubscription = {
  onCreateEventAuditor?:  {
    __typename: "EventAuditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    filters?:  {
      __typename: "ModelEventAuditorFilterConnection",
      items:  Array< {
        __typename: "EventAuditorFilter",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        eventauditorid?: string | null,
        eventAuditorId?: string | null,
        organizationId?: string | null,
        organizationAuditorId?: string | null,
        filterId?: string | null,
        options?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorFilterOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    status?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAuditorEventId?: string | null,
  } | null,
};

export type OnUpdateEventAuditorSubscription = {
  onUpdateEventAuditor?:  {
    __typename: "EventAuditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    filters?:  {
      __typename: "ModelEventAuditorFilterConnection",
      items:  Array< {
        __typename: "EventAuditorFilter",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        eventauditorid?: string | null,
        eventAuditorId?: string | null,
        organizationId?: string | null,
        organizationAuditorId?: string | null,
        filterId?: string | null,
        options?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorFilterOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    status?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAuditorEventId?: string | null,
  } | null,
};

export type OnDeleteEventAuditorSubscription = {
  onDeleteEventAuditor?:  {
    __typename: "EventAuditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    filters?:  {
      __typename: "ModelEventAuditorFilterConnection",
      items:  Array< {
        __typename: "EventAuditorFilter",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        eventauditorid?: string | null,
        eventAuditorId?: string | null,
        organizationId?: string | null,
        organizationAuditorId?: string | null,
        filterId?: string | null,
        options?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorFilterOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    status?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAuditorEventId?: string | null,
  } | null,
};

export type OnCreateOrganizationAuditorSubscription = {
  onCreateOrganizationAuditor?:  {
    __typename: "OrganizationAuditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    howSignedUp?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    filters?:  {
      __typename: "ModelEventAuditorFilterConnection",
      items:  Array< {
        __typename: "EventAuditorFilter",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        eventauditorid?: string | null,
        eventAuditorId?: string | null,
        organizationId?: string | null,
        organizationAuditorId?: string | null,
        filterId?: string | null,
        options?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorFilterOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    status?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    organizationAuditorPersonId?: string | null,
    organizationAuditorOrganizationId?: string | null,
  } | null,
};

export type OnUpdateOrganizationAuditorSubscription = {
  onUpdateOrganizationAuditor?:  {
    __typename: "OrganizationAuditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    howSignedUp?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    filters?:  {
      __typename: "ModelEventAuditorFilterConnection",
      items:  Array< {
        __typename: "EventAuditorFilter",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        eventauditorid?: string | null,
        eventAuditorId?: string | null,
        organizationId?: string | null,
        organizationAuditorId?: string | null,
        filterId?: string | null,
        options?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorFilterOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    status?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    organizationAuditorPersonId?: string | null,
    organizationAuditorOrganizationId?: string | null,
  } | null,
};

export type OnDeleteOrganizationAuditorSubscription = {
  onDeleteOrganizationAuditor?:  {
    __typename: "OrganizationAuditor",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    howSignedUp?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "Auditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      email?: string | null,
      status?: AuditorStatus | null,
      statusNote?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    filters?:  {
      __typename: "ModelEventAuditorFilterConnection",
      items:  Array< {
        __typename: "EventAuditorFilter",
        id: string,
        personId?: string | null,
        eventId?: string | null,
        eventauditorid?: string | null,
        eventAuditorId?: string | null,
        organizationId?: string | null,
        organizationAuditorId?: string | null,
        filterId?: string | null,
        options?: Array< string | null > | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAuditorFilterOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    name?: string | null,
    phoneNumber?: string | null,
    status?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    organizationAuditorPersonId?: string | null,
    organizationAuditorOrganizationId?: string | null,
  } | null,
};

export type OnCreateEventAuditorFilterSubscription = {
  onCreateEventAuditorFilter?:  {
    __typename: "EventAuditorFilter",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    eventauditorid?: string | null,
    eventAuditorId?: string | null,
    eventAuditor?:  {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationAuditorId?: string | null,
    organizationAuditor?:  {
      __typename: "OrganizationAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      howSignedUp?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationAuditorPersonId?: string | null,
      organizationAuditorOrganizationId?: string | null,
    } | null,
    filterId?: string | null,
    filter?:  {
      __typename: "EventFilter",
      id: string,
      eventId?: string | null,
      organizationID?: string | null,
      name?: string | null,
      options?: Array< string | null > | null,
      isRequired?: boolean | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    options?: Array< string | null > | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAuditorFilterOrganizationId?: string | null,
  } | null,
};

export type OnUpdateEventAuditorFilterSubscription = {
  onUpdateEventAuditorFilter?:  {
    __typename: "EventAuditorFilter",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    eventauditorid?: string | null,
    eventAuditorId?: string | null,
    eventAuditor?:  {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationAuditorId?: string | null,
    organizationAuditor?:  {
      __typename: "OrganizationAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      howSignedUp?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationAuditorPersonId?: string | null,
      organizationAuditorOrganizationId?: string | null,
    } | null,
    filterId?: string | null,
    filter?:  {
      __typename: "EventFilter",
      id: string,
      eventId?: string | null,
      organizationID?: string | null,
      name?: string | null,
      options?: Array< string | null > | null,
      isRequired?: boolean | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    options?: Array< string | null > | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAuditorFilterOrganizationId?: string | null,
  } | null,
};

export type OnDeleteEventAuditorFilterSubscription = {
  onDeleteEventAuditorFilter?:  {
    __typename: "EventAuditorFilter",
    id: string,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventId?: string | null,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    eventauditorid?: string | null,
    eventAuditorId?: string | null,
    eventAuditor?:  {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationAuditorId?: string | null,
    organizationAuditor?:  {
      __typename: "OrganizationAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      howSignedUp?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationAuditorPersonId?: string | null,
      organizationAuditorOrganizationId?: string | null,
    } | null,
    filterId?: string | null,
    filter?:  {
      __typename: "EventFilter",
      id: string,
      eventId?: string | null,
      organizationID?: string | null,
      name?: string | null,
      options?: Array< string | null > | null,
      isRequired?: boolean | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    options?: Array< string | null > | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventAuditorFilterOrganizationId?: string | null,
  } | null,
};

export type OnCreateEventBeddingTypeSubscription = {
  onCreateEventBeddingType?:  {
    __typename: "EventBeddingType",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationBeddingTypeId?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerBag?: string | null,
    taxPerBag?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventBeddingTypeSubscription = {
  onUpdateEventBeddingType?:  {
    __typename: "EventBeddingType",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationBeddingTypeId?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerBag?: string | null,
    taxPerBag?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventBeddingTypeSubscription = {
  onDeleteEventBeddingType?:  {
    __typename: "EventBeddingType",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationBeddingTypeId?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerBag?: string | null,
    taxPerBag?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateEventBreakSubscription = {
  onCreateEventBreak?:  {
    __typename: "EventBreak",
    id: string,
    eventId: string,
    name?: string | null,
    time?: string | null,
    isDisplayedOnSchedule?: boolean | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventBreakSubscription = {
  onUpdateEventBreak?:  {
    __typename: "EventBreak",
    id: string,
    eventId: string,
    name?: string | null,
    time?: string | null,
    isDisplayedOnSchedule?: boolean | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventBreakSubscription = {
  onDeleteEventBreak?:  {
    __typename: "EventBreak",
    id: string,
    eventId: string,
    name?: string | null,
    time?: string | null,
    isDisplayedOnSchedule?: boolean | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateEventClassSubscription = {
  onCreateEventClass?:  {
    __typename: "EventClass",
    id: string,
    name: string,
    eventId: string,
    classId: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    } | null,
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    organizationClassId?: string | null,
    organizationClass?:  {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventResults?:  {
      __typename: "ModelEventResultConnection",
      items:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    number?: number | null,
    entryOption?: EntryOption | null,
    entryFee?: string | null,
    fees?:  {
      __typename: "ModelEventFeeConnection",
      items:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prizeMoney?: string | null,
    entries?:  {
      __typename: "ModelEventClassEntryConnection",
      items:  Array< {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedVerifyEntries?: boolean | null,
    currentNumberEntries?: number | null,
    numberEntriesThatCompeted?: number | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    estimatedTimePerRound?: number | null,
    isCaliforniaSplit?: boolean | null,
    isCombined?: boolean | null,
    combinedEventClassId?: string | null,
    combinedEventDivisionId?: string | null,
    jumpHeight?: Array< string | null > | null,
    isScheduled?: boolean | null,
    note?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventClassPointTableId?: string | null,
    eventClassPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnUpdateEventClassSubscription = {
  onUpdateEventClass?:  {
    __typename: "EventClass",
    id: string,
    name: string,
    eventId: string,
    classId: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    } | null,
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    organizationClassId?: string | null,
    organizationClass?:  {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventResults?:  {
      __typename: "ModelEventResultConnection",
      items:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    number?: number | null,
    entryOption?: EntryOption | null,
    entryFee?: string | null,
    fees?:  {
      __typename: "ModelEventFeeConnection",
      items:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prizeMoney?: string | null,
    entries?:  {
      __typename: "ModelEventClassEntryConnection",
      items:  Array< {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedVerifyEntries?: boolean | null,
    currentNumberEntries?: number | null,
    numberEntriesThatCompeted?: number | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    estimatedTimePerRound?: number | null,
    isCaliforniaSplit?: boolean | null,
    isCombined?: boolean | null,
    combinedEventClassId?: string | null,
    combinedEventDivisionId?: string | null,
    jumpHeight?: Array< string | null > | null,
    isScheduled?: boolean | null,
    note?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventClassPointTableId?: string | null,
    eventClassPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnDeleteEventClassSubscription = {
  onDeleteEventClass?:  {
    __typename: "EventClass",
    id: string,
    name: string,
    eventId: string,
    classId: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    } | null,
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    organizationClassId?: string | null,
    organizationClass?:  {
      __typename: "OrganizationClass",
      id: string,
      name: string,
      classId: string,
      class:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      },
      organizationId: string,
      organizationDivisionId?: string | null,
      hasChampionship?: boolean | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      jumpHeight?: Array< string | null > | null,
      estimatedTimePerRound?: number | null,
      createdBy: string,
      displayOrder?: number | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      organizationClassPointTableId?: string | null,
      organizationClassPrizeMoneyTableId?: string | null,
    } | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventResults?:  {
      __typename: "ModelEventResultConnection",
      items:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    number?: number | null,
    entryOption?: EntryOption | null,
    entryFee?: string | null,
    fees?:  {
      __typename: "ModelEventFeeConnection",
      items:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    prizeMoney?: string | null,
    entries?:  {
      __typename: "ModelEventClassEntryConnection",
      items:  Array< {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    completedVerifyEntries?: boolean | null,
    currentNumberEntries?: number | null,
    numberEntriesThatCompeted?: number | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    estimatedTimePerRound?: number | null,
    isCaliforniaSplit?: boolean | null,
    isCombined?: boolean | null,
    combinedEventClassId?: string | null,
    combinedEventDivisionId?: string | null,
    jumpHeight?: Array< string | null > | null,
    isScheduled?: boolean | null,
    note?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventClassPointTableId?: string | null,
    eventClassPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnCreateEventClassEntrySubscription = {
  onCreateEventClassEntry?:  {
    __typename: "EventClassEntry",
    id: string,
    eventId: string,
    eventClassId: string,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventEntryId: string,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    status?: string | null,
    selectedEntryOption?: EntryOption | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventClassEntrySubscription = {
  onUpdateEventClassEntry?:  {
    __typename: "EventClassEntry",
    id: string,
    eventId: string,
    eventClassId: string,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventEntryId: string,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    status?: string | null,
    selectedEntryOption?: EntryOption | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventClassEntrySubscription = {
  onDeleteEventClassEntry?:  {
    __typename: "EventClassEntry",
    id: string,
    eventId: string,
    eventClassId: string,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventEntryId: string,
    eventEntry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    status?: string | null,
    selectedEntryOption?: EntryOption | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateEventDaySubscription = {
  onCreateEventDay?:  {
    __typename: "EventDay",
    id: string,
    eventId?: string | null,
    awsdate?: string | null,
    dayofweek?: string | null,
    date?: string | null,
    month?: string | null,
    year?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventDaySubscription = {
  onUpdateEventDay?:  {
    __typename: "EventDay",
    id: string,
    eventId?: string | null,
    awsdate?: string | null,
    dayofweek?: string | null,
    date?: string | null,
    month?: string | null,
    year?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventDaySubscription = {
  onDeleteEventDay?:  {
    __typename: "EventDay",
    id: string,
    eventId?: string | null,
    awsdate?: string | null,
    dayofweek?: string | null,
    date?: string | null,
    month?: string | null,
    year?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateEventDivisionSubscription = {
  onCreateEventDivision?:  {
    __typename: "EventDivision",
    id: string,
    eventId: string,
    name: string,
    number?: number | null,
    divisionId: string,
    division?:  {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    } | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventClasses?:  {
      __typename: "ModelEventClassConnection",
      items:  Array< {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventResults?:  {
      __typename: "ModelEventResultConnection",
      items:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    jumpHeight?: Array< string | null > | null,
    minNumberEntriesPerClass?: number | null,
    maxNumberEntriesPerClass?: number | null,
    isCaliforniaSplit?: boolean | null,
    isCombined?: boolean | null,
    combinedEventDivisionId?: string | null,
    combinedEventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventDivisionOrganizationDivisionId?: string | null,
    eventDivisionPointTableId?: string | null,
    eventDivisionPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnUpdateEventDivisionSubscription = {
  onUpdateEventDivision?:  {
    __typename: "EventDivision",
    id: string,
    eventId: string,
    name: string,
    number?: number | null,
    divisionId: string,
    division?:  {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    } | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventClasses?:  {
      __typename: "ModelEventClassConnection",
      items:  Array< {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventResults?:  {
      __typename: "ModelEventResultConnection",
      items:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    jumpHeight?: Array< string | null > | null,
    minNumberEntriesPerClass?: number | null,
    maxNumberEntriesPerClass?: number | null,
    isCaliforniaSplit?: boolean | null,
    isCombined?: boolean | null,
    combinedEventDivisionId?: string | null,
    combinedEventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventDivisionOrganizationDivisionId?: string | null,
    eventDivisionPointTableId?: string | null,
    eventDivisionPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnDeleteEventDivisionSubscription = {
  onDeleteEventDivision?:  {
    __typename: "EventDivision",
    id: string,
    eventId: string,
    name: string,
    number?: number | null,
    divisionId: string,
    division?:  {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    } | null,
    organizationDivisionId?: string | null,
    organizationDivision?:  {
      __typename: "OrganizationDivision",
      id: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      },
      organizationId: string,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      entryFeePerClass?: string | null,
      prizeMoneyPerClass?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      displayOrder?: number | null,
      createdOn: string,
      updatedOn: string,
      organizationDivisionPointTableId?: string | null,
      organizationDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventClasses?:  {
      __typename: "ModelEventClassConnection",
      items:  Array< {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventResults?:  {
      __typename: "ModelEventResultConnection",
      items:  Array< {
        __typename: "EventResult",
        id: string,
        eventId: string,
        entryId: string,
        eventClassEntryId?: string | null,
        eventDivisionId?: string | null,
        eventDivisionName?: string | null,
        eventClassId?: string | null,
        eventClassName?: string | null,
        resultSet?: string | null,
        place?: number | null,
        score?: number | null,
        time?: string | null,
        didNotCompete?: boolean | null,
        prizeMoney?: number | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        eventResultEntryId: string,
        eventResultEventClassEntryId?: string | null,
        eventResultPrizeMoneyTableId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    jumpHeight?: Array< string | null > | null,
    minNumberEntriesPerClass?: number | null,
    maxNumberEntriesPerClass?: number | null,
    isCaliforniaSplit?: boolean | null,
    isCombined?: boolean | null,
    combinedEventDivisionId?: string | null,
    combinedEventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventDivisionOrganizationDivisionId?: string | null,
    eventDivisionPointTableId?: string | null,
    eventDivisionPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnCreateEventEntrySubscription = {
  onCreateEventEntry?:  {
    __typename: "EventEntry",
    id: string,
    eventId: string,
    status: string,
    statusNote?: string | null,
    personId?: string | null,
    number?: number | null,
    isCheckedIn?: boolean | null,
    isCheckedOut?: boolean | null,
    contactId: string,
    primarycontact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    riderName?: string | null,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    secondRiderName?: string | null,
    secondRiderId?: string | null,
    secondRider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    thirdRiderName?: string | null,
    thirdRiderId?: string | null,
    thirdRider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    horseName?: string | null,
    horseId?: string | null,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    ownerName?: string | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    secondOwnerName?: string | null,
    secondOwnerId?: string | null,
    secondOwner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    thirdOwnerName?: string | null,
    thirdOwnerId?: string | null,
    thirdOwner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    trainerName?: string | null,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    secondTrainerName?: string | null,
    secondTrainerId?: string | null,
    secondTrainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    thirdTrainerName?: string | null,
    thirdTrainerId?: string | null,
    thirdTrainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    stablingRequestId?: string | null,
    stablingRequest?:  {
      __typename: "StablingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      departureDateTime?: string | null,
      stallTypeId?: string | null,
      stallType?:  {
        __typename: "EventStallType",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallTypeId?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        pricePerStall?: string | null,
        taxPerStall?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?: number | null,
      taxB?: number | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      requestNote?: string | null,
      stallSetId?: string | null,
      stallSet?:  {
        __typename: "EventStallSet",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallSetId?: string | null,
        name?: string | null,
        location?: string | null,
        available?: string | null,
        totalAvailable?: number | null,
        used?: string | null,
        totalUsed?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      stallLocation?: string | null,
      stallNumbers?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    beddingRequestId?: string | null,
    beddingRequest?:  {
      __typename: "BeddingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      dateTimeNeeded?: string | null,
      deliveryInfo?: string | null,
      beddingType?: string | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      beddingRequestTaxAId?: string | null,
      beddingRequestTaxBId?: string | null,
    } | null,
    markedDoesNotNeedStalls?: boolean | null,
    payerName?: string | null,
    payerId?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    prizeMoneyRecipientName?: string | null,
    prizeMoneyRecipientId?: string | null,
    prizeMoneyRecipient?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    prizeMoneyRecipientContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientType?: string | null,
    eventRulesAgreements?:  {
      __typename: "ModelEventRulesAgreementConnection",
      items:  Array< {
        __typename: "EventRulesAgreement",
        id: string,
        eventId: string,
        eventRuleId: string,
        eventEntryId?: string | null,
        name?: string | null,
        personId?: string | null,
        personType?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    note?: string | null,
    divisionList?: Array< string | null > | null,
    classList?:  {
      __typename: "ModelEventClassEntryConnection",
      items:  Array< {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fees?:  Array< {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null > | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventEntryPrizeMoneyRecipientContactId?: string | null,
  } | null,
};

export type OnUpdateEventEntrySubscription = {
  onUpdateEventEntry?:  {
    __typename: "EventEntry",
    id: string,
    eventId: string,
    status: string,
    statusNote?: string | null,
    personId?: string | null,
    number?: number | null,
    isCheckedIn?: boolean | null,
    isCheckedOut?: boolean | null,
    contactId: string,
    primarycontact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    riderName?: string | null,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    secondRiderName?: string | null,
    secondRiderId?: string | null,
    secondRider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    thirdRiderName?: string | null,
    thirdRiderId?: string | null,
    thirdRider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    horseName?: string | null,
    horseId?: string | null,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    ownerName?: string | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    secondOwnerName?: string | null,
    secondOwnerId?: string | null,
    secondOwner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    thirdOwnerName?: string | null,
    thirdOwnerId?: string | null,
    thirdOwner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    trainerName?: string | null,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    secondTrainerName?: string | null,
    secondTrainerId?: string | null,
    secondTrainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    thirdTrainerName?: string | null,
    thirdTrainerId?: string | null,
    thirdTrainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    stablingRequestId?: string | null,
    stablingRequest?:  {
      __typename: "StablingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      departureDateTime?: string | null,
      stallTypeId?: string | null,
      stallType?:  {
        __typename: "EventStallType",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallTypeId?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        pricePerStall?: string | null,
        taxPerStall?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?: number | null,
      taxB?: number | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      requestNote?: string | null,
      stallSetId?: string | null,
      stallSet?:  {
        __typename: "EventStallSet",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallSetId?: string | null,
        name?: string | null,
        location?: string | null,
        available?: string | null,
        totalAvailable?: number | null,
        used?: string | null,
        totalUsed?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      stallLocation?: string | null,
      stallNumbers?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    beddingRequestId?: string | null,
    beddingRequest?:  {
      __typename: "BeddingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      dateTimeNeeded?: string | null,
      deliveryInfo?: string | null,
      beddingType?: string | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      beddingRequestTaxAId?: string | null,
      beddingRequestTaxBId?: string | null,
    } | null,
    markedDoesNotNeedStalls?: boolean | null,
    payerName?: string | null,
    payerId?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    prizeMoneyRecipientName?: string | null,
    prizeMoneyRecipientId?: string | null,
    prizeMoneyRecipient?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    prizeMoneyRecipientContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientType?: string | null,
    eventRulesAgreements?:  {
      __typename: "ModelEventRulesAgreementConnection",
      items:  Array< {
        __typename: "EventRulesAgreement",
        id: string,
        eventId: string,
        eventRuleId: string,
        eventEntryId?: string | null,
        name?: string | null,
        personId?: string | null,
        personType?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    note?: string | null,
    divisionList?: Array< string | null > | null,
    classList?:  {
      __typename: "ModelEventClassEntryConnection",
      items:  Array< {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fees?:  Array< {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null > | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventEntryPrizeMoneyRecipientContactId?: string | null,
  } | null,
};

export type OnDeleteEventEntrySubscription = {
  onDeleteEventEntry?:  {
    __typename: "EventEntry",
    id: string,
    eventId: string,
    status: string,
    statusNote?: string | null,
    personId?: string | null,
    number?: number | null,
    isCheckedIn?: boolean | null,
    isCheckedOut?: boolean | null,
    contactId: string,
    primarycontact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    riderName?: string | null,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    secondRiderName?: string | null,
    secondRiderId?: string | null,
    secondRider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    thirdRiderName?: string | null,
    thirdRiderId?: string | null,
    thirdRider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    horseName?: string | null,
    horseId?: string | null,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    ownerName?: string | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    secondOwnerName?: string | null,
    secondOwnerId?: string | null,
    secondOwner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    thirdOwnerName?: string | null,
    thirdOwnerId?: string | null,
    thirdOwner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    trainerName?: string | null,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    secondTrainerName?: string | null,
    secondTrainerId?: string | null,
    secondTrainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    thirdTrainerName?: string | null,
    thirdTrainerId?: string | null,
    thirdTrainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    stablingRequestId?: string | null,
    stablingRequest?:  {
      __typename: "StablingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      departureDateTime?: string | null,
      stallTypeId?: string | null,
      stallType?:  {
        __typename: "EventStallType",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallTypeId?: string | null,
        name?: string | null,
        description?: string | null,
        type?: string | null,
        pricePerStall?: string | null,
        taxPerStall?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?: number | null,
      taxB?: number | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      requestNote?: string | null,
      stallSetId?: string | null,
      stallSet?:  {
        __typename: "EventStallSet",
        id: string,
        eventId: string,
        organizationId?: string | null,
        organizationStallSetId?: string | null,
        name?: string | null,
        location?: string | null,
        available?: string | null,
        totalAvailable?: number | null,
        used?: string | null,
        totalUsed?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      stallLocation?: string | null,
      stallNumbers?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    beddingRequestId?: string | null,
    beddingRequest?:  {
      __typename: "BeddingRequest",
      id: string,
      eventId: string,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      arrivalDateTime?: string | null,
      dateTimeNeeded?: string | null,
      deliveryInfo?: string | null,
      beddingType?: string | null,
      quantityNeeded?: number | null,
      basePrice?: number | null,
      taxA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      totalPrice?: number | null,
      status?: string | null,
      statusNote?: string | null,
      payerId?: string | null,
      payerName?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  {
        __typename: "ModelEventEntryConnection",
        nextToken?: string | null,
      } | null,
      entryIds?: Array< string | null > | null,
      submittedEntry?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      beddingRequestTaxAId?: string | null,
      beddingRequestTaxBId?: string | null,
    } | null,
    markedDoesNotNeedStalls?: boolean | null,
    payerName?: string | null,
    payerId?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    prizeMoneyRecipientName?: string | null,
    prizeMoneyRecipientId?: string | null,
    prizeMoneyRecipient?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    prizeMoneyRecipientContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientType?: string | null,
    eventRulesAgreements?:  {
      __typename: "ModelEventRulesAgreementConnection",
      items:  Array< {
        __typename: "EventRulesAgreement",
        id: string,
        eventId: string,
        eventRuleId: string,
        eventEntryId?: string | null,
        name?: string | null,
        personId?: string | null,
        personType?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    note?: string | null,
    divisionList?: Array< string | null > | null,
    classList?:  {
      __typename: "ModelEventClassEntryConnection",
      items:  Array< {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    fees?:  Array< {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null > | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventEntryPrizeMoneyRecipientContactId?: string | null,
  } | null,
};

export type OnCreateEventEntryFeeSubscription = {
  onCreateEventEntryFee?:  {
    __typename: "EventEntryFee",
    id: string,
    name: string,
    description?: string | null,
    status?: string | null,
    amount: number,
    quantity?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    eventId: string,
    feeId: string,
    fee?:  {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null,
    eventFeeId?: string | null,
    eventFee?:  {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    entryId?: string | null,
    entry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventEntryFeeSubscription = {
  onUpdateEventEntryFee?:  {
    __typename: "EventEntryFee",
    id: string,
    name: string,
    description?: string | null,
    status?: string | null,
    amount: number,
    quantity?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    eventId: string,
    feeId: string,
    fee?:  {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null,
    eventFeeId?: string | null,
    eventFee?:  {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    entryId?: string | null,
    entry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventEntryFeeSubscription = {
  onDeleteEventEntryFee?:  {
    __typename: "EventEntryFee",
    id: string,
    name: string,
    description?: string | null,
    status?: string | null,
    amount: number,
    quantity?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    eventId: string,
    feeId: string,
    fee?:  {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null,
    eventFeeId?: string | null,
    eventFee?:  {
      __typename: "EventFee",
      id: string,
      name: string,
      amount: number,
      description?: string | null,
      eventId: string,
      createdBy: string,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      feeId: string,
      fee?:  {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null,
      splitAcrossEntries?: boolean | null,
      entries?:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    entryId?: string | null,
    entry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateEventEntryNumbersSubscription = {
  onCreateEventEntryNumbers?:  {
    __typename: "EventEntryNumbers",
    id: string,
    eventId: string,
    name?: string | null,
    available?: string | null,
    totalAvailable?: number | null,
    used?: string | null,
    totalUsed?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventEntryNumbersSubscription = {
  onUpdateEventEntryNumbers?:  {
    __typename: "EventEntryNumbers",
    id: string,
    eventId: string,
    name?: string | null,
    available?: string | null,
    totalAvailable?: number | null,
    used?: string | null,
    totalUsed?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventEntryNumbersSubscription = {
  onDeleteEventEntryNumbers?:  {
    __typename: "EventEntryNumbers",
    id: string,
    eventId: string,
    name?: string | null,
    available?: string | null,
    totalAvailable?: number | null,
    used?: string | null,
    totalUsed?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateEventFeeSubscription = {
  onCreateEventFee?:  {
    __typename: "EventFee",
    id: string,
    name: string,
    amount: number,
    description?: string | null,
    eventId: string,
    createdBy: string,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    feeId: string,
    fee?:  {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null > | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventFeeSubscription = {
  onUpdateEventFee?:  {
    __typename: "EventFee",
    id: string,
    name: string,
    amount: number,
    description?: string | null,
    eventId: string,
    createdBy: string,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    feeId: string,
    fee?:  {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null > | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventFeeSubscription = {
  onDeleteEventFee?:  {
    __typename: "EventFee",
    id: string,
    name: string,
    amount: number,
    description?: string | null,
    eventId: string,
    createdBy: string,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    feeId: string,
    fee?:  {
      __typename: "Fee",
      id: string,
      name: string,
      description?: string | null,
      createdBy: string,
      organizationId?: string | null,
      stripeId?: string | null,
      altId?: string | null,
      isFixed?: boolean | null,
      isPercentage?: boolean | null,
      totalPrice?: number | null,
      quantity?: number | null,
      isPerEntry?: boolean | null,
      isPerHorse?: boolean | null,
      isPerRider?: boolean | null,
      isPerClass?: boolean | null,
      isPerTrainer?: boolean | null,
      isRefundable?: boolean | null,
      isChargedAtTimeOfEntry?: boolean | null,
      isStartedOnEntryDate?: boolean | null,
      isHiddenFromEntryForm?: boolean | null,
      startDate?: string | null,
      startTime?: string | null,
      taxA?: number | null,
      taxB?: number | null,
      taxRateA?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      taxRateB?:  {
        __typename: "TaxRate",
        id: string,
        name?: string | null,
        createdBy?: string | null,
        organizationId?: string | null,
        stripeTaxRateId?: string | null,
        percentage?: number | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceFeesId?: string | null,
      feeTaxRateAId?: string | null,
      feeTaxRateBId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  Array< {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null > | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateEventFilterSubscription = {
  onCreateEventFilter?:  {
    __typename: "EventFilter",
    id: string,
    eventId?: string | null,
    organizationID?: string | null,
    name?: string | null,
    options?: Array< string | null > | null,
    isRequired?: boolean | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventFilterSubscription = {
  onUpdateEventFilter?:  {
    __typename: "EventFilter",
    id: string,
    eventId?: string | null,
    organizationID?: string | null,
    name?: string | null,
    options?: Array< string | null > | null,
    isRequired?: boolean | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventFilterSubscription = {
  onDeleteEventFilter?:  {
    __typename: "EventFilter",
    id: string,
    eventId?: string | null,
    organizationID?: string | null,
    name?: string | null,
    options?: Array< string | null > | null,
    isRequired?: boolean | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateEventInvoiceSubscription = {
  onCreateEventInvoice?:  {
    __typename: "EventInvoice",
    id: string,
    eventId: string,
    status: string,
    statusNote?: string | null,
    number: number,
    stripeInvoiceNumber?: string | null,
    entryNumber?: number | null,
    entryId?: string | null,
    entry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    payerName?: string | null,
    payerId?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    payerType?: string | null,
    payerStripeId?: string | null,
    payerContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientName?: string | null,
    prizeMoneyRecipientId?: string | null,
    prizeMoneyRecipientStripeId?: string | null,
    prizeMoneyRecipient?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    prizeMoneyRecipientContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientType?: string | null,
    divisions?:  {
      __typename: "ModelDivisionConnection",
      items:  Array< {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fees?:  {
      __typename: "ModelFeeConnection",
      items:  Array< {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryFeeTotalAmount?: number | null,
    otherFeeTotalAmount?: number | null,
    stablingAmount?: number | null,
    beddingAmount?: number | null,
    feedAmount?: number | null,
    results?:  Array< {
      __typename: "EventResult",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventClassEntryId?: string | null,
      eventClassEntry?:  {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      resultSet?: string | null,
      place?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      pointTables?:  {
        __typename: "ModelPointTableConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      eventResultEntryId: string,
      eventResultEventClassEntryId?: string | null,
      eventResultPrizeMoneyTableId?: string | null,
    } | null > | null,
    prizeMoneyAmount?: number | null,
    amountToDeductFromInvoice?: number | null,
    amountToPayRecipientViaCheck?: number | null,
    totalDue?: number | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoicePayerContactId?: string | null,
    eventInvoicePrizeMoneyRecipientId?: string | null,
    eventInvoicePrizeMoneyRecipientContactId?: string | null,
  } | null,
};

export type OnUpdateEventInvoiceSubscription = {
  onUpdateEventInvoice?:  {
    __typename: "EventInvoice",
    id: string,
    eventId: string,
    status: string,
    statusNote?: string | null,
    number: number,
    stripeInvoiceNumber?: string | null,
    entryNumber?: number | null,
    entryId?: string | null,
    entry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    payerName?: string | null,
    payerId?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    payerType?: string | null,
    payerStripeId?: string | null,
    payerContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientName?: string | null,
    prizeMoneyRecipientId?: string | null,
    prizeMoneyRecipientStripeId?: string | null,
    prizeMoneyRecipient?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    prizeMoneyRecipientContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientType?: string | null,
    divisions?:  {
      __typename: "ModelDivisionConnection",
      items:  Array< {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fees?:  {
      __typename: "ModelFeeConnection",
      items:  Array< {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryFeeTotalAmount?: number | null,
    otherFeeTotalAmount?: number | null,
    stablingAmount?: number | null,
    beddingAmount?: number | null,
    feedAmount?: number | null,
    results?:  Array< {
      __typename: "EventResult",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventClassEntryId?: string | null,
      eventClassEntry?:  {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      resultSet?: string | null,
      place?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      pointTables?:  {
        __typename: "ModelPointTableConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      eventResultEntryId: string,
      eventResultEventClassEntryId?: string | null,
      eventResultPrizeMoneyTableId?: string | null,
    } | null > | null,
    prizeMoneyAmount?: number | null,
    amountToDeductFromInvoice?: number | null,
    amountToPayRecipientViaCheck?: number | null,
    totalDue?: number | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoicePayerContactId?: string | null,
    eventInvoicePrizeMoneyRecipientId?: string | null,
    eventInvoicePrizeMoneyRecipientContactId?: string | null,
  } | null,
};

export type OnDeleteEventInvoiceSubscription = {
  onDeleteEventInvoice?:  {
    __typename: "EventInvoice",
    id: string,
    eventId: string,
    status: string,
    statusNote?: string | null,
    number: number,
    stripeInvoiceNumber?: string | null,
    entryNumber?: number | null,
    entryId?: string | null,
    entry?:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    } | null,
    payerName?: string | null,
    payerId?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    payerType?: string | null,
    payerStripeId?: string | null,
    payerContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientName?: string | null,
    prizeMoneyRecipientId?: string | null,
    prizeMoneyRecipientStripeId?: string | null,
    prizeMoneyRecipient?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    prizeMoneyRecipientContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    prizeMoneyRecipientType?: string | null,
    divisions?:  {
      __typename: "ModelDivisionConnection",
      items:  Array< {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      items:  Array< {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    fees?:  {
      __typename: "ModelFeeConnection",
      items:  Array< {
        __typename: "Fee",
        id: string,
        name: string,
        description?: string | null,
        createdBy: string,
        organizationId?: string | null,
        stripeId?: string | null,
        altId?: string | null,
        isFixed?: boolean | null,
        isPercentage?: boolean | null,
        totalPrice?: number | null,
        quantity?: number | null,
        isPerEntry?: boolean | null,
        isPerHorse?: boolean | null,
        isPerRider?: boolean | null,
        isPerClass?: boolean | null,
        isPerTrainer?: boolean | null,
        isRefundable?: boolean | null,
        isChargedAtTimeOfEntry?: boolean | null,
        isStartedOnEntryDate?: boolean | null,
        isHiddenFromEntryForm?: boolean | null,
        startDate?: string | null,
        startTime?: string | null,
        taxA?: number | null,
        taxB?: number | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceFeesId?: string | null,
        feeTaxRateAId?: string | null,
        feeTaxRateBId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryFeeTotalAmount?: number | null,
    otherFeeTotalAmount?: number | null,
    stablingAmount?: number | null,
    beddingAmount?: number | null,
    feedAmount?: number | null,
    results?:  Array< {
      __typename: "EventResult",
      id: string,
      eventId: string,
      entryId: string,
      entry:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      },
      eventClassEntryId?: string | null,
      eventClassEntry?:  {
        __typename: "EventClassEntry",
        id: string,
        eventId: string,
        eventClassId: string,
        eventEntryId: string,
        riderId?: string | null,
        status?: string | null,
        selectedEntryOption?: EntryOption | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventDivisionName?: string | null,
      eventClassId?: string | null,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventClassName?: string | null,
      resultSet?: string | null,
      place?: number | null,
      score?: number | null,
      time?: string | null,
      didNotCompete?: boolean | null,
      prizeMoney?: number | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      pointTables?:  {
        __typename: "ModelPointTableConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      eventResultEntryId: string,
      eventResultEventClassEntryId?: string | null,
      eventResultPrizeMoneyTableId?: string | null,
    } | null > | null,
    prizeMoneyAmount?: number | null,
    amountToDeductFromInvoice?: number | null,
    amountToPayRecipientViaCheck?: number | null,
    totalDue?: number | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventInvoicePayerContactId?: string | null,
    eventInvoicePrizeMoneyRecipientId?: string | null,
    eventInvoicePrizeMoneyRecipientContactId?: string | null,
  } | null,
};

export type OnCreateEventJudgeSubscription = {
  onCreateEventJudge?:  {
    __typename: "EventJudge",
    id: string,
    name: string,
    eventId: string,
    personId?: string | null,
    judgeId?: string | null,
    contactId?: string | null,
    rating?:  Array< {
      __typename: "JudgeRating",
      type?: JudgeType | null,
      license?: JudgeLicense | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEventJudgeSubscription = {
  onUpdateEventJudge?:  {
    __typename: "EventJudge",
    id: string,
    name: string,
    eventId: string,
    personId?: string | null,
    judgeId?: string | null,
    contactId?: string | null,
    rating?:  Array< {
      __typename: "JudgeRating",
      type?: JudgeType | null,
      license?: JudgeLicense | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEventJudgeSubscription = {
  onDeleteEventJudge?:  {
    __typename: "EventJudge",
    id: string,
    name: string,
    eventId: string,
    personId?: string | null,
    judgeId?: string | null,
    contactId?: string | null,
    rating?:  Array< {
      __typename: "JudgeRating",
      type?: JudgeType | null,
      license?: JudgeLicense | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEventOfficialSubscription = {
  onCreateEventOfficial?:  {
    __typename: "EventOfficial",
    id: string,
    name: string,
    position: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    personId?: string | null,
    contactId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEventOfficialSubscription = {
  onUpdateEventOfficial?:  {
    __typename: "EventOfficial",
    id: string,
    name: string,
    position: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    personId?: string | null,
    contactId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEventOfficialSubscription = {
  onDeleteEventOfficial?:  {
    __typename: "EventOfficial",
    id: string,
    name: string,
    position: string,
    eventId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    personId?: string | null,
    contactId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEventResultSubscription = {
  onCreateEventResult?:  {
    __typename: "EventResult",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventClassEntryId?: string | null,
    eventClassEntry?:  {
      __typename: "EventClassEntry",
      id: string,
      eventId: string,
      eventClassId: string,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventEntryId: string,
      eventEntry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      status?: string | null,
      selectedEntryOption?: EntryOption | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    resultSet?: string | null,
    place?: number | null,
    score?: number | null,
    time?: string | null,
    didNotCompete?: boolean | null,
    prizeMoney?: number | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    pointTables?:  {
      __typename: "ModelPointTableConnection",
      items:  Array< {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    note?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    eventResultEntryId: string,
    eventResultEventClassEntryId?: string | null,
    eventResultPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnUpdateEventResultSubscription = {
  onUpdateEventResult?:  {
    __typename: "EventResult",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventClassEntryId?: string | null,
    eventClassEntry?:  {
      __typename: "EventClassEntry",
      id: string,
      eventId: string,
      eventClassId: string,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventEntryId: string,
      eventEntry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      status?: string | null,
      selectedEntryOption?: EntryOption | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    resultSet?: string | null,
    place?: number | null,
    score?: number | null,
    time?: string | null,
    didNotCompete?: boolean | null,
    prizeMoney?: number | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    pointTables?:  {
      __typename: "ModelPointTableConnection",
      items:  Array< {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    note?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    eventResultEntryId: string,
    eventResultEventClassEntryId?: string | null,
    eventResultPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnDeleteEventResultSubscription = {
  onDeleteEventResult?:  {
    __typename: "EventResult",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventClassEntryId?: string | null,
    eventClassEntry?:  {
      __typename: "EventClassEntry",
      id: string,
      eventId: string,
      eventClassId: string,
      eventClass?:  {
        __typename: "EventClass",
        id: string,
        name: string,
        eventId: string,
        classId: string,
        eventDivisionId?: string | null,
        organizationClassId?: string | null,
        organizationDivisionId?: string | null,
        type?: string | null,
        number?: number | null,
        entryOption?: EntryOption | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        completedVerifyEntries?: boolean | null,
        currentNumberEntries?: number | null,
        numberEntriesThatCompeted?: number | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        estimatedTimePerRound?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventClassId?: string | null,
        combinedEventDivisionId?: string | null,
        jumpHeight?: Array< string | null > | null,
        isScheduled?: boolean | null,
        note?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventClassPointTableId?: string | null,
        eventClassPrizeMoneyTableId?: string | null,
      } | null,
      eventEntryId: string,
      eventEntry?:  {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      status?: string | null,
      selectedEntryOption?: EntryOption | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    resultSet?: string | null,
    place?: number | null,
    score?: number | null,
    time?: string | null,
    didNotCompete?: boolean | null,
    prizeMoney?: number | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    pointTables?:  {
      __typename: "ModelPointTableConnection",
      items:  Array< {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    note?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    eventResultEntryId: string,
    eventResultEventClassEntryId?: string | null,
    eventResultPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnCreateEventRingSubscription = {
  onCreateEventRing?:  {
    __typename: "EventRing",
    id: string,
    name: string,
    eventId: string,
    ringId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    ring?:  {
      __typename: "Ring",
      id: string,
      name: string,
      displayOrder?: number | null,
      organizationId?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventRingSubscription = {
  onUpdateEventRing?:  {
    __typename: "EventRing",
    id: string,
    name: string,
    eventId: string,
    ringId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    ring?:  {
      __typename: "Ring",
      id: string,
      name: string,
      displayOrder?: number | null,
      organizationId?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventRingSubscription = {
  onDeleteEventRing?:  {
    __typename: "EventRing",
    id: string,
    name: string,
    eventId: string,
    ringId: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      nickname?: string | null,
      createdBy: string,
      manager?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contactId?: string | null,
      contactIds?: Array< string | null > | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      disciplines?: string | null,
      eventOptions?:  {
        __typename: "EventOptions",
        autoAcceptNewEntries?: boolean | null,
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
        hasUnlimitedCredits?: boolean | null,
        usesEventManagement?: boolean | null,
        usesStablingManagement?: boolean | null,
        usesVendorManagement?: boolean | null,
        usesMediaTools?: boolean | null,
        usesMarketingTools?: boolean | null,
        usesFinanceTools?: boolean | null,
        usesJudgeApp?: boolean | null,
      } | null,
      paymentOptions?:  {
        __typename: "PaymentOptions",
        acceptsChecks?: boolean | null,
        acceptsCash?: boolean | null,
        acceptsACH?: boolean | null,
        acceptsCC?: boolean | null,
      } | null,
      invoiceOptions?:  {
        __typename: "InvoiceOptions",
        classFeeTax?: number | null,
        sendEmails?: boolean | null,
      } | null,
      entryOptions?:  {
        __typename: "EntryOptions",
        displayHorse2Input?: boolean | null,
        displayRider2Input?: boolean | null,
        displayRider3Input?: boolean | null,
        requiresCogginsDocument?: boolean | null,
        requiresCogginsData?: boolean | null,
        maximumNumberClassesOnEntry?: number | null,
      } | null,
      officials?:  {
        __typename: "ModelEventOfficialConnection",
        nextToken?: string | null,
      } | null,
      status?: string | null,
      entriesStatus?: string | null,
      showStatus?: string | null,
      usefID?: string | null,
      usefSoftwareKey?: string | null,
      ecID?: string | null,
      ecPassword?: string | null,
      documents?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAddressId?: string | null,
    } | null,
    ring?:  {
      __typename: "Ring",
      id: string,
      name: string,
      displayOrder?: number | null,
      organizationId?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateEventRulesSubscription = {
  onCreateEventRules?:  {
    __typename: "EventRules",
    id: string,
    eventId: string,
    title?: string | null,
    text?: string | null,
    requiresAgreementPerEntry?: boolean | null,
    agreements?:  {
      __typename: "ModelEventRulesAgreementConnection",
      items:  Array< {
        __typename: "EventRulesAgreement",
        id: string,
        eventId: string,
        eventRuleId: string,
        eventEntryId?: string | null,
        name?: string | null,
        personId?: string | null,
        personType?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventRulesSubscription = {
  onUpdateEventRules?:  {
    __typename: "EventRules",
    id: string,
    eventId: string,
    title?: string | null,
    text?: string | null,
    requiresAgreementPerEntry?: boolean | null,
    agreements?:  {
      __typename: "ModelEventRulesAgreementConnection",
      items:  Array< {
        __typename: "EventRulesAgreement",
        id: string,
        eventId: string,
        eventRuleId: string,
        eventEntryId?: string | null,
        name?: string | null,
        personId?: string | null,
        personType?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventRulesSubscription = {
  onDeleteEventRules?:  {
    __typename: "EventRules",
    id: string,
    eventId: string,
    title?: string | null,
    text?: string | null,
    requiresAgreementPerEntry?: boolean | null,
    agreements?:  {
      __typename: "ModelEventRulesAgreementConnection",
      items:  Array< {
        __typename: "EventRulesAgreement",
        id: string,
        eventId: string,
        eventRuleId: string,
        eventEntryId?: string | null,
        name?: string | null,
        personId?: string | null,
        personType?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateEventScratchSubscription = {
  onCreateEventScratch?:  {
    __typename: "EventScratch",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventScratchSubscription = {
  onUpdateEventScratch?:  {
    __typename: "EventScratch",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventScratchSubscription = {
  onDeleteEventScratch?:  {
    __typename: "EventScratch",
    id: string,
    eventId: string,
    entryId: string,
    entry:  {
      __typename: "EventEntry",
      id: string,
      eventId: string,
      status: string,
      statusNote?: string | null,
      personId?: string | null,
      number?: number | null,
      isCheckedIn?: boolean | null,
      isCheckedOut?: boolean | null,
      contactId: string,
      primarycontact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      riderName?: string | null,
      riderId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      secondRiderName?: string | null,
      secondRiderId?: string | null,
      secondRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      thirdRiderName?: string | null,
      thirdRiderId?: string | null,
      thirdRider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      horseName?: string | null,
      horseId?: string | null,
      horse?:  {
        __typename: "Horse",
        id: string,
        name: string,
        nickname?: string | null,
        previousNames?: Array< string | null > | null,
        personId?: string | null,
        ownerId?: string | null,
        ownerName: string,
        barnName?: string | null,
        barnId?: string | null,
        contactId?: string | null,
        microchipNumber?: string | null,
        passportNumber?: string | null,
        dateOfBirth?: string | null,
        breed?: string | null,
        sex?: string | null,
        color?: string | null,
        markings?: string | null,
        height?: string | null,
        foalState?: string | null,
        cogginsNumber?: string | null,
        cogginsDate?: string | null,
        cogginsState?: string | null,
        highestLevelCompeted?: string | null,
        greenEligibility?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      ownerName?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      secondOwnerName?: string | null,
      secondOwnerId?: string | null,
      secondOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      thirdOwnerName?: string | null,
      thirdOwnerId?: string | null,
      thirdOwner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      trainerName?: string | null,
      trainerId?: string | null,
      trainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      secondTrainerName?: string | null,
      secondTrainerId?: string | null,
      secondTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      thirdTrainerName?: string | null,
      thirdTrainerId?: string | null,
      thirdTrainer?:  {
        __typename: "Trainer",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        trainerContactId?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      stablingRequestId?: string | null,
      stablingRequest?:  {
        __typename: "StablingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        departureDateTime?: string | null,
        stallTypeId?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        taxA?: number | null,
        taxB?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        requestNote?: string | null,
        stallSetId?: string | null,
        stallLocation?: string | null,
        stallNumbers?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      beddingRequestId?: string | null,
      beddingRequest?:  {
        __typename: "BeddingRequest",
        id: string,
        eventId: string,
        trainerId?: string | null,
        barnId?: string | null,
        personId?: string | null,
        arrivalDateTime?: string | null,
        dateTimeNeeded?: string | null,
        deliveryInfo?: string | null,
        beddingType?: string | null,
        quantityNeeded?: number | null,
        basePrice?: number | null,
        totalPrice?: number | null,
        status?: string | null,
        statusNote?: string | null,
        payerId?: string | null,
        payerName?: string | null,
        splitAcrossEntries?: boolean | null,
        entryIds?: Array< string | null > | null,
        submittedEntry?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        beddingRequestTaxAId?: string | null,
        beddingRequestTaxBId?: string | null,
      } | null,
      markedDoesNotNeedStalls?: boolean | null,
      payerName?: string | null,
      payerId?: string | null,
      payer?:  {
        __typename: "Payer",
        id: string,
        name: string,
        personId?: string | null,
        createdOn: string,
        updatedOn: string,
        payerContactId?: string | null,
        payerPaymentMethodId?: string | null,
      } | null,
      prizeMoneyRecipientName?: string | null,
      prizeMoneyRecipientId?: string | null,
      prizeMoneyRecipient?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      prizeMoneyRecipientContact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      prizeMoneyRecipientType?: string | null,
      eventRulesAgreements?:  {
        __typename: "ModelEventRulesAgreementConnection",
        nextToken?: string | null,
      } | null,
      note?: string | null,
      divisionList?: Array< string | null > | null,
      classList?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      fees?:  Array< {
        __typename: "EventFee",
        id: string,
        name: string,
        amount: number,
        description?: string | null,
        eventId: string,
        createdBy: string,
        eventClassId?: string | null,
        feeId: string,
        splitAcrossEntries?: boolean | null,
        createdOn: string,
        updatedOn: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventEntryPrizeMoneyRecipientContactId?: string | null,
    },
    eventDivisionId?: string | null,
    eventDivision?:  {
      __typename: "EventDivision",
      id: string,
      eventId: string,
      name: string,
      number?: number | null,
      divisionId: string,
      division?:  {
        __typename: "Division",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        discipline?: string | null,
        isSearchable?: boolean | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceDivisionsId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventClasses?:  {
        __typename: "ModelEventClassConnection",
        nextToken?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      entryFee?: string | null,
      prizeMoney?: string | null,
      jumpHeight?: Array< string | null > | null,
      minNumberEntriesPerClass?: number | null,
      maxNumberEntriesPerClass?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventDivisionId?: string | null,
      combinedEventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventDivisionOrganizationDivisionId?: string | null,
      eventDivisionPointTableId?: string | null,
      eventDivisionPrizeMoneyTableId?: string | null,
    } | null,
    eventDivisionName?: string | null,
    eventClassId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    eventClassName?: string | null,
    status?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateEventStallSetSubscription = {
  onCreateEventStallSet?:  {
    __typename: "EventStallSet",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationStallSetId?: string | null,
    name?: string | null,
    location?: string | null,
    available?: string | null,
    totalAvailable?: number | null,
    used?: string | null,
    totalUsed?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventStallSetSubscription = {
  onUpdateEventStallSet?:  {
    __typename: "EventStallSet",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationStallSetId?: string | null,
    name?: string | null,
    location?: string | null,
    available?: string | null,
    totalAvailable?: number | null,
    used?: string | null,
    totalUsed?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventStallSetSubscription = {
  onDeleteEventStallSet?:  {
    __typename: "EventStallSet",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationStallSetId?: string | null,
    name?: string | null,
    location?: string | null,
    available?: string | null,
    totalAvailable?: number | null,
    used?: string | null,
    totalUsed?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateEventStallTypeSubscription = {
  onCreateEventStallType?:  {
    __typename: "EventStallType",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationStallTypeId?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerStall?: string | null,
    taxPerStall?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateEventStallTypeSubscription = {
  onUpdateEventStallType?:  {
    __typename: "EventStallType",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationStallTypeId?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerStall?: string | null,
    taxPerStall?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteEventStallTypeSubscription = {
  onDeleteEventStallType?:  {
    __typename: "EventStallType",
    id: string,
    eventId: string,
    organizationId?: string | null,
    organizationStallTypeId?: string | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerStall?: string | null,
    taxPerStall?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateFeeSubscription = {
  onCreateFee?:  {
    __typename: "Fee",
    id: string,
    name: string,
    description?: string | null,
    createdBy: string,
    organizationId?: string | null,
    stripeId?: string | null,
    altId?: string | null,
    isFixed?: boolean | null,
    isPercentage?: boolean | null,
    totalPrice?: number | null,
    quantity?: number | null,
    isPerEntry?: boolean | null,
    isPerHorse?: boolean | null,
    isPerRider?: boolean | null,
    isPerClass?: boolean | null,
    isPerTrainer?: boolean | null,
    isRefundable?: boolean | null,
    isChargedAtTimeOfEntry?: boolean | null,
    isStartedOnEntryDate?: boolean | null,
    isHiddenFromEntryForm?: boolean | null,
    startDate?: string | null,
    startTime?: string | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceFeesId?: string | null,
    feeTaxRateAId?: string | null,
    feeTaxRateBId?: string | null,
  } | null,
};

export type OnUpdateFeeSubscription = {
  onUpdateFee?:  {
    __typename: "Fee",
    id: string,
    name: string,
    description?: string | null,
    createdBy: string,
    organizationId?: string | null,
    stripeId?: string | null,
    altId?: string | null,
    isFixed?: boolean | null,
    isPercentage?: boolean | null,
    totalPrice?: number | null,
    quantity?: number | null,
    isPerEntry?: boolean | null,
    isPerHorse?: boolean | null,
    isPerRider?: boolean | null,
    isPerClass?: boolean | null,
    isPerTrainer?: boolean | null,
    isRefundable?: boolean | null,
    isChargedAtTimeOfEntry?: boolean | null,
    isStartedOnEntryDate?: boolean | null,
    isHiddenFromEntryForm?: boolean | null,
    startDate?: string | null,
    startTime?: string | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceFeesId?: string | null,
    feeTaxRateAId?: string | null,
    feeTaxRateBId?: string | null,
  } | null,
};

export type OnDeleteFeeSubscription = {
  onDeleteFee?:  {
    __typename: "Fee",
    id: string,
    name: string,
    description?: string | null,
    createdBy: string,
    organizationId?: string | null,
    stripeId?: string | null,
    altId?: string | null,
    isFixed?: boolean | null,
    isPercentage?: boolean | null,
    totalPrice?: number | null,
    quantity?: number | null,
    isPerEntry?: boolean | null,
    isPerHorse?: boolean | null,
    isPerRider?: boolean | null,
    isPerClass?: boolean | null,
    isPerTrainer?: boolean | null,
    isRefundable?: boolean | null,
    isChargedAtTimeOfEntry?: boolean | null,
    isStartedOnEntryDate?: boolean | null,
    isHiddenFromEntryForm?: boolean | null,
    startDate?: string | null,
    startTime?: string | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    eventInvoiceFeesId?: string | null,
    feeTaxRateAId?: string | null,
    feeTaxRateBId?: string | null,
  } | null,
};

export type OnCreateFeedbackSubscription = {
  onCreateFeedback?:  {
    __typename: "Feedback",
    id: string,
    clinicianId?: string | null,
    personId?: string | null,
    technical?: string | null,
    style?: string | null,
    turnout?: string | null,
    general?: string | null,
    score?: number | null,
    status?: FeedbackStatus | null,
    submissionFeedback?:  {
      __typename: "SubmissionFeedback",
      id: string,
      type?: string | null,
      submission?:  {
        __typename: "Submission",
        id: string,
        title: string,
        description?: string | null,
        videoLength?: string | null,
        riderId?: string | null,
        personId?: string | null,
        totalPrice: number,
        status?: SubmissionStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
      } | null,
      submissionId: string,
      feedback:  {
        __typename: "Feedback",
        id: string,
        clinicianId?: string | null,
        personId?: string | null,
        technical?: string | null,
        style?: string | null,
        turnout?: string | null,
        general?: string | null,
        score?: number | null,
        status?: FeedbackStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        feedbackSubmissionFeedbackId?: string | null,
      },
      feedbackId: string,
      personId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      clinician?:  {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null,
      clinicianId?: string | null,
      submissionStatus?: SubmissionStatus | null,
      feedbackStatus?: FeedbackStatus | null,
      clinicianAmount?: number | null,
      declineReason?: string | null,
      permissionToPost?: boolean | null,
      dateSubmitted?: string | null,
      dateCompleted?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      submissionFeedbackClinicianId?: string | null,
    } | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    feedbackSubmissionFeedbackId?: string | null,
  } | null,
};

export type OnUpdateFeedbackSubscription = {
  onUpdateFeedback?:  {
    __typename: "Feedback",
    id: string,
    clinicianId?: string | null,
    personId?: string | null,
    technical?: string | null,
    style?: string | null,
    turnout?: string | null,
    general?: string | null,
    score?: number | null,
    status?: FeedbackStatus | null,
    submissionFeedback?:  {
      __typename: "SubmissionFeedback",
      id: string,
      type?: string | null,
      submission?:  {
        __typename: "Submission",
        id: string,
        title: string,
        description?: string | null,
        videoLength?: string | null,
        riderId?: string | null,
        personId?: string | null,
        totalPrice: number,
        status?: SubmissionStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
      } | null,
      submissionId: string,
      feedback:  {
        __typename: "Feedback",
        id: string,
        clinicianId?: string | null,
        personId?: string | null,
        technical?: string | null,
        style?: string | null,
        turnout?: string | null,
        general?: string | null,
        score?: number | null,
        status?: FeedbackStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        feedbackSubmissionFeedbackId?: string | null,
      },
      feedbackId: string,
      personId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      clinician?:  {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null,
      clinicianId?: string | null,
      submissionStatus?: SubmissionStatus | null,
      feedbackStatus?: FeedbackStatus | null,
      clinicianAmount?: number | null,
      declineReason?: string | null,
      permissionToPost?: boolean | null,
      dateSubmitted?: string | null,
      dateCompleted?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      submissionFeedbackClinicianId?: string | null,
    } | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    feedbackSubmissionFeedbackId?: string | null,
  } | null,
};

export type OnDeleteFeedbackSubscription = {
  onDeleteFeedback?:  {
    __typename: "Feedback",
    id: string,
    clinicianId?: string | null,
    personId?: string | null,
    technical?: string | null,
    style?: string | null,
    turnout?: string | null,
    general?: string | null,
    score?: number | null,
    status?: FeedbackStatus | null,
    submissionFeedback?:  {
      __typename: "SubmissionFeedback",
      id: string,
      type?: string | null,
      submission?:  {
        __typename: "Submission",
        id: string,
        title: string,
        description?: string | null,
        videoLength?: string | null,
        riderId?: string | null,
        personId?: string | null,
        totalPrice: number,
        status?: SubmissionStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
      } | null,
      submissionId: string,
      feedback:  {
        __typename: "Feedback",
        id: string,
        clinicianId?: string | null,
        personId?: string | null,
        technical?: string | null,
        style?: string | null,
        turnout?: string | null,
        general?: string | null,
        score?: number | null,
        status?: FeedbackStatus | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        feedbackSubmissionFeedbackId?: string | null,
      },
      feedbackId: string,
      personId?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      clinician?:  {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null,
      clinicianId?: string | null,
      submissionStatus?: SubmissionStatus | null,
      feedbackStatus?: FeedbackStatus | null,
      clinicianAmount?: number | null,
      declineReason?: string | null,
      permissionToPost?: boolean | null,
      dateSubmitted?: string | null,
      dateCompleted?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      submissionFeedbackClinicianId?: string | null,
    } | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    feedbackSubmissionFeedbackId?: string | null,
  } | null,
};

export type OnCreateGameInputSubscription = {
  onCreateGameInput?:  {
    __typename: "GameInput",
    id: string,
    eventId?: string | null,
    eventClassId?: string | null,
    backNumber?: string | null,
    round?: string | null,
    score?: number | null,
    isMasterRecord: boolean,
    createdBy: string,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateGameInputSubscription = {
  onUpdateGameInput?:  {
    __typename: "GameInput",
    id: string,
    eventId?: string | null,
    eventClassId?: string | null,
    backNumber?: string | null,
    round?: string | null,
    score?: number | null,
    isMasterRecord: boolean,
    createdBy: string,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteGameInputSubscription = {
  onDeleteGameInput?:  {
    __typename: "GameInput",
    id: string,
    eventId?: string | null,
    eventClassId?: string | null,
    backNumber?: string | null,
    round?: string | null,
    score?: number | null,
    isMasterRecord: boolean,
    createdBy: string,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateHorseSubscription = {
  onCreateHorse?:  {
    __typename: "Horse",
    id: string,
    name: string,
    nickname?: string | null,
    previousNames?: Array< string | null > | null,
    personId?: string | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    ownerName: string,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    microchipNumber?: string | null,
    passportNumber?: string | null,
    dateOfBirth?: string | null,
    breed?: string | null,
    sex?: string | null,
    color?: string | null,
    markings?: string | null,
    height?: string | null,
    foalState?: string | null,
    cogginsNumber?: string | null,
    cogginsDate?: string | null,
    cogginsState?: string | null,
    coggins?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    highestLevelCompeted?: string | null,
    greenEligibility?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateHorseSubscription = {
  onUpdateHorse?:  {
    __typename: "Horse",
    id: string,
    name: string,
    nickname?: string | null,
    previousNames?: Array< string | null > | null,
    personId?: string | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    ownerName: string,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    microchipNumber?: string | null,
    passportNumber?: string | null,
    dateOfBirth?: string | null,
    breed?: string | null,
    sex?: string | null,
    color?: string | null,
    markings?: string | null,
    height?: string | null,
    foalState?: string | null,
    cogginsNumber?: string | null,
    cogginsDate?: string | null,
    cogginsState?: string | null,
    coggins?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    highestLevelCompeted?: string | null,
    greenEligibility?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteHorseSubscription = {
  onDeleteHorse?:  {
    __typename: "Horse",
    id: string,
    name: string,
    nickname?: string | null,
    previousNames?: Array< string | null > | null,
    personId?: string | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    ownerName: string,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    microchipNumber?: string | null,
    passportNumber?: string | null,
    dateOfBirth?: string | null,
    breed?: string | null,
    sex?: string | null,
    color?: string | null,
    markings?: string | null,
    height?: string | null,
    foalState?: string | null,
    cogginsNumber?: string | null,
    cogginsDate?: string | null,
    cogginsState?: string | null,
    coggins?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    highestLevelCompeted?: string | null,
    greenEligibility?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateHorseShowSubscription = {
  onCreateHorseShow?:  {
    __typename: "HorseShow",
    id: string,
    name: string,
    nickname?: string | null,
    eventId?: string | null,
    ratings?:  Array< {
      __typename: "HorseShowRating",
      id: string,
      eventId: string,
      governingBody?: string | null,
      rating?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    judges?:  Array< {
      __typename: "EventJudge",
      id: string,
      name: string,
      eventId: string,
      personId?: string | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateHorseShowSubscription = {
  onUpdateHorseShow?:  {
    __typename: "HorseShow",
    id: string,
    name: string,
    nickname?: string | null,
    eventId?: string | null,
    ratings?:  Array< {
      __typename: "HorseShowRating",
      id: string,
      eventId: string,
      governingBody?: string | null,
      rating?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    judges?:  Array< {
      __typename: "EventJudge",
      id: string,
      name: string,
      eventId: string,
      personId?: string | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteHorseShowSubscription = {
  onDeleteHorseShow?:  {
    __typename: "HorseShow",
    id: string,
    name: string,
    nickname?: string | null,
    eventId?: string | null,
    ratings?:  Array< {
      __typename: "HorseShowRating",
      id: string,
      eventId: string,
      governingBody?: string | null,
      rating?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    judges?:  Array< {
      __typename: "EventJudge",
      id: string,
      name: string,
      eventId: string,
      personId?: string | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateHorseShowRatingSubscription = {
  onCreateHorseShowRating?:  {
    __typename: "HorseShowRating",
    id: string,
    eventId: string,
    governingBody?: string | null,
    rating?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateHorseShowRatingSubscription = {
  onUpdateHorseShowRating?:  {
    __typename: "HorseShowRating",
    id: string,
    eventId: string,
    governingBody?: string | null,
    rating?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteHorseShowRatingSubscription = {
  onDeleteHorseShowRating?:  {
    __typename: "HorseShowRating",
    id: string,
    eventId: string,
    governingBody?: string | null,
    rating?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateInvitationSubscription = {
  onCreateInvitation?:  {
    __typename: "Invitation",
    id: string,
    createdBy: string,
    creatorEmail?: string | null,
    invitee?: string | null,
    inviteeEmail?: string | null,
    status?: InvitationStatus | null,
    organizationId?: string | null,
    barnId?: string | null,
    permissionLevel?: string | null,
    roles?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateInvitationSubscription = {
  onUpdateInvitation?:  {
    __typename: "Invitation",
    id: string,
    createdBy: string,
    creatorEmail?: string | null,
    invitee?: string | null,
    inviteeEmail?: string | null,
    status?: InvitationStatus | null,
    organizationId?: string | null,
    barnId?: string | null,
    permissionLevel?: string | null,
    roles?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteInvitationSubscription = {
  onDeleteInvitation?:  {
    __typename: "Invitation",
    id: string,
    createdBy: string,
    creatorEmail?: string | null,
    invitee?: string | null,
    inviteeEmail?: string | null,
    status?: InvitationStatus | null,
    organizationId?: string | null,
    barnId?: string | null,
    permissionLevel?: string | null,
    roles?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateMembershipSubscription = {
  onCreateMembership?:  {
    __typename: "Membership",
    id: string,
    name: string,
    description?: string | null,
    membershipId: string,
    backNumber?: number | null,
    membershipStatus?: string | null,
    amountPaid?: number | null,
    organizationMembershipTypeId?: string | null,
    organizationMembershipType?:  {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    } | null,
    type?: string | null,
    personName?: string | null,
    personId: string,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    horseId?: string | null,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    teamId?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationName?: string | null,
    isJunior?: boolean | null,
    isProfessional?: boolean | null,
    dateMembershipEnds?: string | null,
    volunteerHours?: number | null,
    meetingsAttended?: number | null,
    showsAttended?: number | null,
    personEmail?: string | null,
    gradeLevel?: string | null,
    aelDivision?: string | null,
    aelTeamRole?: TeamRole | null,
    documents?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    organizationAgreements?:  Array< {
      __typename: "OrganizationAgreements",
      organizationRuleId?: string | null,
      organizationRuleTitle: string,
      firstName: string,
      lastName: string,
      personId?: string | null,
      createdOn: string,
      updatedOn?: string | null,
    } | null > | null,
    contactPersonId?: string | null,
    groupContactId?: string | null,
    groupContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    groupLeaderContactId?: string | null,
    groupLeaderContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    personMembershipsId?: string | null,
    membershipOrganizationId?: string | null,
  } | null,
};

export type OnUpdateMembershipSubscription = {
  onUpdateMembership?:  {
    __typename: "Membership",
    id: string,
    name: string,
    description?: string | null,
    membershipId: string,
    backNumber?: number | null,
    membershipStatus?: string | null,
    amountPaid?: number | null,
    organizationMembershipTypeId?: string | null,
    organizationMembershipType?:  {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    } | null,
    type?: string | null,
    personName?: string | null,
    personId: string,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    horseId?: string | null,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    teamId?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationName?: string | null,
    isJunior?: boolean | null,
    isProfessional?: boolean | null,
    dateMembershipEnds?: string | null,
    volunteerHours?: number | null,
    meetingsAttended?: number | null,
    showsAttended?: number | null,
    personEmail?: string | null,
    gradeLevel?: string | null,
    aelDivision?: string | null,
    aelTeamRole?: TeamRole | null,
    documents?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    organizationAgreements?:  Array< {
      __typename: "OrganizationAgreements",
      organizationRuleId?: string | null,
      organizationRuleTitle: string,
      firstName: string,
      lastName: string,
      personId?: string | null,
      createdOn: string,
      updatedOn?: string | null,
    } | null > | null,
    contactPersonId?: string | null,
    groupContactId?: string | null,
    groupContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    groupLeaderContactId?: string | null,
    groupLeaderContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    personMembershipsId?: string | null,
    membershipOrganizationId?: string | null,
  } | null,
};

export type OnDeleteMembershipSubscription = {
  onDeleteMembership?:  {
    __typename: "Membership",
    id: string,
    name: string,
    description?: string | null,
    membershipId: string,
    backNumber?: number | null,
    membershipStatus?: string | null,
    amountPaid?: number | null,
    organizationMembershipTypeId?: string | null,
    organizationMembershipType?:  {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    } | null,
    type?: string | null,
    personName?: string | null,
    personId: string,
    riderId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    horseId?: string | null,
    horse?:  {
      __typename: "Horse",
      id: string,
      name: string,
      nickname?: string | null,
      previousNames?: Array< string | null > | null,
      personId?: string | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Owner",
        id: string,
        name: string,
        type?: string | null,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        ownerContactId?: string | null,
      } | null,
      ownerName: string,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      microchipNumber?: string | null,
      passportNumber?: string | null,
      dateOfBirth?: string | null,
      breed?: string | null,
      sex?: string | null,
      color?: string | null,
      markings?: string | null,
      height?: string | null,
      foalState?: string | null,
      cogginsNumber?: string | null,
      cogginsDate?: string | null,
      cogginsState?: string | null,
      coggins?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      highestLevelCompeted?: string | null,
      greenEligibility?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Owner",
      id: string,
      name: string,
      type?: string | null,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnId?: string | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      ownerContactId?: string | null,
    } | null,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    teamId?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationName?: string | null,
    isJunior?: boolean | null,
    isProfessional?: boolean | null,
    dateMembershipEnds?: string | null,
    volunteerHours?: number | null,
    meetingsAttended?: number | null,
    showsAttended?: number | null,
    personEmail?: string | null,
    gradeLevel?: string | null,
    aelDivision?: string | null,
    aelTeamRole?: TeamRole | null,
    documents?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    organizationAgreements?:  Array< {
      __typename: "OrganizationAgreements",
      organizationRuleId?: string | null,
      organizationRuleTitle: string,
      firstName: string,
      lastName: string,
      personId?: string | null,
      createdOn: string,
      updatedOn?: string | null,
    } | null > | null,
    contactPersonId?: string | null,
    groupContactId?: string | null,
    groupContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    groupLeaderContactId?: string | null,
    groupLeaderContact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    personMembershipsId?: string | null,
    membershipOrganizationId?: string | null,
  } | null,
};

export type OnCreateOrganizationMembershipTypeSubscription = {
  onCreateOrganizationMembershipType?:  {
    __typename: "OrganizationMembershipType",
    id: string,
    name: string,
    description?: string | null,
    order?: number | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    membershipIdValues?: string | null,
    nextAvailableMembershipId?: string | null,
    price?: number | null,
    category?: MembershipTypeCategory | null,
    schedule?: MembershipTypeSchedule | null,
    isStartAtTimeOfPurchase?: boolean | null,
    setStartDate?: string | null,
    acceptOnlineApplications?: string | null,
    applicationFields?:  {
      __typename: "ApplicationFields",
      firstName?: boolean | null,
      lastName?: boolean | null,
      dateOfBirth?: boolean | null,
      contact?: boolean | null,
      gradeLevel?: boolean | null,
      barnName?: boolean | null,
      teamName?: boolean | null,
      backNumber?: boolean | null,
      groupName?: boolean | null,
      groupLeader?: boolean | null,
      groupLeaderContactInfo?: boolean | null,
      collectMemberDetailsTogether?: boolean | null,
      maxNumberOfGroupMembers?: number | null,
      isTeam?: boolean | null,
      horseName?: boolean | null,
      horseHeight?: boolean | null,
      horseOwnerName?: boolean | null,
      cogginsInfo?: boolean | null,
      cogginsUpload?: boolean | null,
    } | null,
    applicationOptions?:  {
      __typename: "ApplicationOptions",
      requireApproval?: boolean | null,
      autoAssignIds?: boolean | null,
      acceptRSPPayment?: boolean | null,
      acceptOtherPayment?: boolean | null,
    } | null,
    organizationDocuments?:  Array< {
      __typename: "OrganizationDocument",
      id: string,
      name: string,
    } | null > | null,
    organizationRules?:  Array< {
      __typename: "OrganizationRule",
      id: string,
      title: string,
      body: string,
      isRequired?: boolean | null,
    } | null > | null,
    seasons?:  {
      __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
      items:  Array< {
        __typename: "OrganizationMembershipTypeSeasons",
        id: string,
        organizationMembershipTypeID: string,
        seasonID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdOn: string,
    updatedOn: string,
    organizationMembershipTypeOrganizationId?: string | null,
  } | null,
};

export type OnUpdateOrganizationMembershipTypeSubscription = {
  onUpdateOrganizationMembershipType?:  {
    __typename: "OrganizationMembershipType",
    id: string,
    name: string,
    description?: string | null,
    order?: number | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    membershipIdValues?: string | null,
    nextAvailableMembershipId?: string | null,
    price?: number | null,
    category?: MembershipTypeCategory | null,
    schedule?: MembershipTypeSchedule | null,
    isStartAtTimeOfPurchase?: boolean | null,
    setStartDate?: string | null,
    acceptOnlineApplications?: string | null,
    applicationFields?:  {
      __typename: "ApplicationFields",
      firstName?: boolean | null,
      lastName?: boolean | null,
      dateOfBirth?: boolean | null,
      contact?: boolean | null,
      gradeLevel?: boolean | null,
      barnName?: boolean | null,
      teamName?: boolean | null,
      backNumber?: boolean | null,
      groupName?: boolean | null,
      groupLeader?: boolean | null,
      groupLeaderContactInfo?: boolean | null,
      collectMemberDetailsTogether?: boolean | null,
      maxNumberOfGroupMembers?: number | null,
      isTeam?: boolean | null,
      horseName?: boolean | null,
      horseHeight?: boolean | null,
      horseOwnerName?: boolean | null,
      cogginsInfo?: boolean | null,
      cogginsUpload?: boolean | null,
    } | null,
    applicationOptions?:  {
      __typename: "ApplicationOptions",
      requireApproval?: boolean | null,
      autoAssignIds?: boolean | null,
      acceptRSPPayment?: boolean | null,
      acceptOtherPayment?: boolean | null,
    } | null,
    organizationDocuments?:  Array< {
      __typename: "OrganizationDocument",
      id: string,
      name: string,
    } | null > | null,
    organizationRules?:  Array< {
      __typename: "OrganizationRule",
      id: string,
      title: string,
      body: string,
      isRequired?: boolean | null,
    } | null > | null,
    seasons?:  {
      __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
      items:  Array< {
        __typename: "OrganizationMembershipTypeSeasons",
        id: string,
        organizationMembershipTypeID: string,
        seasonID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdOn: string,
    updatedOn: string,
    organizationMembershipTypeOrganizationId?: string | null,
  } | null,
};

export type OnDeleteOrganizationMembershipTypeSubscription = {
  onDeleteOrganizationMembershipType?:  {
    __typename: "OrganizationMembershipType",
    id: string,
    name: string,
    description?: string | null,
    order?: number | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    membershipIdValues?: string | null,
    nextAvailableMembershipId?: string | null,
    price?: number | null,
    category?: MembershipTypeCategory | null,
    schedule?: MembershipTypeSchedule | null,
    isStartAtTimeOfPurchase?: boolean | null,
    setStartDate?: string | null,
    acceptOnlineApplications?: string | null,
    applicationFields?:  {
      __typename: "ApplicationFields",
      firstName?: boolean | null,
      lastName?: boolean | null,
      dateOfBirth?: boolean | null,
      contact?: boolean | null,
      gradeLevel?: boolean | null,
      barnName?: boolean | null,
      teamName?: boolean | null,
      backNumber?: boolean | null,
      groupName?: boolean | null,
      groupLeader?: boolean | null,
      groupLeaderContactInfo?: boolean | null,
      collectMemberDetailsTogether?: boolean | null,
      maxNumberOfGroupMembers?: number | null,
      isTeam?: boolean | null,
      horseName?: boolean | null,
      horseHeight?: boolean | null,
      horseOwnerName?: boolean | null,
      cogginsInfo?: boolean | null,
      cogginsUpload?: boolean | null,
    } | null,
    applicationOptions?:  {
      __typename: "ApplicationOptions",
      requireApproval?: boolean | null,
      autoAssignIds?: boolean | null,
      acceptRSPPayment?: boolean | null,
      acceptOtherPayment?: boolean | null,
    } | null,
    organizationDocuments?:  Array< {
      __typename: "OrganizationDocument",
      id: string,
      name: string,
    } | null > | null,
    organizationRules?:  Array< {
      __typename: "OrganizationRule",
      id: string,
      title: string,
      body: string,
      isRequired?: boolean | null,
    } | null > | null,
    seasons?:  {
      __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
      items:  Array< {
        __typename: "OrganizationMembershipTypeSeasons",
        id: string,
        organizationMembershipTypeID: string,
        seasonID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdOn: string,
    updatedOn: string,
    organizationMembershipTypeOrganizationId?: string | null,
  } | null,
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    type?: OrganizationType | null,
    industry?: string | null,
    addresses?: Array< string | null > | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeId?: string | null,
    stripeCustomerId?: string | null,
    paymentMethodId?: string | null,
    website?: string | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    organizationOptions?:  {
      __typename: "OrganizationOptions",
      usesTextAlerts?: boolean | null,
      acceptsIncomingTextAlerts?: boolean | null,
    } | null,
    urlBackHalf?: string | null,
    colors?: Array< string | null > | null,
    logos?:  Array< {
      __typename: "Logo",
      logoURL?: string | null,
      key?: string | null,
      logo?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
    } | null > | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    type?: OrganizationType | null,
    industry?: string | null,
    addresses?: Array< string | null > | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeId?: string | null,
    stripeCustomerId?: string | null,
    paymentMethodId?: string | null,
    website?: string | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    organizationOptions?:  {
      __typename: "OrganizationOptions",
      usesTextAlerts?: boolean | null,
      acceptsIncomingTextAlerts?: boolean | null,
    } | null,
    urlBackHalf?: string | null,
    colors?: Array< string | null > | null,
    logos?:  Array< {
      __typename: "Logo",
      logoURL?: string | null,
      key?: string | null,
      logo?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
    } | null > | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteOrganizationSubscription = {
  onDeleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    type?: OrganizationType | null,
    industry?: string | null,
    addresses?: Array< string | null > | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeId?: string | null,
    stripeCustomerId?: string | null,
    paymentMethodId?: string | null,
    website?: string | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    organizationOptions?:  {
      __typename: "OrganizationOptions",
      usesTextAlerts?: boolean | null,
      acceptsIncomingTextAlerts?: boolean | null,
    } | null,
    urlBackHalf?: string | null,
    colors?: Array< string | null > | null,
    logos?:  Array< {
      __typename: "Logo",
      logoURL?: string | null,
      key?: string | null,
      logo?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
    } | null > | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateOrganizationBeddingTypeSubscription = {
  onCreateOrganizationBeddingType?:  {
    __typename: "OrganizationBeddingType",
    id: string,
    organizationId: string,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerBag?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateIdA?: string | null,
    taxRateIdB?: string | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateOrganizationBeddingTypeSubscription = {
  onUpdateOrganizationBeddingType?:  {
    __typename: "OrganizationBeddingType",
    id: string,
    organizationId: string,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerBag?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateIdA?: string | null,
    taxRateIdB?: string | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteOrganizationBeddingTypeSubscription = {
  onDeleteOrganizationBeddingType?:  {
    __typename: "OrganizationBeddingType",
    id: string,
    organizationId: string,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerBag?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateIdA?: string | null,
    taxRateIdB?: string | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateOrganizationClassSubscription = {
  onCreateOrganizationClass?:  {
    __typename: "OrganizationClass",
    id: string,
    name: string,
    classId: string,
    class:  {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    },
    organizationId: string,
    organizationDivisionId?: string | null,
    hasChampionship?: boolean | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    number?: number | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    jumpHeight?: Array< string | null > | null,
    estimatedTimePerRound?: number | null,
    createdBy: string,
    displayOrder?: number | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    organizationClassPointTableId?: string | null,
    organizationClassPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnUpdateOrganizationClassSubscription = {
  onUpdateOrganizationClass?:  {
    __typename: "OrganizationClass",
    id: string,
    name: string,
    classId: string,
    class:  {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    },
    organizationId: string,
    organizationDivisionId?: string | null,
    hasChampionship?: boolean | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    number?: number | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    jumpHeight?: Array< string | null > | null,
    estimatedTimePerRound?: number | null,
    createdBy: string,
    displayOrder?: number | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    organizationClassPointTableId?: string | null,
    organizationClassPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnDeleteOrganizationClassSubscription = {
  onDeleteOrganizationClass?:  {
    __typename: "OrganizationClass",
    id: string,
    name: string,
    classId: string,
    class:  {
      __typename: "Class",
      id: string,
      name: string,
      nickname?: string | null,
      divisionId?: string | null,
      type?: string | null,
      description?: string | null,
      discipline?: string | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceClassesId?: string | null,
    },
    organizationId: string,
    organizationDivisionId?: string | null,
    hasChampionship?: boolean | null,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    type?: string | null,
    number?: number | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    jumpHeight?: Array< string | null > | null,
    estimatedTimePerRound?: number | null,
    createdBy: string,
    displayOrder?: number | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    organizationClassPointTableId?: string | null,
    organizationClassPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnCreateOrganizationDivisionSubscription = {
  onCreateOrganizationDivision?:  {
    __typename: "OrganizationDivision",
    id: string,
    name: string,
    number?: number | null,
    divisionId: string,
    division:  {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    },
    organizationId: string,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    jumpHeight?: Array< string | null > | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    entryFeePerClass?: string | null,
    prizeMoneyPerClass?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    displayOrder?: number | null,
    createdOn: string,
    updatedOn: string,
    organizationDivisionPointTableId?: string | null,
    organizationDivisionPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnUpdateOrganizationDivisionSubscription = {
  onUpdateOrganizationDivision?:  {
    __typename: "OrganizationDivision",
    id: string,
    name: string,
    number?: number | null,
    divisionId: string,
    division:  {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    },
    organizationId: string,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    jumpHeight?: Array< string | null > | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    entryFeePerClass?: string | null,
    prizeMoneyPerClass?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    displayOrder?: number | null,
    createdOn: string,
    updatedOn: string,
    organizationDivisionPointTableId?: string | null,
    organizationDivisionPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnDeleteOrganizationDivisionSubscription = {
  onDeleteOrganizationDivision?:  {
    __typename: "OrganizationDivision",
    id: string,
    name: string,
    number?: number | null,
    divisionId: string,
    division:  {
      __typename: "Division",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      discipline?: string | null,
      isSearchable?: boolean | null,
      ushjaName?: string | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      ecClassTypeNumber?: string | null,
      ecClassType?: string | null,
      code?: string | null,
      specs?: string | null,
      judgedOn?: string | null,
      rules?:  {
        __typename: "DivisionRules",
        horseSex?: string | null,
        horseBreed?: string | null,
        horseMinHeight?: string | null,
        horseMaxHeight?: string | null,
        horseMinAge?: string | null,
        horseMaxAge?: string | null,
        riderMinAge?: string | null,
        riderMaxAge?: string | null,
        riderProfessionalStatus?: string | null,
        riderRecord?: string | null,
        horseRecord?: string | null,
        crossEntry?: string | null,
        other?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventInvoiceDivisionsId?: string | null,
    },
    organizationId: string,
    pointTable?:  {
      __typename: "PointTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isAddValueToRegularFromEntryCount?: boolean | null,
      isAddValueToChampionshipFromEntryCount?: boolean | null,
      isUseChampionRule?: boolean | null,
      isUseReserveRule?: boolean | null,
      tiers?:  Array< {
        __typename: "PointTier",
        lowEntryCount?: number | null,
        highEntryCount?: number | null,
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null > | null,
      numberEntriesToReceiveSpecialClassPoints?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventResultPointTablesId?: string | null,
    } | null,
    prizeMoneyTable?:  {
      __typename: "PrizeMoneyTable",
      id: string,
      name: string,
      description?: string | null,
      displayOrder?: number | null,
      organizationId?: string | null,
      discipline?: string | null,
      isPercentage?: boolean | null,
      totalPot?: number | null,
      tiers?:  {
        __typename: "PrizeMoneyTiers",
        first?: string | null,
        second?: string | null,
        third?: string | null,
        fourth?: string | null,
        fifth?: string | null,
        sixth?: string | null,
        seventh?: string | null,
        eighth?: string | null,
        ninth?: string | null,
        tenth?: string | null,
        eleventh?: string | null,
        twelfth?: string | null,
        champion?: string | null,
        reserve?: string | null,
      } | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    jumpHeight?: Array< string | null > | null,
    minNumberEntries?: number | null,
    maxNumberEntries?: number | null,
    entryFee?: string | null,
    prizeMoney?: string | null,
    entryFeePerClass?: string | null,
    prizeMoneyPerClass?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    displayOrder?: number | null,
    createdOn: string,
    updatedOn: string,
    organizationDivisionPointTableId?: string | null,
    organizationDivisionPrizeMoneyTableId?: string | null,
  } | null,
};

export type OnCreateOrganizationStallSetSubscription = {
  onCreateOrganizationStallSet?:  {
    __typename: "OrganizationStallSet",
    id: string,
    organizationId: string,
    name?: string | null,
    location?: string | null,
    available?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateOrganizationStallSetSubscription = {
  onUpdateOrganizationStallSet?:  {
    __typename: "OrganizationStallSet",
    id: string,
    organizationId: string,
    name?: string | null,
    location?: string | null,
    available?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteOrganizationStallSetSubscription = {
  onDeleteOrganizationStallSet?:  {
    __typename: "OrganizationStallSet",
    id: string,
    organizationId: string,
    name?: string | null,
    location?: string | null,
    available?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateOrganizationStallTypeSubscription = {
  onCreateOrganizationStallType?:  {
    __typename: "OrganizationStallType",
    id: string,
    organizationId: string,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerStall?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateIdA?: string | null,
    taxRateIdB?: string | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateOrganizationStallTypeSubscription = {
  onUpdateOrganizationStallType?:  {
    __typename: "OrganizationStallType",
    id: string,
    organizationId: string,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerStall?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateIdA?: string | null,
    taxRateIdB?: string | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteOrganizationStallTypeSubscription = {
  onDeleteOrganizationStallType?:  {
    __typename: "OrganizationStallType",
    id: string,
    organizationId: string,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    pricePerStall?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    taxRateIdA?: string | null,
    taxRateIdB?: string | null,
    taxRateA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxRateB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateOwnerSubscription = {
  onCreateOwner?:  {
    __typename: "Owner",
    id: string,
    name: string,
    type?: string | null,
    location?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnId?: string | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    ownerContactId?: string | null,
  } | null,
};

export type OnUpdateOwnerSubscription = {
  onUpdateOwner?:  {
    __typename: "Owner",
    id: string,
    name: string,
    type?: string | null,
    location?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnId?: string | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    ownerContactId?: string | null,
  } | null,
};

export type OnDeleteOwnerSubscription = {
  onDeleteOwner?:  {
    __typename: "Owner",
    id: string,
    name: string,
    type?: string | null,
    location?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnId?: string | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    ownerContactId?: string | null,
  } | null,
};

export type OnCreatePayerSubscription = {
  onCreatePayer?:  {
    __typename: "Payer",
    id: string,
    name: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    paymentMethod?:  {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    payerContactId?: string | null,
    payerPaymentMethodId?: string | null,
  } | null,
};

export type OnUpdatePayerSubscription = {
  onUpdatePayer?:  {
    __typename: "Payer",
    id: string,
    name: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    paymentMethod?:  {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    payerContactId?: string | null,
    payerPaymentMethodId?: string | null,
  } | null,
};

export type OnDeletePayerSubscription = {
  onDeletePayer?:  {
    __typename: "Payer",
    id: string,
    name: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    paymentMethod?:  {
      __typename: "PaymentMethod",
      id: string,
      personId?: string | null,
      organizationId?: string | null,
      type?: PaymentMethodType | null,
      stripeId?: string | null,
      altId?: string | null,
      value?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    payerContactId?: string | null,
    payerPaymentMethodId?: string | null,
  } | null,
};

export type OnCreatePaymentMethodSubscription = {
  onCreatePaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    personId?: string | null,
    organizationId?: string | null,
    type?: PaymentMethodType | null,
    stripeId?: string | null,
    altId?: string | null,
    value?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdatePaymentMethodSubscription = {
  onUpdatePaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    personId?: string | null,
    organizationId?: string | null,
    type?: PaymentMethodType | null,
    stripeId?: string | null,
    altId?: string | null,
    value?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeletePaymentMethodSubscription = {
  onDeletePaymentMethod?:  {
    __typename: "PaymentMethod",
    id: string,
    personId?: string | null,
    organizationId?: string | null,
    type?: PaymentMethodType | null,
    stripeId?: string | null,
    altId?: string | null,
    value?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreatePersonSubscription = {
  onCreatePerson?:  {
    __typename: "Person",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    isVerified?: boolean | null,
    roles?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    personalInformationId?: string | null,
    personalInformation?:  {
      __typename: "PersonalInformation",
      id: string,
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      dateOfBirth?: string | null,
      gender?: Gender | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pictureURL?: string | null,
    picture?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    disciplines?: string | null,
    stripeId?: string | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdatePersonSubscription = {
  onUpdatePerson?:  {
    __typename: "Person",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    isVerified?: boolean | null,
    roles?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    personalInformationId?: string | null,
    personalInformation?:  {
      __typename: "PersonalInformation",
      id: string,
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      dateOfBirth?: string | null,
      gender?: Gender | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pictureURL?: string | null,
    picture?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    disciplines?: string | null,
    stripeId?: string | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeletePersonSubscription = {
  onDeletePerson?:  {
    __typename: "Person",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    isVerified?: boolean | null,
    roles?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    personalInformationId?: string | null,
    personalInformation?:  {
      __typename: "PersonalInformation",
      id: string,
      personId: string,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      dateOfBirth?: string | null,
      gender?: Gender | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pictureURL?: string | null,
    picture?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    disciplines?: string | null,
    stripeId?: string | null,
    media?:  Array< {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null > | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreatePersonNameSubscription = {
  onCreatePersonName?:  {
    __typename: "PersonName",
    id: string,
    personId: string,
    title?: Title | null,
    firstName?: string | null,
    middleName?: string | null,
    maidenName?: string | null,
    lastName?: string | null,
    suffix?: string | null,
    legalName?: string | null,
    displayName?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePersonNameSubscription = {
  onUpdatePersonName?:  {
    __typename: "PersonName",
    id: string,
    personId: string,
    title?: Title | null,
    firstName?: string | null,
    middleName?: string | null,
    maidenName?: string | null,
    lastName?: string | null,
    suffix?: string | null,
    legalName?: string | null,
    displayName?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePersonNameSubscription = {
  onDeletePersonName?:  {
    __typename: "PersonName",
    id: string,
    personId: string,
    title?: Title | null,
    firstName?: string | null,
    middleName?: string | null,
    maidenName?: string | null,
    lastName?: string | null,
    suffix?: string | null,
    legalName?: string | null,
    displayName?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePersonalInformationSubscription = {
  onCreatePersonalInformation?:  {
    __typename: "PersonalInformation",
    id: string,
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    dateOfBirth?: string | null,
    gender?: Gender | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePersonalInformationSubscription = {
  onUpdatePersonalInformation?:  {
    __typename: "PersonalInformation",
    id: string,
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    dateOfBirth?: string | null,
    gender?: Gender | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePersonalInformationSubscription = {
  onDeletePersonalInformation?:  {
    __typename: "PersonalInformation",
    id: string,
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    dateOfBirth?: string | null,
    gender?: Gender | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePointTableSubscription = {
  onCreatePointTable?:  {
    __typename: "PointTable",
    id: string,
    name: string,
    description?: string | null,
    displayOrder?: number | null,
    organizationId?: string | null,
    discipline?: string | null,
    isAddValueToRegularFromEntryCount?: boolean | null,
    isAddValueToChampionshipFromEntryCount?: boolean | null,
    isUseChampionRule?: boolean | null,
    isUseReserveRule?: boolean | null,
    tiers?:  Array< {
      __typename: "PointTier",
      lowEntryCount?: number | null,
      highEntryCount?: number | null,
      first?: string | null,
      second?: string | null,
      third?: string | null,
      fourth?: string | null,
      fifth?: string | null,
      sixth?: string | null,
      seventh?: string | null,
      eighth?: string | null,
      ninth?: string | null,
      tenth?: string | null,
      eleventh?: string | null,
      twelfth?: string | null,
      champion?: string | null,
      reserve?: string | null,
    } | null > | null,
    numberEntriesToReceiveSpecialClassPoints?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventResultPointTablesId?: string | null,
  } | null,
};

export type OnUpdatePointTableSubscription = {
  onUpdatePointTable?:  {
    __typename: "PointTable",
    id: string,
    name: string,
    description?: string | null,
    displayOrder?: number | null,
    organizationId?: string | null,
    discipline?: string | null,
    isAddValueToRegularFromEntryCount?: boolean | null,
    isAddValueToChampionshipFromEntryCount?: boolean | null,
    isUseChampionRule?: boolean | null,
    isUseReserveRule?: boolean | null,
    tiers?:  Array< {
      __typename: "PointTier",
      lowEntryCount?: number | null,
      highEntryCount?: number | null,
      first?: string | null,
      second?: string | null,
      third?: string | null,
      fourth?: string | null,
      fifth?: string | null,
      sixth?: string | null,
      seventh?: string | null,
      eighth?: string | null,
      ninth?: string | null,
      tenth?: string | null,
      eleventh?: string | null,
      twelfth?: string | null,
      champion?: string | null,
      reserve?: string | null,
    } | null > | null,
    numberEntriesToReceiveSpecialClassPoints?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventResultPointTablesId?: string | null,
  } | null,
};

export type OnDeletePointTableSubscription = {
  onDeletePointTable?:  {
    __typename: "PointTable",
    id: string,
    name: string,
    description?: string | null,
    displayOrder?: number | null,
    organizationId?: string | null,
    discipline?: string | null,
    isAddValueToRegularFromEntryCount?: boolean | null,
    isAddValueToChampionshipFromEntryCount?: boolean | null,
    isUseChampionRule?: boolean | null,
    isUseReserveRule?: boolean | null,
    tiers?:  Array< {
      __typename: "PointTier",
      lowEntryCount?: number | null,
      highEntryCount?: number | null,
      first?: string | null,
      second?: string | null,
      third?: string | null,
      fourth?: string | null,
      fifth?: string | null,
      sixth?: string | null,
      seventh?: string | null,
      eighth?: string | null,
      ninth?: string | null,
      tenth?: string | null,
      eleventh?: string | null,
      twelfth?: string | null,
      champion?: string | null,
      reserve?: string | null,
    } | null > | null,
    numberEntriesToReceiveSpecialClassPoints?: string | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    eventResultPointTablesId?: string | null,
  } | null,
};

export type OnCreatePollSubscription = {
  onCreatePoll?:  {
    __typename: "Poll",
    id: string,
    title: string,
    description?: string | null,
    responses?:  {
      __typename: "ModelResponseConnection",
      items:  Array< {
        __typename: "Response",
        id: string,
        pollId?: string | null,
        personId: string,
        value?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePollSubscription = {
  onUpdatePoll?:  {
    __typename: "Poll",
    id: string,
    title: string,
    description?: string | null,
    responses?:  {
      __typename: "ModelResponseConnection",
      items:  Array< {
        __typename: "Response",
        id: string,
        pollId?: string | null,
        personId: string,
        value?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePollSubscription = {
  onDeletePoll?:  {
    __typename: "Poll",
    id: string,
    title: string,
    description?: string | null,
    responses?:  {
      __typename: "ModelResponseConnection",
      items:  Array< {
        __typename: "Response",
        id: string,
        pollId?: string | null,
        personId: string,
        value?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePostSubscription = {
  onCreatePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    status: PostStatus,
    rating?: number | null,
    content?: string | null,
    organizationId?: string | null,
    createdBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePostSubscription = {
  onUpdatePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    status: PostStatus,
    rating?: number | null,
    content?: string | null,
    organizationId?: string | null,
    createdBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePostSubscription = {
  onDeletePost?:  {
    __typename: "Post",
    id: string,
    title: string,
    status: PostStatus,
    rating?: number | null,
    content?: string | null,
    organizationId?: string | null,
    createdBy?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePrizeMoneyTableSubscription = {
  onCreatePrizeMoneyTable?:  {
    __typename: "PrizeMoneyTable",
    id: string,
    name: string,
    description?: string | null,
    displayOrder?: number | null,
    organizationId?: string | null,
    discipline?: string | null,
    isPercentage?: boolean | null,
    totalPot?: number | null,
    tiers?:  {
      __typename: "PrizeMoneyTiers",
      first?: string | null,
      second?: string | null,
      third?: string | null,
      fourth?: string | null,
      fifth?: string | null,
      sixth?: string | null,
      seventh?: string | null,
      eighth?: string | null,
      ninth?: string | null,
      tenth?: string | null,
      eleventh?: string | null,
      twelfth?: string | null,
      champion?: string | null,
      reserve?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdatePrizeMoneyTableSubscription = {
  onUpdatePrizeMoneyTable?:  {
    __typename: "PrizeMoneyTable",
    id: string,
    name: string,
    description?: string | null,
    displayOrder?: number | null,
    organizationId?: string | null,
    discipline?: string | null,
    isPercentage?: boolean | null,
    totalPot?: number | null,
    tiers?:  {
      __typename: "PrizeMoneyTiers",
      first?: string | null,
      second?: string | null,
      third?: string | null,
      fourth?: string | null,
      fifth?: string | null,
      sixth?: string | null,
      seventh?: string | null,
      eighth?: string | null,
      ninth?: string | null,
      tenth?: string | null,
      eleventh?: string | null,
      twelfth?: string | null,
      champion?: string | null,
      reserve?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeletePrizeMoneyTableSubscription = {
  onDeletePrizeMoneyTable?:  {
    __typename: "PrizeMoneyTable",
    id: string,
    name: string,
    description?: string | null,
    displayOrder?: number | null,
    organizationId?: string | null,
    discipline?: string | null,
    isPercentage?: boolean | null,
    totalPot?: number | null,
    tiers?:  {
      __typename: "PrizeMoneyTiers",
      first?: string | null,
      second?: string | null,
      third?: string | null,
      fourth?: string | null,
      fifth?: string | null,
      sixth?: string | null,
      seventh?: string | null,
      eighth?: string | null,
      ninth?: string | null,
      tenth?: string | null,
      eleventh?: string | null,
      twelfth?: string | null,
      champion?: string | null,
      reserve?: string | null,
    } | null,
    createdBy: string,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreatePromoCodeSubscription = {
  onCreatePromoCode?:  {
    __typename: "PromoCode",
    id: string,
    name: string,
    stripeId?: string | null,
    isvalid?: boolean | null,
    isfundraiser?: boolean | null,
    ispercentdiscount?: boolean | null,
    isfixeddiscount?: boolean | null,
    percent?: number | null,
    fixed?: number | null,
    minamount?: number | null,
    startdate?: string | null,
    enddate?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdatePromoCodeSubscription = {
  onUpdatePromoCode?:  {
    __typename: "PromoCode",
    id: string,
    name: string,
    stripeId?: string | null,
    isvalid?: boolean | null,
    isfundraiser?: boolean | null,
    ispercentdiscount?: boolean | null,
    isfixeddiscount?: boolean | null,
    percent?: number | null,
    fixed?: number | null,
    minamount?: number | null,
    startdate?: string | null,
    enddate?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeletePromoCodeSubscription = {
  onDeletePromoCode?:  {
    __typename: "PromoCode",
    id: string,
    name: string,
    stripeId?: string | null,
    isvalid?: boolean | null,
    isfundraiser?: boolean | null,
    ispercentdiscount?: boolean | null,
    isfixeddiscount?: boolean | null,
    percent?: number | null,
    fixed?: number | null,
    minamount?: number | null,
    startdate?: string | null,
    enddate?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateResponseSubscription = {
  onCreateResponse?:  {
    __typename: "Response",
    id: string,
    pollId?: string | null,
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateResponseSubscription = {
  onUpdateResponse?:  {
    __typename: "Response",
    id: string,
    pollId?: string | null,
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteResponseSubscription = {
  onDeleteResponse?:  {
    __typename: "Response",
    id: string,
    pollId?: string | null,
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    value?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRiderSubscription = {
  onCreateRider?:  {
    __typename: "Rider",
    id: string,
    name: string,
    location?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    age?: number | null,
    birthdate?: string | null,
    isProfessional?: boolean | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    createdAt: string,
    updatedAt: string,
    riderContactId?: string | null,
  } | null,
};

export type OnUpdateRiderSubscription = {
  onUpdateRider?:  {
    __typename: "Rider",
    id: string,
    name: string,
    location?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    age?: number | null,
    birthdate?: string | null,
    isProfessional?: boolean | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    createdAt: string,
    updatedAt: string,
    riderContactId?: string | null,
  } | null,
};

export type OnDeleteRiderSubscription = {
  onDeleteRider?:  {
    __typename: "Rider",
    id: string,
    name: string,
    location?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    age?: number | null,
    birthdate?: string | null,
    isProfessional?: boolean | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    createdAt: string,
    updatedAt: string,
    riderContactId?: string | null,
  } | null,
};

export type OnCreateRingSubscription = {
  onCreateRing?:  {
    __typename: "Ring",
    id: string,
    name: string,
    displayOrder?: number | null,
    organizationId?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateRingSubscription = {
  onUpdateRing?:  {
    __typename: "Ring",
    id: string,
    name: string,
    displayOrder?: number | null,
    organizationId?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteRingSubscription = {
  onDeleteRing?:  {
    __typename: "Ring",
    id: string,
    name: string,
    displayOrder?: number | null,
    organizationId?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateScheduleItemSubscription = {
  onCreateScheduleItem?:  {
    __typename: "ScheduleItem",
    id: string,
    eventId: string,
    eventRingId: string,
    eventDayId: string,
    eventRing?:  {
      __typename: "EventRing",
      id: string,
      name: string,
      eventId: string,
      ringId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      ring?:  {
        __typename: "Ring",
        id: string,
        name: string,
        displayOrder?: number | null,
        organizationId?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventDay?:  {
      __typename: "EventDay",
      id: string,
      eventId?: string | null,
      awsdate?: string | null,
      dayofweek?: string | null,
      date?: string | null,
      month?: string | null,
      year?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    displayOrder?: number | null,
    classId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    breakId?: string | null,
    eventBreak?:  {
      __typename: "EventBreak",
      id: string,
      eventId: string,
      name?: string | null,
      time?: string | null,
      isDisplayedOnSchedule?: boolean | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    startTime?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateScheduleItemSubscription = {
  onUpdateScheduleItem?:  {
    __typename: "ScheduleItem",
    id: string,
    eventId: string,
    eventRingId: string,
    eventDayId: string,
    eventRing?:  {
      __typename: "EventRing",
      id: string,
      name: string,
      eventId: string,
      ringId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      ring?:  {
        __typename: "Ring",
        id: string,
        name: string,
        displayOrder?: number | null,
        organizationId?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventDay?:  {
      __typename: "EventDay",
      id: string,
      eventId?: string | null,
      awsdate?: string | null,
      dayofweek?: string | null,
      date?: string | null,
      month?: string | null,
      year?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    displayOrder?: number | null,
    classId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    breakId?: string | null,
    eventBreak?:  {
      __typename: "EventBreak",
      id: string,
      eventId: string,
      name?: string | null,
      time?: string | null,
      isDisplayedOnSchedule?: boolean | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    startTime?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteScheduleItemSubscription = {
  onDeleteScheduleItem?:  {
    __typename: "ScheduleItem",
    id: string,
    eventId: string,
    eventRingId: string,
    eventDayId: string,
    eventRing?:  {
      __typename: "EventRing",
      id: string,
      name: string,
      eventId: string,
      ringId: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      ring?:  {
        __typename: "Ring",
        id: string,
        name: string,
        displayOrder?: number | null,
        organizationId?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    eventDay?:  {
      __typename: "EventDay",
      id: string,
      eventId?: string | null,
      awsdate?: string | null,
      dayofweek?: string | null,
      date?: string | null,
      month?: string | null,
      year?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    displayOrder?: number | null,
    classId?: string | null,
    eventClass?:  {
      __typename: "EventClass",
      id: string,
      name: string,
      eventId: string,
      classId: string,
      class?:  {
        __typename: "Class",
        id: string,
        name: string,
        nickname?: string | null,
        divisionId?: string | null,
        type?: string | null,
        description?: string | null,
        discipline?: string | null,
        ushjaName?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        ecClassTypeNumber?: string | null,
        ecClassType?: string | null,
        code?: string | null,
        specs?: string | null,
        judgedOn?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventInvoiceClassesId?: string | null,
      } | null,
      eventDivisionId?: string | null,
      eventDivision?:  {
        __typename: "EventDivision",
        id: string,
        eventId: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationDivisionId?: string | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        jumpHeight?: Array< string | null > | null,
        minNumberEntriesPerClass?: number | null,
        maxNumberEntriesPerClass?: number | null,
        isCaliforniaSplit?: boolean | null,
        isCombined?: boolean | null,
        combinedEventDivisionId?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventDivisionOrganizationDivisionId?: string | null,
        eventDivisionPointTableId?: string | null,
        eventDivisionPrizeMoneyTableId?: string | null,
      } | null,
      organizationClassId?: string | null,
      organizationClass?:  {
        __typename: "OrganizationClass",
        id: string,
        name: string,
        classId: string,
        organizationId: string,
        organizationDivisionId?: string | null,
        hasChampionship?: boolean | null,
        type?: string | null,
        number?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        jumpHeight?: Array< string | null > | null,
        estimatedTimePerRound?: number | null,
        createdBy: string,
        displayOrder?: number | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        organizationClassPointTableId?: string | null,
        organizationClassPrizeMoneyTableId?: string | null,
      } | null,
      organizationDivisionId?: string | null,
      organizationDivision?:  {
        __typename: "OrganizationDivision",
        id: string,
        name: string,
        number?: number | null,
        divisionId: string,
        organizationId: string,
        jumpHeight?: Array< string | null > | null,
        minNumberEntries?: number | null,
        maxNumberEntries?: number | null,
        entryFee?: string | null,
        prizeMoney?: string | null,
        entryFeePerClass?: string | null,
        prizeMoneyPerClass?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        displayOrder?: number | null,
        createdOn: string,
        updatedOn: string,
        organizationDivisionPointTableId?: string | null,
        organizationDivisionPrizeMoneyTableId?: string | null,
      } | null,
      eventResults?:  {
        __typename: "ModelEventResultConnection",
        nextToken?: string | null,
      } | null,
      pointTable?:  {
        __typename: "PointTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isAddValueToRegularFromEntryCount?: boolean | null,
        isAddValueToChampionshipFromEntryCount?: boolean | null,
        isUseChampionRule?: boolean | null,
        isUseReserveRule?: boolean | null,
        numberEntriesToReceiveSpecialClassPoints?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventResultPointTablesId?: string | null,
      } | null,
      prizeMoneyTable?:  {
        __typename: "PrizeMoneyTable",
        id: string,
        name: string,
        description?: string | null,
        displayOrder?: number | null,
        organizationId?: string | null,
        discipline?: string | null,
        isPercentage?: boolean | null,
        totalPot?: number | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      type?: string | null,
      number?: number | null,
      entryOption?: EntryOption | null,
      entryFee?: string | null,
      fees?:  {
        __typename: "ModelEventFeeConnection",
        nextToken?: string | null,
      } | null,
      prizeMoney?: string | null,
      entries?:  {
        __typename: "ModelEventClassEntryConnection",
        nextToken?: string | null,
      } | null,
      completedVerifyEntries?: boolean | null,
      currentNumberEntries?: number | null,
      numberEntriesThatCompeted?: number | null,
      minNumberEntries?: number | null,
      maxNumberEntries?: number | null,
      estimatedTimePerRound?: number | null,
      isCaliforniaSplit?: boolean | null,
      isCombined?: boolean | null,
      combinedEventClassId?: string | null,
      combinedEventDivisionId?: string | null,
      jumpHeight?: Array< string | null > | null,
      isScheduled?: boolean | null,
      note?: string | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventClassPointTableId?: string | null,
      eventClassPrizeMoneyTableId?: string | null,
    } | null,
    breakId?: string | null,
    eventBreak?:  {
      __typename: "EventBreak",
      id: string,
      eventId: string,
      name?: string | null,
      time?: string | null,
      isDisplayedOnSchedule?: boolean | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    startTime?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateSeasonSubscription = {
  onCreateSeason?:  {
    __typename: "Season",
    id: string,
    name: string,
    description?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationMembershipTypes?:  {
      __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
      items:  Array< {
        __typename: "OrganizationMembershipTypeSeasons",
        id: string,
        organizationMembershipTypeID: string,
        seasonID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    startDate: string,
    endDate: string,
    disciplines?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    createdAt: string,
    updatedAt: string,
    seasonOrganizationId?: string | null,
  } | null,
};

export type OnUpdateSeasonSubscription = {
  onUpdateSeason?:  {
    __typename: "Season",
    id: string,
    name: string,
    description?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationMembershipTypes?:  {
      __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
      items:  Array< {
        __typename: "OrganizationMembershipTypeSeasons",
        id: string,
        organizationMembershipTypeID: string,
        seasonID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    startDate: string,
    endDate: string,
    disciplines?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    createdAt: string,
    updatedAt: string,
    seasonOrganizationId?: string | null,
  } | null,
};

export type OnDeleteSeasonSubscription = {
  onDeleteSeason?:  {
    __typename: "Season",
    id: string,
    name: string,
    description?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: OrganizationType | null,
      industry?: string | null,
      addresses?: Array< string | null > | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeId?: string | null,
      stripeCustomerId?: string | null,
      paymentMethodId?: string | null,
      website?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy: string,
      lastUpdatedBy?: string | null,
      organizationOptions?:  {
        __typename: "OrganizationOptions",
        usesTextAlerts?: boolean | null,
        acceptsIncomingTextAlerts?: boolean | null,
      } | null,
      urlBackHalf?: string | null,
      colors?: Array< string | null > | null,
      logos?:  Array< {
        __typename: "Logo",
        logoURL?: string | null,
        key?: string | null,
      } | null > | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    organizationMembershipTypes?:  {
      __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
      items:  Array< {
        __typename: "OrganizationMembershipTypeSeasons",
        id: string,
        organizationMembershipTypeID: string,
        seasonID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    startDate: string,
    endDate: string,
    disciplines?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    createdAt: string,
    updatedAt: string,
    seasonOrganizationId?: string | null,
  } | null,
};

export type OnCreateStablingRequestSubscription = {
  onCreateStablingRequest?:  {
    __typename: "StablingRequest",
    id: string,
    eventId: string,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    arrivalDateTime?: string | null,
    departureDateTime?: string | null,
    stallTypeId?: string | null,
    stallType?:  {
      __typename: "EventStallType",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallTypeId?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      pricePerStall?: string | null,
      taxPerStall?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    quantityNeeded?: number | null,
    basePrice?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    totalPrice?: number | null,
    status?: string | null,
    statusNote?: string | null,
    requestNote?: string | null,
    stallSetId?: string | null,
    stallSet?:  {
      __typename: "EventStallSet",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallSetId?: string | null,
      name?: string | null,
      location?: string | null,
      available?: string | null,
      totalAvailable?: number | null,
      used?: string | null,
      totalUsed?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    stallLocation?: string | null,
    stallNumbers?: string | null,
    payerId?: string | null,
    payerName?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  {
      __typename: "ModelEventEntryConnection",
      items:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryIds?: Array< string | null > | null,
    submittedEntry?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateStablingRequestSubscription = {
  onUpdateStablingRequest?:  {
    __typename: "StablingRequest",
    id: string,
    eventId: string,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    arrivalDateTime?: string | null,
    departureDateTime?: string | null,
    stallTypeId?: string | null,
    stallType?:  {
      __typename: "EventStallType",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallTypeId?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      pricePerStall?: string | null,
      taxPerStall?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    quantityNeeded?: number | null,
    basePrice?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    totalPrice?: number | null,
    status?: string | null,
    statusNote?: string | null,
    requestNote?: string | null,
    stallSetId?: string | null,
    stallSet?:  {
      __typename: "EventStallSet",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallSetId?: string | null,
      name?: string | null,
      location?: string | null,
      available?: string | null,
      totalAvailable?: number | null,
      used?: string | null,
      totalUsed?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    stallLocation?: string | null,
    stallNumbers?: string | null,
    payerId?: string | null,
    payerName?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  {
      __typename: "ModelEventEntryConnection",
      items:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryIds?: Array< string | null > | null,
    submittedEntry?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteStablingRequestSubscription = {
  onDeleteStablingRequest?:  {
    __typename: "StablingRequest",
    id: string,
    eventId: string,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    arrivalDateTime?: string | null,
    departureDateTime?: string | null,
    stallTypeId?: string | null,
    stallType?:  {
      __typename: "EventStallType",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallTypeId?: string | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      pricePerStall?: string | null,
      taxPerStall?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    quantityNeeded?: number | null,
    basePrice?: number | null,
    taxA?: number | null,
    taxB?: number | null,
    totalPrice?: number | null,
    status?: string | null,
    statusNote?: string | null,
    requestNote?: string | null,
    stallSetId?: string | null,
    stallSet?:  {
      __typename: "EventStallSet",
      id: string,
      eventId: string,
      organizationId?: string | null,
      organizationStallSetId?: string | null,
      name?: string | null,
      location?: string | null,
      available?: string | null,
      totalAvailable?: number | null,
      used?: string | null,
      totalUsed?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    stallLocation?: string | null,
    stallNumbers?: string | null,
    payerId?: string | null,
    payerName?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  {
      __typename: "ModelEventEntryConnection",
      items:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryIds?: Array< string | null > | null,
    submittedEntry?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateBeddingRequestSubscription = {
  onCreateBeddingRequest?:  {
    __typename: "BeddingRequest",
    id: string,
    eventId: string,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    arrivalDateTime?: string | null,
    dateTimeNeeded?: string | null,
    deliveryInfo?: string | null,
    beddingType?: string | null,
    quantityNeeded?: number | null,
    basePrice?: number | null,
    taxA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    totalPrice?: number | null,
    status?: string | null,
    statusNote?: string | null,
    payerId?: string | null,
    payerName?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  {
      __typename: "ModelEventEntryConnection",
      items:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryIds?: Array< string | null > | null,
    submittedEntry?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    beddingRequestTaxAId?: string | null,
    beddingRequestTaxBId?: string | null,
  } | null,
};

export type OnUpdateBeddingRequestSubscription = {
  onUpdateBeddingRequest?:  {
    __typename: "BeddingRequest",
    id: string,
    eventId: string,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    arrivalDateTime?: string | null,
    dateTimeNeeded?: string | null,
    deliveryInfo?: string | null,
    beddingType?: string | null,
    quantityNeeded?: number | null,
    basePrice?: number | null,
    taxA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    totalPrice?: number | null,
    status?: string | null,
    statusNote?: string | null,
    payerId?: string | null,
    payerName?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  {
      __typename: "ModelEventEntryConnection",
      items:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryIds?: Array< string | null > | null,
    submittedEntry?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    beddingRequestTaxAId?: string | null,
    beddingRequestTaxBId?: string | null,
  } | null,
};

export type OnDeleteBeddingRequestSubscription = {
  onDeleteBeddingRequest?:  {
    __typename: "BeddingRequest",
    id: string,
    eventId: string,
    trainerId?: string | null,
    trainer?:  {
      __typename: "Trainer",
      id: string,
      name: string,
      location?: string | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      trainerContactId?: string | null,
    } | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    personId?: string | null,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    arrivalDateTime?: string | null,
    dateTimeNeeded?: string | null,
    deliveryInfo?: string | null,
    beddingType?: string | null,
    quantityNeeded?: number | null,
    basePrice?: number | null,
    taxA?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    taxB?:  {
      __typename: "TaxRate",
      id: string,
      name?: string | null,
      createdBy?: string | null,
      organizationId?: string | null,
      stripeTaxRateId?: string | null,
      percentage?: number | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    totalPrice?: number | null,
    status?: string | null,
    statusNote?: string | null,
    payerId?: string | null,
    payerName?: string | null,
    payer?:  {
      __typename: "Payer",
      id: string,
      name: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      paymentMethod?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      payerContactId?: string | null,
      payerPaymentMethodId?: string | null,
    } | null,
    splitAcrossEntries?: boolean | null,
    entries?:  {
      __typename: "ModelEventEntryConnection",
      items:  Array< {
        __typename: "EventEntry",
        id: string,
        eventId: string,
        status: string,
        statusNote?: string | null,
        personId?: string | null,
        number?: number | null,
        isCheckedIn?: boolean | null,
        isCheckedOut?: boolean | null,
        contactId: string,
        riderName?: string | null,
        riderId?: string | null,
        secondRiderName?: string | null,
        secondRiderId?: string | null,
        thirdRiderName?: string | null,
        thirdRiderId?: string | null,
        horseName?: string | null,
        horseId?: string | null,
        ownerName?: string | null,
        ownerId?: string | null,
        secondOwnerName?: string | null,
        secondOwnerId?: string | null,
        thirdOwnerName?: string | null,
        thirdOwnerId?: string | null,
        trainerName?: string | null,
        trainerId?: string | null,
        secondTrainerName?: string | null,
        secondTrainerId?: string | null,
        thirdTrainerName?: string | null,
        thirdTrainerId?: string | null,
        barnName?: string | null,
        barnId?: string | null,
        stablingRequestId?: string | null,
        beddingRequestId?: string | null,
        markedDoesNotNeedStalls?: boolean | null,
        payerName?: string | null,
        payerId?: string | null,
        prizeMoneyRecipientName?: string | null,
        prizeMoneyRecipientId?: string | null,
        prizeMoneyRecipientType?: string | null,
        note?: string | null,
        divisionList?: Array< string | null > | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventEntryPrizeMoneyRecipientContactId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    entryIds?: Array< string | null > | null,
    submittedEntry?: string | null,
    lastUpdatedBy?: string | null,
    createdOn: string,
    updatedOn: string,
    beddingRequestTaxAId?: string | null,
    beddingRequestTaxBId?: string | null,
  } | null,
};

export type OnCreateSubmissionSubscription = {
  onCreateSubmission?:  {
    __typename: "Submission",
    id: string,
    title: string,
    description?: string | null,
    video?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    videoLength?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    riderId?: string | null,
    personId?: string | null,
    clinicians?:  Array< {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null > | null,
    totalPrice: number,
    status?: SubmissionStatus | null,
    submissionFeedback?:  {
      __typename: "ModelSubmissionFeedbackConnection",
      items:  Array< {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdOn?: string | null,
    updatedOn?: string | null,
  } | null,
};

export type OnUpdateSubmissionSubscription = {
  onUpdateSubmission?:  {
    __typename: "Submission",
    id: string,
    title: string,
    description?: string | null,
    video?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    videoLength?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    riderId?: string | null,
    personId?: string | null,
    clinicians?:  Array< {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null > | null,
    totalPrice: number,
    status?: SubmissionStatus | null,
    submissionFeedback?:  {
      __typename: "ModelSubmissionFeedbackConnection",
      items:  Array< {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdOn?: string | null,
    updatedOn?: string | null,
  } | null,
};

export type OnDeleteSubmissionSubscription = {
  onDeleteSubmission?:  {
    __typename: "Submission",
    id: string,
    title: string,
    description?: string | null,
    video?:  {
      __typename: "S3Object",
      title?: string | null,
      description?: string | null,
      originalExtension?: string | null,
      dateUploaded?: string | null,
      url?: string | null,
      bucket: string,
      region: string,
      key: string,
    } | null,
    videoLength?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    riderId?: string | null,
    personId?: string | null,
    clinicians?:  Array< {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null > | null,
    totalPrice: number,
    status?: SubmissionStatus | null,
    submissionFeedback?:  {
      __typename: "ModelSubmissionFeedbackConnection",
      items:  Array< {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdOn?: string | null,
    updatedOn?: string | null,
  } | null,
};

export type OnCreateSubmissionFeedbackSubscription = {
  onCreateSubmissionFeedback?:  {
    __typename: "SubmissionFeedback",
    id: string,
    type?: string | null,
    submission?:  {
      __typename: "Submission",
      id: string,
      title: string,
      description?: string | null,
      video?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      videoLength?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      personId?: string | null,
      clinicians?:  Array< {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null > | null,
      totalPrice: number,
      status?: SubmissionStatus | null,
      submissionFeedback?:  {
        __typename: "ModelSubmissionFeedbackConnection",
        nextToken?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
    } | null,
    submissionId: string,
    feedback:  {
      __typename: "Feedback",
      id: string,
      clinicianId?: string | null,
      personId?: string | null,
      technical?: string | null,
      style?: string | null,
      turnout?: string | null,
      general?: string | null,
      score?: number | null,
      status?: FeedbackStatus | null,
      submissionFeedback?:  {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      feedbackSubmissionFeedbackId?: string | null,
    },
    feedbackId: string,
    personId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    riderId?: string | null,
    clinician?:  {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null,
    clinicianId?: string | null,
    submissionStatus?: SubmissionStatus | null,
    feedbackStatus?: FeedbackStatus | null,
    clinicianAmount?: number | null,
    declineReason?: string | null,
    permissionToPost?: boolean | null,
    dateSubmitted?: string | null,
    dateCompleted?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    submissionFeedbackClinicianId?: string | null,
  } | null,
};

export type OnUpdateSubmissionFeedbackSubscription = {
  onUpdateSubmissionFeedback?:  {
    __typename: "SubmissionFeedback",
    id: string,
    type?: string | null,
    submission?:  {
      __typename: "Submission",
      id: string,
      title: string,
      description?: string | null,
      video?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      videoLength?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      personId?: string | null,
      clinicians?:  Array< {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null > | null,
      totalPrice: number,
      status?: SubmissionStatus | null,
      submissionFeedback?:  {
        __typename: "ModelSubmissionFeedbackConnection",
        nextToken?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
    } | null,
    submissionId: string,
    feedback:  {
      __typename: "Feedback",
      id: string,
      clinicianId?: string | null,
      personId?: string | null,
      technical?: string | null,
      style?: string | null,
      turnout?: string | null,
      general?: string | null,
      score?: number | null,
      status?: FeedbackStatus | null,
      submissionFeedback?:  {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      feedbackSubmissionFeedbackId?: string | null,
    },
    feedbackId: string,
    personId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    riderId?: string | null,
    clinician?:  {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null,
    clinicianId?: string | null,
    submissionStatus?: SubmissionStatus | null,
    feedbackStatus?: FeedbackStatus | null,
    clinicianAmount?: number | null,
    declineReason?: string | null,
    permissionToPost?: boolean | null,
    dateSubmitted?: string | null,
    dateCompleted?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    submissionFeedbackClinicianId?: string | null,
  } | null,
};

export type OnDeleteSubmissionFeedbackSubscription = {
  onDeleteSubmissionFeedback?:  {
    __typename: "SubmissionFeedback",
    id: string,
    type?: string | null,
    submission?:  {
      __typename: "Submission",
      id: string,
      title: string,
      description?: string | null,
      video?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      videoLength?: string | null,
      rider?:  {
        __typename: "Rider",
        id: string,
        name: string,
        location?: string | null,
        addressId?: string | null,
        personId?: string | null,
        age?: number | null,
        birthdate?: string | null,
        isProfessional?: boolean | null,
        barnName?: string | null,
        barnId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        createdAt: string,
        updatedAt: string,
        riderContactId?: string | null,
      } | null,
      riderId?: string | null,
      personId?: string | null,
      clinicians?:  Array< {
        __typename: "Clinician",
        id: string,
        name: string,
        personId?: string | null,
        judgeId?: string | null,
        contactId?: string | null,
        usefNumber?: string | null,
        ecNumber?: string | null,
        price?: number | null,
        status?: ClinicianStatus | null,
        paymentVerified?: boolean | null,
        stripeAccountId?: string | null,
        totalEarned?: number | null,
        location?: string | null,
        disciplines?: string | null,
        experience?: string | null,
        personal?: string | null,
        preferences?: string | null,
        hasIHSAExperience?: boolean | null,
        hasIEAExperience?: boolean | null,
        createdOn: string,
        updatedOn: string,
        clinicianPaymentChoiceId?: string | null,
      } | null > | null,
      totalPrice: number,
      status?: SubmissionStatus | null,
      submissionFeedback?:  {
        __typename: "ModelSubmissionFeedbackConnection",
        nextToken?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
    } | null,
    submissionId: string,
    feedback:  {
      __typename: "Feedback",
      id: string,
      clinicianId?: string | null,
      personId?: string | null,
      technical?: string | null,
      style?: string | null,
      turnout?: string | null,
      general?: string | null,
      score?: number | null,
      status?: FeedbackStatus | null,
      submissionFeedback?:  {
        __typename: "SubmissionFeedback",
        id: string,
        type?: string | null,
        submissionId: string,
        feedbackId: string,
        personId?: string | null,
        riderId?: string | null,
        clinicianId?: string | null,
        submissionStatus?: SubmissionStatus | null,
        feedbackStatus?: FeedbackStatus | null,
        clinicianAmount?: number | null,
        declineReason?: string | null,
        permissionToPost?: boolean | null,
        dateSubmitted?: string | null,
        dateCompleted?: string | null,
        createdOn?: string | null,
        updatedOn?: string | null,
        submissionFeedbackClinicianId?: string | null,
      } | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      feedbackSubmissionFeedbackId?: string | null,
    },
    feedbackId: string,
    personId?: string | null,
    rider?:  {
      __typename: "Rider",
      id: string,
      name: string,
      location?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      personId?: string | null,
      age?: number | null,
      birthdate?: string | null,
      isProfessional?: boolean | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      barnName?: string | null,
      barnId?: string | null,
      barn?:  {
        __typename: "Barn",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: string | null,
        ownerName?: string | null,
        ownerPersonId?: string | null,
        secondaryOwnerName?: string | null,
        secondaryOwnerPersonId?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        barnContactId?: string | null,
        barnLocationId?: string | null,
      } | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
      createdAt: string,
      updatedAt: string,
      riderContactId?: string | null,
    } | null,
    riderId?: string | null,
    clinician?:  {
      __typename: "Clinician",
      id: string,
      name: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      judgeId?: string | null,
      contactId?: string | null,
      rating?:  Array< {
        __typename: "JudgeRating",
        type?: JudgeType | null,
        license?: JudgeLicense | null,
      } | null > | null,
      usefNumber?: string | null,
      ecNumber?: string | null,
      price?: number | null,
      status?: ClinicianStatus | null,
      paymentChoice?:  {
        __typename: "PaymentMethod",
        id: string,
        personId?: string | null,
        organizationId?: string | null,
        type?: PaymentMethodType | null,
        stripeId?: string | null,
        altId?: string | null,
        value?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      paymentVerified?: boolean | null,
      stripeAccountId?: string | null,
      totalEarned?: number | null,
      location?: string | null,
      disciplines?: string | null,
      experience?: string | null,
      personal?: string | null,
      preferences?: string | null,
      hasIHSAExperience?: boolean | null,
      hasIEAExperience?: boolean | null,
      createdOn: string,
      updatedOn: string,
      clinicianPaymentChoiceId?: string | null,
    } | null,
    clinicianId?: string | null,
    submissionStatus?: SubmissionStatus | null,
    feedbackStatus?: FeedbackStatus | null,
    clinicianAmount?: number | null,
    declineReason?: string | null,
    permissionToPost?: boolean | null,
    dateSubmitted?: string | null,
    dateCompleted?: string | null,
    createdOn?: string | null,
    updatedOn?: string | null,
    submissionFeedbackClinicianId?: string | null,
  } | null,
};

export type OnCreateTaxRateSubscription = {
  onCreateTaxRate?:  {
    __typename: "TaxRate",
    id: string,
    name?: string | null,
    createdBy?: string | null,
    organizationId?: string | null,
    stripeTaxRateId?: string | null,
    percentage?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateTaxRateSubscription = {
  onUpdateTaxRate?:  {
    __typename: "TaxRate",
    id: string,
    name?: string | null,
    createdBy?: string | null,
    organizationId?: string | null,
    stripeTaxRateId?: string | null,
    percentage?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteTaxRateSubscription = {
  onDeleteTaxRate?:  {
    __typename: "TaxRate",
    id: string,
    name?: string | null,
    createdBy?: string | null,
    organizationId?: string | null,
    stripeTaxRateId?: string | null,
    percentage?: number | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateTeamSubscription = {
  onCreateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    location?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    members?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        teamId: string,
        personId: string,
        status?: TeamStatus | null,
        role?: TeamRole | null,
        permissionLevel?: TeamPermissionLevel | null,
        creatorId?: string | null,
        createdOn: string,
        updatedOn: string,
        teamMemberCreatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    creatorId?: string | null,
    creator?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    teamContactId?: string | null,
    teamLocationId?: string | null,
    teamCreatorId?: string | null,
  } | null,
};

export type OnUpdateTeamSubscription = {
  onUpdateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    location?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    members?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        teamId: string,
        personId: string,
        status?: TeamStatus | null,
        role?: TeamRole | null,
        permissionLevel?: TeamPermissionLevel | null,
        creatorId?: string | null,
        createdOn: string,
        updatedOn: string,
        teamMemberCreatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    creatorId?: string | null,
    creator?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    teamContactId?: string | null,
    teamLocationId?: string | null,
    teamCreatorId?: string | null,
  } | null,
};

export type OnDeleteTeamSubscription = {
  onDeleteTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    nickname?: string | null,
    description?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    location?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    members?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        teamId: string,
        personId: string,
        status?: TeamStatus | null,
        role?: TeamRole | null,
        permissionLevel?: TeamPermissionLevel | null,
        creatorId?: string | null,
        createdOn: string,
        updatedOn: string,
        teamMemberCreatorId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    creatorId?: string | null,
    creator?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    teamContactId?: string | null,
    teamLocationId?: string | null,
    teamCreatorId?: string | null,
  } | null,
};

export type OnCreateTeamMemberSubscription = {
  onCreateTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    teamId: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      members?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      creator?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      teamContactId?: string | null,
      teamLocationId?: string | null,
      teamCreatorId?: string | null,
    },
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    status?: TeamStatus | null,
    role?: TeamRole | null,
    permissionLevel?: TeamPermissionLevel | null,
    creatorId?: string | null,
    creator?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    teamMemberCreatorId?: string | null,
  } | null,
};

export type OnUpdateTeamMemberSubscription = {
  onUpdateTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    teamId: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      members?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      creator?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      teamContactId?: string | null,
      teamLocationId?: string | null,
      teamCreatorId?: string | null,
    },
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    status?: TeamStatus | null,
    role?: TeamRole | null,
    permissionLevel?: TeamPermissionLevel | null,
    creatorId?: string | null,
    creator?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    teamMemberCreatorId?: string | null,
  } | null,
};

export type OnDeleteTeamMemberSubscription = {
  onDeleteTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    teamId: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      members?:  {
        __typename: "ModelTeamMemberConnection",
        nextToken?: string | null,
      } | null,
      creatorId?: string | null,
      creator?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      createdOn: string,
      updatedOn: string,
      teamContactId?: string | null,
      teamLocationId?: string | null,
      teamCreatorId?: string | null,
    },
    personId: string,
    person?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    status?: TeamStatus | null,
    role?: TeamRole | null,
    permissionLevel?: TeamPermissionLevel | null,
    creatorId?: string | null,
    creator?:  {
      __typename: "Person",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      isVerified?: boolean | null,
      roles?: string | null,
      memberships?:  {
        __typename: "ModelMembershipConnection",
        nextToken?: string | null,
      } | null,
      personalInformationId?: string | null,
      personalInformation?:  {
        __typename: "PersonalInformation",
        id: string,
        personId: string,
        dateOfBirth?: string | null,
        gender?: Gender | null,
        contactId?: string | null,
        addressId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      pictureURL?: string | null,
      picture?:  {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null,
      disciplines?: string | null,
      stripeId?: string | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      createdBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    createdOn: string,
    updatedOn: string,
    teamMemberCreatorId?: string | null,
  } | null,
};

export type OnCreateTextSubscription = {
  onCreateText?:  {
    __typename: "Text",
    id: string,
    eventId?: string | null,
    blockId?: string | null,
    block?:  {
      __typename: "Block",
      id: string,
      name?: string | null,
      type?: string | null,
      personid?: string | null,
      organizationId?: string | null,
      purchasedOn?: string | null,
      expiresOn?: string | null,
      totalCredits?: number | null,
      usedCredits?: number | null,
      status?: BlockStatus | null,
      price?: number | null,
      amountPaid?: number | null,
      isPromotion?: string | null,
      isFree?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    alertId?: string | null,
    alert?:  {
      __typename: "Alert",
      id: string,
      type?: string | null,
      title?: string | null,
      body?: string | null,
      imageKey?: string | null,
      category?: string | null,
      organizationId?: string | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      messageSegments?: number | null,
      subscriberCount?: number | null,
      totalCost?: number | null,
      blockIdsUsed?: Array< string | null > | null,
      fromUnlimitedEvent?: boolean | null,
      isIncoming?: string | null,
      sentFromPhoneNumber?: string | null,
      recipientPhoneNumbers?: Array< string | null > | null,
      successSIDList?: Array< string | null > | null,
      errorSIDList?: Array< string | null > | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      alertEventId?: string | null,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null,
    status?: TextStatus | null,
    statusNote?: string | null,
    errorCode?: string | null,
    twilioSID?: string | null,
    twilioMessagingServiceSID?: string | null,
    twilioNote?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    timeSent?: string | null,
    timeDelivered?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnUpdateTextSubscription = {
  onUpdateText?:  {
    __typename: "Text",
    id: string,
    eventId?: string | null,
    blockId?: string | null,
    block?:  {
      __typename: "Block",
      id: string,
      name?: string | null,
      type?: string | null,
      personid?: string | null,
      organizationId?: string | null,
      purchasedOn?: string | null,
      expiresOn?: string | null,
      totalCredits?: number | null,
      usedCredits?: number | null,
      status?: BlockStatus | null,
      price?: number | null,
      amountPaid?: number | null,
      isPromotion?: string | null,
      isFree?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    alertId?: string | null,
    alert?:  {
      __typename: "Alert",
      id: string,
      type?: string | null,
      title?: string | null,
      body?: string | null,
      imageKey?: string | null,
      category?: string | null,
      organizationId?: string | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      messageSegments?: number | null,
      subscriberCount?: number | null,
      totalCost?: number | null,
      blockIdsUsed?: Array< string | null > | null,
      fromUnlimitedEvent?: boolean | null,
      isIncoming?: string | null,
      sentFromPhoneNumber?: string | null,
      recipientPhoneNumbers?: Array< string | null > | null,
      successSIDList?: Array< string | null > | null,
      errorSIDList?: Array< string | null > | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      alertEventId?: string | null,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null,
    status?: TextStatus | null,
    statusNote?: string | null,
    errorCode?: string | null,
    twilioSID?: string | null,
    twilioMessagingServiceSID?: string | null,
    twilioNote?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    timeSent?: string | null,
    timeDelivered?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnDeleteTextSubscription = {
  onDeleteText?:  {
    __typename: "Text",
    id: string,
    eventId?: string | null,
    blockId?: string | null,
    block?:  {
      __typename: "Block",
      id: string,
      name?: string | null,
      type?: string | null,
      personid?: string | null,
      organizationId?: string | null,
      purchasedOn?: string | null,
      expiresOn?: string | null,
      totalCredits?: number | null,
      usedCredits?: number | null,
      status?: BlockStatus | null,
      price?: number | null,
      amountPaid?: number | null,
      isPromotion?: string | null,
      isFree?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
    } | null,
    alertId?: string | null,
    alert?:  {
      __typename: "Alert",
      id: string,
      type?: string | null,
      title?: string | null,
      body?: string | null,
      imageKey?: string | null,
      category?: string | null,
      organizationId?: string | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      messageSegments?: number | null,
      subscriberCount?: number | null,
      totalCost?: number | null,
      blockIdsUsed?: Array< string | null > | null,
      fromUnlimitedEvent?: boolean | null,
      isIncoming?: string | null,
      sentFromPhoneNumber?: string | null,
      recipientPhoneNumbers?: Array< string | null > | null,
      successSIDList?: Array< string | null > | null,
      errorSIDList?: Array< string | null > | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      alertEventId?: string | null,
    } | null,
    auditorId?: string | null,
    auditor?:  {
      __typename: "EventAuditor",
      id: string,
      personId?: string | null,
      person?:  {
        __typename: "Person",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        isVerified?: boolean | null,
        roles?: string | null,
        personalInformationId?: string | null,
        pictureURL?: string | null,
        disciplines?: string | null,
        stripeId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      eventId?: string | null,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        nickname?: string | null,
        createdBy: string,
        manager?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        contactId?: string | null,
        contactIds?: Array< string | null > | null,
        organizationId?: string | null,
        type?: string | null,
        disciplines?: string | null,
        status?: string | null,
        entriesStatus?: string | null,
        showStatus?: string | null,
        usefID?: string | null,
        usefSoftwareKey?: string | null,
        ecID?: string | null,
        ecPassword?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
        eventAddressId?: string | null,
      } | null,
      auditorId?: string | null,
      auditor?:  {
        __typename: "Auditor",
        id: string,
        personId?: string | null,
        name?: string | null,
        phoneNumber?: string | null,
        email?: string | null,
        status?: AuditorStatus | null,
        statusNote?: string | null,
        createdBy?: string | null,
        lastUpdatedBy?: string | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      filters?:  {
        __typename: "ModelEventAuditorFilterConnection",
        nextToken?: string | null,
      } | null,
      name?: string | null,
      phoneNumber?: string | null,
      status?: string | null,
      createdBy?: string | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      eventAuditorEventId?: string | null,
    } | null,
    status?: TextStatus | null,
    statusNote?: string | null,
    errorCode?: string | null,
    twilioSID?: string | null,
    twilioMessagingServiceSID?: string | null,
    twilioNote?: string | null,
    createdBy?: string | null,
    lastUpdatedBy?: string | null,
    timeSent?: string | null,
    timeDelivered?: string | null,
    createdOn: string,
    updatedOn: string,
  } | null,
};

export type OnCreateTrainerSubscription = {
  onCreateTrainer?:  {
    __typename: "Trainer",
    id: string,
    name: string,
    location?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    trainerContactId?: string | null,
  } | null,
};

export type OnUpdateTrainerSubscription = {
  onUpdateTrainer?:  {
    __typename: "Trainer",
    id: string,
    name: string,
    location?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    trainerContactId?: string | null,
  } | null,
};

export type OnDeleteTrainerSubscription = {
  onDeleteTrainer?:  {
    __typename: "Trainer",
    id: string,
    name: string,
    location?: string | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      name?: string | null,
      type?: string | null,
      isDefault?: boolean | null,
      streetAddress1?: string | null,
      streetAddress2?: string | null,
      city?: string | null,
      provState?: string | null,
      zip?: string | null,
      country?: string | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      name?: string | null,
      cell?: string | null,
      home?: string | null,
      work?: string | null,
      fax?: string | null,
      personalEmail?: string | null,
      personalEmailIsVerified?: boolean | null,
      workEmail?: string | null,
      workEmailIsVerified?: boolean | null,
      mailingAddress?: string | null,
      website?: string | null,
      facebookPage?: string | null,
      instagramHandle?: string | null,
      twitterHandle?: string | null,
      tiktokHandle?: string | null,
      emergencyContactName?: string | null,
      emergencyContactPhone?: string | null,
      createdBy?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    personId?: string | null,
    memberships?:  {
      __typename: "ModelMembershipConnection",
      items:  Array< {
        __typename: "Membership",
        id: string,
        name: string,
        description?: string | null,
        membershipId: string,
        backNumber?: number | null,
        membershipStatus?: string | null,
        amountPaid?: number | null,
        organizationMembershipTypeId?: string | null,
        type?: string | null,
        personName?: string | null,
        personId: string,
        riderId?: string | null,
        horseId?: string | null,
        ownerId?: string | null,
        trainerId?: string | null,
        barnId?: string | null,
        teamId?: string | null,
        organizationId?: string | null,
        organizationName?: string | null,
        isJunior?: boolean | null,
        isProfessional?: boolean | null,
        dateMembershipEnds?: string | null,
        volunteerHours?: number | null,
        meetingsAttended?: number | null,
        showsAttended?: number | null,
        personEmail?: string | null,
        gradeLevel?: string | null,
        aelDivision?: string | null,
        aelTeamRole?: TeamRole | null,
        contactPersonId?: string | null,
        groupContactId?: string | null,
        groupLeaderContactId?: string | null,
        createdBy?: string | null,
        createdOn: string,
        updatedOn: string,
        personMembershipsId?: string | null,
        membershipOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    barnName?: string | null,
    barnId?: string | null,
    barn?:  {
      __typename: "Barn",
      id: string,
      name: string,
      nickname?: string | null,
      description?: string | null,
      type?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        name?: string | null,
        cell?: string | null,
        home?: string | null,
        work?: string | null,
        fax?: string | null,
        personalEmail?: string | null,
        personalEmailIsVerified?: boolean | null,
        workEmail?: string | null,
        workEmailIsVerified?: boolean | null,
        mailingAddress?: string | null,
        website?: string | null,
        facebookPage?: string | null,
        instagramHandle?: string | null,
        twitterHandle?: string | null,
        tiktokHandle?: string | null,
        emergencyContactName?: string | null,
        emergencyContactPhone?: string | null,
        createdBy?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      ownerName?: string | null,
      ownerPersonId?: string | null,
      secondaryOwnerName?: string | null,
      secondaryOwnerPersonId?: string | null,
      members?:  {
        __typename: "ModelBarnMemberConnection",
        nextToken?: string | null,
      } | null,
      location?:  {
        __typename: "Address",
        id: string,
        name?: string | null,
        type?: string | null,
        isDefault?: boolean | null,
        streetAddress1?: string | null,
        streetAddress2?: string | null,
        city?: string | null,
        provState?: string | null,
        zip?: string | null,
        country?: string | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      media?:  Array< {
        __typename: "S3Object",
        title?: string | null,
        description?: string | null,
        originalExtension?: string | null,
        dateUploaded?: string | null,
        url?: string | null,
        bucket: string,
        region: string,
        key: string,
      } | null > | null,
      lastUpdatedBy?: string | null,
      createdOn: string,
      updatedOn: string,
      barnContactId?: string | null,
      barnLocationId?: string | null,
    } | null,
    createdBy?: string | null,
    createdOn: string,
    updatedOn: string,
    trainerContactId?: string | null,
  } | null,
};

export type OnCreateOrganizationMembershipTypeSeasonsSubscription = {
  onCreateOrganizationMembershipTypeSeasons?:  {
    __typename: "OrganizationMembershipTypeSeasons",
    id: string,
    organizationMembershipTypeID: string,
    seasonID: string,
    organizationMembershipType:  {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    },
    season:  {
      __typename: "Season",
      id: string,
      name: string,
      description?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationMembershipTypes?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      startDate: string,
      endDate: string,
      disciplines?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      createdAt: string,
      updatedAt: string,
      seasonOrganizationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrganizationMembershipTypeSeasonsSubscription = {
  onUpdateOrganizationMembershipTypeSeasons?:  {
    __typename: "OrganizationMembershipTypeSeasons",
    id: string,
    organizationMembershipTypeID: string,
    seasonID: string,
    organizationMembershipType:  {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    },
    season:  {
      __typename: "Season",
      id: string,
      name: string,
      description?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationMembershipTypes?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      startDate: string,
      endDate: string,
      disciplines?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      createdAt: string,
      updatedAt: string,
      seasonOrganizationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrganizationMembershipTypeSeasonsSubscription = {
  onDeleteOrganizationMembershipTypeSeasons?:  {
    __typename: "OrganizationMembershipTypeSeasons",
    id: string,
    organizationMembershipTypeID: string,
    seasonID: string,
    organizationMembershipType:  {
      __typename: "OrganizationMembershipType",
      id: string,
      name: string,
      description?: string | null,
      order?: number | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      membershipIdValues?: string | null,
      nextAvailableMembershipId?: string | null,
      price?: number | null,
      category?: MembershipTypeCategory | null,
      schedule?: MembershipTypeSchedule | null,
      isStartAtTimeOfPurchase?: boolean | null,
      setStartDate?: string | null,
      acceptOnlineApplications?: string | null,
      applicationFields?:  {
        __typename: "ApplicationFields",
        firstName?: boolean | null,
        lastName?: boolean | null,
        dateOfBirth?: boolean | null,
        contact?: boolean | null,
        gradeLevel?: boolean | null,
        barnName?: boolean | null,
        teamName?: boolean | null,
        backNumber?: boolean | null,
        groupName?: boolean | null,
        groupLeader?: boolean | null,
        groupLeaderContactInfo?: boolean | null,
        collectMemberDetailsTogether?: boolean | null,
        maxNumberOfGroupMembers?: number | null,
        isTeam?: boolean | null,
        horseName?: boolean | null,
        horseHeight?: boolean | null,
        horseOwnerName?: boolean | null,
        cogginsInfo?: boolean | null,
        cogginsUpload?: boolean | null,
      } | null,
      applicationOptions?:  {
        __typename: "ApplicationOptions",
        requireApproval?: boolean | null,
        autoAssignIds?: boolean | null,
        acceptRSPPayment?: boolean | null,
        acceptOtherPayment?: boolean | null,
      } | null,
      organizationDocuments?:  Array< {
        __typename: "OrganizationDocument",
        id: string,
        name: string,
      } | null > | null,
      organizationRules?:  Array< {
        __typename: "OrganizationRule",
        id: string,
        title: string,
        body: string,
        isRequired?: boolean | null,
      } | null > | null,
      seasons?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      createdOn: string,
      updatedOn: string,
      organizationMembershipTypeOrganizationId?: string | null,
    },
    season:  {
      __typename: "Season",
      id: string,
      name: string,
      description?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        nickname?: string | null,
        description?: string | null,
        type?: OrganizationType | null,
        industry?: string | null,
        addresses?: Array< string | null > | null,
        contactId?: string | null,
        stripeId?: string | null,
        stripeCustomerId?: string | null,
        paymentMethodId?: string | null,
        website?: string | null,
        createdBy: string,
        lastUpdatedBy?: string | null,
        urlBackHalf?: string | null,
        colors?: Array< string | null > | null,
        createdOn: string,
        updatedOn: string,
      } | null,
      organizationMembershipTypes?:  {
        __typename: "ModelOrganizationMembershipTypeSeasonsConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      startDate: string,
      endDate: string,
      disciplines?: string | null,
      createdOn?: string | null,
      updatedOn?: string | null,
      createdAt: string,
      updatedAt: string,
      seasonOrganizationId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
