import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { CreateEventBeddingTypeInput } from "../../API";
import { Event, EventBeddingType, OrganizationBeddingType } from "../../models";
import { createEventBeddingType, deleteEventBeddingType, getEventBeddingTypesByEventId } from "../../utilities/eventBeddingType/EventBeddingType";
import { getOrganizationBeddingTypesByOrganizationId } from "../../utilities/organizationBeddingType/OrganizationBeddingType";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    organizationId?: string
    event: Event
}

interface FormattedBeddingType {
    isChecked: boolean
    object: OrganizationBeddingType
}

const EventBeddingTypeForm: React.FC<_Props> = ({event, organizationId}) => {

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [formattedBeddingTypes, setFormattedBeddingTypes] = useState<FormattedBeddingType[] | null | undefined>();
    const [selectedBeddingTypes, setSelectedBeddingTypes] = useState<EventBeddingType[] | null | undefined>();
    const [error, setError] = useState<string>("");

    const getEventBeddingTypes = async (event: Event) => {
        const queryResult = await getEventBeddingTypesByEventId(event.id);
        if (queryResult.isSuccess) {
            setSelectedBeddingTypes(queryResult.result);
        }
        if (event.organizationId) await getOrganizationBeddingTypes(event.organizationId, queryResult.result);
    }

    const getOrganizationBeddingTypes = async (organizationId: string, selectedEventBeddingTypes?: EventBeddingType[]) => {
        const queryResult = await getOrganizationBeddingTypesByOrganizationId(organizationId);
        if (queryResult.isSuccess) {
            if (selectedEventBeddingTypes) formatOrganizationBeddingTypes(queryResult.result, selectedEventBeddingTypes);
            else formatOrganizationBeddingTypes(queryResult.result);
        } else {
            setError("No organization bedding types found.")
        }
    }

    const formatOrganizationBeddingTypes = (stallTypes: OrganizationBeddingType[], selectedEventBeddingTypes?: EventBeddingType[]) => {
        let result: FormattedBeddingType[] = [];
        for (var i = 0; i < stallTypes.length; i++) {
            const stallType = stallTypes[i];
            let checked = false;
            if (selectedEventBeddingTypes && selectedEventBeddingTypes.length) {
                selectedEventBeddingTypes.forEach(type => {
                    if (type.organizationBeddingTypeId === stallType.id) checked = true;
                });
            }
            const formatted: FormattedBeddingType = {
                isChecked: checked,
                object: stallType
            };
            result.push(formatted);
        }
        setFormattedBeddingTypes(result);
    }

    useEffect(() => {
        if (event) getEventBeddingTypes(event);
    }, [event]);

    const handleCreateEventBeddingTypes = async (organizationBeddingType: OrganizationBeddingType) => {
        if (event && organizationId && organizationBeddingType) {
            // TO DO: find different way to handle taxes?
            const taxAmount = ((organizationBeddingType.taxA || 0) + (organizationBeddingType.taxB || 0)).toString();
            const input: CreateEventBeddingTypeInput = {
                eventId: event.id,
                name: organizationBeddingType.name || "",
                pricePerBag: organizationBeddingType.pricePerBag?.toString() || "",
                description: organizationBeddingType.description || "",
                organizationId: organizationId,
                organizationBeddingTypeId: organizationBeddingType.id,
                type: organizationBeddingType.type,
                taxPerBag: taxAmount
            };
            const createResult = await createEventBeddingType(input);
            if (!createResult.isSuccess) {
                setError("Sorry, we had a problem creating the stall type.");
            }
        } else {
            setError("Sorry, we could not find your organization.")
        }
    }

    const handleDeleteEventBeddingTypes = async (eventBeddingType: EventBeddingType) => {
        if (event) {
            const deleteResult = await deleteEventBeddingType({id: eventBeddingType.id});
            if (!deleteResult.isSuccess) {
                setError("Sorry, we had a problem removing the stall type.");
            }
        } else {
            setError("Sorry, we could not find your organization.")
        }
    }

    const handleSave = async () => {
        // First delete the old event stall types
        if (selectedBeddingTypes) {
            for (var i = 0 ; i < selectedBeddingTypes.length; i++) {
                const currentEventBeddingType: EventBeddingType = selectedBeddingTypes[i];
                if (currentEventBeddingType) await handleDeleteEventBeddingTypes(currentEventBeddingType);
            }
        }

        // Then create new event stall types
        if (formattedBeddingTypes) {
            for (var j = 0 ; j < formattedBeddingTypes.length; j++) {
                const currentFormattedBeddingType: FormattedBeddingType = formattedBeddingTypes[j];
                if (currentFormattedBeddingType.isChecked) {
                    const currentOrganizationBeddingType: OrganizationBeddingType = currentFormattedBeddingType.object;
                    await handleCreateEventBeddingTypes(currentOrganizationBeddingType);
                }
            }
        }

        // Finally, make sure lists are updated
        if (event) getEventBeddingTypes(event);
    }

    const handleOnChange = (index: number, stallType: OrganizationBeddingType) => {
        setIsDisabled(false);
        if (formattedBeddingTypes) {
            const selectedBeddingType = formattedBeddingTypes[index];
            const updatedBeddingType: FormattedBeddingType = {
                isChecked: !selectedBeddingType.isChecked,
                object: stallType
            };
            const updatedArray: FormattedBeddingType[] = [
                ...formattedBeddingTypes.slice(0, index),
                updatedBeddingType,
                ...formattedBeddingTypes.slice(index + 1)
            ];
            setFormattedBeddingTypes(updatedArray);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        await handleSave();
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {formattedBeddingTypes ? 
                <>
                    <IonList className="bg-white">
                        {formattedBeddingTypes.map((formattedBeddingType: FormattedBeddingType, index: number) => (
                            <IonItem key={index}>
                                <IonCheckbox slot="start" color="primary" checked={formattedBeddingType.isChecked} value={formattedBeddingType.object.id}  onClick={() => handleOnChange(index, formattedBeddingType.object)}/>
                                <IonLabel className="ion-text-wrap">
                                    {formattedBeddingType.object.name} {formattedBeddingType.object.pricePerBag ? " - $" + formattedBeddingType.object.pricePerBag : ""} 
                                </IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                    <IonRow>
                        <IonCol sizeMd="4">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    disabled={isDisabled}
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    {isDisabled ? "Saved" : "Save"}
                                </IonButton>
                            }
                        </IonCol>
                    </IonRow>
                </>
                :
                <p>No organization stall types. Go to your organization settings to update.</p>
            }
        </>
    );
};

export default EventBeddingTypeForm;