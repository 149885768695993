import {
    IonButton,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import ErrorAlert from "../Errors/ErrorAlert";
import { Barn, BarnMember, Contact, Event, EventEntry, Horse, Owner, Rider, Trainer } from "../../models";
import HorseEntryForm from "../Horse/HorseEntryForm";
import OwnerEntryForm from "../Owner/OwnerEntryForm";
import RiderEntryForm from "../Rider/RiderEntryForm";
import TrainerEntryForm from "../Trainer/TrainerEntryForm";
import Spinner from "../Spinners/Spinner";
import {createEntryInfo, entryInfoInterface, updateEntryInfo} from "../../utilities/eventEntry/CreateEntryInfo";
import { getBarnsByPersonId } from "../../utilities/barn/Barn";
import BarnEntryForm from "../Barn/BarnEntryForm";
import ContactEntryForm from "../Contact/ContactEntryForm";
import { getHorseById } from "../../utilities/horse/Horse";
import { getOwnerById, updateOwner } from "../../utilities/owner/Owner";
import { getRiderById, updateRider } from "../../utilities/rider/Rider";
import { getTrainerById, updateTrainer } from "../../utilities/trainer/Trainer";
import { getContactById } from "../../utilities/contact/Contact";
import SuccessBanner from "../Banners/SuccessBanner";
import moment from "moment";
import { getBarnMembersByPersonId } from "../../utilities/barnMember/BarnMember";
import { getBarnPeople } from "../../utilities/barn/BarnPeople";
import { addRequiredEventEntryFeesByEventByEntry } from "../../utilities/eventEntryFee/AddRequiredEventEntryFees";
import { UpdateOwnerInput, UpdateRiderInput, UpdateTrainerInput } from "../../API";

interface _Props {
    event: Event
    entry?: EventEntry
    onSubmit: Function
}

const EntryInfoForm: React.FC<_Props> = ({entry, event, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");
    const [reminderToSave, setReminderToSave] = useState(false);

    const [displayRider2Input, setDisplayRider2Input] = useState(false);

    const [horse, setHorse] = useState<Horse | null | undefined>();
    const [horseName, setHorseName] = useState("");
    const [horseHeight, setHorseHeight] = useState(0);
    const [owner, setOwner] = useState<Owner | null | undefined>();
    const [ownerName, setOwnerName] = useState("");
    const [ownerLocation, setOwnerLocation] = useState("");
    const [rider, setRider] = useState<Rider | null | undefined>();
    const [rider2, setRider2] = useState<Rider | null | undefined>();
    const [riderName, setRiderName] = useState("");
    const [riderLocation, setRiderLocation] = useState("");
    const [riderAge, setRiderAge] = useState(0);
    const [isProfessional, setIsProfessional] = useState(false);
    const [trainer, setTrainer] = useState<Trainer | null | undefined>();
    const [trainerName, setTrainerName] = useState("");
    const [barn, setBarn] = useState<Barn | null | undefined>();
    const [contact, setContact] = useState<Contact | null | undefined>();
    const [contactList, setContactList] = useState<Contact[] | null | undefined>();
    const [primaryContactName, setPrimaryContactName] = useState("");
    const [primaryContactEmail, setPrimaryContactEmail] = useState("");
    const [primaryContactPhone, setPrimaryContactPhone] = useState("");
    const [primaryContactPhoneNumberType, setPrimaryContactPhoneNumberType] = useState("");
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();

    const barnName = "";

    async function getBarns() {
        let barn: Barn | null | undefined = undefined;
        const queryResult = await getBarnMembersByPersonId(user.id);
        if (queryResult.isSuccess) {
            const barnMembersList: BarnMember[] = queryResult.result;
            if (barnMembersList && barnMembersList.length > 0) {
                const barnMember = barnMembersList[0]; //To Do: make alphabetical or chronological?
                barn = barnMember.barn;
            }
        }

        if (!barn) {
            const barnQueryResult = await getBarnsByPersonId(user.id);
            if (barnQueryResult.isSuccess) {
                barn = barnQueryResult.result[0];
            }
        }

        setBarn(barn);
        setIsLoading(false);
    }

    async function getHorse(id: string) {
        const queryResult = await getHorseById(id);
        if (queryResult.isSuccess) {
            handleHorseInputChange(queryResult.result);
        }
    }

    async function getOwner(id: string) {
        const queryResult = await getOwnerById(id);
        if (queryResult.isSuccess) {
            handleOwnerInputChange(queryResult.result);
        }
    }

    async function getRider(id: string, isSecond?: boolean) {
        const queryResult = await getRiderById(id);
        if (queryResult.isSuccess) {
            if (isSecond) handleRider2InputChange(queryResult.result);
            else handleRiderInputChange(queryResult.result);
        }
    }

    async function getTrainer(id: string) {
        const queryResult = await getTrainerById(id);
        if (queryResult.isSuccess) {
            handleTrainerInputChange(queryResult.result);
        }
    }

    async function getContact(id: string) {
        const queryResult = await getContactById(id);
        if (queryResult.isSuccess) {
            handleContactInputChange(queryResult.result);
            setContactList([queryResult.result]);
        }
    }

    useEffect(() => {
        getBarns();
    }, [user, user.id]);

    useEffect(() => {
        if (event && event.entryOptions?.displayRider2Input) setDisplayRider2Input(true); 
    }, [event]);

    useEffect(() => {
        if (entry) {
            if (entry.horseId) getHorse(entry.horseId); 
            if (entry.ownerId) getOwner(entry.ownerId);
            if (entry.riderId) getRider(entry.riderId);
            if (entry.secondRiderId) getRider(entry.secondRiderId, true);
            if (entry.trainerId) getTrainer(entry.trainerId);
            if (entry.contactId) getContact(entry.contactId);
            getContactOptions();
            setCurrentEntry(entry);
        }
    }, [entry]);

    const verifyForm = () => {
        if (event.type !== "camp") {
            if (!horse) {
                setError("Please include a name for the horse.");
                return false;
            }
            if (event && event.entryOptions?.requiresCogginsData) {
                if (!horse.cogginsDate) {
                    setError("Please include a date for the coggins.");
                    return false;
                }
                if (horse.cogginsDate && !moment(new Date()).isBefore(moment(horse.cogginsDate).add(1, "year"))) {
                    setError("Please make sure the coggins test date is within the last year.");
                    return false;
                }
                if (!horse.cogginsNumber) {
                    setError("Please include an accession number for the coggins.");
                    return false;
                }
                if (!horse.cogginsState) {
                    setError("Please include a state for where the coggins test took place.");
                    return false;
                }
            }
            if (event && event.entryOptions?.requiresCogginsDocument) {
                if (!horse.coggins) {
                    setError("Please include a document for the coggins.");
                    return false;
                }
            }
            if (!owner) {
                setError("Please include a name for the horse owner.");
                return false;
            }
            if (!trainerName) {
                setError("Please include a name for the trainer. If you do not have a trainer, please write: self");
                return false;
            }
        }
        
        if (!rider) {
            setError("Please include a name for the rider.");
            return false;
        }

        // For Stonegate Classic horse shows, check that there is a DOB for the rider
        if (!rider.birthdate && event.organizationId === "3031d5b7-74cb-48ca-a05b-71a3ec78e390") {
            setError("Please include a Date of Birth for the rider.");
            return false;
        }
        // if (!primaryContactName) {
        //     setError("Please include a name for the primary contact. The primary contact is typically the rider, the trainer or rider's parent.");
        //     return false;
        // }
        // if (!primaryContactPhone) {
        //     setError("Please include a phone number for the primary contact.");
        //     return false;
        // }
        // if (!primaryContactEmail) {
        //     setError("Please include an email address for the primary contact.");
        //     return false;
        // }
        return true;
    }

    const getContactOptions = async () => {
        let contactList: Contact[] = [];
        
        if (barn && barn.id) {
            const barnPeople = await getBarnPeople(barn.id, user.id);
            if (barnPeople) {
                for (let i = 0; i < barnPeople.length; i++) {
                    const barnPerson = barnPeople[i];
                    if (barnPerson.rider && barnPerson.rider.contact) {
                        const contact = barnPerson.rider.contact;
                        contactList.push(contact);
                    }
                    if (barnPerson.trainer && barnPerson.trainer.contact) {
                        const contact = barnPerson.trainer.contact;
                        contactList.push(contact);
                    }
                    if (barnPerson.owner && barnPerson.owner.contact) {
                        const contact = barnPerson.owner.contact;
                        contactList.push(contact);
                    }
                }
            }
        } else {
            if (horse) {
                const horseContact = horse.contact;
                if (horseContact) contactList.push(horseContact);
            }
            if (owner) {
                const ownerContact = owner.contact;
                if (ownerContact) contactList.push(ownerContact);
            }
            if (rider) {
                const riderContact = rider.contact;
                if (riderContact) contactList.push(riderContact);
            }
            if (rider2) {
                const rider2Contact = rider2.contact;
                if (rider2Contact) contactList.push(rider2Contact);
            }
            if (trainer) {
                const trainerContact = trainer.contact;
                if (trainerContact) contactList.push(trainerContact);
            }
            if (entry && entry.contactId) {
                const contactResult = await getContactById(entry.contactId);
                if (contactResult.isSuccess) {
                    const contact = contactResult.result;
                    contactList.push(contact);
                }
            }
        }
        setContactList(contactList);
    }

    const handleHorseInputChange = (horse: Horse) => {
        if (horse) {
            setHorse(horse);
            setHorseName(horse.name);
            setHorseHeight(horse.height ? parseInt(horse.height) : 0);
            getContactOptions();

            if (!owner && horse.owner) {
                setOwner(horse.owner);
                setOwnerName(horse.owner ? horse.owner.name : "");
                setOwnerLocation(horse.owner?.location || "");
            }
        }
    }

    const handleOwnerInputChange = (owner: Owner | null | undefined) => {
        if (owner) {
            setOwner(owner);
            setOwnerName(owner ? owner.name : "");
            setOwnerLocation(owner?.location || "");
            getContactOptions();
        }
    }

    const handleRiderInputChange = (rider: Rider) => {
        if (rider) {
            setRider(rider);
            setRiderName(rider.name);
            setRiderLocation(rider?.location || "");
            setRiderAge(rider.age || 0);
            setIsProfessional(!!rider.isProfessional);
            getContactOptions();
        }
    }

    const handleRider2InputChange = (rider: Rider) => {
        if (rider) {
            setRider2(rider);
            getContactOptions();
        }
    }

    const handleTrainerInputChange = (trainer: Trainer) => {
        if (trainer) {
            setTrainer(trainer);
            setTrainerName(trainer.name);
            getContactOptions();
        }
    }

    const handleBarnInputChange = (barn: Barn) => {
        setBarn(barn);
    }

    const handleContactInputChange = async (contact: Contact, contactType?: string) => {
        if (contact) {
            setContact(contact);
            setPrimaryContactName(contact.name || "");
            setPrimaryContactEmail(contact.personalEmail || "");
            setPrimaryContactPhone(contact.cell || contact.home || contact.work || "");
            setPrimaryContactPhoneNumberType(contact.cell ? "cell" : (contact.home ? "home" : "work"));

            if (contactType) {
                if (contactType === "owner" && owner && !owner.contact) {
                    const input: UpdateOwnerInput = {
                        id: owner.id,
                        ownerContactId: contact.id
                    };
                    await updateOwner(input);
                } else if (contactType === "rider1" && rider && !rider.contact) {
                    const input: UpdateRiderInput = {
                        id: rider.id,
                        riderContactId: contact.id
                    };
                    await updateRider(input);
                } else if (contactType === "rider2" && rider2 && !rider2.contact) {
                    const input: UpdateRiderInput = {
                        id: rider2.id,
                        riderContactId: contact.id
                    };
                    await updateRider(input);
                } else if (contactType === "trainer" && trainer && !trainer.contact) {
                    const input: UpdateTrainerInput = {
                        id: trainer.id,
                        trainerContactId: contact.id
                    };
                    await updateTrainer(input);
                }
            }
        }
    }

    const saveEventEntry = async () => {
        const input: entryInfoInterface = {
            userId: user.id,
            eventId: event.id,
            organizationId: event.organizationId || "",
            horseName,
            horseHeight,
            ownerName,
            ownerLocation,
            riderName,
            riderLocation,
            riderAge,
            isProfessional,
            trainerName,
            barnName,
            primaryContactName,
            primaryContactEmail,
            primaryContactPhone,
            primaryContactPhoneNumberType,
            currentPersonId: user.id,
            currentHorse: horse!,
            currentOwner: owner!,
            currentRider: rider!,
            currentRider2: rider2!,
            currentTrainer: trainer!,
            currentContact: contact!,
            currentEntry: currentEntry!,
            currentBarn: barn || undefined
        };
        if (entry) {
            const updateEntryResult = await updateEntryInfo(input);
            if (updateEntryResult.isSuccess) {
                setCurrentEntry(updateEntryResult.result);
                onSubmit(updateEntryResult.result);
                setSuccess("Successfully saved the entry info!");
            } else {
                setError(updateEntryResult.message);
            }
        } else {
            const createEntryResult = await createEntryInfo(input);
            if (createEntryResult.isSuccess) {
                const currentEntry: EventEntry = createEntryResult.result;
                setCurrentEntry(currentEntry);
                await addRequiredEventEntryFeesByEventByEntry(user.id, currentEntry.eventId, currentEntry);
                onSubmit(createEntryResult.result);
                setCurrentEntry(undefined);
                setSuccess("Successfully saved the entry info!");
            } else {
                setError(createEntryResult.message);
            }
        }
    }

    const handleSubmit = async () => {
        setReminderToSave(false);
        setIsLoading(true);
        setError("");
        setSuccess("");
        const isValid = verifyForm();
        if (isValid) await saveEventEntry();
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            {reminderToSave && <ErrorAlert width="12" error="Remember to save your updates. Scroll down to save." />}
            {isLoading ?
                <Spinner/>
                :
                <form>
                    {event.type !== "camp" && (
                        <>
                            <BarnEntryForm barn={barn} onChange={handleBarnInputChange} />
                            <div className="ion-padding-top"></div>
                            <HorseEntryForm horse={horse} barn={barn} event={event} onChange={handleHorseInputChange} />
                            <div className="ion-padding-top"></div>
                            <OwnerEntryForm horse={horse} barn={barn} owner={owner || horse?.owner} event={event} onChange={handleOwnerInputChange} />
                            <div className="ion-padding-top"></div>
                        </>
                    )}
                    <RiderEntryForm key={rider?.id || ""} rider={rider!} barn={barn!} label={displayRider2Input ? "Rider #1" : "Rider"} isRequired={true} onChange={handleRiderInputChange} />
                    <div className="ion-padding-top"></div>
                    {displayRider2Input && (
                        <>
                            <RiderEntryForm rider={rider2!} barn={barn!} label="Rider #2" onChange={handleRider2InputChange} />
                            <div className="ion-padding-top"></div>
                        </>
                    )}
                    {event.type !== "camp" && (
                        <>
                            <TrainerEntryForm trainer={trainer} barn={barn} onChange={handleTrainerInputChange} />
                            <div className="ion-padding-top"></div>
                        </>
                    )}
                    <ContactEntryForm contacts={contactList!} contact={contact!} onChange={handleContactInputChange} />
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="text-center" sizeMd="4">
                            <IonButton
                                className="ion-margin-top"
                                color="success"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                Save Info
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </form>
            }
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
        </>
    );
};

export default EntryInfoForm;