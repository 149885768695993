import { IonButton, IonText, isPlatform } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { isWindows } from "../../utilities/platform/Platform";

const { v4: uuidv4 } = require('uuid');

interface _Props {
    className?: string
    label?: string
    tip: string
}



const BasicTooltip: React.FC<_Props> = ({label, tip, className}) => {
    const [tooltip, setTooltip] = useState(false);
    const [id, setId] = useState("");

    useEffect(() => {
        const uniqueId = uuidv4();
        setId(uniqueId.toString());
    }, []);

    return (
        <>
            {(isWindows() || (isPlatform("desktop"))) ?
                <>
                    <IonText className={className}>{label + " "}<IonButton className="tooltip-btn" fill="solid" size="small" color="medium" id={"tooltip" + id}>i</IonButton></IonText>
                    <UncontrolledTooltip placement="right" isOpen={tooltip} target={"tooltip" + id} toggle={() => {setTooltip(!tooltip)}}>
                        {tip}
                    </UncontrolledTooltip>
                </>
                :
                <IonText className={className}>{label + " "}</IonText>
            }
        </>
    );
};

export default BasicTooltip;
