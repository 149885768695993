import BasicTooltip from "../../Tooltip/BasicTooltip";
import React from "react";

const EntryFeeSelectTooltip: React.FC = () => {
    return (
        <>
            <BasicTooltip 
            label="Entry Fees" 
            tip="Select if exhibitors can 1) only sign up for the entire division, 2) only sign up by entering class(es) or 3) can choose to sign either way."
            />
        </>
    );
};

export default EntryFeeSelectTooltip;
