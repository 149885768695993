import {
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import { useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import {Event} from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import QuickAddEntry from "../../../../../components/Entry/QuickAddEntryForm";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventQuickAddEntryPage: React.FC<EventPageProps> = ({match}) => {
    const history = useHistory();

    const [event, setEvent] = useState<Event>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            if (!match.params.id) {
                setError("Sorry, we could not find the event");
                return;
            }
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getEvent();
    }, [match]);

    const handleNavigateToParticipantsPage = () => {
        if (event) {
            const path = "/index/staff/event/" + event.id + "/participants";
            history.replace(path);
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={event ? event.name : "participants"} />
                {error && <ErrorAlert width="12" error={error} />}
                {event && (
                    <>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol>
                                                Quick Add Entry
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    <IonCardSubtitle>
                                        <QuickAddEntry event={event} onSubmit={handleNavigateToParticipantsPage} />
                                    </IonCardSubtitle>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventQuickAddEntryPage;