import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { close, create } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../../context/PersonContext";
import { Event, EventRules } from "../../../models";
import RuleForm from "../../EventRules/RuleForm";
import ErrorAlert from "../../Errors/ErrorAlert";
import Spinner from "../../Spinners/Spinner";
import { getEventRulesByEventId } from "../../../utilities/eventRules/EventRules";

interface _Props {
    event: Event
}

const EventRulesForm: React.FC<_Props> = ({event}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [eventRuleArray, setEventRulesArray] = useState<(EventRules | null)[] | null | undefined>();
    const [selectedEventRules, setSelectedEventRules] = useState<EventRules | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEventRuless(eventId: string) {
            const queryResult = await getEventRulesByEventId(eventId);
            if (queryResult.isSuccess) {
                setEventRulesArray(queryResult.result);
            }
        };
        if (event) getEventRuless(event.id);
    }, [event]);

    const handleSubmit = async (type: "create" | "update" | "delete", newEventRules: EventRules) => {
        setIsLoading(true);
        if (event) {
            setError("");
            let ruleArray: (EventRules | null)[] = [];
            if (type === "create") {
                if (eventRuleArray && eventRuleArray.length) {
                    ruleArray = eventRuleArray.concat([newEventRules]);
                } else {
                    ruleArray.push(newEventRules);
                }
            } else if (type === "update") {
                if (eventRuleArray && eventRuleArray.length) {
                    for (var i = 0; i < eventRuleArray.length; i++) {
                        const eventRule = eventRuleArray[i];
                        if (eventRule && eventRule.id === selectedEventRules?.id) ruleArray.push(newEventRules);
                        else ruleArray.push(eventRule);
                    }
                }
            } else {
                if (eventRuleArray && eventRuleArray.length) {
                    ruleArray = eventRuleArray.filter(eventRule => eventRule?.id !== newEventRules.id);
                }
            }
            setEventRulesArray(ruleArray);
        } else {
            setError("Error: no event found. Please contact hello@ringsidepro.com");
        }
        setIsLoading(false);
        setShowModal(false);
    }

    const handleAddNewRule = () => {
        setSelectedEventRules(null);
        setShowModal(true);
    }

    const handleUpdate = async (eventRule?: EventRules) => {
        if (eventRule) {
            setSelectedEventRules(eventRule);
            setShowModal(true);
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {eventRuleArray && eventRuleArray.length &&
                <IonList className="bg-white">
                    {eventRuleArray.map((eventRule, index) => (
                        <IonItem key={index}>
                            <IonLabel><h3>{eventRule && eventRule.title}</h3></IonLabel>
                            <IonButton slot="end" color="tertiary" onClick={() => handleUpdate(eventRule as EventRules)}><IonIcon icon={create}/></IonButton>
                        </IonItem>
                    ))}
                </IonList>
            }
            <IonRow>
                <IonCol>
                    <IonButton color="tertiary" onClick={() => handleAddNewRule()}>
                        Add Rule
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonModal backdropDismiss={false} isOpen={showModal} id="addEventRulesModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Rule
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeRuleModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <RuleForm event={event} selectedEventRules={selectedEventRules} onSubmit={handleSubmit} />
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventRulesForm;