import { Auth, API } from "aws-amplify";
import { CognitoUser } from "../../interfaces/CognitoUser";

/**
 * Returns an array of all cognito user pool groups the current user belongs to.
 * 
 * All active users will belong to at least 1 group -> rsp-users
 * 
 * @param username Cognito user's username (sub)
 * @returns 
 */
export async function getGroupsForUser(username: string) { 
    let nextToken: any;
    let apiName = "AdminQueries";
    let path = "/listGroupsForUser";
    let myInit = {
        queryStringParameters: {
            "username" : username,
            "token": nextToken
        }, 
        headers: {
            "Content-Type" : "application/json",
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        } 
    }
    const { NextToken, ...rest } = await API.get(apiName, path, myInit);
    nextToken = NextToken;
    return rest;
}

export async function listUsersInGroup(groupName: string) { 
    let nextToken: any;
    let apiName = "AdminQueries";
    let path = "/listUsersInGroup";
    let myInit = {
        queryStringParameters: {
            "groupname" : groupName,
            "token": nextToken
        }, 
        headers: {
            "Content-Type" : "application/json",
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        } 
    }
    const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
    nextToken = NextToken;
    return rest;
}

export async function addUserToOrgGroup(user: CognitoUser, groupName: string) { 
    let apiName = "AdminQueries";
    let path = "/addUserToGroup";
    let myInit = {
        body: {
            "username" : user.username,
            "groupname": groupName
        }, 
        headers: {
            "Content-Type": "application/json",
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        } 
    }
    return await API.post(apiName, path, myInit);
}

/**
 * Creates a new user with roles and then adds that user to the given group
 * 
 * https://github.com/aws-amplify/amplify-cli/issues/4351
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/CognitoIdentityServiceProvider.html#adminCreateUser-property 
 * @param email 
 * @param groupName 
 * @param roles 
 * @returns 
 */
export async function inviteUserToGroup(email: string, groupName: string, roles: string) { 
    let apiName = "AdminQueries";
    let path = "/createUser";
    let myInit = {
        body: {
            "username" : email,
            "groupname": groupName,
            "roles": roles,
            "userPoolId": "us-east-1_Cv9DWHfuj"
        }, 
        headers: {
            "Content-Type" : "application/json",
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        } 
    }
    return await API.post(apiName, path, myInit);
}