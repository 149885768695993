export interface OrganizationIndustryOption {
    name: string
    value: string
}

export const organizationIndustries = [
    {
        "name": "Barn (Hosts Horse Shows)",
        "value": "barn_hosts_horse_shows"
    },
    {
        "name": "Barn (Does Not Host Horse Shows)",
        "value": "barn_does_not_host_horse_shows"
    },
    {
        "name": "Event Venue",
        "value": "event_venue"
    },
    {
        "name": "Horse Show Management Company",
        "value": "horse_show_management_company"
    },
    {
        "name": "Horse Show Series",
        "value": "horse_show_series"
    },
    {
        "name": "Horse Show (Single Event)",
        "value": "horse_show_single_event"
    },
    {
        "name": "Horse Show Association",
        "value": "horse_show_association"
    },
    {
        "name": "Horse Show Sponsor",
        "value": "horse_show_sponsor"
    }
];