import React, { useEffect, useState } from "react";
import { EventBreak } from "../../models";
import { getClassById } from "../../utilities/class/Class";
import { getEventBreaksByEventId } from "../../utilities/eventBreak/EventBreak";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    eventId: string
    menuPlacement?: string
    selectedValue?: string
    onSelect: Function
}

const SelectEventBreak: React.FC<_Props> = ({eventId, selectedValue, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedEventBreaks, setFormattedEventBreaks] = useState<formattedOption[] | null | undefined>();

    const formatEventBreaks = async (eventBreaks: EventBreak[]) => {
        let formattedEventBreaks = [];
        for (var i = 0; i < eventBreaks.length; i++) {
            const currentEventBreak = eventBreaks[i];
            if (currentEventBreak) {
                const label = currentEventBreak.name + (currentEventBreak.time ? " (" + currentEventBreak.time + " min.)" : "");
                let object = {
                    value: currentEventBreak.id,
                    label: label,
                    object: currentEventBreak
                };
                formattedEventBreaks.push(object);
            }
        }
        setFormattedEventBreaks(formattedEventBreaks);
    }

    useEffect(() => {
        async function getEventBreaks(eventId: string) {
            const queryResult = await getEventBreaksByEventId(eventId);
            if (queryResult.isSuccess) {
                formatEventBreaks(queryResult.result);
            }
        }

        if (eventId) getEventBreaks(eventId);
    }, [eventId]);

    useEffect(() => {
        if (selectedValue) {
            setSelected(selectedValue);
        } else {
            setSelected(undefined);
        }
    }, [selectedValue]);

    const handleOnChange = (event?: any) => {
        if (event) {
            onSelect(event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedEventBreaks ?
                <BasicSelect formattedValues={formattedEventBreaks} selectedValue={selected} menuPlacement={menuPlacement} onSelect={handleOnChange}/>
                :
                <p>Loading options...</p>
            }
        </>
    );
};

export default SelectEventBreak;
