import { Clinician, SubmissionFeedback } from "../../models";
import {
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { getClinicianByPersonId} from "../../utilities/clinician/Clinician";
import { getCompleteSubmissionFeedbacksByClinicianId } from "../../utilities/submissionFeedback/SubmissionFeedback";
import { getPersonByPersonId } from "../../utilities/person/Person";
import Spinner from "../Spinners/Spinner";
import moment from "moment";

interface _Props {
    selectedClinician?: Clinician
    onChange?: Function
}

interface formattedPayment {
    payer: string
    amount: string
    date: string
}

const ClinicianPaymentTracker: React.FC<_Props> = ({selectedClinician, onChange}) => {
    const user = useContext(PersonContext);
    
    const [formattedList, setFormattedList] = useState<formattedPayment[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const formatSubmissionFeedbackItems = async (submissionFeedback: SubmissionFeedback[]) => {
        let list = [];
        if (submissionFeedback && submissionFeedback.length > 0) {
            for (let i = 0; i < submissionFeedback.length; i++) {
                const sf = submissionFeedback[i];
                let personName = "";
                if (sf.personId) {
                    const personResult = await getPersonByPersonId(sf.personId);
                    if (personResult.isSuccess) {
                        const person = personResult.result;
                        personName = person.firstName + " " + person.lastName;
                    }
                }
                
                const formatted: formattedPayment = {
                    payer: personName,
                    amount: sf.clinicianAmount?.toString() || "0",
                    date: moment(sf.dateCompleted).format("MMM DD, YYYY") || ""
                }

                list.push(formatted);
            }
        }
        setFormattedList(list);
    }

    const getSubmissionFeedback = async (clinicianId: string) => {
        const queryResult = await getCompleteSubmissionFeedbacksByClinicianId(clinicianId);
        if (queryResult.isSuccess) {
            formatSubmissionFeedbackItems(queryResult.result);
        }
    }

    const getClinician = async (personId: string) => {
        setIsLoading(true);
        const queryResult = await getClinicianByPersonId(personId);
        if (queryResult.isSuccess) {
            const clinician: Clinician = queryResult.result;
            getSubmissionFeedback(clinician.id);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (user) {
            getClinician(user.id);
        }
    }, []);

    return (
        <>
            <IonRow>
                {isLoading ?
                    <Spinner />
                    :
                    <IonCol>
                        {(formattedList && formattedList.length>0) ?
                            <IonList className="bg-white">
                                {formattedList.map((sf, index) => (
                                    <IonItem key={index}>
                                        <IonLabel>
                                            <IonRow>
                                                <IonCol className="ion-text-wrap">{sf.payer}</IonCol>
                                                <IonCol className="ion-text-wrap">${sf.amount}</IonCol>
                                                <IonCol className="ion-text-wrap">{sf.date}</IonCol>
                                            </IonRow>
                                        </IonLabel>
                                    </IonItem>
                                ))}
                            </IonList>
                            :
                            <p></p>
                        }
                    </IonCol>
                }
            </IonRow>
        </>
    );
};

export default ClinicianPaymentTracker;