import moment from "moment";
import { EventDay } from "../../models";

export const formatDateShortFormat = (eventDay: EventDay): string => {
    return moment(eventDay.awsdate).format("M/D/YY");
};

export const formatDateLongFormat = (eventDay: EventDay): string => {
    return eventDay.dayofweek + " " + eventDay.month + " " + eventDay.date + ", " + eventDay.year;
};

export const formatMultiMonthDateRange = (startDate: string, endDate: string): string => {
    // Only gets ran through if there is a start/end date and the format chosen is MMM D, 
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    
    if (startMoment.month() === endMoment.month() && startMoment.year() === endMoment.year()) {
        // Same month and year
        return `${startMoment.format("MMM D")} - ${endMoment.format("D")}, ${startMoment.format("YYYY")}`;
    } else {
        // Different months or years
        return `${startMoment.format("MMM D, YYYY")} - ${endMoment.format("MMM D, YYYY")}`;
    }
};

export const formatStartEndDatesStrings = (
    startDate?: string | null, 
    endDate?: string | null, 
    format?: string | null
): string => {
    const chosenFormat = format || "M/D/YY";
    const dateRangeFormat = stripCharactersAfterDays(chosenFormat);

    if (startDate) {
        if (startDate === endDate || !endDate) {
            return moment(startDate).format(chosenFormat);
        } else {
            if (chosenFormat === "MMM D, YYYY") {
                return formatMultiMonthDateRange(startDate, endDate);
            } else {
                return moment(startDate).format(dateRangeFormat) + " - " + moment(endDate).format(chosenFormat);
            }
        }
    } else {
        return "";
    }
};

const stripCharactersAfterDays = (format: string): string => {
    const indexOfD = format.indexOf("D");

    if (indexOfD !== -1) {
        return format.substring(0, indexOfD + 1);
    }

    return format;
};
