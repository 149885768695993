import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import React, { useState } from "react";
import { removeSubmissionFeedback } from "../../../../../utilities/delete/deleteSubmissionFeedbackData";
import Spinner from "../../../../../components/Spinners/Spinner";

const TestClinicDataPage: React.FC = () => {

    const [isLoading, setIsLoading] = useState(false);

    const handleRemoveSubmissionFeedback = async () => {
        setIsLoading(true);
        await removeSubmissionFeedback();
        setIsLoading(false);
    }
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Test Clinic Data" />
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Test Data</IonCardTitle>
                            <IonCardContent>
                                {isLoading ?
                                    <Spinner />
                                    :
                                    <>
                                        <IonButton color="danger" onClick={handleRemoveSubmissionFeedback}>Remove Submission Feedback</IonButton>
                                    </>
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default TestClinicDataPage;