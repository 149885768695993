import { Event, EventEntry, Horse, Membership } from "../../models";
import { getEventEntriesByEventId } from "../eventEntry/EventEntry";
import { getHorseById } from "../horse/Horse";
import { sortEventEntriesByNumber } from "../eventEntry/SortEventEntry";
import { getMembershipsByHorseId } from "../membership/Membership";
import { downloadCSVFile } from "./ReportDownloadCSV";
require('jspdf-autotable');

export async function generateTIPHorseNumberReportCSV(event: Event) {

    const headerRow = [
        "Entry #",
        "Status",
        "Horse Name",
        "TIP Number"
    ];

    let rows = [];

    rows.push(headerRow);

    let nonTIPRows = [];

    const entryListResult = await getEventEntriesByEventId(event.id);
    if (entryListResult.isSuccess) {
        const entryList: EventEntry[] = entryListResult.result;

        if (entryList && entryList.length > 0) {
            const sorted = sortEventEntriesByNumber(entryList) || entryList;
            for (let i = 0; i < sorted.length; i++) {
                const entry: EventEntry = sorted[i];
                // Get the Horse
                let horse: Horse | undefined = undefined;
                if (entry.horse) {
                    horse = entry.horse;
                } else if (entry.horseId) {
                    const horseResult = await getHorseById(entry.horseId);
                    if (horseResult.isSuccess) {
                        horse = horseResult.result;
                    }
                }
                // Check for a TIP number
                if (horse) {
                    const membershipsResult = await getMembershipsByHorseId(horse.id);
                    if (membershipsResult.isSuccess) {
                        const memberships: Membership[] = membershipsResult.result;
                        let tipNumber = "";
                        if (memberships && memberships.length > 0) {
                            memberships.forEach(membership => {
                                if (membership.name.toLowerCase().includes("tip")) {tipNumber = membership.membershipId}
                            });
                        }
                        const newRow = [
                            entry.number || "",
                            entry.status || "",
                            horse.name,
                            tipNumber
                        ];
                        if (tipNumber && tipNumber !== "") rows.push(newRow);
                        else nonTIPRows.push(newRow);
                    }
                }
            }
        }
    }

    rows = rows.concat(nonTIPRows);
    
    const fileName = event.nickname ? (event.nickname + " Horse TIP Numbers.csv") : (event.name ? event.name + " Horse TIP Numbers.csv" : "Horse TIP Numbers.csv");
    downloadCSVFile(fileName, rows);
}