import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonPage,
  IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { Organization } from "../../../../../models";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import EventOrganizationBranding from "../../../../../components/EventOrganization/EventOrganizationBranding";

interface OrganizationPageProps extends RouteComponentProps<{
  id: string;
}> {}

const EventOrganizationBrandingPage: React.FC<OrganizationPageProps> = ({match}) => {
  const [organization, setOrganization] = useState<Organization>();
  const [error, setError] = useState("");

  useEffect(() => {
    async function getOrganization() {
      const queryResult = await getOrganizationById(match.params.id);
      if (queryResult.isSuccess) {
        const org: Organization = queryResult.result;
        setOrganization(org);
      } else {
        setError("Sorry, a problem occurred. Please go back and try again.");
      }
    }
    getOrganization();
  }, [match, match.params.id]);

  return (
    <IonPage className="bg-light">
      <Header />
      <IonContent>
        <PageTitle title={organization ? organization.name : "Branding"} />
        <IonRow className="ion-justify-content-center">
          {error && <ErrorAlert width="12" error={error} />}
          <IonCol size="12">
            <IonCard mode="md" className="ion-padding bg-white">
              <IonCardTitle>Branding</IonCardTitle>
              <IonCardContent>
                {organization ?
                  <>
                    <EventOrganizationBranding organization={organization}/>
                  </>
                :
                  <p>Loading organization....</p>
                }
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default EventOrganizationBrandingPage;