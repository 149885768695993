import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../../../context/PersonContext";
import SelectDisciplines from "../../../../components/Discipline/SelectDisciplines";
import { EventDisciplineOption, formatDisciplinesForDisplay, getDisciplineOptionByName, getDisciplineOptionByValue } from "../../../../utilities/events/EventDiscipline";
import { Owner } from "../../../../models";
import { getOwnerByPersonId, updateOwner } from "../../../../utilities/owner/Owner";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import { updatePerson } from "../../../../utilities/person/Person";
import LocationForm from "../../../../components/Address/LocationForm";
import { UpdateOwnerInput, UpdatePersonInput } from "../../../../API";

const OwnerInfoPage: React.FC = () => {
    const user = useContext(PersonContext);

    const [currentOwner, setCurrentOwner] = useState<Owner | null | undefined>();
    const [disciplines, setDisciplines] = useState<EventDisciplineOption[] | null | undefined>();
    const [city, setCity] = useState<string | null | undefined>();
    const [provState, setProvState] = useState<string | null | undefined>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getOwner() {
            const queryResult = await getOwnerByPersonId(user.id);
            if (queryResult.isSuccess) {
                const owner: Owner = queryResult.result;
                setCurrentOwner(owner);
                if (owner.location) {
                    const addressArray = owner.location.split(",");
                    setCity(addressArray.length > 1 ? addressArray[0] : undefined);
                    setProvState(addressArray.length > 1 ? addressArray[1].trim() : addressArray[0]);
                }
            } else {
                setError("No owner found.");
            }
        }

        function getDisciplines() {
            let disciplines = [];
            if (user.disciplines) {
                const disciplineArray = formatDisciplinesForDisplay(user.disciplines);
                for (var i = 0; i < disciplineArray.length; i++) {
                    const discipline = disciplineArray[i];
                    const formattedOption = getDisciplineOptionByName(discipline);
                    disciplines.push(formattedOption);
                }
            } 
            setDisciplines(disciplines);
        }

        if (user && user.id) {
            getOwner();
            getDisciplines();
        }
    }, [user]);

    const handleDisciplinesSelection = async (value: string[]) => {
        if (value) {
            let displayString = "";
            let optionArray: EventDisciplineOption[] = [];
            for (var i = 0; i < value.length; i++) {
                displayString = displayString + value[i] + ";";
                const option = getDisciplineOptionByValue(value[i]);
                optionArray.push(option);
            }
            const personInput: UpdatePersonInput = {
                id: user.id,
                disciplines: displayString
            };
            await updatePerson(personInput);
            setDisciplines(optionArray);
        }
    }

    const handleLocationSelection = async (city: string, state: string) => {
        const locationValue = city + ", " + state;
        if (currentOwner) {
            const input: UpdateOwnerInput = {
                id: currentOwner.id,
                location: locationValue
            }
            const updateResult = await updateOwner(input);
            if (!updateResult) {
                setError("Could not update the owner's location.");
            }
        } else {
            setError("Could not find the current owner.");
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Owner Info" />
                {error && <ErrorAlert error={error} />}
                <IonRow>
                    <IonCol size="12" sizeMd="6">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                Your Location
                            </IonCardTitle>
                            <IonCardContent className="ion-margin">
                                <LocationForm selectedCity={city} selectedProvState={provState} onSubmit={handleLocationSelection} />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    <IonCol size="12" sizeMd="6">
                        <IonCard color="white" className="ion-padding">
                            <IonCardTitle>
                                Your Disciplines
                            </IonCardTitle>
                            <IonCardContent className="ion-margin">
                                <SelectDisciplines selectedDisciplines={disciplines} onSelect={handleDisciplinesSelection} />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default OwnerInfoPage;