import { FormattedStablingRequest } from "../../interfaces/Stabling";
import { BeddingRequest, Event, EventEntry, EventEntryFee, EventInvoice, EventResult, StablingRequest } from "../../models";
import { getBeddingRequestsByEntryId } from "../beddingRequest/BeddingRequest";
import { getEventClassEntriesByEventIdEntryId } from "../eventClassEntry/EventClassEntry";
import { formattedOptionGroup, getFormattedEntryClassList } from "../eventClassEntry/FormattedEventEntryClasses";
import { getEventEntryFeesByEventIdByEntryId } from "../eventEntryFee/EventEntryFee";
import { isAfterEventFeeStartDate } from "../eventEntryFee/StartDateEventFee";
import { getEventInvoicesByEventIdEntryId } from "../eventInvoice/EventInvoice";
import { getEventResultsByEntryId } from "../eventResult/EventResult";
import { getEventById } from "../events/Event";
import { formatStablingRequests } from "../stablingRequest/FormatStablingRequests";
import { getStablingRequestsByEventId } from "../stablingRequest/StablingRequest";

export interface EventEntryInvoiceInterface {
    entryFeeTotal?: number
    eventFeeTotal?: number
    stablingFeeTotal?: number
    beddingFeeTotal?: number
    feeTotal?: number
    prizeMoneyTotal?: number
    paymentsTotal?: number
    amountOwed?: number
    paymentMethodsUsed?: string
    paymentNote?: string
}

async function getEntryFeeCost(entry: EventEntry, event?: Event): Promise<number> {
    let totalFeeCost = 0;
    let formattedClassData: (formattedOptionGroup[] | undefined) = undefined;
    const formattedClassDataResult = await getFormattedEntryClassList(entry, true);
    if (formattedClassDataResult.isSuccess) {
        formattedClassData = formattedClassDataResult.result.classList;
    }
    if (formattedClassData && formattedClassData.length > 0) {
        for (let i = 0; i < formattedClassData.length; i++) {
            const currentClassData = formattedClassData[i];
            let classTax = event?.invoiceOptions?.classFeeTax;
            if (currentClassData.division) {
                const division = currentClassData.division;
                const total = division.entryFee + division.entryFee * (classTax ? classTax/100 : 0);
                totalFeeCost = totalFeeCost + total;
            } else if (currentClassData.class) {
                const c = currentClassData.class;
                const total = c.entryFee + c.entryFee * (classTax ? classTax/100 : 0);
                totalFeeCost = totalFeeCost + total;
            }
        };
    }
    return totalFeeCost;
}

async function getEventFeeCost(entry: EventEntry): Promise<number> {
    let totalFeeCost = 0;

    let previousEventEntryFees: EventEntryFee[] | null | undefined = undefined;
    const queryResult = await getEventEntryFeesByEventIdByEntryId(entry.eventId, entry.id);
    if (queryResult.isSuccess) {
        previousEventEntryFees = queryResult.result;
    }

    if (previousEventEntryFees && previousEventEntryFees.length > 0) {
        for (let i = 0; i < previousEventEntryFees.length; i++) {
            const currentEventEntryFee = previousEventEntryFees[i];
            if (currentEventEntryFee.fee && currentEventEntryFee.fee.isStartedOnEntryDate) {
                const isAfterFeeStartDate = isAfterEventFeeStartDate(currentEventEntryFee);
                if (!isAfterFeeStartDate) continue;
            }
            let quantity = currentEventEntryFee.quantity || 1;
            if (currentEventEntryFee.fee && currentEventEntryFee.fee.isPerClass) {
                const classQueryResult = await getEventClassEntriesByEventIdEntryId(entry.eventId, entry.id);
                if (classQueryResult.isSuccess) {
                    const classList = classQueryResult.result;
                    quantity = classList.length;
                }
            }
            const tax = currentEventEntryFee.fee?.taxA || 0;
            const taxPercentage = tax/100;
            const total = (currentEventEntryFee.amount + (currentEventEntryFee.amount*taxPercentage)) * quantity;
            totalFeeCost = totalFeeCost + total;
        };
    }

    return totalFeeCost;
}

async function getStablingFeeCost(entry: EventEntry): Promise<number> {
    let totalFeeCost = 0;

    let stablingRequests: StablingRequest[] = [];
    let formattedStablingRequests: FormattedStablingRequest[] = [];
    const queryStablingResult = await getStablingRequestsByEventId(entry.eventId);
    if (queryStablingResult.isSuccess) {
        const allStablingRequests = queryStablingResult.result;
        allStablingRequests.forEach((sr: StablingRequest) => {
            const currentEntries = sr.entryIds;
            if (currentEntries && currentEntries.length) {
                currentEntries.forEach(currentEntry => {
                    if (currentEntry === entry.id) stablingRequests.push(sr);
                });
            }
        });
    }

    if (stablingRequests) {
        formattedStablingRequests = await formatStablingRequests(stablingRequests);
    }

    if (formattedStablingRequests) {
        for (let j = 0; j < formattedStablingRequests.length; j++) {
            const currentStablingRequest = formattedStablingRequests[j];
            const total = parseFloat(currentStablingRequest.totalPrice as any as string);
            if (currentStablingRequest.totalPrice) {
                totalFeeCost = totalFeeCost + total;
            }
        }
    }

    return totalFeeCost;
}

async function getBeddingFeeCost(entry: EventEntry): Promise<number> {
    let totalFeeCost = 0;

    let beddingRequests: BeddingRequest[] = [];
    const beddingQueryResult = await getBeddingRequestsByEntryId(entry.id);
    if (beddingQueryResult.isSuccess) {
        beddingRequests = beddingQueryResult.result;
    }

    if (beddingRequests) {
        for (let j = 0; j < beddingRequests.length; j++) {
            const currentBeddingRequest = beddingRequests[j];
            const quantity = ((parseInt(currentBeddingRequest.quantityNeeded as any as string) || 1) / (currentBeddingRequest.entries?.length || 1));
            const total = parseFloat(currentBeddingRequest.basePrice as any as string) * quantity;
            if (currentBeddingRequest.totalPrice) {
                totalFeeCost = totalFeeCost + total;
            }
        }
    }

    return totalFeeCost;
}

async function getPrizeMoneyWon(entry: EventEntry): Promise<number> {
    let totalPrizeMoney = 0;

    let eventResults: EventResult[] = [];
    const eventResultsResult = await getEventResultsByEntryId(entry.id);
    if (eventResultsResult.isSuccess) {
        eventResults = eventResultsResult.result;
    }

    for (let k = 0; k < eventResults.length; k++) {
        const currentResult = eventResults[k];
        totalPrizeMoney = totalPrizeMoney + (currentResult.prizeMoney || 0);
    }

    return totalPrizeMoney;
}

async function getEntryPayments(entry: EventEntry) {
    let totalPaymentAmount = 0;
    let paymentMethodsUsed = "";
    let paymentNote = "";

    const queryResult = await getEventInvoicesByEventIdEntryId(entry.eventId, entry.id);
    if (queryResult.isSuccess) {
        const invoiceRecords = queryResult.result;
        if (invoiceRecords && invoiceRecords.length > 0) {
            for (let i = 0; i < invoiceRecords.length; i++) {
                const invoice: EventInvoice = invoiceRecords[i];
                if (invoice) {
                    totalPaymentAmount = totalPaymentAmount + (invoice?.amountToDeductFromInvoice || 0);
                    paymentMethodsUsed = paymentMethodsUsed + (invoice.payerType ? invoice.payerType : "No_Method_Found") + "; ";
                    paymentNote = paymentNote + (invoice.statusNote ? invoice.statusNote : "No_Note_Found") + "; ";
                }
            }
        }
    }

    const result = {
        paymentMethodsUsed: paymentMethodsUsed,
        paymentNote: paymentNote,
        totalPaymentAmount: totalPaymentAmount
    };
    return result;
}

/**
 * Calculates the total values for the fees, prize money and payments for an entry invoice.
 * Note: uses the onlyIncludeAcceptedClasses flag to get EventEntryClasses
 * @param entry 
 * @param event (optional)
 * @returns EventEntryInvoiceInterface
 */
export async function getEventEntryInvoiceData(entry: EventEntry, event?: Event) {
    // First, get the event
    if (!event) {
        const eventQueryResult = await getEventById(entry.eventId);
        if (eventQueryResult.isSuccess) {
            event = eventQueryResult.result;
        }
    }
    
    // Next, get all of the numbers needed for the entry invoice    
    const totalEntryFeeCost = await getEntryFeeCost(entry, event);
    
    const totalEventFeeCost = await getEventFeeCost(entry);

    const totalStablingFeeCost = await getStablingFeeCost(entry);

    const totalBeddingFeeCost = await getBeddingFeeCost(entry);

    const feeTotal = totalEntryFeeCost + totalEventFeeCost + totalStablingFeeCost + totalBeddingFeeCost;

    const totalPrizeMoney = await getPrizeMoneyWon(entry);

    const result = await getEntryPayments(entry);
    const totalAmountPaid = result.totalPaymentAmount;
    const paymentMethodsUsed = result.paymentMethodsUsed;
    const paymentNote = result.paymentNote;

    const amountOwed = feeTotal - totalPrizeMoney - totalAmountPaid;

    // Next, set up the EventEntryInvoiceInterface object
    const resultObject: EventEntryInvoiceInterface = {
        entryFeeTotal: totalEntryFeeCost,
        eventFeeTotal: totalEventFeeCost,
        stablingFeeTotal: totalStablingFeeCost,
        beddingFeeTotal: totalBeddingFeeCost,
        feeTotal: feeTotal,
        prizeMoneyTotal: totalPrizeMoney,
        paymentsTotal: totalAmountPaid,
        amountOwed: amountOwed,
        paymentMethodsUsed: paymentMethodsUsed,
        paymentNote: paymentNote
    };

    return resultObject;
}