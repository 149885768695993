import { IonAlert, IonButton, IonIcon, IonLabel, isPlatform } from "@ionic/react";
import React, { useState } from "react";
import { add } from "ionicons/icons";
import { Organization } from "../../models";
import { useHistory } from "react-router";
import { isWindows } from "../../utilities/platform/Platform";

interface _Props {
    organization: Organization;
}

const CreatePostButton: React.FC<_Props> = ({organization}) => {

    const history = useHistory();
    const [showAlert, setShowAlert] = useState(false);

    const onCreatePostHandler = () =>{
       if(!organization) {
            setShowAlert(true);
            return;
       }
       history.push(`/index/staff/organization/post/create/${organization.id}`)
    }

    return (
        <React.Fragment>
            <IonButton 
                onClick={onCreatePostHandler}
            >
                <IonIcon icon={add} />
                {(isWindows() || (isPlatform("desktop"))) && <IonLabel>Add Post</IonLabel>}
            </IonButton>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={"Post Not Found"}
                message={"Before creating an post, finish setting up an organization."}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => {
                            history.push("/index/staff/organization/posts/" + organization?.id);
                        }
                    },
                    {
                        text: 'Set Up',
                        handler: () => {
                            history.push("/index/staff/organization");
                        }
                    }
                ]}
            />
        </React.Fragment>
    );
};

export default CreatePostButton;
