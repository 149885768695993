import { API } from 'aws-amplify';
import { Result } from "../../interfaces/Result";
import { formatTwilioNumber } from '../contact/FormatPhoneNumber';
import { canadianAreaCodes, getAreaCode } from './AreaCodes';

/**
 * Check a phone number's data using Twilio
 * More details here: https://www.twilio.com/docs/lookup/api
 * 
 * @param {string} phoneNumber 
 * @param {string} isTest 
 */
export async function lookupPhoneNumber(phoneNumber: string, isTest?: boolean) {
    try {
        if (isTest) {
            return {isSuccess: true, type: "SMS", result: {type: "mobile"}, message: "Successfully looked up the phone number."};
        }

        const areaCode = getAreaCode(phoneNumber);
        if (areaCode && canadianAreaCodes.includes(areaCode)) {
            return {isSuccess: true, type: "SMS", result: {type: "mobile"}, message: "Successfully looked up the Canadian phone number."};
        }

        const data = {
            body: {
                "number": formatTwilioNumber(phoneNumber)
            }
        };

        const path = "/sms/lookup";

        const response = await API.post('TwilioAPI', path, data);
        const responseBody = JSON.parse(response.body);
        const carrierInfo = responseBody.carrier;

        if (carrierInfo) {
            let result: Result = {isSuccess: true, type: "SMS", result: carrierInfo, message: "Successfully looked up the phone number."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "SMS", result: carrierInfo, message: "Could not look up the phone number."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "SMS", result: error, message: "Error occurred while trying to look up the phone number."};
        return result; 
    }
}