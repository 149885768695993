import React, { useState } from "react";
import Spinner from "../Spinners/Spinner";
import ErrorAlert from "../Errors/ErrorAlert";
import { isWindows } from "../../utilities/platform/Platform";
import { formatStartEndDatesStrings } from "../../utilities/dates/FormatDates";
import { Season } from "../../models";
import DataTable from "react-data-table-component";
import { trashOutline, createOutline, close } from "ionicons/icons";
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from "@ionic/react";
import CONSTANTS from "../../constant/constant";
import { deleteSeason, getSeasonsByOrganizationId } from "../../utilities/seasons/Seasons";
import { useHistory } from "react-router";

interface _Props {
    seasons: Season[];
    setSeasons: Function;
}

const DisplaySeasonTable: React.FC<_Props> = ({ seasons, setSeasons }) => {

    const columns = [{
        id: 1,
        name: "Season Name",
        selector: (row: Season) => row.name,
        width: isWindows() || isPlatform("desktop") ? "30%" : "25%",
    }, {
        id: 2,
        name: "Start Date",
        selector: (row: Season) => formatStartEndDatesStrings(row.startDate),
        center: true
    }, {
        id: 3,
        name: "End Date",
        selector: (row: Season) => formatStartEndDatesStrings(row.endDate),
        center: true
    }, {
        id: 4,
        name: "Status",
        center: true,
        cell: (row: Season) =>
            row.isActive ? (
                <IonText color="success">{CONSTANTS.SEASON_STATUS.ACTIVE}</IonText>
            ) : (
                <IonText color="danger">{CONSTANTS.SEASON_STATUS.IN_ACTIVE}</IonText>
            )
    }, {
        name: "Edit",
        center: true,
        button: true,
        cell: (row: Season) => 
            isWindows() || isPlatform("desktop") ? (
                <IonButton
                    onClick={() => handleEditClick(row.id)}
                    color="tertiary"
                    className="edit-season-btn"
                >
                    Edit
                </IonButton>
            ) : (
                <IonIcon
                    size="large"
                    slot="start"
                    icon={createOutline}
                    color="tertiary"
                    onClick={() => handleEditClick(row.id)}
                />
            )
        
    }, {
        name: "Remove",
        center: true,
        button: true,
        cell: (row: Season) => 
            isWindows() || isPlatform("desktop") ? (
                <IonButton
                    className="remove-season-btn"
                    onClick={() => handleRemoveClick(row)}
                    color="danger"
                >
                    Remove
                </IonButton>
            ) : (
                <IonIcon
                    size="large"
                    slot="start"
                    icon={trashOutline}
                    color="danger"
                    onClick={() => handleRemoveClick(row)}
                />
            )
        
    }];

    const tableCustomStyles = {
        headCells: {
            style: {
                fontSize: "14px",
                fontWeight: "bold",
                width: "9rem"
            }
        }
    };

    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [seasonsData, setSeasonsData] = useState<Season>();

    const handleEditClick = (id: string) => {
        history.push("/index/staff/organization/org-seasons/edit/" + id);
    };

    const handleRemoveClick = async (row: Season) => {
        setShowModal(true);
        setSeasonsData(row);
    };

    const handleDeleteSeason = async () => {
        setError("");
        setIsLoading(true);
        if (seasonsData?.id) {
            const seasonId = {
                id: seasonsData.id,
            };
            const deleteSeasonResult = await deleteSeason(seasonId);
            if (deleteSeasonResult.isSuccess) {
                if (seasonsData?.organizationId) {
                    const season = await getSeasonsByOrganizationId(
                        seasonsData.organizationId
                    );
                    setSeasons(season.result);
                }
                setError("");
            } else {
                setError(deleteSeasonResult.message);
            }
        }
        setShowModal(false);
        setIsLoading(false);
    };

    return (
        <React.Fragment>
             <IonModal backdropDismiss={false} isOpen={showModal}>
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">Remove Season</IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            id="delete-season-modal-close-icon"
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p
                                id="closeSeasonModalBtn"
                                className="font-weight-normal text-medium text-capitalize"
                            >
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <h3>
                                Are you sure you want to permanently remove this
                                season:{" "}
                            </h3>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10" className="ion-text-center">
                            <h2>{seasonsData?.name}</h2>
                            <IonText>
                                Created On{" "}
                                {formatStartEndDatesStrings(
                                    seasonsData?.createdOn
                                )}
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonContent>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <IonRow className="ion-justify-content-center ion-padding-top">
                        <IonCol size="6" className="ion-text-center">
                            <IonButton
                                id="delete-season-modal-cancel-btn"
                                expand="block"
                                color="light"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </IonButton>
                        </IonCol>
                        <IonCol size="6" className="ion-text-center">
                            <IonButton
                                id="delete-season-modal-delete-btn"
                                expand="block"
                                color="danger"
                                onClick={handleDeleteSeason}
                            >
                                Remove
                            </IonButton>
                        </IonCol>
                    </IonRow>
                )}
            </IonModal>
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    {seasons && seasons.length ? (
                        <DataTable
                            columns={columns}
                            data={seasons}
                            pagination
                            customStyles={tableCustomStyles}
                            highlightOnHover
                        />
                    ) : (
                        <IonText>No season data yet</IonText>
                    )}
                </>
            )}
        </React.Fragment>
    );
};

export default DisplaySeasonTable;
