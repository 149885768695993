/**
 * This file contains the functionality for a layout: DashboardLayout
 * 
 * Using layouts for categories of pages creates 
 * - consistency 
 * - easy maintenance
 * 
 * The Dasboard layout is used for the pages you have to be authenticated to see:
 *  - Examples: Profile, Settings, Log Out...
 */
import {IonCol, IonRouterOutlet, IonRow, IonSplitPane} from "@ionic/react";
import {Redirect, Route, RouteComponentProps, withRouter} from "react-router-dom";
import {Role, blankRoles} from "../utilities/roles/Roles";
import {PersonContext, defaultUserContextValue, blankPerson} from "../context/PersonContext";
import {blankOrganization, OrganizationContext} from "../context/OrganizationContext";
import AdminDashboardPage from "../pages/dashboard/roles/admin/AdminDashboardPage";
import AdminPeoplePage from "../pages/dashboard/roles/admin/AdminPeoplePage";
import AllEventsPage from "../pages/dashboard/roles/eventStaff/events/AllEventsPage";
import AuthRoutes from "../routing/AuthRoutes";
import {CognitoUser} from "../interfaces/CognitoUser";
import CreateEventOrganizationPage from "../pages/dashboard/roles/eventStaff/eventOrganization/CreateEventOrganizationPage";
import CreateEventPage from "../pages/dashboard/roles/eventStaff/events/CreateEventPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import {API, graphqlOperation} from "aws-amplify";
import EditAccountPage from "../pages/dashboard/settings/EditAccountPage";
import EditContactPage from "../pages/dashboard/settings/EditContactPage";
import EditSecurityPage from "../pages/dashboard/settings/EditSecurityPage";
import EventClassesPage from "../pages/dashboard/roles/eventStaff/events/EventClassesPage";
import EventFinancesPage from "../pages/dashboard/roles/eventStaff/eventFinancial/EventFinancesPage";
import EventOrganizationAddressesPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationAddressesPage";
import EventOrganizationBasicInfoPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationBasicInfoPage";
import EventOrganizationBasicSettingsPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationBasicSettingsPage";
import EventOrganizationBrandingPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationBrandingPage";
import EventOrganizationMembersPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationMembersPage";
import EventOrganizationPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationPage";
import Menu from "../components/Menus/Menu";
import {Person, Organization} from "../models";
import ProfilePage from "../pages/dashboard/ProfilePage";
import React from "react";
import SettingsPage from "../pages/dashboard/SettingsPage";
import Spinner from "../components/Spinners/Spinner";
import {formatRolesForDisplay} from "../utilities/roles/FormatRoles";
import {getUser, getUserAttributes} from "../utilities/user/User";
import EventAboutPage from "../pages/dashboard/roles/eventStaff/events/EventAboutPage";
import EventSchedulePage from "../pages/dashboard/roles/eventStaff/events/EventSchedulePage";
import EventAlertsPage from "../pages/dashboard/roles/eventStaff/events/EventAlertsPage";
import EventResultsPage from "../pages/dashboard/roles/eventStaff/events/EventResultsPage";
import EventReportsPage from "../pages/dashboard/roles/eventStaff/events/EventReportsPage";
import EventParticipantsPage from "../pages/dashboard/roles/eventStaff/events/EventParticipantsPage";
import AdminEventManagementPage from "../pages/dashboard/roles/admin/AdminEventManagementPage";
import AdminOrganizationManagementPage from "../pages/dashboard/roles/admin/AdminOrganizationManagementPage";
import EventOrganizationEventSettingsPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationEventSettingsPage";
import OrganizationDivisionsPage from "../pages/dashboard/roles/eventStaff/eventOrganization/OrganizationEventSettings/OrganizationDivisionsPage";
import OrganizationClassesPage from "../pages/dashboard/roles/eventStaff/eventOrganization/OrganizationEventSettings/OrganizationClassesPage";
import OrganizationPrizeMoneyTemplatesPage from "../pages/dashboard/roles/eventStaff/eventOrganization/OrganizationEventSettings/OrganizationPrizeMoneyTemplatesPage";
import OrganizationStallsPage from "../pages/dashboard/roles/eventStaff/eventOrganization/OrganizationEventSettings/OrganizationStallsPage";
import ViewEntryPage from "../components/Entry/EntryPage/ViewEntryPage";
import EventSelectionPage from "../pages/dashboard/events/EventSelectionPage";
import EventViewInfoPage from "../pages/dashboard/events/EventViewInfoPage";
import EventViewSchedulePage from "../pages/dashboard/events/EventViewSchedulePage";
import EventViewEntriesPage from "../pages/dashboard/events/EventViewEntriesPage";
import RiderProfilePage from "../pages/dashboard/roles/rider/RiderProfilePage";
import RiderInfoPage from "../pages/dashboard/roles/rider/RiderInfoPage";
import RiderMembershipsPage from "../pages/dashboard/roles/rider/RiderMembershipsPage";
import RiderCalendarPage from "../pages/dashboard/roles/rider/RiderCalendarPage";
import RiderHorsesPage from "../pages/dashboard/roles/rider/RiderHorsesPage";
import OwnerProfilePage from "../pages/dashboard/roles/owner/OwnerProfilePage";
import OwnerInfoPage from "../pages/dashboard/roles/owner/OwnerInfoPage";
import OwnerMembershipsPage from "../pages/dashboard/roles/owner/OwnerMembershipsPage";
import OwnerCalendarPage from "../pages/dashboard/roles/owner/OwnerCalendarPage";
import OwnerHorsesPage from "../pages/dashboard/roles/owner/OwnerHorsesPage";
import TrainerProfilePage from "../pages/dashboard/roles/trainer/TrainerProfilePage";
import TrainerInfoPage from "../pages/dashboard/roles/trainer/TrainerInfoPage";
import TrainerMembershipsPage from "../pages/dashboard/roles/trainer/TrainerMembershipsPage";
import TrainerCalendarPage from "../pages/dashboard/roles/trainer/TrainerCalendarPage";
import BarnHorsesPage from "../pages/dashboard/roles/barn/BarnHorsesPage";
import BarnPage from "../pages/dashboard/roles/barn/BarnPage";
import BarnBasicInfoPage from "../pages/dashboard/roles/barn/BarnBasicInfo";
import CreateBarnPage from "../pages/dashboard/roles/barn/CreateBarnPage";
import BarnPeoplePage from "../pages/dashboard/roles/barn/BarnPeoplePage";
import * as subscriptions from "../graphql/subscriptions";
import { Observable } from "zen-observable-ts";
import { getPersonByPersonId } from "../utilities/person/Person";
import LibraryPage from "../pages/dashboard/library/LibraryPage";
import PreviousSubmissionsPage from "../pages/dashboard/clinics/PreviousSubmissionsPage";
import PersonPage from "../pages/dashboard/roles/admin/PeopleManagementPages/PersonPage";
import ClinicianPage from "../pages/dashboard/roles/judge/ClinicianPage";
import ViewSubmissionFeedbackPage from "../pages/dashboard/clinics/ViewSubmissionFeedbackPage";
import EventViewAlertsPage from "../pages/dashboard/events/EventViewAlertsPage";
import OrganizationTaxesPage from "../pages/dashboard/roles/eventStaff/eventOrganization/OrganizationEventSettings/OrganizationTaxesPage";
import AdminCreditManagementPage from "../pages/dashboard/roles/admin/AdminCreditManagementPage";
import EventViewResultsPage from "../pages/dashboard/events/EventViewResultsPage";
import EditHorsePage from "../pages/dashboard/roles/barn/EditHorsePage";
import NewHorsePage from "../pages/dashboard/roles/barn/NewHorsePage";
import EditBarnPersonPage from "../pages/dashboard/roles/barn/EditBarnPersonPage";
import NewSubmissionsPage from "../pages/dashboard/clinics/NewSubmissionsPage";
import AdminClinicManagementPage from "../pages/dashboard/roles/admin/AdminClinicManagementPage";
import EditClinicianPage from "../pages/dashboard/roles/admin/ClinicsManagement/EditClinicianPage";
import SuccessSubmissionPage from "../pages/dashboard/clinics/SuccessSubmissionPage";
import ClinicianVideoPage from "../pages/dashboard/roles/judge/ClinicianVideoPage";
import ClinicianVideoFeedbackPage from "../pages/dashboard/roles/judge/ClinicianVideoFeedbackPage";
import ClinicianStatusPage from "../pages/dashboard/roles/judge/ClinicianStatusPage";
import ClinicianProfilePage from "../pages/dashboard/roles/judge/ClinicianProfilePage";
import ClinicianPaymentPage from "../pages/dashboard/roles/judge/ClinicianPaymentPage";
import ClinicianStripeSuccessPage from "../pages/dashboard/roles/judge/ClinicianStripeSuccessPage";
import ViewClinicianPage from "../pages/dashboard/roles/admin/ClinicsManagement/ViewCliniciansPage";
import ViewPaymentsPage from "../pages/dashboard/roles/admin/ClinicsManagement/ViewPaymentsPages";
import TestClinicDataPage from "../pages/dashboard/roles/admin/TestDataPages/TestClinicDataPage";
import EventMenuPage from "../pages/dashboard/roles/eventStaff/events/EventMenuPage";
import EventFeesPage from "../pages/dashboard/roles/eventStaff/events/EventFeesPage";
import EventClassPage from "../pages/dashboard/roles/eventStaff/events/EventClassPage";
import EventClassCreatePage from "../pages/dashboard/roles/eventStaff/events/EventClassCreatePage";
import EventDivisionsEditPage from "../pages/dashboard/roles/eventStaff/events/EventDivisionsEditPage";
import EventDivisionEditPage from "../pages/dashboard/roles/eventStaff/events/EventDivisionEditPage";
import EventDivisionCreatePage from "../pages/dashboard/roles/eventStaff/events/EventDivisionCreatePage";
import EventBulkAddSubscribersPage from "../pages/dashboard/roles/eventStaff/events/EventBulkAddSubscribers";
import EventEditEntryPage from "../pages/dashboard/events/EventEditEntryPage";
import EventCreateEntryPage from "../pages/dashboard/events/EventCreateEntryPage";
import EventNewEntryPage from "../pages/dashboard/events/EventNewEntryPage";
import NewBarnPersonPage from "../pages/dashboard/roles/barn/NewBarnPersonPage";
import SubscriberEventsPage from "../pages/dashboard/events/Alerts/SubscriberEventsPage";
import EditSubscriberPage from "../pages/dashboard/events/Alerts/EditSubscriberPage";
import UserEntriesPage from "../pages/dashboard/events/Entries/UserEntriesPage";
import EventSettingsMenuPage from "../pages/dashboard/roles/eventStaff/events/EventSettingsMenu";
import EventTextAlertSettingsPage from "../pages/dashboard/roles/eventStaff/events/SettingsPages/EventTextAlertSettingsPage";
import EventStallSettingsPage from "../pages/dashboard/roles/eventStaff/events/SettingsPages/EventStallSettingsPage";
import EventAffiliationSettingsPage from "../pages/dashboard/roles/eventStaff/events/SettingsPages/EventAffiliationSettingsPage";
import EventEntryReqsSettingsPage from "../pages/dashboard/roles/eventStaff/events/SettingsPages/EventEntryReqsSettings";
import ViewBarnPage from "../pages/dashboard/roles/barn/ViewBarnPage";
import RiderNewHorsePage from "../pages/dashboard/roles/rider/RiderNewHorsePage";
import EventRulesSettingsPage from "../pages/dashboard/roles/eventStaff/events/SettingsPages/EventRulesSettingsPage";
import OwnerNewHorsePage from "../pages/dashboard/roles/owner/OwnerNewHorsePage";
import AdminBarnManagementPage from "../pages/dashboard/roles/admin/AdminBarnManagementPage";
import BarnManagementPage from "../pages/dashboard/roles/admin/BarnManagementPage";
import EventDuplicateSettingsPage from "../pages/dashboard/roles/eventStaff/events/SettingsPages/EventDuplicateSettingsPage";
import EventResourcesPage from "../pages/dashboard/roles/eventStaff/events/EventResourcesPage";
import BarnSettingsPage from "../pages/dashboard/roles/barn/BarnSettingsPage";
import StripeDeleteEventPage from "../pages/dashboard/roles/eventStaff/events/StripeDeleteEventPage";
import AddBarnPage from "../pages/dashboard/roles/barn/AddBarnPage";
import OrganizationPointsTemplatesPage from "../pages/dashboard/roles/eventStaff/eventOrganization/OrganizationEventSettings/OrganizationPointsTemplatesPage";
import EventQuickAddEntryPage from "../pages/dashboard/roles/eventStaff/events/EventQuickAddEntryPage";
import EventAddClassResultsPage from "../pages/dashboard/roles/eventStaff/events/EventAddClassResultsPage";
import EventAddDivisionResultsPage from "../pages/dashboard/roles/eventStaff/events/EventAddDivisionResultsPage";
import EventOrganizationMembersCreatePage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationMembersCreatePage";
import OrganizationDivisionTemplatesPage from "../pages/dashboard/roles/eventStaff/eventOrganization/OrganizationEventSettings/OrganizationDivisionTemplatesPage";
import EventCreateEntriesFromTemplatePage from "../pages/dashboard/events/EventCreateEntriesFromTemplatePage";
import EventOrganizationMemberSettingsPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationMemberSettingsPage";
import EventOrganizationAdminMembersPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationAdminMembersPage";
import EventOrganizationPointSettingsPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationPointSettingsPage";
import EventOrganizationUploadPointDataPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationUploadPointDataPage";
import EventOrganizationViewPointsPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationViewPointsPage";
import EventOrganizationViewDivisionPointsPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationViewDivisionPointsPage";
import EventOrganizationViewClassPointsPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationViewClassPointsPage";
import EventOrganizationViewMemberPointsPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationViewMemberPointsPage";
import EventOrganizationEntriesPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationEntriesPage";
import EventOrganizationTextAlertsPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationTextAlertsPage";
import EventOrganizationDownloadPointReportsPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationDownloadPointReportsPage";
import EventOrganizationDeletePage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationDeletePage";
import OrganizationDashboardPage from "../pages/dashboard/organizationDashboard/OrganizationDashboardPage";
import EventOrganizationPostInfoPage from "../pages/dashboard/roles/eventStaff/posts/EventOrganizationPostInfoPage";
import CreatePostPage from "../pages/dashboard/roles/eventStaff/posts/CreatePostPage";
import EditPostPage from "../pages/dashboard/roles/eventStaff/posts/EditPostPage";
import OrganizationSeasonPage from "../pages/dashboard/roles/eventStaff/seasons/OrganizationSeasonPage";
import CreateSeasonPage from "../pages/dashboard/roles/eventStaff/seasons/CreateSeasonPage";
import EditSeasonPage from "../pages/dashboard/roles/eventStaff/seasons/EditSeasonPage";
import EventOrganizationMembershipTypesInfoPage from "../pages/dashboard/roles/eventStaff/eventOrganization/MembershipType/EventOrganizationMembershipTypesInfoPage";
import CreateMembershipTypePage from "../pages/dashboard/roles/eventStaff/eventOrganization/MembershipType/CreateMembershipTypePage";
import EditMembershipTypePage from "../pages/dashboard/roles/eventStaff/eventOrganization/MembershipType/EditMembershipTypePage";
import MembershipApplicationsPage from "../pages/dashboard/applications/MembershipApplicationsPage";
import EventOrganizationMemberEditPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationMemberEditPage"
import ViewAllMembershipsPage from "../pages/dashboard/applications/ViewAllMembershipsPage";
import EventParticipantsContactListPage from "../pages/dashboard/roles/eventStaff/events/EventParticipantContactListPage";
import TeamPage from "../pages/dashboard/roles/team/TeamPage";
import TeamRosterPage from "../pages/dashboard/roles/team/TeamRosterPage";
import TeamCoachesPage from "../pages/dashboard/roles/team/TeamCoachesPage";
import EventJudgeGamePage from "../pages/dashboard/events/JudgeGame/EventJudgeGamePage";
import EventJudgeGameMasterPage from "../pages/dashboard/roles/eventStaff/events/EventJudgeGamePage";
import WelcomeModal from "../components/Modals/WelcomeModal";
import ViewTeamMemberPage from "../pages/dashboard/roles/team/ViewTeamMemberPage";
import EventOrganizationMembersBulkAddPage from "../pages/dashboard/roles/eventStaff/eventOrganization/EventOrganizationMembersBulkAddPage";

interface IProps {
    organization?: Organization
}

type DashboardLayoutProps = IProps & RouteComponentProps<any>;

interface IState {
    organization: Organization,
    user: CognitoUser,
    isLoading: boolean,
    person: Person,
    roles: Role[],
    path: String
}

let subscription: any; 

class DashboardLayout extends React.Component<DashboardLayoutProps, IState> {

    constructor(props: DashboardLayoutProps) {
        super(props);
        this.state = {
            isLoading: true,
            user: defaultUserContextValue,
            organization: this.props.organization ?? blankOrganization,
            person: blankPerson,
            roles: blankRoles,
            path: "/index/home"
        };
        this.setOrganization = this.setOrganization.bind(this);
    }

    async componentDidMount() {
        await this.checkForUser();
        const subscription = API.graphql(graphqlOperation(subscriptions.onUpdatePerson));
        if (subscription instanceof Observable) {
            subscription.subscribe({
                next: (data: any) => {
                    const updatedPerson = data.value.data.onUpdatePerson;
                    if (updatedPerson.id === this.state.person.id) this.getPerson(updatedPerson.id);
                }
            });
        }
    }

    async componentDidUpdate(prevProps:any) {
        if (this.props !== prevProps) {
            if (!this.state.user) await this.checkForUser();
        }
    }

    async checkForUser() {
        const user = await getUser();
        if (user && user.username) {
            if (user && user.attributes && user.attributes["custom:personid"]) {
                const userAttributes = user.attributes;
                const personId = userAttributes["custom:personid"];
                if (personId) {
                    await this.getPerson(personId);
                }
            } else {
                const userAttributes = await getUserAttributes();
                if (userAttributes) {
                    const personId = userAttributes.attributes["custom:personid"];
                    if (personId) {
                        await this.getPerson(personId);
                    }
                }
            }
            await this.setState({user, isLoading: false});
        }
    }

    async setOrganization(organization: Organization) {
        if (organization) {
            await this.setState({organization});
        }
    }

    async getPerson(personId: string) {
        const queryResult = await getPersonByPersonId(personId);
        if (queryResult.isSuccess) {
            const person = queryResult.result;
            await this.setState({person});
            this.getRoles(person);
        }
    }

    async getRoles(person: Person) {
        if (person.roles) {
            const personRoles = person.roles;
            if (personRoles) {
                const roles = formatRolesForDisplay(personRoles);
                if (roles) this.setState({roles});
            }
        } else {
            const queryResult = await getPersonByPersonId(person.id);
            if (queryResult.isSuccess && queryResult.result.roles) {
                const roles = formatRolesForDisplay(queryResult.result.roles);
                if (roles) this.setState({roles});
            }
        }
    }

    componentWillUnmount() {
        if (subscription) subscription.unsubscribe();
    }

    render() {
        const {isLoading, person, roles, organization} = this.state;
        return (
            <React.Fragment>
                {isLoading ?
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <Spinner />
                        </IonCol>
                    </IonRow>
                    :
                    <OrganizationContext.Provider value={organization}>
                        <PersonContext.Provider value={person}>

                            <WelcomeModal openModal={!roles || roles.length === 0} />
                        
                            <IonSplitPane contentId="main">
                                <Menu roles={roles}/>

                                <IonRouterOutlet id="main">
                                    
                                    <Route path="/index/admin/dashboard" exact>
                                        <AdminDashboardPage />
                                    </Route>

                                    <Route path="/index/admin/test-data/clinics" exact>
                                        <TestClinicDataPage />
                                    </Route>

                                    <Route path="/index/admin/users" exact>
                                        <AdminPeoplePage />
                                    </Route>

                                    <Route path="/index/admin/users/:id" exact render={props => <PersonPage {...props} />} />

                                    <Route path="/index/admin/events" exact>
                                        <AdminEventManagementPage />
                                    </Route>

                                    <Route path="/index/admin/barns" exact>
                                        <BarnManagementPage />
                                    </Route>

                                    <Route path="/index/admin/barn/:id" exact render={props => <AdminBarnManagementPage {...props} />} />

                                    <Route path="/index/admin/orgs" exact>
                                        <AdminOrganizationManagementPage />
                                    </Route>

                                    <Route path="/index/admin/credits" exact>
                                        <AdminCreditManagementPage />
                                    </Route>

                                    <Route path="/index/admin/clinics" exact>
                                        <AdminClinicManagementPage />
                                    </Route>

                                    <Route path="/index/admin/clinicians" exact>
                                        <ViewClinicianPage />
                                    </Route>

                                    <Route path="/index/admin/clinicians/:id" exact render={props => <EditClinicianPage {...props} />} />

                                    <Route path="/index/admin/clinicians/payments" exact>
                                        <ViewPaymentsPage />
                                    </Route>

                                    <Route path="/index/events" exact>
                                        <EventSelectionPage />
                                    </Route>

                                    <Route path="/index/event/about/:id" exact render={props => <EventViewInfoPage {...props} />} />
                                    <Route path="/index/event/alerts/:id" exact render={props => <EventViewAlertsPage {...props} />} />
                                    <Route path="/index/event/results/:id" exact render={props => <EventViewResultsPage {...props} />} />
                                    <Route path="/index/event/schedule/:id" exact render={props => <EventViewSchedulePage {...props} />} />
                                    <Route path="/index/event/entries/:id" exact render={props => <EventViewEntriesPage {...props} />} />
                                    <Route path="/index/event/judge/:id" exact render={props => <EventJudgeGamePage {...props} />} />

                                    <Route path="/index/event/entry/:id" render={props => <EventEditEntryPage {...props} />} />
                                    <Route path="/index/event/:id/new-entry-from-template" render={props => <EventCreateEntriesFromTemplatePage {...props} />} />
                                    <Route path="/index/event/:id/new-entry" render={props => <EventCreateEntryPage {...props} />} />
                                    <Route path="/index/event/:id/new-entry-redesign" render={props => <EventNewEntryPage {...props} />} />

                                    
                                    <Route path="/index/alerts" exact>
                                        <SubscriberEventsPage />
                                    </Route>

                                    <Route path="/index/alerts/subscriber/:id" render={props => <EditSubscriberPage {...props} />} />

                                    <Route path="/index/entries" exact>
                                        <UserEntriesPage />
                                    </Route>

                                    <Route path="/index/staff/events" exact>
                                        <AllEventsPage />
                                    </Route>

                                    <Route path="/index/staff/event/:id/about" exact render={props => <EventAboutPage {...props} />} />

                                    <Route path="/index/staff/event/:id/classes" exact render={props => <EventClassesPage {...props} />} />

                                    <Route path="/index/staff/event/:id/class/:classId" exact render={props => <EventClassPage {...props} />} />

                                    <Route path="/index/staff/event/:id/class/new" exact render={props => <EventClassCreatePage {...props} />} />

                                    <Route path="/index/staff/event/:id/division/:divisionId" exact render={props => <EventDivisionEditPage {...props} />} />

                                    <Route path="/index/staff/event/:id/division/new" exact render={props => <EventDivisionCreatePage {...props} />} />

                                    <Route path="/index/staff/event/:id/divisions/edit" exact render={props => <EventDivisionsEditPage {...props} />} />

                                    <Route path="/index/staff/event/:id/fees" exact render={props => <EventFeesPage {...props} />} />

                                    <Route path="/index/staff/event/:id/schedule" exact render={props => <EventSchedulePage {...props} />} />

                                    <Route path="/index/staff/event/:id/participants" exact render={props => <EventParticipantsPage {...props} />} />

                                    <Route path="/index/staff/event/:id/participants/contact-list" exact render={props => <EventParticipantsContactListPage {...props} />} />

                                    <Route path="/index/staff/event/:id/quick-add-entry" exact render={props => <EventQuickAddEntryPage {...props} />} />

                                    <Route path="/index/staff/event/:id/alerts/:session_id" exact render={props => <EventAlertsPage {...props} />} />
                                    
                                    <Route path="/index/staff/event/:id/alerts" exact render={props => <EventAlertsPage {...props} />} />

                                    <Route path="/index/staff/event/:id/alerts/subscribers/add" exact render={props => <EventBulkAddSubscribersPage {...props} />} />

                                    <Route path="/index/staff/event/:id/results" exact render={props => <EventResultsPage {...props} />} />

                                    <Route path="/index/staff/event/:id/results/class/:classId" exact render={props => <EventAddClassResultsPage {...props} />} />

                                    <Route path="/index/staff/event/:id/results/division/:divisionId" exact render={props => <EventAddDivisionResultsPage {...props} />} />

                                    <Route path="/index/staff/event/:id/reports" exact render={props => <EventReportsPage {...props} />} />

                                    <Route path="/index/staff/event/:id/settings/alerts" exact render={props => <EventTextAlertSettingsPage {...props} />} />

                                    <Route path="/index/staff/event/:id/settings/duplicate" exact render={props => <EventDuplicateSettingsPage {...props} />} />

                                    <Route path="/index/staff/event/:id/settings/entries" exact render={props => <EventEntryReqsSettingsPage {...props} />} />

                                    <Route path="/index/staff/event/:id/settings/stalls" exact render={props => <EventStallSettingsPage {...props} />} />

                                    <Route path="/index/staff/event/:id/settings/rules" exact render={props => <EventRulesSettingsPage {...props} />} />

                                    <Route path="/index/staff/event/:id/settings/affiliations" exact render={props => <EventAffiliationSettingsPage {...props} />} />

                                    <Route path="/index/staff/event/:id/settings" exact render={props => <EventSettingsMenuPage {...props} />} />

                                    <Route path="/index/staff/event/:id/resources" exact render={props => <EventResourcesPage {...props} />} />

                                    <Route path="/index/staff/event/:id/judge-game" exact render={props => <EventJudgeGameMasterPage {...props} />} />

                                    <Route path="/index/staff/event/entry/:id" exact render={props => <ViewEntryPage {...props} />} />

                                    <Route path="/index/staff/event/:id" exact render={props => <EventMenuPage {...props} />} />

                                    <Route path="/index/staff/event/remove/:session_id" exact render={props => <StripeDeleteEventPage {...props} />} />

                                    <Route path="/index/staff/event/new" exact>
                                        <CreateEventPage />
                                    </Route>

                                    <Route path="/index/staff/organization/create" exact>
                                        <CreateEventOrganizationPage />
                                    </Route>

                                    <Route path="/index/staff/organization" exact>
                                        <EventOrganizationPage />
                                    </Route>

                                    <Route path="/index/staff/organization/info/:id" exact render={props => <EventOrganizationBasicInfoPage {...props} />} />

                                    <Route path="/index/staff/organization/basic-settings/:id" exact render={props => <EventOrganizationBasicSettingsPage {...props} />} />

                                    <Route path="/index/staff/organization/branding/:id" exact render={props => <EventOrganizationBrandingPage {...props} />} />

                                    <Route path="/index/staff/organization/delete/:id" exact render={props => <EventOrganizationDeletePage {...props} />} />

                                    <Route path="/index/staff/organization/posts/:id" exact render={props => <EventOrganizationPostInfoPage {...props} />} />

                                    <Route path="/index/staff/organization/post/create/:id" exact render={props => <CreatePostPage {...props} />} />
                                    
                                    <Route path="/index/staff/organization/post/edit/:id" exact render={props => <EditPostPage {...props} />} />

                                    <Route path="/index/staff/organization/event-settings/:id" exact render={props => <EventOrganizationEventSettingsPage {...props} />} />

                                    <Route path="/index/staff/organization/text-alerts/:id/:session_id?" exact render={props => <EventOrganizationTextAlertsPage {...props} />} />

                                    <Route path="/index/staff/organization/addresses/:id" exact render={props => <EventOrganizationAddressesPage {...props} />} />

                                    <Route path="/index/staff/organization/members/:id" exact render={props => <EventOrganizationMemberSettingsPage {...props} />} />

                                    <Route path="/index/staff/organization/members/admin/:id" exact render={props => <EventOrganizationAdminMembersPage {...props} />} />

                                    <Route path="/index/staff/organization/members/general/:id" exact render={props => <EventOrganizationMembersPage {...props} />} />

                                    <Route path="/index/staff/organization/members/entries/:id" exact render={props => <EventOrganizationEntriesPage {...props} />} />
                                    
                                    <Route path="/index/staff/organization/membership-types/:id" exact render={props => <EventOrganizationMembershipTypesInfoPage {...props} />} />

                                    <Route path="/index/staff/organization/membership-types/create/:id" exact render={props => <CreateMembershipTypePage {...props} />} />

                                    <Route path="/index/staff/organization/membership-types/edit/:id" exact render={props => <EditMembershipTypePage {...props} />} />

                                    <Route path="/index/staff/organization/:id/member/create" exact render={props => <EventOrganizationMembersCreatePage {...props} />} />
                                   
                                   <Route path="/index/staff/organization/:id/member/bulk-add" exact render={props => <EventOrganizationMembersBulkAddPage {...props} />} />

                                    <Route path="/index/staff/organization/points/:id" exact render={props => <EventOrganizationPointSettingsPage {...props} />} />

                                    <Route path="/index/staff/organization/points/view/:id" exact render={props => <EventOrganizationViewPointsPage {...props} />} />

                                    <Route path="/index/staff/organization/points/view/division/:id" exact render={props => <EventOrganizationViewDivisionPointsPage {...props} />} />

                                    <Route path="/index/staff/organization/points/view/class/:id" exact render={props => <EventOrganizationViewClassPointsPage {...props} />} />

                                    <Route path="/index/staff/organization/points/view/:id/member/:memberId" exact render={props => <EventOrganizationViewMemberPointsPage {...props} />} />

                                    <Route path="/index/staff/organization/points/reports/:id" exact render={props => <EventOrganizationDownloadPointReportsPage {...props} />} />

                                    <Route path="/index/staff/organization/points/upload/:id" exact render={props => <EventOrganizationUploadPointDataPage {...props} />} />

                                    <Route path="/index/staff/organization/finance/:id" exact render={props => <EventFinancesPage {...props} />} />

                                    <Route path="/index/staff/organization/point-templates/:id" exact render={props => <OrganizationPointsTemplatesPage {...props} />} />

                                    <Route path="/index/staff/organization/prizemoney-templates/:id" exact render={props => <OrganizationPrizeMoneyTemplatesPage {...props} />} />

                                    <Route path="/index/staff/organization/org-divisions/:id" exact render={props => <OrganizationDivisionsPage {...props} />} />

                                    <Route path="/index/staff/organization/org-classes/:id" exact render={props => <OrganizationClassesPage {...props} />} />

                                    <Route path="/index/staff/organization/division-templates/:id" exact render={props => <OrganizationDivisionTemplatesPage {...props} />} />

                                    <Route path="/index/staff/organization/stalls/:id" exact render={props => <OrganizationStallsPage {...props} />} />

                                    <Route path="/index/staff/organization/tax/:id" exact render={props => <OrganizationTaxesPage {...props} />} />

                                    <Route path="/index/settings/security" exact>
                                        <EditSecurityPage />
                                    </Route>
                                    
                                    <Route path="/index/settings/profile" exact>
                                        <EditAccountPage />
                                    </Route>

                                    <Route path="/index/settings/contact" exact>
                                        <EditContactPage />
                                    </Route>

                                    <Route path="/index/settings" exact>
                                        <SettingsPage />
                                    </Route>

                                    <Route path="/index/library" exact>
                                        <LibraryPage />
                                    </Route>

                                    <Route path="/index/submissions/:id" exact render={props => <ViewSubmissionFeedbackPage {...props} />} />

                                    <Route path="/index/submissions/new" exact>
                                        <NewSubmissionsPage />
                                    </Route>

                                    <Route path="/index/submission/success/:id" exact render={props => <SuccessSubmissionPage {...props} />} />

                                    <Route path="/index/submissions/previous" exact>
                                        <PreviousSubmissionsPage />
                                    </Route>

                                    <Route path="/index/rider/profile">
                                        <RiderProfilePage />
                                    </Route>

                                    <Route path="/index/rider/info">
                                        <RiderInfoPage />
                                    </Route>

                                    <Route path="/index/rider/memberships">
                                        <RiderMembershipsPage />
                                    </Route>

                                    <Route path="/index/rider/calendar">
                                        <RiderCalendarPage />
                                    </Route>

                                    <Route path="/index/rider/horses">
                                        <RiderHorsesPage />
                                    </Route>

                                    <Route path="/index/rider/horse/create">
                                        <RiderNewHorsePage />
                                    </Route>

                                    <Route path="/index/horse/:id" exact render={props => <EditHorsePage {...props} />} />

                                    <Route path="/index/owner/profile">
                                        <OwnerProfilePage />
                                    </Route>

                                    <Route path="/index/owner/info">
                                        <OwnerInfoPage />
                                    </Route>

                                    <Route path="/index/owner/memberships">
                                        <OwnerMembershipsPage />
                                    </Route>

                                    <Route path="/index/owner/calendar">
                                        <OwnerCalendarPage />
                                    </Route>

                                    <Route path="/index/owner/horses">
                                        <OwnerHorsesPage />
                                    </Route>

                                    <Route path="/index/owner/horse/create">
                                        <OwnerNewHorsePage />
                                    </Route>

                                    <Route path="/index/trainer/profile">
                                        <TrainerProfilePage />
                                    </Route>

                                    <Route path="/index/trainer/info">
                                        <TrainerInfoPage />
                                    </Route>

                                    <Route path="/index/trainer/memberships">
                                        <TrainerMembershipsPage />
                                    </Route>

                                    <Route path="/index/trainer/calendar">
                                        <TrainerCalendarPage />
                                    </Route>

                                    <Route path="/index/barn/info/:id" exact render={props => <ViewBarnPage {...props} />} />

                                    <Route path="/index/barn/add" exact>
                                        <AddBarnPage />
                                    </Route>

                                    <Route path="/index/barn" exact>
                                        <BarnPage />
                                    </Route>

                                    <Route path="/index/team/coaches/:id" exact render={props => <TeamCoachesPage {...props} />} />
                                    <Route path="/index/team/roster/:id" exact render={props => <TeamRosterPage {...props} />} />
                                    <Route path="/index/team/roster/member/:id" exact render={props => <ViewTeamMemberPage {...props} />} />

                                    <Route path="/index/team" exact>
                                        <TeamPage />
                                    </Route>

                                    <Route path="/index/barn/member/info/:id" exact render={props => <BarnBasicInfoPage {...props} />} />
                                    <Route path="/index/barn/member/horses/:id" exact render={props => <BarnHorsesPage {...props} />} />
                                    <Route path="/index/barn/member/people/:id" exact render={props => <BarnPeoplePage {...props} />} />

                                    <Route path="/index/barn/admin/info/:id" exact render={props => <BarnBasicInfoPage {...props} />} />
                                    <Route path="/index/barn/admin/horses/:id" exact render={props => <BarnHorsesPage {...props} />} />
                                    <Route path="/index/barn/admin/people/:id" exact render={props => <BarnPeoplePage {...props} />} />
                                    <Route path="/index/barn/admin/settings/:id" exact render={props => <BarnSettingsPage {...props} />} />

                                    <Route path="/index/staff/barn/create" exact>
                                        <CreateBarnPage />
                                    </Route>

                                    <Route path="/index/staff/barn" exact>
                                        <BarnPage />
                                    </Route>
                                    
                                    <Route path="/index/staff/barn/horses/:id" exact render={props => <BarnHorsesPage {...props} />} />
                                    <Route path="/index/staff/barn/horse/create/:id" exact render={props => <NewHorsePage {...props} />} />
                                    <Route path="/index/staff/barn/horse/:id" exact render={props => <EditHorsePage {...props} />} />

                                    <Route path="/index/staff/barn/people/:id" exact render={props => <BarnPeoplePage {...props} />} />
                                    <Route path="/index/staff/barn/:barnId/person/:type/:id" exact render={props => <EditBarnPersonPage {...props} />} />
                                    <Route path="/index/staff/barn/create/person/:id" exact render={props => <NewBarnPersonPage {...props} />} />

                                    <Route path="/index/judge/clinics" exact>
                                        <ClinicianPage />
                                    </Route>

                                    <Route path="/index/judge/clinics/profile" exact>
                                        <ClinicianProfilePage />
                                    </Route>

                                    <Route path="/index/judge/clinics/status" exact>
                                        <ClinicianStatusPage />
                                    </Route>

                                    <Route path="/index/judge/clinics/videos" exact>
                                        <ClinicianVideoPage />
                                    </Route>

                                    <Route path="/index/judge/clinics/payment" exact>
                                        <ClinicianPaymentPage />
                                    </Route>

                                    <Route path="/index/judge/clinics/payment/:personId/:stripeId" exact render={props => <ClinicianStripeSuccessPage {...props} />} />

                                    <Route path="/index/judge/clinics/video/:id" exact render={props => <ClinicianVideoFeedbackPage {...props} />} />

                                    <Route path="/index/profile">
                                        <ProfilePage />
                                    </Route>

                                    <Route path="/index/:orgId/home" exact render={props => (<OrganizationDashboardPage setOrganizationHandler={this.setOrganization} {...props} />)} />

                                    <Route path="/index/home" exact>
                                        <DashboardPage setOrganizationHandler={this.setOrganization}/>
                                    </Route>

                                    <Route path="/index/staff/organization/org-seasons/:id" exact render={props => <OrganizationSeasonPage {...props} />} />

                                    <Route path="/index/staff/organization/org-seasons/create/:id" exact  render={props => <CreateSeasonPage   {...props} />} />

                                    <Route path="/index/staff/organization/org-seasons/edit/:id" exact render={props => <EditSeasonPage {...props} />} />
                                    
                                    <Route path="/index/:organizationBackHalf/application/:orgMembershipTypeId/:membershipId?" exact render={props => <MembershipApplicationsPage setOrganizationHandler={this.setOrganization} {...props} />} />
                                    
                                    <Route path="/index/staff/organization/membership/edit/:id" exact render={props => <EventOrganizationMemberEditPage {...props} />} />

                                    <Route path="/index/:organization/view-memberships/:id" exact render={props => <ViewAllMembershipsPage setOrganizationHandler={this.setOrganization} {...props} />} />

                                    {this.state.user ? (
                                        <Redirect path="/" to="/index/home" exact />
                                    ) : (
                                        <Redirect path="/" to="/home" exact />
                                    )}

                                    <Redirect path="/" to="/index/home" exact />

                                    <AuthRoutes />
                                </IonRouterOutlet>
                            </IonSplitPane>
                        </PersonContext.Provider>
                    </OrganizationContext.Provider>
                }
            </React.Fragment>
        );
    }
}
  
export default withRouter(DashboardLayout);
