import {
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, HorseShowRating } from "../../../models";
import { getHorseShowRatingsByEventId } from "../../../utilities/horseShowRating/HorseShowRating";

interface _Props {
    event: Event
}

const EventDisplayRating: React.FC<_Props> = ({event}) => {
    const [eventRatingArray, setEventRatingArray] = useState<(HorseShowRating | null)[] | null | undefined>();

    useEffect(() => {
        async function getHorseShowRatings(eventId: string) {
            const queryResult = await getHorseShowRatingsByEventId(eventId);
            if (queryResult.isSuccess) {
                const ratings = queryResult.result;
                setEventRatingArray(ratings);
            }
        };
        if (event) getHorseShowRatings(event.id);
    }, [event]);

    return (
        <>
            {eventRatingArray && eventRatingArray.length ?
                <>
                    <IonList className="bg-white">
                        {eventRatingArray.map((rating, index) => (
                            <IonItem key={index}>
                                <IonLabel><h3 className="ion-text-wrap text-darker">{rating && rating.governingBody} - {rating && rating.rating}</h3></IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                </>
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel><h3 className="ion-text-wrap text-darker">No ratings listed.</h3></IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default EventDisplayRating;