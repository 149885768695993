import {
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import React, { useEffect, useState } from "react";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import { Organization } from "../../../../../models";
import Spinner from "../../../../../components/Spinners/Spinner";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import SeasonForm from "../../../../../components/Season/SeasonForm";
import { RouteComponentProps } from "react-router";


interface _Props extends RouteComponentProps<{
    id: string;
}> {}


const CreateSeasonPage: React.FC<_Props> = ({match}) => {


    const [organization, setOrganization] = useState<Organization | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        async function getOrganizations() {
            setIsLoading(true);
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                setOrganization(queryResult.result);
            } else {
                setError(queryResult.message)
            }
            setIsLoading(false);
        }
        getOrganizations();
    }, [match, match.params.id]);
   
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                {error && <ErrorAlert width="12" error={error} />}
                <PageTitle title={`${(organization?.nickname || organization?.name) ?? ""}`} />
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeXs="12" sizeMd="12">
                        <IonCard className="ion-padding bg-white">
                            <IonCardContent >
                                {organization && !isLoading ?
                                    <SeasonForm organization={organization} headerTitle= "Create Season"/>
                                :   
                                    <Spinner/> 
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default CreateSeasonPage;