import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ViewEventNavbar from "../../../../components/Navbars/ViewEventNavbar";
import {Event, GameInput} from "../../../../models";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../utilities/events/Event";
import { addCircleOutline, close, ellipsisVerticalCircleOutline, phonePortraitOutline } from "ionicons/icons";
import { FormGroup, Input, Table } from "reactstrap";
import { CreateGameInputInput } from "../../../../API";
import { PersonContext } from "../../../../context/PersonContext";
import { createGameInput, getGameInputsByEvent } from "../../../../utilities/gameInput/GameInput";
import Spinner from "../../../../components/Spinners/Spinner";
import { useSubscriptionByItself } from "../../../../utilities/subscription/Subscription";
import { onCreateGameInput, onDeleteGameInput, onUpdateGameInput } from "../../../../graphql/subscriptions";
import RequiredInputIndicator from "../../../../components/Forms/RequiredInputIndicator";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

interface FormattedTableData {
    id: string
    backNumber: string
    roundOneUserScore: string
    roundOneJudgeScore: string
    roundTwoUserScore: string
    roundTwoJudgeScore: string
    totalUserScore: string
    totalJudgeScore: string
    gameInputs: GameInput[]
}

const EventJudgeGamePage: React.FC<EventPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const gameInputCreateSubscription = useSubscriptionByItself({
        config: {
            query: onCreateGameInput,
            key: "onCreateGameInput"
        }
    });

    const gameInputUpdateSubscription = useSubscriptionByItself({
        config: {
            query: onUpdateGameInput,
            key: "onUpdateGameInput"
        }
    });

    const gameInputDeleteSubscription = useSubscriptionByItself({
        config: {
            query: onDeleteGameInput,
            key: "onDeleteGameInput"
        }
    });

    const [currentCreateSubscriptionItem, setCreateCurrentSubscriptionItem] = useState<any>();
    const [currentUpdateSubscriptionItem, setUpdateCurrentSubscriptionItem] = useState<any>();
    const [currentDeleteSubscriptionItem, setDeleteCurrentSubscriptionItem] = useState<any>();

    const [currentEvent, setCurrentEvent] = useState<Event | null | undefined>();
    const [gameInputs, setGameInputs] = useState<GameInput[] | null | undefined>();
    const [userGameInputs, setUserGameInputs] = useState<GameInput[] | null | undefined>();
    const [masterGameInputs, setMasterGameInputs] = useState<GameInput[]>([]);
    const [formattedData, setFormattedData] = useState<FormattedTableData[] | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [backNumber, setBackNumber] = useState<number | null | undefined>(null);
    const [score, setScore] = useState<number | null | undefined>(null);
    const [round, setRound] = useState("1");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    async function getEvent() {
        const queryResult = await getEventById(match.params.id);
        if (queryResult.isSuccess) {
            const foundEvent: Event = queryResult.result;
            setCurrentEvent(foundEvent);
            return foundEvent;
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getPreviousGameInputs(e: Event) {
        const queryResult = await getGameInputsByEvent(e.id);
        if (queryResult.isSuccess) {
            const gameInputData: GameInput[] = queryResult.result;
            setGameInputs(gameInputData);
            return gameInputData;
        }
    }

    function formatTableData(userGameInputList: GameInput[], masterGameInputList: GameInput[]) {
        let formattedDataArray: FormattedTableData[] = [];
        for (let i = 0; i < userGameInputList.length; i++) {
            // Grab the current input from the user
            const gameInput = userGameInputList[i];
            let gameInputList = [gameInput];

            // If it is for a round 1 score
            if (gameInput.round === "1") {
                // Find the master version for round 1 for this entry back number
                const masterGameInput = masterGameInputList.find((input) => input.backNumber === gameInput.backNumber && input.round === gameInput.round);

                // Find the round 2 scores if they exist
                const gameInputRound2 = userGameInputList.find((input) => input.backNumber === gameInput.backNumber && input.round === "2");
                if (gameInputRound2) gameInputList.push(gameInputRound2);
                const masterGameInputRound2 = masterGameInputList.find((input) => input.backNumber === gameInput.backNumber && input.round === "2");

                // Create the totals
                const userTotal = (gameInput.score || 0) + (gameInputRound2?.score || 0);
                const masterTotal = (masterGameInput?.score || 0) + (masterGameInputRound2?.score || 0);

                // Format all of the data
                const formattedData: FormattedTableData = {
                    id: gameInput.id,
                    backNumber: gameInput.backNumber || "",
                    roundOneUserScore: gameInput.score ? gameInput.score.toString() : "",
                    roundOneJudgeScore: masterGameInput?.score ? masterGameInput.score.toString() : "",
                    roundTwoUserScore: gameInputRound2?.score ? gameInputRound2.score.toString() : "",
                    roundTwoJudgeScore: masterGameInputRound2?.score ? masterGameInputRound2.score.toString() : "",
                    totalUserScore: userTotal ? userTotal.toString() : "",
                    totalJudgeScore: masterTotal ? masterTotal.toString() : "",
                    gameInputs: gameInputList
                };
                formattedDataArray.push(formattedData);
            }
        }
        const sorted = formattedDataArray.sort((a, b) => {
            const judgeScore = (b.totalJudgeScore ? parseFloat(b.totalJudgeScore) : 0) - (a.totalJudgeScore ? parseFloat(a.totalJudgeScore) : 0);
            const userScore = (b.totalUserScore ? parseFloat(b.totalUserScore) : 0) - (a.totalUserScore ? parseFloat(a.totalUserScore) : 0);
            return judgeScore || userScore;
        });
        return sorted || formattedDataArray;
    }

    async function getData() {
        setIsLoading(true);
        const foundEvent = await getEvent();
        if (foundEvent) {
            const eventGameInputs = await getPreviousGameInputs(foundEvent);
            if (eventGameInputs) {
                const userGameInputs = eventGameInputs.filter((input) => input.createdBy === user.id);
                setUserGameInputs(userGameInputs);
                const masterGameInputs = eventGameInputs.filter((input) => input.isMasterRecord === true);
                setMasterGameInputs(masterGameInputs);

                const formattedData = formatTableData(userGameInputs, masterGameInputs);
                setFormattedData(formattedData);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getData();
    }, [match.params.id]);

    useEffect(() => {
        if (gameInputCreateSubscription && gameInputCreateSubscription[0] !== undefined && gameInputCreateSubscription[0] !== currentCreateSubscriptionItem) {
            const newGameInput: any = gameInputCreateSubscription[0];
            setCreateCurrentSubscriptionItem(gameInputCreateSubscription[0]);
            
            // Look for new master inputs and be sure to display them to the current user
            if (newGameInput && newGameInput.isMasterRecord) {
                // Update the master game inputs
                const updatedMasterGameInputs: GameInput[] = (masterGameInputs || []).concat([newGameInput]);
                setMasterGameInputs((prevValue) => (prevValue || []).concat([newGameInput]));

                // Reformat the table data with the new info
                if (userGameInputs) {
                    const updatedTableData = formatTableData(userGameInputs, updatedMasterGameInputs);
                    setFormattedData(updatedTableData);
                }
            }
        }
    }, [gameInputCreateSubscription]);

    useEffect(() => {
        if (gameInputUpdateSubscription && gameInputUpdateSubscription[0] !== undefined && gameInputUpdateSubscription[0] !== currentUpdateSubscriptionItem) {
            const updatedGameInput: any = gameInputUpdateSubscription[0];
            setUpdateCurrentSubscriptionItem(updatedGameInput);
            
            // Look for updated master inputs and be sure to display them to the current user
            if (updatedGameInput && updatedGameInput.isMasterRecord) {
                // Update the master game inputs
                const index = masterGameInputs.findIndex(input => input.id === updatedGameInput.id);
                if (index > -1) {
                    const updatedMasterGameInputs: GameInput[] = [
                        ...masterGameInputs.slice(0, index),
                        updatedGameInput,
                        ...masterGameInputs.slice(index + 1),
                    ];
                    setMasterGameInputs((prevValue) => (updatedMasterGameInputs));

                    // Reformat the table data with the new info
                    if (userGameInputs) {
                        const updatedTableData = formatTableData(userGameInputs, updatedMasterGameInputs);
                        setFormattedData(updatedTableData);
                    }
                }
            }
        }
    }, [gameInputUpdateSubscription]);

    useEffect(() => {
        if (gameInputDeleteSubscription && gameInputDeleteSubscription[0] !== undefined && gameInputDeleteSubscription[0] !== currentDeleteSubscriptionItem) {
            const deletedGameInput: any = gameInputDeleteSubscription[0];
            setDeleteCurrentSubscriptionItem(deletedGameInput);
            
            // Look for deleted master inputs and be sure to display them to the current user
            if (deletedGameInput && deletedGameInput.isMasterRecord) {
                // Update the master game inputs
                const index = masterGameInputs.findIndex(input => input.id === deletedGameInput.id);
                if (index > -1) {
                    const updatedMasterGameInputs: GameInput[] = [
                        ...masterGameInputs.slice(0, index),
                        ...masterGameInputs.slice(index + 1),
                    ];
                    setMasterGameInputs(updatedMasterGameInputs);

                    // Reformat the table data with the new info
                    if (userGameInputs) {
                        const updatedTableData = formatTableData(userGameInputs, updatedMasterGameInputs);
                        setFormattedData(updatedTableData);
                    }
                }
            }
        }
    }, [gameInputDeleteSubscription]);

    const verifyForm = () => {
        if (!round) {
            setError("Please select a round.");
            return false;
        }
        if (!backNumber) {
            setError("Please include an entry number (back number).");
            return false;
        }
        if (!score) {
            setError("Please include your score.");
            return false;
        }
        return true;
    }

    const handleRecordNewScore = async () => {
        setIsLoading(true);
        const input: CreateGameInputInput = {
            eventId: currentEvent?.id || "",
            backNumber: backNumber ? backNumber.toString() : "",
            score: score,
            round: round,
            isMasterRecord: false,
            createdBy: user.id
        };
        const createResult = await createGameInput(input);
        if (createResult.isSuccess) {
            const newGameInput: GameInput = createResult.result;

            // Update the user game inputs
            const updatedUserGameInputs = (userGameInputs || []).concat([newGameInput]);
            setUserGameInputs((prevValue) => (prevValue || []).concat([newGameInput]));

            // Re-format the data
            const updatedTableData = formatTableData(updatedUserGameInputs, masterGameInputs);
            setFormattedData(updatedTableData);

            setBackNumber(undefined);
            setScore(undefined);
            setShowModal(false);
        } else {
            const msg = "Error - the score could not be saved. " + createResult.message;
            setError(msg); 
        }
        setIsLoading(false);
    }

    const handleSave = async () => {
        const isValid = verifyForm();
        if (isValid) {
            await handleRecordNewScore();
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent ? (currentEvent.nickname ? currentEvent.nickname : currentEvent.name) : "Event"} />
                {currentEvent && (
                    <>
                        <ViewEventNavbar active="judge" event={currentEvent} />
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonItem lines="none">
                                            <IonIcon icon={phonePortraitOutline} slot="start"/>
                                            <IonLabel> Ride Along</IonLabel>
                                        </IonItem>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol className="ion-text-center font-weight-bold">
                                                <h1 className="text-primary">$5,000 Hunter Classic</h1>
                                                <h2>Test your skills by entering scores for each entry. See how you compare to the judge!</h2>
                                            </IonCol>
                                        </IonRow>
                                        {isLoading ?
                                            <Spinner />
                                            :
                                            <>
                                                <IonRow className="ion-justify-content-center">
                                                    <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                                        <IonIcon icon={addCircleOutline} />
                                                        Record New Score
                                                    </IonButton>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        {(formattedData && formattedData.length > 0) ?
                                                            <>
                                                                <Table responsive hover striped>
                                                                    <thead>
                                                                        <th className="ion-text-wrap">Back Number</th>
                                                                        <th className="ion-text-wrap">Round 1 - User</th>
                                                                        <th className="ion-text-wrap">Round 1 - Judge</th>
                                                                        <th className="ion-text-wrap">Round 2 - User</th>
                                                                        <th className="ion-text-wrap">Round 2 - Judge</th>
                                                                        <th className="ion-text-wrap">Total - User</th>
                                                                        <th className="ion-text-wrap">Total - Judge</th>
                                                                        <th className="ion-text-wrap">Current Rank - Judge</th>
                                                                    </thead>
                                                                    <tbody>
                                                                        {formattedData.map((data, index) => (
                                                                            <tr key={index}>
                                                                                <td className="ion-text-wrap">{data.backNumber}</td>
                                                                                <td className="ion-text-wrap">{data.roundOneUserScore}</td>
                                                                                <td className="ion-text-wrap font-weight-bold">{data.roundOneJudgeScore}</td>
                                                                                <td className="ion-text-wrap">{data.roundTwoUserScore}</td>
                                                                                <td className="ion-text-wrap font-weight-bold">{data.roundTwoJudgeScore}</td>
                                                                                <td className="ion-text-wrap">{data.totalUserScore}</td>
                                                                                <td className="ion-text-wrap font-weight-bold">{data.totalJudgeScore}</td>
                                                                                <td className="ion-text-wrap">{index+1}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </>
                                                            :
                                                            <h2>No scores have been entered yet.</h2>
                                                        }
                                                    </IonCol> 
                                                </IonRow>
                                            </>
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <section className="py-5" id="aboutInfo">
                            <IonRow className="pb-5">
                                <IonCol sizeSm="12" sizeMd="6">
                                    <IonCard mode="md" className="ion-padding bg-white stretch">
                                        <IonCardTitle>
                                            <IonItem lines="none">
                                                <IonIcon icon={ellipsisVerticalCircleOutline} slot="start"/>
                                                <IonLabel> About</IonLabel>
                                            </IonItem>
                                        </IonCardTitle>
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>
                                                    <p>
                                                        This is a trial of a new feature. We are hoping to expand this feature in the future, and we would love any feedback you are willing to provide.
                                                    </p>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </section>
                    </>
                )}
                <IonModal backdropDismiss={false} isOpen={showModal} id="recordNewScoreForJudgeGameModal">
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                            Record New Score
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowModal(false)}
                            >
                                <p id="closeRecordNewScoreModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    <IonIcon icon={close} />
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonContent className="ion-padding">
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="12">
                                <FormGroup>
                                    <IonLabel className="description pl-3">Round <RequiredInputIndicator showWordRequired /></IonLabel>
                                    <Input
                                        name="type" 
                                        type="select" 
                                        value={round} 
                                        onChange={e => {
                                            setRound(e.target.value);
                                        }}
                                    >
                                        <option value="1">Round 1</option>
                                        <option value="2">Round 2</option>
                                    </Input>
                                </FormGroup>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="12">
                                <FormGroup>
                                    <IonLabel className="description pl-3">Entry Number <RequiredInputIndicator showWordRequired /></IonLabel>
                                    <Input
                                        name="entryNumber" 
                                        type="number" 
                                        value={backNumber || undefined} 
                                        min={0}
                                        onChange={e => {
                                            setBackNumber(e.target.value ? parseInt(e.target.value) : undefined);
                                        }}
                                    />
                                </FormGroup>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="12">
                                <FormGroup>
                                    <IonLabel className="description pl-3">Your Score <RequiredInputIndicator showWordRequired /></IonLabel>
                                    <Input
                                        name="userScore" 
                                        type="number" 
                                        value={score || undefined} 
                                        onChange={e => {
                                            setScore(e.target.value ? parseFloat(e.target.value) : undefined);
                                        }}
                                    />
                                </FormGroup>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                <IonButton expand="block" color="tertiary" onClick={handleSave}>
                                    Save
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default EventJudgeGamePage;