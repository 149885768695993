import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Class, OrganizationClass, PointTable, PrizeMoneyTable } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import { createClass, getClassById, updateClass } from "../../utilities/class/Class";
import SelectDiscipline from "../Discipline/SelectDiscipline";
import { createOrganizationClass, deleteOrganizationClass, updateOrganizationClass } from "../../utilities/organizationClass/OrganizationClass";
import SuccessBanner from "../Banners/SuccessBanner";
import SelectECClass from "../Class/SelectECClass";
import SelectUSEFClasses from "../Class/SelectUSEFClass";
import { ECClass, ECClassType } from "../../interfaces/ECData";
import { USEFSection } from "../../interfaces/USEFData";
import { capitalizeName } from "../../utilities/person/PersonNameFormat";
import { getDisciplineFromName } from "../../utilities/discipline/FormatDisciplines";
import SelectECClassType from "../Class/SelectECClassType";
import { CreateClassInput, CreateOrganizationClassInput, UpdateClassInput, UpdateOrganizationClassInput } from "../../API";
import { getTimestamp } from "../Timestamp";
import { Input } from "reactstrap";
import AllowedNumberOfEntries from "../EventClass/EventClassForm/AllowedNumberOfEntries";
import ClassEntryFee from "../EventClass/EventClassForm/ClassEntryFee";
import ClassPrizeMoney from "../EventClass/EventClassForm/ClassPrizeMoney";
import ClassPointTable from "../EventClass/EventClassForm/ClassPointTable";
import SelectUSHJAOutreachClass from "../Class/SelectUSHJAOutreachClass";
import { USHJAOutreachSection } from "../../data/content/USHJAOutreachSections";
import JumpHeightOptionsForm from "../EventClass/EventClassForm/JumpHeightOptionsForm";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    organizationId: string
    organizationClass?: (OrganizationClass | null)
    onSubmit: Function
}

const OrganizationClassForm: React.FC<_Props> = ({organizationClass, organizationId, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectedValue, setSelectedValue] = useState<"custom" | "ec" | "usef" | "ushja">("custom");
    const [ecClassNumber, setECClassNumber] = useState<number | undefined>();
    const [ecClassTypeNumber, setECClassTypeNumber] = useState<number | undefined>();
    const [usefClassNumber, setUSEFClassNumber] = useState<number | undefined>();
    const [Class, setClass] = useState<Class | undefined>(); 
    const [name, setName] = useState(((organizationClass && organizationClass.class) ? organizationClass.class.name : ""));
    const [number, setNumber] = useState<number | undefined | null>(undefined);
    const [ecName, setECName] = useState("");
    const [ecClassTypeName, setEClassTypeName] = useState("");
    const [usefName, setUSEFName] = useState("");
    const [ushjaName, setUSHJAName] = useState("");
    const [jumpHeights, setJumpHeights] = useState<(string | null)[] | undefined | null>((organizationClass && organizationClass.jumpHeight) ? organizationClass.jumpHeight : undefined);
    const [description, setDescription] = useState((organizationClass && organizationClass.class) ? organizationClass.class.description : "");
    const [discipline, setDiscipline] = useState((organizationClass && organizationClass.class) ? organizationClass.class.discipline : "hunter"); //Hunter is the default option in SelectDiscipline
    const [entryFee, setEntryFee] = useState((organizationClass && organizationClass.entryFee) ? parseFloat(organizationClass.entryFee) : 0);
    const [prizeMoney, setPrizeMoney] = useState((organizationClass && organizationClass.prizeMoney) ? parseFloat(organizationClass.prizeMoney) : 0);
    const [prizeMoneyTable, setPrizeMoneyTable] = useState<PrizeMoneyTable | undefined>();
    const [pointTable, setPointTable] = useState<PointTable | null | undefined>();
    const [minNumberEntries, setMinNumberEntries] = useState(3);
    const [maxNumberEntries, setMaxNumberEntries] = useState<number | undefined>();
    
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    const getClass = async (classId: string) => {
        const queryResult = await getClassById(classId);
        if (queryResult.isSuccess) {
            const c = queryResult.result;
            setClass(c);
            setDescription(c.description);
            setDiscipline(c.discipline);
            if (c.ecNumber) {
                setSelectedValue("ec");
                setECClassNumber(parseInt(c.ecNumber));
                if (c.ecClassTypeNumber) setECClassTypeNumber(parseInt(c.ecClassTypeNumber));
                if (c.ecClassType) setEClassTypeName(c.ecClassType);
            }
            if (c.usefNumber) {
                setSelectedValue("usef");
                setUSEFClassNumber(parseInt(c.usefNumber));
            }
            if (c.ushjaName) {
                setSelectedValue("ushja");
                setUSHJAName(c.ushjaName);
            }
            return queryResult.result;
        } else {
            return null;
        }
    }

    useEffect(() => {
        if (organizationClass) {
            getClass(organizationClass.classId);
            setName(organizationClass.name);
            setNumber(organizationClass.number);
            setMinNumberEntries(organizationClass.minNumberEntries || 0);
            setMaxNumberEntries(organizationClass.maxNumberEntries || 0);
            setJumpHeights(() => organizationClass.jumpHeight);
            setEntryFee(organizationClass.entryFee ? parseFloat(organizationClass.entryFee) : 0);
            setPrizeMoney(organizationClass.prizeMoney ? parseFloat(organizationClass.prizeMoney) : 0);
            setPrizeMoneyTable(organizationClass.prizeMoneyTable || undefined);
            setPointTable(organizationClass.pointTable);
            setSuccess("");
            setError("");
        }
    }, [organizationClass]);

    const verifyForm = () => {
        try {
            if (!name) {
                setError("You must include a name.");
                return false;
            }
            if (!discipline) {
                setError("You must include a discipline.");
                return false;
            }
            if (selectedValue === "usef" && (!usefClassNumber && !ushjaName)) {
                setError("You must include a USEF Class and/or USHJA Class.");
                return false;
            }
            if (selectedValue === "ec" && !ecClassNumber) {
                setError("You must include an EC Class.");
                return false;
            }
            if (selectedValue === "ec" && !ecClassTypeNumber) {
                setError("You must include an EC Class Type.");
                return false;
            }
            return true;
        } catch (error: any) {
            setError(error);
            return false;
        }
    }

    const clearForm = () => {
        setClass(undefined);
        setClass(undefined);
        setName("");
        setNumber(undefined);
        setECName("");
        setEClassTypeName("");
        setUSEFName("");
        setUSHJAName("");
        setECClassNumber(undefined);
        setECClassTypeNumber(undefined);
        setUSEFClassNumber(undefined);
        setDescription("");
        setJumpHeights(undefined);
        setDiscipline("hunter");
        setEntryFee(0);
        setPrizeMoney(0);
        setError("");
    } 

    const handleSelectedECClass = (data: ECClass) => {
        const name = data.ClassDescription;
        setName(name);
        setECName(name);
        setDiscipline(getDisciplineFromName(name));
        setECClassNumber(data.ClassID);
    }

    const handleSelectedECClassType = (data: ECClassType) => {
        setEClassTypeName(data.ClassTypeDescription);
        setECClassTypeNumber(data.ClassTypeID);
    }

    const handleSelectedUSEFDivision = (data: USEFSection) => {
        const name = data.SectionName;
        setName(capitalizeName(name));
        setUSEFName(name);
        setDiscipline(getDisciplineFromName(name));
        setUSEFClassNumber(data.SectionCd);
    }

    const handleSelectedUSHJASection = (data: USHJAOutreachSection) => {
        const name = data.name;
        setName(capitalizeName(name));
        setUSHJAName(name);
        setDiscipline(getDisciplineFromName(name));
    }

    const handleSelectedDiscipline = (discipline: string) => {
        setDiscipline(discipline);
        setIsDisabled(false);
    }

    const handleSelectedJumpHeights = (selectedJumpHeights: string[]) => {
        setJumpHeights(selectedJumpHeights);
        setIsDisabled(false);
    }

    const handleSelectedEntryNumber = (option: "min" | "max", number: number) => {
        if (option === "min") setMinNumberEntries(number);
        else setMaxNumberEntries(number);
        setIsDisabled(false);
    }

    const handleSelectedClassEntryFee = (option: "division", number: number) => {
        setEntryFee(number);
        setIsDisabled(false);
    }

    const handleSelectedPrizeMoney = (option: "prizeMoney", number: number) => {
        setPrizeMoney(number);
        setIsDisabled(false);
    }

    const handleSelectedPrizeMoneyTable = (option: "prizeMoneyTable", prizeMoneyTable: PrizeMoneyTable) => {
        setPrizeMoneyTable(prizeMoneyTable);
        if (!prizeMoneyTable.isPercentage && prizeMoneyTable.totalPot) {
            setPrizeMoney(prizeMoneyTable.totalPot);
        } else {
            setPrizeMoney(0);
        }
        setIsDisabled(false);
    }

    const handleSelectedPointTable = (option: "pointTable", pointTable: PointTable) => {
        setPointTable(pointTable);
        setIsDisabled(false);
    }

    const handleCreateClass = async () => {
        try {
            const input: CreateClassInput = {
                name: name,
                description: description,
                discipline: discipline,
                ushjaName: ushjaName || "",
                usefNumber: usefClassNumber?.toString(),
                ecNumber: ecClassNumber?.toString(),
                ecClassTypeNumber: ecClassTypeNumber?.toString(),
                ecClassType: ecClassTypeName,
                createdBy: user.id,
                lastUpdatedBy: user.id,
                createdOn: getTimestamp(),
                updatedOn: getTimestamp()
            };
            const createResult = await createClass(input);
            if (createResult.isSuccess) {
                const input: CreateOrganizationClassInput = {
                    createdBy: user.id,
                    name: name,
                    number: number,
                    classId: createResult.result.id,
                    organizationId: organizationId,
                    entryFee: entryFee.toString(),
                    prizeMoney: prizeMoney.toString(),
                    organizationClassPrizeMoneyTableId: prizeMoneyTable?.id || "",
                    organizationClassPointTableId: pointTable?.id || "",
                    minNumberEntries: minNumberEntries,
                    maxNumberEntries: maxNumberEntries,
                    jumpHeight: jumpHeights,
                };
                const createOrganizationClassResult = await createOrganizationClass(input);
                if (createOrganizationClassResult.isSuccess) {
                    onSubmit("create", createOrganizationClassResult.result);
                    clearForm();
                    const message = "Successfully created the Class: " + name;
                    setSuccess(message);
                    
                } else {
                    setError(createOrganizationClassResult.message);
                }
            } else {
                setError(createResult.message);
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleEditClass = async () => {
        try {
            if (Class) {
                const input: UpdateClassInput = {
                    id: Class.id,
                    name: name,
                    description: description,
                    discipline: discipline,
                    ushjaName: ushjaName || "",
                    usefNumber: usefClassNumber?.toString(),
                    ecNumber: ecClassNumber?.toString(),
                    ecClassTypeNumber: ecClassTypeNumber?.toString(),
                    ecClassType: ecClassTypeName,
                    lastUpdatedBy: user.id,
                    updatedOn: getTimestamp()
                };
                const updateResult = await updateClass(input);
                if (updateResult.isSuccess) {
                    if (organizationClass) {
                        const input: UpdateOrganizationClassInput = {
                            id: organizationClass.id,
                            name: name,
                            number: number,
                            entryFee: entryFee.toString(),
                            prizeMoney: prizeMoney.toString(),
                            organizationClassPrizeMoneyTableId: prizeMoneyTable?.id || "",
                            organizationClassPointTableId: pointTable?.id || "",
                            minNumberEntries: minNumberEntries,
                            maxNumberEntries: maxNumberEntries,
                            jumpHeight: jumpHeights
                        };
                        const updateOrganizationClassResult = await updateOrganizationClass(input);
                        if (updateOrganizationClassResult.isSuccess) {
                            onSubmit("update", updateOrganizationClassResult.result);
                            clearForm();
                            const message = "Successfully updated the Class: " + name;
                            setSuccess(message);
                        } else {
                            setError(updateOrganizationClassResult.message);
                        }
                    } else {
                        setError("Could not update the organization Class.");
                    }
                } else {
                    setError(updateResult.message);
                }
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) {
            setError("");
            if (organizationClass) {
                await handleEditClass();
            } else {
                await handleCreateClass();
            }
        }
        setIsDisabled(true);
        setIsLoading(false);
    }

    const handleDelete = async () => {
        setIsLoading(true);
        if (organizationClass) {
            const deleteResult = await deleteOrganizationClass(organizationClass);
            if (deleteResult.isSuccess) {
                clearForm();
                setSuccess("Deleted the Class");
                onSubmit("delete", organizationClass);
            } else {
                setError(deleteResult.message);
            }
        }
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            <form>
                {/* TO DO - Add back in the ability to check for USEF Classes */}
                {/* <IonRow>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "custom" ? "primary" : "light"} onClick={() => {setSelectedValue("custom"); clearForm();}}>Custom</IonButton>
                    </IonCol>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "ec" ? "primary" : "light"} onClick={() => {setSelectedValue("ec"); clearForm();}}>EC</IonButton>
                    </IonCol>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "usef" ? "primary" : "light"} onClick={() => {setSelectedValue("usef"); clearForm();}}>USEF</IonButton>
                    </IonCol>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "ushja" ? "primary" : "light"} onClick={() => {setSelectedValue("ushja");}}>USHJA</IonButton>
                    </IonCol>
                </IonRow> */}
                <IonRow>
                    <IonCol>
                        <IonCard className="bg-light">
                            <IonCardContent>
                            {selectedValue === "ec" && (
                                <>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <p>Select from the list of recognized EC options.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectECClass selectedValue={ecClassNumber?.toString() || ""} onSelect={handleSelectedECClass} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">EC Number</IonLabel>
                                                <IonInput 
                                                    type="number"
                                                    value={ecClassNumber}
                                                    disabled={true}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectECClassType selectedValue={ecClassTypeNumber?.toString() || ""} onSelect={handleSelectedECClassType} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">EC Class Type ID</IonLabel>
                                                <IonInput 
                                                    type="number"
                                                    value={ecClassTypeNumber}
                                                    disabled={true}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {selectedValue === "usef" && (
                                <>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <p>Select from the list of recognized USEF options.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectUSEFClasses onSelect={handleSelectedUSEFDivision} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">USEF Number</IonLabel>
                                                <IonInput 
                                                    type="number"
                                                    value={usefClassNumber}
                                                    disabled={true}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {selectedValue === "ushja" && (
                                <>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <p>And/Or Select from the list of USHJA Outreach options.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectUSHJAOutreachClass onSelect={handleSelectedUSHJASection} />
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {selectedValue === "custom" && (
                                <IonRow className="ion-padding-top">
                                    <IonCol>
                                        <p>Create your own class.</p>
                                        {/* <p>Create your own class. We will not report its results to EC, USEF or USHJA.</p> */}
                                    </IonCol>
                                </IonRow>
                            )}
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <hr/>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name <RequiredInputIndicator/></IonLabel>
                            <IonInput 
                                type="text"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Number</IonLabel>
                            <IonInput 
                                type="number"
                                value={number}
                                min="0"
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setNumber(e.detail.value ? parseInt(e.detail.value) : undefined)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel position="stacked">Discipline <RequiredInputIndicator/></IonLabel>
                        <SelectDiscipline selectedValue={discipline} onSelect={(value: string) => handleSelectedDiscipline(value)} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel>Description</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <Input
                            rows="5"
                            type="textarea"
                            name="declineReason"
                            value={description!}
                            spellCheck="true"
                            onChange={(event) => setDescription(event.target.value)}
                            data-hj-whitelist
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <AllowedNumberOfEntries selectedMin={minNumberEntries} selectedMax={maxNumberEntries} onSelect={handleSelectedEntryNumber} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <JumpHeightOptionsForm selectedJumpHeightOptions={jumpHeights} onSelect={handleSelectedJumpHeights} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <ClassEntryFee selectedEntryFee={entryFee} onSelect={handleSelectedClassEntryFee} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <ClassPointTable organizationId={organizationId} selectedPointTable={pointTable} onSelectPointTable={handleSelectedPointTable} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <ClassPrizeMoney organizationId={organizationId} selectedPrizeMoney={prizeMoney} selectedPrizeMoneyTable={prizeMoneyTable} onSelectPrizeMoney={handleSelectedPrizeMoney} onSelectPrizeMoneyTable={handleSelectedPrizeMoneyTable} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                    {Class && (
                        <IonCol offsetMd="4" sizeMd="4">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    disabled={isDisabled}
                                    className="ion-margin-top"
                                    color="danger"
                                    expand="block"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </IonButton>
                            }
                        </IonCol>
                    )}
                </IonRow>
            </form>
        </>
    );
};

export default OrganizationClassForm;