import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { EventDisciplineOption } from "../../utilities/events/EventDiscipline";
import {eventDisciplines} from "../../utilities/events/EventDiscipline";

interface _Props {
    onSelect: Function,
    selectedDisciplines?: (EventDisciplineOption[] | null)
}

interface formattedOption {
    label: string
    value: string
    discipline: EventDisciplineOption
}

const SelectDisciplines: React.FC<_Props> = ({selectedDisciplines, onSelect}) => {

    const [formattedDisciplines, setFormattedDisciplines] = useState<formattedOption[] | null | undefined>();
    const [selectedformattedDisciplines, setSelectedFormattedDisciplines] = useState<formattedOption[] | null | undefined>();

    const formatDisciplines = () => {
        let formattedDisciplines = [];
        for (var i = 0; i < eventDisciplines.length; i++) {
            let object = {
                value: eventDisciplines[i].value,
                label: eventDisciplines[i].name,
                discipline: eventDisciplines[i]
            };
            formattedDisciplines.push(object);
        }
        setFormattedDisciplines(formattedDisciplines);
    }

    const formatSelectedValues = (values: EventDisciplineOption[]) => {
        if (values) {
            let result: formattedOption[] = [];
            for (var i = 0; i < values.length; i++) {
                const currentOption = values[i];
                let object = {
                    value: currentOption.value,
                    label: currentOption.name,
                    discipline: currentOption
                };
                result.push(object);
            }
            setSelectedFormattedDisciplines(result);
        }
    };

    useEffect(() => {
        formatDisciplines();
    }, []);

    useEffect(() => {
        if (selectedDisciplines) formatSelectedValues(selectedDisciplines)
    }, [selectedDisciplines]);

    const handleOnChange = (event: any) => {
        if (event && event.length) {
            let result: string[] = [];
            for (var i = 0; i < event.length; i++) {
                result.push(event[i].value);
            }
            onSelect(result);
        }
    }

    return (
        <>
            <Select
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                defaultValue={selectedformattedDisciplines}
                value={selectedformattedDisciplines}
                menuPortalTarget={document.body}
                isMulti
                isClearable
                options={formattedDisciplines!}
                onChange={handleOnChange}
                id="select-discipline"
            />
        </>
    );
};

export default SelectDisciplines;
