export function stripPhoneNumberToDigits(phoneNumber: string) {
    // This regex expression finds any non-digit and replaces it with an empty string
    let result = phoneNumber.replace(/\D/g,"");
    return result;
}

/**
 * Format a phone number to comply with Twilio requiremenets: E.164 format
 * An explanation of E.164 can be found here: https://www.twilio.com/docs/glossary/what-e164
 * 
 * @param {string}  phonenumber               The phone number.
 * 
 * @return {string} Returns properly formatted number twilio expects.
 */
 export function formatTwilioNumber(phonenumber: string) {
    const number = phonenumber.toString();
    let result = stripPhoneNumberToDigits(number);
    // Check if phone number is too long - E.164 has a maximum of 15 digits
    if (result.length > 15) throw new Error("This phone number is too long. Please check that it is formatted correctly.");
    // Check if phone number already includes the country code (international phone number or if US phone number with a 1 already on it)
    if (result.length >= 11) return "+" + result;
    // Otherwise, all phone numbers default to a US country code
    else if (result.length === 10) return "+1" + result;
    // Check if US phone number that needs the area code
    else if (result.length === 7) throw new Error("The phone number needs an area code");
    else throw new Error("The phone number is not formatted correctly");
}

export function formatAllCountryPhoneNumber(phoneNumber: string) {
    const number = phoneNumber.toString();
    let result = stripPhoneNumberToDigits(number);
    if (result) return "+" + result;
    else new Error("The phone number needs an area code");
}

/**
 * Get the number as a string.
 * 
 * Twilio Format: +12345678901
 * The country code: +1
 * The number as a string: 2345678901
 * 
 * @param {string | null | undefined} phonenumber The phone number.
 * 
 * @return {string} Returns properly formatted number twilio expects or a default message.
 */
export function formatPhoneNumberForDisplay(phonenumber: string | null | undefined): string {
    if (!phonenumber) {
        return "Phone Number Not Provided";
    }

    const number = stripPhoneNumberToDigits(phonenumber);
    
    if (number.length === 7) {
        throw new Error("The phone number needs an area code");
    } else if (number.length < 10 || number.length > 11) {
        throw new Error("Please check the number of digits in the phone number.");
    }
    
    let result = number;
    if (number.length === 11) {
        result = "+" + result;
    }
    if (result.length === 12) {
        result = result.slice(0, 2) + "-" + result.slice(2, 5) + "-" + result.slice(5, 8) + "-" + result.slice(8);
    } else if (result.length === 10) {
        result = result.slice(0, 3) + "-" + result.slice(3, 6) + "-" + result.slice(6);
    }

    return result;
}


/**
 * Verify phone number against regex expression
 * 
 * Used before including the NPM package react-phone-number-input
 * 
 * TO DO: to regex expression: ^\+[1-9]\d{1,14}$
 * 
 * @param {string}  phonenumber               The phone number.
 * 
 * @return {boolean} Returns true or false.
 */
export function isValidPhoneNumber(phonenumber: string) {
    const number = stripPhoneNumberToDigits(phonenumber);
    if (number.length === 7) throw new Error("The phone number needs an area code");
    else if (number.length < 10 || number.length > 11) throw new Error("Please check number of digits in the phone number.");
    
    const regex = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/g;
    return regex.test(phonenumber);
}