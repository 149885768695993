import {
    IonButton,
    IonCol,
    IonItem,
    IonLabel,
    IonRow,
    IonToggle,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../../context/PersonContext";
import { Event, TaxRate } from "../../../models";
import ErrorAlert from "../../Errors/ErrorAlert";
import Spinner from "../../Spinners/Spinner";
import SelectCreateTaxRate from "../../TaxRates/SelectCreateTaxRate";
import { UpdateEventInput, InvoiceOptionsInput } from "../../../API";
import { updateEvent } from "../../../utilities/events/Event";
import SuccessBanner from "../../Banners/SuccessBanner";

interface _Props {
    event: Event
}

const EventClassFeeForm: React.FC<_Props> = ({event}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [includeTax, setIncludeTax] = useState(false);
    const [tax, setTax] = useState(0);
    const [taxRate, setTaxRate] = useState<TaxRate | null | undefined>();
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEventClassTax(event: Event) {
            if (event.invoiceOptions?.classFeeTax) setIncludeTax(true);
            setTax(event.invoiceOptions?.classFeeTax || 0);
            // setTaxRate(event.invoiceOptions?.classFeeTaxRate); //To DO
        };
        if (event) getEventClassTax(event);
    }, [event]);

    const handleSelectedTaxRate = (selectedTaxRate: TaxRate) => {
        setTaxRate(selectedTaxRate);
        if (selectedTaxRate.percentage) setTax(selectedTaxRate.percentage);
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const invoiceOptions: InvoiceOptionsInput = {
            classFeeTax: tax
        };
        const input: UpdateEventInput = {
            id: event.id,
            invoiceOptions: invoiceOptions
        };
        const updateEventResult = await updateEvent(input);
        if (updateEventResult.isSuccess) {
            setSuccess("Saved the tax rate.");
        } else {
            setError("Could not save the tax rate.");
        }
        setIsLoading(false);
    }

    return (
        <>
            {success && <SuccessBanner width="12" success={success} />}
            {error && <ErrorAlert width="12" error={error} />}
            <IonRow>
                <IonCol>
                    <IonItem color="white" lines="none">
                        <IonLabel position="stacked">Charge Taxes on class fees?</IonLabel>
                        <IonToggle 
                            color="tertiary" 
                            checked={includeTax} 
                            onIonChange={e => {
                                if (e.detail.checked === false) {
                                    setTax(0);
                                }
                                setIncludeTax(e.detail.checked)
                            }}
                        />
                    </IonItem>
                </IonCol>
                {includeTax && (
                    <>
                        <IonCol sizeXs="12" sizeMd="6">
                            <SelectCreateTaxRate organizationId={event.organizationId} selectedValue={taxRate?.id} onSelect={handleSelectedTaxRate}/>
                        </IonCol>
                    </>
                )}
            </IonRow>
            {includeTax && (
                <>
                    <IonCol sizeXs="12" sizeMd="6">
                        <h3>All class / division entry fees will include a {tax}% tax.</h3>
                    </IonCol>
                </>
            )}
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            Save
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </>
    );
};

export default EventClassFeeForm;