import React, { useEffect, useState } from "react";
import { Membership, Person } from "../../models";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";
import { IonLabel, isPlatform } from "@ionic/react";
import { isWindows } from "../../utilities/platform/Platform";
import { getPersonByPersonId } from "../../utilities/person/Person";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    isDisabled?: (boolean | undefined)
    menuPlacement?: (string | null)
    selectedValue?: (string | null)
    user?: (Person | undefined | null)
    membership?: (Membership | null)
    label?: (string | null)
    includeNewPersonOption?: (Boolean | null)
    onSelect: Function
}

const SelectPersonForMembership: React.FC<_Props> = ({isDisabled, selectedValue, menuPlacement, user, membership, label, includeNewPersonOption, onSelect}) => {

    const [peopleOptions, setPeopleOptions] = useState<formattedOption[] | undefined | null>();
    const [selected, setSelected] = useState<string | null | undefined>();

    const getPeopleOptions = async () => {
        // People options will be the list of Person records that this user has access to
        // The list will include a "New Person" option to allow the user to add a new person
        // 
        // Example: A parent user may submit a membership for one or more of their children
        // This user would have a list consisting of: 1) parent 2) first child 3) second child
        let formattedOptions: formattedOption[] = [];

        // First person in list is the current user
        if (user) {
            const formattedOption = formatPersonOption(user);
            formattedOptions.push(formattedOption);
        }

        // Next, find the person that is listed on the current membership (if this is not a brand new application)
        if (membership && membership.personId) {
            if (membership.personId === user?.id) {
                // Leave as is, the current user has been set as the selected person
            } else {
                const queryResult = await getPersonByPersonId(membership.personId);
                if (queryResult.isSuccess) {
                    const person: Person = queryResult.result;
                    const formattedPerson = formatPersonOption(person);
                    formattedOptions.push(formattedPerson);
                }
            }
        }

        // Next, find the person that is listed as the contact on the current membership (if this is not a brand new application)
        if (membership && membership.contactPersonId) {
            if (membership.contactPersonId === user?.id && membership.contactPersonId === membership.personId) {
                // Leave as is, the current user has been set as the selected person
            } else {
                const queryResult = await getPersonByPersonId(membership.contactPersonId);
                if (queryResult.isSuccess) {
                    const person: Person = queryResult.result;
                    const formattedPerson = formatPersonOption(person);
                    formattedOptions.push(formattedPerson);
                }
            }
        }

        // TO DO, add any other people this person should have access to
        // Example, through creating online entries or adding people to barns?
        // Note: For AEL, creating people in membership applications will be the main way to create new people

        if (includeNewPersonOption) {
            // Last option in list is to create a new person
            const blankOption: formattedOption = {
                value: "",
                label: "New Person",
                object: null
            };
            formattedOptions.push(blankOption);
        }

        setPeopleOptions(formattedOptions);
    }

    const formatPersonOption = (person: Person) => {
        const value = person.id;
        const label = person.firstName + " " + person.lastName; 
        let object: formattedOption = {
            value: value,
            label: label,
            object: person
        };
        return object;
    }

    useEffect(() => {
        getPeopleOptions();
    }, []);

    useEffect(() => {
        if (selectedValue) {
            setSelected(selectedValue);
        }
    }, [selectedValue]);

    const handleOnChange = (person?: any) => {
        if (person) {
            onSelect(person);
        } else {
            onSelect();
        }
    }

    return (
        <>
            <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                {label ? label : "Select who this membership is for"} {" "}
                <RequiredInputIndicator/>
            </IonLabel>
            {peopleOptions ?
                <BasicSelect formattedValues={peopleOptions} menuPlacement={menuPlacement} selectedValue={selected} isClearable={false} isDisabled={isDisabled} onSelect={handleOnChange} />
                :
                <p>Loading options...</p>
            }
        </>
    )
};

export default SelectPersonForMembership;
