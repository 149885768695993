/**
 * Taken from: https://dev.to/mwarger/aws-amplify-graphql-operations-with-typescript-and-hooks-part-4-subscriptions-h0j
 * More explanation here: https://reactjs.org/docs/hooks-custom.html
 * 
 * Explanation:
 * The ItemType represents the object that we're going to be returning and operating on in our hook.
 * The extends { id: string } means that whatever object we pass in, it must have an id of type string as a property.
 * This is useful, as we want a unique identifier for our object.
 * 
 * The itemData is used in case we want to initialize our state.
 * 
 * Note that I'm leveraging fragments to provide a single typed object that we can work with. 
 * Once created, the Amplify codegen tool will create types for your fragments that you can then use as we are in this example.
 * 
 * The second VariableType is going to be an object that represents any variables that we will be passing to our subscription graphqlOperation. 
 * 
 * 
 * The Why:
 * This is, at its core, just a wrapper over the existing Amplify tools.
 * But for TypeScript projects, it gives you the help you can use to make sure your app is doing what you expect. 
 * The nice by-product is that the API surface is more complete while abstracting away the common bits. 
 * It's generally a good practice to extract these things away and avoid having useEffect directly in your components.
 */

import React from "react";
import {API, graphqlOperation} from "aws-amplify";
import { Observable } from "zen-observable-ts";

type ConfigType<VariableType extends {}> = {
    query: string;
    key: string;
    variables?: VariableType;
};

export const useSubscriptionByItself = <
    ItemType extends { id: string },
    VariablesType extends {} = {}
>({
    config,
    itemData,
}: {
    config?: ConfigType<VariablesType>;
    itemData?: ItemType;
} = {}) => {
    // We use the ItemType parameter we passed in to type the useState function. 
    // If we passed in initial itemData, we use this as well to establish the state that will keep track of the subscription we're working with.
    const [item, update] = React.useState<ItemType | null | undefined>(itemData);

    // Amplify follows a convention for returning data in the subscriptions
    interface AmplifyReturnType {
        value: { 
            data: { 
                [key: string]: ItemType 
            } 
        }
    };

    React.useEffect(() => {
        let unsubscribe;
        if (config) {
            // Next, we will check if the config exists, as it is optional. We destructure the components and will use them to construct our subscription.
            const { query, key, variables } = config;

            // The API.graphql call actually returns Observable | Promise<> 
            // To get the autocomplete help that we expect, we need to do what is called "type narrowing" using a type guard. 
            // We do this by using the instanceof keyword to check if the type is an Observable. 
            const subscription = API.graphql(graphqlOperation(query, variables));
            if (subscription instanceof Observable) {

                // We have our subscription that's returned from our graphql call, so now we need to subscribe to it.
                // Observables operate with values by returning them as streams, so you can listen for updates to the stream by supplying callbacks - in this case, next.
                // Our next callback takes a payload (this will be the value of the next event in the stream) and we then do some destructuring on this value to get the underlying data we want.
                const sub = subscription.subscribe({
                    next: (payload: AmplifyReturnType) => {
                        try {
                            // We can use the Amplify return type convention to make sure our destructuring is correct.
                            const {
                                value: {
                                    data: { [key]: item },
                                },
                            }: AmplifyReturnType = payload;
                             
                            update(item);
                        } catch (error: any) {
                            console.error(
                                `${error.message} - Check the key property: the current value is ${key}`
                            );
                        }
                    },
                });

                // We declare a variable that will hold the function we want to run when returning from the effect.
                unsubscribe = () => {
                    sub.unsubscribe();
                };
            }
        }
        return unsubscribe;
        // Our useEffect hook takes in one dependency (an object) so we'll just stringify it 
        // If the object is changed in any way, our hook will run again and we can re-establish the appropriate subscription.
    }, [JSON.stringify(config)]);

    // The last line merely returns the data kept in state, so we can use it from the caller.
    return [item];
};