/**
 * Privacy Policy last updated by Kellam Parks and associates on Jan 4th
 * - NOTE:
 * - - Did not include the section that Kellam wrote about users under the age of 13
 * - - Instead, RingSide Pro will ask users if they are over the age of 13 during sign up.
 */
export default 
<div>
    <div>
        <h2>
            Privacy Policy
        </h2>
        <p>
            Last Updated: January 9, 2023
        </p>
        <ol type="I">
            <li>
                Introduction
                <p>
                    RINGSIDE PRO RESPECTS YOUR PRIVACY.  RingSide Pro ("us", "we", or "our") operates www.ringsidepro.com (the "Site"). 
                    This page informs you of our policies regarding the collection, use, and disclosure of personal information we collect from users of the Site (“users”). 
                    It also outlines your rights and choices regarding your personal information. 
                    Our privacy practices are subject to applicable laws in the places in which we operate. 
                    This means that we engage in practices described in this notice only if it permitted under the laws of those places. 
                    In addition: 
                </p>
            </li>
            <li>
                Information Collection and Use
                <p>
                    When you access the Site or otherwise use the services offered by RingSide Pro, you consent to the use of personal information by RingSide Pro as outlined in this Privacy Notice. 
                    This information is required to provide our products and/or services to you. 
                    If you do not provide the personal information we are requesting, it may delay or prevent us from providing our products and/or services to you. 
                </p>
                <ol type="A">
                    <li>
                        The Information We Collect:
                    </li>
                    <p>RingSide Pro collects the following categories of personal information: </p>
                    <ol type="i">
                        <li>
                            Information Provided by Users
                            <ul>
                                <li>
                                    Your name, email address, telephone number, date of birth, age, photograph, and other information provided by users in the creation of a profile and/or account with RingSide Pro.
                                </li>
                                <li>
                                    Responses to surveys, competitions, promotions. 
                                </li>
                                <li>
                                    Online entry purchases, online clinic purchases, subscriptions, text alert credit purchases. 
                                </li>
                            </ul>
                        </li>
                        <li>
                            Information Created During Use of Our Services
                            <ul>
                                <li>
                                    Geographic information (country, city, and geographic coordinates) based on IP address.
                                </li>
                                <li>
                                    Usage statistics about your interactions with RingSide Pro’s services, including browsing history, search history, events accessed, events created, time spent on pages or the Service, pages visited, features used, your search queries, click data, referrals to sites, interactions with advertisements. 
                                </li>
                                <li>
                                    Device type, operating system and version, browser, browser language, domain and other systems data, platform types.
                                </li>
                                <li>
                                    Messages and videos shared between a rider and clinician.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Information from Other Sources
                            <ul>
                                <li>
                                    Information from accounts you link to us.
                                </li>
                                <li>
                                    Financial information such as credit card information, billing address and zip code are collected directly by our GDPR compliant payment processing partners. RingSide Pro does not itself store full credit card numbers or card authorization data. 
                                </li>
                                <li>
                                    Information received from third party vendors with your consent.
                                </li>
                                <li>
                                    Users or others providing information in connection with claims or disputes.
                                </li>
                                <li>
                                    Information from publicly available sources.
                                </li>
                                <li>
                                    Law enforcement, public health, and other government authorities.
                                </li>
                                <li>
                                    Information collected through tracking and automated means. Please see our Cookie and Third-Party Technology Party Policy in part C below.
                                </li>
                            </ul>
                        </li>
                    </ol>

                    <li>
                        How We Use Personal Information
                    </li>
                    <p>RingSide Pro collects and uses personal information for the following purposes: </p>
                    <ol type="i">
                        <li>
                            Provide, maintain, and improve our services
                            <ul>
                                <li>
                                    This includes using data to create, maintain, and update accounts.
                                </li>
                                <li>
                                    Processing payments.
                                </li>
                                <li>
                                    Performing necessary operations to maintain our services, including troubleshooting software and operational problems.
                                </li>
                                <li>
                                    Maintaining customer and vendor relationships.
                                </li>
                                <li>
                                    Improving the Site and its content.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Customer Support
                            <ul>
                                <li>
                                    RingSide Pro uses the information collected to provide customer support, including to investigate and address customer concerns. 
                                </li>
                            </ul>
                        </li>
                        <li>
                            Compliance with legal and regulatory requirements
                            <ul>
                                <li>
                                    RingSide Pro uses the data it collects to defend itself, investigate, or otherwise address claims or disputes relating to RingSide Pro’s services.
                                </li>
                                <li>
                                    RingSide Pro uses the data it collects to satisfy requirements under applicable laws, regulations, operating licenses or agreements, or pursuant to legal process or governmental requests.
                                </li>
                                <li>
                                    Using IP addresses to identify a user when we feel it is necessary to protect our services, website, vendor, visitors, or others. We also reserve the right to protect the security or integrity of the Site and our business, such as by protecting or preventing fraud, unauthorized transactions, claims and other liabilities, and managing risk exposure, including by identifying potential hackers and other unauthorized users.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Marketing and Advertising
                            <ul>
                                <li>
                                    Based on your consent for marketing purposes or where you agree to participate in customer surveys, you can withdraw your consent at any time.
                                </li>
                                <li>
                                    We process information collected through cookies, such as location data, IP address, and content viewed on our website, for marketing purposes. You can manage or disable cookies at any time by adjusting your browser settings.
                                </li>
                                <li>
                                    Carrying out the terms of a survey, sweepstakes, contests, or drawing.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Research and Development 
                            <ul>
                                <li>
                                    Analyzing the use of the Site or services.
                                </li>
                                <li>
                                    For data analytics including research on usage patterns, product development, online site customization, customer habits, or other analyses.
                                </li>
                                <li>
                                    RingSide Pro utilizes third-party tracking software provided by Google Analytics, Google AdSense, and HubSpot.
                                </li>
                            </ul>
                        </li>
                    </ol>

                    <p>RingSide Pro performs the above functions on the grounds that they are necessary to enter into or fulfil the terms of a contract with you.</p>

                    <li>
                        Cookies and Third-Party Technologies
                    </li>
                    <p>
                        Cookies are small text files that a website transfers to a customer’s hard drive or web browser and that are used to track user preferences. 
                        Cookies cannot be used to run programs or deliver viruses to your computer. 
                        Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.
                    </p>
                    <p>
                        RingSide Pro uses cookies to collect anonymous traffic data including browser information, user preferences, device information, and language information to help analyze site usage and improve user experience. 
                        These cookies do not provide RingSide Pro with personally identifiable information. 
                        RingSide Pro uses the third party services Google Analytics, Google AdSense, and HubSpot to help understand traffic on our Site and track information for statistical purposes.
                    </p>
                    <p>
                        You can accept or decline cookies. 
                        Most web browsers automatically accept cookies, but you can modify your browser settings to disable cookies if you prefer (check your browser's Help page). 
                        If you choose to decline cookies, you may not be able to experience all the features of the Site and Services. 
                        For more information on how to manage or restrict the use of cookies and to opt out of all interest-based advertising, please visit the Network Advertising Initiative or Your Ad Choices. 
                        For End Users located in European Territories, visit the EDAA opt-out page here: https://youronlinechoices.eu/.
                    </p>

                    <li>
                        Data Sharing and Disclosure
                    </li>
                    <p>RingSide Pro does not sell, trade, or rent your personal data to third parties. We disclose personal information that we collect about you to the following categories of third parties:</p>
                    <ol type="i">
                        <li>
                            Business Affiliates
                            <ul>
                                <li>
                                    We share data with our affiliates to help us provide our services. 
                                    Our business affiliates include competition companies and their management teams. 
                                    When competition management teams use RingSide Pro to collect online registrations for their event, then they will be able to access data about the users that registered for their event such as names, contact information and date of birth. 
                                    When competition management teams use RingSide Pro to collect text message alert registrations for their event, then they will be able to access data about the users that registered for their event such as names, classifications (such as trainer/coach, rider, parent), filter settings and phone numbers.
                                </li>
                            </ul>
                        </li>
                        <li>
                            General Public
                            <ul>
                                <li>
                                    Questions or comments from users submitted through public channels such as social media pages may be viewable by the public, including any data included in the question or comment submitted by the user.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Service providers: We disclose your personal data to third party service providers whom we engage to perform services for us. These companies are bound by contractual obligations to keep personal data confidential and use the data only for the purposes for which it has been disclosed to them. These service providers include:
                            <ul>
                                <li>
                                    Information technology service providers: We engage information technology service providers to help service our platform, facilitate the functioning of the platform and our services, troubleshoot, and resolve issues, secure the platform, and prevent fraud and abuse. 
                                    The categories of personal data we share with our information technology service providers are identifiers, profile information, user account information, commercial information, shared content, device type information, internet activity, and sensitive information.
                                </li>
                                <li>
                                    Analytics or marketing service providers: We engage analytics or marketing service providers to help us analyze traffic, purchases, and usage patterns for the purpose of helping us understand our users so that we develop products, customize our online platform, engage in targeted marketing, and market and administer surveys and promotions. 
                                    The categories of personal data we share with our analytics or marketing service providers are identifiers, device type information, internet or other electronic network activity, and social media information.
                                </li>
                                <li>
                                    Payment processors: We engage payment card service providers to collect and process payment card data for purchases made on our sites. 
                                    This data is collected directly by our payment processing partners through RingSide Pro sites, but RingSide Pro itself does not store full credit card numbers or card authentication data.
                                    The categories of personal data we share with our payment card service providers are identifiers and financial information.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Government and Law Enforcement
                            <ul>
                                <li>
                                    We disclose any and all categories of personal data as required by a court order or any other legal or regulatory requirement, including in response to requests from public and government authorities, or to protect our rights, privacy, safety, or property as necessary if we believe that there has been a violation of our terms of use or of our rights or the rights of any third party.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Successors
                            <ul>
                                <li>
                                    In the event of a sale, merger, consolidation, change in control, transfer of substantial assets, reorganization, or liquidation, we may transfer, sell, or assign to third parties information concerning your relationship with us, including, without limitation, any and all categories of personal data that we have collected about you in accordance with applicable law. We also may disclose this information in connection with the due diligence for any such transaction. 
                                    Any acquirer or successor may continue to use your data as set forth in this Privacy Notice.
                                </li>
                            </ul>
                        </li>
                        <li>
                            With User’s Consent
                            <ul>
                                <li>
                                    RingSide Pro may share a user’s data other than as included in this Policy only after notification to the user and consent by the user to sharing the information.
                                </li>
                            </ul>
                        </li>
                        <li>
                            International Data Transfers
                            <ul>
                                <li>
                                    The Site is controlled and operated by us from the United States and is not intended to subject RingSide Pro to the laws or jurisdiction of any state, country, or territory other than that of the United States.
                                </li>
                                <li>
                                    RingSide Pro will not transfer personal information across borders except as necessary to perform our Services and in accordance with applicable law.
                                </li>
                            </ul>
                        </li>
                    </ol>

                    <li>
                        Data Retention and Deletion
                    </li>
                    <ol type="i">
                        <li>
                            RingSide Pro maintains your personal information for as long as necessary to perform the purposes described above, including providing its services and complying with any/all legal obligations.
                        </li>
                        <li>
                            At the appropriate time, RingSide Pro will take all reasonable steps to destroy your personal data in a safe manner.
                        </li>
                        <li>
                            RingSide Pro may retain personal information after a user’s deletion request to comply with legal requirements or due to issues related to a user’s account.
                        </li>
                    </ol>

                    <li>
                        Data Protection
                    </li>
                    <ol type="i">
                        <li>
                            We have reasonable procedures in place designed to comply with applicable local, state, and federal laws and international regulations regarding the storing and dissemination of personal information.
                        </li>
                        <li>
                            We strive to comply with the controls set forth in applicable data protection laws.
                        </li>
                        <li>
                            RingSide Pro has implemented appropriate technical and organizational safeguards to protect your data including:
                            <ul>
                                <li>
                                    Data Minimization: 
                                    RingSide Pro collects user data in a way where data is only required when necessary to complete a function. 
                                    For example, when registering for a horse show users must provide their contact information so that the horse show management team can contact the users with questions or updates.
                                </li>
                                <li>
                                    Data Accuracy: 
                                    RingSide Pro strives to collect accurate data and rectifies any inaccurate data upon discovery/notification.
                                </li>
                                <li>
                                    Data Storage: 
                                    RingSide Pro stores data in an encrypted database in which users are given unique IDs (uuid). 
                                    Data retention and deletion are explained in the prior section.
                                </li>
                                <li>
                                    Data Integrity: 
                                    RingSide Pro allows users to create accounts through its website and requires user accounts to have a password with at least 8 characters. 
                                    RingSide Pro encrypts passwords. 
                                    No other user should be able to access a user’s account as long as the passwords are stored properly by the user, it is not shared with others, and the users take other reasonable precautions. 
                                    RingSide Pro also protects against accidental loss, destruction, or damage of user data through the use of secure database backups.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Unfortunately, no method of transmission over the Internet or method of electronic storage is 100% secure. 
                            As a result, while we will strive to protect your personal data, we cannot and do not guarantee or warrant the absolute security of any of your personal data.
                            We will not be and are not liable for disclosures of your personal data due to errors in transmission, networks that we do not control, nor unauthorized acts of third parties.
                        </li>
                        <li>
                            In the event that we are required by law to inform you of any unauthorized access to your personal data, we may notify you electronically or in writing in accordance with applicable law.
                        </li>
                    </ol>
                </ol>
            </li>
            <li>
                Choice and Transparency
                <ol type="A">
                    <li>
                        Below is a summary of rights for those individuals in the United States:
                        <ol type="i">
                            <li>
                                Data Access
                                <ul>
                                    <li>
                                        You may request the following information relating to our collection, use, and disclosure of your personal data during the past 12 months:
                                        <ul>
                                            <li>
                                                The categories and specific pieces of personal data we have collected, sold, shared, or disclosed for a business purpose; 
                                            </li>
                                            <li>
                                                The categories of sources from which we collected the data;
                                            </li>
                                            <li>
                                                The business or commercial purpose(s) for collecting, selling, or sharing the data, and
                                            </li>
                                            <li>
                                                The categories of third parties to whom we disclosed, sold, or shared your data (as applicable).
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Portability
                                <ul>
                                    <li>
                                        You have the right, upon request, to obtain a copy of your personal information from us by mail or electronically without charge and in a readily usable format.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Correction
                                <ul>
                                    <li>
                                        You may request that we correct inaccurate information about you.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Deletion
                                <ul>
                                    <li>
                                        You may request that we delete your account and personal data.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Opt-out of Sales and Sharing
                                <ul>
                                    <li>
                                        RingSide Pro requires users to opt-in to having their data collected, processed, and stored. 
                                        This opt-in requirement is required under the GDPR and is more restrictive that the opt-out method required in the United States. 
                                        The Opt-In method allows you, the user, to make the initial choice regarding whether you agree to having your data processed. 
                                        Should you later choose you no longer consent to these activities, you have the right to later direct RingSide Pro not to sell or share your personal information. 
                                        A link to an opt-out page on our website is available here: www.ringsidepro.com/contact-us.
                                        <ul>
                                            <li>
                                                “Sale” refers to the disclosure of personal information to a third party in exchange for money or other value.
                                            </li>
                                            <li>
                                                “Share” refers to the disclosure of personal information to a third party for purposes of targeted advertising.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Limit the Use and Disclosure
                                <ol>
                                    <li>
                                        You may request that we limit our use of your sensitive personal information to uses that are:
                                        <ol type="a">
                                            <li>
                                                Necessary to perform or provide the goods reasonably expected by an average consumer who requests those goods or services.
                                            </li>
                                            <li>
                                                Necessary for business purposes.
                                            </li>
                                            <li>
                                                Authorized by law.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                No Discrimination or Retaliation
                                <ol>
                                    <li>
                                        You have the right not to be discriminated against or retaliated against for exercising these rights.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        Below is a summary of rights for those individuals subject to the GDPR:
                        <ol type="i">
                            <li>
                                Right of Access
                                <ol>
                                    <li>
                                        You can request access to the personal data we hold about you, including:
                                        <ol type="a">
                                            <li>
                                                The categories of data we process.
                                            </li>
                                            <li>
                                                The purposes of the data processing.
                                            </li>
                                            <li>
                                                The period during which we retain that data.
                                            </li>
                                            <li>
                                                Third parties to which we disclose that data.
                                            </li>
                                            <li>
                                                Upon request, we can either provide an overview of the data we hold, or we can provide you with a copy of your Personal Data.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Right to Rectification
                                <ol>
                                   <li>
                                        If your personal data is inaccurate or incomplete, you are entitled to ask that we correct or complete it. If we shared your personal data with others, we will tell them about the correction where possible. If you ask us, and where possible and lawful to do so, we will also tell you with whom we shared your personal data so you can contact them directly.
                                   </li>
                                </ol>
                            </li>
                            <li>
                                Right to Erasure
                                <ol>
                                   <li>
                                        You may ask us to erase your personal data in the following circumstances:
                                        <ol type="a">
                                            <li>
                                                in the case your personal data is no longer needed for the purposes for which it was collected.
                                            </li>
                                            <li>
                                                if you have withdrawn your consent and there is no other legal basis for the processing of your personal data.
                                            </li>
                                            <li>
                                                if you have filed an objection to our processing of certain personal data and there are no overriding legitimate reasons for continued processing of such personal data.
                                            </li>
                                            <li>
                                                if your personal data is being processed unlawfully.
                                            </li>
                                            <li>
                                                if your personal data must be deleted to fulfil a legal obligation.
                                            </li>
                                            <li>
                                                if we share your personal data with others, we will alert them to the need for erasure where possible. If you ask us, and where possible and lawful to do so, we will also tell you with whom we shared your personal data so you can contact them directly.
                                            </li>
                                        </ol>
                                   </li>
                                </ol>
                            </li>
                            <li>
                                Right to Restrict Processing
                                <ol>
                                    <li>
                                        You may ask us to restrict or ‘block’ the processing of your personal data in the following circumstances:
                                        <ol type="a">
                                            <li>
                                                we processed or will process inaccurate personal data.
                                            </li>
                                            <li>
                                                we processed your personal data unlawfully.
                                            </li>
                                            <li>
                                                we don't need to process your personal data, but we need to keep it to allow you to establish, exercise, or defend a legal claim.
                                            </li>
                                            <li>
                                                you exercised your right to object processing, but we are still validating your request.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        We will tell you before we lift any restriction on processing. 
                                        If we shared your personal data with others, we will tell them about the restriction where possible. 
                                        If you ask us, and where possible and lawful to do so, we will also tell you with whom we shared your personal data so you can contact them directly.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Right to Data Portability
                                <ol>
                                    <li>
                                        You have the right to obtain your personal data from us that you consented to give us or that was provided to us as necessary in connection with our contract with you where we process that personal data in an automated way. 
                                        We will give you your personal data in a structured, commonly-used and machine-readable format. 
                                        You may reuse it elsewhere.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Right to Object
                                <ol>
                                    <li>
                                        You may ask us at any time to stop processing your personal data, and we will do so if we are relying on a legitimate interest to process your personal data — unless we demonstrate compelling legitimate grounds for the processing.
                                    </li>
                                    <li>
                                        If your objection is related to receiving marketing communications, please hit the unsubscribe link on the communication you no longer wish to receive.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Rights in relation to automated decision-making and profiling
                                <ol>
                                    <li>
                                        You have the right to be free from decisions based solely on automated processing of your personal data, including profiling, unless this is necessary in relation to a contract between you and us or you provide your explicit consent to this use. 
                                        RingSide Pro does not perform automated decision-making.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Right to Withdraw Consent 
                                <ol>
                                    <li>
                                        If we rely on your consent to process your personal data, you have the right to withdraw that consent at any time, but this will not affect any processing of your data that has already taken place.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        Exercising Users’ Rights
                        <ol type="i">
                            <li>
                                Users can exercise their rights by sending an email to hello@ringsidepro.com or by submitting a personal information or erasure request through the following link available www.ringsidepro.com/contact-us.
                            </li>
                            <li>
                                California Residents can also contact RingSide Pro via the following mailing address: 203 Riverside Drive, Newport News, VA 23606, by sending an email to hello@ringsidepro.com, or going to www.ringsidepro.com/contact-us.
                            </li>
                            <li>
                                Only you or an authorized agent acting on your behalf may make a request related to your personal data. 
                                We may need to verify your identity (and if applicable, your agent’s authority to make the request on your behalf) before we are able to process your request. 
                                We will ask you to provide certain information to verify your identity: name, e-mail address, and telephone number. 
                                If you have an authorized agent who makes the request, then 
                                (i) we need proof that you have provided the agent with power of attorney;
                                or (ii) you must provide the agent with signed permission to submit a request to know or delete;
                                you must verify your identity directly with us; or you must confirm with us that you provided the authorized agent permission to submit the request. 
                                During the verification process, we will try to match the information you provided against known information in our databases. 
                                If we still need additional information, we also may contact you or your authorized agent by e-mail or telephone number to ask you additional questions about the products or services you purchased or your last order with us, so that we can match your identity with the personal data we have about you. 
                                In some instances, we may ask you to declare under penalty of perjury that you are the consumer whose personal information is the subject of the request.
                                <ul>
                                    <li>
                                        If we cannot verify your identity or sufficiently differentiate you from another consumer or cannot confirm an agent’s authority to act on your behalf, we are not required to fulfill your request.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                We will respond within 30 days of receiving a personal information request.
                            </li>
                        </ol>
                    </li>
                </ol>
            </li>
            {/* <li>
                Privacy of Minors
                <p>
                    RingSide Pro is committed to protecting the privacy of children who use the Site and our applications. 
                    Our policies regarding our information collection, disclosure, and parental consent practices with respect to information provided by children under the age of 13 (“child” or “children”) who use our Site are in accordance with the Children’s Online Privacy Protection Act (“COPPA”) and apply to our practices in the United States regarding children’s personal information.
                </p>
            </li> */}
            <li>
                Contact Us
                <p>
                    If you have any questions or for more information about RingSide Pro’s Privacy Notice, the information practices of the Site, or your interaction with the Site, you can send us an email at: hello@ringsidepro.com.
                </p>
            </li>
            <li>
                Updates
                <p>
                    We may occasionally update this Notice. Any changes to this Policy will be shared to users via email.  
                    Use of services after an update constitutes consent to the updated notice to the extent permitted by law. 
                    RingSide Pro encourages users to periodically review this Policy for the latest information regarding our privacy practices.
                </p>
            </li>
            <li>
                Region Specific Provisions
                <ol type="A">
                    <li>
                        GDPR - General Data Protection Regulation (EU)
                        <ol type="i">
                            <li>
                                RingSide Pro believes that the GDPR provides the gold standard for personal data protection and therefore has based its entire privacy policy on the premise that all users should be afforded the rights guaranteed by the GDPR. 
                                As such, many of the specific provisions of the GDPR are covered in the main privacy policy.
                            </li>
                            <li>
                                Under the GDPR and when processing your data, RingSide Pro will act as either a Processor (or sub-Processor, as applicable) or a Controller, depending on the purpose for the data processing. 
                                While processing Personal Data for the provision of RingSide Pro’s Services, RingSide Pro’s Customer is the Controller (or a Processor processing Personal Data on behalf of a third party Controller), and RingSide Pro is a Processor (or sub-Processor, as applicable). 
                                In this case, the end-user of the service is the data subject.
                            </li>
                            <li>
                                As Processor, RingSide Pro will comply with the instructions contained in this document, in the RingSide Pro Terms of Service, and in any agreements between you and RingSide Pro, or other instructions that you provide later.
                            </li>
                            <li>
                                As Controller, RingSide Pro will use, with your consent, your Personal Data for improving our services. 
                            </li>
                            <li>
                                RingSide Pro ensures that the collection, use, and retention of Personal Data transferred from the European Union, Switzerland, and the United Kingdom to the United States is authorized via a suitable legal vehicle, such as the relevant Standard Contractual Clauses.
                            </li>
                            <li>
                                As noted in the Section titled “Choice and Transparency” above, you have a right to access your data, correct or remove it, or completely withdraw your consent for processing it at any time. 
                                Such requests may be submitted here: <a href="https://www.ringsidepro.com/contact-us/">https://www.ringsidepro.com/contact-us/</a>. 
                                The withdrawal of a consent does not affect the lawfulness of processing based on consent before its withdrawal.
                            </li>
                            <li>
                                RingSide Pro maintains a record of the processing activities it carries out in the course of using personal data to deliver its services, and it will provide this report (Art. 30 “Record of Processing Activities”) to supervisory authorities upon written demand.
                            </li>
                            <li>
                                If you have questions about our Privacy Policy or data practices, you may contact RingSide Pro at hello@ringsidepro.com. 
                                You also have the right to lodge a complaint with the national supervisory authority of your residence, place of work, or where you believe an infringement of your GDPR rights may have occurred.
                            </li>
                        </ol>
                    </li>
                    <li>
                        CCPA/CPRA - California Consumer Protection Act / California Privacy Rights Act
                        <ol type="i">
                            <li>
                                The California Consumer Privacy Act of 2018 (“CCPA”) and the subsequent California Privacy Rights Act (“CPRA”) requires businesses that collect personal data of California residents to make certain disclosures regarding how they collect, use, and disclose such information.
                                This CCPA/CPRA-specific section addresses those requirements.
                            </li>
                            <li>
                                Personal Data Collection and Sharing: 
                                RingSide Pro collects the Personal Data as set forth in the Section titled “Data Sharing and Disclosure” of the Privacy Policy. 
                                For the purposes of the CCPA/CPRA, the term “Personal Data” means “Personal Data,” as defined in the CCPA. 
                                For purposes of CCPA/CPRA, we collect the following categories of Personal Data from you when you Interact with us and we share that Personal Data for a business purpose as follows:
                                <ul>
                                    <li>
                                        You can find the types of Personal Data we collect about you in the Section titled "The Information We Collect" of the Privacy Policy, but since California regulations have specific terms, please find below the correct applicable terms:
                                        <ul>
                                            <li>
                                                Identifiers (e.g., unique personal identifiers)
                                            </li>
                                            <li>
                                                Any categories of Personal Data described in subdivision (e) of Section 1798.80. (e.g., name, address, telephone number, passport number, employment history, bank account number, etc.)
                                            </li>
                                            <li>
                                                Characteristics of protected classifications under California or federal law (e.g., gender, marital status)
                                            </li>
                                            <li>
                                                Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding the interaction with the website, applications, or advertisements (e.g. email conversations).
                                            </li>
                                            <li>
                                                Geolocation data (e.g., your location).
                                            </li>
                                            <li>
                                                Professional or employment-related information.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Sources and Uses of Personal Data: 
                                        The sources from which we collect Personal Data are described in the Section titled “The Information We Collect” of the Privacy Policy.
                                    </li>
                                    <li>
                                        Purposes and Uses for Collecting Personal Data: 
                                        We collect Personal Data identified in the list above to communicate with you, for marketing and promotional purposes, and to provide and improve our services and other purposes set forth in the Section titled “How We Use Personal Information” of the Privacy Policy.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>Subprocessors.</p>
                                Our subprocessors have privacy and security practices in place to ensure compliance with the CCPA and have contractual requirements to protect the privacy and security of the personal data that they sub-process. 
                                As described in the Section titled “How We Use Personal Information” of the Privacy Policy, we share some personal data with these sub-processors to help us provide, manage, secure, and improve the Services we provide. 
                            </li>
                            <li>
                                <p>Sale or Sharing of Personal Data.</p>
                                In this context, the word “sale” means any “selling, renting, releasing, disclosing, disseminating, making available, transferring or otherwise communicating orally, in writing, or by electronic means, a consumer's Personal Data by the business to another business or a third party, for monetary or other valuable consideration.” 
                                Similarly, the word “share” means the disclosure of Personal Data “for cross-context behavioral advertising, whether or not for monetary or other valuable consideration.”
                            </li>
                            <li>
                                RingSide Pro does not sell or share your Personal Data as those terms are defined under California law. 
                                RingSide Pro will only disclose your Personal Data as detailed in this Privacy Policy.
                            </li>
                            <li>
                                <p>California Consumer Rights: </p>
                                California law gives California residents the right to make the following requests with regard to certain information we collect about them, at no charge, two times every 12 months. 
                                <ul>
                                    <li>
                                        Disclosure Right. 
                                        The right to request disclosure of RingSide Pro’s data collection and sales practices, including categories of information we have collected, the source of the information, our use of the information, and, if the information was disclosed or sold to third parties, the categories of Personal Data disclosed or sold to third parties and the categories of third parties to whom such information was disclosed or sold.
                                    </li>
                                    <li>
                                        Copy Right. 
                                        The right to request a copy of the specific Personal Data we collected about you in the past 12 months.
                                    </li>
                                    <li>
                                        Deletion Right. 
                                        The right to request deletion of Personal Data we have collected, subject to certain exemptions (for example, where the information is used by us to detect security incidents, debugging or to comply with a legal obligation, and where the Personal Data is being used on an anonymized and aggregated basis consistent with the requirements of the CCPA/CPRA).
                                    </li>
                                    <li>
                                        Opt-out Right. 
                                        The right to opt-out of the sale or sharing of your Personal Data, if applicable. 
                                        However, RingSide Pro strictly does not sell or share, as defined by the CCPA and the CPRA, as applicable, your Personal Data.
                                    </li>
                                    <li>
                                        Non-discrimination Right. 
                                        The right not to be discriminated against when exercising any of these rights.
                                    </li>
                                    <li>
                                        Exercising Your Rights: 
                                        You may exercise these rights by submitting a request through any of the methods listed above for the GDPR rights. 
                                        Following the submission of your request, RingSide Pro will verify your identity and respond to you within 30 days of receipt of the request. 
                                        When you update information, we may maintain a copy of the unrevised information in our records. 
                                        RingSide Pro does not and will not discriminate against you for exercising your rights under the CCPA/CPRA.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Appealing RingSide Pro’s Decision
                                <ul>
                                    <li>
                                        If RingSide Pro declines to take action on your request as permitted by law, RingSide Pro will provide you with a written explanation of the reasons for our decision within 30 days of receipt of the request, or as otherwise permitted by law.
                                    </li>
                                    <li>
                                        Users are permitted the opportunity to appeal such decision. 
                                        Instructions on how to appeal the decision will be included in the letter providing justification for its decision not to act on the user’s request.
                                    </li>
                                </ul>
                            </li>
                        </ol> 
                    </li>
                </ol>
            </li>
        </ol>
    </div>
</div>