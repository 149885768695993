import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateAuditorInput, CreateAuditorMutation, DeleteAuditorInput, DeleteAuditorMutation, GetAuditorQuery, ListAuditorsQuery, UpdateAuditorInput, UpdateAuditorMutation } from "../../API";
import moment from "moment";
import { formatTwilioNumber } from "../contact/FormatPhoneNumber";
import { Auditor } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: auditor. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created auditor.
*/
export async function createAuditor(input: CreateAuditorInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Auditor", "No input", "Create Auditor received no input.");
   try {
        const fullInput: CreateAuditorInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createAuditor, { input: fullInput }))) as GraphQLResult<CreateAuditorMutation>;
       else result = (await API.graphql({
           query: mutations.createAuditor,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateAuditorMutation>;
       const auditor = result.data?.createAuditor;
       return formatResult(true, "Auditor", auditor, "Successfully created the auditor.");
   } catch (error: any) {
       return formatResult(false, "Auditor", error, "Error creating record in the database for type: auditor");
   }
}

/**
* Handle updating a new record in the database for type: auditor. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated auditor.
*/
export async function updateAuditor(input: UpdateAuditorInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Auditor", "No input", "Update Auditor received no input.");
   try {
        const fullInput: UpdateAuditorInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateAuditor, { input: fullInput }))) as GraphQLResult<UpdateAuditorMutation>;
       else result = (await API.graphql({
           query: mutations.updateAuditor,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateAuditorMutation>;
       const auditor = result.data?.updateAuditor;
       return formatResult(true, "Auditor", auditor, "Successfully updated the auditor.");
   } catch (error: any) {
       return formatResult(false, "Auditor", error, "Error updating record in the database for type: auditor");
   }
}

/**
* Handle deleting a new record in the database for type: auditor. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted auditor.
*/
export async function deleteAuditor(input: DeleteAuditorInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Auditor", "No input", "Delete Auditor received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteAuditor, { input: input }))) as GraphQLResult<DeleteAuditorMutation>;
       else result = (await API.graphql({
           query: mutations.deleteAuditor,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteAuditorMutation>;
       const auditor = result.data?.deleteAuditor;
       return formatResult(true, "Auditor", auditor, "Successfully deleted the auditor.");
   } catch (error: any) {
       return formatResult(false, "Auditor", error, "Error deleting record in the database for type: auditor");
   }
}

/**
* Get all records in the database for type: auditor. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the auditor object
*/
export async function getAllAuditors(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listAuditors,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListAuditorsQuery>;

        let items = result.data?.listAuditors?.items as Auditor[];
        let nextToken = result.data?.listAuditors?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listAuditors,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListAuditorsQuery>;

            const nextItems = nextResult.data?.listAuditors?.items as Auditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listAuditors?.nextToken;
        }

        return formatResult(true, "Auditor", items, "Successfully got the auditors.");
    } catch (error: any) {
        return formatResult(false, "Auditor", error, "Error reading record in the database for type: auditors");
    }
}

/**
* Read a specific record in the database for type: auditor. 
* 
* @param {string}  id                  The auditor id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the auditor object
*/
export async function getAuditorById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getAuditor,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetAuditorQuery>;
       const auditor = result.data?.getAuditor;
       return formatResult(true, "Auditor", auditor, "Successfully got the auditor.");
   } catch (error: any) {
       return formatResult(false, "Auditor", error, "Error reading record in the database for type: auditor");
   }
}

/**
* Get all records in the database that match the given criteria for type: auditor. 
* 
* @param {string}  phoneNumber         The phone number formatted as a Twilio number
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the auditor object
*/
export async function getAuditorByPhoneNumber(phoneNumber: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {phoneNumber: {eq: formatTwilioNumber(phoneNumber)}};
        const result = (await API.graphql({
            query: queries.listAuditors,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListAuditorsQuery>;

        let items = result.data?.listAuditors?.items as Auditor[];
        let nextToken = result.data?.listAuditors?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listAuditors,
                variables: {
                    limit: 1000,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListAuditorsQuery>;

            const nextItems = nextResult.data?.listAuditors?.items as Auditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listAuditors?.nextToken;
        }

        if (items && items[0]) return formatResult(true, "Auditor", items[0], "Successfully got the auditor.");
        else return formatResult(false, "Auditor", null, "Could not find the auditor.");
    } catch (error: any) {
        return formatResult(false, "Auditor", error, "Error reading record in the database for type: auditor");
    }
}

/**
* Get all records in the database that match the given criteria for type: auditor. 
* 
* @param {string}  personId            The person id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the auditor object
*/
export async function getAuditorsByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listAuditors,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListAuditorsQuery>;

        let items = result.data?.listAuditors?.items as Auditor[];
        let nextToken = result.data?.listAuditors?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listAuditors,
                variables: {
                    limit: 1000,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListAuditorsQuery>;

            const nextItems = nextResult.data?.listAuditors?.items as Auditor[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listAuditors?.nextToken;
        }

        if (items) return formatResult(true, "Auditor", items, "Successfully got the auditors.");
        else return formatResult(false, "Auditor", null, "Could not find the auditors.");
    } catch (error: any) {
        return formatResult(false, "Auditor", error, "Error reading record in the database for type: auditor");
    }
}