import moment from "moment";
import { Membership, OrganizationMembershipType } from "../../models";
import CONSTANT from "../../constant/constant";
import { MembershipTypeSchedule } from "../../models";

export function getMembershipStatus(currentMembership: Membership) {
    let status = "";
    // A membership is active if the today's date has not hit the date the membership ends OR if the membership is lifetime (aka no dateMembershipEnds)
    const isActive = (currentMembership.dateMembershipEnds) ? (moment(new Date()).isSameOrBefore(moment(currentMembership.dateMembershipEnds))) : true;
    if (isActive) {
        status = currentMembership.membershipStatus === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE ? 
            CONSTANT.MEMBERSHIP.APPLICATION_STATUS.ACTIVE : CONSTANT.MEMBERSHIP.APPLICATION_STATUS.APPLICATION_INCOMPLETE;
    } else {
        status = "Expired";
    }
    return status;
}

export function getDateMembershipEnds(membershipType: OrganizationMembershipType) {
    let dateMembershipEnds = "";
    try {
        const schedule =  membershipType.schedule;
        const isStartAtTimeOfPurchase = membershipType.isStartAtTimeOfPurchase;
        const setStartDate = membershipType.setStartDate;
        if(schedule === MembershipTypeSchedule.ANNUAL) {
            if(isStartAtTimeOfPurchase) {
                // End date should be one year after today's date (because the membership starts on the date of purchase)
                dateMembershipEnds = moment().add(1, "year").format("YYYY-MM-DD");
            } else {
                // End date should be one year after the set start date
                dateMembershipEnds = moment(setStartDate).year((new Date()).getFullYear()).add(1, "year").format("YYYY-MM-DD");
            }
        } else if (schedule === MembershipTypeSchedule.LIFE) {
            dateMembershipEnds = "";
        }
    } catch (error) {
        const message = "Could not get the date the membership should end: " + error;
        console.error(message);
    }
    return dateMembershipEnds;
}