import { Event, EventAuditor, EventAuditorFilter, EventFilter, Organization, Person } from "../../models";
import { getEventFiltersByEventId, getEventFiltersByOrganizationId } from "../eventFilter/EventFilter";
import { getEventAuditorsWithFilterByEventId } from "../eventAuditor/EventAuditor";
import { sortEventAuditorsByName } from "../eventAuditor/SortEventAuditors";
import moment from "moment";
import { getAllEvents } from "../events/Event";
import { getOrganizationAuditorsWithFilterByOrganizationId } from "../organizationAuditor/OrganizationAuditor";
import { downloadCSVFile } from "./ReportDownloadCSV";
require('jspdf-autotable');

export const headerRow = [
    "Phone Number",
    "Name",
    "Date Created",
    "Date Last Updated",
    "Status"
];

export async function generateEventSubscriberReport(event: Event) {

    let rows = [];

    // Add any event filters to the header row
    let eventFilters: EventFilter[] = [];
    const eventFiltersResult = await getEventFiltersByEventId(event.id);
    if (eventFiltersResult.isSuccess) {
        eventFilters = eventFiltersResult.result;
        for (let i = 0; i < eventFilters.length; i++) {
            const eventFilter: EventFilter = eventFilters[i];
            headerRow.push(eventFilter.name || "");
        }
    }

    rows.push(headerRow);
    
    const queryResult = await getEventAuditorsWithFilterByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventAuditors = queryResult.result as EventAuditor[];
        const sorted = sortEventAuditorsByName(eventAuditors);
        if (sorted && sorted.length > 0) {
            for (let i = 0; i < sorted.length; i++) {
                let currentRow: any[] = [];
                const eventAuditor = sorted[i];
                currentRow.push(eventAuditor.phoneNumber || "");
                currentRow.push(eventAuditor.name || "");
                currentRow.push(eventAuditor.createdOn ? moment(eventAuditor.createdOn).format("MM/DD/YYYY") : "");
                currentRow.push(eventAuditor.updatedOn ? moment(eventAuditor.updatedOn).format("MM/DD/YYYY") : "");
                currentRow.push(eventAuditor.status === "paused" ? "PAUSED" : (eventAuditor.status === "STOPPED" ? "stopped" : "active"));
                for (let j = 0; j < eventFilters.length; j++) {
                    const currentEventFilter = eventFilters[j];
                    const auditorEventFilters = eventAuditor.filters;
                    if (auditorEventFilters) {
                        const list: any = auditorEventFilters;
                        const items = list.items;
                        const found = items.find((filter: EventAuditorFilter) => filter.filterId === currentEventFilter.id);
                        if (found) {
                            const options: string = found.options.toString();
                            if (options) {
                                currentRow.push(options.replace(/,/g,';'));
                            } else {
                                currentRow.push("");
                            }
                        } else {
                            currentRow.push("");
                        }
                    } else {
                        currentRow.push("");
                    }
                }
                rows.push(currentRow);
            }
        }
    }

    const fileName = event.nickname ? (event.nickname + " subscribers.csv") : (event.name ? event.name + " subscribers.csv" : "subscribers.csv");
    downloadCSVFile(fileName, rows);
}

export async function generateAdminEventAuditorReport(event: Event, personList: Person[]) {

    const adminReportHeaderRow = [
        "FirstName",
        "LastName",
        "EmailAddress",
        "EventName"
    ];

    let rows = [];

    rows.push(adminReportHeaderRow);

    for (let i = 0; i < personList.length; i++) {
        const currentPerson: Person = personList[i];
        if (currentPerson && currentPerson.email) {
            let currentRow: any[] = [];
            currentRow.push(currentPerson.firstName || "");
            currentRow.push(currentPerson.lastName || "");
            currentRow.push(currentPerson.email || "");
            currentRow.push(event.name || "");
            rows.push(currentRow);
        }
    }
    
    const fileName = event.nickname ? (event.nickname + " auditors.csv") : (event.name ? event.name + " auditors.csv" : "auditors.csv");
    downloadCSVFile(fileName, rows);
}

export async function generateHorseShowGITNBSubscriberReport() {

    const gitnbHeaderRow = [
        "Event Name",
        "Event Start Date",
        "Total Subscribers",
        "# Subscribed to GITNB",
        "Percentage"
    ];

    let rows = [];

    rows.push(gitnbHeaderRow);

    // Get the current list of GITNB subscribers
    let gitnbAuditors: EventAuditor[] = [];
    const auditorQuery = await getEventAuditorsWithFilterByEventId("4ddb21d4-ed5d-4941-ad6a-78e2e6ae64f0");
    if (auditorQuery.isSuccess) {
        gitnbAuditors = auditorQuery.result;
    }

    // Get the list of events to show in the report
    const eventQueryResult = await getAllEvents();
    if (eventQueryResult.isSuccess) {
        const eventList: Event[] = eventQueryResult.result;

        if (eventList && eventList.length > 0) {
            for (let i = 0; i < eventList.length; i++) {
                const currentEvent = eventList[i];
                
                // Only add a row for this event if the event used the text alerts
                if (currentEvent.eventOptions?.usesTextAlerts) {

                    // Only add a row for this event if the event is after June 1, 2022 (when we added in the GITNB Alerts sign up)
                    if (moment(currentEvent.startDate).isAfter("June 1, 2022")) {
                        const subscriberQuery = await getEventAuditorsWithFilterByEventId(currentEvent.id);
                        if (subscriberQuery.isSuccess) {
                            const eventAuditorList: EventAuditor[] = subscriberQuery.result;
    
                            if (eventAuditorList && eventAuditorList.length > 0) {
                                const totalEventSubscribers = eventAuditorList.length;
                                let currentEventGITNBSuscribers = 0;
    
                                for (let j = 0; j < eventAuditorList.length; j++) {
                                    const eventSubscriber = eventAuditorList[j];
                                    const index = gitnbAuditors.findIndex(s => s.phoneNumber === eventSubscriber.phoneNumber);
                                    if (index > -1) {
                                        currentEventGITNBSuscribers = currentEventGITNBSuscribers + 1;
                                    }
                                }
                                
                                const currentRow = [
                                    currentEvent.name,
                                    currentEvent.startDate,
                                    totalEventSubscribers.toString(),
                                    currentEventGITNBSuscribers.toString(),
                                    ((currentEventGITNBSuscribers/totalEventSubscribers*100).toString()+"%")
                                ];

                                rows.push(currentRow);
                            }
                        }
                    }
                }
            }
        }
    }

    const fileName = "Horse Show Sign Ups Report.csv";
    downloadCSVFile(fileName, rows);
}

export async function generateOrganizationSubscriberReport(organization: Organization) {

    let rows = [];

    // Add any event filters to the header row
    let eventFilters: EventFilter[] = [];
    const eventFiltersResult = await getEventFiltersByOrganizationId(organization.id);
    if (eventFiltersResult.isSuccess) {
        eventFilters = eventFiltersResult.result;
        for (let i = 0; i < eventFilters.length; i++) {
            const eventFilter: EventFilter = eventFilters[i];
            headerRow.push(eventFilter.name || "");
        }
    }

    rows.push(headerRow);
    
    const queryResult = await getOrganizationAuditorsWithFilterByOrganizationId(organization.id);
    if (queryResult.isSuccess) {
        const eventAuditors = queryResult.result as EventAuditor[];
        const sorted = sortEventAuditorsByName(eventAuditors);
        if (sorted && sorted.length > 0) {
            for (let i = 0; i < sorted.length; i++) {
                let currentRow: any[] = [];
                const eventAuditor = sorted[i];
                currentRow.push(eventAuditor.phoneNumber || "");
                currentRow.push(eventAuditor.name || "");
                currentRow.push(eventAuditor.createdOn ? moment(eventAuditor.createdOn).format("MM/DD/YYYY") : "");
                currentRow.push(eventAuditor.updatedOn ? moment(eventAuditor.updatedOn).format("MM/DD/YYYY") : "");
                currentRow.push(eventAuditor.status === "paused" ? "PAUSED" : (eventAuditor.status === "STOPPED" ? "stopped" : "active"));
                for (let j = 0; j < eventFilters.length; j++) {
                    const currentEventFilter = eventFilters[j];
                    const auditorEventFilters = eventAuditor.filters;
                    if (auditorEventFilters) {
                        const list: any = auditorEventFilters;
                        const items = list.items;
                        const found = items.find((filter: EventAuditorFilter) => filter.filterId === currentEventFilter.id);
                        if (found) {
                            const options: string = found.options.toString();
                            if (options) {
                                currentRow.push(options.replace(/,/g,';'));
                            } else {
                                currentRow.push("");
                            }
                        } else {
                            currentRow.push("");
                        }
                    } else {
                        currentRow.push("");
                    }
                }
                rows.push(currentRow);
            }
        }
    }

    const fileName = (organization.name ? organization.name + " subscribers.csv" : "subscribers.csv");
    downloadCSVFile(fileName, rows);
}