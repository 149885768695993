import {
    IonButton,
    IonButtons,
    IonHeader,
    IonToolbar
} from "@ionic/react";
import React, { useState } from "react";

interface _Props {
    active: string
    onSelect: Function
}

const EntryPageInfoNavbar: React.FC<_Props> = ({active, onSelect}) => {

    const [currentActiveBtn, setCurrentActiveBtn] = useState(active || "about");

    const handleClick = (value: string) => {
        setCurrentActiveBtn(value);
        onSelect(value);
    }

    return (
        <IonHeader id="editEntryAboutMenu">
            <IonToolbar id="editEntryAboutMenu" color="light">
                <div className="scrollmenu">
                    <IonButtons>
                        <IonButton onClick={() => handleClick("general")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "general" ? "active-border" : "")}>
                                General
                            </p>
                        </IonButton>
                        <IonButton onClick={() => handleClick("horse")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "horse" ? "active-border" : "")}>
                                Horse
                            </p>
                        </IonButton>
                        <IonButton onClick={() => handleClick("owner")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "owner" ? "active-border" : "")}>
                                Owner
                            </p>
                        </IonButton>
                        <IonButton onClick={() => handleClick("riders")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "riders" ? "active-border" : "")}>
                                Rider
                            </p>
                        </IonButton>
                        <IonButton onClick={() => handleClick("trainer")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "trainer" ? "active-border" : "")}>
                                Trainer
                            </p>
                        </IonButton>
                        <IonButton onClick={() => handleClick("barn")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "barn" ? "active-border" : "")}>
                                Barn
                            </p>
                        </IonButton>
                        <IonButton onClick={() => handleClick("contact")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "contact" ? "active-border" : "")}>
                                Contact
                            </p>
                        </IonButton>
                        <IonButton onClick={() => handleClick("notes")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "notes" ? "active-border" : "")}>
                                Notes
                            </p>
                        </IonButton>
                        <IonButton onClick={() => handleClick("agreements")}>
                            <p className={"text-capitalize " + (currentActiveBtn === "agreements" ? "active-border" : "")}>
                                Agreements
                            </p>
                        </IonButton>
                    </IonButtons>
                </div>
            </IonToolbar>
        </IonHeader>
    );
};

export default EntryPageInfoNavbar;
