import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event} from "../../../../../../models";
import Header from "../../../../../../components/Headers/Header";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../../utilities/events/Event";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import EventAlertSettingsForm from "../../../../../../components/Event/Alerts/AlertSettingsForm";
import InformationBanner from "../../../../../../components/Banners/InformationBanner";
import EventFiltersForm from "../../../../../../components/Event/Alerts/AlertFiltersForm";
import ErrorAlert from "../../../../../../components/Errors/ErrorAlert";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventTextAlertSettingsPage: React.FC<EventPageProps> = ({match}) => {
    const [event, setEvent] = useState<Event>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getEvent();
    }, [match, match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={event ? event.name : "Settings"} />
                {event && (
                    <>
                        {error && <ErrorAlert error={error} />}
                        <IonRow id="alertSettings" className="ion-align-items-stretch">
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>Types of Alerts</IonCardTitle>
                                    <IonCardContent>
                                        <EventAlertSettingsForm event={event} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>Filters for Alerts</IonCardTitle>
                                    <IonCardContent>
                                        <InformationBanner info={"It's helpful to allow subscribers to choose filters on the alerts. This reduces the number of credits you need."} />
                                        <EventFiltersForm event={event} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventTextAlertSettingsPage;