import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventDayInput, CreateEventDayMutation, DeleteEventDayInput, DeleteEventDayMutation, GetEventDayQuery, ListEventDaysQuery, UpdateEventDayInput, UpdateEventDayMutation } from "../../API";
import { EventDay } from "../../models";
import { sortEventDays } from "./SortEventDays";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventDay. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventDay.
*/
export async function createEventDay(input: CreateEventDayInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "EventDay", "No input", "Create EventDay received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventDay, { input: input }))) as GraphQLResult<CreateEventDayMutation>;
        else result = (await API.graphql({
            query: mutations.createEventDay,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<CreateEventDayMutation>;
        const eventDay = result.data?.createEventDay;
        return formatResult(true, "EventDay", eventDay, "Successfully created the eventDay.");
    } catch (error: any) {
        return formatResult(false, "EventDay", error, "Error creating record in the database for type: eventDay");
    }
}

/**
* Handle updating a new record in the database for type: eventDay. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventDay.
*/
export async function updateEventDay(input: UpdateEventDayInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "EventDay", "No input", "Update EventDay received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventDay, { input: input }))) as GraphQLResult<UpdateEventDayMutation>;
        else result = (await API.graphql({
            query: mutations.updateEventDay,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<UpdateEventDayMutation>;
        const eventDay = result.data?.updateEventDay;
        return formatResult(true, "EventDay", eventDay, "Successfully updated the eventDay.");
    } catch (error: any) {
        return formatResult(false, "EventDay", error, "Error updating record in the database for type: eventDay");
    }
}

/**
* Handle deleting a new record in the database for type: eventDay. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventDay.
*/
export async function deleteEventDay(input: DeleteEventDayInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventDay", "No input", "Delete EventDay received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventDay, { input: input }))) as GraphQLResult<DeleteEventDayMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventDay,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventDayMutation>;
       const eventDay = result.data?.deleteEventDay;
       return formatResult(true, "EventDay", eventDay, "Successfully deleted the eventDay.");
   } catch (error: any) {
       return formatResult(false, "EventDay", error, "Error deleting record in the database for type: eventDay");
   }
}

/**
* Get all records in the database for type: eventDay. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventDay object
*/
export async function getAllEventDays(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventDays,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventDaysQuery>;

        let items = result.data?.listEventDays?.items as EventDay[];
        let nextToken = result.data?.listEventDays?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventDays,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventDaysQuery>;

            const nextItems = nextResult.data?.listEventDays?.items as EventDay[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventDays?.nextToken;
        }

        if (items && items.length > 0) {
            return formatResult(true, "EventDay", items, "Successfully got the eventDays.");
        } else {
            return formatResult(false, "EventDay", null, "Found no eventDays.");
        }
    } catch (error: any) {
        return formatResult(false, "EventDay", error, "Error reading record in the database for type: eventDays");
    }
}

/**
* Read a specific record in the database for type: eventDay. 
* 
* @param {string}  id                  The eventDay id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventDay object
*/
export async function getEventDayById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventDay,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventDayQuery>;
       const eventDay = result.data?.getEventDay;
       return formatResult(true, "EventDay", eventDay, "Successfully got the eventDay.");
   } catch (error: any) {
       return formatResult(false, "EventDay", error, "Error reading record in the database for type: eventDay");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventDay. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventDay object
*/
export async function getEventDaysByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventDays,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventDaysQuery>;

        let items = result.data?.listEventDays?.items as EventDay[];
        let nextToken = result.data?.listEventDays?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventDays,
                variables: {
                    limit: 1000,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventDaysQuery>;

            const nextItems = nextResult.data?.listEventDays?.items as EventDay[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventDays?.nextToken;
        }

        if (items && items.length > 0) {
            const sorted = sortEventDays(items);
            if (sorted) {
                return formatResult(true, "EventDay", sorted, "Successfully got the eventDays.");
            } else {
                return formatResult(true, "EventDay", items, "Successfully got the eventDays.");
            }
        } else {
            return formatResult(false, "EventDay", null, "Found no eventDays.");
        }
    } catch (error: any) {
        return formatResult(false, "EventDay", error, "Error reading record in the database for type: eventDays");
    }
}