import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventFilterInput, CreateEventFilterMutation, DeleteEventFilterInput, DeleteEventFilterMutation, GetEventFilterQuery, ListEventFiltersQuery, UpdateEventFilterInput, UpdateEventFilterMutation } from "../../API";
import moment from "moment";
import { EventFilter } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventFilter. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventFilter.
*/
export async function createEventFilter(input: CreateEventFilterInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventFilter", "No input", "Create EventFilter received no input.");
   try {
        const fullInput: CreateEventFilterInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventFilter, { input: fullInput }))) as GraphQLResult<CreateEventFilterMutation>;
       else result = (await API.graphql({
           query: mutations.createEventFilter,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventFilterMutation>;
       const eventFilter = result.data?.createEventFilter;
       return formatResult(true, "EventFilter", eventFilter, "Successfully created the eventFilter.");
   } catch (error: any) {
       return formatResult(false, "EventFilter", error, "Error creating record in the database for type: eventFilter");
   }
}

/**
* Handle updating a new record in the database for type: eventFilter. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventFilter.
*/
export async function updateEventFilter(input: UpdateEventFilterInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventFilter", "No input", "Update EventFilter received no input.");
   try {
        const fullInput: UpdateEventFilterInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventFilter, { input: fullInput }))) as GraphQLResult<UpdateEventFilterMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventFilter,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventFilterMutation>;
       const eventFilter = result.data?.updateEventFilter;
       return formatResult(true, "EventFilter", eventFilter, "Successfully updated the eventFilter.");
   } catch (error: any) {
       return formatResult(false, "EventFilter", error, "Error updating record in the database for type: eventFilter");
   }
}

/**
* Handle deleting a new record in the database for type: eventFilter. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventFilter.
*/
export async function deleteEventFilter(input: DeleteEventFilterInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventFilter", "No input", "Delete EventFilter received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventFilter, { input: input }))) as GraphQLResult<DeleteEventFilterMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventFilter,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventFilterMutation>;
       const eventFilter = result.data?.deleteEventFilter;
       return formatResult(true, "EventFilter", eventFilter, "Successfully deleted the eventFilter.");
   } catch (error: any) {
       return formatResult(false, "EventFilter", error, "Error deleting record in the database for type: eventFilter");
   }
}

/**
* Get all records in the database for type: eventFilter. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventFilter object
*/
export async function getAllEventFilters(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventFilters,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventFiltersQuery>;

        let items = result.data?.listEventFilters?.items as EventFilter[];
        let nextToken = result.data?.listEventFilters?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventFilters,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventFiltersQuery>;

            const nextItems = nextResult.data?.listEventFilters?.items as EventFilter[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventFilters?.nextToken;
        }

        return formatResult(true, "EventFilter", items, "Successfully got the EventFilters.");
    } catch (error: any) {
        return formatResult(false, "EventFilter", error, "Error reading record in the database for type: eventFilters");
    }
}

/**
* Read a specific record in the database for type: eventFilter. 
* 
* @param {string}  id                  The eventFilter id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventFilter object
*/
export async function getEventFilterById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventFilter,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventFilterQuery>;
       const eventFilter = result.data?.getEventFilter;
       return formatResult(true, "EventFilter", eventFilter, "Successfully got the eventFilter.");
   } catch (error: any) {
       return formatResult(false, "EventFilter", error, "Error reading record in the database for type: eventFilter");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventFilter. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventFilter object
*/
export async function getEventFiltersByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventFilters,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventFiltersQuery>;

        let items = result.data?.listEventFilters?.items as EventFilter[];
        let nextToken = result.data?.listEventFilters?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventFilters,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventFiltersQuery>;

            const nextItems = nextResult.data?.listEventFilters?.items as EventFilter[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventFilters?.nextToken;
        }

        return formatResult(true, "EventFilter", items, "Successfully got the EventFilters.");
    } catch (error: any) {
        return formatResult(false, "EventFilter", error, "Error reading record in the database for type: eventFilters");
    }
}

/**
* Get all records in the database that match the given criteria for type: organizationFilter. 
* 
* @param {string}  organizationId      The organization id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the organizationFilter object
*/
export async function getEventFiltersByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationID: {eq: organizationId}};
        const result = (await API.graphql({
            query: queries.listEventFilters,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventFiltersQuery>;

        let items = result.data?.listEventFilters?.items as EventFilter[];
        let nextToken = result.data?.listEventFilters?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventFilters,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventFiltersQuery>;

            const nextItems = nextResult.data?.listEventFilters?.items as EventFilter[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventFilters?.nextToken;
        }

        return formatResult(true, "EventFilter", items, "Successfully got the EventFilters.");
    } catch (error: any) {
        return formatResult(false, "EventFilter", error, "Error reading record in the database for type: eventFilters");
    }
}