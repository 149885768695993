import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Division, OrganizationDivision, PointTable, PrizeMoneyTable } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import { createDivision, getDivisionById, updateDivision } from "../../utilities/division/Division";
import SelectDiscipline from "../Discipline/SelectDiscipline";
import { createOrganizationDivision, deleteOrganizationDivision, updateOrganizationDivision } from "../../utilities/organizationDivision/OrganizationDivision";
import SuccessBanner from "../Banners/SuccessBanner";
import SelectECClass from "../Class/SelectECClass";
import SelectECClassType from "../Class/SelectECClassType";
import { ECClass, ECClassType } from "../../interfaces/ECData";
import { USEFSection } from "../../interfaces/USEFData";
import SelectUSEFClass from "../Class/SelectUSEFClass";
import { capitalizeName } from "../../utilities/person/PersonNameFormat";
import { getDisciplineFromName } from "../../utilities/discipline/FormatDisciplines";
import { CreateDivisionInput, CreateOrganizationDivisionInput, UpdateDivisionInput, UpdateOrganizationDivisionInput } from "../../API";
import { Input } from "reactstrap";
import DivisionEntryFee from "../EventClass/EventClassForm/DivisionEntryFee";
import ClassPrizeMoney from "../EventClass/EventClassForm/ClassPrizeMoney";
import AllowedNumberOfEntries from "../EventClass/EventClassForm/AllowedNumberOfEntries";
import ClassPointTable from "../EventClass/EventClassForm/ClassPointTable";
import SelectUSHJAOutreachClass from "../Class/SelectUSHJAOutreachClass";
import { USHJAOutreachSection } from "../../data/content/USHJAOutreachSections";
import JumpHeightOptionsForm from "../../components/EventClass/EventClassForm/JumpHeightOptionsForm";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    organizationId: string
    organizationDivision?: (OrganizationDivision | null)
    onSubmit: Function
}

const OrganizationDivisionForm: React.FC<_Props> = ({organizationDivision, organizationId, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectedValue, setSelectedValue] = useState<"custom" | "ec" | "usef" | "ushja">("custom");
    const [ecClassNumber, setECClassNumber] = useState<number | undefined>();
    const [ecClassTypeNumber, setECClassTypeNumber] = useState<number | undefined>();
    const [usefClassNumber, setUSEFClassNumber] = useState<number | undefined>();
    const [division, setDivision] = useState<Division | undefined>(); 
    const [name, setName] = useState(((organizationDivision && organizationDivision.division) ? organizationDivision.division.name : ""));
    const [number, setNumber] = useState<number | undefined | null>(undefined);
    const [ecName, setECName] = useState("");
    const [ecClassTypeName, setEClassTypeName] = useState("");
    const [usefName, setUSEFName] = useState("");
    const [ushjaName, setUSHJAName] = useState("");
    const [jumpHeights, setJumpHeights] = useState<(string | null)[] | undefined | null>((organizationDivision && organizationDivision.jumpHeight) ? organizationDivision.jumpHeight : undefined);
    const [description, setDescription] = useState((organizationDivision && organizationDivision.division) ? organizationDivision.division.description : "");
    const [discipline, setDiscipline] = useState((organizationDivision && organizationDivision.division) ? organizationDivision.division.discipline : "hunter"); //Hunter is the default option in SelectDiscipline
    const [minNumberEntries, setMinNumberEntries] = useState(3);
    const [maxNumberEntries, setMaxNumberEntries] = useState<number | undefined>();
    const [entryFee, setEntryFee] = useState((organizationDivision && organizationDivision.entryFee) ? parseFloat(organizationDivision.entryFee) : 0);
    const [pointTable, setPointTable] = useState<PointTable | null | undefined>();
    const [prizeMoneyTable, setPrizeMoneyTable] = useState<PrizeMoneyTable | undefined>();
    const [prizeMoney, setPrizeMoney] = useState((organizationDivision && organizationDivision.prizeMoney) ? parseFloat(organizationDivision.prizeMoney) : 0);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    const getDivision = async (divisionId: string) => {
        const queryResult = await getDivisionById(divisionId);
        if (queryResult.isSuccess) {
            const division = queryResult.result;
            setDivision(division);
            setDescription(division.description);
            setDiscipline(division.discipline);
            if (division.ecNumber) {
                setSelectedValue("ec");
                setECClassNumber(parseInt(division.ecNumber));
                if (division.ecClassTypeNumber) setECClassTypeNumber(parseInt(division.ecClassTypeNumber));
                if (division.ecClassType) setEClassTypeName(division.ecClassType);
            }
            if (division.usefNumber) {
                setSelectedValue("usef");
                setUSEFClassNumber(parseInt(division.usefNumber));
            }
            if (division.ushjaName) {
                setSelectedValue("ushja");
                setUSHJAName(division.ushjaName);
            }
            return queryResult.result;
        } else {
            return null;
        }
    }

    useEffect(() => {
        if (organizationDivision) {
            getDivision(organizationDivision.divisionId);
            setName(organizationDivision.name);
            setNumber(organizationDivision.number);
            setDescription(organizationDivision.division.description);
            setDiscipline(organizationDivision.division.discipline);
            setMinNumberEntries(organizationDivision.minNumberEntries || 3);
            setMaxNumberEntries(organizationDivision.maxNumberEntries || 0);
            setJumpHeights(organizationDivision.jumpHeight);
            setEntryFee(organizationDivision.entryFee ? parseFloat(organizationDivision.entryFee) : 0);
            setPrizeMoney(organizationDivision.prizeMoney ? parseFloat(organizationDivision.prizeMoney) : 0);
            setPrizeMoneyTable(organizationDivision.prizeMoneyTable || undefined);
            setPointTable(organizationDivision.pointTable || undefined);
            setSuccess("");
            setError("");
        }
    }, [organizationDivision]);

    const verifyForm = () => {
        try {
            if (!name) {
                setError("You must include a name.");
                return false;
            }
            if (!discipline) {
                setError("You must include a discipline.");
                return false;
            }
            if (selectedValue === "usef" && (!usefClassNumber && !ushjaName)) {
                setError("You must include a USEF Class and/or USHJA Class.");
                return false;
            }
            if (selectedValue === "ec" && !ecClassNumber) {
                setError("You must include an EC Class.");
                return false;
            }
            if (selectedValue === "ec" && !ecClassTypeNumber) {
                setError("You must include an EC Class Type.");
                return false;
            }
            return true;
        } catch (error: any) {
            setError(error);
            return false;
        }
    }

    const clearForm = () => {
        setDivision(undefined);
        setName("");
        setNumber(undefined);
        setECName("");
        setEClassTypeName("");
        setUSEFName("");
        setUSHJAName("");
        setECClassNumber(undefined);
        setECClassTypeNumber(undefined);
        setUSEFClassNumber(undefined);
        setJumpHeights(undefined);
        setDescription("");
        setDiscipline("hunter");
        setEntryFee(0);
        setPrizeMoney(0);
        setError("");
    } 

    const handleSelectedECClass = (data: ECClass) => {
        const name = data.ClassDescription;
        setName(name);
        setECName(name);
        setDiscipline(getDisciplineFromName(name));
        setECClassNumber(data.ClassID);
    }

    const handleSelectedECClassType = (data: ECClassType) => {
        setEClassTypeName(data.ClassTypeDescription);
        setECClassTypeNumber(data.ClassTypeID);
    }

    const handleSelectedUSEFDivision = (data: USEFSection) => {
        const name = data.SectionName;
        setName(capitalizeName(name));
        setUSEFName(name);
        setDiscipline(getDisciplineFromName(name));
        setUSEFClassNumber(data.SectionCd);
    }

    const handleSelectedUSHJASection = (data: USHJAOutreachSection) => {
        const name = data.name;
        setName(capitalizeName(name));
        setUSHJAName(name);
        setDiscipline(getDisciplineFromName(name));
    }

    const handleSelectedDiscipline = (discipline: string) => {
        setDiscipline(discipline);
        setIsDisabled(false);
    }

    const handleSelectedJumpHeights = (selectedJumpHeights: string[]) => {
        setJumpHeights(selectedJumpHeights);
        setIsDisabled(false);
    }

    const handleSelectedEntryNumber = (option: "min" | "max", number: number) => {
        if (option === "min") setMinNumberEntries(number);
        else setMaxNumberEntries(number);
        setIsDisabled(false);
    }

    const handleSelectedDivisionEntryFee = (option: "division", number: number) => {
        setEntryFee(number);
        setIsDisabled(false);
    }

    const handleSelectedPrizeMoney = (option: "prizeMoney", number: number) => {
        setPrizeMoney(number);
        setIsDisabled(false);
    }

    const handleSelectedPrizeMoneyTable = (option: "prizeMoneyTable", prizeMoneyTable: PrizeMoneyTable) => {
        setPrizeMoneyTable(prizeMoneyTable);
        if (!prizeMoneyTable.isPercentage && prizeMoneyTable.totalPot) {
            setPrizeMoney(prizeMoneyTable.totalPot);
        } else {
            setPrizeMoney(0);
        }
        setIsDisabled(false);
    }

    const handleSelectedPointTable = (option: "pointTable", pointTable: PointTable) => {
        setPointTable(pointTable);
        setIsDisabled(false);
    }

    const handleCreateDivision = async () => {
        try {
            const input: CreateDivisionInput = {
                createdBy: user.id,
                name: name,
                description: description,
                discipline: discipline,
                ushjaName: ushjaName || "",
                usefNumber: usefClassNumber ? usefClassNumber.toString() : "",
                ecNumber: ecClassNumber ? ecClassNumber?.toString() : "",
                ecClassTypeNumber: ecClassTypeNumber ? ecClassTypeNumber.toString() : "",
                ecClassType: ecClassTypeName
            };
            const createResult = await createDivision(input);
            if (createResult.isSuccess) {
                const input: CreateOrganizationDivisionInput = {
                    createdBy: user.id,
                    name: name,
                    number: number,
                    divisionId: createResult.result.id,
                    jumpHeight: jumpHeights,
                    organizationId: organizationId,
                    entryFee: entryFee.toString(),
                    prizeMoney: prizeMoney.toString(),
                    organizationDivisionPrizeMoneyTableId: prizeMoneyTable?.id || "",
                    organizationDivisionPointTableId: pointTable?.id || "",
                    minNumberEntries: minNumberEntries,
                    maxNumberEntries: maxNumberEntries
                };
                const createOrganizationDivisionResult = await createOrganizationDivision(input);
                if (createOrganizationDivisionResult.isSuccess) {
                    onSubmit("create", createOrganizationDivisionResult.result);
                    clearForm();
                    const message = "Successfully created the division: " + name;
                    setSuccess(message);
                    
                } else {
                    setError(createOrganizationDivisionResult.message);
                }
            } else {
                setError(createResult.message);
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleEditDivision = async () => {
        try {
            if (division) {
                const input: UpdateDivisionInput = {
                    id: division.id,
                    createdBy: user.id,
                    name: name,
                    description: description,
                    discipline: discipline,
                    ushjaName: ushjaName || "",
                    usefNumber: usefClassNumber ? usefClassNumber.toString() : "",
                    ecNumber: ecClassNumber ? ecClassNumber?.toString() : "",
                    ecClassTypeNumber: ecClassTypeNumber ? ecClassTypeNumber.toString() : "",
                    ecClassType: ecClassTypeName
                };
                const updateResult = await updateDivision(input);
                if (updateResult.isSuccess) {
                    if (organizationDivision) {
                        const input: UpdateOrganizationDivisionInput = {
                            id: organizationDivision.id,
                            createdBy: user.id,
                            name: name,
                            number: number,
                            organizationId: organizationId,
                            entryFee: entryFee.toString(),
                            prizeMoney: prizeMoney.toString(),
                            organizationDivisionPrizeMoneyTableId: prizeMoneyTable?.id || "",
                            organizationDivisionPointTableId: pointTable?.id || "",
                            minNumberEntries: minNumberEntries,
                            maxNumberEntries: maxNumberEntries,
                            jumpHeight: jumpHeights
                        };
                        const updateOrganizationDivisionResult = await updateOrganizationDivision(input);
                        if (updateOrganizationDivisionResult.isSuccess) {
                            onSubmit("update", updateOrganizationDivisionResult.result);
                            clearForm();
                            const message = "Successfully updated the division: " + name;
                            setSuccess(message);
                        } else {
                            setError(updateOrganizationDivisionResult.message);
                        }
                    } else {
                        setError("Could not update the organization division.");
                    }
                } else {
                    setError(updateResult.message);
                }
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) {
            setError("");
            if (organizationDivision) {
                await handleEditDivision();
            } else {
                await handleCreateDivision();
            }
        }
        setIsDisabled(true);
        setIsLoading(false);
    }

    const handleDelete = async () => {
        setIsLoading(true);
        if (organizationDivision) {
            const deleteResult = await deleteOrganizationDivision({id: organizationDivision.id});
            if (deleteResult.isSuccess) {
                clearForm();
                setSuccess("Deleted the division");
                onSubmit("delete", organizationDivision);
            } else {
                setError(deleteResult.message);
            }
        }
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            <form>
                {/* TO DO - Add back in the ability to check for USEF Classes */}
                {/* <IonRow>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "custom" ? "primary" : "light"} onClick={() => {setSelectedValue("custom"); clearForm();}}>Custom</IonButton>
                    </IonCol>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "ec" ? "primary" : "light"} onClick={() => {setSelectedValue("ec"); clearForm();}}>EC</IonButton>
                    </IonCol>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "usef" ? "primary" : "light"} onClick={() => {setSelectedValue("usef"); clearForm();}}>USEF</IonButton>
                    </IonCol>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "ushja" ? "primary" : "light"} onClick={() => {setSelectedValue("ushja"); }}>USHJA</IonButton>
                    </IonCol>
                </IonRow> */}
                <IonRow>
                    <IonCol>
                        <IonCard className="bg-light">
                            <IonCardContent>
                            {selectedValue === "ec" && (
                                <>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <p>Select from the list of recognized EC options.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectECClass selectedValue={ecClassNumber?.toString() || ""} onSelect={handleSelectedECClass} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">EC Number</IonLabel>
                                                <IonInput 
                                                    type="number"
                                                    value={ecClassNumber}
                                                    disabled={true}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectECClassType selectedValue={ecClassTypeNumber?.toString() || ""} onSelect={handleSelectedECClassType} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">EC Class Type ID</IonLabel>
                                                <IonInput 
                                                    type="number"
                                                    value={ecClassTypeNumber}
                                                    disabled={true}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {selectedValue === "usef" && (
                                <>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <p>Select from the list of recognized USEF options.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectUSEFClass onSelect={handleSelectedUSEFDivision} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">USEF Number</IonLabel>
                                                <IonInput 
                                                    type="number"
                                                    value={usefClassNumber}
                                                    disabled={true}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {selectedValue === "ushja" && (
                                <>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <p>Select from the list of USHJA Outreach options.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectUSHJAOutreachClass onSelect={handleSelectedUSHJASection} />
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {selectedValue === "custom" && (
                                <IonRow className="ion-padding-top">
                                    <IonCol>
                                        {/* <p>Create your own division. We will not report its results to EC, USEF or USHJA.</p> */}
                                        <p>Create your own division.</p>
                                    </IonCol>
                                </IonRow>
                            )}
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name <RequiredInputIndicator/></IonLabel>
                            <IonInput 
                                type="text"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Number</IonLabel>
                            <IonInput 
                                type="number"
                                value={number}
                                min="0"
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setNumber(e.detail.value ? parseInt(e.detail.value) : undefined)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel position="stacked">Discipline <RequiredInputIndicator/></IonLabel>
                        <SelectDiscipline selectedValue={discipline} onSelect={(value: string) => handleSelectedDiscipline(value)} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel>Description</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <Input
                            rows="5"
                            type="textarea"
                            name="declineReason"
                            value={description!}
                            spellCheck="true"
                            onChange={(event) => setDescription(event.target.value)}
                            data-hj-whitelist
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <AllowedNumberOfEntries selectedMin={minNumberEntries} selectedMax={maxNumberEntries} isExpandedByDefault={false} onSelect={handleSelectedEntryNumber} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <JumpHeightOptionsForm selectedJumpHeightOptions={jumpHeights} onSelect={handleSelectedJumpHeights} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <DivisionEntryFee selectedEntryFee={entryFee} onSelect={handleSelectedDivisionEntryFee} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <ClassPointTable organizationId={organizationId} selectedPointTable={pointTable} onSelectPointTable={handleSelectedPointTable} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <ClassPrizeMoney organizationId={organizationId} selectedPrizeMoney={prizeMoney} selectedPrizeMoneyTable={prizeMoneyTable} onSelectPrizeMoney={handleSelectedPrizeMoney} onSelectPrizeMoneyTable={handleSelectedPrizeMoneyTable} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                    {division && (
                        <IonCol offsetMd="4" sizeMd="4">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    disabled={isDisabled}
                                    className="ion-margin-top"
                                    color="danger"
                                    expand="block"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </IonButton>
                            }
                        </IonCol>
                    )}
                </IonRow>
            </form>
        </>
    );
};

export default OrganizationDivisionForm;