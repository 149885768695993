import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventInvoiceInput, CreateEventInvoiceMutation, DeleteEventInvoiceInput, DeleteEventInvoiceMutation, GetEventInvoiceQuery, ListEventInvoicesQuery, UpdateEventInvoiceInput, UpdateEventInvoiceMutation } from "../../API";
import moment from "moment";
import { EventInvoice } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventInvoice. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventInvoice.
*/
export async function createEventInvoice(input: CreateEventInvoiceInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventInvoice", "No input", "Create EventInvoice received no input.");
   try {
        const fullInput: CreateEventInvoiceInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventInvoice, { input: fullInput }))) as GraphQLResult<CreateEventInvoiceMutation>;
       else result = (await API.graphql({
           query: mutations.createEventInvoice,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventInvoiceMutation>;
       const eventInvoice = result.data?.createEventInvoice;
       return formatResult(true, "EventInvoice", eventInvoice, "Successfully created the eventInvoice.");
   } catch (error: any) {
       return formatResult(false, "EventInvoice", error, "Error creating record in the database for type: eventInvoice");
   }
}

/**
* Handle updating a new record in the database for type: eventInvoice. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventInvoice.
*/
export async function updateEventInvoice(input: UpdateEventInvoiceInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventInvoice", "No input", "Update EventInvoice received no input.");
   try {
        const fullInput: UpdateEventInvoiceInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventInvoice, { input: fullInput }))) as GraphQLResult<UpdateEventInvoiceMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventInvoice,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventInvoiceMutation>;
       const eventInvoice = result.data?.updateEventInvoice;
       return formatResult(true, "EventInvoice", eventInvoice, "Successfully updated the eventInvoice.");
   } catch (error: any) {
       return formatResult(false, "EventInvoice", error, "Error updating record in the database for type: eventInvoice");
   }
}

/**
* Handle deleting a new record in the database for type: eventInvoice. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventInvoice.
*/
export async function deleteEventInvoice(input: DeleteEventInvoiceInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventInvoice", "No input", "Delete EventInvoice received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventInvoice, { input: input }))) as GraphQLResult<DeleteEventInvoiceMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventInvoice,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventInvoiceMutation>;
       const eventInvoice = result.data?.deleteEventInvoice;
       return formatResult(true, "EventInvoice", eventInvoice, "Successfully deleted the eventInvoice.");
   } catch (error: any) {
       return formatResult(false, "EventInvoice", error, "Error deleting record in the database for type: eventInvoice");
   }
}

/**
* Get all records in the database for type: eventInvoice. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventInvoice object
*/
export async function getAllEventInvoices(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventInvoices,
            variables: {
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventInvoicesQuery>;

        let items = result.data?.listEventInvoices?.items as EventInvoice[];
        let nextToken = result.data?.listEventInvoices?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventInvoices,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventInvoicesQuery>;

            const nextItems = nextResult.data?.listEventInvoices?.items as EventInvoice[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventInvoices?.nextToken;
        }

        return formatResult(true, "EventInvoice", items, "Successfully got the eventInvoices.");
    } catch (error: any) {
        return formatResult(false, "EventInvoice", error, "Error reading record in the database for type: eventInvoices");
    }
}

/**
* Read a specific record in the database for type: eventInvoice. 
* 
* @param {string}  id                  The eventInvoice id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventInvoice object
*/
export async function getEventInvoiceById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventInvoice,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventInvoiceQuery>;
       const eventInvoice = result.data?.getEventInvoice;
       return formatResult(true, "EventInvoice", eventInvoice, "Successfully got the eventInvoice.");
   } catch (error: any) {
       return formatResult(false, "EventInvoice", error, "Error reading record in the database for type: eventInvoice");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventInvoice. 
* 
* @param {string}  payerId             The payer id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventInvoice object
*/
export async function getEventInvoicesByPayerId(payerId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {payerId: {eq: payerId}};
        const result = (await API.graphql({
            query: queries.listEventInvoices,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventInvoicesQuery>;

        let items = result.data?.listEventInvoices?.items as EventInvoice[];
        let nextToken = result.data?.listEventInvoices?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventInvoices,
                variables: {
                    filter,
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventInvoicesQuery>;

            const nextItems = nextResult.data?.listEventInvoices?.items as EventInvoice[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventInvoices?.nextToken;
        }

        return formatResult(true, "EventInvoice", items, "Successfully got the eventInvoices.");
    } catch (error: any) {
        return formatResult(false, "EventInvoice", error, "Error reading record in the database for type: eventInvoices");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventInvoice. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventInvoice object
*/
export async function getEventInvoicesByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventInvoices,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventInvoicesQuery>;

        let items = result.data?.listEventInvoices?.items as EventInvoice[];
        let nextToken = result.data?.listEventInvoices?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventInvoices,
                variables: {
                    filter,
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventInvoicesQuery>;

            const nextItems = nextResult.data?.listEventInvoices?.items as EventInvoice[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventInvoices?.nextToken;
        }

        if (items && items.length > 0) {
            return formatResult(true, "EventInvoice", items, "Successfully got the eventInvoices.");
        } else {
            return formatResult(false, "EventInvoice", null, "Found no eventInvoices.");
        }
    } catch (error: any) {
        return formatResult(false, "EventInvoice", error, "Error reading record in the database for type: eventInvoices");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventInvoice. 
* 
* @param {string}  eventId             The event id
* @param {string}  entryId             The entry id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventInvoice object
*/
export async function getEventInvoicesByEventIdEntryId(eventId: string, entryId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventInvoicesByEventEntryId,
            variables: {
                limit: 1000,
                entryId: entryId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventInvoicesByEventEntryId?.items as EventInvoice[];
        let nextToken = result.data?.eventInvoicesByEventEntryId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.eventInvoicesByEventEntryId,
                variables: {
                    entryId: entryId,
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.eventInvoicesByEventEntryId?.items as EventInvoice[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.eventInvoicesByEventEntryId?.nextToken;
        }

        if (items && items.length > 0) {
            return formatResult(true, "EventInvoice", items, "Successfully got the eventInvoices.");
        } else {
            return formatResult(false, "EventInvoice", null, "Found no eventInvoices.");
        }
    } catch (error: any) {
        return formatResult(false, "EventInvoice", error, "Error reading record in the database for type: eventInvoices");
    }
}