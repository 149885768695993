import React, { useEffect, useState } from "react";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";
import { IonLabel, isPlatform } from "@ionic/react";
import { isWindows } from "../../utilities/platform/Platform";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    isDisabled?: boolean
    menuPlacement?: (string | null)
    selectedValue?: (string | null)
    onSelect: Function
}

const GradeLevels = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "Adult"
];

const GradeLevel: React.FC<_Props> = ({isDisabled, selectedValue, menuPlacement, onSelect}) => {

    const [formattedOptions, setFormattedOptions] = useState<formattedOption[] | undefined | null>();
    const [selected, setSelected] = useState<string | null | undefined>();

    const getFormattedOptions = () => {
        let options: formattedOption[] = [];
        const gradeLevelValues = GradeLevels;
        for (let i = 0; i < gradeLevelValues.length; i++) {
            const label = gradeLevelValues[i];
            const formattedOption: formattedOption = {
                value: label, //This is how the selected value passed in through props will get matched
                label: label,
                object: label
            };
            options.push(formattedOption);
        }
        setFormattedOptions(options);
    }

    useEffect(() => {
        getFormattedOptions();
    }, []);

    useEffect(() => {
        if (selectedValue) setSelected(selectedValue)
    }, [selectedValue]);

    const handleOnChange = (value?: any) => {
        if (value) {
            onSelect(value);
        } else {
            onSelect();
        }
    }

    return (
        <>
            <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>Grade Level <RequiredInputIndicator/></IonLabel>
            {formattedOptions ?
                <BasicSelect formattedValues={formattedOptions} menuPlacement={menuPlacement} selectedValue={selected} isClearable={false} isDisabled={isDisabled} onSelect={handleOnChange} />
                :
                <p>Loading options...</p>
            }
        </>
    )
};

export default GradeLevel;
