import { Result } from "../../interfaces/Result";
import { baseURL, compIdTest, passwordTest } from "./ECValues";

export async function getECClasses(): Promise<Result> {
    // Specify which endpoint to hit
    let URL = baseURL + "GetECClasses";

    // Add the query params
    URL = URL + "?compId=" + compIdTest;
    URL = URL + "&password=" + passwordTest;
    URL = URL + "&discipline=HJ";

    // Make the GET request
    const response = await fetch(URL, {
        method: "GET"
    });

    if (response) {
        const myJson = await response.json();
        let result: Result = {isSuccess: true, type: "ECSearch", result: myJson, message: "We found the EC classes."};
        return result;
    } else {
        let result: Result = {isSuccess: false, type: "ECSearch", result: null, message: "An error occurred. Please ensure you have the correct EC Id or contact hello@ringsidepro.com."};
        return result;
    }
}