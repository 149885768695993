import React, { useState } from "react";
import {  IonLabel, IonRow, IonCol, IonButton, IonSegment, IonSegmentButton } from "@ionic/react";
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

interface _Props {
    onSelect: Function
}

const EditJumpHeightOptionForm: React.FC<_Props> = ({onSelect}) => {

    const [selectedValue, setSelectedValue] = useState<"feet" | "meters" | "other">("feet");
    const [feet, setFeet] = useState<number>(0);
    const [inches, setInches] = useState<number>(0);
    const [meters, setMeters] = useState<number>(0);
    const [heightValue, setHeightValue] = useState("");

    const handleSelectedValue = (newSelectedValue: string | undefined) => {
        switch (newSelectedValue) {
            case "feet":
            case "meters":
            case "other": 
                setSelectedValue(newSelectedValue);
                break;
            default:
                setSelectedValue("feet");
        }
    }

    /**
     * Formats the feet and inches into a standardized string
     * Example: 2 feet and 6 inches should return 2'6"
     * Example: 0 feet and 18 inches should return 18"
     */
    const handleFeet = () => {
        const formattedString = (feet > 0 ? feet.toString() + `'` : "") + inches.toString() + `"`;
        onSelect(formattedString);
    }

    /**
     * Formats the meters into a standardized string
     * Example: 1 meter should return 1.0m
     * Example: 0.95 meters should return 0.95m
     */
    const handleMeters = () => {
        const formattedString = meters.toPrecision(2).toString() + "m";
        onSelect(formattedString);
    }

    const handleSubmit = () => {
        if (selectedValue === "feet") handleFeet();
        else if (selectedValue === "meters") handleMeters();
        else onSelect(heightValue);
    }
    
    return (
        <>
           <IonRow className="ion-justify-content-center">
                <IonCol sizeXs="12" sizeMd="12">
                    <IonSegment color="primary" mode="ios" value={selectedValue} scrollable={true} onIonChange={e => {handleSelectedValue(e.detail.value || "feet");}}>
                        <IonSegmentButton value="feet">
                            <IonLabel>Feet</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="meters">
                            <IonLabel>Meters</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="other">
                            <IonLabel>Other</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonCol>
            </IonRow>
            <IonRow>
                {selectedValue === "feet" && (
                    <>
                        <IonCol sizeXs="12" sizeMd="5">
                            <InputGroup>
                                <Input 
                                    type="number" 
                                    id="feetInput" 
                                    placeholder="0" 
                                    value={feet}
                                    min={0}
                                    max={8}
                                    step={1}
                                    onChange={e => {
                                        setFeet(parseInt(e.target.value));
                                    }}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>Feet</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="5">
                            <InputGroup>
                                <Input 
                                    type="number" 
                                    id="inchesInput" 
                                    placeholder="0" 
                                    value={inches}
                                    min={0}
                                    max={11}
                                    step={1}
                                    onChange={e => {
                                        setInches(parseInt(e.target.value));
                                    }}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>Inches</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </IonCol>
                    </>
                )}
                {selectedValue === "meters" && (
                    <IonCol sizeXs="12" sizeMd="10">
                        <InputGroup>
                            <Input 
                                type="number" 
                                id="metersInput" 
                                placeholder="0" 
                                value={meters}
                                min={0}
                                max={10}
                                step={0.05}
                                onChange={e => {
                                    setMeters(parseFloat(e.target.value));
                                }}
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>Meters</InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </IonCol>
                )}
                {selectedValue === "other" && (
                    <IonCol sizeXs="12" sizeMd="10">
                        <FormGroup>
                            <Input type="text" id="heightCustomInput" placeholder="Example: poles" onChange={e => setHeightValue(e.target.value)} />
                        </FormGroup>
                    </IonCol>
                )}
                <IonCol sizeXs="12" sizeMd="2">
                    <IonButton 
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Add
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default EditJumpHeightOptionForm;
