import React, { useEffect, useState } from "react";
import { ECClassType } from "../../interfaces/ECData";
import { getECClassTypes } from "../../utilities/ECAPI/ECClassTypes";
import BasicSelect from "../Select/BasicSelect";

interface _Props {
    menuPlacement?: string
    selectedValue?: string
    onSelect: Function
}

interface formattedOption {
    label: string
    value: string
    object: ECClassType
}

const SelectECClassType: React.FC<_Props> = ({selectedValue, menuPlacement, onSelect}) => {

    const [formattedClasses, setFormattedClasses] = useState<formattedOption[] | null | undefined>();
    const [selectedClass, setSelectedClass] = useState<ECClassType | null | undefined>();

    const getECClassList = async () => {
        const queryResult = await getECClassTypes();
        if (queryResult.isSuccess) {
            const list: ECClassType[] = queryResult.result;
            formatClasses(list);
        } 
    }

    const formatClasses = (classList: ECClassType[]) => {
        let formattedClasses = [];
        for (var i = 0; i < classList.length; i++) {
            let object = {
                value: classList[i].ClassTypeID ? classList[i].ClassTypeID.toString() : "",
                label: classList[i].ClassTypeDescription,
                object: classList[i]
            };
            formattedClasses.push(object);
        }
        setFormattedClasses(formattedClasses);
    }

    useEffect(() => {
        async function getClasses() {
            await getECClassList();
        }
        getClasses();
    }, []);

    useEffect(() => {
        // setSelectedClass(selectedValue);
    }, [selectedValue]);

    const handleOnChange = (event: any) => {
        if (event) {
            setSelectedClass(event);
            onSelect(event);
        } else {
            setSelectedClass(undefined);
        }
    }

    return (
        <>
            {formattedClasses ?
                <BasicSelect formattedValues={formattedClasses} selectedValue={selectedClass?.ClassTypeID ? selectedClass?.ClassTypeID.toString() : ""} menuPlacement={menuPlacement} onSelect={handleOnChange}/>
                :
                <p>Loading options...</p>
            }
        </>
    );
};

export default SelectECClassType;
