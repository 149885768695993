import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { ClinicianStatus, CreateClinicianInput, CreateClinicianMutation, DeleteClinicianInput, DeleteClinicianMutation, GetClinicianQuery, ListCliniciansQuery, UpdateClinicianInput, UpdateClinicianMutation } from "../../API";
import moment from "moment";
import { Clinician } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: clinician. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created clinician.
*/
export async function createClinician(input: CreateClinicianInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Clinician", "No input", "Create Clinician received no input.");
   try {
        const fullInput: CreateClinicianInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createClinician, { input: fullInput }))) as GraphQLResult<CreateClinicianMutation>;
       else result = (await API.graphql({
           query: mutations.createClinician,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateClinicianMutation>;
       const clinician = result.data?.createClinician;
       return formatResult(true, "Clinician", clinician, "Successfully created the clinician.");
   } catch (error: any) {
       return formatResult(false, "Clinician", error, "Error creating record in the database for type: clinician");
   }
}

/**
* Handle updating a new record in the database for type: clinician. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated clinician.
*/
export async function updateClinician(input: UpdateClinicianInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Clinician", "No input", "Update Clinician received no input.");
   try {
        const fullInput: UpdateClinicianInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateClinician, { input: fullInput }))) as GraphQLResult<UpdateClinicianMutation>;
       else result = (await API.graphql({
           query: mutations.updateClinician,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateClinicianMutation>;
       const clinician = result.data?.updateClinician;
       return formatResult(true, "Clinician", clinician, "Successfully updated the clinician.");
   } catch (error: any) {
       return formatResult(false, "Clinician", error, "Error updating record in the database for type: clinician");
   }
}

/**
* Handle deleting a new record in the database for type: clinician. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted clinician.
*/
export async function deleteClinician(input: DeleteClinicianInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Clinician", "No input", "Delete Clinician received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteClinician, { input: input }))) as GraphQLResult<DeleteClinicianMutation>;
       else result = (await API.graphql({
           query: mutations.deleteClinician,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteClinicianMutation>;
       const clinician = result.data?.deleteClinician;
       return formatResult(true, "Clinician", clinician, "Successfully deleted the clinician.");
   } catch (error: any) {
       return formatResult(false, "Clinician", error, "Error deleting record in the database for type: clinician");
   }
}

/**
* Get all records in the database for type: clinician. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the clinician object
*/
export async function getAllClinicians(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listClinicians,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListCliniciansQuery>;
        const clinicians = result.data?.listClinicians?.items;
        return formatResult(true, "Clinician", clinicians, "Successfully got the clinicians.");
    } catch (error: any) {
        return formatResult(false, "Clinician", error, "Error reading record in the database for type: clinicians");
    }
}

/**
* Get all records in the database for type: clinician. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the clinician object
*/
export async function getAllActiveClinicians(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.cliniciansByStatus,
            variables: {
                status: ClinicianStatus.AVAILABLE,
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.cliniciansByStatus?.items as Clinician[];
        let nextToken = result.data?.cliniciansByStatus?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.cliniciansByStatus,
                variables: {
                    status: ClinicianStatus.AVAILABLE,
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.cliniciansByStatus?.items as Clinician[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.cliniciansByStatus?.nextToken;
        }

        if (items && items.length > 0) {
            return formatResult(true, "Clinician", items, "Successfully got the clincians.");
        } else {
            return formatResult(false, "Clinician", null, "Found no clincians.");
        }
    } catch (error: any) {
        return formatResult(false, "Clinician", error, "Error reading record in the database for type: clinicians");
    }
}

/**
* Read a specific record in the database for type: clinician. 
* 
* @param {string}  id                  The clinician id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the clinician object
*/
export async function getClinicianById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getClinician,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetClinicianQuery>;
       const clinician = result.data?.getClinician;
       return formatResult(true, "Clinician", clinician, "Successfully got the clinician.");
   } catch (error: any) {
       return formatResult(false, "Clinician", error, "Error reading record in the database for type: clinician");
   }
}

/**
* Get all records in the database that match the given criteria for type: clinician. 
* 
* @param {string}  personId            The personId of the clinician.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the clinician object
*/
export async function getClinicianByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listClinicians,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListCliniciansQuery>;

        let items = result.data?.listClinicians?.items as Clinician[];
        let nextToken = result.data?.listClinicians?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listClinicians,
                variables: {
                    status: ClinicianStatus.AVAILABLE,
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.listClinicians?.items as Clinician[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listClinicians?.nextToken;
        }

        if (items && items.length > 0) {
            return formatResult(true, "Clinician", items[0], "Successfully got the clincian.");
        } else {
            return formatResult(false, "Clinician", null, "Found no clincians.");
        }
    } catch (error: any) {
        return formatResult(false, "Clinician", error, "Error reading record in the database for type: clinician");
    }
}

/**
* Get all records in the database that match the given criteria for type: clinician. 
* 
* @param {string}  name                The state name of the clinician.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the clinician object
*/
export async function getClinicianByName(name: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {name: {eq: name}};
        const result = (await API.graphql({
            query: queries.listClinicians,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListCliniciansQuery>;
        const clinicians = result.data?.listClinicians?.items;
        if (clinicians && clinicians[0]) return formatResult(true, "Clinician", clinicians[0], "Successfully got the clinician.");
        else return formatResult(false, "Clinician", null, "Could not find the clinician.");
    } catch (error: any) {
        return formatResult(false, "Clinician", error, "Error reading record in the database for type: clinician");
    }
}
