import { useHistory } from "react-router-dom";
import {Auth} from "aws-amplify";
import React, { useContext, useEffect } from "react";
import { OrganizationContext } from "../../context/OrganizationContext";
import { isPlatform } from "@ionic/react";
import { isWindows } from "../../utilities/platform/Platform";
import CONSTANT from "../../constant/constant";

const LogoutPage: React.FC = () => {
    const history = useHistory();
    const organization = useContext(OrganizationContext);
    
    useEffect(() => {
        signOut();
    }, []);

    const signOut = async () => {
        try {
            await Auth.signOut();
            // To Do: figure out how to clear local data after a user logs out.
            // await DataStore.clear();
            navigateToHomePage();
        } catch (error: any) {
            console.log("error signing out: ", error);
        }
    }

    const navigateToHomePage = () => {
        // navigate to organization's login page if organization is present
        if (organization?.name.length > 0 && organization.urlBackHalf &&
            organization?.name !== CONSTANT.RINGSIDE_PRO_ORGANIZATION.name) {
            history.push(`/${organization.urlBackHalf}/login`);
            return;
        }
        if((isWindows() || (isPlatform("desktop")))) {
            history.push("/home");
        } else {
            history.push("/login");
        }
    };

    return (
        <React.Fragment></React.Fragment>
    );
}
  
export default LogoutPage;
