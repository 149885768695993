import { IonCol, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import { Barn, Person } from "../../models";
import { getSegmentOfBarns } from "../../utilities/barn/Barn";
import Spinner from "../Spinners/Spinner";
import { Table } from "reactstrap";
import { getPersonByPersonId } from "../../utilities/person/Person";
import { useHistory } from "react-router";

var moment = require("moment");

interface formattedBarn {
    id: string
    name: string
    ownerName: string
    dateCreated: string
    barn: Barn
}

const BarnsListEditor: React.FC = () => {
    const history = useHistory();

    const [storedNextToken, setStoredNextToken] = useState<string | undefined>();
    const [isCompleteList, setIsCompleteList] = useState(false);
    const [formattedBarns, setFormattedBarns] = useState<formattedBarn[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const formatBarns = async(barns: Barn[]) => {
        setIsLoading(true);
        let result: formattedBarn[] = [];
        if (barns && barns.length > 0) {
            for (let i = 0; i < barns.length; i++) {
                const barn = barns[i];
                let ownerName = "";
                if (barn.ownerPersonId) {
                    const ownerResult = await getPersonByPersonId(barn.ownerPersonId);
                    if (ownerResult.isSuccess) {
                        const person: Person = ownerResult.result;
                        if (person) {
                            ownerName = person.firstName + " " + person.lastName;
                        }
                    }
                }

                const formatted: formattedBarn = {
                    id: barn.id, 
                    name: barn.name,
                    ownerName: ownerName,
                    dateCreated: moment(barn.createdOn).format("MM-DD-YYYY"),
                    barn
                };
                result.push(formatted);
            }
        }

        // Build the formatted barns list each time the Load More button is clicked
        let updatedFormattedBarns = formattedBarns ? formattedBarns.concat(result) : result;
        const sorted = updatedFormattedBarns.sort((a, b) => a.name.localeCompare(b.name));

        setFormattedBarns(sorted);
        setIsLoading(false);
    }  
    
    async function getBarns(numberToLoad?: number) {
        setIsLoading(true);
        const barnsResult = await getSegmentOfBarns((numberToLoad || 25), storedNextToken);
        if (barnsResult.isSuccess) {
            const results = barnsResult.result;
            const barns = results.items;
            const nextToken = results.nextToken;
            if (storedNextToken) {
                if (nextToken) {
                    // Replace the old next token with the new next token
                    setStoredNextToken(nextToken);
                } else {
                    // Hit the end of the list
                    setStoredNextToken(undefined);
                    setIsCompleteList(true);
                }
            } else {
                // First time storing the next token, store it in state
                setStoredNextToken(nextToken);
            }
            
            await formatBarns(barns);
            setError("");
        } else {
            setError("Could not get the barns from the database.");
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getBarns();
    }, []);

    const handleRowClick = (formattedBarn: formattedBarn) => {
        const path = "/index/admin/barn/" + formattedBarn.barn.id;
        history.push(path);
    }

    return (
        <>
           {error && <ErrorAlert width="12" error={error} />}
            {isLoading ?
                <Spinner />
                :
                <>
                    <IonRow className="ion-justify-content-center mt-4">
                        <IonCol sizeXs="12" sizeMd="6">
                            <p>Number of barns displayed: {formattedBarns?.length || 0}</p>
                        </IonCol>
                        {!isCompleteList && (
                            <>
                                <IonCol sizeXs="12" sizeMd="2" className="text-info link">
                                    <p onClick={() => getBarns(25)}><span>Load 25 More</span></p>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="2" className="text-info link">
                                    <p onClick={() => getBarns(50)}>Load 50 More</p>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="2" className="text-info link">
                                    <p onClick={() => getBarns(100)}>Load 100 More</p>
                                </IonCol>
                            </>
                        )}
                    </IonRow>
                    {(formattedBarns && formattedBarns.length > 0) ?
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Id</th>
                                    <th>Date Created</th>
                                    <th>Created By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formattedBarns.map((formattedBarn, i) => (
                                    <tr key={i} onClick={() => handleRowClick(formattedBarn)}>
                                        <td>{formattedBarn.name}</td>
                                        <td>{formattedBarn.id}</td>
                                        <td>{formattedBarn.dateCreated}</td>
                                        <td>{formattedBarn.ownerName}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        :
                        <p>No barns found.</p>
                    }
                    <IonRow className="ion-justify-content-center mt-4">
                        {!isCompleteList && (
                            <>
                                <IonCol sizeXs="12" sizeMd="2" className="ion-text-center text-info link">
                                    <p onClick={() => getBarns(25)}><span>Load 25 More</span></p>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="2" className="ion-text-center text-info link">
                                    <p onClick={() => getBarns(50)}>Load 50 More</p>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="2" className="ion-text-center text-info link">
                                    <p onClick={() => getBarns(100)}>Load 100 More</p>
                                </IonCol>
                            </>
                        )}
                    </IonRow>
                </>
            }
        </>
    );
};

export default BarnsListEditor;
