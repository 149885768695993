import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import BasicUserInfo from "../../components/ProfilePage/BasicUserInfo";
import Header from "../../components/Headers/Header";
import PageTitle from "../../components/PageTitle/PageTitle";
import React from "react";
import SettingsList from "../../components/SettingsPage/SettingsList";

const SettingsPage: React.FC = () => {

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Settings" />
                <IonRow className="ion-text-center ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="8">
                        <BasicUserInfo />
                    </IonCol>
                </IonRow>
                <IonRow className="ion-text-center ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="8">
                        <SettingsList />
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default SettingsPage;