import { CartItem } from "../../interfaces/Cart";
import { Clinician } from "../../models";
import { getClinicPrice } from "../clinics/calculatePrice";

interface mapItem {
    quantity: number
    object: any
}

export function formatCliniciansAsCartItems(clinicians: Clinician[]) {
    let clinicianMap = new Map();

    if (clinicians && clinicians.length > 0) {
        clinicians.forEach(clinician => {
            const clinicianInMap = clinicianMap.get(clinician.id);
            if (clinicianInMap) {
                const updatedMapItem: mapItem = {
                    quantity: clinicianInMap.quantity + 1,
                    object: clinician
                };
                clinicianMap.set(clinician.id, updatedMapItem);
            } else {
                const newMapItem: mapItem = {
                    quantity: 1,
                    object: clinician
                };
                clinicianMap.set(clinician.id, newMapItem);
            }
        });
    }

    let result: CartItem[] = [];

    clinicianMap.forEach((value: mapItem) => {
        const clinician: Clinician = value.object;
        const cartItem: CartItem = {
            label: clinician.name,
            quantity: value.quantity,
            description: "Video Submission",
            basePrice: getClinicPrice(clinician.price)
        };
        result.push(cartItem);
    });

    return result;
}