import React, { useState } from "react";
import SelectBarnSection from "./SelectBarnSection";
import { Barn, Event } from "../../../models"; 
import EntryInfoSection from "./EntryInfoSection";

interface EntryCardProps {
    event: Event;
}

const EntryCard: React.FC<EntryCardProps> = ({ event }) => {
    const [selectedBarn, setSelectedBarn] = useState<Barn | undefined | null>();

    return (
        <>
            <SelectBarnSection event={event} onSelect={(barn: Barn | null | undefined) => setSelectedBarn(barn)} />
            <EntryInfoSection event={event} selectedBarn={selectedBarn} />
        </>
    );
};

export default EntryCard;
