import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { Input } from "reactstrap";
import { sendNewContactUsMessageEmail } from "../../utilities/emails/ContactUsEmail";
import SuccessBanner from "../Banners/SuccessBanner";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
}

const ContactUsForm: React.FC<_Props> = ({}) => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [messageBody, setMessageBody] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");

    const verifyForm = () => {
        try {
            if (!firstName) {
                setError("Please include a first name.");
                return false;
            }
            if (!lastName) {
                setError("Please include a last name.");
                return false;
            }
            if (!emailAddress) {
                setError("Please include an email address so that we can contact you.");
                return false;
            }
            if (!messageBody) {
                setError("Please include a message.");
                return false;
            }
            return true;
        } catch (error: any) {
            setError(error);
            return false;
        }
    }

    const sendEmail = async () => {
        const sendEmailResult = await sendNewContactUsMessageEmail(firstName, lastName, emailAddress, messageBody);
        if (sendEmailResult?.isSuccess) {
            setSuccess("Thank you for your message! We will get back to you as soon as we can.");
        } else {
            setError("Sorry, a problem occurred. Please try again or reach out to us via email or phone.");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) {
            setError("");
            setSuccess("");
            await sendEmail();
        }
        setIsLoading(false);
    }

    return (
        <>
            <h3 className="ion-text-center">Send us a message:</h3>
            {success && <SuccessBanner success={success} />}
            {error && <ErrorAlert width="12" error={error}/>}
            {success ?
                <SuccessBanner width="12" success={success}/>
                :
                <form>
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonItem color="white">
                                <IonLabel position="stacked">First Name <RequiredInputIndicator/></IonLabel>
                                <IonInput 
                                    type="text"
                                    value={firstName}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setFirstName(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonItem color="white">
                                <IonLabel position="stacked">Last Name <RequiredInputIndicator/></IonLabel>
                                <IonInput 
                                    type="text"
                                    value={lastName}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setLastName(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Email Address <RequiredInputIndicator/></IonLabel>
                                <IonInput 
                                    type="email"
                                    value={emailAddress}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setEmailAddress(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem lines="none">
                                <IonLabel position="stacked">Message <RequiredInputIndicator/></IonLabel>
                            </IonItem>
                            <Input
                                placeholder="Type here"
                                rows="5"
                                type="textarea"
                                name="messageBody"
                                value={messageBody}
                                spellCheck="true"
                                onChange={(event) => setMessageBody(event.target.value)}
                                data-hj-whitelist
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeMd="12">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    Send
                                </IonButton>
                            }
                        </IonCol>
                    </IonRow>
                </form>
            }
        </>
    );
};

export default ContactUsForm;