import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Event, EventEntry } from "../../models";
import { Table } from "reactstrap";
import { PersonContext } from "../../context/PersonContext";
import { useHistory } from "react-router";
import { getEventEntriesByEventIdPersonId } from "../../utilities/eventEntry/EventEntry";
import moment from "moment";
import { sortEventEntries } from "../../utilities/eventEntry/SortEventEntry";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import { onCreateEventEntry, onUpdateEventEntry } from "../../graphql/subscriptions";
import Spinner from "../Spinners/Spinner";
import BasicTooltip from "../Tooltip/BasicTooltip";
import constants from "../../constant/constant";

interface _Props {
    event: (Event)
    organizationId?: (string | null)
}

interface formattedEntry {
    id: string
    backNumber?: string
    horseName: string
    riderName: string
    trainerName: string
    status: string
    lastUpdated: string
    object: EventEntry
}

const UserEventEntryTable: React.FC<_Props> = ({event, organizationId}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const createEntrySubscription = useSubscriptionByItself({
        config: {
            query: onCreateEventEntry,
            key: "onCreateEventEntry"
        }
    });

    const updateEntrySubscription = useSubscriptionByItself({
        config: {
            query: onUpdateEventEntry,
            key: "onUpdateEventEntry"
        }
    });

    const [entries, setEntries] = useState<EventEntry[] | null | undefined>();
    const [formattedEntries, setFormattedEntries] = useState<formattedEntry[] | null | undefined>();
    const [currentCreateSubscriptionItem, setCreateCurrentSubscriptionItem] = useState<any>();
    const [currentUpdateSubscriptionItem, setUpdateCurrentSubscriptionItem] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (createEntrySubscription && createEntrySubscription[0] !== undefined && createEntrySubscription[0] !== currentCreateSubscriptionItem) {
            setCreateCurrentSubscriptionItem(createEntrySubscription[0]);
            if (event) getEntries(event.id);
        }
    }, [createEntrySubscription]);

    useEffect(() => {
        if (updateEntrySubscription && updateEntrySubscription[0] !== undefined && updateEntrySubscription[0] !== currentUpdateSubscriptionItem) {
            setUpdateCurrentSubscriptionItem(updateEntrySubscription[0]);
            if (event) getEntries(event.id);
        }
    }, [updateEntrySubscription]);
    
    useEffect(() => {
        if (event) getEntries(event.id);
    }, [event]);

    function formatEntries(entries: EventEntry[]) {
        let formattedEntries: formattedEntry[] = [];
        if (entries && entries.length > 0) {
            for (let i = 0; i < entries.length; i++) {
                const currentEntry = entries[i];
                if (currentEntry) {
                    let formattedEntry: formattedEntry = {
                        id: currentEntry.id,
                        riderName: currentEntry.riderName + (currentEntry.secondRiderName ? ", " + currentEntry.secondRiderName : ""),
                        horseName: currentEntry.horseName || "",
                        trainerName: currentEntry.trainerName || "",
                        status: currentEntry.status === "declined" ? "declined" : (currentEntry.status === "complete" ? "accepted" : (currentEntry.status === "in_progress" ? "in progress" : "submitted")),
                        backNumber: currentEntry.number?.toString(),
                        lastUpdated: moment(currentEntry.updatedOn).format("MMM DD, YYYY"),
                        object: currentEntry
                    };
                    formattedEntries.push(formattedEntry);
                }
            }
        }
        setFormattedEntries(formattedEntries);
    }

    async function getEntries(eventId: string) {
        setIsLoading(true);
        const queryResult = await getEventEntriesByEventIdPersonId(eventId, user.id);
        if (queryResult.isSuccess) {
            const entryList = queryResult.result;
            const sorted = sortEventEntries(entryList);
            setEntries(sorted || entryList);
            formatEntries(sorted || entryList);
        }
        setIsLoading(false);
    }

    const onSelectAdd = () => {
        const path = "/index/event/" + event.id + "/new-entry";
        history.push(path);
        window.location.reload();
    }

    const onSelectAddFromTemplate = () => {
        const path = "/index/event/" + event.id + "/new-entry-from-template";
        history.push(path);
    }

    const onSelectEntry = (entry: EventEntry) => {
        const path = "/index/event/entry/" + entry.id;
        history.push(path);
    }

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="4">
                        My {event.type === "camp" ? "Registrations": "Entries"}
                    </IonCol>
                    {event.entriesStatus === "accepting" ?
                        <>
                            <IonCol sizeXs="12" sizeMd="4" className="ion-text-right">
                                <IonButton color="success" onClick={() => onSelectAdd()}>Add New {event.type === "camp" ? "Registration": "Entry"}</IonButton>
                            </IonCol>
                            {event.type !== "camp" &&
                                <IonCol sizeXs="12" sizeMd="4" className="ion-text-right">
                                    <IonButton disabled={false} onClick={() => onSelectAddFromTemplate()}>Add From Template</IonButton> 
                                </IonCol>
                            }
                        </>
                        :
                        <>
                            <IonCol sizeXs="12" sizeMd="4" className="ion-text-right">
                                <IonButton color="light" disabled>Entries Not Open</IonButton>
                            </IonCol>
                        </>
                    }
                </IonRow>
            </IonCardTitle>
            <IonCardSubtitle>Click on {event.type === "camp" ? "a Registration": "an Entry"} row to edit it.</IonCardSubtitle>
            {isLoading ?
                <Spinner />
                :
                <IonCardContent>
                    {isPlatform("mobile") ?
                        <IonList className="bg-white">
                            {(formattedEntries && formattedEntries.length > 0) && (
                                <>
                                    {formattedEntries.map((entry, index) => (
                                        <IonItem key={index} onClick={() => onSelectEntry(entry.object)}>
                                            <IonLabel>
                                                <IonRow>
                                                    {event.type !== "camp" &&
                                                        <IonCol sizeXs="12" sizeMd="3">
                                                            <p className="ion-text-wrap">Horse: {entry.horseName}</p>
                                                        </IonCol>
                                                    }
                                                    <IonCol sizeXs="12" sizeMd="3">
                                                        <p className="ion-text-wrap">Rider: {entry.riderName}</p>
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="3">
                                                        <p className="ion-text-wrap">Status: {entry.status}</p>
                                                    </IonCol>
                                                    {(organizationId === constants.RHSC_ORGANIZATION.id) && (
                                                        <IonCol sizeXs="12" sizeMd="3">
                                                            <p className="ion-text-wrap">Back Number: {entry.backNumber}</p>
                                                        </IonCol>
                                                    )}
                                                </IonRow>
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                </>
                            )}
                        </IonList>
                        :
                        <Table hover responsive>
                            <thead>
                                <tr>
                                    {(organizationId === constants.RHSC_ORGANIZATION.id) && (
                                        <th>Back Number</th>
                                    )}
                                    {event.type !== "camp" && <th>Horse Name</th>}
                                    {event.type !== "camp" ? <th>Rider Name</th> : <th>Participant Name</th>}
                                    {event.type !== "camp" && <th>Trainer Name</th>}
                                    <th>Status</th>
                                    <th>Last Updated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(formattedEntries && formattedEntries.length > 0) && (
                                    <>
                                        {formattedEntries.map((entry, index) => (
                                            <tr key={index} onClick={() => onSelectEntry(entry.object)}>
                                                {(organizationId === constants.RHSC_ORGANIZATION.id) && (
                                                    <td className="ion-text-wrap">
                                                        {entry.backNumber}
                                                    </td>
                                                )}
                                                {event.type !== "camp" && (
                                                    <td className="ion-text-wrap">
                                                        {entry.horseName}
                                                    </td>
                                                )}
                                                <td className="ion-text-wrap">
                                                    {entry.riderName}
                                                </td>
                                                {event.type !== "camp" && (
                                                    <td className="ion-text-wrap">
                                                        {entry.trainerName}
                                                    </td>
                                                )}
                                                <td className="ion-text-wrap">
                                                    <span className={entry.status === "declined" ? "text-danger" : (entry.status === "accepted" ? "text-success" : (entry.status === "in progress" ? "text-warning" : "text-primary"))}>
                                                        {entry.status}
                                                    </span>
                                                </td>
                                                <td className="ion-text-wrap">
                                                    {entry.lastUpdated}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </Table>
                    }
                </IonCardContent>
            }
        </IonCard>
    );
};

export default UserEventEntryTable;