import {
    IonButton,
    IonCol,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import ErrorAlert from "../Errors/ErrorAlert";
import { Contact, Person, PersonalInformation } from "../../models";
import { CreateContactInput, CreatePersonalInformationInput, UpdateContactInput, UpdatePersonInput, UpdatePersonalInformationInput } from "../../API";
import { createPersonalInformation, getPersonalInformationByPersonId, updatePersonalInformation } from "../../utilities/personalInformation/PersonalInformation";
import { updatePerson } from "../../utilities/person/Person";
import { createContact, getContactById, updateContact } from "../../utilities/contact/Contact";
import { formatAllCountryPhoneNumber } from "../../utilities/contact/FormatPhoneNumber";
import PhoneInput from "react-phone-input-2";

interface _Props {
    onSubmit?: Function
}

const UserPhoneContactForm: React.FC<_Props> = ({onSubmit}) => {
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");

    const [currentPersonalInformation, setCurrentPersonalInformation] = useState<PersonalInformation | null | undefined>();
    const [currentContact, setCurrentContact] = useState<Contact | null | undefined>();

    const [cellPhoneNumber, setCellPhoneNumber] = useState<string>("");
    const [homePhoneNumber, setHomePhoneNumber] = useState<string>("");
    const [workPhoneNumber, setWorkPhoneNumber] = useState<string>("");


    const getDataFromContact = (contact: Contact) => {
        if (contact.cell) setCellPhoneNumber(contact.cell);
        if (contact.home) setHomePhoneNumber(contact.home);
        if (contact.work) setWorkPhoneNumber(contact.work);
    }

    useEffect(() => {
        const getContactFromUser = async (person: Person) => {
            let personalInformationRecord = person.personalInformation;
    
            if (!personalInformationRecord) {
                const queryResult = await getPersonalInformationByPersonId(person.id);
                if (queryResult.isSuccess) {
                    personalInformationRecord = queryResult.result;
                }
            }
    
            if (personalInformationRecord) {
                setCurrentPersonalInformation(personalInformationRecord);
    
                let personContact = personalInformationRecord.contact;
                if (!personContact && personalInformationRecord.contactId) {
                    const contactQueryResult = await getContactById(personalInformationRecord.contactId);
                    if (contactQueryResult.isSuccess) {
                        personContact = contactQueryResult.result;
                    }
                }
    
                if (personContact) {
                    setCurrentContact(personContact);
                    getDataFromContact(personContact);
                }
            }
        }
        
        if (user) getContactFromUser(user);
    }, [user]);

    const createFullName = (firstName?: (string | null), lastName?: (string | null)) => {
        const fullNameFormatted = (firstName ? firstName.trim() : "") + 
        (firstName && lastName ? " " : "") + 
        (lastName ? lastName.trim() : "");
        return fullNameFormatted;
    }

    const getPersonFullName = () => {
        let person = user;
        const fullName = createFullName(person?.firstName, person?.lastName);
        return fullName;
    }

    const handleCreateContact = async () => {
        try {
            const contactInput: CreateContactInput = {
                name: getPersonFullName(),
                cell: cellPhoneNumber ? formatAllCountryPhoneNumber(cellPhoneNumber) : "",
                work: workPhoneNumber ? formatAllCountryPhoneNumber(workPhoneNumber) : "",
                home: homePhoneNumber ? formatAllCountryPhoneNumber(homePhoneNumber) : ""
            };
            const createResult = await createContact(contactInput);
            if (createResult.isSuccess) {
                if (onSubmit) onSubmit(createResult.result);
                return createResult.result;
            } else {
                const message = "Sorry, an error occurred. " + createResult.message;
                setError(message);
            }
        } catch (error: any) {
            const message = "Sorry, an error occurred. The contact could not be created.";
            setError(message);
        }
    }

    const handleUpdateContact = async (currentContact: Contact) => {
        try {
            const contactInput: UpdateContactInput = {
                id: currentContact.id,
                name: getPersonFullName(),
                cell: cellPhoneNumber ? formatAllCountryPhoneNumber(cellPhoneNumber) : null,
                work: workPhoneNumber ? formatAllCountryPhoneNumber(workPhoneNumber) : null,
                home: homePhoneNumber ? formatAllCountryPhoneNumber(homePhoneNumber) : null
            };
            const updateResult = await updateContact(contactInput);
            if (updateResult.isSuccess) {
                if (onSubmit) onSubmit(updateResult.result);
            } else {
                const message = "Sorry, an error occurred. " + updateResult.message;
                setError(message);
            }
        } catch (error: any) {
            const message = "Sorry, an error occurred. The contact could not be updated.";
            setError(message);
        }
    }

    const handleUpdateUser = async () => {
        if (currentContact) {
            // Just update the contact record
            // This record is already attached to PersonalInformation
            await handleUpdateContact(currentContact);
        } else {
            // First, create the new contact record
            const newContact = await handleCreateContact();

            // Then, try to attach it to the PersonalInformation
            if (newContact) {
                // Check that this user has a PersonalInformation record already
                if (currentPersonalInformation?.id) {
                    const updatePersonalInfoInput: UpdatePersonalInformationInput = {
                        id: currentPersonalInformation.id,
                        contactId: newContact.id
                    };
                    const updateResult = await updatePersonalInformation(updatePersonalInfoInput);
                    if (!updateResult.isSuccess) {
                        const message = "An error occurred. The contact could not be added to your profile.";
                        setError(message);
                    }
                } 
                // If the user does not have a PersonalInformation record, add one
                else if (user) {
                    const createPersonalInfoInput: CreatePersonalInformationInput = {
                        personId: user.id,
                        contactId: newContact.id
                    };
                    const createPersonalInfoResult = await createPersonalInformation(createPersonalInfoInput);
                    if (createPersonalInfoResult.isSuccess) {
                        // Then, update the person record to include the new personal info record
                        const newPersonalInformationRecord = createPersonalInfoResult.result;
                        const updatePersonInput: UpdatePersonInput = {
                            id: user.id,
                            personalInformationId: newPersonalInformationRecord.id
                        };
                        const updatePersonResult = await updatePerson(updatePersonInput);
                        if (!updatePersonResult.isSuccess) {
                            const message = "An error occurred. The contact could not be added to your profile.";
                            setError(message);
                        }
                    } else {
                        const message = "An error occurred. The contact could not be added to your profile.";
                        setError(message);
                    }
                }
            }
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        await handleUpdateUser();
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="12">
                        <IonLabel className="pl-3 text-darker" position="stacked">
                            Cell Phone Number{" "}
                        </IonLabel>
                        <PhoneInput
                            inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: true,
                                id:"profile-cell-phone-number"
                            }}
                            placeholder="Enter phone number"
                            country={"us"}
                            enableSearch
                            enableAreaCodes={false}
                            inputStyle={{
                                width: "100%",
                                height: "auto",
                            }}
                            value={cellPhoneNumber}
                            onChange={(phoneNumber) => {
                                if(isMounted) setIsDisabled(false);
                                else setIsMounted(true);
                                setCellPhoneNumber(phoneNumber);
                            }}
                        />
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="12">
                        <IonLabel className="pl-3 text-darker" position="stacked">
                            Home Phone Number{" "}
                        </IonLabel>
                        <PhoneInput
                            inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: true,
                                id:"profile-home-phone-number"
                            }}
                            placeholder="Enter phone number"
                            country={"us"}
                            enableSearch
                            enableAreaCodes={false}
                            inputStyle={{
                                width: "100%",
                                height: "auto",
                            }}
                            value={homePhoneNumber}
                            onChange={(phoneNumber) => {
                                if(isMounted) setIsDisabled(false);
                                else setIsMounted(true);
                                setHomePhoneNumber(phoneNumber);
                            }}
                        />
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="12">
                        <IonLabel className="pl-3 text-darker" position="stacked">
                            Work Phone Number{" "}
                        </IonLabel>
                        <PhoneInput
                            inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: true,
                                id:"profile-work-phone-number"
                            }}
                            placeholder="Enter phone number"
                            country={"us"}
                            enableSearch
                            enableAreaCodes={false}
                            inputStyle={{
                                width: "100%",
                                height: "auto",
                            }}
                            value={workPhoneNumber}
                            onChange={(phoneNumber) => {
                                if(isMounted) setIsDisabled(false);
                                else setIsMounted(true);
                                setWorkPhoneNumber(phoneNumber);
                            }}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default UserPhoneContactForm;