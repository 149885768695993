import {
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import { Clinician, Submission, SubmissionFeedback } from "../../models";
import { getSubmissionFeedbacksByPersonId } from "../../utilities/submissionFeedback/SubmissionFeedback";
import { getSubmissionById } from "../../utilities/submission/Submission";
import { getClinicianById } from "../../utilities/clinician/Clinician";
import { useHistory } from "react-router";

interface FormattedSubmissionFeedback {
    id: string
    title: string
    clinicianName: string
    sf: SubmissionFeedback
}

const SubmissionFeedbackList: React.FC = () => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [submissionFeedbacks, setSubmissionFeedbacks] = useState<FormattedSubmissionFeedback[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    async function formatSubmissionFeedback(submissionFeedback: SubmissionFeedback[]) {
        let formatted: FormattedSubmissionFeedback[] = [];
        for (let i = 0; i < submissionFeedback.length; i++) {
            const current = submissionFeedback[i];
            if (current.submission?.id) {
                const querySubmissionResult = await getSubmissionById(current.submission?.id);
                const queryClinicianResult = await getClinicianById(current.clinicianId || "");
                if (querySubmissionResult.isSuccess && queryClinicianResult.isSuccess) {
                    const submission: Submission = querySubmissionResult.result;
                    const clinician: Clinician = queryClinicianResult.result;
                    let formattedObject: FormattedSubmissionFeedback = {
                        id: current.id,
                        title: submission.title,
                        clinicianName: clinician.name,
                        sf: current
                    };
                    formatted.push(formattedObject);
                }
            }
        }
        setSubmissionFeedbacks(formatted);
        setIsLoading(false);
    }

    useEffect(() => {
        async function getSubmissions() {
            setIsLoading(true);
            const queryResult = await getSubmissionFeedbacksByPersonId(user.id);
            if (queryResult.isSuccess) {
                await formatSubmissionFeedback(queryResult.result);
            } else {
                setIsLoading(false);
            }
        }
        
        if (user) {
            getSubmissions();
        }
    }, [user]);

    const handleSelectedSubmission = (submissionFeedback: FormattedSubmissionFeedback) => {
        const path = "/index/submissions/" + submissionFeedback.id;
        history.push(path);
    }

    return (
        <>
            {isLoading ?
                <IonRow>
                    <IonCol className="ion-text-center">
                        <Spinner />
                    </IonCol>
                </IonRow>
                :
                <>
                    {submissionFeedbacks ?
                        <IonList className="bg-white">
                            {submissionFeedbacks.map((submissionFeedback: FormattedSubmissionFeedback, index: number) => (
                                <IonItem key={index}>
                                    <IonLabel onClick={() => handleSelectedSubmission(submissionFeedback)}>
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="4">
                                                <p>{submissionFeedback.title}</p>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="4">
                                                <p>{submissionFeedback.clinicianName}</p>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="4">
                                                <p>{submissionFeedback.sf.feedbackStatus}</p>
                                            </IonCol>
                                        </IonRow>
                                    </IonLabel>
                                </IonItem>
                            ))}
                        </IonList>
                        :
                        <p>No submissions yet.</p>
                    }
                </>
            }
        </>
    );
};

export default SubmissionFeedbackList;