import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../../../context/PersonContext";
import { Clinician, Person, SubmissionFeedback } from "../../../../models";
import { getSubmissionFeedbacksByClinicianId } from "../../../../utilities/submissionFeedback/SubmissionFeedback";
import { getClinicianByPersonId } from "../../../../utilities/clinician/Clinician";
import { getPersonByPersonId } from "../../../../utilities/person/Person";
import moment from "moment";
import { sortSubmissionFeedbacksByStatus } from "../../../../utilities/submissionFeedback/SortSubmissionFeedback";
import { useHistory } from "react-router";
import { useSubscriptionByItself } from "../../../../utilities/subscription/Subscription";
import { onUpdateFeedback } from "../../../../graphql/subscriptions";
import Spinner from "../../../../components/Spinners/Spinner";

interface formattedSubmissionFeedback {
    personName: string
    status: string
    dateSent: string
    dateDue: string
    videoTitle: string
    sf: SubmissionFeedback
}

const ClinicianVideoPage: React.FC = () => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const feedbackSubscription = useSubscriptionByItself({
        config: {
            query: onUpdateFeedback,
            key: "onUpdateFeedback"
        }
    });

    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();

    const [submissionFeedback, setSubmissionFeedback] = useState<SubmissionFeedback[] | null | undefined>();
    const [formattedSubmissionFeedback, setFormattedSubmissionFeedback] = useState<formattedSubmissionFeedback[] | null | undefined>();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (feedbackSubscription && feedbackSubscription[0] !== undefined && feedbackSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(feedbackSubscription[0]);
        }

        if (user) {
            getClinician();
        }
    }, [user]);

    const getClinicianSubmissionFeedback = async (clinicianId: string) => {
        const queryResult = await getSubmissionFeedbacksByClinicianId(clinicianId);
        if (queryResult.isSuccess) {
            const sorted = sortSubmissionFeedbacksByStatus(queryResult.result);
            if (sorted) {
                setSubmissionFeedback(sorted);
                await formatSubmissionFeedback(sorted);
            } else {
                setSubmissionFeedback(queryResult.result);
                await formatSubmissionFeedback(queryResult.result);
            }
        }
    }

    const getClinician = async () => {
        setIsLoading(true);
        const queryResult = await getClinicianByPersonId(user.id);
        if (queryResult.isSuccess) {
            const clinician: Clinician = queryResult.result;
            if (clinician.status === "AVAILABLE" || clinician.status === "PAUSED") {
                await getClinicianSubmissionFeedback(clinician.id);
            }
        }
        setIsLoading(false);
    }

    const getPersonName = async (personId: string) => {
        const queryResult = await getPersonByPersonId(personId);
        if (queryResult.isSuccess) {
            const person: Person = queryResult.result;
            const name = person.firstName + " " + person.lastName;
            return name;
        }
    }

    const formatSubmissionFeedback = async (submissionFeedbackItems?: SubmissionFeedback[]) => {
        const items = submissionFeedbackItems || submissionFeedback;
        if (items) {
            let result: formattedSubmissionFeedback[] = [];
            for (let i = 0; i < items.length; i++) {
                const item: SubmissionFeedback = items[i];
                const formatted: formattedSubmissionFeedback = {
                    personName: await getPersonName(item.personId || "") || "unknown",
                    status: item.feedbackStatus?.toString() || "unknown",
                    dateSent: moment(item.createdOn).format("MM/DD/YY"),
                    dateDue: (item.feedbackStatus === "pending" || item.feedbackStatus === "draft") ? moment(item.createdOn).add("weeks", 2).format("MM/DD/YY") : "n/a",
                    videoTitle: item.submission?.video?.title || "unknown",
                    sf: item
                };
                result.push(formatted);
            }
            setFormattedSubmissionFeedback(result);
        }
    }

    const navigateToPage = (id: string) => {
        const path = "/index/judge/clinics/video/" + id;
        history.push(path);
    }
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Your Feedback" />
                <IonRow id="status" className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>All Videos {formattedSubmissionFeedback ? "(" + formattedSubmissionFeedback?.length + ")" : ""}</IonCardTitle>
                            {isLoading ?
                                <Spinner />
                                :
                                <IonCardContent>
                                    {formattedSubmissionFeedback ?
                                        <IonList className="bg-white">
                                            {formattedSubmissionFeedback.map((item, index) => (
                                                <IonItem key={index} onClick={() => navigateToPage(item.sf.id)}>
                                                    <IonLabel>
                                                        <IonRow>
                                                            <IonCol sizeXs="12" sizeMd="3">
                                                                {item.personName}
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="3">
                                                                <span className={item.status === "complete" ? "text-success" : (item.status === "declined" ? "text-warning" : "text-danger")}>{item.status}</span>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="3">
                                                                Sent: {item.dateSent}
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="3">
                                                                Due: {item.dateDue}
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonLabel>
                                                </IonItem>
                                            ))}
                                        </IonList>
                                        :
                                        <p>No Videos</p>
                                    }
                            </IonCardContent>
                            }
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default ClinicianVideoPage;