import { 
    IonButton,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonText
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { create, mail, phonePortrait } from "ionicons/icons";
import { Organization } from "../../models";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import { UserGroup } from "../../interfaces/UserGroup";
import { listUsersInGroup } from "../../utilities/adminQueries/userGroups";

interface _Props {
    organization: Organization,
    userGroup?: (UserGroup | null)
}

interface GroupUser {
    Username: string
    UserStatus: string
    UserCreateDate: string
    Attributes: GroupUserAttributes[]
}

interface GroupUserAttributes {
    Name: string
    Value: string
}

const OrganizationMemberList: React.FC<_Props> = ({organization, userGroup}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(true);
    const [members, setMembers] = useState<GroupUser[] | null>();

    useEffect(() => {
        async function getGroupMembers() {
            if (userGroup) {
                const result = await listUsersInGroup(userGroup.GroupName);
                if (result && result.Users) {
                    const members: GroupUser[] = result.Users;
                    if (members && members.length) {
                        setMembers(members);
                    }
                }
            }
            setIsLoading(false);
        }

        getGroupMembers();
    }, [userGroup]);

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonList className="bg-white">
                                {!userGroup && (<IonText>Want to add more admins to your organization? Contact your RingSide Pro rep and we'll make sure you can invite your team!</IonText>)}
                                {(members && members?.length) ?
                                    <>
                                        {members.map((member: GroupUser, index: number) => (
                                            <IonItem key={index}>
                                                <IonLabel className="ion-text-wrap">
                                                    <IonRow>
                                                        <IonCol sizeXs="12" sizeMd="4">
                                                            <IonText>{(member.Attributes.filter(a => a.Name === "email"))[0].Value}</IonText>
                                                        </IonCol>
                                                        <IonCol sizeXs="12" sizeMd="4">
                                                            <IonText>
                                                                Organization Admin
                                                            </IonText>
                                                        </IonCol>
                                                        <IonCol sizeXs="12" sizeMd="4">
                                                            <IonButton fill="clear" disabled={true}><IonIcon icon={create}/></IonButton>
                                                            <IonButton fill="clear" href={"mailto:" + (member.Attributes.filter(a => a.Name === "email"))[0].Value}><IonIcon icon={mail}/></IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonLabel>
                                            </IonItem> 
                                        ))}
                                    </>
                                    :
                                    <IonItem>
                                        <IonLabel className="ion-text-wrap">
                                            <IonRow>
                                                <IonCol sizeXs="12" sizeMd="4">
                                                    <IonText>{user.email}</IonText>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="4">
                                                    <IonText>
                                                        Organization Admin
                                                    </IonText>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="4">
                                                    <IonButton fill="clear" disabled={true}><IonIcon icon={create}/></IonButton>
                                                    <IonButton fill="clear" disabled={true}><IonIcon icon={mail}/></IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                    </IonItem>
                                }
                                
                                <IonItem>
                                    <IonLabel className="ion-text-wrap">
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="4">
                                                <IonText>emma@ringsidepro.com</IonText>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="4">
                                                <IonText>
                                                    Your RingSide Pro Rep
                                                </IonText>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="4">
                                                <IonButton fill="clear" disabled={true}><IonIcon icon={create}/></IonButton>
                                                <IonButton fill="clear" href="mailto:emma@ringsidepro.com"><IonIcon icon={mail}/></IonButton>
                                                <IonButton fill="clear" href="tel:+17578120385"><IonIcon icon={phonePortrait}/></IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonLabel>
                                </IonItem>
                                
                            </IonList>
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default OrganizationMemberList;
