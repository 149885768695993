import React, { useEffect, useState } from "react";
import {
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import { Barn, Trainer } from "../../../models";
import { Label } from "reactstrap";
import SelectState from "../../Address/SelectState";
import SelectOrCreateTrainer from "../../Trainer/SelectOrCreateTrainer";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";

interface _Props {
    selectedBarn?: (Barn | null | undefined);
    onSelectTrainer: (trainer?: Trainer) => void;
}

const SelectTrainerSection: React.FC<_Props> = ({ selectedBarn, onSelectTrainer }) => {
    const [selectedTrainer, setSelectedTrainer] = useState<Trainer | undefined>(undefined);
    const [trainerCity, setTrainerCity] = useState<string>("");
    const [trainerState, setTrainerState] = useState<string>("");

    const handleSelectedTrainer = (trainer: Trainer, name: string) => {
        // Set the trainer object
        trainer && setSelectedTrainer(trainer);

        // Set the trainer's city/state
        setTrainerCity(trainer?.address?.city || "");
        setTrainerState(trainer?.address?.provState || "-");
        
        // Notify parent component
        onSelectTrainer(trainer);
    }

    const handleStateSelection = (value: string) => {
        setTrainerState(value);
    };

    useEffect(() => {
        // Whenever barn changes, reset the trainer
        setSelectedTrainer(undefined);
    }, [selectedBarn]);

    return (
        <>
            {/* First Row - Trainer Name */}
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <Label>
                        Name
                        <RequiredInputIndicator />
                    </Label>
                    <SelectOrCreateTrainer 
                        selectedBarn={selectedBarn}
                        onSelect={handleSelectedTrainer}
                    />
                </IonCol>
            </IonRow>

            {/* Second Row - City and State */}
            <IonRow>
                {/* Location - City */}
                <IonCol sizeXs="12" sizeMd="4.5">
                    <IonItem color="white">
                        <IonLabel position="stacked">City</IonLabel>
                        <IonInput 
                            type="text"
                            value={trainerCity}
                            onIonChange={e => {setTrainerCity(e.detail.value!)}}
                        />
                    </IonItem>              
                </IonCol>
                {/* Location - State */}
                <IonCol sizeXs="12" sizeMd="1.5">
                    <SelectState 
                        selectedValue={trainerState}
                        onInputChange={handleStateSelection} 
                    />
                </IonCol>
            </IonRow>
        </>
    );
};

export default SelectTrainerSection;
