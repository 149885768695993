import { UpdateEventEntryFeeInput } from "../../API";
import { Result } from "../../interfaces/Result";
import { getEventEntryFeesByEventIdByEntryId, updateEventEntryFee } from "./EventEntryFee";

export async function updateEventEntryFeeStatusByEventByEntry(eventId: string, entryId: string, status: string) {
    const queryResult = await getEventEntryFeesByEventIdByEntryId(eventId, entryId);
    if (queryResult.isSuccess) {
        const list = queryResult.result;

        // Loop through the eventEntryFees and updates their statuses
        let isSuccess = true;
        let message = "";
        for (var i = 0; i < list.length; i++) {
            const currentFee = list[i];
            const input: UpdateEventEntryFeeInput = {
                id: currentFee.id,
                status: status
            };
            const updateResult = await updateEventEntryFee(input);
            if (!updateResult.isSuccess) {
                isSuccess = false;
                message = message + "Could not update the status on event entry fee for: " + currentFee.id + ". ";
            }
        }
        if (isSuccess) {
            let result: Result = {isSuccess: true, type: "EventEntryFee", result: null, message: "Successfully updated the event entry fee statuses."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "EventEntryFee", result: null, message: message};
            return result; 
        }
    } else {
        let result: Result = {isSuccess: true, type: "EventEntryFee", result: null, message: "Could find any event entry fees."};
        return result; 
    }
}