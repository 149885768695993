import moment from "moment";
import { EventDay } from "../../models";

export const getStartDate = (eventDates: EventDay[]): EventDay | null | undefined => {
    let startDateObject;
    
    if (eventDates && eventDates.length) {
        startDateObject = eventDates[0];
        let startDate = startDateObject.awsdate || "9999-99-99";

        for (var i = 0; i < eventDates?.length; i++) {
            const currentDate = eventDates[i];
            if (currentDate.awsdate && moment(currentDate.awsdate).isBefore(moment(startDate))) {
                startDate = currentDate.awsdate;
                startDateObject = currentDate;
            }
        }
    } 

    return startDateObject;
}

export const getEndDate = (eventDates: EventDay[]): EventDay | null | undefined => {
    let endDateObject;
    
    if (eventDates && eventDates.length) {
        endDateObject = eventDates[0];
        let endDate = endDateObject.awsdate || "0000-00-00";

        for (var i = 0; i < eventDates?.length; i++) {
            const currentDate = eventDates[i];
            if (currentDate.awsdate && moment(currentDate.awsdate).isAfter(moment(endDate))) {
                endDate = currentDate.awsdate;
                endDateObject = currentDate;
            }
        }
    } 

    return endDateObject;
}