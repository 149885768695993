import { API } from "aws-amplify";
import { CartItem } from "../../interfaces/Cart";
import { Result } from "../../interfaces/Result";

/**
 * Functionality to create a new Checkout Session for a user to pay for online entry software
 * 
 * More details here: https://stripe.com/docs/payments/accept-a-payment?integration=checkout&platform=web
 * 
 * @param {string} accountId  
 */
export async function createOnlineEntryCheckoutSession(accountId: string, eventId: string, quantity: string) {
    try {
        let queryParams = "";
        if (!accountId) {
            let result: Result = {isSuccess: false, type: "Stripe", result: "No account id provided.", message: "Failed to create a checkout session." }
            return result;
        }
        queryParams = queryParams + "?accountid=" + accountId;
        queryParams = queryParams + "&eventId=" + eventId;
        queryParams = queryParams + "&quantity=" + quantity;

        const path = "/payments/create-online-entry-checkout-session" + queryParams;
        const response = await API.get("Payments", path, {});
        const responseBody = JSON.parse(response.body);
        const session = responseBody.session;
        
        if (session && session.id) {
            let result: Result = {isSuccess: true, type: "Stripe", result: session, message: "Successfully created a new checkout session."}
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "Stripe", result: session, message: "Could not create a new checkout session."}
            return result;
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Error occurred while trying to create a new checkout session." }
        return result;
    }
}

/**
 * Functionality to create a new Checkout Session for a user to pay for credit block
 * 
 * More details here: https://stripe.com/docs/payments/accept-a-payment?integration=checkout&platform=web
 * 
 * @param {string} accountId  
 */
 export async function createCreditBlockCheckoutSession(accountId: string, productId: string, quantity: string, successURL: string, cancelURL: string) {
    try {
        let queryParams = "";
        if (!accountId) {
            let result: Result = {isSuccess: false, type: "Stripe", result: "No account id provided.", message: "Failed to create a checkout session." }
            return result;
        }
        if (!productId) {
            let result: Result = {isSuccess: false, type: "Stripe", result: "No product id provided.", message: "Failed to create a checkout session." }
            return result;
        }

        queryParams = queryParams + "?accountid=" + accountId;
        queryParams = queryParams + "&productId=" + productId;
        queryParams = queryParams + "&quantity=" + quantity;
        queryParams = queryParams + "&successURL=" + successURL;
        queryParams = queryParams + "&cancelURL=" + cancelURL;

        const path = "/payments/create-credit-block-checkout-session" + queryParams;
        const response = await API.get("Payments", path, {});
        const responseBody = JSON.parse(response.body);
        const session = responseBody.session;
        
        if (session && session.id) {
            let result: Result = {isSuccess: true, type: "Stripe", result: session, message: "Successfully created a new checkout session."}
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "Stripe", result: session, message: "Could not create a new checkout session."}
            return result;
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Error occurred while trying to create a new checkout session." }
        return result;
    }
}

/**
 * Functionality to create a new Checkout Session for a user to pay for credit block
 * 
 * More details here: https://stripe.com/docs/payments/accept-a-payment?integration=checkout&platform=web
 * 
 * @param {string} accountId  
 */
 export async function getCheckoutSessionInfo(sessionId: string) {
    try {
        let queryParams = "";
        if (!sessionId) {
            let result: Result = {isSuccess: false, type: "Stripe", result: "No session id provided.", message: "Failed to create a checkout session." }
            return result;
        }

        queryParams = queryParams + "?sessionId=" + sessionId;

        const path = "/payments/checkout-session-info" + queryParams;
        const response = await API.get("Payments", path, {});
        const responseBody = JSON.parse(response.body);
        const session = responseBody.sessionObject;
        
        if (session) {
            let result: Result = {isSuccess: true, type: "Stripe", result: session, message: "Successfully got the checkout session info."}
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "Stripe", result: session, message: "Could not get the checkout session info."}
            return result;
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Error occurred while trying to get the checkout session info." }
        return result;
    }
}

/**
 * Functionality to create a new Checkout Session for a user to pay for online entry software
 * 
 * More details here: https://stripe.com/docs/payments/accept-a-payment?integration=checkout&platform=web
 * 
 * @param {string} accountId  
 */
 export async function createClinicCheckoutSession(accountId: string, submissionId: string, cartItems: CartItem[]) {
    try {
        const queryData = {
            body: {
                "accountId": accountId,
                "cartItems": cartItems,
                "submissionId": submissionId
            }
        }

        const path = "/payments/create-clinics-checkout-session";
        const result = await API.post("Payments", path, queryData);

        const responseBody = JSON.parse(result.body);
        const session = responseBody.session;
        
        if (session && session.id) {
            let result: Result = {isSuccess: true, type: "Stripe", result: session, message: "Successfully created a new checkout session."}
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "Stripe", result: session, message: "Could not create a new checkout session."}
            return result;
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Error occurred while trying to create a new checkout session." }
        return result;
    }
}