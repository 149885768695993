import {
    IonButton,
    IonButtons,
    IonContent,
    IonIcon,
    IonModal,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import { close } from "ionicons/icons";
import { Organization, OrganizationAuditor } from "../../models";
import OrganizationAuditorForm from "./OrganizationAuditorForm";

interface _Props {
    organization: Organization
    organizationAuditor?: (OrganizationAuditor | null)
    show?: Boolean
    onChange: Function
    isAdminView?: Boolean
}

const OrganizationAuditorModal: React.FC<_Props> = ({organization, organizationAuditor, show, onChange, isAdminView}) => {
    
    const [showModal, setShowModal] = useState(false);
    const [currentOrganizationAuditor, setCurrentOrganizationAuditor] = useState<OrganizationAuditor | null | undefined>();

    useEffect(() => {
        if (organizationAuditor) {
            setCurrentOrganizationAuditor(organizationAuditor);
            setShowModal(true);
        }
        else setCurrentOrganizationAuditor(undefined);
    }, [organizationAuditor]);

    useEffect(() => {
        if (show) setShowModal(true);
        else setShowModal(false);
    }, [show]);

    const handleFoundOrganizationAuditor = (organizationAuditor: OrganizationAuditor) => {
        setCurrentOrganizationAuditor(organizationAuditor);
    }

    const handleSubmit = (action: string, organizationAuditor: OrganizationAuditor) => {
        setShowModal(false);
        onChange(action, organizationAuditor);
    }

    return (
        <>
            <IonModal backdropDismiss={false} isOpen={showModal} id="contactModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        {currentOrganizationAuditor ? "Edit Subscriber" : "New Subscriber"}
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeContactModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <OrganizationAuditorForm organization={organization} organizationAuditor={currentOrganizationAuditor} isAdminView={isAdminView} onFindCurrentOrganizationAuditor={handleFoundOrganizationAuditor} onCreate={(organizationAuditor: OrganizationAuditor) => handleSubmit("create", organizationAuditor)} onEdit={(organizationAuditor: OrganizationAuditor) => handleSubmit("update", organizationAuditor)} onDelete={(organizationAuditor: OrganizationAuditor) => handleSubmit("delete", organizationAuditor)}/>
                </IonContent>
            </IonModal>
        </>
    );
};

export default OrganizationAuditorModal;
