import { Clinician } from "../../models";
import React, { useEffect, useState } from "react";
import { getAllActiveClinicians } from "../../utilities/clinician/Clinician";
import { IonItem, IonList, IonLabel, IonRow, IonCol, IonIcon, IonButton } from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";
import { getClinicPrice } from "../../utilities/clinics/calculatePrice";
import { Col, Row } from "reactstrap";
import { getFormattedDisciplineString } from "../../utilities/discipline/FormatDisciplines";

interface _Props {
    selectedClinicians?: Clinician[]
    onSelect: Function
}

interface formattedOption {
    label: string
    value: string
    clinician: Clinician
    isExpanded: boolean
    isSelected: boolean
}

const LargeSelectClinicians: React.FC<_Props> = ({selectedClinicians, onSelect}) => {

    const [clinicianOptions, setClinicianOptions] = useState<formattedOption[] | null | undefined>();
    const [selectedFormattedClinicians, setSelectedFormattedClinicians] = useState<formattedOption[] | null | undefined>();

    const formatClinicians = (clinicians: Clinician[]) => {
        let formattedClinicians = [];
        for (var i = 0; i < clinicians.length; i++) {
            let object = {
                value: clinicians[i].id,
                label: clinicians[i].name,
                clinician: clinicians[i],
                isExpanded: false,
                isSelected: false
            };
            formattedClinicians.push(object);
        }
        setClinicianOptions(formattedClinicians);
    }

    const formatSelectedValues = (values: Clinician[]) => {
        if (values) {
            let result: formattedOption[] = [];
            for (var i = 0; i < values.length; i++) {
                const currentOption = values[i];
                let object = {
                    value: currentOption.id,
                    label: currentOption.name,
                    clinician: currentOption,
                    isExpanded: false,
                    isSelected: true
                };
                result.push(object);
            }
            setSelectedFormattedClinicians(result);
        }
    };

    const getClinicians = async () => {
        const queryResult = await getAllActiveClinicians();
        if (queryResult.isSuccess) {
            formatClinicians(queryResult.result);
        }
    }

    useEffect(() => {
        getClinicians();
    }, []);

    useEffect(() => {
        if (selectedClinicians) {
            formatSelectedValues(selectedClinicians);
        }
    }, [selectedClinicians]);

    const handleExpand = (selectedClinicianOption: formattedOption, index: number, isExpand: boolean) => {
        const newClinicianOption: formattedOption = {
            ...selectedClinicianOption,
            isExpanded: isExpand
        };
        if (clinicianOptions && clinicianOptions.length > 0) {
            const newClinicianOptions = [
                ...clinicianOptions?.slice(0, index),
                newClinicianOption,
                ...clinicianOptions?.slice(index + 1)
            ];
            setClinicianOptions(newClinicianOptions);
        } else {
            setClinicianOptions([newClinicianOption]);
        }
    }

    const handleUpdateSelect = (selectedClinicianOption: formattedOption, index: number) => {
        const newClinicianOption: formattedOption = {
            ...selectedClinicianOption,
            isSelected: !selectedClinicianOption.isSelected
        };
        if (clinicianOptions && clinicianOptions.length > 0) {
            const newClinicianOptions = [
                ...clinicianOptions?.slice(0, index),
                newClinicianOption,
                ...clinicianOptions?.slice(index + 1)
            ];
            setClinicianOptions(newClinicianOptions);
        } else {
            setClinicianOptions([newClinicianOption]);
        }
    }

    const handleSelect = (selectedClinicianOption: formattedOption, index: number) => {
        if (selectedFormattedClinicians && selectedFormattedClinicians.length > 0) {
            // First, update the array of formatted clinicians
            let newClinicianList: formattedOption[] = [];
            if (selectedClinicianOption.isSelected) {
                // Previously added to cart, now remove it
                newClinicianList = [
                    ...selectedFormattedClinicians.slice(0, index),
                    ...selectedFormattedClinicians.slice(index + 1)
                ];
            } else {
                // Need to add it to cart
                newClinicianList = selectedFormattedClinicians.concat([selectedClinicianOption]);
            }
            setSelectedFormattedClinicians(newClinicianList);

            // Second, update the array of clinicians
            let clinicians: Clinician[] = [];
            newClinicianList.forEach(clinicianOption => {
                clinicians.push(clinicianOption.clinician);
            });
            onSelect(clinicians);
        } else {
            // Nothing was in cart, add selected clinician to the cart
            setSelectedFormattedClinicians([selectedClinicianOption]);
            onSelect([selectedClinicianOption.clinician]);
        }
        handleUpdateSelect(selectedClinicianOption, index);
    }

    return (
        <>
            {clinicianOptions ? 
                <IonList className="bg-white">
                    {clinicianOptions.map((clinician, index) => (
                        <IonItem key={index}>
                            <IonLabel>
                                <>
                                    <Row>
                                        <Col xs="10" sm="11">
                                            <Row>
                                                <Col xs={{size: 12, order: 1}} sm={{size: 4, order: 1}}>
                                                    <h2 className="ion-wrap-text" onClick={() => handleExpand(clinician, index, !clinician.isExpanded)}>{clinician.label}</h2>
                                                </Col>
                                                <Col xs={{size: 12, order: 2}} sm={{size: 4, order: 2}}>
                                                    <h2 className="ion-wrap-text" onClick={() => handleExpand(clinician, index, !clinician.isExpanded)}>${getClinicPrice(clinician.clinician.price).toFixed(2)}</h2>
                                                </Col>
                                                <Col xs={{size: 12, order: 4}} sm={{size: 3, order: 3}} md={{size: 3, order: 3, offset: 1}}>
                                                    <IonButton color={clinician.isSelected ? "danger"  : "tertiary"} onClick={() => handleSelect(clinician, index)}>{clinician.isSelected ? "Remove"  : "Add To Cart"}</IonButton>
                                                </Col>
                                                <Col xs={{size: 12, order: 3}} sm={{size: 12, order: 4}}>
                                                    <h3 className="ion-text-wrap" onClick={() => handleExpand(clinician, index, !clinician.isExpanded)}>{getFormattedDisciplineString(clinician.clinician.disciplines || "")}</h3>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs="2" sm="1" className="text-right">
                                            {clinician.isExpanded ? <IonIcon icon={chevronUp} ariaLabel="Collapse" onClick={() => handleExpand(clinician, index, false)} /> : <IonIcon icon={chevronDown} ariaLabel="Expand" onClick={() => handleExpand(clinician, index, true)} />}
                                        </Col>
                                    </Row>
                                    {clinician.isExpanded && (
                                        <>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <h2>Experience: </h2>
                                                    <p className="ion-text-wrap">{clinician.clinician.experience}</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <h2 className="ion-text-wrap">Ratings: {clinician.clinician.rating}</h2>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <h2 className="ion-text-wrap">IHSA Experience: {clinician.clinician.hasIHSAExperience ? "Yes" : "No"}</h2>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <h2 className="ion-text-wrap">IEA Experience: {clinician.clinician.hasIEAExperience ? "Yes" : "No"}</h2>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <h2>Personal: </h2>
                                                    <p className="ion-text-wrap">{clinician.clinician.personal}</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <h2>Preferences: </h2>
                                                    <p className="ion-text-wrap">{clinician.clinician.preferences}</p>
                                                </IonCol>
                                            </IonRow>
                                        </>
                                    )}
                                </>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
                :
                <p>Loading...</p>
            }
        </>
    );
};

export default LargeSelectClinicians;