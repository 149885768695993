import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventRulesAgreementInput, CreateEventRulesAgreementMutation, DeleteEventRulesAgreementInput, DeleteEventRulesAgreementMutation, GetEventRulesAgreementQuery, ListEventRulesAgreementsQuery, UpdateEventRulesAgreementInput, UpdateEventRulesAgreementMutation } from "../../API";
import moment from "moment";
import { EventRulesAgreement } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventRulesAgreement. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventRulesAgreement.
*/
export async function createEventRulesAgreement(input: CreateEventRulesAgreementInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventRulesAgreement", "No input", "Create EventRulesAgreement received no input.");
   try {
        const fullInput: CreateEventRulesAgreementInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventRulesAgreement, { input: fullInput }))) as GraphQLResult<CreateEventRulesAgreementMutation>;
       else result = (await API.graphql({
           query: mutations.createEventRulesAgreement,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventRulesAgreementMutation>;
       const eventRulesAgreement = result.data?.createEventRulesAgreement;
       return formatResult(true, "EventRulesAgreement", eventRulesAgreement, "Successfully created the eventRulesAgreement.");
   } catch (error: any) {
       return formatResult(false, "EventRulesAgreement", error, "Error creating record in the database for type: eventRulesAgreement");
   }
}

/**
* Handle updating a new record in the database for type: eventRulesAgreement. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventRulesAgreement.
*/
export async function updateEventRulesAgreement(input: UpdateEventRulesAgreementInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventRulesAgreement", "No input", "Update EventRulesAgreement received no input.");
   try {
        const fullInput: UpdateEventRulesAgreementInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventRulesAgreement, { input: fullInput }))) as GraphQLResult<UpdateEventRulesAgreementMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventRulesAgreement,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventRulesAgreementMutation>;
       const eventRulesAgreement = result.data?.updateEventRulesAgreement;
       return formatResult(true, "EventRulesAgreement", eventRulesAgreement, "Successfully updated the eventRulesAgreement.");
   } catch (error: any) {
       return formatResult(false, "EventRulesAgreement", error, "Error updating record in the database for type: eventRulesAgreement");
   }
}

/**
* Handle deleting a new record in the database for type: eventRulesAgreement. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventRulesAgreement.
*/
export async function deleteEventRulesAgreement(input: DeleteEventRulesAgreementInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventRulesAgreement", "No input", "Delete EventRulesAgreement received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventRulesAgreement, { input: input }))) as GraphQLResult<DeleteEventRulesAgreementMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventRulesAgreement,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventRulesAgreementMutation>;
       const eventRulesAgreement = result.data?.deleteEventRulesAgreement;
       return formatResult(true, "EventRulesAgreement", eventRulesAgreement, "Successfully deleted the eventRulesAgreement.");
   } catch (error: any) {
       return formatResult(false, "EventRulesAgreement", error, "Error deleting record in the database for type: eventRulesAgreement");
   }
}

/**
* Get all records in the database for type: eventRulesAgreement. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventRulesAgreement object
*/
export async function getAllEventRulesAgreements(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventRulesAgreements,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventRulesAgreementsQuery>;
        const eventRulesAgreements = result.data?.listEventRulesAgreements?.items;
        return formatResult(true, "EventRulesAgreement", eventRulesAgreements, "Successfully got the eventRulesAgreements.");
    } catch (error: any) {
        return formatResult(false, "EventRulesAgreement", error, "Error reading record in the database for type: eventRulesAgreements");
    }
}

/**
* Read a specific record in the database for type: eventRulesAgreement. 
* 
* @param {string}  id                  The eventRulesAgreement id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventRulesAgreement object
*/
export async function getEventRulesAgreementById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventRulesAgreement,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventRulesAgreementQuery>;
       const eventRulesAgreement = result.data?.getEventRulesAgreement;
       return formatResult(true, "EventRulesAgreement", eventRulesAgreement, "Successfully got the eventRulesAgreement.");
   } catch (error: any) {
       return formatResult(false, "EventRulesAgreement", error, "Error reading record in the database for type: eventRulesAgreement");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventRulesAgreement. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventRulesAgreement object
*/
export async function getEventRulesAgreementsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventRulesAgreements,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventRulesAgreementsQuery>;

        let items = result.data?.listEventRulesAgreements?.items as EventRulesAgreement[];
        let nextToken = result.data?.listEventRulesAgreements?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventRulesAgreements,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventRulesAgreementsQuery>;

            const nextItems = nextResult.data?.listEventRulesAgreements?.items as EventRulesAgreement[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventRulesAgreements?.nextToken;
        }

        return formatResult(true, "EventRulesAgreement", items, "Successfully got the eventRulesAgreements.");
    } catch (error: any) {
        return formatResult(false, "EventRulesAgreement", error, "Error reading record in the database for type: eventRulesAgreements");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventRulesAgreement. 
* 
* @param {string}  entryId             The entry id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventRulesAgreement object
*/
export async function getEventRulesAgreementsByEntryId(entryId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventEntryId: {eq: entryId}};
        const result = (await API.graphql({
            query: queries.listEventRulesAgreements,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventRulesAgreementsQuery>;


        let items = result.data?.listEventRulesAgreements?.items as EventRulesAgreement[];
        let nextToken = result.data?.listEventRulesAgreements?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventRulesAgreements,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventRulesAgreementsQuery>;

            const nextItems = nextResult.data?.listEventRulesAgreements?.items as EventRulesAgreement[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventRulesAgreements?.nextToken;
        }

        return formatResult(true, "EventRulesAgreement", items, "Successfully got the eventRulesAgreements.");
    } catch (error: any) {
        return formatResult(false, "EventRulesAgreement", error, "Error reading record in the database for type: eventRulesAgreements");
    }
}