import moment from "moment";
import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { onCreateBarn, onUpdateBarn } from "../../graphql/subscriptions";
import { Barn } from "../../models";
import { getAllBarns } from "../../utilities/barn/Barn";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import { formatShortAddress } from "../../utilities/address/FormatAddress";
import Spinner from "../Spinners/Spinner";

interface _Props {
    onSelect: Function
    selectedValue?: string
    barn?: (Barn | null)
    isDisabled?: boolean
    showBarnIds?: boolean
    isHeightRequired?: boolean
    height?: string
    placeholder?: string;
}

interface formattedOption {
    label: string
    value: string
    barn: Barn
}

const SelectFromAllBarns: React.FC<_Props> = ({barn, onSelect, selectedValue, isDisabled, showBarnIds, isHeightRequired, height, placeholder}) => {

    const barnSubscription = useSubscriptionByItself({
        config: {
            query: onCreateBarn,
            key: "onCreateBarn"
        }
    });

    const barnUpdateSubscription = useSubscriptionByItself({
        config: {
            query: onUpdateBarn,
            key: "onUpdateBarn"
        }
    });

    const [isLoading, setIsLoading] = useState(false);
    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    const [currentUpdateSubscriptionItem, setUpdateCurrentSubscriptionItem] = useState<any>();
    const [formattedBarns, setFormattedBarns] = useState<formattedOption[] | null | undefined>();
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();

    const getBarns = async () => {
        setIsLoading(true);
        const queryResult = await getAllBarns();
        if (queryResult.isSuccess) {
            const barns = queryResult.result;
            const sorted = barns.sort((a: Barn, b: Barn) => (a?.name || "zzzzzz").localeCompare(b?.name || "zzzzzz"))
            formatBarns(sorted || barns);          
        }
        setIsLoading(false);
    };

    const formatBarn = (barn: Barn) => {
        const barnName = barn.name || "";
        const barnCreatedOn = barn.createdOn ? moment(barn.createdOn).format("MM-DD-YY") : "unknown";
        const barnLocation = barn.location ? formatShortAddress(barn.location) : "unknown";

        const label = barnName + "\nCreated On: " + barnCreatedOn + "\nLocation: " + barnLocation + (showBarnIds ? ("\nId: " + barn.id) : "");
        let object: formattedOption = {
            value: barn.id,
            label: label,
            barn: barn as Barn
        };
        return object;
    }

    const formatBarns = (barns: Barn[]) => {
        let formattedBarns = [];
        for (var i = 0; i < barns.length; i++) {
            const barn = barns[i];
            if (barn) {
                let object: formattedOption = formatBarn(barn);
                formattedBarns.push(object);
            }
        }
        setFormattedBarns(formattedBarns);
        if (selectedValue) findValueInList(selectedValue, formattedBarns);
    }

    const findValueInList = (value: string, barnList?: formattedOption[]) => {
        let optionArray = barnList || formattedBarns;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        getBarns();
    }, []);

    useEffect(() => {
        if (barn) {
            const formattedBarn = formatBarn(barn);
            setCurrentValue(formattedBarn);
        } else {
            setCurrentValue(undefined);
        }
    }, [barn]);

    useEffect(() => {
        if (barnSubscription && barnSubscription[0] !== undefined && barnSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(barnSubscription[0]);
            getBarns();
        }
    }, [barnSubscription]);

    useEffect(() => {
        if (barnUpdateSubscription && barnUpdateSubscription[0] !== undefined && barnUpdateSubscription[0] !== currentUpdateSubscriptionItem) {
            setUpdateCurrentSubscriptionItem(barnUpdateSubscription[0]);
            getBarns();
        }
    }, [barnUpdateSubscription]);

    useEffect(() => {
        async function setValue() {
            if (selectedValue) {
                if (!formattedBarns) {
                    await getBarns();
                } else {
                    findValueInList(selectedValue);
                }
                
            } else {
                setCurrentValue(undefined);
            }
        }
        setValue();
    }, [selectedValue]);

    const handleOnChange = async (event: any) => {
        if (event && event.barn) {
            setCurrentValue(event);
            onSelect(event.barn);
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <Select
                    id = "select-from-all-barn"
                    inputId="select-from-all-barn-input"
                    classNamePrefix="react-select"
                    styles={{
                        // Fixes the overlapping problem of the component
                        control: baseStyles => ({
                            ...baseStyles,
                            height: isHeightRequired ? height : baseStyles.height
                        }),
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    defaultValue={currentValue}
                    value={currentValue}
                    menuPortalTarget={document.body}
                    menuPlacement={"auto"}
                    isClearable
                    isDisabled={isDisabled}
                    options={formattedBarns!}
                    onChange={handleOnChange}
                    placeholder={placeholder || "Select..."}
                />
            }
        </>
    );
};

export default SelectFromAllBarns;
