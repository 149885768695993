import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRadio,
    IonReorder,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Event, EventClass, EventClassEntry } from "../../models";
import { getEventClassesByEventId } from "../../utilities/eventClass/EventClass";
import { getEventClassTypeAbbreviation } from "../../utilities/eventClass/EventClassTypes";
import { getAcceptedEventClassEntriesByEventClassId, getEventClassEntriesCountByEventClassId } from "../../utilities/eventClassEntry/EventClassEntry";
import { sortEventClassEntriesByNumber } from "../../utilities/eventClassEntry/SortEventClassEntries";
import { generateClassCountsPDF } from "../../utilities/reports/ClassCountsReport";
import { generateClassSheetPDF } from "../../utilities/reports/ClassSheetPDF";
import DownloadLink from "../PDF/DownloadLink";
import Spinner from "../Spinners/Spinner";

interface formattedEventClass {
    number: number,
    name: string,
    type: string,
    divisionName: string,
    ec: EventClass
}

interface _Props {
    event: Event
}

const EventClassDisplayTable: React.FC<_Props> = ({event}) => {  
    
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSelectedClassInfo, setIsLoadingSelectedClassInfo] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [eventClasses, setEventClasses] = useState<EventClass[] | null | undefined>();
    const [formattedEventClasses, setFormattedEventClasses] = useState<formattedEventClass[] | null | undefined>();

    const [showModal, setShowModal] = useState(false);
    const [selectedEventClass, setSelectedEventClass] = useState<EventClass | null | undefined>();
    const [selectedEventClassEntries, setSelectedEventClassEntries] = useState<EventClassEntry[] | null | undefined>();

    const formatEventClasses = async (eventClasses: EventClass[]) => {
        let formattedEventClasses: formattedEventClass[] = [];
        if (eventClasses) {
            for (var i = 0; i < eventClasses.length; i++) {
                const currentClass = eventClasses[i];
                const formattedClass: formattedEventClass = {
                    number: currentClass.number || 0,
                    name: currentClass.name,
                    type: currentClass.class?.type ? (getEventClassTypeAbbreviation(currentClass.class?.type)) : "",
                    divisionName: currentClass.eventDivision?.name || "",
                    ec: currentClass
                }
                formattedEventClasses.push(formattedClass);
            }
        }
        setFormattedEventClasses(formattedEventClasses);
        setIsLoading(false);
    }

    async function getEventClasses(event: Event) {
        setIsLoading(true);
        const queryResult = await getEventClassesByEventId(event.id);
        if (queryResult.isSuccess) {
            setEventClasses(queryResult.result);
            formatEventClasses(queryResult.result);
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (event && !formattedEventClasses) getEventClasses(event);
    }, [event]);

    const onSelectClass = async (c: EventClass) => {
        setIsLoadingSelectedClassInfo(true);
        setShowModal(true);
        setSelectedEventClass(c);
        const queryResult = await getAcceptedEventClassEntriesByEventClassId(c.id);
        if (queryResult.isSuccess) {
            const entryList = queryResult.result;
            const sorted = sortEventClassEntriesByNumber(entryList);
            setSelectedEventClassEntries(sorted || entryList);
        } else {
            setSelectedEventClassEntries([]);
        }
        setIsLoadingSelectedClassInfo(false);
    }

    const handleGenerateClassSheet = async () => {
        setIsDownloading(true);
        if (selectedEventClass) {
            await generateClassSheetPDF(selectedEventClass, event.name);
            setIsDownloading(false);
        } else {
            setIsDownloading(false);
        }
    }

    const generateClassCounts = async () => {
        if (event) {
            setIsDownloading(true);
            await generateClassCountsPDF(event);
            setIsDownloading(false);
        }
    }

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>Classes</IonCardTitle>
            <IonCardSubtitle>Click on a Class to view details.</IonCardSubtitle>
            <IonRow>
                <IonCol className="text-right" onClick={generateClassCounts}>
                    {isDownloading ?
                        <Spinner />
                        :
                        <div>
                            <p>Download Class Counts <DownloadLink /></p>
                        </div>
                    }
                </IonCol>
            </IonRow>
            <IonList className="bg-white">
                {!isPlatform("mobile") && (
                    <IonItem key="header">
                        <IonLabel>
                            <IonRow>
                                <IonCol>
                                    <p className="ion-text-wrap">Number</p>
                                </IonCol>
                                <IonCol>
                                    <p className="ion-text-wrap">Class</p>
                                </IonCol>
                                <IonCol>
                                    <p className="ion-text-wrap">Type</p>
                                </IonCol>
                                <IonCol>
                                    <p className="ion-text-wrap">Division</p>
                                </IonCol>
                            </IonRow>
                        </IonLabel>
                    </IonItem>
                )}
                {isLoading ? 
                    <Spinner />
                    :
                    <>
                        {formattedEventClasses && formattedEventClasses.length ?
                            <div>
                                {formattedEventClasses.map((formattedClass, index) => (
                                    <div key={index}>
                                        {isPlatform("mobile") ? 
                                            <IonItem onClick={() => onSelectClass(formattedClass.ec)}>
                                                <IonLabel>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p className="ion-text-wrap">{formattedClass.number} - {formattedClass.name} {formattedClass.type}</p>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonLabel>
                                            </IonItem>
                                            :
                                            <IonItem key={index} onClick={() => onSelectClass(formattedClass.ec)}>
                                                <IonLabel>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p className="ion-text-wrap">{formattedClass.number}</p>
                                                        </IonCol>
                                                        <IonCol>
                                                            <p className="ion-text-wrap">{formattedClass.name}</p>
                                                        </IonCol>
                                                        <IonCol>
                                                            <p className="ion-text-wrap">{formattedClass.type}</p>
                                                        </IonCol>
                                                        <IonCol>
                                                            <p className="ion-text-wrap">{formattedClass.divisionName}</p>
                                                        </IonCol>

                                                    </IonRow>
                                                </IonLabel>
                                            </IonItem>
                                        }
                                    </div>
                                ))}
                            </div>
                        :
                        <p>No Classes yet.</p>
                    }
                    </>
                }
            </IonList>
            <IonModal backdropDismiss={false} isOpen={showModal} id="eventClassModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        {selectedEventClass?.number} - {selectedEventClass?.name}
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10" className="ion-text-center">
                            <h2>{selectedEventClass?.name}</h2>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12" className="ion-text-center">
                            <p>{selectedEventClass?.class?.description}</p>
                        </IonCol>
                    </IonRow>
                    {isLoadingSelectedClassInfo ?
                        <Spinner />
                        :
                        <>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="12" className="ion-text-center">
                                    {isDownloading ?
                                        <Spinner />
                                        :
                                        <p className="link" onClick={handleGenerateClassSheet}>Download Class Sheet</p>
                                    }
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="12" className="ion-text-center">
                                    <p>Number of Entries: {selectedEventClassEntries?.length || 0}</p>
                                </IonCol>
                            </IonRow>
                            {(selectedEventClassEntries && selectedEventClassEntries.length > 0) && (
                                <>
                                    <IonList className="bg-white">
                                        <IonItem key="entryHeader">
                                            <IonLabel>
                                                <IonRow>
                                                    <IonCol>
                                                        <p className="ion-text-wrap">Number</p>
                                                    </IonCol>
                                                    <IonCol>
                                                        <p className="ion-text-wrap">Horse</p>
                                                    </IonCol>
                                                    <IonCol>
                                                        <p className="ion-text-wrap">Rider</p>
                                                    </IonCol>
                                                    <IonCol>
                                                        <p className="ion-text-wrap">Owner</p>
                                                    </IonCol>
                                                    <IonCol>
                                                        <p className="ion-text-wrap">Trainer</p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonLabel>
                                        </IonItem>
                                        {selectedEventClassEntries.map((entry, index) => (
                                            <IonItem key={index}>
                                                <IonLabel>
                                                    <IonRow>
                                                        <IonCol>
                                                            <p className="ion-text-wrap">{entry.eventEntry?.number}</p>
                                                        </IonCol>
                                                        <IonCol>
                                                            <p className="ion-text-wrap">{entry.eventEntry?.horseName}</p>
                                                        </IonCol>
                                                        <IonCol>
                                                            <p className="ion-text-wrap">{entry?.rider?.name || entry.eventEntry?.riderName}</p>
                                                        </IonCol>
                                                        <IonCol>
                                                            <p className="ion-text-wrap">{entry.eventEntry?.ownerName}</p>
                                                        </IonCol>
                                                        <IonCol>
                                                            <p className="ion-text-wrap">{entry.eventEntry?.trainerName}</p>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonLabel>
                                            </IonItem>
                                        ))}
                                    </IonList>
                                </>
                            )}
                        </>
                    }
                </IonContent>
            </IonModal>
        </IonCard>
    );
};

export default EventClassDisplayTable;