import { Result } from "../../interfaces/Result";

/**
 * Standardize the way utility functions return results.
 * 
 * @param {boolean} isSuccessful 
 * @param {string} type 
 * @param {string} result 
 * @param {string} message 
 */
function formatResult(isSuccessful: boolean, type: string, result: any, message: string): Result {
    return {
        isSuccess: isSuccessful,
        type: type,
        result: result,
        message: message
    };
}

export default formatResult;