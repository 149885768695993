import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event} from "../../../../../../models";
import Header from "../../../../../../components/Headers/Header";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../../utilities/events/Event";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import EventCogginsSettingsForm from "../../../../../../components/EventSettings/EventCogginsSettingsForm";
import EventAutoAcceptEntriesSettingsForm from "../../../../../../components/EventSettings/EventAutoAcceptEntriesSettingsForm";
import EventRiderSettingsForm from "../../../../../../components/EventSettings/EntryRiderSettingsForm";
import ErrorAlert from "../../../../../../components/Errors/ErrorAlert";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventEntryReqsSettingsPage: React.FC<EventPageProps> = ({match}) => {

    const [event, setEvent] = useState<Event>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getEvent();
    }, [match, match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={event ? event.name : "Settings"} />
                {event && (
                    <>
                        <IonRow>
                            {error && <ErrorAlert error={error} />}
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>Coggins Settings</IonCardTitle>
                                    <IonCardContent>
                                        {(event && event.organizationId) && (
                                            <EventCogginsSettingsForm event={event} />
                                        )}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>Entry Info Settings</IonCardTitle>
                                    <IonCardContent>
                                        {(event && event.organizationId) && (
                                            <EventRiderSettingsForm event={event} />
                                        )}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>Entry Status Settings</IonCardTitle>
                                    <IonCardContent>
                                        <EventAutoAcceptEntriesSettingsForm event={event} />
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                
            </IonContent>
        </IonPage>
    );
};

export default EventEntryReqsSettingsPage;