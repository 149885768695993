import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Horse } from "../../models";

interface _Props {
    selectedValue?: (string | null)
    horses?: (Horse[] | null)
    onSelect: Function
    isHeightRequired?: boolean
    height?: string
}

interface formattedOption {
    label: string
    value: string
    horse: Horse
}

const SelectHorse: React.FC<_Props> = ({horses, selectedValue, onSelect, isHeightRequired, height}) => {

    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [formattedHorses, setFormattedHorses] = useState<formattedOption[] | null | undefined>();

    const formatHorses = (horses: Horse[]) => {
        let formattedHorses = [];
        for (var i = 0; i < horses.length; i++) {
            const value = horses[i].id;
            const label = (horses[i].name || "error") + (horses[i].nickname ? " (" + horses[i].nickname +  ")" : "");
            let object: formattedOption = {
                value: value,
                label: label,
                horse: horses[i]
            };
            formattedHorses.push(object);
        }
        setFormattedHorses(formattedHorses);
        if (selectedValue) findValueInList(selectedValue, formattedHorses);
    }

    const findValueInList = (value: string, horseList?: formattedOption[]) => {
        let optionArray = horseList || formattedHorses;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        if (horses) formatHorses(horses);
    }, [horses]);

    useEffect(() => {
        function setValue() {
            if (selectedValue) {
                findValueInList(selectedValue);
            } else {
                setCurrentValue(undefined);
            }
        }
        setValue();
    }, [selectedValue]);

    const handleOnChange = (event?: any) => {
        if (event && event.horse) {
            setCurrentValue(event);
            onSelect(event.horse);
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <>
            <Select
                id = "select-from-horse"
                inputId="select-from-horse-input"
                styles={{
                    // Fixes the overlapping problem of the component
                    control: baseStyles =>(isHeightRequired ? {...baseStyles, height} : {...baseStyles}),
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                defaultValue={currentValue}
                value={currentValue}
                menuPortalTarget={document.body}
                isClearable
                options={formattedHorses!}
                onChange={handleOnChange}
            />
        </>
    )
};

export default SelectHorse;
