/**
 * This file contains the functionality for a layout: UnauthLayout
 * 
 * Using layouts for categories of pages creates 
 * - consistency 
 * - easy maintenance
 * 
 * The Web layout is used for the pages you do not have to be authenticated to see and will only see on a web browser:
 *  - Examples: Home, Pricing, etc...
 */
import {IonRouterOutlet} from "@ionic/react";
import {Redirect, Route, RouteComponentProps, withRouter} from "react-router-dom";
import BasicUnauthenticatedNavbar from "../components/Navbars/WebNavbar";
import {CognitoUser} from "../interfaces/CognitoUser";
import DashboardLayout from "./DashboardLayout";
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";
import LoginPage from "../pages/auth/LoginPage";
import React from "react";
import RegisterPage from "../pages/auth/RegisterPage";
import WebHomePage from "../pages/web/WebHomePage";
import {defaultUserContextValue} from "../context/PersonContext";
import {blankOrganization} from "../context/OrganizationContext";
import { Organization } from "../models";
import {getUser} from "../utilities/user/User";
import ViewShowPage from "../pages/web/ViewShowPage";
import AboutPage from "../pages/web/About";
import AuthMenu from "../components/Menus/AuthMenu";
import AboutOnlineEntriesPage from "../pages/web/AboutOnlineEntriesPage";
import AboutTextAlertsPage from "../pages/web/AboutTextAlertsPage";
import ClinicPage from "../pages/web/ClinicPage";
import AboutClinicsPage from "../pages/web/AboutClinicsPage";
import SampleFeedbackPage from "../pages/web/SampleFeedbackPage";
import CustomRegisterPage from "../pages/auth/CustomRegisterPage";
import GITNBPage from "../pages/web/GITNBPage";
import ViewOrgPage from "../pages/web/ViewOrgPage";
import OrganizationRegisterPage from "../pages/auth/OrganizationRegisterPage";
import OrganizationLoginPage from "../pages/auth/OrganizationLoginPage";
import OrganizationForgotPasswordPage from "../pages/auth/OrganizationForgotPasswordPage";
import OrganizationVerifyPage from "../pages/auth/OrganizationVerifyPage";
import ContactUsPage from "../pages/web/ContactUsPage";
import PrivacyPolicyPage from "../pages/web/PrivacyPolicyPage";

interface IProps {}

type UnauthLayoutProps = IProps & RouteComponentProps<any>;

interface IState {
    organization: Organization,
    user: CognitoUser,
    path: string
}

class UnauthLayout extends React.Component<UnauthLayoutProps, IState> {

    constructor(props: UnauthLayoutProps) {
        super(props);
        this.state = {
            organization: blankOrganization,
            user: defaultUserContextValue,
            path: "/"
        };
        this.setOrganizationHandler = this.setOrganizationHandler.bind(this)
    }

    async componentDidMount() {
        await this.checkForRoute();
        await this.checkForUser();
    }

    async componentDidUpdate(prevProps:any) {
        if (this.props !== prevProps) {
            // Check for a change in route that leads to a Dashboard (authenticated) page
            if ((this.props.location.pathname !== this.state.path) && this.props.location.pathname.includes("index")) {
                await this.checkForDashboardRoute(this.props.location.pathname);
            }

            if (!this.state.user) {
                await this.checkForUser();
            }
        }
    }

    async checkForDashboardRoute(pathName: string) {
        this.setState({path: pathName});
        await this.checkForUser();
    }

    async checkForRoute() {
        const path = window.location.pathname;
        if (path.includes("index")) {
            // probably a reload from a logged in user
            await this.setState({path});
        }
    }

    async setOrganizationHandler(organization: Organization) {
        if (organization) {
            await this.setState({organization});
        }
    }

    async checkForUser() {
        const user = await getUser();
        if (user && user.username) {
            await this.setState({user});

            // Will navigate to /index/home or /index/:orgId/home
            this.navigateToPath(this.state.path);
        }
    }

    async navigateToPath(currentPath: string) {
        await this.props.history.push(currentPath);
    }

    render() {
        return (
            <React.Fragment>
                <AuthMenu organization={this.state.organization} />
                <IonRouterOutlet id="unauth">
                    
                    <BasicUnauthenticatedNavbar />

                    <Route path="/home">
                        <WebHomePage />
                    </Route>

                    <Route path="/about">
                        <AboutPage />
                    </Route>

                    <Route path="/contact-us">
                        <ContactUsPage />
                    </Route>

                    <Route path="/privacy-policy">
                        <PrivacyPolicyPage />
                    </Route>

                    <Route path="/gitnb">
                        <GITNBPage />
                    </Route>

                    <Route path="/clinics">
                        <ClinicPage />
                    </Route>

                    <Route path="/online-entries">
                        <AboutOnlineEntriesPage />
                    </Route>

                    <Route path="/text-alerts">
                        <AboutTextAlertsPage />
                    </Route>

                    <Route path="/online-clinics">
                        <AboutClinicsPage />
                    </Route>

                    <Route path="/sample-feedback">
                        <SampleFeedbackPage />
                    </Route>

                    <Route path="/show/:id" exact render={props => <ViewShowPage {...props} />} />

                    <Route path="/alerts/:id" exact render={props => <ViewOrgPage {...props} />} />

                    <Route path="/:id/register" exact render={props => <OrganizationRegisterPage setOrganizationHandler={this.setOrganizationHandler} {...props} />} />

                    <Route path="/:id/verify-account" exact render={props => <OrganizationVerifyPage setOrganizationHandler={this.setOrganizationHandler} {...props} />} />

                    <Route path="/:id/login" exact render={props => <OrganizationLoginPage setOrganizationHandler={this.setOrganizationHandler} {...props} />} />

                    <Route path="/:id/forgot-password" exact render={props => <OrganizationForgotPasswordPage setOrganizationHandler={this.setOrganizationHandler} {...props} />} />    

                    <Route path="/register">
                        <RegisterPage />
                    </Route>

                    <Route path="/register/RHSC">
                        <CustomRegisterPage />
                    </Route>

                    <Route path="/login">
                        <LoginPage />
                    </Route>

                    <Route path="/forgot-password">
                        <ForgotPasswordPage />
                    </Route>
                    
                    {this.state.user.username ? (
                        <>
                            <DashboardLayout organization={this.state.organization} />
                        </>
                    ) : (
                        <>
                            {/* If the path includes index - then the user is trying to get to an authenticated page - so log them in */}
                            {this.state.path.includes("index") ?
                                <>
                                    <LoginPage />
                                </>
                                :
                                <>
                                    <WebHomePage />
                                    <Redirect path="/" to="/home" exact />
                                </>
                            }
                        </>
                    )}
                    
                </IonRouterOutlet>
            </React.Fragment>
        );
    }
}
  
export default withRouter(UnauthLayout);
