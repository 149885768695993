import { API } from "aws-amplify";
import { Result } from "../../interfaces/Result";

export async function sendSubmittedEntryEmail(email: string, name: string, eventName: string, eventEmail: string, horseName: string, riderName: string, trainerName: string, pdf: Blob) {
    try {
        
        let data = "";
        var reader = new FileReader();
        reader.readAsDataURL(pdf); 
        reader.onloadend = async function() {
            var base64data = reader.result;                
            data = base64data as string;

            const queryData = {
                body: {
                    "email": email,
                    "name": name,
                    "eventName": eventName,
                    "showEmail": eventEmail,
                    "horseName": horseName,
                    "riderName": riderName,
                    "trainerName": trainerName,
                    "attachment": data
                }
            }

            const path = "/emails/entry/submitted";
            await API.post("Emails", path, queryData);
        }
        
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Email", result: error, message: "Error occurred while trying to create a new email." }
        return result;
    }
}

export async function sendDeclinedEntryEmail(email: string, name: string, eventName: string, reason: string, eventEmail: string, horseName: string, riderName: string, trainerName: string) {
    try {
        const queryData = {
            body: {
                "email": email,
                "name": name,
                "eventName": eventName,
                "showEmail": eventEmail,
                "horseName": horseName,
                "riderName": riderName,
                "trainerName": trainerName,
                "reason": reason
            }
        }

        const path = "/emails/entry/declined";
        const apiResult = API.post("Emails", path, queryData);

        let result: Result = {isSuccess: true, type: "Email", result: apiResult, message: "Sent the email." }
        return result;
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Email", result: error, message: "Error occurred while trying to create a new email." }
        return result;
    }
}