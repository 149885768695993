import {
    IonCard,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
} from "@ionic/react";
import React from "react";
import { Page } from "../../interfaces/Page";

interface _Props {
    listOptions: Page[]
    id?: string
    includeId?: boolean
}

const PageList: React.FC<_Props> = ({listOptions, id, includeId}) => {
    return (
        <IonCard className="bg-white ion-padding">
            <IonList className="bg-white">
                {listOptions.map((page: Page, index: number) => (
                    <IonItem 
                        key={index} 
                        detail={true}
                        routerLink={includeId ? page.path + "/" + id : page.path} 
                        routerDirection="none"
                    >
                        <IonIcon icon={page.icon} slot="start" />
                        <IonLabel>
                            <h2>{page.title}</h2>
                            <p>{page.description}</p>
                        </IonLabel>
                    </IonItem>
                ))}
            </IonList>
        </IonCard>
    );
};

export default PageList;