import React, { useEffect, useState } from "react";
import { Event } from "../../models";
import { formatStartEndDatesStrings } from "../../utilities/dates/FormatDates";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    menuPlacement?: (string | null)
    selectedValue?: (string | null)
    events?: (Event[] | null)
    onSelect: Function
}

const SelectEvent: React.FC<_Props> = ({selectedValue, events, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedEvents, setFormattedEvents] = useState<formattedOption[] | null | undefined>();

    const formatEvents = (events: Event[]) => {
        let formattedEvents = [];
        for (var i = 0; i < events.length; i++) {
            const event = events[i];
            const value = event.id;
            const label = (event.name || "error") + (event.startDate ? " (" + formatStartEndDatesStrings(event.startDate, event.endDate) +  ")" : "");
            let object: formattedOption = {
                value: value,
                label: label,
                object: events[i]
            };
            formattedEvents.push(object);
        }
        setFormattedEvents(formattedEvents);
    }

    useEffect(() => {
        if (events) formatEvents(events);
    }, [events]);

    useEffect(() => {
        if (selectedValue) setSelected(selectedValue)
    }, [selectedValue]);

    const handleOnChange = (event?: any) => {
        if (event) {
            onSelect(event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedEvents ?
                <BasicSelect formattedValues={formattedEvents} menuPlacement={menuPlacement} selectedValue={selected} onSelect={handleOnChange} />
                :
                <p>Loading options...</p>
            }
        </>
    )
};

export default SelectEvent;
