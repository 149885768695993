import React from "react";
import {
    IonChip
} from "@ionic/react";
import "../../theme/cards.css";

interface EntryCardChipProps {
    chipTitle: ChipTitleOption;
}

export type ChipTitleOption = "Event Info" | "Not Started" | "Incomplete" | "Completed";

const EntryCardChip: React.FC<EntryCardChipProps> = ({
    chipTitle,
}) => {
    const chipColor = ((chipTitle === "Event Info") ? "info" : ((chipTitle === "Completed") ? "success" : "danger"));
    return (
        <IonChip className={"display-4.5 rounded-2 font-weight-700 text-white px-3 m-0 bg-" + chipColor}>{chipTitle}</IonChip>
    );
};

export default EntryCardChip;