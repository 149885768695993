/**
 * Allow the user to connect to: USEF, USHJA or EC
 * 
 * USEF: United States Equestrian Federation
 * - Allows a user to sync their data to USEF sections to report results
 * USHJA: United State Hunter Jumper Association
 * - Allows a user to sync their data to USHJA Outreach sections to report results
 * EC: Equestrian Canada 
 * - Allows a user to sync their data to EC sections to report results
 */

import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { EventDivision } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { getDivisionById, updateDivision } from "../../utilities/division/Division";
import SuccessBanner from "../Banners/SuccessBanner";
import SelectECClass from "../Class/SelectECClass";
import SelectECClassType from "../Class/SelectECClassType";
import { ECClass, ECClassType } from "../../interfaces/ECData";
import { USEFSection } from "../../interfaces/USEFData";
import SelectUSEFClass from "../Class/SelectUSEFClass";
import { UpdateDivisionInput } from "../../API";
import SelectUSHJAOutreachClass from "../Class/SelectUSHJAOutreachClass";
import { USHJAOutreachSection } from "../../data/content/USHJAOutreachSections";

interface _Props {
    eventDivision?: (EventDivision | null)
    onSubmit: Function
}

const EventDivisionAffiliationForm: React.FC<_Props> = ({eventDivision, onSubmit}) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    const [selectedValue, setSelectedValue] = useState<"custom" | "ec" | "usef" | "ushja">("custom");
    const [ecClassNumber, setECClassNumber] = useState<number | null | undefined>();
    const [ecName, setECName] = useState("");
    const [ecClassTypeName, setECClassTypeName] = useState("");
    const [ecClassTypeNumber, setECClassTypeNumber] = useState<number | null | undefined>();
    const [usefClassNumber, setUSEFClassNumber] = useState<number | null | undefined>();
    const [usefName, setUSEFName] = useState("");
    const [ushjaName, setUSHJAName] = useState("");
   
    const getDivision = async (divisionId: string) => {
        const queryResult = await getDivisionById(divisionId);
        if (queryResult.isSuccess) {
            const division = queryResult.result;
            if (division && division.ecNumber) {
                setSelectedValue("ec");
                setECClassNumber(parseInt(division.ecNumber));
                if (division.ecClassTypeNumber) setECClassTypeNumber(parseInt(division.ecClassTypeNumber));
                if (division.ecClassType) setECClassTypeName(division.ecClassType);
            } else {
                setECClassNumber(undefined);
                setECClassTypeNumber(undefined);
                setECClassTypeName("");
            }
            if (division && division.usefNumber) {
                setSelectedValue("usef");
                setUSEFClassNumber(parseInt(division.usefNumber));
            } else {
                setUSEFClassNumber(undefined);
            }
            if (division && division.ushjaName) {
                setSelectedValue("ushja");
                setUSHJAName(division.ushjaName);
            } else {
                setUSHJAName("");
            }
            return queryResult.result;
        } else {
            return null;
        }
    }

    useEffect(() => {
        if (eventDivision) {
            getDivision(eventDivision.divisionId);
            setSuccess("");
            setError("");
        }
    }, [eventDivision]);

    const clearForm = () => {
        setECName("");
        setECClassTypeName("");
        setECClassNumber(undefined);
        setECClassTypeNumber(undefined);
        setUSEFName("");
        setUSEFClassNumber(undefined);
        setUSHJAName("");
        setError("");
    } 

    const handleSelectedECClass = (data: ECClass) => {
        setIsDisabled(false);
        const name = data.ClassDescription;
        setECName(name);
        setECClassNumber(data.ClassID);
    }

    const handleSelectedECClassType = (data: ECClassType) => {
        setIsDisabled(false);
        setECClassTypeName(data.ClassTypeDescription);
        setECClassTypeNumber(data.ClassTypeID);
    }

    const handleSelectedUSEFDivision = (data: USEFSection) => {
        setIsDisabled(false);
        const name = data.SectionName;
        setUSEFName(name);
        setUSEFClassNumber(data.SectionCd);
    }

    const handleSelectedUSHJASection = (data?: USHJAOutreachSection) => {
        setIsDisabled(false);
        const name = data ? data.name : "";
        setUSHJAName(name);
    }

    const handleEditDivision = async () => {
        try {
            if (eventDivision?.divisionId) {
                const input: UpdateDivisionInput = {
                    id: eventDivision?.divisionId,
                    ushjaName: ushjaName,
                    usefNumber: usefClassNumber ? usefClassNumber.toString() : "",
                    ecNumber: ecClassNumber ? ecClassNumber?.toString() : "",
                    ecClassTypeNumber: ecClassTypeNumber ? ecClassTypeNumber.toString() : "",
                    ecClassType: ecClassTypeName
                };
                const updateResult = await updateDivision(input);
                if (updateResult.isSuccess) {
                    setSuccess("Successfully updated the division.");
                } else {
                    setError("Could not update the data.");
                }
            } else {
                setError("No division to update.");
            }
        } catch (error: any) {
            setError("Sorry, an error occurred.");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setSuccess("");
        setError("");
        await handleEditDivision();
        onSubmit(selectedValue);
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            <form>
                {/* TO DO - Add back in the ability to check for USEF Classes */}
                {/* <IonRow>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "custom" ? "primary" : "light"} onClick={() => {setSelectedValue("custom"); clearForm();}}>Custom</IonButton>
                    </IonCol>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "ec" ? "primary" : "light"} onClick={() => {setSelectedValue("ec"); clearForm();}}>EC</IonButton>
                    </IonCol>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "usef" ? "primary" : "light"} onClick={() => {setSelectedValue("usef"); clearForm();}}>USEF</IonButton>
                    </IonCol>
                    <IonCol sizeXs="6" sizeMd="3" className="ion-text-center">
                        <IonButton color={selectedValue === "ushja" ? "primary" : "light"} onClick={() => {setSelectedValue("ushja"); }}>USHJA</IonButton>
                    </IonCol>
                </IonRow> */}
                <IonRow>
                    <IonCol>
                        <IonCard className="bg-light">
                            <IonCardContent>
                            {selectedValue === "ec" && (
                                <>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <p>Select from the list of recognized EC options.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectECClass selectedValue={ecClassNumber?.toString() || ""} onSelect={handleSelectedECClass} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">EC Number</IonLabel>
                                                <IonInput 
                                                    type="number"
                                                    value={ecClassNumber}
                                                    disabled={true}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectECClassType selectedValue={ecClassTypeNumber?.toString() || ""} onSelect={handleSelectedECClassType} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">EC Class Type ID</IonLabel>
                                                <IonInput 
                                                    type="number"
                                                    value={ecClassTypeNumber}
                                                    disabled={true}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {selectedValue === "usef" && (
                                <>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <p>Select from the list of recognized USEF options.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectUSEFClass onSelect={handleSelectedUSEFDivision} />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem color="white">
                                                <IonLabel position="stacked">USEF Number</IonLabel>
                                                <IonInput 
                                                    type="number"
                                                    value={usefClassNumber}
                                                    disabled={true}
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {selectedValue === "ushja" && (
                                <>
                                    <IonRow className="ion-padding-top">
                                        <IonCol>
                                            <p>Select from the list of USHJA Outreach options.</p>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <SelectUSHJAOutreachClass selectedValue={ushjaName} onSelect={handleSelectedUSHJASection} />
                                        </IonCol>
                                    </IonRow>
                                </>
                            )}
                            {selectedValue === "custom" && (
                                <IonRow className="ion-padding-top">
                                    <IonCol>
                                        {/* <p>Create your own division. We will not report its results to EC or USEF.</p> */}
                                        <p>Create your own division.</p>
                                    </IonCol>
                                </IonRow>
                            )}
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeMd="4" className="ion-text-center">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default EventDivisionAffiliationForm;