import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import { Barn } from "../../../../models";
import { getBarnById } from "../../../../utilities/barn/Barn";
import EditHorsesTable from "../../../../components/Horse/EditHorsesTable";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";

interface BarnPageProps extends RouteComponentProps<{
    id: string;
}> {}

const BarnHorsesPage: React.FC<BarnPageProps> = ({match}) => {
    const [barn, setBarn] = useState<Barn>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getBarn() {
            const queryResult = await getBarnById(match.params.id);
            if (queryResult.isSuccess) {
                setBarn(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        if (!barn) {
            getBarn();
        }
    }, [match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={barn ? barn.name : "Barn"} />
                {error && <ErrorAlert width="12" error={error} />}
                <IonRow className="ion-justify-content-center">
                    <IonCol size="12">
                        <EditHorsesTable barn={barn} />
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default BarnHorsesPage;