import "./../../theme/main.css";
import {
    IonCol,
    IonRow,
    IonSpinner
} from "@ionic/react";
import React from "react";

const Spinner: React.FC = () => {

    return (
        <IonRow class="ion-justify-content-center ion-padding-bottom">
            <IonCol sizeMd="6">
                <IonSpinner color="tertiary" />
            </IonCol>
        </IonRow>
    );
};

export default Spinner;
