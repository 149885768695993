import {
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    isPlatform,
} from "@ionic/react";
import moment from "moment";
import React, {useEffect, useState} from "react";
import { Table } from "reactstrap";
import { EventFilter } from "../../../API";
import { Event, OrganizationAuditor, EventAuditorFilter, Organization } from "../../../models";
import { getOrganizationAuditorsWithFilterByOrganizationId } from "../../../utilities/organizationAuditor/OrganizationAuditor";
import { sortOrganizationAuditorsByName } from "../../../utilities/organizationAuditor/SortOrganizationAuditors";
import { getEventAuditorFiltersByOrganizationAuditorId } from "../../../utilities/eventAuditorFilter/EventAuditorFilter";
import { getEventFiltersByEventId, getEventFiltersByOrganizationId } from "../../../utilities/eventFilter/EventFilter";
import { isWindows } from "../../../utilities/platform/Platform";
import ErrorAlert from "../../Errors/ErrorAlert";
// import OrganizationAuditorModal from "../../OrganizationAuditor/OrganizationAuditorModal";
import Spinner from "../../Spinners/Spinner";
import OrganizationAuditorModal from "../../OrganizationAuditor/OrganizationAuditorModal";

interface _Props {
    organization: Organization
    organizationAuditor?: OrganizationAuditor
}

const EditOrganizationAuditorsFullTable: React.FC<_Props> = ({organization, organizationAuditor}) => {    

    const [organizationAuditors, setOrganizationAuditors] = useState<OrganizationAuditor[] | null | undefined>();
    const [selectedOrganizationAuditor, setSelectedOrganizationAuditor] = useState<OrganizationAuditor | null | undefined>();
    const [eventFilters, setEventFilters] = useState<EventFilter[] | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    async function getOrganizationAuditors(organization: Organization, eventFilters: EventFilter[]) {
        setIsLoading(true);
        const queryResult = await getOrganizationAuditorsWithFilterByOrganizationId(organization.id);
        if (queryResult.isSuccess) {
            let organizationAuditors: OrganizationAuditor[] = sortOrganizationAuditorsByName(queryResult.result) || queryResult.result;
            let updatedAuditors: OrganizationAuditor[] = [];
            if (organizationAuditors && organizationAuditors.length > 0) {
                for (let i = 0; i < organizationAuditors.length; i++) {
                    const currentOrganizationAuditor = organizationAuditors[i];
                    const filterResult: any = currentOrganizationAuditor.filters;
                    const filters = filterResult?.items;
                    if (eventFilters) {
                        if (filters && filters.length) {
                            let sorted: EventAuditorFilter[] = [];
                            for (let j = 0; j < eventFilters.length; j++) {
                                const eventFilter = eventFilters[j];
                                for (let k = 0; k < filters.length; k++) {
                                    const currentFilter = filters[k];
                                    if (currentFilter && currentFilter.filterId === eventFilter.id) {
                                        sorted.push(currentFilter);
                                    }
                                }
                            }
                            const updatedOrganizationAuditor = {
                                ...currentOrganizationAuditor,
                                filters: sorted
                            };
                            updatedAuditors.push(updatedOrganizationAuditor);
                        } else {
                            updatedAuditors.push(currentOrganizationAuditor);
                        }
                    }  else {
                        updatedAuditors.push(currentOrganizationAuditor);
                    }
                }
            }
            setOrganizationAuditors(updatedAuditors);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    async function getEventFilters(organization: Organization) {
        const queryResult = await getEventFiltersByOrganizationId(organization.id);
        if (queryResult.isSuccess) {
            const orgFilters: EventFilter[] = queryResult.result;
            const sorted = orgFilters.sort((a, b) => (a.name || "").localeCompare((b.name || "")));
            setEventFilters(sorted);
            getOrganizationAuditors(organization, sorted);
        }
    }

    useEffect(() => {
        if (organization) {
            getEventFilters(organization);
        }
    }, [organization]);

    useEffect(() => {
        if (organizationAuditor) {
            if (organizationAuditors) {
                const updated = organizationAuditors.concat(organizationAuditor);
                const sorted = updated.sort((a, b) => (a.name || "").localeCompare((b.name || "")));
                setOrganizationAuditors(sorted);
            } else {
                setOrganizationAuditors([organizationAuditor]);
            }
        }
    }, [organizationAuditor]);

    const handleSelectedSubscriber = (organizationAuditor: OrganizationAuditor) => {
        setSelectedOrganizationAuditor(organizationAuditor);
        setShowModal(true);
    }

    const handleOrganizationAuditorChange = async (action: string, organizationAuditor: OrganizationAuditor) => {
        setIsLoading(true);
        setShowModal(false);
        let updated: OrganizationAuditor[] = [organizationAuditor];
        if (action === "create") {
            if (organizationAuditors) updated = updated.concat(organizationAuditors);
        } else if (action === "update") {
            if (organizationAuditors) {
                let updatedOrganizationAuditor: OrganizationAuditor = organizationAuditor;
                if (eventFilters) {
                    const filterQueryResult = await getEventAuditorFiltersByOrganizationAuditorId(organizationAuditor.id);
                    if (filterQueryResult.isSuccess) {
                        const filters: EventAuditorFilter[] = filterQueryResult.result;
                        let sorted: EventAuditorFilter[] = [];
                        for (let j = 0; j < eventFilters.length; j++) {
                            const eventFilter = eventFilters[j];
                            const findResult = filters.find(eventAuditorFilter => eventAuditorFilter.filterId === eventFilter.id);
                            if (findResult) {
                                sorted.push(findResult);
                            } else {
                                const blankEventAuditorFilter: EventAuditorFilter = {
                                    id: "1",
                                    options: [],
                                    createdOn: moment(new Date()).format("MM/DD/YY"),
                                    updatedOn: moment(new Date()).format("MM/DD/YY")
                                };
                                sorted.push(blankEventAuditorFilter);
                            }
                        }
                        updatedOrganizationAuditor = {
                            ...organizationAuditor,
                            filters: sorted
                        };
                        
                    }
                }
                const index = organizationAuditors.findIndex(auditor => auditor.id === organizationAuditor.id);
                updated = [
                    ...organizationAuditors.slice(0, index),
                    updatedOrganizationAuditor,
                    ...organizationAuditors.slice(index + 1)
                ];
            } 
        } else if (action === "delete") {
            if (organizationAuditors) {
                const deletedIndex = organizationAuditors.findIndex(eA => organizationAuditor.id === eA.id);
                updated = [
                    ...organizationAuditors.slice(0, deletedIndex),
                    ...organizationAuditors.slice(deletedIndex + 1)
                ];
            }
        }
        setOrganizationAuditors(updated);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ?
                <Spinner />
                :
                <>
                    {organizationAuditors ? 
                        <>
                            {(isWindows() || (isPlatform("desktop"))) ?
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Count</th>
                                            <th>Name</th>
                                            <th>Phone Number</th>
                                            <th>Status</th>
                                            {eventFilters?.map((eventFilter, index) => (
                                                <th key={index}>{eventFilter?.name}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {organizationAuditors.map((organizationAuditor, index) => (
                                            <tr key={index} onClick={() => handleSelectedSubscriber(organizationAuditor)}>
                                                <td>
                                                    {index+1}
                                                </td>
                                                <td>
                                                    {organizationAuditor.name}
                                                </td>
                                                <td>
                                                    {organizationAuditor.phoneNumber}
                                                </td>
                                                <td>
                                                    <span className={(organizationAuditor.status === "paused" || organizationAuditor.status === "stopped") ? "text-danger" : "text-primary"}>{organizationAuditor.status === "paused" ? "Paused" : organizationAuditor.status === "stopped" ? "Stopped" : "Active"}</span>
                                                </td>
                                                {(organizationAuditor.filters && organizationAuditor.filters.length > 0) ? 
                                                    (organizationAuditor.filters?.map((eventAuditorFilter, i) => (
                                                        <td key={i}>
                                                            {(eventAuditorFilter?.options && eventAuditorFilter.options.length > 0) ? 
                                                                <>
                                                                    {eventAuditorFilter?.options?.map((option, j) => (
                                                                        <IonRow key={j}>
                                                                            {option}
                                                                        </IonRow>
                                                                    ))}
                                                                </>
                                                                :
                                                                <p>none</p>
                                                            }   
                                                        </td>
                                                    )))
                                                    :
                                                    <>
                                                        {eventFilters?.map((eventFilter, index) => (
                                                            <th key={index}>*none</th>
                                                        ))}
                                                    </>
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            :
                                <IonList className="bg-white">
                                    {organizationAuditors.map((organizationAuditor, index) => (
                                        <IonItem key={index} onClick={() => handleSelectedSubscriber(organizationAuditor)}>
                                            <IonLabel>
                                                <IonRow>
                                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-wrap font-weight-bold"><h2>{index+1 + ") " + organizationAuditor.name}</h2></IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-wrap"><p>{organizationAuditor.phoneNumber}</p></IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-wrap">
                                                        <p>
                                                            Status: <span className={(organizationAuditor.status === "paused" || organizationAuditor.status === "stopped") ? "text-danger" : "text-primary"}>{organizationAuditor.status === "paused" ? "Paused" : organizationAuditor.status === "stopped" ? "Stopped" : "Active"}</span>
                                                        </p>
                                                    </IonCol>
                                                    {(organizationAuditor.filters && organizationAuditor.filters.length > 0) ? (organizationAuditor.filters?.map((eventAuditorFilter, i) => (
                                                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-wrap" key={i}>
                                                            <p><span className="font-weight-bold">{eventAuditorFilter?.filter?.name}</span>: {eventAuditorFilter?.options?.toString()}</p>
                                                        </IonCol>
                                                    )))
                                                        :
                                                        <p></p>
                                                    }
                                                </IonRow>
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                </IonList>
                            }
                        </>
                        
                        :
                        <p>No subscribers yet.</p>
                    }
                </>
            }
            <OrganizationAuditorModal show={showModal} organizationAuditor={selectedOrganizationAuditor} isAdminView={true} organization={organization} onChange={(action: string, organizationAuditor: OrganizationAuditor) => handleOrganizationAuditorChange(action, organizationAuditor)} />
        </>
    );
};

export default EditOrganizationAuditorsFullTable;
