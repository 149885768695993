import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Spinner from "../../Spinners/Spinner";
import { Event, EventFilter, Organization } from "../../../models";
import ErrorAlert from "../../Errors/ErrorAlert";
import { deleteEventFilter, getEventFiltersByEventId, getEventFiltersByOrganizationId } from "../../../utilities/eventFilter/EventFilter";
import { close } from "ionicons/icons";
import InformationBanner from "../../Banners/InformationBanner";
import EventFilterForm from "../../EventFilter/EventFilterForm";

interface _Props {
    event?: Event
    organization?: Organization
}

const EventFiltersForm: React.FC<_Props> = ({event, organization}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [eventFilters, setEventFilters] = useState<EventFilter[] | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEventFilters(event: Event) {
            const queryResult = await getEventFiltersByEventId(event.id);
            if (queryResult.isSuccess) {
                setEventFilters(queryResult.result);
            }
        }

        if (event) getEventFilters(event);
    }, [event]);

    useEffect(() => {
        async function getOrganizationFilters(organization: Organization) {
            const queryResult = await getEventFiltersByOrganizationId(organization.id);
            if (queryResult.isSuccess) {
                setEventFilters(queryResult.result);
            }
        }

        if (organization) getOrganizationFilters(organization);
    }, [organization]);

    const handleSubmit = async (newFilter: EventFilter) => {
        setIsLoading(true);
        setError("");
        let filterArray: EventFilter[] = [];
        if (eventFilters && eventFilters.length) {
            filterArray = eventFilters.concat(newFilter);
        } else {
            filterArray.push(newFilter);
        }
        setEventFilters(filterArray);
        setIsLoading(false);
        setShowModal(false);
    }

    const handleDelete = async (eventFilter?: EventFilter) => {
        if (eventFilter) {
            const deleteResult = await deleteEventFilter({id: eventFilter.id});
            if (deleteResult.isSuccess) {
                // Remove the event filter from the current event filter array
                let newFilterArray = [];
                if (event && eventFilters && eventFilters.length) {
                    for (var i = 0; i < eventFilters?.length; i++) {
                        const current = eventFilters[i];
                        if (current && eventFilter) {
                            if (current.name !== eventFilter.name) {
                                newFilterArray.push(current);
                            }
                        }
                    }
                    setEventFilters(newFilterArray);
                }
            } else {
                setError("Could not delete the event filter.");
            }
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {eventFilters && eventFilters.length ?
                <>
                    <IonRow>
                        <IonCol>
                            <IonList className="bg-white">
                                {eventFilters.map((filter, index) => (
                                    <IonItem key={index}>
                                        <IonLabel>
                                            <IonRow>
                                                <IonCol sizeXs="12" sizeMd="6">
                                                    <h3 className="ion-text-wrap text-darker">{filter && filter.name}</h3>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="6">
                                                    {filter && filter.options && (
                                                        <h4 className="ion-text-wrap text-darker">{filter.options.map((option, index) => (<span key={index}>{option}{((index+1) !== filter.options?.length) && ", " }</span>))}</h4>
                                                    )}
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                        {!filter.isRequired && (
                                            <IonButton slot="end" color="danger" onClick={() => handleDelete(filter as EventFilter)}><IonIcon icon={close}/></IonButton>
                                        )}
                                    </IonItem>
                                ))}
                            </IonList>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                Add Filter
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                Add Filter
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
            <IonModal backdropDismiss={false} isOpen={showModal} id="addEventFilterModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Filter
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeFilterModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            <InformationBanner info="Example: Name = Rings. Options = Main Ring, Hilltop Ring, Pony Ring." />
                            <EventFilterForm onSubmit={handleSubmit} event={event} organization={organization} />
                        </>
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventFiltersForm;