export interface ECField{
    order: number
    name: string
    type: string
    description: string
    isMandatory: boolean
    value?: any
    optionalValues?: any[]
}

export const ecFields: ECField[] = [
    {
        order: 1,
        name: "ShowNumber",
        type: "Long Integer",
        description: "EC supplied competition number",
        isMandatory: true,
    },
    {
        order: 2,
        name: "ShowJulian",
        type: "Long Integer",
        description: "Julian Date for competition start date",
        isMandatory: true,
    },
    {
        order: 3,
        name: "DisciplineCode",
        type: "Text",
        description: "DR, EV, HJ or PE",
        isMandatory: true,
        value: "HJ",
        optionalValues: ["BR", "DR", "EV", "HJ", "PE"]
    },
    {
        order: 4,
        name: "ClassID",
        type: "Long Integer",
        description: "Example 10010107",
        isMandatory: true,
    },
    {
        order: 5,
        name: "Category",
        type: "Text",
        description: "will be derived from EC tables",
        isMandatory: false,
    },
    {
        order: 6,
        name: "ClassTypeID",
        type: "Long Integer",
        description: "Example 12",
        isMandatory: true,
        optionalValues: [12, 13, 17, 14, 7, 16, 15, 9, 43, 45, 10, 11]
    },
    {
        order: 7,
        name: "ClassType",
        type: "Text",
        description: "Example JUNIOR",
        isMandatory: false,
        optionalValues: ["JUNIOR", "AMATEUR", "JUNIOR/AMATEUR", "OPEN", "CHILD", "YOUNG RIDER", "SENIOR", "NON-PRO", "HACK", "FEI CHILD", "FEI PONY CHILD", "FEI JUNIOR"]
    },
    {
        order: 8,
        name: "SanctionLevel",
        type: "Text",
        description: "Example Gold",
        isMandatory: false,
        optionalValues: ["Gold", "Platinum", "Silver", "Bronze"]
    },
    {
        order: 9,
        name: "SanctionLevelID",
        type: "Long Integer",
        description: "Example 1",
        isMandatory: true,
        optionalValues: [1, 2, 3, 4]
    },
    {
        order: 10,
        name: "Province",
        type: "Text",
        description: "Example AB",
        isMandatory: true,
        optionalValues: ["AB", "BC", "NU", "MB", "NB", "NF", "NS", "NT", "ON", "PE", "QC", "SK", "YT"]
    },
    {
        order: 11,
        name: "Year",
        type: "Integer",
        description: "4 digit year of competition",
        isMandatory: true
    },
    {
        order: 12,
        name: "ShowInstanceName",
        type: "Text",
        description: "Competition Name",
        isMandatory: true
    },
    {
        order: 13,
        name: "ShowInstanceStartDate",
        type: "Date",
        description: "Competition Start Date (MM/DD/YYYY format)",
        isMandatory: true
    },
    {
        order: 14,
        name: "ClientShowInstanceClassName",
        type: "Text",
        description: "Competition’s Class name",
        isMandatory: true
    },
    {
        order: 15,
        name: "ClientShowInstanceClassNumber",
        type: "Text",
        description: "Competition’s Class number",
        isMandatory: true
    },
    {
        order: 16,
        name: "ClassDate",
        type: "Text",
        description: "Date of the Class (MM/DD/YYYY format)",
        isMandatory: false
    },
    {
        order: 17,
        name: "HeadEntryNumber",
        type: "Text",
        description: "Entry Number",
        isMandatory: true
    },
    {
        order: 18,
        name: "FirstName",
        type: "Text",
        description: "Rider’s First Name",
        isMandatory: true
    },
    {
        order: 19,
        name: "LastName",
        type: "Text",
        description: "Rider’s Last Name",
        isMandatory: true
    },
    {
        order: 20,
        name: "FullName",
        type: "Text",
        description: "Rider’s Full Name",
        isMandatory: false
    },
    {
        order: 21,
        name: "SportLicenseNumber",
        type: "Long Integer",
        description: "EC SportLicenseNumber for the Rider",
        isMandatory: true
    },
    {
        order: 22,
        name: "MemberNumber",
        type: "Long Integer",
        description: "EC MemberNumber (Same as SportLicenseNumber)",
        isMandatory: false
    },
    {
        order: 23,
        name: "HorseName",
        type: "Text",
        description: "Horse's Name",
        isMandatory: true
    },
    {
        order: 24,
        name: "PassportNumber",
        type: "Text",
        description: "Horse’s Horse Recording/Passport Number",
        isMandatory: true
    },
    {
        order: 25,
        name: "HorseLifetimeNumber",
        type: "Text",
        description: "Horse’s EC LifeTimeNumber – Required if a Passported Horse. Note: this field is available in the web SOAP service call",
        isMandatory: true
    },
    {
        order: 26,
        name: "Owners",
        type: "Text",
        description: "Owners’ Name(s) (FirstName LastName with a comma between owners)",
        isMandatory: true
    },
    {
        order: 27,
        name: "OwnersSLN",
        type: "Text",
        description: "Owners’ SportLicenseNumbers (Same order as above with comma between numbers)",
        isMandatory: true
    },
    {
        order: 28,
        name: "Starts",
        type: "Integer",
        description: "Number of starters",
        isMandatory: true
    },
    {
        order: 29,
        name: "Entries",
        type: "Integer",
        description: "Number of entries",
        isMandatory: true
    },
    {
        order: 30,
        name: "StatusID",
        type: "Long Integer",
        description: "EC Status ID",
        isMandatory: false,
        optionalValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    },
    {
        order: 31,
        name: "StatusCode",
        type: "Text",
        description: "EC Status Code",
        isMandatory: true,
        optionalValues: ["C", "S", "E", "R", "W", "CN", "MR", "DQ", "E-DR", "E-RF", "E-HF", "E-TE"]
    },
    {
        order: 32,
        name: "Placing",
        type: "Integer",
        description: "Placing",
        isMandatory: true
    },
    {
        order: 32,
        name: "PrizeMoneyWon",
        type: "Double",
        description: "Prize Money Won - please include 0 otherwise",
        isMandatory: true
    },
]