import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { checkmark, close } from "ionicons/icons";
import { getPendingInvitationsByEmail, updateInvitation } from "../../utilities/invitation/Invitation";
import { getUser, updateUserRoles } from "../../utilities/user/User";
import ErrorAlert from "../Errors/ErrorAlert";
import { Invitation } from "../../models";
import { PersonContext } from "../../context/PersonContext";
import { addUserToOrgGroup } from "../../utilities/adminQueries/userGroups";
import { getOrganizationById } from "../../utilities/organization/Organization";
import { updatePerson } from "../../utilities/person/Person";
import Spinner from "../Spinners/Spinner";
import { InvitationStatus, UpdateInvitationInput, UpdatePersonInput } from "../../API";

interface FormattedInvitation {
    invitation: Invitation
    organizationName: string
}

const InvitationsCard: React.FC = () => {
    const user = useContext(PersonContext);

    const [formattedInvitations, setFormattedInvitations] = useState<FormattedInvitation[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        async function getInvitations() {
            const queryResult = await getPendingInvitationsByEmail(user.email);
            if (queryResult.isSuccess) {
                const invitations: Invitation[] = queryResult.result;
                setFormattedInvitations(await formatInvitations(invitations))
            } 
        }

        getInvitations();
    }, [user]);

    const formatInvitations = async (invitations: Invitation[]): Promise<FormattedInvitation[]> => {
        let result: FormattedInvitation[] = [];
        for (var i = 0; i < invitations.length; i++) {
            const currentInvitation = invitations[i];
            if (currentInvitation.organizationId) {
                const organizationNameResult = await getOrganizationById(currentInvitation.organizationId)
                if (organizationNameResult.isSuccess) {
                    let displayObject = {
                        invitation: currentInvitation,
                        organizationName: organizationNameResult.result.name
                    };
                    result.push(displayObject);
                }
            }
        }
        return result;
    }

    const handleAccept = async (formattedInvitation: FormattedInvitation) => {
        try {
            setIsLoading(true);

            // First, add user to the correct User Group (name = organization ID)
            const userResult = await getUser();
            if (userResult && formattedInvitation.invitation && formattedInvitation.invitation.organizationId) {
                const addUserToGroupResult = await addUserToOrgGroup(userResult, formattedInvitation.invitation.organizationId);
                if (!addUserToGroupResult) {
                    setError("Sorry, we were not able to add you to this organization's group.");
                    return;
                }
            }

            // Next, make sure user has the correct role to access organization (event, barn, etc)
            const personRoles = user.roles;
            if (!personRoles || (formattedInvitation && formattedInvitation.invitation.roles && !personRoles?.includes(formattedInvitation.invitation.roles))) { //TO DO: make sure to add 
                // add the formattedInvitation roles to the person
                if (formattedInvitation.invitation.roles) {
                    const personInput: UpdatePersonInput = {
                        id: user.id,
                        roles: formattedInvitation.invitation.roles
                    }
                    const updateResult = await updatePerson(personInput);
                    if (!updateResult.isSuccess) {
                        setError("Sorry, we could not set you up with the roles for this organization.");
                        return;
                    }
                    const newRoleString = user?.roles + formattedInvitation.invitation.roles + ";";
                    await updateUserRoles(newRoleString);
                } else {
                    setError("This formattedInvitation had a problem. Contact us at hello@ringsidepro.com");
                }
            }

            // Finally, mark the formattedInvitation as "accepted"
            const input: UpdateInvitationInput = {
                id: formattedInvitation.invitation.id,
                status: InvitationStatus.accepted
            }
            await updateInvitation(input);

            // Then remove it from the list
            let newFormattedInvitations = [];
            if (formattedInvitations && formattedInvitations.length) {
                for (var i = 0; i < formattedInvitations.length; i++) {
                    const currentFormattedInvitation = formattedInvitations[i];
                    if (currentFormattedInvitation.invitation.id !== formattedInvitation.invitation.id) {
                        newFormattedInvitations.push(currentFormattedInvitation);
                    }
                }
            }
            setFormattedInvitations(newFormattedInvitations);
            setSuccessMessage("Success! You accepted the invitation.");
            setIsLoading(false);
        } catch (error: any) {
            setError("Sorry, a problem has occurred. If it continues, contact hello@ringsidepro.com.");
            console.error(error);
        }
    }

    const handleDecline = async (formattedInvitation: FormattedInvitation) => {
        try {
            const input: UpdateInvitationInput = {
                id: formattedInvitation.invitation.id,
                status: InvitationStatus.declined
            }
            const result = await updateInvitation(input);
            if (result.isSuccess) {
                // Then remove it from the list
                let newFormattedInvitations = [];
                if (formattedInvitations && formattedInvitations.length) {
                    for (var i = 0; i < formattedInvitations.length; i++) {
                        const currentFormattedInvitation = formattedInvitations[i];
                        if (currentFormattedInvitation.invitation.id !== formattedInvitation.invitation.id) {
                            newFormattedInvitations.push(currentFormattedInvitation);
                        }
                    }
                }
                setFormattedInvitations(newFormattedInvitations);
                setSuccessMessage("You declined this invitation.");
            } else {
                setError("Sorry, a problem has occurred. If it continues, contact hello@ringsidepro.com.");
            }
        } catch (error: any) {
            setError("Sorry, a problem has occurred. If it continues, contact hello@ringsidepro.com.");
        }
    }

    return (
        <>
            {(formattedInvitations && formattedInvitations.length) ?
                <IonCard className="bg-white ion-padding">
                    <IonCardHeader>
                        <h2>Invitations</h2>
                    </IonCardHeader>
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {successMessage && (
                            <IonCard color="success" mode="md">
                                <IonCardContent>
                                    <IonIcon icon={checkmark} />
                                    <IonLabel id="errorMsg" className="ion-text-wrap description font-weight-bold">
                                        {" " + successMessage}
                                    </IonLabel>
                                </IonCardContent>
                            </IonCard>
                        )}
                        <IonRow>
                            {isLoading ?
                                <Spinner />
                                :
                                <IonCol>
                                    <IonList className="bg-white">
                                        {formattedInvitations.map((formattedInvitation: FormattedInvitation, index: number) => (
                                            <IonItem key={index} lines="full">
                                                <IonRow>
                                                    <IonCol sizeXs="12" sizeMd="12">
                                                        <IonLabel className="ion-text-wrap">
                                                            <h2>You've been invited to join {formattedInvitation.organizationName}.</h2>
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="12" className="ion-text-center">
                                                        <IonButtons>
                                                            <IonButton color="success" onClick={() => handleAccept(formattedInvitation)}>
                                                                <IonIcon icon={checkmark} />
                                                                Accept
                                                            </IonButton>
                                                            <IonButton color="danger" onClick={() => handleDecline(formattedInvitation)}>
                                                                <IonIcon icon={close} />
                                                                Decline
                                                            </IonButton>
                                                        </IonButtons>
                                                    </IonCol>
                                                </IonRow>
                                            </IonItem>
                                        ))}
                                    </IonList>
                                </IonCol>
                            }
                        </IonRow>
                    </IonCardContent>
                </IonCard>
                :
                <></>
            }
        </>
    );
};

export default InvitationsCard;
