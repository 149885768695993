import {
    IonHeader,
    IonLabel,
    IonSegment,
    IonSegmentButton,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Event } from "../../models";

interface _Props {
    event: Event
    active: string
}

const ViewEventNavbar: React.FC<_Props> = ({active, event}) => {
    const history = useHistory();

    const [currentActiveBtn, setCurrentActiveBtn] = useState(active || "about");

    useEffect(() => {
        setCurrentActiveBtn(active);
    }, [active]);

    const handleSelection = async (selection: string) => {
        await setCurrentActiveBtn(selection);
        navigateToPage(selection);
    }

    const navigateToPage = (selection: string) => {
        const path = "/index/event/" + selection + "/" + event.id;
        history.push(path);
    };

    return (
        <IonHeader id="viewEventMenu">
            <IonSegment color="primary" mode="md" value={currentActiveBtn || active} scrollable={true} onIonChange={e => {handleSelection(e.detail.value || "about");}}>
                <IonSegmentButton value="about">
                    <IonLabel>About</IonLabel>
                </IonSegmentButton>
                {event.eventOptions?.usesJudgeApp && (
                    <IonSegmentButton value="judge">
                        <IonLabel>Judge Game</IonLabel>
                    </IonSegmentButton>
                )}
                {event.eventOptions?.usesTextAlerts && (
                    <IonSegmentButton value="alerts">
                        <IonLabel>Text Alerts</IonLabel>
                    </IonSegmentButton>
                )}
                {event.eventOptions?.usesEventManagement && (
                    <>
                        <IonSegmentButton value="schedule">
                            <IonLabel>Schedule</IonLabel>
                        </IonSegmentButton>
                        {/* For a 'lite' version of Online Entry - do not allow Cedar Valley Ranch riders to Enter Online */}
                        {(event.organizationId !== "a603e82a-a76b-4c76-bcbc-d6527ef878bc" || event.id === "04378e60-9038-4689-9275-5ac271c302d0") && 
                            <>
                                <IonSegmentButton value="entries">
                                    <IonLabel>Enter Online</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="results">
                                    <IonLabel>Results</IonLabel>
                                </IonSegmentButton>
                            </>
                        }
                    </>
                )}
            </IonSegment>
        </IonHeader>
    );
};

export default ViewEventNavbar;
