export const eventClassResultsByEventClassIdWithPointTables = /* GraphQL */ `
  query EventClassResultsByEventClassId(
    $eventClassId: ID!
    $place: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassResultsByEventClassId(
      eventClassId: $eventClassId
      place: $place
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryFee
          prizeMoney
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventClassName
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          items {
            id
            name
            description
            displayOrder
            organizationId
            discipline
            isAddValueToRegularFromEntryCount
            isAddValueToChampionshipFromEntryCount
            isUseChampionRule
            isUseReserveRule
            numberEntriesToReceiveSpecialClassPoints
            createdBy
            lastUpdatedBy
            createdOn
            updatedOn
          }
          nextToken
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;

export const eventClassResultsByEventClassIdWithEventClassEntryRider = /* GraphQL */ `
  query EventClassResultsByEventClassId(
    $eventClassId: ID!
    $place: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventClassResultsByEventClassId(
      eventClassId: $eventClassId
      place: $place
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          eventEntry {
            id
            horseName
            riderName
          }
          riderId
          rider {
            id
            name
          }
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      nextToken
    }
  }
`;