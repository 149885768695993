import {
    IonButton,
    IonButtons,
    IonContent,
    IonIcon,
    IonModal, 
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import { EventClass, EventClassEntry, EventResult } from "../../models";
import { getEventClassEntriesByEventClassId } from "../../utilities/eventClassEntry/EventClassEntry";
import { getEventResultsByEventClassId } from "../../utilities/eventResult/EventResult";
import Spinner from "../Spinners/Spinner";
import { close } from "ionicons/icons";
import EventClassKeyInResultsForm from "../EventClass/EventClassKeyInResultsForm";
import { getEventClassTypeAbbreviation } from "../../utilities/eventClass/EventClassTypes";

interface _Props {
    eventClass: EventClass
    openModal: boolean
    closeModal: Function
}

const EditEventClassResultsModal: React.FC<_Props> = ({eventClass, openModal, closeModal}) => {

    const [eventClassEntries, setEventClassEntries] = useState<EventClassEntry[] | undefined>();
    const [eventClassResults, setEventClassResults] = useState<EventResult[] | undefined>();

    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    async function getEventClassEntries(eventClassId: string) {
        const queryResult = await getEventClassEntriesByEventClassId(eventClassId);
        if (queryResult.isSuccess) {
            const eventClassEntries = queryResult.result;
            setEventClassEntries(eventClassEntries);
            return eventClassEntries;
        } else {
            setError("Sorry, a problem occurred. No event class entries found. Please go back and try again.");
        }
    }

    async function getEventClassResults(eventClassId: string) {
        const queryResult = await getEventResultsByEventClassId(eventClassId);
        if (queryResult.isSuccess) {
            const eventResults: EventResult[] = queryResult.result;
            setEventClassResults(eventResults);
            return eventResults;
        } else {
            setError("Sorry, a problem occurred. No class results found. Please go back and try again.");
        }
    }

    const getData = async (eventClassId: string) => {
        const eventClassEntries: EventClassEntry[] = await getEventClassEntries(eventClassId);
        const eventResults: EventResult[] | undefined = await getEventClassResults(eventClassId);
        setIsLoading(false);
    }

    useEffect(() => {
        if (eventClass) {
            getData(eventClass.id);
        }
    }, [eventClass]);

    useEffect(() => {
        setShowModal(openModal);
    }, [openModal]);

    const handleSave = async () => {
        setError("");
        if (eventClassEntries) {
            setIsLoading(true);
            setIsLoading(false);
            closeModal(true);
        } else {
            setError("No entries found.")
        }
    }

    return (
       <>
            {isLoading ?
                <Spinner />
                :
                <>
                    <IonModal backdropDismiss={false} isOpen={showModal} id="eventClassModal">
                        <IonToolbar color="light">
                            <IonTitle className="ion-text-center">
                                Class Results
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    fill="clear"
                                    onClick={() => {setShowModal(false); closeModal(true);}}
                                >
                                    <p id="closeEventClassModalBtn" className="font-weight-normal text-medium text-capitalize">
                                        <IonIcon icon={close} />
                                    </p>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                        <IonContent className="ion-padding">
                            <h3 className="ion-text-wrap">{eventClass.number ? eventClass.number + ": " : ""}{eventClass.name}</h3>
                            <p className="ion-text-wrap">{eventClass.type ? "Class Type: " + getEventClassTypeAbbreviation(eventClass.type) : ""}</p>
                            <hr/>
                            {eventClassResults && (
                                <EventClassKeyInResultsForm eventClass={eventClass} canEdit={true} onSubmit={() => setShowModal(false)} />
                            )}
                        </IonContent>
                    </IonModal>
                </>
            }        
       </>
    );
};

export default EditEventClassResultsModal;
