import React, { useEffect, useState } from "react";
import {creditBlockPricingStructure} from "../../data/content/CreditBlockPricing";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    menuPlacement?: string
    selectedValue?: string
    onSelect: Function
}

const SelectBlocks: React.FC<_Props> = ({selectedValue, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedBlocks, setFormattedBlocks] = useState<formattedOption[] | null | undefined>();

    useEffect(() => {
        formatBlocks();
    }, []);

    useEffect(() => {
        if (selectedValue) setSelected(selectedValue)
    }, [selectedValue]);

    const formatBlocks = () => {
        let formattedBlocks = [];
        for (var i = 0; i < creditBlockPricingStructure.length; i++) {
            const value = creditBlockPricingStructure[i].id.toString();
            const label = creditBlockPricingStructure[i].numberCredits.toLocaleString().toString();
            let object: formattedOption = {
                value: value,
                label: label,
                object: creditBlockPricingStructure[i]
            };
            formattedBlocks.push(object);
        }
        setFormattedBlocks(formattedBlocks);
    }

    const handleOnChange = (event?: any) => {
        if (event) {
            onSelect(event);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedBlocks ?
                <BasicSelect formattedValues={formattedBlocks} menuPlacement={menuPlacement} selectedValue={selected} onSelect={handleOnChange} />
                :
                <p>Loading options...</p>
            }
        </>
    )
};

export default SelectBlocks;
