import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    IonToggle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {EventAuditor} from "../../../../models";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { RouteComponentProps, useHistory } from "react-router";
import {getEventAuditorById, updateEventAuditor} from "../../../../utilities/eventAuditor/EventAuditor";
import ErrorAlert from "../../../../components/Errors/ErrorAlert";
import EventAuditorForm from "../../../../components/EventAuditor/EventAuditorForm";
import { UpdateEventAuditorInput } from "../../../../API";
import SuccessBanner from "../../../../components/Banners/SuccessBanner";

interface SubscriberPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EditSubscriberPage: React.FC<SubscriberPageProps> = ({match}) => {
    const history = useHistory();

    const [eventAuditor, setEventAuditor] = useState<EventAuditor | null | undefined>();
    const [status, setStatus] = useState("");
    const [isToggled, setIsToggled] = useState(true);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    useEffect(() => {
        async function getEventAuditor() {
            const queryResult = await getEventAuditorById(match.params.id);
            if (queryResult.isSuccess) {
                const currentEventAuditor = queryResult.result;
                setEventAuditor(currentEventAuditor);
                if (currentEventAuditor && currentEventAuditor.status) {
                    setStatus(currentEventAuditor.status); 
                    if (currentEventAuditor.status === "paused") setIsToggled(false);
                    else setIsToggled(true);
                }
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        getEventAuditor();
    }, [match, match.params.id]);

    const handleStatusSave = async () => {
        setSuccess("");
        setError("");
        if (eventAuditor) {
            const input: UpdateEventAuditorInput = {
                id: eventAuditor?.id,
                status: isToggled ? "active" : "paused"
            };
            const updateResult = await updateEventAuditor(input);
            if (updateResult.isSuccess) {
                setSuccess("Successfully updated the status setting.");
                setStatus(isToggled ? "active" : "paused");
            } else {
                setError("Could not update the status setting.");
            }
        } else {
            setError("Could not find the subscriber.");
        }
    }

    const handleSubmit = () => {
        history.goBack();
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Edit Settings" />
                {error && <ErrorAlert width="12" error={error} />}
                {success && <SuccessBanner width="12" success={success} />}
                <IonRow id="entryForm" className="ion-align-items-stretch">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-no-padding bg-white stretch">
                            <IonCardContent>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol className="ion-text-center">
                                        <p>{status !== "paused" ? "You're getting these alerts! Click the toggle, then click save to pause alerts." : "You've paused your alerts. Click the toggle, then click save to get alerts."}</p>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol className="ion-text-center">
                                        <IonToggle color="success" checked={isToggled} onIonChange={e => setIsToggled(e.detail.checked)}  />
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol className="ion-text-center">
                                       <IonButton color="tertiary" onClick={handleStatusSave}>Save</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow id="entryForm" className="ion-align-items-stretch">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-no-padding bg-white stretch">
                            <IonCardContent>
                                {eventAuditor ?
                                    <>
                                        {eventAuditor.event ?
                                            <EventAuditorForm event={eventAuditor?.event} eventAuditor={eventAuditor} onEdit={handleSubmit} />
                                            :
                                            <p>No event.</p>
                                        }
                                    </>
                                    :
                                    <p>Loading subsciber...</p>
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EditSubscriberPage;