import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Spinner from "../Spinners/Spinner";
import ErrorAlert from "../Errors/ErrorAlert";

interface _Props {
    selectedBarnName?: string
    onSubmit: Function
}

const BarnNameForm: React.FC<_Props> = ({selectedBarnName, onSubmit}) => {

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [barnName, setBarnName] = useState<string>(selectedBarnName || "");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (selectedBarnName) setBarnName(selectedBarnName);
    }, [selectedBarnName]);

    const verifyForm = () => {
        if (!barnName) {
            setError("Please include the barn name.");
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) onSubmit(barnName);
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonItem color="white">
                            <IonLabel position="stacked">Barn Name</IonLabel>
                            <IonInput 
                                type="text"
                                value={barnName}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setBarnName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default BarnNameForm;