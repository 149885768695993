import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useRef, useState } from "react";

import { Auth } from "aws-amplify";
import Spinner from "../Spinners/Spinner";
import ErrorAlert from "../Errors/ErrorAlert";
import SuccessBanner from "../Banners/SuccessBanner";

const PasswordForm: React.FC = () => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const passwordInputRef = useRef<HTMLIonInputElement>(null);
    const oldPasswordInputRef = useRef<HTMLIonInputElement>(null);

    const handleUpdatePassword = () => {
        const password = (passwordInputRef.current!.value as string);
        const oldPassword = (oldPasswordInputRef.current!.value as string);

        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPassword, password);
            })
            .then(() => {
                setSuccess("Successfully updated the password.");
            })
            .catch(err => {
                const message = "Could not update your password. Error: " + err;
                setError(message);
            });
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        handleUpdatePassword();
        setIsLoading(false);
    }

    return (
        <form>
            {error && <ErrorAlert error={error} width="12" /> }
            {success && <SuccessBanner success={success} width="12" /> }

            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Old Password</IonLabel>
                        <IonInput 
                            type="password" 
                            required={true}
                            aria-required={true}
                            ref={oldPasswordInputRef} 
                        />
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">New Password</IonLabel>
                        <IonInput 
                            type="password" 
                            required={true}
                            aria-required={true}
                            ref={passwordInputRef} 
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            Save
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </form>
    );
};

export default PasswordForm;