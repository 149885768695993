import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonRow,
} from "@ionic/react";
import React from "react";

const NewEventOrganizationPage: React.FC = () => {
    return (
        <>
            <IonRow className="ion-justify-content-center">
                <IonCol sizeSm="12" sizeMd="6">
                    <IonCard mode="md" className="ion-padding bg-white">
                        <IonCardTitle>Create Organization</IonCardTitle>
                        <IonCardContent>
                            <p>If you run events through an organization (i.e. a management company or other group), we recommend you create an organization.</p>
                            <IonButton
                                id="create-org-btn"
                                className="ion-margin-top"
                                    color="tertiary"
                                    routerLink="/index/staff/organization/create"    
                                >
                                Create Organization
                            </IonButton>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
                <IonCol sizeSm="12" sizeMd="6">
                    <IonCard mode="md" className="ion-padding bg-white">
                        <IonCardTitle>Request Access</IonCardTitle>
                        <IonCardContent>
                            <p>If you are a member of an organization, you can search for the org and request access.</p>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </>
    );
};

export default NewEventOrganizationPage;