import { IonButton, IonButtons, IonContent, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getPrizeMoneyTablesByOrganizationId } from "../../utilities/prizeMoneyTable/PrizeMoneyTable";
import CreatableSelect from 'react-select/creatable';
import ErrorAlert from "../Errors/ErrorAlert";
import InformationBanner from "../Banners/InformationBanner";
import { PrizeMoneyTable } from "../../models";
import PrizeMoneyTableForm from "./PrizeMoneyTableForm";
import Spinner from "../Spinners/Spinner";
import { close } from "ionicons/icons";

interface _Props {
    organizationId: string
    prizeMoneyTable?: (PrizeMoneyTable | null)
    onSelect: Function
}

interface formattedOption {
    label: string
    value: string
    prizeMoneyTable: PrizeMoneyTable
}

let subscription: any;

const SelectCreatePrizeMoneyTable: React.FC<_Props> = ({organizationId, prizeMoneyTable, onSelect}) => {

    const [formattedPrizeMoneyTables, setFormattedPrizeMoneyTables] = useState<formattedOption[] | null | undefined>();
    const [selectedOption, setSelectedOption] = useState<formattedOption | null | undefined>();
    const [selectedLabel, setSelectedLabel] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const formatPrizeMoneyTables = async () => {
        const queryResult = await getPrizeMoneyTablesByOrganizationId(organizationId);
        if (queryResult.isSuccess) {
            const prizeMoneyTables = queryResult.result;
            let formattedPrizeMoneyTables = [];
            for (var i = 0; i < prizeMoneyTables.length; i++) {
                const tableLabel = prizeMoneyTables[i].name + " (" + (prizeMoneyTables[i].isPercentage ? "%" : ("$" + prizeMoneyTables[i].totalPot)) + ")";
                let object = {
                    label: tableLabel,
                    value: prizeMoneyTables[i].id,
                    prizeMoneyTable: prizeMoneyTables[i]
                };
                formattedPrizeMoneyTables.push(object);
            }
            setFormattedPrizeMoneyTables(formattedPrizeMoneyTables);
            setPrizeMoneyTable(formattedPrizeMoneyTables);
        }
    }

    const setPrizeMoneyTable = (formattedTables?: formattedOption[]) => {
        let tables = formattedTables || formattedPrizeMoneyTables;

        if (prizeMoneyTable && tables) {
            for (var i = 0; i < tables?.length; i++) {
                const formattedTable = tables[i];

                if (formattedTable.prizeMoneyTable.id === prizeMoneyTable.id) {
                    setSelectedOption(formattedTable);
                }
            }
        } else {
            setSelectedOption(undefined);
        }
    }

    useEffect(() => {
        async function getPrizeMoneyTables(organizationId: string) {
            await formatPrizeMoneyTables();
        }

        async function setPrizeMoneyTableSubscription(organizationId: string) {
            // subscription = DataStore.observe(PrizeMoneyTable).subscribe(msg => {
            //     getPrizeMoneyTables(organizationId);
            // });
        }

        if (organizationId) getPrizeMoneyTables(organizationId);
        if (organizationId) setPrizeMoneyTableSubscription(organizationId);
        
        return () => {
            if (subscription && organizationId) subscription.unsubscribe();
        }
    }, [organizationId]);

    useEffect(() => {
        async function getPrizeMoneyTables() {
            await formatPrizeMoneyTables();
        }
        
        if (!formattedPrizeMoneyTables) {
            // Get the tables and then try to set the current table
            getPrizeMoneyTables();
        } else {
            setPrizeMoneyTable();
        }
        
    }, [prizeMoneyTable]);


    const handleOnChange = async (event: any) => {
        if (event && event.prizeMoneyTable) {
            // Chose a pre-configured prizeMoneyTable
            onSelect(event.prizeMoneyTable);
        } else if (event && event.label) {
            // Create a new prizeMoneyTable
            setSelectedLabel(event.label);
            setShowModal(true);
        } else {
            setSelectedOption(undefined);
            onSelect(undefined);
        }
    }

    const handleCreatedPrizeMoneyTable = (prizeMoneyTable: PrizeMoneyTable) => {
        setShowModal(false);
        onSelect(prizeMoneyTable);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {formattedPrizeMoneyTables && (
                <CreatableSelect
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    value={selectedOption}
                    menuPortalTarget={document.body}
                    menuPlacement="top"
                    isClearable
                    options={formattedPrizeMoneyTables}
                    onChange={handleOnChange}
                />
            )}
            <IonModal backdropDismiss={false} isOpen={showModal} id="addPrizeMoneyTableModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Prize Money Table
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closePrizeMoneyTableModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            <InformationBanner info="After you create a prize money table, you'll be able to re-use it for future clases." />
                            {organizationId && (
                                <PrizeMoneyTableForm tableName={selectedLabel} organizationId={organizationId} onSubmit={handleCreatedPrizeMoneyTable} />
                            )}
                        </>
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default SelectCreatePrizeMoneyTable;
