import {
    IonCard,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { Event } from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import Spinner from "../../../../../components/Spinners/Spinner";
import { RouteComponentProps } from "react-router";
import CreateEventPage from "./CreateEventPage";
import { getEventById } from "../../../../../utilities/events/Event";
import { formatStartEndDatesStrings } from "../../../../../utilities/dates/FormatDates";
import { copyOutline, createOutline, phonePortraitOutline, reorderThreeOutline, trailSignOutline } from "ionicons/icons";
import barn from "../../../../../assets/img/icons/barn.svg";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";

interface EventSettingsPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventSettingsMenuPage: React.FC<EventSettingsPageProps> = ({match}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            setIsLoading(true);
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setCurrentEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
            setIsLoading(false);
        }
        getEvent();
    }, [match, match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header isEventPage={currentEvent ? true : false} event={currentEvent ? currentEvent : undefined} />
            <IonContent>
                <PageTitle title={currentEvent ? (currentEvent.nickname ? currentEvent.nickname : currentEvent.name) : "Event"} />
                {currentEvent ?
                    <>
                        {error && <ErrorAlert error={error} />}
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <IonCard className="bg-white ion-padding">
                                    <IonText className="ion-justify-content-center">
                                        <h3 className="text-center">{currentEvent.name} Settings</h3>
                                        <p className="text-center">{formatStartEndDatesStrings(currentEvent.startDate, currentEvent.endDate)}</p>
                                    </IonText>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                            <IonCard className="bg-white ion-padding">
                                <IonList className="bg-white">
                                    {currentEvent.eventOptions?.usesTextAlerts && (
                                        <IonItem
                                            detail={true}
                                            routerLink={"/index/staff/event/" + currentEvent.id + "/settings/alerts"}
                                            routerDirection="none"
                                        >
                                            <IonIcon icon={phonePortraitOutline} slot="start" />
                                            <IonLabel>
                                                <h2 className="ion-text-wrap">Text Alert Settings</h2>
                                                <p className="ion-text-wrap">Types of Alerts, Filters</p>
                                            </IonLabel>
                                        </IonItem>
                                    )}
                                    {currentEvent.eventOptions?.usesEventManagement && (
                                        <>
                                            <IonItem
                                                detail={true}
                                                routerLink={"/index/staff/event/" + currentEvent.id + "/settings/duplicate"}
                                                routerDirection="none"
                                            >
                                                <IonIcon icon={copyOutline} slot="start" />
                                                <IonLabel>
                                                    <h2 className="ion-text-wrap">Duplicate Settings</h2>
                                                    <p className="ion-text-wrap">Copy settings, schedule, etc from past event</p>
                                                </IonLabel>
                                            </IonItem>
                                            <IonItem
                                                detail={true}
                                                routerLink={"/index/staff/event/" + currentEvent.id + "/settings/entries"}
                                                routerDirection="none"
                                            >
                                                <IonIcon icon={reorderThreeOutline} slot="start" />
                                                <IonLabel>
                                                    <h2 className="ion-text-wrap">Entry Settings</h2>
                                                    <p className="ion-text-wrap">Required information for entries (coggins, contact info, etc.)</p>
                                                </IonLabel>
                                            </IonItem>
                                            <IonItem
                                                detail={true}
                                                routerLink={"/index/staff/event/" + currentEvent.id + "/settings/stalls"}
                                                routerDirection="none"
                                            >
                                                <IonIcon src={barn} slot="start" />
                                                <IonLabel>
                                                    <h2 className="ion-text-wrap">Stall Settings</h2>
                                                    <p className="ion-text-wrap">Stall Types and Stall Sets</p>
                                                </IonLabel>
                                            </IonItem>
                                            <IonItem
                                                detail={true}
                                                routerLink={"/index/staff/event/" + currentEvent.id + "/settings/rules"}
                                                routerDirection="none"
                                            >
                                                <IonIcon icon={createOutline} slot="start" />
                                                <IonLabel>
                                                    <h2 className="ion-text-wrap">Rules Settings</h2>
                                                    <p className="ion-text-wrap">Waivers, Rules Notices, etc. </p>
                                                </IonLabel>
                                            </IonItem>
                                            <IonItem
                                                detail={true}
                                                routerLink={"/index/staff/event/" + currentEvent.id + "/settings/affiliations"}
                                                routerDirection="none"
                                            >
                                                <IonIcon icon={trailSignOutline} slot="start" />
                                                <IonLabel>
                                                    <h2 className="ion-text-wrap">EC/USEF Settings</h2>
                                                    <p className="ion-text-wrap">Your EC/USEF show id</p>
                                                </IonLabel>
                                            </IonItem>
                                        </>
                                    )}
                                </IonList>
                            </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                    :
                    <>
                        {isLoading ?
                            <Spinner />
                            :
                            <CreateEventPage />
                        }
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default EventSettingsMenuPage;