import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Rider } from "../../models";
import { getAllRiders } from "../../utilities/rider/Rider";
import moment from "moment";
import Spinner from "../Spinners/Spinner";

interface _Props {
    selectedValue?: (string | null)
    onSelect: Function
    isHeightRequired?: boolean
    height?: string
}

interface formattedOption {
    label: string
    value: string
    rider: Rider
}

const SelectFromAllRiders: React.FC<_Props> = ({selectedValue, onSelect, isHeightRequired, height}) => {

    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [formattedRiders, setFormattedRiders] = useState<formattedOption[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const getRiders = async () => {
        const queryResult = await getAllRiders();
        if (queryResult.isSuccess) {
            const ridersArray: Rider[] = queryResult.result;
            const sorted = ridersArray.sort((a,b) => a.name.trim().localeCompare(b.name.trim()));
            return sorted || ridersArray;
        }
        return [];
    }

    const formatRiders = async () => {
        setIsLoading(true);

        let formattedRiders = [];

        const allRiders = await getRiders();

        for (var i = 0; i < allRiders.length; i++) {
            const rider = allRiders[i];
            const value = rider.id;
            const name = rider.name;
            const barnName = rider.barn?.name || rider.barnName || "unknown";
            const createdOn = rider.createdOn ? moment(rider.createdOn).format("MM/DD/YYYY") : "unknown";
            const label = (name || "error") + "\nIn Barn: " + barnName  + "\nCreated on: " + createdOn;
            let object: formattedOption = {
                value: value,
                label: label,
                rider: rider
            };
            formattedRiders.push(object);
        }
        setFormattedRiders(formattedRiders);
        if (selectedValue) findValueInList(selectedValue, formattedRiders);

        setIsLoading(false);
    }

    const findValueInList = (value: string, riderList?: formattedOption[]) => {
        let optionArray = riderList || formattedRiders;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        formatRiders();
    }, []);

    useEffect(() => {
        function setValue() {
            if (selectedValue) {
                findValueInList(selectedValue);
            } else {
                setCurrentValue(undefined);
            }
        }
        setValue();
    }, [selectedValue]);

    const handleOnChange = (event?: any) => {
        if (event && event.rider) {
            setCurrentValue(event);
            onSelect(event.rider);
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <Select
                    id="select-from-riders"
                    inputId="select-from-all-riders-input"
                    classNamePrefix="react-select"
                    styles={{
                        // Fixes the overlapping problem of the component
                        control: baseStyles =>(isHeightRequired ? {...baseStyles, height} : {...baseStyles}),
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        
                    }}
                    defaultValue={currentValue}
                    value={currentValue}
                    menuPortalTarget={document.body}
                    isClearable
                    options={formattedRiders!}
                    onChange={handleOnChange}
                />
            }
        </>
    )
};

export default SelectFromAllRiders;
