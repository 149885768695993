import { Clinician, PaymentMethod } from "../../models";
import { CreatePaymentMethodInput, PaymentMethodType, UpdateClinicianInput, UpdatePaymentMethodInput } from "../../API";
import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonRadio,
    IonRadioGroup,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import { updateClinician, getClinicianByPersonId} from "../../utilities/clinician/Clinician";
import moment from "moment";
import InformationBanner from "../Banners/InformationBanner";
import Spinner from "../Spinners/Spinner";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import { onUpdateClinician } from "../../graphql/subscriptions";
import { createPaymentMethod, updatePaymentMethod } from "../../utilities/paymentMethod/PaymentMethod";
import { createExpressAccount } from "../../utilities/stripe/ExpressAccount";

interface _Props {
    selectedClinician?: Clinician
    onChange?: Function
}

const paymentDescriptions = {
    "check": "We will mail your checks on the first of each month. Please make sure you provide who you would like to make the check payable to as well as the mailing address.",
    "venmo": "We will send you Venmo payments on the first of each month. Please sure you provide a Venmo username.",
    "direct_deposit": "Your payments will be sent to you on the first of each month. Please allow a few days for the payment to display in your bank account."
};

const ClinicianPaymentForm: React.FC<_Props> = ({selectedClinician, onChange}) => {
    const user = useContext(PersonContext);

    const clinicianSubscription = useSubscriptionByItself({
        config: {
            query: onUpdateClinician,
            key: "onUpdateClinician"
        }
    });

    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    
    const [isEditing, setIsEditing] = useState(false);
    const [clinician, setClinician] = useState<Clinician | null | undefined>();
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null | undefined>();
    const [selected, setSelected] = useState<string>("");
    const [venmoHandle, setVenmoHandle] = useState<string>("");
    const [payableTo, setPayableTo] = useState<string>("");
    const [mailingAddress, setMailingAddress] = useState<string>("");
    const [stripeId, setStripeId] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const verifyForm = () => {
        if (!selected) {
            setError("Please select one of the options.");
            return false;
        }
        if (selected === "direct_deposit" && !stripeId) {
            setError("Please complete the Stripe direct deposit setup.");
            return false;
        }
        if (selected === "venmo" && !venmoHandle) {
            setError("Please include your Venmo username.");
            return false;
        }
        if (selected === "check" && (!payableTo || !mailingAddress)) {
            setError("Please include the mailing address and payable to fields.");
            return false;
        }
        return true;
    }

    const setPaymentSelection = (clinician: Clinician) => {
        const method: PaymentMethod | null | undefined = clinician.paymentChoice;
        if (method) {
            setPaymentMethod(method);
            if (method.type === "check") {
                setSelected("check");
                setPayableTo(method.altId || "");
                setMailingAddress(method.value || "");
            }
            if (method.type === "venmo") {
                setSelected("venmo");
                setVenmoHandle(method.altId || "");
            }
            if (method.type === "ach") {
                setSelected("direct_deposit");
                setStripeId(method.stripeId || "");
            }
        } else {
            setIsEditing(true);
        }
    }

    const getClinician = async (personId: string) => {
        setIsLoading(true);
        const queryResult = await getClinicianByPersonId(personId);
        if (queryResult.isSuccess) {
            const clinician: Clinician = queryResult.result;
            setClinician(clinician);
            setPaymentSelection(clinician);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (user) {
            getClinician(user.id);
        }
    }, []);

    useEffect(() => {
        setClinician(selectedClinician);
        if (selectedClinician) setPaymentSelection(selectedClinician);
    }, [selectedClinician]);

    useEffect(() => {
        if (clinicianSubscription && clinicianSubscription[0] !== undefined && clinicianSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(clinicianSubscription[0]);
            if (user) {
                getClinician(user.id);
            }
        }
    }, [clinicianSubscription]);

    const handleUpdateClinician = async () => {
        let selectedPaymentMethod = paymentMethod;

        const type = selected === "check" ? PaymentMethodType.check : selected === "venmo" ? PaymentMethodType.venmo : PaymentMethodType.ach;
        const altId = selected === "check" ? payableTo : selected === "venmo" ? venmoHandle : "";
        const value = selected === "check" ? mailingAddress :  "";

        if (!selectedPaymentMethod) {
            const createInput: CreatePaymentMethodInput = {
                personId: user.id,
                type,
                altId,
                value,
                stripeId
            };
            const createResult = await createPaymentMethod(createInput);
            if (createResult.isSuccess) {
                selectedPaymentMethod = createResult.result;
            } else {
                setError("Could not save the payment method option.");
            }
        } else {
            const updateInput: UpdatePaymentMethodInput = {
                id: selectedPaymentMethod.id,
                personId: user.id,
                type,
                altId,
                value,
                stripeId
            };
            const updateResult = await updatePaymentMethod(updateInput);
            if (updateResult.isSuccess) {
                selectedPaymentMethod = updateResult.result;
            } else {
                setError("Could not save the payment method option.");
            }
        }

        if (clinician) {
            const input: UpdateClinicianInput = {
                id: clinician.id,
                clinicianPaymentChoiceId: selectedPaymentMethod?.id,
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
            const updateResult = await updateClinician(input);
            if (updateResult.isSuccess) {
                if (onChange) onChange(updateResult.result);
                setClinician(updateResult.result);
            } else {
                setError(updateResult.message);
            }
        } else {
            setError("No clinician to edit.");
        }
    }

    const handleEditClinician = async () => {
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            setIsLoading(true);
            await handleUpdateClinician();
            setIsEditing(false);
            setIsLoading(false);
        }
    }

    const createStripeExpressAccount = async () => {
        setIsLoading(true);
        const dataResult = await createExpressAccount(user.id);
        if (dataResult.isSuccess) {
            const onboardingLink = dataResult.result.onboardingLink;
            window.open(onboardingLink);
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert error={error} />}
            {isEditing ?
                <>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem lines="none">
                                <IonLabel>
                                    <IonRow>
                                        <IonCol size="12">
                                        <p className="ion-text-wrap">Select how you would like to be paid.</p> 
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonList className="bg-white">
                                                <IonRadioGroup value={selected} onIonChange={e => setSelected(e.detail.value)}>
                                                    <IonItem>
                                                        <IonLabel>Direct Deposit</IonLabel>
                                                        <IonRadio slot="start" mode="ios" value="direct_deposit" />
                                                    </IonItem>

                                                    <IonItem>
                                                        <IonLabel>Venmo</IonLabel>
                                                        <IonRadio slot="start" mode="ios" value="venmo" />
                                                    </IonItem>

                                                    <IonItem>
                                                        <IonLabel>Check</IonLabel>
                                                        <IonRadio slot="start" mode="ios" value="check" />
                                                    </IonItem>
                                                </IonRadioGroup>
                                            </IonList>
                                        </IonCol>
                                    </IonRow>
                                </IonLabel>
                            </IonItem> 
                        </IonCol>
                    </IonRow>
                    {selected === "direct_deposit" && (
                        <IonRow>
                            <IonCol size="12" className="ion-text-center">
                                <p>We partner with a secure online payment platform, Stripe, for direct deposits.</p>
                                <IonButton color="tertiary" onClick={createStripeExpressAccount}>Get Started</IonButton>
                            </IonCol>
                        </IonRow>
                    )}
                    {selected === "venmo" && (
                        <>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem>
                                        <IonLabel position="stacked">Venmo Username</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={venmoHandle}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setVenmoHandle(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    {selected === "check" && (
                        <>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem>
                                        <IonLabel position="stacked">Make Check Payable To</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={payableTo}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setPayableTo(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <IonItem>
                                        <IonLabel position="stacked">Mailing Address</IonLabel>
                                        <IonInput 
                                            type="text"
                                            value={mailingAddress}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setMailingAddress(e.detail.value!)
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                </>
                :
                <IonRow>
                    <IonCol size="12">
                        <InformationBanner info={
                            selected === "direct_deposit" ? paymentDescriptions.direct_deposit :
                            selected === "venmo" ? paymentDescriptions.venmo :
                            selected === "check" ? paymentDescriptions.check :
                            !selected ? "Please select Edit to choose a payment method." : ""
                        } />
                        <IonItem lines="none">
                            {selected ? <h3>Selection: {selected}</h3> : ""}
                        </IonItem> 
                        <hr />
                        {selected === "venmo" && (
                            <p>Venmo Username: {venmoHandle}</p>
                        )}
                        {selected === "check" && (
                            <>
                                <p>Payable To: {payableTo}</p>
                                <p>Mailing Address: {mailingAddress}</p>
                            </>
                        )}
                        {selected === "direct_deposit" && (
                            <>
                                <p>Stripe Id Number: {stripeId}</p>
                            </>
                        )}
                    </IonCol>
                </IonRow>
            }
            {selected !== "direct_deposit" && (
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeMd="4" className="ion-text-center">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={() => (isEditing ? handleEditClinician() : setIsEditing(true))}
                            >
                                {isEditing ? "Save" : "Edit"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            )}
        </>
    );
};

export default ClinicianPaymentForm;