import { ScheduleItem } from "../../models";

var moment = require("moment");

/**
 * Sorts the scheduleItems so that the first element is the lowest and the last element is the highest display order
 * 
 * @param data 
 * @returns scheduleItems sorted lowest to highest
 */
export function sortScheduleItemsByDisplayOrder(data: ScheduleItem[]) {
    try {
        let result: ScheduleItem[] = [];
        if (data && data.length) {
            result = data.sort((a: ScheduleItem, b: ScheduleItem) => ((a.displayOrder) ? a.displayOrder : 0) - ((b.displayOrder) ? b.displayOrder : 0));
            result = data.sort(function(a, b): number {
                // To do - need a better way to ensure if a change in the order happens, the schedule correctly re-orders all the way day
                const dateResult = new moment(a.updatedOn).format("YYYYMMDDHHmmss") - new moment(b.updatedOn).format("YYYYMMDDHHmmss");
                const orderResult = (a.displayOrder || 0) - (b.displayOrder || 0);
                return orderResult || dateResult;
            });
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: scheduleItem");
        console.error(error);
        return null;
    }
};