import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateBarnInput, CreateBarnMutation, DeleteBarnInput, DeleteBarnMutation, GetBarnQuery, ListBarnsQuery, UpdateBarnInput, UpdateBarnMutation } from "../../API";
import moment from "moment";
import { Barn } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: barn. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created barn.
*/
export async function createBarn(input: CreateBarnInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Barn", "No input", "Create Barn received no input.");
   try {
        const fullInput: CreateBarnInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createBarn, { input: fullInput }))) as GraphQLResult<CreateBarnMutation>;
       else result = (await API.graphql({
           query: mutations.createBarn,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateBarnMutation>;
       const barn = result.data?.createBarn;
       return formatResult(true, "Barn", barn, "Successfully created the barn.");
   } catch (error: any) {
       return formatResult(false, "Barn", error, "Error creating record in the database for type: barn");
   }
}

/**
* Handle updating a new record in the database for type: barn. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated barn.
*/
export async function updateBarn(input: UpdateBarnInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Barn", "No input", "Update Barn received no input.");
   try {
        const fullInput: UpdateBarnInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateBarn, { input: fullInput }))) as GraphQLResult<UpdateBarnMutation>;
       else result = (await API.graphql({
           query: mutations.updateBarn,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateBarnMutation>;
       const barn = result.data?.updateBarn;
       return formatResult(true, "Barn", barn, "Successfully updated the barn.");
   } catch (error: any) {
       return formatResult(false, "Barn", error, "Error updating record in the database for type: barn");
   }
}

/**
* Handle deleting a new record in the database for type: barn. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted barn.
*/
export async function deleteBarn(input: DeleteBarnInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Barn", "No input", "Delete Barn received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteBarn, { input: input }))) as GraphQLResult<DeleteBarnMutation>;
       else result = (await API.graphql({
           query: mutations.deleteBarn,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteBarnMutation>;
       const barn = result.data?.deleteBarn;
       return formatResult(true, "Barn", barn, "Successfully deleted the barn.");
   } catch (error: any) {
       return formatResult(false, "Barn", error, "Error deleting record in the database for type: barn");
   }
}

/**
* Get all records in the database for type: barn. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the barn object
*/
export async function getAllBarns(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listBarns,
            variables: {
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBarnsQuery>;

        let items = result.data?.listBarns?.items as Barn[];
        let nextToken = result.data?.listBarns?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBarns,
                variables: {
                    limit: 1000,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBarnsQuery>;

            const nextItems = nextResult.data?.listBarns?.items as Barn[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBarns?.nextToken;
        }

        return formatResult(true, "Barn", items, "Successfully got the Barns.");
    } catch (error: any) {
        return formatResult(false, "Barn", error, "Error reading record in the database for type: barns");
    }
}

/**
* Get all records in the database for type: barn. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the barn object
*/
export async function getSegmentOfBarns(segmentSize: number, previousNextToken?: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        let variables: any = {
            limit: segmentSize
        };
        if (previousNextToken) {
            variables["nextToken"] = previousNextToken;
        }
        const result = (await API.graphql({
            query: queries.listBarns,
            variables: variables,
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBarnsQuery>;

        let items = result.data?.listBarns?.items as Barn[];
        let nextToken = result.data?.listBarns?.nextToken;

        const resultObject = {
            items: items,
            nextToken: nextToken
        };

        return formatResult(true, "Barn", resultObject, "Successfully got the Barns.");
    } catch (error: any) {
        return formatResult(false, "Barn", error, "Error reading record in the database for type: barns");
    }
}

/**
* Read a specific record in the database for type: barn. 
* 
* @param {string}  id                  The barn id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the barn object
*/
export async function getBarnById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getBarn,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetBarnQuery>;
       const barn = result.data?.getBarn;
       return formatResult(true, "Barn", barn, "Successfully got the barn.");
   } catch (error: any) {
       return formatResult(false, "Barn", error, "Error reading record in the database for type: barn");
   }
}

/**
* Get all records in the database that match the given criteria for type: barn. 
* 
* @param {string}  name                The state name of the barn.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the barn object
*/
export async function getBarnByName(name: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {name: {eq: name}};
        const result = (await API.graphql({
            query: queries.listBarns,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBarnsQuery>;
        const barns = result.data?.listBarns?.items;
        if (barns && barns[0]) return formatResult(true, "Barn", barns[0], "Successfully got the barn.");
        else return formatResult(false, "Barn", null, "Could not find the barn.");
    } catch (error: any) {
        return formatResult(false, "Barn", error, "Error reading record in the database for type: barn");
    }
}

/**
* Get all records in the database that match the given criteria for type: barn. 
* 
* @param {string}  personId            The person id of the "owner" of the barn
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the barn object
*/
export async function getBarnsByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {ownerPersonId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listBarns,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListBarnsQuery>;

        let items = result.data?.listBarns?.items as Barn[];
        let nextToken = result.data?.listBarns?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listBarns,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListBarnsQuery>;

            const nextItems = nextResult.data?.listBarns?.items as Barn[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listBarns?.nextToken;
        }

        return formatResult(true, "Barn", items, "Successfully got the Barns.");
    } catch (error: any) {
        return formatResult(false, "Barn", error, "Error reading record in the database for type: barns");
    }
}