import { Storage } from "aws-amplify";
import moment from "moment";
import awsmobile from "../../aws-exports";
import { Result } from "../../interfaces/Result";
import { Event, Horse, Organization, Person, Membership } from "../../models";
import { getExtension } from "../files/File";
import CONSTANT from "../../constant/constant";

const { v4: uuidv4 } = require('uuid');

interface StorageObject {
    key: string
}

export function createS3Object(key: string, title?: string, file?: any, description?: string) {
    return {
        title: title || "untitled",
        description: description || "",
        originalExtension: getExtension(file.name) || "",
        dateUploaded: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        bucket: awsmobile.aws_user_files_s3_bucket,
        region: awsmobile.aws_user_files_s3_bucket_region,
        key: key
    };
};

export function createOrgLogoFileName(org: Organization) {
    let uniqueId = "";
    if (org) {
        uniqueId = `${org.id}${uuidv4()}`;
    } else {
        uniqueId = uuidv4();
    }
    return uniqueId.toString() + "_logo.png";
};

export function createMembershipUploadFileName(membership: Membership, fileName: string) {
    const fileExtension = getExtension(fileName);
    const newFileName = `${uuidv4()}.${fileExtension}`;
    // folder structure - organizationId/OrganizationMembershipTypeId/membershipId/uniqueKey.ext
    // stored original file name in the description of S3Object
    const path = `${membership.organizationId}/${membership.organizationMembershipTypeId}/${membership.id}/${newFileName}`;
    return path;
};

export function createProfilePictureFileName(user: Person) {
    let uniqueId = "";
    if (user) uniqueId = user.id;
    else uniqueId = uuidv4(); 
    return uniqueId.toString() + "_profile_picture.png";
};

/**
 * Puts all MMS images in a folder called Alerts
 * The folder is set to public, which allows Twilio to access the s3 bucket URLS
 * 
 * @param option uses the event id  or organization id to name the image - can search for all images from an event
 * @returns 
 */
export function createMMSPictureFileName(option?: (Event | Organization), file?: any) {
    let uniqueId = uuidv4();
    let optionId = option?.id || "noId";
    const fileExtension = getExtension(file.name);
    return "Alerts/" + optionId + "_" + uniqueId.toString() + "." + (fileExtension ? fileExtension : "png");
};

export function createMMSPDFFileName(option?: (Event | Organization), originalFileName?: string) {
    let uniqueId = uuidv4();
    let optionId = option?.id || "noId";
    return "Alerts/" + (originalFileName ? (originalFileName + "_") : "") + optionId + "_" + uniqueId.toString() + ".pdf";
};

export function createEventDocumentFileName(event?: Event) {
    let uniqueId = "";
    if (event) uniqueId = event.id;
    else uniqueId = uuidv4(); 
    return uniqueId.toString() + "_" + uuidv4() +  "_document.png";
};

export function createCogginsDocumentFileName(horse?: Horse) {
    let uniqueId = "";
    if (horse) uniqueId = horse.id;
    else uniqueId = uuidv4(); 
    return uniqueId.toString() + "_" + uuidv4() +  "coggins.png";
};

export function createPersonVideoFileName(title: string, person?: Person) {
    let uniqueId = "";
    if (person) uniqueId = person.id;
    else uniqueId = uuidv4(); 
    return uniqueId.toString() + "_" + uuidv4() + "_" + title + ".mp4";
};

export async function getS3ImageFileUrl(fileName: string,
    expires: number = CONSTANT.ORGANIZATION.S3_FILE_URL_VALIDITY): Promise<Result> {
  try {
    const fileExtension = getExtension(fileName);
    let contentType = "";
    if (fileExtension) {
      if (fileExtension === "png") {
        contentType = "image/png";
      } else if (fileExtension === "jpeg") {
        contentType = "image/jpeg";
      } else if (fileExtension === "jpg") {
        contentType = "image/jpg";
      } else {
        contentType = "image/png";
      }
    }
    Storage.configure({
      AWSS3: {
        bucket: awsmobile.aws_user_files_s3_bucket,
        region: awsmobile.aws_user_files_s3_bucket_region
      },
    });
    const getResult = await Storage.get(fileName, {
      level: 'public',
      contentType,
      expires
    });
    if (getResult) {
      let result: Result = {isSuccess: true, type: "S3Object", result: getResult, message: "Successfully received the image url."};
      return result;
    } else {
      let result: Result = {isSuccess: false, type: "S3Object", result: null, message: "Error occurred while fetching the image url."};
      return result;
    }
  } catch (error: any) {
    let result: Result = {isSuccess: false, type: "S3Object", result: error, message: "Error occurred while fetching the image url."};
    return result;
  }
}

export async function handleUploadS3Image(fileName: string, file: any): Promise<Result> {
    try {
        const fileExtension = getExtension(file.name);
        let contentType = "";
        if (fileExtension) {
            if (fileExtension === "png") {
                contentType = "image/png";
            } else if (fileExtension === "jpeg") {
                contentType = "image/jpeg";
            } else if (fileExtension === "jpg") {
                contentType = "image/jpg";
            } else {
                contentType = "image/png";
            }
        }
        Storage.configure({
            AWSS3: {
                bucket: awsmobile.aws_user_files_s3_bucket,
                region: awsmobile.aws_user_files_s3_bucket_region
            },
        });
        const uploadResult = await Storage.put(fileName, file, {
            level: 'public',
            contentType: contentType
        });
        if (uploadResult) {
            let storageObject: StorageObject = uploadResult as StorageObject;
            let result: Result = {isSuccess: true, type: "S3Object", result: storageObject.key, message: "Successfully uploaded the image to S3."};
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "S3Object", result: null, message: "Error occurred while uploading the image to S3."};
            return result;
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "S3Object", result: error, message: "Error occurred while uploading the image to S3."};
        return result;
    }
}

export async function handleDeleteS3Item(fileName: string): Promise<Result> {
    try {
        const deleteResult = await Storage.remove(fileName);
        let result: Result = {isSuccess: true, type: "S3Object", result: deleteResult, message: "Successfully removed the item from S3."};
        return result;
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "S3Object", result: error, message: "Error occurred while removing the item from S3."};
        return result;
    }
}

export async function handleUploadS3File(fileName: string, file: any): Promise<Result> {
    try {
        Storage.configure({
            AWSS3: {
                bucket: awsmobile.aws_user_files_s3_bucket,
                region: awsmobile.aws_user_files_s3_bucket_region
            },
        });
        const uploadResult = await Storage.put(fileName, file, {contentType: "application/pdf"});
        if (uploadResult) {
            let storageObject: StorageObject = uploadResult as StorageObject;
            let result: Result = {isSuccess: true, type: "S3Object", result: storageObject.key, message: "Successfully uploaded the document to S3."};
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "S3Object", result: null, message: "Error occurred while uploading the document to S3."};
            return result;
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "S3Object", result: error, message: "Error occurred while uploading the document to S3."};
        return result;
    }
}

export async function handleDownloadS3File(key: string,
    expires: number = CONSTANT.ORGANIZATION.S3_FILE_URL_VALIDITY): Promise<Result> {
    // expires is the validity of the URL, in seconds. defaults to 900 (15 minutes)
    try {
        const getResult = await Storage.get(key, {
            level: 'public',
            download: true,
            expires
        });
        let result: Result = {isSuccess: true, type: "Blob", result: getResult, message: "Successfully downloaded the document."};
        return result;
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Blob", result: error, message: "Error occurred while downloading the file from S3."};
        return result;
    }
}

export async function handleDeleteS3File(key: string): Promise<Result> {
    try {
        const deleteResult = await Storage.remove(key, {
            level: 'public'
        });
        let result: Result = {isSuccess: true, type: "Blob", result: deleteResult, message: "Successfully deleted the file."};
        return result;
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Blob", result: error, message: "Error occurred while deleting the file from S3."};
        return result;
    }
}

export async function handleUploadVideoFile(file: any, fileName: string, title: string, description: string): Promise<Result> {
    try {
        // To Do - try to switch to Video on Demand
        // // Switch the Storage configuration to VOD
        // Storage.configure({
        //     AWSS3: {
        //         bucket: awsvideoconfig.awsInputVideo,
        //         region: awsmobile.aws_user_files_s3_bucket_region,
        //         customPrefix: {
        //             public: '',
        //         }
        //     },
        // });

        // // https://github.com/awslabs/amplify-video/wiki/Uploading-Assets
        // // First, create the video object
        // const uuid = uuidv4();
        // const videoObject = {
        //     input: {
        //         id: uuid
        //     }
        // };
        // API.graphql(graphqlOperation(createVideoObject, videoObject));
        
        // // Then create the video asset
        // const videoAsset = {
        //     input: {
        //         title: title,
        //         description: description,
        //         vodAssetVideoId: uuid,
        //     },
        // };
        // API.graphql(graphqlOperation(createVodAsset, videoAsset));

        // // Then add the file to the VOD S3 Storage bucket
        // const fileExtension = fileName.toLowerCase().split('.');
        // const uploadResult = await Storage.put(`${uuid}.${fileExtension[fileExtension.length - 1]}`, file, {
        //     progressCallback(progress: any) {
        //         const { loaded, total } = progress;
        //     },
        //     contentType: 'video/*',
        // });

        Storage.configure({
            AWSS3: {
                bucket: awsmobile.aws_user_files_s3_bucket,
                region: awsmobile.aws_user_files_s3_bucket_region
            },
        });

        const uploadResult = await Storage.put(fileName, file, {contentType: "video/*"});
        if (uploadResult) {
            let storageObject: StorageObject = uploadResult as StorageObject;
            let result: Result = {isSuccess: true, type: "S3Object", result: storageObject.key, message: "Successfully uploaded the video to S3."};
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "S3Object", result: null, message: "Error occurred while uploading the video to S3."};
            return result;
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "S3Object", result: error, message: "Error occurred while uploading the video to S3."};
        return result;
    }
}