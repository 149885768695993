export interface EventStatusOption {
    name: string
    value: string
}

export const publishStatusOptions = [
    {
        "name": "Draft",
        "value": "draft"
    },
    {
        "name": "Published",
        "value": "published"
    }
]

export const entriesStatusOptions = [
    // {
    //     "name": "No Online Entries",
    //     "value": "no_entries"
    // },
    {
        "name": "Entries Not Open Yet",
        "value": "will_accept"
    },
    {
        "name": "Accepting Entries",
        "value": "accepting"
    },
    {
        "name": "Entries Closed",
        "value": "not_accepting"
    }
];

export const showStatusOptions = [
    // {
    //     "name": "On Schedule",
    //     "value": "scheduled"
    // },
    {
        "name": "Postponed",
        "value": "postponed"
    },
    {
        "name": "Cancelled",
        "value": "cancelled"
    }
];

export function getEntriesStatusName(status: string) {
    let result = "No Online Entries";
    for (var i = 0; i < entriesStatusOptions.length; i++) {
        if (entriesStatusOptions[i].value === status) {
            result = entriesStatusOptions[i].name;
        }
    }
    return result;
}