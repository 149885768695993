function getFileNameWithoutExtension(filename: string) {
    var parts = filename.split(".");
    if (parts[0]) return parts[0];
    else return "unknown";
}

export function getExtension(filename: string) {
    var parts = filename.split(".");
    if (parts[parts.length - 1]) return parts[parts.length - 1];
    else return "unknown";
}

export function isCSV(file: File): Boolean {
    const ext = getExtension(file.name);
    switch (ext.toLowerCase()) {
        case "csv":
        case "xlsx":
        case "xls":
        case "xlt":
        return true;
    }
    return false;
};

export function isPDF(file: File): Boolean {
    const ext = getExtension(file.name);
    switch (ext.toLowerCase()) {
        case "pdf":
        return true;
    }
    return false;
};

/**
 * See Twilio's list of accepted content types for media:
 * https://www.twilio.com/docs/messaging/guides/accepted-mime-types
 * @param file 
 * @returns boolean
 */
export function isTwilioCompliantImage(file: File): boolean {
    const ext = getExtension(file.name);
    switch (ext.toLowerCase()) {
        case "jpg":
        case "jpeg":
        case "gif":
        case "png":
        return true;
    }
    return false;
};

/**
 * Twilio requires the file to have a lowercase extension
 * 
 * Changing the file extension:
 * https://stackoverflow.com/questions/21720390/how-to-change-name-of-file-in-javascript-from-input-file
 * @param file 
 * @returns boolean
 */
export function getTwilioCompliantFile(originalFile: File): File {
    const ext = getExtension(originalFile.name);

    // Check if the extension is uppercase
    if (ext === ext.toUpperCase()) {
        // If it is, convert the file to a new one with a lowercase extension
        const name = getFileNameWithoutExtension(originalFile.name);
        const updatedExtension = ext.toLowerCase();
        const updatedFullName = name + "." + updatedExtension;
    
        const updatedFile = new File([originalFile], updatedFullName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
        return updatedFile;
    }

    return originalFile;
};

export function isImage(file: File): Boolean {
    const ext = getExtension(file.name);
    switch (ext.toLowerCase()) {
        case "jpg":
        case "jpeg":
        case "svg":
        case "png":
        case "apng":
        case "webp":
        return true;
    }
    return false;
};

export function isVideo(file: File): Boolean {
    const ext = getExtension(file.name);
    switch (ext.toLowerCase()) {
        case "webm":
        case "mpg":
        case "mp2":
        case "mpeg":
        case "mpe":
        case "mpv":
        case "ogg":
        case "mp4":
        case "m4p":
        case "m4v":
        case "avi":
        case "wmv":
        case "mov":
        case "qt":
        case "flv":
        case "swf":
        case "avchd":
        return true;
    }
    return false;
};

export function downloadBlob(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
};
