import { OrganizationTypeOption, formatOrganizationTypes } from "../../utilities/organization/OrganizationType";
import { FormGroup, Input } from "reactstrap";
import React, { useEffect, useState } from "react";
import { OrganizationType } from "../../API";
import { IonLabel } from "@ionic/react";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    selectedValue?: OrganizationType,
    onInputChange: Function,
    isRequired?: (boolean | null)
}

const SelectOrganizationType: React.FC<_Props> = ({selectedValue, onInputChange, isRequired}) => {

    const options = formatOrganizationTypes();
    const [organizationType, setOrganizationType] = useState<string>("-");

    useEffect(() => {
        if (selectedValue) setOrganizationType(selectedValue);
    }, [selectedValue]);

    return (
        <>
            <FormGroup>
                <IonLabel className="description pl-3">Type {isRequired && <RequiredInputIndicator />}</IonLabel>
                <Input 
                    id="create-org-type"
                    name="OrganizationType" 
                    type="select" 
                    value={organizationType} 
                    onChange={e => {
                        setOrganizationType(e.target.value);
                        onInputChange(e.target.value)
                    }}
                >
                    <option>-</option>
                    {options?.map((organizationType: OrganizationTypeOption, index: number) => (
                        <option key={index} value={organizationType.value}>{organizationType.name}</option>
                    ))}
                </Input>
            </FormGroup>
        </>
    );
};

export default SelectOrganizationType;