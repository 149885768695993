import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import { createRing, updateRing} from "../../utilities/ring/Ring";
import { CreateEventRingInput, CreateRingInput, UpdateRingInput } from "../../API";
import { Event, Ring } from "../../models";
import SelectRing from "../Ring/SelectRing";
import { createEventRing } from "../../utilities/eventRing/EventRing";
import SuccessBanner from "../Banners/SuccessBanner";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    event: Event
    onSubmit: Function
}

const EventRingForm: React.FC<_Props> = ({event, onSubmit}) => {
    const user = useContext(PersonContext);

    const [name, setName] = useState("");
    const [isCreateNew, setIsCreateNew] = useState(false);
    const [ring, setRing] = useState<Ring | null | undefined>();

    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");

    const verifyForm = () => {
        try {
            if (!name) {
                setError("You must include a name.");
                return false;
            }
            return true;
        } catch (error: any) {
            setError(error);
            return false;
        }
    }

    const handleCreateRing = async () => {
        try {
           if (event && event.organizationId) {
                const ringInput: CreateRingInput = {
                    name,
                    organizationId: event.organizationId
                };
                const createResult = await createRing(ringInput);
                if (createResult.isSuccess) {
                    return createResult.result;
                } else {
                    setError(createResult.message);
                }
           }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleCreateEventRing = async (ringId: string) => {
        try {
            const input: CreateEventRingInput = {
                name: name,
                eventId: event.id,
                ringId
            };
            const createResult = await createEventRing(input);
            if (createResult.isSuccess) {
                setSuccess("Created the new ring!"); 
            } else {
                setError("Could not create the new ring.");
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        const isValid = verifyForm();
        if (isValid) {
            setError("");
            let currentRing = ring;
            if (!currentRing) {
                currentRing = await handleCreateRing();
            } 
            if (currentRing) await handleCreateEventRing(currentRing.id);
            else setError("No ring.");
        }
        setIsLoading(false);
    }

    return (
        <>
            {success && <SuccessBanner width="12" success={success}/>}
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol size="6">
                        <IonButton color={isCreateNew ? "light" : "primary"} onClick={() => setIsCreateNew(false)}>Org Rings</IonButton>
                    </IonCol>
                    <IonCol size="6">
                        <IonButton color={isCreateNew ? "primary" : "light"} onClick={() => setIsCreateNew(true)}>New Ring</IonButton>
                    </IonCol>
                </IonRow>
                {isCreateNew ?
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="floating">Name <RequiredInputIndicator showWordRequired /></IonLabel>
                                <IonInput 
                                    type="text"
                                    value={name}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setName(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    :
                    <>
                        <IonRow>
                            <IonCol>
                                <p>Select from one of the ring's previously saved to your organization.</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <SelectRing organizationId={event.organizationId || ""} onSelect={(ring: Ring) => {setRing(ring); setName(ring.name);}} />
                            </IonCol>
                        </IonRow>
                    </>
                }
                
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                Save
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default EventRingForm;