import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform
} from "@ionic/react";
import { add, close, trashOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { isWindows } from "../../utilities/platform/Platform";
import ErrorAlert from "../Errors/ErrorAlert";
import { MembershipTypeCategory, UpdateMembershipInput } from "../../API";
import { OrganizationDocument, OrganizationMembershipType, S3Object } from "../../models";
import { getMembershipsByMembershipTypeId, updateMembership } from "../../utilities/membership/Membership";
import Spinner from "../Spinners/Spinner";
import { handleDeleteS3File } from "../../utilities/s3Object/s3Object";
const { v4: uuidv4 } = require('uuid');

interface _Props {
    type: string;
    organizationDocuments: (OrganizationDocument | null)[] | null | undefined;
    setOrganizationDocuments: Function;
    membershipType?: OrganizationMembershipType;
}

const MembershipDocumentUploadList: React.FC<_Props> = ({ type, organizationDocuments, setOrganizationDocuments, membershipType}) => {
    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedDocument, setSelectedDocument] = useState<OrganizationDocument | undefined | null>(undefined);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [documentName, setDocumentName] = useState<string>("");
    const [documentList, setDocumentList] = useState<(OrganizationDocument | null)[] | null | undefined>(organizationDocuments);

    const handleOpenModal = () => {
        setError("");
        setShowModal(true);
    };

    const handleNewDocumentName = () => {
        if (!documentName) {
            setError("Please include a name for the file the user must upload.");
            return;
        }
        const newDocument = {
            id: uuidv4(),
            name: documentName
        };
        setDocumentList([...documentList ?? [], newDocument]);
        setDocumentName("");
        setShowModal(false);
    };

    const handleRemoveDocument = async () => {
        if (membershipType) {
            setIsLoading(true);
            const membershipResult = await getMembershipsByMembershipTypeId(membershipType.id);
            if(membershipResult.isSuccess) {
                for (const membership of membershipResult.result) {
                    const documents = membership.documents;
                    if(documents && documents.length) {
                        const index = documents?.findIndex((document: S3Object) => {
                            return document?.title === selectedDocument?.id;
                        });
                        if(index !== -1) {
                            const document = documents[index];
                            documents?.splice(index ?? 0, 1);
                            const input: UpdateMembershipInput = {
                                id: membership.id,
                                documents: documents
                            };
                            await updateMembership(input);
                            await handleDeleteS3File(document.key);
                        }
                    }
                }
            } else {
                setError(membershipResult.message);
            }
            setIsLoading(false);
        }
        const index = documentList?.findIndex((document) => {
            return document?.id === selectedDocument?.id;
        });
        documentList?.splice(index ?? 0, 1);
        setDocumentList([...documentList ?? []]);
        setShowConfirmModal(false);
        setSelectedDocument(undefined);
    }

    useEffect(() => {
        setOrganizationDocuments(documentList)
    }, [documentList]);

    return (
        <React.Fragment>
            <IonModal backdropDismiss={false} isOpen={showModal}>
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Required Documentation
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            id="document-upload-modal-close-icon"
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p
                                id="document-upload-modal-close-btn"
                                className="font-weight-normal text-medium text-capitalize"
                            >
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {error && <ErrorAlert width="12" error={error} />}
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <h4>
                                Include the name of a file that the user needs
                                to upload when submitting an application for
                                this membership type.
                            </h4>
                            <h4>Example: Proof of Insurance</h4>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10" className="ion-text-center">
                            <IonItem color="white">
                                <IonLabel position="floating">
                                    File Name{" "}
                                    <span className="description text-danger">
                                        *
                                    </span>
                                </IonLabel>
                                <IonInput
                                    id="document-upload-modal-name"
                                    type="text"
                                    value={documentName}
                                    aria-required={true}
                                    onIonChange={(e) => {
                                        setError("");
                                        setDocumentName(e.detail.value!);
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonContent>
                <IonRow className="ion-justify-content-center ion-padding-top">
                    <IonCol size="6" className="ion-text-center">
                        <IonButton
                            id="document-upload-modal-cancel-btn"
                            expand="block"
                            color="light"
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </IonButton>
                    </IonCol>
                    <IonCol size="6" className="ion-text-center">
                        <IonButton
                            id="document-upload-modal-add-btn"
                            expand="block"
                            color="tertiary"
                            onClick={handleNewDocumentName}
                        >
                            Add
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonModal>
            <IonModal backdropDismiss={false} isOpen={showConfirmModal}>
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Remove Document
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            id="document-upload-confirm-modal-close-icon"
                            fill="clear"
                            onClick={() => {
                                setShowConfirmModal(false)
                                setSelectedDocument(undefined)
                            }}
                        >
                            <p
                                id="document-upload-confirm-modal-close-btn"
                                className="font-weight-normal text-medium text-capitalize"
                            >
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading && <Spinner/>}
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12" className="mb-3">
                            <IonText>Are you sure you want to delete this document?</IonText>
                        </IonCol>
                        <IonCol size="12" className="mb-3" class="ion-text-center">
                            <h4>{selectedDocument?.name}</h4>
                        </IonCol>
                        <IonCol size="12" className="mb-3">
                            <IonText>
                                NOTE: if any users have created a membership of this type,
                                removing this document will also delete the connected documents
                                users have uploaded for their memberships.
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonContent>
                <IonRow className="ion-justify-content-center ion-padding-top">
                    <IonCol size="6" className="ion-text-center">
                        <IonButton
                            id="document-upload-confirm-modal-cancel-btn"
                            expand="block"
                            color="light"
                            onClick={() => {
                                setShowConfirmModal(false)
                                setSelectedDocument(undefined)
                            }}
                        >
                            Cancel
                        </IonButton>
                    </IonCol>
                    <IonCol size="6" className="ion-text-center">
                        <IonButton
                            id="document-upload-confirm-modal-remove-btn"
                            expand="block"
                            color="danger"
                            onClick={handleRemoveDocument}
                        >
                            Remove
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonModal>
            <h6 className="ml-1">
                Required Document Uploads for{" "}
                {type === MembershipTypeCategory.individual ? "an " + type : "a " + type} Member
            </h6>
            {isWindows() || isPlatform("desktop") ? (
                <IonRow>
                    <IonCol>
                        {documentList && documentList.length === 0 && (
                            <p>No items listed yet</p>
                        )}
                        <ul>
                            {documentList?.map((element, index) => {
                                return (
                                    <IonRow  key={index} className="ion-justify-content-between">
                                        <IonCol sizeMd="10">
                                            <li className="text-darker">
                                                {element?.name}
                                            </li>
                                        </IonCol>
                                        <IonCol className="pt-2" sizeMd="2">
                                            <IonIcon
                                                class="membership-type-document-remove-icon"
                                                className="ml-3 link"
                                                icon={trashOutline}
                                                color="danger"
                                                onClick={() => {
                                                    setShowConfirmModal(true)
                                                    setSelectedDocument(element)
                                                }}
                                            />
                                        </IonCol>
                                    </IonRow>
                                );
                            })}
                        </ul>
                    </IonCol>
                    <IonCol>
                        <IonButton id="membership-type-doc-upload" onClick={handleOpenModal}>
                            <IonIcon icon={add} />
                            <IonLabel>Add New</IonLabel>
                        </IonButton>
                    </IonCol>
                </IonRow>
            ) : (
                <>
                    <IonRow className="ion-justify-content-center">
                        <IonCol>
                            {documentList && documentList.length === 0 && (
                                <p className="text-center">No items listed yet</p>
                            )}
                            <ul>
                                {documentList?.map((element, index) => {
                                    return (
                                        <IonRow  key={index} className="ion-justify-content-between">
                                            <IonCol sizeXs="10">
                                                <li className="text-darker">
                                                    {element?.name}
                                                </li>
                                            </IonCol>
                                            <IonCol className="pt-2" sizeXs="2">
                                                <IonIcon
                                                    className="ml-1 link"
                                                    icon={trashOutline}
                                                    color="danger"
                                                    onClick={() => {
                                                        setShowConfirmModal(true)
                                                        setSelectedDocument(element)
                                                    }}
                                                />
                                            </IonCol>
                                        </IonRow>
                                    );
                                })}
                            </ul>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonButton onClick={handleOpenModal}>
                            <IonIcon icon={add} />
                            <IonLabel>Add New</IonLabel>
                        </IonButton>
                    </IonRow>
                </>
            )}
        </React.Fragment>
    );
};

export default MembershipDocumentUploadList;
