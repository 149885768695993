import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import {Auditor, Event, EventAuditor, EventAuditorFilter, EventFilter} from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import SelectOrganizationEvent from "../../../../../components/Event/SelectOrganizationEvent";
import { createEventAuditor, getEventAuditorByPhoneNumber, getEventAuditorsWithFilterByEventId } from "../../../../../utilities/eventAuditor/EventAuditor";
import Spinner from "../../../../../components/Spinners/Spinner";
import { AuditorStatus, CreateAuditorInput, CreateEventAuditorFilterInput, CreateEventAuditorInput } from "../../../../../API";
import { PersonContext } from "../../../../../context/PersonContext";
import { getEventFiltersByEventId } from "../../../../../utilities/eventFilter/EventFilter";
import { createEventAuditorFilter } from "../../../../../utilities/eventAuditorFilter/EventAuditorFilter";
import FileBrowser from "../../../../../components/FileBrowser/FileBrowser";
import { createAuditor, getAuditorByPhoneNumber } from "../../../../../utilities/auditor/Auditor";
import SuccessBanner from "../../../../../components/Banners/SuccessBanner";
import { formatTwilioNumber } from "../../../../../utilities/contact/FormatPhoneNumber";
import { sendRSPWelcomeTextsForEvent } from "../../../../../utilities/twilio/AuditorMessages";
import { handleBulkUploadFromCSV } from "../../../../../utilities/twilio/BulkAddSubscribers";
import { Table } from "reactstrap";
import PhoneInput from "react-phone-number-input";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

export interface formattedOption {
    isChecked: boolean
    isDisabled: boolean
    invalidReason?: string
    label: string
    phoneNumber: string
    name: string
    id?: string
    eventAuditor?: EventAuditor
    filters?: string
    rolesOptionString?: string
    daysOptionString?: string
    sectionOptionString?: string
}

const EventBulkAddSubscribersPage: React.FC<EventPageProps> = ({match}) => {
    const user = useContext(PersonContext);

    const [usePastEvent, setUsePastEvent] = useState(true);

    const [event, setEvent] = useState<Event>(); 
    const [eventFilters, setEventFilters] = useState<EventFilter[] | null | undefined>();
    const [currentEventSubscribers, setCurrentEventSubscribers] = useState<EventAuditor[] | null | undefined>();
    const [formattedOptions, setFormattedOptions] = useState<formattedOption[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [progressMessage, setProgressMessage] = useState("");

    const handleSelectedFile = async (file: File) => {
        setIsLoading(true);
        setSuccess("");
        setError("");
        if (event) {
            const result = await handleBulkUploadFromCSV(file, event, setProgressMessage, currentEventSubscribers);
            if (result.isSuccess) {
                const options: formattedOption[] = result.result;
                setFormattedOptions(options);
                setSuccess("Successfully uploaded the file.");
            } else {
                setError(result.message);
            }
        } else {
            setError("No event was found.");
        }
        setIsLoading(false);
    }

    async function getEvent() {
        const queryResult = await getEventById(match.params.id);
        if (queryResult.isSuccess) {
            setEvent(queryResult.result);
            await getEventSubscribers(queryResult.result);
            await getEventFilters(queryResult.result);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getEventFilters(event: Event) {
        if (event) {
            const queryResult = await getEventFiltersByEventId(event.id);
            if (queryResult.isSuccess) {
                setEventFilters(queryResult.result);
            }
        }
    }

    useEffect(() => {
        getEvent();
    }, [match, match.params.id]);

    const getEventSubscribers = async (event: Event) => {
        if (event) {
            const queryResult = await getEventAuditorsWithFilterByEventId(event.id);
            if (queryResult.isSuccess) {
                const eventAuditorList: EventAuditor[] = queryResult.result;
                setCurrentEventSubscribers(eventAuditorList);
                return eventAuditorList;
            }
        }
    }

    const handleSelectedEvent = async (newlySelectedEvent: Event) => {
        setIsLoading(true);
        setSuccess("");
        setError("");

        const selectedSubscribers = await getEventSubscribers(newlySelectedEvent) || [];

        let currentEventAuditors = currentEventSubscribers;
        if (event) {
            currentEventAuditors = await getEventSubscribers(event);
        }
        
        formatEventAuditors(selectedSubscribers, currentEventAuditors);
        setIsLoading(false);
    }

    const formatEventAuditors = (pastAuditors: EventAuditor[], currentAuditors?:(EventAuditor[] | null | undefined)) => {
        const auditorList = currentAuditors || currentEventSubscribers || [];
        // Track the seen phone numbers to avoid duplicates
        const seenPhoneNumbers: string[] = [];
        let result: formattedOption[] = [];
        for (let i = 0; i < pastAuditors.length; i++) {
            const current = pastAuditors[i];

            const message = i+1 + " of " + pastAuditors.length;
            setProgressMessage(message);

            let label = current.name + ": " + current.phoneNumber

            // If there is a current list of event auditors, the list was checked, and a duplicate was found, add to the label
            const foundAuditor = auditorList.findIndex(eventAuditor => eventAuditor.phoneNumber === current.phoneNumber);
            if (foundAuditor > -1) {
                label = label + " - Already subscribed to this event.";
            }
            
            // If this phone number is a duplicate of another in the list of auditors from a past event, add to the label
            const foundPhoneNumber = seenPhoneNumbers.findIndex(ph => ph === current.phoneNumber);
            if (foundPhoneNumber > -1) {
                label = label + " - This phone number is a duplicate of another number in the list.";
            } else {
                seenPhoneNumbers.push(current.phoneNumber || "");
            }

            let filtersString: string | undefined = undefined;
            const filtersItems: any = current.filters;
            if (filtersItems) {
                const filtersArray: (EventAuditorFilter | null)[] | undefined | null = filtersItems.items;
                if (filtersArray && filtersArray.length > 0) {
                    filtersString = "";
                    filtersArray.forEach((filter: any) => {
                        if (filter && filter.filter.name === "Roles") {
                            const options = filter.options;
                            if (options && options.length > 0) {
                                options.forEach((option: string | undefined) => {
                                    if (option) {
                                        filtersString = filtersString + " " + option;
                                    }
                                })
                            }
                        }
                    })
                }
            }
            
            const formatted: formattedOption = {
                isChecked: false,
                isDisabled: (foundAuditor !== -1 || foundPhoneNumber !== -1),
                label: label,
                phoneNumber: current.phoneNumber || "",
                name: current.name || "",
                id: current.id,
                eventAuditor: current,
                filters: filtersString,
            };

            result.push(formatted);
        };
        setProgressMessage("");
        setFormattedOptions(result);
    }

    const handleSelectAll = (value: boolean) => {
        setIsLoading(true);
        if (formattedOptions) {
            let list: formattedOption[] = (formattedOptions ? [...formattedOptions] : []);
            if (list) {
                for (let i = 0; i < list.length; i++) {
                    const current = list[i];
                    if (!current.isDisabled) current.isChecked = value;
                }
            } else {
                setError("Could not find the subscribers.");
            }
            setFormattedOptions(list);
        } 
        
        setIsLoading(false);
    }

    const handleOptionClick = (index: number) => {
        if (formattedOptions) {
            const selectedOption: formattedOption = formattedOptions[index];

            const updatedFormattedOption: formattedOption = {
                ...selectedOption,
                isChecked: !selectedOption.isChecked
            };

            const updatedList = [
                ...formattedOptions?.slice(0, index),
                updatedFormattedOption,
                ...formattedOptions.slice(index + 1)
            ];
            setFormattedOptions(updatedList);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setSuccess("");
        setError("");

        if (formattedOptions) {
            for (let i = 0; i < formattedOptions.length; i++) {
                const current = formattedOptions[i];

                const message = i+1 + " of " + formattedOptions.length;
                setProgressMessage(message);

                if (current.isChecked && !current.isDisabled) {
                    // Find or Create an auditor
                    let auditor: Auditor | null | undefined = current.eventAuditor?.auditor;
                    let isNewAuditor = false;
                    if (!auditor) {
                        const auditorQueryResult = await getAuditorByPhoneNumber(current.phoneNumber);
                        if (auditorQueryResult.isSuccess) {
                            auditor = auditorQueryResult.result;
                        } else {
                            const auditorInput: CreateAuditorInput = {
                                name: current.name,
                                phoneNumber: formatTwilioNumber(current.phoneNumber),
                                status: AuditorStatus.verified,
                                createdBy: user.id
                            };
                            const createAuditorResult = await createAuditor(auditorInput);
                            if (createAuditorResult.isSuccess) {
                                auditor = createAuditorResult.result;
                                isNewAuditor = true;
                            }
                        }
                    }
                    
                    if (event) {
                        // Check to make sure this phone number is not already used for this event
                        const eventAuditorQueryResult = await getEventAuditorByPhoneNumber(current.phoneNumber, event?.id);
                        if (!eventAuditorQueryResult.isSuccess) {
                            // Create New Event Auditor
                            const input: CreateEventAuditorInput = {
                                eventId: event?.id,
                                eventAuditorEventId: event?.id,
                                auditorId: auditor ? auditor.id : "",
                                name: current.name,
                                phoneNumber: formatTwilioNumber(current.phoneNumber),
                                status: auditor?.status || AuditorStatus.verified,
                                createdBy: user.id,
                                lastUpdatedBy: user.id
                            };
                            const createResult = await createEventAuditor(input);
                            if (createResult.isSuccess) {
                                if (eventFilters) {
                                    for (let j = 0; j < eventFilters.length; j++) {
                                        const currentFilter: EventFilter = eventFilters[j];
                                        if (currentFilter) {
                                            // Assume all options to begin with
                                            let optionsStringArray: (string | null)[] = currentFilter.options || [];

                                            if (currentFilter.name?.includes("Role")) {
                                                if (current.rolesOptionString) {
                                                    optionsStringArray = current.rolesOptionString.split(";");
                                                }
                                            } else if (currentFilter.name?.includes("Day")) {
                                                if (current.daysOptionString) {
                                                    optionsStringArray = current.daysOptionString.split(";");
                                                }
                                            } else if (currentFilter.name?.includes("Section")) {
                                                if (current.sectionOptionString) {
                                                    optionsStringArray = current.sectionOptionString.split(";");
                                                }
                                            }

                                            // Add the event filter with the selected options
                                            const eventAuditorFilterInput: CreateEventAuditorFilterInput = {
                                                eventId: event?.id,
                                                eventAuditorId: createResult.result.id,
                                                filterId: currentFilter.id,
                                                options: optionsStringArray,
                                                createdBy: user.id
                                            };
                                            await createEventAuditorFilter(eventAuditorFilterInput);
                                        }
                                    }
                                }
                            }
                            if (auditor) sendRSPWelcomeTextsForEvent(auditor, event, true, isNewAuditor);
                        }
                    }
                }
            }
        }
        setProgressMessage("");
        setSuccess("Successfully added new subscribers to your event!");
        setIsLoading(false);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={event ? event.name : "alerts"} />
                {event && (
                    <>
                        {error && <ErrorAlert width="12" error={error} />}
                        {success && <SuccessBanner width="12" success={success} />}
                        <IonRow id="bulkAdd" className="ion-align-items-stretch">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>Bulk Add</IonCardTitle>
                                    <IonCardContent>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol sizeXs="12" sizeSm="6" className="ion-text-center">
                                                <IonButton color={usePastEvent ? "primary" : "light"} onClick={() => setUsePastEvent(true)}>
                                                    Use Past Event
                                                </IonButton>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeSm="6" className="ion-text-center">
                                                <IonButton color={usePastEvent ? "light" : "primary"} onClick={() => setUsePastEvent(false)}>
                                                    Use CSV
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                        {usePastEvent ?
                                            <IonRow>
                                                <IonCol>
                                                    <p>First, select a past event to see the subscribers you can choose from.</p>
                                                    {event && event.organizationId && (
                                                        <SelectOrganizationEvent organizationId={event.organizationId} onSelect={handleSelectedEvent} />
                                                    )}
                                                </IonCol>
                                            </IonRow>
                                            :
                                            <>
                                                <IonRow className="ion-justify-content-center">
                                                    <IonCol size="12" className="text-center">
                                                        <p>First, select a CSV file or Excel file with a column labeled Name and a column labeled Phone Number.</p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-justify-content-center">
                                                    <IonCol size="12" className="text-center">
                                                        <FileBrowser handleSelectedFile={handleSelectedFile}/>
                                                    </IonCol>
                                                </IonRow>
                                            </>
                                        }
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow id="bulkAdd" className="ion-align-items-stretch">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>Select Subscribers</IonCardTitle>
                                        {isLoading ?
                                            <>
                                                <Spinner />
                                                <p className="ion-text-center ion-text-wrap">{progressMessage}</p>
                                            </>
                                            :
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol size="12">
                                                        <p>Select subscribers to add. Phone numbers already signed up for {event.name || "the current event"} are disabled.</p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size="12">
                                                        <p>Each subscriber you add will be signed up for ALL filters.</p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size="12">
                                                        <p>When you add them, each subscriber will get a text letting them know how to edit their alert settings.</p>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-justify-content-center">
                                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                                        <IonButton color="primary" onClick={() => handleSelectAll(true)}>Select All</IonButton>
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                                        <IonButton color="light" onClick={() => handleSelectAll(false)}>Deselect All</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                                {formattedOptions ?
                                                    <Table responsive hover bordered>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Select</th>
                                                                <th>Name</th>
                                                                <th>Phone Number</th>
                                                                <th>Valid?</th>
                                                                <th>Filters</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {formattedOptions.map((formattedOption, index) => (
                                                                <tr key={index} color={formattedOption.isDisabled ? "warning" : ""}>
                                                                    <td className="ion-text-wrap">{index+1}</td>
                                                                    <td>
                                                                        <IonCheckbox disabled={formattedOption.isDisabled } checked={formattedOption.isChecked} onClick={() => handleOptionClick(index)} />
                                                                    </td>
                                                                    <td className="ion-text-wrap">{formattedOption.name}</td>
                                                                    <td className="ion-text-wrap">
                                                                        {formattedOption.phoneNumber ?
                                                                            <PhoneInput
                                                                                placeholder="No phone number found"
                                                                                defaultCountry="US"
                                                                                disabled={true}
                                                                                value={formattedOption.phoneNumber as any}
                                                                                onChange={(phoneNumberValue?: any) => {
                                                                                    setError("");
                                                                                    console.log(phoneNumberValue);
                                                                                }}
                                                                            />
                                                                            :
                                                                            <p></p>
                                                                        }
                                                                    </td>
                                                                    <td className={`ion-text-wrap ${formattedOption.invalidReason ? "text-danger" : "text-success"}`}>{formattedOption.invalidReason ? formattedOption.invalidReason : "valid"}</td>
                                                                    <td className="ion-text-wrap">{formattedOption.filters ? formattedOption.filters : "all"}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    :
                                                    <p>None found.</p>
                                                }
                                                {/* {formattedOptions ?
                                                    <IonList className="bg-white">
                                                        {formattedOptions.map((formattedOption, index) => (
                                                            <IonItem key={index} disabled={formattedOption.isDisabled}>
                                                                <IonCheckbox slot="start" checked={formattedOption.isChecked} onClick={() => handleOptionClick(index)} />
                                                                <IonLabel>
                                                                    <IonRow>
                                                                        <IonCol size="12">
                                                                            <p className="ion-text-wrap"> {index + 1}) {formattedOption.label}</p>
                                                                        </IonCol>
                                                                        {formattedOption.filters && (
                                                                            <IonCol size="12">
                                                                                <p className="ion-text-wrap">{formattedOption.filters}</p>
                                                                            </IonCol>
                                                                        )}
                                                                    </IonRow>
                                                                </IonLabel>
                                                            </IonItem>
                                                        ))}
                                                    </IonList>
                                                    :
                                                    <p>None found.</p>
                                                } */}
                                                <IonRow className="ion-justify-content-center">
                                                    <IonCol size="12" className="ion-text-center">
                                                        <IonButton color="success" onClick={handleSubmit}>Submit</IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        }
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}           
            </IonContent>
        </IonPage>
    );
};

export default EventBulkAddSubscribersPage;