import { createRider, deleteRider, getRiderById, updateRider } from "../rider/Rider";
import { createOwner, deleteOwner, getOwnerById, updateOwner } from "../owner/Owner";
import { createTrainer, deleteTrainer, getTrainerById, updateTrainer } from "../trainer/Trainer";
import { formattedPerson } from "../../interfaces/Person";
import { Contact, CreateContactInput, CreateOwnerInput, CreateRiderInput, CreateTrainerInput, Membership, Owner, Rider, Trainer, UpdateContactInput, UpdateOwnerInput, UpdateRiderInput, UpdateTrainerInput } from "../../API";
import moment from "moment";
import { Result } from "../../interfaces/Result";
import { Person } from "../../models";
import { formatTwilioNumber } from "../contact/FormatPhoneNumber";
import { createContact, updateContact } from "../contact/Contact";
import { getMembershipsByOwnerId, getMembershipsByRiderId, getMembershipsByTrainerId } from "../membership/Membership";

interface RoleObject {
    rider?: Rider
    owner?: Owner
    trainer?: Trainer
}

async function getRider(id: string): Promise<Rider | null> {
    const queryResult = await getRiderById(id);
    if (queryResult.isSuccess) return queryResult.result;
    else return null;
}

async function getOwner(id: string): Promise<Owner | null> {
    const queryResult = await getOwnerById(id);
    if (queryResult.isSuccess) return queryResult.result;
    else return null;
}

async function getTrainer(id: string): Promise<Trainer | null> {
    const queryResult = await getTrainerById(id);
    if (queryResult.isSuccess) return queryResult.result;
    else return null;
}

async function getRoleObjects(formattedPerson: formattedPerson): Promise<RoleObject> {
    let rider: Rider | null = null;
    let owner: Owner | null = null;
    let trainer: Trainer | null = null;

    for (let i = 0; i < formattedPerson.ids.length; i++) {
        const roleId = formattedPerson.ids[i];
        if (roleId.role === "rider") rider = await getRider(roleId.id);
        if (roleId.role === "owner") owner = await getOwner(roleId.id);
        if (roleId.role === "trainer") trainer = await getTrainer(roleId.id);
    }

    return {
        rider: rider || undefined,
        owner: owner || undefined,
        trainer: trainer || undefined
    };
}

export async function getPersonContact(formattedPerson: formattedPerson): Promise<Contact | null> {
    let contact: Contact | null = null;

    const roleObject = await getRoleObjects(formattedPerson);
    if (roleObject.rider && roleObject.rider.contact) contact = roleObject.rider.contact;
    if (roleObject.owner && roleObject.owner.contact) contact = roleObject.owner.contact;
    if (roleObject.trainer && roleObject.trainer.contact) contact = roleObject.trainer.contact;
    
    return contact;
}

export async function getPersonMemberships(formattedPerson: formattedPerson): Promise<Membership[]> {
    let memberships: Membership[] = [];

    const roleObject = await getRoleObjects(formattedPerson);
    if (roleObject.rider) {
        const riderResult = await getMembershipsByRiderId(roleObject.rider.id);
        if (riderResult.isSuccess) {
            const current = riderResult.result;
            if (current && current.length > 0) {
                current.forEach((membership: Membership) => {
                    const index = memberships.findIndex(m => m.membershipId === membership.membershipId);
                    if (index === -1) memberships.push(membership);
                });
            }
        }
    }
    if (roleObject.owner) {
        const ownerResult = await getMembershipsByOwnerId(roleObject.owner.id);
        if (ownerResult.isSuccess) {
            const current = ownerResult.result;
            if (current && current.length > 0) {
                current.forEach((membership: Membership) => {
                    const index = memberships.findIndex(m => m.membershipId === membership.membershipId);
                    if (index === -1) memberships.push(membership);
                });
            }
        }
    }
    if (roleObject.trainer) {
        const trainerResult = await getMembershipsByTrainerId(roleObject.trainer.id);
        if (trainerResult.isSuccess) {
            const current = trainerResult.result;
            if (current && current.length > 0) {
                current.forEach((membership: Membership) => {
                    const index = memberships.findIndex(m => m.membershipId === membership.membershipId);
                    if (index === -1) memberships.push(membership);
                });
            }
        }
    }
    
    return memberships;
}

export async function savePersonBasicInfo(formattedPerson: formattedPerson, name?: string, location?:string ) {
    const roleObject = await getRoleObjects(formattedPerson);

    if (roleObject.rider) {
        const updateRiderInput: UpdateRiderInput = {
            id: roleObject.rider.id,
            name: name,
            location: location
        };
        await updateRider(updateRiderInput);
    }

    if (roleObject.owner) {
        const updateOwnerInput: UpdateOwnerInput = {
            id: roleObject.owner.id,
            name: name,
            location: location
        };
        await updateOwner(updateOwnerInput);
    }

    if (roleObject.trainer) {
        const updateTrainerInput: UpdateTrainerInput = {
            id: roleObject.trainer.id,
            name: name,
            location: location
        };
        await updateTrainer(updateTrainerInput);
    }
}

export async function savePersonContactInfo(formattedPerson: formattedPerson, email?: string, phoneNumber?:string, phoneType?: string) {
    let result: Result = {isSuccess: true, type: "Person", result: null, message: "Successfully updated the contact info."};

    let contact: (Contact | null) = await getPersonContact(formattedPerson);

    const roleObject = await getRoleObjects(formattedPerson);

    if (contact) {
        // Update the contact
        let contactInput: UpdateContactInput = {
            id: contact.id,
            name: contact.name || "",
            mailingAddress: contact.mailingAddress || "",                
        };
        if (phoneNumber) {
            const formattedPhoneNumber = formatTwilioNumber(phoneNumber);
            if (phoneType === "cell") contactInput["cell"] = formattedPhoneNumber;
            if (phoneType === "home") contactInput["home"] = formattedPhoneNumber;
            if (phoneType === "work") contactInput["work"] = formattedPhoneNumber;
        }
        if (roleObject.rider || roleObject.owner) {
            contactInput["personalEmail"] = email;
        }
        if (roleObject.trainer) {
            contactInput["workEmail"] = email;
        }
        const updateContactResult = await updateContact(contactInput);
        if (updateContactResult.isSuccess) {
            contact = updateContactResult.result;
        }
    } else {
        // Create the contact
        let contactInput: CreateContactInput = {};
        if (phoneNumber) {
            const formattedPhoneNumber = formatTwilioNumber(phoneNumber);
            if (phoneType === "cell") contactInput["cell"] = formattedPhoneNumber;
            if (phoneType === "home") contactInput["home"] = formattedPhoneNumber;
            if (phoneType === "work") contactInput["work"] = formattedPhoneNumber;
        }
        if (roleObject.rider || roleObject.owner) {
            contactInput["personalEmail"] = email;
        }
        if (roleObject.trainer) {
            contactInput["workEmail"] = email;
        }
        const createContactResult = await createContact(contactInput);
        if (createContactResult.isSuccess) {
            contact = createContactResult.result;
        }
    }

    if (contact) {
        // Now connect the contact to any rider, owner or trainer objects
        if (roleObject.rider) {
            const updateRiderInput: UpdateRiderInput = {
                id: roleObject.rider.id,
                riderContactId: contact?.id
            };
            const updateRiderResult = await updateRider(updateRiderInput);
            if (!updateRiderResult.isSuccess) {
                result = {isSuccess: false, type: "Person", result: null, message: "Could not update rider contact information"};
                return result;
            }
        }
        if (roleObject.owner) {
            const updateOwnerInput: UpdateOwnerInput = {
                id: roleObject.owner.id,
                ownerContactId: contact?.id
            };
            const updateOwnerResult = await updateOwner(updateOwnerInput);
            if (!updateOwnerResult.isSuccess) {
                result = {isSuccess: false, type: "Person", result: null, message: "Could not update owner contact information"};
                return result;
            }
        }
        if (roleObject.trainer) {
            const updateTrainerInput: UpdateTrainerInput = {
                id: roleObject.trainer.id,
                trainerContactId: contact?.id
            };
            const updateTrainerResult = await updateTrainer(updateTrainerInput);
            if (!updateTrainerResult.isSuccess) {
                result = {isSuccess: false, type: "Person", result: null, message: "Could not update trainer contact information"};
                return result;
            }
        }
        return result;
    } else {
        result = {isSuccess: false, type: "Person", result: null, message: "No contact information to update"};
        return result;
    }
}

export async function savePersonRoles(user: Person, creator: Person, formattedPerson: formattedPerson, isRider?: boolean, isOwner?: boolean, isTrainer?: boolean, isParent?: boolean, barnId?: string): Promise<Result> {
    const roleObject = await getRoleObjects(formattedPerson);

    let result: Result = {isSuccess: true, type: "Person", result: null, message: "Updated the roles."};

    if (roleObject.rider) {
        if (isRider) {
            // Already has the rider
        } else {
            // Remove the rider
            await deleteRider({id: roleObject.rider.id});
        }
    } else {
        if (isRider) {
            // Needs a new rider
            const createRiderInput: CreateRiderInput = {
                name: formattedPerson.name,
                location: formattedPerson.location,
                personId: user.id || roleObject.trainer?.personId || roleObject.owner?.personId || "",
                barnId: barnId || roleObject.trainer?.barnId || roleObject.owner?.barnId || "",
                createdBy: creator.id,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")             
            };
            const createRiderResult = await createRider(createRiderInput);
            if (!createRiderResult.isSuccess) {
                const message = result.message + " Could not update the rider role. " + createRiderResult.message;
                result = {isSuccess: false, type: "Person", result: createRiderResult.result, message: message};
            }
        } else {
            // Do nothing
        }
    }

    if (roleObject.owner) {
        if (isOwner) {
            // Already has the owner
        } else {
            // Remove the owner
            await deleteOwner({id: roleObject.owner.id});
        }
    } else {
        if (isOwner) {
            // Needs a new owner
            const createOwnerInput: CreateOwnerInput = {
                name: formattedPerson.name,
                location: formattedPerson.location,
                personId: user.id || roleObject.trainer?.personId || roleObject.rider?.personId || "",
                barnId: barnId || roleObject.trainer?.barnId || roleObject.rider?.barnId || "",
                createdBy: creator.id,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")             
            };
            const createOwnerResult = await createOwner(createOwnerInput);
            if (!createOwnerResult.isSuccess) {
                const message = result.message + " Could not update the owner role. " + createOwnerResult.message;
                result = {isSuccess: false, type: "Person", result: createOwnerResult.result, message: message};
            }
        } else {
            // Do nothing
        }
    }

    if (roleObject.trainer) {
        if (isTrainer) {
            // Already has the trainer
        } else {
            // Remove the trainer
            await deleteTrainer({id: roleObject.trainer.id});
        }
    } else {
        if (isTrainer) {
            // Needs a new trainer
            const createTrainerInput: CreateTrainerInput = {
                name: formattedPerson.name,
                location: formattedPerson.location,
                personId: user.id || roleObject.owner?.personId || roleObject.rider?.personId || "",
                barnId: barnId || roleObject.owner?.barnId || roleObject.rider?.barnId || "",
                createdBy: creator.id,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")             
            };
            const createTrainerResult = await createTrainer(createTrainerInput);
            if (!createTrainerResult.isSuccess) {
                const message = result.message + " Could not update the trainer role. " + createTrainerResult.message;
                result = {isSuccess: false, type: "Person", result: createTrainerResult.result, message: message};
            }
        } else {
            // Do nothing
        }
    }

    return result;
}