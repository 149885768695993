import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { UpdateEventInput } from "../../../API";
import { PersonContext } from "../../../context/PersonContext";
import { Contact, Event } from "../../../models";
import { getContactById } from "../../../utilities/contact/Contact";
import { formatPhoneNumberForDisplay } from "../../../utilities/contact/FormatPhoneNumber";
import { updateEvent } from "../../../utilities/events/Event";
import InformationBanner from "../../Banners/InformationBanner";
import ContactForm from "../../Contact/ContactForm";
import ErrorAlert from "../../Errors/ErrorAlert";
import Spinner from "../../Spinners/Spinner";

interface _Props {
    event: Event
}

const EventContactForm: React.FC<_Props> = ({event}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [eventContacts, setEventContacts] = useState<Contact[]>([]);
    const [selectedEventContact, setSelectedEventContact] = useState<Contact | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    async function getContact(contactId: string) {
        const queryResult = await getContactById(contactId);
        if (queryResult.isSuccess) {
            return queryResult.result;
        }
    }

    async function getEventContacts(contactIds: (string | null)[]) {
        const contactArray: Contact[] = [];
        for (let i = 0; i < contactIds.length; i++) {
            const contactId = contactIds[i];
            if (contactId) {
                const contact = await getContact(contactId);
                if (contact) contactArray.push(contact);
            }
        }
        setEventContacts(contactArray);
    }

    useEffect(() => {
        if (event.contactIds) getEventContacts(event.contactIds);
        else if (event.contactId) getEventContacts([event.contactId]);
    }, [event]);

    const handleSubmit = async (contact: Contact, functionType: string) => {
        setIsLoading(true);
        
        // Check if this was an edited Contact or a new Contact
        let index = eventContacts.findIndex(c => c.id === contact.id);
        
        let updatedContactIdArray: (string | null)[] = [];
        let updatedContactArray: Contact[] = [];

        if (functionType === "create") {
            // Created a Contact - update the contact array and update event's contact id array
            if (event && event.contactIds) {
                updatedContactIdArray = event.contactIds.concat([contact.id]);
            } else {
                updatedContactIdArray = [contact.id];
            }
            
            if (eventContacts) {
                updatedContactArray = eventContacts.concat([contact]);
            } else {
                updatedContactArray = [contact];
            }

            const eventInput: UpdateEventInput = {
                id: event.id,
                lastUpdatedBy: user.id,
                contactId: contact.id,
                contactIds: updatedContactIdArray
            }
            const updateResult = await updateEvent(eventInput);
            if (!updateResult.isSuccess) {
                setError("Error: could not attach contact to event. Please refresh the page and try again.");
            }
        } else if (functionType === "update") {
            // Edited - update the contact array
            updatedContactArray = [
                ...eventContacts.slice(0, index),
                contact,
                ...eventContacts.slice(index + 1)
            ];
        } else if (functionType === "delete") {
            // Deleted a Contact - update the contact array and update event's contact id array
            updatedContactArray = [
                ...eventContacts.slice(0, index),
                ...eventContacts.slice(index + 1)
            ];

            if (event && event.contactIds) {
                const currentContactIds = event.contactIds;
                currentContactIds.forEach(id => {
                    if (id !== contact.id) updatedContactIdArray.push(id);
                });
            }
            
            const eventInput: UpdateEventInput = {
                id: event.id,
                lastUpdatedBy: user.id,
                contactId: contact.id,
                contactIds: updatedContactIdArray
            }
            const updateResult = await updateEvent(eventInput);
            if (!updateResult.isSuccess) {
                setError("Error: could not attach contact to event. Please refresh the page and try again.");
            }
        }
        
        setEventContacts(updatedContactArray);
        setIsLoading(false);
        setShowModal(false);
        setSelectedEventContact(undefined);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {(eventContacts && eventContacts.length > 0) ?
                <>
                    <p className="description ion-text-info">Click on a contact to edit it.</p>
                    {eventContacts.map((eventContact, i) => (
                        <div key={i} onClick={() => {setShowModal(true); setSelectedEventContact(eventContact)}}>
                            <IonRow>
                                <IonCol>
                                    <h2 className="ion-text-wrap">{eventContact.name}</h2>
                                    <p className="ion-text-wrap text-darker">Phone Number: {eventContact.work && formatPhoneNumberForDisplay(eventContact.work)}</p>
                                    <p className="ion-text-wrap text-darker">Fax Number: {eventContact.fax && formatPhoneNumberForDisplay(eventContact.fax)}</p>
                                    <p className="ion-text-wrap text-darker">Email Address: {eventContact.workEmail}</p>
                                    <p className="ion-text-wrap text-darker">Facebook Page: {eventContact.facebookPage}</p>
                                    <p className="ion-text-wrap text-darker">Instagram: {eventContact.instagramHandle}</p>
                                    <p className="ion-text-wrap text-darker">Twitter: {eventContact.twitterHandle}</p>
                                </IonCol>
                            </IonRow>
                            <hr />
                        </div>
                    ))}
                </>
                :
                <>
                    <p>No Contact Info Found</p>
                </>
            }
            <IonRow>
                <IonCol>
                    <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                        Add Contact
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonModal backdropDismiss={false} isOpen={showModal} id="addEventContactModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        {selectedEventContact ? "Edit" : "Add"}  Contact
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => {
                                setShowModal(false);
                                setSelectedEventContact(undefined);
                            }}
                        >
                            <p id="closeContactModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            <InformationBanner info="Include any ways you would like exhibitors to contact you or follow your updates." />
                            <ContactForm onSubmit={handleSubmit} contact={selectedEventContact}/>
                        </>
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventContactForm;