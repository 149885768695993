import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonToggle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { CreateOrganizationStallTypeInput, UpdateOrganizationStallTypeInput } from "../../API";
import { Organization, OrganizationStallType, TaxRate } from "../../models";
import { createOrganizationStallType, deleteOrganizationStallType, updateOrganizationStallType } from "../../utilities/organizationStallType/OrganizationStallType";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import SelectCreateTaxRate from "../TaxRates/SelectCreateTaxRate";

interface _Props {
    organization?: (Organization | null)
    selectedOrganizationStallType?: (OrganizationStallType | null)
    onSubmit: Function
}

const StallTypeForm: React.FC<_Props> = ({selectedOrganizationStallType, organization, onSubmit}) => {
    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [pricePerStall, setPricePerStall] = useState(0);
    const [includeTax, setIncludeTax] = useState(true);
    const [taxA, setTaxA] = useState(0);
    const [taxRateA, setTaxRateA] = useState<TaxRate | null | undefined>();
    const [taxB, setTaxB] = useState(0);
    const [taxRateB, setTaxRateB] = useState<TaxRate | null | undefined>();
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (selectedOrganizationStallType) {
            setName(selectedOrganizationStallType.name || "");
            setDescription(selectedOrganizationStallType?.description || "");
            setPricePerStall(selectedOrganizationStallType.pricePerStall || 0);
            if (selectedOrganizationStallType.taxA) {
                setIncludeTax(true);
                setTaxA(selectedOrganizationStallType.taxA);
                setTaxB(selectedOrganizationStallType.taxB || 0);
            } else {
                setIncludeTax(false);
            }
        } else {
            clearForm();
        }
    }, [selectedOrganizationStallType]);

    const verifyForm = () => {
        if (!name) {
            setError("Please include an informative name so exhibitors know what these stalls are. Example: Overnight Stalls.");
            return false;
        }
        if (!pricePerStall) {
            setError("Please include a price per stall.");
            return false;
        }
        if (!taxA && taxB) {
            setError("Please use the Tax #1 option if you are going to only set one tax rate.");
            return false;
        }
        return true;
    }

    const clearForm = () => {
        setName("");
        setDescription("");
        setPricePerStall(0);
        setIncludeTax(true);
        setTaxA(0);
        setTaxB(0);
        selectedOrganizationStallType = undefined;
    }

    const handleSelectedTaxRateA = (taxRate: TaxRate) => {
        setTaxRateA(taxRate);
        if (taxRate.percentage) setTaxA(taxRate.percentage);
        setIsDisabled(false);
    }

    const handleSelectedTaxRateB = (taxRate: TaxRate) => {
        setTaxRateB(taxRate);
        if (taxRate.percentage) setTaxB(taxRate.percentage);
        setIsDisabled(false);
    }

    const handleCreateOrganizationStallTypes = async () => {
        if (organization) {
            let input: CreateOrganizationStallTypeInput = {
                organizationId: organization.id,
                name: name,
                pricePerStall: pricePerStall,
                description: description,
                type: includeTax ? "Taxable" : "Non-Taxable"
            };
            if (taxRateA) {
                input["taxA"] = includeTax ? taxA : 0;
                input["taxRateIdA"] = taxRateA.id;
                // input["organizationStallTypeTaxRateAId"] = taxRateA.id;
            }
            if (taxRateB) {
                input["taxB"] = includeTax ? taxB : 0;
                input["taxRateIdB"] = taxRateB.id;
                // input["organizationStallTypeTaxRateBId"] = taxRateB.id;
            }
            const createResult = await createOrganizationStallType(input);
            if (createResult.isSuccess) {
                onSubmit(true, createResult.result);
                clearForm();
            } else {
                setError("Sorry, we had a problem creating the stall type.");
            }
        } else {
            setError("Sorry, we could not find your organization.")
        }
    }

    const handleUpdateOrganizationStallType = async () => {
        if (organization && selectedOrganizationStallType) {
            let input: UpdateOrganizationStallTypeInput = {
                id: selectedOrganizationStallType.id,
                organizationId: organization.id,
                name: name,
                pricePerStall: pricePerStall,
                description: description,
                type: includeTax ? "Taxable" : "Non-Taxable",
            };
            if (taxRateA) {
                input["taxA"] = includeTax ? taxA : 0;
                input["taxRateIdA"] = taxRateA.id;
                // input["organizationStallTypeTaxRateAId"] = taxRateA.id;
            }
            if (taxRateB) {
                input["taxB"] = includeTax ? taxB : 0;
                input["taxRateIdB"] = taxRateB.id;
                // input["organizationStallTypeTaxRateBId"] = taxRateB.id;
            }
            const updateResult = await updateOrganizationStallType(input);
            if (updateResult.isSuccess) {
                onSubmit(false, updateResult.result);
                clearForm();
            } else {
                setError("Sorry, we had a problem updating the stall type.");
            }
        } else {
            setError("Sorry, we could not find your organization.")
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            if (selectedOrganizationStallType) {
                await handleUpdateOrganizationStallType();
            } else {
                await handleCreateOrganizationStallTypes();
            }
            setIsDisabled(true);
        }
        setIsLoading(false);
    }

    const handleDelete = async () => {
        setIsLoading(true);
        setError("");
        if (selectedOrganizationStallType) {
            const deleteResult = await deleteOrganizationStallType({id: selectedOrganizationStallType.id});
            if (deleteResult.isSuccess) {
                onSubmit(false, deleteResult.result);
                clearForm();
            } else {
                setError("Could not delete the organization stall type.");
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name of Stall Type</IonLabel>
                            <IonInput 
                                type="text"
                                placeholder="Ex: Friday Night Stall or Tack Stall"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <IonItem color="white">
                            <IonLabel position="stacked">Description</IonLabel>
                            <IonInput 
                                type="text"
                                placeholder="Include any information exhibitors should know."
                                value={description} 
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setDescription(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel position="stacked" className="description pl-3">Price Per Stall</IonLabel>
                        <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>$</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type="number"
                                    value={pricePerStall}
                                    min={0}
                                    max={9999}
                                    onChange={e => {
                                        setPricePerStall(parseFloat(e.target.value));
                                    }}
                                />
                        </InputGroup>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white" lines="none">
                            <IonLabel position="stacked">Charge Tax per stall?</IonLabel>
                            <IonToggle color="tertiary" checked={includeTax} onIonChange={e => setIncludeTax(e.detail.checked)}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                {includeTax && (
                    <>
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonLabel position="stacked" className="description pl-3">Tax #1</IonLabel>
                                <SelectCreateTaxRate organizationId={organization?.id} selectedValue={taxRateA?.id} onSelect={handleSelectedTaxRateA}/>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonLabel position="stacked" className="description pl-3">Tax #2</IonLabel>
                                <SelectCreateTaxRate organizationId={organization?.id} selectedValue={taxRateB?.id} onSelect={handleSelectedTaxRateB}/>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                <IonRow>
                    {selectedOrganizationStallType && (
                        <IonCol sizeMd="4">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    className="ion-margin-top"
                                    color="danger"
                                    expand="block"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </IonButton>
                            }
                        </IonCol>
                    )}
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default StallTypeForm;