import {
    IonButton,
    IonButtons,
    IonContent,
    IonIcon,
    IonModal,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import TaxForm from "./TaxForm";
import { close } from "ionicons/icons";
import { TaxRate } from "../../models";

interface _Props {
    taxRate?: (TaxRate | null)
    organizationId?: (string | null)
    show?: (Boolean | null)
    handleEdit: Function
}

const EditTaxRateModal: React.FC<_Props> = ({taxRate, organizationId, show, handleEdit}) => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (show) setShowModal(true);
        else setShowModal(false);
    }, [show]);

    const handleSubmit = (taxRate: TaxRate, type: string) => {
        handleEdit(taxRate);
        setShowModal(false);
    }

    return (
        <>
            <IonModal backdropDismiss={false} isOpen={showModal} id="taxRateModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Edit Tax Rate
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeTaxRateModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <TaxForm selectedTax={taxRate} organizationId={organizationId} onSubmit={handleSubmit} />
                </IonContent>
            </IonModal>
        </>
    );
};

export default EditTaxRateModal;
