import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ViewEventNavbar from "../../../components/Navbars/ViewEventNavbar";
import {Event} from "../../../models";
import ErrorAlert from "../../../components/Errors/ErrorAlert";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../utilities/events/Event";
import { phonePortraitOutline } from "ionicons/icons";
import EventAuditorSignUpButton from "../../../components/EventAuditor/EventAuditorSignUpButton";
import ViewEventAlertsTable from "../../../components/Event/Alerts/ViewEventAlertsTable";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventViewAlertsPage: React.FC<EventPageProps> = ({match}) => {
    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setCurrentEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        getEvent();
    }, [match, match.params.id]);
    
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent ? (currentEvent.nickname ? currentEvent.nickname : currentEvent.name) : "Event"} />
                {currentEvent && (
                    <>
                        <ViewEventNavbar active="about" event={currentEvent} />
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonRow id="alerts">
                            <IonCol sizeSm="12" sizeMd="6">
                                <IonCard mode="md" className="ion-padding bg-white stretch">
                                    <IonCardTitle>
                                        <IonItem lines="none">
                                            <IonIcon icon={phonePortraitOutline} slot="start"/>
                                            <IonLabel> Alerts</IonLabel>
                                        </IonItem>
                                    </IonCardTitle>
                                    <IonCardContent>
                                        <IonRow className="ion-justify-content-center">
                                            <p>Receive text messages about the show.</p>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center">
                                            <EventAuditorSignUpButton event={currentEvent} />
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow id="eventName" className="ion-align-items-stretch">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding pb-5 bg-white stretch card-large-height">
                                    <IonCardTitle>Alerts</IonCardTitle>
                                    <IonCardSubtitle>
                                        Scroll to see the alerts.
                                    </IonCardSubtitle>
                                    <IonContent color="white">
                                        <IonCardContent>
                                            <ViewEventAlertsTable event={currentEvent} />
                                        </IonCardContent>
                                    </IonContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default EventViewAlertsPage;