import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { Barn, Event, Horse, Owner } from "../../models";
import { createOwner, updateOwner} from "../../utilities/owner/Owner";
import ErrorAlert from "../Errors/ErrorAlert";
import SelectState from "../Address/SelectState";
import { updateHorse } from "../../utilities/horse/Horse";
import {  CreateAddressInput, CreateOwnerInput, UpdateAddressInput, UpdateHorseInput, UpdateOwnerInput } from "../../API";
import SelectCountry from "../Address/SelectCountry";
import { countryList } from "../Address/Country";
import { createAddress, updateAddress } from "../../utilities/address/Address";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    owner?: (Owner | null)
    horse?: (Horse | null)
    selectedBarn?: (Barn | null)
    event?: (Event | null)
    onChange: Function
}

const QuickAddOwnerForm: React.FC<_Props> = ({owner, horse, selectedBarn, event, onChange}) => {
    const user = useContext(PersonContext);
    
    const [barn, setBarn] = useState<Barn | null | undefined>();
    const [name, setName] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [error, setError] = useState("");

    const clearForm = () => {
        setName("");
        setCity("");
        setState("");
    }

    useEffect(() => {
        clearForm();
    }, []);

    useEffect(() => {
        setBarn(selectedBarn);
    }, [selectedBarn]);

    useEffect(() => {
        if (owner) {
            // Set owner details
            setName(owner.name);
            if (owner.address) {
                if (owner.address.streetAddress1) setStreetAddress(owner.address.streetAddress1);
                if (owner.address.city) setCity(owner.address.city);
                if (owner.address.provState) setState(owner.address.provState);
                if (owner.address.zip) setZip(owner.address.zip);
                if (owner.address.country) setCountry(owner.address.country);
            } else if (owner.location) {
                const locationArray = owner.location.split(",");
                setCity(locationArray[0].trim());
                setState(locationArray[1].trim());
            }
        } else {
            clearForm();
        }
    }, [owner]);

    const verifyForm = () => {
        if (!name) {
            setError("Please include a name for the owner");
            return false;
        }
        if (event && event.entryOptions?.requiresCogginsData) {
            if (!streetAddress) {
                setError("Please include the owner's street address.");
                return false;
            }
            if (!city) {
                setError("Please include the owner's city.");
                return false;
            }
            if (!state) {
                setError("Please include the owner's providence or state.");
                return false;
            }
            if (!zip) {
                setError("Please include the owner's zip code.");
                return false;
            }
            if (!country) {
                setError("Please include the owner's country.");
                return false;
            }
        }
        return true;
    }

    const handleProvStateInputChange = (value: string) => {
        if (value && value !== "-") setState(value);
    }

    const handleCountryChange = (value: string) => {
        if (value) {
            setCountry(value);
        }
    }

    const handleAddress = async (addressId?: (string | null)) => {
        if (addressId) {
            let addressInput: UpdateAddressInput = {
                id: addressId
            };
            if (name) addressInput.name = name;
            if (streetAddress) addressInput.streetAddress1 = streetAddress;
            if (city) addressInput.city = city;
            if (state) addressInput.provState = state;
            if (zip) addressInput.zip = zip;
            if (country) addressInput.country = country;
            const updateResult = await updateAddress(addressInput);
            if (updateResult.isSuccess) {
                return updateResult.result;
            } else {
                setError("Could not update the owner's address.");
                return null;
            }
        } else {
            let addressInput: CreateAddressInput = {};
            if (name) addressInput.name = name;
            if (streetAddress) addressInput.streetAddress1 = streetAddress;
            if (city) addressInput.city = city;
            if (state) addressInput.provState = state;
            if (zip) addressInput.zip = zip;
            if (country) addressInput.country = country;
            const createResult = await createAddress(addressInput);
            if (createResult.isSuccess) {
                return createResult.result;
            } else {
                setError("Could not create the owner's address.");
                return null;
            }
        }
    }

    const handleUpdateHorseOwner = async (horse: Horse, owner: Owner) => {
        const input: UpdateHorseInput = {
            id: horse.id,
            ownerId: owner.id,
            // horseOwnerId: owner.id,
            ownerName: owner.name
        };
        await updateHorse(input);
    }

    const handleCreateOwner = async () => {
        const location = city + ", " + state;
        let ownerInput: CreateOwnerInput = {
            name: name,
            createdBy: user.id,
            location: location,
            barnId: barn?.id
        };
        const address = await handleAddress();
        if (address) {
            ownerInput.addressId = address.id;
            // ownerInput.ownerAddressId = address.id;
        }
        const createResult = await createOwner(ownerInput);
        if (createResult.isSuccess) {
            if (horse) {
                handleUpdateHorseOwner(horse, createResult.result);
            }
            onChange(createResult.result);
        } else {
            setError(createResult.message);
        }
    }

    const handleUpdateOwner = async () => {
        if (owner) {
            const location = city + ", " + state;
            const input: UpdateOwnerInput = {
                id: owner.id,
                name: name,
                location: location,
                barnId: barn?.id
            };
            const address = await handleAddress(owner?.addressId);
            if (address) {
                input.addressId = address.id;
                // input.ownerAddressId = address.id;
            }
            const updateResult = await updateOwner(input);
            if (updateResult.isSuccess) {
                if (horse) {
                    handleUpdateHorseOwner(horse, updateResult.result);
                }
                onChange(updateResult.result);
            } else {
                setError(updateResult.message);
            }
        } else {
            setError("No owner to edit.");
        }
    }

    const handleAddOwner = async () => {
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            await handleCreateOwner();
        }
    }

    const handleEditOwner = async () => {
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            await handleUpdateOwner();
        }
    }

    return (
        <>
            {error && <ErrorAlert error={error} />}
            <IonRow className="ion-padding-top">
                <IonCol size="12">
                    <IonItem color="white">
                        <IonLabel position="stacked">Name <RequiredInputIndicator /></IonLabel>
                        <IonInput 
                            type="text"
                            value={name}
                            aria-required={true}
                            onIonChange={e => {
                                setName(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            {event && event.entryOptions?.requiresCogginsData && (
                <>
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="12">
                            <IonItem color="white">
                                <IonLabel position="stacked">Street Address <RequiredInputIndicator showWordRequired /></IonLabel>
                                <IonInput 
                                    type="text"
                                    value={streetAddress}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setStreetAddress(e.detail.value!);
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </>
            )}
            <IonRow className="ion-padding-top">
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="stacked">City {(event && event.entryOptions?.requiresCogginsData) && <RequiredInputIndicator showWordRequired />}</IonLabel>
                        <IonInput 
                            type="text"
                            value={city}
                            aria-required={true}
                            onIonChange={e => {
                                setCity(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <SelectState isRequired={(event && event.entryOptions?.requiresCogginsData)} selectedValue={state} onInputChange={handleProvStateInputChange} />
                </IonCol>
            </IonRow>
            {event && event.entryOptions?.requiresCogginsData && (
                <>
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonItem color="white">
                                <IonLabel position="stacked">Zip Code <RequiredInputIndicator showWordRequired /></IonLabel>
                                <IonInput 
                                    type="text"
                                    value={zip}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setZip(e.detail.value!);
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6">
                            <SelectCountry isRequired={true} countryValue={country} onInputChange={handleCountryChange} />
                        </IonCol>
                    </IonRow>
                </>
            )}
            <IonRow className="ion-justify-content-center">
                <IonCol sizeMd="4" className="ion-text-center">
                    <IonButton
                        className="ion-margin-top"
                        color="tertiary"
                        expand="block"
                        onClick={owner ? handleEditOwner : handleAddOwner}
                    >
                        {owner ? "Edit " + owner.name : "Add Owner"}
                    </IonButton>
                </IonCol>
            </IonRow>
        </>
    );
};

export default QuickAddOwnerForm;