import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React from "react";
import CreateBarnForm from "../../../../components/Barn/CreateBarnForm";

const CreateBarnPage: React.FC = () => {
    
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Create Barn" />
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeXs="12" sizeMd="10">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Create Barn</IonCardTitle>
                            <IonCardContent>
                                <CreateBarnForm />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default CreateBarnPage;