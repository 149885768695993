import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useState } from "react";
import { Contact } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { createContact, deleteContact, updateContact } from "../../utilities/contact/Contact";
import { formatTwilioNumber, isValidPhoneNumber } from "../../utilities/contact/FormatPhoneNumber";
import { formatURL, isFacebookPageURLValid } from "../../utilities/contact/FormatURL";
import { CreateContactInput, UpdateContactInput } from "../../API";

interface _Props {
    contact?: (Contact | null)
    onSubmit: Function
}

const ContactForm: React.FC<_Props> = ({contact, onSubmit}) => {

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [name, setName] = useState((contact && contact.name) ? contact.name : "Event Contact");
    const [workPhoneNumber, setWorkPhoneNumber] = useState((contact && contact.work) ? contact.work : "");
    const [workEmail, setWorkEmail] = useState((contact && contact.workEmail) ? contact.workEmail : "");
    const [faxNumber, setFaxNumber] = useState((contact && contact.fax) ? contact.fax : "");
    const [facebookPage, setFacebookPage] = useState((contact && contact.facebookPage) ? contact.facebookPage : "");
    const [instagramHandle, setInstagramHandle] = useState((contact && contact.instagramHandle) ? contact.instagramHandle : "");
    const [twitterHandle, setTwitterHandle] = useState((contact && contact.twitterHandle) ? contact.twitterHandle : "");
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState<string>("");

    const verifyForm = () => {
        try {
            if (workPhoneNumber) isValidPhoneNumber(workPhoneNumber);
            if (faxNumber) isValidPhoneNumber(faxNumber);
            if (facebookPage) isFacebookPageURLValid(facebookPage);
            return true;
        } catch (error: any) {
            setError(error);
            return false;
        }
    }

    const handleCreateContact = async () => {
        try {
            let contactInput: CreateContactInput = {
                name: "Event Contact"
            };
            if (name) contactInput["name"] = name;
            if (workPhoneNumber) contactInput["work"] = formatTwilioNumber(workPhoneNumber);
            if (faxNumber) contactInput["fax"] = formatTwilioNumber(faxNumber);
            if (workEmail) contactInput["workEmail"] = workEmail;
            if (facebookPage) contactInput["facebookPage"] = formatURL(facebookPage);
            if (instagramHandle) contactInput["instagramHandle"] = instagramHandle;
            if (twitterHandle) contactInput["twitterHandle"] = twitterHandle;
            const createResult = await createContact(contactInput);
            if (createResult.isSuccess) {
                onSubmit(createResult.result, "create");
            } else {
                const message = createResult.message + " - " + createResult.result;
                setError(message);
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleEditContact = async () => {
        try {
            if (contact) {
                let contactInput: UpdateContactInput = {
                    id: contact.id
                };
                if (name) contactInput["name"] = name;
                if (workPhoneNumber) contactInput["work"] = formatTwilioNumber(workPhoneNumber);
                if (faxNumber) contactInput["fax"] = formatTwilioNumber(faxNumber);
                if (workEmail) contactInput["workEmail"] = workEmail;
                if (facebookPage) contactInput["facebookPage"] = formatURL(facebookPage);
                if (instagramHandle) contactInput["instagramHandle"] = instagramHandle;
                if (twitterHandle) contactInput["twitterHandle"] = twitterHandle;
                const updateResult = await updateContact(contactInput);
                if (updateResult.isSuccess) {
                    onSubmit(updateResult.result, "update");
                } else {
                    const message = updateResult.message + " - " + updateResult.result.errors[0].message;
                    setError(message);
                }
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleDeleteContact = async () => {
        try {
            if (contact) {
                let contactInput: UpdateContactInput = {
                    id: contact.id
                };
                const deleteResult = await deleteContact(contactInput);
                if (deleteResult.isSuccess) {
                    onSubmit(deleteResult.result, "delete");
                } else {
                    const message = deleteResult.message + " - " + deleteResult.result.errors[0].message;
                    setError(message);
                }
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) {
            setError("");
            await handleCreateContact();
        }
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name of this Contact</IonLabel>
                            <IonInput 
                                type="text"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Phone Number</IonLabel>
                            <IonInput 
                                type="tel"
                                value={workPhoneNumber}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setWorkPhoneNumber(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Fax Number</IonLabel>
                            <IonInput 
                                type="tel"
                                value={faxNumber}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setFaxNumber(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Email</IonLabel>
                            <IonInput 
                                type="email"
                                value={workEmail}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setWorkEmail(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Facebook Page</IonLabel>
                            <IonInput 
                                type="url"
                                value={facebookPage}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setFacebookPage(e.detail.value!);
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Instagram Account Name</IonLabel>
                            <IonInput 
                                type="text"
                                value={instagramHandle}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setInstagramHandle(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Twitter Account Name</IonLabel>
                            <IonInput 
                                type="text"
                                value={twitterHandle}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setTwitterHandle(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    {contact && (
                        <IonCol sizeMd="6">
                            {isLoading ?
                                <Spinner />
                                :
                                <IonButton
                                    className="ion-margin-top"
                                    color="danger"
                                    expand="block"
                                    onClick={handleDeleteContact}
                                >
                                    Delete
                                </IonButton>
                            }
                        </IonCol>
                    )}
                    <IonCol sizeMd="6">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={contact ? handleEditContact : handleSubmit}
                            >
                                {isDisabled ? "Saved" : contact ? "Save Changes" : "Create Contact"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default ContactForm;