import {
    IonHeader,
    IonLabel,
    IonSegment,
    IonSegmentButton,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event } from "../../models";

interface _Props {
    active: string
    event?: Event
    onSubmit: Function
}

const EntryFormNavbar: React.FC<_Props> = ({active, event, onSubmit}) => {

    const [currentActiveBtn, setCurrentActiveBtn] = useState(active || "info");
    const [currentEvent, setCurrentEvent] = useState<Event | null | undefined>();

    useEffect(() => {
        setCurrentActiveBtn(active);
    }, [active]);

    useEffect(() => {
        setCurrentEvent(event);
    }, [event]);

    const handleSelection = (selection: string) => {
        setCurrentActiveBtn(selection);
        onSubmit(selection);
    }

    return (
        <IonHeader id="entryFormMenu">
            <IonSegment color="primary" value={currentActiveBtn || active} scrollable={true} onIonChange={e => handleSelection(e.detail?.value || "info")}>
                <IonSegmentButton type="button" value="info">
                    <IonLabel>{event?.type === "camp" ? "Basic" : "Entry"} Info</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton type="button" value="classes">
                    <IonLabel>{event?.type === "camp" ? "Sections" : "Classes"}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton type="button" value="stalls">
                    <IonLabel>Stabling</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton type="button" value="fees">
                    <IonLabel>Fees</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton type="button" value="rules">
                    <IonLabel>Rules</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton type="button" value="submit">
                    <IonLabel>Submit</IonLabel>
                </IonSegmentButton>
            </IonSegment>
        </IonHeader>
    );
};

export default EntryFormNavbar;
