import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { CreateEventFilterInput } from "../../API";
import { Event, EventFilter, Organization } from "../../models";
import { createEventFilter } from "../../utilities/eventFilter/EventFilter";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import EventFilterOptionForm from "./EventFilterOptionForm";

interface _Props {
    event?: Event
    organization?: Organization
    eventFilter?: EventFilter
    onSubmit: Function
}

const EventFilterForm: React.FC<_Props> = ({eventFilter, event, organization, onSubmit}) => {
    const [name, setName] = useState(eventFilter?.name || "");
    const [options, setOptions] = useState<(string | null)[] | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (eventFilter) {
            setOptions(eventFilter.options);
        }
    }, [eventFilter]);

    const verifyForm = () => {
        if (!name) {
            setError("Error: include a name.");
            return false;
        }
        if (!options) {
            setError("Error: include options.");
            return false;
        }
        return true;
    }

    const handleOptionAdd = (optionName: string) => {
        if (options && options.length) {
            const newOptionArray = options.concat(optionName);
            setOptions(newOptionArray);
        } else {
            setOptions([optionName]);
        }
    }

    const handleOptionDelete = (optionName: string) => {
        if (options) {
            let newArray: (string | null)[] = [];
            options.forEach(element => {
                if (element !== optionName) newArray.push(element);
            });
            setOptions(newArray)
        } else {
            setOptions([]);
        }
    }

    const handleCreate = async () => {
        if (options) {
            const input: CreateEventFilterInput = {
                eventId: event?.id || "",
                organizationID: organization?.id || "",
                name: name,
                options: options
            };
            const createResult = await createEventFilter(input);
            if (createResult.isSuccess) {
                onSubmit(createResult.result);
            } else {
                setError("Error: could not create the filter.");
            }
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyForm();
        if (isValid) {
            await handleCreate();
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name</IonLabel>
                            <IonInput 
                                type="text"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        {options && options.map((option: (string | null), index: number) => (
                            <div key={index}>
                                <EventFilterOptionForm option={option || undefined} label={"Option " + (index+1)} onDelete={handleOptionDelete}/>
                            </div>
                        ))}
                        <EventFilterOptionForm option={undefined} label={"New Option"} onAdd={handleOptionAdd}/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                Save
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default EventFilterForm;