import {
    IonCol,
    IonDatetime,
    IonItem,
    IonLabel,
    IonRow,
    isPlatform,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import moment from "moment";
import { isWindows } from "../utilities/platform/Platform";
import { formatTimeTo24Hour, formatTimeToAMPM } from "../utilities/time/Time";

interface _Props {
    onChange: Function
    label?: string
    position?: ("fixed" | "stacked" | "floating" | null | undefined)
    selectedTime?: (string | null)
}

const TimePicker: React.FC<_Props> = ({label, selectedTime, position, onChange}) => {

    const [time, setTime] = useState<string | null | undefined>();
    const [currentMoment, setCurrentMoment] = useState<any>("");

    useEffect(() => {
        if (selectedTime) {
            const formattedTime = formatTimeTo24Hour(selectedTime);
            setCurrentMoment(formattedTime);
        } else {
            setCurrentMoment(undefined);
        }
    }, [selectedTime]);

    const handleMobileInput = (input: string) => {
        const formattedTime = moment(input).format("h:mm a");
        setTime(formattedTime);
        onChange(formattedTime);
    }

    const handleDesktopInput = (input: string) => {
        setCurrentMoment(input);
        if (input === "") {
            onChange("");
        } else {
            const formattedTime = formatTimeToAMPM(input);
            onChange(formattedTime);
        }
    }

    return (
        <>
            <IonRow className="ion-justify-content-center">
                <IonCol size="12" className="ion-no-padding">
                   {(isWindows() || isPlatform("desktop")) ?
                        <FormGroup>
                            {label && (
                                <Label className="form-control-label">
                                    {label}
                                </Label>
                            )}
                            <Input
                                type="time"
                                name="time"
                                value={currentMoment}
                                placeholder="time placeholder"
                                onChange={e =>
                                    handleDesktopInput(e.target.value)
                                }
                            />
                        </FormGroup>
                        :
                        <IonItem color="white">
                            {label && <IonLabel position={position || "stacked"}>{label}</IonLabel>}
                            <IonDatetime display-format="h:mm A" picker-format="h:mm A" value={time} onIonChange={e => handleMobileInput(e.detail.value!)}></IonDatetime>
                        </IonItem>
                    }
                </IonCol>
            </IonRow>
        </>
    );
};

export default TimePicker;
