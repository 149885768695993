import { API } from "aws-amplify";
import { Result } from "../../interfaces/Result";
import { Person } from "../../models";
import { getAllPeople } from "../person/Person";

export async function addContactToHubspot(email: string) {
    try {
        let queryParams = "";
        queryParams = queryParams + "?email=" + email.toLowerCase();
        const path = "/hubspot/sync/contact" + queryParams;
        const response = await API.get("Hubspot", path, {});
        const responseBody = JSON.parse(response.body);
        
        let result: Result = {isSuccess: true, type: "Hubspot", result: responseBody, message: "Successfully added the contacts."}
        return result;
    } catch (error) {
        let result: Result = {isSuccess: false, type: "Hubspot", result: error, message: "Error occurred while trying to add the contacts." }
        return result;
    }
}

export async function updateContactInHubspot(email: string, firstName?: (string | null), lastName?: (string | null), roles?: (string | null)) {
    try {
        let queryParams = "";
        queryParams = queryParams + "?email=" + email;
        if (firstName) queryParams = queryParams + "&firstName=" + firstName;
        if (lastName) queryParams = queryParams + "&lastName=" + lastName;
        if (roles) queryParams = queryParams + "&roles=" + roles;
        const path = "/hubspot/sync/contact" + queryParams;
        const response = await API.get("Hubspot", path, {});
        const responseBody = JSON.parse(response.body);
        
        let result: Result = {isSuccess: true, type: "Hubspot", result: responseBody, message: "Successfully added the contacts."}
        return result;
    } catch (error) {
        let result: Result = {isSuccess: false, type: "Hubspot", result: error, message: "Error occurred while trying to add the contacts." }
        return result;
    }
}

export async function syncContactsInHubspot() {
    try {
        let resultArray: any[] = [];

        const queryResult = await getAllPeople();

        if (queryResult.isSuccess) {
            const allPeople = queryResult.result;
            if (allPeople && allPeople.length > 0) {

                for (let i = 0; i < allPeople.length; i++) {
                    const person: Person = allPeople[i];
                    
                    let queryParams = "";
                    queryParams = queryParams + "?email=" + person.email;
                    if (person.firstName) queryParams = queryParams + "&firstName=" + person.firstName;
                    if (person.lastName) queryParams = queryParams + "&lastName=" + person.lastName;
                    if (person.roles) queryParams = queryParams + "&roles=" + person.roles;
                    const path = "/hubspot/sync/contact" + queryParams;
                    const response = await API.get("Hubspot", path, {});
                    const responseBody = JSON.parse(response.body);
                    if (responseBody.success && responseBody.data) {
                        resultArray.push(responseBody.data);
                    }
                }

            } else {
                let result: Result = {isSuccess: false, type: "Hubspot", result: null, message: "Found no people to sync." }
                return result;
            }
        } else {
            let result: Result = {isSuccess: false, type: "Hubspot", result: null, message: "Found no people to sync." }
            return result;
        }
        
        let result: Result = {isSuccess: true, type: "Hubspot", result: resultArray, message: "Successfully synced the contacts."}
        return result;
    } catch (error) {
        let result: Result = {isSuccess: false, type: "Hubspot", result: error, message: "Error occurred while trying to sync the contacts." }
        return result;
    }
}
