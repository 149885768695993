import React, { useState } from "react";
import Spinner from "../Spinners/Spinner";
import ErrorAlert from "../Errors/ErrorAlert";
import { isWindows } from "../../utilities/platform/Platform";
import { formatStartEndDatesStrings } from "../../utilities/dates/FormatDates";
import { Post, PostStatus } from "../../models";
import DataTable from "react-data-table-component";
import { trashOutline, createOutline, close } from "ionicons/icons";
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
} from "@ionic/react";
import { deletePost, getPostsByOrganizationId } from "../../utilities/posts/Post";
import { useHistory } from "react-router";

interface _Props {
    posts: Post[];
    setPosts: Function;
}

const DisplayPostTable: React.FC<_Props> = ({ posts, setPosts }) => {

    const columns = [{
        id: 1,
        name: "Title",
        selector: (row: Post) => row.title,
        width: isWindows() || isPlatform("desktop") ? "40%" : "50%",
    }, {
        id: 2,
        name: "Date Posted",
        selector: (row: Post) => formatStartEndDatesStrings(row.createdOn),
        center: true
    }, {
        id: 3,
        name: "Status",
        center: true,
        cell: (row: Post) =>
            row.status === PostStatus.PUBLISHED ? (
                <IonText color="success">{row.status}</IonText>
            ) : (
                <IonText color="dark">{row.status}</IonText>
            )
    }, {
        name: "Edit",
        center: true,
        button: true,
        cell: (row: Post) => 
            isWindows() || isPlatform("desktop") ? (
                <IonButton
                    className="edit-post-btn"
                    onClick={() => handleEditClick(row.id)}
                    color="tertiary"
                >
                    Edit
                </IonButton>
            ) : (
                <IonIcon
                    size="large"
                    slot="start"
                    icon={createOutline}
                    color="tertiary"
                    onClick={() => handleEditClick(row.id)}
                />
            )
        
    }, {
        name: "Remove",
        center: true,
        button: true,
        cell: (row: Post) => 
            isWindows() || isPlatform("desktop") ? (
                <IonButton
                    className="remove-post-btn"
                    onClick={() => handleRemoveClick(row)}
                    color="danger"
                >
                    Remove
                </IonButton>
            ) : (
                <IonIcon
                    size="large"
                    slot="start"
                    icon={trashOutline}
                    color="danger"
                    onClick={() => handleRemoveClick(row)}
                />
            )
        
    }];

    const tableCustomStyles = {
        headCells: {
            style: {
                fontSize: "14px",
                fontWeight: "bold",
                width: "12rem"
            }
        }
    };

    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [postData,setPostData] = useState<Post>();

    const handleEditClick = (id: string) => {
        history.push("/index/staff/organization/post/edit/" + id);
    };

    const handleRemoveClick = async (row: Post) => {
        setShowModal(true);
        setPostData(row);
    };

    const handleDeletePost = async () => {
        setError("");
        setIsLoading(true);
        if (postData?.id) {
            const deletePostData = {
                id: postData.id,
            };
            const deletePostResult = await deletePost(deletePostData);
            if (deletePostResult.isSuccess) {
                if (postData?.organizationId) {
                    const post = await getPostsByOrganizationId(
                        postData.organizationId
                    );
                    setPosts(post.result);
                }
                setError("");
            } else {
                setError(deletePostResult.message);
            }
        }
        setShowModal(false);
        setIsLoading(false);
    };

    return (
        <React.Fragment>
            <IonModal backdropDismiss={false} isOpen={showModal}>
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">Delete Post</IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            id="delete-org-modal-close-icon"
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p
                                id="closeAddressModalBtn"
                                className="font-weight-normal text-medium text-capitalize"
                            >
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <h3>
                                Are you sure you want to permanently delete this
                                post:{" "}
                            </h3>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10" className="ion-text-center">
                            <h2>{postData?.title}</h2>
                            <IonText>
                                Posted on{" "}
                                {formatStartEndDatesStrings(
                                    postData?.createdOn
                                )}
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonContent>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <IonRow className="ion-justify-content-center ion-padding-top">
                        <IonCol size="6" className="ion-text-center">
                            <IonButton
                                id="delete-org-modal-cancel-btn"
                                expand="block"
                                color="light"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </IonButton>
                        </IonCol>
                        <IonCol size="6" className="ion-text-center">
                            <IonButton
                                id="delete-post-modal-delete-btn"
                                expand="block"
                                color="danger"
                                onClick={handleDeletePost}
                            >
                                Delete
                            </IonButton>
                        </IonCol>
                    </IonRow>
                )}
            </IonModal>
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    {posts && posts.length ? (
                        <DataTable
                            columns={columns}
                            data={posts}
                            pagination
                            customStyles={tableCustomStyles}
                            highlightOnHover
                        />
                    ) : (
                        <p>No Posts Found</p>
                    )}
                </>
            )}
        </React.Fragment>
    );
};

export default DisplayPostTable;
