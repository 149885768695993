import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonToggle,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import { createEventBreak, updateEventBreak} from "../../utilities/eventBreak/EventBreak";
import { CreateEventBreakInput, UpdateEventBreakInput } from "../../API";
import { Event, EventBreak } from "../../models";
import SuccessBanner from "../Banners/SuccessBanner";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    event: Event
    eventBreak?: EventBreak
    onSubmit: Function
}

const EventBreakForm: React.FC<_Props> = ({event, eventBreak, onSubmit}) => {
    const user = useContext(PersonContext);

    const [name, setName] = useState("");
    const [time, setTime] = useState("");
    const [shouldDisplayOnSchedule, setShouldDisplayOnSchedule] = useState(true);
    const [currentBreak, setCurrentBreak] = useState<EventBreak | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");

    useEffect(() => {
        if (eventBreak) setCurrentBreak(eventBreak);
        else setCurrentBreak(undefined);
    }, [eventBreak]);

    const verifyForm = () => {
        try {
            if (!name) {
                setError("You must include a name.");
                return false;
            }
            return true;
        } catch (error: any) {
            setError(error);
            return false;
        }
    }

    const handleCreateEventBreak = async () => {
        try {
            const input: CreateEventBreakInput = {
                name: name,
                eventId: event.id,
                time: time,
                isDisplayedOnSchedule: shouldDisplayOnSchedule,
                createdBy: user.id
            };
            const createResult = await createEventBreak(input);
            if (createResult.isSuccess) {
                const newBreak: EventBreak = createResult.result;
                const msg = "Created the new break: " + newBreak.name;
                setSuccess(msg); 
                setCurrentBreak(newBreak);
                onSubmit(newBreak);
                setCurrentBreak(undefined);
            } else {
                const msg = "Could not create the new break: " + name;
                setError(msg);
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleUpdateEventBreak = async () => {
        try {
           if (currentBreak) {
                const input: UpdateEventBreakInput = {
                    id: currentBreak.id,
                    name: name,
                    time: time,
                    isDisplayedOnSchedule: shouldDisplayOnSchedule,
                };
                const updateResult = await updateEventBreak(input);
                if (updateResult.isSuccess) {
                    const updatedBreak: EventBreak = updateResult.result;
                    const msg = "Updated the break: " + updatedBreak.name;
                    setSuccess(msg); 
                    setCurrentBreak(updatedBreak);
                    onSubmit(updatedBreak);
                    setCurrentBreak(undefined);
                } else {
                    const msg = "Could not update the break: " + name;
                    setError(msg);
                }
           } else {
               setError("No break found.");
           }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        const isValid = verifyForm();
        if (isValid) {
            setError("");
            if (currentBreak) await handleUpdateEventBreak();
            else await handleCreateEventBreak();
        }
        setIsLoading(false);
    }

    return (
        <>
            {success && <SuccessBanner width="12" success={success}/>}
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name <RequiredInputIndicator showWordRequired/></IonLabel>
                            <IonInput 
                                type="text"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Time it will take (minutes)</IonLabel>
                            <IonInput 
                                type="number"
                                value={time}
                                aria-required={true}
                                onIonChange={e => {
                                    setTime(e.detail.value! ? e.detail.value.toString() : "")
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Display break on the schedule exhibitors view?</IonLabel>
                            <IonToggle color="success" checked={shouldDisplayOnSchedule} onClick={() => setShouldDisplayOnSchedule(!shouldDisplayOnSchedule)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {currentBreak ? "Edit Break": "Create Break"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default EventBreakForm;