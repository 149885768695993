import { API } from 'aws-amplify';
import { Result } from "../../interfaces/Result";

/**
 * Customer is an individual Stripe customer
 * More details here: https://stripe.com/docs/api/sources/attach
 * 
 * @param {string} customerid 
 * @param {string} sourceid 
 */
export async function createTransfer(expressAccountid: string, amount: number, ) {
    try {
        let queryParams = "?destination=" + expressAccountid;
        queryParams = queryParams + "&transfer_amount=" + amount*100;
        const path = "/payments/create-transfer" + queryParams;
        
        const response = await API.get("Payments", path, {});

        const responseBody = JSON.parse(response.body);
        const requestResult = responseBody.response;

        if (responseBody.success) {
            let result: Result = {isSuccess: true, type: "Stripe", result: requestResult, message: "Successfully created the transfer."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "Stripe", result: requestResult, message: "Could not create the transfer."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Could not create the transfer."};
        return result; 
    }
}

/**
 * More details here: https://stripe.com/docs/payments/quickstart
 * @param connectAccountId string - organization's Stripe connect account id
 * @param amount number - total amount charged to the customer
 * @param feeAmount number - total RingSide Pro fee to charge (will be removed from amount)
 * @param customerEmail string - Person making the payment's email address for the receipt
 * @param description string - The description that will show in Stripe for each payment
 * @returns 
 */
export async function createTransferPaymentIntent(connectAccountId: string, amount: number, feeAmount: number, customerEmail?: string, description?: string) {
    try {
        // All API requests expect amounts to be provided in a currency’s smallest unit. 
        // In USD, the smallest unit is a cent ($0.01)
        // For example, to charge $10.00 USD, provide an amount value of 1000 (that is, 1000 cents).
        const formattedAmount = amount*100;
        const formattedFeeAmount = feeAmount*100;

        // Format the parameters
        let queryParams = "?transferAccountId=" + connectAccountId;
        queryParams = queryParams + "&amount=" + formattedAmount;
        queryParams = queryParams + "&feeAmount=" + formattedFeeAmount;
        queryParams = queryParams + "&receiptEmail=" + customerEmail;
        queryParams = queryParams + "&description=" + description;

        const path = "/payments/create-payment" + queryParams;
        
        const response = await API.get("Payments", path, {});

        const responseBody = JSON.parse(response.body);
        const requestResult = responseBody.client_secret;

        if (responseBody.success) {
            let result: Result = {isSuccess: true, type: "Stripe", result: requestResult, message: "Successfully created the transfer payment intent."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "Stripe", result: requestResult, message: "Could not create the transfer payment intent."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Could not create the transfer payment intent."};
        return result; 
    }
}