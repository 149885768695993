import React, {useEffect, useState} from "react";
import { EventResult } from "../../models";
import { Table } from "reactstrap";
import Spinner from "../Spinners/Spinner";
import { calculatePointsFromTable } from "../../utilities/pointTable/CaculatePointsFromTable";
import { IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonIcon, IonModal, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { chevronDown, chevronUp, close } from "ionicons/icons";
import { deleteEventResult } from "../../utilities/eventResult/EventResult";
import ErrorAlert from "../Errors/ErrorAlert";
import moment from "moment";

interface _Props {
    eventResults?: (EventResult[] | null)
    isEditable?: (Boolean | null)
}

enum sortOptions {
    set1 = "SortBySetSmallToLarge",
    set2 = "SortBySetLargeToSmall",
    place1 = "SortByPlaceSmallToLarge",
    place2 = "SortByPlaceLargeToSmall",
    entryNumber1 = "SortByEntryNumberSmallToLarge",
    entryNumber2 = "SortByEntryNumberLargeToSmall"
}

interface FormattedEventResult {
    id: string
    eventResult: EventResult
    isChecked: Boolean
}

const VerifyEventResultsTable: React.FC<_Props> = ({eventResults, isEditable}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [displayDeleteBtn, setDisplayDeleteBtn] = useState(false);
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
    const [formattedData, setFormattedResults] = useState<FormattedEventResult[] | null | undefined>();
    const [currentSortOption, setCurrentSortOption] = useState(sortOptions.set1);
    const [error, setError] = useState("");

    function sortEventResults(formattedEventResults: FormattedEventResult[], currentSortOption?: sortOptions) {
        if (currentSortOption === sortOptions.set1 || currentSortOption === null || currentSortOption === undefined) {
            const sorted = formattedEventResults.sort(function(a: FormattedEventResult, b: FormattedEventResult): number {
                const setResult = (parseInt(a.eventResult.resultSet || "0") || 0) - (parseInt(b.eventResult.resultSet || "0") || 0);
                const placeResult = (a.eventResult.place || 0) - (b.eventResult.place || 0);
                return setResult || placeResult;
            });
            setFormattedResults(sorted);
        } else if (currentSortOption === sortOptions.set2) {
            const sorted = formattedEventResults.sort(function(a: FormattedEventResult, b: FormattedEventResult): number {
                const setResult = (parseInt(b.eventResult.resultSet || "0") || 0) - (parseInt(a.eventResult.resultSet || "0") || 0);
                const placeResult = (a.eventResult.place || 0) - (b.eventResult.place || 0);
                return setResult || placeResult;
            });
            setFormattedResults(sorted);
        } 
        if (currentSortOption === sortOptions.place1 || currentSortOption === null || currentSortOption === undefined) {
            const sorted = formattedEventResults.sort(function(a: FormattedEventResult, b: FormattedEventResult): number {
                const placeResult = (a.eventResult.place || 0) - (b.eventResult.place || 0);
                const setResult = (parseInt(a.eventResult.resultSet || "0") || 0) - (parseInt(b.eventResult.resultSet || "0") || 0);
                return placeResult || setResult;
            });
            setFormattedResults(sorted);
        } else if (currentSortOption === sortOptions.place2) {
            const sorted = formattedEventResults.sort(function(a: FormattedEventResult, b: FormattedEventResult): number {
                const placeResult = (b.eventResult.place || 0) - (a.eventResult.place || 0);
                const setResult = (parseInt(a.eventResult.resultSet || "0") || 0) - (parseInt(b.eventResult.resultSet || "0") || 0);
                return placeResult || setResult;
            });
            setFormattedResults(sorted);
        } 
    }

    function formatSingleResult(eventResult: EventResult) {
        const formatted: FormattedEventResult = {
            id: eventResult.id,
            eventResult: eventResult,
            isChecked: false
        };
        return formatted;
    }

    function formatEventResults(results: EventResult[]) {
        let formattedResults: FormattedEventResult[] = [];
        for (let i = 0; i < results.length; i++) {
            const current: EventResult = results[i];
            const formatted: FormattedEventResult = formatSingleResult(current);
            formattedResults.push(formatted);
        }
        sortEventResults(formattedResults);
    }

    useEffect(() => {
        if (eventResults) formatEventResults(eventResults);
    }, [eventResults]);

    function handleSelectedRow(index: number, value: boolean) {
        setDisplayDeleteBtn(true);
        if (formattedData) {
            let selectedRow = formattedData[index];
            selectedRow = {
                ...selectedRow,
                isChecked: value
            };
            const updatedData = [
                ...formattedData.slice(0, index),
                selectedRow,
                ...formattedData.slice(index + 1)
            ];
            setFormattedResults(updatedData);
        }
    }

    function handleDeleteButtonClick() {
        setDisplayDeleteModal(true);
    }

    async function deleteSelectedRows() {
        setIsLoading(true);
        setError("");
        setDisplayDeleteModal(false);
        setDisplayDeleteBtn(false);
        if (formattedData) {
            let updatedRows: FormattedEventResult[] = [];
            for (let i = 0; i < formattedData.length; i++) {
                const current = formattedData[i];
                if (current.isChecked) {
                    const result = await deleteEventResult({id: current.eventResult.id});
                    if (!result.isSuccess) {
                        const msg = "A result could not be deleted: " + result.message;
                        setError(msg);
                        updatedRows.push(current);
                        setDisplayDeleteBtn(true);
                    }
                } else {
                    updatedRows.push(current);
                }
            };
            setFormattedResults(updatedRows);
        } else {
            setError("No data was found.");
        }
        setIsLoading(false);
    }

    return (
       <>
            {isLoading ?
                <Spinner />
                :
                <>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <h2>Class Results found in Database</h2>
                            <p>Quickly verify the saved event results after you save them via the "Key In Results" or "Full Results" Feature.</p>
                            <p>You can use the "Select" column to select one or more rows of results and permanently remove the result(s) from the database.</p>
                        </IonCol>
                    </IonRow>
                    {error && <ErrorAlert error={error} />}
                    {displayDeleteBtn &&
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="ion-text-center">
                                <hr />
                                <IonButton color="danger" onClick={handleDeleteButtonClick}>Delete Selected</IonButton>
                                <p className="font-weight-bold">Reload this page after you delete any results.</p>
                            </IonCol>
                        </IonRow>
                    }
                    {(formattedData && formattedData.length > 0) ? 
                        <Table bordered striped hover responsive>
                            <thead>
                                {isEditable && <th>Select</th>}
                                <th>
                                    Set
                                    <IonIcon icon={currentSortOption === sortOptions.set1 ? chevronDown : chevronUp} onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.set1 ? sortOptions.set2 : sortOptions.set1); sortEventResults(formattedData, currentSortOption === sortOptions.set1 ? sortOptions.set2 : sortOptions.set1);} } /> 
                                </th>
                                <th>
                                    Place
                                    <IonIcon icon={currentSortOption === sortOptions.place1 ? chevronDown : chevronUp} onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.place1 ? sortOptions.place2 : sortOptions.place1); sortEventResults(formattedData, currentSortOption === sortOptions.place1 ? sortOptions.place2 : sortOptions.place1);} } /> 
                                </th>
                                <th>Entry #</th>
                                <th>Rider Name</th>
                                <th>Horse Name</th>
                                <th>Time</th>
                                <th>Score</th>
                                <th>Points</th>
                                <th>Prize $</th>
                                <th>DNC</th>
                                <th>Time Created</th>
                            </thead>
                            <tbody>
                            {formattedData.map((result, index) => (
                                <tr key={index}>
                                    {isEditable && (
                                        <td>
                                            <IonCheckbox slot="start" checked={result.isChecked ? true : false} onIonChange={e => handleSelectedRow(index, e.detail.checked)} />
                                        </td>
                                    )}
                                    <td>
                                        <p className="ion-text-wrap">{result.eventResult.resultSet}</p>
                                    </td>
                                    <td>
                                        <p className="ion-text-wrap">{result.eventResult.place}</p>
                                    </td>
                                    <td>
                                        <p className="ion-text-wrap">{result.eventResult.entry.number || ""}</p>
                                    </td>
                                    <td>
                                        <p className="ion-text-wrap">{result.eventResult.entry.rider?.name || result.eventResult.entry.riderName || ""}</p>
                                    </td>
                                    <td>
                                        <p className="ion-text-wrap">{result.eventResult.entry.horse?.name || result.eventResult.entry.horseName || ""}</p>
                                    </td>
                                    <td>
                                        <p className="ion-text-wrap">{result.eventResult.time || ""}</p>
                                    </td>
                                    <td>
                                        <p className="ion-text-wrap">{result.eventResult.score || ""}</p>
                                    </td>
                                    <td>
                                        <p className="ion-text-wrap">{(result.eventResult.place && result.eventResult.pointTables && result.eventResult.pointTables[0]) ? calculatePointsFromTable(result.eventResult.place, result.eventResult.pointTables[0]) : ""}</p>
                                    </td>
                                    <td>
                                        <p className="ion-text-wrap">{result.eventResult.prizeMoney || ""}</p>
                                    </td>
                                    <td>
                                        <p className="ion-text-wrap">{result.eventResult.didNotCompete ? "DNC" : ""}</p>
                                    </td>
                                    <td>
                                        <p className="ion-text-wrap">{result.eventResult.createdOn ? moment(result.eventResult.createdOn).format("MM/DD/YYYY hh:mm a") : ""}</p>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            
                        </Table>
                        :
                        <p>The are no results saved for this class.</p>
                    }
                </>
            }
            <IonModal backdropDismiss={false} isOpen={displayDeleteModal} id="termsAndConditionsModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Delete Results
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setDisplayDeleteModal(false)}
                        >
                            <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <p>This will permanently remove the results that you have selected.</p>
                            <p>Are you sure you want to delete these results?</p>
                        </IonCol>
                    </IonRow>
                </IonContent>
                <IonRow>
                    <IonCol size="6">
                        <IonButton expand="block" color="light" onClick={() => setDisplayDeleteModal(false)}>
                            Close
                        </IonButton>
                    </IonCol>
                    <IonCol size="6">
                        <IonButton expand="block" color="danger" onClick={() => deleteSelectedRows()}>
                            Delete
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonModal>
       </>
    );
};

export default VerifyEventResultsTable;
