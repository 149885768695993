import { Event, S3Object } from "../../../models";
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorAlert from "../../Errors/ErrorAlert";
import InformationBanner from "../../Banners/InformationBanner";
import DocumentForm from "../../s3Object/DocumentForm";
import Spinner from "../../Spinners/Spinner";
import { close } from "ionicons/icons";
import Storage from "@aws-amplify/storage";
import {handleDeleteS3Item} from "../../../utilities/s3Object/s3Object";
import { getEventById, updateEvent } from "../../../utilities/events/Event";
import { PersonContext } from "../../../context/PersonContext";
import { UpdateEventInput } from "../../../API";
import moment from "moment";

interface FormattedDocument {
    url: string
    document: S3Object
    lastUpdated: string
}

interface _Props {
    event: Event
}

const EventDocumentsForm: React.FC<_Props> = ({event}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [eventDocuments, setEventDocuments] = useState<(S3Object | null)[] | null | undefined>(event.documents || undefined);
    const [formattedDocuments, setFormattedDocuments] = useState<FormattedDocument[]>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    async function formatDocuments(documents: any[]) {
        let formattedArray: FormattedDocument[] = [];
        if (documents) {
            for (var i = 0; i < documents.length; i++) {
                const document = documents[i];
                const url = await handleGetDocument(document.key);
                const formattedDocument: FormattedDocument = {
                    url: (url && typeof(url) === "string") ? url : "unknown",
                    document,
                    lastUpdated: moment(document.dateUploaded).format("MM/DD/YYYY hh:mm a")
                };
                formattedArray.push(formattedDocument);
            }
        }
        const sorted = formattedArray.sort((a, b) => b.lastUpdated.localeCompare(a.lastUpdated));
        setFormattedDocuments(sorted || formattedArray);
    }

    useEffect(() => {
        if (event && event.documents) {
            formatDocuments(event.documents);
        }
    }, [event]);

    const handleSubmit = async (newDocumentList: S3Object[]) => {
        setIsLoading(true);
        const queryResult = await getEventById(event.id);
        if (queryResult.isSuccess) {
            formatDocuments(queryResult.result.documents);
            setEventDocuments(queryResult.result.documents);
        } else {
            formatDocuments(newDocumentList);
            setEventDocuments(newDocumentList);
        }
        setIsLoading(false);
        setShowModal(false);
    }

    async function handleGetDocument(fileName: string): Promise<object | string> {
        try {
            return await Storage.get(fileName);
        } catch (error: any) {
            console.error("Error while getting the document: ", error);
            return "";
        }
    }

    const handleDelete = async (document: S3Object) => {
        let newDocumentArray = [];
        const deleteResult = await handleDeleteS3Item(document.key);
        if (deleteResult.isSuccess) {
            if (eventDocuments) {
                for (var i = 0; i < eventDocuments.length; i++) {
                    const currentDocument = eventDocuments[i];
                    if (currentDocument?.key !== document.key) {
                        newDocumentArray.push(currentDocument);
                    }
                }
            }
            const eventInput: UpdateEventInput = {
                id: event.id,
                lastUpdatedBy: user.id,
                documents: newDocumentArray
            };
            const updateResult = await updateEvent(eventInput);
            if (updateResult.isSuccess) {
                setEventDocuments(updateResult.result.documents);
                formatDocuments(updateResult.result.documents);
            } else {
                setError("Could not update the event documents.");
            }
        } else {
            setError("Could not remove the document from storage.");
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {formattedDocuments && formattedDocuments.length ?
                <>
                    <IonRow>
                        <IonCol>
                            <IonList className="bg-white">
                                {formattedDocuments.map((formattedDocument, index) => (
                                    <IonItem key={index}>
                                        <IonLabel>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <a href={formattedDocument.url} rel="noopener noreferrer"  target="_blank" >
                                                        <h2 className="ion-text-wrap link">{formattedDocument.document && formattedDocument.document.title}</h2>
                                                    </a>
                                                </IonCol>
                                                <IonCol size="12">
                                                    <p className="description ion-text-wrap">(Last Updated: {formattedDocument.lastUpdated})</p>
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                        <IonButton slot="end" color="danger" onClick={() => handleDelete(formattedDocument.document as S3Object)}><IonIcon icon={close}/></IonButton>
                                    </IonItem>
                                ))}
                            </IonList>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                Add Document
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                Add Document
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
            <IonModal backdropDismiss={false} isOpen={showModal} id="addS3ObjectModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Document
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeDocumentModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            <InformationBanner info="Choose a document to upload. Include any prize lists, forms, rules, etc." />
                            <DocumentForm onSubmit={handleSubmit} event={event} />
                        </>
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventDocumentsForm;