import {
    IonButton, 
    IonCard, 
    IonCardContent, 
    IonCardHeader, 
    IonCardTitle, 
    IonCol, 
    IonContent, 
    IonInput,  
    IonItem,  
    IonLabel,  
    IonPage, 
    IonRouterLink, 
    IonRow,
    IonText, 
} from "@ionic/react";
import React, {useEffect, useRef, useState} from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { Organization } from "../../models";
import { getOrganizationByBackHalf } from "../../utilities/organization/Organization";
import Footer from "../../components/Footers/Footer";
import OrganizationAuthNavbar from "../../components/Navbars/OrganizationAuthNavbar";
import Spinner from "../../components/Spinners/Spinner";
import ErrorAlert from "../../components/Errors/ErrorAlert";
import { logIn } from "../../utilities/auth/LoginUtil";
import { forgotPassword, resetPassword } from "../../utilities/auth/ForgotPasswordUtil";
import { LogoInput } from "../../API";
import AuthNavbar from "../../components/Navbars/AuthNavbar";

interface ViewOrgProps extends RouteComponentProps<{
    id: string;
}> {
    setOrganizationHandler: Function;
}

const OrganizationForgotPasswordPage: React.FC<ViewOrgProps> = ({match, setOrganizationHandler}) => {
    const history = useHistory();

    const [currentOrganization, setCurrentOrganization] = useState<Organization>();
    const [logos, setLogos] = useState<(LogoInput | null)[] | null[]>();
    const [orgLogoURL, setOrgLogoURL] = useState<string>();
    const [id, setId] = useState<string>("");
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [isEmailDisabled, setIsEmailDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const codeInputRef = useRef<HTMLIonInputElement>(null);
    const passwordInputRef = useRef<HTMLIonInputElement>(null);

    async function getOrganization(id: string) {
        setIsLoading(true);
        setError("");
        const queryResult = await getOrganizationByBackHalf(id);
        if (queryResult.isSuccess && queryResult.result) {
            const organization = queryResult.result;
            setCurrentOrganization(organization);
            setOrganizationHandler(organization);
        } else {
            setError("Error: please check that you have the correct URL or try navigating to www.ringsidepro.com/register");
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setLogos(currentOrganization?.logos ?? []);
    }, [currentOrganization]);

    useEffect(() => {
        setOrgLogoURL(logos?.[0]?.logoURL ?? "");
    }, [logos]);

    useEffect(() => {
        // First, check if the id is found in the URL parameters
        if (match.params && match.params.id) {
            setError("");
            setId(match.params.id);
            getOrganization(match.params.id);
        // Second, check if you can find the id by searching in the URL
        } else {
            // For ex - match.path = "/iea/forgot-password".
            // The pathArray will be ["", "iea", "forgot-password"]
            const path = match.path;
            const pathArray = path.split("/");
            const id = pathArray[pathArray.length - 2];
            if (id) {
                setError("");
                setId(id);
                getOrganization(id);
            } else {
                // Lastly, set an error message 
                setError("Error: please check that you have the correct URL or try navigating to www.ringsidepro.com/register"); 
            }
        }
    }, [match, match.params.id]);

    const validateEmailForm = () => {
    // Using a type assertion to ensure that the inputs contain strings
        if (!email) {
            setError("Please enter your email address.");
            return false;
        }
        setError("");
        return true;
    };

    const handleSendResetCode = async () => {
        try {
            // Validate the form fields
            const isValid = validateEmailForm();
            if (!isValid) return;

            const forgotPasswordResult = await forgotPassword(email);
            if (forgotPasswordResult.isSuccess) {
                setIsEmailDisabled(true);
            } else {
                setError(forgotPasswordResult.message);
            }
        } catch (error: any) {
            setError(error);
        }
    };

    const validateNewPasswordForm = () => {
        // Using a type assertion to ensure that the inputs contain strings
        const code = (codeInputRef.current!.value as string);
        const password = (passwordInputRef.current!.value as string);

        if (!email) {
            setError("Please enter your email address.");
            return false;
        }
        if (!code) {
            setError("Please enter your code.");
            return false;
        }
        if (!password) {
            setError("Please enter your password.");
            return false;
        }
        setError("");
        return true;
    };

    const handlePasswordReset = async () => {
        try {
            setIsLoading(true);
            // Validate the form fields
            const isValid = validateNewPasswordForm();
            if (!isValid) return;

            // Using a type assertion to ensure that the inputs contain strings
            const code = (codeInputRef.current!.value as string);
            const password = (passwordInputRef.current!.value as string);

            const resetPasswordResult = await resetPassword(email, code, password);
            if (resetPasswordResult && resetPasswordResult.isSuccess) {
                const logInResult = await logIn(email, password);
                if (logInResult.isSuccess) {
                    navigateToDashboard();
                } else setError(logInResult.message);
            }
            if (!(resetPasswordResult && resetPasswordResult.isSuccess)) setError(resetPasswordResult.message);
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
        setIsLoading(false);
    };

    const navigateToDashboard = () => {
        const path = id ? ("/index/" + id + "/home") : "/index/home";
        history.push(path);
    }
    
    return (
        <IonPage>
            <IonContent color="primary">
                {currentOrganization ? <OrganizationAuthNavbar organization={currentOrganization} /> : <AuthNavbar/>}
                <section className="section bg-primary">
                    <div>
                        <IonRow class="ion-justify-content-center">
                            <IonCol sizeXs="6" sizeMd="2">
                                {orgLogoURL && orgLogoURL.length > 0 && (<img
                                    src={orgLogoURL}
                                    alt={currentOrganization?.name}
                                />)}
                            </IonCol>
                        </IonRow>
                        <IonRow class="ion-justify-content-center ion-padding-bottom">
                            <IonCol sizeSm="8" sizeMd="6">
                                <IonCard color="light">
                                    <IonCardHeader className="ion-margin-top">
                                        <IonCardTitle color="primary" className="ion-text-center h3">
                                            Forgot Password
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        {error && (
                                            <ErrorAlert width="12" error={error} />
                                        )}
                                        {isEmailDisabled && (
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeSm="12" sizeMd="8" className="ion-no-padding">
                                                    <IonText>Check your email (including spam and promotions folders) for a 6 digit code.</IonText>
                                                </IonCol>
                                            </IonRow>
                                        )}
                                        {isLoading ?
                                            <Spinner />
                                            :
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeSm="12" sizeMd="8">
                                                    <form>
                                                        <IonItem color="light">
                                                            <IonLabel position="floating">Email Address</IonLabel>
                                                            <IonInput 
                                                                type="email"
                                                                required={true}
                                                                aria-required={true}
                                                                value={email}
                                                                disabled={isEmailDisabled}
                                                                onIonChange={e => {
                                                                    setEmail(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                        {isEmailDisabled && (
                                                            <>
                                                                <IonItem color="light">
                                                                    <IonLabel position="floating">Code</IonLabel>
                                                                    <IonInput 
                                                                        required={true}
                                                                        aria-required={true}
                                                                        ref={codeInputRef} 
                                                                    />
                                                                </IonItem>
                                                                <IonItem color="light">
                                                                    <IonLabel position="floating">Password</IonLabel>
                                                                    <IonInput 
                                                                        type="password" 
                                                                        required={true}
                                                                        aria-required={true}
                                                                        ref={passwordInputRef} 
                                                                    />
                                                                </IonItem>
                                                            </>
                                                        )}
                                                        <IonButton
                                                            id="registerBtn"
                                                            className="ion-margin-top"
                                                            color="tertiary"
                                                            expand="block"
                                                            onClick={isEmailDisabled ? handlePasswordReset : handleSendResetCode}
                                                        >
                                                            {isEmailDisabled ? "Reset Password" : "Send Me A Code"}
                                                        </IonButton>
                                                    </form>
                                                </IonCol>
                                            </IonRow>
                                        }
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol sizeSm="12" sizeMd="8" className="ion-text-center">
                                                <IonText> Are you new? <IonRouterLink routerLink={`/${currentOrganization?.urlBackHalf}/register`}>Create an Account.</IonRouterLink></IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </div>
                </section>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default OrganizationForgotPasswordPage;
