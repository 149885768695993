import { API } from "aws-amplify";
import { Result } from "../../interfaces/Result";

/**
 * Functionality to create a new Stripe Express Connect Customer.
 * 
 * More details here: https://stripe.com/docs/connect/express-accounts
 * 
 * @param {string} personid 
 * @param {string} email 
 */
export async function createExpressAccount(personid: string, organizationId?: (string | null), organizationName?: (string | null), organizationType?: (string | null), organizationWebsite?: (string | null)) {
    try {
        let queryParams = "";
        if (!personid) {
            let result: Result = {isSuccess: false, type: "Stripe", result: "No person id provided.", message: "Failed to create a new Stripe customer." }
            return result;
        }
        else queryParams = queryParams + "?personId=" + personid;
        if (organizationId) queryParams = queryParams + "&organizationId=" + organizationId;
        if (organizationName) queryParams = queryParams + "&organizationName=" + organizationName;
        if (organizationType) queryParams = queryParams + "&organizationType=" + organizationType;
        if (organizationWebsite) queryParams = queryParams + "&organizationWebsite=" + organizationWebsite;

        const path = "/payments/create-express-account" + queryParams;
        const response = await API.get("Payments", path, {});

        const responseBody = JSON.parse(response.body);
        const connectedAccountId = responseBody.connectedAccountId;
        const onboardingLink = responseBody.accountLink;

        if (connectedAccountId && onboardingLink) {
            const data = {
                onboardingLink,
                connectedAccountId
            };
        
            let result: Result = {isSuccess: true, type: "Stripe", result: data, message: "Successfully created a new account."}
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "Stripe", result: null, message: "Couldn't create a new account."}
            return result;
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Error occurred while trying to create a new Stripe account." }
        return result;
    }
}

/**
 * Functionality to create a new Stripe Express Connect Customer Login Link.
 * 
 * More details here: https://stripe.com/docs/api/account/create_login_link
 * 
 * @param {string} stripeAccountId 
 */
 export async function createExpressLoginLink(stripeAccountId: string) {
    try {
        let queryParams = "";
        if (!stripeAccountId) {
            let result: Result = {isSuccess: false, type: "Stripe", result: "No stripe account id provided.", message: "Failed to get the Stripe Express Login Link." }
            return result;
        }
        else queryParams = queryParams + "?accountid=" + stripeAccountId;

        const path = "/payments/create-login-link" + queryParams;
        const response = await API.get("Payments", path, {});
        const responseBody = JSON.parse(response.body);
        const url = responseBody.url;

        let result: Result = {isSuccess: true, type: "Stripe", result: url, message: "Successfully created a new express login link."}
        return result;
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Error occurred while trying to create a new express login link." }
        return result;
    }
}

/**
 * Functionality to get a Stripe Express Account balance
 * 
 * More details here: https://stripe.com/docs/api/balance/balance_retrieve
 * 
 * @param {string} stripeAccountId 
 */
 export async function getAccountBalance(stripeAccountId: string) {
    try {
        let queryParams = "";
        if (!stripeAccountId) {
            let result: Result = {isSuccess: false, type: "Stripe", result: "No stripe account id provided.", message: "Failed to get the Stripe Account Balance." }
            return result;
        }
        else queryParams = queryParams + "?accountid=" + stripeAccountId;

        const path = "/payments/account-balance" + queryParams;
        const response = await API.get("Payments", path, {});
        const responseBody = JSON.parse(response.body);
        const balanceObject = responseBody.balanceObject;     

        let result: Result = {isSuccess: true, type: "Stripe", result: balanceObject, message: "Successfully got the Stripe Account Balance."}
        return result;
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Error occurred while trying to get the Stripe Account Balance." }
        return result;
    }
}