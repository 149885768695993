import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { Address, Event } from "../../../models";
import React, { useContext, useEffect, useState } from "react";
import { formatAddress } from "../../../utilities/address/FormatAddress";
import { getAddressById } from "../../../utilities/address/Address";
import CondensedAddressForm from "../../Address/CondensedAddressForm";
import ErrorAlert from "../../Errors/ErrorAlert";
import { PersonContext } from "../../../context/PersonContext";
import InformationBanner from "../../Banners/InformationBanner";
import Spinner from "../../Spinners/Spinner";
import { close } from "ionicons/icons";
import { updateEvent } from "../../../utilities/events/Event";
import { UpdateEventInput } from "../../../API";

interface _Props {
    event: Event
}

let subscription: any;

const EventLocationForm: React.FC<_Props> = ({event}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [eventAddress, setEventAddress] = useState<Address | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getAddress(addressId: string) {
            const queryResult = await getAddressById(addressId);
            if (queryResult.isSuccess) {
                setEventAddress(queryResult.result);
            }
        }

        async function setEventAddressSubscription() {
            if (user) {
                // subscription = DataStore.observe(Address).subscribe(msg => {
                //     const updatedAddressId = msg.element.id;
                //     if (event && eventAddress) {
                //         if (event.address && (event.address.id === updatedAddressId)) {
                //             const updatedAddressProperties = msg.element;
                //             let updatedAddress = {
                //                 ...eventAddress
                //             };
                //             if (updatedAddressProperties.name) updatedAddress.name = updatedAddressProperties.name;
                //             if (updatedAddressProperties.type) updatedAddress.type = updatedAddressProperties.type;
                //             if (updatedAddressProperties.streetAddress1) updatedAddress.streetAddress1 = updatedAddressProperties.streetAddress1;
                //             if (updatedAddressProperties.streetAddress2) updatedAddress.streetAddress2 = updatedAddressProperties.streetAddress2;
                //             if (updatedAddressProperties.city) updatedAddress.city = updatedAddressProperties.city;
                //             if (updatedAddressProperties.provState) updatedAddress.provState = updatedAddressProperties.provState;
                //             if (updatedAddressProperties.zip) updatedAddress.zip = updatedAddressProperties.zip;
                //             if (updatedAddressProperties.country) updatedAddress.country = updatedAddressProperties.country;
                //             setEventAddress(updatedAddress);
                //         }
                //     } else if (event && !eventAddress) {
                //         setEventAddress(msg.element);
                //     }
                // });
            }
        }

        if (event.address && event.address.id) getAddress(event.address.id);
        if (event.address && event.address.id) setEventAddressSubscription();

        return () => {
            if (subscription) subscription.unsubscribe();
        }
    }, [event]);

    const handleSubmit = async (address: Address) => {
        setIsLoading(true);
        const eventInput: UpdateEventInput = {
            id: event.id,
            lastUpdatedBy: user.id,
            eventAddressId: address.id
        }
        const updateResult = await updateEvent(eventInput);
        if (eventAddress) {
            setEventAddress(address);
        } else {
            if (updateResult.isSuccess) {
                setEventAddress(address);
            } else {
                setError("Error: could not attach address to event. Contact hello@ringsidepro.com for help.");
            }
        }
        setIsLoading(false);
        setShowModal(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {eventAddress ?
                <>
                    <IonRow>
                        <IonCol>
                            <h2 className="text-darker">{formatAddress(eventAddress)}</h2>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                Edit Address
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonButton color="tertiary" onClick={() => setShowModal(true)}>
                                Add Address
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </>
            }
            <IonModal backdropDismiss={false} isOpen={showModal} id="addEventAddressModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Address
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            <InformationBanner info="The physical address of the event that people should use to find it." />
                            <CondensedAddressForm onSubmit={handleSubmit} address={eventAddress}/>
                        </>
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventLocationForm;