import {
    IonContent,
    IonPage,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import EventOrganizationPostInfo from "../../../../../components/Posts/EventOrganizationPostInfo";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import { Organization } from "../../../../../models";

interface _Props extends RouteComponentProps<{
    id: string;
}> {}


const EventOrganizationPostInfoPage: React.FC<_Props> = ({match}) => {

    const [organization, setOrganization] = useState<Organization>();
    const [error, setError] = useState<string>("");

    useEffect(() => {
        async function getOrganization() {
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                const org: Organization = queryResult.result;
                setOrganization(org);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        getOrganization();
    }, [match, match.params.id]);


    return (
        <IonPage id="view-post-page" className="bg-light">
            <Header />
            <IonContent>
                {error && <ErrorAlert width="12" error={error} />}
                <PageTitle title={`${(organization?.nickname || organization?.name) ?? ""} Posts`} />
                {organization ?
                    <EventOrganizationPostInfo organization={organization}/>
                :   <p>Loading Posts....</p>
                }
            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationPostInfoPage;