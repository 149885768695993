import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { CreateMembershipInput, UpdateEventEntryInput, UpdateTrainerInput, UpdateMembershipInput, UpdateContactInput, CreateContactInput } from "../../../../API";
import { PersonContext } from "../../../../context/PersonContext";
import { EventEntry, Trainer, Membership } from "../../../../models";
import { updateEventEntry } from "../../../../utilities/eventEntry/EventEntry";
import { updateTrainer } from "../../../../utilities/trainer/Trainer";
import { createMembership, getMembershipsByTrainerId, updateMembership } from "../../../../utilities/membership/Membership";
import SuccessBanner from "../../../Banners/SuccessBanner";
import ErrorAlert from "../../../Errors/ErrorAlert";
import Spinner from "../../../Spinners/Spinner";
import { formatTwilioNumber } from "../../../../utilities/contact/FormatPhoneNumber";
import { createContact, updateContact } from "../../../../utilities/contact/Contact";

interface _Props {
    entry: EventEntry
}

const EntryInfoTrainer: React.FC<_Props> = ({entry}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();
    const [trainerName, setTrainerName] = useState("");
    const [trainerLocation, setTrainerLocation] = useState("");
    const [trainerEmail, setTrainerEmail] = useState("");
    const [trainerPhone, setTrainerPhone] = useState("");
    const [ecMembership, setECMembership] = useState<Membership | null | undefined>();
    const [trainerECNumber, setTrainerECNumber] = useState("");
    const [trainerECStatus, setTrainerECStatus] = useState("");
    const [otherMembership, setOtherMembership] = useState<Membership | null | undefined>();
    const [trainerOtherMembershipName, setTrainerOtherMembershipName] = useState("");
    const [trainerOtherMembershipNumber, setTrainerOtherMembershipNumber] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    async function setMemberships(trainer: Trainer) {
        const queryResult = await getMembershipsByTrainerId(trainer.id);
        if (queryResult.isSuccess) {
            const memberships = queryResult.result;
            if (memberships) {
                for (let i = 0; i < memberships.length; i++) {
                    const membership = memberships[i];
                    if (membership?.name === "EC") {
                        setECMembership(membership);
                        setTrainerECNumber(membership.membershipId);
                        setTrainerECStatus(membership.membershipStatus || "");
                    } else {
                        setOtherMembership(membership);
                        setTrainerOtherMembershipName(membership.name);
                        setTrainerOtherMembershipNumber(membership.membershipId);
                    }
                }
            }
        }
    }

    const getEntryInformation = (entry: EventEntry) => {
        setCurrentEntry(entry);
        setTrainerName(entry.trainerName || "");
        setTrainerLocation(entry?.trainer?.location || "");
        setTrainerEmail(entry.trainer?.contact?.personalEmail || "");
        setTrainerPhone(entry?.trainer?.contact?.cell || "");
        if (entry.trainer) setMemberships(entry.trainer);
    }

    useEffect(() => {
        if (entry) {
            getEntryInformation(entry);
        }
    }, [entry]);

    const handleEdit = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        let contactId = "";
        if (entry.trainer?.contact?.id) {
            const updateContactInput: UpdateContactInput = {
                id: entry.trainer.contact.id,
                personalEmail: trainerEmail,
                cell: trainerPhone ? formatTwilioNumber(trainerPhone) : undefined
            };
            const updateContactResult = await updateContact(updateContactInput);
            if (updateContactResult) {
                contactId = updateContactResult.result.id;
            } else {
                setError("Could not update the contact info.");
                setIsLoading(false);
                return;
            }
        } else {
            const createContactInput: CreateContactInput = {
                personalEmail: trainerEmail,
                cell: trainerPhone ? formatTwilioNumber(trainerPhone) : undefined
            };
            const createContactResult = await createContact(createContactInput);
            if (createContactResult) {
                contactId = createContactResult.result.id;
            } else {
                setError("Could not create the contact info.");
                setIsLoading(false);
                return;
            }
        }
        if (entry.trainerId) {
            const input: UpdateTrainerInput = {
                id: entry.trainerId,
                name: trainerName,
                location: trainerLocation,
                trainerContactId: contactId
            };
            const updateResult = await updateTrainer(input);
            if (updateResult.isSuccess) {
                const updateEntryInput: UpdateEventEntryInput = {
                    id: entry.id,
                    trainerName: trainerName,
                    trainerId: updateResult.result.id,
                    // eventEntryTrainerId: updateResult.result.id
                };
                const updateEntryResult = await updateEventEntry(updateEntryInput);
                if (updateEntryResult.isSuccess) {
                    const updatedEntry = updateEntryResult.result;
                    setCurrentEntry(updatedEntry);
                    getEntryInformation(updatedEntry);
                    if (trainerECNumber) {
                        if (ecMembership) {
                            const ecUpdateInput: UpdateMembershipInput = {
                                id: ecMembership.id,
                                membershipId: trainerECNumber,
                                membershipStatus: trainerECStatus,
                                trainerId: entry.trainerId,
                                // trainerMembershipsId: entry.trainerId
                            };
                            const updateECResult = await updateMembership(ecUpdateInput);
                            if (updateECResult.isSuccess) {
                                setECMembership(updateECResult.result);
                            } else {
                                setError("Could not update the EC info.");
                                setIsLoading(false);
                                return;
                            }
                        } else {
                            const ecCreateInput: CreateMembershipInput = {
                                name: "EC",
                                membershipId: trainerECNumber,
                                membershipStatus: trainerECStatus,
                                personId: entry.trainerId || user.id,
                                trainerId: entry.trainerId,
                                // trainerMembershipsId: entry.trainerId
                            };
                            const createECResult = await createMembership(ecCreateInput);
                            if (createECResult.isSuccess) {
                                setECMembership(createECResult.result);
                            } else {
                                setError("Could not create the EC info.");
                                setIsLoading(false);
                                return;
                            }
                        }
                    }
                    if (trainerOtherMembershipNumber) {
                        if (otherMembership) {
                            const otherUpdateInput: UpdateMembershipInput = {
                                id: otherMembership.id,
                                name: trainerOtherMembershipName,
                                membershipId: trainerOtherMembershipNumber,
                                trainerId: entry.trainerId,
                                // trainerMembershipsId: entry.trainerId
                            };
                            const updateOtherMembershipResult = await updateMembership(otherUpdateInput);
                            if (updateOtherMembershipResult.isSuccess) {
                                setOtherMembership(updateOtherMembershipResult.result);
                            } else {
                                setError("Could not update the Other Membership info.");
                                setIsLoading(false);
                                return;
                            }
                        } else {
                            const otherCreateInput: CreateMembershipInput = {
                                name: trainerOtherMembershipName,
                                membershipId: trainerOtherMembershipNumber,
                                personId: entry.trainerId || user.id,
                                trainerId: entry.trainerId,
                                // trainerMembershipsId: entry.trainerId
                            };
                            const createOtherMembershipResult = await createMembership(otherCreateInput);
                            if (createOtherMembershipResult.isSuccess) {
                                setOtherMembership(createOtherMembershipResult.result);
                            } else {
                                setError("Could not create the Other Membership info.");
                                setIsLoading(false);
                                return;
                            }
                        }
                    }
                    setMemberships(updatedEntry.trainer);
                    setSuccess("Updated the trainer info.");
                    setIsEdit(false);
                    setIsLoading(false);
                } else {
                    setError("Could not update the entry.");
                    setIsLoading(false);
                    return;
                }
            } else {
                setError("Could not update the trainer");
                setIsLoading(false);
            }
        } else {
            setError("Could not find the trainer in the database.");
            setIsLoading(false);
        }
    }

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            <h3>Trainer</h3>
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {error && <ErrorAlert width="12" error={error} />}
                        {success && <SuccessBanner width="12" success={success} />}
                        {isEdit ?
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Name</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={trainerName}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setTrainerName(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Location</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={trainerLocation}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setTrainerLocation(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Email</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={trainerEmail}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setTrainerEmail(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Cell Phone</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={trainerPhone}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setTrainerPhone(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer EC</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={trainerECNumber}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setTrainerECNumber(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Status</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={trainerECStatus}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setTrainerECStatus(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Membership Name</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={trainerOtherMembershipName}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setTrainerOtherMembershipName(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Membership Number</IonLabel>
                                            <IonInput 
                                                type="text"
                                                value={trainerOtherMembershipNumber}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setTrainerOtherMembershipNumber(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                            :
                            <>
                                <IonRow>
                                    <IonCol>
                                        <h3>Trainer</h3>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Name</IonLabel>
                                            <IonText><p>{trainerName}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Location</IonLabel>
                                            <IonText><p>{trainerLocation}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Email</IonLabel>
                                            <IonText><p>{trainerEmail}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Cell Phone</IonLabel>
                                            <IonText><p>{trainerPhone}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer EC Number</IonLabel>
                                            <IonText><p>{trainerECNumber}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer EC Status</IonLabel>
                                            <IonText><p>{trainerECStatus}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Other Membership</IonLabel>
                                            <IonText><p>{trainerOtherMembershipName}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Trainer Other Membership Number</IonLabel>
                                            <IonText><p>{trainerOtherMembershipNumber}</p></IonText>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                        }
                        {isLoading ?
                            <Spinner />
                            :
                            <IonRow className="ion-justify-content-center">
                                {isEdit && (
                                    <IonCol sizeMd="4" className="ion-text-center">
                                        <IonButton
                                            className="ion-margin-top"
                                            color="primary"
                                            expand="block"
                                            onClick={() => setIsEdit(false)}
                                        >
                                            Cancel
                                        </IonButton>
                                    </IonCol>
                                )}
                                <IonCol sizeMd="4" className="ion-text-center">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="tertiary"
                                        expand="block"
                                        onClick={isEdit ? handleEdit : (() => setIsEdit(true))}
                                    >
                                        Edit
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        }
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default EntryInfoTrainer;