import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRouterLink,
    IonRow,
    IonText,
} from "@ionic/react";
import React, {useRef, useState} from "react";

import AuthNavbar from "../../components/Navbars/AuthNavbar";
import Footer from "../../components/Footers/Footer";
import Spinner from "../../components/Spinners/Spinner";

import {logIn} from "../../utilities/auth/LoginUtil";
import { Link, useHistory } from "react-router-dom";
import ErrorAlert from "../../components/Errors/ErrorAlert";
import { addNoteToHubspot } from "../../utilities/hubspot/HubspotNote";
import RequiredInputIndicator from "../../components/Forms/RequiredInputIndicator";

const LoginPage: React.FC = () => {
    const history = useHistory();

    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const emailInputRef = useRef<HTMLIonInputElement>(null);
    const passwordInputRef = useRef<HTMLIonInputElement>(null);

    const validateLogInForm = () => {
        // Using a type assertion to ensure that the inputs contain strings
        const email = (emailInputRef.current!.value as string);
        const password = (passwordInputRef.current!.value as string);

        if (!email) {
            setError("Please enter your email address.");
            return false;
        }
        if (!password) {
            setError("Please enter your password.");
            return false;
        }
        setError("");
        return true;
    };

    const handleLogin = async () => {
        setIsLoading(true);
        try {
            // Validate the form fields
            const isValid = validateLogInForm();
            if (!isValid) {
                setIsLoading(false);
                return;
            }

            // Using a type assertion to ensure that the inputs contain strings
            const email = (emailInputRef.current!.value as string);
            const password = (passwordInputRef.current!.value as string);

            const logInResult = await logIn(email, password);
            if (logInResult.isSuccess) {
                addNoteToHubspot(email, "Logged in.");
                navigateToDashboard();
            } else setError(logInResult.message);
            setIsLoading(false);
        } catch (error: any) {
            setError("An error occurred.");
            setIsLoading(false);
        }
    };

    const navigateToDashboard = () => {
        // Check if the user is trying to get to a specific dashboard page
        const previousPath = history.location.pathname;
        if (previousPath && previousPath.includes("index")) {
            // Send the user to the specific Dashboard page the user is trying to get to
            history.push(previousPath);
        } else {
            // Otw, send the user to the general dashboard page
            history.push("/index/home");
        }
        window.location.reload();
    }

    return (
        <IonPage id="loginPage">
            <IonContent color="primary">
                <AuthNavbar />
                <section className="section bg-primary">
                    <div>
                        <IonRow class="ion-justify-content-center ion-padding-bottom">
                            <IonCol sizeSm="8" sizeMd="6">
                                <IonCard color="light">
                                    <IonCardHeader className="ion-margin-top">
                                        <IonCardTitle color="primary" className="ion-text-center h3">
                                            Log In
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    {isLoading ?
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol className="ion-text-center">
                                                    <Spinner />
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                        :
                                        <IonCardContent>
                                            {error && (
                                                <ErrorAlert width="12" error={error} />
                                            )}
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeSm="12" sizeMd="8">
                                                    <form>
                                                        <IonItem color="light">
                                                            <IonLabel position="stacked">Email Address <RequiredInputIndicator/></IonLabel>
                                                            <IonInput 
                                                                id="login-email"
                                                                type="email"
                                                                required={true}
                                                                aria-required={true}
                                                                ref={emailInputRef} 
                                                            />
                                                        </IonItem>
                                                        <IonItem color="light">
                                                            <IonLabel position="stacked">Password <RequiredInputIndicator/></IonLabel>
                                                            <IonInput 
                                                                id="login-password"
                                                                type="password"
                                                                required={true}
                                                                aria-required={true}
                                                                ref={passwordInputRef} 
                                                            />
                                                        </IonItem>
                                                        <IonItem 
                                                            button={false}
                                                            lines="none"
                                                            color="light"
                                                            className="ion-no-padding"
                                                        >
                                                            <IonRow className="ion-justify-content-center">
                                                                <IonCol sizeMd="12" className="">
                                                                    <IonText className="description">Forgot your password? <IonRouterLink routerLink="/forgot-password"><span className="link">Reset It</span></IonRouterLink></IonText>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonItem>
                                                        <IonButton
                                                            id="loginBtn"
                                                            className="ion-margin-top"
                                                            color="tertiary"
                                                            expand="block"
                                                            onClick={async () => await handleLogin()}
                                                        >
                                                            Log in
                                                        </IonButton>
                                                    </form>
                                                </IonCol>
                                            </IonRow>
                                            <hr />
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeSm="12" sizeMd="8" className="ion-text-center">
                                                    <IonText>
                                                        Are you new? {" "}
                                                        <Link id="registerLink" to={{pathname: '/register', state: { prevPath: history.location.pathname }}}>
                                                            <span className="link">Create an Account.</span>
                                                        </Link>
                                                    </IonText>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    }
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </div>
                </section>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default LoginPage;