import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";
import formatResult from "../ReturnType/ReturnType";
import { CreatePostInput, CreatePostMutation, DeletePostInput, DeletePostMutation, GetPostQuery, UpdatePostInput, UpdatePostMutation, ModelSortDirection} from "../../API";
import { Post } from "../../models";
const moment = require("moment");

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: Post. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created Post.
*/
export async function createPost(input: CreatePostInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Post", "No input", "Create Post received no input.");
   try {
        const fullInput: CreatePostInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createPost, { input: fullInput }))) as GraphQLResult<CreatePostMutation>;
       else result = (await API.graphql({
           query: mutations.createPost,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreatePostMutation>;
       const Post = result.data?.createPost;
       return formatResult(true, "Post", Post, "Successfully created the Post.");
   } catch (error: any) {
       return formatResult(false, "Post", error, "Error creating record in the database for type: Post");
   }
}

/**
* Get all records in the database that match the given criteria for type: post. 
* 
* @param {string}  organizationId         The organization Id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the post object
*/
export async function getPostsByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.getPostsByOrganizationIDByCreatedOn,
            variables: {
                limit: 1000,
                organizationId: organizationId,
                sortDirection: ModelSortDirection.DESC
            },
            
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.getPostsByOrganizationIDByCreatedOn?.items as Post[];
        let nextToken = result.data?.getPostsByOrganizationIDByCreatedOn?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.getPostsByOrganizationIDByCreatedOn,
                variables: {
                    limit: 100,
                    organizationId: organizationId,
                    sortDirection: ModelSortDirection.DESC,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.getPostsByOrganizationIDByCreatedOn?.items as Post[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.getPostsByOrganizationIDByCreatedOn?.nextToken;
        }
        return formatResult(true, "Post", items, "Successfully got the Posts.");
    } catch (error: any) {
        return formatResult(false, "Post", error, "Error reading record in the database for type: Posts");
    }
}

/**
* Get all records in the database that match the given criteria for type: post. 
* 
* @param {string}  organizationId         The organization Id
* @param {string}  status                 Status
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the post object
*/
export async function getPostsByOrganizationIdStatus(organizationId: string, status: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {
            status: {
                eq: status
            },
            organizationId: {
                eq: organizationId
            }
        };
        
        const result = (await API.graphql({
            query: queries.listPosts,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.listPosts?.items as Post[];
        let nextToken = result.data?.listPosts?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listPosts,
                variables: {
                    limit: 1000,
                    filter
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.listPosts?.items as Post[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listPosts?.nextToken;
        }
        const sorted = sortPostsByCreateOnDate(items);
        return formatResult(true, "Post", sorted, "Successfully got the Posts.");
    } catch (error: any) {
        return formatResult(false, "Post", error, "Error reading record in the database for type: Posts");
    }
}

/**
* Handle deleting a new record in the database for type: post. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted post.
*/
export async function deletePost(input: DeletePostInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "Post", "No input", "Delete Post received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deletePost, { input: input }))) as GraphQLResult<DeletePostMutation>;
        else result = (await API.graphql({
            query: mutations.deletePost,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<DeletePostMutation>;
        const post = result.data?.deletePost;
        return formatResult(true, "Post", post, "Successfully deleted the post.");
    } catch (error: any) {
        return formatResult(false, "Post", error, "Error deleting record in the database for type: post");
    }
 }

 /**
* Read a specific record in the database for type: post. 
* 
* @param {string}  id                  The post id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the post object
*/
export async function getPostById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.getPost,
            variables: {
                id: id
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<GetPostQuery>;
        const post = result.data?.getPost;

        if (post) {
            return formatResult(true, "Post", post, "Successfully got the post.");
        } else {
            return formatResult(false, "Post", null, "No post was found.");
        }
    } catch (error: any) {
        return formatResult(false, "Post", error, "Error reading record in the database for type: post");
    }
}

/**
* Handle updating a new record in the database for type: post. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated post.
*/
export async function updatePost(input: UpdatePostInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "post", "No input", "Update post received no input.");
    try {
         const fullInput: UpdatePostInput = {
             ...input,
             updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
         };
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updatePost, { input: fullInput }))) as GraphQLResult<UpdatePostMutation>;
        else result = (await API.graphql({
            query: mutations.updatePost,
            variables: {
                input: fullInput
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<UpdatePostMutation>;
        const post = result.data?.updatePost;
        return formatResult(true, "Post", post, "Successfully updated the post.");
    } catch (error: any) {
        return formatResult(false, "Post", error, "Error updating record in the database for type: post");
    }
 }


function sortPostsByCreateOnDate(data: Post[]) {
    try {
        let result: Post[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => new moment(b.createdOn).format("YYYYMMDDHHmmss") - new moment(a.createdOn).format("YYYYMMDDHHmmss"));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: Post");
        console.error(error);
        return null;
    }
};
 
