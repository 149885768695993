import React, { useEffect, useState } from "react";
import Select from 'react-select';
import Spinner from "../Spinners/Spinner";
import { portalPlacement } from "../../interfaces/Select";
import ErrorAlert from "../Errors/ErrorAlert";

interface _Props {
    formattedValues: any[]
    selectedValue?: (string | null)
    defaultValue?: (string | null)
    menuPlacement?: (string | null)
    onSelect: Function
    isClearable?: boolean
    isDisabled?: boolean
}

export interface formattedOption {
    label: string
    value: string
    object: any
}

interface basicOption {
    label: string
    value: string
}

const BasicSelect: React.FC<_Props> = ({formattedValues, selectedValue, defaultValue, menuPlacement, onSelect, isClearable, isDisabled}) => {

    const [basicValue, setBasicValue] = useState<basicOption | null | undefined>();
    const [currentPlacement, setCurrentPlacement] = useState<"auto" | "top" | "bottom">(portalPlacement);
    const [error, setError] = useState<string | null | undefined>();

    const findValueInList = (value: string) => {
        if (formattedValues) {
            let result: formattedOption;
            for (var i = 0; i < formattedValues.length; i++) {
                const currentOption = formattedValues[i];
                if (currentOption.value === value) {
                    result = currentOption;
                    setBasicValue({
                        label: currentOption.label,
                        value: currentOption.value
                    });
                }
            }
        } else {
            setError("Could not find values");
        }
    };

    useEffect(() => {
        if (defaultValue) {
            findValueInList(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (selectedValue) {
            findValueInList(selectedValue);
        } else {
            setBasicValue(undefined);
        }
    }, [selectedValue]);

    useEffect(() => {
        if (menuPlacement === "auto" || menuPlacement === "top" || menuPlacement === "bottom") {
            setCurrentPlacement(menuPlacement);
        }
    }, [menuPlacement]);

    const handleOnChange = (event: any) => {
        if (event && event.object) {
            setBasicValue({
                label: event.label,
                value: event.value
            });
            onSelect(event.object);
        } else {
            setBasicValue(undefined);
            onSelect();
        }
    }

    return (
        <>
            {error && (<ErrorAlert width="12" error={error} />)}
            {formattedValues ?
                <Select
                    id="select-dropdown-component-input"
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    defaultValue={basicValue}
                    value={basicValue}
                    menuPortalTarget={document.body}
                    menuPlacement={currentPlacement}
                    isClearable={isClearable === false ? false : true}
                    isDisabled={isDisabled === true ? true : false}
                    isSearchable
                    options={formattedValues}
                    onChange={handleOnChange}
                />
                :
                <Spinner />
            }
        </>
    );
};

export default BasicSelect;