import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { Ring } from "../../models";
import { getRingsByOrganizationId } from "../../utilities/ring/Ring";

interface _Props {
    organizationId: string
    onSelect: Function
}

interface formattedOption {
    label: string
    value: string
    ring: Ring
}

let subscription: any;

const SelectRing: React.FC<_Props> = ({organizationId, onSelect}) => {

    const [formattedRings, setFormattedRings] = useState<formattedOption[] | null | undefined>();

    const formatRings = (rings: Ring[]) => {
        let formattedRings = [];
        for (var i = 0; i < rings.length; i++) {
            let object = {
                value: rings[i].id,
                label: rings[i].name,
                ring: rings[i]
            };
            formattedRings.push(object);
        }
        setFormattedRings(formattedRings);
    }

    useEffect(() => {
        async function getRings(organizationId: string) {
            const queryResult = await getRingsByOrganizationId(organizationId);
            if (queryResult.isSuccess) {
                formatRings(queryResult.result);
            }
        }

        async function setRingSubscription(organizationId: string) {
            // subscription = DataStore.observe(Ring).subscribe(msg => {
            //     getRings(organizationId);
            // });
        }

        if (organizationId) getRings(organizationId);
        if (organizationId) setRingSubscription(organizationId);
        
        return () => {
            if (subscription) subscription.unsubscribe();
        }
    }, [organizationId]);


    const handleOnChange = async (event: any) => {
        if (event && event.ring) {
            onSelect(event.ring);
        }
    }

    return (
        <>
            <Select
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                menuPortalTarget={document.body}
                isClearable
                options={formattedRings!}
                onChange={handleOnChange}
            />
        </>
    );
};

export default SelectRing;
