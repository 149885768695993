export interface EventDisciplineOption {
    name: string
    value: string
}

export function formatDisciplinesForDisplay(disciplineArray: string): string[] {
    let result = [];
    const splitArray = disciplineArray.split(";");
    for (var i = 0; i < splitArray.length; i++) {
        const disciplineValue = splitArray[i];
        const disciplineName = eventDisciplines.find(d => d.value === disciplineValue)?.name;
        if (disciplineName) result.push(disciplineName);
    }
    return result;
}

export function getEventDisciplinesAsArray(disciplineString: string): EventDisciplineOption[] {
    let result: EventDisciplineOption[] = [];
    const splitArray = disciplineString.split(";");
    for (var i = 0; i < splitArray.length; i++) {
        const disciplineValue = splitArray[i];
        const disciplineOption = eventDisciplines.find(d => d.value === disciplineValue);
        if (disciplineOption) result.push(disciplineOption);
    }
    return result;
}

export function getDisciplineOptionByValue(value: string): EventDisciplineOption {
    let result: EventDisciplineOption = {
        name: "",
        value: ""
    };
    eventDisciplines.forEach(option => {
        if (option.value === value) result = option; 
    });
    return result;
}

export function getDisciplineOptionByName(name: string): EventDisciplineOption {
    let result: EventDisciplineOption = {
        name: "",
        value: ""
    };
    eventDisciplines.forEach(option => {
        if (option.name === name) result = option; 
    });
    return result;
}

export const eventDisciplines = [
    {
        "name": "Andalusian Lusitano",
        "value": "andalusian_lusitano"
    },
    {
        "name": "Arabian",
        "value": "arabian"
    },
    {
        "name": "Carriage Pleasure Driving",
        "value": "carriage_pleasure_driving"
    },
    {
        "name": "Combined Driving",
        "value": "combined_driving"
    },
    {
        "name": "Connemara",
        "value": "connemara"
    },
    {
        "name": "Dressage",
        "value": "dressage"
    },
    {
        "name": "Endurance",
        "value": "endurance"
    },
    {
        "name": "English Pleasure",
        "value": "english_pleasure"
    },
    {
        "name": "Eventing",
        "value": "eventing"
    },
    {
        "name": "Friesian",
        "value": "friesian"
    },
    {
        "name": "Hackney Pony",
        "value": "hackney_pony"
    },
    {
        "name": "Hunter",
        "value": "hunter"
    },
    {
        "name": "Hunter Breeding",
        "value": "hunter_breeding"
    },
    {
        "name": "Hunter Equitation",
        "value": "hunter_equitation"
    },
    {
        "name": "Jumper",
        "value": "jumper"
    },
    {
        "name": "Jumping Seat Equitation",
        "value": "jumping_seat_equitation"
    },
    {
        "name": "Morgan",
        "value": "morgan"
    },
    {
        "name": "National Show Horse",
        "value": "national_show_horse"
    },
    {
        "name": "Para Equestrian Dressage",
        "value": "para_equestrian_dressage"
    },
    {
        "name": "Paso Fino",
        "value": "paso_fino"
    },
    {
        "name": "Polo",
        "value": "polo"
    },
    {
        "name": "Reining",
        "value": "reining"
    },
    {
        "name": "Roadster",
        "value": "roadster"
    },
    {
        "name": "Saddle Seat Equitation",
        "value": "saddle_seat_equitation"
    },
    {
        "name": "Saddlebred",
        "value": "saddlebred"
    },
    {
        "name": "SaddleSeat",
        "value": "saddleseat"
    },
    {
        "name": "Shetland",
        "value": "shetland"
    },
    {
        "name": "Vaulting",
        "value": "vaulting"
    },
    {
        "name": "Welsh",
        "value": "welsh"
    },
    {
        "name": "Western",
        "value": "western"
    },
    {
        "name": "Western Dressage",
        "value": "western_dressage"
    },
    {
        "name": "Other",
        "value": "other"
    }
];