import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import SelectState from "./SelectState";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import SelectCountry from "./SelectCountry";
import SelectAddressType from "./SelectAddressType";
import { createAddress } from "../../utilities/address/Address";
import ErrorAlert from "../Errors/ErrorAlert";
import { Address } from "../../models";
import { createAddressName } from "../../utilities/address/FormatAddress";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { CreateAddressInput } from "../../API";

interface _Props {
    address?: Address
    onSubmit: Function
}

const AddressForm: React.FC<_Props> = ({address, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [streetAddressLine1, setStreetAddressLine1] = useState<string>(address?.streetAddress1 || "");
    const [streetAddressLine2, setStreetAddressLine2] = useState<string>(address?.streetAddress2 || "");
    const [city, setCity] = useState<string>(address?.city || "");
    const [provState, setProvState] = useState<string>(address?.provState || "");
    const [zip, setZip] = useState<string>(address?.zip || "");
    const [country, setCountry] = useState<string>(address?.country || "");
    const [type, setType] = useState<string>(address?.type || "-");
    const [error, setError] = useState<string>("");

    const handleProvStateInputChange = (value: string) => {
        setIsDisabled(false);
        if (value && value !== "-") setProvState(value);
    }

    const handleCountryInputChange = (value: string) => {
        setIsDisabled(false);
        if (value && value !== "-") setCountry(value);
    }

    const handleAddressTypeInputChange = (value: string) => {
        setIsDisabled(false);
        if (value && value !== "-") setType(value);
    }

    const handleCreateAddress = async () => {
        try {
            const addressInput: CreateAddressInput = {
                name: createAddressName(user.id, type),
                type: type,
                streetAddress1: streetAddressLine1,
                streetAddress2: streetAddressLine2,
                city: city,
                provState: provState,
                zip: zip,
                country: country
            };
            const createResult = await createAddress(addressInput, GRAPHQL_AUTH_MODE.API_KEY);
            if (createResult.isSuccess) {
                onSubmit(createResult.result);
            } else {
                setError(createResult.message);
            }
        } catch (error: any) {
            console.log("An error occurred");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        await handleCreateAddress();
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Street Address Line 1</IonLabel>
                            <IonInput 
                                type="text"
                                value={streetAddressLine1}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setStreetAddressLine1(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Street Address Line 2</IonLabel>
                            <IonInput 
                                type="text"
                                value={streetAddressLine2}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setStreetAddressLine2(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">City</IonLabel>
                            <IonInput 
                                type="text"
                                value={city}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setCity(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <SelectState onInputChange={handleProvStateInputChange} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Postal Code</IonLabel>
                            <IonInput 
                                type="text"
                                value={zip}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setZip(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <SelectCountry onInputChange={handleCountryInputChange} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <SelectAddressType onInputChange={handleAddressTypeInputChange} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default AddressForm;