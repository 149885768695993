import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonLabel,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event, EventClass, PointTable, PrizeMoneyTable} from "../../../../../models";
import Header from "../../../../../components/Headers/Header";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../utilities/events/Event";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import { getEventClassById, getEventClassesByEventId, updateEventClass } from "../../../../../utilities/eventClass/EventClass";
import ClassPointTable from "../../../../../components/EventClass/EventClassForm/ClassPointTable";
import ClassPrizeMoney from "../../../../../components/EventClass/EventClassForm/ClassPrizeMoney";
import { Input } from "reactstrap";
import { alert, checkmark, chevronDown, chevronUp } from "ionicons/icons";
import EventResultTable from "../../../../../components/EventResult/EventResultTable";
import { UpdateEventClassInput } from "../../../../../API";
import Spinner from "../../../../../components/Spinners/Spinner";
import EventKeyInResultsTable from "../../../../../components/EventResult/EventKeyInResultsTable";
import VerifyEntriesTable from "../../../../../components/EventResult/VerifyEntriesTable";

interface EventPageProps extends RouteComponentProps<{
    id: string;
    classId: string;
}> {}

const EventAddClassResultsPage: React.FC<EventPageProps> = ({match}) => {

    const [isHideVerify, setIsHideVerify] = useState(true);
    const [isKeyInResults, setIsKeyInResults] = useState(true);
    const [isHideTemplates, setIsHideTemplates] = useState(false);
    const [isHideNotes, setIsHideNotes] = useState(false);
    const [event, setEvent] = useState<Event>();
    const [eventClass, setEventClass] = useState<EventClass | null | undefined>();
    const [eventClassNext, setEventClassNext] = useState<EventClass | null | undefined>();
    const [pointTable, setPointTable] = useState<PointTable | null | undefined>();
    const [prizeMoneyTable, setPrizeMoneyTable] = useState<PrizeMoneyTable | null | undefined>();
    const [prizeMoney, setPrizeMoney] = useState<number | null | undefined>();
    const [notes, setNotes] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    
    const clearForm = () => {
        setEvent(undefined);
        setEventClass(undefined);
        setEventClassNext(undefined);
        setPointTable(undefined);
        setPrizeMoney(undefined);
        setPrizeMoneyTable(undefined);
        setNotes("");
        setError("");
        setIsLoading(false);
    }

    async function getNextEventClass(eventId: string, currentClass: EventClass) {
        const queryResult = await getEventClassesByEventId(eventId);
        if (queryResult.isSuccess) {
            const classList: EventClass[] = queryResult.result;
            const filtered = classList.find((c: EventClass) => ((c && c.number) ? c.number : 0) === (((currentClass && currentClass.number) ? currentClass.number : 0) + 1));
            if (filtered) setEventClassNext(filtered);
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getEvent(eventId: string) {
        const queryResult = await getEventById(eventId);
        if (queryResult.isSuccess) {
            setEvent(queryResult.result);
            return queryResult.result;
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    async function getEventClass(classId: string) {
        const queryResult = await getEventClassById(classId);
        if (queryResult.isSuccess) {
            const eventClass: EventClass = queryResult.result;
            setEventClass(queryResult.result);

            if (eventClass) {
                if (eventClass.pointTable) setPointTable(eventClass.pointTable);
                if (eventClass.prizeMoneyTable) setPrizeMoneyTable(eventClass.prizeMoneyTable);
                if (eventClass.prizeMoney) setPrizeMoney(parseFloat(eventClass.prizeMoney));
            }

            return queryResult.result;
        } else {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    const getData = async (eventId: string, eventClassId: string) => {
        clearForm();
        setIsLoading(true);
        const currentEventClass = await getEventClass(eventClassId);
        const event = await getEvent(eventId);
        await getNextEventClass(eventId, currentEventClass);
        setIsLoading(false);
    }

    useEffect(() => {
        getData(match.params.id, match.params.classId);
    }, [match, match.params.id, match.params.classId]);

    const handleSelectPointTable = (type: string, pointTable: PointTable) => {
        setPointTable(pointTable);
    }

    const handleSelectPrizeMoney = (type: string, prizeMoney: number) => {
        setPrizeMoney(prizeMoney);
    }

    const handleSelectPrizeMoneyTable = (type: string, prizeMoneyTable: PrizeMoneyTable) => {
        setPrizeMoneyTable(prizeMoneyTable);
    }

    const handleSaveTemplates = async () => {
        if (eventClass) {
            let input: UpdateEventClassInput = {
                id: eventClass.id,
                eventClassPointTableId: pointTable?.id,
                prizeMoney: prizeMoney ? prizeMoney.toString() : undefined,
                eventClassPrizeMoneyTableId: prizeMoneyTable?.id
            };
            await updateEventClass(input);
        }
    }

    const handleSaveNotes = async () => {
        if (eventClass) {
            let input: UpdateEventClassInput = {
                id: eventClass.id,
                note: notes
            };
            await updateEventClass(input);
        }
    }

    // Used after the Verify Entries function updates the boolean for completed verification
    const handleUpdateEventClass = (updatedEventClass: EventClass) => {
        setEventClass(updatedEventClass);
        setIsHideVerify(true);
    }

    return (
        <IonPage className="bg-light">
            <Header forwardBtn={eventClassNext ? "Go to Class " + eventClassNext.number : undefined} forwardBtnPath={(event && eventClassNext) ? (`/index/staff/event/${event.id}/results/class/${eventClassNext.id}`) : ""} />
            <IonContent>
                <PageTitle title={event ? event.name : "results"} />
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="8" className="ion-text-left">
                        <h3>{eventClass?.number ? "#" + eventClass.number + ": ": ""} {eventClass?.name ? eventClass?.name : "Loading..."}</h3>
                    </IonCol>
                </IonRow>  
                {isLoading ?
                    <Spinner />
                    :
                    <>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>
                                        <IonRow onClick={() => setIsHideVerify(!isHideVerify)}>
                                            <IonCol size="10"> 
                                                <span className={(eventClass && eventClass.completedVerifyEntries) ? "text-success" : "text-warning"}>
                                                    <IonIcon icon={(eventClass && eventClass.completedVerifyEntries) ? checkmark : alert}  />
                                                </span>
                                                Verify Entries
                                            </IonCol>
                                            <IonCol size="2" className="ion-text-right">
                                                <IonIcon icon={isHideVerify ? chevronUp : chevronDown} />
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    <IonCardSubtitle>
                                        First, click to expand this section and enter the back numbers that are on the judge's card. Fix any mistakes here before entering results.
                                    </IonCardSubtitle>
                                    <IonCardContent>
                                        {(!isHideVerify) && (
                                            <IonRow>
                                                <IonCol>
                                                    {eventClass ?
                                                        <>
                                                            <VerifyEntriesTable eventClass={eventClass} onUpdateEventClass={(ec: EventClass) => handleUpdateEventClass(ec)}/>
                                                        </>
                                                        :
                                                        <p>Loading ... </p>
                                                    }
                                                </IonCol>
                                            </IonRow>
                                        )}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            {error && <ErrorAlert width="12" error={error} />}
                            <IonCol size="12">
                                <IonCard mode="md" className="ion-padding bg-white">
                                    <IonCardTitle>
                                        <IonRow>
                                            <IonCol>{eventClass?.number ? eventClass.number + " - ": ""} {eventClass?.name ? eventClass?.name : "Loading..."} Results</IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    <IonCardSubtitle>
                                        This is where you will add results to a class. The points and prize money templates (scroll down) will auto-calulate values as you enter your results. 
                                    </IonCardSubtitle>
                                    <IonCardContent>
                                        <IonRow>
                                            <IonCol>
                                                {eventClass ?
                                                    <>
                                                        <IonRow className="ion-justify-content-center">
                                                            <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                                                <IonButton color={isKeyInResults ? "primary" : "light"} onClick={() => setIsKeyInResults(true)}>Key In Results</IonButton>
                                                            </IonCol>
                                                            <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                                                <IonButton color={isKeyInResults ? "light" : "primary"} onClick={() => setIsKeyInResults(false)}>Full Results</IonButton>
                                                            </IonCol>
                                                        </IonRow>
                                                        <hr />
                                                        <IonRow>
                                                            <IonCol>
                                                                {isKeyInResults ?
                                                                    <>
                                                                        <EventKeyInResultsTable isEditable={true} prizeMoneyTable={prizeMoneyTable} eventClass={eventClass} />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <EventResultTable isEditable={true} pointTable={pointTable} prizeMoney={prizeMoney} prizeMoneyTable={prizeMoneyTable} eventClass={eventClass} />
                                                                    </>
                                                                }
                                                            </IonCol>
                                                        </IonRow>
                                                    </>
                                                    :
                                                    <p>Loading ... </p>
                                                }
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                <IonCard color="white" mode="md">
                                    <IonCardTitle>
                                        <IonRow onClick={() => setIsHideTemplates(!isHideTemplates)}>
                                            <IonCol size="10">
                                                <h5>Points and Prize Money</h5>
                                            </IonCol>
                                            <IonCol size="2" className="ion-text-right">
                                                <IonIcon icon={isHideTemplates ? chevronDown : chevronUp} />
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    {!isHideTemplates && (
                                        <IonCardContent>
                                            <p className="text-danger description">If you edit these after entering the results, click the SAVE button to resave the results with the new Point and Prize Money values.</p>
                                            <IonRow>
                                                <IonCol size="12">
                                                    {event?.organizationId && <ClassPointTable organizationId={event?.organizationId} selectedPointTable={pointTable} onSelectPointTable={(type: string, pointTable: PointTable) => handleSelectPointTable(type, pointTable)} />}
                                                </IonCol>
                                                <IonCol size="12">
                                                    {event?.organizationId && <ClassPrizeMoney organizationId={event?.organizationId} selectedPrizeMoney={prizeMoney} selectedPrizeMoneyTable={prizeMoneyTable} onSelectPrizeMoney={handleSelectPrizeMoney} onSelectPrizeMoneyTable={(type: string, prizeMoneyTable: PrizeMoneyTable) => handleSelectPrizeMoneyTable(type, prizeMoneyTable)} />}
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-align-items-center">
                                                <IonCol sizeXs="12" sizeMd="6" offsetMd="3" className="ion-text-center">
                                                    <IonButton color="success" expand="full" onClick={handleSaveTemplates}>Save</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    )}
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12">
                                <IonCard color="white" mode="md">
                                    <IonCardTitle>
                                        <IonRow onClick={() => setIsHideNotes(!isHideNotes)}>
                                            <IonCol size="10">
                                                <h5>Add Note</h5>
                                            </IonCol>
                                            <IonCol size="2" className="ion-text-right">
                                                <IonIcon icon={isHideNotes ? chevronDown : chevronUp} />
                                            </IonCol>
                                        </IonRow>
                                    </IonCardTitle>
                                    {!isHideNotes && (
                                        <IonCardContent>
                                            <IonRow>
                                                <IonCol>
                                                    <IonLabel>Notes</IonLabel>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol>
                                                    <Input
                                                        rows="3"
                                                        type="textarea"
                                                        name="notes"
                                                        value={notes}
                                                        spellCheck="true"
                                                        onChange={(event) => setNotes(event.target.value)}
                                                        data-hj-whitelist
                                                    />
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-align-items-center">
                                                <IonCol sizeXs="12" sizeMd="6" offsetMd="3" className="ion-text-center">
                                                    <IonButton color="success" expand="full" onClick={handleSaveNotes}>Save</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    )}
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default EventAddClassResultsPage;