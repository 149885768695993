import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonToggle,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Input } from "reactstrap";
import { CreateEventRulesInput, UpdateEventRulesInput } from "../../API";
import { PersonContext } from "../../context/PersonContext";
import { Event, EventRules } from "../../models";
import { createEventRules, deleteEventRules, updateEventRules } from "../../utilities/eventRules/EventRules";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";

interface _Props {
    event: Event
    selectedEventRules?: (EventRules | null)
    onSubmit: Function
}

const RuleForm: React.FC<_Props> = ({selectedEventRules, event, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState<string>("");
    const [text, setText] = useState<string>("");
    const [requiresAgreementPerEntry, setRequiresAgreementPerEntry] = useState(true);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (selectedEventRules) {
            setTitle(selectedEventRules.title || "");
            setText(selectedEventRules.text  || "");
            setRequiresAgreementPerEntry(selectedEventRules.requiresAgreementPerEntry || false);
        } else {
            setTitle("");
            setText("");
            setRequiresAgreementPerEntry(true);
        }
    }, [selectedEventRules]);

    const verifyForm = () => {
        if (!title) {
            setError("Please include an informative title so exhibitors know what this rules is.");
            return false;
        }
        if (!text) {
            setError("Please include the text of the rule. This includes a detailed explanation of the rule and how you expect exhibitors to follow it.");
            return false;
        }
        return true;
    }

    const clearForm = () => {
        setTitle("");
        setText("");
        setRequiresAgreementPerEntry(true);
        selectedEventRules = undefined;
    }

    const handleCreateRules = async () => {
        // Create the basic rules
        const input: CreateEventRulesInput = {
            createdBy: user.id,
            eventId: event.id,
            title: title,
            text: text,
            requiresAgreementPerEntry: requiresAgreementPerEntry
        };
        const createResult = await createEventRules(input);
        if (createResult.isSuccess) {
            onSubmit("create", createResult.result);
            clearForm();
        } else {
            setError("Sorry, we had a problem creating the rule.");
        }
    }

    const handleUpdateEventRules = async () => {
        if (selectedEventRules) {
            const input: UpdateEventRulesInput = {
                id: selectedEventRules.id,
                title: title,
                text: text,
                requiresAgreementPerEntry: requiresAgreementPerEntry
            };
            const updateResult = await updateEventRules(input);
            if (updateResult.isSuccess) {
                clearForm();
                onSubmit("update", updateResult.result);
            } else {
                setError("Sorry, we had a problem updating the rule.");
            }
        } else {
            setError("Sorry, we did not find a rule to update.");
        }
    }

    const handleDelete = async () => {
        setError("");
        if (selectedEventRules) {
            setIsLoading(true);
            const deleteResult = await deleteEventRules({id: selectedEventRules.id});
            if (deleteResult.isSuccess) {
                clearForm();
                onSubmit("delete", deleteResult.result);
            } else {
                setError("A problem occured. The Rule could not be deleted.");
            }
            setIsLoading(true);
        } else {
            setError("A problem occured. The Rule was not found.");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            if (selectedEventRules) {
                await handleUpdateEventRules();
            } else {
                await handleCreateRules();
            }
            setIsDisabled(true);
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Title</IonLabel>
                            <IonInput 
                                type="text"
                                value={title}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setTitle(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel position="floating" className="text-darker pl-3">Text</IonLabel>
                        <Input
                            id="create-org-description"
                            rows="10"
                            type="textarea"
                            name="description"
                            value={text} 
                            spellCheck="true"
                            placeholder="Enter an explanation of the rule and how you expect participants to follow it here..." 
                            onChange={e => {
                                if(isMounted) setIsDisabled(false);
                                else setIsMounted(true);
                                setText(e.target.value!);
                            }}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white" lines="none">
                            <IonLabel position="stacked">Require each entry to agree?</IonLabel>
                            <IonToggle color="tertiary" checked={requiresAgreementPerEntry} onIonChange={e => {setRequiresAgreementPerEntry(e.detail.checked); setIsDisabled(false);}}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            {selectedEventRules && (
                                <IonCol sizeMd="4" className="ion-text-center">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="danger"
                                        expand="block"
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </IonButton>
                                </IonCol>
                            )}
                            <IonCol sizeMd="4" className="ion-text-center">
                                <IonButton
                                    disabled={isDisabled}
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    {isDisabled ? "Saved" : "Save"}
                                </IonButton>
                            </IonCol>
                        </>   
                    }
                </IonRow>    
            </form>
        </>
    );
};

export default RuleForm;