import { 
    IonCol, 
    IonRow
} from "@ionic/react";
import React, { useState } from "react";
import { Event } from "../../../models";
import EventReportCard from "../EventReportCard";
import { generateRevenueTotal } from "../../../utilities/reports/RevenueTotal";
import { generateRevenueFromCollectedPayments } from "../../../utilities/reports/RevenueFromCollectedPayments";
import { generateExpensesFromPrizeMoney } from "../../../utilities/reports/ExpensesFromPrizeMoney";
import { generateRevenueFromStallFees } from "../../../utilities/reports/RevenueFromStabling";
import { generateRevenueFromEventFees } from "../../../utilities/reports/RevenueFromEventFees";
import { generateRevenueFromEntryFees } from "../../../utilities/reports/RevenueFromEntryFees";

interface _Props {
    event: Event
}

const EventReportFinancialSection: React.FC<_Props> = ({event}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [progressMessage, setProgressMessage] = useState("");
    const [error, setError] = useState("");

    const generateRevenueFromClassFees = async () => {
        if (event) {
            setIsLoading(true); 
            await generateRevenueFromEntryFees(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateRevenueFromOtherFees = async () => {
        if (event) {
            setIsLoading(true); 
            await generateRevenueFromEventFees(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateRevenueFromStablingFees = async () => {
        if (event) {
            setIsLoading(true); 
            await generateRevenueFromStallFees(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateExpenseFromPrizeMoney = async () => {
        if (event) {
            setIsLoading(true); 
            await generateExpensesFromPrizeMoney(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateTotalRevenue = async () => {
        if (event) {
            setIsLoading(true); 
            await generateRevenueTotal(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateCollectedPaymentInfo = async () => {
        if (event) {
            setIsLoading(true); 
            await generateRevenueFromCollectedPayments(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }
    const reportLinks = (
        <IonRow>
            <IonCol>
                <IonRow>
                    <IonCol>
                        <p onClick={generateRevenueFromClassFees} className="link ion-text-wrap">Revenue from Class / Division Entry Fees</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={generateRevenueFromOtherFees} className="link ion-text-wrap">Revenue from Event Fees</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={generateRevenueFromStablingFees} className="link ion-text-wrap">Revenue from Stabling Fees</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={generateTotalRevenue} className="link ion-text-wrap">Total Revenue</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={generateCollectedPaymentInfo} className="link ion-text-wrap">Total Collected Payments</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={generateExpenseFromPrizeMoney} className="link ion-text-wrap">Expenses from Prize Money</p>
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonRow>
    )

    return (
        <>
            <EventReportCard title="Financial Reports" content={reportLinks} isLoading={isLoading} progressMessage={progressMessage} error={error} />
        </>
    );
};

export default EventReportFinancialSection;
