import {
    IonButton,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Spinner from "../../Spinners/Spinner";
import { Organization } from "../../../models";
import ErrorAlert from "../../Errors/ErrorAlert";
import {creditBlockPricingStructure, BlockTier} from "../../../data/content/CreditBlockPricing";
import { getSourceById } from "../../../utilities/stripe/GetSource";
import { StripeSource } from "../../../interfaces/Stripe";
import { getOrganizationById } from "../../../utilities/organization/Organization";
import { createCreditBlockCheckoutSession } from "../../../utilities/stripe/Checkout";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_live_EJGhy2cBbcqIpfN95FhieHL5006DOpYcQi");

interface _Props {
    organizationId?: (string | null)
    eventId?: (string | null)
}

const BuyCreditsForm: React.FC<_Props> = ({organizationId, eventId}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentOrganization, setCurrentOrganization] = useState<Organization | null | undefined>();
    const [organizationSource, setOrganizationSource] = useState<StripeSource | null | undefined>();
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const getSource = async (sourceId: string) => {
        setIsLoading(true);
        const queryResult = await getSourceById(sourceId);
        const source: StripeSource = queryResult.result;
        setOrganizationSource(source);
        setIsLoading(false);
    }

    async function getOrganization(organizationId: string) {
        setIsLoading(true);
        const queryResult = await getOrganizationById(organizationId);
        if (queryResult.isSuccess) {
            const organization: Organization = queryResult.result;
            setCurrentOrganization(organization);
            if (organization.paymentMethodId) getSource(organization.paymentMethodId);
            else setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (organizationId) {
            getOrganization(organizationId);
        }
    }, [organizationId]);

    const handleCheckout = async (tier: BlockTier) => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        if (tier) {
            const stripe = await stripePromise;
            const stripeAccountId = currentOrganization?.stripeCustomerId || "";
            const productId = tier.priceId || "";
            const successURL = eventId ? "https://www.ringsidepro.com/index/staff/event/" + eventId + "/alerts" : organizationId ? "https://www.ringsidepro.com/index/staff/organization/text-alerts/" + organizationId : "https://www.ringsidepro.com/index/home";
            const sessionResult = await createCreditBlockCheckoutSession(stripeAccountId, productId, "1", successURL, successURL);
            if (sessionResult.isSuccess) {
                const session = sessionResult.result;

                // When the customer clicks on the button, redirect them to Checkout.
                if (stripe) {
                    const result = await stripe.redirectToCheckout({
                        sessionId: session.id,
                    });
                    if (result.error) {
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer
                        // using `result.error.message`.
                        if (result.error.message) setError(result.error.message);
                    }
                }
            } else {
                setError("Sorry, we could not perform a checkout out at this time.");
            }
        } else {
            setError("Could not find the credit tier.");
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {isLoading ?
                <Spinner />
                :
                <>
                    <IonRow>
                        <IonCol>
                            {creditBlockPricingStructure.map((object, index) => (
                                <div key={index}>
                                    <IonRow>
                                        <IonCol size="12">
                                            <h2>{object.numberCredits.toLocaleString()} credits</h2>
                                            <h3>{object.useCase}</h3>
                                            <h3>${object.cost.toFixed(2)}</h3>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonButton
                                                color="tertiary"
                                                onClick={() => {
                                                    handleCheckout(object)
                                                }}
                                            >
                                                Checkout
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                    <hr/>
                                </div>
                            ))}
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default BuyCreditsForm;