import { API } from 'aws-amplify';
import { Result } from "../../interfaces/Result";

/**
 * Customer is an individual Stripe customer
 * More details here: https://stripe.com/docs/api/sources/attach
 * 
 * @param {string} customerid 
 * @param {string} sourceid 
 */
export async function attachSourceToCustomer(customerid: string, sourceid: string) {
    try {
        let queryParams = "?customerid=" + customerid;
        queryParams = queryParams + "&sourceid=" + sourceid;
        const path = "/payments/attach-source" + queryParams;
        
        const response = await API.get("Payments", path, {});

        const responseBody = JSON.parse(response.body);
        const requestResult = responseBody.response;

        if (requestResult) {
            let result: Result = {isSuccess: true, type: "Stripe", result: requestResult, message: "Successfully attached the source to the customer."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "Stripe", result: requestResult, message: "Could not attach the source to the customer."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Could not attach the source to the customer."};
        return result; 
    }
}

/**
 * Account is a Stripe connect account - aka an organization
 * More details here: https://stripe.com/docs/payments/payment-methods/connect#creating-paymentmethods-directly-on-the-connected-account 
 * 
 * @param {string} customerid 
 * @param {string} sourceid 
 */
 export async function attachSourceToAccount(accountid: string, sourceid: string) {
    try {
        let queryParams = "?accountid=" + accountid;
        queryParams = queryParams + "&sourceid=" + sourceid;
        const path = "/payments/attach-source-to-account" + queryParams;
        
        const response = await API.get("Payments", path, {});

        const responseBody = JSON.parse(response.body);
        const requestResult = responseBody.response;

        if (requestResult) {
            let result: Result = {isSuccess: true, type: "Stripe", result: requestResult, message: "Successfully attached the source to the customer."};
            return result; 
        } else {
            let result: Result = {isSuccess: false, type: "Stripe", result: requestResult, message: "Could not attach the source to the customer."};
            return result; 
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Stripe", result: error, message: "Could not attach the source to the customer."};
        return result; 
    }
}