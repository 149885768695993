import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonSearchbar,
} from "@ionic/react";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import React, { useContext, useEffect, useState } from "react";
import { EventAuditor } from "../../../../models";
import { PersonContext } from "../../../../context/PersonContext";
import { getEventAuditorsByPersonId, getEventAuditorsByPhoneNumberSearch } from "../../../../utilities/eventAuditor/EventAuditor";
import { useHistory } from "react-router";
import Spinner from "../../../../components/Spinners/Spinner";

const SubscriberEventsPage: React.FC = () => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [eventAuditorInstances, setEventAuditorInstances] = useState<EventAuditor[] | null | undefined>();
    const [phoneNumberInput, setPhoneNumberInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const getEventAuditors = async () => {
        setIsLoading(true);
        const queryResult = await getEventAuditorsByPersonId(user.id);
        if (queryResult.isSuccess) {
            setEventAuditorInstances(queryResult.result);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getEventAuditors();
    }, []);

    const handleSearchInput = async (input: string) => {
        setIsLoading(true);
        // If search input is empty, reset to all accepted entries
        if (!input || input === "") {
            await getEventAuditors();
            return;
        }

        const queryResult = await getEventAuditorsByPhoneNumberSearch(input);
        if (queryResult.isSuccess) {
            setEventAuditorInstances(queryResult.result);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    const navigateToPage = (id: string) => {
        const path = "/index/alerts/subscriber/" + id;
        history.push(path);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Your Alerts" />
                <IonRow className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Your Subscriptions</IonCardTitle>
                            <IonCardSubtitle>
                                <IonRow>
                                    <IonCol>
                                        Click on a row to adjust the settings.
                                    </IonCol>
                                </IonRow>
                            </IonCardSubtitle>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol size="12">
                                        <IonSearchbar
                                            color="white"  
                                            placeholder="Search by phone #" 
                                            value={phoneNumberInput} 
                                            onIonChange={e => {
                                                setPhoneNumberInput(e.detail.value!)
                                                handleSearchInput(e.detail.value!)
                                            }}
                                        />
                                    </IonCol>
                                </IonRow>
                                {isLoading ? 
                                    <Spinner />
                                    :
                                    <>
                                        {(eventAuditorInstances && eventAuditorInstances.length > 0) ? 
                                            <IonList className="bg-white">
                                                {eventAuditorInstances.map((eventAuditor, index) => (
                                                    <IonItem key={index} onClick={() => navigateToPage(eventAuditor.id)}>
                                                        <IonLabel>
                                                            <IonRow>
                                                                <IonCol>
                                                                    <p className="ion-text-wrap">{eventAuditor.event?.name}</p>
                                                                </IonCol>
                                                                <IonCol>
                                                                    <p className="ion-text-wrap">{eventAuditor.name}</p>
                                                                </IonCol>
                                                                <IonCol>
                                                                    <p className="ion-text-wrap">{eventAuditor.phoneNumber}</p>
                                                                </IonCol>
                                                                <IonCol>
                                                                    <p className="ion-text-wrap">Status: <span className={eventAuditor.status === "paused" ? "text-danger" : "text-success"}>{eventAuditor.status === "paused" ? "Paused" : "Active"}</span></p>
                                                                </IonCol>
                                                            </IonRow>
                                                        </IonLabel>
                                                    </IonItem>
                                                ))}
                                            </IonList>
                                            :
                                            <p>None found.</p>
                                        }
                                    </>
                                }
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default SubscriberEventsPage;