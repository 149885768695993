import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { EventEntry, Rider } from "../../../models";
import { getRiderById } from "../../../utilities/rider/Rider";

interface _Props {
    entry: EventEntry
    secondary?: boolean
}

const DisplayEntryRiderInfo: React.FC<_Props> = ({entry, secondary}) => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [rider, setRider] = useState<Rider | null | undefined>();

    const getRider = async (entry: EventEntry) => {
        let riderId = "";
        if (secondary) {
            riderId = entry.secondRiderId || "";
        } else {
            riderId = entry.riderId || "";
        }

        if (riderId) {
            const queryResult = await getRiderById(riderId);
            if (queryResult.isSuccess) {
                setRider(queryResult.result);
            }
        }
    }

    useEffect(() => {
        if (entry) {
            setIsCollapsed(true);
            getRider(entry);
        }
    }, [entry]);

    return (
        <>
        {rider && (
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {entry ? <IonRow><h2>Rider{secondary && <span>#2</span>}: {rider.name}</h2></IonRow> : <h2>No Rider</h2> }
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        <>
                            <IonRow>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <p>Rider Name: {rider.name}</p>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    {rider.location ? <p>Rider Location: {rider.location}</p> : ""}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <p>Rider Age: {rider.age}</p>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <p>Rider Status: {rider.isProfessional ? "Professional" : "Amateur"}</p>
                                </IonCol>
                            </IonRow>
                        </>
                    </IonCardContent>
                )}
            </IonCard>
            )}
        </>
    );
};

export default DisplayEntryRiderInfo;