import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateGameInputInput, CreateGameInputMutation, DeleteGameInputInput, DeleteGameInputMutation, GetGameInputQuery, ListGameInputsQuery, UpdateGameInputInput, UpdateGameInputMutation } from "../../API";
import moment from "moment";
import { GameInput } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: gameInput. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created gameInput.
*/
export async function createGameInput(input: CreateGameInputInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "GameInput", "No input", "Create GameInput received no input.");
   try {
        const fullInput: CreateGameInputInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createGameInput, { input: fullInput }))) as GraphQLResult<CreateGameInputMutation>;
       else result = (await API.graphql({
           query: mutations.createGameInput,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateGameInputMutation>;
       const gameInput = result.data?.createGameInput;
       return formatResult(true, "GameInput", gameInput, "Successfully created the gameInput.");
   } catch (error: any) {
       return formatResult(false, "GameInput", error, "Error creating record in the database for type: gameInput");
   }
}

/**
* Handle updating a new record in the database for type: gameInput. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated gameInput.
*/
export async function updateGameInput(input: UpdateGameInputInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "GameInput", "No input", "Update GameInput received no input.");
   try {
        const fullInput: UpdateGameInputInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateGameInput, { input: fullInput }))) as GraphQLResult<UpdateGameInputMutation>;
       else result = (await API.graphql({
           query: mutations.updateGameInput,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateGameInputMutation>;
       const gameInput = result.data?.updateGameInput;
       return formatResult(true, "GameInput", gameInput, "Successfully updated the gameInput.");
   } catch (error: any) {
       return formatResult(false, "GameInput", error, "Error updating record in the database for type: gameInput");
   }
}

/**
* Handle deleting a new record in the database for type: gameInput. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted gameInput.
*/
export async function deleteGameInput(input: DeleteGameInputInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "GameInput", "No input", "Delete GameInput received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteGameInput, { input: input }))) as GraphQLResult<DeleteGameInputMutation>;
       else result = (await API.graphql({
           query: mutations.deleteGameInput,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteGameInputMutation>;
       const gameInput = result.data?.deleteGameInput;
       return formatResult(true, "GameInput", gameInput, "Successfully deleted the gameInput.");
   } catch (error: any) {
       return formatResult(false, "GameInput", error, "Error deleting record in the database for type: gameInput");
   }
}

/**
* Get all records in the database for type: gameInput. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the gameInput object
*/
export async function getAllGameInputs(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listGameInputs,
            variables: {
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListGameInputsQuery>;

        let items = result.data?.listGameInputs?.items as GameInput[];
        let nextToken = result.data?.listGameInputs?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listGameInputs,
                variables: {
                    limit: 1000,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListGameInputsQuery>;

            const nextItems = nextResult.data?.listGameInputs?.items as GameInput[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listGameInputs?.nextToken;
        }

        return formatResult(true, "GameInput", items, "Successfully got the GameInputs.");
    } catch (error: any) {
        return formatResult(false, "GameInput", error, "Error reading record in the database for type: gameInputs");
    }
}

/**
* Read a specific record in the database for type: gameInput. 
* 
* @param {string}  id                  The gameInput id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the gameInput object
*/
export async function getGameInputById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getGameInput,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetGameInputQuery>;
       const gameInput = result.data?.getGameInput;
       return formatResult(true, "GameInput", gameInput, "Successfully got the gameInput.");
   } catch (error: any) {
       return formatResult(false, "GameInput", error, "Error reading record in the database for type: gameInput");
   }
}

/**
* Get all records in the database for type: gameInput. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the gameInput object
*/
export async function getGameInputsByEvent(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.gameInputsByEventId,
            variables: {
                limit: 100,
                eventId: eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.gameInputsByEventId?.items as GameInput[];
        let nextToken = result.data?.gameInputsByEventId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.gameInputsByEventId,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.gameInputsByEventId?.items as GameInput[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.gameInputsByEventId?.nextToken;
        }

        return formatResult(true, "GameInput", items, "Successfully got the GameInputs.");
    } catch (error: any) {
        return formatResult(false, "GameInput", error, "Error reading record in the database for type: gameInputs");
    }
}