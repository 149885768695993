import React, { useState } from "react";
import {
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import { Barn, Rider } from "../../../models";
import { Label } from "reactstrap";
import SelectState from "../../Address/SelectState";
import DatePicker from "../../DatePicker/DatePicker";
import RiderSelectStatus from "../../Rider/RiderSelectStatus";
import SelectOrCreateRider from "../../Rider/SelectOrCreateRider";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";

interface _Props {
    selectedBarn?: (Barn | null | undefined);
}

const SelectRiderSection: React.FC<_Props> = ({ selectedBarn }) => {
    const [selectedRider, setSelectedRider] = useState<Rider | undefined | null>(undefined);
    const [riderDOB, setRiderDOB] = useState<string>("");
    const [riderStatus, setRiderStatus] = useState<string>("-");
    const [riderCity, setRiderCity] = useState<string>("-");
    const [riderState, setRiderState] = useState<string>("");

    const handleSelectedRider = (rider?: Rider, name?: string) => {
        // Set the rider object
        if (rider) {
            setSelectedRider(rider);
    
            // Set the rider's city/state
            if (rider && rider.address) {
                setRiderCity(rider.address?.city || "");
                setRiderState(rider.address?.provState || "-");         
            } else {
                setRiderCity("");
                setRiderState("-");
            }

            // Set the rider's status
            if (rider.isProfessional !== undefined) {
                setRiderStatus(rider.isProfessional ? "Professional" : "Amateur");
            } else {
                setRiderStatus("-");
            }
        } else {
            // Rider cleared, reset the state
            setSelectedRider(null);
            setRiderDOB("");
            setRiderStatus("-");
            setRiderCity("");
            setRiderState("-");
        }
    }

    const handleStateSelection = (value: string) => {
        setRiderState(value);
    };
    const handleDateChange = (value: string) => {
        setRiderDOB(value);
    };

    const handleRiderStatusChange = (value: string) => {
        setRiderStatus(value);
    };

    return (
        <>
            {/* First Row - Rider Name and Status */}
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <Label>
                        Name
                        <RequiredInputIndicator />
                    </Label>
                    <SelectOrCreateRider 
                        selectedBarn={selectedBarn}
                        onSelect={handleSelectedRider}
                    />
                </IonCol>
                {/* Rider Status */}
                <IonCol sizeXs="12" sizeMd="6">
                    <RiderSelectStatus 
                        rider={selectedRider}
                        isRequired={false} 
                        onChange={handleRiderStatusChange}
                    />
                </IonCol>
            </IonRow>

            {/* Second Row - DOB, City and State */}
            <IonRow>
                {/* Date - Rider DOB */}
                <IonCol sizeXs="12" sizeMd="6" className="py-0">
                    <DatePicker 
                        label="Birth Date" 
                        isRequired={true} 
                        onChange={handleDateChange} 
                        value={riderDOB}
                    />
                </IonCol>
                {/* Location - City */}
                <IonCol sizeXs="12" sizeMd="4.5">
                    <IonItem color="white">
                        <IonLabel position="stacked">
                            City
                            <RequiredInputIndicator />
                        </IonLabel>
                        <IonInput 
                            type="text"
                            value={riderCity}
                            aria-required={true}
                            onIonChange={e => {setRiderCity(e.detail.value!)}}
                        />
                    </IonItem>              
                </IonCol>
                {/* Location - State */}
                <IonCol sizeXs="12" sizeMd="1.5">
                    <SelectState 
                        selectedValue={riderState}
                        isRequired={true}
                        onInputChange={handleStateSelection} 
                    />
                </IonCol>
            </IonRow>
        </>
    );
};

export default SelectRiderSection;
