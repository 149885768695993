import {
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
} from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Event, EventDay } from "../../../models";
import { getEventDaysByEventId } from "../../../utilities/eventDay/EventDay";

interface _Props {
    event: Event
}

const EventDatesForm: React.FC<_Props> = ({event}) => {

    const [eventDates, setEventDates] = useState<EventDay[] | null | undefined>();

    useEffect(() => {
        async function getEventDatesByEventId(eventId: string) {
            const queryResult = await getEventDaysByEventId(eventId);
            if (queryResult.isSuccess) {
                setEventDates(queryResult.result);
            }
        }

        if (event) getEventDatesByEventId(event.id);
    }, [event]);

    const getStartDate = (): string => {
        let value = "";
        
        if (eventDates && eventDates.length) {
            let startDateObject = eventDates[0];
            let startDate = startDateObject.awsdate || "9999-99-99";

            for (var i = 0; i < eventDates?.length; i++) {
                const currentDate = eventDates[i];
                if (currentDate.awsdate && moment(currentDate.awsdate).isBefore(moment(startDate))) {
                    startDate = currentDate.awsdate;
                    startDateObject = currentDate;
                }
            }
            value = startDateObject.dayofweek + " " + startDateObject.month + " " + startDateObject.date + ", " + startDateObject.year;
        } 

        return value;
    }

    const getEndDate = (): string => {
        let value = "";
        
        if (eventDates && eventDates.length) {
            let endDateObject = eventDates[0];
            let endDate = endDateObject.awsdate || "0000-00-00";

            for (var i = 0; i < eventDates?.length; i++) {
                const currentDate = eventDates[i];
                if (currentDate.awsdate && moment(currentDate.awsdate).isAfter(moment(endDate))) {
                    endDate = currentDate.awsdate;
                    endDateObject = currentDate;
                }
            }
            value = endDateObject.dayofweek + " " + endDateObject.month + " " + endDateObject.date + ", " + endDateObject.year;
        } 

        return value;
    }

    return (
        <form>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">Start Date</IonLabel>
                        <IonInput 
                            type="text"
                            value={getStartDate()}
                            disabled={true}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonItem color="white">
                        <IonLabel position="stacked">End Date</IonLabel>
                        <IonInput 
                            type="text"
                            value={getEndDate()}
                            disabled={true}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonText>
                        You cannot edit the event dates without help from a RingSide Pro staff member. Please contact us at hello@ringsidepro.com
                    </IonText>
                </IonCol>
            </IonRow>
        </form>
    );
};

export default EventDatesForm;