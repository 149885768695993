import { Result } from "../../interfaces/Result";
import { Barn } from "../../models";
import { deleteBarnMember, getBarnMembersByBarnId } from "../barnMember/BarnMember";
import { deleteBarn } from "./Barn";

export async function removeAllBarnData(barn: Barn) {
    let result: Result = {isSuccess: true, type: "Barn", result: null, message: "Successfully removed the barn data."};

    const barnMemberResult = await getBarnMembersByBarnId(barn.id);
    if (barnMemberResult.isSuccess) {
        const memberList = barnMemberResult.result;

        if (memberList && memberList.length) {
            for (let i = 0; i < memberList.length; i++) {
                const currentBarnMember = memberList[i];
                
                const deleteBarnMemberResult = await deleteBarnMember({id: currentBarnMember.id});
                if (!deleteBarnMemberResult.isSuccess) {
                    result = {isSuccess: false, type: "Barn", result: deleteBarnMemberResult.result, message: "Could not remove a barn member."};
                    return result;
                }
            }
        }
    } 

    const deleteBarnResult = await deleteBarn({id: barn.id});
    if (deleteBarnResult.isSuccess) {
        return result;
    } else {
        return deleteBarnResult;
    }
}