import { Result } from "../../interfaces/Result";
import { Organization } from "../../models";
import { getGroupsForUser } from "../adminQueries/userGroups";
import { getUser } from "../user/User";
import { getOrganizationById } from "./Organization";

interface CognitioGroup {
    GroupName: string
}

export function findOrgInGroups(organization: Organization, groups: CognitioGroup[]): Result {
    for (var i = 0; i < groups.length; i++) {
        const currentGroup = groups[i];
        if (currentGroup.GroupName === organization.id) {
            let result: Result = {isSuccess: true, type: "Organization", result: currentGroup, message: "Found the group associated with this Organization."};
            return result;
        } 
    }
    let result: Result = {isSuccess: false, type: "Organization", result: null, message: "Could not find the group associated with this Organization."};
    return result;
}

export async function getOrganizationsFromGroups(): Promise<Result> {
    let results = [];
    try {
        const userResult = await getUser();
        if (userResult) {
            const userGroupResult = await getGroupsForUser(userResult.username);
            if (userGroupResult.Groups.length > 0) {
                const groupArray = userGroupResult.Groups;
                for (var i = 0; i < groupArray.length; i++) {
                    const currentGroup = groupArray[i];
                    const groupName = currentGroup.GroupName;
                    if (groupName !== "rspusers") {
                        const orgResult = await getOrganizationById(groupName);
                        if (orgResult.isSuccess) {
                            results.push(orgResult.result);
                        }
                    }
                }
            } 
        }

        if (results && results.length) {
            let result: Result = {isSuccess: true, type: "Organization", result: results, message: "Successfully got the organizations."};
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "Organization", result: [], message: "Failed to get the organizations."};
            return result;
        }
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Organization", result: error, message: "Failed to get the organizations."};
        return result;
    }
}