import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, Organization, OrganizationClass, OrganizationDivision } from "../../models";
import Spinner from "../../components/Spinners/Spinner";
import { getOrganizationById } from "../../utilities/organization/Organization";
import SelectEvent from "../../components/Event/SelectEvent";
import { Table } from "reactstrap";
import SelectYear from "../../components/DatePicker/SelectYear";
import { TableData } from "../../interfaces/Points";
import { getPointDataByEvent } from "../../utilities/eventResult/OrganizationPointData";
import { getEventsByOrganizationIdByYear } from "../../utilities/events/Event";
import { useHistory } from "react-router";
import { getOrganizationClassesByOrganizationId } from "../../utilities/organizationClass/OrganizationClass";
import { getOrganizationDivisionsByOrganizationId } from "../../utilities/organizationDivision/OrganizationDivision";
import { generatePointsByYearReport, generatePointsByYearWithPointBreakdownReport } from "../../utilities/reports/PointsReports";
import moment from "moment";

interface _Props {
    organizationId: string
}

const yearChoices = [
    moment(new Date()).subtract(2, "year").format("YYYY"),
    moment(new Date()).subtract(1, "year").format("YYYY"),
    moment(new Date()).format("YYYY"),
    moment(new Date()).add(1, "year").format("YYYY"),
];

const OrganizationMainPointTable: React.FC<_Props> = ({organizationId}) => {
    const history = useHistory();

    const [organization, setOrganization] = useState<Organization>();
    const [selectedYear, setSelectedYear] = useState(moment(new Date()).format("YYYY")); 
    const [selectedEvent, setSelectedEvent] = useState<Event | null | undefined>(); 
    const [organizationEvents, setOrganizationEvents] = useState<Event[] | null | undefined>(); 

    const [organizationClasses, setOrganizationClasses] = useState<OrganizationClass[] | null | undefined>();
    const [organizationDivisions, setOrganizationDivisions] = useState<OrganizationDivision[] | null | undefined>();

    const [filteredFormattedEventResults, setFilteredFormattedEventResults] = useState<TableData[] | null | undefined>(); 
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const handlePrintYearlyPoints = async (includeBreakdown: boolean) => {
        if (organization) {
            setIsDownloading(true);
            if (includeBreakdown) await generatePointsByYearWithPointBreakdownReport(organization?.id, selectedYear);
            else await generatePointsByYearReport(organization?.id, selectedYear);
            setIsDownloading(false);
        }
    }
    
    const handleSelectedYear = async (year: string) => {
        setSelectedYear(year);
        if (organization?.id) await getOrganizationEvents(organization?.id, year);
    }

    const handleSelectedEvent = async (event: Event) => {
        setIsLoading(true);
        setSelectedEvent(event);
        if (event.name === "Yearly Points") {
            setFilteredFormattedEventResults(undefined);
        } else {
            const eventMapResult: (TableData[] | undefined) = await getPointDataByEvent(event);
            if (eventMapResult) {
                const filterClassResults = eventMapResult.filter(result => result.classNumber !== undefined);
                const filterDivisionResults = eventMapResult.filter(result => result.classNumber === undefined);
                const sortedClassResults = filterClassResults?.sort((a, b) => (a.classNumber ? parseInt(a.classNumber) : 0) - (b.classNumber ? parseInt(b.classNumber) : 0));
                const sortedDivisionResults = filterDivisionResults?.sort((a, b) => (a.classNumber ? parseInt(a.classNumber) : 0) - (b.classNumber ? parseInt(b.classNumber) : 0));
                const finalResults = sortedClassResults.concat(sortedDivisionResults);
                setFilteredFormattedEventResults(finalResults);
            } else {
                setFilteredFormattedEventResults(undefined);
            }
        }
        setIsLoading(false);
    }

    async function getOrganizationEvents(organizationId: string, year?: string) {
        let events: Event[] = [];
        const queryResult = await getEventsByOrganizationIdByYear(organizationId, year || selectedYear);
        if (queryResult.isSuccess) {
            events = queryResult.result;

            const newEvent: Event = {
                id: "1",
                name: "Yearly Points",
                createdBy: "",
                createdOn: "",
                updatedOn: ""
            };

            events = [newEvent].concat(events);

            setOrganizationEvents(events);
            setSelectedEvent(newEvent);
        }
    }

    async function getOrganizationDivisions(id: string) {
        const queryResult = await getOrganizationDivisionsByOrganizationId(id);
        if (queryResult.isSuccess) {
            const orgDivisionList: OrganizationDivision[] = queryResult.result;
            const sorted = orgDivisionList.sort((a, b) => a.name.localeCompare(b.name));
            setOrganizationDivisions(sorted);
        }
    }

    async function getOrganizationClasses(id: string) {
        const queryResult = await getOrganizationClassesByOrganizationId(id);
        if (queryResult.isSuccess) {
            const orgClassList: OrganizationClass[] = queryResult.result;
            const sorted = orgClassList.sort((a, b) => a.name.localeCompare(b.name));
            setOrganizationClasses(sorted);
        }
    }

    async function getOrganization(organizationId: string) {
        try {
            setIsLoading(true);
            const queryResult = await getOrganizationById(organizationId);
            if (queryResult.isSuccess) {
                const organization: Organization = queryResult.result;
                setOrganization(organization);
                await getOrganizationEvents(organization.id);
                await getOrganizationClasses(organization.id);
                await getOrganizationDivisions(organization.id);
                
                setIsLoading(false);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
                setIsLoading(false);
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (organizationId) getOrganization(organizationId);
    }, [organizationId]);

    const handleDivisionLinkClick = (id: string) => {
        const path = "/index/staff/organization/points/view/division/" + id;
        history.push(path);
    }

    const handleClassLinkClick = (id: string) => {
        const path = "/index/staff/organization/points/view/class/" + id;
        history.push(path);
    }

    return (
        <>
            {organization ?
                <IonRow>
                    <IonCol>
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Organization Event Points</IonCardTitle>
                            {isDownloading ?
                                <Spinner />
                                :
                                <IonCardSubtitle>
                                    {(selectedEvent && selectedEvent.name === "Yearly Points") && (
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="3">
                                                <p className="link ion-text-wrap" onClick={() => handlePrintYearlyPoints(false)}>
                                                    Print Yearly Points (PDF)
                                                </p>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="3">
                                                <p className="link" onClick={() => handlePrintYearlyPoints(true)}>
                                                    Print Yearly Breakdown
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                </IonCardSubtitle>
                            }
                            {isLoading ?
                                <Spinner />
                                :
                                <IonCardContent>
                                    {organizationEvents && (
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                                <SelectYear selectedValue={selectedYear} years={yearChoices} onSelect={(year: string) => handleSelectedYear(year)} />
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                                <SelectEvent selectedValue={selectedEvent?.id} events={organizationEvents} onSelect={(event: Event) => handleSelectedEvent(event)} />
                                            </IonCol>
                                            {/* <IonCol sizeXs="12" sizeMd="4" className="text-center">
                                                <SelectMembership selectedValue={"All Riders"} memberships={["All Riders", "Members Only"]} onSelect={(selected: string) => handleSelectedMembership(selected)} />
                                            </IonCol> */}
                                        </IonRow>
                                    )}
                                    {selectedEvent && selectedEvent.name !== "Yearly Points" ?
                                        <>
                                            {(filteredFormattedEventResults && filteredFormattedEventResults.length > 0) ?
                                                <>
                                                    {filteredFormattedEventResults.map((tableData: TableData, index: number) => (
                                                        <div key={index}>
                                                            <h1>{tableData.classNumber ? tableData.classNumber + ": " : (tableData.divisionNumber ? tableData.divisionNumber + ": " : "")}{tableData.className ? tableData.className + " " : (tableData.divisionName ? tableData.divisionName + " Division Championship" : "")}</h1>
                                                            <Table responsive hover>
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <p className="description ion-text-wrap font-weight-bold">Event Name</p>
                                                                        </th>
                                                                        <th>
                                                                            <p className="description ion-text-wrap font-weight-bold">Back Number</p>
                                                                        </th>
                                                                        <th>
                                                                            <p className="description ion-text-wrap font-weight-bold">Horse Name</p>
                                                                        </th>
                                                                        <th>
                                                                            <p className="description ion-text-wrap font-weight-bold">Rider Name</p>
                                                                        </th>
                                                                        <th>
                                                                            <p className="description ion-text-wrap font-weight-bold">Place</p>
                                                                        </th>
                                                                        <th>
                                                                            <p className="description ion-text-wrap font-weight-bold">Points Earned</p>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {tableData.eventData && tableData.eventData.map((result, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <p className="description ion-text-wrap">{result.eventName}</p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="description ion-text-wrap">{result.backNumber}</p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="description ion-text-wrap">{result.horseName}</p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="description ion-text-wrap">{result.riderName}</p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="description ion-text-wrap">{result.place || ""}</p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="description ion-text-wrap">{result.pointsEarned}</p>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    ))}
                                                </>
                                                :
                                                <>
                                                    <p>No results found.</p>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            {(organizationDivisions && organizationDivisions.length > 0) && (
                                                <>
                                                    {organizationDivisions.map((orgDiv, index) => (
                                                        <IonRow key={index} className="ion-justify-content-center">
                                                            <IonCol className="ion-text-center ion-text-wrap">
                                                                <h2 className="link" onClick={() => handleDivisionLinkClick(orgDiv.id)}>{orgDiv.name}</h2>
                                                            </IonCol>
                                                        </IonRow>
                                                    ))}
                                                </>
                                            )}
                                            {(organizationClasses && organizationClasses.length > 0) && (
                                                <>
                                                    {organizationClasses.map((orgClass, index) => (
                                                        <IonRow className="ion-justify-content-center">
                                                            <IonCol className="ion-text-center ion-text-wrap">
                                                                <h2 className="link" onClick={() => handleClassLinkClick(orgClass.id)}>{orgClass.name}</h2>
                                                            </IonCol>
                                                        </IonRow>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    }
                                </IonCardContent>
                            }
                        </IonCard>
                    </IonCol>
                </IonRow>
                :
                <p>No organization found</p>
            }
        </>
    );
};

export default OrganizationMainPointTable;
