import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonDatetime,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow
} from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { LengthTypeOption, formatMembershipTypeLength } from "../../../utilities/organizationMembershipType/MembershipTypeLength";
import { OrganizationMembershipType, Organization, Season } from "../../../models";
import { MembershipTypeSchedule } from "../../../API";
import { getSeasonsByOrganizationId } from "../../../utilities/seasons/Seasons";
import CONSTANT from "../../../constant/constant";
import moment from "moment";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";

interface _Props {
    organization: Organization;
    membershipType?: OrganizationMembershipType;
    onChangeSchedule: Function;
    onChangeSeasons: Function;
    onChangeStartDate: Function;
}

interface FormattedSeasonItem {
    id: string
    isChecked: boolean
    season: Season
}

const MembershipTypeFormScheduleCard: React.FC<_Props> = ({ organization, membershipType, onChangeSchedule, onChangeSeasons, onChangeStartDate }) => {
    const lengthOptions = formatMembershipTypeLength();
    const startRuleEdit = membershipType?.isStartAtTimeOfPurchase ?
        CONSTANT.MEMBERSHIP.START_MEMBERSHIP_ON_PURCHASE_DATE.YES : CONSTANT.MEMBERSHIP.START_MEMBERSHIP_ON_PURCHASE_DATE.NO;

    const [isExpandedMembershipLength, setIsExpandedMembershipLength] = useState(true);
    const [schedule, setSchedule] = useState<string>(membershipType?.schedule ? membershipType?.schedule as string : MembershipTypeSchedule.annual as string);
    const [formattedSeasons, setFormattedSeasons] = useState<FormattedSeasonItem[]>([]);
    const [startRule, setStartRule] = useState(membershipType ? startRuleEdit : CONSTANT.MEMBERSHIP.START_MEMBERSHIP_ON_PURCHASE_DATE.YES);
    const [startDate, setStartDate] = useState<any>(membershipType?.setStartDate ? membershipType?.setStartDate : moment(new Date).format("YYYY-MM-DD"));

    const getOrganizationSeasons = async (organization: Organization) => {
        const queryResult = await getSeasonsByOrganizationId(organization.id);
        if (queryResult.isSuccess) {
            const orgSeasons: Season[] = queryResult.result;
            formatSeasons(orgSeasons);
        }
    }

    const formatSeasons = (seasons: Season[]) => {
        const formattedSeasons: FormattedSeasonItem[] = [];
        for (let i = 0; i < seasons.length; i++) {
            const season = seasons[i];
            const formattedSeason = formatSeason(season);
            formattedSeasons.push(formattedSeason)
        }
        setFormattedSeasons(formattedSeasons);
    }

    const formatSeason = (season: Season): FormattedSeasonItem => {
        let isSeasonChecked = false;
        if (membershipType?.seasons) {
            const orgMembershipTypeSeasons: any = membershipType?.seasons;
            const seasonLists = orgMembershipTypeSeasons.items;
            for (let i = 0; i < seasonLists.length; i++) {
                const selectedSeason: any = seasonLists[i];
                if (selectedSeason) {
                    if (selectedSeason.seasonID === season.id) isSeasonChecked = true;
                }
            }
        }
        const formattedSeasonItem: FormattedSeasonItem = {
            id: season.id,
            season: season,
            isChecked: isSeasonChecked
        };
        return formattedSeasonItem;
    }

    useEffect(() => {
        if (organization) {
            getOrganizationSeasons(organization);
        }
    }, [organization]);

    const handleSelectedCheckbox = (index: number, formattedSeason: FormattedSeasonItem, newValue: boolean) => {
        const updatedItem: FormattedSeasonItem = {
            ...formattedSeason,
            isChecked: newValue
        };
        const updatedFormattedSeasonItems: FormattedSeasonItem[] = [
            ...formattedSeasons.slice(0, index),
            updatedItem,
            ...formattedSeasons.slice(index + 1)
        ];
        setFormattedSeasons(updatedFormattedSeasonItems);
        handleSeasonChange(updatedFormattedSeasonItems);
    }

    const handleSeasonChange = (formattedSeasonItems: FormattedSeasonItem[]) => {
        let seasons: Season[] = [];
        for (let i = 0; i < formattedSeasonItems.length; i++) {
            const formattedSeasonItem = formattedSeasonItems[i];
            if (formattedSeasonItem.isChecked) {
                seasons.push(formattedSeasonItem.season);
            }
        }
        onChangeSeasons(seasons);
    }

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonRow onClick={() => setIsExpandedMembershipLength(!isExpandedMembershipLength)}>
                <IonCol size="10" className="ion-text-left">
                    <h5>Membership Length</h5>
                </IonCol>
                <IonCol size="2" className="ion-text-right">
                    <IonIcon size="large" icon={isExpandedMembershipLength ? chevronUp : chevronDown} />
                </IonCol>
            </IonRow>
            {isExpandedMembershipLength && (<IonCardContent>
                <IonRow className="pb-3">
                    <IonCol sizeXs="12" sizeMd="6">
                        <IonItem color="white" lines="none">
                            <IonLabel position="stacked">Membership Length <RequiredInputIndicator /></IonLabel>
                            <Input
                                id="membership-type-length"
                                name="schedule" 
                                type="select"
                                value={schedule} 
                                className="mt-2"
                                onChange={e => {
                                    setSchedule(e.target.value);
                                    onChangeSchedule(e.target.value);
                                }}
                            >
                                {lengthOptions?.map((length: LengthTypeOption, index: number) => (
                                    <option key={index} value={length.value}>{length.name}</option>
                                ))}
                            </Input>
                        </IonItem>
                    </IonCol>
                </IonRow>
                {(schedule === "season") && (
                    <>
                        <IonRow className="pb-3">
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonLabel position="stacked">Season <span className="text-danger">* (selected at least one)</span></IonLabel>
                                {(formattedSeasons.length > 0) ?
                                    <>
                                        <IonList className="bg-white">
                                            {formattedSeasons.map((formattedSeason, index) => (
                                                <IonItem color="white" lines="none" key={index}>
                                                    <IonLabel>
                                                        <IonRow>
                                                            <IonCol>
                                                                <p className="ion-text-wrap">{formattedSeason.season.name} (Is Active: {formattedSeason.season.isActive ? "Yes" : "No"})</p>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonLabel>
                                                    <IonCheckbox checked={formattedSeason.isChecked} onIonChange={(e) => handleSelectedCheckbox(index, formattedSeason, e.detail.checked)} />
                                                </IonItem>
                                            ))}
                                        </IonList>
                                    </>
                                    :
                                    <>
                                        <p>No seasons found.</p>
                                    </>
                                }
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                <a href={"index/staff/organization/org-seasons/" + organization.id} target="_blank">
                                    <IonButton>
                                        Add New Season
                                    </IonButton>
                                </a>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {(schedule === "annual") && (
                    <>
                        <IonRow className="pb-3">
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white" lines="none">
                                    <IonLabel position="stacked">Start Membership on Purchase Date <RequiredInputIndicator /></IonLabel>
                                    <Input
                                        id="membership-type-purchase-date"
                                        name="startRule" 
                                        type="select"
                                        value={startRule} 
                                        className="mt-2"
                                        onChange={e => {
                                            setStartRule(e.target.value);
                                        }}
                                    >
                                        {Object.values(CONSTANT.MEMBERSHIP.START_MEMBERSHIP_ON_PURCHASE_DATE).map((value, index) => {
                                            return <option key={index} value={value}>{value}</option>;
                                        })}
                                    </Input>
                                </IonItem>
                            </IonCol>
                            {startRule ===  CONSTANT.MEMBERSHIP.START_MEMBERSHIP_ON_PURCHASE_DATE.YES ?
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white" lines="none">
                                        <IonLabel position="stacked">Start Date <RequiredInputIndicator /></IonLabel>
                                        <p>Start on Membership Purchase Date</p>
                                    </IonItem>
                                </IonCol>
                                :
                                <>  
                                    <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">Start Date <RequiredInputIndicator /></IonLabel>
                                            <IonDatetime
                                                mode="ios"
                                                displayFormat="MMMM DD"
                                                placeholder="Select Date"
                                                value={startDate}
                                                onIonChange={(e) => {
                                                    setStartDate(e.detail.value!);
                                                    onChangeStartDate(e.detail.value!);
                                                }}
                                            ></IonDatetime>
                                        </IonItem>
                                    </IonCol>
                                </>
                            }
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="ion-text-center">
                                <p className="description">Note: memberships will expire at 1:00am EST on the given expiration date.</p>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                {(schedule === "lifetime") && (
                    <>
                    </>
                )}
            </IonCardContent>
            )}
        </IonCard>
    );
};

export default MembershipTypeFormScheduleCard;
