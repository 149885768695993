import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";
import LoginPage from "../pages/auth/LoginPage";
import LogoutPage from "../pages/auth/LogoutPage";
import React from "react";
import RegisterPage from "../pages/auth/RegisterPage";
import {Route} from "react-router-dom";

const AuthRoutes: React.FC = () => {
    return (
        <>
            <Route path="/register">
                <RegisterPage />
            </Route>
            <Route path="/login">
                <LoginPage />
            </Route>
            <Route path="/forgot-password">
                <ForgotPasswordPage />
            </Route>
            <Route path="/index/logout">
                <LogoutPage />
            </Route>
        </>
    );
};

export default AuthRoutes;
  