import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event} from "../../../../../../models";
import Header from "../../../../../../components/Headers/Header";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../../../../utilities/events/Event";
import PageTitle from "../../../../../../components/PageTitle/PageTitle";
import SelectOrganizationEvent from "../../../../../../components/Event/SelectOrganizationEvent";
import ErrorAlert from "../../../../../../components/Errors/ErrorAlert";
import SuccessBanner from "../../../../../../components/Banners/SuccessBanner";
import { copyEventSettings } from "../../../../../../utilities/events/DuplicateEvent";
import Spinner from "../../../../../../components/Spinners/Spinner";

interface EventPageProps extends RouteComponentProps<{
    id: string;
}> {}

export interface SettingsOption {
    title: string
    val: string
    isChecked: boolean
}

let checkboxList: SettingsOption[] = [
    { title: "Divisions, Classes & Schedule", val: "classes", isChecked: true },
    { title: "Fees", val: "fees", isChecked: true },
    { title: "Stabling", val: "stabling", isChecked: true },
    { title: "Event Rules", val: "rules", isChecked: true },
    { title: "Coggins Requirements", val: "coggins", isChecked: true },
];

const EventDuplicateSettingsPage: React.FC<EventPageProps> = ({match}) => {

    const [event, setEvent] = useState<Event>();
    const [selectedEvent, setSelectedEvent] = useState<Event | null | undefined>();
    const [isAutoAcceptEntries, setIsAutoAcceptEntries] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    useEffect(() => {
        async function getEvent() {
            const queryResult = await getEventById(match.params.id);
            if (queryResult.isSuccess) {
                setEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getEvent();
    }, [match, match.params.id]);

    const handleSelectedEvent = (event: Event | null | undefined) => {
        setSelectedEvent(event);
    }

    const handleCheckboxSelect = (index: number) => {
        checkboxList[index].isChecked = !checkboxList[index].isChecked;
    }

    const handleSubmit = async () => {
        setError("");
        setSuccess("");
        if (event) {
            if (selectedEvent) {
                setIsLoading(true);
                const duplicateResult = await copyEventSettings(event, selectedEvent, checkboxList, isAutoAcceptEntries);
                if (duplicateResult.isSuccess) {
                    setSuccess("Successfully copied the settings!");
                    setIsLoading(false);
                } else {
                    setError("Could not copy the settings.");
                    setIsLoading(false);
                }
            } else {
                setError("You must select an event to copy.");
            }
        } else {
            setError("Could not find the event you want to update.")
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={event ? event.name : "Settings"} />
                {event && (
                    <>
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="12">
                                <IonCard mode="md" className="card-min-height ion-padding bg-white">
                                    <IonCardTitle>Copy Settings From Previous Event</IonCardTitle>
                                    {isLoading ?
                                        <Spinner />
                                        :
                                        <IonCardContent>
                                            {error && <ErrorAlert error={error} width="12" />}
                                            {success && <SuccessBanner success={success} width="12" />}
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeXs="12" sizeMd="8" className="ion-text-center">
                                                    <p>First, select the event you would like to copy.</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeXs="12" sizeMd="8" className="ion-text-center">
                                                    <SelectOrganizationEvent organizationId={event.organizationId || ""} onSelect={(event: Event) => handleSelectedEvent(event)}/>
                                                </IonCol>
                                            </IonRow>
                                            {selectedEvent && (
                                                <>
                                                    <IonRow className="pt-5 ion-justify-content-center">
                                                        <IonCol sizeXs="12" sizeMd="8" className="ion-text-center">
                                                            <p>Next, select the settings you would like to copy. (This will override any settings {event.name} currently has.)</p>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="pt-5 ion-justify-content-center">
                                                        <IonCol sizeXs="12" sizeMd="8" className="ion-text-center">
                                                            <IonList className="bg-white">
                                                            {checkboxList.map(({ title, val, isChecked }, i) => (
                                                                <IonItem key={i}>
                                                                    <IonLabel>{title}</IonLabel>
                                                                    <IonCheckbox slot="end" value={val} checked={isChecked} onClick={() => handleCheckboxSelect(i)} />
                                                                </IonItem>
                                                            ))}
                                                                <IonItem>
                                                                    <IonLabel>Auto-Accept Submitted Entries</IonLabel>
                                                                    <IonCheckbox slot="end" value={"acceptEntries"} checked={isAutoAcceptEntries} onClick={() => setIsAutoAcceptEntries(!isAutoAcceptEntries)} />
                                                                </IonItem>
                                                                <p>Do you want to auto-accept all new entries? If so, you want be required to review each new entry as it comes in and accept or decline it.</p>
                                                            </IonList>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className="pt-5 ion-justify-content-center">
                                                        <IonCol sizeXs="12" sizeMd="8" className="ion-text-center">
                                                            <IonButton color="tertiary" onClick={handleSubmit} expand="block">Submit</IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </>
                                            )}
                                        </IonCardContent>
                                    }
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                
            </IonContent>
        </IonPage>
    );
};

export default EventDuplicateSettingsPage;