import { API } from "aws-amplify";
import { Result } from "../../interfaces/Result";

export async function sendClinicianNewVideoEmail(emailAddress: string, riderName: string) {
    try {
        let queryParams = "";
        queryParams = queryParams + "?email=" + emailAddress;
        queryParams = queryParams + "&riderName=" + riderName;

        const path = "/emails/clinician-new-video" + queryParams;
        const response = await API.get("Emails", path, {});
        const responseBody = JSON.parse(response.body);
        const url = responseBody.url;

        let result: Result = {isSuccess: responseBody.success, type: "Email", result: url, message: "Successfully sent the email."}
        return result;
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Email", result: error, message: "Error occurred while trying to send the email." }
        return result;
    }
}

export async function sendRiderFeedbackCompleteEmail(emailAddress: string, riderName: string, clinicianName: string) {
    try {
        let queryParams = "";
        queryParams = queryParams + "?email=" + emailAddress;
        queryParams = queryParams + "&riderName=" + riderName;
        queryParams = queryParams + "&clinicianName=" + clinicianName;

        const path = "/emails/feedback-completed" + queryParams;
        const response = await API.get("Emails", path, {});
        const responseBody = JSON.parse(response.body);
        const url = responseBody.url;

        let result: Result = {isSuccess: responseBody.success, type: "Email", result: url, message: "Successfully sent the email."}
        return result;
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Email", result: error, message: "Error occurred while trying to send the email." }
        return result;
    }
}

export async function sendRiderFeedbackDeclinedEmail(emailAddress: string, riderName: string, clinicianName: string) {
    try {
        let queryParams = "";
        queryParams = queryParams + "?email=" + emailAddress;
        queryParams = queryParams + "&riderName=" + riderName;
        queryParams = queryParams + "&clinicianName=" + clinicianName;

        const path = "/emails/feedback-declined" + queryParams;
        const response = await API.get("Emails", path, {});
        const responseBody = JSON.parse(response.body);
        const url = responseBody.url;

        let result: Result = {isSuccess: responseBody.success, type: "Email", result: url, message: "Successfully sent the email."}
        return result;
    } catch (error: any) {
        let result: Result = {isSuccess: false, type: "Email", result: error, message: "Error occurred while trying to send the email." }
        return result;
    }
}