import moment from "moment";
import { CreateOwnerInput, CreateRiderInput, CreateTrainerInput } from "../../API";
import { Result } from "../../interfaces/Result";
import { Person } from "../../models";
import { createOwner, deleteOwner, getOwnerByPersonId } from "../owner/Owner";
import { getPersonNameByPersonId } from "../person/PersonName";
import { createRider, deleteRider, getRiderByPersonId } from "../rider/Rider";
import { createTrainer, deleteTrainer, getTrainerByPersonId } from "../trainer/Trainer";

export async function createRoleRecords(person: Person, roles: string[]) {
    const riderResult = await handleRider(person, roles.includes("Rider"));
    if (!riderResult.isSuccess) {
        let result: Result = {isSuccess: false, type: "Record", result: riderResult.result, message: "Could not handle rider record."};
        return result;
    }

    const ownerResult = await handleOwner(person, roles.includes("Horse Owner"));
    if (!ownerResult.isSuccess) {
        let result: Result = {isSuccess: false, type: "Record", result: ownerResult.result, message: "Could not handle owner record."};
        return result;
    }

    const trainerResult = await handleTrainer(person, roles.includes("Trainer or Coach"));
    if (!trainerResult.isSuccess) {
        let result: Result = {isSuccess: false, type: "Record", result: trainerResult.result, message: "Could not handle trainer record."};
        return result;
    }
}

async function handleRider(person: Person, isCreate: boolean): Promise<Result> {
    const queryResult = await getRiderByPersonId(person.id);
    let rider = queryResult.isSuccess ? queryResult.result : undefined;

    // check if you need to either add a new record or remove a record, otw do nothing
    if (rider && !isCreate) {
        const deleteResult = await deleteRider({id: rider.id});
        if (deleteResult.isSuccess) {
            let result: Result = {isSuccess: true, type: "Record", result: deleteResult.result, message: "Deleted the Rider"};
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "Record", result: deleteResult.result, message: "Could not delete the Rider"};
            return result;
        }
    } else if (!rider && isCreate) {
        let name = "";
        let personNameResult = await getPersonNameByPersonId(person.id);
        if (personNameResult.isSuccess) {
            const personName = personNameResult.result;
            if (personName) {
                name = personName.firstName + " " + personName.lastName;
            } else {
                name = person.firstName + " " + person.lastName;
            }
        } else {
            name = person.firstName + " " + person.lastName;
        }
        const riderInput: CreateRiderInput = {
            name: name,
            personId: person.id,
            createdBy: person.id,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
        const createResult = await createRider(riderInput);
        if (createResult.isSuccess) {
            let result: Result = {isSuccess: true, type: "Record", result: createResult.result, message: "Created a new Rider"};
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "Record", result: createResult.result, message: "Could not create the new Rider"};
            return result;
        }
    }

    let result: Result = {isSuccess: true, type: "Record", result: rider, message: "Did not need to update Rider"};
    return result;
}

async function handleOwner(person: Person, isCreate: boolean): Promise<Result> {
    const queryResult = await getOwnerByPersonId(person.id);
    let owner = queryResult.isSuccess ? queryResult.result : undefined;

    // check if you need to either add a new record or remove a record, otw do nothing
    if (owner && !isCreate) {
        const deleteResult = await deleteOwner({id: owner.id});
        if (deleteResult.isSuccess) {
            let result: Result = {isSuccess: true, type: "Record", result: deleteResult.result, message: "Deleted the Owner"};
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "Record", result: deleteResult.result, message: "Could not delete the Owner"};
            return result;
        }
    } else if (!owner && isCreate) {
        let name = "";
        let personNameResult = await getPersonNameByPersonId(person.id);
        if (personNameResult.isSuccess) {
            const personName = personNameResult.result;
            if (personName) {
                name = personName.firstName + " " + personName.lastName;
            } else {
                name = person.firstName + " " + person.lastName;
            }
        } else {
            name = person.firstName + " " + person.lastName;
        }
        const ownerInput: CreateOwnerInput = {
            name: name,
            personId: person.id,
            createdBy: person.id
        };
        const createResult = await createOwner(ownerInput);
        if (createResult.isSuccess) {
            let result: Result = {isSuccess: true, type: "Record", result: createResult.result, message: "Created a new Owner"};
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "Record", result: createResult.result, message: "Could not create the new Owner"};
            return result;
        }
    }

    let result: Result = {isSuccess: true, type: "Record", result: owner, message: "Did not need to update Owner"};
    return result;
}

async function handleTrainer(person: Person, isCreate: boolean): Promise<Result> {
    const queryResult = await getTrainerByPersonId(person.id);
    let trainer = queryResult.isSuccess ? queryResult.result : undefined;

    // check if you need to either add a new record or remove a record, otw do nothing
    if (trainer && !isCreate) {
        const deleteResult = await deleteTrainer({id: trainer.id});
        if (deleteResult.isSuccess) {
            let result: Result = {isSuccess: true, type: "Record", result: deleteResult.result, message: "Deleted the Trainer"};
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "Record", result: deleteResult.result, message: "Could not delete the Trainer"};
            return result;
        }
    } else if (!trainer && isCreate) {
        let name = "";
        let personNameResult = await getPersonNameByPersonId(person.id);
        if (personNameResult.isSuccess) {
            const personName = personNameResult.result;
            if (personName) {
                name = personName.firstName + " " + personName.lastName;
            } else {
                name = person.firstName + " " + person.lastName;
            }
        } else {
            name = person.firstName + " " + person.lastName;
        }
        const trainerInput: CreateTrainerInput = {
            name: name,
            personId: person.id,
            createdBy: person.id  
        };
        const createResult = await createTrainer(trainerInput);
        if (createResult.isSuccess) {
            let result: Result = {isSuccess: true, type: "Record", result: createResult.result, message: "Created a new Trainer"};
            return result;
        } else {
            let result: Result = {isSuccess: false, type: "Record", result: createResult.result, message: "Could not create the new Trainer"};
            return result;
        }
    }

    let result: Result = {isSuccess: true, type: "Record", result: trainer, message: "Did not need to update Trainer"};
    return result;
}