import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { onCreateContact, onUpdateContact } from "../../graphql/subscriptions";
import { Contact } from "../../models";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";

interface _Props {
    onSelect: Function
    selectedValue?: string
    contacts?: Contact[]
}

interface formattedOption {
    label: string
    value: string
    contact: Contact
}

const SelectContact: React.FC<_Props> = ({contacts, onSelect, selectedValue}) => {
    const contactSubscription = useSubscriptionByItself({
        config: {
            query: onCreateContact,
            key: "onCreateContact"
        }
    });

    const contactUpdateSubscription = useSubscriptionByItself({
        config: {
            query: onUpdateContact,
            key: "onUpdateContact"
        }
    });

    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    const [currentUpdateSubscriptionItem, setUpdateCurrentSubscriptionItem] = useState<any>();
    const [formattedContacts, setFormattedContacts] = useState<formattedOption[] | null | undefined>();
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();

    const findValueInList = (value: string, contactList?: formattedOption[]) => {
        let optionArray = contactList || formattedContacts;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        const formatContacts = (contacts: Contact[]) => {
            let formattedContacts = [];
            for (var i = 0; i < contacts.length; i++) {
                let object = {
                    value: contacts[i].id,
                    label: contacts[i].personalEmail || contacts[i].workEmail || "none",
                    contact: contacts[i]
                };
                formattedContacts.push(object);
            }
            setFormattedContacts(formattedContacts);
            if (selectedValue) findValueInList(selectedValue, formattedContacts);
        }
        
        if (contacts) formatContacts(contacts);
    }, [contacts]);

    useEffect(() => {
        if (contactSubscription && contactSubscription[0] !== undefined && contactSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(contactSubscription[0]);
        }
    }, [contactSubscription]);

    useEffect(() => {
        if (contactUpdateSubscription && contactUpdateSubscription[0] !== undefined && contactUpdateSubscription[0] !== currentUpdateSubscriptionItem) {
            setUpdateCurrentSubscriptionItem(contactUpdateSubscription[0]);
        }
    }, [contactUpdateSubscription]);

    useEffect(() => {
        async function setValue() {
            if (selectedValue) {
                const found = formattedContacts?.find(formattedContact => formattedContact.value === selectedValue);
                if (found) {
                    findValueInList(selectedValue);
                }
            } else {
                setCurrentValue(undefined);
            }
        }
        setValue();
    }, [selectedValue]);

    const handleOnChange = async (event: any) => {
        if (event && event.contact) {
            setCurrentValue(event);
            onSelect(event.contact);
        } else {
            setCurrentValue(undefined);
            onSelect(undefined);
        }
    }

    return (
        <>
            <Select
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                // defaultValue={currentValue}
                value={currentValue}
                menuPortalTarget={document.body}
                isClearable
                options={formattedContacts!}
                onChange={handleOnChange}
            />
        </>
    );
};

export default SelectContact;
