import {
    IonButton,
    IonCard,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
    isPlatform,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Input } from "reactstrap";
import { isWindows } from "../../utilities/platform/Platform";
import { Address, Membership, Organization, Contact, OrganizationMembershipType, Person, PersonalInformation, MembershipTypeSchedule, Barn, Rider, MembershipTypeCategory, Horse } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import PhoneNumberTypeSelect from "../Contact/PhoneNumberTypeSelect";
import AddressFormGroup from "../Address/AddressFormGroup";
import SelectFromAllBarns from "../Barn/SelectFromAllBarns";
import { CreateAddressInput, CreateContactInput, CreateHorseInput, CreateMembershipInput, CreatePersonalInformationInput, CreateRiderInput, 
    UpdateAddressInput, UpdateContactInput, UpdateHorseInput, UpdateMembershipInput, UpdateOrganizationMembershipTypeInput, UpdatePersonalInformationInput, UpdateRiderInput } from "../../API";
import { createMembership, updateMembership, getMembershipsByPersonName, getMembershipsByOrganizationId } from "../../utilities/membership/Membership";
import { getPersonByPersonId } from "../../utilities/person/Person";
import { createPersonalInformation, getPersonalInformationByPersonId, updatePersonalInformation } from "../../utilities/personalInformation/PersonalInformation";
import { createAddress, getAddressById, updateAddress } from "../../utilities/address/Address";
import { createContact, getContactById, updateContact } from "../../utilities/contact/Contact";
import Spinner from "../Spinners/Spinner";
import { createRider, getRiderByPersonId, getRidersByBarnId, updateRider } from "../../utilities/rider/Rider";
import { formatAllCountryPhoneNumber } from "../../utilities/contact/FormatPhoneNumber";
import CONSTANT from "../../constant/constant";
import { getNextAvailableMembershipId } from "../../utilities/organizationMembershipType/MembershipIdValues";
import { updateOrganizationMembershipType } from "../../utilities/organizationMembershipType/OrganizationMembershipType";
import GradeLevel from "./GradeLevel";
import { createHorse, getHorseById, updateHorse } from "../../utilities/horse/Horse";
import SelectState from "../Address/SelectState";
import CogginsDocumentForm from "../s3Object/CogginsDocumentForm";
import SelectHorse from "../Horse/SelectHorse";
import HorseHeightInput from "../Horse/HorseHeightInput";
import { getHorsesByUserByBarn } from "../../utilities/horse/HorseByBarn";
import { createCogginsDocumentFileName, createS3Object, handleUploadS3File, handleUploadS3Image } from "../../utilities/s3Object/s3Object";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    membershipType: OrganizationMembershipType;
    setSegmentValue: Function;
    memberships: Membership[] | undefined;
    personId: string;
    user: Person | undefined;
    setMemberships: Function;
    organization: Organization | undefined;
}

interface GroupMember {
    name: string;
    birthDate: string;
    backNumber?: number;
    membership?: Membership;
}

const MembershipApplicationBasicInfo: React.FC<_Props> = ({membershipType, setSegmentValue, memberships, setMemberships, personId, user, organization}) => {

    const membership: (Membership | undefined) = (memberships?.length) ? memberships[0] : undefined;

    const [previousMemberships, setPreviousMemberships] = useState<Membership[] | undefined>(); //An expired membership for this user
    const [fullName, setFullName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [birthDate, setBirthDate] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [phoneType, setPhoneType] = useState<string>("");
    const [streetAddressLine1, setStreetAddressLine1] = useState("");
    const [streetAddressLine2, setStreetAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [provState, setProvState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [barn, setBarn] = useState<Barn | undefined | null>();
    const [gradeLevel, setGradeLevel] = useState<string>(membership && membership.gradeLevel ? membership.gradeLevel : "-");
    const [backNumber, setBackNumber] = useState<number | undefined>(undefined);
    const [groupMembers, setGroupMembers] = useState<(GroupMember | null)[]>([]);
    const [error, setError] = useState<string>("");
    const [address, setAddress] = useState<Address>();
    const [contact, setContact] = useState<Contact>();
    const [rider, setRider] = useState<Rider>();
    const [personInfo, setPersonInfo] = useState<PersonalInformation>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showDescription, setShowDescription] = useState<boolean>(true);

    // Group/Team name
    const [groupTeamName, setGroupTeamName] = useState<string>("");
    const [groupOrTeam, setGroupOrTeam] = useState<string>("");

    // Group/Team Leader name and leader contact info 
    const [leaderName, setLeaderName] = useState<string>("");
    const [leaderAddress, setLeaderAddress] = useState<Address>();
    const [leaderContact, setLeaderContact] = useState<Contact>();
    const [leaderEmail, setLeaderEmail] = useState<string>("");
    const [leaderPhoneNumber, setLeaderPhoneNumber] = useState<string>("");
    const [leaderPhoneType, setLeaderPhoneType] = useState<string>("");
    const [leaderStreetAddressLine1, setLeaderStreetAddressLine1] = useState("");
    const [leaderStreetAddressLine2, setLeaderStreetAddressLine2] = useState("");
    const [leaderCity, setLeaderCity] = useState("");
    const [leaderProvState, setLeaderProvState] = useState("");
    const [leaderZip, setLeaderZip] = useState("");
    const [leaderCountry, setLeaderCountry] = useState("");

    // Horse
    const [horseName, setHorseName] = useState<string>("");
    const [horseHeight, setHorseHeight] = useState<number>(0);
    const [horseOwnerName, setHorseOwnerName] = useState<string>("");
    const [cogginsState, setCogginsState] = useState("");
    const [cogginsNumber, setCogginsNumber] = useState("");
    const [cogginsDate, setCogginsDate] = useState("");
    const [cogginsDocument, setCogginsDocument] = useState<any | null | undefined>();
    const [horseList, setHorseList] = useState<Horse[] | null | undefined>();
    const [selectedHorse, setSelectedHorse] = useState<Horse | null | undefined>();

    const handleGroupMemberNameChange = async (index: number, name: string) => {
        if (groupMembers) {
            // Try to get the back number
            let currentBackNumber: number | undefined = undefined;
            let prevMembership: Membership | undefined = undefined;
            if (name) {
                prevMembership = getPreviousMembershipByPersonName(name);
                currentBackNumber = prevMembership?.backNumber || undefined;
            }

            let currentGroupMember = groupMembers[index];
            if (currentGroupMember) {
                // If the current group member already exists, only update the applicable field
                currentGroupMember.name = name;
                currentGroupMember.backNumber = currentBackNumber;
            } else {
                // If the current group member does not already exists, create it with the data we currently have
                currentGroupMember = {
                    name: name || "",
                    birthDate: "",
                    backNumber: currentBackNumber
                };
            }
            // pre populate DOB from previous membership data
            if (prevMembership && prevMembership.rider?.birthdate) {
                currentGroupMember.birthDate = prevMembership.rider.birthdate;
            } else {
                currentGroupMember.birthDate = "";
            }
            // Update the group member array
            const updatedGroupMemberArray = [
                ...groupMembers.slice(0, index),
                currentGroupMember,
                ...groupMembers.slice(index + 1)
            ];
            setGroupMembers([...updatedGroupMemberArray]);
        }
    };

    const handleGroupMemberBirthDateChange = async (index: number, birthDate: string) => {
        if (groupMembers) {
            let currentGroupMember = groupMembers[index];
            if (currentGroupMember) {
                if (birthDate) {
                    currentGroupMember.birthDate = birthDate;
                } else {
                    currentGroupMember.birthDate = "";  
                }
            } else {
                // If the current group member does not already exists, create it with the data we currently have
                currentGroupMember = {
                    name: "",
                    birthDate: birthDate || "",
                    backNumber: undefined
                };
            }
            // Update the group member array
            const updatedGroupMemberArray = [
                ...groupMembers.slice(0, index),
                currentGroupMember,
                ...groupMembers.slice(index + 1)
            ];
            setGroupMembers([...updatedGroupMemberArray]);
        }
    };

    const handlePhoneNumberTypeSelectInput = (phoneNumberTypeInput: string) => {
        if (phoneNumberTypeInput && phoneNumberTypeInput !== "-"){
            setPhoneType(phoneNumberTypeInput);
        } else {
            setPhoneType("");
        }
    };

    const handleLeaderPhoneNumberTypeSelectInput = (phoneNumberTypeInput: string) => {
        if (phoneNumberTypeInput && phoneNumberTypeInput !== "-"){
            setLeaderPhoneType(phoneNumberTypeInput);
        } else {
            setLeaderPhoneType("");
        }
    };

    const handleAddressInputChange = (type: string, value: string) => {
        setError("");
        if (type === "streetAddressLine1") setStreetAddressLine1(value);
        if (type === "streetAddressLine2") setStreetAddressLine2(value);
        if (type === "city") setCity(value);
        if (type === "provState") setProvState(value);
        if (type === "zip") setZip(value);
        if (type === "country") setCountry(value);
    };

    const handleLeaderAddressInputChange = (type: string, value: string) => {
        setError("");
        if (type === "streetAddressLine1") setLeaderStreetAddressLine1(value);
        if (type === "streetAddressLine2") setLeaderStreetAddressLine2(value);
        if (type === "city") setLeaderCity(value);
        if (type === "provState") setLeaderProvState(value);
        if (type === "zip") setLeaderZip(value);
        if (type === "country") setLeaderCountry(value);
    };

    const handleSelectBarn = async (barn?: Barn) => {
        setError("");
        if (barn && user) {
            setBarn(barn);
            const horses = await getHorsesByUserByBarn(user, barn);
            setHorseList(horses);
        } else {
            setBarn(undefined);
            setHorseList(undefined);
        }
    };

    const getOrganizationMemberships = async (org: Organization) => {
        const queryResult = await getMembershipsByOrganizationId(org.id);
        if (queryResult.isSuccess) {
            setPreviousMemberships(queryResult.result);
        }
    }

    const getPreviousMembershipByPersonName = (personName: string) => {
        if (previousMemberships) {
            const found = previousMemberships.find(membership => membership.personName?.toLowerCase().trim() === personName.toLowerCase().trim());
            if (found) {
                if (found.backNumber) { 
                    setBackNumber(found.backNumber);
                }
                if (found.rider) setRider(found.rider);
                if (found.barn) setBarn(found.barn);
                if (found.gradeLevel) setGradeLevel(found.gradeLevel);
                return found;
            }
        }
    }

    const getPrePopulatedDataForPersonalInformation = async () => {
        try {
            const queryResult = await getPersonalInformationByPersonId(personId);
            if (queryResult.isSuccess) {
                const personalInformation: PersonalInformation = queryResult.result;
                setPersonInfo(personalInformation);
                setAddress(personalInformation && personalInformation.address ? personalInformation.address : undefined);
                setStreetAddressLine1(personalInformation && personalInformation.address?.streetAddress1 ? personalInformation.address.streetAddress1 : "");
                setStreetAddressLine2(personalInformation && personalInformation.address?.streetAddress2 ? personalInformation.address.streetAddress2 : "");
                setCity(personalInformation && personalInformation.address?.city ? personalInformation.address.city : "");
                setProvState(personalInformation && personalInformation.address?.provState ? personalInformation.address.provState : "");
                setZip(personalInformation && personalInformation.address?.zip ? personalInformation.address.zip : "");
                setCountry(personalInformation && personalInformation.address?.country ? personalInformation.address.country : "");
                if (membershipType.category !== MembershipTypeCategory.HORSE) {
                    setBirthDate(personalInformation && personalInformation.dateOfBirth ? personalInformation.dateOfBirth : "")
                } else {
                    setBirthDate("");
                };
                const contactData = personalInformation.contact;
                setContact(contactData || undefined);
                setPhoneType(
                    contactData && contactData.cell
                    ? "cell"
                    : contactData && contactData.home
                    ? "home"
                    : contactData && contactData.work
                    ? "work"
                    : ""
                );
                setPhoneNumber(
                    contactData && contactData.cell
                    ? contactData.cell
                    : contactData && contactData.home
                    ? contactData.home
                    : contactData && contactData.work
                    ? contactData.work
                    : ""
                );                     
            } else {
                // We will need to create the personal information in handleSubmit
                // No need to show an error to the user in this case
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    const getPrePopulatedDataForRiderInformation = async () => {
        try {
            const queryResult = await getRiderByPersonId(personId);
            if (queryResult.isSuccess) {
                const riderData: Rider = queryResult.result;
                setRider(riderData);
                const barn: (Barn | null | undefined) = riderData.barn;
                setBarn(barn);
                if (riderData.birthdate) setBirthDate(riderData.birthdate);
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
    }

    const getPrePopulatedDataForGroupMembership = async () => {
        setIsLoading(true);
        // Get barn
        await getPrePopulatedDataForRiderInformation();

        // Get groupMembers
        if (memberships && memberships.length > 0) {
            let updatedGroupMembers = groupMembers;
            for (let i = 0; i < memberships.length; i++) {
                const membership: Membership = memberships[i];
                // Create the new group member and update the array in state
                const groupMember: GroupMember = {
                    name: membership.personName || "",
                    birthDate: membership.rider?.birthdate || "",
                    backNumber: membership.backNumber || 0,
                    membership: membership
                };
                updatedGroupMembers[i] = groupMember;
            }
            setGroupMembers([...updatedGroupMembers])
        }
        if(membership) {
            // initialize the Group Contact Info fields
            setGroupTeamName(membership.groupContact?.name || "");
            setEmail(membership.groupContact?.personalEmail || "");
            const contactData = membership.groupContact;
            setPhoneType(
                contactData && contactData.cell
                ? "cell"
                : contactData && contactData.home
                ? "home"
                : contactData && contactData.work
                ? "work"
                : ""
            );
            setPhoneNumber(
                contactData && contactData.cell
                ? contactData.cell
                : contactData && contactData.home
                ? contactData.home
                : contactData && contactData.work
                ? contactData.work
                : ""
            );

            if(contactData?.mailingAddress) {
                const groupAddressResult = await getAddressById(contactData?.mailingAddress);
                const groupAddress = groupAddressResult.result;
                setAddress(groupAddress);
                setStreetAddressLine1(groupAddress.streetAddress1 ? groupAddress.streetAddress1 : "");
                setStreetAddressLine2(groupAddress.streetAddress2 ? groupAddress.streetAddress2 : "");
                setCity(groupAddress.city ? groupAddress.city : "");
                setProvState(groupAddress.provState ? groupAddress.provState : "");
                setZip(groupAddress.zip ? groupAddress.zip : "");
                setCountry(groupAddress.country ? groupAddress.country : "");
            }

            // initialize the Group Leader Contact Info fields
            setLeaderName(membership.groupLeaderContact?.name || "");
            setLeaderEmail(membership.groupLeaderContact?.personalEmail || "");
            const leaderContactData = membership.groupLeaderContact;
            setLeaderPhoneType(
                leaderContactData && leaderContactData.cell
                ? "cell"
                : leaderContactData && leaderContactData.home
                ? "home"
                : leaderContactData && leaderContactData.work
                ? "work"
                : ""
            );
            setLeaderPhoneNumber(
                leaderContactData && leaderContactData.cell
                ? leaderContactData.cell
                : leaderContactData && leaderContactData.home
                ? leaderContactData.home
                : leaderContactData && leaderContactData.work
                ? leaderContactData.work
                : ""
            );
            if(leaderContactData?.mailingAddress) {
                const groupLeaderAddressResult = await getAddressById(leaderContactData?.mailingAddress);
                const groupLeaderAddress = groupLeaderAddressResult.result;
                setLeaderAddress(groupLeaderAddress);
                setLeaderStreetAddressLine1(groupLeaderAddress.streetAddress1 ? groupLeaderAddress.streetAddress1 : "");
                setLeaderStreetAddressLine2(groupLeaderAddress.streetAddress2 ? groupLeaderAddress.streetAddress2 : "");
                setLeaderCity(groupLeaderAddress.city ? groupLeaderAddress.city : "");
                setLeaderProvState(groupLeaderAddress.provState ? groupLeaderAddress.provState : "");
                setLeaderZip(groupLeaderAddress.zip ? groupLeaderAddress.zip : "");
                setLeaderCountry(groupLeaderAddress.country ? groupLeaderAddress.country : "");
            }
        }
        setIsLoading(false);
    }

    const getPrePopulatedDataForHorseContactInformation = async () => {    
        setIsLoading(true);    
        if(membership && membership.horse) {
            const horseContactResult = await getContactById(membership.horse?.contactId ||  "");
            const horseContact = horseContactResult.result;
            setPhoneType(
                horseContact && horseContact.cell
                ? "cell"
                :  horseContact && horseContact.home
                ? "home"
                :  horseContact && horseContact.work
                ? "work"
                : ""
            );
            setPhoneNumber(
                horseContact && horseContact.cell
                ?   horseContact.cell
                :   horseContact && horseContact.home
                ?   horseContact.home
                :   horseContact && horseContact.work
                ?   horseContact.work
                : ""
            );

            if(horseContact?.mailingAddress) {
                const horseAddressResult = await getAddressById(horseContact?.mailingAddress);
                const horseAddress = horseAddressResult.result;
                setAddress(horseAddress);
                setStreetAddressLine1(horseAddress.streetAddress1 ? horseAddress.streetAddress1 : "");
                setStreetAddressLine2(horseAddress.streetAddress2 ? horseAddress.streetAddress2 : "");
                setCity(horseAddress.city ? horseAddress.city : "");
                setProvState(horseAddress.provState ? horseAddress.provState : "");
                setZip(horseAddress.zip ? horseAddress.zip : "");
                setCountry(horseAddress.country ? horseAddress.country : "");
            }
        }
        setIsLoading(false);
    }

    const getPrePopulatedDataForIndividualMembership = async () => {
        setIsLoading(true);
        if (personId) {
            // First, try to get info by the current person id
            await getPrePopulatedDataForPersonalInformation();
            await getPrePopulatedDataForRiderInformation();
            try {
                const queryResult = await getPersonByPersonId(personId);
                if (queryResult.isSuccess) {
                    const personData: Person = queryResult.result;
                    const fullNameFormatted = (personData.firstName ? personData.firstName.trim() : "") + 
                        (personData.firstName && personData.lastName ? " " : "") + 
                        (personData.lastName ? personData.lastName.trim() : "");
                    if (!membership?.personName && fullNameFormatted) {
                        // Check if this person has a previous membership - get info from there
                        getPreviousMembershipByPersonName(fullNameFormatted);
                        setFullName(fullNameFormatted);
                    }
                    if (!membership?.personEmail && personData.email) {
                        setEmail(personData.email);
                    }
                } else {
                    setError(queryResult.message);
                }
            } catch (error: any) {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        setIsLoading(false);
    }

    const getPrePopulatedDataForHorseMembership = async () => {

        if(membership && membership.personEmail) {
            setEmail(membership.personEmail);
        } else {
            setIsLoading(true);
            const queryResult = await getPersonalInformationByPersonId(personId);
            if (queryResult.isSuccess) {
                const personData: PersonalInformation = queryResult.result;
                if(personData.person) {
                    setEmail(personData.person.email);
                }
            }
            setIsLoading(false);
        }
    
        if(membership) {
            await getPrePopulatedDataForHorseContactInformation();
            if(membership.horse?.name) setHorseName(membership.horse.name || "");
            if(membership.horse?.dateOfBirth) setBirthDate(membership.horse.dateOfBirth || "");
            if(membership.horse?.ownerName) setHorseOwnerName(membership.horse.ownerName || "");
            if(membership.horse?.height) setHorseHeight(parseFloat(membership.horse.height));
            if(membership.horse?.cogginsNumber) setCogginsNumber(membership.horse.cogginsNumber || "");
            if(membership.horse?.cogginsDate) setCogginsDate(membership.horse.cogginsDate || "");
            if(membership.horse?.cogginsState) setCogginsState(membership.horse.cogginsState || "");
            if(membership.barn) setBarn(membership.barn || undefined);
            if(membership.horse) {
                const queryResult = await getHorseById(membership.horse.id);
                if (queryResult.isSuccess) {
                    const horse: Horse = queryResult.result;
                    setSelectedHorse(horse);
                }
            }
        } else {
            clearForm();
            getPrePopulatedDataForPersonalInformation();
        }

    }

    const clearForm = () => {
        setSelectedHorse(undefined);
        setBarn(undefined);
        setHorseName("");
        setHorseHeight(0);
        setHorseOwnerName("");
        setBirthDate("");
        setCogginsDate("");
        setCogginsNumber("");
        setCogginsState("");
        setCogginsDocument(undefined);
    }

    useEffect(() => {
        if (membershipType.applicationFields?.collectMemberDetailsTogether &&
            membershipType.applicationFields?.maxNumberOfGroupMembers &&
            membershipType.applicationFields?.maxNumberOfGroupMembers !== groupMembers?.length) {
            const blankMembersCount = membershipType.applicationFields?.maxNumberOfGroupMembers - groupMembers?.length;
            for(let i = 0; i < blankMembersCount; i++) {
                groupMembers.push(null);
            }
            setGroupMembers([...groupMembers]);
        }
    }, [groupMembers]);

    useEffect(() => {
        const getPrePopulatedData = async () => {
            if (membershipType.category === MembershipTypeCategory.INDIVIDUAL) {
                await getPrePopulatedDataForIndividualMembership();
            } else if (membershipType.category === MembershipTypeCategory.GROUP) {
                await getPrePopulatedDataForGroupMembership();
                if(membershipType.applicationFields?.isTeam) {
                    setGroupOrTeam("Team");
                } else {
                    setGroupOrTeam("Group");
                }
                if (membershipType && membershipType.applicationFields?.collectMemberDetailsTogether &&
                    membershipType.applicationFields?.maxNumberOfGroupMembers && memberships?.length === 0) {
                    // Populate the group member array with the max number of objects that can be added
                    const emptyGroupMemberArray = Array(membershipType.applicationFields?.maxNumberOfGroupMembers).fill(null);
                    setGroupMembers([...emptyGroupMemberArray]);
                }
                // Handle single group membership
                if (membershipType && membershipType.applicationFields?.collectMemberDetailsTogether === false) {
                    let name = "";
                    let birthDate = "";
                    if (personId) {
                        // Get name
                        const queryResult = await getPersonByPersonId(personId);
                        if (queryResult.isSuccess) {
                            const personData: Person = queryResult.result;
                            name = (personData.firstName ? personData.firstName.trim() : "") + 
                                (personData.firstName && personData.lastName ? " " : "") + 
                                (personData.lastName ? personData.lastName.trim() : "");
                        } else {
                            setError(queryResult.message);
                        }
                        // Get birth date
                        const queryResultInfo = await getPersonalInformationByPersonId(personId);
                        if (queryResultInfo.isSuccess) {
                            const personalInformation: PersonalInformation = queryResultInfo.result;
                            birthDate = personalInformation && personalInformation.dateOfBirth ? personalInformation.dateOfBirth : "";
                        } else {
                            // setError(queryResultInfo.message);
                        }
                    }
                    const singleGroupMember = {
                        name: name,
                        birthDate: birthDate
                    };
                    setGroupMembers([singleGroupMember]);
                }
            } else if (membershipType.category === MembershipTypeCategory.HORSE) {
                getPrePopulatedDataForHorseMembership();
            }
        };
      
        if (organization) getOrganizationMemberships(organization);
        getPrePopulatedData();
    }, [membershipType, organization]);

    useEffect(() => {
        if (membership) {
            if (membership.personName) setFullName(membership.personName);
            if (membership.personEmail) setEmail(membership.personEmail);
            if (membership.gradeLevel) setGradeLevel(membership.gradeLevel);
            if (membership.barn) {
                // Display the selected barn based on the saved membership
                setBarn(membership.barn);
            }
            if (membership.rider) {
                setRider(membership.rider);
            }
        }
        if(membershipType.category === MembershipTypeCategory.GROUP) {
            getPrePopulatedDataForGroupMembership();
        }

        if(membershipType.category === MembershipTypeCategory.HORSE) {
            getPrePopulatedDataForHorseMembership();
        }
    }, [membership, memberships]);

    useEffect(() => {
        getHorses();
    }, []);

    useEffect(() => {
        if (barn) getHorses();
    }, [barn]);

    const handleAddress = async (name: string, id: string| undefined | null) => {
        try {
            let addressData: Address | null = null;
        
            if (id) {
                const addressInput: UpdateAddressInput = {
                    id: id,
                    name: name,
                    type: "mailing",
                    isDefault: true,
                    streetAddress1: streetAddressLine1,
                    streetAddress2: streetAddressLine2,
                    city: city,
                    provState: provState,
                    zip: zip,
                    country: country,
                    notes: ""
                };
                const updateAddressResult = await updateAddress(addressInput);
                if (updateAddressResult.isSuccess) {
                    addressData = updateAddressResult.result;
                    setAddress(updateAddressResult.result);
                } else {
                    setError(updateAddressResult.message);
                }
            } else {
                const addressInput: CreateAddressInput = {
                    name: name,
                    type: "mailing",
                    isDefault: true,
                    streetAddress1: streetAddressLine1,
                    streetAddress2: streetAddressLine2,
                    city: city,
                    provState: provState,
                    zip: zip,
                    country: country,
                    notes:""
                };
                const createAddressResult = await createAddress(addressInput);
                if (createAddressResult.isSuccess) {
                    addressData = createAddressResult.result;
                    setAddress(createAddressResult.result);
                } else {
                    setError(createAddressResult.message);
                }
            }
            return addressData;
        } catch (error) {
            const message = "Could not save the address information: " + error;
            setError(message);
        }
    }

    const handleGroupLeaderAddress = async (name: string, id: string| undefined | null) => {
        try {
            let addressData: Address | null = null;
        
            if (id) {
                const addressInput: UpdateAddressInput = {
                    id: id,
                    name: name,
                    type: "mailing",
                    isDefault: true,
                    streetAddress1: leaderStreetAddressLine1,
                    streetAddress2: leaderStreetAddressLine2,
                    city: leaderCity,
                    provState: leaderProvState,
                    zip: leaderZip,
                    country: leaderCountry,
                    notes: ""
                };
                const updateAddressResult = await updateAddress(addressInput);
                if (updateAddressResult.isSuccess) {
                    addressData = updateAddressResult.result;
                    setLeaderAddress(updateAddressResult.result);
                } else {
                    setError(updateAddressResult.message);
                }
            } else {
                const addressInput: CreateAddressInput = {
                    name: name,
                    type: "mailing",
                    isDefault: true,
                    streetAddress1: leaderStreetAddressLine1,
                    streetAddress2: leaderStreetAddressLine2,
                    city: leaderCity,
                    provState: leaderProvState,
                    zip: leaderZip,
                    country: leaderCountry,
                    notes:""
                };
                const createAddressResult = await createAddress(addressInput);
                if (createAddressResult.isSuccess) {
                    addressData = createAddressResult.result;
                    setLeaderAddress(createAddressResult.result);
                } else {
                    setError(createAddressResult.message);
                }
            }
            return addressData;
        } catch (error) {
            const message = "Could not save the address information: " + error;
            setError(message);
        }
    }

    const handleContact = async (name: string, id: string| undefined | null, addressId?: string) => {
        try {
            let contactData: Contact | null = null;
            if (id) {
                const contactInput: UpdateContactInput = {
                    name: name,
                    id: id,
                    cell: phoneType === "cell" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    home: phoneType === "home" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    work: phoneType === "work" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    personalEmail: email ? email : undefined,
                    mailingAddress: addressId
                };
                const updateContactResult = await updateContact(contactInput);
                if (updateContactResult.isSuccess) {
                    setContact(updateContactResult.result);
                    contactData = updateContactResult.result;
                } else {
                    setError(updateContactResult.message);
                }
            } else {
                const contactInput: CreateContactInput = {
                    name: name,
                    cell: phoneType === "cell" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    home: phoneType === "home" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    work: phoneType === "work" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    personalEmail: email ? email : undefined,
                    mailingAddress: addressId
                };
                const createContactResult = await createContact(contactInput);
                if (createContactResult.isSuccess) {
                    setContact(createContactResult.result);
                    contactData = createContactResult.result;
                } else {
                    setError(createContactResult.message);
                }
            }
            return contactData;
        } catch (error) {
            const message = "Could not save the contact information: " + error;
            setError(message);
        }
    }

    const handleGroupLeaderContact = async (leaderName: string, id: string| undefined | null, addressId?: string) => {
        try {
            let contactData: Contact | null = null;
            if (id) {
                const contactInput: UpdateContactInput = {
                    name: leaderName,
                    id: id,
                    cell: leaderPhoneType === "cell" ? formatAllCountryPhoneNumber(leaderPhoneNumber) : undefined,
                    home: leaderPhoneType === "home" ? formatAllCountryPhoneNumber(leaderPhoneNumber) : undefined,
                    work: leaderPhoneType === "work" ? formatAllCountryPhoneNumber(leaderPhoneNumber) : undefined,
                    personalEmail: leaderEmail ? leaderEmail : undefined,
                    mailingAddress: addressId
                };
                const updateContactResult = await updateContact(contactInput);
                if (updateContactResult.isSuccess) {
                    setLeaderContact(updateContactResult.result);
                    contactData = updateContactResult.result;
                } else {
                    setError(updateContactResult.message);
                }
            } else {
                const contactInput: CreateContactInput = {
                    name: leaderName,
                    cell: leaderPhoneType === "cell" ? formatAllCountryPhoneNumber(leaderPhoneNumber) : undefined,
                    home: leaderPhoneType === "home" ? formatAllCountryPhoneNumber(leaderPhoneNumber) : undefined,
                    work: leaderPhoneType === "work" ? formatAllCountryPhoneNumber(leaderPhoneNumber) : undefined,
                    personalEmail: leaderEmail ? leaderEmail : undefined,
                    mailingAddress: addressId
                };
                const createContactResult = await createContact(contactInput);
                if (createContactResult.isSuccess) {
                    setLeaderContact(createContactResult.result);
                    contactData = createContactResult.result;
                } else {
                    setError(createContactResult.message);
                }
            }
            return contactData;
        } catch (error) {
            const message = "Could not save the contact information: " + error;
            setError(message);
        }
    }

    const handlePersonalInformation = async (addressData: Address | null | undefined, contactData: Contact | null | undefined) => {
        try {
            if(personInfo?.id){
                const personalInformationInput: UpdatePersonalInformationInput = {
                    id: personInfo.id,
                    dateOfBirth: birthDate,
                    contactId: contactData?.id,
                    addressId: addressData?.id,
                };
                const updatePersonInfoResult = await updatePersonalInformation(personalInformationInput);
                if (updatePersonInfoResult.isSuccess) {
                    setPersonInfo(updatePersonInfoResult.result);
                } else {
                    setError(updatePersonInfoResult.message);
                }
            } else {
                const personalInformationInput: CreatePersonalInformationInput = {
                    personId: personId,
                    dateOfBirth: birthDate,
                    contactId: contactData?.id,
                    addressId: addressData?.id,
                };
                const createPersonInfoResult = await createPersonalInformation(personalInformationInput);
                if (createPersonInfoResult.isSuccess) {
                    setPersonInfo(createPersonInfoResult.result);
                } else {
                    setError(createPersonInfoResult.message);
                }
            }
        } catch (error) {
            const message = "Could not save the personal information: " + error;
            setError(message);
        }
    }

    const handleRider = async (addressData: Address | null | undefined, contactData: Contact | null | undefined) => {
        try {
            const location = city + ", " + provState;
            const age = moment().diff(birthDate, 'years');
            if (rider) {
                const riderInput: UpdateRiderInput = {
                    id: rider.id,
                    name: fullName,
                    location: location,
                    riderContactId: contactData?.id,
                    addressId: addressData?.id,
                    personId: personId,
                    age: age,
                    birthdate: birthDate,
                    barnId: barn?.id,
                    barnName: barn?.name,
                    createdBy: personId
                };
                const updateRiderResult = await updateRider(riderInput);
                if (updateRiderResult.isSuccess) {
                    setRider(updateRiderResult.result);
                    return updateRiderResult.result;
                } else {
                    console.error(updateRiderResult.message);
                }
            } else {
                const riderInput: CreateRiderInput = {
                    name: fullName,
                    location: location,
                    riderContactId: contactData?.id,
                    addressId: addressData?.id,
                    personId: personId,
                    age: age,
                    birthdate: birthDate,
                    barnId: barn?.id,
                    barnName: barn?.name,
                    createdBy: personId,
                    createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                };
                const createRiderResult = await createRider(riderInput);
                if (createRiderResult.isSuccess) {
                    setRider(createRiderResult.result);
                    return createRiderResult.result;
                } else {
                    console.error(createRiderResult.message);
                }
            }
        } catch (error) {
            const message = "Could not save the rider information: " + error;
            setError(message);
        }
    }

    const handleHorseContact = async (name: string, addressId: string,  id?: string| undefined | null) => {
        try {
            let contactData: Contact | null = null;
            if (id) {
                const contactInput: UpdateContactInput = {
                    name: name,
                    id: id,
                    cell: phoneType === "cell" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    home: phoneType === "home" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    work: phoneType === "work" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    personalEmail: email ? email : undefined,
                    mailingAddress: addressId
                };
                const updateContactResult = await updateContact(contactInput);
                if (updateContactResult.isSuccess) {
                    setContact(updateContactResult.result);
                    contactData = updateContactResult.result;
                } else {
                    setError(updateContactResult.message);
                }
            } else {
                const contactInput: CreateContactInput = {
                    name: name,
                    cell: phoneType === "cell" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    home: phoneType === "home" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    work: phoneType === "work" ? formatAllCountryPhoneNumber(phoneNumber) : undefined,
                    personalEmail: email ? email : undefined,
                    mailingAddress: addressId
                };
                const createContactResult = await createContact(contactInput);
                if (createContactResult.isSuccess) {
                    setContact(createContactResult.result);
                    contactData = createContactResult.result;
                } else {
                    setError(createContactResult.message);
                }
            }
            return contactData;
        } catch (error) {
            const message = "Could not save the contact information: " + error;
            setError(message);
        }
    }

    const handleUploadDocument = async (file: any, horse: Horse) => {
        if (horse) {
            // Upload this to S3 
            const fileName = createCogginsDocumentFileName(horse);
            let uploadResult;
            if (file.type === "application/pdf") {
                uploadResult = await handleUploadS3File(fileName, file);
            } else {
                uploadResult = await handleUploadS3Image(fileName, file);
            }
            if (uploadResult.isSuccess) {
                // If successfully added to S3, save to video list to the person
                const key = uploadResult.result;
                const title = "Coggins for " + horse.name;
                const description = "Coggins uploaded for " + horse.name + " by " + user?.firstName + " " + user?.lastName + " on " + moment(new Date()).format("MMM DD, YYYY h:mm a"); 
                const s3Object = createS3Object(key, title, file, description);
                return s3Object;
            } else {
                setError("Could not upload the video. Contact hello@ringsidepro.com for help.")
            }
        } else {
            setError("No horse found.")
        }
    }
    
    const handleHorse = async (contactData: Contact | null | undefined) => {
        try {
            if (selectedHorse) {
                const horseInput: UpdateHorseInput = {
                    id: selectedHorse.id,
                    personId: personId,
                    name: horseName,
                    ownerName: horseOwnerName,
                    height: horseHeight.toString(),
                    barnId: barn?.id,
                    barnName: barn?.name,
                    dateOfBirth: birthDate ? birthDate : null,
                    contactId: contactData?.id           
                };
                if(membershipType.applicationFields?.cogginsInfo) {
                    if (cogginsState) horseInput.cogginsState = cogginsState;
                    if (cogginsDate) horseInput.cogginsDate = cogginsDate;
                    if (cogginsNumber) horseInput.cogginsNumber = cogginsNumber;
                } else {
                    horseInput.cogginsState = "";
                    horseInput.cogginsDate = null;
                    horseInput.cogginsNumber = "";
                }
                if(membershipType.applicationFields?.cogginsUpload) {
                    if (selectedHorse.coggins && !cogginsDocument) {
                        horseInput.coggins = selectedHorse.coggins;
                    } else if (cogginsDocument) {
                        const s3Object = await handleUploadDocument(cogginsDocument, selectedHorse);
                        horseInput.coggins = s3Object;
                    }
                } else {
                    horseInput.coggins = null;
                }
                const updateHorseResult = await updateHorse(horseInput);
                if (updateHorseResult.isSuccess) {
                    setSelectedHorse(updateHorseResult.result);
                    return updateHorseResult.result;
                } else {
                    console.error(updateHorseResult.message);
                }
            } else {
                const horseInput: CreateHorseInput = {
                    personId: personId,
                    name: horseName,
                    ownerName: horseOwnerName,
                    height: horseHeight.toString(),
                    dateOfBirth: birthDate ? birthDate : null,
                    barnId: barn?.id,
                    barnName: barn?.name,
                    contactId: contactData?.id,             
                    createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                };
                if(membershipType.applicationFields?.cogginsInfo) {
                    if (cogginsState) horseInput.cogginsState = cogginsState;
                    if (cogginsDate) horseInput.cogginsDate = cogginsDate;
                    if (cogginsNumber) horseInput.cogginsNumber = cogginsNumber;
                } else {
                    horseInput.cogginsState = "";
                    horseInput.cogginsDate = null;
                    horseInput.cogginsNumber = "";
                }
                const createHorseResult = await createHorse(horseInput);
                if (createHorseResult.isSuccess) {
                    setSelectedHorse(createHorseResult.result);
                    const horse: Horse = createHorseResult.result;
                    if (cogginsDocument) {
                        const s3Object = await handleUploadDocument(cogginsDocument, createHorseResult.result);
                        const horseUpdateInput: UpdateHorseInput = {
                            id: horse.id,
                            coggins: s3Object
                        };
                        const updateHorseResult = await updateHorse(horseUpdateInput);
                        if (updateHorseResult.isSuccess) {
                            setSelectedHorse(updateHorseResult.result);
                            return updateHorseResult.result;
                        } else {
                            setError(updateHorseResult.message);
                        }
                    } else {
                        return createHorseResult.result;
                    }
                } else {
                    setError(createHorseResult.message);
                }
            }
        } catch (error) {
            const message = "Could not save the horse information: " + error;
            setError(message);
        }
    }

    const handleGroupMemberRider = async (groupMember: GroupMember) => {
        try { 
            let foundRider: Rider | null = null;
            
            // Try to find a rider in the DB that matches this groupMember by name
            if (groupMember.name !== "") {
                const queryByPersonNameResult = await getMembershipsByPersonName(groupMember.name);
                if (queryByPersonNameResult.isSuccess) {
                    const membershipArray: Membership[] = queryByPersonNameResult.result;
                    if (membershipArray && membershipArray.length > 0) {
                        for (let i = 0; i < membershipArray.length; i++) {
                            const currentMembership = membershipArray[i];
                            // Check if this barn matches the membership barn
                            if (barn && currentMembership.barnId === barn?.id) {
                                if (currentMembership.rider) foundRider = currentMembership.rider;
                            }
                            // Check if this organization matched the membership organization
                            else if (organization && currentMembership.organizationId === organization?.id) {
                                if (currentMembership.rider) foundRider = currentMembership.rider;
                            } 
                        }
                    }
                }
                if (!foundRider && barn) {
                    const queryResult = await getRidersByBarnId(barn.id);
                    if (queryResult.isSuccess) {
                        const riders: Rider[] = queryResult.result;
                        if (riders && riders.length > 0) {
                            for (let i = 0; i < riders.length; i++) {
                                const currentRider = riders[i];
                                if (currentRider.name.toLowerCase() === groupMember.name.toLowerCase()) {
                                    foundRider = currentRider;
                                }
                            }
                        }
                    }
                }
            }

            if (foundRider) {
                return foundRider;
            } else {
                const riderInput: CreateRiderInput = {
                    name: groupMember.name,
                    location: "",
                    riderContactId: "",
                    addressId: "",
                    personId: personId,
                    age: moment().diff(groupMember.birthDate, 'years'),
                    birthdate: groupMember.birthDate,
                    barnId: barn?.id,
                    barnName: barn?.name,
                    createdBy: personId,
                    createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                };
                const createRiderResult = await createRider(riderInput);
                if (createRiderResult.isSuccess) {
                    return createRiderResult.result;
                } else {
                    console.error(createRiderResult.message);
                }
            }
        } catch (error) {
            const message = "Could not save the rider information: " + error;
            setError(message);
        }
    }

    const getDateMembershipEnds = () => {
        try {
            const schedule =  membershipType.schedule;
            const isStartAtTimeOfPurchase = membershipType.isStartAtTimeOfPurchase;
            const setStartDate = membershipType.setStartDate;
            let dateMembershipEnds;
            if(schedule === MembershipTypeSchedule.ANNUAL) {
                if(isStartAtTimeOfPurchase) {
                    // End date should be one year after today's date (because the membership starts on the date of purchase)
                    dateMembershipEnds = moment().add(1, "year").format("YYYY-MM-DD");
                } else {
                    // End date should be one year after the set start date
                    dateMembershipEnds = moment(setStartDate).year((new Date()).getFullYear()).add(1, "year").format("YYYY-MM-DD");
                }
            } else if (schedule === MembershipTypeSchedule.LIFE) {
                dateMembershipEnds = "";
            }
            return dateMembershipEnds;
        } catch (error) {
            const message = "Could not get the date the membership should end: " + error;
            setError(message);
        }
    }

    const handleMembership = async (riderData: Rider) => {
        try {
            if (membership) {
                const input: UpdateMembershipInput = {
                    id: membership.id,
                    name: organization && organization.name ? organization.name : "",
                    organizationMembershipTypeId: membershipType.id,
                    type: membershipType.name,
                    personId: personId,
                    personName: fullName,
                    personEmail: email,
                    organizationName: organization?.name,
                    organizationId: organization?.id,
                    gradeLevel: membershipType.applicationFields?.gradeLevel ? gradeLevel : "",
                    barnId: membershipType.applicationFields?.barnName && barn && barn.id ? barn.id : null,
                    riderId: riderData && riderData.id ? riderData.id : null
                };
                const updateMembershipResult = await updateMembership(input);
                if (updateMembershipResult.isSuccess) {
                    setMemberships([updateMembershipResult.result]);
                } else {
                    setError(updateMembershipResult.message);
                }
            } else {
                const dateMembershipEnds = getDateMembershipEnds();
                const input: CreateMembershipInput = {
                    name: organization && organization.name ? organization.name : "",
                    membershipId: membershipType.nextAvailableMembershipId || "",
                    backNumber: backNumber,
                    membershipStatus: CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS,
                    organizationMembershipTypeId: membershipType.id,
                    type: membershipType.name,
                    amountPaid: 0,
                    personId: personId,
                    createdBy: personId,
                    personName: fullName,
                    personEmail: email,
                    organizationName: organization?.name,
                    organizationId: organization?.id,
                    gradeLevel: membershipType.applicationFields?.gradeLevel ? gradeLevel : "",
                    barnId: membershipType.applicationFields?.barnName && barn && barn.id ? barn.id : null,
                    riderId: riderData && riderData.id ? riderData.id : null,
                    dateMembershipEnds: dateMembershipEnds
                };
                const createMembershipResult = await createMembership(input);
                if (createMembershipResult.isSuccess) {
                    if(membershipType.applicationOptions?.autoAssignIds && membershipType?.membershipIdValues) {
                        const newMembershipId = getNextAvailableMembershipId(membershipType?.membershipIdValues, membershipType.nextAvailableMembershipId || "");
                        const updateInput:  UpdateOrganizationMembershipTypeInput = {
                            id: membershipType.id,
                            nextAvailableMembershipId: newMembershipId
                        };
                        await updateOrganizationMembershipType(updateInput);
                    }
                    setMemberships([createMembershipResult.result]);
                } else {
                    setError(createMembershipResult.message);
                }
            }
        } catch (error) {
            const message = "Could not save the membership: " + error;
            setError(message);
        }
    }

    const handleGroupMembership = async (
        groupMember: GroupMember, membershipId: string, riderData: Rider,
        currentMembership: Membership | undefined| void, groupContactId: string, groupLeaderContactId: string) => {
        try {
            if (currentMembership) {
                const input: UpdateMembershipInput = {
                    id: currentMembership.id,
                    name: organization && organization.name ? organization.name : "",
                    organizationMembershipTypeId: membershipType.id,
                    type: membershipType.name,
                    personId: personId,
                    personName: groupMember.name,
                    personEmail: email,
                    organizationName: organization?.name,
                    organizationId: organization?.id,
                    barnId: barn && barn.id ? barn.id : null,
                    riderId: riderData && riderData.id ? riderData.id : null,
                    groupContactId,
                    groupLeaderContactId
                };
                const updateMembershipResult = await updateMembership(input);
                if (updateMembershipResult.isSuccess) {
                    return updateMembershipResult.result;
                } else {
                    setError(updateMembershipResult.message);
                }
            } else {
                const dateMembershipEnds = getDateMembershipEnds();
                const input: CreateMembershipInput = {
                    name: organization && organization.name ? organization.name : "",
                    membershipId: membershipId,
                    backNumber: groupMember.backNumber || 0,
                    membershipStatus: CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS,
                    organizationMembershipTypeId: membershipType.id,
                    type: membershipType.name,
                    amountPaid: 0,
                    personId: personId,
                    createdBy: personId,
                    personName: groupMember.name,
                    personEmail: email,
                    organizationName: organization?.name,
                    organizationId: organization?.id,
                    barnId: barn && barn.id ? barn.id : null,
                    riderId: riderData && riderData.id ? riderData.id : null,
                    dateMembershipEnds: dateMembershipEnds,
                    groupContactId,
                    groupLeaderContactId
                };
                const createMembershipResult = await createMembership(input);
                if (createMembershipResult.isSuccess) {
                    return createMembershipResult.result;
                } else {
                    setError(createMembershipResult.message);
                }
            }
        } catch (error) {
            const message = "Could not save the membership: " + error;
            setError(message);
        }
    }

    const handleHorseMembership = async (horseData: Horse) => {
        try {
            let personName;
            if (user) {
                personName = (user.firstName ? user.firstName.trim() : "") + 
                (user.firstName && user.lastName ? " " : "") + 
                (user.lastName ? user.lastName.trim() : "");
            }
            if (membership) {
                const input: UpdateMembershipInput = {
                    id: membership.id,
                    name: organization && organization.name ? organization.name : "",
                    personId: personId,
                    personName: personName,
                    personEmail:email,
                    organizationMembershipTypeId: membershipType.id,
                    type: membershipType.name,
                    horseId: horseData && horseData.id ? horseData.id : selectedHorse?.id,
                    organizationName: organization?.name,
                    organizationId: organization?.id,
                    barnId: horseData.barnId
                };
                const updateMembershipResult = await updateMembership(input);
                if (updateMembershipResult.isSuccess) {
                    setMemberships([updateMembershipResult.result]);
                } else {
                    setError(updateMembershipResult.message);
                }
            } else {
                const dateMembershipEnds = getDateMembershipEnds();
                const input: CreateMembershipInput = {
                    name: organization && organization.name ? organization.name : "",
                    membershipId: membershipType.nextAvailableMembershipId || "",
                    backNumber: backNumber,
                    personEmail: email,
                    personName: personName,
                    membershipStatus: CONSTANT.MEMBERSHIP.APPLICATION_STATUS.IN_PROGRESS,
                    organizationMembershipTypeId: membershipType.id,
                    type: membershipType.name,
                    amountPaid: 0,
                    horseId: horseData && horseData.id ? horseData.id : selectedHorse?.id,
                    personId: personId,
                    organizationName: organization?.name,
                    organizationId: organization?.id,
                    barnId: horseData.barnId,
                    dateMembershipEnds: dateMembershipEnds
                };
                const createMembershipResult = await createMembership(input);
                if (createMembershipResult.isSuccess) {
                    if(membershipType.applicationOptions?.autoAssignIds && membershipType?.membershipIdValues) {
                        const newMembershipId = getNextAvailableMembershipId(membershipType?.membershipIdValues, membershipType.nextAvailableMembershipId || "");
                        const updateInput:  UpdateOrganizationMembershipTypeInput = {
                            id: membershipType.id,
                            nextAvailableMembershipId: newMembershipId
                        };
                        await updateOrganizationMembershipType(updateInput);
                    }
                    setMemberships([createMembershipResult.result]);
                } else {
                    setError(createMembershipResult.message);
                }
            }
        } catch (error) {
            const message = "Could not save the membership: " + error;
            setError(message);
        }
    }

    const handleSubmit = async () => {
        const isValid = verifyIsValid();
        if (isValid) {
            setIsLoading(true);
            try {
                // For an individual membership - create one membership
                if (membershipType.category === MembershipTypeCategory.INDIVIDUAL) {
                    // First, create and update the Address Object
                    const addressData = await handleAddress(fullName, personInfo?.addressId);
                    // Second, create and update the Contact Object
                    const contactData = await handleContact(fullName, personInfo?.contactId);
                    //   Third, create/update the PersonalInformation Object
                    await handlePersonalInformation(addressData, contactData);
                    //   Fourth, create/update the Rider Object
                    const riderData = await handleRider(addressData, contactData);
                    //   Fifth, create/update the membership Object
                    await handleMembership(riderData);
                } 
                // For a group membership - create each membership
                else if (membershipType.category ===  MembershipTypeCategory.GROUP) {
                    // Use the same membership id for all of the memberships
                    let membershipId = "";
                    if(memberships?.length) {
                        membershipId = memberships[0].membershipId;
                    } else {
                        membershipId = membershipType.nextAvailableMembershipId || "";
                    }
                    let groupContactId = "";
                    let groupLeaderContactId = "";
                    const membershipArray: Membership[] = [];
                    // Loop through the members and create a membership for each
                    for (let i = 0; i < groupMembers.length; i++) {
                        const currentGroupMember = groupMembers[i];
                        // do not create membership if name is absent
                        if(!currentGroupMember?.name) {
                            continue;
                        }
                        let currentMembership: Membership | undefined | void;
                        if(memberships && memberships?.length > 0) {
                            currentMembership = memberships?.find((m) => {
                                return m.personName?.toLowerCase() === currentGroupMember?.name?.toLowerCase();
                            });
                        }
                        if (currentGroupMember) {
                            // Second, create and update the Contact Object
                            let contactData;
                            let leaderContactData;
                            let addressData;
                            let leaderAddressData;
                            if (i === 0 && (membershipType.applicationFields?.groupName || membershipType.applicationFields?.contact)) {
                                // create/update address record and store the address id in contact's mailingAddress field
                                if(membershipType.applicationFields?.contact) {
                                    addressData = await handleAddress(
                                        groupTeamName, contact?.mailingAddress
                                    );
                                }
                                contactData = await handleContact(
                                    groupTeamName, currentMembership?.groupContactId, addressData?.id
                                );
                                groupContactId = contactData?.id || "";
                            }
                            if (i === 0 && (membershipType.applicationFields?.groupLeader || membershipType.applicationFields?.groupLeaderContactInfo)) {
                                if(membershipType.applicationFields?.groupLeaderContactInfo) {
                                    leaderAddressData = await handleGroupLeaderAddress(
                                        leaderName, leaderContact?.mailingAddress
                                    );
                                }
                                leaderContactData = await handleGroupLeaderContact(
                                    leaderName, currentMembership?.groupLeaderContactId, leaderAddressData?.id
                                );
                                groupLeaderContactId = leaderContactData?.id || "";
                            }

                            const riderData: Rider = await handleGroupMemberRider(currentGroupMember);
                            const membershipResult = await handleGroupMembership(
                                currentGroupMember, membershipId, riderData,
                                currentMembership, groupContactId, groupLeaderContactId);
                            membershipArray.push(membershipResult);
                        }
                    }

                    // Finally, update the nextAvailableMembershipId
                    if(membershipType.applicationOptions?.autoAssignIds &&
                        membershipType?.membershipIdValues &&
                        memberships && memberships?.length === 0) {
                        const newMembershipId = getNextAvailableMembershipId(membershipType?.membershipIdValues, membershipType.nextAvailableMembershipId || "");
                        const updateInput:  UpdateOrganizationMembershipTypeInput = {
                            id: membershipType.id,
                            nextAvailableMembershipId: newMembershipId
                        };
                        await updateOrganizationMembershipType(updateInput);
                    }
                    setMemberships(membershipArray);
                } 
                // For a horse membership - create each membership
                else if (membershipType.category ===  MembershipTypeCategory.HORSE) {
                    // First, create and update the Address Object
                    let addressData;
                    let contactData;
                    if(membershipType.applicationFields?.contact) {
                        addressData = await handleAddress(horseOwnerName, contact?.mailingAddress);
                        // Second, create and update the Contact Object
                        contactData = await handleHorseContact(horseOwnerName, addressData?.id || "", membership?.horse?.contactId);
                    }
                    //   Third, create/update the Horse Object
                    const horseData = await handleHorse(contactData);
                    //   Fourth, create/update the membership Object
                    await handleHorseMembership(horseData);
                }
                handleNavigateToNext();
            } catch (error: any) {
                const message = "Could not create or update the membership: " + error;
                setError(message);
            }
            setIsLoading(false);   
        }
    };

    const handleNavigateToNext = () => {
        setSegmentValue(CONSTANT.MEMBERSHIP.APPLICATION_STAGES.UPLOADS);
    }

    const verifyIsValid = () => {
        if (membershipType.category === MembershipTypeCategory.INDIVIDUAL) {
            if (!fullName) {
                setError("A required field is missing data: Full Name");
                return false;
            }
            if (!birthDate) {
                setError("A required field is missing data: Birth Date");
                return false;
            }
            if (!email) {
                setError("A required field is missing data: Email Address");
                return false;
            }
            if (!phoneType) {
                setError("A required field is missing data: Phone Number Type");
                return false;
            }
            if (!phoneNumber) {
                setError("A required field is missing data: Phone Number");
                return false;
            }
            if (!streetAddressLine1) {
                setError("A required field is missing data: Street Address Line1");
                return false;
            }
            if (!city) {
                setError("A required field is missing data: City");
                return false;
            }
            if (!provState) {
                setError("A required field is missing data: State");
                return false;
            }
            if (!zip) {
                setError("A required field is missing data: Postal Code");
                return false;
            }
            if (!country) {
                setError("A required field is missing data: Country");
                return false;
            }
            if(membershipType.applicationFields?.gradeLevel && gradeLevel === "-") {
                setError("A required field is missing data: Grade Level");
                return false;
            }
        }

        if (membershipType.category ===  MembershipTypeCategory.GROUP) {
            if(membershipType.applicationFields?.groupName && !groupTeamName) {
                setError(`A required field is missing data: ${groupOrTeam} Name`);
                return false;
            }
            if (membershipType.applicationFields?.collectMemberDetailsTogether && (!groupMembers || groupMembers.length === 0 || !groupMembers[0]?.name)) {
                setError("A required field is missing data: Group Member #1 Full Name");
                return false;
            }
            if(membershipType.applicationFields?.contact) {
                if (!email) {
                    setError(`A required field is missing data(${groupOrTeam} Contact Info): Email Address`);
                    return false;
                }
                if (!phoneType) {
                    setError(`A required field is missing data(${groupOrTeam} Contact Info): Phone Number Type`);
                    return false;
                }
                if (!phoneNumber) {
                    setError(`A required field is missing data(${groupOrTeam} Contact Info): Phone Number`);
                    return false;
                }
                if (!streetAddressLine1) {
                    setError(`A required field is missing data(${groupOrTeam} Contact Info): Street Address Line1`);
                    return false;
                }
                if (!city) {
                    setError(`A required field is missing data(${groupOrTeam} Contact Info): City`);
                    return false;
                }
                if (!provState) {
                    setError(`A required field is missing data(${groupOrTeam} Contact Info): State`);
                    return false;
                }
                if (!zip) {
                    setError(`A required field is missing data(${groupOrTeam} Contact Info): Postal Code`);
                    return false;
                }
                if (!country) {
                    setError(`A required field is missing data(${groupOrTeam} Contact Info): Country`);
                    return false;
                }
            }
            if(membershipType.applicationFields?.groupLeader && !leaderName) {
                setError(`A required field is missing data: ${groupOrTeam} Leader Name`);
                return false;
            }
            if(membershipType.applicationFields?.groupLeaderContactInfo) {
                if (!leaderEmail) {
                    setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): Email Address`);
                    return false;
                }
                if (!leaderPhoneType) {
                    setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): Phone Number Type`);
                    return false;
                }
                if (!leaderPhoneNumber) {
                    setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): Phone Number`);
                    return false;
                }
                if (!leaderStreetAddressLine1) {
                    setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): Street Address Line1`);
                    return false;
                }
                if (!leaderCity) {
                    setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): City`);
                    return false;
                }
                if (!leaderProvState) {
                    setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): State`);
                    return false;
                }
                if (!leaderZip) {
                    setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): Postal Code`);
                    return false;
                }
                if (!leaderCountry) {
                    setError(`A required field is missing data(${groupOrTeam} Leader Contact Info): Country`);
                    return false;
                }
            }
        }

        if (membershipType.category ===  MembershipTypeCategory.HORSE) {
            if (!horseName) {
                setError("A required field is missing data: Horse Name");
                return false;
            }
            if (!horseHeight) {
                setError("A required field is missing data: Height in Hands");
                return false;
            }
            if (!horseOwnerName) {
                setError("A required field is missing data: Owner's Name");
                return false;
            }
            if (!email) {
                setError("A required field is missing data: Email Address");
                return false;
            }
            if (!phoneType) {
                setError("A required field is missing data: Phone Number Type");
                return false;
            }
            if (!phoneNumber) {
                setError("A required field is missing data: Phone Number");
                return false;
            }
            if (!streetAddressLine1) {
                setError("A required field is missing data: Street Address Line1");
                return false;
            }
            if (!city) {
                setError("A required field is missing data: City");
                return false;
            }
            if (!provState) {
                setError("A required field is missing data: State");
                return false;
            }
            if (!zip) {
                setError("A required field is missing data: Postal Code");
                return false;
            }
            if (!country) {
                setError("A required field is missing data: Country");
                return false;
            }
            if (membershipType.applicationFields?.dateOfBirth && !birthDate) {
                setError("A required field is missing data: Birth Date");
                return false;
            }
            if (membershipType.applicationFields?.barnName && !barn) {
                setError("A required field is missing data: Barn");
                return false;
            }
            if (membershipType.applicationFields?.cogginsInfo) {
                if (!cogginsNumber) {
                    setError("A required field is missing data: Coggins Accession Number");
                    return false;
                }
                if (!cogginsDate) {
                    setError("A required field is missing data: Coggins Test Date");
                    return false;
                }
                if (!cogginsState) {
                    setError("A required field is missing data: State of Test");
                    return false;
                }
            }
            if (selectedHorse?.coggins) {
                setError("");
            } else if (membershipType.applicationFields?.cogginsUpload && !cogginsDocument) {
                setError("A required field is missing data: Upload a Coggins");
                return false;
            }
        }
        return true;
    };

    const handleEditFullName = (updatedName: string) => {
        setFullName(updatedName);
        getPreviousMembershipByPersonName(updatedName);
    }

    const handleCogginsState = (state: string) => {
        if (state && state !== "-"){ 
            setCogginsState(state);
        } else {
            setCogginsState("");
        }
    }

    async function getHorses() {
        if (user) {
            const horses = await getHorsesByUserByBarn(user);
            if (horses) setHorseList(horses);
        }
    }

    const handleHorseSelect = (horse: Horse) => {
        if(horse) {
            setSelectedHorse(horse);
            if(horse.barn) setBarn(horse.barn);
            if (horse.name) setHorseName(horse.name);
            if (horse.height) setHorseHeight(parseFloat(horse.height));
            if (horse.ownerName) setHorseOwnerName(horse.ownerName);
            if (horse.dateOfBirth) setBirthDate(horse.dateOfBirth);
            if( membershipType.category === MembershipTypeCategory.HORSE && membershipType.applicationFields?.cogginsInfo) {
                if (horse.cogginsDate) setCogginsDate(horse.cogginsDate);
                if (horse.cogginsNumber) setCogginsNumber(horse.cogginsNumber);
                if (horse.cogginsState) setCogginsState(horse.cogginsState || "");
            }
            if( membershipType.category === MembershipTypeCategory.HORSE && membershipType.applicationFields?.cogginsUpload) {
                if (horse.coggins) setCogginsDocument(horse.coggins.title);
            }
        } else {
            clearForm();
        }
    }

    const handleSelectedDocument = async (file: any) => {
        setCogginsDocument(file);
    }

    const handleHeightChange = (heightInInches: number) => {
        setHorseHeight(heightInInches);
    }

    return (
        <React.Fragment>
            <IonCard color="white" className="ion-padding">
                <h3>{membershipType.name}</h3>
                {isLoading ?
                    <Spinner />
                    :
                    <form>
                        {error && <ErrorAlert width="12" error={error} />}
                        {membershipType && membershipType.category !== MembershipTypeCategory.GROUP &&
                            membershipType.category !== MembershipTypeCategory.HORSE &&
                            <IonRow className="mt-3 mb-0">
                                <IonCol className="ion-text-center">
                                    <h4>Member Info</h4>
                                </IonCol>
                            </IonRow>
                        }
                        {membershipType.description && 
                        <IonRow>
                            <IonCol size="12">
                                <IonText
                                    class="ion-float-right"
                                    onClick={() => setShowDescription(!showDescription)}
                                >
                                    {showDescription ? "Hide Description" : "Show Description"}
                                </IonText>
                            </IonCol>
                            <IonCol size="12">
                                {showDescription && 
                                <Input
                                    rows={(isWindows() || isPlatform("desktop")) ? "5" : "15"}
                                    type="textarea"
                                    value={membershipType.description ?? ""}
                                    disabled={true}
                                />
                                }                                
                            </IonCol>
                        </IonRow>
                        }
                        {/* Individual Membership - Collect name and birthday */}
                        <IonRow className="mb-3">
                            {membershipType.applicationFields?.firstName &&
                                membershipType.applicationFields?.lastName && (
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                                Member's Full Name{" "}
                                                <RequiredInputIndicator />
                                            </IonLabel>
                                            <IonInput
                                                id="membership-app-basicInfo-fullName"
                                                type="text"
                                                value={fullName}
                                                aria-required={true}
                                                onIonChange={(e) => {
                                                    setError("");
                                                    handleEditFullName(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                )}
                            {membershipType &&
                                membershipType.applicationFields?.dateOfBirth && membershipType.category !== MembershipTypeCategory.HORSE && (
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">
                                                Member's Birth Date
                                                <RequiredInputIndicator />
                                            </IonLabel>
                                            <IonInput 
                                                id="membership-app-basicInfo-birth-date"
                                                type="date"
                                                value={birthDate}
                                                aria-required={true}
                                                onIonChange={e => {
                                                    setBirthDate(e.detail.value!)
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                            )}
                            {membershipType && membershipType.category === MembershipTypeCategory.INDIVIDUAL &&
                                membershipType.applicationFields?.backNumber && (
                                    <IonCol sizeXs="12" sizeMd="12">
                                        <IonItem color="white">
                                            <IonLabel position="stacked">
                                                {organization?.id === "e05fc919-2793-4ead-acef-3f6efb584b67" ? 
                                                    <span>Member's Arm Number</span>
                                                    :
                                                    <span>Member's Back Number(s)</span>
                                                }
                                            </IonLabel>
                                            <IonInput 
                                                type="number"
                                                value={backNumber}
                                                aria-required={true}
                                                disabled={organization?.id === "e05fc919-2793-4ead-acef-3f6efb584b67" ? false : true}
                                                onIonChange={e => {
                                                    setBackNumber(e.detail.value! ? parseInt(e.detail.value!) : 0);
                                                }}
                                            />
                                        </IonItem>
                                        {organization?.id === "e05fc919-2793-4ead-acef-3f6efb584b67" ? 
                                            <p className="ion-text-wrap description">If you have an arm number already, please enter it. If you do not know your arm number, it can be assigned later.</p>
                                            :
                                            <p className="ion-text-wrap description">(You cannot edit back numbers. The admin will handle this. This will show at most one back number - the admin will add any additional back numbers later.)</p>
                                        }
                                    </IonCol>
                            )}
                        </IonRow>
                        {membershipType && membershipType.category === MembershipTypeCategory.GROUP && <IonRow className="mt-3 mb-0">
                            <IonCol className="ion-text-center">
                                <h4>{groupOrTeam} Basic Info</h4>
                            </IonCol>
                            {membershipType.applicationFields?.groupName && <IonCol sizeXs="12" sizeMd="12">
                                <IonItem color="white">
                                    <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                        {groupOrTeam} Name{" "}
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput
                                        id="group-team-name"
                                        type="text"
                                        value={groupTeamName}
                                        aria-required={true}
                                        onIonChange={(e) => {
                                            setError("");
                                            setGroupTeamName(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>}
                        </IonRow>}
                        {membershipType && membershipType.category === MembershipTypeCategory.HORSE && <IonRow className="mt-3 mb-0">
                            <IonCol className="ion-text-center">
                                <h4>Horse Basic Info</h4>
                            </IonCol>
                        </IonRow>}
                        {membershipType && membershipType.category === MembershipTypeCategory.HORSE && <IonRow className="mt-3 mb-0">
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonLabel className="pl-3 text-darker" position="stacked">
                                    Barn{" "}
                                    {membershipType.applicationFields?.barnName && <RequiredInputIndicator />} 
                                </IonLabel>
                                <SelectFromAllBarns
                                    barn={barn}
                                    selectedValue={barn?.id}
                                    isHeightRequired={true}
                                    height={"45px"}
                                    onSelect={(barn: Barn) =>
                                        handleSelectBarn(barn)
                                    }
                                />
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonLabel position="stacked" color="dark">
                                    Select Existing Horse
                                </IonLabel>
                                <SelectHorse
                                    horses={horseList} 
                                    selectedValue={selectedHorse?.id} 
                                    onSelect={handleHorseSelect}
                                    isHeightRequired={true}
                                    height={"45px"}
                                />
                            </IonCol>
                            {membershipType.applicationFields?.horseName &&
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                        Horse Name
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput
                                        id="membership-app-basicInfo-horse-name"
                                        type="text"
                                        value={horseName}
                                        aria-required={true}
                                        onIonChange={(e) => {
                                            setError("");
                                            setHorseName(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>}
                            {membershipType.applicationFields?.horseHeight && <IonCol sizeXs="12" sizeMd="6">
                                <HorseHeightInput height={horseHeight} isRequired={true} onChange={handleHeightChange} />
                            </IonCol>}
                            {membershipType.applicationFields?.horseOwnerName && <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                        Owner's Name
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput
                                        id="membership-app-basicInfo-horse-owner-name"
                                        type="text"
                                        value={horseOwnerName}
                                        aria-required={true}
                                        onIonChange={(e) => {
                                            setError("");
                                            setHorseOwnerName(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>}
                            {membershipType.applicationFields?.dateOfBirth && <IonCol sizeXs="12" sizeMd="6">
                                <IonItem color="white">
                                    <IonLabel position="stacked">
                                        Birth Date
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput 
                                        id="membership-app-basicInfo-horse-birth-date"
                                        type="date"
                                        value={birthDate}
                                        aria-required={true}
                                        onIonChange={e => {
                                            setError("");
                                            setBirthDate(e.detail.value!)
                                        }}
                                    />
                                </IonItem>
                            </IonCol>}
                        </IonRow>}
                        {/* Group Membership - collect all names and birthdays */}
                        {((groupMembers && groupMembers.length > 0) && (
                            <>
                                {groupMembers.map((groupMember: (GroupMember | null), i: number) => {
                                    return (
                                        <>
                                            <IonRow key={i}>
                                                <IonCol sizeXs="12" sizeMd="4">
                                                    <IonItem color="white">
                                                        <IonLabel position="stacked">
                                                            {organization?.id === "ddc2fd3c-0bde-4b39-bbbb-5e00d2a35ba0" ? "Your" : ("Member #" + (i+1))} Full Name
                                                        </IonLabel>
                                                        <IonInput
                                                            class="membership-app-basicInfo-groupMember-fullName"
                                                            type="text"
                                                            value={groupMember?.name || ""}
                                                            aria-required={true}
                                                            onIonChange={(e) => {
                                                                setError("");
                                                                handleGroupMemberNameChange(i, e.detail.value!);
                                                            }}
                                                        />
                                                    </IonItem>
                                                </IonCol>
                                                <IonCol sizeXs="12" sizeMd="4">
                                                    <IonItem color="white">
                                                        <IonLabel position="stacked">
                                                            {organization?.id === "ddc2fd3c-0bde-4b39-bbbb-5e00d2a35ba0" ? "Your" : ("Member #" + (i+1))} Birth Date
                                                        </IonLabel>
                                                        <IonInput 
                                                            class="membership-app-basicInfo-groupMember-birthDate"
                                                            type="date"
                                                            value={groupMember?.birthDate || ""}
                                                            aria-required={true}
                                                            onIonChange={e => {
                                                                handleGroupMemberBirthDateChange(i, e.detail.value!)
                                                            }}
                                                        />
                                                    </IonItem>
                                                </IonCol>
                                                {membershipType &&
                                                    membershipType.applicationFields?.backNumber && (
                                                        <IonCol sizeXs="12" sizeMd="4">
                                                            <IonItem color="white">
                                                                <IonLabel position="stacked">
                                                                    Member #{i+1} Back Number(s)
                                                                </IonLabel>
                                                                <IonInput 
                                                                    type="text"
                                                                    value={groupMember?.backNumber ? groupMember.backNumber : ""}
                                                                    aria-required={true}
                                                                    disabled={true}
                                                                />
                                                            </IonItem>
                                                        </IonCol>
                                                )}
                                            </IonRow>
                                        </>
                                    )
                                })}
                                {membershipType && 
                                    membershipType.category === MembershipTypeCategory.GROUP && 
                                    membershipType.applicationFields?.backNumber && (
                                    <p className="ion-text-wrap description">
                                        (You cannot edit back numbers. The admin will handle this. 
                                        This will show at most one back number - the admin will add any additional back numbers later.)
                                    </p>
                                )}
                            </>
                        ))}
                        {membershipType && membershipType.category === MembershipTypeCategory.GROUP &&
                            membershipType && membershipType.applicationFields?.contact && <IonRow className="mt-3 mb-0">
                            <IonCol className="ion-text-center">
                                <hr />
                                <h4>{groupOrTeam} Contact Info</h4>
                            </IonCol>
                        </IonRow>}
                        {membershipType && membershipType.category !== MembershipTypeCategory.GROUP && 
                            membershipType.category !== MembershipTypeCategory.HORSE && <IonRow className="mt-3 mb-0">
                            <IonCol className="ion-text-center">
                                <hr />
                                <h4>Membership Contact Info</h4>
                            </IonCol>
                        </IonRow>}
                        {membershipType && membershipType.category === MembershipTypeCategory.HORSE &&
                            membershipType.applicationFields?.contact && <IonRow className="mt-3 mb-0">
                            <IonCol className="ion-text-center">
                                <hr />
                                <h4>Horse Contact Info</h4>
                            </IonCol>
                        </IonRow>}
                        <IonRow className="mb-3">
                            {membershipType &&
                                membershipType.applicationFields?.contact && (
                                    <IonCol sizeXs="12" sizeMd="12">
                                        <IonItem color="white">
                                            <IonLabel
                                                position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                                Email Address{" "}
                                                <RequiredInputIndicator />
                                            </IonLabel>
                                            <IonInput
                                                id="membership-app-basicInfo-email"
                                                type="email"
                                                required={true}
                                                aria-required={true}
                                                value={email}
                                                onIonChange={(e) => {
                                                    setError("");
                                                    setEmail(e.detail.value!);
                                                }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                )}
                        </IonRow>
                        <IonRow className="mb-3">
                            {membershipType &&
                                membershipType.applicationFields?.contact && (
                                    <>
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <PhoneNumberTypeSelect
                                                id="membership-app-phone-number"
                                                isRequired={true}
                                                selectedType={phoneType}
                                                onChange={handlePhoneNumberTypeSelectInput}
                                            />
                                        </IonCol>
                                        <IonCol sizeXs="12" sizeMd="6">
                                            <IonLabel className="pl-3 text-darker" position="stacked">
                                                Phone Number{" "}
                                                <RequiredInputIndicator />
                                            </IonLabel>
                                            <PhoneInput
                                                inputProps={{
                                                    name: "phone",
                                                    required: true,
                                                    autoFocus: true,
                                                    id:"membership-app-basicInfo-phone-number"
                                                }}
                                                placeholder="Enter phone number"
                                                country={"us"}
                                                enableSearch
                                                enableAreaCodes={false}
                                                inputStyle={{
                                                    width: "100%",
                                                    height: "auto",
                                                }}
                                                value={phoneNumber}
                                                onChange={(phoneNumber) => {
                                                    setError("");
                                                    setPhoneNumber(phoneNumber);
                                                }}
                                            />
                                        </IonCol>
                                    </>
                                )}
                        </IonRow>
                        {membershipType &&
                            membershipType.applicationFields?.contact && (
                                <AddressFormGroup
                                    id="membership-app-basicInfo"
                                    isRequired={true}
                                    isTextDarker={true}
                                    onChange={handleAddressInputChange}
                                    address={address}
                                    setIsDisabled={setIsDisabled}
                                />
                            )}

                        {membershipType && membershipType.category === MembershipTypeCategory.GROUP && membershipType.applicationFields?.groupLeader && <IonRow className="mt-3 mb-0">
                            <IonCol className="ion-text-center">
                                <h4>
                                    {organization?.id === "e05fc919-2793-4ead-acef-3f6efb584b67" ?
                                        `Head Coach`
                                        :
                                        `${groupOrTeam} Leader Info`
                                    }
                                </h4>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="12">
                                <IonItem color="white">
                                    <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                        {organization?.id === "e05fc919-2793-4ead-acef-3f6efb584b67" ?
                                            `Head Coach Name`
                                            :
                                            `${groupOrTeam} Leader Name`
                                        }
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput
                                        id="group-team-leader-name"
                                        type="text"
                                        value={leaderName}
                                        aria-required={true}
                                        onIonChange={(e) => {
                                            setError("");
                                            setLeaderName(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>}

                        {membershipType && membershipType.category === MembershipTypeCategory.GROUP &&
                            membershipType && membershipType.applicationFields?.groupLeaderContactInfo && <IonRow className="mt-3 mb-0">
                            <IonCol className="ion-text-center">
                                <hr />
                                <h4>{groupOrTeam} Leader Contact Info</h4>
                            </IonCol>
                        </IonRow>}
                        <IonRow className="mb-3">
                            {membershipType && membershipType.applicationFields?.groupLeaderContactInfo && (
                                <IonCol sizeXs="12" sizeMd="12">
                                    <IonItem color="white">
                                        <IonLabel
                                            position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                            Email Address{" "}
                                            <RequiredInputIndicator />
                                        </IonLabel>
                                        <IonInput
                                            id="membership-app-leader-contact-email"
                                            type="email"
                                            required={true}
                                            aria-required={true}
                                            value={leaderEmail}
                                            onIonChange={(e) => {
                                                setError("");
                                                setLeaderEmail(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                            )}
                        </IonRow>
                        <IonRow className="mb-3">
                            {membershipType && membershipType.applicationFields?.groupLeaderContactInfo && (
                                <>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <PhoneNumberTypeSelect
                                            id="membership-app-leader-phone-number"
                                            isRequired={true}
                                            selectedType={leaderPhoneType}
                                            onChange={handleLeaderPhoneNumberTypeSelectInput}
                                        />
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <IonLabel className="pl-3 text-darker" position="stacked">
                                            Phone Number{" "}
                                            <RequiredInputIndicator />
                                        </IonLabel>
                                        <PhoneInput
                                            inputProps={{
                                                name: "phone",
                                                required: true,
                                                autoFocus: true,
                                                id:"membership-app-leader-phone-number"
                                            }}
                                            placeholder="Enter phone number"
                                            country={"us"}
                                            enableSearch
                                            enableAreaCodes={false}
                                            inputStyle={{
                                                width: "100%",
                                                height: "auto",
                                            }}
                                            value={leaderPhoneNumber}
                                            onChange={(phoneNumber) => {
                                                setError("");
                                                setLeaderPhoneNumber(phoneNumber);
                                            }}
                                        />
                                    </IonCol>
                                </>
                            )}
                        </IonRow>
                        {membershipType && membershipType.applicationFields?.groupLeaderContactInfo && (
                            <AddressFormGroup
                                id="membership-app-basicInfo-group-leader"
                                isRequired={true}
                                isTextDarker={true}
                                onChange={handleLeaderAddressInputChange}
                                address={leaderAddress}
                                setIsDisabled={setIsDisabled}
                            />
                        )}

                        {/* Header for Additional Info */}
                        {membershipType && (membershipType.applicationFields?.barnName) && (
                            <IonRow className="mt-3 mb-0">
                                <IonCol className="ion-text-center">
                                    <hr />
                                    <h4>Additional Info</h4>
                                </IonCol>
                            </IonRow>
                        )}
                        {/* Header for Coggins Info */}
                        {membershipType && membershipType.category === MembershipTypeCategory.HORSE && (<IonRow>
                            <IonCol className="ion-text-center">
                                <hr />
                                <h4>Coggins Info</h4>
                            </IonCol>
                        </IonRow>)}
                        <IonRow>
                            {membershipType.applicationFields?.gradeLevel && (
                                <IonCol sizeXs="12" sizeMd="6" className="mb-3">
                                    <GradeLevel
                                        onSelect={setGradeLevel}
                                        selectedValue={gradeLevel}
                                    />
                                </IonCol>
                            )}
                            {membershipType.applicationFields?.barnName && membershipType.category !== MembershipTypeCategory.HORSE && (
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonLabel className="pl-3 text-darker" position="stacked">
                                        Barn{" "}
                                    </IonLabel>
                                    <SelectFromAllBarns
                                        barn={barn}
                                        selectedValue={barn?.id}
                                        isHeightRequired={true}
                                        height={"45px"}
                                        onSelect={(barn: Barn) =>
                                            handleSelectBarn(barn)
                                        }
                                    />
                                </IonCol>)}
                        </IonRow>
                        {membershipType && membershipType.category === MembershipTypeCategory.HORSE &&
                            membershipType.applicationFields?.cogginsInfo && (
                            <IonRow>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                                            Coggins Accession Number
                                            <RequiredInputIndicator />
                                        </IonLabel>
                                        <IonInput 
                                            id="membership-app-coggins-accession-number"
                                            type="text"
                                            value={cogginsNumber}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setError("");
                                                setCogginsNumber(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <IonItem color="white">
                                        <IonLabel position="stacked">
                                            Coggins Test Date
                                            <RequiredInputIndicator />
                                        </IonLabel>
                                        <IonInput 
                                            id="membership-app-coggins-test-date"
                                            type="date"
                                            value={cogginsDate}
                                            aria-required={true}
                                            onIonChange={e => {
                                                setError("");
                                                setCogginsDate(e.detail.value!);
                                            }}
                                        />
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs="12" sizeMd="6">
                                    <SelectState
                                        id="membership-app-state-of-test"
                                        isRequired={true}
                                        label="State of Test"
                                        selectedValue={cogginsState}
                                        onInputChange={handleCogginsState}
                                    />
                                </IonCol>
                            </IonRow>
                        )}
                        {membershipType && membershipType.applicationFields?.cogginsUpload && (<IonRow className="ion-justify-content-center"> 
                            <IonCol sizeXs="12" sizeMd="6">
                                <CogginsDocumentForm isRequired={true} horse={selectedHorse} onSubmit={handleSelectedDocument} />
                            </IonCol>
                        </IonRow>)}
                        <hr/>
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                <IonButton
                                    id="mt-basic-info-save-btn"
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    {membership ? "Edit" : "Save"}
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </form>
                }
            </IonCard>
        </React.Fragment>
    );
};

export default MembershipApplicationBasicInfo;
