import {
    IonButton,
    IonButtons,
    IonContent,
    IonIcon,
    IonModal,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useState} from "react";
import PointTableForm from "./PointTableEditForm";
import { close } from "ionicons/icons";
import { PointTable } from "../../models";
import AddPointTableButton from "./AddPointTableButton";

interface _Props {
    organizationId: string
    handleNewPointTable: Function
}

const AddPointTableCard: React.FC<_Props> = ({organizationId, handleNewPointTable}) => {
    const [showModal, setShowModal] = useState(false);

    const handleSubmit = (pointTable: PointTable) => {
        handleNewPointTable(pointTable);
        setShowModal(false);
    }

    return (
        <>
            <AddPointTableButton onSubmit={() => setShowModal(true)} />
            <IonModal backdropDismiss={false} isOpen={showModal} id="pointTableModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Point Table
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closePointTableModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <PointTableForm organizationId={organizationId} onSubmit={handleSubmit} />
                </IonContent>
            </IonModal>
        </>
    );
};

export default AddPointTableCard;
