import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateFeedbackInput, CreateFeedbackMutation, DeleteFeedbackInput, DeleteFeedbackMutation, GetFeedbackQuery, ListFeedbacksQuery, UpdateFeedbackInput, UpdateFeedbackMutation } from "../../API";
import moment from "moment";
import { Feedback } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: feedback. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created feedback.
*/
export async function createFeedback(input: CreateFeedbackInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Feedback", "No input", "Create Feedback received no input.");
   try {
        const fullInput: CreateFeedbackInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createFeedback, { input: fullInput }))) as GraphQLResult<CreateFeedbackMutation>;
       else result = (await API.graphql({
           query: mutations.createFeedback,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateFeedbackMutation>;
       const feedback = result.data?.createFeedback;
       return formatResult(true, "Feedback", feedback, "Successfully created the feedback.");
   } catch (error: any) {
       return formatResult(false, "Feedback", error, "Error creating record in the database for type: feedback");
   }
}

/**
* Handle updating a new record in the database for type: feedback. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated feedback.
*/
export async function updateFeedback(input: UpdateFeedbackInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Feedback", "No input", "Update Feedback received no input.");
   try {
        const fullInput: UpdateFeedbackInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateFeedback, { input: fullInput }))) as GraphQLResult<UpdateFeedbackMutation>;
       else result = (await API.graphql({
           query: mutations.updateFeedback,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateFeedbackMutation>;
       const feedback = result.data?.updateFeedback;
       return formatResult(true, "Feedback", feedback, "Successfully updated the feedback.");
   } catch (error: any) {
       return formatResult(false, "Feedback", error, "Error updating record in the database for type: feedback");
   }
}

/**
* Handle deleting a new record in the database for type: feedback. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted feedback.
*/
export async function deleteFeedback(input: DeleteFeedbackInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Feedback", "No input", "Delete Feedback received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteFeedback, { input: input }))) as GraphQLResult<DeleteFeedbackMutation>;
       else result = (await API.graphql({
           query: mutations.deleteFeedback,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteFeedbackMutation>;
       const feedback = result.data?.deleteFeedback;
       return formatResult(true, "Feedback", feedback, "Successfully deleted the feedback.");
   } catch (error: any) {
       return formatResult(false, "Feedback", error, "Error deleting record in the database for type: feedback");
   }
}

/**
* Get all records in the database for type: feedback. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the feedback object
*/
export async function getAllFeedbacks(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listFeedbacks,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListFeedbacksQuery>;

        let items = result.data?.listFeedbacks?.items as Feedback[];
        let nextToken = result.data?.listFeedbacks?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listFeedbacks,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListFeedbacksQuery>;

            const nextItems = nextResult.data?.listFeedbacks?.items as Feedback[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listFeedbacks?.nextToken;
        }

        return formatResult(true, "Feedback", items, "Successfully got the Feedbacks.");
    } catch (error: any) {
        return formatResult(false, "Feedback", error, "Error reading record in the database for type: feedbacks");
    }
}

/**
* Read a specific record in the database for type: feedback. 
* 
* @param {string}  id                  The feedback id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the feedback object
*/
export async function getFeedbackById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getFeedback,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetFeedbackQuery>;
       const feedback = result.data?.getFeedback;
       return formatResult(true, "Feedback", feedback, "Successfully got the feedback.");
   } catch (error: any) {
       return formatResult(false, "Feedback", error, "Error reading record in the database for type: feedback");
   }
}

/**
* Get all records in the database for type: feedback. 
* 
* @param {string}  personId            The person id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the feedback object
*/
export async function getFeedbacksByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listFeedbacks,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListFeedbacksQuery>;

        let items = result.data?.listFeedbacks?.items as Feedback[];
        let nextToken = result.data?.listFeedbacks?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listFeedbacks,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListFeedbacksQuery>;

            const nextItems = nextResult.data?.listFeedbacks?.items as Feedback[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listFeedbacks?.nextToken;
        }

        return formatResult(true, "Feedback", items, "Successfully got the Feedbacks.");
    } catch (error: any) {
        return formatResult(false, "Feedback", error, "Error reading record in the database for type: feedbacks");
    }
}