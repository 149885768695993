import { IonButton, IonCol, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';
import { PersonContext } from "../../context/PersonContext";
import { Organization, TaxRate } from "../../models";
import { getTaxRatesByOrganizationId } from "../../utilities/taxRate/TaxRate";
import ErrorAlert from "../Errors/ErrorAlert";
import EditTaxRateModal from "./EditTaxRateModal";

interface _Props {
    onSelect: Function
    selectedValue?: (string | null)
    organizationId?: (string | null)
}

interface formattedOption {
    label: string
    value: string
    taxRate: TaxRate
}

let subscription: any;

const SelectCreateTaxRate: React.FC<_Props> = ({organizationId, onSelect, selectedValue}) => {
    const user = useContext(PersonContext);

    const [formattedTaxRates, setFormattedTaxRates] = useState<formattedOption[] | null | undefined>();
    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState("");

    async function getTaxRates() {
        // taxRates are saved with organizationId id
        let taxRates: TaxRate[] = [];

        if (organizationId) {
            const queryResultByOrganization = await getTaxRatesByOrganizationId(organizationId);
            if (queryResultByOrganization.isSuccess) {
                taxRates = taxRates.concat(queryResultByOrganization.result);
            }
        }
        
        formatTaxRates(taxRates);
    }

    const formatTaxRates = (taxRates: TaxRate[]) => {
        let formattedTaxRates = [];
        for (var i = 0; i < taxRates.length; i++) {
            const name = taxRates[i].name || "";
            const amount = taxRates[i].percentage || 0;
            const label = name + " (" + amount.toString() + "%)";
            let object = {
                value: taxRates[i].id,
                label: label,
                taxRate: taxRates[i]
            };
            formattedTaxRates.push(object);
        }
        setFormattedTaxRates(formattedTaxRates);
        if (selectedValue) findValueInList(selectedValue, formattedTaxRates);
    }

    const findValueInList = (value: string, taxRateList?: formattedOption[]) => {
        let optionArray = formattedTaxRates || taxRateList;
        if (optionArray) {
            let result: formattedOption;
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    result = currentOption;
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        getTaxRates();
    }, [organizationId]);

    useEffect(() => {
        async function setValue() {
            if (selectedValue) {
                if (!formattedTaxRates) {
                    await getTaxRates();
                } else {
                    findValueInList(selectedValue);
                }
                
            } else {
                setCurrentValue(undefined);
            }
        }
        setValue();
    }, [selectedValue]);

    const handleOnChange = async (event: any) => {
        if (event && event.taxRate) {
            setCurrentValue(event);
            onSelect(event.taxRate);
        } else {
            setCurrentValue(undefined);
            setShowModal(true);
        }
    }

    const handleOnCreateTaxRate = async (taxRate: TaxRate) => {
        onSelect(taxRate);
        setShowModal(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            <IonRow>
                <IonCol size="12">
                    <CreatableSelect
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        defaultValue={currentValue}
                        value={currentValue}
                        menuPortalTarget={document.body}
                        menuPlacement={"auto"}
                        isClearable={true}
                        isSearchable
                        options={formattedTaxRates!}
                        onChange={handleOnChange}
                    />
                </IonCol>
                {/* <IonCol size="2">
                    <IonButton color="primary" size="small" disabled={!(!!currentValue)} onClick={() => setShowModal(true)}>
                        Edit
                    </IonButton>
                </IonCol> */}
            </IonRow>
            <EditTaxRateModal organizationId={organizationId} show={showModal} taxRate={currentValue ? currentValue.taxRate : undefined} handleEdit={handleOnCreateTaxRate}/>
        </>
    );
};

export default SelectCreateTaxRate;
