import { EventAuditor } from "../../models";

/**
 * Sorts the EventAuditors first by person name and then by phone number
 * 
 * @param data 
 * @returns scheduleItems sorted by name and phone number
 */
export function sortEventAuditorsByName(data: EventAuditor[]) {
    try {
        let result: EventAuditor[] = [];
        if (data && data.length) {
            result = data.sort((a: any, b: any) => a.name.localeCompare(b.name) || a.phoneNumber.localeCompare(b.phoneNumber));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: EventAuditor");
        console.error(error);
        return null;
    }
};
