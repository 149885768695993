import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { EventAuditor, OrganizationAuditor } from "../../models";

interface _Props {
    onSelect: Function,
    eventAuditors?: EventAuditor[]
    organizationAuditors?: OrganizationAuditor[]
}

interface formattedOption {
    label: string
    value: string
    auditor: (EventAuditor | OrganizationAuditor)
}

const SelectEventAuditors: React.FC<_Props> = ({eventAuditors, organizationAuditors, onSelect}) => {

    const [formattedEventAuditors, setFormattedEventAuditors] = useState<formattedOption[] | null | undefined>();
    const [selectedformattedEventAuditors, setSelectedFormattedEventAuditors] = useState<formattedOption[] | null | undefined>();

    const formatEventAuditors = (eventAuditors: EventAuditor[]) => {
        let formattedEventAuditors = [];
        for (var i = 0; i < eventAuditors.length; i++) {
            const label = eventAuditors[i].phoneNumber + " - " + eventAuditors[i].name;
            let object = {
                value: eventAuditors[i].id,
                label: label,
                auditor: eventAuditors[i]
            };
            formattedEventAuditors.push(object);
        }
        setFormattedEventAuditors(formattedEventAuditors);
    }

    useEffect(() => {
        if (eventAuditors) formatEventAuditors(eventAuditors);
    }, [eventAuditors]);

    const formatOrganizationAuditors = (eventAuditors: OrganizationAuditor[]) => {
        let formattedOrganizationAuditors = [];
        for (var i = 0; i < eventAuditors.length; i++) {
            const label = eventAuditors[i].phoneNumber + " - " + eventAuditors[i].name;
            let object = {
                value: eventAuditors[i].id,
                label: label,
                auditor: eventAuditors[i]
            };
            formattedOrganizationAuditors.push(object);
        }
        setFormattedEventAuditors(formattedOrganizationAuditors);
    }

    useEffect(() => {
        if (organizationAuditors) formatOrganizationAuditors(organizationAuditors);
    }, [organizationAuditors]);


    const handleOnChange = (event: any) => {
        if (event && event.length) {
            let result: EventAuditor[] = [];
            for (var i = 0; i < event.length; i++) {
                result.push(event[i].auditor);
            }
            onSelect(result);
        }
    }

    return (
        <>
            <Select
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                menuPortalTarget={document.body}
                isMulti
                isClearable
                options={formattedEventAuditors!}
                onChange={handleOnChange}
            />
        </>
    );
};

export default SelectEventAuditors;
