import {
    IonContent,
    IonLabel,
    IonPage,
    IonSegment,
    IonSegmentButton,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import Header from "../../../../../components/Headers/Header";
import { OrganizationMembershipType, Organization, Membership, MembershipTypeCategory } from "../../../../../models";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import Spinner from "../../../../../components/Spinners/Spinner";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import { getOrganizationMembershipTypeById } from "../../../../../utilities/organizationMembershipType/OrganizationMembershipType";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import EditMembershipApplicationGroupInfo from "../../../../../components/Applications/Edit/EditMembershipApplicationGroupInfo";
import EditMembershipApplicationBasicInfo from "../../../../../components/Applications/Edit/EditMembershipApplicationBasicInfo";
import EditMembershipApplicationContact from "../../../../../components/Applications/Edit/EditMembershipApplicationContact";
import EditMembershipApplicationUploads from "../../../../../components/Applications/Edit/EditMembershipApplicationUploads";
import EditMembershipApplicationWaivers from "../../../../../components/Applications/Edit/EditMembershipApplicationWaivers";
import EditMembershipApplicationPayment from "../../../../../components/Applications/Edit/EditMembershipApplicationPayment";
import { getMembershipById } from "../../../../../utilities/membership/Membership";
import { PersonContext } from "../../../../../context/PersonContext";
import CONSTANT from "../../../../../constant/constant";

interface _Props extends RouteComponentProps<{
    id: string;
}> {}

const EventOrganizationMemberEditPage: React.FC<_Props> = ({ match }) => {
    const user = useContext(PersonContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");
    const [membershipType, setMembershipType] = useState<OrganizationMembershipType>();
    const [membership, setMembership] = useState<Membership>();
    const [organization, setOrganization] = useState<Organization>();
    const [segmentValue, setSegmentValue] = useState<string>("");
    const [isAELOrganization, setIsAELOrganization] = useState(false);

    useEffect(() => {
        if (organization && organization.id === "e05fc919-2793-4ead-acef-3f6efb584b67") setIsAELOrganization(true);
    }, [organization]);

    useEffect(() => {
        async function getMembership() {
            try {
                setIsLoading(true);
                const queryResult = await getMembershipById(match.params.id);
                if (queryResult.isSuccess) {
                    const membership = queryResult.result;
                    setMembership(membership);
                    if(membership.organizationMembershipType?.category === MembershipTypeCategory.GROUP) {
                        setSegmentValue(CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.GROUP);
                    } else {
                        setSegmentValue(CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.BASIC);
                    }
                } else {
                    setError(queryResult.message);
                }
                setIsLoading(false);
            } catch (error: any) {
                setError("Sorry, a problem occurred. Please go back and try again.");
                setIsLoading(false);
            }
        }
        setSegmentValue(CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.BASIC);
        getMembership();
    }, [match, match.params.id]);


    useEffect(() => {
        getMembershipType();
        getOrganization();
    }, [membership]);

    async function getMembershipType() {
        try {
            if (membership && membership.organizationMembershipTypeId) {
                setIsLoading(true);
                const queryResult = await getOrganizationMembershipTypeById(membership.organizationMembershipTypeId);
                if (queryResult.isSuccess) {
                    setMembershipType(queryResult.result);
                } else {
                    setError(queryResult.message);
                }
                setIsLoading(false);
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }

    async function getOrganization() {
        try {
            if (membership && membership.organizationId) {
                setIsLoading(true);
                const queryResult = await getOrganizationById(membership.organizationId);
                if (queryResult.isSuccess) {
                    setOrganization(queryResult.result);
                } else {
                    setError(queryResult.message);
                }
                setIsLoading(false);
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }

    return (
        <IonPage className="bg-light" id="membership-application-edit-page">
            <Header />
            <IonContent>
                <PageTitle title={`${(organization?.nickname || organization?.name) ?? "Organization"}`}/>
                {error && <ErrorAlert width="12" error={error} />}
                {isLoading && <Spinner />}
                {membership ? (
                    <>
                        <IonSegment
                            mode="md"
                            scrollable
                            value={segmentValue}
                            onIonChange={(e) => {
                                setSegmentValue(e.detail.value!);
                            }}
                        >
                            {membershipType?.category === MembershipTypeCategory.GROUP &&
                                <IonSegmentButton value={CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.GROUP} id="membership-application-edit-groupInfo-btn">
                                    <IonLabel>
                                        {isAELOrganization ? "Team" : "Group"} Info
                                    </IonLabel>
                                </IonSegmentButton>
                            }
                            {(!isAELOrganization || membershipType?.category !== MembershipTypeCategory.GROUP) && (
                                <>
                                    <IonSegmentButton value={CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.BASIC} id="membership-application-edit-basicInfo-btn">
                                        <IonLabel>
                                            Basic Info
                                        </IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton value={CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.CONTACT} id="membership-application-edit-contact-btn">
                                        <IonLabel>
                                            Contact
                                        </IonLabel>
                                    </IonSegmentButton>
                                </>
                            )}
                            <IonSegmentButton value={CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.UPLOADS} id="membership-application-edit-uploads-btn">
                                <IonLabel>
                                    Uploads
                                </IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value={CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.WAIVERS} id="membership-application-edit-waivers-btn">
                                <IonLabel>
                                    Waivers
                                </IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value={CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.PAYMENT} id="membership-application-edit-payment-btn">
                                <IonLabel>
                                    Payment
                                </IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                        {segmentValue === CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.GROUP && (
                            <EditMembershipApplicationGroupInfo
                                membership={membership}
                                membershipType={membershipType}
                                organization={organization}
                                setMembership={setMembership}
                                personId={user.id}
                            />
                        )}
                        {segmentValue === CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.BASIC && (
                            <EditMembershipApplicationBasicInfo
                                membership={membership}
                                membershipType={membershipType}
                                organization={organization}
                                setMembership={setMembership}
                                setMembershipType={setMembershipType}
                            />
                        )}
                        {segmentValue === CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.CONTACT && (
                            <EditMembershipApplicationContact
                                membership={membership}
                                membershipType={membershipType}
                                setMembership={setMembership}
                            />
                        )}
                        {segmentValue === CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.UPLOADS && (
                            <EditMembershipApplicationUploads
                                membershipType={membershipType}
                                membership={membership}
                                setMembership={setMembership}
                            />
                        )}
                        {segmentValue === CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.WAIVERS && (
                            <EditMembershipApplicationWaivers
                                membershipType={membershipType}
                                membership={membership}
                            />
                        )}
                        {segmentValue === CONSTANT.MEMBERSHIP.APPLICATION_EDIT_STAGES.PAYMENT && (
                            <EditMembershipApplicationPayment
                                membershipType={membershipType}
                                membership={membership}
                                setMembership={setMembership}
                            />
                        )}
                    </>
                ) : (
                    <p>No membership was found. Please go back and try again.</p>
                )}
            </IonContent>
        </IonPage>
    );
}

export default EventOrganizationMemberEditPage;
