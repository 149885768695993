import React from "react";
import { Horse } from "../../models";
import AddHorseButton from "./AddHorseButton";
import { useHistory } from "react-router";

interface _Props {
    horse?: Horse
    handleNewHorse: Function
}

const AddHorseCard: React.FC<_Props> = () => {
    const history = useHistory();

    const handleSubmit = () => {
        let path = "/index/rider/horse/create/";
        history.push(path);
    }

    return (
        <>
            <AddHorseButton onSubmit={handleSubmit} />
        </>
    );
};

export default AddHorseCard;
