import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventOfficialInput, CreateEventOfficialMutation, DeleteEventOfficialInput, DeleteEventOfficialMutation, GetEventOfficialQuery, ListEventOfficialsQuery, UpdateEventOfficialInput, UpdateEventOfficialMutation } from "../../API";
import { EventOfficial } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventOfficial. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventOfficial.
*/
export async function createEventOfficial(input: CreateEventOfficialInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "EventOfficial", "No input", "Create EventOfficial received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventOfficial, { input: input }))) as GraphQLResult<CreateEventOfficialMutation>;
        else result = (await API.graphql({
            query: mutations.createEventOfficial,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<CreateEventOfficialMutation>;
        const eventOfficial = result.data?.createEventOfficial;
        return formatResult(true, "EventOfficial", eventOfficial, "Successfully created the eventOfficial.");
    } catch (error: any) {
        return formatResult(false, "EventOfficial", error, "Error creating record in the database for type: eventOfficial");
    }
}

/**
* Handle updating a new record in the database for type: eventOfficial. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventOfficial.
*/
export async function updateEventOfficial(input: UpdateEventOfficialInput, authMode?: GRAPHQL_AUTH_MODE) {
    if (!input) return formatResult(false, "EventOfficial", "No input", "Update EventOfficial received no input.");
    try {
        let result;
        if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventOfficial, { input: input }))) as GraphQLResult<UpdateEventOfficialMutation>;
        else result = (await API.graphql({
            query: mutations.updateEventOfficial,
            variables: {
                input: input
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<UpdateEventOfficialMutation>;
        const eventOfficial = result.data?.updateEventOfficial;
        return formatResult(true, "EventOfficial", eventOfficial, "Successfully updated the eventOfficial.");
    } catch (error: any) {
        return formatResult(false, "EventOfficial", error, "Error updating record in the database for type: eventOfficial");
    }
}

/**
* Handle deleting a new record in the database for type: eventOfficial. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventOfficial.
*/
export async function deleteEventOfficial(input: DeleteEventOfficialInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventOfficial", "No input", "Delete EventOfficial received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventOfficial, { input: input }))) as GraphQLResult<DeleteEventOfficialMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventOfficial,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventOfficialMutation>;
       const eventOfficial = result.data?.deleteEventOfficial;
       return formatResult(true, "EventOfficial", eventOfficial, "Successfully deleted the eventOfficial.");
   } catch (error: any) {
       return formatResult(false, "EventOfficial", error, "Error deleting record in the database for type: eventOfficial");
   }
}

/**
* Get all records in the database for type: eventOfficial. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventOfficial object
*/
export async function getAllEventOfficials(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventOfficials,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventOfficialsQuery>;

        let items = result.data?.listEventOfficials?.items as EventOfficial[];
        let nextToken = result.data?.listEventOfficials?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventOfficials,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventOfficialsQuery>;

            const nextItems = nextResult.data?.listEventOfficials?.items as EventOfficial[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventOfficials?.nextToken;
        }

        return formatResult(true, "EventOfficial", items, "Successfully got the EventOfficials.");
    } catch (error: any) {
        return formatResult(false, "EventOfficial", error, "Error reading record in the database for type: eventOfficials");
    }
}

/**
* Read a specific record in the database for type: eventOfficial. 
* 
* @param {string}  id                  The eventOfficial id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventOfficial object
*/
export async function getEventOfficialById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventOfficial,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventOfficialQuery>;
       const eventOfficial = result.data?.getEventOfficial;
       return formatResult(true, "EventOfficial", eventOfficial, "Successfully got the eventOfficial.");
   } catch (error: any) {
       return formatResult(false, "EventOfficial", error, "Error reading record in the database for type: eventOfficial");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventOfficial. 
* 
* @param {string}  name                The state name of the eventOfficial.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventOfficial object
*/
export async function getEventOfficialByName(name: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {name: {eq: name}};
        const result = (await API.graphql({
            query: queries.listEventOfficials,
            variables: {
                limit: 10,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventOfficialsQuery>;

        let items = result.data?.listEventOfficials?.items as EventOfficial[];
        let nextToken = result.data?.listEventOfficials?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listEventOfficials,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventOfficialsQuery>;

            const nextItems = nextResult.data?.listEventOfficials?.items as EventOfficial[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventOfficials?.nextToken;
        }

        const eventOfficials = items;
        if (eventOfficials && eventOfficials[0]) return formatResult(true, "EventOfficial", eventOfficials[0], "Successfully got the eventOfficial.");
        else return formatResult(false, "EventOfficial", null, "Could not find the eventOfficial.");
    } catch (error: any) {
        return formatResult(false, "EventOfficial", error, "Error reading record in the database for type: eventOfficial");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventOfficial. 
* 
* @param {string}  eventId             The event id
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventOfficial object
*/
export async function getEventOfficialsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventOfficials,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventOfficialsQuery>;

        let items = result.data?.listEventOfficials?.items as EventOfficial[];
        let nextToken = result.data?.listEventOfficials?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventOfficials,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventOfficialsQuery>;

            const nextItems = nextResult.data?.listEventOfficials?.items as EventOfficial[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventOfficials?.nextToken;
        }

        return formatResult(true, "EventOfficial", items, "Successfully got the EventOfficials.");
    } catch (error: any) {
        return formatResult(false, "EventOfficial", error, "Error reading record in the database for type: eventOfficials");
    }
}