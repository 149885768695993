import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateRingInput, CreateRingMutation, DeleteRingInput, DeleteRingMutation, GetRingQuery, ListRingsQuery, UpdateRingInput, UpdateRingMutation } from "../../API";
import moment from "moment";
import { Ring } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: ring. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created ring.
*/
export async function createRing(input: CreateRingInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Ring", "No input", "Create Ring received no input.");
   try {
        const fullInput: CreateRingInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createRing, { input: fullInput }))) as GraphQLResult<CreateRingMutation>;
       else result = (await API.graphql({
           query: mutations.createRing,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateRingMutation>;
       const ring = result.data?.createRing;
       return formatResult(true, "Ring", ring, "Successfully created the ring.");
   } catch (error: any) {
       return formatResult(false, "Ring", error, "Error creating record in the database for type: ring");
   }
}

/**
* Handle updating a new record in the database for type: ring. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated ring.
*/
export async function updateRing(input: UpdateRingInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Ring", "No input", "Update Ring received no input.");
   try {
        const fullInput: UpdateRingInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateRing, { input: fullInput }))) as GraphQLResult<UpdateRingMutation>;
       else result = (await API.graphql({
           query: mutations.updateRing,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateRingMutation>;
       const ring = result.data?.updateRing;
       return formatResult(true, "Ring", ring, "Successfully updated the ring.");
   } catch (error: any) {
       return formatResult(false, "Ring", error, "Error updating record in the database for type: ring");
   }
}

/**
* Handle deleting a new record in the database for type: ring. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted ring.
*/
export async function deleteRing(input: DeleteRingInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Ring", "No input", "Delete Ring received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteRing, { input: input }))) as GraphQLResult<DeleteRingMutation>;
       else result = (await API.graphql({
           query: mutations.deleteRing,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteRingMutation>;
       const ring = result.data?.deleteRing;
       return formatResult(true, "Ring", ring, "Successfully deleted the ring.");
   } catch (error: any) {
       return formatResult(false, "Ring", error, "Error deleting record in the database for type: ring");
   }
}

/**
* Get all records in the database for type: ring. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the ring object
*/
export async function getAllRings(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listRings,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListRingsQuery>;
        
        let items = result.data?.listRings?.items as Ring[];
        let nextToken = result.data?.listRings?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listRings,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListRingsQuery>;

            const nextItems = nextResult.data?.listRings?.items as Ring[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listRings?.nextToken;
        }

        return formatResult(true, "Ring", items, "Successfully got the Rings.");
    } catch (error: any) {
        return formatResult(false, "Ring", error, "Error reading record in the database for type: rings");
    }
}

/**
* Read a specific record in the database for type: ring. 
* 
* @param {string}  id                  The ring id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the ring object
*/
export async function getRingById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getRing,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetRingQuery>;
       const ring = result.data?.getRing;
       return formatResult(true, "Ring", ring, "Successfully got the ring.");
   } catch (error: any) {
       return formatResult(false, "Ring", error, "Error reading record in the database for type: ring");
   }
}

/**
* Get all records in the database that match the given criteria for type: ring. 
* 
* @param {string}  organizationId      The organization id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the ring object
*/
export async function getRingsByOrganizationId(organizationId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {organizationId: {eq: organizationId}};
        const result = (await API.graphql({
            query: queries.listRings,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListRingsQuery>;
        
        let items = result.data?.listRings?.items as Ring[];
        let nextToken = result.data?.listRings?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listRings,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListRingsQuery>;

            const nextItems = nextResult.data?.listRings?.items as Ring[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listRings?.nextToken;
        }

        return formatResult(true, "Ring", items, "Successfully got the Rings.");
    } catch (error: any) {
        return formatResult(false, "Ring", error, "Error reading record in the database for type: rings");
    }
}