import algoliasearch from "algoliasearch";
import { Event } from "../../models";
import { formatAddress } from "../address/FormatAddress";
import { getEventTypeDisplayName } from "./EventType";
import { formatDisciplinesForDisplay} from "./EventDiscipline";
import moment from "moment";
import { AlgoliaEvent } from "../../interfaces/AlgoliaEvent";

const appId: string = process.env.ALGOLIA_APP_ID|| "JWZGHNODNQ";
const apiKey: string = process.env.ALGOLIA_SEARCH_API_KEY || "65e1b535fea4b59b81c60dbb1d758b2a";
const searchClient = algoliasearch(appId, apiKey);

// const env = process.env.AWS_BRANCH;
const indexName = "prod_events";
const index = searchClient.initIndex(indexName);

export async function addEventToSearch(event: Event) {
    const algoliaObject: AlgoliaEvent = {
        objectID: event.id,
        name: event.name,
        nickname: event.nickname || "",
        startDate: moment(event.startDate).format("YYYY-MM-DD"),
        startDate_timestamp: event.startDate ? moment(event.startDate).unix() : 0,
        endDate: moment(event.endDate).format("YYYY-MM-DD"),
        endDate_timestamp: event.endDate ? moment(event.endDate).unix() : 0,
        location: event.address ? formatAddress(event.address) : "", 
        streetAddress1: event.address?.streetAddress1 ? event.address?.streetAddress1 : "",
        streetAddress2: event.address?.streetAddress2 ? event.address?.streetAddress2 : "",
        city: event.address?.city ? event.address?.city : "",
        provState: event.address?.provState ? event.address?.provState : "",
        zip: event.address?.zip ? event.address?.zip : "",
        country: event.address?.country ? event.address?.country : "", 
        type: event.type ? getEventTypeDisplayName(event.type) : "",
        disciplines: event.disciplines ? (formatDisciplinesForDisplay(event.disciplines) || "") : []
    };

    index.saveObject(algoliaObject).then(({ objectID }) => {
        console.log(objectID);
    });
}

export async function removeEventFromSearch(event: Event) {
    index.deleteObject(event.id).then(() => {
        console.log("removed event from search.");
    });
}