import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { EventEntry, Owner } from "../../../models";
import { getOwnerById } from "../../../utilities/owner/Owner";

interface _Props {
    entry: EventEntry
}

const DisplayEntryOwnerInfo: React.FC<_Props> = ({entry}) => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [owner, setOwner] = useState<Owner | null | undefined>();

    const getOwner = async (entry: EventEntry) => {
        const ownerId = entry.ownerId;
        if (ownerId) {
            const queryResult = await getOwnerById(ownerId);
            if (queryResult.isSuccess) {
                setOwner(queryResult.result);
            }
        }
    }

    useEffect(() => {
        if (entry) {
            setIsCollapsed(true);
            getOwner(entry);
        }
    }, [entry]);

    return (
        <>
            <IonCard color="white">
                <IonCardTitle>
                    <IonRow onClick={() => setIsCollapsed(!isCollapsed)}>
                        <IonCol>
                            {entry ? <IonRow><h2>Owner: {entry.ownerName}</h2></IonRow> : <h2>No Owner</h2> }
                        </IonCol>
                        <IonCol className="ion-text-right">
                            <p>{isCollapsed ? "Expand" : "Collapse"}</p>
                        </IonCol>
                    </IonRow>
                </IonCardTitle>
                {!isCollapsed && (
                    <IonCardContent>
                        {owner && (
                            <>
                                <IonRow>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        <p>Owner Name: {owner.name}</p>
                                    </IonCol>
                                    <IonCol sizeXs="12" sizeMd="6">
                                        {owner.location ? <p>Owner Location: {owner.location}</p> : ""}
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                    </IonCardContent>
                )}
            </IonCard>
        </>
    );
};

export default DisplayEntryOwnerInfo;