import React, { useEffect, useState } from "react";
import {eventDisciplines} from "../../utilities/events/EventDiscipline";
import BasicSelect, {formattedOption} from "../Select/BasicSelect";

interface _Props {
    menuPlacement?: (string | null)
    selectedValue?: (string | null)
    onSelect: Function
}

const SelectDiscipline: React.FC<_Props> = ({selectedValue, menuPlacement, onSelect}) => {

    const [selected, setSelected] = useState<string | null | undefined>();
    const [formattedDisciplines, setFormattedDisciplines] = useState<formattedOption[] | null | undefined>();

    useEffect(() => {
        formatDisciplines();
    }, []);

    useEffect(() => {
        if (selectedValue) setSelected(selectedValue)
    }, [selectedValue]);

    const formatDisciplines = () => {
        let formattedDisciplines = [];
        for (var i = 0; i < eventDisciplines.length; i++) {
            let object = {
                value: eventDisciplines[i].value,
                label: eventDisciplines[i].name,
                object: eventDisciplines[i]
            };
            formattedDisciplines.push(object);
        }
        setFormattedDisciplines(formattedDisciplines);
    }

    const handleOnChange = (event?: any) => {
        if (event && event.value) {
            onSelect(event.value);
        } else {
            onSelect();
        }
    }

    return (
        <>
            {formattedDisciplines ?
                <BasicSelect formattedValues={formattedDisciplines} selectedValue={selected} menuPlacement={menuPlacement} onSelect={handleOnChange}/>
                :
                <p>Loading options...</p>
            }
        </>
    );
};

export default SelectDiscipline;
