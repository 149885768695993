import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonToggle,
} from "@ionic/react";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { CreateEventFeeInput, CreateFeeInput, UpdateEventFeeInput, UpdateFeeInput } from "../../API";
import { PersonContext } from "../../context/PersonContext";
import { Event, EventFee, TaxRate } from "../../models";
import { createEventFee, deleteEventFee, updateEventFee } from "../../utilities/eventFee/EventFee";
import { createFee, getFeeById, updateFee } from "../../utilities/fee/Fee";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import SelectCreateTaxRate from "../TaxRates/SelectCreateTaxRate";
import DatePicker from "../DatePicker/DatePicker";
import moment from "moment";
import TimePicker from "../TimePicker";
import { formatDateTimeTo24Hours } from "../../utilities/time/Time";

interface _Props {
    event: Event
    selectedEventFee?: EventFee
    onSubmit: Function
}

const HorseShowRatingForm: React.FC<_Props> = ({selectedEventFee, event, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string | null | undefined>("");
    const [amount, setAmount] = useState<number>(0);
    const [multiplyByClass, setMultipleByClass] = useState(false);
    const [includeTax, setIncludeTax] = useState(false);
    const [taxA, setTaxA] = useState(0);
    const [taxRateA, setTaxRateA] = useState<TaxRate | null | undefined>();
    const [taxB, setTaxB] = useState(0);
    const [taxRateB, setTaxRateB] = useState<TaxRate | null | undefined>();
    const [isEntry, setIsEntry] = useState(true);
    const [isRefundable, setIsRefundable] = useState(true);
    const [isStartedOnDate, setIsStartedOnDate] = useState(false);
    const [isShownOnEntryForm, setIsShownOnEntryForm] = useState(true);
    const [date, setDate] = useState<any>(moment(new Date).format("YYYY-MM-DD"));
    const [time, setTime] = useState<string | null | undefined>("01:00");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (selectedEventFee) {
            setName(selectedEventFee.name);
            setDescription(selectedEventFee.description || "");
            setAmount(selectedEventFee.fee?.totalPrice || 0);
            setIsEntry(selectedEventFee.fee?.isPerEntry || false);
            setMultipleByClass(selectedEventFee.fee?.isPerClass || false);
            setIsRefundable(selectedEventFee.fee?.isRefundable || false);
            setIsStartedOnDate(selectedEventFee.fee?.isStartedOnEntryDate || false);
            setIsShownOnEntryForm(selectedEventFee.fee?.isHiddenFromEntryForm ? false : true);
            setDate(moment(selectedEventFee.fee?.startDate).format("YYYY-MM-DD") || "");
            setTime(selectedEventFee.fee?.startTime || "");
        }
    }, [selectedEventFee]);

    const verifyForm = () => {
        if (!name) {
            setError("Please include an informative name so exhibitors know what this fee is.");
            return false;
        }
        if (!amount || amount <= -1) {
            setError("Please set the fee amount to an amount greater than or equal to $0.");
            return false;
        }
        return true;
    }

    const clearForm = () => {
        setName("");
        setDescription("");
        setAmount(0);
        setIsEntry(true);
        setIsRefundable(true);
        setIsShownOnEntryForm(true);
        selectedEventFee = undefined;
    }

    const handleSelectedTaxRateA = (taxRate: TaxRate) => {
        setTaxRateA(taxRate);
        if (taxRate.percentage) setTaxA(taxRate.percentage);
        setIsDisabled(false);
    }

    const handleSelectedTaxRateB = (taxRate: TaxRate) => {
        setTaxRateB(taxRate);
        if (taxRate.percentage) setTaxB(taxRate.percentage);
        setIsDisabled(false);
    }

    const handleCreateFee = async () => {
        // Create the basic fee
        let input: CreateFeeInput = {
            name: name,
            description: description,
            organizationId: event.organizationId || "",
            isFixed: true,
            totalPrice: amount,
            isPerEntry: isEntry,
            isPerClass: multiplyByClass,
            isRefundable: isRefundable,
            isHiddenFromEntryForm: isShownOnEntryForm ? false : true,
            createdBy: user.id
        };
        if (includeTax) {
            if (taxRateA) {
                input["taxA"] = taxA;
                input["feeTaxRateAId"] = taxRateA.id;
            }
            if (taxRateB) {
                input["taxB"] = taxB;
                input["feeTaxRateBId"] = taxRateB.id;
            }
        } else {
            input["taxA"] = undefined;
            input["feeTaxRateAId"] = "";
            input["taxB"] = undefined;
            input["feeTaxRateBId"] = "";
        }
        if (isStartedOnDate) {
            input["isStartedOnEntryDate"] = true;
            input["startDate"] = moment(date).format("YYYY-MM-DD");
            input['startTime'] = time ? formatDateTimeTo24Hours(date, time) : "";
        } else {
            input["isStartedOnEntryDate"] = false;
        }
        const createResult = await createFee(input);
        if (createResult.isSuccess) {
            // Then add the fee to this event
            const input: CreateEventFeeInput = {
                name: name,
                amount: amount,
                eventId: event.id,
                feeId: createResult.result.id,
                description: description,
                splitAcrossEntries: false,
                createdBy: user.id
            };
            const createEventFeeResult = await createEventFee(input);
            if (createEventFeeResult.isSuccess) {
                onSubmit("create", createEventFeeResult.result);
                clearForm();
            } else {
                setError("Sorry, we had a problem creating the fee for this event.");
            }
        } else {
            const message = "Sorry, we had a problem creating the fee. " + createResult.message;
            setError(message);
        }
    }

    const handleUpdateEventFee = async () => {
        if (selectedEventFee?.feeId) {
            const queryResult = await getFeeById(selectedEventFee.feeId);
            if (queryResult.isSuccess) {
                let input: UpdateFeeInput = {
                    id: queryResult.result.id,
                    name: name,
                    description: description,
                    organizationId: event.organizationId || "",
                    isFixed: true,
                    totalPrice: amount,
                    isPerEntry: isEntry,
                    isPerClass: multiplyByClass,
                    isRefundable: isRefundable,
                    isHiddenFromEntryForm: isShownOnEntryForm ? false : true,
                    createdBy: user.id
                };
                if (includeTax) {
                    if (taxRateA) {
                        input["taxA"] = taxA;
                        input["feeTaxRateAId"] = taxRateA.id;
                    }
                    if (taxRateB) {
                        input["taxB"] = taxB;
                        input["feeTaxRateBId"] = taxRateB.id;
                    }
                } else {
                    input["taxA"] = null;
                    input["feeTaxRateAId"] = "";
                    input["taxB"] = null;
                    input["feeTaxRateBId"] = "";
                }
                if (isStartedOnDate) {
                    input["isStartedOnEntryDate"] = true;
                    input["startDate"] = moment(date).format("YYYY-MM-DD");
                    input['startTime'] = time ? formatDateTimeTo24Hours(date, time) : "";
                } else {
                    input["isStartedOnEntryDate"] = false;
                }
                const updateFeeResult = await updateFee(input);
                if (!updateFeeResult.isSuccess) setError("Sorry, we had a problem updating the fee.");
            }
        }
        if (selectedEventFee) {
            const input: UpdateEventFeeInput = {
                id: selectedEventFee.id,
                name: name,
                amount: amount,
                eventId: event.id,
                description: description,
                splitAcrossEntries: false
            };
            const updateEventFeeResult = await updateEventFee(input);
            if (updateEventFeeResult.isSuccess) {
                clearForm();
                onSubmit("update", updateEventFeeResult.result);
            } else {
                setError("Sorry, we had a problem updating the fee for this event.");
            }
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setError("");
        const isValid = verifyForm();
        if (isValid) {
            if (selectedEventFee) {
                await handleUpdateEventFee();
            } else {
                await handleCreateFee();
            }
            setIsDisabled(true);
        }
        setIsLoading(false);
    }

    const handleDelete = async () => {
        setIsLoading(true);
        setError("");
        if (selectedEventFee) {
            const deleteResult = await deleteEventFee({id: selectedEventFee.id});
            if (deleteResult.isSuccess) {
                clearForm();
                onSubmit("delete", deleteResult.result);
            } else {
                setError(deleteResult.message);
            }
        } else {
            setError("Could not find the event fee");
        }
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Name</IonLabel>
                            <IonInput 
                                type="text"
                                value={name}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setName(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Description</IonLabel>
                            <IonInput 
                                type="text"
                                value={description}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setDescription(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white" lines="none">
                            <IonLabel position="stacked">Fee Amount</IonLabel>
                            <InputGroup className="ion-margin-top">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>$</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type="number"
                                    value={amount}
                                    min={0}
                                    max={100}
                                    onChange={e => {
                                        if(isMounted) setIsDisabled(false);
                                        else setIsMounted(true);
                                        setAmount(parseFloat(e.target.value));
                                    }}
                                />
                            </InputGroup>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white" lines="none">
                            <IonLabel position="stacked">Multiply per Class?</IonLabel>
                            <IonToggle 
                                color="tertiary" 
                                checked={multiplyByClass} 
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setMultipleByClass(e.detail.checked)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                {multiplyByClass && (
                    <>
                        <IonRow>
                            <IonCol size="12">
                                <p>Example: an entry with 5 classes will be charged ${amount} * 5 = ${amount * 5}</p>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                <IonRow>
                    <IonCol>
                        <IonItem color="white" lines="none">
                            <IonLabel position="stacked">Charge Tax on this Fee?</IonLabel>
                            <IonToggle 
                                color="tertiary" 
                                checked={includeTax} 
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setIncludeTax(e.detail.checked)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                {includeTax && (
                    <>
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonLabel position="stacked" className="description pl-3">Tax #1</IonLabel>
                                <SelectCreateTaxRate organizationId={event.organizationId} selectedValue={taxRateA?.id} onSelect={handleSelectedTaxRateA}/>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonLabel position="stacked" className="description pl-3">Tax #2</IonLabel>
                                <SelectCreateTaxRate organizationId={event.organizationId} selectedValue={taxRateB?.id} onSelect={handleSelectedTaxRateB}/>
                            </IonCol>
                        </IonRow>
                    </>
                )}
                <hr/>
                <IonRow>
                    <IonCol>
                        <IonItem color="white" lines="none">
                            <IonLabel position="stacked">Require Fee for Each Entry?</IonLabel>
                            <IonToggle color="tertiary" checked={isEntry} onIonChange={e => {setIsEntry(e.detail.checked); setIsDisabled(false);}}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white" lines="none">
                            <IonLabel position="stacked">Show this Fee on the Entry Form</IonLabel>
                            <IonToggle color="tertiary" checked={isShownOnEntryForm} onIonChange={e => {setIsShownOnEntryForm(e.detail.checked); setIsDisabled(false);}}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white" lines="none">
                            <IonLabel position="stacked">Refund fee if entry scratches?</IonLabel>
                            <IonToggle color="tertiary" checked={isRefundable} onIonChange={e => {setIsRefundable(e.detail.checked); setIsDisabled(false);}}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white" lines="none">
                            <IonLabel position="stacked">Begin Fee on Date?</IonLabel>
                            <IonToggle 
                                color="tertiary" 
                                checked={isStartedOnDate} 
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setIsStartedOnDate(e.detail.checked)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                {isStartedOnDate && (
                    <>
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                <DatePicker onChange={(date: any) => {setDate(date); setIsDisabled(false);}}/>
                            </IonCol>
                            <IonCol sizeXs="10" sizeMd="6" className="ion-text-center">
                                <TimePicker label="Time" position="fixed" selectedTime={time} onChange={(time: string | null | undefined) => {setTime(time); setIsDisabled(false);}} />
                            </IonCol>
                        </IonRow>
                    </>
                )}
                <IonRow>
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            {selectedEventFee && (
                                <IonCol sizeMd="4">
                                    <IonButton
                                        className="ion-margin-top"
                                        color="danger"
                                        expand="block"
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </IonButton>
                                </IonCol>
                            )}
                            <IonCol offsetMd="4" sizeMd="4">
                                <IonButton
                                    disabled={isDisabled}
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleSubmit}
                                >
                                    {isDisabled ? "Saved" : "Save"}
                                </IonButton>
                            </IonCol>
                        </>
                    }
                </IonRow>
            </form>
        </>
    );
};

export default HorseShowRatingForm;