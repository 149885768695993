export const stateList = [
    {value: 1, label:"AL"}, 
    {value: 2, label: "AK"}, 
    {value: 3, label: "AS"}, 
    {value: 4, label: "AZ"},
    {value: 5, label: "AR"}, 
    {value: 6, label: "CA"}, 
    {value: 7, label: "CO"},  
    {value: 8, label: "CT"},  
    {value: 9, label: "DE"},
    {value: 10, label: "DC"},  
    {value: 11, label: "FM"},
    {value: 12, label: "FL"},
    {value: 13, label: "GA"},
    {value: 14, label: "GU"}, 
    {value: 15, label: "HI"}, 
    {value: 16, label: "IA"},
    {value: 17, label: "ID"}, 
    {value: 18, label: "IL"}, 
    {value: 19, label: "IN"}, 
    {value: 20, label: "KS"}, 
    {value: 21, label: "KY"}, 
    {value: 22, label: "LA"}, 
    {value: 23, label: "MA"}, 
    {value: 24, label: "MD"}, 
    {value: 25, label: "ME"}, 
    {value: 26, label: "MH"}, 
    {value: 27, label: "MI"}, 
    {value: 28, label: "MN"}, 
    {value: 29, label: "MO"}, 
    {value: 30, label: "MP"}, 
    {value: 31, label: "MS"}, 
    {value: 32, label: "MT"}, 
    {value: 33, label: "NC"}, 
    {value: 34, label: "ND"}, 
    {value: 35, label: "NE"}, 
    {value: 36, label: "NH"}, 
    {value: 37, label: "NM"}, 
    {value: 38, label: "NJ"}, 
    {value: 39, label: "NV"}, 
    {value: 40, label: "NY"}, 
    {value: 41, label: "OH"}, 
    {value: 42, label: "OK"}, 
    {value: 43, label: "OR"}, 
    {value: 44, label: "PA"}, 
    {value: 45, label: "PR"},
    {value: 46, label: "PW"},
    {value: 47, label: "RI"},
    {value: 48, label: "SC"},
    {value: 49, label: "SC"},
    {value: 50, label: "SD"},
    {value: 51, label: "TN"},
    {value: 52, label: "TX"},
    {value: 53, label: "UT"},
    {value: 54, label: "VA"},
    {value: 55, label: "VI"},
    {value: 56, label: "VT"},
    {value: 57, label: "WA"},
    {value: 58, label: "WI"},
    {value: 59, label: "WV"},
    {value: 60, label: "WY"},
    {value: 61, label: "AB"},
    {value: 62, label: "BC"},
    {value: 63, label: "MB"},
    {value: 64, label: "NB"},
    {value: 65, label: "NL"},
    {value: 66, label: "NS"},
    {value: 67, label: "NT"},
    {value: 68, label: "NU"},
    {value: 69, label: "ON"},
    {value: 70, label: "PE"},
    {value: 71, label: "QC"},
    {value: 72, label: "SK"},
    {value: 73, label: "YT"}
];