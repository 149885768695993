import moment from "moment";
import { ScheduleItem } from "../../models";
import { getEventClassTypeAbbreviation } from "../eventClass/EventClassTypes";
import { getEventClassEntriesCountByEventClassId } from "../eventClassEntry/EventClassEntry";
import { openTabForPrinting } from "./ReportUtils";
import { addFooters } from "./ReportFooter";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export async function generateSchedulePDF(scheduleItems: ScheduleItem[], eventString: string, dateString: string, ringString: string, callbackFunction?: Function, customFontSize?: number, isDownload?: boolean, includeClassTotals?: boolean, includeTable?: boolean) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.setFontSize(11);
    doc.text(`${eventString}`, 14, 14);
    doc.text(`Schedule for ${ringString} - ${moment(dateString).format("ddd MMM D, YYYY")}`, 14, 20);

    // define the columns we want and their titles
    const tableColumn = ["Number", "Class", "Type", "Entries", "Start Time"];
    // define an empty array of rows
    const tableRows = [];

    let totalNumberOverFencesTripsForDayAndRing = 0;
    let totalNumberUnderSaddleClassesForDayAndRing = 0;
    let totalNumberModelClassesForDayAndRing = 0;
    let totalNumberBreaksForDayAndRing = 0;

    for (let i = 0; i < scheduleItems.length; i++) {
        const currentScheduleItem = scheduleItems[i];

        if (callbackFunction) {
            const percentageComplete = (((i+1)/scheduleItems.length)*100).toFixed(2);
            const message = percentageComplete + "% Complete";
            callbackFunction(message);
        }

        const eventClass = currentScheduleItem.eventClass;
        const eventBreak = currentScheduleItem.eventBreak;
        if (eventClass) {
            let entriesCount: number = 0;
            let entriesCountString: string = "-";
            if (eventClass.id) {
                const queryEntriesResult = await getEventClassEntriesCountByEventClassId(eventClass.id);
                if (queryEntriesResult.isSuccess) {
                    entriesCount = queryEntriesResult.result;
                    entriesCountString = queryEntriesResult.result.toString();
                }
            }
            if (eventClass.type && (eventClass.type === "eq_flat" || eventClass.type === "hack" || eventClass.type === "under_saddle")) {
                totalNumberUnderSaddleClassesForDayAndRing = totalNumberUnderSaddleClassesForDayAndRing + 1;
            } else if (eventClass.type && (eventClass.type === "model")) {
                totalNumberModelClassesForDayAndRing = totalNumberModelClassesForDayAndRing + 1;
            } else if (eventClass.type && (eventClass.type === "break")) {
                totalNumberBreaksForDayAndRing = totalNumberBreaksForDayAndRing + 1;
            } else {
                totalNumberOverFencesTripsForDayAndRing = totalNumberOverFencesTripsForDayAndRing + entriesCount;
            }

            const classNumber = currentScheduleItem.eventClass?.number ? currentScheduleItem.eventClass.number.toString() : "";
            const className = currentScheduleItem.eventClass?.name;
            const classType = currentScheduleItem.eventClass?.type ? getEventClassTypeAbbreviation(currentScheduleItem.eventClass?.type) : "";
            const classStartTime = currentScheduleItem.startTime || "";
 
            const currentData = [
                classNumber,
                className,
                classType,
                entriesCountString,
                classStartTime
            ];
            tableRows.push(currentData);
        } else if (eventBreak && eventBreak.isDisplayedOnSchedule) {
            const currentData = [
                "",
                eventBreak.name,
                "break",
                "",
                currentScheduleItem.startTime
            ];
            tableRows.push(currentData);
        }
    };
    
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: includeTable ? "grid" : "plain", //plain means there will be no grid lines
        tableWidth: 'wrap', //Will minimize the size of the table as much as it can
        headerStyles: {
            fillColor: includeTable ? "#73a4d3" : ""
        },
        styles: {
            fontSize: customFontSize || 10,
            rowHeight: customFontSize ? (customFontSize - 4) : 10, //Specifies the height of each row
        },
        startY: 25 
    });

    if (includeClassTotals) {
        var yCoord = doc.lastAutoTable.finalY + 6;
        doc.setFontSize(customFontSize || 10);
        if (totalNumberOverFencesTripsForDayAndRing > 0) doc.text(`Total # trips: ${totalNumberOverFencesTripsForDayAndRing}`, 14, yCoord);
        if (totalNumberUnderSaddleClassesForDayAndRing > 0) doc.text(`Total # flat classes: ${totalNumberUnderSaddleClassesForDayAndRing}`, 14, yCoord + 6);
        if (totalNumberModelClassesForDayAndRing > 0) doc.text(`Total # model classes: ${totalNumberModelClassesForDayAndRing}`, 14, yCoord + 6);
        if (totalNumberBreaksForDayAndRing > 0) doc.text(`Total # breaks: ${totalNumberBreaksForDayAndRing}`, 14, yCoord + 6);
    }

    // page footer
    addFooters(doc);

    if (isDownload) {
        doc.save(`schedule_${dateString}_${ringString}.pdf`);
    } else {
        openTabForPrinting(doc);
    }
}