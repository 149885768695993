import { 
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonRow
} from "@ionic/react";
import React, { useContext, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import { Organization } from "../../models";
import { PersonContext } from "../../context/PersonContext";
import { getPersonByEmail } from "../../utilities/person/Person";
import { createInvitation } from "../../utilities/invitation/Invitation";
import { checkmark } from "ionicons/icons";
import { sendInvitationEmail } from "../../utilities/invitation/InvitationEmail";
import { CreateInvitationInput, InvitationStatus } from "../../API";

interface _Props {
    organization: Organization
}

const OrganizationAddMembersForm: React.FC<_Props> = ({organization}) => {
    const user = useContext(PersonContext);

    const [email, setEmail] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [error, setError] = useState("");

    const inviteNewUser = async () => {
        setError("");
        setSuccessMessage("");

        // Set up the invitation input
        let input: CreateInvitationInput = {
            createdBy: user.id,
            inviteeEmail: email,
            organizationId: organization.id,
            permissionLevel: "admin",
            roles: "show",
            status: InvitationStatus.pending
        };

        // Check if the email address belongs to someone already signed up in the system
        const searchResult = await getPersonByEmail(email);
        if (searchResult.isSuccess) {
            const person = searchResult.result;
            input.invitee = person.id;
        }

        const createInvitationResult = await createInvitation(input);
        if (createInvitationResult.isSuccess) {
            await sendInvitationEmail(email, organization.name);
            setEmail("");
            setSuccessMessage("Sent the invitation!");
        } else {
            setEmail("");
            setError("Sorry, we were unable to send any invitation to this email address. Please double check it and try again.");
        }
    }

    return (
        <IonRow>
            {successMessage && (
                <IonCol size="12">
                    <IonCard color="success" mode="md">
                        <IonCardContent>
                            <IonIcon icon={checkmark} />
                            <IonLabel id="errorMsg" className="ion-text-wrap description font-weight-bold">
                                {" " + successMessage}
                            </IonLabel>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            )}
            {error && <ErrorAlert width="12" error={error} />}
            <IonCol sizeXs="12" sizeMd="10">
                <IonItem>
                    <IonLabel position="floating">Email Address</IonLabel>
                    <IonInput
                        type="email"
                        aria-required={true}
                        value={email}
                        onIonChange={e => setEmail(e.detail.value!)}
                    />
                </IonItem>
            </IonCol>     
            <IonCol className="text-center" sizeXs="12" sizeMd="2">
                <IonButton
                    className="ion-margin-top"
                    expand="block"
                    color="tertiary"
                    onClick={inviteNewUser}
                >
                    Invite
                </IonButton>
            </IonCol>
        </IonRow>
    );
};

export default OrganizationAddMembersForm;
