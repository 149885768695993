import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonText,
    IonTitle,
    IonToggle,
    IonToolbar,
    isPlatform
} from "@ionic/react";
import { add, close, trashOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { OrganizationMembershipType, OrganizationRule } from "../../models";
import { isWindows } from "../../utilities/platform/Platform";
import ErrorAlert from "../Errors/ErrorAlert";
import { MembershipTypeCategory, UpdateMembershipInput } from "../../API";
import Spinner from "../Spinners/Spinner";
import { getMembershipsByMembershipTypeId, updateMembership } from "../../utilities/membership/Membership";
const { v4: uuidv4 } = require('uuid');

interface _Props {
    type: string;
    organizationRules: (OrganizationRule | null)[] | null | undefined;
    setOrganizationRules: Function;
    membershipType?: OrganizationMembershipType;
}

const MembershipTypeWaiversList: React.FC<_Props> = ({ type, organizationRules, setOrganizationRules, membershipType }) => {
    const [error, setError] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [body, setBody] = useState<string>("");
    const [isRequired, setIsRequired] = useState<boolean>(true);
    const [waiversList, setWaiversList] = useState<(OrganizationRule | null)[] | null | undefined>(organizationRules);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedWaivers, setSelectedWaivers] = useState< OrganizationRule | undefined | null>(undefined);

    useEffect(() => {
        setOrganizationRules(waiversList);
    }, [waiversList]);

    const handleOpenModal = () => {
        setError("");
        setShowModal(true);
    };

    const handleNewWaiver = () => {
        if (!title) {
            setError("Please include a title.");
            return;
        }
        if (!body) {
            setError("Please include a body.");
            return;
        }
        setWaiversList([
            ...waiversList ?? [],
            { id: uuidv4(), title, body, isRequired },
        ]);
        setTitle("");
        setBody("");
        setShowModal(false);
    };
    const handleDeleteWaiver = async() => {
        if (membershipType) {
            setIsLoading(true);
            const membershipResult = await getMembershipsByMembershipTypeId(membershipType.id);
            if(membershipResult.isSuccess) {
                for (const membership of membershipResult.result) {
                    const organizationAgreements = membership.organizationAgreements;
                    if(organizationAgreements && organizationAgreements.length) {
                        const index = organizationAgreements?.findIndex((item: any) => {
                            return item?.organizationRuleId === selectedWaivers?.id;
                        });
                        if(index !== -1) {
                            organizationAgreements?.splice(index ?? 0, 1);
                            const input: UpdateMembershipInput = {
                                id: membership.id,
                                organizationAgreements: organizationAgreements
                            };
                            await updateMembership(input);
                        }
                    }
                }
            } else {
                setError(membershipResult.message);
            }
            setIsLoading(false);
        }
        const index = waiversList?.findIndex((item) => {
            return item?.id === selectedWaivers?.id;
        });
        waiversList?.splice(index ?? 0, 1);
        setWaiversList([...waiversList ?? []]);
        setShowConfirmModal(false);
        setSelectedWaivers(undefined);
    };

    return (
        <React.Fragment>
            <IonModal backdropDismiss={false} isOpen={showModal}>
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Waiver
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            id="waiver-list-modal-close-icon"
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p
                                id="waiver-list-modal-close-btn"
                                className="font-weight-normal text-medium text-capitalize"
                            >
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {error && <ErrorAlert width="12" error={error} />}
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <h4>
                                Include body of a waiver or rule that you would
                                like for the user to digitally sign.
                            </h4>
                            <h4>Example: Liability Waiver</h4>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10" className="ion-text-center">
                            <IonItem color="white">
                                <IonLabel position="floating">
                                    Title{" "}
                                    <span className="description text-danger">
                                        *
                                    </span>
                                </IonLabel>
                                <IonInput
                                    id="waiver-list-modal-title"
                                    type="text"
                                    value={title}
                                    aria-required={true}
                                    onIonChange={(e) => {
                                        setError("");
                                        setTitle(e.detail.value!);
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size="10" className="mt-2">
                            <IonLabel
                                position="floating"
                                className="text-darker pl-3"
                            >
                                Body{" "}
                                <span className="description text-danger">
                                    *
                                </span>
                            </IonLabel>
                            <Input
                                id="waiver-list-modal-body"
                                rows="4"
                                type="textarea"
                                name="body"
                                value={body}
                                spellCheck="true"
                                onChange={(e) => {
                                    setError("");
                                    setBody(e.target.value!);
                                }}
                            />
                        </IonCol>
                        <IonCol size="10" className="mt-2">
                            <IonItem lines="none">
                                <IonLabel
                                    slot="start"
                                    className="text-darker pl-3 ion-text-wrap"
                                >
                                    Is signature required?
                                </IonLabel>
                                <IonToggle id="membershipWaiverRequirementToggle" color="tertiary" checked={isRequired} onIonChange={e => setIsRequired(e.detail.checked)} />
                                {isRequired ? "Yes" : "No"}
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonContent>
                <IonRow className="ion-justify-content-center ion-padding-top">
                    <IonCol size="6" className="ion-text-center">
                        <IonButton
                            id="waiver-list-modal-cancel-btn"
                            expand="block"
                            color="light"
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </IonButton>
                    </IonCol>
                    <IonCol size="6" className="ion-text-center">
                        <IonButton
                            id="waiver-list-modal-add-btn"
                            expand="block"
                            color="tertiary"
                            onClick={handleNewWaiver}
                        >
                            Add
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonModal>
            <IonModal backdropDismiss={false} isOpen={showConfirmModal}>
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Remove Waiver
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            id="waiver-upload-confirm-modal-close-icon"
                            fill="clear"
                            onClick={() => {
                                setShowConfirmModal(false)
                                setSelectedWaivers(undefined)
                            }}
                        >
                            <p
                                id="waiver-upload-confirm-modal-close-btn"
                                className="font-weight-normal text-medium text-capitalize"
                            >
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading && <Spinner/>}
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12" className="mb-3">
                            <IonText>Are you sure you want to delete this required signature?</IonText>
                        </IonCol>
                        <IonCol size="12" className="mb-3" class="ion-text-center">
                            <h4>{selectedWaivers?.title}</h4>
                        </IonCol>
                        <IonCol size="12" className="mb-3">
                            <IonText>
                                NOTE: if any users have created a membership of this type,
                                removing this required signature will also delete the connected
                                signatures users have added for their memberships.
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonContent>
                <IonRow className="ion-justify-content-center ion-padding-top">
                    <IonCol size="6" className="ion-text-center">
                        <IonButton
                            id="waiver-upload-confirm-modal-cancel-btn"
                            expand="block"
                            color="light"
                            onClick={() => {
                                setShowConfirmModal(false)
                                setSelectedWaivers(undefined)
                            }}
                        >
                            Cancel
                        </IonButton>
                    </IonCol>
                    <IonCol size="6" className="ion-text-center">
                        <IonButton
                            id="waiver-upload-confirm-modal-remove-btn"
                            expand="block"
                            color="danger"
                            onClick={handleDeleteWaiver}
                        >
                            Remove
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonModal>
            <h6 className="ml-1">
                Waivers for{" "}
                {type === MembershipTypeCategory.individual ? "an " + type : "a " + type} Member
            </h6>
            {isWindows() || isPlatform("desktop") ? (
                <IonRow>
                    <IonCol>
                        {waiversList && waiversList.length === 0 && (
                            <p>No items listed yet</p>
                        )}
                        <ul>
                            {waiversList?.map((element, index) => {
                                return (
                                    <IonRow key={index} className="ion-justify-content-between">
                                        <IonCol sizeMd="10">
                                            <li className="text-darker">
                                                {element?.title}
                                            </li>
                                        </IonCol>
                                        <IonCol className="pt-2" sizeMd="2">
                                            <IonIcon
                                                className="ml-3 link"
                                                icon={trashOutline}
                                                color="danger"
                                                onClick={() => {
                                                    setShowConfirmModal(true) 
                                                    setSelectedWaivers(element)
                                                }}
                                            />
                                        </IonCol>
                                    </IonRow>
                                );
                            })}
                        </ul>
                    </IonCol>
                    <IonCol>
                        <IonButton id="membership-type-sign-upload" onClick={handleOpenModal}>
                            <IonIcon icon={add} />
                            <IonLabel>Add New</IonLabel>
                        </IonButton>
                    </IonCol>
                </IonRow>
            ) : (
                <>
                    <IonRow className="ion-justify-content-center">
                        <IonCol>
                            {waiversList && waiversList.length === 0 && (
                                <p className="text-center">No items listed yet</p>
                            )}
                            <ul>
                                {waiversList?.map((element, index) => {
                                    return (
                                        <IonRow  key={index} className="ion-justify-content-between">
                                            <IonCol sizeXs="10">
                                                <li className="text-darker">
                                                    {element?.title}
                                                </li>
                                            </IonCol>
                                            <IonCol className="pt-2" sizeXs="2">
                                                <IonIcon
                                                    className="ml-1 link"
                                                    icon={trashOutline}
                                                    color="danger"
                                                    onClick={() => {
                                                        setShowConfirmModal(true) 
                                                        setSelectedWaivers(element)
                                                    }}
                                                />
                                            </IonCol>
                                        </IonRow>
                                    );
                                })}
                            </ul>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonButton onClick={handleOpenModal}>
                            <IonIcon icon={add} />
                            <IonLabel>Add New</IonLabel>
                        </IonButton>
                    </IonRow>
                </>
            )}
        </React.Fragment>
    );
};

export default MembershipTypeWaiversList;
